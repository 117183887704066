import { rangeDefaults, radioDefaults, checkboxDefaults } from './helpers'
import partnership_interests from 'util/partnership_interests'
import usRegions from './us-regions'

const brandSearch = {
  /** OPTIONS COMMON TO ALL SEARCH MODELS **/

  // Initial page (usually always 1)
  page: 1,

  // Number of items per page
  size: 24,

  // Total number items returned by search
  total: 0,

  // The text input
  searchValue: '',

  // An object representing options for the SortByFilter
  sortBy: {
    // The field by which results should initially be sorted
    value: 'campaignsLast90Days',
    // The initial ordering (true = ascending, false = descending)
    asc: false,
    // Array of options for how search results can be sorted
    // value = the key in Elasticsearch
    // label = human readable label
    options: [
      {
        value: '_score',
        label: 'Best Match',
      },
      {
        value: 'campaignsLast90Days',
        label: 'Recent Campaigns',
      },
      {
        value: 'accountname',
        label: 'A-Z',
      },
      {
        value: 'dedicatedListSize',
        label: 'List Size',
      },
      {
        value: 'averageSignUpsPerCampaign',
        label: 'Avg. Entries/Campaign',
      },
      {
        value: 'socialMediaImpressions',
        label: 'Social Impressions',
      },
      {
        value: 'created_at',
        label: 'Join Date'
      }
    ],
    // Object with the default initial ordering for each option
    // Note: only options that are initially defaulted to ASCENDING need to be
    // enumerated here, all others are assumed to be DESCENDING
    // key: key in Elasticsearch
    defaultDirections: {
      accountname: true,
    },
  },

  // An object representing the expanded state of each filter in the search view
  // key: key in model
  filtersExpanded: {
    listSizeValue: true,
    avgEntriesCampaignValue: true,
    recentActivity: true,
    partnershipHistory: false,
    geoMinAudience: false,
    totalSocialAudience: false,
    tags: false,
    interests: false,
  },

  /** INDIVIDAUL FILTERS PER SEARCH MODEL **/
  // Range filters
  listSizeValue: rangeDefaults({ term: 'dedicatedListSize' }),
  avgEntriesCampaignValue: rangeDefaults({ term: 'averageSignUpsPerCampaign' }),
  totalSocialAudience: rangeDefaults({ term: 'socialMediaImpressions' }),

  // Radio filters
  recentActivity: radioDefaults({
    term: 'lastActivity',
    value: '90',
    options: [
      {
        label: 'Active in last 30 days',
        value: '30',
      },
      {
        label: 'Active in last 60 days',
        value: '60',
      },
      {
        label: 'Active in last 90 days',
        value: '90',
      },
      {
        label: 'Last active 90+ days ago',
        value: 'older',
      },
    ],
  }),

  joinDate: radioDefaults({
    term: 'created_at',
    value: 'all',
    options: [
      {
        label: 'Within last 30 days',
        value: '30'
      },
      {
        label: 'Within last 60 days',
        value: '60'
      },
      {
        label: 'Within last 90 days',
        value: '90'
      },
      {
        label: 'Ever',
        value: 'all'
      },
    ]
  }),

  // Checkbox filters
  tags: checkboxDefaults({ term: 'tags.raw' }),
  interests: checkboxDefaults({
    term: 'interests.raw',
    labelMap: partnership_interests,
  }),

  // special case where options are enumerated (not derived from Elasticsearch)
  // requires special work in dispatcher
  partnershipHistory: checkboxDefaults({
    options: [
      {
        key: '1st',
        label: 'Brands I’ve worked with',
        value: true,
      },
      {
        key: '2nd',
        label: 'Brands in my extended network',
        value: true,
      },
      {
        key: '3rd',
        label: 'Brands I’ve never worked with',
        value: true,
      },
    ],
  }),

  // for geo filter
  geoMinAudience: rangeDefaults({
    term: 'state_distribution',
    value: 0,
  }),
  regions: checkboxDefaults({
    term: 'regions',
    options: usRegions,
  }),
}

export default brandSearch
