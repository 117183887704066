import React from 'react'
import PropTypes from 'prop-types'

import { H2 } from 'visual-components/util/texts'

import Theme from 'css/themes/ExpandableCalculatorTheme.css'

class ExpandableBar extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    selected: PropTypes.bool,
  }

  static defaultProps = {
    selected: false,
  }

  render() {
    const {
      title,
      value,
      selected,
    } = this.props

    return (
      <div>
        <div className={`${Theme.expandableBar} ${selected && Theme.selected}`}>
          <div className={Theme.title}>
            {title}
          </div>
          <div className={Theme.value}>
            <H2>
              {value}
            </H2>
            <img
              src="/images/icons/collapse-arrow.svg"
              className={`${Theme.collapseArrow} ${selected && Theme.selected}`}
            />
          </div>
        </div>
        <div className={Theme.shadowBar} />
      </div>
    )
  }
}

export default ExpandableBar
