import React from 'react'
import Dialog from 'react-toolbox/lib/dialog';
import { P } from 'visual-components/util/texts';

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const themes = {
  Button: ButtonTheme,
  Dialog: DialogTheme,
}

class ConfirmNavigationModal extends React.Component {
  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this)
  }

  confirm() {
    this.props.closeModal(true)
  }

  render() {
    // this.props.message should be JSON stringified with object properties "heading" and "message"
    let message = this.props.message ? JSON.parse(this.props.message) : {}

    let {
      isOpen,
      closeModal
    } = this.props;

    return (
      <Dialog
        theme={ themes.Dialog }
        active={ isOpen }
        onEscKeyDown={ closeModal }
        onOverlayClick={ closeModal }
        title={ message.heading }
      >
        <img
          onClick={ closeModal }
          className={ themes.Dialog.closeImage }
          src="/images/icons/close.svg" 
        />
        <P multiline>
          { message.message }
        </P>
        <ModalButtonGroup
          canSave={ true }
          confirm={ this.confirm }
          confirmText={ 'Yes, leave' }
          cancel={ closeModal } 
          cancelText={ 'Cancel' }
          hideLine={ true }
        />
        <div style={{ height: '16px' }} />
      </Dialog>
    )
  }
}

export default ConfirmNavigationModal
