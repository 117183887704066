import React from 'react';

import { Card } from 'react-toolbox/lib/card';

import GeographicMapDistributionGraph from 'visual-components/util/graphs/geographic-map-distribution-graph';
import EmptyState from 'visual-components/util/graphs/empty-state';
import { H3 } from 'visual-components/util/texts';

class GeographicMapDistributionCard extends React.Component {
  render() {
    let { data } = this.props

    return(
      <Card style={{
        width: '646px',
        height: '480px',
        overflow: 'visible'
      }}>
        <div style={{
          padding: '20px 24px'
        }}>
          <div style={{
            marginBottom: '18px'
          }}>
            <H3 coral>
              <small>
                Entries’ Geographic Distribution
              </small>
            </H3>
          </div>
          {
            data && Object.keys(data).length ?
            <GeographicMapDistributionGraph distribution={ data } />
            :
            <EmptyState text={ 'No geographic data available yet.' }
              height={ '360px' }
              bigCard
              bigText
            />
          }
        </div>
      </Card>
    )
  }
}

export default GeographicMapDistributionCard;
