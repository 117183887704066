import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import CardTab from './card-tab'

const renderTabs = (...params) => (tab, idx) => {
  const [selectedTab, switchTab, data, tabs] = params

  const twoTabs = tabs.length === 2

  return (
    <CardTab
      key={idx}
      tab={tab}
      currentTab={selectedTab === tab.ref}
      switchTab={switchTab}
      twoTabs={twoTabs}
      value={data[tab.ref]}
    />
  )
}

class CardTabs extends Component {
  static propTypes = {
    selectedTab: PropTypes.string.isRequired,
    switchTab: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    tabs: PropTypes.array,
  }

  static defaultProps = {
    tabs: [],
  }
  
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({
      template: '<span class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></span>'
    })
  }

  render() {
    const {
      selectedTab,
      tabs,
      switchTab,
      data,
    } = this.props

    const renderedTabs = tabs.map(renderTabs(selectedTab, switchTab, data, tabs))

    return (
      <div className="conversion-graph-tabs">
        { renderedTabs }
      </div>
    )
  }
}

export default CardTabs
