import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import InputContainer from 'visual-components/util/InputContainer'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4, Micro } from 'visual-components/util/texts'

import inputTheme from 'css/themes/Input.css'

import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import SellerProfileActions from 'actions/seller-profile-actions'

const stateToProps = ({ sellerProfileModals, sellerProfile }) => ({
  isOpen: sellerProfileModals.showAudienceDescriptionModal,
  sellerProfile,
})

const dispatchToProps = dispatch => ({
  sellerProfileActions: bindActionCreators(SellerProfileActions, dispatch),
})

class AudienceDescriptionModal extends Component {
  static propTypes = {
    sellerProfileActions: PropTypes.object.isRequired,
    sellerProfile: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = { text: '' }

  onSave = () => {
    const { text } = this.state
    const { sellerProfileActions } = this.props
    sellerProfileActions.updateSellerProfile({ audience_description: text })
    this.onClose()
  }

  onClose = () => {
    const { sellerProfileActions } = this.props
    sellerProfileActions.closeModal()
  }

  onUpdate = ({ target }) => {
    this.setState({
      text: target.value,
    })
  }

  componentDidUpdate = prevProps => {
    const { isOpen, sellerProfile } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      this.setState({ text: sellerProfile.audience_description })
    }
  }

  render() {
    const { text } = this.state
    const { isOpen } = this.props

    const charactersRemaining = text ? (200 - text.length) : 200
    const overLimit = charactersRemaining < 0
    const canSave = !overLimit

    return (
      <MultiTaskModal
        active={isOpen}
        close={this.onClose}
        title="Audience Description"
      >
        <div style={{ marginBottom: '16px' }}>
          <H4 multiline>
            Describe your audience profile.
          </H4>
        </div>
        <div style={{ marginBottom: '24px' }}>
          <InputContainer label="Description">
            <div className={inputTheme.textarea}>
              <textarea
                onChange={this.onUpdate}
                value={text}
                className={overLimit ? inputTheme.errorState : null}
                placeholder="Example: High-income 25-50 year olds who enjoys immersive experience and local culture while traveling. On average, our audience travels three times a year and believes every trip is a unique adventure."
              />
              <div style={{ textAlign: 'right' }}>
                {
                  <Micro>
                    <span style={{ color: overLimit ? '#fc2120' : '#374151' }}>
                      { `${Math.abs(charactersRemaining)} characters ${overLimit ? 'over limit' : 'remaining'}` }
                    </span>
                  </Micro>
                }
              </div>
            </div>
          </InputContainer>
        </div>
        <ModalButtonGroup
          canSave={canSave}
          confirm={this.onSave}
          cancel={this.onCancel}
          confirmText="Done"
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(AudienceDescriptionModal)
