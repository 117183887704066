import initialState from './defaults/campaignAnalyticsSearch'

export default {
  model: 'campaignAnalyticsSearch',
  initialState,
  endpoint: '/search-campaign-analytics',
  inputName: ['name', 'partners.brand_name'],
  aggs: getState => {
    const currentBrandId = getState().currentBrand.id
    return {
      industry: {
        terms: {
          field: 'industry.raw',
          size: 100,
        },
      },
      allVisits: {
        sum: {
          field: 'totalVisits',
        },
      },
      allSmsSubscribers: {
        sum: {
          field: 'smsSubscribers',
        },
      },
      allEntries: {
        sum: {
          field: 'totalEntries',
        },
      },
      partners: {
        nested: {
          path: 'partners',
        },
        aggs: {
          currentBrand: {
            filter: {
              term: {
                'partners.brand_id': currentBrandId,
              },
            },
            aggs: {
              allNTF: {
                sum: {
                  field: 'partners.ntf',
                },
              },
              totalEntriesDelivered: {
                sum: {
                  field: 'partners.total_entries_delivered'
                }
              },
              conversion_revenue: {
                sum: {
                  field: 'partners.conversion_revenue'
                }
              },
              conversion_transactions: {
                sum: {
                  field: 'partners.conversion_transactions'
                }
              },
              sms_subscribes: {
                sum: {
                  field: 'partners.sms_subscribes',
                },
              },
            },
          },
        },
      },
    }
  },
  rangeFilters: [
    'totalVisits',
    'totalEntries',
    'numberOfPartners',
    'percentNTF',
  ],
  facets: ['industry'],
  nestedFilter: getState => ({
    term: {
      'partners.brand_id': getState().currentBrand.id,
    },
  }),
  extendQuery({ body, state, getState }) {
    const { filter } = body.query.bool

    // Date Range
    const { dateRange } = state
    if (dateRange && dateRange.value) {
      const startDate = {
        lte: 'now' // filter for only campaigns that have started/launched
      }
      if (dateRange.value === 'custom') {
        if (
          dateRange.customValue &&
          dateRange.customValue.startDate &&
          dateRange.customValue.endDate
        ) {
          startDate.gte =
            `${dateRange.customValue.startDate.format('YYYY-MM-DD')}||/d`
          startDate.lte =
            `${dateRange.customValue.endDate.format('YYYY-MM-DD')}||/d`
        }
      } else if (dateRange.value !== 'all') {
        startDate.gte = `now-${state.dateRange.value}d`
      }
      filter.push({ range: { startDate } })
    }

    return body
  },
}
