import React from 'react';
import { object } from 'prop-types';
import COLOURS from 'util/colours';
import { H5 } from 'visual-components/util/texts';
import { Card } from 'react-toolbox/lib/card';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import CardTheme from 'css/themes/card.css';


class SwitchBrandCard extends React.Component {
  static propTypes = {
    brand: object.isRequired
  }

  render() {
    const {
      accountname,
      logo,
      unreadMessages
    } = this.props.brand;

    const unreadCount = Number(unreadMessages)

    return(
      <Card className={ CardTheme.switchBrandCard }>
        {/* unread message count */}
        { unreadCount
          ? <div style={{
              position: 'relative'
          }}>
            <div className={ CardTheme.unreadCount }>
              { unreadCount }
            </div>
          </div>
          : null
        }
        {/* img section */}
        <div style={{
          width: '72px',
          height: '72px',
          padding: '8px',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
          borderRightColor: COLOURS.silver,
          background: COLOURS.white,
          display: 'table-cell',
          verticalAlign: 'middle',
          cursor: 'pointer'
        }}>
          <img src={ logo }
            style={{
              maxHeight: '56px',
              maxWidth: '56px',
              display: 'block',
              margin: 'auto'
            }}/>
        </div>
        {/* name section */}
        <div style={{
          height: '72px',
          verticalAlign: 'middle',
          display: 'table-cell',
          padding: '8px'
        }}>
          <H5>
            { accountname }
          </H5>
        </div>
      </Card>
    )
  }
};

export default SwitchBrandCard;
