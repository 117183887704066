import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { Switch } from 'react-toolbox/lib/switch'
import { Dialog } from 'react-toolbox/lib/dialog'
import { P, H3 } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import IntegrationSetup from 'visual-components/integrations/integration-setup'
import IntegrationItem from 'visual-components/integrations/integration-item'
import IntegrationsMap from 'visual-components/integrations/integrations-map'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import PartnershipInvitesDispatcher from 'dispatchers/partnership-invites-dispatcher'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import ButtonTheme from 'css/themes/Buttons.css'
import SwitchTheme from 'css/themes/Switch.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import DataIntegrationsTheme from 'css/themes/data-integrations.css'
import COLOURS from 'util/colours'
import * as IntegrationsActions from 'actions/integrations-actions'

const mapStateToProps = ({ currentCampaign, currentBrand, espIntegrations }) =>
  ({ currentCampaign, currentBrand, espIntegrations })

const mapDispatchToProps = dispatch => ({
  integrationsActions: bindActionCreators(IntegrationsActions, dispatch),
})

class EmailDelivery extends React.Component {
  static propTypes = {
    currentCampaign: object.isRequired,
    currentBrand: object.isRequired,
    espIntegrations: object.isRequired,
    integrationsActions: object.isRequired,
    history: object.isRequired,
  }

  state = {
    warningModalOpen: false,
  }

  componentWillMount() {
    const { currentCampaign, integrationsActions: { load } } = this.props
    load('campaign', currentCampaign.id)
  }

  downloadEntries = () => {
    const { currentCampaign, currentBrand } = this.props
    const invite = currentCampaign.invites.find(i => i.invitee_id === currentBrand.id)
    ConfirmationDispatcher.checkDownloadEntries(currentCampaign.id, 'Campaign Builder Summary', invite.filterWebbula)
  }

  toggleFilter = () => {
    const { currentCampaign, currentBrand } = this.props

    const invite = currentCampaign.invites.find(i => i.invitee_id === currentBrand.id)

    PartnershipInvitesDispatcher.toggleEmailFiltering(invite, !invite.filterWebbula)
    this.toggleWarningModal(true)
  }

  toggleWarningModal = forceClose => {
    const { warningModalOpen } = this.state
    this.setState({ warningModalOpen: forceClose ? false : !warningModalOpen })
  }

  goToBrandSettings = () => {
    const { history } = this.props
    history.push('/profile/brand/filters')
  }

  goToDataIntegrations = () => {
    const { history } = this.props
    history.push('/profile/brand/data-integrations')
  }


  render() {
    const { currentCampaign, currentBrand, espIntegrations } = this.props
    const { warningModalOpen } = this.state

    const invite = currentCampaign.invites.find(i => i.invitee_id === currentBrand.id) || {}

    const integrationItems = IntegrationsMap.map((integration, idx) => (
      <IntegrationItem
        key={integration.key}
        idx={idx}
        currentCampaign={currentCampaign}
        integration={integration}
        espIntegrations={espIntegrations}
        noBoxShadow
      />
    ))

    return (
      <div style={{ paddingTop: '16px' }}>
        <Helmet>
          <title>
            Integrate - DojoMojo
          </title>
        </Helmet>
        <div className={DataIntegrationsTheme.bannerContainer}>
          <img src="images/sales-generated.svg" className={DataIntegrationsTheme.bannerImg}/>
          <P style={{fontSize: 20, lineHeight: '1.4'}}>
            Want to track how your new leads are converting?<br />
            Integrate your e-commerce payment provider to find out.
          </P>
          <Button
            onClick={this.goToDataIntegrations}
            label="Integrate"
            className={ButtonTheme.blueButton}
            theme={ButtonTheme}
            primary
            raised
          />
        </div>
        <Card
          style={{
            width: '100%',
            marginBottom: '16px',
            padding: '16px 20px 24px 20px',
          }}
        >
          <Row style={{ marginBottom: '16px' }}>
            <H3 coral>
              <small>
                Email Service Provider Integration
              </small>
            </H3>
          </Row>
          <Row>
            <Col style={{ width: '304px', marginRight: '32px' }}>
              <div style={{ marginBottom: '16px' }}>
                <P multiline>
                  {'Save time, keep list quality high and prevent ESP deliverability issues by sending entries directly to your email list. '}
                  <a
                    href="https://help.dojomojo.com/hc/en-us/articles/115001349352-Why-ESP-Integration-is-Key-to-a-Successful-Sweepstakes"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Learn more about why this is important.
                  </a>
                </P>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <P multiline>
                  {"If you use DojoMojo's Email Filtering, the actual number of emails added to your list and the number of new to file emails shown in your Campaign Analytics may differ slightly. This is because our data hygiene and verification service removes potentially risky email addresses from the list that is shared with you. This service is powered by Webbula, the industry leader in email verification and hygiene support."}
                </P>
              </div>
              <Button
                onClick={this.downloadEntries}
                label="Download Entries"
                className={ButtonTheme.blueButton}
                theme={ButtonTheme}
                primary
                raised
              />
            </Col>
            <Col style={{ width: '776px' }}>
              {integrationItems}
            </Col>
          </Row>
        </Card>

        <Card style={{ width: '100%', padding: '16px 20px 24px 20px' }}>
          <Row style={{ marginBottom: '16px' }}>
            <H3 coral>
              <small>
                Email Filtering Preferences
              </small>
            </H3>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '16px' }}>
              <P multiline>
                {`You can turn off Webbula's filtering here. This may impact your list's overall deliverability. This setting will only be applied to this campaign, ${currentCampaign.name}. This setting cannot be changed once the campaign has started.`}
              </P>
            </div>
            <P multiline>
              {'You can always change your default filtering setting by editing it in '}
              <a onClick={this.goToBrandSettings}>Brand Settings</a>
              {'.'}
            </P>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: '-4px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Switch
                onChange={invite.filterWebbula ? this.toggleWarningModal : this.toggleFilter}
                checked={invite.filterWebbula}
                theme={SwitchTheme}
                disabled={currentCampaign.started}
              />
              <span className={SwitchTheme.labelTextCampaign}>
                <em>
                  {'Filters '}
                  {invite.filterWebbula ? 'On: ' : 'Off: '}
                </em>
                {invite.filterWebbula ? 'Recommended - remove highest level of security threats.' : 'Switch off Webbula for no filters and no added security.'}
              </span>
            </div>
          </Row>
        </Card>

        <IntegrationSetup />

        {/* Warning Modal */}
        <Dialog active={warningModalOpen}
          onOverlayClick={ this.toggleWarningModal }
          onEscKeyDown={ this.toggleWarningModal }
          theme={ DialogTheme }
          title={ 'Turn Off Filters?' }
        >
          <div style={{
            background: COLOURS.coral,
            marginLeft: '-32px',
            marginRight: '-32px',
            paddingTop: '42px',
            paddingBottom: '42px',
            textAlign: 'center'
          }}>
            <img src="images/error-message-icon@3x.png" style={{
              width: '50%'
            }}/>
          </div>
          <P style={{
            fontSize: '16px',
            marginTop: '24px',
            marginBottom: '24px'
          }}>
            Are you sure you want to turn off Webbula filters? Turning off
            filters may increase the risk of impacting your list's
            deliverability, and the likelihood of IP damage and high-risk fraud
            emails.
          </P>
          <ModalButtonGroup confirm={ this.toggleFilter }
            cancel={ this.toggleWarningModal }
            confirmText={ 'Turn Off Filters' }
            cancelText={ 'Cancel' }
            canSave
            hideLine
          />
        </Dialog>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailDelivery))
