import React, { PureComponent } from 'react'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H2, P } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const transitions = {
  title: props => ({
    marginBottom: props.subtitle ? '12px' : '24px',
  }),
  subtitle: {
    marginBottom: '16px',
    display: 'block',
  },
}
class MyPartnershipsHeader extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    learnMore: PropTypes.string,
    action: PropTypes.func,
    actionLabel: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    subtitle: '',
    learnMore: '',
    action() {},
    actionLabel: '',
    style: {},
  }

  render() {
    const {
      classes: css,
      title,
      subtitle,
      learnMore,
      action,
      actionLabel,
      style,
    } = this.props

    return (
      <Row style={{ ...style }}>
        <Col xs={12}>
          <div style={{ display: 'inline-block' }}>
            <div className={css.title}>
              <H2 azure>
                { title }
              </H2>
            </div>
            {
              subtitle
                ? (
                  <div className={css.subtitle}>
                    <P multiline>
                      { subtitle }
                      { learnMore ? <a href={learnMore} target="_blank">&nbsp;Learn More.</a> : null }
                    </P>
                  </div>
                ) : null
            }
          </div>
          {
          action
            ? (
              <Button
                onClick={action}
                label={actionLabel}
                disabled={title === 'Media Market'}
                className={title === 'Media Market' ? `${ButtonTheme.disabledButton} ${ButtonTheme.onRightHeader}` : `${ButtonTheme.greenButton} ${ButtonTheme.onRightHeader}`}
                theme={ButtonTheme}
                raised
              />
            ) : null
        }
        </Col>
      </Row>
    )
  }
}

export default injectSheet(transitions)(MyPartnershipsHeader)
