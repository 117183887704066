import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { H5 } from 'visual-components/util/texts'

class PercentDisplay extends Component {
  static propTypes = {
    percentage: PropTypes.number.isRequired,
    live: PropTypes.bool,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    let {
      percentage,
      live,
    } = this.props

    percentage = Math.ceil(percentage)

    return (
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'top',
          paddingTop: '3px',
          paddingLeft: '8px',
          width: '35px',
        }}
      >
        <H5 white={live}>
          { `${percentage}%` }
        </H5>
      </div>
    )
  }
}

export default PercentDisplay
