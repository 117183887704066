import React, { Component } from 'react'
import moment from 'moment'
import {
  object,
  bool,
  func,
} from 'prop-types'
import cn from 'classnames'
import { Card } from 'react-toolbox/lib/card'
import { H3, P } from 'visual-components/util/texts'
import MobileMarketingContainer from 'visual-components/mobile-marketing/MobileMarketingContainer'
import CustomCta from 'visual-components/customCta/customCta'
import SocialTheme from 'css/themes/SocialGrowth.css'
import PartnershipInvitesDispatcher from 'dispatchers/partnership-invites-dispatcher'
import { connect } from 'react-redux'
import d3 from 'd3'
import { getClaim } from 'actions/brand-claim-actions'
import ConversionActionCard from './conversion-action-card'
import ThankYouOnlyTab from './thank-you-only-tab'

class ConversionAction extends Component {
  static propTypes = {
    currentBrand: object.isRequired,
    sms: object.isRequired,
    currentCampaign: object.isRequired,
    ctaModel: object.isRequired,
    partnerSplitTrafficStatus: bool.isRequired,
    partnerBannerStatus: bool.isRequired,
    setIsSmsTrafficPublished: func.isRequired,
    setSelectedAction: func.isRequired,
    setMobileMarketingState: func.isRequired,
    isSmsTrafficPublished: bool.isRequired,
  }

  state = {
    selectedAction: 'none',
  }

  setSelectedAction = action => {
    const {
      setSelectedAction: setParentSelectedAction,
    } = this.props

    setParentSelectedAction(action)
    this.setState({ selectedAction: action })
  }

  componentDidMount = () => {
    const {
      currentBrand: { id, belt, trial_end },
      currentCampaign: { invites, hostBrandId },
      isHost,
    } = this.props

    const isTrial = trial_end
      && moment(trial_end)
        .startOf('day')
        .isAfter(moment())
    const invite = invites.find(invite => invite.invitee_id === id)
    const lockCustomCTA = !isTrial && !getClaim('sweepstakes.customCta.create')
    if (invite.ctaType !== 'mobileMarketing' && lockCustomCTA) { // they have custom cta disabled
      this.setSelectedAction('none')
    } else {
      this.setSelectedAction(
        ['none', 'mobileMarketing'].includes(invite.ctaType) ? invite.ctaType : 'customCta'
      )
    }
  }

  selectOption = option => {
    const {
      ctaModel: { ctaType },
    } = this.props

    this.setSelectedAction(option)
    if (option === 'customCta') {
      option = ctaType !== 'mobileMarketing' ? ctaType : 'button'
    }
    PartnershipInvitesDispatcher.updateAttrPersist('ctaType', option)
  }

  render() {
    const {
      currentBrand: {
        belt, trial_end, features, id: brandId,
      },
      currentCampaign: { isSolo, invites },
      isHost,
      partnerSplitTrafficStatus,
      partnerBannerStatus,
      setIsSmsTrafficPublished,
      setMobileMarketingState,
      sms: { brandSmsBank },
      isSmsTrafficPublished,
    } = this.props

    const { split_traffic_invite_status: splitTrafficStatus } = invites.find(
      brand => brand.invitee_id === brandId
    )

    const { selectedAction } = this.state

    const isTrial = trial_end
      && moment(trial_end)
        .startOf('day')
        .isAfter(moment())

    const freeMessages = brandSmsBank && brandSmsBank.credits.info.free[belt === 'black' && !isTrial ? 'black' : 'trial']
    const showInfoText = !(brandSmsBank && brandSmsBank.credits.redeemed)

    const canViewSms = !features.block_sms
    const lockCustomCTA = !isTrial && !getClaim('sweepstakes.customCta.create')
    const getIconImage = status => {
      if (isSmsTrafficPublished) return null
      if (status === 'partnerBannerRequested') {
        return '/images/icons/green-triangle-exclamation.svg'
      }
      if (status === 'partnerBannerInactive') {
        return '/images/icons/turmeric-triangle-exclamation.svg'
      }
      return null
    }

    const renderMobileMarketingCard = () => (
      <ConversionActionCard
        name="mobileMarketing"
        title="SMS Marketing (Recommended)"
        isSelected={selectedAction === 'mobileMarketing'}
        selectOption={this.selectOption}
      >
        <P multiline>
          Drive conversions by reaching out to entrants using SMS Marketing.
          {
            showInfoText && !!freeMessages
              ? <em>{` Your first ${d3.format(',')(freeMessages)} credits FREE!`}</em>
              : null
          }
          <a
            href="https://help.dojomojo.com/hc/en-us/categories/360002347972-SMS-Marketing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Learn More
          </a>
        </P>
      </ConversionActionCard>
    )

    const renderCustomCtaCard = () => (
      <ConversionActionCard
        name="customCta"
        title="Custom Call-to-Action"
        isSelected={!['none', 'mobileMarketing'].includes(selectedAction)}
        selectOption={this.selectOption}
        lock={lockCustomCTA}
      >
        <P multiline>
          Build brand awareness and drive traffic to your website with a Custom CTA.
          <a
            href="https://help.dojomojo.com/hc/en-us/articles/115002788511-What-s-a-Custom-CTA-"
            target="_blank"
            rel="noreferrer noopener"
          >
            {' '}
            Learn More
          </a>
        </P>
      </ConversionActionCard>
    )

    const renderThankYouCard = () => (
      <ConversionActionCard
        name="none"
        title="Thank You Only"
        isSelected={selectedAction === 'none'}
        selectOption={this.selectOption}
      >
        <P multiline>
          Don&apos;t want to take advantage of this section?
          <br />
          Just say thank you to the entrants.
        </P>
      </ConversionActionCard>
    )

    const renderPartnershipCards = () => (
      <>
        {canViewSms && renderMobileMarketingCard()}
        {renderCustomCtaCard()}
        {isHost && renderThankYouCard()}
      </>
    )

    const renderSoloCards = () => (
      <>
        {isHost && renderThankYouCard()}
        {selectedAction === 'mobileMarketing' && canViewSms && renderMobileMarketingCard()}
        {renderCustomCtaCard()}
      </>
    )

    return (
      <Card id="conversion-action" className={SocialTheme.conversionActionContainer}>
        <div className={cn(SocialTheme.headerContainer, { [SocialTheme.headerContainerWithIcon]: ['partnerBannerRequested', 'partnerBannerInactive'].includes(partnerBannerStatus) })}>
          <H3 coral>
            <small>Conversion Action</small>
          </H3>
          <img src={getIconImage(partnerBannerStatus)} height="20" />
        </div>
        <div className={SocialTheme.conversionSelectorBoxes}>
          {isSolo ? renderSoloCards() : renderPartnershipCards()}
        </div>
        {selectedAction === 'none' ? (
          <ThankYouOnlyTab />
        ) : selectedAction === 'mobileMarketing' ? (
          <MobileMarketingContainer
            isHost={isHost}
            partnerSplitTrafficStatus={partnerSplitTrafficStatus}
            splitTrafficStatus={splitTrafficStatus}
            setIsSmsTrafficPublished={setIsSmsTrafficPublished}
            setMobileMarketingState={setMobileMarketingState}
          />
        ) : <CustomCta isHost />}
      </Card>
    )
  }
}

const stateToProps = ({ ctaModel }) => ({ ctaModel })

export default connect(stateToProps)(ConversionAction)
