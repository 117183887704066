import React from 'react'
import COLOURS from 'util/colours'
import PrimaryButton from 'util/components/buttons/primary-button'
import SecondaryButton from 'util/components/buttons/secondary-button'
import { H6 } from 'visual-components/util/texts'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

const styles = {
  container: {
    position: 'relative',
    '&:hover .hover-container': {
      opacity: 1,
    },
  },
  hoverContainer: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 20px)',
    backgroundColor: 'rgba(255,255,255,.95)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity .2s',
  },
  image: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    left: '0',
  },
  name: {
    textAlign: 'left',
    marginTop: 16,
  },
  button: {
    marginBottom: 27,
  },
  disabledButton: {
    background: COLOURS.cloudGrey,
    color: '#fff !important',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 20,
    width: 'calc(100% - 41px)',
    height: 'calc(100% - 28px)',
  },
}

class TemplateOption extends React.Component {
  static propTypes = {
    select: propTypes.func.isRequired,
    version: propTypes.string.isRequired,
    classes: propTypes.object.isRequired,
    img: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    requiresUpgrade: propTypes.bool.isRequired,
    premium: propTypes.bool.isRequired,
    video: propTypes.string,
  }

  static defaultProps = {
    video: null,
  }

  select = () => {
    const {
      select,
      version,
    } = this.props

    select(version)
  }

  renderHover = () => {
    const {
      classes: css,
      version,
      premium,
      requiresUpgrade,
    } = this.props

    const previewLink = `/rendered_templates/${version}.html`

    const upgradeNeeded = premium && requiresUpgrade

    return (
      <div
        className={cn(css.hoverContainer, 'hover-container')}
      >
        <div>
          <Button
            onClick={this.select}
            theme={buttonTheme}
            className={cn({
              [buttonTheme.blueButton]: true,
              [css.button]: true,
              [css.disabledButton]: true,
            })}
            disabled={upgradeNeeded}
          >
            Select Template
          </Button>
        </div>
        <div>
          <a
            href={`${previewLink}${upgradeNeeded ? '#upgrade' : ''}`}
            target="_blank"
          >
            Preview Template
          </a>
        </div>
      </div>
    )
  }

  render() {
    const {
      classes: css,
      img,
      name,
      video,
    } = this.props

    return (
      <div
        className={css.container}
      >
        {
          video
          && (
            <video
              className={css.video}
              autoPlay
              loop
              muted
            >
              <source src={video} type="video/mp4" />
            </video>
          )
        }
        <img
          className={cn({
            [css.image]: true,
          })}
          src={img}
        />
        <div className={css.name}>
          <H6>
            {name}
          </H6>
        </div>
        {this.renderHover()}
      </div>
    )
  }
}

export default injectSheet(styles)(TemplateOption)
