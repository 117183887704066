import AppDispatcher from 'dispatchers/app-dispatcher'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'
// dispatches action to load conversations for current user
import _ from 'lodash'

const loadBrand = function (id) {
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'brand',
    data: {
      id,
      error: null,
      loaded: false,
      interestLoaded: false,
    },
  })
  AppDispatcher.http({
    url: `/brand-degree/${id}`,
    success(degree) {
      const textMap = {
        _1st: 'You’ve partnered with this brand before and may have already acquired much of their audience.',
        _2nd: 'It’s possible their audience may overlap with yours - you’ve partnered with someone who has worked with them.',
        _3rd: 'Go for it! This brand isn’t connected to you and their audience will be full of new emails'
      }
      const degreeText = `Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. <br /><br /> <b>${degree} Degree</b>: ${textMap['_' + degree]}`

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brand',
        data: {
          degreeOfRemoval: degree,
          degreeOfRemovalText: degreeText,
        },
      })
    }
  })

  AppDispatcher.http({
    url: `/partnerships-for-brand/${id}`,
    success(invites) {
      const {
        receivedInvitations,
        receivedApplications,
      } = store.getState().conversations

      store.dispatch({
        type: 'LOAD_PARTNERSHIPS',
        model: 'brand',
        data: {
          partnerships: invites,
        },
      })
    },
  })

  AppDispatcher.http({
    url: `/brands/${id}`,
    success(data) {
      let web = data.website
      if (web.indexOf('http') === -1) {
        web = `http://${web}`
      }
      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'brand',
        data: _.extend(data, {
          loaded: true,
          website: web,
        }),
      })
    },
    error(err) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brand',
        data: {
          error: err,
          loaded: true,
        },
      })
    },
  })

  AppDispatcher.http({
    url: `/available-campaigns/${id}`,
    method: 'GET',
    success(results) {
      const items = results[0]

      const campaignInvites = _.groupBy(items, 'campaign_id')

      const campaigns = []

      _.each(campaignInvites, (invites, campaign_id) => {
        const hostInvite = _.remove(invites, { invitee_id: invites[0].hostBrandId })[0]

        let campaign = invites[0] || hostInvite

        _.each(invites, invite => {
          invite.invitee = _.extend({}, invite, {
            id: invite.invitee_id,
          })
        })

        // Accepted partners have priority
        const partners = invites.filter(partner => partner.status === 'accepted')

        // If less than 8 accepted partners, and there are invites with agreements created, add them to the list
        invites.forEach(invite => {
          if (partners.length < 8) { // still less than 8 and fit criteria(accepted already in list)
            if (invite.status === 'interested' && invite.agreement) {
              partners.push(invite)
            }
          }
        })

        campaign = _.extend({}, campaign, {
          hostBrand: {
            id: hostInvite.invitee_id,
            accountname: hostInvite.accountname,
            logo: hostInvite.logo,
          },
          hostBrandLogo: hostInvite.logo,
          partners,
          id: campaign.campaign_id,
          estimatedEntries: partners.reduce((acc, partner) => {
            const estEntries = partner.averageSignUpsPerCampaign ? partner.averageSignUpsPerCampaign : parseInt(partner.dedicatedListSize * 0.01)
            return acc + estEntries
          },0) + (hostInvite.averageSignUpsPerCampaign ? hostInvite.averageSignUpsPerCampaign : parseInt(hostInvite.dedicatedListSize * 0.01))
        })
        campaigns.push(campaign)
      })

      store.dispatch({
        type: 'UPDATE_ARR',
        model: 'brand',
        data: {
          availableCampaigns: campaigns,
        }
      })
    }
  })
}

const loadPartnershipInterests = function (id) {
  AppDispatcher.http({
    url: `/brands/interests/${id}`,
    method: 'GET',
    success(results) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brand',
        data: {
          partnershipInterests: results,
          interestLoaded: true,
        },
      })
    },
  })
}

const setBrand = function (brand) {
  store.dispatch({
    type: 'UPDATE_ARR',
    model: 'brandInDetail',
    data: {
      availableCampaigns: [],
      partnerships: [],
      currentTab: 'details',
    },
  })
  AppDispatcher.http({
    url: `/partnerships-for-brand/${brand.id}`,
    success(invites) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brandInDetail',
        data: {
          partnerships: invites,
        },
      })
    },
  })
  AppDispatcher.http({
    data: {
      query: {
        match: {
          hostBrandId: brand.id,
        },
      },
    },
    url: '/search-campaigns',
    method: 'POST',
    success(results) {
      results = _.compact(_.map(results.hits.hits, result => {
        if (result._source.hostBrandId != brand.id) {
          return null
        }

        return result._source
      }))
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brandInDetail',
        data: {
          availableCampaigns: results,
        },
      })
    },
  })
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'brandInDetail',
    data: _.extend(brand,{
      expanded: true
    })
  });
};

const contentSample = {
  load: id => ({
    http: {
      url: `brands/content-samples/${id}`,
    },
    types: [
      'BRAND_CONTENT_SAMPLE_LOAD',
      'BRAND_CONTENT_SAMPLE_LOAD_SUCCESS',
      'BRAND_CONTENT_SAMPLE_LOAD_FAILURE',
    ],
  }),
  upload: (data, files) => ({
    http: {
      url: 'brands/content-samples',
      method: 'POST',
      data: {
        label: data,
      },
      files,
    },
    types: [
      'BRAND_CONTENT_SAMPLE_UPLOAD',
      'BRAND_CONTENT_SAMPLE_UPLOAD_SUCCESS',
      'BRAND_CONTENT_SAMPLE_UPLOAD_FAILURE',
    ],
  }),
  delete: id => ({
    http: {
      url: `brands/content-samples/${id}`,
      method: 'DELETE',
    },
    types: [
      'BRAND_CONTENT_SAMPLE_DELETE',
      'BRAND_CONTENT_SAMPLE_DELETE_SUCCESS',
      'BRAND_CONTENT_SAMPLE_DELETE_FAILURE',
    ],
  }),
  update: data => ({
    http: {
      url: `brands/content-samples/${data.id}`,
      method: 'PUT',
      data,
    },
    types: [
      'BRAND_CONTENT_SAMPLE_UPDATE',
      'BRAND_CONTENT_SAMPLE_UPDATE_SUCCESS',
      'BRAND_CONTENT_SAMPLE_UPDATE_FAILURE',
    ],
  }),
  clear: () => ({
    type: 'BRAND_CONTENT_SAMPLE_CLEAR',
  }),
  setUpload: file => ({
    type: 'BRAND_CONTENT_SET_FILE_UPLOAD',
    data: file,
  }),
  resetUpload: () => ({ type: 'BRAND_CONTENT_RESET_FILE_UPLOAD' }),
}
  

const sellerProfile = {
  load: id => ({
    http: {
      url: `brands/seller-profile/${id}`,
    },
    types: [
      'BRAND_SELLER_PROFILE_LOAD',
      'BRAND_SELLER_PROFILE_LOAD_SUCCESS',
      'BRAND_SELLER_PROFILE_LOAD_FAILURE',
    ],
  }),
  update: data => ({
    http: {
      url: 'brands/seller-profile/',
      method: 'PUT',
      data,
    },
    types: [
      'BRAND_SELLER_PROFILE_UPDATE',
      'BRAND_SELLER_PROFILE_UPDATE_SUCCESS',
      'BRAND_SELLER_PROFILE_UPDATE_FAILURE',
    ],
  }),
  clear: () => ({
    type: 'BRAND_SELLER_PROFILE_CLEAR',
  }),
}

   

export default {
  setBrand,
  loadBrand,
  loadPartnershipInterests,
  contentSample,
  sellerProfile,
}
