import React from 'react'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function ShortUrlWarningModal() {
  const {
    showModal, modals, saveChanges, currentCampaign, publishPostEntry,
  } = React.useContext(MobileMarketingContext)

  const isPublish = localStorage.getItem(`smsPostEntryPublishAction_${currentCampaign.id}`)

  const onUpdateLink = () => showModal('showUrlWarning', false)
  const onIgnoreWarning = () => {
    localStorage.setItem(`smsPostEntryIgnoreWarning_${currentCampaign.id}`, 'true')
    showModal('showUrlWarning', false)
    if (isPublish) {
      localStorage.removeItem(`smsPostEntryPublishAction_${currentCampaign.id}`)
      onUpdateLink() // close modal
      publishPostEntry() // publish
    } else saveChanges({ isConfirmed: true })
  }

  return (
    <SimpleInfoModal
      title="Oops – it looks like you used an URL shortener! "
      active={modals.showUrlWarning}
    >
      <H4 multiline>
        Links shorted by a third-party service–such as Bitly, TinyURL,
        or Rebrandly-risk being blocked, increase your chance of being
        marked as spam, and interrupt your campaign&apos;s reporting and attribution!
        <br />
        <br />
        DojoMojo automatically shortens links for you. Paste the original
        link in the editor, and see how it&apos;s trimmed in the text preview.
      </H4>
      <ModalButtonGroup
        canSave
        confirm={onUpdateLink}
        cancel={onIgnoreWarning}
        confirmText="Update Link"
        cancelText="Ignore"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(ShortUrlWarningModal)
