import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'

import COLOURS from 'util/colours'

import buttonTheme from 'css/themes/Buttons.css'
import smsTheme from 'css/themes/sms-marketing.css'
import { MobileMarketingContext } from '../MobileMarketingContext'

const styles = {
  followUp: {
    '&:hover': {
      backgroundColor: `${COLOURS.cloudGrey} !important`,
    },
  },
}

function DeleteDoneBtns(props) {
  const { classes: css, message, isFollowup } = props

  const {
    setDeleteID,
    saveChanges,
  } = React.useContext(MobileMarketingContext)

  return (
    <>
      {
        isFollowup && (
          <Button
            style={{ width: 'auto' }}
            onClick={() => setDeleteID(message.id)}
            className={cn(
              css.followUp,
              buttonTheme.redText,
              buttonTheme.button,
              buttonTheme.raised,
              smsTheme.whiteButton
            )}
          >
            Delete Message
          </Button>
        )
      }
      <Button
        style={{ width: 'auto' }}
        onClick={saveChanges} // closes editing
        className={cn(buttonTheme.publishButton, buttonTheme.raised)}
      >
        Save
      </Button>
    </>
  )
}

DeleteDoneBtns.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isFollowup: PropTypes.bool,
}

DeleteDoneBtns.defaultProps = {
  isFollowup: false,
}

export default React.memo(injectCSS(styles)(DeleteDoneBtns))
