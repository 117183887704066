import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Dialog from 'react-toolbox/lib/dialog';

import { H4, P } from 'visual-components/util/texts';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import dialogTheme from 'css/themes/dialogs/formModal.css';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import InputContainer from 'visual-components/util/InputContainer';
import { Input } from 'react-toolbox/lib/input';
import inputTheme from 'css/themes/no-padding-input.css';
import COLOURS from 'util/colours';

export default class SignAgreementModal extends Component {
  state = {
    name: '',
    title: '',
  };

  render() {
    const { closeModal, active, currentBrandName, confirm } = this.props;
    return (
      <Dialog
        theme={dialogTheme}
        active={active}
        onEscKeyDown={closeModal}
        onOverlayClick={closeModal}
        title="Sign Contract"
      >
        <Grid>
          <P>
            Once you have signed this contract, it cannot be edited further.
          </P>
          <Row style={{ marginTop: 16 }}>
            <Col xs={6}>
              <Row>
                <InputContainer label="Type your legal name to complete signature">
                  <Input
                    placeholder="First Name Last Name"
                    type="text"
                    name="name"
                    theme={inputTheme}
                    value={this.state.name}
                    onChange={value => this.setState({ name: value })}
                  />
                </InputContainer>
              </Row>
              <Row style={{ marginTop: 32 }}>
                <InputContainer label="Title" style={{ width: '277px' }}>
                  <Input
                    placeholder="Marketing Specialist"
                    type="text"
                    name="title"
                    theme={inputTheme}
                    value={this.state.title}
                    onChange={value => this.setState({ title: value })}
                  />
                </InputContainer>
              </Row>
            </Col>
            <Col xs={6}>
              <InputContainer label="Signature Preview">
                <div
                  style={{
                    border: `1px solid ${COLOURS.silver}`,
                    borderRadius: '2px',
                    padding: '36px 16px 16px',
                  }}
                >
                  <span style={{ fontFamily: 'Arizonia', fontSize: '20px' }}>
                    {this.state.name || <span>&nbsp;</span>}
                  </span>
                  <hr style={{ marginTop: '8px' }}/>
                  <div
                    style={{
                      fontSize: '12px',
                      fontFamily: 'Larsseit-Medium',
                      color: COLOURS.ink,
                    }}
                  >
                    {this.state.name || 'Name'}
                  </div>
                  <div style={{ fontSize: '12px', fontFamily: 'Larsseit' }}>
                    {this.state.title || 'Title'}
                  </div>
                </div>
              </InputContainer>
            </Col>
          </Row>
          <Row style={{ marginTop: 32, marginBottom: 32 }}>
            <P>
              I, {this.state.name || '{{name}}'}, {this.state.title || '{{title}}'} at {currentBrandName}, certify
              that I have read the contract, and understand that clicking ‘SIGN
              AGREEMENT’ constitutes a legally binding signature.
            </P>
          </Row>
        </Grid>
        <ModalButtonGroup
          canSave={this.state.name && this.state.title}
          confirm={() => confirm(this.state.name, this.state.title)}
          cancel={closeModal}
          cancelText="Cancel"
          confirmText="Sign Agreement"
        />
      </Dialog>
    );
  }
}
