import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { object, bool, number } from 'prop-types'
import moment from 'moment-timezone'
import d3 from 'd3'
import { Card } from 'react-toolbox/lib/card'
import { string } from 'prop-types'
import { H4, Small, Tiny } from 'visual-components/util/texts'
import Badge from 'visual-components/util/badge'
import displayTypeMap from 'util/deal-display-type-map'
import OfferMessageTheme from 'css/themes/media-market/OfferMessage.css'
import ButtonGroup from './offerMessageButtonGroup'
import IOMessage from './assetVisuals/IOMessage'

const userTimezone = moment.tz.guess()
const offset = moment.tz(userTimezone).utcOffset()

const stateToProps = ({
  deals: {
    deal: {
      selling_brand_id,
    },
  },
  deals,
  currentBrand: {
    id: brandId,
  },
}) => ({
  deals,
  brandId,
  isSeller: selling_brand_id === brandId,
})

class ProposalMessage extends PureComponent {
  static propTypes = {
    item: object.isRequired,
    utilityFunctions: object.isRequired,
    statusMap: object.isRequired,
    deals: object.isRequired,
    isSender: bool.isRequired,
    isSeller: bool.isRequired,
    brandId: number.isRequired,
  }

  static contextTypes = {
    impersonating: string,
  }

  render() {
    const {
      item: {
        id,
        expiration_date,
        start_date,
        end_date,
        max_quantity,
        min_quantity,
        pretty_type,
        status,
        cost_type,
        expected_views,
        price,
        deposit_percentage,
        current,
        isCounterOffer,
        insertion_order_id,
      },
      deals: {
        can_change_dates,
      },
      isSeller,
      utilityFunctions,
      statusMap,
      isSender,
      brandId,
    } = this.props

    const { impersonating } = this.context

    const showFooterActionButtons = ['pending'].includes(status) && current
    const timeRemaining = moment(expiration_date).fromNow(true)

    // give priority to footer actions
    const showChangeDatesButton = !showFooterActionButtons
      && ['accepted', 'live'].includes(status)
      && moment(end_date).isAfter()
      && current
      && isSeller
      && can_change_dates

    const themes = {
      mainTheme: isSender ? OfferMessageTheme.senderMain
        : OfferMessageTheme.receiverMain,
      footerTheme: isSender ? OfferMessageTheme.senderFooter
        : OfferMessageTheme.receiverFooter,
    }

    return (
      <>
        <Card className={themes.mainTheme}>
          <div className={OfferMessageTheme.container}>
            <div className={OfferMessageTheme.statusBadge}>
              {
                status !== 'pending'
                && current
                && (
                  <Badge
                    label={status && statusMap[status].label}
                    color={status && statusMap[status].color}
                  />
                )
              }
            </div>

            <div>
              <H4><em>{pretty_type}</em></H4>
            </div>

            <div className={`${OfferMessageTheme.displayFlex} ${OfferMessageTheme.costDateGroup}`}>
              <div className={`${OfferMessageTheme.displayFlex} ${OfferMessageTheme.costType}`}>
                <img src="images/clock-icon@2x.png" className={OfferMessageTheme.icon} />
                <div className={OfferMessageTheme.costDateText}>
                  <Small>{`${moment(start_date).format('MMM D, YYYY')} - ${moment(end_date).format('MMM D, YYYY')}`}</Small>
                </div>
              </div>

              <div className={`${OfferMessageTheme.displayFlex} ${OfferMessageTheme.costType}`}>
                <img src="images/dollar-bill@2x.png" className={OfferMessageTheme.icon} />
                <div className={OfferMessageTheme.costDateText}>
                  <Small>{displayTypeMap[cost_type] || cost_type}</Small>
                </div>
              </div>

            </div>

            { cost_type === 'CPC'
              && (
                <div className={`${OfferMessageTheme.statsGroup} ${OfferMessageTheme.displayFlex}`}>
                  <div className={OfferMessageTheme.cpcStat}>
                    <Small>CPC</Small>
                    <div className={OfferMessageTheme.stats}>
                      <Small><em>{d3.format('$,.2f')(price)}</em></Small>
                    </div>
                  </div>

                  <div className={OfferMessageTheme.maxValueStat}>
                    <Small>Max Value</Small>
                    <div className={OfferMessageTheme.stats}>
                      <Small><em>{d3.format('$,.2f')(price * max_quantity)}</em></Small>
                    </div>
                  </div>

                  <div>
                    <Small>Deal Click Range</Small>
                    <div className={OfferMessageTheme.stats}>
                      <Small>
                        <em>
                          {`${d3.format(',')(min_quantity)} - ${d3.format(',')(max_quantity)}`}
                        </em>
                      </Small>
                    </div>
                  </div>
                </div>
              )
            }

            { cost_type === 'Fixed'
              && (
                <div className={`${OfferMessageTheme.statsGroup} ${OfferMessageTheme.displayFlex}`}>
                  <div className={OfferMessageTheme.expectedViewsStat}>
                    <Small>Expected Reach</Small>
                    <div className={OfferMessageTheme.stats}>
                      <Small><em>{d3.format(',')(expected_views)}</em></Small>
                    </div>
                  </div>

                  <div className={OfferMessageTheme.expectedViewsStat}>
                    <Small>Total Price</Small>
                    <div className={OfferMessageTheme.stats}>
                      <Small><em>{d3.format('$,.2f')(price)}</em></Small>
                    </div>
                  </div>

                  <div>
                    <Small>Deposit %</Small>
                    <div className={OfferMessageTheme.stats}>
                      <Small>
                        <em>
                          {`${deposit_percentage}%`}
                        </em>
                      </Small>
                    </div>
                  </div>
                </div>
              )
            }
          </div>

          {
            showFooterActionButtons
            && (
              <div className={`${OfferMessageTheme.actionFooter} ${themes.footerTheme}`}>
                <div className={OfferMessageTheme.footerInner}>
                  <Tiny alertRed={timeRemaining.includes('hr')}>
                    <em>
                      {`${timeRemaining} left`}
                    </em>
                  </Tiny>
                  <ButtonGroup
                    utilityFunctions={utilityFunctions}
                    isSender={isSender}
                    isCounterOffer={isCounterOffer}
                  />
                </div>
              </div>
            )
          }
          {
            showChangeDatesButton
              ? (
                <div className={`${OfferMessageTheme.actionFooter} ${themes.footerTheme}`}>
                  <div className={`${OfferMessageTheme.footerInner} ${OfferMessageTheme.alignRight}`}>
                    <ButtonGroup changeDates isSender={isSeller} />
                  </div>
                </div>
              ) : null
          }
        </Card>
        <div className={OfferMessageTheme.smallVerticalSeparator} />
        <Card className={themes.mainTheme}>
          <div className={OfferMessageTheme.basicContainer}>
            <Small>Insertion Order attached below.</Small>
          </div>
        </Card>
        <div className={OfferMessageTheme.smallVerticalSeparator} />
        <IOMessage
          isSender={isSender}
          insertion_order_id={insertion_order_id}
          ioPath={`invoice/io/${brandId}/${offset}/${id}/${insertion_order_id}${impersonating ? `?impersonating=${impersonating}` : ''}`}
          invalid={!current}
        />
      </>
    )
  }
}

export default connect(stateToProps)(ProposalMessage)
