import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { string } from 'prop-types'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import moment from 'moment-timezone'

import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import ProfileDispatcher from 'dispatchers/profile-dispatcher'
import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'

import { load } from 'actions/deal-actions'
import { bindActionCreators } from 'redux'
import * as metrics from 'util/metrics'

import Spinner from 'util/components/spinner'
import COLOURS from 'util/colours'
import { isTrialing, isDelinquent } from 'util/brand-stripe-belt-helper'

const mapAppState = ({
  currentBrand,
  conversations: { unread },
  customer,
  profile: {
    uid: userId,
    created_at: userCreatedAt,
    email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
    impersonating,
  },
}) => ({ currentBrand, unread, customer, userId, userCreatedAt, userEmail, userFirstName, userLastName, impersonating })

const mapDispatchToProps = dispatch => ({
  loadDeals: () => dispatch(load()),
})

class App extends Component {
  static childContextTypes = {
    brandId: string,
    impersonating: string,
  }

  getChildContext() {
    return {
      brandId: `${this.props.currentBrand.id}`,
      impersonating: this.props.impersonating,
    }
  }

  constructor(props) {
    super(props)

    //**********************************
    //OLD CAMPAIGN BUILDER DEPENDENCIES
    //(needs refactoring possibly later)
    //**********************************
    window.BRAND_COLORS = [
      'red',
      'yellow',
      'orange',
      'blue',
      'green',
      'purple',
      'black',
      'lightPink',
      'brown',
      'graphDarkBlue',
      'alexGreen',
      'graphBabyBlue',
      'graphDarkGreen'
    ];
    window.COLOURS = COLOURS;
    window.React = React;
    window.ReactDOM = ReactDOM;

    //**********************************
    //**********************************
    //**********************************


    BrowserHistoryDispatcher.setHistory(props.history)

    // Redirect on 403
    $(document).ajaxComplete((event, xhr) => {
      if (xhr.status === 403) {
        props.history.replace({
          pathname: '/login',
          state: {
            to: props.location.pathname
          }
        })
      }
    })
  }

  componentDidMount() {
    App.trackView()

    ProfileDispatcher.load(null)

    if (this.checkTerms()) {
      this.checkPaidStatus(this.props.location.pathname, this.props.customer)
    }

    window.onbeforeunload = metrics.sendEventsImmediately
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      customer,
    } = this.props

    // do something every time route changes
    if (location !== nextProps.location || customer !== nextProps.customer) {
      this.checkPaidStatus(nextProps.location.pathname, nextProps.customer)

      window.Appcues && window.Appcues.start()
    }

    if (location !== nextProps.location) {
      App.trackView()
    }
  }


  componentDidUpdate(prevProps) {
    const { currentBrand: { loading, has_accepted_latest_terms }, loadDeals, location, history } = this.props

    // Send GA Pageviews
    if (location.pathname !== prevProps.location.pathname) {
      const page_path = history.createHref(location)
      gtagPageTrack({ page_path })
    }

    if (!this.checkTerms()) return
    // Load deals as soon as current brand is loaded
    // TODO: make this a more skeletal call or perhaps part of /current-brand call
    if (
      (prevProps.currentBrand.loading && !loading && has_accepted_latest_terms)
      || (!prevProps.currentBrand.has_accepted_latest_terms && has_accepted_latest_terms)
    ) {
      loadDeals()
    }
  }

  componentWillUnmount() {
    metrics.sendEventsImmediately()
  }

  checkTerms = () => {
    const { currentBrand, location, history, impersonating } = this.props

    if (!currentBrand.id) return true

    if (!currentBrand.has_accepted_latest_terms && !impersonating && location.pathname !== '/' && !location.pathname.match(/(payment-methods|missing-payment|upgrade|elite-request|switch-brand)/)) {
      history.replace('/')

      return false
    }

    return true
  }

  static trackView() {
    metrics.create('view', {
      url: window.location.href,
    })
  }

  checkPaidStatus(pathname, customer) {
    const { currentBrand, history, impersonating } = this.props
    const dismissedTrialWarningPaywall =
      sessionStorage.getItem('withinTenDaysOfTrialEndPaywallDismissed') === 'true'

    const dismissedPreferredTermsPlan = sessionStorage.getItem('prerferredTermsPlanDismissed') === 'true'

    const extendedTrialModalShown = sessionStorage.getItem('extendedTrialModalShown') === 'true'

    if (currentBrand.isSensei || impersonating) {
      return
    } else if (!pathname.match(/(cs-admin|payment-methods|missing-payment|upgrade|elite-request|switch-brand)/) && customer.loading === false) {

      const trialEndDate = new Date(currentBrand.trial_end);

      const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */
      const remainingDays = Math.round(Math.abs(moment(trialEndDate)._d.getTime() - new Date()) / oneDay)
      const withinTrialModalDays = [7, 3, 2, 1].includes(remainingDays)

      let subscription = customer && customer.subscriptions && customer.subscriptions.data
        && customer.subscriptions.data.length ? customer.subscriptions.data[0] : null

      let source = customer && customer.sources && customer.sources.data
        && customer.sources.data.length ? customer.sources.data[0] : null

      let trialing = isTrialing(currentBrand, customer)
      let delinquent = isDelinquent(currentBrand, customer)
      // If delinquent because bad payment source, redirect to missing payment screen
      if (delinquent && customer.sources.data.length) {
        history.replace('/missing-payment')
      }
      // If brand has a pending preferredTermsPlan
      else if (
        !!currentBrand.pendingPreferredTermsPlan
        && !dismissedPreferredTermsPlan
      ) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'preferredTermsPlanModal',
          data: {
            open: true,
            locked: currentBrand.belt === 'post-trial-paywall' || (!trialing
              && !subscription
              && currentBrand.belt !== 'white'
              && currentBrand.has_accepted_latest_terms),
            plan: currentBrand.pendingPreferredTermsPlan,
          }
        })
      }
      // If brand is in trial and has been given trial extension
      else if (
        currentBrand.extended_trial_end
        && !extendedTrialModalShown // Redis stores as string
      ) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'trialExtendedModal',
          data: {
            open: true,
            hasDefaultSource: !!customer.default_source
          }
        })
      }
      // If brand is done trialing and has no subscription and is not white belt, show trial ended modal
      else if (
        currentBrand.belt === 'post-trial-paywall' || (!trialing
        && !subscription
        && currentBrand.belt !== 'white'
        && currentBrand.has_accepted_latest_terms)
        && currentBrand.payment_method !== 'shopify'
      ) {
        window.location.replace('/app')
      }
      // If delinquent with no sources, send to select plan, will collect payment info upon new signup
      else if (delinquent && !customer.sources.data.length) {
        window.location = '/app/upgrade-plan'
      }
      // Soft paywall shown every time the user logs in, starting 10 days before trial ends
      else if (
        trialing
        && currentBrand.trial_type === 'trial'
        && withinTrialModalDays
        && !dismissedTrialWarningPaywall
        && !customer.sources.data.length
        && currentBrand.has_accepted_latest_terms
      ) {
        window.location.replace('/app')
      }
      // Redirect if not finished setting up brand
      else if (currentBrand.id && !currentBrand.dedicatedListSize) {
        window.location.replace('/app')
      }
      // if no problems with billing, its safe to show the delighted widget
      else {
        if (moment() > moment(this.props.userCreatedAt).add(30, 'days')) {
          delighted.survey({
            email: this.props.userEmail,
            name: `${this.props.userFirstName} ${this.props.userLastName}`,
            recurringPeriod: false
          });
        }
      }
    }
  }

  render() {
    const {
      location,
      currentBrand,
      children,
      unread,
      userId,
      impersonating,
    } = this.props


    // if URL has brandId parameter, if it doesn't match session or local, redirect
    const { search } = location
    const brandMatch = search.match(/^\?(?:.*&)?brandId=(\d+)(?:&.*|$)/)
    const brandId = brandMatch && brandMatch[1]

    if (brandId && brandId != sessionStorage.getItem(userId) &&
          brandId != localStorage.getItem(userId)) {
      return (
        <Redirect to={{
          pathname: `/switch/${brandId}/${userId}`,
          state: {
            to: location,
          }
        }} />
      )
    }

    if ((currentBrand.loading || currentBrand.featuresLoading) && !module.hot) {
      return ( <Spinner /> )
    }

    const unreadTitle = unread ? ` (${unread})` : ''

    return (
      <div style={{
        backgroundColor: COLOURS.cream,
        marginTop: '64px'
      }}>
        <Helmet
          titleTemplate={`${currentBrand.accountname}${unreadTitle} - %s - DojoMojo`}
          defaultTitle={`${currentBrand.accountname}${unreadTitle} - DojoMojo`}
        />
        { children }
      </div>
    )
  }
}

export default withRouter(connect(mapAppState, mapDispatchToProps)(App))
