import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { withRouter } from 'react-router-dom'
import { H4, H3 } from 'visual-components/util/texts'
import SplitTrafficHostAction from './SplitTrafficHostAction'
import SplitTrafficPartnerAction from './SplitTrafficPartnerAction'


const TRAFFIC_SHARING_BANNER = {
  host: {
    inactive: {
      label: 'Offer to share post entry traffic with interested partners--a great way to encourage high value partners to join!',
      kbLink: 'https://help.dojomojo.com/hc/en-us/articles/360043392691',
    },
    active: {
      label: 'Edit sharing and view partner activity on Post Entry Actions.',
    },
  },
  partner: {
    notInvited: {
      label: 'Enhance your list building! Request traffic from the host and set up your Conversion Action.',
      actions: ['request'],
    },
    invited: {
      label: 'The host has offered your post entry traffic. Accept and set up your Action below.',
      actions: ['accept', 'decline'],
      badge: 'offered',
    },
    partnerRequested: {
      label: 'Your request has been sent to the host. In the meantime, start setting up your Conversion Action.',
      actions: ['requested'],
      badge: 'pending',
    },
    active: {
      label: 'You\'re all set! Your Post Entry traffic is now active.',
      actions: ['message', 'decline'],
      badge: 'shared',
    },
    declined: {
      label: 'Enhance your list building! Request traffic from the host and set up your Conversion Action.',
      actions: ['request'],
      badge: 'declined',
    },
    inactive: {
      label: 'Be sure to finish setting up your Conversion Action to receive your traffic!',
      actions: ['message', 'decline'],
      badge: 'inactive',
    },
  },
}

const styles = {
  wrapper: {
    flex: 1,
    height: 112,
    display: 'flex',
    alignItems: 'center',
  },
  imgWrapper: {
    height: 24,
    width: 24,
    objectFit: 'contain',
    marginRight: 18,
    '& img': {
      width: '100%',
    },
  },
}

function SplitTrafficBanner(props) {
  const {
    classes: css,
    status,
    isHost,
    setIsDashboardOpen,
    setMobileMarketingState,
    isDashboardOpen,
    renderBadges,
    campaign,
    partnerBannerAction,
    mobileMarketingState,
  } = props

  const splitTrafficInfo = TRAFFIC_SHARING_BANNER[isHost ? 'host' : 'partner'][status]

  return (
    <>
      <div className={css.wrapper}>
        <div className={css.imgWrapper}>
          <img src="/images/icons/call-split-24-px.svg" />
        </div>
        <div>
          <H3 multiline>
            <small>
              <b>Split Traffic</b>
            </small>
          </H3>
          <H4 multiline>
            {splitTrafficInfo.label}
            {splitTrafficInfo.kbLink && (
              <>
                <a target="_blank" rel="noopener noreferrer" href={splitTrafficInfo.kbLink}>
                  {' '}
                  Learn More
                </a>
              </>
            )}
          </H4>
        </div>
        {
          isHost
            ? (
              <SplitTrafficHostAction
                setOpen={setIsDashboardOpen}
                open={isDashboardOpen}
                renderBadges={renderBadges}
              />
            )
            : (
              <SplitTrafficPartnerAction
                mobileMarketingState={mobileMarketingState}
                renderBadges={renderBadges}
                setMobileMarketingState={setMobileMarketingState}
                actions={splitTrafficInfo.actions}
                campaign={campaign}
                partnerBannerAction={partnerBannerAction}
              />
            )
        }
      </div>
    </>
  )
}

SplitTrafficBanner.propTypes = {
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isHost: PropTypes.bool,
  setIsDashboardOpen: PropTypes.func.isRequired,
  setMobileMarketingState: PropTypes.func.isRequired,
  isDashboardOpen: PropTypes.bool.isRequired,
  renderBadges: PropTypes.array.isRequired,
  campaign: PropTypes.object.isRequired,
  partnerBannerAction: PropTypes.func.isRequired,
  mobileMarketingState: PropTypes.object.isRequired,
}

SplitTrafficBanner.defaultProps = {
  isHost: false,
}

export default React.memo(injectCSS(styles)(withRouter(SplitTrafficBanner)))
