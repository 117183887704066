import ShowMoreTemplate from 'visual-components/calendar-tasks-popouts/show-more-template.js'

import { Component } from 'react'

import MessagingDispatcher from 'dispatchers/messaging-dispatcher'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'

const mapStateToProps = (state) => {
  return {
    campaignTasks: state.campaignTasks,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
}

class ShowMorePopout extends Component {
  render() {

    const {
      campaignTasks: {
        showMore: {
          shortEvents,
          longEvents,
          show,
          date,
          event
        }
      },
      campaignTaskActions: {
        hideShowMore,
        selectTask,
        selectBrandCampaign
      }
    } = this.props



    return (
      <ShowMoreTemplate
        date={date}
        show={show}
        tasks={shortEvents}
        deselectShowMore={hideShowMore}
        campaigns={longEvents}
        event={event}
        selectTask={selectTask}
        selectCampaign={selectBrandCampaign}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowMorePopout)
