import { rangeDefaults, radioDefaults, checkboxDefaults } from './helpers'
import usRegions from './us-regions'

const partnershipAnalyticsSearch = {
  page: 1,
  size: 16,
  total: 0,
  searchValue: '',
  sortBy: {
    value: 'last_partnered_date',
    asc: false,
    options: [
      {
        value: 'last_partnered_date',
        label: 'Recent Campaigns',
      },
      {
        value: 'partner_brand_name',
        label: 'A-Z',
      },
      {
        value: 'dedicatedListSize',
        label: 'List Size',
      },
      {
        value: 'avg_entries',
        label: 'Avg Entries/Campaign',
      },
      {
        value: 'ntf_perc',
        label: '% NTF',
      },
      {
        value: 'total_entries_driven',
        label: 'Entries Driven',
      },
    ],
    defaultDirections: {
      partner_brand_name: true,
    },
  },
  filtersExpanded: {
    listSize: true,
    avgVisits: true,
    avgEntries: true,
    percentNTF: false,
    recentPartnerships: false,
    householdIncome: false,
    gender: false,
    geoMinAudience: false,
    industry: false,
  },
  listSize: rangeDefaults({ term: 'dedicatedListSize' }),
  avgVisits: rangeDefaults({ term: 'avg_visits' }),
  avgEntries: rangeDefaults({ term: 'avg_entries' }),
  percentNTF: rangeDefaults({ term: 'ntf_perc' }),

  recentPartnerships: radioDefaults({
    term: 'last_partnered_date',
    value: 'all',
    options: [
      {
        label: 'Not in the last 60 days',
        value: '60',
      },
      {
        label: 'Not in the last 90 days',
        value: '90',
      },
      {
        label: 'Not in the last 120 days',
        value: '120',
      },
      {
        label: 'All time',
        value: 'all',
      }
    ],
  }),

  householdIncome: checkboxDefaults({
    term: 'top_income_bracket.keyword',
    labelMap: {
      income_below35k: '< 35K',
      income_35k_49k: '35-49K',
      income_50k_74k: '50-74K',
      income_75k_99k: '75-99K',
      income_above100k: '100K +',
    },
  }),

  gender: 'genderSplitFemale',
  genderSplitFemale: rangeDefaults({
    term: 'female_perc',
    hidden: false,
  }),
  genderSplitMale: rangeDefaults({
    term: 'male_perc',
    hidden: true,
  }),

  // for geo filter
  geoMinAudience: rangeDefaults({
    term: 'state_distribution',
    value: 0,
  }),
  regions: checkboxDefaults({
    term: 'regions',
    options: usRegions,
  }),

  industry: checkboxDefaults({ term: 'industries.keyword' }),

  // other aggregations
  allEntries: { value: 0 },
  allNTF: { value: 0 },
  avgListSize: { value: 0 },

  // grid/list view
  gridView: 'grid',
}

export default partnershipAnalyticsSearch
