import React from 'react'
import { Card } from 'react-toolbox/lib/card'
import { H6, P } from 'visual-components/util/texts'
import Theme from 'css/themes/payments/billing-payment-methods-theme.css'

const mapCreditCards = ({
  customer, editSource, deleteSource, renderCTAButton,
}) => {
  const cards = customer.creditCards
  const defaultCard = customer.default_source

  if (!cards) return null

  return cards.map(item => {
    let cityStateZip = item.address_zip
    if (item.address_city && item.address_state) {
      cityStateZip = `${item.address_city}, ${item.address_state} ${item.address_zip}`
    }

    return (
      <Card className={Theme.paymentMethod__rowItem}>
        <div className={Theme.paymentMethod__rowItem__container}>
          <div className={Theme.paymentMethod__rowItem__dataColumns}>
            <div>
              <div className={`${Theme.paymentMethod__rowItemData__cardName} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
                <H6>Name</H6>
              </div>
              <P>{item.name}</P>
            </div>

            <div>
              <div className={`${Theme.paymentMethod__rowItemData__cardNumber} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
                <H6>Card Number</H6>
              </div>
              <P>{`**** **** **** ${item.last4}`}</P>
            </div>

            <div>
              <div className={`${Theme.paymentMethod__rowItemData__expiry} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
                <H6>Expiry</H6>
              </div>
              <P>{`${item.exp_month}/${item.exp_year}`}</P>
            </div>

            <div>
              <div className={`${Theme.paymentMethod__rowItemData__billingZip} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
                <H6>Billing Zip Code</H6>
              </div>
              <P>{cityStateZip}</P>
            </div>

            <div className={Theme.paymentMethod__rowItemData__verificationCheck}>
              <img src="images/icons/green-verified-checkmark.svg" className={Theme.icon} />
            </div>
          </div>

          <div className={Theme.paymentMethod__rowItem__actionButtons}>
            {
              item.id === defaultCard
                ? <div className={`${Theme.defaultLabel}`}><P>Your Default</P></div>
                : renderCTAButton(item)
            }

            <div className={`${Theme.icon} ${Theme.edit}`} onClick={() => editSource(item)}>
              <img src="images/icons/blue-edit.svg" />
            </div>

            <div
              className={`${Theme.icon} ${Theme.trash} ${item.id === defaultCard ? Theme.hidden : ''}`}
              onClick={() => deleteSource(item)}
            >
              <img src="images/icons/blue-trash.svg" />
            </div>
          </div>
        </div>
      </Card>
    )
  })
}

export default mapCreditCards
