import React, { PureComponent as Component } from 'react'
import {
  string,
  number,
  object,
  func,
  bool,
} from 'prop-types'

import RangeSlider from 'visual-components/util/filters/range-slider'
import FilterBox from './filter-box'

class RangeFilter extends Component {
  static propTypes = {
    title: string.isRequired,
    range: object.isRequired,
    tooltip: string,
    extraStartText: string,
    extraEndText: string,
    name: string.isRequired,
    secondaryStartText: string,
    label: string,
    format: func,
  }

  static defaultProps = {
    tooltip: null,
    extraStartText: '',
    extraEndText: '',
    secondaryStartText: '',
    label: '',
    format: undefined,
  }

  render() {
    const {
      title,
      range,
      tooltip,
      extraStartText,
      extraEndText,
      secondaryStartText,
      name,
      format,
      label,
    } = this.props

    return (
      <FilterBox
        width="184px"
        title={title}
        tooltip={tooltip}
        paddingBot="16px"
        name={name}
      >
        <RangeSlider
          minValue={range.min}
          maxValue={range.max}
          format={format}
          name={name}
          label={label}
          extraStartText={extraStartText}
          extraEndText={extraEndText}
          secondaryStartText={secondaryStartText}
        />
      </FilterBox>
    )
  }
}

export default RangeFilter
