import React, { Component } from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';

import PartnerLogos from './partner-logos';
import CurrentCampaignCardTheme from 'css/themes/CurrentCampaignCard.css';

class ParticipantsLogos extends Component{
  constructor(props) {
    super(props);
  }

  render() {
    const {
      hostBrandImage,
      partnerImages
    } = this.props;

    const numberOfImages = 8;

    // remove hostBrand image from partnerImages
    if (partnerImages && partnerImages.indexOf(hostBrandImage) !== -1) {
      const hostBrandImageIndex = partnerImages.indexOf(hostBrandImage);
      partnerImages.splice(hostBrandImageIndex, 1);
    }

    const formattedPartnerLogos = _.map(partnerImages.slice(0, numberOfImages), function(img, idx) {
      return(
        <div style={{
          display: 'inline-block',
          marginBottom: '4px'
        }}>
          <PartnerLogos
            key={ idx }
            logo={ img }
          />
        </div>
      );
    });

    return(
        <div style={{
          display: 'flex'
        }}>
          <div className={ CurrentCampaignCardTheme.participantsLogosMain }>
            <img src={ hostBrandImage }
                 className={ CurrentCampaignCardTheme.participantsHostLogo }
            />
          </div>

          <div className={ CurrentCampaignCardTheme.participantPartnerLogo }>
            { formattedPartnerLogos }
          </div>
        </div>

    );
  }
}

ParticipantsLogos.propTypes = {
  partnerImages: PropTypes.array.isRequired,
  hostBrandImage: PropTypes.string.isRequired
};

export default ParticipantsLogos;
