import React from 'react';
import CallToAction from './call-to-action';
import _ from 'lodash'

class PostEntry extends React.Component {
  render() {
    var bonuses = [];

    _.each(this.props.acceptedInvites, function (invite) {
      bonuses = bonuses.concat(invite.bonuses);
    });

    var callsToAction = [];

    _.each(this.props.acceptedInvites, (postEntry, idx) => {
      callsToAction.push(
        <CallToAction
          key={postEntry.id}
          postEntry={postEntry}
          campaign={this.props.campaign}
          bonuses={bonuses}
        />
      )
    });

    // post-entry is hidden until after signup
    return (
      <div className='stage post-entry hide'>
        <p>Thank You For Entering {this.props.campaign.name}</p>
        {callsToAction}
      </div>
    )
  }
}

export default PostEntry;
