import React from 'react'
import { object, string, bool } from 'prop-types'
import moment from 'moment-timezone'
import d3 from 'd3'
import {
  H3,
  H4,
  H6,
  Small,
  P,
} from 'visual-components/util/texts'
import { relativeDate } from 'util/relativeDate'
import StatusBarTheme from 'css/themes/media-market/StatusBar.css'
import displayTypeMap from 'util/deal-display-type-map'

class CreateEditSellStatusBar extends React.Component {
  static propTypes = {
    proposal: object.isRequired,
    title: string,
    background: string,
    hideUpdated: bool,
  }

  render() {
    const {
      proposal: {
        proposingBrand: { accountname } = {},
        type,
        cost_type,
        start_date,
        end_date,
        price,
        min_quantity,
        max_quantity,
        expected_views,
        created_at,
      } = {},
      title,
      background,
      hideUpdated,
    } = this.props

    const typeMap = {
      sponsored_email: 'Sponsored Email',
      dedicated_email: 'Dedicated Email',
      blog_post: 'Blog Post',
      banner_ad: 'Banner Ad',
      social_media_post: 'Social Media Post',
      instagram_story: 'Instagram Story',
    }

    return (
      <div className={`${StatusBarTheme.container} ${background ? StatusBarTheme[background] : ''}`}>
        <div className={StatusBarTheme.title}>
          <H3 coral><small>{title || `Current Offer from ${accountname}`}</small></H3>
        </div>

        { !hideUpdated
          && (
            <div className={StatusBarTheme.lastUpdated}>
              <Small>{`Last updated ${relativeDate(created_at)}`}</Small>
            </div>
          )
        }

        <div className={StatusBarTheme.dealStats}>
          <H4><em>{typeMap[type] || type}</em></H4>

          <div className={StatusBarTheme.stat}>
            <img src="images/date-and-time-black.svg" className={StatusBarTheme.icon} />
            <P>{ `${moment(start_date).format('M/D/YY')} - ${moment(end_date).format('M/D/YY')}` }</P>
          </div>

          <div className={StatusBarTheme.stat}>
            <img src="images/dollar-bill.svg" className={StatusBarTheme.icon} />
            <P>{displayTypeMap[cost_type] || cost_type}</P>
          </div>

          { cost_type === 'CPC'
            ? (
              <div>
                <div className={StatusBarTheme.stat}>
                  <P>CPC</P>
                  <div className={StatusBarTheme.priceAdjustment}>
                    <H6>{d3.format('$,.2f')(price)}</H6>
                  </div>
                </div>

                <div className={StatusBarTheme.stat}>
                  <P>Max Value</P>
                  <div className={StatusBarTheme.priceAdjustment}>
                    <H6>{d3.format('$,.2f')(price * max_quantity)}</H6>
                  </div>
                </div>

                <div className={StatusBarTheme.stat}>
                  <P>Deal Click Range</P>
                  <div className={StatusBarTheme.priceAdjustment}>
                    <H6>{`${d3.format(',')(min_quantity)} - ${d3.format(',')(max_quantity)}`}</H6>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={StatusBarTheme.stat}>
                  <P>Expected Reach</P>
                  <div className={StatusBarTheme.priceAdjustment}>
                    <H6>{d3.format(',')(expected_views)}</H6>
                  </div>
                </div>

                <div className={StatusBarTheme.stat}>
                  <P>Total Price</P>
                  <div className={StatusBarTheme.priceAdjustment}>
                    <H6>{d3.format('$,.2f')(price)}</H6>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}


export default CreateEditSellStatusBar
