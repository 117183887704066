import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'react-toolbox'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { H4 } from 'visual-components/util/texts'
import SimpleModal from 'visual-components/util/modals/SimpleInfoModal'

import CheckboxTheme from 'css/themes/Checkbox.css'

import * as TOUActions from 'actions/terms-of-use-actions'

const dispatchToProps = dispatch => ({
  touActions: bindActionCreators(TOUActions, dispatch),
})

const stateToProps = ({ currentBrand, profile }) => ({
  show: !currentBrand.has_accepted_latest_terms && !profile.impersonating,
  brand: currentBrand,
})

class UpdatedTermsModal extends Component {
  static propTypes = {
    touActions: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
  }

  state = { show: false, checked: false }

  componentDidMount = () => {
    const { show } = this.props

    // This keeps the transition effect of the modal
    return this.setState({ show })
  }

  componentDidUpdate = prevProps => {
    const { show } = this.props

    if (show !== prevProps.show) this.setState({ show })
  }

  onConfirm = () => {
    const { touActions } = this.props

    touActions.accept()
  }

  render() {
    const { show, checked } = this.state

    const TITLE = 'Terms Of Use Update'
    const BODY_HIGHLIGHT = 'Thanks for using DojoMojo! We’ve updated our <a href="/home/terms-of-use" rel="noreferrer" target="_blank">Terms of Use</a> to clarify how we provide services to our customers.'
    const BODY = null


    return (
      <SimpleModal
        active={show}
        close={() => {}}
        title={TITLE}
      >
        <div style={{ margin: '0 0 24px' }}>
          <H4 multiline>
            <span dangerouslySetInnerHTML={{ __html: BODY_HIGHLIGHT }} />
          </H4>
        </div>
        {
          BODY && (
          <div style={{ margin: '16px 0' }}>
            <H4 multiline>
              <span dangerouslySetInnerHTML={{ __html: BODY }} />
            </H4>
          </div>
          )
        }
        <div style={{ marginBottom: '16px' }}>
          <Checkbox
            label="Yes, I agree to these Terms of Use updates."
            checked={checked}
            onChange={() => this.setState(({ checked: set }) => ({ checked: !set }))}
            theme={CheckboxTheme}
            className={CheckboxTheme.futureCurrentDesign}
          />
          <ModalButtonGroup
            canSave={checked}
            confirm={this.onConfirm}
            confirmText="Accept & Continue"
            hideLine
          />
        </div>
      </SimpleModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(UpdatedTermsModal)
