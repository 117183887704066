import React, { PureComponent, Fragment } from 'react'
import moment from 'moment-timezone'
import { Card } from 'react-toolbox/lib/card'

import AnalyticsTheme from 'css/themes/analytics.css'

import ConversionLine from 'visual-components/analyticsDashboard/overviewCard/conversion-line'
import Grid from 'visual-components/util/no-padding-grid/grid'
import NoIntegrationEmptyState from 'visual-components/util/graphs/no-integrations-empty-state'
import CardTabs from 'visual-components/util/cards/card-tabs'
import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'
import { Tiny } from 'visual-components/util/texts'

class ConversionGraphs extends PureComponent {
  render() {
    const {
      tabs,
      data,
      switchTab,
      conversionSelection,
      oldestDate,
      changeFilter,
      datePickerData,
      hasAnyActiveIntegrations,
      firstConversion,
      handleUpsellClick,
      smaller,
    } = this.props

    // depending on tab selection, pick the relevant data to render graph
    const graphData = data[conversionSelection]

    const aggregrates = !hasAnyActiveIntegrations
      ? { revenue: -1, transactions: -1 }
      : data.aggregates

    return (
      <Card
        className={
          changeFilter
            ? AnalyticsTheme.conversionCard
            : AnalyticsTheme.overviewCard
        }
        style={smaller ? { height: 480 } : {}}
      >
        <Grid>
          <CardTabs
            tabs={tabs}
            data={aggregrates}
            switchTab={switchTab}
            selectedTab={conversionSelection}
          />
          {!hasAnyActiveIntegrations
            ? <NoIntegrationEmptyState upsellClickHandler={handleUpsellClick} />
            : (
              <Fragment>
                <div className={AnalyticsTheme.overviewCardContainer}>
                  <ConversionLine data={graphData} />
                </div>
                <div
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '10px',
                    height: '30px',
                  }}
                >
                  {firstConversion && (
                    <Tiny>
                      First Conversion:&nbsp;
                      { moment(firstConversion).format('ll') }
                    </Tiny>
                  )}
                </div>
              </Fragment>
            )}

          {changeFilter && (
            <CardDateRangePickerRow
              oldestDate={oldestDate}
              changeFilter={changeFilter}
              {...datePickerData}
            />
          )}
        </Grid>
      </Card>
    );
  }
}

export default ConversionGraphs
