import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from 'util/components/text';
import {
  SimpleForm, Input, Submit, Validations,
} from 'visual-components/util/form';
import AppDispatcher from 'dispatchers/app-dispatcher';

const { isValidDate, isAfterStartDate } = Validations;


const mapState = state => {
  return {
    currentBrand: state.currentBrand
  }
}


class QualityAssuranceAdminCreateCampaign extends React.Component {
  state = {};


  handleChange = (event) => {
    this.setState(event);
  };



  handleSubmit = () => {
    const data = {
      name: this.state.campaignName,
      description: 'qa-admin test campaign',
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      hostBrand: null,
      id: null,
      industry: 'Arts',
      invites: [],
      mainImage: '/images/images.png',
      mainImageAlignHorizontal: 'right',
      mainImageAlignVertical: 'top',
      partnerships: [],
      prizingDescription: 'something special, unforgettable',
      prizingValue: '1500',
      rid: 'currentCampaign',
      viewDesktop: true,
      visible: true,
      currentBrand: this.props.currentBrand,
    };

    AppDispatcher.http({
      url: '/qa-test-campaign',
      method: 'POST',
      data,
      success() {
        toastr.success('Campaign Created!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      },
    });
  };

  render() {
    return(
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          marginLeft: '50px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Create Test Campaign
          </Text.dojoH2Bold>

          <SimpleForm
            updateModel={(e) => this.handleChange(e)}
            handleSubmit={this.handleSubmit}
          >
            <Input
              name="campaignName"
              label="Campaign Name"
              required
            />

            <Input
              name="startDate"
              label="Start Date"
              placeholder="5-1-18"
              required
              validations={[isValidDate]}
            />

            <Input
              name="endDate"
              label="End Date"
              placeholder="6-1-18"
              required
              validations={[isValidDate, isAfterStartDate]}
            />

            <Submit
              label="Make my campaign"
            />
          </SimpleForm>

        </div>
      </div>
    );
  }


}




export default withRouter(connect(mapState)(QualityAssuranceAdminCreateCampaign));
