import React, { Component } from 'react'
import { func, object } from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H4 } from 'visual-components/util/texts'
import {
  Select, SimpleForm, Input, Checkbox, Validations,
} from 'visual-components/util/form'
import Spinner from 'util/components/spinner'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'


import US_STATE_DROPDOWN_VALUES from 'util/us-states/us-state-dropdown-values.json'
import { CLOSED, BANK_ACCOUNT_SETTINGS } from './steps'


const {
  isLastFour, isValidRepBirthday, isTaxId,
} = Validations


export default class BrandAccountDetailsForm extends Component {
  static propTypes = {
    toggleModal: func.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
  }

  componentDidUpdate(prevProps) {
    const {
      brandAccount: { last_updated },
    } = this.props
    if (prevProps.brandAccount.last_updated !== last_updated) this.next()
  }

  toggleModal = STEP => {
    const { toggleModal } = this.props
    toggleModal(STEP)
  }

  next = () => this.toggleModal(BANK_ACCOUNT_SETTINGS)

  close = () => this.toggleModal(CLOSED)

  update = data => {
    const {
      brandAccountActions: { updateModel },
    } = this.props
    updateModel(data)
  }

  handleSubmit = data => {
    const {
      brandAccountActions: { persistAccountDetails },
    } = this.props
    persistAccountDetails(data)
  }

  render() {
    const {
      brandAccount,
      brandAccount: {
        loading, error, verified, tos_already_accepted,
      },
    } = this.props

    return (
      <SimpleForm
        disabled={verified}
        mergeFieldsImmediately={false}
        model={brandAccount}
        updateModel={this.update}
        handleSubmit={this.handleSubmit}
      >
        {loading ? <Spinner /> : null}
        <Grid>
          <Row style={{ marginBottom: '16px' }}>
            {verified ? (
              <H4 multiline coral>
                Please contact
                {' '}
                <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
                {' '}
to
                update your business information.
              </H4>
            ) : (
              <H4 multiline>
                Complete the below to receive ACH payments from the DojoMojo network.
              </H4>
            )}
            {error ? (
              <H4 multiline>
                <strong style={{ color: 'red' }}>{`Error: ${error}`}</strong>
              </H4>
            ) : null}
          </Row>

          <Row>
            <Col style={{ width: '146px', marginRight: '8px' }}>
              <Input
                required
                name="legal_entity.first_name"
                label="First Name"
                placeholder="Mojo"
              />
            </Col>
            <Col style={{ width: '146px', marginRight: '8px' }}>
              <Input required name="legal_entity.last_name" label="Last Name" placeholder="Tiger" />
            </Col>
            <Col style={{ width: '146px', marginRight: '8px' }}>
              <Input
                required
                name="legal_entity.dob"
                label="Date of Birth"
                placeholder="MM/DD/YYYY"
                validations={[isValidRepBirthday()]}
              />
            </Col>
            <Col style={{ width: '146px' }}>
              <Input
                required
                name="legal_entity.ssn_last_4"
                label="Last 4 Digits of SSN"
                placeholder="8888"
                validations={[isLastFour]}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ width: '300px', marginRight: '8px' }}>
              <Input
                required
                name="legal_entity.business_name"
                label="Business Legal Name"
                placeholder="DojoMojo, Inc."
              />
            </Col>
            <Col style={{ width: '300px' }}>
              <Input
                required
                name="legal_entity.business_tax_id"
                label="Tax ID Number"
                placeholder="88-4444222"
                validations={[isTaxId]}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ width: '300px', marginRight: '8px' }}>
              <Input
                required
                name="legal_entity.address.line1"
                label="Official Company Address"
                placeholder="Street Name"
              />
            </Col>
            <Col style={{ width: '300px' }}>
              <Input
                name="legal_entity.address.line2"
                label="Address 2"
                placeholder="Apt, Suite, Unit # "
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ width: '300px', marginRight: '8px' }}>
              <Input required name="legal_entity.address.city" label="City" />
            </Col>
            <Col style={{ width: '146px', marginRight: '8px' }}>
              <Select
                name="legal_entity.address.state"
                label="State"
                options={US_STATE_DROPDOWN_VALUES}
                fullwidth
                required
              />
            </Col>
            <Col style={{ width: '146px' }}>
              <Input required name="legal_entity.address.postal_code" label="Zip/Postal Code" />
            </Col>
          </Row>

          <Row style={{ marginBottom: '24px' }}>
            <Col>
              <Checkbox
                required={!tos_already_accepted}
                name="terms_accepted"
                disabled={tos_already_accepted}
                forceValue={tos_already_accepted || undefined}
                label={(
                  <span>
                    By registering this account, I agree to the
                    {' '}
                    <a href="/home/cta-advertiser-terms" target="_blank">
                      Services Agreement
                    </a>
                    {' '}
                    and
                    {' '}
                    <a
                      href="https://stripe.com/us/connect-account/legal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe Connected Account Agreement
                    </a>
                    .
                    {' '}
                  </span>
)}
              />
            </Col>
          </Row>
        </Grid>
        <ModalButtonGroupWithContext
          canSave={!verified}
          confirmText="Next"
          cancelText="Cancel"
          cancel={this.close}
        />
      </SimpleForm>
    )
  }
}
