import React from 'react';

import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';
import _ from 'lodash';
import { bindActionCreators } from 'redux'
import * as ReactRedux from 'react-redux';
import PartnershipDispatcher from 'dispatchers/partnership-invites-dispatcher';
import Spinner from 'util/components/spinner';
import * as CurrentCampaignActions from 'actions/current-campaign-actions'

import RichEditor from 'visual-components/richEditor/RichEditor';
import {stateToHTML} from 'draft-js-export-html';
import MessageSlider from 'visual-components/messaging/message-slider';



const processNewInvite = (campaign, currentCampaignActions) => (err, invite) => {
  store.dispatch({
    model: 'conversations',
    type: 'UPDATE_ATTR',
    data: {
      loading: false,
      modalBrandInviteCampaign: {},
      modalBrandInviteContent: null,
      modalBrandInviteMinDelivery: 0
    }
  })

  if (err) {
    toastr.error("Error: " + err)
  } else if (store.getState().brand.partnerships) {
      const partnerships = _.clone(store.getState().brand.partnerships)
      partnerships.push(invite);
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brand',
        data: { partnerships }
      })
    }

    currentCampaignActions.updateInvite(invite)
}


class BrandInvite extends React.Component {
  constructor(props) {
    super(props);
    this.closeSlider = this.closeSlider.bind(this);
    this.invite = this.invite.bind(this);
  }

  closeSlider() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        modalBrandInviteCampaign: {},
      }
    })
  }


  invite(contentState) {
    const { campaign, reinviteType, currentCampaignActions } = this.props
    PricingDispatcher.check('sweepstakes.partnershipInvitation.create', () => {
      store.dispatch({
        model: 'conversations',
        type: 'UPDATE_ATTR',
        data: {
          loading: true
        }
      })
      let htmlContent = stateToHTML(contentState)

      if (['pending', 'rejected', 'partnerWithBrand'].includes(reinviteType)) {
        PartnershipDispatcher.reInvite(campaign.id, htmlContent, reinviteType, processNewInvite(campaign, currentCampaignActions))
      } else {
        PartnershipDispatcher.invite(campaign, htmlContent, 0, processNewInvite(campaign, currentCampaignActions))
      }
    })
  }

  render() {
    const { campaign, messageContent, brand, loading } = this.props;

    if (loading) {
      return (
        <Spinner needsBackground={ true } />
      )
    }
    return (
      <MessageSlider
        isShown={ campaign.id ? true : false }
        onClose={ this.closeSlider }
        onSend={ this.invite }
        title={`New Message to ${brand.accountname}`}
        buttonText="Send Message"
        initialContent={ messageContent }
      />
    )
  }
}

const mapState = (state) => {
  return {
    campaign: state.conversations.modalBrandInviteCampaign,
    messageContent : state.conversations.modalBrandInviteContent,
    loading: state.conversations.loading,
    brand: state.brand,
    reinviteType: state.conversations.reinviteType
  }
}

const mapDispatchToProps = dispatch => ({
  currentCampaignActions: bindActionCreators(CurrentCampaignActions, dispatch)
})


export default ReactRedux.connect(mapState, mapDispatchToProps)(BrandInvite);
