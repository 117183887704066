import React from 'react'

import Validator from 'util/form-validator'
import UploadImage from 'components/util/editor-uploader'
import Label from 'visual-components/util/inputs/label'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import FieldDispatcher from 'dispatchers/field-dispatcher'
import { P } from 'visual-components/util/texts'

class PinterestPin extends React.Component {
  uploadPinterestResource = src => {
    const { bonus: { id } } = this.props

    FieldDispatcher.updateAttr('bonusItems', {
      id: this.props.bonus.id,
      pinterestresource: src
    })
  }

  render() {
    const { bonus: { id, partnership_invite_id, pinterestresource }, bonusItems } = this.props

    return (
      <div>
        <Label label={ 'Share Image' } />

        {
          pinterestresource ?
          <img src={ pinterestresource }
            style={{
              width: '100%',
              marginBottom: '16px'
          }}/>
          : null
        }

        <div style={{
          width: '112px',
        }}>
          <UploadImage action={ `/partnership-pinterest-image/${partnership_invite_id}/${id}` }
            src={ pinterestresource }
            success={ this.uploadPinterestResource }
            name="upload-share-image"
            acceptedFiles="image/jpeg,image/png"
          >
            <Button
              theme={ buttonTheme }
              className={ `${buttonTheme.greenButton} ${buttonTheme.noMargin}` }
              label={ 'Select Image' }
              raised
            />
          </UploadImage>
        </div>
        <P multiline>
          { 'Image Size: 1280 x 433 pt' }
        </P>
      </div>
    )
  }
}

export default PinterestPin
