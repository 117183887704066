import React from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

class DojoRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { style = {} , ...rest } = this.props;
    let rowStyles = _.extend({ marginTop: '0', marginRight: '0', marginBottom: '0', marginLeft: '0' }, this.props.style || {} );

    return(
      <Row style={ rowStyles } { ...rest } >
        { this.props.children }
      </Row>
    )
  }
}

DojoRow.propTypes = {
  style: PropTypes.object
}

export default DojoRow;
