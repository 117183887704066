import React from 'react';
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet'

import { Card } from 'react-toolbox/lib/card';
import { Button } from 'react-toolbox/lib/button';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import InviteUserModal from 'visual-components/brandSettings/invite-user-modal';
import InvitedUserRow from 'visual-components/brandSettings/invited-user-row';
import UserRow from 'visual-components/brandSettings/user-row';
import Validator from 'util/form-validator';
import COLOURS from 'util/colours';
import { H2 } from 'visual-components/util/texts';
import AppDispatcher from 'dispatchers/app-dispatcher';
import ButtonTheme from 'css/themes/Buttons.css'

const validations = {
  firstName: [Validator.required],
  lastName: [Validator.required],
  email: [Validator.required, Validator.isValidEmail, Validator.validDomain]
}

const themes = {
  Button: ButtonTheme,
}

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    profile: state.profile,
    brandCredentials: state.brandCredentials,
    brandUserInvites: state.brandUserInvites,
    newBrandInvite: state.newBrandInvite,
    newBrandInviteForm: state.newBrandInviteForm
  }
}

class UsersSettings extends React.Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.revokeAccess = this.revokeAccess.bind(this);
    this.cancelInvite = this.cancelInvite.bind(this);
    this.updateRole = this.updateRole.bind(this);

    this.state = {
      modalOpen: false
    }
  }

  componentDidMount() {
    var {
      brandCredentials,
      brandUserInvites
    } = this.props;

    AppDispatcher.loadOnce({
      model: 'brandCredentials',
      endpoint: '/brand-credentials',
      data: brandCredentials,
      type: 'CREDENTIAL_UPDATE_ATTR',
    });

    AppDispatcher.loadOnce({
      model: 'brandUserInvites',
      endpoint: '/brand-user-invites',
      data: brandUserInvites
    });
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  inviteUser() {
    var {
      newBrandInvite
    } = this.props;

    var that = this;
    Validator.validateForm(validations, newBrandInvite, 'newBrandInviteForm', function (valid) {
      if (valid) {
        AppDispatcher.http({
          url: '/brand-invite',
          method: 'POST',
          data: {
            email: newBrandInvite.email,
            firstName: newBrandInvite.firstName,
            lastName: newBrandInvite.lastName,
            role: newBrandInvite.role
          },
          success: function (data) {
            store.dispatch({
              model: 'brandUserInvites',
              type: 'ADD_ITEM',
              data: data
            })
            store.dispatch({
              model: 'newBrandInvite',
              type: 'UPDATE_ATTR',
              data: {
                email: '',
                firstName: '',
                lastName: ''
              }
            })
            that.setState({
              modalOpen: false
            })

            toastr.success('Your invitation has been sent', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
          },
          error: function (data) {
            toastr.error(data.responseJSON ? data.responseJSON.message : data.responseText, null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
          }
        });
      }
    });
  }

  revokeAccess(id) {
    AppDispatcher.http({
      url: '/brand-credential/' + id,
      method: 'DELETE',
      success: function () {
        store.dispatch({
          model: 'brandCredentials',
          type: 'CREDENTIAL_REMOVE_ITEM_BY_ID',
          data: {
            id: id
          }
        })
      }
    });
  }

  cancelInvite(id) {
    AppDispatcher.http({
      url: '/brand-invite/' + id,
      method: 'DELETE',
      success: function () {
        store.dispatch({
          model: 'brandUserInvites',
          type: 'REMOVE_ITEM_BY_ID',
          data: {
            id: id
          }
        })
      }
    });
  }

  updateRole(id, role) {
    AppDispatcher.http({
      url: '/brand-credential/' + id,
      method: 'PUT',
      data: {
        role: role
      },
      success: function () {
        store.dispatch({
          model: 'brandCredentials',
          type: 'CREDENTIAL_UPDATE_ITEM',
          data: {
            id: id,
            role: role
          }
        })
      }
    });
  }

  render() {
    var {
      currentBrand,
      profile,
      brandCredentials,
      brandUserInvites,
      newBrandInvite,
      newBrandInviteForm
    } = this.props;

    var {
      modalOpen
    } = this.state;

    var divider = (
      <div style={{
        height: '1px',
        backgroundColor: COLOURS.silver
      }}></div>
    )

    var userItems = _.map(brandCredentials.items, (member, idx) => {
      var showDivider = true;
      if (idx == brandCredentials.items - 1 && brandUserInvites.items == 0) {
        showDivider = false;
      }

      return(
        <UserRow key={ idx }
          member={ member }
          currentBrand={ currentBrand }
          profile={ profile }
          revokeAccess={ this.revokeAccess }
          showDivider={ showDivider }
          owner={currentBrand.ownerId === member.userId}
        />
      )
    });

    var inviteItems = _.map(brandUserInvites.items, (invite, idx) => {
      var showDivider = true;
      if (idx == brandUserInvites.items - 1) {
        showDivider = false;
      }

      return(
        <InvitedUserRow key={ idx }
          invite={ invite }
          currentBrand={ currentBrand }
          profile={ profile }
          cancelInvite={ this.cancelInvite }
          showDivider={ showDivider }
        />
      )
    });

    return(
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>Users</title>
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '24px'
          }}>
            <Col xs={6}>
              <H2 azure>
                Users
              </H2>
            </Col>
            <Col xs={6}>
              <Button onClick={ this.toggleModal }
                label={ 'Invite a User' }
                className={ themes.Button.greenButton }
                theme={ themes.Button }
                raised
                style={{
                  fontFamily: 'Larsseit-Medium',
                  fontSize: '14px',
                  width: '109px',
                  height: '36px',
                  marginTop: '-13px',
                  float: 'right'
              }}/>
            </Col>
          </Row>
          <Row>
            <table style={{
              marginBottom: '6px'
            }}>
              <thead>
                <tr>
                  <th style={{
                    fontFamily: 'Larsseit-Light',
                    width: '296.25px',
                    paddingLeft: '16px'
                  }}>
                    User Name
                  </th>
                  <th style={{
                    fontFamily: 'Larsseit-Light',
                    width: '372.5px'
                  }}>
                    Email
                  </th>
                  <th style={{
                    fontFamily: 'Larsseit-Light',
                    width: '92.5px'
                  }}>
                    Type
                  </th>
                  <th style={{
                    fontFamily: 'Larsseit-Light',
                    width: '213px',
                    textAlign: 'center'
                  }}>
                    Actions
                  </th>
                </tr>
              </thead>
            </table>
            <Card style={{
              width: '976px'
            }}>
              <table>
                <tbody>
                  { userItems.length ? userItems : null }
                  { inviteItems.length ? inviteItems : null }
                </tbody>
              </table>
            </Card>
          </Row>
        </Grid>

        <InviteUserModal modalOpen={ modalOpen }
          toggleModal={ this.toggleModal }
          inviteUser={ this.inviteUser }
          newBrandInvite={ newBrandInvite }
          newBrandInviteForm={ newBrandInviteForm }
          accountname={ currentBrand.accountname }
          validations={ validations }
        />
      </div>
    )
  }
};

export default ReactRedux.connect(mapState)(UsersSettings);
