import React from 'react'
import { withRouter } from 'react-router-dom'
import { object, func } from 'prop-types'
import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text'
import Table from 'util/components/standardized-tables'
import { P, H3 } from 'visual-components/util/texts'
import Label from 'visual-components/util/inputs/label'
import Dropdown from 'react-toolbox/lib/dropdown'
import DropDownTheme from 'css/themes/formdropdown.css'
import DefaultDialog from 'visual-components/util/modals/default-dialog'
import PartnershipAgreementRow from './agreement-items/partnership-agreement-row'

class Dashboard extends React.Component {
  static propTypes = {
    partnershipAgreement: object.isRequired,
    currentCampaign: object.isRequired,
    actions: object.isRequired,
    openDialog: func.isRequired,
    history: object.isRequired,
  }

  state = {
    deleteModalOpen: false,
    deleteInviteId: null,
    deleteCopy: '',
  }

  changeBrand = val => {
    const {
      currentCampaign,
      actions: { clearAgreement, updateAttributes },
      history,
    } = this.props

    if (val) {
      clearAgreement()
      updateAttributes({
        selectedBrand: val,
      })

      history.push(`/builder/${currentCampaign.id}/partnership-agreement/choose/${val}`)
    }
  }

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpen: false,
      deleteInviteId: null,
      deleteCopy: '',
    })
  }

  openDeleteModal = invite => {
    const { id, agreement, invitee: { accountname } } = invite
    let deleteCopy = `You will need to make a new partnership agreement in order to confirm ${accountname} as a partner on this campaign.`
    if (agreement && agreement.status === 'completed') {
      deleteCopy = `Deleting this Partnership Agreement removes ${accountname} as an accepted partner from the campaign. This brand will be marked as "Interested".`
    }

    this.setState({
      deleteModalOpen: true,
      deleteInviteId: id,
      deleteCopy,
    })
  }

  deleteAgreement = () => {
    const {
      actions: { deleteAgreement },
    } = this.props

    const { deleteInviteId } = this.state

    deleteAgreement(deleteInviteId)

    this.setState({
      deleteModalOpen: false,
      deleteInviteId: null,
      deleteCopy: '',
    })

    toastr.success('Your agreement has been deleted.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
  }

  render() {
    const {
      partnershipAgreement: { selectedBrand = '' },
      currentCampaign,
      openDialog,
      actions,
    } = this.props

    const { deleteModalOpen, deleteCopy } = this.state


    const paras = [
      <P multiline>
        {'Create a Partnership Agreement here to ensure all parties in the campaign are clear about what is expected of them. DojoMojo provides an easily customizable Partnership Agreement that you may use.'}
        <br />
        {'Note availability of the Sample Partnership Agreement does '}
        <b><u>not</u></b>
        {' represent any endorsement by DojoMojo. Use of the Sample Partnership Agreement is not a substitute for legal advice. Contact an attorney to obtain advice with respect to any legal issues or questions. DojoMojo expressly disclaims any and all liability with respect to actions taken or not taken based on the Sample Partnership Agreement.'}
      </P>,
    ]

    const options = currentCampaign.invites.filter(invite => invite.invitee_id !== currentCampaign.hostBrandId && ['interested', 'accepted'].includes(invite.status)).map(invite => ({
      label: invite.invitee.accountname,
      value: invite.id,
      disabled: !!invite.agreement,
    })).sort((a, b) => a.label > b.label)

    options.unshift({
      label: 'Select a Brand',
      value: '',
    })

    const agreements = currentCampaign.invites.filter(invite => invite.invitee_id !== currentCampaign.hostBrandId && !!invite.agreement && invite.status !== 'rejected').map(invite => (
      <PartnershipAgreementRow
        key={invite.id}
        invite={invite}
        actions={actions}
        closeModal={this.closeDeleteModal}
        openModal={this.openDeleteModal}
        openDialog={openDialog}
        campaignStartDate={currentCampaign.startDate}
      />
    ))

    return (
      <div>
        <TabTitleAndText headers={[]} paras={paras} />

        <div
          style={{
            width: '540px',
            marginTop: '20px',
            marginBottom: '40px',
          }}
        >
          <Label label="Select the brand you’d like to generate an agreement for:" />
          <Dropdown
            onChange={this.changeBrand}
            source={options}
            value={selectedBrand}
            theme={DropDownTheme}
          />
        </div>

        <div style={{ marginBottom: '32px' }}>
          <H3>
            <small>
              { `Draft Agreements for ${currentCampaign.name}` }
            </small>
          </H3>
        </div>

        <Table.Table>
          <Table.Tbody>
            <Table.HeaderTr styles={[{ borderWidth: '0px' }]}>
              <Table.Th>
                {/* This is a placeholder table header so we have the correct spacing over the logo */}
              </Table.Th>
              <Table.LeftTh textStyles={[{ fontFamily: "'Larsseit'", fontSize: '12px' }]}>
                Brand Name
              </Table.LeftTh>
              <Table.LeftTh textStyles={[{ fontFamily: "'Larsseit'", fontSize: '12px' }]}>
                Status
              </Table.LeftTh>
              <Table.LeftTh textStyles={[{ fontFamily: "'Larsseit'", fontSize: '12px' }]}>
                Actions
              </Table.LeftTh>
            </Table.HeaderTr>
          </Table.Tbody>
          <Table.Tbody>
            { agreements }
          </Table.Tbody>
        </Table.Table>
        <DefaultDialog
          active={deleteModalOpen}
          closeModal={this.closeDeleteModal}
          title="Are You Sure You Want to Delete This Partnership Agreement?"
          confirm={this.deleteAgreement}
          confirmText="Delete"
          cancel={this.closeDeleteModal}
          cancelText="Cancel"
          children={(
            <div>
              <P multiline>
                { deleteCopy }
              </P>
            </div>
          )}
        />
      </div>
    )
  }
}

export default withRouter(Dashboard)
