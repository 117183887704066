import React from 'react'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import PartnerLogo from 'visual-components/shared/campaign-detail-card/CurrentCampaignCard/partner-logos'

import { Card } from 'react-toolbox/lib/card'
import { P, H3, Small } from 'visual-components/util/texts'
import { relativeDate } from 'util/relativeDate'
import AnnouncementTheme from 'css/themes/announcement-theme.css'
import COLOURS from 'util/colours'

class AnnouncementItem extends React.Component {
  render() {
    const {
      announcement: { type, message, date, count },
      invites,
    } = this.props

    const partnerLogos = invites.map(invite => {
      if (!count.includes(invite.invitee_id)) {
        return null
      }

      return (
        <PartnerLogo key={invite.id} logo={invite.invitee.logo} />
      )
    })

    return (
      <Card style={{
        width: '736px',
        marginLeft: '208px',
        marginBottom: '16px'
      }}>
        <Row style={{
          padding: '16px 20px'
        }}>
          <Row style={{
            marginBottom: '12px'
          }}>
            <Col style={{
              marginRight: '8px'
            }}>
              <H3>
                <small>
                  { type }
                </small>
              </H3>
            </Col>
            <Col style={{
              marginTop: '4px'
            }}>
              <Small>
                { relativeDate(date) }
              </Small>
            </Col>
          </Row>
          <Col style={{
            width: '696px'
          }}>
            <P multiline>
              <span dangerouslySetInnerHTML={{ __html: message }}
                className={ AnnouncementTheme.lineBreak }></span>
            </P>
          </Col>
        </Row>
        <Row style={{
          height: '56px',
          padding: '12px 20px',
          borderTop: `1px solid ${COLOURS.silver}`
        }}>
          { partnerLogos }
          <div style={{
            marginTop: '12px',
            marginLeft: '4px'
          }}>
            <Small>
              { `Seen by ${count.length} partner${count.length !== 1 ? 's' : ''}` }
            </Small>
          </div>
        </Row>
      </Card>
    )
  }
}

export default AnnouncementItem
