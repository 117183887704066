import React, { Component } from 'react'
import { object, func } from 'prop-types'
import Clipboard from 'clipboard'
import { Form, Input } from 'visual-components/util/form'
import { P, H4 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import ModalBaseTheme from 'css/themes/dialogs/formModal.css'
import ButtonTheme from 'css/themes/Buttons.css'
import DataIntegrationTheme from 'css/themes/data-integrations.css'
import Spinner from 'util/components/spinner'
import COLOURS from 'util/colours'
import conversionScript from 'util/conversion-pixel-script'
import axios from 'axios'
import DataIntegrationsMap from './data-integrations-map'
import IntegrationIcon from './data-integration-icon'

const tileLogoDir = '/images/tile-logos'
const iconImageMap = {
  shopify: 'shopify-modal.svg',
  stripe: 'stripe-modal.svg',
  pixel: 'mojo-modal.svg',
  woocommerce: 'woocommerce-modal.svg',
}

class DataIntegrationsModal extends Component {
  static propTypes = {
    currentBrand: object.isRequired,
    dataIntegrations: object.isRequired,
    dataIntegrationsActions: object.isRequired,
    closeModal: func.isRequired,
  }

  state = {
    loading: false,
  }

  componentDidMount() {
    new Clipboard('.copy-link')
  }

  onSubmit = () => {
    const {
      dataIntegrations: {
        shopify,
        type,
        conversionSuccessPath,
        woocommerce,
      },
      dataIntegrationsActions: {
        loading,
        testPixel,
      },
      currentBrand: {
        id: brandId,
        uid,
      },
      closeModal,
    } = this.props

    let {
      currentBrand: {
        website,
      },
    } = this.props

    let iframe
    switch (type) {
      case 'shopify':
        if (!shopify.isIntegrated) {
          // Start Auth
          closeModal()
          loading()
          window.location = 'https://apps.shopify.com/dojomojo'
        } else {
          closeModal()
        }
        break
      case 'pixel':
        loading()
        if (!website.startsWith('http')) {
          website = `http://${website}`
        }
        iframe = document.createElement('iframe')
        iframe.src = website
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
        setTimeout(() => {
          testPixel({
            brandId,
            conversionSuccessPath,
          })
        }, 5000)
        break
      case 'woocommerce':
        axios.get(`/woocommerce-auth/${encodeURIComponent(woocommerce.storeUrl)}`, {
          headers: {
            brand: brandId,
            impersonating: sessionStorage.getItem('impersonating'),
          },
        }).then(res => {
          window.location = res.data
        })
        break
      default:
        closeModal()
        break
    }
  }

  onKeyUp = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.onSubmit()
    }
  }

  handleCopied = () => {
    toastr.success('Link Copied!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
  }

  renderPixelForm = () => {
    const {
      currentBrand,
    } = this.props
    const script = conversionScript(currentBrand.id)
    return (
      <div>
        <img
          src={`${tileLogoDir}/mojo-modal.svg`}
          className={DataIntegrationTheme.modalLogo}
        />
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <H4 multiline>
            <em>Note: This step requires a developer.</em>
          </H4>
        </div>

        <div
          style={{
            marginBottom: '20px',
            marginLeft: '10px',
          }}
        >
          <H4 multiline>
            <span style={{ marginRight: '14px' }}>1. </span>
            Copy the script below and include it inside the
            <em> head </em>
            tag of your brand website.
          </H4>
        </div>

        <div
          className="copy-link"
          data-clipboard-text={script}
          onClick={this.handleCopied}
          style={{
            cursor: 'pointer',
            maxHeight: '160px',
            overflowY: 'scroll',
            textAlign: 'left',
            backgroundColor: COLOURS.silver,
            border: `1px solid ${COLOURS.cloudGrey}`,
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              backgroundColor: COLOURS.silver,
              border: 'none',
              padding: '16px',
            }}
          >
            {script}
          </div>
        </div>
        <div
          style={{
            marginBottom: '0px',
            marginLeft: '10px',
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <H4 multiline>
              <span style={{ marginRight: '14px' }}>2. </span>
              Then indicate to us what path on your site indicates a conversion.
            </H4>
          </div>

          <Input
            onKeyUp={this.onKeyUp}
            name="conversionSuccessPath"
            label="Conversion Success Path"
            placeholder="http://www.yoursite.com/thank_you_for_your_purchase"
            required
          />
        </div>
        <div
          style={{
            marginBottom: '32px',
            marginLeft: '10px',
          }}
        >
          <H4 multiline>
            <span style={{ marginRight: '14px' }}>3. </span>
            Once both are completed, click Integrate to confirm and start syncing data.
          </H4>
        </div>
      </div>
    )
  }

  renderShopifyInfo = () => (
    <div style={{ marginBottom: '32px' }}>
      <div style={{ marginBottom: '16px' }}>
        <H4 multiline>
          Connect your store to track campaign performance, get personalized recommendations, and activate DojoMojo's most powerful tools.&nbsp;
          <a
            href="https://help.dojomojo.com/hc/en-us/articles/360008892612-Integrating-DojoMojo-Shopify"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
          .
        </H4>
      </div>
      <ul style={{ listStyle: 'decimal', paddingLeft: '24px' }}>
        <li>
          <H4 multiline>By clicking &quot;Get started&quot; you will be redirected to the Shopify App Store.</H4>
        </li>
        <li>
          <H4 multiline>Install the DojoMojo app on your store.</H4>
        </li>
        <li>
          <H4 multiline>
            You will be redirected back here after installing the app.
          </H4>
        </li>
      </ul>
    </div>
  )

  render() {
    const {
      currentBrand,
      dataIntegrations,
      dataIntegrations: {
        type,
        step,
        modalOpen,
      },
      closeModal,
      dataIntegrationsActions: {
        update,
        disconnect,
      },
    } = this.props

    const {
      loading,
    } = this.state

    const integrationItem = DataIntegrationsMap.find(integration => integration.key === type)

    const dialogMap = {
      choose: {
        title: 'Set Up Tracking Integrations',
        confirmText: 'Cancel',
      },
      setup: {
        title: `Set Up ${integrationItem && integrationItem.label} Integration`,
        confirmText: type === 'shopify'? 'Get Started': 'Integrate',
      },
      edit: {
        title: `Edit ${integrationItem && integrationItem.label} Integration`,
        confirmText: 'Close',
      },
    }

    const dataIntegrationItems = DataIntegrationsMap.map(integration => {
      const i = dataIntegrations[integration.key]
      const integrated = (integration.key === 'pixel' && i)
        || (i && i.id && i.access_token)

      return (
        <IntegrationIcon
          integrationType={integration.key}
          integrated={!!integrated}
          onClick={() => {
            if (!integrated && integration.oauth) {
              window.location.pathname = `/${integration.oauth.route}/${currentBrand.id}`
            } else {
              update({
                step: integrated ? 'edit' : 'setup',
                type: integration.key,
              })
            }
          }}
        />
      )
    })

    const inputs = integrationItem && integrationItem.inputs
      && integrationItem.inputs.map(input => (
        <Input
          name={`${type}.${input.attr}`}
          label={input.label}
          required
        />
      ))

    return (
      <Dialog
        active={modalOpen}
        title={dialogMap[step].title}
        onEscKeyDown={closeModal}
        onOverlayClick={closeModal}
        theme={ModalBaseTheme}
      >
        <div>
          <img
            src="images/icons/close.svg"
            onClick={closeModal}
            className={ModalBaseTheme.closeImage}
          />
        </div>

        { loading && <Spinner /> }

        <Form
          modelName="dataIntegrations"
          updateModel={update}
          handleSubmit={this.onSubmit}
          mergeFieldsImmediately
        >
          {
            type === 'pixel'
              ? this.renderPixelForm()
              : (
                <div>
                  { step === 'choose'
                    && (
                      <div style={{ marginBottom: '-24px' }}>
                        <P>Select an integration provider below to begin.</P>
                        <div style={{ textAlign: 'center' }}>
                          {dataIntegrationItems}
                        </div>
                      </div>
                    )
                  }

                  { step === 'setup'
                    && (
                      <div>
                        <img
                          src={`${tileLogoDir}/${iconImageMap[integrationItem.key]}`}
                          className={DataIntegrationTheme.modalLogo}
                        />
                        {
                          type === 'shopify'
                            ? this.renderShopifyInfo()
                            : (
                              <P>
                                Enter your&nbsp;
                                {integrationItem.label}
                                &nbsp;info to continue.
                              </P>
                            )
                        }
                        {inputs}
                      </div>
                    )
                  }

                  { step === 'edit'
                    && (
                      <div>
                        <img
                          src={`${tileLogoDir}/${iconImageMap[integrationItem.key]}`}
                          className={DataIntegrationTheme.modalLogo}
                        />
                        <P>
                          {`Manage your ${integrationItem.label} integration settings below.`}
                        </P>
                        {
                          type === 'shopify' && dataIntegrations.shopify.isPaymentMethod
                            && (
                              <p>
                                Note: Disconnecting Shopify will cancel your subscription.
                              </p>
                            )
                        }
                        <div style={{ marginTop: '24px' }}>
                          {inputs}
                        </div>
                        <Button
                          onClick={() => {
                            // if recharge is integrated, disconnect as well
                            if (type === 'shopify'
                              && dataIntegrations.recharge
                              && dataIntegrations.recharge.isIntegrated) {
                              disconnect('recharge')
                            }

                            disconnect(type)
                          }}
                          label="Disconnect"
                          className={ButtonTheme.whiteButton}
                          theme={ButtonTheme}
                          raised
                          style={{
                            marginRight: '16px',
                            marginBottom: '24px',
                          }}
                        />
                      </div>
                    )
                  }
                </div>
              )
          }

          <ModalButtonGroupWithContext
            confirmText={dialogMap[step].confirmText}
            cancel={(step === 'choose' || step === 'edit') ? null : closeModal}
            cancelText="Cancel"
            bottomPad={step === 'choose'}
            hideLine={step === 'choose'}
            canSave
          />
        </Form>
      </Dialog>
    )
  }
}

export default DataIntegrationsModal
