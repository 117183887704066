import React, { PureComponent as Component } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { Checkbox } from 'react-toolbox'
import PropTypes from 'prop-types'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import SearchCard from 'visual-components/shared/search-card'
import SortByFilter from 'visual-components/shared/filters/SortByFilter'
import NoCampaigns from 'visual-components/myPartnerships/no-campaigns'
import injectSheet from 'react-jss'
import CheckboxTheme from 'css/themes/Checkbox.css'
import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'
import UnderConstructionBanner from 'visual-components/mediaMarket/UnderConstructionBanner'
import MyPartnershipsHeader from './myPartnershipsHeader'
import FilterPartnerships from './FilterPartnerships'

const transitions = {
  container: props => ({
    paddingTop: props.noHeader ? '0px' : '32px',
    marginBottom: props.noHeader ? '58px' : '0px',
  }),
  error: {
    fontFamily: 'Larsseit-Light',
    fontSize: '12px',
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '24px',
    alignItems: 'flex-end',
  },
  sort: {
    width: '100%',
    marginBottom: '24px',
  },
  searchCard: {
    marginBottom: '16px',
  },
  title: {
    fontFamily: 'Larsseit-Bold',
    fontSize: '20px',
    marginBottom: '20px',
  },
}
class PartnershipsContainer extends Component {
  static propTypes = {
    actionLabel: PropTypes.string,
    classes: PropTypes.object.isRequired,
    noHeader: PropTypes.bool,
    prefix: PropTypes.string,
    showFilter: PropTypes.string,
    actionExplore: PropTypes.func,
    action: PropTypes.func,
    labelExplore: PropTypes.string,
  }

  static defaultProps = {
    actionLabel: 'Create Campaign',
    noHeader: false,
    prefix: '',
    showFilter: '',
    actionExplore: () => {},
    labelExplore: '',
    action: () => {},
  }

  componentDidMount() {
    const { title, location } = this.props
    const splitPathname = location.pathname.split('/')
    const pageURI = splitPathname[splitPathname.length - 1]

    // Track only these sections
    if (['opportunities', 'past', 'mm-sells', 'mm-buys'].includes(pageURI)) {
      const page_title = title
      gtagPageTrack({ page_title, location })
    }
  }

  componentDidUpdate(prevProps) {
    const { title, location } = this.props
    const splitPathname = location.pathname.split('/')
    const pageURI = splitPathname[splitPathname.length - 1]
 
    if ((title !== prevProps.title) && ['opportunities', 'past', 'mm-sells', 'mm-buys'].includes(pageURI)) {
      const page_title = title
      gtagPageTrack({ page_title, location })
    }
  }


  render() {
    const {
      title,
      actionExplore,
      labelExplore,
      classes: css,
      search,
      searchKeyUp,
      searchLabel,
      sort,
      sortByOptions,
      filter,
      filterLabel,
      filterValues,
      errorText,
      content,
      showPartnershipButton,
      noHeader,
      switchLayouts,
      showFilter,
      prefix,
    } = this.props

    let { action, actionLabel } = this.props
    action = actionLabel === 'Start RFP' ? actionExplore : action
    actionLabel = actionLabel === 'Start RFP' ? labelExplore : actionLabel

    return (
      <div className={css.container}>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
        <Grid>
          { noHeader ? (null) : (<MyPartnershipsHeader {...this.props} style={{ marginRight: '-16px' }} />) }
          {
            search
              ? (
                <Row>
                  <Col xs>
                    <div className={css.searchCard}>
                      <SearchCard
                        placeholder={searchLabel}
                        value={filterValues.search}
                        onChange={search}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null
            }
          {title === 'Media Market' && <UnderConstructionBanner />}
          {
            showFilter
              ? (
                <Row>
                  <div className={css.filter}>
                    <FilterPartnerships prefix={prefix} showFilter={showFilter} />
                    {filter ? (
                      <Checkbox
                        label={filterLabel}
                        checked={filterValues.hosting}
                        onChange={filter}
                        className={CheckboxTheme.boldLabelMedium}
                        theme={CheckboxTheme}
                      />
                    ) : null}
                  </div>
                </Row>
              ) : null
          }
          {
            sort
              ? (
                <Row>
                  <div className={css.sort}>
                    <SortByFilter
                      onChange={sort}
                      source={sortByOptions}
                      name="sortBy"
                      value={filterValues.sort}
                    />
                  </div>
                </Row>
              ) : null
          }
          { noHeader
            ? (<div className={css.title}>{title}</div>)
            : (null)}
          <Row className={css.error}>
            {
              errorText
                ? (noHeader ? (errorText) : (
                  <NoCampaigns
                    error={errorText}
                    showPartnershipButton={showPartnershipButton}
                    createCampaign={action}
                    actionLabel={actionLabel}
                  />
                )) : content
            }
          </Row>
        </Grid>
      </div>
    )
  }
}

export default withRouter(injectSheet(transitions)(PartnershipsContainer))
