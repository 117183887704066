import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Col from 'visual-components/util/no-padding-grid/col';



class CampaignPreviewPartnerLogo extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var {
      logo,
      remainder,
      marginTop,
      large
    } = this.props;

    var style = {
      width: '30px',
      height: '30px',
      maxWidth: '30px',
      maxHeight: '30px',
      lineHeight: '30px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: COLOURS.silver,
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center'
    }

    var logoStyle = {
      maxHeight: '24px',
      maxWidth: '24px',
      display: 'block',
      margin: 'auto'
    }

    if (large) {
      style.width = '48px';
      style.height = '48px';
      style.maxHeight = '48px';
      style.maxWidth = '48px';
      style.lineHeight = '48px';

      logoStyle.maxWidth = '36px';
      logoStyle.maxHeight = '36px';
    }

    return(
      <div style={{
        display: 'inline-block',
        marginLeft: large ? '6px' : '4px',
        marginTop: marginTop
      }}>
        <div style={ style }>
          { logo ? <img src={ logo }
              style={ logoStyle }/>
            : null }
          { remainder ? <p
              style={{
                fontFamily: 'Larsseit',
                fontSize: large ? '12px' : '9px',
                lineHeight: large ? '12px' : '9px',
                textAlign: 'center',
                paddingLeft: large ? '6px' : '3px',
                paddingRight: large ? '6px' : '3px',
                paddingTop: large ? '6px' : '3px',
                color: COLOURS.azure
              }}>{ `+ ${remainder} more` }</p>
            : null }
        </div>
      </div>
    )

  }
};

CampaignPreviewPartnerLogo.propTypes = {
  logo: PropTypes.string.isRequired,
  marginTop: PropTypes.string
};

export default CampaignPreviewPartnerLogo;
