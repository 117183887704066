import React, { Component } from 'react'
import RegistrationHeader from 'components/registration/registration-header'
import Field from 'util/components/field'
import NewBrandDispatcher from 'dispatchers/new-brand-dispatcher'
import Validator from 'util/form-validator'
import * as ReactRedux from 'react-redux'
import SelectField from 'util/components/select-field'
import queryString from 'query-string'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import ExistingBrandSupportModal from 'visual-components/util/modals/existing-brand-support-modal'
import * as ContactSupportActions from 'actions/contact-support-actions'
import { bindActionCreators } from 'redux'
import validQueryParams from 'util/valid-query-params'
import { H2, P } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import Spinner from 'util/components/spinner'
import theme from 'css/themes/login/registrationTheme.css'
import buttonTheme from 'css/themes/Buttons.css'
import * as metrics from 'util/metrics'
import _, { memoize } from 'lodash'

const track = memoize(action => () => {
  metrics.create('registrationFlow', {
    meta: {
      page: 'brandSetup',
      action,
    },
  })
})

const validations = {
  accountname: [Validator.required, Validator.UniqueBrandName],
  website: [Validator.required, Validator.UniqueBrandDomain, Validator.IsWebsite],
  phone: [Validator.required, Validator.IsPhoneNumber],
}

const mapState = function ({ newBrand, newBrandForm }) {
  return {
    newBrand,
    newBrandForm,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ContactSupportActions, dispatch),
})

class BrandSetup extends Component {
  state = {
    showExistingBrandModal: false,
  }

  openExistingBrandModal = () => {
    this.setState({ showExistingBrandModal: true })
  }

  closeExistingBrandModal = () => {
    this.setState({ showExistingBrandModal: false })
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props
    const parsedQueryString = queryString.parse(search)
    const { referral } = parsedQueryString
    const { promo } = parsedQueryString

    this.emptyForm()

    track('loadedBrandSetup')()

    if (promo) {
      track('hasPromo')()
      store.dispatch({
        model: 'newBrand',
        type: 'UPDATE_ATTR',
        data: {
          promo,
        },
      })
    }

    BrowserHistoryDispatcher.setHistory(this.props.history)
    window.scrollTo(0, 0)

    const urlParams = _.pick(parsedQueryString, validQueryParams)
    store.dispatch({
      model: 'newBrand',
      type: 'UPDATE_ATTR',
      data: urlParams,
    })

    // if referral code , check to make sure it's valid referral
    if (referral) {
      track('hasReferralCode')()
      NewBrandDispatcher.checkReferral(referral)
    }
  }

  emptyForm = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'newBrand',
      data: {
        accountname: '',
        phone: '',
        website: '',
      },
    })
  }


  updateAttr = (attr, value) => {
    const data = {}
    data[attr] = value
  }

  save = () => {
    const that = this
    const { history, newBrand } = this.props
    Validator.validateForm(validations, newBrand, 'newBrandForm', valid => {
      if (valid) {
        track('brandSetupFormIsValid')()
        store.dispatch({
          model: 'newBrand',
          type: 'LOADING_START',
        })
        NewBrandDispatcher.save(history)
      } else {
        track('brandSetupFormIsInvalid')()
      }
    })
  }

  onBrandNameBlur = e => {
    const myErrors = { errors: [] }
    Validator.UniqueBrandName.bind(myErrors)(e.target.value, {}, () => {
      if (myErrors.errors.length) setTimeout(this.openExistingBrandModal, 700)
    })
  }

  render() {
    const { newBrand: brand, newBrandForm: brandForm } = this.props

    const { showExistingBrandModal } = this.state

    const howDidYouHearOptions = [
      {
        label: 'Select an Option',
        value: '',
      },
      {
        label: 'Invited by another user',
        value: 'invited',
      },
      {
        label: 'Search engine (Google, Bing, Yahoo)',
        value: 'searchEngine',
      },
      {
        label: 'Advertisement',
        value: 'advert',
      },
      {
        label: 'Press/Blog',
        value: 'pressOrBlog',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ]

    const { loading } = this.props.newBrand
    return (
      <div>
        <RegistrationHeader step={2} />
        <div className={theme.container}>
          <div className={theme.headerTitle}>
            <H2 multiline>Brand Setup</H2>
          </div>
          {loading && <Spinner />}
          <div className={`${theme.subheaderTitle} ${theme.subheaderWithPaddingBottom}`}>
            <P multiline>
              Welcome! Tell us about yourself. Add this info to participate in giveaways, appear in
              search results and connect with partners.
            </P>
          </div>
          <Field
            dataModel={brand}
            formModel={brandForm}
            autoComplete="off"
            attrName="accountname"
            validations={validations.accountname}
            blur={this.onBrandNameBlur}
            label="Brand Name *"
          />
          <Field
            dataModel={brand}
            formModel={brandForm}
            attrName="website"
            validations={validations.website}
            label="Brand Website *"
          />
          <Field
            dataModel={brand}
            formModel={brandForm}
            validations={validations.phone}
            attrName="phone"
            label="Phone Number *"
          />
          <Field dataModel={brand} formModel={brandForm} attrName="promo" label="Promo Code" />
          <div>
            <SelectField
              attrName="howDidYouHear"
              options={howDidYouHearOptions}
              label="How did you hear about us?"
              dataModel={brand}
              formModel={brandForm}
            />
            <div>
              {!brand.howDidYouHear
              || brand.howDidYouHear === 'invited'
              || brand.howDidYouHear === 'other' ? (
                <Field
                  dataModel={brand}
                  formModel={brandForm}
                  attrName="referrer"
                  label="Referrer"
                  placeholder="Event, a friend, carrier pigeon..."
                />
                ) : null}
            </div>
          </div>
          <div className={theme.buttonWrapper}>
            <Button
              type="submit"
              onClick={this.save}
              className={
                !brand.phone && !brand.website && !brand.accountname
                  ? `${buttonTheme.disabledButton} ${buttonTheme.noMargin}`
                  : `${buttonTheme.blueButton} ${buttonTheme.noMargin}`
              }
              theme={buttonTheme}
              label="Create Brand"
              disabled={!brand.phone && !brand.website && !brand.accountname}
            />
          </div>
          <ExistingBrandSupportModal
            brand={brand}
            onClose={this.closeExistingBrandModal}
            open={showExistingBrandModal}
          />
        </div>
      </div>
    )
  }
}

export default ReactRedux.connect(mapState, mapDispatchToProps)(BrandSetup)
