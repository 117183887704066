import React from 'react';
import { object } from 'prop-types';

import { Card } from 'react-toolbox/lib/card';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H5, Tiny, Small } from 'visual-components/util/texts';

class SocialNumber extends React.Component {
  render() {
    var {
      label,
      valuePretty
    } = this.props;

    return (
      <div style={{marginTop: '12px'}}>
        <div
        style={{
          display: 'inline-block',
          width: '130px',
          textAlign: 'left'
        }}>
          <Small>
            {label}
          </Small>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '70px',
            textAlign: 'right'
          }}>
          <H5>
            {valuePretty}
          </H5>
        </div>
      </div>
    )
  }
}

class ContributionBarTooltip extends React.Component {
  render() {
    let {
      data,
      brandName,
      datePretty
    } = this.props;

    let socialRows = data.map((item, idx) => {
      return (
        <SocialNumber
          key={idx}
          {...item} />
      )
    })

    return (
      <Card style={{
        padding: '12px',
        width: '224px',
        height: '300px'
      }}>
        <Tiny>
          {datePretty}
        </Tiny>
        <div style={{marginTop: '12px'}}>
          <Small>
            <b>
              {brandName}
            </b>
          </Small>
        </div>
        {socialRows}
      </Card>
    )
  }
};

export default ContributionBarTooltip;
