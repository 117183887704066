import React from 'react'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function DeleteFollowupsModal() {
  const {
    followups,
    deleteID,
    deleteFollowup,
    setDeleteID,
  } = React.useContext(MobileMarketingContext)

  const index = followups.findIndex(({ id }) => id === deleteID)

  const hasFollowups = followups.slice(index + 1).length > 0

  const label = hasFollowups
    ? (
      <>
        Deleting a follow-up message will delete
        {' '}
        <strong>all subsequent messages as well.</strong>
        {' '}
        Are you sure you want to delete?
      </>
    ) : 'Are you sure you want to delete?'

  return (
    <SimpleInfoModal title="Before You Delete" active={!!deleteID}>
      <H4 multiline>
        {label}
      </H4>
      <ModalButtonGroup
        canSave
        confirm={deleteFollowup}
        cancel={() => setDeleteID(null)}
        confirmText="Delete"
        cancelText="Cancel"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(DeleteFollowupsModal)
