import React from 'react'
import CalendarTheme from 'css/themes/calendar/calendar.css'

const ShowMore = ({ showMore, number }) => {
  if (!number > 0) return null

  const msg = `${number} more`

  return (
    <div onClick={showMore} className={CalendarTheme.showMore}>
      { msg }
    </div>
  )
}

export default ShowMore
