import React, { Component } from 'react'
import { P, H6, Small } from 'visual-components/util/texts'
import InputContainer from 'visual-components/util/InputContainer'
import { DateRangePicker } from 'react-dates'
import { START_DATE, END_DATE } from 'react-dates/constants'
import moment from 'moment-timezone'
import 'react-dates/lib/css/_datepicker.css'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'
import ConfirmPartnershipInviteTheme from 'css/themes/dialogs/confirmPartnershipInviteDialog.css'
import InputTheme from 'css/themes/no-padding-input.css'
import { Input } from 'visual-components/util/form'


const formatDate = date => moment(date).format('MMMM Do, YYYY')

class CampaignPartnerDetails extends Component {
  state = { focusedInput: START_DATE }

  updateValue = name => value => {
    this.props.updateData({
      [name]: value,
    })
  }

  isDayOutsideRange = day => !day.isBetween(this.props.campaign.startDate, this.props.campaign.endDate, null, '[]')

  onDatesChange = dates => {
    const { startDate, endDate } = dates
    this.props.updateData({
      promotionEndDate: endDate && endDate.format(),
      promotionStartDate: startDate && startDate.format(),
    })
  }

  onFocusChange = focus => {
    this.setState({ focusedInput: focus })
  }

  showCalendar = () => {
    this.setState({ focusedInput: START_DATE })
    this.props.toggleDatePicker(true)
  }

  render() {
    const {
      updateData,
      nextStep,
      closeModal,
      applicationData,
      confirm,
      datePickerOpen,
      toggleDatePicker,
      campaign,
      partnerListSize,
      partnerExpectedEntries,
      toolTip
    } = this.props

    return (
      <div>
        { !!partnerListSize ?
          <div className={ ConfirmPartnershipInviteTheme.listEntriesInfo }>
            <div>
              <H6>My List Size</H6>
              <div className= {ConfirmPartnershipInviteTheme.stat }><P>{ partnerListSize }</P></div>
            </div>

            <div>
              <div className={ ConfirmPartnershipInviteTheme.withToolTip }>
                <H6>Expected Entries</H6>
                <div className= { ConfirmPartnershipInviteTheme.toolTip }>
                  <BlueInfoTooltip
                    text={toolTip}
                  />
                </div>
              </div>
              <div className= {ConfirmPartnershipInviteTheme.stat }><P>{ partnerExpectedEntries }</P></div>
            </div>
          </div>
          : null
        }

        <div className={ ConfirmPartnershipInviteTheme.proposedEntry }>
          <div className={ ConfirmPartnershipInviteTheme.proposedEntrySubtitle }>
            <H6>Proposed Entry Contribution</H6>
            <Small>How many entries will come from your list? We suggest offering 1% of your list size.</Small>
          </div>

          <div className={ ConfirmPartnershipInviteTheme.input }>
            <Input
              name="applicationData.entryContribution"
              placeholder={partnerExpectedEntries}
            />
          </div>
        </div>

        <div className={ ConfirmPartnershipInviteTheme.prizingContribution }>
          <div>
            <Input
              type="text"
              label="Prizing Contribution"
              name="applicationData.prizingContribution"
              placeholder="What would you contribute? Include its market value."
            />
          </div>
        </div>

        <div className={ ConfirmPartnershipInviteTheme.promoDates} >
          <InputContainer label="Available Promotion Dates">
            <P>
              Campaign Dates: {formatDate(campaign.startDate)} - {' '}
              {formatDate(campaign.endDate)}
            </P>
            {datePickerOpen ? (
              <DateRangePicker
                onDatesChange={ this.onDatesChange }
                onFocusChange={ this.onFocusChange }
                onClose={ () => toggleDatePicker(false) }
                isOutsideRange={ this.isDayOutsideRange }
                focusedInput={ this.state.focusedInput }
                startDate={
                  applicationData.promotionStartDate
                    ? moment(applicationData.promotionStartDate)
                    : null
                }
                endDate={
                  applicationData.promotionEndDate
                    ? moment(applicationData.promotionEndDate)
                    : null
                }
                startDatePlaceholderText="Start Date"
                endDatePlaceholderText="End Date"
                navPrev={<img src="/images/icons/left-arrow.svg" />}
                navNext={<img src="/images/icons/right-arrow.svg" />}
                hideKeyboardShortcutsPanel={true}
                initialVisibleMonth={() => moment(campaign.startDate)}
              />
            ) : (
              <div>
                {applicationData.promotionStartDate &&
                applicationData.promotionEndDate ? (
                  <P>
                    Your Availability:{' '}
                    {formatDate(applicationData.promotionStartDate)} -{' '}
                    {formatDate(applicationData.promotionEndDate)}
                  </P>
                ) : null}
                <div style={{minHeight: '4px'}}></div>
                <a style={{color: '#03a9f4'}}
                  onClick={this.showCalendar}>
                  {applicationData.promotionStartDate &&
                  applicationData.promotionEndDate
                    ? 'Change your available dates'
                    : 'Select your available dates'}
                </a>
              </div>
            )}
          </InputContainer>
        </div>

        <div className={ ConfirmPartnershipInviteTheme.optionalNote }>
            <Input
              multiline
              rows={3}
              className={InputTheme.textarea}
              name="applicationData.optionalNote"
              label="Note to Host"
              placeholder="Anything else you'd like the Host to know?"
            />
        </div>
      </div>
    )
  }
}

export default CampaignPartnerDetails
