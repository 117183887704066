import React from 'react';
import { func, bool } from 'prop-types';

import appbarTheme from 'css/themes/homePage/appbarTheme.css';

class ClosedSearchBar extends React.Component {
  static proptypes = {
    onclick: func.isRequired,
    lightBackground: bool
  }

  render() {
    const {
      lightBackground
    } = this.props;

    let {
      onclick
    } = this.props;

    return(
      <div onClick={ e => onclick() }
        style={{
          paddingTop: '7px'
      }}>
        { lightBackground
          ? <img src="/images/icons/ink-mag-glass.svg" />
          : <img src="/images/icons/white-mag-glass.svg" />
        }
      </div>
    )
  }
}

export default ClosedSearchBar;
