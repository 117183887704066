import React from 'react';
import Dialog from 'react-toolbox/lib/dialog';
import { Input, Form } from 'visual-components/util/form'
import { isPositiveInteger } from 'visual-components/util/form/validations'
import { bindActionCreators } from 'redux'

import dialogTheme from 'css/themes/dialogs/formModal.css'

import * as ReactRedux from 'react-redux';

import * as EmailReleaseActions from 'actions/email-release-actions';

import { P, LabelValue, H4, H2 } from 'visual-components/util/texts'

import Spinner from 'util/components/spinner'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext';

const mapDispatchToProps = (dispatch) => {
  return {
    emailReleaseActions: bindActionCreators(EmailReleaseActions, dispatch),
    dispatch: dispatch
  }
};

const mapStateToProps = (state) => {
  return {
    emailRelease: state.emailRelease,
    currentCampaign: state.currentCampaign
  }
}
class EmailReleaseFormModal extends React.Component {

  next = () => {
    const {
      emailRelease,
      emailReleaseActions
    } = this.props

    const stepMap = {
      selectNumber: emailReleaseActions.confirm,
      confirmation: emailReleaseActions.release,
      success: emailReleaseActions.closeModal
    }

    stepMap[emailRelease.step](emailRelease)
  }
  getTitle = () => {

    let {
      emailRelease,
    } = this.props;

    const partnerBrand = emailRelease.partnershipInvite.invitee.accountname

    const stepMap = {
      selectNumber: `Release Emails for ${partnerBrand}`,
      confirmation: 'Release Emails Confirmation',
      success: `We've Released ${d3.format(',')(emailRelease.numberToRelease)} Total Entries to ${partnerBrand}`
    }

    return stepMap[emailRelease.step]
  }
  nextStep(step) {
    return {
      selectNumber: 'Next',
      confirmation: 'Confirm',
      success: 'Done'
    }[step]
  }
  render() {
    let {
      emailReleaseActions,
      emailRelease,
      currentCampaign
    } = this.props;

    if (emailRelease.modalOpen && emailRelease.loading) {
      return <Spinner />
    }

    const invite = currentCampaign.invites.find( invite => { return invite.id === parseInt(emailRelease.inviteId) })

    if (!invite) {
      return <div />
    }

    return (
      <Dialog
        theme={ dialogTheme }
        active={ emailRelease.modalOpen }
        onEscKeyDown={ emailReleaseActions.closeModal }
        onOverlayClick={ emailReleaseActions.closeModal }
        title={this.getTitle()}
      >
        <Form
          modelName="emailRelease"
          updateModel={emailReleaseActions.update}
          mergeFieldsImmediately
          handleSubmit={this.next}
        >
          { emailRelease.step === 'selectNumber'  &&
            <div>
              <P>Release emails to Partners during or after a campaign.</P>
              <div>
                <LabelValue label="Total Entries Driven" value={d3.format(',')(emailRelease.totalEntriesDriven)} marginRight={33} />
                <LabelValue label="Partner Entries Driven" value={d3.format(',')(emailRelease.partnerEntriesDriven)} marginRight={33} />
                <LabelValue label="Minimum Threshold" value={d3.format(',')(emailRelease.minimumDelivery)} marginRight={33} />
                <LabelValue label="Percentage Reached" value={d3.format('%')(emailRelease.percentagReached)} marginRight={0} />
              </div>
              <div style={{marginTop: '32px'}}>
                <Input
                  name="numberToRelease"
                  label={`Entries to be Released / Percentage of Total Entries`}
                  type="number"
                  placeholder="1,000"
                  percentageOf={emailRelease.totalEntriesDriven}
                  required
                  validations={[
                    isPositiveInteger,
                    (value) => {
                      const number = value.replace ? parseInt(value.replace(/,/g, '')) : value
                      if (number <= emailRelease.totalAlreadyReleased) {
                        throw('Must be greater than whats already been released')
                      }
                    },
                    (value) => {
                      const number = value.replace ? parseInt(value.replace(/,/g, '')) : value
                      if (number > emailRelease.totalEntriesDriven) {
                        throw('Cannot be greater than entries in campaign')
                      }
                    }
                  ]}
                />
                <div style={{marginTop: '-20px', marginBottom: '32px'}}>
                  <LabelValue label="Entries Already Released to Partner" value={d3.format(',')(emailRelease.totalAlreadyReleased)} marginRight={33} />
                </div>
                <H2>
                  { `TOTAL: ${d3.format(',')(emailRelease.numberToRelease)} (${d3.format('%')(emailRelease.numberToRelease / emailRelease.totalEntriesDriven)} of entries)` }
                </H2>
                <br />
              </div>
            </div>
          }
          { emailRelease.step === 'confirmation' &&
            <div>
              <div style={{
                paddingBottom: '24px'
              }}>
                <H4 multiline>
                  <b>{d3.format(',')(emailRelease.numberToRelease)}</b> entries will be released for <b>{emailRelease.partnershipInvite.invitee.accountname}</b>,
                    this represents <b>{d3.format('%')((emailRelease.totalAlreadyReleased + emailRelease.numberToRelease) / emailRelease.minimumDelivery)}</b> of the partner's minimum threshold.
                </H4>
              </div>
              <div style={{
                paddingBottom: '24px'
              }}>
                <H4 multiline>
                  Entries are calculated before email filtering. Note the number of emails
                  released may be smaller than entries depending on their email filter settings.
                </H4>
              </div>
            </div>
          }
          { emailRelease.step === 'success' &&
            <div>
              <div style={{
                marginRight: '-32px',
                marginLeft: '-32px',
                paddingTop: '8px',
                marginBottom: '24px'
              }}>
                <img src="/images/icons/message-sent.png" />
              </div>
              <div>
                <div style={{
                  paddingBottom: '24px'
                }}>
                  <H4 multiline>
                    Entries are on their way! Remember their email filtering
                    settings may affect the number of emails received.
                  </H4>
                </div>
              </div>
            </div>
          }
          <ModalButtonGroupWithContext
            canSave
            cancel={ emailReleaseActions.closeModal }
            confirm={ this.next }
            confirmText={ this.nextStep(emailRelease.step) }
          />
        </Form>
      </Dialog>
    )
  }
}

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(EmailReleaseFormModal);
