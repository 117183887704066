var model = 'userBrands';
import _ from 'lodash'

const defaultState = {
  rid: model,
  loading: true,
  brands: []
}
const UserBrandsReducer = function (state, action) {

  if (action.model !== model) {
    return state || defaultState;
    // returns the given state or the default state
  }
    
  const actions = {
    // Add initial brands all at once
    LOAD_BRANDS() {
      const brands = action.data 
        ? action.data.sort((brandA, brandB) => {
          return brandA.accountname && brandA.accountname.localeCompare(brandB.accountname)
        })
        : []

      return Object.assign({}, state, { brands, loading: false })
    },

    ADD_BRAND: function() {
      var brands = _.clone( state.brands );
      brands.push( action.data );
      // combines brands from the state with the one passed in

      var sortedBrands = _.sortBy( brands, function (brand) {
        return brand.accountname.toLowerCase()
        // sort by name
      })
      return _.extend({}, state, { brands: sortedBrands });
    },

    UPDATE_BRAND: function() {
      var brands = _.clone( state.brands );
      // clone all the of the brands

      var brand = _.find( brands, { id: action.data.id } )
      // point at the given brand we want to update

      _.extend( brand, action.data );
      // put in new data

      return _.extend({}, state, { brands: brands });
      // return the new list of brands with the new data 
    },

    LOAD_UNREADS: function() {
      let brands = state.brands.slice();
      var unreads = action.data;
      _.each(unreads, (unread) => {
        let idx = _.findIndex(brands, {id: unread.id})
        if(idx != -1) {
          brands[idx] = _.extend({}, brands[idx], {
            unreadMessages: unread.unread_messages
          })
        }
      })
      return _.extend({}, state, {brands: brands})
    }
  }

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default UserBrandsReducer;
