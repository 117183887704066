import React, { Component } from 'react'
import propTypes from 'prop-types'
import { debounce } from 'lodash'
import * as metrics from 'util/metrics'
import COLOURS from 'util/colours'
import FormGroup from 'util/components/form-group'
import SimpleColorPicker from 'util/components/color-picker'
import injectSheet from 'react-jss'
import LanderSectionHeader from 'components/campaigns/builder/lander/lander-section-header'
import { Small } from 'visual-components/util/texts'
import StyleChoices from '../../StyleChoices'
import InputContainer from '../InputContainer'

const landerSectionHeaderStyle = { marginBottom: '3px' }
const themeLanderSectionHeaderStyle = { marginBottom: '2px' }

const inputCheck = {
  marginBottom: '32px',
  height: '12px !important',
  display: 'block',
}

const input = {
  width: '12px',
  height: '12px',
  marginTop: '-10px',
  backgroundSize: '10px',
  backgroundPositionY: '2px',
}

const inputLabel = {
  fontFamily: 'Larsseit-Light',
  fontSize: '12px',
  lineHeight: '1',
  display: 'inline',
}

const styleTabTransitions = {
  preview: {
    border: `solid 1px ${COLOURS.silver}`,
    padding: '16px 20px',
  },
  themeColors: {
    whiteSpace: 'nowrap',
    marginBottom: '32px',
    display: 'flex',
    '& > div': {
      marginRight: 96,
    },
  },
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    flex: '0 50%',
    marginRight: 48,
  },
  right: {
    flex: '0 50%',
  },
  buttonContainer: {
    marginTop: 10,
  },
}

class StyleTab extends Component {
  static propTypes = {
    allPartners: propTypes.array.isRequired,
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    customFonts: propTypes.array.isRequired,
    desktopVersion: propTypes.bool.isRequired,
    elements: propTypes.object.isRequired,
    isHost: propTypes.bool,
    termsLink: propTypes.string.isRequired,
    updateElementsAttr: propTypes.func.isRequired,
    updateAttr: propTypes.func.isRequired,
  }

  static defaultProps = {
    isHost: null,
  }

  track = debounce(data => {
    metrics.create('campaignManagerUpdatedFormFieldAttribute', {
      meta: data,
    })
  }, 500)

  updateFormStyles = (attr, value) => {
    const { updateElementsAttr, campaign: { id: campaignId } } = this.props
    this.track({ attr, value, campaignId })
    updateElementsAttr(attr, value)
  }

  render() {
    const {
      campaign,
      classes: css,
      updateAttr,
      isHost,
      elements,
      updateElementsAttr,
      customFonts,
      allPartners,
      desktopVersion,
      termsLink,
    } = this.props

    const campaignInputs = campaign.inputs
    const allowed = ['email']
    const onlyEmail = Object.keys(campaignInputs)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = campaignInputs[key]
        return obj
      }, {})
    const { isSolo } = campaign

    return (
      <div className={css.parent}>
        <div className={css.left}>
          <LanderSectionHeader
            style={landerSectionHeaderStyle}
            headerText="Entry Field Lables"
          />
          <Small multiline>
            Select whether you would like the entry field labels to appear
            within the fields or above them.
          </Small>
          <FormGroup
            updateAttr={updateElementsAttr}
            editable={isHost}
            model={elements}
            type="checkbox"
            attrName="inputLabels"
            label="Show labels above entry fields"
            styles={[inputCheck]}
            inputStyles={input}
            labelStyles={[inputLabel]}
          />
          <LanderSectionHeader
            style={themeLanderSectionHeaderStyle}
            headerText="Theme Colors"
          />
          <div className={css.themeColors}>
            <SimpleColorPicker
              attr="formTextColor"
              updateAttr={this.updateFormStyles}
              model={elements.styles.fonts}
              label="Text"
            />
            <SimpleColorPicker
              attr="formContainerColor"
              updateAttr={this.updateFormStyles}
              model={elements.styles.fonts}
              label="Text Field"
            />
            <SimpleColorPicker
              attr="entryButtonText"
              updateAttr={updateElementsAttr}
              model={elements.styles.fonts}
              label="Button Text"
            />
            <SimpleColorPicker
              attr="entryButtonColor"
              updateAttr={updateElementsAttr}
              model={elements.styles.fonts}
              label="Links & Controls"
            />
          </div>
          <LanderSectionHeader
            style={themeLanderSectionHeaderStyle}
            headerText="SMS Opt-In Section"
          />
          <div className={css.themeColors}>
            <SimpleColorPicker
              attr="smsOptinTextColor"
              updateAttr={this.updateFormStyles}
              model={elements.styles.fonts}
              label="Text"
            />
            <SimpleColorPicker
              attr="smsOptinHeadlineColor"
              updateAttr={this.updateFormStyles}
              model={elements.styles.fonts}
              label="Headline"
            />
            <SimpleColorPicker
              attr="smsOptinBackgroundColor"
              updateAttr={updateElementsAttr}
              model={elements.styles.fonts}
              label="Background"
            />
            <SimpleColorPicker
              attr="smsOptinButtonColor"
              updateAttr={updateElementsAttr}
              model={elements.styles.fonts}
              label="Checkbox"
            />
          </div>
          <LanderSectionHeader
            style={landerSectionHeaderStyle}
            headerText="Button"
          />
          <div className={css.buttonContainer}>
            <StyleChoices
              attr="entryButtonText"
              updateAttr={updateElementsAttr}
              elements={elements}
              styles={elements.styles}
              customFonts={customFonts}
              notScrolling
              isSoloCampaign={isSolo}
            />
          </div>
        </div>

        <div className={css.right}>
          <LanderSectionHeader
            style={landerSectionHeaderStyle}
            headerText="Preview"
          />

          <div className={css.preview}>
            <InputContainer
              campaign={campaign}
              inputs={onlyEmail}
              includeCustomField={campaign.includeCustomField}
              customFieldName={campaign.customFieldName}
              additionalTermsDetail=""
              entryButtonText={elements.entryButtonText}
              smsOptinHeadlineText={elements.smsOptinHeadlineText}
              smsOptinLanguage={elements.smsOptinLanguage}
              formStyles={elements.styles.fonts}
              preCheck={campaign.preCheck}
              allPartners={allPartners}
              perBrandTerms={false}
              styles={elements.styles}
              termsLink={termsLink}
              elements={elements}
              darkTemplateTheme={false}
              desktopVersion={desktopVersion}
              updateAttr={updateAttr}
              removeCustomFields
            />
          </div>
          <br />
        </div>
      </div>
    )
  }
}

export default injectSheet(styleTabTransitions)(StyleTab)
