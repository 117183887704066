import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Helmet from 'react-helmet'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import Axios from 'axios'
import { Button } from 'react-toolbox/lib/button'

import ButtonTheme from 'css/themes/Buttons.css'

import Spinner from 'util/components/spinner'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import {
  H2,
  H3,
  H6,
  P,
  Small,
} from 'visual-components/util/texts'

import * as MetricsActions from 'actions/analytics-actions'

import MMAgreementTheme from 'css/themes/agreement/mediamarket-agreement.css'
import MediaMarketTOUTheme from 'css/themes/mediamarket-tou.css'
import MediaMarketAcceptTOU from '../mediaMarket/confirmMedia/MediaMarketAcceptTOU'

const stateToProps = ({ currentBrand }) => ({
  currentBrand,
  isSeller: currentBrand.features.mediaMarketSeller === true,
})

const dispatchToProps = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

const loadEmpty = (isSeller, onPreview = () => { }) => (
    <div className={MMAgreementTheme.empty}>
      <div style={{ width: '252px', padding: '0 20px', textAlign: 'center' }}>
        <div style={{ width: '184px', margin: 'auto' }}>
          <Small multiline cloudGrey>
            There are currently no Media Market Agreements.
          </Small>
        </div>
        <div style={{ marginBottom: '24px' }} />
        <Small multiline cloudGrey>
          {
            isSeller
              ? (
                <span>
                  To start selling with Media Market, you need to agree to our&nbsp;
                  <a onClick={onPreview}>
                    Service Agreement
                  </a>
                </span>
              )
              : (
                <span>
                  Get started on&nbsp;
                  <Link to="/partnerships/media-market/in-discussion">
                    Media Market
                  </Link>
                </span>
              )
          }
          .
        </Small>
      </div>
    </div>
  )

class AgreementList extends Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isSeller: PropTypes.bool.isRequired,
  }

  static contextTypes = {
    impersonating: PropTypes.string,
  }

  state = {
    preview: false,
    canAcceptTerms: false,
    which: null,
    loading: true,
    data: [],
  }

  containerElement = null

  async componentDidMount() {
    const { match: { params } } = this.props

    await this.fetchTerms()

    this.containerElement = document.querySelector('header + div')

    if (params.which) this.onPreview(params.which)()
  }

  componentWillUnmount() {
    this.containerElement = null
  }

  onPreviewClose = () => this.setState({ preview: false })

  onDownload = which => {
    const { currentBrand: { id: brandId } } = this.props
    const { impersonating } = this.context

    const link = document.createElement('a')

    link.download = 'media_agreements.zip'
    link.href = `/terms-of-use/media/download/${which}/${brandId}${impersonating && !['undefined', 'null'].includes(impersonating) ? `?impersonating=${impersonating}` : ''}`
    link.click()
  }

  onDownloadSingle = which => () => this.onDownload(which)

  onPreview = (which, canAcceptTerms = false) => () => this.setState({ which, preview: true, canAcceptTerms }, () => {
    const { metricsActions } = this.props

    metricsActions.create('brandViewedMMToS', {
      meta: {
        from: 'Brand Settings',
        type: which,
      },
    })
  })

  onClosePreview = () => {
    this.setState({
      which: null,
      preview: false,
      canAcceptTerms: false,
    }, this.fetchTerms)
  }

  loadHeader = () => {
    const { data } = this.state

    return (
      <div className={MMAgreementTheme.row}>
        <div>
          <H6>
            Document
          </H6>
        </div>
        <div>
          <H6>
            Agreed By
          </H6>
        </div>
        <div>
          <H6>
            Email
          </H6>
        </div>
        <div>
          <H6>
            Date
          </H6>
        </div>
        {
          data.length
            ? (
              <div>
                <Button
                  raised
                  onClick={this.onDownloadSingle('all')}
                  label="Download All"
                  className={ButtonTheme.newTask}
                />
              </div>
            ) : null
        }
      </div>
    )
  }

  loadDocuments = () => {
    const { data } = this.state
    const { isSeller } = this.props

    if (!data.length) return loadEmpty(isSeller, this.onPreview('publisher', true))

    const items = data.map(item => {
      const {
        agreement_type: type,
        user: {
          uid,
          firstName,
          lastName,
          email,
        },
        accepted_at: date,
      } = item

      const by = `${firstName} ${lastName}`

      return (
        <div key={uid} className={MMAgreementTheme.row}>
          <div>
            <H6>
              {`${type} Agreement`}
            </H6>
          </div>
          <div>
            <P>
              {by}
            </P>
          </div>
          <div>
            <P>
              {email}
            </P>
          </div>
          <div>
            <P>
              {moment(date).format('MMM D, YYYY')}
            </P>
          </div>
          <div>
            <div className={MMAgreementTheme.clickable} onClick={this.onPreview(type)}>
              <img src="/images/icons/blue-view.svg" />
            </div>
            <div className={MMAgreementTheme.clickable} onClick={this.onDownloadSingle(type)}>
              <img src="/images/icons/blue-download.svg" />
            </div>
          </div>
        </div>
      )
    })

    return items
  }

  async fetchTerms() {
    const { currentBrand: { id } } = this.props
    const { impersonating } = this.context

    this.setState({ loading: true })

    try {
      const { data } = await Axios({
        method: 'GET',
        url: `/terms-of-use/media${impersonating && !['undefined', 'null'].includes(impersonating) ? `?impersonating=${impersonating}` : ''}`,
        headers: {
          brand: id,
        },
      })

      return this.setState({ data, loading: false })
    } catch (err) {
      console.log({ err })
    }

    return this.setState({ loading: false })
  }

  render() {
    const {
      preview,
      which,
      loading,
      canAcceptTerms,
    } = this.state

    if (loading) return <Spinner />

    return (
      <React.Fragment>
        <div style={{ paddingTop: '32px' }}>
          <Helmet>
            <title>Media Market Agreements</title>
          </Helmet>
          <Grid>
            <Row style={{ marginBottom: '24px' }}>
              <Col xs>
                <H2 azure>
                  Media Market Agreements
                </H2>
              </Col>
            </Row>
            <Row style={{ marginBottom: '16px' }}>
              <Col xs>
                <H3 coral>
                  <small>My Documents</small>
                </H3>
              </Col>
            </Row>
            <Row>
              <div className={MMAgreementTheme.table}>
                {this.loadHeader()}
                {this.loadDocuments()}
              </div>
            </Row>
          </Grid>
        </div>
        {
          preview
          && (
            ReactDOM.createPortal(
              <div className={MediaMarketTOUTheme.snapToTop}>
                <MediaMarketAcceptTOU
                  onBtnClick={this.onClosePreview}
                  which={which}
                  isPreview={!canAcceptTerms}
                />
              </div>,
              this.containerElement
            )
          )
        }
      </React.Fragment>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(AgreementList))
