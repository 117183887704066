import React, { Component } from 'react'
import { H3, Small } from 'visual-components/util/texts'
import TableHeader from './yourReferralTable/tableHeader'
import { Card } from 'react-toolbox/lib/card'
import TableRow from './yourReferralTable/tableRow'
import theme from 'css/themes/referralTheme.css'

class YourReferrals extends Component {
  render() {
    const {
      referrals,
      openReminderModal
    } = this.props

    let referralRows = referrals.slice(0).reverse().map((referral, idx) => {
      return(
        <TableRow
          key={idx}
          referral={referral}
          openReminderModal={openReminderModal}
        />
      )
    })

    return (
      <div>
        <div className={theme.yourReferralHeader}>
          <H3 coral>
            <small>
              Your Referrals
            </small>
          </H3>
        </div>
        <TableHeader />
        <Card className="">
          { referralRows.length > 0
            ? referralRows
            : <div className={theme.emptyStateWrapper}>
                <Small multiline cloudGrey>
                  <i>
                    You haven’t referred anyone to DojoMojo yet!
                  </i>
                </Small>
              </div>
          }
        </Card>
      </div>
    )
  }
}

export default YourReferrals
