import React from 'react'
import PropTypes from 'prop-types'
import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

class NoMessagesWarningModal extends React.Component {
  render() {
    const { show, cancel, purchase } = this.props

    return (
      <SimpleInfoModal title="You Are Out of Messages" active={show}>
        <H4 multiline>
          You have no more SMS Marketing messages in your account. You must purchase more messages
          in order to launch your SMS Marketing Post Entry Action.
        </H4>
        <ModalButtonGroup
          canSave
          confirm={purchase}
          cancel={cancel}
          confirmText="Purchase Messages"
          cancelText="Cancel"
          hideLine
        />
      </SimpleInfoModal>
    )
  }
}
export default NoMessagesWarningModal

NoMessagesWarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  purchase: PropTypes.func.isRequired,
}
