import React from 'react';
import OpportunityCard from './opportunity-card';
import InterestValue from '../interests/interest-value';
import d3 from 'd3'
import { formatRange } from './formatRange'

class DedicatedEmailOpportunityCard extends React.Component {
  render() {
    let {
      opportunity,
      brand,
      brand: { obscure },
      isBrand,
      action,
      showActions,
      hideBar,
      editOpportunity,
      shareOpportunity,
      deleteOpportunity
    } = this.props

    return (
      <OpportunityCard
        opportunity={ opportunity }
        brand={ brand }
        action={ action }
        editOpportunity={ editOpportunity }
        deleteOpportunity={ deleteOpportunity }
        shareOpportunity={ shareOpportunity }
        isBrand={isBrand}
        showActions={showActions}
        hideBar={hideBar}
      >
        <InterestValue
          title='List Size'
          value={ formatRange(opportunity.min_list_size, opportunity.max_list_size) }
          marginRight='67px'
          marginBottom='0px'
          obscure={obscure}
        />
        <InterestValue
          title='CPM'
          value={formatRange(opportunity.min_target_cpm, opportunity.max_target_cpm, 'money')}
          marginRight='64px'
          marginBottom='0px'
          obscure={obscure}
        />
        <InterestValue
          title='CPC'
          value={formatRange(opportunity.min_target_cpc, opportunity.max_target_cpc, 'money')}
          marginBottom='0px'
          paddingLeft= '12px'
          obscure={obscure}
        />
      </OpportunityCard>
    )
  }
}

export default DedicatedEmailOpportunityCard;
