import React from 'react';
import COLOURS from 'util/colours';
import { Link } from 'react-router-dom';
import appbarTheme from 'css/themes/homePage/appbarTheme.css';

import FeaturePageItemLi from './feature-page-item-li';

class FeatureDropdown extends React.Component {
  render() {
    let {
      lightBackground
    } = this.props;

    return (
      <div className={ appbarTheme.dropdownWrapper }>
        <div className={ appbarTheme.featureLink }
          style={{
            color: lightBackground ? COLOURS.ink : COLOURS.white
        }}>
          Features
        </div>
        <div style={{
          position: 'relative'
        }}>
          <ul className={appbarTheme.dropdown}>
            <FeaturePageItemLi
              to="/sweepstakes"
              title="Sweepstakes"
              description="Build beautiful sweepstakes, no developer required"
            />
            <FeaturePageItemLi
              to="/sms-marketing"
              title="SMS Marketing"
              description="Drive engagement & conversions through SMS"
            />
            <FeaturePageItemLi
              to="/network"
              title="Network"
              description="Connect with our network of over 5,000 brands"
            />
          </ul>
        </div>
      </div>
    )
  }
}

export default FeatureDropdown;
