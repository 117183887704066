export const openDialog = (model, url) => ({
  model,
  data: {
    open: true,
    url: url
  },
  type: 'UPDATE_ATTR'
});

export const closeDialog = model => ({
  model,
  data: {
    open: false
  },
  type: 'UPDATE_ATTR'
});


export const openEditing = (model) => ({
  model,
  data: {
    editing: true
  },
  type: 'UPDATE_ATTR'
});

export const closeEditing = (model) => ({
  model,
  data: {
    editing: false
  },
  type: 'UPDATE_ATTR'
});

export const dialogUpdateWithPayload = (model, data) => ({
  model,
  data,
  type: 'UPDATE_ATTR'
})

export const openDialogWithPayload = (model, data) => ({
  model,
  data: Object.assign({}, data, { open: true} ),
  type: 'UPDATE_ATTR'
})