import React from 'react'
import { P, Small } from 'visual-components/util/texts'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import injectCSS from 'react-jss'
import cn from 'classnames'

const styles = {
  textRight: {
    textAlign: 'right',
  },
}

const Cell = props => {
  const { value: data, classes: css } = props

  const renderLink = (linkName, link) => (
    <P multiline>
      <Link to={link}>
        {linkName}
      </Link>
    </P>
  )

  const renderValue = ({primary, secondary = null}) => (
    <>
      <P multiline>{primary}</P>
      {
        !!secondary
        && (
          <Small>
            <i>{secondary}</i>
          </Small>
        )
      }
    </>
  )

  return (
    <div className={cn({ [css.textRight]: !data.link })}>
      {
        data.link ? renderLink(data.name, data.link) : renderValue(data)
      }
    </div>
  )
}

Cell.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
}

const StyledCell = injectCSS(styles)(Cell)

export default React.memo(StyledCell)
