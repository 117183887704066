import React, { Component } from 'react';
import CareerBanner from './career-banner';
import MobileFooter from '../mobile-footer/MobileFooter';
import ImgAndContent from '../util/img-and-content';
import CurrentOpenings from './current-openings';

import theme from 'css/themes/mobile/home/careers/careers.css';

class MobileCareers extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  render() {
    return(
      <div>
        <CareerBanner />
        <div className={ theme.careersPageContent }>
          <p className={ theme.sectionHeader }>
            Perks & Benefits
          </p>
          <p className={ theme.sectionExplain }>
            Enjoy coming to work every day.
          </p>
          <ImgAndContent
            imgSrc={ '/images/login/mobile/careers/health-benefits-01.svg' }
            imgClass={ 'health-benefits-01' }
            title={ 'Health Coverage' }
            explain={ 'We offer competitive medical, dental and vision benefits - choose the plan that’s best for you. Not feeling well? We’ll encourage you to rest (and take advantage of your free One Medical membership).' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/careers/breakfast-lunch-01.svg' }
            imgClass={ 'breakfast-lunch-01' }
            title={ 'Weekly Breakfast & Lunch' }
            explain={ 'Dig in! We provide a breakfast spread every Monday and lunch every Friday.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/careers/fitness-01.svg' }
            imgClass={ 'fitness-01' }
            title={ 'Fitness Classes' }
            explain={ 'Take advantage of our corporate gym membership at New York Health and Racquet Clubs across the city. Feeling competitive? Spar in our group boxing classes or join the volleyball team.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/careers/soho.svg' }
            imgClass={ 'soho' }
            title={ 'A Beautiful SoHo Office' }
            explain={ 'Work from a loft in one of New York’s best neighborhoods. Explore local restaurants, bars and shopping in the heart of downtown NYC.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/careers/vacation-01.svg' }
            imgClass={ 'vacation-01' }
            title={ 'Unlimited Vacation' }
            explain={ 'We trust you to get your work done and encourage you to take hard-earned rest (yes - that includes Summer Fridays).' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/careers/team-outings-01.svg' }
            imgClass={ 'team-outings-01' }
            title={ 'Team Events' }
            explain={ 'Enjoy game and movie nights, book club, wine tastings and other chill fun with the team.' }
          />
        </div>
        <CurrentOpenings />
        <MobileFooter />
      </div>
    )
  }
};

export default MobileCareers;
