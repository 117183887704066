import React, { Component } from 'react'
import Dropdown from 'react-toolbox/lib/dropdown'
import dropTheme from 'css/themes/PurchaseDropdown.css'

class SocialActionsDropdown extends Component {
  handleChange = campaignId => {
    this.props.handleCampaignChange(campaignId)
  }

  render() {
    const { value, upcomingCampaigns, currentCampaigns } = this.props

    const options = upcomingCampaigns.concat(currentCampaigns)

    const formattedOptions = options
      .filter(campaign => !campaign.started) // Removes active campaigns
      .map(campaign => ({
        label: campaign.name,
        value: campaign.id,
      }))

    return (
      <Dropdown
        auto
        theme={dropTheme}
        onChange={this.handleChange}
        source={formattedOptions}
        value={value}
        style={{ marginRight: '95%' }}
        placeholder="Please Select"
      />
    )
  }
}

export default SocialActionsDropdown
