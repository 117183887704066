import { useMemo, useEffect, useState } from 'react'

import useMobileDetect from './useMobileDetect'

const MOBILE_ROWS_CONST = 10

const defaultCfg = {
  rowsPerPage: 25,
  sortOrder: null,
  page: 0,
  sortColumn: null,
  formatter: data => data,
}

function numberSort(column, order) {
  return (a, b) => (a[column] - b[column]) * order
}

function dateSort(column, order) {
  return (a, b) => (new Date(a[column]) - new Date(b[column])) * order
}

function stringSort(column, order) {
  return (a, b) => `${(order > 0 ? a : b)[column]}`.localeCompare(`${(order > 0 ? b : a)[column]}`)
}

function useTable({ data, ...config }) {
  const conf = { ...defaultCfg, ...config }
  const { tableConfig } = conf

  // Amount of rows to show
  const [rowsNumber, setRowsNumber] = useState(tableConfig.rowsPerPage || conf.rowsPerPage)
  // Column to sort by
  const [sortColumn, setSortColumn] = useState(conf.sortColumn)
  // Order to sort by
  const [sortOrder, setSortOrder] = useState(conf.sortOrder)
  // Current page
  const [page, setPage] = useState(conf.page)
  // Rows: Data to show
  const [rows, setRows] = useState([])
  // Filters to filter by
  // Signature: { ['column name']: val => val === 'Draft' } or { ['column name']: value }
  // return true for values to show
  const [filterBy, setFilterBy] = useState([])
  // Total pages
  const [totalPages, setTotalPages] = useState(0)
  // Total Items (filtered or not)
  const [totalItems, setTotalItems] = useState(0)

  const memoizedData = useMemo(() => data, [data])

  const useMobile = useMobileDetect()
  const isMobile = useMobile.isMobile()

  // sorting
  useEffect(() => {
    const filterFunc = currentRow => {
      let show = true

      if (filterBy.length) {
        for (let i = 0; i < filterBy.length; i++) {
          const [[key, filterFn]] = Object.entries(filterBy[i])

          if (typeof filterFn === 'function') show = filterFn(currentRow[key])
          else show = filterFn === currentRow[key]

          if (!show) break
        }
      }

      return show
    }

    let sortedData = memoizedData.filter(filterFunc)

    const [row] = sortedData

    if (row && sortOrder) {
      const cell = row[sortColumn]
      const rowConfig = conf.tableConfig[sortColumn] || {}

      const order = sortOrder === 'asc' ? 1 : -1

      const sortFunc = Number.isInteger(+cell)
        ? numberSort(sortColumn, order)
        : !Number.isNaN(Date.parse(cell))
          ? dateSort(sortColumn, order)
          : stringSort(sortColumn, order)

      if (rowConfig.sortBy) sortedData = sortedData.sort((a, b) => rowConfig.sortBy(a, b, sortOrder))
      else sortedData = sortedData.sort(sortFunc)
    }

    // Slicing
    const position = (isMobile ? MOBILE_ROWS_CONST : rowsNumber) * page

    const pages = Math.ceil(sortedData.length / (isMobile ? MOBILE_ROWS_CONST : rowsNumber)) || 0

    // If no sortOrder is passed, it means to sort it out to its initial (unordered) order
    setRows(sortedData.slice(position, position + (isMobile ? MOBILE_ROWS_CONST : rowsNumber)))
    setTotalPages(pages)
    setTotalItems(sortedData.length)
  }, [sortColumn, sortOrder, rowsNumber, page, filterBy, memoizedData])

  const onNext = () =>
    setPage(pageNum =>
      Math.min(
        Math.ceil(memoizedData.length / (isMobile ? MOBILE_ROWS_CONST : rowsNumber)),
        pageNum + 1
      ))
  const onPrev = () => setPage(pageNum => Math.max(0, pageNum - 1))
  const onSort = column => {
    const differentColumn = sortColumn !== column

    setSortColumn(column)
    // Resets after third time
    setSortOrder(order =>
      (differentColumn ? 'asc' : order === 'asc' ? 'desc' : order === 'desc' ? null : 'asc'))
    setPage(0)
  }
  const onSetRowsNumber = value => {
    const currentPosition = (isMobile ? MOBILE_ROWS_CONST : rowsNumber) * page

    setPage(Math.floor(currentPosition / value))
    setRowsNumber(value)
  }

  const filters = {
    by(filter) {
      setFilterBy(prevFilters => [...prevFilters, filter])
      setPage(0)
    },
    clear() {
      setFilterBy([])
      setPage(0)
    },
  }

  const actions = {
    onSort,
    onNext,
    onPrev,
    onSetRowsNumber,
    filter: filters,
  }

  return [
    {
      data: rows,
      rows: isMobile ? MOBILE_ROWS_CONST : rowsNumber,
      order: sortOrder,
      sortedColumn: sortColumn,
      totalItems,
      pages: totalPages,
      page,
    },
    actions,
  ]
}

export default useTable
