import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import EmailCollector from 'login/visual-components/util/email-collector'

import { H1, P } from 'visual-components/util/texts';

import footerTheme from 'css/themes/mobile/footer.css';

class MobileGrowSection extends React.Component {
  render() {
    return (
      <div className={ footerTheme.mobileGrowSection }>
        <Grid>
          <Row center='xs'>
            <Col xs>
              <H1 white>
                Ready to Grow?
              </H1>
            </Col>
          </Row>
          <Row center='xs' style={{
            marginTop: '16px'
          }}>
            <Col xs>
              <P white>
                Try it free for 30 days, no credit card required.
              </P>
            </Col>
          </Row>
          <Row center='xs'>
            <Col xs>
              <EmailCollector isMobile buttonText="Start Free Trial" />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default MobileGrowSection
