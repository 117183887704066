import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InputContainer from 'visual-components/util/InputContainer'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import Dialog from 'react-toolbox/lib/dialog'
import Dropdown from 'react-toolbox/lib/dropdown'

import DropdownTheme from 'css/themes/Dropdown.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const stateToProps = ({ brand, brandCampaigns }) => ({
  brand,
  campaignsApplied: brandCampaigns.invites
    ? brandCampaigns.invites.map(({ id, status }) => (['accepted', 'pending'].includes(status) ? id : null)).filter(Boolean)
    : [],
})

class ApplyToCampaignModal extends PureComponent {
  state = {
    value: null,
  }

  onChange = value => this.setState({ value })

  template = item => <p style={{ color: item.disabled ? '#a9b6c3' : 'auto' }}>{item.label}</p>

  signup = () => { window.location.href = '/app/register' }

  onApply = () => {
    const { history } = this.props
    const { value } = this.state

    return history.push(`/explore/campaigns/${value}/details?apply=true`)
  }

  onClose = () => {
    const { onClose } = this.props

    this.setState({ value: null }, onClose)
  }

  render() {
    const { brand, active, onClose, campaignsApplied } = this.props

    const { value } = this.state

    const campaigns = brand.availableCampaigns.map(({ id, name }) => {
      if (campaignsApplied.includes(id)) return null

      return { value: id, label: name }
    }).filter(Boolean)

    return (
      <Dialog
        theme={DialogTheme}
        active={active}
        onEscKeyDown={onClose}
        onOverlayClick={onClose}
        title={`Partner With ${brand.accountname}`}
      >
        <img
          onClick={onClose}
          className={DialogTheme.closeImage}
          src="/images/icons/close.svg"
          style={{ cursor: 'pointer' }}
        />
        <p
          style={{
            fontFamily: 'Larsseit',
            fontSize: '16px',
            color: '#2c3540',
            marginBottom: '10px',
          }}
        >
          Select the campaign you’d like to apply to:
        </p>
        <InputContainer label="Upcoming Sweepstakes">
          <Dropdown
            theme={DropdownTheme}
            auto
            template={this.template}
            className={DropdownTheme.partner}
            onChange={this.onChange}
            source={campaigns}
            value={value}
          />
        </InputContainer>
        <div style={{ paddingTop: '24px' }}>
          <ModalButtonGroup
            canSave={value}
            cancel={onClose}
            confirm={this.onApply}
            confirmText="Apply"
          />
        </div>
      </Dialog>
    )
  }
}

ApplyToCampaignModal.propTypes = {
  brand: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  campaignsApplied: PropTypes.object.isRequired,
}

ApplyToCampaignModal.defaultProps = {
  active: false,
}

export default withRouter(connect(stateToProps)(ApplyToCampaignModal))
