export const load = requestId => ({
  http: {
    url: `/deals/request/${requestId}`,
  },
  types: [
    'DEAL_REQUEST_LOAD',
    'DEAL_REQUEST_LOAD_SUCCESS',
    'DEAL_REQUEST_LOAD_FAILURE',
  ],
})

export const loadActiveByBrandId = brandId => ({
  http: {
    url: `/deals/request-from-brand/${brandId}`,
  },
  types: [
    'DEAL_REQUEST_LOAD',
    'DEAL_REQUEST_LOAD_SUCCESS',
    'DEAL_REQUEST_SET_LOADING_FALSE',
  ],
})

export const save = data => ({
  http: {
    url: `/deals/request/${data.id || ''}`,
    data,
    method: data.id ? 'PUT' : 'POST',
  },
  data,
  types: [
    'DEAL_REQUEST_SUBMIT',
    'DEAL_REQUEST_SUBMIT_SUCCESS',
    'DEAL_REQUEST_SUBMIT_FAILURE',
  ],
})

export const clear = () => ({
  type: 'DEAL_REQUEST_CLEAR',
})
