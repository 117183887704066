import React, { PureComponent } from 'react';
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PartnerWithBrand from 'visual-components/brandProfile/PartnerWithBrand'
import * as CampaignPartnerActions from 'actions/campaign-partner-actions'

const stateToProps = function (state) {
  return {
    brand: state.brand,
    currentBrand: state.currentBrand,
    upcomingCampaigns: state.upcomingCampaigns,
    currentCampaigns: state.currentCampaigns,
    profile: state.profile,
  }
}

const dispatchToProps = dispatch => ({
  campaignPartnerActions: bindActionCreators(CampaignPartnerActions, dispatch),
})

class PartnerWithBrandContainer extends PureComponent {
  invite = campaignId => {
    const {
      campaignPartnerActions,
      currentBrand,
      brand,
      profile,
      upcomingCampaigns
    } = this.props

    const invite = _.find(brand.partnerships, { campaign_id: campaignId })
    const campaign = _.find(upcomingCampaigns.campaigns, { id: campaignId })

    if (invite) {
      return campaignPartnerActions.openReinvitationMessenger({
        campaign,
        currentBrand,
        inviteeBrand: brand,
        profile,
        reinviteType: 'partnerWithBrand',
      })
    }

    return store.dispatch({
      model: 'conversations',
      type: 'OPEN_INVITE_MODAL',
      data: { campaign, currentBrand },
    })
  }

  render() {
    const {
      brand,
      currentBrand,
      upcomingCampaigns,
      active,
      onClose,
    } = this.props

    let potentialCampaigns = _.map(upcomingCampaigns.campaigns, campaign => {
      if (new Date(campaign.startDate) < new Date()) return null

      const isHost = campaign.hostBrandId === currentBrand.id

      if (!isHost) return null

      let label = campaign.name
      let disabled = false

      const invite = _.find(brand.partnerships, { campaign_id: campaign.id })

      const acceptedPartners = campaign.allPartners.filter(partner => partner.status === 'accepted')

      if (invite && invite.status !== 'rejected') {
        if (invite.status === 'pending') {
          if (invite.application) {
            label = `${label} - Accept Their Application`
          } else {
            label = `${label} (invite pending)`
            disabled = true
          }
        } else {
          disabled = true

          label = `${label} (already a partner)`
        }
      } else if (!campaign.noPartnerLimit && acceptedPartners.length >= 8) {
        label = `${label} (Campaign is full)`
        disabled = true
      }

      const data = {
        label,
        disabled,
        value: campaign.id,
      }

      return data
    })

    potentialCampaigns = _.compact(potentialCampaigns)

    return (
      <PartnerWithBrand
        active={active}
        onClose={onClose}
        brand={brand}
        invite={this.invite}
        currentBrand={currentBrand}
        potentialCampaigns={potentialCampaigns}
      />
    )
  }
}

export default connect(stateToProps, dispatchToProps)(PartnerWithBrandContainer)
