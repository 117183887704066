import React, { Component } from 'react'
import { func, object } from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H4 } from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'

import { SimpleForm, Input, Validations } from 'visual-components/util/form'

import { CLOSED, BRAND_ACCOUNT_SETTINGS } from './steps'

const { isRoutingNumber, isBankAccount } = Validations

export default class BankAccountSettingsForm extends Component {
  static propTypes = {
    toggleModal: func.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
  }

  constructor(props) {
    super(props)
    const {
      brandAccount: {
        external_account: { routing_number, account_number } = {},
      },
    } = this.props
    this.state = { routing_number, account_number, old_account_number: account_number }
  }

  componentDidUpdate(prevProps) {
    const {
      brandAccount: { external_account },
    } = this.props
    if (prevProps.brandAccount.external_account !== external_account) this.close()
  }

  toggleModal = STEP => {
    const { toggleModal } = this.props
    toggleModal(STEP)
  }

  back = () => this.toggleModal(BRAND_ACCOUNT_SETTINGS)

  close = () => this.toggleModal(CLOSED)

  update = data => this.setState(prevState => ({ ...prevState, ...data }))

  handleSubmit = data => {
    // Ignore if previously added
    if (data.account_number.startsWith('****')) return this.close()

    return this.setState({ error: null, loading: true }, () => this.getToken(data))
  }

  getToken = async ({ routing_number, account_number }) => {
    const { brandAccount: { id: stripeAccount } } = this.props
    const stripeConnect = window.StripeFactory(stripeAccount)
    const response = await stripeConnect.createToken('bank_account', {
      country: 'us',
      currency: 'usd',
      routing_number,
      account_number,
    })
    if (response.error) return this.setState({ error: response.error.message, loading: false })

    return this.setState({ loading: false }, () => this.sendToServer(response.token.id))
  }

  sendToServer = id => {
    const {
      brandAccountActions: { addBankAccount },
    } = this.props
    addBankAccount({ id })
  }

  render() {
    const {
      brandAccount: { loading, error },
    } = this.props
    const { error: internalError, loading: internalLoading } = this.state

    return (
      <SimpleForm model={this.state} updateModel={this.update} handleSubmit={this.handleSubmit}>
        {(internalLoading || loading) && <Spinner />}
        <Grid>
          <Row style={{ marginBottom: '16px' }}>
            <H4 multiline>
              We&apos;ll make two small deposits to your account in the next four business days.
              Return to Payments to verify the deposit amounts and connect your account.
              {' '}
              <a href="https://help.dojomojo.com/hc/en-us/articles/360020001391-Setting-Up-Payouts-for-MediaMarket-Sellers" target="_blank" rel="noopener noreferrer">Learn More</a>
              {(internalError || error) && (
                <strong style={{ color: 'red' }}>
                  <br />
                  {`Error: ${internalError || error}`}
                </strong>
              )}
            </H4>
          </Row>
          <Row>
            <Col style={{ width: '300px', marginRight: '8px' }}>
              <Input
                required
                name="routing_number"
                label="Routing Number"
                placeholder="110000000"
                validations={[isRoutingNumber]}
              />
            </Col>
            <Col style={{ width: '300px' }}>
              <Input
                required
                name="account_number"
                label="Account Number"
                placeholder="12345678"
                validations={[isBankAccount]}
              />
            </Col>
          </Row>
        </Grid>
        <ModalButtonGroupWithContext confirmText="Done" cancelText="Back" cancel={this.back} />
      </SimpleForm>
    )
  }
}
