import {
  defaultQuery,
  applyRange,
  applyFacet,
} from './search-config/defaults/helpers'

const searchActionsFactory = ({
  model,
  endpoint,
  sources,
  inputName = ['searchValue'],
  aggs = {},
  rangeFilters = [],
  facets = [],
  limitedFacets = [],
  nestedFilter = {},
  extendQuery = ({ body }) => body,
  extendEndpoint = '',
}) => {
  inputName = Array.isArray(inputName) ? inputName : [inputName]

  const searchBody = (getState, page, modelOverride) => {
    const state = getState()[modelOverride || model]
    // Pass nestedFilter getState if needed
    nestedFilter =
      typeof nestedFilter === 'function' ? nestedFilter(getState) : nestedFilter
    // Prepare query
    let body = defaultQuery({
      page,
      nestedFilter,
      sources,
      inputName,
      aggs,
      state,
      getState,
    })
    // Custom query extension
    body = extendQuery({ body, state, getState })
    // Apply range filters
    rangeFilters.forEach(filterName =>
      applyRange({ filterName, nestedFilter, state, body })
    )
    // Apply facets
    facets.forEach(facetName => applyFacet({ facetName, state, body, limitedFacets }))

    return body
  }

  const clear = () => ({ model, type: 'CLEAR' })

  const updateModel = payload => ({ model, type: 'UPDATE_ATTR', payload })

  const applySearch = payload => (dispatch, getState) => {
    const data = typeof payload === 'function'
      ? payload(getState)
      : searchBody(getState, payload)

    return dispatch({
      http: {
        url: `${endpoint}${extendEndpoint}`,
        method: 'POST',
        data,
      },
      model,
      types: ['LOADING', 'LOAD_SUCCESS', 'LOAD_FAILURE'],
    })
  }

  return { clear, updateModel, applySearch }
}

export default searchActionsFactory
