import React, { PureComponent as Component } from 'react'
import { H3 } from 'visual-components/util/texts'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'
import PropTypes from 'prop-types';

class AnalyticsHeader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
  }

  static defaultProps = {
    tooltipText: null,
  }

  render() {
    const {
      title,
      tooltipText,
    } = this.props

    return (
      <div style={{ marginBottom: '10px' }}>
        <H3 coral>
          <small>
            {title}
            {
              tooltipText
                ? (
                  <div
                    style={{
                      borderRadius: '100%',
                      width: '20px',
                      height: '20px',
                      textAlign: 'center',
                      display: 'inline-block',
                      marginLeft: '8px',
                    }}
                  >
                    <BlueInfoTooltip text={tooltipText} />
                  </div>
                )
                : null
            }
          </small>
        </H3>
      </div>
    )
  }
}

export default AnalyticsHeader
