import React, { Component } from 'react'
import { object, array, bool } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Checkbox } from 'react-toolbox'
import d3 from 'd3'
import _ from 'lodash'

import Spinner from 'util/components/spinner'

import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import Theme from 'css/themes/payments/upgrade-plan-full-modal.css'
import CheckboxTheme from 'css/themes/Checkbox.css'

import BrowserHistory from 'dispatchers/browser-history-dispatcher'
import AppDispatcher from 'dispatchers/app-dispatcher'
import CardDispatcher from 'dispatchers/card-dispatcher'

import { SimpleForm, Select } from 'visual-components/util/form'
import FullscreenModal from 'visual-components/util/modals/FullscreenModal'
import ChooseCard from 'visual-components/util/choose-card'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import { H3, P } from 'visual-components/util/texts'
import { isTrialingAndNotUpgraded } from 'util/brand-stripe-belt-helper'

import * as smsActions from 'actions/sms-actions'

import OrderSummaryDisplay from '../../payments/order-summary-display'
import SmsMarketingBreakdownItems from '../../payments/sms-marketing-breakdown-items'

const mapStateToProps = ({
  currentBrand, customer, card, sms,
}) => ({
  currentBrand,
  customer,
  card,
  sms,
  pendingInvoiceItems: sms.pendingInvoiceItems,
  actionLoading: sms.loading,
  purchaseSuccess: sms.purchaseSuccess,
  purchaseFailure: sms.purchaseFailure,
  error: sms.error,
})

const mapDispatchToProps = dispatch => ({
  smsActions: bindActionCreators(smsActions, dispatch),
})

class SMSCheckoutModal extends Component {
  static propTypes = {
    currentBrand: object.isRequired,
    customer: object.isRequired,
    card: object.isRequired,
    sms: object.isRequired,
    smsActions: object.isRequired,
    pendingInvoiceItems: array,
    actionLoading: bool,
    purchaseSuccess: bool.isRequired,
    purchaseFailure: bool.isRequired,
  }

  static defaultProps = {
    actionLoading: false,
    pendingInvoiceItems: [],
  }

  state = {
    selectedPlan: null,
    agreementChecked: false,
  }

  componentDidMount = async () => {
    const { currentBrand, smsActions, customer } = this.props

    await smsActions.fetchPendingInvoiceItems()
    await smsActions.fetchBrandBank(currentBrand.id)

    await AppDispatcher.loadOnce({
      model: 'customer',
      endpoint: '/customer',
      data: customer,
    })
  }

  formatCredits = int => d3.format(',')(int)

  back = () => BrowserHistory.goBack()

  toggleAgreement = () => {
    this.setState(prevState => ({ agreementChecked: !prevState.agreementChecked }))
  }

  onUpdate = ({ smsPlan: selectedPlan }) => {
    if (selectedPlan === undefined) return

    this.setState({ selectedPlan })
  }

  onSubmit = async orderSummary => {
    const {
      smsActions: { purchaseSms },
      currentBrand,
      card,
      customer,
    } = this.props
    const { credits, price } = orderSummary
    let { selectedSource } = customer

    const selectedCardDetails = customer.creditCards.find(c => c.id === selectedSource) || {
      isNY: false,
      taxRate: 0,
    }

    if (card.adding) selectedSource = await CardDispatcher.updateBilling()

    const taxApplicable = card.adding ? currentBrand.isNY : selectedCardDetails.isNY
    const taxRate = card.adding ? currentBrand.taxRate : selectedCardDetails.taxRate

    const orderData = {
      quantity: credits,
      plan: credits,
      autoRenew: true,
      price,
      taxRate,
      selectedSource,
      belt: currentBrand.belt,
      brandId: currentBrand.id,
      isNY: taxApplicable,
      isUserPurchase: true,
    }
    purchaseSms(orderData)
  }

  render() {
    const {
      card,
      actionLoading,
      purchaseSuccess,
      purchaseFailure,
      pendingInvoiceItems,
      currentBrand,
      sms,
      customer: { loading: customerLoading, selectedSource, creditCards = [] },
    } = this.props

    // Success redirect
    if (!sms.loading && purchaseSuccess) BrowserHistory.replace('/sms-marketing-purchase-success')

    const isTrial = isTrialingAndNotUpgraded(currentBrand)

    const { brandSmsBank } = sms

    // Other validations / redirects
    const smsLoading = sms.loading || !brandSmsBank
    if ((customerLoading || smsLoading) && !purchaseFailure) return <Spinner needsBackground />
    if (!currentBrand.id || currentBrand.belt === 'white' || isTrial) return <Redirect to="/profile/brand/billing" />
    if (currentBrand.features.block_sms) return <Redirect to="/profile/brand/billing" />

    const { selectedPlan, agreementChecked } = this.state

    const { plansInfo, credits, currentPlan } = brandSmsBank
    const { info: { plans, pricing, cost } } = credits

    // Old plan information, if one exists
    let showFreeCredits = true
    let oldAutoPlan = false
    let oldPlanCredits = null

    if (credits) {
      showFreeCredits = !credits.autorefilledAt
      oldAutoPlan = !!credits.autorefilled
      oldPlanCredits = currentPlan ? currentPlan.credits : 0
    }

    // Default/initial modal content set to Brands without any existing plan
    let modalContent = {
      title: 'Enable SMS Marketing',
      subtitle: 'Plan Selection',
      header: null,
      confirmText: 'Purchase',
      cancelText: 'Back',
    }
    const existingAutoRenew = {
      title: 'Manage Plan',
      subtitle: 'Auto-Refill Plan',
      header: `You are currently enrolled in the Auto-Refill plan for ${this.formatCredits(
        oldPlanCredits
      )} Credits.`,
      confirmText: 'Update Plan',
      cancelText: 'Cancel',
    }
    if (oldAutoPlan) modalContent = existingAutoRenew

    const planOptions = plansInfo ? plansInfo.plans : plans
    const currentPlanIdx = currentPlan
      ? _.findIndex(planOptions, opt => opt.credits === currentPlan.credits)
      : 0 // TODO: Determine which one is the default plan

    // If no selectedPlan, orderSummary set to default plan
    const orderSummary = planOptions[selectedPlan !== null ? selectedPlan : currentPlanIdx]

    // Determine if user is adding new card or using saved card,
    // then lookup whether it has NY tax info
    const selectedCardDetails = creditCards.find(c => c.id === selectedSource) || {}
    const lookupLocation = card.adding ? currentBrand : selectedCardDetails

    // Calculations to be shown in order summary table
    const subtotal = orderSummary.cost
    const tax = lookupLocation.isNY ? subtotal * Number(lookupLocation.taxRate) : 0
    const total = subtotal + tax

    // Applies any existing account credits (e.g. referrals) to purchase
    const pendingCredit = pendingInvoiceItems
      ? pendingInvoiceItems.reduce((sum, item) => {
        sum += item.amount / 100
        return sum
      }, 0)
      : 0
    const usedCredit = Math.max(pendingCredit, -1 * total)

    const lineItemLabel = `${this.formatCredits(orderSummary.credits)} Credits x $${
      pricing || plansInfo.pricing
    }`
    orderSummary.label = lineItemLabel
    orderSummary.subAmount = subtotal
    orderSummary.totalAmount = total + usedCredit
    orderSummary.invoiceCredit = usedCredit
    orderSummary.credits = orderSummary.credits
    orderSummary.price = pricing || plansInfo.pricing

    if (!brandSmsBank.hasBoostPack && currentBrand.stripe_belt.includes('12month')) {
      [orderSummary.belt] = currentBrand.stripe_belt.split('-')
    }

    // Validations to enable submit button

    const canSave = !!agreementChecked && !!selectedSource && !!orderSummary

    // Reformats plan options for dropdown component
    const formattedOptions = planOptions.map((option, i) => {
      const { credits } = option
      const formatedCredits = this.formatCredits(credits)

      const selected = currentPlan && i === currentPlanIdx

      return {
        ...option,
        value: i,
        label: `${formatedCredits} Credits (${d3.format('$.2f')(credits * pricing)})${selected ? ' - Current' : ''}`,
      }
    })

    const defaultSelected = formattedOptions[
      (selectedPlan === null ? currentPlanIdx : selectedPlan) || 0
    ].value

    return (
      <FullscreenModal title={modalContent.title} close={this.back}>
        {(card.loading || actionLoading) && !purchaseFailure && <Spinner needsBackground />}
        <SimpleForm
          model={orderSummary}
          updateModel={this.onUpdate}
          handleSubmit={this.onSubmit}
          className={Theme.form}
        >
          <div className={Theme.smsContainer}>
            <div className={Theme.reviewItems}>
              <div style={{ marginTop: 24 }} />
              <SmsMarketingBreakdownItems plan={currentBrand.belt} title />
              <div className={Theme.messageCountSelection} style={{ marginTop: 32 }}>
                <Select
                  name="smsPlan"
                  label="Plan Selection"
                  sublabel={`Approx. ${cost.sms} credit per SMS / ${cost.mms} credits per MMS`}
                  defaultSelected={defaultSelected}
                  options={formattedOptions}
                />
              </div>
              <div>
                <H3 coral>
                  <small>Payment Information</small>
                </H3>
                <div className={Theme.choosePaymentMethod}>
                  <ChooseCard />
                </div>
                <div className={Theme.agreement__checkbox} onClick={this.toggleAgreement}>
                  <Checkbox
                    onChange={this.toggleAgreement}
                    checked={agreementChecked}
                    theme={CheckboxTheme}
                  />
                  <div className={Theme.agreement__terms}>
                    <P multiline>
                      <em>
                        You will be immediately charged and your credits will automatically refill
                        when your balance reaches 100 credits. Note that customers based in New York
                        will be charged 8.875% sales tax. You can cancel at anytime.
                      </em>
                    </P>
                  </div>
                </div>
              </div>
            </div>
            <OrderSummaryDisplay
              type="sms"
              purchaseDealSummary={orderSummary}
              chargeTax={tax}
              existingPlan={!showFreeCredits}
            />
          </div>
          <div className={FullscreenModalTheme.buttonGroupFixedBottom}>
            <ModalButtonGroupWithContext
              cancel={this.back}
              cancelText={modalContent.cancelText}
              confirmText={modalContent.confirmText}
              canSave={canSave}
              hideLine
            />
          </div>
        </SimpleForm>
      </FullscreenModal>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SMSCheckoutModal)
