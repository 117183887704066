import React, { Component } from 'react'
import DefaultCard from 'visual-components/util/cards/default-card'
import GetStartedStep from './get-started-step'
import theme from 'css/themes/card.css'

class GetStarted extends Component {
  render() {
    return (
      <DefaultCard title="Get Started">
        <div className={theme.getStartedCardInner}>
          <GetStartedStep
            img="/images/referrals/get-started/paper-airplane.svg"
            text="Spread the word by link or email"
          />
          <GetStartedStep
            img="/images/referrals/get-started/man-and-woman.svg"
            text="See your referrals join DojoMojo"
          />
          <GetStartedStep
            img="/images/referrals/get-started/game-star.svg"
            text="Get rewards when they sign up"
          />
        </div>
      </DefaultCard>
    )
  }
}

export default GetStarted
