import { merge } from 'lodash'
import { v1 as uuidv1 } from 'uuid'

const initialState = {
  rid: 'deals',
  loading: false,
  deal: {},
  deals: [],
  error: '',
  tempProposal: {},
  dealResults: {},
  dealPixelAnalytics: {},
  dealESPAnalytics: {},
  pixelSetupActive: false,
  pixelConfirmationActive: '',
  totalUnreadCount: 0,

  showDeclineReasonModal: false,
  showChangeDatesModal: false,

  initial_end_date: null,
  initial_start_date: null,
  can_change_dates: true,
}

// Calculates a global unread count
const calculateTotalUnreadCount = deals =>
  deals.reduce((sum, deal) => {
    const { unread_count } = deal
    if (!unread_count || !unread_count.all) return sum
    return sum + unread_count.all
  }, 0)

// Adjusts deal's unread counts only
const markDealRead = (dealId, type, {
  dealAttachmentId,
  dealAttachmentUnreadCount,
} = {}) => deal => {
  if (deal.id !== Number(dealId)) return deal

  const { unread_count } = deal
  if (!unread_count) return deal

  const itemCount = unread_count[type]
  let all
  let newUnreadCount

  // Deal attachments sub object
  if (typeof itemCount === 'object') {
    const subItemCount = itemCount[dealAttachmentId]

    // If count is already 0, ignore
    if (!subItemCount || subItemCount === 0) return deal

    // Recalculate deal total
    all = Math.max(0, unread_count.all - subItemCount)

    // Adjust counts object
    const newAttachmentUnreadCount = { ...itemCount, [dealAttachmentId]: 0 }
    newUnreadCount = { ...unread_count, [type]: newAttachmentUnreadCount, all }
  } else {
    // all other types

    // If count is already 0, ignore
    if (!itemCount || itemCount === 0) return deal

    // Recalculate deal total
    all = Math.max(0, unread_count.all - (dealAttachmentUnreadCount || itemCount))

    // attachmentComments are merged at high level so must subtract individually
    // rather than setting entire type to 0
    const newTypeCount = dealAttachmentUnreadCount
      ? unread_count[type] - dealAttachmentUnreadCount
      : 0

    // Adjust counts object
    newUnreadCount = { ...unread_count, [type]: newTypeCount, all }
  }

  // Account for overall deal unread (when status change)
  if (newUnreadCount.deal_unread) {
    newUnreadCount.deal_unread = 0
    newUnreadCount.all--
  }

  return { ...deal, unread_count: newUnreadCount }
}

// Adjusts deal's unread counts only
const markDealUnread = (dealId, type, action = { payload: {} }) => deal => {
  if (deal.id !== Number(dealId)) return deal

  // Only update non-pending deals if dealing with tracking links
  if (type === 'targets' && deal.status === 'pending') return deal

  const { unread_count: oldUnreadCount = {} } = deal

  const itemCount = (oldUnreadCount[type] || 0) + 1
  const all = (oldUnreadCount.all || 0) + 1

  // Adjust counts object
  const unread_count = { ...oldUnreadCount, [type]: itemCount, all }

  // Update status + updated_at if changed
  const dealUpdates = type === 'deal_unread'
    ? { status: action.payload.status, updated_at: action.payload.updated_at }
    : {}

  // If new proposal, update details
  if (type === 'proposals') {
    const {
      id, updated_at, created_at, ...relevantFields
    } = action.payload
    return {
      ...deal, unread_count, ...dealUpdates, ...relevantFields,
    }
  }

  return { ...deal, unread_count, ...dealUpdates }
}

// Adjust deal's attachent's unread counts only
const markDealAttachmentUnread = (dealId, dealAttachmentId) => deal => {
  if (deal.id !== Number(dealId)) return deal

  const { unread_count } = deal
  if (!unread_count) {
    return { ...deal, unread_count: { attachmentComments: { [dealAttachmentId]: 1 }, all: 1 } }
  }

  const attachmentComments = unread_count.attachmentComments || {}
  const itemCount = (attachmentComments[dealAttachmentId] || 0) + 1
  const all = (unread_count.all || 0) + 1

  const newUnreadCount = {
    ...unread_count,
    attachmentComments: { ...attachmentComments, [dealAttachmentId]: itemCount },
    all,
  }

  return { ...deal, unread_count: newUnreadCount }
}


// Mark each deal's individual item as read
/* TODO maybe don't need?
const markDealItemsRead = (dealId, type) => deal => {
  if (deal.id !== Number(dealId)) return deal

  const items = deal[type]

  // If none of type is attached, ignore
  if (!items || items.length === 0) return deal

  // Mark each unread item as read
  const newItems = items.map(item =>
    (item.is_unread ? ({ ...item, is_unread: false }) : item))

  return { ...deal, [type]: newItems }
}
*/

// Adds or edits new tracking link
const addOrEditTrackingLink = (deal, action) => {
  let isNew = true
  deal.targets = deal.targets.map(target => {
    if (target.id !== action.payload.id) return target
    isNew = false
    return action.payload
  })
  if (isNew) deal.targets.push(action.payload)
}

// Updates a deal's current proposal
const addProposal = (deal, action) => {
  const { currentProposal } = deal
  // Move (current) currentProposal to previousProposals and mark not current
  deal.previousProposals = [...deal.previousProposals, { ...currentProposal, current: false }]
  deal.currentProposal = { ...action.payload, uid: uuidv1() }
}

const getDojoClicks = (state, action) => {
  const { cumulativeByDay } = action.payload
  const dealEmailDojoClicks = cumulativeByDay[cumulativeByDay.length - 1].y || 0
  return merge({}, state, { dealResults: { [action.id]: { dealEmailDojoClicks } } }, { loading: false })
}

const extendDealForMessageWindow = deal => {
  deal.currentProposal.uid = uuidv1()
  deal.messageType = 'proposals'
  if (deal.previousProposals) {
    deal.previousProposals = deal.previousProposals.map(dp => ({ ...dp, uid: uuidv1() }))
  }
  return deal
}

export default function dealReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DEAL_ATTR':
      return merge({}, state, action.data)

    case 'UPDATE_DEAL_LINK_ATTR':
      return merge({}, state, {
        editTargetLinkActive: { ...state.editTargetLinkActive, ...action.data },
      })

    case 'DEALS_RESET_ERROR':
      return { ...state, error: '' }

    case 'LOADING_DEALS':
    case 'TESTING_DEAL_PIXEL':
      return { ...state, loading: true, error: '' }

    case 'LOAD_DEALS_FINISH':
      return { ...state, loading: false, error: '' }

    case 'LOAD_DEALS_SUCCESS': {
      const deals = action.payload.map(extendDealForMessageWindow)
      const totalUnreadCount = calculateTotalUnreadCount(deals)
      return {
        ...state, loading: false, deals, totalUnreadCount,
      }
    }

    case 'LOAD_DEAL_SUCCESS':
      return { ...state, loading: false, deal: extendDealForMessageWindow(action.payload) }

    case 'CLEAR_DEAL':
      return { ...state, deal: {} }

    case 'CLEAR_TEMP_PROPOSAL':
      return { ...state, tempProposal: {} }

    case 'CREATE_DEAL_SUCCESS': {
      const deals = [action.payload, ...state.deals]
      return {
        ...state,
        tempProposal: {},
        successfulAction: {
          id: action.payload.id,
          type: 'create',
        },
        loading: false,
        deals,
      }
    }

    case 'COUNTER_DEAL_SUCCESS': {
      const deals = [action.payload, ...state.deals]
      return {
        ...state,
        tempProposal: {},
        editProposalActive: false,
        successfulAction: {
          id: action.payload.id,
          type: action.status,
        },
        loading: false,
        deals,
      }
    }

    case 'SHOW_DECLINE_REASON_MODAL':
      return { ...state, showDeclineReasonModal: action.data }

    case 'CONFIRM_DEAL_SUCCESS': {
      const dealUnmarker = markDealUnread(state.deal.id, 'targets')

      const deal = dealUnmarker({ ...state.deal, status: 'accepted', updated_at: new Date() })

      const deals = state.deals.map(d => {
        if (d.id !== action.dealId) return d
        return dealUnmarker({ ...d, status: 'accepted' })
      })

      const totalUnreadCount = calculateTotalUnreadCount(deals)

      return {
        ...state,
        tempProposal: {},
        successfulAction: {
          id: action.dealId,
          type: 'accept',
        },
        loading: false,
        deals,
        deal,
        totalUnreadCount,
      }
    }

    case 'DECLINE_DEAL_SUCCESS': {
      const deal = { ...state.deal, status: 'declined', updated_at: new Date() }

      const deals = state.deals.map(d => {
        if (d.id !== action.dealId) return d
        return { ...d, status: 'declined' }
      })

      return {
        ...state,
        loading: false,
        deals,
        deal,
      }
    }

    case 'CLEAR_DEAL_SUCCESSES':
      return { ...state, successfulAction: {} }

    case 'CREATE_DEAL_LINK_SUCCESS': {
      const targets = [...state.deal.targets, action.payload]

      return {
        ...state,
        loading: false,
        editTargetLinkActive: false,
        deal: { ...state.deal, targets },
        successfulAction: {
          id: action.payload.id,
          type: 'link-create',
        },
      }
    }

    case 'UPDATE_DEAL_LINK_SUCCESS': {
      const targets = state.deal.targets.map(t => {
        if (t.id !== action.payload.id) return t
        return action.payload
      })

      return {
        ...state,
        loading: false,
        editTargetLinkActive: false,
        deal: { ...state.deal, targets },
        successfulAction: {
          id: action.payload.id,
          type: 'link-edit',
        },
      }
    }

    case 'FETCH_DEAL_DATES_SUCCESS': {
      return { ...state, ...action.payload }
    }

    case 'CREATE_DEAL_FAILURE':
    case 'CONFIRM_DEAL_FAILURE':
    case 'COUNTER_DEAL_FAILURE':
    case 'CREATE_DEAL_LINK_FAILURE':
    case 'UPDATE_DEAL_LINK_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error',
      }

    case 'TESTING_DEAL_PIXEL_SUCCESS':
      return {
        ...state,
        loading: false,
        pixelConfirmationActive: action.payload.hasPixel ? 'success' : 'failed',
      }

    case 'CLEAR_DEAL_PIXEL':
      return { ...state, pixelSetupActive: !!action.data.isFailedConfirm, pixelConfirmationActive: '' }

    case 'DELETE_DEAL_ASSET_COMMENT_SUCCESS': {
      const {
        deal_id: dealId, item_type: type, deal_attachment_id: dealAttachmentId,
      } = action.payload
      action.data = {
        dealId, type, dealAttachmentId, dealAttachmentUnreadCount: 1,
      }
    }
    // fall through

    case 'MARK_DEAL_ITEMS_READ': {
      const {
        dealId, type, dealAttachmentId, dealAttachmentUnreadCount,
      } = action.data

      // Bind deal ID and item type to callbacks
      const dealMarker = markDealRead(dealId, type, {
        dealAttachmentId,
        dealAttachmentUnreadCount,
      })
      // const dealItemMarker = markDealItemsRead(dealId, type)

      // Adjust deal data in global deals object + currently loaded deal
      const deals = state.deals.map(dealMarker)
      const deal = dealMarker(state.deal)
      // const deal = dealMarker(dealItemMarker(state.deal))

      // Recalculate grand total
      const totalUnreadCount = calculateTotalUnreadCount(deals)

      return {
        ...state, deals, deal, totalUnreadCount,
      }
    }

    case 'LOAD_SINGLE_DEAL_COLLABORATION_ITEM': {
      const { deal_id, item_type, deal_attachment_id } = action.payload
      const dealUnmarker = markDealUnread(deal_id, item_type, action)
      const dealAttachmentUnmarker = markDealAttachmentUnread(deal_id, deal_attachment_id)

      const deals = state.deals.map(dealUnmarker)
      const deal = item_type === 'attachmentComments'
        ? dealAttachmentUnmarker(state.deal)
        : dealUnmarker(state.deal)

      const totalUnreadCount = calculateTotalUnreadCount(deals)

      if (deal.id === deal_id) {
        // New/Edited tracking link
        if (item_type === 'targets') addOrEditTrackingLink(deal, action)
        // New proposal
        if (item_type === 'proposals') addProposal(deal, action)
      }

      return {
        ...state, deals, deal, totalUnreadCount,
      }
    }

    case 'UPDATE_DEAL_DATES_SUCCESS': {
      const { deal } = state

      const { endDate: end_date, startDate: start_date } = action.data

      toastr.success('Dates have been changed successfully', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })

      return {
        ...state,
        deal: extendDealForMessageWindow({ ...deal, end_date, start_date }),
        showChangeDatesModal: false,
      }
    }

    case 'UPDATE_DEAL_DATES_FAILURE':
      toastr.error('Dates couldn\'t be saved. Try again later.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      return { ...state, showChangeDatesModal: false }

    case 'GET_DEAL_ANALYTICS_BY_ID':
      return Object.assign({}, state, { loading: true })

    case 'GET_DEAL_ANALYTICS_BY_ID_SUCCESS':
      return getDojoClicks(state, action)

    case 'SHOW_CHANGE_DATES_MODAL':
      return { ...state, showChangeDatesModal: action.data }
    default:
      return state
  }
}
