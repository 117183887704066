import React, { Component } from 'react'
import * as MessagingPartnerActions from 'actions/messaging-partner-actions'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'
import Dialog from 'react-toolbox/lib/dialog'
import { Button } from 'react-toolbox/lib/button'
import { P } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import { withRouter } from 'react-router-dom'
import dialogTheme from 'css/themes/dialogs/formModal.css'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MessagingPartnerActions, dispatch)
})

const mapStateToProps = (state) => {
  return {
    open: state.interestedConfirmationModal.open,
    invite_attrs: state.interestedConfirmationModal.invite_attrs
  }
}

class InterestedConfirmationModal extends Component {
  closeModal = () => {
    this.props.actions.toggleInterestedConfirmationModal(false)
  }

  createAgreement = () => {
    const { invite_attrs: { campaign_id, partnership_invite_id } } = this.props

    this.closeModal()
    this.props.history.push(`/builder/${campaign_id}/partnership-agreement/choose/${partnership_invite_id}`)
  }

  render() {
    let {
      open,
      invite_attrs: {
        brand_name,
        campaign_name,
        application,
      }
    } = this.props

    return (
      <Dialog
        theme={ dialogTheme }
        active={ open }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title={ application ? `Interested in ${brand_name}` : 'Your Interest Has Been Sent!'}
      >
        { application ?
          <P style={{
            fontSize: '16px'
          }}>
            We’ve let them know you’re interested! Create a Partnership
            Agreement to confirm their prizing and promotional obligation - these
            can be finalized as you create the agreement.
            <br />
            <a style={{color: '#03a9f4'}} href='//help.dojomojo.com/hc/en-us/articles/115005958507-Creating-a-Partnership-Agreement'>Learn More.</a>
          </P>
          :
          <P style={{
            fontSize: '16px'
          }}>
            We’ve let the Host know you’re interested in joining {campaign_name}.
            Once you’ve both agreed to what you’ll contribute, they will create
            a Partnership Agreement for you to sign, so keep an eye on
            your inbox. &nbsp;
            <a style={{color: '#03a9f4'}} href='//help.dojomojo.com/hc/en-us/articles/360003182772-Review-and-Sign-the-Partnership-Agreement'>Learn More.</a>
          </P>
        }
        <ModalButtonGroup
          canSave={ true }
          hideLine={ true }
          confirm={ application ? this.createAgreement : this.closeModal }
          confirmText={ application ? 'Create Agreement' : 'Got It' }
        />
      </Dialog>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterestedConfirmationModal))
