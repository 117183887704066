import React from 'react';
import COLOURS from 'util/colours';

class MessagingRowItemContent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { heading, text } = this.props;

    return (
      <div>
        { heading ?
            <p style={{
              fontFamily: 'Larsseit-Bold',
              fontSize: '14px',
              marginBottom: '12px'
            }}>
              { heading }
            </p>
          :
            null
        }
        <p dangerouslySetInnerHTML={{ __html: text }}
            style={{
            height: heading ? '14px' : '32px',
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '16px',
            color: COLOURS.ink,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: heading ? '1' : '2',
            WebkitBoxOrient: 'vertical',
            display: '-webkit-box'
          }} />
      </div>
    )
  }
}

export default MessagingRowItemContent;
