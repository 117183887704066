import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import OverviewTheme from 'css/themes/Overview.css'
import { H6, P } from 'visual-components/util/texts'

const nav = [
  {
    url: 'quickview',
    label: 'Quick View',
  }, {
    url: 'grow-optimize',
    label: 'Post Entry Actions',
  }, {
    url: 'email-delivery',
    label: 'Integrations',
  }, {
    url: 'calendar',
    label: 'Calendar',
  }, {
    url: 'links-files',
    label: 'Links & Files',
  }, {
    url: 'announcements',
    label: 'Announcements',
  },
]

const soloNav = [
  {
    url: 'quickview',
    label: 'Post Entry',
  }, {
    url: 'welcome-message',
    label: 'SMS Welcome Message',
  }, {
    url: 'email-delivery',
    label: 'Integrations',
  }, {
    url: 'links-files',
    label: 'Links & Files',
  },
]

const stateToProps = ({ currentBrand }) => ({ currentBrand })

class NavItemComp extends Component {
  navigate = () => {
    const {
      history,
      prefixedUrl,
      url,
      isSolo,
    } = this.props

    history.push(`${prefixedUrl}/${url}`)
  }

  render() {
    const {
      label,
      selected,
      isSolo,
    } = this.props

    const LabelComponent = selected ? H6 : P

    return (
      <div className={`${OverviewTheme.navItem} ${isSolo ? OverviewTheme.soloNavItem : ''}`} onClick={this.navigate}>
        <div style={{ cursor: 'pointer' }} className={`${OverviewTheme.navLabelHolder} ${selected ? OverviewTheme.selected : ''}`}>
          <LabelComponent ink>
            {label}
          </LabelComponent>
        </div>
      </div>
    )
  }
}

const NavItem = withRouter(NavItemComp)

const renderNavItems = (prefixedUrl, selected, isSolo) => (navItem, idx) => (
  <NavItem
    key={idx}
    prefixedUrl={prefixedUrl}
    selected={selected === navItem.url}
    isSolo={isSolo}
    {...navItem}
  />
)

// eslint-disable-next-line react/no-multi-comp
class OverviewNav extends Component {
  render() {
    const {
      selected,
      prefixedUrl,
      isSolo,
    } = this.props

    const navItems = (isSolo ? soloNav : nav).map(renderNavItems(prefixedUrl, selected, isSolo))

    return (
      <div className={`${OverviewTheme.nav} ${isSolo ? OverviewTheme.soloNav : ''}`}>
        { navItems }
      </div>
    )
  }
}

OverviewNav.propTypes = {
  selected: PropTypes.string,
  prefixedUrl: PropTypes.string,
  isSolo: PropTypes.bool,
}

OverviewNav.defaultProps = {
  selected: '',
  prefixedUrl: '',
  isSolo: false,
}

export default connect(stateToProps)(OverviewNav)
