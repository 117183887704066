import React from 'react'
import injectSheet from 'react-jss'
import { Card } from 'react-toolbox/lib/card'
import { H3, H4 } from 'visual-components/util/texts'
import ColumnGrouped from 'visual-components/util/graphs/column-grouped'
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option'

const styles = {
  card: {
    width: '976px',
    height: '232px',
    overflow: 'visible',
  },
  columnGrouped: {
    marginLeft: '24px',
  },
  emptyState: {
    marginTop: '54px',
    marginLeft: '284px',
    width: '360px',
    height: '48px',
  },
  emptyStateText: {
    textAlign: 'center',
  },
  graphContainer: {
    padding: '20px 24px',
  },
  header: {
    marginBottom: '24px',
  },
}

class MobilePartnerContributions extends React.Component {
  toggleItem = type => {
    const { hideMap } = this.props.data
    hideMap[type] = !hideMap[type]
    store.dispatch({
      model: 'analyticsCampaign',
      type: 'UPDATE_ATTR',
      data: {
        campaignMobilePartnerContributions: _.extend({}, this.props.data, {
          hideMap,
        }),
      },
    })
  }

  renderEmptyState = classes => (
    <div className={classes.emptyState}>
      <div
        className={classes.emptyStateText}
      >
        <H4 multiline cloudGrey>
          <i>
            Mobile Marketing allows hosts to send mobile messages to sweepstakes entrants.
            {' '}
            <a href="/">Learn more</a>
          </i>
        </H4>
      </div>
    </div>
  )

  render() {
    let {
      data,
      data: {
        items, colorMap, categories, hideMap,
      },
      started,
      classes,
    } = this.props

    hideMap = hideMap || {}
    const options = items
      ? items.map((item, idx) => (
        <ChartOption
          {...item}
          onClick={this.toggleItem}
          selected={!hideMap[item.name]}
          key={idx}
        />
      ))
      : []

    return (
      <Card className={classes.card}>
        <div className={classes.graphContainer}>
          <div className={classes.header}>
            <H3 coral>
              <small>SMS Contributions by Partner</small>
            </H3>
          </div>
          {data ? (
            <React.Fragment>
              <ColumnGrouped
                className={classes.columnGrouped}
                data={data}
                showAll
                started={started}
                graphClass="mobile-partner-contributions"
                yAxis={{ labels: { format: '0%' } }}
              />
              {options}
            </React.Fragment>
          ) : (
            this.renderEmptyState(classes)
          )}
        </div>
      </Card>
    )
  }
}

export default injectSheet(styles)(MobilePartnerContributions)
