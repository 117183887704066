import React from 'react'
import injectSheet from 'react-jss'
import { object, string } from 'prop-types'
import { Small } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'flex-start',
    width: 640,
    height: 482,
    marginTop: 16,
    overflowY: 'scroll',
    overflowX: 'visible',
  },
  imageContainer: {
    width: 200,
    height: 128,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 2,
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
    },
    '& video': {
      objectFit: 'cover',
      minWidth: 296,
      height: 160,
      marginBottom: 4,
    },
  },
  videoContainer: {
    width: 296,
    height: 160,
    marginBottom: 8,
    marginRight: 16,
  },
  selected: {
    border: `4px solid ${COLOURS.seaGreen}`,
  },
  selectedIcon: {
    position: 'absolute',
    marginTop: 8,
    marginLeft: 8,
    zIndex: 2,
    '& img': {
      width: 16,
      height: 16,
    },
  },
  selectedContainer: {
    height: 412,
    marginBottom: 24,
  },
  noMargin: {
    marginRight: 0,
  },
  portrait: {
    width: 200,
    minHeight: 128,
    marginTop: '-30%',
  },
  landscape: {
    minWidth: 200,
    height: 128,
  },
  attribution: {
    marginBottom: 24,
  },
  duration: {
    marginBottom: 12,
  },
}

class SearchImageRecentTab extends React.Component {
  static propTypes = {
    backgroundType: string.isRequired,
    backgroundImages: object.isRequired,
    backgroundImageActions: object.isRequired,
    classes: object.isRequired,
  }

  select = selectedImage => {
    const { backgroundImageActions: { updateAttr } } = this.props
    updateAttr({ selectedImage })
  }

  play = idx => {
    const myVideo = document.getElementById(`video${idx}`)
    myVideo.play()
  }

  pause = idx => {
    const myVideo = document.getElementById(`video${idx}`)
    myVideo.pause()
  }

  renderImages = () => {
    const {
      backgroundType,
      backgroundImages: {
        recentStockImages,
        selectedImage,
      },
      classes: css,
    } = this.props

    const isVideo = backgroundType === 'Video'

    const attribution = selectedImage && selectedImage.metadata && {
      photoUrl: selectedImage.metadata.photoUrl,
      artistUrl: selectedImage.metadata.artistUrl,
      artistName: selectedImage.metadata.artistName,
    }

    const filtered = recentStockImages.filter(item =>
      item.src.endsWith(isVideo ? '.mp4' : '.png') || (!isVideo && item.src.includes('unsplash')))
    const images = filtered.map((item, idx) => {
      const isSelected = selectedImage && item.src && selectedImage.src === item.src
      const portrait = item.metadata && item.metadata.height > item.metadata.width
      const noMargin = isVideo
        ? idx % 2 === 1
        : idx !== 0 && (idx - 1) % 3 === 1

      let duration
      if (isVideo && item.metadata && item.metadata.duration) {
        const minutes = Math.floor(item.metadata.duration / 60)
        const seconds = Math.floor(item.metadata.duration % 60)
        duration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      }

      return (
        <div>
          <div
            className={`${css.imageContainer} ${isVideo ? css.videoContainer : ''} ${isSelected ? css.selected : ''} ${noMargin ? css.noMargin : ''}`}
            onClick={() => this.select(item)}
          >
            { isSelected ? (
              <div className={css.selectedIcon}>
                <img src="/images/integration-logos/checkmark.svg" />
              </div>
            ) : null}
            { isVideo ? (
              <div>
                <div onMouseOver={() => this.play(idx)} onMouseOut={() => this.pause(idx)}>
                  <video muted className={css.video} id={`video${idx}`} key={item.src}>
                    <source src={item.src} type="video/mp4" />
                  </video>
                </div>
              </div>
            ) : (
              <img src={item.src} className={portrait ? css.portrait : css.landscape} />
            )}
          </div>
          { duration ? (
            <div className={css.duration}>
              <Small>{duration}</Small>
            </div>
          ) : null }
        </div>
      )
    })

    return (
      <div>
        <div className={`${css.container} ${selectedImage ? css.selectedContainer : ''}`}>
          {images}
        </div>
        { selectedImage && attribution ? (
          <div>
            <div className={css.attribution}>
              <Small multiline>
                <em>
                  <a href={attribution.photoUrl} target="_blank" rel="noopener noreferrer">{isVideo ? 'Video' : 'Photo'}</a>
                  {' by '}
                  <a href={attribution.artistUrl} target="_blank" rel="noopener noreferrer">{attribution.artistName}</a>
                </em>
              </Small>
            </div>
          </div>
        ) : null }
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderImages()}
      </div>
    )
  }
}

export default injectSheet(styles)(SearchImageRecentTab)
