import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';

import { H4 } from 'visual-components/util/texts';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import dialogTheme from 'css/themes/dialogs/dialogBase.css';

export function BrandLogoRequiredModal({ dispatch, open }) {
  const closeModal = () => dispatch({ type: 'UPDATE_ATTR', model: 'uploadLogoToApplyModal', data: { open: false }});

  return (
    <Dialog
      theme={dialogTheme}
      active={open}
      onEscKeyDown={closeModal}
      onOverlayClick={closeModal}
      title="Upload Brand Logo to Apply">
      <H4 multiline>
        Whoops! Your Brand Profile needs your logo before you can apply to this campaign. Please upload a logo to continue.
      </H4>
      <ModalButtonGroup
        canSave
        hideLine
        confirm={closeModal}
        confirmText="Got it"
        cancelText="Cancel"
        cancel={closeModal}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  open: state.uploadLogoToApplyModal.open
});

export default connect(mapStateToProps)(BrandLogoRequiredModal);
