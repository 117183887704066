import React, { Component } from 'react'
import COLOURS from 'util/colours'
import moment from 'moment-timezone'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import cn from 'classnames'
import Logo from './Logo'
import PartnerLogos from './PartnerLogos'
import Form from './form/Form'
import Disclaimer from './Disclaimer'

const styles = {
  container: {
    width: '100%',
  },
  prizingLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  contestEnded: {
    margin: '20px 0',
    border: `1px solid ${COLOURS.orange}`,
    background: COLOURS.white,
    textAlign: 'center',
    padding: '10px',
  },
  disclaimer: {
    fontFamily: 'OpenSans',
    fontSize: 9,
    color: '#2c3541',
    lineHeight: '156%',
    marginTop: 57,
  },
  bottomInfo: {
    background: '#fff',
  },
  hide: {
    display: 'none',
  },
}

class FormContainer extends Component {
  static propTypes = {
    hidePartnerInformation: propTypes.bool,
    campaign: propTypes.object.isRequired,
    updateAttr: propTypes.object.isRequired,
    updateElementsAttr: propTypes.object.isRequired,
    edit: propTypes.object.isRequired,
    editElements: propTypes.object.isRequired,
    termsLink: propTypes.object.isRequired,
    elements: propTypes.object.isRequired,
    preEntryStyles: propTypes.object.isRequired,
    agreeToLiability: propTypes.func.isRequired,
    acceptedInvites: propTypes.array.isRequired,
    customFonts: propTypes.array.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool.isRequired,
  }

  static defaultProps = {
    hidePartnerInformation: false,
  }

  static contextTypes = {
    baseStyles: propTypes.object,
    placeholderColor: propTypes.string,
  }

  renderCampaignEnded = () => {
    const {
      campaign,
      classes: css,
    } = this.props

    return (
      <div
        id="sweepstakes-ended"
        className={cn({
          [css.contestEnded]: true,
          [css.hide]: campaign.endDate >= new Date(),
        })}
      >
        Giveaway ended&nbsp;
        { moment.tz(campaign.endDate, 'America/New_York').format('MM/DD/YYYY') }
      </div>
    )
  }

  renderCampaignEntryForm = () => {
    const {
      updateAttr,
      updateElementsAttr,
      edit,
      editElements,
      termsLink,
      campaign,
      elements,
      preEntryStyles,
      agreeToLiability,
      acceptedInvites,
      customFonts,
      desktopVersion,
      hidePartnerInformation,
      classes: css,
    } = this.props

    const {
      baseStyles,
    } = this.context

    return (
      <div
        id="form-container"
        className={cn({ [css.hide]: campaign.endDate < new Date() })}
      >
        <div className={baseStyles.formContainerForm}>
          <Form
            updateAttr={updateAttr}
            updateElementsAttr={updateElementsAttr}
            edit={edit}
            editElements={editElements}
            termsLink={termsLink}
            campaign={campaign}
            elements={elements}
            preEntryStyles={preEntryStyles}
            agreeToLiability={agreeToLiability}
            customFonts={customFonts}
            desktopVersion={desktopVersion}
            acceptedInvites={acceptedInvites}
          />
        </div>
        {
          !hidePartnerInformation
          && (
            <div className={cn(css.bottomInfo, baseStyles.formContainerBottomInfo)}>
              <PartnerLogos
                updateAttr={this.props.updateElementsAttr}
                edit={this.props.editElements}
                campaign={elements}
                partnerCampaigns={acceptedInvites}
                desktopVersion={desktopVersion}
              />
              <div className={css.prizingLogo}>
                <Logo
                  logoPath={elements.prizing_logo || campaign.prizingLogo}
                  updateAttr={updateElementsAttr}
                  editName="prizing-logo"
                  edit={this.props.editElements}
                  campaign={campaign}
                  elements={elements}
                  maxHeight={elements.styles.prizingLogo ? elements.styles.prizingLogo.height : 100}
                  id="prizing-logo"
                  allowDelete
                  label="PRIZING BY"
                  uploadButtonLabel="Upload a prizing logo"
                  modalPosition="top"
                />
              </div>
              <Disclaimer
                campaign={campaign}
                edit={this.props.edit}
                updateAttr={updateAttr}
                customFonts={customFonts}
              />
            </div>
          )
        }
        
      </div>
    )
  }

  render() {
    const {
      classes: css,
    } = this.props

    return (
      <div
        className={css.container}
        id="form-holder-tour"
      >
        <div id="form">
          {this.renderCampaignEnded()}
          {this.renderCampaignEntryForm()}
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(FormContainer)
