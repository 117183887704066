import React from 'react'

import Validator from 'util/form-validator'
import Field from 'visual-components/util/inputs/field'

class YoutubeSubscribe extends React.Component {
  componentDidMount() {
    this.refs.field.validate(this.refs.field.valueFromDataModel())
  }

  render() {
    const { bonus: { id, partnership_invite_id }, bonusItems } = this.props

    return (
      <Field id={ id } ref={ 'field' }
        label={ 'YouTube Channel URL' }
        attrName={ 'youtubechannel' }
        endpoint={ `/partnership-bonus/${partnership_invite_id}/${id}` }
        dataModel={ bonusItems }
        formModel={ bonusItems }
        validations={ [Validator.required] }
      />
    )
  }
}

export default YoutubeSubscribe
