import React, { Component } from 'react'
import DefaultCard from 'visual-components/util/cards/default-card'
import EntryThermometer from './entry-thermometer'
import { P, Small } from 'visual-components/util/texts'
import theme from 'css/themes/referralTheme.css'
import moment from 'moment-timezone'

class WinFreeGift extends Component {
  render() {
    let {
      lastWinnerName,
      nextDrawingDate,
      entries,
      userName
    } = this.props

    return(
      <DefaultCard title="Win a $250 Gift Card!" className={theme.winFreeGiftCard}>
        <div className={theme.textWrapper}>
          <Small multiline>
            For every brand you refer, you’ll be entered in our monthly raffle to win a $250 Amazon gift card! The more brands you refer, the more chances you have to win.
          </Small>
        </div>
        { lastWinnerName
          ? <div className={theme.winnerWrapper}>
              <P multiline>
                <em>
                  {`The last raffle’s winner was ${lastWinnerName}`}
                </em>
              </P>
            </div>
          : null
        }
        <div className={theme.nextDrawing}>
          <P multiline>
            <em>
              {`The next drawing is on ${nextDrawingDate}.`}
            </em>
          </P>
        </div>
        <EntryThermometer
          entries={entries}
          userName={userName}
        />
      </DefaultCard>
    )
  }
}

export default WinFreeGift
