import React from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import * as ReactRedux from 'react-redux';

import COLOURS from 'util/colours';

import Dropdown from 'react-toolbox/lib/dropdown';
import DropDownTheme from 'css/themes/SwitchBrandDropdown.css'

class MessagingSwitchBrand extends React.Component {
  constructor(props) {
    super(props);
    this.changeBrand = this.changeBrand.bind(this)
  }
  changeBrand(value){
    const {history} = this.props
    const generateLink = id => history.createHref({ pathname: `/messaging/?brandId=${id}` })
    window.open(generateLink(value), '_blank');
  }

  render() {
    const { userBrands, currentBrandId, unread } = this.props;

    let brands = _.map(userBrands, (brand)=> {
      return {
        label: brand.id == currentBrandId ? `${brand.accountname}` : `${brand.accountname} ${brand.unreadMessages ? `(${brand.unreadMessages})` : '' }`,
        value: brand.id,
        unreads: brand.id != currentBrandId && brand.unreadMessages ? Number(brand.unreadMessages) : 0
      }
    })

    let idx = _.findIndex(brands, {value: currentBrandId})
    if (idx != -1) {
      let currentBrandUnread = brands.splice(idx, 1)[0]
      brands.unshift(currentBrandUnread);
    }

    let sumUnread = _.sumBy(brands, 'unreads') + (unread || 0);

    return(
      <div style={{marginTop: '18px', position: 'relative'}}>
        { sumUnread ? 
          <div style={{
            position: 'absolute',
            background: COLOURS.alertRed,
            fontFamily: 'Larsseit-Bold',
            height: '24px',
            width: '24px',
            borderRadius: '100%',
            color: 'white',
            fontSize: '12px',
            lineHeight: '26px',
            top: '-12px',
            right: '12px',
            zIndex: '101',
            textAlign: 'center'
          }}>
            { sumUnread }
          </div> 
          : null 
        }
        <Dropdown
          onChange={ this.changeBrand }
          source={ brands }
          value={ currentBrandId }
          theme = {DropDownTheme}
        />
      </div>
    )
  }
};

const mapState = (state) => {
  return {
    userBrands: state.userBrands.brands,
    currentBrandId: state.currentBrand.id,
    unread: state.conversations.unread
  }
}
export default ReactRedux.connect(mapState)(MessagingSwitchBrand);
