import React, { Component } from 'react'
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import COLOURS from 'util/colours';
import SearchResultItem from './search-result-item';
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';
import Card from 'react-toolbox/lib/card';
import SearchCard from 'visual-components/shared/search-card';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const themes = {
  Button: ButtonTheme,
  Dialog: DialogTheme,
}

class NewMessageModal extends Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.state = {
      searchValue: '',
    }
    this.updateSuggestions = this.updateSuggestions.bind(this)
    this.applySearch = _.debounce(this.applySearch.bind(this), 500);
    this.setBrand = this.setBrand.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showModal == false && this.props.showModal == true ) {
      $('section input').focus()
    }
  }

  updateSuggestions(newValue) {
    this.setState({searchValue: newValue});
    this.applySearch(newValue);
  }

  applySearch(value) {
    if (value.length != 0) {
      MessagingDispatcher.searchForBrands(value, 'modal')
    } else {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'conversations',
        data: {
          modalShowSearchResults: false
        }
      })
    }
  }


  setBrand(brandId, brandName) {
    this.setState({
      searchValue: ''
    })

    PricingDispatcher.check('messaging.message.create', function () {
      store.dispatch({
        model: 'conversations',
        type: 'MESSAGE_BRAND',
        data: {
          conversationBrandId: brandId,
          conversationBrandName: brandName
        }
      })
    }, brandId)
  }

  hideModal() {
    this.setState({
      searchValue: ''
    })
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showNewMessageModal: false,
        modalShowSearchResults: false
      }
    })
  }


  render(){
    let {
      showModal,
      modalSearchResults,
      modalShowSearchResults,
      newConversationThread,
      newConversationBrandId,
      newConversationBrandName,
      inputRef }
     = this.props

    let resultItems = _.map(modalSearchResults, (item) => {
      return (
        <SearchResultItem
          brand = {item._source}
          choose ={ ()=>{ this.setBrand(item._source.id, item._source.accountname)}  } />
      )
    })

    resultItems = resultItems.slice(0, 6);

    return (
      <Dialog
        active={ showModal ? true : false }
        onEscDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
        theme={themes.Dialog}
      >
        <Row>
          <Col xs={12}>
            <div style={{position: 'relative'}}>
              <SearchCard
                placeholder={'Which brand do you want to talk to?'}
                value={ this.state.searchValue }
                onChange={ this.updateSuggestions }
                inputRef = { inputRef }
                />
              { modalShowSearchResults ?
                <Card style={{
                  padding: '0px',
                  listStyleType: 'none',
                  position: 'absolute',
                  background: 'white',
                  zIndex: '100',
                  marginTop: '0px',
                  width: '100%',
                  borderTop: `1px solid ${ COLOURS.lightGrey }`
                }}>
                   { resultItems }
                </Card>
                : null
              }
            </div>
          </Col>
        </Row>
      </Dialog>
    )
  }
}


const mapState = (state) => {
  return {
    modalShowSearchResults: state.conversations.modalShowSearchResults,
    modalSearchResults: state.conversations.modalSearchResults,
    showModal: state.conversations.showNewMessageModal,
    newConversationThread: state.conversations.newConversationThread,
    newConversationBrandId: state.conversations.newConversationBrandId,
    newConversationBrandName: state.conversations.newConversationBrandName
  }
}

export default ReactRedux.connect(mapState)(NewMessageModal);
