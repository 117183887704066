export const loadBrandClaims = () => ({
  http: {
    url: '/brand-claims',
  },
  types: [
    'LOADING_BRAND_CLAIMS',
    'LOAD_BRAND_CLAIMS_SUCCESS',
    'LOAD_BRAND_CLAIMS_FAILURE',
  ],
})

export const getClaim = claim => {
  const { brandClaims } = store.getState()
  return brandClaims[claim]
}
