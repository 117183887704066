import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { Tiny } from 'visual-components/util/texts'

import COLOURS from 'util/colours'

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: 'fit-content',
    '&:hover > div:last-child': {
      animation: 'show .2s linear forwards',
    },
  },
  tooltip: {
    zIndex: 1,
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: COLOURS.stone,
    borderRadius: 2,
    maxWidth: 128,
    transition: 'all .2s linear',
    '& p': {
      fontSize: '9px',
    },
  },

  '@keyframes show': {
    '0%': {
      opacity: 0,
      padding: 12,
      width: 'max-content',
      height: 'max-content',
    },
    '100%': {
      opacity: 1,
      padding: 12,
      width: 'max-content',
      height: 'max-content',
    },
  },
}

function WrapperTooltip(props) {
  const {
    classes: css,
    children,
    text,
    position,
    delay,
    customClasses,
  } = props

  if (!text) return children

  const pos = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  }[position]

  const styleProps = {
    [pos]: '100%',
    animationDelay: `${delay}s`,
  }

  return (
    <div className={css.wrapper}>
      {children}
      <div style={styleProps} className={cn(css.tooltip, customClasses)}>
        <Tiny multiline white>
          {text}
        </Tiny>
      </div>
    </div>
  )
}

WrapperTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  delay: PropTypes.number,
  customClasses: PropTypes.string,
}

WrapperTooltip.defaultProps = {
  position: 'bottom',
  delay: 2,
  customClasses: '',
}

export default React.memo(injectCSS(styles)(WrapperTooltip))
