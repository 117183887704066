import React, { PureComponent as Component } from 'react'

import { H6 } from 'visual-components/util/texts'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import Row from 'visual-components/util/no-padding-grid/row'

class SelectDueDatePicker extends Component {
  static contextTypes = {
    form: PropTypes.object.isRequired,
    formActions: PropTypes.object.isRequired,
  }

  isOutsideRange = date => date.isBefore(new Date())

  update = date => {
    const {
      updateFormModel,
      validateField,
    } = this.context.formActions

    const {
      onDateClick,
    } = this.props

    updateFormModel('currentTask.due_date', 'date', date._d)
    validateField('currentTask.due_date')

    onDateClick()
  }

  render() {
    const {
      isFocused,
      isDateOpen,
      toggleSelectDate,
      initialFocusDate,
    } = this.props;

    const {
      formModel,
    } = this.context.form

    const date = formModel.currentTask.due_date

    return (
      <Row>
        <div style={{ width: '608px' }}>
          <H6>Due Date</H6>
          { date && <div style={{ paddingTop: '8px' }}>{moment(date).format('dddd, MMMM D')}</div> }
          <div style={{ paddingTop: '10px' }}>
            <a onClick={toggleSelectDate}>{ date ? 'Change Due Date' : 'Select due date' }</a>
          </div>
        </div>

        {
          isDateOpen
          && (
            <div className="single-calendar" style={{ position: 'absolute', zIndex: '2', top: '315px' }}>
              <DayPickerSingleDateController
                className
                isOutsideRange={this.isOutsideRange}
                onDateChange={this.update}
                focused
                isFocused={isFocused}
                onOutsideClick={toggleSelectDate}
                initialVisibleMonth={initialFocusDate ? () => moment(initialFocusDate) : null}
              />
            </div>
          )
        }
      </Row>
    )
  }
}

SelectDueDatePicker.propTypes = {
  date: PropTypes.object,
  onDateClick: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isDateOpen: PropTypes.bool.isRequired,
  toggleSelectDate: PropTypes.func.isRequired,
}

export default SelectDueDatePicker
