import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object, string } from 'prop-types'
import moment from 'moment'
import statusMap from 'util/deal-status-map'
import Spinner from 'util/components/spinner'

import DealCollaborateTheme from 'css/themes/media-market/DealCollaborate.css'

import * as DealCollaborationActions from 'actions/deal-collaboration-actions'
import * as DealActions from 'actions/deal-actions'
import * as AssetActions from 'actions/asset-actions'
import DataIntegrationSuggestion from 'components/dialogs/data-integration-suggestion'
import DealCard from '../DealCard'
import Assets from './assets/Assets'
import AssetsPending from './Assets'
import Messenger from './messaging/Messenger'
import CollaborateStatusDialog from './collaborate-status-dialog'
import CollaborateEducationalModal from './CollaborateEducationalModal'
import DealDeclineReasonModal from './DealDeclineReasonModal'

const mapStateToProps = ({
  currentBrand,
  deals: { deal, successfulAction, error },
  dealCollaboration,
  dealAssets,
  profile,
}) => ({
  currentBrand,
  deal,
  successfulAction,
  error,
  dealCollaboration,
  dealAssets,
  profile,
})

const mapDispatchToProps = dispatch => ({
  dealCollaborationActions: bindActionCreators(DealCollaborationActions, dispatch),
  dealActions: bindActionCreators(DealActions, dispatch),
  assetActions: bindActionCreators(AssetActions, dispatch),
})

class DealCollaborate extends PureComponent {
  static propTypes = {
    deal: object.isRequired,
    successfulAction: object,
    error: string.isRequired,
    currentBrand: object.isRequired,
    dealCollaboration: object.isRequired,
    dealCollaborationActions: object.isRequired,
    dealActions: object.isRequired,
    assetActions: object.isRequired,
    history: object.isRequired,
    match: object.isRequired,
    profile: object.isRequired,
    dealAssets: object.isRequired,
  }

  static defaultProps = {
    successfulAction: {},
  }

  state = {
    canShowDataIntegrationDialog: false,
  }

  componentWillMount() {
    const {
      match: {
        params: { dealId },
      },
      dealCollaborationActions: {
        loadMessages,
        loadLinks,
      },
      assetActions: {
        loadDealAssets,
      },
    } = this.props

    loadDealAssets(dealId)
    loadMessages(dealId)
    loadLinks(dealId)
  }

  componentDidUpdate(prevProps) {
    const {
      currentBrand,
      error,
      successfulAction: { id, type } = {},
      dealActions: { clearActionSuccess, finished },
      dealCollaborationActions: { setStatusDialog },
      deal: { selling_brand_id, currentProposal, readReceipts },
    } = this.props

    const { canShowDataIntegrationDialog } = this.state

    const isBuyer = currentBrand.id !== selling_brand_id

    //if the buyer just accepted the deal
    if (id && type === 'accept') {
      setStatusDialog('accepted')
      clearActionSuccess()

      if (isBuyer) {
        this.setState({ canShowDataIntegrationDialog: true })
      }
    }


    //if the seller just accepted the deal
    if (
      canShowDataIntegrationDialog === false
      && currentProposal.accepted_at
      && isBuyer
      && currentProposal.status === 'accepted'
      && readReceipts.length
      && moment(currentProposal.accepted_at).isAfter(readReceipts[0].last_read)
    ) {
      this.setState({ canShowDataIntegrationDialog: true })
    }

    if (error) {
      setStatusDialog('error')
      finished()
    }
  }

  componentWillUnmount() {
    const {
      assetActions: {
        exitCollaborate,
      },
      dealCollaborationActions: {
        clearLinks,
      },
    } = this.props

    clearLinks()
    exitCollaborate()
  }

  editProposal = type => {
    const {
      deal,
      history,
      dealActions: { updateAttr, clearActionSuccess },
      dealCollaborationActions: { setStatusDialog },
    } = this.props
    updateAttr({ editProposalActive: type })
    clearActionSuccess()
    setStatusDialog('')
    history.push(`/partnerships/deal/${deal.id}/edit`)
  }

  editRequest = () => {
    const {
      deal: {
        request: {
          id: requestId,
        },
      },
      history,
    } = this.props

    history.push(`/partnerships/request/${requestId}/edit`)
  }

  acceptProposal = () => {
    const {
      currentBrand,
      deal: { id: dealId, selling_brand_id },
      dealActions: { confirm },
      history,
    } = this.props

    const isSeller = currentBrand.id === selling_brand_id

    if (isSeller) {
      confirm(dealId)
    } else {
      history.push(`/partnerships/deal/${dealId}/confirm/accept`)
    }
  }

  openDeclineProposal = type => {
    const { dealCollaborationActions: { setStatusDialog } } = this.props
    setStatusDialog(type)
  }

  declineProposal = () => {
    const {
      deal,
      dealActions: { decline, showDeclineReasonModal },
      dealCollaborationActions: { setStatusDialog },
      currentBrand,
    } = this.props

    setStatusDialog('')

    const isBuyer = deal.buying_brand_id === currentBrand.id

    // Only buyer brands can give reasons why it was declined
    if (isBuyer) {
      return setTimeout(showDeclineReasonModal, 500)
    }

    // Wait for modal to disappear before showing the other modal
    return decline(deal.id)
  }

  setStatusDialog = dialogActive => {
    const { dealCollaborationActions: { setStatusDialog } } = this.props
    setStatusDialog(typeof dialogActive === 'string' ? dialogActive : '')
  }

  render() {
    const {
      deal,
      currentBrand,
      profile,
      dealCollaboration,
      dealCollaborationActions,
      dealAssets,
      dealActions: { markRead },
    } = this.props

    const {
      canShowDataIntegrationDialog,
    } = this.state

    if (
      dealCollaboration.linksLoading
      || dealCollaboration.messagesLoading
      || dealAssets.loading
      || !deal
      || !Object.keys(deal).length
    ) {
      return (
        <Spinner />
      )
    }

    const isRequest = deal.request && deal.request.status === 'pending'

    const utilityFunctions = {
      sendMessage: dealCollaborationActions.sendMessage,
      acceptProposal: this.acceptProposal,
      editProposal: this.editProposal,
      openDeclineProposal: this.openDeclineProposal,
      markRead,
      editRequest: this.editRequest,
    }


    return (
      <div>
        <div className={DealCollaborateTheme.container}>
          <div>
            <DealCard
              showEdit
              deal={deal}
              statusMap={statusMap}
              currentBrandId={currentBrand.id}
              collaborateView
            />
            {
              isRequest
                ? <AssetsPending />
                : <Assets />
            }
          </div>

          <Messenger
            deal={deal}
            dealCollaboration={dealCollaboration}
            dealAssets={dealAssets}
            utilityFunctions={utilityFunctions}
            profile={profile}
            currentBrand={currentBrand}
            statusMap={statusMap}
          />
          <DataIntegrationSuggestion
            enabled={canShowDataIntegrationDialog}
            left={130}
          />
        </div>

        <CollaborateStatusDialog
          currentBrand={currentBrand}
          deal={deal}
          dialogActive={dealCollaboration.dialogActive}
          counterDeal={() => this.editProposal('counter')}
          declineDeal={this.declineProposal}
          setDialog={this.setStatusDialog}
        />
        <CollaborateEducationalModal />
        <DealDeclineReasonModal />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealCollaborate))
