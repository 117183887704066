import React from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import appbarTheme from 'css/themes/homePage/appbarTheme.css';

class LeftSide extends React.Component {
  static propTypes = {
    lightBackground: bool
  }

  render() {
    const {
      lightBackground
    } = this.props;
    const currentMonth = new Date().getMonth()
    const isPrideMonth = currentMonth === 5
    return (
      <div className={ appbarTheme.leftWrapper }>
        <Link to="/">
          { isPrideMonth
            ? <img alt="Logo Text" style={{ marginBottom: '16px' }} src="/images/nav-bar/pride/mojo-pride-left-aligned.png" srcSet="/images/nav-bar/pride/mojo-pride-left-aligned.png, /images/nav-bar/pride/mojo-pride-left-aligned@2x.png 2x, /images/nav-bar/pride/mojo-pride-left-aligned@3x.png 3x" />
            : lightBackground
              ? <img alt="Logo Text" src="/images/blue-text.svg" />
              : <img alt="Logo Text" src="/images/white-text.svg" />
          }
        </Link>
      </div>
    )
  }
}

export default LeftSide;
