import { useState, useCallback } from 'react'

import useWindowEvent from './useWindowEvent'

const useWindowResize = () => {
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)

  const onResize = useCallback(() => {
    setHeight(window.innerHeight)
    setWidth(window.innerWidth)
  }, [])

  useWindowEvent(window, 'resize', onResize)

  return { height, width }
}

export default useWindowResize
