import React from 'react';
import Tooltip from 'util/components/tooltip';
import { P, Micro } from 'visual-components/util/texts';

class ToggleField extends React.Component{
  constructor(props) {
    super(props);
    this.toggleChecked = this.toggleChecked.bind(this);
  }

  toggleChecked(e) {
   this.props.updateAttr( this.props.attrName, !this.props.model[this.props.attrName] );
  }

  render() {
    var holderClassName = this.props.value ? `toggle-holder checked ${this.props.toggleClasses}` : `toggle-holder not-checked ${this.props.toggleClasses}` ;

    holderClassName += ' ' + this.props.selectedClass;

    var labelStyles = [{
      paddingLeft: '12px',
      paddingRight: '12px'
    }].concat(this.props.labelStyles || []);

    return(
      <div style={ this.props.containerStyles }>
        <label onClick={ this.toggleChecked }
          style={{
            cursor: this.props.disabled ? 'not-allowed' : 'pointer',
            display: 'inline'
        }}>

          { this.props.altLabel ?
            <span style={{
              paddingLeft: '8px',
              paddingRight: '8px',
              display: 'inline-block'
            }}>
              {this.props.microAltLabel ? (
                <Micro multiline>
                  { this.props.altLabel }
                </Micro>
              ) : (
                <P multiline>
                  { this.props.altLabel }
                </P>
              )}
            </span>
            : null
          }

          <div className={ holderClassName }>
            <div className={ this.props.value ? `toggle-ball checked` : `toggle-ball not-checked` }
              style={ this.props.toggleStyles }>
            </div>
          </div>
          <div style={{
            width: '8px',
            marginLeft: '8px',
            display: 'inline'
          }}/>
          <div style={{display: 'inline-block'}}>
            <P multiline styles={ labelStyles }>
              { this.props.label }
            </P>
          </div>
        </label>
        { this.props.toolTip ?
          <Tooltip
            title={ this.props.toolTip }
            position={ this.props.position || 'bottom' }
            styles={{
              marginLeft: '10px',
              display: 'inline'
          }} />
          : null
        }
      </div>
    )
  }
};

export default ToggleField;
