import React from 'react'
import { connect } from 'react-redux'
import { object, number } from 'prop-types'
import { withRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import * as AssetActions from 'actions/asset-actions'
import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import preventBodyScroll from 'visual-components/util/modals/prevent-body-scroll'
import Header from './collaborate-assets-header'
import ImageGallery from './collaborate-image-gallery'
import CommentModule from './collaborate-comment-module'
import Viewer from './viewer/Viewer'

const mapStateToProps = ({
  deals,
  dealAssets,
  deals: { deal: { unread_count } },
  currentBrand: { id: currentBrandId },
}) => ({
  deals,
  dealAssets,
  unreadCount: unread_count,
  currentBrandId,
})

const mapDispatchToProps = dispatch => ({
  assetActions: bindActionCreators(AssetActions, dispatch),
})

const initialState = {
  zoomPercent: 100,
  selectedCommentId: {},
  newComment: null,
  showAnnotations: true,
  scrollViewerToAnnotation: null,
  scrollCommentsToComment: null,
}

class DealCollaborateAssets extends React.Component {
  static propTypes = {
    match: object.isRequired,
    deals: object.isRequired,
    assetActions: object.isRequired,
    dealAssets: object.isRequired,
    history: object.isRequired,
    unreadCount: object,
    currentBrandId: number.isRequired,
  }

  static defaultProps = {
    unreadCount: {},
  }

  state = initialState

  componentWillMount() {
    const {
      match: {
        params: { dealId },
      },
      assetActions: {
        loadDealAssets,
      },
    } = this.props
    loadDealAssets(dealId)
    this.loadComments()
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { assetId },
      },
    } = this.props
    if (prevProps.match.params.assetId !== assetId) {
      //if the user is in the middle of writing a new comment then reset it
      this.deleteNewComment()
      this.loadComments()
    }
  }

  getBlankComment = ({ ...args }) => {
    const {
      match: {
        params: { dealId, assetId },
      },
    } = this.props
    return {
      ...DealCollaborateAssets.newComment,
      deal_attachment_id: assetId,
      deal_id: dealId,
      ...args,
    }
  }

  static getCommentById(comments, id) {
    const queue = []
    let match = comments.find(comment => {
      if (comment.replies) {
        queue.push(comment.replies)
      }
      return comment.id === id
    })

    if (match) {
      return match
    }

    for (const replies of queue) {
      match = DealCollaborateAssets.getCommentById(replies, id)
      if (match) {
        return match
      }
    }

    return null
  }

  setNewCommentContent = content => {
    this.setState(prevState => {
      const newComment = {
        ...prevState.newComment,
        content,
      }
      return {
        newComment,
      }
    })
  }

  deleteNewComment = () => {
    this.setState({
      newComment: null,
    })
  }

  createAnnotation = (x, y) => {
    this.setState({
      selectedCommentId: {},
      newComment: this.getBlankComment({
        x_coord: x,
        y_coord: y,
        is_annotation: true,
      }),
    })
  }

  createComment = () => {
    this.setState({
      newComment: this.getBlankComment(),
    })
  }

  saveReplyComment = (parentId, content) => {
    if (content === '') {
      return
    }
    const {
      assetActions: { createDealAssetComment },
    } = this.props
    const newReply = this.getBlankComment({
      parent_id: parentId,
      content,
    })
    createDealAssetComment(newReply)
  }

  saveNewComment = () => {
    const {
      assetActions: {
        createDealAssetComment,
      },
      deals,
      currentBrandId,
    } = this.props
    const { newComment } = this.state
    if (newComment.content !== '') {
      createDealAssetComment({
        ...newComment,
        isSellerBrand: deals.deal.selling_brand_id === currentBrandId,
      })
    }
    this.deleteNewComment()
  }

  deleteComment = id => {
    const {
      assetActions: { deleteDealAssetComment },
      dealAssets: { comments },
    } = this.props
    const comment = DealCollaborateAssets.getCommentById(comments, id)
    deleteDealAssetComment(comment)
  }

  saveEditComment = (id, content) => {
    const {
      assetActions: { updateDealAssetComment },
    } = this.props
    const commentEdit = this.getBlankComment({
      id,
      content,
    })
    updateDealAssetComment(commentEdit)
  }

  onZoomUpdate = zoomPercent => {
    this.setState({ zoomPercent: parseInt(zoomPercent, 10) })
  }

  onImageSelect = selectedImageId => {
    const {
      history,
      assetActions: {
        clearAssetComments,
      },
      match: {
        params: { dealId, assetId },
      },
    } = this.props

    //Don't redirect to the same url
    if (selectedImageId === Number(assetId)) {
      return
    }
    clearAssetComments()
    this.setState(initialState)
    history.replace(`/partnerships/deal/${dealId}/collaborate/${selectedImageId}`)
  }

  getImage = assetId => {
    const {
      dealAssets: { assets },
    } = this.props

    return (assets.images || []).find(asset => asset.id === assetId)
  }

  getImageAttr = (assetId, attr = 's3_url') => {
    const selectedAsset = this.getImage(assetId)
    return selectedAsset ? selectedAsset[attr] : ''
  }

  /**
   * @param value comment ID
   * @param from where the ID is being set from ex. (viewer, comment)
   */
  setSelectedCommentId = (value, from = null) => {
    const {
      dealAssets: { comments },
    } = this.props
    const { showAnnotations } = this.state
    const selectedAnnotation = comments
      .find(comment => comment.id === value && comment.is_annotation)
    this.setState({
      selectedCommentId: { value }, // new object each time to force child update
      scrollViewerToAnnotation: showAnnotations && from !== 'viewer' ? selectedAnnotation : null,
      scrollCommentsToComment: from !== 'comment' ? selectedAnnotation : null,
    })
  }

  setViewerScrolledToAnnotation = () => {
    this.setState({
      scrollViewerToAnnotation: null,
    })
  }

  setCommentsScrolledToComment = () => {
    this.setState({
      scrollCommentsToComment: null,
    })
  }

  closeModal = () => {
    const {
      history,
      match: {
        params: { dealId },
      },
    } = this.props
    history.push(`/partnerships/deal/${dealId}/collaborate`)
  }

  /**
   * direction integer (-1 || 1) previous or next image
   */
  onHeaderChangeImage = direction => {
    const {
      dealAssets: { assets },
      match: {
        params: { assetId },
      },
    } = this.props

    const images = assets.images || []

    if (images.length <= 1) {
      return
    }

    let selectedAssetIndex = images.findIndex(asset => asset.id === parseInt(assetId, 10))
    if (selectedAssetIndex === -1) {
      return
    }

    selectedAssetIndex += direction

    //make sure the image isn't out of bounds
    selectedAssetIndex = selectedAssetIndex < 0 ? images.length - 1 : selectedAssetIndex
    selectedAssetIndex = selectedAssetIndex > images.length - 1 ? 0 : selectedAssetIndex

    this.onImageSelect(images[selectedAssetIndex].id)
  }

  toggleShowAnnotations = () => {
    this.setState(prevState => ({
      showAnnotations: !prevState.showAnnotations,
    }))
  }

  downloadSelectedImage = () => {
    const {
      match: {
        params: { assetId },
      },
      currentBrandId,
    } = this.props

    const asset = this.getImage(parseInt(assetId, 10))
    const link = document.createElement('a')
    link.download = `${asset.name}.${asset.type}`
    link.href = `/deals/attachment/${asset.deal_id}/${currentBrandId}/${asset.id}/download/${asset.name}.${asset.type}`
    link.click()
  }

  loadComments() {
    const {
      match: {
        params: { dealId, assetId },
      },
      assetActions: {
        clearAssetComments,
        loadDealAssetComments,
      },
    } = this.props
    clearAssetComments()
    loadDealAssetComments(dealId, assetId)
  }

  static newComment = {
    id: null,
    x_coord: null,
    y_coord: null,
    is_annotation: false,
    content: '',
    replies: [],
    user: {
      firstName: '',
      lastName: '',
    },
  }

  renderImageGallery = () => {
    const {
      dealAssets: { assets },
      match: {
        params: { assetId },
      },
      unreadCount,
    } = this.props

    return (
      <ImageGallery
        images={assets.images || []}
        selectedImageId={parseInt(assetId, 10)}
        onImageSelect={this.onImageSelect}
        unreadCount={unreadCount}
      />
    )
  }

  renderCommentModule = () => {
    const { match: { params: { dealId, assetId } } } = this.props

    const {
      selectedCommentId,
      newComment,
      showAnnotations,
      scrollCommentsToComment,
    } = this.state

    return (
      <CommentModule
        dealId={Number(dealId)}
        assetId={Number(assetId)}
        selectedCommentId={selectedCommentId}
        setSelectedCommentId={this.setSelectedCommentId}
        newComment={newComment}
        setNewCommentContent={this.setNewCommentContent}
        deleteNewComment={this.deleteNewComment}
        deleteComment={this.deleteComment}
        createComment={this.createComment}
        createReplyComment={this.createReplyComment}
        saveNewComment={this.saveNewComment}
        saveReplyComment={this.saveReplyComment}
        saveEditComment={this.saveEditComment}
        showAnnotations={!showAnnotations}
        toggleShowAnnotations={this.toggleShowAnnotations}
        scrollToComment={scrollCommentsToComment}
        setScrolledToComment={this.setCommentsScrolledToComment}
      />
    )
  }

  render() {
    const {
      zoomPercent,
      selectedCommentId,
      newComment,
      showAnnotations,
      scrollViewerToAnnotation,
    } = this.state
    const {
      dealAssets: { comments },
      match: {
        params: { assetId },
      },
    } = this.props

    let annotations = (comments || []).filter(comment => comment.is_annotation)
    annotations = showAnnotations ? annotations : []

    return ReactDOM.createPortal(
      <div className={FullscreenModalTheme.fullScreenContainer}>
        <Header
          zoomPercent={zoomPercent}
          onZoomUpdate={this.onZoomUpdate}
          onChange={this.onHeaderChangeImage}
          assetUrl={this.getImageAttr(parseInt(assetId, 10), 'name')}
          closeModal={this.closeModal}
          downloadSelectedImage={this.downloadSelectedImage}
        />
        <Viewer
          onCommentClick={this.setSelectedCommentId}
          assetUrl={this.getImageAttr(parseInt(assetId, 10))}
          zoomPercent={zoomPercent}
          leftColumn={this.renderImageGallery}
          rightColumn={this.renderCommentModule}
          comments={annotations}
          selectedCommentId={selectedCommentId}
          createNewComment={this.createAnnotation}
          deleteNewComment={this.deleteNewComment}
          newComment={newComment}
          scrollViewerToAnnotation={scrollViewerToAnnotation}
          setViewerScrolledToAnnotation={this.setViewerScrolledToAnnotation}
        />
      </div>,
      document.getElementById('modal-holder')
    )
  }
}

export default preventBodyScroll(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DealCollaborateAssets)
  )
)
