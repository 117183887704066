const brandRatingSearch = {
  page: 1,
  size: 5,
  total: 0,
  searchValue: '',
  sortBy: {
    value: 'updated_at',
    asc: false,
    options: [
      {
        value: 'updated_at',
        label: 'Most Recent',
      },
      {
        value: 'rating',
        label: 'Highest Rated',
      },
      {
        value: 'rating_desc',
        label: 'Lowest Rated',
      },
      {
        value: 'rating_brand_accountname',
        label: 'Brand Name',
      },
    ],
    defaultDirections: {
      rating_brand_accountname: true,
      rating_desc: true,
    },
  },
}

export default brandRatingSearch
