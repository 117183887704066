import React from 'react';
import _ from 'lodash';

var render = function  (data) {
var svg = d3.select("svg"),
    width = +svg.attr("width"),
    height = +svg.attr("height");

var color = d3.scaleOrdinal(d3.schemeCategory20);

var simulation = d3.forceSimulation()
    .force("link", d3.forceLink().id(function(d) { return d.id; }))
    .force("charge", d3.forceManyBody())
    .force("center", d3.forceCenter(width / 3, height / 3));

var link = svg.append("g")
    .attr("class", "links")
  .selectAll("line")
  .data(data.links)
  .enter().append("line")
    .attr("stroke-width", function(d) { return Math.sqrt(d.value); });

var node = svg.append("g")
    .attr("class", "nodes")
  .selectAll("circle")
  .data(data.nodes)
  .enter().append("circle")
    .attr("r", 7)
    .attr("fill", function(d) { return color(d.group); })
    .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended))
    // .append("text")
    //   .attr("dx", 12)
    //   .attr("dy", ".35em")
    //   .text(function(d) { return d.id })

node.append("title")
    .text(function(d) { return d.name; });

 // node.append("text")
 //      .attr("dx", 12)
 //      .attr("dy", ".35em")
 //      .text(function(d) { return d.id });

simulation
    .nodes(data.nodes)
    .on("tick", ticked);

simulation.force("link")
    .links(data.links);

function ticked() {
  link
      .attr("x1", function(d) { return d.source.x; })
      .attr("y1", function(d) { return d.source.y; })
      .attr("x2", function(d) { return d.target.x; })
      .attr("y2", function(d) { return d.target.y; });

  node
      .attr("cx", function(d) { return d.x; })
      .attr("cy", function(d) { return d.y; });
}


function dragstarted(d) {
  if (!d3.event.active) simulation.alphaTarget(0.3).restart();
  d.fx = d.x;
  d.fy = d.y;
}

function dragged(d) {
  d.fx = d3.event.x;
  d.fy = d3.event.y;
}

function dragended(d) {
  if (!d3.event.active) simulation.alphaTarget(0);
  d.fx = null;
  d.fy = null;
}

}

class NetworkGraph extends React.Component {
  componentDidMount() {
      var data = store.getState().admin.presentation.data;
      var nodes = [];
      var links = _.map(data, function (linkItem) {
        var nodeA = _.find(nodes, { id: linkItem.brand_a_id })
        if (!nodeA) {
          nodes.push({
            id: linkItem.brand_a_id,
            name: linkItem.brand_a_name,
            group: 1
          })
        }
        var nodeB = _.find(nodes, { id: linkItem.brand_b_id });
        if (!nodeB) {
          nodes.push({
            id: linkItem.brand_b_id,
            name: linkItem.brand_b_name,
            group: 1
          })
        }
        var link = {
          source: linkItem.brand_a_id,
          target: linkItem.brand_b_id,
          value: 1
        }

        return link;
      })
      render({
        nodes: nodes,
        links: links
      });
  }
  render() {
    return (
      <div>hello
        <svg width="960" height="600"></svg>
      </div>
    )
  }
}

export default NetworkGraph;