import React, { PureComponent as Component } from 'react'
import _ from 'lodash'

import PropTypes from 'prop-types'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import BlueTag from './blue-tag'

const renderTag = (tag, idx) => {
  if (!tag) {
    return null
  }

  return (
    <span key={idx}>
      <BlueTag tag={tag} />
      <br />
    </span>
  )
}

class BlueTags extends Component {
  static propTypes = {
    tags: PropTypes.array.isRequired,
    noPadding: PropTypes.bool,
  }

  static defaultProps = {
    noPadding: false,
  }

  render() {
    const {
      tags,
      noPadding,
    } = this.props

    const formattedTags = _.compact(_.map(tags, renderTag))

    if (!formattedTags.length) {
      return null
    }

    return (
      <Row
        style={{
          paddingTop: noPadding ? '0px' : '12px',
          paddingRight: noPadding ? '0px' : '12px',
          paddingBottom: noPadding ? '0px' : '12px',
          paddingLeft: noPadding ? '0px' : '12px',
        }}
      >
        <Col xs={2} style={{ width: '24px', flexBasis: '24px', maxWidth: '24px' }}>
          <img src="/images/icons/tag-icon.svg" style={{ width: '16px', height: '16px' }} />
        </Col>
        <Col xs={10}>
          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            { formattedTags }
          </div>
        </Col>
      </Row>
    )
  }
}

BlueTags.propTypes = {
  tags: PropTypes.array.isRequired,
  noPadding: PropTypes.bool,
}

export default BlueTags
