import React from 'react'
import COLOURS from 'util/colours'

const SpinnerComponent = props => {
  const currentMonth = new Date().getMonth()
  const isPrideMonth = currentMonth === 5
  const logoUrl = isPrideMonth
    ? '/images/nav-bar/pride/mojo-pride-zen.svg'
    : '/images/mojo-zen-white-head.svg'
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '5000',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: COLOURS.darkGreen.full,
          opacity: props.needsBackground ? '.5' : '0.05',
        }}
      />
      <div
        style={{
          width: '200px',
          position: 'absolute',
          top: '30%',
          left: '50%',
          marginLeft: '-100px',
        }}
      >
        <img className="loader-tiger" style={{ width: '100%' }} src={logoUrl} />
        <img className="tiger-shadow" src="/images/zen-shadow.svg" />
      </div>
    </div>
  )
}

export default SpinnerComponent
