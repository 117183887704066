import React, { PureComponent as Component, Fragment } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header'
import SocialMetricsBar from 'visual-components/analyticsDashboard/overviewCard/social-metrics-bars'
import EmptyState from 'visual-components/util/graphs/empty-state'
import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'
import { Card } from 'react-toolbox/lib/card'
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option'
import * as socialActionsActions from 'actions/social-actions-actions'
import SocialActionsUpsellModal from 'visual-components/socialGrowth/upsell-modal'

const mapState = ({
  currentCampaign,
  SocialActions,
}) => ({
  currentCampaign,
  upsellModalOpen: SocialActions.showUpsellModal,
})

const mapDispatch = dispatch => ({
  socialActionsActions: bindActionCreators(socialActionsActions, dispatch),
})

class SocialChart extends Component {
  static propTypes = {
    changeDateFilter: PropTypes.func.isRequired,
    toggleItem: PropTypes.func.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
    data: PropTypes.object,
    datePicker: PropTypes.object.isRequired,
  }

  static defaultProps = {
    data: {},
  }

  render() {
    const {
      socialActionsActions: { showUpsellModal, closeUpsellModal, showPurchaseModal },
      toggleItem,
      changeDateFilter,
      data,
      oldestDate,
      datePicker,
      currentCampaign,
      upsellModalOpen,
    } = this.props

    const { socialItems, hideMap = {} } = data

    const socialOptions = socialItems
      ? _.compact(
        socialItems.map(item => {
          if (item.name === 'linkShare') {
            return null
          }

          return (
            <ChartOption
              {...item}
              onClick={toggleItem}
              selected={!hideMap[item.name]}
              key={item.name}
            />
          )
        })
      )
      : []

    const hasData = socialItems && socialItems.some(el => el.data.some(num => num > 0))

    return (
      <Card style={{ width: '646px', height: 'auto', overflow: 'visible' }}>
        <div style={{ padding: '20px 24px 8px 24px' }}>
          <AnalyticsHeader
            title="Social Metrics"
            tooltipText="A breakdown of all the social actions taken across every channel through your campaigns"
          />
          {hasData ? (
            <div style={{ minHeight: '375px' }}>
              <SocialMetricsBar {...this.props} />
              {socialOptions}
            </div>
          ) : (
            <Fragment>
              <SocialActionsUpsellModal
                isOpen={upsellModalOpen}
                closeUpsellModal={closeUpsellModal}
                openPurchaseModal={showPurchaseModal}
              />
              <EmptyState
                text="Social Metrics are a powerful feature reserved for Essential and Premium DojoMojo users."
                learnMore="https://help.dojomojo.com/hc/en-us/articles/230426167-Add-Partner-Social-Actions-"
                height="380px"
                ctaText="Buy Social Actions"
                ctaAction={showUpsellModal}
                bigCard
                bigText
              />
            </Fragment>
          )}
        </div>

        {changeDateFilter ? (
          <CardDateRangePickerRow
            oldestDate={oldestDate}
            changeFilter={changeDateFilter}
            {...datePicker}
          />
        ) : null}
      </Card>
    )
  }
}

export default connect(
  mapState,
  mapDispatch
)(SocialChart)
