import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AnalyticsDashboard from 'components/analytics/DashboardAnalytics'
import CampaignsOverview from 'components/analytics/CampaignsOverview'
import CampaignAnalytics from 'components/analytics/CampaignAnalytics'
import PartnershipsOverview from 'components/analytics/PartnershipsOverview'
import SmsAnalytics from 'components/analytics/SmsAnalytics'
import PartnershipAnalytics from 'components/analytics/PartnershipAnalytics'
import { Route, Redirect, Switch } from 'react-router-dom'
import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import SideNav from 'visual-components/sideBars/side-nav'
import ExportAnalyticsCsv from 'components/analytics/export-analytics-csv'
import MediaMarketAnalytics from 'components/analytics/MediaMarketAnalytics'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import * as DataIntegrationsActions from 'actions/data-integration-actions'

const mapStateToProps = ({ currentBrand, analyticsDashboard  }) => ({
  currentBrand,
  dashboardCustomCTA: analyticsDashboard.dashboardCustomCTA
})

const mapDispatchToProps = dispatch => ({
  dataIntegrationsActions: bindActionCreators(DataIntegrationsActions, dispatch),
})
class Analytics extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    dashboardCustomCTA: PropTypes.object.isRequired,
  }

  componentDidMount = () => {
    // This is because of the new UpdatedTermsModal logic
    // Doesn't load analytics data due to its logic.
    // This is in case they are not loaded, so request for them
    const { dashboardCustomCTA, dataIntegrationsActions } = this.props
    if (!dashboardCustomCTA) {
      AnalyticsDispatcher.loadDefaultDashboardAnalytics(false)
    }
    dataIntegrationsActions.load()
  }

  render() {
    const { match, currentBrand } = this.props

    const { features, id: brandId } = currentBrand
    const prefix = match.path

    // Only brands with mediaMarketSeller flag can see "Sells" in Analytics
    const MediaMarketRoutes = features.mediaMarketSeller ?
      [{ label: 'Sells', to: '/mm-sells'}] : []

    // All brands can see "Buys" in Analytics
    MediaMarketRoutes.push({
      label: 'Buys',
      to: '/mm-buys',
    })

    const routes = [
      {
        label: 'Dashboard',
        to: '/dashboard',
      },
      {
        label: 'Sweepstakes',
        to: '/campaigns',
      },
      {
        label: 'Partnerships',
        to: '/partnerships',
      },
    ]

    return (
      <InternalContentContainer width="1176px">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Route
          exact
          path={`${prefix}/:firstLevelOnly`}
          render={routeProps => <SideNav width="240px" routes={routes} prefix={prefix} {...routeProps} />}
        />
        <div style={{ width: '100%' }}>
          <Switch>
            <Redirect exact from={prefix} to={`${prefix}/dashboard`} />
            <Route exact path={`${prefix}/dashboard`} component={AnalyticsDashboard} />
            <Route exact path={`${prefix}/campaigns`} component={CampaignsOverview} />
            <Route exact path={`${prefix}/partnerships`} component={PartnershipsOverview} />
            <Route exact path={`${prefix}/sms`} component={SmsAnalytics} />
            <Route exact path={`${prefix}/campaigns/:campaignId`} component={CampaignAnalytics} />
            <Route exact path={`${prefix}/partnerships/:partnershipId`} component={PartnershipAnalytics} />
            <Route exact path={`${prefix}/mm-sells`} component={MediaMarketAnalytics} />
            <Route exact path={`${prefix}/mm-buys`} component={MediaMarketAnalytics} />
            <Redirect to="/404" />
          </Switch>
        </div>
        <ExportAnalyticsCsv />
      </InternalContentContainer>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Analytics)
