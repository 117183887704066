import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignsListTheme from 'css/themes/calendar/campaignsList.css';
import { Small } from 'visual-components/util/texts';

class PartnerList extends Component {
  render() {
    const {
      partners,
      toggleShowCampaignPartner
    } = this.props;

    const mapCampaigns = partners.map((partner, idx) => {
      return(
        <div key={idx}
          onClick={() => {
            toggleShowCampaignPartner(partner.invitee_id)
          }}
          className={ `${CampaignsListTheme.partnerList} ${partner.hideTasks ? CampaignsListTheme.hiddenItem : ''}`}>
          <div style={{ backgroundColor: partner.color }}
               className={ CampaignsListTheme.colorIcon }
          />

          <div className={ CampaignsListTheme.individualPartnerName }>
            <Small>
              { partner.header.length > 18 ? partner.header.slice(0, 18) : partner.header }
            </Small>
          </div>
        </div>
      );
    });

    return(
      <div className={ CampaignsListTheme.campaignsContainer }>
        { mapCampaigns }
      </div>
    );
  }
}

PartnerList.propTypes = {
  partners: PropTypes.array.isRequired
};


export default PartnerList;
