import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import Badge from 'visual-components/util/badge'
import { P } from 'visual-components/util/texts'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import { cloneDeep } from 'lodash'
import CustomCSSModal from 'visual-components/campaign-builder/custom-css/custom-css-modal'
import { toggleCustomFontModal } from 'dispatchers/current-brand-dispatcher'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PlatformToggle from './PlatformToggle'
import constants from '../constants.json'
import BackgroundEditor from './BackgroundEditor'
import FormBackgroundEditor from './FormBackgroundEditor'

const injectStyles = {
  container: {
    position: 'fixed',
    top: 156,
    left: 0,
    bottom: 0,
    background: '#fff',
    width: 24,
    flexShrink: 0,
    zIndex: 110,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: 24,
    transition: `width ${constants.menuTransitionTime}`,
  },
  openEditorMenu: {
    width: constants.editorMenuOpenWidth,
  },
  menuToggle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 24,
    border: '1px solid #dee4e5',
    borderTop: 'none',
    borderBottom: 'none',
    background: '#fff',
    cursor: 'pointer',
    zIndex: 1,
    '& img': {
      transform: 'rotate(0)',
      transition: `transform ${constants.menuTransitionTime}`,
    },
  },
  menuToggleClosed: {
    '& img': {
      transform: 'rotate(180deg)',
    },
  },
  menuSlider: {
    // Position absolute so it sticks to the right side
    // and slides out with the menu
    position: 'absolute',
    right: 24,
    top: 0,
    width: 320,

    height: '100%',
    display: 'flex',
    marginLeft: 0,
  },
  showSecondaryMenu: {
    marginLeft: -320,
  },
  closeMenuSliderAnimation: {
    marginLeft: constants.editorMenuOpenWidth * -1,
  },
  menu: {
    position: 'relative',
    width: '100%',
    height: '100%',
    flexShrink: 0,
    overflowX: 'auto',
    transition: `margin ${constants.menuTransitionTime}`,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    height: 38,
    transition: 'background .2s',
    textTransform: 'uppercase',
    cursor: 'default',
    paddingLeft: 32,
    marginTop: 24,
    '&:hover': {
      background: '#dee4e5',
      textTransform: 'uppercase',
    },
  },
  menuitemIcon: {
    width: 30,
    marginRight: 17,
  },
  secondaryMenuClose: {
    position: 'absolute',
    top: 23,
    left: 24,
    cursor: 'default',
  },
  postEntryLink: {
    borderTop: '1px solid #dee4e5',
    marginTop: 12,
  },
  badge: {
    marginLeft: 8,
    '& img': {
      width: 29,
      height: 16,
    },
  },
}

let backupBackgroundState = {}

const prefix = process.env.SENTRY_ENV === 'production' ? 'https://do-not-use-this-link.dojomojo.com' : ''

const EditorMenuContainer = props => {
  const {
    classes: css,
    isEditorMenuOpen,
    onEditorMenuOpen,
    campaign: {
      viewDesktop: isDesktopView,
    },
    campaign,
    opts: {
      elements,
    },
    opts,
    updateAttr,
    updateDataAttr,
    toggleFontsModal,
  } = props

  const [secondaryMenu, setSecondaryMenu] = useState({
    isShowing: false,
    type: null,
  })

  const [showingModal, setShowingModal] = useState(null)

  const onToggleEditorMenu = () => {
    onEditorMenuOpen(!isEditorMenuOpen)
  }

  const onToggleShowingModal = (modal = null) => () => {
    setShowingModal(modal)
  }

  const onSecondMenuOpen = menuType => () => {
    // save previous state for the cancel button to work
    if (['backgroundEditor', 'formBackgroundEditor'].includes(menuType)) {
      const {
        styles,
        mainImage,
      } = elements
      backupBackgroundState = {
        prevStyles: cloneDeep(styles),
        prevBackground: mainImage,
      }
    }

    setSecondaryMenu({
      isShowing: true,
      type: menuType,
    })
  }

  const onSecondaryMenuClose = () => {
    backupBackgroundState = {}
    setSecondaryMenu(prevState => ({ ...prevState, isShowing: false }))
  }

  const onSaveBackgroundEdit = () => {
    const {
      mainImage,
      styles,
    } = elements

    updateDataAttr({
      styles,
      mainImage,
    })
    backupBackgroundState = { prevBackground: mainImage, prevStyles: styles }
    onSecondaryMenuClose()
  }

  const onCancelBackgroundEdit = () => {
    const {
      mainImage,
      styles,
    } = elements

    const { prevBackground, prevStyles } = backupBackgroundState

    const isChanged = mainImage !== prevBackground
      || JSON.stringify(styles) !== JSON.stringify(prevStyles)

    if (isChanged) {
      ConfirmationDispatcher.check({
        header: 'You Have Unsaved Changes',
        copy: 'Do you want to discard these changes?',
        confirmText: 'Discard',
        action: () => {
          updateAttr('styles', prevStyles, true)
          updateAttr('mainImage', prevBackground, true)
          onSecondaryMenuClose()
          ConfirmationDispatcher.closeModal()
        },
      })
    } else {
      onSecondaryMenuClose()
    }
  }

  const renderMenuIcon = icon => (
    <div className={css.menuitemIcon}>
      <img src={`images/builder/icons/${icon}.svg`} />
    </div>
  )
  const renderMenuText = text => (
    <P>
      <i>
        {text}
      </i>
    </P>
  )

  const renderMainMenu = () => (
    <div
      className={cn({
        [css.menu]: true,
        [css.showSecondaryMenu]: secondaryMenu.isShowing,
      })}
    >
      <div
        className={css.menuItem}
        onClick={onSecondMenuOpen('backgroundEditor')}
      >
        {renderMenuIcon('background')}
        {renderMenuText('background')}
      </div>
      <div
        className={css.menuItem}
        onClick={onSecondMenuOpen('formBackgroundEditor')}
      >
        {renderMenuIcon('form')}
        {renderMenuText('form container')}
      </div>
      <div
        className={css.menuItem}
        onClick={toggleFontsModal}
      >
        {renderMenuIcon('font')}
        {renderMenuText('fonts')}
      </div>
      <div
        className={css.menuItem}
        onClick={onToggleShowingModal('customCss')}
      >
        {renderMenuIcon('css')}
        {renderMenuText('css')}
      </div>
      <PlatformToggle
        isDesktopView={isDesktopView}
      />
      <a
        className={css.menuItem}
        href={`${prefix}/landing/campaign/${campaign.id}?preview=devices&cb=${Date.now()}&previewDevice=${isDesktopView ? 'desktop' : 'mobile'}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {renderMenuIcon('preview')}
        {renderMenuText('preview')}
      </a>
      {!campaign.isSolo && (
        <div className={css.postEntryLink}>
          <Link
            to={`/partnerships/campaign/${campaign.id}/grow-optimize`}
            className={css.menuItem}
          >
            {renderMenuIcon('list')}
            {renderMenuText('Post Entry Actions')}
            <div className={css.badge}>
              <img src="images/icons/new@3x.png" />
            </div>
          </Link>
        </div>
      )}
    </div>
  )

  const renderBackgroundEditor = () => {
    const isNewTemplate = !RegExp('.*-(1|2|3|4|5|6|7|8|9)$').test(elements.landerVersion)
    const isVideoTemplate = elements.landerVersion === 'version-15'
    return (
      <BackgroundEditor
        campaign={campaign}
        elements={elements}
        isNewTemplate={isNewTemplate}
        isVideoTemplate={isVideoTemplate}
        updateAttr={updateAttr}
        onSave={onSaveBackgroundEdit}
        onCancel={onCancelBackgroundEdit}
      />
    )
  }

  const renderFormBackgroundEditor = () => (
    <FormBackgroundEditor
      campaign={campaign}
      elements={elements}
      updateAttr={updateAttr}
      onSave={onSaveBackgroundEdit}
      onCancel={onCancelBackgroundEdit}
    />
  )

  const renderSecondaryMenu = () => (
    <div className={css.menu}>
      <div
        className={css.secondaryMenuClose}
        onClick={onCancelBackgroundEdit}
      >
        <img src="/images/builder/icons/left-arrow.svg" />
      </div>
      {secondaryMenu.type === 'backgroundEditor' && renderBackgroundEditor()}
      {secondaryMenu.type === 'formBackgroundEditor' && renderFormBackgroundEditor()}
    </div>
  )

  const renderModals = () => (
    <>
      <CustomCSSModal
        active={showingModal === 'customCss'}
        opts={opts}
        toggleModal={(onToggleShowingModal(null))}
        updateAttr={updateAttr}
      />
    </>
  )

  return (
    <div className={cn(css.container, { [css.openEditorMenu]: isEditorMenuOpen })}>
      <div
        className={cn(css.menuToggle, { [css.menuToggleClosed]: !isEditorMenuOpen })}
        onClick={onToggleEditorMenu}
      >
        <img src="images/builder/icons/menu-toggle.svg" />
      </div>
      <div
        className={css.menuSlider}
      >
        {renderMainMenu()}
        {renderSecondaryMenu()}
        {renderModals()}
      </div>
    </div>
  )
}

EditorMenuContainer.propTypes = {
  classes: propTypes.object.isRequired,
  isEditorMenuOpen: propTypes.bool.isRequired,
  onEditorMenuOpen: propTypes.func.isRequired,
  campaign: propTypes.shape({
    viewDesktop: propTypes.bool.isRequired,
    isSolo: propTypes.bool.isRequired,
    id: propTypes.number.isRequired,
  }).isRequired,
  opts: propTypes.shape({
    elements: propTypes.object.isRequired,
  }).isRequired,
  updateAttr: propTypes.func.isRequired,
  updateDataAttr: propTypes.func.isRequired,
  toggleFontsModal: propTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  toggleFontsModal: bindActionCreators(
    toggleCustomFontModal, dispatch
  ),
})

export default connect(() => {}, mapDispatchToProps)(injectSheet(injectStyles)(EditorMenuContainer))
