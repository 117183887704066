import React, { Component } from 'react'
import injectSheet from 'react-jss'
import {
  number, bool, string, func, object,
} from 'prop-types'
import { H5 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

const styles = {
  previewContainer: {
    padding: '12px',
    width: '204px',
    height: '120px',
    borderBottom: `solid 1px ${COLOURS.silver}`,
  },
  fontName: {
    display: 'inline-block',
  },
  toggleButton: {
    display: 'inline-block',
    cursor: 'pointer',
    float: 'right',
  },
  previewText: {
    fontSize: '16px',
    marginTop: '12px',
    display: 'block; /* Fallback for non-webkit *',
    display: '-webkit-box',
    height: '69px; /* Fallback for non-webkit *',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    paddingTop: '4px',
  },
  evenIdx: {
    borderRight: `solid 1px ${COLOURS.silver}`,
  },
}

class FontPreview extends Component {
  static propTypes = {
    idx: number.isRequired,
    selected: bool.isRequired,
    name: string.isRequired,
    updateFont: func.isRequired,
    classes: object.isRequired,
  }

  toggleFontSelect = () => {
    const { name, selected, updateFont } = this.props
    updateFont(name, !selected)
  }

  render() {
    const {
      name,
      selected,
      idx,
      classes: css,
    } = this.props

    const formattedName = name.replace(/\+/g, ' ')

    return (
      <div className={`${css.previewContainer} ${idx % 2 === 0 ? css.evenIdx : ''}`}>
        <div className={css.fontName}>
          <H5>
            { formattedName }
          </H5>
        </div>
        <div className={css.toggleButton} onClick={this.toggleFontSelect}>
          { selected
            ? <img src="images/icons/subtract.svg" />
            : <img src="images/icons/add.svg" />
          }
        </div>
        <p className={css.previewText} style={{ fontFamily: formattedName }}>
          Pack my box with five dozen liquor jugs
        </p>
      </div>
    )
  }
}

export default injectSheet(styles)(FontPreview)
