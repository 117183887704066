import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment-timezone'

import Table from 'util/components/standardized-tables'

class PartnershipAgreementRow extends React.Component {
  nav = location => {
    const { invite, actions, history } = this.props
    actions.loadPartnershipAgreement(invite.id)
    history.push(`/builder/${invite.campaign_id}/partnership-agreement/${location}/${invite.id}`)
  }

  edit = () => {
    const { agreement } = this.props.invite
    this.nav(agreement.type === 'template' ? 'draft' : 'upload')
  }

  share = () => {
    this.nav('review')
    this.props.openDialog('shareAgreementDialog')
  }

  delete = () => {
    const { invite, openModal } = this.props
    openModal(invite)
  }

  sign = () => this.nav('sign')

  view = () => this.nav('completed')

  render() {
    const {
      invite: { invitee: brand, agreement },
      campaignStartDate
    } = this.props

    const isAgreementDeletable = moment().isBefore(campaignStartDate)

    let liStyles = {
      fontFamily: 'Larsseit-Medium',
      fontSize: '14px',
      lineHeight: '22px',
      display: 'inline-block',
      cursor: 'pointer',
      color: COLOURS.azure,
      marginTop: '10px',
      marginRight: '24px'
    }

    const statusMap = {
      created: {
        label: 'Created',
        actions: ['edit', 'delete']
      },
      drafted: {
        label: 'Drafted',
        actions: ['edit', 'share', 'delete']
      },
      shared: {
        label: 'Shared',
        actions: ['edit', 'delete']
      },
      partner_signed: {
        label: 'Partner Signed',
        actions: ['sign', 'delete']
      },
      completed: {
        label: 'Completed',
        actions: ['view', 'delete']
      }
    }

    const actionMap = {
      edit: 'Edit',
      share: 'Share',
      delete: 'Delete',
      sign: 'Sign Agreement',
      view: 'View Completed'
    }


    const actions = statusMap[agreement.status].actions.map(action => {
      if (action === 'delete' && !isAgreementDeletable) return null
      return (
        <li onClick={ this[action] } style={ liStyles } key={action}>{ actionMap[action] }</li>
      )
    })

    return (
      <Table.Tr styles={[{
        borderBottomWidth: '0px'
      }]}>
        <Table.LeftTd styles={[{
          width: '142px',
          paddingLeft: '15px',
          paddingRight: '15px'
        }]}>
          <div style={{
            display: 'table',
            minHeight: '73px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            <div style={{
              display: 'table-cell',
              verticalAlign: 'middle'
            }}>
              <div style={{
                height: '73px',
                lineHeight: '73px'
              }}>
                <Link to={ `/explore/brands/${brand.id}/details`}>
                  <img src={ brand.logo }
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%'
                  }}/>
                </Link>
              </div>
            </div>
          </div>
        </Table.LeftTd>
        <Table.LeftTd
          styles={[{
            textAlign: 'left',
            paddingTop: '15px',
            paddingRight:  '10px',
            paddingBottom:  '15px',
            paddingLeft: '20px'
          }]}>
          { brand.accountname }
        </Table.LeftTd>
        <Table.LeftTd>
          { statusMap[agreement.status].label }
        </Table.LeftTd>
        <Table.LeftTd>
          <ul style={{
            paddingLeft: 0
          }}>
            { actions }
          </ul>
        </Table.LeftTd>
      </Table.Tr>
    );
  }
}

export default withRouter(PartnershipAgreementRow)
