import React from 'react'
import PropTypes from 'prop-types'

import Field from 'visual-components/util/inputs/field'
import Validator from 'util/form-validator'
import UploadImage from 'components/util/editor-uploader'

import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

const validations = {
  ctaURL: [Validator.required],
}

class CtaFullImage extends React.Component {
  updateFullPageCtaImage = src => {
    store.dispatch({
      model: 'ctaModel',
      type: 'UPDATE_ATTR',
      data: {
        fullPageCTAImage: src
      }
    })
  }

  updateFullPageMobileCtaImage = src => {
    store.dispatch({
      model: 'ctaModel',
      type: 'UPDATE_ATTR',
      data: {
        fullPageMobileCTAImage: src
      }
    })
  }

  render() {
    const { dataModel: cta } = this.props

    return (
      <div style={{ display: 'inline' }}>
        <Field
          {...this.props}
          attrName="ctaUrl"
          label="Custom CTA Link"
          placeholder="http://www.companysite.com/promo"
          validations={validations.ctaURL}
        />

        <div
          style={{
            width: '174px',
            display: 'inline-block',
            marginRight: '12px',
            marginTop: '-8px',
          }}
        >
          <UploadImage
            action={`/partnership-cta-image-full/${cta.id}`}
            src={cta.fullPageCTAImage}
            success={this.updateFullPageCtaImage}
            name="upload-cta-image-full"
            acceptedFiles="image/jpeg,image/png"
          >
            <Button
              theme={buttonTheme}
              className={`${buttonTheme.greenButton} ${buttonTheme.noMargin}`}
              label="Upload Desktop Image"
              raised
            />
          </UploadImage>
        </div>

        <div
          style={{
            width: '164px',
            display: 'inline-block',
            marginRight: '12px',
            marginTop: '-8px',
          }}
        >
          <UploadImage
            action={`/partnership-cta-image-mobile-full/${cta.id}`}
            src={cta.fullPageMobileCTAImage}
            success={this.updateFullPageMobileCtaImage}
            name="upload-cta-image-full-mobile"
          >
            <Button
              theme={buttonTheme}
              className={`${buttonTheme.greenButton} ${buttonTheme.noMargin}`}
              label="Upload Mobile Image"
              raised
            />
          </UploadImage>
        </div>
      </div>
    )
  }
}

CtaFullImage.propTypes = {
  dataModel: PropTypes.object.isRequired,
}

export default CtaFullImage
