import { PureComponent } from 'react';
import moment from 'moment-timezone';
import d3 from 'd3';
import { Card } from 'react-toolbox/lib/card';
import { H3, H6, P, Small } from 'visual-components/util/texts';
import { string } from 'prop-types'


export default class WinnerInfoCard extends PureComponent {
  static propTypes = {
    name: string.isRequired,
    email: string.isRequired,
    zipcode: string.isRequired,
    sourceBrand: string.isRequired,
  }
  render() {
    const { name, email, zipcode, sourceBrand } = this.props;

    return (
      <div style={{
        width: '208px',
        height: '318px',
        position: 'relative',
      }}>
        <Card style={{
          width: '100%',
          height: '100%',
          paddingTop: '16px',
          paddingRight: '20px',
          paddingBottom: '16px',
          paddingLeft: '20px'
        }}>
          <H3 coral>
            <small>
              Winner Info
            </small>
          </H3>
          <Small style={{marginTop: '12px'}}>
            <em>
              Name
            </em>
          </Small>
          <Small multiline style={{ marginTop: '4px'}}>
            { name }
          </Small>
          <Small style={{marginTop: '16px'}}>
            <em>
              Email
            </em>
          </Small>
          <Small multiline style={{ marginTop: '4px'}}>
            { email }
          </Small>
          <Small style={{marginTop: '16px'}}>
            <em>
              Zip Code
            </em>
          </Small>
          <Small multiline style={{ marginTop: '4px'}}>
            { zipcode }
          </Small>
          <Small style={{marginTop: '16px'}}>
            <em>
              Source Brand
            </em>
          </Small>
          <Small multiline style={{ marginTop: '4px'}}>
            { sourceBrand }
          </Small>
        </Card>
      </div>
    )
  }
}
