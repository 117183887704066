import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Spinner from 'util/components/spinner'

import DedicatedEmailOpportunityCard from 'visual-components/brandProfile/opportunities/dedicated-email-opportunity-card'
import PrizingPartnerOpportunityCard from 'visual-components/brandProfile/opportunities/prizing-partner-opportunity-card'
import InsertSwapOpportunityCard from 'visual-components/brandProfile/opportunities/insert-swap-opportunity-card'
import ContentSwapOpportunityCard from 'visual-components/brandProfile/opportunities/content-swap-opportunity-card'
import CustomOpportunityCard from 'visual-components/brandProfile/opportunities/custom-opportunity-card'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import CreateOpportunityModal from 'container-components/opportunities/create-opportunity-modal'
import ShareOpportunityModal from 'visual-components/brandProfile/opportunities/share-opportunity-modal'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import * as OpportunityActions from 'actions/opportunities-actions'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import OpportunityCreatedDialog from './opportunity-created-dialog'

const mapStateToProps = ({
  currentBrand,
  createOpportunityModal,
}) => ({ currentBrand, createOpportunityModal })

const mapDispatchToProps = dispatch => ({
  opportunityActions: bindActionCreators(OpportunityActions, dispatch),
})

class CurrentOpportunities extends Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    createOpportunityModal: PropTypes.object.isRequired,
    opportunityActions: PropTypes.object.isRequired,
  }

  state = {
    editModalOpen: false,
    shareModalOpen: false,
    selectedOpportunity: null,
    filterValues: {
      search: '',
      sort: 'createdAt',
      asc: false,
    },
  }

  componentWillMount() {
    const { opportunityActions } = this.props
    const { filterValues } = this.state

    clearTimeout(this.timer)

    opportunityActions.filterOpportunities(filterValues)
  }

  updateOpportunity = opportunity => {
    const { opportunityActions } = this.props

    opportunityActions.save(opportunity)
    this.closeOpportunitiesModal()
  }

  deleteOpportunity = opportunity => () => {
    const { opportunityActions } = this.props

    ConfirmationDispatcher.check({
      header: 'Delete Opportunity',
      copy: 'Are you sure want to delete this Opportunity? This action cannot be undone.',
      confirmText: 'Delete',
      action: () => opportunityActions.deleteOpportunity(opportunity),
    })
  }

  shareOpportunity = opportunity => () => {
    const { shareModalOpen } = this.state

    this.setState({
      shareModalOpen: !shareModalOpen,
      selectedOpportunity: opportunity,
    })
  }

  openOpportunitiesModal = opportunity => () => {
    const { opportunityActions } = this.props

    if (opportunity) {
      opportunityActions.edit(opportunity)
    } else {
      opportunityActions.clear()
    }

    this.setState({
      editModalOpen: opportunity ? true : false,
      selectedOpportunity: opportunity || null,
    })
  }

  closeOpportunitiesModal = () => {
    this.setState({
      editModalOpen: false,
      shareModalOpen: false,
    })
  }

  searchOpportunities = value => {
    const { filterValues } = this.state

    clearTimeout(this.timer)

    this.setState({
      filterValues: {
        ...filterValues,
        search: value,
      },
    })

    this.timer = setTimeout(() => {
      const { opportunityActions } = this.props

      opportunityActions.filterOpportunities(this.state.filterValues)
    }, 500)
  }

  searchKeyUp = value => {
    if (value === 13) {
      const { opportunityActions } = this.props
      const { filterValues } = this.state

      clearTimeout(this.timer)

      opportunityActions.filterOpportunities(filterValues)
    }
  }

  sortOpportunities = (x, value) => {
    const { opportunityActions } = this.props
    const { filterValues } = this.state

    const { search, sort, asc } = filterValues
    this.setState({
      filterValues: {
        search,
        sort: value,
        asc: sort === value ? !asc : asc,
      },
    }, () => {
      opportunityActions.filterOpportunities(this.state.filterValues)
    })
  }

  createOpportunity = () => {
    const { opportunityActions } = this.props

    opportunityActions.openCreateOpportunityModal()

    EventTrackingDispatcher.track('appPartnerships_partnership_createPartnership_createPartnershipPostOpportunity')
  }

  render() {
    const {
      opportunityActions,
      currentBrand,
      createOpportunityModal,
    } = this.props

    const {
      editModalOpen,
      selectedOpportunity,
      filterValues,
      shareModalOpen,
    } = this.state

    const sortByOptions = [
      {
        value: 'createdAt',
        label: 'Date Created',
      },
      {
        value: 'type.raw',
        label: 'Opportunity Type',
      },
    ]

    const OpportunityComponents = {
      dedicated_email_buy: DedicatedEmailOpportunityCard,
      dedicated_email_sell: DedicatedEmailOpportunityCard,
      prizing_partner: PrizingPartnerOpportunityCard,
      product_shipment_insert: InsertSwapOpportunityCard,
      content_swap: ContentSwapOpportunityCard,
      custom: CustomOpportunityCard,
    }

    const opportunities = currentBrand.opportunities.map(opportunity => {
      const OpportunityComponent = OpportunityComponents[opportunity.type] || CustomOpportunityCard

      return (
        <OpportunityComponent
          key={opportunity.id}
          opportunity={opportunity}
          brand={currentBrand}
          editOpportunity={this.openOpportunitiesModal(opportunity)}
          deleteOpportunity={this.deleteOpportunity(opportunity)}
          shareOpportunity={this.shareOpportunity(opportunity)}
          isBrand
          showActions
        />
      )
    })

    let errorText

    if (!opportunities.length) {
      errorText = 'Looking for partners on any marketing initiatives? Try creating a Partnership Opportunity!'
    }

    return (
      <div>
        { currentBrand.loadingOpportunity && <Spinner /> }

        <PartnershipsContainer
          title="My Opportunities"
          action={this.createOpportunity}
          actionLabel="Create Opportunity"
          sort={this.sortOpportunities}
          sortByOptions={sortByOptions}
          filter={this.filterCampaigns}
          filterLabel="Campaigns I've Hosted"
          filterValues={filterValues}
          errorText={errorText}
          content={opportunities}
        />

        <CreateOpportunityModal
          editing
          active={editModalOpen}
          closeEditingModal={this.closeOpportunitiesModal}
          opportunityActions={opportunityActions}
          persistEditOpportunity={this.updateOpportunity}
        />

        <OpportunityCreatedDialog
          active={!currentBrand.loadingOpportunity && createOpportunityModal.confirmationDialog}
          handleToggle={opportunityActions.closeOpportunityConfirmationDialog}
          opportunity={currentBrand.newOpportunity}
        />

        <ShareOpportunityModal
          active={shareModalOpen}
          opportunity={{ ...selectedOpportunity, brand: currentBrand }}
          handleToggle={this.closeOpportunitiesModal}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentOpportunities)
