import React from 'react'
import { P, Micro } from 'visual-components/util/texts'
import Label from 'visual-components/util/inputs/label'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import Spinner from 'util/components/spinner'
import COLOURS from 'util/colours'
import AppDispatcher from 'dispatchers/app-dispatcher'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'

class CardForm extends React.Component {
  state = {
    loading: false,
    missingName: false,
    missingZip: false,
    nameOnCard: undefined,
    zipCode: undefined,
  }

  componentDidMount() {
    const { updateCard: cardObj } = this.props

    const style = {
      base: {
        // Add your base input styles here. For example:
        fontWeight: 100,
        fontSize: '14px',
        lineHeight: '14px',
        color: COLOURS.ink,
        '::placeholder': {
          color: COLOURS.cloudGrey,
        },
      },
      invalid: {
        color: COLOURS.alertRed,
      },
    }

    if (cardObj) {
      this.setState({
        zipCode: cardObj.address_zip,
        nameOnCard: cardObj.name,
      })
      return
    }

    if (elements._elements.length) {
      elements._elements.pop()
    }

    // Create an instance of the card Element
    window.card = window.card || elements.create('card', {
      hidePostalCode: true,
      style,
    })

    // Add an instance of the card Element into the `card-element` <div>
    card.mount('#card-element')

    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    const myPostalCodeField = document.querySelector('input[name=address-zip]')
    myPostalCodeField.addEventListener('change', event => {
      if (event.target.value.length === 5) {
        AppDispatcher.http({
          url: `/check-ny-zipcode/${event.target.value}`,
          success: data => {
            store.dispatch({
              type: 'UPDATE_ATTR',
              model: 'currentBrand',
              data,
            })
          },
          error: () => {},
        })
      } else {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentBrand',
          data: {
            isNY: false,
          },
        })
      }
    })

    this.card = card
  }

  nameFocus = () => {
    this.setState({
      missingName: false,
    })
  }

  zipFocus = () => {
    this.setState({
      missingZip: false,
    })
  }

  stripeTokenHandler = (token, errorCallback) => {
    const { cardAdded } = this.props
    AppDispatcher.http({
      url: '/add-card-token',
      method: 'POST',
      data: {
        token,
      },
      success: () => {
        if (cardAdded) {
          cardAdded()
        }
        AppDispatcher.load({
          model: 'customer',
          endpoint: '/customer',
        })
      },
      error: errorCallback,
    })
  }

  updateBilling = () => {
    const { updateCard, cardAdded } = this.props
    const { nameOnCard, zipCode } = this.state

    if (!nameOnCard) return this.setState({ missingName: true })
    if (!zipCode) return this.setState({ missingZip: true })

    this.setState({ loading: true })

    const extraDetails = { name: nameOnCard, address_zip: zipCode }

    if (updateCard) {
      CurrentBrandDispatcher.editSource(updateCard.id, {
        ...extraDetails,
      }, () => {
        this.setState({ loading: false })
        cardAdded()
      })
      return null
    }

    const that = this
    stripe.createToken(this.card, extraDetails).then(result => {
      if (result.error) {
        that.setState({ loading: false })

        // Inform the user if there was an error
        const errorElement = document.getElementById('card-errors')
        errorElement.textContent = result.error.message
      } else {
        // Send the token to your server
        that.stripeTokenHandler(result.token, error => {
          that.setState({ loading: false })

          const errorElement = document.getElementById('card-errors')
          errorElement.textContent = error.responseText
        })
      }
    })
  }

  inputOnChange = name => event => {
    const info = {}
    info[name] = event.target.value
    this.setState(info)
  }

  render() {
    const { toggleModal, addCard, updateCard } = this.props
    const { loading, nameOnCard, zipCode, missingName, missingZip } = this.state

    const canSave = !!nameOnCard && !!zipCode

    const inputStyle = {
      width: '100%',
      height: '40px',
      border: `1px solid ${COLOURS.silver}`,
      borderRadius: '2px',
      paddingTop: '13px',
      paddingRight: '12px',
      paddingBottom: '13px',
      paddingLeft: '12px',
      fontSize: '14px',
      lineHeight: '14px',
      color: COLOURS.ink,
    }

    return (
      <div>
        { loading ? <Spinner /> : null }

        <form id="payment-form" style={{ paddingBottom: '24px' }}>
          <Label label="Credit Card Details" />

          <div style={{ width: '100%', marginBottom: '32px' }}>
            { updateCard ? (
              <P>
                {`**** **** **** ${updateCard.last4}`}
              </P>
            ) : (
              <div>
                <div id="card-element" style={inputStyle} />
                <div
                  id="card-errors"
                  style={{
                    fontSize: '9px',
                    lineHeight: '9px',
                    color: COLOURS.alertRed,
                    paddingTop: '5px',
                    minHeight: '14px',
                  }}
                />
              </div>
            )}
          </div>

          <div style={{ width: '456px', marginRight: '8px', display: 'inline-block' }}>
            <Label label="Name on Card*" />
            <input
              name="cardholder-name"
              className="card-input"
              onFocus={this.nameFocus}
              style={inputStyle}
              value={nameOnCard}
              onChange={e => this.inputOnChange('nameOnCard')(e)}
            />
            <div style={{ visibility: !missingName ? 'hidden' : 'visible', paddingTop: '5px' }}>
              <Micro alertRed>
                Name Required
              </Micro>
            </div>
          </div>
          <div style={{ width: '144px', display: 'inline-block' }}>
            <Label label="Zip Code*" />
            <input
              name="address-zip"
              className="card-input"
              onFocus={this.zipFocus}
              style={inputStyle}
              value={zipCode}
              onChange={e => this.inputOnChange('zipCode')(e)}
            />
            <div style={{ visibility: !missingZip ? 'hidden' : 'visible', paddingTop: '5px' }}>
              <Micro alertRed>
                Zipcode Required
              </Micro>
            </div>
          </div>
        </form>

        <ModalButtonGroup
          canSave={canSave}
          confirm={this.updateBilling}
          confirmText={addCard ? 'Add Card' : 'Update Info'}
          cancel={toggleModal}
        />
      </div>
    )
  }
}

export default CardForm
