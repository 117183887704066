import React from 'react'
import { string, object } from 'prop-types'
import _ from 'lodash'
import { Checkbox } from 'react-toolbox'
import Field from 'visual-components/util/inputs/field'
import IntegrationTheme from 'css/themes/integrations.css'
import CheckboxTheme from 'css/themes/Checkbox.css'

class MergeTagsTableRow extends React.Component {
  static propTypes = {
    attr: string.isRequired,
    sample: string.isRequired,
    label: string.isRequired,
    espIntegration: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  warn = _.debounce(() => {
    toastr.error('Your integration has been deactivated. Once all changes are complete please reactivate.', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
  }, 500)

  onChange = (attr, value) => {
    const {
      attr: key,
      espIntegration,
      utilityFunctions: { updateMergeTags },
    } = this.props

    const { activated, mergeTags } = espIntegration
    const hasKey = mergeTags && mergeTags.find(tag => tag.attribute === key)

    if (activated && (attr === 'include' || (attr === 'value' && hasKey && hasKey.include))) {
      this.warn()
    }

    updateMergeTags(key, attr, value)
  }

  render() {
    const {
      attr,
      label,
      sample,
      espIntegration,
    } = this.props

    const item = (espIntegration.mergeTags && espIntegration.mergeTags.find(tag =>
      tag.attribute === attr)) || { value: '' }

    return (
      <tr className={item.include ? IntegrationTheme.selectedRow : ''}>
        <td className={IntegrationTheme.firstTd}>
          { label }
        </td>
        <td className={IntegrationTheme.secondTd}>
          { sample }
        </td>
        <td className={`${IntegrationTheme.thirdTd} ${IntegrationTheme.smallPadding}`}>
          <Field
            attrName="value"
            dataModel={item}
            formModel={{}}
            updateAttr={this.onChange}
            placeholder="Enter Value"
            className={IntegrationTheme.noErrors}
          />
        </td>
        <td className={IntegrationTheme.checkboxTd}>
          <Checkbox
            label={null}
            checked={item.include}
            onChange={e => this.onChange('include', e)}
            disabled={!item.value && !item.include}
            theme={CheckboxTheme}
          />
        </td>
      </tr>
    )
  }
}

export default MergeTagsTableRow
