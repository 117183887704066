import moment from 'moment-timezone'

const initialState = {
  loading: false,
  showReviewModal: false,
  showPendingReviewsPopup: false,

  pendingCampaignReviews: [],
  pendingMarketDealReviews: [],

  brandToReview: null,
  rating: null,
  reviews: [],
}

export default function brandRatingsReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_REVIEWS':
      return { ...state, loading: true }

    case 'GET_AVAILABLE_REVIEWS_SUCCESS': {
      const { payload } = action
      const { ratings, model } = payload

      const modelKey = model === 'marketplace' ? 'pendingMarketDealReviews' : 'pendingCampaignReviews'

      const lastlogin = payload && payload.length && payload[0].last_login
      const isFirstLogin = moment(lastlogin).isAfter(moment().subtract(1, 'minutes'))

      let reviews = isFirstLogin || Math.random() * 100 < 20 ? ratings : []

      // Group reviews by campaign
      if (model === 'campaign') {
        const campaigns = {}

        reviews.forEach(review => {
          const {
            campaign_name,
            campaign_id,
            model_end_date,
            review_created_date,
          } = review
          const campaign_key = JSON.stringify({ campaign_name, campaign_id, model_end_date, type: 'campaign', review_created_date })

          if (!(campaign_key in campaigns)) campaigns[campaign_key] = []

          campaigns[campaign_key].push(review)
        })

        reviews = Object.entries(campaigns)
      }

      return {
        ...state,
        [modelKey]: reviews,
        loading: false,
        brandToReview: null,
      }
    }

    case 'OPEN_MARKET_REVIEW_MODAL': {
      const { rating = 0, ...brandToReview } = action.payload

      return {
        ...state,
        showReviewModal: true,
        brandToReview,
        rating,
      }
    }

    case 'CLOSE_MARKET_REVIEW_MODAL': {
      return {
        ...state,
        showReviewModal: false,
        rating: null,
      }
    }

    case 'GET_AVAILABLE_REVIEWS_FAILURE':
    case 'SUBMIT_REVIEW_FAILURE':
    case 'REJECT_REVIEW_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response
          ? `${action.payload.response.status}: ${action.payload.response.data}`
          : 'Error',
      }

    default:
      return state
  }
}
