import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import injectJSS from 'react-jss'
import { Link } from 'react-toolbox/lib/link'
import cn from 'classnames'

import COLOURS from 'util/colours'
import AppBarTheme from '../../../../css/themes/app-bar-theme.css'

const themes = {
  AppBarTheme,
}

const styles = {
  badge: {
    position: 'absolute',
    height: 12,
    width: 12,
    borderRadius: '100%',
    top: -3,
    right: -3,
  },
  redDot: {
    background: COLOURS.alertRed,
    fontFamily: 'Larsseit-Bold',
  },
  loading: {
    height: 12,
    width: 12,
    animation: 'rotate 1s linear infinite',
  },
  top: {
    top: -8,
  },
  '@keyframes rotate': {
    to: {
      transform: 'rotate(-360deg)',
    },
  },
}

class NavIconLink extends PureComponent {
  render() {
    const {
      imgSize,
      href,
      target,
      onClick,
      unreadCount,
      menuOpen,
      clickedSrc,
      src: iconSrc,
      iconMarginTop,

      loading,
      loadingSrc,
      classes: css,
    } = this.props

    const src = menuOpen ? clickedSrc : iconSrc

    return (
      <Link
        href={href}
        className={themes.AppBarTheme.blueCircle}
        onClick={onClick}
        target={target}
      >
        {
          loading && loadingSrc
            ? (
              <div className={cn(css.badge, css.top)}>
                <img className={css.loading} src={loadingSrc} />
              </div>
            ) : unreadCount
              ? <div className={cn(css.badge, { [css.redDot]: unreadCount })} />
              : null
        }
        <div>
          <img
            src={src}
            style={{
              height: imgSize || '20px',
              width: imgSize || '20px',
              marginTop: iconMarginTop || '-3px',
            }}
          />
        </div>
      </Link>
    )
  }
}

NavIconLink.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  clickedSrc: PropTypes.string,
  iconMarginTop: PropTypes.string,
  imgSize: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

NavIconLink.defaultProps = {
  loading: false,
  loadingSrc: null,
}

export default injectJSS(styles)(NavIconLink)
