import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { object, func } from 'prop-types'
import BillingCardForm from 'visual-components/brandSettings/card-form'
import SelectField from 'visual-components/util/inputs/select-field'
import { Micro } from 'visual-components/util/texts'

const mapState = ({ customer, card }) => ({ customer, card })

class ChooseCard extends React.Component {
  static propTypes = {
    card: object.isRequired,
    customer: object.isRequired,
    resetStoreError: func, // Used to reset errors in independent stores (ie: SocialActions)
  }

  static defaultProps = {
    resetStoreError: null,
  }

  componentDidMount() {
    const { customer } = this.props

    if (customer && !customer.loading) {
      const source = customer.sources && customer.sources.data
        && customer.sources.data.find(source => source.id === customer.default_source)

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'card',
        data: {
          adding: !source,
        },
      })

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'customer',
        data: {
          source,
          selectedSource: (source && source.id) || 'card',
        },
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { customer } = this.props
    const { customer: prevCustomer } = prevProps

    if (prevCustomer && prevCustomer.loading && customer && !customer.loading) {
      const source = customer.sources.data.find(source => source.id === customer.default_source)

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'card',
        data: {
          adding: !source,
        },
      })

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'customer',
        data: {
          source,
          selectedSource: (source && source.id) || 'card',
        },
      })
    }
  }

  selectSource = (attr, value) => {
    const { resetStoreError } = this.props
    if (value === 'card') {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'card',
        data: {
          adding: true,
        },
      })
      return
    }

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'card',
      data: {
        adding: false,
        error: null,
      },
    })

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'customer',
      data: {
        selectedSource: value,
        error: null,
      },
    })

    if (resetStoreError) resetStoreError()
  }

  render() {
    const {
      card,
      customer,
    } = this.props

    const {
      default_source,
      creditCards = [],
      bankAccounts = [],
    } = customer

    const cardMap = {
      'American Express': 'american-express',
      Discover: 'discover',
      MasterCard: 'mastercard',
      Visa: 'visa',
    }

    const sources = creditCards.concat(bankAccounts.filter(account => account.status === 'verified'))
    sources.forEach((s, idx) => {
      if (s.id === default_source) {
        sources.splice(idx, 1)
        sources.unshift(s)
      }
    })

    const options = sources.map(s => {
      const isCard = s.object === 'card'
      const label = isCard
        ? `**** **** **** ${s.last4}`
        : `${s.bank_name} - *****${s.last4}`

      return {
        img: cardMap[s.brand] || null,
        value: s.id,
        label,
      }
    }).concat([{
      value: 'card',
      label: 'Add Card',
    }])

    return (
      <div>
        {default_source && (
          <div style={{ marginBottom: '24px' }}>
            <SelectField
              label="Choose Payment Method"
              attrName="selectedSource"
              className="selectSourceDropdown"
              options={options}
              dataModel={customer}
              formModel={{}}
              updateAttr={this.selectSource}
              cardTemplate
              allowBlank={false}
            />
            { customer.error ? (
              <div style={{ marginTop: '-24px' }}>
                <Micro alertRed>
                  { customer.error }
                </Micro>
              </div>
            ) : null }
          </div>
        )}
        { card.adding
          ? <BillingCardForm />
          : null
        }
        <div style={{ marginTop: card.adding ? '-18px' : '-12px', marginBottom: '32px' }}>
          <Link to="/profile/brand/payment-methods?achModal=open">
            Add a Bank Account (Verification Required)?
          </Link>
        </div>
      </div>
    )
  }
}

export default connect(mapState)(ChooseCard)
