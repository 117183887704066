import React from 'react'
import propTypes from 'prop-types'
import _ from 'lodash'
import SecondaryButton from 'util/components/buttons/secondary-button'
import { Button } from 'react-toolbox/lib/button'
import Text from 'util/components/text'
import injectSheet from 'react-jss'
import Checkbox from 'util/components/form-elements/checkbox'
import Validator from 'util/form-validator'
import Field from 'util/components/field'
import EditorWithValidations from 'util/components/editor-with-validations'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import cn from 'classnames'

import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/dialogBase.css'

import COLOURS from 'util/colours'

const themes = {
  Button: ButtonTheme,
  disabledButton: ButtonTheme,
  Dialog: DialogTheme,
}

const button = {
  width: '62px',
  minWidth: '62px',
  height: '36px',
  minHeight: '36px',
  fontFamily: 'Larsseit-Medium !important',
  fontSize: '14px',
  border: '0px',
}

const secondaryButton = {
  ...button,
  position: 'relative',
  minHeight: 0,
  height: 0,
  display: 'inherit',
  bottom: '28px',
  left: '499px',
  width: 'inherit',
}

const editLegalText = {
  display: 'block',
  color: COLOURS.alertRed,
  marginBottom: '20px',
  marginTop: '16px',
  fontFamily: 'Larsseit-Medium',
  fontSize: '12px',
  width: '608px',
  fontWeight: 500,
}

const agreeEditTransitions = {
  editButton: {
    fontFamily: 'Larsseit-Medium',
    fontSize: '14px',
    marginRight: '4px',
  },
  cancelButton: {
    color: COLOURS.azure,
  },
  main: {
    marginBottom: '32px',
    width: '608px',
  },
  optInLanguage: {
    border: `solid 1px ${COLOURS.silver}`,
    color: '#2c3541',
    fontSize: '14px',
    fontFamily: 'Larsseit-Light',
    lineHeight: '1.57',
    letterSpacing: 'normal',
    marginBottom: '12px',
    padding: '10px 12px',
  },
  optInLanguageDisabled: {
    cursor: 'not-allowed',
    backgroundColor: COLOURS.cream,
    color: COLOURS.ink,
    opacity: 0.6,
  },
}

class AgreeToEdit extends React.Component {
  static propTypes = {
    agreeToLiability: propTypes.func.isRequired,
    classes: propTypes.object.isRequired,
    dataModel: propTypes.object.isRequired,
    formModel: propTypes.object.isRequired,
    liabilityAttr: propTypes.string.isRequired,
    attrName: propTypes.string.isRequired,
    label: propTypes.string.isRequired,
    mergeData: propTypes.object,
    type: propTypes.any,
    validations: propTypes.bool,
    updateAttr: propTypes.func.isRequired,
    hideEditButton: propTypes.bool,
    fieldType: propTypes.string,
    setEditingLegalLanguage: propTypes.func.isRequired,
  }

  static defaultProps = {
    mergeData: null,
    type: null,
    validations: false,
    hideEditButton: false,
    fieldType: null,
  }

  state = {
    showTerms: false,
    showEditMode: false,
    agreeTerms: false,
  }

  onCancelClick = discard => {
    const { setEditingLegalLanguage, liabilityAttr } = this.props
    if (discard) {
      ConfirmationDispatcher.check({
        header: 'You Have Unsaved Changes',
        copy:
          'Do you want to discard these changes?',
        confirmText: 'Discard',
        cancelText: 'Go Back',
        action: () => {
          ConfirmationDispatcher.closeModal()
          setEditingLegalLanguage(liabilityAttr, false)
          this.setState({
            showEditMode: false,
            showTerms: false,
            agreeTerms: false,
          })
        },
      })
    } else {
      setEditingLegalLanguage(liabilityAttr, false)
      this.setState({
        showTerms: false,
        showEditMode: false,
        agreeTerms: false,
      })
    }
  }

  cancel = () => {
    const {
      updateAttr,
      attrName,
      liabilityAttr,
      setEditingLegalLanguage,
    } = this.props
    ConfirmationDispatcher.check({
      header: 'Restore Default',
      copy:
        'Do you want to discard these changes?',
      confirmText: 'Restore',
      cancelText: 'Go Back',
      action: () => {
        setEditingLegalLanguage(liabilityAttr, false)
        updateAttr(attrName, 'I agree to the Official Rules.')
        ConfirmationDispatcher.closeModal()
        this.setState({
          showEditMode: false,
        })
      },
    })
  }

  onEditClick = () => {
    const {
      dataModel,
      liabilityAttr,
      agreeToLiability,
      setEditingLegalLanguage,
    } = this.props

    setEditingLegalLanguage(liabilityAttr)

    const { agreeTerms } = this.state
    if (dataModel[liabilityAttr]) {
      this.setState({
        showEditMode: true,
      })
    } else if (agreeTerms) {
      this.setState({
        showEditMode: true,
      })
      agreeToLiability(liabilityAttr)
    } else {
      this.setState({
        showTerms: true,
      })
    }
  }

  toggleCheckBox = () => {
    const { agreeTerms } = this.state
    this.setState({
      agreeTerms: !agreeTerms,
    })
  }

  render() {
    const {
      updateAttr,
      dataModel,
      formModel,
      attrName,
      mergeData,
      validations,
      classes: css,
      hideEditButton,
      fieldType,
    } = this.props
    let displayValue = _.unescape(
      dataModel[attrName]
        .replace(/<\/?p>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&rsquo;/g, "'")
    )

    const mergeFields = mergeData ? Object.keys(mergeData) : null

    if (mergeData) {
      _.each(mergeData, (value, key) => {
        const mergeField = new RegExp(`{{${key}}}`, 'g')
        displayValue = displayValue.replace(mergeField, value)
      })
    }

    const { showEditMode, showTerms, agreeTerms } = this.state

    return (
      <div className={css.main}>
        {(showEditMode && !hideEditButton) ? (
          <div>
            {validations ? (
              <EditorWithValidations
                updateAttr={updateAttr}
                attrName={attrName}
                dataModel={dataModel}
                onCancelAction={this.onCancelClick}
                mergeFields={mergeFields}
                mergeData={mergeData}
                displayValue={displayValue}
              />
            ) : (
              <div>
                <Field
                  validations={[Validator.required]}
                  manualSave
                  updateAttr={updateAttr}
                  dataModel={dataModel}
                  formModel={formModel}
                  attrName={attrName}
                  onCancelAction={this.onCancelClick}
                  onCancelDiscard
                  type="textarea"
                  customButtonStyles={button}
                  customDivStyle={{ marginBottom: 0 }}
                />
                <SecondaryButton styles={secondaryButton} onClick={this.cancel} blueButton>
                  Restore Default
                </SecondaryButton>
              </div>
            )}
          </div>
        ) : (
          <div>
            {validations ? (
              <div
                className={cn(css.optInLanguage, css.optInLanguageDisabled)}
              >
                {`"${displayValue}"`}
              </div>
            ) : (
              <Field
                dataModel={dataModel}
                formModel={formModel}
                attrName={attrName}
                type={fieldType}
                disabled
                customInputStyle={{
                  overflow: 'auto',
                  resize: 'vertical',
                  padding: '10px 12px',
                  lineHeight: 1.57,
                  backgroundColor: COLOURS.cream,
                  color: COLOURS.ink,
                  opacity: 0.6,
                }}
                customDivStyle={{ display: 'inline' }}
              />
            )}
            {showTerms ? (
              <div>
                <Text.dojoFinePrintBold styles={editLegalText}>
                  We&apos;ve provided a sample text based on best practices. Before modifying this
                  text, we suggest consulting legal counsel. Under no circumstances will Innovation
                  Brands, Corp. and DojoMojo be held liable for any loss or damage whatsoever
                  arising from or in connection with the use of this website.
                </Text.dojoFinePrintBold>
                <Checkbox toggleChecked={this.toggleCheckBox} value={agreeTerms} {...this.props} />
              </div>
            ) : null}
            {!hideEditButton && (
              <Button
                label="Edit"
                className={`${themes.Button.whiteButton} ${css.editButton}`}
                theme={themes.Button}
                raised
                onClick={this.onEditClick}
                disabled={showTerms && !agreeTerms}
              />
            )}
            {showTerms ? (
              <Button
                theme={themes.Button}
                label="Cancel"
                onClick={this.onCancelClick}
                neutral={false}
                className={css.cancelButton}
              />
            ) : null}
          </div>
        )}
      </div>
    )
  }
}

export default injectSheet(agreeEditTransitions)(AgreeToEdit)
