import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H3 } from 'visual-components/util/texts';

import PartnerList from '../../visual-components/calendar/PartnerList';
import CampaignsListTheme from 'css/themes/calendar/campaignsList.css';

import { bindActionCreators } from 'redux'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'

import { connect } from 'react-redux'

const mapStateToProps = function (state) {
  return {
    campaignTasks: state.campaignTasks,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
}


class PartnerListContainer extends Component {
  render() {

    const {
      campaignTasks: {
        acceptedInvites
      },
      campaignTaskActions: {
        toggleShowCampaignPartner
      }
    } = this.props

    return(
      <div>
        <div className={ CampaignsListTheme.partnerListContainer }>
          <div className={ CampaignsListTheme.headerTitle }>
            <H3 coral>
              <small>
                Partners
              </small>
            </H3>
          </div>
          <div className={ CampaignsListTheme.listContainer }>
            <PartnerList partners={acceptedInvites} toggleShowCampaignPartner={toggleShowCampaignPartner} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerListContainer)
