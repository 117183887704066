import React from 'react'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function DiscardChangesModal() {
  const { cancelChanges, showModal, modals } = React.useContext(MobileMarketingContext)

  return (
    <SimpleInfoModal
      title="You Have Unsaved Changes"
      active={modals.discardChanges}
    >
      <H4 multiline>Do you want to discard these changes?</H4>
      <ModalButtonGroup
        canSave
        confirm={cancelChanges}
        cancel={() => showModal('discardChanges', false)}
        confirmText="Discard"
        cancelText="Go Back"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(DiscardChangesModal)
