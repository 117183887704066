import AppDispatcher from 'dispatchers/app-dispatcher'
import { isTrialingWithOffers } from 'util/brand-stripe-belt-helper'
import { getClaim } from 'actions/brand-claim-actions'

const PricingGateDispatcher = {
  ...AppDispatcher,
  check: (feature, callback, args = null) => {
    const {
      currentBrand: brand,
      conversations: { conversations },
      customer,
    } = store.getState()

    // If brand is on a trial no need to check
    const isTrial = isTrialingWithOffers(brand, customer)
    if (isTrial) return callback()

    const featureMap = {
      'sweepstakes.partnership.create': () => {
        if (!getClaim('sweepstakes.partnership.create')) {
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'pricingModal',
            data: {
              expand: true,
              feature,
              title: 'Want to Create Your Own Giveaways?',
              message: 'Upgrade to design and manage your own fully-branded giveaways - no developer required.',
              eventTrack: 'hostUpgrade',
            },
          })
        } else {
          callback()
        }
      },
      'sweepstakes.partnershipApplication.create': () => {
        if (!getClaim('sweepstakes.partnershipApplication.create')) {
          store.dispatch({
            type: 'UPDATE_ATTR',
            data: {
              selectedCampaign: null,
              selectedCampaignBasicDetails: null,
              message: null,
            },
            model: 'campaignSearch',
          })
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'pricingModal',
            data: {
              expand: true,
              feature,
              title: customer.trialAvailable
                ? 'Start Growing Your Audience'
                : 'Looking to Get in on the Fun?',
              message: customer.trialAvailable
                ? 'Premium and Essential users can apply to join open campaigns. It’s free to try for 30 days - no credit card required.'
                : 'Reach your target audience quickly by applying to join this and other upcoming campaigns in the DojoMojo network.',
            },
          })
        } else {
          callback()
        }
      },
      'messaging.message.create': targetBrandId => {
        const isExistingConversation = conversations.find(conversation =>
          conversation.conversation_brand_id === targetBrandId)

        if (isExistingConversation) {
          return callback()
        }

        if (
          getClaim('messaging.message.create')
          && getClaim('messaging.message.limit')
          && brand.conversations.length >= getClaim('messaging.message.limit')
        ) {
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'pricingModal',
            data: {
              expand: true,
              feature,
              title: "Whoops! You're Out of Messages",
              message: 'Still want to connect with this brand? Take a look at our upgrade options to keep sending messages.',
              eventTrack: 'messageUpgrade',
            },
          })
        } else {
          return callback()
        }
      },
    }

    const featureFn = featureMap[feature]
    if (featureFn) {
      featureFn(args)
    } else if (!getClaim(feature)) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'pricingModal',
        data: {
          expand: true,
          feature,
        },
      })
    } else {
      return callback()
    }
  },
}

export default PricingGateDispatcher
