const initialState = {
  loading: false,
  selectedCampaign: null,
  purchaseCount: null,
  pendingInvoiceItems: null,
  showUpsellModal: false,
  showPurchaseModal: false,
  purchaseSuccess: false,
  purchaseFailure: false,
  error: null,
}

function socialActionsReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_PENDING_INVOICE_ITEMS_SUCCESS':
      return {
        ...state,
        pendingInvoiceItems: action.payload,
      }

    case 'PURCHASE_SOCIAL_ACTIONS':
      return {
        ...state,
        loading: true,
        purchaseSuccess: false,
        purchaseFailure: false,
      }

    case 'PURCHASE_SOCIAL_ACTIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        purchaseSuccess: true,
        purchaseCount: action.quantity,
        selectedCampaign: action.payload.selectedCampaign,
        purchaseFailure: false,
      }

    case 'PURCHASE_SOCIAL_ACTIONS_FAILURE':
      return {
        ...state,
        loading: false,
        purchaseSuccess: false,
        purchaseFailure: true,
        error: action.payload.response ? action.payload.response.data : 'Error',
      }

    case 'SOCIAL_ACTIONS_RESET_ERROR':
      return { ...state, error: null, purchaseFailure: false }

    case 'SHOW_UPSELL_MODAL':
      return {
        ...state,
        showUpsellModal: true,
      }

    case 'SHOW_PURCHASE_MODAL':
      return {
        ...state,
        showPurchaseModal: true,
      }

    case 'HIDE_UPSELL_MODAL':
    case 'HIDE_PURCHASE_MODAL':
    case 'RESET_PURCHASE_STORE':
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default socialActionsReducer
