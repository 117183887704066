import React from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'
import { Small } from 'visual-components/util/texts'
import TaskListTheme from 'css/themes/TaskList.css'

class TaskExpandable extends React.Component {
  state = {
    isOpened: true,
  }

  toggleCardOpen = () => {
    const { isOpened } = this.state

    this.setState({
      isOpened: !isOpened,
    })
  }

  render() {
    const {
      title,
      children,
      backgroundColor,
    } = this.props

    const { isOpened } = this.state

    return (
      <div style={{ width: '238px' }}>
        <div
          onClick={this.toggleCardOpen}
          style={{
            backgroundColor,
            cursor: 'pointer',
            color: 'white',
            height: '24px',
          }}
        >
          <div>
            <div className={TaskListTheme.campaignHeader}>
              <Small overflowEllipsis white>
                <em>{ title }</em>
              </Small>
            </div>
            <div style={{ float: 'right', paddingRight: '13px', paddingTop: '3px' }}>
              {
                isOpened
                  ? <img src="/images/icons/white-expand-arrow.svg" style={{ transform: 'rotate(180deg)' }} />
                  : <img src="/images/icons/white-expand-arrow.svg" />
              }
            </div>
          </div>
        </div>

        <Collapse isOpened={!!isOpened}>
          <div style={{ paddingLeft: '2px', paddingTop: '4px' }}>
            { children }
          </div>
        </Collapse>
      </div>
    )
  }
}

TaskExpandable.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default TaskExpandable
