const initialState = {
  files: [],
  fileToUpload: [],
  loading: null,
  uploading: null,
}

export default function contentSamplesReducer(state = initialState, action) {
  switch (action.type) {
    case 'BRAND_CONTENT_SET_FILE_UPLOAD':
      return { ...state, fileToUpload: action.data }

    case 'BRAND_CONTENT_RESET_FILE_UPLOAD':
      return { ...state, fileToUpload: [] }

    case 'BRAND_CONTENT_SAMPLE_LOAD':
      return { ...state, loading: true }

    case 'BRAND_CONTENT_SAMPLE_LOAD_SUCCESS':
      return {
        ...state,
        loading: null,
        files: action.payload,
      }

    case 'BRAND_CONTENT_SAMPLE_LOAD_FAILURE':
      return { ...state }

    case 'BRAND_CONTENT_SAMPLE_UPLOAD':
      return { ...state, uploading: true }

    case 'BRAND_CONTENT_SAMPLE_UPLOAD_SUCCESS':
      toastr.success('Media Sample successfully uploaded.', null, { positionClass: 'toast-bottom-center' })
      return {
        ...state,
        files: [...state.files, action.payload],
        fileToUpload: [],
        uploading: null,
      }

    case 'BRAND_CONTENT_SAMPLE_UPLOAD_FAILURE':
      toastr.error('Media Sample upload unsuccessful.', null, { positionClass: 'toast-bottom-center' })
      return { ...state, loading: null, uploading: null }

    case 'BRAND_CONTENT_SAMPLE_DELETE':
      return { ...state }

    case 'BRAND_CONTENT_SAMPLE_DELETE_SUCCESS':
      toastr.success('Media Sample deleted.', null, { positionClass: 'toast-bottom-center' })
      return {
        ...state,
        files: state.files.filter(item => item.id !== action.payload),
      }

    case 'BRAND_CONTENT_SAMPLE_DELETE_FAILURE':
      toastr.error('Media Sample delete unsuccessful.', null, { positionClass: 'toast-bottom-center' })
      return { ...state }

    case 'BRAND_CONTENT_SAMPLE_UPDATE':
      return { ...state }

    case 'BRAND_CONTENT_SAMPLE_UPDATE_SUCCESS':
      toastr.success('Media Sample updated.', null, { positionClass: 'toast-bottom-center' })
      return {
        ...state,
        files: action.payload,
      }

    case 'BRAND_CONTENT_SAMPLE_UPDATE_FAILURE':
      toastr.error('Media Sample update unsuccessful.', null, { positionClass: 'toast-bottom-center' })
      return { ...state }

    default:
      return state
  }
}
