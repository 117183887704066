
export const setUploadFiles = files => ({
  type: 'SET_UPLOAD_FILES',
  data: files,
})

export const exitCollaborate = () => ({
  type: 'EXIT_DEAL_ASSETS',
})

export const cancelUpload = () => ({ type: 'CLEAR_ASSET_UPLOAD' })

export const toggleIncludeFile = file => ({
  type: 'TOGGLE_INCLUDE_UPLOAD_FILE',
  data: file,
})

export const updateDealAssets = data => ({ type: 'UPDATE_ASSET_ATTR', data })

export const uploadFiles = (dealId, files) => ({
  http: {
    url: `/deals/attachment/${dealId}`,
    method: 'POST',
    files,
  },
  types: [
    'UPLOADING_DEAL_ASSETS',
    'UPLOADING_DEAL_ASSETS_SUCCESS',
    'UPLOADING_DEAL_ASSETS_FAILURE',
  ],
})

export const loadDealAssets = dealId => ({
  data: {
    dealId,
  },
  http: {
    url: `/deals/attachment/${dealId}`,
    method: 'GET',
  },
  types: [
    'LOAD_DEAL_ASSETS',
    'LOAD_DEAL_ASSETS_SUCCESS',
    'LOAD_DEAL_ASSETS_FAILURE',
  ],
})

export const loadDealAssetComments = (dealId, dealAttachmentId) => {
  return {
    http: {
      url: `/deals/attachment-comment/${dealId}/${dealAttachmentId}`,
      method: 'GET',
    },
    types: [
      'LOAD_DEAL_ASSET_COMMENTS',
      'LOAD_DEAL_ASSET_COMMENTS_SUCCESS',
      'LOAD_DEAL_ASSET_COMMENTS_FAILURE',
    ],
    dealAttachmentId,
  }
}

export const createDealAssetComment = comment => ({
  http: {
    url: `/deals/attachment-comment/${comment.deal_id}/${comment.deal_attachment_id}`,
    method: 'POST',
    data: comment,
  },
  types: [
    'ADD_DEAL_ASSET_COMMENT',
    'ADD_DEAL_ASSET_COMMENT_SUCCESS',
    'ADD_DEAL_ASSET_COMMENT_FAILURE',
  ],
  parentId: comment.parent_id,
})

export const updateDealAssetComment = comment => ({
  http: {
    url: `/deals/attachment-comment/${comment.deal_id}/${comment.deal_attachment_id}/${comment.id}`,
    method: 'PUT',
    data: comment,
  },
  types: [
    'ADD_DEAL_ASSET_COMMENT',
    'ADD_DEAL_ASSET_COMMENT_SUCCESS',
    'ADD_DEAL_ASSET_COMMENT_FAILURE',
  ],
})

export const deleteDealAssetComment = ({
  deal_id, deal_attachment_id, id,
}) => ({
  http: {
    url: `/deals/attachment-comment/${deal_id}/${deal_attachment_id}/${id}`,
    method: 'DELETE',
  },
  types: [
    'DELETE_DEAL_ASSET_COMMENT',
    'DELETE_DEAL_ASSET_COMMENT_SUCCESS',
    'DELETE_DEAL_ASSET_COMMENT_FAILURE',
  ],
  commentId: id,
})

export const clearAssetComments = () => ({
  type: 'LOAD_DEAL_ASSET_COMMENTS_SUCCESS',
  data: [],
})

export const switchAssetTab = tab => ({ type: 'SWITCH_ASSET_TAB', tab })

export const readDealAssetComments = (dealId, dealAttachmentId, dealAttachmentUnreadCount) => ({
  http: {
    url: `/deal/read-attachment-comment/${dealId}/${dealAttachmentId}`,
    method: 'POST',
  },
  types: ['MARK_DEAL_ITEMS_READ', 'NOOP', 'NOOP'],
  data: {
    dealId, dealAttachmentId, dealAttachmentUnreadCount, type: 'attachmentComments',
  },
})
