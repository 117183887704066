import React from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';

class DojoCol extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    let colStyles = _.extend(this.props.style || {}, { paddingTop: '0', paddingRight: '0', paddingBottom: '0', paddingLeft: '0' });

    return(
      <Col style={ colStyles } { ...this.props }>
        { this.props.children }
      </Col>
    )
  }
}

DojoCol.propTypes = {
  style: PropTypes.object
}

export default DojoCol;
