import React from 'react';
import { string, func, number } from 'prop-types';
import stickyTabTheme from 'css/themes/homePage/stickyTabTheme.css';

class StickyTab extends React.Component {
  static propTypes = {
    activeTab: string,
    title: string.isRequired,
    icon: string.isRequired,
    onclick: func,
    href: string.isRequired,
    scrollTop: number,
    idx: number
  }

  render() {
    let {
      title,
      icon,
      onclick,
      href,
      scrollTop,
      idx,
      activeTab
    } = this.props;

    let thisIsActiveTab = activeTab === title;

    return(
      <div className={ `${stickyTabTheme.tab} ${thisIsActiveTab ? stickyTabTheme.active : stickyTabTheme.inactive}` }>
        <div onClick={ e => onclick(title, href) }
          className={ stickyTabTheme.contents }
        >
          <img alt={title} src={ `/images/icons/${icon}.svg` } className={ `${stickyTabTheme.icon} ${stickyTabTheme[icon]}` } />
          <p className={ stickyTabTheme.text }>
            { title }
          </p>
        </div>
      </div>
    )
  }
}

export default StickyTab;
