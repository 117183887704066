import { rangeDefaults, checkboxDefaults } from './helpers'

const campaignSearch = {
  page: 1,
  size: 16,
  total: 0,
  searchValue: '',
  sortBy: {
    value: 'startDate',
    asc: true,
    options: [
      {
        value: 'startDate',
        label: 'Start Date',
      },
      {
        value: 'hostBrandName',
        label: 'Host Brand (name)',
      },
      {
        value: 'numberOfPartners',
        label: '# of Partners',
      },
      {
        value: 'estimatedEntries',
        label: 'Est. Entries',
      },
      {
        value: 'minimumListSize',
        label: 'Min. List Size',
      },
    ],
    defaultDirections: {
      startDate: true,
      hostBrandName: true,
    },
  },
  filtersExpanded: {
    startDateFilterValue: true,
    estEntriesFilterValue: true,
    minListSizeFilterValue: true,
    tags: false,
    numberOfPartnersFilterValue: false,
    minEntriesRequired: false,
    prizeValue: false,
  },
  startDateFilterValue: rangeDefaults({
    term: 'startDate',
    minDefault: 'now',
    transform: field => `now+${field}d`,
  }),
  estEntriesFilterValue: rangeDefaults({ term: 'estimatedEntries' }),
  minListSizeFilterValue: rangeDefaults({ term: 'minimumListSize' }),
  tags: checkboxDefaults({ term: 'industry.raw' }),
  numberOfPartnersFilterValue: rangeDefaults({ term: 'numberOfPartners' }),
  minEntriesRequired: rangeDefaults({ term: 'minimumEntriesRequired' }),
  prizeValue: rangeDefaults({ term: 'prizingValue' }),
}

export default campaignSearch
