import React from 'react'
import _ from 'lodash'

import SelectField from 'visual-components/util/inputs/select-field';

import { Form, Select } from 'visual-components/util/form'

class OpportunitySelectType extends React.Component {
  render() {
    const { opportunity } = this.props

    const optionMap = {
      '': {
        title: 'Select an option',
        disabled: true
      },
      content_swap: {
        title: 'Content Swap',
        placeholder: 'For a compelling post, you may want to share your reader demographics, the average clicks your articles get, how much time readers spend on your content and your email list size.'
      },
      product_shipment_insert: {
        title: 'Insert Swap',
        placeholder: 'Potential partners may want to know about how many boxes you ship a month, more about your customer demographics and the minimum number of inserts you’d be looking to swap.'
      },
      dedicated_email_buy: {
        title: 'Dedicated Buy',
        placeholder: 'Let partners know what you’d pay for CPC / CPM, how many clicks you’d budget for, and what list size you’d like to target.'
      },
      dedicated_email_sell: {
        title: 'Dedicated Sale',
        placeholder: 'Making your offer complete by sharing your CPC / CPM asking price, how many clicks they should budget for, and any details about your list size and its demographics.'
      },
      prizing_partner: {
        title: 'Prizing Partner',
        placeholder: 'Provide some details about what sort of prizing you’re looking for (travel/product/cash/multiple) and the retail/cash value you estimate would be required.'
      },
    }

    const options = _.map(optionMap, (opt, key) => {
      return {
        value: key,
        label: opt.title,
        disabled: opt.disabled
      }
    })

    return (
      <Select
        required
        label="I’m looking for a partner for a..."
        name="type"
        options={options}
        defaultSelected="default"
      />
    )
  }
}

export default OpportunitySelectType
