import React from 'react';
import SplashTheme from 'css/themes/splash.css'

import SmallSplash from './small-splash';
import BigSplash from './big-splash';

class Splash extends React.Component {
  render() {
    return(
      <div>
        <div className={ SplashTheme.hiddenUnder910 }>
          <BigSplash />
        </div>
        <div className={ SplashTheme.hiddenOver910 }>
          <SmallSplash />
        </div>
      </div>
    )  
  }
};

export default Splash;
