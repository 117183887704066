import React from 'react'
import { string } from 'prop-types'
import { connect } from 'react-redux'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import AssetItem from 'visual-components/assets/asset-item'
import UploadImage from 'components/util/editor-uploader'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { H3, H6 } from 'visual-components/util/texts'

import ButtonTheme from 'css/themes/Buttons.css'

import CampaignDispatcher from 'dispatchers/campaign-dispatcher'

const mapStateToProps = ({ currentCampaign, currentBrand, profile }) =>
  ({ currentCampaign, currentBrand, profile })

class Assets extends React.Component {
  static contextTypes = {
    impersonating: string,
  }

  componentDidMount() {
    CampaignDispatcher.loadAssets()
  }

  downloadAll = () => {
    const { currentCampaign: { id }, currentBrand: { id: brandId } } = this.props
    const { impersonating } = this.context
    window.open(`/campaigns/download-assets/${brandId}/${id}${impersonating ? `?impersonating=${impersonating}` : ''}`)
  }

  downloadAsset = url => {
    window.open(url)
  }

  uploadAsset = asset => {
    CampaignDispatcher.loadAssets()
    toastr.remove()
    toastr.success('Asset Uploaded!', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
  }

  deleteAsset = id => {
    CampaignDispatcher.deleteAsset(id)
    toastr.success('Asset Deleted!', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
  }

  render() {
    const { currentCampaign: { id, invites, assets = [] }, profile } = this.props

    const assetItems = assets.map((asset, i) => {
      const isUploader = profile.id === asset.user_id
      const brandInvite = invites.find(invite => invite.invitee_id === asset.brand_id )
      const brandName = brandInvite && brandInvite.invitee && brandInvite.invitee.accountname
      if (!brandName) {
        return null
      }

      return (
        <AssetItem asset={ asset }
          brandName={ brandName }
          isUploader={ isUploader }
          downloadAsset={ this.downloadAsset }
          deleteAsset={ this.deleteAsset }
          key={ i }
        />
      )
    })

    return (
      <div>
        <Row>
          <Col style={{
            width: '54px',
            marginRight: '996px',
            marginTop: '6px'
          }}>
            <H3 coral>
              <small>
                Assets
              </small>
            </H3>
          </Col>
          <Col style={{
            width: '103px'
          }}>
            <div style={{
              float: 'right'
            }}>
              <UploadImage
                action={ `/campaigns/assets/${id}` }
                src={ 'campaign-asset' }
                success={ this.uploadAsset }
                name="upload-logo-image"
                maxFilesize={ 25 }
                uploadAsset
              >
                <Button label={ 'Upload Asset' }
                  className={ `${ButtonTheme.blueButton} ${ButtonTheme.shortButton}` }
                  theme={ ButtonTheme }
                  primary
                  raised
                />
              </UploadImage>
            </div>
          </Col>
        </Row>
        <Card style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)' }}>
          <Row style={{
            padding: '11px 20px'
          }}>
            <Col style={{
              width: '302px',
              marginTop: '5px'
            }}>
              <H6>
                Brand
              </H6>
            </Col>
            <Col style={{
              width: '415px',
              marginTop: '5px'
            }}>
              <H6>
                File Name
              </H6>
            </Col>
            <Col style={{
              width: '120px',
              marginTop: '5px'
            }}>
              <H6>
                Size
              </H6>
            </Col>
            <Col style={{
              width: '149px',
              marginTop: '5px'
            }}>
              <H6>
                Type
              </H6>
            </Col>
            <Col style={{
              width: '127px'
            }}>
              <div style={{
                float: 'right'
              }}>
                <Button onClick={ this.downloadAll }
                  label={ 'Download All' }
                  className={ `${ButtonTheme.whiteButton} ${ButtonTheme.shortButton}` }
                  theme={ ButtonTheme }
                  disabled={ !assetItems.length }
                  primary
                  raised
                />
              </div>
            </Col>
          </Row>
          {
            assetItems.length ?
            assetItems
            : null
          }
        </Card>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Assets)
