import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { Small } from 'visual-components/util/texts'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import Dropdown from 'react-toolbox/lib/dropdown'
import ButtonTheme from 'css/themes/Buttons.css'
import InputTheme from 'css/themes/Input.css'
import DropdownTheme from 'css/themes/Dropdown.css'
import COLOURS from 'util/colours'
import ContextButton from 'visual-components/util/buttons/context-button';

import * as PartnerActions from 'actions/campaign-partner-actions'
import PartnerConfirmationDialog from 'components/campaigns/builder/partners/partner-confirmation-dialog'
import { Form, Input, Select } from 'visual-components/util/form'

const mapStateToProps = state => ({
  announcement: state.announcement,
  currentCampaign: state.currentCampaign,
})

const mapDispatchToProps = dispatch => ({
  partnerActions: bindActionCreators(
    PartnerActions, dispatch
  ),
  dispatch,
})

let options = [
  'New Announcement Category',
  'Campaign Launch',
  'Promotion Reminder',
  'Campaign Debrief',
  'Winner Information',
  'Prizing Reminder',
  'General Update',
  'Other',
]

options = options.map(option => ({
  value: option,
  label: option,
}))

options[0].value = ''

class CreateAnnouncement extends React.Component {
  state = {
    activeDialog: null,
  }

  updateAttr = (data) => {
    const {
      dispatch,
    } = this.props

    dispatch({
      type: 'UPDATE_ATTR',
      data,
      model: 'announcement',
    })
  }

  openDialog = type => {
    this.setState({
      activeDialog: type,
    })
  }

  closeDialog = () => {
    this.setState({
      activeDialog: null,
    })
  }

  sendAnnouncement = () => {
    const {
      currentCampaign: {
        id,
      },
      currentBrand,
      partnerActions,
      announcement: {
        other,
        type,
        message,
      },
      dispatch,
    } = this.props

    if (!message) return
    const announcementType = type === 'Other' ? other : type
    partnerActions.sendAnnouncement(id, announcementType, message)
    this.openDialog('announcementDialog')

    dispatch({
      type: 'CLEAR',
      model: 'announcement'
    })

    store.dispatch({
      model: 'currentCampaign',
      type: 'ADD_ANNOUNCEMENT',
      data: {
        type: announcementType,
        message,
        date: new Date(),
        count: [currentBrand.id]
      }
    })
  }

  render() {
    const {
      currentCampaign,
      acceptedInvites,
      announcement: {
        type,
        other,
      },
    } = this.props

    const {
      activeDialog,
    } = this.state


    return (
      <Form
        modelName="announcement"
        updateModel={this.updateAttr}
        handleSubmit={this.sendAnnouncement}
        mergeFieldsImmediately={true}
      >
        <Card style={{
          width: '736px',
          marginLeft: '208px',
          marginBottom: '16px',
          overflow: 'visible',
        }}>
          <div
            style={{
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
            <Select
              name="type"
              options={options}
              required
            />

              { type === 'Other' &&
                <Input
                  name="other"
                  placeholder="Custom Category"
                  required
                />
              }
          </div>
          <Input
            required
            name="message"
            placeholder="What would you like to communicate with your partners?"
            multiline
            className={`${InputTheme.textarea} ${InputTheme.textareaNoBorder}`}
          />
          <Row style={{
            height: '56px',
            padding: '16px 20px',
            borderTop: `1px solid ${COLOURS.silver}`
          }}>
            <Col xs={12} className={ 'text-right' }>
              <div style={{
                display: 'inline-block',
                marginRight: '8px'
              }}>
                <Small>
                  { `${acceptedInvites.length} partner${acceptedInvites.length === 1 ? '' : 's'} will be notified`}
                </Small>
              </div>
              <div style={{
                display: 'inline-block'
              }}>
                <ContextButton
                  label="Send Announcement"
                  className={`${ButtonTheme.blueButton} ${ButtonTheme.shortButton}` }
                  theme={ ButtonTheme }
                  primary
                  raised
                />
              </div>
            </Col>
          </Row>
        </Card>
        <PartnerConfirmationDialog
          type={activeDialog}
          currentCampaign={currentCampaign}
          closeDialog={this.closeDialog}
        />
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnnouncement)
