import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Stars from 'visual-components/util/stars'
import {
  H6,
  Small,
  Smaller,
  P,
} from 'visual-components/util/texts'
import ReviewsTheme from 'css/themes/ratings-reviews.css'

const Space = ({ of = 8 }) => <div style={{ marginBottom: `${of}px` }} />

const ReviewCardContent = props => {
  const {
    review,
  } = props

  const {
    rating_brand_accountname,
    rated_brand_accountname,
    rating_brand_role,
    model_id,
    model_type,
    model_name,
    rating,
    comment,
    updated_at,
    isPosted,
    belongsToCampaign,
  } = review

  const date = moment(updated_at).format('MMM DD')
  const isCampaign = model_type === 'campaign'

  return (
    <div>
      <H6>
        {
          `${isPosted ? `Review For ${rated_brand_accountname}` : rating_brand_accountname}`
        }
      </H6>
      <Space of={8} />
      <Smaller>
        {rating_brand_role}
        {
          isCampaign
            ? (
              <React.Fragment>
                &nbsp;-&nbsp;
                <Link to={belongsToCampaign ? `/partnerships/campaign/${model_id}/quickview` : `/explore/campaigns/${model_id}/details`}>
                  {
                    isPosted
                      ? model_name
                      : 'View Campaign'
                  }
                </Link>
              </React.Fragment>
            ) : null
        }
      </Smaller>
      <Space of={24} />
      <div className={ReviewsTheme.flex}>
        <Stars
          className={ReviewsTheme.cstmRatingStars}
          value={rating}
          style={{ marginLeft: 0, marginTop: '-4px' }}
        />
        <Small>{ isCampaign ? 'Campaigns' : 'Media Market' }</Small>
      </div>
      <Space of={12} />
      <P>{comment}</P>
      <Space of={16} />
      <Small cloudGrey>{date}</Small>
    </div>
  )
}

ReviewCardContent.propTypes = {
  review: PropTypes.object.isRequired,
}

export default ReviewCardContent
