import React from 'react'

const ThemelessDiv = props => {
  const {
    theme,
    tooltip,
    tooltipDelay,
    tooltipHideOnClick,
    ...rest
  } = props

  return (<div {...rest} />)
}


export default ThemelessDiv
