import React from 'react'
import { func, string } from 'prop-types'
import OverviewTheme from 'css/themes/Overview.css'
import NavItem from './NavItem'

const navs = [{
  label: 'Images',
  type: 'images',

}, {
  label: 'Files',
  type: 'files',
}, {
  label: 'URLs',
  type: 'links',
}]

class AssetsHeaderNav extends React.Component {
  static propTypes = {
    selected: string.isRequired,
    selectTabFunc: func.isRequired,
  }

  render() {
    const {
      selected,
      selectTabFunc,
    } = this.props

    return (
      <div className={OverviewTheme.navForAssets}>
        {navs.map(nav => (
          <NavItem
            key={nav.type}
            selected={selected === nav.type}
            selectTabFunc={selectTabFunc}
            {...nav}
          />
        ))
        }
      </div>
    )
  }
}

export default AssetsHeaderNav
