import React from 'react'
import { object } from 'prop-types'
import { Button } from 'react-toolbox/lib/button'
import { P, H3, H4 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import IntegrationTheme from 'css/themes/integrations.css'
import ButtonTheme from 'css/themes/Buttons.css'
import SelectTable from './select-table'
import TestResults from './integration-test-results'

class IntegrationSelect extends React.Component {
  static propTypes = {
    espIntegrations: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  state = {
    step: 0,
  }

  componentWillMount() {
    const {
      integration,
      utilityFunctions: { fetchLists },
    } = this.props

    const { step } = this.state

    const input = integration.selectInputs[step]

    if (input.attr === 'listId' && integration.lists) {
      fetchLists()
    }
  }

  renderActionButtonGroup = () => {
    const {
      integration: {
        connectInputs = [],
        selectInputs = [],
      },
      espIntegration,
      utilityFunctions: { test },
    } = this.props

    const inputs = connectInputs.concat(selectInputs)
    const valid = inputs.every(input => input.optional || espIntegration[input.attr])

    return (
      <div>
        { valid
          ? (
            <Button
              label="Test"
              onClick={() => test()}
              className={ButtonTheme.whiteButton}
              theme={ButtonTheme}
              raised
            />
          ) : null
        }
      </div>
    )
  }

  renderModalButtonGroup = (input, nextInput) => {
    const {
      integration,
      espIntegration,
      utilityFunctions: {
        fetchLists,
        activate,
        deactivate,
        goTo,
      },
    } = this.props

    const nextStep = integration.mergeTags ? 'merge-tags' : null

    let confirm = nextStep
      ? () => goTo(nextStep)
      : !espIntegration.activated
        ? activate
        : deactivate

    let confirmText = nextStep
      ? 'Next'
      : !espIntegration.activated
        ? 'Activate'
        : 'Deactivate'

    if (nextInput) {
      confirm = () => {
        this.setState(prevState => ({ step: prevState.step + 1 }))
        if (typeof nextInput.options === 'string') {
          fetchLists(nextInput.options)
        } else if (nextInput.attr === 'listId') {
          fetchLists()
        }
      }
      confirmText = 'Next'
    }

    return (
      <div className={IntegrationTheme.modalButtonGroup}>
        <ModalButtonGroup
          confirm={confirm}
          confirmText={confirmText}
          cancel={() => goTo('details')}
          cancelText="Back"
          canSave={input.optional || espIntegration[input.attr]}
          hideLine
        />
      </div>
    )
  }

  closeWarningBanner = () => {
    this.setState({
      warningBannerDismissed: true,
    })
  }

  render() {
    const {
      integration: { label, selectInputs, warningBanner },
      espIntegrations: { listsLoading, testLoading, testResults },
    } = this.props

    const { step, warningBannerDismissed } = this.state

    const input = selectInputs[step]
    const nextInput = selectInputs[step + 1]

    return (
      <div>
        <div className={IntegrationTheme.modalSubtitle}>
          <H3 coral>
            <small>{`${input.title || 'List Export'} ${input.optional ? '(Optional)' : ''}`}</small>
          </H3>
        </div>

        <div className={IntegrationTheme.modalPara}>
          <H4 multiline>
            {input.label || "Select what list you'd like to export to."}
          </H4>
        </div>

        {!warningBannerDismissed && warningBanner && (
          <div className={IntegrationTheme.warningBanner}>
            <img
              src="images/icons/close.svg"
              onClick={this.closeWarningBanner}
              alt="close-icon"
            />
            <H4><em>{warningBanner.header}</em></H4>
            <P>{warningBanner.copy}</P>
            <a href={warningBanner.linkUrl} target="_blank" rel="noopener noreferrer">{warningBanner.linkText}</a>
          </div>
        )}

        <div>
          { listsLoading
            ? <MaterialSpinner when={listsLoading} />
            : <SelectTable {...this.props} input={input} />
          }
        </div>

        <div>
          <TestResults testLoading={testLoading} testResults={testResults} />
        </div>

        { this.renderActionButtonGroup() }

        { this.renderModalButtonGroup(input, nextInput) }
      </div>
    )
  }
}

export default IntegrationSelect
