import styleHelper from 'v2/styles/style-helper'
import { getTemplate, compileTemplate } from './TemplateV2'

const template = getTemplate()
const themeColor = '#37317c'

template.submitButton.button = {
  borderRadius: 4,
}

template.formContainer = {
  form: {
    maxWidth: 800,
    padding: props => (props.desktopVersion ? 60 : 20),
    margin: '0 auto 0 auto',
    [styleHelper.max(720)]: {
      padding: '20px !important',
    },
  },
  bottomInfo: {
    padding: props => (props.desktopVersion ? '40px 60px 0 60px' : '40px 20px 0 20px'),
  },
}

export const templateStyles = compileTemplate(template)
export const defaultVals = {}
