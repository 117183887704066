const initialState = {
  rid: 'brandClaims',
  loading: false,
  error: null,
}

function brandClaimReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_BRAND_CLAIMS':
      return { ...state, loading: true }

    case 'LOAD_BRAND_CLAIMS_SUCCESS':
      return { ...state, ...action.payload, loading: false }

    case 'LOAD_BRAND_CLAIMS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response
          ? `${action.payload.response.status}: ${action.payload.response.data}`
          : 'Error',
      }

    default:
      return state
  }
}

export default brandClaimReducer
