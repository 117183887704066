import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as AssetActions from 'actions/asset-actions'
import { Small } from 'visual-components/util/texts'
import {
  object, func, bool, number,
} from 'prop-types'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import Theme from 'css/themes/media-market/collaborate-comment-theme.css'
import CommentInput from './util/comment-input'
import HideAnnotations from './util/hide-annotations'
import Comment from './util/comment'


const mapStateToProps = ({
  profile,
  dealAssets,
  currentBrand,
  deals: { deal: { unread_count } },
}) => ({
  profile,
  dealAssets,
  currentBrand,
  unreadCount: unread_count,
})

const mapDispatchToProps = dispatch => ({
  assetActions: bindActionCreators(AssetActions, dispatch),
})

class CommentModule extends Component {
  static propTypes = {
    dealId: number.isRequired,
    assetId: number.isRequired,
    selectedCommentId: object,
    setSelectedCommentId: func.isRequired,
    newComment: object,
    setNewCommentContent: func.isRequired,
    deleteNewComment: func.isRequired,
    deleteComment: func.isRequired,
    createComment: func.isRequired,
    saveNewComment: func.isRequired,
    saveReplyComment: func.isRequired,
    saveEditComment: func.isRequired,
    showAnnotations: bool.isRequired,
    toggleShowAnnotations: func.isRequired,
    unreadCount: object,
    scrollToComment: object,
    setScrolledToComment: func,
  }

  static defaultProps = {
    selectedCommentId: {},
    newComment: null,
    unreadCount: {},
    scrollToComment: null,
    setScrolledToComment: () => {},
  }

  state = {
    showReplies: true,
    disableMainCommentInput: false,
  }

  componentDidUpdate(prevProps) {
    const {
      newComment,
      dealAssets: { comments },
      assetActions: { readDealAssetComments },
      dealId,
      assetId,
      unreadCount,
    } = this.props

    // After comments loaded, send read receipt
    if (prevProps.dealAssets.comments !== comments && comments.length) {
      const unreadAssetCounts = unreadCount && unreadCount.attachmentComments
        ? unreadCount.attachmentComments
        : {}
      readDealAssetComments(dealId, assetId, unreadAssetCounts[assetId] || 0)
    }

    // If new annotation created, scroll to input box
    if (prevProps.newComment !== newComment) {
      this.commentContainerRef.scrollTop = this.commentContainerRef.scrollHeight
    }
  }

  toggleShowReplies = () => {
    this.setState(prevState => ({
      showReplies: !prevState.showReplies,
    }))
  }

  disableMainCommentInput = TorF => {
    this.setState({ disableMainCommentInput: !!TorF })
  }

  deleteComment = (dealId, dealAttachmentId, id) => {
    const { deleteComment } = this.props
    deleteComment(dealId, dealAttachmentId, id)
  }

  setNewCommentContent = event => {
    const { setNewCommentContent } = this.props
    setNewCommentContent(event.target.value)
  }

  onNewCommentFocus = () => {
    //create a new comment if it doesn't exist
    const { newComment, createComment } = this.props
    if (!newComment) {
      createComment()
    }
  }

  captureCommentContainer = el => (this.commentContainerRef = el)

  static renderNoComments() {
    return (
      <React.Fragment>
        <div className={Theme.commentIcon}>
          <img src="images/icons/comment-icon.png" />
        </div>

        <div className={Theme.infoText}>
          <Small multiline>
            Leave a note below or click anywhere on the content to add a comment.
          </Small>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {
      dealAssets: {
        comments,
        commentLoading,
        firstUnreadComment,
      },
      selectedCommentId,
      setSelectedCommentId,
      newComment,
      deleteNewComment,
      saveNewComment,
      saveReplyComment,
      saveEditComment,
      profile,
      showAnnotations,
      toggleShowAnnotations,
      scrollToComment,
      setScrolledToComment,
      currentBrand: {
        id: currentBrandId,
      },
    } = this.props
    const {
      disableMainCommentInput,
    } = this.state

    let annotationNumber = 1

    return (
      <div
        className={Theme.commentContainer}
        ref={this.captureCommentContainer}
      >
        {
          comments.find(comment => comment.is_annotation)
          && (
            <HideAnnotations
              toggleShowAnnotations={toggleShowAnnotations}
              showAnnotations={showAnnotations}
            />
          )
        }
        { !comments.length && !commentLoading.isLoading && CommentModule.renderNoComments() }
        {
          comments.map(comment => (
            <Comment
              {...comment}
              key={comment.id}
              firstUnreadComment={firstUnreadComment}
              saveComment={this.saveComment}
              selectedCommentId={selectedCommentId}
              setSelectedCommentId={setSelectedCommentId}
              deleteComment={this.deleteComment}
              annotationNumber={comment.is_annotation ? annotationNumber++ : null}
              saveReplyComment={saveReplyComment}
              saveEditComment={saveEditComment}
              isLastComment={!comment.replies || !comment.replies.length}
              currentBrandId={currentBrandId}
              profile={profile}
              disableMainCommentInput={this.disableMainCommentInput}
              loading={commentLoading.parentId === comment.id}
              scrollToComment={scrollToComment}
              setScrolledToComment={setScrolledToComment}
            />
          ))
        }
        <MaterialSpinner when={commentLoading.isLoading && commentLoading.parentId === null} />
        <CommentInput
          value={(newComment && newComment.content) || ''}
          newComment={newComment}
          onChange={this.setNewCommentContent}
          onFocus={this.onNewCommentFocus}
          hide={deleteNewComment}
          annotationNumber={newComment && newComment.is_annotation ? annotationNumber++ : null}
          topLevelCommentInput
          onSubmit={saveNewComment}
          disableMainCommentInput={disableMainCommentInput}
        />
      </div>
    )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentModule))
