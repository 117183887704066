import React, { Component } from 'react'
import {
  object, array, bool, string,
} from 'prop-types'
import ReactDOM from 'react-dom'
import Spinner from 'util/components/spinner'
import PrimaryButton from 'util/components/buttons/primary-button'

class UploadImage extends Component {
  static propTypes = {
    acceptVideo: bool,
    baseFormStyles: array,
  }

  static defaultProps = {
    acceptVideo: false,
    baseFormStyles: [{
      paddingTop: '0px',
      marginTop: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
    }],
  }

  static contextTypes = {
    brandId: string.isRequired,
    impersonating: string,
  }

  opts = {
    accept: function (file, done) {
      const reader = new FileReader()
      reader.onload = img => {
        const src = img.target.result
        this.options.context.setSrc(src, done)
      }
      reader.readAsDataURL(file)
    },
    maxFilesize: 10,
  }

  state = {
    loading: false,
  }

  componentDidMount() {
    const { opts } = this
    const { brandId, impersonating } = this.context
    const {
      action,
      acceptedFiles,
      acceptVideo,
      maxFilesize,
    } = this.props

    opts.headers = { brand: brandId }

    if (impersonating) {
      opts.headers.impersonating = impersonating
    }

    opts.success = this.success
    opts.error = this.error
    opts.processing = this.processing
    opts.url = action
    opts.context = this
    opts.acceptedFiles = acceptedFiles || null
    opts.maxFilesize = maxFilesize || (acceptVideo ? 100 : 10)
    this.dz = $(ReactDOM.findDOMNode(this)).dropzone(opts)
  }

  processing = () => {
    const { maxFilesize } = this.props

    if (maxFilesize) {
      toastr.success('Uploading file, this may take a few minutes.', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
    }

    this.setState({
      loading: true,
    })
  }

  success = (xhr, body) => {
    const { success } = this.props
    this.setState({
      loading: false,
    })

    this.dz = $(ReactDOM.findDOMNode(this)).dropzone(this.opts)

    success(body)
  }

  error = (xhr, body) => {
    const message = body.includes('File is too big')
      ? `Some of your files couldn't be uploaded because they exceed the maximum file size (${this.opts.maxFilesize}MB).`
      : body
    toastr.error(message, null, { timeOut: 5000 })
  }

  setSrc = (src, done) => {
    this.src = src
    done()
  }

  render() {
    let { label } = this.props
    const {
      src,
      action,
      children,
      styles = [],
      baseFormStyles,
      buttonStyles,
    } = this.props
    const { loading } = this.state

    if (loading) {
      return (
        <Spinner />
      )
    }

    const formStyles = { ...baseFormStyles[0], ...((styles && styles[0]) || {}) }

    if (!label) {
      if (src) {
        label = 'UPLOAD IMAGE'
      } else {
        label = 'CHANGE IMAGE'
      }
    }

    return (
      <div>
        <form
          style={formStyles}
          action={action}
          data-src={src}
          className="dropzone main-image dz-clickable"
        >
          <div
            className="dz-message"
            style={{
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            { children || (
              <PrimaryButton
                blueButton
                buttonStyles={buttonStyles}
              >
                { label }
              </PrimaryButton>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export default UploadImage
