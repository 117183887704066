import React from 'react';
import { string } from 'prop-types';
import theme from 'css/themes/homePage/whatMarketersSay.css';

class MarketerSaidSlide extends React.Component {
  static propTypes = {
    quote: string.isRequired,
    author: string.isRequired,
    whoDat: string.isRequired,
    linkTitle: string.isRequired,
    linkURL: string.isRequired,
    imgSrc: string
  }
  render() {
    const {
      quote,
      author,
      whoDat,
      linkTitle,
      linkURL,
      imgSrc
    } = this.props;

    return (
      <div className={ theme.marketerSaidSlide }>
        <p className={ theme.quote }>
          { quote }
        </p>
        <div className={ theme.saidWho }>
          <div className={ theme.right }>
            <p className={ theme.author }>
              { author }
            </p>
            <p className={ theme.whoDat }>
              { whoDat }
              <a href={linkURL} target="_blank">
                { ` ${linkTitle}` }
              </a>
            </p>
          </div>
          <div>
            <a href={linkURL} target="_blank">
              { imgSrc
                ? <img alt={linkTitle} src={ imgSrc } className={ theme.brandImg } />
                : null
              }
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default MarketerSaidSlide;
