import React from 'react'
import { object } from 'prop-types'
import IntegrationTheme from 'css/themes/integrations.css'
import SelectTableRow from './select-table-row'

class SelectTable extends React.Component {
  static propTypes = {
    input: object.isRequired,
    espIntegration: object.isRequired,
    espIntegrations: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  renderSelectTableRows = () => {
    const {
      input,
      espIntegration,
      utilityFunctions,
    } = this.props

    let options
    if (typeof input.options === 'string') {
      options = (espIntegration[input.options] && [{ value: '', label: 'None' }, ...espIntegration[input.options]])
        || []
    } else {
      options = input.options || espIntegration.lists || []
    }

    return options && options.map(option => (
      <SelectTableRow
        key={option.value}
        input={input}
        option={option}
        selected={espIntegration[input.attr] === option.value}
        utilityFunctions={utilityFunctions}
      />
    ))
  }

  render() {
    const {
      input,
      espIntegrations: { testResults },
    } = this.props

    return (
      <div className={IntegrationTheme.tableContainer}>
        <table className={`${IntegrationTheme.table} ${IntegrationTheme.selectTable}`}>
          <thead>
            <tr className={IntegrationTheme.noSideBorder}>
              <th className={IntegrationTheme.firstTd}>
                { input.subtitle || 'List Name' }
              </th>
              <th className={IntegrationTheme.checkboxTd}>
                Export?
              </th>
            </tr>
          </thead>
        </table>
        <div className={`${IntegrationTheme.tableScroll} ${Object.keys(testResults).length ? IntegrationTheme.shortTable : ''}`}>
          <table className={`${IntegrationTheme.table} ${IntegrationTheme.selectTable}`}>
            <tbody>
              {this.renderSelectTableRows()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default SelectTable
