
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import ClickAwayListener from 'util/components/click-away-listener'
import { Picker as EmojiPicker } from 'emoji-mart'

import WrapperTooltip from 'util/components/wrapper-tooltip'
import colors from 'util/colours'

import emojimart from './emoji-selector-styles'

import { SMSEditorContext } from '../SMSEditorContext'

const EmojiIcon = props => {
  const dPath = 'M2 0h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0z'
  const dPath2 = 'M11.992 4C7.576 4 4 7.584 4 12s3.576 8 7.992 8C16.416 20 20 16.416 20 12s-3.584-8-8.008-8zM12 18.4c-3.536 0-6.4-2.864-6.4-6.4 0-3.536 2.864-6.4 6.4-6.4 3.536 0 6.4 2.864 6.4 6.4 0 3.536-2.864 6.4-6.4 6.4zm2.8-7.2c.664 0 1.2-.536 1.2-1.2 0-.664-.536-1.2-1.2-1.2-.664 0-1.2.536-1.2 1.2 0 .664.536 1.2 1.2 1.2zm-5.6 0c.664 0 1.2-.536 1.2-1.2 0-.664-.536-1.2-1.2-1.2-.664 0-1.2.536-1.2 1.2 0 .664.536 1.2 1.2 1.2zm2.8 5.6c1.824 0 3.374-1.335 4-3.2H8c.626 1.865 2.176 3.2 4 3.2z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(-4)">
        <rect width="32" height="24" rx="2" />
        <g>
          <path d={dPath} transform="translate(4)" />
          <path fill="#475464" fillRule="nonzero" d={dPath2} transform="translate(4)" />
        </g>
      </g>
    </svg>
  )
}

const styles = {
  wrapper: {
    margin: '0 6px',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s linear',
    cursor: 'pointer',
    borderRadius: 2,
    zIndex: 20,
    backgroundColor: 'white',
    position: 'relative',
    '&:hover': {
      background: colors.silver,
    },
  },
  picker: {
    position: 'absolute',
    top: '100%',
    left: 'calc(100% - 24px)',
    height: ({ open }) => (open ? 'auto' : '0'),
    overflow: ({ open }) => (open ? 'auto' : 'hidden'),
    ...emojimart,
  },
  customTooltip: {
    marginTop: 8,
  },
}

function EmojiSelector(props) {
  const {
    actions: { actionsRef },
  } = React.useContext(SMSEditorContext)
  const { classes: css, setOpen } = props

  const onSelect = emoji => {
    if (actionsRef.insertText) actionsRef.insertText(emoji.native)

    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={css.wrapper}>
        <WrapperTooltip customClasses={css.customTooltip} delay={1} text="Emoji">
          <EmojiIcon onClick={() => setOpen(true)} />
        </WrapperTooltip>
        <div className={css.picker}>
          {/* TODO: Check why emoji styling is not working */}
          <EmojiPicker
            native
            onSelect={onSelect}
            color={colors.azure}
            // emojiTooltip
            perLine={8}
            title="Pick an emoji"
          />
        </div>
      </div>
    </ClickAwayListener>
  )
}

EmojiSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
}

const StyledEmojiSelector = injectCSS(styles)(EmojiSelector)

function EmojiSelectorParent() {
  const [open, setOpen] = useState(false)

  return (
    <StyledEmojiSelector open={open} setOpen={setOpen} />
  )
}

export default React.memo(EmojiSelectorParent)
