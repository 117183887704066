import React from 'react'
import { object, string } from 'prop-types'
import { Checkbox } from 'visual-components/util/form'
import { H3, Micro, P } from 'visual-components/util/texts'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import ChooseCard from 'visual-components/util/choose-card'
import ConfirmNote from './confirm-note'
import OrderSummaryDisplay from '../../payments/order-summary-display'

import UpgradePlanTheme from 'css/themes/payments/upgrade-plan-full-modal.css'
import checkboxTheme from 'css/themes/Checkbox.css'

class PaymentInformation extends React.Component {
  static propTypes = {
    confirmType: string.isRequired,
    deal: object.isRequired,
    proposal: object.isRequired,
  }

  state = {
    agreementChecked: false,
  }

  toggleAgreement = () => {
    const { agreementChecked } = this.state
    this.setState(prevState => ({ agreementChecked: !prevState.agreementChecked }))
  }

  render() {
    const {
      deal: {
        cost_type,
        max_value,
        deposit_percentage,
      },
      proposal,
      error,
      loading,
      confirmType,
      resetError,
    } = this.props

    const { agreementChecked } = this.state

    let cost
    const isProposal = proposal && Object.keys(proposal).length
    const isCPC = isProposal ? proposal.cost_type === 'CPC' : cost_type === 'CPC'
    if (isProposal) {
      cost = isCPC ? proposal.max_quantity * proposal.price
        : proposal.price * proposal.deposit_percentage / 100
    } else {
      cost = isCPC ? max_value : max_value * deposit_percentage / 100
    }

    const orderSummary = isCPC ? {
      dealType: 'cpc',
      label: 'Max Value',
      totalAmount: cost,
    } : {
      dealType: 'fixed',
      label: 'Deposit Value',
      totalAmount: cost,
    }

    return (
      <div style={{ display: 'flex' }}>
        <div id="payment-info" style={{ marginRight: '48px' }}>
          <div>
            <H3 coral>
              <small>Payment Information</small>
            </H3>
          </div>
          <div className={UpgradePlanTheme.choosePaymentMethod}>
            <ChooseCard resetStoreError={resetError} />
            <div
              style={{
                marginTop: '-16px',
                marginBottom: '16px',
              }}
            >
              { error && !loading
                && (
                  <Micro alertRed>
                    { error }
                  </Micro>
                )
              }
              <MaterialSpinner when={loading} />
            </div>
          </div>

          <ConfirmNote />

          <div className={UpgradePlanTheme.agreement__checkbox}>
            <Checkbox
              name="agreementChecked"
              label={(
                <P multiline>
                  <em>
                    {confirmType === 'accept' ? 'You will be charged today the ' : "If the seller accepts your offer, we'll charge you for the "}
                    {isCPC ? 'full' : 'deposit'}
                    {' amount shown on the right.'}
                    {isCPC ? " If the seller doesn't reach the minimum click threshold, you'll be refunded the difference." : ' The remaining balance will be charged 5 business following the close of the deal.'}
                  </em>
                </P>
              )}
              theme={checkboxTheme}
              required
            />
          </div>
        </div>

        <OrderSummaryDisplay purchaseDealSummary={orderSummary} />
      </div>
    )
  }
}


export default PaymentInformation
