import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-toolbox/lib/button'

import { P, H4, Small } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import { smsRelativeDate } from 'util/relativeDate'

import buttonTheme from 'css/themes/Buttons.css'
import { MobileMarketingContext } from './MobileMarketingContext'

function MobileMarketingPostEntryActions(props) {
  const {
    isHost,
    partnerSplitTrafficStatus,
    splitTrafficStatus,
    publishButtonRef,
    currentCampaign: { isSolo },
  } = props
  const {
    editMode,
    smsSend: { status = 'draft' },
    postEntry: { updated_at },
    smsBank,
    showModal,
    toggleEditMode,
    saveChanges,
    unsavedChanges,
  } = React.useContext(MobileMarketingContext)

  const isDraft = status !== 'published'

  const overrideSplitTrafficStatus = partnerSplitTrafficStatus || splitTrafficStatus

  const [RightCTALabel, RightCTAAction] = isDraft
    ? [
      'Publish',
      () =>
        showModal(
          !isHost && overrideSplitTrafficStatus === 'notInvited'
            ? 'requestTrafficReminder'
            : 'publish'
        ),
    ]
    : editMode
      ? ['Save', saveChanges]
      : ['Unpublish', () => showModal('unpublishWarning')]

  const [LeftCTALabel, LeftCTAAction] = isDraft
    ? ['Save', saveChanges]
    : [
      'Cancel',
      unsavedChanges
        ? () => showModal('discardChanges')
        : toggleEditMode,
    ]

  const paidMessages = smsBank.credits.count
  const freeMessages = smsBank.credits.freeCount
  const totalMessages = paidMessages + freeMessages
  const zeroSmsBalance = totalMessages < 1 && !smsBank.credits.autorefill

  function EditButton() {
    return (
      <Button onClick={toggleEditMode} className={buttonTheme.editButton}>
        Edit
        <img
          src="/images/icons/blue-edit.svg"
          alt="Edit"
        />
      </Button>
    )
  }

  function LeftButton() {
    return (
      <>
        <Button
          onClick={LeftCTAAction}
          className={
              isDraft ? buttonTheme.publishButton : buttonTheme.cancelButton
            }
        >
          {LeftCTALabel}
        </Button>
        {isDraft
            && Boolean(updated_at) && (
              <Small style={{ marginRight: 12, marginTop: 8 }}>
                {smsRelativeDate(updated_at)}
              </Small>
        )}
      </>
    )
  }

  function RightButton() {
    return (
      <Button
        disabled={isDraft && zeroSmsBalance}
        onClick={RightCTAAction}
        className={buttonTheme.publishButton}
      >
        {RightCTALabel}
      </Button>
    )
  }

  function renderActions() {
    return (
      <>
        <div style={{ textAlign: 'right' }} ref={publishButtonRef}>
          {
            editMode
              ? <LeftButton />
              : <EditButton />
          }
        </div>
        <div
          onClick={isDraft && zeroSmsBalance ? () => showModal('outOfMessages') : null}
        >
          <RightButton />
        </div>
      </>
    )
  }

  if (isSolo && isDraft) {
    return (
      <Row style={{ justifyContent: 'space-between' }}>
        <div style={{ marginTop: 22 }}>
          <div style={{ marginBottom: 8 }}>
            <H4>
              <i>
                SMS Welcome Message Required
              </i>
            </H4>
          </div>
          <P>
            Create your welcome text message and save it before publishing your Conversion Action.
          </P>
        </div>
        <div style={{ display: 'flex' }}>
          { renderActions() }
        </div>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'flex-end' }}>
      { renderActions() }
    </Row>
  )
}

MobileMarketingPostEntryActions.propTypes = {
  isHost: PropTypes.bool.isRequired,
  partnerSplitTrafficStatus: PropTypes.string.isRequired,
  splitTrafficStatus: PropTypes.string.isRequired,
  publishButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  currentCampaign: PropTypes.object.isRequired,
}

export default React.memo(MobileMarketingPostEntryActions)
