import React, { PureComponent } from 'react';
import homeTheme from 'css/themes/homePage/home.css';
import { Link } from 'react-router-dom';

export default class ReferralBanner extends PureComponent {
  render() {
    const {
      type,
      firstName,
      campaignName
    } = this.props;

    return (
      <a href="/app/register">
      <div className={ homeTheme.referralBanner }>
        <h3 className={ homeTheme.referralHeadline }>
          { campaignName ?
            `${firstName} invited you to join ${campaignName} on DojoMojo! Click here or sign up to accept your invitation.`
            :
            `${firstName} has given you $25 credit on DojoMojo! Click here or sign up to accept your invitation.`
          }
        </h3>
      </div>
      </a>
    )
  }
}
