
import axios from 'axios'
import requestHeaders from 'request-config'

// loads currentbrands reducer and updates intercom
function Intercom() {
  axios.get('/brands/intercom', { headers: requestHeaders() }).then(res => {
    const intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'ucxc40qm',
      ...res.data,
    }

    window.Intercom('boot', intercomSettings)
  })

  return null
}

export default Intercom
