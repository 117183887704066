import React from 'react'
import COLOURS from 'util/colours'

import CampaignBuilderSection from 'campaigns/builder/components/campaign-builder-section'

import HostingOption from 'campaigns/builder/page_hosting/hosting-option'
import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text'
import { H3, P } from 'visual-components/util/texts'
import DojoHost from 'campaigns/builder/page_hosting/dojo-host'
import SelfHost from 'campaigns/builder/page_hosting/self-host'
import PageHostingChoice from 'campaigns/builder/page_hosting/page-hosting-choice.js'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'

import Dialog from 'react-toolbox/lib/dialog'
import ModalTheme from 'css/themes/dialogs/modalBase.css'

import * as ReactRedux from 'react-redux'

const mapState = function (state) {
  return state
}

const ViewMap = {
  dojoHost: DojoHost,
  selfHost: SelfHost,
}

class PageHosting extends React.Component {
  constructor(props) {
    super(props)
    this.activate = this.activate.bind(this)
    this.deselectOption = this.deselectOption.bind(this)
    this.openModal = this.openModal.bind(this)
    this.openChoosing = this.openChoosing.bind(this)
    this.closeChoosing = this.closeChoosing.bind(this)
  }

  state = { choosing: false }

  componentDidMount() {
    document.title = 'Page Hosting - DojoMojo'
  }

  activate(type) {
    this.deselectOption()
    CampaignDispatcher.updateAttrPersist('hostingType', type)
    this.closeChoosing()
  }

  openModal(type) {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentCampaign',
      data: {
        hostingModalKey: type,
      },
    })
  }

  deselectOption() {
    CampaignDispatcher.updateAttr('hostingModalKey', null)
  }

  openChoosing() {
    this.setState({
      choosing: true,
    })
  }

  closeChoosing() {
    this.setState({
      choosing: false,
    })
  }

  render() {
    const { hostingModalKey } = this.props.currentCampaign
    const { hostingType } = this.props.currentCampaign
    const HostingOptionComponent = ViewMap[hostingModalKey]

    const paras = [
      <P multiline>Create a web address where entrants will view your landing page.</P>,
    ]

    return (
      <CampaignBuilderSection>
        <div className="row">
          {/* explain text */}
          <div className="col-sm-6">
            <TabTitleAndText headers={[<H3>Page Hosting</H3>]} paras={paras} />
          </div>

          {this.state.choosing ? (
            <div className="col-sm-6">
              <HostingOption
                color="green"
                title="Let DojoMojo Handle Hosting (Recommended)"
                body="Keep it simple and use DojoMojo.com as the hosting web domain."
                confirmText={
                  this.props.currentCampaign.hostingType === 'dojoHost'
                    ? 'Continue Hosting with DojoMojo'
                    : 'Switch Hosting to DojoMojo'
                }
                type="dojoHost"
                isConfirmButton
                selected={this.props.currentCampaign.hostingType === 'dojoHost'}
                selectOption={
                  this.props.currentCampaign.hostingType === 'dojoHost'
                    ? this.closeChoosing
                    : this.openModal
                }
              />
              {// hide branded option unless legacy
              this.props.currentCampaign.hostingType === 'dnsForward' ? (
                <HostingOption
                  disabled
                  color="yellow"
                  title="branded"
                  body="I’d like the web address to use my brand’s name. (DEPRECATED)"
                  type="dnsForward"
                  selected={this.props.currentCampaign.hostingType === 'dnsForward'}
                  selectOption={this.openModal}
                  image="images/builder/page-hosting/Page_Hosting_Branded.svg"
                />
              ) : null}
              <HostingOption
                color="orange"
                title="Handle Hosting Yourself"
                body={[
                  'Host your campaign on your own domain. ',
                  <span style={{ fontFamily: 'Larsseit-Medium', fontWeight: '500' }}>
                    Requires Developer Support!
                  </span>,
                ]}
                type="selfHost"
                confirmText={
                  this.props.currentCampaign.hostingType === 'selfHost'
                    ? 'Continue with Self-Hosting'
                    : 'Do it Yourself'
                }
                selected={this.props.currentCampaign.hostingType === 'selfHost'}
                selectOption={
                  this.props.currentCampaign.hostingType === 'selfHost'
                    ? this.closeChoosing
                    : this.openModal
                }
              />
            </div>
          ) : (
            <div className="row" style={{ display: 'flex', flexDirection: 'column' }}>
              <PageHostingChoice
                title={
                  this.props.currentCampaign.hostingType === 'selfHost'
                    ? 'Self Hosting - Activated'
                    : 'Hosting with DojoMojo - Activated'
                }
                url={
                  this.props.currentCampaign.hostingType === 'selfHost'
                    ? this.props.currentCampaign.baseUrl
                    : ''
                }
                type={this.props.currentCampaign.hostingType}
                onEdit={this.openModal}
                renderSuccess
              />
              <a style={{ alignSelf: 'flex-end', marginTop: '19px' }} onClick={this.openChoosing}>
                Change Hosting
              </a>
            </div>
          )}

          <Dialog
            theme={ModalTheme}
            active={!!hostingModalKey}
            onEscKeyDown={this.deselectOption}
            onOverlayClick={this.deselectOption}
            title={this.props.hostingOptionHeader}
          >
            <img
              onClick={this.deselectOption}
              className={ModalTheme.closeImage}
              src="/images/icons/close.svg"
            />
            {HostingOptionComponent ? (
              <HostingOptionComponent
                {...this.props}
                activateHosting={this.activate}
                deselectOption={this.deselectOption}
              />
            ) : null}
          </Dialog>
        </div>
      </CampaignBuilderSection>
    )
  }
}

export default ReactRedux.connect(mapState)(PageHosting)
