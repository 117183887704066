import React from 'react'
import { connect } from 'react-redux'
import Button from 'react-toolbox/lib/button/Button'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import buttonTheme from 'css/themes/Buttons.css'

import Badge from 'visual-components/util/badge'
import { H3, P } from 'visual-components/util/texts'

import COLOURS from 'util/colours'
import { isTrialingAndNotUpgraded } from 'util/brand-stripe-belt-helper'
import { getClaim } from 'actions/brand-claim-actions'
import CreditsBanner from './CreditsBanner'
import { MobileMarketingContext } from './MobileMarketingContext'

const styles = {
  wrapper: {
    backgroundColor: COLOURS.crystal,
    padding: '36px 24px',
    marginBottom: 32,
  },
  soloWrapper: {
    backgroundColor: 'transparent',
    padding: 0,
    marginTop: -10,
  },
  contentWrapper: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      border: 'none',
    },
  },
  headerContainer: {
    display: 'flex',
  },
  badge: {
    marginLeft: 12,
    display: 'flex',
    alignItems: 'flex-end',
  },
}

const prettyStatus = ({
  status,
  campaignStarted,
  campaignEndDate,
  isHost,
  partnerSplitTrafficStatus,
  splitTrafficStatus,
}) => {
  if (!status) return null

  const overrideSplitTrafficStatus = partnerSplitTrafficStatus || splitTrafficStatus
  const now = new Date()
  const end = new Date(campaignEndDate)
  if (now > end) {
    return 'Inactive'
  }

  if (status === 'published') {
    if (campaignStarted) {
      if (!isHost) {
        return overrideSplitTrafficStatus === 'active'
          ? 'Live'
          : 'Inactive'
      }
      return 'Live'
    }
    return 'Published'
  }
  return status.charAt(0).toUpperCase() + status.slice(1)
}

const colorMap = {
  Draft: COLOURS.darkGrey,
  Published: COLOURS.cerulean,
  Inactive: COLOURS.darkGrey,
  Live: COLOURS.coral,
}

const stateToProps = ({ currentCampaign, currentBrand }) => ({ currentCampaign, currentBrand })

function MobileMarketingPostEntryHeader(props) {
  // these props come from conversion-action.js
  const {
    classes: css,
    isHost,
    partnerSplitTrafficStatus,
    splitTrafficStatus,
    currentBrand,
    currentCampaign,
  } = props

  const {
    smsBank, smsSend, canDuplicate, showModal,
  } = React.useContext(MobileMarketingContext)

  const displayStatus = prettyStatus({
    campaignStarted: currentCampaign.started,
    campaignEndDate: currentCampaign.endDate,
    status: smsSend.status,
    isHost,
    partnerSplitTrafficStatus,
    splitTrafficStatus,
  })

  const paidMessages = smsBank.credits.count
  const freeMessages = smsBank.credits.freeCount
  const totalMessages = paidMessages + freeMessages
  const isTrial = isTrialingAndNotUpgraded(currentBrand)
  const canCreateSmsMarketingPostEntry = getClaim('sweepstakes.mobileMarketing.create')
  const subscriberLimit = getClaim('sms.subscribers.hardLimit')
  const { subscriberCount } = smsBank
  const subscriberOverLimit = subscriberLimit && subscriberCount >= subscriberLimit

  const displayColor = colorMap[displayStatus]

  return (
    <>
      <div className={`${css.wrapper} ${currentCampaign.isSolo ? css.soloWrapper : ''}`}>
        <div className={css.headerContainer}>
          <H3>Your SMS Marketing Post Entry Action</H3>
          <div className={css.badge}>
            <Badge label={displayStatus} color={displayColor} />
          </div>
        </div>
        {
          !!canDuplicate && (
            <div className={css.contentWrapper}>
              <P>
                <i>
                Use a previous sweepstakes as your base for your post entry form, or build from scratch below!
                </i>
              </P>
              <Button
                theme={buttonTheme}
                className={buttonTheme.publishButton}
                raised
                onClick={() => showModal('templates')}
              >
                Use Previous Post Entry
              </Button>
            </div>
          )
        }
      </div>
      {(!paidMessages || subscriberOverLimit) && canCreateSmsMarketingPostEntry && (
        <CreditsBanner
          credits={totalMessages}
          isFreeCredits={!!freeMessages && !paidMessages}
          isTrial={isTrial}
          subscriberCount={subscriberCount}
        />
      )}
    </>
  )
}

MobileMarketingPostEntryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  isHost: PropTypes.bool.isRequired,
  partnerSplitTrafficStatus: PropTypes.string.isRequired,
  splitTrafficStatus: PropTypes.string.isRequired,
}

export default React.memo(
  connect(stateToProps)(injectSheet(styles)(MobileMarketingPostEntryHeader))
)
