import React from 'react';
import SectionHr from 'util/components/section-hr';
import Tooltip from 'util/components/tooltip';
import _ from 'lodash'

class CampaignSummaryRow extends React.Component{
  constructor(props) {
    super(props)
  };

  render() {

    var bulletPoints = _.map( this.props.bulletPoints, function( bulletPoint, idx ) {
      return(
        <li key={ idx }
          style={{
            marginBottom: '12px'  
        }}>
          { bulletPoint }
        </li>
      )
    });

    let hrStyles = {
      marginBottom: '20px',
      marginTop: '20px'
    };

    return(
      <div>
        <SectionHr
          styles={{
            marginBottom: '20px',
            marginTop: '0px'
          }}   
        />
        { this.props.header }
        { this.props.headerToolTip ?
          <Tooltip
            title={ this.props.headerToolTip }
            styles={{
              float: 'right'
          }}/>
          : null
        }
        { this.props.noHrBottom ?
          null
          : 
          <SectionHr
            styles={{
              marginBottom: '20px',
              marginTop: '20px'
            }}
          />
        }
        <ul>
          { bulletPoints }
        </ul>
      </div>
    )
  }
};

export default CampaignSummaryRow;
