import React from 'react';
import theme from 'css/themes/homePage/whatMarketersSay.css';
import { Carousel } from 'react-responsive-carousel';
import MarketerSaidSlide from '../util/marketer-said-slide';

// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

class WhatTheySay extends React.Component {

  render() {
    return(
      <div className={ theme.container }>
        <p className={ theme.title }>
          What Marketers Are Saying
        </p>
        <Carousel
          infiniteLoop={ true }
          showThumbs={false}
        >
          <div>
            <MarketerSaidSlide
              quote={ `“DojoMojo has taken standard audience-building campaigns that we've run in the past and multiplied their effects, enabling us to work with amazing brands on incredible campaigns, and allowing us to more than triple our list size in a little over a month. It looks like we'll be able to triple again within the next two months!”` }
              author={ 'Leif Frey' }
              whoDat={ 'Head Gent of Direction at' }
              linkTitle={ 'FREY' }
              linkURL={ 'https://www.frey.com/' }
              imgSrc={  '/images/brand-logos/frey-logo-6-29-black-copy-01.svg' }
            />
          </div>
          <div>
            <MarketerSaidSlide
              quote={ `“Before DojoMojo, giveaways took A LOT of time. Now we can create giveaways that we know our readers will love in just a few steps. Creating one landing page and finding other brands to join our giveaway happens all on one platform.”` }
              author={ 'Lauren McGoodwin' }
              whoDat={ 'CEO & Co-Founder at' }
              linkTitle={ 'Career Contessa' }
              linkURL={ 'http://www.careercontessa.com/' }
              imgSrc={  '/images/brand-logos/careercontessa-02.svg' }
            />
          </div>
          <div>
            <MarketerSaidSlide
              quote={ `“Our team enjoys using DojoMojo - the platform makes easy to invite partners, create official rules and a landing page, and drive social media following.”` }
              author={ 'Chris Devine' }
              whoDat={ 'Retail Operations Manager at' }
              linkTitle={ 'Rhone' }
              linkURL={ 'https://www.rhone.com/' }
              imgSrc={  '/images/brand-logos/rhone-logo-2017.svg' }
            />
          </div>
        </Carousel>
      </div>
    )
  }
}

export default WhatTheySay;
