export default function (data) {
  var str = `
    <html lang="en" xmlns:fb="http://ogp.me/ns/fb#">
      <head>
        <meta charset="utf-8">
        <meta content="IE=edge,chrome=1" http-equiv="X-UA-Compatible">
        <meta content="width=device-width" name="viewport">
        <title>${data.name}</title>
        <meta content="${data.description}" name="description">
        <meta property="og:title" content="${data.name}" />
        <meta property="og:type" content="website" />
        <meta property="ga" content="${data.ga}" />
        <meta property="og:image" content="${data.mainImage}" />
        <meta property="og:description" content="${data.description}"/>
      </head>
      <body>
      </body>
      <style>
        html {
          height: 100%;
        }
        body {
          margin: 0px;
          height: 100%;
          overflow: hidden;
        }
        iframe {
          width: 100%;
          height: 100%;
          overflow-x: hidden;
          overflow-y: scroll;
        }
      </style>
      <script>
        window.adroll_adv_id = '${data.adRollId}';
        window.adroll_pix_id = '${data.adRollPixelId}';
        (function () {
            var _onload = function(){
                if (document.readyState && !/loaded|complete/.test(document.readyState)){setTimeout(_onload, 10);return}
                if (!window.__adroll_loaded){__adroll_loaded=true;setTimeout(_onload, 50);return}
                var scr = document.createElement('script');
                var host = (('https:' == document.location.protocol) ? 'https://s.adroll.com' : 'http://a.adroll.com');
                scr.setAttribute('async', 'true');
                scr.type = 'text/javascript';
                scr.src = host + '/j/roundtrip.js';
                ((document.getElementsByTagName('head') || [null])[0] ||
                    document.getElementsByTagName('script')[0].parentNode).appendChild(scr);
            };
            if (window.addEventListener) {window.addEventListener('load', _onload, false);}
            else {window.attachEvent('onload', _onload)}
        }());
        function getParameterByName(name) {
           name = name.replace(/[\[]/, \"\\[\").replace(/[\]]/, \"\\]\");
           var regex = new RegExp(\"[\\?&]\" + name + \"=([^&#]*)\"),
               results = regex.exec(location.search);
           return results === null ? '' : decodeURIComponent(results[1].replace(/\\+/g, \" \"));
        }
        window.onload = function () {
          var iframe = document.createElement('iframe');
          var shareId = getParameterByName('share_id');
          shareId = shareId ? '&share_id=' + shareId : '';
          var promoId = getParameterByName('promo_id');
          if (promoId) {
            iframe.src = '${data.staticPath}' + window.location.search + shareId;
          } else {
            iframe.src = '//www.dojomojo.com/landing/campaign/${data.id}';
          }
          var body = document.getElementsByTagName('body')[0];
          body.insertBefore(iframe, body.firstChild);
        }
        ;var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
          if (parseInt(ua.substring(msie + 5, ua.indexOf('.', msie))) < 10) {
            alert('Looks like this browser is not supported so the elements on this page may not display properly.');
          }
        }
      </script>
    </html>
  `;
  return str;
}
