import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { H6, P } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'
import Dropzone from 'react-dropzone'

const styles = {
  addImage: {
    height: 208,
    backgroundColor: COLOURS.foggy,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addImageText: {
    textAlign: 'center',
    width: 312,
    margin: '8px 0 24px',
  },
  addNowButton: {
    borderRadius: '2px',
    borderWidth: '0px',
    padding: '11px 16px',
    fontFamily: 'Larsseit-Medium',
    backgroundColor: 'rgba(44,53,65,0.8)',
    color: COLOURS.white,
    fontSize: 14,
    lineHeight: '14px',
  },
}

class ImageDropZone extends Component {
  render() {
    const {
      classes: css,
      onDrop,
    } = this.props

    return (
      <div className={`row ${css.addImage}`}>
        <H6>Add Hero Image</H6>
        <div className={css.addImageText}>
          <P multiline>Including a hero image can substantially improve post entry performance.</P>
        </div>
        <Dropzone 
          className="no-style" 
          onDrop={onDrop} 
          accept={{
            'image/x-png': [],
            'image/gif': [],
            'image/jpeg': [],
          }} 
          maxSize={5000000}
        >
          {({
            getRootProps,
            getInputProps,
          }) => (
            <>
              <input {...getInputProps()} />
              <button type="button" className={`btn btn-primary ${css.addNowButton}`}>
                Add Now
              </button>
            </>
          )}
        </Dropzone>
      </div>
    )
  }
}

ImageDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrop: PropTypes.func, //used for preview mode only (upload image)
}

ImageDropZone.defaultProps = {
  onDrop: () => {},
}

export default injectSheet(styles)(ImageDropZone)
