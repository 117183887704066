import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import SingleCalendar from 'visual-components/util/SingleCalendar'

import { H4 } from 'visual-components/util/texts'

import * as DealActions from 'actions/deal-actions'

const stateToProps = ({ deals }) => ({ deals })

const dispatchToProps = dispatch => ({ dealActions: bindActionCreators(DealActions, dispatch) })

class MediaDealChangeDatesModal extends PureComponent {
  static propTypes = {
    deals: PropTypes.object.isRequired,
    dealActions: PropTypes.object.isRequired,
  }

  state = {
    endDate: null,
    startDate: null,
  }

  componentDidMount() {
    const {
      deals: {
        deal: {
          id,
          end_date: endDate,
          start_date: startDate,
        },
      },
      dealActions,
    } = this.props


    dealActions.fetchInitialDates(id)

    this.setState({ endDate, startDate })
  }

  componentDidUpdate({ deals: { deal, showChangeDatesModal } }) {
    const { deals } = this.props

    if (
      showChangeDatesModal
      && showChangeDatesModal !== deals.showChangeDatesModal
      && (
        !moment(deal.start_date).isSame(deals.deal.start_date)
        || !moment(deal.end_date).isSame(deals.deal.end_date)
      )
    ) {
      const {
        dealActions,
        deals: {
          deal: {
            id: deal_id,
          },
        },
      } = this.props

      // Note: Not sure if necessary...
      dealActions.loadById(deal_id)
      dealActions.fetchInitialDates(deal_id)
    }
  }

  onCloseModal = () => {
    const { dealActions } = this.props

    dealActions.showChangeDatesModal(false)
  }

  onDateChange = which => date => {
    this.setState({
      [which]: moment(date)[which === 'endDate' ? 'endOf' : 'startOf']('day'),
    })
  }

  onSubmit = () => {
    const { endDate, startDate } = this.state
    const {
      deals: {
        deal: {
          id,
          end_date: prevEndDate,
          start_date: prevStartDate,
        },
      },
      dealActions,
    } = this.props

    if (moment(endDate).isSame(prevEndDate) && moment(startDate).isSame(prevStartDate)) {
      toastr.info('No changes have been made.', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })

      return dealActions.showChangeDatesModal(false)
    }

    const obj = { endDate, startDate }

    // This closes the modal
    return dealActions.updateDates(id, obj)
  }

  render() {
    const {
      deals: {
        showChangeDatesModal: active,
        deal: {
          status,
          end_date,
          start_date,
        },
        initial_start_date,
        initial_end_date,
      },
    } = this.props

    // Avoid showing it if it ended
    if (moment(end_date).isBefore()) return null

    const isLive = status === 'live'

    return (
      <MultiTaskModal
        active={active}
        close={this.onCloseModal}
        title={isLive ? 'Extend End Date' : 'Edit Deal Dates'}
        disableOverlayClick
      >
        <H4 multiline>
          You can change the dates of the deal below. We will notify the buyer of any changes.
        </H4>
        <div style={{ display: 'flex', marginBottom: '32px', position: 'relative' }}>
          {
            isLive
              ? null
              : (
                <React.Fragment>
                  <SingleCalendar
                    title="Start Date"
                    date={start_date}
                    onChange={this.onDateChange('startDate')}
                    minDate={moment(new Date()).endOf('day')}
                    maxDate={moment(initial_start_date).add(1, 'days')}
                  />
                  <div style={{ width: '8px' }} />
                </React.Fragment>
              )
          }
          <SingleCalendar
            title="End Date"
            date={end_date}
            onChange={this.onDateChange('endDate')}
            minDate={moment(isLive ? end_date : initial_end_date).startOf('day')}
            maxDate={moment(initial_end_date).add(1, 'months')}
          />
        </div>
        <ModalButtonGroup
          canSave
          confirmText="Confirm"
          cancelText="Cancel"
          confirm={this.onSubmit}
          cancel={this.onCloseModal}
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(MediaDealChangeDatesModal)
