import React, { Component } from 'react';
import _ from 'lodash';
import Row from 'visual-components/util/no-padding-grid/row';
import { Col } from 'react-flexbox-grid';
import { H4, P } from 'visual-components/util/texts';
import InputContainer from 'visual-components/util/InputContainer';
import { Input } from 'react-toolbox/lib/input';
import inputTheme from 'css/themes/no-padding-input.css';
import countries from 'util/countries';
import dropdownTheme from 'css/themes/Dropdown.css';
import Dropdown from 'react-toolbox/lib/dropdown';

export default class ApplyToCampaignStepThree extends Component {
  updateValue = name => value => {
    this.props.updateData({
      legalDetails: {
        ...this.getMergedLegalData(),
        [name]: value,
      },
    });
  };

  onFocusChange = focus => {
    this.setState({ focusedInput: focus });
  };

  getMergedLegalData() {
    const { applicationData, brand } = this.props;
    return Object.keys(applicationData).reduce((acc, curr) => {
      acc[curr] = applicationData[curr] || brand[curr];
      return acc;
    }, {});
  }

  render() {
    const { updateValue, confirmText, required } = this.props;

    const formData = this.getMergedLegalData()

    return (
      <div>
        <P>
          {`Step 3 of 3: Add your brand's information, which the Host brand will
          use to create a Partnership Agreement. If you can't fill this
          out now, don't worry - you can complete this prior to finalizing the
          agreement with the Host.`}
        </P>
        <Row style={{ marginTop: 16 }}>
          <Col xs>
            <InputContainer label="Legal Company Name">
              <Input
                type="text"
                name="legalName"
                theme={inputTheme}
                value={formData.legalName}
                placeholder="Company Name, Inc"
                onChange={this.updateValue('legalName')}
              />
            </InputContainer>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Col xs={6} style={{ paddingRight: 8 }}>
            <InputContainer label="State of Incorporation">
              <Input
                type="text"
                name="state"
                theme={inputTheme}
                value={formData.incorporationState}
                placeholder="Delaware"
                onChange={this.updateValue('incorporationState')}
              />
            </InputContainer>
          </Col>
          <Col xs={6}>
            <InputContainer label="Entity Type">
              <Input
                type="text"
                name="entityType"
                theme={inputTheme}
                value={formData.entityType}
                placeholder="Corporation"
                onChange={this.updateValue('entityType')}
              />
            </InputContainer>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Col xs>
            <InputContainer label="Legal Address">
              <Row between="xs">
                <Col xs={8} style={{ paddingRight: 8 }}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Address Line 1
                  </P>
                  <Input
                    type="text"
                    name="addressL1"
                    theme={inputTheme}
                    placeholder="111 Street Name"
                    value={formData.addressL1}
                    onChange={this.updateValue('addressL1')}
                  />
                </Col>
                <Col xs={4}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Address Line 2
                  </P>
                  <Input
                    type="text"
                    name="addressL2"
                    theme={inputTheme}
                    placeholder="Apt, Suite, Unit #"
                    value={formData.addressL2}
                    onChange={this.updateValue('addressL2')}
                  />
                </Col>
              </Row>
              <Row between="xs" style={{ marginTop: 18 }}>
                <Col xs={8} style={{ paddingRight: 8 }}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    City
                  </P>
                  <Input
                    type="text"
                    name="city"
                    theme={inputTheme}
                    placeholder=""
                    value={formData.city}
                    onChange={this.updateValue('city')}
                  />
                </Col>
                <Col xs={4}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    State/Province/Region
                  </P>
                  <Input
                    type="text"
                    name="state"
                    theme={inputTheme}
                    placeholder=""
                    value={formData.state}
                    onChange={this.updateValue('state')}
                  />
                </Col>
              </Row>
              <Row between="xs" style={{ marginTop: 18 }}>
                <Col xs={4} style={{ paddingRight: 8 }}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Zip Code
                  </P>
                  <Input
                    type="text"
                    name="zipcode"
                    theme={inputTheme}
                    placeholder=""
                    value={formData.zipcode}
                    onChange={this.updateValue('zipcode')}
                  />
                </Col>
                <Col xs={8}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Country
                  </P>
                  <Dropdown
                    theme={dropdownTheme}
                    auto
                    onChange={this.updateValue('country')}
                    source={countries.map(c => ({
                      label: c.name,
                      value: c.name,
                    }))}
                    value={formData.country}
                  />
                </Col>
              </Row>
            </InputContainer>
          </Col>
        </Row>

      </div>
    );
  }
}
