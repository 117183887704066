import React from 'react'
import _ from 'lodash'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Text from 'util/components/text'
import COLOURS from 'util/colours'
import Badge from 'visual-components/util/badge'
import { messagingRelativeDate } from 'util/relativeDate'
import MessagingBrandLogo from 'visual-components/messaging/messaging-brand-logo'
import { withRouter, Link } from 'react-router-dom'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import PartnershipDispatcher from 'dispatchers/partnership-invites-dispatcher'
import * as MessagingPartnerActions from 'actions/messaging-partner-actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import requestHeaders from 'request-config'
import cn from 'classnames'

import buttonTheme from 'css/themes/Buttons.css'

import Button from 'react-toolbox/lib/button'
import FontIcon from 'react-toolbox/lib/font_icon'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(MessagingPartnerActions, dispatch),
})

const responseMap = {
  interested: {
    label: 'Interested',
    styles: 'ctaInboxBlueLight',
  },
  decline: {
    label: 'Decline',
    styles: 'ctaInboxCharcoal',
  },
  viewCampaign: {
    label: 'View Campaign',
    styles: 'ctaInboxGreen',
  },
  // Todo: Delete if not used
  // counter: 'Negotiate',
  viewAgreement: {
    label: 'View Agreement',
    styles: 'ctaInboxGreen',
  },
  createAgreement: {
    label: 'Create Agreement',
    styles: 'ctaInboxGreen',
  },
  signAgreement: {
    label: 'Sign Agreement',
    styles: 'ctaInboxGreen',
  },
  explore: {
    label: 'Explore',
    styles: 'ctaInboxGreen',
  },

  // Split traffic
  acceptSplitTraffic: {
    label: 'Accept',
    styles: 'ctaInboxBlueLight',
  },
  declineSplitTraffic: {
    label: 'Decline',
    styles: 'ctaInboxCharcoal',
  },
  viewSplitTraffic: {
    label: 'Setup Split Traffic',
    styles: 'ctaInboxBlueLight',
  },
}

const statusMap = {
  accepted: {
    text: 'Accepted',
    color: COLOURS.seaGreen,
  },
  pending: {
    text: 'Pending',
    color: COLOURS.cloudGrey,
  },
  rejected: {
    text: 'Declined',
    color: COLOURS.charcoal,
  },
  withdrawn: {
    text: 'Withdrawn',
    color: COLOURS.charcoal,
  },
  interested: {
    text: 'Interested',
    color: COLOURS.cobalt,
  },
  // split traffic invites
  active: {
    text: 'Accepted',
    color: COLOURS.seaGreen,
  },
  partnerRequested: {
    text: 'Requested',
    color: COLOURS.cloudGrey,
  },
  notInvited: {
    text: 'Not invited',
    color: COLOURS.cloudGrey,
  },


}

const splitTrafficStatusMap = {
  invited: 'pending',
  declined: 'rejected',
}

const replyToSplitTrafficInvite = async (campaignId, response) => {
  await axios.put(`/partnerships/split-traffic-invite/${campaignId}`, { response }, { headers: requestHeaders() })
  toastr.success(`Invite ${response ? 'accepted' : 'declined'}`, null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
}

class MessageThreadItemInvite extends React.Component {
  state = {
    splitTrafficInviteSent: false,
    splitTrafficInviteStatus: null,
  }

  accept = () => {
    const { message } = this.props
    const campaign = _.find(store.getState().upcomingCampaigns.campaigns, { id: message.campaign_id })
    const acceptedPartners = campaign && Object.keys(campaign.partners).filter(partner =>
      campaign.partners[partner].status === 'accepted')
    const numberOfPartners = acceptedPartners ? acceptedPartners.length : message.numberOfPartners
    if (campaign && !campaign.noPartnerLimit && numberOfPartners >= 8) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'campaignLimitModal',
        data: {
          open: true,
          isInvitation: !message.application,
          campaignId: message.campaign_id,
        },
      })
      return
    }

    const actionMap = {
      receivedInvitation() {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'conversations',
          data: {
            showInviteAcceptConfirmationModal: message,
          },
        })
      },
      receivedApplication() {
        PartnershipDispatcher.acceptApplication({
          id: message.partnershipinvite_id,
          campaign_id: message.campaign_id,
          message_id: message.id,
          conversation_id: message.conversation_id,
          campaign_name: message.campaign_name,
          campaign_host_name: message.host_brand_name,
          campaign_start_date: message.campaign_start_date,
          campaign_end_date: message.campaign_end_date,
          application: true,
          invitee: {
            name: message.invitee_brand_name,
          },
        })
      },
    }

    actionMap[message.messageType]()
  }

  viewAgreement = () => {
    const { message } = this.props
    const url = this.props.history.createHref({ pathname: `/partnership-agreement/${message.partnershipinvite_id}` })
    window.open(url, '_blank')
  }

  signAgreement = () => {
    const { message } = this.props

    BrowserHistoryDispatcher.push(`/builder/${message.campaign_id}/partnership-agreement/sign/${message.partnershipinvite_id}`)
  }

  createAgreement = () => {
    const { message } = this.props

    BrowserHistoryDispatcher.push(`/builder/${message.campaign_id}/partnership-agreement/choose/${message.partnershipinvite_id}`)
  }

  interested = () => {
    const {
      actions,
      message,
    } = this.props

    const campaign = _.find(store.getState().upcomingCampaigns.campaigns, { id: message.campaign_id })
    const acceptedPartners = campaign && Object.keys(campaign.partners).filter(partner =>
      campaign.partners[partner].status === 'accepted')
    const numberOfPartners = acceptedPartners ? acceptedPartners.length : message.numberOfPartners
    if (campaign && !campaign.noPartnerLimit && numberOfPartners >= 8) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'campaignLimitModal',
        data: {
          open: true,
          isInvitation: !message.application,
          campaignId: message.campaign_id,
        },
      })
    } else if (!message.application) {
      actions.toggleInterestedInfoModal(
        message.partnershipinvite_id,
        {
          campaign: {
            id: message.campaign_id,
            startDate: message.campaign_start_date,
            endDate: message.campaign_end_date,
            name: message.campaign_name,
          },
          brand_name: message.displayBrandName,
          application: message.application,
          invite_id: message.partnershipinvite_id,
          message_id: message.id,
          conversation_id: message.conversation_id,
          messageType: `${message.messageType}s`,
        }
      )
    } else {
      actions.sendInterest(message.partnershipinvite_id, message.id, message.conversation_id, `${message.messageType}s`, { application: true, campaignId: message.campaign_id })
      actions.toggleInterestedConfirmationModal(true, {
        application: message.application,
        campaign_name: message.campaign_name,
        brand_name: message.displayBrandName,
        campaign_id: message.campaign_id,
        partnership_invite_id: message.partnershipinvite_id,
      })
    }
  }

  explore = () => {
    window.location = '/app/'
  }

  decline = () => {
    const { message } = this.props
    const actionMap = {
      receivedInvitation() {
        PartnershipDispatcher.rejectInvite({
          id: message.partnershipinvite_id,
          invitee: {
            id: message.invitee_id,
          },
          message_id: message.id,
          conversation_id: message.conversation_id,
        })
      },
      receivedApplication() {
        PartnershipDispatcher.rejectApplication({
          id: message.partnershipinvite_id,
          campaign_id: message.campaign_id,
          message_id: message.id,
          conversation_id: message.conversation_id,
        })
      },
    }

    actionMap[message.messageType]()
  }

  counter = () => {
    const { message } = this.props
    BrowserHistoryDispatcher.push(`/messaging/inbox/${message.conversation_id}`)
  }

  viewCampaign = () => {
    window.open(`/app/explore/campaigns/${this.props.message.campaign_id}`)
  }

  acceptSplitTraffic = async () => {
    const { splitTrafficInviteSent } = this.state
    if (splitTrafficInviteSent) return
    const { message } = this.props
    await replyToSplitTrafficInvite(message.campaign_id, true)
    this.setState({
      splitTrafficInviteSent: true,
      splitTrafficInviteStatus: 'accepted',
    })
  }

  declineSplitTraffic = async () => {
    const { splitTrafficInviteSent } = this.state
    if (splitTrafficInviteSent) return
    const { message } = this.props
    await replyToSplitTrafficInvite(message.campaign_id, false)
    this.setState({
      splitTrafficInviteSent: true,
      splitTrafficInviteStatus: 'rejected',
    })
  }

  viewSplitTraffic = () => {
    const { message } = this.props
    BrowserHistoryDispatcher.push(`/partnerships/campaign/${message.campaign_id}/grow-optimize`)
  }

  render() {
    const {
      splitTrafficInviteSent,
      splitTrafficInviteStatus,
    } = this.state
    const { message, seenBy } = this.props

    const that = this
    const startDate = message.campaign_start_date
    const responses = message.responses && message.responses.map((response, idx) => {
      let isDisabled
      if (message.type === 'splitTrafficInvite') {
        message.statusOverride = splitTrafficInviteStatus || splitTrafficStatusMap[message.split_traffic_invite_status] || message.split_traffic_invite_status
        isDisabled = splitTrafficInviteSent
      } else if (message.type === 'splitTrafficRequest') {
        message.statusOverride = 'interested'
      } else if (message.messageType === 'newBrandWelcome') {
        isDisabled = false
      } else if (message.status === 'interested') {
        isDisabled = response === 'interested' || (startDate && new Date(startDate) < new Date())
      } else {
        const nonDisabledArray = ['viewCampaign', 'viewAgreement', 'createAgreement']

        if (message.status !== 'accepted') {
          nonDisabledArray.push('signAgreement')
        }

        isDisabled = !nonDisabledArray.includes(response) && (startDate && new Date(startDate) < new Date() || message.status !== 'pending')
      }
      const showIcon = ['createAgreement', 'viewAgreement', 'viewCampaign', 'explore'].includes(response)
      return (
        <Button
          key={message.id}
          onClick={_.debounce(that[response], 200)}
          theme={buttonTheme}
          className={cn(
            buttonTheme.noMargin,
            buttonTheme[responseMap[response].styles],
            buttonTheme.ctaInbox,
            { [buttonTheme.ctaInboxDisabled]: isDisabled }
          )}
          label={responseMap[response].label}
          flat
          disabled={isDisabled}
          style={{
            marginRight: '8px',
          }}
        >
          {showIcon && (<FontIcon value="open_in_new" />)}
        </Button>
      )
    })

    return (
      <div style={{
        borderBottom: `1px solid ${COLOURS.lightGrey}`,
      }}
      >
        <Row style={{
          padding: '12px 16px',
        }}
        >
          <Col xs={10}>
            <p style={{
              fontSize: '18px',
              fontFamily: 'Larsseit-Bold',
            }}
            >
              { message.heading }
            </p>
          </Col>
          <Col xs={1} style={{ textAlign: 'right' }}>
            { message.partnership_action
              ? null
              : (
                <Badge
                  label={statusMap[message.statusOverride || message.status].text}
                  color={statusMap[message.statusOverride || message.status].color}
                />
              )
            }
          </Col>
          <Col xs={1}>
            <p style={{
              fontFamily: 'Larsseit-Light',
              fontSize: '12px',
              marginTop: '2px',
              textAlign: 'right',
            }}
            >
              { messagingRelativeDate(message.createdAt) }
            </p>
          </Col>
        </Row>
        <Row style={{
          borderBottom: `1px solid ${COLOURS.lightGrey}`,
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingBottom: '16px',
        }}
        >
          <Col xs={12}>
            { responses }
          </Col>
        </Row>
        <Row style={{
          padding: '12px 12px 0',
        }}
        >
          <Col xs={1}>
            <MessagingBrandLogo logo={message.displayBrandLogo} brandId={message.brand_id} />
          </Col>
          <Col
            xs={2}
            style={{
              paddingLeft: '12px',
            }}
          >
            <Row>
              <Col xs>
                <p style={{
                  fontFamily: 'Larsseit-Bold',
                  fontSize: '14px',
                  marginTop: '8px',
                  marginLeft: '-5px',
                  whiteSpace: 'nowrap',
                }}
                >
                  { message.displayBrandName }
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{
                  fontFamily: 'Larsseit',
                  fontSize: '12px',
                  marginTop: '12px',
                  display: 'inline',
                }}
                >
                  { message.firstName }
                </p>
                <p style={{
                  fontFamily: 'Larsseit-Light',
                  fontSize: '12px',
                  marginTop: '8px',
                  textAlign: 'left',
                  color: COLOURS.cloudGrey,
                  display: 'inline',
                  paddingLeft: '8px',
                }}
                >
                  {
                    seenBy >= 1
                      ? `Seen by ${seenBy} ${seenBy === 1 ? 'person' : 'people'}`
                      : null
                  }
                </p>
              </Col>
            </Row>
          </Col>
          { window.location.href.indexOf('inbox') === -1
            ? (
              <Col
                xs={3}
                xsOffset={6}
                style={{
                  textAlign: 'right',
                }}
              >
                <Link to={`/messaging/inbox/${message.conversation_id}`}>
                  <span>
                    <img
                      src="/images/icons/messaging-icons/go-to-icon.svg"
                      style={{
                        display: 'inline-block',
                        marginRight: '8px',
                      }}
                    />
                    <p style={{
                      color: COLOURS.azure,
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '14px',
                      display: 'inline-block',
                    }}
                    >
                      See Conversation
                    </p>
                  </span>
                </Link>
              </Col>
            )
            : null
          }
        </Row>
        <Row>
          <Col xsOffset={1} xs={10}>
            <p
              dangerouslySetInnerHTML={{ __html: message.content }}
              className="message-container"
              style={{
                color: COLOURS.dropdownText,
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(MessageThreadItemInvite))
