import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import injectSheet from 'react-jss'
import { object, func, string, bool } from 'prop-types'
import { Dialog } from 'react-toolbox/lib/dialog'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import UploadImage from 'components/util/editor-uploader'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { P, Small } from 'visual-components/util/texts'
import * as BackgroundImageActions from 'actions/background-image-actions'
import * as metrics from 'util/metrics'
import COLOURS from 'util/colours'

const mapStateToProps = ({ currentCampaign, backgroundImages }) =>
  ({ currentCampaign, backgroundImages })

const mapDispatchToProps = dispatch => ({
  backgroundImageActions: bindActionCreators(BackgroundImageActions, dispatch),
})

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'flex-start',
    width: '100%',
    height: '482px',
    overflowY: 'scroll',
    overflowX: 'visible',
    '&::-webkit-scrollbar': {
      width: '0px', /* remove scrollbar space */
      background: 'transparent', /* optional: just make scrollbar invisible */
    },
    marginTop: 16,
  },
  uploadImage: {
    width: 200,
    height: 128,
    border: `1px solid ${COLOURS.silver}`,
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 2,
    '& img': {
      marginTop: 30,
      marginBottom: 24,
    },
  },
  uploadVideo: {
    width: 296,
    height: 160,
    marginBottom: 16,
    marginRight: 16,
    '& img': {
      marginTop: 46,
    },
  },
  imageContainer: {
    width: 200,
    height: 128,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 2,
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
    },
    '& video': {
      objectFit: 'cover',
      width: 296,
      height: 160,
    },
  },
  videoContainer: {
    width: 296,
    height: 160,
    marginBottom: 16,
    marginRight: 16,
  },
  selected: {
    border: `4px solid ${COLOURS.seaGreen}`,
  },
  selectedIcon: {
    position: 'absolute',
    marginTop: 8,
    marginLeft: 8,
    zIndex: 2,
    '& img': {
      width: 16,
      height: 16,
    },
  },
  noMargin: {
    marginRight: 0,
  },
  portrait: {
    width: 200,
    minHeight: 128,
    marginTop: '-30%',
  },
  landscape: {
    minWidth: 200,
    height: 128,
  },
}

class UploadImageModal extends React.Component {
  static propTypes = {
    active: bool,
    currentCampaign: object.isRequired,
    backgroundType: string.isRequired,
    backgroundImages: object.isRequired,
    backgroundImageActions: object.isRequired,
    updateAttr: func.isRequired,
    changeImage: func.isRequired,
    closeModal: func.isRequired,
    classes: object.isRequired,
  }

  static defaultProps = {
    active: false,
  }

  state = {}

  componentDidMount() {
    const { backgroundImageActions: { fetch } } = this.props
    fetch()
  }

  upload = image => {
    const { backgroundImageActions: { upload } } = this.props
    upload(image)
  }

  select = selectedImage => {
    const { backgroundImageActions: { updateAttr } } = this.props
    updateAttr({ selectedImage })
  }

  insert = () => {
    const {
      backgroundType,
      backgroundImages: { selectedImage },
      changeImage,
      updateAttr,
    } = this.props

    metrics.create(`campaignManagerUploadedBackgound${backgroundType}`)

    updateAttr('mainImage', selectedImage.src, true)
    changeImage(true)
    this.closeModal()
  }

  play = id => {
    const myVideo = document.getElementById(`video${id}`)
    myVideo.play()
  }

  pause = id => {
    const myVideo = document.getElementById(`video${id}`)
    myVideo.pause()
  }

  closeModal = () => {
    const {
      closeModal,
      backgroundImageActions: { updateAttr, clear },
    } = this.props

    clear()
    updateAttr({ selectedImage: null })
    closeModal()
  }

  renderImages = () => {
    const {
      currentCampaign,
      backgroundType,
      backgroundImages: { images: uploadedImages, selectedImage },
      classes: css,
    } = this.props

    const isVideo = backgroundType === 'Video'
    const filtered = uploadedImages.filter(item => item.src.endsWith(isVideo ? '.mp4' : '.png'))
    const images = filtered.map((item, idx) => {
      const isSelected = selectedImage && item.src && selectedImage.src === item.src
      const portrait = item.metadata && item.metadata.height > item.metadata.width
      const noMargin = isVideo
        ? idx % 2 === 0
        : idx !== 0 && (idx - 1) % 3 === 0

      return (
        <div
          className={`${css.imageContainer} ${isVideo ? css.videoContainer : ''} ${isSelected ? css.selected : ''} ${noMargin ? css.noMargin : ''}`}
          onClick={() => this.select(item)}
          key={`imageContainer${item.id}`}
        >
          { isSelected ? (
            <div className={css.selectedIcon}>
              <img src="/images/integration-logos/checkmark.svg" />
            </div>
          ) : null}
          { isVideo ? (
            <div onMouseOver={() => this.play(item.id)} onMouseOut={() => this.pause(item.id)}>
              <video muted className={css.video} id={`video${item.id}`} key={`video${item.id}`}>
                <source src={item.src} type="video/mp4" />
              </video>
            </div>
          ) : (
            <img
              src={item.src}
              key={`image${item.id}`}
              className={portrait ? css.portrait : css.landscape}
            />
          )}
        </div>
      )
    })

    return (
      <div className={css.container}>
        <div className={`${css.uploadImage} ${isVideo ? css.uploadVideo : ''}`}>
          <UploadImage
            success={this.upload}
            action={`/campaign-photo/${currentCampaign.id}/background`}
            name="campaign-main-image"
            acceptedFiles={isVideo ? 'video/mp4,video/x-msvideo,video/quicktime,video/x-ms-wmv' : 'image/jpeg,image/png'}
          >
            <img src="/images/builder/icons/upload-image.svg" />
            <Small azure>
              <b>{ `Upload ${backgroundType}` }</b>
            </Small>
          </UploadImage>
        </div>
        { images }
      </div>
    )
  }

  render() {
    const {
      active,
      backgroundType,
      backgroundImages: { selectedImage },
    } = this.props

    const isVideo = backgroundType === 'Video'

    return (
      <div>
        <Dialog
          active={active}
          title={`${backgroundType} Upload`}
          theme={DialogTheme}
          onEscKeyDown={this.closeModal}
          onOverlayClick={this.closeModal}
        >
          <div>
            <img
              onClick={this.closeModal}
              className={DialogTheme.closeImage}
              src="/images/icons/close.svg"
            />

            <P multiline>
              {`Add or select ${isVideo ? 'a video' : 'an image'} below. Files larger than ${isVideo ? '100' : '10'}mb are not currently supported.`}
            </P>

            {this.renderImages()}

            <ModalButtonGroup
              confirm={this.insert}
              confirmText="Insert"
              cancel={this.closeModal}
              canSave={selectedImage}
            />
          </div>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(UploadImageModal))
