import { merge } from 'lodash'
import moment from 'moment-timezone'
import { v1 as uuidv1 } from 'uuid'
import ImageTypeArray from '../../app/visual-components/mediaMarket/utilMaps/asset-image-type-map'

const initialState = {
  rid: 'dealAssets',
  upload: {
    note: '',
    includeMap: {},
    confirmingUpload: false,
    uploading: false,
    files: [],
  },
  loading: false,
  assetGroups: [
    // { assets: [], created_at }
  ],
  rawAssets: [],
  assets: {
    files: [],
    images: [],
  },
  dealId: null,
  selectedTab: 'images',
  dealAttachmentId: null,
  comments: [],
  firstUnreadComment: null,
  commentLoading: {
    isLoading: false,
    parentId: null,
  },
}

const setFiles = (state, action) => {
  const includeMap = action.data.reduce((memo, file) => Object.assign({}, memo, {
    [file.name]: true,
  }), {})

  const upload = {
    ...state.upload,
    files: action.data,
    confirmingUpload: true,
    includeMap,
  }

  return { ...state, upload }
}

const toggleIncludeFile = (state, action) => {
  const includeMap = Object.assign({}, state.upload.includeMap)

  includeMap[action.data] = !includeMap[action.data]

  const upload = { ...state.upload, includeMap }

  return { ...state, upload }
}

const loadingDealAssets = (state, action) => {
  const {
    dealId,
  } = action.data

  return {
    ...state,
    loading: true,
    dealId: Number(dealId),
  }
}


const groupItem = (asset, user_name, user_id) => ({
  created_at: asset.created_at,
  messageType: 'asset',
  brand_id: asset.brand_id,
  [ImageTypeArray.includes(asset.type) ? 'images' : 'assets']: [asset],
  uid: uuidv1(),
  allAssets: [asset],
  user_name,
  user_id,
})


const groupAssets = assets => assets.reduce((memo, asset) => {
  // user details about asset
  const user_name = asset.user && asset.user.fullName ? asset.user.fullName
    : `${asset.user.firstName || ''} ${asset.user.lastName || ''}`
  const { user_id, type, created_at } = asset

  //details about last group item
  const lastItemCreatedAt = memo.length ? memo[memo.length - 1].created_at : null
  const lastUserId = memo.length ? memo[memo.length - 1].user_id : null
  const currentGroupIsImage = memo.length ? memo[memo.length - 1].images : null

  // type of asset
  const assetIsImage = ImageTypeArray.includes(type)
  const assetSameMinute = lastItemCreatedAt ? Math.abs(moment(lastItemCreatedAt).diff(moment(created_at), 'minute')) <= 1 : null
  const assetSameUser = lastUserId === user_id
  const assetSameType = assetIsImage && currentGroupIsImage

  // first item => first group
  if (!lastItemCreatedAt) {
    return [groupItem(asset, user_name, user_id)]
  }

  // to stay in same group asset must be same user, same minute, same type
  if (assetSameUser && assetSameMinute && assetSameType) {
    if (assetIsImage) {
      memo[memo.length - 1].images.push(asset) // add asset to images
    } else {
      memo[memo.length - 1].assets.push(asset) // add asset to assets
    }
    memo[memo.length - 1].allAssets.push(asset) // add to size of group
  } else { // add new group
    memo.push(groupItem(asset, user_name, user_id))
  }

  return memo
}, [])

const loadDealAssetsSuccess = (state, action) => {
  const {
    payload: assets, // expect sorted assets by created_at
  } = action

  return {
    ...state,
    assetGroups: groupAssets(assets),
    rawAssets: assets,
    assets: {
      files: assets.filter(asset => !ImageTypeArray.includes(asset.type)),
      images: assets.filter(asset => ImageTypeArray.includes(asset.type)),
    },
    loading: false,
  }
}

const clearAssetUpload = state => {
  const upload = { ...initialState.upload }
  return { ...state, upload }
}

const uploadingDealAssets = state => {
  const upload = { ...state.upload, uploading: true, confirmingUpload: false }
  return {
    ...state,
    upload,
  }
}

const uploadDealAssetsSuccess = (state, action, withToastr = true) => {
  if (withToastr) {
    toastr.success('Assets Uploaded', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
  }

  const { payload } = action
  const assets = Array.isArray(payload) ? payload : [payload]

  const {
    files,
    images,
  } = state.assets

  const rawAssets = state.rawAssets.concat(assets)

  const upload = { ...initialState.upload }

  return {
    ...state,
    assets: {
      files: files.concat(assets.filter(asset => !ImageTypeArray.includes(asset.type))),
      images: images.concat(assets.filter(asset => ImageTypeArray.includes(asset.type))),
    },
    rawAssets,
    assetGroups: groupAssets(rawAssets),
    upload,
  }
}

const uploadDealAssetsFailure = state => {
  toastr.error('Failed to upload.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })

  const upload = {
    ...state.upload,
    uploading: false,
    confirmingUpload: false,
  }

  return { ...state, upload }
}

const updateAssets = (state, action) => merge({}, state, action.data)

const setCommentLoading = (state, status, parentId = null) => {
  state.commentLoading = {
    ...state.loading,
    isLoading: status,
    parentId,
  }
}

// Recursively finds first unread comment
const findFirstUnread = comments => {
  for (let i = 0, len = comments.length; i < len; i++) {
    const comment = comments[i]
    if (comment.is_unread) return comment.id
    if (comment.replies && comment.replies.length) {
      const unreadReplyId = findFirstUnread(comment.replies)
      if (unreadReplyId) return unreadReplyId
    }
  }
  return null
}

const loadDealAssetComments = (state, action, status = null) => {
  const newState = { ...state }

  switch (status) {
    case 'success':
      newState.comments = addReplies(action.payload || [])
      newState.firstUnreadComment = findFirstUnread(newState.comments)
      setCommentLoading(newState, false)
      break
    case 'failure':
      toastr.error('Could not load comments. Please refresh.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      setCommentLoading(newState, false)
      break
    case 'loading':
      newState.dealAttachmentId = Number(action.dealAttachmentId)
      setCommentLoading(newState, true)
      break
    default:
      break
  }

  return newState
}

//recursively adds an empty replies array to comments if missing
const addReplies = comments => {
  if (!Array.isArray(comments)) {
    comments.replies = addReplies(comments.replies || [])
    return comments
  }
  return comments.map(addReplies)
}

const addCommentToDealAssetComments = (comments, newComment) => {
  let parent = null
  if (newComment.parent_id) {
    parent = comments.find(comment => comment.id === newComment.parent_id)
    if (!parent) {
      return comments
    }
  }

  const siblingComments = (parent ? [...parent.replies] : comments) || []

  const commentIndex = siblingComments.findIndex(comment => comment.id === newComment.id)
  if (commentIndex > -1) {
    siblingComments[commentIndex] = newComment
  } else {
    siblingComments.push(newComment)
  }

  if (parent) {
    parent.replies = siblingComments
  }
  return comments
}

const addDealAssetComments = (state, action, status = null) => {
  const newState = { ...state }
  switch (status) {
    case 'success':
      newState.firstUnreadComment = action.payload.id
      newState.comments = addCommentToDealAssetComments([...newState.comments], addReplies(action.payload))
      setCommentLoading(newState, false)
      break
    case 'failure':
      toastr.error('Could not save the comment. Please refresh.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      setCommentLoading(newState, false)
      break
    case 'loading':
      setCommentLoading(newState, true, action.parentId || null)
      break
    default:
      return state
  }
  return newState
}

const deleteFromDealAssetComments = (comments, commentId) => {
  const commentIndex = comments.findIndex(comment => comment.id === commentId)
  if (commentIndex > -1) {
    comments.splice(commentIndex, 1)
  }
  return comments
}

const deleteDealAssetComment = (state, action, status) => {
  const newState = { ...state }
  switch (status) {
    case 'success':
      newState.comments = deleteFromDealAssetComments([...newState.comments], action.commentId)
      break
    case 'failure':
      toastr.error('Could not delete the comment. Please refresh.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      break
    default:
      break
  }

  return newState
}

export default function assetReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_DEAL_ASSETS':
      return loadingDealAssets(state, action)

    case 'LOAD_DEAL_ASSETS_SUCCESS':
      return loadDealAssetsSuccess(state, action)

    case 'LOAD_DEAL_ASSETS_FAILURE':
      return { ...state, loading: false }

    case 'LOAD_SINGLE_DEAL_COLLABORATION_ITEM': {
      const { deal_id, item_type, deal_attachment_id } = action.payload

      // If deal not currently loaded, ignore
      if (state.dealId && state.dealId !== deal_id) return state

      if (item_type === 'attachments') {
        return uploadDealAssetsSuccess(state, action, false)
      }

      if (item_type === 'attachmentComments') {
        if (state.dealAttachmentId && state.dealAttachmentId !== deal_attachment_id) return state
        return addDealAssetComments(state, action, 'success')
      }

      // If type unrecognized, ignore
      return state
    }

    case 'UPDATE_ASSET_ATTR':
      return updateAssets(state, action)

    case 'SET_UPLOAD_FILES':
      return setFiles(state, action)

    case 'TOGGLE_INCLUDE_UPLOAD_FILE':
      return toggleIncludeFile(state, action)

    case 'CLEAR_ASSET_UPLOAD':
      return clearAssetUpload(state, action)

    case 'EXIT_DEAL_ASSETS':
      return { ...initialState }

    case 'UPLOADING_DEAL_ASSETS':
      return uploadingDealAssets(state, action)

    case 'UPLOADING_DEAL_ASSETS_SUCCESS':
      return uploadDealAssetsSuccess(state, action)

    case 'UPLOADING_DEAL_ASSETS_FAILURE':
      return uploadDealAssetsFailure(state, action)

    case 'SWITCH_ASSET_TAB':
      return { ...state, selectedTab: action.tab }

    case 'LOAD_DEAL_ASSET_COMMENTS':
      return loadDealAssetComments(state, action, 'loading')
    case 'LOAD_DEAL_ASSET_COMMENTS_SUCCESS':
      return loadDealAssetComments(state, action, 'success')
    case 'LOAD_DEAL_ASSET_COMMENTS_FAILURE':
      return loadDealAssetComments(state, action, 'failure')

    case 'ADD_DEAL_ASSET_COMMENT':
      return addDealAssetComments(state, action, 'loading')
    case 'ADD_DEAL_ASSET_COMMENT_SUCCESS':
      return addDealAssetComments(state, action, 'success')
    case 'ADD_DEAL_ASSET_COMMENT_FAILURE':
      return addDealAssetComments(state, action, 'failure')
    case 'DELETE_DEAL_ASSET_COMMENT':
      return deleteDealAssetComment(state, action, 'loading')
    case 'DELETE_DEAL_ASSET_COMMENT_SUCCESS':
      return deleteDealAssetComment(state, action, 'success')
    case 'DELETE_DEAL_ASSET_COMMENT_FAILURE':
      return deleteDealAssetComment(state, action, 'failure')

    default:
      return state
  }
}
