import React from 'react'
import { object, array, func } from 'prop-types'
import IntegrationDetails from './integration-details'

class IntegrationModal extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    deal: object.isRequired,
    dealIntegrations: array.isRequired,
    dealIntegrationActions: object.isRequired,
    match: object.isRequired,
    closeIntegration: func.isRequired,
  }

  componentWillMount() {
    const {
      deal,
      match: {
        params: { key: selectedIntegration },
      },
      dealIntegrations: {
        dealIntegration: integration,
      },
      dealIntegrationActions: {
        loadIntegration,
      },
    } = this.props

    if (selectedIntegration && selectedIntegration !== integration.integration_type) {
      loadIntegration(deal.id, selectedIntegration)
    }
  }

  render() {
    const {
      currentBrand,
      deal,
      dealIntegrations: {
        dealIntegration: integration,
        campaignsLoading,
        campaignsReady,
      },
      closeIntegration,
    } = this.props

    if (!integration || !integration.id) return null

    return (
      <IntegrationDetails
        currentBrand={currentBrand}
        deal={deal}
        integration={integration}
        campaignsLoading={campaignsLoading}
        campaignsReady={campaignsReady}
        closeIntegration={closeIntegration}
      />
    )
  }
}

export default IntegrationModal
