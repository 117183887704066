import React from 'react';
import * as ReactRedux from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import Dialog from 'react-toolbox/lib/dialog';

import Field from 'visual-components/util/inputs/field';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import Validator from 'util/form-validator';
import AppDispatcher from 'dispatchers/app-dispatcher';

import * as ContactSupportActions from 'actions/contact-support-actions';

import DialogTheme from 'css/themes/dialogs/formModal.css'
import InputTheme from 'css/themes/Input.css'

const validations = {
  name: [Validator.required],
  email: [Validator.required],
  subject: [Validator.required],
  message: [Validator.required]
}

const themes = {
  Dialog: DialogTheme,
  Input: InputTheme,
}

var mapState = function (state) {
  return {
    profile: state.profile,
    brand: _.get(state, 'currentBrand.accountname', null),
    contactSupport: state.contactSupport,
    contactSupportForm: state.contactSupportForm
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ContactSupportActions, dispatch)
})

class ContactSupportModal extends React.Component {
  constructor(props) {
    super(props);

    this.hideModal = this.hideModal.bind(this);
    this.contactSupport = this.contactSupport.bind(this);
  }

  hideModal() {
    this.props.actions.closeModal();
  }

  contactSupport() {
    var {
      brand,
      contactSupport,
      contactSupportForm
    } = this.props;

    var that = this;
    Validator.validateForm(validations, contactSupport, 'contactSupportForm', function (valid) {
      if (valid) {
        AppDispatcher.http({
          url: '/zendesk-contact-support',
          method: 'POST',
          data: {
            brand,
            name: contactSupport.name,
            email: contactSupport.email,
            subject: contactSupport.subject,
            message: contactSupport.message
          },
          success: function () {
            that.hideModal();
            toastr.success('Your message has been sent.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
          }
        })
      }
    })
  }

  render() {
    var {
      profile,
      currentBrand,
      contactSupport,
      contactSupportForm,
    } = this.props;

    return(
      <Dialog
        theme={ themes.Dialog }
        active={ !!contactSupport.modalOpen }
        onEscKeyDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
        title={ `Contact Support` }
      >
        <img
          onClick={ this.hideModal }
          className={ themes.Dialog.closeImage }
          src="/images/icons/close.svg"
        />

        <Field label={ 'Contact Name*' }
          attrName={ 'name' }
          dataModel={ contactSupport }
          formModel={ contactSupportForm }
          validations={ validations.name }
        />

        <Field label={ 'Contact Email*' }
          attrName={ 'email' }
          type={ 'email' }
          dataModel={ contactSupport }
          formModel={ contactSupportForm }
          validations={ validations.email }
        />

        <Field label={ 'Subject Line*' }
          attrName={ 'subject' }
          dataModel={ contactSupport }
          formModel={ contactSupportForm }
          validations={ validations.subject }
        />

        <Field label={ 'Message*' }
          attrName={ 'message' }
          multiline={ true }
          rows={ 10 }
          placeholder={ 'Type your message here' }
          dataModel={ contactSupport }
          formModel={ contactSupportForm }
          validations={ validations.message }
          className={ themes.Input.textarea }
        />

        <ModalButtonGroup
          canSave={ true }
          confirm={ this.contactSupport }
          confirmText={ 'Send Message' }
          cancel={ this.hideModal }
        />
      </Dialog>
    )
  }
}

export default ReactRedux.connect(mapState, mapDispatchToProps)(ContactSupportModal);
