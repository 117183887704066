import { Component } from 'react'
import moment from 'moment-timezone'

export class NotStartedWarning extends Component {
  render() {
    const { startDate } = this.props

    let startDateFormatted = moment(startDate).format('MMMM DD, YYYY')

    return (
      <div className={`hide postentry_modal__modal-overlay not-started-warning-modal`} id="not-started-warning">
        <div className={`postentry_modal__modal`} id="modal2">
          <div className="postentry_modal__modal-guts">
            <p className="header">
              This Campaign Has Not Started
            </p>
            <div
              className={`bannerWrapper orangeBanner`}
            >
              <img src="/images/icons/error-message-icon.svg" />
            </div>
            <p className="description">
              Oops! This campaign hasn't started yet, come back after <span className="start">{startDateFormatted}</span> to enter!
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default NotStartedWarning
