import React from 'react';
import appbarTheme from 'css/themes/homePage/appbarTheme.css';
import OpenSearchBar from './open-search-bar';
import ClosedSearchBar from './closed-search-bar';
import { bool } from 'prop-types';

class MenuSearchBar extends React.Component {
  static propTypes = {
    lightBackground: bool
  }

  state = {
    open: false
  }

  onMagClick = () => {
    this.setState({
      open: true
    })
  }

  onXClick = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {
      lightBackground
    } = this.props;

    let {
      open
    } = this.state;

    return(
      <div className={ `${appbarTheme.searchBarWrapper} ${ open ? appbarTheme.open : appbarTheme.closed}` }>
        { open
          ? <OpenSearchBar
              onclick={ this.onXClick }
            />
          : <ClosedSearchBar
              onclick={ this.onMagClick }
              lightBackground={ lightBackground }
            />
        }
      </div>
    )
  }
}

export default MenuSearchBar;
