import React from 'react'
import PropTypes from 'prop-types'
import theme from 'css/themes/Checkbox.css'

const IndeterminateCheckbox = props => {
  const {
    checked,
    label,
    name,
    onChange,
    some,
  } = props

  const id = label.replace(' ', '_')

  return (
    <span className={theme.spanWrapper}>
      <input
        id={id}
        className={`${theme.check} ${theme.indeterminateCheck}`}
        name={name}
        type="checkbox"
        ref={el => el && (el.indeterminate = some)}
        onChange={onChange}
        checked={checked}
      />
      <label
        htmlFor={id}
        className={`${theme.text} ${theme.checkAllLabel}`}
      >
        {label}
      </label>
    </span>
  )
}

IndeterminateCheckbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  some: PropTypes.bool,
  onChange: PropTypes.func,
}

IndeterminateCheckbox.defaultProps = {
  checked: false,
  label: '',
  name: 'indeterminate-checkbox',
  some: false,
  onChange() {},
}

export default IndeterminateCheckbox
