import React, { Component, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import * as metrics from 'util/metrics'

const ViewMetrics = props => {
  const {
    location,
  } = props

  useEffect(() => {
    metrics.create('view', {
      url: window.location.href,
    })
  }, [location.pathname])

  return null
}

export default withRouter(ViewMetrics)
