import React from 'react';
import { array } from 'prop-types';
import COLOURS from 'util/colours';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import QuestCard from './quest-card';
import QuestPercentCompleted from './quest-percent-completed';
import { H3, H4 } from 'visual-components/util/texts';

class OpenQuestBarContent extends React.Component {
  static propTypes = {
    quests: array.isRequired
  }

  render() {
    const {
      quests
    } = this.props;

    const isNotCompleted = (quest) => {
      return quest.completed === false;
    };

    const completedQuestsCount = quests.filter( quest => quest.completed === true ).length;

    return(
      <div style={{
        paddingBottom: '24px'
      }}>
        <Grid fluid style={{
          width: '100%'
        }}>
          {/* header stuff */}
          <Row>
            <Col xs={11}>
              <Row>
                <Col xs>
                  <div style={{
                    paddingBottom: '16px',
                    paddingTop: '8px'
                  }}>
                    <H3 coral>
                      <small>
                        Complete your Brand Profile
                      </small>
                    </H3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs>
                  <div style={{
                    paddingBottom: '24px'
                  }}>
                    <H4>
                      Welcome! Share a bit about your brand with potential partners in the DojoMojo network. <a href="https://help.dojomojo.com/hc/en-us/articles/115001456851-Complete-Your-Brand-Profile" target="_blank">Learn More</a>
                    </H4>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={1}>
              <QuestPercentCompleted
                completedCount={ completedQuestsCount }
                totalCount={ quests.length }
              />
            </Col>
          </Row>
          {/* card content */}
          <Row>
            <Col xs>
              <QuestCard quests={quests} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
};

export default OpenQuestBarContent;
