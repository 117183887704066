import React, { Component } from 'react';
import theme from 'css/themes/homePage/footer.css';
import EmailCollector from '../util/email-collector';

class ReadyToGrow extends React.Component {
  render() {
    return(
      <div className={ theme.readyToGrow }>
        <div className={ theme.container }>
          <p className={ theme.title }>
            Ready to grow?
          </p>
        </div>
        <div className={ theme.container }>
          <img alt="emailgrowth" src='/images/login/homepage/shared/mail-and-running-man.svg' />
          <div className={ theme.middle }>
            <div className={ theme.describe }>
              Try it free for 30 days, no credit card required.
            </div>
            <EmailCollector buttonText={ 'Start Free Trial' } located={ 'Footer' }/>
          </div>
          <img alt="analytics" src='/images/login/homepage/shared/analytics-symbol.svg' />
        </div>
      </div>
    )
  }
}

export default ReadyToGrow;
