import React from 'react';
import PropTypes from 'prop-types';

import {IconButton} from 'react-toolbox/lib/button';

import IconButtonTheme from 'css/themes/IconButton.css'

class EditPen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      onClick,
    } = this.props;

    return (
      <div style={{
        display: 'inline-flex',
        position: 'absolute',
        marginRight: '16px',
        marginBottom: '12px',
        top: '8px',
        right: '-8px'
      }}>
        <IconButton
          onClick={ onClick }
          icon="edit"
          theme={ IconButtonTheme }
        />
      </div>
    );
  }
};

EditPen.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EditPen;
