import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Spinner from 'util/components/spinner'
import smsTheme from 'css/themes/sms-marketing.css'

import MobileMarketing from './MobileMarketing'
import {
  PublishModal,
  UnpublishWarningModal,
  RequestTrafficReminderModal,
  SaveChangesLiveModal,
  DiscardChangesModal,
  // NoMessagesWarningModal,
  LeaveWarningModal,
  ReminderToPublishModal,
  SendTestSmsModal,
  DeleteFollowupsModal,
  TemplateSelectionModal,
  UpdatePostEntryWarningModal,
  ShortUrlWarningModal,
} from './modals'

import { MobileMarketingContext } from './MobileMarketingContext'

const mapState = ({
  currentCampaign,
  currentBrand,
}) => ({
  brandName: currentBrand.accountname,
  brandWebsite: currentBrand.website,
  currentCampaign,
  currentBrand,
})

const TOASTR_OPTIONS = { timeOut: 3000, positionClass: 'toast-bottom-center' }

class MobileMarketingContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.publishButtonRef = React.createRef()
  }

  sendTest = testPhoneNumber => {
    const { sendSmsTest, testData } = this.context
    const { custom_sms_message, coupon_code } = testData

    const containsCoupon = /\*\|COUPON(_CODE)?\|\*/.test(custom_sms_message)
    const validPhoneNumber = testPhoneNumber.replace(/\D/g, '').length === 10

    let valid = validPhoneNumber

    if (containsCoupon && !coupon_code) valid = false

    if (!valid) {
      toastr.error('Please, check the settings before sending a test message.', null, TOASTR_OPTIONS)
      return
    }

    sendSmsTest(testPhoneNumber)
  }

  // TODO: Is this needed anymore?
  // saveAndPurchaseMessages = () => {
  //   const { currentBrand } = this.props
  //   const isTrial = isTrialingAndNotUpgraded(currentBrand)


  //   this.save()
  //   if (isTrial || currentBrand.belt === 'white') {
  //     BrowserHistoryDispatcher.push('/profile/brand/billing')
  //   } else {
  //     BrowserHistoryDispatcher.push('/manage-sms-marketing')
  //   }
  // }

  previewPostEntry = () => {
    const {
      currentCampaign: { uid },
      currentBrand: { id: brandId },
    } = this.props

    window.open(`/landing/post-page/${uid}/${brandId}?preview=true`, '_blank')
  }

  render() {
    const {
      currentCampaign: { startDate, id, isSolo },
      setMobileMarketingState,
      isWelcomeMessage,
    } = this.props

    const { loading, saving } = this.context

    if (loading) return <Spinner needsBackground />

    return (
      <div className={isWelcomeMessage ? '' : smsTheme.contentContainer}>
        <MobileMarketing
          {...this.props}
          sendTest={this.sendTest}
          previewPostEntry={this.previewPostEntry}
          publishButtonRef={this.publishButtonRef}
        />
        {/* Modals */}
        <UnpublishWarningModal />
        <RequestTrafficReminderModal setMobileMarketingState={setMobileMarketingState} />
        <PublishModal isSolo={isSolo} />
        <SaveChangesLiveModal />
        <LeaveWarningModal />
        <ReminderToPublishModal
          campaignStartDate={startDate}
          campaignId={id}
          publishButtonRef={this.publishButtonRef}
        />
        <SendTestSmsModal confirm={this.sendTest} />
        <DeleteFollowupsModal />
        <DiscardChangesModal />
        <TemplateSelectionModal />
        <UpdatePostEntryWarningModal />
        { saving && <Spinner needsBackground /> }
        <ShortUrlWarningModal />
        {/* TODO: Remove? */}
        {/* <NoMessagesWarningModal
          show={modals.outOfMessages}
          cancel={hideOutOfMessagesModal}
          purchase={this.saveAndPurchaseMessages}
        /> */}
      </div>
    )
  }
}

MobileMarketingContainer.contextType = MobileMarketingContext

MobileMarketingContainer.propTypes = {
  currentCampaign: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  setIsSmsTrafficPublished: PropTypes.func.isRequired,
  setMobileMarketingState: PropTypes.func.isRequired,
  isWelcomeMessage: PropTypes.bool,
}

MobileMarketingContainer.defaultProps = {
  isWelcomeMessage: false,
}

export default connect(mapState)(props => <MobileMarketingContainer {...props} />)
