import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { debounce } from 'lodash'
import moment from 'moment'
import Card from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import {
  P, H2, H3, H5,
} from 'visual-components/util/texts'
import { SimpleForm, Input, Select } from 'visual-components/util/form'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import SearchCard from 'visual-components/shared/search-card'
import AppDispatcher from 'dispatchers/app-dispatcher'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import {
  beltMapping, couponMapping, getPlanLabel, getPreferredTermPlans,
} from 'util/brand-stripe-belt-helper'
import COLOURS from 'util/colours'
import ButtonTheme from 'css/themes/Buttons.css'
import CancelPlanModal from './cancel-plan-modal'
import CSAdminBrandSMSPlan from './customer-success-admin-brand-sms-plan'

const styles = {
  container: {
    padding: 40,
  },
  heading: {
    marginBottom: 24,
  },
  searchContainer: {
    width: '50%',
  },
  searchCard: {
    marginBottom: 16,
  },
  searchResults: {
    padding: 16,
    maxHeight: 300,
    overflow: 'scroll',
    border: `1px solid ${COLOURS.silver}`,
  },
  searchResult: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    borderBottom: `1px solid ${COLOURS.silver}`,
    cursor: 'pointer',
    '&:first-child': {
      borderTop: `1px solid ${COLOURS.silver}`,
    },
  },
  searchResultLogo: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    width: 48,
    minWidth: 48,
    marginRight: 24,
    '& img': {
      maxHeight: 48,
      maxWidth: 48,
      margin: 'auto',
    },
  },
  brandContainer: {
    padding: 16,
    overflow: 'visible',
  },
  brandTable: {
    margin: '24px 0px',
    '& thead': {
      borderBottom: `1px solid ${COLOURS.silver}`,
    },
    '& td': {
      paddingTop: 16,
      '& h5': {
        marginBottom: 4,
      },
    },
  },
  preferredTermsContainer: {
    marginTop: 24,
    padding: 16,
    overflow: 'visible',
  },
  preferredTermsTable: {
    margin: '8px 0px 24px',
    '& thead': {
      borderBottom: `1px solid ${COLOURS.silver}`,
    },
    '& td': {
      paddingTop: 16,
      '& h5': {
        marginBottom: 4,
      },
    },
  },
  preferredTermsInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div': {
      width: 'calc(100% - 8px)',
    },
  },
}

let PLAN_TYPES = []
let DISCOUNT_TYPES = []

function CSAdminBrandManager(props) {
  const { classes } = props

  const [brand, setBrand] = useState(null)
  const [searchValue, setSearchValue] = useState(null)
  const [searchResults, setSearchResults] = useState([])

  // Current Plan
  const [cancelPlanModalActive, setCancelPlanModalActive] = useState(false)

  // Preferred Term Plans
  const [issuedPreferredTermsPlan, setIssuedPreferredTermsPlan] = useState({})
  const [preferredTermsActive, setPreferredTermsActive] = useState(false)

  useEffect(() => {
    PLAN_TYPES = getPreferredTermPlans().map(([key, entry]) => ({ value: key, label: entry.label }))

    AppDispatcher.http({
      url: '/coupons',
      method: 'GET',
      success: ({ data }) => {
        DISCOUNT_TYPES = data.filter(({ valid }) => valid).map(({
          id, name, amount_off, percent_off, duration, duration_in_months,
        }) => ({ value: id, label: `${name || id} --- ${amount_off ? `$${amount_off / 100}` : `${percent_off}%`} off ${duration === 'repeating' ? `for ${duration_in_months} months` : duration}` }))
          .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      },
    })
  }, [preferredTermsActive])

  useEffect(() => {
    if (issuedPreferredTermsPlan.plan_id) {
      const selectedB3G1Plan = beltMapping[issuedPreferredTermsPlan.plan_id].b3g1
      const b3g1Coupon = couponMapping(issuedPreferredTermsPlan.plan_id)
      if (selectedB3G1Plan) {
        updateIssuedPreferredTermsPlan({ discount_id: b3g1Coupon })
      }
      DISCOUNT_TYPES = DISCOUNT_TYPES.map(discount =>
        ({
          ...discount,
          disabled: selectedB3G1Plan && discount.value !== b3g1Coupon,
        }))
    }
  }, [issuedPreferredTermsPlan.plan_id])

  const search = debounce(value => {
    const query = {
      bool: {
        filter: {
          bool: {
            should: [{
              wildcard: {
                accountname: `*${value.toLowerCase().replace(/\s/g, '*')}*`,
              },
            }],
          },
        },
      },
    }

    if (parseInt(value, 10) > 0) {
      query.bool.filter.bool.should.push({
        term: {
          id: parseInt(value, 10),
        },
      })
    }

    AppDispatcher.http({
      url: '/search/',
      method: 'POST',
      data: {
        excludeDegree: true,
        size: 100,
        _source: ['id', 'accountname', 'logo'],
        query,
      },
      success: data => setSearchResults(data.hits.hits),
    })
  }, 500)

  const updateSearch = value => {
    setSearchValue(value)
    search(value)
  }

  const chooseBrand = brandId => {
    setSearchValue('')
    setSearchResults([])
    AppDispatcher.http({
      url: `/cs-manage-brand-lookup/${brandId}`,
      method: 'GET',
      success: data => setBrand(data),
    })
  }

  const cancelPlan = term => {
    setCancelPlanModalActive(false)
    AppDispatcher.http({
      url: `/current-plan/${brand.id}`,
      method: 'DELETE',
      data: {
        term,
      },
      success: () => {
        if (term === 'plan-term') {
          toastr.success('Current Plan set to cancel at end of Plan Term', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
        } else if (term === 'billing-term') {
          toastr.success('Current Plan set to cancel at end of Billing Term', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
        } else if (term === 'immediately') {
          toastr.success('Current Plan Canceled!', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
          setBrand({
            ...brand,
            belt: 'white',
            stripe_belt: 'white',
            next_invoice_at: null,
            renewal_date: null,
          })
        }
      },
      error: error => toastr.error(error.responseText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' }),
    })
  }

  const updateIssuedPreferredTermsPlan = data => {
    setIssuedPreferredTermsPlan({ ...issuedPreferredTermsPlan, ...data })
  }

  const submitIssuedPreferredTermsPlan = data => {
    setIssuedPreferredTermsPlan({})
    setPreferredTermsActive(false)
    AppDispatcher.http({
      url: `/cs-manage-brand-preferred-terms/${brand.id}`,
      method: 'POST',
      data,
      success: preferredTermsPlan => {
        toastr.success('Preferred Terms Plan Issued!', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
        setBrand({ ...brand, preferredTermsPlan })
      },
    })
  }

  const revokePreferredTermPlan = () => {
    ConfirmationDispatcher.check({
      header: 'Please Confirm',
      copy: 'Are you sure you want to revoke this plan?',
      confirmText: 'Revoke',
      action: () => {
        AppDispatcher.http({
          url: `/cs-manage-brand-preferred-terms/${brand.id}`,
          method: 'DELETE',
          success: () => {
            toastr.success('Preferred Terms Plan Revoked!', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
            setBrand({ ...brand, preferredTermsPlan: null })
            ConfirmationDispatcher.closeModal()
          },
        })
      },
    })
  }

  const renderSearchResults = () =>
    searchResults.map(({ _source: result }) => (
      <div className={classes.searchResult} onClick={() => chooseBrand(result.id)}>
        <div className={classes.searchResultLogo}>
          <img src={result.logo} />
        </div>
        <H3>{result.accountname}</H3>
      </div>
    ))

  const preferredTermsPlan = brand && brand.preferredTermsPlan
  const isTrialing = brand && moment(brand.trial_end).isAfter(moment())
  const canceledPlan = preferredTermsPlan && preferredTermsPlan.accepted_at && preferredTermsPlan.plan_id !== brand.stripe_belt

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <H2 azure>
          CS Admin - Brand Manager
        </H2>
      </div>
      <div className={classes.searchContainer}>
        <div className={classes.searchCard}>
          <SearchCard
            placeholder="Search for a brand by Account Name or ID"
            value={searchValue}
            onChange={updateSearch}
          />
        </div>
        {!!searchResults.length && (
          <Card className={classes.searchResults}>
            {renderSearchResults()}
          </Card>
        )}
      </div>
      {!!brand && (
        <>
          <Card className={classes.brandContainer}>
            <H3 coral>{brand.accountname}</H3>
            <table className={classes.brandTable}>
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Current Plan</th>
                  <th>{isTrialing ? 'Trial End Date' : 'Next Bill Date'}</th>
                  <th>Renewal Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <H5>{brand.accountname}</H5>
                    <P>{`ID: ${brand.id}`}</P>
                  </td>
                  <td>
                    <P>{getPlanLabel(brand.stripe_belt)}</P>
                  </td>
                  <td>
                    {isTrialing
                      ? (
                        <P>{moment(brand.trial_end).format('MM/DD/YYYY')}</P>
                      ) : (
                        <P>{brand.next_invoice_at ? moment(brand.next_invoice_at).format('MM/DD/YYYY') : 'N/A'}</P>
                      )}
                  </td>
                  <td>
                    <P>{brand.renewal_date ? moment(brand.renewal_date).format('MM/DD/YYYY') : 'N/A'}</P>
                  </td>
                </tr>
              </tbody>
            </table>
            {!['trial', 'post-trial-paywall', 'white'].includes(brand.stripe_belt) && (
              <div>
                <Button
                  onClick={() => setCancelPlanModalActive(true)}
                  theme={ButtonTheme}
                  className={ButtonTheme.whiteButton}
                  label="Cancel Plan"
                />
              </div>
            )}
          </Card>
          {
            brand.brandBank && (
              <CSAdminBrandSMSPlan
                brandBank={brand.brandBank}
                brandId={brand.id}
                refreshBrand={() => chooseBrand(brand.id)}
              />
            )
          }
          <Card className={classes.preferredTermsContainer}>
            {!!preferredTermsPlan && (
              <>
                <H5 azure>Current Preferred Terms Plan</H5>
                <table className={classes.preferredTermsTable}>
                  <thead>
                    <tr>
                      <th>Issued Plan</th>
                      <th>Issued Discount</th>
                      <th>Issued By</th>
                      <th>Issued On</th>
                      <th>{preferredTermsPlan.accepted_at ? 'Accepted On' : 'Expires On'}</th>
                      {moment(preferredTermsPlan.effective_date).isAfter(moment()) && (
                        <th>Effective Date</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <P>{preferredTermsPlan.plan_label}</P>
                      </td>
                      <td>
                        <P>{preferredTermsPlan.discount_label}</P>
                      </td>
                      <td>
                        <P>{preferredTermsPlan.issued_by.name}</P>
                      </td>
                      <td>
                        <P>{moment(preferredTermsPlan.created_at).format('MM/DD/YYYY')}</P>
                      </td>
                      <td>
                        <P>{moment(preferredTermsPlan.accepted_at ? preferredTermsPlan.accepted_at : preferredTermsPlan.expiration_date).format('MM/DD/YYYY')}</P>
                      </td>
                      {moment(preferredTermsPlan.effective_date).isAfter(moment()) && (
                        <td>
                          <P>{moment(preferredTermsPlan.effective_date).format('MM/DD/YYYY')}</P>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            {!preferredTermsActive && (
              <div>
                {preferredTermsPlan
                  && (
                    !preferredTermsPlan.effective_date
                    || moment().isBefore(moment(preferredTermsPlan.effective_date))
                  ) && (
                    <Button
                      onClick={revokePreferredTermPlan}
                      theme={ButtonTheme}
                      className={ButtonTheme.whiteButton}
                      label="Revoke Plan"
                    />
                )}
                {(!preferredTermsPlan || canceledPlan) && (
                  <Button
                    onClick={() => setPreferredTermsActive(true)}
                    theme={ButtonTheme}
                    className={ButtonTheme.blueButton}
                    label="Issue Preferred Terms Plan"
                  />
                )}
              </div>
            )}
            {preferredTermsActive && (
              <>
                <SimpleForm
                  model={issuedPreferredTermsPlan}
                  updateModel={updateIssuedPreferredTermsPlan}
                  handleSubmit={submitIssuedPreferredTermsPlan}
                >
                  <div className={classes.preferredTermsInputs}>
                    <Select
                      name="plan_id"
                      label="Plan"
                      options={PLAN_TYPES}
                      required
                    />
                    <Select
                      name="discount_id"
                      label="Discount"
                      options={DISCOUNT_TYPES}
                      required
                    />
                    <Input
                      type="date"
                      name="expiration_date"
                      label="Expiration Date"
                      initialValue={moment().add(30, 'days').format('YYYY-MM-DD')}
                    />
                  </div>

                  <ModalButtonGroupWithContext
                    cancel={() => setPreferredTermsActive(false)}
                    cancelText="Cancel"
                    confirmText="Issue Plan"
                    canSave
                    hideLine
                  />
                </SimpleForm>
              </>
            )}
          </Card>
          <CancelPlanModal
            active={cancelPlanModalActive}
            brand={brand}
            cancelPlan={cancelPlan}
            closeModal={() => setCancelPlanModalActive(false)}
          />
        </>
      )}
    </div>
  )
}

CSAdminBrandManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CSAdminBrandManager)
