import React, { Component } from 'react'

import { H6 } from 'visual-components/util/texts'

class BonusOptionItem extends Component {
  render() {
    const { item: { key, label, colour }, addBonusItem } = this.props

    return (
      <div onClick={ e => addBonusItem(key) }
        style={{
          width: '229px',
          height: '40px',
          borderRadius: '2px',
          backgroundColor: colour,
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
          marginBottom: '12px',
          padding: '13px 16px',
          cursor: 'pointer'
      }}>
        <div style={{
          display: 'inline-block'
        }}>
          <H6 white={ key !== 'snapchatFollow' }>
            { label }
          </H6>
        </div>
        <i className={ 'fa fa-plus-circle pull-right' }
          aria-hidden="true"
          style={{
            color: COLOURS.white,
            fontSize: '16px'
        }}/>
      </div>
    )
  }
}

export default BonusOptionItem
