import React, { Component } from 'react';
import { string, node } from 'prop-types';

import theme from 'css/themes/mobile/home/banner.css';

class BannerBase extends Component {
  static propTypes = {
    title: string.isRequired,
    imgSrc: string.isRequired,
    about: string,
    children: node
  }

  render() {
    let {
      title,
      imgSrc,
      about,
      children
    } = this.props;

    const [imgName, extension] = imgSrc.split('.')

    const srcSet = extension == 'svg' ? null : `
      ${imgName}.${extension},
      ${imgName}@2x.${extension} 2x,
      ${imgName}@3x.${extension} 3x
    `

    return(
      <div className={ theme.bannerContainer }>
        <div className={ theme.imgContainer }>
          <img alt={title} src={ imgSrc } srcSet={srcSet} />
        </div>
        <div className={ theme.textContainer }>
          <div className={ theme.title }>
            { title }
          </div>
          <div className={ theme.about }>
            { about }
          </div>
        </div>
        <div className={ theme.children }>
          { children }
        </div>
      </div>
    )
  }
};

export default BannerBase;
