import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import MobileMarketingContainer from 'visual-components/mobile-marketing/MobileMarketingContainer'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import PartnershipInvitesDispatcher from 'dispatchers/partnership-invites-dispatcher'
import COLOURS from 'util/colours'
import WelcomeMessageEmptyState from './WelcomeMessageEmptyState'

const mapStateToProps = ({ currentBrand, currentCampaign }) =>
  ({ currentBrand, currentCampaign })

const styles = {
  container: {
    marginTop: 32,
    padding: 20,
    backgroundColor: 'white',
    border: `1px solid ${COLOURS.silver}`,
    borderRadius: 2,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  emptyState: {
    paddingTop: 20,
  },
}

class WelcomeMessage extends React.Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    currentCampaign: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  enableSmsOptin = () => {
    CampaignDispatcher.updateAttrPersist('showSmsOptin', true)
  }

  enableSmsPostEntry = () => {
    PartnershipInvitesDispatcher.updateAttrPersist('ctaType', 'mobileMarketing')
  }

  render() {
    const {
      currentBrand,
      currentCampaign,
      classes,
    } = this.props

    const invite = currentCampaign.invites.find(i => i.invitee_id === currentBrand.id)
    const hasActiveSms = currentCampaign.showSmsOptin || invite.ctaType === 'mobileMarketing'

    if (hasActiveSms) {
      return (
        <div className={classes.container}>
          <MobileMarketingContainer
            isHost
            isWelcomeMessage
            setIsSmsTrafficPublished={() => {}}
            setMobileMarketingState={() => {}}
          />
        </div>
      )
    }

    return (
      <div className={classes.emptyState}>
        <WelcomeMessageEmptyState
          enableSmsOptin={this.enableSmsOptin}
          enableSmsPostEntry={this.enableSmsPostEntry}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(injectSheet(styles)(WelcomeMessage))
