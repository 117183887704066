import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { string, object, func } from 'prop-types'
import { P } from 'visual-components/util/texts'
import DefaultDialog from 'visual-components/util/modals/default-dialog'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import COLOURS from 'util/colours'
import * as ContactSupportActions from 'actions/contact-support-actions'

const mapDispatchToProps = dispatch => ({
  contactSupportActions: bindActionCreators(ContactSupportActions, dispatch),
})

class CollaborateStatusDialog extends React.PureComponent {
  static propTypes = {
    currentBrand: object.isRequired,
    deal: object.isRequired,
    dialogActive: string.isRequired,
    counterDeal: func.isRequired,
    declineDeal: func.isRequired,
    setDialog: func.isRequired,
    contactSupportActions: object.isRequired,
  }

  render() {
    const {
      currentBrand,
      deal: {
        selling_brand_id,
        selling_brand,
        buying_brand,
      },
      dialogActive,
      counterDeal,
      declineDeal,
      setDialog,
      contactSupportActions,
    } = this.props

    if (!dialogActive) return null

    const isSeller = currentBrand.id === selling_brand_id

    const statusMap = {
      created: {
        title: "We've Sent Your Offer!",
        img: '/images/icons/coin-gate-v-2.svg',
        color: COLOURS.seaGreen,
        content: "Your offer is on its way. We'll notify you as soon as a response is received. Return to this page to view its status.",
        confirm: setDialog,
        confirmText: 'Got It',
      },
      accepted: {
        title: 'Offer Accepted',
        img: '/images/icons/high-five-icon.svg',
        color: COLOURS.azure,
        content: 'Congrats! Once the campaign is live, return to the Analytics tab here to track clicks and performance.',
        confirm: setDialog,
        confirmText: 'Close',
      },
      negotiate: {
        title: 'Open to Negotiating?',
        img: '/images/icons/warning-megaphone.svg',
        color: COLOURS.lightPeach,
        content: 'Want to negotiate this deal instead of declining it? Propose a counter-offer with an alternate media type, price structure, or pricing.',
        confirm: counterDeal,
        confirmText: 'Make Counter-Offer',
        cancel: () => setDialog('decline'),
        cancelText: 'Decline',
      },
      counter: {
        title: 'Your Offer Was Sent',
        img: '/images/icons/paper-plane-circle-icon.svg',
        color: COLOURS.easterGreen,
        content: `We've sent your counter-offer to ${isSeller ? buying_brand.accountname : selling_brand.accountname} and will let you know as soon as they respond. They'll have 3 business days to respond before it expires. Return here anytime before they respond to edit your offer.`,
        confirm: setDialog,
        confirmText: 'Got It',
      },
      decline: {
        title: 'Are You Sure You Want to Decline?',
        img: '/images/icons/warning-megaphone.svg',
        color: COLOURS.lightPeach,
        content: `If you decline, we'll let ${isSeller ? buying_brand.accountname : selling_brand.accountname} know. To continue working with them on a Media Sale, a new offer will need to be created.`,
        confirm: declineDeal,
        confirmText: 'Decline',
        cancel: setDialog,
        cancelText: 'Cancel',
      },
      error: {
        title: 'Deal Error',
        content: (
          <span>
            {"We're sorry! Our system isn't able to accept this deal. Please contact "}
            <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
            {" and we'll get right on it."}
          </span>
        ),
        confirm: contactSupportActions.openModal,
        confirmText: 'Contact Support',
        cancel: setDialog,
        cancelText: 'Cancel',
      },
    }

    const status = statusMap[dialogActive]

    if (!status) return null

    return (
      <div>
        <DefaultDialog
          active={!!dialogActive}
          closeModal={setDialog}
          title={status.title}
          confirm={status.confirm}
          confirmText={status.confirmText}
          cancel={status.cancel}
          cancelText={status.cancelText}
          children={(
            <div>
              { status.img
              && (
                <div
                  className={dialogTheme.bannerWrapper}
                  style={{ backgroundColor: status.color }}
                >
                  <img src={status.img} />
                </div>
              )
              }
              <P multiline>
                {status.content}
              </P>
            </div>
          )}
        />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(CollaborateStatusDialog)
