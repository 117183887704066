import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { number, string, object, shape, func } from 'prop-types'
import { SimpleForm } from 'visual-components/util/form'
import moment from 'moment-timezone'
import Spinner from 'util/components/spinner'
import AppDispatcher from 'dispatchers/app-dispatcher'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import * as DealActions from 'actions/deal-actions'
import FullscreenModal from 'visual-components/util/modals/FullscreenModal'
import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import queryString from 'query-string'
import BrandDetailDispatcher from 'dispatchers/brand-detail-dispatcher';
import EnterMediaInfo from './enterMediaInfo'
import SelectDealType from './selectDealType'
import CPCPricingInfo from './cpcPricingInfo'
import FixedPricingInfo from './fixedPricingInfo'
import EditStatusBar from './StatusBar'

const mapStateToProps = ({
  deals,
  sellMediaModal,
  currentBrand: { timezone, id: currentBrandId },
  brand,
}) => ({
  deals,
  timezone,
  currentBrandId,
  sellMediaModal,
  brand,
})

const mapDispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
  brandActions: BrandDetailDispatcher,
})

const mediaTypes = ['sponsored_email', 'dedicated_email', 'blog_post', 'banner_ad', 'social_media_post', 'instagram_story']

class CreateNewMedia extends PureComponent {
  static propTypes = {
    currentBrandId: number.isRequired,
    deals: object.isRequired,
    dealActions: object.isRequired,
    history: object.isRequired,
    match: object.isRequired,
    timezone: string,
    location: object.isRequired,
    brand: object.isRequired,
    brandActions: shape({
      loadBrand: func.isRequired,
    }).isRequired,
  }

  state = {
    focusedInput: null,
    searchInFocus: false,
    loadingBrand: false,
    selectedBrand: {},
  }

  componentWillMount() {
    const {
      deals: { deal, editProposalActive },
      dealActions: { loadById, updateAttr, clearActionSuccess },
      match: { params: { dealId, brandId } },
      location,
      brandActions: {
        loadBrand,
      },
    } = this.props
    const urlParams = queryString.parse(location.search)

    if (editProposalActive === 'edit' && !dealId) {
      updateAttr({ editProposalActive: null })
    }

    if (dealId && (!deal || !Object.keys(deal).length || deal.status === 'request')) {
      loadById(dealId)
      updateAttr({ editProposalActive: 'edit' })
      clearActionSuccess()
    } else if (urlParams.brand) {
      this.setState({
        loadingBrand: true,
      })
      AppDispatcher.http({
        url: `/brand/id-to-name/${urlParams.brand}`,
        success: data => {
          this.setState({
            loadingBrand: false,
            selectedBrand: data,
          })
        },
        error: () => {
          this.setState({
            loadingBrand: false,
          })
        },
      })
    }

    if (brandId) {
      loadBrand(brandId)
    }
  }

  componentDidMount() {
    const {
      deals: { deal, tempProposal, editProposalActive },
    } = this.props

    window.scrollTo(0, 0)

    const hasTempProposal = tempProposal && !!Object.keys(tempProposal).length

    let newState
    if ((!editProposalActive && hasTempProposal)
      || (editProposalActive === 'counter' && hasTempProposal)
      || (editProposalActive === 'edit' && (hasTempProposal || !!Object.keys(deal).length))) {
      const temp = hasTempProposal ? tempProposal : deal
      newState = Object.assign({
        costType: temp.cost_type,
        dealCostTypeMapLabel: `${temp.cost_type.toLowerCase()}_pricing_info`,
        label: temp.label,
        startDate: moment(temp.start_date),
        endDate: moment(temp.end_date),
        mediaType: mediaTypes.includes(temp.type) ? temp.type : 'other',
        otherMediaInput: mediaTypes.includes(temp.type) ? '' : temp.type,
        costPerUnit: temp.price,
      }, temp.cost_type === 'CPC' ? {
        minQuantity: temp.min_quantity || temp.min_clicks,
        maxQuantity: temp.max_quantity || temp.max_clicks,
      } : {
        expectedViews: temp.expected_views,
        depositPercentage: temp.deposit_percentage,
      })
    } else if (editProposalActive === 'counter' && deal) {
      const temp = tempProposal && Object.keys(tempProposal).length ? tempProposal : deal
      newState = {
        label: temp.label,
        mediaType: mediaTypes.includes(temp.type) ? temp.type : 'other',
        otherMediaInput: mediaTypes.includes(temp.type) ? '' : temp.type,
        startDate: moment(temp.start_date),
        endDate: moment(temp.end_date),
      }
    }

    if (newState && hasTempProposal) {
      newState.selectedBrand = {
        id: tempProposal.buying_brand_id,
        accountname: tempProposal.buying_brand_accountname,
      }
    }

    this.setState(newState)
  }

  componentDidUpdate(prevProps) {
    const {
      deals: {
        deal,
        successfulAction: { id } = {},
      },
      history,
      brand,
    } = this.props

    const {
      deals: {
        deal: prevDeal,
        successfulAction: { id: prevId } = {},
        editProposalActive,
      },
    } = prevProps

    if (editProposalActive === 'edit' && !prevDeal && deal) {
      const newState = Object.assign({
        costType: deal.cost_type,
        dealCostTypeMapLabel: `${deal.cost_type.toLowerCase()}_pricing_info`,
        label: deal.label,
        startDate: moment(deal.start_date),
        endDate: moment(deal.end_date),
        mediaType: mediaTypes.includes(deal.type) ? deal.type : 'other',
        otherMediaInput: mediaTypes.includes(deal.type) ? '' : deal.type,
        costPerUnit: deal.price,
      }, deal.cost_type === 'CPC' ? {
        minQuantity: deal.min_clicks,
        maxQuantity: deal.max_clicks,
      } : {
        expectedViews: deal.expected_views,
        depositPercentage: deal.deposit_percentage,
      })
      this.setState(newState)
    }

    if (brand.loaded !== prevProps.brand.loaded && brand.loaded) {
      this.setState({
        selectedBrand: brand,
      })
    }

    if ((!prevId && id) || prevId !== id) {
      history.push(`/partnerships/deal/${id}/collaborate`)
    }
  }

  onSubmit = status => {
    const {
      currentBrandId,
      deals: {
        deal: { id: dealId, selling_brand_id },
        deals,
      },
      history,
      dealActions: { updateAttr },
    } = this.props

    const {
      otherMediaInput,
      maxQuantity,
      minQuantity,
      startDate: start_date,
      endDate: end_date,
      label,
      depositPercentage,
      selectedBrand: { id, accountname } = {},
      costType,
      expectedViews,
    } = this.state

    let {
      mediaType,
      costPerUnit: price,
    } = this.state

    price = parseFloat(price)

    if (mediaType === 'other') {
      mediaType = otherMediaInput
    }

    const data = {
      type: mediaType,
      cost_type: costType || 'CPC',
      max_quantity: maxQuantity,
      min_quantity: minQuantity,
      start_date,
      end_date,
      label,
      price,
      deposit_percentage: depositPercentage,
      expected_views: expectedViews,
    }

    if (status === 'create_new') {
      // Check to ensure seller/buyer don't already have an existing proposal in the works
      const existingProposal = deals.find(deal =>
        deal.status === 'pending'
        && deal.current // Confirm it is the latest proposal
        && deal.buying_brand_id === id // Confirm selected buyer doesn't match existing proposals
      )

      if (existingProposal) {
        return toastr.error(
          `You already have a pending RFP with ${accountname}. Please respond to it first.`,
          null,
          { timeOut: 5000, positionClass: 'toast-bottom-center' }
        )
      }
      
      updateAttr({
        tempProposal: Object.assign(data,
          { buying_brand_id: id, buying_brand_accountname: accountname }),
      })
      history.push('/partnerships/deal/confirm/create')
    }

    if (status === 'edit') {
      updateAttr({ tempProposal: data })
      history.push(`/partnerships/deal/${dealId}/confirm/edit`)
    }

    if (status === 'counter') {
      const isSeller = currentBrandId === selling_brand_id
      updateAttr({ tempProposal: data })
      history.push(`/partnerships/deal/${dealId}/confirm/${isSeller ? 'seller' : 'buyer'}-counter`)
    }
  }

  onDatesChange = dates => {
    let { startDate, endDate } = dates
    const { timezone } = this.props
    startDate = startDate && startDate.tz(timezone || 'America/New_York').startOf('day')
    endDate = endDate && endDate.tz(timezone || 'America/New_York').endOf('day')
    startDate = startDate && moment.utc(startDate)
    this.setState({ startDate, endDate })
  }

  onFocusChange = focusedInput => this.setState({ focusedInput })

  closeAll = () => {
    const {
      deals: { deal, editProposalActive },
      dealActions: { updateAttr, clearTempProposal, clearActionSuccess },
      history,
    } = this.props

    updateAttr({ editProposalActive: false })
    clearTempProposal()
    clearActionSuccess()

    if (deal && !!Object.keys(deal).length && ['edit', 'counter'].includes(editProposalActive)) {
      return history.push(`/partnerships/deal/${deal.id}/collaborate`)
    }

    return history.push('/partnerships/media-market/current')
  }

  setDealCostTypeMapLabel = label => this.setState({ dealCostTypeMapLabel: label })

  setCostType = type => this.setState({ costType: type })

  setSelectedBrand = brand => this.setState({ selectedBrand: brand })

  setStateFromForm = newData => {
    delete newData.selectedBrand // this should not be updated from the form
    this.setState(newData)
  }

  render() {
    const {
      currentBrandId,
      deals: {
        loading,
        deal: {
          status: dealStatus,
          currentProposal,
          selling_brand_id,
          selling_brand,
          buying_brand,
        },
        editProposalActive,
      },
    } = this.props

    const {
      selectedBrand = {},
      startDate,
      endDate,
      dealCostTypeMapLabel,
      loadingBrand,
    } = this.state

    if (loading || loadingBrand) {
      return (
        <Spinner />
      )
    }

    const status = editProposalActive || 'create_new'
    const isSeller = status !== 'create_new' && currentProposal ? currentBrandId === selling_brand_id : true
    const dealWithAccountname = currentProposal && (isSeller ? buying_brand.accountname
      : selling_brand.accountname)

    const titleMap = {
      create_new: 'Create New Proposal',
      edit: 'Edit Proposal',
      confirm_purchase_details: 'Confirm Purchase Details',
      counter: 'Create Counter Proposal',
    }

    const utilityFunctions = {
      selectBrand: this.setSelectedBrand,
      onDatesChange: this.onDatesChange,
      onFocusChange: this.onFocusChange,
      setDealCostTypeMapLabel: this.setDealCostTypeMapLabel,
      setCostType: this.setCostType,
    }

    const dealInfo = Object.assign({}, this.state, {
      status,
      isSeller,
      dealWithAccountname,
    })

    const pricingInfoComponent = {
      cpc_pricing_info: <CPCPricingInfo data={dealInfo} />,
      fixed_pricing_info: <FixedPricingInfo data={dealInfo} />,
    }

    return (
      <FullscreenModal
        title={titleMap[dealStatus === 'request' ? 'create_new' : status]}
        close={this.closeAll}
      >
        <div className={FullscreenModalTheme.statusBars}>
          { ['edit', 'counter'].includes(status)
            && dealStatus !== 'request'
            && <EditStatusBar proposal={currentProposal} />
          }
        </div>
        <SimpleForm model={this.state} updateModel={this.setStateFromForm} handleSubmit={() => this.onSubmit(status)}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <EnterMediaInfo
              functions={utilityFunctions}
              data={dealInfo}
              selectedBrand={selectedBrand}
            />
            <div>
              <SelectDealType functions={utilityFunctions} data={dealInfo} />

              {pricingInfoComponent[dealCostTypeMapLabel || 'cpc_pricing_info']}
            </div>
          </div>

          <div style={{ marginBottom: 75 }} className={FullscreenModalTheme.buttonGroup}>
            <ModalButtonGroupWithContext
              cancel={this.closeAll}
              cancelText="Cancel"
              confirmText="Confirm"
              canSave={(Object.keys(selectedBrand).length || !!dealWithAccountname)
                && !!startDate && !!endDate}
              hideLine
            />
          </div>
        </SimpleForm>
      </FullscreenModal>
    )
  }
}

CreateNewMedia.defaultProps = {
  timezone: 'America/New_York',
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNewMedia))
