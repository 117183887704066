import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import BrandDetailCard from 'visual-components/shared/brand-detail-card/BrandDetailCard';

class CampaignPartnersTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      campaign,
      currentBrand
    } = this.props;

    const partnerItems = campaign.visiblePartners.map(partner => {
      return (
        <Col key={partner.id}>
          <BrandDetailCard
            logo={ partner.invitee.logo }
            accountname={ partner.invitee.accountname }
            dedicatedListSize={ partner.invitee.dedicatedListSize }
            averageSignUpsPerCampaign={ partner.invitee.averageSignUpsPerCampaign }
            socialMediaImpressions={ partner.invitee.social.socialMediaImpressions }
            tags={ partner.formattedTags }
            degreeOfRemoval={ partner.degree }
            facebookCoverPhoto={ partner.invitee.social.facebookCoverPhoto || '/images/defaults/coverPhotoFull.png' }
            coverPhotoOffsetY={ partner.invitee.social.coverPhotoOffsetY || 0 }
            ratingAvg={ partner.ratingAvg }
            ratingCount={ partner.ratingCount }
            brandId={ partner.invitee.id }
            currentBrandId={ currentBrand.id }
          />
        </Col>
      )
    });

    return(
      <div>
        <Grid>
          <Row>
            { partnerItems }
          </Row>
        </Grid>
      </div>
    )
  }
}

CampaignPartnersTab.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired
};

export default CampaignPartnersTab;
