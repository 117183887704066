import { PureComponent } from 'react';
import { Link } from "react-router-dom";
import {array, string } from 'prop-types'
import moment from 'moment-timezone';
import d3 from 'd3';
import { Card } from 'react-toolbox/lib/card';
import { H3, H6, P, Small } from 'visual-components/util/texts';
import EmptyState from 'visual-components/util/graphs/empty-state';
import theme from 'css/themes/campaign-quickview.css'


class RecentAnnouncementItem extends PureComponent {
  render() {
    const { title, timestamp, description } = this.props
    return (
      <div className={theme.recentAnnouncementItem}>
        <H6
          style={{ display: 'inline-block', maxWidth: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginBottom: '-2px', marginTop: '2px' }}>
          { title }
        </H6>
        <Small style={{ display: 'inline-block', marginLeft: '8px', overflow: 'hidden' }}>
          { timestamp }
        </Small>
        <P
          multiline
          overflowEllipsis
          style = {{ marginTop: '3px' }}
        >
          <span dangerouslySetInnerHTML={{ __html: description && description.length > 85 ? description.slice(0,85) + '...' : description }}></span>
        </P>
      </div>
    )
  }
}

export default class RecentAnnouncementsCard extends PureComponent {
  static propTypes = {
    announcements: array.isRequired,
    campaignId: string.isRequired,
  }
  render() {
    const { announcements, campaignId, shrink } = this.props;
    const announcementItems = announcements && announcements.length ?
    announcements.map((item, idx) => {
      return (
        <RecentAnnouncementItem
        key = {idx}
        title = {item.title}
        timestamp = {item.timestamp}
        description = {item.description}
        />
      )
    })
    :
    <div style={{
      display: 'flex',
      height: '219px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid #d8d8d8',
    }}>
        <EmptyState
          text="No announcements yet. Keep an eye out here for announcemens from your host."
          height = "219px"
          />
      </div>

    return (
      <div style={{
        width: shrink ? '312px' : '536px',
        position: 'relative',
      }}>
        <Card style={{
          height: '320px',
          paddingTop: '16px',
          paddingBottom: '16px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)'

        }}>
          <div style={{ paddingLeft: '20px', paddingBottom: '16px', borderBottom: '1px solid #d8d8d8' }}>
            <H3 coral >
              <small>
                Recent Announcements
              </small>
            </H3>
          </div>

          { announcementItems.length > 3 ? announcementItems.slice(0,3) : announcementItems }

          <div style={{ position: 'absolute', bottom: '16px', width: '100%'}}>
          { announcementItems.length < 3 && <div style={{ borderBottom: '1px solid #d8d8d8', width: '100%' }} /> }
            <div style={{ paddingTop: '16px', paddingLeft: '20px' }}>
              <Link to={`/partnerships/campaign/${campaignId}/announcements`}>
                <H6 azure>
                  See all announcements
                </H6>
              </Link>
            </div>
          </div>

        </Card>
      </div>
    )
  }
}
