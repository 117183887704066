import React from 'react'
import { string, bool } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { Small } from 'visual-components/util/texts'
import DealStatusMessageTheme from 'css/themes/media-market/DealStatusMessage.css'

class DealStatusMessage extends React.Component {
  static propTypes = {
    status: string,
    isProposingBrand: bool.isRequired,
  }

  static defaultProps = {
    status: '',
  }

  render() {
    const {
      status,
      isProposingBrand,
    } = this.props

    const accepted = {
      img: '/images/icons/high-five-icon.svg',
      message: 'Congrats! This offer was accepted.',
      graphicBackground: DealStatusMessageTheme.acceptedBackground,
    }

    const info = {
      accepted,
      live: accepted,
      completed: accepted,
      unfulfilled: accepted,
      declined: {
        img: '/images/icons/warning-megaphone.svg',
        message: 'This offer was declined. You can keep the conversation going but will need to set up a new sale to finalize a Media Market partnership.',
        graphicBackground: DealStatusMessageTheme.declinedBackground,
      },
      expired: {
        img: '/images/icons/warning-megaphone.svg',
        message: 'This offer has expired. You can keep the conversation going but will need to set up a new sale to finalize a Media Market partnership.',
        graphicBackground: DealStatusMessageTheme.declinedBackground,
      },
    }

    // proposing brand is the receiver of the status
    const theme = {
      main: DealStatusMessageTheme.main,
      mainBackground: isProposingBrand
        ? DealStatusMessageTheme.receiver : DealStatusMessageTheme.sender,
      footerContainer: DealStatusMessageTheme.footerContainer,
      graphicPosition: DealStatusMessageTheme.graphicPosition,
    }

    return (
      <Card className={`${theme.main} ${theme.mainBackground}`}>
        <div>
          <div className={info[status].graphicBackground}>
            <img src={info[status].img} className={theme.graphicPosition} />
          </div>
          <div className={`${theme.footerContainer} ${theme.footer}`}>
            <Small multiline>{info[status].message}</Small>
          </div>
        </div>
      </Card>
    )
  }
}

export default DealStatusMessage
