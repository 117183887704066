import React from 'react'
import { connect } from 'react-redux'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import COLOURS from 'util/colours'

import { Card } from 'react-toolbox/lib/card'
import { P, H3 } from 'visual-components/util/texts'

import SelectField from 'visual-components/util/inputs/select-field'
import Field from 'visual-components/util/inputs/field'


import PostEntryMessage from 'util/components/templates/form/post-entry-message'
import LiveCtaButton from 'util/components/templates/form/cta/cta-button'
import LiveCtaImage from 'util/components/templates/form/cta/cta-small-image'
import LiveCtaFullPage from 'util/components/templates/form/cta/cta-full-page-image'
import LiveCtaVideo from 'util/components/templates/form/cta/cta-video'

import PartnershipInvitesDispatcher from 'dispatchers/partnership-invites-dispatcher'

import moment from 'moment-timezone'
import { getClaim } from 'actions/brand-claim-actions'
import TrafficDistribution from './traffic-distribution'
import CtaVideo from './types/cta-video'
import CtaFullImage from './types/cta-full-image'
import CtaSmallImage from './types/cta-small-image'
import CtaButton from './types/cta-button'


const mapStateToProps = ({
  currentCampaign, currentBrand, ctaModel, ctaForm,
}) => ({
  currentCampaign, currentBrand, ctaModel, ctaForm,
})

const ViewMap = {
  button: {
    component: CtaButton,
    preview: LiveCtaButton,
    width: '375px',
  },
  smallImage: {
    component: CtaSmallImage,
    preview: LiveCtaImage,
    width: '375px',
  },
  fullImage: {
    component: CtaFullImage,
    preview: LiveCtaFullPage,
    width: '560px',
  },
  video: {
    component: CtaVideo,
    preview: LiveCtaVideo,
    width: '560px',
  },
  none: {
  },
}

class CustomCTA extends React.Component {
  componentDidMount() {
    const { currentCampaign, currentBrand } = this.props

    const partnerInvites = currentCampaign.invites.filter(invite => invite.status === 'accepted')
    const partnerInvite = partnerInvites.find(invite => invite.invitee_id === currentBrand.id) || {}
    partnerInvite.bonus = partnerInvite.bonus || {}

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'partnershipInvite',
      data: partnerInvite,
    })

    this.setState({
      [partnerInvite.ctaType]: true,
    })
  }

  updateAttr = (attr, value) => {
    const { currentBrand: { isSensei, trial_end } } = this.props

    const isTrial = trial_end && moment(trial_end).startOf('day').isAfter(moment())

    if (!getClaim('sweepstakes.customCta.create') && !isSensei && !isTrial) {
      this.openPricingGate()
    } else {
      PartnershipInvitesDispatcher.updateAttrPersist(attr, value)
    }
  }

  openModal = () => {
    const { currentBrand: { isSensei, trial_end } } = this.props

    const isTrial = trial_end && moment(trial_end).startOf('day').isAfter(moment())

    if (!getClaim('sweepstakes.customCta.create') && !isSensei && !isTrial) {
      this.openPricingGate()
    } else {
      store.dispatch({
        model: 'ctaModel',
        type: 'UPDATE_ATTR',
        data: {
          editTrafficModal: true,
        },
      })
    }
  }

  closeModal = () => {
    store.dispatch({
      model: 'ctaModel',
      type: 'UPDATE_ATTR',
      data: {
        editTrafficModal: false,
      },
    })
  }

  openPricingGate = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'pricingModal',
      data: {
        growthExpand: true,
        feature: 'customCta',
        title: 'Looking to Drive Conversions?',
        message: 'Want to turn your entrants into readers and customers? Premium users can leverage campaign traffic to advertise to entrants.',
        eventTrack: 'customCTAUpgrade',
      },
    })
  }

  render() {
    const {
      currentCampaign, currentBrand, ctaModel: cta, ctaForm,
    } = this.props

    if (!cta.id) {
      return null
    }

    const isHost = currentBrand.id === currentCampaign.hostBrandId
    const newView = isHost && !currentBrand.features.block_sms

    const invite = currentCampaign.invites.find(invite => invite.invitee_id === currentBrand.id)

    const view = ViewMap[cta.ctaType] || ViewMap.button
    const CtaComponent = view.component
    const LiveCtaComponent = view.preview

    const ctaOptions = [{
      value: 'button',
      label: 'Simple Button + Text',
    }, {
      value: 'smallImage',
      label: 'Small Image/GIF',
    }, {
      value: 'fullImage',
      label: 'Full Page Image',
    }, {
      value: 'video',
      label: 'Video',
    }, {
      value: 'none',
      label: 'None',
    }]

    const isTrial = currentBrand.trial_end && moment(currentBrand.trial_end).startOf('day').isAfter(moment())

    const cantUse = !getClaim('sweepstakes.customCta.create')
      && !currentBrand.isSensei
      && !isTrial

    const allocatedTrafficPercentage = invite.successPageProportion * 100

    const content = (
      <>
        {!newView && (
          <Row style={{ marginBottom: '16px' }}>
            <H3 coral>
              <small>
                Custom Call-to-Action
              </small>
            </H3>
          </Row>
        )}
        <div>
          {!isHost && (
            <Row style={{ marginBottom: '24px' }}>
              <P multiline>
                If the Host has agreed to show your Custom CTA, create and preview your ad here.
                {' '}
                <a href="https://help.dojomojo.com/hc/en-us/articles/115002788571-Drive-Conversions-with-a-Custom-CTA-Partners" target="_blank">Learn more</a>
                .
              </P>
            </Row>
          )}

          <Row style={{ marginBottom: '24px', display: 'flex', flexDirection: 'column' }}>
            <H3>
              Build Your Custom Call-to-Action
            </H3>
            {!isHost && (
              <P style={{ marginTop: 12 }}>
                The host has currently allocated
                {' '}
                <em style={{ color: COLOURS.seaGreen }}>
                  {`${allocatedTrafficPercentage}%`}
                </em>
                {' '}
                of the traffic to your brand.
              </P>
            )}
          </Row>
          <Row>
            <Col style={{ width: '560px', marginRight: '106px' }}>
              <SelectField
                attrName="ctaType"
                label="Custom CTA Type"
                dataModel={cta}
                formModel={ctaForm}
                updateAttr={this.updateAttr}
                options={ctaOptions}
              />

              <Field
                attrName="postEntryMessage"
                label="Custom CTA Message"
                dataModel={cta}
                formModel={ctaForm}
                updateAttr={this.updateAttr}
              />

              {
                CtaComponent
                  ? (
                    <CtaComponent
                      updateAttr={this.updateAttr}
                      dataModel={cta}
                      formModel={ctaForm}
                    />
                  )
                  : null
              }
            </Col>
            <Col style={{ width: '420px' }}>
              <PostEntryMessage message={cta.postEntryMessage} />
              {
                LiveCtaComponent
                  ? <LiveCtaComponent cta={cta} />
                  : null
              }
            </Col>
          </Row>
        </div>
      </>
    )

    return (
      <div style={{ position: 'relative', marginBottom: '16px' }}>
        { cantUse && <div style={{ width: '100%', height: '100%', position: 'absolute' }} onClick={this.openPricingGate} /> }
        { newView ? content : (
          <Card style={{ width: '100%', padding: '16px 20px 24px 20px' }}>
            {content}
          </Card>
        )}
        <TrafficDistribution {...this.props} expanded={cta.editTrafficModal} closeModal={this.closeModal} />
      </div>
    )
  }
}

export default connect(mapStateToProps)(CustomCTA)
