const initialState = {
  audience_description: '',
  gender_distribution_female: 0,
  open_rate: 0,
  ctr: 0,
  cpc_max: 0,
  cpc_min: 0,
  cpm_max: 0,
  cpm_min: 0,
  flat_rate_max: 0,
  flat_rate_min: 0,
  ageDistributions: [],
  preferences: [],
  loading: false,
}

export default function sellerProfileReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_SELLER_PROFILE':
      return { ...state, loading: true }

    case 'LOADING_SELLER_PROFILE_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    case 'LOADING_SELLER_PROFILE_FAILURE':
      return { ...state, loading: false }

    case 'UPDATE_SELLER_PROFILE':
      return { ...state, loading: true }

    case 'UPDATE_SELLER_PROFILE_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    case 'UPDATE_SELLER_PROFILE_FAILURE':
      return { ...state, loading: false }

    case 'SELLER_PROFILE_CLEAR':
      return { ...initialState }

    default:
      return state
  }
}
