import moment from 'moment-timezone';

export default {
  overviewSelection: 'dashboardEmailsAcquired',
  conversionSelection: 'transactions',
  overviewAggregates: {
    dashboardEmailsAcquired: 0,
    dashboardSmsSubscribers: 0,
    dashboardSocialActions: 0,
    dashboardCampaignsLaunched: 0,
    dashboardNewPartnerships: 0,
  },
  dashboardSocialMetrics: {
    socialItems: [],
    colorMap: {},
    categories: [],
    hideMap: {
      linkShare: true,
    },
  },
  dashboardTopRegions: [],
  dashboardSocialFollower: {
    data: [],
    colorMap: {},
    hideMap: {
      linkShare: true,
    },
  },
  dashboardConversionTotals: {
    data: [
      {
        count: 0,
      },
    ],
  },
  dashboardSmsSubscribersTotals: {
    count: 0,
    pass_roi: true,
    first_conversion_date: null,
  },
  dashboardSmsSummary: {
    data: [
      {
        sms_subscribers: 0,
        messages: 0,
        clicks: 0,
      },
    ],
  },
  dashboardConversionDetails: {
    transactions: {
      name: 'transactions',
      data: [],
    },
    revenue: {
      name: 'revenue',
      data: [],
    },
    aggregates: {
      transactions: 0,
      revenue: 0,
    },
  },
  oldestDate: moment().toDate(),
  datePickerAggregateTop: {
    model: 'analyticsDashboard',
    name: 'datePickerAggregateTop',
    startDate: null,
    endDate: moment(),
    focusedInput: null,
    timeFrame: 'All Time',
    displayed: false,
  },
  datePickerSocialMetrics: {
    model: 'analyticsDashboard',
    name: 'datePickerSocialMetrics',
    startDate: null,
    endDate: moment(),
    focusedInput: null,
    timeFrame: 'All Time',
    displayed: false,
  },
  datePickerTopRegions: {
    model: 'analyticsDashboard',
    name: 'datePickerTopRegions',
    startDate: null,
    endDate: moment(),
    focusedInput: null,
    timeFrame: 'All Time',
    displayed: false,
  },
  datePickerSocialFollower: {
    model: 'analyticsDashboard',
    name: 'datePickerSocialFollower',
    startDate: null,
    endDate: moment(),
    focusedInput: null,
    timeFrame: 'All Time',
    displayed: false,
  },
  datePickerCustomCTA: {
    model: 'analyticsDashboard',
    name: 'datePickerCustomCTA',
    startDate: null,
    endDate: moment(),
    focusedInput: null,
    timeFrame: 'All Time',
    displayed: false,
  },
  datePickerConversionDetails: {
    model: 'analyticsDashboard',
    name: 'datePickerConversionDetails',
    startDate: null,
    endDate: moment(),
    focusedInput: null,
    timeFrame: 'All Time',
    displayed: false,
  },
}