import { throttle } from 'lodash'
import moment from 'moment'

let eventQueue = []
let waitForBrandToLoad = null


const sendEventsImmediately = dispatch => {
  if (eventQueue.length === 0) return

  dispatch({
    types: [
      'METRIC_CREATE',
      'METRIC_CREATE_SUCCESS',
      'METRIC_CREATE_FAILURE',
    ],
    http: {
      url: '/metrics',
      method: 'POST',
      data: eventQueue,
    },
  })

  eventQueue = []
}

const sendEvents = throttle(sendEventsImmediately, 3000)

export const create = (action, data = {}) => (dispatch, getState) => {
  data.action = action
  data.createdAt = moment.utc().format('YYYY-MM-DD HH:mm:ss')
  eventQueue.push(data)

  // wait for brand to load so that the brand id is set
  if (getState().currentBrand.loading) {
    if (!waitForBrandToLoad) {
      waitForBrandToLoad = setInterval(() => {
        if (!getState().currentBrand.loading) {
          if (eventQueue.length) {
            sendEvents(dispatch)
          }
          clearInterval(waitForBrandToLoad)
        }
      }, 1000)
    }
    return
  }


  sendEvents(dispatch)
}

export const sendImmediately = () => dispatch => {
  sendEventsImmediately(dispatch)
}
