import React from 'react'
import PropTypes from 'prop-types'
import { SMSEditorContext } from '../../SMSEditorContext'

import EditorLink from './EditorLinkWrapper'
import EditorTag from './EditorTagWrapper'
import EditorCoupon from './EditorCouponWrapper'

function EditorElementsWrapper(props) {
  const {
    decoratedText, children, type, ...restProps
  } = props

  const { minifiedLinks, ignoredCoupons } = React.useContext(SMSEditorContext)

  const Component = {
    link: EditorLink,
    tag: EditorTag,
    coupon: EditorCoupon,
  }[type]

  const editor = document.querySelector('#sms-editor-wrapper > :first-child')

  const componentProps = {
    isEditorFocused: editor.contains(document.activeElement),
    ...restProps,
  }

  if (type === 'coupon') {
    if (ignoredCoupons.includes(decoratedText)) return children

    componentProps.code = decoratedText
  } else if (type === 'link') {
    const url = minifiedLinks.find(
      ({ originalUrl, minifiedUrl }) => (
        originalUrl === decoratedText || minifiedUrl === decoratedText
      )
    )

    componentProps.minifiedUrl = url && url.minifiedUrl
    componentProps.url = url ? url.originalUrl : decoratedText
  }

  return (
    <Component {...componentProps}>
      {children}
    </Component>
  )
}

EditorElementsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  decoratedText: PropTypes.string.isRequired,
}

export default React.memo(EditorElementsWrapper)
