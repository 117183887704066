import React from 'react';

import Dialog from 'react-toolbox/lib/dialog';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import ProfileDispatcher from 'dispatchers/profile-dispatcher';

import dialogTheme from 'css/themes/dialogs/formModal.css'

import * as ReactRedux from 'react-redux';

const mapStateToProps = (state) => {
  return state;
};

class QuestCompleteModal extends React.Component {
  closeModal = () => {
    ProfileDispatcher.updateAttrPersist('questCompleteConfirm', true);
  }

  render() {
    var {
      currentBrand,
      profile,
      conversations
    } = this.props;

    return(
      <Dialog
        theme={ dialogTheme }
        active={ false }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title={ 'Success!' }
      >
        <div style={{
          marginRight: 'auto',
          marginLeft: 'auto',
          paddingTop: '8px',
          textAlign: 'center'
        }}>
          <img src="/images/icons/celebration-icon.svg"
            style={{
              width: '115px',
              height: '120px',
            }}/>
        </div>
        <p style={{
          fontSize: '16px',
          fontFamily: 'Larsseit-Light',
          color: COLOURS.ink,
          lineHeight: '1.5',
          paddingTop: '24px'
        }}>
          Congratulations! You’re ready to grow your audience and are on your way to becoming a DojoMojo campaign ninja!
        </p>
        <ModalButtonGroup
          hideLine={ true }
          canSave={ true }
          confirm={ this.closeModal }
          confirmText="Close"
        />
      </Dialog>
    );
  };
}

export default ReactRedux.connect(mapStateToProps)(QuestCompleteModal);
