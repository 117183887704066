import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { H4 } from 'visual-components/util/texts'
import * as DialogActions from 'actions/dialog-actions'

const OpenMessenger = (data) => ({...data})

const mapStateToProps = ({ noOfficialRulesModal }) => ({ noOfficialRulesModal })
const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
  openMessenger: bindActionCreators(OpenMessenger, dispatch)
})

class NoOfficialRulesModal extends Component {

  closeModal = () => {
    const { 
      dialogActions: { closeDialog } 
    } = this.props
    
    closeDialog('noOfficialRulesModal')
  }
  
  messageHost = () => {
    const { 
      noOfficialRulesModal: { messenger: { hostBrandId, hostBrandName } },
      openMessenger
    } = this.props
    
    this.closeModal()

    openMessenger({
      model: 'conversations',
      type: 'POPOUT_MESSAGE_BRAND',
      data: {
        brandId: hostBrandId,
        brandName: hostBrandName
      }
    })
  }

  render() {
    const { 
      noOfficialRulesModal: { open } 
    } = this.props

    return (
      <Dialog
        title="Oops! Your host hasn't created Official Rules for the campaign yet"
        theme={ dialogTheme }
        active={ open }
        onEscDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
      >
        <div className={`${dialogTheme.bannerWrapper} ${dialogTheme.orangeBanner}`}>
          <img src="/images/icons/error-message-icon.svg" />
        </div>

        <H4 multiline>Please ask your host to add Official Rules to the campaign</H4>
      
        <ModalButtonGroup
          canSave
          confirm={ this.messageHost }
          confirmText={ 'Message Host' }
          cancel={ this.closeModal }
          cancelText={'Close'}
          hideLine
        />
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoOfficialRulesModal)
