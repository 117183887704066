import React from 'react'
import COLOURS from 'util/colours'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
  mobileContainer: {
    background: 'url(/images/templates/phone-container.svg) no-repeat center center',
    backgroundSize: 'contain',
    position: 'absolute',
    top: 200, /* this will change according to the size of device-toggle */
    left: '50%',
    height: 868,
    width: 429,
    marginLeft: '-206px',
    padding: '99px 26px 102px 29px',
    overflow: 'none',
  },
  campaignContainer: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
    height: '100%',
  },
}
class MobileWrap extends React.Component {
  static propTypes = {
    children: propTypes.any.isRequired,
    classes: propTypes.object.isRequired,
  }

  render() {
    const {
      children,
      classes: css,
    } = this.props

    return (
      <div className={css.mobileContainer}>
        <div className={css.campaignContainer}>
          {children}
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(MobileWrap)
