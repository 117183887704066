import React, { PureComponent as Component } from 'react'
import { H4, Small } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import { CloudFormation } from 'aws-sdk'
import { Link } from 'react-router-dom'

class GraphEmptyState extends Component {

  render() {
    const {
      text,
      learnMore,
      bigCard,
      bigText,
      height,
      ctaText,
      ctaAction,
      upsellButtonText,
      upsellButtonLink,
      upsellClickHandler,
    } = this.props

    const TextComponent = bigText ? H4 : Small

    return (
      <div
        style={{
          display: 'flex',
          height,
          width: bigCard ? '360px' : '240px',
          marginLeft: bigCard ? '119px' : '13px',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <TextComponent cloudGrey multiline>
          <i>
            {text}
            {learnMore ? (
              <a href={learnMore} target="_blank">
                {' Learn More'}
              </a>
            ) : null}
          </i>
          {upsellButtonText && (
            <div style={{ marginTop: '20px' }}>
              <Link to={upsellButtonLink}>
                <Button
                  theme={ButtonTheme}
                  className={ButtonTheme.blueButton}
                  onClick={upsellClickHandler}
                >
                  {upsellButtonText}
                </Button>
              </Link>
            </div>
          )}
        </TextComponent>
        {ctaText ? (
          <Button
            style={{ marginTop: '16px' }}
            onClick={ctaAction}
            theme={ButtonTheme}
            className={ButtonTheme.blueButton}
            label={ctaText}
            primary
            raised
          />
        ) : null}
      </div>
    );
  }
}

export default GraphEmptyState
