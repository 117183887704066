import React, { PureComponent } from 'react'
import TooltipCard from 'visual-components/analyticsDashboard/tooltip/tooltip-card'
import { renderToString } from 'react-dom/server'

import moment from 'moment-timezone'

const EntriesTooltipFactory = React.createFactory(TooltipCard)

const renderGraph = function (data) {
  const dates = data[0].data.map(item => item.x)

  $('.line-conversion').html('')

  const graph = new Contour({
    el: '.line-conversion',
    xAxis: {
      type: 'linear',
      maxTicks: 8,
    },
    yAxis: {
    },
    legend: {
      vAlign: 'top',
      hAlign: 'left',
    },
    chart: {
      padding: {
        left: 33,
      },
      height: 300,
    },
    tooltip: {
      opacity: 1,
      verticalTrack: true,
      formatter(d) {
        const str = renderToString(EntriesTooltipFactory(d))

        return str
      },
    },
  })
    .cartesian()
    .yAxisLines()
    .line(data)
    .tooltip()
    .dateBasedXAxis(null, {
      n: dates.length,
      shiftLabel: true,
      formattedDates: dates.map(date => {
        return moment.utc(date).format('MMM DD')
      }),
    })
    .render()

  return graph
}


class ConversionLine extends PureComponent {
  componentDidMount() {
    this.graph = renderGraph([this.props.data])
  }

  componentDidUpdate(prevProps) {
    /*
    Conditions to re-render graph
    1. if user has changed tabs (new graph)
    2. if data has changed for existing graph
    */
    if (prevProps.selectedTab !== this.props.selectedTab || prevProps.data !== this.props.data) {
      renderGraph([this.props.data])
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            display: 'inline-block',
            width: '590px',
            height: '300px',
            marginBottom: '-20px',
          }}
          className="line-conversion"
        />
      </div>
    )
  }
}

export default ConversionLine
