import React from 'react'
import PropTypes from 'prop-types'
import d3 from 'd3'
import Theme from 'css/themes/ExpandableCalculatorTheme.css'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'
import { H2 } from 'visual-components/util/texts'

class PlainBar extends React.PureComponent {
  static propTypes = {
    tooltipText: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }

  static defaultProps = {
    tooltipText: null,
  }

  render() {
    const {
      tooltipText,
      title,
      value,
    } = this.props

    return (
      <div>
        <div className={Theme.plainBar}>
          <div className={Theme.title}>
            {title}
          </div>
          {
            tooltipText
            && (
              <div className={Theme.toolTip}>
                <BlueInfoTooltip text={tooltipText} />
              </div>
            )
          }
          <div className={Theme.value}>
            <H2>
              {d3.format(',')(value)}
            </H2>
          </div>
        </div>
        <div className={Theme.shadowBar} />
      </div>
    )
  }
}

export default PlainBar
