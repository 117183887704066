import React from 'react';
import _ from 'lodash'
import MessageThreadItem from './messaging-thread-item';
import MessageResponse from './messaging-response';
import { Grid, Row, Col } from 'react-flexbox-grid';
import COLOURS from 'util/colours';

class MessageThread extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAllMessages: false,
      seenBy: 0
    }
    this.loadAllMessages = this.loadAllMessages.bind(this)
  }

  componentDidMount() {
    var that = this;
    if (this.props.conversationId) {
      $.get(`/messages/read-receipt/${this.props.conversationId}`, function(data){
        let currentUserId = store.getState().profile.id
        let seenByArr = _.filter(data, (receipt)=> {
          return receipt.user_id != currentUserId && new Date(receipt.last_read) > new Date(that.props.conversationLastMessage)
        })
        that.timeout = setTimeout(() => {
          that.setState({
            seenBy: seenByArr.length
          })
        }, 100)
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  loadAllMessages() {
    this.setState({
      showAllMessages: true
    })
  }

  render() {
    let {showQuickReply, showCollapseInThread} = this.props
    let messages = this.props.messages.slice().reverse();
    let showFirstFiveMessages = false;

    if (!this.state.showAllMessages && messages.length > 5) {
      messages = messages.slice(-5);
      showFirstFiveMessages = true;
    }

    let messageItems = _.map(messages, (message, idx) => {
      if(idx == messages.length-1) {
        return (
          <MessageThreadItem
            message= { message }
            key = { message.id }
            seenBy = { this.state.seenBy }
          />
        )
      } else {
        return (
          <MessageThreadItem
            message= { message }
            key = { message.id }
          />
        )
      }
    })
    return (
      <div>
        { showFirstFiveMessages ?
          <div onClick={this.loadAllMessages} style={{
            textAlign: 'center',
            fontFamily: 'Larsseit-Bold',
            fontSize: '16px',
            color: COLOURS.seaGreen,
            paddingTop: '14px',
            paddingBottom: '12px',
            borderBottom: `1px solid ${COLOURS.lightGrey}`,
            cursor: 'pointer'
          }}>
            Load More Messages
          </div>
          : null
        }

        <div className="message-scrollbox" style={{
          maxHeight: '620px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}>
          { messageItems }
        </div>
        { showCollapseInThread ?
            <div>
              <Col xs={12} style={{
                  textAlign: 'center'
                }}>
                  <div onClick={ this.props.collapse }>
                    <p style={{
                      color: COLOURS.azure,
                      fontSize: '14px',
                      fontFamily: 'Larsseit-Medium',
                      marginTop: '10px',
                      marginBottom: '10px',
                      display: 'inline-block',
                      cursor: 'pointer'
                    }}>
                      Collapse
                    </p>
                    <img src="/images/icons/messaging-icons/industry-list-collapse-arrow.svg" style={{
                      width: '12px',
                      marginLeft: '8px',
                      marginRight: '8px',
                      cursor: 'pointer'
                    }}/>
                  </div>
                </Col>
            </div>
          :
            null
        }
        { !this.props.hideResponse ?
          <MessageResponse
            conversationId = { this.props.conversationId }
            collapse = { this.props.collapse }
            showQuickReply = { showQuickReply }
          />
          : null
        }

      </div>
    )
  }

}

export default MessageThread
