import React from 'react'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import constants from './constants.json'

const styles = {
  backgroundImage: {
    position: 'absolute',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    transition: `top .1s, bottom .1s, left ${constants.menuTransitionTime}, right .1s`,
  },
  backgroundVideo: {
    position: 'absolute',
    transition: `top .1s, bottom .1s, left ${constants.menuTransitionTime}, right .1s`,
  },
  video: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    backgroundSize: 'cover',
    overflow: 'hidden',
    zIndex: -1,
  },
}

// Amount of pixels the hero pixels moves by on adjustment
const MOVE_FACTOR = 10

class HeroImage extends React.Component {
  static propTypes = {
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool.isRequired,
    imageUrl: propTypes.string.isRequired,
    editorMenuWidth: propTypes.number.isRequired,
    isVideo: propTypes.bool,
  }

  static defaultProps = {
    isVideo: false,
  }
 
  static contextTypes = {
    userStyles: propTypes.object,
  }
 
  render() {
    const {
      classes: css,
      imageUrl,
      desktopVersion,
      isVideo,
      editorMenuWidth,
    } = this.props

    const {
      userStyles: {
        imagePos: backgroundPos,
      },
    } = this.context

    const zoom = (backgroundPos.zoom - 50) * 3
  
    const top = Math.min(0, (backgroundPos.y - 50) * MOVE_FACTOR) - zoom
    const bottom = Math.min(0, (50 - backgroundPos.y) * MOVE_FACTOR) - zoom
  
    const left = Math.min(0, (backgroundPos.x - 50) * MOVE_FACTOR) - zoom
    const right = Math.min(0, (50 - backgroundPos.x) * MOVE_FACTOR) - zoom
    
    const positionStyles = {
      top: `${top}px`,
      left: `${left}px`,
      right: `${right}px`,
      bottom: `${bottom}px`,
    }

    if (isVideo) {
      return (
        <div
          id="hero-video-container"
          className={css.backgroundVideo}
          style={positionStyles}
        >
          <video
            id="hero-video"
            key={imageUrl}
            className={css.video}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={imageUrl} type="video/mp4" />
          </video>
        </div>
        
          
      )
    }

    return (
      <div
        id="hero"
        className={css.backgroundImage}
        style={{
          ...positionStyles,
          backgroundImage: `url('${imageUrl}')`,
        }}
      />
    )
  }
}

export default injectSheet(styles)(HeroImage)