import AppDispatcher from 'dispatchers/app-dispatcher';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';
// dispatches action to load conversations for current user

const loadConversations = () => {
  return new Promise(function (res, rej) {
    Promise.all([
      $.get('/conversations'),
      $.get('/unread-notifications'),
    ])
    .then(data => {
      const [conversations, userBrandsUnreads] = data;

      store.dispatch({
        model: 'conversations',
        type: 'LOAD_CONVERSATIONS',
        data: conversations
      })
      store.dispatch({
        model: 'userBrands',
        type: 'LOAD_UNREADS',
        data: userBrandsUnreads
      })
      res();
    }).catch(rej)
  })
};


const selectConversation = (data) => {
  store.dispatch({
    type: 'SELECT_CONVERSATION',
    model: 'conversations',
    id: id
  });
};

const newBrandConversation = (data) => {
  AppDispatcher.http({
    url: `/new-conversation/${data.brandId}`,
    method: 'POST',
    data: data,
    success: (data) => {
      store.dispatch({
        model: 'currentBrand',
        type: 'ADD_CONVERSATION_STARTED',
        data: data
      })
      store.dispatch({
        type: 'LOAD_NEW_CONVERSATION',
        model: 'conversations',
        data: data
      });
    },
  });
};

// loads messages for a conversation_id
const loadMessages = (conversationId) => {
  AppDispatcher.http({
    url: `/messages/${conversationId}`,
    data: {
      id: conversationId
    },
    method: 'GET',
    success: (data) => {
      // console.log('loading messages for conversation', data);
      store.dispatch({
        type: 'LOAD_CONVERSATION_MESSAGES',
        id: conversationId,
        model: 'conversations',
        data: {
          messages: data
        }
      });
    }
  });
};

const flagReadReceipt = (conversationId) => {
  AppDispatcher.http({
    url: `/messages/read-conversation/${conversationId}`
  })
}

// get read receipts for a conversation_id
const loadWhoHasReadReceipts = (data) => {
  store.dispatch({
    type: 'LOAD_WHO_HAS_READ_RECEIPTS',
    id: data.conversationId,
    model: 'conversations',
    data: {
      id: data.conversationId
    }
  })
};

const sendMessage = (data) => {
  const { conversationId } = data;

  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'conversations',
    data: {
      hasSent: true
    }
  });
  AppDispatcher.http({
    url: `/new-message/${conversationId}`,
    data: data,
    method: 'POST',
    success: (data) => {
      store.dispatch({
        type: 'LOAD_NEW_MESSAGE',
        model: 'conversations',
        id: conversationId,
        message: data
      });
    }
  });
};

const conversationModal = (brandId, brandName) => {
  PricingDispatcher.check('messaging.message.create', function () {
    store.dispatch({
      model: 'conversations',
      type: 'POPOUT_MESSAGE_BRAND',
      data: {
        brandId: brandId,
        brandName: brandName
      }
    })
  }, brandId)
}

const saveDraft = (data) => {
  store.dispatch({
    model: 'conversations',
    type: 'SAVE_DRAFT',
    data: data
  })
}

const searchForBrands = (value, type) => {
  AppDispatcher.http({
    url: '/search/',
    method: 'POST',
    data: {
      size: 10,
      query: {
        bool: {
          must: [{
            wildcard: {
              accountname:'*' + value.toLowerCase().replace(/\s/g, '*') + '*'
            }
          }]
        }
      }
    },
    success: (data)=> {
      store.dispatch({
        type: 'UPDATE_SEARCH_RESULTS',
        model: 'conversations',
        data: {
          results: data.hits.hits,
          type: type
        }
      })
    }
  })
}

const archive = (conversationId) => {
  AppDispatcher.http({
    url: `/messages/archive/${conversationId}`,
    method: 'POST',
    success: () => {
      store.dispatch({
        model: 'conversations',
        type: 'UPDATE_ATTR',
        data: {
          archiveConfirmation: null
        }
      })
      store.dispatch({
        model: 'conversations',
        type: 'UPDATE_CONVERSATION',
        data: {
          conversationId: conversationId,
          updatedObj: {
            conversation_archive: true
          }
        }
      })
    }
  });
}

const unarchive = (conversationId) => {
  AppDispatcher.http({
    url: `/messages/un-archive/${conversationId}`,
    method: 'GET'
  });
}

const markAllRead = () => {
  store.dispatch({
    model: 'conversations',
    type: 'MARK_ALL_READ'
  })
  AppDispatcher.http({
    url: `/messages/mark-all-read`,
    method: 'GET'
  })
}

export default {
  newBrandConversation,
  loadConversations,
  loadMessages,
  loadWhoHasReadReceipts,
  sendMessage,
  conversationModal,
  saveDraft,
  searchForBrands,
  archive,
  unarchive,
  flagReadReceipt,
  markAllRead
};
