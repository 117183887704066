import React from 'react'
import FormContainer from 'v2/components/templates/FormContainer'
import Logo from 'v2/components/templates/Logo'
import Text from 'v2/components/templates/Text'
import TemplateContainer from 'v2/components/templates/TemplateContainer'
import BackgroundOverlay from 'v2/components/templates/BackgroundOverlay'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { templateStyles } from 'v2/components/templates/styles/version-11'
import styleHelper from 'v2/styles/style-helper'
import constants from 'v2/components/templates/constants.json'
import getEditorMenuWidth from 'v2/components/templates/utils/getEditorMenuWidth'

const menuWidth = props => (getEditorMenuWidth(
  props.edit,
  props.desktopVersion,
  props.isEditorMenuOpen
))

const styles = {
  topLogoContainer: {
    position: 'relative',
    zIndex: 3,
    padding: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topTextContainer: {
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 40px)'),
    margin: props => (props.desktopVersion ? '60px 60px 0 60px' : '20px 20px 0 20px'),
    [styleHelper.max(1040)]: {
      width: 'calc(100% - 40px) !important',
      margin: '20px 20px 0 20px !important',
    },
  },
  formContainer: {
    position: 'relative',
    background: 'rgba(255,255,255,.85)',
    zIndex: 1,
    width: props => (props.desktopVersion ? `calc(100% - ${menuWidth(props)}px)` : 'calc(100% - 40px)'),
    maxWidth: 1000,
    overflow: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: props => (props.desktopVersion ? '0 auto 20px auto' : '0 20px 20px 20px'),
    transition: `width ${constants.menuTransitionTime}`,
    [styleHelper.max(1040)]: {
      margin: '0 20px 20px 20px !important',
      width: 'calc(100% - 40px) !important',
    },
  },
  headlineContainer: {
    width: '100%',
  },
  preEntryMessageContainer: {
    width: '100%',
    margin: '40px 0',
  },
  detailsContainer: {
    marginBottom: 32,
    width: '100%',
  },
  ...templateStyles,
}

const hexToRGBA = (color, percent = 1) => {
  const colors = color.substring(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16))
  return `rgba(${colors.join(', ')}, ${percent})`
}

class Version11 extends React.Component {
  static propTypes = {
    opts: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool,
    isEditorMenuOpen: propTypes.bool,
  }

  static defaultProps = {
    desktopVersion: true,
    isEditorMenuOpen: false,
  }

  static childContextTypes = {
    baseStyles: propTypes.object,
    userStyles: propTypes.object,
  }

  getChildContext() {
    const {
      classes: css,
      opts: {
        elements: {
          styles: userStyles,
        },
      },
    } = this.props

    return {
      baseStyles: css,
      userStyles,
    }
  }

  render() {
    const {
      opts: {
        campaign,
        hostBrand,
        elements,
        termsLink,
        acceptedInvites,
        elements: {
          styles: userStyles,
        },
      },
      updateElementsAttr,
      editElements,
      customFonts,
      edit,
      updateAttr,
      agreeToLiability,
      classes: css,
      desktopVersion,
      isEditorMenuOpen,
    } = this.props

    const formStyles = userStyles.form

    return (
      <TemplateContainer
        campaign={campaign}
        elements={elements}
        desktopVersion={desktopVersion}
        updateAttr={updateElementsAttr}
        campaignUpdateAttr={updateAttr}
        acceptedInvites={acceptedInvites}
        isEditorMenuOpen={isEditorMenuOpen}
        hostBrand={hostBrand}
      >
        <BackgroundOverlay
          desktopVersion={desktopVersion}
          isFixed
        />
        <div
          id="top-logo-container"
          className={css.topLogoContainer}
        >
          <Logo
            hostWebsite={hostBrand.website}
            logoPath={elements.top_logo || hostBrand.logo}
            updateAttr={updateElementsAttr}
            edit={editElements}
            campaign={campaign}
            elements={elements}
            maxHeight={elements.styles.topLogo ? elements.styles.topLogo.height : 100}
            className={css.topLogoImg}
            id="top-logo"
            allowDelete
            editName="top-logo"
          />
        </div>
        <div
          className={css.formContainer}
          style={{ background: hexToRGBA(formStyles.color, formStyles.opacity) }}
          id="form-holder"
        >
          <div className={css.topTextContainer}>
            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="headLine"
              customFonts={customFonts}
              className={css.headlineContainer}
            />
            
            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="preEntryMessage"
              customFonts={customFonts}
              className={css.preEntryMessageContainer}
            />
            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="details"
              customFonts={customFonts}
              className={css.detailsContainer}
            />
          </div>
          <FormContainer
            updateAttr={updateAttr}
            updateElementsAttr={updateElementsAttr}
            campaign={campaign}
            elements={elements}
            edit={edit}
            editElements={editElements}
            termsLink={termsLink}
            agreeToLiability={agreeToLiability}
            acceptedInvites={acceptedInvites}
            customFonts={customFonts}
            desktopVersion={desktopVersion}
          />
        </div>
      </TemplateContainer>
    )
  }
}

export default injectSheet(styles)(Version11)
