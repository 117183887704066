import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import { P } from 'visual-components/util/texts'

class ConfirmedPartnerDialog extends React.Component {
  confirm = () => {
    const {
      partnershipAgreement,
      partnershipAgreement: { campaign_id },
      currentCampaign: { campaignTerms: { useTemplate, createYourOwn }, invites },
      closeModal,
      history,
      actions
    } = this.props

    closeModal()

    let section = 'dashboard'
    if (useTemplate) {
      section = 'info'
    } else if (createYourOwn) {
      section = 'edit'
    }

    const hasPendingInvites = !!invites.filter(invite => invite.status === 'interested' && invite.invitee_id !== partnershipAgreement.invitee_id).length

    const nextView = hasPendingInvites ? 'partner/interested' : `terms/${section}`

    history.push(`/builder/${campaign_id}/${nextView}`)
  }

  render() {
    const { partnershipAgreement, closeModal, active, currentCampaign } = this.props

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    const hasInterestedInvitees = !!currentCampaign.invites.filter(invite => {
      return invite.status === 'interested' && invite.invitee_id !== partnershipAgreement.invitee_id
    }).length

    const startDate = moment(currentCampaign.startDate)
    const today = moment()
    const moreThanFiveDays = startDate.diff(today, 'days', true) > 5

    const confirmText = hasInterestedInvitees
      ? 'Check Partners'
      : !moreThanFiveDays
        ? 'Update Official Rules'
        : null

    const { invitee: { accountname }, campaign: { name: campaignName } } = partnershipAgreement

    return (
      <div>
        <DefaultDialog active={ active }
          closeModal={ closeModal }
          title={ `${accountname} is a Confirmed Partner!` }
          confirm={ confirmText ? this.confirm : null }
          confirmText={ confirmText }
          cancel={ closeModal }
          cancelText={ 'Close' }
          imgSrc={ '/images/icons/celebration-icon.svg' }
          children={
            <div>
              <P multiline>
                Congratulations! {accountname} is now a confirmed partner on <em>{campaignName}</em>.
                {hasInterestedInvitees && ' Click to check the status of all other Interested Partners.'}
                {!hasInterestedInvitees && !moreThanFiveDays && ' You can now create or update the Official Rules to include this Partner.'}
              </P>
            </div>
          }
        />
      </div>
    )
  }
}

export default withRouter(ConfirmedPartnerDialog)
