import React from 'react';
import stickyTabTheme from 'css/themes/homePage/stickyTabTheme.css';
import _ from 'lodash'

class StickyTabsContainer extends React.Component {
  state = {
    stuck: false,
    scrollTop: 0
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    let {
      bannerHeight,
      children,
      start
    } = this.props;

    let lastChild = document.getElementById(children[children.length - 1].props.href);
    const bottom = lastChild.offsetTop + lastChild.offsetHeight - 100
    let scrollTop = e.currentTarget.scrollY;

    // console.log(`${scrollTop}/${bottom}`)

    // console.log(scrollTop)

    if(scrollTop > bannerHeight && scrollTop < bottom) {
      this.setState({
        stuck: true,
        scrollTop: scrollTop
      });

      this.checkForActiveTab()
    } else if (scrollTop < bannerHeight) {
      this.setState({
        stuck: false,
        scrollTop: scrollTop
      });
    } else if (scrollTop > bottom) {
      this.setState({
        stuck: false,
        scrollTop: scrollTop
      });
    }
  }

  onclick = (tabTitle, href) => {
    let top = document.getElementById(href).offsetTop; //Getting Y of target element

    top = top - 154; // height of sticky header, plus some distance for nice whitespace

    // smooth scroll if available
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({ top, behavior: 'smooth' })
    } else {
      window.scrollTo(0, top);
    }
  }

  checkForActiveTab = () => {
    let childOffsets = this.props.children.map(child => {
      return {title: child.props.title, offset: Math.abs(document.getElementById(child.props.href).getBoundingClientRect().top)}
    })

    return _.minBy(childOffsets, 'offset')
  }

  render() {
    let {
      children,
      start,
      bannerHeight
    } = this.props;

    let {
      stuck,
      scrollTop
    } = this.state;

    let activeTab;

    const childrenWithProps = React.Children.map(children, (child, idx) => {
      if (scrollTop > start + 50) {
        activeTab = this.checkForActiveTab().title;
      };

      return React.cloneElement(child, {
        scrollTop: scrollTop,
        idx: idx,
        onclick: this.onclick,
        activeTab: activeTab,
      });
    });

    return(
      <div>
        { stuck ?
            <div style={{
              height: '64px',
            }}></div>
          :
            null
        }
        <div className={ `${stickyTabTheme.container} ${stuck ? stickyTabTheme.stuck : stickyTabTheme.unstuck}` }>
          <div className={ stickyTabTheme.inner }>
            { childrenWithProps }
          </div>
        </div>
      </div>
    )
  }
}

export default StickyTabsContainer;
