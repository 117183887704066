import { merge } from 'lodash'
import { v1 as uuidv1 } from 'uuid'

const initialState = {
  rid: 'dealCollaboration',
  messagesLoading: false,
  linksLoading: false,
  messages: [],
  dialogActive: '',
  links: [],
  uniqueLinks: [],
  dealId: null,
}

function getLinkDomain(link) {
  const linkWithoutHttp = link.replace('http://', '').replace('https://', '')

  return linkWithoutHttp.split('/')[0]
}

function processLink(link) {
  let { description } = link
  description = description || ''
  return Object.assign({}, link, {
    messageType: 'link',
    linkDomain: getLinkDomain(link.link),
    uid: uuidv1(),
    description: description.length > 76 ? `${description.slice(0, 76)}...` : description,
  })
}

const sendingDealCollaborateMessage = (state, action) => {
  const {
    profile: {
      fullName,
      firstName,
      lastName,
      id: user_id,
    },
  } = action.data

  const message = Object.assign({}, action.data, {
    created_at: new Date(),
    user_name: fullName || `${firstName} ${lastName}`,
    user_id,
    uid: uuidv1(),
  })

  const { messages } = state

  return { ...state, messages: messages.concat([message]) }
}

const sendingDealCollaborateMessageSuccess = (state, action) => {
  const { links } = state

  const newLinks = action.payload.map(processLink)

  const newLinksGroup = links.concat(newLinks)

  const uniqueLinks = newLinksGroup.reduce((memo, link) => {
    const inList = memo.find(memoLink => link.link === memoLink.link)

    if (inList) {
      return memo
    }

    memo.push(link)
    return memo
  }, [])

  return {
    ...state,
    dealId: Number(action.data.dealId),
    linksLoading: false,
    links: newLinksGroup,
    uniqueLinks,
  }
}

const attachUsername = message => ({
  ...message,
  uid: uuidv1(),
  messageType: 'message',
  user_name: message.user && message.user.fullName
    ? message.user.fullName
    : `${message.user.firstName || ''} ${message.user.lastName || ''}`,
})

export default function dealCollaborationReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DEAL_COLLABORATION_ATTR':
      return merge({}, state, action.data)

    case 'CLEAR_COLLABORATION_LINKS':
      return initialState

    case 'LOADING_DEAL_COLLABORATION_MESSAGES':
      return { ...state, messagesLoading: true, error: '' }

    case 'LOADING_DEAL_COLLABORATION_LINKS':
      return { ...state, linksLoading: true, error: '' }

    case 'LOADING_DEAL_COLLABORATION_MESSAGES_SUCCESS': {
      const messages = action.payload.map(attachUsername)
      return { ...state, messagesLoading: false, messages }
    }

    case 'LOAD_SINGLE_DEAL_COLLABORATION_ITEM': {
      const { deal_id, item_type } = action.payload

      // If deal not currently loaded, ignore
      if (state.dealId && state.dealId !== deal_id) return state

      // If type unrecognized, ignore
      if (!state[item_type]) return state

      const item = item_type === 'links'
        ? processLink(action.payload)
        : attachUsername(action.payload)

      return { ...state, [item_type]: [...state[item_type], item] }
    }

    case 'LOADING_DEAL_COLLABORATION_LINKS_SUCCESS': {
      return sendingDealCollaborateMessageSuccess(state, action)
    }

    case 'SENDING_DEAL_COLLABORATION_MESSAGE': {
      return sendingDealCollaborateMessage(state, action)
    }

    case 'SENDING_DEAL_COLLABORATION_MESSAGE_SUCCESS': {
      return sendingDealCollaborateMessageSuccess(state, action)
    }

    case 'LOADING_DEAL_COLLABORATION_MESSAGES_FAILURE':
    case 'SENDING_DEAL_COLLABORATION_MESSAGE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error',
      }

    default:
      return state
  }
}
