import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

function getImage(img) {
  if (!img) return null

  // uploaded image
  if (img.img64) return img.img64

  if (img.type === 'pixabay') return img.original.imageURL
  if (img.type === 'unsplash') return img.original.urls.thumb

  // Return a gif
  if (img.type === 'giphy') return img.original.images.original.url

  return img.fullSrc
}

export const SMSEditorContext = React.createContext()

const DEFAULT_MESSAGE = '*|BRAND_NAME|*: This is a sample text message. Add a *|COUPON_CODE|* below and paste/shorten your links.'

const sanitizeWebsite = website => {
  if (!website) return 's'
  const [brandWebsite] = website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')
  return brandWebsite
}

const generateRandom = n => {
  const numbers = '0123456789'
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

  const vals = `${chars}${numbers}`

  return [...new Array(n)].map(() => vals.charAt(Math.floor(Math.random() * vals.length))).join('').toLowerCase()
}

const generateMinifiedPlaceholder = website => {
  const brandWebsite = sanitizeWebsite(website)
  return `https://${brandWebsite}.djmj.io/${generateRandom(8)}`
}

const CHARACTER_LIMIT_DEFAULT = 160
const CHAREMOJI_LIMIT_DEFAULT = 70
const MMS_CHARS_LIMIT_DEFAULT = 1600
const UNICODE_REGEX = /[^\u0000-\u00ff]/

function calculateCredits(text, isMMS = false) {
  const hasUnicode = UNICODE_REGEX.test(text)
  const charLimit = isMMS
    ? MMS_CHARS_LIMIT_DEFAULT
    : hasUnicode
      ? CHAREMOJI_LIMIT_DEFAULT
      : CHARACTER_LIMIT_DEFAULT

  const multipart = text.length >= charLimit
  const appendedHeader = charLimit - (multipart ? 7 : 0)
  const charsInUse = text.length // : text.length % appendedHeader

  const values = {
    charsInUse,
    credits: Math.ceil(text.length / appendedHeader) * (isMMS ? 3 : 1),
  }

  return values
}

export function replaceEscapeChars(text) {
  let hasSpecialChars = false

  const charsMap = {
    "'": ['`', '´', '‘', '`', '´', '’'],
    '"': ['¨', '“', '„', '¨', '”', '˝'],
    '~': ['˜'],
    ' ': [/([\u0000-\u0007]|[\u0010-\u0017]|[\u0021-\u0027]|[\u0030-\u0037]|\u0177)/],
  }

  Object.entries(charsMap).forEach(([key, patterns]) => {
    patterns.forEach(pattern => {
      const regex = new RegExp(pattern)
      if (regex.exec(text)) {
        text = text.replace(regex, key)
        hasSpecialChars = true
      }
    })
  })

  return { text, hasSpecialChars }
}

const stateToProps = ({ currentBrand }) => ({ currentBrand })

class SMSEditorProvider extends PureComponent {
  actionsRef = {}

  _minifiedDummyUrl = null

  setValues = null

  state = {
    loading: true,
    custom_sms_message: '',
    coupon_code: '',
    images: [],
    minifiedLinks: [],
    minifiedLinksList: [],
    ignoredCoupons: [],
  }

  _minifiedDummyUrl = generateMinifiedPlaceholder(this.props.currentBrand.website)

  setValues = _.debounce(values => {
    const { setValues } = this.props
    setValues(values)
  }, 500)

  componentDidMount() {
    const { message: messageData } = this.props
    const {
      coupon_code,
      url_link,
      image_url,
      custom_sms_message,
    } = messageData
    const newState = {
      custom_sms_message: custom_sms_message || DEFAULT_MESSAGE,
      coupon_code,
      images: image_url ? [{ fullSrc: image_url }] : [],
      loading: false,
    }

    // // TODO: Check how to solve this issue here when *|LINK|* but no url_link
    if (/(\*\|LINK\|\*|https:\/\/.*\.do?jo?mo?jo?\.io\/\w{8}x?)/.test(custom_sms_message) && url_link) {
      newState.custom_sms_message = custom_sms_message.replace(/(\*\|LINK\|\*|https:\/\/.*\.do?jo?mo?jo?\.io\/\w{8}x?)/g, this._minifiedDummyUrl)

      const url = { originalUrl: url_link, minifiedUrl: this._minifiedDummyUrl }

      newState.minifiedLinks = [{ ...url }]
      newState.minifiedLinksList = [{ ...url }]
    }

    this.setState(newState)
    this.setValues({ dummyLink: this._minifiedDummyUrl, skipDirty: true })
  }

  componentDidUpdate(prevProps) {
    const { message: { coupon_code: coupon, url_link: url } } = this.props
    const couponChanged = prevProps.message.coupon_code !== coupon
    const urlChanged = prevProps.message.url_link !== url
    // eslint-disable-next-line react/no-did-update-set-state
    if (couponChanged) this.setState({ coupon_code: coupon })
    if (urlChanged) {
      const { minifiedLinksList: alreadyUsedLinks } = this.state
      const minifiedLinks = [{ originalUrl: url, minifiedUrl: this._minifiedDummyUrl }]
      const minifiedLinksList = [...alreadyUsedLinks, minifiedLinks[0]]
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ minifiedLinks, minifiedLinksList })
    }
  }

  _getValues = () => {
    const {
      coupon_code, ignoredCoupons, images, custom_sms_message, minifiedLinks, minifiedLinksList,
    } = this.state

    return {
      images,
      type: images.length ? 'MMS' : 'SMS',
      text: custom_sms_message,
      coupon: coupon_code,
      minifiedLinks,
      ignoredCoupons,
      minifiedLinksList,
      ...calculateCredits(this._getTextToSend(), images.length > 0),
    }
  }

  _getActions = () => ({
    setText: this._onTextChange,
    clearItem: item => this.setState({ [item]: item === 'coupon' ? '' : [] }),
    onSetImages: this._onSetImages,
    onLinkMinifyAndInsert: this._onLinkMinifyAndInsert,
    onCouponTrack: this._onCouponTrack,
    onDismissCouponTrack: this._onDismissCouponTrack,
    onAddTag: this._onAddTag,
    actionsRef: this.actionsRef,
  })

  _onSetImages = (img, isDelete = false) => {
    const images = [img]

    this.setState({ images: isDelete ? [] : images }, () => {
      this.setValues({
        image_url: isDelete ? null : getImage(img),
        image_obj: isDelete ? null : img,
      })
    })
  }

  _onAddTag = which => {
    const newTag = `*|${which
      .toUpperCase()
      .split(' ')
      .join('_')}|*`

    if (this.actionsRef.insertText) this.actionsRef.insertText(newTag)

    // TODO: Check this part
    setTimeout(() => {
      const { custom_sms_message } = this.state
      this.setValues({ custom_sms_message })
    }, 50)
  }

  _onCouponTrack = (coupon, cb = () => {}) => {
    this.setState({ coupon_code: coupon.trim() }, () => {
      this.setValues({ coupon_code: coupon.trim() })
      cb()
    })
  }

  _onDismissCouponTrack = (code, cb = () => {}) => {
    this.setState(({ ignoredCoupons }) => ({ ignoredCoupons: [...ignoredCoupons, code] }))
    cb()
    this.actionsRef.editor.focus()
  }

  _onLinkMinifyAndInsert = (originalUrl, cb = () => {}, isInsert = false) => {
    const { currentBrand: { website }, setValues } = this.props
    let { custom_sms_message } = this.state

    if (isInsert && this.actionsRef.insertText) this.actionsRef.insertText(this._minifiedDummyUrl)
    else {
      const sanitizedWebsite = sanitizeWebsite(website)
      const REGEX = new RegExp(`(https://${sanitizedWebsite}.djmj.io/.{8}|${originalUrl})`, 'g')

      custom_sms_message = custom_sms_message.replace(REGEX, this._minifiedDummyUrl)
    }

    const minifiedLinks = [
      {
        originalUrl: /http(s)?:\/\//.test(originalUrl) ? originalUrl : `http://${originalUrl}`,
        minifiedUrl: this._minifiedDummyUrl,
      },
    ]

    this.setState(
      ({ minifiedLinksList }) => ({
        custom_sms_message,
        minifiedLinks,
        minifiedLinksList: [...minifiedLinksList, minifiedLinks[0]],
      }),
      () => setValues({ custom_sms_message, url_link: originalUrl })
    )

    cb()
  }

  _getTextToSend = () => {
    const { currentBrand: { accountname } } = this.props
    const { url_link, coupon_code, custom_sms_message: msg } = this.state

    const MERGE_TAGS = ['COUPON_CODE', 'BRAND_NAME', 'LINK', 'COUPON']

    const regex = new RegExp(`\\*\\|(${MERGE_TAGS.join('|')})\\|\\*`)

    const mapping = {
      LINK: url_link ? this._minifiedDummyUrl : '',
      COUPON_CODE: coupon_code || '',
      BRAND_NAME: accountname,
      COUPON: coupon_code || '',
    }

    return msg
      .split(/\s|\n/)
      .map(textPart => {
        const match = regex.exec(textPart)
        if (match) {
          const [tag, tagKey] = match

          return textPart.replace(tag, mapping[tagKey])
        }

        return textPart
      })
      .join(' ')
  }

  _onTextChange = text => {
    const { custom_sms_message } = this.state

    if (text === custom_sms_message) return

    this.setState({ custom_sms_message: text }, () => {
      this.setValues({ custom_sms_message: text })
    })
  }

  render() {
    const { children } = this.props
    const { loading } = this.state
    const values = this._getValues()
    const actions = this._getActions()

    if (loading) return null

    return (
      <SMSEditorContext.Provider
        value={{
          ...values,
          actions,
        }}
      >
        {children}
      </SMSEditorContext.Provider>
    )
  }
}

SMSEditorProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  currentBrand: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
}

export default connect(stateToProps)(SMSEditorProvider)
