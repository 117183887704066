import React, { Component } from 'react'
import { string, array, object, func } from 'prop-types'

import FilterBox from './filter-box'
import RadioSlider from 'visual-components/util/filters/radio-slider'

class RadioSliderFilter extends Component {
  static propTypes = {
    // filter box props
    title: string.isRequired,
    tooltip: string,
    
    // radio props
    outerName: string.isRequired,
    options: array,
    
    // slider props
    range: object.isRequired,
    format: func,
    extraStartText: string,
    extraEndText: string,
    secondaryStartText: string,
  }

  render() {
    const { 
      title, 
      tooltip, 
      outerName, 
      options,
      range,
      format,
      extraStartText,
      extraEndText,
      secondaryStartText,
    } = this.props

    return (
      <FilterBox
        width="184px"
        title={title}
        tooltip={tooltip}
        paddingBot="16px"
        name={outerName}
      >
        <RadioSlider 
          outerName={outerName} 
          options={options} 
          range={range}
          format={format}
          extraStartText={extraStartText}
          extraEndText={extraEndText}
          secondaryStartText={secondaryStartText}
        />
      </FilterBox>
    )
  }
}

export default RadioSliderFilter
