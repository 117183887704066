/*
*******SAMPLE CALL*********

EventTrackingDispatcher.track('home_click_header')

ga: {
  event_label: 'some label',
  event_category: 'some category',
  action: 'some action',
},
*/

const eventsInventory = {
  home_navBar_click: {
    ga: {
      event_category: 'Nav Bar',
      action: 'Click',
    },
  },
  home_register_emailInput_mainButton: {
    ga: {
      event_label: 'Main Button',
      event_category: 'Register',
      action: 'Email Input',
    },
  },
  home_register_registerButton_mainButton: {
    ga: {
      event_label: 'Main Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  home_register_emailInput_footerButton: {
    ga: {
      event_label: 'Footer Button',
      event_category: 'Register',
      action: 'Email Input',
    },
  },
  home_register_registerButton_footerButton: {
    ga: {
      event_label: 'Footer Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  home_register_registerButton_navBarButton: {
    ga: {
      event_label: 'Nav Bar Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_mainBasicButton: {
    ga: {
      event_label: 'Main Basic Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_mainEssentialButton: {
    ga: {
      event_label: 'Main Essential Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_mainPremiumButton: {
    ga: {
      event_label: 'Main Premium Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_mainEliteButton: {
    ga: {
      event_label: 'Main Elite Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_faqButton: {
    ga: {
      event_label: 'FAQ Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_topGridBasicButton: {
    ga: {
      event_label: 'Top Grid Basic Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_topGridEssentialButton: {
    ga: {
      event_label: 'Top Grid Essential Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_topGridPremiumButton: {
    ga: {
      event_label: 'Top Grid Premium Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_bottomGridBasicButton: {
    ga: {
      event_label: 'Bottom Grid Basic Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_bottomGridEssentialButton: {
    ga: {
      event_label: 'Bottom Grid Essential Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_register_registerButton_bottomGridPremiumButton: {
    ga: {
      event_label: 'Bottom Grid Premium Button',
      event_category: 'Register',
      action: 'Register Button',
    },
  },
  homePricing_contactUs_click_topHelpButton: {
    ga: {
      event_label: 'Top Help Button',
      event_category: 'Contact Us',
      action: 'Click',
    },
  },
  homePricing_contactUs_click_faqHelpButton: {
    ga: {
      event_label: 'FAQ Help Button',
      event_category: 'Contact Us',
      action: 'Click',
    },
  },
  homePricing_contactUs_click_bottomHelpButton: {
    ga: {
      event_label: 'Bottom Help Button',
      event_category: 'Contact Us',
      action: 'Click',
    },
  },
  homePricing_register_priceToggle_annualPriceSwitch: {
    ga: {
      event_label: 'Annual Price Switch',
      event_category: 'Register',
      action: 'Price Toggle',
    },
  },
  appAnalytics_export_exportButton_mainExportButton: {
    ga: {
      event_label: 'Main Export Button',
      event_category: 'Export',
      action: 'Export Button',
    },
  },
  appAnalytics_export_exportButton_campaignExportButton: {
    ga: {
      event_label: 'Campaign Export Button',
      event_category: 'Export',
      action: 'Export Button',
    },
  },
  appPartnerships_partnership_createPartnership_createPartnershipButton: {
    ga: {
      event_label: 'Create Partnership Button',
      event_category: 'Partnership',
      action: 'Create Partnership',
    },
  },
  appPartnerships_partnership_createPartnership_createPartnershipHostGiveaway: {
    ga: {
      event_label: 'Create Partnership - Host Giveaway',
      event_category: 'Partnership',
      action: 'Create Partnership',
    },
  },
  appPartnerships_partnership_createPartnership_createPartnershipPostOpportunity: {
    ga: {
      event_label: 'Create Partnership - Post Opportunity',
      event_category: 'Partnership',
      action: 'Create Partnership',
    },
  },
  appPartnerships_partnership_createPartnership_opportunityStep1Complete: {
    ga: {
      event_label: 'Opportunity Step 1 Complete',
      event_category: 'Partnership',
      action: 'Create Partnership',
    },
  },
  appPartnerships_partnership_createPartnership_opportunityStep2Complete: {
    ga: {
      event_label: 'Opportunity Step 2 Complete',
      event_category: 'Partnership',
      action: 'Create Partnership',
    },
  },
  appPartnerships_partnership_createPartnership_opportunityStep3Complete: {
    ga: {
      event_label: 'Opportunity Step 3 Complete',
      event_category: 'Partnership',
      action: 'Create Partnership',
    },
  },
  appExploreBrands_explore_searchInput_brands: {
    ga: {
      event_label: 'Brands',
      event_category: 'Explore',
      action: 'Search Input',
    },
  },
  appExploreCampaign_explore_searchInput_campaigns: {
    ga: {
      event_label: 'Campaigns',
      event_category: 'Explore',
      action: 'Search Input',
    },
  },
  appExploreBrands_invite_invite_inviteButton: {
    ga: {
      event_label: 'Invite Button',
      event_category: 'Invite',
      action: 'Invite',
    },
  },
  app_upgrade_upgradeButton_hostUpgradeModal: {
    ga: {
      event_label: 'Host Upgrade Modal',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  app_upgrade_upgradeButton_messageUpgradeModal: {
    ga: {
      event_label: 'Message Upgrade Modal',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  app_upgrade_upgradeButton_applicationUpgradeModal: {
    ga: {
      event_label: 'Application Upgrade Modal',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  app_upgrade_upgradeButton_socialActionsUpgradeModal: {
    ga: {
      event_label: 'Social Actions Upgrade Modal',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  app_upgrade_upgradeButton_customCTAUpgradeModal: {
    ga: {
      event_label: 'Custom CTA Upgrade Modal',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  app_upgrade_upgradeButton_expiringUpgradeModal: {
    ga: {
      event_label: 'Expiring Upgrade Modal',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_upgradeButton_topGridEssentialButton: {
    ga: {
      event_label: 'Top Grid Essential Button',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_upgradeButton_topGridPremiumButton: {
    ga: {
      event_label: 'Top Grid Premium Button',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_upgradeButton_topGridEliteButton: {
    ga: {
      event_label: 'Top Grid Elite Button',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_upgradeButton_bottomGridEssentialButton: {
    ga: {
      event_label: 'Bottom Grid Essential Button',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_upgradeButton_bottomGridPremiumButton: {
    ga: {
      event_label: 'Bottom Grid Premium Button',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_upgradeButton_bottomGridEliteButton: {
    ga: {
      event_label: 'Bottom Grid Elite Button',
      event_category: 'Upgrade',
      action: 'Upgrade Button',
    },
  },
  appUpgradePlan_upgrade_priceToggle_annualPriceSwitch: {
    ga: {
      event_label: 'Annual Price Switch',
      event_category: 'Upgrade',
      action: 'Price Toggle',
    },
  },
  appUpgradePlan_upgrade_click_contactUs: {
    ga: {
      event_label: 'Contact Us',
      event_category: 'Upgrade',
      action: 'Click',
    },
  },
  emailReleaseIntentionClick: {},
}

const mapping = {
  ga: ({ event_label, event_category, action }) => {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category,
        event_label,
      })
    }
  },
}

const EventTrackingDispatcher = {
  track(name) {
    if (eventsInventory[name]) {
      Object.keys(eventsInventory[name]).forEach(type => {
        const data = eventsInventory[name][type]
        const eventFunction = mapping[type]
        if (eventFunction) {
          eventFunction(data)
        }
      })
    }
  },
}

export default EventTrackingDispatcher
