import React, { Component } from 'react'

import { Input } from 'visual-components/util/form'
import { isPositive, cannotExceed } from 'visual-components/util/form/validations'

class PrizingPartnerOpportunityInfo extends Component {
  render() {
    return (
      <div>
        <Input
          required
          validations={[isPositive, cannotExceed(1e6)]}
          name="value"
          type="number"
          label="Estimated Prizing Value"
          placeholder="100,000"
        />
        <Input
          required
          name="restrictions"
          characterLimit={90}
          label="Restrictions"
          sublabel="Describe any restrictions on who can receive your prizing"
          placeholder="I.e. Alcohol, only 21+"
        />
        <Input
          required
          name="contribution"
          characterLimit={120}
          label="Contribution"
          sublabel="Share the type of prizing you are willing to contribute"
          placeholder="I.e. A trip for two to Mexico with $100 giftcard to tequila distillery"
        />
      </div>
    )
  }
}

export default PrizingPartnerOpportunityInfo
