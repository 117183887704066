import React, { Component } from 'react'
import { func, array, string, object, any } from 'prop-types'
import ReactDOM from 'react-dom'

import Dropdown from 'react-toolbox/lib/dropdown'
import DropdownFilterTheme from 'css/themes/dropdownFilter.css'
import { H6 } from 'visual-components/util/texts'

class SortByFilter extends React.Component {
  static contextTypes = {
    handleChangeImmediately: func,
    searchModel: object,
  }

  static propTypes = {
    onChange: func,
    source: array,
    name: string,
    value: any,
  }

  static defaultProps = {
    name: 'sortBy',
  }

  customItem = item => {
    /* this breaks out of the normal loop, allowing for customization of the chosen value */
    return <div className={DropdownFilterTheme.customItem}>{item.label}</div>
  }

  onChange = value => {
    // if onChange prop, override behavior
    const { onChange, name } = this.props
    if (onChange) {
      return onChange(name, value)
    }

    // default behavior using context
    const {
      value: oldValue,
      asc: oldAsc,
      defaultDirections,
    } = this.context.searchModel[name]

    // if selecting same sortBy option, then flip asc/desc
    // otherwise use default direction
    const asc =
      value === oldValue
        ? !oldAsc
        : defaultDirections ? !!defaultDirections[value] : false

    this.context.handleChangeImmediately(name, { value, asc })
  }

  render() {
    const { name } = this.props
    // get options and value from props if present, otherwise context
    const { searchModel } = this.context
    const { source, options, value } = this.props.source
      ? this.props
      : searchModel && searchModel[name]

    return (
      <div style={{ height: '24px', display: 'inline' }}>
        <div style={{ display: 'inline-block', height: '14px' }}>
          <H6>Sort by:</H6>
        </div>
        <div style={{ display: 'inline-block', height: '14px' }}>
          <Dropdown
            auto
            onChange={this.onChange}
            source={source || options}
            value={value}
            theme={DropdownFilterTheme}
            template={this.customItem}
          />
        </div>
      </div>
    )
  }
}

export default SortByFilter
