import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PropTypes from 'prop-types'

import DistributionGroupInputs from 'visual-components/util/inputs/distribution-group-inputs'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import SellerProfileActions from 'actions/seller-profile-actions'

const dispatchToProps = dispatch => ({
  sellerProfileActions: bindActionCreators(SellerProfileActions, dispatch),
})

const stateToProps = ({ sellerProfileModals, sellerProfile }) => ({
  isOpen: sellerProfileModals.showGenderDistributionModal,
  sellerProfile,
})
class GenderDistributionModal extends Component {
  static propTypes = {
    sellerProfileActions: PropTypes.object.isRequired,
    sellerProfile: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = {
    female: null,
    male: null,
  }

  onSave = () => {
    const { female } = this.state
    const { sellerProfileActions } = this.props
    sellerProfileActions.updateSellerProfile({ gender_distribution_female: female / 100 })
    this.onClose()
  }

  onClose = () => {
    const { sellerProfileActions } = this.props
    sellerProfileActions.closeModal()
  }

  onUpdate = ({ valueA, valueB }) => {
    this.setState({ male: valueA, female: valueB })
  }

  componentDidUpdate = prevProps => {
    const { isOpen, sellerProfile } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      if (sellerProfile && sellerProfile.gender_distribution_female) {
        const female = Number(sellerProfile.gender_distribution_female) * 100
        const male = 100 - female

        this.setState({
          male,
          female,
        })
      }
    }
  }

  render() {
    const {
      female,
      male,
    } = this.state

    const { isOpen } = this.props

    const canSave = !!female || !!male

    return (
      <MultiTaskModal
        active={isOpen}
        close={this.onClose}
        title="Gender Distribution"
      >
        <div style={{ marginBottom: '16px' }}>
          <H4 multiline>
            Add the gender distribution of your audience.
          </H4>
        </div>
        <DistributionGroupInputs
          valueA={male}
          labelA="Male"
          valueB={female}
          labelB="Female"
          onUpdate={this.onUpdate}
        />
        <ModalButtonGroup
          canSave={canSave}
          confirm={this.onSave}
          cancel={this.onClose}
          confirmText="Done"
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(GenderDistributionModal)
