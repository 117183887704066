import React from 'react';
import moment from 'moment-timezone';
import Text from 'util/components/text';
import Table from 'util/components/standardized-tables';
import _ from 'lodash'

class BrandDetailView extends React.Component {
  render() {
    const { brand } = this.props;
    const copy = LANGUAGE.brandSettings;

    const tags = _.map( brand.tagsconnected, function( tag, idx ){
      return(
        <span key={ idx }>
          <Text.dojoFinePrintBold>
            { tag.name }
          </Text.dojoFinePrintBold>
          <br />
        </span>
      )
    })
    return (
      <Table.Table allWhite={ true }>
        <Table.Tbody>
          <Table.HeaderTr>
            <Table.LeftTh>
            </Table.LeftTh>
            <Table.Th>
            </Table.Th>
          </Table.HeaderTr>
        </Table.Tbody>

        <Table.Tbody>

          { /* list size */ }
          <Table.Tr>
            <Table.LeftTd textStyles={[{
              color: COLOURS.darkGreen.point6
            }]}>
              { copy.sizes.dedicated }
            </Table.LeftTd>
            <Table.Td>
              { !brand.dedicatedListSize ? '-' : d3.format(',')(brand.dedicatedListSize) }
            </Table.Td>
          </Table.Tr>

          { /* social media impressions */ }
          { brand.socialMediaImpressions ?
            <Table.Tr>
              <Table.LeftTd textStyles={[{
                color: COLOURS.darkGreen.point6
              }]}>
                { copy.detail.socialMediaImpressions }
              </Table.LeftTd>
              <Table.Td>
                { d3.format(',')(brand.socialMediaImpressions) }
              </Table.Td>
            </Table.Tr>
            : null
          }

          { /* facebook followers */ }
          { brand.facebookFollowers ?
            <Table.Tr>
              <Table.LeftTd textStyles={[{
                color: COLOURS.darkGreen.point6
              }]}>
                { copy.detail.facebookFollowers }
              </Table.LeftTd>
              <Table.Td>
                { d3.format(',')(brand.facebookFollowers) }
              </Table.Td>
            </Table.Tr>
            : null
          }

          { /* twitter followers */ }
          { brand.twitterFollowers ? 
            <Table.Tr>
              <Table.LeftTd textStyles={[{
                color: COLOURS.darkGreen.point6
              }]}>
                { copy.detail.twitterFollowers }
              </Table.LeftTd>
              <Table.Td>
                { d3.format(',')(brand.twitterFollowers) }
              </Table.Td>
            </Table.Tr>
            : null
          }

          { /* instagram followers */ }
          { brand.instagramFollowers ?
            <Table.Tr>
              <Table.LeftTd textStyles={[{
                color: COLOURS.darkGreen.point6
              }]}>
                { copy.detail.instagramFollowers }
              </Table.LeftTd>
              <Table.Td>
                { d3.format(',')(brand.instagramFollowers) }
              </Table.Td>
            </Table.Tr>
            : null
          }

          { /* average signups per campaigns */ }
          { brand.averageSignUpsPerCampaign ?
            <Table.Tr>
              <Table.LeftTd textStyles={[{
                color: COLOURS.darkGreen.point6
              }]}>
                { copy.detail.averageSignUpsPerCampaign }
              </Table.LeftTd>
              <Table.Td>
                { d3.format(',')(brand.averageSignUpsPerCampaign) }
              </Table.Td>
            </Table.Tr>
            : null
          }

          { /* campaigns in last 90 days */ }
          <Table.Tr>
            <Table.LeftTd textStyles={[{
              color: COLOURS.darkGreen.point6
            }]}>
              { copy.detail.numberOfCampaigns90 }
            </Table.LeftTd>
            <Table.Td>
              { d3.format(',')(brand.campaignsLast90Days) }
            </Table.Td>
          </Table.Tr>

          { /* industry categories */ }
          <Table.Tr>
            <Table.LeftTd textStyles={[{
              color: COLOURS.darkGreen.point6
            }]}
            styles={[{
              width: '50%'
            }]}>
              { copy.detail.industryCategories }
            </Table.LeftTd>
            <Table.Td>
              { tags }
            </Table.Td>
          </Table.Tr>
        { /* member since */ }
          <Table.Tr>
            <Table.LeftTd textStyles={[{
              color: COLOURS.darkGreen.point6
            }]}>
              { copy.detail.memberSince }
            </Table.LeftTd>
            <Table.Td>
              { moment(brand.created_at).format('l') }
            </Table.Td>
          </Table.Tr>
          
        </Table.Tbody>
      </Table.Table>
    )
  }
}

export default BrandDetailView;
