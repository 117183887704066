import React from 'react'
import FormContainer from 'v2/components/templates/FormContainer'
import HeroImage from 'v2/components/templates/HeroImage'
import Logo from 'v2/components/templates/Logo'
import Text from 'v2/components/templates/Text'
import TemplateContainer from 'v2/components/templates/TemplateContainer'
import BackgroundOverlay from 'v2/components/templates/BackgroundOverlay'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { templateStyles } from 'v2/components/templates/styles/version-15'
import styleHelper from 'v2/styles/style-helper'
import cn from 'classnames'
import NotStartedWarning from './NotStartedWarning'
import PartnerLogos from 'v2/components/templates/PartnerLogos'
import Prizing from 'v2/components/templates/Prizing'
import Disclaimer from 'v2/components/templates/Disclaimer'

const styles = {
  background: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    padding: '154px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    height: props => (
      props.desktopVersion
        ? (props.updateAttr ? 'calc(100vh - 157px)' : '100vh')
        : '100%'
    ),
    [styleHelper.max(1100)]: {
      padding: 0,
      height: '100vh',
    },
  },
  backgroundParalax: {
    position: 'relative',
    overflow: 'hidden',
  },
  bottomInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: props => (props.desktopVersion ? '40px 100px 0 100px' : '40px auto 0 auto'),
    [styleHelper.max(1100)]: {
      margin: '0 !important',
      width: '100% !important',
      padding: '44px !important',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
    opacity: 0.3,
  },
  bodyContainer: {
    display: 'flex',
    padding: props => (props.desktopVersion ? 60 : 20),
    flexDirection: props => (props.desktopVersion ? 'row' : 'column'),
    [styleHelper.max(1100)]: {
      flexDirection: 'column !important',
      padding: '20px !important',
    },
  },
  topLogoContainer: {
    display: 'flex',
    marginBottom: 40,
  },
  leftColumn: {
    width: props => (props.desktopVersion ? '50%' : '100%'),
    [styleHelper.max(1100)]: {
      width: '100% !important',
    },
  },
  formContainer: {
    width: props => (props.desktopVersion ? '50%' : '100%'),
    maxWidth: 1000,
    overflow: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [styleHelper.max(1100)]: {
      width: '100% !important',
    },
    padding: 20,
  },
  headlineContainer: {
    position: 'relative',
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 30px)'),
    margin: props => (props.desktopVersion ? '0 60px 40px 60px' : '30px 15px'),
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 30px)',
      margin: '30px 15px',
    },
  },
  preEntryMessageContainer: {
    position: 'relative',
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 30px)'),
    margin: props => (props.desktopVersion ? '0 60px 60px 60px' : '15px'),
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 30px)',
      margin: '15px',
    },
  },
  detailsContainer: {
    marginBottom: 32,
  },
  button: {
    border: 'none',
    borderRadius: 20,
    backgroundColor: '#1976d2',
    color: '#fff',
    textAlign: 'center',
    padding: 12,
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: 200,
    zIndex: 2,
  },
  ...templateStyles,
}

class Version15 extends React.Component {
  static propTypes = {
    opts: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool,
    isEditorMenuOpen: propTypes.bool,
  }

  static defaultProps = {
    desktopVersion: true,
    isEditorMenuOpen: false,
  }

  static childContextTypes = {
    baseStyles: propTypes.object,
    userStyles: propTypes.object,
  }

  formContainerRef = null

  getChildContext() {
    const {
      classes: css,
      opts: {
        elements: {
          styles: userStyles,
        },
      },
    } = this.props

    return {
      baseStyles: css,
      userStyles,
    }
  }

  setFormContainerRef = element => {
    this.formContainerRef = element
  }

  scrollToForm = () => {
    window.scrollTo(0, this.formContainerRef.offsetTop - 20)
  }

  render() {
    const {
      opts: {
        campaign,
        hostBrand,
        elements,
        termsLink,
        acceptedInvites,
        elements: {
          styles: userStyles,
        },
      },
      updateElementsAttr,
      editElements,
      customFonts,
      edit,
      updateAttr,
      agreeToLiability,
      classes: css,
      desktopVersion,
      isEditorMenuOpen,
    } = this.props

    const formStyles = userStyles.form

    const isBackgroundVideo = elements.mainImage.split('.').pop() === 'mp4'

    const buttonStyles = elements.styles.fonts
    return (
      <TemplateContainer
        campaign={campaign}
        elements={elements}
        isFixedBackgroundImage={false}
        desktopVersion={desktopVersion}
        updateAttr={updateElementsAttr}
        campaignUpdateAttr={updateAttr}
        acceptedInvites={acceptedInvites}
        isEditorMenuOpen={isEditorMenuOpen}
        hostBrand={hostBrand}
      >
        <div className={cn(css.backgroundParalax, 'background-parallax')}>
          <div
            className={css.background}
          >
            <HeroImage
              imageUrl={elements.mainImage}
              desktopVersion={desktopVersion}
              isVideo={isBackgroundVideo}
            />
            <BackgroundOverlay
              desktopVersion={desktopVersion}
            />
            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="headLine"
              customFonts={customFonts}
              className={css.headlineContainer}
              width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
            />

            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="preEntryMessage"
              customFonts={customFonts}
              className={css.preEntryMessageContainer}
              width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
            />
            <button
              type="button"
              className={cn(css.button, css.submitButtonButton, 'scroll-to-form')}
              onClick={this.scrollToForm}
              style={{
                background: buttonStyles.entryButtonColor.color.color,
                color: buttonStyles.entryButtonText.color.color,
                fontFamily: buttonStyles.entryButtonText.family,
                fontSize: buttonStyles.entryButtonText.size,
              }}
            >
              ENTER NOW
            </button>
          </div>
        </div>
        <div className={css.bodyContainer} style={{ background: `${formStyles.color}` }}>
          <div className={css.leftColumn}>
            <div
              id="top-logo-container"
              className={css.topLogoContainer}
            >
              <Logo
                hostWebsite={hostBrand.website}
                logoPath={elements.top_logo || hostBrand.logo}
                updateAttr={updateElementsAttr}
                edit={editElements}
                campaign={campaign}
                elements={elements}
                maxHeight={elements.styles.topLogo ? elements.styles.topLogo.height : 100}
                className={css.topLogoImg}
                id="top-logo"
                allowDelete
                editName="top-logo"
                horizontalAlign="left"
              />
            </div>
            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="details"
              customFonts={customFonts}
              className={css.detailsContainer}
              width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
            />
          </div>
          <div
            className={css.formContainer}
            ref={this.setFormContainerRef}
            id="form-holder"
          >
            <FormContainer
              hidePartnerInformation
              updateAttr={updateAttr}
              updateElementsAttr={updateElementsAttr}
              campaign={campaign}
              elements={elements}
              edit={edit}
              editElements={editElements}
              termsLink={termsLink}
              agreeToLiability={agreeToLiability}
              acceptedInvites={acceptedInvites}
              customFonts={customFonts}
              desktopVersion={desktopVersion}
            />
          </div>
        </div>
        <div className={css.bottomInfo}>
          <PartnerLogos
            updateAttr={updateElementsAttr}
            edit={editElements}
            campaign={elements}
            partnerCampaigns={acceptedInvites}
            desktopVersion={desktopVersion}
          />
          <Logo
            logoPath={elements.prizing_logo || campaign.prizingLogo}
            updateAttr={updateElementsAttr}
            editName="prizing-logo"
            edit={editElements}
            campaign={campaign}
            elements={elements}
            maxHeight={elements.styles.prizingLogo ? elements.styles.prizingLogo.height : 100}
            id="prizing-logo"
            allowDelete
            label="PRIZING BY"
            uploadButtonLabel="Upload a prizing logo"
            modalPosition="top"
          />
          <Disclaimer
            campaign={campaign}
            edit={edit}
            updateAttr={updateAttr}
            customFonts={customFonts}
          />
        </div>
      </TemplateContainer>
    )
  }
}

export default injectSheet(styles)(Version15)
