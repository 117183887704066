import React from 'react';
import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet'

import Validator from 'util/form-validator';
import { H2 } from 'visual-components/util/texts';

import { Button } from 'react-toolbox/lib/button';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import Field from 'visual-components/util/inputs/field';
import PasswordField from 'visual-components/util/inputs/password-field';

import AppDispatcher from 'dispatchers/app-dispatcher';
import ButtonTheme from 'css/themes/Buttons.css'

const validations = {
  currentPassword: [Validator.required, Validator.isCorrectPassword],
  newPassword: [Validator.required, Validator.isValidPassword],
  confirmPassword: [Validator.required, Validator.matchPassword]
}

const buttonTheme = {
  Button: ButtonTheme,
}

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    profile: state.profile,
    profileForm: state.profileForm
  }
}

class PasswordSettings extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(e) {
    e.preventDefault();

    var {
      profile,
      profileForm
    } = this.props;

    Validator.validateForm(validations, profile, 'profileForm', function (valid) {
      if (valid) {
        AppDispatcher.http({
          data: {
            currentPassword: profile.currentPassword,
            newPassword: profile.newPassword,
            passwordConfirm: profile.confirmPassword
          },
          url: '/new-password',
          method: 'POST',
          success: function () {
            toastr.success('Saved!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });            
          }
        });
      }
    });
  }

  render() {
    var {
      currentBrand,
      profile,
      profileForm
    } = this.props;

    return(
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>Password</title>
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '24px'
          }}>
            <Col xs>
              <H2 azure>
                Password
              </H2>
            </Col>
          </Row>
          <Row>
            <Col style={{
              width: '360px'
            }}>
              <form onSubmit={ this.submit }>
                <Field label={ 'Verify Current Password' }
                  attrName={ 'currentPassword' }
                  type={ 'password' }
                  dataModel={ profile }
                  formModel={ profileForm }
                  validations={ validations.currentPassword }
                />

                <PasswordField label={ 'New Password' }
                  attrName={ 'newPassword' }
                  type={ 'password' }
                  dataModel={ profile }
                  formModel={ profileForm }
                  validations={ validations.newPassword }
                />

                <Field label={ 'Confirm New Password' }
                  attrName={ 'confirmPassword' }
                  type={ 'password' }
                  dataModel={ profile }
                  formModel={ profileForm }
                  validations={ validations.confirmPassword }
                />

                <Button label={ 'Update' }
                  type={ 'submit' }
                  className={ buttonTheme.Button.greenButton }
                  theme={ buttonTheme.Button }
                  raised
                  style={{
                    fontFamily: 'Larsseit-Medium',
                    fontSize: '14px',
                    width: '79px',
                    height: '36px'
                }}/>
              </form>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
};

export default ReactRedux.connect(mapState)(PasswordSettings);
