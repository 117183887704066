import React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'
import { H4 } from 'visual-components/util/texts'

class NavTrialCountdown extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */

    const { shouldShow, overrideText } = this.props

    if (shouldShow || overrideText) {
      const trialEnd = new Date(this.props.trialEnd)

      const daysLeft = Math.round(Math.abs((trialEnd.getTime() - new Date()) / oneDay))

      return (
        <li
          className="hidden-xs hidden-sm hidden-1065"
          style={{
            letterSpacing: '0',
            position: 'relative',
            display: 'inline-block',
            padding: '0 16px',
            lineHeight: '6.4rem',
            margin: '0 16px !important',
            opacity: '1 !important',
            verticalAlign: 'middle',
            textTransform: 'none',
          }}
        >
          <H4 white>
            <i>{shouldShow ? `${daysLeft} day${daysLeft > 1 ? 's' : ''} left in trial` : overrideText}</i>
          </H4>
        </li>
      )
    }
    // don't show anything if they aren't on a trial. duh.
    return null
  }
}

NavTrialCountdown.propTypes = {
  shouldShow: PropTypes.bool,
  daysLeft: PropTypes.number,
}

export default NavTrialCountdown
