import React from 'react'
import { object, array } from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styles from 'css/themes/custom-input-transition.css'

import { H3, H4, H6 } from 'visual-components/util/texts'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import TargetLinkForm from './TargetLinkForm'

const {
  enter,
  enterActive,
  exit,
  exitActive,
} = styles

class MediaInformation extends React.Component {
  static propTypes = {
    targets: array.isRequired,
    linkActions: object.isRequired,
  }

  render() {
    const {
      targets,
      linkActions,
    } = this.props

    const targetLinkInputs = targets.map((form, index) => (
      <CSSTransition
        key={typeof form.key !== 'undefined' ? `new${form.key}` : form.id}
        timeout={500}
        classNames={{
          enter,
          enterActive,
          exit,
          exitActive,
        }}
      >
        <TargetLinkForm
          data={form}
          index={index}
          editLink={linkActions.editLink}
          removeLink={linkActions.removeLink}
        />
      </CSSTransition>
    ))

    return (
      <div style={{ display: 'inline-block' }}>
        <div className={SellMediaModalTheme.orangeTitle}>
          <H3 coral>
            <small>Media Information</small>
          </H3>
        </div>

        <div className={SellMediaModalTheme.inputs}>
          <div className={SellMediaModalTheme.subtitle}>
            <H4 multiline>
              Add links to where you would like to drive traffic to.
              Edit labels to let the seller know the link purpose.
            </H4>
          </div>

          <div className={SellMediaModalTheme.subtitle}>
            <H4>
              <em>Buyer Target Link</em>
            </H4>
          </div>

          <TransitionGroup children={targetLinkInputs} />

          {/*
            <div
              onClick={linkActions.addLink}
              style={{
                cursor: 'pointer',
              }}
            >
              <H6 azure>
                <img
                  src="/images/icons/circle-add.svg"
                  style={{
                    marginRight: '8px',
                  }}
                />
                Add New Link
              </H6>
            </div>
          */}
        </div>
      </div>
    )
  }
}


export default MediaInformation
