import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import d3 from 'd3'
import Checkbox from 'react-toolbox/lib/checkbox'

import { Input, Validations, SimpleForm } from 'visual-components/util/form'
import ChipInput from 'visual-components/util/inputs/chip-input'
import CheckboxWithInput from 'visual-components/util/CheckboxWithInput'
import { H4, P } from 'visual-components/util/texts'
import InputContainer from 'visual-components/util/InputContainer'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import SellerProfileActions from 'actions/seller-profile-actions'

import SellerTheme from 'css/themes/seller-profile-tab.css'
import CheckboxTheme from 'css/themes/Checkbox.css'
import { uniq } from 'lodash'

const dispatchToProps = dispatch => ({
  sellerProfileActions: bindActionCreators(SellerProfileActions, dispatch),
})

const stateToProps = ({ sellerProfileModals, sellerProfile }) => ({
  isOpen: sellerProfileModals.showMediaSheetModal,
  sellerProfile,
})

const { isPositiveOrZero, cannotExceed } = Validations

const defaultPreferencesData = {
  'Sponsored Email': {
    isChecked: false,
    prettyDisplay: 'Sponsored Email',
  },
  'Dedicated Email': {
    isChecked: false,
    prettyDisplay: 'Dedicated Email',
  },
  'Blog Post': {
    isChecked: false,
    prettyDisplay: 'Blog Post',
  },
  'Banner Ad': {
    isChecked: false,
    prettyDisplay: 'Banner Ad',
  },
  'Social Media Post': {
    isChecked: false,
    prettyDisplay: 'Social Media Post',
  },
  'Instagram Story': {
    isChecked: false,
    prettyDisplay: 'Instagram Story',
  },
  other: {
    isChecked: false,
    prettyDisplay: 'Other',
  },
}

const defaultData = {
  hasError: false,
  preferencesData: defaultPreferencesData,
  otherTags: [],
  cpm: {
    isChecked: false,
    min: null,
    max: null,
    error: false,
    cstmErrorMsg: null,
  },
  cpc: {
    isChecked: false,
    min: null,
    max: null,
    error: false,
    cstmErrorMsg: null,
  },
  flatRate: {
    isChecked: false,
    min: null,
    max: null,
    error: false,
    cstmErrorMsg: null,
  },
  openRate: null,
  ctr: null,
}

const isNumber = num => !isNaN(num)
const _notNumberError = 'You must enter a number'

const roundToCents = (num, decimals) => {
  return Number(Math.round(num + 'e' + decimals) + 'e-' + decimals)
}

const formatDataForState = data => {
  const defaultDblInptState = {
    isChecked: false,
    min: null,
    max: null,
    error: false,
    cstmErrorMsg: false,
  }

  const preferencesData = defaultPreferencesData

  const otherTags = []

  const state = {}

  Object.keys(data).forEach(key => {
    switch (key) {
      case 'cpm_min':
      case 'cpm_max':
        state.cpm = state.cpm ? { ...state.cpm } : { ...defaultDblInptState }
        state.cpm[/min/g.test(key) ? 'min' : 'max'] = data[key]
        if (data[key]) {
          state.cpm.isChecked = true
        }
        break

      case 'cpc_min':
      case 'cpc_max':
        state.cpc = state.cpc ? { ...state.cpc } : { ...defaultDblInptState }
        state.cpc[/min/g.test(key) ? 'min' : 'max'] = data[key]
        if (data[key]) {
          state.cpc.isChecked = true
        }
        break

      case 'flat_rate_min':
      case 'flat_rate_max':
        state.flatRate = state.flatRate ? { ...state.flatRate } : { ...defaultDblInptState }
        state.flatRate[/min/g.test(key) ? 'min' : 'max'] = data[key]
        if (data[key]) {
          state.flatRate.isChecked = true
        }
        break

      case 'open_rate':
        state.openRate = d3.format(',.2f')(data[key] * 100)
        break

      case 'ctr':
        state.ctr =  d3.format(',.2f')(data[key] * 100)
        break

      case 'preferences':
        data[key].forEach(({ tag }) => {
          if (preferencesData[tag]) preferencesData[tag].isChecked = true
          else {
            preferencesData.other.isChecked = true
            otherTags.push(tag)
          }
        })
        break
      default:
        break
    }
  })

  state.preferencesData = { ...preferencesData }
  state.otherTags = [...otherTags]

  return state
}

const retrieveDataFromState = state => {
  const data = {
    preferences: [],
  }

  Object.keys(state).forEach(key => {

    switch (key) {
      case 'preferencesData':
      case 'otherTags':
        Object.keys(state[key]).forEach(stateTag => {
          let tag = null

          // We don't want 'other' as a tag
          if (stateTag === 'other') return

          // This means is in otherTags
          if (/\d/.test(stateTag)) {
            if (state.preferencesData.other.isChecked) {
              tag = state.otherTags[stateTag]
            }
          } else if (state[key][stateTag] && state[key][stateTag].isChecked) {
            tag = stateTag
          }

          if (!tag) return

          data.preferences.push({ tag })
        })
        break
      case 'cpm':
      case 'cpc':
        if (state[key].isChecked) {
          data[`${key}_min`] = state[key].min
          data[`${key}_max`] = state[key].max
        } else {
          data[`${key}_min`] = null
          data[`${key}_max`] = null
        }
        break
      case 'flatRate':
        if (state[key].isChecked) {
          data.flat_rate_min = state[key].min
          data.flat_rate_max = state[key].max
        } else {
          data.flat_rate_min = null
          data.flat_rate_max = null
        }
        break
      case 'ctr':
        data.ctr = state[key] / 100
        break
      case 'openRate':
        data.open_rate = state[key] / 100
        break
      default:
        break
    }
  })

  return data
}

class MediaSheetModal extends Component {
  static propTypes = {
    sellerProfileActions: PropTypes.object.isRequired,
    sellerProfile: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = { ...defaultData }

  onSave = () => {
    this.checkIfError(() => {
      const { hasError } = this.state
      const { sellerProfileActions } = this.props

      if (hasError) return

      const data = retrieveDataFromState({ ...this.state })

      // make sure that on the double inputs both fields are filled in.
      const doubleInputFields = ['cpm', 'cpc', 'flat_rate']
      for (let i = 0; i < doubleInputFields.length; i += 1) {
        const field = doubleInputFields[i]
        if (
          (data[`${field}_min`] !== null && data[`${field}_max`] === null)
          || (data[`${field}_min`] === null && data[`${field}_max`] !== null)
        ) {
          this.setState(prevState => ({
            [field]: {
              ...prevState[field],
              cstmErrorMsg: 'Both fields must be filled in',
              error: true,
            },
          }))
          return
        }
      }

      sellerProfileActions.updateSellerProfile(data)
      this.onClose()
    })
  }

  onClose = () => {
    const { sellerProfileActions } = this.props
    sellerProfileActions.closeModal()
  }

  onInputChange = newState => {
    const newStateKeys = Object.keys(newState)
    if (newStateKeys.includes('openRate') || newStateKeys.includes('ctr')) {
      return this.setState(newState)
    }
  }

  onDoubleInputCheck = name => val => {
    const { [name]: state } = this.state
    state.isChecked = val

    return this.setState({ [name]: { ...state } }, this.checkIfError)
  }

  onDoubleInputBlur = name => value => {
    const { [name]: state } = this.state
    const key = Object.keys(value)[0]
    const val = value[key]
    state[key === 'first' ? 'min' : 'max'] = Number(val)
    state.error = null
    state.cstmErrorMsg = null

    const isMax = roundToCents(state.min, 2) >= roundToCents(state.max, 2) && isNumber(state.min) && isNumber(state.max)

    if (isMax) {
      state.error = true
      state.cstmErrorMsg = 'Min must be less than Max'
    }

    this.setState({ [name]: { ...state } }, this.checkIfError)
  }

  onDoubleInputError = name => error => {
    const { [name]: state } = this.state
    this.setState({ [name]: { ...state, error } }, this.checkIfError)
  }

  onCheck = (name, checked) => {
    const { preferencesData } = this.state
    const newPrefData = { ...preferencesData }
    newPrefData[name].isChecked = checked
    return this.setState({ preferencesData: { ...newPrefData } }, this.checkIfError)
  }

  onChipAdd = item => {
    const { otherTags, preferencesData } = this.state
    if (!item) return null
    preferencesData.other.isChecked = true
    return this.setState({ otherTags: uniq([...otherTags, `${item}`.trim()]) })
  }

  onChipDeleted = tag => {
    const { otherTags, preferencesData } = this.state
    const newPrefData = { ...preferencesData }
    const items = otherTags.filter(item => item !== tag)
    newPrefData.other.isChecked = items.length > 0
    return this.setState({ otherTags: [...items], preferencesData: { ...newPrefData } })
  }

  checkIfError = (cb = () => {}) => {
    const {
      flatRate,
      cpc,
      cpm,
      openRate,
      ctr,
    } = this.state

    let hasError = false

    hasError = (flatRate.error && flatRate.isChecked)
      || (cpc.error && cpc.isChecked)
      || (cpm.error && cpm.isChecked)
      || openRate > 100
      || openRate < 0
      || ctr > 100 || ctr < 0
      || (cpm.isChecked && (!cpm.min || !cpm.max))
      || (ctr.isChecked && (!ctr.min || !ctr.max))
      || (flatRate.isChecked && (!flatRate.min || !flatRate.max))

    return this.setState({ hasError }, cb)
  }

  isLetter = (error = _notNumberError) => value => {
    if (value.match(/[a-z]/i)) {
      this.setState({ hasError: true })
      throw error
    }
    this.setState({ hasError: false })
  }

  componentDidUpdate = prevProps => {
    const { isOpen, sellerProfile } = this.props
    if (isOpen && prevProps.isOpen !== isOpen) this.setState({ ...formatDataForState(sellerProfile) })
  }


  render() {
    const {
      hasError,
      cpm,
      cpc,
      flatRate,
      preferencesData,
      otherTags,
    } = this.state

    const { isOpen } = this.props

    return (
      <MultiTaskModal
        active={isOpen}
        close={this.onClose}
        title="Media Sheet"
      >
        <div style={{ marginBottom: '16px' }}>
          <H4 multiline>
            Enter information about your media pricing and audience engagement.
          </H4>
        </div>

        <SimpleForm
          model={this.state}
          updateModel={this.onInputChange}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: '8px' }}>
              <Input
                name="openRate"
                label="Open Rate"
                type="string"
                placeholder="25%"
                validations={[
                  cannotExceed(100),
                  this.isLetter()
                ]}
              />
            </div>
            <div style={{ flex: 1, marginLeft: '8px' }}>
              <Input
                name="ctr"
                label="CTR (of Total List)"
                type="string"
                placeholder="1.75%"
                validations={[
                  cannotExceed(100),
                  this.isLetter()
                ]}
              />
            </div>
          </div>
        </SimpleForm>
        <InputContainer
          style={{ marginBottom: '16px' }}
          label="Media Pricing Information (Min and Max)"
        >
          <CheckboxWithInput
            onError={this.onDoubleInputError('cpm')}
            raiseError={cpm.error}
            customErrorMsg={cpm.cstmErrorMsg}
            label="CPM"
            canCheck
            isChecked={cpm.isChecked}
            value={cpm.min}
            formatter={d3.format('$,.2f')}
            onBlur={this.onDoubleInputBlur('cpm')}
            onCheck={this.onDoubleInputCheck('cpm')}
            placeholder="$2.00"
            secondInput
            secondValue={cpm.max}
            secondPlaceholder="$25.00"
            validators={[isPositiveOrZero]}
          />
          <CheckboxWithInput
            onError={this.onDoubleInputError('cpc')}
            raiseError={cpc.error}
            customErrorMsg={cpc.cstmErrorMsg}
            label="CPC"
            canCheck
            isChecked={cpc.isChecked}
            value={cpc.min}
            formatter={d3.format('$,.2f')}
            onBlur={this.onDoubleInputBlur('cpc')}
            onCheck={this.onDoubleInputCheck('cpc')}
            placeholder="$0.25"
            secondInput
            secondValue={cpc.max}
            secondPlaceholder="$2.50"
            validators={[isPositiveOrZero]}
          />
          <CheckboxWithInput
            onError={this.onDoubleInputError('flatRate')}
            raiseError={flatRate.error}
            customErrorMsg={flatRate.cstmErrorMsg}
            label="Flat Rate"
            canCheck
            isChecked={flatRate.isChecked}
            value={flatRate.min}
            formatter={d3.format('$,f')}
            onBlur={this.onDoubleInputBlur('flatRate')}
            onCheck={this.onDoubleInputCheck('flatRate')}
            placeholder="$1,000"
            secondInput
            secondValue={flatRate.max}
            secondPlaceholder="$5,000"
            validators={[isPositiveOrZero]}
          />
        </InputContainer>

        <InputContainer
          label="Looking to Sell"
          subLabel="Choose as many as you want."
          style={{ marginBottom: '16px' }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: preferencesData.other ? '16px' : '32px' }}>
            { Object.keys(preferencesData).map(preferenceName => {
              return (
                <div style={{ marginTop: '16px', width: '202px' }} key={preferenceName}>
                  <Checkbox
                    label={(
                      <div style={{ display: 'flex', paddingTop: '1px' }}>
                        <P style={{ lineHeight: '14px', paddingLeft: '2px' }}>{ preferencesData[preferenceName].prettyDisplay }</P>
                      </div>
                    )}
                    theme={CheckboxTheme}
                    checked={preferencesData[preferenceName].isChecked}
                    onChange={checked => this.onCheck(preferenceName, checked)}
                    className={CheckboxTheme.taskCheckbox}
                  />
                </div>
              )
            })
            }
          </div>
        </InputContainer>
        { preferencesData.other.isChecked
          && (
            <ChipInput
              // Enter, Tab or Comma
              chipTheme={SellerTheme}
              addChipKeyCodes={[13, 9, 188]}
              style={{ marginTop: '-8px', marginBottom: '32px' }}
              list={otherTags}
              onItemAdded={this.onChipAdd}
              onItemDeleted={this.onChipDeleted}
              inputProps={{
                placeholder: 'Hit tab, comma, or return to add multiple media types',
              }}
            />
          )
        }
        <ModalButtonGroup
          canSave={!hasError}
          cancel={this.onClose}
          confirmText="Done"
          confirm={this.onSave}
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(MediaSheetModal)
