import moment from 'moment-timezone'

import React from 'react'
import CalendarTheme from 'css/themes/calendar/calendar.css'
import { func, array } from 'prop-types'
import CampaignBar from './CampaignBar'

class Campaigns extends React.Component {
  static contextTypes = {
    campaigns: array,
    onShowMore: func,
  }

  // eslint-disable-next-line class-methods-use-this
  _filterEventsForWeek(events, week) {
    const eventsForWeek = events.filter(event => {
      return event.start <= moment(week[6]).endOf('day')._d
        && event.end >= moment(week[0]).startOf('day')._d
    })

    return eventsForWeek.slice(0, 8).sort((a, b) => a.start - b.start)
  }

  render() {
    const {
      range
    } = this.props

    const {
      campaigns
    } = this.context

    const eventsForWeek = this._filterEventsForWeek(campaigns, range)

    eventsForWeek.forEach(event => {
      if (event.start > range[0]) {
        event.left = moment(event.start).diff(moment(range[0]), 'day')
      } else {
        event.left = 0
      }

      if (event.end < range[6]) {
        event.right = moment(range[6]).diff(moment(event.end), 'day')
      } else {
        event.right = 0
      }
    })

    return (
      <div className={CalendarTheme.longEventBarHolder}>
        { eventsForWeek.map(event => <CampaignBar event={event} key={event.id} />) }
      </div>
    )
  }
}

export default Campaigns
