import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import SocialChart from 'visual-components/analyticsDashboard/overviewCard/socialChart'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import { connect } from 'react-redux'
import _ from 'lodash'

const mapState = ({ analyticsDashboard }) => {
  const { dashboardSocialMetrics, datePickerSocialMetrics, oldestDate } = analyticsDashboard

  return {
    socialSection: dashboardSocialMetrics,
    datePicker: datePickerSocialMetrics,
    oldestDate,
  }
}

class SocialMetricsGuide extends Component {
  static propTypes = {
    datePicker: PropTypes.object.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
    socialSection: PropTypes.object.isRequired,
  }

  changeDateFilter = (startDate, endDate) => {
    const data = {
      startDate,
      endDate,
      metric: 'dashboardSocialMetrics',
    }

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  toggleType = socialType => {
    const { socialSection } = this.props

    const { hideMap } = socialSection

    hideMap[socialType] = !hideMap[socialType]

    store.dispatch({
      model: 'analyticsDashboard',
      type: 'UPDATE_ATTR',
      data: {
        dashboardSocialMetrics: _.extend({}, socialSection, {
          hideMap,
        }),
      },
    })
  }

  render() {
    const {
      socialSection,
      datePicker,
      oldestDate,
    } = this.props

    return (
      <div style={{ paddingRight: '40px' }}>
        <SocialChart
          data={socialSection}
          datePicker={datePicker}
          toggleItem={this.toggleType}
          changeDateFilter={this.changeDateFilter}
          oldestDate={oldestDate}
        />
      </div>
    )
  }
}

export default connect(mapState)(SocialMetricsGuide)
