import { Component } from 'react'
import { H6 } from 'visual-components/util/texts'
import TooltipWrapper from 'visual-components/util/tooltip/tool-tipped-div'
import TaskCardTheme from 'css/themes/TaskCardTheme.css'



class ButtonItem extends Component {
  render() {
    const {
      onClick,
      imgSrc,
      deselect,
      deselectShowMore,
      tooltip
    } = this.props

    if (onClick) {
      return (
        <div className={ TaskCardTheme.TCButtonWrapper }>
          <TooltipWrapper tooltip={ tooltip }>
            <img src={imgSrc}
              onClick={(e) => {
                e.stopPropagation()
                deselect()
                deselectShowMore()
                onClick()
            }} />
          </TooltipWrapper>
        </div>
      )
    } else {
      return (null)
    }
  }
}

class PopoutTaskcardButtons extends Component {
  render() {
    const {
      exportToCal,
      analytics,
      edit,
      trash,
      sendMessage,
      markComplete,
      manageCampaign,
      deselect,
      campaignId,
      deselectShowMore
    } = this.props

    return(
      <div>

        <div className={ TaskCardTheme.TCButtonContainer } />

        <div className={ TaskCardTheme.TCButtonGroup }>
          <ButtonItem
            onClick={exportToCal}
            deselect={()=>{}}
            deselectShowMore={() => {}}
            tooltip={ 'Export to Calendar' }
            imgSrc="/images/popout-taskcard-button-icons/export-to-calendar.svg" />
          <ButtonItem
            onClick={analytics}
            deselect={deselect}
            deselectShowMore={deselectShowMore}
            tooltip={ 'View Analytics' }
            imgSrc="/images/popout-taskcard-button-icons/analytics.svg" />
          <ButtonItem
            onClick={edit}
            deselect={deselect}
            deselectShowMore={deselectShowMore}
            tooltip={ 'Edit Task' }
            imgSrc="/images/popout-taskcard-button-icons/blue.svg" />
          <ButtonItem
            onClick={trash}
            deselect={deselect}
            deselectShowMore={deselectShowMore}
            tooltip={ 'Delete Task' }
            imgSrc="/images/popout-taskcard-button-icons/trash.svg" />
          <ButtonItem
            onClick={sendMessage}
            deselect={deselect}
            deselectShowMore={deselectShowMore}
            tooltip={ 'Send Message' }
            imgSrc="/images/popout-taskcard-button-icons/send-message.svg" />
          <ButtonItem
            onClick={markComplete}
            deselect={deselect}
            deselectShowMore={deselectShowMore}
            tooltip={ 'Mark Complete' }
            imgSrc="/images/popout-taskcard-button-icons/mark-as-done.svg" />
        </div>
        { manageCampaign &&
          <div className={ TaskCardTheme.TCManageCampaignButton }
            onClick={() => {
              deselect()
              deselectShowMore()
              manageCampaign()
            }}>
            <H6 azure>Manage Campaign</H6>
          </div>
        }
      </div>
    )
  }
}

export default PopoutTaskcardButtons
