import React from 'react'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'
import MultiTaskModal from '../../util/modals/MultiTaskModal'

function UpdatePostEntryWarningModal() {
  const {
    showModal,
    modals,
    onLoadPreviousPostEntryTemplate,
  } = React.useContext(MobileMarketingContext)

  return (
    <MultiTaskModal
      close={() => showModal('overwriteTemplate', false)}
      title="Update Post Entry Form?"
      active={Boolean(modals.overwriteTemplate)}
    >
      <H4 multiline>
        Are you sure you want to update your post entry form? All changes you have made will be lost.
      </H4>
      <ModalButtonGroup
        canSave
        confirm={() => onLoadPreviousPostEntryTemplate(modals.overwriteTemplate, true)}
        cancel={() => showModal('overwriteTemplate', false)}
        confirmText="Update"
        cancelText="Cancel"
        hideLine
      />
    </MultiTaskModal>
  )
}

export default React.memo(UpdatePostEntryWarningModal)
