const initialState = {
  rid: 'recommendation-modals',
  recommendedBrandsLoginModal: {
    open: false,
  },
  loadingRecommendationAnimation: {
    open: false,
    step: 0,
  },
  showMoreTomorrowCard: {
    open: false,
    showCard: false,
  },
  showMeMoreTomorrow: null,
  clearShowMeMoreTomorrow: null,
}

export default function recommendationModalsReducer(state = initialState, action) {
  switch (action.type) {
    case 'REC_BRANDS_OPEN_MODAL':
      return { ...state, recommendedBrandsLoginModal: { open: true } }

    case 'REC_BRANDS_CLOSE_MODAL':
      return { ...state, recommendedBrandsLoginModal: { open: false } }

    case 'REC_BRANDS_OPEN_LOADING_ANIMATION_MODAL':
      return { ...state, loadingRecommendationAnimation: { open: true, step: 0 } }

    case 'REC_BRANDS_CLOSE_LOADING_ANIMATION_MODAL':
      return { ...state, loadingRecommendationAnimation: { open: false, step: 0 } }

    case 'REC_BRANDS_INCREMENT_ANIMATION_STEP':
      return { ...state, loadingRecommendationAnimation: { open: true, step: action.data } }

    case 'REC_BRANDS_FLAG_SHOW_ME_MORE_TOMORROW_CARD':
      return { ...state, showMoreTomorrowCard: { showCard: true } }

    case 'REC_BRANDS_DISPLAY_SHOW_MORE_TOMORROW_CARD':
      return { ...state, showMoreTomorrowCard: { open: true } }

    case 'REC_BRANDS_HIDE_SHOW_MORE_TOMORROW_CARD':
      return { ...state, showMoreTomorrowCard: { open: false } }

    case 'REC_BRANDS_SETTING_SHOW_ME_MORE_TOMORROW':
      return { ...state }
    
    case 'REC_BRANDS_SHOW_ME_MORE_TOMORROW_SUCCESS':
      return { ...state, showMeMoreTomorrow: 'success' }

    case 'REC_BRANDS_SHOW_ME_MORE_TOMORROW_FAILURE':
      return { ...state, showMeMoreTomorrow: 'failed' }

    case 'REC_BRANDS_CLEARING_SHOW_ME_MORE_TOMORROW':
      return { ...state }

    case 'REC_BRANDS_CLEAR_SHOW_ME_MORE_TOMORROW_SUCCESS':
      return { ...state, clearShowMeMoreTomorrow: 'success' }

    case 'REC_BRANDS_CLEAR_SHOW_ME_MORE_TOMORROW_FAILURE':
      return { ...state, clearShowMeMoreTomorrow: 'failed' }

    default:
      return state
  }
}
