import React from 'react'
import { string } from 'prop-types'
import { withRouter } from 'react-router-dom'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { P, H6 } from 'visual-components/util/texts'

class LegalItem extends React.Component {
  static contextTypes = {
    impersonating: string,
  }

  open = url => {
    const { impersonating } = this.context
    window.open(url + (impersonating ? `?impersonating=${impersonating}` : ''))
  }

  render() {
    const { type, name, url } = this.props

    return (
      <Row style={{
        padding: '16px 20px',
        borderTop: '1px solid #d8d8d8'
      }}>
        <Col style={{
          width: '986px'
        }}>
          <div style={{
            marginBottom: '12px'
          }}>
            <H6>
              { type }
            </H6>
          </div>
          <P>
            { name }
          </P>
        </Col>
        <Col style={{
          width: '127px'
        }}>
          <div style={{
            float: 'right'
          }}>
            <img src={ `/images/icons/blue-view.svg` }
              onClick={ e => this.open(url) }
              style={{
                cursor: 'pointer'
            }}/>
          </div>
        </Col>
      </Row>
    )
  }
}

export default withRouter(LegalItem)
