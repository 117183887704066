import React from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import BlueTag from 'visual-components/util/tags/blue-tag';

class CampaignPreviewBlueCards extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      tags,
      fullCampaign
    } = this.props;

    const formattedTags = _.map(tags, function(tag, idx) {
      return (
        <BlueTag
          key={ idx }
          tag={ tag }
        />
      )
    });

    return(
      <Row style={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: COLOURS.silver,
        paddingTop: '8px',
        paddingRight: '8px',
        paddingBottom: '6px',
        paddingLeft: '12px',
        marginTop: '20px'
      }}>
        <Col xs={2}
          style={{
            width: '24px',
            flexBasis: '24px',
            maxWidth: '24px'
        }}>
          <img src="/images/icons/tag-icon.svg"
            style={{
              width: '16px',
              height: '16px'
          }} />
        </Col>
        <Col xs={10}>
          { formattedTags }
        </Col>
        { fullCampaign && 
          <Col>
            <img src="/images/icons/rectangle-2@2x.png" width="30px" height="16px" />
          </Col>
        }
      </Row>
    )
  }
};

CampaignPreviewBlueCards.propTypes = {
  tags: PropTypes.array.isRequired
};

export default CampaignPreviewBlueCards;
