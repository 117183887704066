import React, { PureComponent } from 'react'
import { array, bool, string } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import ReviewsTheme from 'css/themes/ratings-reviews.css'
import ReviewCard from './ReviewCard'

const Separator = ({ large = false }) => (
  <div className={`${ReviewsTheme.separator} ${large ? ReviewsTheme.large : ''}`} />
)

class ReviewsMain extends PureComponent {
  static propTypes = {
    reviews: array.isRequired,
    type: string,
  }

  static defaultProps = {
    type: 'received',
  }

  renderReviews = () => {
    const { reviews, type } = this.props

    return reviews.map((review, idx) => (
      <div key={review.id}>
        <ReviewCard review={{ ...review, isPosted: type === 'posted' }} />
        {idx + 1 !== reviews.length ? <Separator large /> : null}
      </div>
    ))
  }

  render() {
    return (
      <Card style={{ padding: '16px 20px' }}>
        { this.renderReviews() }
      </Card>
    )
  }
}

export default ReviewsMain
