var model = 'promotionalUnit';
import _ from 'lodash'

var defaultState =  {
  impressionsPer: 0,
  editModalOpen: false,
  removeModalOpen: false,
  brandOptions: [],
  typeOptions: [
    {
      label: 'Select a Promotional Channel',
      value: '',
      key: ''
    },{
      label: 'Dedicated',
      value: 'Dedicated',
      key: 'dedicatedListSize'
    },{
      label: 'Newsletter',
      value: 'Newsletter',
      key: 'newsletterListSize'
    }, {
      label: 'Facebook',
      value: 'Facebook',
      key: 'facebookFollowers'
    }, {
      label: 'Twitter',
      value: 'Twitter',
      key: 'twitterFollowers'
    }, {
      label: 'Instagram',
      value: 'Instagram',
      key: 'instagramFollowers'
    }, {
      label: 'Pinterest',
      value: 'Pinterest',
      key: 'pinterestFollowers'
    }, {
      label: 'Banner Ad',
      value: 'Banner Ad',
      key: ''
    }
  ]
}

var PromotionalUnitReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_ATTR: function () {
      if ('brand' in action.data) {
        let updatedBrand = store.getState().currentCampaign.invites
        .map((invite) => invite.invitee)
        .find((invitee) => invitee.id == action.data['brand'])

        let promotionaltype = _.find(state.typeOptions, {value: state.promotionaltype})

        if(updatedBrand && promotionaltype) {
          let newImpression = updatedBrand[promotionaltype.key] || 0;
          action.data['impressionsPer'] = newImpression;
        }
      } else if ('promotionaltype' in action.data) {
        let invite = store.getState().currentCampaign.invites
        .find((invite) => invite.invitee.id == state.brand )

        let type = _.find(state.typeOptions, {value: action.data['promotionaltype']})

        if(invite && type) {
          let newImpression = invite.invitee[type.key] || 0;
          action.data['impressionsPer'] = newImpression;
        }
      }

      return _.extend({}, state, action.data);
    },
    LOAD_SUCCESS: function () {
      let { acceptedCampaignInvites, isHost } = action.data;
      let currentBrand = store.getState().currentBrand
      let brandOptions;
      if (isHost) {
        brandOptions = _.map(acceptedCampaignInvites, invite => {
          return {
            label: invite.invitee.accountname,
            value: invite.invitee.id
          }
        })

        let indexOfBrandInvites = _.findIndex(brandOptions, {value: currentBrand.id});
        if (indexOfBrandInvites > 0) {
          brandOptions.unshift(brandOptions.splice(indexOfBrandInvites,1)[0])
        }
      } else {
        brandOptions = [{
          label: currentBrand.accountname,
          value: currentBrand.id
        }]
      }
      return _.extend({}, state, {brandOptions: brandOptions})
    },
    CLOSE_EDIT_MODAL: function () {
      return _.extend({}, state, {editModalOpen: false})
    },
    OPEN_EDIT_MODAL: function() {
      return _.extend({}, state, {editModalOpen: true})
    },
    EDIT_PROMO: function () {
      let promoUnit = _.extend({}, action.data);
      promoUnit.brand = promoUnit.brand_id;
      return _.extend({}, state, promoUnit, {editModalOpen: true})
    },
    OPEN_REMOVE_MODAL: function () {
      return _.extend({}, state, action.data, {editModalOpen: false, removeModalOpen: true})
    },
    CLOSE_REMOVE_MODAL: function () {
      return _.extend({}, state, {removeModalOpen: false})
    },
    CREATE_PROMO: function () {
      const startDate = store.getState().currentCampaign.startDate
      return _.extend({}, defaultState, {
        brandOptions: state.brandOptions,
        brand: action.data.brand,
        promotionaltype: '',
        editModalOpen: true,
        launchDate: startDate
      })
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default PromotionalUnitReducer;
