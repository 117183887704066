import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import COLOURS from 'util/colours'

import SplitTrafficBadge from './SplitTrafficBadge'
import WrapperTooltip from '../../../util/components/wrapper-tooltip'

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '110px 86px auto',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    width: 36,
    height: 36,
    borderRadius: '50%',
    '& path:first-child': {
      fill: COLOURS.silver,
    },
  },
  active: {
    cursor: 'pointer',
    '& *': {
      transition: 'all .2s linear',
    },
    '&:hover': {
      backgroundColor: COLOURS.foggy,
      '& path:first-child': {
        fill: COLOURS.stone,
      },
    },
    '& path:first-child': {
      fill: COLOURS.azure,
    },
  },
}

const Preview = ({ css, onClick }) => (
  <WrapperTooltip position="top" text="Preview">
    <div onClick={onClick} className={css}>
      <svg width="29" height="17" viewBox="0 0 29 17">
        <path fillRule="nonzero" d="M14.222 0C8.787 0 3.86 2.91.222 7.635a1.083 1.083 0 0 0 0 1.316c3.637 4.732 8.565 7.642 14 7.642 5.435 0 10.364-2.91 14-7.636a1.083 1.083 0 0 0 0-1.315C24.586 2.909 19.657 0 14.222 0zm.39 14.139c-3.608.221-6.587-2.688-6.36-6.224.186-2.916 2.601-5.279 5.58-5.461 3.608-.221 6.587 2.688 6.36 6.224-.192 2.91-2.607 5.273-5.58 5.46zm-.18-2.7a3.278 3.278 0 0 1-2.492-.919 3.088 3.088 0 0 1-.93-2.428c.104-1.575 1.39-2.833 3.008-2.945a3.278 3.278 0 0 1 2.492.92c.656.641.995 1.526.93 2.428-.104 1.578-1.408 2.848-3.008 2.945z" />
      </svg>
    </div>
  </WrapperTooltip>
)

Preview.propTypes = {
  css: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const Message = ({ css, onClick }) => (
  <WrapperTooltip position="top" text="Message">
    <div onClick={onClick} className={css}>
      <svg width="20" height="16" viewBox="0 0 20 16">
        <g fill="none" fillRule="evenodd">
          <path fillRule="nonzero" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" transform="translate(-2 -4)" />
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(-2 -4)" />
        </g>
      </svg>
    </div>
  </WrapperTooltip>
)

Message.propTypes = {
  css: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const statuses = {
  notStarted: {
    notInvited: 'notInvited',
    partnerRequested: 'notInvited',
    invited: 'invited',
    declined: 'declined',
    ready: 'ready',
    notReady: 'notReady',
  },
  running: {
    notInvited: 'notInvited',
    partnerRequested: 'notInvited',
    invited: 'notReady',
    declined: 'declined',
    ready: 'live',
    notReady: 'notLive',
  },
  completed: {
    notInvited: 'notInvited',
    partnerRequested: 'notInvited',
    invited: 'declined',
    declined: 'declined',
    ready: 'completed',
    notReady: 'completed',
  },
}

function SplitTrafficStatusCol(props) {
  const {
    classes: css,
    partner,
    onClickMessage,
    campaignUid,
    campaignStatus,
  } = props

  const getStatus = () => {
    let { status } = partner
    if (status === 'active') {
      status = partner.isPostEntryReady ? 'ready' : 'notReady'
    }
    return statuses[campaignStatus][status]
  }

  const msgActive = partner.status === 'active'
  const prevActive = partner.status === 'active' && partner.isPostEntryReady

  const onPreviewClick = () => {
    if (partner.status !== 'active') return
    window.open(`/landing/post-page/${campaignUid}/${partner.id}?preview=true`)
  }

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <SplitTrafficBadge isStatus status={getStatus()} />
      </div>
      <div className={cn(css.wrapper, css.link)}>
        <Preview
          onClick={onPreviewClick}
          css={cn(css.linkItem, { [css.active]: prevActive })}
        />
      </div>
      <div className={cn(css.wrapper, css.link)}>
        <Message
          onClick={() => onClickMessage(partner, 'message')}
          css={cn(css.linkItem, { [css.active]: msgActive })}
        />
      </div>
    </div>
  )
}

SplitTrafficStatusCol.propTypes = {
  classes: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  onClickMessage: PropTypes.func.isRequired,
  campaignUid: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
}

export default injectCSS(styles)(SplitTrafficStatusCol)
