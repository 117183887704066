import React from 'react';
import _ from 'lodash'

import TermsMap from 'campaigns/builder/customize/terms/terms-map';

import TermsDispatcher from 'dispatchers/terms-dispatcher';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import TertiaryNav from 'visual-components/navBar/tertiary-nav';
import OfficialRulesHostReminderModal from './OfficialRulesHostReminderModal'
import TertiaryNavTheme from 'css/themes/tertiaryNav.css';

const isDisabled = function(key, campaign, campaignTermsDetails, buttonAction) {
  let disabled = false;

  if (!campaign.campaignTerms) {
    disabled = true;
  } else if (!campaign.campaignTerms.termsLiability || (!campaign.campaignTerms.useTemplate && !campaign.campaignTerms.createYourOwn)) {
    disabled = true;
  } else if (campaign.campaignTerms.useTemplate && (key == 'review' || key == 'edit')) {
    if (!buttonAction && !campaign.campaignTerms.termsGeneratedAt) {
      return true;
    }

    const requiredItems = [
      'legalName',
      'privacyPolicy',
    ];

    const hostItems = requiredItems.concat([
      'address1',
      'city',
      'state',
      'zip'
    ]);

    _.each(campaignTermsDetails.items, function(invite) {
      if (invite.invitee_id === campaign.hostBrandId) {
        _.each(hostItems, function (item) {
          if (!invite[item]) {
            disabled = true;
          }
        })
      } else {
        _.each(requiredItems, function (item) {
          if (!invite[item]) {
            disabled = true;
          }
        })
      }
    });
  }

  return disabled;
}

class TermsHeader extends React.Component{
  constructor(props) {
    super(props);

    this.generateTerms = this.generateTerms.bind(this);
    this.publish = this.publish.bind(this);
  }

  generateTerms() {
    const that = this;

    TermsDispatcher.generate(function() {
      BrowserHistoryDispatcher.push(`/builder/${that.props.currentCampaign.id}/terms/review`);
    });
  }

  publish() {
    const { closeHostRulesModal, currentCampaign, analyticsActions } = this.props
    if (currentCampaign.campaignTerms.termsStatus === 'published') {
      const data = {
        termsUnsaved: false
      }
      if (store.getState().currentCampaign.campaignTerms.createYourOwn) {
        data.termsCustom = store.getState().currentCampaign.campaignTerms.termsFromCustomDraft
      } else {
        data.termsFromTemplate = store.getState().currentCampaign.campaignTerms.termsFromTemplateDraft
      }
      TermsDispatcher.updateTerms(data);
      toastr.success('Published!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      return;
    }

    closeHostRulesModal()

    TermsDispatcher.publish(function() {
      BrowserHistoryDispatcher.push(`/builder/${currentCampaign.id}/terms/dashboard`);
      toastr.success('Published!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
    })
  }

  openModalAndTrack = () => {
    const { openHostRulesModal, analyticsActions } = this.props
    openHostRulesModal()
    analyticsActions.create('userViewedOfficialRulesModal')
  }

  render() {
    const { currentCampaign, selected, analyticsActions } = this.props
    let { campaignTermsDetails } = this.props

    const campaignTerms = currentCampaign.campaignTerms || {}
    campaignTermsDetails = campaignTermsDetails || {}

    const availableSteps = _.filter(TermsMap, function(item) {
      if (campaignTerms.createYourOwn) {
        return !item.useTemplate;
      } else {
        return true;
      }
    });

    let selectedStep = {};

    const disabledItems = ['info', 'review', 'edit'];
    let steps = availableSteps.map((item, idx) => {
      if (item.key === selected) {
        selectedStep = item;
      }

      const disabled = disabledItems.indexOf(item.key) != -1 ? isDisabled(item.key, currentCampaign, campaignTermsDetails) : false;

      return {
        path: item.key,
        label: campaignTerms.createYourOwn && item.key == 'edit' ? item.title2 : item.title,
        disabled: disabled,
        navigate: e => {
          navigate(item)
        }
      }
    });
    return(
      <div>
        <TertiaryNav
          base={ `/builder/${currentCampaign.id}/terms` }
          options={ steps }
          selected={ selected }
        >
          { selectedStep.actionTitle ?
            <div style={{
              float: 'right'
            }}>
              <li onClick={ e => selectedStep.key == 'info' ? this.generateTerms() : this.openModalAndTrack() }
                className={ TertiaryNavTheme.nextButton }
              >
                <span className={ TertiaryNavTheme.nextText }>
                  { (selectedStep.key == 'info' && !campaignTerms.termsGeneratedAt) || ((selectedStep.key == 'review' || selectedStep.key == 'edit') && !campaignTerms.termsPublishedAt) ? selectedStep.actionTitle : selectedStep.actionTitle2 }
                </span>
              </li>
            </div> : null
          }
        </TertiaryNav>
        <OfficialRulesHostReminderModal publishRules={this.publish} analyticsActions={analyticsActions} />
      </div>
    )
  }
}

export default TermsHeader
