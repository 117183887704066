import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { P, H3 } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import COLOURS from 'util/colours'

const styles = {
  winnerCard: {
    padding: '12px 12px 28px 28px',
    marginBottom: 20,
    width: '100%',
    border: `1px solid ${COLOURS.skyBase}`,
    boxShadow: 'none',
  },
  winnerHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  winnerCopy: {
    marginBottom: 20,
  },
  winnerInfo: {
    display: 'flex',
  },
  winnerInfoStat: {
    marginRight: 60,
  },
}

class SoloWinnerCard extends React.Component {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    chooseWinner: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  renderWinnerInfo = winnerInfo => {
    const { chooseWinner, classes } = this.props
    return winnerInfo ? (
      <div className={classes.winnerInfo}>
        <div className={classes.winnerInfoStat}>
          <P>
            <i>Name</i>
          </P>
          <H3>
            <small>
              {winnerInfo.name}
            </small>
          </H3>
        </div>
        <div className={classes.winnerInfoStat}>
          <P>
            <i>Email</i>
          </P>
          <H3>
            <small>
              {winnerInfo.email}
            </small>
          </H3>
        </div>
        <div className={classes.winnerInfoStat}>
          <P>
            <i>Zipcode</i>
          </P>
          <H3>
            <small>
              {winnerInfo.zipcode}
            </small>
          </H3>
        </div>
      </div>
    ) : (
      <div>
        <Button
          onClick={chooseWinner}
          label="Pick Winner"
          className={ButtonTheme.blueButton}
          theme={ButtonTheme}
          primary
          raised
        />
      </div>
    )
  }

  render() {
    const {
      currentCampaign,
      chooseWinner,
      classes,
    } = this.props

    const {
      winner,
    } = currentCampaign

    const winnerInfo = winner ? {
      name: winner.fullname ? winner.fullname : `${winner.firstName || ''} ${winner.lastName || ''}`,
      email: winner.email,
      zipcode: winner.zipcode,
      sourceBrand: winner.fromBrand,
    } : null

    return (
      <Card className={classes.winnerCard}>
        <div className={classes.winnerHeading}>
          <H3>
            <small>
              Winner Info
            </small>
          </H3>
          <Button
            onClick={chooseWinner}
            label="Pick Another Winner"
            className={ButtonTheme.whiteButtonAzureText}
            theme={ButtonTheme}
            raised
          />
        </div>
        <div className={classes.winnerCopy}>
          <P>
            As the host, you’ll need to notify the winner of their prize.
          </P>
        </div>
        {this.renderWinnerInfo(winnerInfo)}
      </Card>
    )
  }
}

export default injectSheet(styles)(SoloWinnerCard)
