import React from 'react'
import { Card } from 'react-toolbox/lib/card'
import { H3 } from 'visual-components/util/texts'
import ShareOpportunityCommon from './share-opportunity-common'

import OpportunityTheme from 'css/themes/opportunityProfile.css'

const ShareOpportunityCard = ({ opportunity, isPublic }) => (
  <Card className={OpportunityTheme.shareCard}>
    <div style={{ marginBottom: '16px' }}>
      <H3 coral>Share</H3>
    </div>

    <ShareOpportunityCommon
      opportunity={opportunity}
      isPublic={isPublic}
      render={({ options, url }) =>
        options.map((option, idx) => (
          <img
            src={`images/social_icons/share-${option.key}-icon.svg`}
            key={idx}
            onClick={option.onClick}
            data-clipboard-text={`${url}`}
            className={`${option.key === 'link' ? 'copy-link' : ''} ${
              OpportunityTheme.socialShareIcon
            }`}
            style={{ marginRight: idx !== options.length - 1 ? '12px' : 0 }}
          />
        ))
      }
    />
  </Card>
)

export default ShareOpportunityCard
