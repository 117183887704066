import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';

import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import ExploreBrandsResults from './../exploreBrands/explore-brands-results'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'

import SearchActionsFactory from 'actions/search-actions-factory'
import SearchBrandConfig from 'actions/search-config/search-brand-config'

import * as OpportunityActions from 'actions/opportunities-actions';

const mapState = ({
  brandSearch
}) => ({ brandSearch })

const extendQuery = props => {
  const body = SearchBrandConfig.extendQuery(props)
  body.query = {
    terms: {
      id: props.getState().currentBrand.brandfavorites
    }
  }
  body.size = 500
  return body
}

const mapDispatch = dispatch => ({
  searchActions: bindActionCreators(
    SearchActionsFactory({ ...SearchBrandConfig, extendQuery }),
    dispatch
  ),
  opportunityActions: bindActionCreators(OpportunityActions, dispatch)
});

class MyFavorites extends React.Component {
  componentDidMount() {
    this.props.searchActions.clear()
    this.props.searchActions.applySearch()
  }

  addMoreFavorites = () => {
    const { history } = this.props
    window.location = '/app/search/brands'
  };

  render() {
    const {
      brandSearch: { results, loading, error },
      opportunityActions
    } = this.props;

    const myFavorites = results && results.length ? (
      <ExploreBrandsResults
        brands={ results || [] }
        loading={ loading }
        error={ error }
      />
    ) : (
      <div style={{
        width: '976px',
        marginTop: '88px'
      }}>
        <EmptyBonzai description={ `No favorites? No worries! Go to Explore to discover brands to partner with.` } noTree />
      </div>
    )

    return (
      <div>
        <PartnershipsContainer
          title={ 'My Favorites' }
          action={ this.addMoreFavorites }
          actionLabel={ 'Add More Favorites' }
          content={ myFavorites }
          opportunityActions={ opportunityActions }
        />
      </div>
    )
  }
}

MyFavorites.propTypes = {
  opportunityActions: PropTypes.object.isRequired
};

export default withRouter(connect(mapState, mapDispatch)(MyFavorites))
