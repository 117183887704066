import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Dialog from 'react-toolbox/lib/dialog'
import FontIcon from 'react-toolbox/lib/font_icon'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import UploadImage from 'components/util/editor-uploader'
import COLOURS from 'util/colours'

import dialogTheme from 'css/themes/dialogs/formModal.css'
import ProfileTheme from 'css/themes/Profile.css'

let logoPreviewStyle = {
  display: 'inline-block',
  width: '216px',
  height: '216px',
  paddingTop: '60px',
  border: 'solid 1px #dee4e5',
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative',
}

class EditLogo extends PureComponent {
  static propTypes = {
    logo: PropTypes.string,
    uploadSuccess: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    active: PropTypes.bool,
  }

  static defaultProps = {
    logo: '',
    active: false,
  }

  render() {
    const {
      logo,
      uploadSuccess,
      handleToggle,
      save,
      cancel,
      active,
    } = this.props

    if (logo) {
      logoPreviewStyle = {
        ...logoPreviewStyle,
        backgroundSize: 'cover',
        backgroundImage: `url(${logo})`,
      }
    }

    return (
      <div className={ProfileTheme.edit} style={{ position: 'relative' }}>
        <div
          onClick={handleToggle}
          style={{
            cursor: 'pointer',
            backgroundColor: 'rgba(44, 53, 65, 0.8)',
            borderRadius: '2px',
          }}
        >
          <div
            style={{
              fontFamily: 'Larsseit',
              fontSize: '14px',
              lineHeight: '36px',
              paddingLeft: '16px',
              paddingRight: '36px',
              color: COLOURS.white,
            }}
          >
            <div style={{ display: 'inline-block' }}>
              Edit Image
            </div>
            <div
              style={{
                display: 'inline-block',
                position: 'absolute',
                top: '4px',
                right: '16px',
              }}
            >
              <FontIcon value="edit" style={{ fontSize: '18px' }} />
            </div>
          </div>
        </div>

        <Dialog
          theme={dialogTheme}
          active={active}
          onEscKeyDown={handleToggle}
          onOverlayClick={handleToggle}
          title="Edit Logo Image"
        >
          <img
            onClick={handleToggle}
            className={dialogTheme.closeImage}
            src="/images/icons/close.svg"
          />

          <p style={{ fontFamily: 'Larsseit', fontSize: '16px' }}>
            To change your logo, add a square PNG or JPG image with 1:1 aspect ratio and at least 200 x 200px. This logo will appear on your giveaways.
          </p>

          <div style={{ textAlign: 'center', marginTop: '24px' }}>
            <UploadImage
              action="/brands/logo"
              src={logo}
              success={uploadSuccess}
              name="upload-logo-image"
              acceptedFiles="image/jpeg,image/png"
            >
              <div style={logoPreviewStyle}>
                <div style={{ position: 'absolute', zIndex: 2, width: '100%' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                    <path fill={!logo ? COLOURS.azure : '#fff'} fillRule="nonzero" d="M64 30.476H33.524V0h-3.048v30.476H0v3.048h30.476V64h3.048V33.524H64z"/>
                  </svg>
                  <p
                    style={{
                      fontFamily: 'Larsseit-Bold',
                      fontSize: '24px',
                      color: !logo ? COLOURS.azure : '#fff',
                      marginTop: '34px',
                    }}
                  >
                    { logo ? 'Change' : 'Upload' }
                  </p>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    opacity: 0.4,
                    top: '0px',
                    display: logo ? 'block' : 'none',
                  }}
                />
              </div>
            </UploadImage>
          </div>

          <ModalButtonGroup canSave={logo} confirm={save} cancel={cancel} />
        </Dialog>
      </div>
    )
  }
}

export default EditLogo;
