import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import d3 from 'd3'

import SliderCard from 'visual-components/util/SliderCard'

import * as MetricsActions from 'actions/analytics-actions'
import * as ROICalculatorActions from 'actions/roi-calculator-actions'

import Theme from 'css/themes/roiCalculatorTheme.css'

const METRICS_TIMEOUT = 500

const mapStateToProps = ({ roiCalculator }) => ({ roiCalculator })

const mapDispatchToProps = dispatch => ({
  roiCalculatorActions: bindActionCreators(ROICalculatorActions, dispatch),
  metricActions: bindActionCreators(MetricsActions, dispatch),
})

class ROISliderCards extends PureComponent {
  static propTypes = {
    metricActions: object.isRequired,
    roiCalculator: object.isRequired,
    roiCalculatorActions: object.isRequired,
  }

  timeout = null

  onChange = property => value => {
    const { roiCalculatorActions, metricActions } = this.props

    switch (property) {
      case 'Conversion Rate':
        roiCalculatorActions.setConversionRate(value)
        break
      case 'Average Lifetime Value':
        roiCalculatorActions.setAverageLifetimeValue(value)
        break
      case 'Average Spent Per Campaign':
        roiCalculatorActions.setAverageSpendPerCampaign(value)
        break
      default:
        return null
    }

    clearTimeout(this.timeout)

    // Execute after METRIC_TIMEOUT seconds stopping changing values
    this.timeout = setTimeout(() => {
      const { roiCalculator } = this.props

      const {
        roi: ROI,
        hasCustomCTA,
        hasHosted,
        loading,
        ...data
      } = roiCalculator

      roiCalculatorActions.postROIValues({ ...roiCalculator })

      metricActions.create('ROICalculated', {
        meta: { ...data, ROI },
        object: property,
      })
    }, METRICS_TIMEOUT)

    return null
  }

  componentWillUnmount = () => clearTimeout(this.timeout)

  render() {
    const { roiCalculator } = this.props

    return (
      <div className={Theme.sliderCard}>
        <SliderCard
          decimal
          title="Conversion Rate"
          tooltip="What percentage of new emails convert into buyers? Typical conversion rate: 0.75%"
          min={0.05}
          max={2.5}
          defaultValue={0.15}
          value={roiCalculator.conversionRate}
          onChange={this.onChange('Conversion Rate')}
          formatter={val => `${val}%`}
        />
        <div style={{ marginBottom: '16px' }} />
        <SliderCard
          title="Avg Lifetime Value"
          tooltip="Lifetime Value = Average Order Value x Average Orders per Customer"
          min={100}
          max={1000}
          defaultValue={250}
          value={roiCalculator.avgLifetimeValue}
          onChange={this.onChange('Average Lifetime Value')}
          formatter={d3.format('$,.0f')}
        />
        <div style={{ marginBottom: '16px' }} />
        <SliderCard
          title="Avg Spent per Campaign"
          tooltip="How much do you spend on participating in a campaign?"
          min={10}
          max={1000}
          defaultValue={150}
          value={roiCalculator.avgCampaignSpend}
          onChange={this.onChange('Average Spent Per Campaign')}
          formatter={d3.format('$,.0f')}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ROISliderCards)
