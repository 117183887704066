import React from 'react'
import PropTypes from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'

import ProfileMainCard from 'visual-components/util/profile-main-card'
import ProfileStat from 'visual-components/util/profile-stat'

class BrandStats extends React.Component {
  render() {
    const {
      campaignsHosted,
      recentCampaigns,
      totalCampaigns,
      brandPartners,
      totalAudienceReach,
    } = this.props

    return (
      <ProfileMainCard title="Campaign Stats">
        <Grid>
          <Row>
            <ProfileStat brand value={campaignsHosted} title="CAMPAIGNS HOSTED" />
            <ProfileStat brand value={recentCampaigns} title="RECENT CAMPAIGNS" />
            <ProfileStat brand value={totalCampaigns} title="TOTAL CAMPAIGNS" />
            <ProfileStat brand value={brandPartners} title="BRAND PARTNERS" />
            <ProfileStat brand value={totalAudienceReach} title="TOTAL AUDIENCE REACH" />
          </Row>
        </Grid>
      </ProfileMainCard>
    )
  }
}

BrandStats.propTypes = {
  campaignsHosted: PropTypes.number.isRequired,
  recentCampaigns: PropTypes.number.isRequired,
  totalCampaigns: PropTypes.number.isRequired,
  brandPartners: PropTypes.number.isRequired,
  totalAudienceReach: PropTypes.number.isRequired,
}

export default BrandStats
