import React from 'react'
import { Tiny, H4 } from 'visual-components/util/texts'
import d3 from 'd3'
import MediaMarketAnalyticsTheme from 'css/themes/media-market/MediaMarketAnalyticsTheme.css'

const CPCStats = props => {
  const {
    isLive,
    minClicks,
    maxClicks,
    price,
    status,
  } = props

  return (
    <React.Fragment>
      <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
        <div className={MediaMarketAnalyticsTheme.dealStatValue}>
          <H4 white={isLive && status === 'live'}><em>{ d3.format('$,.2f')(price) }</em></H4>
        </div>
        <Tiny white={isLive && status === 'live'}>CPC</Tiny>
      </div>
      <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
        <div className={MediaMarketAnalyticsTheme.dealStatValue}>
          <H4 white={isLive && status === 'live'}>
            <em>{`${d3.format(',')(minClicks)} - ${d3.format(',')(maxClicks)}`}</em>
          </H4>
        </div>
        <Tiny white={isLive && status === 'live'}>Deal Click Range</Tiny>
      </div>
    </React.Fragment>
  )
}

export default CPCStats
