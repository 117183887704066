import React from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import { debounce } from 'lodash'
import { H3, H6 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import ColorRangeSlider from 'util/components/templates/form/range-slider-with-color'
import * as metrics from 'util/metrics'
import COLOURS from 'util/colours'

const styles = {
  container: {
    background: COLOURS.white,
    padding: '24px 24px 16px 24px',
  },
  header: {
    marginBottom: '24px',
    textAlign: 'center',
  },
  hr: {
    width: '320px',
    marginTop: '8px',
    marginLeft: '-24px',
    borderBottomColor: COLOURS.silver,
  },
}

class editBackground extends React.Component {
  static propTypes = {
    campaign: object.isRequired,
    elements: object.isRequired,
    updateAttr: func.isRequired,
    onSave: func.isRequired,
    onCancel: func.isRequired,
    classes: object.isRequired,
  }

  track = debounce(data => {
    metrics.create('campaignManagerUpdatedFormContainerAttribute', {
      meta: data,
    })
  }, 500)

  updateForm = (attr, value) => {
    const { updateAttr } = this.props
    const data = {}
    data[attr] = value
    const form = {}
    form.form = data
    this.track({
      type: 'formBackground',
      attr,
    })
    updateAttr('styles', form, true)
  }

  render() {
    const {
      campaign: {
        desktopElements: {
          landerVersion,
        },
      },
      elements: { styles: styleObj },
      onSave,
      onCancel,
      classes: css,
    } = this.props

    const formModel = 'form'

    const showSlider = ['version-10', 'version-11', 'version-13'].includes(landerVersion)

    return (
      <div className={css.container}>
        <div className={css.header}>
          <H3>
            Edit Form Container
          </H3>
        </div>
        <div>
          <H6>
            Background Color
          </H6>
          <div style={{ width: '272px', display: 'table' }}>
            <ColorRangeSlider
              attr="opacity"
              label="Opacity"
              value={styleObj[formModel].opacity}
              color={styleObj[formModel].color}
              min={0}
              max={1}
              step={0.01}
              updateValue={this.updateForm}
              showSlider={showSlider}
            />
          </div>
        </div>
        <hr className={css.hr} />
        <div>
          <ModalButtonGroup
            confirm={onSave}
            confirmText="Save"
            cancel={onCancel}
            cancelText="Cancel"
            canSave
            hideLine
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(editBackground)
