import React from 'react'
import { string, number } from 'prop-types'
import d3 from 'd3'
import Theme from 'css/themes/ExpandableCalculatorTheme.css'
import { H1 } from 'visual-components/util/texts'

class ROIResultBar extends React.PureComponent {
  static propTypes = {
    title: string.isRequired,
    value: number.isRequired,
  }

  render() {
    const { title, value } = this.props

    return (
      <div className={Theme.roiResultBar}>
        <div className={Theme.title}>
          <H1 white>
            {title}
          </H1>
        </div>
        <div className={Theme.value}>
          <H1 white>
            {d3.format('.1%')(value)}
          </H1>
        </div>
      </div>
    )
  }
}

export default ROIResultBar
