import _ from 'lodash'

const model = 'brand';
let initialState = {
  rid: model,
  loaded: false,
  availableCampaigns: [],
  dedicatedListSize: 0,
  socialImpressions: 0,
  faceBookFollowers: 0,
  instagramFollowers: 0,
  averageEntriesPerCampaign: 0,
  campaignsList90: 0,
  industry: '',
  pendingItems: [],
  interestsList: [],
  partnershipInterests: {}
};

var stateNameHash = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PW': 'Palau',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
}

const BrandDetailReducer = (state, action) => {

  if (action.model !== model && action.type !== 'SUBMIT_APPLICATION_SUCCESS') {
    return state || initialState;
  }
  const actions = {
    CLEAR: function () {
      return _.extend({}, initialState);
    },
    UPDATE_ATTR: function () {
      return _.merge({}, state, action.data);
    },
    UPDATE_ARR: function () {
      return _.extend({}, state, action.data);
    },
    LOADING: function () {
      return _.extend({}, state, { loading: true });
    },
    LOAD_PARTNERSHIPS: function () {
      // data: {
      //   receivedInvitations: receivedInvitations,
      //   receivedApplications: receivedApplications,
      //   partnerships: invites
      // }

      var invites = _.filter(action.data.receivedInvitations, {
        invitee_id: state.id,
        status: 'pending'
      });
      var applications = _.filter(action.data.receivedApplications, {
        invitee_id: store.getState().currentBrand.id,
        status: 'pending'
      });

      _.each(invites, function (invite) {
        invite.actionText = `${state.accountname} has invited you to join ${invite.campaign.name}`;
      })

      _.each(applications, function (application) {
        invite.actionText = `${state.accountname} has applied to join ${invite.campaign.name}`;
      });

      return _.extend({}, state, {
        partnerships: action.data.partnerships,
        pendingItems: invites.concat(applications),
      });
    },
    UPDATE_SORT: function () {
      state = _.extend({}, state, action.data);
      var results = _.sortBy(state.results, state.sortVar);
      if (state.reversed) {
        results = results.reverse();
      }

      state.results = results;
      return state;
    },
    LOAD_SUCCESS: function () {
      var data = _.extend({}, state, { loading: false });

      var tags = _.map( action.data.tags, function( tag ) {
        return tag.name
      })

      var opportunities = action.data.opportunities.map(function(opportunity) {
        return _.extend({}, opportunity, { brandName: action.data.accountname, brandLogo: action.data.logo, brandTags: tags } );
      })

      action.data.incomeData = action.data.incomeData || {};
      let { stateDistribution } = action.data

      let incomeTypes = ['below15k', '15k_24k', '25k_34k', '35k_49k', '50k_74k', '75k_99k', '100k_149k', '150k_199k','above200k'];

      var totalIncomeCount = _.reduce(action.data.incomeData, function (memo, value, key) {
        var key = key.replace('income_', '');
        if (incomeTypes.indexOf(key) !== -1) {
          return memo + value;
        }

        return memo;
      }, 1);
      var incomeDistributionPercent = {};

      _.each(action.data.incomeData, function (value, key) {
        var key = key.replace('income_', '');
        if (incomeTypes.indexOf(key) !== -1) {
          incomeDistributionPercent[key] = value / totalIncomeCount;
        }
      });

      incomeDistributionPercent.sub30 = incomeDistributionPercent.below15k + incomeDistributionPercent['15k_24k'] + incomeDistributionPercent['25k_34k'];
      incomeDistributionPercent.sub49 = incomeDistributionPercent['35k_49k'];
      incomeDistributionPercent.sub74 = incomeDistributionPercent['50k_74k'];
      incomeDistributionPercent.sub99 = incomeDistributionPercent['75k_99k'];
      incomeDistributionPercent.over100 = incomeDistributionPercent['100k_149k'] + incomeDistributionPercent['150k_199k'] + incomeDistributionPercent['above200k'];

      var stateDistributionCount = action.data.stateDistribution;
      var total = _.reduce(stateDistributionCount, function (memo, value) {
        return memo + value;
      }, 0);
      var stateDistributionPercent = {};
      _.each(stateDistributionCount, function  (value, state) {
        stateDistributionPercent[state] = value / total;
      });



      var stateHash = _.map(stateDistributionCount, function (value, state) {
        return {
          state: state,
          value: value
        }
      });

      var orderedStates = _.sortBy(stateHash, function (item) {
        return item.value;
      }).reverse();

      var stateOne = orderedStates[0] ? stateNameHash[orderedStates[0].state] : '';
      var stateTwo = orderedStates[1] ? stateNameHash[orderedStates[1].state] : '';
      var stateThree = orderedStates[2] ? stateNameHash[orderedStates[2].state] : '';

      var totalsItems = [
        'dedicatedListSize',
        'facebookFollowers',
        'instagramFollowers',
        'pinterestFollowers',
        'twitterFollowers',
        'youtubeFollowers'
      ];



      var totalAudienceReach = _.reduce(totalsItems, function (memo, item) {
        var value = parseInt(action.data[item] || 0);
        return memo + value;
      }, 0);

      return _.extend({}, data, action.data, { tags: tags }, {
        hostRating: action.data.ratings[0].hostavg,
        partnerRating: action.data.ratings[0].partneravg,
        sellerRating: action.data.ratings[0].selleravg,
        totalRating: action.data.ratings[0].totalavg,
        hostRatingCount: parseInt(action.data.ratings[0].counthostratings),
        partnerRatingCount: parseInt(action.data.ratings[0].countpartnerratings),
        sellerRatingCount: parseInt(action.data.ratings[0].countsellerratings),
        totalRatingCount: parseInt(action.data.ratings[0].counttotalratings),
        opportunities: opportunities,
        partnershipInterests: action.data.partnershipInterests,
        stateDistributionPercent: stateDistributionPercent,
        stateOne: stateOne,
        stateTwo: stateTwo,
        stateThree: stateThree,
        incomeDistributionPercent: incomeDistributionPercent,
        isDemographicEmpty: !orderedStates.length,
        totalAudienceReach: totalAudienceReach,
        totalCampaigns: parseInt(action.data.totalCampaigns),
        hostedCampaigns: parseInt(action.data.hostedCampaigns),
        features: action.data.features,
        totalDeals: parseInt(action.data.totalDeals)
        // statePercentile: statePercentile
      });
    },
    ACCEPT_APPLICATION: function() {
      var newState = _.extend({}, state);
      var partnership = _.find( newState.partnerships, {id: action.data.inviteId} );
      partnership.status = "accepted";

      return newState;
    },
    UPDATE_INTEREST: function() {
      let subInterest = Object.assign({}, state.partnershipInterests[action.attribute], action.data)
      let partnershipInterests = Object.assign({}, state.partnershipInterests, {[action.attribute]: subInterest})

      return _.extend({}, state, {partnershipInterests})
    },
    SUBMIT_APPLICATION_SUCCESS: function() {
      const oldAvailable = state.availableCampaigns;
      const newAvailable = oldAvailable.map(old => ({
        ...old,
        invites: [...old.invites, action.payload.invite]
      }));

      return { ...state, availableCampaigns: newAvailable };
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
};

export default BrandDetailReducer;
