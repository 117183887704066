export const REPORT_PARTNER = 'REPORT_PARTNER'
export const REPORT_PARTNER_SUCCESS = 'REPORT_PARTNER_SUCCESS'
export const REPORT_PARTNER_FAILURE = 'REPORT_PARTNER_FAILURE'

export const SEND_ANNOUNCEMENT = 'SEND_ANNOUNCEMENT'
export const SEND_ANNOUNCEMENT_SUCCESS = 'SEND_ANNOUNCEMENT_SUCCESS'
export const SEND_ANNOUNCEMENT_FAILURE = 'SEND_ANNOUNCEMENT_FAILURE'

export const reportPartner = (campaignId, partnerName, type, details) => dispatch => {
  dispatch({
    model: 'currentCampaign',
    http: {
      url: `/campaign-report-partner/${campaignId}`,
      method: 'POST',
      data: {
        partnerName,
        type,
        details
      }
    },
    types: [
      REPORT_PARTNER,
      REPORT_PARTNER_SUCCESS,
      REPORT_PARTNER_FAILURE
    ]
  })
}

export const sendAnnouncement = (campaignId, type, details) => dispatch => {
  dispatch({
    model: 'currentCampaign',
    http: {
      url: `/campaign-send-announcement/${campaignId}`,
      method: 'POST',
      data: {
        type,
        details
      }
    },
    types: [
      SEND_ANNOUNCEMENT,
      SEND_ANNOUNCEMENT_SUCCESS,
      SEND_ANNOUNCEMENT_FAILURE
    ]
  })
}

export const openReinvitationMessenger = data => ({
  model: 'conversations',
  type: 'OPEN_REINVITE_MESSENGER_WINDOW',
  data,
})

export const setBrandForInvite = brand => ({
  type: 'UPDATE_ATTR',
  model: 'brand',
  data: { ...brand },
})
