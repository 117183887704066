import React from 'react';
import * as ReactRedux from 'react-redux';
import _, { debounce } from 'lodash'

import Text from 'util/components/text';
import PrimaryButton from 'util/components/buttons/primary-button';
import AppDispatcher from 'dispatchers/app-dispatcher';
import ProfileDispatcher from 'dispatchers/profile-dispatcher'
import SearchCard from 'visual-components/shared/search-card'
import Card from 'react-toolbox/lib/card';
import SearchResultItem from 'visual-components/messaging/search-result-item';

class CustomerSuccessAdminImpersonate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      searchValue: '',
      users: [],
      brandId: null
    }
  }

  updateSuggestions = (newValue) => {
    this.setState({searchValue: newValue});
    this.search(newValue)
  }

  search = debounce(value => {
    const query = {
      bool: {
        filter: {
          bool: {
            should: [{
              wildcard: {
                accountname:'*' + value.toLowerCase().replace(/\s/g, '*') + '*'
              }
            }]
          }
        }
      }
    };

    if (parseInt(value, 10) > 0) {
      query.bool.filter.bool.should.push({
        term: {
          id: parseInt(value, 10)
        }
      });
    }

    AppDispatcher.http({
      url: '/search/',
      method: 'POST',
      data: {
        excludeDegree: true,
        size: 100,
        _source: ['id', 'accountname', 'logo'],
        query
      },
      success: (data) => {
        this.setState({
          results: data.hits.hits
        })
      }
    })
  }, 500)

  findBrandUsers = (brand_id) => {
    this.setState({results: []})

    AppDispatcher.http({
      url: '/cs-users-for-brand',
      method: 'POST',
      data: {
        brand: brand_id
      },
      success: (users) => {
        this.setState({
          users: users,
          brandId: brand_id
        })
      }
    })
  }

  impersonate = (id) => {
    ProfileDispatcher.impersonate(id, this.state.brandId)
  }

  render() {
    let resultItems = _.map(this.state.results, (item) => {
      return (
        <div style={{minHeight: '50px', minWidth: '900px'}}>
          <SearchResultItem
            brand = {item._source}
            choose ={ ()=>{ this.findBrandUsers(item._source.id)}  } />
        </div>
      )
    })

    let userItems = this.state.users.map(user => {
      return (
        <div style={{ marginBottom: '10px' }}>
          <a onClick={() => this.impersonate(user.uid)}>
            <span>{user.name}, {user.email}</span>
          </a>
        </div>
      )
    })

    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Impersonate User
          </Text.dojoH2Bold>
          <SearchCard
            placeholder={'Which brand do you want to impersonate?'}
            value={ this.state.searchValue }
            onChange={ this.updateSuggestions }
            />
          { this.state.results ?
            <Card style={{
              padding: '0px',
              listStyleType: 'none',
              position: 'absolute',
              background: 'white',
              zIndex: '100',
              marginTop: '0px',
              width: '100%',
              maxHeight: '500px',
              overflow: 'scroll',
              borderTop: `1px solid ${ COLOURS.lightGrey }`
            }}>
               { resultItems }
            </Card>
            : null
          }
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Users
          </Text.dojoH2Bold>
          { userItems }
        </div>
      </div>
    )
  }
}

var mapState = function(state) {
  return state;
}

export default ReactRedux.connect(mapState)(CustomerSuccessAdminImpersonate);
