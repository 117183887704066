import React from 'react'
import _ from 'lodash'
import propTypes from 'prop-types'
import Fonts from 'util/fonts'
import COLOURS from 'util/colours'
import Dropdown from 'react-toolbox/lib/dropdown'
import injectSheet from 'react-jss'

const fontFamilyTransitions = {
  stylesDropdown: {
    width: '100%',
  },
  templateValue: {
    width: '100%',
    borderRadius: '2px',
    border: `solid 1px ${COLOURS.silver}`,
    height: '40px',
    paddingLeft: '12px',
    '&:after': {
      top: '15px',
      height: '9px',
      width: '12px',
      border: 0,
      backgroundImage: 'url("/images/select-arrow-blue.svg")',
    },
  },
  field: {
    paddingTop: '18px',
    paddingBottom: '0px',
  },
  label: {
    fontFamily: 'Larsseit-Light',
    fontSize: '10px',
    color: COLOURS.ink,
    top: '0px',
    marginBottom: '0px',
    fontWeight: 'normal',
  },
  values: {
    maxHeight: '250px !important',
    marginTop: '18px',
  },
}
class FontFamilySelect extends React.Component {
  static propTypes = {
    attr: propTypes.string.isRequired,
    model: propTypes.object.isRequired,
    customFonts: propTypes.array,
    updateAttr: propTypes.func.isRequired,
    classes: propTypes.object.isRequired,
  }

  static defaultProps = {
    customFonts: [],
  }

  state = {
    value: this.props.model[this.props.attr].family || 'Courier New',
  }

  updateFont = family => {
    this.setState({ value: family })
    const data = {
      fonts: {},
    }
    data.fonts[this.props.attr] = {
      family,
    }
    this.props.updateAttr('styles', data)
  }

  template = value => <div style={{ fontFamily: value.family }}>{value.family}</div>

  render() {
    const {
      customFonts, model, attr, classes: css,
    } = this.props
    const selectedFamily = model[attr].family || Fonts[0]
    let fonts = _.without(customFonts.concat(Fonts), selectedFamily)
    fonts = _.map(Fonts, family => ({ value: family, family }))
    const { value } = this.state

    return (
      <Dropdown
        auto
        onChange={this.updateFont}
        template={this.template}
        source={fonts}
        label="Font"
        value={value}
        className={css.stylesDropdown}
        theme={{
          label: css.label,
          field: css.field,
          templateValue: css.templateValue,
          values: css.values,
        }}
      />
    )
  }
}

export default injectSheet(fontFamilyTransitions)(FontFamilySelect)
