import React from 'react';
import { H6 } from 'visual-components/util/texts';

class CardHeaderItem extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: '12px' }}>
        <H6>
          {this.props.label}
        </H6>
      </div>
    )
  }
}

export default CardHeaderItem;