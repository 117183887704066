import moment from 'moment-timezone'

export const isRoutingNumber = value => {
  if (!value || !value.toString().match(/^\d{9}$/)) {
    throw 'Please enter a valid routing number'
  }
}

export const isBankAccount = (value, model) => {
  if (value && value === model.old_account_number) return
  if (!value || !value.toString().match(/^\d+$/)) throw 'Please enter a valid account number'
}

export const isLastFour = (value, model) => {
  if (model.legal_entity.ssn_last_4_provided && value === '****') return
  if (!value || !value.toString().match(/^\d{4}$/)) throw 'Please enter 4 digits'
}

export const isValidRepBirthday = (format = 'MM/DD/YYYY') => value => {
  const date = moment.utc(value, format)
  if (!date.isValid() || !date.isBetween('1900-01-01', moment.utc().subtract(18, 'years'))) throw 'Enter a valid date'
}

export const isTaxId = (value, model) => {
  if (model.legal_entity.business_tax_id_provided && value === '**-*******') return
  if (
    !value
    || !value
      .toString()
      .replace(/-/g, '')
      .match(/^\d{9}$/)
  ) throw 'Invalid Tax ID'
}
