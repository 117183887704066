import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import useWindowResize from 'util/hooks/useWindowResize'

const styles = {
  body: {
    '& tr > :first-child': {
      minWidth: 170,
    },
    '& tr': {
      minWidth: 50,
    },
  },
  column: {
    padding: 24,
    minHeight: 64,
    verticalAlign: 'top',
  },
  empty: {
    padding: 32,
    width: 'unset !important',
  },
}

const DataMapper = ({
  name, index, row, render, config, classes: css,
}) => {
  const itemConfig = config[name]

  if (window.innerWidth < ((itemConfig && itemConfig.hideOn) || 0)) return null

  return (
    <td key={`${JSON.stringify(row[name])}_${index}`} className={css.column}>
      {render(
        {
          name,
          index,
          value: row[name],
        },
        row,
        config
      )}
    </td>
  )
}

DataMapper.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

const MemoedDataMapper = injectCSS(styles)(DataMapper)

function TableBody(props) {
  const {
    data,
    cellRenderer,
    config,
    currentPage,
    classes: css,
  } = props

  useWindowResize() // Allows rerendering when resized

  const tableRows = data.map((dataRow, idx) => {
    const rowItems = config.columns || Object.keys(dataRow)
    return (
      <>
        {(!currentPage && !idx && config.customBanner && (
          <tr key="custom-row">
            <td colSpan={1000} className={cn(css.column, css.wide)}>
              {config.customBanner}
            </td>
          </tr>
        ))
          || null}
        <tr
          key={idx} // eslint-disable-line react/no-array-index-key
        >
          {rowItems.map((dataKey, index) => (
            <MemoedDataMapper
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}_${index}`}
              name={dataKey}
              index={index}
              row={dataRow}
              render={cellRenderer}
              config={config}
            />
          ))}
        </tr>
      </>
    )
  })

  const rows = tableRows.length ? (
    tableRows
  ) : (
    <tr>
      <td className={css.empty} colSpan={1000}>
        {React.cloneElement(config.emptyState, { data, config })}
      </td>
    </tr>
  )

  return <tbody className={css.body}>{rows}</tbody>
}

TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  cellRenderer: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  config: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

TableBody.defaultProps = {
  config: {},
}

export default React.memo(injectCSS(styles)(TableBody))
