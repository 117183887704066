import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import EmailCollector from 'login/visual-components/util/email-collector'
import theme from 'css/themes/mobile/home/banner.css'
import MobileHeader from '../mobile-header/MobileHeader'
import { Button } from 'react-toolbox/lib/button/Button'
import ButtonTheme from 'css/themes/Buttons.css'

class HomeBanner extends Component {
  render() {
    return (
      <div id={theme.home}>
        <MobileHeader />
        <div className={theme.smsBanner}>
          <span className={theme.smsTitle}>
              Reach your customers where they are &mdash; their phones!
            <br />
Try DojoMojo’s
            <Link to="/sms-marketing"> SMS Marketing solutions </Link>
for Free
          </span>
        </div>
        <div className={theme.bannerContainer}>
          <div className={theme.textContainer}>
            <div className={theme.title}> One-Stop-Shop Acquisition <br /> and Engagement </div>
            <div className={theme.about}>
              Build your audience base through our powerful acquisition network and
              leverage advanced SMS messaging features to engage with your customers.
            </div>
          </div>
          <a href="/app/register">
            <div className={theme.buttonWrapper}>
              <Button
                label="Sign Up and Start Growing"
                className={ButtonTheme.orangeButton}
                theme={ButtonTheme}
                floating
              />
            </div>
          </a>
          <div className={theme.imgContainer}>
            <img alt="homepage-hero-sms" src="/images/login/homepage/homepage-hero-sms.svg" />
          </div>
          <div className={theme.bottomBackground} />
        </div>
      </div>
    )
  }
}

export default HomeBanner
