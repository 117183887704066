import React from 'react';
import OpportunityCard from './opportunity-card';
import InterestValue from '../interests/interest-value';

class CustomOpportunityCard extends React.Component {
  render() {
    let {
      opportunity,
      brand,
      brand: { obscure },
      isBrand,
      action,
      showActions,
      hideBar,
      editOpportunity,
      shareOpportunity,
      deleteOpportunity
    } = this.props

    return (
      <OpportunityCard
        opportunity={ opportunity }
        brand={ brand }
        action={ action }
        editOpportunity={ editOpportunity }
        deleteOpportunity={ deleteOpportunity }
        shareOpportunity={ shareOpportunity }
        isBrand={isBrand}
        showActions={showActions}
        hideBar={hideBar}
      >
        <p style={{
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          display: '-webkit-box',
          lineHeight: 1.67,
          fontFamily: 'Larsseit-Light',
          fontSize: '12px',
          filter: obscure ? 'blur(3px)' : null,
          userSelect: obscure ? 'none' : null,
        }}>
          { opportunity.details.split('\n')
            .map((fragment, idx) => <span key={idx}>{fragment}<br /></span>) }
        </p>
      </OpportunityCard>
    )
  }
}

export default CustomOpportunityCard;
