import moment from 'moment-timezone'
import _ from 'lodash'

const currentTaskDefaults = {
  openDialog: false,
  type: null,
  assignee_id: null, // brand
  campaign_id: '',
  invite_id: '', // invite_id of creator brand
  due_date: null,
  description: '',
  category: '',
  availablePromotionChannels: [],
  promoId: '',
  confirmDelete: false,
  exportingTask: false
}

const initialState = {
  rid: 'campaignTasks',
  loading: false,
  acceptedInvites: [],
  allCampaigns: [],
  liveUpcomingBrandCampaigns: [],
  currentCampaign: {},
  campaignTasks: [],
  brandTasks: [],
  currentTask: currentTaskDefaults,
  error: null,
  subscribeUrl: null,
  calendarType: null,
  showMore: {
    show: false,
    date: null,
    shortEvents: [],
    longEvents: [],
    event: {}
  }
}

const colors = [
  '#14cba8',
  '#03a9f4',
  '#0176c8',
  '#1b3088',
  '#75f0d2',
  '#ff7043',
  '#ffca28',
  '#8290ff',
  '#4461ff',
  '#4ad9ee'
];

const getRandomKey = () => {
  let n = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + '_' + n;
}

const formatTime = (date) => {
  let formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
  return formattedDate.replace("+00:00", "Z");
}

const buildTaskEventUrl = (event, type) => {
  let calendarUrl = '';

  // allow mobile browsers to open the gmail data URI within native calendar app
  // type = (type == "google" && this.isMobile()) ? "outlook" : type;

  switch (type) {
    case 'google':
      calendarUrl = 'https://calendar.google.com/calendar/render';
      calendarUrl += '?action=TEMPLATE';
      calendarUrl += '&dates=' + formatTime(event.due);
      calendarUrl += '/' + formatTime(moment(event.due).add(1, 'hour')._d);
      calendarUrl += '&text=' + encodeURIComponent(event.title);
      calendarUrl += '&details=' + encodeURIComponent(event.description);
      break;

    case 'outlookcom':
      calendarUrl = 'https://outlook.live.com/owa/?rru=addevent';
      calendarUrl += '&startdt=' + formatTime(event.due);
      calendarUrl += '&enddt=' + formatTime(moment(event.due).add(1, 'hour')._d);
      calendarUrl += '&subject=' + encodeURIComponent(event.title);
      calendarUrl += '&body=' + encodeURIComponent(event.description);
      calendarUrl += '&allday=false';
      calendarUrl += '&uid=' + getRandomKey();
      calendarUrl += '&path=/calendar/view/Month';
      break;

    default:
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + formatTime(event.due),
        'DTEND:' + formatTime(moment(event.due).add(1, 'hour')._d),
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.description,
        'END:VEVENT',
        'END:VCALENDAR'
      ].join('\n')
      calendarUrl = 'data:text/calendar;charset=utf8,' + escape(calendarUrl)
  }

  return calendarUrl;
}

const enhanceTaskCreator = () => {
  const currentBrand = store.getState().currentBrand
  return (task, opt) => {
    let enhancedTask =  Object.assign({}, task, {
      due: moment(task.due_date).add(1, 'day')._d,
      isDue: moment(task.due_date)._d < new Date(),
      task: task.title,
      color: opt.color,
      campaignName: opt.campaignName,
      isComplete: !!task.completed_at,
      selfAssigned: task.assignee_id === task.creator_id,
      isHost: currentBrand.id === opt.hostBrandId,
      hostBrandId: opt.hostBrandId,
      hostBrandName: opt.hostBrandName,
      canEdit: task.assignee_id === currentBrand.id || task.creator_id === currentBrand.id,
      exportingTask: false
    })

    enhancedTask.exportLinks = ['google', 'ical', 'outlookcom'].map(type => {
      return {
        type: type,
        url: buildTaskEventUrl(enhancedTask, type)
      }
    })
    return enhancedTask;
  }
}

let enhanceTask

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_CAMPAIGN_TASK_ATTR':
      return _.merge({}, state, action.data)

    case 'LOADING_TASKS':
      return { ...state, loading: true, error: null }

    case 'LOAD_TASKS_SUCCESS':
      let {
        id,
        acceptedInvites,
        hostBrandId,
        name,
        discription,
        startDate,
        endDate,
        total,
        remaining,
        completed,
      } = action.payload

      let invites = action.payload.acceptedInvites

      let hostInvite = invites.find(invite => {
        return invite.invitee_id === hostBrandId
      })

      // order: host, first signed agreements, first created invites(legacy / no agreement)
      let getInviteOrderValue = (invite) => {
        let inviteValue = invite.agreement ? (invite.agreement.id + 10000) : (invite.id + 100000)
        if (invite.invitee_id === hostBrandId) {
          inviteValue = inviteValue - 1000000
        }

        return inviteValue
      }
      acceptedInvites.sort((inviteA, inviteB) => {
        return getInviteOrderValue(inviteA) - getInviteOrderValue(inviteB)
      })

      let campaignTasks = []
      enhanceTask = enhanceTaskCreator()
      acceptedInvites.forEach((invite, idx) => {
        invite.header = invite.invitee.accountname
        invite.color = colors[idx]
        invite.tasks = invite.campaignTasks.map(task => {
          return enhanceTask(task, {
            color: colors[idx],
            campaignName: name,
            hostBrandName: hostInvite.invitee.accountname,
            hostBrandId: action.payload.hostBrandId
          })
        })
        campaignTasks = campaignTasks.concat(invite.tasks)
      })



      return {
        ...state,
        loading: false,
        error: null,
        acceptedInvites: acceptedInvites,
        campaignTasks: campaignTasks,
        calendarType: 'campaign',
        currentCampaign:   {
          id,
          title: name,
          name,
          discription,
          color: colors[0],
          start: moment(startDate).add(24, 'hours').startOf('day')._d,
          end: moment(endDate).startOf('day')._d,
          totalTasks: total,
          tasksRemaining: remaining,
          completed,
          hostBrandId: action.payload.hostBrandId,
          hostBrand: {
            accountname: hostInvite.invitee.accountname
          }
        },
      }

    case 'TOGGLE_SHOW_CAMPAIGN_PARTNER':
      let partnerId = action.data.partnerBrandId

      let invite = state.acceptedInvites.find(invite => {
        return invite.invitee_id === partnerId
      })

      invite.hideTasks = !invite.hideTasks

      state.campaignTasks.forEach(task => {
        if (task.assignee_id === partnerId) {
          task.hide = invite.hideTasks
        }
      })

      return { ...state}

    case 'TOGGLE_SHOW_CAMPAIGN':
      let campaignId = action.data.campaignId

      let campaignToToggle = state.allCampaigns.find(campaign => {
        return campaign.id === campaignId
      })

      campaignToToggle.hideTasks = !campaignToToggle.hideTasks

      state.brandTasks.forEach(task => {
        if (task.campaign_id === campaignId) {
          task.hide = campaignToToggle.hideTasks
        }
      })

      return { ...state}


    case 'LOAD_TASKS_FAILURE':
      return { ...state, loading: false, error: 'Failed To Load Tasks' }

    case 'LOAD_BRAND_TASKS':
      return { ...state, loadingBrandCampaigns: true }

    case 'LOAD_BRAND_TASKS_FAILURE':
      toastr.error('Could Not Load Calendar', null, { timeOut: 2000 })
      return { ...state, loadingBrandCampaigns: false, loadedBrandCampaignsFail: true }

    case 'LOAD_BRAND_TASKS_SUCCESS':

      const initialDate = moment().subtract(2, 'months')

      let allCampaigns =  _.clone(store.getState().brandCampaigns.campaigns)

      allCampaigns = allCampaigns.filter((campaign) => {
        return campaign.status === 'accepted'
      })

      allCampaigns.sort((a,b) => {
        return a.startDate - b.startDate
      })

      let potentialCampaigns = allCampaigns.filter((campaign) => {
        return moment(campaign.endDate).add(30, 'days').isAfter(moment())
      })

      let reversedColors = colors.slice(0, 10).slice(0, potentialCampaigns.length)

      // reversedColors.reverse()

      let liveUpcoming = []

      reversedColors.forEach((color, idx) => {
        potentialCampaigns[idx].color = color
        liveUpcoming.push(potentialCampaigns[idx])
      })

      allCampaigns.reverse()

      allCampaigns.forEach(campaign => {
        campaign.color = campaign.color || 'gray'
        campaign.header = campaign.name
        campaign.tasks = []
        campaign.totalTasks = 0
        campaign.tasksRemaining = 0
        campaign.completed = 0
        campaign.start = moment(new Date(campaign.startDate)).add(24, 'hours').startOf('day')._d
        campaign.end = moment(new Date(campaign.endDate)).startOf('day')._d
      })

      enhanceTask = enhanceTaskCreator()
      let brandTasks = []
      action.payload.forEach(taskGroup => {
        taskGroup.campaignTasks.forEach(task => {

          let campaign = allCampaigns.find(campaignItem => {
            return campaignItem.id === task.campaign_id
          })

          if (campaign) {
            let enhancedTask = enhanceTask(task, {
              color: campaign.color,
              campaignName: campaign.name,
              hostBrandId: campaign.hostBrandId,
              hostBrandName: campaign.hostBrand.accountname
            })
            brandTasks.push(enhancedTask)
            campaign.tasks.push(enhancedTask)
            campaign.totalTasks++
            campaign.tasksRemaining += task.completed_at ? 0 : 1
            campaign.completed += task.completed_at ? 1 : 0
          }
        })
      })

      return {
        ...state,
        loading: false,
        error: null,
        brandTasks: brandTasks,
        allCampaigns: allCampaigns,
        liveUpcomingBrandCampaigns: liveUpcoming,
        loadingBrandCampaigns: false,
        loadedBrandCampaigns: true,
        calendarType: 'dashboard'
      }

    case 'SELECT_CAMPAIGN':
      const campaign = store.getState().currentCampaign
      return { ...state,
        selectedCampaign: Object.assign(action.data.campaign, {
          campaignPictureSrc: campaign.desktopElements.mainImage,
          host: campaign.hostBrand.accountname,
        }),
        event: action.data.event
      }

    case 'SELECT_BRAND_CAMPAIGN':
      return { ...state,
        selectedCampaign: Object.assign(action.data.campaign, {
          host: action.data.campaign.hostBrand.accountname,
          campaignPictureSrc: action.data.campaign.mainImage,
        }),
        event: action.data.event
      }

    case 'DESELECT_CAMPAIGN':
      return { ...state, selectedCampaign: null }

    case 'SELECT_TASK':
      return { ...state, selectedTask: Object.assign({}, action.data.task, { exportingTask: false }), event: action.data.event }

    case 'DESELECT_TASK':
      return { ...state, selectedTask: null }

    case 'LOADING_PROMO_CHANNELS':
      return { ...state, currentTask: Object.assign({}, state.currentTask, { availablePromotionChannels: [] }) }

    case 'LOADING_PROMO_CHANNELS_SUCCESS':
      return { ...state, currentTask: Object.assign({}, state.currentTask, {
        availablePromotionChannels: action.payload.map(unit => {
          return {
            label: unit.label || unit.promotionaltype,
            value: unit.id
          }
        })
      }) }

    case 'CREATING_TASK':
      return { ...state, currentTask: Object.assign(state.currentTask, { loading: true }) }

    case 'CREATING_TASK_SUCCESS':

      let attachedInviteToNewTask = state.acceptedInvites.find(invite => {
        return action.payload.invite_id === invite.id
      })

      enhanceTask = enhanceTaskCreator()

      let enhancedTask

      if (attachedInviteToNewTask) {
        enhancedTask = enhanceTask(action.payload, {
          color: attachedInviteToNewTask.color,
          campaignName: state.currentCampaign.title,
          hostBrandId: state.currentCampaign.hostBrandId,
          hostBrandName: state.currentCampaign.hostBrand.accountname
        })

        attachedInviteToNewTask.tasks.push(enhancedTask)
      }

      let attachedCampaignToNewTask = state.allCampaigns.find(campaign => {
        return action.payload.campaign_id === campaign.id
      })

      if (attachedCampaignToNewTask) {
        enhancedTask = enhanceTask(action.payload, {
          color: attachedCampaignToNewTask.color,
          campaignName: attachedCampaignToNewTask.name,
          hostBrandId: attachedCampaignToNewTask.hostBrandId,
          hostBrandName: attachedCampaignToNewTask.hostBrand.accountname
        })

        attachedCampaignToNewTask.tasks.push(enhancedTask)
      }

      return {
        ...state,
        campaignTasks: state.campaignTasks.concat([enhancedTask]),
        brandTasks: state.brandTasks.concat([enhancedTask]),
        currentTask: currentTaskDefaults
      }
    case 'SAVING_TASK':
      return { ...state, currentTask: Object.assign({}, state.currentTask, { loading: true }) }

    case  'SAVING_TASK_SUCCESS':
      let updatedTask = action.payload
      enhanceTask = enhanceTaskCreator()

      let attachedInvite = state.acceptedInvites.find(invite => {
        return updatedTask.invite_id === invite.id
      })

      if (attachedInvite) {
        attachedInvite.tasks = attachedInvite.tasks.map(task => {
          if (task.id === updatedTask.id) {
            return enhanceTask(updatedTask, task)
          }

          return task
        })
      }

      let newCampaignTasks = state.campaignTasks.map(task => {
        if (task.id === updatedTask.id) {
          return enhanceTask(updatedTask, task)
        }

        return task
      })

      let attachedCampaign = state.allCampaigns.find(campaign => {
        return updatedTask.campaign_id === campaign.id
      })

      if (attachedCampaign) {
        attachedCampaign.tasks = attachedCampaign.tasks.map(task => {
          if (task.id === updatedTask.id) {
            return enhanceTask(updatedTask, task)
          }

          return task
        })
      }

      let newBrandTasks = state.brandTasks.map(task => {
        if (task.id === updatedTask.id) {
          return enhanceTask(updatedTask, task)
        }

        return task
      })

      return {
        ...state,
        currentTask: currentTaskDefaults,
        campaignTasks: newCampaignTasks,
        brandTasks: newBrandTasks
      }

    case 'DELETING_TASK':
      return { ...state, currentTask: Object.assign({}, state.currentTask, { loading: true }) }

    case 'DELETING_TASK_SUCCESS':

      const deletedTask = state.currentTask

      let attachedInviteToDeleted = state.acceptedInvites.find(invite => {
        return deletedTask.invite_id === invite.id
      })

      if (attachedInviteToDeleted) {
        attachedInviteToDeleted.tasks = attachedInviteToDeleted.tasks.filter(task => {
          return task.id !== deletedTask.id
        })
      }

      let campaignTasksWithout = state.campaignTasks.filter(task => {
        return task.id !== deletedTask.id
      })

      let attachedCampaignToDeleted = state.allCampaigns.find(campaign => {
        return deletedTask.campaign_id === campaign.id
      })

      if (attachedCampaignToDeleted) {
        attachedCampaignToDeleted.tasks = attachedCampaignToDeleted.tasks.filter(task => {
          return task.id !== deletedTask.id
        })
      }

      let brandTasksWithout = state.brandTasks.filter(task => {
        return task.id !== deletedTask.id
      })

      return {
        ...state,
        campaignTasks: campaignTasksWithout,
        brandTasks: brandTasksWithout,
        currentTask: currentTaskDefaults
      }

    case 'CREATING_TASK_FAILURE':
      toastr.error('Could Not Create Task', null, { timeOut: 2000 })
      return { ...state, currentTask: Object.assign(state.currentTask, { loading: false }) }

    case 'SAVING_TASK_FAILURE':
      toastr.error('Could Not Save Task', null, { timeOut: 2000 })
      return { ...state, currentTask: Object.assign(state.currentTask, { loading: false }) }

    case 'DELETING_TASK_FAILURE':
      toastr.error('Could Not Delete Task', null, { timeOut: 2000 })
      return { ...state, currentTask: Object.assign(state.currentTask, { loading: false }) }

    case 'EXIT_CURRENT_TASK':
      return { ...state, currentTask: currentTaskDefaults }


    default:
      return state
  }
}
