import Editor from 'util/components/editor'
import React, { PureComponent } from 'react'
import TextThemes from 'css/themes/Text.css'
import { each } from 'lodash'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import COLOURS from 'util/colours'
import * as metrics from 'util/metrics'
import Dropdown from 'react-toolbox/lib/dropdown'
import EditOverlay from './EditOverlay'


import { disclaimerReplacer, regExp18Disclaimer, regExp21Disclaimer } from './utils/disclaimerPlaceholder'

const styles = {
  container: {
    position: 'relative',
    zIndex: 2,
  },
  overlay: {
    zIndex: 3,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  editorContainer: {
    position: 'relative',
    zIndex: 6,
    width: props => props.width,
    margin: '0 auto',
  },
  disclaimerPlaceholder: {
    fontFamily: 'Larsseit-Light',
    lineHeight: 1.6,
    fontWeight: 300,
    fontSize: '10px',
    color: '#9e9e9e',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  disclaimerWrapper: {
    marginBottom: 12,
    marginRight: 4,
  },
  dropdown: {
    padding: 0,
    minWidth: '65%',
    '& div': {
      padding: 0,
      '& input': {
        padding: '8px 18px 8px 12px',
        textOverflow: 'ellipsis',
        fontSize: 10,
        '& li': {
          color: 'black',
        },
      },
    },
    color: COLOURS.ink,
    '& li': {
      color: `${COLOURS.ink} !important`,
    },
  },
}

const highlight = function (str) {
  return !str ? '' : str.replace(/{{([A-Za-z]+)}}/g, '<strong><span style="background-color: #e5f3fd">{{$1}}</span></strong>')
}

class TextComponent extends PureComponent {
  static propTypes = {
    width: propTypes.string,
    edit: propTypes.func.isRequired,
    model: propTypes.object.isRequired,
    attr: propTypes.object.isRequired,
    mergeData: propTypes.object.isRequired,
    updateAttr: propTypes.object.isRequired,
    editingAttr: propTypes.object.isRequired,
    campaign: propTypes.object.isRequired,
    bodyClass: propTypes.object.isRequired,
    customFonts: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    agreeToEdit: propTypes.bool.isRequired,
    liabilityAttr: propTypes.string.isRequired,
    className: propTypes.string,
    disclaimers: propTypes.array,
  }

  static defaultProps = {
    className: null,
    width: '100%',
    disclaimers: [],
  }

  constructor(props) {
    super(props)
    const {
      model,
      attr,
    } = this.props
    if (attr === 'disclaimer') {
      const is21 = regExp21Disclaimer.test(model[attr])
      this.state = { is21 }
    } else {
      this.state = { is21: null }
    }
  }


  handleChange = value => {
    const { is21 } = this.state
    if (is21 !== value) {
      const { model, updateAttr } = this.props
      const disclaimerText = model.disclaimer.replace(is21 ? regExp21Disclaimer : regExp18Disclaimer, '')
      updateAttr('disclaimer', disclaimerText, value)
      this.setState({ is21: value })
    }
  };

  handlePaste = () => {
    const { attr } = this.props
    metrics.create('campaignManagerPastedCopy', {
      meta: {
        attr,
      },
    })
  }

  edit = () => {
    const {
      agreeToEdit,
      model,
      liabilityAttr,
      attr,
      edit,
    } = this.props
    if (agreeToEdit && !model[liabilityAttr]) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'currentCampaign',
        data: {
          showAgreeToEditModal: true,
          agreeToEditAttr: attr,
          agreeToEditLiabilityAttr: liabilityAttr,
        },
      })
    } else {
      edit(attr)
    }
  }

  stopEdit = () => {
    const {
      edit,
    } = this.props

    edit(null)
  }

  renderAbove18Disclaimer = () => {
    const { classes: css, disclaimers } = this.props

    return (
      <div className={css.disclaimerWrapper}>
        <span className={css.disclaimerPlaceholder}>
          TERMS: NO PURCHASE NECESSARY. ELIGIBILITY:
          <Dropdown
            auto
            onChange={this.handleChange}
            source={disclaimers}
            value={this.state.is21}
            className={cn(css.dropdown, css.values)}
          />
        </span>
      </div>
    )
  }

  render() {
    const {
      model,
      attr,
      mergeData,
      updateAttr,
      campaign,
      bodyClass,
      customFonts,
      classes: css,
      className,
    } = this.props
    const { is21 } = this.state

    const value = model[attr]
    let displayValue = value
    let editValue = value

    if (attr === 'disclaimer') {
      const is18 = regExp18Disclaimer.test(value)
      displayValue = (is18 || is21)
        ? disclaimerReplacer(value, true, is21)
        : value

      editValue = value.replace(is21 ? regExp21Disclaimer : regExp18Disclaimer, '')
    }

    if (mergeData) {
      each(mergeData, (v, k) => {
        const mergeField = new RegExp(`{{${k}}}`, 'g')
        displayValue = !displayValue ? '' : displayValue.replace(mergeField, v)
      })
      editValue = highlight(editValue)
    }

    if (model.editingAttr === attr) {
      return (
        <div
          // the "orange" class has to be there to enable the WYSIWYG styles"
          className={cn('orange', css.container, className)}
          id={attr}
        >
          <div
            onClick={this.stopEdit}
            className={css.overlay}
          />
          <div className={css.editorContainer}>
            { attr === 'disclaimer' && this.renderAbove18Disclaimer() }
            <Editor
              {...this.props}
              orange
              model={campaign || model || {}}
              // height={this.state.height}
              originalContent={editValue}
              bodyClass={bodyClass}
              customFonts={customFonts}
              handlePaste={this.handlePaste}
            />
          </div>
        </div>
      )
    }

    return (
      <div
        className={cn(className, css.container)}
        id={attr}
      >
        { !!updateAttr && <EditOverlay onClick={this.edit} /> }
        <div
          className={TextThemes.textComponentField}
          style={{ overflowWrap: 'break-word' }}
          dangerouslySetInnerHTML={{ __html: displayValue }}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(TextComponent)
