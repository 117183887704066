import React from 'react'
import Radium from 'radium'
import propTypes from 'prop-types'
import DojoModal from 'util/components/dojomodal'
import injectSheet from 'react-jss'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import InputContainer from './InputContainer'
import EditOverlay from '../EditOverlay'
import FormEditModal from '../FormEditModal'

const styles = {
  container: {
    position: 'relative',
  },
}

const Form = class extends React.Component {
  static propTypes = {
    campaign: propTypes.object.isRequired,
    elements: propTypes.object.isRequired,
    termsLink: propTypes.string,
    acceptedInvites: propTypes.array.isRequired,
    edit: propTypes.func.isRequired,
    editElements: propTypes.func.isRequired,
    updateAttr: propTypes.func.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool.isRequired,
  }

  static defaultProps = {
    termsLink: '',
  }

  state = {
    hover: false,
    editingLegalLanguage: [],
  }

  stopEdit = e => {
    e.stopPropagation()

    const {
      edit,
      editElements,
    } = this.props

    const { editingLegalLanguage } = this.state

    const closeModal = () => {
      edit(null)
      editElements(null)
      this.setState({
        editingLegalLanguage: [],
      })
    }

    if (editingLegalLanguage.length > 0) {
      ConfirmationDispatcher.check({
        header: 'You Have Unsaved Changes',
        copy:
          'Do you want to discard these changes?',
        confirmText: 'Discard',
        cancelText: 'Go Back',
        action: () => {
          ConfirmationDispatcher.closeModal()
          closeModal()
        },
      })
    } else {
      closeModal()
    }
  }

  edit = () => {
    const {
      campaign,
      edit,
    } = this.props
    if (campaign.editingAttr === 'form') {
      return
    }
    edit('form')
  }

  setEditingLegalLanguage = (key, add = true) => {
    this.setState(prevState => ({
      editingLegalLanguage: add
        ? [...prevState.editingLegalLanguage, key]
        : prevState.editingLegalLanguage.filter(editingKey => editingKey !== key),
    }))
  }

  render() {
    const {
      campaign,
      elements,
      termsLink,
      acceptedInvites,
      updateAttr,
      classes: css,
      desktopVersion,
    } = this.props

    const allPartners = acceptedInvites.map(invite => invite.invitee.accountname)
    const { isSolo } = campaign

    return (
      <div
        id="pre-entry"
        className={`${css.container} pre stage`}
      >
        <div id="form-input-container">
          { updateAttr && <EditOverlay onClick={this.edit} /> }
          <InputContainer
            campaign={campaign}
            inputs={campaign.inputs}
            includeCustomField={campaign.includeCustomField}
            customFieldName={campaign.customFieldName}
            additionalTermsDetail={campaign.additionalTermsDetail}
            entryButtonText={elements.entryButtonText}
            smsOptinHeadlineText={elements.smsOptinHeadlineText}
            smsOptinLanguage={elements.smsOptinLanguage}
            formStyles={elements.styles.fonts}
            preCheck={campaign.preCheck}
            allPartners={allPartners}
            perBrandTerms={campaign.perBrandTerms}
            styles={elements.styles}
            termsLink={termsLink}
            elements={elements}
            darkTemplateTheme={false}
            desktopVersion={desktopVersion}
            updateAttr={updateAttr}
          />
        </div>
        <DojoModal
          isOpen={campaign.editingAttr === 'form'}
          hideModal={this.stopEdit}
          className={isSolo ? 'soloFormEditModal' : 'formEditModal'}
        >
          <FormEditModal
            {...this.props}
            closeModal={this.stopEdit}
            isSoloCampaign={isSolo}
            allPartners={allPartners}
            setEditingLegalLanguage={this.setEditingLegalLanguage}
          />
        </DojoModal>
      </div>
    )
  }
}

export default Radium(injectSheet(styles)(Form))
