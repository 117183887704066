export default [
  {
    key: 'shopify',
    label: 'Shopify',
    img: '/images/integration-logos/shopify.png',
  },
  {
    key: 'stripe',
    label: 'Stripe',
    img: '/images/integration-logos/stripe-logo.png',
    oauth: {
      route: 'stripe-auth',
    },
  },
  {
    key: 'woocommerce',
    label: 'WooCommerce',
    img: '/images/integration-logos/woocommerce.svg',
    inputs: [{
      type: 'text',
      attr: 'storeUrl',
      label: 'WooCommerce website',
    }],
  },
  {
    key: 'recharge',
    label: 'Recharge',
  },
  {
    key: 'pixel',
    label: 'DojoMojo Conversion Pixel',
  },
]
