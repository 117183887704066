import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import moment from 'moment-timezone'

import LiveCampaignTheme from 'css/themes/campaign-analytics-cards/liveCampaign.css'
import NotLiveCampaignTheme from 'css/themes/campaign-analytics-cards/notLiveCampaign.css'

import DealTitleAndBadge from './transaction-card-components/DealTitleAndBadge'
import MediaCostTypeAndDates from './transaction-card-components/MediaCostTypeAndDates'
import CounterBrandInfo from './transaction-card-components/CounterBrandInfo'
import CardDealStats from './transaction-card-components/CardDealStats'

const mapStateToProps = ({ deals, currentBrand }) => ({ deals, currentBrand })

class DealTransactionCard extends Component {
  componentDidMount() {
    const {
      deal: { id },
      dealActions,
    } = this.props

    if (id) {
      dealActions.getDealAnalyticsById(id)
    }
  }

  render() {
    const {
      deal,
      deals,
      currentBrand,
    } = this.props

    const { dealResults } = deals

    const {
      start_date,
      end_date,
      max_clicks,
      min_clicks,
      label,
      status,
      pretty_type,
      cost_type,
      buying_brand,
      buying_brand_id,
      selling_brand,
      expected_views,
      price,
      max_value,
      id,
      opens, // Change this to proper prop name
    } = deal

    const isCPC = cost_type === 'CPC'
    const isFixed = cost_type === 'Fixed'
    const isLive = moment().isBetween(start_date, end_date)
    const currentTheme = isLive && status === 'live' ? LiveCampaignTheme : NotLiveCampaignTheme

    const dealEmailDojoClicks = (dealResults[id] && dealResults[id].dealEmailDojoClicks) || 0
    const dealValueByType = isCPC ? (dealEmailDojoClicks * price) : isFixed ? max_value : null

    const commonProps = {
      price,
      isCPC,
      isLive,
      isFixed,
      dealValueByType,
      dealEmailDojoClicks,
      expectedViews: expected_views,
      minClicks: min_clicks,
      maxClicks: max_clicks,
      status,
      opens, // Change this to proper prop name
    }

    const counterBrand = buying_brand_id === currentBrand.id
      ? selling_brand
      : buying_brand

    return (
      <Card className={currentTheme.card}>
        <Link to={`/partnerships/deal/${id}/analytics`}>
          <Grid>
            <DealTitleAndBadge status={status} label={label} isLive={isLive} />
            <MediaCostTypeAndDates
              status={status}
              isLive={isLive}
              prettyType={pretty_type}
              costType={cost_type}
              startDate={start_date}
              endDate={end_date}
            />
            <CounterBrandInfo
              status={status}
              logo={counterBrand.logo}
              brandName={counterBrand.accountname}
              isLive={isLive}
            />
            <CardDealStats {...commonProps} />
            {/*  */}
          </Grid>
        </Link>
      </Card>
    )
  }
}

export default connect(mapStateToProps)(DealTransactionCard)
