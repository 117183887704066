import React from 'react'
import COLOURS from 'util/colours'
import { Small } from 'visual-components/util/texts'

class SocialLabel extends React.Component {
  render() {
    var {
      label,
      attr,
      socialValue,
      model,
      sublabel
    } = this.props


    return (
      <div>
        <div style={{
          paddingBottom: '8px'
        }}>
          <span style={{
            fontFamily: 'Larsseit-Medium',
            fontSize: '14px',
            marginRight: '8px',
            color: '#212731'
          }}>
            { label }
          </span>
          { sublabel
            ? <span style={{
                display: 'inline-block'
              }}>
                <Small>
                  { sublabel }
                </Small>
              </span>
            : null
          }
          <span style={{
            float: 'right',
            fontFamily: 'Larsseit',
            fontSize: '14px',
            color: COLOURS.azure
          }}>
            { socialValue }
          </span>
        </div>
      </div>
    )
  }
}

export default SocialLabel
