import React, { PureComponent as Component } from 'react'
import COLOURS from 'util/colours'
import { P, Tiny } from 'visual-components/util/texts'

import {
  instanceOf,
  func,
  any,
  bool,
} from 'prop-types'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates'

import DateRangeSortWithDisplay from 'visual-components/shared/filters/DateRangeSortWithDisplay'
import AnalyticsTheme from 'css/themes/analytics.css'
import moment from 'moment-timezone'

const timeFrameMap = {
  7: 'Last 7 Days',
  30: 'Last 30 Days',
  90: 'Last 90 Days',
  180: 'Last 180 Days',
  365: 'Last 12 Months',
  'All Time': 'All Time',
  Custom: 'Custom',
}

const getDefaultOptions = (timeFrame, startDate, endDate) => {
  const displayEl = (
    <div className={AnalyticsTheme.customLabel}>
      <div style={{ marginBottom: '12px' }}>
        <Tiny coral>
          { timeFrameMap[timeFrame] }
        </Tiny>
      </div>
      <P>
        <i>
          {`${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`}
        </i>
      </P>
    </div>
  )

  const defaultOptions = [
    {
      value: 'null',
      disabled: true,
      label: displayEl,
    },
    {
      value: 7,
      label: 'Last 7 Days',
    },
    {
      value: 30,
      label: 'Last 30 Days',
    },
    {
      value: 90,
      label: 'Last 90 Days',
    },
    {
      value: 180,
      label: 'Last 180 Days',
    },
    {
      value: 365,
      label: 'Last 12 Months',
    },
    {
      value: 'All Time',
      label: 'All Time',
    },
    {
      value: 'Custom',
      label: 'Custom',
    },
  ]

  return defaultOptions
}

class CardDateRangePickerRow extends Component {
  static propTypes = {
    oldestDate: instanceOf(Date).isRequired,
    changeFilter: func.isRequired,
    timeFrame: any,
    displayed: bool,
    alignLeft: bool,
  }

  static defaultProps = {
    timeFrame: 'All Time',
    displayed: false,
    alignLeft: false,
  }

  updateDatePicker = data => {
    const { name, model } = this.props

    store.dispatch({
      type: 'UPDATE_DATE_PICKER',
      model,
      name,
      data,
    })
  }

  handleCustomVals = () => {
    this.updateDatePicker({
      displayed: true,
      focusedInput: 'startDate',
      startDate: null,
      endDate: null,
      timeFrame: 'Custom',
    })
  }

  handleFilterChange = (name, val) => {
    const data = { [name]: val }

    if (val === 'Custom') {
      this.handleCustomVals()
    } else if (val === 'All Time') {
      const endDate = moment()
      const startDate = moment(this.props.oldestDate)
      this.datesChange({ ...data, startDate, endDate })
    } else {
      const endDate = moment()
      const startDate = moment().subtract(val - 1, 'days')

      this.datesChange({ ...data, startDate, endDate })
    }
  }

  datesChange = data => {
    const { startDate, endDate } = data

    this.props.changeFilter(startDate, endDate)

    this.updateDatePicker(data)
  }

  onDatePickerClose = () => {
    const { startDate, endDate } = this.props

    /* if you haven't chosen a valid date range, it defaults back to all time */
    if (!startDate || !endDate) {
      this.handleFilterChange('timeFrame', 'All Time')
    } else {
      this.datesChange({ startDate, endDate })
    }

    this.updateDatePicker({ displayed: false })
  }

  onFocusChange = focusedInput => {
    if (!focusedInput) return // doesn't update the focusedInput if it is trying to close the DRP

    this.updateDatePicker({ focusedInput })
  }

  render() {
    const {
      timeFrame,
      displayed,
      startDate,
      endDate,
      alignLeft,
      focusedInput,
      oldestDate,
    } = this.props
    const defaultOptions = getDefaultOptions(timeFrame, startDate || oldestDate, endDate)

    return (
      <div
        style={{
          height: '40px',
          borderTop: `1px solid ${COLOURS.silver}`,
          padding: '9px 16px',
        }}
      >
        <DateRangeSortWithDisplay
          onChange={this.handleFilterChange}
          source={defaultOptions}
          name="timeFrame"
          value={timeFrame}
        />
        <div style={{ position: 'relative' }}>
          <div
            id="alwaysOpen"
            style={{
              display: displayed ? null : 'none',
              left: alignLeft ? -200 : 79,
            }}
          >
            {
              displayed
                ? (
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={this.updateDatePicker}
                    focusedInput={focusedInput}
                    onFocusChange={this.onFocusChange}
                    anchorDirection="right"

                    navPrev={<img src="/images/icons/left-arrow.svg" />}
                    navNext={<img src="/images/icons/right-arrow.svg" />}
                    hideKeyboardShortcutsPanel

                    keepOpenOnDateSelect
                    onClose={this.onDatePickerClose}
                    displayFormat="MMM DD, YYYY"
                    isOutsideRange={
                      oldestDate
                        ? day => !isInclusivelyAfterDay(day, moment(oldestDate)) || !isInclusivelyBeforeDay(day, moment())
                        : day => !isInclusivelyBeforeDay(day, moment())
                    }
                  />
                ) : null
            }
          </div>
        </div>
      </div>
    )
  }
}

export default CardDateRangePickerRow
