import React, { Component } from 'react'
import { Dialog } from 'react-toolbox/lib/dialog'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import {
  func,
  bool,
  string,
  oneOfType,
  node,
  array,
} from 'prop-types'

class MultiTaskModal extends Component {
  static propTypes = {
    active: bool.isRequired,
    close: func.isRequired,
    title: string.isRequired,
    disableOverlayClick: bool,
    children: oneOfType([node, array, func]).isRequired,
  }

  static defaultProps = {
    disableOverlayClick: false,
  }

  render() {
    const {
      active,
      close,
      title,
      children,
      disableOverlayClick,
    } = this.props

    return (
      <Dialog
        theme={DialogTheme}
        active={active}
        onEscKeyDown={close}
        onOverlayClick={disableOverlayClick ? null : close}
        title={title}
        className={DialogTheme.smallShadow}
      >
        <img
          onClick={close}
          className={DialogTheme.closeImage}
          src="/images/icons/close.svg"
        />
        {children}
      </Dialog>
    )
  }
}

export default MultiTaskModal
