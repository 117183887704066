import React from 'react'
import { withRouter } from 'react-router-dom'

import Sign from './sign'
import Upload from './upload'

class Complete extends React.Component {
  componentDidMount() {
    const { partnershipAgreement, actions, params, history } = this.props
    
    if (partnershipAgreement.error) {
      history.replace(`/builder/${params.campaignId}/partnership-agreement/dashboard`)
    }

    if (!partnershipAgreement.agreement.id) {
      actions.loadPartnershipAgreement(params.partnershipId)
    }
  }

  render() {
    const { partnershipAgreement } = this.props

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    const { agreement: { type } } = partnershipAgreement

    return type === 'template' ? (
      <Sign {...this.props} />
    ) : (
      <Upload hideAction {...this.props} />
    )
  }
}

export default withRouter(Complete)
