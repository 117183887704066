import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import COLOURS from 'util/colours'

import WrapperTooltip from './wrapper-tooltip'

const style = {
  wrapper: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
  info: {
    cursor: 'pointer',
  },
}

const colorMap = {
  blue: {
    fill: COLOURS.azure,
    background: COLOURS.crystal,
  },
  grey: {
    fill: COLOURS.stone,
    background: COLOURS.foggy,
  },
}

function Tooltip(props) {
  const { classes: css, color, ...rest } = props

  const colors = colorMap[color] || colorMap.blue

  return (
    <WrapperTooltip {...rest} delay={0}>
      <div className={css.wrapper}>
        <svg className={css.info} width="12" height="12" viewBox="0 0 12 12">
          <g fill="none" fillRule="evenodd">
            <circle cx="6" cy="6" r="6" fill={colors.background} />
            <path fill={colors.fill} d="M5 5H7V10H5z" />
            <circle cx="6" cy="3" r="1" fill={colors.fill} />
          </g>
        </svg>
      </div>
    </WrapperTooltip>
  )
}
Tooltip.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

Tooltip.defaultProps = {
  color: 'blue',
}

export default React.memo(injectCSS(style)(Tooltip))
