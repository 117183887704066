import React from 'react';
import { bool, func } from 'prop-types';
import Dialog from 'react-toolbox/lib/dialog';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import COLOURS from 'util/colours';

import dialogTheme from 'css/themes/dialogs/formModal.css'

class PrizingValueWarningModal extends React.Component {
  static propTypes = {
    active: bool.isRequired,
    closeModal: func.isRequired
  }

  render() {
    let {
      active,
      closeModal
    } = this.props;

    return (
      <div>
        <Dialog
          theme={ dialogTheme }
          active={ active }
          onEscKeyDown={ closeModal }
          onOverlayClick={ closeModal }
          title='Heads up!'
        >
          <p style={{
            fontFamily: 'Larsseit',
            fontSize: '14px',
            color: COLOURS.ink,
            lineHeight: '22px'
          }}>
             Note: state law in FL and NY require that sweepstakes with prizes valued at $5,000 and higher be registered and bonded. Contact your counsel for more details. We don’t recommend allowing entrants from RI, which requires it for prizes over $500.
          </p>
          <ModalButtonGroup
            canSave={ true }
            confirm={ closeModal }
            hideLine={ true }
            confirmText="Got it"
          />
        </Dialog>
      </div>
    )
  }
}

export default PrizingValueWarningModal;
