import React, { Component } from 'react'
import { number, object, func } from 'prop-types'

import { MenuItem } from 'react-toolbox/lib/menu'

import COLOURS from 'util/colours'
import LinkOrSwitchBrand from 'visual-components/notificationCard/LinkOrSwitchBrand'

import { P } from 'visual-components/util/texts'
import WideNavBarDropdownTheme from 'css/themes/WideNavBarDropdown.css'

const themes = {
  WideNavBarDropdown: WideNavBarDropdownTheme,
}

class NotificationPopupItem extends Component {
  static propTypes = {
    item: object.isRequired,
    handleDismiss: func.isRequired,
    handleRead: func.isRequired,
    delay: number,
  }

  static defaultProps = {
    delay: 5000,
  }

  componentDidMount() {
    this.timedDismissal()
  }

  saveNewVersion = version => {
    try {
      localStorage.setItem('dojo-release', version)
    } catch (e) {
      // ignore error when localStorage not supported
    }
  }

  // For use with new version notification only
  dismissOrRefresh = async refresh => {
    if (refresh === true) {
      window.location.reload(true)
    } else {
      // save the new version so user isn't bother until next new version
      const { item: { newVersion, id }, handleDismiss } = this.props
      this.saveNewVersion(newVersion)
      handleDismiss(id)
    }
  }

  dismiss = send => {
    const { item: { newVersion, id }, handleDismiss, handleRead } = this.props
    if (newVersion) return this.dismissOrRefresh(send)

    if (send === true) {
      handleRead(id)
    }

    this.clearTimer()
    return handleDismiss(id)
  }

  timedDismissal = () => {
    // if this is new version notification, delay for 20 minutes
    // otherwise prop (5 minutes default)
    const { item: { newVersion }, delay } = this.props
    const delayPeriod = newVersion ? 1000 * 60 * 20 : delay
    this.timer = setTimeout(this.dismiss, delayPeriod)
  }

  clearTimer = () => clearTimeout(this.timer)

  render() {
    const { item } = this.props
    const text = item.text || (item.presentational && item.presentational.text)
    return (
      <div
        onMouseEnter={this.clearTimer}
        onMouseLeave={this.timedDismissal}
        style={{
          width: '400px',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
          marginBottom: '15px',
        }}
      >
        <MenuItem
          style={{ height: '100%' }}
          theme={themes.WideNavBarDropdown}
          className={`${themes.WideNavBarDropdown.Notification} ${
            themes.WideNavBarDropdown.unread
          }`}
        >
          <LinkOrSwitchBrand
            to={item.presentational && item.presentational.to}
            brandId={item.brand_id}
            hide={!!item.newVersion}
            onClick={() => this.dismiss(true)}
          >
            <div>
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: COLOURS.cloudGrey,
                  backgroundColor: 'white',
                  padding: '5px',
                  lineHeight: '28px',
                  marginRight: '12px',
                }}
              >
                <img
                  src={item.img || '/images/defaults/mojo-white-tiger-logo@2x.png'}
                  style={{
                    width: '100%',
                    maxHeight: '30px',
                    maxWidth: '30px',
                  }}
                />
              </div>
            </div>
          </LinkOrSwitchBrand>
          <LinkOrSwitchBrand
            to={item.presentational && item.presentational.to}
            brandId={item.brand_id}
            hide={!!item.newVersion}
            onClick={() => this.dismiss(true)}
          >
            <div
              style={{
                width: '288px',
                height: '40px',
                whiteSpace: 'normal',
              }}
            >
              <P multiline>
                {text && text.length > 65 && !item.newVersion
                  ? `${text.substring(0, 65)}...`
                  : text}
              </P>
            </div>
          </LinkOrSwitchBrand>
          <div
            onClick={this.dismiss}
            style={{
              width: '60px',
              textAlign: 'right',
              height: '35px',
              marginLeft: '8px',
            }}
          >
            <img src="/images/icons/close.svg" />
          </div>
        </MenuItem>
      </div>
    )
  }
}

export default NotificationPopupItem
