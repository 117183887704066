import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { Button } from 'react-toolbox/lib/button'
import { H3, P } from 'visual-components/util/texts'

import DialogTheme from 'css/themes/dialogs/formModal.css'
import ButtonTheme from 'css/themes/Buttons.css'

const mapState = ({ softEntriesPaywallModal, currentBrand}) =>
  ({ softEntriesPaywallModal, currentBrand})

class SoftEntriesPaywall extends React.Component {
  redirectToPlans = () => {
    const { history } = this.props

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'softEntriesPaywallModal',
      data: {
        open: false
      }
    })

    history.push('/upgrade-plan')
  }

  close = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'softEntriesPaywallModal',
      data: {
        open: false
      }
    })
  }

  trackDropLine = () => {
    // TODO: What should this do?
  }

  render() {
    const { softEntriesPaywallModal: { open } } = this.props

    return (
      <Dialog
        theme={ DialogTheme }
        active={ !!open }
        onEscKeyDown={ this.close }
        onOverlayClick={ this.close }
      >
        <div style={{
          textAlign: 'center'
        }}>
          <div>
            <img src="/images/Success_Icon.svg"
              style={{
                height: '80px',
                width: '80px',
                marginBottom: '30px'
            }} />
          </div>

          <div style={{
            marginBottom: '20px'
          }}>
            <H3>
              Your entries have been sent!
            </H3>
          </div>
        </div>

        <div style={{
          marginBottom: '20px'
        }}>
          <P>
            Avoid disrupting your performance! Choose your plan to continue growing your email list and audience on DojoMojo.
          </P>
        </div>

        <div style={{
          marginBottom: '20px'
        }}>
          <P>
            Questions? <a href='mailto:support@dojomojo.com' onClick={ this.trackDropLine }>Drop us a line</a> and tell us how we can help out.
          </P>
        </div>

        <ModalButtonGroup canSave
          confirm={ this.redirectToPlans }
          confirmText={ 'Select My Plan' }
          cancel={ this.close }
          cancelText={ 'Close' }
        />
      </Dialog>
    )
  }
}

export default withRouter(connect(mapState)(SoftEntriesPaywall))
