export const setStatusDialog = dialogActive => ({
  type: 'UPDATE_DEAL_COLLABORATION_ATTR',
  data: { dialogActive },
})

export const clearLinks = () => ({
  type: 'CLEAR_COLLABORATION_LINKS',
})

export const loadMessages = dealId => ({
  http: {
    url: `/deals/message/${dealId}`,
  },
  types: [
    'LOADING_DEAL_COLLABORATION_MESSAGES',
    'LOADING_DEAL_COLLABORATION_MESSAGES_SUCCESS',
    'LOADING_DEAL_COLLABORATION_MESSAGES_FAILURE',
  ],
  data: { dealId },
})

export const loadLinks = dealId => ({
  http: {
    url: `/deals/links/${dealId}`,
  },
  types: [
    'LOADING_DEAL_COLLABORATION_LINKS',
    'LOADING_DEAL_COLLABORATION_LINKS_SUCCESS',
    'LOADING_DEAL_COLLABORATION_LINKS_FAILURE',
  ],
  data: { dealId },
})

export const sendMessage = ({
  deal_id,
  content,
  profile,
  brand_id,
  is_decline_reason = false,
}) => ({
  http: {
    url: `/deals/message/${deal_id}`,
    method: 'POST',
    data: {
      content,
      is_decline_reason,
    },
  },
  data: {
    content,
    profile,
    brand_id,
    deal_id,
    is_decline_reason,
  },
  types: [
    'SENDING_DEAL_COLLABORATION_MESSAGE',
    'SENDING_DEAL_COLLABORATION_MESSAGE_SUCCESS',
    'SENDING_DEAL_COLLABORATION_MESSAGE_FAILURE',
  ],
})
