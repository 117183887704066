import moment from 'moment-timezone'
import { merge } from 'lodash'

const initialState = {
  loading: false,
  error: null,
  transactions: [],
  dealTransactions: [],
}

const recursivelyMarkNullValuesAsEmptyString = object => {
  Object.keys(object).forEach(key => {
    if (object[key] === null) {
      object[key] = ''
    } else if (typeof object[key] === 'object') {
      recursivelyMarkNullValuesAsEmptyString(object[key])
    }
  })
}

const processBankAccount = external_account => {
  const {
    last4,
    metadata: { isValid },
  } = external_account
  return {
    ...external_account,
    account_number: `****-${last4}`,
    verified: !!isValid,
  }
}

const flattenAccount = account => {
  const { dealTransactions = [] } = account

  if (!account.id) return { dealTransactions }

  const {
    external_account,
    legal_entity: {
      business_tax_id_provided,
      dob,
      first_name,
      last_name,
      ssn_last_4_provided,
      verification,
    },
    tos_acceptance,
    ...flattened
  } = account

  const legal_entity = { ...account.legal_entity }

  flattened.tos_already_accepted = !!tos_acceptance.date
  flattened.verified = verification.status === 'verified'
  flattened.dealTransactions = dealTransactions

  // Full name
  if (first_name && last_name) flattened.full_name = `${first_name} ${last_name}`

  // Process bank account
  if (external_account) flattened.external_account = processBankAccount(external_account)

  // Show masked numbers
  if (business_tax_id_provided) legal_entity.business_tax_id = '**-*******'
  if (ssn_last_4_provided) legal_entity.ssn_last_4 = '****'

  // Parse DOB
  const { year, month, day } = dob
  if (year && month && day) {
    legal_entity.dob = moment.utc({ year, month: month - 1, day }).format('MM/DD/YYYY')
  } else {
    legal_entity.dob = ''
  }

  recursivelyMarkNullValuesAsEmptyString(legal_entity)
  flattened.legal_entity = legal_entity

  return flattened
}

const getError = action => {
  const { payload: { response: { status, data } = {} } = {} } = action
  return status && data ? `${status}: ${data}` : 'Error'
}

export default function brandAccountReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_BRAND_ACCOUNT':
      return merge({}, state, action.data)
    case 'LOADING_BRAND_ACCOUNT':
      return { ...state, loading: true, error: null }
    case 'LOADING_BRAND_ACCOUNT_TRANSACTIONS':
      return { ...state, transactionsLoading: true, transactionsError: null }

    case 'LOAD_BRAND_ACCOUNT_SUCCESS': {
      const { transactionsLoading, dealTransactions } = state
      return {
        ...flattenAccount(action.payload),
        transactionsLoading,
        dealTransactions,
        loading: false,
        last_updated: Date.now(),
      }
    }
    case 'VERIFY_BANK_ACCOUNT_SUCCESS':
    case 'ADD_BANK_ACCOUNT_SUCCESS': {
      // Used for verifying payment source bank accounts
      if (action.simpleVerifyId) {
        return {
          ...state,
          loading: false,
          simpleVerifyId: null,
          simpleVerifySuccess: true,
        }
      }

      return { ...state, loading: false, external_account: processBankAccount(action.payload) }
    }
    case 'LOAD_BRAND_ACCOUNT_TRANSACTIONS_SUCCESS': {
      const { loading } = state
      return { ...flattenAccount(action.payload), loading, transactionsLoading: false }
    }

    case 'VERIFY_BANK_ACCOUNT_FAILURE':
    case 'LOAD_BRAND_ACCOUNT_FAILURE':
    case 'ADD_BANK_ACCOUNT_FAILURE':
      return {
        ...state,
        error: getError(action),
        loading: false,
        simpleVerifySuccess: false,
      }

    case 'LOAD_BRAND_ACCOUNT_TRANSACTIONS_FAILURE':
      return { ...state, transactionsError: getError(action), transactionsLoading: false }

    case 'CLEAR_BRAND_ACCOUNT_ERROR':
      return { ...state, error: null, transactionsError: null }

    default:
      return state
  }
}
