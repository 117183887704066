import React, { PureComponent as Component } from 'react'
import { array, string, bool } from 'prop-types'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'
import PartnershipAnalyticsTable from 'visual-components/analytics/partnerships/PartnershipAnalyticsTable'
import PartnershipAnalyticsGrid from 'visual-components/analytics/partnerships/PartnershipAnalyticsGrid'

class PartnershipAnalyticsResultsContainer extends Component {
  static propTypes = {
    partnerships: array.isRequired,
    display: string,
    loading: bool,
    error: string,
    filtered: bool,
  }

  static defaultProps = {
    display: '',
    error: null,
    loading: false,
    filtered: false,
  }

  render() {
    const {
      partnerships,
      loading,
      error,
      filtered,
      display,
    } = this.props

    if (loading) return null

    if (error || !partnerships || !partnerships.length) {
      const description = error
        ? `Oops! There was an error processing your search. Please try again. ${error}`
        : filtered
          ? 'Oops! No search results. Expand your filters and try again.'
          : 'It looks like you haven’t started any partnerships yet. Head to Explore to view brands in the DojoMojo network'

      return (
        <div style={{ width: '752px' }}>
          <EmptyBonzai noTree description={description} />
        </div>
      )
    }

    if (display === 'grid') return <PartnershipAnalyticsGrid partnerships={partnerships} />

    return <PartnershipAnalyticsTable partnerships={partnerships} />
  }
}

export default PartnershipAnalyticsResultsContainer
