import React from 'react'
import { withRouter } from 'react-router-dom'
import MiniOpportunityInfo from './mini-opportunity-info'
import { Card } from 'react-toolbox/lib/card';

import CardThemes from 'css/themes/card.css';

class SmallOpportunityCard extends React.Component {
  viewOpportunity = () => {
    const { opportunity, history, isPublic } = this.props
    const { type, id } = opportunity
    const url = `opportunities/${type}/${id}`
    
    if (isPublic) {
      // TODO: forces reload, investigate if worth it to set up publicly
      // accessible endpoints to fetch brand info between in-app page requests
      window.location.href = `/home/${url}`
    } else {
      history.push(`/explore/${url}`)
    }
  }
  
  render() {
    let { brand, opportunity } = this.props

    return (
      <div className={ CardThemes.miniOpportunityCard }>
        <Card onClick={this.viewOpportunity} style={{
          width: '100%',
          paddingTop: '16px',
          paddingRight: '16px',
          paddingBottom: '8px',
          paddingLeft: '16px',
          height: '168px',
          cursor: 'pointer',
        }}>
          <MiniOpportunityInfo brand={ brand } opportunity={ opportunity } />
        </Card>
      </div>
    )
  }
}

export default withRouter(SmallOpportunityCard)
