import { merge } from 'lodash'
import DataIntegrationsMap from 'components/brand_settings/data-integrations/data-integrations-map'

const initialState = {
  rid: 'dataIntegrations',
  loading: false,
  step: 'choose',
  modalOpen: false,
  type: '',
  pixelConfirmationActive: '',
  conversionSuccessPath: '',
  showSuggestionDialog: true,
  hasAnyActiveIntegrations: true,
}

export default function dataIntegrationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DATA_INTEGRATIONS_ATTR':
      return merge({}, state, action.data)

    case 'LOADING_INTEGRATIONS':
      return { ...state, loading: true }

    case 'LOAD_INTEGRATIONS_SUCCESS': {
      const activeIntegration = DataIntegrationsMap
        .find(integration =>
          (integration.key === 'pixel' && action.payload[integration.key])
          || (
            action.payload[integration.key]
            && action.payload[integration.key].isIntegrated
          ))

      return Object.assign({}, state, action.payload, {
        loading: false,
        hasAnyActiveIntegrations: !!activeIntegration,
      })
    }
  

    case 'LOAD_INTEGRATIONS_FAILURE':
      toastr.error('Could Not Load Integrations', null, { timeOut: 2000 })
      return { ...state, loading: false }

    case 'DISCONNECTING_INTEGRATION':
      return { ...state, loading: true }

    case 'DISCONNECTING_INTEGRATION_FAILURE':
      toastr.error('Could Not Disconnect Integration', null, { timeOut: 2000 })
      return { ...state, loading: false }

    case 'DISCONNECTING_INTEGRATION_SUCCESS': {
      toastr.success('Integration Disconnected', null, { timeOut: 2000 })
      const activeIntegration = DataIntegrationsMap
        .find(integration =>
          (integration.key === 'pixel' && action.payload[integration.key])
          || (
            action.payload[integration.key]
            && action.payload[integration.key].isIntegrated
          ))

      return Object.assign({}, state, action.payload, {
        loading: false,
        modalOpen: false,
        step: 'choose',
        hasAnyActiveIntegrations: activeIntegration ? true : false
      })
    }


    case 'TESTING_PIXEL_SUCCESS':
      return {
        ...state,
        loading: false,
        pixelConfirmationActive: true,
        pixelConfirmationSuccess: action.payload.hasPixel,
      }

    case 'CLEAR_PIXEL':
      return {
        ...state,
        modalOpen: !!action.data.isFailedConfirm,
        pixelConfirmationActive: false,
      }

    case 'CLOSE_INTEGRATION_SUGGESTION_DIALOG':
      return {
        ...state,
        showSuggestionDialog: false,
      }

    default:
      return state
  }
}
