import MessagingPartnershipConstants from 'constants/messaging-partnership-constants'
import PartnershipApplicationConstants from 'constants/partnership-application-constants'

export const sendInterest = (invite_id, messageId, conversationId, messageType, messageData = {}) => dispatch => {
  let message = `
    <p>Great news! We're interested in joining your campaign, <strong>${messageData.campaignName}.</strong></p>
    <br />
    <p>Below are some preliminary stats about <strong>${messageData.brandName}'s</strong> proposed partnership:</p>
    <br />
    <p><strong>Proposed Entry Contribution:</strong> ${messageData.entryContribution}</p>
    ${messageData.promoDates ? `<p><strong>Promotional Dates:</strong> ${messageData.promoDates}</p>` : `` }
    <p><strong>Prizing Contribution:</strong> ${messageData.prizingContribution}</p>
    ${messageData.note ? `<p><strong>Note:</strong> "${messageData.note}"</p>` :``}
    <br />
    <p>
      You can now create a Partnership Agreement, which is required to finalize adding us as a partner in
      a campaign. These help ensure everyone is meeting their prizing and promotional obligations,
      and can be created easily on DojoMojo.
    </p>
  `

  const {
    entryContribution,
    prizingContribution,
    note: optionalNote,
    promoDates
  } = messageData

  dispatch({
    model: 'conversations',
    http: {
      url: `/partnership/mark-interested/${invite_id}`,
      method: 'POST',
      data: {
        message,
        invite_id,
        conversation_id: conversationId,
        application: messageData.application,
        additionalPartnerInfo: {
          entryContribution,
          prizingContribution,
          optionalNote,
          promoDates,
        }
      }
    },
    types: [
      'MARK_PARTNERSHIP_INTEREST_REQUEST',
      'MARK_PARTNERSHIP_INTEREST_SUCCESS',
      'MARK_PARTNERSHIP_INTEREST_FAILURE'
    ],
    data: {
      messageId,
      conversationId,
      messageType,
      updatedObj: {
        status: 'interested'
      }
    }
  })
}

export const markInterestedRecievedApplication = (invite_id, messageType) => ({
  model: 'conversations',
  type: 'MARK_INTERESTED_RECIEVED_SUCCESS',
  data: {
    invite_id,
    messageType
  }
})

export const updatePartnerDetails = (invite_id, data) => {
  return {
    http: {
      url: `/partnership/${invite_id}`,
      method: 'PUT',
      data: {
        optionalNote: data.optionalNote,
        prizingContribution: data.prizingContribution,
        entryContribution: data.entryContribution,
        promoStartDate: data.promotionStartDate,
        promoEndDate: data.promotionEndDate
      }
    },
    types: [
      'UPDATE_PARTNER_DETAILS_REQUEST',
      'UPDATE_PARTNER_DETAILS_SUCCESS',
      'UPDATE_PARTNER_DETAILS_FAILURE'
    ]
  }
}

export const updatePartnerLegal = data => {
  return {
    http: {
      url: '/brands',
      method: 'PUT',
      data
    },
    types: [
      'UPDATE_BRAND_LEGAL_REQUEST',
      'UPDATE_BRAND_LEGAL_SUCCESS',
      'UPDATE_BRAND_LEGAL_FAILURE'
    ]
  }
}

export const toggleInterestedConfirmationModal = (open, invite_attrs) => ({
  type: 'UPDATE_ATTR',
  model: 'interestedConfirmationModal',
  data: {
    open,
    invite_attrs
  }
})

export const toggleInterestedInfoModal = (invite_id, data, open = true) => ({
  type: 'UPDATE_ATTR',
  model: 'interestedInfoModal',
  data: {
    open,
    data,
    invite_id
  }
})

export function updateData(data) {
  return {
    type: PartnershipApplicationConstants.UPDATE_DATA,
    payload: data,
  }
}

export function toggleDatePicker(open) {
  return {
    type: PartnershipApplicationConstants.TOGGLE_DATE_PICKER,
    payload: {
      open,
    },
  }
}

export const reloadConversations = () => ({
  type: 'LOAD_CONVERSATIONS',
  model: 'conversations'
})
