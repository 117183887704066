import React from 'react'
import COLOURS from 'util/colours'
import propTypes from 'prop-types'
import { debounce } from 'lodash'

const inputStyles = {
  borderRadius: '2px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: COLOURS.silver,
  paddingLeft: '12px',
  width: '100%',
  textAlign: 'left',
  fontFamily: 'Larsseit-Light',
  color: COLOURS.ink,
  fontSize: '14px',
  height: '40px',
}

class SmsMarketingInput extends React.Component {
  static propTypes = {
    attr: propTypes.string.isRequired,
    elements: propTypes.object.isRequired,
    updateAttr: propTypes.func.isRequired,
    placeholder: propTypes.string,
  }

  static defaultProps = {
    placeholder: '',
  }

  state = {
    inputText: this.props.elements[this.props.attr],
  }

  updateInputText = debounce(inputText => {
    this.props.updateAttr(this.props.attr, inputText)
  }, 300)

  changeInputText = e => {
    const { value: inputText } = e.target
    this.setState({
      inputText,
    })
    this.updateInputText(inputText)
  }

  render() {
    const { placeholder } = this.props
    const { inputText } = this.state
    return (
      <input
        type="text"
        onChange={this.changeInputText}
        value={inputText}
        style={inputStyles}
        placeholder={placeholder}
      />
    )
  }
}

export default SmsMarketingInput
