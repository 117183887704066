import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Dialog } from 'react-toolbox/lib/dialog'
import COLOURS from 'util/colours'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4, H2 } from 'visual-components/util/texts'

const styles = {
  dialog: {
    width: 792,
    '& section': {
      padding: '0 0 0 0',
    },
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: COLOURS.eggshell,
  },
  image: {
    width: '100%',
  },
  closeIcon: {
    height: '14px',
    width: '14px',
    position: 'absolute',
    top: '17px',
    right: '17px',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Larsseit-Medium',
    fontSize: '20px',
    lineHeight: '20px',
    color: COLOURS.ink,
    marginBottom: 24,
    textAlign: 'center',
  },
  subText: {
    padding: '32px 32px 16px 32px',
    display: 'grid',
    gridTemplateColumns: '160px 1fr',
    gridColumnGap: '32px',
    '& h2': {
      fontFamily: 'Larsseit-Medium, sans-serif',
      lineHeight: '36px',
      color: '#172931', // charcoalDarker
    },
    '& h4': {
      fontFamily: 'Larsseit-Regular, sans-serif',
      lineHeight: '24px',
      color: '#172931', // charcoalDarker
    },
  },
  buttonGroup: {
    margin: '0 32px 32px 32px',
  },
}

function SmsVerifyModal(props) {
  const { confirm, close, classes } = props

  const copy = {
    title: 'Verification Required',
    detail: "Before you can begin text messaging your customers, we'll need to submit your phone number for verification. Please note that texting capabilities will be activated once your number is successfully verified.",
    cta: 'Verify my number',
  }

  return (
    <Dialog
      theme={{ dialog: classes.dialog, title: classes.title }}
      active
      onEscKeyDown={close}
      onOverlayClick={close}
      className={classes.smallShadow}
    >
      <div className={classes.imageContainer}>
        <img src="/images/sms-verify-modal.svg" className={classes.image} />
        <img
          onClick={close}
          src="/images/icons/messaging-icons/close-x-icon-white.svg"
          className={classes.closeIcon}
        />
      </div>
      <div className={classes.subText}>
        <H2>{copy.title}</H2>
        <H4>{copy.detail}</H4>
      </div>
      <div className={classes.buttonGroup}>
        <ModalButtonGroup
          canSave
          confirm={confirm}
          cancel={close}
          confirmText={copy.cta}
          hideLine
          cancel={false}
        />
      </div>
    </Dialog>
  )
}

SmsVerifyModal.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(SmsVerifyModal)
