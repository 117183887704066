import React, { Component } from 'react'
import d3 from 'd3'
import {
  bool,
  string,
  func,
  object,
} from 'prop-types'
import { Checkbox } from 'react-toolbox'
import IndeterminateCheckbox from 'visual-components/util/inputs/indeterminate-checkbox'

import { Collapse } from 'react-collapse'
import { H6 } from 'visual-components/util/texts'
import NestedCheckboxList from 'visual-components/util/filters/nested-checkbox-list'

import CheckboxTheme from 'css/themes/Checkbox.css'

class CheckboxList extends Component {
  static propTypes = {
    selectAllOption: string,
    seeMoreText: string,
    seeLessText: string,
    name: string.isRequired,
    showAll: bool,

    // This indicates that selecting all options will not necessarilly return all results
    limited: bool,
  }

  static defaultProps = {
    selectAllOption: '',
    seeMoreText: '',
    seeLessText: '',
    limited: false,
    showAll: false,
  }

  static contextTypes = {
    handleChangeImmediately: func.isRequired,
    searchModel: object.isRequired,
  }

  state = {
    extraCardOpen: false,
  }

  toggleCardOpen = () => {
    const { extraCardOpen } = this.state

    this.setState({
      extraCardOpen: !extraCardOpen,
    })
  }

  labelGenerator = ({ label, key, doc_count }) => {
    const { name } = this.props
    const { searchModel } = this.context
    const { labelMap, excludeCount } = searchModel[name]

    if (label && typeof label === 'object') {
      return label
    }

    if (labelMap && labelMap[key]) {
      label = labelMap[key]
    } else {
      label = label || key
    }

    if (doc_count !== undefined && !excludeCount) {
      label += ` (${d3.format(',')(doc_count)})`
    }

    return label
  }

  _selectAll = (data, value) => {
    const { key, options } = data
    const newData = { key, value }

    if (options) {
      newData.some = false
      newData.options = options.map(opt => this._selectAll(opt, value))
    }

    return newData
  }

  _select = (data, address = []) => {
    const { limited } = this.props
    let { key, value, options } = data

    if (!address.length) {
      return this._selectAll(data, !value)
    }

    options = options.map(
      (opt, i) => (i === address[0] ? this._select(opt, address.slice(1)) : opt)
    )

    value = !limited && options.every(({ value: val }) => val)

    const some = !value && options.some(({ value: val, some: sm }) => val || sm)

    return {
      key,
      value,
      some,
      options,
    }
  }

  handleSelect = address => () => {
    const { name } = this.props
    const { searchModel, handleChangeImmediately } = this.context

    const data = this._select(searchModel[name], address)

    handleChangeImmediately(name, data)
  }

  mapOptions = opts => {
    const {
      levels = [],
      value,
      some,
      options,
      selectAllOption,
    } = opts

    const selectAll = selectAllOption && (
      <IndeterminateCheckbox
        key={selectAllOption}
        label={selectAllOption}
        checked={value}
        some={some}
        onChange={this.handleSelect(levels)}
        theme={CheckboxTheme}
      />
    )

    const checkboxOptions = options.map((opt, level) => {
      return opt.options
        ? (
          <NestedCheckboxList
            key={opt.key}
            name={opt.key}
            {...this.mapOptions({
              levels: levels.concat([level]),
              selectAllOption: selectAllOption && `All ${this.labelGenerator(opt)}`,
              ...opt,
            })}
          />
        ) : (
          <Checkbox
            key={opt.key}
            label={this.labelGenerator(opt)}
            checked={opt.value}
            onChange={this.handleSelect(levels.concat([level]))}
            theme={CheckboxTheme}
          />
        )
    })

    return {
      selectAll,
      options: checkboxOptions,
    }
  }

  render() {
    const {
      selectAllOption,
      seeLessText,
      seeMoreText,
      name,
      showAll,
    } = this.props
    const { searchModel } = this.context
    const { extraCardOpen } = this.state

    const model = { selectAllOption, ...searchModel[name] }

    const { selectAll, options } = this.mapOptions(model)

    const sliceLength = showAll ? 100 : 5
    const firstFiveCheckBoxOptions = options.slice(0, sliceLength)
    const restOfOptions = options.slice(sliceLength)

    return (
      <div style={{ paddingLeft: '1px', width: '184px' }}>
        {selectAll}
        {firstFiveCheckBoxOptions}
        {
          restOfOptions.length > 0
            ? (
              <Collapse isOpened={extraCardOpen}>
                {restOfOptions}
              </Collapse>
            ) : null
        }
        {
          restOfOptions.length > 0
            ? (
              <div onClick={this.toggleCardOpen} style={{ paddingTop: '16px', cursor: 'pointer' }} >
                <div style={{ display: 'inline-flex', float: 'left' }}>
                  {
                    extraCardOpen
                      ? <H6 azure>{seeLessText}</H6>
                      : <H6 azure>{seeMoreText}</H6>
                  }
                </div>
                <div style={{ display: 'inline-flex', float: 'right' }}>
                  {
                    extraCardOpen
                      ? <img src="/images/icons/blue-collapse-icons/industry-list-collapse-arrow.svg" />
                      : <img src="/images/icons/blue-collapse-icons/industry-list-expand-arrow.svg" />
                  }
                </div>
              </div>
            ) : null
        }
        {
          restOfOptions.length > 0
            ? <br />
            : null
        }
      </div>
    )
  }
}

export default CheckboxList
