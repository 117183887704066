import React, { Component } from 'react'
import {
  string, object, bool, node,
} from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import FontIcon from 'react-toolbox/lib/font_icon'

import { H6, P } from 'visual-components/util/texts'
import styles from 'css/themes/Overview.css'
import navStyles from 'css/themes/HorizontalNav.css'

const {
  navItem,
  navLabelHolder,
  navWithFour,
} = styles
const selectedClass = styles.selected
const { unreadBadge } = navStyles

const navs = [
  {
    url: 'collaborate',
    label: 'Collaborate',
  },
  {
    url: 'analytics',
    label: 'Analytics',
  },
  {
    url: 'content-integration',
    label: 'Content Integration',
  },
  {
    url: 'tracking-links',
    label: 'Tracking Links',
  },
]

function LinkOrEmpty(props) {
  const { to, disabled, children } = props
  return disabled
    ? <React.Fragment>{children}</React.Fragment>
    : <Link to={to}>{children}</Link>
}

LinkOrEmpty.propTypes = {
  to: string.isRequired,
  disabled: bool.isRequired,
  children: node.isRequired,
}

function NavItem(props) {
  const {
    label, isUnread, url, selected, pendingDeclinedOrExpired,
  } = props

  const LabelComponent = selected ? H6 : P

  const disabled = pendingDeclinedOrExpired && label !== 'Collaborate'

  return (
    <div className={navItem}>
      <LinkOrEmpty to={url} disabled={disabled || selected}>
        <div className={cn(navLabelHolder, { [selectedClass]: selected })}>
          <LabelComponent ink={!disabled} cloudGrey={disabled}>
            {label}
            {isUnread && <FontIcon value="lens" className={unreadBadge} />}
          </LabelComponent>
        </div>
      </LinkOrEmpty>
    </div>
  )
}

NavItem.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
  pendingDeclinedOrExpired: bool.isRequired,
  selected: bool.isRequired,
  isUnread: bool.isRequired,
}

class HeaderNav extends Component {
  static propTypes = {
    dealUnreadCount: object.isRequired,
    selected: string.isRequired,
    dealStatus: string.isRequired,
    prefixedUrl: string.isRequired,
  }

  isUnread(url) {
    const { dealUnreadCount } = this.props
    const targets = dealUnreadCount.targets || 0
    const all = dealUnreadCount.all || 0
    switch (url) {
      case 'tracking-links':
        return targets > 0
      case 'collaborate':
        return all - targets > 0
      default:
        return false
    }
  }

  render() {
    const { selected, prefixedUrl, dealStatus } = this.props

    const dealNotActive = ['pending', 'declined', 'expired', 'request'].includes(dealStatus)

    return (
      <div className={navWithFour}>
        {navs.map(({ url, label }) => (
          <NavItem
            key={url}
            url={`${prefixedUrl}/${url}`}
            label={label}
            selected={selected === url}
            pendingDeclinedOrExpired={dealNotActive}
            isUnread={this.isUnread(url)}
          />
        ))}
      </div>
    )
  }
}

export default HeaderNav
