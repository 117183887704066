import React from 'react'
import { Form, Input, Select, Submit } from 'visual-components/util/form'
import { isNum } from 'visual-components/util/form/validations'
import { Card } from 'react-toolbox/lib/card'
import { P, H2 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import AppDispatcher from 'dispatchers/app-dispatcher'

class CustomerSuccessMailchimpDoubleOptin extends React.Component {
  state = {
    open: false,
    brandId: null,
    optInStatus: null,
  }

  handleChange = e => {
    this.setState(e)
  }

  handleSubmit = () => {
    const { brandId, optInStatus } = this.state
    AppDispatcher.http({
      url: `/cs-mailchimp-double-optin/${brandId}`,
      method: 'POST',
      data: {
        optInStatus,
      },
      success: () => {
        toastr.success('Opt-in status updated', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      },
      error: error => {
        toastr.error(error.responseText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      },
    })
  }

  render() {
    const { open } = this.state

    return (
      <Card style={{ padding: '24px' }}>
        <Grid>
          <Row style={{ marginBottom: '16px' }}>
            <div
              onClick={() => this.handleChange({ open: !open })}
              style={{ cursor: 'pointer' }}
            >
              <H2 azure>
                Mailchimp Double Opt-in
                <i
                  className={`fa fa-angle-${open ? 'up' : 'down'}`}
                  style={{ fontSize: '32px', marginLeft: '8px' }}
                />
              </H2>
              <P multiline>
                {"Updates a brands Mailchimp ESP Integration to use double opt-in. Should only be used on successfully integrated integrations."}
              </P>
            </div>
          </Row>
          <Row style={{ display: open ? 'flex' : 'none' }}>
            <Col style={{ width: '364px', marginRight: '32px' }}>
              <Form
                handleSubmit={this.handleSubmit}
                updateModel={this.handleChange}
                dataModel={this.state}
              >
                <Input
                  name="brandId"
                  label="Enter Brand ID"
                  type="number"
                  validations={[isNum]}
                />

                <Select
                  name="optInStatus"
                  label="Opt-in Status"
                  options={[{
                    value: 'true',
                    label: 'Use Double Opt-in',
                  }, {
                    value: 'false',
                    label: 'Do not use Double Opt-in',
                  }]}
                />

                <Submit label="Submit" />
              </Form>
            </Col>
          </Row>
        </Grid>
      </Card>
    )
  }
}

export default CustomerSuccessMailchimpDoubleOptin
