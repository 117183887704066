import React from 'react';
import COLOURS from 'util/colours';

class DocContainer extends React.Component {
  render() {
    return (
      <div style={{
        width: '100%',
        height: '100%',
        background: COLOURS.white,
        fontFamily: 'Helvetica',
        color: COLOURS.black
      }}>
        {this.props.children}
      </div>
    );
  }
}

export default DocContainer;
