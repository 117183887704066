import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { H3 } from 'visual-components/util/texts'

import useTable from 'util/hooks/useTable'
import colors from 'util/colours'

import TableHeader from './TableHeader'
import TableBody from './TableBody'
import TableFooter from './TableFooter'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
  },
  title: {
    padding: 20,
  },
  table: {
    flex: 1,
    // TODO: Delete or not delete...
    '@media only screen and (max-width:360px)': {
      width: '100%',
    },
    borderCollapse: 'collapse',
    borderRadius: 2,
    '& > *:not(tfoot) tr': {
      border: 'none',
      borderBottom: `1px solid ${colors.silver}`,
    },
    '& *': {
      userSelect: 'none',
    },
  },
  column: {
    padding: 24,
    minHeight: 64,
    verticalAlign: 'top',
  },
}

const Table = React.forwardRef((props, ref) => {
  const {
    data,
    cellRenderer,
    config,
    customClass,
    classes: css,
    overlayFn,
  } = props

  const [tableData, tableActions] = useTable({
    data,
    customComponent: config.customComponent,
    tableConfig: config,
  })

  useEffect(() => {
    // Expose filters to outter wrapper
    if (config.filters) config.filters(tableActions.filter)
  }, [])

  if (overlayFn) overlayFn()

  if (!tableData.data.length && !config.emptyState) return null

  return (
    <div className={css.wrapper}>
      {
        config.title && (
          <div className={css.title}>
            <H3 coral>{config.title}</H3>
          </div>
        )
      }
      <table ref={ref} className={cn(css.table, customClass)}>
        <TableHeader
          config={config}
          data={tableData.data}
          sortOptions={{ column: tableData.sortedColumn, order: tableData.order }}
          onSortClick={tableActions.onSort}
        />
        <TableBody
          currentPage={tableData.page}
          data={tableData.data}
          cellRenderer={cellRenderer}
          config={config}
        />
        <TableFooter
          config={config}
          pages={tableData.pages}
          currentPage={tableData.page}
          rowsPerPage={tableData.rows}
          totalCount={tableData.totalItems}
          onNext={tableActions.onNext}
          onPrev={tableActions.onPrev}
          onSetRowsNumber={tableActions.onSetRowsNumber}
        />
      </table>
    </div>
  )
})

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  cellRenderer: PropTypes.func.isRequired,
  overlayFn: PropTypes.func.isRequired,
  config: PropTypes.object,
  classes: PropTypes.object.isRequired,
  customClass: PropTypes.string,
}

Table.defaultProps = {
  config: {},
  customClass: '',
}

export default React.memo(injectCSS(styles)(Table))
