import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { object } from 'prop-types'
import d3 from 'd3'
import moment from 'moment-timezone'

import FullscreenModalWide from 'visual-components/util/modals/FullscreenModalWide'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'
import Theme from 'css/themes/payments/payment-received-full-modal-theme.css'

import BrowserHistory from 'dispatchers/browser-history-dispatcher'
import * as smsActions from 'actions/sms-actions'

const mapStateToProps = ({ sms }) => ({ sms })

const mapDispatchToProps = dispatch => ({
  smsActions: bindActionCreators(smsActions, dispatch),
})
class SMSPurchaseSuccess extends Component {
  static propTypes = {
    sms: object.isRequired,
  }

  componentWillUnmount() {
    const { smsActions } = this.props
    smsActions.resetPurchaseStore()
  }

  close = () => {
    // if it comes from future app, redirect them back to url
    const futureAppRedirectKey = 'future_app_return_url'
    const futureItem = localStorage.getItem(futureAppRedirectKey)
    localStorage.removeItem(futureAppRedirectKey)

    if (futureItem) {
      const returnUrl = JSON.parse(futureItem)
      if (moment().isBefore(moment(returnUrl.expiry))) {
        window.location.href = returnUrl.value
        return
      }
    }
    BrowserHistory.goBack()
  }

  render() {
    const { sms } = this.props

    // Redirect if user clicks back to success view
    if (sms && !sms.purchaseSuccess) BrowserHistory.goBack()

    const { brandSmsBank } = sms

    const count = d3.format(',')(brandSmsBank.currentPlan.credits)
    const autoRenew = brandSmsBank.credits.autorefill

    let mainText = `Thanks for purchasing ${count} Messages for SMS Marketing. You’ll receive a confirmation email shortly.`

    if (autoRenew) {
      mainText = `Thanks for subscribing to an SMS Marketing plan (${count} Credit Auto-Refill).

      You’ll receive a confirmation email shortly.`
    }

    return (
      <div>
        <FullscreenModalWide
          title="Thank You!"
          close={this.close}
        >
          <div className={Theme.mainContainer}>
            <div className={Theme.mainContainer__content}>
              <div className={Theme.mainContainer__content__image}>
                <img
                  src="images/icons/paper-plane-circle-icon.svg"
                  style={{ width: '205px', height: '132px' }}
                />
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>{mainText}</H4>
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>
                  You can review your invoice in your
                  {' '}
                  <Link to="/profile/brand/billing">Billing Settings</Link>
                  .
                </H4>
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>
                  Please contact
                  {' '}
                  <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
                  {' '}
                  if you have any questions about your purchase.
                </H4>
              </div>
            </div>
          </div>
          <div className={Theme.mainContainer__content__buttonGroup}>
            <ModalButtonGroup
              confirm={this.close}
              confirmText="Close"
              canSave
              hideLine
            />
          </div>
        </FullscreenModalWide>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSPurchaseSuccess)
