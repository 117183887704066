import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'

import { H5 } from 'visual-components/util/texts'
import ProfileMainCard from 'visual-components/util/profile-main-card'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'
import PageTitle from 'visual-components/util/PageTitle'
import Stars from 'visual-components/util/stars'

import * as RatingActions from 'actions/ratings-actions'

import Spinner from 'util/components/spinner'

import ReviewsTheme from 'css/themes/ratings-reviews.css'

const subtitle = 'Let your partners know how they perfomed on your previous campaigns and deals.'

const stateToProps = ({ brandRatings, currentBrand }) => ({ brandRatings, currentBrand })

const dispatchToProps = dispatch => ({
  ratingActions: bindActionCreators(RatingActions, dispatch),
})

const BrandReviewCard = props => {
  const {
    ratedbrand_logo,
    ratedbrand_accountname,
    onClick,
    isLast,
  } = props

  return (
    <div style={{ margin: isLast ? '8px 0 0' : '8px 0 16px' }} className={`${ReviewsTheme.flex}`}>
      <div
        className={`${ReviewsTheme.brandImg} ${ReviewsTheme.bigger}`}
        style={{ backgroundImage: `url(${ratedbrand_logo || '/images/dummyimg.svg'})` }}
      />
      <div className={`${ReviewsTheme.flex} ${ReviewsTheme.column} ${ReviewsTheme.center}`}>
        <div style={{ marginBottom: '12px' }}>
          <H5>{ratedbrand_accountname}</H5>
        </div>
        <Stars
          size={24}
          style={{ margin: null }}
          value={0}
          readonly={false}
          onChange={onClick(props)}
        />
      </div>
    </div>
  )
}

const renderPendingReviews = (onClick, { modelInfo, brands }) => {
  const {
    remainingTime,
    endDate,
    label,
    type,
    id,
  } = modelInfo

  const subTitle = (
    <span>
      You have&nbsp;
      <strong style={remainingTime < 6 ? { color: '#ff3c2a' } : {}}>
        {`${remainingTime} days `}
      </strong>
      {`left to submit a public review of your partners in this ${type}.`}
    </span>
  )

  return (
    <div key={id} style={{ marginTop: '16px' }}>
      <ProfileMainCard
        textStyle={{ marginBottom: '16px' }}
        style={{ padding: '16px 20px' }}
        title={label}
        subTitle={subTitle}
        sideTitle={`Ended on ${moment(endDate).format('MMM, DD YYYY')}`}
      >
        { brands.map((brand, idx, arr) => <BrandReviewCard key={brand.rated_brand_id} onClick={onClick} {...brand} isLast={idx === arr.length - 1} />) }
      </ProfileMainCard>
    </div>
  )
}

const parseCampaigns = pendingReviews => {
  const reviews = pendingReviews.map(campaignReview => {
    const [campaignObj, brands] = campaignReview
    const {
      campaign_name: label,
      campaign_id: id,
      model_end_date: endDate,
      review_created_date,
    } = JSON.parse(campaignObj)

    const obj = {
      modelInfo: {
        id,
        label,
        endDate,
        remainingTime: 90 - moment().diff(review_created_date, 'days'), // 90 days only for campaigns
        type: 'campaign',
      },
      brands,
    }

    return obj
  })

  return reviews
}

const parseMediaDeals = pendingReviews => {
  const reviews = pendingReviews.map(mediadealReview => {
    const {
      id,
      deal_name: label,
      model_end_date: endDate,
      review_created_date,
    } = mediadealReview

    const obj = {
      modelInfo: {
        id,
        label,
        endDate,
        remainingTime: 21 - moment().diff(review_created_date, 'days'), // 21 days only for deals
        type: 'deal',
      },
      brands: [mediadealReview],
    }

    return obj
  })

  return reviews
}

const existsInPendingReviews = (rateId, reviews = []) => {
  for (let i = 0; i < reviews.length; i++) {
    const reviewItem = reviews[i]

    if (Array.isArray(reviewItem)) {
      const [, camp_reviews] = reviewItem

      for (let j = 0; j < camp_reviews.length; j++) {
        if (camp_reviews[j].id === rateId) {
          return camp_reviews[j]
        }
      }
    } else if (reviewItem.id === rateId) {
      return reviewItem
    }
  }

  return null
}

class BrandReviewsPending extends PureComponent {
  static propTypes = {
    ratingActions: PropTypes.object.isRequired,
    brandRatings: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { ratingActions } = this.props

    ratingActions.getPendingReviews('marketplace')
    ratingActions.getPendingReviews('campaign')
  }

  componentDidUpdate() {
    const { history, brandRatings, currentBrand } = this.props

    if (brandRatings.loading || currentBrand.loading) return

    const pathRegex = new RegExp(/brandId=(\d+)&rate_value=(\d{1})&rating_id=(\d+)/)

    const { search } = history.location

    const result = pathRegex.exec(search)

    if (result) {
      // Clear query, you don't want that in the address bar
      history.push(history.location.pathname)

      const [,, rate_value, rating_id] = result

      const brandToReview = existsInPendingReviews(
        +rating_id,
        [
          ...brandRatings.pendingMarketDealReviews,
          ...brandRatings.pendingCampaignReviews,
        ]
      )

      if (rate_value && rating_id && brandToReview) this.onBrandClick(brandToReview)(rate_value, true)
    }
  }

  onBrandClick = brandToRate => (rating = 0, isFromEmail = false) => {
    const { ratingActions } = this.props

    // This allows modal to load properly when coming from the email to review the brand
    setTimeout(() => {
      ratingActions.openReviewModal({ ...brandToRate, rating })
    }, isFromEmail ? 500 : 1)
  }

  renderReviews = () => {
    const {
      brandRatings: {
        pendingCampaignReviews,
        pendingMarketDealReviews,
      },
    } = this.props

    const components = [].concat(
      parseCampaigns(pendingCampaignReviews),
      parseMediaDeals(pendingMarketDealReviews)
    )
      .sort((a, b) => moment(b.modelInfo.endDate).diff(a.modelInfo.endDate))
      .map(review => renderPendingReviews(this.onBrandClick, review))

    return components
  }

  render() {
    const { brandRatings, currentBrand } = this.props

    if (brandRatings.loading || currentBrand.loading) return <Spinner />

    const hasPendingReviews = brandRatings.pendingCampaignReviews.length > 0
    || brandRatings.pendingMarketDealReviews.length > 0

    return (
      <div className={`${ReviewsTheme.flex} ${ReviewsTheme.column} ${ReviewsTheme.container}`}>
        <PageTitle title="Pending Reviews" subtitle={subtitle} />
        {
          hasPendingReviews
            ? this.renderReviews()
            : <EmptyBonzai description="You don't have any pending reviews." />
        }
      </div>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(BrandReviewsPending))
