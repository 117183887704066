import React, { PureComponent } from 'react'
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'

const styles = {
  editableFormHover: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    textAlign: 'center',
    zIndex: 2,
    opacity: 0,
    background: COLOURS.wyswygBlueHover,
    transition: 'opacity .1s',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 1,
    },
  },
  formHoverClickToEdit: {
    color: COLOURS.white,
    letterSpacing: '2px',
    lineHeight: '10px',
    fontSize: '10px',
    fontFamily: 'Larsseit-Medium',
  },
}

class EditOverlay extends PureComponent {
  static propTypes = {
    classes: propTypes.object.isRequired,
    label: propTypes.string,
    onClick: propTypes.func.isRequired,
    icon: propTypes.string,
  }

  static defaultProps = {
    label: 'CLICK TO EDIT',
    icon: 'fa-pencil',
  }

  render() {
    const {
      classes: css,
      onClick,
      label,
      icon,
    } = this.props

    return (
      <div
        className={css.editableFormHover}
        onClick={onClick}
      >
        <div className={css.formHoverClickToEdit}>
          {
            icon
            && (
              <i
                className={`fa ${icon}`}
                aria-hidden="true"
              />
            )
          }
          {label}
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(EditOverlay)
