import d3 from 'd3'

// eslint-disable-next-line import/prefer-default-export
export const makeNumbersPretty = (data, showZero) => {
  let displayedNumber
  const temp = parseInt(data, 10)

  if (temp && temp > 0) {
    if (temp <= 9999) {
      displayedNumber = d3.format(',')(temp)
    } else if (temp >= 1000000 && temp < 1100000) {
      displayedNumber = d3.format('.1s')(temp)
    } else if (temp >= 1100000 && temp < 100000000) {
      displayedNumber = d3.format('.2s')(temp)
    } else {
      displayedNumber = d3.format('.3s')(temp).replace('G', 'B').replace('k', 'K')
    }
  } else if (showZero === 'withDecimal') {
    displayedNumber = d3.format(',.2f')(data)
  } else {
    displayedNumber = showZero ? 0 : '-'
  }

  return displayedNumber
}
