import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import COLOURS from 'util/colours';

import ProfileSideCard from 'visual-components/util/profile-side-card';
import BlueTag from 'visual-components/util/tags/blue-tag';

class CampaignDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      startDate,
      endDate,
      description,
      industry,
      noPartnerLimit,
      numPartners
    } = this.props;

    return(
      <div>
        <ProfileSideCard title={ 'Details' }>
          <div>
            {
              moment(startDate).isValid() ?
              <div style={{
                maxWidth: '34px',
                marginTop: '2px',
                marginRight: '12px',
                float: 'left',
                textAlign: 'center'
              }}>
                <p style={{
                  fontFamily: 'Larsseit',
                  fontSize: '16px',
                  lineHeight: '21px',
                  textAlign: 'center',
                  color: COLOURS.ink,
                  marginBottom: '5px'
                }}>
                  { moment(startDate).format('MMM').toUpperCase() }
                </p>
                <p style={{
                  fontFamily: 'Larsseit-Bold',
                  fontSize: '34px',
                  lineHeight: '30px',
                  color: COLOURS.seaGreen
                }}>
                  { moment(startDate).format('D') }
                </p>
              </div>
              : null
            }
            <div>
              <p style={{
                minHeight: '56px',
                fontFamily: 'Larsseit-Light',
                fontSize: '12px',
                lineHeight: '20px',
                color: COLOURS.ink
              }}>
                { description }
              </p>
            </div>
            <div style={{
              marginTop: '40px',
              marginBottom: '12px'
            }}>
              {
                new Date(endDate) < new Date() ?
                <p style={{
                  fontFamily: 'Larsseit-Medium',
                  fontSize: '12px',
                  color: COLOURS.alertRed
                }}>
                  This campaign is now closed.
                </p>
                :
                  !noPartnerLimit && numPartners >= 8 ?
                    <p style={{
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '12px',
                      color: COLOURS.alertRed
                    }}>
                      This campaign has reached the 8 partner limit and is now closed for applications.
                    </p>
                  : null
              }
            </div>
            <div>
              <img src="/images/icons/tag-icon.svg"
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '8px'
              }} />
              <BlueTag
                tag={ industry }
              />
            </div>
          </div>
        </ProfileSideCard>
      </div>
    )
  }
}

CampaignDetails.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired
};

export default CampaignDetails;
