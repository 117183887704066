import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'

import { H4 } from 'visual-components/util/texts'

const BrandTabBadge = ({ count }) => {
  if (!count) return null

  return (
    <div
      style={{
        display: 'inline-block',
        background: COLOURS.seaGreen,
        fontFamily: 'Larsseit-Bold',
        height: '24px',
        width: '24px',
        borderRadius: '100%',
        color: 'white',
        fontSize: '12px',
        lineHeight: '26px',
        marginLeft: '-14px',
        textAlign: 'center',
        marginRight: '24px',
      }}
    >
      { count }
    </div>
  )
}

class BrandTab extends PureComponent {
  render() {
    const {
      tab,
      active,
    } = this.props

    return (
      <div style={{ display: 'inline-block' }}>
        <div style={{ marginTop: '6px', marginRight: '20px', display: 'inline-block' }}>
          <Link to={tab.href}>
            <H4 azure={active}>
              {
                active
                  ? <b>{tab.title}</b>
                  : tab.title
              }
            </H4>
          </Link>
        </div>
        <BrandTabBadge count={tab.count} />
      </div>
    )
  }
}

BrandTab.propTypes = {
  tab: PropTypes.object.isRequired,
  active: PropTypes.bool,
}

BrandTab.defaultProps = {
  active: false,
}

export default BrandTab
