import React, { Component } from 'react'
import { Button } from 'react-toolbox'
import _ from 'lodash'

import ButtonTheme from 'css/themes/Buttons.css'
import theme from 'css/themes/referrals/social-buttons.css'

const headline = 'Grow your Audience with Partnerships!'
const description = 'Try it free and join the thousands of brands using DojoMojo'
const openWindow = (url, params) => {
  const queryString = _.map(params, (value, key) => `${key}=${encodeURIComponent(value)}`).join('&')
  const fullUrl = `${url}?${queryString}`
  window.open(fullUrl, null, "width=600, height=400, location=0, menubar=0, resizeable=0, scrollbars=0, status=0, titlebar=0, toolbar=0")
}

export default class SocialButtons extends Component {
  handleFacebook = () => {
    openWindow('https://www.facebook.com/sharer/sharer.php', {
      u: `${this.props.inviteUrl}-03`,
      title: headline,
      description: description
    })
  }

  handleTwitter = () => {
    openWindow('https://twitter.com/share', {
      url: `${this.props.inviteUrl}-04`,
      text: headline
    })
  }

  handleLinkedIn = () => {
    openWindow('https://www.linkedin.com/shareArticle', {
      url: `${this.props.inviteUrl}-05`,
      title: headline,
      summary: description,
      mini: true
    })
  }

  render() {
    return (
      <div className={theme.container}>
        <Button
          primary
          onClick={this.handleFacebook}
          theme={ButtonTheme}
          className={`${theme.facebookButton} ${theme.socialButton}`}
          raised
          icon={
            <div className={theme.buttonInner}>
              Facebook <i className={`${theme.icon} fa fa-facebook-square`} />
            </div>
          }
        />

        <Button
          primary
          onClick={this.handleTwitter}
          theme={ButtonTheme}
          className={`${theme.twitterButton} ${theme.socialButton}`}
          raised
          icon={
            <div className={theme.buttonInner}>
              Twitter <i className={`${theme.icon} fa fa-twitter-square`} />
            </div>
          }
        />
        <Button
          primary
          onClick={this.handleLinkedIn}
          theme={ButtonTheme}
          className={`${theme.linkedInButton} ${theme.socialButton}`}
          raised
          icon={
            <div className={theme.buttonInner}>
              LinkedIn <i className={`${theme.icon} fa fa-linkedin-square`} />
            </div>
          }
        />
      </div>
    );
  }
}
