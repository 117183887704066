import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import Navigation from 'react-toolbox/lib/navigation'
import { Link } from 'react-toolbox/lib/link'
import FontIcon from 'react-toolbox/lib/font_icon'
import { Collapse } from 'react-collapse'
import { H4 } from 'visual-components/util/texts'
import * as metrics from 'util/metrics'

import MessagingSwitchBrand from 'visual-components/messaging/messaging-switch-brand'

import _ from 'lodash'

import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher'

import HorizontalNavTheme from 'css/themes/HorizontalNav.css'

const unreadIcon = <FontIcon value="lens" className={HorizontalNavTheme.unreadBadge} />

class SideNav extends Component {
  state = {
    isOpened: {},
  }

  componentDidMount() {
    const { routes } = this.props

    this.setOpenAccordions(routes)
  }

  componentDidUpdate(prevProps) {
    const { routes, location } = this.props
    const routesChanged = JSON.stringify(routes) !== JSON.stringify(prevProps.routes)
    const urlChanged = location.pathname !== prevProps.location.pathname

    if (routesChanged || urlChanged) {
      this.setOpenAccordions(routes)
    }
  }

  setOpenAccordions(routes) {
    const { match, prefix } = this.props

    const isOpened = routes
      .filter(route => route.subs)
      .reduce((all, { label, subs }) => {
        //open all dropdowns by default
        all[label] = true

        //if dropdowns are not opened by default then
        //open the dropdown of the currently selected link
        const subSelected = subs.find(sub => `${prefix || ''}${sub.to}` === match.url)

        if (subSelected) {
          all[label] = true
        }
        return all
      }, {})

    this.setState({ isOpened })
  }

  // generates an onClick for each link
  generateOnClick = (to, priceCheck) =>
    to
    && 'pushState' in window.history
    && (e => {
      e.preventDefault()

      const { history } = this.props

      if (!priceCheck) {
        history.push(to)
      } else {
        PricingDispatcher.check(priceCheck, () => history.push(to))
      }

      if (to === '/search/sweepstakes') {
        metrics.create('exploreRecommendedClickedSideBarCampaign')
      } else if (to === '/search/brands') {
        metrics.create('exploreRecommendedClickedSideBarBrands')
      } else if (to === '/search/opportunities') {
        metrics.create('exploreRecommendedClickedSideBarOpportunities')
      } else if (to.split('/')[2] === 'media-market') {
        metrics.create('exploreRecommendedClickedSideBarMediaMarket', {
          meta: {
            type: to.split('/').pop(),
          },
        })
      }
    })

  toggleSubNav(label) {
    this.setState(prevState => {
      const isOpened = { ...prevState.isOpened }
      isOpened[label] = !isOpened[label]
      return { isOpened }
    })
  }

  // decorate routes array passed to props
  mapRoutes() {
    let {
      routes,
      history,
      prefix,
      location: { pathname },
    } = this.props
    const { isOpened } = this.state

    routes = _.cloneDeep(routes)

    // apply prefix if present
    routes.forEach(route => (route.to = `${prefix || ''}${route.to}`))

    // apply handlers
    return routes.map(({
      label, to, subs, overrideHref, target, exact, priceCheck, open,
    }) => {
      const isBeta = [].includes(label)
      const isNew = ['SMS Marketing'].includes(label)

      if (subs) {
        // apply prefix if present
        subs.forEach(sub => {
          sub.to = `${prefix || ''}${sub.to}`
        })
        const subItems = subs.map(({
          label, to, overrideHref, target, exact, isUnread,
        }) => (
          <Link
            label={label}
            key={label}
            href={overrideHref || history.createHref({ pathname: to })}
            target={target || '_self'}
            onClick={!overrideHref && this.generateOnClick(to, priceCheck)}
            active={pathname.startsWith(to) && (!exact || pathname === to)}
            theme={HorizontalNavTheme}
            className={HorizontalNavTheme[label]}
          >
            {isUnread && unreadIcon}
          </Link>
        ))

        return (
          <div key={label}>
            <div onClick={() => this.toggleSubNav(label)} style={{ cursor: 'pointer' }}>
              <div style={{ display: 'inline-flex', verticalAlign: 'top', paddingBottom: '20px' }}>
                <H4>{label}</H4>
              </div>
              <div style={{ display: 'inline-flex', marginTop: '-6px' }}>
                {
                  isOpened[label]
                    ? <img src="/images/icons/collapse-arrow.svg" />
                    : <img src="/images/icons/expand-arrow.svg" />
                }
                {
                  isNew || isBeta ? (
                    <img
                      src={`images/icons/${isNew ? 'new' : 'beta'}@3x.png`}
                      style={{
                        width: '29px',
                        height: '16px',
                        marginTop: '3px',
                        marginLeft: '8px',
                      }}
                    />
                  ) : null
                }
              </div>
            </div>
            <Collapse isOpened={!!isOpened[label]}>
              <div style={{ marginLeft: '16px' }}>
                { subItems }
              </div>
            </Collapse>
          </div>
        )
      }

      return (
        <Link
          label={label}
          key={label}
          href={overrideHref || history.createHref({ pathname: to })}
          target={target || '_self'}
          onClick={!overrideHref && this.generateOnClick(to, priceCheck)}
          active={pathname.startsWith(to) && (!exact || pathname === to)}
          theme={HorizontalNavTheme}
          className={isNew || isBeta ? HorizontalNavTheme.hasIcon : ''}
          icon={
            isNew || isBeta ? (
              <img
                src={`images/icons/${isNew ? 'new' : 'beta'}@3x.png`}
                style={{ width: '29px', height: '16px' }}
              />
            ) : null
          }
        />
      )
    })
  }

  render() {
    const { width, history, switchBrands } = this.props
    const routes = this.mapRoutes()

    return (
      <div style={{ width }}>
        { switchBrands ? <MessagingSwitchBrand history={history} /> : null }
        <Navigation
          type="vertical"
          theme={HorizontalNavTheme}
          className={HorizontalNavTheme.sideNav}
        >
          { routes }
        </Navigation>
      </div>
    )
  }
}

SideNav.propTypes = {
  routes: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,

  /* react-router-dom props */
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default SideNav
