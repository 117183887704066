import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TasksList from 'visual-components/tasks/TaskList'
import * as CampaignTaskActions from 'actions/campaign-task-actions'

const mapStateToProps = (state) => ({
  campaignTasks: state.campaignTasks,
  currentCampaign: state.currentCampaign,
  currentBrand: state.currentBrand
})
const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
}

class TasksListContainer extends React.Component {
  render() {
    const {
      campaignTasks: {
        acceptedInvites
      },
      currentCampaign,
      currentBrand,
      campaignTaskActions: {
        currentTaskOpenModal,
        markComplete
      }
    } = this.props

    let currentBrandInvite = currentCampaign.invites.find(invite => {
      return invite.invitee_id === currentBrand.id
    })

    const isHost = currentCampaign.isHost

    return (
      <TasksList
        tasks={acceptedInvites.filter(invite => {
          return !invite.hideTasks
        })}
        markComplete={markComplete}
        isHost={ isHost }
        brandId={ currentBrand.id }
        newTask={() => {
          currentTaskOpenModal({
            isHost: isHost,
            campaign_id: currentCampaign.id,
            assignee_id: isHost ? '' : currentBrand.id,
            invite_id: isHost ? '' : currentBrandInvite.id
          })
        }}
        />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksListContainer)
