import React, { PureComponent as Component } from 'react'
import _ from 'lodash'

import SocialTooltipCard from 'visual-components/analyticsDashboard/tooltip/social-tooltip-card'

import { renderToString } from 'react-dom/server'

const TooltipFactory = React.createFactory(SocialTooltipCard)

/*

>= 18

  20 data points

  20 / 6 = 3 Remainder 2

  offset 2 (label on second) every third has labels

  30 data points

  30 / 6 = 5 remainder 0

  0 offset, every 5th has label


< 18

  15 points

  16 / 3 = 5 remainder 1

  offset 1 (label)

  15 / 5 = every 3rds has label

  5 points

  5 / 3 = 1 remainder 2

  offset 2 (label on second) every 3rd has label (2 labels total)

  4 pts

  4/3 = 1 remainder 1

  ofsett 1 (label on first) every 3rd has label

*/

const renderGraph = data => {
  $('.social-bars').html('')

  const mappedData = {}

  const categories = data.categories || []

  const periodMap = {}

  _.each(data.categories, (category, idx) => {
    periodMap[category] = idx === data.categories.length - 1
      ? category
      : `${category} - ${data.categories[idx + 1]}`

    mappedData[category] = data.socialItems.map(item => ({
      series: item.name,
      y: item.data[idx],
    }))
  })

  _.each(data.socialItems, item => {
    item.selected = !data.hideMap[item.name] && item.name !== 'linkShare'
  })

  const socialItems = _.filter(data.socialItems, { selected: true })

  const graph = new Contour({
    el: '.social-bars',
    xAxis: {
      categories: data.categories,
      maxTicks: 7,
    },
    chart: {
      padding: {
        left: 33,
        bottom: 40,
      },
    },
    tooltip: {
      opacity: 1,
      formatter(d) {
        const str = renderToString(TooltipFactory({
          date: periodMap[d.x],
          data: mappedData[d.x],
        }))

        return str
      },
    },
  })
    .cartesian()
    .yAxisLines()
    .column(socialItems.reverse(), { stacked: true })
    .tooltip()
    .assignColors(socialItems, {
      colorMap: data.colorMap,
    })
    .dateBasedXAxis(null, {
      n: categories.length,
      formattedDates: categories,
    })
    .render()
  return graph
}

class SocialBars extends Component {
  componentDidMount() {
    renderGraph(this.props.data)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data) {
      renderGraph(this.props.data)
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            display: 'inline-block',
            width: '590px',
            height: '300px',
            marginBottom: '-20px',
          }}
          className="social-bars"
        />
      </div>
    )
  }
}

export default SocialBars
