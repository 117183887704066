import React, { Component } from 'react';
import _ from 'lodash'

'use strict';

import ReactDOM from 'react-dom';

import Label from 'util/components/label';
import Text from 'util/components/text';
import COLOURS from 'util/colours';
import Checkbox from 'util/components/form-elements/checkbox';

var clearTime = function clearTime(date) {
  var daySec = 86400000,
      time;

  time = parseInt(date.getTime() / daySec) * daySec + daySec;

  var result = new Date();
  result.setTime(time);
  return result;
};

class FormGroup extends Component {
  state = {};

  resetError = (e) => {
    this.props.model.errors[this.props.attrName] = [];
    this.forceUpdate();
  };

  validate = (callback) => {
    var that = this;
    if (!this.props.model.validateAttr) {
      callback([]);
      return;
    }
    this.props.model.validateAttr(this.props.attrName, this.state.value, function () {
      that.forceUpdate();
      that.props.updateAttr('errors', that.props.model.errors)

      callback(that.props.model.errors[that.props.attrName]);
    });
  };

  updateNumber = (e) => {
    var value = $(e.currentTarget).val();
    if (this.props.type === 'number') {
      value = parseFloat(value.replace(/[^0-9\.]/g, ''));
    }
    if (isNaN(value)) {
      value = 0;
    }

    this.state.value = value;

    if (value != '') {
      this.state.changedValue = true
    } else {
      this.state.changedValue = false
    }
    this.forceUpdate();

    this.validate(function (errors) {
      if (!errors.length) {
        this.props.updateAttr(this.props.attrName, value);
        $(e.currentTarget).val(d3.format(',')(value));
      }
    }.bind(this));
  };

  updateValue = (e) => {
    var value = $(e.currentTarget).val();
    this.processValue(value);
  };

  processValue = (value) => {
    if (this.props.type === 'number' || this.props.type === 'incrementor') {
      value = parseFloat(value.replace(/[^0-9\.]/g, ''));
    }

    this.state.value = value;

    if (value != '') {
      this.state.changedValue = true
    } else {
      this.state.changedValue = false
    }
    this.forceUpdate();

    this.validate(function (errors) {
      if (!errors.length) {
        this.props.updateAttr(this.props.attrName, value);
      }
    }.bind(this));
  };

  showActual = (e) => {
    var value = $(e.currentTarget).val();
    value = parseFloat(value.replace(/,/g, ''));
    $(e.currentTarget).val(value);
  };

  updateValueTemp = (e) => {
    this.props.model[this.props.attrName] = $(e.currentTarget).val();
    this.forceUpdate();
  };

  updateValueTempDebounce = (e) => {
    this.props.model[this.props.attrName] = $(e.currentTarget).val();
    this.forceUpdate();
    this.processValueDebounce($(e.currentTarget).val());
  };

  incrementValue = () => {
    this.props.model[this.props.attrName]++;
    this.forceUpdate();
    this.processValueDebounce(this.props.model[this.props.attrName]);
  };

  decrementValue = () => {
    this.props.model[this.props.attrName]--;
    this.forceUpdate();
    this.processValueDebounce(this.props.model[this.props.attrName]);
  };

  toggleChecked = (e) => {
    this.props.updateAttr(this.props.attrName, !this.props.model[this.props.attrName]);
  };

  componentDidMount() {
    var node = ReactDOM.findDOMNode(this);
    this.processValueDebounce = _.debounce(this.processValue, 1000);
    $(node).find('[data-toggle="tooltip"]').tooltip();
    if (this.props.initialValidate) {
      this.state.value = this.props.model[this.props.attrName];
      var that = this;
      this.validate(function () {
        // that.props.updateAttr(that.props.attrName, that.state.value);
      });
    }
  }

  render() {

    var inputStyle = {
      borderRadius: '1px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: COLOURS.silver,
      paddingTop: '4px',
      paddingLeft: '14px',
      paddingBottom: '4px',
      paddingRight: '4px',
      width: '100%',
      backgroundColor: 'transparent',
      color: COLOURS.ink,
      fontFamily: 'Larsseit-Light',
      fontSize: '14px',
      outline: 'none',
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
      height: this.props.withIcon ? null : '50px'
    }

    var model = this.props.model || {};
    var attrName = this.props.attrName;
    var tooltip;
    if (this.props.tooltip) {
      tooltip = (
        <i className="fa fa-question-circle"></i>
      )
    }
    var editable = this.props.editable === 'undefined' || this.props.editable;
    this.props.model.errors = this.props.model.errors || {};
    var errors = this.props.model.errors;
    errors = errors[this.props.attrName];
    errors = _.map(errors, function (text, idx) {
      return (
        <span key={idx} className="help-block">{text}</span>
      );
    });
    var value = this.props.model[this.props.attrName];
    var type = this.props.type || 'text';

    var className = 'form-group ';
    className += errors.length ? 'has-error' : '' ;
    var disabled = (this.props.disabled || this.props.editable === false) ? 'disabled' : '';

    if (type === 'select') {

      var options = _.map(this.props.options, function (opt, idx) {
        var val;
        var label;
        if (typeof opt === 'string') {
          val = label = opt;
        } else {
          val = opt.value;
          label = opt.label;
        }
        return (
          <option key={idx} value={val}>
            {label}
          </option>
        );
      });

      return (
        <div style={{}} className={className}>
          <Label
            {...this.props} />
          <select
            disabled={this.props.disabled}
            style={inputStyle}
            value={value}
            onChange={this.updateValue}
            id={attrName}
            name={attrName}
            className="form-control form-group-select borderAzureFocus"
          >
            {options}
          </select>
          <div style={{
            position: 'absolute',
            right: '15px'
          }}>
            <div style={{
              content: '',
              top: '0px',
              marginTop: '-29px',
              marginRight: '1px',
              width: '0',
              height: '0',
              borderLeftWidth: '8px',
              borderLeftStyle: 'solid',
              borderLeftColor: 'transparent',
              borderRightWidth: '8px',
              borderRightStyle: 'solid',
              borderRightColor: 'transparent',
              borderTopWidth: '8px',
              borderTopStyle: 'solid',
              borderTopColor: COLOURS.ink,
              position: 'absolute',
              pointerEvents: 'none',
              right: '15px'
            }} />
            <div style={{
              content: '',
              top: '0px',
              marginTop: '-29px',
              marginRight: '1px',
              width: '0',
              height: '0',
              borderLeftWidth: '6px',
              borderLeftStyle: 'solid',
              borderLeftColor: 'transparent',
              borderRightWidth: '6px',
              borderRightStyle: 'solid',
              borderRightColor: 'transparent',
              borderTopWidth: '6px',
              borderTopStyle: 'solid',
              borderTopColor: COLOURS.white,
              position: 'absolute',
              pointerEvents: 'none',
              right: '17px'
            }} />
          </div>
          {errors}
        </div>
      );
    }

    if (type === 'select2') {
      var options = _.map(this.props.options, function (opt, idx) {
        var val;
        var label;
        if (typeof opt === 'string') {
          val = label = opt;
        } else {
          val = opt.value;
          label = opt.label;
        }
        return (
          <option key={idx} value={val}>
            {label}
          </option>
        );
      });

      var otherStyles = {
        color: this.state.changedValue ? COLOURS.textGrey : COLOURS.inputBorder
      }

      let combinedStyles = Object.assign({}, inputStyle, this.props.inputStyle, otherStyles)

      return (
        <div
          className={className}
          style={{marginTop: '16px'}}
        >
          <select
            disabled={this.props.disabled}
            style={combinedStyles}
            value={value}
            onChange={this.updateValue}
            id={attrName}
            name={attrName}
            className="form-control form-group-select borderAzureFocus"
            placeholder={this.props.placeholder}
          >
            { this.props.placeholder ?
              <option value="" disabled selected>
                { this.props.placeholder }
              </option>
              : null
            }
            {options}
            }
          </select>
          <div style={{
            content: '',
            top: '0px',
            marginTop: '34px',
            width: '0',
            height: '0',
            borderLeftWidth: '8px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'transparent',
            borderRightWidth: '8px',
            borderRightStyle: 'solid',
            borderRightColor: 'transparent',
            borderTopWidth: '8px',
            borderTopStyle: 'solid',
            borderTopColor: this.state.changedValue ? COLOURS.ink : COLOURS.ink,
            position: 'absolute',
            pointerEvents: 'none',
            right: '25px'
          }} />
          <div style={{
            content: '',
            top: '0px',
            marginTop: '34px',
            width: '0',
            height: '0',
            borderLeftWidth: '6px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'transparent',
            borderRightWidth: '6px',
            borderRightStyle: 'solid',
            borderRightColor: 'transparent',
            borderTopWidth: '6px',
            borderTopStyle: 'solid',
            borderTopColor: COLOURS.white,
            position: 'absolute',
            pointerEvents: 'none',
            right: '27px'
          }} />
          {errors}
        </div>
      );
    }

    if (type === 'checkbox') {
      return (
        <Checkbox
          { ...this.props }
          toggleChecked={ this.toggleChecked }
          value={ value } />
      )
    }

    if (type === 'incrementor') {
      type='number';
      var options = [];
      var formatter = this.props.formatter || function (x) { return x;}

      if (this.props.placeholder) {
        options.push((
          <option value="-1" key="null" disabled defaultValue>
            {this.props.placeholder}
          </option>
        ))
      }
      for (var i = this.props.min; i < this.props.max; i += this.props.step) {
        options.push((
          <option key={i} value={i}>
            {formatter(i)}
          </option>
        ))
      }

      var moreStyles = {
        color: this.state.changedValue ? COLOURS.textGrey : COLOURS.inputBorder,
        ':hover': {
          cursor: 'pointer'
        },
      }

      let combinedStyles = Object.assign({}, inputStyle, this.props.inputStyle, moreStyles, this.props.withIcon ? inputWithIcon : {})

      return (
        <div
          data-toggle="tooltip"
          data-trigger="hover"
          key={this.props.attrName}
          data-placement="bottom"
          title={this.props.tooltip}
          className={className}
          style={{marginTop: '16px'}}>
          { this.props.label ? <Label {...this.props} model={this.props.model} attrName={this.props.attrName} /> : null }
          {this.defaultValue}
          <select
            placeholder={this.props.placeholder}
            value={value ? value : -1}
            onChange={this.updateValue}
            style={combinedStyles}
            id={attrName}
            name={attrName}
            className="form-control form-group-select borderAzureFocus">
            {options}
          </select>
          <div style={{
            content: '',
            top: '0px',
            marginTop: '34px',
            width: '0',
            height: '0',
            borderLeftWidth: '8px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'transparent',
            borderRightWidth: '8px',
            borderRightStyle: 'solid',
            borderRightColor: 'transparent',
            borderTopWidth: '8px',
            borderTopStyle: 'solid',
            borderTopColor: this.state.changedValue ? COLOURS.ink : COLOURS.ink,
            position: 'absolute',
            pointerEvents: 'none',
            right: '25px'
          }} />
          <div style={{
            content: '',
            top: '0px',
            marginTop: '34px',
            width: '0',
            height: '0',
            borderLeftWidth: '6px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'transparent',
            borderRightWidth: '6px',
            borderRightStyle: 'solid',
            borderRightColor: 'transparent',
            borderTopWidth: '6px',
            borderTopStyle: 'solid',
            borderTopColor: COLOURS.white,
            position: 'absolute',
            pointerEvents: 'none',
            right: '27px'
          }} />
          {errors}
        </div>
      );
    }

    if (type === 'number') {
      type = 'text';
      value = d3.format(',')(value);

      return (
        <div
          className={className}>
          <Label
            data-toggle="tooltip"
            data-trigger="hover"
            data-placement="top"
            title={this.props.tooltip}
            label={this.props.label}
            model={this.props.model}
            attrName={this.props.attrName} />
          <input
            style={inputStyle}
            min={this.props.min}
            max={this.props.max}
            onBlur={this.updateNumber}
            disabled={disabled}
            defaultValue={value}
            ref={this.props.attrName}
            onFocus={this.showActual}
            type={type}
            className="form-control borderAzureFocus"
            name={this.props.attrName}
            id={this.props.attrName} />
          {errors}
        </div>
      );
    }

    if (type === 'range') {
      const signStyle = {
        position: 'absolute',
        bottom: '10px',
        color: COLOURS.ink,
        fontSize: '20px',
        fontFamily: 'Larsseit-Light',
        ':hover': {
          cursor: 'pointer'
        }
      }

      const sliderLabelStyle = {
        fontSize: '11px',
        fontFamily: 'Larsseit-Light',
        position: 'absolute',
        bottom: '35px',
        color: COLOURS.ink,
      }

      const leftStyle = {
        left: '12px'
      }

      const rightStyle = {
        right: '12px'
      }

      const defaultDivStyle = {
        position: 'relative',
        paddingBottom: '10px',
        marginBottom: '10px'
      }

      let combinedStyles = Object.assign({}, defaultDivStyle, this.props.style)
      let combinedLeftStyles = Object.assign({}, signStyle, leftStyle)
      let combinedRightStyles = Object.assign({}, signStyle, rightStyle)
      let combinedLeftLabelStyles = Object.assign({}, sliderLabelStyle, leftStyle)
      let combinedRightLabelStyles = Object.assign({}, sliderLabelStyle, rightStyle)

      return (
        <div className={className} key={this.props.attrName} style={combinedStyles}>
          { this.props.label ?
            <label htmlFor={this.props.attrName}>
              <Text.dojoP styles={{textTransform: 'uppercase', marginLeft:'12px'}}>
                {this.props.label}
              </Text.dojoP>
            </label>
            : null
          }
          { this.props.showButton ?
            <span key="left" onClick={this.decrementValue} style={combinedLeftStyles}>
            { this.props.leftShowButton ? this.props.leftShowButton : <i className='fa fa-minus' aria-hidden='true' /> }
            </span>
            : null
          }
          { this.props.showButton ?
            <span key="right" onClick={this.incrementValue} style={combinedRightStyles}>
              { this.props.rightShowButton ? this.props.rightShowButton : <i className='fa fa-plus' aria-hidden='true' /> }
            </span>
            : null
          }
          { this.props.leftSliderLabel ?
            <span style={combinedLeftLabelStyles}>
              {this.props.leftSliderLabel}
            </span>
            : null
          }
          { this.props.rightSliderLabel ?
            <span style={combinedRightLabelStyles}>
              {this.props.rightSliderLabel}
            </span>
            : null
          }
          <input
            min={this.props.min}
            style={{boxShadow: 'none'}}
            placeholder={this.props.placeholder}
            max={this.props.max}
            disabled={disabled}
            onChange={this.updateValue}
            value={value}
            ref={this.props.attrName}
            onFocus={this.resetError}
            type={type}
            step={this.props.step || 1}
            className={"form-control borderAzureFocus " + this.props.class}
            name={this.props.attrName}
            id={this.props.attrName} />
          {errors}
        </div>
        );
    }

    if (type === 'textarea') {
      return (
        <div className={className + " text-left"} key={this.props.attrName} style={{width:'100%', marginTop: this.props.marginTop || '8px', marginBottom: '24px', display: 'inline-block'}}>
          <Label
            {...this.props}
            model={this.props.model}
            attrName={this.props.attrName} />
          <div>
            <textarea
              name={this.props.attrName}
              style={{
                backgroundColor: 'transparent',
                borderColor: COLOURS.silver,
                fontFamily: 'Larsseit-Light',
                color: COLOURS.ink,
                fontSize: '14px',
                borderRadius: '2px',
                resize: 'none',
                WebkitBoxShadow: 'none',
              }}
              id={this.props.attrName}
              onBlur={this.updateValue}
              disabled={disabled}
              onChange={this.updateValueTemp}
              onFocus={this.resetError}
              className="form-control borderAzureFocus"
              rows="3"
              placeholder="">
              {value}
            </textarea>
            <div className="form-control-line">
            </div>
          </div>
          {errors}
        </div>
      );
    }

    var divBaseStyle = {
      width:'100%',
      marginBottom: '24px',
      display: 'inline-block'
    }

    var inputWithIcon = {
      maxWidth: '90%',
      minWidth: '90%',
      display: 'inline-block'
    }

    if (this.props.align) {
      inputBaseStyle.textAlign = this.props.align;
    }

    let combinedStyles = Object.assign({}, divBaseStyle, this.props.divStyle)
    let combinedInputStyles = Object.assign({}, inputStyle, this.props.inputStyle)

    return (
      <div className={className + " text-left"} key={this.props.attrName} style={combinedStyles}>
        { this.props.label ?
          <Label {...this.props}
            model={this.props.model}
            attrName={this.props.attrName} />
          : null
        }
        <div style={ this.props.withIcon ? inputWithIcon : null }>
          <input
            style={combinedInputStyles}
            onBlur={this.updateValue}
            disabled={disabled}
            onChange={this.updateValueTemp}
            value={value}
            ref={this.props.attrName}
            onFocus={this.resetError}
            type={type}
            className="form-control borderAzureFocus"
            name={this.props.attrName}
            id={this.props.attrName}
            placeholder={this.props.placeholder}
            required={this.props.validations == 'required'}
            onKeyPress={ this.props.onHittingReturn ? this.props.onHittingReturn : null }
          />
        </div>
        { this.props.withIcon ?
            <div style={{ maxWidth: '10%',
                      minWidth: '10%',
                      display: 'inline-block',
                      fontSize: '14px',
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      borderBottomColor: COLOURS.silver,
                      height: '24px',
                      color: this.state.changedValue ? COLOURS.inputBorder : COLOURS.inputBorder
                    }}>
            <i className="fa fa-search"></i>
            </div>
          : null }
          {errors}
      </div>
    );
  }
};

export default FormGroup;
