import React from 'react'
import { withRouter } from 'react-router-dom'
import { object } from 'prop-types'
import BuildOption from 'visual-components/shared/legal_builder/build-option'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'

class Dashboard extends React.Component {
  static propTypes = {
    partnershipAgreement: object.isRequired,
    currentCampaign: object.isRequired,
    actions: object.isRequired,
    history: object.isRequired,
    params: object.isRequired,
  }

  state = {
    selectedAgreement: this.props.partnershipAgreement.agreement.type || '',
  }

  componentWillMount() {
    const {
      partnershipAgreement: { agreement, error },
      actions,
      params,
      history,
    } = this.props

    if (error && error.includes('Agreement does not exist')) {
      return
    }

    if (error) {
      history.replace(`/builder/${params.campaignId}/partnership-agreement/dashboard`)
    }

    if (!agreement.id) {
      actions.loadPartnershipAgreement(params.partnershipId)
    }
  }

  next = () => {
    const {
      params: { partnershipId },
      currentCampaign,
      history,
    } = this.props

    const { selectedAgreement } = this.state

    if (selectedAgreement === 'template') {
      history.push(`/builder/${currentCampaign.id}/partnership-agreement/draft/${partnershipId}`)
    } else if (selectedAgreement === 'custom') {
      history.push(`/builder/${currentCampaign.id}/partnership-agreement/upload/${partnershipId}`)
    }
  }

  check = (attr, value) => {
    this.setState({
      selectedAgreement: value ? attr : '',
    })
  }

  continue = () => {
    const {
      partnershipAgreement: { agreement, invitee },
      params: { partnershipId },
      actions,
    } = this.props

    const { selectedAgreement } = this.state

    if (!agreement.id) {
      actions.createPartnershipAgreement(partnershipId, selectedAgreement)
      this.next()
    } else if (agreement.type !== selectedAgreement) {
      ConfirmationDispatcher.check({
        header: 'Heads up!',
        copy: `By choosing a new template, your previous Partnership Agreement with ${invitee.accountname} will be deleted. You will need to create a new draft using the selected template.`,
        confirmText: 'Change Template',
        action: () => {
          actions.createPartnershipAgreement(partnershipId, selectedAgreement)
          ConfirmationDispatcher.closeModal()
          this.next()
        },
      })
    } else {
      this.next()
    }
  }

  render() {
    const {
      params: { partnershipId },
    } = this.props

    const { selectedAgreement } = this.state

    return (
      <div>
        <div
          className="col-sm-12"
          style={{ marginBottom: '80px' }}
        >
          <BuildOption
            section="Agreement"
            type="template"
            check={this.check}
            continue={this.continue}
            heading="Use DojoMojo’s Partnership Agreement"
            highlightedHeading=""
            body="Select this option to use DojoMojo’s easily customizable sample Partnership Agreement. This will enable DojoMojo to automate tasks and reminders and enforce any minimum entry thresholds. By checking the box below, you acknowledge and agree that DojoMojo expressly disclaims any and all liability with respect thereto."
            button="Use Template"
            checked={selectedAgreement === 'template'}
            checkboxEditable={!!partnershipId}
            onLeft
          />

          <BuildOption
            section="Agreement"
            type="custom"
            check={this.check}
            continue={this.continue}
            heading="Create Your Own"
            body="Select this option if you prefer to use your own Partnership Agreement. You’ll upload your own document if you select this option. Note DojoMojo will require written consent from the brand Partner to enforce a minimum threshold and will not be able to automate tasks or reminders based on the Partnership Agreement."
            button="Use My Own"
            checked={selectedAgreement === 'custom'}
            checkboxEditable={!!partnershipId}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(Dashboard)
