import React from 'react'
import Theme from 'css/themes/media-market/EditRequest.css'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import { Input } from 'visual-components/util/form'
import {
  H3,
} from 'visual-components/util/texts'
import InputTheme from 'css/themes/Input.css'

import { isInRange } from 'visual-components/util/form/validations'
import Row from 'visual-components/util/no-padding-grid/row'
import classNames from 'classnames'

class RequestAudienceInformation extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className={SellMediaModalTheme.orangeTitle}>
          <H3 coral>
            <small>Audience Information</small>
          </H3>
        </div>
        <Input
          className={InputTheme.textarea}
          name="brand_description"
          label="Brand Description*"
          placeholder="Example: Yoga pants for ethically conscious women. Usually, our customers are between the ages of 20-35, who are passionate about wellness and fitness, and of course, Yoga."
          type="string"
          multiline
          required
          rows={3}
        />
        <Row className={classNames(Theme.conversionInputRows, Theme.spreadEven)}>
          <Input
            name="conversion_rate"
            type="text"
            label="Website Conversion Rate"
            placeholder="2%"
            validations={[
              isInRange({
                minValue: 1,
                maxValue: 100,
                errors: {
                  outOfBounds: 'Conversion rate must be between 1 and 100',
                },
              }),
            ]}
          />
          <Input
            name="conversion_value"
            label="Est. Conversion Value"
            placeholder="$200"
            type="text"
            fullwidth
            validations={[
              isInRange({
                minValue: 1,
                maxValue: 50000,
              }),
            ]}
          />
        </Row>

      </React.Fragment>
    )
  }
}

export default RequestAudienceInformation
