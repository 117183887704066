import React from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import AgreementThemes from 'css/themes/agreement/agreement-item.css'

class CalendarSelect extends React.Component {
  render() {
    const { focused, date, min, max } = this.props;
    const isDayOutsideRange = day => !day.isBetween(min, max, null, '[]')

    return (
      <div>
        <SingleDatePicker
          id="date_input"
          placeholder={this.props.placeholder}
          date={date}
          focused={focused}
          onDateChange={this.props.updateValue}
          onFocusChange={this.props.onFocusChange}
          isOutsideRange={!!min && !!max ? isDayOutsideRange : false}
          initialVisibleMonth={ min ? () => moment(min): null}/>
      </div>
    );
  }
}

export default CalendarSelect;
