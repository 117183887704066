import React from 'react'
import moment from 'moment-timezone'

import { Form, Input, Submit, } from 'visual-components/util/form'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

import { Card } from 'react-toolbox/lib/card'
import { P, H2, H3 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import AppDispatcher from 'dispatchers/app-dispatcher'

class CustomerSuccessAdminUnlockUser extends React.Component {
  state = {
    open: false,
    email: null,
    user: null,
  }

  unlockUser = () => {
    const { user: { id } } = this.state
    AppDispatcher.http({
      url: `/cs-unlock-user`,
      method: 'POST',
      data: {
        id,
      },
      success: data => {
        toastr.success('User Unlocked!', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
        this.handleChange({ user: data })
      },
      error: error => {
        toastr.error(error.responseText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      }
    })
  }

  handleChange = e => {
    this.setState(e)
  }

  handleSubmit = () => {
    const { email } = this.state
    AppDispatcher.http({
      url: `/cs-find-user`,
      method: 'POST',
      data: {
        email,
      },
      success: data => this.handleChange({ user: data }),
      error: error => {
        this.handleChange({ user: null})
        toastr.error('Cannot find user', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      }
    })
  }

  render() {
    const { open, email, user } = this.state



    return (
      <Card style={{
        padding: '24px'
      }}>
        <Grid>
          <Row style={{
            marginBottom: '16px'
          }}>
            <div onClick={ e => this.handleChange({ open: !open }) }
              style={{
                cursor: 'pointer'
            }}>
              <H2 azure>
                Unlock Users
                <i className={ `fa fa-angle-${ open ? 'up' : 'down' }` }
                  style={{
                    fontSize: '32px',
                    marginLeft: '8px'
                }}></i>
              </H2>
              <P multiline>
                { `Shows user account status and ability to unlock accounts` }
              </P>
            </div>
          </Row>
          <Row style={{
            display: open ? 'flex' : 'none'
          }}>
            <Col style={{
              width: '300px',
              marginRight: '100px'
            }}>
              <Form handleSubmit={ this.handleSubmit }
                updateModel={ this.handleChange }
                dataModel={ this.state }
              >
                <Input name="email"
                  label="Enter email address"
                  type="email"
                />

                <Submit label="Find user" />
              </Form>
            </Col>
            <Col style={{
              width: '400px'
            }}>
              {
                user ?
                <div>
                  <div style={{
                    marginBottom: '16px'
                  }}>
                    <H3>
                      <small>
                        <b>Email:</b>
                        { `  ${user.email}` }
                      </small>
                    </H3>
                    <H3>
                      <small>
                        <b>Name:</b>
                        { user.firstName
                          ? `  ${user.firstName || ''} ${user.lastName || ''}` 
                          : `  ${user.fullName}`
                        }
                      </small>
                    </H3>
                    <H3>
                      <small>
                        <b>Join Date:</b>
                        {`  ${moment(user.created_at).format('LL')}` }
                      </small>
                    </H3>
                    <H3>
                      <small>
                        <b>Current Status:</b>
                        {`  ${user.locked_out ? `Locked Out` : 'Unlocked'} ` }
                      </small>
                    </H3>
                    {
                      user.locked_out &&
                      <H3>
                        <small>
                          <b>Locked Out Since:</b>
                          {`  ${moment(user.locked_out).format('LL')}` }
                        </small>
                      </H3>
                    }
                    <Button
                      className={
                        user.locked_out
                          ? `${buttonTheme.blueButton} ${buttonTheme.noMargin}`
                          : `${buttonTheme.disabledButton} ${buttonTheme.noMargin}`
                      }
                      theme={buttonTheme}
                      disabled={!user.locked_out}
                      onClick={this.unlockUser}
                    >
                      Unlock User
                    </Button>
                  </div>
                </div>
                : null
              }
            </Col>
          </Row>
        </Grid>
      </Card>
    )
  }
}



export default CustomerSuccessAdminUnlockUser
