import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import d3 from 'd3'
import { Card } from 'react-toolbox/lib/card'
import {
  P, H1, H3, H6
} from 'visual-components/util/texts'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import COLOURS from 'util/colours'

const styles = {
  container: {
    marginBottom: 32,
  },
  statsCard: {
    width: '100%',
    border: `1px solid ${COLOURS.skyBase}`,
    boxShadow: 'none',
  },
  innerContainer: {
    padding: '16px 20px',
  },
  title: {
    marginBottom: 16,
  },
  statRow: {
    display: 'flex',
    marginBottom: 16,
    '& div:not(:last-child)': {
      marginRight: 80,
    },
  },
  visitsColumn: {
    width: 204,
  },
  entriesColumn: {
    width: 204,
  },
  optinColumn: {
    width: 160,
  },
  smsCtrColumn: {
    width: 160,
  },
  ctaCtrColumn: {
    width: 168,
  },
  revenueColumn: {
    width: 204,
  },
}

const SoloStatsCard = props => {
  const {
    loading,
    campaignId,
    visits,
    entries,
    cta,
    smsSend,
    classes: css,
  } = props

  const {
    entries: ctaClicks,
  } = cta

  // while loading sumAnalytics shouldn't be null
  const sumAnalytics = smsSend && smsSend.sumAnalytics ? smsSend.sumAnalytics : {}

  const {
    messages = 0,
    clicks = 0,
    revenue = 0,
    views = 0,
    sweepstakes_views = 0,
    sweepstakes_optins = 0,
  } = sumAnalytics

  const optin = sweepstakes_views ? sweepstakes_optins / sweepstakes_views : 0
  const smsCtr = messages ? clicks / messages : 0
  const ctaCtr = entries ? ctaClicks / entries : 0

  return (
    <div className={css.container}>
      <Card
        className={css.statsCard}
      >
        {loading ? (
          <MaterialSpinner when />
        ) : (
          <div className={css.innerContainer}>
            <div className={css.title}>
              <H3>
                <small>
                  Performance Summary
                </small>
              </H3>
            </div>
            <div className={css.statRow}>
              <div className={css.visitsColumn}>
                <P>
                  <i>Sweeps Visits</i>
                </P>
                <H1>
                  {d3.format(',')(visits)}
                </H1>
              </div>
              <div className={css.entriesColumn}>
                <P>
                  <i>Sweeps Entries</i>
                </P>
                <H1>
                  {d3.format(',')(entries)}
                </H1>
              </div>
              <div className={css.optinColumn}>
                <P>
                  <i>SMS Opt-In Rate</i>
                </P>
                <H1>
                  {optin ? d3.format('.1%')(optin) : '-'}
                </H1>
              </div>
              <div className={css.smsCtrColumn}>
                <P>
                  <i>SMS CTR</i>
                </P>
                <H1>
                  {smsCtr ? d3.format('.1%')(smsCtr) : '-'}
                </H1>
              </div>
              <div className={css.ctaCtrColumn}>
                <P>
                  <i>Custom CTA CTR</i>
                </P>
                <H1>
                  {ctaCtr ? d3.format('.1%')(ctaCtr) : '-'}
                </H1>
              </div>
              <div className={css.revenueColumn}>
                <P>
                  <i>Revenue Generated</i>
                </P>
                <H1>
                  {revenue ? d3.format('$,.2f')(revenue) : '-'}
                </H1>
              </div>
            </div>
            <Link to={`/analytics/campaigns/${campaignId}`}>
              <H6 azure>
                View Full Analytics
              </H6>
            </Link>
          </div>
        )}
      </Card>
    </div>
  )
}

SoloStatsCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  campaignId: PropTypes.number.isRequired,
  visits: PropTypes.number.isRequired,
  entries: PropTypes.number.isRequired,
  cta: PropTypes.object.isRequired,
  smsSend: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(SoloStatsCard)
