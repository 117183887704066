import { getTemplate, compileTemplate } from './TemplateV2'

const template = getTemplate()
const themeColor = '#ff7043'

template.submitButton = {
  button: {
    borderRadius: 20,
  },
}

template.inputs = {
  label: {
    color: '#fff',
  },
  input: {
    color: '#2c3541',
    borderColor: '#e4e4e4',
    '&::placeholder': {
      color: '#e4e4e4 !important',
    },
  },
}


export const templateStyles = compileTemplate(template)
export const defaultVals = {}
