import React from 'react'
import PropTypes from 'prop-types'

import { P, H3 } from 'visual-components/util/texts'

import { cleanPlanText } from 'util/brand-stripe-belt-helper'

const subscriberLimits = {
  white: '500',
  greenFixed: '10,000',
  black: '50,000',
}

function SMSMarketingBreakdownItems({ plan, title }) {
  const subscriberLimit = subscriberLimits[cleanPlanText(plan || '')]

  const items = [
    {
      content: 'Access to the fully-featured SMS Marketing suite',
      check: true,
    },
    {
      content: "The joint power of DojoMojo's Partnership Network and SMS marketing",
      check: true,
    },
    {
      content:
        'All SMS acquisition tools, from sweepstakes, opt-in capture for your website and online store, and more',
      check: true,
    },
    {
      content:
        'SmartTarget and Audience AutoScrub – analytics-driven features to optimize your audience health so you can focus on your highest potential customers',
      check: true,
    },
    {
      content: (
        <>
          The best available pricing through our SMS credits-based plans!
          {' '}
          <a
            href="https://help.dojomojo.com/hc/en-us/articles/360036050372-SMS-Pricing-Information"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </>
      ),
      check: true,
    },
    !!subscriberLimit && {
      content: (
        <i>
          {`Important Note: Your DojoMojo subscription allows you to manage up to ${subscriberLimit}
          SMS subscribers.`}
        </i>
      ),
      check: false,
    },
    !!subscriberLimit && {
      content:
        'Once you reach the maximum subscriber limit, your plan will automatically upgrade to the next available tier in the next billing cycle.',
      check: false,
    },
  ]

  return (
    <div>
      {title ? (
        <H3 multiline coral>
          <small>What you&rsquo;ll get</small>
        </H3>
      ) : (
        <P multiline>
          <i>What you&rsquo;ll get</i>
        </P>
      )}
      {items.map((item, i) => (
        <>
          <div style={{ marginTop: 16 }} />
          <P multiline key={i}>
            {item.check && <span style={{ marginRight: 8 }}>✓</span>}
            {item.content}
          </P>
        </>
      ))}
    </div>
  )
}

SMSMarketingBreakdownItems.propTypes = {
  plan: PropTypes.string.isRequired,
  title: PropTypes.bool,
}

SMSMarketingBreakdownItems.defaultProps = {
  title: false,
}

export default React.memo(SMSMarketingBreakdownItems)
