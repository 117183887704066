import React from 'react'
import Theme from 'css/themes/media-market/EditRequest.css'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import {
  object,
  func,
  array,
} from 'prop-types'
import { Select } from 'visual-components/util/form'
import {
  H3,
  H5,
  H6,
} from 'visual-components/util/texts'
import Checkbox from 'react-toolbox/lib/checkbox'
import CheckboxTheme from 'css/themes/Checkbox.css'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import DateRangePickerWrapper from 'components/util/date-range-picker-wrapper'
import classNames from 'classnames'
import { requiredText } from 'visual-components/util/form/validations'
import ChipInput from 'visual-components/util/inputs/chip-input'
import SellerTheme from 'css/themes/seller-profile-tab.css'
import SearchBrand from '../createNewMedia/SearchBrand'

const budgetRanges = [
  { value: null, label: 'Select a range', disabled: true },
  { value: '500-999', label: '$500 - $999' },
  { value: '1000-1999', label: '$1,000 - $1,999' },
  { value: '2000-4999', label: '$2,000 - $4,999' },
  { value: '5000-9999', label: '$5,000 - $9,999' },
  { value: '10000-50000', label: '$10,000+' },
  { value: '0-0', label: 'Prefer not to disclose' },
]
const mediaInterestOptionsPerRow = 3
const mediaInterestOptions = [
  'Sponsored Email',
  'Blog Post',
  'Social Media Post',
  'Dedicated Email',
  'Banner Ad',
  'Instagram Story',
  'Other',
]
const mediaInterestOptionsRows = mediaInterestOptions
  .reduce((all, option, index) => { // create rows of three
    const rowIndex = index % mediaInterestOptionsPerRow
    if (!all[rowIndex]) all[rowIndex] = []
    all[rowIndex].push(option)
    return all
  }, [])

const dealTermOptions = [
  'CPC',
  'Flat Rate',
  'CPM',
  'CPA',
]

class RequestMediaInformation extends React.Component {
  static propTypes = {
    possibleStartDate: object,
    possibleEndDate: object,
    onUpdatePossibleDateRange: func.isRequired,
    onCheckboxToggle: func.isRequired,
    mediaInterests: array.isRequired,
    dealTerms: array.isRequired,
    isDateFlexible: array.isRequired,
    selectedBrand: object,
    onSellerBrandChange: func.isRequired,
  }

  static defaultProps = {
    possibleStartDate: null,
    possibleEndDate: null,
    selectedBrand: {},
  }

  state = {
    focusedInput: null,
  }

  componentDidUpdate = () => {
    const { mediaInterests } = this.props
    if (
      mediaInterests.find(interest => !mediaInterestOptions.includes(interest))
      && !mediaInterests.includes('Other')
    ) {
      this.onInterestAdd('Other')
    }
  }

  onCheckboxToggle = (isChecked, event) => {
    const { onCheckboxToggle, mediaInterests } = this.props
    const { target: { name, value } } = event
    if (value === 'Other' && isChecked === false) { // remove all "Other" tags
      mediaInterests
        .filter(selectedInterest => !mediaInterestOptions.includes(selectedInterest))
        .forEach(otherInterest => { onCheckboxToggle(false, 'mediaInterests', otherInterest) })
    }

    onCheckboxToggle(isChecked, name, value)
  }

  onFocusChange = focusedInput => {
    this.setState({ focusedInput })
  }

  onInterestAdd = interest => {
    const { onCheckboxToggle } = this.props
    onCheckboxToggle(true, 'mediaInterests', interest)
  }

  onInterestDelete = interest => {
    const { onCheckboxToggle } = this.props
    onCheckboxToggle(false, 'mediaInterests', interest)
  }

  renderMediaInterest = () => {
    const { mediaInterests } = this.props
    return mediaInterestOptionsRows.map(rowOptions => (
      <Col
        style={{
          width: '192px',
        }}
      >
        {
          rowOptions.map(option => (
            <Checkbox
              key={option}
              name="mediaInterests"
              value={option}
              label={option}
              theme={CheckboxTheme}
              checked={mediaInterests.includes(option)}
              onChange={this.onCheckboxToggle}
              className={classNames(CheckboxTheme.textMedium, Theme.checkbox)}

            />
          ))
        }
      </Col>
    ))
  }

  renderDealTermOptions = () => {
    const { dealTerms } = this.props
    return dealTermOptions.map(option => (
      <Checkbox
        key={option}
        name="dealTerms"
        value={option}
        label={option === 'CPA' || option === 'CPM' ? `${option} (Coming Soon)` : option}
        theme={CheckboxTheme}
        checked={dealTerms.includes(option)}
        onChange={this.onCheckboxToggle}
        className={classNames(CheckboxTheme.textMedium, Theme.checkbox)}
      />
    ))
  }

  render() {
    const {
      focusedInput,
    } = this.state

    const {
      possibleStartDate,
      possibleEndDate,
      onUpdatePossibleDateRange,
      isDateFlexible,
      mediaInterests,
      onSellerBrandChange,
      selectedBrand,
    } = this.props

    const hasOtherInterests = mediaInterests.includes('Other')
    return (
      <React.Fragment>
        <div className={SellMediaModalTheme.orangeTitle}>
          <H3 coral>
            <small>Media Information</small>
          </H3>
        </div>
        <Row className={SellMediaModalTheme.sellingBrandSelect}>
          <div className={SellMediaModalTheme.sellingBrandSelectLabel}>
            <H6>Selling Brand</H6>
          </div>
          <SearchBrand
            selectedBrand={selectedBrand}
            selectBrand={onSellerBrandChange}
            searchResultsType="mediaMarketSeller"
          />
        </Row>
        <Row className={Theme.budgetInput}>
          <Select
            name="budget_range"
            label="Budget Range*"
            options={budgetRanges}
            placeholder="Select a range"
            fullwidth
            validations={[
              requiredText({ error: 'Budget Range is required' }),
            ]}
          />
        </Row>
        <H5>Type of Media Interested in Buying</H5>
        <Row className={classNames({ [Theme.checkboxWrapper]: !hasOtherInterests }, Theme.spreadEven)}>
          {this.renderMediaInterest()}
        </Row>
        {
          hasOtherInterests
          && (
            <Row className={Theme.checkboxWrapper}>
              <ChipInput
                chipTheme={SellerTheme}
                addChipKeyCodes={[13, 9, 188]}
                style={{ width: '100%' }}
                list={mediaInterests.filter(interest => !mediaInterestOptions.includes(interest))}
                onItemAdded={this.onInterestAdd}
                onItemDeleted={this.onInterestDelete}
                inputProps={{
                  placeholder: 'Hit tab, comma, or return to add multiple media types',
                }}
                fullwidth
              />
            </Row>
          )
        }
        <H5>Deal Terms</H5>
        <Row className={classNames(Theme.checkboxWrapper, Theme.spreadEven)}>
          {this.renderDealTermOptions()}
        </Row>
        <H5>Possible Date Ranges</H5>

        {/* todo figure out if the below is needed */}
        <div className={SellMediaModalTheme.calendar}>
          <DateRangePickerWrapper
            onDatesChange={onUpdatePossibleDateRange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={possibleStartDate}
            startDatePlaceholderText="Click to select start date"
            endDate={possibleEndDate}
            endDatePlaceholderText="Click to select end date"
            // isOutsideRange={() => {return false}}
            customLeftInputIcon={<img src="images/react-dates-clock-icon.png" />}
            customRightInputIcon={<img src="images/react-dates-clock-icon.png" />}
            openDirection="up"
            displayFormat="MMM D, YYYY"
            disabled={false}
          />
        </div>
        <Row>
          <Checkbox
            label="Dates are flexible"
            theme={CheckboxTheme}
            name="isDateFlexible"
            value="yes"
            checked={isDateFlexible.length > 0}
            onChange={this.onCheckboxToggle}
            className={classNames(CheckboxTheme.textMedium, Theme.checkbox)}
          />
        </Row>
      </React.Fragment>
    )
  }
}

export default RequestMediaInformation
