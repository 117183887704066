import React from 'react'
import { P } from 'visual-components/util/texts'
import Field from 'visual-components/util/inputs/field'
import propTypes from 'prop-types'
import Theme from 'css/themes/media-market/DealCollaborate.css'
import classNames from 'classnames'
class CollaborateAssetsHeader extends React.Component {
  static propTypes = {
    onZoomUpdate: propTypes.func.isRequired,
    zoomPercent: propTypes.number.isRequired,
    assetUrl: propTypes.string,
    onChange: propTypes.func.isRequired,
    closeModal: propTypes.func.isRequired,
    downloadSelectedImage: propTypes.isRequired,
  }

  static defaultProps = {
    assetUrl: '',
  }

  onZoomUpdate = add => {
    const {
      onZoomUpdate,
      zoomPercent,
    } = this.props
    const {
      maxZoom,
      minZoom,
      zoomIncrement,
    } = CollaborateAssetsHeader.constants
    onZoomUpdate(Math.max(Math.min(zoomPercent + (zoomIncrement * add), maxZoom), minZoom))
  }

  onZoomIncrease = () => this.onZoomUpdate(1)

  onZoomDecrease = () => this.onZoomUpdate(-1)

  onPreviousClick = () => {
    const { onChange } = this.props
    onChange(-1)
  }

  onNextClick = () => {
    const { onChange } = this.props
    onChange(1)
  }

  static constants = {
    zoomIncrement: 25,
    maxZoom: 200,
    minZoom: 25,
  }

  renderZoomControl(zoomPercent) {
    return (
      <div className={Theme.zoomControl}>
        <img
          src="images/icons/circle-subtract.svg"
          onClick={this.onZoomDecrease}
        />
        <Field
          attrName="zoom"
          dataModel={{ zoom: `${zoomPercent}%` }}
          formModel={{}}
          className={Theme.input}
          readOnly
        />
        <img
          src="images/icons/circle-add.svg"
          onClick={this.onZoomIncrease}
        />
      </div>
    )
  }

  renderDownload() {
    const { downloadSelectedImage } = this.props
    return (
      <div className={classNames(Theme.downloadButton, Theme.divider)}>
        <img
          src="images/icons/blue-download.svg"
          onClick={downloadSelectedImage}
        />
      </div>
    )
  }

  render() {
    const {
      zoomPercent,
      assetUrl,
      closeModal,
    } = this.props
    return (
      <div className={Theme.header}>
        <div className={Theme.navigationBar}>
          <div className={Theme.close}>
            <img
              src="images/icons/white-close.svg"
              onClick={closeModal}
            />
          </div>
          <img
            src="images/icons/chevron-left.svg"
            onClick={this.onPreviousClick}
          />
          <P white>
            <i>
              {assetUrl}
            </i>
          </P>
          <img
            src="images/icons/chevron-right.svg"
            onClick={this.onNextClick}
          />
        </div>

        <div className={Theme.menuBar}>
          <div className={Theme.rightAligned}>
            {this.renderZoomControl(zoomPercent)}
            {this.renderDownload()}
          </div>
        </div>
      </div>
    )
  }
}

export default CollaborateAssetsHeader
