import React from 'react'
import COLOURS from 'util/colours'

class SubNavContainer extends React.Component {
  render() {
    return (
      <div style={{
        marginTop: '50px'
      }}>
        <div className="nav-container Sub-Nav-Container--padding-15" style={{
          paddingLeft: this.props.paddingLeft ? this.props.paddingLeft : '115px',
          paddingRight: this.props.paddingRight ? this.props.paddingRight : '115px'
        }}>
          { this.props.children }
          <hr style={{
              display: this.props.noBorder ? 'none' : 'inherit',
              marginTop: '-1px',
              borderColor: COLOURS.lightGrey,
              marginBottom: '0'
            }}/>
        </div>
      </div>
    );
  }
}

export default SubNavContainer
