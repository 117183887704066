import React, { PureComponent as Component } from 'react'
import TooltipCard from 'visual-components/analyticsDashboard/tooltip/tooltip-card'

import { renderToString } from 'react-dom/server'

const EntriesTooltipFactory = React.createFactory(TooltipCard)

const renderGraph = function (data, section) {
  const { columnData, columnCategories } = data
  $('.aggregrate-bar').html('')

  const periodMap = {}

  data.columnCategories.forEach((item, idx) => {
    const val = idx === data.columnCategories.length - 1
      ? item
      : `${item} - ${data.columnCategories[idx + 1]}`

    periodMap[item] = val
  })

  const graph = new Contour({
    el: '.aggregrate-bar',
    xAxis: {
      categories: columnCategories,
    },
    chart: {
      padding: {
        left: 33,
      },
      height: 300,
    },
    tooltip: {
      opacity: 1,
      formatter(d) {
        const str = renderToString(EntriesTooltipFactory({
          period: periodMap[d.x],
          value: d.y,
          metric: section === 'dashboardCampaignsLaunched' ? 'campaigns launched' : 'new partnerships',
        }))

        return str
      },
    },
  })
    .cartesian()
    .yAxisLines()
    .column(columnData)
    .tooltip()
    .dateBasedXAxis(null, {
      n: columnCategories.length,
      formattedDates: columnCategories,
    })
    .render()

  return graph
}

class AggregateBar extends Component {
  componentDidMount() {
    this.graph = renderGraph(this.props.data, this.props.section)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      renderGraph(this.props.data, this.props.section)
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            display: 'inline-block',
            width: '590px',
            height: '300px',
            marginBottom: '20px',
          }}
          className="aggregrate-bar"
        />
      </div>
    )
  }
}

export default AggregateBar
