import React from 'react';
import { H2, P } from 'visual-components/util/texts';

var steps = [
  'setup',
  'template',
  'lander',
  'postentry',
  'bonusentry',
  'partners',
  'hosting',
  'tracking',
  'checklist'
]

class BuilderStepHeader extends React.Component{
  render() {
    var stepIdx = steps.indexOf(this.props.step);

    return (
      <div className="row" style={{paddingTop: '45px', paddingBottom: '35px', paddingLeft: '15px'}}>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div className="row" style={{
            marginTop: '15px'
          }}>
            <H2>
              {LANGUAGE.builder.steps[this.props.step].title}
            </H2>
          </div>
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          {this.props.children}
        </div>
        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
          <div className="row">
            <P>
              { !this.props.isHost && this.props.step == "bonusentry" ?
                LANGUAGE.builder.steps[this.props.step].partnerSub
                :
                LANGUAGE.builder.steps[this.props.step].sub
              }
            </P>
          </div>
          <div className="row" style={{
              background: '#FFFFFF',
            }}>
         </div>
           <div className="row" style={{
              marginTop: this.props.subNav ? '35px' : '0'
           }}>
             { this.props.subNav }
            </div>
        </div>
      </div>
    );
  }
};

export default BuilderStepHeader;
