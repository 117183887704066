import React from 'react';
import Radium from 'radium';

var ErrorMsg = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: '12px',
      color: 'red',
      display: 'none',
      textAlign: 'left'
    }],
    styles: [],
    message: 'Required field'
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span className="error-msg" style={styles}>* {this.props.message}<br /></span>
    )
  }
});

export default ErrorMsg;