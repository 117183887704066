import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card } from 'react-toolbox/lib/card'
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header'
import CtaTrafficBar from 'visual-components/analyticsDashboard/overviewCard/cta-traffic-bar'
import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'
import EmptyState from 'visual-components/util/graphs/empty-state'
import Spinner from 'util/components/spinner'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import AnalyticsTheme from 'css/themes/analytics.css'

const mapState = ({ analyticsDashboard }) => {
  const {
    dashboardCustomCTA,
    datePickerCustomCTA,
    oldestDate,
  } = analyticsDashboard

  return {
    dashboardCustomCTA,
    datePicker: datePickerCustomCTA,
    oldestDate,
  }
}

class CtaMetrics extends Component {
  static propTypes = {
    datePicker: PropTypes.object.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
    dashboardCustomCTA: PropTypes.object,
  }

  static defaultProps = {
    dashboardCustomCTA: null,
  }

  changeFilter = (startDate, endDate) => {
    const data = {
      startDate,
      endDate,
      metric: 'dashboardCustomCTA',
    }

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  render() {
    const {
      datePicker,
      dashboardCustomCTA,
      oldestDate,
    } = this.props

    if (!dashboardCustomCTA) {
      return (
        <Spinner />
      )
    }

    const { columnData, columnCategories } = dashboardCustomCTA

    return (
      <Card className={`${AnalyticsTheme.ctaBarCard}`}>
        <div className={`${AnalyticsTheme.ctaBarCardContainer}`}>
          <AnalyticsHeader
            title="Custom CTA Traffic"
            tooltipText="A history of all the Custom CTA clicks driven by your campaigns"
          />
          {
            columnData[0].data.some(el => el > 0)
              ? <CtaTrafficBar values={columnData} dates={columnCategories} changeFilter={this.changeFilter} />
              : (
                <EmptyState
                  text="The Custom CTA is a powerful feature reserved for Premium DojoMojo users."
                  learnMore="https://help.dojomojo.com/hc/en-us/articles/226204288-Drive-Conversions-and-Page-Views-with-a-Custom-CTA"
                  height="135px"
                  bigCard
                  bigText
                />
              )
          }
        </div>

        <CardDateRangePickerRow
          oldestDate={oldestDate}
          changeFilter={this.changeFilter}
          {...datePicker}
        />
      </Card>
    )
  }
}

export default connect(mapState)(CtaMetrics)
