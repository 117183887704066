import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

import Dropdown from 'react-toolbox/lib/dropdown';

import Field from 'visual-components/util/inputs/field';
import Label from 'visual-components/util/inputs/label';
import DropdownTheme from 'css/themes/Dropdown.css'

const themes = {
  Dropdown: DropdownTheme
}

class SelectField extends Field {
  constructor(props) {
    super(props);

    this.dropdownBlur = this.dropdownBlur.bind(this);
  }

  static propTypes = {
    allowBlank: PropTypes.bool,
  }

  static defaultProps = {
    allowBlank: true,
  }

  valueFromFormModel = () => {
    const { attrName, formModel, id } = this.props

    if (id) {
      const errors = formModel.items[id].errors || {}
      return errors[attrName]
    }

    return formModel[attrName]
  }

  dropdownBlur() {
    var that = this;
    setTimeout(function() {
      that.blur();
    }, 500);
  }
 
  cardTemplate(item) {
    const containerStyle = {
      display: 'flex',
      flexDirection: 'row'
    };

    const imageStyle = {
      display: 'flex',
      width: '25px',
      height: '16px',
      marginRight: '12px',
    };

    const contentStyle = {
      display: 'flex',
    };

    return (
      <div style={containerStyle}>
        { item.img ? (
          <img src={`/images/credit-card-icons/${item.img}.svg`} style={imageStyle}/>
        ) : null }
        <div style={contentStyle}>
          <span>{item.label}</span>
        </div>
      </div>
    );
  }

  render() {
    var {
      attrName,
      label,
      dataModel,
      formModel,
      validations,
      options,
      value,
      style,
      className,
      defaultSelected,
      cardTemplate,
      showErrorMessages,
      allowBlank
    } = this.props;

    if (!dataModel[attrName] && defaultSelected) {
      dataModel[attrName] = defaultSelected;
    }

    const formModelData = this.valueFromFormModel()
    const errors = (formModelData ? formModelData.errors : []) || []
    const error_messages = _.map(errors, text => text)
    const show_messages = showErrorMessages && error_messages.length

    return (
      <div>
        <Label label={ label } />
        <Dropdown source={ options }
          allowBlank={allowBlank}
          value={ dataModel[attrName] }
          onBlur={ this.dropdownBlur }
          onChange={ e => this.updateValue(e) }
          theme={ themes.Dropdown }
          className={themes.Dropdown[className]}
          style={ style }
          error={show_messages ? error_messages : false}
          template={cardTemplate ? this.cardTemplate : null}
          auto
        />
      </div>
    );
  }
}

export default SelectField;
