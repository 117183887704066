import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { H3 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import COLOURS from 'util/colours'

const styles = {
  editModal: {
    position: 'absolute',
    left: props => (props.horizontalAlign === 'left' ? '0' : '50%'),
    transform: props => (props.horizontalAlign === 'left' ? 'none' : 'translate(-50%, 0)'),
    top: props => (
      props.modalPosition === 'bottom'
        ? 210
        : -260
    ),
    zIndex: '6000',
    width: '260px',
    padding: '24px 24px 16px 24px',
    borderRadius: '2px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    background: COLOURS.white,
  },
  modalHeader: {
    marginBottom: '24px',
    textAlign: 'center',
  },
}

const EditModalWrapper = props => {
  const {
    classes: css,
    onSave,
    children,
    onDelete,
  } = props

  return (
    <div
      className={css.editModal}
    >
      <div className={css.modalHeader}>
        <H3>
          Edit Logo
        </H3>
      </div>
      {children}
      <ModalButtonGroup
        confirm={onSave}
        confirmText="Done"
        canSave
        hideLine
        remove={onDelete}
      />
    </div>
  )
}

EditModalWrapper.propTypes = {
  classes: propTypes.object.isRequired,
  onSave: propTypes.func.isRequired,
  children: propTypes.element.isRequired,
  modalPosition: propTypes.string.isRequired,
  onDelete: propTypes.func,
  horizontalAlign: propTypes.string.isRequired,
}

EditModalWrapper.defaultProps = {
  onDelete: null,
}

export default injectSheet(styles)(EditModalWrapper)
