import React, { Component } from 'react'
import { object, string, func } from 'prop-types'
import COLOURS from 'util/colours'
import { MenuItem } from 'react-toolbox/lib/menu'

import moment from 'moment-timezone'
import RelativeTime from 'visual-components/util/relative-time'

import LinkOrSwitchBrand from 'visual-components/notificationCard/LinkOrSwitchBrand'
import WideNavBarDropdownTheme from 'css/themes/WideNavBarDropdown.css'

const themes = {
  WideNavBarDropdown: WideNavBarDropdownTheme,
}

class NotificationMenuItem extends Component {
  static propTypes = {
    item: object.isRequired,
    notificationText: string.isRequired,
    handleNotificationMenuHide: func.isRequired,
    handleRead: func.isRequired,
    className: string,
  }

  static defaultProps = {
    className: '',
  }

  read = () => {
    const {
      item: { id, read_at },
      handleRead,
    } = this.props

    if (!read_at) handleRead(id)
  }

  render() {
    const {
      className,
      notificationText,
      item: {
        img, brand_id, createdAt, presentational: { to } = {},
      },
      handleNotificationMenuHide,
    } = this.props

    return (
      <LinkOrSwitchBrand to={to} brandId={brand_id} onClick={this.read}>
        <MenuItem
          theme={themes.WideNavBarDropdown}
          className={className}
          onClick={handleNotificationMenuHide}
        >
          <div>
            <div
              style={{
                overflow: 'hidden',
                background: COLOURS.white,
                borderRadius: '50%',
                height: '48px',
                minWidth: '48px',
                width: '48px',
                marginRight: '12px',
              }}
            >
              <img
                src={img || '/images/defaults/mojo-white-tiger-logo@2x.png'}
                style={{
                  objectFit: 'contain',
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: '248px',
              height: '40px',
              whiteSpace: 'normal',
            }}
          >
            <p
              style={{
                fontFamily: 'Larsseit',
                fontSize: '14px',
                color: COLOURS.ink,
                lineHeight: '22px',
              }}
            >
              {notificationText}
            </p>
          </div>
          <div
            style={{
              width: '70px',
              textAlign: 'right',
              height: '40px',
              marginLeft: '8px',
              whiteSpace: 'normal',
            }}
          >
            <RelativeTime
              time={moment(createdAt)}
              style={{
                fontFamily: 'Larsseit-Light',
                fontSize: '14px',
                color: COLOURS.ink,
              }}
            />
          </div>
        </MenuItem>
      </LinkOrSwitchBrand>
    )
  }
}

export default NotificationMenuItem
