import React from 'react'
import { Small } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'

const CounterBrandInfo = props => {
  const {
    logo,
    brandName,
    isLive,
    status,
  } = props

  return (
    <Row style={{ alignItems: 'center', marginBottom: '20px' }}>
      <img
        src={logo}
        style={{
          maxWidth: '32px',
          maxHeight: '32px',
          marginRight: '8px',
          border: 'solid 1px #dee4e5',
        }}
      />
      <Small white={isLive && status === 'live'}>{brandName}</Small>
    </Row>
  )
}

export default CounterBrandInfo
