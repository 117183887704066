var model = 'notificationsList';
import _ from 'lodash'

import CampaignInvitesNotification from 'components/notifications/campaign-invite-notification';
import CampaignApplicationNotification from 'components/notifications/campaign-application-notification';
import BrandInviteNotification from 'components/notifications/brand-invite-notification';
var defaultState =  {
  identifier: 'id',
  rid: model,
  items: {},
  presentation: [],
  notificationsNum: 0,
  loading: true,
}

var toPresentation = function (newState) {
  var presentation = _.map(newState.items, function (item) {
    return item;
  });

  presentation = _.sortBy(presentation, 'created_at').reverse();

  newState.presentation = presentation;

  return newState;
}

var NotificationsReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_ATTR: function () {
      return _.extend({}, state, action.data);
    },
    ADD_APPLICATIONS: function () {
      var copy = LANGUAGE.notifications;

      var applications = {};

      _.each(action.data, function (item) {
        item.component = CampaignApplicationNotification;
        item.recipient = item.campaign.hostBrand.accountname;
        item.from = item.invitee.accountname;
        item.copy = copy.campaignApplication;
        item.created_at = new Date(item.created_at)
        item.type = copy.campaignApplication.type;

        applications[item.id] = item;
      });

      var newState = _.extend({}, state);

      newState.notificationsNum += action.data.length;

      _.extend(newState.items, applications);

      return toPresentation(newState);
    },
    HANDLE_ITEM: function () {
      var newState = _.extend({}, state);

      newState.notificationsNum -= 1;

      delete newState.items[action.data.id];

      return toPresentation(newState);
    },
    ADD_CAMPAIGN_INVITES: function () {
      let copy = LANGUAGE.notifications;

      var invites = {};

      _.each(action.data, function (item) {
        item.component = CampaignInvitesNotification;
        item.recipient = item.invitee.accountname;
        item.type = copy.campaignInvite.type;
        item.copy = copy.campaignInvite;
        item.created_at = new Date(item.created_at)
        item.from = item.campaign.hostBrand.accountname;

        invites[item.id] = item;
      });

      var newState = _.extend({}, state);

      newState.notificationsNum += action.data.length;

      _.extend(newState.items, invites);

      return toPresentation(newState);
    },
    ADD_BRAND_INVITES: function () {
      var brandInvites = {};
      let copy = LANGUAGE.notifications;

      _.each(action.data, function (item) {
        item = _.extend({}, item, {
          component: BrandInviteNotification,
          recipient: item.email,
          from: item.brand.accountname,
          type: copy.brandInvite.type + ' - ' + item.brand.accountname,
          copy: copy.brandInvite,
          status: 'pending',
          id: 'brandInvite' + item.id,
          statusLabel: LANGUAGE.notifications.pending
        });

        brandInvites[item.id] = item;
      });

      var newState = _.extend({}, state);

      newState.notificationsNum += action.data.length;

      _.extend(newState.items, brandInvites);

      return toPresentation(newState);
    },
    LOAD: function () {
  //     var applications =   applications: function (credentials) {
  //   var brandIds = _.map(credentials, 'brandId');
  //   return _.filter(this.records, function (partnershipInvite) {
  //     return partnershipInvite.application && (brandIds.indexOf(partnershipInvite.hostbrand_id) !== -1)
  //   });
  // },

  //     return _.extend({}, state, action.data);
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default NotificationsReducer;
