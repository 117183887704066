import React from 'react'
import { object, func, bool } from 'prop-types'
import injectSheet from 'react-jss'
import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import { debounce } from 'lodash'
import { H3, H6 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import RangeSlider from 'util/components/templates/form/range-slider'
import ColorRangeSlider from 'util/components/templates/form/range-slider-with-color'
import RadioTheme from 'css/themes/Radio.css'
import * as metrics from 'util/metrics'
import COLOURS from 'util/colours'
import SelectBackground from './SelectBackground'

const styles = {
  container: {
    background: COLOURS.white,
    width: '320px',
    padding: '24px 0px 16px 24px',
  },
  header: {
    marginBottom: '24px',
    paddingRight: '24px',
    textAlign: 'center',
  },
  section: {
    marginBottom: '24px',
  },
  selectImage: {
    color: `${COLOURS.azure} !important`,
    backgroundColor: `${COLOURS.white} !important`,
    marginLeft: '88px !important',
  },
  radioGroup: {
    marginTop: '16px',
  },
  radioButton: {
    display: 'inline-block',
    marginRight: '40px',
  },
  radioButtonNoMargin: {
    marginRight: '0px',
  },
  hr: {
    width: '320px',
    marginTop: '8px',
    marginLeft: '-24px',
    borderTopColor: COLOURS.silver,
  },
  buttonGroup: {
    paddingRight: '24px',
  },
}

class editBackground extends React.Component {
  static propTypes = {
    campaign: object.isRequired,
    elements: object.isRequired,
    isNewTemplate: bool,
    isVideoTemplate: bool,
    updateAttr: func.isRequired,
    onSave: func.isRequired,
    onCancel: func.isRequired,
    classes: object.isRequired,
  }

  static defaultProps = {
    isNewTemplate: false,
    isVideoTemplate: false,
  }

  componentDidMount() {
    const el = document.getElementById('launcher')
    if (el) {
      el.style.display = 'none'
    }
  }

  componentWillUnmount() {
    const el = document.getElementById('launcher')
    if (el) {
      el.style.display = 'block'
    }
  }

  track = debounce(data => {
    metrics.create('campaignManagerUpdatedBackgroundAttribute', {
      meta: data,
    })
  }, 500)

  incrementValue = attr => {
    const { elements: { styles } } = this.props
    const value = styles.imagePos[attr] + 1
    this.updatePostion(attr, value)
  }

  decrementValue = attr => {
    const { elements: { styles } } = this.props
    const value = styles.imagePos[attr] - 1
    this.updatePostion(attr, value)
  }

  updatePostion = (attr, value) => {
    const {
      updateAttr,
      campaign: { viewDesktop },
    } = this.props
    const data = {}
    data[attr] = Number(value)
    const imagePos = {
      imagePos: data,
    }
    //backwards compatibility
    if (!viewDesktop) {
      imagePos.imagePosMobile = { ...data }
    }

    this.track({
      type: 'imagePosition',
      attr,
    })
    updateAttr('styles', imagePos, true)
  }

  updateOverlay = (attr, value) => {
    const { updateAttr } = this.props
    const data = {}
    data[attr] = value
    const overlay = {}
    overlay.overlay = data
    this.track(Object.assign({
      type: 'imageOverlay',
      attr,
    }, attr === 'type' ? { value } : {}))
    updateAttr('styles', overlay, true)
  }

  render() {
    const {
      campaign: { viewDesktop },
      elements,
      updateAttr,
      isNewTemplate,
      isVideoTemplate,
      classes: css,
      onSave,
      onCancel,
    } = this.props
    const { styles: styleObj } = elements

    const postionModel = 'imagePos'
    const overlayModel = 'overlay'

    return (
      <div className={css.container}>
        <div className={css.header}>
          <H3>
            Edit Background
          </H3>
        </div>
        <div>
          <div className={css.section}>
            <SelectBackground
              elements={elements}
              isVideoTemplate={isVideoTemplate}
              updateAttr={updateAttr}
            />
          </div>
          <div className={css.section}>
            <H6>
              Image Placement
            </H6>
            <div style={{ width: '272px', display: 'table' }}>
              <RangeSlider
                attr="x"
                value={styleObj[postionModel].x}
                min={0}
                max={100}
                step={1}
                updateValue={this.updatePostion}
                incrementValue={this.incrementValue}
                decrementValue={this.decrementValue}
                leftButton={<img src="images/builder/icons/arrow-left.svg" />}
                rightButton={<img src="images/builder/icons/arrow-right.svg" />}
              />
            </div>
            <div style={{ width: '272px', display: 'table' }}>
              <RangeSlider
                attr="y"
                value={styleObj[postionModel].y}
                min={0}
                max={100}
                step={1}
                updateValue={this.updatePostion}
                incrementValue={this.incrementValue}
                decrementValue={this.decrementValue}
                leftButton={<img src="images/builder/icons/arrow-up.svg" />}
                rightButton={<img src="images/builder/icons/arrow-down.svg" />}
              />
            </div>
          </div>
          <div className={css.section}>
            <H6>
              Image Zoom
            </H6>
            <div style={{ width: '272px', display: 'table' }}>
              <RangeSlider
                attr="zoom"
                value={styleObj[postionModel].zoom}
                min={50}
                max={200}
                step={1}
                updateValue={this.updatePostion}
                incrementValue={this.incrementValue}
                decrementValue={this.decrementValue}
                leftButton={<img src="images/builder/icons/minus.svg" />}
                rightButton={<img src="images/builder/icons/plus.svg" />}
              />
            </div>
          </div>
          { isNewTemplate ? (
            <div>
              <H6>
                Color Overlay
              </H6>
              <div style={{ width: '272px', display: 'table' }}>
                <RadioGroup
                  name="colorOverlay"
                  value={styleObj[overlayModel].type}
                  onChange={value => this.updateOverlay('type', value)}
                  className={css.radioGroup}
                >
                  <RadioButton
                    key="single"
                    label="One Color"
                    value="single"
                    theme={RadioTheme}
                    className={css.radioButton}
                  />
                  <RadioButton
                    key="gradient"
                    label="Gradient"
                    value="gradient"
                    theme={RadioTheme}
                    className={css.radioButton}
                  />
                  <RadioButton
                    key="none"
                    label="None"
                    value="none"
                    theme={RadioTheme}
                    className={`${css.radioButton} ${css.radioButtonNoMargin}`}
                  />
                </RadioGroup>
              </div>
              {
                styleObj[overlayModel].type === 'none'
                  ? null : (
                    <div>
                      <div style={{ width: '272px', display: 'table' }}>
                        <ColorRangeSlider
                          attr="opacity1"
                          label="Opacity"
                          value={styleObj[overlayModel].opacity1}
                          color={styleObj[overlayModel].color1}
                          min={0}
                          max={1}
                          step={0.01}
                          updateValue={this.updateOverlay}
                        />
                      </div>
                      {
                        styleObj[overlayModel].type === 'gradient' ? (
                          <div style={{ width: '272px', display: 'table' }}>
                            <ColorRangeSlider
                              attr="opacity2"
                              label="Opacity"
                              value={styleObj[overlayModel].opacity2}
                              color={styleObj[overlayModel].color2}
                              min={0}
                              max={1}
                              step={0.01}
                              updateValue={this.updateOverlay}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  )
              }
            </div>
          ) : null }
        </div>
        <hr className={css.hr} />
        <div className={css.buttonGroup}>
          <ModalButtonGroup
            confirm={onSave}
            confirmText="Save"
            cancel={onCancel}
            cancelText="Cancel"
            canSave
            hideLine
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(editBackground)
