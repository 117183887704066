import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import COLOURS from 'util/colours'
import axios from 'axios'
import requestHeaders from 'request-config'
import moment from 'moment'
import SplitTrafficBadge from './SplitTrafficBadge'
import SplitTrafficBanner from './SplitTrafficBanner'
import SplitTrafficHostDashboard from './SplitTrafficHostDashboard'
import useSplitTraffic from './util/useSplitTraffic'
import SmsWelcomeModal from './SmsWelcomeModal'
import SplitTrafficPartnerActionBanner from './SplitTrafficPartnerActionBanner'

const styles = {
  wrapper: {
    marginBottom: 36,
    width: '100%',
    padding: '0 36px',
    backgroundColor: COLOURS.crystal,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    flexWrap: 'wrap',
  },
  upgradeBanner: {
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    alignItems: 'center',
    maxWidth: 630,
  },
  upgradeBannerSubTitle: {
    margin: '18px 0',
    textAlign: 'center',
  },
}

const LOCAL_STORAGE_WELCOME_MODAL = 'splitTrafficWelcomeModalLastViewed'

function SplitTraffic(props) {
  const {
    classes: css,
    campaign,
    isHost,
    setPartnerSplitTrafficStatus,
    setPartnerBannerStatus,
    setMobileMarketingState,
    canView,
    selectedAction,
    isSmsTrafficPublished,
    mobileMarketingState,
  } = props

  const [isDashboardOpen, setIsDashboardOpen] = useState(false)
  const [bannerStatus, setBannerStatus] = useState(null)
  const [bannerStats, setBannerStats] = useState({})
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)

  const splitTrafficData = useSplitTraffic(campaign.id)

  const {
    partners,
    onUpdatePartnerField,
    isLoading: splitTrafficIsLoading,
    traffic,
  } = splitTrafficData

  const updateBanner = useCallback((status, stats = null) => {
    setBannerStatus(status)
    if (stats !== null) {
      setBannerStats(stats)
    }
  }, [])
  const getPartnerBannerStatus = partner => {
    let { status } = partner

    if (status === 'active') {
      status = partner.isPostEntryReady ? 'ready' : 'notReady'
      // the above only updates with a page refresh
      // this makes it update live
      if ( selectedAction === 'mobileMarketing' && isSmsTrafficPublished !== null) {
        status = isSmsTrafficPublished ? 'ready' : 'notReady'
      }
    }
    const partnerStatuses = {
      partnerRequested: 'partnerBannerRequested',
      invited: 'partnerBannerOffered',
      declined: 'partnerBannerDeclined',
      ready: 'partnerBannerShared',
      notReady: 'partnerBannerInactive',
    }
    return partnerStatuses[status]
  }

  useEffect(() => {
    if (splitTrafficIsLoading) return
    if (isHost) {
      updateBanner(traffic.host < 100 ? 'active' : 'inactive', traffic)
    } else {
      const [partner] = partners
      let partnerBannerStats = null
      if (partner.status !== 'notInvited') {
        partnerBannerStats = {
          [getPartnerBannerStatus(partner)]: partner.traffic,
        }
      }
      updateBanner(partner.status, partnerBannerStats)
    }
    let welcomeModalLastViewed = localStorage.getItem(LOCAL_STORAGE_WELCOME_MODAL)
    welcomeModalLastViewed = welcomeModalLastViewed && moment(welcomeModalLastViewed)
    if (
      traffic.host === 100
      && (!welcomeModalLastViewed || moment().diff(welcomeModalLastViewed, 'days') > 30)
    ) {
      setShowWelcomeModal(true)
    }
    if (!isHost && partners) {
      const [partner] = partners
      setPartnerBannerStatus(getPartnerBannerStatus(partner))
    }
  }, [splitTrafficIsLoading, traffic, isHost, isSmsTrafficPublished, selectedAction])

  const onCloseWelcomeModal = () => {
    setShowWelcomeModal(false)
    localStorage.setItem(LOCAL_STORAGE_WELCOME_MODAL, moment().format())
  }

  const scrollToConversionAction = () => {
    const conversionActionEl = document.getElementById('conversion-action')
    const headerOffset = 74 // 64px NavBar height + a 10px gap
    const elementPosition = conversionActionEl.getBoundingClientRect().top
    const offsetPosition = elementPosition - headerOffset
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
  }

  const onClickSetupWelcomeModal = () => {
    onCloseWelcomeModal()
    if (isHost) scrollToConversionAction()
    else setMobileMarketingState({ showMessageType: 'request' })
  }

  const getCampaignStatus = () => {
    // When campaign is just created, campaign.hasStarted is missing
    if (!campaign.hasStarted || !campaign.hasStarted()) {
      return 'notStarted'
    }
    if (campaign.isRunning()) {
      return 'running'
    }
    return 'completed'
  }

  // action can be accept || decline || request
  const partnerBannerAction = async action => {
    const [partner] = partners
    if (['accept', 'decline'].includes(action)) {
      await axios.put(
        `/partnerships/split-traffic-invite/${campaign.id}`,
        { response: action === 'accept' },
        { headers: requestHeaders() }
      )
      onUpdatePartnerField(partner.id, 'status', action === 'accept' ? 'active' : 'declined')
      setPartnerSplitTrafficStatus(action === 'accept' ? 'active' : 'declined')
    }
    if (action === 'request') {
      onUpdatePartnerField(partner.id, 'status', 'partnerRequested')
      setPartnerSplitTrafficStatus('partnerRequested')
    }
  }

  const renderBadges = () => {
    if (isHost && bannerStatus === 'inactive') return null
    return Object.keys(bannerStats).map(stat => (
      <SplitTrafficBadge
        key={stat}
        status={stat}
        isHost={isHost}
        percentage={bannerStats[stat]}
      />
    ))
  }
  
  let postEntryStatus = ''
  if (!isHost && partners.length !== 0) {
    [{ postEntryStatus }] = partners
  }

  return (
    <>
      <div className={css.wrapper}>
        {
          canView && bannerStatus && (
            <SplitTrafficBanner
              isHost={isHost}
              status={bannerStatus}
              setIsDashboardOpen={setIsDashboardOpen}
              setMobileMarketingState={setMobileMarketingState}
              isDashboardOpen={isDashboardOpen}
              renderBadges={renderBadges()}
              campaign={campaign}
              partnerBannerAction={partnerBannerAction}
              mobileMarketingState={mobileMarketingState}
            />
          )

        }
        {
          canView && isHost && (
            <SplitTrafficHostDashboard
              open={isDashboardOpen}
              campaignName={campaign.name}
              campaignUid={campaign.uid}
              campaignStatus={getCampaignStatus()}
              updateBanner={updateBanner}
              splitTrafficData={splitTrafficData}
            />
          )
        }
      </div>
      { !isHost && selectedAction === 'mobileMarketing' && isSmsTrafficPublished !== true
        && (
          <SplitTrafficPartnerActionBanner
            status={Object.keys(bannerStats)[0]}
            postEntryStatus={postEntryStatus}
          />
        )
      }
      {showWelcomeModal && (
        <SmsWelcomeModal
          isHost={isHost}
          close={onCloseWelcomeModal}
          confirm={onClickSetupWelcomeModal}
        />
      )}
    </>
  )
}

SplitTraffic.propTypes = {
  classes: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  isHost: PropTypes.bool.isRequired,
  setPartnerSplitTrafficStatus: PropTypes.func.isRequired,
  setPartnerBannerStatus: PropTypes.func.isRequired,
  setMobileMarketingState: PropTypes.func.isRequired,
  canView: PropTypes.bool.isRequired,
  isSmsTrafficPublished: PropTypes.bool.isRequired,
  mobileMarketingState: PropTypes.object.isRequired,
  selectedAction: PropTypes.string.isRequired,
}

export default React.memo(injectCSS(styles)(SplitTraffic))
