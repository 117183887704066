import React from 'react'
import * as ReactRedux from 'react-redux'
import validUrl from 'valid-url'

import Dialog from 'react-toolbox/lib/dialog'

import SocialLabel from 'visual-components/brandProfile/social-label'
import EditPen from 'visual-components/util/EditPen'
import Field from 'util/components/field'
import NumberField from 'util/components/number-field'
import { makeNumbersPretty } from 'util/makeNumbersPretty'
import { H2, H4 } from 'visual-components/util/texts'

import dialogTheme from 'css/themes/dialogs/formModal.css'

import BrandSocialDispatcher from 'dispatchers/brand-social-dispatcher'

const mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    currentBrandForm: state.currentBrandForm,
  }
}

class SocialItem extends React.Component {
  render() {
    return (
      <div className={dialogTheme.inputColumn}>
        {this.props.children}
      </div>
    )
  }
}


class EditSocial extends React.Component {
  constructor(props) {
    super(props)

    this.handleToggle = this.handleToggle.bind(this)
    this.updateSocialData = this.updateSocialData.bind(this)
    this.updateHandleOrUrl = this.updateHandleOrUrl.bind(this)
    this.convertToHandle = this.convertToHandle.bind(this)

    this.state = {
      active: false
    }

    this.actionMap = {
      facebookUrl: 'retrieveFacebookLikes',
      twitterHandle: 'retrieveTwitterFollowers',
    }
  }

  handleToggle() {
    this.setState({
      active: !this.state.active
    })
  }

  convertToHandle(attr, val) {
    // let path = getLocation(val).pathname
    /* in case of need for more granular parsing
    let fbUrl = /^((http[s]?):\/)?\/(www.|)facebook.com?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/
    let twitterUrl = /^((http[s]?):\/)?\/(www.|)twitter.com?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/
    */
    const parts = val
      .replace(/https?:\/\//, '')
      .replace(/(www\.)?.*?\//, '')
      .replace(/user\//, '')
      .split('/')

    const path = parts[0] || ''

    return path.replace(/\W/g, '')
  }

  updateHandleOrUrl(attr, val) {
    if (validUrl.isWebUri(val)) {
      this.updateSocialData(attr, this.convertToHandle(attr, val))
    } else {
      this.updateSocialData(attr, val)
    }
  }

  updateSocialData(attr, value) {
    const action = this.actionMap[attr]

    if (action) {
      BrandSocialDispatcher[action](value)
    } else {
      BrandSocialDispatcher.updateAttrPersist(attr, value)
    }
  }

  render() {
    var {
      currentBrand,
      currentBrandForm,
      hovered
    } = this.props

    return (
      <div style={{
        visibility: hovered ? 'inherit' : 'hidden'
      }}>
        <EditPen onClick={ this.handleToggle } />
        <Dialog
          theme={ dialogTheme }
          active={ this.state.active }
          onEscKeyDown={ this.handleToggle }
          onOverlayClick={ this.handleToggle }
          title='Edit Social Following'
        >
          <div style={{ marginBottom: '16px' }}>
            <H4 multiline>
              Add your social channels and we’ll update your social following - no "@" sign or URL required.
            </H4>
          </div>

          <img
            onClick={ this.handleToggle }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />

          <SocialItem>
            <Field
              CustomLabel={SocialLabel}
              blur={e => { this.updateHandleOrUrl('facebookUrl', e.target.value) }}
              placeholder="handle"
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="facebookUrl"
              endpoint="/brands-social"
              label="Facebook Handle"
            />
          </SocialItem>

          <SocialItem>
            <NumberField
              CustomLabel={SocialLabel}
              blur={e => { this.updateSocialData('facebookFollowers', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="facebookFollowers"
              endpoint="/brands-social"
              socialValue={makeNumbersPretty(currentBrand.facebookFollowers)}
              label="Facebook"
              sublabel= "Enter your follower count."
            />
          </SocialItem>

          <SocialItem>
            <Field
              CustomLabel={SocialLabel}
              blur={e => { this.updateHandleOrUrl('youtubeUrl', e.target.value) }}
              placeholder="handle"
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="youtubeHandle"
              endpoint="/brands-social"
              label="Youtube Handle"
            />
          </SocialItem>

          <SocialItem>
            <NumberField
              CustomLabel={SocialLabel}
              blur={e => { this.updateSocialData('youtubeFollowers', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="youtubeFollowers"
              endpoint="/brands-social"
              socialValue={makeNumbersPretty(currentBrand.youtubeFollowers)}
              label="YouTube"
              sublabel="Enter your subscriber count."
            />
          </SocialItem>

          <SocialItem>
            <Field
              CustomLabel={SocialLabel}
              blur={e => { this.updateHandleOrUrl('instagramHandle', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="instagramHandle"
              placeholder="handle"
              endpoint="/brands-social"
              label="Instagram Handle"
            />
          </SocialItem>

          <SocialItem>
            <NumberField
              CustomLabel={SocialLabel}
              blur={e => { this.updateSocialData('instagramFollowers', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="instagramFollowers"
              endpoint="/brands-social"
              socialValue={makeNumbersPretty(currentBrand.instagramFollowers)}
              label="Instagram"
              sublabel="Enter your follower count."
            />
          </SocialItem>

          <SocialItem>
            <Field
              CustomLabel={SocialLabel}
              blur={e => { this.updateHandleOrUrl('twitterHandle', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="twitterHandle"
              placeholder="handle"
              endpoint="/brands-social"
              label="Twitter Handle"
            />
          </SocialItem>

          <SocialItem>
            <NumberField
              CustomLabel={SocialLabel}
              blur={e => { this.updateSocialData('twitterFollowers', e.target.value) }}
              dataModel={ currentBrand }
              formModel={ currentBrandForm }
              attrName="twitterFollowers"
              endpoint="/brands-social"
              socialValue={makeNumbersPretty(currentBrand.twitterFollowers)}
              label="Twitter"
              sublabel="Enter your follower count."
            />
          </SocialItem>

          <SocialItem>
            <Field
              CustomLabel={SocialLabel}
              blur={e => { this.updateHandleOrUrl('pinterestHandle', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="pinterestHandle"
              placeholder="handle"
              endpoint="/brands-social"
              label="Pinterest Handle"
            />
          </SocialItem>

          <SocialItem>
            <NumberField
              CustomLabel={SocialLabel}
              blur={e => { this.updateSocialData('pinterestFollowers', e.target.value) }}
              dataModel={currentBrand}
              formModel={currentBrandForm}
              attrName="pinterestFollowers"
              endpoint="/brands-social"
              socialValue={makeNumbersPretty(currentBrand.pinterestFollowers)}
              label="Pinterest"
              sublabel="Enter your follower count."
            />
          </SocialItem>


          <div style={{ marginBottom: '8px' }}>
            <H2>
              { `TOTAL: ${makeNumbersPretty(currentBrand.socialMediaImpressions)}` }
            </H2>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default ReactRedux.connect(mapState)(EditSocial)
