export default {
  fbLike: 'Facebook Likes',
  fbShare: 'Facebook Shares',
  instagramFollow: 'Instagram Follows',
  instagramVisit: 'Instagram Visits',
  twitterShare: 'Twitter Share',
  twitterFollow: 'Twitter Follows',
  youtubeSubscribe: 'YouTube Subscribes',
  youtubeWatch: 'YouTube Views',
  pinterestFollow: 'Pinterest Follows',
  pinterestPin: 'Pinterest Pins',
  snapchatFollow: 'Snapchat Follows',
  appDownload: 'App Downloads'
}
