import React from 'react'
import { connect } from 'react-redux'

import OpportunityProfileBase from './opportunity-profile-base'

import { bindActionCreators } from 'redux'
import * as OpportunityActions from 'actions/opportunities-actions'

const mapStateToProps = ({ opportunity, currentBrand }) => ({
  opportunity,
  currentBrand,
})

const mapDispatchToProps = dispatch => ({
  opportunityActions: bindActionCreators(OpportunityActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  OpportunityProfileBase
)
