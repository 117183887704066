import React from 'react'
import { withRouter } from 'react-router-dom'
import { object, array, func } from 'prop-types'
import { P, H3 } from 'visual-components/util/texts'
import IntegrationsMap from 'visual-components/integrations/integrations-map'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import IntegrationItem from './integration-item'

class ChooseIntegration extends React.Component {
  static propTypes = {
    deal: object.isRequired,
    activeIntegrations: array.isRequired,
    toggleModal: func.isRequired,
    history: object.isRequired,
  }

  state = {
    selectedIntegration: null,
  }

  select = integration => {
    this.setState({
      selectedIntegration: integration,
    })
  }

  goTo = () => {
    const { deal, history } = this.props
    const { selectedIntegration } = this.state

    history.push(`/partnerships/deal/${deal.id}/content-integration/${selectedIntegration}`)
  }

  render() {
    const { activeIntegrations, toggleModal } = this.props
    const { selectedIntegration } = this.state

    let integrationItems = IntegrationsMap.filter(integration => integration.mediaMarket)
    integrationItems = integrationItems.map((integration, idx) => (
      <div
        key={integration.key}
        onClick={() => this.select(integration.key)}
      >
        <IntegrationItem
          idx={idx}
          integration={integration}
          active={activeIntegrations.includes(integration.key)}
          selected={selectedIntegration === integration.key}
          noBoxShadow
        />
      </div>
    ))

    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '16px' }}>
            <H3>
              Choose Email Service Provider
            </H3>
          </div>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <P>
            If you haven’t integrated an ESP yet, you will be prompted to do it first.
          </P>
        </div>

        <div style={{ textAlign: 'center' }}>
          { integrationItems }
        </div>

        <ModalButtonGroup
          canSave={!!selectedIntegration}
          confirm={this.goTo}
          confirmText="Next"
          cancel={toggleModal}
          hideLine
        />
      </div>
    )
  }
}

export default withRouter(ChooseIntegration)
