import React, { Component } from 'react';
import BaseBanner from '../util/banner-base';
import MobileHeader from './../mobile-header/MobileHeader';

import theme from 'css/themes/mobile/home/banner.css';

class ResourcesBanner extends Component {
  render() {
    return(
      <div id={ theme.resources }>
        <MobileHeader lightBackground={ true } />
        <BaseBanner
          title={ 'Resource Library' }
          imgSrc={ '/images/login/mobile/resources/education-icon.png' } />
      </div>
    )
  }
};

export default ResourcesBanner;
