import React from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import * as ReactRedux from 'react-redux';

import CampaignBuilderSection from 'campaigns/builder/components/campaign-builder-section';
import TermsHeader from 'campaigns/builder/customize/terms/terms-header';
import TermsMap from 'campaigns/builder/customize/terms/terms-map';
import TermsDispatcher from 'dispatchers/terms-dispatcher';
import Spinner from 'util/components/spinner'
import * as DialogActions from 'actions/dialog-actions'
import * as AnalyticsActions from 'actions/analytics-actions'


const mapState = function (state) {
  return {
    currentCampaign: state.currentCampaign,
    campaignTermsDetails: state.campaignTermsDetails,
    campaignTermsDetailsForm: state.campaignTermsDetailsForm
  }
}

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
  analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
})

class TermsContainer extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.params.subSection,
      termStep: _.find(TermsMap, { key: this.props.params.subSection }).component,
      forcedLeave: false,
      modalOpen: false
    }

    TermsDispatcher.loadCampaignTerms(props.currentCampaign.id)
    this.navigate = this.navigate.bind(this);
    this.routerWillLeave = this.routerWillLeave.bind(this);
  }

  componentDidMount() {
    document.title = 'Terms - DojoMojo';
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.match.params.subSection,
      termStep: _.find(TermsMap, { key: nextProps.match.params.subSection }).component
    })
  }

  routerWillLeave(nextLocation) {
    const { match, currentCampaign: { campaignTerms } } = this.props
    const section = match.params.subSection
    const status = campaignTerms.termsStatus
    const termsUnsaved = campaignTerms.termsUnsaved
    const terms = campaignTerms

    if (section == 'info' && !terms.termsGeneratedAt) {
      return JSON.stringify({
        heading: `Rules Not Generated`,
        message: `Your Official Rules document has not been generated. Are you sure you want to leave?`,
      })
    } else if (section == 'info' && terms.termsGeneratedAt && (status != 'generated' && status !== 'published')) {
      return JSON.stringify({
        heading: `Rules Not Re-generated`,
        message: `Your Official Rules document has not been re-generated. Are you sure you want to leave?`,
      })
    } else if (section == 'edit' && status == 'neverCreated') {
      return JSON.stringify({
        heading: `Draft Not Saved`,
        message: `Your Official Rules draft has not been saved. Are you sure you want to leave?`,
      })
    } else if ((section == 'edit' && termsUnsaved)) {
      return JSON.stringify({
        heading: status === 'published' ? `Rules Not Updated` : `Draft Not Saved`,
        message: status === 'published' ? `Your Official Rules document has not been updated. Are you sure you want to leave?` : `Your Official Rules draft has not been saved. Are you sure you want to leave?`,
      })
    } else if ((section == 'review' && !nextLocation.pathname.includes('terms/edit') && !terms.termsPublishedAt) || (section == 'edit' && !terms.termsPublishedAt)) {
      return JSON.stringify({
        heading: `Rules Not Published`,
        message: `Your Official Rules have not yet been published live. Are you sure you want to leave?`
      })
    } else if ((section == 'review' || section == 'edit') && terms.termsPublishedAt && status != 'published') {
      return JSON.stringify({
        heading: `Rules Not Updated`,
        message: `Your Official Rules document has not been updated. Are you sure you want to leave?`
      })
    } else {
      return true
    }
  }

  navigate(item) {
    const { history, currentCampaign } = this.props
    const key = item.key || item;
    history.push(`/builder/${currentCampaign.id}/terms/${key}`)
  }

  render() {
    const {
      dialogActions,
      currentCampaign,
      campaignTermsDetails,
      currentBrand,
      campaignTermsDetailsForm,
      analyticsActions
    } = this.props

    const { selected } = this.state

    if (!currentCampaign.campaignTerms.id) {
      return <Spinner />
    }
    return(
      <CampaignBuilderSection
        subSection={currentCampaign.section}
      >
      <Prompt
        message={this.routerWillLeave}
      />
        <TermsHeader
          currentCampaign={currentCampaign}
          campaignTermsDetails={campaignTermsDetails}
          navigate={this.navigate}
          selected={selected}
          analyticsActions={analyticsActions}
          openHostRulesModal={() => dialogActions.openDialog('officialRulesHostReminderModal')}
          closeHostRulesModal={() => dialogActions.closeDialog('officialRulesHostReminderModal')}
        />

        <div className="row">
          <div className="col-sm-12">
            <this.state.termStep
              currentBrand={currentBrand}
              currentCampaign={currentCampaign}
              campaignTermsDetails={campaignTermsDetails}
              campaignTermsDetailsForm={campaignTermsDetailsForm}
            />
          </div>
        </div>
      </CampaignBuilderSection>
    )
  }
}

export default withRouter(ReactRedux.connect(mapState, mapDispatchToProps)(TermsContainer))
