import React from 'react';
import _ from 'lodash'
import moment from 'moment-timezone'

import SecondaryButton from 'util/components/buttons/secondary-button';
import TertiaryButton from 'util/components/buttons/tertiary-button';
import Dropdown from 'util/components/dropdown';
import COLOURS from 'util/colours';
import { Link } from 'react-router-dom';
import { H4 } from 'visual-components/util/texts';

class NavPrimary extends React.Component {
  navigate = () => {
    var nextStep;

    if (this.props.step.subSteps) {
      if (this.props.isHost) {
        if (this.props.step.key == 'customize' && this.props.currentCampaign.desktopElements
          && this.props.currentCampaign.desktopElements.landerVersion) {
          nextStep = this.props.step.subSteps[1].key
        } else {
          nextStep = this.props.step.subSteps[0].key
        }
      } else {
        nextStep = _.find(this.props.step.subSteps, function (item) {
          return !item.host;
        }).key;
      }
    } else {
      nextStep = this.props.step.key;
    }
    this.props.navigate(nextStep)
  }

  render() {
    return (
      <li onClick={this.navigate}
        style={{
          paddingRight: '20px',
          paddingLeft: this.props.paddingLeft ? this.props.paddingLeft : '20px',
          cursor: 'pointer',
          display: 'table',
          height: '71px'
      }}>
        <small style={{
          color: this.props.selected ? COLOURS.white : COLOURS.cornFlower,
          borderTopWidth: '5px',
          borderTopStyle: 'solid',
          borderTopColor: 'transparent',
          borderBottomWidth: '5px',
          borderBottomStyle: 'solid',
          borderBottomColor: this.props.selected ? COLOURS.neonMint : 'transparent',

          display: 'table-cell',
          verticalAlign: 'middle',
          fontFamily: "'Larsseit'",
          fontSize: '16px',
      }}>
          { this.props.title }
        </small>
      </li>
    )
  }
}

class NavSecondary extends React.Component {
  navigate = () => {
    this.props.navigate(this.props.step.key)
  }

  render() {
    return (
      <li onClick={this.navigate}
        style={{
          paddingRight: '40px',
          paddingLeft: this.props.paddingLeft ? this.props.paddingLeft : '40px',
          cursor: 'pointer',
          display: 'table',
          height: '50px'
      }}>
        <small style={{
          color: COLOURS.ink,
          display: 'table-cell',
          verticalAlign: 'middle',
          borderTopWidth: '5px',
          borderTopStyle: 'solid',
          borderTopColor: 'transparent',
          borderBottomWidth: '5px',
          borderBottomStyle: 'solid',
          borderBottomColor: this.props.selected ? COLOURS.neonMint : 'transparent',
          fontFamily: this.props.selected ? "'Larsseit'" : "'Larsseit-Light'",
          fontSize: '16px',
          lineHeight: '20px'
        }}>
          { this.props.title }
        </small>
      </li>
    )
  }
}


class BuilderHeader extends React.Component{
  render() {
    const { currentCampaign, isSolo, viewMap = [] } = this.props
    let steps = LANGUAGE.builder.steps;

    var secondary = [];

    const { isHost, endDate } = currentCampaign

    const solo = isSolo || currentCampaign.isSolo
    const campaignOlderThan30Days = moment().diff(currentCampaign.endDate, 'days') > 30
    const pages = viewMap.filter(page => !solo || (solo && !page.partnerships))
    const exitPage = solo ? 'page-hosting' : 'partner'
    var primary = _.map(pages, (item, idx) => {
      if (!isHost && item.host) {
        return;
      }

      // Only allow the Partners page to be viewed after 30 days
      if (item.key !== 'partner' && campaignOlderThan30Days) return

      let selected = item.key === this.props.headerNavPrimary;

      // Don't show substep nav if there are no substeps or after 30 days
      if (selected && item.subSteps && !campaignOlderThan30Days) {
        var partnerItems = _.filter( item.subSteps, function(sub) {
          return !( !isHost && sub.host )
        })

        secondary = _.map(partnerItems, (sub, idx) => {
          let secondarySelected = sub.key === this.props.headerNavSecondary;
          return (
            <NavSecondary
              key={idx}
              step={sub}
              selected={secondarySelected}
              navigate={this.props.navigate}
              paddingLeft={ idx === 0 ? '0px' : null }
              title={steps[item.key].subSteps[sub.key].title} />
          )
        });
      }
      return (
        <NavPrimary
          key={idx}
          selected={selected}
          isHost={isHost}
          step={item}
          navigate={this.props.navigate}
          paddingLeft={ idx === 0 ? '0px' : null }
          title={steps[item.key].title}
          currentCampaign = {currentCampaign} />
      )
    })

    return(
      <div>
        <nav className="navbar navbar-default navbar-fixed-top"
          style={{
            zIndex: 200,
            minHeight: '36px',
            borderBottom: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            marginBottom: '0',
            borderRadius: '0',
            backgroundColor: COLOURS.white,
            zIndex: '200'
        }}>
          <div className="container">
            <div className="navbar-header pull-left">
              <i className="fa fa-pencil" aria-hidden="true"
                style={{
                  display: 'inline-block',
                  marginTop: '8px',
                  marginRight: '5px',
                  fontSize: '16px',
                  color: COLOURS.ink
              }}/>
              <div style={{
                display: 'inline-block',
                marginTop: '10px',
                marginBottom: '8px',
              }}>
                <H4>
                  {`${currentCampaign.solo ? 'Solo ' : ''}Sweepstakes Builder`}
                </H4>
              </div>
            </div>
            <div className="navbar-header absolute-right">
              <div style={{
                minWidth: '167px',
                textAlign: 'center'
              }}>
                <a
                  href={!!currentCampaign.id
                      ? `/app/partnerships/campaign/${currentCampaign.id}/quickview`
                      : `/app/sweepstakes/${solo ? 'solo ' : 'partnership'}`
                  }
                  style={{
                    height: '100%',
                    display: 'inline-flex'
                  }}
                >
                  <i className="fa fa-sign-out" aria-hidden="true"
                    style={{
                      display: 'inline-block',
                      marginTop: '8px',
                      marginRight: '5px',
                      fontSize: '18px',
                      color: COLOURS.ink
                  }}/>
                  <div style={{
                    display: 'inline-block',
                    marginTop: '10px',
                    marginBottom: '8px',
                  }}>
                    <H4>
                      Exit
                    </H4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <nav className="navbar navbar-fixed-top"
          style={{
            zIndex: 200,
            minHeight: '36px',
            borderBottom: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            marginBottom: '0',
            borderRadius: '0',
            zIndex: '200',
            backgroundColor: COLOURS.cerulean,
            minHeight: '70px',
            marginTop: '35px'
        }}>
          <div className="container">
              <ul className="nav navbar-nav">
                {primary}
              </ul>
            <ul className="nav navbar-nav absolute-right">
              <li onClick={ () => this.props.headerNavPrimary === exitPage ? this.props.exit(true) : this.props.next() }
                style={{
                  height: '71px',
                  width: '167px',
                  display: 'table',
                  backgroundColor: COLOURS.azure,
                  cursor: 'pointer'
              }}>
                <small style={{
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  color: COLOURS.white,
                  fontFamily: 'Larsseit',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}>
                  { this.props.headerNavPrimary === exitPage ? "Finish" : "Next"}
                </small>
              </li>
            </ul>
          </div>
        </nav>
        { secondary.length > 0 ?
          <nav className="navbar navbar-fixed-top builder-secondary-nav"
            style={{
              zIndex: 200,
              minHeight: '36px',
              borderBottom: 'none',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              marginBottom: '0',
              borderRadius: '0',
              backgroundColor: COLOURS.white,
              zIndex: '200',
              borderBottomColor: COLOURS.lightGrey,
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              marginTop: '105px'
          }}>
            <div className="container">
              <ul className="nav navbar-nav">
                {secondary}
              </ul>
            </div>
          </nav> : null}
      </div>
    )
  }
};

export default BuilderHeader;
