import React, { Component } from 'react'
import _ from 'lodash'

import {
  string,
  func,
  object,
  element,
} from 'prop-types'

import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import RadioTheme from 'css/themes/Radio.css'

class RadioList extends Component {
  static propTypes = {
    name: string.isRequired,
    customOption: element,
  }

  static defaultProps = {
    customOption: null,
  }

  static contextTypes = {
    handleChangeImmediately: func.isRequired,
    searchModel: object.isRequired,
  }

  onChange = value => {
    const { handleChangeImmediately } = this.context

    handleChangeImmediately(this.props.name, { value })
  }

  renderRadioButtons = (opt, idx) => {
    const { label, value } = opt

    return (
      <RadioButton
        key={idx}
        label={label}
        value={value}
        theme={RadioTheme}
      />
    )
  }

  render() {
    const { name, customOption } = this.props
    const { searchModel } = this.context
    const { value, options } = searchModel[name]

    const radioButtons = _.map(options, this.renderRadioButtons)

    return (
      <RadioGroup name={name} value={value} onChange={this.onChange}>
        { radioButtons }
        { value === 'custom' && customOption }
      </RadioGroup>
    )
  }
}

export default RadioList
