import React from 'react'
import { object } from 'prop-types'
import d3 from 'd3'
import { Input } from 'visual-components/util/form'
import { H3, H6 } from 'visual-components/util/texts'
import { isPositive, isPositiveInteger, cannotExceed } from 'visual-components/util/form/validations'
import { atLeast } from 'visual-components/util/form/validations'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'

class CPCPricingInfo extends React.Component {
  static propTypes = {
    data: object.isRequired,
  }

  render() {
    const {
      data: {
        maxQuantity,
        costPerUnit,
      },
    } = this.props

    const maxRev = maxQuantity && costPerUnit
      ? d3.format('$,.2f')(maxQuantity * costPerUnit) : '$0.00'

    const maxGreaterThanMin = (value, model) => {
      if (+value <= +model.minQuantity) {
        throw 'Maximum must be greater than Minimum'
      }
    }

    return (
      <div className={SellMediaModalTheme.dealTypeContainer}>
        <div className={SellMediaModalTheme.pricingInputFlex}>
          <div className={SellMediaModalTheme.pricingInput}>
            <Input
              name="minQuantity"
              label="Minimum Clicks"
              type="number"
              placeholder="500"
              required
              validations={[isPositiveInteger]}
            />
          </div>
          <div className={SellMediaModalTheme.pricingInput}>
            <Input
              name="maxQuantity"
              label="Maximum Clicks"
              type="number"
              placeholder="4,000"
              required
              validations={[
                isPositiveInteger,
                maxGreaterThanMin,
                cannotExceed(1e6),
              ]}
            />
          </div>
          <div className={SellMediaModalTheme.pricingInput}>
            <Input
              name="costPerUnit"
              label="Cost Per Click"
              placeholder="$2.00"
              required
              validations={[
                isPositive,
                atLeast(0.01, () => 'Must be at least $0.01'),
                cannotExceed(10000, () => 'Cannot exceed $10,000'),
              ]}
            />
          </div>
        </div>

        <div className={SellMediaModalTheme.dealRevDispaly}>
          <H6>Max Potential Value:</H6>
          <H3>{maxRev}</H3>
        </div>
      </div>
    )
  }
}


export default CPCPricingInfo
