import React from 'react'
import moment from 'moment-timezone'
import { renderToString } from 'react-dom/server'
import d3 from 'd3'
import Row from 'visual-components/util/no-padding-grid/row'
import TooltipCard from 'visual-components/analyticsDashboard/tooltip/tooltip-card'
import MediaDateRangePicker from './media-date-range-picker'

const EntriesTooltipFactory = React.createFactory(TooltipCard)

const renderLineGraph = function (options) {
  const element = document.getElementById('graph')
  while (element && element.firstElementChild) {
    element.firstElementChild.remove()
  }
  const dates = options.dataPoints.map(item => item.period)

  options.dataPoints.forEach((item, idx) => {
    item.x = idx
  })

  const graph = new Contour({
    el: '.multi-line',
    xAxis: {
      type: 'ordinal',
    },
    chart: {
      padding: {
        left: 33,
      },
      height: 300,
      width: 600,
    },
    tooltip: {
      opacity: 1,
      formatter: (d) => {
        if (options.hourly) {
          d.formatMomentHourly = true
        } else {
          d.formatMoment = true
        }
        return renderToString(EntriesTooltipFactory(d))
      },
    },
  })
    .cartesian()
    .yAxisLines()
    .line(options.dataPoints)
    .tooltip()

  if (!options.isFixedDeal && options.selectedTab.includes('Click') && options.cumulative && !options.hourly) {
    graph.minMaxThreshold(null, options.minMaxThreshold)
  }

  graph.dateBasedXAxis(null, {
    n: dates.length,
    shiftLabel: true,
    formattedDates: dates.map(date => {
      if (options.hourly) {
        return moment(date).format('MMM DD, h a')
      }
      return moment(date).format('MMM DD')
    }),
  })
    .render()

  return graph
}

class MediaDetailsGraph extends React.Component {
  componentDidMount() {
    renderLineGraph(this.props)
  }

  componentDidUpdate() {
    renderLineGraph(this.props)
  }

  render() {
    const {
      startDate,
      endDate,
      timeFrame,
      selectDate,
      dealStartDate,
      dealEndDate,
    } = this.props

    return (
      <Row style={{ marginTop: '16px' }}>
        <div style={{ width: '604px', height: '184px', paddingBottom: '8px' }}>
          <div className="multi-line" id="graph" />
        </div>

        <div style={{ marginTop: '115px' }}>
          <MediaDateRangePicker
            startDate={startDate}
            dealStartDate={dealStartDate}
            dealEndDate={dealEndDate}
            endDate={endDate}
            timeFrame={timeFrame}
            selectDateFunc={selectDate}
          />
        </div>

      </Row>
    )
  }
}

export default MediaDetailsGraph
