import React from 'react';
import AdminRow from './admin-row';

class TopTen extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      cols,
      rows,
      dates
    } = this.props;

    const rowDisplay = rows.map(row => <AdminRow vals={row.vals} title={row.title} />);

    return (
      <tbody>
        <AdminRow title={title} className="info" vals={dates} />
        { rowDisplay }
      </tbody>
    );
  }
}

TopTen.defaultProps = {
  rows: [],
  cols: 13,
  title: ""
}

export default TopTen;
