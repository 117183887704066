import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import NavIconLink from 'visual-components/navBar/nav-icon-link'

import * as NotificationActions from 'actions/notification-actions'
import * as metrics from 'util/metrics'

import MessagesDropdownMenu from './messages-dropdown-menu'

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
})

class NavMessagesDropdown extends React.Component {
  state = {
    messagesMenuActive: false,
  }

  toggleMessagesMenu = e => {
    const { messagesMenuActive } = this.state
    e.preventDefault()

    if (!messagesMenuActive) {
      if (window.location.pathname === '/app/') {
        metrics.create('homePageNavBarClickedMessages')
      }
    }

    this.setState(prevState => ({
      messagesMenuActive: !prevState.messagesMenuActive,
    }))
  }

  handleMessagesMenuHide = () => {
    this.setState({
      messagesMenuActive: false,
    })
  }

  render() {
    const {
      notificationData,
      unreadNotificationCount,
      messagesData,
      unreadCount,
      notificationActions,
      loading,
    } = this.props
    const { read, readAll } = notificationActions
    const { messagesMenuActive } = this.state

    return (
      <div style={{ display: 'inline-flex' }}>
        <NavIconLink
          onClick={this.toggleMessagesMenu}
          src="/images/icons/menu-icons/mail-icon.svg"
          clickedSrc="/images/icons/menu-icons/mail-icon.svg"
          loadingSrc="/images/icons/menu-icons/loading-icon.svg"
          loading={loading}
          menuOpen={messagesMenuActive}
          iconMarginTop="-1px"
        />
        <MessagesDropdownMenu
          notificationData={notificationData.slice(0, 4)}
          unreadNotificationCount={unreadNotificationCount}
          messagesData={messagesData}
          unreadCount={unreadCount}
          handleNotificationMenuHide={this.handleMessagesMenuHide}
          messagesMenu={messagesMenuActive}
          notificationRead={read}
          notificationsReadAll={readAll}
        />
      </div>
    )
  }
}

NavMessagesDropdown.propTypes = {
  notificationData: PropTypes.array,
  messagesData: PropTypes.array,
  unreadNotificationCount: PropTypes.number,
  unreadCount: PropTypes.number,
  loading: PropTypes.bool,
  notificationActions: PropTypes.object.isRequired,
}

NavMessagesDropdown.defaultProps = {
  notificationData: [],
  loading: false,
  unreadNotificationCount: 0,
  messagesData: [],
  unreadCount: 0,
}

export default connect(null, mapDispatchToProps)(NavMessagesDropdown)
