import React from 'react';

import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';
import _ from 'lodash';
import * as ReactRedux from 'react-redux';
import PartnershipDispatcher from 'dispatchers/partnership-invites-dispatcher';
import Spinner from 'util/components/spinner';

import RichEditor from 'visual-components/richEditor/RichEditor';
import {stateToHTML} from 'draft-js-export-html';
import MessageSlider from 'visual-components/messaging/message-slider';

class BrandApplication extends React.Component {
  constructor(props) {
    super(props);
    this.closeSlider = this.closeSlider.bind(this);
    this.apply = this.apply.bind(this);
  }

  closeSlider() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        modalBrandApplicationCampaign: {},
      }
    })
  }

  apply(contentState) {
    const { campaign } = this.props;

    PricingDispatcher.check('sweepstakes.partnershipApplication.create', function () {
      store.dispatch({
        model: 'conversations',
        type: 'UPDATE_ATTR',
        data: {
          loading: true
        }
      })

      let htmlContent = stateToHTML(contentState)

      PartnershipDispatcher.apply(campaign, htmlContent, (err, invite) => {
        store.dispatch({
          model: 'conversations',
          type: 'UPDATE_ATTR',
          data: {
            loading: false,
            modalBrandApplicationCampaign: {},
            modalBrandApplicationContent: null,
            modalBrandInviteMinDelivery: 0,
          }
        })
        if (err) {
          toastr.error("Error: " + err)
        } else {
          let data = {};
          data[invite.campaign_id] = invite;
          store.dispatch({
            model: 'brandCampaigns',
            type: 'ADD_APPLICATION',
            data: invite
          });

          // when coming from Join Campaigns
          if (store.getState().campaign.invites) {
            store.dispatch({
              model: 'campaign',
              type: 'UPDATE_ATTR',
              data: {
                invites: store.getState().campaign.invites.concat(invite)
              }
            })
          }
          // when coming from Find Partners
          else {
            let oldAvailable = store.getState().brand.availableCampaigns;
            let newAvailable = [];
            oldAvailable.forEach(oldCampaign => {
              // other campaigns
              if (oldCampaign.id !== oldCampaign.id) {
                newAvailable.push(oldCampaign);
                return;
              }
              // campaign in question
              let newCampaign = Object.assign({}, oldCampaign);
              newCampaign.invites = newCampaign.invites.slice(0);
              newCampaign.invites.push(invite);
              newAvailable.push(newCampaign);
            })

            store.dispatch({
              model: 'brand',
              type: 'UPDATE_ATTR',
              data: {
                availableCampaigns: newAvailable
              }
            })
          }
          toastr.success('Application Sent!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        }
      })

    });
  }


  render() {
    const { campaign, messageContent, loading } = this.props;

    if (loading) {
      return (
        <Spinner
          needsBackground={ true } />
      )
    }
    return (
      <MessageSlider
        isShown={ campaign.id ? true : false }
        onClose={ this.closeSlider }
        onSend={ this.apply }
        title={ `Apply To ${campaign.name}`}
        buttonText='Send Application'
        initialContent={ messageContent }
      />
    );
  }
}

const mapState = (state) => {
  return {
    campaign: state.conversations.modalBrandApplicationCampaign,
    messageContent : state.conversations.modalBrandApplicationContent,
    loading: state.conversations.loading
  }
}

export default ReactRedux.connect(mapState)(BrandApplication);
