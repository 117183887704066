import React from 'react'
import { object } from 'prop-types'
import d3 from 'd3'
import { Input } from 'visual-components/util/form'
import { H3, H6 } from 'visual-components/util/texts'
import { isPositive, isPositiveInteger, cannotExceed } from 'visual-components/util/form/validations'
import { atLeast } from 'visual-components/util/form/validations'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'

class FixedPricingInfo extends React.Component {
  static propTypes = {
    data: object.isRequired,
  }

  render() {
    const {
      data: {
        costPerUnit,
        depositPercentage,
      },
    } = this.props

    const depositValue = costPerUnit && depositPercentage
      ? d3.format('$,.2f')(costPerUnit * (depositPercentage * 0.01)) : '$0.00'

    return (
      <div className={SellMediaModalTheme.dealTypeContainer}>
        <div className={SellMediaModalTheme.pricingInputFlex}>
          <div className={SellMediaModalTheme.pricingInput}>
            <Input
              name="expectedViews"
              label="Expected Reach"
              type="number"
              placeholder="1,000"
              required
              validations={[
                isPositiveInteger,
                cannotExceed(1e8),
              ]}
            />
          </div>

          <div className={SellMediaModalTheme.pricingInput}>
            <Input
              name="costPerUnit"
              label="Total Price"
              type="number"
              placeholder="$100.00"
              required
              validations={[
                isPositive,
                atLeast(0.01, () => 'Must be at least $0.01'),
                cannotExceed(100000, () => 'Cannot exceed $100,000'),
              ]}
            />
          </div>

          <div className={SellMediaModalTheme.pricingInput}>
            <Input
              name="depositPercentage"
              label="Deposit %"
              type="number"
              placeholder="10%"
              required
              validations={[
                isPositive,
                atLeast(1, () => 'Must be at least 1%'),
                cannotExceed(1e2 - 1),
              ]}
              tooltip="Add the non-refundable amount required up front to secure a spot on your Media Calendar."
            />
          </div>
        </div>

        <div className={SellMediaModalTheme.dealRevDispaly}>
          <H6>Deposit Value:</H6>
          <H3>{ depositValue }</H3>
        </div>
      </div>
    )
  }
}

export default FixedPricingInfo
