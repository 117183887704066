import React, { Component } from 'react';
import Button from 'react-toolbox/lib/button';
import { Link } from 'react-router-dom';

import theme from 'css/themes/mobile/home/pricing.css'
import buttonTheme from 'css/themes/Buttons.css'

class ImgAndContent extends Component {
  render() {
    const {
      imgSrc,
      imgClass,
      title,
      explain,
      subparas,
      buttonLink,
      buttonText
    } = this.props;

    const [imgName, extension] = imgSrc.split('.')

    const srcSet = extension == 'svg' ? null : `
      ${imgName}.${extension},
      ${imgName}@2x.${extension} 2x,
      ${imgName}@3x.${extension} 3x
    `

    const renderSubparas = () => (
      <div className={theme.subparas}>
        {subparas.map(subpara => (
          <div className={theme.subpara}>
            <b> {subpara.title} </b>
            <br />
            <span className={theme.light}>
              {subpara.text}
            </span>
          </div>
        ))}
      </div>
    )

    return(
      <div className={ theme.imgAndContentContainer }>
        <img
          alt={title}
          src={ imgSrc }
          srcSet={ srcSet }
          className={ theme[imgClass] || theme.standard } />
        <p className={ theme.title }>
          { title }
        </p>
        <p className={ theme.explain }>
          { explain }
        </p>
        { !!subparas && renderSubparas() }
        { buttonLink && buttonText
          ? <Link to={ buttonLink }>
              <Button
                onClick={ this.startFreeTrial }
                label={ buttonText }
                className={ buttonTheme.greenButton }
                theme={ buttonTheme }
                floating
              />
            </Link>
          : null
        }
      </div>
    )
  }
};

export default ImgAndContent;
