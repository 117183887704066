const initialState = {
  rid: 'dataDisclosures',
  loading: false,
  error: null,
}

function dataDisclosureReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_DATA_DISCLOSURES':
      return { ...state, loading: true }

    case 'SUBMIT_DATA_DISCLOSURE_SUCCESS':
      return { ...state, ...action.payload }

    case 'SUBMIT_DATA_DISCLOSURE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response
          ? `${action.payload.response.status}: ${action.payload.response.data}`
          : 'Error',
      }

    default:
      return state
  }
}

export default dataDisclosureReducer
