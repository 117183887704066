import React, { PureComponent } from 'react'
import { object, bool } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import OfferMessageTheme from 'css/themes/media-market/OfferMessage.css'

import * as DealActions from 'actions/deal-actions'

const dispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
})

class ButtonGroup extends PureComponent {
  static propTypes = {
    utilityFunctions: object,
    dealActions: object.isRequired,
    isSender: bool,
    isCounterOffer: bool,
    changeDates: bool,
  }

  static defaultProps = {
    changeDates: false,
    utilityFunctions: {},
    isSender: false,
    isCounterOffer: false,
  }

  onChangeDatesClick = () => {
    const { dealActions } = this.props

    dealActions.showChangeDatesModal()
  }

  render() {
    const {
      utilityFunctions,
      isSender,
      isCounterOffer,
      changeDates,
    } = this.props

    if (changeDates) {
      return (
        <Button
          label="Edit Dates"
          theme={ButtonTheme}
          className={`${ButtonTheme.blueButton} ${ButtonTheme.shortButton}`}
          primary
          raised
          onClick={this.onChangeDatesClick}
        />
      )
    }

    const themes = {
      proposal: isCounterOffer ? OfferMessageTheme.counterButtonGroup
        : OfferMessageTheme.proposalButtonGroup,
    }

    return (
      <div>
        {
          isSender
            ? (
              <Button
                label="Edit"
                theme={ButtonTheme}
                className={`${ButtonTheme.blueButton} ${ButtonTheme.shortButton}`}
                primary
                raised
                onClick={() => utilityFunctions.editProposal('edit')}
              />
            ) : (
              <div className={themes.proposal}>
                <Button
                  label="Decline"
                  theme={ButtonTheme}
                  className={`${ButtonTheme.whiteButton} ${ButtonTheme.shortButton}`}
                  primary
                  raised
                  onClick={() => utilityFunctions.openDeclineProposal(isCounterOffer ? 'decline' : 'negotiate')}
                />

                { isCounterOffer
                  && (
                    <Button
                      label="Counter"
                      theme={ButtonTheme}
                      className={`${ButtonTheme.whiteButton} ${ButtonTheme.shortButton}`}
                      primary
                      raised
                      onClick={() => utilityFunctions.editProposal('counter')}
                    />
                  )
                }

                <Button
                  label="Accept"
                  theme={ButtonTheme}
                  className={`${ButtonTheme.greenButton} ${ButtonTheme.shortButton}`}
                  primary
                  raised
                  onClick={utilityFunctions.acceptProposal}
                />
              </div>
            )
        }
      </div>
    )
  }
}

export default connect(null, dispatchToProps)(ButtonGroup)
