
export const setConversionRate = conversionRate => ({ type: 'SET_CONVERSION_RATE', data: { conversionRate } })

export const setAverageLifetimeValue = avgLifetimeValue => ({ type: 'SET_AVERAGE_LIFETIME_VALUE', data: { avgLifetimeValue } })

export const setAverageSpendPerCampaign = avgCampaignSpend => ({ type: 'SET_AVERAGE_SPEND_PER_CAMPAIGN', data: { avgCampaignSpend } })

export const getNTFs = () => ({
  http: {
    url: '/ntf',
  },
  types: [
    'NTF_BY_BRAND',
    'NTF_BY_BRAND_SUCCESS',
    'NTF_BY_BRAND_FAILURE',
  ],
})

export const getIntegrationsInfo = () => ({
  http: {
    url: '/roi/get-integrations-info',
  },
  types: [
    'ROI_BRAND_INTEGRATIONS_INFO',
    'ROI_BRAND_INTEGRATIONS_INFO_SUCCESS',
    'ROI_BRAND_INTEGRATIONS_INFO_FAILURE',
  ],
})

export const getROIValues = () => ({
  http: {
    url: '/roi',
  },
  types: [
    'GET_ROI',
    'GET_ROI_SUCCESS',
    'GET_ROI_FAILURE',
  ],
})

export const postROIValues = data => ({
  http: {
    url: '/roi',
    method: 'PUT',
    data,
  },
  types: [
    'POST_ROI',
    'POST_ROI_SUCCESS',
    'POST_ROI_FAILURE',
  ],
})
