import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import colors from 'util/colours'

// IMPORTANT: this was a PITA to deal with... In order to show something different
// from what's in the editor, you can force it by doing the logic I did down there...
// BUT the values must be the same length in order for the editor to maintain its state
// accordingly with what's intended to be shown (we want to keep the *|WORD|* text part
// for us to know where to do the replacements in the preview)
const TAG_MAPPING = {
  '*|BRAND_NAME|*': '  brand name  ',
  '*|COUPON_CODE|*': '  coupon code  ',
  '*|COUPON|*': '  coupon  ',
}

const styles = {
  tag: {
    textTransform: 'capitalize',
    color: 'white',
    backgroundColor: colors.cerulean,
    padding: 4,
    fontFamily: 'Larsseit',
    borderRadius: 2,
    userSelect: 'none',
    whiteSpace: 'pre',
  },
}

function EditorTagWrapper(props) {
  const { children, classes: css } = props

  const [{ props: { text } }] = children

  const value = TAG_MAPPING[text]

  if (!value) return null

  const childCopy = React.cloneElement(children[0], { text: value })

  return (
    <span className={css.tag}>
      {childCopy}
    </span>
  )
}

EditorTagWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(EditorTagWrapper))
