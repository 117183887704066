import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { object } from 'prop-types'
import FullscreenModalWide from 'visual-components/util/modals/FullscreenModalWide'
import { H4, Small } from 'visual-components/util/texts'
import Theme from 'css/themes/payments/payment-received-full-modal-theme.css'

const mapStateToProps = ({ currentCampaign, SocialActions }) =>
  ({ currentCampaign, SocialActions })

class SocialActionsPurchaseSuccess extends React.Component {
  static propTypes = {
    currentCampaign: object.isRequired,
    SocialActions: object.isRequired,
    history: object.isRequired,
  }

  close = () => {
    const { SocialActions: { selectedCampaign }, currentCampaign, history } = this.props
    
    if (currentCampaign && currentCampaign.id && selectedCampaign.id === currentCampaign.id) {
      history.push(`/partnerships/campaign/${currentCampaign.id}/grow-optimize`)
    } else {
      history.push('/calendar')
    }
  }

  render() {
    const { SocialActions } = this.props
    
    const count = SocialActions.purchaseCount === 1
      ? '1 Social Action'
      : `${SocialActions.purchaseCount} Social Actions`

    return (
      <div>
        <FullscreenModalWide
          title="Your Payment Has Been Received!"
          close={this.close}
        >
          <div className={Theme.mainContainer}>
            <div className={Theme.mainContainer__content}>
              <div className={Theme.mainContainer__content__image}>
                <img src="images/icons/paper-plane-circle-icon.svg" style={{ width: '205px', height: '132px' }} />
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>
                  {'Thank you for purchasing '}
                  <em>{count}</em>
                  {' for '}
                  <em>{SocialActions.selectedCampaign}</em>
                  {". You'll receive a confirmation email shortly."}
                </H4>
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>
                  {'You can review your invoice in your '}
                  <Link to="/profile/brand/billing">Billing Settings</Link>
                  {'.'}
                </H4>
              </div>

              <div className={Theme.mainContainer__content__contactSupport}>
                <Small>
                  {'Please contact '}
                  <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
                  {' if you have any questions about your purchase.'}
                </Small>
              </div>
            </div>
          </div>
        </FullscreenModalWide>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(SocialActionsPurchaseSuccess))
