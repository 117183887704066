import React from 'react'
import propTypes from 'prop-types'
import SubHeader from './subheader'
import SubHeaderText from './subheader-text'

class LanderSectionHeader extends React.Component {
  static propTypes = {
    style: propTypes.object.isRequired,
    headerText: propTypes.string.isRequired,
    subHeaderText: propTypes.string,
  }

  static defaultProps = {
    subHeaderText: null,
  }

  render() {
    const { style, headerText, subHeaderText } = this.props
    return (
      <div style={style}>
        <SubHeader text={headerText} style={style.header} />
        <SubHeaderText text={subHeaderText} style={style.subHeader} />
      </div>
    )
  }
}

export default LanderSectionHeader
