import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withStyles from 'react-jss'
import Dialog from 'react-toolbox/lib/dialog'
import { Button } from 'react-toolbox/lib/button'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H4 } from 'visual-components/util/texts'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import DialogTheme from 'css/themes/dialogs/trialEndingDialog.css'
import ButtonTheme from 'css/themes/Buttons.css'
import createSrcSet from 'util/create-srcset'
import { getPlanLabel } from 'util/brand-stripe-belt-helper'

const mapStateToProps = ({ preferredTermsPlanModal }) =>
  ({ preferredTermsPlanModal })

const styles = {
  header: {
    height: 252,
    width: 672,
    margin: '24px 0 24px -32px',
    textAlign: 'center',
  },
  plan: {
    justifyContent: 'center',
  },
  discount: {
    justifyContent: 'center',
    marginBottom: '24px !important',
  },
  action: {
    alignItems: 'center',
    flexFlow: 'column',
  },
}

class PreferredTermsPlanModal extends Component {
  static propTypes = {
    preferredTermsPlanModal: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  closeModal = () => {
    sessionStorage.setItem('prerferredTermsPlanDismissed', true)
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'preferredTermsPlanModal',
      data: {
        open: false,
      },
    })
  }

  completePurchase = () => {
    this.closeModal()
    BrowserHistoryDispatcher.push('/upgrade-plan')
  }

  render() {
    const {
      preferredTermsPlanModal: { open, locked, plan },
      classes,
    } = this.props

    if (!plan) return null

    return (
      <Dialog
        active={open}
        onEscKeyDown={null}
        onOverlayClick={null}
        theme={DialogTheme}
        title="Your Preferred Terms Plan is Ready!"
      >
        {!locked && (
          <img
            onClick={this.closeModal}
            className={DialogTheme.closeImage}
            src="/images/icons/close.svg"
          />
        )}

        <Grid>
          <Row>
            <Col>
              <div className={classes.header}>
                <img
                  src="/images/pricing/preferred-terms-illustration.png"
                  srcSet={createSrcSet('/images/pricing/preferred-terms-illustration.png')}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className={classes.plan}>
            <H4 multiline>
              <i>Plan: </i>
              {getPlanLabel(plan.plan_id)}
            </H4>
          </Row>
          <Row className={classes.discount}>
            <H4 multiline>
              <i>Discount: </i>
              {plan.discount_label}
            </H4>
          </Row>
          <Row className={classes.action}>
            <Button
              onClick={this.completePurchase}
              label="Complete Your Purchase"
              theme={ButtonTheme}
              className={ButtonTheme.blueButton}
              primary
              raised
            />
          </Row>
        </Grid>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(PreferredTermsPlanModal))
