import { Component } from 'react'
import QuestStyles from 'css/themes/QuestStyles.css'

import { H5, H4, Small } from 'visual-components/util/texts'

class QuestItem extends Component {
  render() {
    const {
      completed,
      text,
      title,
      number,
      wide,
      href
    } = this.props

    return (
      <div className={`${QuestStyles.questItem} ${completed && QuestStyles.completed} ${wide && QuestStyles.wide}`}>
        <div className={QuestStyles.number}>
          { completed ?
            <img src="/images/welcome/checkmark.svg" />
            :
            <H5 white>{number}</H5>
          }
        </div>
        <div className={QuestStyles.title}>
          <H4><b>{title}</b></H4>
        </div>
        <div className={QuestStyles.desc}>
          <Small>{text}</Small>
        </div>
      </div>
    )
  }
}

export default QuestItem
