import React, { Component } from 'react'
import * as ReactRedux from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import Grid from 'visual-components/util/no-padding-grid/grid';
import COLOURS from 'util/colours';
import RichEditor from 'visual-components/richEditor/RichEditor';
import { stateToHTML } from 'draft-js-export-html';
import PartnershipDispatcher from 'dispatchers/partnership-invites-dispatcher';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import modalTheme from 'css/themes/dialogs/modalBase.css';

import dialogTheme from 'css/themes/dialogs/reviewModal.css'

class AcceptInviteConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.updateMessageText = this.updateMessageText.bind(this);
    this.accept = this.accept.bind(this);
  }
  hideModal() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showInviteAcceptConfirmationModal: null
      }
    })
  }

  accept() {
    let { editorState } = this.state;
    let { message, campaign } = this.props
    let contentState = editorState.getCurrentContent();
    
    PartnershipDispatcher.acceptInvite({
      id: message.partnershipinvite_id,
      invitee: {
        id: message.invitee_id,
      },
      campaign_start_date: message.campaign_start_date,
      campaign_end_date: message.campaign_end_date,
      campaign_name: message.campaign_name,
      campaign_host_name: message.host_brand_name,
      campaign_id: message.campaign_id,
      message_id: message.id,
      conversation_id: message.conversation_id,
      attached_message: contentState.hasText() ? stateToHTML(contentState) : null
    })
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showInviteAcceptConfirmationModal: null
      }
    })

    toastr.success('Invite Accepted', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
  }

  updateMessageText(editorState) {
    this.setState({editorState: editorState})
  }

  render() { 
    const { message } = this.props
    return (
      <Dialog
        active={ message ? true : false }
        onEscDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
        theme={ dialogTheme }
      >
        <img
          onClick={ this.hideModal }
          className={ modalTheme.closeImage }
          src="/images/icons/close.svg"
        />
        <Grid>
          <Row>
            <Col xs={12} style={{
              textAlign: 'center',
              marginTop: '6px',
              marginBottom: '16px'
            }}>
              <p style={{
                fontSize: '20px',
                fontFamily: 'Larsseit-Medium'
              }}>
                Join { message ? message.campaign_name : null }
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{
                fontFamily: 'Larsseit-Light',
                fontSize: '16px',
                lineHeight: '24px',
                marginBottom: '10px'
              }}>
                To ensure a successful partnership, we strongly recommend sharing the below info with the host. We suggest sharing:
              </p>
              <ul style={{
                paddingLeft: '20px',
                fontFamily: 'Larsseit-Light',
                fontSize: '14px',
                lineHeight: '22px',
                color: COLOURS.ink,
              }}>
                <li>What you're willing to contribute in terms of prizing</li>
                <li>What dates(s), channel(s) and audience size you are able to promote the giveaway to</li>
                <li>If applicable, how many entries you expect to deliver (generally, this number is a percentage of your audience)</li>
              </ul>
            </Col>
          </Row>
          <Row style={{
            marginTop: '16px'
          }}>
            <Col xs={12}>
              <p style={{
                fontFamily: 'Larsseit-Medium',
                fontSize: '14px',
                lineHeight: '14px',
                paddingBottom: '4px'
              }}>
              Message to Host (optional)
              </p>
              <div style={{
                paddingLeft: '6px',
                paddingRight: '6px'
              }}>
                <RichEditor
                  onChange={ this.updateMessageText }
                  placeholder={ 'We’re able to contribute $300 in cash towards an airline ticket. We expect 3,000 entries and plan to promote through a dedicated email to our 50K subscriber list in mid-October.' }
                />
              </div>
            </Col>
          </Row>
          <ModalButtonGroup 
            canSave={ true }
            hideLine={ true }
            confirm={ this.accept }
            cancel={ this.hideModal }
            cancelText="Cancel"
            confirmText="Accept"
          />
        </Grid>
      </Dialog>
    )
  }
}


const mapState = (state) => {
  return {
    message: state.conversations.showInviteAcceptConfirmationModal,
    campaign: state.campaign
  }
}

export default ReactRedux.connect(mapState)(AcceptInviteConfirmationModal);
