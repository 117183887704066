import React from 'react'
import { Button } from 'react-toolbox/lib/button'
import { Card } from 'react-toolbox/lib/card'
import { H3 } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import TaskListTheme from 'css/themes/TaskList.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as CampaignTaskActions from 'actions/campaign-task-actions'
import TaskBuilder from './task-builder'


const mapDispatchToProps = dispatch => ({
  campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch),
})

class TaskList extends React.Component {
  render() {
    const {
      tasks,
      newTask,
      isCampaignDashboard,
      isHost,
      brandId,
      markComplete,
      campaignTaskActions
    } = this.props

    return (
      <Card className={TaskListTheme.builderContainer}>
        <div className={TaskListTheme.builderHeader}>
          <div className={TaskListTheme.builderTitle}>
            <H3 coral>Task List</H3>
          </div>
          <Button
            raised
            onClick={newTask}
            label="New Task"
            className={ButtonTheme.newTask}
          />
        </div>
        <div className={TaskListTheme.builderTask}>
          <TaskBuilder
            markComplete={markComplete}
            tasks={tasks}
            isCampaignDashboard={isCampaignDashboard}
            isHost={isHost}
            brandId={brandId}
            campaignTaskActions={campaignTaskActions}
          />
        </div>
      </Card>
    )
  }
}

export default connect(null, mapDispatchToProps)(TaskList)
