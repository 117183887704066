import React from 'react'
import { object, number, bool } from 'prop-types'
import { Small, Tiny } from 'visual-components/util/texts'
import IntegrationIcon from 'visual-components/integrations/integration-icon'
import IntegrationTheme from 'css/themes/integrations.css'

class IntegrationItem extends React.Component {
  static propTypes = {
    idx: number.isRequired,
    integration: object.isRequired,
    active: bool,
    selected: bool,
    noBoxShadow: bool,
    noHover: bool,
  }

  static defaultProps = {
    active: false,
    selected: false,
    noBoxShadow: false,
    noHover: false,
  }

  state = {
    hovered: false,
  }

  toggleHover = () => {
    const { noHover } = this.props

    if (!noHover) {
      this.setState(prevState => ({
        hovered: !prevState.hovered,
      }))
    }
  }

  render() {
    const {
      integration: {
        img,
        label,
      },
      idx,
      active: hasSuccess,
      selected,
      noBoxShadow,
    } = this.props

    const { hovered } = this.state

    let imgName
    let extension
    let srcSet
    if (img) {
      [imgName, extension] = img.split('.')
      srcSet = imgName && extension && `
        ${imgName}-logo.${extension},
        ${imgName}-logo@2x.${extension} 2x,
        ${imgName}-logo@3x.${extension} 3x
      `
    }

    return (
      <div
        onMouseOver={this.toggleHover}
        onMouseOut={this.toggleHover}
        className={`
          ${IntegrationTheme.integrationItem}
          ${noBoxShadow && IntegrationTheme.noBoxShadow}
          ${(hovered || selected) && IntegrationTheme.hovered}
          ${(idx + 1) % 8 === 0 && IntegrationTheme.noMargin}
        `}
      >
        { hasSuccess
          ? <IntegrationIcon success />
          : null
        }

        <img
          src={img}
          srcSet={srcSet}
          className={IntegrationTheme.itemLogo}
        />

        <div className={IntegrationTheme.itemContent}>
          <div className={IntegrationTheme.itemLabel}>
            <Small>
              <b>
                { label }
              </b>
            </Small>
          </div>
          <Tiny>
            ESP
          </Tiny>
        </div>
      </div>
    )
  }
}

export default IntegrationItem
