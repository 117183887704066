import React from 'react';
import PropTypes from 'prop-types';

import BrandDropdownMenu from './dropdowns/brand-dropdown-menu';
import NavBrandLogo from './nav-brand-logo';

import * as metrics from 'util/metrics'

class NavBrandMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandMenuActive: false,
    };

    this.toggleBrandMenu = this.toggleBrandMenu.bind(this);
    this.handleBrandMenuHide = this.handleBrandMenuHide.bind(this);
  }

  toggleBrandMenu(e) {
    e.preventDefault();

    if (!this.state.brandMenuActive) {
      if (window.location.pathname === '/app/') {
        metrics.create('homePageNavBarClickedBrandLogo')
      }
    }

    this.setState({
      brandMenuActive: !this.state.brandMenuActive
    });
  }

  handleBrandMenuHide() {
    this.setState({
      brandMenuActive: false
    });
  }

  render() {

    var {
      imgSrc,
      brandName,
      brandLogo,
      profileHref
    } = this.props;

    return(
      <div style={{
        display: 'inline-flex'
      }}>
        <NavBrandLogo
          imgSrc={ imgSrc }
          onclick={ this.toggleBrandMenu }
        />
        <BrandDropdownMenu
          handleMenuHide={ this.handleBrandMenuHide }
          active={ this.state.brandMenuActive }
          brandName={ brandName }
          brandLogo={  brandLogo }
          profileHref={ profileHref }
        />
      </div>
    )
  }
}

NavBrandMenu.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  brandLogo: PropTypes.string.isRequired,
  profileHref: PropTypes.string.isRequired
}

export default NavBrandMenu;
