import React from 'react'
import { Form, Input, Submit } from 'visual-components/util/form'
import { isNum } from 'visual-components/util/form/validations'
import { Card } from 'react-toolbox/lib/card'
import { P, H2, H3 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

import AppDispatcher from 'dispatchers/app-dispatcher'

class CustomerSuccessBrandRatings extends React.Component {
  state = {
    open: false,
    brandId: null,
    brandName: null,
    reviews: [],
  }

  handleChange = e => {
    this.setState(e)
  }

  handleSubmit = () => {
    const { brandId } = this.state
    AppDispatcher.http({
      url: `/cs-brand-ratings/${brandId}`,
      method: 'GET',
      success: data => this.handleChange({ ...data, brandId }),
      error: error => {
        this.handleChange({ reviews: [] })
        toastr.error(error.responseText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      },
    })
  }

  handleHide = review => {
    AppDispatcher.http({
      url: `/cs-brand-ratings/${review.id}`,
      method: 'POST',
      data: review,
      success: review => {
        this.setState(prevState => {
          const tempReviews = prevState.reviews
          const reviewIdx = tempReviews.findIndex(r => r.id === review.id)
          tempReviews[reviewIdx].hidden = review.hidden
          return { reviews: tempReviews }
        })
        toastr.success(`Review ${review.hidden ? 'Hidden' : 'Unhidden'}`, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      },
      error: error => {
        toastr.error(error.responseText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      },
    })
  }

  renderReviews = () => {
    const { reviews } = this.state

    return reviews.map(review => (
      <div style={{ marginBottom: '24px' }}>
        <P multiline>
          <b>Rating Brand: </b>
          {review.rating_brand.accountname}
        </P>
        <P multiline>
          <b>Type: </b>
          {review.model_type}
        </P>
        <P multiline>
          <b>Rating: </b>
          {review.rating}
        </P>
        <P multiline>
          <b>Comment: </b>
          {review.comment}
        </P>
        <Button
          onClick={() => this.handleHide(review)}
          theme={ButtonTheme}
          className={ButtonTheme.blueButton}
          label={review.hidden ? 'Unhide' : 'Hide'}
        />
      </div>
    ))
  }

  render() {
    const { open, brandId, brandName } = this.state

    return (
      <Card style={{ padding: '24px' }}>
        <Grid>
          <Row style={{ marginBottom: '16px' }}>
            <div
              onClick={() => this.handleChange({ open: !open })}
              style={{ cursor: 'pointer' }}
            >
              <H2 azure>
                Brand Ratings
                <i
                  className={`fa fa-angle-${open ? 'up' : 'down'}`}
                  style={{ fontSize: '32px', marginLeft: '8px' }}
                />
              </H2>
              <P multiline>
                {"Shows a selected Brand's received reviews. Reviews can be hidden from view."}
              </P>
            </div>
          </Row>
          <Row style={{ display: open ? 'flex' : 'none' }}>
            <Col style={{ width: '364px', marginRight: '32px' }}>
              <Form
                handleSubmit={this.handleSubmit}
                updateModel={this.handleChange}
                dataModel={this.state}
              >
                <Input
                  name="brandId"
                  label="Enter Brand ID"
                  type="number"
                  validations={[isNum]}
                />

                <Submit label="Submit" />
              </Form>
            </Col>
            <Col style={{ width: '696px' }}>
              {brandId && (
                <div style={{ marginBottom: '16px' }}>
                  <H3>
                    <small>
                      {`${brandId} - ${brandName}`}
                    </small>
                  </H3>
                </div>
              )}
              {this.renderReviews()}
            </Col>
          </Row>
        </Grid>
      </Card>
    )
  }
}



export default CustomerSuccessBrandRatings
