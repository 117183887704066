import { instanceOf, func } from 'prop-types'
import React from 'react'
import moment from 'moment-timezone'
import CalendarTheme from 'css/themes/calendar/calendar.css'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import { H6 } from 'visual-components/util/texts'

class Toolbar extends React.Component {
  static propTypes = {
    handleNavigate: func.isRequired,
  }

  static contextTypes = {
    date: instanceOf(Date).isRequired,
  }

  render() {
    const { date } = this.context
    const { handleNavigate, exportCalendar } = this.props

    return (
      <div className={CalendarTheme.toolBarHolder}>
        <div style={{ display: 'inline-block' }}>
          <H6 coral className={CalendarTheme.dateRangeLabel}>
            {`${moment(date).format('MMM YYYY')} - ${moment(date).add(1, 'month').format('MMM YYYY')}`}
          </H6>
        </div>
        <Button
          className={`${ButtonTheme.raised} ${ButtonTheme.smallBlueButton} ${CalendarTheme.todayButton}`}
          type="button"
          onClick={() => handleNavigate('TODAY')}
        >
          Today
        </Button>
        <div
          style={{
            cursor: 'pointer',
            display: 'inline-block',
            width: '20x',
            float: 'right',
            marginRight: '20px',
          }}
          onClick={exportCalendar}
        >
          <img src="/images/popout-taskcard-button-icons/export-to-calendar.svg" />
        </div>
      </div>
    )
  }
}

export default Toolbar
