import React from 'react';
import theme from 'css/themes/homePage/whatMarketersSay.css';
import { Carousel } from 'react-responsive-carousel';
import MarketerSaidSlide from '../util/marketer-said-slide';

// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

class WhatMarketersSay extends React.Component {

  render() {
    return(
      <div className={ theme.container }>
        <p className={ theme.title }>
          What Marketers Are Saying
        </p>
        <Carousel
          infiniteLoop={ true }
          showThumbs={false}
        >
          <div>
            <MarketerSaidSlide
              quote={ `DojoMojo is an effective tool that has brought transparency to our sweepstakes acquisition campaigns, helping us scale this channel tremendously and hit our quarterly targets. We couldn’t imagine our acquisition efforts without it.”` }
              author={ 'Lindsay Fitzgerald' }
              whoDat={ 'Marketing Partnerships Account Manager at' }
              linkTitle={ 'General Assembly' }
              linkURL={ 'https://generalassemb.ly/' }
              imgSrc={  '/images/brand-logos/general-assembly-logo.svg' }
            />
          </div>
          <div>
            <MarketerSaidSlide
              quote={ `“DojoMojo is one of the easiest, most convenient tools I use in my day-to-day. I connect with new brands, easily organize upcoming opportunities, and stay up to date with current campaigns in real-time. I don’t know how we would run our partnerships program without it. I’m bummed when a sweepstakes is not hosted on DojoMojo!”` }
              author={ 'Nicole Ribaudo' }
              whoDat={ 'Associate Manager, Email & Partnerships at' }
              linkTitle={ 'Hearst' }
              linkURL={ 'https://www.hearst.com/magazines/digital-media' }
              imgSrc={  '/images/brand-logos/hearst-logo.svg' }
            />
          </div>
          <div>
            <MarketerSaidSlide
              quote={ `“DojoMojo makes it easy for us to host sweepstakes on our own site! It's been a key component in helping us reach our email acquisition goals and has given us the additional benefit of boosting on-site traffic. We've also gotten greater insight into entrants which will continue to help inform our future sweepstakes strategies.”` }
              author={ 'Sophie Corwin' }
              whoDat={ 'Growth Marketing Coordinator at' }
              linkTitle={ 'Food52' }
              linkURL={ 'https://food52.com/' }
              imgSrc={  '/images/brand-logos/logo-food-52.svg' }
            />
          </div>
          <div>
            <MarketerSaidSlide
              quote={ `“DojoMojo is the rare service that delivers exactly what they promise...and then goes well beyond it. We're simply astonished by the effectiveness and simplicity of the platform to create sweepstakes and generate email leads. And if that's not enough, their support is absolutely top notch.”` }
              author={ 'Sam Payrovi' }
              whoDat={ 'CEO & Founder at' }
              linkTitle={ 'Custom Consortium' }
              linkURL={ 'https://customconsortium.com/' }
              imgSrc={  '/images/brand-logos/Custom_Consortium_Logo.png' }
            />
          </div>
        </Carousel>
      </div>
    )
  }
}

export default WhatMarketersSay;
