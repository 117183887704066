import React, { Component } from 'react'

import theme from 'css/themes/mobile/home/pricing.css'

class InThePress extends Component {
  render() {
    return (
      <div className={theme.inThePress}>
        <p className={theme.sectionTitle}>In the Press</p>
        <div className={theme.imgContainer}>
          <div className={theme.inner}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.fastcompany.com/90370478/time-management-can-ruin-your-productivity-heres-how-to-prevent-that-from-happening"
            >
              <img
                alt="Fast Company"
                src="/images/login/mobile/home/press-logos/fast-company-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/fast-company-logo.png,
                  /images/login/mobile/home/press-logos/fast-company-logo@2x.png 2x,
                  /images/login/mobile/home/press-logos/fast-company-logo@3x.png 3x`}
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.forbes.com/sites/theyec/2019/08/22/how-to-win-at-ecommerce-without-facebook-or-google/#595b554e2f31"
            >
              <img
                alt="Forbes"
                src="/images/login/mobile/home/press-logos/forbes-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/forbes-logo.png,
                  /images/login/mobile/home/press-logos/forbes-logo@2x.png 2x,
                  /images/login/mobile/home/press-logos/forbes-logo@3x.png 3x`}
              />
            </a>
          </div>
          <div className={theme.inner}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.inc.com/jared-hecht/6-social-media-mistakes-that-could-land-you-in-legal-trouble.html"
            >
              <img
                alt="INC."
                src="/images/login/mobile/home/press-logos/inc-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/inc-logo.png,
                /images/login/mobile/home/press-logos/inc-logo@2x.png 2x,
                /images/login/mobile/home/press-logos/inc-logo@3x.png 3x`}
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.martechadvisor.com/news/ads/dojomojo-launches-media-market-to-offer-brands-an-effective-paid-acquisition-alternative-to-facebook-and-google/"
            >
              <img
                alt="Martech Advisor"
                src="/images/login/mobile/home/press-logos/mta-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/mta-logo.png,
                /images/login/mobile/home/press-logos/mta-logo@2x.png 2x,
                /images/login/mobile/home/press-logos/mta-logo@3x.png 3x`}
              />
            </a>
          </div>
          <div className={theme.inner}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.entrepreneur.com/article/300897"
            >
              <img
                alt="Entrepreneur"
                src="/images/login/mobile/home/press-logos/entrepreneur-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/entrepreneur-logo.png,
                  /images/login/mobile/home/press-logos/entrepreneur-logo@2x.png 2x,
                  /images/login/mobile/home/press-logos/entrepreneur-logo@3x.png 3x`}
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.modernretail.co/platforms/how-one-company-is-building-brands-to-sell-only-on-amazon/"
            >
              <img
                alt="Modern Retail"
                src="/images/login/mobile/home/press-logos/modern-retail-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/modern-retail-logo.png,
              /images/login/mobile/home/press-logos/modern-retail-logo@2x.png 2x,
              /images/login/mobile/home/press-logos/modern-retail-logo@3x.png 3x`}
              />
            </a>
          </div>
          <div className={theme.inner}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://goodmenproject.com/featured-content/3-things-climbing-mount-kilimanjaro-taught-me-about-resilience-kpkn/"
            >
              <img
                alt="The Good Men Project"
                src="/images/login/mobile/home/press-logos/good-men-project-logo.png"
                srcSet={`/images/login/mobile/home/press-logos/good-men-project-logo.png,
                /images/login/mobile/home/press-logos/good-men-project-logo@2x.png 2x,
                /images/login/mobile/home/press-logos/good-men-project-logo@3x.png 3x`}
              />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default InThePress
