import React, { Component } from 'react'
import COLOURS from 'util/colours'
import PrimaryButton from 'util/components/buttons/primary-button'
import Checkbox from 'util/components/form-elements/checkbox'
import { H3, P } from 'visual-components/util/texts'

class TermsCreateOption extends Component {
  render() {
    const {
      heading,
      highlightedHeading,
      body,
      checked,
      button
    } = this.props

    var optionStyle = {
      padding: '40px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: checked ? COLOURS.cerulean : COLOURS.elephantGrey
    }

    return (
      <div className="col-sm-6"
        style={{
          paddingLeft: this.props.onLeft ? '15px' : '25px',
          paddingRight: this.props.onLeft ? '25px' : '15px'
      }}>
        <div className="row">
          <div className="Terms-Create-Option" style={ optionStyle }>
            <div className="Terms-Create-Option-inner" style={{
              marginBottom: '20px',
            }}>
              <H3>
                { heading }
              </H3>
              <H3 azure>
                { highlightedHeading }
              </H3>
            </div>
            <div className="Terms-Create-Option-inner-paragraph">
              <P multiline>
                { body }
              </P>
            </div>
            <div style={{
              marginTop: '24px',
              marginBottom: '24px'
            }}>
              <Checkbox
                toggleChecked={ e => this.props.check(this.props.type, e.target.checked) }
                label="I agree and wish to proceed."
                value={ checked }
                labelStyles={[{
                  lineHeight: '22px',
                  fontFamily: "'Larsseit'",
                  fontSize: '18px'
              }]} />
            </div>
            <PrimaryButton onClick={ this.props.continue }
              isDisabled={ !checked }
              blueButton={ true }
            >
              { button }
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
}

export default TermsCreateOption
