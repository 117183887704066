import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import {
  H3,
  H4,
  H6,
  Small,
} from 'visual-components/util/texts'
import * as DealActions from 'actions/deal-actions'

const mapStateToProps = ({ deals }) => ({ deals })

const mapDispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
})

class SelectDealType extends React.Component {
  static propTypes = {
    functions: object.isRequired,
    data: object.isRequired,
  }

  render() {
    const {
      functions: {
        setDealCostTypeMapLabel,
        setCostType,
      },
      data: {
        isSeller,
        dealCostTypeMapLabel = 'cpc_pricing_info',
      },
    } = this.props

    const dealTypes = [
      {
        title: 'CPC',
        subtitle: 'Cost per click. Set a min and max click range.',
        type: 'CPC',
        mapLabel: 'cpc_pricing_info',
        active: true,
      },
      {
        title: 'Flat Rate',
        subtitle: 'Set a flat rate price.',
        type: 'Fixed',
        mapLabel: 'fixed_pricing_info',
        active: true,
      },
      {
        title: 'CPA - Coming Soon',
        subtitle: 'Cost per acquisition. Sell based on conversions.',
        type: 'CPA',
        mapLabel: null,
        active: false,
      },
      {
        title: 'CPM - Coming Soon',
        subtitle: 'Cost per thousand. Set a fixed price per unit.',
        type: 'CPM',
        mapLabel: null,
        active: false,
      },
    ]

    const mappedDealTypes = dealTypes.map(deal => {
      const {
        title,
        subtitle,
        type,
        mapLabel,
        active,
      } = deal

      const selectDeal = active
        ? () => {
          setDealCostTypeMapLabel(mapLabel)
          setCostType(type)
        } : null

      const isSelected = dealCostTypeMapLabel === mapLabel

      return (
        <div
          key={type}
          className={isSelected
            ? SellMediaModalTheme.dealWrapperSelected
            : SellMediaModalTheme.dealWrapper
          }
          onClick={selectDeal}
        >
          <div className={SellMediaModalTheme.dealTitle}>
            <H6 inactive={!active}>{title}</H6>
          </div>
          <div className={SellMediaModalTheme.dealSubtitle}>
            <Small multiline inactive={!active}>{subtitle}</Small>
          </div>
        </div>
      )
    })

    return (
      <div className={SellMediaModalTheme.dealTypeContainer}>
        <div className={SellMediaModalTheme.orangeTitle}>
          <H3 coral>
            <small>Pricing Model</small>
          </H3>
        </div>

        <div className={SellMediaModalTheme.dealTypeSubtitle}>
          <H4 multiline>
            { `Select how you'd like to be ${isSeller ? 'compensated' : 'charged'} for the media you're ${isSeller ? 'selling' : 'buying'}.` }
          </H4>
        </div>

        <div className={SellMediaModalTheme.dealTypeMain}>
          {mappedDealTypes}
        </div>
      </div>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SelectDealType)
