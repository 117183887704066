import React, { Component } from 'react'
import _ from 'lodash'

import { Route, Prompt, Switch, Redirect } from 'react-router-dom';
import SideNav from 'visual-components/sideBars/side-nav';
import * as ReactRedux from 'react-redux';

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import InboxContainer from './InboxContainer';
import InviteContainer from './InviteContainer';
import MessagingSwitchBrand from 'visual-components/messaging/messaging-switch-brand'
import InterestedConfirmationModal from 'visual-components/messaging/interested-confirmation-modal'
import InterestedInfoModal from 'visual-components/messaging/interested-info-modal'

class Messaging extends Component {

  render() {
    const prefix = this.props.match.path
    const { conversations, receivedInvitations, receivedApplications } = this.props
    const promptBeforeLeaving = false;

    const unreadConversations = _.filter(conversations, {read: false, conversation_archive: false}).length
    const pendingReceivedInvites = _.filter(receivedInvitations, {status: 'pending', partnership_action: null}).length
    const pendingreceivedApps = _.filter(receivedApplications, {status: 'pending', partnership_action: null}).length

    const routes = [{
      label: `Inbox ${unreadConversations > 0 ? `(${unreadConversations})` : ''}`,
      to: '/inbox',
    }, {
      label: `Received Invitations ${pendingReceivedInvites > 0 ? `(${pendingReceivedInvites})` : ''}`,
      to: '/received-invitations',
    }, {
      label: `Sent Invitations`,
      to: '/sent-invitations',
    }, {
      label: `Received Applications ${pendingreceivedApps > 0 ? `(${pendingreceivedApps})` : ''}`,
      to: '/received-applications',
    }, {
      label: `Sent Applications`,
      to: '/sent-applications',
    }]



    return (
      <div>
        <InternalContentContainer width={ '1080px' }>

          <Route children={ routeProps => (
            <SideNav
              width={ '240px' }
              routes={ routes }
              prefix={ prefix }
              switchBrands = { true }
              { ...routeProps }
            />
          )} />

          {/* for demonstration */}
          <Prompt
            message="Are you sure you want to leave messaging?"
            when={ promptBeforeLeaving }
          />

          <Switch>
            <Redirect exact from={`${prefix}`} to={`${prefix}/inbox`} />

            <Route path={`${prefix}/inbox/:id?`} component={ InboxContainer } />
            <Route path={`${prefix}/received-invitations/:partnershipId?`} component={ InviteContainer } />
            <Route path={`${prefix}/sent-invitations`} component={ InviteContainer } />
            <Route path={`${prefix}/received-applications/:partnershipId?`} component={ InviteContainer } />
            <Route path={`${prefix}/sent-applications`} component={ InviteContainer } />
            <Redirect to="/404" />
          </Switch>

          {/* Interested Confirmation Modal */}
          <InterestedConfirmationModal />
          <InterestedInfoModal />
        </InternalContentContainer>
      </div>

    )
  }
}




const mapState = (state) => {
  return {
    conversations: state.conversations.conversations,
    receivedInvitations: state.conversations.receivedInvitations,
    sentInvitations: state.conversations.sentInvitations,
    receivedApplications: state.conversations.receivedApplications,
    sentApplications: state.conversations.sentApplications,
  }
}

export default ReactRedux.connect(mapState)(Messaging);
