import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { H3, H6 } from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'
import MMDealTrackingTheme from 'css/themes/mediaMarketDealTrackingTheme.css'
import ButtonTheme from 'css/themes/Buttons.css'
import * as DealActions from 'actions/deal-actions'
import TrackingItem from './tracking-item'
import TrackingLinkModal from './tracking-link-modal'
import TrackingLinkIFrameModal from './TrackingLinkIFrame'

const mapStateToProps = ({
  currentBrand,
  deals: { deal, editTargetLinkActive, successfulAction },
}) => ({
  currentBrand,
  deal,
  editTargetLinkActive,
  successfulAction,
})

const mapDispatchToProps = dispatch => ({ dealActions: bindActionCreators(DealActions, dispatch) })

const warningMsg = 'Warning - please make sure you use the tracking links in your promotions to ensure your performance is tracked correctly.'

const SellerWarningMsg = () => (
  <Row style={{ padding: '0 20px 0px 20px', margin: '8px 0' }}>
    <Col style={{ width: '986px' }}>
      <div style={{ display: 'inline-block' }}>
        <H6 multiline>
          { warningMsg }
        </H6>
      </div>
    </Col>
  </Row>
)

const renderTrackingItems = (isSeller, toggleLinkModal, toggleLinkPreview) => (item, idx) => (
  <TrackingItem
    idx={idx}
    key={item.id}
    link={item}
    isSeller={isSeller}
    toggleLinkModal={toggleLinkModal}
    toggleLinkPreview={toggleLinkPreview}
  />
)

class DealTrackingLinks extends Component {
  static propTypes = {
    currentBrand: object.isRequired,
    deal: object.isRequired,
    editTargetLinkActive: object.isRequired,
    successfulAction: object,
    dealActions: object.isRequired,
  }

  static defaultProps = {
    successfulAction: {},
  }

  state = {
    linkToPreview: {},
    showPreviewModal: false,
    isCopying: false,
  }

  componentDidMount() {
    const {
      deal: { id: dealId },
      successfulAction: { id, type } = {},
      dealActions: { clearActionSuccess, markRead },
    } = this.props

    markRead(dealId, 'targets')

    if (id && type === 'link-create') {
      toastr.success('Link added successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      clearActionSuccess()
    } else if (id && type === 'link-edit') {
      toastr.success('Link updated successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      clearActionSuccess()
    }
  }

  componentDidUpdate(prevProps) {
    const { deal: { id, targets }, dealActions: { markRead } } = this.props
    if (prevProps.deal.targets !== targets) markRead(id, 'targets')
  }

  toggleLinkModal = link => {
    const { dealActions: { updateAttr } } = this.props
    updateAttr({ editTargetLinkActive: link })
  }

  toggleLinkPreview = (link, isCopying = false) => {
    this.setState({ linkToPreview: { ...link }, showPreviewModal: true, isCopying })
  }

  closePreviewModal = () => {
    this.setState({ showPreviewModal: false })

    setTimeout(() => this.setState({ linkToPreview: {}, isCopying: false }), 500)
  }

  render() {
    const {
      currentBrand,
      deal,
      dealActions,
      editTargetLinkActive,
    } = this.props

    if (!deal || !Object.keys(deal).length) {
      return (
        <Spinner />
      )
    }

    const isSeller = currentBrand.id === deal.selling_brand_id
    const trackingItems = deal.targets.map(renderTrackingItems(isSeller, this.toggleLinkModal, this.toggleLinkPreview))

    return (
      <div style={{ paddingTop: '16px', width: '1172px', marginLeft: '-.6%' }}>
        <Card style={{ overflow: 'visible', boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)' }}>
          <Row style={{ padding: '16px 20px 0px 20px', marginBottom: '8px' }}>
            <Col style={{ flex: 1 }}>
              <div style={{ display: 'inline-block' }}>
                <H3 coral style={{ fontWeight: '500' }}>
                  <small>
                    { isSeller ? 'Tracking Links' : 'Target Links' }
                  </small>
                </H3>
              </div>
            </Col>
            {
              !isSeller
                ? (
                  <Col style={{ width: '107px' }}>
                    <div>
                      <Button
                        onClick={() => this.toggleLinkModal({})}
                        label="Add New Link"
                        theme={ButtonTheme}
                        className={MMDealTrackingTheme.blueTextButton}
                        primary
                        raised
                      />
                    </div>
                  </Col>
                ) : null
            }
          </Row>
          { isSeller && <SellerWarningMsg /> }
          <Row style={{ padding: '0px 20px' }}>
            {
              trackingItems.length
                ? trackingItems
                : null
            }
          </Row>
        </Card>
        <TrackingLinkModal
          deal={deal}
          link={editTargetLinkActive}
          actions={dealActions}
          closeEditModal={() => this.toggleLinkModal(false)}
        />
        <TrackingLinkIFrameModal
          trackingLink={this.state.linkToPreview}
          showModal={this.state.showPreviewModal}
          closeModal={this.closePreviewModal}
          isCopying={this.state.isCopying}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealTrackingLinks))
