import React from 'react';
import COLOURS from 'util/colours';
import Radium from 'radium';
import Text from './text';

var StandardTableWrapper = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      overflowX: 'scroll',
      paddingBottom: '150px'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <div style={styles}>
        {this.props.children}
      </div>
    )
  }
})

var StandardTable = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      width: '100%',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    var classes = this.props.allWhite == true ? "table" : "table table-striped"

    return ( 
      <table
        className={classes}
        id={this.props.id}
        style={styles}>
        {this.props.children}
      </table>
    )
  }
});

var StandardTableBody = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      width: '100%',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return ( 
      <tbody
        id={this.props.id}
        style={styles}>
        {this.props.children}
      </tbody>
    )
  }
});

var StandardHeaderTr =  Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      borderBottomColor: COLOURS.darkGreen.point05,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      backgroundColor: 'transparent'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return ( 
      <tr style={styles} onClick={this.props.onClick}>
        {this.props.children}
      </tr>
    )
  }
});

var StandardHeaderTd =  Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      textAlign: 'right',
      paddingRight: '10px',
      paddingLeft: '0',
      paddingTop: '0',
      paddingBottom: '3px',
      lineHeight: '13px'
    }],
    styles: [],
    baseTextStyles: [{
      fontFamily: "'Larsseit-Light'"
    }]
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles).concat(this.props.textStyles);
    var textStyles = this.props.baseTextStyles.concat(this.props.textStyles);
    return ( 
      <td style={styles} onClick={this.props.onClick}>
        <Text.TableHeaderText styles={textStyles}>
          {this.props.children}
        </Text.TableHeaderText>
      </td>
    )
  }
});

var StandardLeftHeaderTd =  Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      textAlign: 'left',
      paddingRight: '0',
      paddingLeft: '20px',
      paddingTop: '0',
      paddingBottom: '3px',
      lineHeight: '13px'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles).concat(this.props.textStyles);
    return ( 
      <td style={styles}>
        <Text.TableHeaderText styles={ this.props.textStyles }>
          {this.props.children}
        </Text.TableHeaderText>
      </td>
    )
  }
});

var StandardTr = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      borderBottomColor: COLOURS.darkGreen.point05,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderTopWidth: '0',
      borderRightWidth: '0',
      borderLeftWidth: '0',
      backgroundColor: 'transparent'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return ( 
      <tr style={styles} onClick={this.props.onClick}>
        {this.props.children}
      </tr>
    )
  }
});

var StandardTd = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      paddingTop: '15px',
      paddingRight: '10px',
      paddingBottom: '15px',
      paddingLeft: '0',
      textAlign: 'right',
      verticalAlign: 'middle'
    }],
    styles: [],
    baseTextStyles: [{fontFamily: "'Larsseit'"}]
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    var textStyles = this.props.baseTextStyles.concat(this.props.textStyles);
    return (
      <td onClick={ this.props.onClick }
        style={styles} 
        className={this.props.classes}
      >
        <Text.TableText styles={textStyles}>
          {this.props.children}
        </Text.TableText>
      </td>
    );
  }
});

var StandardLeftTd = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      paddingTop: '15px',
      paddingRight: '0',
      paddingBottom: '15px',
      paddingLeft: '20px',
      textAlign: 'left',
      verticalAlign: 'middle'
    }],
    styles: [],
    baseTextStyles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    var textStyles = this.props.baseTextStyles.concat(this.props.textStyles);

    return (
      <td style={styles}>
        <Text.TableText styles={textStyles}>
          {this.props.children}
        </Text.TableText>
      </td>
    );
  }
});

var StandardTdLink = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      paddingLeft: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      maxWidth: '250px',
      paddingRight: '10px',
      paddingTop: '15px',
      paddingBottom: '15px',
      verticalAlign: 'middle'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);

    return (
      <td style={styles}>
        <a href={this.props.href}
          onClick={ this.props.onClick || function(){} }
          style={{
            lineHeight: '51px',
            textDecoration: 'none',
            borderTop: '1px solid transparent'
          }}
          target={ this.props.target || "_self"}
        >
          <Text.TableLink styles={ this.props.textStyles }>
            {this.props.children}
          </Text.TableLink>
        </a>
      </td>
    );
  }
});

var StandardDropdownTd = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      width: '214px',
      minWidth: '214px',
      maxWidth: '214px',
      height: '54px',
      paddingTop: '8px',
      paddingBottom: '0',
      paddingRight: '21px',
      paddingLeft: '20px',
      verticalAlign: 'middle'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <td style={styles}>
        {this.props.children}
      </td>
    );
  }
});




var StandardTotalsTd = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
    }],
    styles: [],
    textBaseStyles: [{
      marginTop: '18px',
      marginBottom: '18px',
      display: 'inline-block',
      verticalAlign: 'middle'
    }],
    textStyles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    var textStyles = this.props.textBaseStyles.concat(this.props.textStyles)
    return ( 
      <td style={styles}>
        <Text.TableText styles={textStyles}>
          {this.props.children}
        </Text.TableText>
      </td>
    )
  }
});

export default {
  TableWrapper: StandardTableWrapper,
  Table: StandardTable,
  Tbody: StandardTableBody,
  HeaderTr: StandardHeaderTr,
  Th: StandardHeaderTd,
  LeftTh: StandardLeftHeaderTd,
  Tr: StandardTr,
  Td: StandardTd,
  LeftTd: StandardLeftTd,
  TdLink: StandardTdLink,
  DropdownTd: StandardDropdownTd,
  TotalsTd: StandardTotalsTd
}
