import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { object } from 'prop-types'
import { Checkbox } from 'react-toolbox'
import moment from 'moment'
import d3 from 'd3'
import axios from 'axios'

import {
  H3, H4, H6, P,
} from 'visual-components/util/texts'
import { SimpleForm, Select } from 'visual-components/util/form'
import FullscreenModal from 'visual-components/util/modals/FullscreenModal'
import ChooseCard from 'visual-components/util/choose-card'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import Spinner from 'util/components/spinner'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'
import CardDispatcher from 'dispatchers/card-dispatcher'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import Theme from 'css/themes/payments/upgrade-plan-full-modal.css'
import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import CheckboxTheme from 'css/themes/Checkbox.css'
import {
  beltMapping,
  getPlanLabel,
  isTrialing,
  cleanPlanText,
  getLTCPlans,
  smsCreditCostMapping,
} from 'util/brand-stripe-belt-helper'
import * as SmsActions from 'actions/sms-actions'
import OrderSummaryDisplay from './order-summary-display'
import { applyPlanPromos, applySummaryPromos } from './subscription-promotions'
import AddSmsMarketingPlan from './add-sms-marketing-plan'

const mapStateToProps = ({
  currentBrand, customer, invoices, card, sms,
}) =>
  ({
    currentBrand, customer, invoices, card, sms,
  })

const dispatchToProps = dispatch => ({
  smsActions: bindActionCreators(SmsActions, dispatch),
})

let defaultPlans = [
  {
    value: 'greenFixed',
    label: 'Essential - $199/mo (billed monthly)',
  },
  // {
  //   value: 'essential-12months-upfront',
  //   label: 'Essential - $149/mo (25% off, billed annually)',
  //   upgradeOnly: true,
  //   annual: true,
  // },
  {
    value: 'black',
    label: 'Premium - $499/mo (billed monthly)',
  },
  // {
  //   value: 'premium-12month-upfront',
  //   label: 'Premium - $374/mo (25% off, billed annually)',
  //   upgradeOnly: true,
  //   annual: true,
  // },
  // {
  //   value: 'pro-monthly',
  //   label: 'Pro - $899/mo (billed monthly)',
  // },
  // {
  //   value: 'pro-12month-upfront',
  //   label: 'Pro - $674/mo (25% off, billed annually)',
  //   upgradeOnly: true,
  //   annual: true,
  // },
]

const defaultSummaryMap = {
  greenFixed: {
    plan: 'Essential Plan (billed monthly)',
    planAmount: 199,
    featureSet: 'essential',
  },
  // 'essential-12months-upfront': {
  //   plan: 'Essential Plan (billed annually)',
  //   planAmount: 2388,
  //   discountAmount: 597,
  //   discountLabel: 'Annual Discount (25%)',
  //   featureSet: 'essential',
  //   annual: true,
  // },
  black: {
    plan: 'Premium Plan (billed monthly)',
    planAmount: 499,
    featureSet: 'premium',
  },
  // 'premium-12month-upfront': {
  //   plan: 'Premium Plan (billed annually)',
  //   planAmount: 5988,
  //   discountAmount: 1497,
  //   discountLabel: 'Annual Discount (25%)',
  //   featureSet: 'premium',
  //   annual: true,
  // },
  // 'pro-monthly': {
  //   plan: 'Pro Plan (billed monthly)',
  //   planAmount: 899,
  //   featureSet: 'pro',
  // },
  // 'pro-12month-upfront': {
  //   plan: 'Pro Plan (billed annually)',
  //   planAmount: 10788,
  //   discountAmount: 2697,
  //   discountLabel: 'Annual Discount (25%)',
  //   featureSet: 'pro',
  //   annual: true,
  // },
}

class UpgradePlanFullModal extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    customer: object.isRequired,
    invoices: object.isRequired,
    card: object.isRequired,
    location: object.isRequired,
    match: object.isRequired,
    smsActions: object.isRequired,
    sms: object.isRequired,
  }

  state = {
    agreementChecked: false,
    includesSmsPlan: false,
    selectedSmsPlan: 0,
    // check if user is paying with shopify or stripe
    isStripePayment: false,
  }

  componentDidMount() {
    const {
      currentBrand, location, match: { params: { belt } }, smsActions,
    } = this.props
    const isMissingPayment = location.pathname.includes('missing-payment')
    this.setState({
      selectedPlan: isMissingPayment ? currentBrand.stripe_belt : belt,
      orderSummary: applySummaryPromos(defaultSummaryMap, currentBrand.stripe_belt)[isMissingPayment ? currentBrand.belt : belt],
    })

    // retrieve (current) sms plan
    smsActions.fetchBrandBank(currentBrand.id)

    axios
      .get(`/shopify-subscription/${currentBrand.id}/${belt}`)
      .then(subscriptionUrlRes => {
        if (subscriptionUrlRes && subscriptionUrlRes.data) {
          window.location.href = subscriptionUrlRes.data
        } else {
          this.setState({ isStripePayment: true })
        }
      })
  }

  back = () => BrowserHistoryDispatcher.goBack()

  toggleAgreement = () => {
    this.setState(prevState => ({ agreementChecked: !prevState.agreementChecked }))
  }

  onUpdate = ({ selectedPlan, smsPlan: selectedSmsPlan = 0 }) => {
    this.setState({ selectedSmsPlan })

    if (!selectedPlan) return

    this.setState({
      selectedPlan,
      orderSummary: applySummaryPromos(defaultSummaryMap, this.props.currentBrand.stripe_belt)[selectedPlan],
    })
  }

  getSmsInfo = selectedSource => {
    const {
      currentBrand,
      sms,
      card,
      customer,
    } = this.props

    const { creditCards = [] } = customer

    const { includesSmsPlan, selectedSmsPlan } = this.state

    const smsPlanInfo = includesSmsPlan && sms.brandSmsBank && sms.brandSmsBank.credits.info
    let smsPlan = smsPlanInfo
      ? smsPlanInfo.plans[selectedSmsPlan]
      : null

    // Figure out if user is adding new card or using saved card
    // Then lookup the right place for the NY Tax info
    const selectedCardDetails = creditCards.find(c => c.id === selectedSource) || {}
    const lookupLocation = card.adding ? currentBrand : selectedCardDetails

    if (includesSmsPlan && smsPlan) {
      smsPlan = {
        autoRenew: true,
        isNY: lookupLocation.isNY,
        taxRate: lookupLocation.taxRate,
        isUserPurchase: true,
        plan: smsPlan.credits,
        quantity: smsPlan.credits,
        price: smsPlanInfo.pricing,
      }
    }

    return includesSmsPlan ? smsPlan : null
  }

  onSubmit = async () => {
    const {
      currentBrand,
      match: { params: { belt } },
      location,
    } = this.props

    const { selectedPlan } = this.state

    const isMissingPayment = location.pathname.includes('missing-payment')
    const isPreferredTermsPlan = !!currentBrand.pendingPreferredTermsPlan

    const selectedSource = await CardDispatcher.updateBilling(true)

    if (isMissingPayment) {
      CurrentBrandDispatcher.updateCreditCard(currentBrand.stripe_belt)
    } else {
      CurrentBrandDispatcher.upgradeConfirm(
        selectedPlan || belt,
        currentBrand.isNY,
        isPreferredTermsPlan && currentBrand.pendingPreferredTermsPlan.discount_id,
        this.getSmsInfo(selectedSource)
      )
    }
  }

  setIncludesSmsPlan = includesSmsPlan => this.setState({ includesSmsPlan })

  getSmsPlanPricing = () => {
    const { selectedPlan } = this.state
    const { sms } = this.props

    return sms.brandSmsBank.credits.info.custom_pricing
      || smsCreditCostMapping[cleanPlanText(selectedPlan)]
  }

  render() {
    const {
      currentBrand, customer, invoices, card, location, sms,
    } = this.props

    const {
      selectedPlan,
      orderSummary,
      agreementChecked,
      includesSmsPlan,
      selectedSmsPlan,
      isStripePayment,
    } = this.state
    const { selectedSource, creditCards = [] } = customer

    if (!currentBrand.id) return <Redirect to="/" />

    if (!isStripePayment) return <Spinner needsBackground />

    const subscription = customer && customer.subscriptions && customer.subscriptions.data
      && customer.subscriptions.data.length ? customer.subscriptions.data[0] : null
    const trialing = isTrialing(currentBrand, customer)
    const isTrialConfirm = location.pathname.includes('upgrade-trial-confirm')
    const isMissingPayment = location.pathname.includes('missing-payment')
    const isCurrentB3G1 = currentBrand.stripe_belt && currentBrand.stripe_belt.includes('buy3-get1')

    if (currentBrand.stripe_belt === 'essential-12months-upfront') defaultPlans = defaultPlans.filter(plan => plan.value !== 'greenFixed')
    if (currentBrand.stripe_belt === 'premium-12month-upfront') defaultPlans = defaultPlans.filter(plan => plan.value !== 'black')

    // TODO: Eventually remove these 3?
    if (currentBrand.stripe_belt === 'premium-buy3-get1-upfront') defaultPlans = defaultPlans.filter(plan => plan.value !== 'essential-12months-upfront')
    if (currentBrand.stripe_belt === 'pro-12month-upfront') defaultPlans = defaultPlans.filter(plan => plan.value !== 'pro')
    if (currentBrand.stripe_belt === 'pro-buy3-get1-upfront') defaultPlans = defaultPlans.filter(plan => plan.value !== 'premium-12month-upfront')

    if (isCurrentB3G1) defaultPlans = defaultPlans.filter(plan => plan.value.includes('12month'))

    let plans = applyPlanPromos(defaultPlans, currentBrand.stripe_belt)
    const summaryMap = applySummaryPromos(defaultSummaryMap, currentBrand.stripe_belt)

    if (isTrialConfirm) {
      plans = plans.filter(plan => !plan.upgradeOnly)
    }

    const currentPlan = plans.findIndex(plan => plan.value === currentBrand.stripe_belt)
    const upgradePlans = [{
      label: 'Select which plan to upgrade to', value: '', disabled: true,
    }].concat(!subscription || trialing || currentPlan < 0 ? plans : plans.slice(currentPlan + 1))

    const canSave = !!selectedPlan
      && (isTrialConfirm || (agreementChecked && !!selectedSource))

    // Figure out if user is adding new card or using saved card
    // Then lookup the right place for the NY Tax info
    const selectedCardDetails = creditCards.find(c => c.id === selectedSource) || {}
    const lookupLocation = card.adding ? currentBrand : selectedCardDetails

    let preferredTermsPlan
    let preferredTermsSummary
    if (currentBrand.pendingPreferredTermsPlan) {
      const {
        plan_id, plan_label, plan_cost, discount_label, discount_amount,
      } = currentBrand.pendingPreferredTermsPlan
      const isPremium = cleanPlanText(plan_id) === 'black'
      const isPro = cleanPlanText(plan_id) === 'pro'
      preferredTermsPlan = [{
        label: plan_label,
        value: plan_id,
      }]
      preferredTermsSummary = {
        plan: plan_label,
        planAmount: plan_cost,
        discountAmount: discount_amount,
        discountLabel: discount_label,
        featureSet: isPro ? 'pro' : (isPremium ? 'premium' : 'essential'),
      }
    }

    let missingPaymentPlan
    let missingPaymentSummary
    if (isMissingPayment) {
      const isPremium = cleanPlanText(currentBrand.stripe_belt) === 'black'
      const isPro = cleanPlanText(currentBrand.stripe_belt) === 'pro'
      const planConfig = beltMapping[currentBrand.stripe_belt]
      const intervalMap = {
        monthly: 1, quarterly: 3, 'bi-annually': 6, annually: 12,
      }
      const planCost = (isPro ? 899 : (isPremium ? 499 : 199)) * (intervalMap[planConfig.interval]
      || planConfig.count)
      missingPaymentPlan = [{
        label: getPlanLabel(currentBrand.stripe_belt),
        value: currentBrand.stripe_belt,
      }]
      missingPaymentSummary = {
        plan: getPlanLabel(currentBrand.stripe_belt),
        planAmount: planCost,
        featureSet: isPro ? 'pro' : (isPremium ? 'premium' : 'essential'),
        ...((currentBrand.stripe_belt.includes(12) || currentBrand.stripe_belt.includes(6)) && {
          discountAmount: planCost * (currentBrand.stripe_belt.includes(12) ? 0.25 : 0.1),
          discountLabel: currentBrand.stripe_belt.includes(12) ? 'Annual Discount (25%)' : 'Bi-annual Discount (10%)',
        }),
      }
    }

    const isCurrentlyOnLTC = getLTCPlans().map(([value]) => value)
      .includes(currentBrand.stripe_belt)

    const billingChargedCopy = trialing
      ? `on your trial end date (${moment(currentBrand.trial_end).format('M/D/YYYY')})`
      : (isCurrentlyOnLTC && !isMissingPayment
        ? `at the end of your current plan term${currentBrand.renewalDate ? ` (${moment(currentBrand.renewalDate).format('M/D/YYYY')})` : ''}`
        : 'today')

    const paymentInterval = selectedPlan && beltMapping[selectedPlan].interval
    const renewalInterval = selectedPlan && (selectedPlan.includes(12)
      ? 'annually'
      : selectedPlan.includes(6)
        ? 'bi-annually'
        : 'monthly')

    const confirmationCopy = `Your account will be charged ${billingChargedCopy}${renewalInterval !== 'monthly' ? `. Your plan will be charged ${paymentInterval !== renewalInterval ? paymentInterval : 'upfront'}` : ''} and your subscription will automatically renew ${renewalInterval}.`

    const smsPlanInfo = includesSmsPlan && sms.brandSmsBank && sms.brandSmsBank.credits.info
    const hasSmsPlan = Boolean(sms.brandSmsBank && sms.brandSmsBank.credits.autorefill)
    const smsPlan = smsPlanInfo
      ? smsPlanInfo.plans[selectedSmsPlan]
      : null

    const orderSummaryObj = preferredTermsSummary || missingPaymentSummary || orderSummary
    let orderObj = null
    const smsPricing = includesSmsPlan
      ? this.getSmsPlanPricing()
      : 0

    if (orderSummaryObj) {
      orderObj = { ...orderSummaryObj }
      orderObj.extraItem = null
      orderObj.extraCost = 0

      if (includesSmsPlan && smsPlan) {
        orderObj.extraItem = {
          label: (
            <>
              <p>{`${d3.format(',')(smsPlan.credits)} Credits x $${smsPricing}`}</p>
              <p style={{ marginTop: 20, fontStyle: 'italic' }}>Auto-Refill</p>
            </>
          ),
          price: smsPlan.credits * smsPricing,
        }
        orderObj.hasBoostPack = sms.brandSmsBank.hasBoostPack
        orderObj.includesSmsPlan = true
        orderObj.extraCost = smsPlan.credits * smsPricing
      }
    }

    if (sms.loading) return <Spinner needsBackground />

    return (
      <div>
        {(customer.loading || card.loading) && <Spinner needsBackground />}
        <FullscreenModal
          title={isMissingPayment ? 'Confirm Payment Method to Continue' : 'Confirm Upgrade Details'}
          close={isMissingPayment ? null : this.back}
        >
          <SimpleForm
            model={this.state}
            updateModel={this.onUpdate}
            handleSubmit={this.onSubmit}
          >
            <div className={Theme.mainContainer}>
              <div className={Theme.reviewItems}>
                <div className={Theme.reviewItems__header}>
                  <H3 coral>
                    <small>{isMissingPayment ? 'Subscription' : 'Review Items'}</small>
                  </H3>
                </div>

                { isMissingPayment ? (
                  <P multiline>
                    Please update your billing to keep using DojoMojo’s most powerful features.
                  </P>
                ) : null }

                <div className={Theme.reviewItems__subsection}>
                  <div className={Theme.reviewItems__subsection__header}>
                    <H6>Brand</H6>
                  </div>
                  <div className={Theme.reviewItems__subsection__brandDisplay}>
                    <div className={Theme.reviewItems__subsection__brandName}>
                      <P>{currentBrand.accountname}</P>
                    </div>
                  </div>
                </div>

                { isMissingPayment ? (
                  <div className={Theme.reviewItems__subsection}>
                    <div className={Theme.reviewItems__subsection__header}>
                      <H6>Your Subscription</H6>
                    </div>
                    <div className={Theme.reviewItems__subsection__brandDisplay}>
                      <div className={Theme.reviewItems__subsection__brandName}>
                        <P>
                          {summaryMap[currentBrand.stripe_belt]
                            ? summaryMap[currentBrand.stripe_belt].simplePlan
                            : getPlanLabel(currentBrand.stripe_belt)}
                        </P>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={Theme.reviewItems__subsection}>
                    <Select
                      name="selectedPlan"
                      label={isTrialConfirm ? 'Trial plan' : 'Upgrade to'}
                      options={preferredTermsPlan || missingPaymentPlan || upgradePlans}
                      required
                    />
                  </div>
                )}

                {
                  !hasSmsPlan
                    && (
                      <AddSmsMarketingPlan
                        smsPricing={smsPricing}
                        withSmsPlan={includesSmsPlan}
                        setWithSmsPlan={this.setIncludesSmsPlan}
                        brandBank={sms.brandSmsBank}
                        selectedPlan={selectedPlan}
                        selectedSmsPlan={selectedSmsPlan}
                      />
                    )
                }

                { isTrialConfirm ? (
                  <H4>
                    <i>
                      No Payment Info Required
                    </i>
                  </H4>
                ) : (
                  <div>
                    <div>
                      <H3 coral>
                        <small>Payment Information</small>
                      </H3>
                    </div>

                    <div className={Theme.choosePaymentMethod}>
                      <ChooseCard />
                    </div>

                    <div className={Theme.agreement__checkbox}>
                      <Checkbox
                        onChange={this.toggleAgreement}
                        checked={agreementChecked}
                        theme={CheckboxTheme}
                      />

                      <div className={Theme.agreement__terms}>
                        <P style={{ lineHeight: '22px' }}>
                          <em>
                            {`${confirmationCopy} Note that customers based in New York will be charged sales tax. You can cancel anytime by emailing `}
                          </em>
                          <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
                          <em>.</em>
                        </P>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <OrderSummaryDisplay
                type="upgrade"
                upgradeOrderSummary={orderObj}
                chargeTax={lookupLocation.isNY}
                rate={lookupLocation.taxRate}
              />
            </div>

            <div className={FullscreenModalTheme.buttonGroupFixedBottom}>
              <ModalButtonGroupWithContext
                cancel={isMissingPayment ? null : this.back}
                cancelText="Back"
                confirmText={isTrialConfirm
                  ? 'Start 30 Day Free Trial'
                  : (isMissingPayment
                    ? 'Submit Payment'
                    : 'Upgrade Now')}
                canSave={canSave}
                hideLine
              />
            </div>
          </SimpleForm>
        </FullscreenModal>
      </div>
    )
  }
}

export default connect(mapStateToProps, dispatchToProps)(UpgradePlanFullModal)
