import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { func, object } from 'prop-types'
import {
  SimpleForm, Input, Select, Validations,
} from 'visual-components/util/form'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import { P, H6, Micro } from 'visual-components/util/texts'
import * as DialogActions from 'actions/dialog-actions'
import Spinner from 'util/components/spinner'
import InputThemes from 'css/themes/Input.css'
import AppDispatcher from 'dispatchers/app-dispatcher'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'

const mapStateToProps = ({ currentBrand, customer }) =>
  ({ currentBrand, customer })

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
})

const { isRoutingNumber, isBankAccount } = Validations

class ACHForm extends React.Component {
  static propTypes = {
    updateAccount: object,
    onClose: func.isRequired,
  }

  static defaultProps = {
    updateAccount: null,
  }

  state = {
    loading: false,
    ownerOrBusinessName: undefined,
    accountType: undefined,
    accountNumber: undefined,
    routingNumber: undefined,
  }

  accountTypeOptions = [
    { label: 'Select an option', value: '', disabled: true },
    { label: 'Company', value: 'company' },
    { label: 'Individual', value: 'individual' },
  ]

  componentDidMount() {
    const { updateAccount } = this.props
    if (updateAccount) {
      this.setState({
        ownerOrBusinessName: updateAccount.account_holder_name,
      })
    }
  }

  onClose = () => {
    const { onClose } = this.props
    onClose()
  }

  onSubmit = () => {
    const { updateAccount, onSuccess } = this.props
    const data = { ...this.state }

    this.setState({ error: null, loading: true })

    if (updateAccount) {
      CurrentBrandDispatcher.editSource(updateAccount.id, {
        account_holder_name: data.ownerOrBusinessName,
      }, () => {
        this.setState({ loading: false })
        onSuccess()
      })
      return null
    }

    return this.getToken(data)
  }

  getToken = async data => {
    const { onSuccess } = this.props
    const {
      ownerOrBusinessName,
      accountType,
      accountNumber,
      routingNumber,
    } = data

    const response = await stripe.createToken('bank_account', {
      country: 'us',
      currency: 'usd',
      account_holder_name: ownerOrBusinessName,
      account_holder_type: accountType,
      routing_number: routingNumber,
      account_number: accountNumber,
    })

    if (response.error) return this.setState({ error: response.error.message, loading: false })

    return AppDispatcher.http({
      url: '/add-bank-account-token',
      method: 'POST',
      data: {
        token: response.token,
      },
      success: () => {
        this.setState({ loading: false })
        AppDispatcher.load({
          model: 'customer',
          endpoint: '/customer',
        })
        onSuccess()
      },
      error: err => {
        this.setState({ error: err.responseText, loading: false })
      },
    })
  }

  inputOnChange = name => event => {
    const info = {}
    info[name] = event.target.value
    this.setState(info)
  }

  render() {
    const { updateAccount } = this.props
    const { loading, error } = this.state

    return (
      <div>
        { loading ? <Spinner /> : null }

        <SimpleForm
          model={this.state}
          updateModel={e => this.setState(e)}
          handleSubmit={this.onSubmit}
        >
          { updateAccount ? (
            <div>
              <div style={{ marginBottom: '8px' }}>
                <H6>
                  Bank Account Details
                </H6>
              </div>
              <div style={{ marginBottom: '32px' }}>
                <P>
                  {`${updateAccount.bank_name} - *****${updateAccount.last4}`}
                </P>
              </div>
            </div>
          ) : null }
          <Input
            name="ownerOrBusinessName"
            label="Owner or Business Name"
            className={InputThemes.textarea}
            type="string"
            required
          />

          { updateAccount ? null : (
            <div>
              <Select
                name="accountType"
                label="Account Type"
                options={this.accountTypeOptions}
              />

              <Input
                name="accountNumber"
                label="Account Number"
                className={InputThemes.textarea}
                required
                placeholder="000000000"
                validations={[isBankAccount]}
              />

              <Input
                name="routingNumber"
                label="Routing Number"
                className={InputThemes.textarea}
                required
                placeholder="000000000"
                validations={[isRoutingNumber]}
              />

              <P>
                <b>Verification is required. </b>
                Two small payments will be made to your account within 24 hours. You must return here and confirm the payment amounts in order to verify this account.
              </P>

              <div style={{ visibility: !error ? 'hidden' : 'visible', marginTop: '-20px', marginBottom: '32px' }}>
                <Micro alertRed>
                  {error}
                </Micro>
              </div>
            </div>
          )}

          <ModalButtonGroupWithContext
            cancel={this.onClose}
            cancelText="Cancel"
            confirmText={updateAccount ? 'Update Account' : 'Add Account'}
            canSave={formModal => {
              const {
                ownerOrBusinessName, accountType, accountNumber, routingNumber,
              } = formModal

              if (updateAccount) {
                return !!ownerOrBusinessName
              }

              return !!ownerOrBusinessName
                && !!accountType
                && !!accountNumber
                && !!routingNumber
            }}
          />
        </SimpleForm>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ACHForm)
