import TextThemes from 'css/themes/Text.css'
import cx from 'classnames'

export function checkClassNames(props) {
  const {
    azure,
    coral,
    cloudGrey,
    stormySkies,
    alertRed,
    white,
    seaGreen,
    neonMint,
    smaller,
    tiny,
    micro,
    multiline,
    right,
    overflowEllipsis,
    inactive,
    extralarge,
    buttonheight,
    medium,
  } = props

  const classNames = cx(TextThemes.base, {
    [TextThemes.azure]: azure,
    [TextThemes.coral]: coral,
    [TextThemes.cloudGrey]: cloudGrey,
    [TextThemes.alertRed]: alertRed,
    [TextThemes.white]: white,
    [TextThemes.seaGreen]: seaGreen,
    [TextThemes.neonMint]: neonMint,
    [TextThemes.stormySkies]: stormySkies,

    [TextThemes.smaller]: smaller,
    [TextThemes.tiny]: tiny,
    [TextThemes.micro]: micro,

    [TextThemes.multiline]: multiline,

    [TextThemes.inactive]: inactive,

    [TextThemes.right]: right,
    [TextThemes.overflowEllipsis]: overflowEllipsis,
    [TextThemes.extralarge]: extralarge,
    [TextThemes.buttonheight]: buttonheight,
    [TextThemes.medium]: medium,
  })

  return classNames
}
