import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Dialog from 'react-toolbox/lib/dialog'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import moment from 'moment-timezone'

import { hasTrialEnded, isTrialAboutToEnd } from 'util/brand-stripe-belt-helper'
import createSrcSet from 'util/create-srcset'
import TrialB3g1Modal from './trial-b3g1-modal-content'

import dialogTheme from 'css/themes/dialogs/trialEndingDialog.css'

const mapState = ({
  trialEndedModal,
  currentBrand,
  userBrands: { brands },
}) => ({ trialEndedModal, currentBrand, numBrands: brands.length })

class TrialEndedModal extends Component {
  componentDidMount() {
    const { currentBrand, location } = this.props
    const { pathname: path } = location

    const checkTrial = () => {
      const impersonating = sessionStorage.getItem('impersonating')
      const isInProtectedRoute = path.match(/(cs-admin|payment-methods|missing-payment|upgrade|elite-request|switch-brand)/)
      if (currentBrand.isSensei || impersonating || isInProtectedRoute) return false

      return hasTrialEnded() || isTrialAboutToEnd()
    }

    if (checkTrial()) this.openModal()
  }

  closeModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'trialEndedModal',
      data: {
        open: false,
      },
    })
  }

  openModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'trialEndedModal',
      data: {
        open: true,
      },
    })
  }

  dismissPaywall = () => {
    sessionStorage.setItem('withinTenDaysOfTrialEndPaywallDismissed', true)
    this.closeModal()
  }

  upgradePlan = () => {
    const { trialEndedModal: { status } } = this.props

    // TODO: bulk up this security -- anyone can currently just delete
    // the element from the DOM to bypass the paywall. Perhaps v2-app
    // should abort render() or something. Backend stuff needed too.

    EventTrackingDispatcher.track('app_upgrade_upgradeButton_expiringUpgradeModal')

    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: 'trialEndedModal',
      },
    })

    // if past due, replace rather than push so that clicking back
    // button doesn't get to a page where anything renders behind modal
    const action = status === 'past_due'
      ? BrowserHistoryDispatcher.replace
      : BrowserHistoryDispatcher.push

    this.dismissPaywall()
    action('/upgrade-plan')
  }

  onLinkClick = url => event => {
    event.preventDefault()
    this.dismissPaywall()
    BrowserHistoryDispatcher.push(url)
  }

  render() {
    const {
      currentBrand,
      trialEndedModal: { open, status },
      numBrands,
    } = this.props
    const isPastDue = status === 'past_due'
    let showModal = open

    const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */

    const remainingDays = Math.round(
      Math.abs(
        moment(currentBrand.trial_end)
          .add(isPastDue ? 10 : 0, 'days')._d.getTime() - new Date()
      ) / oneDay
    )

    if (isPastDue) {
      showModal = remainingDays > 0 && open
    } else {
      showModal = moment(currentBrand.trial_end).isAfter(moment()) && open
    }

    return (
      <Dialog
        active={showModal}
        onEscKeyDown={null}
        onOverlayClick={null}
        theme={dialogTheme}
        title={isPastDue
          ? 'Oh No! Your free trial has ended'
          : 'Your free trial is about to end'
        }
      >
        { !isPastDue && (
          <img
            onClick={this.dismissPaywall}
            className={dialogTheme.closeImage}
            src="/images/icons/close.svg"
          />
        ) }
        <Grid>
          <Row>
            <Col>
              <div style={{
                height: 252, width: 672, margin: '24px 0 24px -32px', textAlign: 'center',
              }}
              >
                <img
                  src="/images/pricing/tiger_megaphone.png"
                  srcSet={createSrcSet('/images/pricing/tiger_megaphone.png')}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </Col>
          </Row>
          <TrialB3g1Modal
            remainingDays={remainingDays}
            numBrands={numBrands}
            onBtnClick={this.upgradePlan}
            onLinkClick={this.onLinkClick}
          />
        </Grid>
      </Dialog>
    )
  }
}

export default connect(mapState)(withRouter(TrialEndedModal))
