import React from 'react'
import { object, string, node } from 'prop-types'
import { H3 } from 'visual-components/util/texts'
import IntegrationTheme from 'css/themes/integrations.css'
import IntegrationDetails from './integration-details'
import IntegrationSelect from './integration-select'
import IntegrationMergeTags from './integration-merge-tags'

class FullscreenModal extends React.Component {
  static propTypes = {
    integration: object.isRequired,
    children: node,
    subsection: string,
    utilityFunctions: object.isRequired,
  }

  static defaultProps = {
    subsection: 'details',
    children: null,
  }

  render() {
    const {
      integration,
      subsection,
      children,
      utilityFunctions,
    } = this.props

    const componentMap = {
      details: IntegrationDetails,
      select: IntegrationSelect,
      'merge-tags': IntegrationMergeTags,
    }

    const Component = componentMap[subsection || 'details']

    return (
      <div className={IntegrationTheme.fullscreenIntegrationModal}>
        <div className={IntegrationTheme.modalContent}>
          <div onClick={utilityFunctions.closeModal} className={IntegrationTheme.modalClose}>
            <img src="/images/icons/close.svg" />
          </div>

          <div className={IntegrationTheme.modalTitle}>
            <H3>
              <small>{`${integration.label} Integration`}</small>
            </H3>
          </div>

          { children
            || (
              <Component
                {...this.props}
                utilityFunctions={utilityFunctions}
              />
            )
          }
        </div>
      </div>
    )
  }
}

export default FullscreenModal
