import React from 'react';
import { bool } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Button from 'react-toolbox/lib/button';
import MenuSearchBar from './menu-search-bar/MenuSearchBar';
import COLOURS from 'util/colours';
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import appbarTheme from 'css/themes/homePage/appbarTheme.css';
import buttonTheme from 'css/themes/Buttons.css'

class RightSide extends React.Component {
  static propTypes = {
    lightBackground: bool
  }

  navigateRegister = () => {
    const { history } = this.props

    EventTrackingDispatcher.track('home_register_registerButton_navBarButton')

    window.location.href = '/app/register'
  }

  render() {
    const {
      lightBackground
    } = this.props;

    return(
      <div className={ appbarTheme.rightWrapper }>

        {/*
        hiding since there is no associated functionality

        <MenuSearchBar
          lightBackground={ lightBackground }
        />
        */}

        <a href="/app/login"
          className={ appbarTheme.loginLink }
          style={{
            color: lightBackground ? COLOURS.ink : COLOURS.white
        }}>
          Log In
        </a>

        <Button
          onClick={ this.navigateRegister }
          label="Start Free Trial"
          className={ buttonTheme.greenButton }
          theme={ buttonTheme }
          id={ appbarTheme.tryFreeButton }
          raised
        />
      </div>
    )
  }
}

export default withRouter(RightSide)
