import React, { useState } from 'react'
import injectCSS from 'react-jss'
import { Button } from 'react-toolbox/lib/button'
import PropTypes from 'prop-types'
import cn from 'classnames'

import CreateDropdownMenu from './create-dropdown-menu'

const styles = {
  arrow: {
    transition: 'transform .17s linear',
  },
  button: {
    backgroundColor: '#008DE6',
    borderRadius: '4px 0 0 4px',
    color: 'white !important',
    fontFamily: 'Larsseit',
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    lineHeight: '22px',
    padding: '0px 16px',
    textTransform: 'none',
    width: 109,
    '&:hover': {
      backgroundColor: '#008DE6 !important',
    },
  },
  container: {
    display: 'flex',
    position: 'relative',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  tab: {
    backgroundColor: '#008DE6',
    borderRadius: '0 4px 4px 0',
    height: 36,
    marginLeft: 4,
    minWidth: 0,
    padding: 0,
    width: 36,
    '&:hover': {
      backgroundColor: '#008DE6 !important',
    },
  },
}

const CreateDropdown = ({ classes, canViewSignupForm }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        label="Create New"
        onClick={() => setOpen(prevOpen => !prevOpen)}
        type="button"
      />
      <Button className={classes.tab} onClick={() => setOpen(prevOpen => !prevOpen)} type="button">
        <img
          alt=""
          className={cn(classes.arrow, { [classes.rotate]: open })}
          src="/images/select-arrow-light.svg"
        />
      </Button>
      <CreateDropdownMenu
        onClose={() => setOpen(false)}
        open={open}
        canViewSignupForm={canViewSignupForm}
      />
    </div>
  )
}

CreateDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  canViewSignupForm: PropTypes.bool.isRequired,
}

export default React.memo(injectCSS(styles)(CreateDropdown))
