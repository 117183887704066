import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'
import {
  P, H2, H6,
} from 'visual-components/util/texts'
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

const styles = {
  card: {
    width: '314px',
    height: '232px',
    marginBottom: '16px',
    overflow: 'visible',
  },
  column: {
    flexDirection: 'column',
  },
  dataContainer: {
    display: 'flex',
  },
  emptySummary: {
    height: '66px',
    width: '254px',
    textAlign: 'center',
    margin: '38px 0px',
  },
  summary: {
    padding: '20px 20px 19px 24px',
  },
  header: {
    marginBottom: '20px',
  },
  topItem: {
    marginBottom: '20px',
  },
}

function MobileMarketingSummaryCard(props) {
  const {
    data, oldestDate, datePicker, changeFilter, classes,
  } = props
  const newestDate = new Date()

  return (
    <Card className={classes.card}>
      <div className={classes.summary}>
        <div className={classes.header}>
          <AnalyticsHeader title="SMS Marketing Summary" />
        </div>
        {data.smsSubscribers > 0 ? (
          <div className={classes.dataContainer}>
            <div className={classes.column} style={{ marginRight: '19px' }}>
              <div className={classes.topItem}>
                <H6>SIGNUPS</H6>
                <H2 multiline>{makeNumbersPretty(data.smsSubscribers, true)}</H2>
              </div>
              <div>
                <H6>CLICKS</H6>
                <H2 multiline>{makeNumbersPretty(data.clicks, true)}</H2>
              </div>
            </div>
            <div className={classes.column}>
              <div className={classes.topItem}>
                <H6>MESSAGES SENT</H6>
                <H2 multiline>{makeNumbersPretty(data.messages, true)}</H2>
              </div>
              <div>
                <H6>CTR</H6>
                <H2 multiline>
                  {makeNumbersPretty(data.messages > 0 ? data.clicks / data.messages * 100 : 0)}
                  %
                </H2>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.emptySummary}>
            <P multiline cloudGrey>
              <i>
                Mobile Marketing allows hosts to send mobile messages to sweepstakes entrants.
                {' '}
                <a href="/">Learn more</a>
              </i>
            </P>
          </div>
        )}
      </div>
      <Grid>
        <CardDateRangePickerRow
          oldestDate={oldestDate}
          newestDate={newestDate}
          changeFilter={changeFilter}
          {...datePicker}
        />
      </Grid>
    </Card>
  )
}

MobileMarketingSummaryCard.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  datePicker: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  oldestDate: PropTypes.instanceOf(Date).isRequired,
}

export default injectSheet(styles)(MobileMarketingSummaryCard)
