import React from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import CampaignPreviewPartnerLogo from './campaign-preview-partner-logo';
import CampaignPreviewXMore from './campaign-preview-x-more';

class CampaignPreviewPartnerList extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var {
      hostBrandImage,
      partnerImages
    } = this.props;

    var numberOfImages = 4;
    var partnerCount = partnerImages.length;

    if (partnerCount > 4) {
      numberOfImages = 3;
    }

    var formattedPartnerLogos = _.map(partnerImages.slice(0, numberOfImages), function(img, idx) {
      var marginTop = idx > 1 ? '4px' : '0';

      return(
        <CampaignPreviewPartnerLogo
          key={ idx }
          logo={ img }
          marginTop={ marginTop }
        />
      )
    });

    if (partnerCount - 4 > 0) {
      formattedPartnerLogos.push(
        <CampaignPreviewXMore
          partnerCount={ partnerCount - 3 }
          key={ 'seeMore' }
        />
      )
    }

    return(
      <Grid>
        <Row>
          <Col xs={6}>
            <div style={{
              width: '64px',
              height: '64px',
              maxWidth: '64px',
              maxHeight: '64px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: COLOURS.silver,
              display: 'table-cell',
              verticalAlign: 'middle'
            }}>
              <img src={ hostBrandImage }
                style={{
                  maxHeight: '48px',
                  maxWidth: '48px',
                  display: 'block',
                  margin: 'auto'
              }}/>
            </div>
          </Col>
          <Col xs={6}>
            <Grid>
              <Row>
                { formattedPartnerLogos }
              </Row>
            </Grid>
          </Col>
        </Row>
      </Grid>
    )
  }
};

CampaignPreviewPartnerList.propTypes = {
  partnerImages: PropTypes.array.isRequired,
  hostBrandImage: PropTypes.string.isRequired
}

export default CampaignPreviewPartnerList;
