import { useState, useEffect } from 'react'
import axios from 'axios'
import requestHeaders from 'request-config'
import { sum } from 'lodash'

const sortPartners = (p1, p2) => p1.accountname.localeCompare(p2.accountname)

function useSplitTraffic(campaignId) {
  const [host, setHost] = useState({})
  const [partners, setPartners] = useState([])
  const [traffic, setTraffic] = useState({ host: 100, active: 0, inactive: 0 })
  const [isLoading, setIsLoading] = useState(true)

  // uses partners to update the total split percentages for the graphs
  const updateTraffic = newPartners => {
    const trafficData = newPartners.reduce((stats, partner) => {
      let trafficStatus = 'inactive'
      if (partner.status === 'active' && partner.isPostEntryReady) {
        trafficStatus = 'active'
      }
      stats[trafficStatus] += partner.traffic
      return stats
    }, {
      host: 0,
      active: 0,
      inactive: 0,
    })

    trafficData.host = 100 - trafficData.active - trafficData.inactive
    setTraffic(trafficData)
  }

  const onUpdatePartnerField = (partnerBrandId, property, newStatus) => {
    setPartners(prevPartners => {
      const newPartners = [...prevPartners]
      const partner = newPartners.find(partnerSearch => partnerSearch.id === partnerBrandId)
      partner[property] = newStatus
      updateTraffic(newPartners)
      return newPartners.sort(sortPartners)
    })
  }

  const onPercentChange = (partnerBrandId, newPercent) => {
    setPartners(prevPartners => {
      const newPartners = [...prevPartners]
      const partner = newPartners.find(partnerSearch => partnerSearch.id === partnerBrandId)
      partner.traffic = newPercent
      partner.isChanged = true
      partner.error = false
      if (!Number.isInteger(newPercent)) {
        partner.error = true
      } else if (newPercent > partner.maxTraffic) {
        partner.error = `Max traffic ${partner.maxTraffic}`
      } else if (sum(newPartners.map(p => p.traffic)) > 100) {
        partner.error = 'Total over 100%'
      }
      if (!partner.error) {
        updateTraffic(newPartners)
      }
      return newPartners.sort(sortPartners)
    })
  }

  useEffect(() => {
    setIsLoading(true)
    axios.get(`/partnerships/split-traffic/${campaignId}`, {
      headers: requestHeaders(),
    })
      .then(res => {
        const campaignBrands = res.data.map(invite => ({
          id: invite.invitee.id,
          logo: invite.invitee.logo,
          accountname: invite.invitee.accountname,
          error: false,
          isChanged: false,
          state: null, // inviteSending | inviteSent | saving | saved | null
          maxTraffic: 100,
          inviteId: invite.id,
          traffic: invite.success_page_proportion * 100,
          status: invite.split_traffic_invite_status,
          isHost: invite.hostbrand_id === null,
          // post entry is ready unless partner has an sms post entry and it's not published.
          isPostEntryReady: invite.ctaType !== 'mobileMarketing' || (invite.mobileMarketing && invite.mobileMarketing.smsSend.status === 'published'),
          postEntryStatus: (invite.mobileMarketing && invite.mobileMarketing.smsSend.status),
        }))
        const partnerData = campaignBrands.filter(brand => !brand.isHost)
        const hostData = campaignBrands.find(brand => brand.isHost)
        setPartners(partnerData.sort(sortPartners))
        //extract the host
        updateTraffic(partnerData)
        //find host
        setHost(hostData)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setPartners])

  const onSavePartner = async id => {
    const partner = partners.find(p => p.id === id)
    if (partner) {
      if (partner.error) {
        toastr.error('Please fix the errors to save', null, { timeOut: 10000, positionClass: 'toast-bottom-center' })
        return
      }

      // set the state of partner to saving
      onUpdatePartnerField(partner.id, 'state', partner.isChanged ? 'saving' : null)

      try {
        await axios.put(
          `/partnerships/split-traffic/${campaignId}`,
          [{ inviteId: partner.inviteId, percent: partner.traffic }],
          { headers: requestHeaders() }
        )
      } catch (err) {
        toastr.error('Split Traffic total over 100%', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
        onUpdatePartnerField(partner.id, 'state', null)
        onUpdatePartnerField(partner.id, 'isChanged', false)
        return
      }


      // set partner who was in saving state to saved
      onUpdatePartnerField(partner.id, 'state', 'saved')
      onUpdatePartnerField(partner.id, 'isChanged', false)
    }
  }

  return {
    host,
    partners,
    traffic,
    isLoading,
    onSavePartner,
    onPercentChange,
    onUpdatePartnerField,
  }
}

export default useSplitTraffic
