import React from 'react'
import { connect } from 'react-redux'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import TrackingItem from 'visual-components/trackingLinks/tracking-item'
import TrackingLinkModal from 'visual-components/trackingLinks/tracking-link-modal'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import Dropdown from 'react-toolbox/lib/dropdown'
import { H3, H6 } from 'visual-components/util/texts'

import ButtonTheme from 'css/themes/Buttons.css'
import DropdownTheme from 'css/themes/dropdownFilter.css'

import CampaignDispatcher from 'dispatchers/campaign-dispatcher'

const mapStateToProps = ({ currentCampaign, currentBrand, promotionalUnits }) =>
  ({ currentCampaign, currentBrand, promotionalUnits })

class TrackingLinks extends React.Component {
  state = {
    selectedBrand: this.props.currentBrand.id
  }

  customItem = item => {
    const { selectedBrand } = this.state
    /* this breaks out of the normal loop, allowing for customization of the chosen value */
    return (
      <div className={ DropdownTheme.linksItem }>
        { item.value == selectedBrand && item.label.length > 36 ? item.label.substring(0, 36) + '...' : item.label }
      </div>
    )
  }

  updateSelectedBrand = value => {
    this.setState({
      selectedBrand: value
    })
  }

  createLink = () => {
    const { selectedBrand } = this.state

    store.dispatch({
      model: 'promotionalUnit',
      type: 'CREATE_PROMO',
      data: {
        brand: selectedBrand
      }
    })
  }

  editLink = unit => {
    store.dispatch({
      model: 'promotionalUnit',
      type: 'EDIT_PROMO',
      data: unit
    })
  }

  deleteLink = unit => {
    store.dispatch({
      model: 'promotionalUnit',
      type: 'OPEN_REMOVE_MODAL',
      data: unit
    })
  }

  render() {
    const {
      currentCampaign: { id, hostBrandId, startDate, endDate, invites, isSolo },
      currentBrand: { id: brandId },
      promotionalUnits
    } = this.props

    const { selectedBrand } = this.state

    const isHost = hostBrandId === brandId

    const filteredItems = promotionalUnits.items.filter(item => item.promotionaltype !== 'Preview Promotion'
      && item.hidden === false && item.brand_id === selectedBrand)
    const trackingItems = filteredItems.map((item, i) => {
      return (
        <TrackingItem unit={ item }
          editLink={ this.editLink }
          deleteLink={ this.deleteLink }
          key={ i }
          idx={ i }
        />
      )
    })

    const filteredInvites = invites.filter(invite => invite.status === 'accepted')
    const options = isHost && !isSolo ? filteredInvites.map(invite => ({
      label: invite.invitee.accountname,
      value: invite.invitee_id
    })) : []

    return (
      <div style={{
        marginBottom: '24px'
      }}>
        <Card style={{
          overflow: 'visible',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)'
        }}>
          <Row style={{
            padding: '16px 20px 0px 20px'
          }}>
            <Col style={{
              width: '986px'
            }}>
              <div style={{
                display: 'inline-block'
              }}>
                <H3 coral>
                  <small>
                    { `Tracking Links${options.length ? ' -  ' : ''}` }
                  </small>
                </H3>
              </div>
              {
                options.length ?
                <div style={{
                  display: 'inline-block',
                  paddingBottom: '18px',
                }}>
                  <Dropdown
                    auto
                    onChange={ this.updateSelectedBrand }
                    source={ options }
                    value={ selectedBrand }
                    theme={ DropdownTheme }
                    className={ DropdownTheme.linksDropdown }
                    template={ this.customItem }
                  />
                </div>
                : null
              }
            </Col>
            <Col style={{
              width: '127px'
            }}>
              <div style={{
                float: 'right'
              }}>
                <Button onClick={ this.createLink }
                  label={ 'Create New Link' }
                  className={ ButtonTheme.whiteButton }
                  theme={ ButtonTheme }
                  primary
                  raised
                />
              </div>
            </Col>
          </Row>
          <Row style={{
            marginTop: '-10px',
            padding: '0px 20px 20px 20px'
          }}>
            {
              trackingItems.length ?
              trackingItems
              : null
            }
          </Row>
        </Card>
        <TrackingLinkModal isHost={ isHost }
          selectedBrand={ selectedBrand }
          campaignId={ id }
          startDate={ startDate }
          endDate={ endDate }
        />
      </div>
    )
  }
}

export default connect(mapStateToProps)(TrackingLinks)
