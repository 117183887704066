import { rangeDefaults, radioDefaults, checkboxDefaults } from './helpers'

const campaignAnalyticsSearch = {
  page: 1,
  size: 16,
  total: 0,
  searchValue: '',
  sortBy: {
    value: 'startDate',
    asc: false,
    options: [
      {
        value: 'startDate',
        label: 'Start Date',
      },
      {
        value: 'name',
        label: 'A-Z',
      },
      {
        value: 'hostBrandName',
        label: 'Host Brand (name)',
      },
      {
        value: 'totalEntries',
        label: 'Total Entries',
      },
      {
        value: 'partners.ntf',
        label: 'Total NTF',
      },
    ],
    defaultDirections: {
      name: true,
      hostBrandName: true,
    },
  },
  filtersExpanded: {
    dateRange: true,
    totalVisits: true,
    totalEntries: true,
    percentNTF: false,
    numberOfPartners: false,
    industry: false,
  },
  dateRange: radioDefaults({
    term: 'startDate',
    value: 'all',
    customValue: null,
    options: [
      {
        label: 'In the last 90 days',
        value: '90',
      },
      {
        label: 'In the last 180 days',
        value: '180',
      },
      {
        label: 'In the last 12 months',
        value: '365',
      },
      {
        label: 'All time',
        value: 'all',
      },
      {
        label: 'Custom',
        value: 'custom',
      },
    ],
  }),
  totalVisits: rangeDefaults({ term: 'totalVisits' }),
  totalEntries: rangeDefaults({ term: 'partners.total_entries_delivered' }),
  percentNTF: rangeDefaults({ term: 'partners.ntf_perc' }),
  numberOfPartners: rangeDefaults({ term: 'numberOfPartners' }),
  industry: checkboxDefaults({ term: 'industry.raw' }),

  // other aggregations
  allVisits: { value: 0 },
  allSmsSubscribers: { value: 0 },
  allEntries: { value: 0 },
  partners: { currentBrand: {
    allNTF: { value: 0 },
    totalEntriesDelivered: { value: 0 },
    conversion_revenue: { value: 0 },
    conversion_transactions: { value: 0 },
    sms_subscribes: { value: 0 },
  } },

  // grid/list view
  gridView: 'grid',
}

export default campaignAnalyticsSearch
