const initialState = {
  rid: 'dataDisclosurePolicy',
  loading: false,
  error: null,
  uploadModalOpen: false,
}

function dataDisclosurePolicyReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_DATA_DISCLOSURE_POLICY':
      return { ...state, loading: true }

    case 'RESET_DATA_DISCLOSURE_POLICY':
      return { ...state, tempPolicy: null, uploadModalOpen: false }

    case 'OPEN_DATA_DISCLOSURE_POLICY_MODAL':
      return { ...state, uploadModalOpen: true }

    case 'CLOSE_DATA_DISCLOSURE_POLICY_MODAL':
      return { ...state, uploadModalOpen: false }

    case 'UPDATE_DATA_DISCLOSURE_POLICY':
      return { ...state, tempPolicy: { ...state.tempPolicy, ...action.data } }

    case 'LOAD_DATA_DISCLOSURE_POLICY_SUCCESS':
      return { ...state, policy: action.payload, tempPolicy: action.payload, loading: false }

    case 'SUBMIT_DATA_DISCLOSURE_POLICY_SUCCESS':
      return { ...state, policy: action.payload, loading: false }

    case 'LOAD_DATA_DISCLOSURE_POLICY_FAILURE':
    case 'SUBMIT_DATA_DISCLOSURE_POLICY_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response
          ? `${action.payload.response.status}: ${action.payload.response.data}`
          : 'Error',
      }

    default:
      return state
  }
}

export default dataDisclosurePolicyReducer
