import React from 'react'
import { Card } from 'react-toolbox/lib/card'

import { H4, Small } from 'visual-components/util/texts'
import SocialTheme from 'css/themes/SocialGrowth.css'
import PropTypes from 'prop-types'

function ProgressSteps(props) {
  const { isHost } = props

  if (isHost) {
    return (
      <Card className={SocialTheme.progressSteps}>
        <div className={SocialTheme.stepContent}>
          <div className={SocialTheme.greenCircle}>1</div>
          <div className={SocialTheme.progressStepsText}>
            <H4>
              <b className={SocialTheme.progressLabel}>Set Conversion Action</b>
            </H4>
            <Small>Drive additional conversions and site traffic.</Small>
          </div>
        </div>
        <div className={SocialTheme.stepContent}>
          <div className={SocialTheme.greenCircle}>2</div>
          <div className={SocialTheme.progressStepsText}>
            <H4>
              <b className={SocialTheme.progressLabel}>Add Social Growth</b>
            </H4>
            <Small>Boost your social channels.</Small>
          </div>
        </div>
      </Card>
    )
  }
  return null
}

ProgressSteps.propTypes = {
  isHost: PropTypes.bool.isRequired,
}

export default ProgressSteps
