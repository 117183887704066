import COLOURS from 'util/colours'

// how to use it: <SplitTrafficBadge isStatus type={TYPE} />

export default type => {
  const uppercasedType = type.split(' ').map(word => `${word[0].toUpperCase()}${word.slice(1)}`).join(' ')
  switch (type) {
    case 'ready':
    case 'active':
      return {
        label: 'Ready',
        color: COLOURS.seaGreen,
      }
    case 'live':
      return {
        label: uppercasedType,
        color: COLOURS.seaGreen,
      }
    case 'not ready':
    case 'not live':
      return {
        label: uppercasedType,
        color: COLOURS.alertRed,
      }
    case 'accepted':
    case 'completed':
      return {
        label: uppercasedType,
        color: COLOURS.azure,
      }
    case 'declined':
      return {
        label: uppercasedType,
        color: COLOURS.stone,
      }
    case 'not invited':
      return {
        label: uppercasedType,
        color: COLOURS.foggy,
        grey: true,
      }
    case 'invited':
      return {
        label: uppercasedType,
        color: COLOURS.cloudGrey,
      }
    default:
      return {}
  }
}
