import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import COLOURS from 'util/colours';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import ProfileSideCard from 'visual-components/util/profile-side-card';
import ProfileStat from 'visual-components/util/small-profile-stat';

class AudienceCard extends React.Component {
  state = {
    isMouseInside: false,
    EditAudience: () => null,
  }

  mouseEnter = () => {
    this.setState({
      isMouseInside: true
    });
  }
  mouseExit = () => {
    this.setState({
      isMouseInside: false
    });
  }

  componentDidMount() {
    this.setState({
      EditAudience: require('visual-components/brandProfile/EditAudience').default,
    })
  }

  render() {
    var {
      dedicatedListSize,
      isBrand,
      obscure,
      averageSignUpsPerCampaign
    } = this.props;

    return(
      <div
        onMouseEnter={ this.mouseEnter }
        onMouseLeave={ this.mouseExit }
         style={{
        marginTop: '16px'
      }}>
        <ProfileSideCard title={ 'Audience Size' }>
          <div>
            <this.state.EditAudience
              show={ isBrand }
              hovered={ this.state.isMouseInside }
            />
            <div>
              <Grid>
                <Row>
                  <Col
                    xs={6}>
                    <ProfileStat
                      title={ 'LIST SIZE' }
                      obscure={obscure}
                      value={ dedicatedListSize }
                    />
                  </Col>
                  <Col
                    xs={6}>
                    <ProfileStat
                      title={ 'AVG ENTRIES' }
                      marginRight="0px"
                      obscure={obscure}
                      value={ averageSignUpsPerCampaign }
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        </ProfileSideCard>
      </div>
    )
  }
}

AudienceCard.propTypes = {
  dedicatedListSize: PropTypes.any.isRequired,
  averageSignUpsPerCampaign: PropTypes.any.isRequired
};

export default AudienceCard;
