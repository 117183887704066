import React from 'react'
import { Card } from 'react-toolbox/lib/card'
import { H4, Small } from 'visual-components/util/texts'
import AssetsTheme from 'css/themes/media-market/Assets.css'

class Assets extends React.Component {
  render() {
    return (
      <Card className={AssetsTheme.container}>
        <div className={AssetsTheme.title}>
          <H4 coral><em>Shared Assets</em></H4>
        </div>

        <div className={AssetsTheme.emptyState}>
          <Small multiline cloudGrey>
            You will be able to upload and collaborate on creative assets once the deal is accepted.
          </Small>
        </div>
      </Card>
    )
  }
}

export default Assets
