import React, { Component } from 'react'
import { string, oneOfType, object, bool, func } from 'prop-types'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import RelativeTime from 'visual-components/util/relative-time'
import COLOURS from 'util/colours'
import moment from 'moment-timezone'

import { ListItem } from 'react-toolbox/lib/list'
import LinkOrSwitchBrand from './LinkOrSwitchBrand'

import NotificationTheme from 'css/themes/NotificationTheme.css'

class NotificationShell extends Component {
  static propTypes = {
    date: oneOfType([ string, object ]),
    read: bool.isRequired,
    to: string.isRequired,
    handleRead: func.isRequired,
    text: string.isRequired,
  }

  read = () => {
    const { read, id, handleRead } = this.props
    if (!read) {
      handleRead(id)
    }
  }

  render() {
    const {
      img,
      text,
      date,
      read,
      to,
      brandId,
      id,
    } = this.props

    return(
      <LinkOrSwitchBrand
        to={ to }
        brandId={ brandId }
        onClick={ this.read }
      >
        <ListItem
          theme={ NotificationTheme }
          className={ read ? '' : NotificationTheme.unread }
        >
          <Row style={{
            padding: '16px',
            borderBottom: `1px solid ${COLOURS.silver}`,
            cursor: 'pointer',
          }}>
            <Col style={{
              width: '40px',
              display: 'inline'
            }}>
              <div style={{
                width: '40px',
                height: '40px',
                padding: '4px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: COLOURS.silver,
                background: COLOURS.white,
                display: 'table-cell',
                verticalAlign: 'middle'
              }}>
                <img src={ img || '/images/defaults/mojo-white-tiger-logo@2x.png' }
                  style={{
                    maxHeight: '32px',
                    maxWidth: '32px',
                    display: 'block',
                    margin: 'auto'
                  }}/>
              </div>
            </Col>
            <Col style={{
              width: '564px',
              display: 'inline-flex',
              verticalAlign: 'top',
              whiteSpace: 'initial'
            }}>
              <div
                style={{ padding: '0 12px' }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </Col>
            <Col style={{
              width: '114px',
              display: 'inline-flex',
              verticalAlign: 'top',
            }}>
              <p style={{
                fontFamily: 'Larsseit-Light',
                color: COLOURS.ink,
                fontSize: '12px',
                textAlign: 'right',
                width: '100%'
              }}>
                <RelativeTime time={ moment(date) } />
              </p>
            </Col>
          </Row>

        </ListItem>
      </LinkOrSwitchBrand>
    )
  }
}

export default NotificationShell
