import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { debounce } from 'lodash'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import InDiscussionCampaigns from 'visual-components/myPartnerships/InDiscussionCampaigns'
import CurrentCampaigns from 'visual-components/myPartnerships/CurrentCampaigns'
import PastCampaigns from 'visual-components/myPartnerships/PastCampaigns'

const mapState = ({
  currentCampaigns,
  upcomingCampaigns,
  currentBrand,
  brandCampaigns,
  pastCampaigns,
  inDiscussionCampaigns,
}) => ({
  currentCampaigns,
  upcomingCampaigns,
  currentBrand,
  brandCampaigns,
  pastCampaigns,
  inDiscussionCampaigns,
})

class Sweepstakes extends Component {
  static propTypes = {
    brandCampaigns: PropTypes.object,
    closeModal: PropTypes.func,
    confirmDeleteCampaign: PropTypes.func.isRequired,
    createCampaign: PropTypes.func,
    currentBrand: PropTypes.object.isRequired,
    currentCampaigns: PropTypes.object,
    sortByOptions: PropTypes.array,
    upcomingCampaigns: PropTypes.object,
    pastCampaigns: PropTypes.object,
    inDiscussionCampaigns: PropTypes.object,
    history: PropTypes.object.isRequired,
    signAgreement: PropTypes.func,
    match: PropTypes.object.isRequired,
  }

  static defaultProps = {
    brandCampaigns: null,
    closeModal() {},
    createCampaign() {},
    currentCampaigns: null,
    sortByOptions: null,
    upcomingCampaigns: null,
    pastCampaigns: null,
    inDiscussionCampaigns: null,
    signAgreement() {},
  }

  state = {
    filterValues: {
      search: '',
      hosting: false,
      sort: 'startDate',
    },
  }

  componentDidMount() {
    CampaignDispatcher.loadCampaignsData()
    this.loadSweepstakes()
    this.timer = null
  }

  componentDidUpdate(prevProps) {
    const {
      currentCampaigns, upcomingCampaigns, pastCampaigns, inDiscussionCampaigns,
    } = this.props

    if (
      currentCampaigns !== prevProps.currentCampaigns
      || upcomingCampaigns !== prevProps.upcomingCampaigns
      || pastCampaigns !== prevProps.pastCampaigns
      || inDiscussionCampaigns !== prevProps.inDiscussionCampaigns
    ) {
      this.loadSweepstakes()
    }
  }

  searchCampaigns = value => {
    clearTimeout(this.timer)
    this.setState(state => ({
      filterValues: {
        search: value,
        hosting: state.filterValues.hosting,
        sort: state.filterValues.sort,
      },
    }))
    this.timer = setTimeout(() => {
      CampaignDispatcher.filterCampaigns(this.state.filterValues)
    }, 300)
  }

  navigateTo = link => {
    const { history } = this.props
    history.push(link)
  }

  sortCampaigns = (x, value) => {
    const { filterValues } = this.state

    this.setState({
      filterValues: {
        search: filterValues.search,
        hosting: filterValues.hosting,
        sort: value,
      },
    })

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'currentCampaigns',
      data: {
        sort: value,
      },
    })

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'upcomingCampaigns',
      data: {
        sort: value,
      },
    })

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'pastCampaigns',
      data: {
        sort: value,
      },
    })

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'inDiscussionCampaigns',
      data: {
        sort: value,
      },
    })
  }

  filterCampaigns = value => {
    const { filterValues } = this.state

    const newFilterValues = {
      search: filterValues.search,
      hosting: value,
      sort: filterValues.sort,
    }

    this.setState({
      filterValues: newFilterValues,
    })

    CampaignDispatcher.filterCampaigns(newFilterValues)
  }

  loadSweepstakes = () => {
    const {
      confirmDeleteCampaign,
      signAgreement,
      closeModal,
      inDiscussionCampaigns,
      currentCampaigns,
      upcomingCampaigns,
      pastCampaigns,
      currentBrand,
      brandCampaigns,
    } = this.props

    const { match: { url } } = this.props
    let type = 'all'
    if (url === '/partnerships/current') {
      type = 'current'
    } else if (url === '/partnerships/past') {
      type = 'past'
    } else if (url === '/partnerships/in-discussion') {
      type = 'in-discussion'
    }

    const sweepstakes = {}
    sweepstakes['in-discussion'] = (
      <InDiscussionCampaigns
        signAgreement={signAgreement}
        inDiscussionCampaigns={inDiscussionCampaigns}
      />
    )
    sweepstakes.current = (
      <CurrentCampaigns
        confirmDeleteCampaign={confirmDeleteCampaign}
        closeModal={closeModal}
        currentCampaigns={currentCampaigns}
        upcomingCampaigns={upcomingCampaigns}
      />
    )
    sweepstakes.past = (
      <PastCampaigns
        pastCampaigns={pastCampaigns}
        currentBrand={currentBrand}
        brandCampaigns={brandCampaigns}
      />
    )
    if (type === 'all') {
      return Object.values(sweepstakes)
    }
    return [sweepstakes[type]]
  }


  render() {
    const {
      createCampaign,
      currentCampaigns,
      upcomingCampaigns,
      pastCampaigns,
      inDiscussionCampaigns,
      sortByOptions,
      match: { url },
    } = this.props

    const { filterValues } = this.state

    let errorText = ''
    let showPartnershipButton = false
    let showFilter = 'all'
    if (url === '/partnerships/sweepstakes') {
      showFilter = 'all'
    } else if (url === '/partnerships/current') {
      showFilter = 'current'
    } else if (url === '/partnerships/past') {
      showFilter = 'past'
    } else if (url === '/partnerships/in-discussion') {
      showFilter = 'in-discussion'
    }

    if (
      !currentCampaigns.campaigns.length
      && !upcomingCampaigns.campaigns.length
      && !pastCampaigns.campaigns.length
      && !inDiscussionCampaigns.campaigns.length
    ) {
      errorText = 'Create a sweepstakes to get started, or search to explore sweepstakes in the DojoMojo network.'
      showPartnershipButton = true
      if (filterValues.search) {
        errorText = 'Whoops! We don’t have any results for that search. Try expanding your search for more results.'
      } else if (filterValues.hosting) {
        errorText = 'It looks like you haven’t hosted a campaign yet. Want to give it a try? Create a Campaign to get started.'
      }
    }

    return (
      <div>
        <PartnershipsContainer
          title=" My Sweepstakes"
          action={createCampaign}
          actionLabel="Create Sweepstakes"
          search={this.searchCampaigns}
          searchLabel="Search your sweepstakes"
          sort={this.sortCampaigns}
          sortByOptions={sortByOptions}
          filter={this.filterCampaigns}
          filterLabel="Hosted Campaigns"
          filterValues={filterValues}
          errorText={errorText}
          prefix="/partnerships"
          showFilter={showFilter}
          showPartnershipButton={showPartnershipButton}
        />
        {!errorText && this.loadSweepstakes()}
      </div>
    )
  }
}
export default withRouter(connect(mapState)(Sweepstakes))
