import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import ProfileSideCard from 'visual-components/util/profile-side-card'
import EditAboutContainer from 'container-components/brands/EditAboutContainer'
import BlueTags from 'visual-components/util/tags/BlueTags'

import { H6, Small } from 'visual-components/util/texts'
import Stars from 'visual-components/util/stars'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

import ReviewsTheme from 'css/themes/ratings-reviews.css'

const degreeIconHash = {
  '1st': '/images/icons/degree-of-separation-icons/1-st-degree-connection-icon.svg',
  '2nd': '/images/icons/degree-of-separation-icons/mutual-connection-icon.svg',
  '3rd': '/images/icons/degree-of-separation-icons/never-worked-with-icon.svg',
}

const degreeTextHash = {
  '1st': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 1st Degree: You’ve partnered with this brand before and may have already acquired much of their audience.',
  '2nd': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 2nd Degree: It’s possible their audience may overlap with yours - you’ve partnered with someone who has worked with them.',
  '3rd': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 3rd Degree: Go for it! This brand isn’t connected to you and their audience will be full of new emails.',
}

const stateToProps = ({
  currentBrand,
}) => ({
  currentBrand,
})

const OverallRating = ({ brandId, rating, reviewsTotal, obscure }) => {
  if (!reviewsTotal || obscure) return null

  return (
    <div>
      <H6>
        Overall Rating
      </H6>
      <div className={`${ReviewsTheme.flex} ${ReviewsTheme.cardOverall}`}>
        <Stars
          className={ReviewsTheme.cstmRatingStars}
          value={rating}
          style={{ margin: '-4px 0 0' }}
        />
        <Small>
          <em>
            <Link to={`/explore/brands/${brandId}/reviews`}>
              { makeNumbersPretty(reviewsTotal, true) }
            </Link>
          </em>
        </Small>
      </div>
    </div>
  )
}

class AboutCard extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    degree: PropTypes.string,
    website: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    isBrand: PropTypes.bool.isRequired,
    ratingCardInfo: PropTypes.object,
  }

  static defaultProps = {
    degree: null,
    ratingCardInfo: { shouldShow: false },
  }

  state = {
    isMouseInside: false,
  }

  mouseEnter = () => this.setState({ isMouseInside: true })

  mouseExit = () => this.setState({ isMouseInside: false })

  render() {
    const {
      description,
      degree,
      tags,
      website,
      isBrand,
      ratingCardInfo,
    } = this.props

    const { isMouseInside } = this.state

    const whichIcon = degreeIconHash[degree]
    const whichText = degreeTextHash[degree]
    const shouldShowIcon = !isBrand

    return (
      <div onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseExit}>
        <ProfileSideCard title="About" degree={shouldShowIcon ? whichIcon : ''} degreeText={shouldShowIcon ? whichText : ''}>
          {
            <OverallRating
              reviewsTotal={ratingCardInfo.totalRatingCount}
              rating={ratingCardInfo.totalRating}
              brandId={ratingCardInfo.brandId}
              obscure={ratingCardInfo.obscure}
            />
          }
          <div>
            {
              isBrand
                ? <EditAboutContainer hovered={isMouseInside} />
                : null
            }
            <div style={{ marginBottom: '16px' }}>
              {
                !description
                  ? (
                    <Small multiline cloudGrey>
                      <i>
                        {
                          isBrand
                            ? 'Add a short description of your company so other brands can learn who you are.'
                            : 'This brand has yet to complete their profile. Check out their website to learn more.'
                        }
                      </i>
                    </Small>
                  ) : (
                    <Small multiline>
                      <i>
                        { description }
                      </i>
                    </Small>
                  )
              }
            </div>
            <div style={{ marginBottom: '30px' }}>
              <Small>
                <a href={website} target="_blank">
                  { website }
                </a>
              </Small>
            </div>
            <div style={{ margin: '-12px -12px -18px' }}>
              <BlueTags tags={tags} />
            </div>
          </div>
        </ProfileSideCard>
      </div>
    )
  }
}

export default connect(stateToProps)(AboutCard)
