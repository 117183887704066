import React from 'react'
import Radium from 'radium'
import countries from 'server-util/countries/all-map.json'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { difference } from 'lodash'
import OptIn from './OptIn'
import SmsOptIn from './SmsOptIn'
import OptInPreview from './OptInPreview'
import SubmitButton from './SubmitButton'
import Inputs from './Inputs'

const styles = {

}

const fieldOrder = [
  '', // leave first element blank because index 0 in sort is falsy
  'email',
  'firstName',
  'lastName',
  'gender',
  'instagram',
  'birthday',
  'mobileBirthday',
  'phone',
  'streetAddress',
  'state',
  'city',
  'country',
  'zip',
]

const fieldOptions = {
  email: {
    label: 'Email',
    inputType: 'email',
    placeholder: 'name@dojomojo.com',
    errorMsg: 'Please enter a valid email address',
  },
  firstName: {
    label: 'First Name',
    placeholder: 'John',
    name: 'firstName',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Smith',
    name: 'lastName',
  },
  instagram: {
    label: 'Instagram Handle',
    placeholder: 'handle',
    name: 'instagram',
    required: false,
  },
  dateMonth: {
    label: 'Month',
    placeholder: 'Month',
    inputType: 'select',
    options: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
  },
  dateDay: {
    label: 'Day',
    placeholder: 'Day',
    inputType: 'select',
    options: {},
  },
  dateYear: {
    label: 'Year',
    placeholder: 'Year',
    inputType: 'select',
    options: {},
  },
  phone: {
    label: 'Phone',
    placeholder: '(555) 555-5555',
  },
  birthday: {
    label: 'Birthday',
    placeholder: 'Birthday',
    errorMsg: 'You must be at least 18 years old to enter',
  },
  streetAddress: {
    label: 'Street Address',
    placeholder: '937 Smith st',
  },
  city: {
    label: 'City',
    placeholder: 'Select city',
    name: 'city',
  },
  state: {
    label: 'State',
    placeholder: 'Select State',
    name: 'state',
  },
  zip: {
    label: 'Zip Code',
    placeholder: '78946',
    name: 'zip',
  },
  country: {
    label: 'Country',
    inputType: 'select',
    options: countries,
    placeholder: 'Select Country',
    name: 'country',
  },
  gender: {
    label: 'Gender',
    placeholder: 'Select Gender',
    inputType: 'select',
    options: {
      Female: 'Female',
      Male: 'Male',
      'n/a': 'Not Specified',
    },
  },
}

class InputContainer extends React.Component {
  static propTypes = {
    inputs: propTypes.object.isRequired,
    campaign: propTypes.object.isRequired,
    elements: propTypes.object.isRequired,
    preCheck: propTypes.bool.isRequired,
    additionalTermsDetail: propTypes.string,
    perBrandTerms: propTypes.bool.isRequired,
    termsLink: propTypes.string.isRequired,
    allPartners: propTypes.array.isRequired,
    entryButtonText: propTypes.string.isRequired,
    smsOptinHeadlineText: propTypes.string,
    smsOptinLanguage: propTypes.string,
    formStyles: propTypes.object.isRequired,
    desktopVersion: propTypes.bool.isRequired,
    updateAttr: propTypes.func.isRequired,
    removeCustomFields: propTypes.bool,
  }

  static defaultProps = {
    additionalTermsDetail: '',
    removeCustomFields: false,
    smsOptinHeadlineText: '',
    smsOptinLanguage: '',
  }

  static contextTypes = {
    baseStyles: propTypes.object,
  }

  formatInput = (key, fields, options = {}) => ({
    key,
    fields: Array.isArray(fields) ? fields : [{ ...fields, name: key }],
    ...options,
  })

  formatCustomFields = customFields => customFields.map(field => {
    const input = {
      name: field.id,
      label: field.label,
      placeholder: '',
      inputType: field.type,
      options: field.options.map(value => value.trim()),
      formattedLabel: `${field.label}*`,
    }
    return this.formatInput(field.label, [input])
  })

  formatInputs = inputs => {
    const {
      campaign,
      elements,
    } = this.props

    let fieldKeys = Object.keys(fieldOptions)
      .filter(key => inputs[key])

    const fieldGroups = []

    // combine first and last name fields onto one row
    if (fieldKeys.includes('firstName') && fieldKeys.includes('lastName')) {
      fieldGroups.push(this.formatInput(
        'firstName',
        [fieldOptions.firstName, fieldOptions.lastName]
      ))
      fieldKeys = difference(fieldKeys, ['firstName', 'lastName'])
    }

    // create birthday fields
    if (fieldKeys.includes('birthday')) {
      const birthdayInputs = [
        {
          ...fieldOptions.dateMonth,
          label: elements.inputLabels ? 'Date of Birth' : 'Birth Month',
          name: 'birthday[month]',
          errorMsg: fieldOptions.birthday.errorMsg,
        },
        {
          ...fieldOptions.dateDay,
          label: elements.inputLabels ? null : 'Birth Day',
          name: 'birthday[day]',
        },
        {
          ...fieldOptions.dateYear,
          label: elements.inputLabels ? null : 'Birth Year',
          name: 'birthday[year]',
        },
      ]

      fieldGroups.push(this.formatInput(
        'birthday',
        birthdayInputs
      ))

      fieldKeys = difference(fieldKeys, ['birthday'])
    }

    // combine city and state fields into one row
    // otherwise combine country and state fields onto one row
    if (fieldKeys.includes('city') && fieldKeys.includes('state')) {
      fieldGroups.push(this.formatInput(
        'city',
        [fieldOptions.city, fieldOptions.state]
      ))
      fieldKeys = difference(fieldKeys, ['city', 'state'])
    } else if (fieldKeys.includes('country') && fieldKeys.includes('state')) {
      fieldGroups.push(this.formatInput(
        'country',
        [fieldOptions.country, fieldOptions.state]
      ))
      fieldKeys = difference(fieldKeys, ['country', 'state'])
    }

    // change phone label if smsoptin is enabled
    if (fieldKeys.includes('phone') && campaign.showSmsOptin) {
      fieldOptions.phone.label = 'Mobile Phone Number'
      fieldOptions.phone.name = 'phone'
      fieldGroups.push(this.formatInput(
        'phone',
        [fieldOptions.phone]
      ))
      fieldKeys = difference(fieldKeys, ['phone'])
    }

    // add the rest of the inputs
    fieldGroups.push(
      ...fieldKeys.map(fieldKey => this.formatInput(fieldKey, fieldOptions[fieldKey]))
    )

    // sort the inputs
    fieldGroups.sort((a, b) => (fieldOrder.findIndex(key => key === a.key) || 9999) - (fieldOrder.findIndex(key => key === b.key) || 9999))

    // mark required fields' labels with an asterisk
    fieldGroups.forEach(fieldGroup => {
      fieldGroup.fields.forEach(field => {
        field.formattedLabel = field.label && field.required !== false ? `${field.label}*` : field.label
      })
    })

    return fieldGroups
  }

  hasInputLabels = (inputLabels, input) => {
    if (input.fields[0].inputType === 'date' && input.fields[0].label.length > 16) return true
    return inputLabels
  }

  renderFieldGroups = () => {
    const {
      inputs,
      desktopVersion,
      formStyles,
      elements,
      updateAttr,
      removeCustomFields,
    } = this.props

    let {
      campaign: {
        customFields = {},
      },
    } = this.props
    if (removeCustomFields) {
      customFields = []
    }

    const formattedInputs = [
      ...this.formatInputs(inputs),
      ...this.formatCustomFields(customFields),
    ]

    return formattedInputs.map(input => (
      <Inputs
        desktopVersion={desktopVersion}
        key={input.fields.map(field => field.label).join('')}
        field={input}
        textStyle={formStyles.formTextColor}
        containerStyle={formStyles.formContainerColor}
        inputLabels={this.hasInputLabels(elements.inputLabels, input)}
        updateAttr={updateAttr}
      />
    ))
  }

  render() {
    const {
      campaign,
      preCheck,
      additionalTermsDetail,
      perBrandTerms,
      termsLink,
      allPartners,
      entryButtonText,
      smsOptinHeadlineText,
      smsOptinLanguage,
      formStyles,
      desktopVersion,
      removeCustomFields,
      inputs,
    } = this.props

    const { isSolo, showSmsOptin } = campaign
    return (
      <div>
        <form id="input-form-fields" noValidate>
          <div id="inputs">
            { this.renderFieldGroups() }
          </div>

          {removeCustomFields ? (
            <OptInPreview
              preCheck={preCheck}
              entryButtonStyles={formStyles}
            />
          ) : (
            <OptIn
              whiteInputs={campaign.whiteInputs}
              tosLinkLabel={campaign.tosLinkLabel}
              preCheck={preCheck}
              additionalTermsDetail={additionalTermsDetail}
              perBrandTerms={perBrandTerms}
              partnerBrandNames={campaign.partnerBrandNames}
              partnerBrandNamesCommas={campaign.partnerBrandNamesCommas}
              hostBrandName={campaign.hostBrandName}
              termsLink={termsLink}
              campaignId={campaign.id}
              allPartners={allPartners}
              desktopVersion={desktopVersion}
              entryButtonStyles={formStyles}
              inputs={inputs}
              hasSmsOptInLanguage={campaign.campaignTerms.enable_sms_opt_in_language}
            />
          )}
          {isSolo && showSmsOptin && (
            <SmsOptIn
              smsOptinHeadlineText={smsOptinHeadlineText}
              smsOptinLanguage={smsOptinLanguage}
              hostBrandName={campaign.hostBrandName}
              formStyles={formStyles}
            />
          )}
          <SubmitButton
            disabled={removeCustomFields}
            entryButtonText={entryButtonText}
            entryButtonStyles={formStyles}
          />
        </form>
      </div>
    )
  }
}


export default Radium(injectSheet(styles)(InputContainer))
