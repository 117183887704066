import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import { object } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as DealCollaborationActions from 'actions/deal-collaboration-actions'
import * as AssetActions from 'actions/asset-actions'
import COLOURS from 'util/colours'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { P } from 'visual-components/util/texts'
import { Form, Input } from 'visual-components/util/form'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import AssetUploadTableTheme from 'css/themes/assets/AssetUploadTableTheme.css'
import InputTheme from 'css/themes/Input.css'
import FileOptionRow from './assets/file-option-row'
import FileUploading from './assets/FileUploading'

const mapDispatchToProps = dispatch => ({
  dispatch,
  assetActions: bindActionCreators(AssetActions, dispatch),
  dealCollaborationActions: bindActionCreators(DealCollaborationActions, dispatch),
})

const mapStateToProps = state => ({
  dealAssets: state.dealAssets,
  profile: state.profile,
  currentBrand: state.currentBrand,
})

class AssetUploader extends PureComponent {
  static propTypes = {
    dealCollaborationActions: object.isRequired,
    currentBrand: object.isRequired,
    assetActions: object.isRequired,
    dealAssets: object.isRequired,
    profile: object.isRequired,
  }

  onDrop = files => {
    const {
      assetActions: {
        setUploadFiles,
      },
    } = this.props

    setUploadFiles(files)
  }

  uploadFiles = () => {
    const {
      assetActions: {
        uploadFiles,
      },
      dealCollaborationActions: {
        sendMessage,
      },
      currentBrand: {
        id: brand_id,
      },
      profile,
      dealAssets: {
        dealId: deal_id,
        upload: {
          files,
          includeMap,
          note: content,
        },
      },
    } = this.props

    const filesToUpload = files.filter(file => includeMap[file.name])

    uploadFiles(deal_id, filesToUpload)

    if (content) {
      sendMessage({
        profile,
        brand_id,
        deal_id,
        content,
      })
    }
  }

  render() {
    const {
      assetActions: {
        cancelUpload,
        toggleIncludeFile,
        updateDealAssets,
      },
      dealAssets: {
        upload: {
          confirmingUpload,
          files,
          includeMap,
          uploading,
        },
      },
    } = this.props

    const fileOptions = files.map(file => {
      const namePieces = file.name.split('.')

      const name = namePieces.slice(0, namePieces.length - 1).join('.')

      const type = namePieces[namePieces.length - 1]
      return (
        <FileOptionRow
          key={file.name}
          fileName={name}
          fileType={type}
          checked={includeMap[file.name]}
          toggleIncludeFile={() => {
            toggleIncludeFile(file.name)
          }}
        />
      )
    })

    return (
      <div
        style={{
          display: 'inline-block',
          marginLeft: '35px',
        }}
      >
        <div style={{
          position: 'absolute',
          width: '1px',
          height: '48px',
          marginTop: '-13px',
          marginLeft: '-16px',
          backgroundColor: COLOURS.silver,
        }}
        />
        <Dropzone
          style={{
            width: '20px',
            height: '20px',
            border: 'none',
            cursor: 'pointer',
          }}
          onDrop={this.onDrop}
        >
          <img src="/images/icons/paperclip.svg" />
        </Dropzone>
        <MultiTaskModal
          title="Upload Files"
          close={cancelUpload}
          active={confirmingUpload}
        >
          <div>
            <P>Review your files before uploading and a note with it.</P>
            <Form
              modelName="dealAssets"
              updateModel={updateDealAssets}
              mergeFieldsImmediately
            >
              <Input
                name="upload.note"
                label="Note (Optional)"
                multiline
                className={InputTheme.textarea}
                rows={3}
                placeholder="I’m sending a few versions for..."
                type="text"
              />
            </Form>
            <div style={{ borderBottom: `1px solid ${COLOURS.siver}` }}>
              <table className={AssetUploadTableTheme.table}>
                <thead>
                  <tr className={AssetUploadTableTheme.noSideBorder}>
                    <th className={AssetUploadTableTheme.firstTd}>
                      File Name
                    </th>
                    <th className={AssetUploadTableTheme.secondTd}>
                      Type
                    </th>
                    <th className={AssetUploadTableTheme.checkboxTd}>
                      Selected
                    </th>
                  </tr>
                </thead>
              </table>
              <div className={AssetUploadTableTheme.tableScroll}>
                <table className={AssetUploadTableTheme.table}>
                  <tbody>
                    {fileOptions}
                  </tbody>
                </table>
              </div>
            </div>
            <ModalButtonGroup
              confirm={this.uploadFiles}
              cancel={cancelUpload}
              canSave
              cancelText="Cancel"
              confirmText="Upload Selected"
            />
          </div>
        </MultiTaskModal>
        { uploading && <FileUploading /> }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetUploader)
