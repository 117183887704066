import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import moment from 'moment';
import COLOURS from 'util/colours';
import Badge from 'visual-components/util/badge';
import MessagingBrandLogo from 'visual-components/messaging/messaging-brand-logo';
import MessagingRowItemContent from 'visual-components/messaging/messaging-row-item-content';
import MessagingRowItemActions from 'visual-components/messaging/messaging-row-item-actions';

class MessageRowItemContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { logo, brandName, brandId, userName, text, timestamp, status, clickFunction, highlight, heading, archiveOption, archiveFunction } = this.props

    let statusMap = {
      accepted: {
        text: 'Accepted',
        color: COLOURS.seaGreen
      },
      pending: {
        text: 'Pending',
        color: COLOURS.cloudGrey
      },
      rejected: {
        text: 'Declined',
        color: COLOURS.charcoal
      },
      withdrawn: {
        text: 'Withdrawn',
        color: COLOURS.charcoal
      },
      interested: {
        text: 'Interested',
        color: COLOURS.cobalt
      }
    }

    let rowStyle = {}

    if(highlight) {
      rowStyle.backgroundColor = '#e0f2f9'
    }

    return (
      <Row style={rowStyle}>
        <Col xs={11} onClick={ clickFunction } style={{
          cursor: 'pointer',
          paddingRight: '0px'
        }}>
          <Row style={{
            borderBottom: '1px solid #dee4e5',
            padding: '13px'
          }}>
            <Col xs={1}>
              <MessagingBrandLogo logo={ logo } brandId={ brandId } />
            </Col>
            <Col xs={3} style={{
              marginTop: '6px',
              position: 'relative'
            }}>
              <p style={{
                height: '14px',
                fontFamily: 'Larsseit-Bold',
                fontSize: '14px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box'
              }}>
                { brandName }
              </p>
              <p style={{
                fontFamily: 'Larsseit',
                fontSize: '12px',
                marginTop: '12px',
                position: 'absolute',
                bottom: '6px'
              }}>
                { userName }
              </p>
            </Col>
            <Col xs={7} style={{
              marginTop: '8px'
            }}>
              <MessagingRowItemContent text={ text } heading={ heading } />
            </Col>
            <Col xs={1}>
              { status ?
                  <Badge label={ statusMap[status].text }
                    color={ statusMap[status].color }
                  />
                :
                  null
              }
            </Col>
          </Row>
        </Col>
        <Col xs={1} style={{
          paddingLeft: '0px'
        }}>
          <MessagingRowItemActions timestamp={ timestamp } archiveOption={ archiveOption } archiveFunction={ archiveFunction }/>
        </Col>
      </Row>
    )

  }
}

MessageRowItemContainer.propTypes = {
  logo: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  clickFunction: PropTypes.func.isRequired,
  heading: PropTypes.string,
  highlight: PropTypes.bool,
  status: PropTypes.string
}

export default MessageRowItemContainer;
