import React from 'react';
import _ from 'lodash'

import { Helmet } from 'react-helmet'
import Card from 'react-toolbox/lib/card';
import InviteItem from 'visual-components/messaging/messaging-invites-item';
import PricingGateOverlay from 'visual-components/util/pricing-gate-overlay';
import * as ReactRedux from 'react-redux';
import COLOURS from 'util/colours';
import NoMessages from './no-messages';
import PartnershipInviteDispatcher from 'dispatchers/partnership-invites-dispatcher'

import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'
import { getClaim } from 'actions/brand-claim-actions'

class InviteContainer extends React.Component {
  constructor(props) {
    super(props)

    this.updateActiveMessage = this.updateActiveMessage.bind(this);
  }

  openInvite = (inviteId) => {
    let onReceivedInvitations = window.location.href.indexOf('received-invitations') != -1;
    let invite = _.find(
      ( onReceivedInvitations ? this.props.receivedInvitations : this.props.receivedApplications), { partnershipinvite_id: inviteId }
    )

    if (invite) {
      this.updateActiveMessage(invite.id)
    }
  }
  componentDidMount() {
    const { match, location } = this.props
    if (match.params.partnershipId) {
      this.openInvite(parseInt(match.params.partnershipId));
    }

    const splitPathname = location.pathname.split('/')
    const pageURI = splitPathname[splitPathname.length - 1]
    if (this.pageTitleMap[pageURI] && this.pageTitleMap[pageURI].page_title) {
      gtagPageTrack({ page_title: this.pageTitleMap[pageURI].page_title, location })
    }
  }

  componentDidUpdate(prevProps) {
    const { match, receivedInvitations, receivedApplications, location } = this.props
    if (match.params.partnershipId && (receivedInvitations || receivedApplications) && !(prevProps.receivedApplications || prevProps.receivedInvitations)) {
      this.openInvite(parseInt(match.params.partnershipId))
    }

    if (location.pathname !== prevProps.location.pathname) {
      const splitPathname = location.pathname.split('/')
      const pageURI = splitPathname[splitPathname.length - 1]
      if (this.pageTitleMap[pageURI] && this.pageTitleMap[pageURI].page_title) {
        gtagPageTrack({ page_title: this.pageTitleMap[pageURI].page_title, location })
      }
    }
  }

  pageTitleMap = {
    "received-applications": {
      page_title: 'Received Applications',
    },
    "sent-applications": {
      page_title: 'Sent Applications',
    },
  }

  openEmailReplyModal = (action, partnershipId) => {

    let onReceivedInvitations = window.location.href.indexOf('received-invitations') != -1;

    let invite = _.find(
      ( onReceivedInvitations ? this.props.receivedInvitations : this.props.receivedApplications), { partnershipinvite_id: partnershipId, status: 'pending' }
    )

    if (action == 'accept' && invite) {
      const campaign = _.find(store.getState().upcomingCampaigns.campaigns, { id: invite.campaign_id })
      const acceptedPartners = campaign && Object.keys(campaign.partners).filter(partner =>
        campaign.partners[partner].status === 'accepted')
      const numberOfPartners = acceptedPartners ? acceptedPartners.length : invite.numberOfPartners
      if (campaign && !campaign.noPartnerLimit && numberOfPartners >= 8) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'campaignLimitModal',
          data: {
            open: true,
            isInvitation: !invite.application,
            campaignId: invite.campaign_id
          }
        });
        return;
      }
      // Show either invitation or application confirm modal (same data)
      let data = onReceivedInvitations ?
      { showInviteAcceptConfirmationModal: Object.assign({}, invite, { invitee: {name: invite.invitee_brand_name} }) }
      :
      { showApplicationAcceptConfirmationModal: Object.assign({}, invite, { invitee: {name: invite.invitee_brand_name} }) }

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'conversations',
        data: data
      })
    } else if (action == 'decline' && invite) {
      let data = Object.assign([], onReceivedInvitations ? this.props.receivedInvitations : this.props.receivedApplications);
      data[_.findIndex(data, { partnershipinvite_id: invite.partnershipinvite_id })].status = 'rejected';

      invite.invitee = {
        id: invite.invitee_id
      }
      invite.id = invite.partnershipinvite_id

      if (onReceivedInvitations) {
        PartnershipInviteDispatcher.rejectInvite(invite);
      } else {
        PartnershipInviteDispatcher.rejectApplication(invite);
      }

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'conversations',
        data: Object.assign({}, data, {
          showPartnershipDeclineModal: _.merge(invite, {
            hostBrandName: invite.host_brand_name,
            inviteeName: invite.invitee_brand_name,
            active: true
          })
        })
      })
    }
  }

  updateActiveMessage(messageId) {

    if (this.props.activeMessage == messageId) {
      store.dispatch({
        model: 'conversations',
        type: 'UPDATE_ATTR',
        data: {
          activeMessage: null
        }
      })
    } else {
      store.dispatch({
        model: 'conversations',
        type: 'SET_ACTIVE_CONVERSATION',
        data: {
          activeMessage: messageId
        }
      })
    }
  }

  render() {

    let { currentBrand, match, activeMessage } = this.props
    let urlMap = {
      'received-invitations': {
        messageType: 'receivedInvitations',
        title: 'Received Invitations',
        missingMessage: 'No campaign invitations yet! Keep an eye on this space.'
      },
      'sent-invitations': {
        messageType: 'sentInvitations',
        title: 'Sent Invitations',
        missingMessage: 'You haven’t sent any invitations yet. If you’ve already created a campaign, head to Explore to start inviting potential brand partners!',
        pricingGateTitle: 'This Inbox Could Be Full of Chances to Grow Your Audience',
        pricingGateMessage: 'Create giveaways on DojoMojo and invite anyone in our network to join them. Upgrade to get started.',
        eventTrack: 'HostUpgrade'
      },
      'received-applications': {
        messageType: 'receivedApplications',
        title: 'Received Applications',
        missingMessage: 'No campaign applications yet! Create a campaign and start connecting with brands.',
        pricingGateTitle: 'Brands Could Be Flooding This Inbox',
        pricingGateMessage: `Start hosting campaigns and let DojoMojo's network of partners come to you. Upgrade to give it a try.`,
        eventTrack: 'HostUpgrade'
      },
      'sent-applications': {
        messageType: 'sentApplications',
        title: 'Sent Applications',
        missingMessage: 'You haven’t sent any applications yet. Head to Explore to apply to join campaigns in the network.',
        pricingGateTitle: 'This Inbox Could Be Full of Chances to Grow Your Audience',
        pricingGateMessage: 'Want to get in on the action? Upgrade to apply to join any campaign on the DojoMojo network. ',
        eventTrack: 'ApplicationUpgrade'
      },
    }

    let messages = this.props[urlMap[match.path.split('/')[2]].messageType];

    let idx = _.findIndex(messages, {id: activeMessage});

    let messagesArr = [
      idx == -1 ? messages : messages.slice(0, idx),
      idx == -1 ? [] : [messages[idx]],
      idx == -1 ? [] : messages.slice(idx + 1)
    ];
    let cardContentObj = {}

    let mainContent = null;

    const data = urlMap[match.path.split('/')[2]]

    if (getClaim('messaging.message.create') && data.pricingGateTitle) {
      mainContent = <PricingGateOverlay src='/images/pricing-block-messages.png'
      feature={ 'messageBrandDirect' }
      title={ data.pricingGateTitle }
      content={ data.pricingGateMessage }
      eventTrack={ data.eventTrack }
      style={{
        width: '928px',
        height: '510px',
        marginTop: '79px',
        marginLeft: '128px'
      }}
      />
    }
    if (!messages || messages.length == 0) {
      mainContent = <NoMessages message={ data.missingMessage } />
    } else {
      mainContent = messagesArr.filter(arr=>arr.length != 0).map( (messageCards, idx)=> {
        let cardContent = _.map(messageCards, (message)=> {
          return (
            <InviteItem
              message={ message }
              logo={ message.conversation_brand_logo }
              brandName={ message.conversation_brand_name }
              userName={ `${message.firstName} ${message.lastName}`}
              text={ message.content.replace(/<[^>]+>/g, '') }
              timestamp={ message.createdAt }
              preview={ message.id != activeMessage }
              status={ message.status }
              key={ message.id }
              onclick={ this.updateActiveMessage }
            />
          )
        })

        return (
          <Card key={idx} style={{
            marginBottom: '16px'
          }}>
            { cardContent }
          </Card>
        )

      });
    }

    let title = data.title;

    return (
      <div style={{marginTop: '30px', width: '840px'}}>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
        <p style={{
          marginBottom: '24px',
          fontFamily: 'Larsseit-Bold',
          fontSize: '24px',
          color: COLOURS.azure
        }}>
          { title }
        </p>

        { mainContent }
      </div>
    )
  }
}


const mapState = (state) => {
  return {
    currentBrand: state.currentBrand,
    receivedInvitations: state.conversations.receivedInvitations,
    sentInvitations: state.conversations.sentInvitations,
    receivedApplications: state.conversations.receivedApplications,
    sentApplications: state.conversations.sentApplications,
    activeMessage: state.conversations.activeMessage
  }
}

export default ReactRedux.connect(mapState)(InviteContainer);
