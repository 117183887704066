import React, { Component } from 'react';

import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { H4, P } from 'visual-components/util/texts';
import InputContainer from 'visual-components/util/InputContainer';
import Dropdown from 'react-toolbox/lib/dropdown';
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';
import { bool, object, func, number } from 'prop-types';

import inputTheme from 'css/themes/no-padding-input.css';
import dropdownTheme from 'css/themes/Dropdown.css';
import d3 from 'd3';

import { Input } from 'visual-components/util/form'
import { isPositive, cannotExceed } from 'visual-components/util/form/validations'

const themes = {
  Input: inputTheme,
  Dropdown: dropdownTheme
};

export default class ApplyToCampaignStepOne extends Component {

  updateValue = name => value => {
    this.props.updateData({
      [name]: value,
    });
  };

  updatePastPartners = index => value => {
    const newValue = [...this.props.applicationData.pastPartners];
    newValue[index] = value;

    this.props.updateData({
      pastPartners: newValue,
    });
  };

  render() {
    const {
      updateData,
      nextStep,
      closeModal,
      applicationData,
      pastPartners,
      socialReach,
      confirm,
      partnerRating,
      expectedEntries,
      industries
    } = this.props;
    return (
      <div>
        <H4>Step 1 of 3: Share some info about your brand with the Host.</H4>
        <Row style={{ marginTop: 16 }}>
          <Col xs>
            <Input
              validations={[isPositive, cannotExceed(100000000)]}
              type="number"
              label="List Size"
              sublabel="Updating your list size will update your brand profile."
              name="applicationData.listSize"
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 24 }}>
          <Col xs={4}>
            <InputContainer label="Partner Rating">
              <P>
                { partnerRating > 0 ? `${d3.format('.2f')(partnerRating)} / 5` : 'N/A' }
              </P>
            </InputContainer>
          </Col>
          <Col xs={4}>
            <InputContainer label={
              <span>Expected Entries
                <div style={{ display: 'inline-flex' }}>
                  &nbsp;
                  <BlueInfoTooltip text="We calculate this based on 1% of your list size or the average of your last three campaigns." />
                </div>
              </span>
            }>
              <P>
                { !isNaN(expectedEntries) ? d3.format(',')(expectedEntries) : 'N/A' }
              </P>
              </InputContainer>
          </Col>
          <Col xs={4}>
            <InputContainer label="Social Reach"><P>{d3.format(',')(socialReach)}</P></InputContainer>
          </Col>
        </Row>
        <Row style={{ marginBottom: 24 }}>
          <Col>
            <InputContainer label="Industry"><P>{industries.join(', ')}</P></InputContainer>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Input
              multiline
              required
              rows={3}
              className={themes.Input.textarea}
              name="applicationData.greatFitMessage"
              label="Why is your brand a great fit for this campaign?"
              placeholder="Let the host know a bit about what product or service you offer and how it may be relevant to the theme or their audience."
            />
          </Col>
        </Row>
        {pastPartners && pastPartners.length ? (
          <Row>
            <Col xs>
              <InputContainer
                label="Past Partners (Optional)"
                subLabel="Demonstrate your partner performance by listing the top 3 brands you’ve worked with.">
                <Row>
                  {applicationData.pastPartners.map((value, index) => (
                    <Col xs style={{ marginRight: index === 2 ? 0 : 8 }}>
                      <Dropdown
                        theme={themes.Dropdown}
                        auto
                        onChange={this.updatePastPartners(index)}
                        source={[
                          { label: 'Select past partner', value: null },
                        ].concat(
                          pastPartners.filter(name => {
                            const selectedIndex = applicationData.pastPartners.indexOf(name);
                            return selectedIndex === -1 || selectedIndex === index;
                          }).map(name => ({
                            label: name,
                            value: name,
                          }))
                        )}
                        value={value}
                      />
                    </Col>
                  ))}
                </Row>
              </InputContainer>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}
