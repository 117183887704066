import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { H4 } from 'visual-components/util/texts'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'

const stateToProps = ({ currentCampaign }) => ({ currentCampaign })

class AnnouncementSentModal extends PureComponent {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onCloseModal = () => CampaignDispatcher.showAnnouncementSentOnCampaingDateChangeModal()

  onSeeAnnouncements = () => {
    const { currentCampaign, history } = this.props

    history.push(`/partnerships/campaign/${currentCampaign.id}/announcements`)

    this.onCloseModal()
  }

  render() {
    const { currentCampaign } = this.props

    const {
      name,
      showAnnouncementSentCampaignDateChangeModal,
    } = currentCampaign

    return (
      <Dialog
        title="We've Sent Your Announcement!"
        theme={dialogTheme}
        active={showAnnouncementSentCampaignDateChangeModal}
        onEscDown={this.closeModal}
        onOverlayClick={this.closeModal}
      >
        <div className={dialogTheme.bannerWrapper}>
          <img src="/images/icons/message-sent.svg" style={{ paddingTop: 0 }} />
        </div>

        <H4 multiline>
          Your announcement was sent to the partners on
          <strong>
            {` ${name}`}
          </strong>
          . You&apos;ll also receive a copy of your announcement in your messages inbox.
        </H4>

        <div style={{ marginTop: '24px' }}>
          <ModalButtonGroup
            canSave
            confirm={this.onSeeAnnouncements}
            confirmText="See Announcements"
            cancel={this.onCloseModal}
            cancelText="Close"
            hideLine
          />
        </div>
      </Dialog>
    )
  }
}

export default withRouter(connect(stateToProps)(AnnouncementSentModal))
