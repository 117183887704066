import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import ConfirmNavigationModal from './ConfirmNavigationModal'
import Impersonating from './Impersonating'

class Root extends Component {
  state = {
    confirmNavigationModalOpen: false
  }

  getConfirmation = (message, callback) => {
    this.setState({
      confirmNavigationModalOpen: true,
      message,
      callback,
    })
  }

  closeConfirmNavigationModal = response => {
    this.state.callback(response === true)
    this.setState({
      confirmNavigationModalOpen: false,
      message: null,
      callback: null,
    })
  }

  render() {
    const { store, routes, basename } = this.props
    const supportsHistory = 'pushState' in window.history
    return (
      <Provider store={ store }>
        <div>
          <Impersonating />
          <Router
            basename={ basename }
            getUserConfirmation={ this.getConfirmation }
            forceRefresh={ !supportsHistory }
            children={ routes }
          />
          <ConfirmNavigationModal
            isOpen={ this.state.confirmNavigationModalOpen }
            closeModal={ this.closeConfirmNavigationModal }
            message={ this.state.message }
          />
        </div>
      </Provider>
    )
  }
}

export default Root
