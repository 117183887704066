import React from 'react'
import { string } from 'prop-types'

import FbShare from './fb-share'
import FbLike from './fb-like'
import InstagramFollow from './instagram-follow'
import InstagramVisit from './instagram-visit'
import TwitterFollow from './twitter-follow'
import TwitterShare from './twitter-share'
import PinterestFollow from './pinterest-follow'
import PinterestPin from './pinterest-pin'
import YoutubeSubscribe from './youtube-subscribe'
import YoutubeWatch from './youtube-watch'
import SnapchatFollow from './snapchat-follow'
import AppDownload from './app-download'

import Label from 'visual-components/util/inputs/label'
import Field from 'visual-components/util/inputs/field'
import Dropdown from 'react-toolbox/lib/dropdown'

import { P, Small } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'

import ButtonTheme from 'css/themes/Buttons.css'
import DropdownTheme from 'css/themes/Dropdown.css'
import COLOURS from 'util/colours'
import _ from 'lodash'

const bonusComponentMap = {
  fbLike: {
    component: FbLike,
    faIcon: 'fa-facebook-square'
  },
  fbShare: {
    component: FbShare,
    faIcon: 'fa-facebook-square',
  },
  instagramFollow: {
    component: InstagramFollow,
    colour: COLOURS.instagram,
    faIcon: 'fa-instagram'
  },
  instagramVisit: {
    component: InstagramVisit,
    colour: COLOURS.instagram,
    faIcon: 'fa-instagram'
  },
  twitterShare: {
    component: TwitterShare,
    faIcon: 'fa-twitter-square'
  },
  twitterFollow: {
    component: TwitterFollow,
    faIcon: 'fa-twitter-square'
  },
  youtubeWatch: {
    component: YoutubeWatch,
    faIcon: 'fa-youtube-square'
  },
  youtubeSubscribe: {
    component: YoutubeSubscribe,
    faIcon: 'fa-youtube-square'
  },
  pinterestFollow: {
    component: PinterestFollow,
    faIcon: 'fa-pinterest-square'
  },
  pinterestPin: {
    component: PinterestPin,
    faIcon: 'fa-pinterest-square'
  },
  snapchatFollow: {
    component: SnapchatFollow,
    faIcon: 'fa-snapchat-square'
  },
  appDownload: {
    component: AppDownload,
    faIcon: 'fa-download'
  },
  linkShare: {
    faIcon: 'fa-bullhorn'
  }
}

class BonusItem extends React.Component {
  static contextTypes = {
    impersonating: string.isRequired,
  }

  updatePersist = () => {
    const {
      updateBonusItem,
      bonus: { id, partnership_invite_id, data },
    } = this.props

    updateBonusItem(id, partnership_invite_id, data)
  }

  updateBonusDataAttr = (attr, value) => {
    const { bonus: { id, partnership_invite_id } } = this.props

    const data = {
      id: id,
      data: {
        [attr]: value
      }
    }

    store.dispatch({
      model: 'bonusItems',
      type: 'UPDATE_ITEM',
      data: data
    })
  }

  render() {
    const {
      bonus,
      bonusItems,
      bonus: { id: bonusId, bonus_type: type, partnership_invite_id, data, expanded, errors },
      partnershipInvite: { id: inviteId },
      isHost,
      expandBonusItem,
      unexpandBonusItem,
      deleteBonusItem,
      currentCampaign
    } = this.props

    const { impersonating } = this.context

    const Component = bonusComponentMap[type].component

    const options = ['0','1','2','3'].map(number => ({
      value: number,
      label: `+ ${number}/each`
    }))

    const hasError = _.some(errors, item => item.errors && item.errors.length)

    const ineligableBonus = ['fbShare', 'fbLike', 'instagramFollow']
    const eligable = !ineligableBonus.includes(type)
    const ownsUnit = inviteId === partnership_invite_id

    return (
      <div>
        {
          !ownsUnit && data.hidden && !isHost ?
            null
          :
          <div style={{
            width: '851px',
            minHeight: '40px',
            border: `solid 1px ${COLOURS.silver}`,
            borderRadius: '1px',
            marginBottom: '12px',
            padding: '2px 12px',
            cursor: 'pointer'
          }}>
            {
              isHost ?
              <i className="fa fa-bars"
                aria-hidden="true"
                style={{
                  color: COLOURS.azure,
                  fontSize: '16px',
                  marginRight: '24px'
              }}/>
              : null
            }

            <div style={{
              width: '16px',
              display: 'inline-block',
              marginRight: '16px'
            }}>
              <i className={ `fa ${bonusComponentMap[type].faIcon}` }
                aria-hidden="true"
                style={{
                  color: COLOURS.ink,
                  fontSize: '16px'
              }}/>
            </div>

            <div style={{
              width: '486px',
              display: 'inline-block',
              marginRight: '16px'
            }}>
              <P multiline style={{lineHeight: '36px'}}>
                { `${data.message}${data.hidden && !isHost ? ' (Hidden by Host)' : ''}` }
              </P>
            </div>

            <div style={{
              width: '11px',
              display: 'inline-block',
              marginRight: '104px'
            }}>
              {
                ownsUnit && !expanded ?
                  hasError ?
                  <i className="fa fa-times"
                    aria-hidden="true"
                    style={{
                      fontSize: '20px',
                      color: COLOURS.alertRed
                  }}/>
                  :
                  <i className="fa fa-check"
                    aria-hidden="true"
                    style={{
                      fontSize: '20px',
                      color: COLOURS.seaGreen
                  }}/>
                : null
              }
            </div>

            <div style={{
              display: 'inline-block',
              width: '138px',
              textAlign: 'right'
            }}>
              {
                ownsUnit ?
                <div>
                  {
                    expanded ?
                    <Button onClick={ e => {
                        unexpandBonusItem(bonusId)
                        this.updatePersist()
                        toastr.success('Saved!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
                      }}
                      className={ `${ButtonTheme.noMargin}` }
                      theme={ ButtonTheme }
                      label={ 'Save' }
                      primary
                      flat
                    />
                    :
                    <Button onClick={ e => expandBonusItem(bonusId) }
                      className={ `${ButtonTheme.noMargin}` }
                      theme={ ButtonTheme }
                      label={ 'Edit' }
                      primary
                      flat
                    />
                  }
                  {
                    data.paid && currentCampaign.startDate < new Date() && !impersonating ?
                    <div style={{width: '82px', display: 'inline-block'}}></div>
                    :
                    <Button onClick={e => deleteBonusItem(bonusId)}
                      theme={ButtonTheme}
                      label={'Delete'}
                      primary
                      flat
                    />
                  }

                </div>
                : null
              }

            </div>

            <div className={ expanded ? '' : 'hide' }>
              <div style={{
                display: 'inline-block',
                width: '210px',
                marginRight: '16px'
              }}>
                {
                  eligable ?
                  <div>
                    <Label label={ 'Social Growth' } />
                    <Dropdown onChange={ e => this.updateBonusDataAttr('bonusCount', e) }
                      source={ options }
                      value={ data.bonusCount ? data.bonusCount.toString() : '2' }
                      className={ DropdownTheme.buttonDropdown }
                      theme={ DropdownTheme }
                    />
                  </div>
                  :
                  <div>
                    <P multiline>
                      { 'You cannot offer extra entries for this action.' }
                    </P>
                  </div>
                }
              </div>
              <div style={{
                display: 'inline-block',
                width: '599px'
              }}>
                <Field label={ 'Custom Message' }
                  attrName={ 'message' }
                  dataModel={ data }
                  formModel={ {} }
                  updateAttr={ this.updateBonusDataAttr }
                  disabled={!impersonating && ['fbShare', 'fbLike', 'instagramFollow', 'instagramVisit'].includes(type)}

                />
              </div>
              {
                Component ?
                <div>
                  <Component bonus={ bonus }
                    bonusItems={ bonusItems }
                    updateBonusDataAttr={this.updateBonusDataAttr}
                  />
                </div>
                : null
              }
            </div>
          </div>
        }
      </div>
    )
  }
}

export default BonusItem
