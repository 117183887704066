export default [
  'Courier New',
  'Impact',
  'Verdana',
  'Helvetica',
  'Tahoma',
  'Lucida Sans Unicode',
  'Arial',
  'Arial Black',
  'Georgia',
  'Palatino Linotype',
  'Times New Roman'
];