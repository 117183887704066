import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { H4 } from 'visual-components/util/texts'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import { getClaim } from 'actions/brand-claim-actions'

const stateToProps = ({ currentCampaign }) => ({ currentCampaign })

class CreateCampaignLimitModal extends PureComponent {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSolo: PropTypes.bool,
  }

  static defaultProps = {
    isSolo: false,
  }

  onClose = () => CampaignDispatcher.showCampaignCreatedLimitModal()

  onConfirm = () => {
    const { history } = this.props
    history.push('/upgrade-plan')
    this.onClose()
  }

  render() {
    const { isSolo, currentCampaign } = this.props
    const { showCampaignCreatedLimitModal } = currentCampaign

    const campaignLimit = getClaim(`sweepstakes.${isSolo ? 'solo' : 'partnership'}.limit`)

    return (
      <Dialog
        title="Unlock unlimited sweepstake campaigns!"
        theme={dialogTheme}
        active={showCampaignCreatedLimitModal}
        onEscDown={this.closeModal}
        onOverlayClick={this.closeModal}
      >
        <H4 multiline>
          {`You've reached your ${campaignLimit} sweepstake campaign limit (maximum ${campaignLimit} active sweepstakes per month). You can set your campaign to start after your current sweepstakes has completed, or upgrade your plan to create unlimited sweepstake campaigns.`}
        </H4>

        <div style={{ marginTop: '24px' }}>
          <ModalButtonGroup
            canSave
            confirm={this.onConfirm}
            confirmText="Upgrade Your Plan"
            cancel={this.onClose}
            cancelText="Close"
            hideLine
          />
        </div>
      </Dialog>
    )
  }
}

export default withRouter(connect(stateToProps)(CreateCampaignLimitModal))
