import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

class CampaignImage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      imgSrc,
      name,
      style
    } = this.props;

    const isVideo = imgSrc && imgSrc.endsWith('.mp4')

    return (
      <div style={{
        width: '100%',
        height: '320px',
        background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.0) 71%, rgba(0, 0, 0, 0.3) 88%, rgba(0, 0, 0, 0.5)), url(${imgSrc || '/images/defaults/coverPhotoFull.png'}) no-repeat center`,
        position: 'relative',
        backgroundSize: '100%',
        backgroundPosition: `0% ${style.y ? 100 - style.y + '%' : '0%'}`,
        overflow: 'hidden',
      }}>
        { isVideo ? (
          <video key={imgSrc} style={{ minWidth: '944px', height: '320px', objectFit: 'cover' }} preload="metadata" muted>
            <source src={imgSrc} type="video/mp4" />
          </video>
        ) : null }
        <p style={{
          fontFamily: 'Larsseit-Bold',
          fontSize: '28px',
          lineHeight: '28px',
          color: COLOURS.white,
          position: 'relative',
          top: isVideo ? '-48px' : '278px',
          left: '24px',
          width: '90%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          { name }
        </p>
      </div>
    )
  }
}

CampaignImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default CampaignImage;
