import React, { PureComponent as Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Dialog } from 'react-toolbox/lib/dialog'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import SocialTheme from 'css/themes/SocialGrowth.css'
import {
  H1, H3, H4, Small,
} from 'visual-components/util/texts'
import { memoize } from 'lodash'
import * as metrics from 'util/metrics'
class SocialActionsUpsellModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    closeUpsellModal: PropTypes.func.isRequired,
    openPurchaseModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isOpen: false,
  }

  track = memoize(button => () => {
    metrics.create('socialActionsModalClicked', {
      meta: {
        action: button,
      }
    })
  })

  closeModal = () => {
    const {
      closeUpsellModal,
    } = this.props

    metrics.create('SocialActionsModalClosed')
    closeUpsellModal()

  }

  render() {
    const { isOpen, closeUpsellModal, openPurchaseModal } = this.props

    return (
      <Dialog
        title="Looking to Drive Conversions?"
        type="large"
        active={isOpen}
        onEscKeyDown={this.closeModal}
        onOverlayClick={this.closeModal}
        theme={DialogTheme}
      >
        <div className={SocialTheme.titleContainer}>
          <H4 multiline ink>
            Want to turn grow your traffic and social audience as well? Buy
            <a
              className={SocialTheme.azureText}
              href="https://help.dojomojo.com/hc/en-us/articles/230426167-Add-Partner-Social-Actions"
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.track('helpDeskArticle')}
            >
              {' '}
              Social Actions
              {' '}
            </a>
            or upgrade your account so you can leverage the entrants from this
            campaign.
          </H4>
        </div>
        <div className={SocialTheme.cardsContainer}>
          <div className={SocialTheme.card}>
            <img src="/images/social-action-assets/bullhorn-icon.svg" />
            <div className={SocialTheme.copyContainer}>
              <H4>
                <i>Social Actions</i>
              </H4>
              <Small>Promote your social channels</Small>
              <div className={SocialTheme.priceContainer}>
                <div className={SocialTheme.dollarSign}>
                  <H3>$</H3>
                </div>
                <div className={SocialTheme.dollarValue}>
                  <H1>99</H1>
                </div>
                <div className={SocialTheme.trailingText}>
                  <H4>/ ea.</H4>
                </div>
              </div>
              <Link to="/partnerships/purchase-social-actions" onClick={this.track('purchase')}>
                <Button
                  onClick={openPurchaseModal}
                  theme={ButtonTheme}
                  className={ButtonTheme.whiteButtonAzureText}
                  label="Buy Social Actions"
                />
              </Link>
            </div>
          </div>
          <div className={SocialTheme.card}>
            <img src="/images/social-action-assets/planet-icon.svg" />
            <div className={SocialTheme.copyContainer}>
              <H4>
                <i>Upgrade Plan</i>
              </H4>
              <Small>Includes 3 Social Actions per campaign</Small>
              <div className={SocialTheme.priceContainer}>
                <div className={SocialTheme.dollarSign}>
                  <div style={{ marginRight: '4px' }}>
                    <H4>from</H4>
                  </div>
                  <H3>$</H3>
                </div>
                <div className={SocialTheme.dollarValue}>
                  <H1>199</H1>
                </div>
                <div className={SocialTheme.trailingText}>
                  <H4>/ mo.</H4>
                </div>
              </div>
              <a href="/app/upgrade-plan" onClick={this.track('upgradePlan')}>
                <Button
                  onClick={closeUpsellModal}
                  theme={ButtonTheme}
                  className={ButtonTheme.whiteButtonAzureText}
                  label="View Upgrade Options"
                />
              </a>
            </div>
          </div>
        </div>
        <div className={SocialTheme.buttonContainer}>
          <Button
            onClick={closeUpsellModal}
            theme={ButtonTheme}
            className={ButtonTheme.blueButton}
            label="Close"
            primary
            raised
          />
        </div>
      </Dialog>
    )
  }
}

export default SocialActionsUpsellModal
