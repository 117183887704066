import React from 'react';
import { P } from 'visual-components/util/texts';

class PostEntryMessage extends React.Component {
  render() {
    return (
      <div 
        className="row text-center"
        style={{
          paddingTop: '15px',
          paddingBottom: '15px'
      }}>
        <P multiline>
          <span dangerouslySetInnerHTML={{__html: this.props.message}} />
        </P>
      </div>
    )
  }
}

export default PostEntryMessage;
