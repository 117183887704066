import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as RatingActions from 'actions/ratings-actions'

import { P } from 'visual-components/util/texts'
import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import SideNav from 'visual-components/sideBars/side-nav'
import BrandReviewsPending from './components/BrandReviewsPending'
import BrandReviewsPreview from './components/BrandReviewsPreview'

const stateToProps = ({ currentBrand, brandRatings }) => ({ currentBrand, brandRatings })

const dispatchToProps = dispatch => ({
  ratingActions: bindActionCreators(RatingActions, dispatch),
})

const routes = [
  {
    label: 'Pending Reviews',
    to: '/pending',
  },
  {
    label: 'Reviews For You',
    to: '/received',
  },
  {
    label: 'Reviews By You',
    to: '/posted',
  },
]

const ReceivedReviews = () => {
  const subtitle = (
    <P multiline>
      Here are all the reviews you have received from the brands you have collaborated with on Campaigns or Marketplace.
    </P>
  )

  return (
    <BrandReviewsPreview type="received" title="Reviews For You" subtitle={subtitle} />
  )
}

const PostedReviews = ({ brandName }) => {
  const subtitle = (
    <P multiline>
      All reviews below were sent by someone on your team under the
      <em>{` ${brandName} `}</em>
      brand.
    </P>
  )

  return (
    <BrandReviewsPreview type="posted" title="Reviews By You" subtitle={subtitle} />
  )
}

class MediaReviewsPage extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
  }

  render() {
    const { match, currentBrand } = this.props
    const { accountname: brandName } = currentBrand

    const { path: prefix } = match

    return (
      <InternalContentContainer width="1176px">
        <Helmet>
          <title>Reviews</title>
        </Helmet>
        <Route
          exact
          render={props => <SideNav width="240px" routes={routes} prefix={prefix} {...props} />}
        />
        <Switch>
          <Route exact path={`${prefix}/pending`} component={BrandReviewsPending} />
          <Route exact path={`${prefix}/received`} component={ReceivedReviews} />
          <Route exact path={`${prefix}/posted`} render={() => <PostedReviews brandName={brandName} />} />
          <Redirect to={`${prefix}/pending`} />
          <Redirect to="/404" />
        </Switch>
      </InternalContentContainer>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(MediaReviewsPage))
