import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { H3, H6, P } from 'visual-components/util/texts'
import Label from 'visual-components/util/inputs/label'
import COLOURS from 'util/colours'
import ImageDropZone from './image-dropzone'

const styles = {
  container: {
    marginTop: -15,
  },
  disclaimer: {
    fontFamily: 'Open Sans',
    fontSize: 11,
    lineHeight: '18px',
    color: '#202124',
  },
  image: {
    width: 'calc(100% + 30px)',
    margin: 0,
    marginLeft: -15,
    objectFit: 'contain',
    '& img': {
      maxWidth: '640px',
      maxHeight: '400px',
      objectFit: 'contain',
    },
    '&.preview': {
      marginTop: -1,
    },
  },
  title: {
    marginBottom: 8,
  },
  input: {
    width: '100%',
    height: 40,
    padding: '13px 12px',
    borderRadius: 2,
    border: '1px solid #dee4e5',
    fontFamily: 'Larsseit',
    fontSize: 14,
    marginBottom: 16,
    '&::placeholder': {
      color: '#b7c3cd',
    },
  },
  formHeader: {
    padding: '16px 0',
    borderBottom: `1px solid ${COLOURS.silver}`,
  },

  thankYou: {
    height: 114,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    textAlign: 'center',
    padding: 16,
    border: `solid 1px ${COLOURS.silver}`,
    '& > :first-child': {
      marginBottom: 8,
    },
  },

  errorMsg: {
    marginBottom: 16,
    '& input': {
      marginBottom: 0,
    },
    '& span': {
      marginTop: 2,
      color: COLOURS.red,
      fontSize: 10,
      display: 'none',
    },

    '&.show-error': {
      '& input': {
        border: `1px solid ${COLOURS.red}`,
      },

      '& span': {
        display: 'block',
      },
    },
  },
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  (condition ? wrapper(children) : children)

class MobileMarketingPostEntry extends React.Component {
  render() {
    const {
      cta, classes: css, brandName, preview, showImageUploadCTA, onDrop,
    } = this.props

    const terms = `* By clicking “${cta.button_text},” I authorize ${brandName} and their representatives to send marketing and informational text messages to the number I provided above using an automatic telephone dialing system or other technology. I acknowledge that my consent is not required as a condition of any purchase. Msg & Data rates may apply.`

    return (
      <div className={preview ? 'container-fluid' : css.container}>
        <div className={`form-header row text-center ${css.formHeader}`}>
          <H6>{cta.form_header}</H6>
        </div>
        <div className={`${preview ? '' : 'hide '}postentry_sms_body`} id={preview ? 'hideDraftPostEntry' : ''}>
          {
            showImageUploadCTA && !cta.image_url && (
              <ImageDropZone onDrop={onDrop} />
            )
          }
          {
            cta.image_url && (
              <ConditionalWrapper
                condition={cta.image_link}
                wrapper={children => (
                  <a href={cta.image_link} target="_blank" rel="noopener noreferrer">
                    {children}
                  </a>
                )}
              >
                <div className={`row text-center ${css.image} ${preview ? '' : 'preview'}`}>
                  <img
                    style={{ width: '100%' }}
                    src={cta.image_url}
                  />
                </div>
              </ConditionalWrapper>
            )
          }
          <div className="mobile-marketing-post-entry row" style={{ padding: '16px 0' }}>
            <div className="form-body col-xs-12">
              <div className={css.title}>
                <H3>
                  {cta.title}
                </H3>
              </div>
              <P style={{ marginBottom: '24px' }} multiline>{`Enter your phone number ${cta.unlock_copy} and receive mobile marketing texts.*`}</P>
              <Label label="Phone Number" />
              <div className={css.errorMsg}>
                <input disabled={!!preview} required={!preview} name="smsphone" type="tel" className={css.input} placeholder="(555) 555-5555" />
                <span className={css.errorMsgTst}>* This field is required</span>
              </div>
              <p className={css.disclaimer}>
                {terms}
              </p>
            </div>
            <div className="hide col-xs-12">
              <div className={css.thankYou}>
                <H3>Thank you!</H3>
                <P multiline>
                  {`You'll receive a mobile message from ${brandName} shortly.`}
                </P>
              </div>
            </div>
          </div>
          <div className="mobile-marketing-post-entry-btn row" style={{ paddingBottom: '30px' }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <button
                type={preview ? 'button' : 'submit'}
                className="btn btn-primary"
                style={{
                  width: '100%',
                  borderRadius: '2px',
                  borderWidth: '0px',
                  padding: '10px',
                  fontFamily: 'Larsseit-Medium',
                  backgroundColor: cta.button_color.color.color,
                  color: cta.button_text_color.color.color,
                }}
              >
                {cta.button_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MobileMarketingPostEntry.propTypes = {
  brandName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  cta: PropTypes.object.isRequired,
  preview: PropTypes.bool, // true when used in SMS Post Entry Form builder
  onDrop: PropTypes.func, //used for preview mode only (upload image)
}

MobileMarketingPostEntry.defaultProps = {
  preview: false,
  onDrop: () => {},
}

export default injectSheet(styles)(MobileMarketingPostEntry)
