export default {
  partnershipCampaignPerformance: {
    campaigns: [],
    aggregrates: {
      campaignCount: 0,
      entriesDriven: 0,
      ntfDriven: 0,
      ntfPerc: 0,
      avgEntries: 0,
    }
  }
}