import { getTemplate, compileTemplate } from './TemplateV2'

const template = getTemplate()
const themeColor = '#8f5e24'

template.submitButton.button = {
  borderRadius: 4,
}

template.formContainer = {
  form: {
    padding: props => (props.desktopVersion ? '0 60px' : '0 20px'),
    marginBottom: 40,
  },
  bottomInfo: {
    padding: props => (props.desktopVersion ? '40px 60px 0 60px' : '40px 20px 0 20px'),
  },
}

export const templateStyles = compileTemplate(template)
export const defaultVals = {}
