import React from 'react'
import _ from 'lodash'

import COLOURS from 'util/colours'

import { Button } from 'react-toolbox/lib/button'
import Badge from 'visual-components/util/badge'
import ButtonTheme from 'css/themes/Buttons.css'
import SwitchTheme from 'css/themes/Switch.css'

const themes = {
  Button: ButtonTheme,
  Switch: SwitchTheme,
}

class InvitedUserRow extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      invite,
      profile,
      currentBrand,
      cancelInvite,
      showDivider,
    } = this.props

    const credential = _.find(
      profile.credentials,
      credential => credential.brand.id == currentBrand.id
    )

    const isOwner = (credential ? credential.role : 'viewer') == 'owner'

    return (
      <tr
        style={{
          height: '54px',
          verticalAlign: 'middle',
          borderBottom: showDivider ? '1px solid #dee4e5' : null,
        }}
      >
        <td
          style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '54px',
            color: '#212731',
            width: '256px',
            paddingLeft: '16px',
          }}
        >
          <div
            style={{
              display: 'inline-block',
            }}
          >
            {invite.fullName || `${invite.firstName || ''} ${invite.lastName || ''}`}
          </div>
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '-6px',
              marginLeft: '12px',
            }}
          >
            <Badge label="Pending" color={COLOURS.cloudGrey} />
          </div>
        </td>
        <td
          style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '14px',
            color: '#212731',
            width: '320px',
          }}
        >
          {invite.email}
        </td>
        <td
          style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '14px',
            color: '#212731',
            width: '80px',
          }}
        >
          {invite.role == 'owner' ? 'Admin' : 'User'}
        </td>
        <td
          style={{
            width: '183px',
            textAlign: 'center',
          }}
        >
          {isOwner ? (
            <Button
              onClick={e => cancelInvite(invite.id)}
              label="Cancel Invite"
              theme={themes.Button}
              flat
              primary
            />
          ) : null}
        </td>
      </tr>
    )
  }
}

export default InvitedUserRow
