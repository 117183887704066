import React, { Component } from 'react'
import { Card } from 'react-toolbox/lib/card'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

import { Tiny, H3, H5 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import PopoutTaskcardButtons from '../util/buttons/popout-taskcard-buttons/PopoutTaskcardButtons'
import CampaignPopoutTheme from 'css/themes/CampaignPopoutTheme.css'

class CampaignTimelinePopout extends Component {
  render() {
    const {
      campaignPictureSrc,
      name,
      tasksRemaining,
      totalTasks,
      taskId,
      startDate,
      endDate,
      host,
      campaignId,
      tasksPercentageCompleted,
      event,
      deselect,
      exportToCal,
      analytics,
      manageCampaign,
      deselectShowMore
    } = this.props

    const momentStartDate = moment(startDate)
    const momentEndDate = moment(endDate)
    const momentNow = moment().toDate()


    return(
      <div>
        <div onClick={deselect} className={CampaignPopoutTheme.overlay} />
          <Card className={ CampaignPopoutTheme.mainContainer }
                style={{ top: `${event.top}px`, left: `${event.left}px` }}
          >
          <Grid>
            <Row>
              <Link to={`/partnerships/campaign/${campaignId}/quickview`} onClick={deselect}>
                <div className={ CampaignPopoutTheme.headerDisplay }
                    style={{
                      background: `url(${campaignPictureSrc})`,
                      backgroundPosition: '0% 0%',
                      position: 'relative',
                      backgroundSize: '100%'
                    }}
                >
                <div className={ CampaignPopoutTheme.coverGradient } />
                  <div className={ CampaignPopoutTheme.campaignTitle }>
                    <H5 white style={{ fontSize: '14px !important' }}>{ name }</H5>
                  </div>
                </div>
              </Link>

              <div className={ CampaignPopoutTheme.closeButton }>
                <img onClick={ deselect } src="images/icons/small-white-close.svg" />
              </div>
            </Row>

            {/*campaign date and host info*/}
            <Row className={ CampaignPopoutTheme.dateHostContainer }>
              <div className={ CampaignPopoutTheme.clockAndDate }>
                <div className={ CampaignPopoutTheme.timeIcon }><img src="/images/date-and-time.svg" /></div>
                <div className={ CampaignPopoutTheme.date }><Tiny>{`${momentStartDate.format('MMM D, YYYY')} - ${momentEndDate.format('MMM D, YYYY')}`}</Tiny></div>
              </div>

              <div className={ CampaignPopoutTheme.hostInfo }>
                <div className={ CampaignPopoutTheme.hostIconGroup }>
                  <div className={ CampaignPopoutTheme.hostIcon }><img src="/images/host.svg" /></div>
                  <div className={ CampaignPopoutTheme.hostLabel }><Tiny>Host:</Tiny></div>
                </div>
                <div className={ CampaignPopoutTheme.hostLabel }><Tiny><i>{ host.length > 19 ? host.slice(0, 19) + '..' : host }</i></Tiny></div>
              </div>
            </Row>

            {/*campaign info*/}
            <Row>
              {/*tasks left*/}
              <Col>
                <div className={ CampaignPopoutTheme.numberTasksLeft }>
                  <H3>{ tasksRemaining }</H3>
                </div>

                <div className={ CampaignPopoutTheme.tasksLeft }>
                  <Tiny>tasks left</Tiny>
                </div>
              </Col>

              {/*tasks complete */}
              <Col>
                <div className={ CampaignPopoutTheme.numberTasksComplete }>
                  <H3>{ totalTasks - tasksRemaining }</H3>
                </div>

                <div className={ CampaignPopoutTheme.complete }>
                  <Tiny>complete</Tiny>
                </div>
              </Col>

              {/*days left */}
              <Col>
                <div className={ CampaignPopoutTheme.numberDaysLeft }>
                  <H3>{ momentEndDate.diff(momentNow, 'days') }</H3>
                </div>

                <div className={ CampaignPopoutTheme.daysLeft }>
                  <Tiny>days left</Tiny>
                </div>
              </Col>
            </Row>

            {/*task visualizer*/}
            <Row className={ CampaignPopoutTheme.taskVisualizerContainer }>

              {/*task bar*/}
              <Col>
                <div className={ CampaignPopoutTheme.taskBarOuter }>

                  {/*status meter */}
                  <div className={ CampaignPopoutTheme.taskBarInner }
                       style={{ width: `${288 * tasksPercentageCompleted}px` }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <PopoutTaskcardButtons
                taskId={ taskId }
                campaignId={ campaignId }
                exportToCal={exportToCal}
                analytics={analytics}
                deselect={deselect}
                deselectShowMore={deselectShowMore}
                manageCampaign={manageCampaign}
              />
            </Row>
          </Grid>
        </Card>
      </div>
    )
  }
}


export default CampaignTimelinePopout
