import _ from 'lodash'

const ListReducer = function (model, initialState) {

  const defaultState = _.extend({}, {
    rid: model,
    identifier: 'id',
    items: []
  }, initialState);

  return function (state, action) {
    if (action.model !== model) {
      return state || defaultState;
    }
    const actions = {
      UPDATE_ATTR: function () {
        return _.merge({}, state, action.data);
      },
      LOADING: function () {
        return _.extend({}, state, { loading: true });
      },
      LOAD_SUCCESS: function () {
        var data = _.extend({}, state, { loading: false });
        var items = action.data;
        return _.extend({}, data, { items: items });
      },
      ADD_ITEM: function () {
        var data = _.extend({}, state);

        data.items.push(action.data);

        return data;
      },
      UPDATE_ATTR: function () {
        if (action.data.id) {
          var data = _.extend({}, state);
          var search = {};
          search[state.identifier] = action.data.id;

          var item = _.find(data.items, search);
          _.extend(item, action.data);
          return data;
        } else {
          return _.merge({}, state, action.data);
        }
      },
      UPDATE_ITEM: function () {
        var data = _.extend({}, state);

        var search = {};
        search[state.identifier || 'id'] = action.data.id;

        var item = _.find(data.items, search);

        _.merge(item, action.data);

        return data;
      },
      REMOVE_ITEM_BY_ID: function () {
        var data = _.extend({}, state);

        var search = {};
        search[state.identifier] = action.data.id;
        _.remove(data.items, search);

        return data;
      },
      SORT_ITEM_ALPHA: function () {
        var data = _.extend({}, state);
        data.items = data.items.concat().sort(function(a,b) {
          if(a[action.data.sortField] && b[action.data.sortField]) {
            let valueA = typeof a[action.data.sortField] == 'number' ? a[action.data.sortField] : a[action.data.sortField].toUpperCase();
            let valueB = typeof b[action.data.sortField] == 'number' ? b[action.data.sortField] : b[action.data.sortField].toUpperCase();
            if (valueA < valueB) {
              return action.data.sortAsc ? -1 : 1;
            }
            if (valueA > valueB) {
              return action.data.sortAsc ? 1 : -1;
            }
          }
          return 0;
        })
        return data;
      } 
    };

    const item = actions[action.type] || function () {
      return state || defaultState;
    };

    return item();
  };
}

export default ListReducer;