import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import COLOURS from 'util/colours'

const partnerStats = {
  partnerBannerRequested: {
    backgroundColor: COLOURS.seaGreen,
    color: 'white',
    image: '/images/icons/white-triangle-exclamation.svg',
    getText() {
      return "You must set up a Conversion Action to activate Split Traffic.  Prepare a Custom CTA or enable SMS Marketing while you wait for the host's response."
    },
  },
  partnerBannerInactive: {
    backgroundColor: 'rgba(249, 178, 0, 0.5)',
    color: '#4c4c4c',
    image: '/images/icons/black-triangle-exclamation.svg',
    getText(isDraft = false) {
      return isDraft ? this.publishText : this.setUpText
    },
    publishText:
      'Publish your SMS Marketing to complete your Conversion Action set up.',
    setUpText:
      'Set up a Conversion Action to activate your share of the traffic.',
  },
  isValidStatus(status) {
    return Object.keys(this).includes(status)
  },
}
const styles = {
  outerBox: {
    backgroundColor: props => {
      const { status } = props
      if (partnerStats.isValidStatus(status)) {
        return partnerStats[status].backgroundColor
      }
      return null
    },
    padding: '0 20px 0 36px',
    color: props => {
      const { status } = props
      if (partnerStats.isValidStatus(status)) {
        return partnerStats[status].color
      }
      return null
    },
    display: 'flex',
    alignItems: 'center',
    height: 60,
  },
  rightWrapper: {
    fontFamily: 'larsseit',
    fontSize: 16,
    marginLeft: 20,
  },
}

const SplitTrafficPartnerActionBanner = props => {
  const {
    classes: css,
    status = null,
    postEntryStatus,
  } = props
  const doNotShow = ![
    'partnerBannerShared',
    'partnerBannerDeclined',
    'partnerBannerOffered',
    '',
  ].includes(status)

  const bannerText = partnerStats.isValidStatus(status) && partnerStats[status].getText(postEntryStatus === 'draft')
  const { image } = partnerStats.isValidStatus(status) && partnerStats[status]

  return (
    doNotShow && (
      <div className={css.outerBox}>
        <div className={css.imgWrapper}>
          <img src={image} height="24px" />
        </div>
        <div className={css.rightWrapper}>{bannerText}</div>
      </div>
    )
  )
}

SplitTrafficPartnerActionBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string,
  postEntryStatus: PropTypes.string.isRequired,
}

SplitTrafficPartnerActionBanner.defaultProps = {
  status: '',
}

export default React.memo(injectSheet(styles)(SplitTrafficPartnerActionBanner))
