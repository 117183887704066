import initialState from './defaults/brandSearch'

export default {
  model: 'brandSearch',
  initialState,
  endpoint: '/search',
  sources: [
    'id', 'accountname', 'logo', 'facebookCoverPhoto', 'coverPhotoOffsetY',
    'dedicatedListSize', 'averageSignUpsPerCampaign', 'socialMediaImpressions',
    'tags', 'ratingAvg', 'ratingCount', 'degree', 'firstDegreePartners', 'secondDegreePartners',
  ],
  inputName: ['accountname', 'description', 'meta', 'industry.search'],
  aggs: getState => {
    const brand = getState().currentBrand
    const currentBrandId = brand.id

    return {
      partnershipHistory: {
        filters: {
          filters: {
            '1st': {
              bool: {
                must: [{ term: { firstDegreePartners: currentBrandId } }],
              },
            },
            '2nd': {
              bool: {
                must: [{ term: { secondDegreePartners: currentBrandId } }],
              },
            },
            '3rd': {
              bool: {
                must: [
                  {
                    bool: {
                      must_not: {
                        term: {
                          firstDegreePartners: currentBrandId,
                        },
                      },
                    },
                  },
                  {
                    bool: {
                      must_not: {
                        term: {
                          secondDegreePartners: currentBrandId,
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        },
      },
      tags: {
        filter: {},
        aggs: {
          tags: {
            terms: {
              field: 'industry.raw',
              size: 100,
            },
          },
        },
      },
      interests: {
        filter: {},
        aggs: {
          interests: {
            terms: {
              field: 'interests.raw',
            },
          },
        },
      },
    }
  },
  rangeFilters: [
    'listSizeValue',
    'avgEntriesCampaignValue',
    'totalSocialAudience',
  ],
  facets: ['tags', 'interests', 'partnershipHistory'],
  extendQuery({ body, state, getState }) {
    const { filter } = body.query.bool

    if (!state.searchValue) {
      filter.push({
        term: {
          inactive: false,
        },
      })
    }

    // Recent Activity
    // accepts 'older', 90, 60, 30 as parameters
    if (state.recentActivity && state.recentActivity.value && !state.searchValue) {
      const lastActivity = {}
      if (state.recentActivity.value !== 'older') {
        lastActivity.gte = `now-${state.recentActivity.value}d`
      }
      filter.push({
        range: {
          lastActivity,
        },
      })
    }

    if (state.joinDate && state.joinDate.value && state.joinDate.value !== 'all') {
      filter.push({
        range: {
          created_at: {
            gte: `now-${state.joinDate.value}d`
          }
        }
      })
    }

    const brandId = getState().currentBrand.id

    // Partnership History
    // accepts array of objects w/keys up to ['1st', '2nd', '3rd']
    // requires brand ID
    const { partnershipHistory } = state
    if (!partnershipHistory.all && partnershipHistory.options) {
      const { options } = partnershipHistory
      const partnerHistory = { bool: { should: [] } }
      const { should } = partnerHistory.bool
      options.forEach(({ key, value }) => {
        if (!value) {
          return
        }
        if (key === '1st') {
          should.push({
            term: {
              firstDegreePartners: brandId,
            },
          })
        } else if (key === '2nd') {
          should.push({
            term: {
              secondDegreePartners: brandId,
            },
          })
        } else if (key === '3rd') {
          should.push({
            bool: {
              must_not: [
                {
                  term: {
                    firstDegreePartners: brandId,
                  },
                },
                {
                  term: {
                    secondDegreePartners: brandId,
                  },
                },
              ],
            },
          })
        }
      })

      const { post_filter } = body

      if (should.length) {
        post_filter.bool.must = post_filter.bool.must || []
        post_filter.bool.must.push(partnerHistory)
        // apply degree filter to tags aggregation as well
        body.aggs.tags.filter = partnerHistory
        body.aggs.interests.filter = partnerHistory
      } else {
        body.size = 0
      }
    }

    return body
  },
}
