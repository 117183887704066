export const loading = () => ({ type: 'LOADING_DEALS' })
export const finished = () => ({ type: 'LOAD_DEALS_FINISH' })

export const clearDeal = () => ({ type: 'CLEAR_DEAL' })
export const clearTempProposal = () => ({ type: 'CLEAR_TEMP_PROPOSAL' })
export const clearActionSuccess = () => ({ type: 'CLEAR_DEAL_SUCCESSES' })

export const resetError = () => ({ type: 'DEALS_RESET_ERROR' })

export const updateAttr = data => ({
  type: 'UPDATE_DEAL_ATTR',
  data,
})

export const updateLinkAttr = data => ({
  type: 'UPDATE_DEAL_LINK_ATTR',
  data,
})

export const updateDates = (dealId, data) => ({
  types: [
    'UPDATE_DEAL_DATES',
    'UPDATE_DEAL_DATES_SUCCESS',
    'UPDATE_DEAL_DATES_FAILURE',
  ],
  http: {
    method: 'PATCH',
    url: `/deals/${dealId}/dates`,
    data,
  },
  data,
})

export const fetchInitialDates = dealId => ({
  types: [
    'FETCH_DEAL_DATES',
    'FETCH_DEAL_DATES_SUCCESS',
    'FETCH_DEAL_DATES_FAILURE',
  ],
  http: {
    url: `/deals/${dealId}/dates`,
  },
})

export const showDeclineReasonModal = (data = true) => ({
  type: 'SHOW_DECLINE_REASON_MODAL',
  data,
})

export const showChangeDatesModal = (data = true) => ({
  type: 'SHOW_CHANGE_DATES_MODAL',
  data,
})

export const createTargetLink = (dealId, link) => ({
  http: {
    url: `/deal/target-link/${dealId}`,
    method: 'POST',
    data: link,
  },
  types: [
    'LOADING_DEALS',
    'CREATE_DEAL_LINK_SUCCESS',
    'CREATE_DEAL_LINK_FAILURE',
  ],
})

export const editTargetLink = (dealId, link) => ({
  http: {
    url: `/deal/target-link/${dealId}/${link.id}`,
    method: 'PUT',
    data: link,
  },
  types: [
    'LOADING_DEALS',
    'UPDATE_DEAL_LINK_SUCCESS',
    'UPDATE_DEAL_LINK_FAILURE',
  ],
})

export const resetAnalytics = () => ({
  type: 'DEAL_ANALYTICS_RESET',
})

export const load = () => ({
  http: {
    url: '/deals',
  },
  types: [
    'LOADING_DEALS',
    'LOAD_DEALS_SUCCESS',
    'LOAD_DEALS_FAILURE',
  ],
})

export const loadById = dealId => ({
  http: {
    url: `/deals/${dealId}`,
  },
  types: [
    'LOADING_DEALS',
    'LOAD_DEAL_SUCCESS',
    'LOAD_DEAL_FAILURE',
  ],
})

export const create = data => ({
  http: {
    url: '/deals',
    method: 'POST',
    data,
  },
  types: [
    'LOADING_DEALS',
    'CREATE_DEAL_SUCCESS',
    'CREATE_DEAL_FAILURE',
  ],
})

export const confirm = (dealId, data) => ({
  dealId,
  http: {
    url: `/deals/confirm/${dealId}`,
    method: 'POST',
    data,
  },
  types: [
    'LOADING_DEALS',
    'CONFIRM_DEAL_SUCCESS',
    'CONFIRM_DEAL_FAILURE',
  ],
})

export const counter = (dealId, data, status) => ({
  dealId,
  status,
  http: {
    url: `/deals/counter/${dealId}`,
    method: 'POST',
    data,
  },
  types: [
    'LOADING_DEALS',
    'COUNTER_DEAL_SUCCESS',
    'COUNTER_DEAL_FAILURE',
  ],
})

export const decline = (dealId, data = {}) => ({
  dealId,
  http: {
    url: `/deals/decline/${dealId}`,
    method: 'POST',
    data,
  },
  types: [
    'LOADING_DEALS',
    'DECLINE_DEAL_SUCCESS',
    'DECLINE_DEAL_FAILURE',
  ],
})

export const getDealAnalyticsById = id => ({
  http: {
    url: `/deals/analytics/${id}`,
    method: 'GET',
  },
  types: [
    'GET_DEAL_ANALYTICS_BY_ID',
    'GET_DEAL_ANALYTICS_BY_ID_SUCCESS',
    'GET_DEAL_ANALYTICS_BY_ID_FAILURE',
  ],
  id,
})

export const getDealClickAnalyticsById = deal => ({
  http: {
    url: `/deals/analytics/${deal.id}`,
    method: 'GET',
  },
  types: [
    'GET_DEAL_CLICK_ANALYTICS_BY_ID',
    'GET_DEAL_CLICK_ANALYTICS_BY_ID_SUCCESS',
    'GET_DEAL_CLICK_ANALYTICS_BY_ID_FAILURE',
  ],
  data: deal,
})

export const getDealPixelAnalyticsById = dealId => ({
  http: {
    url: `/deals/pixel-analytics/${dealId}`,
  },
  types: [
    'GET_DEAL_PIXEL_ANALYTICS_BY_ID',
    'GET_DEAL_PIXEL_ANALYTICS_BY_ID_SUCCESS',
    'GET_DEAL_PIXEL_ANALYTICS_BY_ID_FAILURE',
  ],
})

export const getDealESPAnalyticsById = (dealId, integrationType) => ({
  http: {
    url: `${integrationType}/fetch-report/${dealId}`,
  },
  types: [
    'GET_DEAL_ANALYTICS_ESP',
    'GET_DEAL_ESP_ANALYTICS_SUCCESS',
    'GET_DEAL_ANALYTICS_ESP_FAILURE',
  ],
})

export const toggleCumulative = () => ({
  type: 'TOGGLE_DEAL_ANALYTICS_CUMMULATIVE',
})

export const dealAnalyticsGraphSwitchTab = tab => ({
  type: 'SWITCH_DEAL_ANALYTICS_SELECTED_TAB',
  data: tab,
})

export const selectDateRange = ({ startDate, endDate, timeFrame }) => ({
  type: 'UPDATE_DEAL_ANALYTICS_TIMEFRAME',
  data: { startDate, endDate, timeFrame },
})

export const togglePixelSetup = pixelSetupActive => ({
  type: 'UPDATE_DEAL_ATTR',
  data: { pixelSetupActive },
})

export const closePixelConfirmation = isFailedConfirm => ({
  type: 'CLEAR_DEAL_PIXEL',
  data: { isFailedConfirm },
})

export const testPixel = brandId => ({
  http: {
    url: `/tctesting/${brandId}`,
  },
  types: [
    'TESTING_DEAL_PIXEL',
    'TESTING_DEAL_PIXEL_SUCCESS',
    'TESTING_DEAL_PIXEL_FAILURE',
  ],
})

// Readable types that must be updated at the same time as deal messages being marked as read
const SHARED_READABLE_TYPES = [
  'links', 'proposals', 'attachments', 'deal_unread',
]

export const markRead = (dealId, type) => dispatch => {
  if (type === 'messages') {
    SHARED_READABLE_TYPES.forEach(sharedType => dispatch({
      type: 'MARK_DEAL_ITEMS_READ',
      data: { dealId, type: sharedType },
    }))
  }
  dispatch({
    http: {
      url: `/deal/read-receipt/${type}/${dealId}`,
      method: 'POST',
    },
    types: ['MARK_DEAL_ITEMS_READ', 'NOOP', 'NOOP'],
    data: { dealId, type },
  })
}
