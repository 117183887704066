import React, { Component } from 'react'

import theme from 'css/themes/mobile/home/pricing.css'

class TrustedBy extends Component {
  render() {
    let {
      whiteBackground
    } = this.props;

    return(
      <div className={ `${theme.trustedBy } ${whiteBackground ? theme.whiteBackground : ''}`}>
        <p className={ theme.sectionTitle }>
          Trusted by Thousands of the World’s Top Marketers
        </p>
        <div className={ theme.imgContainer }>
          <div className={ theme.inner }>
            <div>
              <img
                alt="Casper"
                src={ '/images/login/mobile/home/trusted-by/casper-logo.png' }
                srcSet={`/images/login/mobile/home/trusted-by/casper-logo.png,
                  /images/login/mobile/home/trusted-by/casper-logo@2x.png 2x,
                  /images/login/mobile/home/trusted-by/casper-logo@3x.png 3x`}
              />
            </div>
            <div>
              <img
                alt="Daily Harvest"
                src="/images/login/mobile/home/trusted-by/daily-harvest-logo.svg"
                style={{ width: '65%', height: 'auto' }}
              />
            </div>
          </div>
          <div className={ theme.inner }>
          <div>
            <img
              alt="Harper Collins"
              src={ '/images/login/mobile/home/trusted-by/harpercollins-logo.png' }
              srcSet={`/images/login/mobile/home/trusted-by/harpercollins-logo.png,
                /images/login/mobile/home/trusted-by/harpercollins-logo@2x.png 2x,
                /images/login/mobile/home/trusted-by/harpercollins-logo@3x.png 3x`}
            />
            </div>
            <div>
            <img
              alt="Harper's Bazaar"
              src={ '/images/login/mobile/home/trusted-by/harper-s-bazaar-logo.png' }
              srcSet={`/images/login/mobile/home/trusted-by/harper-s-bazaar-logo.png,
                /images/login/mobile/home/trusted-by/harper-s-bazaar-logo@2x.png 2x,
                /images/login/mobile/home/trusted-by/harper-s-bazaar-logo@3x.png 3x`}
            />
            </div>
          </div>
          <div className={ theme.inner }>
            <div>
              <img
                alt="theSkimm"
                src={ '/images/login/mobile/home/trusted-by/the-skimm-logo.png' }
                srcSet={`/images/login/mobile/home/trusted-by/the-skimm-logo.png,
                  /images/login/mobile/home/trusted-by/the-skimm-logo@2x.png 2x,
                  /images/login/mobile/home/trusted-by/the-skimm-logo@3x.png 3x`}
              />
              </div>
              <div>
              <img
                alt="Hearst"
                src={ '/images/login/mobile/home/trusted-by/hearst-logo.png' }
                srcSet={`/images/login/mobile/home/trusted-by/hearst-logo.png,
                  /images/login/mobile/home/trusted-by/hearst-logo@2x.png 2x,
                  /images/login/mobile/home/trusted-by/hearst-logo@3x.png 3x`}
              />
            </div>
          </div>
          <div className={ theme.inner }>
            <div>
            <img
              alt="Wellpath"
              src={ '/images/login/mobile/home/trusted-by/wellpath-logo.svg' }
              style={{width:"50%", height: "auto", marginTop: "-5px"}}
            />
            </div>
            <div>
            <img
              alt="General Assembly"
              src={ '/images/login/mobile/home/trusted-by/general-assembly-logo.png' }
              srcSet={`/images/login/mobile/home/trusted-by/general-assembly-logo.png,
                /images/login/mobile/home/trusted-by/general-assembly-logo@2x.png 2x,
                /images/login/mobile/home/trusted-by/general-assembly-logo@3x.png 3x`}
            />
          </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrustedBy
