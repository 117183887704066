import React, { Component } from 'react';
import Header from '../header/Header';

import theme from 'css/themes/homePage/resources.css';

class ResourcesBanner extends Component {
  render() {
    return(
      <div className={ theme.banner }>
        <Header lightBackground={ true } />
        <div className={ theme.inner }>
          <img src={ '/images/login/resources/education-icon.svg'} />
          <p className={ theme.title }>
            DojoMojo Resource Library
          </p>
        </div>

      </div>
    )
  }
}

export default ResourcesBanner;
