import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { Link } from 'react-router-dom'

import { P, H4 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

import Dropdown from 'react-toolbox/lib/dropdown'
import DropDownTheme from 'css/themes/Dropdown.css'
import { MobileMarketingContext } from './MobileMarketingContext'

const styles = {
  container: {
    width: '100%',
    outline: `1px solid ${COLOURS.silver}`,
    marginTop: 32,
  },
  criteria: {
    padding: 28,
    outline: `1px solid ${COLOURS.silver}`,
    backgroundColor: COLOURS.crystal,
    '& > :first-child': {
      marginBottom: 16,
      '& + p': {
        marginBottom: 8,
      },
    },
  },
  dropdown: {
    maxWidth: 500,
    margin: 'unset',
    backgroundColor: 'white',
    '& p': {
      lineHeight: 'unset',
      '& a': {
        marginLeft: 4,
      },
    },
    '& ul': {
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    },
    '& li': {
      margin: '4px 0',
    },
  },
  content: {
    padding: '32px 28px 28px',
    '& > div:not(:last-of-type)': { // Space between followup messages
      marginBottom: '32px !important',
    },
  },
}

function FollowupSendCriteriaWrapper(props) {
  const { classes: css, children } = props
  const {
    setFollowupSendCriteria,
    followupSendCriteria,
    FOLLOWUP_CRITERIA_OPTIONS,
  } = React.useContext(MobileMarketingContext)

  const dropdownTemplate = item => (
    <div className={css.dropdownItem}>
      <P cloudGrey={item.disabled}>
        <i>{item.label}</i>
        {
          item.link && item.linkLabel && (
            <Link to={item.link}>
              {item.linkLabel}
            </Link>
          )
        }
      </P>
    </div>
  )

  return (
    <div className={css.container}>
      <div className={css.criteria}>
        <H4>
          <em>Follow up Criteria</em>
        </H4>
        <P>
          <em>Send if subscriber</em>
        </P>
        <Dropdown
          theme={DropDownTheme}
          className={css.dropdown}
          source={FOLLOWUP_CRITERIA_OPTIONS}
          template={dropdownTemplate}
          onChange={setFollowupSendCriteria}
          value={followupSendCriteria}
        />
      </div>
      <div className={css.content}>
        {children}
      </div>
    </div>
  )
}

FollowupSendCriteriaWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default React.memo(injectCSS(styles)(FollowupSendCriteriaWrapper))
