import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import { Chip } from 'react-toolbox'
import cn from 'classnames'

import theme from 'css/themes/chip-input.css'

const TAB_KEYCODE = 9
const COMMA_KEYCODE = 188
const SPACE_KEYCODE = 32
const ENTER_KEYCODE = 13

export default class ChipInput extends Component {
  static propTypes = {
    chipTheme: PropTypes.object,
    onListUpdate: PropTypes.func,
    onItemDeleted: PropTypes.func,
    onItemAdded: PropTypes.func,
    list: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    inputProps: PropTypes.object,
    addChipKeyCodes: PropTypes.array,
  }

  static defaultProps = {
    addChipKeyCodes: [ENTER_KEYCODE, TAB_KEYCODE, COMMA_KEYCODE, SPACE_KEYCODE], // [ENTER, TAB,COMMA,SPACE]
    onListUpdate: f => f,
    onItemAdded: f => f,
    onItemDeleted: f => f,
    placeholder: null,
    list: null,
    inputProps: null,
    chipTheme: theme.chip,
  }

  componentDidMount() {
    if (this.container) {
      this.container.addEventListener('click', this.handleFocusClick)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { list } = this.props
    if (nextProps.list !== list) {
      this.input.value = ''
    }
  }

  handleFocusClick = e => {
    e.preventDefault()
    this.input.focus()
  }

  handleKeyDown = e => {
    const { addChipKeyCodes } = this.props

    if (addChipKeyCodes.indexOf(e.keyCode) >= 0) {
      e.preventDefault()
      this.handleAdd(e.target.value)
    }
  }

  handleDelete = deletedItem => {
    const { onItemDeleted, onListUpdate, list } = this.props

    onItemDeleted(deletedItem)
    onListUpdate(list.filter(item => item !== deletedItem))
  }

  handleAdd = addedItem => {
    const { onItemAdded, onListUpdate, list } = this.props

    onItemAdded(addedItem)
    onListUpdate([...list, addedItem])
  }

  handleBlur = e => {
    if (e.target.value) {
      this.handleAdd(e.target.value)
    }
  }

  renderChips() {
    const { list, chipTheme } = this.props

    return list.map(item => (
      <li className={theme.listItem} key={item}>
        <Chip
          key={item}
          deletable
          theme={chipTheme}
          onDeleteClick={() => this.handleDelete(item)}
        >
          {item}
        </Chip>
      </li>
    ))
  }

  render() {
    const { list, inputProps, style = {} } = this.props
    return (
      <div
        className={cn(theme.container, 'clearfix')}
        ref={el => (this.container = el)}
        style={style}
      >
        <ul className={cn(theme.inputList, theme.listItem)}>
          {this.renderChips()}
          <li className={`${theme.inputListItem} ${list.length ? theme.withItems : ''}`}>
            <input
              {...inputProps}
              ref={el => (this.input = el)}
              className={cn(
                theme.inputField,
                inputProps && inputProps.className
              )}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
          </li>
        </ul>
      </div>
    )
  }
}
