import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PropTypes from 'prop-types'

import { cannotExceed, isInRange } from 'visual-components/util/form/validations'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import {
  H4,
  H6,
  H3,
  Small,
} from 'visual-components/util/texts'
import CheckboxWithInput from 'visual-components/util/CheckboxWithInput'

import SellerProfileActions from 'actions/seller-profile-actions'
import ModalTheme from 'css/themes/seller-profile-tab.css'
import MultiTaskModal from '../../../app/visual-components/util/modals/MultiTaskModal'


const stateToProps = ({ sellerProfileModals, sellerProfile }) => ({
  isOpen: sellerProfileModals.showGroupAgeDistributionModal,
  sellerProfile,
})

const dispatchToProps = dispatch => ({
  sellerProfileActions: bindActionCreators(SellerProfileActions, dispatch),
})

const availableItems = [
  {
    min_age: 0,
    max_age: 18,
  },
  {
    min_age: 19,
    max_age: 25,
  },
  {
    min_age: 26,
    max_age: 35,
  },
  {
    min_age: 36,
    max_age: 45,
  },
  {
    min_age: 46,
    max_age: 55,
  },
  {
    min_age: 56,
    max_age: 200,
  },
]

const stateItems = [
  {
    isChecked: false,
    canCheck: true,
    value: null,
  },
  {
    isChecked: false,
    canCheck: true,
    value: null,
  },
  {
    isChecked: false,
    canCheck: true,
    value: null,
  },
  {
    isChecked: false,
    canCheck: true,
    value: null,
  },
  {
    isChecked: false,
    canCheck: true,
    value: null,
  },
  {
    isChecked: false,
    canCheck: true,
    value: null,
  },
]

const RaiseError = () => (
  <div style={{ display: 'flex' }}>
    <img className={ModalTheme.warnImg} src="/images/Error_Icon.svg" />
    <H6 alertRed multiline>
      You can't exceed 100%
    </H6>
  </div>
)

class GroupAgeDistributionModal extends Component {
  static propTypes = {
    sellerProfileActions: PropTypes.object.isRequired,
    sellerProfile: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = {
    total: 0,
    items: [...stateItems],
  }

  onSave = () => {
    const { items } = this.state
    const { sellerProfileActions } = this.props

    const data = []

    items.forEach((stateItem, i) => {
      const isItem = stateItem.isChecked
        && stateItem.canCheck
        && Number(stateItem.value)

      const item = availableItems[i]

      if (isItem) {
        data.push({
          max_age: item.max_age,
          min_age: item.min_age,
          percent: Number(stateItem.value / 100), // divide for decimal format
        })
      }
    })

    sellerProfileActions.updateSellerProfile({ ageDistributions: data })
    this.onClose()
  }

  onClose = () => {
    const { sellerProfileActions } = this.props
    sellerProfileActions.closeModal()
  }

  onCheck = idx => val => {
    const { items } = this.state

    const item = items[idx]
    item.isChecked = val

    items.splice(idx, 1, item)

    this.setValues([...items])
  }

  checkIfCanCheck = () => {
    const { items } = this.state
    const enoughItemsChecked = items.filter(item => item.isChecked).length >= 3

    const newItems = []

    items.forEach(item => {
      if (!item.isChecked && enoughItemsChecked) item.canCheck = false
      else item.canCheck = true

      newItems.push(item)
    })

    this.setState({ items: newItems })

    return newItems
  }

  onInputBlur = idx => val => {
    const { items } = this.state

    const item = items[idx]

    item.value = Number(val)

    items.splice(idx, 1, item)

    this.setValues(items)
  }

  renderCheckboxWithInputComps = isOverHundred => {
    const { items } = this.state

    return availableItems.map((item, i) => {
      const { max_age, min_age } = item
      let label = `${min_age <= 0 ? '< ' : min_age} `
      label = `${label}${min_age > 0 && max_age < 100 ? '-' : ''} `
      label = `${label}${max_age > 100 ? '+' : max_age}`

      const stateItem = items[i]

      return (
        <CheckboxWithInput
          pad={!(i % 2)}
          raiseError={isOverHundred}
          customErrorMsg=""
          canCheck={stateItem.canCheck}
          isChecked={stateItem.isChecked}
          value={Math.floor(stateItem.value)}
          label={label}
          key={label}
          formatter={e => `${e}%`}
          onChange={this.onInputBlur(i)}
          onCheck={this.onCheck(i)}
          placeholder="50%"
          validators={[
            isInRange({
              minValue: 1,
              maxValue: 100,
            }),
            cannotExceed(100),
          ]}
        />
      )
    })
  }

  getTotal = () => {
    const { items } = this.state

    const total = items.reduce((prev, item) => {
      const { value, isChecked } = item

      if (!isChecked) return prev

      return prev + Number(value ? value : 0)
    }, 0)

    return this.setState({ total })
  }

  setValues = items => {
    this.setState({ items: [...items] }, () => {
      this.checkIfCanCheck()
      this.getTotal()
    })
  }

  componentDidUpdate = prevProps => {
    const { isOpen, sellerProfile } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      if (sellerProfile && sellerProfile.ageDistributions) {
        const { items } = this.state
        const newItems = []

        items.forEach((stateItem, i) => {
          const item = availableItems[i]
          const dataItem = sellerProfile.ageDistributions.find(obj => obj.min_age === item.min_age && obj.max_age === item.max_age)

          if (dataItem) {
            stateItem.value = dataItem.percent * 100
          }

          stateItem.isChecked = !!dataItem

          newItems.push(stateItem)
        })

        this.setValues(newItems)
      }
    }
  }

  render() {
    const {
      total,
    } = this.state

    const { isOpen } = this.props

    const isOverHundred = total > 100

    return (
      <MultiTaskModal
        active={isOpen}
        title="Primary Age Distribution"
        close={this.onClose}
      >
        <div style={{ marginBottom: '16px' }}>
          <H4 multiline>
            Add the age distribution of your audience.
          </H4>
        </div>
        <div style={{ display: 'flex', marginBottom: '16px', alignItems: 'center' }}>
          <H6>Age Range</H6>
          &nbsp;
          <Small>Select up to 3 ranges.</Small>
        </div>
        <div className={ModalTheme.checkboxInputs}>
          { this.renderCheckboxWithInputComps(isOverHundred) }
        </div>
        <div className={ModalTheme.total}>
          <H3>
            { `TOTAL: ${total}%` }
          </H3>
          {
            isOverHundred
            && <RaiseError />
          }
        </div>
        <ModalButtonGroup
          canSave={!isOverHundred}
          confirm={this.onSave}
          cancel={this.onClose}
          confirmText="Done"
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(GroupAgeDistributionModal)
