import AppDispatcher from 'dispatchers/app-dispatcher';
import _ from 'lodash'

const CampaignElementsDispatcher = {
  updateCampaignElements: function(data) {
    data = data || {};

    data.platform = data.platform || 
      (store.getState().currentCampaign.viewDesktop ? 'desktop' : 'mobile');

    store.dispatch({
      type: 'UPDATE_ELEMENTS',
      model: 'currentCampaign',
      data
    });
  },
  persistCampaignElements: function(data) {
    this.updateCampaignElements(data);
    this.persist(data);
  },
  persist: _.debounce(function(data) {
    data.platform = data.platform || 
      (store.getState().currentCampaign.viewDesktop ? 'desktop' : 'mobile');

    data.id = data.id || store.getState().currentCampaign[`${data.platform}Elements`].id;

    AppDispatcher.http({
      url: `/campaign-elements/${data.id}`,
      data,
      method: 'PUT'
    });
  }, 600)
}

export default CampaignElementsDispatcher;
