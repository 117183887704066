import React from 'react'
import { object } from 'prop-types'
import { Button } from 'react-toolbox/lib/button'
import { H3, H4 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import IntegrationTheme from 'css/themes/integrations.css'
import ButtonTheme from 'css/themes/Buttons.css'
import MergeTagsTable from './merge-tags-table'
import TestResults from './integration-test-results'

class MergeTags extends React.Component {
  static propTypes = {
    currentCampaign: object.isRequired,
    integration: object.isRequired,
    espIntegration: object.isRequired,
    espIntegrations: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  componentDidMount() {
    const {
      espIntegration,
      utilityFunctions: { defaultMergeTags },
    } = this.props

    if (!espIntegration.merge_tags || !espIntegration.merge_tags.length) {
      defaultMergeTags()
    }
  }

  renderActionButtonGroup = () => {
    const {
      integration: {
        connectInputs = [],
        selectInputs = [],
      },
      espIntegration,
      utilityFunctions: { defaultMergeTags, test },
    } = this.props

    const inputs = connectInputs.concat(selectInputs)
    const valid = inputs.every(input => input.optional || espIntegration[input.attr])

    return (
      <div>
        <Button
          label="Restore Defaults"
          onClick={() => defaultMergeTags(true)}
          className={ButtonTheme.whiteButton}
          theme={ButtonTheme}
          style={{ marginRight: '16px' }}
          raised
        />
        { valid
          ? (
            <Button
              label="Test"
              onClick={() => test()}
              className={ButtonTheme.whiteButton}
              theme={ButtonTheme}
              raised
            />
          ) : null
        }
      </div>
    )
  }

  renderModalButtonGroup = () => {
    const {
      integration,
      espIntegration,
      utilityFunctions: { activate, deactivate, goTo },
    } = this.props

    const prevStep = integration.selectInputs ? 'select' : 'details'

    return (
      <div className={IntegrationTheme.modalButtonGroup}>
        <ModalButtonGroup
          confirm={!espIntegration.activated ? activate : deactivate}
          confirmText={!espIntegration.activated ? 'Activate' : 'Deactivate'}
          cancel={() => goTo(prevStep)}
          cancelText="Back"
          canSave
          hideLine
        />
      </div>
    )
  }

  render() {
    const {
      espIntegrations: { testLoading, testResults },
    } = this.props

    return (
      <div>
        <div className={IntegrationTheme.modalSubtitle}>
          <H3 coral>
            <small>Merge Field Settings</small>
          </H3>
        </div>

        <div className={IntegrationTheme.modalPara}>
          <H4 multiline>
            {"Set the merge tag labels below so that DojoMojo's email list export data conforms to the pre-existing settings of your email list."}
          </H4>
        </div>

        <div>
          <MergeTagsTable {...this.props} />
        </div>

        <div>
          <TestResults testLoading={testLoading} testResults={testResults} />
        </div>

        { this.renderActionButtonGroup() }

        { this.renderModalButtonGroup() }
      </div>
    )
  }
}

export default MergeTags
