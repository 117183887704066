import React, { Component } from 'react'
import { connect } from 'react-redux'
import COLOURS from 'util/colours'

import Field from 'util/components/field';

import PrimaryButton from 'util/components/buttons/primary-button'

import Text from 'util/components/text';

import Validator from 'util/form-validator';

var items = {
  email: [Validator.required]
}

const mapState = ({ forgot, forgotForm }) => ({ forgot, forgotForm })

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    // redirect to new forgot-password page if user tries to access old page
    window.location.replace('/app/forgot-password')
  }

  state = {
    sent: false,
    email: '',
  }

  reset = e => {
    e.preventDefault();

    var that = this;

    Validator.validateForm(items, this.props.forgot, 'forgotForm', function (valid) {
      if (valid) {
        $.ajax({
          method: 'POST',
          url: '/send-reset-link',
          data: {
            email: that.props.forgot.email
          },
          success: function () {
            that.setState({
              sent: true
            });
          }
        });
      }
    });
  }

  updateEmail = (attr, value) => {
    this.setState({
      email: value
    });
  }

  render() {
    var copy = LANGUAGE.register;

    var content;
    if (this.state.sent) {
      content = (
        <div style={{
          paddingBottom: '400px'
        }}>
          <br />
          <h3 style={{
            fontSize: '18px',
            lineHeight: '30px',
            fontFamily: 'Larsseit',
            color: COLOURS.ink,
            display: 'inline'
          }}>
            We've sent you an email with a link to reset your password. If you don't see it, please check your spam folder.
          </h3>
        </div>
      );
    } else {
      content = (
        <div>
          <form onSubmit={ this.reset }
            id="login-form"
            className="form"
            role="form"
          >
            <Field
              type="email"
              validations={ items.email }
              attrName="email"
              dataModel={ this.props.forgot }
              formModel={ this.props.forgotForm }
              label="Email" />

            <PrimaryButton type="submit"
              blueButton={ true }
              styles={[{
                width: '100%'
            }]}>
              Reset Password
            </PrimaryButton>
          </form>
        </div>
      )
    }

    return (
      <div id="content" style={{paddingBottom: '200px'}}>
        <div className="container">
          <div className="col-xs-10 col-xs-offset-1 col-md-6 col-md-offset-3 text-center" style={{marginTop: '80px'}}>
            <div>
              {/* <img src="/images/big-tiger-face.png" style={{width: '100px'}} /> */}
              <img src="/images/big-tiger-face-white.png"
                style={{
                  width: '100px'
              }} />
            </div>
            <br />
            <Text.dojoH2>
              Forgot Password
            </Text.dojoH2>
            <br />
            { content }
            <img src="/images/DojoMojo-Wordmark-Blue.png"
              style={{
                width: '150px',
                marginBottom: '60px',
                marginTop: '50px'
            }} />
          </div>

        </div>
      </div>
    )
  }
}

export default connect(mapState)(ForgotPassword)
