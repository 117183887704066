import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import MessageImageTheme from 'css/themes/media-market/assetVisuals/MessageImage.css'
import { object, func, bool } from 'prop-types'

class SingleImage extends PureComponent {
  static propTypes = {
    asset: object.isRequired,
    downloadAssetFunc: func.isRequired,
    isSender: bool.isRequired,
    history: object.isRequired,
    location: object.isRequired,
  }

  onImageClick = () => {
    const { asset: { id }, history, location } = this.props

    history.push(`${location.pathname}/${id}`)
  }

  render() {
    const {
      asset,
      isSender,
      downloadAssetFunc,
    } = this.props

    const imgSrc = asset.s3_url.startsWith('//') ? `https:${asset.s3_url}` : asset.s3_url

    return (
      <div className={MessageImageTheme.wrapper}>
        {
          isSender
            && (
              <img
                src="images/icons/blue-asset-download-icon.png"
                className={MessageImageTheme.downloadIconLeft}
                onClick={downloadAssetFunc}
              />
            )
        }

        <div
          onClick={this.onImageClick}
          className={MessageImageTheme.imageHolder}
          style={{ backgroundImage: `url(${imgSrc})` }}
        />

        {
          !isSender
            && (
              <img
                src="images/icons/blue-asset-download-icon.png"
                className={MessageImageTheme.downloadIconRight}
                onClick={downloadAssetFunc}
              />
            )
        }
      </div>
    )
  }
}

export default withRouter(SingleImage)
