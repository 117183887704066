import React from 'react'
import injectCSS from 'react-jss'
import PropTypes from 'prop-types'

import { P } from 'visual-components/util/texts'

import Tooltip from 'react-toolbox/lib/tooltip'
import { SMSEditorContext } from '../SMSEditorContext'

import SMSEditorGenericModal from './SMSEditorGenericModal'

const styles = {
  linkable: {
    cursor: 'pointer',
  },
}

function LinkDetectorModal(props) {
  const {
    actions: { onLinkMinifyAndInsert },
  } = React.useContext(SMSEditorContext)

  const {
    show, onClose, originalLink, placement, link, classes: css,
  } = props

  const onConfirm = () => onLinkMinifyAndInsert(link, onClose)

  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    window.open(originalLink)
  }

  const TextLink = () => <strong onClick={onClick}>{originalLink}</strong>

  const TooltipTextLink = Tooltip(TextLink)

  if (originalLink) {
    return (
      <SMSEditorGenericModal
        show={show}
        placement={placement}
        content={(
          <P ellipsis azure>
            <em className={css.linkable}>
              <TextLink />
            </em>
            {/* TODO: Check why tooltip is not working */}
            {/* <TooltipTextLink tooltip={originalLink} /> */}
            {/* <TextLink /> */}
          </P>
        )}
        title="Original Link"
      />
    )
  }

  return (
    <SMSEditorGenericModal
      show={show}
      placement={placement}
      title="Shorten your link and track it?"
      content="Minimize the number of characters and help us to track your conversions."
      confirmLabel="Shorten It"
      cancelLabel="Dismiss"
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  )
}

LinkDetectorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  originalLink: PropTypes.string,
  link: PropTypes.string.isRequired,
  placement: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

LinkDetectorModal.defaultProps = {
  originalLink: null,
  placement: null,
}

export default React.memo(injectCSS(styles)(LinkDetectorModal))
