export const closeReviewModal = () => ({ type: 'CLOSE_MARKET_REVIEW_MODAL' })

export const openReviewModal = brandToReview => ({ type: 'OPEN_MARKET_REVIEW_MODAL', payload: brandToReview })

export const getPendingReviews = model => ({
  http: {
    url: `/ratings/pending/${model}`,
  },
  types: [
    'LOADING_REVIEWS',
    'GET_AVAILABLE_REVIEWS_SUCCESS',
    'GET_AVAILABLE_REVIEWS_FAILURE',
  ],
})

export const submitReview = ({ id, rate, comments }) => ({
  http: {
    url: `/ratings/${id}`,
    method: 'PUT',
    data: {
      rate,
      comments,
    },
  },
  types: [
    'LOADING_REVIEWS',
    'CLOSE_MARKET_REVIEW_MODAL',
    'SUBMIT_REVIEW_FAILURE',
  ],
})

export const rejectReview = id => ({
  http: {
    url: '/ratings/reject',
    method: 'POST',
    data: { id },
  },
  types: [
    'LOADING_REVIEWS',
    'CLOSE_MARKET_REVIEW_MODAL',
    'REJECT_REVIEW_FAILURE',
  ],
})
