import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import Dialog from 'react-toolbox/lib/dialog'

import MediaTabs from './MediaTabs'
import UploadImageSection from './UploadImageSection'
import SearchImageSection from './SearchImageSection'
import SearchGIFSection from './SearchGIFSection'
import ModalButtonGroup from '../../../app/visual-components/util/buttons/ModalButtonGroup'

const styles = {
  wrapper: {
    width: 672,
    height: 654,
    '& > section': {
      flexFlow: 'column',
    },
    '& [class*=buttons]': {
      paddingRight: 32,
    },
  },
}

const TABS = [
  'Upload',
  'Stock Photos',
  'Giphy',
]

function InsertImageGIFModal(props) {
  const {
    onSubmit, open, onClose, classes: css,
  } = props

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedMedia, setSelectedMedia] = useState(null)

  const onTabChange = tab => {
    setSelectedTab(tab)
    setSelectedMedia(null)
  }

  const onInsert = () => {
    onSubmit(selectedMedia)
    setSelectedMedia(null)
  }

  return (
    <Dialog active={open} className={css.wrapper}>
      <MediaTabs tabs={TABS} selectedTab={selectedTab} onChange={onTabChange} onClose={onClose} />
      {
        [
          <UploadImageSection onMediaSelect={setSelectedMedia} />,
          <SearchImageSection onMediaSelect={setSelectedMedia} />,
          <SearchGIFSection onMediaSelect={setSelectedMedia} />,
        ][selectedTab]
      }
      <ModalButtonGroup
        confirmText="Insert"
        confirm={onInsert}
        cancel={onClose}
        canSave={!!selectedMedia}
      />
    </Dialog>
  )
}

InsertImageGIFModal.propTypes = {
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

InsertImageGIFModal.defaultProps = {
  open: false,
  onSubmit() {},
  onClose() {},
}

export default React.memo(injectCSS(styles)(InsertImageGIFModal))
