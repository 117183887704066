import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import Clipboard from 'clipboard'
import { validate as validateEmail } from 'email-validator'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import GetStarted from './get-started'
import BrandReferralStats from './brand-referral-stats'
import GiveAndGet from './giveAndGet'
import YourReferrals from './your-referrals'
import WinFreeGift from './win-free-gift'
import TransactionItem from 'visual-components/brandSettings/transaction-item'
import { H2, H3, H6, P, Micro } from 'visual-components/util/texts'
import getHostName from 'util/get-host-name';

import theme from 'css/themes/referrals/ReferralDashboard.css'
import ImportContacts from './import-contacts'
import SendReminderModal from './send-reminder-modal'

import * as ReferralActions from 'actions/referral-actions'
import * as NotificationActions from 'actions/notification-actions'

const mapStateToProps = ({
  referrals: { contactImport, emailValidation, emailsToInvite, yourReferrals, lastWinnerName, nextDrawingDate, reminderModal },
  currentBrand: { accountname: brandName, id: brandId },
  profile: { credentials, firstName, id: currentUserId },
}) => ({
  contactImport,
  brandName,
  emailValidation,
  emailsToInvite,
  inviteUrl: `${getHostName()}/home/?referral=${credentials.find((cred) => cred.brand.id === brandId).uid}`,
  yourReferrals,
  currentUserId,
  lastWinnerName,
  nextDrawingDate,
  firstName,
  reminderModal
})

const mapDispatchToProps = dispatch => ({
  referralActions: bindActionCreators(ReferralActions, dispatch),
  notificationActions: bindActionCreators(NotificationActions, dispatch),
})

class ReferralDashboard extends Component {
  state = { emails: [] };

  componentDidMount() {
    this.props.referralActions.loadBrandReferrals();
    this.props.referralActions.loadRaffleWinner();
    this.props.notificationActions.readAllReferrals();
    new Clipboard($(ReactDOM.findDOMNode(this)).find('.copy-invite-link')[0]);
    if (window.gapi) window.gapi.load('client');
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.emailValidation.valid && this.props.emailValidation.valid) {
      this.showEmailValidationError(nextProps.emailValidation.message);
    }
  }

  showEmailValidationError(message) {
    toastr.error(message, null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
  }

  handleAddEmail = (email) => {
    if (!validateEmail(email)) {
      this.showEmailValidationError(`${email} is an invalid email`);
      return;
    }
    this.props.referralActions.addEmailToInviteInput(email);
  }

  handleRemoveEmail = (email) => {
    this.props.referralActions.removeEmailFromInviteInput(email);
  }

  startGmailImport = e => {
    e.preventDefault();
    this.props.referralActions.startGmailImport();
  }

  openReminderModal = (referral) => {
    this.props.referralActions.openReminderModal(referral)
  }

  render() {
    const {
      brandName,
      firstName,
      yourReferrals,
      currentUserId,
      lastWinnerName,
      nextDrawingDate,
      reminderModal,
      contactImport,
      referralActions,
    } = this.props

    const brandReferrals = yourReferrals.filter(item => item.status === 'Joined')
    const friendsReferred = brandReferrals.length
    const rewardsEarned = friendsReferred ? brandReferrals.reduce((acc,cur)=> acc + cur.credit, 0)/-100 : 0

    const userReferrals = brandReferrals.filter(item => item.source_user_id === currentUserId);
    const entries = userReferrals.length;

    return (
      <div className={theme.container}
      >
        <Helmet>
          <title>Referral Dashboard</title>
        </Helmet>
        <Grid>
          <Row style={{ marginBottom: '12px' }}
          >
            <Col xs>
              <H2 azure>Referral Dashboard</H2>
            </Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}
          >
            <Col xs>
              <P>
                Track your referrals and rewards here.
                <a
                  href="https://help.dojomojo.com/hc/en-us/articles/360001133571"
                  target="_blank">
                  {` Learn more `}
                </a>
                about how invitations work on DojoMojo.&nbsp;
                <a
                  href="/home/referral-terms-conditions"
                  target="_blank">
                  Terms and conditions apply.
                </a>
              </P>
            </Col>
          </Row>
          <Row>
            <Col style={{
              width:'750px'
            }}>
              <GetStarted />
              <GiveAndGet
                emails={this.props.emailsToInvite}
                handleAddEmail={this.handleAddEmail}
                handleRemoveEmail={this.handleRemoveEmail}
                sendInvites={this.props.referralActions.sendInvites}
                startGmailImport={this.startGmailImport}
                inviteUrl={this.props.inviteUrl}
                copiedNotify={this.copiedNotify}
                brandName={brandName}
              />
              <BrandReferralStats
                accountname={ brandName }
                friendsReferred={ friendsReferred }
                rewardsEarned={ rewardsEarned }
              />
              <YourReferrals
                referrals={ yourReferrals }
                openReminderModal={this.openReminderModal}
              />
            </Col>
            <Col style={{
              width: '226px'
            }}>
              <div style={{
                paddingLeft: '16px'
              }}>
                <WinFreeGift
                  lastWinnerName={lastWinnerName}
                  nextDrawingDate={nextDrawingDate}
                  entries={entries}
                  userName={firstName}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <ImportContacts referralActions={referralActions} {...contactImport} />
        <SendReminderModal
          active = { reminderModal.modalOpen }
          referralActions = { referralActions }
          referral = { reminderModal.referral } />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralDashboard);
