import React, { Component } from 'react'
import { Button } from 'react-toolbox/lib/button'
import { Link, withRouter } from 'react-router-dom'


import buttonTheme from 'css/themes/Buttons.css'
import mobileHeaderTheme from 'css/themes/mobile/header.css'
import DropdownListItem from './mobile-header-dropdown-list-item'

class MobileHeader extends Component {
  state = {
    active: false,
    subNavOpen: [],
  }

  clickHamburger = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  toggleSubNav = item => {
    let navs = this.state.subNavOpen
    navs.indexOf(item) == -1 ? navs.push(item) : (navs = navs.filter(e => e != item))

    this.setState({
      subNavOpen: navs,
    })
  }

  clickTryFree = () => {
    const { history } = this.props
    window.location.href = '/app/register'
  }

  render() {
    const { lightBackground, history } = this.props

    const { active, subNavOpen } = this.state

    const currentMonth = new Date().getMonth()
    const isPrideMonth = currentMonth === 5
    return (
      <div>
        <div className={mobileHeaderTheme.navigation}>
          <div
            onClick={this.clickHamburger}
            className={`${mobileHeaderTheme.hamburgerButton} ${
              active ? mobileHeaderTheme.backgroundWhite : ''
            }`}
          >
            {this.state.active || lightBackground ? (
              <img
                alt="hamburger"
                src="/images/icons/cerulean-hamburger.svg"
                className={mobileHeaderTheme.hamburgerIcon}
              />
            ) : (
              <img
                alt="hamburger"
                src="/images/icons/white-hamburger.svg"
                className={mobileHeaderTheme.hamburgerIcon}
              />
            )}
          </div>
          <div className={mobileHeaderTheme.headerLogo}>
            <Link to="/home">
              {isPrideMonth ? (
                <img
                  alt="logotext"
                  src="/images/nav-bar/pride/mojo-pride-left-aligned.svg"
                  className={mobileHeaderTheme.img100}
                />
              ) : lightBackground ? (
                <img
                  alt="logotext"
                  src="/images/blue-text.svg"
                  className={mobileHeaderTheme.img100}
                />
              ) : (
                <img
                  alt="logotext"
                  src="/images/white-text.svg"
                  className={mobileHeaderTheme.img100}
                />
              )}
            </Link>
          </div>
          <div
            style={{
              display: 'inline-block',
              float: 'right',
            }}
          >
            <Button
              onClick={this.clickTryFree}
              className={`${buttonTheme.greenButton} ${mobileHeaderTheme.rightButton}`}
              primary
              theme={buttonTheme}
              raised
            >
              Start Free Trial
            </Button>
          </div>
        </div>
        {active ? (
          <ul className={mobileHeaderTheme.dropdownContainer}>
            <DropdownListItem
              text="Features"
              action={() => {
                this.toggleSubNav('features')
              }}
              open={subNavOpen.indexOf('features') !== -1}
            >
              <DropdownListItem
                text="Sweepstakes"
                action={() => {
                  history.push('/sweepstakes')
                }}
              />
              <DropdownListItem
                text="SMS Marketing"
                action={() => {
                  history.push('/sms-marketing')
                }}
              />
              <DropdownListItem
                text="Network"
                action={() => {
                  history.push('/network')
                }}
              />
            </DropdownListItem>
            <DropdownListItem
              text="Pricing"
              action={() => {
                window.location = '/app/pricing'
              }}
            />
            <DropdownListItem
              text="About Us"
              action={() => {
                this.toggleSubNav('about us')
              }}
              open={subNavOpen.indexOf('about us') !== -1}
            >
              <DropdownListItem
                text="About Us"
                action={() => {
                  window.location = '/app/about-us'
                }}
              />
              {/* TODO: Disabled as of 11/30/2021 */}
              {/* <DropdownListItem
                text="Team"
                action={() => {
                  window.location = '/app/meet-the-team'
                }}
              /> */}
            </DropdownListItem>
            <DropdownListItem
              text="Resources"
              action={() => {
                history.push('/resources/all')
              }}
            />
            <DropdownListItem
              text="Blog"
              action={() => window.open('http://blog.dojomojo.com/', '_blank')}
            />
            <DropdownListItem
              text="Login"
              action={() => {
                window.location = '/app/login'
              }}
            />
            <li>
              <Button
                onClick={this.clickTryFree}
                className={`${buttonTheme.greenButton} ${mobileHeaderTheme.activeRightButton}`}
                primary
                theme={buttonTheme}
                raised
              >
                Start Free Trial
              </Button>
            </li>
          </ul>
        ) : null}
      </div>
    )
  }
}

export default withRouter(MobileHeader)
