import React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash'

import AdminDispatcher from 'dispatchers/admin-dispatcher';
import * as ReactRedux from 'react-redux';
// AdminDispatcher.loadSweepsDetail(new Date(), 'week');

import SweepsDetail from 'admin/sweeps-detail';
import SummaryDashboard from 'admin/summary-dashboard';
import FeatureDetail from 'admin/feature-detail';
import UserDetail from 'admin/user-detail';
import HouseBrands from 'admin/house-brands';
import Spinner from 'util/components/spinner';
import PrimaryButton from 'util/components/buttons/primary-button';
import PeriodPicker from 'admin/period-picker';
import AdminRow from './admin-row';
import OkrMetrics from 'admin/okr-metrics';
import NetworkGraph from 'admin/network-graph';


const ViewMap = {
  sweeps: SweepsDetail,
  summary: SummaryDashboard,
  feature: FeatureDetail,
  user: UserDetail,
  house: HouseBrands,
  okr: OkrMetrics,
  network: NetworkGraph
};


class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.onLoadClick = this.onLoadClick.bind(this)
    this.state = {
      view: ViewMap[this.props.admin.section]
    };
    this.onIntervalChange = this.onIntervalChange.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);

    $.getScript('//d3js.org/d3.v4.min.js', function(){
    });
  }

  onDateSelect(selected_date) {
    this.setState({
      selectedDate: moment(selected_date.toString())
    });
//    AdminDispatcher.load(this.props.params.splat, selected_date.toString(), this.props.interval);
  }

  onIntervalChange(e) {
    this.setState({
      interval: e.target.value
    });
 //   AdminDispatcher.load(this.props.params.splat, this.props.admin.endDate.toString(), e.target.value);
  }

  componentWillReceiveProps(props) {
    this.setState({
      view: ViewMap[props.admin.section]
    });
  }

  onLoadClick() {
    AdminDispatcher.load(_.merge({}, this.props.admin, { interval: this.state.interval, endDate: this.state.selectedDate }));
  }

  export() {
    var $table = $('table');
    var csv = $table.table2CSV({
        delivery: 'value'
    });
    window.location.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv);
  }

  render() {
    if (this.props.admin.loading) {
      return (
        <Spinner />
      );
    }
    var dates = [];

    const currentView = this.props.admin.loaded ? <this.state.view admin={this.props.admin} dates={this.props.admin.presentation.dates} /> : null;
    return (
      <div className="container" style={{ width: '100%', marginTop: '100px' }}>
        <div className="row">
          <div className="col col-med-12 col-sm-12 col-lg-12">
            <PeriodPicker admin={this.props.admin} />
            <PrimaryButton onClick={this.onLoadClick}>LOAD</PrimaryButton>
            { this.props.admin.loaded ? <PrimaryButton styles={[{marginLeft: '15px'}]} onClick={this.export}>Export</PrimaryButton> : null }
          </div>
        </div>
        { currentView }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
}

export default ReactRedux.connect(mapStateToProps)(Admin);
