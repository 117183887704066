import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { H3 } from 'visual-components/util/texts';

import SocialDistributionGraph from 'visual-components/analytics/campaign/social-distribution-graph';
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option';
import Dropdown from 'react-toolbox/lib/dropdown'
import EmptyState from 'visual-components/util/graphs/empty-state';
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';

import DropdownFilterTheme from 'css/themes/dropDownAnalytics.css'

class HostHeader extends React.Component {
  customItem = item => {
    /* this breaks out of the normal loop, allowing for customization of the chosen value */
    return (
      <div className={ DropdownFilterTheme.customItem }>
        { item.label == this.props.selectedBrand && item.label.length > 15 ? item.label.substring(0, 15) + '...' : item.label }
      </div>
    )
  }

  render() {
    var {
      options,
      value,
      onChange,
      isSolo,
    } = this.props;

    return (
      <div style={{
        marginBottom: '-10px'
      }}>
        <div style={{
          display: 'inline-block'
        }}>
          <H3 coral>
            <small>
              { `Social Actions${!isSolo ? ' - ' : ''}` }
            </small>
          </H3>
        </div>
        {!isSolo && (
          <div style={{
            display: 'inline-block',
            marginLeft: '-12px'
          }}>
            <Dropdown
              auto
              onChange={ onChange }
              source={ options }
              value={ value }
              theme={ DropdownFilterTheme }
              template={ this.customItem }
            />
          </div>
        )}
        <div style={{
          borderRadius: '100%',
          width: '20px',
          height: '20px',
          textAlign: 'center',
          display: 'inline-block',
          marginLeft: '8px'
        }}>
          <BlueInfoTooltip text={ 'A breakdown of social actions driven to your brand' }/>
        </div>
      </div>
    )
  }
}

class SocialDistributionCard extends React.Component {
  render() {
    const {
      socialItems,
      toggleItem,
      hideMap,
    } = this.props;

    var brandOptions = socialItems ? socialItems.map(function(item) {
      return (
        <ChartOption { ...item }
          onClick={ toggleItem }
          selected={ !hideMap[item.name] }
        />
      )
    }) : [];

    let hasData = socialItems && socialItems.some((el, idx, arr) => {
      return el.data.some(num => num > 0);
    });

    return (
      <Card style={{
        width: '646px',
        height: '480px',
        overflow: 'visible'
      }}>
        <div style={{
          padding: '20px 24px 8px 24px'
        }}>
          <HostHeader { ...this.props } />
          {
            hasData ?
            <div style={{
              paddingTop: '30px'
            }}>
              <SocialDistributionGraph { ...this.props } />
              <div style={{paddingLeft: '22px', marginTop: '12px'}}>
                { brandOptions }
              </div>
            </div>
            :
            <EmptyState text={ 'Social Actions is a powerful feature reserved for Essential and Premium DojoMojo users.' }
              learnMore={ 'https://help.dojomojo.com/hc/en-us/articles/230426167-Add-Partner-Social-Actions-' }
              height={ '380px' }
              bigCard
              bigText
            />
          }
        </div>
      </Card>
    );
  }
}

export default SocialDistributionCard;
