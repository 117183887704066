import React from 'react'
import { H6 } from 'visual-components/util/texts'

class HintText extends React.Component {
  render() {
    const {
      text,
      edit
    } = this.props

    return (
      <div onClick={ edit }>
        <H6 azure>
          { text }
        </H6>
      </div>
    )
  }
}

export default HintText
