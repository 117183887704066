import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import injectSheet from 'react-jss'
import { object, func, bool } from 'prop-types'
import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import RadioTheme from 'css/themes/Radio.css'
import UploadImageModal from 'components/campaigns/builder/lander/imagery/upload-image-modal'
import SearchImageModal from 'components/campaigns/builder/lander/imagery/search/search-image-modal'
import { Small, H6 } from 'visual-components/util/texts'
import * as BackgroundImageActions from 'actions/background-image-actions'
import COLOURS from 'util/colours'

const mapStateToProps = ({ backgroundImages }) =>
  ({ backgroundImages })

const mapDispatchToProps = dispatch => ({
  backgroundImageActions: bindActionCreators(BackgroundImageActions, dispatch),
})

const styles = {
  container: {
    marginTop: '16px',
  },
  box: {
    display: 'inline-block',
    width: 128,
    height: 128,
    border: `1px solid ${COLOURS.silver}`,
    padding: '30px 0',
    textAlign: 'center',
    cursor: 'pointer',
    '&:first-child': {
      marginRight: 16,
    },
    '& img': {
      marginBottom: 24,
    },
  },
  imageContainer: {
    width: 272,
    height: 128,
    marginTop: 8,
    textAlign: 'center',
    backgroundColor: COLOURS.silver,
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
    },
    '& video': {
      objectFit: 'cover',
      width: 272,
      height: 128,
    },
  },
  videoContainer: {
    width: 272,
    height: 128,
    position: 'relative',
    overflow: 'hidden',
  },
  portrait: {
    height: 128,
  },
  landscape: {
    minWidth: 272,
    height: 128,
  },
  action: {
    width: 272,
    padding: '14px 16px',
    border: `1px solid ${COLOURS.silver}`,
    cursor: 'pointer',
  },
  radioGroup: {
    marginTop: '16px',
  },
  radioButton: {
    display: 'inline-block',
    marginRight: '40px',
  },
}

class SelectBackground extends React.Component {
  static propTypes = {
    elements: object.isRequired,
    backgroundImages: object.isRequired,
    backgroundImageActions: object.isRequired,
    isVideoTemplate: bool,
    updateAttr: func.isRequired,
    classes: object.isRequired,
  }

  static defaultProps = {
    isVideoTemplate: false,
  }

  state = {
    backgroundType: 'Image',
    forceSelect: false,
    modalOpen: null,
  }

  componentDidMount() {
    const { elements, backgroundImageActions } = this.props
    if (elements.mainImage.endsWith('.mp4')) {
      this.setState({ backgroundType: 'Video' })
    }
    backgroundImageActions.fetch()
  }

  selectBackgroundType = backgroundType => {
    this.setState({ backgroundType })
  }

  searchBackground = () => {
    const { backgroundImageActions: { updateAttr } } = this.props
    updateAttr({ selectedImage: null })
    this.setState({ modalOpen: 'search' })
  }

  uploadBackground = () => {
    const { backgroundImageActions: { updateAttr } } = this.props
    updateAttr({ selectedImage: null })
    this.setState({ modalOpen: 'upload' })
  }

  changeImage = hide => {
    this.setState({ forceSelect: !(hide === true) })
  }

  play = () => {
    const myVideo = document.getElementById('video-preview')
    myVideo.play()
  }

  pause = () => {
    const myVideo = document.getElementById('video-preview')
    myVideo.pause()
  }

  closeModal = () => {
    this.setState({ modalOpen: null })
  }

  renderContent = () => {
    const {
      elements,
      backgroundImages: { images = [], recentStockImages = [] },
      classes: css,
    } = this.props

    const { backgroundType, forceSelect } = this.state

    const isMainVideo = elements.mainImage.endsWith('.mp4')
    const allImages = images.concat(recentStockImages)
    const image = allImages.find(i => i.src === elements.mainImage)
    const portrait = image && image.metadata && (image.metadata.height > image.metadata.width
      || image.metadata.height * 1.1 > image.metadata.width)

    if (elements.default_background || forceSelect || (isMainVideo && backgroundType !== 'Video') || (!isMainVideo && backgroundType !== 'Image')) {
      return (
        <div className={css.container}>
          <div className={css.box} onClick={this.searchBackground}>
            <img src="/images/builder/icons/search-small.svg" />
            <Small azure>
              <b>{ `Search ${backgroundType}s` }</b>
            </Small>
          </div>
          <div className={css.box} onClick={this.uploadBackground}>
            <img src="/images/builder/icons/upload-image.svg" />
            <Small azure>
              <b>{ `Upload ${backgroundType}` }</b>
            </Small>
          </div>
        </div>
      )
    }

    return (
      <div className={css.container}>
        <div className={css.imageContainer}>
          { isMainVideo ? (
            <div
              className={css.videoContainer}
              onMouseOver={this.play}
              onMouseOut={this.pause}
            >
              <video muted className={css.video} id="video-preview" key={`video${image && image.id}`}>
                <source src={elements.mainImage} type="video/mp4" />
              </video>
            </div>
          ) : (
            <img src={elements.mainImage} className={portrait ? css.portrait : css.landscape} />
          )}
        </div>
        <div className={css.action} onClick={this.changeImage}>
          <Small azure>
            <b>{`Change ${backgroundType}`}</b>
          </Small>
        </div>
      </div>
    )
  }

  render() {
    const {
      elements,
      isVideoTemplate,
      updateAttr,
      classes: css,
    } = this.props

    const { backgroundType, modalOpen } = this.state

    return (
      <div>
        <H6>
          Media
        </H6>

        { isVideoTemplate ? (
          <RadioGroup
            name="backgroundType"
            value={backgroundType}
            onChange={this.selectBackgroundType}
            className={css.radioGroup}
          >
            <RadioButton
              key="Image"
              label="Image"
              value="Image"
              theme={RadioTheme}
              className={css.radioButton}
            />
            <RadioButton
              key="Video"
              label="Video"
              value="Video"
              theme={RadioTheme}
              className={css.radioButton}
            />
          </RadioGroup>
        ) : null }

        <div>
          {this.renderContent()}
        </div>

        <UploadImageModal
          active={modalOpen === 'upload'}
          elements={elements}
          backgroundType={backgroundType}
          updateAttr={updateAttr}
          changeImage={this.changeImage}
          closeModal={this.closeModal}
        />

        <SearchImageModal
          active={modalOpen === 'search'}
          elements={elements}
          backgroundType={backgroundType}
          updateAttr={updateAttr}
          changeImage={this.changeImage}
          closeModal={this.closeModal}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(SelectBackground))
