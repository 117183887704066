import React, { PureComponent } from 'react'

import { H1, H4 } from 'visual-components/util/texts'

import Theme from 'css/themes/roiCalculatorTheme.css'

const createSrcSet = src => {
  const srcs = ['', '@2x', '@3x']

  return srcs.map(size => `${src.split('.').join(`${size}.`)} ${size.replace('@', '')}`).join(',')
}

class ROIHeader extends PureComponent {
  render() {
    const imgSrc = '/images/roi-calculator/roi-banner-asset-4.png'

    return (
      <div className={Theme.header}>
        <div className={Theme.innerHeader}>
          <div className={Theme.headerText}>
            <div style={{ width: '400px' }}>
              <div style={{ margin: '72px 0 28px' }}>
                <H1 extralarge>
                  ROI Calculator
                </H1>
              </div>
              <H4 multiline>
                ROI is the key aspect of determining the value of your marketing spend, but it is often challenging to calculate accurately. We&apos;ve made this calculator to help you unlock insights about your ROI.
              </H4>
            </div>
          </div>
          <div className={Theme.headerBanner}>
            <img style={{ height: '304px', marginRight: '112px' }} src={imgSrc} srcSet={createSrcSet(imgSrc)} />
          </div>
        </div>
      </div>
    )
  }
}

export default ROIHeader
