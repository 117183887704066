import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { H3 } from 'visual-components/util/texts'
import Dropdown from 'react-toolbox/lib/dropdown'
import InputTheme from 'css/themes/Input.css'
import DropDownTheme from 'css/themes/formdropdown.css'
import ButtonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import { Input } from 'react-toolbox/lib/input'
import cn from 'classnames'
import requestHeaders from 'request-config'
import axios from 'axios'
import SeedSms from './seed-sms'
import SetMessageRevenue from './set-message-revenue'

const THEMES = {
  input: InputTheme,
  dropdown: DropDownTheme,
  button: ButtonTheme,
}

const cronOptions = [
  { value: 'smsSubscriberAnalyticsUpdated', label: 'Subscriber Analytics Updated (Automations)' },
  { value: 'getAllAbandonedCarts', label: 'Get All Abandoned Carts' },
  { value: 'handleTrials', label: 'Trigger Trial Emails (Requires Trial End Date change)' },
  { value: 'brandRecommendations', label: 'Refresh Brand Recommendations' },
  { value: 'campaignRecommendations', label: 'Refresh Sweepstakes Recommendations' },
  { value: 'brandRatings', label: 'Refresh Brand Ratings' },
  { value: 'refreshAllESCampaignAnalytics', label: 'Refresh Campaign Analytics' },
  { value: 'smsScheduleSend', label: 'Trigger Scheduled SMS Campaigns' },
  { value: 'smsScheduleMessage', label: 'Trigger SMS Messages' },
  { value: 'smsProcessFollowups', label: 'Trigger SMS Followups' },
  { value: 'downgradeBrands', label: 'Trigger Brand Downgrades' },
  { value: 'convertTrialPlans', label: 'Trigger Plan Upgrade' },
  { value: 'convertPostTrialPlans', label: 'Trigger Post Trial Plan Downgrades (Moves to Basic)' },
].sort((a, b) => a.label.localeCompare(b.label))

const styles = {
  container: {
    marginLeft: 35,
  },
  card: {
    marginTop: '50px',
    padding: '15px',
    boxShadow: '0px 1px 4px #c0c0c0',
    width: 700,
  },
  cardRow: {
    marginTop: 15,
    display: 'flex',
    '& > *': {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0,
      },
    },
    '& button': {
      flexShrink: 0,
    },
  },
  subscriberIdInput: {
    width: 250,
  },
  noPadding: {
    padding: 0,
  },
}

const QACronjobTrigger = function (props) {
  const {
    classes: css,
  } = props

  const [loading, setLoading] = useState(false)

  const [cronToRun, setCronToRun] = useState(null)

  const [selectedSubscriberId, setSelectedSubscriberId] = useState('')
  const [subscriberLastOrderAt, setSubscriberLastOrderAt] = useState('')
  const [subscriberTotalOrderValue, setSubscriberTotalOrderValue] = useState('')
  const [subscriberOrderCount, setSubscriberOrderCount] = useState('')

  const onRunCron = async () => {
    setLoading(true)
    try {
      await axios.post('/qa/run-cron/', { cron: cronToRun }, { headers: requestHeaders() })
    } catch (e) {
      console.log(e)
      toastr.error(e.response.data || e.response.statusText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
    }
    setLoading(false)
  }

  const onUpdateSubscriberMetric = async () => {
    setLoading(true)
    const postBody = {
      lastOrderAt: subscriberLastOrderAt,
      totalOrderValue: subscriberTotalOrderValue,
      orderCount: subscriberOrderCount,
    }
    try {
      await axios.put(`/qa/sms-subscriber-analytics/${selectedSubscriberId}`, postBody, { headers: requestHeaders() })
      setSelectedSubscriberId('')
      setSubscriberLastOrderAt('')
      setSubscriberTotalOrderValue('')
      setSubscriberOrderCount('')
    } catch (e) {
      console.log(e)
      toastr.error(e.response.data || e.response.statusText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
    }
    setLoading(false)
  }

  const onSeedSmsClick = async postBody => {
    setLoading(true)
    const smsSendId = postBody.smsCampaignId
    delete postBody.smsCampaignId
    try {
      await axios.post(`/qa/sms-messages/${smsSendId}/seed`, postBody, { headers: requestHeaders() })
    } catch (e) {
      console.log(e)
      toastr.error(e.response.data || e.response.statusText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
    }
    setLoading(false)
  }

  const onSetMessageRevenueClick = async postBody => {
    setLoading(true)
    const { messageId } = postBody
    delete postBody.messageId
    try {
      await axios.put(`/qa/sms-message-revenues/${messageId}`, postBody, { headers: requestHeaders() })
    } catch (e) {
      console.log(e)
      toastr.error(e.response.data || e.response.statusText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
    }
    setLoading(false)
  }

  // TODO when this component gets too big move render functions
  // into their own components
  const renderRunCron = () => (
    <div className={css.card}>
      <H3>Run cron</H3>
      <div className={css.cardRow}>
        <Dropdown
          onChange={setCronToRun}
          source={cronOptions}
          value={cronToRun}
          theme={THEMES.dropdown}
        />
        <Button
          onClick={onRunCron}
          theme={THEMES.button}
          className={THEMES.button.blueButton}
          primary
          raised
          disabled={loading || !cronToRun}
          label="Run cron"
        />
      </div>
    </div>
  )

  const renderUpdateSubscriberAnalytics = () => (
    <div className={css.card}>
      <H3>Update subscriber analytics</H3>
      <div className={css.cardRow}>
        <Input
          value={selectedSubscriberId}
          theme={THEMES.input}
          className={cn(css.noPadding, css.subscriberIdInput)}
          onChange={setSelectedSubscriberId}
          placeholder="Subscriber ID"
        />
      </div>
      <div className={css.cardRow}>
        <Input
          value={subscriberLastOrderAt}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setSubscriberLastOrderAt}
          placeholder="Last order placed at"
          type="date"
        />
        <Input
          value={subscriberTotalOrderValue}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setSubscriberTotalOrderValue}
          placeholder="Total amount spent"
        />
        <Input
          value={subscriberOrderCount}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setSubscriberOrderCount}
          placeholder="Number of orders"
        />
      </div>
      <div className={css.cardRow}>
        <Button
          onClick={onUpdateSubscriberMetric}
          theme={THEMES.button}
          className={THEMES.button.blueButton}
          primary
          raised
          disabled={
            loading
            || !selectedSubscriberId
            || !subscriberLastOrderAt
            || !subscriberTotalOrderValue
            || !subscriberOrderCount
          }
          label="Update Analytics"
        />
      </div>
    </div>
  )

  const renderSeedSMS = () => (
    <div className={css.card}>
      <H3>Seed sms message</H3>
      <SeedSms
        cardRowStyle={css.cardRow}
        isLoading={loading}
        onSubmit={onSeedSmsClick}
      />
    </div>
  )

  const renderSetMessageRevenue = () => (
    <div className={css.card}>
      <H3>Set message revenue</H3>
      <SetMessageRevenue
        cardRowStyle={css.cardRow}
        isLoading={loading}
        onSubmit={onSetMessageRevenueClick}
      />
    </div>
  )

  return (
    <div className={css.container}>
      {renderRunCron()}
      {renderUpdateSubscriberAnalytics()}
      {renderSeedSMS()}
      {renderSetMessageRevenue()}
    </div>
  )
}

QACronjobTrigger.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(QACronjobTrigger)
