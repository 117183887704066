import moment from 'moment-timezone'
import { remove, filter } from 'lodash'
import _ from 'lodash'

const model = 'brandCampaigns'

const defaultState = {
  rid: model,
  campaigns: [],
  pastCampaigns: [],
  currentCampaigns: [],
  upcomingCampaigns: [],
  needTermsCampaigns: [],
  loaded: false,
  allCampaigns: [],
}

const partnerDefaults = {
  partner_id: '',
  partnership_invite_id: '',
  partnership_agreement_type: '',
  custom_file_name: '',
  impressions: 0,
  entries: 0,
  visits: 0,
  newEmails: 0,
  conversions: 0,
  postentries: 0,
}

function updateItemInArray(array, newData, id) {
  const foundIndex = array.findIndex(item => item.id === id)
  if (foundIndex === -1) {
    return array
  }
  return array.map((item, idx) => (idx === foundIndex ? { ...item, ...newData } : item))
}

const groupCampaignsByDate = campaigns => ({
  pastCampaigns: campaigns.filter(campaign => campaign.endDate < new Date()),
  currentCampaigns: campaigns.filter(campaign => campaign.startDate < new Date() && campaign.endDate > new Date() && campaign.status === 'accepted'),
  upcomingCampaigns: campaigns.filter(campaign => campaign.startDate > new Date() && campaign.status === 'accepted'),
})

const BrandCampaignsReducer = function (state, action) {
  if (action.model !== model && action.type !== 'SUBMIT_APPLICATION_SUCCESS') {
    return state || defaultState
  }
  const actions = {
    SET_VISIBLE_CAMPAIGNS: () => ({
      ...state,
      ...groupCampaignsByDate(action.data),
    }),
    LOAD_CAMPAIGNS: () => {
      const {
        campaigns,
        allPartnerDetails,
        newEmails,
        postEntries,
        entriesVisits,
        promotionalDefaults,
        tasks,
      } = action.data
      const partnerDetails = allPartnerDetails.filter(details => details.status === 'accepted' || 
        (details.status === 'interested' && details.partnership_agreement_status)
      )
      const pendingInvites = remove(campaigns, { status: 'pending' })
      const acceptedInvites = filter(campaigns, { status: 'accepted' })
      const rejectedInvites = remove(campaigns, { status: 'rejected' })

      const extendPartnerInfo = function (partners, id, data) {
        if (id) {
          if (partners[id]) {
            partners[id] = { ...partners[id], ...data }
          } else {
            partners[id] = {
              partner_id: '',
              partnership_invite_id: '',
              partnership_agreement_type: '',
              custom_file_name: '',
              accountname: '',
              logo: '',
              entries: 0,
              visits: 0,
              impressions: 0,
              newEmails: 0,
              postEntries: 0,
              conversions: 0,
              minimumDelivery: 0,
              ...data,
            }
          }
        }
      }


      const partners = {}
      const campaignsHash = {}

      for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i]
        campaign.endDate = campaign.endDate ? new Date(campaign.endDate) : null
        campaign.startDate = campaign.startDate ? new Date(campaign.startDate) : null
        campaign.started = new Date(campaign.startDate) < new Date()
        campaign.hostBrand = {}
        campaign.partners = {}
        campaign.partnerImages = []
        campaign.impressions = 0
        campaign.postentries = 0
        campaign.conversions = 0
        campaign.entries = 0
        campaign.visits = 0
        campaign.newEmails = 0
        campaign.estimatedEntries = 0
        campaign.previewLink = `/landing/campaign/${campaign.id}?preview=true`
        campaign.allPartners = allPartnerDetails.filter(details => details.campaign_id === campaign.id)

        const campaignTasks = tasks.find(task => task.campaign_id === campaign.id) || {}
        campaign.totalTasks = campaignTasks.total_tasks || 0
        campaign.completedTasks = campaignTasks.completed_tasks || 0

        var promo = _.filter(promotionalDefaults, { campaign_id: campaign.id })[0];
        if (promo) {
          campaign.previewLink = '/landing/campaign/' + campaign.id + '?promo_id=' + promo.uid + '&campaign_id=' + campaign.id + '&preview=true';
        }
        if (process.env.SENTRY_ENV === 'production') {
          campaign.previewLink = `https://do-not-use-this-link.dojomojo.com${campaign.previewLink}`
        }

        campaignsHash[campaign.id] = campaign;

        extendPartnerInfo(campaign.partners, campaign.invitee_id, {
          minimumDelivery: campaign.minimumDelivery,
          successPageProportion: campaign.successPageProportion
        })
      }

      _.each(partnerDetails, function (item) {
        if (!campaignsHash[item.campaign_id]) {
          return;
        }

        extendPartnerInfo(campaignsHash[item.campaign_id].partners, item.partner_id, {
          partner_id: item.partner_id,
          partnership_invite_id: item.partnership_invite_id,
          partnership_agreement_type: item.partnership_agreement_type,
          custom_file_name: item.custom_file_name,
          accountname: item.accountname,
          logo: item.logo,
          impressions: item.impressions,
          dedicatedListSize: item.dedicatedListSize,
          averageSignUpsPerCampaign: item.averageSignUpsPerCampaign,
          minimumDelivery: item.minimumDelivery
        });

        partners[item.partner_id] = partners[item.partner_id] || _.extend({}, partnerDefaults);
        partners[item.partner_id].impressions += item.impressions
        partners[item.partner_id].accountname = item.accountname;
        partners[item.partner_id].logo = item.logo;
        partners[item.partner_id].partner_id = item.partner_id;
        partners[item.partner_id].partnership_invite_id = item.partnership_invite_id;
        partners[item.partner_id].partnership_agreement_type = item.partnership_agreement_type;
        partners[item.partner_id].custom_file_name = item.custom_file_name;

        if (campaignsHash[item.campaign_id]) {
          campaignsHash[item.campaign_id].impressions += parseInt(item.impressions);
          campaignsHash[item.campaign_id].estimatedEntries += parseInt(item.averageSignUpsPerCampaign ? item.averageSignUpsPerCampaign : parseInt(item.dedicatedListSize * 0.01));

          if (campaignsHash[item.campaign_id].hostBrandId == item.partner_id) {
            campaignsHash[item.campaign_id].hostBrand = partners[item.partner_id];
          } else {
            campaignsHash[item.campaign_id].partnerImages.push(item.logo);
          }
        }
      });

      _.each(newEmails, function (item) {
        if (!campaignsHash[item.campaign_id]) {
          return;
        }

        var val = parseInt(item.count);
        partners[item.source_brand_id] = partners[item.source_brand_id] || _.extend({}, partnerDefaults);
        partners[item.source_brand_id].newEmails += val;

        if (campaignsHash[item.campaign_id]) {
          campaignsHash[item.campaign_id].newEmails += val;
          extendPartnerInfo(campaignsHash[item.campaign_id].partners, item.source_brand_id, {
            newEmails: val
          });
        }
      });


      _.each(postEntries, function (item) {
        if (!campaignsHash[item.campaign_id]) {
          return;
        }

        var val = parseInt(item.totalpostentryactions);
        partners[item.brand_id] = partners[item.brand_id] || _.extend({}, partnerDefaults);
        partners[item.brand_id].postentries += val;

        if (campaignsHash[item.campaign_id]) {
          campaignsHash[item.campaign_id].postentries += val;
          extendPartnerInfo(campaignsHash[item.campaign_id].partners, item.brand_id, {
            postEntries: val
          });
        }
      });

      _.each(entriesVisits, function (item) {
        var totalvisits = parseInt(item.totalvisits);
        var totalentries = parseInt(item.totalentries);
        partners[item.brand_id] = partners[item.brand_id] || _.extend({}, partnerDefaults);
        partners[item.brand_id].visits += totalvisits;
        partners[item.brand_id].entries += totalentries;

        if (campaignsHash[item.campaign_id]) {
          campaignsHash[item.campaign_id].entries += totalentries;
          campaignsHash[item.campaign_id].visits += totalvisits;
          extendPartnerInfo(campaignsHash[item.campaign_id].partners, item.brand_id, {
            entries: totalentries,
            visits: totalvisits
          });
        }
      });

      var currentBrandId = store.getState().currentBrand.id;

      const { pastCampaigns, currentCampaigns, upcomingCampaigns } = groupCampaignsByDate(campaigns)

      const inDiscussionCampaigns = campaigns.filter((campaign) => {
        return campaign.status === 'interested'
      })

      var needTermsCampaigns = _.filter(upcomingCampaigns, function (campaign) {
        return campaign.startDate < moment().add(5, 'days')._d && campaign.hostBrandId === currentBrandId && campaign.termsStatus !== 'published';
      })

      return _.extend({}, state, {
        promotionalDefaults: promotionalDefaults,
        partners: partners,
        campaignsHash: campaignsHash,
        pastCampaigns: pastCampaigns,
        currentCampaigns: currentCampaigns,
        upcomingCampaigns: upcomingCampaigns,
        inDiscussionCampaigns: inDiscussionCampaigns,
        campaigns: campaigns,
        needTermsCampaigns: needTermsCampaigns,
        invites: _.clone(acceptedInvites).concat(rejectedInvites).concat(pendingInvites),
        allCampaigns: state.allCampaigns && state.allCampaigns.length > 0 ? state.allCampaigns : campaigns,
        loaded: true
      });
    },
    ADD_INVITE: function () {
      var upcomingCampaigns = state.upcomingCampaigns;
      var campaign = _.find(upcomingCampaigns, { id: action.data.campaign_id });
      campaign.allPartners.push(action.data);

      return _.extend({}, state, {
        upcomingCampaigns: upcomingCampaigns
      });
    },
    LOAD_CONVERSIONS: function () {

    },
    REMOVE_CAMPAIGN: function () {
      let updatedPastCampaigns = _.filter(state.pastCampaigns, (campaign) => campaign.id != action.data);
      let updatedCurrentCampaigns = _.filter(state.currentCampaigns, (campaign) => campaign.id != action.data);
      let updatedUpcomingCampaigns = _.filter(state.upcomingCampaigns, (campaign) => campaign.id != action.data);
      let updatedCampaigns = _.filter(state.campaigns, (campaign) => campaign.id != action.data);
      let updatedCampaignsHash = _.extend({}, state.campaignsHash);
      delete updatedCampaignsHash[action.data]

      return _.extend({}, state, {
        campaignsHash: updatedCampaignsHash,
        pastCampaigns: updatedPastCampaigns,
        currentCampaigns: updatedCurrentCampaigns,
        upcomingCampaigns: updatedUpcomingCampaigns,
        campaigns: updatedCampaigns
      })
    },
    UPDATE_CAMPAIGN: function() {
      let updatedCampaigns = updateItemInArray(state.campaigns, action.data.attr, action.data.id);
      let updatedPastCampaigns = updateItemInArray(state.pastCampaigns, action.data.attr, action.data.id);
      let updatedCurrentCampaigns =updateItemInArray(state.currentCampaigns, action.data.attr, action.data.id);
      let updatedUpcomingCampaigns = updateItemInArray(state.upcomingCampaigns, action.data.attr, action.data.id);
      state.campaignsHash[action.data.id] = _.extend({}, state.campaignsHash[action.data.id], action.data.attr)

      return _.extend({}, state, {
        pastCampaigns: updatedPastCampaigns,
        currentCampaigns: updatedCurrentCampaigns,
        upcomingCampaigns: updatedUpcomingCampaigns,
        campaigns: updatedCampaigns
      })
    },
    ADD_APPLICATION: function () {
      var invites = _.clone(state.invites);
      invites.push({
        status: action.data.status,
        id: action.data.campaign_id
      });
      return _.extend({}, state, {
        invites: invites
      });
    },
    HIDE_NEED_TERMS: function () {
      var needTermsCampaigns = _.clone(state.needTermsCampaigns);

      _.remove(needTermsCampaigns, { id: action.data.id })

      return _.extend({}, state, {
        needTermsCampaigns: needTermsCampaigns
      });
    },
    SUBMIT_APPLICATION_SUCCESS: function () {
      var invites = _.clone(state.invites);
      invites.push({
        status: action.payload.invite.status,
        id: action.payload.invite.campaign_id
      });
      return _.extend({}, state, {
        invites: invites
      });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default BrandCampaignsReducer;
