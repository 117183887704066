import React, { PureComponent as Component } from 'react'
import { instanceOf } from 'prop-types'
import moment from 'moment-timezone'
import CalendarTheme from 'css/themes/calendar/calendar.css'

import cn from 'classnames'

class DateHeader extends React.Component {
  static contextTypes = {
    date: instanceOf(Date).isRequired,
  }

  render() {
    const {
      dateItem,
    } = this.props

    const isOffRange = dateItem < new Date()
    const label = moment(dateItem).format('D')
    const isFirstOfMonth = dateItem.getDate() === 1

    const isToday = dateItem.getMonth() === new Date().getMonth()
      && dateItem.getDate() === new Date().getDate()
      && dateItem.getYear() === new Date().getYear()

    const className = cn(
      CalendarTheme.calendarDateCell,
      isToday && CalendarTheme.calendarNow
    )

    return (
      <div
        className={cn(
          className,
          isOffRange && CalendarTheme.calendarOffRange,
          !isOffRange && CalendarTheme.calendarCurrent
        )}
      >
        <div
          className={cn(
            CalendarTheme.dateHeader,
            isFirstOfMonth && CalendarTheme.isFirstOfMonth
          )}
        >
          <div className={CalendarTheme.dateHeaderMonth}>
            {moment(dateItem).format('MMM')}
          </div>
          <div className={CalendarTheme.dateHeaderDay}>{label}</div>
        </div>
      </div>
    )
  }
}

DateHeader.propTypes = {
  dateItem: instanceOf(Date),
}

export default DateHeader
