import React from 'react';

class CtaImage extends React.Component {
  render() {
    const {
      cta
    } = this.props;

    if (cta.ctaUrl && !cta.ctaUrl.match(/^(?:http(?:s)?\:\/\/)/)) {
      cta.ctaUrl = `http://${cta.ctaUrl}`;
    }

    return (
      <div className="text-center">
        <a className="cta-link" href={cta.ctaUrl} target="_blank">
          <img style={{width: '100%'}} src={cta.ctaImage} />
        </a>
      </div>
    );
  }
};

export default CtaImage;
