import React from 'react'
import { array, bool } from 'prop-types'
import { MenuItem, IconMenu, MenuDivider } from 'react-toolbox/lib/menu'
import { P, H6 } from 'visual-components/util/texts'
import OverveiwTheme from 'css/themes/Overview.css'
import NavBarDropdownTheme from 'css/themes/nav-bar-dropdown.css'

class PartnerListActions extends React.Component {
  static propTypes = {
    active: bool,
    actions: array,
  }

  static defaultProps = {
    active: false,
    actions: [],
  }

  render() {
    const { active, actions, typeToShow } = this.props

    const newActions = actions.slice()

    if (actions.length > 2 && actions.find(action => action.label === 'Report')) {
      newActions.splice(actions.length - 1, 0, 'divider')
    }

    if (!newActions.length) return null

    // non-dropdown format for actions
    if (['pendingInvite', 'pendingApplication', 'rejected'].includes(typeToShow)) {
      return (
        <div style={{ display: 'flex' }}>
          { 
            actions.map((item, indx) => {
              const style = {
                cursor: 'pointer',
                marginRight: indx === 0 ? '41px' : null,
              }
              return (
                <div style={style} onClick={item.onClick}>
                  <H6 azure>{item.label}</H6>
                </div>
              )
            })
          }
        </div>
      )
    }


    return (
      <div className="text-right" style={{ marginTop: '-15px' }}>
        <div
          className={NavBarDropdownTheme.overviewMenuHolder}
          style={{
            display: 'inline-block',
            marginLeft: '12px',
          }}
        >
          <IconMenu
            position="auto"
            icon={(
              <div className={OverveiwTheme.actionsToggle}>
                <P>Actions</P>
                <img src="/images/icons/expand-arrow.svg" />
              </div>
            )}
            active={active}
            onHide={() => {}}
            theme={NavBarDropdownTheme}
          >
            {newActions.map(item => {
              if (item === 'divider') {
                return (
                  <MenuDivider theme={NavBarDropdownTheme} />
                )
              }

              return (
                <MenuItem
                  key={item.label}
                  value={item.label}
                  caption={item.label}
                  onClick={item.onClick}
                  theme={NavBarDropdownTheme}
                />
              )
            })}
          </IconMenu>
        </div>
      </div>
    )
  }
}

export default PartnerListActions
