import React, { Component } from 'react'
import { connect } from 'react-redux'
import ExploreContainer from 'visual-components/explore/explore-container'
import OverviewBar from 'visual-components/analytics/util/overview-bar'
import SearchCampaignAnalyticsConfig from 'actions/search-config/search-campaign-analytics-config'
import CampaignAnalyticsResultsContainer from 'visual-components/analytics/campaign/CampaignAnalyticsResultsContainer'

import { P } from 'visual-components/util/texts'

const mapStateToProps = ({
  analyticsPartnership: {
    partnershipBasicDetails: { accountname: partnerBrandName },
  },
}) => ({ partnerBrandName })

class PartnershipCampaigns extends Component {

  render() {
    const { endpoint } = SearchCampaignAnalyticsConfig
    const { partnerBrandId, partnerBrandName } = this.props

    return (
      <ExploreContainer
        title={`Campaigns with ${partnerBrandName}`}
        config={{
          ...SearchCampaignAnalyticsConfig,
          model: 'partnershipCampaignsSearch',
          endpoint: `${endpoint}/${partnerBrandId}`,
        }}
        belowTitle={
          <div style={{ marginTop: '16px' }}>
            <P multiline>
            </P>
          </div>
        }
        hideSearchBar

        //appcuesTourId
        render={({ results, loading, error, filtered, gridView }) => (
          <CampaignAnalyticsResultsContainer
            campaigns={results || []}
            loading={loading}
            error={error}
            filtered={filtered}
            display={gridView}
          />
        )}
      />
    )
  }
}

export default connect(mapStateToProps)(PartnershipCampaigns)
