import React from 'react';
import theme from 'css/themes/homePage/trustedByTheme.css';

class TrustedBy extends React.Component {
  render() {
    let {
      background
    } = this.props;

    const imgs = [
      {
        src: 'casper-logo',
        id: 125,
        brand: 'Casper',
      },
      {
        src: 'cosmopolitan-logo',
        id: 170,
        brand: 'Cosmopolitan',
      },
      {
        src: 'hearst-logo',
        id: 219,
        brand: 'Hearst',
      },
      {
        src: 'harper-s-bazaar-logo',
        id: 186,
        brand: `Harper's Bazaar`,
      },
      {
        src: 'general-assembly-logo',
        id: 49,
        brand: 'General Assembly',
      },
    ]
    const imgs2 = [
      {
        src: 'daily-harvest-logo',
        id: 48,
        brand: 'Daily Harvest',
      },
      {
        src: 'the-chive-logo',
        id: 158,
        brand: 'The Chive',
      },
      {
        src: 'harpercollins-logo',
        id: 40,
        brand: 'HarperCollins',
      },
      {
        src: 'the-skimm-logo',
        id: 22,
        brand: 'The Skimm',
      },
      {
        src: 'wellpath-logo',
        id: 52,
        brand: 'Wellpath',
      },
    ]

    const imgList1 = imgs.map((img, idx) => {
      return (
        <a target="_blank" href={`https://www.dojomojo.com/home/brand/${img.id}`} key={idx}>
          <img src={ `/images/login/homepage/brand-logos/${img.src}.svg` }
            className={ theme[img.src] }
            key={ idx }
            alt={`${img.brand}'s profile on DojoMojo`}
          />
        </a>
      )
    })
    const imgList2 = imgs2.map((img, idx) => {
      return (
        <a target="_blank" href={`https://www.dojomojo.com/home/brand/${img.id}`} key={idx}>
          <img src={ `/images/login/homepage/brand-logos/${img.src}.svg` }
            className={ theme[img.src] }
            key={ idx }
            alt={`${img.brand}'s profile on DojoMojo`}
          />
        </a>
      )
    })

    return(
      <div className={ theme.container }
        style={background ? { background } : {}}>
        <p className={ theme.title }>
          Trusted by Thousands of the World’s Top Marketers
        </p>
        <div className={ theme.imgs }>
          { imgList1 }
        </div>
        <div className={ theme.imgs2 }>
          { imgList2 }
        </div>
      </div>
    )
  }
}

export default TrustedBy;
