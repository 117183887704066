import React from 'react'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import { P } from 'visual-components/util/texts'

class ConfirmPartnerDialog extends React.Component {
  confirm = () => {
    const { partnershipAgreement, confirmedPartner, closeModal, navigate, actions } = this.props
    actions.confirmPartner(partnershipAgreement.id, (err) => {
      if (err) {
        closeModal()
      } else {
        closeModal()
        navigate({ key: 'completed' })
        confirmedPartner()
      }
    })
  }

  render() {
    const { partnershipAgreement, closeModal, active } = this.props

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    const { invitee: { accountname } } = partnershipAgreement

    return (
      <div>
        <DefaultDialog active={ active }
          closeModal={ closeModal }
          title={ 'Confirm Partner?' }
          confirm={ this.confirm }
          confirmText={ 'Confirm Partner' }
          cancel={ closeModal }
          cancelText={ 'Cancel' }
          children={
            <div>
              <P multiline>
                Click Confirm to add {accountname} to your campaign. Note that DojoMojo cannot automate campaign processes nor enforce Minimum Entry Thresholds for Partners added this way.
              </P>
            </div>
          }
        />
      </div>
    )
  }
}

export default ConfirmPartnerDialog
