import React, { Component } from 'react'
import theme from 'css/themes/homePage/smsMarketing.css'
import SmsMarketingBanner from './SmsMarketingBanner'
import StickyTabContainer from '../util/sticky-tabs-container'
import StickyTab from '../util/sticky-tab'
import SideImgContent from '../util/side-img-content'
import Footer from '../footer/Footer'

class SmsMarketing extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <SmsMarketingBanner />
        <StickyTabContainer bannerHeight={750} start={600}>
          <StickyTab title="Build Your Audience" icon="build" href="build-your-audience" />
          <StickyTab
            title="Engage Your Subscribers"
            icon="handshake"
            href="engage-your-subscribers"
          />
          <StickyTab title="Drive Revenue" icon="revenue" href="drive-revenue" />
        </StickyTabContainer>

        <div className={theme.sideImgContentContainer}>
          <SideImgContent
            title="Broaden Your Reach"
            para="While other solutions may focus on converting your existing customers to SMS, DojoMojo makes it easy for you to quickly scale and acquire a broader audience."
            imgSrc="/images/login/side-imgs/sms-marketing/post-entry.png"
            href="build-your-audience"
            subparas={[
              {
                title: 'Partnerships powerhouse:',
                text: 'Work within our network of 10,000+ brands to widen your audience exposure',
              },
              {
                title: 'Accelerate audience growth:',
                text:
                  'Leverage existing sweepstakes to rapidly build both your email and mobile messaging lists',
              },
              {
                title: 'Maximize touchpoints:',
                text:
                  'Set up website lead forms and easily capture even more compliant SMS subscribers directly from your website',
              },
            ]}
          />
          <SideImgContent
            right
            title="Optimize Customer Engagement"
            para="Stop battling flooded inboxes and find your audience where they are &mdash; their phones. SMS delivery is already 2-3x that of traditional email!"
            imgSrc="/images/login/side-imgs/sms-marketing/create-new-message.png"
            href="engage-your-subscribers"
            subparas={[
              {
                title: 'Safe send:',
                text: 'Use built-in optimizations to start meaningful conversations with the right customer, at the right time',
              },
              {
                title: 'Say more without words:',
                text:
                  'Personalize your message with content-rich GIFs, photos, and media',
              },
            ]}
          />
          <SideImgContent
            title="Drive More Revenue"
            para="Marketers using DojoMojo are seeing results as high as a 12x+ return on their advertising spend. See how your SMS messages perform and watch your revenue grow!"
            imgSrc="/images/login/side-imgs/sms-marketing/overview.png"
            href="drive-revenue"
            subparas={[
              {
                title: 'Monitor performance:',
                text: 'Analyze and measure the results from your communications and how they stack up over time',
              },
              {
                title: 'Seamless integrations:',
                text:
                  'Connect your payment processors so you don’t miss a beat in your customer’s journey ',
              },
            ]}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export default SmsMarketing
