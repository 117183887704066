import React, {Component} from 'react'
import { H6, P } from 'visual-components/util/texts'
import theme from 'css/themes/referralTheme.css'
import d3 from 'd3'
import { Link } from 'react-router-dom';

class TableRow extends Component {
  clickSendReminder = () => {
    const { openReminderModal } = this.props;
    openReminderModal(this.props.referral)
  }
  render() {
    const {
      referral: {
        id,
        email,
        brandName,
        status,
        credit,
        brandLogo,
        brandId,
        reminder_sent
      },
      openReminderModal
    } = this.props

    const formattedCredit = d3.format("$,.2f")(Math.abs(credit*.01));

    return (
      <div className={`${theme.tableRow}`}>
        <div className={theme.firstColumn}>
        {
          status === 'Joined' ?
          <Link to={`/explore/brands/${brandId}`}>
            <div className={theme.tableImgWrapper}>
              <img src={ brandLogo }
                className={theme.tableImg}
              />
            </div>
          </Link>
          :
          <div className={theme.tableImgWrapper}>
            <img src={ brandLogo || `/images/dummyimg.svg` }
              className={theme.tableImg}
            />
          </div>
        }
          <div className={theme.text}>
            <P overflowEllipsis>
              { email }
            </P>
          </div>
        </div>
        <div className={theme.secondColumn}>
          <P overflowEllipsis>
            { brandName }
          </P>
        </div>
        <div className={theme.thirdColumn}>
          <P>
            { status }
          </P>
        </div>
        <div className={theme.fourthColumhn}>
          { credit < 0
            ? <H6 seaGreen>
                { formattedCredit }
              </H6>
            : <P>
                -
              </P>
           }
        </div>
        <div className={theme.fifthColumhn}>
          { status !== 'Joined' && !reminder_sent
            ? <P>
                <a onClick={ this.clickSendReminder }>
                  Send Reminder
                </a>
              </P>
            : null
          }
        </div>
      </div>
    )
  }
}

export default TableRow
