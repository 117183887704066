import React from 'react';
import DocContainer from './doc-container';
import PostLoginFooter from 'components/post-login-footer';
import { Link } from 'react-router-dom';
import { H2, H4 } from 'visual-components/util/texts';

class ReferralTerms extends React.Component{
  render() {
    return (
        <DocContainer>
          <div className="container">


            <H2><br /><div>DojoMojo Referral Program Terms & Conditions</div><br /></H2>

            <H4>
              <div>
                Last updated: April 1, 2019
              </div>
              <br />
              <div>
                The DojoMojo Referral Program allows registered DojoMojo users (a “DojoMojo User”) to earn promotional credits (“Referral Credits”) toward future service billings by referring businesses to DojoMojo.
              </div>
              <br />
              <div>
                To participate, DojoMojo Users must agree to these terms, which become part of the DojoMojo <a href="https://www.dojomojo.com/home/terms-of-use" target="_blank">Terms of Service</a>. Capitalized terms that are not defined here will have the same meaning given to them in the DojoMojo <a href="https://www.dojomojo.com/home/terms-of-use" target="_blank">Terms of Service</a>.
              </div>
              <br />
              <div><b>How to Earn Referral Credits</b></div>
              <br />
              <div>DojoMojo Users can earn Referral Credits towards future DojoMojo Billings if:</div>
              <br />
              <div>(i) A referred business clicks on the Referrer’s unique referral link to create a valid DojoMojo account that complies with our <a href="https://www.dojomojo.com/home/terms-of-use" target="_blank">Terms of Service</a>,</div>
              <br />
              <div>(ii) The Referred User is not a member of any other DojoMojo account (whether they’re active, expired or canceled or a paid, free or trial account),</div>
              <br />
              <div>(iii) The Referred User was not referred to DojoMojo at an earlier date by someone else,</div>
              <br />
              <div>(iv) The Referred Account created by the Referred User must not be for a company associated with any other DojoMojo accounts (whether they’re active, expired or canceled or a paid, free or trial account),</div>
              <br />
              <div>(v) The Referred User can’t have the same IP address as the Referrer,</div>
              <br />
              <div>(vi) The Referred User can’t have the same IP address as any other user of DojoMojo (whether the user is a current or former user) at the time the Referred User signs up.</div>
              <br />
              <div>(vii) The Referred User must have a valid Tax ID and business website.</div>
              <br />
              <div>The referring DojoMojo User will be credited with a $25 Referral Credit and the Referred User’s account will be credited with $25 Credit amount unless indicated otherwise in the referral invitation or accompanying promotional materials.</div>
              <br />
              <div><b>Redeeming Referral Credits</b></div>
              <br />
              <div>Referral Credits may only be redeemed via the DojoMojo Application and Services. Referral Credits will automatically appear as Credit in the billing section, and must be used on a billing payment within one year from the date they are issued. After one year, the Referral Credits will expire. Referral Credits are coupons issued for promotional purposes; they have no cash value and may not be transferred or exchanged for cash.</div><br/>
              <div>Referral Credits may not be earned by creating multiple DojoMojo Accounts. Referral Credits accrued in separate DojoMojo accounts may not be combined into one DojoMojo account.</div><br/>
              <div>If for any reason you believe that there is a discrepancy regarding your balance of Referral Credits, please contact us. DojoMojo may require you to submit additional information in order to make a determination regarding your balance. All decisions regarding your balance will be final and at DojoMojo’s sole discretion.</div><br/>
              <div>You are responsible for any Tax consequences, if any, that may result from your redemption or use of Referral Credits. Where applicable, DojoMojo may be required to account for VAT on any services for which the Referral Credits are redeemed.</div><br/>
              <div><b>Sharing Referral Links</b></div><br/>
              <div>Referrals should only be shared with personal connections that will appreciate receiving these invitations. Referral links should not be published or distributed where there is no reasonable basis for believing that all or most of the recipients are personal contacts (such as coupon websites, Reddit, or Wikipedia).</div><br/>
              <div><b>Referred Contacts</b></div><br/>
              <div>Referred contacts that have signed up using a valid referral link will also receive a Referral Credit indicated in the referral invitation or accompanying promotional materials that can be used on their next billing on DojoMojo.</div><br/>
              <div><b>Multiple Referrals</b></div><br/>
              <div>A referred contact may only use one referral link. If a referred contact receives referral links from multiple DojoMojo Users, only the corresponding DojoMojo User of the referral link used by the referred friend will receive Referral Credit.</div><br/>
              <div><b>Severability</b></div><br/>
              <div>If any provision in these terms are held to be invalid, void, or unenforceable, such provision (or the part of it that is making it invalid, void or unenforceable) will be struck and not affect the validity of and enforceability of the remaining provisions.</div><br/>
              <div><b>Termination and Changes</b></div><br/>
              <div>DojoMojo may suspend or terminate the Referral Program or a user’s ability to participate in the Referral Program at any time for any reason.</div><br/>
              <div>We reserve the right to suspend accounts or remove Referral Credits if we notice any activity that we believe is abusive, fraudulent, or in violation of the DojoMojo Terms of Service. We reserve the right to review and investigate all referral activities and to suspend accounts or modify referrals in our sole discretion as deemed fair and appropriate.</div><br/>
              <div>The scope, variety, and type of services and products that you may obtain by redeeming Referral Credits can change at any time.</div><br/>
              <div><b>Updates to the Terms</b></div><br/>
              <div>We can update these terms at any time without prior notice. If we modify these terms, we will post the modification on the DojoMojo website, applications, or services, which are effective upon posting. Continued participation in the Referral Program after any modification shall constitute consent to such modification.</div><br/>
              <div>* The referral’s local currency equivalent credit amount will be calculated using a system-wide rate, known as the base exchange rate, for currency conversion using data from one or more third parties, such as <a href="https://www.oanda.com/" target="_blank">OANDA</a>. We may update the base exchange rate and it may not be identical to the real-time market rate.</div><br/>
            </H4>
            <br />
            <div className="row text-center">
              <H2>
                <br />
                <b>
                  OFFICIAL RULES - THE DOJOMOJO SWEEPSTAKES
                </b>
              </H2>
            </div>
            <br />
            <br />
            <H4>
              <div>The DojoMojo Sweepstakes (the “Sweepstakes”) is intended for participation in the United States (but excluding Puerto Rico, American Samoa, Guam, all other U.S. territories and possessions and foreign-based U.S. embassies and military installations) and the District of Columbia only and shall only be construed and evaluated according to United States law. Do not proceed in this Sweepstakes if entrant is an individual but is not a legal resident and physically residing in the United States or the District of Columbia or if entrant is a corporate entity but is not registered to do business in the United States or the District of Columbia (individuals and corporate entities will be referred collectively hereinafter from time to time as “you” or “Entrant”) and you do not fall within the eligibility requirements set forth in the Official Rules below.</div><br/>
              <div><b>HOW TO ENTER:</b></div><br/>
              <div><b>NO PURCHASE NECESSARY TO ENTER OR WIN A PRIZE. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. VOID WHERE PROHIBITED.</b></div><br/>
              <div>The Sweepstakes is sponsored by Innovation Brands Corp. d/b/a DojoMojo (“Sponsor” or “DojoMojo”). Each entry period begins at 7 a.m. Pacific Time (PT) on the first day of each calendar Month during the second calendar quarter (April 1, May 1, June 1, 2019) and ends at 11:59:59 p.m. PT on the last day of each calendar Month (April 30, May 31, June 30, 2019) (each an “Entry Period”). There will be one (1) prize selected and awarded following the expiration of each Entry Period. Sponsor’s computer will be the official clock for the Sweepstakes. Proof of submitting an entry will not be deemed by Sponsor as proof of receipt of entry into the Sweepstakes. Any attempted form of entry into the Sweepstakes than as described herein is void.</div><br/>
              <div>To enter go to www.dojomojo.com (the “Website”) and log in if you are a current DojoMojo account holder or, or if you are not a current DojoMojo account holder, create a DojoMojo account during the relevant Entry Period (opening a DojoMojo account is free). Then, invite a new brand to create a new account on DojoMojo using the following procedure: Log into your account. Navigate to the Explore Brands or Referral Dashboard in your Account Settings on the Website and use the appropriate button (“Send Invite” or “Invite Brand” or similar) to send an invitation to a brand. This invitation will include a Referral Link inserted by DojoMojo. The Referral Link will contain a code that automatically registers you with DojoMojo as the referring party. When the person or entity to whom you send the Referral Link opens a new DojoMojo account, you earn one Sweepstakes entry (“Sweepstakes Entry”) and a $25.00 credit to your DojoMojo account.</div><br/>
              <div>For a brand to be considered new and eligible for a referral, the brand has not created an account on DojoMojo before, and that they have a website, a tax ID and have uploaded a logo to the platform.</div><br/>
              <div>You may send as many Referral Links as you wish; however, you will receive no more than 100 Sweepstakes Entries in total per Entry Period.</div><br/>
              <div><b>THE REFERRAL LINK</b></div><br/>
              <div>All entries must be submitted at the Website during the applicable Entry Period according to all applicable directions as listed on the Website or the entry will be void. Those Entrants who do not follow the directions or provide all of the required information will be disqualified. Sponsor is not responsible for lost, late, incomplete, illegible, incomprehensible, or misdirected entries, bugs, failures in servers, hardware or software transmission failure or loss, or delayed or corrupted data transmissions or any injury or damage to an Entrant’s or any other person’s computer related to or resulting from downloading any portion of this Sweepstakes. All entries will become the property of Sponsor and none will be returned. Sponsor shall be the sole and exclusive owner of all Sweepstakes entries.</div><br/>
              <div><b>THIS SWEEPSTAKES IS VOID WHERE PROHIBITED OR RESTRICTED BY LAW.</b></div><br/>
              <div><b>ELIGIBILITY:</b></div><br/>
              <div>All Entrants must be a new or existing DojoMojo account holder or user (Basic, Essential or Premium user) Entrants who are individuals must be at least eighteen (18) years old or the age of the majority in the Entrant’s state of residence as of the date of entry (whichever is greater) and be a legal resident of and physically reside in one of the 50 United States (excluding Puerto Rico, Guam, American Samoa, all other U.S. territories and possessions, and foreign-based U.S. embassies and military installations) or the District of Columbia. Entrants that are business entities must be registered to do business in at least one of the United States or the District of Columbia. Employees of Sponsor, Innovative Brands LLC, Innovation Department, or Amazon, and each of their respective partners, parent companies, divisions, subsidiaries, affiliates, advertising, promotion and public relations agencies and their respective officers, directors, employees, representatives, and agencies (collectively, the “Sweepstakes Entities”) are not eligible to participate in the Sweepstakes and/or win a Prize. Additionally, relatives of such employees and persons living in the same household as such employees, whether related or not, are not eligible to win. For the purposes of this Sweepstakes, relatives are defined as spouse, mother, father, in-laws, grandmother, grandfather, brother, sister, children, and grandchildren. In addition, you are not eligible to participate or win the Prize in this Sweepstakes if you have been involved in the development, production (including prize suppliers), implementation, and distribution of this Sweepstakes.</div><br/>
              <div><b>PRIVACY:</b></div><br/>
              <div>Except as otherwise stated in these Official Rules, the use of the information each Entrant submits in this Sweepstakes is governed by DojoMojo’s online privacy policy, which can be found at: <a href="https://www.dojomojo.com/home/privacy" target="_blank">https://www.dojomojo.com/home/privacy</a> </div><br/>
              <div><b>DRAWING:</b></div><br/>
              <div>One prize winner (“Winner”) will be selected by a random drawing conducted by or on behalf of Sponsor from all eligible Sweepstakes Entries for each Entry Period within five (5) business days following the expiration of the relevant Entry Period. Only one (1) Prize per Entrant per drawing.</div><br/>
              <div>The Winners will be announced within five (5) business days following the end date of each Sweepstakes.</div><br/>
              <div>The Winners will be notified via telephone or e-mail f the Prize, subject to verification, of eligibility and identity. If Winner refuses to accept the Prize, cannot be reached after a reasonable effort has been made during three (3) days from Sponsor’s first attempt, or is found to be ineligible, an alternate winner may be selected in Sponsor’s sole discretion. If any Prize or Prize notification is returned as undeliverable, the Winner may be disqualified, and an alternate Winner may be selected in Sponsor’s sole discretion.</div><br/>
              <div>Winner’s DojoMojo account must be active to receive a Prize. No transfer or Prize substitution allowed, except at Sponsor’s sole discretion. Sponsor may require Prize winner to sign an affidavit of eligibility and liability/publicity release (as more fully discussed below). That document must be returned to Sponsor within five (5) days of receipt of such document(s) or Prize will be forfeited. Decisions made by Sponsor are final in all matters, including interpretation of these Official Rules and awarding of the Prize and those decisions cannot be appealed. If legitimately claimed, the Prize will be awarded.</div><br/>
              <div><b>PRIZES:</b></div><br/>
              <div>Each Prize will consist of one (1) gift card from Amazon. Approximate retail value (“ARV”) of each Prize: $250.00. Each Winner will receive one (1) Prize. Each Prize shall be governed by Amazon’s terms and conditions relating to gift cards which can be found at <a href="https://www.amazon.com/exec/obidos/tg/browse/-/3122091/pop-up" target="_blank">https://www.amazon.com/exec/obidos/tg/browse/-/3122091/pop-up</a>.</div><br/>
              <div><b>Total ARV of Prize for each Entry Period: $1,000.00 per Sweepstakes. There will be a Sweepstakes drawing each calendar quarter until the Sponsor elects to terminate the Sweepstakes.</b></div><br/>
              <div><b>TOTAL ARV OF ALL PRIZES PER YEAR:  $4,000.00</b></div><br/>
              <div><b>Sponsor is not responsible for and will not replace lost, stolen or destroyed Gift Cards.  </b></div><br/>
              <div><b>LIMITATION ON LIABILITY / DISCLAIMER / OTHER REQUIREMENTS:</b></div><br/>
              <div>All Entrants, as a condition of entry, agree to be bound by these Official Rules and the decisions of Sponsor. Except where prohibited by law, by entering the Sweepstakes, each Entrant consents to the use of his/her name or the name of Entrant’s business entity name, an individual Entrant’s voice, picture, likeness, and biographical data and/or any other information contained in an entry in advertising, promotional and marketing materials by Sponsor and the Sweepstakes Entities without additional compensation, review, or approval which may require, without limitation, a Winner to appear in person on a television, radio or Internet broadcast. In addition, by entering, all Entrants, who are individuals, except where prohibited by law, consent to being photographed, filmed, taped, and/or interviewed about the Sweepstakes. All business entity Entrants will select one (1) person to be photographed, filmed, taped, and/or interviewed about the Sweepstakes on behalf of that business entity. All Prize claims and winner materials will be verified by Sponsor.</div><br/>
              <div>If this Sweepstakes is not capable of running as planned, including, because of any infection by computer virus, bugs, tampering, unauthorized intervention, fraud, technical failure, or other cause beyond Sponsor’s control which corrupts or threatens to corrupt or adversely affects the administration, security, fairness, integrity, or proper conduct of the Sweepstakes, Sponsor reserves the right, in its sole discretion, to cancel, suspend, and/or modify this Sweepstakes and Sponsor can select a Prize winner from among all eligible entries received prior to such cancellation, suspension, or modification of the Sweepstakes. By accepting the Prize, Prize winner agrees to release Sponsor and the Sweepstakes Entities from any and all liability, claims, or actions of any kind whatsoever for injuries, damages, or losses to persons and property which may be sustained in connection with the receipt, ownership, or use of the Prize or while traveling to and from a location to obtain or use the Prize or any portion thereof. Further, no responsibilities are accepted for any additional expenses, omissions, delays, re-routing, or acts of any government or authority. </div><br/>
              <div><b>Neither Sponsor nor the Sweepstakes Entities make any warranty, representation, or guarantee, express or implied, in fact or in law, relative to the use of the Prize including, without limitation, quality, merchantability, or fitness for a particular purpose. By participating, Entrants agree to release, discharge, and hold harmless Sponsor and the Sweepstakes Entities from any and all claims, actions, demands, losses, liabilities and damages of any kind whatsoever (including, without limitation, bodily injury, personal injury, death, disability, and property damage), costs and expenses (including, without limitation, reasonable attorney’s fees, court costs, settlement and disbursements) directly or indirectly arising out of their participation in the Sweepstakes, the redemption, acceptance, possession, use, misuse, loss or misdirection of a Prize or any portion of a Prize, and participation in any Prize-related activities, and hereby waive all rights to claim punitive, incidental and consequential damages.</b></div><br/>
              <div>Neither Sponsor nor the Sweepstakes Entities are responsible for technical, hardware, software, computer, Internet, or telephone malfunctions of any kind, lost or unavailable network connections, or failed, incorrect, incomplete, inaccurate, garbled, or delayed electronic communications caused by Sponsor or the Sweepstakes Entities, the user or by any of the equipment or programming associated with or utilized in this Sweepstakes or by any human error which may occur in the processing of the entries in this Sweepstakes and which may limit a participant’s ability to participate in this Sweepstakes. Entries that are incomplete, illegible, or corrupted are void and will not be accepted. Any person who supplies false information, enters by fraudulent means, or is otherwise determined to be in violation of these Official Rules in an attempt to obtain a Prize will forfeit that Prize won and may be prosecuted to the fullest extent of the law. </div><br/>
              <div>The Sweepstakes shall be governed by, construed and enforced in accordance with the laws of the State of New York. Any action by an Entrant or a third party brought to enforce these rules or, in connection with any matters related to the Sweepstakes and/or the Website shall be resolved individually, without resort to any form of class action, exclusively by those courts located in New York County, New York, and Entrants expressly consent to the jurisdiction of said courts. Any and all claims, judgments and awards shall be limited to actual out-of-pocket costs incurred including, without limitation, costs associated with entering the Sweepstakes but in no event attorneys’ fees. Under no circumstances will Entrants be permitted to obtain awards for, and Entrants hereby waive all rights to claim indirect, punitive, incidental and consequential damages and any other damages other than for actual out-of-pocket expenses and any and all right to have damages multiplied or otherwise increased.</div><br/>
              <div>Each Entrant further agrees to not knowingly: damage or cause interruption of the Sweepstakes; prevent others from using the Sweepstakes; or obtain or modify another user’s non-public account information without their consent. Using the “Send Invite” or “Invite Brand” button to send an invitation and/or a Referral Link to a brand is acknowledgment of, and agreement to be bound by, all terms and conditions of these Official Rules. In the event of a dispute concerning who submitted an e-mail entry, the entry will be declared to have been made by the authorized account holder of the e-mail address submitted at time of entry. An “authorized account holder” is defined as the natural person who is assigned to an e-mail address by an Internet access provider, online service provider, or other organization (e.g. business, educational institution, etc.) that is responsible for assigning e-mail addresses for the domain associated with the submitted e-mail address.</div><br/>
              <div>CAUTION: ANY ATTEMPT TO DAMAGE ANY ONLINE SERVICE OR THE WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION OF THE SWEEPSTAKES VIOLATES CRIMINAL AND/OR CIVIL LAWS. IF SUCH AN ATTEMPT IS MADE, SPONSOR MAY DISQUALIFY ANY ENTRANT MAKING SUCH ATTEMPT AND SPONSOR MAY SEEK DAMAGES TO THE FULLEST EXTENT PERMITTED BY LAW.</div><br/>
              <div>Sponsor and Sweepstakes Entities shall not be liable to any Winners or any other person for failure to supply the Prize or any part thereof, by reason of any act of God, any action, regulation, order or request by any governmental or quasi-governmental entity, equipment failure, terrorist acts, earthquake, war, fire, flood, explosion, unusually severe weather, hurricane, embargo, labor dispute or strike, labor or material shortage, transportation interruption of any kind, work slow-down, civil disturbance, insurrection, riot or any similar or dissimilar event beyond Sponsor’s or Sweepstakes Entities’ reasonable control.</div><br/>
              <div>For names of the Prize winners, go to the Website 5 business days after the end of each calendar month of the second calendar quarter of 2019 (April 30, May 31, June 30, 2019).</div><br/>
              <div>A copy of these Official Rules may be found on the Website.</div><br/>
              <div>dojomojo.com and all of the related pages, contents and code are © 2019 Innovation Brands, Corp. Copying or unauthorized use of any copyrighted material or trademarks without the express written consent of its owner is strictly prohibited.</div><br/>
              <div>Amazon is not a sponsor of this Sweepstakes.</div><br/>
            </H4>
          </div>
        </DocContainer>
    );
  }
};

export default ReferralTerms;
