import React, { Component } from 'react';
import BaseBanner from '../util/banner-base';
import MobileHeader from '../mobile-header/MobileHeader';

import theme from 'css/themes/mobile/home/banner.css';

class SweepstakesBanner extends Component {
  render() {
    return(
      <div id={ theme.sweepstakes }>
        <MobileHeader/>
        <BaseBanner
          title={ 'Sweepstakes' }
          imgSrc={ '/images/login/mobile/sweepstakes/sweepstakes-screenshot.png' }
          about={ 'Growing your audience doesn’t have to take months. Giveaways are a low cost, high ROI way to grow your email list and social following. Find brand partners, create beautiful sweepstakes pages and measure results - all in one place.' }/>
      </div>
    )
  }
};

export default SweepstakesBanner;
