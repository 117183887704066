import React from 'react';
import theme from 'css/themes/mobile/home/home.css';

class Countup extends React.Component {
  state = {
    count: 0,
    Odometer: () => null,
    smsCount: 0,
  }

  componentDidMount() {
    this.setState({
      Odometer: require('react-odometerjs').default,
    })
    $.get('/total-subs-acquired', ({ emails: data, smsData }) => {
      var latestEmailCount = Number(data.email_count);
      var lastUpdated = data.last_updated || new Date();
      lastUpdated = Math.min(new Date(), Date.parse(lastUpdated))
      var secondsSinceLastUpdate = (Date.now() - lastUpdated)/1000;
      var estEmailsSinceLastUpdate = Math.round(secondsSinceLastUpdate / 3.5);

      this.setState({
        count: latestEmailCount + estEmailsSinceLastUpdate,
        smsCount: smsData.smsSubscribers,
      })

      const loop = () => {
        var rand = (Math.floor(Math.random() * 6) + 1)* 1000 //random between 1 and 6 seconds
        this.timer = setTimeout(() => {
          this.setState({
            count: this.state.count + 1
          })
          loop()
        }, rand)
      }
      loop()
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    const {
      count, smsCount,
    } = this.state

    return (
      <>
        <div className={ theme.clockWrapper }>
          <div className={ theme.middleWrapper }>
            <this.state.Odometer
              value={ count }
              format={ ",ddd" }
              animation={ true }
            />
            <p className={ theme.totalText }>
              Total Emails Acquired
            </p>
          </div>
        </div>
        <div className={theme.clockWrapperSms}>
          <div className={theme.middleWrapper}>
            <this.state.Odometer value={smsCount} format=",ddd" animation />
            <p className={theme.totalText}>SMS Marketing Opt-Ins Acquired</p>
          </div>
        </div>
      </>
    )
  }
}

export default Countup
