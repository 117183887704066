import React from 'react'
import Radium from 'radium'
import COLOURS from 'util/colours'
import { H3, H6 } from 'visual-components/util/texts'
import ToggleField from 'util/components/toggle-field'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import { uniq, memoize } from 'lodash'
import styleHelper from 'v2/styles/style-helper'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import RangeSlider from 'util/components/templates/form/range-slider'
import UploadImage from 'components/util/editor-uploader'
import EditOverlay from './EditOverlay'

const styles = {
  container: {
    position: 'relative',
    marginBottom: 60,
  },
  logoContainer: {
    display: 'grid',
    gridTemplateColumns: props => `repeat(auto-fit, ${Math.min(props.campaign.logoSize, 200) + 50}px)`,
    maxWidth: props => (props.desktopVersion ? 750 : 300),
    justifyContent: 'center',
    margin: '0 auto',
    gridGap: '10px',
    // hide hacks created by jquery's ui-sortable
    '&::before, &::after': {
      display: 'none !important',
    },
    [styleHelper.max(750)]: {
      maxWidth: '300px !important',
    },
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 5,
  },
  logo: {
    width: '100%',
    objectFit: 'contain',
  },
  editModal: {
    position: 'absolute',
    left: '50px',
    top: '-250px',
    zIndex: '6000',
    width: '260px',
    padding: '24px 24px 16px 24px',
    borderRadius: '2px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    background: COLOURS.white,
  },
  modalHeader: {
    marginBottom: '24px',
    textAlign: 'center',
  },
  logoUploadWrapper: {
    position: 'absolute',
    zIndex: 5,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}

const PartnerLogos = Radium(class extends React.Component {
  static propTypes = {
    updateAttr: propTypes.func.isRequired,
    campaign: propTypes.object.isRequired,
    edit: propTypes.func.isRequired,
    partnerCampaigns: propTypes.array.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool.isRequired,
  }

  updateLogoSize = (attr, value) => {
    const {
      updateAttr,
    } = this.props
    updateAttr(attr, Number(value))
  }

  updateGreyScale = () => {
    const {
      campaign,
      updateAttr,
    } = this.props

    const data = {
      greyScaleLogos: !campaign.styles.greyScaleLogos,
    }

    updateAttr('styles', data)
  }

  edit = () => {
    const {
      edit,
      updateAttr,
    } = this.props

    $('#partner-logo-container').sortable({
      update: function() {
        const ids = $('.logo')
        .map(function () {
          return $(this).data('partnership')
        })
        .get()

        updateAttr('styles', {
          partnerLogoOrder: ids
        })
      }
    })

    edit('logos')
  }

  doneEdit = () => {
    const {
      edit,
    } = this.props

    $('#partner-logo-container').sortable('disable')
    edit('')
  }


  incrementSize = () => {
    const {
      updateAttr,
      campaign,
    } = this.props
    const value = Number(campaign.logoSize || 50) + 1
    updateAttr('logoSize', Math.min(value, 200))
  }

  decrementSize = () => {
    const {
      updateAttr,
      campaign,
    } = this.props
    const value = Number(campaign.logoSize || 50) - 1
    updateAttr('logoSize' , Math.max(value, 50))
  }

  onPartnerLogoUpload = inviteId => result => {
    const {
      campaign,
      updateAttr,
    } = this.props

    const logos = campaign.styles.partnerLogos || {}
    logos[inviteId] = result.src

    updateAttr('styles', {
      partnerLogos: logos,
    })
  }

  renderLogos = () => {
    const {
      campaign,
      partnerCampaigns,
      classes: css,
      updateAttr,
    } = this.props

    const partnerLogos = campaign.styles.partnerLogos || {}
    const partnerIds = partnerCampaigns.map(partner => partner.id)
    const order = campaign.styles.partnerLogoOrder || partnerIds.sort()

    //add the rest of the partner ids that are missing
    order.push(...partnerIds.filter(id => !order.includes(id)))

    const invites = uniq(order)
      .map(id => {
        const invite = partnerCampaigns.find(partnerCampaign => partnerCampaign.id === id)
        return invite
          && invite.invitee
          && invite.invitee.logo
          && invite.invitee.logo.indexOf('Empty-Avatar.png') === -1
          ? invite
          : null
      })
      .filter(invite => invite !== null)

    return (
      <div
        id="partner-logo-container"
        className={css.logoContainer}
      >
        {
          invites.map(invite => (
            <div
              className={cn(css.imageWrapper, 'partner-logo')}
              data-partnership={invite.id}
              key={invite.id}
            >
              {
                !!updateAttr && campaign.editingAttr === 'logos'
                && (
                  <div className={css.logoUploadWrapper}>
                    <UploadImage
                      success={this.onPartnerLogoUpload(invite.id)}
                      action={`/campaign-photo/${campaign.campaign_id}/partner-logo`}
                      name="campaign-main-image"
                      acceptedFiles="image/jpeg,image/png"
                    >
                      <EditOverlay
                        label="UPLOAD LOGO"
                      />
                    </UploadImage>
                  </div>
                )
              }
              <img
                key={invite.id}
                src={partnerLogos[invite.id] || invite.invitee.logo}
                className={cn(css.logo, invite.invitee.accountname.replace(/[\s+']/g, '-'))}
                style={{
                  filter: campaign.styles.greyScaleLogos ? 'grayscale(100%)' : '',
                  WebkitFilter: campaign.styles.greyScaleLogos ? 'grayscale(100%)' : '',
                }}
              />
            </div>
          ))
        }
      </div>
    )
  }

  renderEditModal = () => {
    const {
      campaign,
      classes: css,
    } = this.props

    return (
      <div
        className={css.editModal}
      >
        <div className={css.modalHeader}>
          <H3>
            Edit Logos
          </H3>
        </div>
        <div>
          <H6>
            Logo Size
          </H6>
          <RangeSlider
            attr="logoSize"
            value={campaign.logoSize}
            min="50"
            max="200"
            step="1"
            updateValue={this.updateLogoSize}
            incrementValue={this.incrementSize}
            decrementValue={this.decrementSize}
            leftButton={<img src="images/builder/icons/minus.svg" />}
            rightButton={<img src="images/builder/icons/plus.svg" />}
          />
          <div style={{ marginTop: '15px', marginBottom: '15px' }}>
            <ToggleField
              value={campaign.styles.greyScaleLogos}
              disabled={false}
              model={campaign.styles}
              selectedClass="black"
              updateAttr={this.updateGreyScale}
              labelStyles={[{
                color: 'white',
              }]}
              label="Greyscale"
            />
          </div>
          <ModalButtonGroup
            confirm={this.doneEdit}
            confirmText="Done"
            canSave
            hideLine
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      campaign,
      updateAttr,
      classes: css,
    } = this.props

    if (!updateAttr) {
      return this.renderLogos()
    }

    return (
      <div className={css.container}>
        {
          !!updateAttr
            && (
              campaign.editingAttr === 'logos'
                ? this.renderEditModal()
                : <EditOverlay onClick={this.edit} />
            )
        }
        {this.renderLogos()}
      </div>
    )
  }
})

export default injectSheet(styles)(PartnerLogos)
