import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import colors from 'util/colours'
import { H4, P } from 'visual-components/util/texts'

const CloseIcon = props => {
  const dPath = 'M16 .941l-.928-.925L8 7.075.928.015 0 .942l7.072 7.06L0 15.058l.928.925L8 8.926l7.072 7.058.928-.925L8.927 8z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path fill="#475464" fillRule="nonzero" d={dPath} />
    </svg>
  )
}

const BANNER_TYPES = {
  info: colors.foggy,
  warning: colors.coralLight,
  regular: colors.crystal,
  default: 'transparent',
}

const styles = {
  wrapper: {
    position: 'relative',
    padding: ({ pad }) => (pad ? 16 : 0),
    backgroundColor: ({ type }) => BANNER_TYPES[type] || BANNER_TYPES.default,
    marginBottom: 8,
  },
  close: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
  },
}

function SMSInfoBanner(props) {
  const {
    title, cta, body, onClose, classes: css,
  } = props

  return (
    <div className={cn(css.wrapper, 'sms-info-banner')}>
      {title && (
        <H4 multiline>
          <strong>{title}</strong>
        </H4>
      )}
      {React.isValidElement(body) ? body : <P multiline>{body}</P>}
      {cta && <div style={{ marginBottom: 12 }} />}
      {cta && (React.isValidElement(cta) ? cta : <Link to={cta.url}>{cta.label}</Link>)}
      {onClose && <CloseIcon className={css.close} onClick={onClose} />}
    </div>
  )
}

SMSInfoBanner.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClose: PropTypes.func,
  cta: PropTypes.string,
}

SMSInfoBanner.defaultProps = {
  cta: null,
  title: null,
  onClose: null,
}

export default React.memo(injectCSS(styles)(SMSInfoBanner))
