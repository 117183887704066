import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProfileLogo from 'visual-components/util/BrandProfileLogo'
import BrandCoverPhoto from 'visual-components/brandProfile/brand-cover-photo'

const mapState = state => ({
  brand: state.brand,
  currentBrand: state.currentBrand,
})

class BrandProfileTopContent extends PureComponent {
  render() {
    const {
      brandId,
      isPublic,
      currentBrand,
      brand,
    } = this.props

    const isBrand = !isPublic && currentBrand.id === brand.id

    return (
      <div style={{ width: '100vw', position: 'absolute', left: 0 }}>
        <BrandCoverPhoto isBrand={isBrand} brandId={brandId} isPublic={isPublic} />
        <ProfileLogo isBrand={isBrand} isPublic={isPublic} />
      </div>
    )
  }
}

BrandProfileTopContent.propTypes = {
  isPublic: PropTypes.bool,
  currentBrand: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
}

BrandProfileTopContent.defaultProps = {
  isPublic: false,
}

export default connect(mapState)(BrandProfileTopContent)
