import React, { Component } from 'react'
import { 
  oneOfType, 
  object, 
  number, 
  instanceOf,
  bool,
  string,
} from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

moment.updateLocale('en', {
  relativeTime : {
    future: 'in %s',
    past: '%s ago',
    s: 'just now',
    ss: '%d seconds',
    m: '1 min',
    mm: '%d min',
    h: '1 hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  }
})

// guess user timezone
const guessUserTimezone = moment.tz.guess()

const mapState = ({ currentTime }) => ({ currentTime })

class RelativeTime extends Component {
  static propTypes = {
    // time can be raw number, or instance of moment or Date, or string
    time: oneOfType([ 
        number, 
        instanceOf(moment), 
        instanceOf(Date),
        string,
      ]).isRequired,

    style: object,

    // flag to indicate whether dates should be displayed in
    // user's timezone or in platform default; defaults to user timezone
    userTimezone: bool,
  }

  static defaultProps = {
    style: {},
    userTimezone: true,
  }

  // Static timer object that all component instances can update from
  // every 30 seconds
  static timer = setInterval(() => {
    store.dispatch({
      model: 'currentTime',
      type: 'UPDATE_ATTR',
      data: { value: +moment() },
    })
  }, 30000)

  // calculate 'then' once, with user timezone or without
  then = this.props.userTimezone
    ? moment.tz(this.props.time, guessUserTimezone)
    : moment(this.props.time)

  calculate() {
    const { then } = this
    const now = moment(this.props.currentTime.value)

    if (now.diff(then) < 45000) {
      return 'just now'
    }
    else if (now.diff(then, 'hours') < 48) {
      return then.fromNow()
    }
    else if (now.diff(then, 'days') < 365) {
      return then.format('MMM D')
    }
    else {
      return then.format('M/D/YYYY')
    }
  }

  render() {
    return (
      <span style={ this.props.style }>
        { this.calculate() }
      </span>
    )
  }
}

export default connect(mapState)(RelativeTime)
