import React from 'react'

import { H2, H3, P } from 'visual-components/util/texts'
import styles from 'css/themes/Text.css'

const { terms } = styles

const CTAPurchaserTerms = () =>
  <div className="container">
    <div className={`row ${terms}`}>
      <H2>Innovation Brands Corp ("DOJOMOJO") Terms &amp; Conditions
      for Advertiser/their Authorized Representative</H2>

      <P>BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX
      INDICATING YOUR ACCEPTANCE OR BY EXECUTING AN INSERTION ORDER THAT REFERENCES
      THESE TERMS &amp; CONDITIONS, YOU AGREE TO THE TERMS OF THESE TERMS &amp;
      CONDITIONS. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR
      OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH
      ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE
      TERMS &quot;YOU&quot; OR &quot;YOUR&quot; SHALL REFER TO SUCH ENTITY AND ITS
      AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH
      THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE
      THE SERVICES.</P>

      <H3>1. Description of Service provided by DOJOMOJO </H3>

      <P>1.1 DOJOMOJO provides a Software and Platform that engages with
      a network of partner brands ("Partner ") to enable the display of advertising
      campaigns, sweepstakes, offers and related information ("Campaigns") prepared
      and supplied by advertisers and their authorized representatives (together
      "Advertiser" or "You" or "Your"). The Advertiser authorizes DOJOMOJO to display
      Campaigns on the DOJOMOJO Platform and on its Network and provide other
      services ("Services") in accordance with these terms and conditions ("T's &amp;
      C's").</P>

      <P>1.2 These services may be provided to you via an electronic
      platform allowing electronic management of Campaigns via a web portal or
      program via WWW.DOJOMOJO.COM ("Self-Service Portal") or through your DOJOMOJO
      Account Manager, as applicable.</P>

      <P>1.3 If you click a box on the Self-Service Portal indicating
      your acceptance to these T's &amp; C's you are entering into this Agreement
      ("Agreement") with Innovation Brands Corp, a Delaware C-Corp. If you execute an
      Insertion Order that references these T's &amp; C's via your DOJOMOJO Account
      Manager you will be notified of the entity with whom you are contracting on the
      face of the Insertion Order. References to "DOJOMOJO" in these T's and C's
      refer to the relevant DOJOMOJO contracting entity.</P>

      <P>1.4 The Advertiser may specify an applicable budget for a
      Campaign or series of Campaigns using the Self-Service Portal or by providing
      notification to the Advertiser's Account Manager. Campaigns will be displayed
      until such time as such budget is exhausted by charged Booking Fees (defined
      below). </P>

      <H3>2. Campaign Acceptance </H3>

      <P>2.1 Campaigns may be lodged with DOJOMOJO by providing
      complying advertising content ("Content") including copy, images and format
      files that adhere to the DOJOMOJO Terms of Use ("Terms of Use"). </P>

      <P>2.2 Campaigns are subject to the acceptance of DOJOMOJO.
      DOJOMOJO may reject or remove non-complying content at any time for any or no
      reason, including on the basis of non-compliance with the Terms of Use. DOJOMOJO
      will notify the Advertiser of non-compliance at the earliest available
      opportunity to provide a chance for complying Content to be provided. </P>

      <P>2.3 The Advertiser can provide updated Content in writing in
      electronic format to DOJOMOJO at any time. If provided using the Self-Service
      Portal, updates will be reflected on DOJOMOJO's Partner Network immediately
      following acceptance by DOJOMOJO. If provided via Advertiser's Account Manager,
      these updates will be reflected on DOJOMOJO's Partner Network within a
      reasonable time period.</P>

      <P>2.4 DOJOMOJO does not provide creative or design services.</P>

      <P>2.5 The party providing instructions in relation to a
      Campaign (i.e. Advertiser or, if applicable, their authorized representative)
      will be principally liable for the performance of the obligations of the
      Advertiser.</P>

      <H3>3. Campaign Display and Billing</H3>

      <P>3.1 DOJOMOJO will display the Campaign, as provided by the
      Advertiser, on the DOJOMOJO Platform or across the Partner Network. DOJOMOJO is
      not responsible for errors or omissions in any advertising content provided by or
      on behalf of the Advertiser.</P>

      <P>3.2 DOJOMOJO will charge the Advertiser fees ("Booking
      Fees") on the basis of Campaign delivery. Campaign advertising is regarded as
      delivered when the customer engages with the Campaign on the Partner Network in
      the specified manner such as impressions, clicks or referrals generated.
      DOJOMOJO does not guarantee the performance of the campaign, the performance of
      referrals, or any minimum number of impressions, clicks or referrals.</P>

      <P>3.3 While all care is used to ensure Campaigns are targeted
      accurately, and transmitted without error, the accuracy of end-user responses
      ("Referrals") is outside of DOJOMOJO's control. In addition, no guarantee can
      be made as to the accuracy of any Referral. DOJOMOJO does not warrant or
      guarantee any aspect of Referral data.</P>

      <P>3.4 As the DOJOMOJO platform is a marketplace, there is no
      guarantee that your Campaign will get impressions or be displayed. You will be
      charged for your Campaign on the basis set out in an applicable Insertion Order
      or as specified by you using the Self-Service Portal.</P>

      <P>3.5 If you undertake a campaign where all or part of the
      fees are paid for by DOJOMOJO, these T's &amp; C's will also apply.
      Notwithstanding anything else in this agreement, during such instances the
      Services are provided on 'as is' basis without any warranty.</P>

      <H3>4. Payment &amp; Billing</H3>

      <P>4.1 All services provided by DOJOMOJO to the Advertiser will
      be processed immediately as determined by DOJOMOJO for the estimated purchase
      amount. DOJOMOJO reserves the right to render an interim invoice, or final
      invoice for any outstanding balance, and settle that invoice using a nominated
      credit card (or other payment method set out in Insertion Order or specified
      using the Self-Service Portal). DOJOMOJO may allocate monies received against
      the total amount outstanding to DOJOMOJO pursuant to all invoices.</P>

      <P>4.2 The DOJOMOJO platform will be used for calculation of
      all invoices.</P>

      <H3>5. Overdue Payments</H3>

      <P>5.1 Where any payment owing to DOJOMOJO by the Advertiser is
      overdue, DOJOMOJO may remove and suspend the placement of a Campaign, or elect
      to treat the failure to pay as a repudiation of these Terms &amp; Conditions.</P>

      <P>5.2 Without limiting DOJOMOJO's rights or remedies, DOJOMOJO
      reserves the right to charge interest on any overdue payments at the rate of 2%
      of the outstanding balance per month, or the maximum rate permitted by law,
      whichever is lower.</P>

      <H3>6. Cancellation</H3>

      <P>6.1 The Advertiser may cancel the placement of a Campaign
      without incurring a cancellation fee by cancelling the order in the Self-Service
      Portal before the Campaign's commencement date. </P>

      <P>6.2 The Advertiser may not cancel an advertising campaign
      after the commencement date of a Campaign.</P>

      <H3>7. Advertisers' Obligations</H3>

      <P>7.1 The Advertiser warrants that it is authorized to make
      available the entire Content and subject matter of the Campaign.</P>

      <P>7.2 The Advertiser acknowledges that: (a) it must comply
      with DOJOMOJO practices and policies notified to it from time to time; (b) any
      Personal Information or personal data collected from end-users and supplied via
      a Campaign on the Partner Network may not be disclosed, sold, rented,
      forwarded, made public or shared in any way with any other third party or used
      other than to facilitate the services that the user has consented to
      participate in or receive from you as specified in any Campaign Content.
      "Personal information" or "Personal data" means information about an individual
      that identifies them, or from which the person's identity can be reasonably
      determined. Examples of personal information include name, address, email
      address and phone number; (c) If the Advertiser has collected Personal
      Information from end-users prior to the Start Date specified on any Insertion
      Order or as specified in the Self-Service Portal (the "Pre-Start Date Ads"),
      the Advertiser represents and warrants that all data collected in connection
      with the Pre-Start Date Ads will be treated in all respects as though it were
      collected after the Start Date and will be governed by the terms of this
      Agreement; (d) DOJOMOJO may control the display of Campaigns on its Platform
      and &nbsp;Partner Network in its absolute discretion. This includes (but is not
      limited to) preventing the display of a Campaign where the Advertiser or
      Campaign is excluded by Partner Network partners on any exclusion list of
      companies or product verticals; (e) Systems or technological failure may impede
      or prevent access to all or any part of the Campaign or Partner Network from
      time to time and transmission of data over the Internet can be subject to
      errors and delays; (f) DOJOMOJO may modify the organization, structure, "look
      and feel" and other elements of the service provided across its Partner Network
      at its sole discretion at any time without prior notice.; (g) DOJOMOJO will
      provide relevant Campaign performance data in substance and form as determined
      by DOJOMOJO. The Advertiser must not distribute or disclose usage information
      to any third party without DOJOMOJO's prior written consent. DOJOMOJO makes no
      guarantees regarding the accuracy, reliability or completeness of any usage
      information provided to the Advertiser. (h) In respect of any software, tools,
      reporting or websites (including code or the use of any self-service portal)
      provided in connection with the Services, the Advertiser is granted a
      non-exclusive, non-sub licensable license for use of that software, tools,
      reporting or website. This license is for the sole purpose of enabling the
      Advertiser to use and enjoy the benefit of the Services as provided by
      DOJOMOJO. The software, tools, reporting or website may not be copied,
      modified, distributed, sold or leased. (i) Advertiser grants to DOJOMOJO a
      non-exclusive, perpetual, royalty free sublicense for DOJOMOJO to use all
      Advertiser Campaign content (including any and all intellectual property rights
      in that Campaign content) for the purposes of DOJOMOJO displaying the Campaign.</P>

      <H3>8. Termination, Suspension and Non-Availability</H3>

      <P>8.1 Without limiting its other rights, DOJOMOJO may, upon
      notice to the Advertiser, also immediately remove and suspend the placement of
      a Campaign without liability if: (a) the Advertiser enters into bankruptcy,
      liquidation, administration, receivership, a composition or arrangement with
      its creditors, has a receiver or manager appointed over all or any part of its
      assets or becomes or is deemed to become insolvent; (b) the Advertiser dies, or
      if a partnership is dissolved or an application to dissolve the partnership is
      filed, or if a company is wound up or an application for the company's winding
      up is filed; or (c) the Advertiser fails to comply with applicable privacy
      requirements set out in clause 13.</P>

      <P>8.2 Either party may terminate this agreement where the
      other party is in material breach of these Terms &amp; Conditions by providing
      written notice to the other party. A party will be considered to be in material
      breach where an administrator, liquidator or receiver is appointed to the
      party, or the party has committed a breach which cannot be rectified within 7
      days of notice in writing specifying the breach having been given by the party
      not in default.</P>

      <P>8.3 Where the Advertiser is in breach of this agreement, the
      Advertiser must pay all outstanding amounts to DOJOMOJO within 7 days of
      termination of the Agreement.</P>

      <H3>9. Limitation of Liability</H3>

      <P>9.1 This clause does not exclude or limit the application of
      any statute where to do so would: (a) contravene that statute; or (b) cause any
      part of these Terms &amp; Conditions to be void.</P>

      <P>9.2 Subject to 7.1, and as otherwise expressly set out in
      these T's &amp; C's, DOJOMOJO excludes all conditions and warranties regarding
      the services to be provided by DOJOMOJO the subject of these Terms &amp;
      Conditions.</P>

      <P>9.3 DOJOMOJO's liability to the Advertiser for: (a) breach
      of any express provision of these Terms &amp; Conditions; (b) breach of any
      implied condition or warranty; or (c) any loss or damage (including
      consequential loss or damage) which may be suffered, incurred or arise under
      these Terms &amp; Conditions is limited, at DOJOMOJO's option, to: (i) refunding
      the price of the services in respect of which the breach occurred; or (ii)
      providing those services again. Such liability will be limited to the total
      value of the charges incurred under any Insertion Order, or the amount paid by
      the customer in the (1) one month preceding the incident, whichever is less.</P>

      <P>9.4 DOJOMOJO specifically disclaims any warranty or
      representation regarding: (a) the number of persons who will access, 'accept',
      'click-through', convert, "open rates", 'contactability' of the Campaign; (b)
      any benefit the Advertiser might obtain from including the Campaign within the
      DOJOMOJO service; and (c) the functionality, performance or operation of the
      DOJOMOJO service with respect to the Campaign.</P>

      <H3>10. Indemnification</H3>

      <P>10.1 The Advertiser shall indemnify and hold harmless
      DOJOMOJO, any related bodies corporate, its directors, servants and agents both
      jointly and severally from and against any loss, expense, including reasonable
      attorney fees and expenses, damages, or liability reasonably incurred or
      suffered by DOJOMOJO, its directors, servants and agents arising from any
      claim, demand, action, suit or proceedings brought by a third party in relation
      to a Campaign, including but not limited to those: (a) relating to the content
      of a Campaign; (b) arising from any claim that the Advertiser's advertising
      infringing the intellectual property of any third party; (c) arising as a
      result of a breach of the Guidelines or AUP; (d) arising out of any material
      breach by the Advertiser of any duty, representation or warranty under these
      Terms &amp; Conditions; or (e) relating to any contaminated file, virus, work
      or Trojan horse originating from the Advertiser's Campaign or any linked areas.</P>

      <P>10.2 The Advertiser will notify DOJOMOJO promptly of any
      claim, action or demand which may give rise to the indemnity set out in clause
      10.1 and will cooperate reasonably with DOJOMOJO at the Advertiser's expense.
      The Advertiser will have the right to participate in any defense of a claim
      and/or be represented by counsel of its own choosing at its own expense.</P>

      <P>10.3 This clause will survive the termination or fulfillment
      of this contract.</P>

      <H3>11. Set-Off</H3>

      <P>11.1 DOJOMOJO may set-off monies received against any unpaid
      charges or fees under this or other contracts with the Advertiser.</P>

      <H3>12. Force Majeure</H3>

      <P>12.1 Without limiting these Terms &amp; Conditions, neither
      party will be liable for any delay in performance or breach of these Terms
      &amp; Conditions that arises as a result of a Force Majeure event.</P>

      <P>12.2 "Force Majeure" means any act of government of state,
      civil commotion, epidemic, fire, flood, natural disaster, war, or any event
      beyond the control of the party claiming to be excused from its obligations.</P>

      <P>12.3 Each party disclaims all liability and indemnification
      obligations for any harm or damages caused by any third party hosting
      providers.</P>

      <H3>13. Legislation and Compliance</H3>

      <P>13.1 The Advertiser must agree to observe DOJOMOJO's Privacy
      Policy and obligations. In particular but without limiting the foregoing, all
      or any data or other information which is supplied from time to time by
      DOJOMOJO to the Advertiser in relation to any end user or customer, is supplied
      to the specific Advertiser (as set out in the Self-Service Portal or Insertion
      Order) for the specific purpose, and for no other purpose. The use and
      disclosure of such data will be subject to such limitations for the use and
      disclosure of such data as apply at the time of collection of such data. It is
      the Advertiser's responsibility to ensure that any use, disclosure,
      transmission or storage of customer data is in compliance with the privacy,
      spam, do not call, data protection, telemarketing and other applicable laws
      regulations or other obligations in each territory or territories in which they
      operate and in which personal information is originally collected.</P>

      <P>13.2 DOJOMOJO warrants that it will collect and use data in
      accordance with the applicable privacy, spam and data protection legislation
      applicable in the jurisdictions in which the specific data is collected. </P>

      <P>13.3 In respect of any data or other information which is
      supplied from time to time by DOJOMOJO to the Advertiser, the Advertiser agrees
      to observe the obligations contained in applicable privacy, spam, data
      protection, telemarketing, do-not-call or other applicable legislation in the
      jurisdictions in which they operate and in which personal information is
      originally collected. This shall include (but not be limited to) the
      Advertiser: (a) (Consent): sending electronic messages only with the customer's
      consent; (b) (Identify): including clear and accurate information in electronic
      messages about the person or business that is responsible for sending the
      message; (c) (Storage): taking reasonable steps to protect the personal
      information and data from unauthorized access, misuse, modification or
      disclosure; (d) (Access): allowing users to access and correct to their
      personal data in accordance with applicable laws; (e) (Unsubscribe): including
      a facility in each electronic message allowing customers to unsubscribe to
      future electronic messages. Once a customer has unsubscribed to electronic
      messages from the Advertiser, the Advertiser will promptly remove the customer
      and will not send any future electronic messages to the customer; (f)
      (Complaints) maintaining a complaint management process for the handling of any
      privacy complaints received, in accordance with any applicable laws.</P>

      <P>13.4 Where the Advertiser provides data (including any
      personally identifiable information) to DOJOMOJO during the term, the
      Advertiser warrants that it is authorized to disclose that information to
      DOJOMOJO in accordance with the Advertiser's own privacy policy and applicable
      privacy and data protection laws. </P>

      <H3>14. Confidential Information</H3>

      <P>14.1 Neither party will use or disclose any Confidential
      Information of a party that is disclosed to the other party during the course
      of this contract. The parties agree that such information will only be used for
      the purposes of this contract and for no other purposes. This clause will
      survive the termination or fulfillment of this contract. Confidential
      Information includes all data and other information which is confidential to a
      party but which is not in the public domain other than as a result of a breach
      by a party of its obligations under this clause.</P>

      <P>14.2 Where the Advertiser provides data to DOJOMOJO during
      the term, DOJOMOJO will treat this information as Confidential Information and
      will only be used for the purposes of this contract and for no other purpose.
      DOJOMOJO will treat the information in accordance with its strict information
      security practices and Privacy Policy.</P>

      <H3>15. Disclaimers</H3>

      <P>15.1 Except as expressly provided herein, neither party
      makes any warranty of any kind, whether express, implied, statutory or
      otherwise, and each party specifically disclaims all implied warranties,
      including any implied warranty of merchantability, fitness for a particular
      purpose or noninfringement, to the maximum extent permitted by applicable law.</P>

      <H3>16. General</H3>

      <P>16.1 You may elect to receive DOJOMOJO marketing materials
      that we send from time to time. You also may unsubscribe from these materials
      at any time. By agreeing to these T's and C'S , You also acknowledge that
      Advertiser will receive certain service, billing and administrative messages to
      your nominated email or other address that may not be unsubscribed from. </P>

      <P>16.2 DOJOMOJO may modify these T's and C's or any additional
      terms that apply to the Services to, for example, reflect changes to the
      Services or changes to the law. You should look at the terms regularly.
      DOJOMOJO will post notice of modifications to these terms on the Self-Service
      Portal or policies referenced in these terms at applicable URLs for such
      policies. Changes will not apply retroactively and will become effective no
      sooner than 7 days after they are posted. If You do not agree to the modified
      terms for the Services, You should discontinue Your use of the Services. No
      amendment to or modification of this Agreement will be binding unless (i) in
      writing and signed by a duly authorized representative of DOJOMOJO (in which
      case those terms shall prevail to the extent of any inconsistency), (ii) You
      accept updated terms online, or (iii) You continue to use the Service after
      DOJOMOJO has posted updates to this agreement or to any policy governing the
      Service.</P>

      <P>16.3 No delay or failure by DOJOMOJO to enforce any
      provision of these Terms &amp; Conditions will be deemed to be a waiver, create
      a precedent or prejudice DOJOMOJO's rights.</P>

      <P>16.4 A notice may be sent by e-mail, prepaid post or
      facsimile to the Advertiser's last known address as set out in an Insertion
      Order or specified on the Self-Service Portal.</P>

      <P>16.5 The Advertiser may not assign this Agreement without the
      DOJOMOJO's written consent.</P>

      <P>16.6 The parties to these Terms &amp; Conditions are
      independent contractors. Neither party is an agent, employee, fiduciary,
      authorized representative, franchise, joint venture, or partner of the other
      party. </P>

      <P>16.7 These Terms &amp; Conditions (together with any
      Insertion Order), and all other documents referred to in them, comprise the
      entire contract between the parties. It supersedes all prior understandings,
      agreements or representations.</P>

      <P>16.8 These Terms &amp; Conditions are governed by laws set
      out in New York and controlling United States federal law. </P>

      <P>16.9 If a dispute arises between the parties, the parties
      agree to give the other party written notice specifying the nature of the
      dispute. Prior to commencing proceedings in relation to such breach the parties
      agree to engage in mediation in New York, New York.</P>
    </div>
  </div>

export default CTAPurchaserTerms
