import React from 'react'
import { string, bool } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { Small, Tiny } from 'visual-components/util/texts'
import LinkMessageTheme from 'css/themes/media-market/LinkMessageTheme.css'

class PlainTextOfferMessage extends React.Component {
  static propTypes = {
    image: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    link: string.isRequired,
    linkDomain: string.isRequired,
    isSender: bool.isRequired,
  }

  render() {
    const {
      isSender,
      image,
      title,
      description,
      link,
      linkDomain,
    } = this.props

    return (
      <Card className={`${LinkMessageTheme.card} ${isSender ? LinkMessageTheme.sender : LinkMessageTheme.receiver}`}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className={LinkMessageTheme.rightContainer}>
            <div
              className={LinkMessageTheme.imageHolder}
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
            <div className={LinkMessageTheme.contentHolder}>
              <div className={LinkMessageTheme.site}>
                <Small azure>
                  <em>
                    {linkDomain}
                  </em>
                </Small>
              </div>
              <div className={LinkMessageTheme.title}>
                <Small>
                  <em>
                    {title}
                  </em>
                </Small>
              </div>
              <div className={LinkMessageTheme.description}>
                <Tiny multiline>
                  {description}
                </Tiny>
              </div>
            </div>
          </div>
        </a>
      </Card>
    )
  }
}

export default PlainTextOfferMessage
