import React from 'react';
import _ from 'lodash'

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import Card from 'react-toolbox/lib/card';
import SearchCard from 'visual-components/shared/search-card';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import SearchResultItem from 'visual-components/messaging/search-result-item';
import COLOURS from 'util/colours';
import MessageResponse from 'visual-components/messaging/messaging-response'
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';

import * as ReactRedux from 'react-redux';

class NewMessageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
    }
    this.updateSuggestions = this.updateSuggestions.bind(this)
    this.applySearch = _.debounce(this.applySearch.bind(this), 500);
    this.setBrand = this.setBrand.bind(this);
  }

  updateSuggestions(newValue) {
    this.setState({searchValue: newValue});
    this.applySearch(newValue);
  }

  applySearch(value) {
    if (value.length != 0) {
      MessagingDispatcher.searchForBrands(value, 'normal')
    } else {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'conversations',
        data: {
          showSearchResults: false
        }
      })
    }
  }

  setBrand(brandId, brandName) {
    this.setState({
      searchValue: brandName
    })

    PricingDispatcher.check('messaging.message.create', function () {
      store.dispatch({
        model: 'conversations',
        type: 'MESSAGE_BRAND',
        data: {
          conversationBrandId: brandId,
          conversationBrandName: brandName
        }
      })
    }, brandId)
  }


  render() {
    let { showSearchResults, searchResults, newConversationThread, newConversationBrandId, newConversationBrandName, inputRef } = this.props

    let resultItems = _.map(searchResults, (item) => {
      return (
        <SearchResultItem
          brand = {item._source}
          choose ={ ()=>{ this.setBrand(item._source.id, item._source.accountname)}  } />
      )
    })

    return (
      <div style={{
        marginBottom: '24px',
        position: 'relative'
      }}>
        <SearchCard
          placeholder={'Which brand do you want to talk to?'}
          value={ this.state.searchValue }
          onChange={ this.updateSuggestions }
          inputRef = { inputRef }
          />
        { showSearchResults ?
          <Card style={{
            padding: '0px',
            listStyleType: 'none',
            position: 'absolute',
            background: 'white',
            zIndex: '100',
            marginTop: '0px',
            width: '100%',
            borderTop: `1px solid ${ COLOURS.lightGrey }`
          }}>
             { resultItems }
          </Card>
          : null
        }
        { newConversationThread ?
          <div>
            <Card style={{
              marginTop: '16px'
            }}>
              <Row style={{
                background: COLOURS.azure,
                minHeight: '40px'
              }}>
                <Col xs={11}>
                  <p style={{
                    marginTop: '15px',
                    color: '#fff',
                    fontFamily: 'Larsseit-Medium',
                    fontSize: '14px',
                    paddingLeft: '12px'
                  }}>
                    New message to {newConversationBrandName}
                  </p>
                </Col>
                <Col xs={1} style={{
                  textAlign: 'right'
                }}>
                  <img src="/images/icons/messaging-icons/close-x-icon-white.svg" onClick={()=>{store.dispatch({
                      model: 'conversations',
                      type: 'UPDATE_ATTR',
                      data: {
                        newConversationThread: false,
                        newConversationBrandId: null
                      }
                    })}}
                    style={{
                      marginTop: '12px',
                      marginBottom: '12px',
                      marginRight: '12px',
                      cursor: 'pointer',
                      display: 'inline-block'
                  }}/>
                </Col>
              </Row>
              <MessageResponse
                conversationId = {'newBrandConversation'}/>
            </Card>
          </div>: null
        }

      </div>
    )
  }
}

const mapState = (state) => {
  return {
    showSearchResults: state.conversations.showSearchResults,
    searchResults: state.conversations.searchResults,
    newConversationThread: state.conversations.newConversationThread,
    newConversationBrandId: state.conversations.newConversationBrandId,
    newConversationBrandName: state.conversations.newConversationBrandName,
  }
}
export default ReactRedux.connect(mapState)(NewMessageContainer)
