import React, { PureComponent } from 'react'
import d3 from 'd3'
import PropTypes from 'prop-types'
import { H3, P } from 'visual-components/util/texts'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'
import { Card } from 'react-toolbox/lib/card'
import { Input } from 'react-toolbox/lib/input'
import { Slider } from 'react-toolbox/lib/slider'
import Theme from 'css/themes/SliderCard.css'

const to2DecimalPlaces = val => +d3.format('.2f')(Math.round(val * 1000) / 1000)

class SliderCard extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    formatter: PropTypes.func,
    tooltip: PropTypes.string,
    decimal: PropTypes.bool,
  }

  static defaultProps = {
    title: 'Slider Value',
    defaultValue: 0,
    value: 0,
    decimal: false,
    formatter(val) { return val },
    onChange() {},
    tooltip: 'this is my tooltip',
  }

  state = {
    value: 0.4,
    inputValue: 0.4,
    isEditing: false,
  }

  setValue = value => {
    const { min, max, defaultValue } = this.props

    if (typeof value !== 'number') value = defaultValue

    if (value < min) value = min
    else if (value > max) value = max

    this.setState({ inputValue: value, value })
  }

  componentDidUpdate = () => {
    if (this.props.value !== this.state.value) {
      this.setValue(this.props.value)
    }
  }

  onChange = value => {
    const { onChange, decimal } = this.props

    let newValue = value

    if (decimal) newValue = to2DecimalPlaces(newValue)
    else newValue = Math.round(value)

    return this.setState({ value: newValue, inputValue: newValue }, () => onChange(newValue))
  }

  onInputChange = inputValue => this.setState({ inputValue })

  onEdit = isEditing => () => {
    if (!isEditing) {
      const { inputValue, value } = this.state
      const {
        min,
        max,
        onChange,
        decimal,
      } = this.props

      let newVal = Number(inputValue)

      if (isNaN(newVal)) return this.setState({ inputValue: value, isEditing })

      if (decimal) newVal = to2DecimalPlaces(newVal)
      else newVal = Math.round(newVal)

      if (newVal < min || value === '') newVal = min
      else if (newVal > max) newVal = max

      return this.setState({ value: newVal, inputValue: newVal, isEditing }, () => onChange(newVal))
    }

    return this.setState({ isEditing })
  }

  componentDidMount = () => {
    const { value } = this.props

    this.setValue(value)
  }

  render() {
    const { value, inputValue, isEditing } = this.state
    const {
      tooltip,
      title,
      min,
      max,
      formatter,
    } = this.props

    return (
      <div className="slider-card">
        <Card theme={Theme}>
          <div className={Theme.topContent}>
            <div style={{ display: 'flex' }}>
              <H3>
                <small>{ title }</small>
              </H3>
              {
                tooltip
                  ? (
                    <div style={{ display: 'inline-block', paddingTop: '2px', paddingLeft: '8px' }}>
                      <BlueInfoTooltip text={tooltip} />
                    </div>
                  ) : null
              }
            </div>
            <div>
              <Input
                onBlur={this.onEdit(false)}
                onFocus={this.onEdit(true)}
                onChange={this.onInputChange}
                theme={Theme}
                value={isEditing ? inputValue : formatter(inputValue)}
              />
            </div>
          </div>
          <Slider theme={Theme} onChange={this.onChange} value={value} min={min} max={max} />
          <div className={Theme.bottomContent}>
            <P>{ formatter(min) }</P>
            <P>{ formatter(max) }</P>
          </div>
        </Card>
      </div>
    )
  }
}

export default SliderCard
