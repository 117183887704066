import React from 'react';

/* basics */
import Basics from 'campaigns/builder/basics/basics';

/* customize */
import SelectTemplate from 'campaigns/builder/customize/select-template';
import EditContent from 'campaigns/builder/customize/edit-content';
import TermsContainer from 'campaigns/builder/customize/terms/terms-container';

/* page hosting */
import PageHosting from 'campaigns/builder/page_hosting/page-hosting';

/* partners */
import Partners from 'campaigns/builder/partners/partners';
import AgreementContainer from 'campaigns/builder/partners/agreement/agreement-container';

export default [
  {
    key: 'setup',
    /* leaving this one until we have a chance to root out all instances of /setup */
    component: Basics
  },
  {
    key: 'customize',
    subSteps: [
      {
        key: 'select-template',
        component: SelectTemplate,
        host: true
      },
      {
        key: 'edit-content',
        component: EditContent,
        host: true
      },
      {
        key: 'terms',
        component: TermsContainer,
        host: true
      }
    ]
  },
  {
    key: 'page-hosting',
    component: PageHosting,
    host: true
  },
  {
    key: 'partner',
    partnerships: true,
    subSteps: [
      {
        key: 'partner',
        component: Partners,
        partnerships: true,
      },
      {
        key: 'partnership-agreement',
        component: AgreementContainer,
        host: true,
        partnerships: true,
      },
    ]
  }
]
