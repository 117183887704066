import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  SimpleForm, Input, Select, Checkbox, Validations,
} from 'visual-components/util/form'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import { H3, H4 } from 'visual-components/util/texts'
import CcpaTheme from 'css/themes/ccpa-request.css'
import * as DataDisclosureActions from 'actions/data-disclosure-actions'

const mapDispatchToProps = dispatch => ({
  dataDisclosureActions: bindActionCreators(DataDisclosureActions, dispatch),
})

class CcpaRequest extends Component {
  static propTypes = {
    dataDisclosureActions: PropTypes.object.isRequired,
  }

  state = {
    regulation: 'ccpa',
    email: '',
    type: '',
    agree: false,
    recaptcha: false,
    complete: false,
  }

  onUpdate = ({ ...rest }) => {
    this.setState(rest)
  }

  onSubmit = () => {
    const { dataDisclosureActions } = this.props
    dataDisclosureActions.submit(this.state)
    this.setState({
      complete: true,
    })
  }

  onClose = () => {
    window.location = '/home'
  }

  recaptcha = value => {
    this.setState({
      recaptcha: value,
    })
  }

  render() {
    const { recaptcha, complete } = this.state

    const options = [{
      label: 'Request an account of your stored personal information',
      value: 'request',
    }, {
      label: 'Opt-out of sales of your personal information',
      value: 'opt-out',
    }, {
      label: 'Delete your personal information',
      value: 'delete',
    }]

    return (
      <>
        <Helmet>
          <title>DojoMojo - CCPA Data Request Form</title>
        </Helmet>
        <div className={CcpaTheme.header}>
          <img src="/images/icons/close.svg" onClick={this.onClose} />
          <H3>
            CCPA Data Request
          </H3>
        </div>
        <div className={CcpaTheme.content}>
          <div className={CcpaTheme.copy}>
            {complete && (
              <H3>
                Thank You
              </H3>
            )}
            <H4 multiline>
              {complete
                ? 'Your CCPA Data Request has been received. Further instructions have been sent to the email address provided.'
                : 'If you are a California resident, you can use the form below to make your data request. Proof of identity and residence will be required.'}
            </H4>
            <H4 multiline>
              {complete
                ? 'For further assistance, please call 866-I-OPT-OUT (use company code 226).'
                : 'If you are a legal guardian and are making a request on behalf of a ward, please call 866-I-OPT-OUT (use company code 226) for further assitance.'}
            </H4>
          </div>
          <div className={CcpaTheme.form}>
            {complete ? (
              <ModalButtonGroup
                confirm={this.onClose}
                confirmText="Close"
                canSave
                hideLine
              />
            ) : (
              <SimpleForm
                model={this.state}
                updateModel={data => {
                  if (!data.recaptcha && recaptcha) return
                  this.onUpdate(data)
                }}
                handleSubmit={this.onSubmit}
              >
                <Input
                  name="email"
                  label="Email"
                  placeholder="name@dojomojo.com"
                  validations={[Validations.isEmail]}
                  required
                />
                <Select
                  name="type"
                  label="What Is the Nature of Your Request?"
                  options={options}
                  required
                />
                <Checkbox
                  name="agree"
                  label="Yes, I can provide proof of my identity and residence in California in the last 12 months."
                  boldLabel
                  required
                />
                <div className={CcpaTheme.recaptcha}>
                  <ReCAPTCHA
                    sitekey="6LfwyskUAAAAAI97wmr7cZWconvQ4PlyHRJlyUu0"
                    onChange={this.recaptcha}
                  />
                </div>
                <ModalButtonGroupWithContext
                  confirmText="Submit Request"
                  cancel={this.onClose}
                  canSave={!!recaptcha}
                  hideLine
                />
              </SimpleForm>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default connect(null, mapDispatchToProps)(CcpaRequest)
