import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom'
import { object } from 'prop-types'
import { H2 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import HeaderNav from 'visual-components/mediaMarket/HeaderNav'
import DealAnalytics from 'visual-components/mediaMarket/analytics/DealAnalytics'
import DealContentIntegration from 'visual-components/mediaMarket/contentIntegration/ContentIntegration'
import DealCollaborate from 'visual-components/mediaMarket/collaborate/DealCollaborate'
import DealTrackingLinks from 'visual-components/mediaMarket/tracking/DealTrackingLinks'
import Spinner from 'util/components/spinner'
/*import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'*/
import OverviewTheme from 'css/themes/Overview.css'
import * as DealActions from 'actions/deal-actions'
import DealCollaborateAssets from '../mediaMarket/collaborate/DealCollaborateAssets'
import MediaDealChangeDatesModal from '../mediaMarket/MediaDealChangeDatesModal'

const mapStateToProps = ({ deals, currentBrand }) => ({
  deals,
  isBuyingBrand: deals.deal.buying_brand_id === currentBrand.id,
})

const mapDispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
})

class MediaMarketDealAnalytics extends Component {
  static propTypes = {
    deals: object.isRequired,
    dealActions: object.isRequired,
    match: object.isRequired,
  }

  componentDidMount() {
    const {
      match: { params: { dealId } },
      dealActions: { loadById },
    } = this.props

    loadById(dealId)
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { dealId: previousDealId } } } = prevProps
    const { match: { params: { dealId } }, dealActions: { loadById } } = this.props

    if (previousDealId !== dealId) {
      loadById(dealId)
    }
  }

  // TODO: this will fix duplicating links issue but currently breaks Proposal Editing
  // componentWillUnmount() {
  //   const { dealActions: { clearDeal } } = this.props
  //   clearDeal()
  // }

  render() {
    const {
      match: { path: prefix, url: prefixedUrl },
      deals: { deal, loading },
      isBuyingBrand,
    } = this.props

    if (loading || !deal || !Object.keys(deal).length) {
      return (
        <Spinner />
      )
    }
    
    const dealLabelAppend = deal.status === 'request'
      ? ` From ${isBuyingBrand ? 'You' : deal.buying_brand.accountname}`
      : ''
      

    return (
      <div>
        <div className={`${OverviewTheme.overviewContainer} ${OverviewTheme.overviewContainerNav}`}>
          <div className={OverviewTheme.overviewWrapper}>
            <Grid>
              <Row
                style={{
                  marginBottom: '24px',
                }}
              >
                <Col xs={10}>
                  <div
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    <H2 azure>
                      {deal && deal.label}{dealLabelAppend}
                    </H2>
                  </div>
                </Col>
                <Col xs={2}>
                  {/*<div className={ 'text-right' }>
                    <Button
                      onClick={ () => {} }
                      label={ 'Export CSV' }
                      className={ ButtonTheme.greenButton }
                      theme={ ButtonTheme }
                      primary
                      raised
                    />
                  </div>*/}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Route
                    path={`${prefix}/:selected`}
                    render={({ match }) => (
                      <HeaderNav
                        prefixedUrl={prefixedUrl}
                        selected={match.params.selected}
                        dealStatus={deal.status}
                        dealUnreadCount={deal.unread_count}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <div className={OverviewTheme.overviewContainer}>
          <div className={OverviewTheme.overviewWrapper}>
            <Switch>
              <Route exact path={`${prefix}/collaborate`} component={DealCollaborate} />
              <Route path={`${prefix}/collaborate/:assetId`} component={DealCollaborateAssets} />
              <Route path={`${prefix}/analytics`} component={DealAnalytics} />
              <Route path={`${prefix}/content-integration/:key?`} component={DealContentIntegration} />
              <Route path={`${prefix}/tracking-links`} component={DealTrackingLinks} />
              <Redirect to="/404" />
            </Switch>
          </div>
        </div>
        <MediaDealChangeDatesModal />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaMarketDealAnalytics))
