import React, { Component } from 'react'
import {
  number,
  object,
  func,
  string,
} from 'prop-types'
import InputRange from 'react-input-range'
import { P, Smaller } from 'visual-components/util/texts'
import d3 from 'd3'

class RangeSlider extends Component {
  static propTypes = {
    maxValue: number.isRequired,
    minValue: number.isRequired,
    extraStartText: string,
    extraEndText: string,
    secondaryStartText: string,
    name: string.isRequired,
    label: string,
    format: func,
    paddingBottom: string,
  }

  static defaultProps = {
    extraStartText: '',
    extraEndText: '',
    secondaryStartText: '',
    label: '',
    paddingBottom: null,
    format: num => d3.format(',')(num),
  }

  static contextTypes = {
    handleChange: func.isRequired,
    handleChangeImmediately: func.isRequired,
    searchModel: object.isRequired,
  }

  _change = (changeFn, value) => {
    const { name } = this.props

    if (typeof value === 'number') {
      return changeFn(name, { value })
    }

    const { min, max } = value
    const { maxValue, minValue } = this.props

    return changeFn(name, {
      value,
      atMax: max >= maxValue,
      atMin: min <= minValue,
    })
  }

  onChange = value => this._change(this.context.handleChange, value)

  onChangeComplete = value => this._change(this.context.handleChangeImmediately, value)

  render() {
    const {
      maxValue,
      minValue,
      format,
      extraStartText,
      extraEndText,
      secondaryStartText,
      name,
      label,
      paddingBottom,
    } = this.props

    const { searchModel } = this.context

    const { value, atMax } = searchModel[name]

    if (typeof value === 'object') {
      value.max = value.max || maxValue
    }

    // even steps based on range
    const step = maxValue < 2.5
      ? 0.1
      : maxValue < 5
        ? 0.5
        : maxValue < 100
          ? 1
          : maxValue < 1000
            ? 10
            : maxValue < 100000
              ? 100
              : maxValue < 1000000
                ? 1000
                : maxValue < 10000000
                  ? 10000
                  : maxValue < 100000000 ? 100000 : 1000000

    return (
      <div style={paddingBottom && { paddingBottom }}>
        <div style={{ paddingBottom: '8px' }}>
          {
            label ? (
              <div style={{ paddingBottom: '12px' }}>
                <Smaller>{label}</Smaller>
              </div>
            ) : null
          }
          <P>
            {
              `${extraStartText}
              ${
                typeof value === 'object'
                  ? `${format(value.min)}-${secondaryStartText}${format(value.max)}`
                  : format(value)
              }
              ${atMax ? '+' : ''}${extraEndText}`
            }
          </P>
        </div>
        <div style={{ paddingRight: '7px', paddingLeft: '7px' }}>
          <InputRange
            step={step}
            maxValue={maxValue}
            minValue={minValue}
            value={value}
            onChange={this.onChange}
            onChangeComplete={this.onChangeComplete}
          />
        </div>
      </div>
    )
  }
}

export default RangeSlider
