import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash'
import ColorPicker from 'rc-color-picker';
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'

const simpleColorTransitions = {
  colorPicker: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  colorPickerLabel: {
    fontSize: '11px',
    fontFamily: 'Larsseit-Medium',
    lineHeight: '11px',
    color: COLOURS.ink,
  },
  picker: {
    display: 'flex',
    marginTop: '8px',
    '& span': {
      border: `2px solid ${COLOURS.cloudGrey}`,
      borderRadius: '50%',
    },
  },
}

let SimpleColorPicker = createReactClass({
  displayName: 'SimpleColorPicker',

  getInitialState: function(){
    if (this.props.value) return this.props.value

    var obj = _.merge({}, {
      color: {
        color: '#00000',
        alpha: 100
      }
    }, this.props.model[this.props.attr]);
    return obj;
  },

  componentWillReceiveProps: function(nextProps){
    if (!this.props.model) return
    if(_.has(nextProps, 'styles.'+this.props.attr+'.fontColor')) {
      this.setState({currentColor: nextProps.styles[this.props.attr].fontColor})
    }
    else if (_.has(nextProps, `model.${this.props.attr}.color.color`)) {
      this.setState({color: nextProps.model[this.props.attr].color})
    }
  },

  handleChangeComplete: _.debounce(function(color) {
    if (!this.props.model) return
    var data = {
      fonts: {}
    };
    data.fonts[this.props.attr] = {
      color: color
    };
    this.props.updateAttr('styles', data);
  }, 500, this),

  setCurrentColor: function(color) {
    const { onChange = () => {} } = this.props
    this.handleChangeComplete(color);
    this.setState({color: color}, () => onChange(color))
  },

  render: function(){
    const { label, colorPosition, classes: css, customStyleClass, disableAlpha } = this.props
    const { color, alpha } = this.state.color

    return (
      <div className={`${css.colorPicker} ${customStyleClass}`}>
        <div className={css.colorPickerLabel}>
          {label}
        </div>
          <ColorPicker
            animation="slide-up"
            onClose={this.handleChangeComplete}
            onChange={this.setCurrentColor}
            color={color}
            alpha={alpha}
            placement="topRight"
            className={css.picker}
            enableAlpha = { !disableAlpha }
          />
      </div>
    )
  },
})

export default injectSheet(simpleColorTransitions)(SimpleColorPicker)
