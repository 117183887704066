import React from 'react'
import propTypes from 'prop-types'
import { H6 } from 'visual-components/util/texts'
import injectSheet from 'react-jss'

const subHeaderTransitions = {
  styles: props => props.style,
  heading: {
    display: 'inline',
  },
}

class SubHeader extends React.Component {
  static propTypes = {
    text: propTypes.string.isRequired,
    classes: propTypes.object.isRequired,
    style: propTypes.object,
  }

  static defaultProps = {
    style: {
      display: 'inline',
      lineHeight: '14px',
      marginRight: '8px',
    },
  }

  render() {
    const { text, classes: css } = this.props
    return (
      <div
        className={css.styles}
      >
        <H6 style={{ display: 'inline' }} ink>{text}</H6>
      </div>
    )
  }
}

export default injectSheet(subHeaderTransitions)(SubHeader)
