import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import InputContainer from 'visual-components/util/InputContainer'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import { H4 } from 'visual-components/util/texts'
import moment from 'moment-timezone'

import * as PartnerActions from 'actions/campaign-partner-actions'
import * as MetricsActions from 'actions/analytics-actions'

import inputTheme from 'css/themes/Input.css'

const stateToProps = ({ currentCampaign }) => ({ currentCampaign })

const dispatchToProps = dispatch => ({
  partnerActions: bindActionCreators(PartnerActions, dispatch),
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

const getPartnerIds = ({ id, invites = [] }) => {
  const partners = []

  for (let i = 0; i < invites.length; i++) {
    const invite = invites[i]

    if (invite.invitee_id !== id) partners.push(invite.invitee_id)
  }

  return partners
}

class CampaignDateChangeReminderModal extends PureComponent {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    partnerActions: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    setOldDates: PropTypes.func.isRequired,
    setNewDates: PropTypes.func.isRequired,
  }

  state = {
    text: '',
  }

  componentDidMount() {
    this.setDefaultText()
  }

  componentDidUpdate(prevProps) {
    const { currentCampaign } = this.props

    const shouldUpdateText = moment(prevProps.currentCampaign.startDate).diff(currentCampaign.startDate, 'days')
    || moment(prevProps.currentCampaign.endDate).diff(currentCampaign.endDate, 'days')

    if (shouldUpdateText) this.setDefaultText()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  setDefaultText = () => {
    const { currentCampaign } = this.props
    const { endDate, startDate, name } = currentCampaign

    const text = `This is a campaign announcement to inform you that ${name} has been changed to start on ${moment(startDate).format('MM/DD/YYYY')} and end on ${moment(endDate).format('MM/DD/YYYY')}.

If you have any questions, please message the host.`

    return this.setState({ text })
  }

  onUpdate = ({ target }) => this.setState({ text: target.value })

  onSendAnnouncement = () => {
    const { partnerActions, currentCampaign, metricsActions, setNewDates } = this.props
    const { text } = this.state

    // Taking metrics of invitees being notified that campaign dates have changed
    metricsActions.create('campaignAnnouncement', {
      object: 'Campaign Host Did Notify Users About Date Changes',
      meta: {
        campaignId: currentCampaign.id,
        host: currentCampaign.hostBrandId,
        sent: true,
        partners: getPartnerIds(currentCampaign),
      },
    })

    setNewDates()

    partnerActions.sendAnnouncement(currentCampaign.id, 'General Update', text)

    CampaignDispatcher.showAnnouncementSentOnCampaingDateChangeModal(true)
    CampaignDispatcher.toggleCampaignDateChangeModal()
  }

  onCancel = () => {
    const { currentCampaign, metricsActions, setNewDates } = this.props

    // Taking metrics of invitees not being notified that campaign dates have changed
    metricsActions.create('campaignAnnouncement', {
      object: 'Campaign Host Did Notify Users About Date Changes',
      meta: {
        campaignId: currentCampaign.id,
        host: currentCampaign.hostBrandId,
        sent: false,
        partners: getPartnerIds(currentCampaign),
      },
    })

    setNewDates()

    CampaignDispatcher.toggleCampaignDateChangeModal()
  }

  render() {
    const { currentCampaign } = this.props
    const { text } = this.state
    const canSave = text.length > 0

    const { showCampaignDateChangeModal, name } = currentCampaign

    return (
      <MultiTaskModal
        active={showCampaignDateChangeModal}
        close={this.onCancel}
        title="Campaign Date Change"
      >
        <div style={{ marginBottom: '24px' }}>
          <H4 multiline>
            Would you like to notify the current partners about the changes made to the start and end dates of the&nbsp;
            <strong>{name}</strong>
            &nbsp;campaign?
          </H4>
        </div>
        <div style={{ marginBottom: '24px' }}>
          <InputContainer label="Message To Partners">
            <div className={inputTheme.textarea}>
              <textarea
                style={{ minHeight: '108px', height: 'auto' }}
                onChange={this.onUpdate}
                value={text}
              />
            </div>
          </InputContainer>
        </div>
        <ModalButtonGroup
          canSave={canSave}
          confirm={this.onSendAnnouncement}
          cancel={this.onCancel}
          confirmText="Send Announcement"
          cancelText="No, Thanks"
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(CampaignDateChangeReminderModal)
