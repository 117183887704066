import React from 'react'
import ReactDOM from 'react-dom'
import Tooltip from 'util/components/tooltip'
import { H6 } from 'visual-components/util/texts'
// import $ from 'jqu'

class Label extends React.Component {
  componentDidMount() {
    // Make sure $ is loaded (1 sec is enough I think :\)
    this.timeout = setTimeout(() => {
      if ($(ReactDOM.findDOMNode(this)).data('toggle') === 'tooltip') {
        $(ReactDOM.findDOMNode(this)).tooltip()
      }
    }, 1000)
  }

  componentWillUnmount() {
    // Clear timeout
    clearTimeout(this.timeout)
  }

  render() {
    const {
      model, attrName, tooltip, labelId, label,
    } = this.props

    let required = false

    if (model) {
      if ((typeof (model.validations) === 'object') && (model.validations[attrName])) {
        if (model.validations[attrName].indexOf('required') !== -1) {
          required = true
        }
      }
    }

    const common = {
      htmlFor: attrName,
      id: labelId,
    }

    const tooltipComponent = (
      <div {...(tooltip && common)} style={{ display: tooltip ? 'inline-block' : 'block', textAlign: 'left' }}>
        <H6>
          { label }
          { label && required
            ? <span>*</span>
            : null
          }
        </H6>
      </div>
    )

    if (tooltip) {
      return (
        <div data-toggle="tooltip" data-placement="top" {...common}>
          {tooltipComponent}
          <Tooltip title={tooltip} styles={{ marginLeft: '10px', display: 'inline' }} />
        </div>
      )
    }

    return tooltipComponent
  }
}

export default Label
