import React, { Component } from 'react'
import { array } from 'prop-types'
import { Small } from 'visual-components/util/texts'
import AssetsTheme from 'css/themes/media-market/Assets.css'
import LinkWrapper from './link-wrapper'

class AssetsLinksWindow extends Component {
  static propTypes = {
    uniqueLinks: array.isRequired,
  }

  render() {
    const {
      uniqueLinks,
    } = this.props

    return (
      <div className={`${AssetsTheme.windowContainer} ${AssetsTheme.filesWindow}`}>
        { uniqueLinks && uniqueLinks.length
          ? uniqueLinks.map(link => <LinkWrapper key={link.id} {...link} />)
          : (
            <div className={AssetsTheme.emptyState}>
              <Small multiline cloudGrey>
                No Links Available.
              </Small>
            </div>
          )
        }
      </div>
    )
  }
}

export default AssetsLinksWindow
