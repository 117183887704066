import React from 'react'
import COLOURS from 'util/colours'
import BrandCardLogo from './brand-card-logo'

const style = {
  minWidth: '240px',
  maxWidth: '270px',
}

 class BrandCardContent extends React.Component{
  constructor(props){
    super(props);
    this.cardFunction = this.cardFunction.bind(this);
  }

  cardFunction() {
    if (this.props.cardFunction) {
      this.props.cardFunction(this.props.brand);
    }
  }

  render() {
    var brand = this.props.brand;
    var logo = brand.logo;
    var styles = this.props.cardFunction ? {
        cursor: 'pointer'
      } : {};

    const brandAccountname = brand.accountname ? brand.accountname.toUpperCase() : '';

    return (
      <div
        onClick={this.cardFunction}
        className="text-center col-xs-6 col-md-3 brand-card-content"
        style={style}>
        <BrandCardLogo
          { ...this.props }
          styles={styles}
          cardBrand={ brand }
          cardLogo={ logo }
          upcaseName={ brandAccountname } />
      </div>
    );
  }
}

export default BrandCardContent
