import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { Row, Col } from 'react-flexbox-grid'
import { H1, H6, P } from 'visual-components/util/texts'

import { makeNumbersPretty } from 'util/makeNumbersPretty'

import ButtonTheme from 'css/themes/Buttons.css'
import * as MetricsActions from 'actions/analytics-actions'
import overViewBarStyles from './overview-bar.css'

const { overviewBar } = overViewBarStyles

const mapState = ({ dataIntegrations, analyticsDashboard }) => {
  return {
    hasAnyActiveIntegrations: dataIntegrations.hasAnyActiveIntegrations,
    pass_roi: analyticsDashboard.dashboardConversionTotals.pass_roi,
  }
}

const mapDispatch = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
});

class OverviewBar extends Component {
  static propTypes = {
    metrics: PropTypes.array.isRequired,
    metricsActions: PropTypes.func.isRequired,
    overviewCellWidth: PropTypes.string,
    canViewSms: PropTypes.bool,
  }

  static defaultProps = {
    overviewCellWidth: '14.28%',
    canViewSms: false,
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({
      template: '<span class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></span>'
    })
  }

  handleUpsellClick = () => {
    const { metricsActions, eventTrackingLocation } = this.props

    metricsActions.create('clickedAddDataIntegration', {
      meta: { from: eventTrackingLocation }
    });
  }

  render() {
    const {
      metrics: mtrcs,
      hasAnyActiveIntegrations,
      checkDataIntegrations,
      pass_roi,
      overviewCellWidth,
      canViewSms,
    } = this.props

    let metrics = mtrcs
      .slice(0, canViewSms ? 7 : 6)
      .map(
        (
          { label, value, isPercent, isDollar, hideZeros, checkPassROI, tooltip }
        ) => {
          const realValue = checkPassROI && !pass_roi ? 0 : value
          const dataToggle = tooltip ? 'tooltip' : null
          const title = tooltip || ''
          return (
            <Col style={{ flexBasis: overviewCellWidth }} key={label} data-toggle={dataToggle} title={title} data-placement="bottom">
              <H1 white>
                {isDollar && realValue ? '$' : ''}
                {makeNumbersPretty(
                  realValue,
                  !hideZeros
                )}
                {isPercent ? '%' : ''}
              </H1>
              <H6 white>{label}</H6>
            </Col>
          )
        }
      )


    if (checkDataIntegrations && !hasAnyActiveIntegrations) {
      metrics = metrics.slice(0, 5)
      metrics.push(
        <Col style={{ width: 277 }}>
          <P white small>
            <small>See customers and the sales generated</small>
          </P>
          <Link to={'/profile/brand/data-integrations'}>
            <Button
              className={ButtonTheme.upsellButton}
              onClick={this.handleUpsellClick}
            >
              Add Data Integrations
            </Button>
          </Link>
        </Col>
      )
    }

    return (
      <Card className="overview-bar-card">
        <Row start="xs" className={overviewBar}>
          {metrics}
        </Row>
      </Card>
    )
  }
}


export default connect(mapState, mapDispatch)(OverviewBar)
