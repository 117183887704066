

import React from 'react'
import Calendar from 'visual-components/calendar/Calendar'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TasksList from 'visual-components/tasks/TaskList'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'

const mapStateToProps = (state) => {
  return {
    campaignTasks: state.campaignTasks,
    currentBrand: state.currentBrand,
    profile: state.profile
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
}

class CalendarContainer extends React.Component {
  render() {
    const {
      campaignTasks: {
        acceptedInvites,
        campaignTasks,
        currentCampaign
      },
      campaignTaskActions: {
        selectCampaign,
        selectTask,
        showMore,
        exportCalendar,
        saveTask
      },
      currentBrand,
      profile
    } = this.props

    return (
      <Calendar
        tasks={campaignTasks.filter(task => {
          return !task.hide
        })}
        exportCalendar={() => {
          exportCalendar(
            `${location.origin}/calendar/brand-tasks/${currentBrand.uid}/${profile.uid}`,
            currentBrand.accountname
          )
        }}
        initialDate={currentCampaign.start}
        campaigns={[currentCampaign]}
        onNavigate={(date) => {
          console.log(date)
        }}
        updateTaskDueDate={saveTask}
        onCampaignSelect={selectCampaign}
        onTaskSelect={selectTask}
        onShowMore={showMore}
        eventDateUpdate={((event, date) => {
          console.log(event, date)
        })}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer)
