import moment from 'moment-timezone';
import _ from 'lodash'

var model = 'socialMetrics';

var terms = ['month', 'week', 'day'];

var columns = 12;

var generateXAxis = function (freq) {
  return {
    month: function () {
      return _.times(12, function(idx){
        return moment().subtract(11-idx, 'months').format('MMM');
      })
    },
    week: function () {
      return _.times(12, function(idx){
        return moment().subtract(1, 'days').subtract(11-idx, 'weeks').format('MMM DD');
      })
    },
    day: function () {
      return _.times(12, function(idx){
        return moment().subtract(11-idx+1, 'days').format('MMM DD');
      })
    }
  }[freq]();
}

var defaultState =  {
  rid: model,
  term: 'month',
  termList: [{ 
    id: 'day',
    name: 'Daily'
  },{ 
    id: 'week',
    name: 'Weekly'
  },{ 
    id: 'month',
    name: 'Monthly'
  }],
  selectedData: {
    data: [0,0,0,0,0,0,0,0,0,0,0,0],
    termsListNames: generateXAxis('month')
  },
  displayState: {}
}


var SocialMetricsReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }

  // Term is mandatory, but name is only passed if it is being changed
  const filterData = function (term, name) {
    var displayState = _.extend({}, state.displayState);
    let filteredData;

    if (name == "all") {
      // Reset all to true (shown)
      _.each(state.socialMetrics[term], function(metric) {
        displayState[metric.name] = true
      })
      filteredData = state.socialMetrics[term]
    }
    else {
      if (name) {
        displayState[name] = !displayState[name]
      }

      // Remove all the metrics the user doesnt want to see
      filteredData = state.socialMetrics[term].filter(function(metric) {
        return displayState[metric.name]
      })
    }

    // If we don't have any data for the past 12 months, show dummy data
    if (filteredData.length == 0 && state.socialMetrics['month'].length == 0) {
      filteredData = [{name: "Social Actions", data: [100000,8221,88890,72500,64000,128000,34300,14466,99021,100234,55212,11122]}]
    } 
    else if (filteredData.length == 0) {
      // Show no data if they've unselected everything
      filteredData = [{name: "Social Actions", data: [0,0,0,0,0,0,0,0,0,0,0,0]}]
    }

    return { filteredData: filteredData, displayState: displayState }
  }

  const actions = {
    UPDATE_VIEW: function () {
      var term = action.data ? action.data.term : state.term;

      let { filteredData, displayState } = filterData(term)

      var selectedData = {
        data: filteredData,
        termsListNames: generateXAxis(term)
      }

      return _.extend({}, state, { selectedData: selectedData, term: term, displayState: displayState });
    },
    LOAD: function () {
      var { socialMetrics } = action.data
      var selectedData = {
        data: socialMetrics['month'].length == 0 ? [{name: "Social Actions", data: [100000,8221,88890,72500,64000,128000,34300,14466,99021,100234,55212,11122]}] : socialMetrics[state.term],
        termsListNames: generateXAxis(state.term)
      }

      var displayState = {};

      let availableColors = ['dark-blue', 'standard-blue', 'baby-blue', 'gray-blue', 'sad-green', 'standard-green', 'dark-green', 'pale-yellow', 'peach', 'orange', 'red'];
      let colorMap = {};
      _.each(socialMetrics[state.term], function(metric, idx) {
        displayState[metric.name] = true
        colorMap[metric.name] = availableColors[idx]
      })

      if (Object.keys(colorMap).length == 0) {
        colorMap['Social Actions'] = 'hidden-gray'
      }

      return _.extend({}, state, { 
        socialMetrics: socialMetrics, 
        selectedData: selectedData, 
        isEmpty: socialMetrics.month.length == 0, 
        displayState: displayState, 
        colorMap: colorMap 
      });
    },
    UPDATE_DISPLAY_STATE: function () {
      let { filteredData, displayState } = filterData(state.term, action.data.name);
      
      var selectedData = {
        data: filteredData,
        termsListNames: generateXAxis(state.term)
      }

      return _.extend({}, state, { selectedData: selectedData, displayState: displayState });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default SocialMetricsReducer;
