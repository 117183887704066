import React, { Component } from 'react';
import NetworkBanner from './network-banner';
import StickyTabContainer from '../util/sticky-tabs-container';
import StickyTab from '../util/sticky-tab';
import TrustedBy from '../util/trusted-by';
import SideImgContent from '../util/side-img-content';
import WhatTheySay from './what-they-say';
import Footer from '../footer/Footer';

class Network extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  render() {
    return(
      <div>
        <NetworkBanner />
        <StickyTabContainer
          bannerHeight={ 680 }
          start={ 950 }
        >
          <StickyTab
            title={ 'Powerful Search' }
            icon={ 'ink-mag-glass' }
            href={ 'powerfulSearch' }
          />
          <StickyTab
            title={ 'Detailed Profiles' }
            icon={ 'detailed-info' }
            href={ 'detailedProfiles' }
          />
          <StickyTab
            title={ 'In-App Messaging' }
            icon={ 'chat-bubbles' }
            href={ 'inAppMessaging' }
          />
          <StickyTab
            title={ 'Analytics' }
            icon={ 'bar-chart' }
            href={ 'analytics' }
          />
        </StickyTabContainer>
        <TrustedBy
          background={ 'transparent' }
        />
        <SideImgContent
          title={ 'Powerful Search' }
          para={ 'Search by industry, list size and audience demographics. See what partners are searching for what kinds of partnerships.' }
          imgSrc={ '/images/login/side-imgs/network/newsfeed.png' }
          href={ 'powerfulSearch' }
        />
        <SideImgContent
          right={ true }
          title={ 'Detailed Profiles' }
          para={ 'View list size, social following, audience geography and income information for any brand on DojoMojo.' }
          imgSrc={ '/images/login/side-imgs/network/campaign-profile.png' }
          href={ 'detailedProfiles' }
        />
        <SideImgContent
          title={ 'In-App Messaging' }
          para={ 'Quickly correspond with any brand in the DojoMojo network, straight from your DojoMojo inbox.' }
          imgSrc={ '/images/login/side-imgs/network/messaging.png' }
          href={ 'inAppMessaging' }
        />
        <SideImgContent
          right={ true }
          title={ 'Partner Analytics' }
          para={ 'Understand which partners performed best with your audience, and when.' }
          imgSrc={ '/images/login/side-imgs/network/partner-analytics.png' }
          href={ 'analytics' }
        />
        <WhatTheySay />
        <Footer />
      </div>
    )
  }
}

export default Network;
