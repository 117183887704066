const initialState = {
  loading: true,
  pendingInvoiceItems: null,
  brandSmsBank: null,
  purchaseSuccess: false,
  purchaseFailure: false,
  cancelSuccess: false,
  error: null,
}

function smsReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * LOADING STATES
     */

    case 'PURCHASE_SMS':
    case 'FETCH_BRAND_SMS_BANK':
    case 'CANCEL_SMS_AUTORENEW':
      return {
        ...state,
        loading: true,
        purchaseSuccess: false,
        purchaseFailure: false,
        cancelSuccess: false,
      }

      /**
     * SUCCESS STATES
     */

    case 'FETCH_PENDING_INVOICE_ITEMS_SUCCESS':
      return {
        ...state,
        loading: false,
        pendingInvoiceItems: action.payload,
      }

    case 'FETCH_BRAND_SMS_BANK_SUCCESS':
      return {
        ...state,
        loading: false,
        brandSmsBank: action.payload,
      }

    case 'PURCHASE_SMS_SUCCESS':
      return {
        ...state,
        loading: false,
        purchaseSuccess: true,
        purchaseFailure: false,
        brandSmsBank: action.payload,
      }

    case 'CANCEL_SMS_AUTORENEW_SUCCESS':
      return {
        ...state,
        loading: false,
        brandSmsBank: action.payload,
        cancelSuccess: true,
      }

      /**
     * FAILURE STATES
     */

    case 'FETCH_BRAND_SMS_BANK_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response ? action.payload.response.data : 'Error',
      }

    case 'PURCHASE_SMS_FAILURE':
      toastr.error('Failed to process payment', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
      return {
        ...state,
        loading: false,
        purchaseSuccess: false,
        purchaseFailure: true,
        error: action.payload.response ? action.payload.response.data : 'Error',
      }

    case 'CANCEL_SMS_AUTORENEW_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response ? action.payload.response.data : 'Error',
      }

      /**
     * STORE RESET
     */

    case 'RESET_PURCHASE_STORE':
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default smsReducer
