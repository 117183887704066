const initialState = {
  rid: 'billingEmails',
  emails: [],
  loading: false,
}

export default function billingEmailsReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_BILLING_EMAILS':
      return { ...state, loading: true }

    case 'LOAD_BILLING_EMAILS_SUCCESS':
      return { ...state, loading: false, emails: action.payload }

    case 'ADD_BILLING_EMAIL_SUCCESS': {
      toastr.success(`${action.email} added successfully`, null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      return { ...state, loading: false, emails: [...state.emails, action.email] }
    }

    case 'DELETE_BILLING_EMAIL_SUCCESS': {
      let { emails } = state
      emails = emails.filter(email => email !== action.email)
      toastr.success(`${action.email} deleted successfully`, null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      return { ...state, loading: false, emails }
    }

    case 'LOAD_BILLING_EMAILS_FAILURE':
    case 'ADD_BILLING_EMAIL_FAILURE':
    case 'DELETE_BILLING_EMAIL_FAILURE': {
      const { response } = action.payload
      // If the email belongs to an existing DojoMojo or billing user
      if (response && response.data) {
        toastr.error(`${action.email} already exists`, null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      }
      return {
        ...state,
        loading: false,
        error: response ? `${response.status}: ${response.data}` : 'Error',
      }
    }

    default:
      return state
  }
}
