import React, { Component } from 'react'
import { string } from 'prop-types'
import { Small } from 'visual-components/util/texts'
import theme from 'css/themes/card.css'

class GetStartedStep extends Component {
    render() {
      const {
        img,
        text
      } = this.props

      return (
        <div className={theme.getStartedStep}>
          <div className={theme.imgWrapper}>
            <img alt={text} src={img} />
          </div>
          <div className={theme.textWrapper}>
            <Small multiline>
              { text }
            </Small>
          </div>
        </div>
      )
    }
}

export default GetStartedStep
