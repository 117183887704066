import React, { PureComponent as Component } from 'react'
import { string, bool, object } from 'prop-types'
import { Input } from 'react-toolbox/lib/input'
import homeTheme from 'css/themes/homePage/home.css'
import footerTheme from 'css/themes/mobile/footer.css'
import buttonTheme from 'css/themes/Buttons.css'
import { withRouter } from 'react-router-dom'
import Button from 'react-toolbox/lib/button'

import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'

class EmailCollector extends Component {
  static propTypes = {
    buttonText: string.isRequired,
    isMobile: bool,
    theme: object,
    className: string,
    located: string,
    history: object.isRequired,
  }

  static defaultProps = {
    isMobile: false,
    theme: null,
    className: null,
    located: '',
  }

  state = { email: '' }

  onChange = email => this.setState({ email })

  onEnter = ({ key }) => key === 'Enter' && this.submit()

  submit = () => {
    const { history, located } = this.props

    store.dispatch({
      model: 'register',
      type: 'UPDATE_ATTR',
      data: { email: this.state.email },
    })

    window.location.href = '/app/register'

    if (located === 'Header') {
      EventTrackingDispatcher.track('home_register_registerButton_mainButton')
    } else if (located === 'Footer') {
      EventTrackingDispatcher.track('home_register_registerButton_footerButton')
    }
  }

  trackOnFocus = () => {
    const { located } = this.props

    if (located === 'Header') {
      EventTrackingDispatcher.track('home_register_emailInput_mainButton')
    } else if (located === 'Footer') {
      EventTrackingDispatcher.track('home_register_emailInput_footerButton')
    }
  }

  render() {
    const {
      buttonText,
      isMobile,
      theme: thm,
      className: cn,
    } = this.props

    const { email } = this.state

    const theme = thm || (isMobile ? footerTheme : homeTheme)

    const className = cn || (isMobile ? footerTheme.inputContainer : null)

    return (
      <div style={{ display: 'flex' }} className={className}>
        <Input
          type="text"
          value={email}
          onChange={this.onChange}
          onKeyPress={this.onEnter}
          name="emailCollection"
          theme={theme}
          className={theme.emailInput}
          placeholder="Enter your email"
          onFocus={this.trackOnFocus}
        />
        <Button
          onClick={this.submit}
          label={buttonText}
          className={`${buttonTheme.greenButton} ${isMobile ? homeTheme.mobileFreeTrialButton : homeTheme.freeTrialButton}`}
          theme={buttonTheme}
          raised
        />
      </div>
    )
  }
}

export default withRouter(EmailCollector)
