import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import Field from 'visual-components/util/inputs/field'

class DistributionGroupInputs extends Component {
  static propTypes = {
    onUpdate: PropTypes.func,
    labelA: PropTypes.string,
    labelB: PropTypes.string,
    valueA: PropTypes.number.isRequired,
    valueB: PropTypes.number.isRequired,
  }

  static defaultProps = {
    onUpdate() {},
    labelA: 'Label A',
    labelB: 'Label B',
  }

  state = {
    valueA: null,
    valueB: null,
    prevalueA: null,
    prevalueB: null,
  }

  onUpdate = (name, badVal) => {
    const { onUpdate } = this.props

    // If backspace
    if (!/%/g.test(badVal)) {
      const { [`pre${name}`]: prevVal } = this.state

      // If not first number
      if (/%/.test(prevVal)) badVal = badVal.slice(0, -1)
    }

    // remove % sign
    const value = badVal.split('%').join('')

    if (isNaN(value)) return null

    const other = name === 'valueB' ? 'valueA' : 'valueB'

    let val = Math.floor(Number(value))

    if (val > 100) val = 100
    else if (val < 0) val = 0

    const otherVal = 100 - val

    const state = {
      [name]: `${val}%`,
      [other]: `${otherVal}%`,
      [`pre${name}`]: `${val}%`,
      [`pre${other}`]: `${otherVal}%`,
    }

    const stateToReturn = { [name]: val, [other]: otherVal }

    return this.setState(state, () => onUpdate(stateToReturn))
  }

  componentDidMount = () => {
    const { valueA, valueB } = this.props

    const state = {
      valueA: (valueA ? `${valueA}%` : null),
      valueB: (valueB ? `${valueB}%` : null),
    }

    return this.setState(state)
  }

  render() {
    const { labelA, labelB } = this.props

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, marginRight: '8px' }}>
          <Field
            dataModel={this.state}
            formModel={this.state}
            attrName="valueA"
            updateAttr={this.onUpdate}
            placeholder="50%"
            label={labelA}
          />
        </div>
        <div style={{ flex: 1, marginLeft: '8px' }}>
          <Field
            dataModel={this.state}
            formModel={this.state}
            attrName="valueB"
            updateAttr={this.onUpdate}
            placeholder="50%"
            label={labelB}
          />
        </div>
      </div>
    )
  }
}

export default DistributionGroupInputs
