import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { P, Smaller } from 'visual-components/util/texts';

import { makeNumbersPretty } from 'util/makeNumbersPretty';

class CampaignPreviewSpecRow extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var {
      name,
      data,
      paddingTop
    } = this.props

    var displayedNumber = makeNumbersPretty(data)

    return(
      <Row style={{
        paddingTop: paddingTop,
      }}>
        <Col style={{
          width: '100px'
        }}>
          <div style={{
            paddingTop: '3px'
          }}>
            <Smaller>
              { name }
            </Smaller>
          </div>
        </Col>
        <Col style={{
          width: '35px',
          textAlign: 'right'
        }}>
          <P>
            <i>
              { displayedNumber }
            </i>
          </P>
        </Col>
      </Row>
    )
  }
};

CampaignPreviewSpecRow.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  paddingTop: PropTypes.string.isRequired
};

export default CampaignPreviewSpecRow;
