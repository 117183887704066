import React from 'react'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import { H4 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import { MobileMarketingContext } from '../MobileMarketingContext'

function UnpublishWarningModal() {
  const { unpublishPostEntry, showModal, modals } = React.useContext(MobileMarketingContext)

  return (
    <SimpleInfoModal
      title="Are You Sure You Want to Unpublish?"
      active={modals.unpublishWarning}
      close={null}
    >
      <H4 multiline>
        You are about to disable your SMS Marketing Post Entry Action and revert to the standard Thank You Only post entry page.
      </H4>
      <ModalButtonGroup
        canSave
        confirm={unpublishPostEntry}
        cancel={() => showModal('unpublishWarning', false)}
        confirmText="Unpublish"
        cancelText="Cancel"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(UnpublishWarningModal)
