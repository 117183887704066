import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-toolbox/lib/button'
import { withRouter } from 'react-router-dom'

import Row from 'visual-components/util/no-padding-grid/row'
import { P, Smaller } from 'visual-components/util/texts'

import ButtonTheme from 'css/themes/Buttons.css'

function TrialB3G1ModalContent({
  remainingDays, numBrands, onBtnClick, onLinkClick,
}) {
  return (
    <>
      <Row style={{ justifyContent: 'center', marginBottom: 24 }}>
        <div style={{ width: 320, textAlign: 'center' }}>
          <P multiline>
            Your Premium trial is ending soon. Keep your account running after your trial ends without interruption.
          </P>
        </div>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <P multiline>
          <em>
            A special offer just for you!
          </em>
        </P>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <P style={{ width: 360, textAlign: 'center', margin: '16px 0 24px' }}>
          Sign up for a yearly plan and
          {' '}
          <em>save 25%.</em>
          {' '}
          Teams who go yearly spend less, increase ROI, and get more out of DojoMojo.
        </P>
      </Row>
      <Row style={{ alignItems: 'center', flexFlow: 'column' }}>
        <Button
          style={{ marginBottom: 8 }}
          onClick={onBtnClick}
          label="Select Plan"
          primary
          theme={ButtonTheme}
          className={ButtonTheme.blueButton}
          raised
        />
        {
          numBrands > 1 && (
            <a
              style={{ margin: '12px 0 24px' }}
              onClick={onLinkClick('/profile/switch-brand')}
            >
              Switch Brands
            </a>
          )
        }
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <Smaller>
          {
            remainingDays === 7
              ? (
                <>
                  Need to
                  {' '}
                  <a onClick={onLinkClick('/profile/brand/payment-methods')}>
                    add your payment information
                  </a>
                  ?
                </>
              ) : (
                <>
                  Going with a custom plan? Enter your payment information
                  {' '}
                  <a onClick={onLinkClick('/profile/brand/payment-methods')}>
                    here
                  </a>
                </>
              )
          }
        </Smaller>
      </Row>
    </>
  )
}

TrialB3G1ModalContent.propTypes = {
  remainingDays: PropTypes.number.isRequired,
  numBrands: PropTypes.number.isRequired,
  onBtnClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onLinkClick: PropTypes.func,
}

TrialB3G1ModalContent.defaultProps = {
  onLinkClick: null,
}

export default React.memo(withRouter(TrialB3G1ModalContent))
