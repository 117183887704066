import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import injectSheet from 'react-jss'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'

import MyPartnershipsHeader from 'visual-components/myPartnerships/myPartnershipsHeader'

import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'

import * as OpportunityActions from 'actions/opportunities-actions/'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'

import CalendarTheme from 'css/themes/calendar/calendar.css'
import BrandCalendar from './BrandCalendar'
import BrandTaskList from './BrandTaskList'
import BrandCampaignsList from './BrandCampaignsList'

const transitions = {
  container: {
    paddingTop: '32px',
    margin: '0 auto',
    width: '976px',
  },
}
const mapDispatchToProps = dispatch => ({
  opportunityActions: bindActionCreators(OpportunityActions, dispatch),
  campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch),
})

const mapStateToProps = state => ({
  currentBrand: state.currentBrand,
  brandCampaigns: state.brandCampaigns,
  campaignTasks: state.campaignTasks,
})

class PartnershipsCalendarContainer extends Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    brandCampaigns: PropTypes.object,
    campaignTaskActions: PropTypes.object,
    opportunityActions: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    brandCampaigns: {},
    campaignTaskActions: {},
  }

  componentDidMount() {
    const {
      brandCampaigns,
      campaignTaskActions,
    } = this.props

    if (brandCampaigns.loaded) {
      campaignTaskActions.loadBrandCampaigns()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      brandCampaigns,
      campaignTaskActions,
    } = this.props

    if (!prevProps.brandCampaigns.loaded && brandCampaigns.loaded) {
      campaignTaskActions.loadBrandCampaigns()
    }
  }

  createCampaign = () => {
    const { history } = this.props

    PricingDispatcher.check('sweepstakes.partnership.create', () => {})

    EventTrackingDispatcher.track('appPartnerships_partnership_createPartnership_createPartnershipHostGiveaway')

    history.push('/new-campaign')
  }

  render() {
    const {
      currentBrand,
      opportunityActions,
      classes: css,
    } = this.props

    return (
      <div className={css.container}>
        <Helmet>
          <title>{ `Calendar for ${currentBrand.accountname}` }</title>
        </Helmet>
        <Grid>
          <MyPartnershipsHeader
            action={this.createCampaign}
            opportunityActions={opportunityActions}
            actionLabel="Create Campaign"
            title={`Calendar for ${currentBrand.accountname}`}
          />
          <Row>
            <div className={CalendarTheme.calendarBrandPage}>
              <BrandCalendar />
              <BrandTaskList />
            </div>
            <BrandCampaignsList />
          </Row>
        </Grid>
      </div>
    )
  }
}

export default injectSheet(transitions)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PartnershipsCalendarContainer)
)
