import React from 'react'
import COLOURS from 'util/colours'
import { P } from 'visual-components/util/texts'
import { Link } from 'react-router-dom'

class PostLoginFooter extends React.Component {
  render() {
    const copy = {
      tou: 'Terms of Use',
      privacy: 'Privacy Policy',
      careers: 'Careers',
      copyright: `${new Date().getFullYear()} DojoMojo Inc`,
    }

    return (
      <footer className="footer" style={{ background: COLOURS.footerGreen }}>
        <div className="container">
          <div
            className="row text-center"
            style={{
              paddingTop: '25px',
              paddingBottom: '25px',
            }}
          >
            <div className="col-xs-6 col-sm-2 col-sm-offset-4 col-md-2 col-md-offset-4 col-lg-2 col-lg-offset-4">
              <P>
                <Link to="/terms-of-use">
                  {copy.tou}
                </Link>
              </P>
            </div>
            <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2">
              <P>
                <Link to="/privacy">
                  {copy.privacy}
                </Link>
              </P>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
              <P>
                <Link to="/careers">
                  {copy.careers}
                </Link>
              </P>
            </div>
            <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
              <P white>
              &copy;
              &nbsp;
                {copy.copyright}
              </P>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default PostLoginFooter
