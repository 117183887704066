import React from 'react'
import { Card } from 'react-toolbox/lib/card'
import { Tiny, H5 } from 'visual-components/util/texts'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

const CtaTrafficBarTooltip = props => {
  const {
    period,
    conversions,
  } = props

  return (
    <Card
      style={{
        paddingTop: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        width: '136px',
      }}
    >
      <Tiny>
        {period}
      </Tiny>
      <div style={{ marginTop: '12px' }}>
        <H5>
          {makeNumbersPretty(conversions)}
        </H5>
      </div>
      <Tiny>
        clicks
      </Tiny>
    </Card>
  )
}

export default CtaTrafficBarTooltip
