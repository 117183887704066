export const fetchBrandBank = () => ({
  http: {
    url: '/brand-sms-bank',
    method: 'GET',
  },
  types: ['FETCH_BRAND_SMS_BANK', 'FETCH_BRAND_SMS_BANK_SUCCESS', 'FETCH_BRAND_SMS_BANK_FAILURE'],
})

export const fetchPendingInvoiceItems = () => ({
  http: {
    url: '/pending-invoice-items',
    method: 'GET',
  },
  types: [
    'FETCH_PENDING_INVOICE_ITEMS',
    'FETCH_PENDING_INVOICE_ITEMS_SUCCESS',
    'FETCH_PENDING_INVOICE_ITEMS_FAILURE',
  ],
})

export const purchaseSms = orderData => ({
  http: {
    url: '/sms/purchase',
    method: 'POST',
    data: { orderData },
  },
  types: ['PURCHASE_SMS', 'PURCHASE_SMS_SUCCESS', 'PURCHASE_SMS_FAILURE'],
})

export const cancelSmsAutorenew = () => ({
  http: {
    url: '/sms/auto-renew/cancel',
    method: 'POST',
  },
  types: ['CANCEL_SMS_AUTORENEW', 'CANCEL_SMS_AUTORENEW_SUCCESS', 'CANCEL_SMS_AUTORENEW_FAILURE'],
})

export const resetPurchaseStore = () => ({
  type: 'RESET_PURCHASE_STORE',
})
