export default {
  overviewSelection: 'campaignVisits',
  conversionSelection: 'transactions',
  overviewSection: {
    items: [],
    colorMap: {},
    categories: [],
    hideMap: {
      linkShare: true
    }
  },
  statsByPartnerSection: {
    items: [],
    colorMap: {},
    categories: [],
    hideMap: {
      linkShare: true
    }
  },
  campaignConversionDetails: {
    transactions: {
      name: 'transactions',
      data: []
    },
    revenue: {
      name: 'revenue',
      data: []
    },
    aggregates: {
      transactions: 0,
      revenue: 0
    }
  },
}