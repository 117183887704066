import React, { Component } from 'react'
import Field from 'visual-components/util/inputs/field'
import theme from 'css/themes/custom-input-theme.css'

class DropdownField extends Component {
  render() {
    let {
      data,
      index,
      updateOptionVal,
      canRemove,
      remove,
      disabled,
    } = this.props

    return(
      <Field label={ `Dropdown Option ${index + 1}` }
        editable={!disabled}
        index={ index }
        attrName={ index }
        updateAttr={ updateOptionVal }
        dataModel={ data }
        formModel={ {} }
        disabledUpdateAttr={ true }
        outerClassName={ theme.fullWidthInput }
        className={ theme.dropdownInputField }
        canRemove={ canRemove }
        remove={ remove }
      />
    )
  }
}

export default DropdownField
