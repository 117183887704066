import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { withRouter } from 'react-router-dom'
import SplitTrafficHostStatus from './SplitTrafficHostStatus'
import SplitTrafficPartnersTable from './SplitTrafficPartnersTable'
import SplitTrafficStatusGraph from './SplitTrafficStatusGraph'


const styles = {
  wrapper: {
    height: 0,
    width: 'calc(100% + 72px)',
    backgroundColor: 'white',
    transition: 'height .3s ease-in',
    margin: '0 -36px',
    overflow: 'hidden',
    '& h3 > small': { // Overrides font family not listed in the styleguide
      fontFamily: 'Larsseit',
    },
  },
  wrapperOpen: {
    height: 'initial', // TODO: Fix transition issue here...
  },

  innerWrapper: {
    padding: '24px 76px',
  },
  statusWrapper: {
    margin: '24px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1.2fr',
    gridColumnGap: '24px',
  },
}

function SplitTrafficHostDashboard(props) {
  const {
    classes: css,
    open,
    campaignName,
    campaignUid,
    campaignStatus,
    splitTrafficData,
  } = props

  const {
    host,
    partners,
    traffic,
    onSave,
    onSavePartner,
    onPercentChange,
    onUpdatePartnerField,
  } = splitTrafficData

  return (
    <div className={cn(css.wrapper, { [css.wrapperOpen]: open })}>
      <div className={css.innerWrapper}>
        <div className={css.statusWrapper}>
          <SplitTrafficHostStatus
            host={host}
            campaignStatus={campaignStatus}
            campaignName={campaignName}
          />
          <SplitTrafficStatusGraph traffic={traffic} />
        </div>
        <SplitTrafficPartnersTable
          partners={partners}
          onSave={onSave}
          onSavePartner={onSavePartner}
          onPercentChange={onPercentChange}
          onUpdatePartnerField={onUpdatePartnerField}
          campaignName={campaignName}
          campaignUid={campaignUid}
          campaignStatus={campaignStatus}
        />
      </div>
    </div>
  )
}

SplitTrafficHostDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  campaignName: PropTypes.string.isRequired,
  campaignUid: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
  splitTrafficData: PropTypes.object.isRequired,
}

export default injectCSS(styles)(withRouter(SplitTrafficHostDashboard))
