import initialState from './defaults/campaignSearch'

export default {
  model: 'campaignSearch',
  initialState,
  endpoint: '/search-campaigns',
  sources: [
    'id', 'name', 'mainImage', 'minimumListSize', 'estimatedEntries', 'minimumEntriesRequired',
    'industry', 'partners', 'startDate', 'hostBrandLogo', 'imagePos',
  ],
  inputName: ['name', 'hostBrandName', 'partners.name', 'industry.search'],
  aggs: {
    tags: {
      terms: {
        field: 'industry.raw',
        size: 100,
      },
    },
  },
  rangeFilters: [
    'startDateFilterValue',
    'estEntriesFilterValue',
    'minListSizeFilterValue',
    'numberOfPartnersFilterValue',
    'minEntriesRequired',
    'prizeValue',
  ],
  facets: ['tags'],
}
