import { useEffect } from 'react'

const useWindowEvent = (el, event, func) => {
  useEffect(() => {
    if (el) el.addEventListener(event, func)

    return () => {
      if (el) el.removeEventListener(event, func)
    }
  }, [el, event, func])
}

export default useWindowEvent
