import React from 'react';

class StyleButton extends React.Component {
  constructor() {
    super();

    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    let { selected, selectedIcon, unselectedIcon, label, active } = this.props;

    return (
      <div className={ className } onMouseDown={ this.onToggle } style={{
        display: 'inline-block',
      	cursor: 'pointer',
        marginLeft: '22px'
      }}>
        { unselectedIcon ? 
            <img src={ `images/icons/rich-editor-icons/${active ? selectedIcon : unselectedIcon}` } style={{
              height: '12px'
            }}/>
          :
            label
        }
      </div>
    );
  }
}

export default StyleButton;