import React from 'react';
import _ from 'lodash'

import { renderToString } from 'react-dom/server'
import moment from 'moment-timezone';
import SocialTooltipCard from './social-tooltip';

var TooltipFactory = React.createFactory(SocialTooltipCard)

var renderGraph = (data) => {
  $('.campaign-social-bars').html('');

  var mappedData = {};

  _.each(data.categories, function  (category, idx) {
    mappedData[category] = data.socialItems.map(function (item) {
      return {
        label: item.title,
        value: item.data[idx]
      }
    });
  })

  _.each(data.socialItems, function (item) {
    item.selected = !data.hideMap[item.name];
  })

  var socialItems = _.filter(data.socialItems, { selected: true });

  var campaign = data.campaign;
  var endDate = campaign.endDate < new Date() ? campaign.endDate : new Date();

  var end = moment(endDate).format('ll');

  var graph = new Contour({
    el: '.campaign-social-bars',
    xAxis: {
      categories: data.categories
    },
    chart: {
      padding: {
        left: 33,
        bottom: 40
      },
      height: 300
    },
    tooltip: {
      opacity: 1,
      formatter: function (d) {
        var str = renderToString(TooltipFactory({
          date: `${moment(campaign.startDate).format('ll')} - ${end}`,
          platform: d.x,
          data: mappedData[d.x]
        }));
        return str;
      }
    }
  })
  .cartesian()
  .yAxisLines(null, {
    showAll: true
  })
  .socialLineBreaks()
  .column(socialItems.reverse(), { stacked: true })
  .tooltip()
  .assignColors(socialItems, {
    colorMap: data.colorMap
  })
  .render()

  return graph;
};

class SocialBars extends React.Component {
  componentDidMount() {
    renderGraph(this.props);
  }

  componentDidUpdate() {
    renderGraph(this.props);
  }

  render() {
    return (
      <div className="campaign-social-bars"
        style={{
          display: 'inline-block',
          width: '590px',
          height: '300px',
          marginBottom: '-20px'
      }}>
      </div>
    );
  }
}

export default SocialBars;
