import React from 'react'
import { Checkbox } from 'react-toolbox'
import AssetUploadTableTheme from 'css/themes/assets/AssetUploadTableTheme.css'
import CheckboxTheme from 'css/themes/Checkbox.css'
import { string, func, bool } from 'prop-types'

class FileOptionRow extends React.Component {
  static propTypes = {
    fileName: string.isRequired,
    fileType: string.isRequired,
    checked: bool.isRequired,
    toggleIncludeFile: func.isRequired,
  }

  render() {
    const {
      fileName,
      fileType,
      checked,
      toggleIncludeFile,
    } = this.props

    return (
      <tr>
        <td className={AssetUploadTableTheme.firstTd}>
          {fileName}
        </td>
        <td className={AssetUploadTableTheme.secondTd}>
          {fileType}
        </td>
        <td className={AssetUploadTableTheme.checkboxTd}>
          <Checkbox
            checked={checked}
            onChange={toggleIncludeFile}
            theme={CheckboxTheme}
          />
        </td>
      </tr>
    )
  }
}

export default FileOptionRow
