import { merge } from 'lodash'

const initialState = {
  rid: 'espIntegrations',
  model: 'campaign',
  espIntegrations: [],
  espIntegration: {},
  error: '',
  loading: false,
  listsLoading: false,
  extraDetailsLoading: false,
  testLoading: false,
  testResults: {},
  warningModal: {},
}

export default function espIntegrationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOADING_ESP_INTEGRATIONS': {
      return { ...state, loading: true, model: action.model || state.model }
    }

    case 'LOADING_ESP_INTEGRATION_LISTS':
      return { ...state, listsLoading: true }

    case 'LOADING_ESP_INTEGRATION_TEST':
      return { ...state, testLoading: true }

    case 'LOADING_ESP_INTEGRATIONS_FINISHED':
      return {
        ...state,
        loading: false,
        listsLoading: false,
        testLoading: false,
      }

    case 'CLEAR_ESP_INTEGRATIONS':
      return initialState

    case 'CLEAR_ESP_INTEGRATION':
      return { ...state, espIntegration: {}, testResults: {} }

    case 'CLEAR_ESP_INTEGRATION_TEST':
      return { ...state, testResults: {} }

    case 'CLEAR_ESP_INTEGRATION_WARNING_MODAL':
      return { ...state, warningModal: {} }

    case 'LOAD_ESP_INTEGRATIONS_SUCCESS': {
      const { model, payload } = action
      return {
        ...state,
        model,
        espIntegrations: payload,
        loading: false,
      }
    }

    case 'LOAD_ESP_INTEGRATION_SUCCESS': {
      const { model, payload } = action
      return {
        ...state,
        model,
        espIntegration: payload,
        loading: false,
      }
    }

    case 'UPDATE_ESP_INTEGRATIONS_ATTR':
      return merge({}, state, action.data)

    case 'UPDATE_ESP_INTEGRATION_SUCCESS': {
      const espIntegration = { ...state.espIntegration, ...action.data }
      const espIntegrations = [...state.espIntegrations]

      let idx = espIntegrations.findIndex(obj =>
        obj.integration_type === espIntegration.integration_type)

      if (idx === -1) idx = espIntegrations.length

      espIntegrations[idx] = espIntegration

      return { ...state, espIntegrations, espIntegration }
    }

    case 'UPDATE_ESP_INTEGRATION_MERGE_TAGS': {
      const espIntegration = { ...state.espIntegration }
      const { key, attr, value } = action.data

      const itemIdx = espIntegration.mergeTags.findIndex(obj => obj.attribute === key)
      if (itemIdx !== -1) {
        espIntegration.mergeTags[itemIdx][attr] = value
      } else {
        espIntegration.mergeTags.push({
          attribute: key,
          [attr]: value,
        })
      }

      return { ...state, espIntegration }
    }

    case 'UPDATE_ESP_INTEGRATION_MERGE_TAGS_SUCCESS': {
      const espIntegration = { ...state.espIntegration }
      const { data, payload } = action

      const tag = payload.find(m => m.attribute === data.key)
      if (data.attr === 'include' || (data.attr === 'value' && tag && tag.include)) {
        espIntegration.activated = false
        espIntegration.verified = null
      }

      return { ...state, espIntegration: { ...espIntegration, mergeTags: payload } }
    }

    case 'UPDATE_ESP_INTEGRATION_TEST': {
      const espIntegration = { ...state.espIntegration }
      const espIntegrations = [...state.espIntegrations]

      const idx = espIntegrations.findIndex(obj =>
        obj.integration_type === espIntegration.integration_type)

      espIntegration.verified = action.success
      espIntegrations[idx].verified = action.success

      const copyMap = {
        success: action.isActivate === true
          ? 'Your ESP is successfully integrated.'
          : 'Test Success! Please check your ESP for confirmation.',
        error: action.isActivate === true
          ? 'Your ESP could not be activated. Check above to resolve the error.'
          : 'Error! Something went wrong.',
      }

      if (action.success) {
        toastr.success(copyMap.success, null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
      } else {
        toastr.error(copyMap.error, null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
      }

      return {
        ...state,
        espIntegration,
        espIntegrations,
        testLoading: false,
        testResults: { response: JSON.stringify(action.data), success: action.success },
      }
    }

    case 'DELETE_ESP_INTEGRATION_SUCCESS': {
      const espIntegrations = [...state.espIntegrations].filter(i => i.id !== action.id)
      return { ...state, espIntegrations, espIntegration: {} }
    }

    case 'LOAD_ESP_INTEGRATIONS_FAILURE':
    case 'LOAD_ESP_INTEGRATION_FAILURE':
    case 'UPDATE_ESP_INTEGRATION_FAILURE':
    case 'UPDATE_ESP_INTEGRATION_MERGE_TAGS_FAILURE':
    case 'DELETE_ESP_INTEGRATION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload.response
          ? `${action.payload.response.status}: ${action.payload.response.data}`
          : 'Error',
      }

    default:
      return state
  }
}
