import BrandAccountDetailsForm from './brand-account-details-form'
import BankAccountSettingsForm from './bank-account-settings-form'
import VerifyBankAccountForm from './verify-bank-account-form'
import IncorrectVerificationAmountsForm from './incorrect-verification-amounts-form'

export const CLOSED = {}

export const BRAND_ACCOUNT_SETTINGS = {
  title: 'Enter Business Info',
  FormComponent: BrandAccountDetailsForm,
}

export const BANK_ACCOUNT_SETTINGS = {
  title: 'Enter Receiving Bank Info',
  FormComponent: BankAccountSettingsForm,
}

export const VERIFY_BANK_ACCOUNT = {
  title: 'Verify Bank Account',
  FormComponent: VerifyBankAccountForm,
}

export const INCORRECT_VERIFICATION_AMOUNTS = {
  title: 'Oops! Try Again',
  FormComponent: IncorrectVerificationAmountsForm,
}
