import React, { PureComponent } from 'react'
import { Button } from 'react-toolbox/lib/button'
import {
  number,
  string,
  bool,
  func,
  object,
} from 'prop-types'
import Theme from 'css/themes/media-market/collaborate-comment-theme.css'
import buttonTheme from 'css/themes/Buttons.css'
import Textarea from 'react-textarea-autosize'

class CommentInput extends PureComponent {
  static propTypes = {
    annotationNumber: number,
    isReply: bool,
    hide: func.isRequired,
    value: string.isRequired,
    onChange: func.isRequired,
    onFocus: func,
    onBlur: func,
    submitLabel: string,
    onSubmit: func.isRequired,
    topLevelCommentInput: bool,
    disableMainCommentInput: bool,
    newComment: object,
  }

  static defaultProps = {
    annotationNumber: null,
    submitLabel: 'Comment',
    isReply: false,
    topLevelCommentInput: false,
    onFocus: () => {},
    onBlur: () => {},
    disableMainCommentInput: false,
    newComment: {},
  }

  state = {
    showButtons: false,
    textRows: 2,
  }

  componentDidMount() {
    const { submitLabel } = this.props
    if (submitLabel !== 'Comment') {
      this.textArea.focus()
    }
  }

  componentDidUpdate(prevProps) {
    const { newComment } = this.props
    if (newComment && prevProps.newComment !== newComment) {
      this.textArea.focus()
    }
  }

  hideButtons = () => this.setState({ showButtons: false })

  onCancel = e => {
    e.stopPropagation()
    const { hide, onBlur } = this.props
    hide()
    onBlur()
    this.setState({
      showButtons: false,
    })
  }

  onFocus = () => {
    const { onFocus } = this.props
    onFocus()
    this.setState({
      showButtons: true,
    })
  }

  onBlur = () => {
    const { onBlur } = this.props
    onBlur()
    this.setState({
      showButtons: false,
    })
  }

  onSubmit = e => {
    e.stopPropagation()
    const { onSubmit, hide } = this.props
    onSubmit()
    hide()
    this.setState({
      showButtons: false,
    })
  }

  onClick = e => { e.stopPropagation() }

  captureTextarea = el => (this.textArea = el)

  render() {
    const {
      showButtons,
      textRows,
    } = this.state

    const {
      annotationNumber,
      isReply,
      submitLabel,
      value,
      onChange,
      topLevelCommentInput,
      disableMainCommentInput,
    } = this.props

    return (
      <div className={Theme.commentInputContainer} style={isReply ? { width: '211px', left: '12px' } : null}>
        <div style={isReply ? { display: 'flex', justifyContent: 'space-between' } : null}>
          { isReply && <div className={Theme.replyInputVerticalLine} /> }
          <div className={Theme.input}>
            <div style={{
              width: isReply ? '202px' : '211px',
              overflow: 'hidden',
              borderRight: '1px solid #dee4e5',
            }}
            >
              <form style={{ display: 'inline' }}>
                <Textarea
                  inputRef={this.captureTextarea}
                  rows={textRows}
                  type="text"
                  style={{
                    fontSize: '12px',
                    maxWidth: isReply ? '216px' : '225px',
                    minWidth: isReply ? '216px' : '225px',
                    minHeight: '40px',
                    boxSizing: 'border-box',
                    borderColor: '#dee4e5',
                    width: '100%',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    paddingRight: '15px',
                    fontFamily: 'Larsseit',
                    padding: '14px 27px 14px 12px',
                    borderRadius: '2px',
                  }}
                  placeholder="Write a comment..."
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  value={value}
                  onClick={this.onClick}
                  onChange={onChange}
                  maxRows={5}
                  disabled={disableMainCommentInput}
                />
              </form>
            </div>

            {/* for comment input display */}
            <div style={{
              width: isReply ? '216px' : '225px',
              height: '4px',
              marginTop: '-4px',
              backgroundColor: 'white',
            }}
            />
          </div>
        </div>

        <div>
          { annotationNumber
            && (
            <div className={Theme.annotationOval}>
              <div className={Theme.number}>
                { annotationNumber }
              </div>
            </div>
            )
          }

          {
            (!topLevelCommentInput || showButtons || annotationNumber || value)
            && (
            <div className={`${Theme.buttonGroup} ${isReply && Theme.isReply}`}>
              <Button
                theme={Theme}
                className={buttonTheme.whiteButton}
                raised
                label="Cancel"
                onClick={this.onCancel}
                style={{ marginRight: '8px' }}
              />

              <Button
                theme={Theme}
                className={buttonTheme.greenButton}
                primary
                raised
                label={submitLabel}
                onClick={this.onSubmit}
              />
            </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default CommentInput
