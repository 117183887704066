import _ from 'lodash'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Validator = {

  testIsNumber: function (attr, value, callback) {
    if (isNaN(value)) {
      value = 0;
    }
    callback();
  },

  testEmailTaken: function (attr, value, callback) {
    if (!value) {
      callback();
      return;
    }

    var that = this;

    $.ajax({
      method: 'GET',
      url: '/register/email-available/' + value,
      error: function () {
        that.addError(attr, value + ' has been taken.');
        callback();
      },
      success: callback
    });
  },

  testRequired: function (attr,value, callback) {
    if (!value) {
      this.addError(attr, 'This Field Is Required');
    }
    callback();
  },

  testNeedLogo: function (attr, value, callback) {
    if (!value) {
      this.addError(attr, 'This Field Is Required');
    }
    callback();
  },

  testIsWebsite: function (attr, value, callback) {
    // https://gist.github.com/dperini/729294
    var regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    if (value.match(regex)) {
      callback(true);
    } else {
      this.addError(attr, 'Must be a proper web address and start with http(s).');
      callback(false);

    }
  },

  testUniqueBrandName: function (attr, value, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    $.ajax({
      url: '/test-unique-brand-name/' + encodeURIComponent(value),
      method: 'GET',
      success: callback,
      error: function () {
        that.addError(attr, value + ' has been taken.');
        callback();
      }
    });
  },

  testUniqueBrandDomain: function (attr, value, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    var domain = value.replace('http://', '');
    domain = domain.replace('https://', '');
    domain = domain.replace('www.', '');
    domain = domain.split('.')[0];
    $.ajax({
      url: '/test-unique-brand-domain/' + domain,
      method: 'GET',
      success: callback,
      error: function () {
        that.addError(attr, value + ' has been taken.');
        callback();
      }
    });
  },

  testIsCorrectPassword: function (attr, value, callback) {
    var that = this;
    $.ajax({
      url: '/test-has-password',
      method: 'POST',
      data: {
        password: value
      },
      success: callback,
      error: function () {
        that.addError(attr, 'If you do not know your current password you must log out and use the forgot password functionality at login.');
        callback();
      }
    });
  },

  testIsValidPassword: function (attr, value, callback) {
    if (!value || !value.match(/^(?=.*[A-Z])(?=.*[!\?@#$&*+])(?=.*[0-9]).*$/) || value.length < 8) {
      this.addError(attr, 'Password must be 8 characters long, include an uppercase character[A-Z], a digit[0-9], and a special character[!?@#$&*+].')
    }
    callback();
  },

  testMatchPassword: function (attr, value, callback) {
    var match = $('#password').val();
    if (value !== match) {
      this.addError(attr, 'Passwords must match.');
    }
    callback();
  },

  testStartDate: function (attr, value, callback) {
    if (new Date(value).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) {
      this.addError(attr, 'Start date cannot be less than current date.');
    } else if (this.endDate && new Date(value).setHours(0,0,0,0) > new Date(this.endDate).setHours(0,0,0,0)){
      this.addError(attr, 'Start date cannot be greater than End date.');
    }
    callback();
  },

  testEndDate: function (attr, value, callback) {
    if (new Date(value).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) {
      this.addError(attr, 'End date cannot be less than current date.');
    } else if (this.startDate && new Date(value).setHours(0,0,0,0) < new Date(this.startDate).setHours(0,0,0,0)){
      this.addError(attr, 'End date cannot be less than Start date.');
    }
    callback();
  },

  testNonZero: function (attr, value, callback) {
    if (!value || value == 0) {
      this.addError(attr, 'This field cannot be zero.');
    }
    callback();
  },

  errors: {},
  validations: {},

  resetError: function (attr) {
    this.errors[attr] = [];
  },

  addError: function (attr, errorMessage) {
    this.errors[attr].push(errorMessage);
  },

  validate: function (callback) {
    var attrs = Object.keys(this.validations);

    this.validateAttrs(attrs, callback);
  },

  validateAttrs: function (attrs, callback) {
    var that = this;
    var done = _.after(attrs.length, function () {
      callback(_.some(attrs, function (attr) {
        return that.errors[attr].length;
      }));
    });
    _.each(attrs, attr => {
      this.validateAttr(attr, this[attr], done);
    });
  },

  validateAttr: function (attr, value, callback) {
    this.resetError(attr);

    var validations = this.validations[attr] || [];

    var done = _.after(validations.length + 1, callback);

    done();

    var funcName;
    _.each(validations, validation => {
      funcName = 'test' + capitalizeFirstLetter(validation);
      this[funcName].call(this, attr, value, done);
    });
  },

  validateAndSet: function (attr, value, callback) {
    this.resetError(attr);
    var validations = this.validations[attr];
    if (validations) {
      var pass = true;
      var done = _.after(validations.length, function () {
        if (pass) {
          this[attr] = value;
        }
        callback();
      }.bind(this));

      _.each(validations, validation => {
        this[validation](attr, value, function (passValidation) {
          pass = pass && passValidation ? true : false;
          done();
        }.bind(this));
      });
    }
  }
};

export default Validator;
