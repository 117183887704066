import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import colors from 'util/colours'
import LinkDetectorModal from '../LinkDetectorModal'

const styles = {
  link: {
    position: 'relative',
    '& > span': {
      cursor: 'text',
      fontFamily: 'Larsseit-Medium',
      color: ({ show }) => (show ? colors.coral : colors.azure),
    },
  },
}

function EditorLinkWrapper(props) {
  const {
    minifiedUrl,
    url,
    children,
    show,
    setShow,
    classes: css,
  } = props

  const timeoutRef = React.useRef(null)
  const spanRef = React.useRef(null)
  const [shown, setShown] = useState(false)

  const onLinkClick = () => {
    if (show) return

    if (minifiedUrl) setShown(false)
    setShow(true)
  }

  const onMouseHover = () => {
    clearTimeout(timeoutRef.current)
    if (shown) return
    setShow(true)
  }

  const onMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShow(false)
      setShown(true)
    }, 1000)
  }

  const placement = spanRef.current && spanRef.current.getBoundingClientRect()

  return (
    <span
      ref={spanRef}
      className={css.link}
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <span onClick={onLinkClick}>
        {children}
      </span>
      <LinkDetectorModal
        placement={placement}
        show={show}
        originalLink={minifiedUrl && url}
        link={url}
        onClose={() => setShow(false)}
      />
    </span>
  )
}

const StyledEditorLinkWrapper = injectCSS(styles)(EditorLinkWrapper)

function EditorLinkWrapperParent(props) {
  const [show, setShow] = useState(false)

  return <StyledEditorLinkWrapper show={show} setShow={setShow} {...props} />
}

EditorLinkWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  minifiedUrl: PropTypes.string,
}

EditorLinkWrapper.defaultProps = {
  minifiedUrl: null,
}

export default React.memo(EditorLinkWrapperParent)
