import React from 'react'
import OpportunityInfoCard from './opportunity-info-card'
import InterestValue from 'visual-components/brandProfile/interests/interest-value.js'
import { formatRange } from 'visual-components/brandProfile/opportunities/formatRange'

class InsertSwapOpportunityInfo extends React.Component {
  concatAges = (prop) => {
    let ageMap = {
      'age_0_18': '0-18',
      'age_18_24': '18-24',
      'age_25_34': '25-34',
      'age_35_44': '35-44',
      'age_45_54': '45-54',
      'age_55_up': '55+'
    }

    return Object.keys(ageMap).filter(age => prop[age]).map(age => ageMap[age]).join(', ')
  }

  render() {
    let { opportunity, brand, showPen, obscure } = this.props

    return (
      <OpportunityInfoCard tags={ brand.tags } showPen={ showPen }>
        <InterestValue
          title={ 'Monthly Volume' }
          value={ formatRange(opportunity.min_target_monthly_volume, opportunity.max_target_monthly_volume) }
          marginRight='113px'
          obscure={obscure}
        />
        <InterestValue
          title={ 'Audience Gender' }
          value={ opportunity.gender }
          marginRight='129px'
          obscure={obscure}
        />
        <InterestValue
          title={ 'Products I Ship' }
          value={ opportunity.products }
          obscure={obscure}
        />
        <div>
          <InterestValue
            title={ 'Age Range' }
            value={ this.concatAges(opportunity) }
            obscure={obscure}
          />
        </div>
      </OpportunityInfoCard>
    )
  }
}

export default InsertSwapOpportunityInfo
