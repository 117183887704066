import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import theme from 'css/themes/mobile/home/careers/careers.css';

class EachOpening extends Component {
  render() {
    const {
      title,
      href,
    } = this.props;

    return(
      <div className={ theme.eachposition }>
        <a target="_blank" rel="noreferrer" href={ href }>
          <p>
            { title }
          </p>
        </a>
      </div>
    )
  }
}

export default withRouter(EachOpening);
