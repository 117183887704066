import React, { Component } from 'react';
import SweepstakesBanner from './sweepstakes-banner';
import StickyTabContainer from '../util/sticky-tabs-container';
import StickyTab from '../util/sticky-tab';
import PainfulAndConsuming from './painful-and-consuming';
import BlueTabs from './bluetabs';
import WhatTheySay from './what-they-say';
import JoinBrands from './join-brands';
import SideImgContent from '../util/side-img-content';
import BlueBanner from '../util/blue-banner';
import Footer from '../footer/Footer';

class Sweepstakes extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  render() {
    return(
      <div>
        <SweepstakesBanner />
        <StickyTabContainer
          bannerHeight={ 680 }
          start={ 3380 }
        >
          <StickyTab
            title={ 'Templates' }
            icon={ 'palette-brush' }
            href={ 'templates' }
          />
          <StickyTab
            title={ 'Analytics' }
            icon={ 'bar-chart' }
            href={ 'analytics' }
          />
          <StickyTab
            title={ 'Explore Campaigns' }
            icon={ 'ink-mag-glass' }
            href={ 'explore-campaigns' }
          />
          <StickyTab
            title={ 'Quality' }
            icon={ 'quality-badge' }
            href={ 'quality' }
          />
          <StickyTab
            title={ 'Compliance' }
            icon={ 'circle-checkmark' }
            href={ 'compliance' }
          />
          <StickyTab
            title={ 'ESP Integrations' }
            icon={ 'envelope-mail' }
            href={ 'esp-integrations' }
          />
        </StickyTabContainer>
        <PainfulAndConsuming />
        <BlueTabs />
        <WhatTheySay />
        <JoinBrands />
        <SideImgContent
          title={ 'Customizable Templates' }
          para={ 'Choose from one of our easy to customize, optimized for mobile templates, add images and text and you’re ready to go. Simple editing tools let anyone on your team create a landing page in minutes.' }
          imgSrc={ '/images/login/side-imgs/sweepstakes/select-template.png' }
          href={ 'templates' }
        />
        <SideImgContent
          right={ true }
          title={ 'Powerful Analytics' }
          para={ 'Our detailed dashboards help you understand which themes and partners resonate most with your audience and ensure you’re partnering with strong performers.' }
          imgSrc={ '/images/login/side-imgs/sweepstakes/campaign-analytics.png' }
          href={ 'analytics' }
        />
        <SideImgContent
          title={ 'Explore Campaigns' }
          para={ 'Not ready to host your own? Apply to join campaigns looking for partners like you. Need partners? List your sweepstakes and let brands find and apply to join you.' }
          imgSrc={ '/images/login/side-imgs/sweepstakes/campaign-profile.png' }
          href={ 'explore-campaigns' }
        />
        {/* something here */}
        <BlueBanner
          headline={ 'A Focus on Quality' }
          explain={ 'We partner with Webbula, the world leader in email verification, to keep your list safe. We’ll always share our best practices for keeping your email list quality high.' }
          imgSrc={ '/images/login/sweepstakes/mojo-thumbs-up-new.svg' }
          href={ 'quality' }
        />
        <SideImgContent
          right={ true }
          title={ 'Stay Compliant' }
          para={ 'Create Official Rules in minutes with our easy to use generator or use your own. Set promotional targets to make sure other partners deliver before receiving their entries.' }
          imgSrc={ '/images/login/side-imgs/sweepstakes/official-rules-generator.png' }
          href={ 'compliance' }
        />
        <SideImgContent
          title={ 'ESP Integrations' }
          para={ 'Save time and send new email address directly to your list, adding them directly into acquisition funnels while you’re fresh in their mind.' }
          imgSrc={ '/images/login/side-imgs/sweepstakes/esp-integration-grid.png' }
          href={ 'esp-integrations' }
        />
        <Footer />
      </div>
    )
  }
}

export default Sweepstakes;
