import React from 'react';
import { object } from 'prop-types';

import { Card } from 'react-toolbox/lib/card';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H5, Tiny } from 'visual-components/util/texts';

class ContributionBarTooltip extends React.Component {
  render() {
    let {
      threshold,
      performance
    } = this.props;

    return (
      <Card style={{
        padding: '12px',
        width: '136px',
        height: '90px'
      }}>
        <div style={{
          marginBottom: '4px'
        }}>
          <Tiny>
            Assigned Threshold:
          </Tiny>
        </div>

        <div style={{
          marginBottom: '12px'
        }}>
          <H5>
            { makeNumbersPretty(threshold) }
          </H5>
        </div>

        <div style={{
          marginBottom: '4px'
        }}>
          <Tiny>
            Performace:
          </Tiny>
        </div>

        <H5>
          { d3.format(',.2%')(performance) }
        </H5>
      </Card>
    )
  }
};

export default ContributionBarTooltip;
