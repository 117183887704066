import React from 'react';
import * as ReactRedux from 'react-redux';
import _ from 'lodash'

import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text';
import TinyMCE from 'react-tinymce';

import CampaignDispatcher from 'dispatchers/campaign-dispatcher';

import TermsDispatcher from 'dispatchers/terms-dispatcher';
import TermsConfirmEditModal from 'campaigns/builder/customize/terms/terms-confirm-edit-modal';
import { P } from 'visual-components/util/texts';

var campaign = {
  name: 'test campaign'
}
var matches = ["{{hostBrandName}}", "{{partnerBrandNames}}", "{{hostBrandName}}", "{{campaignName}}", "{{hostBrandName}}", "{{hostBrandAddress}}", "{{campaignStartTime}}", "{{campaignStartDate}}", "{{campaignEndDate}}", "{{campaignEndTime}}", "{{signupUrl}}", "{{hostBrandName}}", "{{partnerBrandNames}}", "{{brandPrivacyPolicyUrls}}", "{{socialSharingBlock}}", "{{endDatePlusOneDay}}", "{{prizingValue}}", "{{prizingDescription}}", "{{hostBrandName}}", "{{partnerBrandNames}}", "{{hostBrandCity}}", "{{hostBrandState}}", "{{hostBrandState}}", "{{hostBrandState}}", "{{hostBrandState}}", "{{hostBrandName}}", "{{hostBrandAddress}}"];
var highlight = function (str) {
  str = str.replace('{{{socialSharingBlock}}}', '{{socialSharingBlock}}')
  return str.replace(/{{([A-Za-z]+)}}/g, '<strong><span style="background-color: #e5f3fd;">{{$1}}</span></strong>');
}

var unHighlight = function (str) {
  str = str.replace(/<strong><span style="background-color: #e5f3fd;"(\sdata-mce-style="background-color: #e5f3fd;")?>/g, '')
  str = str.replace(/<\/span><\/strong>/g, '')

  str = str.replace('{{socialSharingBlock}}', '{{{socialSharingBlock}}}');

  return str;
}

import Editor from 'util/components/terms-editor';


class TermsEdit extends React.Component{
  constructor(props) {
    super(props);
    this.updateAttr = _.debounce(this.updateAttr.bind(this), 10);
    this.confirmEdit = this.confirmEdit.bind(this);
    this.revert = this.revert.bind(this);
    this.setup = this.setup.bind(this);
  }

  save() {
    setTimeout(function() {
      toastr.success('Saved!', null, { timeout: 2000, positionClass: 'toast-bottom-center' });
      TermsDispatcher.updateTerms({
        termsUnsaved: false,
        termsFromTemplate: store.getState().currentCampaign.campaignTerms.termsFromTemplateDraft
      });
    }, 200)
  }

  confirmEdit() {
    TermsDispatcher.updateTerms({
      termsIgnoreInvalid: true
    });
    store.dispatch({
      type: 'UPDATE_TERMS',
      model: 'currentCampaign',
      data: {
        termsFromTemplateDraft: store.getState().currentCampaign.campaignTerms.tempTermsFromTemplateDraft,
        checkIgnoreInvalid: false
      }
    });
  }

  revert() {
    tinyMCE.activeEditor.setContent(highlight(this.props.currentCampaign.campaignTerms.termsFromTemplate || ''));
    store.dispatch({
      type: 'UPDATE_TERMS',
      model: 'currentCampaign',
      data: {
        checkIgnoreInvalid: false,
      }
    })
  }

  setup(e) {
    e.contentCSS.push('/css/font/font.css');
    e.contentCSS.push('/web/admin.css');
  }

  updateAttr(attr, value) {
    value = unHighlight(value);
    if (!this.props.currentCampaign.campaignTerms.termsIgnoreInvalid && (value.match(/{{[A-Za-z]+}}/g).length !== matches.length || _.difference(matches, value.match(/{{[A-Za-z]+}}/g)).length !== 0)) {
      store.dispatch({
        type: 'UPDATE_TERMS',
        model: 'currentCampaign',
        data: {
          checkIgnoreInvalid: true,
          tempTermsFromTemplateDraft: value,
          termsUnsaved: true
        }
      })
    }
    else {
      store.dispatch({
        type: 'UPDATE_TERMS',
        model: 'currentCampaign',
        data: { termsFromTemplateDraft: value, termsUnsaved: true }
      });
    }
  }

  render() {
    var campaignTerms = this.props.currentCampaign.campaignTerms || {};

    var paras = [
      <P multiline>
        {
          `Make edits here. Areas highlighted in blue {{brackets}} are updated automatically - if you edit them, you'll be breaking DojoMojo's ability to auto-update them as you make changes to your campaign, and you'll need to manually update these going forward.`
        }
      </P>
    ];

    return(
      <div>
        <TabTitleAndText
          headers={[]}
          paras={ paras }
        />

        <Editor
          { ...this.props }
          published={ campaignTerms.termsStatus === 'published' }
          model={ campaignTerms }
          updateAttr={ this.updateAttr }
          attr="termsFromTemplate"
          save={ this.save }
          height={ 350 }
          originalContent={ highlight(campaignTerms.termsFromTemplate || '') }/>
        <TermsConfirmEditModal
          revert={ this.revert }
          confirm={ this.confirmEdit }
          isOpen={ campaignTerms.checkIgnoreInvalid } />
      </div>
    )
  }
}

export default TermsEdit;
