import React, { Component } from 'react'
import theme from 'css/themes/mobile/home/pricing.css'
import ImgAndContent from '../util/img-and-content'


class OneStopHome extends Component {
  render() {
    return (
      <div className={theme.oneStopHomeContainer}>
        <p className={theme.title}>Powered by Partnerships</p>
        <p className={theme.howItWorks}>How DojoMojo Works</p>
        <ImgAndContent
          imgSrc="/images/login/mobile/home/find-brand-partners-illustration.svg"
          imgClass="find-brand-partners-illustration"
          title="Create Partnerships"
          explain="Partner with thousands of like-minded brands in our network to join or host a sweepstakes."
        />
        <ImgAndContent
          imgSrc="/images/login/mobile/home/grow-your-audience.svg"
          imgClass="grow-your-audience"
          title="Grow Your Audience"
          explain="Leverage both solo and multi-brand sweepstakes to grow Email and SMS lists faster and cheaper than ever before."
        />
        <ImgAndContent
          imgSrc="/images/login/mobile/home/coin-gate.svg"
          imgClass="drive-revenue"
          title="Drive Revenue"
          explain="Engage and convert your audience via DojoMojo's SMS Marketing capabilities while still using your preferred ESP. "
        />
      </div>
    )
  }
}

export default OneStopHome
