import React from 'react';
import { array } from 'prop-types'

import TableTheme from 'css/themes/analytics-tables/campaignTable.css'
import CampaignTableHeader from './campaign-table-header'
import CampaignAnalyticsTableRow from './campaign-analytics-table-row';

class CampaignAnalyticsTable extends React.Component {
  static proptypes = {
    campaigns: array.isRequired
  }

  render() {
    let {
      campaigns
    } = this.props;

    let campaignRows = campaigns.map((campaign, idx) => {
      return(
        <CampaignAnalyticsTableRow
          key={ idx }
          campaign={ campaign }
        />
      )
    })

    return(
      <div>
        <table 
          className={ TableTheme.analyticsTable }
          id="campaign-analytics-results-table"
        >
          <thead>
            <CampaignTableHeader />
          </thead>
          <tbody className={ TableTheme.analyticsTbody }>
            { campaignRows }
          </tbody>
        </table>
      </div>
    )
  }
}

export default CampaignAnalyticsTable;
