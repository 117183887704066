export const loading = () => ({ type: 'LOADING_DEAL_INTEGRATIONS' })
export const finished = () => ({ type: 'LOAD_DEAL_INTEGRATIONS_FINISH' })

export const clearIntegration = () => ({ type: 'CLEAR_INTEGRATION' })

export const clearConfirmation = () => ({
  model: 'confirmationModal',
  type: 'UPDATE_ATTR',
  data: {
    expand: false,
  },
})

export const loadIntegrations = dealId => ({
  http: {
    url: `/deal-integrations/all/${dealId}`,
  },
  types: [
    'LOADING_DEAL_INTEGRATIONS',
    'LOAD_DEAL_INTEGRATIONS_SUCCESS',
    'LOAD_DEAL_INTEGRATIONS_FAILURE',
  ],
})

export const loadIntegration = (dealId, integrationType) => ({
  http: {
    url: `/deal-integrations/${dealId}/${integrationType}`,
  },
  types: [
    'LOADING_DEAL_INTEGRATIONS',
    'LOAD_DEAL_INTEGRATION_SUCCESS',
    'LOAD_DEAL_INTEGRATIONS_FAILURE',
  ],
})

export const updateIntegration = (dealIntegrationId, data) => ({
  http: {
    url: `/deal-integrations/${dealIntegrationId}`,
    method: 'PUT',
    data,
  },
  types: [
    'LOADING_UPDATE_DEAL_INTEGRATIONS',
    'UPDATE_DEAL_INTEGRATIONS_SUCCESS',
    'UPDATE_DEAL_INTEGRATIONS_FAILURE',
  ],
})

export const deleteIntegration = dealIntegrationId => ({
  http: {
    url: `/deal-integrations/${dealIntegrationId}`,
    method: 'DELETE',
  },
  types: [
    'LOADING_DEAL_INTEGRATIONS',
    'DELETE_DEAL_INTEGRATIONS_SUCCESS',
    'DELETE_DEAL_INTEGRATIONS_FAILURE',
  ],
})

export const fetchIntegrationCampaigns = integration => {
  const urls = {
    mailchimp: `/mailchimp/fetch-campaigns?dc=${integration.details.dc}&mailchimpAccessToken=${integration.details.mailchimpAccessToken}`,
  }

  return {
    http: {
      url: urls[integration.integration_type],
    },
    types: [
      'LOADING_DEAL_INTEGRATIONS_CAMPAIGNS',
      'LOADING_DEAL_INTEGRATIONS_CAMPAIGNS_SUCCESS',
      'LOADING_DEAL_INTEGRATIONS_FAILURE',
    ],
  }
}
