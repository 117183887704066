import React, { Component } from 'react'
import { string, bool } from 'prop-types'
import theme from 'css/themes/homePage/easy-step.css'

class EasyStep extends Component {
  static propTypes = {
    imgSrc: string.isRequired,
    title: string.isRequired,
    explain: string.isRequired,
  }

  render() {
    const {
      imgSrc, title, explain, twoColumns, imgWidth
    } = this.props

    const containerStyling = twoColumns
      ? theme.doubleEasyStepContainer
      : theme.easyStepContainer

    const [imgName, extension] = imgSrc.split('.')

    const srcSet = `
      ${imgName}.${extension},
      ${imgName}@2x.${extension} 2x,
      ${imgName}@3x.${extension} 3x
    `

    return (
      <div className={containerStyling}>
        <img src={imgSrc} srcSet={srcSet} style={{ width: imgWidth || '300px' }} />
        <p className={theme.title}>{title}</p>
        <p className={theme.explain}>{explain}</p>
      </div>
    )
  }
}

export default EasyStep
