import React from 'react';
import moment from 'moment-timezone';
import FormGroup from 'util/components/form-group';
import _ from 'lodash';

class PeriodPicker extends React.Component {
  constructor(props) {
    super(props);
    this.updateAttr = this.updateAttr.bind(this);
  }

  updateAttr(attr, value) {
    let data = {};
    data[attr] = value;

    store.dispatch({
      model: 'admin',
      type: 'UPDATE_ATTR',
      data: data
    });
  }

  render() {
    const tabs = [{
      label: 'Summary Dashboard',
      value: 'summary'
    }, {
      label: 'User Detail',
      value: 'user'
    }, {
      label: 'Sweeps Detail',
      value: 'sweeps'
    }, {
      label: 'Feature Detail',
      value: 'feature'
    }, {
      label: 'House Brands',
      value: 'house'
    }, {
      label: 'OKR Metrics',
      value: 'okr'
    }, {
      label: 'Network Graph',
      value: 'network'
    }];

    let startOfInterval = this.props.admin.interval == 'week' ? 'isoweek' : this.props.admin.interval;

    let endDateOptions = ['Please Select'];
    _.times(30, (idx) => {
      
      let date = moment(new Date()).startOf(startOfInterval).subtract(idx - 1, this.props.admin.interval + 's').format('YYYY-MM-DD');
      endDateOptions.push(date);
    });

    return (
      <div>
        <FormGroup
          type="select"
          attrName="interval"
          label="Reporting Interval"
          options={[{
            value: 'week',
            label: 'Weekly'
          }, {
            value: 'month',
            label: 'Monthly'
          }]}
          model={this.props.admin}
          updateAttr={this.updateAttr} />
        <FormGroup
          type="select"
          attrName="endDate"
          label="Ending Date"
          options={endDateOptions}
          model={this.props.admin}
          updateAttr={this.updateAttr} />
        <FormGroup
          type="select"
          attrName="section"
          label="Page Section"
          options={tabs}
          model={this.props.admin}
          updateAttr={this.updateAttr} />
      </div>
    );
  }
}

export default PeriodPicker;
