import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import COLOURS from 'util/colours'
import { P, H4 } from 'visual-components/util/texts'
import _ from 'lodash'

import Label from 'visual-components/util/inputs/label'
import ImageUploader from 'components/util/image-uploader'
import SimpleColorPicker from 'util/components/color-picker'

import FormlessField from 'visual-components/util/inputs/FormlessField'
import InputValidator from 'util/input-validator'
import { MobileMarketingContext } from './MobileMarketingContext'

const styles = {
  unlockCopyInput: {
    paddingBottom: 12,
  },
  buttonColors: {
    whiteSpace: 'nowrap',
    marginBottom: '28px',
    display: 'flex',
  },
  colorPicker: {
    paddingRight: 32,
  },
}

function MobileMarketingForm(props) {
  const { classes: css } = props

  const {
    setPostEntry,
    postEntry,
    loading,
    onPostEntryError,
    uploadImage,
  } = React.useContext(MobileMarketingContext)

  const onError = (field, isError) => onPostEntryError({ [field]: isError })
  const setColors = useCallback(which => _.debounce(color => setPostEntry({ [which]: { color } }), 200))

  return (
    <>
      <H4 style={{ marginBottom: '16px' }}>
        <em>Your Post Entry Form</em>
      </H4>
      <FormlessField
        name="form_header"
        label="Form Header"
        error={postEntry.errorFields.form_header}
        value={postEntry.form_header}
        onChange={form_header => setPostEntry({ form_header })}
        dirty={postEntry.dirty.form_header}
        onError={onError}
        characterLimit={255}
        validations={[InputValidator.Required]}
      />
      <Label
        label="Hero Image (Recommended)"
        sublabel="Upload a PNG, JPG or GIF. 1280×720 px is the recommended size."
      />
      <ImageUploader
        onDrop={uploadImage}
        src={postEntry.image_url}
        loading={loading}
      />
      <FormlessField
        name="image_link"
        label="Hero Image Link (Optional)"
        error={postEntry.errorFields.image_link}
        value={postEntry.image_link}
        onChange={image_link => setPostEntry({ image_link })}
        sublabel={[
          <span style={{ color: COLOURS.warningRed }}>Warning: </span>,
          'Including a click-through link may reduce the effectiveness of your SMS mobile offer.',
        ]}
        sublabelStyle={{ marginLeft: '0px', marginTop: '8px' }}
        validations={[InputValidator.IsWebsite]}
        dirty={postEntry.dirty.image_link}
        onError={onError}
      />
      <FormlessField
        name="title"
        label="Title"
        value={postEntry.title}
        onChange={title => setPostEntry({ title })}
        // TODO: Required?
      />
      <Label label="Description" style={{ marginBottom: 12 }} />
      <div style={{ display: 'flex' }}>
        <div style={{ width: '170px' }}>
          <P>Enter your phone number</P>
        </div>
        <div style={{ width: '430px' }}>
          <FormlessField
            name="unlock_copy"
            value={postEntry.unlock_copy}
            onChange={unlock_copy => setPostEntry({ unlock_copy })}
            style={{
              borderLeft: 'none',
              borderRight: 'none',
              borderTop: 'none',
              borderRadius: 0,
              color: COLOURS.azure,
              fontFamily: 'Larsseit-Medium',
              height: 18,
              padding: '0px 4px 4px',
            }}
            className={css.unlockCopyInput}
          />
        </div>
      </div>
      <P style={{ marginBottom: 32 }}>and receive mobile marketing texts.*</P>
      <FormlessField
        name="button_text"
        label="Button Text"
        error={postEntry.errorFields.button_text}
        value={postEntry.button_text}
        onChange={button_text => setPostEntry({ button_text })}
        validations={[InputValidator.Required]}
        dirty={postEntry.dirty.button_text}
        onError={onError}
      />
      <div className={css.buttonColors}>
        <SimpleColorPicker
          label="Button Color"
          value={postEntry.button_color}
          onChange={setColors('button_color')}
          customStyleClass={css.colorPicker}
          disableAlpha
        />
        <SimpleColorPicker
          label="Button Text Color"
          value={postEntry.button_text_color}
          onChange={setColors('button_text_color')}
          disableAlpha
        />
      </div>
    </>
  )
}

MobileMarketingForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectSheet(styles)(MobileMarketingForm))
