import React from 'react';
import _ from 'lodash'

import Dropdown from 'react-toolbox/lib/dropdown';
import dropTheme from 'css/themes/Dropdown.css'

class IndustryDropdown extends React.Component {
  constructor(props) {
    super(props);

    // this.openSelect = this.openSelect.bind(this);
    // this.closeSelect = this.closeSelect.bind(this);
    // this.state = {
    //   select: false
    // }
  }

  render() {
    var {
      value,
      onChange,
      blocked
    } = this.props;

    var options = _.map(LANGUAGE.brandSettings.tags.tags, function  (item, idx) {
      var block = _.some(blocked, function (blockedTag) {
        return item === blockedTag
      });

      if (block) {
        return;
      }

      return {
        label: item,
        value: item
      }
    });

    options = _.compact(options);

    if (!value) {
      value = ''
      options.unshift({
        label: 'Please Select',
        value: '',
      })
    }

    return (
      <Dropdown
        theme={dropTheme}
        auto
        onChange={onChange}
        source={options}
        value={value}
      />
    );
  }
}

export default IndustryDropdown;
