import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MobileMarketingSummaryCard from 'visual-components/analytics/MobileMarketingSummaryCard'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'

const mapState = ({ analyticsDashboard }) => ({
  dashboardSmsSummary: analyticsDashboard.dashboardSmsSummary,
  datePicker: analyticsDashboard.datePickerTopRegions,
  oldestDate: analyticsDashboard.oldestDate,
})

class MobileMarketingSummary extends Component {
  static propTypes = {
    dashboardSmsSummary: PropTypes.object.isRequired,
    datePicker: PropTypes.object.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
  }

  changeFilter = (startDate, endDate) => {
    const data = {
      startDate,
      endDate,
      metric: 'dashboardSmsSummary',
    }

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  render() {
    const { dashboardSmsSummary, datePicker, oldestDate } = this.props
    if (!dashboardSmsSummary.data) return null
    const data = dashboardSmsSummary.data[0]

    const formattedData = {
      smsSubscribers: data.sms_subscribers,
      messages: data.messages,
      clicks: data.clicks,
      ctr: data.messages > 0 ? data.clicks / data.messages : 0,
    }

    return (
      <MobileMarketingSummaryCard
        data={formattedData}
        changeFilter={this.changeFilter}
        datePicker={datePicker}
        oldestDate={oldestDate}
      />
    )
  }
}

export default withRouter(connect(mapState)(MobileMarketingSummary))
