var model = 'topPartners';
import _ from 'lodash'

const defaultState = {
  rid: model,
  partners: [],
  sortVar: 'entries',
  reversed: false
};

var sort = function (partners, sortVar, reversed) {
  partners = _.sortBy(partners, sortVar);

  if (!reversed) {
    partners = partners.reverse();
  }

  return partners.splice(0,5);
}

var basePartner = { 
  entries: 0,
  impressions: 0,
  visits: 0,
  // conversions: 0,
  postentries: 0,
  newEmails: 0
}

var metricTypes = ['entries', 'visits','impressions'];
var metaMetricTypes = [/*'conversions',*/ 'postentries', 'newEmails'];

var TopPartners = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    LOAD: function () {
      var partners = store.getState().brandCampaigns.partners;

      delete partners[action.data.brandId];

      partners = _.map(partners, function (partner, id) {
        partner.entryRate = partner.entries / partner.visits;
        _.each(metaMetricTypes, function (metricType) {
          partner[metricType + 'Rate'] = partner[metricType] / partner.entries;
          if (isNaN(partner[metricType + 'Rate'])) {
            partner[metricType + 'Rate'] = 0;
          }
        });
        if (isNaN(partner.entryRate)) {
          partner.entryRate = 0;
        }
        partner.newEmails = partner.newEmails || 0;
        return partner;
      });

      var topFive = sort(partners, state.sortVar, state.reversed);

      return _.extend({}, state, { partners: partners, topFive: topFive });
    },
    SORT: function () {
      var topFive = sort(state.partners, action.data.sortVar, action.data.reversed);

      return _.extend({}, state, action.data, { topFive: topFive });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default TopPartners;
