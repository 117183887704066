import React from 'react';
import { func, bool, array, number } from 'prop-types';
import { H6 } from 'visual-components/util/texts';

import QuestStyles from 'css/themes/QuestStyles.css';
import COLOURS from 'util/colours';
import { Button } from 'react-toolbox/lib/button';
import buttonTheme from 'css/themes/Buttons.css'

class ClosedQuestBar extends React.Component {
  static propTypes = {
    collapseFunction: func,
    isOpened: bool.isRequired,
    quests: array.isRequired,
    completedQuestsCount: number.isRequired
  }

  render() {
    const {
      collapseFunction,
      isOpened,
      quests,
      goToProfile,
      completedQuestsCount,
      atProfile
    } = this.props;

    const OpenOption = (
      <div className={ `${QuestStyles.pToggle} ${QuestStyles.expand}` }>
        <a href="javascript:void(0);">
          <H6 white>
            Expand
            <img src='/images/icons/white-expand-arrow.svg'
              style={{
                paddingLeft: '5px',
                height: '9px'
              }}/>
            </H6>
          </a>
      </div>
    );

    const CloseOption = (
      <div className={ `${QuestStyles.pToggle} ${QuestStyles.collapse}` }>
        <a href="javascript:void(0);">
          <H6 azure>
            Collapse
            <img src='/images/icons/blue-collapse-icons/industry-list-collapse-arrow.svg'
              style={{
                paddingLeft: '5px',
                height: '9px'
              }}/>
          </H6>
        </a>
      </div>
    );

    const totalQuestsCount = quests.length;
    const percentDone = Math.round((completedQuestsCount / totalQuestsCount) * 100);

    return(
      <div className={ QuestStyles.collapsedContainer }
        style={{
          backgroundColor: isOpened || !atProfile ? 'transparent' : COLOURS.azure
      }}>
        <div className={ QuestStyles.contentContainer }>
          { isOpened
            ? <div style={{
                height: '32px'
              }} />
            : <div style={{
                display: 'inline-block',
                height: '64px',
                marginLeft: '70px',
                paddingTop: atProfile ? '27px' : '15px',
                textAlign: 'center'
              }}>
                <p style={{
                  display: 'inline-block',
                  fontFamily: 'Larsseit-Medium',
                  color: atProfile ? 'white' : '#2c3541',
                  fontSize: '16px',
                  marginRight: '32px'
                }}>
                  { `You're ${percentDone}% of the way to growing your audience!  ${!atProfile ? 'Updating your profile is the first step.' : ''}`  }
                </p>
                { !atProfile && <Button
                  onClick={ goToProfile }
                  className={ `${buttonTheme.blueButton} ${buttonTheme.noMargin}` }
                  theme={ buttonTheme }
                  label="Complete Profile"
                  flat
                /> }
              </div>
          }
          <div onClick={ collapseFunction }
            style={{
              display: 'inline-block',
              float: 'right',
              position: 'absolute',
              right: '16px',
              marginTop: '12px'
          }}>
            { isOpened && CloseOption }
            { !isOpened && atProfile && OpenOption}
          </div>
        </div>
      </div>
    );
  }
};

export default ClosedQuestBar;
