import React from 'react'
import DojoModal from 'util/components/dojomodal'
import { H2, P } from 'visual-components/util/texts'

class WinnerModal extends React.Component {

  render() {
    const { currentCampaign: { winner }, isOpen, hideModal } = this.props

    if (winner) {
      return (
        <div>
          <DojoModal
            isOpen={ isOpen }
            hideModal={ hideModal }
            title='And The Winner Is...'
          >
            <div className="text-center">
              <div style={{
                marginTop: '30px'
              }}>
                <P>
                  { winner.fullname || `${winner.firstName} ${winner.lastName || ''}!` }
                </P>
              </div>
              <div>
                <P>
                  As the host, you‘ll need to notify the winner of their prize.
                </P>
              </div>
            </div>
            <div style={{
              marginTop: '60px'
            }}>
              <div>
                <div className="row">
                  <div style={{
                    marginTop: '30px'
                  }}>
                    <div className="col-xs-6 text-left">
                      <P>
                        Full Name:
                      </P>
                    </div>
                    <div className="col-xs-6 text-right">
                      <P>
                        { winner.fullname ? winner.fullname : (`${winner.firstName} ${winner.lastName}`) }
                      </P>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div style={{
                    marginTop: '30px'
                  }}>
                    <div className="col-xs-6 text-left">
                      <P>
                        Email Address:
                      </P>
                    </div>
                    <div className="col-xs-6 text-right">
                      <P>
                        { winner.email }
                      </P>
                    </div>
                  </div>
                </div>
                { winner.zipcode ?
                    <div className="row">
                      <div style={{
                        marginTop: '30px'
                      }}>
                        <div className="col-xs-6 text-left">
                          <P>
                            Zip Code:
                          </P>
                        </div>
                        <div className="col-xs-6 text-right">
                          <P>
                            { winner.zipcode }
                          </P>
                        </div>
                      </div>
                    </div>
                  :
                    null
                }
                { winner.gender ?
                    <div className="row">
                      <div style={{
                        marginTop: '30px'
                      }}>
                        <div className="col-xs-6 text-left">
                          <P>
                            Gender:
                          </P>
                        </div>
                        <div className="col-xs-6 text-right">
                          <P>
                            { winner.gender }
                          </P>
                        </div>
                      </div>
                    </div>
                  :
                    null
                }
                { winner.birthday ?
                    <div className="row">
                      <div style={{
                        marginTop: '30px'
                      }}>
                        <div className="col-xs-6 text-left">
                          <P>
                            Birthday:
                          </P>
                        </div>
                        <div className="col-xs-6 text-right">
                          <P>
                            { winner.birthday }
                          </P>
                        </div>
                      </div>
                    </div>
                  :
                    null
                }
              </div>
              <div className="row">
                <div style={{
                  marginTop: '30px'
                }}>
                  <div className="col-xs-6 text-left">
                    <P>
                      Source Brand:
                    </P>
                  </div>
                  <div className="col-xs-6 text-right">
                    <P>
                      { winner.fromBrand }
                    </P>
                  </div>
                </div>
              </div>
            </div>
          </DojoModal>
        </div>
      )
    } else {
      return null
    }
  }
}

export default WinnerModal
