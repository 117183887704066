import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { validate as validateEmail } from 'email-validator'
import { Link } from 'react-router-dom'
import { Button } from 'react-toolbox/lib/button'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import ChipInput from 'visual-components/util/inputs/chip-input'
import InvoiceRow from 'visual-components/brandSettings/invoice-row'
import AppDispatcher from 'dispatchers/app-dispatcher'
import * as MetricsActions from 'actions/analytics-actions'
import * as BillingEmailsActions from 'actions/billing-emails-actions'
import * as SmsActions from 'actions/sms-actions'
import requestHeaders from 'request-config'
import axios from 'axios'

import {
  H2, H3, H6, P,
} from 'visual-components/util/texts'

import Spinner from 'util/components/spinner'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'

import SmsMarketingSettings from 'visual-components/brandSettings/sms-marketing-settings'

import { getPlanLabel, isOffsitePlan } from 'util/brand-stripe-belt-helper'

import ButtonTheme from 'css/themes/Buttons.css'
import BillingSettingsTheme from 'css/themes/payments/billing-settings.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const planDetailsMap = {
  white: [
    'Accept unlimited campaign invitations',
    'SMS Marketing Suite access',
    '1 monthly Solo Sweepstakes',
  ],
  greenFixed: [
    '10k SMS subscribers',
    'Unlimited sweepstakes',
    '50 monthly Mojo Messages',
  ],
  black: [
    '50k SMS subscribers',
    'All Conversion Actions',
    'Unlimited Mojo Messages',
  ],
  pro: [
    'Unlimited SMS subscribers',
    'All advanced SMS Marketing features',
    'All sweepstakes optimizations',
  ],
  elite: [
    'White glove enterprise support',
    'Minimum monthly lead acquisition',
    'Full access to advanced platform features',
  ],
  pro: [
    'Unlimited messages',
    'Unlimited Social Actions',
    'Custom CTA',
  ],
  trial: [
    'SMS Marketing Suite access',
    'All Conversion Actions',
    'Unlimited Mojo Messages',
  ],
}

const renderInvoices = brandId => invoice => (
  <InvoiceRow key={invoice.id} invoice={invoice} currentBrandId={brandId} />
)

const themes = {
  Button: ButtonTheme,
  BillingSettings: BillingSettingsTheme,
  DialogTheme,
}

const mapState = state => ({
  profile: state.profile,
  currentBrand: state.currentBrand,
  customer: state.customer,
  invoices: state.invoices,
  billingEmails: state.billingEmails,
  sms: state.sms,
})

const dispatchToProps = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
  billingEmailsActions: bindActionCreators(BillingEmailsActions, dispatch),
  smsActions: bindActionCreators(SmsActions, dispatch),
})

class BillingSettings extends PureComponent {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    invoices: PropTypes.object.isRequired,
    billingEmails: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    sms: PropTypes.object.isRequired,
    smsActions: PropTypes.object.isRequired,
    billingEmailsActions: PropTypes.object.isRequired,
  }

  state = {
    shopifyAppUrl: null,
    loading: false,
  }

  componentDidMount() {
    const {
      currentBrand, smsActions, customer, invoices, billingEmailsActions,
    } = this.props

    AppDispatcher.loadOnce({
      model: 'customer',
      endpoint: '/customer',
      data: customer,
    })

    AppDispatcher.loadOnce({
      model: 'invoices',
      endpoint: '/billing-history',
      data: invoices,
    })

    axios.get('/shopify-app-url', { headers: requestHeaders() })
      .then(({ data }) => {
        this.setState({ shopifyAppUrl: data })
      })

    billingEmailsActions.load(currentBrand.id)
    smsActions.fetchBrandBank(currentBrand.id)
  }

  onROIButtonClick = () => {
    const { history, metricsActions, location } = this.props

    metricsActions.create('ROINavigated', { meta: { from: location.pathname } })

    return history.push('/calculate-roi')
  }

  billingSupport = (subject = 'Billing Support') => {
    const { profile, currentBrand } = this.props

    store.dispatch({
      model: 'contactSupport',
      type: 'UPDATE_ATTR',
      data: {
        modalOpen: true,
        brand: currentBrand.accountname,
        name: profile.fullName || `${profile.firstName || ''} ${profile.lastName || ''}`,
        email: profile.email,
        subject,
      },
    })
  }

  upgradePlan = () => {
    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: 'billingSettings',
        upgradeOriginalLocation: '/profile/brand/billing',
      },
    })

    window.location.replace('/app/upgrade-plan')
  }

  handleCancelShopifySubscription = async () => {
    if (!confirm('Are you sure you want to cancel your Shopify subscription?')) return

    this.setState({ loading: true })
    axios.delete('/shopify-subscription', { headers: requestHeaders() })
      .then(() => {
        window.location.reload()
      })
  }

  handleAddEmail = email => {
    const { currentBrand, billingEmailsActions } = this.props
    if (!validateEmail(email)) {
      toastr.error(`${email} is an invalid email`, null, {
        timeOut: 2000,
        positionClass: 'toast-bottom-center',
      })
      return
    }

    billingEmailsActions.addEmail(currentBrand.id, email)
  }

  handleRemoveEmail = email => {
    const { currentBrand, billingEmailsActions } = this.props
    billingEmailsActions.deleteEmail(currentBrand.id, email)
  }

  toggleSmsCancelModal = () => {
    this.billingSupport('Cancel SMS Marketing Plan')
  }

  renderCardDetails = source => {
    if (!source) return null

    // format city, state and zip
    let cityStateZip
    if (source.address_city && source.address_state) {
      const cityState = [source.address_city, source.address_state].join(', ')
      cityStateZip = [cityState, source.address_zip].join(' ')
    } else {
      cityStateZip = source.address_zip
    }

    return (
      <div>
        <Row style={{ marginBottom: '24px' }}>
          <Col style={{ width: '152px' }}>
            <div style={{ marginBottom: '8px' }}>
              <H6>Name</H6>
            </div>
            <P>{source.name}</P>
          </Col>
          <Col style={{ width: '124px' }}>
            <div style={{ marginBottom: '8px' }}>
              <H6>Card Number</H6>
            </div>
            <P>{`**** **** **** ${source.last4}`}</P>
          </Col>
          <Col style={{ width: '40px' }}>
            <div style={{ marginBottom: '8px' }}>
              <H6>Expiry</H6>
            </div>
            <P>
              {`${source.exp_month}/${source.exp_year}`}
            </P>
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col>
            <div style={{ marginBottom: '8px' }}>
              <H6>Billing Zip Code</H6>
            </div>
            <P>{cityStateZip}</P>
          </Col>
        </Row>
      </div>
    )
  }

  renderAccountDetails = source => {
    if (!source) return null

    const accountTypeMap = {
      individual: 'Individual',
      company: 'Company',
    }

    return (
      <div>
        <Row style={{ marginBottom: '24px' }}>
          <Col style={{ width: '195px' }}>
            <div style={{ marginBottom: '8px' }}>
              <H6>Owner / Business Name</H6>
            </div>
            <P>{source.account_holder_name}</P>
          </Col>
          <Col style={{ width: '124px' }}>
            <div style={{ marginBottom: '8px' }}>
              <H6>Type</H6>
            </div>
            <P>{accountTypeMap[source.account_holder_type]}</P>
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col>
            <div style={{ marginBottom: '8px' }}>
              <H6>Account</H6>
            </div>
            <P>{`${source.bank_name} - *****${source.last4}`}</P>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const {
      currentBrand, customer, invoices, billingEmails, sms,
    } = this.props

    const {
      shopifyAppUrl,
      loading,
    } = this.state

    if (loading || !customer.loaded || currentBrand.loading || sms.loading) return <Spinner />

    const source = customer.sources
      && customer.sources.data
      && customer.sources.data.find(source => source.id === customer.default_source)

    const invoiceItems = invoices.items.map(renderInvoices(currentBrand.id))

    const planLabel = currentBrand.isSensei ? 'Elite' : getPlanLabel(currentBrand.stripe_belt)
    const planDetails = planDetailsMap[currentBrand.isSensei ? 'elite' : currentBrand.belt]

    const offsitePlan = isOffsitePlan(currentBrand.stripe_belt)
    const showSms = !currentBrand.features.block_sms

    return (
      <div style={{ paddingTop: '32px' }}>
        <Helmet>
          <title>Billing</title>
        </Helmet>
        <Grid>
          <div style={{ width: '100%' }}>
            <Row style={{ marginBottom: '12px' }}>
              <Col xs={5}>
                <H2 azure>Billing</H2>
              </Col>
              <Col xs={7}>
                {(currentBrand.features.roiCalculator || currentBrand.belt === 'white') && (
                  <Button
                    onClick={this.onROIButtonClick}
                    label="Calculate Your ROI"
                    className={`${themes.Button.raised} ${themes.Button.blueButton}`}
                    theme={themes.Button}
                    style={{
                      display: 'inline-block',
                      marginTop: '-13px',
                      marginRight: '8px',
                    }}
                  />
                )}
                {!offsitePlan && (
                  <Button
                    onClick={this.upgradePlan}
                    label="Upgrade Plan"
                    className={themes.Button.greenButton}
                    theme={themes.Button}
                    raised
                    style={{
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '14px',
                      height: '36px',
                      marginTop: '-13px',
                      marginRight: '8px',
                    }}
                  />
                )}
                {
                  currentBrand.payment_method === 'shopify' && (
                    <Button
                      onClick={this.handleCancelShopifySubscription}
                      label="Cancel Subscription"
                      className={themes.Button.greenButton}
                      theme={themes.Button}
                      raised
                      style={{
                        fontFamily: 'Larsseit-Medium',
                        fontSize: '14px',
                        height: '36px',
                        marginTop: '-13px',
                      }}
                    />
                  )
                }
              </Col>
            </Row>
          </div>
          <Row style={{ marginBottom: '24px' }}>
            <Col xs>
              <P multiline>
                Please
                <a style={{ margin: '0 5px' }} onClick={this.billingSupport}>
                  contact us
                </a>
                if you have any questions.
              </P>
            </Col>
          </Row>
          <Row style={{ marginBottom: '32px' }}>
            {planDetails ? (
              <Col style={{ width: '360px', marginRight: '32px' }}>
                <div style={{ marginBottom: '20px' }}>
                  <H3 coral>
                    <small>Your Current Plan</small>
                  </H3>
                </div>

                <Card style={{ width: '100%', height: '172px', padding: '16px' }}>
                  <Row style={{ marginBottom: '12px' }}>
                    <div style={{ display: 'inline-block' }}>
                      <H3>
                        <small>{planLabel}</small>
                      </H3>
                    </div>
                  </Row>
                  <Row style={{ height: '66px', marginBottom: '24px' }}>
                    <ul style={{ width: '100%', paddingLeft: '24px' }}>
                      <li>
                        <P multiline>{planDetails[0]}</P>
                      </li>
                      <li>
                        <P multiline>{planDetails[1]}</P>
                      </li>
                      <li>
                        <P multiline>{planDetails[2]}</P>
                      </li>
                    </ul>
                  </Row>
                  {!offsitePlan && (
                    <Row>
                      <P>
                        <a href="/app/upgrade-plan">Upgrade Plan</a>
                      </P>
                    </Row>
                  )}
                </Card>
              </Col>
            ) : null}
            <Col style={{ width: '360px' }}>
              <div style={{ marginBottom: '20px', display: 'inline-block' }}>
                <H3 coral>
                  <small>Default Payment Method</small>
                </H3>
              </div>

              <Card style={{ width: '100%', height: '172px', padding: '16px' }}>
                {source && source.object === 'card'
                  ? this.renderCardDetails(source)
                  : this.renderAccountDetails(source)}
                <Row>
                  <P>
                    <Link to="/profile/brand/change-payment-methods/billing">
                      Change Default Method
                    </Link>
                  </P>
                </Row>
                {
                  currentBrand.payment_method === 'shopify' && (
                    <Row style={{ marginTop: '20px' }}>
                      <P>
                        <a
                          href={shopifyAppUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Manage Your Plan on Shopify
                        </a>
                      </P>
                    </Row>
                  )
                }
              </Card>
            </Col>
          </Row>
          {showSms && (
            <SmsMarketingSettings
              sms={sms}
              currentBrand={currentBrand}
              toggleSmsCancelModal={this.toggleSmsCancelModal}
              upgradePlan={this.upgradePlan}
            />
          )}
          {
            currentBrand.payment_method !== 'shopify' && (
              <>
                <Row style={{ marginBottom: '32px' }}>
                  <Col style={{ width: '752px', marginBottom: '16px' }}>
                    <H3 coral>
                      <small>Send Invoice Copies</small>
                    </H3>
                  </Col>
                  <Col style={{ width: '752px' }}>
                    <Card style={{ width: '100%', padding: '16px' }}>
                      <div style={{ marginBottom: '16px' }}>
                        <P>
                          We'll send invoice copies to the emails below. This will take effect at the
                          start of the next billing cycle.
                        </P>
                      </div>

                      <ChipInput
                        list={billingEmails.emails}
                        onItemAdded={this.handleAddEmail}
                        onItemDeleted={this.handleRemoveEmail}
                        inputProps={{
                          placeholder: 'Hit tab, comma, or return to add multiple email addresses.',
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: '752px' }}>
                    <div style={{ marginBottom: '20px', display: 'inline-block' }}>
                      <H3 coral>
                        <small>Billing History</small>
                      </H3>
                    </div>

                    <table style={{ marginBottom: '6px', width: '100%' }}>
                      <thead>
                        <tr>
                          <th style={{ width: '420px', paddingLeft: '16px' }}>
                            <H6>Invoice Number</H6>
                          </th>
                          <th style={{ width: '140px' }}>
                            <H6>Date</H6>
                          </th>
                          <th style={{ width: '50px' }}>
                            <H6>Amount</H6>
                          </th>
                          <th style={{ width: '126px' }} />
                        </tr>
                      </thead>
                    </table>
                    <MaterialSpinner when={invoices.loading} />
                    <Card style={{ width: '752px' }}>
                      <table style={{ width: '100%' }}>
                        <tbody>{invoiceItems.length ? invoiceItems : null}</tbody>
                      </table>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
        </Grid>
      </div>
    )
  }
}

export default connect(
  mapState,
  dispatchToProps
)(BillingSettings)
