import React, { PureComponent as Component } from 'react'
import { bool, string } from 'prop-types'
import { H2 } from 'visual-components/util/texts'

class EmptyBonzai extends Component {
  static propTypes = {
    noTree: bool,
    description: string.isRequired,
  }

  static defaultProps = {
    noTree: false,
  }

  render() {
    const { noTree, description } = this.props

    return (
      <div style={{ width: '100%' }}>
        {noTree ? null : (
          <div style={{ textAlign: 'center', marginTop: '70px' }}>
            <img src="/images/bonzai.svg" />
          </div>
        )}
        <div
          style={{
            textAlign: 'center',
            marginTop: '32px',
            width: '440px',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <H2 multiline cloudGrey>
            {description}
          </H2>
        </div>
      </div>
    )
  }
}

export default EmptyBonzai
