import React, { Component } from 'react'
import { array, number, bool, func } from 'prop-types'

import theme from 'css/themes/infiniteScroll.css'

class InfiniteScroll extends Component {
  static propTypes = {
    handleScroll: func.isRequired,
    elements: array.isRequired,
    elementsPerRow: number.isRequired,
    rowHeight: number.isRequired,
    buffer: number,
    showAll: bool,
    noScrollBar: bool,
  }

  static defaultProps = {
    buffer: 0,
    showAll: false,
    noScrollBar: false,
  }

  state = {
    availableHeight: 0,
    scrollTop: 0,
    rowsDisplayed: 0,
  }

  componentDidMount() {
    const {
      rowHeight,
    } = this.props

    const availableHeight = this.node.clientHeight
    const rowsDisplayed = Math.ceil(availableHeight / rowHeight)

    this.setState({
      availableHeight,
      rowsDisplayed,
    })
  }

  handleScroll = event => {
    const position = event.target.scrollTop
    this.setState({
      scrollTop: position,
    })

    const {
      rowHeight,
      elements,
      elementsPerRow,
      buffer,
      handleScroll,
    } = this.props

    const availableHeight = rowHeight * Math.ceil(elements.length / elementsPerRow)

    if (handleScroll && buffer >= availableHeight - position) {
      handleScroll()
    }
  }

  render() {
    const {
      availableHeight,
      scrollTop,
      rowsDisplayed,
    } = this.state

    const {
      elements,
      elementsPerRow,
      rowHeight,
      showAll,
      noScrollBar,
    } = this.props

    const numOfRows = Math.ceil(elements.length / elementsPerRow)
    const totalHeight = rowHeight * numOfRows
    const scrollBottom = scrollTop + availableHeight

    const startIndex = Math.max(0, Math.floor(scrollTop / rowHeight) * elementsPerRow - elementsPerRow)
    const endIndex = Math.min(elements.length, Math.ceil(scrollBottom / rowHeight) * elementsPerRow + (elementsPerRow * rowsDisplayed))

    const elementsToShow = showAll ? elements : []

    if (!showAll) {
      let index = startIndex

      while (index < endIndex) {
        elementsToShow.push(elements[index])
        index++
      }
    }

    return (
      <div
        className={`${theme.scrollableBox} ${noScrollBar ? theme.noScrollBar : ''}`}
        onScroll={this.handleScroll}
        ref={node => this.node = node}
      >
        <div
          className={theme.scrollableInner}
          style={{
            height: `${totalHeight}px`,
            paddingTop: showAll ? 0 : `${Math.round((startIndex / elementsPerRow) * rowHeight)}px`,
          }}
        >
          { elementsToShow }
        </div>
      </div>
    )
  }
}

export default InfiniteScroll
