import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { P } from 'visual-components/util/texts';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import ExplorePageTitle from 'visual-components/shared/explore/explore-page-title';
import GeographicMapDistributionCard from 'visual-components/analytics/geographic-map-distribution-card';
import GenderDistributionCard from 'visual-components/analytics/gender-distribution-card';
import IncomeDistributionCard from 'visual-components/analytics/income-distribution-card';

import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';
import Spinner from 'util/components/spinner';
import PartnershipCampaigns from './partnership-analytics/PartnershipCampaigns'
import OverviewBar from 'visual-components/analytics/util/overview-bar';
import EventChart from 'visual-components/analytics/event-chart';


const mapState = ({analyticsPartnership, partnershipCampaignsSearch}) => {
  return {
    analyticsPartnership,
    partnershipCampaignsSearch
  }
}

class PartnershipAnalytics extends React.Component {
  componentWillMount() {
    let {
      match: {
        params: {
          partnershipId
        }
      },
    } = this.props;

    store.dispatch({
      model: 'partnershipCampaignsSearch',
      type: 'UPDATE_ATTR',
      payload: {
        partnerBrandId: Number(partnershipId)
      }
    })
  }

  componentDidMount() {
    let {
      history,
      match: {
        params: {
          partnershipId
        }
      },
    } = this.props;

    AnalyticsDispatcher.loadDefaultPartnershipAnalytics(partnershipId);
  }

  componentWillUnmount() {
    store.dispatch({
      model: 'analyticsPartnership',
      type: 'CLEAR'
    })
  }

  exportCsv = () => {
    let {
      analyticsPartnership
    } = this.props;

    let geographicDist = [];
    let genderDist = [];
    let incomeDist = [];

    Object.keys(analyticsPartnership.partnershipGeographicDist).forEach(key => {
      geographicDist.push({
        state: key,
        count: analyticsPartnership.partnershipGeographicDist[key].count,
        percentage: `${(analyticsPartnership.partnershipGeographicDist[key].percent * 100).toFixed(2)}%`
      })
    })

    if (analyticsPartnership.partnershipGenderDist) {
      analyticsPartnership.partnershipGenderDist.forEach(type => {
        genderDist.push({gender: type.x, count: type.y})
      })
    }


    analyticsPartnership.partnershipIncomeDist.distribution.map((income, idx) => {
      let incomeMap = ['< 35K', '35-49K', '50-74K', '75-99K', '100K+']

      incomeDist.push({income: incomeMap[idx], count: income})
    })

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'exportAnalyticsModal',
      data: {
        active: true,
        filename: 'partnership_analytics.csv',
        metrics: [
          {
            label: 'Overview Figures',
            value: [Object.assign({}, analyticsPartnership.partnershipCampaignPerformance.aggregrates, { ntfPerc: analyticsPartnership.partnershipCampaignPerformance.aggregrates.ntfPerc.toFixed(2) })]
          },
          {
            label: 'Entries - Geographic Distribution',
            value: geographicDist
          },
          {
            label: 'Entries - Gender Split',
            value: genderDist
          },
          {
            label: 'Entries - Household Income',
            value: incomeDist
          },
          {
            label: 'Historical Campaign Performance',
            value: analyticsPartnership.partnershipCampaignPerformance.campaigns
          }
        ]
      }
    });
  }

  viewProfile = () => {
    let {
      history,
      match: {
        params: {
          partnershipId
        }
      },
    } = this.props;

    history.push(`/explore/brands/${partnershipId}`)
  }

  render() {
    const {
      analyticsPartnership,
      partnershipCampaignsSearch,
      match: {
        params: {
          partnershipId
        }
      },
    } = this.props;

    const {
      total,
      allVisits,
      allEntries,
      partners: { currentBrand: { allNTF } }
    } = partnershipCampaignsSearch;

    const {
      campaigns,
      aggregrates: {
        campaignCount,
        entriesDriven,
        ntfDriven,
        ntfPerc,
        avgEntries
      }
    } = analyticsPartnership.partnershipCampaignPerformance;

    if (analyticsPartnership.loading) {
      return (
        <Spinner />
      )
    }

    return (
      <div style={{
        width: '976px',
        margin: 'auto',
        background: COLOURS.cream
      }}>
        <Helmet>
          <title>{ `Partnership History with ${analyticsPartnership.partnershipBasicDetails.accountname}` }</title>
        </Helmet>
        <Grid>
          <Row style={{
            paddingTop: '32px'
          }}>
            <Col xs>
              <ExplorePageTitle title={ `Partnership History with ${analyticsPartnership.partnershipBasicDetails.accountname}` }
                action={ this.exportCsv }
                actionLabel={ 'Export CSV' }
                secondaryAction={ this.viewProfile }
                secondaryActionLabel={ 'View Profile' }
              />
            </Col>
          </Row>
          <Row style={{
            paddingTop: '12px',
            paddingBottom: '24px'
          }}>
            <P multiline>
              Use this detailed view to understand how specific partners
              performed in your campaign. To protect your deliverability and
              maximize campaign performance, we scrub entries with Webbula
              prior to submitting them to you. <a href="https://help.dojomojo.com/hc/en-us/articles/115002401392-Why-is-the-number-of-email-addresses-I-received-different-from-the-number-of-entries" target="_blank">Learn More</a>
            </P>
          </Row>
          <OverviewBar
            metrics={[
              {
                label: 'total campaigns',
                value: campaignCount,
              },
              {
                label: 'entries driven',
                value: entriesDriven,
              },
              {
                label: 'avg entries/campaign ',
                value: avgEntries,
              },
              {
                label: 'NTF driven',
                value: ntfDriven,
              },
              {
                label: '% NTF',
                value: ntfPerc * 100,
                isPercent: true,
              },
              {
                label: 'list size',
                value: Number(analyticsPartnership.partnershipBasicDetails.dedicatedListSize) || 0,
              },
            ]}
          />
          <Row style={{
            paddingTop: '24px'
          }}>
            <Col style={{
              width: '646px',
              marginRight: '16px'
            }}>
              <GeographicMapDistributionCard data={ analyticsPartnership.partnershipGeographicDist }/>
            </Col>
            <Col style={{
              width: '314px'
            }}>
              <div style={{
                marginTop: '-16px',
                marginBottom: '16px'
              }}>
                <GenderDistributionCard data={ analyticsPartnership.partnershipGenderDist }/>
              </div>
              <IncomeDistributionCard data={ analyticsPartnership.partnershipIncomeDist }/>
            </Col>
          </Row>
          <Row style={{
            paddingTop: '24px'
          }}>
            <Col>
              <EventChart
                campaigns={campaigns}
                earliestDate={(_.minBy(campaigns, 'startDate') || {}).startDate || new Date()}
                latestDate={(_.maxBy(campaigns, 'endDate') || {}).endDate || new Date()}
              />
            </Col>
          </Row>
          <PartnershipCampaigns
            partnerBrandId = {partnershipId} />
        </Grid>
      </div>
    )
  }
}

export default withRouter(connect(mapState)(PartnershipAnalytics));
