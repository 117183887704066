import initialState from './defaults/opportunitySearch'

export default {
  model: 'opportunitiesSearch',
  initialState,
  endpoint: '/search-opportunities',
  inputName: ['headline', 'brandName', 'industry.search'],
  transformResults: ({ _source: { id, ...rest } }) => {
    id = Number(id.split('__')[1])
    return { id, ...rest }
  },
  aggs: {
    prettyType: {
      filter: {},
      aggs: {
        prettyType: {
          terms: {
            field: 'prettyType',
          },
        },
      },
    },
    tags: {
      filter: {},
      aggs: {
        tags: {
          terms: {
            field: 'industry.raw',
            size: 100,
          },
        },
      },
    },
  },
  facets: ['prettyType', 'tags'],
}
