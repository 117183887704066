import React from 'react'
import FormContainer from 'v2/components/templates/FormContainer'
import Logo from 'v2/components/templates/Logo'
import Text from 'v2/components/templates/Text'
import TemplateContainer from 'v2/components/templates/TemplateContainer'
import BackgroundOverlay from 'v2/components/templates/BackgroundOverlay'
import HeroImage from 'v2/components/templates/HeroImage'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { templateStyles } from 'v2/components/templates/styles/version-13'
import styleHelper from 'v2/styles/style-helper'
import NotStartedWarning from './NotStartedWarning'
import PartnerLogos from 'v2/components/templates/PartnerLogos'
import Prizing from 'v2/components/templates/Prizing'
import Disclaimer from 'v2/components/templates/Disclaimer'

const styles = {
  background: {
    position: 'relative',
    overflow: 'hidden',
    width: props => (props.desktopVersion ? 'calc(100% - 200px)' : '100%'),
    padding: props => (props.desktopVersion ? 60 : 40),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: props => (props.desktopVersion ? '0 100px' : '0'),
    [styleHelper.max(1100)]: {
      margin: '0 !important',
      width: '100% !important',
      padding: '44px !important',
    },
  },
  bottomInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: props => (props.desktopVersion ? '40px 100px 0 100px' : '40px auto 0 auto'),
    [styleHelper.max(1100)]: {
      margin: '0 !important',
      width: '100% !important',
      padding: '44px !important',
    },
  },
  topLogoContainer: {
    margin: '22px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formContainer: {
    width: '100%',
    maxWidth: 520,
    overflow: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 20px auto',
    zIndex: 1,
    padding: 20,
  },
  headlineContainer: {
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 20px)'),
    margin: props => (props.desktopVersion ? '0 60px' : '30px'),
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 20px)',
      margin: '0 30px',
    },
  },
  preEntryMessageContainer: {
    width: 'calc(100% - 20px)',
    margin: '40px 60px',
  },
  detailsContainer: {
    marginBottom: 32,
    width: '100%',
  },
  ...templateStyles,
}

const hexToRGBA = (color, percent = 1) => {
  const colors = color.substring(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16))
  return `rgba(${colors.join(', ')}, ${percent})`
}

class Version13 extends React.Component {
  static propTypes = {
    opts: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool,
    isEditorMenuOpen: propTypes.bool,
  }

  static defaultProps = {
    desktopVersion: true,
    isEditorMenuOpen: false,
  }

  static childContextTypes = {
    baseStyles: propTypes.object,
    userStyles: propTypes.object,
  }

  getChildContext() {
    const {
      classes: css,
      opts: {
        elements: {
          styles: userStyles,
        },
      },
    } = this.props

    return {
      baseStyles: css,
      userStyles,
    }
  }

  render() {
    const {
      opts: {
        campaign,
        hostBrand,
        elements,
        termsLink,
        acceptedInvites,
        elements: {
          styles: userStyles,
        },
      },
      updateElementsAttr,
      editElements,
      customFonts,
      edit,
      updateAttr,
      agreeToLiability,
      classes: css,
      desktopVersion,
      isEditorMenuOpen,
    } = this.props

    const formStyles = userStyles.form

    return (
      <TemplateContainer
        campaign={campaign}
        elements={elements}
        isFixedBackgroundImage={false}
        updateAttr={updateElementsAttr}
        campaignUpdateAttr={updateAttr}
        desktopVersion={desktopVersion}
        acceptedInvites={acceptedInvites}
        isEditorMenuOpen={isEditorMenuOpen}
        hostBrand={hostBrand}
      >
        <div
          id="top-logo-container"
          className={css.topLogoContainer}
        >
          <Logo
            hostWebsite={hostBrand.website}
            logoPath={elements.top_logo || hostBrand.logo}
            updateAttr={updateElementsAttr}
            edit={editElements}
            campaign={campaign}
            elements={elements}
            maxHeight={elements.styles.topLogo ? elements.styles.topLogo.height : 100}
            className={css.topLogoImg}
            id="top-logo"
            allowDelete
            editName="top-logo"
          />
        </div>
        <div
          className={css.background}
        >
          <HeroImage
            imageUrl={elements.mainImage}
            desktopVersion={desktopVersion}
          />
          <BackgroundOverlay
            desktopVersion={desktopVersion}
          />
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="headLine"
            customFonts={customFonts}
            className={css.headlineContainer}
          />
          
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="preEntryMessage"
            customFonts={customFonts}
            className={css.preEntryMessageContainer}
          />
          <div
            className={css.formContainer}
            style={{ background: hexToRGBA(formStyles.color, formStyles.opacity) }}
            id="form-holder"
          >
            <Text
              model={elements}
              updateAttr={updateElementsAttr}
              edit={editElements}
              attr="details"
              customFonts={customFonts}
              className={css.detailsContainer}
            />
            <FormContainer
              hidePartnerInformation
              updateAttr={updateAttr}
              updateElementsAttr={updateElementsAttr}
              campaign={campaign}
              elements={elements}
              edit={edit}
              editElements={editElements}
              termsLink={termsLink}
              agreeToLiability={agreeToLiability}
              acceptedInvites={acceptedInvites}
              customFonts={customFonts}
              desktopVersion={desktopVersion}
            />
          </div>
        </div>
        <div className={css.bottomInfo}>
          <PartnerLogos
            updateAttr={updateElementsAttr}
            edit={editElements}
            campaign={elements}
            partnerCampaigns={acceptedInvites}
            desktopVersion={desktopVersion}
          />
          <Logo
            logoPath={elements.prizing_logo || campaign.prizingLogo}
            updateAttr={updateElementsAttr}
            editName="prizing-logo"
            edit={editElements}
            campaign={campaign}
            elements={elements}
            maxHeight={elements.styles.prizingLogo ? elements.styles.prizingLogo.height : 100}
            id="prizing-logo"
            allowDelete
            label="PRIZING BY"
            uploadButtonLabel="Upload a prizing logo"
            modalPosition="top"
          />
          <Disclaimer
            campaign={campaign}
            edit={edit}
            updateAttr={updateAttr}
            customFonts={customFonts}
          />
        </div>
        
      </TemplateContainer>
    )
  }
}

export default injectSheet(styles)(Version13)
