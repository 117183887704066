import Field from './field';
import Label from 'util/components/label';
import COLOURS from 'util/colours';
import React from 'react';
import _ from 'lodash'

class SelectField extends Field {
  constructor(props) {
    super(props);
  }

  render() {

    let options = _.map(this.props.options, function (opt, idx) {
       let val;
       let label;
       if (typeof opt === 'string') {
         val = label = opt;
       } else {
         val = opt.value;
         label = opt.label;
       }

       return (
         <option key={ idx }
          value={ val }>
           { label }
         </option>
       );
    });

    const { toDisplay, align,  type, attrName, editable, disabled, dataModel, formModel } = this.props ;
    var errors = (formModel[attrName] ? formModel[attrName].errors : []) || [];

    errors = _.map(errors, function (err) {
      return (
        <span className="help-block"
          style={{
            color: COLOURS.alertRed
        }}>
          { err }
        </span>
      )
    });
    const value = dataModel[attrName];

   let selectStyles = {
      borderRadius: '1px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: errors.length > 0 ? COLOURS.alertRed : COLOURS.silver,
      fontFamily: "'Larsseit-Light'",
      color: COLOURS.ink,
      fontSize: '14px',
      height: '50px',
      paddingTop: '4px',
      paddingLeft: '14px',
      paddingBottom: '4px',
      paddingRight: '4px',
      width: '100%',
      outline: 'none',
      WebkitAppearance: 'none',
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
      background: 'transparent url("/images/dropdown-arrow.svg") no-repeat calc(100% - 10px) center',
      ':focus': {
        outline: 'none'
      }
    };


    return (
      <div className="form-group"
        style={{
          marginBottom: this.props.marginBottom ? this.props.marginBottom : '10px'
      }}>
        <Label
          { ...this.props }
          labelStyles={ this.props.labelStyles }
        />
        <select
          disabled={ this.props.disabled }
          style={ selectStyles }
          value={ value }
          onChange={ e => this.updateValue(e.target.value) }
          id={ attrName }
          name={ attrName }
          onBlur={ this.blur }
          className="form-control borderAzureFocus"
        >
          { this.props.placeholder ?
            <option value="" disabled>
              { this.props.placeholder }
            </option>
            : null
          }
          { options }
        </select>
          {/* -leaving this code just in case-

          <div style={{
            position: 'absolute',
            right: '15px'
          }}>
            <div style={{
              content: '',
              top: '0px',
              marginTop: '-29px',
              marginRight: '1px',
              width: '0',
              height: '0',
              borderLeftWidth: '8px',
              borderLeftStyle: 'solid',
              borderLeftColor: 'transparent',
              borderRightWidth: '8px',
              borderRightStyle: 'solid',
              borderRightColor: 'transparent',
              borderTopWidth: '8px',
              borderTopStyle: 'solid',
              borderTopColor: COLOURS.inputBorder,
              position: 'absolute',
              pointerEvents: 'none',
              right: '15px'
            }} />
            <div style={{
              content: '',
              top: '0px',
              marginTop: '-29px',
              marginRight: '1px',
              width: '0',
              height: '0',
              borderLeftWidth: '6px',
              borderLeftStyle: 'solid',
              borderLeftColor: 'transparent',
              borderRightWidth: '6px',
              borderRightStyle: 'solid',
              borderRightColor: 'transparent',
              borderTopWidth: '6px',
              borderTopStyle: 'solid',
              borderTopColor: COLOURS.white,
              position: 'absolute',
              pointerEvents: 'none',
              right: '17px'
            }} />
          </div>
        */}
        { errors.length > 0 ?
          errors
          :
          <div style={{
            paddingTop: '17px'
          }}/>
        }
      </div>
    );
  }
}

export default SelectField;
