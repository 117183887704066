import React, {Component} from 'react'
import DefaultDialog from './default-dialog'
import {P} from 'visual-components/util/texts'
import {withRouter} from 'react-router-dom'
import moment from 'moment-timezone'

class ReferralProgramReminderDialog extends Component {

  state = {
    something: true
  }

  closeModal = () => {
    try {
      localStorage.setItem('seenReferralReminderProgramModal', 'true')
    } catch(e) {
    }
    this.setState({something: false}) /* triggers a re-render */
  }

  confirm = () => {
    try {
      localStorage.setItem('seenReferralReminderProgramModal', 'true')
    } catch(e) {
    }
    this.props.history.push('/profile/brand/referrals')
  }

  render() {
    let val

    try {
      val = localStorage.getItem('seenReferralReminderProgramModal')!=='true'
    } catch(e) {
      console.log(e)
    }

    if (!val || moment() > moment('2018-03-31')) {
      return null
    }

    const nextDate = new Date('2018-03-31')

    const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */
    const daysLeft = Math.round(Math.abs((nextDate.getTime() - new Date() )/(oneDay)))

    let title = ''

    if (daysLeft > 1) {
      title = `Only ${daysLeft} Days Left to Win $250!`
    } else {
      title = 'You could win $250 tomorrow!'
    }

    return (
      <DefaultDialog
        active={val}
        closeModal={this.closeModal}
        title={title}
        confirm={this.confirm}
        confirmText={'Check It Out'}
        cancel={this.closeModal}
        cancelText={'Not Now'}
        imgSrc={ '/images/icons/celebration-icon.svg'}
        children={
          <div>
            <P multiline>
              Get a $25 platform credit and a chance to win $250 for every brand you refer! Go to your Referral Dashboard to start inviting.
            </P>
          </div>
        }
      />
    )
  }
}

export default withRouter(ReferralProgramReminderDialog)
