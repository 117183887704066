import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import colors from 'util/colours'

import DropdownButtonV2 from 'util/components/dropdown-v2'

import { SMSEditorContext } from './SMSEditorContext'

import ImageSelector from './utils/ImageSelector'
import EmojiSelector from './utils/EmojiSelector'
import LinkInsert from './utils/LinkInsert'

const styles = {
  wrapper: {
    display: 'flex',
    height: 32,
    borderBottom: `1px solid ${colors.silver}`,
    alignItems: 'center',
    padding: '0 6px',
  },
  separator: {
    height: '100%',
    marginLeft: 6,
    marginRight: 4,
    borderRight: `1px solid ${colors.silver}`,
  },
}

const TAG_OPTIONS = [
  { label: 'Brand Name', value: 'BRAND_NAME' },
  { label: 'Coupon Code', value: 'COUPON_CODE' },
]

function SMSUtilsBar(props) {
  const { classes: css } = props
  const {
    actions: { onAddTag },
  } = React.useContext(SMSEditorContext)

  return (
    <div className={css.wrapper}>
      <ImageSelector />
      <LinkInsert />
      <EmojiSelector />
      <div className={css.separator} />
      <DropdownButtonV2 label="Merge Tags" options={TAG_OPTIONS} onSelect={onAddTag} />
    </div>
  )
}

SMSUtilsBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(SMSUtilsBar))
