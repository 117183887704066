import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import COLOURS from 'util/colours';
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher.js'

class PricingGateOverlay extends React.Component{
  upgradePlan = () => {
    const {
      history,
      feature,
      eventTrack,
    } = this.props

    if (eventTrack === 'HostUpgrade') {
      EventTrackingDispatcher.track('app_upgrade_upgradeButton_hostUpgradeModal')
    } else if (eventTrack === 'ApplicationUpgrade') {
      EventTrackingDispatcher.track('app_upgrade_upgradeButton_applicationUpgradeModal')
    }

    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: feature,
        upgradeOriginalLocation: history.location.pathname
      }
    })

    history.push('/upgrade-plan')
  }

  render() {
    const {
      src,
      feature,
      title,
      content,
      style
    } = this.props

    return (
      <div>
        <div style={{
          width: style.width,
          height: style.height,
          filter: 'blur(2px)',
          background: `url(${src})`,
          borderRadius: '2px',
          position: 'absolute',
          backgroundSize: 'cover'
        }}>
        </div>
        <div style={{
          width: style.width,
          height: style.height,
          backgroundColor: 'rgba(222, 228, 229, 0.7)',
          borderRadius: '2px',
          position: 'absolute',
          backgroundSize: 'cover'
        }}>
        </div>
        <div style={{
          backgroundColor: COLOURS.white,
          position: 'absolute',
          width: '672px',
          padding: '32px',
          height: '352px',
          marginTop: style.marginTop,
          marginLeft: style.marginLeft
        }}>
          <p style={{
            fontFamily: 'Larsseit-Medium',
            fontSize: '20px',
            lineHeight: '20px',
            color: COLOURS.ink,
            textAlign: 'center',
            marginBottom: '24px'
          }}>
            { title }
          </p>

          <img src="/images/nav-bar/mojo-white-tiger-logo.svg"
            style={{
              width: '126px',
              height: '120px',
              marginBottom: '24px',
              marginLeft: '241px'
          }}/>

          <p style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '16px',
            lineHeight: '24px',
            color: COLOURS.ink,
            marginBottom: '16px'
          }}>
            { content }
          </p>

          <ModalButtonGroup
            canSave={ true }
            confirm={ this.upgradePlan }
            confirmText={ 'View Upgrade Options' }
            hideLine={ true }
          />
        </div>
      </div>
    )
  }
}

PricingGateOverlay.propTypes = {
  src: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default withRouter(PricingGateOverlay);
