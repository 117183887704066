import React from 'react'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import { Input } from 'visual-components/util/form'
import InputTheme from 'css/themes/Input.css'
import {
  H3,
} from 'visual-components/util/texts'

class RequestAdditionalInformation extends React.Component {

  render() {
    return (
      <React.Fragment>
        <div className={SellMediaModalTheme.orangeTitle}>
          <H3 coral>
            <small>Additional Information</small>
          </H3>
        </div>
        <Input
          className={InputTheme.textarea}
          name="seller_note"
          label="Note to Seller"
          placeholder="Example: I want to work with you on 1 Sponsored Email and 1 Instagram Story for May to promote our Summer Collection"
          type="string"
          multiline
          rows={3}
        />
      </React.Fragment>
    )
  }
}

export default RequestAdditionalInformation
