import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';
import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H1, H6 } from 'visual-components/util/texts';
import d3 from 'd3'

class ProfileStat extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      title,
      value,
      tooltip,
      brand,
      wholeDollar,
      alwaysShow
    } = this.props;

    var marginRight = brand ? '28px' : (tooltip ? '32px' : '50px');

    return(
      <div>
        {
          value || alwaysShow ?
          <Col>
            <div style={{
              marginRight: marginRight
            }}>
              <div style={{
                display: 'inline-flex',
                verticalAlign: 'top',
                marginBottom: '12px'
              }}>
                <H6>
                  { title }
                </H6>
              </div>

              {
                tooltip ?
                <div style={{
                  display: 'inline-flex',
                  verticalAlign: 'top',
                  marginTop: '-4px',
                  marginLeft: '6px'
                }}>
                  <BlueInfoTooltip
                    text={ tooltip }
                  />
                </div>
                : null
              }
            </div>
            <H1>
              { wholeDollar
                ? d3.format("$,")(value)
                : makeNumbersPretty(value, true)
              }
            </H1>
          </Col>
          : null
        }
      </div>
    )
  }
}

ProfileStat.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  tooltip: PropTypes.string,
  brand: PropTypes.bool,
  style: PropTypes.object,
  alwaysShow: PropTypes.bool
};

export default ProfileStat;
