import React from 'react'
import d3 from 'd3'
import { connect } from 'react-redux'

import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import {
  H3, H4, P, Small,
} from 'visual-components/util/texts'
import Theme from 'css/themes/payments/upgrade-plan-full-modal.css'

const blueCheck = (
  <img src="/images/pricing/chart-icons/blue-check.svg" style={{ height: '12px' }} />
)
const redX = <img src="/images/pricing/chart-icons/red-x.svg" style={{ height: '12px' }} />

// TODO: map generic brand claim limits to frontend so they dont have to be hardcoded
// (subscribers, messages, socialActions)
const subscriberLimit = {
  essential: '10,000',
  premium: '50,000',
  pro: 'Unlimited',
}

const smsBoostPack = {
  essential: '2,500',
  premium: '10,000',
  pro: '25,000',
}

const mapToState = state => ({ currentBrand: state.currentBrand, sms: state.sms })

const upgradeFeatureMap = plan => [
  {
    label: 'Mojo Messages',
    value: plan === 'essential' ? 50 : 'Unlimited',
  },
  {
    label: 'Apply to Join Campaigns',
    value: blueCheck,
  },
  {
    label: 'Create Campaigns',
    value: blueCheck,
  },
  {
    label: 'Social Actions',
    value: plan === 'essential' ? 3 : 'Unlimited',
    tooltip:
      'Incentivize entrants with additional entries for taking actions on social media or downloading your app.',
  },
  {
    label: 'Custom CTA',
    value: plan === 'essential' ? redX : blueCheck,
    toolTip:
      'Show entrants your custom content or a special offer and drive thousands of high intent clicks to your page or product.',
  },
  {
    label: 'SMS Subscribers',
    value: subscriberLimit[plan],
  },
]

class OrderSummaryDisplay extends React.Component {
  state = {
    agreementChecked: false,
  }

  closeModal = () => {
    BrowserHistoryDispatcher.goBack()
  }

  toggleAgreement = () => {
    const { agreementChecked } = this.state
    this.setState({ agreementChecked: !agreementChecked })
  }

  getSmsBoostLines = boostType => {
    const boost = smsBoostPack[boostType]
    if (!boost) return null

    return (
      <div className={Theme.orderSection__subitem}>
        <Small>{`Signup Bonus: SMS Boost Pack, ${boost} SMS Credits`}</Small>
        <Small>{blueCheck}</Small>
      </div>
    )
  }

  formatUpgradeOrderLines = (dataObj, chargeTax) => {
    const { rate, currentBrand, sms } = this.props
    if (!dataObj) return null

    const features = upgradeFeatureMap(dataObj.featureSet).map(feature => (
      <div className={Theme.orderSection__subitem}>
        <Small>{feature.label}</Small>
        <Small>{feature.value}</Small>
      </div>
    ))

    const extraCost = dataObj.extraItem ? dataObj.extraItem.price : 0
    const isInSmsPlan = sms.brandSmsBank
      && sms.brandSmsBank.credits
      && sms.brandSmsBank.credits.autorefill

    /*
      showAnnualBonus if:
      a) brand is switching from trial/monthly to annual (dataObj.annual)
        + adding sms plan (data.includesSmsPlan)
      b) brand is in monthly plan and is switching to annual
        + is currently in sms plan (isInSmsPlan)
      c) brand is in annual already (dataObj.annual) + is adding an sms plan (data.includesSmsPlan)
      d) all of this while isFirstTimeLTC (has never been in an annual plan before)
     */
    const showAnnualBonus = dataObj.annual
      && currentBrand.isFirstTimeLTC
      && !dataObj.hasBoostPack
      && (dataObj.includesSmsPlan || isInSmsPlan)

    const { planAmount, discountAmount = 0 } = dataObj
    const totalAmount = planAmount - discountAmount

    const taxRate = chargeTax ? Number(rate) : 0
    const salesTax = taxRate * (totalAmount + extraCost)

    return (
      <div>
        <div className={Theme.orderSection__item}>
          <P>{dataObj.plan}</P>
          <P>{d3.format('$,.2f')(planAmount)}</P>
        </div>
        <div className={Theme.orderSection__features}>{features}</div>
        {dataObj.discountAmount && (
          <div className={Theme.orderSection__item}>
            <P>{dataObj.discountLabel}</P>
            <P>
              -
              {d3.format('$,.2f')(discountAmount)}
            </P>
          </div>
        )}
        {
          dataObj.extraItem && (
            <div className={Theme.orderSection__item}>
              <P>{dataObj.extraItem.label}</P>
              <P>{d3.format('$,.2f')(dataObj.extraItem.price)}</P>
            </div>
          )
        }
        {
          chargeTax && (
            <div className={`${Theme.orderSection__item} ${Theme.orderSection__lastItem}`}>
              <P>Sales tax for NY residents</P>
              <P>{d3.format('$,.2f')(salesTax)}</P>
            </div>
          )
        }
        {showAnnualBonus && (
          <div className={Theme.orderSection__features} style={{ marginTop: 12 }}>
            {this.getSmsBoostLines(dataObj.featureSet)}
          </div>
        )}
      </div>
    )
  }

  formatPurchaseOrderLines = (dataObj, type, existingPlan, chargeTax) => {
    if (!dataObj) return null
    const isSms = type === 'sms'
    return (
      <div>
        <div className={`${Theme.orderSection__item} ${Theme.orderSection__lastItem}`}>
          <div>
            <P>{dataObj.label}</P>
            {isSms && (
              <>
                <br />
                <P style={{ fontStyle: 'italic' }}>Auto-Refill</P>
              </>
            )}
          </div>
          <P>{d3.format(isSms ? '$.2f' : '$f')(dataObj.subAmount)}</P>
        </div>
        {isSms && dataObj.belt && (
          <div style={{ marginTop: -8, marginBottom: 20 }}>
            {this.getSmsBoostLines(dataObj.belt)}
          </div>
        )}
        {isSms && dataObj.invoiceCredit < 0 && (
          <div className={`${Theme.orderSection__item} ${Theme.orderSection__lastItem}`}>
            <div>
              <P>Available Credit</P>
            </div>
            <P>{d3.format('$.2f')(dataObj.invoiceCredit)}</P>
          </div>
        )}
        {isSms && chargeTax > 0 && (
          <div className={`${Theme.orderSection__item} ${Theme.orderSection__lastItem}`}>
            <P>Sales tax for NY residents</P>
            <P>{d3.format('$.2f')(Number(chargeTax))}</P>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      type,
      upgradeOrderSummary,
      purchaseDealSummary,
      chargeTax,
      rate,
      existingPlan,
    } = this.props

    if (!upgradeOrderSummary && !purchaseDealSummary) return null

    let totalToPay = 0

    /* if direct purchase, no need to calculate for upgrade, as it involves more calculations */
    if (purchaseDealSummary) totalToPay += purchaseDealSummary.totalAmount

    /* if its upgrade, this is the only calculation */
    if (upgradeOrderSummary && type === 'upgrade') {
      const { extraCost = 0, discountAmount = 0, planAmount } = upgradeOrderSummary

      totalToPay = planAmount + extraCost - discountAmount

      if (chargeTax) totalToPay *= (1 + Number(rate || 0))
    }

    return (
      <div
        className={`${Theme.orderSummary} ${
          type === 'upgrade' || type === 'sms' ? Theme.positiveMarginTop : null
        }`}
      >
        <div className={Theme.orderSummary__header}>
          <H4 ink>
            <em>Order Summary</em>
          </H4>
        </div>

        <div className={Theme.orderSummary__dividerLine} />

        <div className={Theme.orderSection}>
          <div className={Theme.orderSection__container} id="order-line-items">
            {type === 'upgrade'
              ? this.formatUpgradeOrderLines(upgradeOrderSummary, chargeTax)
              : this.formatPurchaseOrderLines(purchaseDealSummary, type, existingPlan, chargeTax)}
          </div>
        </div>

        <div className={Theme.orderSummary__dividerLine} />

        <div className={Theme.orderSection}>
          <div className={Theme.orderSection__container}>
            <div className={`${Theme.orderSection__item} ${Theme.orderSection__lastItem}`}>
              <H3>
                <small>Total</small>
              </H3>
              <H3>
                <small>{d3.format('$,.2f')(totalToPay)}</small>
              </H3>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapToState)(OrderSummaryDisplay)
