import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import Card from 'react-toolbox/lib/card'
import { P } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import Axios from 'axios'

import Dropdown from 'react-toolbox/lib/dropdown'
import { Input as InputRT } from 'react-toolbox/lib/input'
import { Button } from 'react-toolbox/lib/button'

import InputTheme from 'css/themes/Input.css'
import DropDownTheme from 'css/themes/formdropdown.css'
import ButtonTheme from 'css/themes/Buttons.css'

const THEMES = {
  input: InputTheme,
  dropdown: DropDownTheme,
  button: ButtonTheme,
}

const styles = {
  container: {
    marginTop: 16,
    padding: '24px 16px',
    overflow: 'unset',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    '& thead': {
      borderBottom: `1px solid ${COLOURS.silver}`,
    },
    '& tr:first-child td': {
      paddingTop: 16,
      '& h5': {
        marginBottom: 4,
      },
    },
  },
  actions: {
    display: 'flex',
    paddingTop: 40,
    maxHeight: 99,
  },
  button: {
    verticalAlign: 'bottom',
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
    '&:nth-child(2)': {
      flex: 1,
      marginLeft: 16,
      marginRight: 16,
    },
  },
}

function CSAdminBrandSMSPlan(props) {
  const {
    classes: css, brandBank, refreshBrand, brandId,
  } = props
  const [action, setAction] = useState('')
  const [credits, setCredits] = useState('')

  const {
    credits: { count, freeCount, info: { pricing } },
    currentPlan,
  } = brandBank

  const DROPDOWN_OPTIONS = [
    {
      label: 'Add or Subtract',
      value: '',
    },
    {
      label: 'Add',
      value: 'add',
    },
    {
      label: 'Subtract',
      value: 'subtract',
    },
  ]

  const onClick = async () => {
    if (!/^\d+$/.test(credits)) {
      setCredits('')
      toastr.error('Only Numbers Can Be Used.', null, { positionClass: 'toast-bottom-center', timeOut: 2000 })
    } else {
      const multiplier = action === 'add' ? 1 : -1

      await Axios.post(`/cs-manage-brand-sms-balance/${brandId}`, { messages: Number(credits) * multiplier })
      toastr.success('Successfuly Modified.', null, { positionClass: 'toast-bottom-center', timeOut: 2000 })
      refreshBrand()
    }
  }

  const paymentType = 'Auto-Refill'
  const countText = `(${count} purchased, ${freeCount} free)`
  const text = currentPlan
    ? `${paymentType} w/${currentPlan.credits} Messages ($${pricing}/message)`
    : 'This brand is not in a paid SMS plan'

  const disable = !action || !Number(credits)

  return (
    <Card className={css.container}>
      <table className={css.table}>
        <thead>
          <tr>
            <th>Current SMS Plan</th>
            <th>Current Credits</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <P>{text}</P>
              <P multiline>{countText}</P>
              <P multiline>
                <em>
                  * Note: This only modifies brand&apos;s free messages
                </em>
              </P>
            </td>
            <td>
              <P>{count + freeCount}</P>
            </td>
          </tr>
          <tr>
            <td className={css.actions}>
              <div className={css.column}>
                <P style={{ marginBottom: 8 }}>
                  <em>Adjust SMS Bank</em>
                </P>
                <Dropdown
                  onChange={setAction}
                  source={DROPDOWN_OPTIONS}
                  value={action}
                  theme={THEMES.dropdown}
                />
              </div>
              <div className={css.column}>
                <P style={{ marginBottom: 8 }}>
                  <em>Number of Credits</em>
                </P>
                <InputRT
                  value={credits}
                  theme={THEMES.input}
                  onChange={setCredits}
                  placeholder="Number of Credits"
                />
              </div>
            </td>
            <td className={css.button}>
              <Button
                onClick={onClick}
                disabled={disable}
                theme={THEMES.button}
                className={THEMES.button.blueButton}
                primary
                raised
                label="Submit Adjustment"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  )
}

CSAdminBrandSMSPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  brandBank: PropTypes.object.isRequired,
  refreshBrand: PropTypes.func.isRequired,
  brandId: PropTypes.number.isRequired,
}

export default injectCSS(styles)(CSAdminBrandSMSPlan)
