import React from 'react';
import AdminRow from './admin-row';
import _ from 'lodash'

class FeatureDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const headerStyle = {
      borderTop: '1px solid',
      borderBottom: '3px solid'
    };

    const { presentation: data } = this.props.admin;
    const { clicksPixel, conversionPixelAggregate, conversionPixelPeriodic, customConversions, emailsAcquiredPixel, entriesFromPostEntrySweeps, facebookLikes, facebookShares, postEntryClicks, sweepsWithPostEntry, twitterFollows, twitterShares, sweepsActive, totalActiveBrands60Days, sweepsActiveWithBonusEntry} = data;
    sweepsActive.pop();

    let convertData = (arr, format = ',' , overrideField) => {
      return _.map(arr, function (item) {
        return d3.format(format)(overrideField ? item[overrideField] : item.c)
      });
    }
    const blendedConversion = _.map(clicksPixel, function(clicks, idx) {
      if (parseInt(clicks.c) + parseInt(emailsAcquiredPixel[idx].c) == 0 || customConversions[idx].c == 0) {
        return 'N/A';
      } else {
      return d3.format('.1%')(parseInt(customConversions[idx].c) / (parseInt(clicks.c) + parseInt(emailsAcquiredPixel[idx].c)));
      }
    })

    const avgPEClicksSweeps = _.zipWith(postEntryClicks, sweepsWithPostEntry, (clicks, sweeps) => d3.format(',.0f')(clicks.c / sweeps.c));
    const avgPEClicksEntries = _.zipWith(postEntryClicks, entriesFromPostEntrySweeps, (clicks, entries) => d3.format('%')(clicks.c / entries.c));

    const sweepsWithPostEntryPercentageOfTotal = _.zipWith(_.map(sweepsWithPostEntry, row=>row.c),_.map(sweepsActive, row => row.count_sweeps), (postentry, active) => {
      return d3.format('.1%')(postentry/active);
    })

    const sweepsWithBonusEntryPercentageOfTotal = _.zipWith(_.map(sweepsActiveWithBonusEntry, row=>row.count_sweeps),_.map(sweepsActive, row => row.count_sweeps), (bonusentry, active) => {
      return d3.format('.1%')(bonusentry/active);
    })

    
    const dummyrow = ['-','-','-','-','-','-','-','-','-','-','-','-']

    const initialMessages = data.countInitialMessages;
    const totalMessages = data.countTotalMessages;
    const conversationsResponded = data.countConversationsResponded;
    const messageResponseRate = _.zipWith(initialMessages, conversationsResponded, (initialMessage, conversationResponded) => {
      const convResponded = conversationResponded ? conversationResponded.c : 0;
      const initMessage = initialMessage ? initialMessage.c : 0;
      return `${(100 * parseInt(convResponded) / parseInt(initMessage)).toFixed(2)}%`
    });

    const messageGrowth = ['--'].concat(_.zipWith(_.drop(totalMessages), _.dropRight(totalMessages), (curr, prev) => {
      return `${(100 * (curr.c - prev.c) / prev.c).toFixed(1)}%`;
    }));

    let totalActiveBrands60DaysMod = totalActiveBrands60Days.slice(1);

    const convStartedPerActiveBrand = _.zipWith(initialMessages, totalActiveBrands60DaysMod, (conversations, activeBrands) => d3.format(',.2f')(conversations.c / activeBrands.c))
    const convStartedWithDialogPerActiveBrand = _.zipWith(conversationsResponded, totalActiveBrands60DaysMod, (conversations, activeBrands) => d3.format(',.2f')(conversations.c / activeBrands.c))

    var bonusRows = _.map(this.props.admin.presentation.bonusTypes, type => {
      return (
        <AdminRow title={type} vals={convertData(this.props.admin.presentation[type])} key={type} />
      )
    })

    return (
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped table-hover table-responsive" style={{marginBottom: '50px'}}>
            <AdminRow className="info" vals={this.props.dates} style={headerStyle}/>
            <tbody>
              <tr className="info"><td colSpan="13"><h4>CONVERSION DATA</h4></td></tr>
                <AdminRow title="Brands with Conversion Tracking Pixel Installed (aggregate)" vals={convertData(conversionPixelAggregate)} key="conversionPixelAggregate"/>
                <AdminRow title="New Conversion Tracking Pixel Installs (time period)" vals={convertData(conversionPixelPeriodic)} key="conversionPixelPeriodic"/>
                <AdminRow title="Total Emails Acquired by Brands with Conversion Pixel" vals={convertData(emailsAcquiredPixel)} key="emailsAcquiredPixel"/>
                <AdminRow title="Total Clicks through Post Entry with Conversion Pixel" vals={convertData(clicksPixel)} key="clicksPixel"/>
                <AdminRow title="Custom Conversions" vals={convertData(customConversions)} key="customConversions"/>
                <AdminRow title="Blended Conversion Rate" vals={blendedConversion} key="blendedConversion"/>
              <AdminRow className="info" vals={this.props.dates} style={headerStyle}/>
              <tr className="info"><td colSpan="13"><h4>POST ENTRY</h4></td></tr>
                <AdminRow title="Post Entry Clicks" vals={convertData(postEntryClicks)} key="postEntryClicks"/>
                <AdminRow title="Total Entries (from Sweeps Using Post Entry)" vals={convertData(entriesFromPostEntrySweeps)} key="entriesFromPostEntrySweeps"/>
                <AdminRow title="Sweeps Using Post Entry (#)" vals={convertData(sweepsWithPostEntry)} key="sweepsWithPostEntry"/>
                <AdminRow title="Sweeps Using Post Entry (% of Active)" vals={sweepsWithPostEntryPercentageOfTotal} key="sweepsWithPostEntryPercentageOfTotal"/>
                <AdminRow title="Average Post Entry Clicks / Sweeps (as absolute number)" vals={avgPEClicksSweeps} key="avgPEClicksSweeps"/>
                <AdminRow title="Average Post Entry Clicks / Sweeps (as % of Total Entries)" vals={avgPEClicksEntries} key="avgPEClicksEntries"/>
              <AdminRow className="info" vals={this.props.dates} style={headerStyle}/>
              <tr className="info"><td colSpan="13"><h4>SOCIAL AND BONUS ENTRY DATA</h4></td></tr>
                <AdminRow title="Sweeps Using Bonus Entry (#)" vals={convertData(sweepsActiveWithBonusEntry,',','count_sweeps')} key="sweepsWithBonusEntry"/>
                <AdminRow title="Sweeps Using Bonus Entry (% of Active)" vals={sweepsWithBonusEntryPercentageOfTotal} key="sweepsWithBonusEntryPercentageOfTotal"/>
                {bonusRows}
              <AdminRow className="info" vals={this.props.dates} style={headerStyle}/>
              <tr className="info"><td colSpan="13"><h4>MESSAGING</h4></td></tr>
                <AdminRow title="Initial Messages Sent" vals={convertData(initialMessages)} key="initialMessages"/>
                <AdminRow title="Messages Responded To" vals={convertData(conversationsResponded)} key="messagesRespondedTo"/>
                <AdminRow title="Total Messages Sent" vals={convertData(totalMessages)} key="totalMessagesSent"/>
                <AdminRow title="Message Response Rate" vals={messageResponseRate} key="mResponseRate"/>
                <AdminRow title="Message Growth" vals={messageGrowth} key="messageGrowth"/>
                <AdminRow title="Conversations Started / Active Brands" vals={convStartedPerActiveBrand} key="ConvStartPerActiveB"/>
                <AdminRow title="Conversations Started With Dialog / Active Brands" vals={convStartedWithDialogPerActiveBrand} key="ConvStartWDialogPerActiveB"/>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}


export default FeatureDetail;
