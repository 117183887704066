import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'

import { getClaim } from 'actions/brand-claim-actions'
import { isTrialingAndNotUpgraded } from 'util/brand-stripe-belt-helper'
import COLOURS from 'util/colours'

import buttonTheme from 'css/themes/Buttons.css'
import smsTheme from 'css/themes/sms-marketing.css'

import MessageWrapperEditingContent from './EditingContent'
import MessageWrapperPreviewContent from './PreviewContent'
import MessageWrapperTitle from './MessageTitle'
import MessageWrapperSendTestEditBtns from './SendTestEditButtons'
import { MobileMarketingContext } from '../MobileMarketingContext'
import CreditsBanner from '../CreditsBanner'

const styles = {
  border: {
    outline: `1px solid ${COLOURS.silver}`,
  },
  container: {
    borderRadius: 2,
    width: '100%',
    display: 'flex',
  },
  padding: {
    padding: '28px !important',
    margin: '0 !important',
  },
  content: {
    flex: 1,
  },
  actions: {
    display: 'flex',
    '& div button, & > button:first-of-type': {
      margin: '0 8px 8px 0',
    },
    '& button:hover': {
      '& path': {
        fill: 'white',
      },
    },
  },
  followUp: {
    '&:hover': {
      backgroundColor: `${COLOURS.cloudGrey} !important`,
    },
  },
}

function MobileMarketingPostEntryMessageWrapper(props) {
  const {
    classes: css,
    isFollowup,
    isPreview,
    message,
    isWelcomeMessage,
    currentBrand,
  } = props

  const wrapper = React.useRef()

  const { smsBank, setDeleteID } = React.useContext(MobileMarketingContext)

  const paidMessages = smsBank.credits.count
  const freeMessages = smsBank.credits.freeCount
  const totalMessages = paidMessages + freeMessages
  const isTrial = isTrialingAndNotUpgraded(currentBrand)
  const canCreateSmsMarketingPostEntry = getClaim('sweepstakes.mobileMarketing.create')
  const subscriberLimit = getClaim('sms.subscribers.hardLimit')
  const { subscriberCount } = smsBank
  const subscriberOverLimit = subscriberLimit && subscriberCount >= subscriberLimit

  const showCreditsBanner = isWelcomeMessage
    && !isFollowup
    && canCreateSmsMarketingPostEntry
    && (!paidMessages || subscriberOverLimit)

  const { isEditing, id } = message

  // TODO: add scroll effect when editing message
  // useEffect(() => {
  //   if (isEditing && wrapper.current) wrapper.current.scrollIntoView()
  // }, [isEditing])

  return (
    <div
      ref={wrapper}
      className={cn(
        'messages-wrapper-container',
        css.container, {
          [css.border]: !isPreview,
          [css.padding]: !isEditing || isPreview,
        }
      )}
    >
      <div className={css.content}>
        <MessageWrapperTitle {...props} />
        {showCreditsBanner && (
          <CreditsBanner
            credits={totalMessages}
            isFreeCredits={!!freeMessages && !paidMessages}
            isTrial={isTrial}
            subscriberCount={subscriberCount}
            isWelcomeMessage={isWelcomeMessage}
          />
        )}
        {
          isEditing && !isPreview
            ? <MessageWrapperEditingContent {...props} />
            : <MessageWrapperPreviewContent {...props} />
        }
      </div>
      {
        !isEditing && !isPreview && (
        <div className={css.actions}>
          <MessageWrapperSendTestEditBtns {...props} />
          {
            isFollowup && (
            <Button
              style={{ width: 'auto' }}
              onClick={() => setDeleteID(id)}
              className={cn(
                css.followUp,
                buttonTheme.redText,
                buttonTheme.button,
                buttonTheme.raised,
                smsTheme.whiteButton
              )}
            >
              Delete Message
            </Button>
            )
          }
        </div>
        )
      }
    </div>
  )
}

MobileMarketingPostEntryMessageWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isFollowup: PropTypes.bool,
  isPreview: PropTypes.bool,
  isWelcomeMessage: PropTypes.bool,
  currentBrand: PropTypes.object.isRequired,
}

MobileMarketingPostEntryMessageWrapper.defaultProps = {
  isFollowup: false,
  isPreview: false,
  isWelcomeMessage: false,
}

export default React.memo(injectCSS(styles)(MobileMarketingPostEntryMessageWrapper))
