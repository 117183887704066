import React, { Component } from 'react';
import NetworkBanner from './network-banner';
import ImgAndContent from '../util/img-and-content'
import StartGrowingYourList from '../util/start-growing-your-list';
import WhatTheySay from '../util/what-they-say';
import TrustedBy from '../util/trusted-by';
import MobileFooter from '../mobile-footer/MobileFooter';

import theme from 'css/themes/mobile/home/sweepstakes.css';

class MobileNetwork extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }
  render() {
    return(
      <div>
        <NetworkBanner />
        <TrustedBy whiteBackground={ true } />
        <StartGrowingYourList />
        <WhatTheySay
          quote={ '“We love the platform, how easy it is to use, how it allows us to search and connect with like-minded partners. DojoMojo provides a smart, consumer friendly way to grow our email business. It’s pretty damn brilliant.”' }
          who={ 'Sean Ryan' }
          position={ 'Founder' }
          where={ 'Elevator' }
          link={ 'http://gettheelevator.com/' }
          brandImg={ '/images/brand-logos/Elevator_Logo.png' }
          title={ 'What Marketers Are Saying' }
        />
        <div className={ theme.imgsContentContainers }>
          <p className={ theme.featureName }>
            Features
          </p>
          <ImgAndContent
            imgSrc={ '/images/login/mobile/network/newsfeed.png' }
            imgClass={ 'newsfeed' }
            title={ 'Powerful Search' }
            explain={ 'Search by industry, list size and audience demographics. See what partners are searching for what kinds of partnerships.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/network/brand-profile.png' }
            imgClass={ 'brand-profile' }
            title={ 'Detailed Profiles' }
            explain={ 'View list size, social following, audience geography and income information for any brand on DojoMojo. ' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/network/inbox.png' }
            imgClass={ 'inbox' }
            title={ 'In-App Messaging' }
            explain={ 'Quickly correspond with any brand in the DojoMojo network, straight from your DojoMojo inbox.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/network/partner-analytics.png' }
            imgClass={ 'partner-analytics' }
            title={ 'Partner Analytics' }
            explain={ 'Understand which partners performed best with your audience, and when.' }
          />
        </div>
        <MobileFooter />
      </div>
    )
  }
}

export default MobileNetwork;
