import React, { PureComponent } from 'react'
import Radium from 'radium'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import styleHelper from 'v2/styles/style-helper'

const styles = {
  inputGroup: {
    '&:not(.sweep)': {
      marginBottom: 20,
    },
  },
  inputContainer: {
    width: '100%',
    marginBottom: props => (props.desktopVersion ? 0 : 20),
    '&:last-of-type': {
      marginBottom: 0,
    },
    [styleHelper.max(700)]: {
      marginBottom: '20px !important',
      '&:last-of-type': {
        marginBottom: '0px !important',
      },
    },
  },
  label: {
    color: '#202124',
    marginBottom: 8,
    fontSize: 14,
    fontFamily: 'Open Sans',
    height: props => (props.desktopVersion ? 20 : 'auto'),
    [styleHelper.max(700)]: {
      height: 'auto !important',
    },
  },
  input: {
    background: 'none',
    padding: 12,
    width: '100%',
    border: '1px solid #9e9e9e',
    position: 'relative',
    borderRadius: 2,
    display: 'flex',
    height: 40,
    outline: 'none',
    '&::placeholder': {
      color: 'inherit !important',
      opacity: '.6',
    },
  },
  placeholder: {
    color: props => props.containerStyle.color.color,
    opacity: '.6',
  },
  selectContainer: {
    padding: '0 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#9e9e9e',
  },
  select: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    '-webkit-appearance': 'menulist-button', // make height:100% work on safari
  },
  dropDownArrow: {
    marginLeft: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: props => (props.desktopVersion ? 'row' : 'column'),
    '& > *': {
      marginRight: props => (props.desktopVersion ? 16 : 0),
    },
    '& > *:last-of-type': {
      marginRight: 0,
    },
    [styleHelper.max(700)]: {
      flexDirection: 'column !important',
      '& > *': {
        marginRight: '0 !important',
      },
    },
  },
  hideDesktop: {
    display: props => (props.desktopVersion ? 'none' : 'block'),
    [styleHelper.min(701)]: {
      display: 'none',
    },
    [styleHelper.max(700)]: {
      display: 'block !important',
    },
  },
  hideMobile: {
    display: props => (props.desktopVersion ? 'block' : 'none'),
    [styleHelper.max(700)]: {
      display: 'none !important',
    },
  },
  error: {
    fontSize: 12,
    color: '#ff0000',
    display: 'none',
    textAlign: 'left',
    width: '100%',
  },
}

class Inputs extends PureComponent {
  static propTypes = {
    field: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    textStyle: propTypes.object.isRequired,
    containerStyle: propTypes.object.isRequired,
    inputLabels: propTypes.bool,
    updateAttr: propTypes.func.isRequired,
  }

  static defaultProps = {
    inputLabels: true,
  }

  static contextTypes = {
    baseStyles: propTypes.object,
    placeholderColor: propTypes.string,
  }

  state = {}

  renderSelectArrow = () => {
    const {
      textStyle,
      classes: css,
    } = this.props
    
    return (
      <svg className={css.dropDownArrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <path id="aa" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M-183-250h480v372h-480z" />
          <g>
            <mask id="bb" fill="#fff">
              <use xlinkHref="#aa" />
            </mask>
            <g fill={textStyle.color.color} mask="url(#bb)">
              <path d="M0 0h24v24H0z" />
            </g>
          </g>
        </g>
      </svg>
    )
  }


  onSelectUpdate = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  renderSelect = field => {
    const {
      classes: css,
      containerStyle,
      textStyle,
      inputLabels,
      updateAttr,
    } = this.props

    const {
      name,
      placeholder,
      formattedLabel,
      noLabelPlaceholder,
    } = field

    let {
      options,
    } = field

    const {
      baseStyles,
    } = this.context

    // if select, build options
    options = Array.isArray(options)
      ? (options.map(value => (
        <option
          key={value}
          value={value}
        >
          {value}
        </option>
      )))
      : (
        Object.keys(options).map(key => (
          <option
            key={key}
            value={key}
          >
            {options[key]}
          </option>
        ))
      )

    options.unshift(
      <option value="" disabled selected>
        {inputLabels ? placeholder : noLabelPlaceholder || formattedLabel}
      </option>
    )

    return (
      <div
        className={cn({
          'form-input': true,
          [css.input]: true,
          [css.selectContainer]: true,
          [baseStyles.inputsInput]: true,
        })}
        style={{
          border: `1px solid ${containerStyle.color.color}`,
        }}
      >
        <div
          className={cn('fancy-select-placeholder', css.placeholder, baseStyles.inputsSelectPlaceholder)}
        >
          {inputLabels ? placeholder : formattedLabel}
        </div>
        <div
          className="fancy-select-value"
          style={{
            color: textStyle.color.color,
          }}
        >
          {this.state[name] || ''}
        </div>
        
        {this.renderSelectArrow()}
        <select
          name={name}
          style={styles}
          className={cn('fancy-select', css.select)}
          onChange={this.onSelectUpdate}
          disabled={!!updateAttr}
        >
          {options}
        </select>
      </div>
    )
  }
  
  renderTextBox = ({
    name,
    inputType = 'text',
    placeholder,
    noLabelPlaceholder,
    formattedLabel,
    required,
  }) => {
    const {
      classes: css,
      containerStyle,
      textStyle,
      inputLabels,
      updateAttr,
    } = this.props
    
    const {
      baseStyles,
    } = this.context
    return (
      <input
        name={name}
        type={inputType || 'text'}
        className={cn('fancy-input', 'form-input', css.input, baseStyles.inputsInput)}
        placeholder={inputLabels ? placeholder : noLabelPlaceholder || formattedLabel}
        data-color={textStyle.color.color}
        disabled={!!updateAttr}
        data-required={required !== false}
        style={{
          border: `1px solid ${containerStyle.color.color}`,
        }}
      />
    )
  }

  renderInput = field => {
    const {
      classes: css,
      textStyle,
      inputLabels,
    } = this.props

    const {
      baseStyles,
    } = this.context

    return (
      <div className={css.inputContainer}>
        {
          inputLabels
          && (
            <div
              className={cn(css.label, baseStyles.inputsLabel)}
              style={{
                color: textStyle.color.color,
              }}
            >
              {field.formattedLabel}
            </div>
          )
        }
        {
          field.inputType === 'select'
            ? this.renderSelect(field)
            : this.renderTextBox(field)
        }
      </div>
    )
  }

  render() {
    const {
      field,
      classes: css,
      containerStyle,
    } = this.props

    const {
      placeholderColor,
    } = this.context

    return (
      <div
        className={cn({
          'form-group': true,
          [`form-group-${field.key}`]: true,
          [css.inputGroup]: true,
          [css.hideMobile]: field.fields[0].hideMobile,
          [css.hideDesktop]: field.fields[0].hideDesktop,
        })}
        style={{
          color: placeholderColor || containerStyle.color.color,
        }}
      >
        <div className={css.row}>
          { field.fields.map(this.renderInput) }
        </div>
        <span className={cn('error-msg', css.error)}>
          {field.fields[0].errorMsg || 'Required field'}
        </span>
      </div>
    )
  }
}

export default injectSheet(styles)(Inputs)
