import React from 'react';
import _ from 'lodash'

import COLOURS from 'util/colours'

class Error extends React.Component {
  render() {
    if (!this.props.error) {
      return null;
    }
    var errors = this.props.error.errors;
    var errorMessages = _.map(errors, (text, idx) => <span key={idx} className="help-block">{text}</span>);

    return (
      <div className="help-block"
        style={{
          color: COLOURS.alertRed,
          textAlign: 'left',
          fontFamily: "'Larsseit-Medium' !important",
          fontSize: '9px',
          lineHeight: '9px'
      }}>
        { errorMessages }
      </div>
    );
  }
};

export default Error