import React, { PureComponent } from 'react'
import injectCSS from 'react-jss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'

import buttonTheme from 'css/themes/Buttons.css'
import smsTheme from 'css/themes/sms-marketing.css'

import { H4, P } from 'visual-components/util/texts'
import MobileMarketingPostEntry from 'util/components/templates/form/mobile-marketing/mobile-marketing-post-entry'
import COLOURS from 'util/colours'
import MobileMarketingPostEntryMessageWrapper from './FollowupMessagesWrapper'
import SMSInfoBanner from './SMSInfoBanner'
import { MobileMarketingContext } from './MobileMarketingContext'

const SCALE_RATIO = 0.75

const styles = {
  container: {
    border: '1px solid #e0e0e0',
  },
  separator: {
    height: 1,
    backgroundColor: COLOURS.silver,
    width: 'calc(100% + 84px)',
    marginLeft: -52,
    marginBottom: 40,
  },
  preview: {
    padding: '32px 12px 0 32px',
  },
  soloPreview: {
    padding: '0 12px 0 32px',
  },
  previewMsgs: {
    position: 'relative',
    '& > .messages-wrapper-container': {
      padding: '0 !important',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: '""',
        height: 12,
        width: 12,
        marginLeft: -5,
        borderRadius: '50%',
        backgroundColor: COLOURS.silver,
      },
      '& .messages-wrapper-title': {
        paddingRight: 0,
      },
    },
    '& > [class*=SMSInfoBanner-wrapper]': {
      marginBottom: 24,
    },
  },
  followupCriteria: {
    position: 'relative',
    zIndex: 1,
    display: 'inline-block',
    backgroundColor: COLOURS.crystal,
    borderRadius: 2,
    padding: '12px 8px',
    margin: '0 0 32px -12px',
  },
  scale: {
    maxWidth: 500,
    transform: `scale(${SCALE_RATIO})`,
    transformOrigin: '0 0',
    '& *': {
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
  title: {
    padding: '0 24px',
    display: 'flex',
    '& > :first-child': {
      flex: 1,
    },
  },
  soloTitle: {
    padding: 0,
  },
  actions: {
    display: 'flex',
    '& div button': {
      marginRight: 8,
      marginBottom: 4,
    },
    '& div + button': {
      '&:hover path': {
        fill: 'white',
      },
    },
  },
  icon: {
    '& path': {
      // :hover state in actions prop
      fill: COLOURS.azure,
    },
  },
  space: {
    marginTop: 32,
  },
  progress: {
    position: 'absolute',
    width: 2,
    backgroundColor: COLOURS.silver,
  },
}

class MobileMarketingPostEntryPreview extends PureComponent {
  previewRef = React.createRef()

  state = {
    progress: 0,
  }

  componentDidMount() {
    const { isEditing } = this.props

    if (!isEditing) {
      setTimeout(this.calculateProgress, 50)
      setTimeout(this.calculateContainerRatio, 200)
    }
  }

  // TODO: Add scrolling when closing* editing mode
  // componentDidUpdate() {
  //   const { isEditing } = this.props

  //   if (!isEditing) {
  //     this.previewRef.current.scrollIntoView(true)
  //     this.previewRef.current.scrollTop -= 64
  //   }
  // }

  calculateContainerRatio = () => {
    const container = document.querySelector('[class*=EntryPreview-scale]')

    if (!container) return

    const wrapper = container.parentElement

    const height = window.getComputedStyle(container, null).getPropertyValue('height').slice(0, -2)

    wrapper.style.height = `calc(${Number(height) * SCALE_RATIO}px + 80px)`
    wrapper.style.overflow = 'hidden'
  }

  calculateProgress = () => {
    const elements = document.querySelectorAll('.messages-wrapper-container')

    let progress = 0

    const items = Array.from(elements).slice(0, -1)
    items.forEach(element => (progress += element.offsetHeight))

    if (items.length) progress += 80

    if (this.state.progress !== progress) this.setState({ progress })
  }

  render() {
    const {
      brandName,
      isEditing,
      previewPostEntry,
      currentBrand,
      currentCampaign: { isSolo },
      classes: css,
    } = this.props

    const {
      followups,
      smsSend,
      oldCouponsCodes: oldCoupons,
      toggleEditMode,
      postEntry,
      uploadImage,
      followupSendCriteria,
    } = this.context

    const criteriaToPreview = {
      'all recipients': 'Send to all original recipients',
      'not clicked': 'Send to subscribers that haven\'t clicked',
      'not purchased': 'Send to subscribers that haven\'t purchased',
      'not clicked or purchased': 'Send to subscribers that haven\'t clicked or purchased',
      'clicked but not purchased': 'Send to subscribers that have clicked but haven\'t purchased',
    }[followupSendCriteria]

    const { progress } = this.state

    const PostEntryPreviewActions = () => (
      <div className={`${css.title} ${isSolo ? css.soloTitle : ''}`}>
        <H4 style={{ marginBottom: '16px' }}>
          <em>Your Post Entry Form</em>
        </H4>
        <div className={css.actions}>
          <div>
            <Button
              style={{ width: 'auto' }}
              onClick={previewPostEntry}
              className={cn(
                buttonTheme.button,
                buttonTheme.raised,
                buttonTheme.whiteButtonAzureText,
                smsTheme.whiteButton
              )}
            >
              Preview
            </Button>
          </div>
          <Button
            style={{ width: 'auto' }}
            onClick={toggleEditMode}
            className={cn(
              buttonTheme.button,
              buttonTheme.raised,
              buttonTheme.whiteButtonAzureText,
              smsTheme.whiteButton
            )}
          >
            Edit
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              style={{ marginLeft: 8, marginTop: -10 }}
              className={css.icon}
            >
              <path
                fillRule="nonzero"
                d="M15.762 3.98l-1.58 1.579-3.741-3.741 1.578-1.58c.536-.536 1.072 0 1.072 0l2.67 2.67s.537.536 0 1.072zM0 16l4.536-.796-3.75-3.75L0 16zm1.312-5.055l3.742 3.742 8.554-8.553-3.742-3.742-8.554 8.553z"
              />
            </svg>
          </Button>
        </div>
      </div>
    )

    const oldCouponsFound = [smsSend, ...followups]
      .map(({ coupon_code }) => coupon_code)
      .filter(code => oldCoupons && oldCoupons.includes(code))

    return (
      <div className={cn({ [css.preview]: !isEditing, [css.soloPreview]: isSolo })}>
        <div className={cn({ [css.space]: isEditing })}>
          {!isSolo && !isEditing && <PostEntryPreviewActions />}
          <div className={cn(css.container, { [css.scale]: !isEditing })}>
            <MobileMarketingPostEntry
              cta={postEntry}
              brandName={brandName}
              preview
              showImageUploadCTA={isEditing}
              onDrop={uploadImage}
            />
          </div>
        </div>
        {
          !isSolo && !isEditing && (
            <div className={css.previewMsgs} ref={this.previewRef}>
              <div className={css.separator} />
              {
                oldCouponsFound.length > 0 && (
                  <SMSInfoBanner
                    pad
                    title="Previous Code Detected"
                    body={`${oldCouponsFound.join(', ')} ${oldCouponsFound.length > 1 ? 'were' : 'was'} used in a previous campaign. Reusing coupon codes will break conversion tracking. Use a unique code`}
                    type="warning"
                  />
                )
              }
              <div className={css.progress} style={{ height: progress }} />
              <MobileMarketingPostEntryMessageWrapper
                currentBrand={currentBrand}
                message={smsSend}
                isPreview
              />
              {
                followups.length > 0 && (
                  <div className={css.followupCriteria}>
                    <P>{criteriaToPreview}</P>
                  </div>
                )
              }
              {
                followups.map(message => (
                  <MobileMarketingPostEntryMessageWrapper
                    currentBrand={currentBrand}
                    message={message}
                    isFollowup
                    isPreview
                    key={message.id}
                  />
                ))
              }
            </div>
          )
        }
      </div>
    )
  }
}

MobileMarketingPostEntryPreview.contextType = MobileMarketingContext

MobileMarketingPostEntryPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
  brandName: PropTypes.string,
  previewPostEntry: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
}

MobileMarketingPostEntryPreview.defaultProps = {
  isEditing: false,
  brandName: '',
}

export default React.memo(injectCSS(styles)(MobileMarketingPostEntryPreview))
