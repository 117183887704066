import React from 'react'
import PropTypes from 'prop-types'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function PublishingModal({ isSolo }) {
  const { publishPostEntry, showModal, modals } = React.useContext(MobileMarketingContext)
  const title = isSolo ? 'Publish Your SMS Welcome Message' : 'Before You Publish'
  const content = isSolo ? 'Before your SMS Post Entry can go live, you will need to also publish your SMS Welcome Message.' : 'Please note: Publishing will make your changes visible to all entrants.'
  const confirmText = isSolo ? 'Publish Message' : 'Publish'
  return (
    <SimpleInfoModal
      title={title}
      active={modals.publish}
    >
      <H4 multiline>{content}</H4>
      <ModalButtonGroup
        canSave
        confirm={publishPostEntry}
        cancel={() => showModal('publish', false)}
        confirmText={confirmText}
        cancelText="Cancel"
        hideLine
      />
    </SimpleInfoModal>
  )
}

PublishingModal.propTypes = {
  isSolo: PropTypes.bool,
}

PublishingModal.defaultProps = {
  isSolo: false,
}

export default React.memo(PublishingModal)
