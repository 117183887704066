import React, { Component } from 'react';
import { string } from 'prop-types';
import theme from 'css/themes/homePage/home.css';

class BlueBanner extends Component {
  static propTypes = {
    headline: string.isRequired,
    explain: string.isRequired,
    imgSrc: string.isRequired
  }

  render() {
    const {
      headline,
      explain,
      imgSrc,
      href
    } = this.props;

    return(
      <div className={ theme.blueBanner } id={ href }>
        <div className={ theme.leftside }>
          <div className={ theme.headline }>
            { headline }
          </div>
          <div className={ theme.explain }>
            { explain }
          </div>
        </div>
        <div className={ theme.rightside }>
          <img src={ imgSrc } />
        </div>
      </div>
    )
  }
}

export default BlueBanner;
