
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Link, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Button } from 'react-toolbox/lib/button'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import InterestsCard from 'visual-components/brandProfile/sellerProfile/InterestsCard'
import AboutCard from 'visual-components/brandProfile/AboutCard'

import AudienceCard from 'visual-components/brandProfile/AudienceCard'
import SocialCard from 'visual-components/brandProfile/SocialCard'
import BrandTabs from 'visual-components/brandProfile/brand-tabs'
import CampaignsTab from 'visual-components/brandProfile/campaigns-tab'
import OpportunitiesTab from 'visual-components/brandProfile/opportunities-tab'

import BrandDetailDispatcher from 'dispatchers/brand-detail-dispatcher'

import BrandReviewsTab from 'visual-components/brandProfile/brand-reviews/BrandReviews'

import { Dialog } from 'react-toolbox/lib/dialog'
import { H4, H3 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import BrandDetailCard from 'visual-components/shared/brand-detail-card/BrandDetailCard'
import PropTypes from 'prop-types'

import PendingReviewsReminder from 'visual-components/brandProfile/brand-reviews/PendingReviewsReminder'

import getCoverPhotoUrl from 'util/brand-cover-photo'
import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/dialogBase.css'

import GroupAgeDistributionModal from './GroupAgeDistributionModal'
import EditPartnershipInterestModal from './EditPartnershipInterestModal'
import AudienceDescriptionModal from './AudienceDescriptionModal'
import GenderDistributionModal from './GenderDistributionModal'
import MediaSheetModal from './MediaSheetModal'

const hideEntriesBrands = [2525, 6833, 6834, 7144, 6835, 6836]

const themes = {
  Button: ButtonTheme,
  Dialog: DialogTheme,
}

const stateToProps = state => ({
  brand: state.brand,
  currentBrand: state.currentBrand,
  general: state.general,
  brandsAlsoViewed: state.brandsAlsoViewed,
  dealRequest: state.dealRequest,
  sellerProfile: state.sellerProfile,
})

class BrandsAlsoViewed extends PureComponent {
  static propTypes = {
    brandsAlsoViewed: PropTypes.array.isRequired,
  }

  render() {
    const {
      brandsAlsoViewed,
    } = this.props

    const recommended = brandsAlsoViewed
      .slice(0, 4)
      .map(brand => (
        <BrandDetailCard
          key={brand.id}
          logo={brand.logo}
          accountname={brand.accountname}
          dedicatedListSize={brand.dedicatedListSize}
          averageSignUpsPerCampaign={brand.averageSignUpsPerCampaign}
          socialMediaImpressions={brand.socialMediaImpressions}
          tags={brand.tagsconnected.map(tag => tag.name)}
          degreeOfRemoval={brand.degreeOfRemoval}
          facebookCoverPhoto={brand.social.facebookCoverPhoto}
          coverPhotoOffsetY={brand.social.coverPhotoOffsetY || 0}
          brandId={brand.id}
          homePage
          currentBrandId={null}
        />
      ))

    return (
      <Row style={{ marginTop: '40px' }}>
        <Col xs={12}>
          <H3 coral>Brands Also Viewed</H3>
        </Col>
        <Col
          xs={12}
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '16px',
          }}
        >
          {recommended}
        </Col>
      </Row>
    )
  }
}

// eslint-disable-next-line react/no-multi-comp
class BrandProfileContainerBase extends PureComponent {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    brandsAlsoViewed: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    sellerProfile: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    isPublic: PropTypes.bool,
    match: PropTypes.object.isRequired,
    dealRequest: PropTypes.object.isRequired,
  }

  static defaultProps = {
    isPublic: false,
  }

  state = { remindLogoModalOpen: false }

  componentDidMount() {
    const {
      isPublic,
      currentBrand,
      match: { params: { brandId } },
    } = this.props

    if (
      !isPublic
      && parseInt(brandId, 10) === currentBrand.id
      && (!currentBrand.logo
      || currentBrand.logo.indexOf('Empty-Avatar.png') !== -1)
    ) {
      this.setState({ remindLogoModalOpen: true })
    }
  }

  componentDidUpdate() {
    const { dispatch, isPublic, match: { params: { brandId } }, brand } = this.props
    const newBrandId = parseInt(brandId, 10)

    if (
      !isPublic && brand.loaded && newBrandId !== brand.id) {
      BrandDetailDispatcher.loadBrand(newBrandId)
      BrandDetailDispatcher.loadPartnershipInterests(newBrandId)
    }
  }

  hideRemindLogoModal = () => this.setState({ remindLogoModalOpen: false })

  renderRemindLogoModal = () => {
    const { remindLogoModalOpen } = this.state

    return (
      <Dialog
        theme={themes.Dialog}
        active={remindLogoModalOpen}
        onEscKeyDown={this.hideRemindLogoModal}
        onOverlayClick={this.hideRemindLogoModal}
        title="Add Your Profile Photo"
      >
        <div style={{ textAlign: 'center' }}>
          <img src="/images/login/sweepstakes/mojo-thumbs-up-new.svg" style={{ width: '122px', height: '120px', display: 'inline-block' }} />
        </div>
        <H4 multiline>
          Be sure to add your profile photo as soon as possible so other brands can recognize you across the platform.
        </H4>
        <ModalButtonGroup
          canSave
          hideLine
          confirm={this.hideRemindLogoModal}
          confirmText="Got it"
        />
      </Dialog>
    )
  }

  renderRequestButton = () => {
    const {
      brand,
      currentBrand,
      dealRequest,
    } = this.props

    const isSeller = brand.features.includes('mediaMarketSeller')
    const { mediaMarketSeller: isCurrentBrandSeller } = currentBrand.features

    if (
      dealRequest.loading
      || currentBrand.id === brand.id
      || (
        !isSeller
        && !isCurrentBrandSeller
      )
    ) return null

    const linkTo = isSeller
      ? dealRequest.id
        ? `/partnerships/deal/${dealRequest.deal_id}/collaborate`
        : `/partnerships/request/${brand.id}/new`
      : '/partnerships/deal/new'

    const btnCopy = isSeller
      ? dealRequest.id
        ? 'View RFP'
        : 'Start RFP'
      : 'Sell Media'

    return (
      <Link to={linkTo}>
        <Button
          label={btnCopy}
          className={themes.Button.whiteButton}
          theme={themes.Button}
          raised
          style={{
            fontFamily: 'Larsseit-Medium',
            fontSize: '14px',
            width: '122px',
            height: '36px',
            marginLeft: '8px',
          }}
        />
      </Link>
    )
  }

  render() {
    const {
      brand,
      currentBrand,
      match,
      isPublic,
      brandsAlsoViewed,
      sellerProfile,
    } = this.props

    const prefix = match.path
    const prefixWithId = match.url

    const isBrand = !isPublic && currentBrand.id === brand.id

    const brandTags = isBrand
      ? [currentBrand.brand_tag_0, currentBrand.brand_tag_1, currentBrand.brand_tag_2]
      : brand.tags || brand.tagsconnected.map(tag => tag.name)

    let brandDescription = isBrand ? currentBrand.description : brand.description

    brandDescription = brandDescription || ''

    const brandListSize = isBrand ? currentBrand.dedicatedListSize : brand.dedicatedListSize

    const coverPhoto = getCoverPhotoUrl(isBrand ? currentBrand.facebookCoverPhoto : brand.facebookCoverPhoto)

    const tabOptions = [
      {
        name: 'campaigns',
        title: 'Campaigns',
        href: `${prefixWithId}/campaigns`,
        count: isPublic ? brand.campaignCount : brand.availableCampaigns.length,
      },
      {
        name: 'opportunities',
        title: 'Opportunities',
        href: `${prefixWithId}/opportunities`,
        count: brand.opportunities && brand.opportunities.length,
      },
      isPublic
        ? null
        : (
          {
            name: 'reviews',
            title: 'Reviews',
            href: `${prefixWithId}/reviews`,
          }
        ),
    ].filter(option => option !== null)

    brand.obscure = isPublic

    const { obscure } = brand
    const override = isPublic && 5

    const metaCoverPhoto = coverPhoto
      ? coverPhoto.startsWith('http') ? coverPhoto : `http:${coverPhoto}`
      : 'http://www.dojomojo.com/images/defaults/coverPhotoFull2x.png'

    const facebookFollowers = isBrand
      ? currentBrand.facebookFollowers
      : brand.facebookFollowers || brand['social.facebookFollowers']

    const twitterFollowers = isBrand
      ? currentBrand.twitterFollowers
      : brand.twitterFollowers || brand['social.twitterFollowers']

    const instagramFollowers = isBrand
      ? currentBrand.instagramFollowers
      : brand.instagramFollowers || brand['social.instagramFollowers']

    const youtubeFollowers = isBrand
      ? currentBrand.youtubeFollowers
      : brand.youtubeFollowers || brand['social.youtubeFollowers']

    const pinterestFollowers = isBrand
      ? currentBrand.pinterestFollowers
      : brand.pinterestFollowers || brand['social.pinterestFollowers']

    const socialMediaImpressions = isBrand
      ? currentBrand.socialMediaImpressions
      : brand.socialMediaImpressions || brand['social.socialMediaImpressions']

    brand.website = brand.website && brand.website.startsWith('http:')
      ? brand.website
      : `http://${brand.website}`

    // block avg entries from displaying for brand 'DojoMojo Partnerships' & Sensei accounts
    const averageSignUpsPerCampaign = !hideEntriesBrands.includes(brand.id)
      && brand.averageSignUpsPerCampaign

    const socialHandles = isBrand
      ? currentBrand.social
      : {
        facebookUrl: brand.facebookUrl,
        instagramHandle: brand.instagramHandle,
        pinterestHandle: brand.pinterestHandle,
        twitterHandle: brand.twitterHandle,
        youtubeHandle: brand.youtubeHandle,
      }

    return (
      <InternalContentContainer width="1096px">
        <Helmet encodeSpecialCharacters={false}>
          <title>{`${brand.accountname} | Partnerships Profile`}</title>
          <meta content={`${brand.description}`} name="description" />
          <meta property="og:title" content={`${brand.accountname} | Partnerships Profile`} />
          <meta property="og:description" content={`${brand.description}`} />
          <meta property="og:image" content={metaCoverPhoto} />
        </Helmet>
        <div style={{ width: '100%', marginTop: 432 }}>
          <Grid>
            <Row style={{ paddingTop: '32px' }}>
              <Col style={{ width: '216px' }}>
                <AboutCard
                  isBrand={isBrand}
                  degree={brand.degreeOfRemoval}
                  description={brandDescription}
                  website={brand.website}
                  tags={brandTags}
                  ratingCardInfo={{
                    obscure: isPublic,
                    totalRating: override || brand.totalRating,
                    totalRatingCount: override || brand.totalRatingCount,
                    brandId: brand.id,
                  }}
                />
                {
                  !isPublic
                  && (
                    <InterestsCard
                      isBrand={isBrand}
                      interests={sellerProfile.interests}
                    />
                  )
                }
                <AudienceCard
                  isBrand={isBrand}
                  obscure={obscure}
                  dedicatedListSize={brandListSize}
                  averageSignUpsPerCampaign={averageSignUpsPerCampaign}
                />
                <SocialCard
                  isBrand={isBrand}
                  obscure={obscure}
                  facebookFollowers={facebookFollowers}
                  twitterFollowers={twitterFollowers}
                  instagramFollowers={instagramFollowers}
                  youtubeFollowers={youtubeFollowers}
                  pinterestFollowers={pinterestFollowers}
                  socialMediaImpressions={socialMediaImpressions}
                  socialHandles={socialHandles}
                />
              </Col>
              <Col style={{ width: '864px', marginLeft: '16px' }}>
                <BrandTabs tabOptions={tabOptions} currentBrand={currentBrand} brand={brand} />
                <Switch>
                  <Redirect exact from={prefix} to={`${prefix}/campaigns`} />
                  <Route exact path={`${prefix}/campaigns`} render={() => <CampaignsTab brand={brand} currentBrand={currentBrand} />} />
                  <Route exact path={`${prefix}/opportunities`} component={() => <OpportunitiesTab brand={brand} currentBrand={currentBrand} />} />
                  {
                    !isPublic
                    && ([
                      <Route exact path={`${prefix}/reviews`} component={BrandReviewsTab} />,
                    ])
                  }
                </Switch>
              </Col>
            </Row>
            {
              isPublic
                ? <BrandsAlsoViewed brandsAlsoViewed={brandsAlsoViewed} />
                : null
            }
          </Grid>
        </div>
        {this.renderRemindLogoModal()}
        {
          isBrand && (
            <React.Fragment>
              <EditPartnershipInterestModal />
              <AudienceDescriptionModal />
              <GroupAgeDistributionModal />
              <GenderDistributionModal />
              <MediaSheetModal />
            </React.Fragment>
          )
        }
        {
          !isBrand
          && !isPublic
          && (
            <PendingReviewsReminder left={116} pendingOnBrand />
          )
        }
      </InternalContentContainer>
    )
  }
}

export default withRouter(connect(stateToProps)(BrandProfileContainerBase))
