import React from 'react'

import { H2, H3, P } from 'visual-components/util/texts'
import styles from 'css/themes/Text.css'

const { terms } = styles

const CTAAdvertiserTerms = () =>
  <div className="container">
    <div className={`row ${terms}`}>
      <H2>DOJOMOJO NETWORK TERMS</H2>

      <P>The following DOJOMOJO Network Terms ("Terms") govern the
      implementation and use of the products, services, and technology made available
      by DOJOMOJO to your company, the Partner.</P>

      <H3>1. NETWORK MEMBERSHIP; SCOPE OF SERVICES</H3>

      <P>1.1 Relationship of the Parties: The Partner appoints
      DOJOMOJO as an independent contractor during the Term to provide the Services
      set out in the Service Schedule, in accordance with the Agreement. DOJOMOJO
      will be the Partner's provider of Marketplace Services during the Term.
      DOJOMOJO may assign any of its rights and delegate any of its duties under this
      Agreement to one or more of its subsidiaries.</P>

      <P>1.2 Additional Services: Partner may elect to include
      additional TMS in the Services by email or other written confirmation agreed by
      Partner and DOJOMOJO from time to time.</P>

      <P>1.3 Integration of the DOJOMOJO Software. Promptly after the
      date of this Agreement DOJOMOJO will provide Partner with access to the
      DOJOMOJO Software and related documentation. Partner will ensure that the
      DOJOMOJO Software code, as updated and released from time to time, is correctly
      included on the Partner Pages during the Term, as necessary to permit Users to
      interact with the Services.</P>

      <P>1.4 TMS Fees: Transaction Marketing Services "TMS" Fees, if
      any, shall be charged by DOJOMOJO and paid by Partner in accordance with the
      Services Schedule.</P>

      <P>1.5 TERMS SPECIFIC TO MARKETPLACE SERVICES. Marketplace
      Services will be available to Partner for use in conjunction with certain other
      Transaction Marketing Services. To the extent that Partner avails itself of
      Marketplace Services, the following terms apply:</P>

      <P>(a) Managing Marketplace Services via online portal or
      account rep. Partner shall activate and manage the Marketplace Services through
      its online partner portal provided by DOJOMOJO, or if applicable, through a
      DOJOMOJO account manager. The partner portal shall be designed and administered
      by DOJOMOJO.</P>

      <P>(b) Serving Content into the Partner Content Areas. When
      Marketplace Services are active, the DOJOMOJO platform will serve Promotional
      Content into the Partner Content Areas via proprietary algorithms. Partner will
      not reduce or restrict Partner Content Areas available for Marketplace Services
      without DOJOMOJO's approval.</P>

      <P>(c) Bidding by Promoters. Marketplace Services involve a
      market mechanism, where all available Promotional Content is evaluated on a
      number of factors, including the maximum bid set by the Promoter and relevance
      to the particular individual User, to determine which Promotional Content should
      be placed. Although the intent is to generate revenues from Promoters, DOJOMOJO
      does not warrant or represent that such revenues will be the maximum amount
      producible, for each placement or in the aggregate, or guarantee any minimum
      level of performance or of revenues.</P>

      <P>(d) Revenue Share Fee: DOJOMOJO will invoice and collect any
      charges paid by Promoters on its own account. DOJOMOJO shall pay to Partner a
      portion of such charges equal to 80% of the Net Receipts attributable to
      Promotional Content served into the Partner Content Areas (the "Partner Revenue
      Share Fee").</P>

      <P>(e) Promotional Content and Requirements; Removal: DOJOMOJO
      will (i) represent the DOJOMOJO Platform fairly and honestly to Promoters, (ii)
      not misrepresent the Partner Pages or Agreed Partner Content Areas to any
      Promoter, and (iii) ensure that each Promoter has accepted the DOJOMOJO Terms
      of Use before accepting any Promotional Content, and direct the Promoter to comply
      with them. </P>

      <P>1.6 Reports: The Parties will provide each other with accurate
      operational reports as reasonably requested from time to time.</P>

      <P>1.7 Support: DOJOMOJO will provide reasonable ongoing
      assistance to Partner in regard to technical, administrative and
      service-oriented issues relating to Partner's use of the DOJOMOJO Platform.</P>

      <P>1.8 Invoicing: The Partner authorizes DOJOMOJO to generate a
      recipient created invoice ("RCI") based on its accounting for the Partner
      Revenue Share Fee and Referral Bonus (defined below), if any, within ten (10)
      days following the end of each calendar month directly following the month of
      activity covered by the invoice. TMS Fees incurred by the Partner, if any, may
      be applied to and offset any Partner Revenue Share Fee.</P>

      <P>1.9 Estimation of cleared funds: To facilitate the process
      of paying the Partner earlier, DOJOMOJO may estimate the cleared funds prior to
      the receipt of all funds. If done, the funds will be reconciled on a quarterly
      basis.</P>

      <P>1.10 Terms of Payment: DOJOMOJO will pay any net amounts due
      to Partner as shown on any invoice within forty-five (45) days of the end of
      each calendar month in which such invoice is created.</P>

      <P>1.11 Disputes: Where there is a bona fide dispute between
      the Parties as to any amounts payable by one Party to the other, such disputed amounts
      may be withheld so long as each Party is working reasonably to resolve such
      dispute. Any undisputed amounts must be paid without delay as per the agreed
      payment terms.</P>

      <P>1.12 Taxes: Where specific taxes are due and payable in
      respect of TMS Fees, Referral Bonus or the Partner Revenue Share Fee, Partner
      shall be responsible for such taxes. DOJOMOJO may make tax withholdings from
      amounts owed to Partner to the extent required by law.</P>

      <P>1.13 Other Costs: Unless otherwise provided in this
      Agreement, each party shall retain its own revenues and bear its own costs and
      expenses in connection with its activities performed under this Agreement.</P>

      <H3>2. TERM AND TERMINATION</H3>

      <P>2.1 Term: The Agreement will commence on the Effective Date
      and will remain in effect for the initial term of three years ("Initial Term
      "). After the Initial Term, the Agreement shall auto-renew for successive
      three-year periods (each a "Renewal Term ") unless written notice of an intent to
      opt out of such renewal is provided by either party at least thirty (30) days
      before such renewal date. The Initial Term and any and all Renewal Terms shall
      collectively be referred to herein as the "Term ".</P>

      <P>2.2 Termination: Either Party may terminate this Agreement
      with immediate effect by giving written notice to the other Party in the event that:</P>

      <P>(a) the other Party materially breaches the provisions of
      this Agreement; provided that if such breach is capable of remedy, no such
      termination shall be effective unless and until the breaching party fails to
      remedy the breach within thirty (30) days after receiving notice from the
      non-breaching Party demanding it to do so; or (b) the other party suffers an
      Insolvency Event.</P>

      <P>2.3 Effect of Termination. Any termination pursuant to
      clause 2.2 will be without any liability or obligation of the terminating
      party, other than with respect to any breach of the Agreement prior to
      termination. Termination does not affect any accrued rights of either party.
      The provisions of clauses 3 through 8 of this Agreement will survive the
      expiration or termination of the Agreement for any reason.</P>

      <H3>3. REPRESENTATIONS AND WARRANTIES, COVENANTS AND
      ACKNOWLEDGEMENTS</H3>

      <P>3.1 Partner warranties: Partner represents and warrants to
      DOJOMOJO as at the date of this agreement that:</P>

      <P>(a) Partner is duly organized, validly existing and in good
      standing, and has the authority to carry on its business as conducted; </P>

      <P>(b) the execution, delivery and performance of this
      Agreement have been duly authorized by Partner and this Agreement constitutes a
      valid and binding agreement of Partner; and </P>

      <P>(c) any necessary software, systems or equipment utilized in
      connection with the Partner Content Areas are owned, validly licensed or
      otherwise legally controlled by the Partner.</P>

      <P>3.2 DOJOMOJO warranties: DOJOMOJO represents and warrants to
      Partner as at the date of this agreement that:</P>

      <P>(a) DOJOMOJO is duly organized, validly existing and in good
      standing, and has the authority to carry on its business as conducted; </P>

      <P>(b) the execution, delivery and performance of this
      Agreement have been duly authorized by DOJOMOJO and this Agreement constitutes
      a valid and binding agreement of DOJOMOJO.</P>

      <P>3.3 Partner Page. Partner manages and controls the related
      landing page, and Partner retains responsibility for the continuing
      performance, accuracy, design, layout, of the Partner Page. From time to time,
      Partner may choose to redesign or modify the organization, structure,
      specifications, arrangement, format, "look and feel," navigation,
      functionality, guidelines, and/or other elements of the Partner Page. </P>

      <P>3.4 No interference: Partner will not intentionally do
      anything that would interfere, impede, or alter the ability of DOJOMOJO to
      provide Services. Unless expressly authorized in writing by DOJOMOJO, Partner
      may not enter into any type of arrangement with a third party where that third
      party receives payments made to Partner under the Agreement or other financial
      benefit in relation to the Services.</P>

      <P>3.5 DOJOMOJO Platform: Partner acknowledges that DOJOMOJO is
      the owner of the DOJOMOJO Platform, including the DOJOMOJO Software and any related
      elements, and DOJOMOJO retains responsibility for the design, layout, hosting,
      and maintenance of the DOJOMOJO Platform, including the DOJOMOJO Software and
      any related elements. From time to time, DOJOMOJO may choose to redesign or modify
      the organization, structure, specifications, arrangement, format, "look and
      feel," navigation, functionality, guidelines, and/or other elements of the
      DOJOMOJO Platform, including the DOJOMOJO Software and any related websites at
      any time, and may suspend or stop a Service altogether. In the event any
      Service is suspended or cancelled, DOJOMOJO will refund to Partner any prepaid
      TMS Fees not yet earned.</P>

      <P>3.6 Other Partner Covenants</P>

      <P>(a) Partner will comply, and cause its employees, agents and
      subcontractors to comply, in all material respects, with all laws, rules and
      regulations applicable to Partner's performance of its obligations under this
      Agreement;</P>

      <P>(b) Partner will not use the Services or any User
      information generated thereby to:</P>

      <P>(i) send unsolicited or unauthorized advertising,
      promotional materials, junk mail, spam, chain letters, pyramid schemes or any
      other form of duplicative or unsolicited messages, whether commercial or
      otherwise, or to send any defamatory, libelous, threatening, racist or obscene
      messages or messages that violate a User's right to privacy or a right of
      publicity; or</P>

      <P>(ii) violate any laws or regulations specific to the markets
      in which it operates, including those related to spamming, junk mail or other
      related laws or regulations prohibiting or discouraging unsolicited mail.</P>

      <P>(c) Partner will not provide any material to DOJOMOJO which
      infringes the intellectual property rights of, or violates any agreement with,
      any third party.</P>

      <H3>4. INTELLECTUAL PROPERTY AND LICENSES</H3>

      <P>4.1 Ownership by DOJOMOJO: Partner acknowledges and agrees
      that as between Partner on the one hand, and DOJOMOJO and its Affiliates on the
      other, DOJOMOJO and its Affiliates own all right, title and interest in the
      DOJOMOJO Platform and the DOJOMOJO's Brand Features. The Partner agrees that
      all intellectual property rights and interests in innovations relating to
      DOJOMOJO Platform and the Services (e.g. or suggested improvements) shall be assigned
      to DOJOMOJO and be DOJOMOJO's sole property, and Partner agrees to execute any
      instruments of assignment reasonably requested by DOJOMOJO to effectuate the
      same.</P>

      <P>4.2 No acquisition of IP rights: Other than as expressly set
      out in the Agreement, neither Party has or will acquire any right, title or
      interest in any intellectual property rights owned or licensed by the other
      Party.</P>

      <P>4.3 Limited license to Partner: DOJOMOJO grants the Partner
      a royalty free, non-exclusive, non-sublicensable license to use the DOJOMOJO
      Software and portal as necessary to enable the Partner to interface with the
      DOJOMOJO Platform and use and enjoy the benefit of the Services. Except for the
      foregoing license, Partner has no other rights in the DOJOMOJO Platform and may
      not modify, edit, copy, reproduce, create derivative works of, reverse engineer,
      alter, enhance or in any way exploit the DOJOMOJO Platform in any manner. Upon
      the expiration or termination of this Agreement, Partner will cease use of the
      DOJOMOJO Brand Features and interfacing with the DOJOMOJO Platform and will
      promptly return all related documentation to DOJOMOJO or destroy all copies
      thereof in its possession or control.</P>

      <P>4.4 Limited license to DOJOMOJO: Partner grants to DOJOMOJO
      a non-exclusive, royalty-free, worldwide, limited license to use the Partner
      Brand Features for display as part of the Services, on DOJOMOJO websites and
      any other marketing or promotional material prepared by DOJOMOJO or its
      Affiliates and to advertise the availability of the Services and its
      integration into the Partner Service. Upon the expiration or termination of
      this Agreement, DOJOMOJO will cease using the Partner Brand Features.</P>

      <H3>5. PRIVACY AND CONFIDENTIALITY</H3>

      <P>5.1 Privacy: DOJOMOJO will ensure that any collection,
      processing, use, disclosure and transfer of Personal Information by DOJOMOJO or
      any of DOJOMOJO's third party suppliers, in connection with the performance of
      DOJOMOJO's obligations under this Agreement, complies with all applicable laws,
      rules and regulations in the primary country of the Partner.</P>

      <P>5.2 Party to Party Disclosure : Where Partner discloses
      Partner Data to DOJOMOJO, or where DOJOMOJO discloses DOJOMOJO Data to Partner,
      the disclosing Party warrants that it is authorized to disclose that
      information in accordance with the disclosing Party's own privacy policy and
      applicable privacy laws. The receiving Party will (i) treat the disclosing
      Party's data as Confidential Information (ii) will only use the disclosing
      Party's data for the purposes of this Agreement and for no other purpose,
      (iii)l treat the information in accordance with its strict information security
      practices and its own Privacy Policy.</P>

      <P>5.4 Confidentiality: Each Party will keep this Agreement and
      the Confidential Information of the other party confidential and use it only
      for the purposes of performing its obligations under this Agreement.</P>

      <P>5.5 Definitio . For the purposes of this Agreement,
      "Confidential Information" means this Agreement, the Partner Data and the
      DOJOMOJO Data, the DOJOMOJO Platform, the Partner Service, all information
      about the disclosing party's business or activities that is proprietary and
      confidential, which shall include all business, financial, technical and other
      information of a party marked or designated by such party as "confidential" or "proprietary"
      at the time of disclosure.</P>

      <P>5.6 Exclusions. Confidential Information will not include
      information that (i) is in or enters the public domain without breach of this
      Agreement, (ii) the receiving party lawfully receives from a third party
      without restriction on disclosure and without breach of a nondisclosure
      obligation, (iii) the receiving party rightfully knew prior to receiving such
      information from the disclosing party or (iv) the receiving party develops independently
      of any information originating from the disclosing party.</P>

      <P>5.7 Restrictions and Disclosure: Each Party agrees (i) that
      it will not disclose to any third party or use any Confidential Information
      disclosed to it by the other except as expressly permitted in this Agreement
      and (ii) that it will take all reasonable measures to maintain the
      confidentiality of all Confidential Information of the other Party in its
      possession or control, which will in no event be less than the measures it uses
      to maintain the confidentiality of its own information of similar importance.
      Notwithstanding the foregoing, each Party may disclose Confidential Information
      (i) to the extent required by a court of competent jurisdiction or other
      governmental authority or otherwise as required by law or (ii) on a
      "need-to-know" basis under an obligation of confidentiality to its legal
      counsel, accountants, banks and other financing sources and their advisors.</P>

      <P>5.8 Injunctive Relief. Each Party acknowledges that any
      material violation by a Party of the rights and obligations provided in this
      clause 5 may result in immediate and irreparable injury to the other party, and
      hereby agrees that the other party may be entitled to seek immediate temporary,
      preliminary, and permanent injunctive relief against any continued violations
      upon adequate proof, as required by applicable law.</P>

      <H3>6. DISCLAIMER</H3>

      <P>6.1 Disclaimers. None of DOJOMOJO, Partner or their
      respective Affiliates makes any representations or warranties, express or
      implied, except for the express representations and warranties in clause 3.
      DOJOMOJO and Partner expressly disclaim any and all other representations and
      warranties, express or implied, regarding the DOJOMOJO Platform or Partner
      Service, including without limitation any implied warranties of
      merchantability, fitness for a particular purpose, non-infringement, or implied
      warranties arising from course of dealing or course of performance.</P>

      <H3>7. INDEMNITIES</H3>

      <P>7.1 Indemnity:</P>

      <P>DOJOMOJO will have no liability for any claim based on (i)
      any use of the DOJOMOJO Platform, DOJOMOJO Software or the Services other than
      as permitted by this Agreement; or (ii) the combination of any DOJOMOJO
      Platform with any product, technology, data or materials, if the DOJOMOJO
      Platform, alone, would not have resulted in such infringement.</P>

      <P>7.2 Indemnity by Partner:</P>

      <P>(a) Partner indemnifies, defends and holds harmless each of
      DOJOMOJO, its Affiliates, its directors, servants and agents both jointly and
      severally from and against any loss, expense, including reasonable attorney
      fees and expenses, damages, or liability reasonably incurred or suffered by
      DOJOMOJO, its directors, servants and agents arising from any claim, demand,
      action, suit or proceedings brought by a third party in relation to any
      Partner-supplied content, data, product or service with which the DOJOMOJO
      Platform integrates, or receives or transmits data from or to, infringes a
      copyright, patent, or misappropriates the proprietary trade secret of any third
      party.</P>

      <P>(b) Partner agrees that it may not, without DOJOMOJO's prior
      written consent, enter into any settlement or compromise of any claim that
      results in any admission of liability or wrongdoing on the part of DOJOMOJO.</P>

      <H3>8. LIMITATION OF LIABILITY</H3>

      <P>8.1 Limitation of liability: Other than with respect to
      liability arising under an indemnity, to the fullest extent permitted by law,
      each Party's liability to the other for all loss or damage suffered or incurred
      by the other Party in connection with this Agreement (including all loss
      arising from any breach of this Agreement, negligence or any breach of statute)
      is limited so that neither party will under any circumstances be liable to the
      other Party for any indirect or consequential loss, or any loss or corruption
      of data or loss of profit. Other than with respect to liability arising under
      an indemnity, any liability arising under this Agreement is limited to the
      amount of the Revenue Share Fee for the preceding one (1) month prior to the
      accrual of the claim. The parties agree that the foregoing represents a fair
      allocation of risk hereunder.</P>

      <P>8.2 Applicability of limitations of liability: The
      limitations and exclusions of liability in this clause 8 will not apply to any
      loss or damage arising from any breach of confidentiality, breach of privacy,
      infringement of Intellectual Property Rights or any injury or death .</P>

      <H3>9. DEFINITIONS</H3>

      <P>In this Agreement, the following words and phrases have the
      following meaning, unless expressly agreed otherwise:</P>

      <P>"Affiliate means any entity that directly or indirectly
      controls, is controlled by, or is under common control with such party (i.e.
      having &gt;50% ownership or the right to direct the management of the entity).</P>

      <P>"Brand Features" means all trademarks, service marks, logos,
      other relevant intellectual property and other distinctive brand features of
      DOJOMOJO and its Affiliates, or Partner and its Affiliates, respectively.</P>

      <P>"Creative Rotation" means ensuring the User is presented the
      most relevant and engaging content including selection of offer creative or engagement
      assets, e.g. ensuring that the same User does not see the same Promotional
      Content twice;</P>

      <P>"Derived Data" means all datasets, analyses, and data that
      is captured or derived from interactions of Users with the DOJOMOJO Software,
      which may be used by DOJOMOJO for the purposes of Creative Rotation, improving
      the user experience or performance of the DOJOMOJO Software and DOJOMOJO
      Platform, or otherwise;</P>

      <P>"DOJOMOJO Ad Policies" means the policies with which
      Promoters are directed to comply by DOJOMOJO including any future policies as
      adopted by DOJOMOJO from time to time in its absolute discretion;</P>

      <P>"DOJOMOJO Data" means any data regarding Users and User
      transactions held by DOJOMOJO prior to the effectiveness of this Agreement or
      acquired by DOJOMOJO during the Term, other than any such data passed to
      DOJOMOJO by Partner;</P>

      <P>"DOJOMOJO Platform" means the platform used to provide the
      Services under this Agreement, incorporating a the DOJOMOJO Software,
      Promotional platform, transaction marketing engine, DOJOMOJO Website, partner
      portal and all software, scripts, data, files, methods, or functionality
      therein and thereto enabling the provision of such Services.;</P>

      <P>"DOJOMOJO Privacy Policy" means DOJOMOJO's privacy policy as
      modified from time to time by DOJOMOJO in its sole discretion.</P>

      <P>"DOJOMOJO Software" means the DOJOMOJO programs used to
      deliver the Services to the Partner, and transfers data from the Partner Pages
      to the DOJOMOJO Platform and vice versa.</P>

      <P>"Insolvency Event" means where either entity enters
      into bankruptcy, liquidation, administration, receivership, a composition or
      arrangement with its creditors, has a receiver or manager appointed over all or
      any part of its assets or becomes or is deemed to become insolvent;</P>

      <P>"Intellectual Property Rights" means all present or future
      intellectual property rights of whatever nature anywhere in the world
      including, but not limited to, rights in respect of or in connection with
      copyright, inventions (including patents), trademarks, service marks, trade
      names, domain names, designs, confidential information, trade secrets and
      know-how and similar industrial, commercial and intellectual property rights,
      whether or not registered or able to be registered, and includes the right to
      apply for the registration of such rights, anywhere in the world;</P>

      <P>"Link" means a hypertext text and/or graphic link from one
      Internet page or site to another Internet page or site.</P>

      <P>"Marketplace Services" means the service of DOJOMOJO
      connecting Promoters to Partners by publishing Promotional Content to Partner
      Content Areas and of connecting consenting Users to Promoters by way of
      referral, which may be optimized by DOJOMOJO by means of using consumer offers
      engagement assets (such as Sweepstakes and Partner offers) to increase overall engagement
      and performance.</P>

      <P>"Net Receipts" means (i) monies actually received from
      Promoters by DOJOMOJO or its associated entities in cleared funds in respect of
      Promoter Booking Charges, less (ii) any applicable Commissions, Engagement
      Asset costs, taxes and any other costs that are jointly agreed. Prizes provided
      directly by Partner as part of any engagement assets are excluded from the
      calculation of Net Receipts;</P>

      <P>"Network Members" means those entities and associations who
      have executed DOJOMOJO Partner Network Membership Agreements (or documents of similar
      content) and receive the individual and/or collective benefits therefrom. For
      the avoidance of doubt, Partner is a Network Member.</P>

      <P>"Partner Content Areas" means the digital properties
      controlled by Partner and made available for Promotional Content through
      Marketplace Services, which may include user's engagement, purchase,
      post-purchase, enquiry, post-enquiry registration, or post-registration areas
      of the Partner Pages.</P>

      <P>"Partner Data" means any data regarding Users and User
      transactions held by Partner prior to the effectiveness of this Agreement or
      acquired by Partner during the Term, other than any such data passed to Partner
      by DOJOMOJO;</P>

      <P>"Partner Service" means the services provided by the Partner
      its Partner Pages, and all software, scripts, data, files, methods, or functionality
      used to provide the Partner Service.</P>

      <P>"Partner Pages" means the landing pages, websites, widgets, mobile
      applications, and other digital properties on the DOJOMOJO Platform or the
      digital properties that the Partner owns or controls that will incorporate or
      feature the DOJOMOJO Software;</P>

      <P>"Personal Information" means information about an individual
      whose identity is apparent from the information, regardless of whether such information
      is provided by the Partner or a third party, or generated by DOJOMOJO;</P>

      <P>"Promoter Booking Charges" means the charges that DOJOMOJO
      will charge to a Promoter for serving its Promotional Content into the Partner
      Pages via the DOJOMOJO Software;</P>

      <P>"Promoter" means any entity whose Promotional Content is served
      via the DOJOMOJO Platform into the Partner Content Areas and other digital
      properties or assets owned or controlled by other Network Members;</P>

      <P>"Promotional Content" means information, data or other
      content sourced via the Marketplace Services that comprises Promotional
      material intended for serving into the Partner Conte</P>

      <P>"Service Schedule" means the list of Services being utilized
      by Partner</P>

      <P>"Services" means the specific Transaction Marketing Services
      provided in connection with this Agreement;</P>

      <P>"Sweepstakes" means a sweepstake, lucky draw, contest,
      competition, other game of chance, or mixed game of chance and skill;</P>

      <P>"TMS Fees" means any fees payable by Partner to DOJOMOJO for
      the provision and use of Transaction Marketing Services, as may be agreed by
      the Parties and as set forth.</P>

      <P>"Transaction Marketing Services" or "TMS" means any tools
      and services (including 'software as a service') that support marketing to
      Users and optimization on the Partner Pages. Transaction Marketing Services
      includes but is not limited to: purchase path optimization, calendaring integration,
      market research, customer feedback, social amplification, data enrichment,
      internal cross-sell, coupon/voucher distribution, sweepstake entries and
      surveys &amp; polls, and associated CRM and email nurture services. TMS
      includes any service that connects consumers through phone, email, or traffic
      referral, and forms of marketing that involve obtaining explicit consent from
      Users. For the avoidance of doubt TMS includes Marketplace Services but does
      not include display, content recommendation, video or search-based Marketing
      Content.</P>

      <P>"User" means a customer of or a visitor to the Partner Pages.</P>

      <H3>10. GENERAL TERMS</H3>

      <P>10.1 Notices : Any notice required or permitted hereunder
      shall be in writing and shall be given by:</P>

      <P>(a) registered or certified mail, return receipt requested,
      postage prepaid;</P>

      <P>(b) courier;</P>

      <P>(c) a confirmed facsimile; or</P>

      <P>(d) a confirmed email.</P>

      <P>10.2 Governing law and jurisdiction : This Agreement is
      governed by and construed in accordance with the laws of the State of Delaware,
      United States of America, without having regard to any conflicts of laws
      provisions, and the Parties to this Agreement submit to the jurisdiction of the
      Delaware courts.</P>

      <P>10.3 Severability : If any part, term or provision of the
      Agreement shall be held illegal, unenforceable, or in conflict with any law,
      the validity of the remaining portions shall not be affected thereby and each
      and every term shall be valid and enforceable to the fullest extent and in the broadest
      application permitted by law.</P>

      <P>10.4 Amendments: The Agreement may be amended only by a
      written agreement signed by authorized representatives of both parties. For the
      avoidance of doubt the Partner may update the list of Excluded Promoters at any
      time.</P>

      <P>10.5 Independent contractors. The parties are dealing with
      each other as independent contractors. The Agreement should not be construed as
      creating or constituting an employee-employer relationship, a partnership, a
      joint venture, a franchise, or an agency between Partner and DOJOMOJO.</P>

      <P>10.6 Binding authority : The Partner warrants to DOJOMOJO
      that the person executing the Agreement has authority to execute this Agreement
      on behalf of the Partner and bind the Partner to the terms and conditions of
      this Agreement.</P>

      <P>10.7 Counterparts : The Agreement may be executed in
      counterparts, each of which will be deemed an original, but all of which
      together will constitute one and the same instrument. </P>

      <P>10.8 Entire agreement: This Agreement contains the entire
      understanding between the Parties concerning its subject matter, and supersedes
      all prior understandings, communications or agreements between the Parties.</P>

      <P>10.9 Interpretation: In this Agreement and for all purposes:</P>

      <P>(a) headings and underlining are for convenience only and do
      not affect the construction of this Agreement;</P>

      <P>(b) a provision of this Agreement will not be interpreted
      against a party because the party prepared or was responsible for the
      preparation of the provision, or because the party's legal representative
      prepared the provision;</P>

      <P>(c) any reference to a currency or a dollar amount is to
      United States dollars, and all invoices, bids, reports, fees and other amounts
      shall be denominated in US Dollars, including any transactions that may
      originally be denominated in another currency, such transactions being
      converted by DOJOMOJO using a foreign exchange rate that reasonably
      approximates DOJOMOJO's real cost to convert to US Dollars, whether or not such
      conversion actually happens;</P>

      <P>(d) a reference to a statute or regulation includes
      amendments thereto;</P>

      <P>(e) a reference to a section, clause, subclause or paragraph
      is a reference to a clause, subclause or paragraph of this Agreement;</P>

      <P>(f) a reference to a subclause or paragraph is a reference
      to the subclause or paragraph in the clause in which the reference is made;</P>

      <P>(g) a reference to a person includes a reference to an
      individual, a partnership, a company, a joint venture, government body,
      government department, and any other legal entity; and</P>

      <P>(h) the words "includes", "including" and similar
      expressions are not words of limitation.</P>
    </div>
  </div>


export default CTAAdvertiserTerms
