import React from 'react'
import BrandRecommendationIngestion from './brand-recommendation-ingestion'

class DataAdminContainer extends React.Component {
  render() {
    return (
      <div className="container" style={{ marginTop: '50px', marginBottom: '50px' }}>
        <BrandRecommendationIngestion />
      </div>
    )
  }
}

export default DataAdminContainer
