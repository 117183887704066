
const model = 'conversations';
import moment from 'moment-timezone';
import _ from 'lodash'

import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import {convertFromHTML, ContentState} from 'draft-js';
import getHostName from 'util/get-host-name'

let initialState = {
  rid: model,
  conversations: [],
  loaded: false,
  popoutMessageBrandConvoId: null,
  popoutMessageBrandId: null,
  popoutMessageBrandName: null,
  popoutMessageOpportunityId: null,
  modalBrandApplicationCampaign: {},
  modalBrandApplicationContent: null,
  modalBrandInviteCampaign: {},
  modalBrandInviteContent: null,
  modalBrandInviteMinDelivery: 0,
  loading: false,
  drafts: {},
  showNewMessageModal: false,
  hasSent: false,
  sentApplications: [],
  userBrandsUnreads: [],
  reinviteType: null,
};

const generateConversationNames = (messages, currentUserId) => {
  let names = {}
  let ids = _.map(messages, message => {
    if (message.sender_id == currentUserId) {
      names[message.sender_id] = {
        firstName: 'me',
        lastName: ''
      }
    } else {
      names[message.sender_id] = {
        firstName: message.firstName,
        lastName: message.lastName
      }
    }
    return message.sender_id
  })
  let uniqIds = _.uniq(ids);
  if(uniqIds.length == 1) {
    return `${names[uniqIds[0]].firstName} ${names[uniqIds[0]].lastName}`
  } else if (uniqIds.length <= 3) {
    return _.map(uniqIds, (id)=> {
      return names[id].firstName
    }).join(', ')
  } else {
    return '...'+_.map(uniqIds.slice(0,3), (id)=> {
      return names[id].firstName
    }).join(', ')
  }
}

const enhanceMessageObject = (message, currentUserId, currentBrandId) => {
  if (message.sender_id == currentUserId) {
    message.firstName = 'me';
    message.lastName = '';
  }

  if (message.partnershipinvite_id) {
    if (message.type === 'campaignAnnouncement') {
      message.messageType = 'normal'
      message.heading = `Campaign Announcement: ${message.campaign_name}`
      message.displayBrandName = message.sender_brand_name
      message.displayBrandLogo = message.sender_brand_logo
    } else if (!message.partnership_action) {
      // Partner(Invitee) viewing split traffic invite
      if (message.type === 'splitTrafficInvite' && message.invitee_id == currentBrandId) {
        if (message.split_traffic_invite_status === 'invited') {
          message.responses = ['acceptSplitTraffic', 'declineSplitTraffic']
        }
      // campaign host viewing split traffic invite or host viewing a request from a partner
      } else if (['splitTrafficInvite', 'splitTrafficRequest'].includes(message.type) && message.hostbrand_id == currentBrandId) {
        message.responses = ['viewSplitTraffic']
      //Invite AND currentBrand = partner
      } else if (message.application == false && message.invitee_id == currentBrandId) {
        message.messageType = 'receivedInvitation'
        message.responses = ['interested', 'decline', 'viewCampaign']
        message.highlight = message.status === 'pending';
        message.displayBrandName = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name;
        message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
        message.heading = `Invitation: ${message.campaign_name}`;

        //Application and currentBrand = Host
      } else if (message.application == true && message.invitee_id != currentBrandId) {
        message.messageType = 'receivedApplication';
        message.responses = ['interested', 'decline']

        if (message.status == 'interested') {
          message.responses.push('createAgreement')
        }

        message.highlight = message.status == 'pending';
        message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
        message.displayBrandLogo = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo : message.invitee_brand_logo;
        message.heading = `Application: ${message.campaign_name}`;

        //Invitation and currentBrand = Host
      } else if (message.application == false && message.invitee_id != currentBrandId) {
        message.messageType = 'sentInvitation';
        message.responses =  ['viewCampaign'];
        message.displayBrandName = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name;
        message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
        message.heading = `Invitation: ${message.campaign_name}`;

        // Application and currentBrand = Partner
      } else if (message.application == true && message.invitee_id == currentBrandId) {
        message.messageType = 'sentApplication';
        message.responses = ['viewCampaign'];
        message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
        message.displayBrandLogo = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo : message.invitee_brand_logo;
        message.heading = `Application: ${message.campaign_name}`;
      }
    } else {
      if (message.partnership_action === 'host_shared_agreement' && message.invitee_id == currentBrandId) {
        message.messageType = 'agreementAction'
        message.responses = ['viewAgreement']
        message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
        message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
        message.heading = `Action Required: Partnership Agreement for ${message.campaign_name}`
      } else if (message.partnership_action === 'partner_signed' && message.invitee_id !== currentBrandId ) {
        message.messageType = 'agreementAction'
        message.responses = ['signAgreement']
        message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
        message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
        message.heading = `${message.invitee_brand_name} Has Signed Your Partnership Agreement for ${message.campaign_name}`
      } else if (message.partnership_action === 'partner_confirmed' && message.invitee_id === currentBrandId) {
        message.messageType = 'agreementAction'
        message.responses = ['viewAgreement', 'viewCampaign']
        message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
        message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
        message.heading = `You Are a Confirmed Partner on ${message.campaign_name}`
      } else if (message.partnership_action === 'partner_indicate_interested' && message.invitee_id !== currentBrandId) {
        message.messageType = 'agreementAction'
        message.responses = ['createAgreement']
        message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
        message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
        message.heading = `${message.invitee_brand_name} is interested in ${message.campaign_name}`
      } else if (message.partnership_action === 'splitTraffic') {
        if (message.type === 'invite') {
          // TODO: Revisit this part, do proper logic to setup message
          message.messageType = 'shareTraffic'
          message.responses = ['acceptTraffic', 'declineTraffic']
          message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
          message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
          message.heading = `Invite: [${message.host_brand_name}] would like to split traffic`
        } else if (message.type === 'request') {
          // TODO: Revisit this part, do proper logic to setup message
          message.messageType = 'setupTraffic'
          message.responses = ['setupTraffic']
          message.displayBrandLogo = message.brand_id && message.brand_id == message.invitee_id ? message.invitee_brand_logo : message.host_brand_logo;
          message.displayBrandName = message.brand_id ? message.brand_id == message.invitee_id ? message.invitee_brand_name : message.host_brand_name : message.invitee_brand_name;
          message.heading = `Request: ${message.invitee_brand_name} would like to share traffic`
        }
      } else {
        message.messageType = 'normal'
        message.displayBrandName = message.sender_brand_name;
        message.displayBrandLogo = message.sender_brand_logo;
      }
    }
  } else {
    message.messageType = 'normal'
    message.displayBrandName = message.sender_brand_name;
    message.displayBrandLogo = message.sender_brand_logo;
  }

  if (message.type === 'newBrandWelcome') {
    message.messageType = 'newBrandWelcome'
    message.responses = ['explore']
    message.heading = 'Welcome! Let’s get you up and running to DojoMojo!'
    message.partnership_action = true
  }

  return message;
}

const filterAndReturnMostRecent = messagesArray => {
  const campaignIDCheck = {}
  messagesArray.forEach(messageObj => {
    const storedMessage = campaignIDCheck[messageObj.campaign_id]
    if (!storedMessage) {
      campaignIDCheck[messageObj.campaign_id] = messageObj
    }

    // replace stored messageObj with most recent messageObj
    if (storedMessage && storedMessage.createdAt && moment(storedMessage.createdAt).isBefore(messageObj.createdAt)) {
      campaignIDCheck[messageObj.campaign_id] = messageObj
    }
  })
  return Object.values(campaignIDCheck).sort((a, b) => {
    return b.campaign_id - a.campaign_id
  })
}


const MessagingReducer = (state, action) => {
  // TODO: Whitelist actions that update multiple reducers
  if (action.model !== 'conversations' && action.type !== 'SUBMIT_APPLICATION_SUCCESS' && action.type !== 'SHARE_AGREEMENT_SUCCESS' && action.type !== 'SIGN_AGREEMENT_SUCCESS' && action.type !== 'COUNTER_SIGN_AGREEMENT_SUCCESS') {
    return state || initialState;
  }

  switch (action.type) {
    //Initial Load of all conversations for particular brand
    case 'LOAD_CONVERSATIONS': {
      if (!action.data) return { ...state }

      let messages = action.data;
      let currentBrandId = store.getState().currentBrand.id
      let unread = 0;
      let currentUserId = store.getState().profile.id
      let pluckFields = [
        'conversation_brand_name',
        'conversation_brand_id',
        'conversation_brand_logo',
        'conversation_id',
        'conversation_archive'
      ]

      var hasSent = false;

      _.each(messages, (message) => {
        if (currentUserId === message.sender_id) {
          hasSent = true;
        }
        message = enhanceMessageObject(message, currentUserId, currentBrandId)
      })


      let data = {};
      var messageHash = {};
      _.each(messages, (row)=> {
        messageHash[row.id] = row;
        let conversationId = row.conversation_id;
        if(data[conversationId]) {
          data[conversationId].messages.push(row);
        } else {
          data[conversationId] = _.extend({}, _.pick(row, pluckFields),
            {
              messages: [row],
              last_message_at: row.createdAt,
              read: new Date(row.createdAt) < new Date(row.last_read)
            }
          )
          if(new Date(row.createdAt) >= new Date(row.last_read) && row.conversation_archive == false) {
            unread++
          }
        }
      })

      let receivedInvitations = _.filter(messages, { messageType: 'receivedInvitation', partnership_action: null })
      const filteredReceivedInvitations = filterAndReturnMostRecent(receivedInvitations)


      let receivedApplications = _.filter(messages, { messageType: 'receivedApplication', partnership_action: null });
      let sentInvitations = _.filter(messages, { messageType: 'sentInvitation', partnership_action: null });
      let sentApplications = _.filter(messages, { messageType: 'sentApplication', partnership_action: null });

      _.each(data, (conversation) => {
        conversation.fromNames = generateConversationNames(conversation.messages, currentUserId)
      })

      return _.extend({}, state, {
        conversations: _.sortBy(data, 'last_message_at').reverse(),
        unread: unread,
        messageHash: messageHash,
        receivedInvitations: filteredReceivedInvitations,
        receivedApplications: receivedApplications,
        sentInvitations: sentInvitations,
        sentApplications: sentApplications,
        hasSent: hasSent
      })

      break;
    }
    //Updating a specific conversation object

    case 'UPDATE_CONVERSATION': {
      /*
      REQUIRED FIELDS: conversationId, updatedObj
      SAMPLE OBJECT:
        data: {
          conversationId: 3456
          updatedObj: {
            read: true
          }
        }
      */
      let { conversationId, updatedObj } = action.data
      var conversations = state.conversations.slice()
      var conversation = _.find(conversations, {conversation_id: conversationId});
      var idx = _.findIndex(conversations, {conversation_id: conversationId});
      conversations[idx] = _.extend({}, conversation, updatedObj);
      let unread = _.filter(conversations, {read: false, conversation_archive: false}).length

      return _.extend({}, state, {
        conversations: conversations,
        unread: unread
      })

    }

    //Updating a specific message object
    case 'UPDATE_MESSAGE_SUCCESS': {
      /*
      REQUIRED FIELDS: messageId, conversationId
      SAMPLE OBJECT:
        data: {
          messageId: 1212
          messageType: 'receivedInvitations'
          conversationId: 3456
          updatedObj: {
            status: 'accepted'
          }
        }
      */
     let { messageId, messageType, updatedObj } = action.data
     var conversationId = action.data.conversationId || state.messageHash[messageId].conversation_id;
     let conversations = state.conversations.slice()
     let conversation = _.find(conversations, {conversation_id: conversationId})
     let conversationIdx = _.findIndex(conversations, {conversation_id: conversationId})

     let message = _.find(conversation.messages, {id: messageId })
     let messageIdx = _.findIndex(conversation.messages, {id: messageId })
     let updatedMessage = _.extend({}, message, updatedObj);

     let currentUserId = store.getState().profile.id
     let currentBrandId = store.getState().currentBrand.id

     updatedMessage = enhanceMessageObject(updatedMessage, currentUserId, currentBrandId);
     conversation.messages[messageIdx] = updatedMessage;

     conversations[conversationIdx] = conversation;



     let newInviteMessages = state[messageType].slice()
     let inviteIdx = _.findIndex(newInviteMessages, {id: messageId})
     newInviteMessages[inviteIdx] = updatedMessage;

     return _.extend({}, state, {
       conversations: conversations,
       [messageType]: newInviteMessages
     })
    }

    case 'MARK_PARTNERSHIP_INTEREST_SUCCESS': {
      let { messageId, messageType, updatedObj } = action.data

      let currentBrand = store.getState().currentBrand
      let currentUser = store.getState().profile
      var conversationId = action.data.conversationId || state.messageHash[messageId].conversation_id;
      let conversations = state.conversations.slice()
      let conversation = _.find(conversations, {conversation_id: conversationId})
      let conversationIdx = _.findIndex(conversations, {conversation_id: conversationId})

      let message = _.find(conversation.messages, {id: messageId })
      let messageIdx = _.findIndex(conversation.messages, {id: messageId })
      let updatedMessage = _.extend({}, message, updatedObj);

      updatedMessage = enhanceMessageObject(updatedMessage, currentUser.id, currentBrand.id);
      conversation.messages[messageIdx] = updatedMessage;

      conversations[conversationIdx] = conversation;

      //if this is a received application (meaning you are host), then no message required
      if (action.payload && messageType !== 'receivedApplications') {
        let newMessage = _.extend({}, action.payload, {
          displayBrandLogo: currentBrand.logo,
          displayBrandName: currentBrand.accountname,
          firstName: 'me',
          lastName: '',
          messageType: 'normal',
          sender_brand_logo: currentBrand.logo,
          sender_brand_name:currentBrand.accountname
        })

        conversations[conversationIdx].messages.unshift(newMessage)
      }

      let newInviteMessages = state[messageType].slice()
      let inviteIdx = _.findIndex(newInviteMessages, {id: messageId})
      newInviteMessages[inviteIdx] = updatedMessage;

      return _.extend({}, state, {
        conversations: conversations,
        [messageType]: newInviteMessages
      })

      break;
    }

    case 'MARK_INTERESTED_RECIEVED_SUCCESS': {
      let { invite_id } = action.data
      let messageType = `${action.data.messageType}s`

      let partnership = state[messageType].find(c => c.partnershipinvite_id == invite_id)
      let partnershipIdx = _.findIndex(state[messageType], {partnershipinvite_id: invite_id})

      partnership.status = 'interested'

      let partnerships = _.extend({}, state[messageType])
      partnerships[partnershipIdx] = partnership

      return _.extend({}, state, {
        [messageType]: partnerships
      })
      break
    }

    //Loading a new conversation object into store (i.e. when user initiates a new conversation with a new brand)
    case 'LOAD_NEW_CONVERSATION':
      var conversation = _.extend({}, action.data);
      var currentUser = store.getState().profile;
      var currentBrand = store.getState().currentBrand;

      conversation.messages[0] = _.extend({}, conversation.messages[0], {
        displayBrandLogo: currentBrand.logo,
        displayBrandName: currentBrand.accountname,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        messageType: 'normal',
        sender_brand_logo: currentBrand.logo,
        sender_brand_name:currentBrand.accountname
      })
      conversation.fromNames = generateConversationNames(conversation.messages, currentUser.id)
      var newConversations = state.conversations.slice();
      newConversations.unshift(conversation);

      toastr.success('Your message has been sent.', null, { timeOut: 10000, positionClass: 'toast-bottom-center' });

      return _.extend({}, state, {
        conversations: newConversations,
        newConversationBrandId: null,
        newConversationThread: false,
      })

      break;

    case 'UPDATE_ATTR':
      return _.extend({}, state, action.data);
      break;

    case 'OPEN_APPLY_MODAL': {
      let { campaign, currentBrand } = action.data;
      let profile = store.getState().profile;
      let tags = _.map(currentBrand.tagsconnected, tag => tag.name).join(', ')
      let defaultMessage =
      `
        <p>Hey <strong>${campaign.hostBrandName}</strong>,</br></p>
        <p>We're <strong>${currentBrand.accountname}</strong> and we would love to join your upcoming campaign, <strong>${campaign.name}</strong>. Below are some details about our brand and what you can expect from our participation.</p><ul style="list-style-type:none"><li>Industry: <strong>${tags}</strong></li>
          <li>Email List Size: <strong>${  d3.format(',')(currentBrand.dedicatedListSize) || 0}</strong></li>
          <li>Social Reach: <strong>${  d3.format(',')(currentBrand.socialMediaImpressions) || 0}</strong></li>
          <li>${currentBrand.averageSignUpsPerCampaign ? 'Average' : 'Estimated'} Entries Per Campaign: <strong>${ d3.format(',.0f')((currentBrand.averageSignUpsPerCampaign ? currentBrand.averageSignUpsPerCampaign : currentBrand.dedicatedListSize * 0.01) || 0) }</strong></li>
        </ul>
        <p>Let us know what we can do to help make this campaign a success. We're looking forward to hearing from you!</p>
        <p>Best,<br>${profile.displayName} at ${currentBrand.accountname}</p>
      `

      return _.extend({}, state, {
        modalBrandApplicationCampaign: campaign,
        modalBrandApplicationContent: defaultMessage,
      })
      break;
    }


    case 'OPEN_INVITE_MODAL': {
      let { campaign } = action.data;
      let { profile, currentBrand, brand } = store.getState()
      let defaultMessage =
      `
        <p>Hi ${brand.accountname},</br></p>
        <p>${profile.displayName} from <strong><a href="${getHostName()}/app/explore/brands/${currentBrand.id}" target="_blank">${currentBrand.accountname}</a></strong> has invited you to join their upcoming giveaway as a brand partner!</br></p>

        <p><strong><a href='${getHostName()}/app/explore/campaigns/${campaign.id}/details'>${campaign.name}</a></strong> is scheduled to run from ${moment(campaign.startDate).format('LL')} to ${moment(campaign.endDate).format('LL')}.</br></p>

        <p>Here are some details for the campaign:</p><ul style="list-style-type:none"><li><strong>Confirmed Partners:</strong> ${campaign.numberOfPartners}${campaign.numberOfPartners <= 2 ? ' (speaking to a few more)' : ''}</li>
          ${campaign.totalImpressions >= 1000 && campaign.estimatedEntries >= 1000
              ? `<li><strong>Estimated Impressions:</strong> ${d3.format(',')(campaign.totalImpressions)}</li>`
              : ''
          }
          ${campaign.estimatedEntries >= 1000
              ? `<li><strong>Estimated Entries:</strong> ${  d3.format(',')(campaign.estimatedEntries) || 0}</li>`
              : ''
          }
          <li><strong>Prize:</strong> ${campaign.prizingDescription || 'N/A'}</li>
          <li><strong>Estimated Prizing Value:</strong> ${d3.format('$')(campaign.prizingValue)}</li>
          <li><strong>Start Date:</strong> ${moment(campaign.startDate).format('LL')}</li>
        </ul>
      `

      return _.extend({}, state, {
        modalBrandInviteCampaign: campaign,
        modalBrandInviteContent: defaultMessage,
      })
      break;
    }


    case 'OPEN_REINVITE_MESSENGER_WINDOW': {
      const { campaign, currentBrand, inviteeBrand, profile, reinviteType } = action.data
      const defaultMessage =
      `
        <p>Hi ${inviteeBrand.accountname},</br></p>
        <p>${profile.displayName} from <strong><a href="${getHostName()}/app/explore/brands/${currentBrand.id}" target="_blank">${currentBrand.accountname}</a></strong> has invited you to join their upcoming giveaway as a brand partner!</br></p>

        <p><strong><a href='${getHostName()}/app/explore/campaigns/${campaign.id}/details'>${campaign.name}</a></strong> is scheduled to run from ${moment(campaign.startDate).format('LL')} to ${moment(campaign.endDate).format('LL')}.</br></p>

        <p>Here are some details for the campaign:</p><ul style="list-style-type:none"><li><strong>Confirmed Partners:</strong> ${campaign.numberOfPartners}</li>
          <li><strong>Estimated Impressions:</strong> ${d3.format(',')(campaign.totalImpressions)}</li>
          <li><strong>Estimated Entries:</strong> ${  d3.format(',')(campaign.estimatedEntries) || 0}</li>
          <li><strong>Prize:</strong> ${campaign.prizingDescription || 'N/A'}</li>
          <li><strong>Estimated Prizing Value:</strong> ${d3.format('$')(campaign.prizingValue)}</li>
          <li><strong>Start Date:</strong> ${moment(campaign.startDate).format('LL')}</li>
        </ul>
      `

      return _.extend({}, state, {
        modalBrandInviteCampaign: campaign,
        modalBrandInviteContent: defaultMessage,
        reinviteType,
      })
    }



    //Load new message for an existing conversation in store
    case 'LOAD_NEW_MESSAGE': {
      let currentBrand = store.getState().currentBrand
      let currentUser = store.getState().profile

      let newMessage = _.extend({}, action.message, {
        displayBrandLogo: currentBrand.logo,
        displayBrandName: currentBrand.accountname,
        firstName: 'me',
        lastName: '',
        messageType: 'normal',
        sender_brand_logo: currentBrand.logo,
        sender_brand_name:currentBrand.accountname
      })

      let updatedConversations = state.conversations.slice();

      var idx = _.findIndex(updatedConversations, {conversation_id: action.id})
      let updatedConvo = updatedConversations.splice(idx, 1)[0];
      updatedConvo = _.extend({}, updatedConvo, {
        last_message_at: new Date().toISOString(),
        read: true
      })

      updatedConvo.messages.unshift(newMessage);
      updatedConvo.fromNames = generateConversationNames(updatedConvo.messages, currentUser.id)

      updatedConversations.unshift(updatedConvo);

      toastr.success('Your message has been sent.', null, { timeOut: 10000, positionClass: 'toast-bottom-center' });
      return _.extend({}, state, {
        conversations: updatedConversations
      })
    }

    //Updating search results
    case 'UPDATE_SEARCH_RESULTS':
      var type = action.data.type;
      if (type == 'modal') {
        return _.extend({}, state, {
          modalSearchResults: action.data.results,
          modalShowSearchResults: true
        })
      } else if (type == 'normal') {
        return _.extend({}, state, {
          searchResults: action.data.results,
          showSearchResults: true
        })
      }

      break;

    //Triggers message to brand inside Messaging Inbox
    case 'MESSAGE_BRAND':
      var { conversationBrandId, conversationBrandName} = action.data
      var conversation = _.find(state.conversations, {conversation_brand_id: conversationBrandId})
      if (conversation) {
        if (conversation.conversation_archive == true) {
          MessagingDispatcher.unarchive(conversation.conversation_id)
        }
        var idx = _.findIndex(state.conversations, {conversation_brand_id: conversationBrandId})
        var updatedConvo = _.extend({}, conversation, {
          conversation_archive: false
        })
        var conversations = state.conversations.slice()
        conversations[idx] = updatedConvo;

        return _.extend({}, state, {
          conversations: conversations,
          activeConversationThread: conversation.conversation_id,
          showSearchResults: false,
          modalShowSearchResults: false,
          showNewMessageModal: false
        })
      } else {
        return _.extend({}, state, {
          newConversationThread: true,
          newConversationBrandId: conversationBrandId,
          newConversationBrandName: conversationBrandName,
          showSearchResults: false,
          modalShowSearchResults: false,
          showNewMessageModal: false
        })
      }

      break;

    //Sets active conversation
    case 'SET_ACTIVE_CONVERSATION':
      var { activeConversationThread } = action.data;
      var conversation = _.find(state.conversations, { conversation_id: activeConversationThread})
      if (conversation) {
        if (conversation.read == false) {
          MessagingDispatcher.flagReadReceipt(conversation.conversation_id);
        }

        if (conversation.conversation_archive) {
          MessagingDispatcher.unarchive(conversation.conversation_id);
        }

        var idx = _.findIndex(state.conversations, { conversation_id: activeConversationThread})
        var updatedConvo = _.extend({}, conversation, {
          conversation_archive: false,
          read: true
        })
        var conversations = state.conversations.slice()
        conversations[idx] = updatedConvo;
        let unread = _.filter(conversations, {read: false, conversation_archive: false}).length
        return _.extend({}, state, {conversations, unread}, action.data)
      } else {
        return _.extend({}, state, action.data )
      }
      break;

    //Triggers message slider for brand message (outside of Messaging section)
    case 'POPOUT_MESSAGE_BRAND':
      var { brandId, brandName, initialContent, title, opportunityId } = action.data
      var conversation = _.find(state.conversations, {conversation_brand_id: brandId})
      if (conversation) {
        var idx = _.findIndex(state.conversations, {conversation_brand_id: brandId})
        var updatedConvo = _.extend({}, conversation, {
          conversation_archive: false
        })
        var conversations = state.conversations.slice()
        conversations[idx] = updatedConvo;

        return _.extend({}, state, {
          conversations: conversations,
          popoutMessageBrandConvoId: conversation.conversation_id,
          popoutMessageBrandName: conversation.conversation_brand_name,
          popoutMessageContent: initialContent,
          popoutMessageBrandId: brandId,
          popoutMesssageTitle: title,
          popoutMessageOpportunityId: opportunityId || null,
        })
      } else {
        return _.extend({}, state, {
          popoutMessageBrandConvoId: 'newBrandConversation',
          popoutMessageBrandId: brandId,
          popoutMessageBrandName: brandName,
          popoutMessageContent: initialContent,
          popoutMesssageTitle: title,
          popoutMessageOpportunityId: opportunityId || null,
        })
      }
      break;

    //Loads message (related to invite/application) into store
    case 'LOAD_INVITE_MESSAGE':
    case 'SUBMIT_APPLICATION_SUCCESS':
      var currentBrand = store.getState().currentBrand
      var currentUser = store.getState().profile
      var conversation = ['LOAD_INVITE_MESSAGE','LOAD_INVITE_SUCCESS'].indexOf(action.type) !== -1 ? action.data : action.payload.conversation;
      var idx = _.findIndex(state.conversations, {conversation_id: conversation.conversation_id});
      var message = conversation.messages[0];
      var newMessage = _.extend({}, message, {
        firstName: 'me',
        lastName: '',
        sender_brand_logo: currentBrand.logo,
        sender_brand_name: currentBrand.accountname,
      })

      // if application then you are the invitee
      if (newMessage.application) {
        newMessage.invitee_brand_logo = currentBrand.logo
        newMessage.invitee_brand_name = currentBrand.accountname
        newMessage.invitee_id = currentBrand.id
      }

      var updatedConversations = state.conversations.slice();

      let sentInviteMessage = _.extend(enhanceMessageObject(newMessage, currentUser.id, currentBrand.id), {
        conversation_brand_logo: conversation.conversation_brand_logo,
        conversation_brand_name: conversation.conversation_brand_name,
        firstName: 'me',
        lastName: '',
        status: 'pending'
      })

      if (idx != -1) {
        var updatedConvo = updatedConversations.splice(idx, 1)[0];
        updatedConvo = _.extend({}, updatedConvo, {
          last_message_at: new Date().toISOString(),
        })
        updatedConvo.messages.unshift(newMessage);
        updatedConvo.fromNames = generateConversationNames(updatedConvo.messages, currentUser.id)
        updatedConversations.unshift(updatedConvo);

      } else {
        conversation.messages[0] = newMessage;
        conversation.fromNames = generateConversationNames(conversation.messages, currentUser.id)
        updatedConversations.unshift(conversation);
      }

      if (sentInviteMessage.messageType == 'sentApplication') {
        return _.extend({}, state, {
          conversations: updatedConversations,
          sentApplications: [sentInviteMessage, ...state.sentApplications]
        })
      } else if (sentInviteMessage.messageType == 'sentInvitation') {
        return _.extend({}, state, {
          conversations: updatedConversations,
          sentInvitations: [sentInviteMessage, ...state.sentInvitations]
        })
      } else {
        return _.extend({}, state, {
          conversations: updatedConversations,
        })
      }

      break;

    case 'MARK_ALL_READ':
      var conversations = state.conversations.slice();
      _.each(conversations, convo => {
        convo.read = true
      })
      return _.extend({}, state, {
        conversations,
        unread: 0
      })
      break;

    case 'SHARE_AGREEMENT_SUCCESS':
      var currentBrand = store.getState().currentBrand
      var currentUser = store.getState().profile

      var newMessage = _.extend({}, action.payload.message, {
        displayBrandLogo: currentBrand.logo,
        displayBrandName: currentBrand.accountname,
        firstName: 'me',
        lastName: '',
        messageType: 'normal',
        sender_brand_logo: currentBrand.logo,
        sender_brand_name:currentBrand.accountname
      })

      var updatedConversations = state.conversations.slice();

      var idx = _.findIndex(updatedConversations, {conversation_id: action.payload.message.conversation_id})
      var updatedConvo = updatedConversations.splice(idx, 1)[0];
      updatedConvo = _.extend({}, updatedConvo, {
        last_message_at: new Date().toISOString(),
        read: true
      })

      updatedConvo.messages.unshift(newMessage);
      updatedConvo.fromNames = generateConversationNames(updatedConvo.messages, currentUser.id)

      updatedConversations.unshift(updatedConvo);

      return _.extend({}, state, {
        conversations: updatedConversations
      })

      break;


    //TO DO: refactor SIGN_AGREEMENT_SUCCESS and SHARE_AGREEMENT_SUCCESS
    case 'COUNTER_SIGN_AGREEMENT_SUCCESS':
    case 'SIGN_AGREEMENT_SUCCESS':
      var currentBrand = store.getState().currentBrand
      var currentUser = store.getState().profile

      var newMessage = _.extend({}, action.payload.message, {
        displayBrandLogo: currentBrand.logo,
        displayBrandName: currentBrand.accountname,
        firstName: 'me',
        lastName: '',
        messageType: 'normal',
        sender_brand_logo: currentBrand.logo,
        sender_brand_name:currentBrand.accountname
      })

      var updatedConversations = state.conversations.slice();

      var idx = _.findIndex(updatedConversations, {conversation_id: action.payload.message.conversation_id})
      var updatedConvo = updatedConversations.splice(idx, 1)[0];
      updatedConvo = _.extend({}, updatedConvo, {
        last_message_at: new Date().toISOString(),
        read: true
      })

      updatedConvo.messages.unshift(newMessage);
      updatedConvo.fromNames = generateConversationNames(updatedConvo.messages, currentUser.id)

      updatedConversations.unshift(updatedConvo);

      return _.extend({}, state, {
        conversations: updatedConversations
      })

      break;

    default:
      return state;
  }
};

export default MessagingReducer;
