import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import MessageThread from './messaging-thread';
import MessageRowItemContainer from './messaging-row-item-container';

var threadHeadingMap = {

}

class ConversationListItem extends React.Component {
  constructor(props) {
    super(props)

    this.togglePreview = this.togglePreview.bind(this);
    this.archive = this.archive.bind(this);
  }

  togglePreview() {
    this.props.onclick(this.props.thread.conversation_id);
  }

  archive() {
    this.props.archive(this.props.thread.conversation_id);
  }

  render() {
    let { thread, showQuickReply } = this.props
    var message = thread.messages[0];
    let textOnly = message.content && message.content.replace(/<[^>]+>/g, '');

    if (this.props.preview) {
      return (
        <MessageRowItemContainer
          logo = {thread.conversation_brand_logo}
          brandName = {thread.conversation_brand_name}
          brandId = {message.conversation_brand_id}
          userName = {thread.fromNames}
          heading = { message.heading }
          text = { textOnly }
          timestamp = { thread.last_message_at }
          clickFunction = { this.togglePreview }
          highlight = { !thread.read }
          status = { message.partnership_action ? null : message.status }
          archiveOption = {true}
          archiveFunction = {this.archive}
        />

      )
    } else {
      return (
        <MessageThread
          messages = { thread.messages }
          collapse = { this.togglePreview }
          conversationId = { thread.conversation_id}
          showQuickReply = { showQuickReply }
          conversationLastMessage = { thread.last_message_at }
          />
      )
    }

  }

}

export default ConversationListItem
