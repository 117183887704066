import React, { PureComponent as Component } from 'react'
import { H4 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

class GraphEmptyState extends Component {
  render() {
    const {
      emptyStateMessage,
      onClick,
      buttonLabel,
      height,
      width,
      marginTop,
    } = this.props

    return (
      <div
        style={{
          height,
          width,
          marginTop,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '16px' }}>
            <H4 cloudGrey multiline>
              <i>{emptyStateMessage}</i>
            </H4>
          </div>
          {
            buttonLabel
              ? (
                <Button
                  onClick={onClick}
                  label={buttonLabel}
                  className={ButtonTheme.blueButton}
                  theme={ButtonTheme}
                  primary
                  raised
                />
              ) : null
          }
        </div>
      </div>
    )
  }
}

export default GraphEmptyState
