import React, { Component } from 'react'
import AssetsTheme from 'css/themes/media-market/Assets.css'
import {
  array, number, func, object,
} from 'prop-types'
import classNames from 'classnames'
import FontIcon from 'react-toolbox/lib/font_icon'
import { memoize } from 'lodash'

class ImageGallery extends Component {
  static propTypes = {
    images: array.isRequired,
    selectedImageId: number.isRequired,
    onImageSelect: func.isRequired,
    unreadCount: object.isRequired,
  }

  onImageSelect = memoize(imageId => () => {
    const { onImageSelect } = this.props
    onImageSelect(imageId)
  })

  getUnreadCount = id => {
    const { unreadCount } = this.props
    const unreadAssetCounts = unreadCount && unreadCount.attachmentComments
      ? unreadCount.attachmentComments
      : {}
    return unreadAssetCounts[id]
  }

  renderNotification = id => {
    if (!this.getUnreadCount(id)) return null
    return (
      <div className={AssetsTheme.unreadImageBadge}>
        <FontIcon value="lens" />
      </div>
    )
  }

  render() {
    const {
      images,
      selectedImageId,
    } = this.props
    return (
      <div className={AssetsTheme.galleryContainer}>
        <div className={AssetsTheme.gallery}>
          { images && images.length
            ? images.map(item => {
              const imgSrc = item.s3_url.startsWith('//') ? `https:${item.s3_url}` : item.s3_url
              const styles = [AssetsTheme.galleryImage]
              const selected = selectedImageId === item.id
              const borderColor = selected ? '#03a9f4' : '#ffffff'
              return (
                <div
                  key={item.id}
                  className={classNames(styles)}
                  style={{ backgroundImage: `url(${imgSrc})`, border: `2px solid ${borderColor}` }}
                  onClick={this.onImageSelect(item.id)}
                >
                  { item.is_unread && this.renderNotification(item.id) }
                  <div className={item.hasNotification ? AssetsTheme.hasNotification : null} />
                  <div
                    className={classNames({
                      [AssetsTheme.overlay]: true,
                      [AssetsTheme.overlaySelected]: selected,
                    })}
                  />
                </div>
              )
            }) : null
          }
        </div>
      </div>
    )
  }
}

export default ImageGallery
