export const loading = () => ({ type: 'LOADING_INTEGRATIONS' })

export const update = (data, value) => {
  let formData = data
  if (typeof data === 'string') {
    formData = {
      [data]: value,
    }
  } else if (data.rid) {
    formData = {}
  }

  return {
    model: 'dataIntegrations',
    type: 'UPDATE_DATA_INTEGRATIONS_ATTR',
    data: formData,
  }
}

export const load = () => ({
  http: {
    url: '/data-integrations',
  },
  types: [
    'LOADING_INTEGRATIONS',
    'LOAD_INTEGRATIONS_SUCCESS',
    'LOAD_INTEGRATIONS_FAILURE',
  ],
})

export const disconnect = type => ({
  http: {
    url: `/data-integrations/${type}`,
    method: 'DELETE',
  },
  types: [
    'DISCONNECTING_INTEGRATION',
    'DISCONNECTING_INTEGRATION_SUCCESS',
    'DISCONNECTING_INTEGRATION_FAILURE',
  ],
})

export const closePixelConfirmation = isFailedConfirm => ({
  type: 'CLEAR_PIXEL',
  data: { isFailedConfirm },
})

export const testPixel = data => ({
  http: {
    url: `/tctesting/${data.brandId}`,
    data,
    method: 'POST'
  },
  types: [
    'TESTING_PIXEL',
    'TESTING_PIXEL_SUCCESS',
    'TESTING_PIXEL_FAILURE',
  ],
})

export const closeSuggestionDialog = () => ({
  type: 'CLOSE_INTEGRATION_SUGGESTION_DIALOG',
})
