import React, { Component } from 'react';
import cn from 'classnames';
import theme from 'css/themes/homePage/resources.css';
import { Link } from 'react-router-dom';

class ResourcesTabPicker extends Component {
  render() {
    let { page } = this.props;

    return (
      <div className={theme.tabContainer}>
        <div className={theme.innerContainer}>
          <Link to={'/resources/all'}>
            <div
              className={cn(theme.tab, { [theme.selected]: page === 'all' })}
            >
              All
            </div>
          </Link>
          <Link to={'/resources/for-ecommerce'}>
            <div
              className={cn(theme.tab, {
                [theme.selected]: page === 'for-ecommerce',
              })}
            >
              For Ecommerce
            </div>
          </Link>
          <Link to={'/resources/for-media'}>
            <div
              className={cn(theme.tab, {
                [theme.selected]: page === 'for-media',
              })}
            >
              For Media
            </div>
          </Link>
          <Link to={'/resources/for-startups'}>
            <div
              className={cn(theme.tab, {
                [theme.selected]: page === 'for-startups',
              })}
            >
              For Startups
            </div>
          </Link>
          <Link to={'/resources/for-enterprise'}>
            <div
              className={cn(theme.tab, {
                [theme.selected]: page === 'for-enterprise',
              })}
            >
              For Enterprise
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default ResourcesTabPicker;
