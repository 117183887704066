import { checkboxDefaults } from './helpers'
import partnership_interests from 'util/partnership_interests'

const opportunitySearch = {
  page: 1,
  size: 24,
  total: 0,
  searchValue: '',
  sortBy: {
    value: 'createdAt',
    asc: false,
    options: [
      {
        value: 'createdAt',
        label: 'Most Recent',
      },
      {
        value: 'brandName',
        label: 'Brand Name',
      },
    ],
    defaultDirections: {
      brandName: true,
    },
  },
  filtersExpanded: {
    prettyType: true,
    tags: true,
  },
  prettyType: checkboxDefaults({ term: 'prettyType' }),
  tags: checkboxDefaults({ term: 'brandTags.raw' }),
}

export default opportunitySearch
