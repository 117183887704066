import React from 'react';
import OpportunityCard from './opportunity-card';
import InterestValue from '../interests/interest-value';
import d3 from 'd3'
import { formatRange } from './formatRange'

class InsertSwapOpportunityCard extends React.Component {
  render() {
    let {
      opportunity,
      brand,
      brand: { obscure },
      isBrand,
      action,
      showActions,
      hideBar,
      editOpportunity,
      shareOpportunity,
      deleteOpportunity
    } = this.props

    return (
      <OpportunityCard
        opportunity={ opportunity }
        brand={ brand }
        action={ action }
        editOpportunity={ editOpportunity }
        deleteOpportunity={ deleteOpportunity }
        shareOpportunity={ shareOpportunity }
        isBrand={isBrand}
        showActions={showActions}
        hideBar={hideBar}
      >
        <InterestValue
          title='Monthly Volume'
          value={ formatRange(opportunity.min_target_monthly_volume, opportunity.max_target_monthly_volume) }
          marginRight='24px'
          marginBottom='0px'
          obscure={obscure}
          style={{
            float: 'left'
        }}/>
        <InterestValue
          title='Products Shipped'
          value={ opportunity.products }
          marginBottom='0px'
          obscure={obscure}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            float: 'left',
            width: '200px'
        }}/>
      </OpportunityCard>
    )
  }
}

export default InsertSwapOpportunityCard;
