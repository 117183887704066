import React from 'react';
import _ from 'lodash'

class SectionHr extends React.Component {
  render() {
    var defaultStyle = {
      marginTop: '40px',
      marginBottom: '30px',
      color: COLOURS.lightGrey
    }

    let style = _.extend({}, defaultStyle, this.props.styles)

    return (
      <hr style={style}/>
    )
  }
}

export default SectionHr;