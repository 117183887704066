import initialState from './defaults/campaignAnalyticsSearch'

// Purely used for retrieving aggregated metrics from ES Campaign Analytics
// Actual search / filtering functionality is not used in this model
export default {
  model: 'campaignAggegrationsSearch',
  initialState,
  endpoint: '/search-campaign-analytics',
  inputName: [],
  aggs: getState => {
    const currentBrandId = getState().currentBrand.id
    return {
      industry: {
        terms: {
          field: 'industry.raw',
          size: 100,
        },
      },
      allVisits: {
        sum: {
          field: 'totalVisits',
        },
      },
      allSmsSubscribers: {
        sum: {
          field: 'smsSubscribers',
        },
      },
      allEntries: {
        sum: {
          field: 'totalEntries',
        },
      },
      partners: {
        nested: {
          path: 'partners',
        },
        aggs: {
          currentBrand: {
            filter: {
              term: {
                'partners.brand_id': currentBrandId,
              },
            },
            aggs: {
              allNTF: {
                sum: {
                  field: 'partners.ntf',
                },
              },
              totalEntriesDelivered: {
                sum: {
                  field: 'partners.total_entries_delivered'
                }
              }
            },
          },
        },
      },
    }
  },
  rangeFilters: [],
  facets: [],
  nestedFilter: getState => ({
    term: {
      'partners.brand_id': getState().currentBrand.id,
    },
  }),
  extendQuery({ body, state, getState }) {
    const { filter } = body.query.bool
    const startDate = {
      lte: 'now' // filter for only campaigns that have started/launched
    }
    filter.push({ range: { startDate } })

    return body
  },
}
