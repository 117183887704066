import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import * as metrics from 'util/metrics'

import Checkbox from 'react-toolbox/lib/checkbox'
import CheckboxTheme from 'css/themes/Checkbox.css'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4, H6, P } from 'visual-components/util/texts'

import SellerProfileActions from 'actions/seller-profile-actions'

const stateToProps = ({ sellerProfileModals, sellerProfile }) => ({
  isOpen: sellerProfileModals.showPartnershipInterestsModal,
  sellerProfile,
})

const dispatchToProps = dispatch => ({
  sellerProfileActions: bindActionCreators(SellerProfileActions, dispatch),
})

const checkboxItems = {
  Sweepstakes: {
    title: 'Sweepstakes',
    text: 'Collaborate on campaigns to drive email list and social growth',
  },
  'Dedicated Email Buys': {
    title: 'Dedicated Email Buys',
    text: 'Buy media from other brands on the platform',
  },
  'Dedicated Email Sells': {
    title: 'Dedicated Email Sells',
    text: 'Sell media to other brands on the platform',
  },
  'Prizing Partners': {
    title: 'Prizing Partner',
    text: 'Provide prizing for sweepstakes in exchange for brand exposure and/or entries',
  },
  'Content Swaps': {
    title: 'Content Swaps',
    text: 'Cross-promote content to acquire new readers',
  },
  'Box-Insert Swaps': {
    title: 'Box-Insert Swaps',
    text: 'Exchange physical inserts in product shipments to grow audience',
  },
}

const renderCheckboxes = (...params) => propName => {
  const [values, onCheck] = params

  const item = checkboxItems[propName]

  return (
    <div style={{ marginBottom: '16px' }} key={propName}>
      <Checkbox
        label={(
          <div style={{ display: 'flex', paddingTop: '1px' }}>
            <H6>{ `${item.title}:` }</H6>
            &nbsp;
            <P style={{ lineHeight: '14px', paddingLeft: '2px' }}>{ item.text }</P>
          </div>
        )}
        theme={CheckboxTheme}
        // disabled={ !task.canEdit || task.category === 'minimum_entries_due' }
        checked={values[propName]}
        onChange={val => onCheck(propName, val)}
        className={CheckboxTheme.taskCheckbox}
      />
    </div>
  )
}

class EditPartnershipInterestModal extends Component {
  static propTypes = {
    sellerProfileActions: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    sellerProfile: PropTypes.object,
  }

  static defaultProps = {
    sellerProfile: {},
    isOpen: false,
  }

  state = {
    values: {
      Sweepstakes: false,
      'Dedicated Email Buys': false,
      'Dedicated Email Sells': false,
      'Prizing Partners': false,
      'Content Swaps': false,
      'Box-Insert Swaps': false,
    },
  }

  onSave = () => {
    const { sellerProfileActions } = this.props
    const { values } = this.state

    const data = []

    Object.keys(values).forEach(key => {
      if (values[key]) data.push({ tag: key })
    })

    metrics.create('partnershipInterestsEdit')

    sellerProfileActions.updateSellerProfile({ interests: data })
    this.onClose()
  }

  onClose = () => {
    const { sellerProfileActions } = this.props
    sellerProfileActions.closeModal()
  }

  onChange = (name, val) => {
    const { values } = this.state

    metrics.create('partnershipInterestsCheck', {
      meta: { value: val ? 'checked' : 'unchecked' },
      object: name,
    })

    return this.setState({ values: { ...values, [name]: val } })
  }

  componentDidUpdate = prevProps => {
    const { isOpen, sellerProfile: { interests } } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      if (interests) {
        const { values } = this.state
        const newVals = {}

        interests.forEach(({ tag }) => {
          newVals[tag] = true
        })

        this.setState({ values: { ...values, ...newVals } })
      }
    }
  }

  render() {
    const { isOpen } = this.props
    const { values } = this.state

    const checkboxes = Object.keys(values).map(renderCheckboxes(values, this.onChange))

    return (
      <MultiTaskModal
        active={isOpen}
        close={this.onClose}
        title="Edit Partnership Interests"
      >
        <div style={{ marginBottom: '24px' }}>
          <H4 multiline>
            Select the partnership opportunities you're interested in.
          </H4>
        </div>
        <div style={{ marginBottom: '24px' }}>
          { checkboxes }
        </div>
        <ModalButtonGroup
          canSave
          confirm={this.onSave}
          cancel={this.onClose}
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(EditPartnershipInterestModal)
