import React from 'react';
import theme from 'css/themes/homePage/whatMarketersSay.css';
import { Carousel } from 'react-responsive-carousel';
import MarketerSaidSlide from '../util/marketer-said-slide';

// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

class WhatTheySay extends React.Component {

  render() {
    return(
      <div className={ `${theme.container} ${theme.noMarginTop}` }>
        <p className={ theme.title }>
          What Marketers Are Saying
        </p>
        <Carousel
          infiniteLoop={ true }
          showThumbs={false}
        >
          <div>
            <MarketerSaidSlide
              quote={ `“We love the platform, how easy it is to use, how it allows us to search and connect with like-minded partners. DojoMojo provides a smart, consumer friendly way to grow our email business. It’s pretty damn brilliant.”` }
              author={ 'Sean Ryan' }
              whoDat={ 'Founder at' }
              linkTitle={ 'Elevator' }
              linkURL={ 'http://gettheelevator.com/' }
              imgSrc={  '/images/brand-logos/Elevator_Logo.png' }
            />
          </div>
        </Carousel>
      </div>
    )
  }
}

export default WhatTheySay;
