import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { H2, H4, P } from 'visual-components/util/texts'
import { Card } from 'react-toolbox/lib/card'
import { Switch } from 'react-toolbox/lib/switch'
import SwitchTheme from 'css/themes/Switch.css'
import Theme from 'css/themes/NotificationSettings.css'

import * as NotificationActions from 'actions/email-notification-actions'

const mapState = ({ brandCredentials }) => ({ brandCredentials })

const mapDispatch = dispatch => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
})


const EmailNotificationCard = props => {
  const { emailType, title, content, checked, handleChange } = props

  return (
    <Card className={Theme.notificationCard} >
      <div className={Theme.cardTitle}>
        <H4>
          <em>
            {title}
          </em>
        </H4>
        <Switch
          checked={checked}
          theme={SwitchTheme}
          onChange={() => handleChange(emailType, checked)}
        />
      </div>
      <div className={Theme.cardContent}>
        <P multiline>
          {content}
        </P>
      </div>
    </Card>
  )
}

class EmailNotificationSettings extends Component {
  componentDidMount = () => {
    const { notificationActions } = this.props
    notificationActions.loadEmailNotificationSettings()
  }

  handleChange = (emailType, value) => {
    const { notificationActions } = this.props
    const newValue = !value
    notificationActions.changeEmailNotificationSetting({
      emailType,
      value: newValue,
    })
  }

  render() {
    const {
      brandCredentials: {
        error, 
        marketingEmails, 
        campaignEmails, 
        messageEmails,
      },
    } = this.props

    return (
      <div className={Theme.container}>
        <Helmet>
          <title>Email Notifications</title>
        </Helmet>
        <Grid>
          <Row>
            <Col xs>
              <div className={Theme.title}>
                <H2 azure>Email Notifications</H2>
                <div className={Theme.subtitle}>
                  Note: You will still receive emails related to your account,
                  login information, billing, and entries downloads.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className={Theme.cardsContainer}>
              <Col>
                <EmailNotificationCard
                  emailType="marketingEmails"
                  title="Marketing Communications"
                  content="This includes recommendation emails, product updates, and company news."
                  checked={marketingEmails}
                  handleChange={this.handleChange}
                />
                <EmailNotificationCard
                  emailType="campaignEmails"
                  title="Campaign Communications"
                  content="This includes message notifications regarding campaign activity and/or media market deals."
                  checked={campaignEmails}
                  handleChange={this.handleChange}
                />
                <EmailNotificationCard
                  emailType="messageEmails"
                  title="Messages"
                  content="This includes message notifications and campaign invitations."
                  checked={messageEmails}
                  handleChange={this.handleChange}
                />
              </Col>
            </div>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default connect(mapState, mapDispatch)(EmailNotificationSettings)
