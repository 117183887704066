import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { Small } from 'visual-components/util/texts'
import DisplayedStat from './displayed-stat'
import NewToFilePie from './new-to-file-pie'

class NewToFileDisplay extends Component {
  static propTypes = {
    live: PropTypes.bool,
    newToFile: PropTypes.node.isRequired,
    totalEntries: PropTypes.number.isRequired,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const {
      live,
      newToFile,
      totalEntries,
    } = this.props

    return (
      <div style={{ textAlign: 'center' }}>
        <Small white={live}>
          <em> NEW TO FILE </em>
        </Small>
        <DisplayedStat live={live} value={newToFile} />
        <NewToFilePie total={totalEntries} contributed={newToFile} live={live} />
      </div>
    )
  }
}

export default NewToFileDisplay
