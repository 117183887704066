import React from 'react'
import PropTypes from 'prop-types'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import { H4, P, Small } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'

class ReferralCard extends React.PureComponent {
  clickReferralButton = () => {
    const {
      campaignId, openPlatformInviteModal, started, isPartner,
    } = this.props
    openPlatformInviteModal({
      source_location: 'Campaign Quickview',
      campaign_id: started || isPartner ? '' : campaignId,
      showCampaignList: !(started || isPartner),
    })
  }

  render() {
    const { started, isPartner } = this.props
    const title = started || isPartner ? 'Give $25, Get $25' : 'Invite New Brands'

    const message = started || isPartner
      ? 'Do you know a brand that might be interested in partnership marketing? Invite them to DojoMojo!'
      : 'Do you know a brand that might be interested in this campaign? Invite them to DojoMojo!'

    return (
      <Card
        style={{
          width: '240px',
          display: 'inline-flex',
          marginBottom: '16px',
          height: '228px',
          marginLeft: '16px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Grid>
          <Row style={{ justifyContent: 'center', alignItems: 'center', height: '228px' }}>
            <div style={{ textAlign: 'center', maxWidth: '192px' }}>
              <H4 style={{ marginBottom: '8px' }}>
                <b>{title}</b>
              </H4>
              <P style={{ marginBottom: '16px' }}>
                <Small multiline>{message}</Small>
              </P>
              <Button
                theme={buttonTheme}
                className={buttonTheme.referralSmallGreen}
                label="Refer a Brand"
                raised
                onClick={this.clickReferralButton}
              />
            </div>
          </Row>
        </Grid>
      </Card>
    )
  }
}

ReferralCard.propTypes = {
  campaignId: PropTypes.string,
  openPlatformInviteModal: PropTypes.func,
  started: PropTypes.bool,
  isPartner: PropTypes.bool,
}

export default ReferralCard
