import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import ProgressDot from 'visual-components/Welcome/progress-dot'

import * as MetricsActions from 'actions/analytics-actions'
import createSrcset from 'util/create-srcset'

import Theme from 'css/themes/CollaborativeEducationalModal.css'

import { H4, Small } from 'visual-components/util/texts'


const stateToProps = ({
  deals: {
    deal,
  },
  collaborateEducationalModal: modalData,
  currentBrand,
}) =>
  ({ deal, modalData, currentBrand })

const dispatchToProps = dispatch => ({
  dispatch,
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

const steps = [
  {
    title: 'Share Creative Assets',
    content: 'Keep the communication all in one place. Share images and documents by uploading them to your conversation.',
    image: '/images/collaborative-educational/assets-illustration.svg',
    srcset: null,
  },
  {
    title: 'Make It a Collaborative Effort',
    content: 'Work together by leaving comments and feedback on shared assets.',
    image: '/images/collaborative-educational/collaborate-illustration.jpg',
    srcset: createSrcset('/images/collaborative-educational/collaborate-illustration.jpg'),
  },
  {
    title: 'Manage Tracking Links',
    img1: 'images/collaborative-educational/target-link-with-bg.svg',
    img2: 'images/collaborative-educational/tracking-links-with-bg.svg',
    content1: 'DojoMojo generates a unique tracking link for the seller. Just tell us where you want users to land.',
    content2: 'Use the tracking link exactly as it appears. Just click "Copy" to copy it to your clipboard.',
  },
  {
    title: 'Enhanced Visibility',
    img1: 'images/collaborative-educational/buyers-email-illustration.svg',
    img2: 'images/collaborative-educational/seller-esp-illustration.svg',
    content1: 'If the seller has connected their ESP, you will be able to preview the campaign.',
    content2: 'Connect your ESP to share a preview of the campaign and enable live updates in Analytics.'
  },
  {
    title: 'Trust and Transparency',
    content: 'Once your deal is live, you can see the results of your campaign under the "Analytics" tab. We\'re excited to help you get started!',
    image: '/images/collaborative-educational/analytics-illustrations.svg',
    srcset: null,
  },
]

class CollaborateEducationalModal extends PureComponent {
  static propTypes = {
    metricsActions: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    deal: PropTypes.object.isRequired,
  }

  componentDidUpdate({ modalData }) {
    const { modalData: { show, step } } = this.props

    // When modal is opened
    if (modalData.show !== show && show) this.sendMetrics({ step: 1 })

    // When navigating
    else if (modalData.step !== step && show) this.sendMetrics({ step: step + 1 })
  }

  sendMetrics = data => {
    const { currentBrand, deal, metricsActions } = this.props

    const isSellerBrand = currentBrand.id === deal.selling_brand_id

    return metricsActions.create('mediaDealCollaborateEducation', {
      meta: {
        isSellerBrand,
        ...data,
      },
    })
  }

  dispatchData = ({ show = null, step = null }) => {
    const { modalData, dispatch } = this.props

    return dispatch({
      type: 'UPDATE_ATTR',
      model: 'collaborateEducationalModal',
      data: {
        show: show !== null ? show : modalData.show,
        step: step !== null ? step : modalData.step,
      },
    })
  }

  onNextStep = val => {
    const { modalData: { step } } = this.props

    // Mouse Event
    if (typeof val !== 'number') {
      const nextStep = step + 1

      if (nextStep >= steps.length) {
        return this.dispatchData({ show: false })
      }

      return this.dispatchData({ step: nextStep })
    }

    return this.dispatchData({ step: val })
  }

  onClose = () => this.dispatchData({ show: false })

  render() {
    const { modalData: { step, show } } = this.props

    const stepItem = steps[step]
    const isFinalStep = step === steps.length - 1

    return (
      <SimpleInfoModal
        className={Theme.dialogShadow}
        active={show}
        title={stepItem.title}
        close={this.onClose}
      >
        {
          stepItem.image
            ? (
              <div className={Theme.banner}>
                <img
                  className={Theme.bannerImg}
                  src={stepItem.image}
                  srcSet={stepItem.srcset}
                />
              </div>
            ) : (
              <div className={Theme.individualItems}>
                <div className={Theme.item}>
                  <img className={Theme.img} src={stepItem.img1} />
                  <div className={Theme.text}>
                    <H4>
                      <i>For Buyer</i>
                    </H4>
                    <Small multiline>
                      { stepItem.content1 }
                    </Small>
                  </div>
                </div>
                <div className={Theme.item}>
                  <img className={Theme.img} src={stepItem.img2} />
                  <div className={Theme.text}>
                    <H4>
                      <i>For Seller</i>
                    </H4>
                    <Small multiline>
                      { stepItem.content2 }
                    </Small>
                  </div>
                </div>
              </div>
            )
        }
        {
          stepItem.content
            && (
              <div style={{ marginBottom: '8px' }}>
                <H4 multiline>{stepItem.content}</H4>
              </div>
            )
        }
        <ModalButtonGroup
          canSave
          confirm={this.onNextStep}
          confirmText={isFinalStep ? 'Get Started' : 'Next'}
          hideLine
        />
        <ProgressDot steps={steps.length} step={step} nextFunc={this.onNextStep} />
      </SimpleInfoModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(CollaborateEducationalModal)
