import {
  func,
  instanceOf,
} from 'prop-types'
import React from 'react'

import _ from 'lodash'
import moment from 'moment-timezone'

import CalendarTheme from 'css/themes/calendar/calendar.css'
import Week from './Week'
import MonthHeader from './MonthHeader'

const propTypes = {
  onNavigate: func,
}

const renderWeekHeaders = (day, i) => <MonthHeader day={day} key={i} />
const renderCalendarWeeks = (week, i) => <Week week={week} key={i} />

class MonthView extends React.Component {
  static propTypes = propTypes

  static contextTypes = {
    date: instanceOf(Date).isRequired,
  }

  state = { scrollY: 0 }

  checkScroll = _.throttle(node => {
    const { handleNavigate } = this.props

    const scrollPos = node.scrollTop
    const rows = node.children
    const rowHeight = rows[0].offsetHeight
    const nodeHeight = node.offsetHeight
    const diff = nodeHeight
    const totalRowHeight = rows.length * rowHeight

    if (scrollPos < diff) {
      handleNavigate('PREV')
      this.setState({
        scrollY: scrollPos + (nodeHeight - rowHeight),
      })
    } else if (scrollPos > totalRowHeight - (nodeHeight * 2 + rowHeight * 20.5)) {
      handleNavigate('NEXT')
      this.setState({
        scrollY: scrollPos - (nodeHeight - rowHeight),
      })
    }
  }, 400)

  componentDidMount() {
    this.scrollToCenter()
  }

  componentDidUpdate() {
    const { scrollY } = this.state

    if (scrollY) {
      this.monthHolder.scrollTop = scrollY
    }
  }

  scrollToCenter = () => {
    const month = this.monthHolder
    const rows = month.children
    const rowHeight = rows[0].offsetHeight

    setTimeout(() => {
      month.scrollTop = month.offsetHeight * 2 - rowHeight * 4 + 7
    }, 10)
  }

  onScroll = e => {
    this.checkScroll(e.target)
  }

  _weeks = () => {
    const {
      date,
    } = this.context

    const firstDay = moment(date).startOf('week').subtract(8, 'weeks')
    const days = []

    _.times(7 * 40, idx => {
      const day = moment(firstDay._d).add((idx + 1), 'day')._d
      days.push(day)
    })

    const weeks = _.chunk(days, 7)

    return weeks
  }

  render() {
    const weeks = this._weeks()

    const weekHeaders = weeks[0].map(renderWeekHeaders)
    const calendarWeeks = weeks.map(renderCalendarWeeks)

    return (
      <div className={CalendarTheme.calendarMonthHolder}>
        <div className={`${CalendarTheme.calendarRow} ${CalendarTheme.calendarMonthHeader}`}>
          { weekHeaders }
        </div>
        <div
          onScroll={this.onScroll}
          ref={ref => { this.monthHolder = ref }}
          className={CalendarTheme.calendarMonthView}
        >
          { calendarWeeks }
        </div>
      </div>
    )
  }
}

export default MonthView
