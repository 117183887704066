import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';
import PromoLinkCard from 'visual-components/analytics/campaign/promo-link-card';

class PromoEntries extends React.Component {
  state = {
    hideMap: {}
  }

  updateBrand = (brandId) => {
    var data = {
      metric: 'campaignEntryContributionStatsByPartnerByPromoUnit',
      campaignId: this.props.campaignId,
      specificBrandId: brandId
    };

    AnalyticsDispatcher.fetchReport(data, 'analyticsCampaign');
  }

  toggleItem = (item) => {
    var hideMap = this.state.hideMap;
    hideMap[item] = !hideMap[item]

    this.setState({
      hideMap: hideMap
    });
  }

  render() {
    let {
      data,
      isSolo,
    } = this.props;

    return (
      <PromoLinkCard { ...data }
        toggle={this.toggleItem}
        onChange={this.updateBrand}
        hideMap={this.state.hideMap}
        isSolo={isSolo}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.analyticsCampaign.campaignEntryContributionStatsByPartnerByPromoUnit,
    campaignId: state.analyticsCampaign.campaign.id,
    isSolo: state.analyticsCampaign.campaign.isSolo,
  };
}

export default connect(mapStateToProps)(PromoEntries);
