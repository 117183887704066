import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ProgressSteps from 'visual-components/socialGrowth/progress-steps'
import ConversionAction from 'visual-components/socialGrowth/conversion-action'
import SocialGrowth from 'visual-components/socialGrowth/socialGrowth'
import PricingDialog from 'visual-components/customCta/growth-pricing-dialog'
import SplitTraffic from 'visual-components/SplitTraffic'
import SocialGrowthHeader from 'visual-components/socialGrowth/SocialPostEntryBanner'

import { getClaim } from 'actions/brand-claim-actions'

const stateToProps = ({
  currentBrand, currentCampaign, sms, customer,
}) => ({
  currentBrand,
  currentCampaign,
  sms,
  customer,
})

class GrowOptimize extends React.Component {
  state = {
    partnerSplitTrafficStatus: '',
    partnerBannerStatus: '',
    isSmsTrafficPublished: null,
    selectedAction: '',
    mobileMarketingState: {},
  }

  setPartnerSplitTrafficStatus = val =>
    this.setState({ partnerSplitTrafficStatus: val })

  setPartnerBannerStatus = val =>
    this.setState({ partnerBannerStatus: val })

  setIsSmsTrafficPublished = val => {
    this.setState({ isSmsTrafficPublished: val })
  }

  setSelectedAction = val => {
    this.setState({ selectedAction: val })
  }

  setMobileMarketingState = val => {
    this.setState(prevState => ({
      mobileMarketingState: {
        ...prevState.mobileMarketingState,
        ...val,
      },
    }))
  }

  render() {
    const {
      currentBrand,
      currentCampaign,
      sms,
    } = this.props


    const {
      partnerBannerStatus,
      partnerSplitTrafficStatus,
      isSmsTrafficPublished,
      selectedAction,
      mobileMarketingState,
    } = this.state

    const isHost = currentBrand.id === currentCampaign.hostBrandId
    const canCreateSplitTraffic = getClaim('sweepstakes.splitTraffic.create')
    const canViewPostEntry = getClaim('sweepstakes.mobileMarketing.create') && (
      currentCampaign.isSolo || getClaim('sweepstakes.partnershipMobileMarketing.create')
    )

    return (
      <div style={{ paddingTop: 16 }}>
        <Helmet>
          <title>Boost Reach - DojoMojo</title>
        </Helmet>
        {!currentCampaign.isSolo && (
          <>
            <SocialGrowthHeader
              canViewSplitTraffic={canCreateSplitTraffic}
              currentBrand={currentBrand}
              currentCampaign={currentCampaign}
              isHost={isHost}
            />
            {
              !currentCampaign.loading
              && (
                <SplitTraffic
                  campaign={currentCampaign}
                  isHost={isHost}
                  canView={canCreateSplitTraffic}
                  setPartnerSplitTrafficStatus={this.setPartnerSplitTrafficStatus}
                  setPartnerBannerStatus={this.setPartnerBannerStatus}
                  setMobileMarketingState={this.setMobileMarketingState}
                  isSmsTrafficPublished={isSmsTrafficPublished}
                  mobileMarketingState={mobileMarketingState}
                  selectedAction={selectedAction}
                />
              )
            }
          </>
        )}
        {
          canViewPostEntry && (
            <>
              <ProgressSteps isHost={isHost} />
              <ConversionAction
                sms={sms}
                isHost={isHost}
                currentBrand={currentBrand}
                currentCampaign={currentCampaign}
                partnerSplitTrafficStatus={partnerSplitTrafficStatus}
                partnerBannerStatus={partnerBannerStatus}
                setIsSmsTrafficPublished={this.setIsSmsTrafficPublished}
                setSelectedAction={this.setSelectedAction}
                setMobileMarketingState={this.setMobileMarketingState}
                isSmsTrafficPublished={isSmsTrafficPublished}
              />
            </>
          )
        }
        <SocialGrowth />
        <PricingDialog />
      </div>
    )
  }
}

GrowOptimize.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  sms: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
}

export default connect(stateToProps)(GrowOptimize)
