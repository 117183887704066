import React from 'react';
import Button from 'util/components/buttons/button';
import COLOURS from 'util/colours';

class TertiaryButton extends React.Component{
  render() {

    var isDisabled = this.props.isDisabled;

    var buttonStyles = [{
      backgroundColor: 'transparent',
      borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.inputFocusBorder,
      borderWidth: '1px',
      borderStyle: 'solid',
      color: isDisabled ? COLOURS.inputBorder : COLOURS.inputFocusBorder,
      fontFamily: 'Larsseit',
      ':hover': {
        color: isDisabled ? COLOURS.inputBorder : COLOURS.textGreenHover,
        borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.textGreenHover
      }
    }];

    var blueButtonStyles = [{
      color: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
      borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
      fontFamily: 'Larsseit',
      fontSize: '14px',
      ':hover': {
        color: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
        borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.azure
      }
    }];

    if ( this.props.styles ) {
      buttonStyles = buttonStyles.concat( this.props.styles );
    }

    if (this.props.blueButton) {
      buttonStyles = buttonStyles.concat( blueButtonStyles );
    }
    return (
      <Button 
        { ...this.props }
        disabled={ isDisabled }
        styles={ buttonStyles }
      />
    );
  }
};

export default TertiaryButton;
