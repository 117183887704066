import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'react-toolbox/lib/menu'
import { array, func, bool, number, } from 'prop-types'

import { H6, P } from 'visual-components/util/texts'

import MessagingDispatcher from 'dispatchers/messaging-dispatcher'
import COLOURS from 'util/colours'

import MessageMenuItem from './message-menu-item'
import NotificationMenuItem from './notification-menu-item'

import WideNavBarDropdownTheme from 'css/themes/WideNavBarDropdown.css'

class MessagesDropdownMenu extends React.Component {
  static propTypes = {
    notificationData: array.isRequired,
    unreadNotificationCount: number.isRequired,
    messagesData: array.isRequired,
    unreadCount: number.isRequired,
    handleNotificationMenuHide: func.isRequired,
    messagesMenu: bool.isRequired,
    notificationRead: func.isRequired,
    notificationsReadAll: func.isRequired,
  }

  readAll = () => {
    const { notificationsReadAll } = this.props

    MessagingDispatcher.markAllRead()
    notificationsReadAll()
  }

  render() {
    const {
      handleNotificationMenuHide,
      messagesMenu,
      messagesData,
      unreadCount,
      notificationData,
      unreadNotificationCount,
      notificationRead,
    } = this.props

    let messageList = messagesData.map((item, i) => {
      const messageMessage = item.message
      const classNames = item.unread ? `${WideNavBarDropdownTheme.Message} ${WideNavBarDropdownTheme.unread}` : WideNavBarDropdownTheme.Message

      return (
        <MessageMenuItem
          key={i}
          className={classNames}
          item={item}
          messageMessage={messageMessage}
          handleNotificationMenuHide={handleNotificationMenuHide}
        />
      )
    })

    if (!messageList.length) {
      messageList = (
        <li style={{
          padding: '10px 16px 7px 16px',
          textTransform: 'none',
          borderBottom: `1px solid ${COLOURS.silver}`,
          textAlign: 'center',
        }}>
          <P>
            <i>
              No messages yet. Reach out to someone!
            </i>
          </P>
        </li>
      )
    }

    let notificationList = notificationData.map(item => {
      let notificationText = item.presentational && item.presentational.text

      if (notificationText && notificationText.length > 65) {
        notificationText = `${notificationText.substring(0, 65)}...`
      }

      const classNames = !item.read_at
        ? `${WideNavBarDropdownTheme.Notification} ${WideNavBarDropdownTheme.unread}`
        : WideNavBarDropdownTheme.Notification

      return (
        <NotificationMenuItem
          key={item.id}
          className={classNames}
          item={item}
          notificationText={notificationText}
          handleNotificationMenuHide={handleNotificationMenuHide}
          handleRead={notificationRead}
        />
      )
    })

    if (!notificationList.length) {
      notificationList = (
        <li
          style={{
            textTransform: 'none',
            borderBottom: `1px solid ${COLOURS.silver}`,
            padding: '10px 16px 7px 16px',
            textAlign: 'center',
          }}
        >
          <P>
            <i>No notifications yet.</i>
          </P>
        </li>
      )
    }

    return (
      <Menu
        position="topRight"
        active={messagesMenu}
        onHide={handleNotificationMenuHide}
        theme={WideNavBarDropdownTheme}
        className={WideNavBarDropdownTheme.MessagesMenu}
      >
        <li style={{
          padding: '10px 16px 7px 16px',
          textTransform: 'none',
          color: COLOURS.dropdownText,
          borderBottom: `1px solid ${COLOURS.silver}`,
        }}>
          <div style={{ width: '50%', display: 'inline-block' }}>
            <H6>
              {`Messages (${unreadCount})`}
            </H6>
          </div>
          <div style={{ width: '50%', display: 'inline-flex' }}>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Link to="/messaging" onClick={handleNotificationMenuHide}>
                <H6 azure>
                  See all messages
                </H6>
              </Link>
            </div>
          </div>
        </li>
        { messageList }
        <li style={{
          padding: '10px 16px 7px 16px',
          textTransform: 'none',
          color: COLOURS.dropdownText,
          borderBottom: `1px solid ${COLOURS.silver}`,
        }}>
          <div style={{ width: '50%', display: 'inline-block' }}>
            <H6>
              {`Notifications (${unreadNotificationCount})`}
            </H6>
          </div>
          <div style={{ width: '50%', display: 'inline-flex' }}>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Link to="/notifications" onClick={handleNotificationMenuHide}>
                <H6 azure>
                  See all notifications
                </H6>
              </Link>
            </div>
          </div>
        </li>
        { notificationList }
        <li style={{
          textAlign: 'center',
          paddingBottom: '10px',
          paddingTop: '14px',
        }}>
          <div onClick={this.readAll} style={{ cursor: 'pointer' }}>
            <H6 seaGreen>
              Mark all as read
            </H6>
          </div>
        </li>
      </Menu>
    )
  }
}

export default MessagesDropdownMenu
