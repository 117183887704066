import React, { Component } from 'react'
import propTypes from 'prop-types'
import LanderSectionHeader from 'components/campaigns/builder/lander/lander-section-header'
import FormGroup from 'util/components/form-group'
import AgreeToEdit from 'util/components/form-elements/agree-to-edit'
import * as ReactRedux from 'react-redux'

const landerSectionHeaderStyle = { marginBottom: '8px' }

const inputCheckStyles = {
  marginBottom: '32px',
  height: '12px !important',
  display: 'block',
}

const inputStyles = {
  width: '12px',
  height: '12px',
  marginTop: '-10px',
  backgroundSize: '10px',
  backgroundPositionY: '2px',
}

const inputLabelStyles = {
  fontFamily: 'Larsseit-Light',
  fontSize: '12px',
  lineHeight: '1',
  display: 'inline',
}

const landerSectionHeaderStyle2 = {
  ...landerSectionHeaderStyle,
  header: { display: 'block', marginBottom: '8px' },
  subHeader: { fontSize: '14px', marginBottom: '12px' },
}

class LegalTab extends Component {
  static propTypes = {
    agreeToLiability: propTypes.func.isRequired,
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    isHost: propTypes.bool,
    termsAndConditionsForm: propTypes.object.isRequired,
    updateAttr: propTypes.func.isRequired,
    setEditingLegalLanguage: propTypes.func.isRequired,
  }

  static defaultProps = {
    isHost: undefined,
  }

  render() {
    const {
      campaign,
      classes: css,
      updateAttr,
      isHost,
      agreeToLiability,
      termsAndConditionsForm,
      setEditingLegalLanguage,
    } = this.props

    const mergeData = campaign.perBrandTerms
      ? {
        hostBrandName: 'all partners',
        partnerBrandNames: '',
      }
      : {
        hostBrandName: campaign.hostBrandName,
        partnerBrandNames: campaign.partnerBrandNames,
      }

    const preCheckedText = campaign.isSolo
      ? 'Note: For compliance reasons, SMS Opt-In will not be pre-checked.'
      : 'Please select whether you would like to display the opt-in boxes as pre-checked.'

    return (
      <div>
        <LanderSectionHeader
          style={landerSectionHeaderStyle}
          headerText="Opt-In by Brand(Recommended)"
          subHeaderText="We recommend displaying a checkbox for every partner."
        />
        <FormGroup
          updateAttr={updateAttr}
          editable={isHost}
          model={campaign}
          type="checkbox"
          attrName="perBrandTerms"
          label={LANGUAGE.landerEditor.form.perBrandTerms.label}
          styles={[inputCheckStyles]}
          inputStyles={inputStyles}
          labelStyles={[inputLabelStyles]}
        />
        <LanderSectionHeader
          style={landerSectionHeaderStyle}
          headerText="Pre-Checked Opt-In Boxes"
          subHeaderText={preCheckedText}
        />
        <FormGroup
          updateAttr={updateAttr}
          editable={isHost}
          model={campaign}
          type="checkbox"
          attrName="preCheck"
          label={LANGUAGE.landerEditor.form.preCheck.label}
          styles={[inputCheckStyles]}
          inputStyles={inputStyles}
          labelStyles={[inputLabelStyles]}
        />
        <LanderSectionHeader
          style={landerSectionHeaderStyle2}
          headerText="Terms and Conditions Label"
          subHeaderText="This text will appear next to your opt-in checkbox and serve as a link to your Official Rules."
        />
        <AgreeToEdit
          dataModel={campaign}
          formModel={termsAndConditionsForm}
          attrName="tosLinkLabel"
          liabilityAttr="tosLinkLabelLiability"
          label="I agree and would like to proceed with editing"
          customDivStyle={{ marginTop: '5px', marginBottom: '30px' }}
          updateAttr={updateAttr}
          agreeToLiability={agreeToLiability}
          styles={[{ ...inputCheckStyles, marginBottom: '24px' }]}
          inputStyles={inputStyles}
          labelStyles={[inputLabelStyles]}
          hideEditButton={campaign.perBrandTerms}
          setEditingLegalLanguage={setEditingLegalLanguage}
        />
        <LanderSectionHeader
          style={landerSectionHeaderStyle2}
          headerText="Opt-In Language"
          subHeaderText="This text will appear above the ENTER button."
        />
        <AgreeToEdit
          dataModel={campaign}
          formModel={termsAndConditionsForm}
          attrName="additionalTermsDetail"
          label="I agree and would like to proceed with editing"
          liabilityAttr="additionalTermsLiability"
          customDivStyle={{ marginTop: '5px', marginBottom: '30px' }}
          updateAttr={updateAttr}
          validations
          mergeData={mergeData}
          agreeToLiability={agreeToLiability}
          styles={[{ ...inputCheckStyles, marginBottom: '24px' }]}
          inputStyles={inputStyles}
          labelStyles={[inputLabelStyles]}
          fieldType="textarea"
          hideEditButton={campaign.perBrandTerms}
          setEditingLegalLanguage={setEditingLegalLanguage}
        />
      </div>
    )
  }
}

const mapState = function (state) {
  return {
    termsAndConditionsForm: state.termsAndConditionsForm,
  }
}

export default ReactRedux.connect(mapState)(LegalTab)
