import React, { PureComponent as Component } from 'react'
import { number, bool } from 'prop-types'
import COLOURS from 'util/colours'

class NewToFilePie extends Component {
  static propTypes = {
    contributed: number.isRequired,
    total: number.isRequired,
    live: bool,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const {
      contributed,
      total,
      live,
    } = this.props

    const percentage = total
      ? Math.round((contributed / total) * 100)
      : 0
    const remaining = 100 - percentage

    const barColour = live ? COLOURS.neonMint : COLOURS.azure
    const barBackColour = live ? COLOURS.stone : COLOURS.silver
    const textColour = live ? COLOURS.white : COLOURS.ink

    return (
      <div
        style={{
          width: '68px',
          height: '68px',
          marginTop: '8px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        {/* thanks to https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72 for the ideas/help */}
        <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
          <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent" />
          <text x="50%" y="50%" textAnchor="middle" strokeWidth="inherit" dy=".3em" fontFamily="Larsseit-Bold" fontSize="9px" fill={textColour} >
            { `${percentage}%` }
          </text>
          <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={barBackColour} strokeWidth="7px" />
          <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={barColour} strokeWidth="7px" strokeDasharray={`${percentage} ${remaining}`} strokeDashoffset="50" />
        </svg>
      </div>
    )
  }
}

export default NewToFilePie
