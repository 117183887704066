import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import ProfileMainCard from 'visual-components/util/profile-main-card';
import ProfileStat from 'visual-components/util/profile-stat';

class CampaignStats extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      estimatedEntries,
      dedicatedListSize,
      socialMediaImpressions,
      minimumDelivery,
      minimumDedicatedListSize
    } = this.props;

    return(
      <div>
        <ProfileMainCard title={ 'Stats' }>
          <Grid>
            <Row>
              <ProfileStat title={ 'EST ENTRIES' }
                value={ estimatedEntries }
              />

              <ProfileStat title={ 'TOTAL LIST SIZE' }
                value={ dedicatedListSize }
              />

              <ProfileStat title={ 'TOTAL SOCIAL REACH' }
                value={ socialMediaImpressions }
              />

              <ProfileStat title={ 'MIN ENTRIES REQUIRED' }
                value={ minimumDelivery }
                tooltip={ `The number of entries each Partner is required to drive before having access to entries.` }
              />

              <ProfileStat title={ 'MIN LIST SIZE' }
                value={ minimumDedicatedListSize }
                tooltip={ `The Host's preferred email list size for Partners wanting to join this campaign.` }
              />
            </Row>
          </Grid>
        </ProfileMainCard>
      </div>
    )
  }
}

CampaignStats.propTypes = {
  estimatedEntries: PropTypes.number.isRequired,
  dedicatedListSize: PropTypes.number.isRequired,
  socialMediaImpressions: PropTypes.number.isRequired,
  minimumDelivery: PropTypes.number,
  minimumDedicatedListSize: PropTypes.number
};

export default CampaignStats;
