import React, { Component } from 'react'
import { string, array, func, object } from 'prop-types'
import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import RangeSlider from 'visual-components/util/filters/range-slider'

import RadioTheme from 'css/themes/Radio.css'

class RadioSlider extends Component {
  static propTypes = {
    // radio props
    outerName: string.isRequired,
    options: array.isRequired,
    
    // slider props
    range: object.isRequired,
    format: func,
    extraStartText: string,
    extraEndText: string,
    secondaryStartText: string,
  }

  static contextTypes = {
    handleChange: func.isRequired,
    handleChangeImmediately: func.isRequired,
    searchModel: object.isRequired,
  }

  onRadioChange = newValue => {
    const { options, outerName } = this.props
    const { handleChange } = this.context
    // set name for dependent range filter
    handleChange(outerName, newValue)

    // toggle all other options to hidden
    options.forEach(({ value }) => {
      handleChange(value, { hidden: newValue !== value })
    })
  }

  render() {
    const { 
      outerName, 
      options,
      range,
      format,
      extraStartText,
      extraEndText,
      secondaryStartText,
    } = this.props
    const { searchModel } = this.context
    const innerName = searchModel[outerName]
    const radioButtons = options.map(({ label, value }, key) => (
      <RadioButton key={key} label={label} value={value} theme={RadioTheme} />
    ))

    return (
      <div>
        <div style={{ paddingBottom: '5px' }}>
          <RadioGroup 
            name={outerName}
            value={searchModel[outerName]} 
            onChange={this.onRadioChange}
          >
            {radioButtons}
          </RadioGroup>
        </div>
        <div>
          <RangeSlider
            minValue={range.min}
            maxValue={range.max}
            format={format}
            name={innerName}
            extraStartText={extraStartText}
            extraEndText={extraEndText}
            secondaryStartText={secondaryStartText}
          />
        </div>
      </div>
    )
  }
}

export default RadioSlider
