import _ from 'lodash';
import AppDispatcher from 'dispatchers/app-dispatcher';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import ProfileDispatcher from 'dispatchers/profile-dispatcher';
import { deleteCookie } from 'util/cookie';
import { getPlanLabel } from 'util/brand-stripe-belt-helper'

const checkReferral = (code) => {
  AppDispatcher.http({
    method: 'GET',
    url: `/referral-lookup/${code}`,
    success: ({referringBrand}) => {
      // only update the New Brand Registration Form if valid referral code is found
      store.dispatch({
        model: 'newBrand',
        type: 'UPDATE_ATTR',
        data: {
          promo: 'REFER25',
          referrer: referringBrand,
          howDidYouHear: 'invited',
          referralCode: code
        }
      })
    },
    error: err => console.log(err)
  })
}

var save = function(history) {
  var brand = _.extend({}, store.getState().newBrand, { belt: 'trial', stripe_belt: 'trial' });
  AppDispatcher.http({
    method: 'POST',
    url: '/brands/',
    data: brand,
    success: brand => createRequestSuccess(brand, history),
    error: err => {
      store.dispatch({
        model: 'newBrand',
        type: 'LOADING_END',
      })
      }
  });
}

var createRequestSuccess = function(brand, history) {
  localStorage.setItem('new-brand', null);

  deleteCookie('referral')

  store.dispatch({
    type: 'ADD_BRAND',
    model: 'userBrands',
    data: brand
  })

  $.ajaxSetup({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('brand', brand.id);
    }
  });

  //Fire marketing pixel events
  if (window.fbq) {
    fbq('track', 'CompleteRegistration');
  }

  history.push('/registrations/brand-profile');

  CurrentBrandDispatcher.load();
  ProfileDispatcher.load(brand.id);
}

export default {
  save,
  checkReferral
};
