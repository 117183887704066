import React from 'react';

import { Card } from 'react-toolbox/lib/card';

import IncomeDistributionGraph from 'visual-components/util/graphs/income-distribution-graph';
import EmptyState from 'visual-components/util/graphs/empty-state';
import { H3 } from 'visual-components/util/texts';

class IncomeDistributionCard extends React.Component {
  render() {
    let { data } = this.props

    return(
      <Card style={{
        width: '314px',
        height: '232px',
        marginRight: '16px',
        marginTop: '16px',
        overflow: 'visible'
      }}>
        <div style={{
          padding: '20px 24px'
        }}>
          <div style={{
            marginBottom: '24px'
          }}>
            <H3 coral>
              <small>
                Entries by Household Income
              </small>
            </H3>
          </div>
          {
            data && Object.keys(data).length ?
            <IncomeDistributionGraph data={ data } />
            :
            <EmptyState text={ 'No household income data available yet.' }
              height={ '80px' }
            />
          }
        </div>
      </Card>
    )
  }
}

export default IncomeDistributionCard;
