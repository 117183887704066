import React from 'react';
import * as ReactRedux from 'react-redux';

import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text';
import PrimaryButton from 'util/components/buttons/primary-button';
import TermsCreateOption from 'campaigns/builder/customize/terms/terms-create-option';

import TermsDispatcher from 'dispatchers/terms-dispatcher';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import { P } from 'visual-components/util/texts';

class TermsCreate extends React.Component{
  constructor(props) {
    super(props);

    this.check = this.check.bind(this);
    this.continue = this.continue.bind(this);
  }

  check(attr, value) {
    TermsDispatcher.updateTerms({
      [attr]: value
    });
  }

  continue() {
    TermsDispatcher.updateTerms({
      termsLiability: new Date() // accept liability
    });

    var campaignTerms = this.props.currentCampaign.campaignTerms;

    if (campaignTerms.useTemplate) {
      BrowserHistoryDispatcher.push(`/builder/${this.props.currentCampaign.id}/terms/info`)
    } else if (campaignTerms.createYourOwn) {
      BrowserHistoryDispatcher.push(`/builder/${this.props.currentCampaign.id}/terms/edit`);
    }
  }

  render() {
    var campaignTerms = this.props.currentCampaign.campaignTerms || {};

    var paras = [
      <P multiline>
        Create your Official Rules document, which will apply to all entrants for your campaign. Choose from the options below to get started. If you decide to switch options, your drafts will still be saved.
      </P>
    ];

    return(
      <div>
        <TabTitleAndText
          headers={[]}
          paras={ paras }
        />
        <div style={{
          paddingTop: '20px'
        }}>
          <TermsCreateOption
            type={ 'useTemplate' }
            check={ this.check }
            continue={ this.continue }
            heading={ `Use DojoMojo’s Template` }
            highlightedHeading={ `` }
            body={ `We've provided easily customizable sample language based on best practices. We're not lawyers, though - consult one before using any legal document. In no event will Innovation Brands Corp and DojoMojo be held liable for any loss or damage whatsoever arising from or in connection with the use of this website.` }
            button={ `Use Template` }
            checked={ campaignTerms.useTemplate }
            onLeft={ true }
          />
        </div>

        <TermsCreateOption
          type={ 'createYourOwn' }
          check={ this.check }
          continue={ this.continue }
          heading={ `Create Your Own` }
          body={ `Select this option if you'd prefer to use your own Official Rules. Always consult a lawyer before using any legal document. In no event will Innovation Brands Corp and DojoMojo be held liable for any loss or damage whatsoever arising from or in connection with the use of this website.` }
          button={ `Create My Own` }
          checked={ campaignTerms.createYourOwn }
        />
      </div>
    )
  }
}

export default TermsCreate;
