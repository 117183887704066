import React, { Component } from 'react'
import { string, bool } from 'prop-types'
import { Small } from 'visual-components/util/texts'
import DocumentSenderTheme from 'css/themes/media-market/assetVisuals/DocumentSender.css'
import DocumentReceiverTheme from 'css/themes/media-market/assetVisuals/DocumentReceiver.css'

class IOMessage extends Component {
  static propTypes = {
    isSender: bool.isRequired,
    invalid: bool.isRequired,
    insertion_order_id: string.isRequired,
    ioPath: string.isRequired,
  }

  render() {
    const {
      isSender, insertion_order_id, ioPath, invalid,
    } = this.props

    return isSender ? (
      <a href={ioPath} target="_blank" rel="noopener noreferrer">
        <img
          src="images/icons/blue-asset-download-icon.png"
          className={DocumentSenderTheme.downloadIcon}
        />

        <div className={DocumentSenderTheme.fileWrapperContainer}>
          <div className={DocumentSenderTheme.iconBackground}>
            <div className={DocumentSenderTheme.fileWrapperIconContainer}>
              <img src="images/icons/sender-asset-pdf-icon.png" />
            </div>
          </div>
          <div className={DocumentSenderTheme.verticalLine} />

          <div className={DocumentSenderTheme.background}>
            <div className={DocumentSenderTheme.fileWrapperItemInfo}>
              <div className={DocumentSenderTheme.flexRow}>
                <Small>
                  <em>{insertion_order_id}</em>
                </Small>
                {invalid && (
                  <div className={DocumentSenderTheme.invalidTag}>Invalid</div>
                )}
              </div>

              <div className={DocumentSenderTheme.fileType}>
                <Small>.pdf</Small>
              </div>
            </div>
          </div>
        </div>
      </a>
    ) : (
      <a href={ioPath} target="_blank" rel="noopener noreferrer">
        <div className={DocumentReceiverTheme.fileWrapperContainer}>
          <div className={DocumentReceiverTheme.background}>
            <div className={DocumentReceiverTheme.fileWrapperItemInfo}>
              <div className={DocumentSenderTheme.flexRow}>
                <Small>
                  <em>{insertion_order_id}</em>
                </Small>
                {invalid && (
                  <div className={DocumentSenderTheme.invalidTag}>Invalid</div>
                )}
              </div>
              <div className={DocumentReceiverTheme.fileType}>
                <Small>.pdf</Small>
              </div>
            </div>
          </div>
          <div className={DocumentReceiverTheme.verticalLine} />

          <div className={DocumentReceiverTheme.fileWrapperIconContainer}>
            <img src="images/icons/receiver-asset-pdf-icon.png" />
          </div>
        </div>

        <img
          src="images/icons/blue-asset-download-icon.png"
          className={DocumentReceiverTheme.IODownloadIcon}
        />
      </a>
    )
  }
}

export default IOMessage
