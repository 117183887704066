import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { P, H4 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { MobileMarketingContext } from './MobileMarketingContext'

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px 19px 24px',
    width: '100%',
    height: 109,
    backgroundColor: COLOURS.coralLight,
    marginBottom: 32,
  },
  alert: {
    backgroundColor: `${COLOURS.lightYellow}40`, // 40% opacity -BK
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  exclamationCircle: {
    width: 22,
    height: 20,
    marginRight: 15,
    marginTop: 14,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    color: '#313D4B !important',
  },
  title: {
    marginTop: 16,
    marginBottom: 8,
  },
  text: {
    marginBottom: 22,
  },
  close: {
    width: 13,
    height: 12,
    marginTop: 16,
    cursor: 'pointer',
  },
}

function BannerCloseable(props) {
  const {
    buttonLabel,
    onClick,
    text,
    title,
    alertAndHide,
    classes: css,
    bannerKey,
    buttonToLink,
  } = props
  const [open, setOpen] = useState(false)
  // To enable postEntry to be published from the banner
  // const { showModal, smsSend: { status } } = React.useContext(MobileMarketingContext)

  useEffect(() => {
    let bannerClosed
    try {
      bannerClosed = localStorage.getItem(bannerKey)
    } catch (e) {
      // error
    }
    if (bannerClosed) return
    setOpen(true)
  }, [bannerKey, setOpen])

  const onClose = () => {
    try {
      localStorage.setItem(bannerKey, true)
    } catch (e) {
      // error
    }
    setOpen(false)
  }

  const onLabelClick = () => {
    if (alertAndHide) onClose()
    // if (status !== 'published') showModal('publish') .  // To publish from outside the contenxt
    onClick()
  }
  return open && (
    <div className={cn(css.wrapper, { [css.alert]: alertAndHide })}>
      <div className={css.container}>
        <img src="images/icons/warning.svg" className={css.exclamationCircle} />
        <div className={css.content}>
          <div className={css.title}>
            <H4>
              <i>{title}</i>
            </H4>
          </div>
          <div className={css.text}>
            <P charcoalDarker size={14}>
              {text}
            </P>
          </div>
          <P>
            <Link to={buttonToLink} onClick={onLabelClick}>{buttonLabel}</Link>
          </P>
        </div>
      </div>
      <img src="images/icons/close.svg" onClick={onClose} className={css.close} />
    </div>
  )
}

BannerCloseable.propTypes = {
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  alertAndHide: PropTypes.bool,
  bannerKey: PropTypes.string,
  buttonToLink: PropTypes.string.isRequired,
}

BannerCloseable.defaultProps = {
  buttonLabel: 'Button Label',
  onClick: () => {},
  text:
    'The Text to be displayed',
  title: 'The Title of the banner',
  alertAndHide: false,
  bannerKey: '',
}

export default React.memo(injectSheet(styles)(BannerCloseable))
