import React from 'react'
import { Card } from 'react-toolbox/lib/card'
import { P, H2 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import Uploader from 'components/util/editor-uploader'

class BrandRecommendationIngestion extends React.Component {
  processCSV = () => {
    toastr.success('Uploaded! Export to SF will take some time.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
  }

  render() {
    return (
      <Card style={{ padding: '24px' }}>
        <div style={{ marginBottom: '16px' }}>
          <H2 azure>
            Brand Recommendation Ingestion
          </H2>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <P multiline>
            { 'Upload a CSV to populate brand recommendation data in Salesforce' }
          </P>
          <P multiline>
            { 'Input File Header: brand_id, recommended_brand_id, recommended_brand_name, recommendation_ranking, confidence_label' }
          </P>
        </div>
        <div style={{ width: '108px' }}>
          <Uploader
            action="/data-admin/brand-recommendation-ingestion"
            success={this.processCSV}
            label="Upload CSV"
            acceptedFiles="text/csv"
          >
            <Button
              theme={buttonTheme}
              className={buttonTheme.greenButton}
              label="Upload CSV"
              raised
            />
          </Uploader>
        </div>
      </Card>
    )
  }
}



export default BrandRecommendationIngestion
