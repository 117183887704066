import _ from 'lodash'

export const load = (id) => {
  return {
    http: {
      url: `/campaign-tasks/${id}`,
    },
    types: [
      'LOADING_TASKS',
      'LOAD_TASKS_SUCCESS',
      'LOAD_TASKS_FAILURE',
    ],
  }
}

export const loadBrandCampaigns = () => {
  return {
    http: {
      url: `/brand-tasks`,
    },
    types: [
      'LOAD_BRAND_TASKS',
      'LOAD_BRAND_TASKS_SUCCESS',
      'LOAD_BRAND_TASKS_FAILURE',
    ],
  }
}

export const loadPromoChannels = (inviteId) => {
  return {
    http: {
      url: `/promotional-units-for-invite/${inviteId}`,
    },
    types: [
      'LOADING_PROMO_CHANNELS',
      'LOADING_PROMO_CHANNELS_SUCCESS',
      'LOADING_PROMO_CHANNELS_FAILURE',
    ],
  }
}

export const create = (task) => {
  return {
    http: {
      url: `/campaign-tasks`,
      method: 'POST',
      data: task,
    },
    types: [
      'CREATING_TASK',
      'CREATING_TASK_SUCCESS',
      'CREATING_TASK_FAILURE',
    ],
  }
}

export const saveTask = (task) => {
  return {
    http: {
      url: `/campaign-tasks/${task.id}`,
      method: 'PUT',
      data: _.pick(task, ['title', 'description', 'due_date', 'completed_at']),
    },
    types: [
      'SAVING_TASK',
      'SAVING_TASK_SUCCESS',
      'SAVING_TASK_FAILURE',
    ],
  }
}

export const markComplete = (task) => {
  let data = {
    completed_at: task.completed_at ? null : new Date()
  }

  return {
    http: {
      url: `/campaign-tasks/${task.id}`,
      method: 'PUT',
      data: data
    },
    types: [
      'SAVING_TASK',
      'SAVING_TASK_SUCCESS',
      'SAVING_TASK_FAILURE',
    ],
  }
}

export const selectCampaign = (campaign, event) => {
  return {
    model: 'campaignTasks',
    type: 'SELECT_CAMPAIGN',
    data: {
      campaign: campaign,
      event: event
    }
  }
}

export const selectBrandCampaign = (campaign, event) => {
  return {
    model: 'brandTasks',
    type: 'SELECT_BRAND_CAMPAIGN',
    data: {
      campaign,
      event
    }
  }
}

export const deselectCampaign = () => {
  return {
    model: 'campaignTasks',
    type: 'DESELECT_CAMPAIGN',
  }
}

export const selectTask = (task, event) => {
  return {
    model: 'campaignTasks',
    type: 'SELECT_TASK',
    data: {
      task,
      event
    }
  }
}

export const deselectTask = () => {
  return {
    model: 'campaignTasks',
    type: 'DESELECT_TASK',
  }
}

export const currentTaskOpenModal = (data) => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      currentTask: Object.assign({}, data, {
        openDialog: true
      })
    }
  }
}

export const updateCurrentTask = (data) => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      currentTask: data,
    }
  }
}

export const update = (data) => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: data
  }
}

export const currentTaskCloseModal = () => {
  return {
    model: 'campaignTasks',
    type: 'EXIT_CURRENT_TASK',
  }
}

export const confirmDelete = (taskId) => dispatch => {
  dispatch({
    http: {
      url: `/campaign-tasks/${taskId}`,
      method: 'DELETE'
    },
    types: [
      'DELETING_TASK',
      'DELETING_TASK_SUCCESS',
      'DELETING_TASK_FAILURE',
    ],
  })
}

export const cancelDelete = () => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      currentTask: {
        confirmDelete: false
      }
    }
  }
}

export const toggleConfirmDelete = () => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      currentTask: {
        confirmDelete: true
      }
    }
  }
}

export const showMore = (date, shortEvents, longEvents, event) => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      showMore: {
        show: true,
        date: date,
        shortEvents: shortEvents,
        longEvents: longEvents,
        event
      },
    }
  }
}

export const hideShowMore = () => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      showMore: {
        show: false
      }
    }
  }
}

export const exportCalendar = (subscribeUrl, subscribeName) => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      subscribeUrl,
      subscribeName
    }
  }
}

export const closeSubscribeModal = () => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      subscribeUrl: false
    }
  }
}

export const exportTaskEvent = () => {
  return {
    model: 'campaignTasks',
    type: 'UPDATE_CAMPAIGN_TASK_ATTR',
    data: {
      selectedTask: {
        exportingTask: true
      }
    }
  }
}

export const toggleShowCampaignPartner = (partnerBrandId) => {
  return {
    model: 'campaignTasks',
    type: 'TOGGLE_SHOW_CAMPAIGN_PARTNER',
    data: {
      partnerBrandId
    }
  }
}

export const toggleShowCampaign = (campaignId) => {
  return {
    model: 'campaignTasks',
    type: 'TOGGLE_SHOW_CAMPAIGN',
    data: {
      campaignId
    }
  }
}
