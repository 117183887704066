import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { Button } from 'react-toolbox/lib/button'
import cn from 'classnames'

import COLOURS from 'util/colours'
import ClickAwayListener from 'util/components/click-away-listener'

import buttonTheme from 'css/themes/Buttons.css'

import { Small, P } from 'visual-components/util/texts'
import FormlessField from 'visual-components/util/inputs/FormlessField'
import { MobileMarketingContext } from '../MobileMarketingContext'

const styles = {
  modal: {
    position: 'absolute',
    padding: '16px 24px',
    zIndex: 10,
    border: `1px solid ${COLOURS.silver}`,
    borderRadius: 2,
    backgroundColor: 'white',
    left: 276,
  },
  input: {
    display: 'flex',
    marginTop: 8,
    '& [data-react-toolbox="input"]': {
      padding: '4px 0 4px',
      flex: 1,
      marginRight: 8,
      '& input': {
        height: 34,
      },
    },
    '& [class*=Input--hint]': {
      top: 12,
    },
    '& .formlessfield-sublabel': {
      position: 'relative',
      margin: 0,
    },
    '& span[class*=error]': {
      display: 'none',
    },
  },
  publishButton: {
    margin: 0,
    marginTop: 4,
  },
}

function EditCouponCode(props) {
  const {
    show,
    onClose,
    message,
    classes: css,
    isFollowup,
  } = props

  const {
    coupon_code,
    dirty,
  } = message

  const {
    setFollowupMessage,
    setMainMessage,
    saveChanges,
  } = React.useContext(MobileMarketingContext)

  const [value, setValue] = useState(coupon_code)

  const onUpdateCoupon = data => (isFollowup ? setFollowupMessage : setMainMessage)(data, message.id)

  const onSaveChanges = () => {
    onUpdateCoupon({ coupon_code: value })
    saveChanges().then(onClose)
  }

  useEffect(() => {
    setValue(coupon_code)
  }, [coupon_code, show])

  if (!show) return null

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={css.modal}>
        <P>
          <em>Coupon Code</em>
        </P>
        <div className={css.input}>
          <FormlessField
            name="coupon_code"
            value={value}
            formatter={val => val && val.replace(/[^0-9A-Za-z]/g, '').toUpperCase()}
            dirty={dirty.coupon_code}
            error={!value && 'Required'}
            onChange={setValue}
            placeholder="SAMPLECODE"
          />
          <Button
            style={{ width: 'auto' }}
            disabled={!value}
            onClick={onSaveChanges}
            className={cn(buttonTheme.publishButton, css.publishButton)}
          >
            Update
          </Button>
        </div>
        <Small multiline>It may take up to 24 hours for your analytics to be updated.</Small>
      </div>
    </ClickAwayListener>
  )
}

EditCouponCode.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isFollowup: PropTypes.bool,
}

EditCouponCode.defaultProps = {
  isFollowup: false,
}

export default React.memo(injectCSS(styles)(EditCouponCode))
