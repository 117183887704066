import React, { Component } from 'react';
import SweepstakesBanner from './sweepstakes-banner';
import ImgAndContent from '../util/img-and-content'
import StartGrowingYourList from '../util/start-growing-your-list';
import WhatTheySay from '../util/what-they-say';
import JoinBrands from '../util/join-brands';
import MobileFooter from '../mobile-footer/MobileFooter';

import theme from 'css/themes/mobile/home/sweepstakes.css';

class MobileSweepstakes extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  render() {
    return(
      <div>
        <SweepstakesBanner />
        <div className={ theme.top3 }>
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/brand-cards.png' }
            imgClass={ 'brand-cards' }
            title={ '1000s of Partners' }
            explain={ 'No more digging through LinkedIn for hours to find brand and prizing partners. DojoMojo puts marketing decision-makers at your fingertips.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/wsiwyg-editor-on-hover.png' }
            imgClass={ 'wsiwyg-editor-on-hover' }
            title={ 'No Developer? No Problem.' }
            explain={ 'Build a fully-customized landing page in minutes, no designer required.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/analytics-cards.png' }
            imgClass={ 'analytics-cards' }
            title={ 'Measure Results' }
            explain={ 'Our algorithm analyzes thousands of data points to help you understand the most powerful levers for audience growth on a campaign and historical basis.' }
          />
        </div>
        <StartGrowingYourList />
        <WhatTheySay
          quote={ '“DojoMojo has taken standard audience-building campaigns that we\'ve run in the past and multiplied their effects, enabling us to work with amazing brands on incredible campaigns, and allowing us to more than triple our list size in a little over a month. It looks like we\'ll be able to triple again within the next two months!”' }
          who={ 'Leif Frey' }
          position={ 'Head Gent of Direction' }
          where={ 'FREY' }
          link={ 'https://www.frey.com/' }
          brandImg={ '/images/brand-logos/frey-logo-6-29-black-copy-01.svg' }
          title={ 'What Marketers Are Saying' }
        />
        <JoinBrands />
        <p className={ theme.featuresTitle }>
          Features
        </p>

        <div className={ theme.imgsContentContainers }>
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/french-alps-sweepstakes.png' }
            imgClass={ 'french-alps-sweepstakes' }
            title={ 'Grow Your Email List' }
            explain={ 'Partner with brands who’s audience you’d like to reach. When brands promote the sweepstakes to their audience, you can.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/social-sharing-screenshot.png' }
            imgClass={ 'social-sharing-screenshot' }
            title={ 'Expand your Social Reach' }
            explain={ 'Incentivize entrants with extra entries for following you on social media, sharing the sweepstakes with friends or downloading your app using Social Growth.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/custom-cta.png' }
            imgClass={ 'custom-cta' }
            title={ 'Drive Conversions' }
            explain={ 'Show entrants a special offer and send traffic directly into sales funnels with a Custom CTA after they enter to win.' }
          />
        </div>
        <StartGrowingYourList />
        <div className={ theme.imgsContentContainers }>
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/template-gallery.png' }
            imgClass={ 'template-gallery' }
            title={ 'Customizable Templates' }
            explain={ 'Choose from one of our easy to customize, optimized for mobile templates, add images and text and you’re ready to go. Simple editing tools let anyone on your team create a landing page in minutes.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/campaign-profile.png' }
            imgClass={ 'campaign-profile' }
            title={ 'Explore Campaigns' }
            explain={ 'Not ready to host your own? Apply to join campaigns looking for partners like you.' }
          />
        </div>
        <div className={ theme.focusSection }>
          <p className={ theme.title }>
            A Focus on Quality
          </p>
          <p className={ theme.explain }>
            We partner with Webbula, the world leader in email verification, to keep your list safe. We’ll always share our best practices for keeping your email list quality high.
          </p>
        </div>
        <div className={ theme.imgsContentContainers }>
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/campaign-analytics.png' }
            imgClass={ 'campaign-analytics' }
            title={ 'Powerful Analytics' }
            explain={ 'Our detailed dashboards help you understand which themes and partners resonate most with your audience and ensure you’re partnering with strong performers.' }
          />
          <ImgAndContent
            imgSrc={ '/images/login/mobile/sweepstakes/terms-and-conditions-generator.png' }
            imgClass={ 'terms-and-conditions-generator' }
            title={ 'Trust and Transparency' }
            explain={ 'From minimum sweepstakes entry requirements for partners to security verification and ESP integration, we’re constantly looking for ways to keep partnership and email list quality high.' }
          />
        </div>
        <MobileFooter />
      </div>
    )
  }
}

export default MobileSweepstakes;
