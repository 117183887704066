/*

Pass in onClick and label for left-side and right-side buttons

*/

import { Component } from 'react'
import { H6 } from 'visual-components/util/texts'
import { Card } from 'react-toolbox/lib/card'
import Row from 'visual-components/util/no-padding-grid/row'
import RadioButtonTheme from 'css/themes/radio-button.css'

class RadioButton extends Component {
  state = {
    leftActive: true,
    rightActive: false
  }

  toggleActive = () => {
    this.setState({ 
      leftActive: !this.state.leftActive, 
      rightActive: !this.state.rightActive
    })
  }

  onLeftClick = () => {
    this.props.leftOnClick()
    this.toggleActive()
  }

  onRightClick = () => {
    this.props.rightOnClick()
    this.toggleActive()
  }

  render() {
    const { 
      leftLabel, 
      rightLabel 
    } = this.props

    const { 
      leftActive, 
      rightActive 
    } = this.state
  

    return(
      <Card className={RadioButtonTheme.container}>
        <Row>
          <button className={leftActive ? RadioButtonTheme.leftButtonActive : RadioButtonTheme.leftButton} 
            onClick={!leftActive ? this.onLeftClick : undefined}
          >
            <div className={RadioButtonTheme.buttonLabel}>
              <H6 inactive={!leftActive} azure={leftActive}>
                {leftLabel}
              </H6>
            </div>
          </button>

          <button className={rightActive ? RadioButtonTheme.rightButtonActive : RadioButtonTheme.rightButton}  
            onClick={!rightActive ? this.onRightClick : undefined}
          > 
            <div className={RadioButtonTheme.buttonLabel}>
              <H6 inactive={!rightActive} azure={rightActive}>
                {rightLabel}
              </H6>
            </div>
          </button>
        </Row>
      </Card>
    )
  }

}


export default RadioButton