import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import { Card } from 'react-toolbox/lib/card';
import { H3 } from 'visual-components/util/texts';

import BrandDegreeTooltip from 'visual-components/util/tooltip/brand-degree-tooltip';
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';

class ProfileSideCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      title,
      tooltip,
      degree,
      degreeText,
      children
    } = this.props;

    return(
      <div style={{
        maxWidth: '216px', 
        position: 'relative'
      }}>
        <Card style={{
          width: '100%',
          paddingTop: '20px',
          paddingRight: '16px',
          paddingBottom: '20px',
          paddingLeft: '16px'
        }}>
          <div style={{marginBottom: '15px'}}>
            <H3 coral>
              <small>
                { title }
                {
                  tooltip || degree ?
                  <div style={{
                    borderRadius: '100%',
                    width: '20px',
                    height: '20px',
                    textAlign: 'center',
                    display: 'inline-block',
                    marginLeft: '8px'
                  }}>
                    {
                      tooltip ?
                      <BlueInfoTooltip text={ tooltip } />
                      :
                      <BrandDegreeTooltip
                        imgSrc={ degree }
                        text={ degreeText }
                      />
                    }
                  </div>
                  : null
                }
              </small>
            </H3>
          </div>
          { children }
        </Card>
      </div>
    )
  }
}

ProfileSideCard.propTypes = {
  title: PropTypes.any.isRequired,
  tooltip: PropTypes.any,
  degree: PropTypes.string,
  degreeText: PropTypes.string,
  children: PropTypes.element.isRequired
}

export default ProfileSideCard;
