import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'

import { Card } from 'react-toolbox/lib/card'
import CurrentCampaignCardTheme from 'css/themes/CurrentCampaignCard.css'
import { Tiny, H3, H4 } from 'visual-components/util/texts'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import Badge from 'visual-components/util/badge'

import CampaignSpecs from './campaign-specs'
import ParticipantsLogos from './participants-logos'

class CurrentCampaignCard extends React.Component {
  render() {
    const {
      campaignId,
      name,
      campaignPictureSrc,
      estimatedEntries,
      entries,
      ntfEmails,
      startDate,
      endDate,
      hostBrandImage,
      style,
      started,
      partnerImages,
      tasksRemaining,
      totalTasks,
    } = this.props

    const tasksPercentageCompleted = (totalTasks - tasksRemaining) / totalTasks
    const isVideo = campaignPictureSrc && campaignPictureSrc.endsWith('.mp4')

    return (
      <Card className={CurrentCampaignCardTheme.mainContainer}>
        {
          started
            ? (
              <div className={CurrentCampaignCardTheme.startedBadgeContainer}>
                <Badge label="Live" color={COLOURS.coral} />
              </div>
            ) : null
        }
        <Grid style={{ marginLeft: '0' }}>
          <Link to={`/partnerships/campaign/${campaignId}/quickview`}>
            <Row>
              <div
                className={CurrentCampaignCardTheme.headerDisplay}
                style={{
                  background: `url(${campaignPictureSrc})`,
                  backgroundPosition: `0% ${`${style.y ? 100 - style.y : 0}%`}`,
                  position: 'relative',
                  backgroundSize: '100%',
                  overflow: 'hidden',
                }}
              >
                <div className={CurrentCampaignCardTheme.coverGradient} />
                { isVideo ? (
                  <video key={campaignPictureSrc} style={{ minWidth: '480px', height: '176px', objectFit: 'cover' }} preload="metadata" muted>
                    <source src={campaignPictureSrc} type="video/mp4" />
                  </video>
                ) : null }
                <div className={CurrentCampaignCardTheme.campaignTitle}>
                  <H4 multiline white>
                    <b>
                      { name }
                    </b>
                  </H4>
                </div>
              </div>
            </Row>
          </Link>

          <Row className={CurrentCampaignCardTheme.participantLogosContainer}>
            <ParticipantsLogos hostBrandImage={hostBrandImage} partnerImages={partnerImages}/>

            {/*campaign stats */}
            <div className={CurrentCampaignCardTheme.campaignStatsContainer}>
              <CampaignSpecs
                startDate={startDate}
                endDate={endDate}
                estEntries={estimatedEntries}
                totalEntries={entries}
                ntfEmails={ntfEmails}
                campaignId={campaignId}
              />
            </div>
          </Row>

          {/*task visualizer*/}
          <Row className={CurrentCampaignCardTheme.taskVisualizerContainer}>

            {/*task bar*/}
            <Col>
              <div className={CurrentCampaignCardTheme.taskBarOuter}>
                {/*status meter */}
                <div
                  className={CurrentCampaignCardTheme.taskBarInner}
                  style={{ width: `${368 * tasksPercentageCompleted}px` }}
                />
              </div>
            </Col>

            {/*tasks remaining */}
            <Col className={CurrentCampaignCardTheme.tasksRemainingContainer}>
              <H3 azure>
                { tasksRemaining }
              </H3>

              <div className={CurrentCampaignCardTheme.tasksLeft}>
                <Tiny>
                  { `task${tasksRemaining === 1 ? '' : 's'} left` }
                </Tiny>
              </div>
            </Col>
          </Row>
        </Grid>
      </Card>
    )
  }
}

CurrentCampaignCard.propTypes = {
  campaignId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  campaignPictureSrc: PropTypes.string.isRequired,
  estimatedEntries: PropTypes.number.isRequired,
  entries: PropTypes.number.isRequired,
  ntfEmails: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  hostBrandImage: PropTypes.string.isRequired,
  style: PropTypes.object,
  started: PropTypes.bool.isRequired,
  partnerImages: PropTypes.array,
  tasksRemaining: PropTypes.number,
  totalTasks: PropTypes.number,
}

CurrentCampaignCard.defaultProps = {
  style: {},
  partnerImages: [],
  tasksRemaining: 0,
  totalTasks: 1,
}

export default CurrentCampaignCard
