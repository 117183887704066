import React from 'react';
import { P } from 'visual-components/util/texts';

class InterestInput extends React.Component {
  render() {
    const {
      children,
      title,
      sub
    } = this.props;

    return (
      <div style={{
        marginTop: '18px'
      }}>
        <P style={{
          fontSize: '14px',
          fontFamily: 'Larsseit-Medium',
          display: 'inline-block',
          marginRight: '8px'
        }}>
          {title}
        </P>
        <P style={{
          fontSize: '12px',
          display: 'inline-block'
        }}>
          {sub}
        </P>
        {children}
      </div>
    )
  }
}
export default InterestInput
