import React, { Component } from 'react'
import { array, number, object } from 'prop-types'
import { withRouter } from 'react-router-dom'
import MessageImageTheme from 'css/themes/media-market/assetVisuals/MessageImage.css'
import { H2 } from 'visual-components/util/texts'

class ImageMapper extends Component {
  static propTypes = {
    history: object.isRequired,
    location: object.isRequired,
    images: array.isRequired,
    extraImagesCount: number.isRequired,
  }

  onImageClick = ({ id }) => () => {
    const { history, location } = this.props

    history.push(`${location.pathname}/${id}`)
  }

  onViewMoreClick = () => {
    const { images, history, location } = this.props

    const [firstItem] = images

    history.push(`${location.pathname}/${firstItem.id}`)
  }

  render() {
    const {
      images,
      extraImagesCount,
    } = this.props

    const styleByPosition = {
      0: MessageImageTheme.topLeft,
      1: MessageImageTheme.topRight,
      2: MessageImageTheme.bottomLeft,
      3: MessageImageTheme.bottomRight,
    }

    const displayFormat = images.map((image, idx) => (
      <div
        onClick={this.onImageClick(image)}
        key={image.id}
        style={{ backgroundImage: image.s3_url.startsWith('//') ? `url(https:${image.s3_url})` : `url(${image.s3_url})` }}
        className={`${MessageImageTheme.mapImageContainer} ${styleByPosition[idx]}`}
      />
    ))

    return (
      <div>
        <div className={MessageImageTheme.multipleImageDisplayFormat}>
          { displayFormat }
        </div>

        {
          extraImagesCount
            ? (
              <div onClick={this.onViewMoreClick} className={MessageImageTheme.overlay}>
                <div className={MessageImageTheme.overlayText}>
                  <H2 white>{`+${extraImagesCount}`}</H2>
                </div>
              </div>
            ) : null
        }

      </div>
    )
  }
}

export default withRouter(ImageMapper)
