import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { P, Tiny } from 'visual-components/util/texts'

import Loader from 'util/components/loader'
import SearchInput from 'visual-components/util/inputs/search-input'
import useImageSearch from 'hooks/useImageSearch'

import colors from 'util/colours'
import { connect } from 'react-redux'

const Checkmark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#14CBA8" />
      <path fill="#FFF" fillRule="nonzero" d="M9.9 14.54L6.96 11.6l-.98.98L9.9 16.5l8.4-8.4-.98-.98z" />
    </g>
  </svg>
)

const stateToProps = ({ currentBrand }) => ({ currentBrand })

const styles = {
  wrapper: {
    padding: '24px 8px',
    display: 'flex',
    flexFlow: 'column',
    height: 466,
    flex: 1,
  },
  subtext: {
    textAlign: 'right',
    marginTop: 4,
  },
  imagesPreview: {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    gridAutoRows: '128px',
    gridColumnGap: '4px',
    gridRowGap: '4px',
    overflowY: 'auto',
    position: 'relative',
    margin: '20px 0 0',
    flex: 1,
  },
  preview: {
    backgroundColor: colors.silver,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '& img': {
      width: '100%',
    },
  },
  spinner: props => {
    if (props.all) return null

    return {
      position: 'relative',
      gridColumn: '1/4',
    }
  },
  disclaimer: {
    padding: '12px 0 0',
    backgroundColor: 'white',
  },
  selected: {
    position: 'relative',
    border: `4px solid ${colors.seaGreen}`,
    borderRadius: 2,
  },
  checkmark: {
    position: 'absolute',
    top: 4,
    left: 4,
    height: 16,
    width: 16,
  },
}

const termsMap = {
  unsplash: {
    label: 'Unsplash',
    url: 'https://unsplash.com/terms',
  },
  pixabay: {
    label: 'Pixabay',
    url: 'https://pixabay.com/service/terms/',
  },
}

const disclaimerText = provider => {
  if (!provider) return null

  return (
    <P multiline>
      By clicking &ldquo;Insert,&rdquo; you are agreeing to
      {' '}
      <a href={termsMap[provider].url} rel="noopener noreferrer">
        {termsMap[provider].label}
        &apos;s Terms
      </a>
      {' '}
      and you understand that you are publishing the image and are responsible for it. This image is
      provided by a third party called
      {' '}
      {termsMap[provider].label}
; it is not provided by DojoMojo.
    </P>
  )
}

const POWERED_BY = (
  <Tiny>
    <strong>
      Powered by
      {' '}
      <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
        Unsplash
      </a>
      {' '}
      and
      {' '}
      <a href="https://pixabay.com/" target="_blank" rel="noopener noreferrer">
        Pixabay
      </a>
    </strong>
  </Tiny>
)

function SearchImageSection(props) {
  const {
    classes: css, onMediaSelect, onScroll, onQueryChange, loading, images, query, selectedImage, setSelectedImage,
  } = props

  const onType = value => {
    setSelectedImage(null)
    onQueryChange(value)
  }

  const onSelect = img => {
    const selected = !selectedImage || selectedImage.id !== img.id ? img : null
    setSelectedImage(selected)
    onMediaSelect(selected)
  }

  return (
    <section className={css.wrapper}>
      <div>
        <SearchInput className="smallInput" value={query} onChange={onType} placeholder="Search Images" />
        <div className={css.subtext}>{POWERED_BY}</div>
      </div>
      <div onScroll={onScroll} className={css.imagesPreview}>
        {images.length > 0
          && images.map(image => {
            const selected = selectedImage && selectedImage.id === image.id
            if (image.type === 'pixabay') return null
            return (
              <div
                key={image.id}
                className={cn(css.preview, { [css.selected]: selected })}
                onClick={() => onSelect(image)}
              >
                <img loading="lazy" src={image.src} alt="preview" />
                {selected && <Checkmark className={css.checkmark} />}
              </div>
            )
          })}
        {loading && (
          <div className={css.spinner}>
            <Loader />
          </div>
        )}
      </div>
      {selectedImage !== null && (
        <div className={css.disclaimer}>{disclaimerText(selectedImage.type)}</div>
      )}
    </section>
  )
}

SearchImageSection.propTypes = {
  onMediaSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onScroll: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  selectedImage: PropTypes.object.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
}

const StyledSearchImageSection = injectCSS(styles)(SearchImageSection)

function SearchImageSectionWrapper(props) {
  const { currentBrand } = props
  const [selectedImage, setSelectedImage] = useState(null)
  const hookProps = useImageSearch({ headers: { brand: currentBrand.id } })

  return (
    <StyledSearchImageSection
      {...{ ...props, ...hookProps }}
      all={!hookProps.images.length}
      selectedImage={selectedImage}
      setSelectedImage={setSelectedImage}
    />
  )
}

SearchImageSectionWrapper.propTypes = {
  currentBrand: PropTypes.object.isRequired,
}

export default React.memo(connect(stateToProps)(SearchImageSectionWrapper))
