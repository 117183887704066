import { useState, useEffect } from 'react'

const typeFormId = process.env.NODE_ENV === 'production' ? 'hKzzVhQ4' : 'VbTS4BgL'
const smsVerifyFormUrl = `https://research.typeform.com/to/${typeFormId}`

/**
 * Hook to check if SMS verification is needed - may change drastically with september Bandwidth
 * verification deadline
 */
const useSmsVerify = () => {
  const { currentBrand, profile: userProfile } = store.getState()

  const brandId = currentBrand.id
  const userId = userProfile.id

  const key = `smsVerifyModalNeeded-${brandId}`

  const [isSmsVerifyModalNeeded, setIsSmsVerifyModalNeeded] = useState(false)

  useEffect(() => {
    const modalNeeded = localStorage.getItem(key)
    if (modalNeeded) {
      setIsSmsVerifyModalNeeded(true)
    }
  })

  const dismissSmsVerifyModal = () => {
    localStorage.removeItem(key)
    setIsSmsVerifyModalNeeded(false)
  }

  const openVerifyLink = () => {
    window.open(`${smsVerifyFormUrl}#brand_id=${brandId}&user_id=${userId}`, '_blank')
    setIsSmsVerifyModalNeeded(false)
  }

  const setSmsVerifyFlag = () => {
    localStorage.setItem(key, 'true')
    setIsSmsVerifyModalNeeded(true)
  }

  return {
    isSmsVerifyModalNeeded,
    dismissSmsVerifyModal,
    openVerifyLink,
    setSmsVerifyFlag,
  }
}

export default useSmsVerify
