import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Dialog from 'react-toolbox/lib/dialog'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'


import { H4 } from 'visual-components/util/texts'
import createSrcSet from 'util/create-srcset'
import { getPlanLabel } from 'util/brand-stripe-belt-helper'
import theme from 'css/themes/login/extendedTrialTheme.css'

import dialogTheme from 'css/themes/dialogs/trialEndingDialog.css'

const mapState = ({
  trialExtendedModal,
  currentBrand,
}) => ({ trialExtendedModal, currentBrand })


class trialExtendedModal extends Component {
  dismissPaywall = () => {
    sessionStorage.setItem('extendedTrialModalShown', true)

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'trialExtendedModal',
      data: {
        open: false,
      },
    })
  }

  upgradePlan = () => {
    // TODO: bulk up this security -- anyone can currently just delete
    // the element from the DOM to bypass the paywall. Perhaps v2-app
    // should abort render() or something. Backend stuff needed too.


    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: 'trialExtendedModal'
      }
    })

    this.dismissPaywall()
    BrowserHistoryDispatcher.push('/upgrade-plan')
  }

  render() {
    const {
      currentBrand,
      trialExtendedModal: { open, hasDefaultSource },
    } = this.props

    const extendedEndDate = new Date(currentBrand.extended_trial_end)
    const originalEndDate = new Date(currentBrand.original_trial_end)

    const oneDay = 86400000 // 24*60*60*1000 ... hours*minutes*seconds*milliseconds
    const extendedDays = Math.round( Math.abs( ( extendedEndDate.getTime() - originalEndDate.getTime() )/( oneDay ) ) );

    return (
      <Dialog
        active={!!open}
        onEscKeyDown={null}
        onOverlayClick={null}
        theme={dialogTheme}
        title={
          hasDefaultSource
            ? 'Your Premium trial has been extended.'
            : 'Let’s extend your free trial'
        }
      >
        <Grid>
          {!hasDefaultSource && (
            <Row>
              <Col>
                <div className={theme.bannerImageContainer} >
                  <img
                    src="/images/pricing/tiger_waving.png"
                    srcSet={createSrcSet('/images/pricing/tiger_waving.png')}
                    className={theme.bannerImage}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {!hasDefaultSource ? (
                <H4 multiline>
                  {`We are happy to extend your ${getPlanLabel(currentBrand.belt)} trial ${extendedDays} days to ${moment(extendedEndDate).format('l')}. `}
                  To confirm your extension, you will need to choose one of our
                  plans and update your payment method.
                  <br />
                  You won't be charged until the trial ends. If you aren't
                  interested in one of our paid plans, please email{' '}
                  <a href="mailto:support@dojomojo.com">
                    support@dojomojo.com
                  </a>
                  {' '}before your trial ends.
                </H4>
              ) : (
                <div className={theme.textRow}>
                  <H4 multiline>
                    You have selected the {getPlanLabel(currentBrand.belt)} plan. You won't be charged
                    until the trial ends and can cancel by emailing{' '}
                    <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
                    {' '}before your trial ends.
                  </H4>
                </div>
              )}
            </Col>
          </Row>
          <ModalButtonGroup
            canSave
            confirm={hasDefaultSource ? this.dismissPaywall : this.upgradePlan}
            hideLine
            confirmText={hasDefaultSource ? 'Got it!' : 'Continue'}
            cancel={this.dismissPaywall}
            cancelText={hasDefaultSource ? null : 'Cancel'}
          />
        </Grid>
      </Dialog>
    )
  }
}

export default connect(mapState)(trialExtendedModal)
