import React from 'react';
import * as ReactRedux from 'react-redux';

import Text from 'util/components/text';
import Spinner from 'util/components/spinner';
import PrimaryButton from 'util/components/buttons/primary-button';
import AppDispatcher from 'dispatchers/app-dispatcher';
import { Checkbox } from 'react-toolbox'

class CustomerSuccessAdminCsvExport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      campaignName: 'No Campaign Found',
      brandName: 'No Brand Found',
      email: '',
      includeBonus: false,
      oneZip: false,
      directDownload: false
    }

    this.exportCsv = this.exportCsv.bind(this);
    this.fetchCampaignInfo = this.fetchCampaignInfo.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  toggleOneZip = () => {
    this.setState({
      oneZip: !this.state.oneZip
    })
  }

  toggleDirectDownload = () => {
    this.setState({
      directDownload: !this.state.directDownload
    })
  }

  toggleIncludeBonus = () => {
    this.setState({
      includeBonus: !this.state.includeBonus
    })
  }

  exportCsv() {
    let that = this;
    this.setState({
      loading: true
    })

    AppDispatcher.http({
      url: '/cs-campaign-export-results',
      method: 'POST',
      data: {
        campaignId: parseInt(this.state.campaignId),
        email: this.state.email,
        brandId: this.state.brandId,
        oneZip: this.state.oneZip,
        includeBonus: this.state.includeBonus,
        directDownload: this.state.directDownload,
      },
      success: function(response) {
        that.setState({
          loading: false
        })

        toastr.success('CSV Sent', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      },
      error: function(err) {
        that.setState({
          loading: false
        })

        toastr.error('Failed to send CSV');
      }
    })
  }

  fetchCampaignInfo() {
    let that = this;
    window.typingTimer = setTimeout(function() {
      if (that.state.campaignId || that.state.brandId) {
        AppDispatcher.http({
          url: `/cs-campaigns/${that.state.campaignId}`,
          success: function(response) {
            let foundInvite = response.invites.find(function(invite) {
              return invite.invitee_id == that.state.brandId && invite.status == 'accepted';
            });

            that.setState({
              campaignName: response ? response.name : 'No Campaign Found',
              brandName: foundInvite ? foundInvite.invitee.accountname : 'No Brand Found',
              brandId: foundInvite ? foundInvite.invitee_id : null
            })
          },
          error: function(err) {
            that.setState({
              campaignName: 'No Campaign Found',
              brandName: 'No Brand Found'
            })
          }
        })
      }
    }, 500)
  }

  onchange(e) {
    clearTimeout(window.typingTimer);
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    window.typingTimer;
    let foundPartnership = this.state.campaignName != 'No Campaign Found' && this.state.brandName != 'No Brand Found';

    if (this.state.loading) {
      return (
        <Spinner />
      );
    }

    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Export Entries CSV
          </Text.dojoH2Bold>
          <form className="col-xs-12">
            <div className="row">
              <div className="col-xs-6" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Campaign ID
                  </Text.dojoP>
                  <input type="number" name="campaignId" className="col-xs-12" onChange={ this.onchange } onKeyUp={ this.fetchCampaignInfo }/>
                </div>
              </div>
              <div className="col-xs-6" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Brand ID
                  </Text.dojoP>
                  <input type="number" name="brandId" className="col-xs-12" onChange={ this.onchange } onKeyUp={ this.fetchCampaignInfo }/>
                </div>
              </div>
              <div className="col-xs-12" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Email
                  </Text.dojoP>
                  <input type="email" name="email" className="col-xs-12" onChange={ this.onchange } />
                </div>
              </div>
              <div className="col-xs-12" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <div className="col-xs-6" style={{
                    marginTop: '20px'
                  }}>
                    <div className="row">
                      <Checkbox
                        label="One Zip File"
                        checked={ this.state.oneZip }
                        onChange={this.toggleOneZip}
                      />
                    </div>
                  </div>
                  <div className="col-xs-6" style={{
                    marginTop: '20px'
                  }}>
                    <div className="row">
                      <Checkbox
                        label="Include Bonus"
                        checked={ this.state.includeBonus }
                        onChange={this.toggleIncludeBonus}
                      />
                    </div>
                  </div>
                  <div className="col-xs-6" style={{
                    marginTop: '20px'
                  }}>
                    <div className="row">
                      <Checkbox
                        label="Direct Download Link"
                        checked={ this.state.directDownload }
                        onChange={this.toggleDirectDownload}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <PrimaryButton
                onClick={ this.exportCsv }
                isDisabled={ !foundPartnership || !this.state.email }
                styles={[{
                  width: '220px',
                  height: '50px',
                  marginTop: '20px'
              }]}>
                Send
              </PrimaryButton>
            </div>
          </form>
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Campaign Name: { this.state.campaignName }
              </Text.dojoH3Bold>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Brand Name: { this.state.brandName }
              </Text.dojoH3Bold>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoP>
                Email: { this.state.email }
              </Text.dojoP>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var mapState = function(state) {
  return state;
}

export default ReactRedux.connect(mapState)(CustomerSuccessAdminCsvExport);
