/**
 * rangeDefaults - generates default values for a range filter in a search model.
 */
export const rangeDefaults = ({ term = null, terms = [], ...rest }) => ({
  // name of key in Elasticsearch
  term,
  terms,
  // selected low and high ends of range
  value: {
    min: 0,
    max: null,
  },

  // indicates to Elasticsearch when at min/max possible values
  // (in which case the boundaries can be dropped from the final search query)
  atMax: true,
  atMin: true,

  // spread miscellaneous options
  ...rest,
})

/**
 * radioDefaults - generates default values for a radio filter in a search model.
 */
export const radioDefaults = ({ term, options, ...rest }) => ({
  // name of key in Elasticsearch
  term,

  // value defaults to first option's value if not specified
  value: options[0].value,

  // array of options ({ label, value })
  options,

  ...rest,
})

/**
 * checkboxDefaults - generates default values for a checkbox filter in a search model
 */
export const checkboxDefaults = ({ term, ...rest }) => ({
  // name of key in Elasticsearch
  term,

  // defaults all to true if not specified
  value: true,

  // defaults some to false (indeterminate)
  some: false,

  // hides result count
  excludeCount: false,

  // options initialized to empty array if not specified
  // (usually populated by Elasticsearch facets)
  options: [],

  ...rest,
})

// helper function to map selected keys from facet options
const mapSelectedKeys = options =>
  !options ? [] : options.filter(({ value }) => value).map(({ key }) => key)

// Generates default ElasticSearch query
export const defaultQuery = ({
  page = 1,
  nestedFilter,
  sources,
  inputName,
  aggs,
  state,
  getState,
}) => {
  const { size, sortBy, searchValue } = state
  const from = (page - 1) * size
  const sort = []

  if (sortBy && sortBy.value) {
    const predicate = { order: sortBy.asc ? 'asc' : 'desc' }

    // Check for nested terms
    const parts = sortBy.value.split('.')

    // Nested
    if (parts.length > 1 && parts[1] !== 'keyword') {
      predicate.mode = 'min'
      predicate.nested_path = parts[0]
      predicate.nested_filter = nestedFilter
    }

    sort.push({ [sortBy.value]: predicate })
  }

  sort.push('_score')

  const should = []
  const bool = {
    must: [{ bool: { should } }],
    should: [],
    filter: [],
  }

  if (searchValue) {
    inputName.forEach(input => {
      const searchTerm = `*${searchValue
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '*')}*`

      // Check for nested terms
      const parts = input.split('.')

      // Not nested
      if (parts.length === 1 || parts[1] === 'search') {
        const fuzzyTerms = ['accountname', 'hostBrandName', 'brandName']
        const getElasticType = term => ({
          name: 'match',
          description: 'match',
          meta: 'match',
          'industry.search': 'match_phrase',
        }[term] || 'wildcard')

        const elasticType = getElasticType(input)
        const isMatchType = elasticType.includes('match')
        const valueType = isMatchType ? 'query' : 'value'

        should.push({
          [elasticType]: {
            [input]: {
              [valueType]: isMatchType ? searchValue : searchTerm,
              boost: fuzzyTerms.includes(input) ? 3 : 1,
            },
          },
        })

        if (fuzzyTerms.includes(input)) {
          should.push({
            match: {
              [input]: {
                query: searchValue,
                boost: 5,
              },
            },
          })
          if (searchValue.length >= 6) {
            should.push({
              fuzzy: {
                [input]: {
                  value: searchValue,
                },
              },
            })
          }
        }
      } else {
        // Nested
        should.push({
          nested: {
            path: parts[0],
            query: {
              wildcard: {
                [input]: searchTerm,
              },
            },
          },
        })
      }
    })
  }

  // Geo Filter
  const { geoMinAudience, regions } = state

  if (geoMinAudience && geoMinAudience.value > 0 && regions) {
    const states = []
    regions.options.forEach(region =>
      region.options.forEach(state => {
        if (state.value) {
          states.push(
            `(doc.containsKey('state_distribution.${
              state.key
            }') ? doc['state_distribution.${state.key}'].value : 0)`
          )
        }
      })
    )
    const inline = `${states.join(' + ') || '0'} >= ${geoMinAudience.value}`
    bool.must.push({ script: { script: { inline } } })
  }

  /***** MAIN QUERY ****/
  return {
    from,
    size,
    sort,
    ...(sources && { _source: sources }),
    query: { bool },
    aggs: typeof aggs === 'function' ? aggs(getState) : aggs,
    post_filter: { bool: {} },
    explain: process.env.SENTRY_ENV !== 'production',
  }
}

// Generic function to apply a range filter
export const applyRange = ({ filterName, nestedFilter, state, body }) => {
  if (!filterName || !state) {
    return
  }

  let {
    term,
    terms,
    value,
    value: { min, max },
    atMin,
    atMax,
    transform = parseFloat,
    minDefault = null,
    maxDefault = null,
    hidden = null,
  } = state[filterName]

  // accepts min/max value vs. single value
  const minMaxValues = typeof value === 'object'

  if (
    (!term && terms.length !== 2) ||
    typeof value === 'undefined' ||
    (minMaxValues && atMin && atMax) ||
    hidden
  ) {
    return
  }

  const minMaxTerms = terms && terms.length === 2
  const { filter, must } = body.query.bool

  const gte = minMaxValues
    ? !atMin && min ? transform(min) : minDefault
    : transform(value)

  const lte = minMaxValues
    ? !atMax && max ? transform(max) : maxDefault
    : transform(value)

  // if min & max terms specified, range is based on range overlap
  if (minMaxTerms) {
    const [minTerm, maxTerm] = terms
    filter.push(
      {
        range: { [maxTerm]: { gte } },
      },
      {
        range: { [minTerm]: { lte } },
      }
    )

    return
  }

  // otherwise based on single term
  const query = {
    range: {
      [term]: {
        gte,
        lte: minMaxValues ? lte : null, // ignore lower bound when based on one value
      },
    },
  }

  // Check for nested terms
  const parts = term.split('.')

  // Not nested
  if (parts.length === 1) {
    filter.push(query)
  } else {
    // If nested -- config should implement nestedFilter
    must.push({
      nested: {
        path: parts[0],
        query: {
          bool: {
            must: [
              {
                range: query.range,
              },
            ],
            filter: nestedFilter,
          },
        },
      },
    })
  }
}

// Generic function to apply facet
export const applyFacet = ({ facetName, state, body, limitedFacets }) => {
  if (!facetName || facetName === 'partnershipHistory') {
    return
  }

  const { value, options, term } = state[facetName]

  if (!value && options) {
    const { post_filter, aggs } = body
    const must = (post_filter.bool.must = post_filter.bool.must || [])

    const mappedKeys = mapSelectedKeys(options)

    if (limitedFacets.includes(facetName) && mappedKeys.length === 0) return

    const terms = { [term]: mappedKeys }

    must.push({ terms })

    // apply filter to partnership history facet as well
    if (aggs && aggs.partnershipHistory) {
      const { filters } = aggs.partnershipHistory.filters
      ;['1st', '2nd', '3rd'].forEach(deg => {
        filters[deg].bool.must.push({ terms })
      })
    }
  }
}

// Generic function to process facets
export const processFacet = ({ results, data, field, state }) => {
  const { aggregations } = data
  const { term, options, value, some, labelMap, excludeCount } = state[field]

  const includedOptions = mapSelectedKeys(options)

  const buckets =
    aggregations[field].buckets || aggregations[field][field].buckets

  const facet = (results[field] = { value, term, some, labelMap, excludeCount })

  // regular facet (eg. industries)
  if (Array.isArray(buckets)) {
    facet.options = buckets.map(({ key, doc_count }) => ({
      key,
      doc_count,
      value: value || includedOptions.includes(key),
    }))
  } else {
    // computed facet (eg. degree of separation)
    facet.options = options.map(({ key, doc_count, ...rest }) => ({
      key,
      doc_count: buckets[key].doc_count,
      ...rest,
    }))
  }
}
