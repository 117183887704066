import React, { Component } from 'react'
import { array, object } from 'prop-types'
import { Small } from 'visual-components/util/texts'
import AssetsTheme from 'css/themes/media-market/Assets.css'
import ImageWrapper from './image-wrapper'

class AssetsImagesWindow extends Component {
  static propTypes = {
    unreadCount: object,
    assetsByType: array.isRequired,
  }

  static defaultProps = {
    unreadCount: {},
  }

  render() {
    const {
      assetsByType,
      unreadCount,
    } = this.props
    const unreadAssetCounts = unreadCount && unreadCount.attachmentComments
      ? unreadCount.attachmentComments
      : {}

    return (
      <div className={`${AssetsTheme.windowContainer} ${AssetsTheme.imagesWindow}`}>
        <div className={AssetsTheme.imagesGroup}>
          { assetsByType && assetsByType.length
            ? assetsByType.map(item => (
              <ImageWrapper
                key={item.id}
                item={item}
                isUnread={unreadAssetCounts[item.id] > 0}
              />
            ))
            : (
              <div className={AssetsTheme.emptyState}>
                <Small multiline cloudGrey>
                  No Images Available.
                </Small>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default AssetsImagesWindow
