import React from 'react';
import * as ReactRedux from 'react-redux';
import { H4 } from 'visual-components/util/texts';
import SingleImageConfirmationModal from './single-image-confirmation-modal';

var mapState = function (state) {
  return {
    modal: state.confirmationModal,
    currentBrand: state.currentBrand
  };
}


class NewUserReferredCampaignModal extends React.Component {

  render() {
    const {
      modal,
      currentBrand
    } = this.props;

    const referral = currentBrand.referral || {
      campaign: {},
      source_brand: {}
    };

    const imgSrc = '/images/mojo-thumbs-up/mojo-thumbs-up.png';

    return (
      <SingleImageConfirmationModal
        imgSrc={imgSrc}
        title={`Welcome! ${referral.source_brand.accountname} Wants to Work with You`}
        active={modal.active}
      >
        <H4 multiline>
          You’ve been invited by <b>{referral.source_brand.accountname}</b> to join <b>{referral.campaign ? referral.campaign.name : null}</b>! We’ve applied a $25 credit to your account, so click below to review the details, the sweepstakes, and accept their invitation.
        </H4>
      </SingleImageConfirmationModal>
    )
  }
};

export default ReactRedux.connect(mapState)(NewUserReferredCampaignModal);
