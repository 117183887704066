import React, { PureComponent as Component } from 'react'
import { arrayOf, instanceOf } from 'prop-types'
import cn from 'classnames'

import CalendarTheme from 'css/themes/calendar/calendar.css'

const renderBgCells = currentDate => (date, index) => {
  const offMonthRange = currentDate.getMonth() !== date.getMonth()
  const isToday = date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()
  const isFirstWeek = date.getDate() <= 10

  return (
    <div
      key={index}
      className={cn(
        isFirstWeek && CalendarTheme.isFirstWeek,
        CalendarTheme.calendarDayBg,
        date.getDate() === 1 && CalendarTheme.firstOfMonthBg,
        isToday && CalendarTheme.calendarToday,
        offMonthRange && CalendarTheme.calendarOffRangeBg
      )}
    />
  )
}

class BackgroundCells extends Component {
  static propTypes = {
    range: arrayOf(instanceOf(Date)),
  }

  static contextTypes = {
    date: instanceOf(Date),
  }

  render() {
    const {
      range,
    } = this.props

    const {
      date: currentDate,
    } = this.context

    const bgCellItems = range.map(renderBgCells(currentDate))

    return (
      <div className={CalendarTheme.calendarRowBg}>
        { bgCellItems }
      </div>
    )
  }
}

export default BackgroundCells
