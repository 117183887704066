import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import useFileUploader from 'util/hooks/useFileUploader'

import colors from 'util/colours'
import ImageUploader from 'components/util/image-uploader'

const styles = {
  dropzone: {
    outline: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    width: 200,
    height: 128,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // Adds the dashes to the box
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${encodeURIComponent(
      colors.cloudGrey
    )}' stroke-width='1' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    '& svg': {
      marginBottom: 24,
      height: 32,
      width: 32,
      '& path': {
        fill: `${colors.cloudGrey} !important`,
      },
    },
  },
  dragReject: {
    cursor: 'not-allowed !important',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

function ImageDropzoneUploader(props) {
  const {
    label, onImageUpload, onImageReject, maxSize, classes: css,
  } = props
  const [image, openFileUploader, clear, img64] = useFileUploader()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (image) {
      switch (true) {
        case maxSize && image.size > maxSize:
          onImageReject()
          break
        default:
          if (!img64) return
          onImageUpload({ image, img64 })
      }
    }

    clear()
  }, [image, img64, onImageUpload, clear, maxSize, onImageReject])

  const onClick = e => {
    e.stopPropagation()
    e.preventDefault()
    openFileUploader()
  }

  return (
    <ImageUploader
      maxSize={maxSize || undefined}
      accept={{
        'image/x-png': [],
        'image/gif': [],
        'image/jpeg': [],
      }}
      onDropAccepted={async ([file]) => {
        const image64 = await toBase64(file)
        onImageUpload({ image: file, img64: image64 })
        setLoading(false)
      }}
      onDropRejected={() => onImageReject()}
      onDrop={() => setLoading(true)}
      disabled={loading}
      onClick={onClick}
      dropzoneClassname={css.dropzone}
      label={label}
    />
  )
}

ImageDropzoneUploader.propTypes = {
  label: PropTypes.string,
  onImageUpload: PropTypes.func,
  onImageReject: PropTypes.func,
  maxSize: PropTypes.number,
  classes: PropTypes.object.isRequired,
}

ImageDropzoneUploader.defaultProps = {
  label: 'Upload Image/GIF',
  onImageUpload() {},
  onImageReject() {},
  maxSize: 450000, // 450kb
}

export default React.memo(injectCSS(styles)(ImageDropzoneUploader))
