import React from 'react'

import RangeFilter from 'visual-components/shared/filters/RangeFilter'
import CheckBoxFilter from 'visual-components/shared/filters/CheckBoxFilter'
import RadioFilter from 'visual-components/shared/filters/RadioFilter'
import RadioSliderFilter from 'visual-components/shared/filters/RadioSliderFilter'
import CheckboxSliderFilter from 'visual-components/shared/filters/CheckboxSliderFilter'

const PartnershipsOverviewFilters = () => (
  <div>
    <RangeFilter
      title="List Size"
      name="listSize"
      range={{ min: 0, max: 1000000 }}
    />
    <RangeFilter
      title="Avg Visits"
      name="avgVisits"
      range={{ min: 0, max: 70000 }}
    />
    <RangeFilter
      title="Avg Entries"
      name="avgEntries"
      range={{ min: 0, max: 50000 }}
    />
    <RangeFilter
      title="% NTF"
      name="percentNTF"
      range={{ min: 0, max: 100 }}
      format={num => (num ? `${num}%` : 0)}
    />
    <RadioFilter
      title="Recent Partnerships"
      name="recentPartnerships"
      tooltip="View brands you have worked with recently."
    />
    <CheckBoxFilter
      title="Household Income"
      name="householdIncome"
      tooltip="View brands with the highest concentration of their list within the selected income levels."
    />
    <RadioSliderFilter
      title="Gender Split"
      outerName="gender"
      range={{ min: 0, max: 100 }}
      format={num => (num ? `${num}%` : 0)}
      options={[
        {
          label: 'Female',
          value: 'genderSplitFemale',
        },
        {
          label: 'Male',
          value: 'genderSplitMale',
        },
      ]}
    />
    <CheckboxSliderFilter
      title="Geography"
      tooltip="View brands with the highest concentration of their list within the selected regions."
      sliderName="geoMinAudience"
      label="Minimum Audience"
      range={{ min: 0, max: 1000000 }}
      selectAllOption="All regions"
      checkboxName="regions"
    />
    <CheckBoxFilter
      title="Industry"
      selectAllOption="All industries"
      seeMoreText="See all industries"
      seeLessText="See less industries"
      hasNestedCollapse
      name="industry"
    />
  </div>
)

export default PartnershipsOverviewFilters
