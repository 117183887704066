import React from 'react'
import injectSheet from 'react-jss'
import { object, string } from 'prop-types'
import { H4 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import SearchTab from './search-tab'
import RecentTab from './recent-tab'

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  tab: {
    textAlign: 'center',
    cursor: 'pointer',
    marginRight: 48,
  },
  tab2: {
    marginRight: 0,
  },
  label: {
    display: 'inline-block',
    padding: '12px 16px 8px 16px',
    borderBottom: '4px solid transparent',
  },
  selected: {
    borderBottom: `4px solid ${COLOURS.neonMint}`,
  },
  divider: {
    height: '1px',
    margin: '0 -32px 24px -32px',
    backgroundColor: COLOURS.silver,
  },
}

class SearchImageTabs extends React.Component {
  static propTypes = {
    backgroundType: string.isRequired,
    backgroundImages: object.isRequired,
    backgroundImageActions: object.isRequired,
    classes: object.isRequired,
  }

  state = {
    selectedTab: 'search',
  }

  select = selectedTab => {
    this.setState({ selectedTab })
  }

  render() {
    const {
      backgroundType,
      backgroundImages,
      backgroundImageActions,
      classes: css,
    } = this.props

    const { selectedTab } = this.state

    const showTabs = backgroundImages.recentStockImages && backgroundImages.recentStockImages.length
    const Component = selectedTab === 'search' ? SearchTab : RecentTab

    return (
      <div>
        { showTabs ? (
          <div>
            <div className={css.container}>
              <div className={css.tab} onClick={() => this.select('recent')}>
                <div className={`${css.label} ${selectedTab === 'recent' ? css.selected : ''}`}>
                  { selectedTab === 'recent' ? (
                    <H4><i>Recent</i></H4>
                  ) : (
                    <H4>Recent</H4>
                  ) }
                </div>
              </div>
              <div className={`${css.tab} ${css.tab2}`} onClick={() => this.select('search')}>
                <div className={`${css.label} ${selectedTab === 'search' ? css.selected : ''}`}>
                  { selectedTab === 'search' ? (
                    <H4><i>Search</i></H4>
                  ) : (
                    <H4>Search</H4>
                  ) }
                </div>
              </div>
            </div>
            <div className={css.divider} />
          </div>
        ) : null }

        <Component
          showTabs={showTabs}
          backgroundType={backgroundType}
          backgroundImages={backgroundImages}
          backgroundImageActions={backgroundImageActions}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(SearchImageTabs)
