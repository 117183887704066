import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'

import { Link } from 'react-router-dom'
import { H5, Tiny } from 'visual-components/util/texts'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import BrandDegreeTooltip from 'visual-components/util/tooltip/brand-degree-tooltip'

import ReviewsTheme from 'css/themes/ratings-reviews.css'
import ButtonTheme from 'css/themes/Buttons.css'

import { makeNumbersPretty } from 'util/makeNumbersPretty'
import getCoverPhotoUrl from 'util/brand-cover-photo'

import Stars from 'visual-components/util/stars'
import BlueTags from 'visual-components/util/tags/BlueTags'
import BrandFavoriteIcon from 'visual-components/brandProfile/brand-favorite-icon'
import InfoRowContent from './info-row-content'

const degreeIconHash = {
  '1st': '/images/icons/degree-of-separation-icons/1-st-degree-connection-icon.svg',
  '2nd': '/images/icons/degree-of-separation-icons/mutual-connection-icon.svg',
  '3rd': '/images/icons/degree-of-separation-icons/never-worked-with-icon.svg',
}

const degreeTextHash = {
  '1st': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 1st Degree: You’ve partnered with this brand before and may have already acquired much of their audience.',
  '2nd': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 2nd Degree: It’s possible their audience may overlap with yours - you’ve partnered with someone who has worked with them.',
  '3rd': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 3rd Degree: Go for it! This brand isn’t connected to you and their audience will be full of new emails.',
}

class BrandDetailCard extends PureComponent {
  static propTypes = {
    logo: PropTypes.string.isRequired,
    accountname: PropTypes.string.isRequired,
    dedicatedListSize: PropTypes.number,
    averageSignUpsPerCampaign: PropTypes.number,
    socialMediaImpressions: PropTypes.number,
    tags: PropTypes.array.isRequired,
    facebookCoverPhoto: PropTypes.string.isRequired,
    coverPhotoOffsetY: PropTypes.number.isRequired,
    brandId: PropTypes.number.isRequired,
    currentBrandId: PropTypes.number.isRequired,
    onclick: PropTypes.func,
    homePage: PropTypes.bool,
    degreeOfRemoval: PropTypes.string,
    exploreMediaMarketResults: PropTypes.bool,
  }

  static defaultProps = {
    homePage: false,
    dedicatedListSize: 0,
    averageSignUpsPerCampaign: 0,
    socialMediaImpressions: 0,
    degreeOfRemoval: '',
    onclick() {},
    exploreMediaMarketResults: false,
  }

  onClick = e => {
    store.dispatch({ model: 'brand', type: 'CLEAR' })

    const { onclick } = this.props

    if (onclick) {
      onclick(e)
    }
  }

  render() {
    const {
      logo,
      accountname,
      dedicatedListSize,
      averageSignUpsPerCampaign,
      socialMediaImpressions,
      tags,
      facebookCoverPhoto,
      coverPhotoOffsetY,
      ratingAvg,
      ratingCount,
      brandId,
      currentBrandId,
      homePage,
      degreeOfRemoval,
      exploreMediaMarketResults,
    } = this.props

    const isBrand = brandId === currentBrandId

    const content = (
      <div>
        {/* photo row */}
        <Row
          style={{
            margin: '0',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: COLOURS.silver,
            height: '72px',
          }}
        >
          <Col xs={4} style={{ padding: '0', width: '71px', maxWidth: '71px' }}>
            <div
              style={{
                height: '72px',
                width: '72px',
                padding: '8px 8px 7px 8px',
                display: 'table-cell',
                verticalAlign: 'middle',
              }}
            >
              <img
                alt={accountname}
                src={logo || '/images/defaults/mojo-white-tiger-logo@2x.png'}
                style={{
                  maxHeight: '56px',
                  maxWidth: '56px',
                  display: 'block',
                  margin: 'auto',
                }}
              />
            </div>
          </Col>
          <Col xs={8} style={{ padding: '0', width: '200px', maxWidth: '169px' }}>
            <div
              style={{
                height: '72px',
                width: '168px',
                background: `url(${getCoverPhotoUrl(facebookCoverPhoto) || '/images/defaults/coverPhotoSmall@2x.png'}) no-repeat center`,
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPositionX: 0,
                backgroundPositionY: `${coverPhotoOffsetY}%`,
              }}
            >
              {
                !isBrand && !exploreMediaMarketResults
                && <BrandFavoriteIcon brandId={brandId} small />
              }
            </div>
          </Col>
        </Row>

        {/* header row */}
        <Row
          style={{
            alignItems: 'center',
            paddingLeft: exploreMediaMarketResults ? '16px' : '12px',
            paddingRight: exploreMediaMarketResults ? '16px' : '12px',
            paddingTop: exploreMediaMarketResults ? '12px' : '8px',
            paddingBottom: exploreMediaMarketResults ? '16px' : '8px',
          }}
        >
          <Col xs={10}>
            <H5>
              { accountname.length > 22 ? accountname.substring(0, 22) + '...' : accountname }
            </H5>
          </Col>
          {
            degreeOfRemoval
              ? (
                <Col xs={2}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '8px' }}>
                    <BrandDegreeTooltip imgSrc={degreeIconHash[degreeOfRemoval]} text={degreeTextHash[degreeOfRemoval]} />
                  </div>
                </Col>
              ) : null
          }
        </Row>

        {/* Rating section */}
        { !exploreMediaMarketResults ? (
          <div
            className={`${ReviewsTheme.flex} ${ReviewsTheme.vCenter}`}
            style={{ padding: '0 12px 16px', minHeight: '28px' }}
          >
            {
              !!ratingCount
                && (
                  <React.Fragment>
                    <Stars
                      value={ratingAvg}
                      style={{ margin: '-4px 4px 0 0' }}
                      className={ReviewsTheme.cstmRatingStars}
                    />
                    <Tiny azure>
                      <span style={{ fontFamily: 'Larsseit-Medium' }}>
                        { makeNumbersPretty(ratingCount, true) }
                      </span>
                    </Tiny>
                  </React.Fragment>
                )
            }
          </div>
        ) : null }

        {/* list size row */}
        <Row style={{ paddingBottom: exploreMediaMarketResults ? '11px' : '9px' }}>
          <InfoRowContent label="List Size" data={dedicatedListSize} exploreMediaMarketResults />
        </Row>

        {/* Avg Entries row */}
        <Row style={{ paddingBottom: exploreMediaMarketResults ? '11px' : '9px' }}>
          <InfoRowContent label="Avg Entries" data={averageSignUpsPerCampaign} exploreMediaMarketResults />
        </Row>

        {/* total social audience row */}
        <Row
          style={{
            paddingBottom: '16px',
            borderBottom: exploreMediaMarketResults ? 'none' : `1px solid ${COLOURS.silver}`,
          }}
        >
          <InfoRowContent label="Total Social Audience" data={socialMediaImpressions} exploreMediaMarketResults />
        </Row>
      </div>
    )

    const component = homePage
      ? (
        <a href={`/home/brand/${brandId}`}>
          {content}
        </a>
      ) : (
        <Link onClick={this.onClick} to={`/explore/brands/${brandId}`}>
          {content}
        </Link>
      )

    return (
      <Card
        style={{
          width: '240px',
          display: 'inline-flex',
          marginRight: '16px',
          marginBottom: '12px',
          height: exploreMediaMarketResults ? '234px' : '267px',
        }}
      >
        <div>
          <Grid>
            {component}
            { exploreMediaMarketResults && !isBrand ? (
              <div>
                <Button
                  onClick={this.onClick}
                  theme={ButtonTheme}
                  label="Sell Media"
                  flat
                  primary
                  style={{
                    marginLeft: '8px',
                  }}
                />
                <BrandFavoriteIcon brandId={brandId} small exploreMediaMarketResults />
              </div>
            ) : (
              <div style={{ height: '64px' }}>
                {/* tags */}
                <BlueTags tags={tags} />
              </div>
            )}
          </Grid>
        </div>
      </Card>
    )
  }
}

export default BrandDetailCard
