import React from 'react'
import _ from 'lodash'

import DropdownButton from 'util/components/buttons/dropdown-button'
import COLOURS from 'util/colours'

class Dropdown extends React.Component {
  render() {
    const baseStyles = {
      paddingTop: '0',
      paddingBottom: '0',
      borderColor: COLOURS.medGreen.point4,
      borderRadius: '1px',
      MozBoxShadow: 'none',
      boxShadow: 'none',
      textAlign: 'left',
    }

    const id = `${this._reactInternalInstance._rootNodeID}dropdown`

    let openClass = this.props.openClass || ''
    openClass += ' dropdown-toggle'

    const items = _.map(this.props.items, function (opt, key) {
      return (
        <li
          key={key}
          style={{ minWidth: '240px', maxWidth: '240px' }}
          onClick={() => this.props.onChange(opt)}
        >
          {opt}
        </li>
      )
    })

    const buttonStyles = this.props.buttonStyles || []

    const styles = Object.assign({}, baseStyles, ...this.props.styles)

    const ButtonComponent = this.props.button || DropdownButton

    return (
      <div
        style={{
          display: 'inline-block',
          width: '145px',
        }}
      >
        <ButtonComponent
          id={id}
          styles={buttonStyles}
          data-toggle="dropdown"
          aria-haspopup="true"
          className={openClass}
          aria-expanded="true"
        >
          {this.props.children}
        </ButtonComponent>
        <ul className="dropdown-menu" aria-labelledby={id} style={styles}>
          {items}
        </ul>
      </div>
    )
  }
}

export default Dropdown
