import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'react-toolbox/lib/button'
// import { Menu, MenuItem, MenuDivider } from 'react-toolbox/lib/menu'
import Dropdown from 'react-toolbox/lib/dropdown'

import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher'
import { connect } from 'react-redux'
import COLOURS from 'util/colours'
import { H3 } from 'visual-components/util/texts'
import EditLogoContainer from 'container-components/brands/EditLogoContainer'
import PartnerWithBrand from 'visual-components/brandProfile/PartnerWithBrand'
import PartnerWithBrandContainer from 'container-components/brands/PartnerWithBrandContainer'
import SearchForCampaignsToApplyModal from 'visual-components/brandProfile/ApplyToCampaignModal'

import ProfileTheme from 'css/themes/Profile.css'
import ButtonTheme from 'css/themes/Buttons.css'
import DropdownTheme from 'css/themes/Dropdown.css'

const stateToProps = state => ({
  brand: state.brand,
  currentBrand: state.currentBrand,
  general: state.general,
  brandsAlsoViewed: state.brandsAlsoViewed,
  upcomingCampaigns: state.upcomingCampaigns,
  isCurrentBrandHost: state.upcomingCampaigns.campaigns.length > 0,
  isHost: state.brand
    && state.brand.availableCampaigns
    && state.brand.availableCampaigns.length > 0,
})

const apply = {
  label: 'Apply to Campaign',
  value: 0,
}

const invite = {
  label: 'Invite to Campaign',
  value: 1,
}

const partnerWithBrand = {
  label: 'Partner with Brand',
  value: 4,
  disabled: 'true',
}

const BrandNameButtonContainer = props => {
  const {
    obscure,
    accountname,
    isBrand,
    signup,
    message,
    onDropdownChange,
    dropdownOptions,
    dropdownInitialValue,
    isPublic,
  } = props

  const showDropdown = dropdownOptions.length > 0 && !isPublic

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 26,
          paddingLeft: 212,
          paddingBottom: 16,
          height: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ marginBottom: 16 }}>
          <H3 multiline>
            { accountname }
          </H3>
        </div>
        <div style={{ height: 36, display: 'flex' }}>
          {
            !isBrand
              ? (
                <Button
                  onClick={obscure ? signup : message}
                  label="Message"
                  className={ButtonTheme.greenButton}
                  theme={ButtonTheme}
                  raised
                  style={{
                    fontFamily: 'Larsseit-Medium',
                    fontSize: '14px',
                    width: '122px',
                    height: '36px',
                  }}
                />
              ) : null
          }
          {
            showDropdown
              ? (
                <div
                  style={{
                    marginLeft: !isBrand ? 8 : 0,
                    backgroundColor: 'white',
                    width: 222,
                  }}
                >
                  <Dropdown
                    auto
                    className={DropdownTheme.smaller}
                    theme={DropdownTheme}
                    onChange={onDropdownChange}
                    source={dropdownOptions}
                    value={dropdownInitialValue}
                  />
                </div>
              ) : null
          }
        </div>
      </div>
    </React.Fragment>
  )
}

const ProfilePicture = ({ logo, isBrand }) => (
  <div
    style={{
      width: 216,
      height: 216,
      borderRadius: '50%',
      display: 'flex',
      background: COLOURS.white,
      overflow: 'hidden',
      position: 'absolute',
      marginTop: -104,
    }}
  >
    <div
      style={{
        backgroundImage: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: 208,
        height: 208,
        border: '4px solid white',
        borderRadius: '50%',
        margin: 'auto',
        position: 'relative',
      }}
      className={ProfileTheme.profile}
    >
      {
          isBrand
            ? (
              <div className={ProfileTheme.editContainer}>
                <EditLogoContainer />
              </div>
            ) : null
          }
    </div>
  </div>
)

class ProfileLogo extends PureComponent {
  state = {
    dropdownOptions: [],
    initialValue: 0,
    showPartnerWithBrandModal: false,
    showApplyToCampaignModal: false,
  }

  componentDidMount() {
    // Force update
    setTimeout(this.populateDropdown, 100)
  }

  componentDidUpdate(prevProps) {
    const {
      currentBrand,
      brand,
      upcomingCampaigns,
    } = this.props

    if (
      (prevProps.brand.loaded !== brand.loaded
      || prevProps.currentBrand.loading !== currentBrand.loading
      || prevProps.upcomingCampaigns.loaded !== upcomingCampaigns.loaded)
    ) {
      this.populateDropdown()
    }
  }

  populateDropdown = () => {
    const {
      isHost,
      isCurrentBrandHost,
      isBrand,
    } = this.props

    if (isBrand) return null
    const dropdownOptions = []
    dropdownOptions.push(partnerWithBrand)

    // https://docs.google.com/document/d/1y_JGwiryBKqBfjeTPqpfJlJulS0MepUr8aqoCW4bP0o/edit#bookmark=id.sv0bpg5g0has
    // Page 7
    if (isCurrentBrandHost) {
      dropdownOptions.push(invite)
    } else if (!isCurrentBrandHost) {
      if (isHost) dropdownOptions.push(apply)
    }

    const initialValue = dropdownOptions[0] && dropdownOptions[0].value

    return this.setState(() => ({ dropdownOptions, initialValue }))
  }

  message = () => {
    const {
      brand: {
        id,
        accountname,
      },
    } = this.props

    PricingDispatcher.check('messaging.message.create', () => {
      store.dispatch({
        model: 'conversations',
        type: 'POPOUT_MESSAGE_BRAND',
        data: {
          brandId: id,
          brandName: accountname,
        },
      })
    }, id)
  }

  signup = () => { window.location.href = '/app/register' }

  onDropdownChange = value => {
    if (value === 0) return this.setState({ showApplyToCampaignModal: true })
    if (value === 1) return this.setState({ showPartnerWithBrandModal: true })
  }

  onCloseInviteModal = () => this.setState({ showPartnerWithBrandModal: false })

  onCloseSearchModal = () => this.setState({ showApplyToCampaignModal: false })

  render() {
    const {
      isBrand,
      brand,
      isPublic,
      currentBrand,
    } = this.props

    const {
      dropdownOptions,
      initialValue,
      showPartnerWithBrandModal,
      showApplyToCampaignModal,
    } = this.state

    const brandLogo = isBrand ? currentBrand.logo : brand.logo

    return (
      <div
        style={{
          width: 1176,
          margin: '0 auto',
          height: 112,
          zIndex: 1,
          padding: '0 40px',
          position: 'relative',
        }}
      >
        <ProfilePicture logo={brandLogo} isBrand={isBrand} />
        <BrandNameButtonContainer
          obscure={brand.obscure}
          accountname={brand.accountname}
          isBrand={isBrand}
          signup={this.signup}
          message={this.message}
          onDropdownChange={this.onDropdownChange}
          isPublic={isPublic}
          dropdownOptions={dropdownOptions}
          dropdownInitialValue={initialValue}
        />
        {
          brand.obscure
            ? (
              <PartnerWithBrand
                active={showPartnerWithBrandModal}
                onClose={this.onCloseInviteModal}
                currentBrand={currentBrand}
                brand={brand}
              />
            ) : brand.id !== currentBrand.id
              ? (
                <PartnerWithBrandContainer
                  active={showPartnerWithBrandModal}
                  onClose={this.onCloseInviteModal}
                />
              ) : null
        }
        {
          !isPublic && (
            <SearchForCampaignsToApplyModal
              active={showApplyToCampaignModal}
              onClose={this.onCloseSearchModal}
            />
          )
        }
      </div>
    )
  }
}

ProfileLogo.propTypes = {
  history: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  upcomingCampaigns: PropTypes.object.isRequired,

  dealRequest: PropTypes.object.isRequired,
  isBrand: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,

  isHost: PropTypes.bool.isRequired,
  isCurrentBrandHost: PropTypes.bool.isRequired,
}

export default withRouter(connect(stateToProps)(ProfileLogo))
