import React, { Component } from 'react'
import AgeTarget from 'visual-components/brandProfile/interests/age-target'

import { Input, Select, SelectRange } from 'visual-components/util/form'
import { isPositiveInteger, cannotExceed } from 'visual-components/util/form/validations'

const swaps = [
  { value: '-', label: 'Select a range', disabled: true },
  { value: '1-3', label: '1 - 3' },
  { value: '3-5', label: '3 - 5' },
  { value: '5-999999', label: '5+' },
]

const genders = [
  { value: '', label: 'Select an option', disabled: true },
  { value: 'Primarily Male', label: 'Primarily Male' },
  { value: 'Primarily Female', label: 'Primarily Female' },
  { value: 'Mixed', label: 'Mixed' },
]

class ContentSwapsOpportunityInfo extends Component {
  updateAge = this.props.opportunityActions.toggleAgeGroup

  render() {
    return (
      <div>
        <SelectRange
          required
          name="min_swaps_per_month"
          maxFieldName="max_swaps_per_month"
          label="Swaps per Month"
          sublabel="The number of article swaps you want to do on a monthly basis."
          options={swaps}
          defaultSelected="-"
        />
        <Input
          required
          name="average_clicks"
          type="number"
          placeholder="100,000"
          validations={[isPositiveInteger, cannotExceed(1e8)]}
          label="Average Clicks per Article"
          sublabel="The average number of clicks you expect delivered on a single content swap."
        />
        <Select
          required
          name="gender"
          label="Target Gender"
          sublabel="Your preferred reader gender."
          options={genders}
          defaultSelected=""
        />
        <AgeTarget />
      </div>
    )
  }
}

export default ContentSwapsOpportunityInfo
