import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

class InternalContentContainer extends Component {
  render() {
    const { width, children } = this.props

    return (
      <div
        style={{
          width,
          display: '-webkit-flex', /* Safari */
          alignItems: 'stretch',
          minHeight: 'calc(100vh - 64px)', // 64px (header height)
          paddingBottom: '64px',
          margin: 'auto',
        }}
      >
        { children }
      </div>
    )
  }
}

InternalContentContainer.propTypes = {
  width: PropTypes.string.isRequired,
}

export default InternalContentContainer
