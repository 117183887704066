import React from 'react';
import * as ReactRedux from 'react-redux';

import Text from 'util/components/text';
import PrimaryButton from 'util/components/buttons/primary-button';
import AppDispatcher from 'dispatchers/app-dispatcher';

class CustomerSuccessAdminPartnershipEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      status: 'accepted',
      foundCampaign: {
        campaignName: 'No Campaign Found',
        inviteStatus: 'N/A',
        brandName: 'No Brand Found'
      }
    }

    this.updatePartnership = this.updatePartnership.bind(this);
    this.fetchCampaignInfo = this.fetchCampaignInfo.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  updatePartnership() {
    let that = this;
    AppDispatcher.http({
      url: '/cs-update-invite',
      method: 'POST',
      data: {
        campaignId: parseInt(this.state.campaignId),
        brandId: parseInt(this.state.brandId),
        status: this.state.status
      },
      success: function(response) {
        toastr.success('Partnership Updated', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        that.fetchCampaignInfo();
      },
      error: function(err) {
        toastr.error('Failed to update partnership');
      }
    })
  }

  fetchCampaignInfo() {
    let that = this;
    window.typingTimer = setTimeout(function() {
      if (that.state.campaignId || that.state.brandId) {
        AppDispatcher.http({
          url: `/campaign-basic-details/${that.state.campaignId}`,
          success: function(response) {
            let foundInvite = response.find(function(invite) {
              return invite.invitee_id == that.state.brandId;
            });

            that.setState({
              foundCampaign: {
                campaignName: foundInvite ? foundInvite.id : 'N/A',
                inviteStatus: foundInvite ? foundInvite.status : 'N/A',
                brandName: foundInvite ? foundInvite.accountname : 'No Brand Found'
              }
            })
          },
          error: function(err) {
            that.setState({
              foundCampaign: {
                campaignName: 'No Campaign Found',
                inviteStatus: 'N/A',
                brandName: 'No Brand Found'
              }
            })
          }
        })
      }
    }, 500)
  }

  onchange(e) {
    clearTimeout(window.typingTimer);
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    window.typingTimer;
    let foundPartnership = this.state.foundCampaign.campaignName != 'No Campaign Found' && this.state.foundCampaign.inviteStatus != 'N/A' && this.state.foundCampaign.brandName != 'No Brand Found';

    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Accept or Reject a Partnership
          </Text.dojoH2Bold>
          <form className="col-xs-12">
            <div className="row">
              <div className="col-xs-6" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Campaign ID
                  </Text.dojoP>
                  <input type="number" name="campaignId" className="col-xs-12" onChange={ this.onchange } onKeyUp={ this.fetchCampaignInfo }/>
                </div>
              </div>
              <div className="col-xs-6" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Brand ID
                  </Text.dojoP>
                  <input type="number" name="brandId" className="col-xs-12" onChange={ this.onchange } onKeyUp={ this.fetchCampaignInfo }/>
                </div>
              </div>
              <div className="col-xs-12" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Desired Status
                  </Text.dojoP>
                  <select name="status" className="col-xs-12" onChange={ this.onchange }>
                    <option value="accepted">Accepted</option>
                    <option value="pending">Pending</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <PrimaryButton
                onClick={ this.updatePartnership }
                isDisabled={ !foundPartnership }
                styles={[{
                  width: '220px',
                  height: '50px',
                  marginTop: '20px'
              }]}>
                Update
              </PrimaryButton>
            </div>
          </form>
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Campaign Name: { this.state.foundCampaign.campaignName }
              </Text.dojoH3Bold>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Brand Name: { this.state.foundCampaign.brandName }
              </Text.dojoH3Bold>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoP>
                Partnership Status: { this.state.foundCampaign.inviteStatus }
              </Text.dojoP>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var mapState = function(state) {
  return state;
}

export default ReactRedux.connect(mapState)(CustomerSuccessAdminPartnershipEditor);
