import PartnershipAgreementConstants from 'constants/partnership-agreement-constants';

const {
  CREATE_AGREEMENT_REQUEST,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,

  LOAD_AGREEMENT_REQUEST,
  LOAD_AGREEMENT_SUCCESS,
  LOAD_AGREEMENT_FAILURE,

  UPDATE_AGREEMENT_REQUEST,
  UPDATE_AGREEMENT_SUCCESS,
  UPDATE_AGREEMENT_FAILURE,

  UPDATE_LEGAL_DETAILS_REQUEST,
  UPDATE_LEGAL_DETAILS_SUCCESS,
  UPDATE_LEGAL_DETAILS_FAILURE,

  SAVE_LEGAL_DETAILS_REQUEST,
  SAVE_LEGAL_DETAILS_SUCCESS,
  SAVE_LEGAL_DETAILS_FAILURE,

  GENERATE_AGREEMENT_DRAFT_REQUEST,
  GENERATE_AGREEMENT_DRAFT_SUCCESS,
  GENERATE_AGREEMENT_DRAFT_FAILURE,

  SHARE_AGREEMENT_REQUEST,
  SHARE_AGREEMENT_SUCCESS,
  SHARE_AGREEMENT_FAILURE,

  CONFIRM_PARTNER_REQUEST,
  CONFIRM_PARTNER_SUCCESS,
  CONFIRM_PARTNER_FAILURE,

  SIGN_AGREEMENT_REQUEST,
  SIGN_AGREEMENT_SUCCESS,
  SIGN_AGREEMENT_FAILURE,

  COUNTER_SIGN_AGREEMENT_REQUEST,
  COUNTER_SIGN_AGREEMENT_SUCCESS,
  COUNTER_SIGN_AGREEMENT_FAILURE,

  OPEN_SIGNING_MODAL,
  CLOSE_SIGNING_MODAL,

  UPDATE_AGREEMENT_TEMPLATE_MARKUP,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_REQUEST,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_SUCCESS,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_ERROR,

  DELETE_AGREEMENT_REQUEST,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_FAILURE,

  UPDATE_AGREEMENT_ATTR,

  CLEAR_AGREEMENT,
} = PartnershipAgreementConstants;

export function createPartnershipAgreement(partnershipId, type) {
  return {
    types: [
      CREATE_AGREEMENT_REQUEST,
      CREATE_AGREEMENT_SUCCESS,
      CREATE_AGREEMENT_FAILURE
    ],
    http: {
      url: `/partnership-agreement/${partnershipId}`,
      method: 'POST',
      data: {
        type
      }
    },
    model: 'currentCampaign'
  }
}

export function loadPartnershipAgreement(partnershipId) {
  return {
    types: [
      LOAD_AGREEMENT_REQUEST,
      LOAD_AGREEMENT_SUCCESS,
      LOAD_AGREEMENT_FAILURE
    ],
    http: {
      url: `/partnership-agreement/${partnershipId}`,
    }
  }
}

export function updatePartnershipAgreement(data, partnershipId) {
  return {
    types: [
      UPDATE_AGREEMENT_REQUEST,
      UPDATE_AGREEMENT_SUCCESS,
      UPDATE_AGREEMENT_FAILURE
    ],
    http: {
      url: `/partnership-agreement/${partnershipId}`,
      method: 'PUT',
      data: {
        data: data
      }
    }
  }
}

export function updateLegalDetails(data, partnershipId) {
  return {
    types: [
      UPDATE_LEGAL_DETAILS_REQUEST,
      UPDATE_LEGAL_DETAILS_SUCCESS,
      UPDATE_LEGAL_DETAILS_FAILURE
    ],
    http: {
      url: `/partnership-agreement/${partnershipId}`,
      method: 'PUT',
      data: {
        data: {
          host_legal: data
        }
      }
    }
  }
}

export function saveLegalDetails(data, partnershipId) {
  return {
    types: [
      SAVE_LEGAL_DETAILS_REQUEST,
      SAVE_LEGAL_DETAILS_SUCCESS,
      SAVE_LEGAL_DETAILS_FAILURE
    ],
    http: {
      url: `/partnership-agreement/partner-legal-details/${partnershipId}`,
      method: 'POST',
      data: {
        data: {
          company_name: data.legalName,
          incorporation_state: data.incorporationState,
          entity_type: data.entityType,
          address1: data.addressL1,
          address2: data.addressL2,
          city: data.city,
          state: data.state,
          zip: data.zipcode,
          country: data.country
        }
      }
    }
  }
}

export const validateDraft = data => dispatch => {
  dispatch({
    type: 'VALIDATE_DRAFT',
    payload: data
  })
}

export function generateAgreementDraft(data, partnershipId) {
  return {
    types: [
      GENERATE_AGREEMENT_DRAFT_REQUEST,
      GENERATE_AGREEMENT_DRAFT_SUCCESS,
      GENERATE_AGREEMENT_DRAFT_FAILURE
    ],
    http: {
      url: `/partnership-agreement/generate/${partnershipId}`,
      method: 'POST',
      data: {
        data: data
      }
    },
    model: 'currentCampaign'
  }
}

export function shareAgreement(partnershipId) {
  return {
    types: [
      SHARE_AGREEMENT_REQUEST,
      SHARE_AGREEMENT_SUCCESS,
      SHARE_AGREEMENT_FAILURE
    ],
    http: {
      url: `/partnership-agreement/share/${partnershipId}`,
      method: 'POST'
    },
    model: 'currentCampaign'
  }
}

export const confirmPartner = (partnershipId, callback) => dispatch => {
  store.dispatch({
    type: CONFIRM_PARTNER_REQUEST
  })
  $.ajax({
    url: `/partnership-agreement/confirm-partner/${partnershipId}`,
    method: 'POST',
    dataType: 'json',
    success: data => {
      store.dispatch({ type: CONFIRM_PARTNER_SUCCESS, payload: data })
      callback(null)
    },
    error: err => {
      store.dispatch({ type: CONFIRM_PARTNER_FAILURE, payload: err})
      callback(true)
    }
  })
}

export function signAgreement(partnershipId, name, title) {
  return {
    types: [
      SIGN_AGREEMENT_REQUEST,
      SIGN_AGREEMENT_SUCCESS,
      SIGN_AGREEMENT_FAILURE
    ],
    http: {
      url: `/partnership-agreement/partner-signature/${partnershipId}`,
      method: 'POST',
      data: {
        data: {
          legal_name: name,
          title
        }
      }
    }
  }
}

export const countersignAgreement = (partnershipId, legal_name, title, callback) => dispatch => {
  store.dispatch({
    type: COUNTER_SIGN_AGREEMENT_REQUEST
  })
  $.ajax({
    url: `partnership-agreement/host-signature/${partnershipId}`,
    method: 'POST',
    dataType: 'json',
    data: JSON.stringify({ data: { legal_name, title }}),
    contentType: 'application/json',
    success: data => {
      store.dispatch({
        type: COUNTER_SIGN_AGREEMENT_SUCCESS,
        payload: data,
        model: 'currentCampaign',
        inviteId: partnershipId
      })
      callback(null)
    },
    error: err => {
      store.dispatch({
        type: COUNTER_SIGN_AGREEMENT_FAILURE,
        payload: err
      })
      callback(true)
    }
  })
}

export function openSigningModal() {
  return {
    type: OPEN_SIGNING_MODAL
  };
}

export function closeSigningModal() {
  return {
    type: CLOSE_SIGNING_MODAL
  };
}

export const updateUploadedAgreement = (src) => dispatch => {
  dispatch({
    type: 'UPDATE_UPLOADED_AGREEMENT',
    payload: src
  })
}

export function messageHost(data) {
  const { brandId, brandName } = data
  return {
    model: 'conversations',
    type: 'POPOUT_MESSAGE_BRAND',
    data: {
      brandId,
      brandName
    }
  }
}

export function updateTemplateMarkup(markup) {
  return {
    type: UPDATE_AGREEMENT_TEMPLATE_MARKUP,
    markup
  }
}

export function saveTemplateMarkup(data) {
  const { partnershipId, template_markup } = data
  return {
    types: [
      SAVE_AGREEMENT_TEMPLATE_MARKUP_REQUEST,
      SAVE_AGREEMENT_TEMPLATE_MARKUP_SUCCESS,
      SAVE_AGREEMENT_TEMPLATE_MARKUP_FAILURE
    ],
    http: {
      url: `/partnership-agreement/template-markup/${partnershipId}`,
      method: 'POST',
      data: {
        template_markup
      }
    }
  }
}

export function deleteAgreement(inviteId) {
  return {
    types: [
      DELETE_AGREEMENT_REQUEST,
      DELETE_AGREEMENT_SUCCESS,
      DELETE_AGREEMENT_FAILURE,
    ],
    http: {
      url: `/partnership-agreement/${inviteId}`,
      method: 'DELETE',
      dataType: 'text',
    },
    model: 'currentCampaign',
    inviteId
  }
}

export function updateAttributes(data) {
  return {
    type: UPDATE_AGREEMENT_ATTR,
    data
  }
}

export function clearAgreement() {
  return {
    type: CLEAR_AGREEMENT,
  }
}
