import React from 'react'
import { string, bool, func } from 'prop-types'
import DataIntegrationTheme from 'css/themes/data-integrations.css'
import { Small, Tiny } from 'visual-components/util/texts'

const tileLogoDir = '/images/tile-logos'
const iconImageMap = {
  shopify: 'shopify.svg',
  stripe: 'stripe.svg',
  pixel: 'mojo.svg',
  woocommerce: 'woocommerce.svg',
  recharge: 'recharge.svg',
}
const tileTitleMap = {
  pixel: 'Conversion Pixel',
}

class IntegrationIcon extends React.PureComponent {
  static propTypes = {
    onClick: func.isRequired,
    integrated: bool.isRequired,
    integrationType: string.isRequired,
    noBoxShadow: bool,
  }

  static defaultProps = {
    noBoxShadow: false,
  }

  static renderIntegrated() {
    return (
      <img
        className={DataIntegrationTheme.integratedIcon}
        src="/images/integration-logos/checkmark.svg"
      />
    )
  }

  render() {
    const {
      onClick,
      integrated,
      integrationType,
      noBoxShadow,
    } = this.props

    return (
      <div className={`${DataIntegrationTheme.tile} ${noBoxShadow ? DataIntegrationTheme.noBoxShadow : ''}`} onClick={onClick}>
        { integrated && IntegrationIcon.renderIntegrated() }
        <img
          className={DataIntegrationTheme.logo}
          src={`${tileLogoDir}/${iconImageMap[integrationType]}`}
        />
        <div className={DataIntegrationTheme.body}>
          <div className={DataIntegrationTheme.title}>
            <Small>
              <b>
                {tileTitleMap[integrationType] || integrationType}
              </b>
            </Small>
          </div>
          <div className={DataIntegrationTheme.subtitle}>
            <Tiny>
              Data
            </Tiny>
          </div>
        </div>
      </div>
    )
  }
}

export default IntegrationIcon
