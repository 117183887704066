import COLOURS from 'util/colours'
import React from 'react';
import { P, Small } from 'visual-components/util/texts';
import OpportunityProfileTheme from 'css/themes/opportunityProfile.css'

class BrandInfo extends React.Component {
  render() {
    const { brand } = this.props;
    const tags = brand.tagsconnected || brand.tags || []

    return (
      <div className={ OpportunityProfileTheme.brandInfoMain }>
        <div className={ OpportunityProfileTheme.brandLogoContainer }>
          <img src={ brand.logo }
            style={{
              maxHeight: '38px',
              maxWidth: '38px',
              alignSelf: 'center'
          }} />
        </div>

        <div className={ OpportunityProfileTheme.brandInfoContainer}>
          <P>
            <i>{ brand.accountname }</i>
          </P>
          <Small style={{
            display: 'block',
            marginTop: '14px',
            fontWeight: '300'
          }}>
            { tags[0] && tags[0].name || tags[0] || brand.tag }
          </Small>
        </div>
      </div>
    )
  }
}

export default BrandInfo
