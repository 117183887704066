import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import BonusOptions from 'visual-components/socialGrowth/bonus-options'
import BonusItems from 'visual-components/socialGrowth/bonus-items'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import SocialTheme from 'css/themes/SocialGrowth.css'
import { P, H3 } from 'visual-components/util/texts'

import AppDispatcher from 'dispatchers/app-dispatcher'

import { getClaim } from 'actions/brand-claim-actions'

import * as socialActionsActions from 'actions/social-actions-actions'
import * as metrics from 'util/metrics'
import { memoize } from 'lodash'
import SocialActionsUpsellModal from './upsell-modal'

const enhanceLabel = (str, opt) => {
  const matches = str.match(/{{[a-zA-Z]*}}/g) || []
  let newStr = str
  matches.forEach(match => {
    newStr = newStr.replace(match, opt[match.replace('{{', '').replace('}}', '')])
  })

  return newStr
}

const mapStateToProps = ({
  currentCampaign,
  currentBrand,
  partnershipInvite,
  profile,
  bonusItems,
  brandCampaigns,
  SocialActions,
}) => ({
  currentCampaign,
  currentBrand,
  partnershipInvite,
  profile,
  bonusItems,
  brandCampaigns,
  upsellModalOpen: SocialActions.showUpsellModal,
})

const dispatchToProps = dispatch => ({
  socialActionsActions: bindActionCreators(socialActionsActions, dispatch),
})

class GrowOptimize extends React.Component {
  state = {
    bonusOption: {
      loading: false,
    },
  }

  openUpsellModal = memoize((from = null) => () => {
    const { socialActionsActions } = this.props
    socialActionsActions.showUpsellModal()
    metrics.create('socialActionsModalOpened', {
      meta: {
        from,
      },
    })
  })

  componentDidMount() {
    const { currentCampaign, currentBrand } = this.props

    const partnerInvites = currentCampaign.invites.filter(invite => invite.status === 'accepted')
    const partnerInvite = partnerInvites.find(invite => invite.invitee_id === currentBrand.id)
    const hostInvite = partnerInvites.find(
      invite => invite.invitee_id === currentCampaign.hostBrandId
    )

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'partnershipInvite',
      data: partnerInvite,
    })

    AppDispatcher.http({
      url: `/partnership-bonus/${currentCampaign.id}`,
      success: bonuses => {
        store.dispatch({
          type: 'LOAD_SUCCESS',
          data: bonuses,
          model: 'bonusItems',
        })

        if (hostInvite.bonusOrder) {
          store.dispatch({
            type: 'REORDER_HASH',
            model: 'bonusItems',
            data: {
              order: hostInvite.bonusOrder.order,
            },
          })
        }
      },
    })
  }

  addBonusItem = key => {
    const {
      currentBrand: {
        accountname: brand, belt, defaultBonusValue, trial_end,
      },
      partnershipInvite: { id: inviteId, paid_social_actions },
      currentCampaign: { name: campaignName },
      bonusItems: { presentationOrder },
    } = this.props

    this.setState({
      bonusOption: {
        loading: true,
      },
    })

    const isTrial = trial_end
      && moment(trial_end)
        .startOf('day')
        .isAfter(moment())
    const impersonating = sessionStorage.getItem('impersonating')

    const ineligibleForEntries = ['fbLike', 'fbShare', 'instagramFollow']
    let defaultBonusCount = 2

    if (ineligibleForEntries.includes(key)) {
      defaultBonusCount = 0
    }

    const createdItems = presentationOrder.filter(item => item.partnership_invite_id === inviteId)
    const paidLimit = paid_social_actions
    const beltLimit = getClaim('sweepstakes.socialAction.limit') || 0
    const totalLimit = paidLimit + beltLimit
    const isBasic = belt === 'white'
    const isEssential = belt === 'greenFixed'

    if (
      isBasic
      && createdItems.length >= totalLimit
      && !isTrial
      && (!impersonating || impersonating === 'null')
    ) {
      this.setState({
        bonusOption: { loading: false },
      })

      this.openUpsellModal('TrialBeltLimitReached')()
      return
    }
    if (
      isEssential
      && createdItems.length >= totalLimit
      && !isTrial
      && (!impersonating || impersonating === 'null')
    ) {
      this.setState({
        bonusOption: { loading: false },
      })

      this.openUpsellModal('essentialBeltLimitReached')()
      return
    }

    AppDispatcher.http({
      url: `/partnership-bonus/${inviteId}`,
      method: 'POST',
      data: Object.assign(
        {
          bonus_type: key,
          message: enhanceLabel(LANGUAGE.builder.steps.bonusentry.options[key].label, {
            brand,
            campaignName,
          }),
          bonusCount: defaultBonusCount,
        },
        defaultBonusValue(key)
      ),
      success: bonus => {
        this.setState({
          bonusOption: {
            loading: false,
          },
        })

        store.dispatch({
          type: 'ADD_ITEM',
          data: Object.assign(bonus, bonus.data, { expanded: true }),
          model: 'bonusItems',
        })
      },
    })
  }

  deleteBonusItem = id => {
    const {
      partnershipInvite: { id: inviteId },
    } = this.props

    AppDispatcher.http({
      url: `/partnership-bonus/${inviteId}/${id}`,
      method: 'DELETE',
      success: bonus => {
        store.dispatch({
          model: 'bonusItems',
          type: 'REMOVE_ITEM_BY_ID',
          data: {
            id,
          },
        })
      },
    })
  }

  updateBonusItem = (id, partnershipInviteId, data) => {
    AppDispatcher.http({
      url: `/partnership-bonus/${partnershipInviteId}/${id}`,
      method: 'PUT',
      data,
      success: bonus => {
        store.dispatch({
          model: 'bonusItems',
          type: 'UPDATE_ITEM',
          data: bonus,
        })
      },
    })
  }

  expandBonusItem = id => {
    store.dispatch({
      model: 'bonusItems',
      type: 'UPDATE_ITEM',
      data: {
        id,
        expanded: true,
      },
    })
  }

  unexpandBonusItem = id => {
    store.dispatch({
      model: 'bonusItems',
      type: 'UPDATE_ITEM',
      data: {
        id,
        expanded: false,
      },
    })
  }

  preview = () => {
    const {
      currentCampaign: { uid },
      currentBrand: { id: brandId },
    } = this.props
    window.open(`/landing/post-page/${uid}/${brandId}?preview=true`)
  }

  openPurchaseModal = () => {
    const { socialActionsActions } = this.props
    socialActionsActions.closeUpsellModal()
    socialActionsActions.showPurchaseModal()
  }

  render() {
    const {
      currentCampaign,
      currentBrand,
      partnershipInvite,
      profile,
      bonusItems,
      upsellModalOpen,
      socialActionsActions,
    } = this.props

    const {
      bonusOption: { loading },
    } = this.state
    const { paid_social_actions } = partnershipInvite

    const isHost = currentCampaign.hostBrandId === currentBrand.id
    const isTrial = currentBrand.trial_end
      && moment(currentBrand.trial_end)
        .startOf('day')
        .isAfter(moment())
    const currentCount = bonusItems.presentationOrder.filter(
      item => item.partnership_invite_id === partnershipInvite.id
    ).length
    const paidLimit = paid_social_actions
    const beltLimit = getClaim('sweepstakes.socialAction.limit') || 0
    const totalLimit = paidLimit + beltLimit
    const availableCount = currentBrand.belt === 'black' || currentBrand.isSensei || isTrial
      ? 'unlimited'
      : Math.max(totalLimit - currentCount, 0)

    const showBuySocialActionsBtn = getClaim('sweepstakes.socialAction.purchase') && !currentCampaign.started

    return (
      <Fragment>
        <SocialActionsUpsellModal
          isOpen={upsellModalOpen}
          closeUpsellModal={socialActionsActions.closeUpsellModal}
          openPurchaseModal={this.openPurchaseModal}
          campaignid={currentCampaign.id}
        />
        <Card
          style={{
            width: '100%',
            padding: '16px 20px 24px 20px',
            overflow: 'visible',
          }}
        >
          <div className={SocialTheme.headerContainer}>
            <H3 coral>
              <small>Social Growth</small>
            </H3>
            <div>
              {showBuySocialActionsBtn && (
                <Button
                  onClick={this.openUpsellModal('socialGrowthClickedBuyMoreHeader')}
                  theme={ButtonTheme}
                  className={ButtonTheme.whiteButtonAzureText}
                  label="Buy Social Growth"
                  raised
                />
              )}
            </div>
          </div>
          <Row style={{ marginBottom: '24px' }}>
            <P multiline>
              Grow your social channels by offering additional incentives to entrants who engage
              with your social media channels.
            </P>
            {isHost && (
              <P style={{ marginTop: '22px' }}>
                As a Host, you choose the order in which Social Growth actions appear on the Post
                Entry.
              </P>
            )}
          </Row>
          <Row>
            <Col style={{ width: '229px', marginRight: '32px' }}>
              <BonusOptions
                currentBrand={currentBrand}
                partnershipInvite={partnershipInvite}
                profile={profile}
                bonusItems={bonusItems}
                addBonusItem={this.addBonusItem}
              />
            </Col>
            <Col style={{ width: '851px' }}>
              {(getClaim('sweepstakes.socialAction.create') || paid_social_actions !== 0) && (
              // {(currentBrand.belt !== 'white' || paid_social_actions !== 0) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '40px',
                    width: '100%',
                    border: '1px dashed #dee4e5',
                    marginBottom: '12px',
                  }}
                >
                  <P multiline>
                    You have
                    <strong>{` ${availableCount} `}</strong>
                    Social Action
                    {availableCount === 1 ? '' : 's'}
                    {availableCount === 'unlimited' ? '.' : ' left.'}
                    {' '}
                    {!availableCount && !currentCampaign.started && (
                      <Button
                        onClick={this.openUpsellModal('socialGrowthTitleNoActionsLeft')}
                        theme={ButtonTheme}
                        className={ButtonTheme.whiteButtonAzureText}
                        label="Buy More"
                        primary
                        flat
                        style={{
                          paddingLeft: '6px',
                          paddingRight: '6px',
                          marginLeft: '3px',
                        }}
                      />
                    )}
                  </P>
                </div>
              )}
              <BonusItems
                loading={loading}
                isHost={isHost}
                bonusItems={bonusItems}
                campaignStarted={currentCampaign.started}
                partnershipInvite={partnershipInvite}
                deleteBonusItem={this.deleteBonusItem}
                updateBonusItem={this.updateBonusItem}
                expandBonusItem={this.expandBonusItem}
                unexpandBonusItem={this.unexpandBonusItem}
                showUpsellModal={socialActionsActions.showUpsellModal}
                currentCampaign={currentCampaign}
              />
            </Col>
          </Row>
        </Card>
      </Fragment>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    dispatchToProps
  )(GrowOptimize)
)
