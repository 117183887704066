import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCookie } from 'util/cookie'
import queryString from 'query-string'
import Field from 'util/components/field'
import Validator from 'util/form-validator'
import RegistrationHeader from 'components/registration/registration-header'
import PasswordField from 'util/components/password-field'
import { Button } from 'react-toolbox/lib/button'

import { H2, P, Small } from 'visual-components/util/texts'
import theme from 'css/themes/login/registrationTheme.css'
import buttonTheme from 'css/themes/Buttons.css'
import validQueryParams from 'util/valid-query-params'
import * as metrics from 'util/metrics'
import { memoize } from 'lodash'

const validations = {
  email: [Validator.required, Validator.emailTaken, Validator.isValidEmail, Validator.validDomain],
  password: [Validator.isValidPassword],
  firstName: [Validator.required],
  lastName: [Validator.required],
}

const mapState = ({ register, registerForm }) => ({ register, registerForm })

function aliasAndRedirect(authMethod, uid) {
  const queryHash = {}

  validQueryParams.forEach(param => {
    if (getCookie(param)) {
      queryHash[param] = getCookie(param)
    }
  })

  setTimeout(_ => {
    const urlExtension = Object.keys(queryHash).length
      ? `?${queryString.stringify(queryHash)}`
      : ''
    window.location.href = `/app/registrations/brand-setup${urlExtension}`
  }, 400)
}

const track = memoize(action => () => {
  metrics.create('registrationFlow', {
    meta: {
      page: 'userRegistration',
      action,
    }
  })
})

const ConfirmTermsMessage = () => (
  <div className={theme.termsWrapper}>
    <Small multiline>
      <i>
        { 'By clicking Create Trial Account, you agree to our ' }
        <a href="/home/terms-of-use" target='_blank'>Terms</a>
        { ' and '}
        <a href="/home/privacy" target='_blank'>Privacy Policy</a>
      </i>
    </Small>
  </div>
)

const LinkedinGoogleButtons = ({ registerLinkedin }) => (
  <div className={theme.loginWithRow}>
    <img
      src="/images/linkedin/nr-lg-default.png"
      srcSet="/images/linkedin/r-lg-default.png 2x"
      onClick={registerLinkedin}
      alt="Register with LinkedIn"
      className={`abcRioButton ${theme.linkedInButton}`}
    />
    <div
      id="g-signin2"
      className={theme.googleButton}
      onClick={track('openedGoogle')}
    />
  </div>
)

class RegistrationForm extends Component {
  state = {
    error_exists: false,
    error_msg: 'Invalid Email/Password',
    blockSubmit: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    track('loadedFirstPage')()

    // listen for message from LinkedIn login pop-up window
    const that = this

    window.addEventListener('message', msg => {
      if (msg && msg.data && msg.data.linkedInSuccess) {
        track('registeredLinkedInSuccess')()
        aliasAndRedirect('LinkedIn', msg.data.id)
      } else if (msg && msg.data && msg.data.linkedInSuccess === false) {
        track('registeredLinkedInError')()
        this.setState({
          error_exists: true,
          error_msg: msg.data.error,
        })
      }
    })

    const googleInterval = setInterval(() => {
      if (window.gapi) {
        window.gapi.signin2.render('g-signin2', {
          scope: 'profile email',
          width: 235,
          height: 44,
          longtitle: true,
          theme: 'dark',
          onsuccess: that.registerGoogle,
          onfailure: that.registerGoogle,
        })
        clearInterval(googleInterval)
      }
    }, 100)
  }

  componentWillUnmount() {
    this.clearForm()
  }

  clearForm = () => {
    store.dispatch({
      model: 'register',
      type: 'CLEAR',
    })
    store.dispatch({
      model: 'registerForm',
      type: 'CLEAR',
    })
  }

  register = e => {
    e.preventDefault()
    track('submittedForm')()
    const { register } = this.props
    const {
      email,
      password,
      firstName,
      lastName,
    } = register

    this.setState({ blockSubmit: true })

    Validator.validateForm(validations, register, 'registerForm', valid => {
      if (valid) {
        $.ajax({
          method: 'POST',
          url: '/register',
          data: {
            email,
            password,
            firstName,
            lastName,
          },
          success(uid) { aliasAndRedirect('Email', uid) },
          error(err) {
            track('submitError')()
            try {
              err = JSON.parse(err.responseText).error
            } catch (e) {
              err = 'Invalid Email/Password'
            }
            this.setState({
              error_exists: true,
              error_msg: err,
              blockSubmit: false,
            })
          },
        })
      } else {
        this.setState({ blockSubmit: false })
      }
    })
  }

  registerLinkedIn = () => {
    track('openedLinkedIn')()
    window.open('/register/linkedin', 'linkedin_window', 'height=600,width=600')
  }

  registerGoogle = response => {
    if (response.error) {
      track('registeredGoogleError')()
      return this.setState({
        error_exists: true,
        error_msg: 'The user cancelled the authorization'
      })
    }

    track('registeredGoogleSuccess')()

    return $.ajax({
      method: 'POST',
      url: '/register/google',
      data: {
        idtoken: response.getAuthResponse().id_token,
      },
      success(uid) {
        aliasAndRedirect('Google', uid)
      },
      error(err) {
        this.setState({
          error_exists: true,
          error_msg: err.responseText,
        })
        window.gapi.auth2.getAuthInstance().signOut()
      },
    })
  }

  render() {
    const { register, registerForm } = this.props
    const { blockSubmit, error_exists, error_msg } = this.state

    const registrationModel = register
    const registerDisabled = !registrationModel.email
      || !registrationModel.firstName
      || !registrationModel.lastName
      || !registrationModel.password

    return (
      <div>
        <RegistrationHeader step={1} />
        <div className={theme.container}>
          <div className={theme.headerTitle}>
            <H2 multiline>
              Start your Premium Free Trial - no credit card required.
            </H2>
          </div>
          <div className={theme.subheaderTitle}>
            <P multiline>
              Sign up to start connecting with brands, growing your audience and building your email list.
            </P>
          </div>
          <div className={theme.errorTextWrapper}>
            <p className={`invalid-credentials ${theme.errorText} ${error_exists ? '' : 'hide'}`}>
              { error_msg }
            </p>
          </div>
          <LinkedinGoogleButtons registerLinkedin={this.registerLinkedIn} />
          <div className={theme.orRow}>
            <P>
              OR
            </P>
          </div>
          <form className={`form ${theme.form}`} role="form">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <Field
                  validations={validations.firstName}
                  type="text"
                  attrName="firstName"
                  label="First Name *"
                  dataModel={register}
                  formModel={registerForm}
                />
              </div>
              <div className="col-xs-12 col-sm-6">
                <Field
                  validations={validations.lastName}
                  type="text"
                  attrName="lastName"
                  label="Last Name *"
                  dataModel={register}
                  formModel={registerForm}
                />
              </div>
            </div>
            <Field
              validations={validations.email}
              type="email"
              attrName="email"
              label="Your Work Email *"
              dataModel={register}
              formModel={registerForm}
            />
            <PasswordField
              register={register}
              registerForm={registerForm}
              attrName="password"
            />
            <ConfirmTermsMessage />
            <div className={theme.buttonWrapper}>
              <Button
                type="submit"
                onClick={this.register}
                className={registerDisabled || blockSubmit ? `${buttonTheme.disabledButton} ${buttonTheme.noMargin}` : `${buttonTheme.blueButton} ${buttonTheme.noMargin}`}
                theme={buttonTheme}
                label="Create Trial Account"
                disabled={registerDisabled || blockSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapState)(RegistrationForm))
