import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import { P } from 'visual-components/util/texts'
import { Collapse } from 'react-collapse'

import theme from 'css/themes/nested-checkbox-list.css'

class NestedCheckboxList extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.node).isRequired,
    selectAll: PropTypes.string.isRequired,
  }

  state = {
    open: false,
  }

  toggle = () => this.setState({ open: !this.state.open })

  render() {
    const { name, selectAll, options } = this.props
    const { open } = this.state

    return (
      <div className={theme.nestedContainer}>
        <div className={theme.firstRow} onClick={this.toggle}>
          <P>{name}</P>
          <img src={`/images/icons/blue-collapse-icons/industry-list-${open ? 'collapse' : 'expand'}-arrow.svg`} />
        </div>

        <div className={theme.checkboxes}>
          {selectAll}
          <Collapse isOpened={open}>
            {options}
          </Collapse>
        </div>
      </div>
    )
  }
}

export default NestedCheckboxList
