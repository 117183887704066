import React, { Component } from 'react'
import GridListToggleTheme from 'css/themes/gridListToggle.css'
import { P } from 'visual-components/util/texts'

class GridListToggle extends Component {
  render() {
    let {
      selected,
      onclick
    } = this.props;

    let grid = selected == 'grid';

    return(
      <div className={ GridListToggleTheme.card }>
          <div onClick={ e => onclick('grid') }
            className={ `${GridListToggleTheme.option} ${GridListToggleTheme.leftOption} ${ grid ? GridListToggleTheme.selected : GridListToggleTheme.notSelected}` }>
            <P cloudGrey={ !grid }
              azure={ grid }>
              <i className={ `fa fa-th-large ${GridListToggleTheme.icon}` }
                aria-hidden="true"
              />
              <i>
                Grid
              </i>
            </P>
          </div>
          <div onClick={ e => onclick('table') }
            className={ `${GridListToggleTheme.option} ${GridListToggleTheme.rightOption} ${ !grid ? GridListToggleTheme.selected : GridListToggleTheme.notSelected}` }>
            <P cloudGrey={ grid }
              azure={ !grid }>
              <i>
                List
              </i>
              <i className={ `fa fa-bars ${GridListToggleTheme.icon}` }
                aria-hidden="true"
              />
            </P>
          </div>
      </div>
    )
  }
}

export default GridListToggle;
