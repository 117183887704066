import React, { Component } from 'react'
import { bool, object, func } from 'prop-types'
import { connect } from 'react-redux'
import Clipboard from 'clipboard'

import OpportunityTheme from 'css/themes/opportunityProfile.css'

const typeMap = {
  product_shipment_insert: 'Box-Insert Swap',
  content_swap: 'Content Swap',
  dedicated_email_buy: 'Dedicated Email Buy',
  dedicated_email_sell: 'Dedicated Email Sale',
  prizing_partner: 'Prizing Partner',
}

const mapStateToProps = ({
  currentBrand: { accountname: currentBrandName } = {},
  profile: { displayName } = {},
}) => ({
  currentBrandName,
  displayName,
})

export class ShareOpportunityCommon extends Component {
  static propTypes = {
    opportunity: object.isRequired,
    render: func.isRequired,
    isPublic: bool,
  }

  componentDidMount() {
    this.initialize()
  }

  componentDidUpdate() {
    this.initialize()
  }

  initialize = () => {
    const node = document.querySelectorAll('.copy-link')
    if (node) {
      new Clipboard(node)
    }

    window.fbAsyncInit = function() {
      FB.init({
        appId: FBappId,
        xfbml: true,
        version: 'v2.4',
      })
    }
  }

  notify = text =>
    typeof toastr !== 'undefined' &&
    toastr.success(text, null, {
      timeOut: 1000,
      positionClass: 'toast-bottom-center',
    })

  get host() {
    if (typeof window !== 'undefined') {
      return window.location.origin
    } else if (process.env.DEV) {
      return 'http://localhost:5000'
    } else if (process.env.DEV_ENV) {
      return 'https://dev.dojomojo.com'
    } else if (process.env.STAGE) {
      return 'https://staging.dojomojo.com'
    } else {
      return 'https://www.dojomojo.com'
    }
  }

  get url() {
    const { type, id } = this.props.opportunity
    return `${this.host}/app/explore/opportunities/${type}/${id}`
  }

  get shareUrl() {
    return encodeURIComponent(this.url)
  }

  get shareHeadline() {
    return encodeURIComponent(this.props.opportunity.headline)
  }

  get shareDetails() {
    return encodeURIComponent(this.props.opportunity.details)
  }

  subject = encodeURIComponent('Check out this partnership on DojoMojo')

  get body() {
    const {
      opportunity: { brand: { accountname }, type },
      isPublic,
      currentBrandName,
      displayName,
    } = this.props

    const body = `Hi there,\n\n${
      accountname
    } is looking for a brand to connect with for a ${
      type === 'custom' ? '' : `${typeMap[type] || ''} `
    }partnership.\n\nCheck it out at ${this.url}`

    const signoff = `\n\nCheers,\n${displayName}\n${currentBrandName}`

    return encodeURIComponent(body + (isPublic ? '' : signoff))
  }

  options = [
    {
      key: 'link',
      label: 'Copy Link',
      onClick: () => this.notify('Link copied to clipboard'),
    },
    {
      key: 'email',
      label: 'Email',
      onClick: () =>
        (window.location = `mailto:?subject=${this.subject}&body=${this.body}`),
    },
    {
      key: 'facebook',
      label: 'Facebook',
      onClick: () => {
        FB.ui(
          {
            method: 'feed',
            display: 'popup',
            link: this.url,
          },
          response => {}
        )
      },
    },
    {
      key: 'twitter',
      label: 'Twitter',
      onClick: () =>
        window.open(
          `https://twitter.com/intent/tweet?text=${this.shareHeadline}&url=${
            this.shareUrl
          }`
        ),
    },
    {
      key: 'linkedin',
      label: 'LinkedIn',
      onClick: () =>
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${
            this.shareUrl
          }&title=${this.shareHeadline}&summary=${
            this.shareDetails
          }&source=DojoMojo`
        ),
    },
  ]

  render() {
    return (
      <div>
        {this.props.render({
          options: this.options,
          url: this.url,
        })}
      </div>
    )
  }
}

export default connect(mapStateToProps)(ShareOpportunityCommon)
