import React from 'react';
import PropTypes from 'prop-types';

import COLOURS from 'util/colours';

import ProfileMainCard from 'visual-components/util/profile-main-card';
import LegendItem from './legend-item';
import CardHeaderItem from './card-header-item';

var createGraph = function(data){
  var data = [{
    x: '100K +',
    y: data.over100
  },{
    x: '75-99k',
    y: data.sub99
  },{
    x: '50-74k',
    y: data.sub74
  },{
    x: '35-49k',
    y: data.sub49
  },{
    x: '<35k',
    y: data.sub30
  }]

  return new Contour({
    el: '.income-card',
    chart: {
      plotHeight: 200
    },
    pie: {
      style: {
        height: 100
      }
    },
    legend: {
      vAlign: 'bottom'
    },
    tooltip: {
      formatter: function (d) {
        const val = d.data.x + ': ' + d3.format('1%')(d.value);
        return `<div style="background-color: white; padding: 10px; border-radius: 4px;"><strong style="color: #000">${val}</strong></div>`;
      }
    }
  })
  .pie(data)
  .tooltip()
  .render();
}

class IncomeCard extends React.Component {
  componentDidMount() {
    createGraph(this.props.distribution);
  }

  render() {
    return (
      <div style={{ 
        position: 'relative', 
        width: '460px' 
      }}>
        <ProfileMainCard title={ 'Income' }>
          <div style={{
            display: 'inline-block',
            width: '280px'
          }}>
            <CardHeaderItem label="ANNUAL HOUSEHOLD INCOME ($)" />
            <LegendItem
              label="< 35K"
              color={ COLOURS.blues.five } />
            <LegendItem
              label="75-99K"
              color={ COLOURS.blues.two } />
            <LegendItem
              label="35-49K"
              color={ COLOURS.blues.four } />
            <LegendItem
              label="100K +"
              color={ COLOURS.blues.one } />
            <LegendItem
              label="50-74k"
              color={ COLOURS.blues.three } />
          </div>
          <div style={{
            position: 'absolute',
            top: '25px',
            right: '-25px',
            width: '290px',
            height: '290px'
          }}>
            <div className="income-card" />
          </div>
        </ProfileMainCard>
      </div>
    )
  }
}

IncomeCard.propTypes = {
  distribution: PropTypes.object.isRequired
}

export default IncomeCard;