import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import SideNav from 'visual-components/sideBars/side-nav'
import UserInfoSettings from 'visual-components/userSettings/user-info-settings'
import PasswordSettings from 'visual-components/userSettings/password-settings'
import EmailNotificationSettings from 'visual-components/userSettings/email-notification-settings'
import COLOURS from 'util/colours'

class UserSettings extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const prefix = this.props.match.path

    const routes = [
      {
        label: 'User Info',
        to: '/info',
      },
      {
        label: 'Password',
        to: '/password',
      },
      {
        label: 'Email Notifications',
        to: '/email-notifications',
      },
    ]

    return (
      <div
        style={{
          width: '100%',
          background: COLOURS.cream,
        }}
      >
        <InternalContentContainer width="1176px">
          <Route
            exact
            path={`${prefix}/(info|password|email-notifications)`}
            render={routeProps => (
              <SideNav
                width="240px"
                routes={routes}
                prefix={prefix}
                {...routeProps}
              />
            )}
          />

          <div
            style={{
              width: '100%',
            }}
          >
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/info`} />
              <Route
                exact
                path={`${prefix}/info`}
                component={UserInfoSettings}
              />
              <Route
                exact
                path={`${prefix}/password`}
                component={PasswordSettings}
              />
              <Route
                exact
                path={`${prefix}/email-notifications`}
                component={EmailNotificationSettings}
              />
              <Redirect to="/404" />
            </Switch>
          </div>
        </InternalContentContainer>
      </div>
    )
  }
}

export default UserSettings
