const sliderDefaults = {
  conversionRate: 0.15,
  avgLifetimeValue: 250,
  avgCampaignSpend: 150,
}

const initialState = {
  conversionRate: null,
  avgLifetimeValue: null,
  avgCampaignSpend: null,
  brandNTF: 0,
  numOfCampaigns: 0,
  roi: 0,
  hasCustomCTA: false,
  hasHosted: false,
  loading: false,
  numOfMonths: 0,
  avgMonthlyFee: 0,
}

const calculateROIData = data => {
  const {
    numOfCampaigns,
    avgCampaignSpend,
    avgLifetimeValue,
    conversionRate,
    brandNTF,
    numOfMonths,
    avgMonthlyFee,
  } = data

  const totalSpend = numOfCampaigns * avgCampaignSpend
  const conversions = Math.round(brandNTF * conversionRate / 100)
  const valueReturned = avgLifetimeValue * conversions
  const softwareFees = numOfMonths * avgMonthlyFee
  const totalInvestment = totalSpend + softwareFees
  const roi = ((valueReturned - totalInvestment) / totalInvestment) || 0

  return {
    totalSpend,
    conversions,
    valueReturned,
    softwareFees,
    totalInvestment,
    roi,
    numOfCampaigns,
    avgCampaignSpend,
    avgLifetimeValue,
    conversionRate,
    brandNTF,
    numOfMonths,
    avgMonthlyFee,
  }
}

const ROICalculatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONVERSION_RATE':
    case 'SET_AVERAGE_LIFETIME_VALUE':
    case 'SET_AVERAGE_SPEND_PER_CAMPAIGN': {
      const newState = { ...sliderDefaults, ...state, ...action.data }
      const roiData = calculateROIData(newState)
      return { ...newState, ...roiData }
    }
    case 'ROI_BRAND_INTEGRATIONS_INFO_SUCCESS':
      return { ...state, ...action.payload }

    case 'GET_ROI':
      return { ...state, loading: true }
    case 'GET_ROI_SUCCESS': {
      const roiData = calculateROIData(action.payload)
      return {
        ...state,
        ...roiData,
        loading: false,
      }
    }
    case 'GET_ROI_FAILURE':
      return { ...state, loading: false }
    default:
      return state
  }
}

export default ROICalculatorReducer
