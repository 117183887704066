import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import COLOURS from 'util/colours'
import { Link } from 'react-router-dom'

const Ellipsis = (
  <span
    style={{
      marginTop: '-5px',
      padding: '9px 24px 7px 0',
      fontFamily: 'Larsseit-Medium',
      fontSize: '16px',
      color: COLOURS.coral,
    }}
  >
    ...
  </span>
)

class Pagination extends Component {
  createActive = ({ page, label }) => {
    return (
      <div
        key={label || page}
        style={{
          backgroundColor: label ? 'transparent' : COLOURS.coral,
          height: '32px',
          width: label ? '81px' : '32px',
          display: 'inline-flex',
          borderRadius: '100%',
          marginRight: label ? '' : '12px',
          marginLeft: label ? '' : '-12px',
          verticalAlign: 'middle',
          textAlign: 'center',
          paddingTop: '9px',
          marginTop: '-3px',
        }}
      >
        <span
          style={{
            color: label ? COLOURS.coral : COLOURS.white,
            fontFamily: 'Larsseit-Medium',
            fontSize: '16px',
            width: '32px',
          }}
        >
          {label || page}
        </span>
      </div>
    )
  }

  createLink({ page, label, ellipsis }) {
    let {
      to,
      page: currentPage,
    } = this.props

    if (page === currentPage) {
      return this.createActive({ page, label })
    }

    if (typeof to === 'object') {
      to = Object.assign({}, to)
      to.state = { page }
    } else if (typeof to === 'string') {
      to += `/${page}`
    }

    return (
      <span key={label || page}>
        { ellipsis === 'left' ? Ellipsis : null }
        <Link
          to={to}
          style={{
            paddingRight: '24px',
            color: COLOURS.coral,
            fontFamily: 'Larsseit-Medium',
            fontSize: '16px',
          }}
        >
          {label || page}
        </Link>
        { ellipsis === 'right' ? Ellipsis : null }
      </span>
    )
  }

  createLinks(start, end) {
    const links = []
    for (let i = start; i <= end; i++) {
      links.push(this.createLink({ page: i }))
    }
    return links
  }

  render() {
    const { page: current, size, total } = this.props

    if (!total) {
      return null
    }

    const numPages = !size ? 1 : Math.ceil(total / size)

    const Prev = this.createLink({ page: current - 1 || 1, label: 'Previous' })
    const First = this.createLink({ page: 1, ellipsis: 'right' })
    const Last = this.createLink({ page: numPages, ellipsis: 'left' })
    const Next = this.createLink({ page: current >= numPages ? numPages : current + 1, label: 'Next' })

    const links = [Prev]

    if (numPages <= 8) {
      // no ellipsis required
      links.push(...this.createLinks(1, numPages))
    } else if (current <= 4) {
      // right ellipsis required
      links.push(...this.createLinks(1, 6), Last)
    } else if (current >= numPages - 4) {
      // left ellipsis required
      links.push(First, ...this.createLinks(numPages - 5, numPages))
    } else {
      // both ellipses required
      links.push(First, ...this.createLinks(current - 1, current + 2), Last)
    }

    links.push(Next)

    if (total <= size) return null

    return (
      <div
        style={{
          paddingTop: '40px',
          paddingBottom: '36px',
          textAlign: 'center',
        }}
      >
        { links }
      </div>
    )
  }
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

export default Pagination
