import React from 'react'
import PropTypes from 'prop-types'

import { H3, Small } from 'visual-components/util/texts'

import Theme from 'css/themes/ExpandableCalculatorTheme.css'

class ExpansionPanel extends React.Component {
  static propTypes = {
    rowData: PropTypes.array.isRequired,
    selected: PropTypes.bool,
  }

  static defaultProps = {
    selected: false,
  }

  mapRows = data => {
    return data.map((item, idx) => {
      return (
        <div key={idx} className={Theme.expansionRow}>

          <div className={Theme.block1}>
            <div className={Theme.label}>
              <Small>
                {item[0].title}
              </Small>
            </div>
            <div className={Theme.value}>
              <H3 azure={item[0].isBlue}>
                {item[0].value}
              </H3>
            </div>
          </div>

          <div className={Theme.multiplicationIcon}>
            <img src="/images/icons/expansion-panel-icons/close-x-icon@3x.png" style={{ height: '9px', width: '9px' }} />
          </div>

          <div className={Theme.block2}>
            <div className={Theme.label}>
              <Small>
                {item[1].title}
              </Small>
            </div>
            <div className={Theme.value}>
              <H3 azure={item[1].isBlue}>
                {item[1].value}
              </H3>
            </div>
          </div>

          <div className={Theme.equalIcon}>
            <img src="/images/icons/expansion-panel-icons/equals-sign@3x.png" style={{ height: '5px', width: '9px' }} />
          </div>

          <div className={Theme.block3}>
            <div className={Theme.label}>
              <Small>
                {item[2].title}
              </Small>
            </div>
            <div className={Theme.value}>
              <H3>
                {item[2].value}
              </H3>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    const { selected, rowData } = this.props

    return (
      <div className={`${Theme.expansionPanel} ${selected && Theme.selected}`}>
        { this.mapRows(rowData) }
      </div>
    )
  }
}

export default ExpansionPanel
