import React, { Component } from 'react'


class Button extends Component {
  render() {
    let oldStyles = {}

    let base = {
      fontFamily: 'GothamRnd-Medium',
      borderRadius: '1px',
      fontSize: '16px',
      minHeight: '50px',
      minWidth: '220px',
      paddingBottom: '0',
      outline: 'none'
    }

    if ( this.props.oldStyles ) {
      oldStyles = {
        minHeight: '38px',
        minWidth: '158px',
        marginBottom: '15px',
        marginTop: '15px',
        fontSize: '12px'
      }
    }

    let combinedButtonStyles = Object.assign({}, base, oldStyles, ...this.props.styles)

    return (
      <button
        disabled={ this.props.disabled }
        onClick={ this.props.onClick || function(){} }
        type={ this.props.type || "button" }
        className={ "btn btn-primary " + this.props.className }
        data-toggle={ this.props['data-toggle'] }
        style={ combinedButtonStyles }
        data-clipboard-text = { this.props.dataClipboardText }
        disabled={ this.props.disabled }
      >
        { this.props.children }
      </button>
    )
  }
}

export default Button
