import React, { PureComponent } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'

import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import cn from 'classnames'

/* react-toolbax */
import AppBar from 'react-toolbox/lib/app_bar'
import Navigation from 'react-toolbox/lib/navigation'
import { Link } from 'react-toolbox/lib/link'

/* analytics */
import * as metrics from 'util/metrics'

/* dojomojo components */
import { isOffsitePlan, isTrialing } from 'util/brand-stripe-belt-helper'
import { relativeDate } from 'util/relativeDate'
import AppBarTheme from 'css/themes/app-bar-theme.css'
import buttonStyles from 'css/themes/Buttons.css'
import NavTrialCountdown from './nav-trial-countdown'
import NavMessagesDropdown from './dropdowns/NavMessagesDropdown'
import CreateDropdown from './dropdowns/CreateDropdown'
import NavLogoLink from './nav-logo-link'
import NavBrandMenu from './nav-brand-menu'

const themes = {
  AppBarTheme,
  Button: buttonStyles
}

const mapState = ({
  currentBrand,
  profile,
  customer,
  conversations,
  notifications,
  referrals,
  deals,
}) => ({
  currentBrand,
  profile,
  customer,
  conversations: conversations.conversations,
  unreadCount: conversations.unread,
  notifications: notifications.items,
  unreadNotificationCount: notifications.unreadCount,
  unreadReferrals: referrals.unreadCount,
  unreadPartnerships: deals.totalUnreadCount > 0,
  loadingItems: Boolean(conversations.loading || notifications.loading),
})

const promoCountdown = [
  {
    startDisplay:
    process.env.SENTRY_ENV === 'production'
      ? moment.tz('2019-12-01', 'America/New_York')
      : moment.tz('2019-11-01', 'America/New_York'),
    endDisplay:
    process.env.SENTRY_ENV === 'production'
      ? moment.tz('2019-12-31', 'America/New_York').endOf('day')
      : moment.tz('2019-11-30', 'America/New_York').endOf('day'),
    promoText: '25% Holiday Discount',
    promoLink: '/upgrade-plan',
    eligibleStripeBelts: ['white', 'greenFixed', 'black'],
  },
]

class InternalNavBar extends PureComponent {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    unreadPartnerships: PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    unreadNotificationCount: PropTypes.number.isRequired,
    conversations: PropTypes.array.isRequired,
    unreadCount: PropTypes.number.isRequired,
    loadingItems: PropTypes.bool.isRequired,

    /* react-router-dom props */
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  trackClick = (from, to) => {
    // from the home page
    if (from === '/app/') {
      switch (to) {
        case '/analytics':
          metrics.create('homePageNavBarClickedAnalytics')
          break
        case '/partnerships':
          metrics.create('homePageNavBarClickedPartnerships')
          break
        case '/explore':
          metrics.create('homePageNavBarClickedExplore')
          break
        default:
          break
      }
    }
  }

  // generates an onClick for each link
  generateOnClick = to => to && ('pushState' in window.history) && (e => {
    e.preventDefault()
    this.trackClick(window.location.pathname, to)
    if (to.startsWith('http')) {
      window.open(to, '_blank')
    }
    this.props.history.push(to)
  })

  // generate special onClick for upgrade link and store original location
  upgradeOnClick = to => to && ('pushState' in window.history) && (e => {
    e.preventDefault()

    if (window.location.pathname === '/app/') {
      metrics.create('homePageNavBarClickedUpgrade')
    }

    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: 'navBar',
        upgradeOriginalLocation: this.props.history.location.pathname,
      },
    })

    this.props.history.push(to)
  })

  // decorate routes array passed to props
  mapRoutes() {
    const {
      routes,
      history,
      location: { pathname },
      unreadPartnerships,
      currentBrand,
    } = this.props

    return routes
      .filter(({ validation, ...other}) => validation ? validation(currentBrand) : {...other})
      .map(({
        label, to, overrideHref, target, exact,
      }) => ({
        label,
        theme: themes.AppBarTheme,
        onClick: !overrideHref && this.generateOnClick(to),
        active: pathname.startsWith(to) && (!exact || pathname === to),
        href: overrideHref || history.createHref({ pathname: to }),
        target: target || '_self',
        icon: label === 'My Partnerships' && unreadPartnerships ? 'lens' : null,
      }))
  }

  render() {
    const {
      currentBrand,
      profile,
      customer,
      history,
      notifications,
      unreadCount,
      unreadNotificationCount,
      conversations,
      loadingItems,
    } = this.props

    let hasCard = true

    /* this is the sample data */
    let trialEnd

    const sub = customer
      && customer.subscriptions
      && customer.subscriptions.data
      && customer.subscriptions.data.length
      ? customer.subscriptions.data[0]
      : null

    if (!customer.loading) {
      hasCard = customer && customer.sources && customer.sources.data && customer.sources.data.length > 0
    }

    const shouldShowTrialCountdown = isTrialing(currentBrand, customer)

    if (shouldShowTrialCountdown) {
      trialEnd = currentBrand.trial_end
    }

    // Check if brand is on offsite plan
    const offsitePlan = isOffsitePlan(currentBrand.stripe_belt)

    const hasPendingPreferredTermsPlan = !!currentBrand.pendingPreferredTermsPlan

    const shouldShowUpgrade = currentBrand.isSensei
      || (offsitePlan && !hasPendingPreferredTermsPlan)
      ? false
      : hasPendingPreferredTermsPlan || !(hasCard && currentBrand.belt == 'pro' && !shouldShowTrialCountdown)

    // Promo Logic (overrides default trial end countdown and Upgrade link)
    const availablePromo = promoCountdown.find(
      ({ startDisplay, endDisplay, eligibleStripeBelts }) =>
        moment().isBetween(startDisplay, endDisplay)
        && eligibleStripeBelts.includes(currentBrand.stripe_belt)
    )
    const daysLeft = availablePromo && moment(availablePromo.endDisplay).diff(moment(), 'days') + 1
    const promoCountdownText = availablePromo ? `${daysLeft} day${daysLeft > 1 ? 's' : ''} left` : null
    const upgradeLink = availablePromo ? availablePromo.promoText : shouldShowUpgrade ? 'Upgrade' : null

    const messagesData = _.chain(conversations)
      .filter({ conversation_archive: false })
      .filter(conversation => conversation.messages[0].sender_id != profile.id)
      .slice(0, 3)
      .map(conversation => ({
        id: conversation.conversation_id,
        img: conversation.conversation_brand_logo,
        brandName: conversation.conversation_brand_name,
        user: conversation.messages[0].firstName,
        message:
          conversation.messages[0].content
          && conversation.messages[0].content.replace(/<[^>]+>/g, ''),
        unread: !conversation.read,
        time: relativeDate(conversation.last_message_at),
      }))
      .value()

    const { canViewSignupForm = false } = currentBrand.features

    return (
      <AppBar theme={themes.AppBarTheme} fixed className={themes.AppBarTheme.forceBlue}>
        {/* left menu */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NavLogoLink />
          <Navigation type="horizontal" routes={this.mapRoutes()} />
          <CreateDropdown canViewSignupForm={canViewSignupForm} />
        </div>

        {/* right menu*/}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Navigation type="horizontal">
            <NavTrialCountdown
              shouldShow={shouldShowTrialCountdown}
              trialEnd={trialEnd}
              overrideText={promoCountdownText}
            />
            {profile.canImpersonate ? (
              <NavLink
                to="/cs-admin"
                className={cn(themes.AppBarTheme.link, themes.AppBarTheme.desktopOnly)}
                activeStyle={{ fontWeight: 'bold', color: 'white' }}
              >
                Admin
              </NavLink>
            ) : null}
            {upgradeLink ? (
              <a
                href="/app/upgrade-plan"
                className={`${themes.AppBarTheme.greenLink} ${
                  themes.AppBarTheme.minusleft
                } hidden-xs hidden-sm`}
              >
                {upgradeLink}
              </a>
            ) : null}
            <Link href="/app/search" active>
              <img
                className={themes.AppBarTheme.favoritesIcon}
                src="/images/icons/menu-icons/search-icon.svg"
                alt="search icon"
              />
            </Link>
            <Link href="/app/favorites" className={themes.AppBarTheme.desktopOnly} active>
              <img
                className={themes.AppBarTheme.favoritesIcon}
                src="/images/icons/menu-icons/heart-icon.svg"
                alt="favorites icon"
              />
            </Link>
            <NavMessagesDropdown
              notificationData={notifications}
              unreadNotificationCount={unreadNotificationCount}
              messagesData={messagesData}
              unreadCount={unreadCount}
              loading={loadingItems}
            />
            <NavLink
              to="/calendar"
              className={cn(themes.AppBarTheme.calendarText, themes.AppBarTheme.desktopOnly)}
            >
              Calendar
            </NavLink>
            <a
              href="https://help.dojomojo.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
              className={themes.AppBarTheme.calendarText}
            >
              Help
            </a>
            <NavBrandMenu
              imgSrc={currentBrand.logo}
              brandName={currentBrand.accountname}
              brandLogo={currentBrand.logo}
              profileHref={`/explore/brands/${currentBrand.id}`}
            />
          </Navigation>
        </div>
      </AppBar>
    )
  }
}

export default connect(mapState)(InternalNavBar)
