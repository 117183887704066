import React, { Component } from 'react';
import theme from 'css/themes/homePage/resources.css';

class ResourcesCaseStudies extends Component {
  render() {
    const children = React.Children.map(this.props.children, child => {
      const img = child.props.children
      const { src } = img.props
      const [file, ext] = src.split('.')
      const srcSet = `${src}, ${file}@2x.${ext} 2x, ${file}@3x.${ext} 3x`
      const Img = React.cloneElement(img, { srcSet })
      return React.cloneElement(child, { children: Img })
    })

    return(
      <div className={ theme.guidessContainer }>
        <p className={ theme.libraryTitle }>
          Guides and Papers
        </p>
        <div className={ theme.libraryWrappers }>
          {children}
        </div>
      </div>
    )
  }
}

export default ResourcesCaseStudies;
