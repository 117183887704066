import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import Axios from 'axios'
import requestHeaders from 'request-config'

import Spinner from 'util/components/spinner'
import { isTrialingAndNotUpgraded } from 'util/brand-stripe-belt-helper'

import BannerCloseable from './BannerCloseable'
import CreditsBanner from './CreditsBanner'

const BANNERS = {
  smsMessageNotPublished: {
    title: 'SMS Welcome Message Not Published',
    text:
      'You must create a welcome message for your SMS subscribers in order for your sweepstakes to go live.',
    buttonLabel: 'Create Welcome Message',
    alertAndHide: false,
    getButtonToLink: campaignId => `/partnerships/campaign/${campaignId}/welcome-message`,
  },
  prizingReminder: {
    title: 'Remember to Finalize Your Prize Details',
    text:
      'Make sure your prize details are all buttoned up before your launch! Your prize will appear on your sweepstakes landing page and in the official rules.',
    buttonLabel: 'Review Prizing',
    alertAndHide: true,
    getButtonToLink: campaignId => `/builder/${campaignId}/setup`,
  },
  postEntryNotPublished: {
    title: 'Post Entry Not Published',
    text:
      'You must publish your Post Entry Action for your sweepstakes to go live.',
    buttonLabel: 'Publish Post Entry',
    alertAndHide: false,
    getButtonToLink: campaignId => `/partnerships/campaign/${campaignId}/quickview`,
  },
}

const getCreditsBanner = (
  credits,
  isFreeCredits,
  { isTrial, subscriberCount }
) => (
  <CreditsBanner
    credits={credits}
    isFreeCredits={isFreeCredits}
    isTrial={isTrial}
    subscriberCount={subscriberCount}
  />
)

const getBannerKey = (bannerType, campaignId, brandId) => `${campaignId}-${bannerType}-${brandId}`

const getBannerCloseable = (bannerType, campaignId, brandId) => {
  const {
    title, text, buttonLabel, alertAndHide, getButtonToLink,
  } = BANNERS[bannerType]
  const bannerKey = getBannerKey(bannerType, campaignId, brandId)
  return (
    <BannerCloseable
      title={title}
      text={text}
      buttonLabel={buttonLabel}
      alertAndHide={alertAndHide}
      bannerKey={bannerKey}
      buttonToLink={getButtonToLink(campaignId)}
    />
  )
}

const getBanner = campaignBrandInfo => {
  const {
    credits,
    smsSendStatus,
    isBrandTrailing,
    campaignStartDate,
    brandId,
    campaignId,
    // ctaType, // prop to find sms-marketing
    showSmsOptin,
    subscriberCount,
  } = campaignBrandInfo
  const { count: paidCredits, freeCount: freeCredits } = credits
  const totalCredits = paidCredits + freeCredits
  const isFreeCredits = !!freeCredits && !paidCredits
  const campaignStarting168HoursOrLess = moment(campaignStartDate).isAfter(moment())
    && moment(campaignStartDate).diff(moment(), 'hours') <= 168

  const brandInfo = { isTrial: isBrandTrailing, subscriberCount }

  if (totalCredits === 0) {
    return getCreditsBanner(totalCredits, isFreeCredits, brandInfo)
  }

  if (smsSendStatus !== 'published' && showSmsOptin) {
    return getBannerCloseable('smsMessageNotPublished', campaignId, brandId)
  }

  // show banner if not published and publish postEntry
  // if (smsSendStatus !== 'published' && ctaType === 'mobileMarketing') {
  //   return getBannerCloseable('postEntryNotPublished', campaignId, brandId)
  // }


  if (campaignStarting168HoursOrLess) {
    return getBannerCloseable('prizingReminder', campaignId, brandId)
  }

  return null
}

function SoloNotificationBanner(props) {
  const { currentCampaign, currentBrand } = props
  const [smsSend, setSmsSend] = useState({ status: 'draft' })
  const [smsBank, setSmsBank] = useState(null)
  const [loading, setLoading] = useState(true)
  const { current: axios } = React.useRef(Axios.create({ headers: requestHeaders() }))

  const brandInvite = currentCampaign.invites.find(
    ({ invitee_id }) => invitee_id === currentBrand.id
  )

  useEffect(() => {
    async function fetchData() {
      try {
        const [
          { data: mobileMarketing },
          { data: smsBankData },
        ] = await Promise.all([
          axios.get(`/partnership/mobile-marketing/${brandInvite.id}`),
          axios.get('/brand-sms-bank'),
        ])

        setSmsSend(oldSmsSend => (mobileMarketing ? mobileMarketing.smsSend : oldSmsSend))
        setSmsBank(smsBankData)
      } catch (err) {
        console.log(err)
      }

      setLoading(false)
    }

    fetchData()
  }, [brandInvite.id])

  if (loading) return <Spinner />

  const { status: smsSendStatus } = smsSend
  const { credits, subscriberCount } = smsBank

  const {
    startDate: campaignStartDate,
    id: campaignId,
    invites: [{ ctaType }],
    showSmsOptin,
    smsStatus: smsStatusOverride,
  } = currentCampaign
  const { id: brandId } = currentBrand
  const isBrandTrailing = isTrialingAndNotUpgraded(currentBrand)
  const campaignBrandInfo = {
    campaignStartDate,
    isBrandTrailing,
    credits,
    smsSendStatus: smsStatusOverride || smsSendStatus,
    campaignId,
    brandId,
    ctaType,
    showSmsOptin,
    subscriberCount,
  }

  return getBanner(campaignBrandInfo)
}

SoloNotificationBanner.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
}

export default React.memo(SoloNotificationBanner)
