import React from 'react'
import COLOURS from 'util/colours'
import Dialog from 'react-toolbox/lib/dialog'
import dialogTheme from 'css/themes/dialogs/formModal.css'


class DojoModal extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    let {
      isOpen,
      hideModal,
      customHeader,
      children,
      title,
      className
    } = this.props

    return(
      <Dialog
        theme={dialogTheme}
        className={dialogTheme[className]}
        active={!!isOpen}
        onEscKeyDown={hideModal}
        onOverlayClick={hideModal}
        title={title}
      >
        { customHeader
          ? <customHeader />
          : null
        }
        {children}
      </Dialog>
    )
  }
};

export default DojoModal;
