import React, { Component } from 'react'
import { array, bool, func } from 'prop-types'
import MessageImageTheme from 'css/themes/media-market/assetVisuals/MessageImage.css'
import ImageMapper from './image-mapper'

class MultipleImage extends Component {
  static propTypes = {
    asset: array.isRequired,
    isSender: bool.isRequired,
    downloadAssetFunc: func.isRequired,
  }

  render() {
    const {
      asset,
      isSender,
      downloadAssetFunc,
    } = this.props

    const extraImagesCount = asset.length - 4

    return (
      <div className={MessageImageTheme.wrapper}>
        { isSender
        && (
          <img
            src="images/icons/blue-asset-download-icon.png"
            className={MessageImageTheme.downloadIconLeft}
            onClick={downloadAssetFunc}
          />
        )
        }

        <div className={MessageImageTheme.imageContainer}>
          <ImageMapper
            images={asset.slice(0, 4)}
            extraImagesCount={extraImagesCount}
          />
        </div>

        { !isSender
        && (
          <img
            src="images/icons/blue-asset-download-icon.png"
            className={MessageImageTheme.downloadIconRight}
            onClick={downloadAssetFunc}
          />
        )
        }
      </div>
    )
  }
}

export default MultipleImage
