import React from 'react'
import CampaignOptionRow from 'campaigns/builder/components/campaign-option-row'
import SuccessIcon from 'util/components/success-icon'

class HostingOption extends React.Component {
  constructor(props) {
    super(props)

    this.selectOption = this.selectOption.bind(this)
  }

  selectOption() {
    this.props.selectOption(this.props.type)
  }

  render() {
    return (
      <div
        className="row"
        key={this.props.type}
        style={{
          marginBottom: '20px',
        }}
      >
        <div className="col-sm-1 text-center">
          <div
            style={{
              display: 'table',
              height: '100px',
              marginLeft: '-15px',
            }}
          />
        </div>
        <div className="col-sm-11">
          <CampaignOptionRow
            disabled={this.props.disabled}
            title={this.props.title}
            optionText={this.props.body}
            confirmText={this.props.confirmText}
            isConfirmButton={this.props.isConfirmButton}
            onclick={!this.props.disabled && this.selectOption}
            image={this.props.image}
            fullImageHeight
            leftImage
          />
        </div>
      </div>
    )

    // return (
    //   <div key={ this.props.type }
    //     onClick={ this.selectOption }
    //     style={{
    //       backgroundColor: this.props.color,
    //       borderColor: COLOURS.inputFocusBorder,
    //       borderWidth: 1
    //    }}>
    //     {/*
    //       this.props.selected ? 'this is selected' : ''
    //     */}
    //     <p>{this.props.title}</p>
    //     <Text.dojoP>
    //       { this.props.body }
    //     </Text.dojoP>
    //   </div>
    // )
  }
}

export default HostingOption
