import React, { PureComponent as Component } from 'react'
import { object, func } from 'prop-types'
import Dialog from 'react-toolbox/lib/dialog'
import { SimpleForm, Input } from 'visual-components/util/form'
import { isWebsite } from 'visual-components/util/form/validations'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import ModalTheme from 'css/themes/dialogs/modalBase.css'
import { P } from 'visual-components/util/texts'

class TrackingLinkModal extends Component {
  static propTypes = {
    deal: object.isRequired,
    link: object.isRequired,
    actions: object.isRequired,
    closeEditModal: func.isRequired,
  }

  onSubmit = () => {
    const {
      deal,
      link,
      actions: { editTargetLink, createTargetLink },
    } = this.props

    if (link.id) {
      editTargetLink(deal.id, link)
    } else {
      createTargetLink(deal.id, link)
    }
  }

  render() {
    const {
      link,
      actions: { updateLinkAttr },
      closeEditModal,
    } = this.props

    if (!link) return null

    return (
      <div>
        <Dialog
          theme={ModalTheme}
          active={!!link}
          onEscKeyDown={closeEditModal}
          onOverlayClick={closeEditModal}
          title={link.uid ? 'Edit Target Link' : 'Create New Target Link'}
        >
          <img
            onClick={closeEditModal}
            className={ModalTheme.closeImage}
            src="/images/icons/close.svg"
          />

          <div
            style={{
              marginBottom: '16px',
            }}
          >
            <P multiline>
              { link.uid
                ? 'Edit your target link for this media sale.'
                : 'Create a new target link for this medial sale.'
              }
            </P>
          </div>

          <SimpleForm
            model={link}
            updateModel={e => updateLinkAttr(e)}
            handleSubmit={this.onSubmit}
          >
            <Input
              label="Label"
              name="label"
              placeholder="i.e. Newsletter, Facebook Page, Homepage..."
              type="string"
              required
            />

            <Input
              label="URL"
              name="target_url"
              placeholder="http://www.companysite.com/seller-brand-promo"
              type="string"
              disabled={!!link.uid}
              required
              validations={[isWebsite]}
            />

            <ModalButtonGroupWithContext
              canSave
              confirmText={link.uid ? 'Save Changes' : 'Add'}
              cancel={closeEditModal}
              cancelText="Cancel"
            />
          </SimpleForm>
        </Dialog>
      </div>
    )
  }
}

export default TrackingLinkModal
