import React from 'react'
import COLOURS from 'util/colours'
import propTypes from 'prop-types'
import { debounce } from 'lodash'

const regexPattern = /\d*/g
const inputStyles = {
  borderRadius: '2px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: COLOURS.silver,
  paddingLeft: '12px',
  width: '100%',
  textAlign: 'left',
  fontFamily: 'Larsseit-Light',
  color: COLOURS.ink,
  fontSize: '14px',
  height: '40px',
}

class FontSizeInput extends React.Component {
  static propTypes = {
    attr: propTypes.string.isRequired,
    model: propTypes.object.isRequired,
    elements: propTypes.object.isRequired,
    updateAttr: propTypes.func.isRequired,
    customLabelStyles: propTypes.object,
    buttonTextField: propTypes.bool,
  }

  static defaultProps = {
    customLabelStyles: {},
    buttonTextField: false,
  }

  state = {
    fontSizeInput:
      typeof this.props.model[this.props.attr].size === 'string'
        ? this.props.model[this.props.attr].size
        : `${this.props.model[this.props.attr].size}px`,
    buttonText: this.props.elements[this.props.attr],
  }

  updateFontSize = debounce((fontSizeInput, noPxLabel = false) => {
    const data = {
      fonts: {},
    }
    let [fontSize] = fontSizeInput.match(regexPattern)
    if (fontSize < 10) {
      fontSize = 10
    }
    if (fontSize > 100) {
      fontSize = 100
    }
    data.fonts[this.props.attr] = {
      size: `${fontSize}px`,
    }
    if (!noPxLabel) {
      this.setState({ fontSizeInput: `${fontSize}px` })
    } else {
      this.props.updateAttr('styles', data)
    }
  }, 300)

  updateButtonText = debounce(buttonText => {
    this.props.updateAttr(this.props.attr, buttonText)
  }, 300)

  changeFontSize = e => {
    const { value: fontSizeInput } = e.target
    this.setState({
      fontSizeInput,
    })
    this.updateFontSize(fontSizeInput, true)
  }

  changeButtonText = e => {
    const { value: buttonText } = e.target
    this.setState({
      buttonText,
    })
    this.updateButtonText(buttonText)
  }


  render() {
    const { customLabelStyles, buttonTextField } = this.props
    const { fontSizeInput, buttonText } = this.state
    return (
      <div style={{ width: '100%' }}>
        <div style={{ ...customLabelStyles, display: 'block', paddingBottom: '8px' }}>
          <span>{buttonTextField ? 'Text' : 'Font Size'}</span>
        </div>
        {buttonTextField ? (
          <input
            type="text"
            min="10"
            max="100"
            onChange={this.changeButtonText}
            value={buttonText}
            style={inputStyles}
          />
        ) : (
          <input
            type="text"
            min="10"
            max="100"
            onBlur={() => {
              this.updateFontSize(fontSizeInput)
            }}
            onChange={this.changeFontSize}
            value={fontSizeInput}
            style={inputStyles}
          />
        )}
      </div>
    )
  }
}

export default FontSizeInput
