import Validator from 'util/validator';
import _ from 'lodash'

export default {
  update_attr: function () {
    Validator.validateAttr(action, function (data) { // { valid: true/false,}
      store.dispatch({
        model: action.fieldModel,
        data: data,
        type: 'UPDATE_ATTR'
      })
    });
    // store.dispatch({

    // })
    // return _.extend({}, state, { [action.dataModel]: action.data });
  },
  http: function(action) {
    // return function () {
    $.ajax({
      url: action.url,
      method: action.method || 'GET',
      data: JSON.stringify(action.data),
      contentType: 'application/json',
      success: action.success,
      error: action.error
    })
    // }
  },
  loading: function (action) {
    store.dispatch({
      type: 'LOADING',
      model: action.model
    });
  },
  loadOnce: function (action) {
    if (!action.data.loaded) {
      this.load(action);
      store.dispatch({
        type: action.type || 'UPDATE_ATTR',
        model: action.model,
        data: {
          loaded: true
        }
      });
      if (action.success) {
        action.success();
      }
    }
  },
  load: function (action) {
    this.loading(action);
    this.http({
      method: 'GET',
      url: action.endpoint,
      success: function (data) {
        store.dispatch({
          type: 'LOAD_SUCCESS',
          model: action.model,
          data: data
        });
        if (action.success) {
          action.success(data);
        }
      }
    });
  }
}
