import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import injectSheet from 'react-jss'

import GrowOptimize from 'visual-components/campaignOverview/GrowOptimize'
import DataIntegrationSuggestion from 'components/dialogs/data-integration-suggestion'

const styles = {
  container: {
    paddingTop: 20,
  },
}

class SoloQuickview extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  state = {
    showDataIntegrationModel: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (Math.random() <= 0.3) {
      this.setState({ showDataIntegrationModel: true })
    }
  }

  render() {
    const {
      classes,
    } = this.props

    const {
      showDataIntegrationModel,
    } = this.state

    return (
      <div className={classes.container}>
        <GrowOptimize isSolo />
        {showDataIntegrationModel && <DataIntegrationSuggestion left={-30} />}
      </div>
    )
  }
}

export default withRouter(injectSheet(styles)(SoloQuickview))
