import React from 'react'

import Validator from 'util/form-validator'
import Field from 'visual-components/util/inputs/field'

class InstagramFollow extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    // eventually use this to speed up and check if this component should rerender
    return true
  }

  render() {
    const { bonus: { id, partnership_invite_id, data }, bonusItems, updateBonusDataAttr } = this.props

    return (
      <Field
        label="Instagram Handle"
        attrName="instagramHandle"
        updateAttr={updateBonusDataAttr}
        dataModel={data}
        formModel={{}}
        validations={[Validator.required, Validator.testTwitterHandle]}
      />
    )
  }
}

export default InstagramFollow
