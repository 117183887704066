import React from 'react';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import Card from 'react-toolbox/lib/card';
import COLOURS from 'util/colours';
import PopoutRichEditor from 'visual-components/richEditor/PopoutRichEditor';

import { Grid, Row, Col } from 'react-flexbox-grid';
import * as ReactRedux from 'react-redux';
import { stateToHTML } from 'draft-js-export-html';
import {convertFromHTML, ContentState} from 'draft-js';

import Transition from 'react-transition-group/Transition';

import PropTypes from 'prop-types';


class MessageSlider extends React.Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.convertHTMLtoContentBlock = this.convertHTMLtoContentBlock.bind(this);
  }

  convertHTMLtoContentBlock(html) {
    const blocksFromHTML = convertFromHTML(html);
    const contentBlock = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return contentBlock
  }

  save(contentState) {
    if (this.props.onSend) {
      this.props.onSend(contentState);
    }
  }

  render() {
    let { title } = this.props;

    let defaultStyle = {
      position: 'fixed',
      background: '#fff',
      width: '520px',
      height: '440px',
      right: '214px',
      bottom: '-440px',
      display: 'none',
      transition: 'bottom 200ms ease-in-out',
      zIndex: '4'
    }

    let transitionStyles = {
      entering: { bottom: '0px', display: 'inline-block' },
      entered: { bottom: '0px', display: 'inline-block' },
      exiting: { bottom: '-440px', display: 'inline-block' },
      exited: { bottom: '-440px', display: 'none' }
    }

    return (
      <div style={{
        zIndex: 100
      }}>
        <Transition in={this.props.isShown} timeout={500}>
          {(status) => (
            <Card key='statusCard'
              style={{
                ...defaultStyle,
                ...transitionStyles[status]
            }}>
              <Row style={{
                background: COLOURS.azure
              }}>
                <Col xs={9}>
                  <p style={{
                    fontFamily: 'Larsseit-Medium',
                    fontSize: '14px',
                    color: '#fff',
                    padding: '16px 13px 11px',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    textOverflow: 'ellipsis'
                  }}>
                    { title }
                  </p>
                </Col>
                <Col xs={3} style={{
                  display: 'inline-block',
                  textAlign: 'right'
                }}>
                  <img src="/images/icons/messaging-icons/close-x-icon-white.svg" onClick={ this.props.onClose } style={{
                    cursor: 'pointer',
                    marginTop: '13px',
                    marginRight: '13px'
                  }}/>
                </Col>
              </Row>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <PopoutRichEditor
                    buttonText={ this.props.buttonText || 'Send Message' }
                    buttonClick={ this.save }
                    styleOptions={['Bold', 'Italic', 'UL']}
                    initialContent={ this.convertHTMLtoContentBlock(this.props.initialContent || '') }
                  />
                </div>
              </div>
            </Card>
          )}
        </Transition>
      </div>
    )
  }
}

MessageSlider.propTypes = {
  onSend: PropTypes.func,
  initialContent: PropTypes.string,
  buttonText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isShown: PropTypes.bool.isRequired
}

export default MessageSlider;
