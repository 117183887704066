import React from 'react';
import OpportunityCard from './opportunity-card';
import InterestValue from '../interests/interest-value';

import OpportunityTheme from 'css/themes/opportunityProfile.css'

class PrizingPartnerOpportunityCard extends React.Component {
  render() {
    let {
      opportunity,
      brand,
      brand: { obscure },
      isBrand,
      action,
      showActions,
      hideBar,
      editOpportunity,
      shareOpportunity,
      deleteOpportunity
    } = this.props

    return (
      <OpportunityCard
        opportunity={ opportunity }
        brand={ brand }
        action={ action }
        editOpportunity={ editOpportunity }
        deleteOpportunity={ deleteOpportunity }
        shareOpportunity={ shareOpportunity }
        isBrand={isBrand}
        showActions={showActions}
        hideBar={hideBar}
      >
        <div className={ OpportunityTheme.prizingPartnerOpportunityOverflow }>
          <InterestValue
            title='Contribution'
            value={ opportunity.contribution }
            marginBottom='0px'
            obscure={obscure}
          />
        </div>
      </OpportunityCard>
    )
  }
}

export default PrizingPartnerOpportunityCard;
