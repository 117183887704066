import React from 'react'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function SaveChangesLiveModal() {
  const { showModal, modals, saveChanges } = React.useContext(MobileMarketingContext)

  const onClose = () => showModal('saveChangesLive', false)
  const onConfirm = () => saveChanges({ isConfirmed: true }).then(onClose)

  return (
    <SimpleInfoModal
      title="Sure You Want to Save Your Changes?"
      active={modals.saveChangesLive}
    >
      <H4 multiline>
          Your SMS Marketing Post Entry Action is already live. Saving changes will make them immediately visible to entrants.
      </H4>
      <ModalButtonGroup
        canSave
        confirm={onConfirm}
        cancel={onClose}
        confirmText="Save"
        cancelText="Cancel"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(SaveChangesLiveModal)
