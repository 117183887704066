import React, { PureComponent as Component } from 'react'
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P, H2, H6 } from 'visual-components/util/texts'
import Badge from 'visual-components/util/badge'
import COLOURS from 'util/colours'

import SoloQuickview from 'visual-components/campaignOverview/SoloQuickview'
import WelcomeMessage from 'visual-components/campaignOverview/WelcomeMessage'
import LinksFiles from 'visual-components/campaignOverview/LinksFiles'
import EmailDelivery from 'visual-components/campaignOverview/EmailDelivery'
import OverviewActionsContainer from 'components/partnerships/OverviewActionsContainer'
import WinnerModal from 'visual-components/myPartnerships/winner-modal'
import CreateCampaignLimitModal from 'components/campaigns/CreateCampaignLimitModal'

import OverviewTheme from 'css/themes/Overview.css'

import OverviewNav from './OverviewNav'
import SoloStatsWinnerCards from '../campaignOverview/SoloStatsWinnerCards'
import SoloNotificationBanners from '../mobile-marketing/SoloNotificationBanners'
import SoloPostEntryAction from '../quickview/SoloPostEntryAction'

class SoloOverview extends Component {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    winnerModalOpen: PropTypes.bool.isRequired,
    closeWinnerModal: PropTypes.func.isRequired,
    chooseWinner: PropTypes.func.isRequired,
    launchCampaign: PropTypes.func.isRequired,
  }

  render() {
    const {
      currentCampaign,
      match,
      winnerModalOpen,
      closeWinnerModal,
      chooseWinner,
      launchCampaign,
      currentBrand,
    } = this.props
    const { path, url } = match

    let soloBadge = { label: 'Live', color: COLOURS.coral }
    if (new Date(currentCampaign.startDate) > new Date()) {
      soloBadge = { label: 'Upcoming', color: COLOURS.cloudGrey }
    } else if (new Date(currentCampaign.endDate) < new Date()) {
      soloBadge = { label: 'Completed', color: COLOURS.cerulean }
    }

    return (
      <div>
        <div className={OverviewTheme.overviewContainerNav}>
          <div className={OverviewTheme.overviewContainer}>
            <div className={OverviewTheme.overviewWrapper}>
              <Grid>
                <Row>
                  <SoloNotificationBanners
                    currentBrand={currentBrand}
                    currentCampaign={currentCampaign}
                  />
                </Row>
                <Row style={{ marginBottom: 8 }}>
                  <Col>
                    <Badge
                      label={soloBadge.label}
                      color={soloBadge.color}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={7}>
                    <div style={{ marginBottom: 24 }}>
                      <H2 multiline>
                        {currentCampaign.name}
                      </H2>
                    </div>
                    <div style={{ display: 'flex', marginBottom: 20 }}>
                      <div style={{ marginRight: 72 }}>
                        <div style={{ marginBottom: 8 }}>
                          <H6>
                            Dates
                          </H6>
                        </div>
                        <P>
                          {`${moment(currentCampaign.startDate).format('LL')} - ${moment(currentCampaign.endDate).format('LL')}`}
                        </P>
                      </div>
                      <div>
                        <div style={{ marginRight: 38, marginBottom: 32 }}>
                          <div style={{ marginBottom: 8 }}>
                            <H6>
                              SMS Opt-In for Sweepstakes
                            </H6>
                          </div>
                          <P>
                            {currentCampaign.showSmsOptin ? 'Enabled' : 'Disabled'}
                          </P>
                        </div>
                      </div>
                      {/* Note: This will likely come back at one point. Bryan should remove if it doesnt */}
                      {/*<SoloPostEntryAction currentCampaign={currentCampaign} />*/}
                    </div>
                  </Col>
                  <OverviewActionsContainer
                    currentCampaign={currentCampaign}
                    pickWinner={chooseWinner}
                    onLaunchCampaign={launchCampaign}
                    isSolo
                  />
                </Row>
                <Row>
                  <SoloStatsWinnerCards chooseWinner={chooseWinner} />
                </Row>
              </Grid>
            </div>
          </div>
          <div className={OverviewTheme.overviewContainer} style={{ borderTop: `1px solid ${COLOURS.silver}` }}>
            <div className={OverviewTheme.overviewWrapper}>
              <Route
                path={`${path}/:selected`}
                render={
                  ({ match }) => (
                    <OverviewNav
                      prefixedUrl={url}
                      selected={match.params.selected}
                      isSolo
                    />
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={OverviewTheme.overviewContainer}>
          <div className={OverviewTheme.overviewWrapper}>
            <Switch>
              <Route path={`${path}/quickview`} render={() => (<SoloQuickview chooseWinner={chooseWinner} />)} />
              <Route path={`${path}/welcome-message`} component={WelcomeMessage} />
              <Route path={`${path}/email-delivery/:section?/:subsection?`} component={EmailDelivery} />
              <Route path={`${path}/links-files`} component={LinksFiles} />
              <Redirect to="/404" />
            </Switch>
          </div>
        </div>
        <WinnerModal
          isOpen={winnerModalOpen}
          currentCampaign={currentCampaign}
          hideModal={closeWinnerModal}
        />
        <CreateCampaignLimitModal isSolo />
      </div>
    )
  }
}

export default withRouter(SoloOverview)
