import React, { Component } from 'react'
import * as ReactRedux from 'react-redux';
import moment from 'moment-timezone'
import Dialog from 'react-toolbox/lib/dialog';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import COLOURS from 'util/colours';
import { withRouter } from 'react-router-dom';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

class AcceptPartnershipModal extends Component {
  hideModal = () => {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showPartnershipAcceptModal: Object.assign({}, this.props.showModal, { active: false })
      }
    })
  }

  nav = (showModal) => {
    this.hideModal();
    this.props.history.push( `/explore/campaigns/${showModal ? showModal.campaignId : null}` );
  }

  render(){ 
    var { showModal, showLogoMessaging } = this.props;

    showModal = showModal || {};

    return (
      <Dialog
        active={ !!showModal.active }
        onEscDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
      >
        { showModal && showModal.active ?
            <div>
              <Row>
                <Col xs={12} style={{
                  textAlign: 'center',
                  marginTop: '6px',
                  marginBottom: '16px'
                }}>
                  <p style={{
                    fontSize: '20px',
                    fontFamily: 'Larsseit-Medium'
                  }}>
                    { showModal.application ? `${showModal.inviteeName} Is In!` : `You're In!`}
                  </p>
                </Col>
              </Row>
              { showModal.application ?
                  <div>
                    <Row>
                      <Col xs={12}>
                        <div>
                          <p style={{
                            fontFamily: 'Larsseit-Light',
                            fontSize: '16px',
                            lineHeight: '24px',
                            display: 'inline'
                          }}>
                            Be sure to provide them with details on how to promote this campaign.
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <ModalButtonGroup 
                      canSave={ true }
                      hideLine={ true }
                      confirm={ this.hideModal }
                      confirmText="Got It"
                    />
                  </div>
                :
                  <div>
                    <Row>
                      <Col xs={12}>
                        <p style={{
                          fontFamily: 'Larsseit-Light',
                          fontSize: '16px',
                          lineHeight: '24px',
                          display: 'inline'
                        }}>
                          You're confirmed as a brand partner on
                        </p>
                        <p style={{
                          fontFamily: 'Larsseit-Medium',
                          fontSize: '16px',
                          lineHeight: '24px',
                          display: 'inline'
                        }}>
                          { ` ${showModal.campaignHostBrand}` }
                        </p>
                        <p style={{
                          fontFamily: 'Larsseit-Light',
                          fontSize: '16px',
                          lineHeight: '24px',
                          display: 'inline'
                        }}>
                          's campaign,
                        </p>
                        <p style={{
                          fontFamily: 'Larsseit-Medium',
                          fontSize: '16px',
                          lineHeight: '24px',
                          display: 'inline'
                        }}>
                          { ` ${showModal.campaignName}` }
                        </p>
                        <p style={{
                          fontFamily: 'Larsseit-Light',
                          fontSize: '16px',
                          lineHeight: '24px',
                          display: 'inline'
                        }}>
                          , scheduled to run from {moment(showModal.campaignStartDate).format('L')} - {moment(showModal.campaignEndDate).format('L')}.
                        </p>
                      </Col>
                    </Row>
                    <Row style={{
                      marginTop: '16px'
                    }}>
                      <Col xs={12}>
                        <p style={{
                          fontFamily: 'Larsseit-Light',
                          fontSize: '16px',
                          lineHeight: '24px'
                        }}>
                          { window.location.href.indexOf('campaigns') != -1 ? null : 'Click below to see the campaign and prepare to promote it.' } For more detailed next steps, check out our <a href="https://help.dojomojo.com/hc/en-us" target="_blank"><span style={{color: COLOURS.azure, fontFamily: 'Larsseit-Bold'}}>Knowledge Base</span></a>.
                        </p>
                      </Col>
                    </Row>
                    {showLogoMessaging &&
                      <Row style={{
                        marginTop: '16px'
                      }}>
                        <Col xs={12}>
                          <p style={{
                            fontFamily: 'Larsseit-Light',
                            fontSize: '16px',
                            lineHeight: '24px'
                          }}>
                          Please be sure to add logo, so your brand’s logo is part of the campaign landing page.
                          </p>
                        </Col>
                      </Row>
                    }
                    { window.location.href.indexOf('campaigns') != -1 ?
                        <ModalButtonGroup 
                          canSave={ true }
                          hideLine={ true }
                          cancel={ this.hideModal }
                          cancelText="Dismiss"
                        />
                      :
                        <ModalButtonGroup 
                          canSave={ true }
                          hideLine={ true }
                          confirm={ () => this.nav(showModal) }
                          cancel={ this.hideModal }
                          cancelText="Dismiss"
                          confirmText="Go to Campaign"
                        />
                    }
                  </div>
              }
            </div>
          :
            null
        }
      </Dialog>
    )
  }
}


const mapState = (state) => {
  return {
    showModal: state.conversations.showPartnershipAcceptModal,
    showLogoMessaging: !state.currentBrand.logo || state.currentBrand.logo.indexOf('Empty-Avatar.png') >= 0
  }
}

export default withRouter(ReactRedux.connect(mapState)(AcceptPartnershipModal));
