import React from 'react'
import { withRouter } from 'react-router-dom'

import TertiaryNavTheme from 'css/themes/tertiaryNav.css'

class NavItem extends React.Component {
  nav = () => {
    const { navigate, link, history } = this.props

    if (navigate) {
      navigate()
    } else {
      history.push(link)
    }
  }

  render() {
    const {
      disabled,
      selected,
      label
    } = this.props

    return (
      <li className={ TertiaryNavTheme.navItem }
        onClick={ disabled ? null : this.nav }>
        <small className={ `${selected ? TertiaryNavTheme.selected : '' } ${disabled ? TertiaryNavTheme.disabled : '' }` }>
          { label }
        </small>
      </li>
    )
  }
}

class TertiaryNav extends React.Component{
  render() {
    const {
      base,
      selected,
      options,
      navigate,
      children,
      history
    } = this.props

    const navItems = options.map((item, idx) => {
      let id = item.id ? '/' + item.id : ''
      return (
        <NavItem key={ idx }
          link={ `${base}/${item.path}${id}` }
          label={ item.label }
          path={ item.path }
          navigate={ navigate }
          disabled={ item.disabled }
          selected={ selected === item.path }
          history={ history }
        />
      )
    })

    return (
      <nav className={ `navbar navbar-fixed-top ${TertiaryNavTheme.navBar}` }>
        <div className="container">
          <ul className="nav navbar-nav"
            style={{
              width: '100%'
          }}>
            { navItems }
            { children }
          </ul>
        </div>
      </nav>
    )
  }
}

export default withRouter(TertiaryNav)
