import React, { Component } from 'react';

import CareersBanner from './careers-banner'
import PerksAndBenefits from 'visual-components/careers/shared/perks-and-benefits';
import OurValues from 'visual-components/careers/shared/our-values';
import CurrentOpenings from 'visual-components/careers/shared/current-openings';
import Footer from '../footer/Footer';

import theme from 'css/themes/homePage/careers.css';

class Careers extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  render() {
    return(
      <div className={ theme.careersPage }>
        <CareersBanner />
        <PerksAndBenefits />
        <OurValues />
        <CurrentOpenings />
        <Footer />
      </div>
    )
  }
}

export default Careers;
