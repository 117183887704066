import React from 'react'
import {
  instanceOf, arrayOf, func, object,
} from 'prop-types'

import moment from 'moment-timezone'
import _ from 'lodash'

import CalendarTheme from 'css/themes/calendar/calendar.css'
import Month from './Month'
import Toolbar from './Toolbar'

class Calendar extends React.Component {
  static propTypes = {
    tasks: arrayOf(object),
    campaigns: arrayOf(object),
    onNavigate: func,
    onTaskSelect: func,
    onCampaignSelect: func,
    onShowMore: func,
  }

  static childContextTypes = {
    date: instanceOf(Date),
    tasks: arrayOf(object),
    campaigns: arrayOf(object),
    onNavigate: func,
    onTaskSelect: func,
    onCampaignSelect: func,
    onShowMore: func,
    dragTask: func,
    dragTaskNewDate: func,
    dragTaskRelease: func,
    dragTaskItem: object,
  }

  state = {
    date: moment(this.props.initialDate || new Date()).startOf('month').add(2, 'week')._d,
    dragTaskItem: {},
  }

  getChildContext() {
    const { date, dragTaskItem } = this.state
    const obj = _.pick(this.props, [
      'onCampaignSelect',
      'onShowMore',
      'onTaskSelect',
      'tasks',
      'campaigns',
    ])

    obj.date = date
    obj.dragTaskItem = dragTaskItem
    obj.dragTask = this.dragTask
    obj.dragTaskNewDate = this.dragTaskNewDate
    obj.dragTaskRelease = this.dragTaskRelease

    return obj
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.dragTaskRelease)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.dragTaskRelease)
  }

  dragTask = task => {
    this.setState({
      dragTaskItem: task,
    })
  }

  dragTaskNewDate = date => {
    const { dragTaskItem } = this.state

    if (!dragTaskItem.id) {
      return
    }

    this.setState({
      dragTaskDateUpdate: true,
      dragTaskItem: { ...dragTaskItem, due: date },
    })
  }

  dragTaskRelease = () => {
    const {
      dragTaskItem,
      dragTaskDateUpdate,
    } = this.state

    const {
      updateTaskDueDate,
    } = this.props

    if (!dragTaskItem.id) {
      return
    }

    if (dragTaskDateUpdate) {
      updateTaskDueDate({ ...dragTaskItem, due_date: moment(dragTaskItem.due) })
    }

    this.setState({
      dragTaskItem: {},
      dragTaskDateUpdate: false,
    })
  }

  handleNavigate = action => {
    const { onNavigate } = this.props
    const { date, key } = this.state

    let newDate

    switch (action) {
      case 'TODAY':
        newDate = new Date()
        break
      case 'PREV':
        newDate = moment(date).subtract(1, 'month')._d
        break
      case 'NEXT':
        newDate = moment(date).add(1, 'month')._d
        break
      default:
        break
    }

    this.setState({
      date: newDate,
      // What is this for? To force a rerendering?
      key: action === 'TODAY' ? Math.random() : key,
    })

    return onNavigate(newDate)
  }

  render() {
    const {
      key,
    } = this.state

    const {
      exportCalendar
    } = this.props

    return (
      // What is key prop for? Rerendering again?
      <div key={key} className={CalendarTheme.calendarContainer}>
        <div className={CalendarTheme.calendar}>
          <Toolbar handleNavigate={this.handleNavigate} exportCalendar={exportCalendar} />
          <Month handleNavigate={this.handleNavigate} />
        </div>
      </div>
    )
  }
}

export default Calendar
