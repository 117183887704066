import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'react-toolbox/lib/button'
import injectSheet from 'react-jss'

import { H4 } from 'visual-components/util/texts'
// this is failing!
// import LockIcon from 'images/icons/lock-icon/lock@2x.png'

import ButtonTheme from 'css/themes/Buttons.css'

const styles = {
  wrapper: {
    backgroundColor: 'transparent',
    backgroundImage: 'url("/images/example-graph@2x.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: '50% 90%',

    position: 'absolute',
    top: 0,
    width: '100%',
    height: 480,
  },
  outterContainer: {
    backgroundColor: 'rgba(44, 53, 65, 0.64)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0 0 2px 2px',
  },
  textContainer: {
    display: 'flex',
    height: 480,
    width: 360,
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      marginBottom: 16,
    },
  },
  neonText: {
    color: '#64ffda',
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
}

const NoIntegrationEmptyState = props => {
  const { upsellClickHandler, classes } = props

  return (
    <div className={classes.wrapper}>
      <div className={classes.outterContainer}>
        <div className={classes.textContainer}>
          <img
            // src={LockIcon}
            src="/images/icons/lock-icon/lock.svg"
            alt=""
          />
          <H4 white multiline>
            <em>
              Want to know your conversions from DojoMojo?
            </em>
            <div style={{ marginTop: '20px' }}>
              <Link to="/profile/brand/data-integrations">
                <Button
                  theme={ButtonTheme}
                  className={`${ButtonTheme.whiteButtonAzureText} ${classes.noHover}`}
                  onClick={upsellClickHandler}
                >
                  Yes Please!
                </Button>
              </Link>
            </div>
          </H4>
        </div>
      </div>
    </div>
  )
}

NoIntegrationEmptyState.propTypes = {
  upsellClickHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(NoIntegrationEmptyState)
