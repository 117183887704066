import React from 'react';
import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet'

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import * as DataIntegrationsActions from 'actions/data-integration-actions';

import Field from 'visual-components/util/inputs/field';
import Validator from 'util/form-validator';
import { H2, P } from 'visual-components/util/texts';
import ButtonTheme from 'css/themes/Buttons.css';
import { Button } from 'react-toolbox/lib/button';
import { bindActionCreators } from "redux";

import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'

const mapDispatchToProps = (dispatch) => {
  return {
    dataIntegrationsActions: bindActionCreators(DataIntegrationsActions, dispatch)
  }
};

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    currentBrandForm: state.currentBrandForm
  }
}

class AnalyticsSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { location } = this.props
    const page_title = this.page_title
    gtagPageTrack({ page_title, location })
  }

  page_title = 'Retargeting'

  render() {
    var {
      currentBrand,
      currentBrandForm,
      dataIntegrationsActions: {
        update
      }
    } = this.props;

    return(
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>{this.page_title}</title>
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '12px'
          }}>
            <Col xs>
              <H2 azure>
                {this.page_title}
              </H2>
            </Col>
          </Row>
          <Row style={{
            marginBottom: '24px'
          }}>
            <Col xs>
              <P multiline>
                Enter your IDs here to retarget your new audience with these advertising platforms (optional).
              </P>
            </Col>
          </Row>
          <Row>
            <Col style={{
              width: '360px',
              marginRight: '32px'
            }}>
              <Field label={ 'Google Analytics ID' }
                attrName={ 'googleAnalyticsCode' }
                placeholder={ 'UA-000000-2' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />

              <Field label={ 'Facebook Pixel ID' }
                attrName={ 'facebookPixelId' }
                placeholder={ '000000000000001' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />

              <Field label={ 'Comscore ID' }
                attrName={ 'comscoreId' }
                placeholder={ '00000003' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />
            </Col>
            <Col style={{
              width: '360px'
            }}>
              <Field label={ 'AdRoll ID' }
                attrName={ 'adRollId' }
                placeholder={ 'JEZ4A6GE4ZHHBE6MY7T3LJ' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />

              <Field label={ 'AdRoll Pixel ID' }
                attrName={ 'adRollPixelId' }
                placeholder={ 'DNH3NBVHQFC2XC6BGZWUTK' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />
              <Field label="LiveIntent LiveConnect Tag ID"
                attrName="liveConnectTagId"
                placeholder="a-XXXX"
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
};

export default ReactRedux.connect(mapState, mapDispatchToProps)(AnalyticsSettings);
