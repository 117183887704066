import React from 'react'
import { Tiny } from 'visual-components/util/texts'
import Theme from 'css/themes/media-market/DealCollaborate.css'
import propTypes from 'prop-types'
import Spinner from 'util/components/spinner'
import Comment from './viewer-comment'
import SliderContainer from '../util/slider-container'

class Viewer extends React.Component {
  static propTypes = {
    assetUrl: propTypes.string,
    createNewComment: propTypes.func.isRequired,
    newComment: propTypes.object,
    onCommentClick: propTypes.func.isRequired,
    zoomPercent: propTypes.number.isRequired,
    leftColumn: propTypes.func.isRequired,
    rightColumn: propTypes.func.isRequired,
    comments: propTypes.array.isRequired,
    selectedCommentId: propTypes.object,
    scrollViewerToAnnotation: propTypes.object,
    setViewerScrolledToAnnotation: propTypes.func.isRequired,

  }

  static defaultProps = {
    assetUrl: null,
    selectedCommentId: {},
    newComment: null,
    scrollViewerToAnnotation: null,
  }

  state = {
    asset: {},
    isLoading: false,
    imgWidth: Viewer.constants.defaultWidth,
    imgHeight: Viewer.constants.defaultHeight,
    toolTipCoords: { x: 0, y: 0 },
    showToolTip: false,
  }

  viewerRef = null

  componentDidMount() {
    const { assetUrl } = this.props
    if (assetUrl !== null) {
      this.loadImage(assetUrl)
    }
  }

  componentDidUpdate(oldProps) {
    const { assetUrl, zoomPercent, scrollViewerToAnnotation } = this.props
    if (assetUrl !== oldProps.assetUrl) {
      this.loadImage(assetUrl)
    } else if (zoomPercent !== oldProps.zoomPercent) {
      this.updateZoom(zoomPercent)
    }

    if (scrollViewerToAnnotation !== null) {
      this.scrollIntoView(scrollViewerToAnnotation)
    }
  }

  setViewerRef = element => {
    this.viewerRef = element
  }

  onCommentCreate = event => {
    const { createNewComment, zoomPercent } = this.props
    //todo make sure the bubble is in the boundries of the image
    const { imgWidth, imgHeight } = this.state
    const { bubbleLength } = Viewer.constants
    const zoomProportion = zoomPercent / 100

    const { offsetLeft, offsetTop } = event.target

    //calculate the x and y position while taking into account
    //the window scroll, container scroll, and container position.
    const { top, left } = event.target.getBoundingClientRect()
    let x = event.pageX - offsetLeft - left - window.pageXOffset
    let y = event.pageY - offsetTop - top - window.pageYOffset

    //bubble offset
    const halfBubbleLength = bubbleLength / 2
    x -= halfBubbleLength
    y -= halfBubbleLength

    //convert for the zoom
    x /= zoomProportion
    y /= zoomProportion

    createNewComment(x, y)
  }

  onMouseMove = event => {
    const { offsetLeft, offsetTop } = event.currentTarget
    let x = event.pageX - offsetLeft - window.pageXOffset
    let y = event.pageY - offsetTop - window.pageYOffset

    //offset to position the cursor in the left middle position
    x += 15
    y -= 12

    this.setState({
      toolTipCoords: { x, y },
    })
  }

  onMouseEnter = () => {
    this.setState({ showToolTip: true })
  }

  onMouseLeave = () => {
    this.setState({ showToolTip: false })
  }

  scrollIntoView(annotation) {
    const { setViewerScrolledToAnnotation, zoomPercent } = this.props

    //account for zoom
    const zoomProportion = zoomPercent / 100
    let x = annotation.x_coord * zoomProportion
    let y = annotation.y_coord * zoomProportion

    //center annotation
    const { offsetWidth, offsetHeight } = this.viewerRef.parentElement
    x -= offsetWidth / 2
    y -= offsetHeight / 2

    this.viewerRef.parentElement.scrollTo(x, y)
    setViewerScrolledToAnnotation()
  }

  loadImage(assetUrl) {
    const { defaultWidth, defaultHeight } = Viewer.constants

    //reset viewer
    this.setState({
      asset: {},
      isLoading: true,
      imgWidth: defaultWidth,
      imgHeight: defaultHeight,
    })

    //load new image
    const imgLoader = new Image()
    imgLoader.onload = event => {
      const imgRef = event.target
      const { zoomPercent } = this.props
      this.setState({
        asset: imgRef,
        isLoading: false,
      }, () => {
        this.updateZoom(zoomPercent)
      })
    }
    imgLoader.src = assetUrl
  }

  updateZoom(zoomPercent) {
    const { asset } = this.state
    const zoomProportion = zoomPercent / 100
    this.setState({
      imgWidth: Viewer.constants.defaultWidth * zoomProportion,
      imgHeight: asset.height * (Viewer.constants.defaultWidth / asset.width) * zoomProportion,
    }, () => {
      //todo make this animate per frame. Not smooth right now.
      // scroll to center
      // setTimeout(() => {
      //   if (this.viewerRef !== null) {
      //     const { offsetWidth, offsetHeight } = this.viewerRef
      //     const {
      //       offsetWidth: parentOffsetWidth,
      //       offsetHeight: parentOffsetHeight,
      //     } = this.viewerRef.parentElement
      //     const scrollOffsetX = Math.max((offsetWidth - parentOffsetWidth) / 2, 0)
      //     const scrollOffsetY = Math.max((offsetHeight - parentOffsetHeight) / 2, 0)
      //     this.viewerRef.parentElement.scrollTo(scrollOffsetX, scrollOffsetY)
      //   }
      // }, 300)
    })
  }

  static constants = {
    defaultWidth: 600,
    defaultHeight: 600,
    bubbleLength: 24,
  }

  static noImage(isLoading) {
    return (
      isLoading
        ? <Spinner />
        : <div>Select an Asset</div>
    )
  }

  static renderToolTip({ x, y }) {
    return (
      <div
        className={Theme.toolTipContainer}
        style={{
          left: x,
          top: y,
        }}
      >
        <Tiny white>
          <small>
            <em>
              Click anywhere to add a comment.
            </em>
          </small>
        </Tiny>
      </div>
    )
  }

  renderAsset() {
    const {
      asset,
      imgWidth,
      imgHeight,
    } = this.state
    const {
      zoomPercent,
      onCommentClick,
      comments,
      newComment,
      selectedCommentId: { value },
    } = this.props
    const zoomProportion = zoomPercent / 100

    return (
      <div
        ref={this.setViewerRef}
        className={Theme.assetContainer}
        onClick={this.onCommentCreate}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{
          width: imgWidth,
          height: imgHeight,
        }}
      >
        {comments
          //Try to add a new comment. If it's null then remove it
          .concat(newComment)
          .filter(comment => comment !== null && comment.is_annotation)
          .map((comment, index) => (
            <Comment
              {...comment}
              key={comment.id}
              index={index}
              zoomProportion={zoomProportion}
              onClick={onCommentClick}
              selected={comment.id === value}
            />
          ))}
        <img
          src={asset.src}
          className={Theme.asset}
        />
      </div>
    )
  }

  render() {
    const {
      asset,
      isLoading,
      showToolTip,
      toolTipCoords,
    } = this.state
    const {
      leftColumn,
      rightColumn,
    } = this.props
    return (
      <div
        className={Theme.body}
        onMouseMove={this.onMouseMove}
      >
        {showToolTip && Viewer.renderToolTip(toolTipCoords)}

        <SliderContainer side="left">
          {leftColumn()}
        </SliderContainer>

        <div
          className={Theme.viewerContainer}
        >
          {
            asset.src
              ? this.renderAsset()
              : Viewer.noImage(isLoading)
          }

        </div>

        <SliderContainer side="right">
          {rightColumn()}
        </SliderContainer>
      </div>
    )
  }
}

export default Viewer
