import React from 'react'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import { H4 } from 'visual-components/util/texts'

class RemovePartnerDialog extends React.Component {
  render() {
    const { active, removePartner, closeModal } = this.props

    return (
      <div>
        <DefaultDialog active={ active }
          closeModal={ closeModal }
          title="Are You Sure You Want to Remove This Partner"
          confirm={removePartner}
          confirmText="Remove"
          cancel={closeModal}
          cancelText="Cancel"
          children={
            <div>
              <H4 multiline>
                Removing this Partner from your sweepstakes is permanent.  You'll need to send them a new invitation to add them again.
              </H4>
            </div>
          }
        />
      </div>
    )
  }
}

export default RemovePartnerDialog
