import React from 'react';
import AdminRow from './admin-row';
import _ from 'lodash';

class SummaryDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const headerStyle = {
      borderTop: '1px solid',
      borderBottom: '3px solid'
    };

    const { presentation: data } = this.props.admin;
    const {
      averageEmailsGainedPerActiveBrand,
      brandsGreaterThanFiveSweeps,
      netNewEmailsToBrands,
      totalActiveBrands90Days,
      totalActiveBrands60Days,
      totalActiveBrands30Days,
      totalEmailsOnPlatform,
      totalSweepsRun,
      clicksPixel,
      customConversions,
      totalEntriesPerEmail,
      firstTimeHosts,
      newBrands,
      newBrandsGTE25k,
      instagramHandle,
      facebookUrl,
      googleAnalyticsCode,
      twitterHandle,
      avgCampaignsPerBrandCreatedLast60Days,
      brandsReEngagementLast60Days,
      partnershipsPerBrand,
      avgApplicationsPerCampaign,
      newCustomerSuccessJiraBugsReported
    } = data;
    // const { mPostEntrySweeps, facebookLikes, facebookShares, postEntryClicks, sweepsWithPostEntry, twitterFollows, twitterShares } = data;
    const dummyrow = ['-','-','-','-','-','-','-','-','-','-','-','-']

    let convertData = (arr, format = ',' , overrideField) => {
      return _.map(arr, function (item) {
        return d3.format(format)(overrideField ? item[overrideField] : item.c)
      });
    }

    return (
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped table-hover table-responsive">
            <AdminRow title={this.props.admin.interval + ' ending'} className="info" vals={this.props.dates} style={headerStyle}/>
            <tbody>
              <tr className="info"><td colSpan="13"><h4>Goal: Acquisition + Funnel Movement (Sales/Marketing)</h4></td></tr>
                <AdminRow title="First Time Hosts (excl. in-house brands)" vals={firstTimeHosts.map(fth => fth.first_time_hosts)} key="3"/>
                <AdminRow title="New Paying Users (ex. conversions)" vals={dummyrow} key="4"/>
                <AdminRow title="New Users - Network Channel" vals={dummyrow} key="5"/>
                <AdminRow title="New Users - Consultant Channel" vals={dummyrow} key="6"/>
                <AdminRow title="New Users - Internally Invited" vals={dummyrow} key="7"/>
              <tr className="info"><td colSpan="13"><h4>Onboarding</h4></td></tr>
                <AdminRow title="New Brands" vals={convertData(newBrands)} key="newBrands"/>
                <AdminRow title="New Brands With List Greater 25K" vals={convertData(newBrandsGTE25k)} key="newBrands25K"/>
                <AdminRow title="Facebook Handle" vals={convertData(facebookUrl)} key="facebookUrl"/>
                <AdminRow title="Twitter Handle" vals={convertData(twitterHandle)} key="twitterHandle"/>
                <AdminRow title="Instagram Handle" vals={convertData(instagramHandle)} key="instagramHandle"/>
                <AdminRow title="Google Analytics Code" vals={convertData(googleAnalyticsCode)} key="googleAnalyticsCode"/>
              <tr className="info"><td colSpan="13"><h4>Goal: Engagement + Campaign Success (Customer Success)</h4></td></tr>
                <AdminRow title="Total Active Brands - 90 Days" vals={convertData(totalActiveBrands90Days)} key="totalActiveBrands90Days"/>
                <AdminRow title="Total Active Brands - 60 Days" vals={convertData(totalActiveBrands60Days)} key="totalActiveBrands60Days"/>
                <AdminRow title="Total Active Brands - 30 Days" vals={convertData(totalActiveBrands30Days)} key="totalActiveBrands30Days"/>
                <AdminRow title="Total Sweeps Started" vals={convertData(totalSweepsRun,',','count_sweeps')} key="totalSweepsRun"/>
                <AdminRow title="Emails Gained per Active Brand (30 days)" vals={convertData(averageEmailsGainedPerActiveBrand,',.0f')} key="averageEmailsGainedPerActiveBrand"/>
                <AdminRow title="Net New Emails to Active Brands" vals={convertData(averageEmailsGainedPerActiveBrand,',',"total")} key="netNewEmailsToActiveBrands"/>
                <AdminRow title="Net New Emails to Brands" vals={convertData(netNewEmailsToBrands)} key="netNewEmailsToBrands"/>
                <AdminRow title="Total Emails on Platform (Aggregate)" vals={convertData(totalEmailsOnPlatform)} key="totalEmailsOnPlatform"/>
                <AdminRow title="Brands w/ > 5 active sweeps in Last 90 Days" vals={convertData(brandsGreaterThanFiveSweeps)} key="brandsGreaterThanFiveSweeps"/>
                <AdminRow title="Avg Campaigns Per Brand Period-60 Days" vals={convertData(avgCampaignsPerBrandCreatedLast60Days,',.2f')} key="avgCampaignsPerBrandCreatedLast60Days"/>
                <AdminRow title="Brand Re-Engagement in Last 60 Days" vals={convertData(brandsReEngagementLast60Days)} key="brandsReEngagementLast60Days"/>
                <AdminRow title="Partnerships Per Brand" vals={convertData(partnershipsPerBrand,',.2f','partnerships_per_brand')} key="partnershipsPerBrand"/>
                <AdminRow title="Average Applications Per Campaign" vals={convertData(avgApplicationsPerCampaign,',.2f')} key="avgApplicationsPerCampaign"/>
              <tr className="info"><td colSpan="13"><h4>Goal: Performance/Activity</h4></td></tr>
                <AdminRow title="Total Clicks through Post Entry with Conversion Pixel" vals={convertData(clicksPixel)} key="32" />
                <AdminRow title="Average # of Entries per Email Submitted" vals={totalEntriesPerEmail.map(entry => entry.entries_per_email ? parseFloat(entry.entries_per_email).toPrecision(2) : 0)} key="33"/>
                <AdminRow title="Tracked Custom Conversions" vals={convertData(customConversions)} key="customConversions"/>
                <AdminRow title="New Jira Bugs Reported" vals={convertData(newCustomerSuccessJiraBugsReported)} key="newCustomerSuccessJiraBugsReported" />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}


export default SummaryDashboard;
