import React from 'react';
import COLOURS from 'util/colours';
import { H6, Small } from 'visual-components/util/texts';

class InputContainer extends React.Component {
  render() {
    var {
      label,
      subLabel,
      children,
      style = {},
    } = this.props;

    return (
      <div style={style}>
        <div style={{
          paddingBottom: '8px'
        }}>
          <div style={{
            display: 'inline-block',
            marginRight: '8px'
          }}>
            <H6>
              { label }
            </H6>
          </div>
          <div style={{
            display: 'inline-block'
          }}>
            <Small>
              { subLabel }
            </Small>
          </div>
        </div>
        <div>
          { children }
        </div>
      </div>
    );
  }
}

export default InputContainer;
