import React, { Component } from 'react';
import _ from 'lodash';
import Row from 'visual-components/util/no-padding-grid/row';
import { Col } from 'react-flexbox-grid';
import { H4, P } from 'visual-components/util/texts';
import InputContainer from 'visual-components/util/InputContainer';
import { Input } from 'react-toolbox/lib/input';
import inputTheme from 'css/themes/no-padding-input.css';
import countries from 'util/countries';
import dropdownTheme from 'css/themes/Dropdown.css';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import Dropdown from 'react-toolbox/lib/dropdown';

export default class LegalDetailsForm extends Component {
  canSave() {
    return !_.some(_.omit(this.props.formData, ['addressL2']), _.isEmpty);
  }

  render() {
    const { formData, closeModal, confirm, updateValue, confirmText, required } = this.props;
    return (
      <div>
        <Row style={{ marginTop: 16 }}>
          <Col xs>
            <InputContainer label="Legal Company Name">
              <Input
                type="text"
                name="legalName"
                theme={inputTheme}
                value={formData.legalName}
                placeholder="Company Name, Inc"
                onChange={updateValue('legalName')}
              />
            </InputContainer>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Col xs={6} style={{ paddingRight: 8 }}>
            <InputContainer label="State of Incorporation">
              <Input
                type="text"
                name="state"
                theme={inputTheme}
                value={formData.incorporationState}
                placeholder="Delaware"
                onChange={updateValue('incorporationState')}
              />
            </InputContainer>
          </Col>
          <Col xs={6}>
            <InputContainer label="Entity Type">
              <Input
                type="text"
                name="entityType"
                theme={inputTheme}
                value={formData.entityType}
                placeholder="Corporation"
                onChange={updateValue('entityType')}
              />
            </InputContainer>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Col xs>
            <InputContainer label="Legal Address">
              <Row between="xs">
                <Col xs={8} style={{ paddingRight: 8 }}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Address Line 1
                  </P>
                  <Input
                    type="text"
                    name="addressL1"
                    theme={inputTheme}
                    placeholder="111 Street Name"
                    value={formData.addressL1}
                    onChange={updateValue('addressL1')}
                  />
                </Col>
                <Col xs={4}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Address Line 2
                  </P>
                  <Input
                    type="text"
                    name="addressL2"
                    theme={inputTheme}
                    placeholder="Apt, Suite, Unit #"
                    value={formData.addressL2}
                    onChange={updateValue('addressL2')}
                  />
                </Col>
              </Row>
              <Row between="xs" style={{ marginTop: 18 }}>
                <Col xs={8} style={{ paddingRight: 8 }}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    City
                  </P>
                  <Input
                    type="text"
                    name="city"
                    theme={inputTheme}
                    placeholder=""
                    value={formData.city}
                    onChange={updateValue('city')}
                  />
                </Col>
                <Col xs={4}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    State/Province/Region
                  </P>
                  <Input
                    type="text"
                    name="state"
                    theme={inputTheme}
                    placeholder=""
                    value={formData.state}
                    onChange={updateValue('state')}
                  />
                </Col>
              </Row>
              <Row between="xs" style={{ marginTop: 18 }}>
                <Col xs={4} style={{ paddingRight: 8 }}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Zip Code
                  </P>
                  <Input
                    type="text"
                    name="zipcode"
                    theme={inputTheme}
                    placeholder=""
                    value={formData.zipcode}
                    onChange={updateValue('zipcode')}
                  />
                </Col>
                <Col xs={8}>
                  <P
                    style={{
                      fontSize: '12px',
                      display: 'inline-block',
                    }}
                  >
                    Country
                  </P>
                  <Dropdown
                    theme={dropdownTheme}
                    auto
                    onChange={updateValue('country')}
                    source={countries.map(c => ({
                      label: c.name,
                      value: c.name,
                    }))}
                    value={formData.country}
                  />
                </Col>
              </Row>
            </InputContainer>
          </Col>
        </Row>
        <ModalButtonGroup
          canSave={!required || this.canSave()}
          confirm={confirm}
          cancel={closeModal}
          cancelText="Cancel"
          confirmText={confirmText}
        />
      </div>
    );
  }
}
