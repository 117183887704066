import React from 'react'
import { Validations } from 'visual-components/util/form'
import { H4 } from 'visual-components/util/texts'

const {
  espValidKey,
  activeCampaignValidKey,
  mailjetValidKey,
  maropostValidKey,
  postupValidKey,
  retentionScienceValidKey,
  sailthruValidKey,
  belowCustomRateLimit,
  isWebsite,
  isPositiveInteger,
} = Validations

/* New Integration Steps

Start by doing some research into the integration. Try to figure out if the integration supports exporting
to multiple lists, whether it can accommodate mergeTags (custom fields), if it uses an OAuth flow,
API keys, or anything else.

1) Download Integration logo to /images/integration-logos/{fileName}
2) Add an object to integrations-map.js
  - This will need to be modified as the integration is built but start by using something
    similar based on the prior research

3) If the integration uses OAuth
  3.1) In the integrations-map object add an oauth field with an object value:
    {
      route: 'initial oauth path',
      token: 'oauth token field name returned'
    }
  3.2) Create a controller file /controllers/{integrationKey}-controller.js
  3.3) Add needed oauth routes (can refer to mailchimp, salesforce, infusionsoft, etc..)

4) If the integration uses lists
  4.1) In the integrations-map object add a lists field with a value of true and a
       apiFields field with a string array value of the needed fields to fetch the lists
  4.2) Create a controller file /controllers/{integrationKey}-controller.js
  4.3) Add a GET route /{integrationKey}/fetch-lists
  4.4) Add the integration to dispatchers/integrations-dispatcher.js, fetchIntegrationLists function
  4.5) In util/form-validator.js if the api call uses only an api key you can simply add the integration to the validEspKey function,
       otherwise you should create a new function, {integrationKey}ValidCreds to simulate a fetch list
  4.6) In the integrations-map object make sure the final input field (not listId) has a validation
       Validator.validEspKey('{integrationKey}') or Validator.{integrationKey}ValidCreds

5) If the integration uses mergeTags
  5.1) In the integrations-map object add a mergeTags field with a value of true
  5.2) Add a function to /integrations/util/merge-tag-helpers.js, this may not be needed depending on the default fields an ESP allows
     - Should return an array with the default fields to be sent (fields that can be sent without any extra setup required by user, excluding email)
      { attribute: 'valueInDojo', value: 'valueInESP' }
  5.3) If using defaults, in the integrations-map object add a mergeDefaults field with a value of true

6) If the integration uses restrictions
  6.1) In the integrations-map object add a restrictions field with true. This will compare the espWhitelist field in the Brand Record with the integration key.

7) Add integration to /db/integration-exports.js
  7.1) Create the main export file as /integrations/{integrationKey}.js
     - This will vary depending on the integration. In some cases the integration will send to a
       specific list, some will handle exports in batches and some one at a time.
     - The goal of this is to successfully export the entries to the ESP
     - As much error handling as possible should be used to try and catch various cases

8) Add integration to test/models/integrations-test.js
  8.1) Create test for 'Errors with Invalid Integration Details'
  8.2) Create test for 'Exports Correct Data'

9) Add integration key and label to /integrations/util/integration-name-map.js
*/

/* Sample Integration
{
  key: 'integration-name',
  label: 'Integration Name',
  img: '/images/integration-logos/updated-integration-image.png',
  apiFields: ['apiUrl', 'apiKey'], // Fields needed for fetchList (exclude if no)
  lists: true, // Does the integration use lists (exclude if no)
  mergeTags: true, // Does the integration use merge tags (exclude if no)
  mergeDefaults: true, // Does the integration use merge tags defaults (function in merge-tag-helpers.js, exclude if no)
  connectInputs: [{ // Inputs to include for connection ex: api keys, secrets, ...
    type: 'text',
    attr: 'apiUrl',
    label: 'API URL'
  }, {
    type: 'text',
    attr: 'apiKey',
    label: 'API Key'
  }],
  selectInputs: [{ // Inputs to include for setup ex: lists, ...
    type: 'select',
    attr: 'listId'
  }],
  validations: { // Validations to include
    apiUrl: [Validator.required],
    apiKey: [Validator.required, Validator.activeCampaignValidCreds],
    listId: [Validator.required]
  },
  oauth: { // Does the integration use OAuth (exclude if no)
    route: 'integration-login', // DojoMojo oauth route
    token: 'oauth_token' // OAuth token to use
  }
} */

export default [
  {
    key: 'active-campaign',
    label: 'Active Campaign',
    img: '/images/integration-logos/updated-active-campaign.png',
    apiFields: ['apiUrl', 'apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiUrl',
      label: 'API URL',
    }, {
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [activeCampaignValidKey],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  // {
  //   key: 'aweber',
  //   label: 'AWeber',
  //   img: '/images/integration-logos/updated-aweber.png',
  //   apiFields: ['account_id', 'oauth_token', 'oauth_token_secret'],
  //   lists: true,
  //   mergeTags: true,
  //   selectInputs: [{
  //     type: 'select',
  //     attr: 'listId'
  //   }],
  //   oauth: {
  //     route: 'aweber-login',
  //     token: 'oauth_token'
  //   }
  // },
  {
    key: 'blueshift',
    label: 'Blueshift',
    img: '/images/integration-logos/updated-blueshift.png',
    apiFields: ['apiKey'],
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'User API Key',
    }],
  },
  {
    key: 'campaign-monitor',
    label: 'Campaign Monitor',
    img: '/images/integration-logos/updated-campaign-monitor.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [espValidKey('campaign-monitor')],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'conde-nast',
    label: 'Condé Nast',
    img: '/images/integration-logos/updated-conde-nast.png',
    apiFields: ['siteCode'],
    lists: true,
    connectInputs: [{
      type: 'text',
      attr: 'sourceCode',
      label: 'Source Code',
      optional: true,
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
      label: "Select which Sweepstake you'd like to export to.",
    }],
    restrictions: true,
  },
  {
    key: 'convert-kit',
    label: 'Convert Kit',
    img: '/images/integration-logos/updated-convert-kit.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [espValidKey('convert-kit')],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
      title: 'Form Export',
      subtitle: 'Form Name',
      label: "Select which form you'd like to use.",
    }],
  },
  {
    key: 'drip',
    label: 'Drip',
    img: '/images/integration-logos/drip.png',
    apiFields: ['apiKey', 'accountId'],
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Token',
    }, {
      type: 'text',
      attr: 'accountId',
      label: 'Account ID',
    }],
  },
  {
    key: 'experian',
    label: 'Experian CheetahMail',
    img: '/images/integration-logos/updated-experian.png',
    apiFields: ['experianCustId', 'experianFormId', 'experianTable'],
    connectInputs: [{
      type: 'text',
      attr: 'experianCustId',
      label: 'Customer ID',
    }, {
      type: 'text',
      attr: 'experianFormId',
      label: 'Form ID',
    }, {
      type: 'text',
      attr: 'experianTable',
      label: 'Table ID',
    }],
  },
  {
    key: 'freshmail',
    label: 'FreshMail',
    img: '/images/integration-logos/updated-freshmail.png',
    apiFields: ['apiKey', 'apiSecret'],
    lists: true,
    mergeTags: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
    }, {
      type: 'text',
      attr: 'apiSecret',
      label: 'API Secret',
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'hubspot',
    label: 'Hubspot',
    img: '/images/integration-logos/updated-hubspot.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
      optional: true,
    }],
  },
  {
    key: 'infusionsoft',
    label: 'InfusionSoft',
    img: '/images/integration-logos/updated-infusionsoft.png',
    apiFields: ['access_token'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    selectInputs: [{
      type: 'select',
      attr: 'listId',
      label: "Select which tag you'd like to apply.",
    }],
    oauth: {
      route: 'infusionsoft-login',
      token: 'access_token',
    },
  },
  {
    key: 'iterable',
    label: 'Iterable',
    img: '/images/integration-logos/updated-iterable.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [espValidKey('iterable')],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'klaviyo',
    label: 'Klaviyo',
    img: '/images/integration-logos/updated-klaviyo.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [espValidKey('klaviyo')],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
    warningBanner: {
      header: 'Opt-In Process with Klaviyo',
      copy: 'Klaviyo defaults to a double opt-in and changing to single opt-in can be tricky.',
      linkText: 'Learn more about Klaviyo’s opt-in process',
      linkUrl: 'https://help.klaviyo.com/hc/en-us/articles/115005251108-The-Double-Opt-In-Process',
    },
  },
  {
    key: 'mailchimp',
    label: 'MailChimp',
    img: '/images/integration-logos/updated-mailchimp.png',
    apiFields: ['dc', 'mailchimpAccessToken'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
    oauth: {
      route: 'integrate-mailchimp',
      token: 'mailchimpAccessToken',
    },
    mediaMarket: true,
  },
  {
    key: 'mailgun',
    label: 'Mailgun',
    img: '/images/integration-logos/updated-mailgun.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [espValidKey('mailgun')],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'mailjet',
    label: 'Mailjet',
    img: '/images/integration-logos/updated-mailjet.png',
    apiFields: ['apiKey', 'apiSecret'],
    lists: true,
    mergeTags: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
    }, {
      type: 'text',
      attr: 'apiSecret',
      label: 'API Secret',
      validations: [mailjetValidKey],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'maropost',
    label: 'Maropost',
    img: '/images/integration-logos/updated-maropost.png',
    apiFields: ['accountId', 'apiKey'],
    lists: true,
    connectInputs: [{
      type: 'text',
      attr: 'accountId',
      label: 'Account ID',
    }, {
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [maropostValidKey],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'omnisend',
    label: 'Omnisend',
    img: '/images/integration-logos/omnisend.png',
    apiFields: ['apiKey'],
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
    }],
  },
  {
    key: 'postup',
    label: 'Postup',
    img: '/images/integration-logos/updated-postup.png',
    apiFields: ['username', 'password'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'username',
      label: 'Username',
    }, {
      type: 'text',
      attr: 'password',
      label: 'Password',
      validations: [postupValidKey],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }, {
      type: 'select',
      attr: 'templateId',
      title: 'Template Export',
      subtitle: 'Send Template Name',
      label: "Select which Send Template you'd like to use (Optional).",
      options: 'templates',
      optional: true,
    }],
  },
  {
    key: 'retention-science',
    label: 'Retention Science',
    img: '/images/integration-logos/retention-science.png',
    apiFields: ['apiKey'],
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [retentionScienceValidKey],
    }],
  },
  {
    key: 'sailthru',
    label: 'Sailthru',
    img: '/images/integration-logos/updated-sailthru.png',
    apiFields: ['apiKey', 'apiSecret'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
    }, {
      type: 'text',
      attr: 'apiSecret',
      label: 'API Secret',
      validations: [sailthruValidKey],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'listId',
    }],
  },
  {
    key: 'salesforce',
    label: 'Salesforce',
    img: '/images/integration-logos/updated-salesforce.png',
    apiFields: ['access_token', 'refresh_token', 'instance_url'],
    mergeTags: true,
    mergeDefaults: true,
    selectInputs: [{
      type: 'select',
      attr: 'objectType',
      label: "Select what object type you'd like to export.",
      options: [{
        value: 'Contact',
        label: 'Contact',
      }, {
        value: 'Lead',
        label: 'Lead',
      }],
    }],
    oauth: {
      route: 'salesforce-login',
      token: 'access_token',
    },
  },
  {
    key: 'salesforce-marketing-cloud',
    label: 'Salesforce Marketing Cloud',
    img: '/images/integration-logos/salesforce-marketing-cloud.png',
    apiFields: ['clientId', 'clientSecret', 'accountId'],
    connectInputs: [{
      type: 'text',
      attr: 'clientId',
      label: 'Client ID',
    }, {
      type: 'text',
      attr: 'clientSecret',
      label: 'Client Secret',
    }, {
      type: 'text',
      attr: 'accountId',
      label: 'Account ID',
    }],
    restrictions: true,
  },
  {
    key: 'sendgrid',
    label: 'Sendgrid',
    img: '/images/integration-logos/updated-sendgrid.png',
    apiFields: ['apiKey'],
    lists: true,
    mergeTags: true,
    mergeDefaults: true,
    connectInputs: [{
      type: 'text',
      attr: 'apiKey',
      label: 'API Key',
      validations: [espValidKey('sendgrid')],
    }],
    selectInputs: [{
      type: 'select',
      attr: 'marketingVersion',
      title: 'Marketing Campaign Version',
      label: "Select which Marketing Campaign version you'd like to use.",
      options: [{
        value: 'legacy',
        label: 'Legacy Marketing Campaigns',
      }, {
        value: 'new',
        label: 'New Marketing Campaigns',
      }],
    }, {
      type: 'select',
      attr: 'listId',
      optional: true,
    }],
  },
  {
    key: 'zapier',
    label: 'Zapier',
    img: '/images/integration-logos/updated-zapier.png',
    apiFields: ['webhookUrl'],
    mergeTags: true,
    mergeDefaults: true,
    instructions: () => (
      <React.Fragment>
        <div
          style={{
            marginBottom: '32px',
            marginLeft: '12px',
          }}
        >
          <H4 multiline>
            {'1. Set up Zapier Zap '}
            <a
              href="https://zapier.com/developer/invite/64585/9572a669324e2fb2218e52a9cc78553d/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            {'.'}
          </H4>
          <H4 multiline>
            {'2. When prompted, provide us with the target webhook URL below.'}
          </H4>
          <H4 multiline>
            {"3. We'll make a POST to it every 5 minutes with any entries submitted in that period."}
          </H4>
        </div>
      </React.Fragment>
    ),
    connectInputs: [{
      type: 'text',
      attr: 'webhookUrl',
      label: 'Webhook URL',
    }],
  },
  {
    key: 'custom',
    label: 'Custom Script',
    img: '/images/integration-logos/updated-mojo.png',
    apiFields: ['callbackUrl'],
    mergeTags: true,
    mergeDefaults: true,
    instructions: () => (
      <React.Fragment>
        <H4 multiline>
          <em>Note: this step requires a developer.</em>
          {' If you like. you can set up your own integration. Use our automatic entry callback to export this data into your ESP.'}
        </H4>
        <div
          style={{
            marginTop: '12px',
            marginBottom: '32px',
            marginLeft: '12px',
          }}
        >
          <H4 multiline>
            {'1. Provide us with the target callback URL below.'}
          </H4>
          <H4 multiline>
            {"2. We'll make a POST to it every 5 minutes with any entries submittted in that period."}
          </H4>
        </div>
      </React.Fragment>
    ),
    connectInputs: [{
      type: 'text',
      attr: 'callbackUrl',
      label: 'Callback URL',
      validations: [isWebsite],
    }, {
      type: 'number',
      attr: 'rateLimit',
      label: 'Maximum Emails/Hour',
      sublabel: "Please specify the maximum emails you'd like to receive per hour - maximum is 1K.",
      validations: [isPositiveInteger, belowCustomRateLimit],
      optional: true,
    }],
  },
]
