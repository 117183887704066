import { connect } from 'react-redux'

import SimpleForm from './SimpleForm'

const mapStateToProps = (state, ownProps) => ({
  model: state[ownProps.modelName],
})

export { SimpleForm }
export const Form = connect(mapStateToProps)(SimpleForm)

export { InputWrapper, SubmitWrapper } from './wrappers'

export Input from './input'
export Checkbox from './checkbox'
export * from './select'
export Submit from './submit'

export * as Validations from './validations'
