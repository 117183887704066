import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import * as MetricsActions from 'actions/analytics-actions'

import Spinner from 'util/components/spinner'
import ConversionGraphs from './conversion-graphs'

const tabs = [
  {
    ref: 'transactions',
    title: 'total purchases',
    tooltip: 'Purchases attributed to DojoMojo campaign entries',
  },
  {
    ref: 'revenue',
    title: 'sales generated',
    isDollar: true,
    tooltip: 'Sales generated attributed to DojoMojo campaign entries',
  },
]

class DashboardConversion extends PureComponent {
  handleUpsellClick = () => {
    const { metricsActions } = this.props

    metricsActions.create('clickedAddDataIntegration', {
      meta: { from: 'dashboardAnalyticsConversionGraph' },
    })
  }

  switchTab = tab => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'analyticsDashboard',
      data: {
        conversionSelection: tab,
      },
    })
  }

  changeFilter = (startDate, endDate) => {
    const data = [
      {
        startDate,
        endDate,
        metric: 'dashboardConversionDetails',
      },
    ]

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  render() {
    const {
      dashboardConversionDetails,
      conversionSelection,
      datePickerConversionDetails,
      oldestDate,
      dashboardConversionTotals,
      hasAnyActiveIntegrations,
    } = this.props

    const data = dashboardConversionDetails[conversionSelection]

    if (!data.data.length) {
      return <Spinner />
    }

    return (
      <ConversionGraphs
        tabs={tabs}
        smaller={!hasAnyActiveIntegrations}
        data={dashboardConversionDetails}
        switchTab={this.switchTab}
        conversionSelection={conversionSelection}
        oldestDate={oldestDate}
        changeFilter={this.changeFilter}
        datePickerData={datePickerConversionDetails}
        hasAnyActiveIntegrations={hasAnyActiveIntegrations}
        firstConversion={dashboardConversionTotals.first_conversion_date}
        handleUpsellClick={this.handleUpsellClick}
      />
    )
  }
}

const mapState = ({ analyticsDashboard, dataIntegrations }) => {
  const {
    dashboardConversionDetails,
    conversionSelection,
    datePickerConversionDetails,
    oldestDate,
    dashboardConversionTotals,
  } = analyticsDashboard

  return {
    dashboardConversionDetails,
    conversionSelection,
    datePickerConversionDetails,
    oldestDate,
    dashboardConversionTotals,
    hasAnyActiveIntegrations: dataIntegrations.hasAnyActiveIntegrations,
  }
}

const mapDispatch = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

export default connect(
  mapState,
  mapDispatch
)(DashboardConversion)
