import React, { Component } from 'react';
import BlueBox from './bluebox';

import theme from 'css/themes/homePage/bluetabs.css';

class BlueTabs extends Component {
  state = {
    selected: 'grow'
  }

  onclick = (name) => {
    this.setState({
      selected: name
    });
  }

  render() {
    let {
      selected
    } = this.state;

    const imgMap = {
      'grow': 'blue-tabs/french-alps-campaign-landing-page.png',
      'expand': 'blue-tabs/french-alps-campaign-social-growth.png',
      'drive': 'blue-tabs/french-alps-campaign-custom-cta.png'
    }

    const srcSetMap = {
      'grow': `
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-landing-page.png,
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-landing-page@2x.png 2x,
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-landing-page@3x.png 3x`,
      'expand': `
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-social-growth.png,
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-social-growth@2x.png 2x,
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-social-growth@3x.png 3x`,
      'drive': `
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-custom-cta.png,
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-custom-cta@2x.png 2x,
        /images/login/sweepstakes/blue-tabs/french-alps-campaign-custom-cta@3x.png 3x`,
    }

    return(
      <div className={ theme.container }>
        <div className={ theme.leftSide }>
          <img
            src={ `/images/login/sweepstakes/${imgMap[selected]}` }
            srcSet={srcSetMap[selected]}
          />
        </div>
        <div className={ theme.rightSide }>
          <div className={ theme.wrapper }>
            <BlueBox
              name={ 'grow' }
              onclick={ this.onclick }
              title={ 'Grow Your Email List' }
              explain={ 'Partner with brands who’s audience you’d like to reach. Create a sweepstakes and offer a prize. When brands promote the sweepstakes to their audience, you can market to anyone who enters to win, bringing your message to thousands of inboxes.' }
              selected={ selected }
            />
            <BlueBox
              name={ 'expand' }
              onclick={ this.onclick }
              title={ 'Expand Your Social Reach ' }
              explain={ 'Incentivize entrants with extra entries for following you on social media, sharing the sweepstakes with friends or downloading your app using Social Growth. ' }
              selected={ selected }
            />
            <BlueBox
              name={ 'drive' }
              onclick={ this.onclick }
              title={ 'Drive Conversions' }
              explain={ 'Show entrants a special offer and send traffic directly into sales funnels with a Custom CTA after they enter to win.' }
              selected={ selected }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default BlueTabs;
