import React from 'react'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'
import EmptyBonzai from './empty-bonzai'
import DedicatedEmailOpportunityCard from './opportunities/dedicated-email-opportunity-card'
import PrizingPartnerOpportunityCard from './opportunities/prizing-partner-opportunity-card'
import InsertSwapOpportunityCard from './opportunities/insert-swap-opportunity-card'
import ContentSwapOpportunityCard from './opportunities/content-swap-opportunity-card'
import CustomOpportunityCard from './opportunities/custom-opportunity-card'

const OpportunityComponents = {
  dedicated_email_buy: DedicatedEmailOpportunityCard,
  dedicated_email_sell: DedicatedEmailOpportunityCard,
  prizing_partner: PrizingPartnerOpportunityCard,
  product_shipment_insert: InsertSwapOpportunityCard,
  content_swap: ContentSwapOpportunityCard,
  custom: CustomOpportunityCard,
}

class OpportunitiesTab extends React.Component {
  render() {
    const { brand, currentBrand } = this.props
    const isBrand = brand.id == currentBrand.id

    if (!brand.opportunities || !brand.opportunities.length) {
      return (
        <EmptyBonzai description={
          brand.obscure
            ? `Sign up or log in to DojoMojo to see this brand's full profile.`
            : isBrand
              ? `You haven't created any opportunities yet. Create an opportunity to get started!`
              : `This brand doesn't have any live opportunities at this time. Connect with them to start a partnership.`
        } />
      )
    }

    const opportunityCards = brand.opportunities.map((opportunity, id) => {
      let Component = OpportunityComponents[opportunity.type]
      return (
        <Component
          key={id}
          opportunity={opportunity}
          brand={brand}
          isPublic={brand.obscure}
        />
      )
    })

    return <div>{opportunityCards}</div>
  }
}

export default OpportunitiesTab
