import React from 'react'
import Dialog from 'react-toolbox/lib/dialog'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { H4 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import propTypes from 'prop-types'

const WarningMobileEditModal = props => {
  const {
    close,
    confirm,
  } = props

  return (
    <Dialog
      title="Before You Get Started..."
      theme={dialogTheme}
      active
      onEscDown={close}
      onOverlayClick={close}
    >
      <div className={dialogTheme.bannerWrapper} style={{ paddingTop: '8px', backgroundColor: COLOURS.lightPeach }}>
        <img src="/images/icons/warning-microphone-blue-shirt.svg" />
      </div>
      <H4 multiline>
        Make sure you finalize the Desktop View of your landing page before getting started.&nbsp;
        <b>Any edits you make to the Mobile View will not be reflected on the Desktop View</b>
        .
      </H4>
      <ModalButtonGroup
        canSave
        confirm={confirm}
        confirmText="Edit Anyway"
        cancel={close}
        cancelText="Cancel"
        hideLine
      />
    </Dialog>
  )
}

WarningMobileEditModal.propTypes = {
  close: propTypes.func.isRequired,
  confirm: propTypes.func.isRequired,
}

export default WarningMobileEditModal
