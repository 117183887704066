import React from 'react';
import DojoModal from 'util/components/dojomodal';
import BrandCardContent from 'components/brands/brand-card-content';
import BrandDetailView from 'components/brands/brand-detail-view';
import Text from 'util/components/text';
import TertiaryButton from 'util/components/buttons/tertiary-button';
import PrimaryButton from 'util/components/buttons/primary-button';
import SecondaryButton from 'util/components/buttons/secondary-button';

class CampaignApplicationNotification extends React.Component {
  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  accept () {
    this.props.acceptCampaignApplication(this.props.item);
  }

  reject () {
    this.props.rejectCampaignApplication(this.props.item);
  }

  closeModal () {
    this.props.closeModal();
    $('body').removeClass('modal-open');
  }

  render () {
    let item = this.props.item;
    let copy = LANGUAGE.notifications.campaignApplication;
    let text = LANGUAGE.run(copy.text, {
      inviteeAccountname: item.invitee.accountname,
      hostAccountname: item.campaign.hostBrand.accountname,
      campaignName: item.campaign.name
    });

    return (
      <div>
        <DojoModal
          gaName="campaignApplication"
          isOpen={ true }
          hideModal={ this.closeModal }
          title={ copy.title }>
          <div className="row">
            <BrandCardContent
              brand={ item.invitee } />
            <div className="col-xs-12 col-md-8">
              <BrandDetailView
                brand={ item.invitee } />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3>
                { text }
              </Text.dojoH3>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-8 col-xs-offset-4 text-right">
              <PrimaryButton onClick={ this.accept }>
                { LANGUAGE.notifications.accept }
              </PrimaryButton>
              <SecondaryButton onClick={ this.reject }
                styles={[{
                  marginLeft: '15px'
                }]}>
                { LANGUAGE.notifications.reject }
              </SecondaryButton>
            </div>
          </div>
        </DojoModal>
      </div>
    );
  }
};

export default CampaignApplicationNotification;
