import PartnershipApplicationConstants from 'constants/partnership-application-constants';
import moment from 'moment-timezone';
import d3 from 'd3'

export function closeApplicationSentModal() {
  return {
    type: PartnershipApplicationConstants.CLOSE_APPLICATION_SENT_CONFIRMATION_MODAL
  }
}

export function closeModal() {
  return {
    type: PartnershipApplicationConstants.CLOSE_MODAL,
  };
}

export function startApplication(campaign, currentBrand) {
  return {
    type: PartnershipApplicationConstants.START_APPLICATION,
    payload: {
      campaign,
      currentBrand,
    },
  };
}

export function updateData(data) {
  return {
    type: PartnershipApplicationConstants.UPDATE_DATA,
    payload: data,
  };
}

export function submitStepOne() {
  return {
    type: PartnershipApplicationConstants.SUBMIT_STEP_ONE,
  };
}

export function submitStepTwo() {
  return {
    type: PartnershipApplicationConstants.SUBMIT_STEP_TWO
  }
}

export function submitApplication(data) {
  return (dispatch, getState) => {
    const state = getState();
    const message = `
    <p>Hi ${data.campaign.hostBrandName},</p>
    <br>
    <p>${state.profile.displayName} from <strong>${
      state.currentBrand.accountname
    }</strong> has submitted an application to the campaign <strong>${
      data.campaign.name
    }</strong>.</p>
    <br>
    <p>Here’s why we’re a great fit:</p>
    <br>
    ${
      data.pastPartners && data.pastPartners.length
        ? `<p><strong>Notable Past Partners:</strong> ${data.pastPartners.join(
            ', '
          )}</p>`
        : ''
    }
    <p><strong>Why We’re Relevant:</strong> “${data.greatFitMessage}”</p>
    <p><strong>Industry:</strong> ${data.industries.join(', ')}</p>
    <br>
    <p>Some stats about ${state.currentBrand.accountname}:</p>
    <br>
    <p><strong>Email List Size:</strong> ${d3.format(',.0f')(data.listSize)}</p>
    <p><strong>Social Reach:</strong> ${data.socialReach.toLocaleString()}</p>
    <p><strong>Estimated Entries per Campaign:</strong> ${
      d3.format(',.0f')(data.expectedEntries)
    }</p>
    <p><strong>Partner Rating:</strong> ${data.partnerRating > 0 ? data.partnerRating.toFixed(1) : 'N/A'}</p>
    <br>
    <p>What we can contribute to the campaign:</p>
    <br>
    <p><strong>Proposed Entry Contribution:</strong> ${
      data.entryContribution
    }</p>
    ${data.promotionEndDate ? `<p><strong>Available Promotional Dates:</strong> ${moment(
      data.promotionStartDate
    ).format('MMMM Do, YYYY')} - ${moment(data.promotionEndDate).format(
      'MMMM Do, YYYY'
    )}</p>` : ``}
    <p><strong>Prizing Contribution:</strong> ${data.prizingContribution}</p>

    ${
      data.optionalNote
        ? `<br><p><strong>Personal Note:</strong>
    “${data.optionalNote}”</p>`
        : ''
    }
    <br>
    <p>You can respond to this application using the buttons above or send a message and learn more. Choose “Interested” to create a Partnership Agreement, which is required to finalize their participation.</p>
    <br>
    `;

    dispatch({
      types: [
        PartnershipApplicationConstants.SUBMIT_APPLICATION_REQUEST,
        PartnershipApplicationConstants.SUBMIT_APPLICATION_SUCCESS,
        PartnershipApplicationConstants.SUBMIT_APPLICATION_FAILURE,
      ],
      payload: {
        dedicatedListSize: data.listSize
      },
      http: {
        url: `/partnership/apply/` + data.campaign.id,
        data: {
          message,
          data: {
            ...data,
            listSize: data.listSize,
            pastPartners: data.pastPartners.length ? data.pastPartners.join(', ') : null,
            profileName: state.profile.firstName,
            promotionStartDate: data.promotionStartDate ? moment(
              data.promotionStartDate
            ).format('MMMM Do, YYYY') : null,
            promotionEndDate: data.promotionEndDate ? moment(
              data.promotionEndDate
            ).format('MMMM Do, YYYY') : null,
            industries: data.industries.join(', '),
            socialReach: data.socialReach.toLocaleString(),
            partnerRating: data.partnerRating === 0 ? false : data.partnerRating.toFixed(1),
            legalDetails: data.legalDetails,
            currentBrandName: state.currentBrand.accountname,
          }
        },
        method: 'POST',
      },
    });
  };
}

export function toggleDatePicker(open) {
  return {
    type: PartnershipApplicationConstants.TOGGLE_DATE_PICKER,
    payload: {
      open,
    },
  };
}
