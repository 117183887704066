import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { H3, H4 } from 'visual-components/util/texts'

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0px 48px',
    width: '100%',
  },
  description: {
    marginTop: 8,
    textAlign: 'center',
    width: 608,
  },
  image: {
    marginBottom: 32,
  },
}

function UnderConstructionBanner(props) {
  const { classes } = props

  return (
    <div className={classes.container}>
      <img className={classes.image} src="/images/construction.svg" alt="Under Construction" />
      <H3 multiline> Media Market is Under Construction </H3>
      <div className={classes.description}>
        <H4 multiline>
          Look forward to a new & improved Media Market coming soon.
          Eager to connect with other brands
          right now? Please use
          <Link to="/messaging/inbox"> Messages </Link>
!
        </H4>
      </div>
    </div>
  )
}

UnderConstructionBanner.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectSheet(styles)(UnderConstructionBanner))
