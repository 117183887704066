import Field from './field';
import Label from './label';
import COLOURS from 'util/colours';
import React from 'react';
import _ from 'lodash'

class NumberField extends Field {
  constructor(props) {
    super(props);
    this.updateNumber = this.updateNumber.bind(this);
    this.formattedVal = this.formattedVal.bind(this);
    this.dollarFormattedValue = this.dollarFormattedValue.bind(this);
    this.showActual = this.showActual.bind(this);

    this.state = {
      isFocused: false
    };
  }

  showActual(e) {
    const input = $(e.currentTarget).val() || '0';
    var value = input.replace(/[^0-9\.]+/g, '');

    value = value || 0;

    value = parseFloat(value);
    $(e.currentTarget).val(value);

    this.setState({
      isFocused: true
    });
  }

  updateNumber(e) {
    let value = e.target.value;
    value = parseFloat(value.replace(/[^0-9\.]/g, ''));
    if (isNaN(value)) {
      value = 0;
    }
    this.updateValue(value);
  }

  processValue(value) {
    value = parseFloat(value.replace(/[^0-9\.]/g, ''));
    this.state.value = value;
    this.forceUpdate();

    this.validate(function (errors) {
      if (!errors.length) {
        this.props.update(this.props.attrName, value);
      }
    }.bind(this));
  }

  blur(e) {
    var value = e.target.value.replace(/[^0-9\.]/g, '');

    $(e.currentTarget).val(value);
    const { attrName, endpoint, formModel, dataModel, blur } = this.props;

    if (blur) {
      blur(e)
    }

    setTimeout(() => {
      this.setState({
        blurred: true
      });
    }, 10);

    this.setState({
      isFocused: false
    });

    this.validate(this.valueFromDataModel(), this.persistIfValid.bind(this))
  }

  formattedVal( value ) {
    if (value || value === 0) {
      return d3.format(',')(value)
    }
    return undefined;
  }

  dollarFormattedValue( value ) {
    return d3.format('$,')(value)
  }

  render() {
    const {
      attrName,
      disabled,
      editable,
      customInputStyle
    } = this.props;

    let errors = this.props.formModel[attrName];
    errors = errors ? errors.errors : [];
    errors = _.map(errors, function (text, idx) {
      return (
        <span key={idx} className="help-block" style={{ color: COLOURS.alertRed }}>{text}</span>
      );
    });
    const className = `form-group ${errors.length ? 'has-error' : ''}`;
    var value = this.props.dataModel[this.props.attrName] || undefined;

    if (this.props.dataModel[this.props.attrName] === 0) {
      value = 0;
    }

    const disable_input = (disabled || editable === false) ? 'disabled' : '';
    var CustomLabel = this.props.CustomLabel;

    const inputStyle = {
      borderRadius: '2px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: errors.length > 0 ? COLOURS.alertRed : COLOURS.silver,
      paddingTop: '4px',
      paddingLeft: '14px',
      paddingBottom: '4px',
      paddingRight: '4px',
      width: '100%',
      backgroundColor: 'transparent',
      color: COLOURS.ink,
      fontFamily: 'Larsseit-Light',
      fontSize: '14px',
      outline: 'none',
      boxShadow: 'none',
      WebkitBoxShadow: 'none',
      height: this.props.withIcon ? null : '50px',

    }

    let combinedStyles = Object.assign({}, inputStyle, customInputStyle)

    return (
      <div className={ className }
        style={{
          marginBottom: '15px'
      }}>
        {CustomLabel ?
          <CustomLabel {...this.props} />
          :
          <Label
            labelStyles={ this.props.labelStyles }
            label={ this.props.label }
          />
        }
        <input
          style={ combinedStyles }
          min={ this.props.min }
          max={ this.props.max }
          onBlur={ this.blur }
          disabled={ disable_input }
          value={ this.props.dollarVal ? this.dollarFormattedValue(value || 0) : this.formattedVal(value) }
          ref={ this.props.attrName }
          onFocus={ this.showActual }
          onChange={ this.updateNumber }
          type="text"
          placeholder={ this.props.placeholder }
          className="form-control borderAzureFocus"
          name={ this.props.attrName }
          id={ this.props.attrName } />
        { errors.length > 0 ?
          errors
          :
          <div style={{
            paddingTop: '17px'
          }}/>
        }
      </div>
    );
  }
}

export default NumberField;
