import React from 'react';
import { number } from 'prop-types';
import COLOURS from 'util/colours';

class QuestPercentCompleted extends React.Component {
  static propTypes = {
    completedCount: number.isRequired,
    totalCount: number.isRequired,
  };

  render() {
    const {
      completedCount,
      totalCount
    } = this.props;

    const percentage = Math.round((completedCount / totalCount) * 100);
    const remaining = 100 - percentage;

    return(
      <div style={{
        width: '64px',
        height: '64px',
        marginLeft: '30px'
      }}>
        {/* thanks to https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72 for the ideas/help */}
        <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
          <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff" />
          <text x="50%" y="50%" textAnchor="middle" strokeWidth="inherit" dy=".3em" fontFamily={ 'Larsseit-Bold'} fontSize="9px" fill={ COLOURS.seaGreen }>
            { `${percentage}%` }
          </text>
          <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={ COLOURS.cloudGrey } strokeWidth="5px" />

          <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={ COLOURS.seaGreen } strokeWidth="5px" strokeDasharray={ `${percentage} ${remaining}`} strokeDashoffset="25" />
        </svg>
       
      </div>
    )
  }
};

export default QuestPercentCompleted;
