import React from 'react'

export default Component => {
  class PreventBodyScroll extends React.Component {
    componentDidMount() {
      document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
      document.body.style.overflow = 'auto'
    }

    render() {
      return (
        <Component {...this.props} />
      )
    }
  }

  return PreventBodyScroll
}
