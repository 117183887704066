import TasksPopoutTemplate from 'visual-components/calendar-tasks-popouts/tasks-popout-template.js'

import { Component } from 'react'

import MessagingDispatcher from 'dispatchers/messaging-dispatcher'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TasksList from 'visual-components/tasks/TaskList'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'
import * as DialogActions from 'actions/dialog-actions'

const mapStateToProps = (state) => {
  return {
    campaignTasks: state.campaignTasks,
    currentBrand: state.currentBrand,
    currentCampaign: state.currentCampaign
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch),
    dialogActions: bindActionCreators(DialogActions, dispatch)
  }
}

class CampaignPopout extends Component {
  reviewTerms = () => {
    const { 
      dialogActions, 
      campaignTasks: { selectedTask }, 
      currentCampaign: { campaignTerms }, 
      currentBrand, 
      history 
    } = this.props

    const isPartner = currentBrand.id !== selectedTask.hostBrandId
    const areRulesPublished = campaignTerms && campaignTerms.termsPublishedAt && campaignTerms.termsStatus === 'published'

    const launchRulesModal = isPartner && !areRulesPublished
    const pushHostToRules = !isPartner && !areRulesPublished

    launchRulesModal ? dialogActions.dialogUpdateWithPayload('noOfficialRulesModal', {open: true, messenger: { hostBrandId: selectedTask.hostBrandId, hostBrandName: selectedTask.hostBrandName } }) 
    : pushHostToRules ? history.push(`/builder/${selectedTask.campaign_id}/terms/create`) 
    : window.open(`/landing/terms/${selectedTask.campaign_id}`)
  }

  render() {
    const {
      campaignTasks: {
        selectedTask,
        event,
        calendarType
      },
      campaignTaskActions: {
        deselectTask,
        markComplete,
        currentTaskOpenModal,
        exportTaskEvent,
        hideShowMore
      },
      currentBrand,
      history,
     
    } = this.props

    if (!selectedTask) {
      return (null)
    }

    const buttonMap = {
      review_landing_page: {
        label: 'Preview',
        onClick: () => {
          const prefix = process.env.SENTRY_ENV === 'production' ? 'https://do-not-use-this-link.dojomojo.com' : ''
          window.open(`${prefix}/landing/campaign/${selectedTask.campaign_id}?preview=true`)
        }
      },
      review_mobile_landing_page: {
        label: 'Preview',
        onClick: () =>
            history.push(`/builder/${selectedTask.campaign_id}/edit-content?mobile`)
      },
      review_desktop_landing_page: {
        label: 'Preview',
        onClick: () => {
          const prefix = process.env.SENTRY_ENV === 'production' ? 'https://do-not-use-this-link.dojomojo.com' : ''
          window.open(`${prefix}/landing/campaign/${selectedTask.campaign_id}?preview=true`)
        }
      },
      review_terms: {
        label: 'Review',
        onClick: this.reviewTerms
      },
      review_terms_host: {
        label: 'Review',
        onClick: () => window.open(`/landing/terms/${selectedTask.campaign_id}`)
      },
      review_esp: {
        label: 'Check Settings',
        onClick: () => {
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/email-delivery`)
        }
      },
      review_social_actions: {
        label: 'Review Social Actions',
        onClick: () => {
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/grow-optimize`)
        }
      },
      review_social_actions_host: {
        label: 'Review',
        onClick: () => {
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/grow-optimize`)
        }
      },
      set_up_custom_cta: {
        label: 'Set up Custom CTA',
        onClick: () => {
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/grow-optimize`)
        }
      },
      finalize_partner_list: {
        label: 'Manage Partners',
        onClick: () => {
          history.push(`/builder/${selectedTask.campaign_id}/partner`)
        }
      },
      finalize_landing_page: {
        label: 'Review',
        onClick: () => {
          history.push(`/builder/${selectedTask.campaign_id}/edit-content`)
        }
      },
      finalize_terms: {
        label: 'Review',
        onClick: () =>
          history.push(`/builder/${selectedTask.campaign_id}/terms/dashboard`)
      },
      send_prizing: {
        label: 'Message Host',
        onClick: () => {
          MessagingDispatcher.conversationModal(selectedTask.hostBrandId, selectedTask.hostBrandName)
        }
      },
      review_partner_promo_dates: {
        label: 'View Calendar',
        onClick: () =>
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/calendar`)
      },
      select_campaign_winner: {
        label: 'Pick Winner',
        onClick: () =>
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/quickview`)
      },
      notify_partners: {
        label: 'Send Announcement',
        onClick: () =>
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/announcements`)
      },
      minimum_entries_due: {
        label: 'View Analytics',
        onClick: () =>
          history.push(`/analytics/campaigns/${selectedTask.campaign_id}`)
      },
    }

    const isDashboard = calendarType === 'dashboard'
    const isAssignee = currentBrand.id === selectedTask.assignee_id

    return (
      <TasksPopoutTemplate
        {...selectedTask}
        isAssignee={isAssignee}
        event={event}
        sendMessage={ selectedTask.selfAssigned ? null : () => {
          let brandId = isAssignee ? selectedTask.creator_id : selectedTask.assignee_id
          let brandName = isAssignee ? selectedTask.creator.accountname : selectedTask.assignee.accountname
          MessagingDispatcher.conversationModal(brandId, brandName)
        }}
        analytics={() => {
          history.push(`/analytics/campaigns/${selectedTask.campaign_id}`)
        }}
        manageCampaign={isDashboard ? () => {
          history.push(`/partnerships/campaign/${selectedTask.campaign_id}/quickview`)
        } : null }
        edit={selectedTask.canEdit ? () => {
          currentTaskOpenModal(selectedTask)
        } : null }
        markComplete={selectedTask.canEdit ? () => {
          markComplete(selectedTask)
        } : null }
        deselectTask={deselectTask}
        deselectShowMore={hideShowMore}
        exportToCal={exportTaskEvent}
        ctaAction={buttonMap[selectedTask.category]}
      />
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignPopout))
