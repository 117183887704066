import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { Link } from 'react-router-dom'
import { Button } from 'react-toolbox/lib/button'
import { Small } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import COLOURS from 'util/colours'
import { MobileMarketingContext } from './MobileMarketingContext'

const styles = {
  container: {
    outline: `1px solid ${COLOURS.silver}`,
    padding: '24px 32px',
    marginTop: 32,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  text: {
    width: 512,
  },
}

function AddFollowUpMessageButton(props) {
  const { classes: css } = props
  const { followups, FOLLOWUPS_LIMIT, addFollowup } = React.useContext(MobileMarketingContext)

  if (followups.length >= FOLLOWUPS_LIMIT) return null

  return (
    <div className={css.container}>
      <Button
        onClick={addFollowup}
        theme={ButtonTheme}
        className={ButtonTheme.addFollowUpMessage}
        label="Add Follow Up Message"
        icon={(<img src="/images/icons/add-icon.svg" />)}
        disabled={followups.length >= FOLLOWUPS_LIMIT}
      />
      <div className={css.text}>
        <Small multiline>
          Enable an Automated Follow-Up Message for subscribers who have not clicked after a set period of time.
          <Link to="/profile/brand/data-integrations"> Integrate your payment processor </Link>
          to exclude subscribers who may have made a purchase.
        </Small>
      </div>
    </div>
  )
}

AddFollowUpMessageButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(withStyles(styles)(AddFollowUpMessageButton))
