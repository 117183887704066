import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { H4 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import * as DialogActions from 'actions/dialog-actions'

const mapStateToProps = ({ officialRulesHostReminderModal }) => ({ officialRulesHostReminderModal })
const mapDispatchToProps = dispatch => ({ dialogActions: bindActionCreators(DialogActions, dispatch) })


class OfficialRulesHostReminderModal extends Component {
  closeModal = () => {
    const { dialogActions, analyticsActions } = this.props
    dialogActions.closeDialog('officialRulesHostReminderModal')
    analyticsActions.create('officialHostRulesModal', { meta: { published: false } })
  }

  publishAndTrack = () => {
    const { publishRules, analyticsActions, dialogActions } = this.props
    publishRules()
    dialogActions.closeDialog('officialRulesHostReminderModal')
    analyticsActions.create('officialHostRulesModal', { meta: { published: true } })
  }

  render() {
    const { officialRulesHostReminderModal: { open } } = this.props

    return (
      <Dialog
        title="Before You Post the Official Rules"
        theme={dialogTheme}
        active={open}
        onEscDown={this.closeModal}
        onOverlayClick={this.closeModal}
      >
        <div className={dialogTheme.bannerWrapper} style={{ paddingTop: '8px', backgroundColor: COLOURS.lightPeach }}>
          <img src="/images/icons/warning-microphone-blue-shirt.svg" />
        </div>

        <H4 multiline>
          Please be aware that once you post the Official Rules, this campaign will go live automatically on the scheduled start date.  
        </H4>
        <br/>
        <H4 multiline>
          For Partnership Sweepstakes Only: Partners without signed Partnership Agreements won’t be able to participate in this campaign once it’s live.
        </H4>

        <ModalButtonGroup
          canSave
          confirm={this.publishAndTrack}
          confirmText="Publish Now"
          cancel={this.closeModal}
          cancelText="Do It Later"
          hideLine
        />
      </Dialog>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfficialRulesHostReminderModal))
