import React, { Component } from 'react'
import ImgAndContent from '../util/img-and-content'

import theme from 'css/themes/mobile/home/home.css';

class Features extends Component {
  render() {
    return(
      <div className={ theme.featureSection }>
        <p className={ theme.title }>
          Features
        </p>
        <div className={ theme.infoContainer }>
          <ImgAndContent
            imgSrc="/images/login/mobile/home/sweepstakes-screenshot.png"
            imgClass="sweepstakes-screenshot"
            title="Build Sweepstakes, Quickly"
            explain="Our simple to use templates and editing tools let you create a fully branded landing page in minutes. Generate Official Rules, customize forms and create a URL with one click."
            buttonLink="/sweepstakes"
            buttonText="See How It Works"
          />
          <ImgAndContent
            imgSrc="/images/login/mobile/home/sms-marketing-mobile.png"
            imgClass="sweepstakes-screenshot"
            title="Sms Marketing"
            explain="Combining DojoMojo's new SMS Marketing capabilities  with our powerful acquisition funnel to grow your SMS audience 10-20x faster than any other alternative."
            buttonLink="/sms-marketing"
            buttonText="Learn More"
          />
          <ImgAndContent
            imgSrc="/images/login/mobile/home/brand-search-screenshot.png"
            imgClass="brand-search-screenshot"
            title="Find Thousands of Marketers"
            explain="Browse thousands of potential partners and opportunities to connect."
            buttonLink="/network"
            buttonText="Explore the Network"
          />
          <ImgAndContent
            imgSrc={ '/images/login/side-imgs/partnership-agreement.png' }
            imgClass={ 'terms-screenshot' }
            title={ 'Trust and Transparency' }
            explain="Transparency and trust are the foundations of healthy partnerships. Leverage features that hold partners accountable like setting minimum sweepstakes entry requirements, advanced high-volume CPC deal tracking, security verification and more. We're constantly looking for ways to keep both partnerships and your email list high quality."
          />
        </div>
        <div className={theme.integrations}>
          <ImgAndContent
            imgSrc={ '/images/login/side-imgs/esp-data-integrations.png' }
            imgClass={ 'terms-screenshot' }
            title={ 'Integrations' }
            explain={ 'Integrate with your favorite marketing and sales tools and send new emails directly to your list.' }
          />
        </div>
      </div>
    )
  }
}

export default Features
