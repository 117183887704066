import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import copyLoader from 'util/copy-loader'

import Root from './Root'

import configureStore from 'util/configure-store'

import rootReducer from 'util/root-reducer'
const preloadedState = window.__INITIAL_STATE__
delete window.__INITIAL_STATE__

const store = configureStore(preloadedState, rootReducer)
window.store = store

const app = (
  <Provider store={store}>
    <Root basename="/home" />
  </Provider>
)

const anchor = document.getElementById('app-holder')

const renderApp = () => ReactDOM.hydrate(app, anchor)

copyLoader(renderApp)
