import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import WrapperTooltip from 'util/components/wrapper-tooltip'
import colors from 'util/colours'
import LinkInsertModal from './LinkInsertModal'

const LinkIcon = props => {
  const dPath = 'M6.272 17.728c-1.209-1.21-1.209-3.175 0-4.384l2.829-2.829-1.344-1.343L4.93 12c-1.952 1.952-1.952 5.12 0 7.071 1.952 1.952 5.12 1.952 7.071 0l2.828-2.828-1.343-1.344-2.829 2.829c-1.209 1.209-3.174 1.209-4.384 0zm3.607-2.192l5.657-5.657-1.415-1.415-5.657 5.657 1.415 1.415zM12 4.929L9.172 7.757l1.343 1.344 2.829-2.829c1.209-1.209 3.174-1.209 4.384 0 1.209 1.21 1.209 3.175 0 4.384l-2.829 2.829 1.344 1.343L19.07 12c1.952-1.952 1.952-5.12 0-7.071-1.952-1.952-5.12-1.952-7.071 0z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(-4)">
        <rect width="32" height="24" rx="2" />
        <g>
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(4)" />
          <path fill="#475464" fillRule="nonzero" d={dPath} transform="translate(4)" />
        </g>
      </g>
    </svg>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    margin: '0 6px',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s linear',
    cursor: 'pointer',
    borderRadius: 2,
    '&:hover': {
      background: colors.silver,
    },
  },
  open: {
    background: colors.silver,
  },
  customTooltip: {
    marginTop: 8,
  },
}

function LinkInsert(props) {
  const { classes: css } = props
  const [show, setShow] = useState(false)

  return (
    <div className={cn(css.wrapper, { [css.open]: show })}>
      <WrapperTooltip customClasses={css.customTooltip} delay={1} text="Insert Link">
        <LinkIcon onClick={() => setShow(true)} />
      </WrapperTooltip>
      <LinkInsertModal show={show} onClose={() => setShow(false)} />
    </div>
  )
}

LinkInsert.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(LinkInsert))
