import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';
import { H4 } from 'visual-components/util/texts';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

class ProfileTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      tab,
      active
    } = this.props;

    return(
      <div style={{
        display: 'inline-block',
        position: 'relative'
      }}>
        <div style={{
          marginTop: '6px',
          marginRight: tab.partnerCount ? '30px' : '24px'
        }}>
          <Link to={ tab.href }>
            { active 
              ? <H4 azure>
                  <b>
                    { tab.title }
                  </b>
                </H4>
              : <H4>
                  { tab.title }
                </H4>
            }
          </Link>
        </div>
        {
          tab.partnerCount
          ? <div style={{
              display: 'inline-block',
              background: COLOURS.seaGreen,
              fontFamily: 'Larsseit-Bold',
              height: '24px',
              width: '24px',
              borderRadius: '100%',
              color: 'white',
              fontSize: '12px',
              lineHeight: '26px',
              marginLeft: '-14px',
              textAlign: 'center',
              position: 'absolute',
              right: '0',
              top: '0'
            }}>
              { tab.partnerCount }
            </div>
          : null
        }
      </div>
    )
  }
}

ProfileTab.propTypes = {
  tab: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired
};

export default ProfileTab;
