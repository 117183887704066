import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text'
import CampaignBuilderSection from 'campaigns/builder/components/campaign-builder-section'
import Field from 'util/components/field'
import ToggleField from 'util/components/toggle-field'
import SelectField from 'util/components/select-field'
import NumberField from 'util/components/number-field'
import DateRangePickerWrapper from 'components/util/date-range-picker-wrapper'
import _ from 'lodash'

import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import FieldDispatcher from 'dispatchers/field-dispatcher'
import Validator from 'util/form-validator'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import PrimaryButton from 'util/components/buttons/primary-button'
import CampaignSummaryRow from 'campaigns/builder/components/campaign-summary-row'
import CreateCampaignLimitModal from 'campaigns/CreateCampaignLimitModal'

import * as MetricsActions from 'actions/analytics-actions'

import COLOURS from 'util/colours'
import { H3, P } from 'visual-components/util/texts'
import PrizingValueWarningModal from './prizing-value-warning-modal'
import CampaignDateChangeReminderModal from './CampaignDateChangeReminderModal'
import AnnouncementSentModal from './AnnouncementSentModal'
import BasicsSmsOptin from './BasicsSmsOptin'

import 'css/datepicker.css'

const mapState = function (state) {
  // designHelp: state.designHelp,
  // ^ not using this right now, but might later
  return {
    currentCampaign: state.currentCampaign,
    campaignSetupForm: state.campaignSetupForm,
    currentBrand: state.currentBrand,
  }
}

const dispatchToProps = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

const validations = {
  required: [Validator.required],
  titleMaxLength: [Validator.maxLength(85, 'You have exceeded the character limit')],
  descriptionMaxLength: [Validator.maxLength(125, 'You have exceeded the character limit')],
  prizingMaxLength: [Validator.maxLength(375, 'You have exceeded the character limit')],
}

const hasCampaignPartners = ({ id, invites = [] }) => {
  for (let i = 0; i < invites.length; i++) {
    const invite = invites[i]

    if (
      invite.invitee_id !== id
      && invite.agreement
      && invite.agreement.status === 'completed'
    ) return true
  }

  return false
}

class Basics extends PureComponent {
  static propTypes = {
    isHost: PropTypes.bool,
    isSolo: PropTypes.bool,
    currentBrand: PropTypes.object.isRequired,
    campaignSetupForm: PropTypes.object.isRequired,
    currentCampaign: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    updateAttr: PropTypes.func,
    timezone: PropTypes.string,
  }

  static defaultProps = {
    isHost: false,
    isSolo: false,
    timezone: null,
    updateAttr() {},
  }

  state = {
    prizingValueWarningModal: false,
    dismissed: false,
    focusedInput: null,
  }

  currentDates = {
    startDate: null,
    endDate: null,
  }

  componentDidMount() {
    const { currentCampaign, match } = this.props

    const newCampaign = match.path.includes('new-campaign')
      || match.path.includes('new-solo-sweepstakes')

    const startDate = (currentCampaign && currentCampaign.startDate && !newCampaign)
      ? moment(currentCampaign.startDate)
      : null
    const endDate = (currentCampaign && currentCampaign.endDate && !newCampaign)
      ? moment(currentCampaign.endDate)
      : null

    if (getParameterByName('toastr')) {
      toastr.success('You\'ve joined!', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
    }

    document.title = currentCampaign && currentCampaign.id ? 'Basics - DojoMojo' : 'New Campaign - DojoMojo'

    this.setState({ startDate, endDate }, () => {
      this.currentDates.endDate = endDate
      this.currentDates.startDate = startDate
    })
  }

  updateValue = (field, val) => {
    const { dismissed } = this.state
    const { updateAttr } = this.props

    if (val > 5000 && !dismissed) {
      this.setState({
        prizingValueWarningModal: true,
        dismissed: true,
      })
    }
    updateAttr(field, val)
  }

  closePrizingValueWarningModal = () => {
    this.setState({
      prizingValueWarningModal: false,
    })
  }

  checkIfDatesChanged = () => {
    const { currentCampaign, metricsActions } = this.props

    const startDateChanged = moment(currentCampaign.startDate).diff(this.currentDates.startDate, 'days')
    const endDateChanged = moment(currentCampaign.endDate).diff(this.currentDates.endDate, 'days')

    const hasPartners = hasCampaignPartners(currentCampaign)

    const shouldAnnounce = (startDateChanged || endDateChanged)
      && currentCampaign.id
      && hasPartners

    if (shouldAnnounce) {
      CampaignDispatcher.toggleCampaignDateChangeModal(true)

      // Taking metrics of dates changes on campaign
      metricsActions.create('campaignModified', {
        object: 'Campaign Dates Have Changed',
        meta: {
          campaignId: currentCampaign.id,
          previousDates: {
            startDate: this.currentDates.startDate,
            endDate: this.currentDates.endDate,
          },
          currentDates: {
            startDate: currentCampaign.startDate,
            endDate: currentCampaign.endDate,
          },
        },
      })
    }
  }

  setOldDates = () => {
    this.setState(() => {
      const { updateAttr, currentCampaign } = this.props
      updateAttr({ ...this.currentDates })

      FieldDispatcher.updateAttr(currentCampaign.rid, { ...this.currentDates })

      return { ...this.currentDates }
    })
  }

  setNewDates = () => {
    this.currentDates.startDate = this.state.startDate
    this.currentDates.endDate = this.state.endDate
  }

  getDatePickerFuncs = () => ({
    onDatesChange: dates => {
      let { startDate, endDate } = dates
      const { timezone, currentCampaign: campaign, updateAttr } = this.props

      const dummyStartDate = (startDate || moment().add(1, 'day')).startOf('day') // select tomorrow
      const dummyEndDate = (endDate || moment(dummyStartDate).add(1, 'day')).endOf('day') // select day after start date

      startDate = dummyStartDate.tz(timezone || 'America/New_York')
      endDate = dummyEndDate.tz(timezone || 'America/New_York')

      // Avoid changing the start date if campaign is running
      const startDateToUse = campaign.isRunning && campaign.isRunning() ? campaign.startDate : startDate

      const dateData = { startDate: moment(startDateToUse), endDate: moment(endDate) }

      return this.setState(dateData, () => {
        const startDateUTC = startDate && moment.utc(startDate)
        const endDateUTC = endDate && moment.utc(endDate)

        if (!startDateUTC || !endDateUTC) return

        const dateDataUTC = { startDate: startDateUTC, endDate: endDateUTC }

        updateAttr(dateDataUTC)
        FieldDispatcher.updateAttr(campaign.rid, dateDataUTC)
      })
    },
    onFocusChange: focusedInput => {
      this.setState({ focusedInput }, () => {
        // If focus is lost, wait for currentCampaign to update, then check if changed
        if (!focusedInput) setTimeout(this.checkIfDatesChanged, 100)
      })
    },
    isDayOutsideRange: day => {
      const { currentCampaign: { campaignTerms } } = this.props
      const today = moment().startOf('day')
      if (!campaignTerms || campaignTerms.termsStatus !== 'published') {
        return day.isBefore(today.add(1, 'day'))
      }
      return day.isBefore(today)
    },
  })

  render() {
    const {
      currentCampaign: campaign,
      currentBrand,
      isHost,
      isSolo,
      updateAttr,
      campaignSetupForm,
    } = this.props

    const {
      prizingValueWarningModal,
      focusedInput,
      startDate,
      endDate,
    } = this.state

    const solo = isSolo || campaign.isSolo

    const datePickerFunc = this.getDatePickerFuncs()

    //returns a list, so grabbing first element
    const invite = _.filter(campaign.invites, { invitee_id: currentBrand.id })[0]

    const hostParas = solo ? [
      <P multiline>
        Solo Sweepstakes are a great way to engage your existing audience!
      </P>,
      <P multiline>
        Get started by giving your sweepstakes a name and including what you're thinking in terms of timing. Don't worry - you can come back and make updates at any time.
      </P>,
    ] : [
      <P multiline>
        Give your sweepstakes a name and let potential partners know what you’re giving away, the industry that best describes you and the dates you expect the giveaway to be live.
      </P>,
      <P multiline>
        Not 100% sure yet what you’re giving away, and when? Not to worry - you can come back and edit this at any time.
      </P>,
    ]

    const divStyles = {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: COLOURS.jungleGreen,
      paddingTop: '14px',
      paddingRight: '20px',
      paddingBottom: '20px',
      paddingLeft: '20px',
    }

    const partnerList = (
      <React.Fragment>
        <div key="partnerListKey">
          {
            invite
            && invite.minimumDelivery > 0
              ? (
                <div style={divStyles}>
                  <div style={{ marginBottom: '20px' }}>
                    <P multiline>
                      To ensure an equitable partnership, this campaign requires that a minimum number of people from your audience enter to win before you’ll have access to new emails generated by the giveaway.
                      <a href="https://help.dojomojo.com/hc/en-us/articles/115008842308-Ensure-an-equitable-partnership-with-Minimum-Entry-Requirements" target="_blank">
                        Learn More
                      </a>
                    </P>
                  </div>
                  <div>
                    <P>
                      <em> Minimum Entries Required: </em>
                      {`${d3.format(',')(invite.minimumDelivery)}`}
                    </P>
                  </div>
                </div>
              )
              : null
          }
        </div>

        <CampaignSummaryRow
          key="name"
          header={(
            <div>
              <P>
                <em> Sweepstakes Name </em>
                {` ${campaign.name}`}
              </P>
            </div>
          )}
          noHrBottom
        />
        {!solo && (
          <>
            <CampaignSummaryRow
              key="description"
              header={(
                <div>
                  <P>
                    <em> Description </em>
                    {` ${campaign.description}`}
                  </P>
                </div>
              )}
              noHrBottom
            />
            <CampaignSummaryRow
              key="industry"
              header={(
                <div>
                  <P>
                    <em> Industry </em>
                    {` ${campaign.industry}`}
                  </P>
                </div>
              )}
              noHrBottom
            />
          </>
        )}
        <CampaignSummaryRow
          key="dates"
          header={(
            <div>
              <P>
                <em> Start Date </em>
                { ` ${moment(campaign.startDate).format('MM/DD/YYYY')} — ` }
                <em> End Date </em>
                { ` ${moment(campaign.endDate).format('MM/DD/YYYY')}`}
              </P>
            </div>
          )}
          noHrBottom
        />
        <CampaignSummaryRow
          key="prizingDescription"
          header={(
            <div>
              <P>
                <em> Prizing Description </em>
                { ` ${campaign.prizingDescription}` }
              </P>
            </div>
          )}
          noHrBottom
        />
        <CampaignSummaryRow
          key="prizingValue"
          header={(
            <div>
              <P>
                <em> Est. Prizing Value </em>
                {` ${d3.format('$,')(campaign.prizingValue)}`}
              </P>
            </div>
          )}
          noHrBottom
        />
        { !solo
          && campaign.visible
          && (
            <CampaignSummaryRow
              key="visible"
              header={(
                <div style={{ display: 'inline-block' }}>
                  <P> Brands can search for and apply to join this campaign </P>
                </div>
              )}
              noHrBottom
              headerToolTip="The host has elected to make this campaign searchable, so that potential partners may apply to join it."
            />
          )
        }
      </React.Fragment>
    )

    return (
      <CampaignBuilderSection subSection={campaign.section}>
        <div className="row">
          {/* explain text */}
          <div className="col-sm-6">
            {
              isHost
                ? (
                  <div>
                    <TabTitleAndText
                      headers={[<H3> Sweepstakes Overview </H3>]}
                      paras={hostParas}
                    />
                  </div>
                )
                : (
                  <div>
                    <div>
                      <TabTitleAndText
                        headers={[<H3> Sweepstakes Overview </H3>]}
                      />
                    </div>
                    { partnerList }
                  </div>
                )
            }
          </div>

          {/* input fields */}
          {
            isHost
              ? (
                <div className="col-sm-6">
                  <Field
                    validations={[validations.required, validations.titleMaxLength]}
                    editable={isHost}
                    updateAttr={updateAttr}
                    dataModel={campaign}
                    formModel={campaignSetupForm}
                    attrName="name"
                    label="Sweepstakes Name *"
                    customDivStyle={{}}
                    charLimit={85}
                  />
                  {!solo && (
                    <>
                      <Field
                        validations={[validations.required, validations.descriptionMaxLength]}
                        editable={isHost}
                        updateAttr={updateAttr}
                        dataModel={campaign}
                        formModel={campaignSetupForm}
                        attrName="description"
                        label="Description *"
                        customDivStyle={{}}
                        charLimit={125}
                      />
                      <SelectField
                        formModel={campaignSetupForm}
                        dataModel={campaign}
                        updateAttr={updateAttr}
                        attrName="industry"
                        label="My Industry *"
                        options={[LANGUAGE.brandSettings.tags.unselected].concat(LANGUAGE.brandSettings.tags.tags)}
                      />
                    </>
                  )}
                  <div style={{ marginBottom: '25px' }}>
                    <DateRangePickerWrapper
                      disabled={campaign.hasEnded && campaign.hasEnded()}
                      onDatesChange={datePickerFunc.onDatesChange}
                      onFocusChange={datePickerFunc.onFocusChange}
                      focusedInput={focusedInput}
                      startDate={startDate}
                      startDatePlaceholderText="Click to select start date"
                      endDate={endDate}
                      endDatePlaceholderText="Click to select end date"
                      isOutsideRange={datePickerFunc.isDayOutsideRange}
                      customLeftInputIcon={<img src="images/react-dates-clock-icon.png" />}
                      customRightInputIcon={<img src="images/react-dates-clock-icon.png" />}
                      openDirection={solo ? 'down' : 'up'}
                      displayFormat="MMM D, YYYY"
                      label="Start and End Dates *"
                      tooltip="The campaign will end at midnight EST on the selected end date."
                    />
                  </div>
                  <Field
                    validations={[validations.required, validations.prizingMaxLength]}
                    editable={isHost}
                    updateAttr={updateAttr}
                    dataModel={campaign}
                    formModel={campaignSetupForm}
                    attrName="prizingDescription"
                    type="textarea"
                    rows="6"
                    label="Prizing Description *"
                    tooltip="Be specific and thorough as possible, including date or transportation details of any travel, and the retail value of any products. Include any restrictions, like number of guests, date constraints, or costs not included in the prize."
                    placeholder="Example: Prize will include a five (5) night stay at the Awesome Hotel in New York City for winner and one guest in a Junior Suite Room. Stay will include two (2) complimentary breakfasts, one (1) guided yoga class and winners choice of either one (1) dinner at the Tasty Restaurant or one (1) Spa package at the Hotel Spa. Prize is subject to blackout dates of 12/21/2017 - 1/5/2018."
                    customDivStyle={{}}
                    charLimit={375}
                  />
                  <NumberField
                    dataModel={campaign}
                    formModel={campaignSetupForm}
                    attrName="prizingValue"
                    label="Est. Prizing Value *"
                    placeholder="$4,000"
                    dollarVal
                    validations={validations.required}
                    updateAttr={this.updateValue}
                  />
                  {!solo && (
                    <ToggleField
                      value={campaign.visible}
                      disabled={!isHost}
                      updateAttr={updateAttr}
                      label="Allow brands to search for and apply to join your sweepstakes?"
                      attrName="visible"
                      model={campaign}
                      position="top"
                      toolTip={
                        isHost
                          ? 'Looking for partners? This allows potential partners to view and apply to join your giveaway in our Join Campaigns dashboard. We highly recommend keeping your campaign visible, but you can change this at any time.'
                          : 'The host has elected to make this campaign searchable, so that potential partners may apply to join it.'
                      }
                    />
                  )}
                  {solo && (
                    <BasicsSmsOptin campaign={campaign} updateAttr={updateAttr} />
                  )}
                </div>
              ) : (
                <div className="col-sm-6 text-right">
                  <a href={campaign.previewLink} target="_blank">
                    <PrimaryButton
                      blueButton
                      styles={{
                        marginBottom: 0,
                        marginTop: '2px',
                        marginRight: 0,
                      }}
                    >
                      Launch Preview
                    </PrimaryButton>
                  </a>
                </div>
              )
          }
        </div>
        <PrizingValueWarningModal active={prizingValueWarningModal} closeModal={this.closePrizingValueWarningModal} />
        <CampaignDateChangeReminderModal setOldDates={this.setOldDates} setNewDates={this.setNewDates} />
        <AnnouncementSentModal />
        <CreateCampaignLimitModal isSolo={solo} />
      </CampaignBuilderSection>
    )
  }
}

export default withRouter(connect(mapState, dispatchToProps)(Basics))
