import React, { Component } from "react";
import { Link } from "react-router-dom";
import theme from "css/themes/homePage/careers.css";
import axios from "axios";

class CurrentOpenings extends Component {
  state = {
    openings: []
  };
  componentDidMount() {
    axios
      .get(
        "https://api.lever.co/v0/postings/dojomojo?mode=json"
      )
      .then(response => {
        this.setState({
          openings: response.data
        });
      });
  }
  render() {
    return (
      <div className={theme.currentOpenings}>
        <div className={theme.currentInner}>
          <div className={theme.openingHeader}>
            <p className={theme.title}>Current Openings</p>
            <p className={theme.explain}>
              We’re always looking to meet interesting people. Don’t see a role
              here that’s perfect for you?
              <br />
              Drop us a line at
              <a href="mailto:careers@dojomojo.com">careers@dojomojo.com</a>
              and let us know how you can help.
            </p>
          </div>
          {this.state.openings.map(job => (
            <div key={job.id} className={theme.eachPosting}>
              <a target="_blank" href={`${job.hostedUrl}?lever-origin=applied&lever-source%5B%5D=DojoWebsite`} rel="noreferrer">
                {job.text}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CurrentOpenings;
