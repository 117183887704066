import React from 'react'
import COLOURS from 'util/colours'

class MessagingBrandLogo extends React.Component {
  viewBrand = () => {
    window.open(`/app/explore/brands/${this.props.brandId}`)
  }

  render() {
    let {
      logo,
      brandId
    } = this.props

    return (
      <div onClick={ brandId ? this.viewBrand : null }
        style={{
          width: '48px',
          minWidth: '48px',
          height: '48px',
          padding: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: COLOURS.silver,
          display: 'table-cell',
          verticalAlign: 'middle',
          background: '#fff',
          cursor: brandId ? 'pointer' : 'default'
      }}>
        <img src={ logo }
          style={{
            maxHeight: '38px',
            maxWidth: '38px',
            display: 'block',
            margin: 'auto'
        }}/>
      </div>
    )
  }
}

export default MessagingBrandLogo
