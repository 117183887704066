import React from 'react'
import { string } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P, H6 } from 'visual-components/util/texts'

import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

import UploadImage from 'components/util/editor-uploader'

const mapStateToProps = ({ currentBrand: { id: currentBrandId } }) => ({
  currentBrandId,
})

class Upload extends React.Component {
  static contextTypes = {
    impersonating: string,
  }

  componentWillMount() {
    const { partnershipAgreement, actions, params, history } = this.props

    if (partnershipAgreement.error) {
      history.replace(`/builder/${params.campaignId}/partnership-agreement/dashboard`)
    }

    if (!partnershipAgreement.agreement.id) {
      actions.loadPartnershipAgreement(params.partnershipId)
    }
  }

  uploadSuccess = (src) => {
    this.props.actions.updateUploadedAgreement(src)
  }

  downloadDocument = () => {
    const {
      currentBrandId,
      partnershipAgreement: { id, agreement: { custom_file_name } },
    } = this.props
    const { impersonating } = this.context
    const encodedFileName = encodeURIComponent(custom_file_name)
    const url = `/partnership-agreement/download/${currentBrandId}/${id}/${encodedFileName}${impersonating ? `?impersonating=${impersonating}` : ''}`
    window.open(url)
  }

  render() {
    const { partnershipAgreement, hideAction, activeDialog, closeDialog } = this.props

    const custom_file_name = partnershipAgreement.agreement.custom_file_name

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    return (
      <div>
        <Grid>
          <Row style={{
            marginBottom: '40px'
          }}>
            <P multiline>
              Upload your own Partnership Agreement here as a .pdf, .doc, .txt, .png, or .jpg file. Note that by using your document, DojoMojo will not be able to automate many campaign administrative functions, or set and enforce Minimum Entry Thresholds.
            </P>
          </Row>

          { custom_file_name ?
            <div>
              <Row style={{
                marginBottom: '40px'
              }}>
                <H6>
                  {custom_file_name}
                </H6>
              </Row>
              <Row style={{
                marginBottom: '40px'
              }}>
                <Button
                  theme={ buttonTheme }
                  onClick={this.downloadDocument}
                  className={ buttonTheme.whiteButton }
                  label={ 'Download' }
                  raised
                  style={{
                    marginRight: '12px'
                  }}
                />
              </Row>
              {
                hideAction ? null
                :
                <Row>
                  <UploadImage
                    action={ `/partnership-agreement/custom-upload/${partnershipAgreement.id}` }
                    src={ "campaign-asset" }
                    success={ this.uploadSuccess }
                    name="upload-logo-image"
                    label="Upload Asset"
                    uploadAsset={ true }
                    maxFilesize={ 25 }
                    acceptedFiles="application/pdf,application/msword,text/plain,image/jpeg,image/png"
                  >
                    <Button
                      theme={ buttonTheme }
                      className={ buttonTheme.greenButton }
                      label={ 'Upload New' }
                      raised
                    />
                  </UploadImage>
                </Row>
              }
            </div>
            :
            <div>
              {
                hideAction ? null
                :
                <Row>
                  <UploadImage
                    action={ `/partnership-agreement/custom-upload/${partnershipAgreement.id}` }
                    src={ "campaign-asset" }
                    success={ this.uploadSuccess }
                    name="upload-logo-image"
                    label="Upload Asset"
                    uploadAsset={ true }
                    maxFilesize={ 25 }
                    acceptedFiles="application/pdf,application/msword,text/plain,image/jpeg,image/png"

                  >
                    <Button
                      theme={ buttonTheme }
                      className={ buttonTheme.greenButton }
                      label={ 'Upload New' }
                      raised
                    />
                  </UploadImage>
                </Row>
              }
            </div>
          }
        </Grid>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(Upload))
