import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-toolbox/lib/button'

import {
  string, func, node, bool,
} from 'prop-types'
import { Small } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import SocialTheme from 'css/themes/SocialGrowth.css'
import buttonTheme from 'css/themes/Buttons.css'

const Lock = () => (
  <svg style={{ marginRight: 8 }} width="12" height="15" viewBox="0 0 12 15">
    <g fill="none" fillRule="evenodd">
      <path d="M-2 0h16v16H-2z" />
      <path
        fill={COLOURS.darkGrey}
        fillRule="nonzero"
        d="M10 5.333h-.667V4a3.335 3.335 0 0 0-6.666 0v1.333H2c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm-4 6c-.733 0-1.333-.6-1.333-1.333S5.267 8.667 6 8.667s1.333.6 1.333 1.333-.6 1.333-1.333 1.333zm2.067-6H3.933V4c0-1.14.927-2.067 2.067-2.067 1.14 0 2.067.927 2.067 2.067v1.333z"
      />
    </g>
  </svg>
)

const ConversionActionCard = props => {
  const {
    name, title, lock, isSelected, selectOption, children,
  } = props

  return (
    <div
      className={SocialTheme.conversionSelectorCard}
      style={{
        borderColor: isSelected
          ? COLOURS.azure
          : COLOURS.silver,
        backgroundColor: lock
          ? 'rgba(216, 216, 216, 0.2)'
          : 'white',
      }}
    >
      <div
        className={SocialTheme.conversionSelector}
        onClick={() => {
          if (!lock) selectOption(name)
        }}
      >
        {!lock ? (
          <div className={SocialTheme.radioContainer}>
            {isSelected && <div className={SocialTheme.radioSelector} />}
          </div>
        ) : (
          <Lock />
        )}
        <Small>
          <b>{title}</b>
        </Small>
        {lock && (
          <a href="/app/upgrade-plan" className={SocialTheme.upgradeLink}>
            <Small azure>
              <em>
                Unlock
              </em>
            </Small>
          </a>
        )}
      </div>
      {children}
    </div>
  )
}

ConversionActionCard.propTypes = {
  name: string.isRequired,
  title: string.isRequired,
  lock: bool.isRequired,
  isSelected: bool.isRequired,
  selectOption: func.isRequired,
  children: node.isRequired,
}

export default withRouter(ConversionActionCard)
