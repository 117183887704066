import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { H3 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'

import * as RoiCalculatorActions from 'actions/roi-calculator-actions'
import * as DataIntegrationsActions from 'actions/data-integration-actions'
import * as MetricsActions from 'actions/analytics-actions'

import ButtonTheme from 'css/themes/Buttons.css'
import Theme from 'css/themes/roiCalculatorTheme.css'

const createSrcSet = src => {
  const srcs = ['', '@2x', '@3x']

  return srcs.map(size => {
    return `${src.split('.').join(`${size}.`)} ${size.replace('@', '')}`
  }).join(',')
}

const stateToProps = ({ dataIntegrations, roiCalculator }) => ({ dataIntegrations, roiCalculator })

const dispatchToProps = dispatch => ({
  roiCalculatorActions: bindActionCreators(RoiCalculatorActions, dispatch),
  metricsActions: bindActionCreators(MetricsActions, dispatch),
  dataIntegrationsActions: bindActionCreators(DataIntegrationsActions, dispatch),
})

const kbUrls = {
  dripCampaign: 'https://help.dojomojo.com/hc/en-us/articles/115003905192-How-to-Optimize-Your-Email-Drip-Campaign',
  customCTA: 'https://help.dojomojo.com/hc/en-us/articles/115002788511-Drive-Conversions-a-with-Custom-CTA-',
  powerOfHosting: 'https://www.dojomojo.com/home/case_studies/rebloom',
}

const openTab = url => {
  const win = window.open(url, '_blank')

  win.focus()
}

const footers = {
  dataIntegration: {
    image: {
      height: '79px',
      src: '/images/roi-calculator/sales-generated.png',
    },
    text: 'Want to track how your new leads are converting? Integrate your e-commerce payment provider to find out.',
    button: {
      text: 'Integrate',
      onClick: (history, metricActions) => {
        metricActions.create('clickedAddDataIntegration', {
          meta: { from: 'roiCalculator' },
        })

        history.push('/profile/brand/data-integrations')
        // Scroll to top
        window.scrollTo(0, 0)
      },
    },
  },
  hosting: {
    image: {
      height: '80px',
      src: '/images/roi-calculator/case-study-illustration.png',
    },
    text: 'Learn how a small startup leveraged DojoMojo to grow their audience. This could be you.',
    button: {
      text: 'Read Case Study',
      onClick: () => openTab(kbUrls.powerOfHosting),
    },
  },
  driveConversion: {
    image: {
      height: '96px',
      src: '/images/roi-calculator/roi-banner-asset-6.png',
    },
    text: 'Maximize conversions by using Custom CTA to drive post-campaign-entry traffic to your website.',
    button: {
      text: 'Learn More',
      onClick: () => openTab(kbUrls.customCTA),
    },
  },
  optimizeDripCampaigns: {
    image: {
      height: '80px',
      src: '/images/roi-calculator/drip-campaigns-illustration.png',
    },
    text: 'Learn how to use email drip campaigns to engage leads you\'ve acquired.',
    button: {
      text: 'Learn More',
      onClick: () => openTab(kbUrls.dripCampaign),
    },
  },
}

const checkIntegrations = integrations => {
  return integrations.pixel
    || Object.keys(integrations.shopify || {}).length > 0
    || Object.keys(integrations.stripe || {}).length > 0
}

class ROIFooter extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    roiCalculatorActions: PropTypes.object.isRequired,
    dataIntegrationsActions: PropTypes.object.isRequired,
    dataIntegrations: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    roiCalculator: PropTypes.object.isRequired,
  }

  componentDidMount = () => {
    const { dataIntegrationsActions, roiCalculatorActions } = this.props

    dataIntegrationsActions.load()
    roiCalculatorActions.getIntegrationsInfo()
  }

  getFooter = () => {
    const { dataIntegrations, roiCalculator } = this.props

    const hasIntegrations = checkIntegrations(dataIntegrations)

    // If there isnt a conversion integration installed
    if (!hasIntegrations) return 'dataIntegration'
    // If there is pixel integration but brand has not hosted yet
    if (dataIntegrations.pixel && !roiCalculator.hasHosted) return 'hosting'
    // If it has hosted, but has never had a custom CTA
    if (roiCalculator.hasHosted && !roiCalculator.hasCustomCTA) return 'driveConversion'

    // Default case
    return 'optimizeDripCampaigns'
  }

  onFooterClick = ({ button: { onClick } }) => () => {
    const { history, metricsActions } = this.props

    return onClick(history, metricsActions)
  }

  render() {
    const footer = footers[this.getFooter()]

    if (!footer) return null

    return (
      <div className={Theme.footer}>
        <img
          src={footer.image.src}
          srcSet={createSrcSet(footer.image.src)}
          style={{ height: footer.image.height }}
        />
        <div style={{ width: '498px' }}>
          <H3 multiline>
            <span style={{ fontFamily: 'Larsseit' }}>
              { footer.text }
            </span>
          </H3>
        </div>
        <Button
          onClick={this.onFooterClick(footer)}
          label={footer.button.text}
          className={`${ButtonTheme.raised} ${ButtonTheme.blueButton}`}
          theme={ButtonTheme}
          style={{ marginRight: '0px' }}
        />
      </div>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(ROIFooter))
