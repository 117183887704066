import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import EditCover from 'visual-components/brandProfile/EditCover'
import BrandSocialDispatcher from 'dispatchers/brand-social-dispatcher'

const stateToProps = ({ currentBrand }) => ({ currentBrand })

class EditLogoContainer extends PureComponent {
  state = {
    facebookCoverPhoto: null,
    coverPhotoOffsetY: null,
    active: false,
  }

  componentDidMount() {
    const {
      currentBrand: {
        facebookCoverPhoto,
        coverPhotoOffsetY
      }
    } = this.props

    this.setState(() => ({
      facebookCoverPhoto,
      coverPhotoOffsetY,
    }))
  }

  changePosition = position => this.setState({ coverPhotoOffsetY: position })

  incrementPosition = () => {
    const { coverPhotoOffsetY } = this.state

    this.setState({
      coverPhotoOffsetY: parseInt(coverPhotoOffsetY, 10) + 1,
    })
  }

  decrementPosition = () => {
    const { coverPhotoOffsetY } = this.state

    this.setState({
      coverPhotoOffsetY: parseInt(coverPhotoOffsetY, 10) - 1,
    })
  }

  uploadSuccess = src => {
    this.setState({
      facebookCoverPhoto: src,
    })
  }

  handleToggle = () => this.setState(({ active }) => ({ active: !active }))

  cancel = () => this.handleToggle()

  save = () => {
    BrandSocialDispatcher.updateAttrPersist('facebookCoverPhoto', this.state.facebookCoverPhoto)
    BrandSocialDispatcher.updateAttrPersist('coverPhotoOffsetY', parseInt(this.state.coverPhotoOffsetY, 10))

    this.handleToggle()
  }

  render() {
    const {
      facebookCoverPhoto,
      coverPhotoOffsetY,
      active,
    } = this.state

    return (
      <EditCover
        active={active}
        facebookCoverPhoto={facebookCoverPhoto}
        coverPhotoOffsetY={coverPhotoOffsetY}
        handleToggle={this.handleToggle}
        uploadSuccess={this.uploadSuccess}
        changePosition={this.changePosition}
        incrementPosition={this.incrementPosition}
        decrementPosition={this.decrementPosition}
        save={this.save}
        cancel={this.cancel}
      />
    )
  }
}

export default connect(stateToProps)(EditLogoContainer)
