import React, { PureComponent as Component } from 'react'
import { number } from 'prop-types'
import DisplayedStat from '../util/displayed-stat'

class StatsDisplay extends Component {
  static propTypes = {
    campaigns: number.isRequired,
    listSize: number.isRequired,
    entriesDriven: number.isRequired,
  }

  render() {
    const {
      listSize,
      campaigns,
      entriesDriven,
    } = this.props

    return (
      <div>
        <DisplayedStat name="list size" value={listSize} />
        <div style={{ padding: '0 18px', display: 'inline-block' }}>
          <DisplayedStat name="campaigns" value={campaigns} />
        </div>
        <DisplayedStat name="entries driven" value={entriesDriven} />
      </div>
    )
  }
}

export default StatsDisplay
