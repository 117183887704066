import React from 'react';
import _ from 'lodash'

import DojoModal from 'util/components/dojomodal';
import Table from 'util/components/standardized-tables';
import DistributionItem from './distribution-item';
import CampaignDispatcher from 'dispatchers/campaign-dispatcher';
import { H2, P } from 'visual-components/util/texts';

class TrafficDistribution extends React.Component {
  updateAttr(attr, value) {
    CampaignDispatcher.updateTrafficProportion();
  }

  render() {
    var currentCampaign = this.props.currentCampaign;
    var partnerInvites = _.filter(currentCampaign.invites, {status: 'accepted'});
    var currentBrand = this.props.currentBrand;

    var partnerRows = _.map( partnerInvites, (invite, idx) => {
      return(
        <DistributionItem
          key={ idx }
          invite={ invite }
          currentCampaign={currentCampaign}
          updateAttr={ CampaignDispatcher.updateTrafficProportion }
        />
      )
    });

    return(
      <div>
        <DojoModal
          isOpen={ this.props.expanded }
          hideModal={ this.props.closeModal }
          title='Would you Like to Share this Traffic with your Partners?'
        >
          <div className="row text-center"
            style={{
              marginBottom: '50px'
          }}>
            <div className="col-xs-12">
              <P>
                As the host, you have the option to share Custom CTA traffic with your Premium partners. Partners will set up their own CTA, but you can specify what percentage of traffic to this page you’d like to redirect to their CTA.
              </P>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Table.Table allWhite={true}>
                <Table.Tbody>
                  <Table.HeaderTr>
                    <Table.LeftTh>
                      {/* LANGUAGE.brandSettings.detail.specs */}
                      Brand Name
                    </Table.LeftTh>
                    <Table.Th styles={{
                      width: '135px'
                    }}>
                      Traffic to Redirect
                    </Table.Th>
                  </Table.HeaderTr>
                </Table.Tbody>
                <Table.Tbody>

                  { partnerRows }

                </Table.Tbody>
              </Table.Table>
            </div>
          </div>
        </DojoModal>
      </div>
    )
  }
}

export default TrafficDistribution;
