import React from 'react';
import { object } from 'prop-types';
import moment from 'moment-timezone';

import { Card } from 'react-toolbox/lib/card';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H2, H5, P, Small, Tiny } from 'visual-components/util/texts';

class MultiLineTooltip extends React.Component {
  render() {
    let {
      label,
      selectedTab,
      hideMap,
      dataByDate,
      period
    } = this.props;

    let tabMap = {
      campaignVisits: 'Campaign Visits',
      campaignEntries: 'Campaign Entries',
      campaignNTF: 'NTF Emails',
      campaignTotalSocialActions: 'Campaign Social Actions',
      campaignPartnerSmsContribution: 'SMS Signups',
    }

    let aggregate = 0;
    let brandTotals = dataByDate && dataByDate.map((data,idx) => {
      if (!hideMap[`id_${data.brand_id}`]) {
        aggregate += parseInt(data.report_data);

        return (
          <Row
          key={idx}
          style={{
            marginBottom: '12px'
          }}>
            <div style={{
              display: 'inline-block',
              width: '130px'
            }}>
              <Small>
                { data.brand_name }
              </Small>
            </div>
            <div style={{
              display: 'inline-block',
              width: '70px',
              textAlign: 'right'
            }}>
              <H5>
                { d3.format(',')(data.report_data) }
              </H5>
            </div>
          </Row>
        )
      }
    })

    return (
      <Card style={{
        padding: '12px',
        width: '224px'
      }}>
        <div style={{
          marginBottom: '12px'
        }}>
          <Tiny>
            { period }
          </Tiny>
        </div>

        <div style={{
          marginBottom: '12px'
        }}>
          <Small>
            <em>
              { tabMap[selectedTab] }
            </em>
          </Small>
        </div>

        <div style={{
          marginBottom: '12px'
        }}>
          <div style={{
            display: 'inline-block',
            marginRight: '4px'
          }}>
            <H2>
              { d3.format(',')(aggregate || 0) }
            </H2>
          </div>
          <div style={{
            display: 'inline-block'
          }}>
            <Small>
              total
            </Small>
          </div>
        </div>

        { brandTotals }
      </Card>
    )
  }
};

export default MultiLineTooltip;
