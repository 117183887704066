import React, { PureComponent } from 'react'
import {
  func, object, bool,
} from 'prop-types'
import Theme from 'css/HoverMenu.css'

class HoverMenu extends PureComponent {
  static propTypes = {
    onPreview: func.isRequired,
    onEdit: func,
    onDelete: func,
    onDownload: func,
    style: object,
    transparent: bool,
    show: bool,
  }

  static defaultProps = {
    transparent: false,
    onDelete: null,
    onDownload: null,
    onEdit: null,
    style: {},
    show: false,
  }

  render() {
    const {
      transparent,
      onPreview,
      onEdit,
      onDelete,
      onDownload,
      style,
      show,
    } = this.props

    if (!show) return null

    return (
      <div style={style} className={`${Theme.hoverMenu} ${transparent ? Theme.transparent : ''}`}>
        <div className={Theme.icon} onClick={onPreview}>
          <div className={Theme.viewIcon} />
        </div>
        {
          onEdit
            ? (
              <div className={Theme.icon} onClick={onEdit}>
                <div className={Theme.editIcon} />
              </div>
            ) : null
        }
        {
          onDelete
            ? (
              <div className={Theme.icon} onClick={onDelete}>
                <div className={Theme.trashIcon} />
              </div>
            ) : null
        }
        {
          onDownload
            ? (
              <div className={Theme.icon} onClick={onDownload}>
                <div className={Theme.downloadIcon} />
              </div>
            ) : null
        }
      </div>
    )
  }
}

export default HoverMenu
