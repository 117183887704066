import React, { PureComponent } from 'react'
import { H3 } from 'visual-components/util/texts'
import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'

class FullscreenModal extends PureComponent {
  render() {
    const { expand, title, close, children } = this.props

    return (
      <div className={FullscreenModalTheme.container}>
        <div className={`${FullscreenModalTheme.main}${expand ? ` ${FullscreenModalTheme.expand}` : ''}`}>
          <div className={FullscreenModalTheme.headerMain}>
            {
              close ? (
                <div className={FullscreenModalTheme.closeButton}>
                  <img src="/images/icons/close.svg" onClick={close} />
                </div>
              ) : null
            }
            <div className={FullscreenModalTheme.title}>
              <H3>{title}</H3>
            </div>
          </div>
          {children}
        </div>
      </div>
    )
  }
}

export default FullscreenModal
