import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { Dialog } from 'react-toolbox/lib/dialog'
import { P, H3 } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import IntegrationsMap from 'visual-components/integrations/integrations-map'
import Spinner from 'util/components/spinner'
import COLOURS from 'util/colours'
import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import * as DealIntegrationActions from 'actions/deal-integrations-actions'
import IntegrationModal from './integration-modal'
import ChooseIntegration from './choose-integration'
import IntegrationItem from './integration-item'

const mapStateToProps = ({ currentBrand, deals: { deal }, dealIntegrations }) =>
  ({ currentBrand, deal, dealIntegrations })

const mapDispatchToProps = dispatch => ({
  dealIntegrationActions: bindActionCreators(DealIntegrationActions, dispatch),
})

class ContentIntegration extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    deal: object.isRequired,
    dealIntegrations: object.isRequired,
    dealIntegrationActions: object.isRequired,
    match: object.isRequired,
    history: object.isRequired,
  }

  state = {
    modalActive: false,
  }

  componentWillMount() {
    const {
      match: {
        params: { dealId },
      },
      dealIntegrationActions: {
        loadIntegrations,
      },
    } = this.props

    loadIntegrations(dealId)
  }

  toggleModal = () => {
    const { modalActive } = this.state
    this.setState({
      modalActive: !modalActive,
    })
  }

  closeIntegration = () => {
    const {
      deal,
      history,
      dealIntegrationActions: {
        clearIntegration,
      },
    } = this.props

    history.push(`/partnerships/deal/${deal.id}/content-integration`)
    clearIntegration()
  }

  disconnect = (campaign, label) => {
    const {
      dealIntegrationActions: {
        updateIntegration,
        clearConfirmation,
      },
    } = this.props

    ConfirmationDispatcher.check({
      header: 'Confirm Campaign Disconnection',
      copy: `Are you sure you want to disconnect the <em>${label}</em> campaign <em>${campaign.label}</em>? Once deleted, we will stop syncing the data to Analytics.`,
      confirmText: 'Disconnect',
      action: () => {
        updateIntegration(campaign.integration_id, {
          attr: 'active',
          value: false,
          campaignId: campaign.value,
        })

        clearConfirmation()
      },
    })
  }

  preview = campaign => {
    const {
      currentBrand,
      deal,
    } = this.props

    const {
      value,
      integration_type,
      integration_details,
    } = campaign

    const urls = {
      mailchimp: `/mailchimp/fetch-content/${currentBrand.id}/${deal.id}/${value}?dc=${integration_details.dc}&mailchimpAccessToken=${integration_details.mailchimpAccessToken}`,
    }

    window.open(urls[integration_type])
  }

  goTo = integration => {
    const { deal, history } = this.props
    history.push(`/partnerships/deal/${deal.id}/content-integration/${integration}`)
  }

  render() {
    const {
      currentBrand,
      deal,
      dealIntegrations: {
        dealIntegrations: integrations,
        loading: integrationsLoading,
      },
      match: {
        params: { key: selectedIntegration },
      },
    } = this.props

    const { modalActive } = this.state

    if (!deal || !Object.keys(deal).length || integrationsLoading) {
      return (
        <Spinner />
      )
    }

    const isSeller = currentBrand.id === deal.selling_brand_id
    const dealComplete = ['completed', 'in_dispute'].includes(deal.status)

    let activeCampaigns = []
    const activeIntegrationsArr = []
    if (integrations && integrations.length) {
      integrations.forEach(integration => {
        const integration_details = Object.assign({}, integration.details)
        delete integration_details.campaigns

        if (Object.keys(integration_details).length) {
          activeIntegrationsArr.push(integration.integration_type)
        }

        if (integration && integration.details && integration.details.campaigns) {
          integration.details.campaigns.forEach(campaign => {
            if (campaign.active) {
              activeCampaigns.push(Object.assign({}, campaign, {
                integration_id: integration.id,
                integration_type: integration.integration_type,
                integration_details,
              }))
            }
          })
        }
      })
    }

    activeCampaigns = activeCampaigns.map((campaign, idx) => {
      const integrationItem = IntegrationsMap.find(item => item.key === campaign.integration_type)
      return (
        <Row
          key={`campaign${campaign.value}`}
          style={{
            height: '52px',
            padding: '9px 20px',
            borderBottom: idx !== activeCampaigns.length - 1 ? `1px solid ${COLOURS.silver}` : 'none',
          }}
        >
          <Col xs={8}>
            <div style={{ marginTop: '11px' }}>
              <P>
                <em>{integrationItem.label}</em>
                {' - '}
                {campaign.label}
              </P>
            </div>
          </Col>
          <Col xs={4}>
            <div
              className="text-right"
              style={{ marginTop: isSeller && !dealComplete ? 0 : '8px' }}
            >
              { isSeller && !dealComplete
                && (
                  <Button
                    onClick={() => this.disconnect(campaign, integrationItem.label)}
                    label="Disconnect"
                    theme={ButtonTheme}
                    primary
                    flat
                  />
                )
              }
              <img
                src="/images/icons/blue-view.svg"
                onClick={() => this.preview(campaign)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </Col>
        </Row>
      )
    })

    const activeIntegrations = activeIntegrationsArr.map((integration, idx) => {
      const integrationItem = IntegrationsMap.find(item => item.key === integration)
      return (
        <div
          key={`integration${integration}`}
          onClick={!dealComplete && (() => this.goTo(integration))}
          style={{
            display: 'inline-block',
            marginRight: idx !== 0 && idx % 9 === 0 ? 0 : '16px',
          }}
        >
          <IntegrationItem
            integration={integrationItem}
            active={activeIntegrationsArr.includes(integrationItem.key)}
            noHover={dealComplete}
            noBoxShadow
          />
        </div>
      )
    })

    return (
      <div>
        <Card style={{ padding: '24px 20px', marginTop: '20px' }}>
          <Row style={{ marginBottom: '24px' }}>
            <Col style={{ width: '361px', marginRight: '623px' }}>
              <H3 coral>
                <small>
                  { 'Connect Email Service Provider' }
                </small>
              </H3>
            </Col>
            <Col style={{ width: '129px' }}>
              { isSeller
                && (
                  <div style={{ marginTop: '-8px', float: 'right' }}>
                    <Button
                      onClick={this.toggleModal}
                      label="Connect ESP"
                      theme={ButtonTheme}
                      className={
                        dealComplete ? ButtonTheme.disabledButton : ButtonTheme.blueButton
                      }
                      disabled={dealComplete}
                      primary
                      raised
                    />
                  </div>
                )
              }
            </Col>
          </Row>

          <div style={{ marginBottom: activeIntegrations.length ? '16px' : 0 }}>
            <P multiline>
              { 'Integrate your ESP and connect your campaign to share performance and content and activate Media Market Analytics.' }
            </P>
          </div>

          { isSeller && activeIntegrations.length
            ? (
              <div style={{ marginBottom: '8px' }}>
                { activeIntegrations }
              </div>
            ) : null
          }

          { activeCampaigns.length
            ? (
              <div style={{ border: `1px solid ${COLOURS.silver}` }}>
                { activeCampaigns }
              </div>
            ) : null
          }
        </Card>

        { isSeller
          && (
            <Dialog
              active={modalActive || !!selectedIntegration}
              theme={DialogTheme}
              onEscKeyDown={selectedIntegration ? this.closeIntegration : this.toggleModal}
              onOverlayClick={selectedIntegration ? this.closeIntegration : this.toggleModal}
            >
              { selectedIntegration
                ? (
                  <IntegrationModal
                    {...this.props}
                    currentBrand={currentBrand}
                    closeIntegration={this.closeIntegration}
                  />
                ) : (
                  <ChooseIntegration
                    deal={deal}
                    activeIntegrations={activeIntegrationsArr}
                    toggleModal={this.toggleModal}
                  />
                )
              }
            </Dialog>
          )
        }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentIntegration))
