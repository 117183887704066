import React from 'react'
import { object } from 'prop-types'
import { Select, Input } from 'visual-components/util/form'
import {
  H3,
  H4,
  H6,
  P,
  Small,
} from 'visual-components/util/texts'
import moment from 'moment-timezone'
import DateRangePickerWrapper from 'components/util/date-range-picker-wrapper'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import SearchBrand from './SearchBrand'

class EnterMediaInfo extends React.Component {
  static propTypes = {
    functions: object.isRequired,
    data: object.isRequired,
  }

  render() {
    const {
      functions: {
        searchBrand,
        toggleSearch,
        selectBrand,
        onDatesChange,
        onFocusChange,
      },
      data: {
        selectedBrand = {},
        searchInFocus,
        searchValue,
        searchResults,
        mediaType,
        startDate,
        endDate,
        focusedInput,
        status,
        isSeller,
        dealWithAccountname,
      },
    } = this.props

    const mediaTypes = [
      { label: "Select the Media Asset you're selling", value: '', disabled: true },
      { label: 'Sponsored email', value: 'sponsored_email' },
      { label: 'Dedicated email', value: 'dedicated_email' },
      { label: 'Blog post', value: 'blog_post' },
      { label: 'Banner ad', value: 'banner_ad' },
      { label: 'Social media post', value: 'social_media_post' },
      { label: 'Instagram story', value: 'instagram_story' },
      { label: 'Other', value: 'other' },
    ]

    const isDayOutsideRange = day => day.isBefore(moment().add(1, 'day'))

    return (
      <div style={{ display: 'inline-block' }}>
        <div className={SellMediaModalTheme.orangeTitle}>
          <H3 coral>
            <small>Media Information</small>
          </H3>
        </div>

        <H4 multiline>Add some quick info to set up the sale.</H4>

        <div className={SellMediaModalTheme.search}>
          <div className={SellMediaModalTheme.searchTitle}>
            <H6>
              {`${isSeller ? 'Buying' : 'Selling'} Brand`}
            </H6>
          </div>

          { ['edit', 'counter'].includes(status)
            ? <P>{dealWithAccountname}</P>
            : (
              <SearchBrand
                selectedBrand={selectedBrand}
                selectBrand={selectBrand}
              />
            )
          }
        </div>

        <div className={SellMediaModalTheme.search}>
          <Select
            name="mediaType"
            label="Media Type"
            options={mediaTypes}
            required
          />

          { mediaType === 'other'
            && (
              <Input
                name="otherMediaInput"
                label="Other Media Type"
                required
                type="string"
                characterLimit={25}
              />
            )
          }

          { isSeller
            ? (
              <Input
                name="label"
                label="Name"
                placeholder="Example: May Newsletter with Wine Awesomeness"
                required
                type="string"
                characterLimit={35}
              />
            ) : null
          }
        </div>

        <div>
          <div className={SellMediaModalTheme.flex}>
            <H6>
              Campaign Dates
            </H6>
            <Small>
              You can always adjust these dates later.
            </Small>
          </div>
          <div className={SellMediaModalTheme.calendar}>
            <DateRangePickerWrapper
              onDatesChange={onDatesChange}
              onFocusChange={onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              startDatePlaceholderText="Click to select start date"
              endDate={endDate}
              endDatePlaceholderText="Click to select end date"
              isOutsideRange={isDayOutsideRange}
              customLeftInputIcon={<img src="images/react-dates-clock-icon.png" />}
              customRightInputIcon={<img src="images/react-dates-clock-icon.png" />}
              openDirection="up"
              displayFormat="MMM D, YYYY"
              disabled={!isSeller}
            />
          </div>
        </div>
      </div>
    )
  }
}


export default EnterMediaInfo
