import React from 'react'
import PropTypes from 'prop-types'
import ButtonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import { Link } from 'react-router-dom'

const partnershipTypes = [
  { type: 'all', label: 'All' },
  { type: 'in-discussion', label: 'In Discussion' },
  { type: 'current', label: 'Current' },
  { type: 'past', label: 'Past' },
]

class FilterPartnerships extends React.Component {
  static propTypes = {
    showFilter: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
  }

  state = {
    showFilter: ['in-discussion', 'current', 'past'].includes(this.props.showFilter)
      ? this.props.showFilter
      : 'all',
  }

  componentDidUpdate(prevProps) {
    const { showFilter } = this.props
    if (prevProps.showFilter !== showFilter) {
      this.onClick(showFilter)
    }
  }

  onClick = value => {
    this.setState({ showFilter: value })
  }

  render() {
    const { showFilter } = this.state
    const { prefix } = this.props

    return (
      <div>
        {partnershipTypes.map(value => (
          <Link to={`${prefix}/${value.type}`}>
            <Button
              onClick={() => {
                this.onClick(value.type)
              }}
              className={`${ButtonTheme.transparentButton} ${ButtonTheme.filter}
              ${showFilter === value.type && ButtonTheme.coralButtonFlat}`}
              theme={ButtonTheme}
              label={value.label}
              style={{ overflow: 'hidden' }}
              flat
            />
          </Link>
        ))}
      </div>
    )
  }
}

export default FilterPartnerships
