import React from 'react'
import PropTypes from 'prop-types'

import { Small } from 'visual-components/util/texts'
import TaskListTheme from 'css/themes/TaskList.css'
import TaskExpandable from './task-expandable'
import TaskChecklist from './task-checklist'

const createTaskComponent = (markComplete, campaignTaskActions) => taskObject => {
  if (!taskObject.tasks.length) {
    return (null)
  }

  return (
    <TaskExpandable
      title={taskObject.header}
      key={taskObject.id}
      hasNestedCollapse={false}
      backgroundColor={taskObject.color}
    >
      <TaskChecklist
        markComplete={markComplete}
        tasks={taskObject.tasks}
        campaignTaskActions={campaignTaskActions}
      />
    </TaskExpandable>
  )
}

class TaskBuilder extends React.Component {
  render() {
    let {
      tasks,
      isCampaignDashboard,
      markComplete,
      isHost,
      brandId,
      campaignTaskActions
    } = this.props

    tasks = (isCampaignDashboard || isHost)
      ? tasks
      : tasks.filter(task => task.invitee_id === brandId)

    let totalTasks = 0
    let completedTasks = 0

    tasks.forEach(taskObj => {
      if (taskObj.tasks.length) {
        totalTasks += taskObj.tasks.length
        taskObj.tasks.forEach(task => {
          if (task.isComplete) {
            completedTasks++
          }
        })
      }
    })

    // func for path arc in donut chart
    const polarToCartesian = (...params) => {
      const [centerX, centerY, radius, angleInDegrees] = params
      const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians)),
      }
    }

    const describeArc = (...params) => {
      const [x, y, radius, startAngle, endAngle] = params
      const start = polarToCartesian(x, y, radius, endAngle)
      const end = polarToCartesian(x, y, radius, startAngle)
      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

      const d = [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      ].join(' ')

      return d
    }

    const tasksRemaining = totalTasks - completedTasks
    const degreesCompleted = (completedTasks / totalTasks) * 360

    const mapTasks = tasks.map(createTaskComponent(markComplete, campaignTaskActions))

    const defaultMsg = 'You don\'t have any partnerships tasks yet. Head to Explore to start creating new partnerships.'

    return (
      <div>
        {
          tasks.length
            ? (
              <div>
                { mapTasks }
              </div>
            )
            : (
              <div className={TaskListTheme.placeHolderMessage}>
                <Small multiline cloudGrey>
                  <i>{ defaultMsg }</i>
                </Small>
              </div>
            )
        }

        {/*task donut chart */}
        {
          !isCampaignDashboard
          && !isHost
          && (
            <div className={TaskListTheme.donutGraphContainer}>
              <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">

                <text x="50%" y="35%" textAnchor="middle" fontFamily="Larsseit-Medium" fontSize="5px">{ completedTasks }</text>
                <text x="50%" y="45%" textAnchor="middle" fontFamily="Larsseit-Light" fontSize="2.5px" fontWeight="lighter">tasks complete</text>
                <text x="50%" y="65%" textAnchor="middle" fontFamily="Larsseit-Medium" fontSize="5px">{ tasksRemaining }</text>
                <text x="50%" y="75%" textAnchor="middle" fontFamily="Larsseit-Light" fontSize="2.5px" fontWeight="lighter">tasks remaining</text>

                <circle
                  className="donut-ring"
                  cx="21"
                  cy="21"
                  r="18"
                  fill="transparent"
                  stroke="#ecf1f3"
                  strokeWidth="5px"
                />

                <path
                  d={describeArc(21, 21, 18, 0, degreesCompleted)}
                  stroke="#14cba8"
                  strokeWidth="5px"
                  fill="transparent"
                />
              </svg>
            </div>
          )
        }
      </div>
    )
  }
}

TaskBuilder.propTypes = {
  tasks: PropTypes.array.isRequired,
}


export default TaskBuilder
