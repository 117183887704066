import _ from 'lodash'

var model = 'pastCampaigns';

var defaultState =  {
  rid: model,
  campaigns: [],
  sort: 'startDate',
  reversed: false,
  loaded: false
}

var PastCampaignsReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_SORT: function () {
      if (action.data.sort === state.sort) {
        return _.extend({}, state, { campaigns: state.campaigns.reverse(), reversed: !state.reversed });
      } else {
        var campaigns = _.sortBy(state.campaigns, action.data.sort);
        if (action.data.sort != 'name') {
          campaigns = campaigns.reverse();
        }
        
        return _.extend({}, state, { sort: action.data.sort, campaigns: campaigns, reversed: false });
      }
    },
    LOAD: function () {
      var campaigns = store.getState().brandCampaigns.pastCampaigns;
      campaigns = _.sortBy(campaigns, state.sort).reverse();
      _.each(campaigns, function (campaign) {
        campaign.totalVisits = parseInt(campaign.visits);
        campaign.totalEntries = parseInt(campaign.entries);
        campaign.totalImpressions = campaign.impressions;

        var entryRate = campaign.totalEntries / campaign.totalVisits;
        campaign.entryRate = isNaN(entryRate) ? 0 : entryRate; 
      });

      return _.extend({}, state, { campaigns: campaigns, loaded: true });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default PastCampaignsReducer;
