import React, { PureComponent as Component } from 'react'
import createTooltip from 'react-toolbox/lib/tooltip'
import PropTypes from 'prop-types'
import ThemelessDiv from './themeless-div'

const Tooltip = createTooltip(ThemelessDiv)

class TooltipWrapper extends Component {
  render() {
    const {
      theme, delay, tooltip, styles,
    } = this.props

    if (tooltip) {
      return (
        <Tooltip
          tooltipDelay={delay || 40}
          {...this.props}
          theme={theme || {}}
          style={{ width: '12px', ...styles }}
        />
      )
    }

    return <ThemelessDiv {...this.props} />
  }
}

TooltipWrapper.propTypes = {
  theme: PropTypes.object,
  styles: PropTypes.object,
  delay: PropTypes.number,
  tooltip: PropTypes.string,
}

TooltipWrapper.defaultProps = {
  theme: null,
  styles: {},
  delay: null,
  tooltip: null,
}

export default TooltipWrapper
