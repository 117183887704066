import React from 'react'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import { H4 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import { MobileMarketingContext } from '../MobileMarketingContext'

function RequestTrafficReminderModal(props) {
  const { setMobileMarketingState } = props
  const { publishPostEntry, showModal, modals } = React.useContext(MobileMarketingContext)
  const confirm = () => {
    publishPostEntry()
    setMobileMarketingState({ showMessageType: 'request' })
    window.scrollTo(0, 300)
    showModal('requestTrafficReminder', false)
  }
  return (
    <SimpleInfoModal
      title="Reminder: Request Traffic from the Host"
      active={modals.requestTrafficReminder}
      close={null}
    >
      <H4 multiline>
        You have not yet requested traffic from the host. Your Post Entry Action is not live until
        the host splits traffic with you.
      </H4>
      <ModalButtonGroup canSave confirm={() => confirm()} confirmText="Request Traffic" hideLine />
    </SimpleInfoModal>
  )
}

export default React.memo(RequestTrafficReminderModal)
