import React from 'react'
import ErrorMsg from 'util/components/templates/form/inputs/error-msg'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'

const getHeadlineColor = props => {
  const { formStyles: { smsOptinHeadlineColor, formTextColor } } = props
  return smsOptinHeadlineColor
    ? smsOptinHeadlineColor.color.color
    : formTextColor.color.color
}

const getTextColor = props => {
  const { formStyles: { smsOptinTextColor, formTextColor } } = props
  return smsOptinTextColor
    ? smsOptinTextColor.color.color
    : formTextColor.color.color
}

const getButtonColor = props => {
  const { formStyles: { smsOptinButtonColor, entryButtonColor } } = props
  return smsOptinButtonColor
    ? smsOptinButtonColor.color.color
    : entryButtonColor.color.color
}

const getBackgroundColor = props => {
  const { formStyles: { smsOptinBackgroundColor, entryButtonText } } = props
  return smsOptinBackgroundColor
    ? smsOptinBackgroundColor.color.color
    : entryButtonText.color.color
}

// const getCheckedColor = props => {
//   const { formStyles: { smsOptinTextColor, entryButtonText } } = props
//   return entryButtonText
//     ? entryButtonText.color.color
//     : smsOptinTextColor.color.color
// }

const styles = {
  checkboxContainer: {
    color: props => getTextColor(props),
    cursor: 'pointer',
    display: 'flex',
    fontSize: 11,
    lineHeight: '18px',
    marginBottom: 12,
    position: 'relative',
    userSelect: 'none',
    '& a': {
      color: props => getButtonColor(props),
    },
    '& input': {
      cursor: 'pointer',
      height: 14,
      opacity: 0,
      position: 'absolute',
      width: 14,
      '&:checked': {
        '& ~ div:first-of-type': {
          backgroundColor: props => getButtonColor(props),
          '&::after': {
            display: 'inline-block',
          },
        },
      },
    },
  },
  checkbox: {
    backgroundColor: 'transparent',
    border: props => `1px solid ${getButtonColor(props)}`,
    height: 14,
    margin: '4px 12px 0px 0px',
    minWidth: 14,
    position: 'relative',
    width: 14,
    '&::after': {
      border: '1px solid',
      borderColor: 'white',
      borderLeft: 'none',
      borderTop: 'none',
      content: "''",
      display: 'none',
      height: 8,
      position: 'absolute',
      top: 1,
      left: 4,
      transform: 'rotate(45deg)',
      width: 5,
    },
  },
  additionalTerms: {
    marginTop: '4px',
    fontSize: 9,
    fontWeight: 'normal',
    lineHeight: '14px',
    '& p': {
      fontSize: 10,
    },
  },
  terms: {
    marginBottom: 22,
    backgroundColor: props => getBackgroundColor(props),
    padding: '20px 20px 20px 20px',
  },
  termsText: {
    fontWeight: '700',
    fontFamily: 'Larsseit',
  },
  headline: {
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '11px',
    color: props => getHeadlineColor(props),
    marginBottom: '12px',
  },
}

class SmsOptIn extends React.Component {
  static propTypes = {
    smsOptinHeadlineText: propTypes.string,
    smsOptinLanguage: propTypes.string,
    additionalTermsDetail: propTypes.string,
    hostBrandName: propTypes.string.isRequired,
    formStyles: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
  }

  static defaultProps = {
    smsOptinHeadlineText: '',
    smsOptinLanguage: '',
    additionalTermsDetail: '*By clicking “Yes” above I authorize {{hostBrandName}} and their representatives to send marketing and informational text messages to the number I provided above using an automatic telephone dialing system or other technology.  I acknowledge that my consent is not required as a condition of any purchase.',
  }

  static contextTypes = {
    baseStyles: propTypes.object,
  }

  renderCheckBox = properties => {
    const { classes: css } = this.props
    const { baseStyles } = this.context

    return (
      (
        <label className={css.checkboxContainer}>
          <input
            type="checkbox"
            name={properties.name}
            checked={properties.preCheck}
            value={properties.value}
            style={{ zIndex: 2 }}
          />
          <div className={cn('sweep', css.checkbox, baseStyles.optInCheckbox)} />
          {properties.label}
        </label>
      )
    )
  }

  render() {
    const {
      smsOptinHeadlineText,
      smsOptinLanguage,
      additionalTermsDetail,
      hostBrandName,
      classes: css,
    } = this.props

    const defaultHeadline = 'Special Offer: Unlock a 25% Discount for MojoMeals Today!'
    const defaultLanguage = 'sign me up for exclusive news and discounts'

    const mergedAdditionalTermsDetail = additionalTermsDetail
      .replace(/{{hostBrandName}}/g, hostBrandName)

    const terms = (
      <div className={css.termsText}>
        {`Yes, ${smsOptinLanguage || defaultLanguage} via mobile marketing texts!*`}
        <div
          className={css.additionalTerms}
          dangerouslySetInnerHTML={{ __html: mergedAdditionalTermsDetail }}
        />
      </div>
    )

    return (
      <div>
        <div id="terms" className={css.terms}>
          <div className={css.headline}>{smsOptinHeadlineText || defaultHeadline}</div>
          <div>
            {
              this.renderCheckBox({
                name: 'sms_opt_in',
                label: terms,
                preCheck: false,
                value: 'true',
              })
            }
            <ErrorMsg />
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(SmsOptIn)
