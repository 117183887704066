import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import FullscreenModal from 'visual-components/util/modals/FullscreenModal'
import Theme from 'css/themes/media-market/EditRequest.css'
import * as DealRequestActions from 'actions/deal-request-actions'
import { SimpleForm } from 'visual-components/util/form'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import { object, shape, func } from 'prop-types'
import Spinner from 'util/components/spinner';
import classNames from 'classnames'
import moment from 'moment'
import BrandDetailDispatcher from 'dispatchers/brand-detail-dispatcher'
import * as DealCollaborationActions from 'actions/deal-collaboration-actions'
import sendDataExport from 'util/sendDataExport'
import MediaInformation from './request-media-information'
import AudienceInformation from './request-audience-information'
import AdditionalInformation from './request-additional-information'

const mapStateToProps = ({
  dealRequest,
  brand,
  currentBrand,
  profile,
}) => ({
  dealRequest,
  brand,
  currentBrand,
  profile,
})

const mapDispatchToProps = dispatch => ({
  dealRequestActions: bindActionCreators(DealRequestActions, dispatch),
  brandActions: BrandDetailDispatcher,
  dealCollaborationActions: bindActionCreators(DealCollaborationActions, dispatch),
})

class EditRequest extends React.Component {
  static propTypes = {
    dealRequest: object.isRequired,
    match: object.isRequired,
    history: object.isRequired,
    dealRequestActions: shape({
      load: func.isRequired,
    }).isRequired,
    brandActions: shape({
      loadBrand: func.isRequired,
    }).isRequired,
    brand: object.isRequired,
    dealCollaborationActions: shape({
      sendMessage: func.isRequired,
    }).isRequired,
    currentBrand: object.isRequired,
    profile: object.isRequired,
  }

  status = 'loading'

  sellerNote = null

  state = {
    possibleStartDate: null,
    possibleEndDate: null,
    mediaInterests: [],
    dealTerms: [],
    isDateFlexible: [],
    selectedSellerBrand: {}, // if the user decides to change it
  }

  componentWillMount() {
    const {
      dealRequestActions: {
        load: loadRequest,
        clear: clearRequest,
      },
      brandActions: {
        loadBrand,
      },
      match: { params: { brandId, requestId } },
    } = this.props

    clearRequest()

    if (requestId) {
      this.status = 'edit'
      loadRequest(requestId)
    }
    if (brandId) {
      this.status = 'new'
      loadBrand(brandId)
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate = prevProps => {
    const {
      dealRequest,
      dealRequest: {
        deal_id,
        saved,
        seller_brand_id,
      },
      history,
      dealCollaborationActions: {
        sendMessage,
      },
      currentBrand: {
        id: brand_id,
      },
      profile,
      brand,
    } = this.props

    if (prevProps.dealRequest.seller_brand_id !== seller_brand_id) {
      this.setState({
        possibleStartDate: dealRequest.start_date ? moment(dealRequest.start_date) : null,
        possibleEndDate: dealRequest.end_date ? moment(dealRequest.end_date) : null,
        isDateFlexible: dealRequest.is_date_flexible ? ['yes'] : [],
        dealTerms: dealRequest.terms ? dealRequest.terms.map(term => term.tag) : [],
        mediaInterests: dealRequest.interests
          ? dealRequest.interests.map(interest => interest.tag)
          : [],
      })
    }

    //saved successfully. redirect to chat
    if (prevProps.dealRequest.saved !== saved && saved) {
      if (this.sellerNote) {
        sendMessage({
          deal_id,
          content: this.sellerNote,
          brand_id,
          profile,
        })
      }
      history.push(`/partnerships/deal/${deal_id}/collaborate`)
    }

    // when the brand loads, set as selected brand.
    if (brand.loaded !== prevProps.brand.loaded && brand.loaded) {
      this.setState({
        selectedSellerBrand: brand,
      })
    }
  }

  close = () => {
    const { history } = this.props
    history.goBack()
  }

  onSubmit = formData => {
    const {
      mediaInterests,
      dealTerms,
      isDateFlexible,
      possibleStartDate,
      possibleEndDate,
      selectedSellerBrand,
    } = this.state
    const {
      currentBrand,
      dealRequestActions: { save },
      match: { params: { brandId } },
      dealRequest: {
        seller_brand_id: requestSellerBrandId,
      },
    } = this.props

    this.sellerNote = formData.seller_note

    save({
      ...formData,
      conversion_rate: formData.conversion_rate ? String(formData.conversion_rate).replace(/[^0-9\.]+/g, '') : null,
      conversion_value: formData.conversion_value ? String(formData.conversion_value).replace(/[^0-9\.]+/g, '') : null,
      budget_min: formData.budget_range.split('-')[0],
      budget_max: formData.budget_range.split('-')[1],
      start_date: possibleStartDate,
      end_date: possibleEndDate,
      seller_brand_id: selectedSellerBrand.id || brandId || requestSellerBrandId,
      interests: mediaInterests.filter(interest => interest !== 'Other').map(interest => ({ tag: interest })),
      terms: dealTerms.map(term => ({ tag: term })),
      is_date_flexible: isDateFlexible.length > 0,
    })
  }

  onCheckboxToggle = (isChecked, name, value) => {
    const { [name]: checkboxValues } = this.state
    if (isChecked) {
      checkboxValues.push(value)
    } else if (checkboxValues.includes(value)) {
      checkboxValues.splice(checkboxValues.indexOf(value), 1)
    }

    this.setState({
      [name]: checkboxValues,
    })
  }

  onUpdatePossibleDateRange = dates => {
    this.setState({
      possibleStartDate: dates.startDate,
      possibleEndDate: dates.endDate,
    })
  }

  onSellerBrandChange = selectedSellerBrand => {
    this.setState({ selectedSellerBrand })
  }

  render() {
    const {
      dealRequest,
      dealRequest: {
        loading: requestLoading,
        sellerBrand,
      },
      brand,
      brand: {
        loaded: brandLoaded,
      },
    } = this.props

    const {
      possibleStartDate,
      possibleEndDate,
      mediaInterests,
      dealTerms,
      isDateFlexible,
      selectedSellerBrand,
    } = this.state

    if (
      (this.status === 'new' && !brandLoaded)
      || (this.status === 'edit' && requestLoading)
    ) {
      return <Spinner />
    }

    return (
      <FullscreenModal
        title={`Media Proposal ${selectedSellerBrand.accountname
          ? `for ${selectedSellerBrand.accountname}`
          : ''}
        `}
        close={this.close}
      >
        <SimpleForm
          model={dealRequest}
          handleSubmit={this.onSubmit}
        >
          <div className={classNames(Theme.body, Theme.spreadEven)}>
            <div className={Theme.column}>
              <MediaInformation
                possibleStartDate={possibleStartDate}
                possibleEndDate={possibleEndDate}
                onUpdatePossibleDateRange={this.onUpdatePossibleDateRange}
                onCheckboxToggle={this.onCheckboxToggle}
                mediaInterests={mediaInterests}
                dealTerms={dealTerms}
                isDateFlexible={isDateFlexible}
                selectedBrand={selectedSellerBrand}
                onSellerBrandChange={this.onSellerBrandChange}
              />
            </div>
            <div className={Theme.column}>
              <AudienceInformation />
              <AdditionalInformation />
            </div>
          </div>
          <div style={{ marginBottom: 75 }}>
            <ModalButtonGroupWithContext
              cancel={this.close}
              cancelText="Cancel"
              confirmText="Send"
              canSave
              hideLine
            />
          </div>
        </SimpleForm>
      </FullscreenModal>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRequest))
