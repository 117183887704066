import React from 'react';

import { Card } from 'react-toolbox/lib/card';
import QuestStyles from 'css/themes/QuestStyles.css';
import QuestItem from './quest-item'
class QuestCard extends React.Component {
  render() {
    const {
      quests
    } = this.props;

    return(
      <Card className={ QuestStyles.card }>
        {quests.map((quest, index) => {
          return <QuestItem key={index} {...quest} />
        })}
      </Card>
    )
  };
}

export default QuestCard;
