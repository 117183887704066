import React from 'react';
import COLOURS from 'util/colours';
import Radium from 'radium';
import _ from 'lodash'

var contentMap = {
  linkShare: {
    faIcon: 'fa-bullhorn',
    iconPadding: '1px',
    backgroundColor: COLOURS.instagram
  },
  fbLike: {
    faIcon: 'fa-facebook',
    iconPadding: '0',
    backgroundColor: COLOURS.fbBlue,
  },
  fbShare: {
    faIcon: 'fa-facebook',
    iconPadding: '0',
    backgroundColor: COLOURS.fbBlue,
  },
  twitterShare: {
    faIcon: 'fa-twitter',
    backgroundColor: COLOURS.twitterBlue,
    iconPadding: '1px',
  },
  twitterFollow: {
    faIcon: 'fa-twitter',
    iconPadding: '1px',
    backgroundColor: COLOURS.twitterBlue,
  },
  instagramFollow: {
    faIcon: 'fa-instagram',
    iconPadding: '1px',
    backgroundColor: COLOURS.instagram,
  },
  instagramVisit: {
    faIcon: 'fa-instagram',
    iconPadding: '1px',
    backgroundColor: COLOURS.instagram,
  },
  youtubeWatch: {
    faIcon: 'fa-youtube-play',
    iconPadding: '0',
    backgroundColor: COLOURS.youtubeRed,
  },
  youtubeSubscribe: {
    faIcon: 'fa-youtube-play',
    iconPadding: '0',
    backgroundColor: COLOURS.youtubeRed,
  },
  pinterestFollow: {
    faIcon: 'fa-pinterest-p',
    iconPadding: '1px',
    backgroundColor: COLOURS.pinterestRed,
    label: 'Follow Us On Pinterest'
  },
  pinterestPin: {
    faIcon: 'fa-pinterest-p',
    iconPadding: '1px',
    backgroundColor: COLOURS.pinterestRed,
  },
  snapchatFollow: {
    faIcon: 'fa-snapchat-ghost',
    iconPadding: '1px',
    backgroundColor: COLOURS.snapchatYellow,
  },
  appDownload: {
    faIcon: 'fa-download',
    iconPadding: '1px',
    backgroundColor: COLOURS.instagram,
  }
}

class BonusItem extends React.Component{
  render () {

    var bonus = _.merge({}, { bonusCount: '2'}, this.props.bonus);
    var props = {};

    _.each(bonus, function (value, key) {
      props['data-' + key.toLowerCase()] = value;
    });

    var bonusKeyHtml = this.props.bonusKey.toLowerCase();

    props['data-bonuskey'] = bonusKeyHtml;

    let faClass = `fa ${ contentMap[this.props.bonusKey].faIcon }`;

    let isEven = this.props.index % 2 === 0;

    var outerDivClasses;
    var outerDivStyles;

    if ( this.props.largeCTA ) {
      outerDivClasses = 'col-xs-12 col-sm-12 col-md-6 col-lg-6';
      outerDivStyles = {
        borderRightStyle: 'dotted',
        borderRightWidth: '1px',
        borderRightColor: 'transparent',
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: 'transparent',
        '@media only screen and (min-width: 992px)': {
          borderRightColor: isEven ? COLOURS.darkGreen.point1 : 'transparent'
        }
      }
    } else {
      outerDivClasses = 'col-xs-12 col-sm-12 col-md-12 col-lg-12';
      outerDivStyles = {}
    }

    var ineligableTypes = ['fbLike', 'fbShare', 'instagramFollow'];
    if (bonus.hidden) {
      return (
        <div>
        </div>
      )
    }

    var bonusCount = parseInt(bonus.bonusCount) && ineligableTypes.indexOf(this.props.bonusKey) === -1 ? `+ ${bonus.bonusCount}` : '';
    if (this.props.bonusKey === 'linkShare') {
      bonusCount += ' each';
    }
    return (
      <div className={ outerDivClasses } style={ outerDivStyles }>
        <div {...props} className={ `text-center bonus-item ${ bonusKeyHtml }` }>
          <div className="row" style={{
            marginLeft: '-15px',
            marginRight: '-15px',
            borderTopStyle: 'dotted',
            borderTopColor: COLOURS.darkGreen.point1,
            borderTopWidth: '1px',
            borderBottom: 'none',
          }}>
            <div className="col-xs-10 col-sm-11 col-md-11 col-lg-11 text-left"
              style={{
                paddingTop: '15px',
                paddingRight: '0',
                paddingBottom: '15px',
                paddingLeft: '0'
              }}>
              <div className="row">
                <div className="col-xs-2"
                  style={{
                    paddingLeft: '9px',
                    '@media only screen and (min-width: 768px)': {
                      paddingLeft: '5%'
                    }
                }}>
                  <div style={{
                    backgroundColor: contentMap[this.props.bonusKey].backgroundColor,
                    paddingTop: '3px',
                    paddingRight: '0',
                    paddingBottom: '2px',
                    paddingLeft: contentMap[this.props.bonusKey].iconPadding,
                    borderRadius: '13px',
                    width: '25px',
                    display: 'inline-block',
                    marginLeft: '15px',
                    marginRight: '15px',
                    textAlign: 'center'
                  }}>
                    <i
                      className={ faClass }
                      aria-hidden="true"
                      style={{
                        color: COLOURS.white,
                      }} />
                  </div>
                </div>
                <div className="col-xs-10"
                  style={{
                    paddingLeft: '5px'
                }}>
                  <small style={{
                    margin: '0',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontFamily: 'Larsseit-Thin',
                    color: COLOURS.textGrey,
                    display: 'inline'
                  }}>
                    { bonus.message }
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 pull-right"
              style={{
                paddingLeft: '0',
                paddingRight: '0',
                display: 'table'
            }}>
              <div style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                height: '50px',
                textAlign: 'right',
                paddingRight: '15px'
              }}>
                <small className="bonus-count"
                  style={{
                    marginBottom: '0',
                    marginRight: '15px',
                    display: 'block',
                    fontSize: '14px',
                    lineHeight: '20px',
                }}>
                  { bonusCount }
                </small>
                <small className="check"
                  style={{
                    fontFamily: 'Larsseit-Thin',
                    color: COLOURS.textGrey,
                    display: 'inline',
                    fontSize: '14px',
                    lineHeight: '20px',
                }}>
                  <i
                    className="fa fa-check"
                    aria-hidden="true"
                    style={{
                      color: COLOURS.medGreen.full
                    }} />
                </small>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
};

export default Radium(BonusItem);
