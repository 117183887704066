import React, { Component } from 'react'
import { Small } from 'visual-components/util/texts'
import DocumentSenderTheme from 'css/themes/media-market/assetVisuals/DocumentSender.css'
import { object, func } from 'prop-types'


class DocumentSender extends Component {
  static propTypes = {
    asset: object.isRequired,
    downloadAssetFunc: func.isRequired,
  }

  render() {
    const {
      asset,
      downloadAssetFunc,
    } = this.props

    const assetLogoMap = {
      pdf: 'images/icons/sender-asset-pdf-icon.png', // receiver and sender have diff icons
    }

    return (
      <div>
        <img
          src="images/icons/blue-asset-download-icon.png"
          className={DocumentSenderTheme.downloadIcon}
          onClick={downloadAssetFunc}
        />

        <div className={DocumentSenderTheme.fileWrapperContainer}>
          <div className={DocumentSenderTheme.iconBackground}>
            <div className={DocumentSenderTheme.fileWrapperIconContainer}>
              <img src={assetLogoMap[asset.type] || 'images/icons/sender-asset-doc-icon.png'} />
            </div>
          </div>
          <div className={DocumentSenderTheme.verticalLine} />

          <div className={DocumentSenderTheme.background}>
            <div className={DocumentSenderTheme.fileWrapperItemInfo}>
              <Small><em>{asset.name.length > 28 ? `${asset.name.slice(0, 28)}...` : asset.name}</em></Small>

              <div className={DocumentSenderTheme.fileType}>
                <Small>{`.${asset.type}`}</Small>
              </div>
            </div>
          </div>
        </div>


      </div>
    )
  }
}

export default DocumentSender
