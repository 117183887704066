import React from 'react';
import BarGraph from 'visual-components/util/graphs/bar-graph';
import PercentDisplay from '../util/percent-display';
import BarGraphHeader from '../util/bar-graph-header';

class EntryRateGraph extends React.Component {
  render() {
    let {
      entriesDriven,
      visitsDriven,
    } = this.props;

    const percentage = entriesDriven && visitsDriven
      ? Math.round((entriesDriven / visitsDriven) * 100)
      : 0

    return(
      <div>
        <BarGraphHeader
          live={ false }
          text={ 'ENTRY RATE' }
        />
        <div style={{ display: 'inline-block' }}>
          <BarGraph
            live={ false }
            progress={ percentage }
          />
        </div>
        <PercentDisplay
          percentage={ percentage }
        />
      </div>
    )
  }
}

export default EntryRateGraph;
