import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignsListTheme from 'css/themes/calendar/campaignsList.css';
import { Small } from 'visual-components/util/texts';

class ListCampaigns extends Component {
  render() {
    const {
      campaigns,
      toggleShowCampaign
    } = this.props;

    const mapCampaigns = campaigns.map((campaignObj, idx) => {
      return(
        <div
          key={idx}
          onClick={() => {
            toggleShowCampaign(campaignObj.id)
          }}
          className={ `${CampaignsListTheme.individualCampaign} ${campaignObj.hideTasks ? CampaignsListTheme.hiddenItem : ''}` }>
          <div style={{ backgroundColor: campaignObj.color }}
               className={ CampaignsListTheme.colorIcon }
          />

          <div className={ CampaignsListTheme.individualCampaignName }>
            <Small>
            { campaignObj.header.length > 33 ?
              `${campaignObj.header.slice(0,33)}...`
              : campaignObj.header
            }
            </Small>
          </div>
        </div>
      );
    });

    return(
      <div className={ CampaignsListTheme.campaignsContainer }>
        { mapCampaigns }
      </div>
    );
  }
}

ListCampaigns.propTypes = {
  campaigns: PropTypes.array.isRequired
};


export default ListCampaigns;
