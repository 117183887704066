import React from 'react';
import * as ReactRedux from 'react-redux';

import Dialog from 'react-toolbox/lib/dialog';

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

import DialogTheme from 'css/themes/dialogs/formModal.css'

const themes = {
  Dialog: DialogTheme,
}

var mapState = function (state) {
  return {
    interestedInLearnMoreModal: state.interestedInLearnMoreModal
  };
}

class InterestedInLearnMoreModal extends React.Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'interestedInLearnMoreModal',
      data: {
        expand: false
      }
    })
  }

  render() {
    var {
      interestedInLearnMoreModal
    } = this.props;

    var optionData = [{
      title: 'Sweepstakes: ',
      description: 'Collaborate on campaigns to drive email list and social growth',
    }, {
      title: 'Dedicated Email Buys: ',
      description: 'Buy media from other brands on the platform',
    }, {
      title: 'Dedicated Email Sells: ',
      description: 'Sell media to other brands on the platform',
    }, {
      title: 'Prizing Partner: ',
      description: 'Provide prizing for sweepstakes in exchange for brand exposure and/or entries',
    }, {
      title: 'Content Swaps: ',
      description: 'Cross-promote content to acquire new readers',
    }, {
      title: 'Box-Insert Swaps: ',
      description: 'Exchange physical inserts in product shipments to grow audience',
    }];

    var options = optionData.map(function(data, idx) {
      return (
        <li key={ idx }
          style={{
            fontSize: '14px',
            lineHeight: '22px',
            color: COLOURS.ink,
            marginBottom: idx == optionData.length - 1 ? '0' : '8px'
        }}>
          <span style={{ fontFamily: 'Larsseit-Medium' }}>
            {data.title}
          </span>
          <span style={{ fontFamily: 'Larsseit-Light' }}>
            {data.description}
          </span>
        </li>
      )
    })

    return (
      <Dialog
        theme={ themes.Dialog }
        active={ !!interestedInLearnMoreModal.expand }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title={ interestedInLearnMoreModal.title }
      >
        <p style={{
          fontFamily: 'Larsseit-Light',
          fontSize: '16px',
          lineHeight: '24px',
          color: COLOURS.ink,
          marginBottom: '16px'
        }}>
          { interestedInLearnMoreModal.message ? interestedInLearnMoreModal.message[0] : '' }
        </p>

        <ul style={{
          width: '528px',
          paddingLeft: '26px'
        }}>
          { options }
        </ul>

        <p style={{
          fontFamily: 'Larsseit-Light',
          fontSize: '16px',
          lineHeight: '24px',
          color: COLOURS.ink,
          marginBottom: '16px'
        }}>
          { interestedInLearnMoreModal.message ? interestedInLearnMoreModal.message[1] : '' }
        </p>

        <ModalButtonGroup
          canSave={ true }
          confirm={ this.closeModal }
          confirmText={ 'Got it!' }
          hideLine={ true }
        />
      </Dialog>
    )
  }
};

export default ReactRedux.connect(mapState)(InterestedInLearnMoreModal);
