import keyMirror from 'keymirror';

export default keyMirror({
  CLOSE_MODAL: null,
  START_APPLICATION: null,
  SUBMIT_STEP_ONE: null,
  SUBMIT_STEP_TWO: null,
  SUBMIT_APPLICATION_REQUEST: null,
  SUBMIT_APPLICATION_SUCCESS: null,
  SUBMIT_APPLICATION_FAILURE: null,
  UPDATE_DATA: null,
  TOGGLE_DATE_PICKER: null,
  CLOSE_APPLICATION_SENT_CONFIRMATION_MODAL: null
});
