import React, { Component } from 'react'
import { string, bool, any } from 'prop-types'

import { Checkbox as CheckboxRT } from 'react-toolbox/lib'
import CheckboxTheme from 'css/themes/Checkbox.css'
import { H6 } from 'visual-components/util/texts'
import { InputWrapper } from './wrappers'

const checkboxRequired = value => {
  if (value === undefined || value === false) throw 'Required field'
}

export default class Checkbox extends Component {
  static propTypes = {
    name: string.isRequired,
    label: any,
    boldLabel: bool,
    required: bool,
    disabled: bool,
    forceValue: bool,
  }

  static defaultProps = {
    required: false,
    label: '',
    boldLabel: false,
    disabled: false,
    forceValue: undefined,
  }

  render() {
    const {
      name, required, label, boldLabel, disabled, forceValue,
    } = this.props
    return (
      <InputWrapper
        name={name}
        validations={required ? [checkboxRequired] : undefined}
        render={({
          value, error, update, formDisabled, onBlur,
        }) => {
          const labelAndError = (
            <React.Fragment>
              {boldLabel ? (
                <H6 multiline>{label}</H6>
              ) : label}
              {error && (
                <span className={CheckboxTheme.error}>
                  &nbsp;
                  {error}
                </span>
              )}
            </React.Fragment>
          )
          return (
            <CheckboxRT
              label={labelAndError}
              checked={forceValue || value || false}
              className={CheckboxTheme.form}
              theme={CheckboxTheme}
              onChange={update}
              onBlur={() => setTimeout(onBlur)}
              disabled={disabled || formDisabled}
            />
          )
        }}
      />
    )
  }
}
