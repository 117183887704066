import React from 'react'
import injectCSS from 'react-jss'
import PropTypes from 'prop-types'

const styles = {
  wrapper: {
    height: 88,
    width: 88,
  },
}

function SplitTrafficDonut(props) {
  const { classes: css, data } = props
  const total = data.reduce((prev, { value }) => prev + value, 0)
  const mappedData = []

  const GRAPH_OFFSET = 75

  data.forEach((next, i, arr) => {
    const valueToUse = Math.round((next.value / total) * 100)
    const precedingTotalSegment = arr
      .slice(i)
      .reduce((sum, { value }) => ((total - Math.round((value / total) * 100)) + sum), 0)
    const offset = i
      ? total - precedingTotalSegment + GRAPH_OFFSET
      : GRAPH_OFFSET

    const values = `${valueToUse} ${100 - valueToUse}`

    mappedData.push({
      ...next,
      values,
      offset,
    })
  })

  return (
    <div className={css.wrapper}>
      {/* thanks to https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72 for the ideas/help */}
      <svg width="100%" height="100%" viewBox="0 0 44 44">
        {
          mappedData.map(({ values, color, offset }) => (
            <circle
              cx="22"
              cy="22"
              r="15.915494309189533"
              fill="transparent"
              stroke={color}
              strokeWidth="8"
              strokeDasharray={values}
              strokeDashoffset={offset}
            />
          ))
        }
      </svg>
    </div>
  )
}

SplitTrafficDonut.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default React.memo(injectCSS(styles)(SplitTrafficDonut))
