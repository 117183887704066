import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { object, bool } from 'prop-types'
import FontIcon from 'react-toolbox/lib/font_icon'
import AssetsTheme from 'css/themes/media-market/Assets.css'
import HoverMenu from 'visual-components/util/HoverMenu'

const stateToProps = ({ currentBrand }) => ({ currentBrand })

class ImageWrapper extends PureComponent {
  static propTypes = {
    item: object.isRequired,
    location: object.isRequired,
    isUnread: bool,
    history: object.isRequired,
    currentBrand: object.isRequired,
  }

  static defaultProps = {
    isUnread: false,
  }

  state = {
    showHoverMenu: false,
  }

  showMenu = () => this.setState({ showHoverMenu: true })

  hideMenu = () => this.setState({ showHoverMenu: false })

  onPreview = () => {
    const { location, history, item } = this.props

    history.push(`${location.pathname}/${item.id}`)
  }

  onDownload = () => {
    const { item, currentBrand: { id: currentBrandId } } = this.props

    const link = document.createElement('a')

    const downloadSrc = `/deals/attachment/${item.deal_id}/${currentBrandId}/${item.id}/download/${item.name}.${item.type}`

    link.download = `${item.name}.${item.type}`
    link.href = downloadSrc
    link.click()
  }

  renderUnread() {
    const { isUnread } = this.props

    if (!isUnread) return null

    return (
      <div className={AssetsTheme.unreadImageBadge}>
        <FontIcon value="lens" />
      </div>
    )
  }

  render() {
    const { item } = this.props
    const { showHoverMenu } = this.state
    const imgSrc = item.s3_url.startsWith('//') ? `https:${item.s3_url}` : item.s3_url

    return (
      <div
        className={AssetsTheme.image}
        style={{ backgroundImage: `url(${imgSrc})` }}
        onMouseEnter={this.showMenu}
        onMouseLeave={this.hideMenu}
      >
        <HoverMenu
          style={{ width: '72px', right: '4px', top: '4px' }}
          show={showHoverMenu}
          transparent
          onDownload={this.onDownload}
          onPreview={this.onPreview}
        />
        { this.renderUnread() }
      </div>
    )
  }
}

export default withRouter(connect(stateToProps)(ImageWrapper))
