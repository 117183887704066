import React from 'react'
import { string } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import LegalItem from 'visual-components/legalDocuments/legal-item'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { H3, H6 } from 'visual-components/util/texts'

import ButtonTheme from 'css/themes/Buttons.css'

import CampaignDispatcher from 'dispatchers/campaign-dispatcher'

const mapStateToProps = ({ currentCampaign, currentBrand }) =>
  ({ currentCampaign, currentBrand })

class LegalDocuments extends React.Component {
  static contextTypes = {
    impersonating: string,
  }

  downloadAll = () => {
    const { currentCampaign: { id }, currentBrand: { id: brandId } } = this.props
    const { impersonating } = this.context
    window.open(`/campaigns/download-legal-documents/${brandId}/${id}${impersonating ? `?impersonating=${impersonating}` : ''}`)
  }

  createRules = () => {
    const { currentCampaign: { id }, history } = this.props
    history.push(`/builder/${id}/terms/dashboard`)
  }

  render() {
    const {
      currentCampaign: { id, hostBrandId, invites,
        campaignTerms: { termsStatus, useTemplate, createYourOwn }
      },
      currentBrand
    } = this.props

    const isHost = hostBrandId === currentBrand.id

    let items = []

    if (termsStatus === 'published') {
      items.push({
        type: 'Campaign Terms & Conditions',
        name: useTemplate ? 'DojoMojo Template' : 'Create Your Own',
        url: `/landing/terms/${id}`
      })
    }

    invites.forEach(invite => {
      if (invite.agreement && invite.agreement.status === 'completed' && (isHost || invite.invitee_id === currentBrand.id)) {
        items.push({
          type: `Partnership Agreement - ${invite.invitee.accountname}`,
          name: invite.agreement.type === 'template' ? 'DojoMojo Template' : invite.agreement.custom_file_name,
          url: invite.agreement.type === 'template' ? `/partnership-agreement/pdf/${currentBrand.id}/${invite.id}`
            : `/partnership-agreement/download/${currentBrand.id}/${invite.id}/${encodeURIComponent(invite.agreement.custom_file_name)}`
        })
      }
    })

    const legalItems = items.map((item, i) => {
      return (
        <LegalItem type={ item.type }
          name={ item.name }
          url={ item.url }
          key={ i }
        />
      )
    })

    return (
      <div style={{
        marginBottom: '24px'
      }}>
        <Card style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)' }}>
          <Row style={{
            padding: '13px 20px'
          }}>
            <Col style={{
              width: '139px',
              marginTop: '2px',
              marginRight: '712px'
            }}>
              <H3 coral>
                <small>
                  Legal Documents
                </small>
              </H3>
            </Col>
            <Col style={{
              width: '262px'
            }}>
              <div style={{
                float: 'right'
              }}>
                {
                  isHost ?
                  <div style={{
                    display: 'inline-block',
                    marginRight: '12px'
                  }}>
                    <Button onClick={ this.createRules }
                      label={ 'Create Rules' }
                      className={ `${ButtonTheme.greenButton} ${ButtonTheme.shortButton}` }
                      theme={ ButtonTheme }
                      primary
                      raised
                    />
                  </div>
                  : null
                }
                <Button onClick={ this.downloadAll }
                  label={ 'Download All' }
                  className={ `${ButtonTheme.whiteButton} ${ButtonTheme.shortButton}` }
                  theme={ ButtonTheme }
                  disabled={ !legalItems.length }
                  primary
                  raised
                />
              </div>
            </Col>
          </Row>
          { legalItems }
        </Card>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(LegalDocuments))
