import React from 'react';
import DojoModal from 'util/components/dojomodal';
import NumberField from 'util/components/number-field';
import COLOURS from 'util/colours';
import PrimaryButton from 'util/components/buttons/primary-button';
import SecondaryButton from 'util/components/buttons/secondary-button';
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import { H2, P } from 'visual-components/util/texts';

class PartnerDetail extends React.Component {
  closeModal() {
    store.dispatch({
      type: 'CLEAR',
      model: 'partnerDetails'
    })
  }

  save = () => {
    var partnershipId = this.props.partnerDetails.id;
    CampaignDispatcher.updatePartnershipRequest({
      id: partnershipId,
      minimumDelivery: this.props.partnerDetails.minimumDelivery
    })
    this.closeModal();
  }

  render () {
    var partnershipId = this.props.partnerDetails.id;

    var partnershipName = this.props.partnerDetails && this.props.partnerDetails.invitee ? this.props.partnerDetails.invitee.accountname : null;

    return (
      <DojoModal
        gaName="partnerDetail"
        isOpen={ this.props.partnerDetails.id ? true : false }
        hideModal={ this.closeModal }
        title='Set Minimum Entries'
      >
        <div className="row"
          style={{
            marginTop: '20px'
        }}>
          <div className="col-md-12">
            <P multiline>
              Specify the minimum number of entries this partner must deliver from they will have access to new emails generated by the giveaway. We’ll send them a note to let them know, but you’ll want to communicate this and update any partnership agreements accordingly.
              <a href="https://help.dojomojo.com/hc/en-us/articles/115008842308-Ensure-an-equitable-partnership-with-Minimum-Entry-Requirements" target="_blank">
                {` Learn more here`}
              </a>
              .
            </P>
          </div>
        </div>

        <div className="row"
          style={{
            marginTop: '30px'
        }}>
          <div className="col-md-12">
            <P>
              { `Minimum Entries Required for: ` }
              <em>
                { `${partnershipName}` }
              </em>
            </P>
          </div>
        </div>

        <div className="row"
          style={{
            marginTop: '0px'
        }}>
          <div className="col-md-12">
            <NumberField
              dataModel={ this.props.partnerDetails }
              formModel={ this.props.partnerDetailsForm }
              attrName="minimumDelivery"
              label=""
            />
          </div>
        </div>
        <div className="row text-center"
          style={{
            marginTop: '0px'
        }}>
          <div className="col-md-12">
            <SecondaryButton onClick={this.closeModal}
              styles={[{
                height: '40px',
                width: '135px',
                minWidth: '135px',
                marginRight: '30px',
                fontSize: '14px'
            }]}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={ this.save }
              isDisabled={ !this.props.partnerDetails.minimumDelivery }
                styles={[{
                  height: '40px',
                  width: '140px',
                  minWidth: '140px',
                  fontSize: '14px'
              }]}>
              Set And Notify
            </PrimaryButton>
          </div>
        </div>
      </DojoModal>
    )
  }
}

export default PartnerDetail;
