import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { H3 } from 'visual-components/util/texts'
import { messagingRelativeDate } from 'util/relativeDate'
import BasicsCard from 'visual-components/quickview/basics-card'
import StatsCard from 'visual-components/quickview/stats-card'
import RecentAnnouncementsCard from 'visual-components/quickview/recent-announcements-card'
import SmsMarketingCard from 'visual-components/quickview/sms-marketing'
import PartnerPerformanceCard from 'visual-components/quickview/partner-performance-card'
import PartnersGrid from 'visual-components/quickview/partners-grid'
import WinnerInfoCard from 'visual-components/quickview/winner-info-card'
import MinEntryRequirement from 'visual-components/quickview/minimum-entry-requirement'
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher'
import COLOURS from 'util/colours'
import DataIntegrationSuggestion from 'components/dialogs/data-integration-suggestion'
import { openPlatformInviteModal } from 'actions/referral-actions'

const mapStateToProps = ({
  currentCampaign,
  campaignTasks,
  currentBrand,
}) => ({
  currentCampaign,
  campaignTasks,
  currentBrand,
})

const hasEnded = endDate => {
  if (!endDate) {
    return false
  }

  return new Date() >= new Date(endDate)
}

const hasStarted = startDate => new Date(startDate) <= new Date()

class Quickview extends React.Component {
  state = {
    showDataIntegrationModel: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (Math.random() <= 0.3) {
      this.setState({ showDataIntegrationModel: true })
    }
  }

  message = ({ id, accountname }) => {
    PricingDispatcher.check('messaging.message.create', () => {
      store.dispatch({
        model: 'conversations',
        type: 'POPOUT_MESSAGE_BRAND',
        data: {
          brandId: id,
          brandName: accountname,
        },
      })
    })
  }

  render() {
    const {
      currentBrand,
      currentCampaign,
      campaignTasks: {
        campaignTasks,
        acceptedInvites,
      },
      match: {
        params: { campaignId },
      },
      openPlatformInviteModal,
    } = this.props

    const {
      showDataIntegrationModel,
    } = this.state

    const {
      hostBrandId,
      description,
      startDate,
      endDate,
      prizingDescription,
      prizingValue,
      announcements,
      winner,
      invites,
      currentBrandId,
      hostBrand,
      smsOverview,
    } = currentCampaign

    const stats = currentCampaign.stats || {}

    const isPartner = currentBrandId !== hostBrandId
    const partnerInvite = invites.filter(invite => {
      if (invite.invitee_id === currentBrandId) {
        return invite
      }

      return null
    })

    // Badge for when the campaign is live or actually completed
    const badge = hasEnded(endDate)
      ? { text: 'Completed', colour: COLOURS.blues.two }
      : hasStarted(startDate)
        ? { text: 'Live', colour: COLOURS.coral }
        : null

    const minEntries = partnerInvite.length && partnerInvite[0].minimumDelivery

    // Campaigns before entries_preWebula was added to Elastic Search will not exist,
    // therefore fall back with entriesDriven
    const entries = stats && stats.entriesPreWebula ? stats.entriesPreWebula : stats.entriesDriven

    const winnerInfo = winner
      ? {
        name: winner.fullname ? winner.fullname : `${winner.firstName || ''} ${winner.lastName || ''}`,
        email: winner.email,
        zipcode: winner.zipcode,
        sourceBrand: winner.fromBrand,
      }
      : null

    // All fields required
    const statsCardData = {
      totalEntries: stats.totalEntries || 0,
      visits: stats.totalVisits || 0,
      estimatedEntries: invites
        .filter(invite => invite.status === 'accepted')
        .reduce((acc, current) => {
          const { invitee } = current

          return invitee.averageSignUpsPerCampaign
            ? acc + invitee.averageSignUpsPerCampaign
            : acc + parseInt(invitee.dedicatedListSize * 0.01, 10)
        }, 0),
      ntf: stats.ntf || 0,
      entriesDriven: stats.entriesDriven || 0,
      daysCompleted: new Date() < startDate
        ? 0
        : new Date() > endDate
          ? moment(endDate).diff(moment(startDate), 'days')
          : moment().diff(moment(startDate), 'days'),
      totalDays: moment(endDate).diff(moment(startDate), 'days'),
      tasksCompleted: campaignTasks.filter(task => !!task.isComplete).length,
      totalTasks: campaignTasks.length,
      campaignId,
    }


    // All fields required
    const announcementCardData = {
      announcements: announcements.slice(0, 3).map(announcement => (
        {
          title: announcement.type,
          description: announcement.message,
          timestamp: messagingRelativeDate(announcement.date),
        }
      )),
      campaignId,
      shrink: !!winner && !isPartner, //THIS SHOULD BE TRUE WHEN THERES A WINNER SELECTED
    }

    // All fields required
    const partnerPerformanceCardData = {
      partners: acceptedInvites.map(invite => {
        const percentage = invite.total
          ? invite.completed / invite.total
          : 0

        return {
          name: invite.invitee.accountname,
          logo: invite.invitee.logo,
          host: hostBrandId === invite.invitee_id,
          taskCompletionPct: percentage,
        }
      }),
    }

    const partnerGridData = {
      partners: invites.filter(invite => invite.status === 'accepted')
        .map(invite => {
          const {
            degree,
            invitee: {
              accountname,
              id,
              dedicatedListSize,
              averageSignUpsPerCampaign,
              logo,
              social: {
                socialMediaImpressions,
                facebookCoverPhoto,
                coverPhotoOffsetY,
              },
            },
          } = invite
          return {
            id,
            accountname,
            logo,
            dedicatedListSize,
            averageSignUpsPerCampaign,
            socialMediaImpressions,
            facebookCoverPhoto,
            coverPhotoOffsetY,
            degreeOfRemoval: degree,
          }
        }),
      currentBrandId: currentBrand.id,
      isPartner,
    }

    const showSmsCard = !currentBrand.features.block_sms
      && !!smsOverview
      && !isPartner

    return (
      <div style={{ paddingTop: '20px' }}>
        <Grid>
          <Row>
            <BasicsCard
              description={description}
              startDate={startDate}
              endDate={endDate}
              prizing={prizingDescription}
              prizingValue={prizingValue}
              badge={badge}
            />
            <div style={{ marginLeft: '16px' }}>
              <StatsCard {...statsCardData} />
            </div>
            <div style={{ marginLeft: '16px' }}>
              <RecentAnnouncementsCard {...announcementCardData} />
            </div>
            { !!winner && !isPartner
              && (
                <div style={{ marginLeft: '16px' }}>
                  <WinnerInfoCard {...winnerInfo} />
                </div>
              )
            }
            <Row style={{ marginTop: '16px' }}>
              <Col>
                { isPartner && minEntries > 0
                  && (
                    <MinEntryRequirement
                      minEntries={minEntries}
                      entries={entries}
                      hostBrandId={hostBrandId}
                      hostBrandName={hostBrand && hostBrand.accountname}
                    />
                  )
                }
                <div style={{ width: '384px' }}>
                  {
                    showSmsCard
                    && (
                      <SmsMarketingCard
                        campaignId={campaignId}
                        subscribers={smsOverview.subscribers}
                        messages={smsOverview.messages}
                        clicks={smsOverview.clicks}
                      />
                    )
                  }
                  <PartnerPerformanceCard {...partnerPerformanceCardData} />
                </div>
              </Col>
              <div style={{ width: '752px', marginLeft: '16px' }}>
                <H3 coral>
                  <small>
                    Partners
                  </small>
                </H3>
                <PartnersGrid
                  {...partnerGridData}
                  message={this.message}
                  openPlatformInviteModal={openPlatformInviteModal}
                  campaign={currentCampaign}
                />
              </div>
            </Row>
          </Row>
          <Row />
          {showDataIntegrationModel && <DataIntegrationSuggestion left={-30} />}
        </Grid>
      </div>
    )
  }
}
const mapDispatchToProps = {
  openPlatformInviteModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quickview))
