import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';
import SearchInput from 'visual-components/util/inputs/search-input';

import { Card } from 'react-toolbox/lib/card';


class SearchCard extends React.Component {
  render() {
    var {
      placeholder,
      value,
      onChange,
      onKeyUp,
      inputRef,
      onFocus
    } = this.props;

    return(
      <SearchInput
        placeholder={ placeholder }
        value={ value }
        onChange={ onChange }
        onKeyUp={ onKeyUp }
        inputRef={ inputRef }
        onFocus= { onFocus }
      />
    )
  }
};

SearchCard.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func
};

export default SearchCard;
