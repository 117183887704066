import React, { useState, useCallback } from 'react'
import propTypes from 'prop-types'
import ReactInput from 'react-toolbox/lib/input'
import { debounce, memoize } from 'lodash'
import AppDispatcher from 'dispatchers/app-dispatcher'
import ModalSearchNoLogoTheme from 'css/themes/modal-search-no-logo-theme.css'
import ModalSearchTheme from 'css/themes/modal-search-theme.css'
import SearchResultItem from 'visual-components/messaging/search-result-item'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'
import Card from 'react-toolbox/lib/card'
import { connect } from 'react-redux'

const SearchBrand = props => {
  const {
    selectBrand,
    currentBrandId,
    selectedBrand,
    disabled,
    searchResultsType,
  } = props

  const [inFocus, setInFocus] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isMouseHover, setMouseHover] = useState(false)

  const displayedBrand = selectedBrand ? selectedBrand.accountname : ''

  const toggleFocus = () => {
    setSearchValue('')
    setSearchResults([])
    setInFocus(prevState => !disabled && !prevState)
  }
  
  const search = useCallback(debounce(value => {
    const query = {
      bool: {
        filter: {
          bool: {
            should: [{
              wildcard: {
                accountname: `*${value.toLowerCase().replace(/\s/g, '*')}*`,
              },
            }],
            must: [
              {
                term: {
                  'marketplace.raw': searchResultsType,
                },
              },
            ],
            must_not: {
              term: {
                id: currentBrandId,
              },
            },
          },
        },
      },
    }

    if (parseInt(value, 10) > 0) {
      query.bool.filter.bool.should.push({
        term: {
          id: parseInt(value, 10),
        },
      })
    }

    AppDispatcher.http({
      url: '/search/',
      method: 'POST',
      data: {
        size: 100,
        query,
      },
      success: data => {
        setSearchResults(data.hits.hits)
      },
    })
  }, 300), [])

  const searchBrand = brand => {
    setSearchResults([])
    setSearchValue(brand)
    if (brand !== '') {
      search(brand)
    }
  }

  const toggleMouseHover = mouseHover => () => {
    setMouseHover(mouseHover)
  }

  // only toggle focus if mouse out of input
  const toggleFocusMouseOut = () => {
    if (!isMouseHover) {
      toggleFocus()
    }
  }

  const resultItems = searchResults && searchResults.map(item => (
    <div
      key={item._id}
      className={SellMediaModalTheme.searchResultItem}
    >
      <SearchResultItem
        noLogo
        modalSearch
        brand={item._source}
        choose={() => {
          selectBrand(item._source)
          toggleFocus()
        }}
      />
    </div>
  ))

  const renderInput = () => (
    inFocus ? (
      // search
      <ReactInput
        placeholder="Search for a brand"
        icon={<img src="/images/icons/search-icon.svg" />}
        value={searchValue}
        onChange={searchBrand}
        theme={ModalSearchTheme}
        className={`${ModalSearchTheme.dealSearch} ${ModalSearchTheme.noBackground}`}
        onBlur={toggleFocusMouseOut}
        disabled={disabled}
      />
    )
      : (
        // selected display
        <ReactInput
          placeholder="Search for a brand"
          theme={ModalSearchNoLogoTheme}
          className={ModalSearchNoLogoTheme.noBackground}
          value={displayedBrand}
          onChange={searchBrand}
          onFocus={toggleFocus}
        />
      )
  )

  return (
    <div
      className={SellMediaModalTheme.searchContainer}
      onMouseEnter={toggleMouseHover(true)}
      onMouseLeave={toggleMouseHover(false)}
    >
      {renderInput()}
      { searchResults && searchResults.length > 0 && inFocus
        && (
          <Card className={SellMediaModalTheme.searchResultsCard}>
            { resultItems }
          </Card>
        )
      }
    </div>
  )
}

SearchBrand.propTypes = {
  currentBrandId: propTypes.number.isRequired,
  selectedBrand: propTypes.object.isRequired,
  selectBrand: propTypes.object.isRequired,
  disabled: propTypes.bool,
  searchResultsType: propTypes.string,
}

SearchBrand.defaultProps = {
  disabled: false,
  searchResultsType: '',
}

const mapStateToProps = ({
  currentBrand: { id: currentBrandId },
}) => ({
  currentBrandId,
})

export default React.memo(connect(mapStateToProps)(SearchBrand))
