import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import injectCSS from 'react-jss'
import d3 from 'd3'
import _ from 'lodash'

import { Tiny } from 'visual-components/util/texts'
import { Select } from 'visual-components/util/form'

import { smsCreditCostMapping, cleanPlanText } from 'util/brand-stripe-belt-helper'

const styles = {
  container: {
    '& > div > div:nth-child(2)': { // dropdown wrapper
      backgroundColor: 'white',
      maxWidth: 480,
    },
  },
  subLabel: {
    marginTop: -16,
    marginBottom: -8,
  },
}

const formatCredits = int => d3.format(',')(int)

const stateToProps = ({ sms }) => ({ smsBank: sms.brandSmsBank })

function SMSPlansDropdown(props) {
  const {
    withSublabel,
    smsBank,
    classes: css,
    smsPricing,
  } = props
  const { currentPlan, credits: { info: { plans } } } = smsBank

  const currentPlanIdx = currentPlan
    ? _.findIndex(plans, opt => opt.credits === currentPlan.credits)
    : 0

  // Reformats plan options for dropdown component
  const formattedOptions = plans.map((option, i) => {
    const { credits } = option
    const formatedCredits = formatCredits(credits)

    const current = currentPlan && i === currentPlanIdx
    const selectedCost = credits * smsPricing

    return {
      credits,
      cost: selectedCost,
      value: i,
      label: `${formatedCredits} Credits ($${d3.format(',.2f')(selectedCost)})${current ? ' - Current' : ''}`,
    }
  })

  return (
    <div className={css.container}>
      <Select
        name="smsPlan"
        label={withSublabel ? '' : 'Plan Selection'}
        defaultSelected={currentPlanIdx}
        options={formattedOptions}
      />
      {
        withSublabel && (
          <div className={css.subLabel}>
            <Tiny>
              Approx. 1 credit per SMS / 3 credits per MMS
            </Tiny>
          </div>
        )
      }
    </div>
  )
}

SMSPlansDropdown.propTypes = {
  smsBank: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  withSublabel: PropTypes.bool,
  smsPricing: PropTypes.number,
}

SMSPlansDropdown.defaultProps = {
  withSublabel: false,
  smsPricing: null,
}

export default React.memo(connect(stateToProps)(injectCSS(styles)(SMSPlansDropdown)))
