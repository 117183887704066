import React from 'react';

class CtaFullPageImage extends React.Component {
  render() {
    var cta = this.props.cta;

    if (cta.ctaUrl && !cta.ctaUrl.match(/^(?:http(?:s)?\:\/\/)/)) {
      cta.ctaUrl = `http://${cta.ctaUrl}`;
    }

    return (
      <a href={ cta.ctaUrl }
        target="_blank"
        className="full-cta-link">
        <div className="full-page">
          <div className="hidden-xs">
            <img style={{ width: '100%' }} src={ cta.fullPageCTAImage } />
          </div>
          <div className="visible-xs">
            <img style={{ width: '100%' }} src={ cta.fullPageMobileCTAImage || cta.fullPageCTAImage } />
          </div>
        </div>
      </a>
    )
  }
}

export default CtaFullPageImage;
