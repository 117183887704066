import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import { Card } from 'react-toolbox/lib/card';

import { Link } from 'react-router-dom';
import { H5 } from 'visual-components/util/texts';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import InfoRowContent from 'visual-components/shared/brand-detail-card/info-row-content';
import BlueTags from 'visual-components/util/tags/BlueTags';
import BrandDegreeTooltip from 'visual-components/util/tooltip/brand-degree-tooltip';
import BrandFavoriteIcon from 'visual-components/brandProfile/brand-favorite-icon';
import getCoverPhotoUrl from 'util/brand-cover-photo'

class PartnerCard extends React.Component {
  message = () => {
    const { message, brandId, accountname } = this.props
    message({brandId, accountname});
  }

  render() {
    var {
      logo,
      accountname,
      dedicatedListSize,
      averageSignUpsPerCampaign,
      socialMediaImpressions,
      tags,
      degreeOfRemoval,
      facebookCoverPhoto,
      coverPhotoOffsetY,
      brandId,
      currentBrandId,
      onclick,
      homePage,
      customMarginRight,
      customMarginBottom,
      customHeight,
      showActions
    } = this.props;

    const isBrand = brandId == currentBrandId

    const degreeIconHash = {
      '1st': '/images/icons/degree-of-separation-icons/1-st-degree-connection-icon.svg',
      '2nd': '/images/icons/degree-of-separation-icons/mutual-connection-icon.svg',
      '3rd': '/images/icons/degree-of-separation-icons/never-worked-with-icon.svg'
    }

    const degreeTextHash = {
      '1st': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 1st Degree: You’ve partnered with this brand before and may have already acquired much of their audience.',
      '2nd': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 2nd Degree: It’s possible their audience may overlap with yours - you’ve partnered with someone who has worked with them.',
      '3rd': 'Degree of connection measures how much of your audience may already overlap with this brand. The further removed you are, the more new emails you’re likely to acquire. 3rd Degree: Go for it! This brand isn’t connected to you and their audience will be full of new emails.'
    }

    var whichIcon = degreeIconHash[degreeOfRemoval];
    var whichText = degreeTextHash[degreeOfRemoval];
    var shouldShowIcon = brandId == currentBrandId ? false : true;

    return (
      <Card style={{
        width: '240px',
        display: 'inline-flex',
        marginBottom: '16px',
        height: '228px',
        marginLeft: '16px',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)'
      }}>
        <Grid>
          {/* photo row */}
          <Row style={{
            margin: '0',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: COLOURS.silver,
            height: '72px'
          }}>
            <Col xs={4} style={{
              padding: '0',
              width: '71px',
              maxWidth: '71px'
            }}>
              <div style={{
                width: '80px',
                height: '71px',
                width: '71px',
                padding: '8px 8px 7px 8px',
                display: 'table-cell',
                verticalAlign: 'middle'
              }}>
                <img
                  alt={accountname}
                  src={ logo || '/images/defaults/mojo-white-tiger-logo@2x.png' }
                  style={{
                    maxHeight: '55px',
                    maxWidth: '55px',
                    display: 'block',
                    margin: 'auto'
                }}/>
              </div>
            </Col>
            <Col xs={8} style={{
              padding: '0',
              width: '200px',
              maxWidth: '169px',
            }}>
              <div style={{
                height: '71px',
                width: '169px',
                background: `url(${getCoverPhotoUrl(facebookCoverPhoto, { thumb: true }) || '/images/defaults/coverPhotoSmall@2x.png'}) no-repeat center`,
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPositionX: 0,
                backgroundPositionY: `${coverPhotoOffsetY}%`
              }}>
              {
                !isBrand ?
                <BrandFavoriteIcon brandId={ brandId }
                  small
                />
                : null
              }
              </div>
            </Col>
          </Row>

          {/* header row */}
          <Row style={{
            marginTop: '10px',
            marginBottom: '16px',
            height: '14px',
            alignItems: 'center',
            paddingLeft: '12px',
            paddingRight: '12px'
          }}>
            <Col xs={10}>
              <H5>
                { accountname }
              </H5>
            </Col>
            { shouldShowIcon ?
              <Col xs={2}
                style={{
                  textAlign: 'right'
              }}>
                <div style={{
                  borderRadius: '100%',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                  float: 'right'
                }}>
                  <BrandDegreeTooltip
                    imgSrc={ whichIcon }
                    text={ whichText }
                  />
                </div>
              </Col>
              : null
            }
          </Row>

          {/* list size row */}
          <Row style={{
            paddingBottom: '8px'
          }}>
            <InfoRowContent
              label={ 'List Size' }
              data={ dedicatedListSize }
            />
          </Row>

          {/* Avg Entries row */}
          <Row style={{
            paddingBottom: '8px'
          }}>
            <InfoRowContent
              label={ 'Avg Entries' }
              data={ averageSignUpsPerCampaign }
            />
          </Row>

          {/* total social audience row */}
          <Row style={{
            paddingBottom: '16px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: COLOURS.silver
          }}>
            <InfoRowContent
              label={ 'Total Social Audience' }
              data={ socialMediaImpressions }
            />
          </Row>

          { !isBrand  ?
              <div>
                <Link to={ `/analytics/partnerships/${brandId}` }>
                  <img src="/images/icons/analytics-blue.svg"
                    style= {{marginLeft: '12px', marginTop: '10px', marginBottom: '11px', marginRight: '16px'}}
                  />
                </Link>
                <a onClick = { this.message }>
                  <img src="/images/icons/send-message.svg" />
                </a>
              </div>
              :
              null
          }
        </Grid>
      </Card>
    )
  }
};

PartnerCard.propTypes = {
  logo: PropTypes.string.isRequired,
  accountname: PropTypes.string.isRequired,
  dedicatedListSize: PropTypes.number,
  averageSignUpsPerCampaign: PropTypes.number,
  socialMediaImpressions: PropTypes.number,
  tags: PropTypes.array.isRequired,
  degreeOfRemoval: PropTypes.string.isRequired,
  facebookCoverPhoto: PropTypes.string.isRequired,
  coverPhotoOffsetY: PropTypes.number.isRequired,
  brandId: PropTypes.number.isRequired,
  currentBrandId: PropTypes.number.isRequired,
};

export default PartnerCard;
