import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import FollowersPie from 'visual-components/analyticsDashboard/overviewCard/followers-pie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import _ from 'lodash'

const mapState = ({ analyticsDashboard }) => {
  const {
    dashboardSocialFollower,
    datePickerSocialFollower,
    oldestDate,
  } = analyticsDashboard

  return {
    dashboardSocialFollower,
    datePicker: datePickerSocialFollower,
    oldestDate,
  }
}

class Followers extends Component {
  static propTypes = {
    dashboardSocialFollower: PropTypes.object.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
    datePicker: PropTypes.object.isRequired,
  }

  toggle = name => {
    const {
      dashboardSocialFollower,
    } = this.props

    const {
      hideMap,
      items,
    } = dashboardSocialFollower

    const newHideMap = _.extend({}, hideMap, { [name]: !hideMap[name] })

    const newDashboardSocialFollower = _.extend({}, dashboardSocialFollower, { hideMap: newHideMap })

    store.dispatch({
      model: 'analyticsDashboard',
      type: 'UPDATE_ATTR',
      data: {
        dashboardSocialFollower: newDashboardSocialFollower,
      },
    })
  }

  changeFilter = (startDate, endDate) => {
    const data = {
      startDate,
      endDate,
      metric: 'dashboardSocialFollower',
    }

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  render() {
    const {
      dashboardSocialFollower,
      oldestDate,
      datePicker,
    } = this.props

    const {
      data,
      colorMap,
      hideMap,
    } = dashboardSocialFollower

    return (
      <FollowersPie
        oldestDate={oldestDate}
        items={data}
        colorMap={colorMap}
        toggle={this.toggle}
        hideMap={hideMap}
        changeFilter={this.changeFilter}
        datePicker={datePicker}
        alignLeft
      />
    )
  }
}

export default withRouter(connect(mapState)(Followers))
