import moment from 'moment-timezone';
import _ from 'lodash'

import { makeNumbersPretty } from 'util/makeNumbersPretty';
import SocialLabelMap from 'util/socialTitleMap';
import SocialTitleArray from 'util/socialTypeArray';
import CampaignAnalyticsReducer from './analytics_reducer_extensions/campaign-analytics-reducer';
import PartnershipAnalyticsReducer from './analytics_reducer_extensions/partnerships-analytics-reducer';
import DashboardAnalyticsReducer from './analytics_reducer_extensions/dashboard-analytics-reducer';
import dashboardAnalyticsDefaults from './analytics_reducer_extensions/dashboard-analytics-defaults';

const metricInfo = _.extend(DashboardAnalyticsReducer.metricInfo, CampaignAnalyticsReducer.metricInfo, PartnershipAnalyticsReducer.metricInfo);

const mapData = _.extend({
  lineGraphWithPriorPeriod: ({metric, data, interval, priorPeriod}) => {
    // PSQL 'week' = moment 'isoweek'
    interval = interval == 'week' ? 'isoweek' : interval;

    // CASE 1: two lines, one for current period, one for prior period
    if (priorPeriod) {
      let commonData = _.zipWith(data[0], data[1], (current, past) => {
        return {
          period: `${moment.utc(current.report_date).format('ll')} - ${moment.utc(current.report_date).endOf(interval).format('ll')}`,
          periodCompare: `${moment.utc(past.report_date).format('ll')} - ${moment.utc(past.report_date).endOf(interval).format('ll')}`,
          percentChange: Number(past.report_data) == 0 ? 0 : (Number(current.report_data) - Number(past.report_data)) / Number(past.report_data) * 100,
          x: moment.utc(current.report_date).toDate(),
          raw: data
        }
      })

      let rawCsv = []

      data.forEach(line => {
        let tempVal = line.map(item => {
          return Object.assign({}, item, { report_date: moment.utc(item.report_date).format('MM-DD-YYYY') })
        })

        rawCsv.push(tempVal)
      })

      return _.map(data, (dataset, idx) => {
        return {
          name: metricInfo[metric].names[idx],
          data: _.map(dataset, (dataset, idx) => {
            let { report_date, report_data } = dataset;
            return {
              y: Number(report_data),
              value: makeNumbersPretty(report_data, true),
              metric: metricInfo[metric].hoverMetric,
              ...commonData[idx],
            }
          }),
          raw: rawCsv
        }
      })
    } else {
    // CASE 2: one line, no prior period
      return [{
        name: metricInfo[metric].names[0],
        data: _.map(data, (dataset) => {
          let { report_date, report_data } = dataset;
          return {
            x: moment.utc(report_date).toDate(),
            y: Number(report_data),
            period: `${moment.utc(report_date).format('ll')} - ${moment.utc(report_date).endOf(interval).format('ll')}`,
            value: makeNumbersPretty(report_data, true),
            metric: metricInfo[metric].hoverMetric
          }
        }),
        raw: data.map(item => {
          return Object.assign({}, item, { report_date: moment.utc(item.report_date).format('MM-DD-YYYY') })
        })
      }]
    }
  },
  lineGraphWithMultipleSeries: ({metric, data, interval}) => {
    let mInterval;

    // logic for line graph in Specific Campaign where there's multiple brands/lines
    let categories = [], colorMap = {}, dataHash = {};
    let colors = ['#13b697', '#3fdac6', '#bef6e9', '#9ad8fb', '#20c3e9', '#03a9f4', '#0176c8', '#0b53c5', '#1b3088', '#010040'];

    let dataByDate = _.groupBy(data, item => {
      return moment.utc(item.report_date).format('l')
    });

    // For these graphs, interval is determined automatically in PSQL, so need to calculate interval based on the dates themselves
    let dates = Object.keys(dataByDate)
    if (dates.length >1) {
      let daysDiff = moment.utc(dates[1]).diff(moment(dates[0]), 'days')
      if (daysDiff == 1) {
        mInterval = 'day'
      } else if (daysDiff === 7) {
        mInterval = 'isoweek'
      } else {
        mInterval = 'month'
      }
    } else {
      mInterval = 'day'
    }

    let brands = _.uniqBy(data.map(item => ({id: item.brand_id, accountname: item.brand_name})), 'id')
                  .filter(item => item.id)
                  .sort((a,b) => b.id - a.id)

    data.forEach(d => {
      if(dataHash[d.report_date]) {
        dataHash[d.report_date][d.brand_id] = d;
      } else if (d.brand_id) {
        dataHash[d.report_date] = {
          [d.brand_id]: d
        }
      } else {
        dataHash[d.report_date] = {}
      }
    })

    let temp2 = brands.map(brand => {
      return {
        name: `id_${brand.id}`,
        title: brand.accountname,
        brand_id: brand.id,
        data: []
      }
    })


    _.each(dataHash, (date, key) => {
      temp2.forEach(brand => {
        if(date[brand.brand_id]) {
          brand.data.push({
            x: moment.utc(key).toDate(),
            y: Number(date[brand.brand_id].report_data),
            period: `${moment.utc(key).format('ll')} - ${moment.utc(key).endOf(mInterval).format('ll')}`,
          })
        } else {
          brand.data.push({
            x: moment.utc(key).toDate(),
            y: 0,
            period: `${moment.utc(key).format('ll')} - ${moment.utc(key).endOf(mInterval).format('ll')}`,
          })
        }
      })
    })

    temp2.forEach((item, idx) => {
      categories.push(item.name);
      colorMap[item.name] = colors[temp2.length - 1 - idx];
      item.color = colors[temp2.length - 1 - idx];
    })

    return {
      categories: categories,
      items: temp2.reverse(),
      dataByDate: dataByDate,
      colorMap,
      hideMap: {},
      raw: data.map(item => {
        let tempVal = Object.assign({}, item, { report_date: moment.utc(item.report_date).format('MM-DD-YYYY') })
        delete tempVal.brand_id
        return tempVal;
      })
    }
  },
  // used for splitting off one set of data into multiple independent line graphs
  lineGraphSplit: ({metric, data, interval}) => {
    const { fieldNames } = metricInfo[metric]
    const first_last = {}
    const reports = {
      aggregates: {},
    }

    Object.keys(fieldNames).forEach(report => {
      first_last[report] = {
        first: null,
        last: null
      }
      reports[report] = {
        name: report,
        data: data.map(dataset => {
          const { report_date } = dataset

          if (Number(dataset[fieldNames[report]]) && !first_last[report].first) {
            first_last[report].first = `${moment.utc(report_date).format('ll')} - ${moment.utc(report_date).endOf(interval).format('ll')}`
          }
          if (Number(dataset[fieldNames[report]])) {
            first_last[report].last = `${moment.utc(report_date).format('ll')} - ${moment.utc(report_date).endOf(interval).format('ll')}`
          }

          return {
            x: moment.utc(report_date).toDate(),
            y: Number(dataset[report]),
            period: `${moment.utc(report_date).format('ll')} - ${moment.utc(report_date).endOf(interval).format('ll')}`,
            value: report == 'revenue' ? `$${makeNumbersPretty(dataset[report], true)}` : makeNumbersPretty(dataset[report], true),
            metric: fieldNames[report],
          }
        })
      }
      reports.aggregates[report] = _.sumBy(data, data => Number(data[report] || 0))
    })
    reports.first_last = first_last
    reports.csv = data

    
    return reports
  },
  barGraphVertical: ({metric, data, interval}) => {
    // logic for the vertical bar chart for "Campaigns Launched" and "New Partnerships" in Dashboard
    let columnData = _.map(data, (dataset) => {
      return Number(dataset.report_data)
    })
    let columnCategories = _.map(data, (dataset) => {
      return moment.utc(dataset.report_date).format('MMM D')
    })
    return {
      columnData: [{
        name: metricInfo[metric].name,
        data: columnData
      }],
      columnCategories,
      data,
      csv: data.map(item => {
        return Object.assign({}, item, { report_date: moment.utc(item.report_date).format('MM-DD-YYYY') })
      })
    }
  },
  barGraphHorizontalTop3: ({metric, data, interval}) => {
    return data.map(({perc, ...rest}) => {
      return {
        perc: parseInt(perc),
        ...rest
      }
    }).reverse()
  },
  dummyPlaceholder: ({metric, data, interval}) => {
    return data;
  },
  basicData: ({metric, data, interval}) => {
    return data[0] || {};
  },
  passThrough: data => data,
  geographicStateDistribution: ({metric, data}) => {
    let stateDistribution = {};
    let stateDistributionTotal = data.reduce((acc, current) => acc + Number(current.count), 0)

    data.forEach(row => {
      stateDistribution[row.state] = {
        count: Number(row.count),
        percent: Number(row.count) / stateDistributionTotal
      }
    });

    return stateDistribution;
  },
  incomeDistribution: ({metric, data}) => {
    let incomeBrackets = ['income_below35k', 'income_35k_49k', 'income_50k_74k','income_75k_99k', 'income_above100k']
    let total = 0;
    let distribution = incomeBrackets.map(bracket => {
      let item = _.find(data, {income_bracket: bracket}) || { count: 0 };
      let count = parseInt(item.count);
      total += count;
      return count;
    })

    return {
      distribution,
      total
    }
  },
  genderDistribution: ({metric, data}) => {
    return [{
      x: 'female',
      y: parseInt(data[0] ? data[0].female : 0)
    }, {
      x: 'male',
      y: parseInt(data[0] ? data[0].male : 0)
    }]
  },
  stackedBarVertical: ({metric, data, interval}) => {
    let colors = [
      '#13b697',
      '#14cba8',
      '#3fdac6',
      '#20c3e9',
      '#03a9f4',
      '#0176c8',
      '#0b53c5',
      '#1b3088',
      '#01125d',
      '#010040'
    ];

    let typesHash = {}, dataHash = {}, colorMap = {};
    _.each(data, d => {
      if (d.bonus_type && !typesHash[d.bonus_type]) {
        typesHash[d.bonus_type] = true
      }

      if (dataHash[d.report_date]) {
        dataHash[d.report_date][d.bonus_type] = d;
      } else if (d.bonus_type) {
        dataHash[d.report_date] ={
          [d.bonus_type]: d
        }
      } else {
        dataHash[d.report_date] = {}
      }
    })

    let socialItems = Object.keys(typesHash).map(type => {
      return {
        name: type,
        data: [],
        title: SocialLabelMap[type],
        selected: true
      }
    })

    _.each(dataHash, date => {
      _.each(socialItems, type => {
        if (date[type.name]) {
          type.data.push(Number(date[type.name].report_data))
        } else {
          type.data.push(0)
        }
      })
    })

    let categories = _.map(Object.keys(dataHash), date => moment.utc(date).format('MMM D  YYYY'))

    socialItems.forEach((social, idx) => {
      colorMap[social.name] = colors[idx];
      social.color = colors[idx];
    });

    return {
      categories,
      socialItems,
      colorMap,
      hideMap: {}
    }
  },
  eventTimelineGraph: ({metric, data}) => {
    let campaigns = _.map(data, (campaign) => {
      return {
        id: campaign.campaign_id,
        startDate: new Date(campaign.start_date),
        endDate: new Date(campaign.end_date),
        name: campaign.name,
        entries: Number(campaign.totalentries),
        newEmails: Number(campaign.totalntf)
      }
    })

    const campaignCount = campaigns.length;
    const entriesDriven = _.sumBy(campaigns, 'entries');
    const ntfDriven = _.sumBy(campaigns, 'newEmails');
    const avgEntries = campaignCount ? entriesDriven / campaignCount : 0;
    const ntfPerc = ntfDriven ? ntfDriven / entriesDriven : 0;

    return {
      campaigns,
      aggregrates: {
        campaignCount,
        entriesDriven,
        ntfDriven,
        avgEntries,
        ntfPerc
      }
    }

  },
  donutChart: ({metric, data, interval}) => {
    const colorMap = {};
    const colors = [
      '#14cba8',
      '#bef6e9',
      '#03a9f4',
      '#0176c8',
      '#1b3088',
      '#01125d'
    ];

    const copyMap = {
      fbLike: 'Facebook',
      instagramFollow: 'Instagram',
      pinterestFollow: 'Pinterest',
      snapchatFollow: 'Snapchat',
      twitterFollow: 'Twitter',
      youtubeSubscribe: 'Youtube',
    }

    const graphData = _.sortBy(data.map((row) => {
      return {
        name: row.bonus_type,
        title: copyMap[row.bonus_type],
        y: Number(row.total),
      }
    }), 'name');

    graphData.forEach((social, idx) => {
      colorMap[social.name] = colors[idx];
      social.color = colors[idx];
    });

    return {
      data: graphData,
      hideMap: {},
      colorMap,
    }

  },
  /*
  *****************************************************
  ADD MORE MAPPING LOGIC HERE FOR EACH TYPE OF GRAPH (i.e. bar, pie, etc)
  *****************************************************
  */
}, CampaignAnalyticsReducer.mappings);

const aggregateMap = {
  dashboardEmailsAcquired: 'dashboardEmailsAcquired',
  homeEmailsAcquired: 'dashboardEmailsAcquired',
  dashboardSocialActions: 'dashboardSocialActions',
  dashboardCampaignsLaunched: 'dashboardCampaignsLaunched',
  homeCampaignsLaunched: 'dashboardCampaignsLaunched',
  dashboardNewPartnerships: 'dashboardNewPartnerships',
  campaignVisits: 'campaignVisits',
  campaignEntries: 'campaignEntries',
  campaignNTF: 'campaignNTF',
  campaignTotalSocialActions: 'campaignTotalSocialActions',
  dashboardSmsSubscribers: 'dashboardSmsSubscribers',
  campaignPartnerSmsContribution: 'campaignPartnerSmsContribution',
}

const calculateAggregrate = (overviewAggregates, { metric, data, priorPeriod }) => {
  const aggregateMetric = aggregateMap[metric]
  if (aggregateMetric) {
    const sumData = priorPeriod ? data[0] : data
    return _.extend({}, overviewAggregates, { [aggregateMetric]: _.sumBy(sumData, data => Number(data.report_data || 0)) })
  }

  return overviewAggregates
}

const AnalyticsReducer = (model, initialState) => {
  const defaultState = _.extend({}, {
    rid: model,
    loading: true
  }, initialState);

  return function (state, action) {
    if (action.model !== model) {
      return state || defaultState;
    }
    const actions = _.extend({
      CLEAR: function () {
        return _.extend({}, defaultState);
      },
      UPDATE_ATTR: function () {
        return _.extend({}, state, action.data);
      },
      UPDATE_DASHBOARD: function () {
        var analyticsDashboard = state.analyticsDashboard;
        return _.extend({}, state, {
          analyticsDashboard: _.extend({}, analyticsDashboard, action.data)
        })
      },
      LOAD_REPORTS: function() {
        let { data } = action;

        let newData = _.reduce(data, (accumulator, currentReport) => {
          let { metric } = currentReport;
          let mappingLogic = metricInfo[metric] ? metricInfo[metric].mappingLogic : false;
          if (mappingLogic) {
            return _.extend(
              {},
              accumulator,
              { [metric]: mapData[mappingLogic](currentReport) },
              { overviewAggregates: calculateAggregrate( accumulator.overviewAggregates, currentReport) }
            )
          } else if (['homeEmailsAcquired', 'homeCampaignsLaunched'].includes(metric)) {
            return _.extend(
              {},
              accumulator,
              { [metric]: _.sumBy(currentReport.data, data => Number(data['report_data'] || 0 )) }
            )
          } else {
            //report is not found, skipping
            return accumulator;
          }
        }, { overviewAggregates : state.overviewAggregates })
        newData.loading = false;
        return _.extend({}, state, newData)
      },
      UPDATE_DATE_PICKER: function () {
        const { data, name } = action;
        if (state[name]) {
          const currentState = state[name]
          const newState = Object.assign({}, currentState, data);
          return Object.assign({}, state, { [name]: newState })
        } else {
          return state;
        }
      },
      LOAD_DEFAULT_DASHBOARD: function () {
        let defaults = dashboardAnalyticsDefaults;
        let { oldestDate } = action.data;

        let datePickerAggregateTop = Object.assign({}, defaults.datePickerAggregateTop, {startDate: oldestDate});
        let datePickerConversionDetails = Object.assign({}, defaults.datePickerConversionDetails, {startDate: oldestDate})
        return _.extend({}, dashboardAnalyticsDefaults, { oldestDate, datePickerAggregateTop, datePickerConversionDetails  })
      }
    }, CampaignAnalyticsReducer.actions);

    const item = actions[action.type] || function () {
      return state || defaultState;
    };

    return item();
  };
}

export default AnalyticsReducer;
