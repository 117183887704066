import React, { Component } from 'react'
import { object, number } from 'prop-types'

import { Small } from 'visual-components/util/texts'
import AssetsTheme from 'css/themes/media-market/Assets.css'

class FileWrapper extends Component {
  static propTypes = {
    item: object.isRequired,
    currentBrandId: number.isRequired,
  }

  render() {
    const {
      item: {
        id,
        deal_id,
        name,
        type,
        ioPath,
        src,
        size,
        invalid,
      },
      currentBrandId,
    } = this.props

    const path = ioPath
      || `/deals/attachment/${deal_id}/${currentBrandId}/${id}/download/${name}.${type}`

    return (
      <div
        className={AssetsTheme.fileWrapperContainer}
        onClick={() => window.open(path)}
      >
        <div className={AssetsTheme.fileWrapperIconContainer}>
          <img src={src || 'images/icons/assets-file-wrapper-icon.png'} />
        </div>

        <div className={AssetsTheme.verticalLine} />

        <div className={AssetsTheme.fileWrapperItemInfo}>
          {!ioPath ? (
            <Small>
              <em>
                {name && name.length > 28 ? `${name.slice(0, 28)}...` : name}
              </em>          
            </Small>
          ) : (
            <div className={AssetsTheme.insertionOrderContainer}>
              <Small>
                <em>{id}</em>
              </Small>
              {ioPath && invalid && (
                <div className={AssetsTheme.invalidTag}>
                  <Small white>Invalid</Small>
                </div>
              )}
            </div>
          )}
          <div className={AssetsTheme.fileType}>
            <Small>{size ? `${size} - .${type}` : `.${type}`}</Small>
          </div>
        </div>
      </div>
    )
  }
}

export default FileWrapper
