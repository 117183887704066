import React from 'react';
import PropTypes from 'prop-types';

import CampaignPreviewSpecRow from './campaign-preview-spec-row';

import Grid from 'visual-components/util/no-padding-grid/grid';

class CampaignPreviewSpecs extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var {
      minListSize,
      estEnties,
      minEntriesReq
    } = this.props;

    return(
      <Grid>
        <CampaignPreviewSpecRow
          name={ 'Min. List Size' }
          data={ minListSize }
          paddingTop={ '4px' }
        />
        <CampaignPreviewSpecRow
          name={ 'Est. Entries' }
          data={ estEnties }
          paddingTop={ '8px' }
        />
        <CampaignPreviewSpecRow
          name={ 'Min. Entries Required' }
          data={ minEntriesReq }
          paddingTop={ '8px' }
        />
      </Grid>
    );
  }
};

CampaignPreviewSpecs.propTypes = {
  minListSize: PropTypes.number.isRequired,
  estEnties: PropTypes.number.isRequired,
  minEntriesReq: PropTypes.number.isRequired,
};

export default CampaignPreviewSpecs;
