import React from 'react';
import COLOURS from 'util/colours';
import { MenuItem } from 'react-toolbox/lib/menu';

import { Link } from 'react-router-dom'
import { P, Small } from 'visual-components/util/texts';
import WideNavBarDropdownTheme from 'css/themes/WideNavBarDropdown.css'

const themes = {
  WideNavBarDropdown: WideNavBarDropdownTheme,
}

class MessageMenuItem extends React.Component{
  render() {
    const {
      className,
      handleNotificationMenuHide,
      item,
      messageMessage
    } = this.props

    return(
      <Link to={ `/messaging/inbox/${item.id}` }>
        <MenuItem
          theme={ themes.WideNavBarDropdown }
          className={ className }
          onClick={ handleNotificationMenuHide }
        >
          <div style={{
            overflow: 'hidden',
            background: COLOURS.white,
            borderRadius: '50%',
            height: '48px',
            minWidth: '48px',
            width: '48px',
            marginRight: '12px',
          }}>
            <img src={ item.img }
              style={{
                objectFit: 'contain',
                height: '100%',
                width: '100%',
            }} />
          </div>
          <div style={{
            width: '308px',
          }}>
            <div style={{
              paddingTop: '2px'
            }}>
              <div style={{
                display: 'inline-block',
                width: '232px'
              }}>
                <P>
                  <em>{item.brandName}</em>
                </P>
              </div>
              <div style={{
                display: 'inline-block',
                width: '60px',
                textAlign: 'right'
              }}>
                <P right>
                  { item.time }
                </P>
              </div>
            </div>
            <div>
              <div style={{
                paddingTop: '8px',
                paddingBottom: '4px',
                width: '280px'
              }}>
                <Small overflowEllipsis>
                  <i>
                    { messageMessage }
                  </i>
                </Small>
              </div>
            </div>
          </div>
        </MenuItem>
      </Link>
    )
  }
};

export default MessageMenuItem;
