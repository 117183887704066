const initialState = {
  unreadCount: 0,
  size: 20,
  hasMore: true,
  items: [],
  liveQueue: [],
}

export default function liveNotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_NOTIFICATIONS':
      return { ...initialState }

    case 'UPDATE_NOTIFICATIONS_ATTR':
      return { ...state, ...action.data }

    case 'LOADING_NOTIFICATIONS':
      return { ...state, loading: true, error: null }

    case 'DEQUEUE_NOTIFICATION': {
      const [, ...liveQueue] = state.liveQueue
      return { ...state, liveQueue }
    }

    case 'LOAD_NEW_NOTIFICATION': {
      const newItem = action.data
      const liveQueue = [newItem, ...state.liveQueue]

      // if regular notification, update unread count and prepend to list
      if (newItem.presentational && newItem.presentational.isNotification) {
        const unreadCount = state.unreadCount + 1
        const items = [newItem, ...state.items]
        return {
          ...state,
          unreadCount,
          items,
          liveQueue,
        }
      }

      return { ...state, liveQueue }
    }

    case 'LOAD_NOTIFICATIONS_SUCCESS': {
      const { unreadCount, items } = action.payload

      // Unless no more notifications, adjust start ID for next page
      const start = items && items.length > 0 ? items[items.length - 1].id : state.start

      // Indicate (potentially) has more if items length is same as page size
      const hasMore = !!items && items.length === state.size

      return {
        ...state,
        items: [...state.items, ...items],
        unreadCount,
        start,
        hasMore,
        loading: false,
      }
    }

    case 'LOAD_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        loading: false,
        error: `${action.payload.statusText}: ${action.payload.responseText}`,
      }

    case 'DISMISS_NOTIFICATION': {
      const liveQueue = state.liveQueue.filter(item => item.id !== action.id)
      return { ...state, liveQueue }
    }

    case 'READ_NOTIFICATION': {
      const itemToRead = state.items.find(item => item.id === action.id)

      // If no item or already read, return state
      if (!itemToRead || itemToRead.read_at) return state

      // Otherwise mark as read and decrement
      const items = state.items.map(item => {
        if (item.id !== action.id) return item
        return { ...item, read_at: new Date() }
      })

      const unreadCount = Math.max(state.unreadCount - 1, 0)

      return { ...state, items, unreadCount }
    }

    case 'READ_ALL_NOTIFICATIONS':
      return {
        ...state,
        items: state.items.map(item => ({ ...item, read_at: new Date() })),
        unreadCount: 0,
      }

    case 'DEDUCT_UNREAD_COUNT_FOR_REFERRALS': {
      const { count } = action.payload
      const items = state.items.map(item => {
        if (item.type !== 'referral_joined') return item
        return { ...item, read_at: new Date() }
      })
      const unreadCount = Math.max(state.unreadCount - count, 0)
      return { ...state, items, unreadCount }
    }

    default:
      return state
  }
}
