import React from 'react'
import moment from 'moment-timezone'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import {
  H4, Small, Tiny, P,
} from 'visual-components/util/texts'
import Badge from 'visual-components/util/badge'
import MessagingDispatcher from 'dispatchers/messaging-dispatcher'
import MediaMarketAnalyticsTheme from 'css/themes/media-market/MediaMarketAnalyticsTheme.css'
import statusMap from 'util/deal-status-map'
import d3 from 'd3'
import PropTypes from 'prop-types'

class AnalyticsTransactionCard extends React.Component {
  sendMessage = () => {
    const {
      data: {
        deal,
      },
    } = this.props
    const brandName = deal && deal.selling_brand && deal.selling_brand.accountname
    const brandId = deal && deal.selling_brand_id
    MessagingDispatcher.conversationModal(brandId, brandName)
  }

  render() {
    const {
      dealValue,
      data: {
        isBuyer,
        isFixedDeal,
        isCPCDeal,
        deal,
      },
    } = this.props

    const sellingBrandSocial = deal && deal.selling_brand && deal.selling_brand.social

    const socialLinks = {
      facebook: `https://www.facebook.com/${sellingBrandSocial.facebookUrl}`,
      instagram: `https://www.instagram.com/${sellingBrandSocial.instagramHandle}`,
      twitter: `https://www.twitter.com/${sellingBrandSocial.twitterHandle}`,
    }

    const cardTitle = deal && deal.pretty_type

    return (
      <Card className={MediaMarketAnalyticsTheme.transactionCard} style={{ marginRight: '14px' }}>
        <Grid style={{ marginLeft: '20px', marginTop: '24px' }}>

          {/* Deal Info/Status */}
          <Row style={{ marginBottom: '8px', alignItems: 'center', justifyContent: 'space-between' }}>
            <H4 overflowEllipsis>
              <em>{cardTitle.length > 35 ? `${cardTitle.slice(0, 35)}...` : cardTitle}</em>
            </H4>

            <div style={{ position: 'relative', right: '32px' }}>
              <Badge
                label={deal && deal.status && statusMap[deal.status].label}
                color={deal && deal.status && statusMap[deal.status].color}
              />
            </div>
          </Row>

          {/* Date/Time */}
          <div className={MediaMarketAnalyticsTheme.dateTimeContainer}>
            <Row>
              <img
                src="images/date-and-time-black.svg"
                className={MediaMarketAnalyticsTheme.dateTimeIcon}
              />
              <div className={MediaMarketAnalyticsTheme.date}>
                <Small>
                  { `${moment(deal && deal.start_date).format('MMM D, YYYY')} - ${moment(deal && deal.end_date).format('MMM D, YYYY')}` }
                </Small>
              </div>
            </Row>
          </div>

          <Row style={{ marginBottom: '24px' }}>
            {/* Selling Brand */}
            <img
              src={deal && deal.selling_brand && deal.selling_brand.logo}
              className={MediaMarketAnalyticsTheme.brandLogo}
            />

            <div>
              <div className={MediaMarketAnalyticsTheme.sellingBrandName}>
                <P overflowEllipsis>
                  <i>{deal && deal.selling_brand && deal.selling_brand.accountname}</i>
                </P>
              </div>

              {/* Send Message */}
              { isBuyer
                && (
                  <div className={MediaMarketAnalyticsTheme.sendMessageContainer}>
                    <img
                      src="images/social_icons/mail-icon.svg"
                      className={MediaMarketAnalyticsTheme.sendMessageIcon}
                    />
                    <div onClick={this.sendMessage} style={{ cursor: 'pointer' }}>
                      <Small azure style={{ marginTop: '1px' }}><em>Send Message</em></Small>
                    </div>
                  </div>
                )
              }

              {/* Social Media Icons */}
              <div className={MediaMarketAnalyticsTheme.socialMediaIconContainer}>
                { sellingBrandSocial.twitterHandle
                && (
                <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                  <img
                    src="images/social_icons/twitter-icon-green.svg"
                    className={MediaMarketAnalyticsTheme.socialMediaIcon}
                  />
                </a>
                )
                }

                { sellingBrandSocial.instagramHandle
                && (
                <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                  <img
                    src="images/social_icons/instagram-icon-green.svg"
                    className={MediaMarketAnalyticsTheme.socialMediaIcon}
                  />
                </a>
                )
                }

                { sellingBrandSocial.facebookUrl
                && (
                  <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                    <img
                      src="images/social_icons/facebook-icon-green.svg"
                      className={MediaMarketAnalyticsTheme.socialMediaIcon}
                    />
                  </a>
                )
                }
              </div>
            </div>
          </Row>

          {/* Deal Stats */}
          <Row style={{ justifyContent: 'space-between', paddingBottom: '20px', width: isCPCDeal ? '260px' : '175px' }}>
            { isFixedDeal
            && (
            <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
              <div className={MediaMarketAnalyticsTheme.dealStatValue}>
                <Small><em>{ dealValue }</em></Small>
              </div>
              <Tiny>Total Value</Tiny>
            </div>
            )
            }

            { isFixedDeal
            && (
              <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
                <div className={MediaMarketAnalyticsTheme.dealStatValue}>
                  <Small><em>{d3.format(',')(deal && deal.expected_views)}</em></Small>
                </div>
                <Tiny>Expected Reach</Tiny>
              </div>
            )
            }

            { isCPCDeal
            && (
              <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
                <div className={MediaMarketAnalyticsTheme.dealStatValue}>
                  <Small><em>{d3.format('$,.2f')(deal && deal.price)}</em></Small>
                </div>
                <Small><i>CPC</i></Small>
              </div>
            )
            }

            { isCPCDeal
            && (
              <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
                <div className={MediaMarketAnalyticsTheme.dealStatValue}>
                  <Small><em>{d3.format('$,.2f')((deal && deal.max_clicks) * (deal && deal.price))}</em></Small>
                </div>
                <Small><i>Potential Value</i></Small>
              </div>
            )
            }

            { isCPCDeal
            && (
              <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
                <div className={MediaMarketAnalyticsTheme.dealStatValue}>
                  <Small>
                    <em>
                      {`${d3.format(',')(deal && deal.min_clicks)} - ${d3.format(',')(deal && deal.max_clicks)}`}
                    </em>
                  </Small>
                </div>
                <Small><i>Deal Click Range</i></Small>
              </div>
            )
            }
          </Row>

        </Grid>
      </Card>
    )
  }
}

AnalyticsTransactionCard.propTypes = {
  data: PropTypes.object.isRequired,
  dealValue: PropTypes.string.isRequired,
}


export default AnalyticsTransactionCard
