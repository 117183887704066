import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import IncomeCard from 'visual-components/brandProfile/IncomeCard'
import GeoCard from 'visual-components/brandProfile/GeoCard'
import EmptyBonzai from './empty-bonzai'

// TODO: Confirm if we're removing currentBrand/EmptyBonzai instead of returning null
class DemographicsContent extends PureComponent {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    // currentBrand: PropTypes.object.isRequired,
  }

  render() {
    const {
      brand,
      // currentBrand
    } = this.props

    // const isBrand = brand.id === currentBrand.id

    if (brand.obscure) {
      return (
        <EmptyBonzai description="Sign up or log in to DojoMojo to see this brand's full profile." />
      )
    }

    return (
      <>
        {brand.isDemographicEmpty ? null : (
          // <EmptyBonzai
          //   description={
          //     isBrand
          //       ? 'You don’t have any audience data yet. Create or find an open campaign to get started!'
          //       : 'This brand has not participated in any active campaigns yet.  Connect with them to start a partnership.'
          //   }
          // />
          <div>
            <div style={{ marginBottom: '16px' }}>
              <IncomeCard distribution={brand.incomeDistributionPercent} />
            </div>
            <div>
              <GeoCard
                distribution={brand.stateDistributionPercent}
                stateOne={brand.stateOne}
                stateTwo={brand.stateTwo}
                stateThree={brand.stateThree}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

export default DemographicsContent
