import React, { Component } from 'react'

import ProgressBar from 'react-toolbox/lib/progress_bar'
import defaultTheme from './theme.css'

const MaterialSpinner = ({ 
  when = false, 
  style = {},
  type = 'circular',
  mode = 'indeterminate',
  theme = defaultTheme,
}) => (
  when
  ? <div style={ Object.assign({}, {
      textAlign: 'center'
    }, style) }>
      <ProgressBar
        type={ type }
        mode={ mode }
        theme={ theme }
      />
    </div>
  : null
)

export default MaterialSpinner
