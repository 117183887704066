import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import { Input } from 'react-toolbox/lib/input'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4, H3 } from 'visual-components/util/texts'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import InputContainer from 'visual-components/util/InputContainer'
import Stars from 'visual-components/util/stars'
import inputTheme from 'css/themes/Input.css'
import ReviewsTheme from 'css/themes/ratings-reviews.css'
import * as RatingsActions from 'actions/ratings-actions'

const mapStateToProps = ({ brandRatings, profile }) => ({ brandRatings, profile })

const mapDispatchToProps = dispatch => ({
  ratingsActions: bindActionCreators(RatingsActions, dispatch),
})

const BrandImg = ({ img }) => (
  <div
    className={`${ReviewsTheme.brandImg} ${ReviewsTheme.modalImg}`}
    style={{ backgroundImage: `url(${img || '/images/dummyimg.svg'})` }}
  />
)

const TIMEOUT_MS = 500

class MediaReviewsModal extends PureComponent {
  static propTypes = {
    brandRatings: object.isRequired,
    ratingsActions: object.isRequired,
    profile: object.isRequired,
  }

  state = {
    rate: 0,
    comments: '',
    open: false,
  }

  componentDidMount() {
    this.fetchPendingReviews()
  }

  componentDidUpdate({ brandRatings: { rating, showReviewModal } }) {
    const { brandRatings } = this.props

    if (brandRatings.rating !== rating) {
      this.setState({ rate: brandRatings.rating })
    }

    if (brandRatings.showReviewModal && brandRatings.showReviewModal !== showReviewModal) {
      this.setState({ open: true })
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeout)
  }

  fetchPendingReviews = () => {
    const { ratingsActions } = this.props

    ratingsActions.getPendingReviews('marketplace')
    ratingsActions.getPendingReviews('campaign')
  }

  onRejectReview = () => {
    const { brandRatings: { brandToReview }, ratingsActions } = this.props

    const { id } = brandToReview

    return this.setState({ open: false, rate: 0, comments: '' }, () => {
      ratingsActions.rejectReview(id)

      // Wait .5 sec before fetching new updated pending reviews
      this.timeout = setTimeout(this.fetchPendingReviews, TIMEOUT_MS)
    })
  }

  onSubmitReview = () => {
    const { brandRatings, ratingsActions } = this.props
    const { rate, comments } = this.state

    const {
      brandToReview: {
        id,
        ratedbrand_accountname,
      },
    } = brandRatings

    ratingsActions.submitReview({
      id,
      rate,
      comments,
    })

    return this.setState({ open: false, rate: 0, comments: '' }, () => {
      // Wait .5 sec before fetching new updated pending reviews
      this.timeout = setTimeout(() => {
        this.fetchPendingReviews()

        toastr.success(`Your review to ${ratedbrand_accountname} has been sent.`, null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      }, TIMEOUT_MS)
    })
  }

  closeReview = () => {
    const { ratingsActions } = this.props

    this.setState({ open: false, rate: 0, comments: '' }, () => {
      this.timeout = setTimeout(() => {
        ratingsActions.closeReviewModal()
      }, TIMEOUT_MS)
    })
  }

  onRate = rate => this.setState({ rate })

  onReview = comments => this.setState({ comments })

  render() {
    const {
      brandRatings: {
        brandToReview,
      },
      profile: {
        impersonating,
      },
    } = this.props
    const { rate, comments, open } = this.state

    const isImpersonating = impersonating && impersonating !== 'null'

    if (isImpersonating) return null

    const brand = brandToReview || {}

    const title = `How was your deal with ${brand.ratedbrand_accountname}`

    const dealName = brand.deal_name ? brand.deal_name : brand.campaign_name

    const isHost = brand.campaign_hostbrand_id === brand.rating_brand_id

    const isModalActive = open && Object.keys(brand).length

    return (
      <MultiTaskModal
        active={isModalActive}
        close={this.closeReview}
        title={title}
      >
        <div style={{ marginBottom: '24px' }}>
          <H4 multiline>
            {
              brand.model_type === 'campaign'
                ? `Please rate your ${isHost ? 'host\'s' : 'partners\''} performance in the campaign,`
                : 'Please rate the seller\'s performance on your media deal'
            }
            <strong>{` ${dealName}`}</strong>
          </H4>
        </div>
        <div style={{ marginBottom: '24px' }} className={`${ReviewsTheme.flex} ${ReviewsTheme.center}`}>
          <BrandImg img={brand.ratedbrand_logo} />
          <div className={`${ReviewsTheme.flex} ${ReviewsTheme.center} ${ReviewsTheme.column}`}>
            <H3>
              <small>
                { brand.ratedbrand_accountname }
              </small>
            </H3>
            <Stars
              value={rate}
              style={{ margin: '20px 0 0' }}
              className={ReviewsTheme.cstmRatingStars}
              readonly={false}
              onChange={this.onRate}
              size={32}
            />
          </div>
        </div>
        <div>
          <InputContainer label="How was your experience with this brand?">
            <Input
              multiline
              type="text"
              name="comment"
              theme={inputTheme}
              className={inputTheme.textarea}
              value={comments}
              placeholder="Let us know..."
              onChange={this.onReview}
            />
          </InputContainer>
        </div>
        <ModalButtonGroup
          canSave
          confirm={this.onSubmitReview}
          cancel={this.onRejectReview}
          cancelText="No Thanks"
          confirmText="Submit Feedback"
        />
      </MultiTaskModal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaReviewsModal)
