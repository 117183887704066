import React, { Component } from 'react'
import Text from 'util/components/text'
import PrimaryButton from 'util/components/buttons/primary-button'
import { debounce } from 'lodash'

import AppDispatcher from 'dispatchers/app-dispatcher'

class CustomerSuccessAdminEntrySeeder extends Component {
  state = {
    loading: false,
    error: null,
    status: null,
    foundCampaign: false,
    campaignName: 'No Campaign Found',
  }

  fetchCampaign = debounce(() => {
    if (this.state.campaignId) {
      AppDispatcher.http({
        url: `/cs-campaigns/${this.state.campaignId}`,
        success: campaign => {
          this.setState({
            foundCampaign: true,
            campaignName: campaign.name,
          })
        },
        error: err => {
          this.setState({
            foundCampaign: false,
            campaignName: 'No Campaign Found',
            error: err.responseText,
          })
        },
      })
    }
  }, 500)

  seedCampaign = () => {
    const {
      campaignId, numEntries, percentNew,
      ctaClicks, engagement, loading
    } = this.state

    const data = {}

    if (numEntries) {
      data.numEntries = parseInt(numEntries)
    }

    if (percentNew) {
      data.percentNew = Math.min(percentNew / 100, 1)
    }

    if (ctaClicks) {
      data.ctaClicks = parseInt(ctaClicks)
    }

    if (engagement) {
      data.engagement = Math.min(engagement / 100, 1)
    }

    if (!loading) {
      this.setState({
        loading: true,
        error: null,
        status: 'Seeding...'
      })

      AppDispatcher.http({
        url: `/campaign-test/${campaignId}`,
        method: 'PUT',
        data,
        success: () => {
          this.setState({
            loading: false,
            status: 'Complete!',
            foundCampaign: false,
          })
        },
        error: err => {
          this.setState({
            loading: false,
            error: err.responseText,
            status: null,
          })
        },
      })
    }
  }

  onchange = e => {
    this.fetchCampaign.cancel()
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Seed Campaign with Entries
          </Text.dojoH2Bold>
          <form className="col-xs-12">
            <div className="row">
              <div className="col-xs-4" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Campaign ID*
                  </Text.dojoP>
                  <input
                    type="number"
                    name="campaignId"
                    value={ this.state.campaignId }
                    className="col-xs-12"
                    onChange={ this.onchange }
                    onKeyUp={ this.fetchCampaign }
                  />
                </div>
              </div>
              <div className="col-xs-4" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Entries (max 2000)
                  </Text.dojoP>
                  <input
                    type="number"
                    name="numEntries"
                    className="col-xs-12"
                    value={ this.state.numEntries }
                    placeholder="100"
                    onChange={ this.onchange }
                  />
                </div>
              </div>
              <div className="col-xs-4" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    CTA Ad Clicks
                  </Text.dojoP>
                  <input
                    type="number"
                    name="ctaClicks"
                    className="col-xs-12"
                    value={ this.state.ctaClicks }
                    placeholder="0"
                    onChange={ this.onchange }
                  />
                </div>
              </div>
              <div className="col-xs-4" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    % New Emails
                  </Text.dojoP>
                  <input
                    type="number"
                    name="percentNew"
                    className="col-xs-12"
                    value={ this.state.percentNew }
                    placeholder="20"
                    onChange={ this.onchange }
                  />
                </div>
              </div>
              <div className="col-xs-4" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    % Engagement
                  </Text.dojoP>
                  <input
                    type="number"
                    name="engagement"
                    className="col-xs-12"
                    value={ this.state.engagement }
                    placeholder="40"
                    onChange={ this.onchange }
                  />
                </div>
              </div>
              <PrimaryButton
                onClick={ this.seedCampaign }
                isDisabled={
                  !this.state.campaignId
                    || !this.state.foundCampaign
                    || this.state.loading
                }
                styles={[{
                  width: '220px',
                  height: '50px',
                  marginTop: '20px'
              }]}>
                Send
              </PrimaryButton>
            </div>
          </form>
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Campaign Name: { this.state.campaignName }
              </Text.dojoH3Bold>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoP>
                Status: { this.state.status }
              </Text.dojoP>
            </div>
          </div>
          {
            this.state.error
              ? <div className="row">
                  <div className="col-xs-12">
                    <Text.dojoP styles={[{ color: 'red' }]}>
                      Error: { this.state.error }
                    </Text.dojoP>
                  </div>
                </div>
              : null
          }
        </div>
      </div>
    )
  }
}

export default CustomerSuccessAdminEntrySeeder
