import React from 'react'
import propTypes from 'prop-types'
import FontSizeInput from 'util/components/font-size-input'
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'
import cn from 'classnames'
import FontFamilySelect from './formEditModal/FontFamilySelect'

const styleChoicesStyles = {
  fontSizeLabel: {
    fontFamily: 'Larsseit-Light',
    fontSize: '10px',
    lineHeight: '1',
    display: 'inline',
    color: COLOURS.ink,
  },
  textLabel: {
    fontFamily: 'Larsseit-Light',
    fontSize: '10px',
    lineHeight: '1',
    display: 'inline',
    marginBottom: '6px',
    color: COLOURS.ink,
  },
  styleChoices: {
    display: 'flex',
    marginBottom: '32px',
    justifyContent: 'space-between',
  },
  styleChoicesSolo: {
    flexWrap: 'wrap',
  },
  buttonText: {
    display: 'grid',
    width: '100%',
    marginTop: '2px',
    marginRight: '8px',
  },
  buttonTextInput: {
    height: 40,
  },
  layout: {
    flex: 1,
    marginRight: 16,
  },
  layoutSolo: {
    width: '100%',
    flex: 'none',
    marginBottom: 12,
    marginRight: 0,
  },
  lastChildLayout: {
    flex: 1,
  },

}
class StyleChoices extends React.Component {
  static propTypes = {
    attr: propTypes.string.isRequired,
    classes: propTypes.object.isRequired,
    customFonts: propTypes.array,
    elements: propTypes.object.isRequired,
    styles: propTypes.object.isRequired,
    updateAttr: propTypes.func.isRequired,
    isSoloCampaign: propTypes.bool.isRequired,
  }

  static defaultProps = {
    customFonts: [],
  }

  render() {
    const {
      attr,
      classes: css,
      elements,
      updateAttr,
      styles: { fonts: model },
      customFonts,
      isSoloCampaign,
    } = this.props

    return (
      <div className={cn(css.styleChoices, { [css.styleChoicesSolo]: isSoloCampaign })}>
        <div className={cn(css.layout, { [css.layoutSolo]: isSoloCampaign })}>
          <FontSizeInput
            attr={attr}
            updateAttr={updateAttr}
            model={model}
            elements={elements}
            placeholder={13}
            customLabelStyles={styleChoicesStyles.fontSizeLabel}
            buttonTextField
            notScrolling
          />
        </div>
        <div className={css.layout}>
          <FontFamilySelect
            attr={attr}
            updateAttr={updateAttr}
            model={model}
            dropdownAbove
            customFonts={customFonts}
          />
        </div>
        <div className={css.lastChildLayout}>
          <FontSizeInput
            attr={attr}
            updateAttr={updateAttr}
            model={model}
            elements={elements}
            placeholder={13}
            customLabelStyles={styleChoicesStyles.fontSizeLabel}
            notScrolling
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styleChoicesStyles)(StyleChoices)
