import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { SimpleForm } from 'visual-components/util/form'

import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'

import * as DealActions from 'actions/deal-actions'
import * as DealCollaborationActions from 'actions/deal-collaboration-actions'

import { H4 } from 'visual-components/util/texts'
import InputContainer from 'visual-components/util/InputContainer'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import RadioTheme from 'css/themes/Radio.css'
import inputTheme from 'css/themes/Input.css'

const reasons = [
  'The timing isn\'t right',
  'Not a good fit for our audience',
  'Price too high',
  'Other',
]

const stateToProps = ({ deals, profile, currentBrand }) => ({ deals, profile, currentBrand })

const dispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
  dealCollaborationActions: bindActionCreators(DealCollaborationActions, dispatch),
})

class DealDeclineReasonModal extends PureComponent {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    dealActions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    deals: PropTypes.object.isRequired,
  }

  state = {
    text: '',
    reasonValue: null,
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.declineOnBack()

      window.onpopstate = null
    }
  }

  onSubmit = () => {
    const {
      profile,
      deals: {
        deal: {
          deal_id,
        },
      },
      currentBrand: {
        id: brand_id,
      },
    } = this.props
    const { reasonValue, text } = this.state

    const content = reasons[reasonValue] !== 'Other' ? reasons[reasonValue] : text

    const data = {
      brand_id,
      deal_id,
      profile,
      is_decline_reason: true,
      content,
    }

    return this.submitData(data)
  }

  // Avoid mouse event
  onClose = e => this.submitData()

  submitData = (data = {}) => {
    const {
      dealActions,
      deals: {
        deal: {
          id,
        },
      },
    } = this.props

    dealActions.decline(id, data)
    dealActions.showDeclineReasonModal(false)
  }

  onInputChange = ({ target }) => this.setState({ text: target.value })

  onCheck = reasonValue => this.setState({ reasonValue })

  declineOnBack = () => {
    const { deals: { showDeclineReasonModal } } = this.props

    // If modal is showing, then decline deal
    if (showDeclineReasonModal) this.submitData()
  }

  renderRadioButtons = () => {
    const { reasonValue } = this.state

    return (
      <RadioGroup value={reasonValue} onChange={this.onCheck} style={{ marginBottom: '8px' }}>
        {
          reasons.map((reason, idx) => (
            <RadioButton
              theme={RadioTheme}
              className={RadioTheme.newStyle}
              key={reason}
              label={reason}
              value={`${idx}`}
            />
          ))
        }
      </RadioGroup>
    )
  }

  render() {
    const {
      deals: {
        showDeclineReasonModal,
      },
    } = this.props

    const { reasonValue, text } = this.state

    let canSave = reasonValue !== null

    if (reasons[reasonValue] === 'Other' && !text) canSave = false

    return (
      <MultiTaskModal
        active={showDeclineReasonModal}
        close={this.onClose}
        title="Before you go!"
      >
        <div style={{ marginBottom: '16px' }}>
          <H4 multiline>
            We are sorry that it didn’t work out this time. We will notify the seller of your decision.
          </H4>
        </div>

        <InputContainer style={{ marginBottom: '16px' }} label="Could you let the seller know what didn’t work for you?">
          <div style={{ marginTop: '8px' }}>
            <SimpleForm model={this.state} updateModel={this.onInputChange}>
              { this.renderRadioButtons() }
              {
                reasons[reasonValue] !== 'Other'
                  ? null
                  : (
                    <div className={inputTheme.textarea}>
                      <textarea
                        onChange={this.onInputChange}
                        value={text}
                        placeholder="Optional detailed feedback"
                      />
                    </div>
                  )
              }
            </SimpleForm>
          </div>
        </InputContainer>

        <ModalButtonGroup
          canSave={canSave}
          cancel={this.onClose}
          cancelText="No, Thanks"
          confirmText="Submit Feedback"
          confirm={this.onSubmit}
        />
      </MultiTaskModal>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(DealDeclineReasonModal)
