import React, { PureComponent } from 'react'
import { object } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import * as DealActions from 'actions/deal-actions'
import * as DialogActions from 'actions/dialog-actions'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import MediaItems from 'visual-components/mediaMarket/media-items'
import Spinner from 'util/components/spinner'
import statusMap from 'util/deal-status-map'

const mapState = ({ currentBrand, deals }) =>
  ({ currentBrand, deals })

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
  dealActions: bindActionCreators(DealActions, dispatch),
})

class MediaMarket extends PureComponent {
  static propTypes = {
    currentBrand: object.isRequired,
    deals: object.isRequired,
    dealActions: object.isRequired,
    history: object.isRequired,
    match: object.isRequired,
  }

  state = {
    mediaMarkets: {},
    mediaMarketToShow: [],
  }

  componentDidMount() {
    const {
      dealActions: { load },
      history,
      match: {
        url: prefix,
        params,
      },
    } = this.props
    load()
    if (!params.subtype) {
      return history.push(`${prefix}/all`)
    }

    if (!['current', 'past', 'in-discussion', 'all'].includes(params.subtype)) {
      return history.replace('/404')
    }
    return this.loadMediaMarket()
  }

  componentDidUpdate(prevProps) {
    const {
      deals,
      match: { params },
    } = this.props
    if (prevProps.deals.loading !== deals.loading || prevProps.match.url !== this.props.match.url) {
      this.loadMediaMarket()
      this.showMediaMarket(params.subtype || 'all')
    }
  }

  sellBuyMedia = type => {
    const { history, dealActions: { clearDeal } } = this.props
    let url = ''
    clearDeal()
    switch (type) {
      case 'sell':
        url = '/partnerships/deal/new'
        break
      case 'buy':
        url = '/partnerships/request/new'
        break
      case 'explore':
        url = '/app/search/media-market/sellers'
        break
      default:
        url = '/'
    }

    if (type === 'explore') {
      window.location = url
      return null
    }

    return history.push(url)
  }

  typeMediaMarket = subtype => {
    const {
      currentBrand: { id },
      deals: {
        deals,
      },
    } = this.props

    let items = deals
    let errorText = ''

    if (subtype === 'in-discussion') {
      errorText = 'You have no media market deals in discussion.'
    } else if (subtype === 'current') {
      errorText = 'You have no current media market deals.'
    } else if (subtype === 'past') {
      errorText = 'You have no past media market deals.'
    }

    items = items && items.filter(item => {
      if (subtype === 'in-discussion') {
        return ['pending', 'request'].includes(item.status) && moment().isBefore(item.end_date)
      }
      if (subtype === 'current') {
        return moment().isSameOrBefore(item.end_date) && !['pending', 'request', 'expired', 'declined'].includes(item.status)
      }
      if (subtype === 'past') {
        return moment().isAfter(item.end_date) || ['expired', 'declined'].includes(item.status)
      }

      return false
    })

    const myMedia = items && items.length ? (
      <MediaItems
        items={items}
        statusMap={statusMap}
        subtype={subtype}
        currentBrandId={id}
      />
    ) : (
      <div>
        {errorText}
      </div>
    )

    const titleMap = {
      'in-discussion': 'In Discussion',
      current: 'Current',
      past: 'Past',
    }

    const title = titleMap[subtype]

    return (
      <div>
        <PartnershipsContainer
          title={title}
          content={myMedia}
          noHeader
        />
      </div>
    )
  }

  showMediaMarket = value => {
    switch (value) {
      case 'all':
        this.setState({
          mediaMarketToShow: ['in-discussion', 'current', 'past'],
        })
        break
      default:
        this.setState({
          mediaMarketToShow: [value],
        })
    }
  }

  loadMediaMarket = () => {
    const mediaMarkets = {
      'in-discussion': this.typeMediaMarket('in-discussion'),
      current: this.typeMediaMarket('current'),
      past: this.typeMediaMarket('past'),
    }
    this.setState({
      mediaMarkets,
      mediaMarketToShow: ['in-discussion', 'current', 'past'],
    })
  }

  render() {
    const {
      currentBrand: { features: { mediaMarketSeller } },
      match: { params: { subtype } },
      deals: {
        deals: items,
        loading,
      },
    } = this.props
    // Nic: load deals on platform load for unread indicator to populate
    if (loading) return <Spinner />
    const { mediaMarketToShow, mediaMarkets } = this.state
    const errorText = !items.length ? (mediaMarketSeller ? 'Get started by sending a proposal to a potential buyer.' : 'Get started by exploring sellers on Media Market.') : null
    return (

      <div>
        <PartnershipsContainer
          title="Media Market"
          subtitle="Monetize your content by selling it through a Media Partnership."
          learnMore="https://help.dojomojo.com/hc/en-us/sections/360002049991-Media-Market-Beta"
          // action={mediaMarketSeller ? (() => { this.sellBuyMedia('sell') }) : (() => { this.sellBuyMedia('buy') })}
          // actionExplore={() => { this.sellBuyMedia('explore') }}
          actionLabel={mediaMarketSeller ? 'Create Media Sales' : 'Start RFP'}
          labelExplore="Explore"
          showFilter={subtype || 'all'}
          prefix="/partnerships/media-market"
          // errorText={errorText}
          showPartnershipButton={!items.length}
        />
        {!!items.length && mediaMarketToShow.map(key => mediaMarkets[key])}
      </div>
    )
  }
}

export default withRouter(connect(mapState, mapDispatchToProps)(MediaMarket))
