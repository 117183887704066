import _ from 'lodash'

const HashReducer = function (model, initialState) {

  const defaultState = _.extend({}, {
    rid: model,
    identifier: 'id',
    items: {},
    presentationOrder: []
  }, initialState);

  return function (state, action) {
    if (action.model !== model) {
      return state || defaultState;
    }
    const actions = {
      UPDATE_ATTR: function () {
        return _.merge({}, state, action.data);
      },
      LOADING: function () {
        return _.extend({}, state, { loading: true });
      },
      REORDER_HASH: function  () {
        var order = action.data.order;

        order = _.uniq(action.data.order);

        var presentationOrder = [];

        _.each(order, function  (id) {
          var obj = state.items[id];
          presentationOrder.push(obj);
        });

        _.each(state.items, function (item, id) {
          var inPresentation = _.find(presentationOrder, (el) => {
            if (!el) {
              return;
            }
            return el.id == parseInt(id) || el.id == id
          });
          if (!inPresentation) {
            presentationOrder.push(item);
          }
        });

        presentationOrder = _.compact(presentationOrder);

        return _.extend({}, state, { presentationOrder: presentationOrder });
      },
      LOAD_SUCCESS: function () {
        state.items = {};
        var data = _.extend({}, state, { loading: false });
        var items = action.data;
        var presentationOrder = []

        _.each(items, function (item) {
          data.items[item.id] = item;
          var extendedItem = _.extend(item, _.omit(item.data,['id']));
          var inPresentation = _.find(presentationOrder, (el) => {
            return el.id == parseInt(item.id) || el.id == item.id
          });
          if (!inPresentation) {
            presentationOrder.push( extendedItem );
          }
        });

        return _.extend({}, data, { presentationOrder: presentationOrder });
      },
      ADD_ITEM: function () {
        var data = _.extend({}, state);

        data.items[action.data[state.identifier]] = action.data;
        data.presentationOrder.push(action.data);

        return data;
      },
      UPDATE_ATTR: function () {
        var data = _.extend({}, state);

        var item = data.items[action.data.id];

        _.merge(item, action.data);

        return data;
      },
      UPDATE_ITEM: function () {
        var data = _.extend({}, state);

        var item = data.items[action.data.id];

        _.merge(item, action.data);

        return data;
      },
      REMOVE_ITEM_BY_ID: function () {
        var data = _.extend({}, state);

        var search = {};

        delete data.items[action.data.id];

        _.remove(data.presentationOrder, { id: action.data.id });

        return data;
      }
    };

    const item = actions[action.type] || function () {
      return state || defaultState;
    };

    return item();
  };
}

export default HashReducer;
