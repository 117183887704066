import React, { Component } from 'react';

import theme from 'css/themes/homePage/careers.css';

class OurValues extends Component {
  render() {
    return(
      <div className={ theme.ourValues }>
        <div className={ theme.innerVals }>
          <p className={ theme.valTitle }>
            Our Values
          </p>
          <div className={ theme.valContainer }>
            <p className={ theme.eachVal }>
              Ensure happiness to our customers.
            </p>
            <p className={ theme.eachVal }>
              Do more with less.
            </p>
            <p className={ theme.eachVal }>
              Pursue challenges and learning.
            </p>
            <p className={ theme.eachVal }>
              Be passionate and determined.
            </p>
            <p className={ theme.eachVal }>
              Build open and honest relationships.
            </p>
            <p className={ theme.eachVal }>
              Build a positive team spirit.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default OurValues;
