import React from 'react'
import { withRouter } from 'react-router-dom'
import { object, func, bool } from 'prop-types'
import TertiaryNav from 'visual-components/navBar/tertiary-nav'
import TertiaryNavTheme from 'css/themes/tertiaryNav.css'
import AgreementMap from './agreement-map'

const isDisabled = ({
  key, agreement, action, editing,
}) => {
  const {
    status,
    host_sign_id,
    partner_sign_id,
    custom_file_name,
  } = agreement

  const statusList = ['created', 'drafted', 'shared', 'partner_signed', 'completed']
  const statusIdx = statusList.indexOf(status)

  if (!action) {
    // General header diabled logic
    if (key === 'draft') return !!partner_sign_id
    if (key === 'upload' || key === 'choose') return statusIdx === 4
    if (key === 'review') return statusIdx < 1 || !!partner_sign_id
    if (key === 'sign') return statusIdx < 3 || !!host_sign_id
    if (key === 'completed') return statusIdx < 4
  }

  // Disabled logic for action button on Review page
  if (key === 'review') return statusIdx > 1 || editing

  // Disabled logic for action button on Upload page
  if (key === 'upload') return !custom_file_name

  return false
}

// Largest integer value
// https://www.postgresql.org/docs/9.1/static/datatype-numeric.html
const tooLarge = number => number > 2147483647

class AgreementHeader extends React.Component {
  static propTypes = {
    partnershipAgreement: object.isRequired,
    currentCampaign: object.isRequired,
    actions: object.isRequired,
    navigate: func.isRequired,
    openDialog: func.isRequired,
    selected: bool,
    params: object.isRequired,
    history: object.isRequired,
  }

  static defaultProps = {
    selected: false,
  }

  componentWillReceiveProps(nextProps) {
    const {
      partnershipAgreement,
      currentCampaign,
      params,
      history,
    } = nextProps

    const disabled = isDisabled({
      key: params.subSection,
      agreement: partnershipAgreement.agreement,
      editing: partnershipAgreement.editing,
    })

    if (!partnershipAgreement.loading && disabled) {
      history.replace(`/builder/${currentCampaign.id}/partnership-agreement/dashboard`)
    }
  }

  generate = () => {
    const {
      partnershipAgreement: { id, agreement },
      navigate,
      actions,
    } = this.props

    const {
      host_legal,
      host_dedicated_email,
      host_social_channels,
      host_doesnt_deliver,
      host_prizing,
      partner_dedicated_email,
      partner_social_channels,
      partner_doesnt_deliver,
      partner_min_entries,
      partner_prizing,
    } = agreement

    const hasWarning = []

    const fields = ['company_name', 'incorporation_state', 'entity_type', 'terms_url', 'address1',
      'city', 'state', 'zip', 'country']
    fields.forEach(field => {
      if (!host_legal[field]) {
        hasWarning.push(field)
      }
    })

    if (host_dedicated_email
      && (['date', 'num_emails'].some(field => !host_dedicated_email[field])
        || tooLarge(host_dedicated_email.num_emails))) {
      hasWarning.push('host_dedicated_email')
    }

    if (host_social_channels && !host_social_channels.date) {
      hasWarning.push('host_social_channels')

      if (host_social_channels.native && !host_social_channels.native_url) {
        hasWarning.push('host_native_url')
      }
    }

    if (host_doesnt_deliver
      && (['date', 'min_entries'].some(field => !host_doesnt_deliver[field])
        || tooLarge(host_doesnt_deliver.min_entries))) {
      hasWarning.push('host_doesnt_deliver')
    }

    if (host_prizing
      && (['date', 'dollar_amount', 'contribution'].some(field => !host_prizing[field])
        || tooLarge(host_prizing.dollar_amount))) {
      hasWarning.push('host_prizing')
    }

    if (partner_dedicated_email
      && (['date', 'num_emails'].some(field => !partner_dedicated_email[field])
        || tooLarge(partner_dedicated_email.num_emails))) {
      hasWarning.push('partner_dedicated_email')
    }

    if (partner_social_channels && !partner_social_channels.date) {
      hasWarning.push('partner_social_channels')

      if (partner_social_channels.native && !partner_social_channels.native_url) {
        hasWarning.push('partner_native_url')
      }
    }

    if (partner_doesnt_deliver
      && (['date', 'min_entries'].some(field => !partner_doesnt_deliver[field])
        || tooLarge(partner_doesnt_deliver.min_entries))) {
      hasWarning.push('partner_doesnt_deliver')
    }

    if (partner_min_entries
      && (!partner_min_entries.min_entries
        || tooLarge(partner_min_entries.min_entries))) {
      hasWarning.push('partner_min_entries')
    }

    if (partner_prizing
      && (['date', 'dollar_amount', 'contribution'].some(field => !partner_prizing[field])
        || tooLarge(partner_prizing.dollar_amount))) {
      hasWarning.push('partner_prizing')
    }

    if (hasWarning.length) {
      toastr.error(`Please complete the required fields to include this item in the agreement:  ${hasWarning.join(', ')}`, null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
      actions.validateDraft(hasWarning)
    } else {
      actions.generateAgreementDraft(agreement, id)
      navigate({ key: 'review' })
    }
  }

  share = () => {
    const { openDialog } = this.props
    openDialog('shareAgreementDialog')
  }

  sign = () => {
    const { actions: { openSigningModal } } = this.props
    openSigningModal()
  }

  confirmPartner = () => {
    const { openDialog } = this.props
    openDialog('confirmPartnerDialog')
  }

  confirmedPartner = () => {
    const { openDialog } = this.props
    openDialog('confirmedPartnerDialog')
  }

  render() {
    const {
      selected,
      currentCampaign,
      partnershipAgreement: { id, agreement, editing },
      navigate,
      params,
    } = this.props

    const actionMap = {
      draft: this.generate,
      review: this.share,
      sign: this.sign,
      upload: this.confirmPartner,
    }

    const availableSteps = AgreementMap.filter(item =>
      (item.key === 'dashboard' || item.key === 'choose'
        ? true
        : item[agreement.type] && !!params.partnershipId))

    let selectedStep = {}
    const steps = availableSteps.map(item => {
      if (item.key === selected) {
        selectedStep = item
        selectedStep.actionDisabled = actionMap[item.key] && isDisabled({
          key: item.key,
          agreement,
          action: true,
          editing,
        })
      }

      return {
        id: item.key !== 'dashboard' ? id : '',
        path: item.key,
        label: item.title,
        disabled: isDisabled({
          key: item.key,
          agreement,
          editing,
        }),
        navigate: () => navigate(item),
      }
    })

    return (
      <div>
        <TertiaryNav
          base={`/builder/${currentCampaign.id}/partnership-agreement`}
          options={steps}
          selected={selected}
        >
          { selectedStep.actionTitle
            ? (
              <div style={{ float: 'right' }}>
                <li
                  onClick={selectedStep.actionDisabled ? null : () => actionMap[selectedStep.key]()}
                  className={`${TertiaryNavTheme.nextButton} ${selectedStep.actionDisabled ? TertiaryNavTheme.disabled : ''}`}
                >
                  <span className={TertiaryNavTheme.nextText}>
                    { selectedStep.actionTitle }
                  </span>
                </li>
              </div>
            ) : null
          }
        </TertiaryNav>
      </div>
    )
  }
}

export default withRouter(AgreementHeader)
