import React from 'react';
import Field from 'util/components/field';
import Validator from 'util/form-validator';
import { H2 } from 'visual-components/util/texts';

class TermsInfoPartner extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div style={{
              marginBottom: '20px',
              display: 'block'
            }}>
              <H2>
                { this.props.partner.accountname }
              </H2>
            </div>

            <Field
              validations={ [Validator.required] }
              attrName={ `legalName` }
              label={ `${this.props.partner.accountname}'s legal entity name *` }
              labelStyles={{ fontSize: '16px', fontFamily: 'Larsseit-Medium', marginBottom: '16px' }}
              placeholder={ `Company Name, Inc.` }
              id={ this.props.partner.id }
              hideToastr={ true }
              dataModel={ this.props.campaignTermsDetails }
              formModel={ this.props.campaignTermsDetails }
              endpoint={ `/terms/partner-info/${this.props.partner.id}` }
            />

            <Field
              validations={ [Validator.required] }
              attrName={ `privacyPolicy` }
              label={ `${this.props.partner.accountname}'s privacy policy *` }
              labelStyles={{ fontSize: '16px', fontFamily: 'Larsseit-Medium', marginBottom: '16px' }}
              tooltip={ `A link to the general privacy policy on ${this.props.partner.accountname}'s website` }
              placeholder={ `www.companyname.com/privacypolicy` }
              id={ this.props.partner.id }
              hideToastr={ true }
              dataModel={ this.props.campaignTermsDetails }
              formModel={ this.props.campaignTermsDetails }
              endpoint={ `/terms/partner-info/${this.props.partner.id}` }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TermsInfoPartner
