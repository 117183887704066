export const DISCLAIMER_MUSTBE18 = 'TERMS: NO PURCHASE NECESSARY. ELIGIBILITY: Must be eighteen (18) or older to win. '

export const DISCLAIMER_MUSTBE21 = 'TERMS: NO PURCHASE NECESSARY. ELIGIBILITY: Must be twenty-one (21) or older to win. '


export const regExp21Disclaimer = new RegExp((DISCLAIMER_MUSTBE21).replace(/[().]/ig, '\\$&'))
export const regExp18Disclaimer = new RegExp((DISCLAIMER_MUSTBE18).replace(/[().]/ig, '\\$&'))

const STRIP_REGEX_1 = /(<.+?>)(\b.*)(<.+?>)/ig
const STRIP_REGEX_2 = /(<p.*?>)?(<span.+?><span.+?>)?(.*)(<\/span><\/span>)?(<\/p>)/ig

export const disclaimerReplacer = (value, remove = true, is21 = false) => {
  const DISCLAIMER = is21 ? DISCLAIMER_MUSTBE21 : DISCLAIMER_MUSTBE18
  if (remove) value = value.replace(is21 ? regExp21Disclaimer : regExp18Disclaimer, '')

  if (STRIP_REGEX_1.test(value)) return value.replace(STRIP_REGEX_1, `$1${DISCLAIMER}$2$3`)

  if (STRIP_REGEX_2.test(value)) return value.replace(STRIP_REGEX_2, `$1$2${DISCLAIMER}$3$4$5`)

  return `${DISCLAIMER}${value}`
}
