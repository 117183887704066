import moment from 'moment-timezone'
import { groupBy } from 'lodash'
import d3 from 'd3'

const mapData = function (realTime = [], cumulative = [], startDate, endDate) {
  const realTimeDaysGroups = groupBy(realTime, item => {
    return moment(item.period).startOf('day').format()
  })

  let daysGroups = Object.keys(realTimeDaysGroups).sort((a, b) => {
    return new Date(a) - new Date(b)
  })


  daysGroups = daysGroups.map( day => {
    return realTimeDaysGroups[day]
  })

  const realTimeDays = daysGroups.map(group => {
    const y = group.reduce((memo, item) => {
      return memo + item.y
    }, 0)

    const value = d3.format(',')(y)

    const day = moment(group[0].period).startOf('day').format()

    return {
      value,
      y,
      x: day,
      period: day,
    }
  })

  const cumulativeDaysGroups = groupBy(cumulative, item => {
    return moment(item.period).startOf('day').format()
  })

  daysGroups = Object.keys(cumulativeDaysGroups).sort((a, b) => {
    return new Date(a) - new Date(b)
  })


  daysGroups = daysGroups.map(day => cumulativeDaysGroups[day])

  const cumulativeDays = daysGroups.map(group => {
    return group.reduce((memo, item) => {
      if (moment(item.period) > moment(memo.period)) {
        return item
      }
      return memo
    }, group[0])
  })

  const bundled = {
    trendHourly: realTime,
    trendByDay: realTimeDays,
    cumulativeHourly: cumulative,
    cumulativeByDay: cumulativeDays,
  }

  return bundled
}

const getDataPoints = state => {
  const {
    selectedTab,
    cumulative,
    raw,
    startDate,
    endDate,
    timeFrame,
  } = state

  const trendType = cumulative ? 'cumulative' : 'trend'

  const timeType = ['Today', 'Yesterday'].includes(timeFrame) || Math.abs(startDate.diff(endDate, 'hours')) <= 24 ? 'hourly' : 'daily'

  const baseData = (raw[selectedTab] && raw[selectedTab][trendType])
    ? raw[selectedTab][trendType][timeType]
    : []

  let dataPoints = baseData.filter(item => {
    const date = new Date(item.period)

    return startDate._d <= date && endDate._d > date
  })

  dataPoints = dataPoints.sort((a, b) => new Date(a.period) - new Date(b.period))

  return {
    dataPoints,
    hourly: timeType === 'hourly',
  }
}

const initialState = {
  rid: 'dealAnalytics',
  loading: false,
  figures: {},
  raw: {},
  hasData: false,
  selectedTab: 'dealEmailDojoClicks',
  dataPoints: [],
  cumulative: true,
  timeFrame: 'All Time',
  startDate: new Date(),
  endDate: new Date(),
  loadingDojoEmailClicks: false,
  loadingDojoPixelClicks: false,
  loadingEspClicks: false,
  espOpenRate: '-',
  espClickRate: '-',
}

const toggleCumulative = state => {
  const {
    cumulative,
  } = state

  const newState = Object.assign({}, state, { cumulative: !cumulative })

  const {
    dataPoints,
    hourly,
  } = getDataPoints(newState)

  return {
    ...newState,
    dataPoints,
    hourly,
  }
}

const switchTab = (state, action) => {
  const newState = Object.assign({}, state, { selectedTab: action.data })

  const {
    dataPoints,
    hourly,
  } = getDataPoints(newState)

  return {
    ...newState,
    dataPoints,
    hourly,
  }
}

const updateTimeFrame = (state, action) => {
  const {
    startDate,
    endDate,
    timeFrame,
  } = action.data

  const newState = Object.assign({}, state, {
    startDate,
    endDate,
    timeFrame,
  })

  const {
    dataPoints,
    hourly,
  } = getDataPoints(newState)

  return {
    ...newState,
    dataPoints,
    hourly,
  }
}

const mapToRaw = data => {
  const {
    trendHourly,
    trendByDay,
    cumulativeHourly,
    cumulativeByDay,
  } = data

  return {
    cumulative: {
      hourly: cumulativeHourly,
      daily: cumulativeByDay,
    },
    trend: {
      hourly: trendHourly,
      daily: trendByDay,
    },
  }
}

const loadESPSuccess = (state, action) => {
  const raw = Object.assign({}, state.raw)
  ;[
    'dealESPSends',
    'dealESPOpens',
    'dealESPClicks',
  ].forEach(type => {
    raw[type] = mapToRaw(mapData(action.payload.realtime_data[type], action.payload.cumulative_data[type]))
  })

  const newState = Object.assign({}, state, { raw })

  const {
    dataPoints,
    hourly,
  } = getDataPoints(newState)

  const {
    click_rate,
    open_rate,
    total_sends,
    unique_clicks,
    unique_opens,
  } = action.payload

  return {
    ...newState,
    loading: false,
    dataPoints,
    hourly,
    espClickRate: click_rate * 100,
    espOpenRate: open_rate * 100,
    dealESPSends: total_sends,
    dealESPOpens: unique_opens,
    dealESPClicks: unique_clicks,
    loadingEspClicks: false,
  }
}

const getDealAnalyticsByIdSuccess = (state, action) => {
  const newRaw = Object.assign({}, state.raw, {
    dealEmailDojoClicks: mapToRaw(action.payload),
  })

  const newState = Object.assign({}, state, { raw: newRaw })

  const {
    dataPoints,
    hourly,
  } = getDataPoints(newState)


  const { cumulativeByDay } = action.payload

  const dealEmailDojoClicks = cumulativeByDay.length
    ? cumulativeByDay[cumulativeByDay.length - 1].y
    : 0

  const {
    price,
    max_clicks,
    min_clicks,
    cost_flat,
    cost_type,
  } = state

  let clicks = dealEmailDojoClicks > max_clicks ? max_clicks : dealEmailDojoClicks

  clicks = dealEmailDojoClicks < min_clicks ? 0 : clicks

  const totalValue = {
    CPC: clicks * price,
    Fixed: cost_flat / 1000000,
  }[cost_type]

  return {
    ...newState,
    loading: false,
    dataPoints,
    hourly,
    loadingDojoEmailClicks: false,
    dealEmailDojoClicks,
    totalValue: d3.format('$,.2f')(totalValue),
    hasData: state.hasData || !!dealEmailDojoClicks,
  }
}

const getDealAnalyticsById = (state, action) => {
  const {
    start_date,
    end_date,
    cost_type,
    min_clicks,
    max_clicks,
    id,
    price,
    cost_flat,
  } = action.data

  return {
    ...state,
    startDate: moment(start_date),
    endDate: moment(end_date),
    deal_id: id,
    loadingDojoEmailClicks: true,
    isFixedDeal: cost_type === 'Fixed',
    minMaxThreshold: {
      minThreshold: min_clicks,
      maxThreshold: max_clicks,
    },
    price,
    max_clicks,
    min_clicks,
    cost_flat,
    cost_type,
  }
}

const loadDojoPixelClicks = (state, action) => {
  const newRaw = {
    dealEmailDojoClicks: mapToRaw(action.payload)
  }

  const newState = Object.assign({}, state, { raw: newRaw })

  const {
    dataPoints,
    hourly,
  } = getDataPoints(newState)


  const { cumulativeByDay } = action.payload

  const dealEmailDojoClicks = cumulativeByDay[cumulativeByDay.length - 1].y

  const {
    price,
    max_clicks,
    min_clicks,
  } = state

  let clicks = dealEmailDojoClicks > max_clicks ? max_clicks : dealEmailDojoClicks

  clicks = dealEmailDojoClicks < min_clicks ? 0 : clicks

  const totalValue = clicks * price

  return {
    ...newState,
    loadingDojoPixelClicks: false,
    dataPoints,
    hourly,
    dealEmailDojoClicks,
    totalValue,
    hasData: state.hasData || !!clicks,
  }
}
// set default graph tab, based on deal type - email or non-email

export default function dealAnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case 'DEAL_ANALYTICS_RESET':
      return Object.assign({}, initialState)

    case 'TOGGLE_DEAL_ANALYTICS_CUMMULATIVE':
      return toggleCumulative(state, action)
    case 'SWITCH_DEAL_ANALYTICS_SELECTED_TAB':
      return switchTab(state, action)

    case 'UPDATE_DEAL_ANALYTICS_TIMEFRAME':
      return updateTimeFrame(state, action)

    case 'GET_DEAL_CLICK_ANALYTICS_BY_ID':
      return getDealAnalyticsById(state, action)

    case 'GET_DEAL_CLICK_ANALYTICS_BY_ID_SUCCESS':
      return getDealAnalyticsByIdSuccess(state, action)

    case 'GET_DEAL_ESP_ANALYTICS_SUCCESS':
      return loadESPSuccess(state, action)

    case 'GET_DEAL_ANALYTICS_ESP':
      return Object.assign({}, state, { loadingEspClicks: true })

    case 'GET_DEAL_PIXEL_ANALYTICS':
      return Object.assign({}, state, { loadingDojoEmailClicks: true })

    case 'GET_DEAL_PIXEL_ANALYTICS_SUCCESS':
      return loadDojoPixelClicks(state, action)

    case 'GET_DEAL_ANALYTICS_ESP_FAILURE':
      return Object.assign({}, state, { loadingEspClicks: false })

    case 'GET_DEAL_PIXEL_ANALYTICS_FAILURE':
      return Object.assign({}, state, { loadingDojoPixelClicks: false })

    default:
      return state
  }
}
