import React, { Component } from 'react'
import Field from 'util/components/field'
import PrimaryButton from 'util/components/buttons/primary-button'
import Validator from 'util/form-validator'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { P, H3 } from 'visual-components/util/texts'
import theme from 'css/themes/login/loginPageTheme.css'

var mapState = function(state) {
  return state;
}

var items = {
  email: [Validator.required],
  password: [Validator.required]
}

class LoginForm extends Component{
  state = {
    error_exists: false
  }

  componentDidMount() {
    var that = this;

    // listen for message from LinkedIn login pop-up window
    window.addEventListener('message', function(msg) {
      if (msg && msg.data && msg.data.linkedInSuccess) {
        that.doLogin('LinkedIn', msg.data.id);
      } else if (msg && msg.data && msg.data.linkedInSuccess === false) {
        that.setState({
          error_exists: true,
          error_msg: msg.data.error
        });
      }
    })

    let googleInterval = setInterval(_ => {
      try {
        if (window.gapi) {
          window.gapi.signin2.render('g-signin2', {
            'scope': 'profile email',
            'width': 235,
            'height': 44,
            'longtitle': true,
            'theme': 'dark',
            'onsuccess': that.loginGoogle,
            'onfailure': that.loginGoogle
          });
          clearInterval(googleInterval);
        }
      } catch (e) {
        console.log('Google sign in render failed', e)
        clearInterval(googleInterval)
      }
    }, 100);
  }

  updateAttr = (attr, value) => {
    this.model[attr] = value;
  }

  goToApp = to => {
    window.location.replace(`/app${to}`)
  }

  doLogin = (authMethod, uid) => {
    const { search, state } = this.props.location

    let replace = '/' // default

    if (state && state.to) {
      replace = state.to
    } else if (search && search.startsWith('?to=')) {
      replace = decodeURIComponent(search.slice(4))
    }

    this.goToApp(replace)
  }

  login = (e) => {
    e.preventDefault();
    var that = this;

    Validator.validateForm(items, this.props.login, 'loginForm', function(valid) {
      if (valid) {
        $.ajax({
          method: 'POST',
          url: '/login',
          data: {
            email: that.props.login.email,
            password: that.props.login.password
          },
          success: function(uid) {
            that.doLogin('Email', uid);
            // ReactRouter.browserHistory.push('/' + window.location.search);
          },
          error: function(err) {
            console.log("ERROR",err);
            that.setState({
              error_exists: true,
              error_msg: err.responseText || 'Invalid Email/Password'
            });
          }
        });
      }
    })
  }

  loginGoogle = (response) => {
    var that = this;
    if (response.error) {
      that.setState({
        error_exists: true,
        error_msg: 'The user cancelled the authorization'
      })
      return;
    }
    $.ajax({
      method: 'POST',
      url: '/login/google',
      data: {
        idtoken: response.getAuthResponse().id_token
      },
      success: function(uid) {
        that.doLogin('Google', uid);
      },
      error: function(err) {
        that.setState({
          error_exists: true,
          error_msg: err.responseText
        });
        window.gapi.auth2.getAuthInstance().signOut();
      }
    });
  }

  loginLinkedIn = () => {
    window.open('/login/linkedin', 'linkedin_window', 'height=600,width=600');
  }

  render() {
    return(
      <div id="content" className={ theme.container }>
        <Helmet>
          <title>Log in</title>
        </Helmet>
        <div className={ theme.logoRow }>
          <img src="/images/big-tiger-face-white.png"
            className={ theme.headerLogo } />
        </div>
        <div className={ theme.loginWithRow }>
          <img
            src="/images/linkedin/nr-lg-default.png"
            srcSet="/images/linkedin/r-lg-default.png 2x"
            onClick={ this.loginLinkedIn }
            alt="Log in with LinkedIn"
            className={ `abcRioButton ${theme.linkedInButton}` }
          />
          <div id="g-signin2"
            className={ theme.googleButton }
          />
        </div>
        <form onSubmit={ this.login }
          id="login-form"
          className="form"
          role="form"
        >
          <Field
            type="email"
            ref="email"
            attrName="email"
            label={ LANGUAGE.form.email }
            validations={ items.email }
            dataModel={ this.props.login }
            formModel={ this.props.loginForm }
          />
          <Field
            attrName="password"
            type="password"
            ref="password"
            validations={ items.password }
            label={ LANGUAGE.form.password }
            dataModel={ this.props.login }
            formModel={ this.props.loginForm }
            customDivStyle= {{ marginBottom: '0px'}}
          />
          {
            this.state.error_exists &&
            <div style={{marginBottom: '17px'}}>
              <H3 multiline alertRed>{ this.state.error_msg }</H3>
            </div>
          }
          <PrimaryButton type="submit"
            blueButton={ true }
            styles={[{ width: '100%' }]}
          >
            Log In
          </PrimaryButton>
        </form>
        <div className={ theme.optionRow }>
          <P>
            <a href='/app/pricing'>
              Create an account
            </a>
          </P>
          <P>
            <a href='/home/forgot'>
              Can‘t log in?
            </a>
          </P>
        </div>
        <div className={ theme.footerRow }>
          <img src="/images/DojoMojo-Wordmark-Blue.png" />
        </div>
      </div>
    )
  }
}

export default connect(mapState)(LoginForm)
