import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer';
import SwitchBrandCard from './switch-brand-card';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Button } from 'react-toolbox/lib/button';
import { H2 } from 'visual-components/util/texts';
import ExplorePageTitle from 'visual-components/shared/explore/explore-page-title';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'

import ButtonTheme from 'css/themes/Buttons.css';

const mapState = ({
  userBrands,
  currentBrand,
  conversations,
  profile: { uid: userId },
}) => ({ userBrands, currentBrand, conversations, userId })

class SwitchBrand extends PureComponent {
  createBrand = () => {
    BrowserHistoryDispatcher.push('/registrations/brand-setup');
  }

  render() {
    const { userBrands, currentBrand, history, userId } = this.props;
    const generateLink = id => history.createHref({ pathname: `/switch/${id}/${userId}` });

    const brandOptions = userBrands.brands.map(brand => (
      <a href={ generateLink(brand.id) } target="_blank" key={ brand.id }>
        <SwitchBrandCard
          brand={ brand }
        />
      </a>
    ))

    return (
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>Switch Brands</title>
        </Helmet>
        <InternalContentContainer width={ '816px'}>
          <Grid fluid
            style={{
              width: '100%',
              paddingRight: '16px', //offsets the margin in the SwitchBrandCard
              paddingLeft: '32px'
          }}>
            <Row style={{
              paddingBottom: '24px'
            }}>
              <Col xs>
                <H2 azure>
                  Your Brands
                </H2>
              </Col>
              <Col xs>
                <Button
                  onClick={ this.createBrand }
                  label={ 'Create Brand' }
                  className={ `${ButtonTheme.greenButton} ${ButtonTheme.onRightHeader}` }
                  theme={ ButtonTheme }
                  raised
                  style={{
                    marginRight: '16px'
                }}/>
              </Col>
            </Row>
            <Row>
              <Col xs>
                { brandOptions }
              </Col>
            </Row>
          </Grid>
        </InternalContentContainer>
      </div>
    )
  }
}

export default connect(mapState)(SwitchBrand);
