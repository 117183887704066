import AppDispatcher from 'dispatchers/app-dispatcher';
import moment from 'moment-timezone';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher';
import _ from 'lodash'

const determineInterval = (startDate, endDate) => {
  const daysDiff = endDate.diff(startDate, 'days');
  if (daysDiff <= 30) {
    return 'day'
  } else if (daysDiff <= 180) {
    return 'week'
  } else {
    return 'month'
  }
}

const determinePriorPeriod = (chosenStart, chosenEnd, earliestDate) => {
  return chosenStart.diff(earliestDate, 'days') >= chosenEnd.diff(chosenStart, 'days');
}

const enhanceData = (data, model) => {
  let {startDate, endDate} = data;

  if (model == 'analyticsDashboard') {
    let priorPeriodGraphs = ['dashboardEmailsAcquired', 'dashboardSocialActions'];
    let noCampaigns = store.getState().brandCampaigns.campaigns.filter(campaign => campaign.startDate < Date.now()).length == 0;
    let user = store.getState().profile;
    let fakeData = noCampaigns || !user.seenDemo;
    let createdAt = fakeData ? moment().subtract(730, 'days') : store.getState().currentBrand.createdAt;
    if (fakeData) {
      data.fakeit = true;
    }
    if (priorPeriodGraphs.includes(data.metric)) {
      data.priorPeriod = determinePriorPeriod(startDate, endDate, createdAt);
    }
  }

  if (startDate && endDate) {
    data = _.extend(data, {
      interval: determineInterval(startDate, endDate),
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    })
  }
}

// initial load of Analytics upon login
const loadDefaultDashboardAnalytics = noCampaigns => {
  const brand = store.getState().currentBrand;
  const user = store.getState().profile;
  const fakeData = noCampaigns || !user.seenDemo;
  const startDate = fakeData ? moment().subtract(730, 'days') : moment(brand.createdAt);
  const endDate = moment();
  const interval = determineInterval(startDate, endDate);
  const startDateFormatted = startDate.format('YYYY-MM-DD');
  const startDate90DaysAgoFormatted = moment().subtract(89,'days');
  const endDateFormatted = endDate.format('YYYY-MM-DD')

  const data =  [
    {
      metric: 'dashboardEmailsAcquired',
      startDate : startDateFormatted,
      endDate: endDateFormatted,
      interval,
      // priorPeriod: true,
      priorPeriod: determinePriorPeriod(startDate, endDate)
    },
    {
      metric: 'homeEmailsAcquired',
      startDate : moment(brand.createdAt).format('YYYY-MM-DD'),
      endDate: endDateFormatted,
      interval: 'month',
    },
    {
      metric: "dashboardSocialActions",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: "dashboardCampaignsLaunched",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: "homeCampaignsLaunched",
      startDate: moment(brand.createdAt).format('YYYY-MM-DD'),
      endDate: endDateFormatted,
      interval: 'month',
    },
    {
      metric: "dashboardNewPartnerships",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: "dashboardTopRegions",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    },
    {
      metric: "dashboardSocialFollower",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    },
    {
      metric: "dashboardSocialMetrics",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: "dashboardCustomCTA",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: "dashboardConversionTotals",
    },
    {
      metric: 'dashboardSmsSubscribersTotals',
    },
    {
      metric: "dashboardConversionDetails",
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: 'dashboardSmsSubscribers',
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
    {
      metric: 'dashboardSmsSummary',
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      interval,
    },
  ]

  if (fakeData) {
    _.each(data, report => {
      if (!['homeEmailsAcquired', 'homeCampaignsLaunched'].includes(report.metric)) {
        report.fakeit = true;
      }
    })
  }

  AppDispatcher.http({
    url: `/analytics/report`,
    method: 'POST',
    data: {data},
    success: (results) => {
      store.dispatch({
        type: 'LOAD_DEFAULT_DASHBOARD',
        model: 'analyticsDashboard',
        data: {
          oldestDate: startDate.toDate()
        }
      })

      store.dispatch({
        type: 'LOAD_REPORTS',
        model: 'analyticsDashboard',
        data: results
      });

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'analyticsDashboard',
        data: {
          fakeData: fakeData,
          noCampaigns: noCampaigns
        }
      })
    },
  });
}

const loadDefaultCampaignAnalytics = campaignId => {
  AppDispatcher.http({
    url: `/campaigns/${campaignId}`,
    success:  campaign => {
      let { startDate, endDate, id, name, invites, created_at } = campaign;
      let brandId = store.getState().currentBrand.id;


      let interval = determineInterval(moment(startDate), moment(endDate));
      const conversionInterval = determineInterval(moment(startDate),moment() );
      

      let currentInvite = invites.find(invite => invite.invitee_id === brandId && invite.status === 'accepted')
      let isPartner = invites && currentInvite;

      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'analyticsCampaign',
        data: {
          campaignName: name,
          campaign: campaign,
          isHost: brandId === campaign.hostBrandId,
          filterWebbula: currentInvite.filterWebbula
        }
      });

      if (!isPartner) {
        BrowserHistoryDispatcher.replace('/');
      } else {
        const data = [
          {
            metric: "campaignVisits",
            campaignId: id,
          },
          {
            metric: "campaignEntries",
            campaignId: id,
          },
          {
            metric: "campaignNTF",
            campaignId: id,
          },
          {
            metric: "campaignTotalSocialActions",
            campaignId: id,
          },
          {
            metric: "campaignEntryContributionStatsByPartner",
            campaignId: id
          },
          {
            metric: "campaignEntryContributionStatsByPartnerByPromoUnit",
            campaignId: id,
            specificBrandId: brandId
          },
          {
            metric: "campaignSocialActionsByType",
            campaignId: id,
            specificBrandId: brandId
          },
          {
            metric: "campaignCustomCTA",
            campaignId: id,
            specificBrandId: brandId
          },
          {
            metric: "campaignDemographics",
            campaignId: id
          },
          {
            metric: "campaignConversionDetails",
            campaignId: id,
            startDate: created_at,
            endDate: moment().toDate(),
            interval: conversionInterval
          },
          {
            metric: 'campaignSmsSummary',
            campaignId: id,
          },
          {
            metric: 'campaignSmsRevenueSummary',
            campaignId: id,
          },
          {
            metric: 'campaignPartnerSmsContribution',
            campaignId: id,
            startDate: created_at,
            endDate,
            interval,
          },
          {
            metric: 'campaignPartnerSmsContributionPercent',
            campaignId: id,
          },
        ]

        AppDispatcher.http({
          url: `/analytics/report`,
          method: 'POST',
          data: {data},
          success: (results) => {
            //Logic to split up 'campaignEntryContributionStatsByPartner' data into 2 reports
            let entryContributionStatsbyPartner = _.find(results, {metric: 'campaignEntryContributionStatsByPartner'})
            if (entryContributionStatsbyPartner) {
              let campaignEntryContribution = _.map(entryContributionStatsbyPartner.data, row => {
                return _.pick(row, ['brand_id', 'entries', 'entries_minimum', 'entries_perc'])
              })
              let campaignStatsByPartner =  _.map(entryContributionStatsbyPartner.data, row => {
                return _.pick(row, ['brand_id', 'brand_name', 'visits', 'entries_factor_webula', 'ntf', 'sms_subscribers'])
              })
              results.push({ metric: 'campaignEntryContribution', data: campaignEntryContribution, campaignId: entryContributionStatsbyPartner.campaignId })
              results.push({ metric: 'campaignStatsByPartner', data: campaignStatsByPartner, campaignId: entryContributionStatsbyPartner.campaignId })
            }

            //Duplicate data from "campaignSocialActionsByType" to "campaignSocialContributionByBrand"
            results.push({ metric: 'campaignSocialContributionByBrand', data: _.find(results, {metric: 'campaignSocialActionsByType'}).data.slice(0) })

            store.dispatch({
              type: 'LOAD_REPORTS',
              model: 'analyticsCampaign',
              data: results
            });

          },
        });
      }
    },
  });
}

const loadDefaultPartnershipAnalytics = (partnerBrandId) => {

  const data = [
    {
      metric: "partnershipCampaignPerformance",
      partnerBrandId: partnerBrandId,
    },
    {
      metric: "partnershipGeographicDist",
      partnerBrandId: partnerBrandId,
    },
    {
      metric: "partnershipIncomeDist",
      partnerBrandId: partnerBrandId,
    },
    {
      metric: "partnershipGenderDist",
      partnerBrandId: partnerBrandId,
    },
    {
      metric: "partnershipBasicDetails",
      partnerBrandId: partnerBrandId,
    }
  ]

  AppDispatcher.http({
    url: `/analytics/report`,
    method: 'POST',
    data: {data},
    success: (results) => {
      store.dispatch({
        type: 'LOAD_REPORTS',
        model: 'analyticsPartnership',
        data: results
      });
    },
  });
}


const fetchReport = (data, model) => {

  /*
  structure of data object:
  {
    metric: 'something',
    ...other fields
  }
  REFERENCE analytics-controller.js for required fields related to each metric
  */

  /*
  SAMPLE CALL
  let data = {
    metric: 'dashboardEmailsAcquired',
    startDate: moment('2016-01-01'),
    endDate: moment('2016-06-01'),
  }
  fetchReport(data, 'analyticsDashboard')
  */


  if (Array.isArray(data)) {
    data.forEach(report => {
      enhanceData(report, model)
    })
  } else {
    enhanceData(data, model)
  }


  AppDispatcher.http({
    url: `/analytics/report`,
    method: 'POST',
    data: {data},
    success: (results) => {
      store.dispatch({
        type: 'LOAD_REPORTS',
        model: model,
        data: results
      });
    },
  });

}



export default {
  loadDefaultDashboardAnalytics,
  loadDefaultCampaignAnalytics,
  loadDefaultPartnershipAnalytics,
  fetchReport,
};
