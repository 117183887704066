import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import {
  P, H4, H6, Small,
} from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import { Link } from 'react-router-dom'
import { makeNumbersPretty } from 'util/makeNumbersPretty'
import cn from 'classnames'
import { getClaim } from 'actions/brand-claim-actions'

const getBanner = ({
  credits, isFreeCredits, isTrial, subscriberCount,
}) => {
  const banner = {}
  banner.message = credits !== 0 ? 'You\'re "FREE" to Get Started!' : "You're out of Credits!"
  banner.info = `Balance: ${makeNumbersPretty(credits, true)} Credits`

  if (isTrial) {
    banner.planText = credits !== 0 ? 'Upgrade (Optional)' : 'Upgrade to Keep Going!'
    banner.planLink = '/upgrade-plan'
  } else {
    banner.planText = 'Select your plan to keep going'
    if (isFreeCredits && credits !== 0) {
      banner.planText = 'Pre-Select Your Plan (Optional)'
    }
    banner.planLink = '/manage-sms-marketing'
  }

  const subscriberLimit = getClaim('sms.subscribers.hardLimit')
  if (credits !== 0 && subscriberLimit && subscriberCount >= subscriberLimit) {
    banner.message = 'You\'ve hit your subscriber limit!'
    banner.info = 'Upgrade your plan to reach more subscribers.'
    banner.planText = 'Select your plan to keep going'
  }

  return banner
}

const styles = {
  outerBox: {
    backgroundColor: COLOURS.crystal,
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 35,
  },
  soloOuterBox: {
    marginBottom: 0,
  },
  warningBackground: {
    backgroundColor: COLOURS.coralLight,
  },
  leftWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& h4, p': {
      marginRight: 8,
    },
  },
  tooltip: {
    verticalAlign: 'middle',
    height: '12px',
    width: '12px',
    display: 'inline',
    backgroundColor: COLOURS.azure,
    color: 'white',
    borderRadius: '49%',
    marginRight: '4px',
    fontSize: '10px',
    lineHeight: '12px',
    padding: '3px 4px 1px 4px',
  },
}

const CreditsBanner = props => {
  const {
    classes: css,
    credits,
    isFreeCredits,
    isTrial,
    isPaidPlan,
    subscriberCount,
    isSolo,
  } = props

  if (isPaidPlan) return null

  const {
    message, info, planLink, planText,
  } = getBanner({
    credits,
    isFreeCredits,
    isTrial,
    subscriberCount,
  })
  const subscriberLimit = getClaim('sms.subscribers.hardLimit')
  const subscriberOverLimit = subscriberLimit && subscriberCount >= subscriberLimit
  const outOfCredits = credits === 0

  return (
    <div
      className={cn(css.outerBox, {
        [css.warningBackground]: (outOfCredits || subscriberOverLimit),
        [css.soloOuterBox]: isSolo,
      })}
    >
      <div className={css.leftWrapper}>
        <H4>
          <em>{message}</em>
        </H4>
        <P>{info}</P>
        {!subscriberOverLimit && (
          <P>
            <a
              href="https://help.dojomojo.com/hc/en-us/articles/360036050372"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Small azure>
                <div className={css.tooltip}>?</div>
                <em>How do credits work?</em>
              </Small>
            </a>
          </P>
        )}
      </div>
      <H6 azure>
        <Link to={planLink}>{planText}</Link>
      </H6>
    </div>
  )
}

CreditsBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  credits: PropTypes.number.isRequired,
  isFreeCredits: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  isPaidPlan: PropTypes.bool.isRequired,
  subscriberCount: PropTypes.number.isRequired,
  isSolo: PropTypes.bool,
}

CreditsBanner.defaultProps = {
  isSolo: false,
}

export default React.memo(injectSheet(styles)(CreditsBanner))
