import React, { Component } from 'react'
import { string, array, number, bool } from 'prop-types'

import { Input as InputRT } from 'react-toolbox/lib/input'
import inputTheme from 'css/themes/Input.css'

import { Micro } from 'visual-components/util/texts'
import Label from 'visual-components/util/inputs/label'
import { InputWrapper } from './wrappers'
import * as Validations from './validations'

const { maxLength } = Validations


export default class Input extends Component {
  static propTypes = {
    name: string.isRequired,
    validations: array,
    required: bool,
  }

  static defaultProps = {
    type: 'text',
    required: false,
    validations: [],
  }

  render() {
    let {
      name,
      validations,
      required,
      label,
      sublabel,
      type,
      multiline,
      rows,
      placeholder,
      tooltip,
      disabled,
      disabledLabel,
      className,
      style,
      characterLimit,
      outerClassName,
      canRemove,
      remove,
      index,
      formatNumber,
      initialValue,
      forceValue,
      percentageOf
    } = this.props

    const classNames = `${className} ${remove ? inputTheme.withTrashcan : null}`

    if (characterLimit) {
      validations = [maxLength(characterLimit), ...validations]
    }

    return (
      <InputWrapper
        name={name}
        type={type}
        required={required}
        validations={validations}
        formatNumber={formatNumber}
        render={({
          value,
          validate,
          error,
          isValidating,
          inputValidating,
          formValidating,
          formDisabled,
          onBlur,
          onFocus,
          update,
        }) => {
          const charactersRemaining =
          characterLimit - (value ? value.length : 0)
          const overLimit = charactersRemaining < 0
          let percentage
          if (percentageOf) {
            percentage = typeof value === 'number' ? value : parseInt(value.replace(/,/g,''))
            if (isNaN(percentage)) {
              percentage = 0
            }

            percentage = d3.format('%')(percentage / percentageOf)
          }
          return (
            <div
              className={outerClassName}
              style={{
                position: 'relative',
              }}
            >
              {label && (
                <Label label={label} sublabel={sublabel} tooltip={tooltip} disabledLabel={disabledLabel} />
              )}

              <InputRT
                onBlur={onBlur}
                value={forceValue || value || initialValue}
                type={type === 'number' ? 'text' : type}
                multiline={multiline || false}
                rows={rows}
                hint={placeholder}
                error={error}
                disabled={disabled || formValidating || formDisabled || inputValidating}
                onFocus={onFocus}
                onChange={update}
                theme={inputTheme}
                className={classNames}
                style={style}
              />
              {
                percentageOf &&
                <span
                  style={{
                    position: 'absolute',
                    color: '#03a9f4',
                    fontFamily: 'Larsseit-Medium',
                    top: '40px',
                    right: '10px'
                  }}>
                  {percentage}
                </span>
              }
              {canRemove && !disabled && !formDisabled ? (
                <div
                  className={inputTheme.trashIcon}
                  onClick={e => remove(index)}
                >
                  <img src="/images/icons/trash.svg" />
                </div>
              ) : null}
              {characterLimit && (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <span
                    style={{
                      float: 'right',
                      position: 'absolute',
                      bottom: '18px',
                      right: '0px',
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {overLimit ? (
                      <Micro alertRed>
                        {`${Math.abs(
                          charactersRemaining
                        )} characters over limit`}
                      </Micro>
                    ) : (
                      <Micro>
                        {`${Math.abs(
                          charactersRemaining
                        )} characters remaining`}
                      </Micro>
                    )}
                  </span>
                </div>
              )}
            </div>
          )
        }}
      />
    )
  }
}
