import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import CampaignDetailCard from 'visual-components/shared/campaign-detail-card/MyCampaignDetailCard'
import Spinner from 'util/components/spinner'

class InDiscussionCampaigns extends Component {
  static propTypes = {
    inDiscussionCampaigns: PropTypes.object.isRequired,
    signAgreement: PropTypes.func,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    signAgreement() {},
  }

  navigateTo = link => {
    const { history } = this.props
    history.push(link)
  }

  render() {
    const {
      inDiscussionCampaigns,
      signAgreement,
    } = this.props

    if (!inDiscussionCampaigns.loaded) {
      return (
        <Spinner />
      )
    }
    let errorText = ''
    if (!inDiscussionCampaigns.campaigns.length) {
      errorText = 'You have no sweepstakes in discussion.'
    }

    const campaigns = inDiscussionCampaigns.campaigns.map(campaign => {
      const actions = [{
        label: campaign.partnership_agreement_status === 'shared' ? 'Sign Agreement' : 'View Agreement',
        onClick: () => signAgreement(campaign.partnership_invite_id),
      }, {
        label: 'Preview',
        href: campaign.previewLink,
        target: '_blank',
      }]

      return (
        <CampaignDetailCard
          key={campaign.id}
          campaignId={campaign.id}
          name={campaign.name}
          campaignPictureSrc={campaign.mainImage || '/images/defaults/coverPhotoFull.png'}
          type="upcoming"
          impressions={campaign.impressions}
          estEntries={campaign.estimatedEntries}
          partnerImages={campaign.partnerImages}
          startDate={campaign.startDate}
          endDate={campaign.endDate}
          actions={actions}
          needsPartnerSignature={campaign.partnership_agreement_status === 'shared'}
          hostBrandImage={campaign.hostBrand.logo}
          style={{
            zoom: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.zoom : 100,
            x: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.x : 0,
            y: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.y : 0,
          }}
        />
      )
    })

    return (
      <div>
        <PartnershipsContainer
          title="In Discussion"
          noHeader
          errorText={errorText}
          content={campaigns}
        />
      </div>
    )
  }
}

export default InDiscussionCampaigns
