import PartnershipAgreementConstants from 'constants/partnership-agreement-constants';

const {
  CREATE_AGREEMENT_REQUEST,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,

  LOAD_AGREEMENT_REQUEST,
  LOAD_AGREEMENT_SUCCESS,
  LOAD_AGREEMENT_FAILURE,

  UPDATE_AGREEMENT_REQUEST,
  UPDATE_AGREEMENT_SUCCESS,
  UPDATE_AGREEMENT_FAILURE,

  UPDATE_LEGAL_DETAILS_REQUEST,
  UPDATE_LEGAL_DETAILS_SUCCESS,
  UPDATE_LEGAL_DETAILS_FAILURE,

  SAVE_LEGAL_DETAILS_REQUEST,
  SAVE_LEGAL_DETAILS_SUCCESS,
  SAVE_LEGAL_DETAILS_FAILURE,

  SIGN_AGREEMENT_REQUEST,
  SIGN_AGREEMENT_SUCCESS,
  SIGN_AGREEMENT_FAILURE,

  COUNTER_SIGN_AGREEMENT_REQUEST,
  COUNTER_SIGN_AGREEMENT_SUCCESS,
  COUNTER_SIGN_AGREEMENT_FAILURE,

  OPEN_SIGNING_MODAL,
  CLOSE_SIGNING_MODAL,

  UPDATE_AGREEMENT_TEMPLATE_MARKUP,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_REQUEST,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_SUCCESS,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_ERROR,

  DELETE_AGREEMENT_REQUEST,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_FAILURE,

  GENERATE_AGREEMENT_DRAFT_REQUEST,
  GENERATE_AGREEMENT_DRAFT_SUCCESS,
  SHARE_AGREEMENT_SUCCESS,
  CONFIRM_PARTNER_SUCCESS,
  CONFIRM_PARTNER_REQUEST,
  CONFIRM_PARTNER_FAILURE,

  UPDATE_AGREEMENT_ATTR,

  CLEAR_AGREEMENT,
} = PartnershipAgreementConstants;

const initialState = {
  agreement: {},
  loading: false,
  signAgreementModalOpen: false,
  agreementSignedModalOpen: false,
  campaign: null,
  campaign_id: null,
  hostBrand: null,
  hostbrand_id: null,
  editing: null,
  confirmNotSave: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_AGREEMENT_REQUEST:
    case LOAD_AGREEMENT_REQUEST:
    case GENERATE_AGREEMENT_DRAFT_REQUEST:
    case COUNTER_SIGN_AGREEMENT_REQUEST:
    case CONFIRM_PARTNER_REQUEST:
      return { ...state, loading: true };

    case CREATE_AGREEMENT_SUCCESS:
    case LOAD_AGREEMENT_SUCCESS:
    case GENERATE_AGREEMENT_DRAFT_SUCCESS:
    case SAVE_AGREEMENT_TEMPLATE_MARKUP_SUCCESS:
    case CONFIRM_PARTNER_SUCCESS:
      return { ...state, loading: false, error: false, ...action.payload };

    case CONFIRM_PARTNER_FAILURE:
    case SIGN_AGREEMENT_FAILURE:
      toastr.error(action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
      return { ...state, loading: false}

    case COUNTER_SIGN_AGREEMENT_FAILURE:
      toastr.error(action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
      return {
        ...state,
        loading: false,
        signAgreementModalOpen: false,
      }

    case SAVE_LEGAL_DETAILS_SUCCESS:
      return { ...state, ...action.payload };

    case UPDATE_AGREEMENT_SUCCESS:
      return { ...state, agreement: Object.assign({}, state.agreement, action.payload)};

    case UPDATE_LEGAL_DETAILS_SUCCESS:
      const host_legal = Object.assign({}, state.agreement.host_legal, action.payload.host_legal)
      return { ...state, agreement: Object.assign({}, state.agreement, {host_legal: host_legal})};

    case SIGN_AGREEMENT_SUCCESS:
    case COUNTER_SIGN_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreement: Object.assign(
          {},
          state.agreement,
          action.payload.agreement.agreement
        ),
        signAgreementModalOpen: false,
        agreementSignedModalOpen: true
      };

    case OPEN_SIGNING_MODAL:
      return { ...state, signAgreementModalOpen: true };
    case CLOSE_SIGNING_MODAL:
      return { ...state, signAgreementModalOpen: false, agreementSignedModalOpen: false };
    case 'VALIDATE_DRAFT':
      return { ...state, agreement: Object.assign( {}, state.agreement, { hasErrors: action.payload } ) };
    case 'UPDATE_UPLOADED_AGREEMENT':
      return { ...state, agreement: Object.assign( {}, state.agreement, action.payload ) };
    case UPDATE_AGREEMENT_TEMPLATE_MARKUP:
      return { ...state, agreement: { ...state.agreement, template_markup_draft: action.markup } }
    case CLEAR_AGREEMENT:
    case DELETE_AGREEMENT_SUCCESS:
      return initialState
    case SHARE_AGREEMENT_SUCCESS:
      return {...state, ...action.payload.agreement}
    case LOAD_AGREEMENT_FAILURE:
      return {...state, loading: false, error: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error'}
    case UPDATE_AGREEMENT_ATTR:
      return {...state, ...action.data}
    default:
      return state;
  }
}
