import React from 'react';
import theme from 'css/themes/homePage/home.css';

class Countup extends React.Component {
  state = {
    count: 0,
    Odometer: () => null,
    smsCount: 0,
  }

  componentDidMount() {
    this.setState({
      Odometer: require('react-odometerjs').default,
    })
    $.get('/total-subs-acquired', ({ emails: data, smsData }) => {
      var latestEmailCount = Number(data.email_count);
      var lastUpdated = data.last_updated || new Date();
      lastUpdated = Math.min(new Date(), Date.parse(lastUpdated))
      var secondsSinceLastUpdate = (Date.now() - lastUpdated)/1000;
      var estEmailsSinceLastUpdate = Math.round(secondsSinceLastUpdate / 3.5);

      this.setState({
        count: latestEmailCount + estEmailsSinceLastUpdate,
        smsCount: smsData.smsSubscribers,
      })

      const loop = () => {
        var rand = (Math.floor(Math.random() * 6) + 1)* 1000 //random between 1 and 6 seconds
        this.timer = setTimeout(() => {
          this.setState({
            count: this.state.count + 1
          })
          loop()
        }, rand)
      }
      loop()
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    const {
      count, smsCount,
    } = this.state
    return(
      <div className={ theme.clockWrapper }>
        <div className={theme.emailTreasureBox}>
          <img alt="Email Treasure" src="/images/login/homepage/email-treasure-box.svg" />
        </div>
        <div className={ theme.middleWrapper }>
          {/*}<div className="countdown-clock" style={{
            width: 'auto',
            display: 'inline-block'
          }} /> */}
          <this.state.Odometer
            value={ count }
            format={ ",ddd" }
            animation={ true }
          />
          <p className={ theme.totalText }>
            Total Emails Acquired
          </p>
        </div>
        <div className={theme.smsCounterContainer}>
          <div className={theme.counterBackground}>
            <div className={theme.counterTag}>NEW!</div>
            <this.state.Odometer
              value={smsCount}
              format=",ddd"
              animation
            />
            <div className={theme.counterDesc}>SMS Marketing Opt-Ins Acquired</div>
          </div>
          <img className={theme.airplaneGirl} alt="Paper Airplane" src="/images/login/homepage/paper-airplane-girl-icon.svg" />
        </div>
      </div>
    )
  }
}

export  default Countup;
