import React from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone'
import Field from 'visual-components/util/inputs/field'
import Label from 'visual-components/util/inputs/label'
import inputTheme from 'css/themes/Input.css'

class SingleDateSelect extends Field {
  state = {
    focused: false,
    date: null
  }

  onDateChange = date => {
    this.setState({ date })
    this.updateDate(date.toDate())
  }

  updateDate = date => {
    const { updateAttr, attrName, dataModel } = this.props
    this.dispatchUpdateAttr(dataModel.rid, {
      [attrName]: date
    })

    if (updateAttr) {
      updateAttr(attrName, date)
    }
  }

  onFocusChange = ({ focused }) => {
    this.setState({ focused })
  }

  render() {
    const { label, dataModel, attrName, min, max } = this.props
    const { focused } = this.state

    const date = dataModel ? moment(dataModel[attrName]) : null
    const isDayOutsideRange = day => !day.isBetween(min, max, null, '[]')

    return (
      <div>
        <Label label={ label } />

        <div className={ inputTheme.dateSelectHolder }>
          <SingleDatePicker id="date_input"
            date={ date }
            focused={ focused }
            onDateChange={ this.onDateChange }
            onFocusChange={ this.onFocusChange }
            numberOfMonths={ 1 }
            isOutsideRange={ !!min && !!max ? isDayOutsideRange : false }
            openDirection={ 'up' }
          />
        </div>
      </div>
    )
  }
}

export default SingleDateSelect;
