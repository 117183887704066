export const fetchPendingInvoiceItems = () => ({
  http: {
    url: '/pending-invoice-items',
    method: 'GET',
  },
  types: [
    'FETCH_PENDING_INVOICE_ITEMS',
    'FETCH_PENDING_INVOICE_ITEMS_SUCCESS',
    'FETCH_PENDING_INVOICE_ITEMS_FAILURE',
  ],
})

export const purchaseSocialActions = (partnershipId, quantity, isNY, selectedSource, taxRate) => ({
  quantity,
  http: {
    url: `/purchase-social-actions/${partnershipId}`,
    method: 'POST',
    data: { quantity, isNY, selectedSource, taxRate },
  },
  types: [
    'PURCHASE_SOCIAL_ACTIONS',
    'PURCHASE_SOCIAL_ACTIONS_SUCCESS',
    'PURCHASE_SOCIAL_ACTIONS_FAILURE',
  ],
})

export function resetPurchaseStore() {
  return {
    type: 'RESET_PURCHASE_STORE',
  }
}

export function showUpsellModal() {
  return {
    type: 'SHOW_UPSELL_MODAL',
  }
}

export function closeUpsellModal() {
  return {
    type: 'HIDE_UPSELL_MODAL',
  }
}

export function showPurchaseModal() {
  return {
    type: 'SHOW_PURCHASE_MODAL',
  }
}

export function closePurchaseModal() {
  return {
    type: 'HIDE_PURCHASE_MODAL',
  }
}

export function resetError() {
  return {
    type: 'SOCIAL_ACTIONS_RESET_ERROR',
  }
}
