import React from 'react';
import StepContent from 'components/campaigns/builder/step-content';
import Editor from 'util/components/editor';
import Spinner from 'util/components/spinner';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import { H2 } from 'visual-components/util/texts';
import { withRouter } from 'react-router-dom'

import EditorApp from 'editor/editor-main'

class EditContent extends React.Component{
  componentDidMount() {
    document.title = 'Edit Content - DojoMojo';
  }

  selectTemplate = () => {
    BrowserHistoryDispatcher.push(`/builder/${this.props.currentCampaign.id}/select-template`);
  }

  render() {
    var managerStyle = {
      zIndex: '1',
      position:'absolute',
      backgroundColor: 'white',
      height: '100%',
      WebkitBoxShadow: '3px 3px 8px 2px #ccc',
      MozBoxShadow: '3px 3px 8px 2px #ccc',
      boxShadow: '3px 3px 8px 2px #ccc'
    }
    var campaign = this.props.currentCampaign;
    const { currentBrand: brand } = this.props
    var copy = LANGUAGE.builder.steps.lander;

    if (campaign.loading) {
      return (
        <Spinner />
      );
    }

    return (
      <div className="container"
        style={{
          backgroundColor:'white',
          width: '100%',
          paddingLeft: '0px',
          paddingRight: '0px',
          marginTop: '155px'
        }}>
        { campaign.desktopElements && campaign.desktopElements.landerVersion ?
          <EditorApp
            campaignId={campaign.id}
            openToMobile={this.props.location.search === '?mobile'}
          />
        :
          <div style={{
            paddingTop: '200px',
            textAlign: 'center'
          }}>
            <div onClick={this.selectTemplate}
              style={{
                cursor: 'pointer'
            }}>
              <H2>
                Click Here to Select a Template
              </H2>
            </div>
          </div>
        }
      </div>
    );
  }
};

export default withRouter(EditContent)
