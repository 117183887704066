import React, { PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { Button } from 'react-toolbox/lib/button'
import { H3 } from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'
import ProfileLogo from 'visual-components/util/profile-logo'
import SmallOpportunityCard from 'visual-components/brandProfile/opportunities/small-opportunity-card'

import OpportunityTheme from 'css/themes/opportunityProfile.css'
import ButtonTheme from 'css/themes/Buttons.css'
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher'
import OpportunityDetailCard from './opportunity-detail-card'
import ShareOpportunityCard from './share-opportunity-card'

import PrizingPartnerOpportunityInfo from './prizing-partner-opportunity-info'
import DedicatedEmailOpportunityInfo from './dedicated-email-opportunity-info'
import InsertSwapOpportunityInfo from './insert-swap-opportunity-info'
import ContentSwapOpportunityInfo from './content-swap-opportunity-info'

const renderOpportunity = isPublic => opportunity => {
  return (
    <SmallOpportunityCard
      isPublic={isPublic}
      brand={opportunity}
      opportunity={opportunity}
      key={opportunity.id}
    />
  )
}

class OpportunityProfile extends PureComponent {
  static propTypes = {
    opportunityActions: PropTypes.object.isRequired,
    isPublic: PropTypes.bool,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    opportunity: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
  }

  static defaultProps = {
    isPublic: false,
  }

  componentDidMount() {
    this.loadOpportunity()
  }

  componentWillReceiveProps(nextProps) {
    const { isPublic, match: { params: { id, type } } } = this.props
    if (isPublic) {
      return
    }
    if (id !== nextProps.match.params.id || type !== nextProps.match.params.type) {
      this.loadOpportunity(nextProps.match.params)
    }
  }

  loadOpportunity = ({ id, type } = this.props.match.params) => {
    const { isPublic, opportunityActions, match } = this.props
    if (!isPublic && match.params) {
      opportunityActions.load({ id, type })
      window.scrollTo(0, 0)
    }
  }

  message = () => {
    const {
      opportunity: {
        brand: { id: brandId, accountname },
        headline,
        details,
        uniqueId: opportunityId,
      },
    } = this.props

    PricingDispatcher.check('messaging.message.create', () => {
      store.dispatch({
        model: 'conversations',
        type: 'POPOUT_MESSAGE_BRAND',
        data: {
          brandId,
          brandName: accountname,
          title: `Re: [${headline}]`,
          initialContent: `<br /><br />--------------------------------------------------------------------------------------------------<br /><p>${accountname}: ${headline}</p><br /><p>${details}</p>`,
          opportunityId,
        },
      })
    }, brandId)
  }

  signup = () => { window.location.href = '/app/register' }

  viewProfile = () => {
    const { history, isPublic, opportunity: { brand: { id } } } = this.props
    if (isPublic) {
      // TODO: forces reload, investigate if worth it to set up publicly
      // accessible endpoints to fetch brand info between in-app page requests
      window.location.href = `/home/brand/${id}`
    } else {
      history.push(`/explore/brands/${id}`)
    }
  }

  renderRecommended = () => {
    const {
      opportunity,
      isPublic,
      currentBrand: { recommendedOpportunities },
    } = this.props

    const recommended = recommendedOpportunities
      .filter(({ id, type }) => id != opportunity.id || type != opportunity.type)
      .slice(0, 3)
      .map(renderOpportunity(isPublic))

    if (!recommended || !recommended.length) {
      return null
    }

    return (
      <Row style={{ marginTop: '40px' }}>
        <Col xs={12}>
          <H3 coral>Recommended Opportunities</H3>
        </Col>
        <Col
          xs={12}
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '16px'
          }}
        >
          {recommended}
        </Col>
      </Row>
    )
  }

  render() {
    const {
      opportunity,
      opportunityActions,
      currentBrand,
      isPublic,
    } = this.props

    if (
      !opportunity
      || opportunity.loading
      || (!opportunity.error && !opportunity.brand)
      || (!isPublic && (!currentBrand || currentBrand.loading))
    ) {
      return <Spinner />
    }

    if (opportunity.error && !isPublic) {
      return window.location.replace('/app/search/opportunities')
    }

    const { brand, type } = opportunity

    brand.tags = brand.tagsconnected.map(({ name }) => name)

    const isBrand = !isPublic && currentBrand.id === brand.id

    const OpportunityInfo = {
      prizing_partner: PrizingPartnerOpportunityInfo,
      dedicated_email_buy: DedicatedEmailOpportunityInfo,
      dedicated_email_sell: DedicatedEmailOpportunityInfo,
      product_shipment_insert: InsertSwapOpportunityInfo,
      content_swap: ContentSwapOpportunityInfo,
    }[type] || (() => null)

    return (
      <InternalContentContainer width="1096px">
        <Grid style={{ marginTop: '16px' }}>
          <Row>
            <Col style={{ marginRight: '16px', width: '216px' }}>
              <div>
                <ProfileLogo imgSrc={brand.logo} accountname={brand.accountname} />
              </div>
              {
                !isBrand
                  ? (
                    <div>
                      <Button
                        onClick={isPublic ? this.signup : this.message}
                        label="Message"
                        className={ButtonTheme.greenButton}
                        theme={ButtonTheme}
                        raised
                        style={{
                          fontFamily: 'Larsseit-Medium',
                          fontSize: '14px',
                          width: '87px',
                          height: '36px',
                        }}
                      />
                      <Button
                        onClick={this.viewProfile}
                        label="View Profile"
                        className={ButtonTheme.whiteButton}
                        theme={ButtonTheme}
                        raised
                        style={{
                          fontFamily: 'Larsseit-Medium',
                          fontSize: '14px',
                          width: '108px',
                          height: '36px',
                          marginLeft: '16px',
                        }}
                      />
                    </div>
                  ) : null
              }
              <div className={OpportunityTheme.shareCardContainer}>
                <ShareOpportunityCard opportunity={opportunity} isPublic={isPublic} />
              </div>
            </Col>
            <Col>
              <OpportunityDetailCard opportunity={opportunity} opportunityActions={opportunityActions} showPen={isBrand} />
              <OpportunityInfo opportunity={opportunity} brand={brand} obscure={isPublic} showPen={isBrand} />
            </Col>
          </Row>
          { this.renderRecommended() }
        </Grid>
      </InternalContentContainer>
    )
  }
}

export default OpportunityProfile
