import React, { Component } from 'react';
import Header from '../header/Header';

import theme from 'css/themes/homePage/careers.css';

class CareersBanner extends Component {
  render() {
    return(
      <div className={ theme.careersBanner }>
        <Header />
        <div className={ theme.content }>
          <p className={ theme.title }>
            Careers at DojoMojo
          </p>
          <div className={ theme.centerImg }>
            <img src="/images/big-tiger-face-white.png" />
          </div>
          <p className={ theme.para }>
            We built DojoMojo to solve our own challenges as startup founders. Now, we’re solving major marketing challenges for top brands and leveling the playing field for startups looking to grow their audience.
          </p>
          <p className={ `${theme.para} ${theme.secondPara}` }>
            At DojoMojo, you’ll be surrounded by a fast moving, open minded and dedicated team of entrepreneurs building powerful software from the ground up. If you thrive in the face of new challenges, want to grow professionally and personally and enjoy being surrounded by a fun and hardworking team, we’d love to meet you!
          </p>
        </div>
      </div>
    )
  }
}

export default CareersBanner;
