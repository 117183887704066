export default {
  female: 'Female',
  male: 'Male',
  both: 'Both',
  '0_18': '< 18',
  '19_25': '19 - 25',
  '26_35': '26 - 35',
  '36_45': '36 - 45',
  '46_55': '46 - 55',
  '56_200': '56+',
}
