import React from 'react';
import { renderToString } from 'react-dom/server'
import { P, Tiny } from 'visual-components/util/texts';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import Donut from 'visual-components/util/graphs/donut';

class GenderDistributionGraph extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { data } = this.props

    if (data[0] && nextProps.data[0] && (data[0].y !== nextProps.data[0].y)) {
      return true
    }
    return false
  }

  render() {
    const {
      data,
      percentHoverOnly,
    } = this.props
    return (
      <div>
        <Grid>
          <Row style={{
            textAlign: 'center'
          }}>
            <Col style={{
              width: '32px',
              marginLeft: '8px'
            }}>
              <div style={{
                marginBottom: '12px'
              }}>
                <Tiny>
                  Male
                </Tiny>
              </div>
              <img src={ '/images/icons/male.svg' }
                style={{
                  width: '100%',
                  height: '64px'
                }}/>
            </Col>
            <Col style={{
              width: '186px',
              marginTop: '-17px',
              position: 'relative'
            }}>
              <Donut items={data} colorMap={{}} percentHoverOnly={percentHoverOnly} />
            </Col>
            <Col style={{
              width: '32px'
            }}>
              <div style={{
                marginBottom: '12px'
              }}>
                <Tiny>
                  Female
                </Tiny>
              </div>
              <img src={ 'images/icons/female.svg' }
                style={{
                  width: '32px',
                  height: '64px'
                }}/>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default GenderDistributionGraph;
