import React from 'react'

import Field from 'visual-components/util/inputs/field'
import Validator from 'util/form-validator'

const validations = {
  ctaVideo: [Validator.required]
}

class CtaVideo extends React.Component {
  render() {
    return (
      <div>
        <Field { ...this.props }
          attrName={ 'ctaVideo' }
          label={ 'YouTube Channel URL' }
          placeholder={ 'YouTube Channel URL' }
          validations={ validations.ctaVideo }
        />
      </div>
    )
  }
}

export default CtaVideo
