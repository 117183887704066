import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Text from 'util/components/text';
import InviteMessage from './messaging-thread-item-invite';
import NormalMessage from './messaging-thread-item-normal';

class MessageThreadItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { message, collapse } = this.props

    if (message.messageType !== 'normal') {
      return (
        <InviteMessage key={message.id} {...this.props}  />
      )
    } else {
      return (
        <NormalMessage key={message.id} {...this.props} />
      )
    }
  }
}

export default MessageThreadItem