import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import { H6, Small } from 'visual-components/util/texts'
import RangeSlider from 'util/components/templates/form/range-slider'
import UploadImage from 'components/util/editor-uploader'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import { camelCase } from 'lodash'
import EditModalWrapper from './EditModalWrapper'

const styles = {
  hr: {
    marginLeft: -24,
    marginRight: -24,
  },
  uploadImage: {
    marginTop: 10,
  },
  uploadButton: {
    color: '#03a9f4 !important', //brand blue
  },
}

const LogoEditModal = props => {
  const {
    classes: css,
    onClose,
    updateAttr,
    maxHeight,
    campaignId,
    editName,
    modalPosition,
    horizontalAlign,
    onDelete,
  } = props

  const updateLogoSize = (attr, value) => {
    const data = {
      [camelCase(editName)]: {
        [attr]: Number(value),
      },
    }

    updateAttr('styles', data, false)
  }

  const onUpload = uploadRes => {
    updateAttr(editName.replace(/-/g, '_'), uploadRes.src)
  }

  const incrementSize = attr => {
    updateLogoSize(attr, Math.min(maxHeight + 1, 200))
  }

  const decrementSize = attr => {
    updateLogoSize(attr, Math.max(maxHeight - 1, 50))
  }

  return (
    <EditModalWrapper
      onSave={onClose}
      onDelete={onDelete}
      modalPosition={modalPosition}
      horizontalAlign={horizontalAlign}
    >
      <div>
        <div className={css.uploadImage}>
          <UploadImage
            success={onUpload}
            action={`/campaign-photo/${campaignId}/${editName}`}
            name="campaign-main-image"
            acceptedFiles="image/jpeg,image/png"
          >
            <Button
              theme={buttonTheme}
              className={cn(buttonTheme.button, css.uploadButton)}
              label="Select Image"
            />
          </UploadImage>
        </div>
        <hr className={css.hr} />
        <H6>
          Image Zoom
        </H6>
        <RangeSlider
          attr="height"
          value={maxHeight}
          min="50"
          max="200"
          step="1"
          updateValue={updateLogoSize}
          incrementValue={incrementSize}
          decrementValue={decrementSize}
          leftButton={<img src="images/builder/icons/minus.svg" />}
          rightButton={<img src="images/builder/icons/plus.svg" />}
        />
        <hr className={css.hr} />
      </div>
    </EditModalWrapper>
  )
}

LogoEditModal.propTypes = {
  classes: propTypes.object.isRequired,
  campaignId: propTypes.number.isRequired,
  onClose: propTypes.func.isRequired,
  updateAttr: propTypes.func.isRequired,
  maxHeight: propTypes.number.isRequired,
  editName: propTypes.string.isRequired,
  modalPosition: propTypes.oneOf(['top', 'bottom']).isRequired,
  horizontalAlign: propTypes.string.isRequired,
  onDelete: propTypes.func,
}

LogoEditModal.defaultProps = {
  onDelete: null,
}

export default injectSheet(styles)(LogoEditModal)
