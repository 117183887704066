import React from 'react'
import { Button } from 'react-toolbox/lib/button/Button'

import { Link } from 'react-router-dom'

import theme from 'css/themes/mobile/home/banner.css'
import ButtonTheme from 'css/themes/Buttons.css'
import BaseBanner from '../util/banner-base'
import MobileHeader from '../mobile-header/MobileHeader'

const renderButton = () => (
  <div className={theme.buttonWrapper}>
    <a href="/app/register">
      <Button
        label="Start Messaging For Free"
        className={ButtonTheme.orangeButton}
        theme={ButtonTheme}
        floating
      />
    </a>
  </div>
)

const SmsMarketingBanner = () => (
  <div id={theme.smsMarketing}>
    <MobileHeader />
    <BaseBanner
      title="SMS Marketing"
      imgSrc="/images/login/homepage/sms-marketing.png"
      about="Tackle today’s greatest marketing challenge  – cutting through the noise. With DojoMojo’s SMS solutions, compliment your acquisition funnel with personalized, targeted messages to reach your customers directly and drive more conversions, faster."
      children={renderButton()}
    />
  </div>
)

export default SmsMarketingBanner
