import React from 'react'
import { Button } from 'react-toolbox/lib/button'
import { Link } from 'react-router-dom'

const LinkedButton = ({ to, replace, ...rest }) => (
  <Link to={ to } replace={ replace }>
    <Button { ...rest } />
  </Link>
)

export default LinkedButton
