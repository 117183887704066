import React from 'react'
import { connect } from 'react-redux'
import Spinner from 'util/components/spinner'
import { withRouter, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import Theme from 'css/themes/roiCalculatorTheme.css'

import * as ROICalculatorActions from 'actions/roi-calculator-actions'

import ExpandableCalculator from './expandable_calculator/ExpandableCalculator'
import ROIHeader from './ROIHeader'
import ROISliderCards from './ROISliderCards'
import ROIFooter from './ROIFooter'
import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'

const mapStateToProps = ({ roiCalculator, currentBrand, dataIntegrations }) =>
  ({ roiCalculator, currentBrand, dataIntegrations })

const mapDispatchToProps = dispatch => ({
  roiCalculatorActions: bindActionCreators(ROICalculatorActions, dispatch),
})


class ROICalculator extends React.PureComponent {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    roiCalculatorActions: PropTypes.object.isRequired,
    roiCalculator: PropTypes.object.isRequired,
  }

  state = { loading: true }

  checkIfLoaded = () => {
    const { currentBrand } = this.props

    let loading = true

    if (currentBrand) {
      loading = currentBrand.loading || currentBrand.featuresLoading
    }

    return this.setState({ loading })
  }

  componentDidMount = () => {
    const { roiCalculatorActions, location } = this.props
    roiCalculatorActions.getROIValues()
    this.checkIfLoaded()

    const page_title = this.page_title
    gtagPageTrack({ page_title, location })
  }

  componentDidUpdate = prevProps => {
    const { currentBrand } = this.props
    // If nothing changed, then dont unnecessarily set state
    if (
      currentBrand
      && currentBrand.loading === prevProps.currentBrand.loading
      && currentBrand.featuresLoading === prevProps.currentBrand.featuresLoading
    ) return null

    return this.checkIfLoaded()
  }

  page_title = 'Calculate ROI'

  render() {
    const { currentBrand, roiCalculator } = this.props
    const { loading } = this.state

    if (loading || !currentBrand || roiCalculator.loading) return <Spinner />

    if (!currentBrand.features.roiCalculator && currentBrand.belt !== 'white') return <Redirect to="/" />

    return (
      <div>
        <ROIHeader />
        <div className={Theme.body}>
          <ROISliderCards />
          <ExpandableCalculator />
        </div>
        <ROIFooter />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ROICalculator))
