import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { Small } from 'visual-components/util/texts';
import CampaignStatPreview from './campaign-stat-preview';
import CurrentCampaignCardTheme from 'css/themes/CurrentCampaignCard.css';


class CampaignSpecs extends Component {
  constructor(props) {
    super(props);
  }

  viewAnalytics = (campaignId) => {
    const { history } = this.props;
    history.push(`/analytics/campaigns/${campaignId}`);
  };

  render() {
    const {
      totalEntries,
      estEntries,
      ntfEmails,
      startDate,
      endDate,
      campaignId,
    } = this.props;

    const started = moment().isAfter(startDate)

    return(
      <Grid>
        <Row>
          <Col className={ CurrentCampaignCardTheme.campaignSpecDates }>
            <img src="images/date-and-time.svg" />
            <div style={{
              paddingTop: '1px',
              width: '152px',
              marginLeft: '6px'
            }}>
              <Small>
                {`${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`}
              </Small>
            </div>
          </Col>
        </Row>

        <Row style={{
          paddingTop: '10px',
          paddingBottom: '6px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '218px',
          paddingLeft: '6px',
        }}>
          <CampaignStatPreview
            name="est. entries"
            data={estEntries}
          />
          <CampaignStatPreview
            name="total entries"
            data={started ? totalEntries : 0}
          />
          <CampaignStatPreview
            name="NTF emails"
            data={started ? ntfEmails : 0}
          />
        </Row>

        <Row>
          <div className={ CurrentCampaignCardTheme.seeAnalyticsContainer }>
            <a onClick={ () => this.viewAnalytics(campaignId) }>
                See analytics
            </a>
          </div>
        </Row>
      </Grid>
    );
  }
}

CampaignSpecs.propTypes = {
  estEntries: PropTypes.number.isRequired,
  totalEntries: PropTypes.number.isRequired,
  ntfEmails: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  campaignId: PropTypes.number.isRequired
};

export default withRouter(CampaignSpecs);
