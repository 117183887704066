import React, { PureComponent } from 'react'
import { object, bool } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { merge } from 'lodash'
import { SimpleForm } from 'visual-components/util/form'
import { Tiny } from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'
import CardDispatcher from 'dispatchers/card-dispatcher'

import * as DealActions from 'actions/deal-actions'
import * as DealCollaborationActions from 'actions/deal-collaboration-actions'
import * as MetricsActions from 'actions/analytics-actions'

import FullscreenModal from 'visual-components/util/modals/FullscreenModal'
import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import MediaMarketAcceptTOU from 'visual-components/mediaMarket/confirmMedia/MediaMarketAcceptTOU'
import MediaInformation from './mediaInformation'
import PaymentInformation from './paymentInformation'
import ConfirmNote from './confirm-note'

import EditStatusBar from '../createNewMedia/StatusBar'

const mapStateToProps = ({
  currentBrand,
  currentBrand: { timezone, id: currentBrandId },
  customer,
  card,
  deals,
  profile,
}) => ({
  currentBrand,
  timezone,
  currentBrandId,
  customer,
  card,
  deals,
  profile,
  isSeller: !Object.keys(deals.deal).length || currentBrandId === deals.deal.selling_brand_id,
})

const mapDispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
  dealCollaborationActions: bindActionCreators(DealCollaborationActions, dispatch),
  metricActions: bindActionCreators(MetricsActions, dispatch),
})

let targetLinkTransitionKey = 0

class ConfirmMedia extends PureComponent {
  static propTypes = {
    deals: object.isRequired,
    dealActions: object.isRequired,
    isSeller: bool.isRequired,
    currentBrand: object.isRequired,
    metricActions: object.isRequired,
    dealCollaborationActions: object.isRequired,
    history: object.isRequired,
    match: object.isRequired,
  }

  state = {
    step: 0,
    targets: [{
      label: 'Label',
      key: targetLinkTransitionKey++,
    }],
    showMediaTerms: false,
  }

  constructor(props) {
    super(props)

    const {
      match: { params: { dealId } },
      deals: { deal },
      dealActions: { loadById },
    } = props

    if (dealId && (!deal || !Object.keys(deal).length)) {
      loadById(dealId)
    }
  }

  componentDidMount() {
    const {
      deals: { deal },
    } = this.props

    window.scrollTo(0, 0)

    if (deal && deal.targets && deal.targets.length) {
      this.setState({ targets: deal.targets })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      deals: {
        deal,
        successfulAction: { id, type } = {},
      },
      currentBrandId,
      profile,
      dealCollaborationActions: { setStatusDialog, sendMessage },
      history,
    } = this.props

    const {
      deals: {
        deal: prevDeal,
        successfulAction: { id: prevId } = {},
      },
    } = prevProps

    const { note } = this.state

    if ((!prevDeal && deal && deal.targets && deal.targets.length)
      || (!prevDeal.targets && deal.targets && deal.targets.length)) {
      this.setState({ targets: deal.targets })
    }

    if ((!prevId && id) || prevId !== id) {
      history.push(`/partnerships/deal/${id}/collaborate`)

      if (note) {
        sendMessage({
          deal_id: id,
          content: `<p>${note}</p>`,
          profile,
          brand_id: currentBrandId,
        })
      }

      if (type === 'create') {
        setStatusDialog('created')
      } else if (type === 'edit') {
        toastr.success('Your offer has been updated.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      } else if (type === 'accept') {
        setStatusDialog('accepted')
      } else if (type === 'counter') {
        setStatusDialog('counter')
      }
    }
  }

  onSubmit = async () => {
    let { customer: { selectedSource } } = this.props
    const {
      match: { params: { type: confirmType } },
      card: { adding },
      deals: { deal, tempProposal },
      dealActions: {
        loading,
        finished,
        create,
        confirm,
        counter,
      },
      metricActions,
    } = this.props

    const {
      step,
      targets,
    } = this.state

    // Close Media Market ToS
    this.setState({ showMediaTerms: false })

    // Seller Create
    if (confirmType === 'create') {
      delete tempProposal.buying_brand_accountname

      create(tempProposal)
      metricActions.create('mediaDealSentOffer')
    }

    // Buyer Accept/Counter Step 0
    if (['accept', 'buyer-counter'].includes(confirmType) && step === 0) {
      this.goToStep(1)
    }

    // Buyer Accept Step 1
    if (confirmType === 'accept' && step === 1) {
      loading()

      if (adding) {
        try {
          selectedSource = await CardDispatcher.updateBilling()
        } catch (e) {
          return finished()
        }
      }

      confirm(deal.id, { targets, selectedSource })
    }

    // Edit
    if (confirmType === 'edit') {
      counter(deal.id, tempProposal, 'edit')
    }

    // Counter
    if (confirmType === 'seller-counter' || (confirmType === 'buyer-counter' && step === 1)) {
      if (confirmType === 'buyer-counter') {
        loading()
        if (adding) {
          try {
            selectedSource = await CardDispatcher.updateBilling()
          } catch (e) {
            return finished()
          }
        }
      }

      counter(deal.id, Object.assign(tempProposal, { targets, selectedSource }), 'counter')
    }
  }

  back = () => {
    const {
      match: { params: { type: confirmType } },
      deals: { deal },
      dealActions: { updateAttr },
      history,
    } = this.props

    const { step } = this.state

    if (step === 0) {
      let location
      switch (confirmType) {
        case 'create':
          location = '/partnerships/deal/new'
          break
        case 'edit':
          location = `/partnerships/deal/${deal.id}/edit`
          break
        case 'seller-counter':
        case 'buyer-counter':
          updateAttr({ editProposalActive: 'counter' })
          location = `/partnerships/deal/${deal.id}/edit`
          break
        default:
          location = `/partnerships/deal/${deal.id}/collaborate`
          break
      }

      history.push(location)
    } else if (step === 1) {
      this.goToStep(0)
    }
  }

  goToStep = step => this.setState({ step })

  closeAll = () => {
    const {
      deals: { deal },
      dealActions: { clearTempProposal },
      history,
    } = this.props

    clearTempProposal()

    if (deal && !!Object.keys(deal).length) {
      return history.push(`/partnerships/deal/${deal.id}/collaborate`)
    }

    return history.push('/partnerships/media-market/current')
  }

  updateModel = e => this.setState(prevState => merge({}, prevState, e))

  addLink = () => {
    const { targets } = this.state
    const temp = [...targets]
    temp.push({
      label: `Label ${targets.length + 1}`,
      key: targetLinkTransitionKey++,
    })
    this.setState({
      targets: temp,
    })
  }

  editLink = idx => {
    const { targets } = this.state
    const temp = [...targets]
    temp.splice(idx, 1, { ...temp[idx], editing: !temp[idx].editing })
    this.setState({
      targets: temp,
    })
  }

  removeLink = idx => {
    const { targets } = this.state
    const temp = [...targets]
    temp.splice(idx, 1)
    this.setState({
      targets: temp,
    })
  }

  getCreateTitle = (tempProposal, deal) => {
    if (tempProposal && tempProposal.buying_brand) {
      return tempProposal.buying_brand_accountname
    }

    return deal.buying_brand.accountname || ''
  }

  confirmTerms = async () => {
    const {
      currentBrand: {
        has_accepted_media_market_seller_latest_terms,
        has_accepted_media_market_buyer_latest_terms,
      },
      isSeller,
      match: { params: { type: confirmType } },
    } = this.props

    const { step } = this.state

    const buyerHasntAccepted = !isSeller && !has_accepted_media_market_buyer_latest_terms
    const sellerHasntAccepted = isSeller && !has_accepted_media_market_seller_latest_terms

    // Buyer (step 1)
    if ((['accept', 'buyer-counter'].includes(confirmType) && buyerHasntAccepted && step)
    // Seller (step 0)
      || (['create', 'edit', 'seller-counter'].includes(confirmType) && sellerHasntAccepted && !step)
    ) {
      return this.setState({ showMediaTerms: true })
    }

    return this.onSubmit()
  }

  renderAgreementStatement = () => {
    const {
      currentBrand: {
        has_accepted_media_market_seller_latest_terms,
        has_accepted_media_market_buyer_latest_terms,
      },
      isSeller,
      match: { params: { type: confirmType } },
    } = this.props

    const { step } = this.state

    if (isSeller && has_accepted_media_market_seller_latest_terms) {
      const text = (
        <div style={{ textAlign: 'right', marginTop: '12px' }}>
          <Tiny multiline>
            By clicking “Send Offer”, you are agreeing to our&nbsp;
            <Link to="/profile/brand/agreements" target="_blank">
              Publisher Agreement
            </Link>
            &nbsp;and that this offer is your “insert order” to the advertiser.
          </Tiny>
        </div>
      )

      return text
    }

    if (!isSeller && has_accepted_media_market_buyer_latest_terms && step === 1) {
      const text = (
        <div style={{ textAlign: 'right', marginTop: '12px' }}>
          <Tiny multiline>
            By clicking &quot;
            { confirmType === 'accept' ? 'Purchase Media' : 'Send Offer'}
            &quot;, you are agreeing to our&nbsp;
            <Link to="/profile/brand/agreements" target="_blank">
              Advertiser Agreement
            </Link>
            .
          </Tiny>
        </div>
      )

      return text
    }

    return null
  }

  render() {
    const {
      deals: {
        error,
        loading,
        deal,
        deal: {
          status: dealStatus,
        },
        tempProposal,
      },
      isSeller,
      dealActions: { resetError },
      match: { params: { type: confirmType } },
    } = this.props

    const { step, targets, showMediaTerms } = this.state

    if (confirmType !== 'create'
      && (!deal || !Object.keys(deal).length)
      && (!tempProposal || !Object.keys(tempProposal).length)
    ) {
      return null
    }

    const confirmMap = {
      create: {
        title: 'Review Media Sale Details',
        statusBars: [{
          title: `Proposal for ${
            tempProposal && tempProposal.buying_brand_accountname
              ? tempProposal.buying_brand_accountname
              : dealStatus === 'request'
                ? deal.buying_brand.accountname
                : ''
          }`,
          proposal: tempProposal,
        }],
        components: [
          ConfirmNote,
        ],
        confirmButtons: [
          'Send Offer',
        ],
      },
      accept: {
        title: 'Confirm Purchase Details',
        statusBars: [{
          title: `Current Offer from ${deal.selling_brand && deal.selling_brand.accountname}`,
          proposal: deal.currentProposal,
          background: 'blue',
        }],
        components: [
          MediaInformation,
          PaymentInformation,
        ],
        confirmButtons: [
          'Submit Details and Add Payment Info',
          'Confirm Details and Submit',
        ],
      },
      edit: {
        title: 'Review Media Sale Details',
        statusBars: [{
          title: 'Your Counter-Offer',
          proposal: tempProposal,
        }, {
          title: 'Your Current Offer',
          proposal: deal.currentProposal,
          background: 'blue',
          hideUpdated: true,
        }],
        components: [
          ConfirmNote,
        ],
        confirmButtons: [
          'Send Offer',
        ],
      },
      'buyer-counter': {
        title: 'Confirm Purchase Details',
        statusBars: [{
          title: 'Your Counter-Offer',
          proposal: tempProposal,
        }, {
          title: `Current Offer from ${deal.selling_brand && deal.selling_brand.accountname}`,
          proposal: deal.currentProposal,
          background: 'blue',
          hideUpdated: true,
        }],
        components: [
          MediaInformation,
          PaymentInformation,
        ],
        confirmButtons: [
          'Submit Details and Add Payment Info',
          'Send Offer',
        ],
      },
      'seller-counter': {
        title: 'Confirm Counter-Offer',
        statusBars: [{
          title: 'Your Counter-Offer',
          proposal: tempProposal,
        }, {
          title: `Current Offer from ${deal.buying_brand && deal.buying_brand.accountname}`,
          proposal: deal.currentProposal,
          background: 'blue',
          hideUpdated: true,
        }],
        components: [
          ConfirmNote,
        ],
        confirmButtons: [
          'Send Offer',
        ],
      },
    }

    const confirmPage = confirmMap[dealStatus === 'request' ? 'create' : confirmType]
    const Component = confirmPage.components[step]

    const linkActions = {
      addLink: this.addLink,
      editLink: this.editLink,
      removeLink: this.removeLink,
    }

    if (!Component) return null

    const statusBars = confirmPage.statusBars.map(bar => (
      <EditStatusBar key={bar.title} {...bar} />
    ))

    const whichTOU = isSeller
      ? 'publisher'
      : 'advertiser'

    return (
      <React.Fragment>
        <FullscreenModal
          title={confirmPage.title}
          close={this.closeAll}
        >
          {loading && <Spinner />}

          <div className={FullscreenModalTheme.statusBars}>
            {statusBars}
          </div>

          <SimpleForm
            model={this.state}
            updateModel={this.updateModel}
            handleSubmit={this.confirmTerms}
          >
            <Component
              deal={deal}
              proposal={tempProposal}
              targets={targets}
              linkActions={linkActions}
              error={error}
              loading={loading}
              confirmType={confirmType}
              resetError={resetError}
            />

            <div className={FullscreenModalTheme.buttonGroup}>
              <ModalButtonGroupWithContext
                cancel={this.back}
                cancelText="Back"
                confirmText={confirmPage.confirmButtons[step]}
                canSave
                hideLine
              />
              {
                this.renderAgreementStatement()
              }
            </div>
          </SimpleForm>
        </FullscreenModal>
        {
          showMediaTerms
            ? (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 100,
                  height: '100%',
                  width: '100%',
                  paddingTop: '60px',
                }}
              >
                <MediaMarketAcceptTOU confirmType={confirmType} onBtnClick={this.onSubmit} which={whichTOU} />
              </div>
            ) : null
        }
      </React.Fragment>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmMedia))
