import React from 'react'
import { array } from 'prop-types'

import TableTheme from 'css/themes/analytics-tables/partnershipTable.css'

import PartnershipTableHeader from './partnership-table-header'
import PartnershipAnalyticsTableRow from './partnership-analytics-table-row'

class PartnershipAnalyticsTable extends React.Component {
  static propTypes = {
    partnerships: array.isRequired,
  }

  render() {
    const { partnerships } = this.props

    const partnershipRows = partnerships.map(partnership => (
      <PartnershipAnalyticsTableRow key={partnership.partner_brand_id} partnership={partnership} />
    ))

    return (
      <div>
        <table
          id="partnership-analytics-results-table"
          className={TableTheme.analyticsTable}
        >
          <thead>
            <PartnershipTableHeader />
          </thead>
          <tbody className={TableTheme.analyticsTbody}>
            { partnershipRows }
          </tbody>
        </table>
      </div>
    )
  }
}

export default PartnershipAnalyticsTable
