import { PureComponent } from 'react'
import { array } from 'prop-types'
import d3 from 'd3'
import { Card } from 'react-toolbox/lib/card'
import {
  H2, H3, H6, P, Small,
} from 'visual-components/util/texts'
import Tooltip from 'util/components/tooltip'
import PartnerLogo from 'visual-components/shared/campaign-detail-card/CurrentCampaignCard/partner-logos'

class PartnerItem extends PureComponent {
  render() {
    const {
      logo, name, host, taskCompletionPct,
    } = this.props
    return (
      <div
        style={{
          borderTop: '1px solid #d8d8d8',
          paddingTop: '12px',
          paddingBottom: '12px',
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '57px',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ marginRight: '4px' }}>
            <PartnerLogo logo={logo} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <Small>
              <i>{name}</i>
            </Small>
            <Small>{host ? 'Host' : 'Partner'}</Small>
          </div>
        </div>
        <H2 azure>{d3.format('%')(taskCompletionPct)}</H2>
      </div>
    )
  }
}

export default class PartnerPerformanceCard extends PureComponent {
  static propTypes = {
    partners: array.isRequired,
  }

  render() {
    const { partners } = this.props
    const partnerItems = partners && partners.length
      ? partners.map(({
        logo, name, host, taskCompletionPct,
      }, idx) => (
        <PartnerItem
          key={idx}
          logo={logo}
          name={name}
          host={host}
          taskCompletionPct={taskCompletionPct}
        />
      ))
      : null

    return (
      <div
        style={{
          maxWidth: '384px',
          position: 'relative',
        }}
      >
        <Card
          style={{
            width: '100%',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div style={{ height: '76px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '20px',
                paddingBottom: '16px',
                paddingTop: '16px',
              }}
            >
              <div style={{ paddingRight: '8px' }}>
                <H3 coral>
                  <small>Partner Performance</small>
                </H3>
              </div>
              <Tooltip title="Progress is only visible for partners who mark tasks completed on the DojoMojo Calendar." />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '12px',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              <H6 style={{ display: 'inline-block' }}>Brand</H6>
              <H6 style={{ display: 'inline-block' }}>Tasks Complete</H6>
            </div>
          </div>
          {partnerItems}
        </Card>
      </div>
    )
  }
}
