/*
Opens for Partnership Agreement editing

*/

import React from 'react'
import { string } from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P, H3, H4 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import Editor from 'util/components/terms-editor';
import { Helmet } from 'react-helmet';

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'

var highlight = function (str) {
  str = (str || '').replace('{{{socialSharingBlock}}}', '{{socialSharingBlock}}')
  return str.replace(/{{([A-Za-z_]+)}}/g, '<strong><span style="background-color: #e5f3fd;">{{$1}}</span></strong>');
}

var unHighlight = function (str) {
  str = (str || '').replace(/<strong><span style="background-color: #e5f3fd;"(\sdata-mce-style="background-color: #e5f3fd;")?>/g, '')
  str = str.replace(/<\/span><\/strong>/g, '')

  str = str.replace('{{socialSharingBlock}}', '{{{socialSharingBlock}}}');

  return str;
}

class Review extends React.Component {
  static contextTypes = {
    impersonating: string,
  }

  state = {
    showWarningModal: false,
    draft: '',
    originalMatches: [],
  }

  componentDidMount() {
    const { partnershipAgreement, actions, match: { params }, history } = this.props

    if (partnershipAgreement.error) {
      history.replace(`/builder/${params.campaignId}/partnership-agreement/dashboard`)
    }

    if (!partnershipAgreement.agreement.id) {
      actions.loadPartnershipAgreement(params.partnershipId)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { partnershipAgreement, actions } = this.props
    const currentMatches = partnershipAgreement && partnershipAgreement.agreement && partnershipAgreement.agreement.template_markup && partnershipAgreement.agreement.template_markup.match(/{{[A-Za-z_]+}}/g) || []
    if (prevState.originalMatches.length !== currentMatches.length) {
      this.setState({
        originalMatches: currentMatches
      })
    }
  }

  get pdfURL() {
    const {
      partnershipAgreement: { id },
      currentBrand: { id: currentBrandId },
    } = this.props
    const { impersonating } = this.context
    return `/partnership-agreement/pdf/${currentBrandId}/${id}${impersonating ? `?impersonating=${impersonating}` : ''}`
  }

  save = () => {
    const { partnershipAgreement: { id, agreement: { template_markup_draft } }, actions } = this.props
    actions.saveTemplateMarkup({
      partnershipId: id,
      template_markup: template_markup_draft
    })

    actions.updateAttributes({
      editing: false
    })

    toastr.success('Changes saved to this agreement. The partner will now see the updated agreement', null, { timeOut: 5000, positionClass: 'toast-bottom-center' });
  }

  toggleEdit = () => {
    const {
      template_markup,
      template_markup_draft
    } = this.props.partnershipAgreement.agreement

    const { editing } = this.props.partnershipAgreement
    const { updateAttributes } = this.props.actions

    if (!editing) {
      updateAttributes({
        editing: true
      })
    } else if (template_markup_draft && template_markup_draft !== template_markup)  {
      updateAttributes({
        confirmNotSave: true
      })
    } else {
      updateAttributes({
        editing: false
      })
    }
  }

  closeExitEditWarningModal = () => {
    const { updateAttributes } = this.props.actions

    updateAttributes({
      confirmNotSave: false
    })
  }

  exitEdit = () => {
    const { updateAttributes } = this.props.actions
    updateAttributes({
      editing: false,
      confirmNotSave: false
    })
  }

  updateAnyWay = () => {
    const { partnershipAgreement, actions } = this.props

    actions.updatePartnershipAgreement({
      ignore_bracket_invalid: true
    }, partnershipAgreement.id)

    actions.updateTemplateMarkup(this.state.draft)

    this.setState({
      showWarningModal: false
    })
  }

  revertBrackets = () => {
    const { partnershipAgreement } = this.props

    tinyMCE.activeEditor.setContent(highlight(partnershipAgreement.agreement.template_markup))

    this.setState({
      showWarningModal: false
    })
  }

  updateAttr = (attr, value) => {
    const { partnershipAgreement, actions } = this.props

    value = unHighlight(value)
    let originalMatches = this.state.originalMatches
    let matches = value.match(/{{[A-Za-z_]+}}/g) || []

    if (!partnershipAgreement.agreement.ignore_bracket_invalid && (matches.length !== originalMatches.length || _.difference(originalMatches, matches).length !== 0)) {
      this.setState({
        showWarningModal: true,
        draft: value
      })
    } else {
      actions.updateTemplateMarkup(value)
    }
  }

  render() {
    const {
      currentCampaign,
      partnershipAgreement,
      actions
    } = this.props

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    const {
      agreement: {
        rendered_html,
        template_markup
      },
      invitee: {
        accountname: partnerBrandName
      },
      campaign: {
        name: campaignName
      },
      editing,
      confirmNotSave
    } = partnershipAgreement

    return (
      <div>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Arizonia"
            rel="stylesheet"
          />
          <link
            href="/css/agreement.css"
            rel="stylesheet"
          />
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '40px'
          }}>
            <Col style={{
              width: '760px',
              marginRight: '115px'
            }}>
              <div style={{
                marginBottom: '24px'
              }}>
                <P multiline>
                  Carefully review this Partnership Agreement. Editing highlighted fields on this page will not automatically update those fields. Please go back to the <b>Draft</b> page to make any necessary changes.
                  <br />
                  When you have completed the required fields and are ready to share it, click <b>Share</b> and we will send this agreement to your Partner for review and signature. If the Partner brand’s corporate information wasn’t specified, we'll ask your Partner to provide it before they sign.
                  <br />
                  <u><b>Warning:</b></u> When you click <b>Share</b>, you’ll have made a legally binding offer, which your Partner can accept and thus form a legally binding agreement with you. <b>You cannot edit this agreement once the Partner signs it.</b>
                </P>
              </div>
            </Col>
            <Col style={{
              width: '265px'
            }}>
              <Button
                href={this.pdfURL}
                target="_blank"
                theme={ buttonTheme }
                className={ buttonTheme.whiteButton }
                label={ 'Download PDF' }
                raised
                style={{
                  marginRight: '12px'
              }}/>
              <Button onClick={ this.toggleEdit }
                theme={ buttonTheme }
                className={ buttonTheme.greenButton }
                label={ editing ? 'Exit Edit Mode' : 'Edit Agreement' }
                raised
              />
            </Col>
          </Row>
          <Row>
            <H3>
              Partnership Agreement with {partnerBrandName} for {campaignName}
            </H3>
          </Row>
          <Row>
            <div style={{
              marginTop: '32px',
              width: '100%'
            }}>
              { editing ?
                <Editor
                  { ...this.props }
                  updateAttr={ this.updateAttr }
                  attr="template_markup"
                  save={ this.save }
                  height={ 350 }
                  originalContent={ highlight(template_markup) }/>
              :
                <div style={{
                  borderWidth: '1px',
                  borderColor: '#dee4e5',
                  borderStyle: 'solid',
                  padding: '40px'
                }}
                  dangerouslySetInnerHTML={ { __html: rendered_html }} >
                </div>
              }
            </div>
          </Row>
        </Grid>
        <DefaultDialog
          active={this.state.showWarningModal}
          closeModal={this.revertBrackets}
          title={'Warning!'}
          confirm={this.updateAnyWay}
          confirmText={'Edit Anyways'}
          cancel={this.revertBrackets}
          cancelText={'Cancel'}
          bannerImgSrc={ '/images/icons/celebration-icon.svg'}
          children={
            <div>
              <div className={`${dialogTheme.bannerWrapper} ${dialogTheme.orangeBanner}`}>
                <img src="/images/icons/error-message-icon.svg" />
              </div>
              <H4 multiline>
                {`Editing the {{bracketed}} fields in this page breaks DojoMojo's ability to automatically update the text if you change any key terms in the future. To edit these fields, exit Edit Mode and do so in the Draft page on the previous screen.`}
              </H4>
            </div>
          }
        />
        <DefaultDialog
          active={confirmNotSave}
          closeModal={this.closeExitEditWarningModal}
          title={'Warning! Changes Not Saved'}
          confirm={this.exitEdit}
          confirmText={'Yes, exit'}
          cancel={this.closeExitEditWarningModal}
          cancelText={'Cancel'}
          bannerImgSrc=""
          children={
            <P>
              Your changes to this Partnership Agreement have not been saved. Are you sure you want to exit edit mode?
            </P>
          }
        />
      </div>
    )
  }
}

export default withRouter(Review)
