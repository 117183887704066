import React, { PureComponent } from 'react'
import injectCSS from 'react-jss'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cn from 'classnames'

import { Input } from 'react-toolbox/lib/input'
import COLOURS from 'util/colours'

import Label from 'visual-components/util/inputs/label'
import { Micro } from 'visual-components/util/texts'
import InputValidator from 'util/input-validator'

import inputTheme from 'css/themes/Input.css'

const styles = {
  input: {
    '& [class*=error]': {
      visibility: 'hidden', // Hides RT error label (still need the wrapper height)
    },
  },
  errorBorder: {
    '& input': {
      borderColor: `${COLOURS.warningRed} !important`,
      marginTop: 1,
    },
  },
  sublabel: {
    position: 'relative',
    marginBottom: 19,
    marginTop: -28,
    display: 'flex',
  },
}

class FormlessField extends PureComponent {
  state = {
    blurred: true,
    value: '',
  }

  inputRef = React.createRef()

  validate = _.debounce(() => {
    const { value } = this.state
    let { validations = [] } = this.props
    const {
      characterLimit, dirty, name,
    } = this.props

    validations = characterLimit
      ? validations.concat(InputValidator.MaxLength(characterLimit))
      : validations

    let errorToSet = ''

    for (let i = 0; i < validations.length; i++) {
      const error = validations[i](value)

      if (error) {
        errorToSet = error
        break
      }
    }

    if (dirty) this._onError(name, errorToSet)
  }, 10)

  _onIdle = this.props.onIdle

  _onError = this.props.onError

  componentDidMount() {
    const {
      value, controlled, onChange, idleTime, onError,
    } = this.props

    this.setState({ value })

    // WIP
    // if (!controlled) this.inputRef.current.value = value
    this._onIdle = _.debounce(onChange, idleTime)
    this._onError = _.debounce(onError, idleTime)
  }

  // // Forces error if `error` prop is passed on
  componentDidUpdate(prevProps) {
    const { error, name, onError } = this.props
    if (prevProps.error !== error && onError) {
      // const { error: stateError } = this.state
      // this.setState({ error: error && stateError }, () => onError(name, error))
      // eslint-disable-next-line react/no-did-update-set-state
      // this.setState({ error }, () => onError(name, error))
      this._onError(name, error)
    }
  }

  persist = () => {
    const { value } = this.state

    this.validate()
    this._onIdle(value)
  }

  blur = e => this.setState({ blurred: true }, () => {
    const { onBlur } = this.props
    const { value } = this.state

    this.persist()
    onBlur(value, e)
  })

  focus = () => this.setState({ blurred: false })

  onChange = oldValue => {
    const { formatter } = this.props

    const value = formatter(oldValue)

    this.setState({ value }, this.persist)
  }

  render() {
    const {
      label,
      sublabel,
      placeholder,
      tooltip,
      disabled,
      style,
      sublabelStyle,
      characterLimit,
      className,
      classes: css,
      error,
      // controlled, // WIP -> https://github.com/react-toolbox/react-toolbox/issues/810
    } = this.props

    const { blurred, value } = this.state

    const charactersRemaining = characterLimit ? characterLimit - value.length : 0

    const overLimit = charactersRemaining < 0

    return (
      <div style={{ position: 'relative' }}>
        {
          label
            ? <Label label={label} sublabel={sublabel} tooltip={tooltip} sublabelStyle={sublabelStyle} />
            : null
        }
        <Input
          // ref={this.inputRef}
          // WIP
          // hint={controlled ? placeholder : value ? null : placeholder}
          // value={controlled ? value : null}
          hint={placeholder}
          value={value}
          error={blurred && error}
          disabled={disabled}
          onBlur={this.blur}
          onFocus={this.focus}
          onChange={this.onChange}
          theme={inputTheme}
          style={style}
          className={cn('formlessfield-input', css.input, { [className]: !!className, [css.errorBorder]: error })}
        />
        {
          (error || characterLimit)
          && (
            <div className={cn('formlessfield-sublabel', css.sublabel)}>
              <div style={{ flex: 1 }}>
                {
                  error && (
                    <Micro alertRed={!!error}>
                      {error}
                    </Micro>
                  )
                }
              </div>
              {
                characterLimit && (
                <Micro alertRed={!!error}>
                  {`${Math.abs(charactersRemaining)} ${overLimit ? 'characters over limit' : 'characters remaining'}` }
                </Micro>
                )
              }
            </div>
          )
        }
      </div>
    )
  }
}

FormlessField.propTypes = {
  controlled: PropTypes.bool,
  sublabel: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  sublabelStyle: PropTypes.object,
  characterLimit: PropTypes.number,
  idleTime: PropTypes.number,

  onChange: PropTypes.func,
  onIdle: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  formatter: PropTypes.func,
  validations: PropTypes.array,
  value: PropTypes.string.isRequired,
}

FormlessField.defaultProps = {
  controlled: true,
  sublabelStyle: {},
  validations: [],
  className: '',
  sublabel: '',
  label: '',
  placeholder: '',
  tooltip: '',
  disabled: false,
  dirty: false,
  style: {},
  characterLimit: null,
  onBlur() {},
  onChange() {},
  onError() {},
  onIdle() {},

  idleTime: 2500,
  error: '',
  formatter: value => value,
}

export default injectCSS(styles)(FormlessField)
