import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { Checkbox } from 'react-toolbox'
import cn from 'classnames'

import colors from 'util/colours'
import { P } from 'visual-components/util/texts'

import CheckboxTheme from 'css/themes/Checkbox.css'
import SmsMarketingBreakdownItems from './sms-marketing-breakdown-items'
import SmsPlansDropdown from './sms-plans-dropdown'

const styles = {
  container: {
    border: `1px solid ${colors.messagingGrey}`,
    borderRadius: 2,
    marginBottom: 32,
    marginTop: -32,
  },
  wrapper: {
    padding: '24px 16px',
  },
  form: {
    backgroundColor: colors.messagingGrey,
  },
  label: {
    fontWeight: '500',
  },
  checkbox: {
    '& label > input + div': { // checkbox/check element
      marginRight: 8,
      marginTop: 4,
    },
  },
}

const NewTabIcon = () => (
  <svg style={{ margin: '0 0 -2px 4px' }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.72667L3.17333 7.88667L4.11333 8.82667L10.6667 2.27333V4.66667H12V0H7.33333Z" fill="#03A9F4" />
  </svg>
)

const SMS_KNOWLEDGE_BASE = 'https://help.dojomojo.com/hc/en-us/articles/360036050372-SMS-Pricing-Information'

function AddSmsMarketingPlan(props) {
  const {
    classes: css,
    withSmsPlan,
    setWithSmsPlan,
    selectedPlan,
    selectedSmsPlan,
    smsPricing,
  } = props

  return (
    <div className={css.container}>
      <div className={cn(css.wrapper, css.form)}>
        <div className={css.checkbox}>
          <Checkbox
            onChange={setWithSmsPlan}
            checked={withSmsPlan}
            theme={CheckboxTheme}
            className={CheckboxTheme.asLabel}
          >
            <div className={css.label}>
              <P multiline>
                <em>
                  Add an SMS Marketing plan.
                  {' '}
                  <a href={SMS_KNOWLEDGE_BASE} rel="noopener noreferrer" target="_blank">
                    Learn More
                    <NewTabIcon />
                  </a>
                </em>
              </P>
            </div>
          </Checkbox>
        </div>
        { withSmsPlan && (
          <SmsPlansDropdown
            smsPricing={smsPricing}
            withSublabel
            selectedSmsPlan={selectedSmsPlan}
          />
        )}
      </div>
      {
        withSmsPlan && (
          <div className={css.wrapper}>
            <SmsMarketingBreakdownItems plan={selectedPlan} />
          </div>
        )
      }
    </div>
  )
}

AddSmsMarketingPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  smsPricing: PropTypes.number.isRequired,
  withSmsPlan: PropTypes.bool.isRequired,
  setWithSmsPlan: PropTypes.func.isRequired,
  selectedSmsPlan: PropTypes.number.isRequired,
  selectedPlan: PropTypes.string.isRequired,
}

export default React.memo(injectCSS(styles)(AddSmsMarketingPlan))
