import { Component } from 'react'
import { string, func } from 'prop-types'
import { connect } from 'react-redux'

class CheckNewVersion extends Component {
  static propTypes = {
    dispatch: func.isRequired,
    currentVersion: string.isRequired,
  }

  componentDidMount() {
    // if can't set currentVersion, abort
    const { currentVersion } = this.props
    try {
      localStorage.setItem('dojo-release', currentVersion)
    } catch (e) {
      return
    }

    // check for new version every 5 minutes, only when window focused
    window.onfocus = () => {
      this.checkVersion()
      this.versionCheckInterval = setInterval(this.checkVersion.bind(this), 1000 * 60 * 5)
    }
    window.onblur = () => clearInterval(this.versionCheckInterval)
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    clearInterval(this.versionCheckInterval)
    window.onfocus = null
    window.onblur = null
  }

  static get currentVersion() {
    try {
      return localStorage.getItem('dojo-release') || 'first-time'
    } catch (e) {
      // if localStorage not supported, don't notify
      return false
    }
  }

  checkVersion() {
    $.ajax({
      url: '/release',
      method: 'GET',
      success: newVersion => {
        const { currentVersion } = CheckNewVersion
        if (currentVersion && newVersion !== currentVersion) {
          const { dispatch } = this.props
          dispatch({
            type: 'LOAD_NEW_NOTIFICATION',
            data: {
              text: 'A new version of DojoMojo is available. Please refresh your browser for the best experience!',
              id: 'DOJO_NEW_VERSION_NOTIFICATION',
              newVersion,
            },
          })
        }
      },
    })
  }

  render() {
    return null
  }
}

export default connect(null)(CheckNewVersion)
