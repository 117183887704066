import React from 'react';
import COLOURS from 'util/colours';
import { H4, P } from 'visual-components/util/texts';

class BrandCardLogo extends React.Component{

  render() {
    let baseStyles = [{
        padding: '50px 30px 30px 30px',
        height: '250px',
        marginBottom: '20px',
        borderStyle: 'solid',
        borderColor: COLOURS.inputBorder,
        borderWidth: '1px',
        borderRadius: '2px'
      }]

    let styles = Object.assign({}, ...baseStyles, this.props.styles);

    return (
      <div
        style={ styles }
        className="card switch-brand-card" >
        <H4>
          { this.props.upcaseName }
        </H4>
        { this.props.cardLogo ?
          <div style={{
            height: '140px',
            lineHeight: '140px'
          }}>
            <img style={{
                maxWidth:'100%',
                maxHeight:'100%'
              }}
              src={ this.props.cardLogo } />
          </div>
        :
          <P>
            { LANGUAGE.registerBrandPageOne.logoText }
          </P>
        }
      </div>
    );
  }
};

export default BrandCardLogo;
