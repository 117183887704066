import _ from 'lodash';
import ReferralConstants from 'constants/referral-constants';

const {
  GMAIL_CONTACTS_SUCCESS,
  REFERRAL_INVITES_SUCCESS,
  CLOSE_GMAIL_IMPORT_MODAL,
  SELECT_CONTACT,
  SEARCH_IMPORTED_CONTACTS,
  TOGGLE_SELECT_ALL_CONTACTS,
  REFERRAL_REMINDER_SUCCESS,
  ADD_EMAIL_TO_INVITE_INPUT_REQUEST,
  ADD_EMAIL_TO_INVITE_INPUT_SUCCESS,
  ADD_EMAIL_TO_INVITE_INPUT_FAILURE,
  REMOVE_EMAIL_FROM_INVITE_INPUT,
  INVITE_INVALID_EMAIL_INPUT,
  VALIDATING_EMAIL_INPUT,
  YOUR_REFERRALS_SUCCESS,
  RAFFLE_WINNER_SUCCESS,
  REFERRAL_UNREAD_COUNT_SUCCESS,
  MARK_ALL_REFERRAL_READ,
  REFERRAL_GMAIL_INVITES_SUCCESS,
  REFERRAL_GMAIL_INVITES_FAILURE,
  REFERRAL_INVITES_FAILURE,
  OPEN_REMINDER_MODAL,
  CLOSE_REMINDER_MODAL,
  NEW_REFERRAL_JOINED

} = ReferralConstants;

const initialState = {
  contactImport: {
    contactList: [],
    filteredContactList: null,
    selectedContacts: [],
    allSelected: false,
    modalOpen: false,
  },
  emailsToInvite: [],
  emailValidation: {
    valid: true,
    message: null
  },
  yourReferrals: [],
  lastWinnerName: null,
  nextDrawingDate: null,
  unreadCount: 0,
  reminderModal: {
    modalOpen: false,
    referralId: null
  }
};

const getContactsSearchResults = (contacts, query) => {
  const lcQuery = query.toLowerCase();
  return contacts.filter(
    contact =>
      contact.email.toLowerCase().indexOf(lcQuery) >= 0 ||
      contact.name.toLowerCase().indexOf(lcQuery) >= 0
  );
};

export default function referralsReducer(state = initialState, action) {
  switch (action.type) {


    case OPEN_REMINDER_MODAL: {
      const { referral } = action;
      return {
        ...state,
        reminderModal: {
          modalOpen: true,
          referral
        }
      }
    }

    case CLOSE_REMINDER_MODAL: {
      return {
        ...state,
        reminderModal: {
          modalOpen: false,
          referral: null
        }
      }
    }

    case GMAIL_CONTACTS_SUCCESS: {
      return {
        ...state,
        contactImport: {
          ...state.contactImport,
          modalOpen: true,
          contactList: action.payload,
        },
      };
    }

    case REFERRAL_GMAIL_INVITES_FAILURE: {
      toastr.error('We were unable to process your referrals because one or more of the emails contained an invalid domain', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });

      return state
    }

    case REFERRAL_INVITES_FAILURE: {
      toastr.error('We were unable to process your referrals because one or more of the emails contained an invalid domain', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });

      return state
    }

    case REFERRAL_INVITES_SUCCESS: {
      toastr.success('Your invitation has been sent.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      return {
        ...state,
        emailsToInvite: [],
        yourReferrals: [...state.yourReferrals, ...action.payload]
      }
    }

    case REFERRAL_GMAIL_INVITES_SUCCESS: {
      toastr.info('Your invitation has been sent.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      const { selectedContacts} = state.contactImport;
      selectedContacts.forEach(contact => {
        contact.status = 'Already Invited'
      })

      return {
        ...state,
        contactImport: {
          ...state.contactImport,
          allSelected: false,
          filteredContactList: null,
          selectedContacts: []
        }
      }
    }

    case REFERRAL_REMINDER_SUCCESS: {
      toastr.success('Your reminder has been sent.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      const id = action.referralId;
      const yourReferrals = state.yourReferrals.map(item => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          reminder_sent: true
        }
      })

      return {
        ...state,
        yourReferrals,
        reminderModal: {
          modalOpen: false,
          referral: null
        }
      }
    }

    case CLOSE_GMAIL_IMPORT_MODAL: {
      return {
        ...state,
        contactImport: initialState.contactImport,
      };
    }

    case SELECT_CONTACT: {
      const { selectedContacts, ...rest } = state.contactImport;
      const wasSelected = _.find(selectedContacts, action.contact);
      const newSelectedContacts = wasSelected
        ? selectedContacts.filter(
            contact => !_.isEqual(contact, action.contact)
          )
        : selectedContacts.concat([action.contact]);
      return {
        ...state,
        contactImport: {
          ...rest,
          selectedContacts: newSelectedContacts,
        },
      };
    }

    case SEARCH_IMPORTED_CONTACTS: {
      return {
        ...state,
        contactImport: {
          ...state.contactImport,
          filteredContactList: getContactsSearchResults(
            state.contactImport.contactList,
            action.query
          ),
        },
      };
    }

    case TOGGLE_SELECT_ALL_CONTACTS: {
      const allSelected = !state.contactImport.allSelected;
      return {
        ...state,
        contactImport: {
          ...state.contactImport,
          allSelected,
          selectedContacts: allSelected ? state.contactImport.contactList.filter(contact => contact.status === null) : [],
        },
      };
    }

    case ADD_EMAIL_TO_INVITE_INPUT_REQUEST:
    case VALIDATING_EMAIL_INPUT: {
      return {
        ...state,
        emailValidation: {
          valid: true,
          message: null
        }
      }
    }

    case ADD_EMAIL_TO_INVITE_INPUT_FAILURE: {
      return {
        ...state,
        emailValidation: {
          valid: false,
          message: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error',
        }
      }
    }

    case ADD_EMAIL_TO_INVITE_INPUT_SUCCESS: {
      return {
        ...state,
        emailValidation: {
          valid: true,
          message: null
        },
        emailsToInvite: [...state.emailsToInvite, action.email]
      }
    }

    case INVITE_INVALID_EMAIL_INPUT: {
      return {
        ...state,
        emailValidation: {
          valid: false,
          message: action.errorMessage
        }
      }
    }

    case REMOVE_EMAIL_FROM_INVITE_INPUT: {
      return {
        ...state,
        emailsToInvite: state.emailsToInvite.filter(email => email !== action.email)
      };
    }

    case YOUR_REFERRALS_SUCCESS: {
      return {
        ...state,
        yourReferrals: action.payload
      }
    }

    case RAFFLE_WINNER_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case REFERRAL_UNREAD_COUNT_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case MARK_ALL_REFERRAL_READ: {
      return {
        ...state,
        unreadCount: 0
      }
    }

    case NEW_REFERRAL_JOINED: {
      return {
        ...state,
        unreadCount: state.unreadCount+1,
      }
    }

    default:
      return state;
  }
}
