import React, { Component } from 'react'
import { Menu, MenuItem } from 'react-toolbox/lib/menu'
import { Link } from 'react-router-dom'
import { func, bool } from 'prop-types'
import cn from 'classnames'

import { getClaim } from 'actions/brand-claim-actions'

import * as metrics from 'util/metrics'

import { P, Small } from 'visual-components/util/texts'
import SlimNavBarDropdown from 'css/themes/SlimNavBarDropdown.css'

class CreateDropdownMenu extends Component {
  static propTypes = {
    onClose: func.isRequired,
    open: bool.isRequired,
    canViewSignupForm: bool.isRequired,
  }

  render() {
    const { onClose, open, canViewSignupForm } = this.props
    const canCreatePartnershipSweepstakes = getClaim('sweepstakes.partnership.create')
    const canCreateSoloSweepstakes = getClaim('sweepstakes.solo.create')

    // to: redirect to legacy app
    // href: redirect to future app
    const createNewMenuItems = [
      {
        label: 'Solo Sweepstakes',
        secondary: 'Engage leads and scale your audience',
        to: canCreateSoloSweepstakes && '/new-solo-sweepstakes',
        href: !canCreateSoloSweepstakes && '/app/upgrade-plan',
        imgSrc: '/images/nav-bar/create-new/solo-sweeps-icon.svg',
        locked: !canCreateSoloSweepstakes,
      },
      {
        label: 'Partnership Sweepstakes',
        secondary: 'Partner up and expand your audience reach',
        to: canCreatePartnershipSweepstakes && '/new-campaign',
        href: !canCreatePartnershipSweepstakes && '/app/upgrade-plan',
        imgSrc: '/images/nav-bar/create-new/partnership-sweeps-icon.svg',
        locked: !canCreatePartnershipSweepstakes,
      },
      {
        label: 'SMS Campaign',
        secondary: 'Engage with your SMS subscribers',
        href: '/app/sms-marketing/campaigns/new',
        imgSrc: '/images/nav-bar/create-new/sms-campaigns-icon.svg',
      },
      {
        label: 'Automations',
        secondary: 'Set your SMS engagement on autopilot',
        href: '/app/sms-marketing/automations',
        imgSrc: '/images/nav-bar/create-new/automations-icon.svg',
      },
    ]

    const signupFormMenuItem = {
      label: 'Signup Forms',
      secondary: 'Turn your website visitors into subscribers',
      href: '/app/sms-marketing/acquisition-tools?signup_form=true',
      imgSrc: '/images/nav-bar/create-new/signup-forms.svg',
    }

    if (canViewSignupForm) {
      createNewMenuItems.unshift(signupFormMenuItem)
    }

    const handleMenuItemClick = label => () => {
      metrics.create('navBar', {
        meta: {
          itemName: label,
          action: 'clickedOnItem',
          from: 'Create New Menu',
        },
      })
      onClose()
    }

    const renderItem = menuItem => {
      const item = (
        <MenuItem
          className={cn(SlimNavBarDropdown.menuItem, {
            [SlimNavBarDropdown.menuItemLocked]: menuItem.locked,
          })}
        >
          {menuItem.locked && (
            <img alt="" className={SlimNavBarDropdown.lock} src="/images/upgrade-badge.svg" />
          )}
          <div className={SlimNavBarDropdown.createOutterDiv}>
            <img alt="" className={SlimNavBarDropdown.icon} src={menuItem.imgSrc} />
            <div className={SlimNavBarDropdown.createInnerDiv}>
              <div className={SlimNavBarDropdown.title}>
                <P>
                  <i>{menuItem.label}</i>
                </P>
              </div>
              <Small>{menuItem.secondary}</Small>
            </div>
          </div>
        </MenuItem>
      )

      return menuItem.to ? (
        <Link to={menuItem.to} key={menuItem.label} onClick={handleMenuItemClick(menuItem.label)}>
          {item}
        </Link>
      ) : (
        <a href={menuItem.href} key={menuItem.label} onClick={handleMenuItemClick(menuItem.label)}>
          {item}
        </a>
      )
    }

    return (
      <Menu
        position="auto"
        active={open}
        onHide={onClose}
        theme={SlimNavBarDropdown}
        className={SlimNavBarDropdown.createNewMenu}
      >
        {createNewMenuItems.map(menuItem => renderItem(menuItem))}
      </Menu>
    )
  }
}

export default CreateDropdownMenu
