const usRegions = [
  {
    key: 'Northeast',
    value: true,
    options: [
      {
        key: 'CT',
        value: true,
        label: 'Connecticut',
      },
      {
        key: 'ME',
        value: true,
        label: 'Maine',
      },
      {
        key: 'MA',
        value: true,
        label: 'Massachusetts',
      },
      {
        key: 'NH',
        value: true,
        label: 'New Hampshire',
      },
      {
        key: 'NJ',
        value: true,
        label: 'New Jersey',
      },
      {
        key: 'NY',
        value: true,
        label: 'New York',
      },
      {
        key: 'PA',
        value: true,
        label: 'Pennsylvania',
      },
      {
        key: 'RI',
        value: true,
        label: 'Rhode Island',
      },
      {
        key: 'VT',
        value: true,
        label: 'Vermont',
      },
    ],
  },
  {
    key: 'South',
    value: true,
    options: [
      {
        key: 'AL',
        value: true,
        label: 'Alabama',
      },
      {
        key: 'AR',
        value: true,
        label: 'Arkansas',
      },
      {
        key: 'DE',
        value: true,
        label: 'Delaware',
      },
      {
        key: 'DC',
        value: true,
        label: 'District of Columbia',
      },
      {
        key: 'FL',
        value: true,
        label: 'Florida',
      },
      {
        key: 'GA',
        value: true,
        label: 'Georgia',
      },
      {
        key: 'KY',
        value: true,
        label: 'Kentucky',
      },
      {
        key: 'LA',
        value: true,
        label: 'Louisiana',
      },
      {
        key: 'MD',
        value: true,
        label: 'Maryland',
      },
      {
        key: 'MS',
        value: true,
        label: 'Mississippi',
      },
      {
        key: 'NC',
        value: true,
        label: 'North Carolina',
      },
      {
        key: 'OK',
        value: true,
        label: 'Oklahoma',
      },
      {
        key: 'SC',
        value: true,
        label: 'South Carolina',
      },
      {
        key: 'TN',
        value: true,
        label: 'Tennessee',
      },
      {
        key: 'TX',
        value: true,
        label: 'Texas',
      },
      {
        key: 'VA',
        value: true,
        label: 'Virginia',
      },
      {
        key: 'WV',
        value: true,
        label: 'West Virginia',
      },
    ],
  },
  {
    key: 'Midwest',
    value: true,
    options: [
      {
        key: 'IL',
        value: true,
        label: 'Illinois',
      },
      {
        key: 'IN',
        value: true,
        label: 'Indiana',
      },
      {
        key: 'IA',
        value: true,
        label: 'Iowa',
      },
      {
        key: 'KS',
        value: true,
        label: 'Kansas',
      },
      {
        key: 'MI',
        value: true,
        label: 'Michigan',
      },
      {
        key: 'MN',
        value: true,
        label: 'Minnesota',
      },
      {
        key: 'MO',
        value: true,
        label: 'Missouri',
      },
      {
        key: 'NE',
        value: true,
        label: 'Nebraska',
      },
      {
        key: 'ND',
        value: true,
        label: 'North Dakota',
      },
      {
        key: 'OH',
        value: true,
        label: 'Ohio',
      },
      {
        key: 'SD',
        value: true,
        label: 'South Dakota',
      },
      {
        key: 'WI',
        value: true,
        label: 'Wisconsin',
      },
    ],
  },
  {
    key: 'West',
    value: true,
    options: [
      {
        key: 'AK',
        value: true,
        label: 'Alaska',
      },
      {
        key: 'AZ',
        value: true,
        label: 'Arizona',
      },
      {
        key: 'CA',
        value: true,
        label: 'California',
      },
      {
        key: 'CO',
        value: true,
        label: 'Colorado',
      },
      {
        key: 'HI',
        value: true,
        label: 'Hawaii',
      },
      {
        key: 'ID',
        value: true,
        label: 'Idaho',
      },
      {
        key: 'MT',
        value: true,
        label: 'Montana',
      },
      {
        key: 'NV',
        value: true,
        label: 'Nevada',
      },
      {
        key: 'NM',
        value: true,
        label: 'New Mexico',
      },
      {
        key: 'OR',
        value: true,
        label: 'Oregon',
      },
      {
        key: 'UT',
        value: true,
        label: 'Utah',
      },
      {
        key: 'WA',
        value: true,
        label: 'Washington',
      },
      {
        key: 'WY',
        value: true,
        label: 'Wyoming',
      },
    ],
  },
]

export default usRegions
