import React from 'react'
import { object, bool } from 'prop-types'
import IntegrationTheme from 'css/themes/integrations.css'
import MergeTagsTableRow from './merge-tags-table-row'

class MergeTagsTable extends React.Component {
  static propTypes = {
    isBrand: bool,
    currentCampaign: object,
    integration: object.isRequired,
    espIntegration: object.isRequired,
    espIntegrations: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  static defaultProps = {
    isBrand: false,
    currentCampaign: null,
  }

  renderMergeTagsTableRows = () => {
    const {
      isBrand,
      currentCampaign: { customFields },
      espIntegration,
      utilityFunctions,
    } = this.props

    const fields = [{
      key: 'hygieneLevel',
      label: 'Email Hygiene Level',
      sample: 'Low Risk',
    }, {
      key: 'firstName',
      label: 'First Name',
      sample: 'Mojo',
    }, {
      key: 'lastName',
      label: 'Last Name',
      sample: 'Tiger',
    }, {
      key: 'gender',
      label: 'Gender',
      sample: 'Male',
    }, {
      key: 'birthday',
      label: 'Birthday',
      sample: '1/1/2016',
    }, {
      key: 'phone',
      label: 'Phone',
      sample: '123456789',
    }, {
      key: 'streetAddress',
      label: 'Street Address',
      sample: '111 Sample St.',
    }, {
      key: 'city',
      label: 'City',
      sample: 'New York',
    }, {
      key: 'state',
      label: 'State',
      sample: 'New York',
    }, {
      key: 'zipcode',
      label: 'Zip Code',
      sample: '22222',
    }, {
      key: 'country',
      label: 'Country',
      sample: 'US',
    }, {
      key: 'ip',
      label: 'IP',
      sample: '111.222.333.444',
    }, {
      key: 'campaignName',
      label: 'Campaign Name',
      sample: 'Spring Getaway to Fiji',
    }, {
      key: 'sourceBrand',
      label: 'Source Brand',
      sample: 'DojoMojo',
    }, {
      key: 'sourceChannel',
      label: 'Promotional Source Type',
      sample: 'Dedicated',
    }, {
      key: 'instagram',
      label: 'Instagram Handle',
      sample: 'dojomojoninja',
    }, {
      key: 'enteredAt',
      label: 'Entered At',
      sample: '5/5/18 10:32',
    }]

    if (['custom', 'zapier'].includes(espIntegration.integration_type)) {
      fields.unshift({
        key: 'email',
        label: 'Email',
        sample: 'hello@dojomojo.com',
      })
    }

    if (!isBrand) {
      customFields.forEach((field, idx) => fields.push({
        key: `customField${idx}`,
        label: field.label,
        sample: '',
      }))
    }

    return fields.map(field => (
      <MergeTagsTableRow
        key={field.key}
        attr={field.key}
        label={field.label}
        sample={field.sample}
        espIntegration={espIntegration}
        utilityFunctions={utilityFunctions}
        isBrand={isBrand}
      />
    ))
  }

  render() {
    const {
      integration,
      espIntegrations: { testResults },
    } = this.props

    return (
      <div className={IntegrationTheme.tableContainer}>
        <table className={`${IntegrationTheme.table} ${IntegrationTheme.mergeTagsTable}`}>
          <thead>
            <tr className={IntegrationTheme.noSideBorder}>
              <th className={IntegrationTheme.firstTd}>
                DojoMojo Value
              </th>
              <th className={IntegrationTheme.secondTd}>
                Sample
              </th>
              <th className={IntegrationTheme.thirdTd}>
                { `Value in ${integration.label}` }
              </th>
              <th className={IntegrationTheme.checkboxTd}>
                Include?
              </th>
            </tr>
          </thead>
        </table>
        <div className={`${IntegrationTheme.tableScroll} ${Object.keys(testResults).length ? IntegrationTheme.shortTable : ''}`}>
          <table className={`${IntegrationTheme.table} ${IntegrationTheme.mergeTagsTable}`}>
            <tbody>
              {this.renderMergeTagsTableRows()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default MergeTagsTable
