import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Dialog from 'react-toolbox/lib/dialog';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import ButtonTheme from 'css/themes/Buttons.css'

const themes = {
  Button: ButtonTheme,
  Dialog: DialogTheme,
}

const mapState = (state) => {
  return {
    pricingModal: state.pricingModal
  };
}

class PricingGateModal extends React.Component {
  closeModal = () => {
    if (!this.props.pricingModal.noAccess) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'pricingModal',
        data: {
          expand: false
        }
      })
    }
  }

  upgradePlan = () => {
    const { history, pricingModal: { feature, eventTrack } } = this.props;

    if (eventTrack === 'messageUpgrade') {
      EventTrackingDispatcher.track('app_upgrade_upgradeButton_messageUpgradeModal')
    } else if (eventTrack === 'hostUpgrade') {
      EventTrackingDispatcher.track('app_upgrade_upgradeButton_hostUpgradeModal')
    }

    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: feature,
        upgradeOriginalLocation: history.location.pathname
      }
    })

    history.push('/upgrade-plan');
    this.closeModal();
  }

  render() {
    var {
      pricingModal
    } = this.props;

    return (
      <Dialog
        theme={ themes.Dialog }
        active={ !!pricingModal.expand }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title={ pricingModal.title }
      >
        {
          !pricingModal.noAccess ?
          <img
            onClick={ this.closeModal }
            className={ themes.Dialog.closeImage }
            src="/images/icons/close.svg"
          />
          : null
        }

        <img src="/images/nav-bar/mojo-white-tiger-logo.svg"
          style={{
            width: '126px',
            height: '120px',
            marginBottom: '24px',
            marginLeft: '241px'
        }}/>

        <p style={{
          fontFamily: 'Larsseit-Light',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#212731',
          marginBottom: '16px'
        }}>
          { pricingModal.message }
        </p>

        <ModalButtonGroup
          canSave={ true }
          confirm={ this.upgradePlan }
          confirmText={ 'View Upgrade Options' }
          cancel={ !pricingModal.noAccess ? this.closeModal : false }
          cancelText={ 'No Thanks' }
          hideLine={ true }
        />
      </Dialog>
    )
  }
};

export default withRouter(connect(mapState)(PricingGateModal));
