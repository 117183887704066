import { debounce } from 'lodash'
import AppDispatcher from 'dispatchers/app-dispatcher';

import BrandDetailDispatcher from 'dispatchers/brand-detail-dispatcher';

var update = debounce(function(interests) {
  AppDispatcher.http({
    url: '/brands-interests',
    method: 'PUT',
    data: interests,
    success: () => {
      BrandDetailDispatcher.loadPartnershipInterests(store.getState().currentBrand.id)
    }
  })
}, 300)

export default {
  update
};
