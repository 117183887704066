import React from 'react'
import { H4, Tiny } from 'visual-components/util/texts'
import MediaMarketAnalyticsTheme from 'css/themes/media-market/MediaMarketAnalyticsTheme.css'
import d3 from 'd3'

const ClicksCount = props => {
  const {
    isLive,
    dealEmailDojoClicks,
    isFixed,
    isCPC,
    status,
  } = props

  const fixedStyle = isFixed ? { display: 'flex', flex: 1, alignItems: 'baseline' } : {}
  const fixedTextStyle = isFixed ? { fontSize: '30px', marginRight: '4px' } : {}

  return (
    <div className={MediaMarketAnalyticsTheme.dealStatContainer} style={fixedStyle}>
      <div className={MediaMarketAnalyticsTheme.dealStatValue}>
        <H4
          neonMint={isFixed && isLive}
          azure={isFixed && !isLive}
          white={isCPC && isLive && status === 'live'}
          style={fixedTextStyle}
        >
          <em>{d3.format(',')(dealEmailDojoClicks)}</em>
        </H4>
      </div>
      <Tiny white={isLive && status === 'live'}>Clicks Accrued</Tiny>
    </div>
  )
}

export default ClicksCount
