import React from 'react'
import moment from 'moment-timezone'

import { Form, Input, Submit, } from 'visual-components/util/form'
import { isNum } from 'visual-components/util/form/validations'

import { Card } from 'react-toolbox/lib/card'
import { P, H2, H3 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import AppDispatcher from 'dispatchers/app-dispatcher'

class CustomerSuccessAssoicatedBrands extends React.Component {
  state = {
    open: false,
    brandId: null,
    brandName: null,
    associatedBrands: []
  }

  handleChange = e => {
    this.setState(e)
  }

  handleSubmit = () => {
    const { brandId } = this.state
    AppDispatcher.http({
      url: `/cs-associated-brands/${brandId}`,
      method: 'GET',
      success: data => this.handleChange({ ...data, brandId: brandId }),
      error: error => {
        this.handleChange({ associatedBrands: [] })
        toastr.error(error.responseText, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      }
    })
  }

  render() {
    const { open, brandId, brandName, associatedBrands } = this.state

    const associatedBrandItems = associatedBrands.map(brand => {
      return (
        <tr style={{
          fontFamily: 'Larsseit',
          fontSize: '14px'
        }}>
          <td style={{
            width: '360px'
          }}>
            { brand.accountname }
          </td>
          <td style={{
            width: '168px'
          }}>
            { brand.count }
          </td>
          <td style={{
            width: '168px'
          }}>
            { moment(brand.lastWorkedWith).format('MM/DD/YY') }
          </td>
        </tr>
      )
    })

    return (
      <Card style={{
        padding: '24px'
      }}>
        <Grid>
          <Row style={{
            marginBottom: '16px'
          }}>
            <div onClick={ e => this.handleChange({ open: !open }) }
              style={{
                cursor: 'pointer'
            }}>
              <H2 azure>
                Associated Brands
                <i className={ `fa fa-angle-${ open ? 'up' : 'down' }` }
                  style={{
                    fontSize: '32px',
                    marginLeft: '8px'
                }}></i>
              </H2>
              <P multiline>
                { `Shows a selected Brand's previous campaign partners. Displays Brand Name, Number of Times Partnered With, and Last Partnered With Date.` }
              </P>
            </div>
          </Row>
          <Row style={{
            display: open ? 'flex' : 'none'
          }}>
            <Col style={{
              width: '364px',
              marginRight: '32px'
            }}>
              <Form handleSubmit={ this.handleSubmit }
                updateModel={ this.handleChange }
                dataModel={ this.state }
              >
                <Input name="brandId"
                  label="Enter Brand ID"
                  type="number"
                  validations={[isNum]}
                />

                <Submit label="Submit" />
              </Form>
            </Col>
            <Col style={{
              width: '696px'
            }}>
              {
                associatedBrandItems.length ?
                <div>
                  <div style={{
                    marginBottom: '16px'
                  }}>
                    <H3>
                      <small>
                        { `${brandId} - ${brandName}` }
                      </small>
                    </H3>
                  </div>
                  <table>
                    <thead>
                      <tr style={{
                        fontFamily: 'Larsseit-Medium',
                        fontSize: '14px'
                      }}>
                        <th style={{
                          width: '360px'
                        }}>
                          Brand Name
                        </th>
                        <th style={{
                          width: '168px'
                        }}>
                          Partnered With Count
                        </th>
                        <th style={{
                          width: '168px'
                        }}>
                          Last Partnered With
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { associatedBrandItems }
                    </tbody>
                  </table>
                </div>
                : null
              }
            </Col>
          </Row>
        </Grid>
      </Card>
    )
  }
}



export default CustomerSuccessAssoicatedBrands
