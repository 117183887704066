import React, { Component } from 'react'
import {
  oneOfType, object, string, number, func, bool,
} from 'prop-types'
import injectSheet from 'react-jss'
import ColorPicker from 'rc-color-picker'
import { Smaller } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

const styles = {
  container: {
    position: 'relative',
    marginTop: '24px',
    marginBottom: '12px',
    height: 32,
  },
  input: {
    boxShadow: 'none',
    marginTop: '16px !important',
    paddingBottom: '3px',
    paddingLeft: '48px',
    paddingRight: '68px',
  },
  smallInput: {
    float: 'right',
    width: '56px',
    height: '40px',
    marginTop: '-24px',
    textAlign: 'center',
  },
  label: {
    marginTop: '-11px',
    paddingLeft: '48px',
  },
  colorPicker: {
    float: 'none',
    position: 'absolute',
    bottom: '-8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}

class ColorRangeSlider extends Component {
  static propTypes = {
    attr: string.isRequired,
    value: oneOfType([string, number]).isRequired,
    color: string.isRequired,
    label: string.isRequired,
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
    updateValue: func.isRequired,
    classes: object.isRequired,
    showSlider: bool,
  }

  static defaultProps = {
    showSlider: true,
  }

  state = {
    prevVal: null,
  }

  updateValue = ({ target: { value } }) => {
    const { attr, updateValue } = this.props

    // If backspace
    if (!/%/g.test(value)) {
      const { prevVal } = this.state

      // If not first number
      if (/%/.test(prevVal)) value = value.slice(0, -1)
    }

    value = value.split('%').join('')
    if (isNaN(value)) return
    if (value > 100) value = 100
    else if (value < 0) value = 0

    this.setState({ prevVal: `${value}%` })
    updateValue(attr, value / 100)
  }

  render() {
    const {
      attr,
      label,
      value,
      min,
      max,
      step,
      color,
      updateValue,
      classes: css,
      showSlider,
    } = this.props

    return (
      <div key={attr} className={css.container}>
        <div>
          <Smaller>
            <em>
              Color
            </em>
          </Smaller>
          <ColorPicker
            animation="slide-up"
            onClose={this.handleChangeComplete}
            onChange={({ color }) => updateValue(attr.replace('opacity', 'color'), color)}
            color={color}
            mode="RGB"
            placement="topRight"
            enableAlpha={false}
            className={css.colorPicker}
          />
        </div>
        {
          showSlider
          && (
          <React.Fragment>
            <div>
              <div className={css.label}>
                <Smaller>
                  <em>
                    {label}
                  </em>
                </Smaller>
              </div>
              <input
                type="range"
                min={min}
                max={max}
                step={step || 1}
                onChange={({ target }) => updateValue(attr, target.value)}
                value={value}
                className={css.input}
              />
            </div>
            <div>
              <input
                onChange={this.updateValue}
                value={`${Math.floor(value * 100)}%`}
                className={css.smallInput}
              />
            </div>
          
          </React.Fragment>
          )
        }
      </div>
    )
  }
}

export default injectSheet(styles)(ColorRangeSlider)
