import React, { PureComponent } from 'react'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import {
  H1, H3, H5, Small,
} from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { makeNumbersPretty } from 'util/makeNumbersPretty'
import ButtonTheme from 'css/themes/Buttons.css'
import QuickViewTheme from 'css/themes/Quickview.css'

class MinimumEntryRequirement extends PureComponent {
  message = () => {
    const { hostBrandId, hostBrandName } = this.props
    store.dispatch({
      model: 'conversations',
      type: 'POPOUT_MESSAGE_BRAND',
      data: {
        brandId: hostBrandId,
        brandName: hostBrandName,
      },
    })
  }

  render() {
    const { entries, minEntries, hostBrandName } = this.props
    let percentage = 0

    if (entries) {
      percentage = Math.round((entries / minEntries) * 100) > 100 ? 100 : Math.round((entries / minEntries) * 100)
    }

    const taskBarWidth = percentage / 100

    return (
      <div style={{ position: 'relative' }}>
        <Card className={QuickViewTheme.mainContainer}>
          <H3 coral><small>Minimum Entry Requirement</small></H3>
          <Grid style={{ marginTop: '12px' }}>
            <Row>
              <Col>
                <div style={{ display: 'inline-block' }}>
                  <H1 azure>{ makeNumbersPretty(minEntries) }</H1>
                </div>
                <div style={{ display: 'inline-block', paddingLeft: '4px' }}>
                  <Small>entries required</Small>
                </div>
              </Col>
            </Row>
            <Row>
              <div style={{ marginTop: '14px' }}>
                <Small><em>ENTRIES PROGRESS</em></Small>
              </div>
              <div className={QuickViewTheme.taskVisualContainer}>
                <div className={QuickViewTheme.taskBarOuter}>
                  <div className={QuickViewTheme.taskBarInner} style={{ width: `${300 * taskBarWidth}px` }} />
                </div>
                <div className={QuickViewTheme.minEntriesPercentage}>
                  <H5>
                    { `${percentage} %` }
                  </H5>
                </div>
              </div>
            </Row>
            { hostBrandName
              && (
                <Row>
                  <div style={{ marginLeft: '-9px', bottom: '12px' }}>
                    <Button className={`${ButtonTheme.blueButtonFlat}`} type="button" onClick={this.message}>
                      <div style={{ marginLeft: '-3px' }}>Contact Host</div>
                    </Button>
                  </div>
                </Row>
              )
            }
          </Grid>
        </Card>
      </div>
    )
  }
}


export default MinimumEntryRequirement
