import keyMirror from 'keymirror';

export default keyMirror({
  ADD_EMAIL_TO_INVITE_INPUT_REQUEST: null,
  ADD_EMAIL_TO_INVITE_INPUT_SUCCESS: null,
  ADD_EMAIL_TO_INVITE_INPUT_FAILURE: null,
  INVITE_INVALID_EMAIL_INPUT: null,
  VALIDATING_EMAIL_INPUT: null,
  REMOVE_EMAIL_FROM_INVITE_INPUT: null,

  GMAIL_CONTACTS_REQUEST: null,
  GMAIL_CONTACTS_SUCCESS: null,
  GMAIL_CONTACTS_FAILURE: null,

  REFERRAL_INVITES_REQUEST: null,
  REFERRAL_INVITES_SUCCESS: null,
  REFERRAL_INVITES_FAILURE: null,

  REFERRAL_GMAIL_INVITES_REQUEST: null,
  REFERRAL_GMAIL_INVITES_SUCCESS: null,
  REFERRAL_GMAIL_INVITES_FAILURE: null,

  REFERRAL_REMINDER_REQUEST: null,
  REFERRAL_REMINDER_SUCCESS: null,
  REFERRAL_REMINDER_FAILURE: null,

  SEARCH_IMPORTED_CONTACTS: null,

  SELECT_CONTACT: null,
  TOGGLE_SELECT_ALL_CONTACTS: null,
  CLOSE_GMAIL_IMPORT_MODAL: null,

  YOUR_REFERRALS_REQUEST: null,
  YOUR_REFERRALS_SUCCESS: null,
  YOUR_REFERRALS_FAILURE: null,

  RAFFLE_WINNER_REQUEST: null,
  RAFFLE_WINNER_SUCCESS: null,
  RAFFLE_WINNER_FAILURE: null,

  REFERRAL_UNREAD_COUNT_REQUEST: null,
  REFERRAL_UNREAD_COUNT_SUCCESS: null,
  REFERRAL_UNREAD_COUNT_FAILURE: null,

  MARK_ALL_REFERRAL_READ: null,
  CLOSE_REMINDER_MODAL: null,
  OPEN_REMINDER_MODAL: null,

  NEW_REFERRAL_JOINED: null
});
