import React, { Component } from 'react';
import ResourcesBanner from './resources-banner';
import ResourcesTabPicker from './resources-tab-picker'
import ResourcesCaseStudies from './resources-case-studies';
import ResourcesGuides from './resources-guides';
import Footer from '../footer/Footer';

class ResourcesForMedia extends Component {
  render() {
    return(
      <div>
        <ResourcesBanner />
        <ResourcesTabPicker
          page={ 'for-media' }
        />
        <ResourcesCaseStudies>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01222832/Wine-Awesomeness-Case-Study.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/case-studies/wine-awesomeness-case-study-card.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01222601/Wanderfull-Case-Study.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/case-studies/wanderfull-case-study-card.png' } />
          </a>
        </ResourcesCaseStudies>
        <ResourcesGuides>
          <a href="http://assets.dojomojo.com/uploads/2017/10/25215107/Email-Deliverability-Guide-1.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/email-deliverability-guide.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01223642/How-to-Create-an-Email-Drip-Campaign-2.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/how-to-create-email-drip-campaign.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01223555/Get-Started-Guide-for-Partners-1.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/get-started-on-dojo-mojo.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01223706/Partner-Checklist-2.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/partner-checklist.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01223624/Hosting-Made-Simple-2.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/hosting-made-simple.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01223610/Host-Checklist.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/host-checklist.png' } />
          </a>
          <a href="http://assets.dojomojo.com/uploads/2017/11/01223452/Before-You-Join-a-Campaign.pdf" target="_blank">
            <img src={ '/images/login/resources/library-imgs/guides/before-you-join-a-campaign.png' } />
          </a>
        </ResourcesGuides>
        <Footer />
      </div>
    )
  }
}

export default ResourcesForMedia;
