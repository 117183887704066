import { makeNumbersPretty } from 'util/makeNumbersPretty';
import SocialLabelMap from 'util/socialTitleMap';
import SocialTitleArray from 'util/socialTypeArray';

export default {
  mappings: {
  },
  metricInfo: {
    partnershipCampaignPerformance: {
      mappingLogic: 'eventTimelineGraph'
    },
    partnershipGeographicDist: {
      mappingLogic: 'geographicStateDistribution'
    },
    partnershipIncomeDist: {
      mappingLogic: 'incomeDistribution'
    },
    partnershipGenderDist: {
      mappingLogic: 'genderDistribution'
    },
    partnershipBasicDetails: {
      mappingLogic: 'basicData'
    }
  },
  actions: {
  }
}
