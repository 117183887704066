import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

import SocialContributionCard from 'visual-components/analytics/campaign/social-contribution-card';

class SocialContribution extends React.Component {
  render() {
    let {
      data,
      started,
    } = this.props;

    return (
      <SocialContributionCard
        data={data}
        started={started}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.analyticsCampaign.campaignSocialContributionByBrand.data
  };
}

export default connect(mapStateToProps)(SocialContribution);
