import React, { Component } from 'react';
import { string } from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { H2, P, H6 } from 'visual-components/util/texts';
import Card from 'react-toolbox/lib/card';
import COLOURS from 'util/colours';
import InternalContentContainer from 'visual-components/containers/InternalContentContainer';
import buttonTheme from 'css/themes/Buttons.css';
import { Button } from 'react-toolbox/lib/button';
import { openModal } from 'actions/legal-details-actions';
import SignAgreementModal from './sign-agreement-modal';
import AgreementSignedModal from './agreement-signed-modal';
import LegalDetailsModal from './LegalDetailsModal';
import { Helmet } from 'react-helmet';
import * as PartnershipAgreementActions from 'actions/partnership-agreement-actions';

const BoldedText = ({ children }) => (
  <span style={{ fontFamily: 'Larsseit-Medium' }}>{children}</span>
);

export class ReviewPartnershipAgreement extends Component {
  static contextTypes = {
    impersonating: string,
  }

  componentDidMount() {
    this.props.actions.loadPartnershipAgreement(
      this.props.match.params.partnershipId
    );
  }

  signAgreement = (name, title) => {
    store.dispatch({
      model: 'inDiscussionCampaigns',
      type: 'SIGN_AGREEMENT_PARTNER',
      data: {
        inviteId: this.props.inviteId
      }
    })
    this.props.actions.signAgreement(
      this.props.match.params.partnershipId,
      name,
      title
    );
  };

  saveLegalDetails = data => {
    this.props.actions.saveLegalDetails(
      data,
      this.props.match.params.partnershipId
    );
  };

  messageHost = _ => {
    this.props.actions.messageHost({
      brandId: this.props.hostBrandId,
      brandName: this.props.hostBrandName
    })
  }

  render() {
    const {
      agreement,
      openLegalDetailsModal,
      dispatch,
      currentBrandName,
      currentBrandId,
      signAgreementModalOpen,
      actions,
      agreementSignedModalOpen,
      hostBrandName,
      campaignName,
      inviteId,
      loading,
      error
    } = this.props;
    const { impersonating } = this.context

    let errorMessage;

    if (error) {
      errorMessage = error
    } else if (!agreement.id) {
      errorMessage = 'Agreement does not exist'
    } else if (agreement.type === 'custom') {
      errorMessage = 'Agreement is of custom type'
    } else if (agreement.status !== 'shared' && agreement.status !== 'partner_signed' && agreement.status !== 'completed') {
      errorMessage = 'Agreement is not shared'
    }

    if (loading) {
      return null
    } else if (errorMessage) {
      return (
        <InternalContentContainer width={'976px'}>
          <Grid>
            <Row style={{ marginTop: '36px' }}>
              <H2>{errorMessage}</H2>
            </Row>
          </Grid>
        </InternalContentContainer>
      )
    }

    const { top_banner_html, rendered_html, signature_html, type } = agreement
    const htmlString = top_banner_html.concat(rendered_html, signature_html)
    return (
      <InternalContentContainer width={'976px'}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Arizonia"
            rel="stylesheet"
          />
          <link
            href="/css/agreement.css"
            rel="stylesheet"
          />
        </Helmet>
        <Grid>
          <Row style={{ marginTop: '36px' }}>
            <Col xs={7}>
              <H2 azure>Review Partnership Agreement</H2>
            </Col>
            <Col xs={5} style={{ textAlign: 'right' }}>
              <a
                href={`/partnership-agreement/pdf/${currentBrandId}/${inviteId}${impersonating ? `?impersonating=${impersonating}` : ''}`}
                target="_blank"
              >
                <Button
                  className={buttonTheme.whiteButton}
                  theme={buttonTheme}
                  label="Download PDF"
                  style={{ marginRight: 16 }}
                  raised
                />
              </a>
              <Button
                onClick={this.messageHost}
                className={buttonTheme.whiteButton}
                theme={buttonTheme}
                label="Message Host"
                style={{ marginRight: 16 }}
                raised
              />
              {!agreement.partner_sign_id && (
                <Button
                  onClick={actions.openSigningModal}
                  className={cn(buttonTheme.greenButton, {
                    [buttonTheme.disabledButton]: !agreement.partner_legal_id,
                  })}
                  theme={buttonTheme}
                  disabled={!agreement.partner_legal_id}
                  label="Sign Agreement"
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: '12px' }}>
            <P multiline>
              Carefully review the contract below. Send a message to the Host to
              request edits or changes. If you have not yet entered your
              company’s information, you will need to do so in order to sign the
              agreement.&nbsp;
              <BoldedText>
                Note that the Host cannot accommodate further changes once you
                have signed this agreement.
              </BoldedText>
            </P>
          </Row>
          <Row
            center="xs"
            style={{
              marginTop: '32px',
              background: COLOURS.foggy,
              boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12)'
            }}
          >
            {!agreement.partner_legal_id && (
              <Col>
                <div style={{ padding: '14px' }}>
                  <BoldedText>
                    Please provide or confirm your company’s legal information
                    in order to sign this agreement.
                  </BoldedText>
                  <Button
                    onClick={() => dispatch(openModal())}
                    className={buttonTheme.blueButton}
                    theme={buttonTheme}
                    style={{ marginLeft: '16px' }}
                    label="Confirm Info"
                  />
                </div>
              </Col>
            )}
          </Row>
          <Card style={{ background: '#fff', padding: '80px' }}>
            <span
              dangerouslySetInnerHTML={{ __html: htmlString }}
            />
          </Card>
        </Grid>
        <LegalDetailsModal submit={this.saveLegalDetails} />
        <SignAgreementModal
          closeModal={actions.closeSigningModal}
          active={signAgreementModalOpen}
          currentBrandName={currentBrandName}
          confirm={this.signAgreement}
        />
        <AgreementSignedModal
          closeModal={actions.closeSigningModal}
          active={agreementSignedModalOpen}
          hostBrandName={hostBrandName}
          campaignName={campaignName}
        />
      </InternalContentContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    agreement: state.partnershipAgreement.agreement,
    signAgreementModalOpen: state.partnershipAgreement.signAgreementModalOpen,
    agreementSignedModalOpen:
      state.partnershipAgreement.agreementSignedModalOpen,
    currentBrandName: state.currentBrand.accountname,
    currentBrandId: state.currentBrand.id,
    hostBrandName: _.get(state.partnershipAgreement, [
      'hostBrand',
      'accountname',
    ]),
    hostBrandId: state.partnershipAgreement.hostbrand_id,
    campaignName: _.get(state.partnershipAgreement, ['campaign', 'name']),
    campaignId: state.partnershipAgreement.campaign_id,
    inviteId: state.partnershipAgreement.id,
    loading: state.partnershipAgreement.loading,
    error: state.partnershipAgreement.error
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(PartnershipAgreementActions, dispatch),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReviewPartnershipAgreement)
);
