import React from 'react'
import Calendar from './Calendar'
import TaskList from './TaskList'
import PartnerList from './PartnerList'

import CalendarTheme from 'css/themes/calendar/calendar.css'

class CalendarPageContainer extends React.Component {


  render() {
    return (
      <div className={CalendarTheme.calendarCampaignPage}>
        <PartnerList />
        <Calendar />
        <TaskList />
      </div>
    )
  }
}

export default CalendarPageContainer
