import React, { PureComponent as Component } from 'react'
import Dialog from 'react-toolbox/lib/dialog'
import { H3, H4, P } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import PropTypes from 'prop-types'

import DialogTheme from 'css/themes/mediaMarketDealTrackingTheme.css'

class TrackingLinkIFrameModal extends Component {
  componentDidUpdate = () => {
    const { showModal, trackingLink } = this.props

    if (showModal) {
      setTimeout(() => {
        if (this.iframe) {
          this.iframe.src = trackingLink.preview_link
        }
      }, 60)
    }
  }

  render() {
    const {
      trackingLink,
      showModal,
      closeModal,
      isCopying,
    } = this.props

    if (!trackingLink) return null

    const canPreview = trackingLink.preview_link

    if (canPreview) {
      /* preview dialog */
      return (
        <Dialog
          theme={DialogTheme}
          active={showModal}
          onEscKeyDown={closeModal}
          onOverlayClick={closeModal}
        >
          <div style={{ width: '100vw', height: '100vh' }}>
            <div className={DialogTheme.iFrameCloseBtn}>
              <img
                src="/images/icons/close.svg"
                style={{
                  height: '16px',
                  width: '16px',
                  cursor: 'pointer',
                  marginRight: '24px',
                }}
                onClick={closeModal}
              />
              <P>{ trackingLink.label || '' }</P>
            </div>
            <iframe
              ref={ref => { this.iframe = ref }}
              className={DialogTheme.trackingLinkIFrame}
              src={null}
            >
              <p>Your Browser doesn't support iframes.</p>
            </iframe>
          </div>
        </Dialog>
      )
    }

    /* message dialog */
    return (
      <Dialog
        theme={DialogTheme}
        active={showModal}
        onEscKeyDown={closeModal}
        onOverlayClick={closeModal}
      >
        <div style={{ width: '672px', padding: '32px 0' }}>
          <div className={DialogTheme.warnTitle}>
            <H3>
              Heads up!
            </H3>
          </div>
          <div className={`${DialogTheme.warningImgContainer} ${isCopying ? DialogTheme.copyImgContainer : ''}`}>
            {
              isCopying
                ? <img className={DialogTheme.copyImg} src="/images/icons/warning-megaphone.svg" />
                : <img className={DialogTheme.warningImg} src="/images/error-message-icon@3x.png" />
            }
          </div>
          <div className={DialogTheme.warningText}>
            <H4 multiline>
              {
                isCopying
                  ? 'Make sure to use the DojoMojo link in your promotion to ensure accurate tracking.'
                  : 'We\'re unable to add a preview for this link - please use the DojoMojo link in your promotion to guarantee accurate tracking.'
              }
            </H4>
          </div>
          <div className={DialogTheme.warningBtnContainer}>
            <ModalButtonGroup
              confirm={closeModal}
              confirmText="Got it!"
              canSave
              hideLine
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

TrackingLinkIFrameModal.defaultProps = {
  closeModal() {},
}

TrackingLinkIFrameModal.propTypes = {
  trackingLink: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
}

export default TrackingLinkIFrameModal
