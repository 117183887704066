import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import colors from 'util/colours'

import WrapperTooltip from 'util/components/wrapper-tooltip'
import InsertImageGIFModal from 'util/components/InsertImageGIFModal'
import { SMSEditorContext } from '../SMSEditorContext'

const ImageIcon = props => {
  const dPath = 'M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3zm-3 4V8h-3V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 19l3-4 2 3 3-4 4 5H5z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(-4)">
        <rect width="32" height="24" rx="2" />
        <g>
          <path fill="#475464" fillRule="nonzero" d={dPath} transform="translate(4)" />
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(4)" />
        </g>
      </g>
    </svg>
  )
}

const styles = {
  wrapper: {
    margin: '0 6px',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s linear',
    cursor: 'pointer',
    borderRadius: 2,
    '&:hover': {
      background: colors.silver,
    },
  },
  customTooltip: {
    marginTop: 8,
  },
}

function ImageSelector(props) {
  const { classes: css } = props
  const {
    actions: { onSetImages },
  } = React.useContext(SMSEditorContext)
  const [open, setOpen] = useState(false)

  const onSubmit = media => {
    setOpen(false)
    onSetImages(media)
  }

  return (
    <div className={css.wrapper}>
      <WrapperTooltip customClasses={css.customTooltip} delay={1} text="Insert Image">
        <ImageIcon onClick={() => setOpen(true)} />
      </WrapperTooltip>
      <InsertImageGIFModal onClose={() => setOpen(false)} open={open} onSubmit={onSubmit} />
    </div>
  )
}

ImageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(ImageSelector))
