import { getTemplate, compileTemplate } from './TemplateV2'

const template = getTemplate()
const themeColor = '#1976d2'

template.submitButton.button = {
  borderRadius: 4,
}

template.inputs = {
  input: {
    borderRadius: 4,
    border: 'solid 1px #bdbdbd',
  },
}

template.formContainer = {
  form: {
    padding: '20px 32px 0 32px',
    marginBottom: 40,
  },
  bottomInfo: {
    padding: '40px 32px 0 32px',
  },
}

export const templateStyles = compileTemplate(template)
export const defaultVals = {}
