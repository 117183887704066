import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import TableTheme from 'css/themes/analytics-tables/campaignTable.css'
import Badge from 'visual-components/util/badge'
import COLOURS from 'util/colours'
import moment from 'moment-timezone'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

class CampaignAnalyticsTableRow extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
  }

  onClick = () => {
    const { history, campaign: { id } } = this.props
    history.push(`/analytics/campaigns/${id}`)
  }

  render() {
    const {
      currentBrand = {},
      totalEntries = currentBrand.total_entries_delivered,
      totalVisits,
      newToFile = currentBrand.ntf,
      name,
      startDate,
      endDate,
      live = moment().isBetween(startDate, endDate),
    } = this.props.campaign

    const newToFilePercent = totalEntries && newToFile
      ? Math.round(newToFile / totalEntries * 100)
      : 0

    const tableClassName = `${TableTheme.campaignRow} ${TableTheme.analyticsRow} ${live ? TableTheme.liveRow : null}`

    return (
      <tr onClick={this.onClick} className={tableClassName} >
        <td data-title="CampaignName" className={TableTheme.tdFirst}>
          { name }
        </td>
        {
          live
            ? (
              <td data-title="Dates" className={TableTheme.liveTd}>
                <div>
                  <div style={{ paddingBottom: '15px', paddingTop: '13px' }}>
                    <Badge label="LIVE" color={COLOURS.coral} />
                  </div>
                  <div style={{ paddingBottom: '15px', paddingTop: '17px', paddingLeft: '2px' }}>
                    { `-${moment(endDate).format('M/D/YY')}` }
                  </div>
                </div>
              </td>
            )
            : (
              <td data-title="Dates">
                { `${moment(startDate).format('M/D/YY')}-${moment(endDate).format('M/D/YY')}` }
              </td>
            )
        }
        <td data-title="Entries">
          { makeNumbersPretty(totalEntries) }
        </td>
        <td data-title="Visits">
          { makeNumbersPretty(totalVisits) }
        </td>
        <td data-title="NTF">
          { makeNumbersPretty(newToFile) }
        </td>
        <td data-title="NTF-percent">
          { `${newToFilePercent}%` }
        </td>
      </tr>
    )
  }
}

export default withRouter(CampaignAnalyticsTableRow)
