import React from 'react'
import Text from 'util/components/text'

class SuccessIcon extends React.Component {
  render() {
    const {
      success,
      style
    } = this.props

    const status = success == true ? 'Success' : 'Error'

    return(
      <div style={ Object.assign({}, {
        height: '30px',
        width: '30px',
        display: 'inline-block'
      }, style)}>
        <img src={ `/images/${status}_Icon.svg` }
          style={{
            width: '100%'
        }}/>
      </div>
    )
  }
}

export default SuccessIcon
