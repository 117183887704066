import React from 'react'
import TableTheme from 'css/themes/analytics-tables/partnershipTable.css'

const PartnershipTableHeader = () => (
  <tr>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.Brand}`}>
      Brand
    </th>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.LastPartnered}`}>
      Last Partnered
    </th>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.Campaigns}`}>
      Campaigns
    </th>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.ListSize}`}>
      List Size
    </th>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.AvgEntries}`}>
      Avg Entries
    </th>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.NTF}`}>
      NTF
    </th>
    <th className={`${TableTheme.analyticsTh} ${TableTheme.NTFpercent}`}>
      NTF%
    </th>
  </tr>
)

export default PartnershipTableHeader
