import React from 'react';
import COLOURS from 'util/colours';
import { H4 } from 'visual-components/util/texts';

class CtaButton extends React.Component{
  render() {
    var cta = this.props.cta;

    if (cta.ctaUrl && !cta.ctaUrl.match(/^(?:http(?:s)?\:\/\/)/)) {
      cta.ctaUrl = `http://${cta.ctaUrl}`;
    }

    if (!cta.ctaUrl) {
      return (
        <div></div>
      )
    }
    return (
      <div>
        <div className="row text-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{
              paddingBottom: '15px'
          }}>
            <H4>
              <i>
                { cta.ctaMessageText }
              </i>
            </H4>
          </div>
        </div>
        <div className="row" style={{
          paddingBottom: '30px'
        }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
            <a className="cta-link" href={ cta.ctaUrl } target="_blank">
              <button type="button"
                className="btn btn-primary"
                style={{
                  borderRadius: '2px',
                  borderWidth: '0px',
                  padding: '10px',
                  fontFamily: 'GothamRnd-Book',
                  backgroundColor: COLOURS.inputFocusBorder
              }}>
                { cta.ctaActionText }
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
};

export default CtaButton;
