import { Component } from  'react'

import _ from 'lodash'

import ProgressTheme from 'css/themes/ProgressTheme.css'

class Dot extends Component {
  render() {
    const { 
      nextFunc,
      position,
    } = this.props

    return (
      <div
        className={` ${ProgressTheme.dot} ${this.props.current ? ProgressTheme.current : ''}`}
        onClick={() => nextFunc(position)}
      />
    )
  }
}

class ProgressDot extends Component {
  steps = () => {
    const {
      steps,
      step,
    } = this.props

    const stepItems = []

    for (let i =  0; i < steps; i++) {
      stepItems.push(<Dot key={i} current={step === i} position={i} {...this.props}/>)
    }

    return stepItems
  }
  render() {
    return (
      <div className={ProgressTheme.progressHolder}>
        { this.steps() }
      </div>
    )
  }
}

export default ProgressDot
