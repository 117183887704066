import React, { PureComponent as Component } from 'react'
import { array, string, bool } from 'prop-types'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'
import CampaignAnalyticsTable from 'visual-components/analytics/campaign/CampaignAnalyticsTable'
import CampaignAnalyticsGrid from 'visual-components/analytics/campaign/CampaignAnalyticsGrid'

class CampaignAnalyticsResultsContainer extends Component {
  static propTypes = {
    campaigns: array.isRequired,
    display: string.isRequired,
    loading: bool,
    error: string,
    filtered: bool,
  }

  static defaultProps = {
    loading: false,
    error: null,
    filtered: false,
  }

  render() {
    const {
      campaigns,
      loading,
      error,
      filtered,
      display,
    } = this.props

    if (loading) return null

    if (error || !campaigns || !campaigns.length) {
      const description = error
        ? `Oops! There was an error processing your search. Please try again. ${error}`
        : filtered
          ? 'Oops! No search results. Expand your filters and try again.'
          : 'It looks like you haven’t participated in a campaign yet. Head to Explore to view campaigns in the DojoMojo network'

      return (
        <div style={{ width: '752px' }}>
          <EmptyBonzai noTree description={description} />
        </div>
      )
    }

    if (display === 'grid') {
      return <CampaignAnalyticsGrid campaigns={campaigns} />
    }

    return <CampaignAnalyticsTable campaigns={campaigns} />
  }
}

export default CampaignAnalyticsResultsContainer
