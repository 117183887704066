import React, { Component } from 'react';

import theme from 'css/themes/homePage/careers.css';

class PerksItem extends Component {
  render() {
    let {
      imgSrc,
      title,
      explain
    } = this.props;

    return(
      <div className={ theme.perkItem }>
        <div className={ theme.imgWrapper }>
          <img src={ imgSrc } />
        </div>
        <p className={ theme.perkTitle }>
          { title }
        </p>
        <p className={ theme.perkExplain }>
          { explain }
        </p>
      </div>
    )
  }
}

export default PerksItem;
