import React, { Component } from 'react'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import {
  bool, object, func, string, oneOfType,
} from 'prop-types'

export default class ModalButtonGroupWithContext extends Component {
  static propTypes = {
    canSave: oneOfType([bool, func]),
    cancel: func,
    cancelText: string,
    confirmText: string,
    hideLine: bool,
    bottomPad: string,
    submitOnMount: bool,
  }

  static defaultProps = {
    canSave: true,
    cancel: undefined,
    cancelText: 'Cancel',
    confirmText: 'Save',
    hideLine: false,
    bottomPad: undefined,
    submitOnMount: false,
  }

  static contextTypes = {
    form: object.isRequired,
    formActions: object.isRequired,
  }

  componentDidMount() {
    const { submitOnMount } = this.props
    const { formActions: { submitForm } } = this.context
    if (submitOnMount) submitForm()
  }

  render() {
    const {
      cancel, cancelText, confirmText, hideLine, bottomPad,
    } = this.props
    let { canSave } = this.props
    const {
      form: { formValid, formModel },
      formActions: { submitForm },
    } = this.context
    if (canSave && typeof canSave !== 'boolean') {
      canSave = canSave(formModel)
    }

    return (
      <ModalButtonGroup
        confirm={submitForm}
        cancel={cancel}
        canSave={formValid && canSave}
        cancelText={cancelText}
        confirmText={confirmText}
        hideLine={hideLine}
        bottomPad={bottomPad}
      />
    )
  }
}
