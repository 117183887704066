import React from 'react';
import { number, string } from 'prop-types';
import COLOURS from 'util/colours';
import theme from 'css/themes/campaign-quickview.css'

export default class StatsPie extends React.Component {
  static propTypes = {
    completed: number.isRequired,
    total: number.isRequired,
    label: string.isRequired
  };

  render() {
    const {
      completed,
      total,
      label
    } = this.props;

    const percentage = total
      ? Math.round((completed / total) * 100)
      : 0
    const remaining = 100 - percentage;

    const barColour = COLOURS.azure;
    const barBackColour = COLOURS.silver;
    const textColour = COLOURS.ink;

    return(
      <div style={{
        width: '82px',
        height: '82px',
        marginTop: '13px',
        marginRight: 'auto',
        marginLeft: 'auto'
      }}>
        {/* thanks to https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72 for the ideas/help */}
        <svg width="100%" height="100%" viewBox="0 0 42 42" className={theme.statsPie}>
          <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent" />
          <g className={theme.statsPieText}>
            <text className={theme.statsPieNumber} x="50%" y="50%"  fill={ textColour }>
              { total - completed }
            </text>
            <text className={theme.statsPieLabel} x="50%" y="50%"  fill={ textColour }>
              { label }
            </text>
          </g>
          <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={ barBackColour } strokeWidth="5px" />
          <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={ barColour } strokeWidth="5px" strokeDasharray={ `${percentage} ${remaining}`} strokeDashoffset="25" />
        </svg>
      </div>
    )
  }
};
