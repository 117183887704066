import React from 'react'
import _ from 'lodash'
import CampaignStatus from './campaign-status'
import BonusEntryStatus from './bonus-entry-status'
import BonusItem from './bonus-item'
import PostEntryMessage from './post-entry-message'
import CtaImage from './cta/cta-small-image'
import CtaButton from './cta/cta-button'
import CtaVideo from './cta/cta-video'
import CtaFullPage from './cta/cta-full-page-image'
import MobileMarketingPostEntryForm from './mobile-marketing/mobile-marketing-post-entry-form'

const CtaMap = {
  button: CtaButton,
  smallImage: CtaImage,
  video: CtaVideo,
  fullImage: CtaFullPage,
}

class CallToAction extends React.Component {
  render() {
    const { postEntry, bonuses: oldBonuses } = this.props
    const { mobileMarketing } = postEntry
    const bonuses = _.clone(oldBonuses)

    let Cta = null

    if (CtaMap[postEntry.ctaType]) Cta = CtaMap[postEntry.ctaType]

    let ctaUrl = postEntry.ctaUrl || ''

    if (ctaUrl && ctaUrl.indexOf('http') === -1) ctaUrl = `http://${ctaUrl}`

    const isFullImage = postEntry.ctaType === 'fullImage'

    const order = postEntry.bonusOrder ? postEntry.bonusOrder.order : []
    const items = []

    _.each(order, id => {
      const item = _.find(bonuses, { id: parseInt(id, 10) })
      if (item) {
        _.remove(bonuses, { id: parseInt(id, 10) })
        items.push(item)
      }
    })

    _.each(bonuses, bonus => {
      const inPresentation = _.find(items, { id: bonus.id })
      if (!inPresentation) {
        items.push(bonus)
      }
    })

    const bonusItems = _.map(items, (bonus, index) => (
      <BonusItem
        key={bonus.id}
        bonus={_.merge({}, bonus.data, { bonus_id: bonus.id })}
        bonusKey={bonus.bonus_type}
        context={postEntry.bonus}
        index={index}
        largeCTA={isFullImage}
      />
    ))

    const className = `post-entry-modalish unused post-page-${postEntry.invitee.id}`

    const isMobileMarketing = postEntry.ctaType === 'mobileMarketing'
    // Criteria for showing mobile marketing
    // 1) cta type must be mobileMarketing
    // 2) the mobile marketing status must be published
    const showSmsPostEntry = isMobileMarketing
      && mobileMarketing
      && (
        mobileMarketing.smsSend.status === 'published'
        || mobileMarketing.smsSend.status === 'draft'
      )
    const isPreview = mobileMarketing && mobileMarketing.smsSend.status !== 'published'

    return (
      <div className={className}>
        <div className="postentry_modal__modal-overlay" id="modal-overlay">
          <div className={`postentry_modal__modal ${isFullImage ? 'isFullImage' : ''}`} id="modal">
            <div className="postentry_modal__modal-guts">
              <div>
                {
                  showSmsPostEntry ? (
                    <MobileMarketingPostEntryForm
                      cta={mobileMarketing}
                      brandName={postEntry.invitee.accountname}
                      preview={isPreview}
                    />
                  ) : (
                    <>
                      <PostEntryMessage message={postEntry.postEntryMessage} />
                      {
                        Cta
                          ? <Cta cta={postEntry} />
                          : null
                      }
                    </>
                  )
                }
              </div>
              <div className={!bonusItems.length ? 'hide bonus-items-holder' : ''}>
                <BonusEntryStatus isButton={postEntry.ctaType === 'button'} />
                <div className="row bonus-items">
                  { bonusItems }
                </div>
                <CampaignStatus {...this.props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CallToAction
