import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import CreateAnnouncement from 'visual-components/announcements/create-announcement'
import AnnouncementItem from 'visual-components/announcements/announcement-item'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'

const mapStateToProps = ({ currentCampaign, currentBrand }) => ({ currentCampaign, currentBrand })

class Announcements extends React.Component {
  render() {
    let {
      currentCampaign: { id: campaignId, hostBrandId, invites, announcements },
      currentCampaign,
      currentBrand
    } = this.props

    const isHost = hostBrandId === currentBrand.id

    const acceptedInvites = invites.filter(invite => invite.status === 'accepted'
      && invite.invitee_id !== hostBrandId)

    const announcementItems = announcements.map(announcement => (
      <AnnouncementItem key={announcement.date} announcement={announcement} invites={invites} />
    ))

    return (
      <div style={{
        paddingTop: '16px'
      }}>
        <Helmet>
          <title>{ 'Announcements - DojoMojo' }</title>
        </Helmet>

        {
          acceptedInvites.length ?
          <div>
            {
              isHost ?
              <CreateAnnouncement currentCampaign={ currentCampaign }
                currentBrand={ currentBrand }
                acceptedInvites={ acceptedInvites }
              />
              : null
            }
            {
              !announcementItems.length ?
              <EmptyBonzai description={ `No announcements yet! ${isHost ? 'Compose one above to message all your Partners at once.' : 'Keep an eye out here for announcements from the Host.'}` } noTree />
              : announcementItems
            }
          </div>
          :
          <div style={{
            marginTop: '78px'
          }}>
            <EmptyBonzai description={ `Uh Oh! You don't have any partners on your campaign - you can only send announcements when you have partners.` } noTree />
          </div>
        }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(Announcements))
