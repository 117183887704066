import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { H4 } from 'visual-components/util/texts'
import * as DialogActions from 'actions/dialog-actions'

const mapStateToProps = ({ rulesPublishedWarning }) => ({ rulesPublishedWarning })
const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
})

class TermsWarnModal extends Component {

  closeModal = () => {
    this.props.dialogActions.closeDialog('rulesPublishedWarning')
  }

  render() {
    const {
      rulesPublishedWarning: { open }
    } = this.props

    return (
      <Dialog
        title="Warning! Campaign Has Already Started"
        theme={ dialogTheme }
        active={ open }
        onEscDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
      >

        <div className={`${dialogTheme.banner}`} style={{ paddingTop: '8px' }}>
          <img src="/images/icons/error-attention-banner.svg" />
        </div>

        <H4 multiline>Making changes to Campaign's Official Rules after a campaign has started is done at your own risk.</H4>

        <ModalButtonGroup
          canSave
          confirm={ this.closeModal }
          confirmText={ 'Change At My Own Risk' }
          cancel={ this.closeModal }
          cancelText={'Cancel'}
          hideLine
        />
      </Dialog>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsWarnModal))
