import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'

import SoloWinnerCard from 'visual-components/quickview/solo-winner-card'
import SoloStatsCard from 'visual-components/quickview/solo-stats-card'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'

const mapStateToProps = ({ currentCampaign, analyticsCampaign }) =>
  ({ currentCampaign, analyticsCampaign })

const styles = {
  container: {
    width: '100%',
  },
}

class SoloStatsWinnerCards extends React.Component {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    analyticsCampaign: PropTypes.object.isRequired,
    chooseWinner: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { currentCampaign } = this.props
    window.scrollTo(0, 0)
    AnalyticsDispatcher.loadDefaultCampaignAnalytics(currentCampaign.id)
  }

  componentWillUnmount() {
    store.dispatch({
      model: 'analyticsCampaign',
      type: 'CLEAR',
    })
  }

  render() {
    const {
      currentCampaign,
      analyticsCampaign,
      chooseWinner,
      classes,
    } = this.props

    const {
      id: campaignId,
    } = currentCampaign

    const completed = currentCampaign.endDate < new Date()

    const { totalEntries = 0, totalVisits = 0 } = currentCampaign.stats || {}

    return (
      <div className={classes.container}>
        {completed && (
          <SoloWinnerCard
            currentCampaign={currentCampaign}
            chooseWinner={chooseWinner}
          />
        )}
        {!!totalVisits && (
          <SoloStatsCard
            loading={analyticsCampaign.loading}
            campaignId={campaignId}
            visits={totalVisits}
            entries={totalEntries}
            cta={analyticsCampaign.campaignCustomCTA
              ? analyticsCampaign.campaignCustomCTA.data[0]
              : {}}
            smsSend={analyticsCampaign.campaign ? analyticsCampaign.campaign.smsSend : {}}
          />
        )}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(injectSheet(styles)(SoloStatsWinnerCards)))
