import React from 'react'
import { func, string, bool } from 'prop-types'
import { P } from 'visual-components/util/texts'
import OverviewTheme from 'css/themes/Overview.css'

class NavItem extends React.Component {
  static propTypes = {
    selected: bool.isRequired,
    label: string.isRequired,
    selectTabFunc: func.isRequired,
    type: string.isRequired,
  }

  render() {
    const {
      label,
      type,
      selected,
      selectTabFunc,
    } = this.props

    const LabelComponent = selected ? 'i' : 'span'

    return (
      <div className={`${OverviewTheme.navItem} ${selected ? OverviewTheme.assetSelected : ''}`} onClick={() => selectTabFunc(type)}>
        <div className={`${OverviewTheme.navLabelHolder} ${OverviewTheme.navLabelAssets}`}>
          <P ink>
            <LabelComponent>{label}</LabelComponent>
          </P>
        </div>
      </div>
    )
  }
}

export default NavItem
