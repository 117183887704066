import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-toolbox/lib/link'
import AppBarTheme from 'css/themes/app-bar-theme.css'

class NavBrandLogo extends PureComponent {
  render() {
    const { onclick, imgSrc } = this.props

    return (
      <Link href="/" className={AppBarTheme.logo} onClick={onclick}>
        <div
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
            height: 32,
            width: 32,
            backgroundColor: 'white',
          }}
        >
          <img src={imgSrc} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
        </div>
      </Link>
    )
  }
}

NavBrandLogo.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onclick: PropTypes.func.isRequired,
}

export default NavBrandLogo
