import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import Field from 'visual-components/util/inputs/field'
import { P, Micro } from 'visual-components/util/texts'

import Checkbox from 'react-toolbox/lib/checkbox'
import CheckboxTheme from 'css/themes/Checkbox.css'
import Theme from 'css/themes/checkbox-with-input.css'

import 'css/themes/checkbox-with-input.css'

const Separator = () => (
  <div className={Theme.separator} />
)

class CheckboxWithInput extends Component {
  static propTypes = {
    validators: PropTypes.array,
    canCheck: PropTypes.bool.isRequired,
    isChecked: PropTypes.bool,
    onError: PropTypes.func,
    label: PropTypes.string,
    formatter: PropTypes.func,
    raiseError: PropTypes.bool,
    customErrorMsg: PropTypes.string,
    onBlur: PropTypes.func,
    onCheck: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pad: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    secondInput: PropTypes.bool,
    secondPlaceholder: PropTypes.string,
    secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    validators: null,
    formatter: null,
    raiseError: false,
    customErrorMsg: null,
    onError() {},
    onCheck() {},
    onBlur() {},
    onChange() {},
    pad: false,
    isChecked: false,
    label: 'Label',
    value: null,
    placeholder: 'Placeholder',
    secondInput: false,
    secondPlaceholder: 'Placeholder',
    secondValue: null,
  }

  state = {
    first: null,
    second: null,
    errorMsg: null,
  }

  removeSigns = val => val.replace(/(?!\.)[\D]/g, '')

  onCheck = () => {
    const { onCheck, isChecked } = this.props

    return onCheck(!isChecked)
  }

  onInputUpdate = (name, val) => {
    const { isChecked } = this.state
    const {
      canCheck,
      secondInput,
      validators,
      onChange,
      onError,
    } = this.props

    // Remove any signs
    const newVal = this.removeSigns(val)

    let hasError = false
    let errorMsg = null

    if (!isChecked && !canCheck) return null

    if (validators) {
      hasError = !validators.every(validator => {
        try {
          validator(newVal)
          return true
        } catch (err) {
          errorMsg = err
        }

        return false
      })
    }

    const inputName = secondInput ? name : 'first'

    if (onChange) {
      if (secondInput) {
        onChange({ [name]: newVal })
      } else onChange(newVal)
    }

    return this.setState({ [inputName]: newVal, errorMsg }, () => onError(hasError))
  }

  onBlur = name => () => {
    const { onBlur, formatter, secondInput } = this.props
    const { [name]: val } = this.state

    if (!val) return

    const value = this.removeSigns(val)
    let state = null

    if (secondInput) state = { [name]: value }
    else state = value

    if (onBlur) onBlur(state)
    if (formatter && value) {
      this.setState({ [name]: formatter(value) })
    }
  }

  renderLabel = () => {
    const { label, canCheck } = this.props

    return (
      <P onClick={this.onCheck} cloudGrey={!canCheck}>
        {label}
      </P>
    )
  }

  componentDidMount = () => {
    let {
      value,
      secondValue,
      isChecked,
      formatter,
    } = this.props

    if (formatter) {
      value = value ? formatter(value) : null
      secondValue = secondValue ? formatter(secondValue) : null
    }

    return this.setState({
      first: value,
      second: secondValue,
      isChecked,
    })
  }

  render() {
    const {
      canCheck,
      isChecked,
      placeholder,
      pad,
      secondInput,
      secondPlaceholder,
      raiseError,
      customErrorMsg,
    } = this.props

    const { errorMsg } = this.state

    let divClassNames = `${Theme.checkboxWithInput} ${(errorMsg || raiseError) && isChecked ? Theme.checkboxWithInputWarning : ''}`
    divClassNames = `${divClassNames} ${secondInput ? Theme.checkboxWithDoubleInput : Theme.checkboxWithInputSingle}`

    const inputTheme = secondInput ? Theme.inputDouble : Theme.input

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginRight: pad ? '8px' : null }} className={divClassNames}>
          <Checkbox
            label={this.renderLabel()}
            theme={CheckboxTheme}
            className={Theme.checkbox}
            disabled={!canCheck}
            checked={isChecked}
            onChange={this.onCheck}
          />
          <Separator />
          <Field
            dataModel={this.state}
            formModel={this.state}
            focus={this.onFocus}
            blur={this.onBlur('first')}
            disabled={!isChecked}
            attrName="first"
            updateAttr={this.onInputUpdate}
            theme={Theme}
            className={inputTheme}
            placeholder={placeholder}
          />
          {
            secondInput
            && (
              <React.Fragment>
                <Separator />
                <Field
                  dataModel={this.state}
                  formModel={this.state}
                  focus={this.onFocus}
                  blur={this.onBlur('second')}
                  disabled={!isChecked}
                  attrName="second"
                  updateAttr={this.onInputUpdate}
                  theme={Theme}
                  className={inputTheme}
                  placeholder={secondPlaceholder}
                />
              </React.Fragment>
            )
          }
        </div>
        <div className={Theme.errorMsg}>
          {
            isChecked
            && (raiseError || errorMsg)
            && (
              <Micro alertRed>
                { customErrorMsg || errorMsg }
              </Micro>
            )
          }
        </div>
      </div>
    )
  }
}

export default CheckboxWithInput
