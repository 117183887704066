import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object, func, string, bool } from 'prop-types'
import { Dialog } from 'react-toolbox/lib/dialog'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import * as BackgroundImageActions from 'actions/background-image-actions'
import * as metrics from 'util/metrics'
import SearchTabs from './search-image-tabs'

const mapStateToProps = ({ currentBrand, backgroundImages }) =>
  ({ currentBrand, backgroundImages })

const mapDispatchToProps = dispatch => ({
  backgroundImageActions: bindActionCreators(BackgroundImageActions, dispatch),
})

class SearchImageModal extends React.Component {
  static propTypes = {
    active: bool,
    currentBrand: object.isRequired,
    backgroundType: string.isRequired,
    backgroundImages: object.isRequired,
    backgroundImageActions: object.isRequired,
    updateAttr: func.isRequired,
    changeImage: func.isRequired,
    closeModal: func.isRequired,
  }

  static defaultProps = {
    active: false,
  }

  static contextTypes = {
    impersonating: string,
  }

  componentDidUpdate(prevProps) {
    const { active, backgroundType, backgroundImageActions } = this.props
    if (!prevProps.active && active) {
      backgroundImageActions.fetchStockSearch({ backgroundType })
    }
  }

  insert = async () => {
    const {
      currentBrand: { id: brandId },
      backgroundType,
      backgroundImages: { selectedImage },
      backgroundImageActions: { uploadStock },
      updateAttr,
      changeImage,
    } = this.props

    const { impersonating } = this.context

    const src = selectedImage.stock_id
      ? selectedImage.src
      : await uploadStock(selectedImage, brandId, impersonating)

    if (src) {
      metrics.create(`campaignManagerDownloadedBackgound${backgroundType}`, {
        meta: {
          source: selectedImage.type,
        },
      })
      updateAttr('mainImage', src, true)
      changeImage(true)
      this.closeModal()
    }
  }

  closeModal = () => {
    const {
      closeModal,
      backgroundImageActions: { updateAttr, clear },
    } = this.props

    clear()
    updateAttr({ searchPage: 1, searchQuery: '', selectedImage: null })
    closeModal()
  }

  render() {
    const {
      active,
      backgroundType,
      backgroundImages,
      backgroundImageActions,
    } = this.props

    const { selectedImage, downloading } = backgroundImages

    return (
      <div>
        <Dialog
          active={active}
          title={`${backgroundType} Search`}
          theme={DialogTheme}
          onEscKeyDown={this.closeModal}
          onOverlayClick={this.closeModal}
        >
          <div>
            <img
              onClick={this.closeModal}
              className={DialogTheme.closeImage}
              src="/images/icons/close.svg"
            />

            <SearchTabs
              backgroundType={backgroundType}
              backgroundImages={backgroundImages}
              backgroundImageActions={backgroundImageActions}
            />

            <ModalButtonGroup
              confirm={this.insert}
              confirmText="Insert"
              cancel={downloading ? null : this.closeModal}
              canSave={selectedImage && !downloading}
            />
          </div>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchImageModal)
