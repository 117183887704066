import { Button } from 'react-toolbox/lib/button'
import { object } from 'prop-types';

const ContextButton = (props, context) => {
  return (
    <Button
      {...props}
      onClick={context.formActions.submitForm}
    />
  )
}

ContextButton.contextTypes = {
  form: object.isRequired,
  formActions: object.isRequired,
}

export default ContextButton
