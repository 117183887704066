import React, { Component } from 'react'
import { bool } from 'prop-types'
import { Helmet } from 'react-helmet'

import { Redirect } from 'react-router-dom'
import { Switch, Route } from 'login/visual-components/decorated-router-components'

import Home from './visual-components/Home/Home'
import Sweepstakes from './visual-components/Sweepstakes/Sweepstakes'
import SmsMarketing from './visual-components/SmsMarketing/SmsMarketing'
import Network from './visual-components/Network/Network'
import Pricing from './visual-components/Pricing/Pricing'
import Careers from './visual-components/Careers/Careers'

import ContactSupportModal from 'visual-components/util/modals/contact-support-modal'
import PriceEliteModal from 'visual-components/util/modals/pricing-elite-modal'

/* resources */
import Resources from './visual-components/Resources/Resources'
import ResourcesForEcommerce from './visual-components/Resources/ResourcesForEcommerce'
import ResourcesForMedia from './visual-components/Resources/ResourcesForMedia'
import ResourcesForStartups from './visual-components/Resources/ResourcesForStartups'
import ResourcesForEnterprise from './visual-components/Resources/ResourcesForEnterprise'

/* terms and such docs */
import TermsOfUse from 'util/components/docs/terms-of-use'
import PrivacyPolicy from 'util/components/docs/privacy-policy'
import CopyrightPolicy from 'util/components/docs/copyright-policy'
import AcceptableUsePolicy from 'util/components/docs/acceptable-use-policy'
import CTAAdvertiserTerms from 'util/components/docs/cta-advertiser-terms'
import CTAPurchaserTerms from 'util/components/docs/cta-purchaser-terms'
import ReferralsTerms from 'util/components/docs/referral-terms'

import Register from 'login/components/register'
import UpdatePassword from 'login/components/update-password'
import ForgotPassword from 'login/components/forgot-password'

/* mobile pages */
import MobileHome from './mobile-visual-components/MobileHome/MobileHome'
import MobileSweepstakes from './mobile-visual-components/MobileSweepstakes/MobileSweepstakes'
import MobileSmsMarketing from './mobile-visual-components/MobileSmsMarketing/SmsMarketing'
import MobileNetwork from './mobile-visual-components/MobileNetwork/MobileNetwork'
import MobileCareers from './mobile-visual-components/MobileCareers/MobileCareers'
import MobilePricing from './mobile-visual-components/MobilePricing/MobilePricing'

/* mobile resources */
import MobileResources from './mobile-visual-components/MobileResources/MobileResources'

/* case studies */
import CaseStudy from './visual-components/Resources/CaseStudy'
import MobileCaseStudy from './mobile-visual-components/MobileResources/MobileCaseStudy'

import ViewMetrics from 'v2/components/util/ViewMetrics'
import SendGAPageViews from 'v2/components/util/SendGAPageViews'

import PublicRoutes from './visual-components/Public/PublicRoutes'




class App extends Component {
  static propTypes = {
    forceMobile: bool,
  }

  render() {
    const { forceMobile } = this.props

    return (
      <div>
        <Helmet titleTemplate="DojoMojo - %s">
          <title>The Home of Partnership Marketing</title>
          <meta content="Growth Acquisition Made Easy. DojoMojo is a platform to build a true end-to-end funnel for customer growth and engagement." name="description" />
          <meta property="og:title" content="DojoMojo" />
          <meta
            property="og:description"
            content="Growth Acquisition Made Easy. DojoMojo is a platform to build a true end-to-end funnel for customer growth and engagement."
          />
          <meta property="og:type" content="website" />
          <style type="text/css">{`
            @media (max-width: 600px) { html, body { width: 100%; overflow-x: hidden; }
          `}</style>
          <meta
            name="viewport"
            content="user-scalable=no, width=device-width, initial-scale=1.0"
          />
          <meta name="apple-mobile-web-app-capable" content="yes" />
        </Helmet>
        <ViewMetrics />
        <SendGAPageViews/>
        <Switch forceMobile={forceMobile}>
          <Route exact path="/"
            component={Home}
            mobileComponent={MobileHome} />
          <Route path="/sweepstakes"
            component={Sweepstakes}
            mobileComponent={MobileSweepstakes} />
          <Route
            path="/sms-marketing"
            component={SmsMarketing}
            mobileComponent={MobileSmsMarketing}
          />
          <Route path="/network"
            component={Network}
            mobileComponent={MobileNetwork} />
          <Route path="/pricing"
            component={Pricing}
            mobileComponent={MobilePricing} />
          <Route exact path="/careers"
            component={Careers}
            mobileComponent={MobileCareers} />
          <Route path="/resources/all"
            component={Resources}
            mobileComponent={MobileResources} />
          <Route path="/resources/for-ecommerce"
            component={ResourcesForEcommerce}
            mobileComponent={MobileResources} />
          <Route path="/resources/for-media"
            component={ResourcesForMedia}
            mobileComponent={MobileResources} />
          <Route path="/resources/for-startups"
            component={ResourcesForStartups}
            mobileComponent={MobileResources} />
          <Route path="/resources/for-enterprise"
            component={ResourcesForEnterprise}
            mobileComponent={MobileResources} />

          <Route path="/case_studies/:case"
            component={CaseStudy}
            mobileComponent={MobileCaseStudy} />

          <Route path="/(brand|opportunities)" render={() => (
            <PublicRoutes forceMobile={forceMobile} />
          )} />

          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/cta-advertiser-terms" component={CTAAdvertiserTerms} />
          <Route path="/cta-purchaser-terms" component={CTAPurchaserTerms} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/copyright" component={CopyrightPolicy} />
          <Route path="/acceptable-use" component={AcceptableUsePolicy} />
          <Route path="/referral-terms-conditions" component={ReferralsTerms} />

          <Route path="/register/:referralId?" component={Register}/>
          <Route path="/update-password" component={UpdatePassword} />
          <Route path="/forgot" component={ForgotPassword} />
          {/* <Route path="/elite-request" component={EliteRequest} />*/}

          <Route render={() => <Redirect to="/" />} />
        </Switch>
        <ContactSupportModal />
        <PriceEliteModal />
      </div>
    )
  }
}

export default App
