import ReferralConstants from 'constants/referral-constants';
import { validate as validateEmail } from 'email-validator';

let CLIENT_ID = '404239621863-91343h3ucpqvnaecv7vpghru823e2jai.apps.googleusercontent.com'
if (process.env.NODE_ENV === 'production') {
  CLIENT_ID = '404239621863-p7frspbtj1kd6dlu94sh089pgc212op2.apps.googleusercontent.com'
}

const CONTACTS_SCOPE = 'https://www.googleapis.com/auth/contacts.readonly';

export const loadBrandReferrals = () => {
  return {
    types: [
      ReferralConstants.YOUR_REFERRALS_REQUEST,
      ReferralConstants.YOUR_REFERRALS_SUCCESS,
      ReferralConstants.YOUR_REFERRALS_FAILURE
    ],
    http: {
      url: `/referrals`,
      method: 'GET'
    }
  }
}

export const loadRaffleWinner = () => {
  return {
    types: [
      ReferralConstants.RAFFLE_WINNER_REQUEST,
      ReferralConstants.RAFFLE_WINNER_SUCCESS,
      ReferralConstants.RAFFLE_WINNER_FAILURE
    ],
    http: {
      url: `/referrals/last-winner`,
      method: 'GET'
    }
  }
}

export const loadUnreadCount = () => {
  return {
    types: [
      ReferralConstants.REFERRAL_UNREAD_COUNT_REQUEST,
      ReferralConstants.REFERRAL_UNREAD_COUNT_SUCCESS,
      ReferralConstants.REFERRAL_UNREAD_COUNT_FAILURE,
    ],
    http: {
      url: `/notification-unread-count/referral_joined`
    }
  }
}

export const startGmailImport = () => {
  return dispatch => {
    window.gapi.auth.authorize(
      {
        clientId: CLIENT_ID,
        scope: CONTACTS_SCOPE,
      },
      function() {
        dispatch(getGmailContacts(window.gapi.auth.getToken()));
      }
    );
  };
};

export const getGmailContacts = ({ access_token }) => {
  return {
    types: [
      ReferralConstants.GMAIL_CONTACTS_REQUEST,
      ReferralConstants.GMAIL_CONTACTS_SUCCESS,
      ReferralConstants.GMAIL_CONTACTS_FAILURE,
    ],
    http: {
      url: `/referrals/gmail-import`,
      method: 'GET',
      data: {
        access_token
      },
    },
  };
};

export const sendGmailInvites = emailList => {
  return {
    types: [
      ReferralConstants.REFERRAL_GMAIL_INVITES_REQUEST,
      ReferralConstants.REFERRAL_GMAIL_INVITES_SUCCESS,
      ReferralConstants.REFERRAL_GMAIL_INVITES_FAILURE,
    ],
    http: {
      method: 'POST',
      url: '/referrals/invites',
      data: {
        data: emailList
      },
    },
  };
};

export const sendInvites = emailList => {
  return {
    types: [
      ReferralConstants.REFERRAL_INVITES_REQUEST,
      ReferralConstants.REFERRAL_INVITES_SUCCESS,
      ReferralConstants.REFERRAL_INVITES_FAILURE,
    ],
    http: {
      method: 'POST',
      url: '/referrals/invites',
      data: {
        data: emailList
      },
    },
  };
};

export const sendReminder = referralId => {
  return {
    types: [
      ReferralConstants.REFERRAL_REMINDER_REQUEST,
      ReferralConstants.REFERRAL_REMINDER_SUCCESS,
      ReferralConstants.REFERRAL_REMINDER_FAILURE,
    ],
    referralId,
    http: {
      method: 'GET',
      url: `/referrals/send-reminder-email/${referralId}`,
      dataType: 'text'
    },
  }
}

export const searchImportedContacts = query => {
  return {
    type: ReferralConstants.SEARCH_IMPORTED_CONTACTS,
    query,
  };
};

export const toggleContact = contact => {
  return {
    type: ReferralConstants.SELECT_CONTACT,
    contact,
  };
};

export const closeGmailModal = () => {
  return {
    type: ReferralConstants.CLOSE_GMAIL_IMPORT_MODAL,
  };
};

export const openReminderModal = referral => {
  return {
    type: ReferralConstants.OPEN_REMINDER_MODAL,
    referral
  }
}

export const closeReminderModal = () => {
  return {
    type: ReferralConstants.CLOSE_REMINDER_MODAL,
  }
}
export const toggleCheckAllContacts = () => {
  return {
    type: ReferralConstants.TOGGLE_SELECT_ALL_CONTACTS,
  };
};

export const addEmailToInviteInput = (email) => {
  return {
    types: [ReferralConstants.ADD_EMAIL_TO_INVITE_INPUT_REQUEST, ReferralConstants.ADD_EMAIL_TO_INVITE_INPUT_SUCCESS, ReferralConstants.ADD_EMAIL_TO_INVITE_INPUT_FAILURE],
    http: {
      url: `/test-valid-referral-email/${encodeURIComponent(email)}`,
      dataType: 'text'
    },
    email
  };
}

export const removeEmailFromInviteInput = (email) => {
  return {
    type: ReferralConstants.REMOVE_EMAIL_FROM_INVITE_INPUT,
    email
  };
}

export const openPlatformInviteModal = (data={}) => {
  return {
    model: 'platformInviteBrand',
    type: 'UPDATE_ATTR',
    data: {
      ...data,
      open: true,
    }
  }
}
