import React, { Component } from 'react'
import { H3, H6, P } from 'visual-components/util/texts'
import theme from 'css/themes/custom-input-theme.css'
import Field from 'visual-components/util/inputs/field'

import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import RadioTheme from 'css/themes/Radio.css'

import DropdownForm from './dropdown-form'

import CustomFieldDispatcher from 'dispatchers/custom-field-dispatcher'

class CustomInputForm extends Component {
  onRadioChange = type =>
    CustomFieldDispatcher.updateCustomField(this.props.index, { type })

  updateVal = (attr, val) =>
    CustomFieldDispatcher.updateCustomField(this.props.index, { [attr]: val })

  deleteCustomField = () => CustomFieldDispatcher.delete(this.props.index)

  render(){
    const {
      data,
      index,
      disabled,
    } = this.props

    const formMap = {
      text: null,
      date: null,
      select: <DropdownForm data={ data } updateVal={ this.updateVal } index={ index } disabled={disabled} />
    }

    return(
      <div className={ theme.container }>
        <div className={ theme.titleRow }>
          <H3>
            { `Custom Field ${index + 1}` }
          </H3>
          {!disabled &&
            <div style={{
              paddingTop: '3px'
            }}>
              <P>
                <a onClick={ this.deleteCustomField }>
                  Delete This Field
                </a>
              </P>
            </div>}
        </div>

        <div className={ theme.fieldLabel }>
          <Field label="Custom Field Label"
            editable={!disabled}
            attrName="label"
            updateAttr={ this.updateVal }
            dataModel={ data }
            formModel={ {} }
            placeholder="I.e. How frequently do you travel internationally?"
          />
          <div className={ theme.labelWrapper }>
            <H6>
                Custom Field Type
            </H6>
          </div>
          <div className={ theme.radioGroup }>
            <RadioGroup
              disabled={disabled}
              name={ 'customFieldType' }
              value={ data.type }
              onChange={ this.onRadioChange }
              className={ theme.inlineRadioRow }
            >
              <RadioButton
                label="Blank Text Field"
                value="text"
                theme={RadioTheme}
                className={theme.inlineButtons}
              />
              <RadioButton
                label="Date Selector (MM/DD/YYYY)"
                value="date"
                theme={RadioTheme}
                className={theme.inlineButtons}
              />
              <RadioButton
                label="Dropdown"
                value="select"
                theme={RadioTheme}
                className={theme.inlineButtons}
              />
            </RadioGroup>

          </div>
          { formMap[data.type] }
        </div>

      </div>
    )
  }
}

export default CustomInputForm
