import React, { Component } from 'react'
import injectSheet from 'react-jss'
import {
  bool, func, string, array, object,
} from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import Dialog from 'react-toolbox/lib/dialog'
import { H4, H6 } from 'visual-components/util/texts'
import SearchInput from 'visual-components/util/inputs/search-input'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import FontPreview from 'visual-components/campaign-builder/custom-fonts/font-preview'
import SelectedFont from 'visual-components/campaign-builder/custom-fonts/selected-font'
import InfiniteScroll from 'visual-components/util/infinite-scroll'
import InvisibleScroll from 'visual-components/util/invisible-scroll'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import COLOURS from 'util/colours'

const styles = {
  searchInput: {
    marginTop: '16px',
  },
  fontBoxContainer: {
    margin: '16px auto 24px',
    display: 'flex',
  },
  fontBox: {
    width: '425px',
    height: '441px',
    border: `solid 1px ${COLOURS.silver}`,
  },
  selectedFontsContainer: {
    width: '175px',
    height: '441px',
    marginLeft: '8px',
    border: `solid 1px ${COLOURS.silver}`,
  },
  title: {
    padding: '12px',
    textAlign: 'left',
    borderBottom: `solid 1px ${COLOURS.silver}`,
  },
}

class CustomFontsModal extends Component {
  static propTypes = {
    active: bool,
    inputRef: string.isRequired,
    hideModal: func.isRequired,
    confirm: func.isRequired,
    selectedFonts: array.isRequired,
    updateFont: func.isRequired,
    classes: object.isRequired,
  }

  static defaultProps = {
    active: false,
  }

  state = {
    offset: 0,
    potentialFonts: [],
    query: '',
    totalFontsAvailable: 0,
  }

  componentDidMount() {
    this.loadFonts()
  }

  updateQuery = query => {
    this.setState({
      offset: 0,
      potentialFonts: [],
      query,
    }, () => {
      this.loadFonts()
    })
  }

  loadFonts = () => {
    const {
      query, offset, totalFontsAvailable, potentialFonts,
    } = this.state

    if (offset === 0 || !(offset >= totalFontsAvailable)) {
      $.post('/google-fonts/search', {
        query,
        offset,
      }, res => {
        this.setState({
          potentialFonts: potentialFonts.concat(res.fonts),
          offset: offset + res.fonts.length,
          totalFontsAvailable: res.total,
        })
      })
    }
  }

  render() {
    const {
      active,
      inputRef,
      hideModal,
      confirm,
      selectedFonts,
      updateFont,
      classes: css,
    } = this.props

    const {
      potentialFonts,
      query,
    } = this.state

    const fonts = potentialFonts.map((fontName, idx) => (
      <FontPreview
        key={fontName}
        name={fontName}
        selected={selectedFonts.includes(fontName)}
        updateFont={updateFont}
        idx={idx}
      />
    ))

    const mySelectedFonts = selectedFonts.map(fontName => (
      <div>
        <Helmet>
          <link rel="stylesheet" href={`//fonts.googleapis.com/css?family=${fontName.replace(/\s/g, '+')}`} />
        </Helmet>
        <SelectedFont
          key={`my${fontName}`}
          name={fontName}
          updateFont={updateFont}
        />
      </div>
    ))

    let customFontsLink
    if (potentialFonts.length) {
      customFontsLink = `//fonts.googleapis.com/css?family=${potentialFonts.map(font =>
        font.replace(/\s/g, '+')).join('|')}&text=ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`
    }

    return (
      <Dialog
        theme={DialogTheme}
        active={active}
        onEscKeyDown={hideModal}
        onOverlayClick={hideModal}
        title="Select Fonts"
      >
        { potentialFonts
          ? (
            <Helmet>
              <link rel="stylesheet" href={customFontsLink} />
            </Helmet>
          ) : null
        }

        <img
          onClick={hideModal}
          className={DialogTheme.closeImage}
          src="/images/icons/close.svg"
        />
        <H4 multiline>
          Fonts you select will be added to the top of your font list in the text editor.
        </H4>
        <div className={css.searchInput}>
          <SearchInput
            placeholder="Search for a font"
            value={query}
            onChange={this.updateQuery}
            inputRef={inputRef}
            className="lightShadow"
          />
        </div>
        <div className={css.fontBoxContainer}>
          <div className={css.fontBox}>
            <InfiniteScroll
              key={`infinite-scroll-${query}`}
              elements={fonts}
              elementsPerRow={2}
              rowHeight={120}
              buffer={800}
              handleScroll={_.debounce(this.loadFonts, 200)}
            />
          </div>
          <div className={css.selectedFontsContainer}>
            <div className={css.title}>
              <H6 coral>
                My Fonts
              </H6>
            </div>
            <div
              style={{
                width: '100%',
                height: '402px',
                paddingBottom: '15px',
              }}
            >
              <InvisibleScroll elements={mySelectedFonts} />
            </div>
          </div>
        </div>
        <ModalButtonGroup
          cancel={hideModal}
          cancelText="Cancel"
          confirm={confirm}
          confirmText="Done"
          canSave
        />
      </Dialog>
    )
  }
}

export default injectSheet(styles)(CustomFontsModal)
