import React, { Component } from 'react'
import {
  string, object, func, node, bool,
} from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import SearchActionsFactory from 'actions/search-actions-factory'
import * as MetricsActions from 'actions/analytics-actions'

import { debounce } from 'lodash'

import { Grid, Row, Col } from 'react-flexbox-grid'
import { H3, H5 } from 'visual-components/util/texts'
import ExplorePageTitle from 'visual-components/shared/explore/explore-page-title'
import SearchFilter from 'visual-components/shared/filters/SearchFilter'
import SortByFilter from 'visual-components/shared/filters/SortByFilter'
import GridListToggle from 'visual-components/analytics/util/grid-list-toggle'
import Spinner from 'util/components/spinner'
import Pagination from 'visual-components/util/filters/pagination'

import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'

import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'

const mapStateToProps = (state, ownProps) => ({
  searchModel: state[ownProps.config.model],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchActions: bindActionCreators(SearchActionsFactory(ownProps.config), dispatch),
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

// Base container component
export class ExploreContainer extends Component {
  static propTypes = {
    // mapped from Redux
    searchModel: object.isRequired,
    searchActions: object.isRequired,
    metricsActions: object.isRequired,

    // route
    history: object.isRequired,
    location: object.isRequired,

    // filters (optional)
    filters: node,

    // render prop -- determines how results are rendered
    render: func.isRequired,

    // other optional props
    title: string,
    titleAction: func,
    titleActionListOnly: func,
    titleActionLabel: string,
    belowTitle: node,
    renderAboveResults: func,
    searchPlaceholder: string,
    hideTitle: bool,
    hideSearchBar: bool,
    width: string,
  }

  static defaultProps = {
    filters: null,
    title: '',
    titleAction: null,
    titleActionListOnly: null,
    titleActionLabel: '',
    belowTitle: null,
    renderAboveResults: null,
    searchPlaceholder: 'Search',
    hideTitle: false,
    hideSearchBar: false,
    width: '792px',
  }

  static childContextTypes = {
    searchModel: object,
    handleChange: func,
    handleChangeImmediately: func,
    collapseFunction: func,
  }

  getChildContext() {
    const { searchModel } = this.props
    return {
      searchModel,
      handleChange: this.handleFilterChangeDebounced,
      handleChangeImmediately: this.handleFilterChangeImmediately,
      collapseFunction: this.handleFilterExpand,
    }
  }

  componentDidMount() {
    const {
      searchActions,
      title,
      location,
      location: { state },
    } = this.props
    // initial search
    // searchActions.clear()

    const page = (state && state.page) || 1

    // if initial filter, clear current search and add immediately
    if (state && state.initialFilter) {
      const { name, data } = state.initialFilter
      searchActions.clear()
      this.handleFilterExpand(name, true)
      this.handleFilterChangeImmediately(name, data)
    } else {
      this.search(page)
    }

    const splitPathname = location.pathname.split('/')
    const pageURI = splitPathname[splitPathname.length - 1]

    // Track only these sections
    if (['content_swap', 'product_shipment_insert', 'dedicated_email_sell'].includes(pageURI)) {
      const page_title = title
      gtagPageTrack({ page_title, location })
    }
  }

  componentDidUpdate(prevProps) {
    const { title, location, searchModel } = this.props
    const splitPathname = location.pathname.split('/')
    const pageURI = splitPathname[splitPathname.length - 1]

    // Track only these sections
    if ((title !== prevProps.title) && ['content_swap', 'product_shipment_insert', 'dedicated_email_sell'].includes(pageURI)) {
      const page_title = title
      gtagPageTrack({ page_title, location })
    }

    if (searchModel.searchValue !== prevProps.searchModel.searchValue) {
      this.trackAnalytics(searchModel)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      history,
      location: { pathname, state },
      searchActions,
    } = this.props
    // if pathname different, reset filters
    if (pathname !== nextProps.location.pathname) {
      searchActions.clear()
      return
    }

    // if no incoming location state, redirect to location with page 1
    if (!nextProps.location.state) {
      history.replace({ pathname, state: { page: 1 } })
      return
    }
    // handle page change
    const { state: nextState } = nextProps.location
    if (nextState && (!state || state.page !== nextState.page)) {
      this.immediateSearch(nextState.page)
      window.scrollTo(0, 0)
    }
  }

  search = page => {
    const {
      history,
      location: { pathname },
      searchActions: { updateModel, applySearch },
    } = this.props

    history.replace({ pathname, state: { page } })

    updateModel({ page })

    applySearch(page)
  }

  // eslint-disable-next-line react/sort-comp
  debouncedSearch = debounce(this.search, 600)

  // cancels debounced searches first
  immediateSearch = page => {
    this.debouncedSearch.cancel()
    this.search(page)
  }

  updateModel = data => {
    const {
      searchActions: { updateModel },
    } = this.props
    updateModel(data)
  }

  handleFilterExpand = (name, override) => {
    const { searchModel } = this.props
    const expanded = searchModel.filtersExpanded[name]
    this.updateModel({
      filtersExpanded: {
        // override needs double bang because value must
        // be boolean (not undefined)
        [name]: !expanded || !!override,
      },
    })
  }

  handleFilterChange = (immediate, name, val) => {
    const data = { [name]: val, filtered: true }

    this.updateModel(data)

    // don't search immediately if selecting custom
    if (val && val.value === 'custom') {
      return
    }

    if (immediate) {
      this.immediateSearch()
    } else {
      this.debouncedSearch()
    }
  }

  handleSearchChange = val => {
    const {
      searchModel: {
        sortBy,
      },
    } = this.props
    const { options } = sortBy
    const hasScoreFilter = !!options.find(option => option.value === '_score')
    if (val && sortBy && sortBy.value !== '_score' && hasScoreFilter) {
      this.handleFilterChange(false, 'sortBy', { value: '_score', asc: false })
    }

    this.handleFilterChange(false, 'searchValue', val)
  }

  handleFilterChangeImmediately = this.handleFilterChange.bind(this, true)

  handleFilterChangeDebounced = this.handleFilterChange.bind(this, false)

  changeView = gridView => this.updateModel({ gridView })

  reset = () => {
    const { searchActions } = this.props
    searchActions.clear()
    this.search()
  }

  trackOnFocus = () => {
    const { title } = this.props
    if (title === 'Campaigns') {
      EventTrackingDispatcher.track('appExploreCampaign_explore_searchInput_campaigns')
    } else if (title === 'Brands') {
      EventTrackingDispatcher.track('appExploreBrands_explore_searchInput_brands')
    }
  }

  trackAnalytics = debounce(searchModel => {
    const { metricsActions } = this.props

    metricsActions.create('executedSearch', {
      meta: {
        index: searchModel.rid,
        query: searchModel.searchValue,
        results: searchModel.results.slice(0, 10).map(result => result.id),
      },
    })
  }, 500)

  render() {
    const {
      searchModel,
      title,
      titleAction,
      titleActionListOnly,
      titleActionLabel,
      belowTitle,
      searchPlaceholder,
      renderAboveResults,
      filters,
      hideTitle,
      hideSearchBar,
      location,
      render,
      width,
    } = this.props


    const {
      results,
      loading,
      error,
      filtered,
      gridView,
      searchValue,
      page,
      size,
      total,
    } = searchModel

    return (
      <Grid style={{ width: '100%' }}>
        <Helmet>
          <title>{`Explore ${title}`}</title>
        </Helmet>
        {!hideTitle && (
          <Row start="xs" style={{ paddingTop: '32px' }}>
            <Col xs>
              <ExplorePageTitle
                title={title}
                action={
                  titleActionListOnly
                    ? gridView === 'table'
                      ? titleActionListOnly
                      : null
                    : titleAction
                }
                actionLabel={titleActionLabel}
              />
              {belowTitle}
            </Col>
          </Row>
        )}
        {!hideSearchBar && (
          <Row
            start="xs"
            style={{
              paddingTop: '24px',
              paddingBottom: '16px',
            }}
          >
            <Col xs>
              <SearchFilter
                placeholder={searchPlaceholder}
                value={searchValue}
                onChange={this.handleSearchChange}
                onEnter={this.immediateSearch}
                onFocus={this.trackOnFocus}
              />
            </Col>
          </Row>
        )}
        <Row start="xs">
          <Col xs>
            <div style={{ paddingBottom: '18px', paddingTop: '5px' }}>
              <SortByFilter />
            </div>
          </Col>
          {gridView && (
            <Col>
              <div style={{ marginRight: '6px' }}>
                <GridListToggle selected={gridView} onclick={this.changeView} />
              </div>
            </Col>
          )}
        </Row>
        {renderAboveResults && (
          <Row start="xs" style={{ paddingBottom: '24px' }}>
            <Col xs>{renderAboveResults(searchModel)}</Col>
          </Row>
        )}
        <Row start="xs">
          <Col xs>
            <div style={{ width, display: 'inline-flex' }}>
              <Grid fluid style={{ width, padding: '0' }}>
                <Row>
                  <Col xs>
                    {loading && <Spinner />}
                    {render({
                      results,
                      loading,
                      error,
                      filtered,
                      gridView,
                      searchValue,
                    })}
                  </Col>
                </Row>
                <Row start="xs">
                  <Col xs>
                    {!loading
                      && !error && (
                        <Pagination
                          page={page}
                          size={size}
                          total={results && results.length ? total : 0}
                          to={location}
                        />
                    )}
                  </Col>
                </Row>
              </Grid>
            </div>
          </Col>
          <Col>
            {filters && (
              <div style={{ width: '184px', display: 'inline-flex' }}>
                <Row>
                  <Col>
                    <div style={{ display: 'inline-block' }}>
                      <H3 coral>
                        <small>Filters</small>
                      </H3>
                    </div>
                    <div style={{ display: 'inline-block', float: 'right' }}>
                      <H5>
                        <a onClick={this.reset}>Reset</a>
                      </H5>
                    </div>

                    {filters}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExploreContainer)
)
