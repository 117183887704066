import axios from 'axios'

export const loading = () => ({ type: 'BACKGROUND_IMAGE_LOADING' })
export const downloading = () => ({ type: 'BACKGROUND_IMAGE_DOWNLOADING' })
export const finished = () => ({ type: 'BACKGROUND_IMAGE_FINISHED' })
export const clear = () => ({ type: 'BACKGROUND_IMAGE_CLEAR' })
export const error = data => ({ type: 'BACKGROUND_IMAGE_FAILURE', data })
export const updateAttr = data => ({ type: 'BACKGROUND_IMAGE_UPDATE_ATTR', data })

export const fetch = () => ({
  http: {
    url: '/background-images',
  },
  types: [
    'BACKGROUND_IMAGE_LOADING',
    'BACKGROUND_IMAGE_SUCCESS',
    'BACKGROUND_IMAGE_FAILURE',
  ],
})

export const fetchStockSearch = ({
  backgroundType: type,
  searchQuery: query,
  searchPage: page,
  skipUnsplash,
  skipPixabay,
}) => ({
  type,
  page,
  http: {
    url: '/background-images/stock-search',
    method: 'POST',
    data: {
      type,
      query,
      page,
      skipUnsplash,
      skipPixabay,
    },
  },
  types: [
    'BACKGROUND_IMAGE_SEARCHING',
    'BACKGROUND_IMAGE_SEARCH_SUCCESS',
    'BACKGROUND_IMAGE_SEARCH_FAILURE',
  ],
})

export const upload = (data, stock) => ({
  type: stock ? 'BACKGROUND_IMAGE_STOCK_UPLOAD' : 'BACKGROUND_IMAGE_UPLOAD',
  data,
})

export const uploadStock = (image, brandId, impersonating) => async dispatch => {
  dispatch(downloading())
  try {
    let url = `/background-images/stock-upload/${brandId}`
    if (impersonating) url += `?impersonating=${impersonating}`
    const { data } = await axios.post(url, image)
    dispatch(upload(data, true))
    dispatch(finished())
    return data.src
  } catch (e) {
    dispatch(error(e))
    return null
  }
}
