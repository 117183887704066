import React from 'react'
import Radium from 'radium'
import propTypes from 'prop-types'
import moment from 'moment-timezone'
import DojoModal from 'util/components/dojomodal'
import PrimaryButton from 'util/components/buttons/primary-button'
import SecondaryButton from 'util/components/buttons/secondary-button'
import injectSheet from 'react-jss'
import { P } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import TextComponent from './Text'

import { disclaimerReplacer, regExp18Disclaimer, regExp21Disclaimer } from './utils/disclaimerPlaceholder'

const highlight = function (str) {
  return str.replace(
    /{{([A-Za-z]+)}}/g,
    '<strong><span style="background-color: #e5f3fd;">{{$1}}</span></strong>'
  )
}

const disclaimers = [
  { value: false, label: 'Must be eighteen (18) or older to win.' },
  { value: true, label: 'Must be twenty-one (21) or older to win.' },
]

const unhighlight = function (str) {
  str = str.replace(
    /<strong><span style="background-color: #e5f3fd;"(\sdata-mce-style="background-color: #e5f3fd;")?>/g,
    ''
  )
  str = str.replace(/<\/span><\/strong>/g, '')
  return str
}

const style = {
  container: {
    margin: '60px auto 0 auto',
    padding: '0 20px 40px 20px',
    maxWidth: 960,
  },
  modalButton: {
    minWidth: 150,
    fontSize: 14,
    marginTop: 20,
    height: 40,
    minHeight: 40,
  },
}

class Disclaimer extends React.Component {
  static propTypes = {
    classes: propTypes.object.isRequired,
    updateAttr: propTypes.func.isRequired,
    campaign: propTypes.object.isRequired,
    onlyTextComponent: propTypes.bool.isRequired,
    edit: propTypes.func.isRequired,
    customFonts: propTypes.object.isRequired,
  }

  state = {
    temporaryValue: this.props.campaign.disclaimer,
    showWarningModal: false,
    ignoreBracketValidations: false,
    is21: false,
  }

  onChange = (attr, value, is21) => {
    const {
      campaign: { disclaimer },
      updateAttr,
    } = this.props
    if (attr === 'disclaimer') {
      let is21Disclaimer = this.state.is21
      let disclaimerRegExp = is21Disclaimer ? regExp21Disclaimer : regExp18Disclaimer
      // dropdown passing age as a boolean to update disclaimer
      if (is21 !== undefined) {
        disclaimerRegExp = is21 ? regExp21Disclaimer : regExp18Disclaimer
        is21Disclaimer = is21
        this.setState({ is21 })
      }


      value = (disclaimerRegExp.test(value))
        ? value : disclaimerReplacer(value, false, is21Disclaimer)
    }

    const bracketRegex = /{{(hostBrandName|partnerBrandNames|endDatePlusOneDay)}}/g
    const newValue = unhighlight(value)
    const originalValueBrackets = disclaimer.match(bracketRegex)
      ? disclaimer.match(bracketRegex).length
      : 0
    const newValueBrackets = newValue.match(bracketRegex) ? newValue.match(bracketRegex).length : 0
    const valid = originalValueBrackets <= newValueBrackets

    if (valid || this.state.ignoreBracketValidations) {
      updateAttr(attr, newValue)
    } else {
      this.setState({
        showWarningModal: true,
        temporaryValue: newValue,
      })
    }
  }

  closeModal = () => {
    this.setState({
      showWarningModal: false,
    })
  }

  revertBrackets = () => {
    const {
      campaign: { disclaimer },
    } = this.props

    tinyMCE.activeEditor.setContent(highlight(disclaimer))
    this.setState({
      showWarningModal: false,
    })
  }

  ignoreValidations = () => {
    const { updateAttr } = this.props

    this.setState({
      ignoreBracketValidations: true,
      showWarningModal: false,
    })
    updateAttr('disclaimer', this.state.temporaryValue)
  }

  renderModal = () => {
    const {
      classes: css,
    } = this.props

    const {
      showWarningModal,
    } = this.state

    return (
      <DojoModal
        isOpen={showWarningModal}
        hideModal={this.revertBrackets}
        customBackDropStyle={{
          zIndex: '7000',
        }}
        customOpenDialogStyle={{
          top: '30%',
        }}
        title="Warning!"
      >
        <div className="row">
          <div className="col-md-12">
            <div
              className="row"
              style={{
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <div className="col-md-12">
                <P>
                  {
                      "If you edit the text within {{brackets}}, you'll be breaking DojoMojo's ability to auto-update these key fields in the future, and will need to manually update them yourself."
                    }
                </P>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-12">
                <SecondaryButton
                  onClick={this.revertBrackets}
                  className={css.modalButton}
                  styles={{ marginRight: '20px' }}
                >
                    Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={this.ignoreValidations}
                  className={css.modalButton}
                >
                    Got it
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </DojoModal>
    )
  }

  render() {
    const {
      campaign: {
        hostBrandName,
        partnerBrandNames,
        endDate,
      },
      campaign,
      onlyTextComponent,
      updateAttr,
      edit,
      customFonts,
      classes: css,
    } = this.props

    const mergeData = {
      hostBrandName,
      partnerBrandNames,
      endDatePlusOneDay: moment(endDate)
        .add(1, 'day')
        .format('l'),
    }

    if (onlyTextComponent) {
      return (
        <div className="disclaimer-wrapper" id="disclaimer">
          <TextComponent
            model={campaign}
            updateAttr={updateAttr && this.onChange}
            edit={edit}
            attr="disclaimer"
            liabilityAttr="disclaimerLiability"
            agreeToEdit
            mergeData={mergeData}
            disclaimers={disclaimers}
          />
          {this.renderModal()}
        </div>
      )
    }
    return (
      <div className={css.container} id="disclaimer-container">
        <TextComponent
          model={campaign}
          updateAttr={updateAttr && this.onChange}
          edit={edit}
          attr="disclaimer"
          liabilityAttr="disclaimerLiability"
          agreeToEdit
          mergeData={mergeData}
          disclaimers={disclaimers}
          customFonts={customFonts}
        />
        {this.renderModal()}
      </div>
    )
  }
}

export default injectSheet(style)(Radium(Disclaimer))
