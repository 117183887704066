import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReviewsTheme from 'css/themes/ratings-reviews.css'
import ReviewCardContent from './ReviewCardContent'

const mapStateToProps = ({ brandRatings }) => ({ brandRatings })

const BrandImage = ({ img }) => (
  <div className={ReviewsTheme.brandImg} style={{ backgroundImage: `url(${img})` }} />
)

BrandImage.propTypes = {
  img: PropTypes.string.isRequired,
}

class ReviewCard extends PureComponent {
  static propTypes = {
    review: PropTypes.object.isRequired,
  }

  render() {
    const {
      review,
    } = this.props
    const { rated_brand_logo, rating_brand_logo, isPosted } = review

    const img = isPosted ? rated_brand_logo : rating_brand_logo

    return (
      <div className={ReviewsTheme.flex}>
        <BrandImage img={img} />
        <ReviewCardContent review={review} />
      </div>
    )
  }
}

export default connect(mapStateToProps)(ReviewCard)
