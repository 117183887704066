import React from 'react';

import { Link } from 'react-toolbox/lib/link';
import AppBarTheme from 'css/themes/app-bar-theme.css'

const themes = {
  AppBarTheme,
}

class NavLogoLink extends React.Component {
  render() {
    const currentMonth = new Date().getMonth()
    const isPrideMonth = currentMonth === 5
    return (
      <Link
        href="/"
        className={ themes.AppBarTheme.noOpacity }
        style={{ verticalAlign: 'middle' }}
      >
        {isPrideMonth ? (
          <div style={{
            marginBottom: '12px',
          }}>
            <img
              src="/images/nav-bar/pride/mojo-pride.png"
              srcSet="/images/nav-bar/pride/mojo-pride.png, /images/nav-bar/pride/mojo-pride@2x.png 2x, /images/nav-bar/pride/mojo-pride@3x.png 3x"
              style={{
                height: '100%',
                verticalAlign: 'top'
          }}/>
        </div>
        ) : (
          <div style={{
            height: '38px',
            marginRight: '7px',
          }}>
            <img src="/images/nav-bar/mojo-white-tiger-logo.svg"
              style={{
                height: '100%',
                verticalAlign: 'middle'
            }}/>
          </div>
        )}
      </Link>
    );
  }
}

export default NavLogoLink;
