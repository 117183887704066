import React, { PureComponent } from 'react'
import _ from 'lodash'

import Dialog from 'react-toolbox/lib/dialog'
import FontIcon from 'react-toolbox/lib/font_icon'

import Label from 'visual-components/util/inputs/label'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import UploadImage from 'components/util/editor-uploader'
import COLOURS from 'util/colours'
import { H2, H4 } from 'visual-components/util/texts'
import getCoverPhotoUrl from 'util/brand-cover-photo'

import dialogTheme from 'css/themes/dialogs/formModal.css'
import ProfileTheme from 'css/themes/Profile.css'

class EditCover extends PureComponent {
  render() {
    const {
      facebookCoverPhoto,
      coverPhotoOffsetY,
      changePosition,
      incrementPosition,
      decrementPosition,
      uploadSuccess,
      handleToggle,
      save,
      cancel,
      active,
    } = this.props

    const coverPreviewStyle = {
      display: 'inline-block',
      width: '608px',
      height: '224px',
      paddingTop: '60px',
      border: 'solid 1px #dee4e5',
      textAlign: 'center',
      cursor: 'pointer',
      position: 'relative',
    }

    if (facebookCoverPhoto) {
      _.extend(coverPreviewStyle, {
        backgroundSize: 'cover',
        backgroundImage: `url(${getCoverPhotoUrl(facebookCoverPhoto)})`,
        backgroundPositionX: 0,
        backgroundPositionY: `${coverPhotoOffsetY}%`,
      })
    }

    return (
      <div
        className={ProfileTheme.edit}
        style={{
          position: 'relative',
          maxWidth: 1176,
          margin: '0 auto',
          height: '100%',
        }}
      >
        <div
          onClick={handleToggle}
          style={{
            position: 'absolute',
            top: '10px',
            right: '36px',
            display: 'inline-block',
            cursor: 'pointer',
            backgroundColor: 'rgba(44, 53, 65, 0.8)',
            borderRadius: '2px',
            width: '121px',
          }}
        >
          <div
            style={{
              fontFamily: 'Larsseit',
              fontSize: '14px',
              lineHeight: '36px',
              paddingLeft: '16px',
              paddingRight: '36px',
              color: COLOURS.white,
            }}
          >
            <div style={{ display: 'inline-block' }}>
              Edit Image
            </div>
            <div style={{ display: 'inline-block', position: 'absolute', top: '4px', right: '16px' }}>
              <FontIcon value="edit" style={{ fontSize: '18px' }} />
            </div>
          </div>
        </div>
        <Dialog
          theme={dialogTheme}
          active={active}
          onEscKeyDown={handleToggle}
          onOverlayClick={handleToggle}
          title="Edit Cover Image"
        >
          <img
            onClick={handleToggle}
            className={dialogTheme.closeImage}
            src="/images/icons/close.svg"
          />

          <H4 multiline>
            To upload a cover photo, select an image in JPG or PNG format with a width of 864px and height of 320px.
          </H4>

          <div style={{ textAlign: 'center', marginTop: '24px', marginBottom: '16px' }}>
            <UploadImage
              action="/brands/cover"
              src={facebookCoverPhoto}
              success={uploadSuccess}
              name="upload-cover-image"
              acceptedFiles="image/jpeg,image/png"
            >
              <div style={coverPreviewStyle}>
                <div style={{ position: 'absolute', zIndex: 2, width: '100%' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                    <path fill={!facebookCoverPhoto ? COLOURS.azure : '#fff'} fillRule="nonzero" d="M64 30.476H33.524V0h-3.048v30.476H0v3.048h30.476V64h3.048V33.524H64z"/>
                  </svg>
                  <div style={{ marginTop: 34 }}>
                    <H2
                      white={!!facebookCoverPhoto}
                      azure={!facebookCoverPhoto}
                    >
                      {
                        facebookCoverPhoto ? 'Change' : 'Upload'
                      }
                    </H2>
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    opacity: 0.4,
                    top: '0px',
                    display: facebookCoverPhoto ? 'block' : 'none',
                  }}
                />
              </div>
            </UploadImage>
          </div>

          <div style={{ position: 'relative', paddingBottom: '10px', marginBottom: '10px' }}>
            <Label label="Image Placement" />
            <span
              key="left"
              onClick={e => decrementPosition()}
              style={{
                position: 'absolute',
                bottom: '0px',
                left: '0px',
                color: COLOURS.azure,
                fontSize: '20px',
                fontFamily: 'Larsseit-Light',
                cursor: 'pointer',
              }}
            >
              <i className="fa fa-arrow-up" aria-hidden />
            </span>
            <span
              key="right"
              onClick={e => incrementPosition()}
              style={{
                position: 'absolute',
                bottom: '0px',
                right: '0px',
                color: COLOURS.azure,
                fontSize: '20px',
                fontFamily: 'Larsseit-Light',
                cursor: 'pointer',
              }}
            >
              <i className="fa fa-arrow-down" aria-hidden />
            </span>

            <input
              type="range"
              min={0}
              max={100}
              step={1}
              value={coverPhotoOffsetY}
              onChange={e => changePosition(e.target.value)}
              style={{ width: '540px', marginLeft: '30px' }}
            />
          </div>

          <ModalButtonGroup canSave={facebookCoverPhoto} confirm={save} cancel={cancel} />
        </Dialog>
      </div>
    )
  }
}

export default EditCover
