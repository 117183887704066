import React from 'react';
import * as ReactRedux from 'react-redux';

import Dialog from 'react-toolbox/lib/dialog';

import EditPen from 'visual-components/util/EditPen';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import NumberField from 'util/components/number-field';
import Validator from 'util/form-validator';
import COLOURS from 'util/colours';

import dialogTheme from 'css/themes/dialogs/formModal.css'

var mapState = function (state) {
  return state;
}

class EditAudience extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.state = {
      active: false
    }
  }

  handleToggle() {
    this.setState({
      active: !this.state.active
    });
  }

  render() {
    var {
      show,
      currentBrand,
      currentBrandForm,
      hovered
    } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div style={{
        visibility: hovered ? 'inherit' : 'hidden'
      }}>
        <EditPen onClick={ this.handleToggle } />
        <Dialog
          theme={ dialogTheme }
          active={ this.state.active }
          onEscKeyDown={ this.handleToggle }
          onOverlayClick={ this.handleToggle }
          title='Edit List Size'
        >
          <img
            onClick={ this.handleToggle }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />
          <NumberField
            dataModel={ currentBrand }
            formModel={ currentBrandForm }
            attrName="dedicatedListSize"
            label="List Size"
            validations={ [Validator.required] }
            endpoint={ '/brands' }
            labelStyles={{
              fontFamily: 'Larsseit-Medium',
              fontSize: '14px',
              fontWeight: 'normal',
              color: '#212731',
              marginBottom: '8px'
            }}
            customInputStyle={{
              borderColor: COLOURS.silver
            }}
          />
          <ModalButtonGroup
            canSave={ currentBrand.dedicatedListSize }
            confirm={ this.handleToggle }
            confirmText={ 'Done' }
          />
        </Dialog>
      </div>
    )
  }
}

export default  ReactRedux.connect(mapState)(EditAudience);;
