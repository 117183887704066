import React, { Component } from 'react'
import { Menu, MenuItem } from 'react-toolbox/lib/menu'
import { bool, func } from 'prop-types'
import Theme from 'css/themes/media-market/util/comment-dropdown-theme.css'

class CommentDropdownMenu extends Component {
  static propTypes = {
    active: bool.isRequired,
    editComment: func.isRequired,
    deleteComment: func.isRequired,
  }

  render() {
    const {
      active,
      editComment,
      deleteComment,
    } = this.props

    return (
      <Menu
        position="topRight"
        active={active}
        theme={Theme}
      >
        <MenuItem
          value="edit"
          caption="Edit"
          onClick={editComment}
          theme={Theme}
        />

        <MenuItem
          value="delete"
          caption="Delete"
          onClick={deleteComment}
          theme={Theme}
        />
      </Menu>
    )
  }
}

export default CommentDropdownMenu
