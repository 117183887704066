import AppDispatcher from 'dispatchers/app-dispatcher';
import _ from 'lodash'

const AdminDispatcher = {

  load: (data) => {
    let interval = data.interval;

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'admin',
      data: {
        loading: true
      }
    })

    var specData = {
      section: data.section,
      endDate: data.endDate,
      interval: data.interval
    }

    var loaded = _.find(data.cache, specData);

    if (loaded) {
      store.dispatch({
        type: 'LOAD',
        model: 'admin',
        data: loaded.cache
      })
    } else {
      AppDispatcher.http({
        url: '/admin',
        data: specData,
        method: 'POST',
        success: function  (data) {

          let skipKeys = {
            dates: true,
            distinctHosts: true,
            topTenBrandEmailsAcquired: true,
            topTenBrandEmailsAcquiredAggregate: true,
            totalEntries: true,
            totalVisits: true,
            sweepsActive: true,
            avgCampaignsPerBrandCreatedLast60Days: true,
            brandsReEngagementLast60Days: true,
            distinctCurrentHosts: true,
            avgApplicationsPerCampaign: true,
            totalActiveBrands30Days: true,
            totalActiveBrands60Days: true,
            totalActiveBrands90Days: true,
            totalBrandInterestedIn: true,
            totalOpportunitiesCreated: true,
            totalOpportunitiesResponded: true,
            opportunityResponseTime: true,
            rolling30NewConversations: true,
            rolling30RespondedConversations: true,
            rolling30MAU: true,
            rolling30MessagePerConvo: true,
            rolling30NewBrandsWithCampaigns: true,
            days_to_first_partnership_rolling60: true,
            opportunities_v2_created_content_swap_opportunities: true,
            opportunities_v2_created_custom_opportunities: true,
            opportunities_v2_created_dedicated_email_buy_opportunities: true,
            opportunities_v2_created_dedicated_email_sell_opportunities: true,
            opportunities_v2_created_prizing_partner_opportunities: true,
            opportunities_v2_created_product_shipment_insert_opportunities: true
          }

          if (specData.section !== 'network') {
            _.each(data, function (value, key) {
              if (!skipKeys[key]) {
                value.pop()
              }
            })
            data.interval = interval;
            specData.cache = _.cloneDeep(data);
            store.dispatch({
              type: 'LOAD_RAW',
              model: 'admin',
              data: specData
            });
          }
          store.dispatch({
            type: 'LOAD',
            model: 'admin',
            data: data
          });
        }
      })
    }
  }
};

export default AdminDispatcher;
