import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  object, array, number, func, bool,
} from 'prop-types'
import d3 from 'd3'
import TableTheme from 'css/themes/partnersTable.css'
import * as PartnershipAgreementActions from 'actions/partnership-agreement-actions'
import * as EmailReleaseActions from 'actions/email-release-actions'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import PartnerListActions from './partner-list-actions'

const mapDispatchToProps = dispatch => ({
  dispatch,
  partnershipAgreementActions: bindActionCreators(PartnershipAgreementActions, dispatch),
  emailReleaseActions: bindActionCreators(EmailReleaseActions, dispatch),
})

class PartnerListItem extends React.Component {
  static propTypes = {
    invite: object.isRequired,
    invites: array.isRequired,
    campaign: number.isRequired,
    campaignId: number.isRequired,
    acceptApplication: func.isRequired,
    displayRemovalModal: func.isRequired,
    displayReportModal: func.isRequired,
    partnershipAgreementActions: object.isRequired,
    emailReleaseActions: object.isRequired,
    dispatch: func.isRequired,
    history: object.isRequired,
    isHost: bool,
    isHostInvite: bool,
  }

  static defaultProps = {
    isHost: false,
    isHostInvite: false,
  }

  displayReportModal = () => {
    const { invite, displayReportModal } = this.props
    displayReportModal(invite)
  }

  displayRemovalModal = () => {
    const { invite, displayRemovalModal } = this.props
    displayRemovalModal(invite.id, invite.application)
  }

  acceptApplication = () => {
    const {
      invites, invite, campaignId, acceptApplication, dispatch, campaign,
    } = this.props

    const totalAccepted = invites.reduce((memo, i) => {
      if (i.status === 'accepted') {
        return memo + 1
      }

      return memo
    }, 0)

    if (!campaign.noPartnerLimit && totalAccepted >= 8) {
      dispatch({
        type: 'UPDATE_ATTR',
        model: 'campaignLimitModal',
        data: {
          open: true,
          isInvitation: false,
          campaignId,
        },
      })

      return
    }

    acceptApplication(invite.id)
  }

  rejectApplication = () => {
    const { invite, displayRemovalModal } = this.props
    displayRemovalModal(invite.id, invite.application)
  }

  canNavigate = location => {
    const {
      campaign: { invites, id, startDate, noPartnerLimit },
      dispatch,
    } = this.props

    const totalAccepted = invites.reduce((memo, invite) => {
      if (invite.status === 'accepted') {
        return memo + 1
      }

      return memo
    }, 0)

    if (location !== 'completed' && ((!noPartnerLimit && totalAccepted >= 8) || startDate < new Date())) {
      dispatch({
        type: 'UPDATE_ATTR',
        model: 'campaignLimitModal',
        data: {
          open: true,
          isInvitation: false,
          campaignId: id,
          hasStarted: startDate < new Date(),
        },
      })
    } else {
      this.nav(location)
    }
  }

  createAgreement = () => {
    const { invite, partnershipAgreementActions, history } = this.props

    partnershipAgreementActions.clearAgreement()
    partnershipAgreementActions.updateAttributes({
      selectedBrand: invite.id,
    })

    history.push(`/builder/${invite.campaign_id}/partnership-agreement/choose/${invite.id}`)
  }

  nav = location => {
    const { invite, partnershipAgreementActions, history } = this.props
    partnershipAgreementActions.loadPartnershipAgreement(invite.id)
    history.push(`/builder/${invite.campaign_id}/partnership-agreement/${location}/${invite.id}`)
  }

  render() {
    const {
      isHost,
      isHostInvite,
      invite,
      campaign,
      campaignId,
      emailReleaseActions,
      typeToShow,
      setBrandOpenConfirmationModal,
      setBrandOpenInviteMessenger,
      showReinvite
    } = this.props

    const minDeliveryPercentage = invite.minimumDelivery ? Math.round(invite.entriesDriven / invite.minimumDelivery * 100) : '-'
    const belowMinDelivery = invite.entriesDriven < invite.minimumDelivery

    const actions = []

    if (['accepted', 'interested'].includes(invite.status) && !isHostInvite) {
      if (!invite.agreement) {
        actions.push({
          label: 'Create Agreement',
          onClick: this.createAgreement,
        })
      } else {
        const actionMap = {
          created: {
            label: 'Edit Agreement',
            location: invite.agreement.type === 'template' ? 'draft' : 'upload',
          },
          drafted: {
            label: 'Edit Agreement',
            location: 'draft',
          },
          shared: {
            label: 'Edit Agreement',
            location: 'draft',
          },
          partner_signed: {
            label: 'Sign Agreement',
            location: 'sign',
          },
          completed: {
            label: 'View Agreement',
            location: 'completed',
          },
        }

        if (actionMap[invite.agreement.status]) {
          actions.push({
            label: actionMap[invite.agreement.status].label,
            onClick: () => { this.canNavigate(actionMap[invite.agreement.status].location) },
          })
        }
      }
    }

    if (invite.status === 'accepted' && !isHostInvite) {
      if (campaign.startDate < new Date()
        && invite.minimumDelivery
        && belowMinDelivery) {
        actions.push({
          label: 'Release Emails',
          onClick: () => {
            EventTrackingDispatcher.track('emailReleaseIntentionClick')
            emailReleaseActions.load(campaign, invite)
          },
        })
      }
      actions.push({
        label: 'Report Partner',
        onClick: this.displayReportModal,
      })
    }

    // Pending Invites tab
    if (!invite.application && invite.status === 'pending' && showReinvite) {
      actions.push({
        label: 'Reinvite Partner',
        onClick: () => setBrandOpenConfirmationModal(invite),
      })
    }

    // Rejected tab
    if (invite.status === 'rejected') {
      actions.push({
        label: 'Reinvite Partner',
        onClick: () => setBrandOpenInviteMessenger(invite.invitee),
      })
    }

    // Display Accept/Reject if application, and Remove if invitation
    if (invite.showRemove && invite.application && invite.status === 'pending') {
      actions.push({
        label: 'Mark Interested',
        onClick: this.acceptApplication,
      })
      actions.push({
        label: 'Decline',
        onClick: this.rejectApplication,
      })
    } else if (invite.showRemove) {
      actions.push({
        label: 'Remove',
        onClick: this.displayRemovalModal,
      })
    }


    let status
    const statusMap = {
      created: 'Agreement Created',
      drafted: 'Agreement Drafted',
      shared: 'Pending Partner Signature',
      partner_signed: 'Pending Host Signature',
    }

    if (['interested', 'pending'].includes(invite.status)) {
      const { agreement } = invite
      const agreementStatus = agreement && agreement.status
      status = (invite.status === 'interested' && (statusMap[agreementStatus] || 'No Agreement Started'))
        || 'Open For Response'
    }

    return (
      <tr style={{ height: '82px' }}>
        <td className={TableTheme.firstTd}>
          <div style={{ display: 'table' }}>
            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
              <Link to={`/explore/brands/${invite.invitee_id}/details`}>
                <img
                  src={invite.invitee.logo}
                  style={{ maxHeight: '73px', maxWidth: '73px' }}
                />
              </Link>
            </div>
          </div>
        </td>
        <td className={TableTheme.secondTd}>
          <Link to={`/explore/brands/${invite.invitee_id}`}>
            { invite.invitee.accountname }
          </Link>
        </td>
        <td className={TableTheme.thirdTd}>
          { !invite.invitee.dedicatedListSize ? '-' : d3.format(',')(invite.invitee.dedicatedListSize) }
        </td>
        <td className={TableTheme.fourthTd}>
          {
            /* If this brand doesn't have an averageSignUpsPerCampaign, estimate it to be 1% of their dedicatedListSize */
            d3.format(',.0f')(invite.invitee.averageSignUpsPerCampaign > 0 ? invite.invitee.averageSignUpsPerCampaign : invite.invitee.dedicatedListSize * 0.01)
          }
        </td>

        { actions && isHost
          ? (
            <td className={TableTheme.fifthTd}>
              { invite.status === 'accepted'
                ? (
                  invite.minimumDelivery > 0
                    ? d3.format(',')(invite.minimumDelivery)
                    : '-'
                ) : status
              }
            </td>
          ) : <td className={TableTheme.fifthTd} />
        }
        { actions && isHost && typeToShow === 'accepted'
          ? (
            <td className={`
              ${TableTheme.sixthTd}
              ${invite.minimumDelivery
              ? belowMinDelivery
                ? TableTheme.belowDelivery
                : TableTheme.aboveDelivery
              : ''
              }
            `}
            >
              {`${d3.format(',')(invite.entriesDriven)} (${minDeliveryPercentage}%)`}
            </td>
          ) : null
        }
        { actions && isHost
          ? (
            <td className={TableTheme.actionsTd}>
              <div>
                <PartnerListActions actions={actions} typeToShow={typeToShow} />
              </div>
            </td>
          ) : <td />
        }
      </tr>
    )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(PartnerListItem))
