import React from 'react'
import { string, number } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { Small } from 'visual-components/util/texts'
import ProposalStatusMessageTheme from 'css/themes/media-market/ProposalStatusMessage.css'
import LinkedButton from 'visual-components/util/buttons/LinkedButton'
import ButtonTheme from 'css/themes/Buttons.css'

class NewTargetLinkMessage extends React.Component {
  static propTypes = {
    buyingBrand: string.isRequired,
    numLinks: number.isRequired,
    dealId: number.isRequired,
  }

  render() {
    const { buyingBrand, numLinks, dealId } = this.props

    return (
      <Card className={ProposalStatusMessageTheme.main}>
        <img
          src="/images/icons/link-asset.svg"
          className={ProposalStatusMessageTheme.headerImage}
        />
        <div className={ProposalStatusMessageTheme.title}>
          <Small>
            <i>New Tracking Link Added</i>
          </Small>
        </div>
        <div className={ProposalStatusMessageTheme.subtitle}>
          <Small multiline>
            {`${buyingBrand} added ${numLinks > 1 ? numLinks : 'a'} new tracking link${
              numLinks > 1 ? 's' : ''
            }.`}
          </Small>
        </div>
        <div className={ProposalStatusMessageTheme.buttons}>
          <LinkedButton
            to={`/partnerships/deal/${dealId}/tracking-links`}
            className={ButtonTheme.smallBlueButton}
            label="See Tracking Links"
            raised
          />
        </div>
      </Card>
    )
  }
}

export default NewTargetLinkMessage
