import { compose, createStore, combineReducers, applyMiddleware } from 'redux'
import { routerReducer } from 'react-router-redux'
import _ from 'lodash'

const ModelReducerFor = function (model, initialState, additionalActions = {}) {
  const defaultState = _.extend({}, {
    rid: model
  }, initialState);

  return function (state, action) {
    if (action.model !== model && !Object.keys(additionalActions).includes(action.type)) {
      return state || defaultState;
    }

    const actions = {
      CLEAR: function () {
        return _.extend({}, defaultState);
      },
      UPDATE_ATTR: function () {
        return _.merge({}, state, action.data);
      },
      UPDATE_ARR: function () {
        return _.extend({}, state, action.data);
      },
      LOADING: function () {
        return _.extend({}, state, { loading: true, error: null });
      },
      UPDATE_SORT: function () {
        state = _.extend({}, state, action.data);
        var results = _.sortBy(state.results, state.sortVar);
        if (state.reversed) {
          results = results.reverse();
        }

        state.results = results;
        return state;
      },
      LOAD_SUCCESS: function () {
        var data = _.extend({}, state, { loading: false });
        return _.extend({}, data, action.data);
      },
      LOAD_FAILURE: function() {
        return _.extend({}, state, {
          loading: false,
          error: action.data || 'Error'
        })
      },
      ...additionalActions
    };

    const item = actions[action.type] || function () {
      return state || defaultState;
    };

    return item(state, action);
  };
}

const FormReducerFor = function (model) {
  const reducer = function (state, action) {
    if (action.model !== model) {
      return state || _.extend({}, {}, {
        rid: model,
        errors: {}
      });
    }
    var actions = {
      UPDATE_ATTR: function () {
        return _.extend({}, state, action.data);
      },
      UPDATE_FORM_ATTR: function () {
        return _.extend({}, state, action.data);
      },
      CLEAR: function () {
        return {
          rid: model,
          errors: {}
        }
      },
      LOADING: function () {
        return _.extend({}, state, { loading: true });
      },
      LOAD_SUCCESS: function () {
        const data = _.merge({}, action.data, { loading: false});
        return _.extend({}, state, data);
      },
      START_PERSIST: function () {
        const data = {
          [action.attrName]: { persist: false }
        };
        return _.extend({}, state, data);
      },
      FINISH_PERSIST: function () {
        const data = {
          [action.attrName]: { persist: true }
        };
        return _.extend({}, state, data);
      },
      PERSIST_ERROR: function () {
        let data = {};
        data[action.attrName] = action.data;
        return _.extend({}, state.error, data);
      }
    };

    var item = actions[action.type] || function () {
      return state || _.extend({}, {}, { rid: model });
    }
    return item();
  }

  return reducer;
};

import DealAssetsReducer from './reducers/deal-assets-reducer'
import TopPartners from './top-partners-reducer';
import CampaignHistoryReducer from './campaign-history-reducer';
import AcquiresHistoryReducer from './acquires-history-reducer';
import RecentAcquiresReducer from './recent-acquires-reducer';
import BrandPostEntryReducer from './brand-post-entry-reducer';
import PastCampaignsReducer from './past-campaigns-reducer';
import CurrentCampaignsReducer from './current-campaigns-reducer';
import UpcomingCampaignsReducer from './upcoming-campaigns-reducer';
import InDiscussionCampaignsReducer from './in-discussion-campaigns-reducer';
import AdminReducer from './admin-reducer';
import ListReducerFor from './list-reducer';
import InvoicesReducerFor from './invoices-reducer';
import HashReducerFor from './hash-reducer';
import MessagingReducer from '../app/reducers/messaging-reducer';
import CsvLogReducer from '../app/reducers/csv-log-reducer';
import CurrentCampaignReducer from './campaign-reducer';
import NotificationsReducer from './reducers/notifications-reducer';
import CurrentBrandReducer from './reducers/current-brand-reducer';
import BrandClaimReducer from './reducers/brand-claim-reducer';
import EmailOverlapReducer from '../app/reducers/email-overlaps-reducer';
import DesignHelpReducer from './reducers/design-help-reducer';
import NewBrandReducer from './reducers/new-brand-reducer';
import UserBrandsReducer from './user-brands-reducer';
import BrandCampaignsReducer from './reducers/brand-campaigns-reducer';
import PromotionalUnitsReducer from './reducers/promotional-units-reducer';
import PromotionalUnitReducer from './reducers/promotional-unit-reducer'
import BrandDetailReducer from '../app/reducers/brand-detail-reducer';
import SocialMetricsReducer from './social-metrics-reducer';
import BrowserHistoryReducer from './browser-history-reducer'
import NewsFeedReducer from './news-feed-reducer'
import LiveNotificationsReducer from './live-notifications-reducer'
import BrandAccountReducer from './reducers/brand-account-reducer'
import AnalyticsReducer from './reducers/analytics-reducer';
import dashboardAnalyticsDefaults from './reducers/analytics_reducer_extensions/dashboard-analytics-defaults';
import campaignAnalyticsDefaults from './reducers/analytics_reducer_extensions/campaign-analytics-defaults';
import partnershipAnalyticsDefaults from './reducers/analytics_reducer_extensions/partnerships-analytics-defaults';
import OpportunitiesReducer from './reducers/opportunities-reducer'
import CampaignTaskReducer from './reducers/campaign-task-reducer'
import DataIntegrationsReducer from './reducers/data-integrations-reducer'
import partnershipApplicationReducer from './reducers/partnership-application-reducer';
import legalDetailsModalReducer from 'reducers/legal-details-reducer';
import partnershipAgreementReducer from 'reducers/partnership-agreement-reducer';
import DealReducer from './reducers/deal-reducer'
import DealAnalyticsReducer from './reducers/deal-analytics-reducer'
import EmailReleaseReducer from './reducers/email-release-reducer'
import DealIntegrationsReducer from './reducers/deal-integrations-reducer'
import DealCollaborationReducer from './reducers/deal-collaboration-reducer'
import referralsReducer from './reducers/referrals-reducer';
import * as searchReducers from './reducers/search-reducers'
import contentSamplesReducer from './reducers/content-samples-reducer'
import SellerProfileReducer from './reducers/seller-profile-reducer'
import SellerProfileModalReducer from './reducers/seller-profile-modals-reducer'
import socialActionsReducer from './reducers/social-actions-reducer'
import smsReducer from './reducers/sms-reducer'
import recommendationModalsReducer from './reducers/recommendation-modals-reducer'
import EspIntegrationsReducer from './reducers/integrations-reducer'
import DealRequestReducer from './reducers/deal-request-reducer'
import ratingsReducer from './reducers/ratings-reducer'
import brandCredentialsReducer from './reducers/brand-credentials-reducer'
import ROICalculatorReducer from './reducers/roi-calculator-reducer'
import BackgroundImageReducer from './reducers/background-image-reducer'
import billingEmailsReducer from './reducers/billing-emails-reducer';
import dataDisclosureReducer from './reducers/data-disclosure-reducer';
import dataDisclosurePolicyReducer from './reducers/data-disclosure-policy-reducer';

var reducers = {
  ...searchReducers,
  announcement: ModelReducerFor('announcement'),
  brandsAlsoViewed: ModelReducerFor('brandsAlsoViewed'),
  backgroundImages: BackgroundImageReducer,
  brandCredentials: brandCredentialsReducer,
  brandUserInvites: ListReducerFor('brandUserInvites'),
  brandCampaigns: BrandCampaignsReducer,
  brandAccount: BrandAccountReducer,
  brandAccountForm: FormReducerFor('brandAccountForm'),
  bankAccountForm: FormReducerFor('bankAccountForm'),
  currentCampaigns: CurrentCampaignsReducer,
  conversations: MessagingReducer,
  emailOverlap: EmailOverlapReducer,
  emailRelease: EmailReleaseReducer,
  dataIntegrations: DataIntegrationsReducer,
  dealAssets: DealAssetsReducer,
  designHelp: DesignHelpReducer,
  designHelpForm: FormReducerFor('designHelpForm'),
  partnershipInvites: ModelReducerFor('partnershipInvites', {
    invites: {}
  }),
  csvUploadLog: CsvLogReducer,
  pastCampaigns: PastCampaignsReducer,
  login: ModelReducerFor('login'),
  loginForm: FormReducerFor('loginForm'),
  currentBrand: CurrentBrandReducer,
  brandClaims: BrandClaimReducer,
  newBrand: NewBrandReducer,
  newBrandForm: FormReducerFor('newBrandForm'),
  currentBrandForm: FormReducerFor('currentBrandForm'),
  upcomingCampaigns: UpcomingCampaignsReducer,
  inDiscussionCampaigns: InDiscussionCampaignsReducer,
  forgot: ModelReducerFor('forgot'),
  forgotForm: FormReducerFor('forgotForm'),
  updatePassword: ModelReducerFor('updatePassword'),
  updatePasswordForm: FormReducerFor('updatePasswordForm'),
  register: ModelReducerFor('register'),
  registerForm: FormReducerFor('registerForm'),
  profile: ModelReducerFor('profile'),
  profileForm: FormReducerFor('profileForm'),
  notificationsList: NotificationsReducer,
  notificationsSettings: ModelReducerFor('notifications'),
  notificationSettingsForm: FormReducerFor('notificationsForm'),
  admin: AdminReducer,
  newBrandInvite: ModelReducerFor('newBrandInvite', {
    role: 'owner'
  }),
  newBrandInviteForm: FormReducerFor('newBrandInviteForm'),
  brandInDetail: ModelReducerFor('brandInDetail', {
    availableCampaigns: []
  }),
  brand: BrandDetailReducer,
  campaign: ModelReducerFor('campaign', {
    availableCampaigns: []
  }, {
    SUBMIT_APPLICATION_SUCCESS: function(state, action) {
      const invites = [...state.invites, action.payload.invite]
      return { ...state, invites };
    }
  }),
  routing: routerReducer,
  platformInviteBrand: ModelReducerFor('platformInviteBrand', {
    campaign_id: '',
    open: false,
    showCampaignList: false,
  }),
  platformInviteBrandForm: FormReducerFor('platformInviteBrandForm'),
  topPartners:TopPartners,
  campaignHistory: CampaignHistoryReducer,
  acquiresHistory: AcquiresHistoryReducer,
  recentAcquires: RecentAcquiresReducer,
  brandPostEntry: BrandPostEntryReducer,
  promotionalUnits: PromotionalUnitsReducer,
  promotionalUnit: PromotionalUnitReducer,
  promotionalUnitForm: FormReducerFor('promotionalUnitForm'),
  partnershipInvite: ModelReducerFor('partnershipInvite', {
    bonus: {}
  }),
  partnershipInviteForm: FormReducerFor('partnershipInviteForm'),
  espIntegrations: EspIntegrationsReducer,
  integrationItem: ModelReducerFor('integrationItem'),
  integrationItemForm: FormReducerFor('integrationItemForm'),
  bonusItems: HashReducerFor('bonusItems'),
  general: ModelReducerFor('general'),
  card: ModelReducerFor('card'),
  cardForm: FormReducerFor('cardForm'),
  ctaModel: ModelReducerFor('ctaModel', {
    postEntryMessage: 'Thank you for entering!',
    headlineMessage: 'Special Offer Select Your Gift',
    ctaUrl: '',
    type: 'button',
    buttonText: 'Visit',
    editTrafficModal: false
  }),
  ctaForm: FormReducerFor('ctaForm'),
  invoices: InvoicesReducerFor('invoices', {
    belts: []
  }),
  customer: ModelReducerFor('customer', {
    loading: true,
    sources: {
      data: []
    },
    source: {}
  }),
  currentCampaign: CurrentCampaignReducer('currentCampaign'),
  currentCampaignForm: FormReducerFor('currentCampaignForm'),
  referBrand: ModelReducerFor('referBrand', {message: `I'm using DojoMojo to connect with brands, create and manage sweepstakes campaigns and drive email acquisition efforts. Give it a try!`}),
  referBrandForm: FormReducerFor('referBrandForm'),
  pricingModal: ModelReducerFor('pricingModal'),
  interestedInLearnMoreModal: ModelReducerFor('interestedInLearnMoreModal'),
  createOpportunityModal: ModelReducerFor('createOpportunityModal'),
  trialEndedModal: ModelReducerFor('trialEndedModal'),
  trialExtendedModal: ModelReducerFor('trialExtendedModal'),
  preferredTermsPlanModal: ModelReducerFor('preferredTermsPlanModal'),
  exportAnalyticsModal: ModelReducerFor('exportAnalyticsModal', {
    active: false,
    metrics: []
  }),
  partnershipInterestsModals: ModelReducerFor('partnershipInterestsModals', {
    contentSwaps: false,
    insertSwaps: false,
    dedicatedEmailBuys: false,
    dedicatedEmailSales: false,
    offerPrizing: false,
    sweepstakes: false
  }),
  interestedConfirmationModal: ModelReducerFor('interestedConfirmationModal', {
    open: false,
    invite_attrs: {
      brand_name: '',
      campaign_name: '',
      application: null,
      partnership_invite_id: null,
    }
  }),
  interestedInfoModal: ModelReducerFor('interestedInfoModal', {
    open: false,
    data: {
      campaign: {}
    },
    invite_id: null
  }),
  calendlyModal: ModelReducerFor('calendlyModal'),
  softEntriesPaywallModal: ModelReducerFor('softEntriesPaywallModal'),
  confirmationModal: ModelReducerFor('confirmationModal'),
  notEnoughEntriesModal: ModelReducerFor('notEnoughEntriesModal'),
  eliteRequest: ModelReducerFor('eliteRequest'),
  eliteRequestForm: FormReducerFor('eliteRequestForm'),
  partnerDetails: ModelReducerFor('partnerDetails'),
  partnerDetailsForm: FormReducerFor('partnerDetailsForm'),
  infoModal: ModelReducerFor('infoModal'),
  userBrands: UserBrandsReducer,
  campaignSetupForm: FormReducerFor('campaignSetupForm'),
  brandInviteForm: FormReducerFor('brandInviteForm'),
  socialMetrics: SocialMetricsReducer,
  welcomeModalInvite: ModelReducerFor('welcomeModalInvite'),
  acceptedInviteModal: ModelReducerFor('acceptedInviteModal'),
  termsAndConditionsForm: FormReducerFor('termsAndConditionsForm'),
  opportunity: OpportunitiesReducer,
  campaignTasks: CampaignTaskReducer,
  dealAnalytics: DealAnalyticsReducer,
  deals: DealReducer,
  dealIntegrations: DealIntegrationsReducer,
  dealCollaboration: DealCollaborationReducer,
  campaignTermsDetails: HashReducerFor('campaignTermsDetails'),
  campaignTermsDetailsForm: FormReducerFor('campaignDetailsForm'),
  browserHistory: BrowserHistoryReducer,
  newsFeed: NewsFeedReducer,
  contactSupport: ModelReducerFor('contactSupport'),
  contactSupportForm: FormReducerFor('contactSupportForm'),
  existingBrandSupport: ModelReducerFor('existingBrandSupport'),
  existingBrandSupportForm: FormReducerFor('existingBrandSupportForm'),
  pricingElite: ModelReducerFor('pricingElite'),
  pricingEliteForm: FormReducerFor('pricingEliteSupportForm'),
  notifications: LiveNotificationsReducer,
  createNewTaskModal: ModelReducerFor('createNewTaskModal'),
  deleteTaskModal: ModelReducerFor('deleteTaskModal'),
  currentTime: ModelReducerFor('currentTime'),
  integrationWarningModal: ModelReducerFor('integrationWarningModal'),
  analyticsDashboard: AnalyticsReducer('analyticsDashboard', dashboardAnalyticsDefaults),
  analyticsCampaign: AnalyticsReducer('analyticsCampaign', campaignAnalyticsDefaults),
  analyticsPartnership: AnalyticsReducer('analyticsPartnership', partnershipAnalyticsDefaults),
  uploadLogoToApplyModal: ModelReducerFor('uploadLogoToApplyModal', { open: false }),
  partnershipApplication: partnershipApplicationReducer,
  campaignLimitModal: ModelReducerFor('campaignLimitModal', { open: false, isInvitation: false }),
  referrals: referralsReducer,
  legalDetailsModal: legalDetailsModalReducer,
  partnershipAgreement: partnershipAgreementReducer,
  sellMediaModal: ModelReducerFor('sellMediaModal', { open: false }),
  noOfficialRulesModal: ModelReducerFor('noOfficialRulesModal', { open: false} ),
  collaborateEducationalModal: ModelReducerFor('collaborateEducationalModal', { step: 0, show: false }),
  incompleteRulesModal: ModelReducerFor('incompleteRulesModal', { open: false} ),
  rulesPublishedWarning: ModelReducerFor('rulesPublishedWarning', { open: false} ),
  mediaMarketDealGraph: ModelReducerFor('mediaMarketDealGraph', { selectedTab: '' }),
  uploadMediaSampleDialog: ModelReducerFor('uploadMediaSampleDialog', { open: false, edit: false }),
  contentSamples: contentSamplesReducer,
  sellerProfile: SellerProfileReducer,
  sellerProfileModals: SellerProfileModalReducer,
  SocialActions: socialActionsReducer,
  sms: smsReducer,
  recommendationModals: recommendationModalsReducer,
  dealRequest: DealRequestReducer,
  brandRatings: ratingsReducer,
  roiCalculator: ROICalculatorReducer,
  creditCardInfoModal: ModelReducerFor('creditCardInfoModal', { open: false }),
  achInfoModal: ModelReducerFor('achInfoModal', { open: false }),
  reinviteConfirmationModal: ModelReducerFor('reinviteConfirmationModal', { open: false }),
  officialRulesHostReminderModal: ModelReducerFor('officialRulesHostReminderModal', { open: false }),
  billingEmails: billingEmailsReducer,
  dataDisclosures: dataDisclosureReducer,
  dataDisclosurePolicy: dataDisclosurePolicyReducer
};

export default combineReducers(reducers);
