import React from 'react'
import { string, func, bool } from 'prop-types'

import CheckboxList from 'visual-components/util/filters/checkbox-list'
import FilterBox from './filter-box'

const CheckBoxFilter = props => {
  const {
    title,
    tooltip,
    tag,
    learnMore,
    selectAllOption,
    seeMoreText,
    seeLessText,
    hasNestedCollapse,
    name,
    limited,
    showAll,
  } = props

  return (
    <FilterBox
      width="184px"
      title={title}
      tooltip={tooltip}
      tag={tag}
      learnMore={learnMore}
      hasNestedCollapse={hasNestedCollapse}
      paddingBot="12px"
      name={name}
    >
      <CheckboxList
        selectAllOption={selectAllOption}
        seeMoreText={seeMoreText}
        seeLessText={seeLessText}
        name={name}
        limited={limited}
        showAll={showAll}
      />
    </FilterBox>
  )
}

CheckBoxFilter.propTypes = {
  title: string.isRequired,
  tooltip: string,
  tag: string,
  learnMore: func,
  selectAllOption: string,
  seeMoreText: string,
  seeLessText: string,
  hasNestedCollapse: bool,
  name: string.isRequired,
  limited: bool,
  showAll: bool,
}

CheckBoxFilter.defaultProps = {
  tooltip: null,
  tag: '',
  learnMore: null,
  selectAllOption: '',
  seeMoreText: '',
  seeLessText: '',
  hasNestedCollapse: false,
  limited: false,
  showAll: false,
}

export default CheckBoxFilter
