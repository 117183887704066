import React from 'react';
import DojoModal from 'util/components/dojomodal';
import BrandCardContent from 'components/brands/brand-card-content';
import BrandDetailView from 'components/brands/brand-detail-view';
import Text from 'util/components/text';
import TertiaryButton from 'util/components/buttons/tertiary-button';
import PrimaryButton from 'util/components/buttons/primary-button';
import SecondaryButton from 'util/components/buttons/secondary-button';

class CampaignInviteNotification extends React.Component {
  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  accept () {
    this.props.acceptCampaignInvite(this.props.item);
  }

  reject () {
    this.props.rejectCampaignInvite(this.props.item);
  }

  closeModal () {
    this.props.closeModal();
    $('body').removeClass('modal-open');
  }

  render () {
    let item = this.props.item;
    let copy = LANGUAGE.notifications.campaignInvite;
    let text = `${item.invitee.accountname} has been invited by ${item.campaign.hostBrand.accountname} to join ${item.campaign.name}.`
    let previewLink = `/landing/campaign/${item.campaign.id}?preview=true`;
    if (process.env.SENTRY_ENV === 'production') {
      previewLink = `https://do-not-use-this-link.dojomojo.com${previewLink}`
    }

    return (
      <div>
        <DojoModal
          gaName="campaignInvite"
          isOpen={ true }
          hideModal={ this.closeModal }
          title={ copy.title }>
          <div className="row">
            <BrandCardContent
              brand={ item.campaign.hostBrand } />
            <div className="col-xs-12 col-md-8">
              <BrandDetailView
                brand={ item.campaign.hostBrand } />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3>
                { text }
              </Text.dojoH3>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <a href={ previewLink } target="_blank">
                <TertiaryButton styles={[{
                  marginTop: '15px'
                }]}>
                  { copy.preview }
                </TertiaryButton>
              </a>
            </div>
            <div className="col-xs-8 text-right">
              <PrimaryButton
                onClick={this.accept}
                styles={[{
                  marginTop: '15px'
              }]}>
                { LANGUAGE.notifications.accept }
              </PrimaryButton>
              <SecondaryButton
                onClick={ this.reject }
                styles={[{
                  marginLeft: '15px',
                  marginTop: '15px'
              }]}>
                { LANGUAGE.notifications.reject }
              </SecondaryButton>
            </div>
          </div>
        </DojoModal>
      </div>
    );
  }
};

export default CampaignInviteNotification;
