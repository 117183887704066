import React from 'react'
import { Helmet } from 'react-helmet'

import TrackingLinks from 'visual-components/trackingLinks/trackingLinks'
import LegalDocuments from 'visual-components/legalDocuments/legalDocuments'
import Assets from 'visual-components/assets/assets'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'

class LinksFiles extends React.Component {
  render() {
    return (
      <div style={{
        paddingTop: '16px'
      }}>
        <Helmet>
          <title>{ 'Links & Files - DojoMojo' }</title>
        </Helmet>

        <TrackingLinks />
        <LegalDocuments />
        <Assets />
      </div>
    )
  }
}

export default LinksFiles
