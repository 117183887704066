import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import getCoverPhotoUrl from 'util/brand-cover-photo'
import ProfileTheme from 'css/themes/Profile.css'
import BrandCoverPhotoContainer from 'container-components/brands/BrandCoverPhotoContainer'
import BrandFavoriteIcon from './brand-favorite-icon'

const stateToProps = ({ currentBrand, brand }) => ({
  currentBrand,
  brand,
  isPublic: brand.obscure,
})

class BrandCoverPhoto extends PureComponent {
  render() {
    const {
      isPublic,
      currentBrand,
      brand,
      brandId,
      isBrand,
    } = this.props

    const coverImg = isBrand ? currentBrand.facebookCoverPhoto : brand.facebookCoverPhoto
    const imgSrc = getCoverPhotoUrl(coverImg)
    const coverPhotoOffsetY = isBrand ? currentBrand.coverPhotoOffsetY : brand.coverPhotoOffsetY

    return (
      <div
        className={ProfileTheme.profile}
        style={{
          width: '100vw',
          left: 0,
          height: 320,
          backgroundImage: imgSrc ? `url(${imgSrc})` : 'none',
          backgroundColor: '#dee4e5',
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'center',
          backgroundPositionX: 'center',
          backgroundPositionY: `${coverPhotoOffsetY}%`,
          backgroundSize: 'cover',
        }}
      >
        {
          isBrand
            ? <BrandCoverPhotoContainer />
            : !isPublic && <BrandFavoriteIcon brandId={brandId} />
        }
      </div>
    )
  }
}

BrandCoverPhoto.propTypes = {
  isPublic: PropTypes.bool,
  isBrand: PropTypes.bool.isRequired,
  currentBrand: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
}

BrandCoverPhoto.defaultProps = {
  isPublic: false,
}

export default connect(stateToProps)(BrandCoverPhoto)
