import React, { Component } from 'react'
import { func, object } from 'prop-types'

import Dialog from 'react-toolbox/lib/dialog'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import * as STEPS from './steps'

const { closeImage } = DialogTheme
const { CLOSED } = STEPS

class PayoutSettingsModal extends Component {
  static propTypes = {
    modalStep: object.isRequired,
    toggleModal: func.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
  }

  close = () => {
    const { toggleModal } = this.props
    toggleModal(CLOSED)
  }

  renderSingleModal = ([key, currentStep]) => {
    if (currentStep === CLOSED) return null
    const {
      modalStep, toggleModal, brandAccount, brandAccountActions,
    } = this.props
    const { title, FormComponent } = currentStep
    if (!FormComponent) return null

    return (
      <Dialog key={key} theme={DialogTheme} active={currentStep === modalStep} title={title}>
        <img onClick={this.close} className={closeImage} src="/images/icons/close.svg" />
        <FormComponent
          brandAccount={brandAccount}
          brandAccountActions={brandAccountActions}
          toggleModal={toggleModal}
          cancel={this.close}
        />
      </Dialog>
    )
  }

  render() {
    return <React.Fragment>{Object.entries(STEPS).map(this.renderSingleModal)}</React.Fragment>
  }
}

export default PayoutSettingsModal
