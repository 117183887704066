import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Button from 'react-toolbox/lib/button';
import * as ReactRedux from 'react-redux';
import { bindActionCreators } from 'redux';

import theme from 'css/themes/mobile/home/pricing-column.css'
import ButtonTheme from 'css/themes/Buttons.css'

import * as DialogActions from 'actions/dialog-actions'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(DialogActions, dispatch)
})

class PricingColumn extends Component {
  clickButton = () => {
    var hasUser = (typeof store.getState().profile.id === 'number');
    let registrationURL = hasUser
      ? `/app/registrations/brand-setup${ this.props.referralURLExtension }`
      : `/app/register${ this.props.referralURLExtension }`

    if (this.props.belt == 'elite' || this.props.belt == 'sensei') {
      registrationURL = '/elite-request';
    };

    if ( this.props.mailTo ) {
      registrationURL = this.props.mailTo;
      window.location.href=registrationURL;
      return
    };

    if (hasUser) {
      window.location = registrationURL;
    } else {
      this.props.history.push(registrationURL)
    }
  }

  render() {
    let {
      isAnnual,
      headline,
      imgSrc,
      subHeader,
      monthlyPrice,
      annualPrice,
      listItems,
      buttonText,
      blueButton,
      belt,
      bannerText,
      mailTo,
      learnMoreButton
    } = this.props;

    let featureList = listItems.map((item, idx) => {
      return(
        <li key={ idx }>
          { item }
        </li>
      )
    });

    const buttonClasses = blueButton ? `${ButtonTheme.blueButton} ${ButtonTheme.flat} ${ButtonTheme.neutral}` : `${ButtonTheme.greenButton}`;

    return(
      <div className={ `${theme.columnContainer} ${bannerText ? null : theme.noBannerText}` }>
        { bannerText
          ? <div className={ theme.banner }>
              <div className={ theme.bannerText }>
                { bannerText }
              </div>
            </div>
          : null
        }
        <div className={ theme.columnInner }>
          <p className={ theme.headline }>
            { headline }
          </p>
          <div className={ theme.imgContainer }>
            <img src={ imgSrc } />
          </div>
          <p className={ theme.subHeaderText }>
            { subHeader }
          </p>
          { learnMoreButton ?
            <div className={ theme.buttonContainer }>
              <Button
                onClick={ this.clickButton }
                label="Contact Us"
                className={ buttonClasses }
                theme={ ButtonTheme }
                style={{marginBottom: '42px'}}
                floating
              />
            </div>
            :
            <React.Fragment>
              <div className={ theme.priceContainer }>
                <p className={ theme.dollar }>
                  $
                </p>
                <p className={ theme.num }>
                  {` ${annualPrice}`}
                </p>
                <p className={ theme.periodicity }>
                  / mo
                </p>
              </div>
              <div className={ theme.monthlyContainer }>
                { monthlyPrice ? (
                  <p className={ theme.monthly }>
                    {`billed annually or $${monthlyPrice} month-to-month`}
                  </p>
                ) : null }
              </div>
            </React.Fragment>
          }
          <div className={ theme.listContainer }>
            <ul>
              { featureList }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ReactRedux.connect(null, mapDispatchToProps)(PricingColumn));
