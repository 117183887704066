import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-toolbox/lib/button/Button'

import theme from 'css/themes/homePage/joinBrands.css'
import ButtonTheme from 'css/themes/Buttons.css'

const JoinBrands = () => (
  <div className={theme.container}>
    <p className={theme.title}>Join over 10,000 brands using DojoMojo</p>
    <p className={theme.description}>
      It’s time to kick start your partnership marketing efforts. Find out for yourself how DojoMojo
      can help you grow and convert more customers.
    </p>
    <a href="/app/register">
      <Button
        label="Start for Free"
        className={`${ButtonTheme.whiteButtonAzureText}`}
        theme={ButtonTheme}
        floating
      />
    </a>
  </div>
)

export default JoinBrands
