
import validator from 'validator'

const InputValidator = {
  IsNumber(value) {
    if (Number.isNaN(+value)) {
      return 'Must be a number'
    }

    return false
  },
  Required(value) {
    if (!value || !value.toString().trim()) {
      return 'Required'
    }

    return false
  },
  MaxLength(max) {
    return function (value) {
      const invalid = value && value.length > max

      if (invalid) {
        return `Cannot exceed ${max} characters`
      }

      return false
    }
  },
  IsPositive(value) {
    const parsedVal = parseFloat(value)

    if (parsedVal < 0) {
      return 'Must be Postive'
    }

    return false
  },
  IsWebsite(value) {
    if (!value) return false

    if (!validator.isURL(value, { require_host: true, require_valid_protocol: true, require_protocol: true })) {
      return 'Invalid domain'
    }

    return false
  },
  IsPhoneNumber(value) {
    if (!value) return false

    value = value.toString().replace(/\(|\)|-| /g, '')

    if (!value.match(/^[0-9]+$/) && (value.length >= 7 && value.length <= 15)) {
      return 'Invalid phone number'
    }
    return false
  },
  IsValidEmail(value) {
    if (!value) return false
    if (validator.isEmail(value)) return `${value} is an invalid email`

    return false
  },
}

export default InputValidator
