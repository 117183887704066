
import React from 'react'
import CalendarTheme from 'css/themes/calendar/calendar.css'
import moment from 'moment-timezone'
import { func, array, object } from 'prop-types'

import Task from './Task'
import ShowMore from './ShowMore'

// Returns function that checks if task is within range
const withDayRange = dateItem => task => (
  task.due.getDate() === dateItem.getDate()
    && task.due.getMonth() === dateItem.getMonth()
    && task.due.getYear() === dateItem.getYear()
)

const showMoreFunc = onShowMore => (...params) => {
  const [dateItem, tasksOfDay, campaignsOfDay, e] = params

  let cardHeight = 0
  let cardWidth = 0

  if (e.clientY > window.innerHeight - 455) {
    cardHeight = window.innerHeight - 455
  } else {
    cardHeight = e.clientY - 200
  }

  if (e.clientX > window.innerWidth - 200) {
    cardWidth = window.innerWidth - 250
  } else if (e.clientX - 200 < 0) {
    cardWidth = e.clientX + 300
  } else {
    cardWidth = e.clientX
  }

  onShowMore(dateItem, tasksOfDay, campaignsOfDay, { left: cardWidth, top: cardHeight })
}

const renderTaskItems = (...params) => (dateItem, idx) => {
  const [
    tasks,
    campaigns,
    onShowMore,
    dragTaskItem,
    dragTaskRelease,
    dragTaskNewDate,
  ] = params

  const withDateRangeCurried = withDayRange(dateItem)

  const tasksOfDay = tasks.filter(withDateRangeCurried)

  const campaignsOfDay = campaigns.filter(campaign => (
    moment(campaign.start).isSameOrBefore(dateItem)
      && moment(campaign.end).isSameOrAfter(dateItem)
  ))

  const isInDate = dragTaskItem.id && withDateRangeCurried(dragTaskItem)

  return (
    <div
      key={idx}
      onMouseUp={dragTaskRelease}
      onMouseEnter={() => {
        dragTaskNewDate(dateItem)
      }}
      className={CalendarTheme.taskDay}
    >
      { tasksOfDay.slice(0, 3).map(task => <Task key={task.id} task={task} />) }
      { isInDate && <Task task={dragTaskItem} /> }
      <ShowMore
        number={tasksOfDay.slice(3).length}
        showMore={e => {
          showMoreFunc(onShowMore)(dateItem, tasksOfDay, campaignsOfDay, e)
        }}
      />
    </div>
  )
}

class Tasks extends React.Component {
  static contextTypes = {
    tasks: array,
    campaigns: array,
    onShowMore: func,
    dragTaskItem: object,
    dragTaskRelease: func,
    dragTaskNewDate: func,
  }

  render() {
    const {
      range,
    } = this.props

    const {
      tasks,
      campaigns,
      onShowMore,
      dragTaskItem,
      dragTaskRelease,
      dragTaskNewDate,
    } = this.context

    const taskItems = range.map(
      renderTaskItems(tasks, campaigns, onShowMore, dragTaskItem, dragTaskRelease, dragTaskNewDate)
    )

    return (
      <div className={CalendarTheme.taskDays}>
        { taskItems }
      </div>
    )
  }
}

export default Tasks
