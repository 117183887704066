export default [
  {
  	name: 'fbLike',
  	title: 'Facebook Likes'
  },
  {
  	name: 'fbShare',
  	title: 'Facebook Shares'
  },
  {
  	name: 'instagramFollow',
  	title: 'Instagram Follows'
  },
  {
  	name: 'instagramVisit',
  	title: 'Instagram Visits'
  },
  {
  	name: 'twitterShare',
  	title: 'Twitter Share'
  },
  {
  	name: 'twitterFollow',
  	title: 'Twitter Follows'
  },
  {
  	name: 'youtubeSubscribe',
  	title: 'YouTube Subscribes'
  },
  {
  	name: 'youtubeWatch',
  	title: 'YouTube Views'
  },
  {
  	name: 'pinterestFollow',
  	title: 'Pinterest Follows'
  },
  {
  	name: 'pinterestPin',
  	title: 'Pinterest Pins'
  },
  {
  	name: 'snapchatFollow',
  	title: 'Snapchat Follows'
  },
  {
  	name: 'appDownload',
  	title: 'App Downloads'
  }
]
