import React from 'react'
import BarGraph from 'visual-components/util/graphs/bar-graph'
import PercentDisplay from './util/percent-display'
import BarGraphHeader from './util/bar-graph-header'

class ClicksPerformanceProgressBar extends React.Component {
  render() {
    let {
      live,
      percentage,
      progressBarMinimum,
      isBelowMinimum,
      isCPC
    } = this.props

    return(
      <div>
        <BarGraphHeader
          live={ live }
          text={ 'CLICKS PERFORMANCE' }
        />
        <BarGraph
          live={ live }
          progress={ percentage }
          minimum={ progressBarMinimum }
          belowMinimum={ isBelowMinimum }
        />
        <PercentDisplay
          live={ live }
          percentage={ percentage }
        />
      </div>
    )
  }
}

export default ClicksPerformanceProgressBar
