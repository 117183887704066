import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function LeaveWarningModal({ history }) {
  const {
    modals, saveChanges, toggleEditMode, nextPath,
  } = React.useContext(MobileMarketingContext)

  const onLeave = () => {
    toggleEditMode(false)
    setTimeout(() => history.push(nextPath), 50)
  }

  const onSaveAndLeave = () => saveChanges({ isConfirmed: true })
    .then(canLeave => canLeave && onLeave())

  return (
    <SimpleInfoModal
      title="Before you leave"
      active={modals.leaveWarning}
    >
      <H4 multiline>Do you want to save your latest changes?</H4>
      <ModalButtonGroup
        canSave
        confirm={onSaveAndLeave}
        cancel={onLeave}
        confirmText="Yes"
        cancelText="Discard"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(withRouter(LeaveWarningModal))

LeaveWarningModal.propTypes = {
  history: PropTypes.object.isRequired,
}
