import AppDispatcher from 'dispatchers/app-dispatcher';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import _ from 'lodash'

const clearCampaign = () => store.dispatch({
  type: 'CLEAR',
  model: 'campaign',
})

var loadCampaign = function (id, cb = () => {}) {
  clearCampaign()
  AppDispatcher.http({
    url: `/campaign-page/${id}`,
    error: err => {
      window.location.replace('/404')
    },
    success: function (data) {
      data.estimatedEntries = 0;
      data.dedicatedListSize = 0;
      data.socialMediaImpressions = 0;
      data.totalPartners = 0;
      data.partners = _.sortBy(data.invites, 'id');

      // visible partner logic should include all accepted partners and as many extra that are pending and have gotten to the partnership agreement step
      data.visiblePartners = data.invites.filter((invite) => {
        return invite.status === 'accepted'
      })

      data.invites.forEach((invite) => {

        let tags = invite.tags || invite.invitee.tagsconnected

        invite.formattedTags = tags.map(tag=> {
          return typeof tag !== 'string' ? tag.name : tag
        })

        if (data.visiblePartners.length < 8) { // still less than 8 and fit criteria(accepted already in list)
          if (!['accepted', 'rejected'].includes(invite.status) && !!invite.partnership_agreement_id) {
            data.visiblePartners.push(invite)
          }
        }
      })

      data.visiblePartnerCount = data.visiblePartners.length
      data.visiblePartners.forEach(invite => {
        // all of this is old/existing logic, but applied to all visiblePartners rather than accepted partners
        data.totalPartners++;
        data.estimatedEntries += invite.invitee.averageSignUpsPerCampaign || parseInt(invite.invitee.dedicatedListSize * 0.01)
        data.dedicatedListSize += invite.invitee.dedicatedListSize || 0
        data.socialMediaImpressions += invite.invitee.social.socialMediaImpressions || 0

        invite.tags = _.map(invite.invitee.tagsconnected, function(tag) {
          return tag.name
        })
        if (data.brandconnections.firstDegreePartners.includes(invite.invitee.id)) {
          invite.degree = '1st';
        } else if (data.brandconnections.secondDegreePartners.includes(invite.invitee.id)) {
          invite.degree = '2nd';
        } else {
          invite.degree = '3rd';
        }
      })

      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'campaign',
        data: _.extend(data, {
          loaded: true,
          hostBrandName: data.hostBrand.accountname
        })
      })

      // wait for data to load, then trigger callback
      setTimeout(cb, 100)
    }
  })
}


export default {
  clearCampaign, loadCampaign
};
