import React, { Component } from 'react';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import COLOURS from 'util/colours';
import UploadButton from 'components/brands/logo/upload-button';
import { H6, P, Tiny } from 'visual-components/util/texts';

import theme from 'css/themes/login/registrationTheme.css'

class UploadLogo extends Component {
  uploadLogoSuccess = (logo) => {
    let data = {
      attr: 'logo',
      value: logo
    }

    CurrentBrandDispatcher.updateAttrPersist(data)
    this.forceUpdate()
  }

  render() {
    let {
      logo
    } = this.props.brand

    return(
      <div className={ theme.uploadLogoWrapper }>
        {/* text section */}
        <div className={ theme.textWrapper }>
          <H6>
            Upload Logo
          </H6>
          <div className={ theme.tinyWrapper }>
            <Tiny multiline>
              Recommended image dimensions are 300 x 300 px or larger, in PNG or JPEG format.
            </Tiny>
          </div>
          <div>
            <UploadButton
              action="/brands/logo"
              src={ logo }
              success={ this.uploadLogoSuccess }
              name="upload-logo-image"
              label={ logo && logo.includes('Empty-Avatar.png') ? 'Upload Image' : 'Change Image' }
            />
          </div>
        </div>

        {/* preview */}
        <div className={ theme.previewWrapper }>
          <div className={ theme.imgWrapper }
            style={{
              backgroundColor: logo ? COLOURS.white : COLOURS.lightGrey,
          }}>
            { logo
              ? <img src={ logo } />
              : <div className={ theme.placeholderText }>
                  <P multiline>
                    Image Preview
                  </P>
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default UploadLogo;
