import React from 'react'
import { Small } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import ClicksPerformanceProgressBar from 'visual-components/analytics/clicks-performance-progress-bar'

const maxMinWarning = params => {
  const {
    isLive,
    dealEmailDojoClicks,
    maxClicks,
    minClicks,
    status,
  } = params

  let text = ''

  if ((dealEmailDojoClicks <= maxClicks) && (dealEmailDojoClicks >= minClicks)) {
    return null
  }
  if (dealEmailDojoClicks < minClicks) {
    text = 'Below Min'
  }
  if (dealEmailDojoClicks > maxClicks) {
    text = 'Above Max'
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '7px',
        position: 'absolute',
        right: '16px',
      }}
    >
      <img
        src={isLive && status === 'live' ? 'images/icons/warning-white.svg' : 'images/icons/warning-gray.svg'}
        style={{ width: '12px', height: '12px', marginRight: '4px' }}
      />
      <Small white={isLive && status === 'live'}>{text}</Small>
    </div>
  )
}

const PerformanceBar = props => {
  const {
    isLive,
    isCPC,
    status,
    dealEmailDojoClicks,
    maxClicks,
    minClicks,
    isFixed,
    expectedViews,
  } = props

  const percentage = isCPC
    ? (dealEmailDojoClicks / maxClicks) * 100
    : isFixed
      ? (dealEmailDojoClicks / expectedViews) * 100
      : null

  const performanceBarStats = {
    percentage,
    progressBarMinimum: (minClicks / maxClicks) * 100,
    isBelowMinimum: dealEmailDojoClicks < minClicks,
  }

  return (
    <Row style={{ marginBottom: '20px', alignItems: 'flex-end' }}>
      <ClicksPerformanceProgressBar
        live={isLive && status === 'live'}
        isCPC={isCPC}
        {...performanceBarStats}
      />
      { !['pending', 'accepted'].includes(status)
        && isCPC
        && maxMinWarning({ isLive, dealEmailDojoClicks, maxClicks, minClicks, status })
      }
    </Row>
  )
}

export default PerformanceBar
