import React from 'react';
import * as ReactRedux from 'react-redux';
import _ from 'lodash'

import Text from 'util/components/text';
import PrimaryButton from 'util/components/buttons/primary-button';
import AppDispatcher from 'dispatchers/app-dispatcher';

class CustomerSuccessAdminSuccessPageProportion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      foundCampaign: 'No Campaign Found',
      invites: []
    }

    this.fetchCampaignInfo = this.fetchCampaignInfo.bind(this);
    this.onchange = this.onchange.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
    this.updatePartnership = this.updatePartnership.bind(this);
  }

  fetchCampaignInfo() {
    let that = this;
    window.typingTimer = setTimeout(function() {
      if (that.state.campaignId) {
        AppDispatcher.http({
          url: `/cs-campaigns/${that.state.campaignId}`,
          success: function(response) {
            that.setState({
              foundCampaign: response ? response.name : 'No Campaign Found',
              invites: response ? response.invites.filter(function(invite) { return invite.status == 'accepted' }) : []
            })
          },
          error: function(err) {
            that.setState({
              foundCampaign: 'No Campaign Found',
              invites: []
            })
          }
        })
      }
    }, 500)
  }

  updatePartnership() {
    let that = this;

    let total = this.state.invites.reduce(function(sum, invite) {
      return sum + parseFloat(invite.successPageProportion);
    }, 0);

    if (total != 1){
      toastr.error('Proportions do not add up to 1');
    } else {
      AppDispatcher.http({
        url: '/cs-update-partnership-proportions',
        method: 'POST',
        data: {
          campaignId: parseInt(this.state.campaignId),
          invites: this.state.invites
        },
        success: function(response) {
          toastr.success('Proportions Updated', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        },
        error: function(err) {
          toastr.error('Failed to update proportions');
        }
      })
    }
  }

  updateStateValue(e) {
    let dupArray = this.state.invites.slice();
    let index = _.findIndex(dupArray, {invitee_id: parseInt(e.target.name)})

    dupArray[index].successPageProportion = e.target.value;

    this.setState({
      invites: dupArray
    })
  }

  onchange(e) {
    clearTimeout(window.typingTimer);

    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    let inviteItems = [];

    if (this.state.invites.length) {
      inviteItems = _.map(this.state.invites, (invite) => {
        return (
          <div className="row">
            <div className="col-xs-9">
              <Text.dojoP>
                { invite.invitee.accountname }:
              </Text.dojoP>
            </div>
            <div className="col-xs-3">
              <input type="number" name={ invite.invitee_id } value={ invite.successPageProportion } min="0" max="1" onChange={ this.updateStateValue } className="col-xs-12"/>
            </div>
          </div>
        )
      })
    }

    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Update Success Page Proportions
          </Text.dojoH2Bold>

          <form className="col-xs-12">
            <div className="row">
              <div className="col-xs-12" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Campaign ID
                  </Text.dojoP>
                  <input type="number" name="campaignId" className="col-xs-12" onChange={ this.onchange } onKeyUp={ this.fetchCampaignInfo }/>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Campaign Name: { this.state.foundCampaign }
              </Text.dojoH3Bold>
            </div>
            <div className="col-xs-12">
              <Text.dojoP>
                Enter a number between 0 and 1 (and make sure the total for all of these adds up to 1)
              </Text.dojoP>
            </div>
          </div>
          <br />

          { inviteItems }

          <div>
            { this.state.invites.length > 0 ?
                <PrimaryButton
                  onClick={ this.updatePartnership }
                  styles={[{
                    width: '220px',
                    height: '50px',
                    marginTop: '20px'
                }]}>
                  Update
                </PrimaryButton>
              :
                null
            }
          </div>
        </div>
      </div>
    )
  }
}

var mapState = function(state) {
  return state;
}

export default ReactRedux.connect(mapState)(CustomerSuccessAdminSuccessPageProportion);
