import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import COLOURS from 'util/colours'

import { Small } from 'visual-components/util/texts'

const styles = {
  container: {
    alignItems: 'flex-end',
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  symbol: {
    backgroundColor: COLOURS.azure,
    borderRadius: '50%',
    color: 'white',
    fontSize: 8,
    height: 12,
    lineHeight: '15px',
    margin: '0px 5px 0px 0px',
    textAlign: 'center',
    width: 12,
  },
}

function HelpLink({ link, text, classes }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={classes.container}>
      <p className={classes.symbol}>?</p>
      <Small azure>
        <em>{text}</em>
      </Small>
    </a>
  )
}

HelpLink.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.string,
  text: PropTypes.string,
}

HelpLink.defaultProps = {
  link: '',
  text: '',
}

export default injectSheet(styles)(HelpLink)
