import React, { Component } from 'react'
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import Grid from 'visual-components/util/no-padding-grid/grid';
import COLOURS from 'util/colours';
import RichEditor from 'visual-components/richEditor/RichEditor';
import { stateToHTML } from 'draft-js-export-html';
import PartnershipDispatcher from 'dispatchers/partnership-invites-dispatcher';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import modalTheme from 'css/themes/dialogs/modalBase.css';

import dialogTheme from 'css/themes/dialogs/reviewModal.css'

class AcceptApplicationConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.updateMessageText = this.updateMessageText.bind(this);
    this.accept = this.accept.bind(this);
  }
  hideModal() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showApplicationAcceptConfirmationModal: null
      }
    })
  }

  accept() {
    let { editorState } = this.state;
    let { message, campaign, brand, receivedApplications, receivedInvites } = this.props
    let contentState = editorState.getCurrentContent();
    let updatedInvites;
    var stuff = contentState.hasText() ? stateToHTML(contentState) : null;

    PartnershipDispatcher.acceptApplication({
      id: message.partnershipinvite_id,
      invitee: {
        id: message.invitee_id,
        name: message.invitee_brand_name
      },
      campaign_start_date: message.campaign_start_date,
      campaign_end_date: message.campaign_end_date,
      campaign_name: message.campaign_name,
      campaign_host_name: message.host_brand_name,
      campaign_id: message.campaign_id,
      message_id: message.id,
      conversation_id: message.conversation_id,
      attached_message: contentState.hasText() ? stateToHTML(contentState) : null,
      application: message.application
    })

    updatedInvites = Object.assign([], message.application ? receivedApplications : receivedInvites);
    let messageIndex = _.findIndex(updatedInvites, {partnershipinvite_id: message.partnershipinvite_id});
    let messageInvite = _.extend({}, updatedInvites[messageIndex], {status: 'accepted', conversation_id: message.conversation_id})

    updatedInvites[messageIndex] = messageInvite;

    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showApplicationAcceptConfirmationModal: null,
        [message.application ? 'receivedApplications' : 'receivedInvites' ]: updatedInvites
      }
    })
  }

  updateMessageText(editorState) {
    this.setState({editorState: editorState})
  }

  render() { 
    const { message } = this.props;
    return (
      <Dialog
        active={ message ? true : false }
        onEscDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
        theme={ dialogTheme }
      >
        <img
          onClick={ this.hideModal }
          className={ modalTheme.closeImage }
          src="/images/icons/close.svg"
        />
        <Grid>
          <Row>
            <Col xs={12} style={{
              textAlign: 'center',
              marginTop: '6px',
              marginBottom: '16px'
            }}>
              <p style={{
                fontSize: '20px',
                fontFamily: 'Larsseit-Medium'
              }}>
                Add { message ? message.invitee_brand_name : null } to { message ? message.campaign_name : null }?
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{
                fontFamily: 'Larsseit-Light',
                fontSize: '16px',
                lineHeight: '24px',
                marginBottom: '10px'
              }}>
                Be sure to discuss what they’ll contribute to prizing and when they’ll need to promote the campaign. We suggest sharing:
              </p>
              <ul style={{
                paddingLeft: '20px',
                fontFamily: 'Larsseit-Light',
                fontSize: '14px',
                lineHeight: '22px',
                color: COLOURS.ink,
              }}>
                <li>What you expect in terms of contribution to prizing</li>
                <li>What date(s), channel(s) and audience size you expect them to promote the giveaway to</li>
                <li>If applicable, how many entries you expect a partner to deliver (generally, this number is a percentage of their audience)</li>
              </ul>
            </Col>
          </Row>
          <Row style={{
            marginTop: '16px'
          }}>
            <Col xs={12}>
              <p style={{
                fontFamily: 'Larsseit-Medium',
                fontSize: '14px',
                lineHeight: '14px',
                paddingBottom: '4px'
              }}>
                Message to New Partner (optional)
              </p>
              <div style={{
                paddingLeft: '6px',
                paddingRight: '6px'
              }}>
                <RichEditor
                  onChange={ this.updateMessageText }
                  placeholder={ 'We expect partners to contribute $300 or the equivalent value towards prizing. We expect every partner to bring in at least 3,000 entries. We would like you to promote this campaign in a dedicated email to your subscriber list in mid-October.' }
                />
              </div>
            </Col>
          </Row>
          <ModalButtonGroup 
            canSave={ true }
            hideLine={ true }
            confirm={ this.accept }
            cancel={ this.hideModal }
            cancelText="Cancel"
            confirmText="Accept"
          />
        </Grid>
      </Dialog>
    )
  }
}


const mapState = (state) => {
  return {
    message: state.conversations.showApplicationAcceptConfirmationModal,
    campaign: state.campaign,
    brand: state.brand,
    receivedApplications: state.conversations.receivedApplications,
    receivedInvites: state.conversations.receivedInvites
  }
}

export default ReactRedux.connect(mapState)(AcceptApplicationConfirmationModal);
