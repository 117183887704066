import AppDispatcher from 'dispatchers/app-dispatcher'
import {H4} from 'visual-components/util/texts'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { isTrialing } from 'util/brand-stripe-belt-helper'

const ConfirmationDispatcher = _.extend({}, AppDispatcher, {
  check: function (data) {
    data.expand = true;
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'confirmationModal',
      data: data
    });
  },
  closeModal: function() {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'confirmationModal',
      data: {
        expand: false,
        loading: false
      }
    });
  },
  exportEntries: function (campaignId) {
    AppDispatcher.http({
      url: `/campaign-export-results/${campaignId}`,
      success: function () {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'confirmationModal',
          data: {
            loading: false,
            expand: false
          }
        });

        var currentBrand = store.getState().currentBrand;
        var customer = store.getState().customer;

        let sub = customer && customer.subscriptions && customer.subscriptions.data
          && customer.subscriptions.data.length ? customer.subscriptions.data[0] : null;

        var trialing = isTrialing(currentBrand, customer)
        if (trialing) {
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'softEntriesPaywallModal',
            data: {
              open: true
            }
          });
        } else {
          toastr.success('Check your email for the downloadable CSV', null, { timeOut: 5000, positionClass: 'toast-bottom-center' });
        }
      },
      error: function(err) {
        if (err.responseText == 'notEnough') {
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'confirmationModal',
            data: {
              loading: false
            }
          });
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'notEnoughEntriesModal',
            data: {
              expand: true,
              campaignId: campaignId
            }
          });
        }
      }
    });
  },
  checkDownloadEntries: function (campaignId, location, filterWebbula) {
    var confirmationHeader = 'Heads Up!';
    var confirmationCopy = (
      <span>
        <H4 multiline>
          We’ll send you an email with your entries attached in .csv files. If you're using DojoMojo's Email Filtering to protect your list
          from potentially harmful emails, any risky emails will already be removed from the file.
          <br />
          <em>Make sure to upload your entries into your ESP in small batches.</em> Failing to do so risks campaign performance and deliverability
          with your ESP. If possible, we recommend integrating with your ESP for your next Campaign.
          <br />
          <em>Note:</em> If you have a minimum threshold set by your host that you have not reached, you will only get access to those entries that have been driven by you or explicitly released by your host.
        </H4>
      </span>
    );

    this.check({
      header: confirmationHeader,
      copy: confirmationCopy,
      confirmText: 'Send Entries',
      action: () => {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'confirmationModal',
          data: {
            loading: true
          }
        });

        this.exportEntries(campaignId);
      },
      location: location
    });
  }
});

export default ConfirmationDispatcher;
