import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { withRouter } from 'react-router-dom'
import { Button } from 'react-toolbox/lib/button'

import ButtonTheme from 'css/themes/Buttons.css'
import { H2, H4, P } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

const styles = {
  banner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  unlockBanner: {
    alignItems: 'center',
    backgroundColor: COLOURS.crystal,
    display: 'flex',
    flexDirection: 'column',
    height: 182,
    marginBottom: 24,
    padding: '24px 0px',
    width: '100%',
  },
  unlockBannerDescription: {
    margin: '12px 0px 18px',
    width: 485,
  },
}

const preview = props => () => {
  const {
    currentCampaign: { uid },
    currentBrand: { id: brandId },
  } = props

  window.open(`/landing/post-page/${uid}/${brandId}?preview=true`)
}

function SocialPostEntryBanner(props) {
  const {
    currentBrand,
    currentCampaign,
    isHost,
    classes: css,
    canViewSplitTraffic,
  } = props

  const renderUnlockBanner = () => (
    <div className={css.unlockBanner}>
      <H2 medium> Unlock New Acquisition & Conversion Opportunities </H2>
      <div className={css.unlockBannerDescription}>
        <H4 multiline>
          Upgrade your plan to access post entry traffic! Drive fast and easy conversions with our SMS Marketing and Custom CTA features.
        </H4>
      </div>
      <a href="/app/upgrade-plan">
        <Button
          primary
          theme={ButtonTheme}
          className={ButtonTheme.blueButton}
          label="Upgrade Plan"
          raised
        />
      </a>
    </div>
  )
  const canViewSms = !currentBrand.features.block_sms

  return (
    <div>
      <div className={css.banner}>
        <H2 azure>Setup Post Entry Actions</H2>
        <Button
          onClick={preview({ currentBrand, currentCampaign })}
          theme={ButtonTheme}
          className={ButtonTheme.greenButton}
          label="Preview"
          style={{ marginLeft: '16px' }}
          raised
        />
      </div>
      <P multiline style={{ margin: '12px 0 24px' }}>
        {isHost ? `Take advantage of your entrants' attention with the right Post Entry Action. ${canViewSms ? 'Spur SMS Marketing subscribers with a special offer,' : ''}${canViewSms ? ' d' : 'D'}rive traffic to your website with a Custom Call-to-Action${canViewSms ? ',' : ''} and build your social audience with Social Growth.` : 'Take advantage of your entrants and their attention with the right post entry actions.'}
      </P>
      {!canViewSplitTraffic && renderUnlockBanner()}
    </div>
  )
}

SocialPostEntryBanner.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isHost: PropTypes.bool.isRequired,
  canViewSplitTraffic: PropTypes.bool.isRequired,
}

export default withRouter(injectSheet(styles)(SocialPostEntryBanner))