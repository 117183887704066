import React from 'react';
import DojoModal from 'util/components/dojomodal';
import Text from 'util/components/text';
import COLOURS from 'util/colours';
import PrimaryButton from 'util/components/buttons/primary-button';
import SecondaryButton from 'util/components/buttons/secondary-button';
import Checkbox from 'util/components/form-elements/checkbox';

import * as ReactRedux from 'react-redux';

var mapState = function (state) {
  return {
    currentCampaign: state.currentCampaign
  };
}

class AgreeToEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreeTerms: false
    }
    this.closeModal = this.closeModal.bind(this);
    this.toggleCheckBox = this.toggleCheckBox.bind(this);
    this.proceedToEdit = this.proceedToEdit.bind(this)
  }

  toggleCheckBox() {
    this.setState({
      agreeTerms: !this.state.agreeTerms
    })
  }

  closeModal() {
    this.setState({
      agreeTerms: false
    })

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentCampaign',
      data: {
        showAgreeToEditModal: false
      }
    });
  }

  proceedToEdit() {
    this.setState({
      agreeTerms: false
    })

    var data = {
      editingAttr: this.props.currentCampaign.agreeToEditAttr,
      showAgreeToEditModal: false
    }

    store.dispatch({
      type: 'UPDATE_ATTR',
      data: data,
      model: 'currentCampaign'
    })

    this.props.agreetoLiability(this.props.currentCampaign.agreeToEditLiabilityAttr) ;
    this.closeModal();
  }

  render() {
    const buttonStyles = [{
      width: '150px',
      minWidth: '150px',
      height: '30px',
      minHeight: '30px',
      fontSize: '14px',
      marginTop: '20px'
    }]

    const modalButtonStyles = buttonStyles.concat({
      height: '40px',
      minHeight: '40px'
    })

    return (
      <div>
        <DojoModal
          isOpen={ this.props.currentCampaign.showAgreeToEditModal }
          hideModal={ this.closeModal }
          customOpenDialogStyle = {{
            top: '30%'
          }}
          title='Disclaimer'
        >
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <Text.dojoP styles={[{
                    marginBottom: '24px',
                    color: COLOURS.alertRed
                  }]}>
                    We’ve provided sample language based on best practices, and suggest consulting legal counsel before creating your own. In no event will Innovation Brands Corp and DojoMojo be held liable for any loss or damage whatsoever arising from or in connection with the use of this website.
                  </Text.dojoP>
                  <br />
                  <br />
                  <Checkbox
                    toggleChecked={this.toggleCheckBox}
                    label = {'I agree and would like to proceed with editing'}
                    value={ this.state.agreeTerms }
                    labelStyles={[{
                      lineHeight: '22px'
                    }]} />
                  <div className="text-center">
                    <SecondaryButton
                      onClick={this.closeModal}
                      styles= { modalButtonStyles.concat({ marginRight: '20px' }) }
                      blueButton={ true }
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={this.proceedToEdit}
                      isDisabled = {!this.state.agreeTerms}
                      styles={ modalButtonStyles }
                      blueButton={ true }
                    >
                      Edit Terms
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DojoModal>
      </div>
    )
  }
};

export default ReactRedux.connect(mapState)(AgreeToEditModal);
