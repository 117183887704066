import MessagingDispatcher from 'dispatchers/messaging-dispatcher'

// Check if new instance was created by current user and brand
function isOwn(data, getState) {
  const { payload: { brand_id, user_id } } = data
  const {
    currentBrand: { id: current_brand_id },
    profile: { id: current_user_id },
  } = getState()

  const isBrandAndUser = (brand_id === current_brand_id && user_id === current_user_id)
  return isBrandAndUser
}

export const load = overrideStart => (dispatch, getState) => {
  const { loading, start } = getState().notifications

  // Prevent double load
  if (loading) return

  dispatch({
    http: { url: `/notifications/${overrideStart || start || ''}` },
    types: ['LOADING_NOTIFICATIONS', 'LOAD_NOTIFICATIONS_SUCCESS', 'LOAD_NOTIFICATIONS_FAILURE'],
  })
}

export const handleNotification = rawData => (dispatch, getState) => {
  let data
  try {
    data = JSON.parse(rawData)
  } catch (e) {
    console.warn(rawData)
    return
  }

  // If message notification, and for current brand, reload messages
  if (
    data.presentational
    && data.presentational.isMessage
    && getState().currentBrand.id === data.brand_id
  ) {
    MessagingDispatcher.loadConversations()
  }

  if (data.type === 'referral_joined') {
    dispatch({ type: 'NEW_REFERRAL_JOINED', model: 'referrals' })
  }

  // Handle notificatons with Redux action type attached
  if (data.actionType) {
    if (isOwn(data, getState)) return // ignore own actions
    dispatch({ ...data, type: data.actionType })
  } else {
    // Handle all other live notifications
    dispatch({ type: 'LOAD_NEW_NOTIFICATION', data })
  }
}

export const read = id => ({
  http: {
    method: 'POST',
    url: '/notification-read',
    data: { id },
  },
  types: [id === 'all' ? 'READ_ALL_NOTIFICATIONS' : 'READ_NOTIFICATION', 'NOOP', 'NOOP'],
  id,
})

export const readAll = () => dispatch => {
  dispatch(read('all'))

  dispatch({
    type: 'MARK_ALL_REFERRAL_READ',
    model: 'referrals',
  })
}

export const readAllReferrals = () => dispatch => {
  dispatch({
    type: 'MARK_ALL_REFERRAL_READ',
    model: 'referrals',
  })

  dispatch({
    http: {
      url: '/notification-read/referral_joined',
      method: 'POST',
      data: {},
    },
    types: [
      'NOOP',
      'DEDUCT_UNREAD_COUNT_FOR_REFERRALS',
      'NOOP',
    ],
  })
}

export const dismiss = id => ({ type: 'DISMISS_NOTIFICATION', id })
