import React from 'react'
import _ from 'lodash'

import Field from 'visual-components/util/inputs/field'
import SelectField from 'visual-components/util/inputs/select-field'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P, H3 } from 'visual-components/util/texts'
import Countries from 'util/countries'
import COLOURS from 'util/colours'
import Validator from 'util/form-validator'

let countries = Countries.map(country => ({
  value: country.name,
  label: country.name
}))

countries.unshift({
  label: 'United States',
  value: 'United States'
})

class HostBrandInfo extends React.Component {
  state = {
    hostLegal: this.props.partnershipAgreement.agreement.host_legal
  }

  updateAttr = (attr, val) => {
    const { partnershipAgreement: { id: invite_id } } = this.props

    this.setState({
      hostLegal: Object.assign({}, this.state.hostLegal, {
        [attr]: val
      })
    }, _ => {
      this.updateAttrPersist(Object.assign({}, this.state.hostLegal, {
        [attr]: val
      }), invite_id)
    })
  }

  updateAttrPersist = _.debounce((data, inviteId) => {
    const { actions } = this.props
    actions.updateLegalDetails(data, inviteId)
  }, 200)

  render() {
    const { host, partnershipAgreement: { agreement: { hasErrors = [] } } } = this.props
    const { hostLegal } = this.state

    return (
      <div>
        <Row style={{
          marginBottom: '16px'
        }}>
          <H3 small>
            { `Host Brand Information` }
          </H3>
        </Row>

        <Row style={{
          marginBottom: '24px'
        }}>
          <P multiline>
            { `Enter your own Brand's information below. You only need to enter this information once - it will save for all subsequent Partnership Agreements for this sweepstakes.` }
          </P>
        </Row>

        <Row>
          <Col style={{
            width: '560px',
            marginRight: '20px'
          }}>
            <Field label={ 'Legal Company Name' }
              attrName={ 'company_name' }
              placeholder={ 'Company Name, Inc' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('company_name') && !hostLegal.company_name ? COLOURS.alertRed : '' }}
            />
          </Col>
          <Col style={{
            width: '276px',
            marginRight: '8px'
          }}>
            <Field label={ 'State of Incorporation' }
              attrName={ 'incorporation_state' }
              placeholder={ 'New York' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('incorporation_state') && !hostLegal.incorporation_state ? COLOURS.alertRed : '' }}
            />
          </Col>
          <Col style={{
            width: '276px'
          }}>
            <Field label={ 'Entity Type' }
              attrName={ 'entity_type' }
              placeholder={ 'Corporation' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('entity_type') && !hostLegal.entity_type ? COLOURS.alertRed : '' }}
            />
          </Col>
        </Row>

        <Row style={{
          marginBottom: '-18px'
        }}>
          <Col style={{
            width: '370px',
            marginRight: '8px'
          }}>
            <Field label={ 'Address Line 1' }
              attrName={ 'address1' }
              placeholder={ '111 Street Name' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('address1') && !hostLegal.address1 ? COLOURS.alertRed : '' }}
            />
          </Col>
          <Col style={{
            width: '182px',
            marginRight: '20px'
          }}>
            <Field label={ 'Address Line 2' }
              attrName={ 'address2' }
              placeholder={ 'Apt, Suite, Unit #' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('address2') && !hostLegal.address2 ? COLOURS.alertRed : '' }}
            />
          </Col>
          <Col style={{
            width: '560px'
          }}>
            <Field label={ 'Company Terms URL' }
              attrName={ 'terms_url' }
              placeholder={ 'www.companywebsite.com/terms' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('terms_url') && !hostLegal.terms_url ? COLOURS.alertRed : '' }}
            />
          </Col>
        </Row>

        <Row style={{
          marginBottom: '-18px'
        }}>
          <Col style={{
            width: '370px',
            marginRight: '8px'
          }}>
            <Field label={ 'City' }
              attrName={ 'city' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('city') && !hostLegal.city ? COLOURS.alertRed : '' }}
            />
          </Col>
          <Col style={{
            width: '182px',
            marginRight: '20px'
          }}>
            <Field label={ 'State/Province/Region' }
              attrName={ 'state' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('state') && !hostLegal.state ? COLOURS.alertRed : '' }}
            />
          </Col>
        </Row>

        <Row style={{
          marginBottom: '48px'
        }}>
          <Col style={{
            width: '182px',
            marginRight: '8px'
          }}>
            <Field label={ 'Zip/Postal Code' }
              attrName={ 'zip' }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('zip') && !hostLegal.zip ? COLOURS.alertRed : '' }}
            />
          </Col>
          <Col style={{
            width: '370px'
          }}>
            <SelectField label={ 'Country' }
              attrName={ 'country' }
              options={ countries }
              updateAttr={ this.updateAttr }
              dataModel={ hostLegal }
              formModel={ {} }
              style={{ borderColor: hasErrors.includes('country') && !hostLegal.country ? COLOURS.alertRed : '' }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default HostBrandInfo
