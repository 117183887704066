import React from 'react';

import { Card } from 'react-toolbox/lib/card';

import GenderDistributionGraph from 'visual-components/util/graphs/gender-distribution-graph';
import EmptyState from 'visual-components/util/graphs/empty-state';
import { H3 } from 'visual-components/util/texts';

import AnalyticsTheme from 'css/themes/analytics.css';

class GenderDistributionCard extends React.Component {
  render() {
    let { data, percentHoverOnly } = this.props

    return (
      <Card className={ `${AnalyticsTheme.sideCard} ${AnalyticsTheme.genderDistributionCard}` }>
        <div style={{
          padding: '20px 24px'
        }}>
          <div style={{
            marginBottom: '49px'
          }}>
            <H3 coral>
              <small>
                Entries by Gender
              </small>
            </H3>
          </div>
          {
            data && data[0] && (data[0].y !== 0 || data[1].y !== 0) ?
            <GenderDistributionGraph data={ data } percentHoverOnly={percentHoverOnly} />
            :
            <EmptyState text={ 'No gender data available yet.' }
              height={ '80px' }
            />
          }
        </div>
      </Card>
    )
  }
}

export default GenderDistributionCard;
