import React, { PureComponent as Component } from 'react'
import COLOURS from 'util/colours'
import { number, bool } from 'prop-types'

class BarGraph extends Component {
  static propTypes = {
    progress: number.isRequired,
    live: bool.isRequired,
  }

  render() {
    const {
      progress,
      live,
      minimum,
      belowMinimum,
    } = this.props

    const backgroundColor = live ? COLOURS.stone : COLOURS.silver
    const barColor = belowMinimum ? COLOURS.warningRed : live ? COLOURS.neonMint : COLOURS.azure
    const minimumBarColor = live ? COLOURS.white : '#5c7f98'

    return (
      <svg width="200px" height="16px" viewBox="0 0 200 16" version="1.1">
        <title>Horizontal Bar Graph Dark</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="analytics/campaign-card/live-campaign/min-entry" transform="translate(-20.000000, -192.000000)">
            <g id="Minimum-Entry-Threshold">
              <g transform="translate(20.000000, 172.000000)">
                <g id="Horizontal-Bar-Graph-Dark" transform="translate(0.000000, 20.000000)">
                  <rect
                    id="Rectangle-9-Copy"
                    fill={backgroundColor}
                    x="0"
                    y="0"
                    width="100%"
                    height="16"
                  />
                  <rect
                    id="Rectangle-9-Copy-2"
                    fill={barColor}
                    x="0"
                    y="0"
                    width={`${progress}%`}
                    height="16"
                  />
                  {
                    minimum
                    && (
                      <rect
                        id="Rectangle-9-Copy-Minimum"
                        fill={minimumBarColor}
                        x={`${minimum}%`}
                        y="0"
                        width="4"
                        height="20"
                      />
                    )
                  }
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default BarGraph
