import React from 'react'
import PropTypes from 'prop-types'

import Field from 'visual-components/util/inputs/field'
import Validator from 'util/form-validator'

const validations = {
  ctaMessageText: [Validator.required],
  ctaURL: [Validator.required],
  ctaActionText: [Validator.required],
}

class CtaButton extends React.Component {
  render() {
    return (
      <div>
        <Field
          {...this.props}
          attrName="ctaMessageText"
          label="Headline Message"
          placeholder="Headline Message"
          validations={validations.ctaMessageText}
        />
        <Field
          {...this.props}
          attrName="ctaUrl"
          label="Custom CTA Link"
          placeholder="http://www.companysite.com/promo"
          validations={validations.ctaURL}
        />
        <Field
          {...this.props}
          attrName="ctaActionText"
          label="Button Text"
          placeholder="Button Text"
          validations={validations.ctaActionText}
        />
      </div>
    )
  }
}

export default CtaButton
