import LegalDetailsConstants from 'constants/legal-details-constants';

export function closeModal() {
  return {
    type: LegalDetailsConstants.CLOSE_LEGAL_DETAILS_MODAL
  }
}

export function openModal(partnershipId) {
  return {
    type: LegalDetailsConstants.OPEN_LEGAL_DETAILS_MODAL,
    payload: { partnershipId }
  }
}

export function saveLegalDetails(data, partnershipId) {
  return {
    type: LegalDetailsConstants.SAVE_LEGAL_DETAILS,
    payload: {
      data,
      partnershipId
    }
  };
}