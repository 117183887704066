import moment from 'moment-timezone'

export const clearError = () => ({ type: 'CLEAR_BRAND_ACCOUNT_ERROR' })

export const updateModel = data => ({
  type: 'UPDATE_BRAND_ACCOUNT',
  data,
})

export const load = () => (dispatch, getState) => {
  if (getState().brandAccount.loading) return
  dispatch({
    http: {
      url: '/brand-account',
      method: 'GET',
    },
    types: ['LOADING_BRAND_ACCOUNT', 'LOAD_BRAND_ACCOUNT_SUCCESS', 'LOAD_BRAND_ACCOUNT_FAILURE'],
  })
}

export const persistAccountDetails = data => (dispatch, getState) => {
  if (getState().brandAccount.loading) return
  const {
    legal_entity: {
      business_name,
      address,
      dob,
      first_name,
      last_name,
      full_name,
      ssn_last_4,
      business_tax_id,
    },
    terms_accepted,
    tos_already_accepted,
  } = data

  // Parse date into object
  const dobMoment = moment.utc(dob, 'MM/DD/YYYY')

  const legal_entity = {
    business_name,
    address,
    dob: {
      year: dobMoment.year(),
      month: dobMoment.month() + 1,
      day: dobMoment.date(),
    },
    first_name,
    last_name,
    full_name,
    ssn_last_4,
    business_tax_id,
  }

  const payload = { legal_entity }

  // Accept terms if haven't already
  if (!tos_already_accepted && terms_accepted) payload.terms_accepted = true

  dispatch({
    http: {
      url: '/brand-account',
      method: 'PUT',
      data: payload,
    },
    types: ['LOADING_BRAND_ACCOUNT', 'LOAD_BRAND_ACCOUNT_SUCCESS', 'LOAD_BRAND_ACCOUNT_FAILURE'],
  })
}

export const addBankAccount = ({ id }) => ({
  http: {
    url: '/brand-account/external-account',
    method: 'POST',
    data: { id },
  },
  types: ['LOADING_BRAND_ACCOUNT', 'ADD_BANK_ACCOUNT_SUCCESS', 'ADD_BANK_ACCOUNT_FAILURE'],
})

export const verifyBankAccount = ({ amount1, amount2 }, simpleVerifyId) => ({
  simpleVerifyId,
  http: {
    url: '/brand-account/verify-external-account',
    method: 'POST',
    data: {
      simpleVerifyId,
      amount1: Math.round(Number(amount1) * 100),
      amount2: Math.round(Number(amount2) * 100),
    },
  },
  types: ['LOADING_BRAND_ACCOUNT', 'VERIFY_BANK_ACCOUNT_SUCCESS', 'VERIFY_BANK_ACCOUNT_FAILURE'],
})

export const loadTransactionHistory = () => (dispatch, getState) => {
  if (getState().brandAccount.transactionsLoading) return
  dispatch({
    http: {
      url: '/transaction-history',
      method: 'GET',
    },
    types: [
      'LOADING_BRAND_ACCOUNT_TRANSACTIONS',
      'LOAD_BRAND_ACCOUNT_TRANSACTIONS_SUCCESS',
      'LOAD_BRAND_ACCOUNT_TRANSACTIONS_FAILURE',
    ],
  })
}
