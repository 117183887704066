import React from 'react';
import { bool, string, array } from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-toolbox/lib/button/Button';

import theme from 'css/themes/homePage/sideImg.css';
import ButtonTheme from 'css/themes/Buttons.css';

class SideImgContent extends React.Component {
  static propTypes = {
    right: bool,
    title: string.isRequired,
    para: string.isRequired,
    subparas: array,
    lis: array,
    buttonText: string,
    buttonUrl: string,
    href: string,
    imgSrc: string.isRequired,
    href: string,
    className: string
  }

  render() {
    const {
      right,
      title,
      para,
      subparas,
      lis,
      buttonText,
      buttonUrl,
      href,
      imgSrc,
      className,
      isIntegrations,
    } = this.props;

    var theLis;

    if (lis) {
      theLis = lis.map((li, idx) => {
        return( <li className={ theme.li }
            key={ idx }>
            { li }
          </li>
        )
      })
    }

    const renderSubparas = () => (
      <div className={theme.subparas}>
        {subparas.map(subpara => (
          <div className={theme.subpara}>
            <b> {subpara.title} </b>
            <span className={theme.light}>
              {subpara.text}
            </span>
          </div>
        ))}
      </div>
    )

    const [imgName, extension] = imgSrc.split('.')

    const srcSet = `
      ${imgName}.${extension},
      ${imgName}@2x.${extension} 2x,
      ${imgName}@3x.${extension} 3x
    `

    return(
      <div
        className={ `${theme.container} ${right ? theme.right : theme.left} ${className}`}
        id={ href }
        style={{ paddingBottom: isIntegrations ? '64px' : '144px' }}
      >
        <div className={ theme.imgHolder }>
          <img alt={title} src={ imgSrc } srcSet={ srcSet } />
        </div>

        <div className={ theme.textHolder }>
          <div className={ theme.texts }>
            <p className={ theme.title }>
              { title }
            </p>
            <p className={ theme.para }>
              { para }
            </p>
            { !!subparas && renderSubparas() }
            { lis
              ? <ul className={ theme.ul }>
                  { theLis }
                </ul>
              : null
            }
            { buttonText
              ? (
                <div className={theme.buttonContainer}>
                  <Link to={ buttonUrl }>
                    <Button
                      label={ buttonText }
                      className={ `${ButtonTheme.greenButton}` }
                      theme={ ButtonTheme }
                      floating
                    />
                  </Link>
                </div>
              )
              : null
            }
          </div>
        </div>
      </div>
    )
  }
};

export default SideImgContent;
