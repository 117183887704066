import React, { Component } from 'react'

import { H3 } from 'visual-components/util/texts'

export default class Impersonating extends Component {
  constructor(props) {
    super(props)
    const impersonating = sessionStorage.getItem('impersonating')
    this.isImpersonating = impersonating && impersonating !== 'null'
  }

  render() {
    return this.isImpersonating ? (
      <div
        style={{
          position: 'fixed',
          width: '320px',
          backgroundColor: 'red',
          borderRadius: '3px',
          opacity: '0.7',
          color: 'white',
          left: '30px',
          bottom: '30px',
          zIndex: '500',
          padding: '10px',
          textAlign: 'center',
          verticalAlign: 'middle',
        }}
      >
        <H3 white>
          You are impersonating (
          <a style={{ color: 'white' }} href="#" onClick={window.close}>
            CLOSE
          </a>
          )
        </H3>
      </div>
    ) : null
  }
}
