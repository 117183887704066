import React from 'react'
import FormContainer from 'v2/components/templates/FormContainer'
import Logo from 'v2/components/templates/Logo'
import Text from 'v2/components/templates/Text'
import propTypes from 'prop-types'
import TemplateContainer from 'v2/components/templates/TemplateContainer'
import BackgroundOverlay from 'v2/components/templates/BackgroundOverlay'
import { templateStyles } from 'v2/components/templates/styles/version-10'
import injectSheet from 'react-jss'
import styleHelper from 'v2/styles/style-helper'
import constants from 'v2/components/templates/constants.json'
import getEditorMenuWidth from 'v2/components/templates/utils/getEditorMenuWidth'

const menuWidth = props => (getEditorMenuWidth(
  props.edit,
  props.desktopVersion,
  props.isEditorMenuOpen
))


const styles = {
  leftColumn: {
    position: props => (props.desktopVersion ? 'fixed' : 'relative'),
    display: 'flex',
    width: props => (props.desktopVersion ? `calc(50% - ${menuWidth(props) / 2}px)` : 'calc(100% - 40px)'),
    margin: props => (props.desktopVersion ? 0 : '0 20px'),
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    top: 0,
    left: menuWidth,
    padding: '10px 0',
    transition: `left ${constants.menuTransitionTime}, width ${constants.menuTransitionTime}`,
    [styleHelper.max(1100)]: {
      position: 'relative !important',
      width: 'calc(100% - 40px) !important',
      margin: '0 20px ! important',
    },
  },
  formContainer: {
    background: 'rgba(255,255,255,.85)',
    position: 'relative',
    zIndex: 1,
    width: props => (props.desktopVersion ? 'calc(50% - 80px)' : 'calc(100% - 40px)'),
    margin: props => (props.desktopVersion ? '0 80px 0 auto' : '52px 20px 0 20px'),
    [styleHelper.max(1100)]: {
      margin: '52px 20px 0 20px !important',
      width: 'calc(100% - 40px) !important',
    },
  },
  headlineContainer: {
    position: 'relative',
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 60px)'),
    margin: props => (props.desktopVersion ? '30px 60px 0 60px' : '30px'),
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 60px) !important',
      margin: '30px !important',
    },
  },
  preEntryMessageContainer: {
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 60px)'),
    marginTop: 32,
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 60px) !important',
    },
  },
  detailsContainer: {
    margin: 32,
  },
  ...templateStyles,
}

const hexToRGBA = (color, percent = 1) => {
  const colors = color.substring(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16))
  return `rgba(${colors.join(', ')}, ${percent})`
}

class Version10 extends React.Component {
  static propTypes = {
    opts: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool,
    isEditorMenuOpen: propTypes.bool,
  }

  static defaultProps = {
    desktopVersion: true,
    isEditorMenuOpen: false,
  }

  static childContextTypes = {
    baseStyles: propTypes.object,
    userStyles: propTypes.object,
  }

  getChildContext() {
    const {
      classes: css,
      opts: {
        elements: {
          styles: userStyles,
        },
      },
    } = this.props

    return {
      baseStyles: css,
      userStyles,
    }
  }

  render() {
    const {
      opts: {
        campaign,
        hostBrand,
        elements,
        termsLink,
        acceptedInvites,
        elements: {
          styles: userStyles,
        },
      },
      updateElementsAttr,
      editElements,
      customFonts,
      edit,
      updateAttr,
      agreeToLiability,
      classes: css,
      desktopVersion,
      isEditorMenuOpen,
    } = this.props

    const formStyles = userStyles.form

    return (
      <TemplateContainer
        campaign={campaign}
        elements={elements}
        desktopVersion={desktopVersion}
        updateAttr={updateElementsAttr}
        campaignUpdateAttr={updateAttr}
        acceptedInvites={acceptedInvites}
        isEditorMenuOpen={isEditorMenuOpen}
        hostBrand={hostBrand}
      >
        <BackgroundOverlay
          desktopVersion={desktopVersion}
          isFixed
        />
        <div
          className={css.leftColumn}
        >
          <div id="top-logo-container">
            <Logo
              hostWebsite={hostBrand.website}
              logoPath={elements.top_logo || hostBrand.logo}
              updateAttr={updateElementsAttr}
              edit={editElements}
              campaign={campaign}
              elements={elements}
              maxHeight={elements.styles.topLogo ? elements.styles.topLogo.height : 100}
              className={css.topLogoImg}
              id="top-logo"
              allowDelete
              editName="top-logo"
            />
          </div>
          
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="headLine"
            customFonts={customFonts}
            className={css.headlineContainer}
            width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
          />
          
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="preEntryMessage"
            customFonts={customFonts}
            className={css.preEntryMessageContainer}
            width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
          />

        </div>
        <div
          className={css.formContainer}
          style={{ background: hexToRGBA(formStyles.color, formStyles.opacity) }}
          id="form-holder"
        >
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="details"
            customFonts={customFonts}
            className={css.detailsContainer}
          />
          <FormContainer
            updateAttr={updateAttr}
            updateElementsAttr={updateElementsAttr}
            campaign={campaign}
            elements={elements}
            edit={edit}
            editElements={editElements}
            termsLink={termsLink}
            agreeToLiability={agreeToLiability}
            acceptedInvites={acceptedInvites}
            customFonts={customFonts}
            desktopVersion={desktopVersion}
          />
        </div>
      </TemplateContainer>
    )
  }
}

export default injectSheet(styles)(Version10)
