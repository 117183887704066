import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Card } from 'react-toolbox/lib/card'
import {
  H2, H3, H4, H6,
} from 'visual-components/util/texts'
import { makeNumbersPretty } from 'util/makeNumbersPretty'
import d3 from 'd3'

const styles = {
  card: {
    width: '976px',
    height: '121px',
    overflow: 'visible',
    padding: '20px 24px',
  },
  emptyState: {
    marginLeft: '284px',
    width: '360px',
    height: '48px',
    textAlign: 'center',
  },
  summary: {
    display: 'flex',
    marginTop: '20px',
  },
  summaryItem: {
    marginRight: '40px',
  },
}

const renderSummary = (campaignSmsSummary, classes) => {
  const data = campaignSmsSummary
  return (
    <div className={classes.summary}>
      <div className={classes.summaryItem}>
        <H6>Signups</H6>
        <H2 multiline>{makeNumbersPretty(data ? data.subscribers : 0, true)}</H2>
      </div>
      <div className={classes.summaryItem}>
        <H6>Messages Sent</H6>
        <H2 multiline>{makeNumbersPretty(data ? data.messages : 0, true)}</H2>
      </div>
      <div className={classes.summaryItem}>
        <H6>Clicks</H6>
        <H2 multiline>{makeNumbersPretty(data ? data.clicks : 0, true)}</H2>
      </div>
      <div className={classes.summaryItem}>
        <H6>CTR</H6>
        <H2 multiline>
          {data && data.messages > 0 ? makeNumbersPretty(data.clicks / data.messages * 100) : 0}
          %
        </H2>
      </div>
      <div className={classes.summaryItem}>
        <H6>Orders</H6>
        <H2 multiline>{makeNumbersPretty(data ? data.order_count : 0, true)}</H2>
      </div>
      <div className={classes.summaryItem}>
        <H6>Conversion Rate</H6>
        <H2 multiline>
          {makeNumbersPretty(data ? (data.order_count / data.messages) * 100 : 0, true)}
          %
        </H2>
      </div>
      <div className={classes.summaryItem}>
        <H6>Revenue</H6>
        <H2 multiline>
          $
          {makeNumbersPretty(data ? data.revenue : 0, true)}
        </H2>
      </div>
    </div>
  )
}

const renderEmptyState = classes => {
  return (
    <div className={classes.emptyState}>
      <H4 multiline cloudGrey>
        <i>
          Mobile Marketing allows hosts to send mobile messages to sweepstakes entrants.
          {' '}
          <a
            href="https://help.dojomojo.com/hc/en-us/articles/360036383611"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </i>
      </H4>
    </div>
  )
}

const CampaignMobileMarketingSummary = props => {
  const { data, classes } = props

  return (
    <Card className={classes.card}>
      <div>
        <H3 coral>
          <small>SMS Marketing Summary</small>
        </H3>
      </div>
      {data ? renderSummary(data, classes) : renderEmptyState(classes)}
    </Card>
  )
}

CampaignMobileMarketingSummary.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(CampaignMobileMarketingSummary)
