import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'
import DealTransactionCard from 'visual-components/mediaMarket/analytics/DealTransactionCard'
import * as DealActions from 'actions/deal-actions'

const mapStateToProps = ({ deals, currentBrand }) => ({ deals, currentBrand })
const mapDispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
})

class MediaMarketAnalytics extends Component {
  componentDidMount() {
    const { dealActions } = this.props

    dealActions.load()
  }

  render() {
    let {
      currentBrand,
      deals: { deals, dealESPAnalytics },
      match: { url },
      dealActions,
    } = this.props

    const mediaMarketSell = url.includes('sell')

    const mediaSells = deals
      && deals.length
      && deals.filter(deal => deal.selling_brand.accountname === currentBrand.accountname)
    const mediaBuys = deals
      && deals.length
      && deals.filter(deal => deal.buying_brand.accountname === currentBrand.accountname)
    deals = mediaMarketSell ? mediaSells : mediaBuys

    const myMedia = deals && deals.length ? (
      deals.map(deal => (
        <DealTransactionCard
          key={deal.id}
          dealESPAnalytics={dealESPAnalytics}
          deal={deal}
          dealActions={dealActions}
        />
      ))
    ) : (
      <div>
        <EmptyBonzai
          description={`You don't have any Media Market deals yet. ${
            mediaMarketSell ? 'Go to Media Market tab to start selling!' : ''
          }`}
          noTree
        />
      </div>
    )

    return (
      <div>
        <PartnershipsContainer
          title="Media Market Analytics"
          subtitle={
            mediaMarketSell
              ? 'An overview of all of your Media Market deals as a seller - use this view to better understand your performance and earnings.'
              : 'An overview of all your deals as a Media Market Buyer. Use this to better understand the ROI of your Media Market deals.'
          }
          learnMore="https://help.dojomojo.com/hc/en-us/sections/360002049991-Media-Market-Beta"
          action={mediaMarketSell ? this.sellMedia : null}
          actionLabel="Export CSV"
          content={myMedia}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaMarketAnalytics)
