import React, { Component } from 'react'
import Spinner from 'util/components/spinner'
import { Button } from 'react-toolbox/lib/button'
import Dropzone from 'react-dropzone'

import buttonTheme from 'css/themes/Buttons.css'
import theme from 'css/themes/login/registrationTheme.css'
import axios from 'axios'

class UploadButton extends Component{
  state = {
    loading: false
  }

  onDrop = async files => {
    const { action } = this.props
    this.setState({
      loading: true
    })
    const form = new FormData()
    files.forEach(file => {
      form.append('file', file)
      form.append('name', file.name)
    })
    const { data } = await axios.post(action, form)
    this.setState({
      loading: false
    })
    this.props.success(data)
  }

  render() {    
    if (this.state.loading) {
      return (
        <Spinner />
      );
    }

    let {
      label
    } = this.props

    if (!label) {
      if (!this.props.src) {
        label = LANGUAGE.form.uploadImage;
      } else {
        label = LANGUAGE.form.changeImage;
      }
    }

    return (
      <div>
        <Dropzone className={theme.dropzoneContainer} onDropAccepted={this.onDrop} accept={'image/jpeg,image/png'} maxSize={1048576}>
          <Button
            theme={ buttonTheme }
            className={ buttonTheme.greenButton }
            label={ label }
            raised
          />
        </Dropzone>
      </div>
    )
  }
}

export default UploadButton
