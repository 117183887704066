import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import COLOURS from 'util/colours'

import { P, H1, Small } from 'visual-components/util/texts'
import WrapperTooltip from 'util/components/wrapper-tooltip'
import Tooltip from 'util/components/tooltip-v2'

import SplitTrafficDonut from './SplitTrafficDonut'

const styles = {
  wrapper: {
    backgroundColor: COLOURS.crystal,
    padding: '16px 24px',
    borderRadius: 2,
    display: 'flex',
  },
  labels: {
    display: 'flex',
    flexFlow: 'column',
    '& h1': {
      marginBottom: 8,
    },
  },
  subLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  graph: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  legend: {
    paddingTop: 12,
    marginRight: 12,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  orb: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginRight: 12,
  },
  red: { backgroundColor: COLOURS.alertRed },
  blue: { backgroundColor: COLOURS.azure },
  green: { backgroundColor: COLOURS.seaGreen },
}

function SplitTrafficStatusGraph(props) {
  const { classes: css, traffic } = props

  const renderLegendItem = ({
    percent,
    tooltip,
    color,
    label,
  }) => (
    <WrapperTooltip text={tooltip}>
      <div className={css.legendItem}>
        <div className={color} />
        <P>
          <em>
            {`${percent}% ${label}`}
          </em>
        </P>
      </div>
    </WrapperTooltip>
  )

  return (
    <div className={css.wrapper}>
      <div className={css.labels}>
        <P multiline style={{ marginBottom: 24 }}>
          <em>
            Traffic Split Overview
          </em>
        </P>
        <H1 extralarge multiline>
          {`${(traffic.host + traffic.inactive) || 0} %`}
        </H1>
        <div className={css.subLabel}>
          <Small>
            Real-time host traffic
          </Small>
          <Tooltip color="grey" text="Real-time host traffic represents the actual amount of post entry traffic you, as the host, are or will receive. This includes traffic you've kept for yourself, traffic declined by partners, and traffic intended for partners who are not properly set up yet." />
        </div>
      </div>
      <div className={css.graph}>
        <SplitTrafficDonut
          data={Object.entries(traffic).map(([key, value]) => ({
            value,
            color: {
              host: COLOURS.azure,
              active: COLOURS.seaGreen,
              inactive: COLOURS.alertRed,
            }[key],
          }))}
        />
      </div>
      <div className={css.legend}>
        {
          !!traffic.host
            && renderLegendItem({
              color: cn(css.orb, css.blue),
              percent: traffic.host,
              label: 'host traffic',
              tooltip: 'Traffic reserved for host',
            })
        }
        {
          !!traffic.active
            && renderLegendItem({
              color: cn(css.orb, css.green),
              percent: traffic.active,
              label: 'active sharing',
              tooltip: 'Traffic that is actively being routed to partners',
            })
        }
        {
          !!traffic.inactive
            && renderLegendItem({
              color: cn(css.orb, css.red),
              percent: traffic.inactive,
              label: 'inactive sharing',
              tooltip: 'Traffic that has been shared with inactive partners and is being rerouted to the host.',
            })
        }
      </div>
    </div>
  )
}

SplitTrafficStatusGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  traffic: PropTypes.object.isRequired,
}

export default injectCSS(styles)(SplitTrafficStatusGraph)
