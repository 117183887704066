import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { H4, Small } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

import MessageWrapperDeleteDoneBtns from './DeleteDoneButtons'
import MessageWrapperSendTestEditBtns from './SendTestEditButtons'

const styles = {
  border: {
    outline: `1px solid ${COLOURS.silver}`,
  },
  titlePreview: {
    padding: '0 28px',
    marginBottom: '0 !important',
    '& + div': {
      marginTop: -20,
    },
  },
  padding: {
    padding: '28px !important',
    margin: '0 !important',
  },

  title: {
    marginBottom: 20,
    display: 'flex',
    '& > :first-child': {
      flex: 1,
      '& img': {
        margin: '0 8px',
        '& + p': {
          display: 'inline-block',
          color: COLOURS.redAlert,
        },
      },
    },
    '& > button:last-child': {
      marginRight: 0,
      marginLeft: 12,
    },
    '& button': {
      marginTop: -12,
      marginBottom: -12,
    },
  },

  flex: {
    '& > h4': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    height: 12,
  },
}

function MessageTitle(props) {
  const {
    classes: css,
    isFollowup,
    isPreview,
    message,
  } = props

  const {
    coupon_code,
    custom_sms_message,
    isEditing,
    name = 'Initial Message',
    dirty = {},
    errorFields = {},
  } = message

  const containsCouponTag = /\*\|COUPON(_CODE)?\|\*/g.test(custom_sms_message)
  const hasError = Object.keys(errorFields).some(key => errorFields[key])
  const notPristine = Object.keys(dirty).some(key => dirty[key])

  return (
    <>
      <div
        className={
          cn(css.title, {
            [css.flex]: !isPreview,
            [css.border]: isEditing && !isPreview,
            [css.padding]: isEditing && !isPreview,
            [css.titlePreview]: isPreview,
            'messages-wrapper-title': isPreview,
          })}
      >
        <H4>
          <em>{isFollowup ? name : 'Initial Message'}</em>
          {
            // TODO: Evaluate conditions here
            hasError
            && (notPristine
            || (containsCouponTag && !coupon_code))
            && (
              <div className={css.error}>
                <img src="/images/icons/error-24-px.svg" alt="alert" />
                <Small alertRed micro>
                  Action Required
                </Small>
              </div>
            )
          }
        </H4>
        {
          isPreview
            ? <MessageWrapperSendTestEditBtns isPreview message={message} />
            : isEditing
              ? <MessageWrapperDeleteDoneBtns message={message} isFollowup={isFollowup} />
              : null
        }
      </div>
    </>
  )
}

MessageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isFollowup: PropTypes.bool,
  isPreview: PropTypes.bool,
}

MessageTitle.defaultProps = {
  isFollowup: false,
  isPreview: false,
}

export default React.memo(injectCSS(styles)(MessageTitle))
