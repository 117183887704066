import React from 'react';
import MessageThread from './messaging-thread';
import MessageRowItemContainer from './messaging-row-item-container';
import Grid from 'react-flexbox-grid';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'

class InviteItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      preview: true
    }
    this.togglePreview = this.togglePreview.bind(this);
    this.redirectToInbox = this.redirectToInbox.bind(this);
  }

  togglePreview() {
    this.props.onclick(this.props.message.id);
  }

  redirectToInbox() {
    BrowserHistoryDispatcher.push(`/messaging/inbox/${this.props.message.conversation_id}`)
  }
  
  render() {
    let { message, logo, brandName, userName, text, timestamp, status } = this.props;
    let noToggle = message.messageType == 'sentApplication' || message.messageType == 'sentInvitation';

    if (this.props.preview) {
      return (
        <MessageRowItemContainer 
          logo = { logo }
          brandName = { brandName }
          userName = { userName }
          heading = { message.heading }
          text = { text }
          timestamp = { timestamp }
          clickFunction = { noToggle ? this.redirectToInbox : this.togglePreview }
          status = { status }
          highlight = { message.highlight }
        />
      )
    } else {
      return (
        <MessageThread
          messages = { [message] }
          collapse = { this.togglePreview }
          hideResponse = { true } 
          showCollapseInThread={ true }/>
      )
    }

  }

}

export default InviteItem