import React, { Component } from 'react';
import BaseBanner from '../util/banner-base';
import MobileHeader from './../mobile-header/MobileHeader';

import theme from 'css/themes/mobile/home/banner.css';

class CareerBanner extends Component {
  render() {
    return(
      <div id={ theme.careers }>
        <MobileHeader/>
        <BaseBanner
          title={ 'Careers at DojoMojo' }
          imgSrc={ '/images/big-tiger-face-white.png' }
          // about={ `We built DojoMojo to solve our own challenges as startup founders. Now, we’re solving major marketing challenges for top brands and leveling the playing field for startups looking to grow their audience. ${<br />} At DojoMojo, you’ll be surrounded by a fast moving, open minded and dedicated team of entrepreneurs building powerful software from the ground up. If you thrive in the face of new challenges, want to grow professionally and personally and enjoy being surrounded by a fun and hardworking team, we’d love to meet you!` }
        />
        <p className={ theme.careerParaText }>
          We built DojoMojo to solve our own challenges as startup founders. Now, we’re solving major marketing challenges for top brands and leveling the playing field for startups looking to grow their audience.
          <br />
          <br />
          At DojoMojo, you’ll be surrounded by a fast moving, open minded and dedicated team of entrepreneurs building powerful software from the ground up. If you thrive in the face of new challenges, want to grow professionally and personally and enjoy being surrounded by a fun and hardworking team, we’d love to meet you!
        </p>
      </div>
    )
  }
};

export default CareerBanner;
