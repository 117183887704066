import React, { PureComponent as Component } from 'react'

import { Select, SelectRange } from 'visual-components/util/form'

const ListSizes = [
  { value: '', label: 'Select a range', disabled: true },
  { value: 0, label: 'Not Applicable' },
  { value: 10000, label: '10,000' },
  { value: 50000, label: '50,000' },
  { value: 100000, label: '100,000+' },
]

const CPMs = [
  { value: '-', label: 'Select a range', disabled: true },
  { value: '0-', label: 'Not Applicable' },
  { value: '0-500', label: '$0 - $5' },
  { value: '500-1000', label: '$5 - $10' },
  { value: '1000-1500', label: '$10 - $15' },
  { value: '1500-2000', label: '$15 - $20' },
  { value: '2000-999999', label: '$20+' },
]

const CPCs = [
  { value: '-', label: 'Select a range', disabled: true },
  { value: '0-', label: 'Not Applicable' },
  { value: '0-50', label: '$0 - $0.50' },
  { value: '50-100', label: '$0.50 - $1.00' },
  { value: '100-150', label: '$1.00 - $1.50' },
  { value: '150-200', label: '$1.50 - $2.00' },
  { value: '200-999999', label: '$2.00+' },
]

const OpenRates = [
  { value: '-', label: 'Select a range', disabled: true },
  { value: '0-', label: 'Not Applicable' },
  { value: '0-10', label: '0 - 10%' },
  { value: '10-20', label: '10% - 20%' },
  { value: '20-100', label: '20%+' },
]

const ClickThroughRates = [
  { value: '-', label: 'Select a range', disabled: true },
  { value: '0-', label: 'Not Applicable' },
  { value: '0-2', label: '0 - 2%' },
  { value: '2-4', label: '2% - 4%' },
  { value: '4-100', label: '4%+' },
]

class DedicatedEmailOpportunityInfo extends Component {
  render() {
    const { opportunity } = this.props
    const buying = opportunity.type === 'dedicated_email_buy'

    return (
      <div>
        <Select
          required
          name="min_list_size"
          label={`${buying ? 'Minimum' : 'Your'} List Size`}
          sublabel={buying ? 'Your potential publisher’s minimum list size.' : ''}
          options={ListSizes}
          defaultSelected=""
        />
        <SelectRange
          required
          name="min_target_cpm"
          maxFieldName="max_target_cpm"
          label={buying ? 'Target CPM' : 'Your Desired CPM'}
          sublabel={buying ? 'The price at which you would buy dedicated emails on a cost-per-thousand basis.' : 'The price at which you want to sell dedicated emails on a cost-per-thousand basis.'}
          options={CPMs}
          defaultSelected="-"
        />
        <SelectRange
          required
          name="min_target_cpc"
          maxFieldName="max_target_cpc"
          label={buying ? 'Target CPC' : 'Your Desired CPC'}
          sublabel={buying ? 'The price at which you would buy dedicated emails on a per click basis.' : 'The price at which you want to sell dedicated emails on a per click basis.'}
          options={CPCs}
          defaultSelected="-"
        />
        <SelectRange
          required
          name="min_target_open_rate"
          maxFieldName="max_target_open_rate"
          label={buying ? 'Target Rate' : 'Your Open Rate'}
          sublabel={buying ? 'Your potential publisher’s desired open rate.' : 'Your approximate open rate.'}
          options={OpenRates}
          defaultSelected="-"
        />
        <SelectRange
          required
          name="min_target_clickthrough_rate"
          maxFieldName="max_target_clickthrough_rate"
          label={buying ? 'Target Click-Through Rate' : 'Your Average Click-Through Rate'}
          sublabel={buying ? 'Your potential publisher’s desired click-through rate.' : 'Your approximate click-through rate.'}
          options={ClickThroughRates}
          defaultSelected="-"
        />
      </div>
    )
  }
}

export default DedicatedEmailOpportunityInfo
