import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { object } from 'prop-types'
import moment from 'moment-timezone'
import FullscreenModalWide from 'visual-components/util/modals/FullscreenModalWide'
import { H3, H4, Small } from 'visual-components/util/texts'
import Theme from 'css/themes/payments/payment-received-full-modal-theme.css'
import Spinner from 'util/components/spinner'
import { beltMapping } from 'util/brand-stripe-belt-helper'

const mapStateToProps = ({ currentBrand, customer, general }) =>
  ({ currentBrand, customer, general })

class PaymentReceivedFullModal extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    customer: object.isRequired,
    match: object.isRequired,
    general: object,
  }

  static defaultProps = {
    general: {},
  }

  componentDidMount() {
    sessionStorage.setItem('extendedTrialModalShown', false)
  }

  close = () => {
    // if it comes from future app, redirect them back to url
    const futureAppRedirectKey = 'future_app_return_url'
    const futureItem = localStorage.getItem(futureAppRedirectKey)
    localStorage.removeItem(futureAppRedirectKey)

    if (futureItem) {
      const returnUrl = JSON.parse(futureItem)
      if (moment().isBefore(moment(returnUrl.expiry))) {
        window.location.href = returnUrl.value
      }
    }

    const { general } = this.props
    const previousLocation = general.upgradeOriginalLocation || '/'
    window.location.href = previousLocation
  }

  render() {
    const {
      currentBrand,
      customer,
      match: { params: { belt } },
    } = this.props
    if (customer.loading) return (<Spinner />)

    const isTrialConfirm = moment().isBefore(moment(currentBrand.trial_end))
      || moment().isBefore(moment(currentBrand.extended_trial_end))

    let nextBillDate
    if (!isTrialConfirm) {
      const subscription = customer.subscriptions.data[0]
      nextBillDate = moment(subscription.current_period_end * 1000)
    }

    return (
      <div>
        <FullscreenModalWide
          title="We Really Appreciate Your Support!"
          close={this.close}
        >
          <div className={Theme.mainContainer}>
            <div className={Theme.mainContainer__content}>
              <div className={Theme.mainContainer__content__image}>
                <img src="images/icons/paper-plane-circle-icon.svg" style={{ width: '205px', height: '132px' }} />
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>
                  {"Thanks for choosing DojoMojo's "}
                  <em>{beltMapping[belt].label}</em>
                  {" Plan. You'll receive a confirmation email shortly."}
                  {isTrialConfirm ? ' We will remind you 3 days before your trial ends to add your payment info to continue using DojoMojo.' : ''}
                </H4>
              </div>

              <div className={Theme.mainContainer__content__copy}>
                <H4 multiline>
                  {'Update your billing info or review your invoice any time in your '}
                  <Link to="/profile/brand/billing">Billing Settings</Link>
                  .
                </H4>
              </div>

              <div className={Theme.mainContainer__content__nextBillDate}>
                <H3>
                  <small>
                    {isTrialConfirm ? 'Trial End Date' : 'Next Bill Date'}
                  </small>
                </H3>
                <H3>
                  <small>
                    { moment(isTrialConfirm ? currentBrand.extended_trial_end || currentBrand.trial_end : nextBillDate).format('MMM D, YYYY')}
                  </small>
                </H3>
              </div>

              <div className={Theme.mainContainer__content__contactSupport}>
                <Small>
                  {'Please contact '}
                  <a href="mailto:support@dojomojo.com">support@dojomojo.com</a>
                  {' if you have any questions about your purchase.'}
                </Small>
              </div>
            </div>
          </div>
        </FullscreenModalWide>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(PaymentReceivedFullModal))
