import React from 'react'
import { withRouter } from 'react-router-dom'

import HostBrandInfo from './agreement-items/draft-host-brand'
import HostResponsibilities from './agreement-items/draft-host-responsibilities'
import PartnerResponsibilities from './agreement-items/draft-partner-responsibilities'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P } from 'visual-components/util/texts'

class Draft extends React.Component {
  componentWillMount() {
    const { partnershipAgreement, actions, params, history } = this.props

    if (partnershipAgreement.error) {
      history.replace(`/builder/${params.campaignId}/partnership-agreement/dashboard`)
    }

    if (!partnershipAgreement.agreement.id) {
      actions.loadPartnershipAgreement(params.partnershipId)
    }
  }

  render() {
    const { currentCampaign, partnershipAgreement, history, actions } = this.props

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    return (
      <div>
        <Grid>
          <Row style={{
            marginBottom: '40px'
          }}>
            <P multiline>
              { `Complete the below to generate a Partnership Agreement. Your information will save automatically, and you will be able to make edits to the agreement until you share it with your brand Partner. Once you share the agreement with them, they can accept the terms, at which point the Partnership Agreement will be legally binding and enforceable.` }
            </P>
          </Row>

          <HostBrandInfo host={ currentCampaign.hostBrand }
            partnershipAgreement={ partnershipAgreement }
            actions={ actions }
          />

          <HostResponsibilities host={ currentCampaign.hostBrand }
            partnershipAgreement={ partnershipAgreement }
            currentCampaign={ currentCampaign }
            actions={ actions }
          />

          <PartnerResponsibilities host={ currentCampaign.hostBrand }
            partnershipAgreement={ partnershipAgreement }
            currentCampaign={ currentCampaign }
            actions={ actions }
          />
        </Grid>
      </div>
    )
  }
}

export default withRouter(Draft)
