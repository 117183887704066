import React from 'react';
import AppBar from 'react-toolbox/lib/app_bar';
import appbarTheme from 'css/themes/homePage/appbarTheme.css';

class HeaderContainer extends React.Component {
  render() {
    let {
      children
    } = this.props;

    return(
      <AppBar theme={ appbarTheme } fixed={ false }>
        { children }
      </AppBar>
    )
  }
}

export default HeaderContainer;
