import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

import smsTheme from 'css/themes/sms-marketing.css'

import PropTypes from 'prop-types'
import MobileMarketingPostEntryHeader from './MobileMarketingPostEntryHeader'

import MobileMarketingPostEntryEdit from './MobileMarketingPostEntryEdit'
import MobileMarketingPostEntryActions from './MobileMarketingPostEntryActions'
import MobileMarketingPostEntryPreview from './MobileMarketingPostEntryPreview'
import { MobileMarketingContext } from './MobileMarketingContext'
import useSmsVerify from './hooks/useSmsVerify'
import SMSVerifyModal from './modals/SMSVerifyModal'

function MobileMarketing(props) {
  const {
    editMode,
    onRouterLeave,
    setPostEntryPublishedFn,
  } = React.useContext(MobileMarketingContext)

  const {
    setIsSmsTrafficPublished,
    isWelcomeMessage,
  } = props

  const { isSmsVerifyModalNeeded, openVerifyLink, dismissSmsVerifyModal } = useSmsVerify()

  useEffect(() => {
    setPostEntryPublishedFn({ setIsSmsTrafficPublished })
  }, [setIsSmsTrafficPublished])

  // All props come from usePostEntrySms.js, MobileMarketingContainer.js and coversion-action.js

  return (
    <div className={isWelcomeMessage ? '' : smsTheme.contentContainer}>
      <Prompt message={onRouterLeave} />
      {!isWelcomeMessage && (
        <MobileMarketingPostEntryHeader {...props} />
      )}
      {
        editMode || isWelcomeMessage
          ? <MobileMarketingPostEntryEdit {...props} />
          : <MobileMarketingPostEntryPreview {...props} />
        }
      <div
        style={{
          height: '1px',
          marginRight: '-20px',
          marginLeft: '-20px',
          marginTop: '20px',
          backgroundColor: '#dee4e5',
          width: '1153px',
        }}
      />
      <MobileMarketingPostEntryActions {...props} />
      {isSmsVerifyModalNeeded && (
        <SMSVerifyModal
          confirm={openVerifyLink}
          close={dismissSmsVerifyModal}
        />
      )}
    </div>
  )
}

MobileMarketing.propTypes = {
  setIsSmsTrafficPublished: PropTypes.func,
  isWelcomeMessage: PropTypes.bool,
}

MobileMarketing.defaultProps = {
  setIsSmsTrafficPublished: null,
  isWelcomeMessage: false,
}

export default React.memo(MobileMarketing)
