/*
Opens for Rules/Terms editing

*/

import React from 'react'
import { H4 } from 'visual-components/util/texts'
import DefaultDialog from 'visual-components/util/modals/default-dialog'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'

class TermsConfirmEditModal extends React.Component {
  render() {
    const {
      isOpen,
      revert,
      confirm,
    } = this.props

    return (
      <div>
        <DefaultDialog
          active={isOpen}
          closeModal={revert}
          title="Warning!"
          confirm={confirm}
          confirmText="Edit Anyways"
          cancel={revert}
          cancelText="Cancel"
          bannerImgSrc="/images/icons/celebration-icon.svg"
          children={
            (
              <div>
                <div className={`${dialogTheme.bannerWrapper} ${dialogTheme.orangeBanner}`}>
                  <img src="/images/icons/error-message-icon.svg" />
                </div>
                <H4 multiline>
                  {`If you edit the text within {{brackets}}, you'll be breaking DojoMojo's ability to auto-update these key fields in the future, and will need to manually update them yourself.`}
                </H4>
              </div>
            )
          }
        />
      </div>
    )
  }
}

export default TermsConfirmEditModal
