import OpportunitiesConstants from 'constants/opportunities-constants'

const {
  LOADING_OPPORTUNITY,
  CLEAR_OPPORTUNITY,
  NEW_OPPORTUNITY,
  LOAD_OPPORTUNITY_SUCCESS,
  LOAD_OPPORTUNITY_FAILURE,
  UPDATE_OPPORTUNITY,
  TOGGLE_OPPORTUNITY_AGE_GROUP,
  EDIT_OPPORTUNITY,
} = OpportunitiesConstants

const initialState = {
  rid: 'opportunity',
  headline: '',
  details: '',
  type: '',
  loading: false,
  error: null,
}

export default function opportunitiesReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_OPPORTUNITY:
      return { ...state, loading: true, error: null }

    case CLEAR_OPPORTUNITY:
      return { ...initialState }

    case NEW_OPPORTUNITY: {
      const { type } = action.payload || {}
      return { ...initialState, type }
    }

    case EDIT_OPPORTUNITY:
    case LOAD_OPPORTUNITY_SUCCESS: {
      return { ...initialState, ...action.payload, loading: false }
    }

    case LOAD_OPPORTUNITY_FAILURE: {
      return {
        ...state,
        error: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error',
        loading: false,
      }
    }

    case UPDATE_OPPORTUNITY: {
      return { ...state, ...action.payload }
    }

    case TOGGLE_OPPORTUNITY_AGE_GROUP: {
      return { ...state, [action.payload]: !state[action.payload] }
    }

    default:
      return state
  }
}
