import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { getClaim } from 'actions/brand-claim-actions'

import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import Spinner from 'util/components/spinner'
import SocialTheme from 'css/themes/SocialGrowth.css'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import { H4 } from 'visual-components/util/texts'
import AppDispatcher from 'dispatchers/app-dispatcher'
import BonusItemCard from './bonus-item-card'

const stateToProps = ({ currentBrand }) => ({ currentBrand })

@DragDropContext(HTML5Backend)

class BonusItems extends React.Component {
  state = {
    items: [],
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.bonusItems.presentationOrder,
    })
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { items } = this.state
    const dragCard = items[dragIndex]

    const updated = update(this.state, {
      items: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      },
    })

    const idOrder = updated.items.map(item => item.id)

    this.persist(idOrder)
    this.setState(updated)
  }

  persist = _.debounce(idOrder => {
    const { partnershipInvite: { id } } = this.props

    const data = {
      id,
      bonusOrder: {
        order: idOrder,
      },
    }

    AppDispatcher.http({
      url: `/partnership/${id}`,
      method: 'PUT',
      data,
      contentType: 'application/json',
      success: data => {
        store.dispatch({
          model: 'bonusItems',
          type: 'REORDER_HASH',
          data: {
            order: idOrder,
          },
        })
      },
    })
  })

  render() {
    const {
      bonusItems: bonuses,
      partnershipInvite: {
        id: inviteId,
        invitee,
        paid_social_actions,
      },
      isHost,
      loading,
      showUpsellModal,
      campaignStarted,
      currentBrand,
    } = this.props
    const { items } = this.state

    if (loading || !invitee) return <Spinner needsBackground />

    const filteredItems = items.filter(item => isHost || item.partnership_invite_id === inviteId)
    const bonusItems = filteredItems.map((bonus, idx) => (
      <BonusItemCard
        {...this.props}
        id={bonus.id}
        key={bonus.id}
        index={idx}
        bonus={bonus}
        bonusItems={bonuses}
        partnershipInviteId={inviteId}
        isHost={isHost}
        moveCard={this.moveCard}
      />
    ))

    return getClaim('sweepstakes.socialAction.purchase') && !filteredItems.length && !paid_social_actions ? (
      <div className={SocialTheme.emptyStateContainer}>
        <div className={SocialTheme.contentBox}>
          <div className={SocialTheme.textBox}>
            <H4 cloudGrey multiline>
              <em>
                Social Growth is a powerful feature to help you grow your social channels.
                {' '}
                <a
                  href="https://help.dojomojo.com/hc/en-us/articles/230426167-Add-Partner-Social-Actions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={SocialTheme.textLink}
                >
                  Learn more
                </a>
              </em>
            </H4>
          </div>
          {!campaignStarted && (
            <Button
              onClick={showUpsellModal}
              theme={ButtonTheme}
              className={ButtonTheme.blueButton}
              label="Buy Social Growth"
              primary
              raised
            />
          )}
        </div>
      </div>
    ) : (
      <div id="bonusItems">
        {bonusItems}
      </div>
    )
  }
}

BonusItems.propTypes = {
  currentBrand: PropTypes.object.isRequired,
}

export default connect(stateToProps)(BonusItems)
