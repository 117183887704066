import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import InputContainer from 'visual-components/util/InputContainer'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import Dialog from 'react-toolbox/lib/dialog'
import Dropdown from 'react-toolbox/lib/dropdown'

import DropdownTheme from 'css/themes/Dropdown.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

class PartnerWithBrand extends PureComponent {
  state = {
    value: null,
  }

  invite = () => {
    const { invite, onClose } = this.props
    const { value } = this.state

    invite(value)
    return onClose()
  }

  onChange = value => this.setState({ value })

  template = item => <p style={{ color: item.disabled ? '#a9b6c3' : 'auto' }}>{item.label}</p>

  signup = () => { window.location.href = '/app/register' }

  render() {
    const {
      brand,
      potentialCampaigns,
      active,
      onClose,
    } = this.props

    return (
      <Dialog
        theme={DialogTheme}
        active={active}
        onEscKeyDown={onClose}
        onOverlayClick={onClose}
        title={`Partner With ${brand.accountname}`}
      >
        <img
          onClick={onClose}
          className={DialogTheme.closeImage}
          src="/images/icons/close.svg"
          style={{ cursor: 'pointer' }}
        />
        <p
          style={{
            fontFamily: 'Larsseit',
            fontSize: '16px',
            color: '#2c3540',
            marginBottom: '10px',
          }}
        >
          Select the campaign you’d like to invite them to:
        </p>
        <InputContainer label="Upcoming Sweepstakes">
          <Dropdown
            theme={DropdownTheme}
            auto
            template={this.template}
            className={DropdownTheme.partner}
            onChange={this.onChange}
            source={potentialCampaigns || []}
            value={this.state.value}
          />
        </InputContainer>
        <div style={{ paddingTop: '24px' }}>
          <ModalButtonGroup
            canSave={this.state.value}
            confirm={this.invite}
            confirmText="Invite"
          />
        </div>
      </Dialog>
    )
  }
}

export default withRouter(PartnerWithBrand)
