import React from 'react';
import moment from 'moment-timezone';
import COLOURS from 'util/colours';

class CampaignStatus extends React.Component{
  render () {
    let endDate = this.props.campaign.endDate;

    let darkPStyles = {
      marginBottom: '0',
      fontFamily: 'sans-serif',
      fontSize: '12px',
      opacity: '1'
    }

    let lightPStyles = {
      marginBottom: '0',
      fontFamily: 'sans-serif',
      fontSize: '11px',
      opacity: '.4'
    }


    return (
      <div className="row text-center" style={{
        marginLeft: '-15px',
        marginRight: '-15px',
        marginBottom: '-15px',
        borderBottom: 'none',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: COLOURS.darkGreen.point1

      }}>
        <div className="col-xs-6" style={{
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: COLOURS.darkGreen.point1,
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: COLOURS.darkGreen.point1,
          paddingTop: '15px',
          paddingBottom: '15px'
        }}>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p style={darkPStyles}>
                { endDate ? moment.tz(endDate, 'America/New_York').format('l') : 'Not Started' }
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p style={lightPStyles}>
                END DATE
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-6"
          style={{
            paddingTop: '15px',
            paddingBottom: '15px'
        }}>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p style={darkPStyles}>
                { endDate ? moment(endDate).fromNow() : 'Not Started' }
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p style={lightPStyles}>
                TIME LEFT
              </p>
            </div>
          </div>
        </div>
      </div>
    )
    // return (
    //   <div>
    //     <p>1 Winner</p>
    //     <p>{Moment(endDate).format('l')}</p>
    //     <p>end date</p>
    //     <p>{Moment(endDate).fromNow()}</p>
    //     <p>Time Left</p>
    //   </div>
    // )
  }
};

export default CampaignStatus;
