import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import { Tiny } from 'visual-components/util/texts'

class ChartOption extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
  }

  static defaultProps = {
    selected: false,
  }

  render() {
    const {
      id,
      name,
      color,
      title,
      onClick,
      selected,
    } = this.props

    return (
      <div
        style={{
          width: '117px',
          marginBottom: '4px',
          marginTop: '4px',
          cursor: 'pointer',
          opacity: selected ? '1' : '0.5',
          height: '11px',
          display: 'inline-block',
          position: 'relative'
        }}
        onClick={e => onClick(id ? id : name)}
      >
        <div style={{ position: 'absolute', width: '12px', height: '12px', backgroundColor: color }} />
        <div style={{ position: 'absolute', left: '20px', top: '2px' }}>
          <Tiny>
            { title && title.length > 15 ? title.substring(0, 15) + '...' : title }
          </Tiny>
        </div>
      </div>
    )
  }
}

export default ChartOption
