import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  string, bool, func, number,
} from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { Small } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import LinkedButton from 'visual-components/util/buttons/LinkedButton'

import ProposalStatusMessageTheme from 'css/themes/media-market/ProposalStatusMessage.css'
import ButtonTheme from 'css/themes/Buttons.css'

const accepted = {
  headerImage: '/images/icons/high-five-circle.svg',
  title: 'Offer Accepted',
  subtitle: 'Congrats! The offer has been accepted.',
}

const declined = {
  headerImage: '/images/icons/warning-megaphone-circle.svg',
  title: 'Proposal Declined',
  subtitle: 'This proposal has been declined. You can keep the conversation going, but will need to create a new media sale to concretize the deal.',
  showSellMedia: true,
}

const expired = {
  headerImage: '/images/icons/warning-megaphone-circle.svg',
  title: 'Offer Expired',
  subtitle: 'This offer has expired. You can keep the conversation going but will need to set up a new sale to finalize a Media Market partnership.',
  showSellMedia: true,
}

class ProposalStatusMessage extends PureComponent {
  static propTypes = {
    dispatch: func.isRequired,
    status: string,
    canSell: bool.isRequired,
    buyingBrandId: number.isRequired,
  }

  static defaultProps = {
    status: '',
  }

  onSeeCollaborateSteps = () => {
    const { dispatch } = this.props

    return dispatch({
      type: 'UPDATE_ATTR',
      model: 'collaborateEducationalModal',
      data: {
        step: 0,
        show: true,
      },
    })
  }

  static content = {
    accepted,
    live: accepted,
    completed: accepted,
    unfulfilled: accepted,
    in_dispute: accepted,
    declined,
    expired,
  }

  render() {
    const { status, canSell, buyingBrandId } = this.props
    const content = ProposalStatusMessage.content[status]
    if (!content) return null

    return (
      <div>
        <Card className={ProposalStatusMessageTheme.main}>
          <img src={content.headerImage} className={ProposalStatusMessageTheme.headerImage} />
          <div className={ProposalStatusMessageTheme.title}>
            <Small>
              <i>
                {content.title}
              </i>
            </Small>
          </div>
          <div className={ProposalStatusMessageTheme.subtitle}>
            <Small multiline>
              {content.subtitle}
            </Small>
          </div>
          {
            content.showSellMedia && canSell
            && (
              <div className={ProposalStatusMessageTheme.buttons}>
                <LinkedButton
                  to={`/partnerships/deal/new/${buyingBrandId}`}
                  className={ButtonTheme.smallBlueButton}
                  label="Sell Media"
                  raised
                />
              </div>
            )
          }
          {
            status === 'accepted'
            || status === 'live'
            || status === 'completed'
            || status === 'unfulfilled'
              ? (
                <Button
                  theme={ButtonTheme}
                  className={`${ButtonTheme.whiteButtonAzureText} ${ButtonTheme.raised} ${ButtonTheme.softShadow}`}
                  style={{
                    margin: '2px 0',
                    fontSize: '12px',
                    lineHeight: '24px',
                    height: '24px',
                    padding: '0 16px',
                  }}
                  type="button"
                  onClick={this.onSeeCollaborateSteps}
                  label="See Next Steps"
                />
              ) : null
          }
        </Card>
      </div>
    )
  }
}

export default connect(null)(ProposalStatusMessage)
