import React from 'react';
import * as ReactRedux from 'react-redux';

import Spinner from 'util/components/spinner';
import Text from 'util/components/text';
import PrimaryButton from 'util/components/buttons/primary-button';
import AppDispatcher from 'dispatchers/app-dispatcher';

class CustomerSuccessAdminEmailOverlap extends React.Component {
  state = {
    overlap: 0,
    brand1: null,
    brand2: null,
    loading: false
  }

  onchange = (e) => {
    let obj = {}

    obj[e.target.name] = e.target.value
    this.setState(obj)
  }

  findOverlap = () => {
    let { brand1, brand2 } = this.state
    this.setState({
      loading: true
    })
    AppDispatcher.http({
      url: `/cs-email-overlap/${brand1}/${brand2}`,
      success: res => {
        this.setState({
          overlap: res,
          loading: false
        })
      }
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner />
      );
    }
    
    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Find Email Overlap
          </Text.dojoH2Bold>

          <form className="col-xs-12">
            <div className="row">
              <div className="col-xs-12" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Brand 1
                  </Text.dojoP>
                  <input type="number" name="brand1" className="col-xs-12" onChange={ this.onchange }/>
                </div>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Brand 2
                  </Text.dojoP>
                  <input type="number" name="brand2" className="col-xs-12" onChange={ this.onchange }/>
                </div>
                <PrimaryButton onClick={ this.findOverlap }
                  isDisabled={ !this.state.brand1 || !this.state.brand2 }
                  styles={[{
                    width: '220px',
                    height: '50px',
                    marginTop: '20px'
                }]}>
                  Find Overlap
                </PrimaryButton>
              </div>
            </div>
          </form>
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Overlap: { this.state.overlap }
              </Text.dojoH3Bold>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var mapState = function(state) {
  return state;
}

export default ReactRedux.connect(mapState)(CustomerSuccessAdminEmailOverlap);
