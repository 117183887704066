import React from 'react'
import { renderToString } from 'react-dom/server'
import { H2, H4, H3 } from 'visual-components/util/texts'
import DocContainer from './doc-container'

const LAST_UPDATE_DATE = 'December 6, 2019'

const DojoLink = ({ route, caps, label }) => {
  const text = `www.dojomojo.com${route ? `${route}` : ''}`

  return (
    <a href={`https://${text}`} target="_blank" rel="noopener noreferrer">
      {
        label || (caps
          ? text.toUpperCase()
          : text)
      }
    </a>
  )
}

const DojoMail = ({ caps, label }) => {
  const text = 'support@dojomojo.com'

  return (
    <a href={`mailto:${text}`}>
      { caps
        ? (label || text).toUpperCase()
        : (label || text)
      }
    </a>
  )
}

const Title = ({ text, element = 'H2', innerEl = 'span' }) => {
  const TitleEl = { H2, H3, H4 }[element]

  return (
    <>
      <TitleEl multiline>
        {React.createElement(innerEl, { dangerouslySetInnerHTML: { __html: text } })}
      </TitleEl>
      <br />
    </>
  )
}

const Paragraph = ({ text }) => (
  <>
    <H4 multiline><span dangerouslySetInnerHTML={{ __html: text }} /></H4>
    <br />
  </>
)

const renderList = (items, el = 'ul') => (
  <>
    {
      React.createElement(el, {
        children: items.map(item => (
          <li key={item}>
            <Paragraph text={item} />
          </li>
        )),
      })
    }
    <br />
  </>
)

const PostMail = () => (
  <H4 multiline>
    <i>
      Innovative Brands Corp.
      <br />
      270 Lafayette Street #1105
      <br />
      New York, NY 10012
    </i>
  </H4>
)

const PrivacyPolicy = () => (
  <DocContainer>
    <div className="container">
      <div className="row text-center">
        <br />
        <Title text="Innovation Brands Corp." innerEl="b" />
        <Title text="Privacy Policy" innerEl="b" />
      </div>
      <br />
      <br />
      <div className="row">
        <Paragraph text={`Effective Date: ${LAST_UPDATE_DATE}`} />
        <Paragraph
          text="This Privacy Policy describes the policies and procedures of Innovation Brands Corp. d/b/a
          DojoMojo, its parent companies, successors and assigns (“DojoMojo”, “we”, “our” or “us”)
          regarding the collection, use and disclosure of your information on www.dojomojo.com (the
          “Site”) and the services, features or content we offer (collectively with the Site, the “Services”).
          We receive information about you from various sources, including: (i) if you register for the Site
          and the Services, through a DojoMojo account; (ii) your use of the Services generally; and (iii)
          from Third-Party Services. When you use the Services, you are consenting to the collection,
          transfer, storage, disclosure and other uses of your information as described in this Privacy
          Policy."
        />
        <Paragraph
          text="DojoMojo Services include a sweepstakes platform for its business customers (“Customers”). A
          Customer can use DojoMojo to market to consumers who are on a Customer’s email marketing
          list (these consumers are called “Subscribers”) and to acquire new Subscribers by partnering
          with other Customers to run a sweepstakes that requires participants in the sweepstakes to opt in
          to the Customers’ marketing."
        />
        <Title text="What Does This Privacy Policy Cover?" innerEl="b" />
        <Paragraph
          text="This Privacy Policy covers the treatment of personally identifiable information (“Personal
            Information”) gathered when you are using or accessing the Services. This Privacy Policy also
            covers our treatment of any Personal Information that our business partners share with us or that
            we share with our business partners."
        />
        <Paragraph
          text="This Privacy Policy does not apply to the practices of third parties that we do not own or control,
          including but not limited to any third-party websites, services and applications (“Third-Party
          Services”) that you choose to access through the Services or to individuals that we do not
          manage or employ. While we attempt to facilitate access only to those Third-Party Services that
          share our respect for your privacy, we cannot take responsibility for the content or privacy
          policies of those Third-Party Services. We encourage you to carefully review the privacy policies
          of any Third-Party Services you access."
        />
        <Title text="What Information Do We Collect?" innerEl="b" />
        <Paragraph
          text="The information we gather enables us to personalize, improve and continue to operate the
          Services. In connection with certain aspects of the Services, we may request, collect and/or
          display some of your Personal Information."
        />
        <div>
          <Title text="DojoMojo Customers" element="H3" />
          <Title text="Account Information:" element="H4" innerEl="i" />
          <Paragraph
            text="When you create an account, you will provide information that could be Personal Information,
            such as your name, username, password, email address, phone number, gender, occupation,
            location, birthdate and other demographic information. You acknowledge that this information
            may be personal to you, and by creating an account on the Services and providing Personal
            Information to us, you allow others, including us, to identify you. If you use a third-party
            platform, like Google or LinkedIn, to create an account, we may collect Personal Information
            from that third-party platform. You can control the information the third-party platform shares
            with us in your account settings with that third-party platform."
          />


          <Paragraph
            text={`
            We may use your contact information to send you information about our Services, but only rarely
            when we feel such information is important. You may unsubscribe from certain messages
            through your ${renderToString(<DojoLink route="/app/profile/brand/users" label="account settings" />)}
            , but we reserve the right to contact you when we believe it is
            necessary, such as for account recovery purposes.
            `}
          />
          <Title text="User Content:" element="H4" innerEl="i" />
          <Paragraph
            text="Some features of the Services allow you to provide content to the Services, such as messages and
            written comments. All content submitted by you to the Services may be retained by us
            indefinitely, even after you terminate your account. We may continue to disclose such content to
            third parties in a manner that does not reveal Personal Information, as described in this Privacy
            Policy."
          />

        </div>
        <div>
          <Title text="All DojoMojo Users" element="H3" />

          <Paragraph
            text="If you interact with the Services, such as by viewing the Site or opening an email sent through
            DojoMojo, we will collect certain information from you."
          />
          <Title text="Information Collected Automatically:" element="H4" innerEl="i" />
          <Paragraph
            text="We automatically receive and record information from your web browser when you interact with
            the Services, including your IP address, time and date of such interaction, the browsers used, and
            the actions you’ve taken within the Services. This information is used for fighting spam/malware
            and also to track your interaction with the Services (e.g., what links you have clicked on)."
          />

          <Paragraph
            text="The Services also automatically collect usage information, such as the number and frequency of
            visitors to the Site, links you may click on through the Sites or emails we send, conversions of
            Subscribers in DojoMojo campaigns, demographics of Subscribers, and geographic location of
            visitors to the Site. We may use this data in aggregate form, that is, as a statistical measure, but
            not in a manner that would identify you."
          />
          <Title text="Cookies and Web Beacons:" element="H4" innerEl="i" />
          <Paragraph
            text="This Privacy Policy covers our use of cookies and web beacons only and does not cover the use
            of cookies by third parties. We do not control when or how third parties place cookies on your
            computer. For example, if you click a link that leads to a third-party website, that website may
            set cookies on your computer."
          />
          <Title text="Cookies:" element="H4" innerEl="u" />
          <Paragraph
            text="Cookies are pieces of text that may be provided to your computer through your web browser
            when you access a website. Your browser stores cookies in a manner associated with each
            website you visit. We use cookies to enable our servers to recognize your web browser and tell
            us how and when you visit the Site and otherwise use the Services through the Internet. We may
            associate this information with your DojoMojo account if you have one."
          />
          <Title text="Web Beacons:" element="H4" innerEl="u" />
          <Paragraph
            text="We, and third parties with whom we partner, may also deliver a file known as a web beacon,
            pixel or pixel tag (“web beacons”) to you through our Services. Web beacons are small, invisible
            graphic images that may be used on Service interfaces or in emails relating to the Services to
            collect certain information and monitor user activity on the interfaces, such as to count visits,
            understand usage effectiveness and to determine if an email has been opened and acted upon."
          />
          <Title text="Aggregate Information:" element="H4" />
          <Paragraph
            text={`
              We collect statistical information about how both unregistered and registered users, collectively,
              use the Services (“
              ${renderToString(<u>Aggregate Information</u>)}
              ”). Some of this information is derived from Personal
              Information, but was de-identified.
            `}
          />
          <Title text="Publicly Available Information:" element="H4" />
          <Paragraph
            text="We may collect information that is publicly available, such as in public records made available
            by state and local governments, and combine this information with Personal Information you
            provide through the Services."
          />
        </div>

        <Title text="How Do We Use Your Information?" innerEl="b" />
        <Paragraph text="We may use Personal Information to:" />
        {
          renderList([
            'Communicate about our Services, and respond to requests, inquiries, comments, and suggestions',
            'Analyze and enhance communications and strategies (including by identifying when emails sent to you have been received and read) for Dojo Mojo and our Customers',
            'Analyze results from marketing campaigns for our Customers',
            'Operate, evaluate and improve our Services (including to develop new products and services)',
            'Establish and maintain your Customer profile in our Services',
            'Tailor the content we display in our Services',
            'Administer surveys and other market research',
            'Invoice and collect payment for our Services',
            'Comply with legal requirements, judicial process and our company policies (including to verify your identity in connection with data access or update requests)',
            'Protect against, identify, investigate and respond to fraud, illegal activity (such as incidents of hacking or misuse of our websites and mobile applications), and claims and other liabilities, including by enforcing the Terms of Use that govern the Services',
          ])
        }
        <Title text="How, and With Whom, Is Your Information Shared?" innerEl="b" />
        <Paragraph
          text="The Services are designed to help you share information with others. As a result, some of the
          information generated through the Services is shared publicly or with third parties."
        />
        <Title text="DojoMojo Customers" element="H3" />
        <Title text="Other DojoMojo Customers:" element="H4" innerEl="i" />
        <Paragraph
          text="If you have a DojoMojo account, your profile information including your username and other
          information you enter may be displayed to other Customers to facilitate interaction within the
          Services. We will not directly reveal your email address to other Customers. When you partner
          with other DojoMojo Customers to collaborate on a sweepstakes campaign, you agree to share
          certain information, including your contact information and information about your Subscribers
          who opt in to receive marketing emails from the sweepstakes partners."
        />
        <Title text="All DojoMojo Users" element="H3" />
        <Title text="Other DojoMojo Customers:" element="H4" innerEl="i" />
        <Paragraph
          text="Some of your activity on and through the Services is public by default. This may include, but is
          not limited to, content you have posted publicly on the Site or otherwise through the Services. If
          you are a DojoMojo Customer, some of this information may be associated with your account."
        />
        <Paragraph
          text="Please also remember that if you choose to provide Personal Information using certain public
          features of the Services, then that information is governed by the privacy settings of those
          particular features and may be publicly available. Individuals reading such information may use
          or disclose it to other individuals or entities without our control and without your knowledge, and
          search engines may index that information. We therefore urge you to think carefully about
          including any information you consider private in content that you create or information that you
          submit through the Services."
        />
        <Title text="Affiliates:" element="H4" innerEl="i" />
        <Paragraph
          text="We may share your Personal Information with our corporate parent, subsidiaries, and affiliates,
          for purposes consistent with this Privacy Policy."
        />
        <Title text="Service Providers:" element="H4" innerEl="i" />
        <Paragraph
          text="We may share your Personal Information with third-party companies and individuals that provide
          services on our behalf or help us operate the Services (such as payment processing, hosting,
          analytics, email delivery, Customer service, marketing, and database management services).
          These third parties may use your Personal Information only as directed or authorized by us and
          in a manner consistent with this Privacy Policy, and are prohibited from using or disclosing your
          information for any other purpose."
        />
        <Title text="Aggregate Information:" element="H4" innerEl="i" />
        <Paragraph
          text="We share Aggregate Information with our partners, service providers and other persons with
          whom we conduct business. We share this type of statistical data so that our partners can
          understand how and how often people use our Services and their services or websites, which
          facilitates improving both their services and how our Services interface with them. In addition,
          these third parties may share with us non-private, aggregated or otherwise non Personal
          Information about you that they have independently developed or acquired."
        />
        <Title text="Business Transfers:" element="H4" innerEl="i" />
        <Paragraph
          text="We may sell, transfer or otherwise share some or all of our business or assets, including your
          Personal Information, in connection with a business deal (or potential business deal) such as a
          merger, consolidation, acquisition, reorganization, sale of assets, or in the event of bankruptcy, in
          which case we will make reasonable efforts to require the recipient to honor this Privacy Policy."
        />
        <Title text="Information Disclosed for Our Protection and the Protection of Others:" element="H4" innerEl="i" />
        <Paragraph
          text="We also reserve the right to access, read, preserve, and disclose any information as it reasonably
          believes is necessary to (i) satisfy any applicable law, regulation, legal process or governmental
          request, (ii) enforce this Privacy Policy and our Terms of Use, including investigation of
          potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical
          issues, (iv) respond to user support requests, or (v) protect our rights, property or safety, our
          users and the public. This includes exchanging information with other companies and
          organizations for fraud protection and spam/malware prevention."
        />
        <Title text="With Consent:" element="H4" innerEl="i" />
        <Paragraph
          text="Except as set forth above, you will be notified when your Personal Information may be shared
          with third parties, and will be able to prevent the sharing of this information."
        />
        <Title text="Is Your Information Secure?" innerEl="b" />
        <Paragraph
          text="We transmit and store your information, including your IP address information, using techniques
          designed to protect the security and confidentiality of your information. However, we cannot
          guarantee or warrant that such techniques will prevent unauthorized access to your information,
          Personal Information or otherwise."
        />
        <Title text="What Choices Do You Have Regarding Your Information?" />
        <Title text="DojoMojo Customers" element="H3" />
        <Title text="Account Settings:" element="H4" innerEl="i" />
        <Paragraph text="You can access and update information associated with your account by logging into the Services." />
        <Title text="Delete Your Account:" element="H4" innerEl="i" />
        <Paragraph
          text="Should you ever decide to delete your account, you may do so by contacting us. Please note that
          we will need to verify that you have the authority to delete the account. If you terminate your
          account, any association between your account and information we store will no longer be
          accessible through your account. However, given the nature of sharing on the Services, any
          public activity on your account prior to deletion will remain stored on our servers and will
          remain accessible to the public subject to the Terms of Use."
        />
        <Title text="All DojoMojo Users" element="H3" />
        <Title text="Choose not to Share Information:" element="H4" innerEl="i" />
        <Paragraph
          text="You can always choose not to disclose certain information to us. However, where we need
          information to provide the Services, you may not be able to take advantage of some of our
          features."
        />
        <Title text="Cookies:" element="H4" innerEl="i" />
        <Paragraph
          text="Most browsers have an option for disabling certain cookies. We strongly recommend that you
          leave cookies active because cookies allow you to take advantage of all of the features we offer
          through the Services."
        />
        <Paragraph
          text={`We currently do not participate in any “Do Not Track” frameworks that would allow us to
          respond to signals or other mechanisms from you regarding the collection of your information.
          To find out more about "Do Not Track," please visit <a href="https://www.allaboutdnt.com" target="_blank" rel="noopener noreferrer">www.allaboutdnt.com</a>.`}
        />
        <Title text="What if Children Access the Services?" innerEl="b" />
        <Paragraph
          text={`
          We do not knowingly collect or solicit Personal Information from children under 13. If we learn
          we have collected Personal Information from a child under 13, we will delete that information as
          quickly as possible. If you believe that a child under 13 may have provided us Personal
          Information, please ${renderToString(<DojoMail label="contact us" />)}
          `}
        />
        <Title text="What If You Use the Services Outside of the United States?" innerEl="b" />
        <Paragraph
          text="The Services are operated from the United States. Any information we obtain about you in
          connection with your use of the Service may be processed and stored in, and subject to the laws
          of, the United States or other countries. Privacy laws in the locations where we process and store
          your Personal Information may not be as protective as the privacy laws in your home country."
        />
        <Title text="What Happens When There Are Changes to this Privacy Policy?" innerEl="b" />
        <Paragraph
          text="We may amend this Privacy Policy from time to time. If we make material changes in the way
          we collect or use information, we will notify you by posting an announcement on the Services or
          sending you an email. If you use the Services after we post changes to our Privacy Policy, you
          are bound by those changes."
        />
        <Title text="What If You Have Questions or Concerns?" innerEl="b" />
        <Paragraph
          text="If you have any questions or concerns regarding DojoMojo’s privacy practices, please send us a
          detailed message:"
        />
        <H4 multiline>
          By email:
          {' '}
          <DojoMail />
        </H4>
        <br />
        <Paragraph text="By post:" />
        <PostMail />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  </DocContainer>
)

export default PrivacyPolicy
