export default function () {
  if (window.location.hostname === 'localhost') {
    return window.location.host
  } else if (window.location.hostname === 'dev.dojomojo.com') {
    return 'https://dev.dojomojo.com'
  } else if (window.location.hostname === 'staging.dojomojo.com') {
    return `https://staging.dojomojo.com`
  } else {
    return `https://www.dojomojo.com`
  }
}
