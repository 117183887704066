import React from 'react';
import TinyMCE from 'react-tinymce';
import ReactDOM from 'react-dom';
import _ from 'lodash'

class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.keyUp = _.debounce(this.keyUp.bind(this),500);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.setup = this.setup.bind(this);
    this.save = this.save.bind(this);
  }
  handleEditorChange(e) {
    this.props.updateAttr(this.props.attr, e.target.getContent());
  }
  setup(e) {
    e.contentCSS.push('//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css');
    e.contentCSS.push('/css/font/font.css');
    e.contentCSS.push('/css/font.css');
    e.contentCSS.push('/web/admin.css');
    if (this.props.orange) {
      e.contentCSS.push('/web/tinymceorange.css');
    }
  }
  shouldComponentUpdate() {
    return false
  }
  keyUp(e) {
    this.props.updateAttr(this.props.attr, e.target.innerHTML);
  }
  save() {
    this.props.save();
  }
  render() {
    let { toolbar } = this.props
    var fonts = [
      'Alegreya',
      'Alegreyasans',
      'Anonymous+Pro',
      'Arvo',
      'BioRhyme',
      'Bitter',
      'Cabin',
      'Cardo',
      'Chivo',
      'Cormorant+Garamond',
      'Crimson+Text',
      'Domine',
      'Eczar',
      'FiraSans',
      'Gentium+Basic',
      'GothamRnd-Bold',
      'GothamRnd-Book',
      'Inconsolata',
      'Karla',
      'Lato',
      'Libre+Baskerville',
      'Libre+Franklin',
      'Lora',
      'Merriweather',
      'Montserrat',
      'Neuton',
      'Old+Standard',
      'Open+Sans',
      'Oswald',
      'Poppins',
      'PT+Sans',
      'PT+Serif',
      'Roboto',
      'Rubik',
      'Rajdhani',
      'Space+Mono',
      'Work+Sans'
    ]

    var fontFormats = _.reduce(fonts, function (memo, font) {
      var fontStr = font.replace(/\+/g, ' ');
      return `${memo}${fontStr}='${fontStr}';`;
    },'');

    var plugins = 'autolink link charmap';
    if (!this.props.published) {
      plugins = plugins += ' save';
    }
    return (
      <div className="terms-editor">
        <TinyMCE
          content={this.props.originalContent}
          config={{
            fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 28pt 32pt 36pt 42pt",
            plugins: plugins,
            toolbar: toolbar != undefined ? toolbar : 'bold italic alignleft aligncenter alignright forecolor bullist  fontsizeselect charmap link save',
            menubar: false,
            statusbar: false,
            paste_as_text: this.props.allowPaste ? false : true,
            paste_retain_style_properties: this.props.allowPaste ? 'all' : '',
            setup: this.setup,
            contentCSS: ['/web/admin.css'],
            height: this.props.height,
            body_class: this.props.bodyClass || null,
            save_onsavecallback : this.save,
            body_class: 'terms-content'
         }}
          onChange={this.handleEditorChange}
          onKeyup={this.keyUp} />
      </div>
    );
  }
}

export default Editor;
