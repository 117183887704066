import React, { Component } from 'react'
import COLOURS from 'util/colours'
import PrimaryButton from 'util/components/buttons/primary-button'
import Checkbox from 'util/components/form-elements/checkbox'
import { H6, P } from 'visual-components/util/texts'

class BuildOption extends Component {
  render() {
    const {
      heading,
      headingHighlight,
      highlightedHeading,
      body,
      checked,
      button,
      onLeft,
      checkboxEditable,
      section = 'Terms'
    } = this.props
    const optionStyle = {
      padding: '40px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: checked ? COLOURS.cerulean : COLOURS.elephantGrey
    }

    return (
      <div className="col-sm-6"
        style={{
          paddingLeft: onLeft ? '0' : '25px',
          paddingRight: onLeft ? '25px' : '0'
      }}>
        <div className="row">
          <div className={ `${section}-Create-Option` } style={ optionStyle }>
            <div className={ `${section}-Create-Option-inner` } style={{
              marginBottom: '16px',
            }}>
              <H6 multiline>
                { heading }
                <span style={{color: COLOURS.azure}}>
                  { ` ${highlightedHeading ? highlightedHeading : ''}` }
                </span>
              </H6>
            </div>
            <div className={ `${section}-Create-Option-inner-paragraph` }>
              <P multiline>
                { body }
              </P>
            </div>
            <div style={{
              marginTop: '24px',
              marginBottom: '24px'
            }}>
              <Checkbox
                toggleChecked={ e => this.props.check(this.props.type, e.target.checked) }
                label="I agree and wish to proceed."
                value={ checked }
                editable={ checkboxEditable }
                labelStyles={{
                  lineHeight: '22px',
                  fontFamily: "'Larsseit'",
                  fontSize: '14px'
              }} />
            </div>
            <PrimaryButton onClick={ this.props.continue }
              isDisabled={ !checked }
              buttonStyles={[{
                minWidth: 'auto',
                paddingLeft: '16px',
                paddingRight: '16px',
                minHeight: '36px'
              }]}
              blueButton={ true }
            >
              { button }
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
}

export default BuildOption
