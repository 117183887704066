import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  parentModal: {
    padding: '32px',
  },
  parentHeader: {
    padding: '0px !important',
  },
  parentDesc: {
    margin: '0px !important',
    fontSize: '16px',
    fontWeight: '300',
    '& a': {
      cursor: 'pointer',
      fontFamily: 'Larsseit-Medium',
      fontWeight: '500',
      color: '#03a9f4',
    },
  },
  previewLinkButton: {
    width: '112px',
    height: '36px',
    marginTop: '24px',
    borderRadius: '2px',
    float: 'right',
    backgroundColor: '#03a9f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontFamily: 'Larsseit-Medium',
      fontSize: '14px',
      fontWeight: '500',
      color: 'white',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}

function PreviewLinkModal(props) {
  const { classes } = props
  return (
    <div className="hide postentry_modal__modal-overlay preview-link-modal" id="preview-link-modal">
      <div className="postentry_modal__modal" id="previewLinkModal">
        <div className={`postentry_modal__modal-guts ${classes.parentModal}`}>
          <p className={`header ${classes.parentHeader}`}>Warning! This Is a Preview Link</p>
          <div className="bannerWrapper coralHalftoneBanner">
            <img src="/images/icons/warning-microphone-blue-shirt.svg" />
          </div>
          <p className={`description ${classes.parentDesc}`}>
            Do not distribute this preview link in your promotions. Head to the
            {' '}
            <a id="links-and-files-ref">Links and Files </a>
            section of the sweepstakes builder to find your tracking links.
          </p>
          <div className={classes.previewLinkButton} id="preview-link-button">
            <span>I Understand</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(styles)(PreviewLinkModal)
