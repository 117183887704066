import React, { Component } from 'react'
import classNames from 'classnames'
import RelativeTime from 'visual-components/util/relative-time'
import {
  number, bool, object, string, array, func,
} from 'prop-types'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import { Tiny, Small } from 'visual-components/util/texts'
import Theme from 'css/themes/media-market/collaborate-comment-theme.css'
import CommentInput from './comment-input'
import DropdownMenu from './comment-dropdown-menu'

class Comment extends Component {
  static propTypes = {
    id: number.isRequired,
    annotationNumber: number,
    user: object,
    created_at: string.isRequired,
    content: string.isRequired,
    replies: array,
    selectedCommentId: object,
    is_edited: bool.isRequired,
    is_unread: bool.isRequired,
    deleteComment: func.isRequired,
    parentId: number,
    parentRef: func,
    firstUnreadComment: number,
    showParentReply: func,
    hideParentReply: func,
    saveReplyComment: func.isRequired,
    saveEditComment: func.isRequired,
    currentBrandId: number.isRequired,
    brand_id: number.isRequired,
    loading: bool,
    scrollToComment: object,
    setScrolledToComment: func,
  }

  static defaultProps = {
    replies: [],
    selectedCommentId: {},
    showParentReply: null,
    hideParentReply: null,
    parentId: null,
    parentRef: () => null,
    firstUnreadComment: 0,
    user: null,
    annotationNumber: null,
    loading: false,
    scrollToComment: null,
    setScrolledToComment: () => {},
  }

  state = {
    dropdownMenuActive: false,
    showReply: false,
    showEdit: false,
    replyValue: '',
    editValue: '',
    isDeleted: false,
  }

  componentDidMount() {
    const { is_unread: isUnread, firstUnreadComment, id } = this.props

    if (isUnread) this.flickerUnread()

    // If this is first unread comment, scroll it into view
    if (id === firstUnreadComment) setTimeout(() => this.scrollIntoView())
  }

  componentDidUpdate(prevProps) {
    const {
      id, scrollToComment, setScrolledToComment, is_unread: isUnread, updated_at,
    } = this.props
    // If selected comment is now this comment, then scroll to it
    if (
      scrollToComment
      && (!prevProps.scrollToComment || prevProps.scrollToComment.id !== scrollToComment.id)
      && scrollToComment.id === id
    ) {
      this.scrollIntoView()
      setScrolledToComment()
    }

    // If newly unread, scroll to it
    if ((isUnread && !prevProps.is_unread) || (updated_at !== prevProps.updated_at)) {
      this.scrollIntoView()
      this.flickerUnread()
    }
  }

  toggleDropdown = e => {
    e.stopPropagation()
    const { dropdownMenuActive } = this.state
    this.setState({ dropdownMenuActive: !dropdownMenuActive })
  }

  showEditing = e => {
    e.stopPropagation()
    const { content, disableMainCommentInput, setSelectedCommentId } = this.props
    this.setState(prevState => ({
      editValue: prevState.editValue || content,
      showEdit: true,
    }))
    setSelectedCommentId(null)
    disableMainCommentInput(true)
  }

  hideEdit = () => {
    const { disableMainCommentInput } = this.props
    this.setState({
      showEdit: false,
      dropdownMenuActive: false,
    })
    disableMainCommentInput(false)
  }

  showReply = e => {
    const { setSelectedCommentId } = this.props
    e.stopPropagation()
    const { parentId, showParentReply, disableMainCommentInput } = this.props
    if (parentId) {
      showParentReply(e)
    } else {
      this.setState({ showReply: true })
      setSelectedCommentId(null)
    }
    disableMainCommentInput(true)
  }

  saveReply = () => {
    const { saveReplyComment, id } = this.props
    const { replyValue } = this.state
    if (replyValue) {
      saveReplyComment(id, replyValue)
    }
    this.hideReply()
  }

  saveEdit = () => {
    const { saveEditComment, id } = this.props
    const { editValue } = this.state
    if (editValue) {
      saveEditComment(id, editValue)
    }
    this.hideEdit()
  }

  hideReply = () => {
    const { parentId, hideParentReply, disableMainCommentInput } = this.props
    if (parentId) {
      hideParentReply()
    } else {
      this.setState({
        showReply: false,
        replyValue: '',
      })
    }
    disableMainCommentInput(false)
  }

  onReplyChange = event => {
    this.setState({
      replyValue: event.target.value,
    })
  }

  onEditChange = event => {
    this.setState({
      editValue: event.target.value,
    })
  }

  setSelectedCommentId = () => {
    const { setSelectedCommentId, parentId, id } = this.props
    setSelectedCommentId(parentId || id, 'comment')
  }

  scrollIntoView = () => {
    const { parentRef } = this.props
    const { offsetTop } = this.divRef
    // If child comment, scroll parentRef
    const { parentElement } = parentRef() || this.divRef
    parentElement.scrollTo(0, offsetTop - 50)
  }

  deleteComment = () => {
    const { deleteComment, id } = this.props
    ConfirmationDispatcher.check({
      header: 'Delete comment?',
      copy: "Are you sure want to delete this comment?  This action can't be undone.",
      confirmText: 'Delete',
      action: () => {
        this.setState({
          isDeleted: true,
        })
        deleteComment(id)
        ConfirmationDispatcher.closeModal()
      },
    })
  }

  captureDiv = el => (this.divRef = el)

  flickerUnread = () => {
    if (this.divRef) {
      this.divRef.classList.add(Theme.unread)
      setTimeout(this.removeUnread, 1000)
    }
  }

  removeUnread = () => {
    if (this.divRef) this.divRef.classList.remove(Theme.unread)
  }

  renderComment = () => {
    const {
      annotationNumber,
      user,
      created_at: createdAt,
      content,
      replies,
      is_edited: isEdited,
      parentId,
      isLastComment,
      profile,
      user_id,
      brand_id,
      currentBrandId,
    } = this.props
    const {
      dropdownMenuActive,
      editValue,
      isDeleted,
    } = this.state

    const isCreator = user_id === profile.id && brand_id === currentBrandId
    const author = user && !isCreator ? `${user.firstName} ${user.lastName}` : `${profile.firstName} ${profile.lastName}`
    const isChild = !!parentId
    const hasReplies = !isChild && replies && replies.length > 0
    const canEdit = isCreator && !isChild && !hasReplies
    if (isDeleted) {
      return null
    }
    return (
      <React.Fragment>
        {isChild && <div className={Theme.verticalLine} />}
        <div className={Theme.main}>
          <DropdownMenu
            active={dropdownMenuActive}
            editComment={this.showEditing}
            deleteComment={this.deleteComment}
          />

          <div className={Theme.header}>
            {!isChild && annotationNumber && Comment.renderAnnotationBubble(annotationNumber) }
            <div className={Theme.author}>
              <Tiny>
                <em>{author.length > 25 ? `${author.slice(0, 22)}...` : author}</em>
              </Tiny>
            </div>
            { canEdit
            && (
              // The menu would not be visible on comments from opposite party.
              <div className={Theme.menu}>
                <img
                  src="images/icons/three-blue-dot-options.svg"
                  width="26px"
                  height="26px"
                  onClick={this.toggleDropdown}
                />
              </div>
            )}
          </div>

          <div className={Theme.comment}>
            <Small multiline>{editValue || content}</Small>
          </div>

          <div className={Theme.footer}>
            <div className={Theme.timeStamp}>
              <Tiny cloudGrey>
                <RelativeTime time={createdAt} />
                {isEdited && ' - Edited'}
              </Tiny>
            </div>
            { isLastComment
            && (
              <div
                className={`${Theme.replyButton} ${isChild && Theme.child}`}
                onClick={this.showReply}
              >
                <Small azure>
                  <em>Reply</em>
                </Small>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  static renderAnnotationBubble(annotationNumber) {
    return (
      <div className={Theme.headerAnnotationOval}>
        <div className={Theme.number}>
          { annotationNumber }
        </div>
      </div>
    )
  }

  render() {
    const {
      id,
      replies,
      parentId,
      is_unread: isUnread,
      annotationNumber,
      selectedCommentId: { value: selectedCommentIdValue },
      loading,
    } = this.props
    const {
      showReply,
      showEdit,
      replyValue,
      editValue,
    } = this.state

    const isChild = !!parentId
    const isSelected = selectedCommentIdValue && (selectedCommentIdValue === id)

    return (
      <div
        ref={this.captureDiv}
        className={classNames({
          [Theme.commentBlock]: true,
          [Theme.commentBlockChild]: isChild,
          [Theme.commentBlockParent]: !isChild,
          [Theme.selected]: isSelected,
        })}
        onClick={this.setSelectedCommentId}
      >
        {
          showEdit
            ? (
              <CommentInput
                submitLabel="Edit"
                isReply={isChild}
                hide={this.hideEdit}
                onChange={this.onEditChange}
                onSubmit={this.saveEdit}
                value={editValue}
                annotationNumber={!isChild && annotationNumber}
              />
            )
            : this.renderComment()
        }
        {/* child comments */}
        <div className={Theme.childComments}>
          { !isChild
            && replies.map((comment, idx) => (
              <Comment
                {...this.props}
                {...comment}
                key={comment.id}
                showUnread={!isUnread && comment.is_unread}
                parentId={id}
                parentRef={() => this.divRef}
                isLastComment={idx === (replies.length - 1)}
                showParentReply={this.showReply}
                hideParentReply={this.hideReply}
              />
            ))
          }
        </div>
        <MaterialSpinner when={loading && !isChild} />
        {/* Reply input */}
        { showReply
        && (
          <CommentInput
            submitLabel="Reply"
            isReply
            hide={this.hideReply}
            onChange={this.onReplyChange}
            onSubmit={this.saveReply}
            value={replyValue}
          />
        )}

      </div>
    )
  }
}
export default Comment
