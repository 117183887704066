import React, { PureComponent } from 'react'
import TextThemes from 'css/themes/Text.css'
import { checkClassNames } from 'util/checkClassNames'
import { node, bool, object, number } from 'prop-types'

export class H1 extends PureComponent {
  static propTypes = {
    children: node,
    seaGreen: bool,
    white: bool,
    neonMint: bool,
    azure: bool,
    extralarge: bool,
  }

  render() {
    const {
      seaGreen,
      white,
      neonMint,
      azure,
      extralarge,
      children,
    } = this.props

    return (
      <h1 className={checkClassNames({ seaGreen, white, neonMint, azure, extralarge })}>
        { children }
      </h1>
    )
  }
}

export class H2 extends PureComponent {
  static propTypes = {
    children: node,
    azure: bool,
    cloudGrey: bool,
    white: bool,
    multiline: bool,
    style: object,
    medium: bool,
  }

  render() {
    const {
      azure,
      cloudGrey,
      white,
      multiline,
      children,
      medium,
    } = this.props;

    return(
      <h2 className={ checkClassNames({medium, azure, cloudGrey, white, multiline}) }>
        { children }
      </h2>
    )
  }
}

export class H3 extends PureComponent {
  static propTypes = {
    children: node,
    azure: bool,
    white: bool,
    coral: bool,
    multiline: bool,
    overflowEllipsis: bool,
    alertRed: bool,
  }

  render() {
    const {
      azure,
      cloudGrey,
      white,
      coral,
      multiline,
      children,
      overflowEllipsis,
      alertRed,
      style = {},
    } = this.props

    return(
      <h3 className={ checkClassNames({azure, cloudGrey, white, coral, multiline, alertRed, overflowEllipsis}) } style={style}>
        { children }
      </h3>
    )
  }
}

export class H4 extends PureComponent {
  static propTypes = {
    children: node,
    coral: bool,
    azure: bool,
    alertRed: bool,
    white: bool,
    cloudGrey: bool,
    multiline: bool,
    overflowEllipsis: bool,
    neonMint: bool,
    style: object
  }

  render() {
    const {
      coral,
      multiline,
      children,
      azure,
      alertRed,
      cloudGrey,
      white,
      overflowEllipsis,
      neonMint,
      style = {},
    } = this.props

    return(
      <h4 className={ checkClassNames({coral, alertRed, azure, white, cloudGrey, multiline, overflowEllipsis, neonMint}) } style={style}>
        { children }
      </h4>
    )
  }
}

export class H5 extends PureComponent {
  static propTypes = {
    children: node,
    white: bool,
    azure: bool,
    multiline: bool,
    overflowEllipsis: bool
  }

  render() {
    const {
      white,
      azure,
      multiline,
      children,
      overflowEllipsis
    } = this.props

    return(
      <h5 className={ checkClassNames({white, azure, multiline, overflowEllipsis}) }>
        { children }
      </h5>
    )
  }
}

export class H6 extends PureComponent {
  static propTypes = {
    children: node,
    coral: bool,
    seaGreen: bool,
    white: bool,
    azure: bool,
    alertRed: bool,
    cloudGrey: bool,
    multiline: bool,
    inactive: bool,
  }

  render() {
    const {
      coral,
      seaGreen,
      white,
      children,
      azure,
      alertRed,
      cloudGrey,
      multiline,
      style,
      inactive
    } = this.props

    return(
      <h6 className={ checkClassNames({coral, seaGreen, white, coral, azure, alertRed, cloudGrey, multiline, inactive}) }
          style = { style } >
        { children }
      </h6>
    )
  }
}

export class P extends PureComponent {
  static propTypes = {
    children: node,
    white: bool,
    azure: bool,
    cloudGrey: bool,
    alertRed: bool,
    multiline: bool,
    style: object,
    right: bool,
    seaGreen: bool,
    overflowEllipsis: bool,
  }

  render() {
    const {
      white,
      azure,
      cloudGrey,
      alertRed,
      multiline,
      children,
      style,
      right,
      seaGreen,
      overflowEllipsis,
    } = this.props

    return(
      <p
        className={ checkClassNames({ seaGreen, white, azure, cloudGrey, alertRed, multiline, right, overflowEllipsis}) }
        style={ style }
      >
        { children }
      </p>
    )
  }
}

export class Small extends PureComponent {
  static propTypes = {
    children: node,
    alertRed: bool,
    azure: bool,
    white: bool,
    cloudGrey: bool,
    stormySkies: bool,
    multiline: bool,
    smaller: bool,
    tiny: bool,
    micro: bool,
    right: bool,
    overflowEllipsis: bool,
    style: object,
    inactive: bool,
  }

  render() {
    const {
      alertRed,
      azure,
      cloudGrey,
      white,
      multiline,
      stormySkies,
      children,
      smaller,
      tiny,
      micro,
      right,
      overflowEllipsis,
      style,
      inactive,
      buttonheight
    } = this.props

    return(
      <p className={`${checkClassNames({ right, overflowEllipsis, white})} ${TextThemes.small} ` } style={ style }>
        <small className={checkClassNames({ stormySkies, alertRed, azure, cloudGrey, white, multiline, smaller, tiny, micro, inactive, buttonheight}) }>
          { children }
        </small>
      </p>
    )
  }
}

export class Smaller extends PureComponent {
  static propTypes = {
    children: node,
  }

  render() {
    const {
      children
    } = this.props

    return(
      <p className={ `${checkClassNames({})} ${TextThemes.smaller}` }>
        <small className={ checkClassNames({ smaller: true }) }>
          { children }
        </small>
      </p>
    )
  }
}

export class Tiny extends PureComponent {
  static propTypes = {
    children: node,
    coral: bool,
    white: bool,
    multiline: bool,
    overflowEllipsis: bool,
    alertRed: bool,
    cloudGrey: bool,
    azure: bool,
    style: object,
  }

  render() {
    const {
      children,
      coral,
      white,
      multiline,
      overflowEllipsis,
      alertRed,
      cloudGrey,
      azure,
      style = {}
    } = this.props

    return(
      <p className={ `${checkClassNames({overflowEllipsis})} ${TextThemes.tiny}` } style={style}>
        <small className={ checkClassNames({ tiny: true, coral, white, multiline, alertRed, cloudGrey, azure }) }>
          { children }
        </small>
      </p>
    )
  }
}

export class Micro extends PureComponent {
  static propTypes = {
    children: node,
    alertRed: bool,
    azure: bool
  }

  render() {
    const {
      alertRed,
      azure,
      children
    } = this.props

    return(
      <p className={ `${checkClassNames({})} ${TextThemes.micro}` }>
        <small className={ checkClassNames({ alertRed, azure, micro: true }) }>
          { children }
        </small>
      </p>
    )
  }
}

export class LabelValue extends PureComponent {
  static propTypes = {
    label: node,
    value: node,
    marginRight: number
  }

  static defaultProps = {
    marginRight: 0
  }

  render() {
    const {
      label,
      value,
      marginRight
    } = this.props

    return(
      <div className={TextThemes.LabelValue} style={{marginRight: `${marginRight}px`, display: 'inline-block'}}>
        <H6>{label}</H6>
        <Small>{value}</Small>
      </div>
    )
  }
}
