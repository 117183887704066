import React, { PureComponent as Component } from 'react'
import Grid from 'visual-components/util/no-padding-grid/grid'
import { Card } from 'react-toolbox/lib/card'
import AnalyticsTheme from 'css/themes/analytics.css'
import CardTabs from 'visual-components/util/cards/card-tabs'
import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'

const defaultTabs = [
  {
    ref: 'dashboardEmailsAcquired',
    title: 'emails acquired',
  },
  {
    ref: 'dashboardSmsSubscribersTotals',
    title: 'sms signups',
  },
  {
    ref: 'dashboardSocialActions',
    title: 'social actions',
  },
  {
    ref: 'dashboardCampaignsLaunched',
    title: 'campaigns launched',
  },
  {
    ref: 'dashboardNewPartnerships',
    title: 'new partnerships',
  },
]
class OverviewCard extends Component {
  render() {
    const {
      children,
      aggregates,
      changeFilter,
      oldestDate,
      datePickerAggregateTop,
      tabs = defaultTabs,
    } = this.props

    return (
      <Card className={AnalyticsTheme.overviewCard}>
        <Grid>
          <CardTabs
            {...this.props}
            tabs={tabs}
            data={aggregates}
          />
          <div className={AnalyticsTheme.overviewCardContainer}>
            { children }
          </div>
          {
            changeFilter
              ? (
                <CardDateRangePickerRow
                  oldestDate={oldestDate}
                  changeFilter={changeFilter}
                  {...datePickerAggregateTop}
                />
              ) : null
          }
        </Grid>
      </Card>
    )
  }
}

export default OverviewCard
