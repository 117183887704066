import React from 'react'
import { object, bool } from 'prop-types'
import { Checkbox } from 'react-toolbox'
import IntegrationTheme from 'css/themes/integrations.css'
import CheckboxTheme from 'css/themes/Checkbox.css'

class SelectTableRow extends React.Component {
  static propTypes = {
    input: object.isRequired,
    option: object.isRequired,
    selected: bool,
    utilityFunctions: object.isRequired,
  }

  static defaultProps = {
    selected: false,
  }

  onChange = attr => {
    const {
      input,
      utilityFunctions: { updateIntegration },
    } = this.props

    updateIntegration({ [input.attr]: attr })
  }

  render() {
    const {
      option,
      selected,
    } = this.props

    return (
      <tr className={selected ? IntegrationTheme.selectedRow : ''} onClick={() => this.onChange(option.value)}>
        <td className={IntegrationTheme.firstTd}>
          { option.label }
        </td>
        <td className={IntegrationTheme.checkboxTd}>
          <Checkbox
            label={null}
            checked={selected}
            onChange={() => this.onChange(option.value)}
            theme={CheckboxTheme}
          />
        </td>
      </tr>
    )
  }
}

export default SelectTableRow
