import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Dialog from 'react-toolbox/lib/dialog'
import { Input as InputRT } from 'react-toolbox/lib/input'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import dialogTheme from 'css/themes/dialogs/modalBase.css'
import inputTheme from 'css/themes/Input.css'

import formatPhoneNumber from 'util/formatPhoneNumber'
import { MobileMarketingContext } from '../MobileMarketingContext'

class SendTestSmsModal extends PureComponent {
  static propTypes = {
    confirm: PropTypes.func.isRequired,
  }

  state = { phoneNumber: '' }

  onChange = phoneNumber => this.setState({ phoneNumber: formatPhoneNumber(phoneNumber) })

  onSendTest = () => {
    const { phoneNumber } = this.state
    const { confirm: sendTest } = this.props

    return sendTest(phoneNumber)
  }

  cancel = () => {
    const { setTestData } = this.context

    setTestData(null)
  }

  render() {
    const { testData } = this.context
    const { phoneNumber } = this.state

    return (
      <div>
        <Dialog
          theme={dialogTheme}
          active={Boolean(testData)}
          onEscKeyDown={this.cancel}
          onOverlayClick={this.cancel}
          title="Send a Test Message"
        >
          <InputRT
            placeholder="(555) 555-5555"
            value={phoneNumber}
            onChange={this.onChange}
            theme={inputTheme}
          />
          <ModalButtonGroup
            canSave
            confirm={this.onSendTest}
            confirmText="Send Test"
            cancel={this.cancel}
            cancelText="Cancel"
          />
        </Dialog>
      </div>
    )
  }
}

SendTestSmsModal.contextType = MobileMarketingContext

export default React.memo(SendTestSmsModal)
