import React from 'react'
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import EditOverlay from './EditOverlay'

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    background: '#fff',
    opacity: 0.8,
    marginTop: 10,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  image: {
    height: 12,
  },
}

class PoweredByDojo extends React.Component {
  static propTypes = {
    campaign: propTypes.object.isRequired,
    updateAttr: propTypes.func.isRequired,
    classes: propTypes.object.isRequired,
  }

  remove = () => {
    const {
      updateAttr,
    } = this.props
    updateAttr('showPoweredByDojo', false)
  }

  render() {
    const {
      campaign: {
        showPoweredByDojo,
        name,
      },
      updateAttr,
      classes: css,
    } = this.props

    const cleanCampaignName = name
      .trim()
      .replace(/["~!@#$%^&*\(\)_+=`{}\[\]\|\\:;'<>,.\/?"\- \t\r\n]+/g, '-')

    if (!showPoweredByDojo) return null

    return (
      <div
        id="powered-by-dojo"
        className={css.container}
      >
        { updateAttr && <EditOverlay onClick={this.remove} label="CLICK TO REMOVE" /> }
        <a
          href={`//www.dojomojo.com/app/pricing?utm_source=dojomojo&utm_medium=poweredByDojo&utm_campaign=${cleanCampaignName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={css.image}
            src="/images/lander-builder-icons/poweredByDojo.svg"
            alt="Powered By DojoMojo"
          />
        </a>
      </div>
    )
  }
}

export default injectSheet(styles)(PoweredByDojo)
