import React, { PureComponent as Component } from 'react'
import { object, string, bool } from 'prop-types'

import { H6, Small } from 'visual-components/util/texts'

import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'

class Label extends Component {
  static propTypes = {
    label: string.isRequired,
    sublabel: string,
    tooltip: string,
    style: object,
    sublabelStyle: object,
    disabledLabel: bool,
  }

  static defaultProps = {
    sublabel: '',
    tooltip: null,
    style: {},
    sublabelStyle: {},
    disabledLabel: false,
  }

  render() {
    const {
      label,
      sublabel,
      tooltip,
      style,
      sublabelStyle,
      disabledLabel,
    } = this.props

    return (
      <div style={{ marginBottom: '8px', display: 'inline-block', ...style }}>
        <H6 cloudGrey={disabledLabel}>
          { label }
          {
            sublabel
              ? (
                <div style={{ display: 'inline-block', marginLeft: '6px', ...sublabelStyle }}>
                  <Small>
                    { sublabel }
                  </Small>
                </div>
              )
              : null
          }
          {
            tooltip
              ? (
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '6px' }}>
                  <BlueInfoTooltip text={tooltip} />
                </div>
              )
              : null
          }
        </H6>
      </div>
    )
  }
}

export default Label
