/* eslint-disable react/sort-comp */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object, func } from 'prop-types'
import { debounce } from 'lodash'
import moment from 'moment'
import Spinner from 'util/components/spinner'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import * as IntegrationsActions from 'actions/integrations-actions'
import IntegrationsMap from './integrations-map'
import IntegrationModal from './integration-modal'
import FullscreenIntegrationModal from './fullscreen-integration-modal'
import RestrictedIntegrationDialog from './restricted-integration-dialog'

const mapStateToProps = ({
  currentBrand,
  currentCampaign,
  espIntegrations,
}) => ({
  currentBrand,
  currentCampaign,
  espIntegrations,
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  integrationsActions: bindActionCreators(IntegrationsActions, dispatch),
})

class IntegrationSetup extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    currentCampaign: object.isRequired,
    espIntegrations: object.isRequired,
    integrationsActions: object.isRequired,
    dispatch: func.isRequired,
    history: object.isRequired,
    match: object.isRequired,
    onSuccessfulIntegration: func,
  }

  static defaultProps = {
    onSuccessfulIntegration: () => {},
  }

  componentWillMount() {
    const {
      currentCampaign,
      espIntegrations: { model, loading },
      integrationsActions: { clearIntegration, loadByType },
      match: { params: { section } },
    } = this.props

    clearIntegration()

    if (section && !loading) {
      loadByType(model, section, currentCampaign && currentCampaign.id)
    }
  }

  componentDidUpdate() {
    const {
      currentCampaign,
      espIntegrations: { model, espIntegration, loading },
      integrationsActions: { clearIntegration, loadByType },
      match: { params: { section } },
    } = this.props

    const lightboxEspKey = 'lightbox_esp_setup'
    // check if user came from lightbox esp setup
    const lightboxEsp = localStorage.getItem(lightboxEspKey)
    if (lightboxEsp && espIntegration && espIntegration.details) {
      const item = JSON.parse(lightboxEsp)
      // remove the lightbox esp setup cache
      localStorage.removeItem(lightboxEspKey)
      if (moment().isBefore(moment(item.expiry))) {
        // if the lightbox esp setup is not expired, redirect back to lightbox esp
        window.location.href = `/app/signup-form/${item.value}/settings/integrations?configEsp=true`
      }
    }

    if (section && !loading && espIntegration.key !== section) {
      clearIntegration()
      loadByType(model, section, currentCampaign && currentCampaign.id)
    }
  }

  updateIntegration = data => {
    const {
      espIntegrations: { model, espIntegration },
      integrationsActions: { updateAttrPersist, brandToCampaigns },
      onSuccessfulIntegration,
    } = this.props

    if (data.espIntegration) data = data.espIntegration

    if (data.activated) {
      onSuccessfulIntegration()
    }

    updateAttrPersist(model, espIntegration.id, data)

    if (model === 'brand' && !data.lists) {
      setTimeout(() => brandToCampaigns(espIntegration, data), 500)
    }
  }

  updateMergeTags = (key, attr, value) => {
    const {
      espIntegrations: { model, espIntegration },
      integrationsActions: { updateMergeTags },
    } = this.props

    const data = {
      model,
      id: espIntegration.id,
      type: espIntegration.integration_type,
      key,
      attr,
      value,
    }

    updateMergeTags(data)
    this.persistMergeTags(data)
  }

  persistMergeTags = debounce(data => {
    const {
      integrationsActions: { persistMergeTags },
    } = this.props

    persistMergeTags(data)
  }, 1000)

  defaultMergeTags = restore => {
    const {
      espIntegrations: { model, espIntegration },
      integrationsActions: { defaultMergeTags },
    } = this.props

    defaultMergeTags({
      model,
      id: espIntegration.id,
      type: espIntegration.integration_type,
      restore,
    })
  }

  integrate = inviteId => {
    const {
      currentBrand,
      currentCampaign,
      espIntegrations: { model, espIntegration },
    } = this.props

    const integration = IntegrationsMap.find(i => i.key === espIntegration.key)

    window.location.href = model === 'brand'
      ? `/${integration.oauth.route}/${currentBrand.id}`
      : `/${integration.oauth.route}/${currentBrand.id}/${currentCampaign.id}?inviteId=${inviteId}`
  }

  activate = () => {
    this.test(true)
  }

  deactivate = () => {
    this.updateIntegration({ activated: false })
  }

  disconnect = () => {
    const {
      espIntegrations: { model, espIntegration },
      integrationsActions: { disconnect },
      dispatch,
    } = this.props

    ConfirmationDispatcher.check({
      header: 'Disconnect Integration',
      copy: 'Are you sure want to disconnect this Integration?',
      confirmText: 'Disconnect',
      action: () => {
        disconnect(model, espIntegration.id)
        this.closeModal()
        dispatch({
          type: 'UPDATE_ATTR',
          model: 'confirmationModal',
          data: {
            expand: false,
          },
        })
      },
    })
  }

  test = async isActivate => {
    const {
      espIntegrations: { model, espIntegration },
      integrationsActions: { test },
    } = this.props

    const success = await test(model, espIntegration.id, isActivate)

    if (isActivate && success) {
      this.updateIntegration({ activated: true })
    }
  }

  goTo = view => {
    const {
      currentCampaign,
      espIntegrations: { model, espIntegration },
      integrationsActions: { clearTestResults },
      history,
    } = this.props

    clearTestResults()
    history.push(model === 'brand' ? `/profile/brand/esp/${espIntegration.key}/${view}`
      : `/partnerships/campaign/${currentCampaign.id}/email-delivery/${espIntegration.key}/${view}`)
  }

  closeModal = () => {
    const {
      currentCampaign,
      espIntegrations: { model },
      integrationsActions: { clearIntegration },
      history,
    } = this.props

    clearIntegration()
    history.push(model === 'brand' ? '/profile/brand/esp'
      : `/partnerships/campaign/${currentCampaign.id}/email-delivery`)
  }

  fetchLists = async type => {
    const {
      espIntegrations: { model, espIntegration },
      integrationsActions: { fetchLists },
    } = this.props

    fetchLists(model, espIntegration, type)
  }

  render() {
    const {
      currentBrand,
      currentCampaign,
      espIntegrations,
      match: { params: { subsection } },
    } = this.props

    const { espWhitelist } = currentBrand
    const { model, espIntegration } = espIntegrations

    const isBrand = model === 'brand'
    const integration = IntegrationsMap.find(i => i.key === espIntegration.key)

    const utilityFunctions = {
      updateIntegration: this.updateIntegration,
      updateMergeTags: this.updateMergeTags,
      defaultMergeTags: this.defaultMergeTags,
      fetchLists: this.fetchLists,
      fetchExtraDetails: this.fetchExtraDetails,
      integrate: this.integrate,
      activate: this.activate,
      deactivate: this.deactivate,
      disconnect: this.disconnect,
      test: this.test,
      goTo: this.goTo,
      closeModal: this.closeModal,
    }

    if (!integration) return null

    if (!espIntegration.id || espIntegration.key !== integration.key) {
      return (
        <Spinner />
      )
    }

    if (integration.restrictions && (!espWhitelist || !espWhitelist.includes(integration.key))) {
      return (
        <RestrictedIntegrationDialog
          active={integration.key}
          closeDialog={this.closeModal}
        />
      )
    }

    const isConnected = espIntegration.details && integration.apiFields
      && integration.apiFields.every(field => !!espIntegration.details[field])
    const ModalComponent = (subsection || isConnected)
      ? FullscreenIntegrationModal
      : IntegrationModal

    return (
      <ModalComponent
        isBrand={isBrand}
        subsection={subsection}
        currentBrand={currentBrand}
        currentCampaign={currentCampaign}
        integration={integration}
        espIntegration={espIntegration}
        espIntegrations={espIntegrations}
        utilityFunctions={utilityFunctions}
      />
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IntegrationSetup))
