import React from 'react';
import _ from 'lodash'

import { Card } from 'react-toolbox/lib/card';
import { H3 } from 'visual-components/util/texts';
import ColumnGrouped from 'visual-components/util/graphs/column-grouped';
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option';

class StatsByPartnerCard extends React.Component {
  toggleItem = type => {
    const { hideMap } = this.props.data
    hideMap[type] = !hideMap[type];
    store.dispatch({
      model: 'analyticsCampaign',
      type: 'UPDATE_ATTR',
      data: {
        campaignStatsByPartner: _.extend({}, this.props.data, {
          hideMap: hideMap,
        }),
      },
    })
  }

  render() {
    const {
      data,
      canViewSms,
      started,
    } = this.props

    let {
      data: {
        items,
        hideMap,
      },
    } = this.props

    if (!canViewSms) {
      items = items.filter(item => item.name !== 'smsSubscribers')
    }

    hideMap = hideMap || {}
    let options = items ? items.map((item, idx) => {
      return (
        <ChartOption
          { ...item }
          onClick={ this.toggleItem }
          selected={ !hideMap[item.name] }
          key={ idx }
        />
      )
    }) : [];

    return(
      <Card style={{
        width: '976px',
        height: '232px',
        overflow: 'visible'
      }}>
        <div style={{
          padding: '20px 24px'
        }}>
          <div style={{
            marginBottom: '24px'
          }}>
            <H3 coral>
              <small>
                Stats by Partner
              </small>
            </H3>
          </div>
          <ColumnGrouped
            data={data}
            showAll
            started={started}
            graphClass="stats-by-partner"
          />
          { options }
        </div>
      </Card>
    )
  }
}

export default StatsByPartnerCard;
