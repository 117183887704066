import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { Input } from 'react-toolbox/lib/input'
import inputTheme from 'css/themes/Input.css'

import { P } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import validator from 'validator'
import ClickAwayListener from 'util/components/click-away-listener'

import colors from 'util/colours'
import { SMSEditorContext } from '../SMSEditorContext'

const styles = {
  wrapper: {
    border: `1px solid ${colors.silver}`,
    width: 552,
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'white',
    top: 'calc(100% + 2px)',
    left: 0,
    padding: 16,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    cursor: 'default',
    '& > p:nth-child(2)': {
      marginTop: 8,
    },
  },
  input: {
    marginTop: 4,
    display: 'flex',
    '& > div:first-child': {
      // Input component
      width: 'calc(100% - 140px)',
      marginRight: 10,
      paddingBottom: 0, // Override legacy input padding
      '& ::placeholder': {
        marginTop: 2,
      },
    },
    '& > button': {
      flex: 1,
      marginTop: 2,
    },
  },
}

const VALIDATOR_OPTIONS = {
  require_host: true,
  require_protocol: true,
  require_valid_protocol: true,
}

function LinkInsertModal(props) {
  const { show, onClose, classes: css } = props
  const [link, setLink] = useState('')
  const [disable, setDisabled] = useState(true)
  const {
    actions: { onLinkMinifyAndInsert },
  } = React.useContext(SMSEditorContext)

  if (!show) return null

  const onLinkShortened = () => {
    onClose()
    setLink('')
    setDisabled(false)
  }

  const onInsert = () => {
    setDisabled(true)
    onLinkMinifyAndInsert(link, onLinkShortened, true)
  }

  const onType = value => {
    setLink(value)
    setDisabled(!validator.isURL(value, VALIDATOR_OPTIONS))
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={css.wrapper}>
        <P multiline>Minimize the number of characters and help us to track your conversions.</P>
        <P><em>Original Link</em></P>
        <div className={css.input}>
          <Input
            value={link}
            onChange={onType}
            placeholder="Paste Your Link"
            theme={inputTheme}
          />
          <Button
            disabled={disable}
            onClick={onInsert}
            label="Shorten & Track"
            theme={ButtonTheme}
            className={ButtonTheme[disable ? 'disabledButton' : 'blueButton']}
            primary
            raised
          />
        </div>
      </div>
    </ClickAwayListener>
  )
}

LinkInsertModal.propTypes = {
  show: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(injectCSS(styles)(LinkInsertModal))
