import React, { Component } from 'react'
import { string, object } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'

import * as BrandAccountActions from 'actions/brand-account-actions'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import EditPen from 'visual-components/util/EditPen'

import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

import {
  H2, H3, H6, P,
} from 'visual-components/util/texts'

import PayoutSettingsModal from './payoutSettings/payout-settings-modal'

import {
  CLOSED,
  BRAND_ACCOUNT_SETTINGS,
  BANK_ACCOUNT_SETTINGS,
  VERIFY_BANK_ACCOUNT,
  INCORRECT_VERIFICATION_AMOUNTS,
} from './payoutSettings/steps'

const mapStateToProps = ({ currentBrand, brandAccount }) => ({ currentBrand, brandAccount })

const mapDispatchToProps = dispatch => ({
  brandAccountActions: bindActionCreators(BrandAccountActions, dispatch),
})

function TextOrPlaceholder({ text, placeholder }) {
  return (
    <P cloudGrey={!text} overflowEllipsis>
      {text || <i>{placeholder}</i>}
    </P>
  )
}

TextOrPlaceholder.propTypes = {
  text: string,
  placeholder: string,
}

TextOrPlaceholder.defaultProps = {
  text: '',
  placeholder: '',
}

class PayoutSettings extends Component {
  static propTypes = {
    currentBrand: object.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
  }

  state = {
    modalStep: CLOSED,
    isMouseOver: false,
  }

  componentDidMount() {
    const {
      brandAccountActions: { load },
    } = this.props
    load()
  }

  componentDidUpdate(prevProps) {
    const {
      brandAccount: { error },
    } = this.props
    if (prevProps.brandAccount.error !== error && error && error.includes('Incorrect')) {
      this.toggleModal(INCORRECT_VERIFICATION_AMOUNTS)
    }
  }

  handleMouseOver = () => this.setState({ isMouseOver: true })

  handleMouseLeave = () => this.setState({ isMouseOver: false })

  toggleModal = modalStep => {
    const {
      brandAccountActions: { clearError },
    } = this.props
    clearError()
    this.setState({ modalStep })
  }

  edit = () => {
    const {
      brandAccount: { verified },
    } = this.props

    // Can't edit brand account settings once verified so skip to bank account settings
    const editStep = !verified ? BRAND_ACCOUNT_SETTINGS : BANK_ACCOUNT_SETTINGS
    this.toggleModal(editStep)
  }

  buttonLaunch = () => {
    const {
      brandAccount: {
        legal_entity: { business_tax_id_provided } = {},
        external_account,
      },
    } = this.props

    // If haven't filled out brand account settings or input bank account,
    // go to brand account settings
    // Otherwise assume brand needs to validate bank account
    const launchStep = !business_tax_id_provided || !external_account ? BRAND_ACCOUNT_SETTINGS : VERIFY_BANK_ACCOUNT
    this.toggleModal(launchStep)
  }

  renderButton() {
    const {
      brandAccount: {
        legal_entity: { business_tax_id_provided } = {},
        external_account,
        external_account: { verified: bankAccountVerified } = {},
      },
    } = this.props

    if (business_tax_id_provided && bankAccountVerified) return null

    const label = !business_tax_id_provided || !external_account ? 'Enter Account Info' : 'Verify Account'

    return (
      <Row style={{ marginTop: '12px' }}>
        <Button
          style={{ marginLeft: '-8px' }}
          theme={buttonTheme}
          label={label}
          flat
          primary
          onClick={this.buttonLaunch}
        />
      </Row>
    )
  }

  renderCard() {
    const {
      brandAccount: {
        full_name,
        business_name,
        legal_entity: { business_tax_id_provided } = {},
        external_account: { bank_name, account_number, verified: bankAccountVerified } = {},
      },
    } = this.props

    const { isMouseOver } = this.state

    return (
      <div
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <Card style={{ width: '360px', padding: '16px 20px' }}>
          {isMouseOver && <EditPen onClick={this.edit} />}
          <Row style={{ marginBottom: '16px' }}>
            <H3 coral>
              <small>Linked Account</small>
            </H3>
            {business_tax_id_provided && (
              <img
                src={`/images/icons/${bankAccountVerified ? 'active' : 'disabled'}.svg`}
                style={{ marginLeft: '8px', marginTop: '0', height: '16px' }}
              />
            )}
          </Row>
          <Row style={{ marginBottom: '8px' }}>
            <Col style={{ width: '144px' }}>
              <H6>Name</H6>
            </Col>
            <Col>
              <H6>Business Name</H6>
            </Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col style={{ width: '144px' }}>
              <TextOrPlaceholder text={full_name} placeholder="Mojo Tiger" />
            </Col>
            <Col>
              <TextOrPlaceholder text={business_name} placeholder="DojoMojo" />
            </Col>
          </Row>
          <Row style={{ marginBottom: '8px' }}>
            <Col style={{ width: '144px' }}>
              <H6>Tax ID</H6>
            </Col>
            <Col>
              <H6>Account</H6>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '144px' }}>
              <TextOrPlaceholder
                text={business_tax_id_provided ? 'Provided' : null}
                placeholder="**-***8888"
              />
            </Col>
            <Col style={{ width: '78px' }}>
              <TextOrPlaceholder text={bank_name} placeholder="Bank Name" />
            </Col>
            <Col>
              <TextOrPlaceholder text={account_number} placeholder="****-2222" />
            </Col>
          </Row>
          {this.renderButton()}
        </Card>
      </div>
    )
  }

  render() {
    const {
      brandAccount,
      brandAccountActions,
      currentBrand: { loading: brandLoading, featuresLoading, features },
    } = this.props

    if (!brandLoading && !featuresLoading && !features.mediaMarketSeller) {
      return <Redirect to="/profile/brand/info" />
    }

    const { loading } = brandAccount

    const { modalStep } = this.state

    return (
      <div style={{ paddingTop: '32px' }}>
        <Helmet>
          <title>Payout Preferences</title>
        </Helmet>
        <Grid>
          <Row style={{ marginBottom: '12px' }}>
            <H2 azure>Payout Preferences</H2>
          </Row>
          <Row style={{ marginBottom: '16px' }}>
            <P multiline>Add your bank account to receive payments via DojoMojo.</P>
          </Row>
          <Row>
            <Col>{loading ? <MaterialSpinner when={loading} /> : this.renderCard()}</Col>
          </Row>
        </Grid>
        <PayoutSettingsModal
          modalStep={modalStep}
          toggleModal={this.toggleModal}
          brandAccount={brandAccount}
          brandAccountActions={brandAccountActions}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutSettings)
