import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H2, H3, P } from 'visual-components/util/texts'
import { Switch } from 'react-toolbox/lib/switch'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import COLOURS from 'util/colours'
import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'

import SwitchTheme from 'css/themes/Switch.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const mapStateToProps = (state) => {
  return {
    brand: state.currentBrand
  }
}

class BrandEmailFilters extends Component {
  state = {
    warningModalOpen: false
  }

  componentDidMount() {
    const { location } = this.props
    const page_title = this.page_title
    gtagPageTrack({ page_title, location })
  }

  toggleWarningModal = () => {
    this.setState({
      warningModalOpen: !this.state.warningModalOpen
    })
  }

  toggleFiltering = () => {
    let { id, ignoreWebbula } = this.props.brand

    CurrentBrandDispatcher.toggleIgnoreWebbula(id, !ignoreWebbula)
  }

  disableFiltering = () => {
    this.toggleFiltering()

    this.setState({
      warningModalOpen: false
    })
  }

  page_title = 'Email Filtering Preferences'

  render() {
    let { brand } = this.props

    return (
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>{this.page_title}</title>
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '12px'
          }}>
            <Col xs>
              <H2 azure>
                {this.page_title}
              </H2>
            </Col>
          </Row>
          <Row>
            <Col xs={ 9 }>
              <P style={{
                marginBottom: '30px',
                lineHeight: '22px',
                fontSize: '14px'
              }}>
                To maximize deliverability and value, we partnered with Webbula,
                which filters out risky emails flagged for high spam complaint
                rates, fraud-risk emails and invalid email addresses. <a href="https://help.dojomojo.com/hc/en-us/articles/360003447892" target="_blank">Learn more.</a>
              </P>
              <P style={{
                lineHeight: '22px',
                fontSize: '14px',
                marginBottom: '40px'
              }}>
                Set your default email verification settings below. Note these
                will only apply for future campaigns. You can also change the
                filter in the <a href="/app/partnerships/current" target="_blank">Campaign Dashboard</a> for a specific campaign if it has
                not launched yet.
              </P>
            </Col>
          </Row>
          <Row>
            <Col xs style={{display: 'flex'}}>
              <Switch
                checked={ !brand.ignoreWebbula }
                onChange={ brand.ignoreWebbula ? this.toggleFiltering : this.toggleWarningModal }
                theme={ SwitchTheme }
              />
              <span className ={ SwitchTheme.labelTextBrand}><em>Filters {brand.ignoreWebbula ? 'Off' : 'On'}:  </em> {brand.ignoreWebbula ? 'Switch off Webbula for no filters and no added security. ' : 'Recommended - remove highest level of security threats.'}</span>
            </Col>
          </Row>
        </Grid>

        <Dialog
          active={ this.state.warningModalOpen }
          onOverlayClick={ this.toggleWarningModal }
          onEscKeyDown={ this.toggleWarningModal }
          theme={ DialogTheme }
          title='Turn Off Filters?'
        >
          <div style={{
            background: COLOURS.coral,
            marginLeft: '-32px',
            marginRight: '-32px',
            paddingTop: '42px',
            paddingBottom: '42px',
            textAlign: 'center'
          }}>
            <img src="images/error-message-icon@3x.png" style={{
              width: '50%'
            }}/>
          </div>
          <div>
            <P style={{
              fontSize: '16px',
              marginTop: '24px',
              marginBottom: '24px'
            }}>
              Are you sure you want to turn off Webbula filters? Turning off
              filters may increase the risk of impacting your list’s
              deliverability, and the likelihood of IP damage and high-risk
              fraud emails.
            </P>
          </div>

          <ModalButtonGroup
            canSave={ true }
            cancel={ this.toggleWarningModal }
            cancelText={ 'Cancel' }
            confirm={ this.disableFiltering }
            confirmText={ 'Turn Off Filtering' }
            hideLine={ true }
          />
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps)(BrandEmailFilters)
