import React from 'react'
import Radium from 'radium'
import COLOURS from 'util/colours'
import UploadImage from 'components/util/editor-uploader'
import TertiaryButton from 'util/components/buttons/tertiary-button'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'

const styles = {
  container: {
    width: '100%',
    maxWidth: '200px',
    paddingTop: '15px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  prizingText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Open-Sans',
    marginBottom: 20,
  },
  image: {
    maxHeight: 50,
    maxWidth: 200,
    width: '100%',
    objectFit: 'contain',
  },
  removeButtonContainer: {
    marginTop: 20,
  },
}

class Prizing extends React.Component {
  static propTypes = {
    updateAttr: propTypes.func.isRequired,
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
  }

  updatePrizingSuccess = src => {
    const {
      updateAttr,
    } = this.props

    updateAttr('prizingLogo', src)
  }

  removePrizing = () => {
    const {
      updateAttr,
    } = this.props

    updateAttr('prizingLogo', '')
  }

  renderNoLogo = () => {
    const {
      classes: css,
      campaign,
      campaign: {
        prizingLogo,
      },
      updateAttr,
    } = this.props

    // Don't show upload button in the live version
    if (!updateAttr) return null

    return (
      <div className={css.uploadContainer}>
        <UploadImage
          action={`/campaign-prizing-logo/${campaign.id}`}
          src={prizingLogo}
          success={this.updatePrizingSuccess}
          name="upload-logo-image"
          label="Upload Prizing Logo"
          acceptedFiles="image/jpeg,image/png"
          blueButton
        />
      </div>
    )
  }

  renderLogo = () => {
    const {
      classes: css,
      campaign: {
        prizingLogo,
      },
      updateAttr,
    } = this.props

    return (
      <React.Fragment>
        <div className={css.prizingText}>
          PRIZING BY
        </div>
        <img
          id="prizing-logo"
          className={css.image}
          src={prizingLogo}
        />
        {
          updateAttr
          && (
            <div className={css.removeButtonContainer}>
              <TertiaryButton onClick={this.removePrizing}>
                Remove Prizing Logo
              </TertiaryButton>
            </div>
          )
        }
      </React.Fragment>
    )
  }

  render() {
    const {
      campaign,
      classes: css,
    } = this.props

    const { prizingLogo } = campaign

    return (
      <div
        id="prizing-logo"
        className={css.container}
      >
        {
          prizingLogo
            ? this.renderLogo()
            : this.renderNoLogo()
        }
      </div>
    )
  }
}

export default injectSheet(styles)(Radium(Prizing))
