import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { Tiny, H5 } from 'visual-components/util/texts';
import { makeNumbersPretty } from 'util/makeNumbersPretty';

class GeoDistributionBarTooltip extends React.Component {
  render() {
    var {
      total
    } = this.props;

    return (
      <Card style={{
        padding: '12px',
        width: '64px',
        height: '50px'
      }}>
        <div style={{
          marginBottom: '2px'
        }}>
          <H5>
            { makeNumbersPretty(total || 0) }
          </H5>
        </div>
        <Tiny>
          emails
        </Tiny>
      </Card>
    )
  }
};

export default GeoDistributionBarTooltip;
