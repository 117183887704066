import React from 'react'
import { bool } from 'prop-types'
import COLOURS from 'util/colours'
import { Link } from 'react-router-dom'
import appbarTheme from 'css/themes/homePage/appbarTheme.css'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import FeatureDropdown from './feature-dropdown'
import AboutUsDropdown from './about-us-dropdown'

class CenterSide extends React.Component {
  static propTypes = {
    lightBackground: bool,
  }

  render() {
    const { lightBackground } = this.props

    return (
      <div
        className={appbarTheme.centerWrapper}
        onClick={() => {
          EventTrackingDispatcher.track('home_navBar_click')
        }}
      >
        <FeatureDropdown lightBackground={lightBackground} />
        <a
          href="/app/pricing"
          style={{
            color: lightBackground ? COLOURS.ink : COLOURS.white,
          }}
        >
          Pricing
        </a>
        <AboutUsDropdown lightBackground={lightBackground} />
        <Link
          to="/resources/all"
          style={{
            color: lightBackground ? COLOURS.ink : COLOURS.white,
          }}
        >
          Resources
        </Link>
        <a
          href="http://blog.dojomojo.com"
          target="_blank"
          style={{
            color: lightBackground ? COLOURS.ink : COLOURS.white,
          }}
        >
          Blog
        </a>
      </div>
    )
  }
}

export default CenterSide
