import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Card } from 'react-toolbox/lib/card';
import { H3, Small } from 'visual-components/util/texts';
import ListCampaigns from '../../visual-components/calendar/list-campaigns';
import CampaignsListTheme from 'css/themes/calendar/campaignsList.css';
import * as CampaignTaskActions from 'actions/campaign-task-actions/'


const mapStateToProps = (state) => {
  return {
    campaignTasks: state.campaignTasks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
}


class BrandCampaignsList extends Component {
  render() {
    const {
      campaignTasks: {
        liveUpcomingBrandCampaigns
      },
      campaignTaskActions: {
        toggleShowCampaign
      }
    } = this.props

    return(
      <Card className={ CampaignsListTheme.mainContainer }>
        <div className={ CampaignsListTheme.headerTitle }>
          <H3 coral>
            <small>
              Campaigns
            </small>
          </H3>
        </div>
        <div className={ CampaignsListTheme.listContainer }>
        {
          liveUpcomingBrandCampaigns.length > 0 ?
          <ListCampaigns 
            toggleShowCampaign={toggleShowCampaign} 
            campaigns={ liveUpcomingBrandCampaigns } 
          /> :
          <div className={ CampaignsListTheme.emptyState} >
            <Small multiline cloudGrey>You don't have any active campaigns yet. Click "Create Partnership" to get started.</Small>
          </div>

        }
        </div>
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandCampaignsList)
