import React from 'react';
import { H4 } from 'visual-components/util/texts';

class BackButton extends React.Component{
  back = () => {
    history.back();
  }

  render() {
    return(
      <div onClick={ this.back }
        style={{
          display: 'inline',
          cursor: 'pointer'
      }}>
        <img src='/images/icons/blue-back-arrow.svg'
          style={{
            marginTop: '-4px',
            height: '16px'
        }}/>
        <div style={{
          display: 'inline-block',
          paddingLeft: '5px'
        }}>
          <H4 azure>
            <b>
              Back
            </b>
          </H4>
        </div>
      </div>
    )
  }
}

export default BackButton;
