import { debounce } from 'lodash'

const model = 'currentCampaign'

const success = customFields =>
  store.dispatch({
    model,
    type: 'LOAD_CUSTOM_FIELDS',
    payload: { customFields },
  })

const error = err => {
  store.dispatch({
    model,
    type: 'UPDATE_ATTR',
    data: { customFieldPersisting: false },
  })
  console.error(err)
}

const CustomFieldDispatcher = {
  addCustomField() {
    store.dispatch({
      model,
      type: 'ADD_CUSTOM_FIELD',
    })
  },

  updateCustomField(index, data) {
    store.dispatch({
      model,
      type: 'UPDATE_CUSTOM_FIELD',
      payload: { index, data },
    })

    this.persist(index)
  },

  addOptionToCustomField(index) {
    store.dispatch({
      model,
      type: 'ADD_OPTION_TO_CUSTOM_FIELD',
      payload: { index },
    })

    this.persist(index)
  },

  deleteOptionFromCustomField(index, subIndex) {
    store.dispatch({
      model,
      type: 'DELETE_OPTION_FROM_CUSTOM_FIELD',
      payload: { index, subIndex },
    })

    this.persist(index)
  },

  updateOptionValue(index, subIndex, data) {
    store.dispatch({
      model,
      type: 'UPDATE_OPTION_TO_CUSTOM_FIELD',
      payload: { index, subIndex, data },
    })

    this.persist(index)
  },

  persist: debounce(index => {
    const {
      currentCampaign: { id: campaignId, customFields, customFieldPersisting },
    } = store.getState()

    const customField = customFields[index]

    // Don't persist if no label or request already in flight
    if (!customField || !customField.label || customFieldPersisting) {
      return
    }

    store.dispatch({ model, type: 'CUSTOM_FIELD_PERSISTING' })

    $.ajax({
      method: 'PUT',
      url: `/custom-field/${campaignId}`,
      data: JSON.stringify(customField),
      contentType: 'application/json',
      success,
      error,
    })
  }, 300),

  delete(index) {
    const {
      currentCampaign: { id: campaignId, customFields },
    } = store.getState()

    const customField = customFields[index]
    if (!customField) {
      return
    }

    store.dispatch({
      model,
      type: 'DELETE_CUSTOM_FIELD',
      payload: { index },
    })

    const { id } = customField
    if (!id) {
      return
    }

    $.ajax({
      method: 'DELETE',
      url: `/custom-field/${campaignId}/${id}`,
      error,
    })
  },
}

export default CustomFieldDispatcher
