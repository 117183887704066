
var model = 'acquiresHistory';
import _ from 'lodash'



var defaultState =  {
  rid: model,
  timeFrames: [],
  loading: false,
}

var AcquiresHistoryReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    LOAD: function () {

      let copy = LANGUAGE.brandDashboard;

      var history = action.data.acquiresHistory;

      var averageLast30 = parseInt( parseInt(history.last_30) / parseInt(history.last30Campaigns) );
      var averageLast90 = parseInt( parseInt(history.last_90) / parseInt(history.last90Campaigns) );
      var averageAllTime = parseInt( parseInt(history.all_time) / parseInt(history.allTimeCampaigns) );

      if (isNaN(averageLast30)) {
        averageLast30 = 0;
      }
      if (isNaN(averageLast90)) {
        averageLast90 = 0;
      }
      if (isNaN(averageAllTime)) {
        averageAllTime = 0;
      }
      var timeFrames = [
        {
          name: copy.historicalCampaignAnalytics.tableFields.totalCampaign,
          data: {
            '30days': history.last30Campaigns,
            '90days': history.last90Campaigns,
            'allTime': history.allTimeCampaigns
          }
        },
        {
          name: copy.historicalCampaignAnalytics.tableFields.newEmailsAquired,
          data: {
            '30days': d3.format(',')(history.last_30),
            '90days': d3.format(',')(history.last_90),
            'allTime': d3.format(',')(history.all_time)
          }
        },
        {
          name: copy.historicalCampaignAnalytics.tableFields.newEmailsAquiredPerCampaign,
          data: {
            '30days': d3.format(',')(averageLast30),
            '90days': d3.format(',')(averageLast90),
            'allTime': d3.format(',')(averageAllTime)
          }
        }
      ];
      
      return _.extend({}, state, { timeFrames: timeFrames, totalAcquiredAllTime: history.all_time, loading: false });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default AcquiresHistoryReducer;