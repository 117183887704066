export default {
  orange: '#FF8013',
  orangeHover: '#F8B200',
  white: '#FFFFFF',
  black: 'black',

  lightGreen: {
    full: '#D4ECDB',
    point8: 'rgba(172,213,197,.8)',
    point6: 'rgba(172,213,197,.6)',
    point5: 'rgba(172,213,197,.5)',
    point4: 'rgba(172,213,197,.4)',
    point4hex: '#EEF8F1',
  },

  medGreen: {
    full: '#03766B',
    point8: 'rgba(3,118,107,.8)',
    point6: 'rgba(3,118,107,.6)',
    point4: 'rgba(3,118,107,.4)',
    point2: 'rgba(3,118,107,.2)',
    point4hex: '#C6DDDA',
  },

  darkGreen: {
    full: '#071815',
    point9: 'rgba(7,24,21,.9)',
    point8: 'rgba(7,24,21,.8)',
    point7: 'rgba(7,24,21,.7)',
    point6: 'rgba(7,24,21,.6)',
    point4: 'rgba(7,24,21,.4)',
    point2: 'rgba(7,24,21,.2)',
    point1: 'rgba(7,24,21,.1)',
    point05: 'rgba(7,24,21,.05)',
  },

  // other colours from colors.less
  red: '#EA5E5B',
  successGreen: 'green',

  // social colours
  // social colours
  snapchatYellow: '#fffc00',
  fbBlue: '#3b5998',
  twitterBlue: '#1da1f2',
  instagram: '#262626',
  youtubeRed: '#ff0000',
  pinterestRed: '#bd081c',

  // site specific (consider consolidating)
  campaignGrey: '#E7E7E7',
  footerGreen: '#243E3A',
  lightGrey: '#DFE2E1',
  messagingGrey: '#F6F7F8',
  orangePoint5: 'rgba(255,128,19,.05)',

  /* new text colours */
  textGrey: '#2c3541', /* changing this from #384645 to ink */
  textGreen: '#02ACA7',
  textGreenHover: '#0E7F7A',

  /* input border colour */
  inputBorder: '#A4AAA9',
  inputFocusBorder: '#159D96',
  inputText: '#6F7977',
  inputGreenText: '#439E96',

  senseiOrange: '#F8B207',
  wyswygGreenHover: 'rgba(14, 127, 122,0.9)',
  wyswygOrangeHover: 'rgba(241,126,48,.9)',
  wyswygOrange: '#FF981A',
  wyswygInputBorder: 'black',

  warningRed: '#ff3c2a',

  /* colors for graphs */
  yellow: '#F0CF25',
  blue: '#3C29B6',
  green: '#03766B',
  purple: '#561F56',

  /* --- colours from meghan --- */

  /* DO NOT CHANGE WITHOUT TALKING TO AARON!!! */

  eggshell: '#f7f9f9',
  slate: '#5c6b77',
  fog: '#dfe1e2',
  jungleGreen: '#159d96',
  elephantGrey: '#a4aaa9',
  aaronBlue: '#337ab7',
  tigerOrange: '#f17e30',
  aliceBlue: '#e5f3fd',
  charcoal: '#2b3f48',
  pastelRed: '#ec5d57',

  lightlyDarkerGrey: '#d4d5d6',

  /* new styleguide colours */
  cloudGrey: '#b7c3ce',
  azure: '#03a9f4',
  azureHover: 'rgba(3, 169, 244, .15)',
  dropdownText: '#212731',
  silver: '#dee4e5',
  seaGreen: '#14cba8',
  ink: '#2c3541',
  cornFlower: '#d9eaf6',
  cerulean: '#0176c8',
  alertRed: '#FF3B2A',
  iceBlue: '#e0f2f9',
  coral: '#ff7043',
  coralLight: '#fff0eb',
  crystal: '#f4f9fc',
  cobalt: '#1b3088',
  stone: '#475464',
  stormySkies: '#5c7f98',
  skyBase: '#DFE9ED',
  cream: '#FBFBFB',
  neonMint: '#64ffda',
  wyswygBlueHover: 'rgba(3, 169, 244, .8)',
  foggy: '#ecf1f3',
  aquamarine: '#9ad8fb',
  fair: '#ffe6de',
  lightPeach: '#ffc6b4',
  easterGreen: '#bef6e9',
  darkGrey: '#b7c3cd',
  turmeric: '#F9B200',
  lightYellow: '#FFEAA9',
  blues: {
    one: '#1b3088',
    two: '#0176c8',
    three: '#03a9f4',
    four: '#9ad8fb',
    five: '#cdecfd',
  },
}
