import React, { Component } from 'react'
import { oneOf, object } from 'prop-types'
import Theme from 'css/themes/media-market/util/slider-container-theme.css'

class SliderContainer extends Component {
  static propTypes = {
    side: oneOf(['left', 'right']).isRequired,
    children: object.isRequired,
  }

  state = {
    visible: true,
  }

  toggleDrawer = () => {
    const { visible } = this.state
    this.setState({ visible: !visible })
  }

  renderToggleBar(side, visible) {
    return (
      <div
        className={Theme[`${side}SlideToggle`]}
        onClick={this.toggleDrawer}
      >
        <div className={`${Theme.expandArrow} ${!visible && Theme.closed}`}>
          <img src="images/icons/expand-arrow.svg" />
        </div>
      </div>
    )
  }

  render() {
    const {
      visible,
    } = this.state

    const {
      side,
      children,
    } = this.props

    return (
      <div className={`${Theme[`${side}Container`]} ${!visible && Theme.closed}`}>
        <div className={`${Theme.sliderContainer} ${!visible && Theme.closed}`}>
          {side === 'right' && this.renderToggleBar(side, visible)}
          <div className={Theme.component}>
            { children }
          </div>
          {side === 'left' && this.renderToggleBar(side, visible)}
        </div>
      </div>
    )
  }
}

export default SliderContainer
