import React from 'react';
import PropTypes from 'prop-types';

import CampaignStats from 'visual-components/campaignProfile/campaign-stats';
import CampaignPrizing from 'visual-components/campaignProfile/campaign-prizing';

class CampaignDetailsTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      campaign,
    } = this.props;

    let campaignStats = (
      <CampaignStats
        estimatedEntries={ campaign.estimatedEntries }
        dedicatedListSize={ campaign.dedicatedListSize }
        socialMediaImpressions={ campaign.socialMediaImpressions }
        minimumDelivery={ campaign.minimumDelivery }
        minimumDedicatedListSize={ campaign.minimumDedicatedListSize }
      />
    );

    let campaignPrizing = (
      <CampaignPrizing
        description={ campaign.prizingDescription }
        value={ campaign.prizingValue }
      />
    );

    return(
      <div>
        <div style={{
          marginBottom: '16px'
        }}>
          { campaignStats }
        </div>
        <div>
          { campaignPrizing }
        </div>
      </div>
    )
  }
}

CampaignDetailsTab.propTypes = {
  campaign: PropTypes.object.isRequired
};

export default CampaignDetailsTab;
