import React from 'react'
import PropTypes from 'prop-types'

import { SMSEditorContext } from '../SMSEditorContext'
import SMSEditorGenericModal from './SMSEditorGenericModal'

function CouponDetectorModal(props) {
  const {
    code, show, onClose, placement,
  } = props
  const {
    coupon,
    actions: { onCouponTrack, onDismissCouponTrack },
  } = React.useContext(SMSEditorContext)

  const onConfirm = e => {
    e.stopPropagation()
    onCouponTrack(code, onClose)
  }

  const onDismiss = e => {
    if (e) e.stopPropagation()
    onDismissCouponTrack(code, onClose)
  }

  return (
    <SMSEditorGenericModal
      show={show}
      placement={placement}
      title={coupon ? 'It looks like you’re trying to add a second code' : 'Track Your Coupon Code'}
      content={
        coupon
          ? 'Messages may only contain one trackable coupon code at a time.'
          : 'Is this your coupon code?'
      }
      confirmLabel={coupon ? 'Replace Current Code' : 'Yes, Track It'}
      cancelLabel="Decline"
      onConfirm={onConfirm}
      onCancel={onDismiss}
    />
  )
}

CouponDetectorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  placement: PropTypes.object,
}

CouponDetectorModal.defaultProps = {
  placement: null,
}

export default React.memo(CouponDetectorModal)
