import LegalDetailsConstants from 'constants/legal-details-constants';
import PartnershipAgreementConstants from 'constants/partnership-agreement-constants';

const initialState = {
  partnershipId: null,
  active: false,
}

const {
  OPEN_LEGAL_DETAILS_MODAL,
  CLOSE_LEGAL_DETAILS_MODAL,
  SAVE_LEGAL_DETAILS
} = LegalDetailsConstants;

export default function legalDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_LEGAL_DETAILS_MODAL:
      return { ...state, active: true, partnershipId: action.payload.partnershipId };
    case CLOSE_LEGAL_DETAILS_MODAL:
    case PartnershipAgreementConstants.SAVE_LEGAL_DETAILS_SUCCESS:
      return { ...state, active: false };
    case SAVE_LEGAL_DETAILS:
      return state;
    default:
      return state;
  }
}