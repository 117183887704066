import React from 'react';
import COLOURS from 'util/colours'

class StepContent extends React.Component{
  render() {
    return (
      <div style={{backgroundColor: COLOURS.white}}>
        <div className="container" style={{paddingRight:'30px',paddingLeft:'30px'}}>
          {this.props.children}
        </div>
      </div>
    );
  }
};

export default StepContent;
