import React, { Component } from 'react'
import Field from 'util/components/field'
import NumberField from 'util/components/number-field'
import Validator from 'util/form-validator'
import { H2 } from 'visual-components/util/texts'

class TermsInfoPrizing extends Component {
  render() {
    let {
      updateAttr,
      campaign,
      form
    } = this.props

    return (
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div style={{
              marginBottom: '20px',
              display: 'block'
            }}>
              <H2>
                Prizing
              </H2>
            </div>
            <Field
              validations={ [Validator.required] }
              attrName="prizingDescription"
              type="textarea"
              rows="10"
              label={ `A thorough prizing description *` }
              labelStyles={{ fontSize: '16px', fontFamily: 'GothamRnd-Medium', marginBottom: '16px' }}
              tooltip={ `Be specific and thorough as possible, including date or transportation details of any travel, and the retail value of any products. Include any restrictions, like number of guests, date constraints, or costs not included in the prize.` }
              placeholder={ `Example: Prize will include a five (5) night stay at the Awesome Hotel in New York City for winner and one guest in a Junior Suite Room. Stay will include two (2) complimentary breakfasts, one (1) guided yoga class and winners choice of either one (1) dinner at the Tasty Restaurant or one (1) Spa package at the Hotel Spa. Prize is subject to blackout dates of 12/21/2017 - 1/5/2018.` }
              hideToastr={ true }
              updateAttr={ updateAttr }
              dataModel={ campaign }
              formModel={ form }
            />

            <NumberField
              validations={ [Validator.required] }
              attrName={ `prizingValue` }
              label={ `Estimated total prizing value *` }
              labelStyles={{ fontSize: '16px', fontFamily: 'GothamRnd-Medium', marginBottom: '16px' }}
              placeholder={ `$4,000` }
              dollarVal={ true }
              hideToastr={ true }
              updateAttr={ updateAttr }
              dataModel={ campaign }
              formModel={ form }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default TermsInfoPrizing
