import React from 'react';
import TermsEditCustom from './terms-edit-custom';
import TermsEditTemplate from './terms-edit-template';

class TermsEdit extends React.Component{
  render() {
    if (this.props.currentCampaign.campaignTerms.createYourOwn) {
      return <TermsEditCustom {...this.props} />
    }
    return <TermsEditTemplate {...this.props} />
  }
}

export default TermsEdit;