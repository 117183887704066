import React from 'react'

import HintText from './hint-text'
import AgreementThemes from 'css/themes/agreement/agreement-item.css'

class EditableText extends React.Component {
  render() {
    const {
      parent,
      name,
      value,
      text,
      placeholder,
      included,
      edit,
      updateValue,
      noRightSpace,
      width,
      hasError,
      charLimit
    } = this.props

    return (
      <div className={ `${AgreementThemes.inputHolder} ${noRightSpace ? AgreementThemes.noRightSpace : ''} ${hasError ? AgreementThemes.hasError : ''}` }>
        {
          included ?
          <input type={ 'text' } name={ name }
            placeholder={ placeholder }
            defaultValue={ value }
            onChange={ e => updateValue(parent, name, e.target.value) }
            maxlength={charLimit}
            style={{
              width: width || 'inherit'
          }}/>
          :
          <HintText text={ text } edit={ edit } />
        }
      </div>
    )
  }
}

export default EditableText
