import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'

import TooltipWrapper from './tool-tipped-div'

class BlueInfoTooltip extends Component {
  render() {
    const { text, styles } = this.props

    return (
      <TooltipWrapper styles={styles} tooltip={text}>
        <img src="/images/icons/tool-tip.svg" />
      </TooltipWrapper>
    )
  }
}

BlueInfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  styles: PropTypes.object,
}

BlueInfoTooltip.defaultProps = {
  styles: {},
}

export default BlueInfoTooltip
