import moment from 'moment-timezone';
import _ from 'lodash'

var model = 'brandPostEntry';

var terms = ['month', 'week', 'day'];

var columns = 12;

var generateXAxis = function (i,freq) {
  return {
    month: function () {
      return moment().subtract(11-i, 'months').format('MMM');
    },
    week: function () {
      return moment().subtract(1, 'days').subtract(11-i, 'weeks').format('MMM DD');
    },
    day: function () {
      return moment().subtract(11-i+1, 'days').format('MMM DD');
    }
  }[freq]();
}

var defaultState =  {
  rid: model,
  term: 'month',
  termList: [{ 
    id: 'day',
    name: 'Daily'
  },{ 
    id: 'week',
    name: 'Weekly'
  },{ 
    id: 'month',
    name: 'Monthly'
  }]
}

var BrandPostEntryReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_VIEW: function () {
      var term = action.data.term || state.term;
      var isEmpty = _.every(state.postEntryClicks[term], {y: 0})
      var selectedData = {
        conversions: state.conversions[term],
        postEntryClicks: state.postEntryClicks[term],
        postEntryIsEmpty: isEmpty
      }

      return _.extend({}, state, { selectedData: selectedData, term: term});
    },
    LOAD: function () {

      var conversions = {
      };
      var postEntryClicks = {
      };

      _.each(terms, function (term) {
        conversions[term] = [];
        postEntryClicks[term] = [];
        var sum = 0;
        _.times(columns, function (i) {
          var conversionCount = action.data.conversions[`${term}_${11 - i}`] || 0;
          var x = generateXAxis(i, term);
          if (!action.data.pixelInstalledAt) {
            conversionCount = Math.random() * 10;
          }
          conversions[term].push({
            x: x,
            y: parseInt(conversionCount)
          });
          var postEntryClickCount = action.data.recentSocialActions['cta-click'][`${term}_${11 - i}`] || 0;
          if (action.data.isEmpty) {
            postEntryClickCount = Math.random() * 10;
          }
          postEntryClicks[term].push({
            x: x,
            y: parseInt(postEntryClickCount)
          });
        })
      });

      var isEmpty = _.every(postEntryClicks[state.term], {y: 0})
      var selectedData = {
        conversions: conversions[state.term],
        postEntryClicks: postEntryClicks[state.term],
        postEntryIsEmpty: isEmpty
      }

      return _.extend({}, state, { isEmpty: action.data.isEmpty, pixelInstalledAt: action.data.pixelInstalledAt, conversions: conversions, postEntryClicks: postEntryClicks, selectedData: selectedData });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default BrandPostEntryReducer;
