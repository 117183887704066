import React, { Component } from 'react'
import { string } from 'prop-types'
import { Small } from 'visual-components/util/texts'

import AssetsTheme from 'css/themes/media-market/Assets.css'

class LinkWrapper extends Component {
  static propTypes = {
    image: string.isRequired,
    title: string.isRequired,
    linkDomain: string.isRequired,
    link: string.isRequired,
  }

  render() {
    const {
      image,
      title,
      linkDomain,
      link,
    } = this.props

    return (
      <a href={link} target="_blank">
        <div className={AssetsTheme.link}>
          <div className={AssetsTheme.linkImgHolder}>
            <img src={image} />
          </div>
          <div className={AssetsTheme.linkContentHolder}>
            <div className={AssetsTheme.linkContentItem}>
              <Small style={{ marginBottom: '12px' }}>
                <em>
                  {linkDomain}
                </em>
              </Small>
            </div>
            <div className={AssetsTheme.linkContentItem}>
              <Small>
                <em>
                  {title}
                </em>
              </Small>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default LinkWrapper
