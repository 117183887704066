/* eslint-disable react/sort-comp */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object, bool, func } from 'prop-types'
import { debounce } from 'lodash'
import { Checkbox } from 'react-toolbox'
import { H3, H4 } from 'visual-components/util/texts'
import IntegrationsMap from 'visual-components/integrations/integrations-map'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import SearchInput from 'visual-components/util/inputs/search-input'
import Badge from 'visual-components/util/badge'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import COLOURS from 'util/colours'
import theme from 'css/themes/deal-integrations-campaigns-table.css'
import CheckboxTheme from 'css/themes/Checkbox.css'
import IntegrationTheme from 'css/themes/integrations.css'
import * as DealIntegrationActions from 'actions/deal-integrations-actions'

const mapDispatchToProps = dispatch => ({
  dealIntegrationActions: bindActionCreators(DealIntegrationActions, dispatch),
})

class IntegrationDetails extends React.Component {
  static propTypes = {
    deal: object.isRequired,
    integration: object.isRequired,
    currentBrand: object.isRequired,
    campaignsReady: bool,
    campaignsLoading: bool,
    dealIntegrationActions: object.isRequired,
    closeIntegration: func.isRequired,
  }

  static defaultProps = {
    campaignsReady: false,
    campaignsLoading: false,
  }

  state = {
    searchQuery: '',
  }

  componentWillMount() {
    this.fetchCampaigns()
  }

  componentDidUpdate() {
    const {
      integration,
      campaignsReady,
      dealIntegrationActions: {
        updateIntegration,
      },
    } = this.props

    if (campaignsReady && integration.details.campaigns) {
      updateIntegration(integration.id, {
        attr: 'campaigns',
        value: integration.details.campaigns,
      })
    }
  }

  integrate = () => {
    const {
      integration,
      currentBrand,
    } = this.props

    const integrationItem = IntegrationsMap.find(item => integration.integration_type === item.key)
    window.location.href = `/${integrationItem.oauth.route}/${currentBrand.id}?dealId=${integration.deal_id}`
  }

  fetchCampaigns = debounce(() => {
    const {
      integration,
      dealIntegrationActions: {
        fetchIntegrationCampaigns,
      },
    } = this.props

    const integrationItem = IntegrationsMap.find(item => integration.integration_type === item.key)

    if (integration && integration.details) {
      const valid = integrationItem.apiFields.every(field => !!integration.details[field])

      if (valid) {
        fetchIntegrationCampaigns(integration)
      }
    }
  }, 500)

  handleChange = (campaignId, value) => {
    const {
      integration,
      dealIntegrationActions: {
        updateIntegration,
      },
    } = this.props

    updateIntegration(integration.id, {
      attr: 'active',
      value,
      campaignId,
    })
  }

  handleSearch = value => {
    this.setState({
      searchQuery: value,
    })
  }

  render() {
    const {
      deal,
      integration,
      campaignsLoading,
      closeIntegration,
    } = this.props

    const { searchQuery } = this.state

    const typeMap = {
      regular: 'Regular',
      plaintext: 'Plain-text',
      absplit: 'A/B Test',
      rss: 'RSS',
      variate: 'Variate',
    }

    const statusMap = {
      save: {
        label: 'Draft',
        color: COLOURS.cloudGrey,
      },
      paused: {
        label: 'Paused',
        color: COLOURS.cloudGrey,
      },
      schedule: {
        label: 'Schedule',
        color: COLOURS.cloudGrey,
      },
      sending: {
        label: 'Sending',
        color: COLOURS.coral,
      },
      sent: {
        label: 'Sent',
        color: COLOURS.seaGreen,
      },
    }

    const integrationItem = IntegrationsMap.find(item => integration.integration_type === item.key)
    const campaigns = integration && integration.details && integration.details.campaigns
      && integration.details.campaigns.map(campaign => {
        if (!searchQuery
          || (campaign.label && campaign.label.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          return (
            <tr key={campaign.value}>
              <td className={theme.firstTd}>
                { campaign.label.length > 38 ? `${campaign.label.slice(0, 34)}...` : campaign.label }
              </td>
              <td className={theme.secondTd}>
                { typeMap[campaign.type] }
              </td>
              <td className={theme.thirdTd}>
                <Badge
                  label={statusMap[campaign.status].label}
                  color={statusMap[campaign.status].color}
                />
              </td>
              <td className={theme.checkboxTd}>
                <Checkbox
                  label={null}
                  checked={campaign.active}
                  onChange={e => this.handleChange(campaign.value, e)}
                  theme={CheckboxTheme}
                />
              </td>
            </tr>
          )
        }

        return null
      })

    if (!integration.details || !Object.keys(integration.details).length) {
      let imgName
      let extension
      let srcSet
      if (integrationItem.img) {
        [imgName, extension] = integrationItem.img.split('.')
        srcSet = imgName && extension && `
          ${imgName}-modal.${extension},
          ${imgName}-modal@2x.${extension} 2x,
          ${imgName}-modal@3x.${extension} 3x
        `
      }

      return (
        <div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '24px' }}>
              <H3>
                { `Integrate with ${integrationItem.label}` }
              </H3>
            </div>
          </div>

          <img
            src={integrationItem.img}
            srcSet={srcSet}
            className={IntegrationTheme.modalLogo}
            style={{ marginLeft: '-32px' }}
          />

          <div>
            <ModalButtonGroup
              confirm={this.integrate}
              confirmText="Integrate"
              cancel={closeIntegration}
              cancelText="Back"
              canSave
            />
          </div>
        </div>
      )
    }

    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '16px' }}>
            <H3>
              Choose Campaigns to Connect
            </H3>
          </div>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <H4 multiline>
            Search or select as many campaigns that you will like to connect to <em>{deal.label}</em>.
          </H4>
        </div>

        <div style={{ marginBottom: '24px' }}>
          { campaignsLoading
            ? <MaterialSpinner when={campaignsLoading} />
            : (
              <div>
                <div style={{ marginBottom: '24px' }}>
                  <SearchInput
                    value={searchQuery}
                    placeholder="Find a campaign by name"
                    onChange={this.handleSearch}
                    className="smallInput"
                  />
                </div>
                <table className={theme.table}>
                  <thead>
                    <tr className={theme.noSideBorder}>
                      <th className={theme.firstTd}>
                        Campaign Name
                      </th>
                      <th className={theme.secondTd}>
                        Type
                      </th>
                      <th className={theme.thirdTd}>
                        Status
                      </th>
                      <th className={theme.checkboxTd}>
                        Connected
                      </th>
                    </tr>
                  </thead>
                </table>
                <div className={theme.tableScroll}>
                  <table className={theme.table}>
                    <tbody>
                      { campaigns }
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }
        </div>

        <ModalButtonGroup
          confirm={closeIntegration}
          confirmText="Done"
          cancel={closeIntegration}
          cancelText="Back"
          canSave
        />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(IntegrationDetails)
