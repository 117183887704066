import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

// sections
import Quickview from 'visual-components/campaignOverview/Quickview'
import CalendarPageContainer from 'components/calendar/CalendarPageContainer'
import Announcements from 'visual-components/campaignOverview/Announcements'
import LinksFiles from 'visual-components/campaignOverview/LinksFiles'
import EmailDelivery from 'visual-components/campaignOverview/EmailDelivery'
import GrowOptimize from 'visual-components/campaignOverview/GrowOptimize'

import MobileMarketingProvider from 'visual-components/mobile-marketing/MobileMarketingContext'

import {
  Route,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom'
import OverviewTheme from 'css/themes/Overview.css'
import { bindActionCreators } from 'redux'
import * as CampaignTaskActions from 'actions/campaign-task-actions'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import * as socialActionsActions from 'actions/social-actions-actions'
import OverviewActionsContainer from 'components/partnerships/OverviewActionsContainer'
import WinnerModal from 'visual-components/myPartnerships/winner-modal'
import CreateCampaignLimitModal from 'components/campaigns/CreateCampaignLimitModal'

import { H2 } from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'
import * as DialogActions from 'actions/dialog-actions'
import * as SmsActions from 'actions/sms-actions'

import OverviewNav from './OverviewNav'
import SoloOverview from './SoloOverview'

const mapStateToProps = ({ currentCampaign, currentBrand, sms }) =>
  ({ currentCampaign, currentBrand, sms })

const mapDispatchToProps = dispatch => ({
  smsActions: bindActionCreators(SmsActions, dispatch),
  campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch),
  dialogActions: bindActionCreators(DialogActions, dispatch),
  socialActionsActions: bindActionCreators(socialActionsActions, dispatch),
})

class CampaignOverview extends Component {
  static contextTypes = {
    launchCampaign: PropTypes.func,
    currentBrand: PropTypes.object.isRequired,
    currentCampaign: PropTypes.object.isRequired,
  }

  state = {
    winnerModal: false,
  }

  componentDidMount() {
    const {
      match: { params: { campaignId } },
      campaignTaskActions: { load },
      sms,
      smsActions,
      currentBrand,
    } = this.props

    CampaignDispatcher.loadRequest(campaignId)

    load(campaignId)

    if (sms.loading) smsActions.fetchBrandBank(currentBrand.id)
  }

  goTo = location => {
    const { history } = this.props

    history.push(locationMap[location])
  }

  chooseWinner = () => {
    this.setState({
      winnerLoading: true,
    })

    CampaignDispatcher.chooseWinner(() => {
      this.setState({
        winnerModal: true,
        winnerLoading: false,
      })
    })

    toastr.success('Choosing a Winner, this may take a few minutes.', null, { timeOut: 5000, positionClass: 'toast-bottom-center' })
  }

  closeWinnerModal = () => {
    this.setState({
      winnerModal: false,
    })
  }

  launchCampaign = () => {
    const { launchCampaign } = this.context
    const { currentCampaign, dialogActions } = this.props
    const {
      campaignTerms, id, name, isSolo,
    } = currentCampaign

    const hasTerms = campaignTerms && campaignTerms.termsPublishedAt && campaignTerms.termsStatus === 'published'
    return hasTerms ? launchCampaign(id, isSolo) : dialogActions.openDialogWithPayload('incompleteRulesModal', { name, id })
  }

  render() {
    const { currentCampaign, currentBrand, sms } = this.props
    const prefix = this.props.match.path
    const prefixedUrl = this.props.match.url

    const { winnerModal } = this.state

    if (currentCampaign.loading || this.state.winnerLoading || sms.loading) {
      return (
        <Spinner />
      )
    }

    if (currentCampaign.isSolo) {
      return (
        <SoloOverview
          currentCampaign={currentCampaign}
          currentBrand={currentBrand}
          winnerModalOpen={winnerModal}
          closeWinnerModal={this.closeWinnerModal}
          chooseWinner={this.chooseWinner}
          launchCampaign={this.launchCampaign}
        />
      )
    }

    return (
      <div>
        <div className={`${OverviewTheme.overviewContainer} ${OverviewTheme.overviewContainerNav}`}>
          <div className={OverviewTheme.overviewWrapper}>
            <Helmet>
              <title>Campaign Overview</title>
            </Helmet>

            <Grid>
              <Row style={{ marginBottom: '24px' }}>
                <Col xs={7}>
                  <div style={{ display: 'inline-block' }}>
                    <H2 azure>
                      { currentCampaign.name }
                    </H2>
                  </div>
                </Col>
                <OverviewActionsContainer
                  currentCampaign={currentCampaign}
                  pickWinner={this.chooseWinner}
                  onLaunchCampaign={this.launchCampaign}
                />
              </Row>
              <Row>
                <Col xs={12}>
                  <Route
                    path={`${prefix}/:selected`}
                    render={
                      ({ match }) => (
                        <OverviewNav
                          prefixedUrl={prefixedUrl}
                          selected={match.params.selected}
                        />
                      )
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <div className={OverviewTheme.overviewContainer}>
          <div className={OverviewTheme.overviewWrapper}>
            <Switch>
              <Route path={`${prefix}/quickview`} component={Quickview} />
              <Route path={`${prefix}/calendar`} component={CalendarPageContainer} />
              <Route path={`${prefix}/announcements`} component={Announcements} />
              <Route path={`${prefix}/links-files`} component={LinksFiles} />
              <Route path={`${prefix}/email-delivery/:section?/:subsection?`} component={EmailDelivery} />
              <Route path={`${prefix}/grow-optimize`} component={GrowOptimize} />
              <Redirect to="/404" />
            </Switch>
          </div>
        </div>
        <WinnerModal
          isOpen={winnerModal}
          currentCampaign={currentCampaign}
          hideModal={this.closeWinnerModal}
        />
        <CreateCampaignLimitModal />
      </div>
    )
  }
}

// Workaround to make contextType work with connect
// Source: https://github.com/reduxjs/react-redux/issues/1067
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(props => (
  <MobileMarketingProvider>
    <CampaignOverview {...props} />
  </MobileMarketingProvider>
)))
