import React, { Component } from 'react'
import theme from 'css/themes/mobile/home/home.css'

class WhatTheySay extends Component {
  render() {
    const {
      quote,
      who,
      position,
      where,
      link,
      brandImg,
      title
    } = this.props;

    return(
      <div className={ theme.whatTheySaidEl }>
        <p className={ theme.whatTheySaidTitle }>
          { title }
        </p>
        <div className={ theme.innerEl }>
          <p className={ theme.quote }>
            { quote }
          </p>
          <p className={ theme.who }>
            { who }
          </p>
          <p className={ theme.position }>
            { `${position} at` }
            <a href={ link } target="_blank">
              { where }
            </a>
          </p>
          <a href={ link } target="_blank">
            <img alt={who} src={ brandImg } />
          </a>
        </div>
      </div>
    )
  }
}

export default WhatTheySay
