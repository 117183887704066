import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object, bool } from 'prop-types'
import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text'
import CampaignBuilderSection from 'campaigns/builder/components/campaign-builder-section'
import SubNavContainer from 'components/nav/subnav-container'
import SubNavTab from 'components/nav/subnav-tab'
import PartnerList from 'campaigns/builder/partners/partners-list'
import PartnerDetails from 'campaigns/builder/partners/partner-detail'
import { H3, P } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import * as PartnerActions from 'actions/campaign-partner-actions'
import * as DialogActions from 'actions/dialog-actions'
import PartnerAnnouncementModal from './partner-announcement-modal'
import PartnerConfirmationDialog from './partner-confirmation-dialog'
import ReferralBanner from './referral-banner'
import { openPlatformInviteModal } from 'actions/referral-actions'


const mapStateToProps = ({
  currentCampaign,
  currentBrand,
  partnerDetails,
  partnerDetailsForm,
  reinviteConfirmationModal,
  profile,
  upcomingCampaigns
}) => ({
  currentCampaign,
  currentBrand,
  partnerDetails,
  partnerDetailsForm,
  reinviteConfirmationModal,
  profile,
  upcomingCampaigns,
})

const mapDispatchToProps = dispatch => ({
  partnerActions: bindActionCreators(PartnerActions, dispatch),
  dialogActions: bindActionCreators(DialogActions, dispatch),
  openPlatformInviteModal: bindActionCreators(openPlatformInviteModal, dispatch),
})

class Partners extends React.Component {
  static propTypes = {
    currentCampaign: object.isRequired,
    currentBrand: object.isRequired,
    partnerDetails: object.isRequired,
    partnerDetailsForm: object.isRequired,
    isHost: bool,
    partnerActions: object.isRequired,
    params: object.isRequired,
    history: object.isRequired,
  }

  static defaultProps = {
    isHost: false,
  }

  state = {
    activeDialog: null,
  }

  componentDidMount() {
    const { currentCampaign, params } = this.props

    document.title = 'Partners - DojoMojo'

    const toastrMessage = getParameterByName('toastr')
    if (toastrMessage) {
      toastr.success(`${toastrMessage} has joined!`, null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
    }

    const partnersInterested = currentCampaign.invites.filter(invite => invite.status === 'interested')
    const partnersPendingApplications = currentCampaign.invites.filter(invite => invite.status === 'pending' && invite.application === true)

    if (params.subSection !== 'accepted' && (partnersInterested.length || partnersPendingApplications.length)) {
      this.changeTab((partnersInterested.length && 'interested')
        || (partnersPendingApplications.length && 'pendingApplication'))
    }
  }

  changeTab = tabName => {
    const { currentCampaign, history } = this.props
    history.push(`/builder/${currentCampaign.id}/partner/${tabName}`)
  }

  openDialog = type => {
    this.setState({
      activeDialog: type,
    })
  }

  closeDialog = () => {
    this.setState({
      activeDialog: null,
    })
  }

  sendAnnouncement = (type, details) => {
    const { currentCampaign, partnerActions } = this.props

    partnerActions.sendAnnouncement(currentCampaign.id, type, details)
    this.openDialog('announcementDialog')
  }


  render() {
    const {
      isHost,
      currentCampaign,
      currentBrand,
      partnerDetails,
      partnerDetailsForm,
      partnerActions,
      params,
      history,
      reinviteConfirmationModal,
      dialogActions,
      profile,
      upcomingCampaigns,
      openPlatformInviteModal
    } = this.props

    const { activeDialog } = this.state

    const currentTab = params.subSection || 'accepted'
    const campaignForMessage = upcomingCampaigns.campaigns.filter(item => item.id === Number(params.campaignId))

    // All partners and the status of their invite
    const partnersAccepted = currentCampaign.invites.filter(invite => invite.status === 'accepted')
    const partnersInterested = currentCampaign.invites.filter(invite => invite.status === 'interested')
    const partnersPendingInvites = currentCampaign.invites.filter(invite => invite.status === 'pending' && invite.application === false)
    const partnersPendingApplications = currentCampaign.invites.filter(invite => invite.status === 'pending' && invite.application === true)
    const partnersRejected = currentCampaign.invites.filter(invite => invite.status === 'rejected')

    const headers = isHost ? [
      <H3>
        <small>
          Add Partners
        </small>
      </H3>,
    ] : [
      <H3>
        <small>
          { `Host Brand: ${currentCampaign.hostBrand.accountname}` }
        </small>
      </H3>,
    ]

    const paras = isHost ? [
      <P multiline>
        {'Invite partners here to promote the sweepstakes. To keep lead quality high, we recommend '}
        <b>a maximum of 6 partners,</b>
        {' including your brand, and can’t allow more than '}
        <b>8 brands</b>
        {' on a sweepstakes. '}
        <a
          href="https://help.dojomojo.com/hc/en-us/articles/360004988932"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
        {'.'}
      </P>,
      <P multiline>
        {'Looking to set a Minimum Entry Requirement? You can now set entry requirements with a quick '}
        <a
          href="https://help.dojomojo.com/hc/en-us/articles/115005958507-Creating-a-Partnership-Agreement"
          target="_blank"
          rel="noopener noreferrer"
        >
          Partnership Agreement
        </a>
        {'.'}
      </P>,
    ] : [
      <P multiline>
        View brand details and contact your campaign Partners.
      </P>,
    ]

    if (isHost && currentCampaign.startDate < new Date()) {
      paras.push(
        <P multiline>
          {'Release emails for your partners by selecting the '}
          <b>Release Emails</b>
          {' option under '}
          <b>Actions</b>
          {' menu.'}
        </P>
      )
    }

    const acceptedTab = (
      <SubNavTab
        href="javascript:void(0)"
        location="accepted"
        name="accepted"
        currentTab={currentTab}
        changeTab={this.changeTab}
        copy={`Accepted (${partnersAccepted.length})`}
      />
    )

    const interestedTab = (
      <SubNavTab
        href="javascript:void(0)"
        location="interested"
        name="interested"
        currentTab={currentTab}
        changeTab={this.changeTab}
        copy={`Interested (${partnersInterested.length})`}
      />
    )

    const pendingInviteTab = (
      <SubNavTab
        href="javascript:void(0)"
        location="pendingInvite"
        name="pendingInvite"
        currentTab={currentTab}
        changeTab={this.changeTab}
        copy={`Pending Invites (${partnersPendingInvites.length})`}
      />
    )

    const pendingApplicationTab = (
      <SubNavTab
        href="javascript:void(0)"
        location="pendingApplication"
        name="pendingApplication"
        currentTab={currentTab}
        changeTab={this.changeTab}
        copy={`Pending Applications (${partnersPendingApplications.length})`}
      />
    )

    const rejectedTab = (
      <SubNavTab
        href="javascript:void(0)"
        location="rejected"
        name="rejected"
        currentTab={currentTab}
        changeTab={this.changeTab}
        copy={`Rejected (${partnersRejected.length})`}
      />
    )

    // Only host can see pending and rejected invites
    const navTabs = currentCampaign.hostBrandId === currentBrand.id ? (
      // Only show rejected tab if there are rejected invites
      partnersRejected.length > 0 ? (
        <SubNavContainer
          paddingLeft="0px"
          paddingRight="0px"
        >
          { acceptedTab }
          { partnersInterested.length > 0 ? interestedTab : null }
          { partnersPendingInvites.length > 0 ? pendingInviteTab : null }
          { partnersPendingApplications.length > 0 ? pendingApplicationTab : null }
          { rejectedTab }
        </SubNavContainer>
      ) : (
        <SubNavContainer
          paddingLeft="0px"
          paddingRight="0px"
        >
          { acceptedTab }
          { interestedTab }
          { pendingInviteTab }
          { pendingApplicationTab }
        </SubNavContainer>
      )

    ) : (
      // Un-privileged users can only see accepted partners
      null
    )

    const findPartnerDisabled = currentCampaign.started
      || (!currentCampaign.noPartnerLimit && partnersAccepted.length >= 8)

    return (
      <CampaignBuilderSection subSection={currentCampaign.section}>
        <PartnerAnnouncementModal
          active={activeDialog === 'announcementModal'}
          currentCampaign={currentCampaign}
          sendAnnouncement={this.sendAnnouncement}
          closeModal={this.closeDialog}
        />
        <PartnerConfirmationDialog
          type={activeDialog}
          currentCampaign={currentCampaign}
          closeDialog={this.closeDialog}
        />

        <div className="row">
          <div className="col-sm-8">
            <TabTitleAndText headers={headers} paras={paras} />
          </div>
          <div className="col-sm-4 text-right">
            { isHost
              ? (
                <div>
                  { partnersAccepted.length > 1
                    ? (
                      <div style={{ marginRight: '12px', display: 'inline-block' }}>
                        <Button
                          onClick={() => this.openDialog('announcementModal')}
                          label="Send Announcement"
                          theme={buttonTheme}
                          className={buttonTheme.greenButton}
                          raised
                          primary
                        />
                      </div>
                    ) : null
                  }
                  <a
                    href={!currentCampaign.started ? history.createHref({ pathname: '/search/brands' }) : null}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      label="Find a Partner"
                      theme={buttonTheme}
                      className={`${buttonTheme.blueButton} ${findPartnerDisabled ? buttonTheme.disabledButton : ''}`}
                      disabled={findPartnerDisabled}
                      raised={!findPartnerDisabled}
                      primary
                    />
                  </a>
                </div>
              ) : null
            }
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            { navTabs }
            { currentCampaign.startDate > new Date() &&
              <ReferralBanner
                openPlatformInviteModal={openPlatformInviteModal}
                campaignId={currentCampaign.id}
              />
            }
            <PartnerList
              partnerActions={partnerActions}
              currentCampaign={currentCampaign}
              currentBrand={currentBrand}
              typeToShow={currentTab} // Only show the invites with the correct status
              isHost={isHost}
              openDialog={this.openDialog}
              closeDialog={this.closeDialog}
              reInvitePartner={this.reInvitePartner}
              reinviteConfirmModalActive={reinviteConfirmationModal.open}
              dialogActions={dialogActions}
              profile={profile}
              campaignForMessage={campaignForMessage}
            />
          </div>
          <PartnerDetails
            partnerDetails={partnerDetails}
            partnerDetailsForm={partnerDetailsForm}
          />
        </div>
      </CampaignBuilderSection>
    )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Partners))
