import React from 'react';
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PartnershipInvitesDispatcher from 'dispatchers/partnership-invites-dispatcher';
import * as MessagingPartnerActions from 'actions/messaging-partner-actions'
import Button from 'react-toolbox/lib/button';
import InterestedInfoModal from 'visual-components/messaging/interested-info-modal'
import ButtonTheme from 'css/themes/Buttons.css'

const themes = {
  Button: ButtonTheme
}

const mapStateToProps = state => {
  return {
    currentBrand: state.currentBrand,
    user: state.profile
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(MessagingPartnerActions, dispatch)
})

class InviteBannerItem extends React.Component {
  acceptPartnership = () => {
    let { invite, actions, user, currentBrand } = this.props;
    const campaign = _.find(store.getState().upcomingCampaigns.campaigns, { id: invite.campaign_id })
    const acceptedPartners = campaign && Object.keys(campaign.partners).filter(partner =>
      campaign.partners[partner].status === 'accepted')
    const numberOfPartners = acceptedPartners ? acceptedPartners.length : invite.numberOfPartners
    if (campaign && !campaign.noPartnerLimit && numberOfPartners >= 8) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'campaignLimitModal',
        data: {
          open: true,
          isInvitation: !invite.application,
          campaignId: invite.campaign_id
        }
      });
      return;
    } else if (invite.application) {
      actions.sendInterest(invite.partnershipinvite_id, invite.id, invite.conversation_id, `${invite.messageType}s`, { application: true, campaignId: invite.campaign_id })
      actions.markInterestedRecievedApplication(invite.partnershipinvite_id, invite.messageType)
      actions.toggleInterestedConfirmationModal(true, {
        application: true,
        campaign_name: invite.campaign_name,
        brand_name: invite.invitee_brand_name,
        campaign_id: invite.campaign_id,
        partnership_invite_id: invite.partnershipinvite_id,
      })
    } else {
      actions.toggleInterestedInfoModal(
        invite.partnershipinvite_id,
        {
          campaign: {
            id: invite.campaign_id,
            startDate: invite.campaign_start_date,
            endDate: invite.campaign_end_date,
            name: invite.campaign_name,
          },
          brand_name: invite.displayBrandName,
          application: invite.application,
          invite_id: invite.partnershipinvite_id,
          message_id: invite.id,
          conversation_id: invite.conversation_id,
          messageType: `${invite.messageType}s`
        }
      )
    }
  }

  rejectPartnership = () => {
    var invite = this.props.invite;

    let inviteObject = {
      id: invite.partnershipinvite_id,
      invitee: {
        id: invite.invitee_id,
      },
      campaign_start_date: invite.campaign_start_date,
      campaign_end_date: invite.campaign_end_date,
      campaign_name: invite.campaign_name,
      campaign_host_name: invite.host_brand_name,
      campaign_id: invite.campaign_id,
      message_id: invite.id,
      conversation_id: invite.conversation_id
    }

    PartnershipInvitesDispatcher[invite.application ? 'rejectApplication' : 'rejectInvite'](inviteObject);

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'conversations',
      data: {
        showPartnershipDeclineModal: _.extend({}, invite, {active: true})
      }
    });
  }
  render() {
    let { invite } = this.props;

    return (
      <div style={{
        paddingTop: '15px',
        width: '650px',
        margin: 'auto',
        overflow: 'hidden'
      }}>
        <div style={{
          float: 'left',
          marginTop: '12px'
        }}>
          <p style={{
            display: 'inline-block',
            fontFamily: 'Larsseit-Medium',
            maxWidth: '444px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
            {invite.actionText}
          </p>
        </div>
        <div style={{
          float: 'right'
        }}>
          <Button
            onClick={ this.acceptPartnership }
            label={ 'Interested' }
            className={ themes.Button.blueButton }
            theme={ themes.Button }
            primary
            raised
            style={{
              fontFamily: 'Larsseit-Medium',
              fontSize: '14px',
              width: '106px',
              height: '36px',
              marginLeft: '15px'
          }}/>
          <Button
            onClick={ this.rejectPartnership }
            theme={ themes.Button }
            className={ themes.Button.noMargin }
            label={ 'Decline' }
            flat
            primary
            style={{
              marginLeft: '15px'
            }}
          />
        </div>

        <InterestedInfoModal />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteBannerItem)
