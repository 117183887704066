import React from 'react'
import moment from 'moment'
import CalendarSelect from 'util/components/single-date-picker'
import HintText from './hint-text'
import AgreementThemes from 'css/themes/agreement/agreement-item.css'

class EditableDate extends React.Component {
  state = {
    focused: false
  }

  onFocusChange = () => {
    this.setState({
      focused: !this.state.focused
    })
  }

  updateValue = date => {
    const {
      parent,
      name
    } = this.props

    this.props.updateValue(parent, name, date)
    this.onFocusChange()
  }

  render() {
    const {
      value,
      text,
      included,
      edit,
      min,
      max,
      noRightSpace,
      hasError
    } = this.props

    const {
      focused
    } = this.state

    return (
      <div className={ `${AgreementThemes.inputHolder} ${noRightSpace ? AgreementThemes.noRightSpace : ''} ${hasError ? AgreementThemes.hasError : ''}` }>
        {
          included ?
          <CalendarSelect date={ value ? moment(value) : null }
            className={ hasError ? AgreementThemes.hasError : '' }
            updateValue={ this.updateValue }
            focused={ focused }
            onFocusChange={ this.onFocusChange }
            min={ min }
            max={ max }
            noRightSpace
          />
          :
          <HintText text={ text } edit={ edit } />
        }
      </div>
    )
  }
}

export default EditableDate
