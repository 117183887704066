import React from 'react';
import FormGroup from 'util/components/form-group';
import Label from 'util/components/label';
import Error from 'util/components/error';
import COLOURS from 'util/colours';
import SelectField from 'util/components/select-field';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import _ from 'lodash'

import * as ReactRedux from 'react-redux';

var mapState = function (state) {
  return {
    brand: state.currentBrand,
    brandForm: state.currentBrandForm
  }
}

class BrandCategoriesContainer extends React.Component{
  constructor(props) {
    super(props);
    this.updateTag = this.updateTag.bind(this);
  }

  updateTag(attr, val) {
    CurrentBrandDispatcher.updateTags(this.props.isSilent);
  }

  render(argument) {
    var copy = LANGUAGE.brandSettings.tags;
    var brand = this.props.brand;
    var options = [{ value: '', label: copy.unselected }].concat(copy.tags);

    let labelStyles = {
      paddingBottom: '4px'
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <Error
            error={ this.props.brandForm.brand_tag_0 }
          />
          <SelectField
            options={ _.without(options, brand.brand_tag_1, brand.brand_tag_2) }
            updateAttr={ this.updateTag }
            dataModel={ this.props.brand }
            formModel={ this.props.brandForm }
            attrName="brand_tag_0"
            label={ 'Brand Category (at least one is required) *' }
            labelStyles={ labelStyles }
          />
          <div style={{
            marginTop: '-15px'
          }}>
            <SelectField
              options={ _.without(options, brand.brand_tag_0, brand.brand_tag_2) }
              updateAttr={ this.updateTag }
              dataModel={ this.props.brand }
              formModel={ this.props.brandForm }
              attrName="brand_tag_1"
              label={ null }
            />
          </div>
          <div style={{
            marginTop: '-15px'
          }}>
            <SelectField
              options={ _.without(options, brand.brand_tag_0, brand.brand_tag_1) }
              updateAttr={ this.updateTag }
              dataModel={ this.props.brand }
              formModel={ this.props.brandForm }
              attrName="brand_tag_2"
              label={ null }
            />
          </div>
        </div>
      </div>
    );
  }
};

export default ReactRedux.connect(mapState)(BrandCategoriesContainer);
