import React from 'react'
import { string, bool } from 'prop-types'
import cn from 'classnames'
import styles from 'css/themes/Badge.css'

const Badge = ({
  label, color: backgroundColor, simple, dark, inline, grey, nowrap, nominwidth,
}) => {
  const {
    badge, small, inlineBlock, darkText, greyText, nowrapText, noMinWidth,
  } = styles
  const className = cn({
    [small]: simple,
    [badge]: true,
    [inlineBlock]: inline,
    [darkText]: dark,
    [greyText]: grey,
    [nowrapText]: nowrap,
    [noMinWidth]: nominwidth,
  })

  return (
    <div className={className} style={{ backgroundColor }}>
      <p>{label}</p>
    </div>
  )
}

Badge.propTypes = {
  label: string.isRequired,
  color: string.isRequired,
  simple: bool,
  grey: bool,
  dark: bool,
  inline: bool,
  nowrap: bool,
  nominwidth: bool,
}

Badge.defaultProps = {
  simple: false,
  grey: false,
  dark: false,
  inline: false,
  nowrap: false,
  nominwidth: false,
}

export default Badge
