import React, { Component } from 'react';
import { Input } from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button';

import homeTheme from 'css/themes/homePage/home.css';
import buttonTheme from 'css/themes/Buttons.css'
import cn from 'classnames'

class SubscribeForm extends Component {
  render() {
    return(
      <form
        action="//ninja.us11.list-manage.com/subscribe/post?u=edee501d99df967bf714b452d&amp;id=578ec8d474"
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        <div style={{
          display: 'flex'
        }}>
          <Input type='text'
            name='EMAIL'
            id="mce-EMAIL"
            theme={ homeTheme }
            className={ cn(homeTheme.emailInput, homeTheme.footerEmailInput) }
            placeholder={ 'Enter your email' }
          />
          <Button
            type={ 'submit' }
            label={ 'Subscribe' }
            className={`${buttonTheme.greenButton} ${homeTheme.freeTrialButton}`}
            theme={ buttonTheme }
            raised
          />
        </div>
      </form>
    )
  }
}

export default SubscribeForm;
