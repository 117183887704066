import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

class NoNotifications extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      message
    } = this.props;

    return (
      <div style={{
        width: '440px',
        margin: 'auto',
        paddingTop: '120px'
      }}>
        <p style={{
          fontFamily: 'Larsseit',
          fontSize: '24px',
          color: COLOURS.cloudGrey,
          textAlign: 'center',
          lineHeight: '1.5'
        }}>
          { 
            message
          }
        </p>
      </div>
    );
  }
};

NoNotifications.propTypes = {
  message: PropTypes.string
};

export default NoNotifications;
