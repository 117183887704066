import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import TinyMCE from 'react-tinymce';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import * as ReactRedux from 'react-redux';
import COLOURS from 'util/colours';
import RichEditor from 'visual-components/richEditor/RichEditor';
import MessagingBrandLogo from 'visual-components/messaging/messaging-brand-logo';
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';
import { stateToHTML } from 'draft-js-export-html';
import { ContentState } from 'draft-js';


class MessagingResponse extends React.Component {
  constructor(props) {
    super(props)
    this.save = this.save.bind(this);
    this.populateEditor = this.populateEditor.bind(this);
    this.showEmptyEditor = this.showEmptyEditor.bind(this);
    this.state = {
      showQuickReply: this.props.showQuickReply,
      initialContent: null
    }
  }


  save(contentState) {
    let { conversationId, newConversationBrandId } = this.props
    if (!contentState.hasText())  {
      return;
    }



      if (conversationId == 'newBrandConversation') {
        PricingDispatcher.check('messaging.message.create', function () {
          MessagingDispatcher.newBrandConversation({
            brandId: newConversationBrandId,
            content: stateToHTML(contentState)
          })
        })
      } else {
        MessagingDispatcher.sendMessage({
          conversationId: conversationId,
          content: stateToHTML(contentState)
        });
      }
  }

  populateEditor(e) {
    this.setState({
      showQuickReply: false,
      initialContent: ContentState.createFromText(e.target.textContent)
    })
  }

  showEmptyEditor(){
    this.setState({
      showQuickReply: false,
    })
  }

  render() {
    let currentBrand = store.getState().currentBrand
    return (
      <div>
        <Row style={{
          paddingTop: '12px',
          paddingRight: '12px',
          paddingBottom: '18px',
          paddingLeft: '12px',
          borderTop: `1px solid ${COLOURS.silver}`
        }}>
          <Col xs={1}>
            <MessagingBrandLogo logo={ currentBrand.logo }/>
          </Col>
          <Col xs={2} style={{
            paddingLeft: '12px'
          }}>
            <p style={{
              fontFamily: 'Larsseit-Bold',
              fontSize: '14px',
              marginTop: '8px'
            }}>
              { currentBrand.accountname }
            </p>
            <p style={{
              fontFamily: 'Larsseit',
              fontSize: '12px',
              marginTop: '12px'
            }}>
              { 'me' }
            </p>
          </Col>
          { this.props.conversationId != 'newBrandConversation' ?
              <Col xs={9} style={{
                textAlign: 'right'
              }}>
                <div onClick={ this.props.collapse }>
                  <p style={{
                    color: COLOURS.azure,
                    fontSize: '14px',
                    fontFamily: 'Larsseit-Medium',
                    marginTop: '8px',
                    display: 'inline-block',
                    cursor: 'pointer'
                  }}>
                    Collapse
                  </p>
                  <img src="/images/icons/messaging-icons/industry-list-collapse-arrow.svg" style={{
                    width: '12px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    cursor: 'pointer'
                  }}/>
                </div>
              </Col>
            :
              null
          }
        </Row>
        <Row>
          <Col xsOffset={1} xs={11} style={{
            paddingTop: '0px',
            paddingRight: '25px',
            paddingBottom: '16px',
            paddingLeft: '25px'
          }}>
            {
              this.state.showQuickReply ?
                <div>
                  <p onClick={this.showEmptyEditor} style={{
                    fontFamily: 'Larsseit-Medium',
                    fontSize: '14px',
                    color: COLOURS.azure,
                    marginBottom: '30px',
                    cursor: 'pointer'
                  }}>
                    Write Own Reply
                  </p>
                  <p>
                    <span onClick={this.populateEditor} style={{
                      color: COLOURS.azure,
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '14px',
                      marginRight: '24px',
                      cursor: 'pointer'
                    }}>
                      Yes let's chat
                    </span>
                    <span onClick={this.populateEditor} style={{
                      color: COLOURS.azure,
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '14px',
                      marginRight: '24px',
                      cursor: 'pointer'
                    }}>
                      Maybe later
                    </span>
                    <span onClick={this.populateEditor} style={{
                      color: COLOURS.azure,
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '14px',
                      marginRight: '24px',
                      cursor: 'pointer'
                    }}>
                      No Thanks
                    </span>
                  </p>
                </div>
              :
                <RichEditor
                  buttonText="Send Message"
                  buttonClick={ this.save }
                  styleOptions={['Bold', 'Italic', 'UL']}
                  initialContent= {this.state.initialContent}
                />
            }
          </Col>
        </Row>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    newConversationBrandId: state.conversations.newConversationBrandId
  }
}
export default ReactRedux.connect(mapState)(MessagingResponse)
