import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import colors from 'util/colours'

const CloseIcon = props => {
  const dPath = 'M16 .941l-.928-.925L8 7.075.928.015 0 .942l7.072 7.06L0 15.058l.928.925L8 8.926l7.072 7.058.928-.925L8.927 8z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path fill="#475464" fillRule="nonzero" d={dPath} />
    </svg>
  )
}

const styles = {
  wrapper: {
    position: 'relative',
    height: 64,
    width: 'fit-content',
    objectFit: 'contain',
    border: `1px solid ${colors.silver}`,
    borderRadius: 2,
    margin: '6px 16px 10px 0',
    '& img': {
      height: '100%',
    },
  },
  close: {
    backgroundColor: 'white',
    borderRadius: '50%',
    border: `1px solid ${colors.silver}`,
    height: 16,
    width: 16,
    position: 'absolute',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2)',
    right: -8,
    top: -8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
      width: 6,
      height: 6,
      stroke: colors.azure,
    },
  },
}

function ImageItem(props) {
  const { image, onDelete, classes: css } = props

  return (
    <div className={css.wrapper}>
      <div className={css.close} onClick={onDelete}>
        <CloseIcon />
      </div>
      <img src={image} alt="preview" />
    </div>
  )
}

ImageItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
}

export default React.memo(injectCSS(styles)(ImageItem))
