import React from 'react';
import * as ReactRedux from 'react-redux';

import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text';
import { P } from 'visual-components/util/texts';

import CampaignDispatcher from 'dispatchers/campaign-dispatcher';


class TermsReview extends React.Component{
  constructor(props) {
    super(props);

    this.resizeIframe = this.resizeIframe.bind(this);
  }


  resizeIframe(iframeID, text) {
    // this doesn't work. leaving it in for when componentDidMount works
    var iframe = document.getElementById('slideshow_frame');
    if (iframe) {
      if (iframe.contentWindow && iframe.contentWindow.document.body) {
        var height = iframe.contentWindow.document.body.scrollHeight;
          // iframe.height = '2500px';
          // iframe.height = `${height}px`;
      }
    };
  }

  render() {
    var campaignTerms = this.props.currentCampaign.campaignTerms || {};

    var paras = [
      <P multiline>
        Carefully review these Official Rules. These are designed to be nearly complete and in many cases shouldn't require editing. However, should you choose to edit them, you can do so at any time in the Edit tab above. If they do not require additional editing, you can Publish them and set them live for your Campaign.
      </P>
    ];

    return(
      <div>
        <TabTitleAndText
          headers={[]}
          paras={ paras }
        />
        <div>
          <iframe
            src={`/landing/terms/${this.props.currentCampaign.id}?preview=true`}
            id="slideshow_frame"
            // onLoad={ this.resizeIframe("slideshow_frame", "onload") }
            style={{
              border: 'none',
              minHeight: '100vh',
              height: '2700px',
              width: '100%',
              marginTop: '-25px'
          }}/>
        </div>
      </div>
    )
  }
}

export default TermsReview;
