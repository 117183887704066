import React, { PureComponent as Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import d3 from 'd3'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import AppDispatcher from 'dispatchers/app-dispatcher'
import CardDispatcher from 'dispatchers/card-dispatcher'

import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import SocialTheme from 'css/themes/SocialGrowth.css'
import DropDownTheme from 'css/themes/formdropdown.css'
import {
  H3, H4, H6, P,
} from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'
import ChooseCard from 'visual-components/util/choose-card'
import * as socialActionsActions from 'actions/social-actions-actions'
import SocialActionsDropdown from './social-actions-dropdown'

const currencyFormat = d3.format('$,.2f')

const mapStateToProps = ({
  currentCampaign,
  currentCampaigns,
  upcomingCampaigns,
  currentBrand,
  partnershipInvite,
  SocialActions,
  customer,
  card,
}) => ({
  currentCampaign,
  currentCampaigns,
  upcomingCampaigns,
  currentBrand,
  partnershipInvite,
  customer,
  card,
  pendingInvoiceItems: SocialActions.pendingInvoiceItems,
  actionLoading: SocialActions.loading,
  purchaseSuccess: SocialActions.purchaseSuccess,
  purchaseFailure: SocialActions.purchaseFailure,
  error: SocialActions.error,
})

const mapDispatchToProps = dispatch => ({
  socialActionsActions: bindActionCreators(socialActionsActions, dispatch),
})

class PurchaseSocialActionsModal extends Component {
  static propTypes = {
    actionLoading: PropTypes.bool,
    currentCampaign: PropTypes.object.isRequired,
    pendingInvoiceItems: PropTypes.array,
    socialActionsActions: PropTypes.object.isRequired,
    currentCampaigns: PropTypes.object,
    upcomingCampaigns: PropTypes.object,
    customer: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    card: PropTypes.object.isRequired,
    purchaseSuccess: PropTypes.bool.isRequired,
    purchaseFailure: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    actionLoading: false,
    pendingInvoiceItems: [],
    currentCampaigns: null,
    upcomingCampaigns: null,
  }

  state = {
    purchaseCount: 1,
    selectedCampaign: null,
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    const { socialActionsActions, customer, currentCampaign } = this.props

    await socialActionsActions.resetPurchaseStore()
    await socialActionsActions.fetchPendingInvoiceItems()
    await AppDispatcher.loadOnce({
      model: 'customer',
      endpoint: '/customer',
      data: customer,
    })

    let selectedCampaign
    if (currentCampaign.id && !currentCampaign.hasStarted) {
      selectedCampaign = currentCampaign.id
    }

    this.setState({ selectedCampaign })
  }

  handleCampaignChange = selectedCampaign => {
    this.setState({ selectedCampaign })
  }

  handleCountChange = e => {
    this.setState({ purchaseCount: e.target.value })
  }

  handlePurchase = async quantity => {
    const {
      socialActionsActions: { purchaseSocialActions, resetPurchaseStore },
      currentCampaigns,
      upcomingCampaigns,
      currentBrand,
      card,
      customer,
    } = this.props
    const { selectedCampaign } = this.state

    await resetPurchaseStore()

    let { selectedSource, creditCards } = customer
    const selectedCardDetails = creditCards.find(c => c.id === selectedSource) || { isNY: false, taxRate: 0}

    if (card.adding) {
      selectedSource = await CardDispatcher.updateBilling()
    }

    const activeCampaigns = currentCampaigns.campaigns.concat(
      upcomingCampaigns.campaigns
    )
    const targetCampaign = await activeCampaigns.find(
      campaign => campaign.id === selectedCampaign
    )
    const { partnership_invite_id } = targetCampaign.partners[currentBrand.id]

    const taxApplicable = card.adding ? currentBrand.isNY : selectedCardDetails.isNY
    const taxRate = card.adding ? currentBrand.taxRate : selectedCardDetails.taxRate

    await purchaseSocialActions(
      partnership_invite_id,
      quantity,
      taxApplicable,
      selectedSource,
      taxRate
    );
  }

  closeModal = () => {
    BrowserHistoryDispatcher.goBack()
  }

  render() {
    const { purchaseCount, selectedCampaign } = this.state
    const {
      socialActionsActions,
      actionLoading,
      currentBrand,
      pendingInvoiceItems,
      customer,
      purchaseSuccess,
      purchaseFailure,
      card,
      currentCampaigns,
      upcomingCampaigns,
      error,
    } = this.props

    if (purchaseSuccess) BrowserHistoryDispatcher.push('/social-action-purchase-success')

    const socialActionsPrice = 99
    const displayValue = purchaseCount * socialActionsPrice
    const availableCredit = pendingInvoiceItems
      ? pendingInvoiceItems.reduce((sum, item) => {
        sum += item.amount / 100
        return sum
      }, 0)
      : 0
    const subtotal = Math.max(displayValue + availableCredit, 0)

    // Figure out if user is adding new card or using saved card
    // Then lookup the right place for the NY Tax info
    const { selectedSource, creditCards = [] } = customer
    const selectedCardDetails = creditCards.find(c => c.id === selectedSource) || {}
    const lookupLocation = card.adding ? currentBrand : selectedCardDetails 
    const tax = lookupLocation.isNY ? subtotal * Number(lookupLocation.taxRate) : 0
    const total = subtotal + tax
    const loadingData = actionLoading
      || customer.loading
      || card.loading
      || !currentCampaigns.loaded
      || !upcomingCampaigns.loaded

    return (
      <Fragment>
        {loadingData && !purchaseFailure && <Spinner needsBackground />}
        <div className={FullscreenModalTheme.purchaseContainer}>
          <div className={FullscreenModalTheme.main}>
            <div className={FullscreenModalTheme.socialActionsHeaderMain}>
              <div className={FullscreenModalTheme.purchaseCloseButton}>
                <img
                  src="/images/icons/close.svg"
                  onClick={this.closeModal}
                />
              </div>
              <div>
                <div className={SocialTheme.subtitleContainer}>
                  <h3 className={SocialTheme.upsellHeadline}>
                    Our upgraded plans give you additional options to
                    advertise to campaign entrants. Plans start at $199.
                  </h3>
                  <a href="/app/upgrade-plan">
                    <Button
                      theme={ButtonTheme}
                      className={ButtonTheme.blueButton}
                      label="View Upgrade Options"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className={SocialTheme.cardsContainer}>
              <div className={SocialTheme.column}>
                <H3>Confirm Purchase Details</H3>
                <h3 className={SocialTheme.orangeHeader}>Review Items</h3>
                <div className={SocialTheme.socialActionsContainer}>
                  <H6 ink>Choose Campaign</H6>
                </div>
                <SocialActionsDropdown
                  auto
                  handleCampaignChange={this.handleCampaignChange}
                  value={selectedCampaign}
                  currentCampaigns={currentCampaigns.campaigns}
                  upcomingCampaigns={upcomingCampaigns.campaigns}
                  theme={DropDownTheme}
                />
                <div className={SocialTheme.socialActionsContainer}>
                  <H6 ink>Social Actions</H6>
                </div>
                <input
                  onChange={this.handleCountChange}
                  name="purchaseCount"
                  value={purchaseCount}
                  className={SocialTheme.socialActionsInput}
                />
                <br />
                <h3 className={SocialTheme.orangeHeader}>
                  Payment Information
                </h3>
                <div className={SocialTheme.cardContainer}>
                  <ChooseCard resetStoreError={socialActionsActions.resetError} />
                  {purchaseFailure && (
                    <div className={SocialTheme.cardErrors}>{error}</div>
                  )}
                </div>
              </div>
              <div className={SocialTheme.column}>
                <div className={SocialTheme.upgradePlanContainer}>
                  <div className={SocialTheme.orderSummary}>
                    <H4 ink>
                      <em>Order Summary</em>
                    </H4>
                  </div>
                  <div className={SocialTheme.orderSummaryDetail}>
                    <div className={SocialTheme.upgradeRowWhite}>
                      <P>
                        {`Social Actions ${
                          purchaseCount === 0
                            ? ''
                            : `x ${purchaseCount} qty.`
                        }`}
                      </P>
                      <P>{`${currencyFormat(displayValue)}`}</P>
                    </div>
                    <div className={SocialTheme.upgradeRowWhite}>
                      <P>Available Credit</P>
                      <P>{currencyFormat(availableCredit)}</P>
                    </div>
                    {lookupLocation.isNY && (
                      <div className={SocialTheme.upgradeRowWhite}>
                        <P>Sales taxes for NY residents</P>
                        <P>{currencyFormat(tax)}</P>
                      </div>
                    )}
                  </div>
                  <div className={SocialTheme.upgradeRowWhite}>
                    <p className={SocialTheme.totalText}>Total</p>
                    <p className={SocialTheme.totalText}>
                      {currencyFormat(total)}
                    </p>
                  </div>
                </div>
                <div className={SocialTheme.buttonGroup}>
                  <Button
                    onClick={this.closeModal}
                    theme={ButtonTheme}
                    className={ButtonTheme.whiteButtonAzureText}
                    label="Cancel"
                  />
                  <Button
                    onClick={() => this.handlePurchase(purchaseCount)}
                    theme={ButtonTheme}
                    className={ButtonTheme.blueButton}
                    label="Purchase"
                    style={{ marginLeft: '16px' }}
                    disabled={!purchaseCount || !selectedCampaign}
                    primary
                    raised
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseSocialActionsModal)
