import React, { PureComponent as Component } from 'react'
import { array } from 'prop-types'
import PartnershipAnalyticsCard from 'visual-components/analytics/partnerships/PartnershipAnalyticsCard'

class PartnershipAnalyticsGrid extends Component {
  static propTypes = {
    partnerships: array.isRequired,
  }

  render() {
    const {
      partnerships,
    } = this.props

    const results = partnerships.map(partnership => {
      const partnershipMapped = {
        partnershipBrandName: partnership.partner_brand_name,
        lastPartnershipDate: partnership.last_partnered_date,
        avgEntries: partnership.avg_entries,
        listSize: partnership.dedicatedListSize,
        campaigns: partnership.num_campaigns,
        entriesDriven: partnership.total_entries_driven,
        visitsDriven: partnership.total_visits_driven,
        newToFile: partnership.total_ntf_driven,
        partnerBrandId: partnership.partner_brand_id,
      }

      return <PartnershipAnalyticsCard {...partnershipMapped} key={partnershipMapped.partnerBrandId} />
    })

    return (
      <div style={{ width: '752px' }}>
        { results }
      </div>
    )
  }
}

export default PartnershipAnalyticsGrid
