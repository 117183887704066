import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import BarGraph from 'visual-components/util/graphs/bar-graph'
import PercentDisplay from '../util/percent-display'
import BarGraphHeader from '../util/bar-graph-header'


class YourMinEntryThreshholdGraph extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    live: PropTypes.bool,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const {
      campaign: {
        currentBrand,
      },
      live,
    } = this.props

    const minEntryRequired = currentBrand.minimum_delivery
    const entries = currentBrand && currentBrand.entries_preWebula
      ? currentBrand.entries_preWebula
      : currentBrand.entries

    const x = entries / minEntryRequired * 100
    const percentage = Math.min(100, Math.round(x))

    return (
      <div>
        <BarGraphHeader live={live} text="Minimum Entry Requirement" />
        <div style={{ display: 'inline-block' }}>
          <BarGraph live={live} progress={percentage} />
        </div>
        <PercentDisplay live={live} percentage={percentage} />
      </div>
    )
  }
}

export default YourMinEntryThreshholdGraph
