import React from 'react'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import { P } from 'visual-components/util/texts'

class ShareAgreementDialog extends React.Component {
  state = {
    section: 'share'
  }

  share = () => {
    const { partnershipAgreement, actions } = this.props

    this.setState({
      section: 'confirm'
    })

    actions.shareAgreement(partnershipAgreement.id)
  }

  render() {
    const { partnershipAgreement, closeModal, active } = this.props
    const { section } = this.state

    if (!partnershipAgreement.agreement.id) {
      return null
    }

    const { invitee: { accountname } } = partnershipAgreement

    return (
      <div>
        {
          section === 'share' ?
          <DefaultDialog active={ active }
            closeModal={ closeModal }
            title={ 'Share Agreement?' }
            confirm={ this.share }
            confirmText={ 'Share' }
            cancel={ closeModal }
            cancelText={ 'Cancel' }
            children={
              <div>
                <div style={{
                  marginBottom: '24px'
                }}>
                  <P multiline>
                    Sharing your agreement with {accountname} will allow them to review the document, confirming their commitments. You can further discuss and adjust the terms if necessary before signing.
                  </P>
                </div>

                <P multiline>
                  Please note that <em>this agreement cannot be edited once it has been signed by {accountname}</em>.
                </P>
              </div>
            }
          />
          :
          <DefaultDialog active={ active }
            closeModal={ closeModal }
            title={ 'Agreement Shared!' }
            confirm={ closeModal }
            confirmText={ 'Got It' }
            children={
              <div>
                <P multiline>
                  { `Your agreement has been shared successfully! ${accountname} can now review and sign it, or further discuss terms with you if needed. Once they've signed, we'll return to you for signature and they will be confirmed as a Partner.` }
                </P>
              </div>
            }
          />
        }
      </div>
    )
  }
}

export default ShareAgreementDialog
