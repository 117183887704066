import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'react-toolbox/lib/button'

import buttonTheme from 'css/themes/Buttons.css'

class ModalButtonGroup extends React.Component {
  render() {
    const {
      cancel,
      confirm,
      remove,
      confirmText,
      cancelText,
      removeText,
      canSave,
      hideLine,
      bottomPad,
    } = this.props

    return (
      <div>
        {
          !hideLine
          && (
            <div style={{
              height: '1px',
              marginRight: '-32px',
              marginLeft: '-32px',
              backgroundColor: '#dee4e5',
            }}
            />
          )
        }

        <div style={{
          marginTop: '16px',
          marginBottom: bottomPad ? '16px' : '0',
          display: 'flex',
        }}
        >
          {
            !!remove
            && (
              <Button
                onClick={remove}
                theme={buttonTheme}
                label={removeText}
                flat
                primary
                style={{
                  color: '#FF3B2A',
                  marginLeft: 0,
                }}
              />
            )
          }
          {
            !!cancel
            && (
              <Button
                onClick={cancel}
                theme={buttonTheme}
                label={cancelText}
                flat
                primary
                style={{
                  marginRight: confirm ? '16px' : '0',
                  marginLeft: 'auto',
                }}
              />
            )
          }

          {
            !!confirm
            && (
              <Button
                onClick={confirm}
                className={!canSave ? `${buttonTheme.disabledButton} ${buttonTheme.noMargin}` : `${buttonTheme.blueButton} ${buttonTheme.noMargin}`}
                theme={buttonTheme}
                label={confirmText}
                flat
                disabled={!canSave}
                style={{
                  marginLeft: cancel ? 0 : 'auto',
                }}
              />
            )
          }
        </div>
      </div>
    )
  }
}

ModalButtonGroup.defaultProps = {
  confirmText: 'Save',
  cancelText: 'Cancel',
  removeText: 'Delete',
  confirm: null,
  cancel: null,
  remove: null,
}


ModalButtonGroup.propTypes = {
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  remove: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  removeText: PropTypes.string,
}

export default ModalButtonGroup
