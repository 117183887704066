import React from 'react';
import { func, bool, array } from 'prop-types';
import COLOURS from 'util/colours';
import { withRouter } from 'react-router-dom'

import { Collapse } from 'react-collapse';
import ClosedQuestBar from './closed-quest-bar';
import OpenQuestBarContent from './open-quest-bar-content';

import QuestStyles from 'css/themes/QuestStyles.css';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import AppDispatcher from 'dispatchers/app-dispatcher';
import * as ReactRedux from 'react-redux';
import BrandDetailDispatcher from 'dispatchers/brand-detail-dispatcher';
import moment from 'moment-timezone'

const hasInterest = (interests) => {
  const types = [
    'contentSwaps',
    'dedicatedEmailBuys',
    'dedicatedEmailSales',
    'insertSwaps',
    'offerPrizing',
    'sweepstakes'
  ]

  return types.some(interestType => {
    let interest = interests ? interests[interestType] : {}

    return interest.active && Object.keys(interest).some(key => {
      return key !== 'id' && !!interest[key]
    })
  })
}

const mapStateToProps = (state) => {
  return state;
};

class QuestCollapse extends React.Component {
  static propTypes = {
    collapseFunction: func,
    shouldShow: bool,
    avoidRoutes: array,
  }

  static defaultProps = {
    avoidRoutes: [],
  }

  componentDidMount() {
    BrandDetailDispatcher.loadPartnershipInterests( this.props.currentBrand.id );
  }

  toggleCardOpen = () => {
    var {
      currentBrand
    } = this.props

    this.props.dispatch({
      model: 'currentBrand',
      data: {
        questOpen: !currentBrand.questOpen
      },
      type: 'UPDATE_ATTR'
    })
  }

  shouldRender = () => {
    const { location, avoidRoutes } = this.props
    const { pathname } = location

    for (let i = 0; i < avoidRoutes.length; i++) {
      if (pathname.includes(`/${avoidRoutes[i]}`)) return false
    }

    return true
  }

  render() {
    var {
      currentBrand,
      profile,
      conversations,
      customer,
      brand,
      history
    } = this.props;

    if (!brand.interestLoaded || (brand.id && brand.id !== currentBrand.id)) {
      return <div></div>
    }

    const shouldRender = this.shouldRender()

    if (!shouldRender) return null

    const handles = [
      'facebookUrl',
      'instagramHandle',
      'pinterestHandle',
      'twitterHandle',
      'youtubeHandle'
    ]

    const hasSocial = handles.some(handle => {
      return currentBrand.social[handle]
    })

    const shouldShow = !profile.questCompleteConfirm || profile.showOldQuest;// will be inherited from their status of the quests

    const profilePath = `/explore/brands/${currentBrand.id}`

    const atProfile = this.props.history.location.pathname.includes(profilePath)

    const atBuilder = this.props.history.location.pathname.includes('builder')
    const newCampaign = this.props.history.location.pathname.includes('new-campaign')
     || this.props.history.location.pathname.includes('new-solo-sweepstakes')

    const listReady = currentBrand.dedicatedListSize >= 1000 ||
      (moment(currentBrand.listLastUpdatedAt).diff(moment(currentBrand.createdAt),'minutes') > 5 && currentBrand.dedicatedListSize >= 100)

    if (atBuilder || newCampaign) {
      return <div></div>
    }
    const quests = [
      {
        title: 'Add Logo',
        text: 'Upload a square 200 x 200px or larger PNG or JPG image',
        number: 1,
        wide: true,
        completed: currentBrand.logo && !currentBrand.logo.includes('Empty-Avatar'),
      },
      {
        title: 'Complete "About"',
        text: 'Tell the DojoMojo network a bit about your products or services',
        number: 4,
        wide: true,
        completed: currentBrand.description && currentBrand.description.length >= 50,
      },
      {
        title: 'Complete “Interested In”',
        text: 'Let brands know what you’re looking for',
        number: 2,
        wide: true,
        completed: true,
      },
      {
        title: 'Add Cover Photo',
        text: 'Upload an 864 x 320px PNG or JPG image',
        number: 5,
        wide: true,
        completed: !!currentBrand.facebookCoverPhoto,
      },
      {
        title: 'Add List Size',
        text: 'Share your email list size and update it as your audience grows',
        number: 3,
        wide: true,
        completed: listReady,
      },
      {
        title: 'Add Social Handles',
        text: 'Show off your social following by adding your social handles',
        wide: true,
        number: 6,
        completed: hasSocial,
      },
    ]

    const completedQuestsCount = quests.filter( quest => quest.completed === true ).length;
    const isOpened = atProfile && brand.id === currentBrand.id && currentBrand.questOpen;

    if (completedQuestsCount === quests.length) {
      return <div></div>
    }

    return(
      <div className={isOpened ? '' : QuestStyles.collapsed} style={{
        backgroundColor: COLOURS.foggy
      }}>
        <div style={{
        }}>
          <ClosedQuestBar
            collapseFunction={ this.toggleCardOpen }
            isOpened={ isOpened }
            atProfile={atProfile}
            goToProfile={ () => {
              history.push(`/explore/brands/${currentBrand.id}`)
            } }
            completedQuestsCount={ completedQuestsCount }
            quests={ quests }
          />
          <div style={{
            width: '1096px',
            margin: 'auto'
          }}>
            <Collapse isOpened={ isOpened }>
              <OpenQuestBarContent
                quests={ quests }
              />
            </Collapse>
          </div>
        </div>
      </div>
    )

  };
}

export default withRouter(ReactRedux.connect(mapStateToProps)(QuestCollapse));
