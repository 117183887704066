
import moment from 'moment-timezone';
import _ from 'lodash'

var model = 'admin';

var defaultState =  {
  rid: model,
  section: 'summary',
  endDate: moment().startOf('isoweek')._d,
  cache: [],
  interval: 'week',
  presentation: null,
  loaded: false,
  periods: 12
}

var AdminReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_ATTR: function () {
      return _.merge({}, state, action.data, { loaded: false });
    },
    LOAD: function () {
      if (action.data.interval == 'month') {
        action.data.dates = _.map(action.data.dates, (date)=> {
          return moment(date).subtract(1, 'days').format("YYYY-MM-DD")
        })
        action.data.dates.push(moment(state.endDate).subtract(1, 'days').format("YYYY-MM-DD"))
      } else {
        action.data.dates.push(state.endDate)
      }

      action.data.dates.shift();
      return _.extend({}, state, {
        presentation: action.data,
        loading: false,
        loaded: true
      });
    },
    LOAD_RAW: function () {
      var raw = _.clone(state.cache);

      raw.push(action.data);

      return _.extend({}, state, {
        cache: raw
      });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default AdminReducer;
