import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import BarGraph from 'visual-components/util/graphs/bar-graph'
import PercentDisplay from '../util/percent-display'
import BarGraphHeader from '../util/bar-graph-header'

class EntryRateGraph extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    live: PropTypes.bool,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const { campaign } = this.props
    const {
      currentBrand = {},
      totalEntries = currentBrand.total_entries_delivered,
      totalVisits,
    } = campaign

    const {
      live,
    } = this.props

    const percentage = totalEntries && totalVisits
      ? Math.round((totalEntries / totalVisits) * 100)
      : 0

    return (
      <div>
        <BarGraphHeader live={false} text="ENTRY RATE" />
        <div style={{ display: 'inline-block' }}>
          <BarGraph live={live} progress={percentage} />
        </div>
        <PercentDisplay percentage={percentage} />
      </div>
    )
  }
}

export default EntryRateGraph
