import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from 'visual-components/util/texts';
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import injectSheet from 'react-jss'

const transitions = {
  content: {
    width: '440px',
    margin: 'auto',
    padding: '12px 5px 0 5px',
    marginTop: '25px',
    textAlign: 'center',
  },
  button: {
    marginTop: '24px',
  },
}

class NoExploreResults extends React.Component {
  static propTypes = {
    actionLabel: PropTypes.string,
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
    showPartnershipButton: PropTypes.bool,
    createCampaign: PropTypes.func,
  }

  static defaultProps = {
    actionLabel: 'Create Campaign',
    error: '',
    showPartnershipButton: false,
    createCampaign: () => {},
  }

  render() {
    const {
      classes: css,
      error,
      showPartnershipButton,
      createCampaign,
      actionLabel,
    } = this.props

    return (
      <div className={css.content}>
        <H2 multiline cloudGrey>
          { error }
        </H2>
        {
          showPartnershipButton
          && (
            <div className={css.button}>
              <Button
                onClick={createCampaign}
                className={`${ButtonTheme.blueButton} ${ButtonTheme.noMargin}`}
                theme={ButtonTheme}
                label={actionLabel}
                flat
              />
            </div>
          )
        }
      </div>
    );
  }
}

export default injectSheet(transitions)(NoExploreResults)
