import React, { PureComponent } from 'react'
import { P, H6 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import injectSheet from 'react-jss'
import moment from 'moment-timezone'
import d3 from 'd3'
import COLOURS from 'util/colours'
import Badge from 'visual-components/util/badge'
import { string } from 'prop-types'

const userTimezone = moment.tz.guess()
const offset = moment.tz(userTimezone).utcOffset()

const getTransactionDate = transaction => {
  const { type, txn, proposal } = transaction
  if (type === 'insertionOrder') {
    return moment.tz(proposal.created_at, userTimezone).format('ll')
  }
  return txn ? moment.tz(txn.created * 1000, userTimezone).format('ll') : 'Pending'
}

const getTransactionAmount = transaction => {
  const {
    type, txn, totalQuantity, price, insertionOrderSide, proposal,
  } = transaction
  if (type === 'insertionOrder') {
    return insertionOrderSide === 'seller' ? proposal.max_value : proposal.max_value * -1
  }
  const amount = txn ? txn.amount / 100 : totalQuantity * price
  const totalAmount = 
    txn.object === 'charge'
      ? amount * -1
      : amount
  return totalAmount
}

const languageMap = {
  charge: {
    partner: 'Seller',
    moneyVerb: 'Paid With',
    detailsName: 'Purchase',
    receiptName: 'Invoice',
    receiptId: 'buyer_invoice_id',
    moneyName: 'Cost',
    hasFee: false,
    invoiceUrl: chId => (chId ? `/invoice/ch/${chId}` : '/invoice/purchase'),
  },
  refund: {
    partner: 'Seller',
    moneyVerb: 'Refunded To',
    detailsName: 'Refund',
    receiptName: 'Invoice',
    receiptId: 'buyer_refund_invoice_id',
    moneyName: 'Refund',
    hasFee: false,
    invoiceUrl: () => '/invoice/refund',
  },
  transfer: {
    partner: 'Purchaser',
    moneyVerb: null,
    detailsName: 'Payout',
    receiptName: 'Receipt',
    receiptId: 'seller_invoice_id',
    moneyName: 'Revenue',
    hasFee: true,
    invoiceUrl: () => '/invoice/payout',
  },
  pending: {
    partner: 'Purchaser',
    moneyVerb: 'Will Pay To',
    detailsName: 'Payout',
    receiptName: 'Receipt',
    receiptId: 'seller_invoice_id',
    moneyName: 'Potential Revenue',
    hasFee: true,
    invoiceUrl: () => null,
  },
  insertionOrder: {
    moneyVerb: null,
    detailsName: 'Insertion Order',
    receiptName: 'Insertion Order',
    invoiceUrl: () => '/invoice/io'
  },
}

languageMap.payout = { ...languageMap.transfer, moneyVerb: 'Paid To' }

const styles = {
  common: {
    paddingTop: 20,
    verticalAlign: 'top',
  },
  alignRight: {
    textAlign: 'right',
  },
  paddingLeft16: {
    paddingLeft: 16,
  },
  paddingLeft36: {
    paddingLeft: 36,
  },
  descriptionRow: {
    marginTop: 8,
  },
  italicP: {
    fontStyle: 'italic',
  },
  greenText: {
    color: COLOURS.seaGreen,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      display: 'inline-block',
    },
    '& > div': {
      display: 'inline-block',
      marginLeft: 8,
    }
  }
}

class TransactionSubItem extends PureComponent {
  static contextTypes = {
    impersonating: string,
  }

  render() {
    const { classes: css, transaction, currentBrandId } = this.props
    const {
      accountNumber, id, type, chId, txnId, proposal,
    } = transaction
    const { impersonating } = this.context

    const date = getTransactionDate(transaction)
    const transactionAmount = getTransactionAmount(transaction)
    const language = languageMap[type in languageMap ? type : 'charge']

    return (
      <tr>
        <td className={`${css.common} ${css.paddingLeft16}`}>
          <P>{date}</P>
        </td>
        <td className={`${css.common} ${css.paddingLeft36}`}>
          <div className={css.heading}>
            <P>{`${language.detailsName} - ${id}`} </P>
            {type === 'insertionOrder' && !proposal.current && <div><Badge label={ 'Invalid' } color={COLOURS.stone}/></div>}
          </div>
          {language.moneyVerb && (
            <div className={css.descriptionRow}>
              <P>
                <em>{`${language.moneyVerb} `}</em>
                {`: ${accountNumber}`}
              </P>
            </div>
          )}
          <a
            href={`${language.invoiceUrl(chId)}/${currentBrandId}/${
              offset
            }/${txnId || proposal && proposal.id}/${id}.pdf${impersonating ? `?impersonating=${impersonating}` : ''}`}
            target="_blank"
          >
            <Button
              flat
              primary
              theme={buttonTheme}
              style={{
                marginLeft: '-8px',
              }}
            >
              Download {language.receiptName}
            </Button>
          </a>
        </td>
        <td className={`${css.common} ${css.alignRight}`}>
          { type === 'insertionOrder' ?
            <P><span className={css.italicP}>{d3.format('$,.2f')(transactionAmount)}</span></P>
            :
            <H6><span className={transactionAmount > 0 ? css.greenText : null}>{d3.format('+$,.2f')(transactionAmount)}</span></H6>
          }
        </td>
        <td />
        <td />
      </tr>
    )
  }
}

export default injectSheet(styles)(TransactionSubItem)
