import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card } from 'react-toolbox/lib/card'
import FontIcon from 'react-toolbox/lib/font_icon'
import { object, string } from 'prop-types'
import moment from 'moment-timezone'
import { H4, Small } from 'visual-components/util/texts'
import AssetsTheme from 'css/themes/media-market/Assets.css'
import * as AssetActions from 'actions/asset-actions'

import AssetsHeaderNav from './assets-header-nav'
import AssetsImagesWindow from './assets-images-window'
import AssetsFilesWindow from './assets-files-window'
import AssetsLinksWindow from './assets-links-window'

const userTimezone = moment.tz.guess()
const offset = moment.tz(userTimezone).utcOffset()

const mapStateToProps = ({
  deals: { deal }, dealAssets, dealCollaboration, currentBrand,
}) => ({
  deal,
  dealAssets,
  dealCollaboration,
  currentBrand,
})

const mapDispatchToProps = dispatch => ({
  assetActions: bindActionCreators(AssetActions, dispatch),
})

class Assets extends Component {
  static propTypes = {
    dealAssets: object.isRequired,
    assetActions: object.isRequired,
    dealCollaboration: object.isRequired,
    currentBrand: object.isRequired,
    deal: object,
  }

  static defaultProps = {
    deal: {},
  }

  static contextTypes = {
    impersonating: string,
  }

  generateFiles = files => {
    const {
      deal: { currentProposal, previousProposals },
      currentBrand: { id: currentBrandId },
    } = this.props
    const { impersonating } = this.context

    // Builds "ghost" files for all proposal insertion orders, which are not
    // stored as "real" files, but rather links that generate PDFs on the fly
    // and thus aren't available on the 'dealAssets' object
    const allProposals = [currentProposal, ...previousProposals]
    const currAssetIds = files.map(asset => asset.id)
    allProposals.forEach(proposal => {
      const {
        id, insertion_order_id, current, created_at,
      } = proposal

      // Prevents creating the insertion order more than once
      if (currAssetIds.includes(insertion_order_id)) return

      files.push({
        created_at,
        id: insertion_order_id,
        ioPath: `invoice/io/${currentBrandId}/${offset}/${id}/${insertion_order_id}${
          impersonating ? `?impersonating=${impersonating}` : ''
        }`,
        invalid: !current,
        type: 'pdf',
        src: 'images/icons/receiver-asset-pdf-icon.png',
      })
    })

    // Sorts files from most recent to least
    return files.sort((proposal1, proposal2) =>
      (proposal1.created_at <= proposal2.created_at ? 1 : -1))
  }

  renderUnread() {
    const {
      deal: { unread_count },
    } = this.props
    if (!unread_count) return null
    const { attachments = 0, links = 0, attachmentComments = {} } = unread_count
    const commentTotal = Object.keys(attachmentComments).reduce(
      (sum, key) => sum + attachmentComments[key],
      0
    )
    if (attachments + links + commentTotal === 0) return null
    return <FontIcon value="lens" className={AssetsTheme.unreadBadge} />
  }

  render() {
    const {
      deal: { currentProposal, previousProposals, unread_count },
      dealAssets: { assets, selectedTab, dealId },
      dealCollaboration: { uniqueLinks },
      assetActions: { switchAssetTab },
      currentBrand: { id: currentBrandId },
    } = this.props

    const windowComponent = {
      images: (
        <AssetsImagesWindow
          assetsByType={assets.images || []}
          unreadCount={unread_count}
          dealId={dealId}
        />
      ),
      files: (
        <AssetsFilesWindow
          assetsByType={this.generateFiles(assets.files) || []}
          currentBrandId={currentBrandId}
          currentProposal={currentProposal}
          previousProposals={previousProposals}
        />
      ),
      links: <AssetsLinksWindow uniqueLinks={uniqueLinks} />,
    }

    return (
      <Card className={AssetsTheme.container}>
        <div className={AssetsTheme.titleAndAction}>
          <div className={AssetsTheme.title}>
            <H4 coral>
              <em>Assets</em>
              {this.renderUnread()}
            </H4>
          </div>

          {(assets.files && assets.files.length) || (assets.images && assets.images.length) ? (
            <div
              className={AssetsTheme.downloadButton}
              onClick={() => {
                window.open(`/deals/attachment-zip/${dealId}/${currentBrandId}/download`)
              }}
            >
              <Small azure>
                <em>Download All</em>
              </Small>
            </div>
          ) : null}
        </div>

        <AssetsHeaderNav selected={selectedTab} selectTabFunc={switchAssetTab} />

        <div>{windowComponent[selectedTab]}</div>
      </Card>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Assets)
