import React, { Component } from 'react'
import { string, array } from 'prop-types'
import Col from 'visual-components/util/no-padding-grid/col'
import { H1, H3, H6 } from 'visual-components/util/texts'
import styles from './overview-bar.css'

const {
  metricsContainer,
  specificDealAnalyticsBar,
  specificDealAnalyticsBarLeft,
  specificDealAnalyticsBarRight,
} = styles

class SpecificDealOverviewBar extends Component {
  static propTypes = {
    metrics: array.isRequired,
    title: string.isRequired,
    position: string.isRequired,
  }

  render() {
    const { title, position } = this.props
    let { metrics } = this.props

    const style = position === 'left' ? specificDealAnalyticsBarLeft
      : position === 'right' ? specificDealAnalyticsBarRight : null

    metrics = metrics
      .slice(0, 4) // ensure there are only four
      .map(({ label, value }) => (
        <div key={label}>
          <H1 white>
            { value }
          </H1>
          <H6 white style={{ marginTop: '4px' }}>
            {label}
          </H6>
        </div>
      ))

    return (
      <Col className={`${specificDealAnalyticsBar} ${style}`}>
        <H3 coral>
          <small>
            {title}
          </small>
        </H3>
        <div className={metricsContainer}>
          {metrics}
        </div>
      </Col>
    )
  }
}

export default SpecificDealOverviewBar
