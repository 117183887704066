import React from 'react'
import COLOURS from 'util/colours'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H3, P } from 'visual-components/util/texts'

class CampaignOptionRow extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="row">
        <div
          style={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '2px',
            borderColor: '#b7c3cd',
            cursor: this.props.disabled ? 'not-allowed ' : 'default',
            opacity: this.props.disabled ? '0.4' : '1',
            display: 'flex',
            alignItems: 'center',
            width: '600px',
            padding: '40px',
          }}
        >
          <div className="col-sm-12">
            <H3 multiline>{this.props.title}</H3>

            <div style={{ padding: '16px 0px 24px 0px' }}>
              <P multiline>{this.props.optionText}</P>
            </div>

            {this.props.isConfirmButton ? (
              <div style={{ float: 'left', marginTop: '-16px' }}>
                <ModalButtonGroup
                  hideLine
                  canSave
                  confirmText={this.props.confirmText}
                  confirm={this.props.onclick}
                />
              </div>
            ) : (
              <a onClick={this.props.onclick}>{this.props.confirmText}</a>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignOptionRow
