import React from 'react'
import COLOURS from 'util/colours'
import { Link } from 'react-router-dom'
import appbarTheme from 'css/themes/homePage/appbarTheme.css'

import AboutUsPageItemLi from './about-us-page-item-li'

class AboutUsDropdown extends React.Component {
  render() {
    const { lightBackground } = this.props

    return (
      <div className={appbarTheme.dropdownWrapper}>
        <div
          className={appbarTheme.featureLink}
          style={{
            color: lightBackground ? COLOURS.ink : COLOURS.white,
          }}
        >
          About Us
        </div>
        <div
          style={{
            position: 'relative',
          }}
        >
          <ul className={appbarTheme.dropdown}>
            <AboutUsPageItemLi
              to="/app/about-us"
              title="About Us"
              description="Learn the story of why we created DojoMojo"
            />
            {/* TODO: Disabled as of 11/30/2021 */}
            {/* <AboutUsPageItemLi
              to="/app/meet-the-team"
              title="Team"
              description="Meet the talented people behind the scenes"
            /> */}
          </ul>
        </div>
      </div>
    )
  }
}

export default AboutUsDropdown
