import React, { PureComponent as Component } from 'react'
import {
  func,
  array,
  string,
  any,
} from 'prop-types'

import Dropdown from 'react-toolbox/lib/dropdown'

import DateRangeTheme from 'css/themes/dateRangeSortWithDisplay.css'
import { P } from 'visual-components/util/texts'

class DateRangeSortWithDisplay extends Component {
  static propTypes = {
    onChange: func.isRequired,
    source: array.isRequired,
    name: string.isRequired,
    value: any.isRequired,
  }

  customItem = item => {
    /* this breaks out of the normal loop, allowing for customization of the chosen value */
    return (
      <div className={DateRangeTheme.customItem}>
        { item.label }
      </div>
    )
  }

  render() {
    const {
      onChange,
      source,
      name,
      value,
    } = this.props

    return (
      <div style={{ height: '24px', display: 'inline' }}>
        <div style={{ display: 'inline-block' }}>
          <P>
            <em>
              Date Range:
            </em>
          </P>
        </div>
        <div style={{ display: 'inline-block', height: '14px' }}>
          <Dropdown
            onChange={val => { onChange(name, val) }}
            source={source}
            value={value}
            theme={DateRangeTheme}
            template={this.customItem}
            auto
          />
        </div>
      </div>
    )
  }
}

export default DateRangeSortWithDisplay
