
import FieldDispatcher from 'dispatchers/field-dispatcher';
import { validate } from 'email-validator';
import moment from 'moment-timezone'
import _ from 'lodash'

var capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Validator = {

  IsNumber: function (value, model, callback) {
    if (isNaN(value)) {
      value = 0;
    }
    callback();
  },

  validateForm: function (items, dataModel, formModelKey, callback) {
    if (typeof dataModel === 'string') {
      dataModel = store.getState()[dataModel];
    }
    var isValid = true;
    var done = _.after(Object.keys(items).length + 1, function () {
      callback(isValid)
    });

    done();

    _.each(items, (validations, key) => {
      var val = dataModel[key];
      this.validate.call({}, val, dataModel, validations, function (data) {
        if (data.errors.length) {
          isValid = false;
        }
        var formModelData = {};
        formModelData[key] = data;
        FieldDispatcher.updateAttr( formModelKey, formModelData);
        done();
      })
    })
  },

  //alternative validateForm, only difference being that each field (items) has its own model to store its results, and only stores status/errors in the Redux form model
  validateFormAlt: function (items, dataModel, formModelKey, callback) {
    let ValidatorModel = function (){
      return this;
    }
    ValidatorModel.prototype = this;

    var isValid = true;
    var done = _.after(Object.keys(items).length + 1, function () {
      callback(isValid)
    });

    done();

    _.each(items, (validations, key) => {
      var val = dataModel[key];
      var Validator = new ValidatorModel();
      Validator.validate(val, dataModel, validations, function (data) {
        if (data.errors.length) {
          isValid = false;
        }
        var formModelData = {};
        formModelData[key] = _.pick(data, ['errors', 'valid']);
        FieldDispatcher.updateAttr( formModelKey, formModelData);
        done();
      })
    })
  },

  emailTaken: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }

    var that = this;

    $.ajax({
      method: 'GET',
      url: '/register/email-available/' + value,
      error: function () {
        that.errors.push(value + ' has been taken.');
        callback();
      },
      success: callback
    });
  },

  validDomain: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }

    var that = this;

    $.ajax({
      method: 'GET',
      url: '/register/valid-domain/' + value,
      error: function () {
        that.errors.push(`Please contact our support team: support@dojomojo.com`);
        callback();
      },
      success: callback
    });
  },

  redirectUrl: function (value, model, callback) {
    if (!value) {
      return callback();
    }
    var that = this;
    $.ajax({
      url: '/brand-redirect',
      contentType: 'application/json',
      data: JSON.stringify({
        redirectURL: value
      }),
      method: 'POST',
      success: callback,
      error: function () {
        console.log('et');
        that.errors.push(value + ' does not forward to dns.dojomojo.com');
        callback();
      }
    });
  },

  required: function (value, model, callback) {
    if (!value || !value.toString().trim()) {
      this.errors.push('This Field Is Required');
    }
    callback();
  },

  maxLength: function (max, errorMessage = '') {
    return function (value, model, callback) {
      const invalid = value && value.length > max;
      if (invalid) {
        this.errors.push(errorMessage);
      }
      callback(!invalid);
    }
  },

  NeedLogo: function (value, model, callback) {
    if (!value) {
      this.errors.push('This Field Is Required');
    }
    callback();
  },

  IsInteger: function (value, model, callback) {
    var float = parseFloat(value);

    if (float % 1 == 0) {
      callback(true)
    } else {
      this.errors.push('Clicks must be a whole number');
      callback(false);
    }
  },

  positive: function (value, model, callback) {
    var parsedVal = parseFloat(value);

    if (parsedVal > 0) {
      callback(true)
    } else {
      this.errors.push('Must be greater than Zero');
      callback(false);
    }
  },

  IsWebsite: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    // https://gist.github.com/dperini/729294
    var regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    if (value.match(regex)) {
      callback(true);
    } else {
      this.errors.push(LANGUAGE.formErrors.invalidDomain);
      callback(false);
    }
  },

  IsPhoneNumber: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    value = value.toString().replace(/\(|\)|-| /g, '');
    if (value.match(/^[0-9]+$/) && (value.length >= 7 && value.length <= 15)) {
      callback(true);
    } else {
      this.errors.push('Invalid phone number');
      callback(false);
    }
  },


  UniqueBrandName: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    $.ajax({
      url: '/test-unique-brand-name/' + encodeURIComponent(value),
      method: 'GET',
      success: callback,
      error: function () {
        that.errors.push(value + LANGUAGE.formErrors.companyExists);
        callback();
      }
    });
  },

  isValidReferral: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    $.ajax({
      url: '/test-valid-referral-email/' + encodeURIComponent(value),
      method: 'GET',
      success: callback,
      error: function (data) {
        that.errors.push(data.responseText);
        callback();
      }
    });
  },


  UniqueBrandDomain: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    var domain = value.trim().toLowerCase().replace('http://', '');
    domain = domain.replace('https://', '');
    domain = domain.replace('www.', '');
    domain = domain.split('/')[0];
    $.ajax({
      url: '/test-unique-brand-domain/' + domain,
      method: 'GET',
      success: callback,
      error: function () {
        that.errors.push(value + ' has been taken.');
        callback();
      }
    });
  },

  UniqueBrandDomain: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    var domain = value.trim().toLowerCase().replace('http://', '');
    domain = domain.replace('https://', '');
    domain = domain.replace('www.', '');
    domain = domain.split('/')[0];
    $.ajax({
      url: '/test-unique-brand-domain/' + domain,
      method: 'GET',
      success: callback,
      error: function () {
        that.errors.push(value + ' has been taken.');
        callback();
      }
    });
  },

  UniqueUpdateBrandDomain: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }
    var that = this;
    var domain = value.trim().toLowerCase().replace('http://', '');
    domain = domain.replace('https://', '');
    domain = domain.replace('www.', '');
    domain = domain.split('/')[0];
    $.ajax({
      url: '/test-unique-update-brand-domain/' + domain,
      method: 'GET',
      success: callback,
      error: function () {
        that.errors.push(value + ' has been taken.');
        callback();
      }
    });
  },

  isCardLength: function (value, model, callback) {
    value = value || '';
    length = value.replace(/-/g, '').length;
    if ( length < 15 || length > 16 ) {
      this.errors.push('Invalid Card');
    }
    callback();
  },

  isValidEmail: function (value, model, callback) {
    let valid = validate(value);

    if (!valid) {
      this.errors.push(value ? value + ' is an invalid email.' : '')
    }
    callback();
  },

  isCorrectPassword: function (value, model, callback) {
    var that = this;
    $.ajax({
      url: '/test-has-password',
      method: 'POST',
      data: {
        password: value
      },
      success: callback,
      error: function () {
        that.errors.push('If you do not know your current password you must log out and use the forgot password functionality at login.');
        callback();
      }
    });
  },

  isValidPassword: function (value, model, callback) {
    value = value || '';

    if (!value || !value.match(/^(?=.*[A-Z])(?=.*[!\?@#$&*+()^%])(?=.*[0-9]).*$/) || (value && value.length < 8)) {

      this.errors.push('Password is not valid');

      store.dispatch({
        model: model.rid,
        type: 'UPDATE_ATTR',
        data: {
          errors: {
            password: {
              hasErrors: true
            }
          }
        }
      });
    }
    callback();
  },

  matchPassword: function (value, model, callback) {
    var match = model.newPassword;
    if (value !== match) {
      this.errors.push('Passwords must match.');
    }
    callback();
  },

  errors: {},
  validations: {},

  resetError: function (attr) {
    this.errors[attr] = [];
  },

  addError: function (errorMessage) {
    this.valid = false;
    this.errors.push(errorMessage);
  },

  validateAttrs: function (attrs, callback) {
    var that = this;
    var done = _.after(attrs.length, function () {
      callback(_.some(attrs, function (attr) {
        return that.errors[attr].length;
      }));
    });
    _.each(attrs, attr => {
      this.validateAttr(attr, this[attr], done);
    });
  },

  validate: function (value, model, validations=[], callback) {

    this.errors = [];
    this.valid = true;
    var that = this;
    Promise.all(_.map(validations, function (validation) {
      if (Array.isArray(validation)) {
        validation = validation[0]
      }
      return new Promise(function (resolve, reject) {
        validation.call(that, value, model, resolve);
      })
    })).then(function () {
      if (that.errors.length > 0) {
        that.valid = false
      }

      callback(that)
    });
  },

  validateAndSet: function (value, model, callback) {
    this.resetError(attr);
    const validations = this.validations[attr];
    if (validations) {
      let pass = true;
      const done = _.after(validations.length, () => {
        if (pass) {
          this[attr] = value;
        }
        callback();
      });

      _.each(validations, (validation) => {
        this[validation](attr, value, function (passValidation) {
          pass = pass && passValidation ? true : false;
          done();
        }.bind(this));
      });
    }
  },
  testTwitterHandle: function (value, model, callback) {
    if (!value) {
      callback();
      return;
    }

    if (value.includes('@')) {
      this.errors.push(`Please don't include @ symbol`);
      callback();
      return;
    }

    // check for valid twitter handle
    const regex = /^[a-zA-Z0-9_]{1,15}$/;
    if (!value.match(regex)) {
      this.errors.push('Please enter a valid Twitter handle');
      callback();
    }
  },
  testInstagramHandle: function (value, model, callback) {
    if (!value) return callback()

    // https://regexr.com/3cg7r
    const regex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i

    if (!value.match(regex)) {
      this.addError('Please enter a valid Instagram handle, excluding the “@” symbol')
    }

    callback()
  },
};

export default Validator;
