import React from 'react'
import axios from 'axios'
import Spinner from 'util/components/spinner'
import Text from 'util/components/text'
import PrimaryButton from 'util/components/buttons/primary-button'

class CustomerSuccessAdminEspResults extends React.Component {
  state = {
    loading: false,
    brandId: null,
    campaignId: null,
    queue: 0,
    successful: 0,
    failed: 0,
    error: ''
  }

  onchange = (attr, value) => {
    this.setState({
      [attr]: value
    })
  }

  checkSendResults = async () => {
    const { brandId, campaignId } = this.state

    if (!!brandId && !!campaignId) {
      this.setState({
        loading: true
      })

      try {
        const { data: results } = await axios.get(`/integrations-send-results/${brandId}/${campaignId}`)
        if (results === 'Not found') {
          this.setState({
            loading: false,
            error: 'Not Found'
          })
        } else {
          this.setState({
            loading: false,
            queue: results.queue,
            successful: results.successful,
            failed: results.failed
          })
        }
      } catch (e) {
        this.setState({
          loading: false,
          error: e
        })
      }
    }
  }

  render() {
    const { loading, brandId, campaignId, queue, successful, failed, error } = this.state

    if (loading) {
      return (
        <Spinner />
      )
    }

    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            ESP Export Results
          </Text.dojoH2Bold>

          <form className="col-xs-12">
            <div className="row">
              <div className="col-xs-12" style={{
                marginTop: '20px'
              }}>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Brand ID
                  </Text.dojoP>
                  <input type="number" name="brandId" className="col-xs-12" onChange={ e => this.onchange('brandId', e.target.value) }/>
                </div>
                <div className="row">
                  <Text.dojoP className="col-xs-12">
                    Campaign ID
                  </Text.dojoP>
                  <input type="number" name="campaignId" className="col-xs-12" onChange={ e => this.onchange('campaignId', e.target.value) }/>
                </div>
                <PrimaryButton onClick={ this.checkSendResults }
                  isDisabled={ !brandId || !campaignId }
                  styles={[{
                    width: '220px',
                    height: '50px',
                    marginTop: '20px'
                }]}>
                  Check Results
                </PrimaryButton>
              </div>
            </div>
          </form>
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                In Queue: { queue }
              </Text.dojoH3Bold>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Successful: { successful }
              </Text.dojoH3Bold>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Text.dojoH3Bold>
                Failed: { failed }
              </Text.dojoH3Bold>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <Text.Danger>
                { error }
              </Text.Danger>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerSuccessAdminEspResults
