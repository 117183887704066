import React, { Component } from 'react'
import { func, object } from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H4 } from 'visual-components/util/texts'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'

import { SimpleForm, Input } from 'visual-components/util/form'
import { isPositive, cannotExceed } from 'visual-components/util/form/validations'

import { CLOSED } from './steps'

export default class VerifyBankAccountForm extends Component {
  static propTypes = {
    toggleModal: func.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
  }

  toggleModal = STEP => {
    const { toggleModal } = this.props
    toggleModal(STEP)
  }

  close = () => this.toggleModal(CLOSED)

  update = data => this.setState(prevState => ({ ...prevState, ...data }))

  handleSubmit = ({ amount1, amount2 }) => {
    const {
      brandAccount: { simpleVerifyId },
      brandAccountActions: { verifyBankAccount },
    } = this.props
    verifyBankAccount({ amount1, amount2 }, simpleVerifyId)
    this.close()
  }

  render() {
    const {
      brandAccount: { error },
    } = this.props
    return (
      <SimpleForm model={this.state} updateModel={this.update} handleSubmit={this.handleSubmit}>
        <Grid>
          <Row style={{ marginBottom: '16px' }}>
            <H4 multiline>
              Please enter the amount of the two deposits we made in your bank account. Each amount
              should be less than a $1.00.
              {error && (
                <strong style={{ color: 'red' }}>
                  <br />
                  {`Error: ${error}`}
                </strong>
              )}
            </H4>
          </Row>
          <Row>
            <Col style={{ width: '300px', marginRight: '8px' }}>
              <Input
                required
                name="amount1"
                label="Amount 1"
                placeholder="0.54"
                validations={[isPositive, cannotExceed(1)]}
              />
            </Col>
            <Col style={{ width: '300px' }}>
              <Input
                required
                name="amount2"
                label="Amount 2"
                placeholder="0.46"
                validations={[isPositive, cannotExceed(1)]}
              />
            </Col>
          </Row>
        </Grid>
        <ModalButtonGroupWithContext confirmText="Verify" cancelText="Cancel" cancel={this.close} />
      </SimpleForm>
    )
  }
}
