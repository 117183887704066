import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'

import COLOURS from 'util/colours'
import ButtonTheme from 'css/themes/Buttons.css'
import InputWithPercentage from './util/InputWithPercentage'
import StatusMarker from './util/StatusMarker'

const styles = {
  wrapper: {
    width: '100%',
    display: 'flex',
  },
  overrideBlue: {
    border: `1px solid ${COLOURS.silver}`,
    backgroundColor: 'white !important',
    color: `${COLOURS.azure} !important`,
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
    '& button': {
      margin: 0,
      marginLeft: 'auto',
      minWidth: 102,
    },
  },
}

function SplitTrafficActionsCol(props) {
  const {
    classes: css,
    partner,
    onSave,
    onChange,
    onClickInvite: onClickInviteParent,
    campaignStatus,
  } = props

  const isInvited = !['partnerRequested', 'declined', 'notInvited'].includes(partner.status)

  const {
    error,
    id,
    isChanged,
    traffic,
    status,
    state,
  } = partner

  const onClickInvite = async () => {
    await onSave(id)
    onClickInviteParent(partner)
  }

  const inviteSent = status === 'invited'
  const isSaving = state === 'saving'

  const inputDisabled = isSaving
  const buttonDisabled = inviteSent || traffic === 0 || partner.error

  return (
    <div className={css.wrapper}>
      <InputWithPercentage
        error={error}
        disabled={inputDisabled}
        helpText={error || 'unlimited'}
        initialValue={traffic}
        onChange={onChange}
      />
      <div className={css.actionWrapper}>
        {isInvited && isChanged && campaignStatus !== 'completed' ? (
          <Button
            onClick={() => onSave(id)}
            label="Save Changes"
            primary
            theme={ButtonTheme}
            className={ButtonTheme.blueButton}
            raised
          />
        )
          : !isInvited && !isSaving && campaignStatus !== 'completed'
            ? (
              <Button
                primary
                onClick={onClickInvite}
                label={`Invite${inviteSent ? ' Sent' : ''}`}
                disabled={buttonDisabled}
                theme={ButtonTheme}
                className={
                  (buttonDisabled && ButtonTheme.disabledWhite)
                  || (inviteSent && ButtonTheme.disabledSilver)
                  || cn(ButtonTheme.blueButton, css.overrideBlue)
                }
                raised
              />
            ) : (
              <StatusMarker
                state={state}
              />
            )
        }
      </div>
    </div>
  )
}

SplitTrafficActionsCol.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  onClickInvite: PropTypes.func.isRequired,
  campaignStatus: PropTypes.string.isRequired,
}

export default React.memo(injectCSS(styles)(SplitTrafficActionsCol))
