import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';
import { withRouter } from 'react-router-dom';

import { H4 } from 'visual-components/util/texts';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import dialogTheme from 'css/themes/dialogs/dialogBase.css';

const themes = {
  Dialog: require('css/themes/dialogs/formModal.css')
}

export function MessagingLimitPartnersModal({ dispatch, open, isInvitation, history, campaignId, hasStarted }) {
  const closeModal = () => dispatch({ type: 'UPDATE_ATTR', model: 'campaignLimitModal', data: { open: false } });
  const confirm = () => {
    closeModal();
    if (isInvitation) {
      window.location = '/app/search/sweepstakes'
    } else {
      history.push(`/builder/${campaignId}/partner/accepted`);
    }
  }

  let text = isInvitation ? `Whoops! Sorry about that. This campaign already has reached its limit of 8 confirmed partners. Fear not - there are lots of other campaigns to join in the DojoMojo network!  ` :
    `This campaign already has 8 confirmed partners, including your brand, which is the maximum allowed on DojoMojo. Too many partners greatly reduces lead quality and we want you to have a happy audience!  `;

  if (hasStarted) {
    text = `Whoops! Sorry about that. This campaign has already started, which means you can not create, edit or share partnership agreements.`
  }
  let confirmText;
  let onBuilder = window.location.pathname.indexOf('builder') !== -1;

  if (isInvitation) {
    confirmText = 'Explore Campaigns';
  } else if (hasStarted) {
    confirmText = 'Got It'
  } else {
    confirmText =  'See Current Partners';
  }

  return (
    <Dialog
      theme={dialogTheme}
      active={open}
      onEscKeyDown={closeModal}
      onOverlayClick={closeModal}
      title={hasStarted ? 'Campaign Has Already Started!' : 'Campaign at Partner Limit'}>
      <H4 multiline>
        {text}
        {!isInvitation && <a href="https://help.dojomojo.com/hc/en-us/articles/226107128-How-many-Partners-should-I-have-on-one-giveaway-">Learn more</a>}
      </H4>
      <ModalButtonGroup
        canSave
        hideLine
        confirm={confirm}
        confirmText={ confirmText }
        cancelText={ "Close" }
        cancel={!hasStarted && closeModal}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => state.campaignLimitModal;

export default withRouter(connect(mapStateToProps)(MessagingLimitPartnersModal));
