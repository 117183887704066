import React from 'react';
import { Link } from 'react-router-dom';
import { H4, P } from 'visual-components/util/texts';

import mobileHeaderTheme from 'css/themes/mobile/header.css';

class DropdownListItem extends React.Component {
  render() {
    return (
      <div>
        <li className={ mobileHeaderTheme.dropdownListItem } onClick={ this.props.action }>
          <H4>
            { this.props.text }
          </H4>
          { this.props.children ?
              <div className={ this.props.open ? mobileHeaderTheme.arrowDown : mobileHeaderTheme.arrowUp }>
              </div>
            :
              null
          }
        </li>
        { this.props.children && this.props.open ?
            <ul className={ mobileHeaderTheme.nestedUl }>
              { this.props.children }
            </ul>
          :
            null
        }
      </div>

    )
  }
}

export default DropdownListItem;
