import React from 'react'
import PropTypes from 'prop-types'
import Rating from 'react-rating'

const filled = '/images/icons/star-filled.svg'
const empty = '/images/icons/star-empty.svg'

const Star = ({ src, size }) => (
  <img src={src} style={{ width: size, height: size }} />
)

Star.propTypes = {
  size: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
}

const Stars = props => {
  const {
    value,
    onChange,
    readonly,
    style,
    size,
    className,
  } = props

  return (
    <div className={className} style={{ margin: '0 8px', ...style }}>
      <Rating
        initialRating={value}
        value={value}
        readonly={readonly}
        onChange={onChange}
        emptySymbol={<Star size={size} src={empty} />}
        fullSymbol={<Star size={size} src={filled} />}
      />
    </div>
  )
}

Stars.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
}

Stars.defaultProps = {
  style: {},
  size: 12,
  className: null,
  value: 0,
  onChange() {},
  readonly: true,
}

export default Stars
