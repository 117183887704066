import React from 'react';
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet'

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import Field from 'visual-components/util/inputs/field';
import SelectField from 'visual-components/util/inputs/select-field';
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';
import Validator from 'util/form-validator';
import Countries from 'util/countries';

import { H2, H3, P } from 'visual-components/util/texts';

import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher';
import formatPhoneNumber from 'util/formatPhoneNumber'
import { Link } from 'react-router-dom'

let countries = _.map(Countries, function (item) {
  return {
    value: item.name,
    label: item.name
  }
});

countries.unshift({
  label: 'United States',
  value: 'United States'
});

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    currentBrandForm: state.currentBrandForm
  }
}

class BrandInfoSettings extends React.Component {
  render() {
    var {
      currentBrand,
      currentBrandForm
    } = this.props;

    return(
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>Brand Info</title>
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '12px'
          }}>
            <Col xs>
              <H2 azure>
                Brand Info
              </H2>
            </Col>
          </Row>
          <Row style={{
            marginBottom: '24px'
          }}>
            <Col xs>
              <P multiline>
                You can edit your brand’s description, logo and other details directly from your
                  <Link to={`/explore/brands/${currentBrand.id}`}>
                    {` Brand Profile`}
                  </Link>
                .
              </P>
            </Col>
          </Row>
          <Row>
            <Col style={{
              width: '360px',
              marginRight: '32px'
            }}>
              <div style={{marginBottom: '20px'}}>
                <H3 coral>
                  <small>
                    Contact Info
                  </small>
                </H3>
              </div>

              <Field label={ 'Phone Number' }
                attrName={ 'phone' }
                placeholder={ 'XXX-XXX-XXXX' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
                onChange={formatPhoneNumber}
              />

              <div style={{
                width: '224px',
                marginRight: '8px',
                display: 'inline-grid'
              }}>
                <Field label={ 'Official Company Address' }
                  attrName={ 'addressL1' }
                  placeholder={ 'Street Name' }
                  tooltip={ 'Your HQ address will be used to create Official Rules for giveaways you participate in.' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>
              <div style={{
                width: '128px',
                display: 'inline-grid'
              }}>
                <Field label={ 'Address 2' }
                  attrName={ 'addressL2' }
                  placeholder={ 'Apt, Suite, Unit #' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>

              <div style={{
                width: '224px',
                marginRight: '8px',
                display: 'inline-grid'
              }}>
                <Field label={ 'City' }
                  attrName={ 'city' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>
              <div style={{
                width: '128px',
                display: 'inline-grid'
              }}>
                <Field label={ 'State/Province' }
                  attrName={ 'state' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>

              <div style={{
                width: '112px',
                marginRight: '8px',
                display: 'inline-grid'
              }}>
                <Field label={ 'Zip/Postal Code' }
                  attrName={ 'zipcode' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>
              <div style={{
                width: '240px',
                display: 'inline-grid'
              }}>
                <SelectField label={ 'Country' }
                  attrName={ 'country' }
                  options={ countries }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>
            </Col>
            <Col style={{
              width: '360px'
            }}>
              <div style={{marginBottom: '20px', display: 'inline-block'}}>
                <H3 coral>
                  <small>
                    Legal Details
                  </small>
                  <div style={{
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginLeft: '6px',
                    marginTop: '-5px'
                  }}>
                    <BlueInfoTooltip
                      text={ 'This info is used to complete the Official Rules for giveaways you participate in.' }
                    />
                  </div>
                </H3>
              </div>

              <Field label={ 'Company Legal Name' }
                attrName={ 'legalName' }
                placeholder={ 'Company Name, Inc' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />

              <Field label={ 'Link to Privacy Policy' }
                attrName={ 'privacyPolicy' }
                placeholder={ 'www.companywebsite.com/privacypolicy' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />

              <Field label={ 'Link to Official Rules' }
                attrName={ 'termsUrl' }
                placeholder={ 'www.companywebsite.com/terms' }
                dataModel={ currentBrand }
                formModel={ currentBrandForm }
                endpoint={ '/brands' }
              />

              <div style={{
                width: '176px',
                height: '40px',
                borderRadius: '2px',
                display: 'inline-grid',
                marginRight: '8px',
              }}>
                <Field label={ 'State of Incorporation' }
                  attrName={ 'incorporationState' }
                  placeholder={ 'Delaware' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>

              <div style={{
                display: 'inline-grid',
                width: '176px',
                height: '40px',
                borderRadius: '2px',
              }}>
                <Field label={ 'Entity Type' }
                  attrName={ 'entityType' }
                  placeholder={ 'Corporation' }
                  dataModel={ currentBrand }
                  formModel={ currentBrandForm }
                  endpoint={ '/brands' }
                />
              </div>

            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
};

export default ReactRedux.connect(mapState)(BrandInfoSettings);
