import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import {
  Editor,
  EditorState,
  ContentState,
  Modifier,
  SelectionState,
} from 'draft-js'
import _ from 'lodash'

import { replaceEscapeChars } from 'visual-components/mobile-marketing/SMSEditor/SMSEditorContext'

import ImagesPreview from './utils/ImagesPreview'
import { decorators } from './utils/editorUtils'

const styles = {
  content: {
    padding: 12,
    flex: 1,
    overflow: 'auto',
  },
  contentEditable: {
    outline: 'none',
    font: '14px/22px Larsseit-Light', // fontSize/lineHeight fontFamily
  },
  editor: {
    height: '100%',
    '& .public-DraftStyleDefault-block': {
      font: '14px/28px Larsseit-Light',
    },
  },
}

class SMSEditorBody extends PureComponent {
  containerRef = React.createRef()

  editorRef = React.createRef()

  state = {
    editorState: EditorState.createEmpty(null),
    lastSelection: null,
    readOnly: true,
  }

  onOutsideCouponChange = _.debounce(() => {
    const { editorState } = this.state

    this._onEditorChange(editorState, true)
  }, 5000) // wait 5 seconds before updating

  componentDidMount() {
    const { actions: { actionsRef } } = this.props

    this._applyInitialSettings()

    actionsRef.insertText = this._insertText
    actionsRef.editor = this.editorRef.current
  }

  // Force re-rendering when coupon is tracked
  componentDidUpdate(prevProps) {
    const { coupon, minifiedLinks } = this.props
    if (prevProps.coupon !== coupon) this.onOutsideCouponChange()
    if (prevProps.minifiedLinks !== minifiedLinks) {
      const { editorState } = this.state
      this._onEditorChange(editorState, true)
    }
  }

  _applyInitialSettings = () => {
    let { text } = this.props

    // TODO: Revisit this part here
    this._replaceLinks({ text }, link => {
      text = text.replace(new RegExp(link.originalUrl, 'g'), link.minifiedUrl)
    })

    this._replaceCouponCodes(text, coupon => {
      text = text.replace(new RegExp(coupon, 'g'), '*|COUPON_CODE|*')
    })

    const editorState = text
      ? EditorState.createWithContent(ContentState.createFromText(text), decorators)
      : EditorState.createEmpty(decorators)
    const lastSelection = editorState.getSelection()

    this.setState({ editorState, lastSelection })
  }

  // Insert tags/links into editor state
  _insertText = text => {
    const { lastSelection: selection, editorState } = this.state
    const { actions: { setText, applyChanges } } = this.props

    let contentState = editorState.getCurrentContent()

    contentState = Modifier.insertText(contentState, selection, text)

    const newEditorState = EditorState.push(editorState, contentState, 'insert-characters')
    const newText = contentState.getPlainText()
    const lastSelection = newEditorState.getSelection()

    setText(newText)

    this.setState({ editorState: newEditorState, lastSelection }, applyChanges)
  }

  _replaceLinks = ({ linkFound, ...rest }, callback) => {
    const {
      text,
      // block
    } = rest
    const {
      minifiedLinks,
      // actions: { clearItem },
    } = this.props

    minifiedLinks.forEach(link => {
      const escapedLink = link.originalUrl.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(`^${escapedLink}$`, 'g')

      let textCursor = 0

      text.split(/\s|\n/).forEach(word => {
        if (regex.test(word)) {
          callback(link, { index: textCursor })
          linkFound = true
        } else if (/do?jo?mo?jo?\.io/.test(word)) {
          linkFound = true
        }

        textCursor += word.length + 1
      })

      linkFound = linkFound || /https:\/\/.*\.do?jo?mo?jo?\.io/.test(text)

      // if (!linkFound && !block) clearItem('minifiedLinks')
    })

    return linkFound
  }

  _replaceCouponCodes = (text, callback) => {
    const { coupon } = this.props

    if (!coupon) return

    const regex = new RegExp(`^${coupon.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')}$`, 'g')

    let textCursor = 0

    text.split(/\s|\n/).forEach(word => {
      if (regex.test(word)) {
        callback(coupon, { index: textCursor })
      }
      textCursor += word.length + 1
    })
  }

  _applyEditorReplacements = nextEditorState => {
    const { actions: { setText } } = this.props

    let contentState = nextEditorState.getCurrentContent()
    const currentSelection = nextEditorState.getSelection()
    const blocks = contentState.getBlockMap()

    const replacerFn = (...params) => {
      const [match, blockKey, text, replacementText] = params
      const { index: start } = match

      const selection = SelectionState.createEmpty(blockKey)
        .set('anchorOffset', start)
        .set('focusOffset', start + text.length)

      contentState = Modifier.replaceText(contentState, selection, replacementText)
    }

    let blockCount = blocks.length
    let linkFound = false

    // eslint-disable-next-line no-restricted-syntax
    for (const [, block] of blocks) {
      const { text: blockText, key: blockKey } = block

      // eslint-disable-next-line no-loop-func
      linkFound = this._replaceLinks(
        {
          text: blockText,
          block: --blockCount,
          linkFound,
        },
        (link, match) => replacerFn(match, blockKey, link.originalUrl, link.minifiedUrl)
      )

      // eslint-disable-next-line no-loop-func
      this._replaceCouponCodes(blockText, (coupon, match) =>
        replacerFn(match, blockKey, coupon, '*|COUPON_CODE|*'))
    }

    // https://github.com/facebook/draft-js/issues/410
    let newEditorState = EditorState.createWithContent(contentState, decorators)
    newEditorState = EditorState.forceSelection(newEditorState, currentSelection)

    const newText = contentState.getPlainText()

    setText(newText)

    return newEditorState
  }

  _onEditorChange = (nextEditorState, override = false) => {
    if (!override && this.state.readOnly) return

    nextEditorState = this._applyEditorReplacements(nextEditorState)
    const lastSelection = nextEditorState.getSelection()

    nextEditorState = EditorState.set(nextEditorState, { decorator: decorators })

    this.setState({ editorState: nextEditorState, lastSelection })
  }

  _onContentPasted = pastedText => {
    const { text, hasEscapeChars } = replaceEscapeChars(pastedText)

    if (hasEscapeChars) {
      const { editorState } = this.state

      // we'll add a message for the offending user to the editor state
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        text
      )

      const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')

      this._onEditorChange(newEditorState)

      return true
    }
    return false
  }

  _onEditorBlur = () => this.setState({ readOnly: true })

  _onEditorFocus = () => this.setState({ readOnly: false }, this.editorRef.current.focus)

  render() {
    const { classes: css } = this.props
    const { editorState, readOnly } = this.state

    return (
      <div className={css.content} onClick={this._onEditorFocus}>
        <ImagesPreview />
        <div
          id="sms-editor-wrapper"
          className={css.editor}
        >
          <Editor
            ref={this.editorRef}
            editorState={editorState}
            onChange={this._onEditorChange}
            onBlur={this._onEditorBlur}
            readOnly={readOnly}
            handlePastedText={this._onContentPasted}
          />
        </div>
      </div>
    )
  }
}

SMSEditorBody.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  text: PropTypes.string,
  coupon: PropTypes.string,
  minifiedLinks: PropTypes.arrayOf(PropTypes.object),
}

SMSEditorBody.defaultProps = {
  text: '',
  coupon: '',
  minifiedLinks: [],
}

export default injectCSS(styles)(SMSEditorBody)
