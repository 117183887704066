import React from 'react'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import Field from 'util/components/field'
import Clipboard from 'clipboard'
import ReactDOM from 'react-dom'
import Embed from 'util/embed-code'
import COLOURS from 'util/colours'
import { H3, H4 } from 'visual-components/util/texts'
import Validator from 'util/form-validator'

const validations = {
  baseUrl: [Validator.required, Validator.IsWebsite],
}

class SelfHost extends React.Component {
  constructor(props) {
    super(props)

    this.copiedNotify = this.copiedNotify.bind(this)
    this.activateHosting = this.activateHosting.bind(this)
    this.deselectOption = this.deselectOption.bind(this)
  }

  state = { validURL: null }

  componentDidMount() {
    new Clipboard($(ReactDOM.findDOMNode(this)).find('.copy-embed')[0])
    this.setState({validURL: this.props.currentCampaign.baseUrl})
  }

  activateHosting() {
    const that = this
    Validator.validateForm(
      validations,
      this.props.currentCampaign,
      'currentCampaignForm',
      valid => {
        if (valid) {
          that.props.activateHosting('selfHost')
        }
      }
    )
  }

  deselectOption() {
    CampaignDispatcher.updateAttr('baseUrl', this.state.validURL)
    this.props.deselectOption()
  }

  copiedNotify() {
    toastr.success('embed code copied to clipboard', null, { timeOut: 1000 })
  }

  render() {
    const campaign = this.props.currentCampaign
    const data = {
      description: campaign.description,
      name: campaign.name,
      twitterShareMessage: campaign.twitterShareMessage,
      facebookShareImage: campaign.facebookShareImage,
      mainImage: campaign.desktopElements.mainImage,
      staticPath: `//landing.dojomojo.com/landing/campaign/${campaign.uid}`,
      ga: this.props.currentBrand.googleAnalyticsCode,
      adRollId: this.props.currentBrand.adRollId,
      adRollPixelId: this.props.currentBrand.adRollPixelId,
      id: campaign.uid,
    }

    return (
      <div>
        {/* text */}
        <div className="row text-center">
          <div className="col-sm-12">
            <H3>Install our Code in your Website</H3>
          </div>
        </div>
        <br />
        <div className="row text-left">
          <div className="col-sm-12">
            <H4 multiline>This option requires a developer.</H4>
          </div>
        </div>
        <br />

        <div className="row text-left">
          <div className="col-sm-12">
            <ol>
              <li>
                <H4 multiline>
                  Share
                  {' '}
                  <a
                    onClick={this.copiedNotify}
                    className="copy-embed"
                    data-clipboard-text={Embed(data)}
                  >
                    {'this snippet of code'}
                  </a>
                  {' '}
                  with your developer and have them embed it in your website.
                </H4>
              </li>
              <li>
                <H4 multiline>
                  Paste the web address where your giveaway landing page will live below and click
                  Activate.
                </H4>
              </li>
            </ol>
          </div>
        </div>

        <br />

        <div className="row text-left">
          <div className="col-sm-12">
            <Field
              editable
              dataModel={this.props.currentCampaign}
              formModel={this.props.currentCampaignForm}
              attrName="baseUrl"
              validations={validations.baseUrl}
              updateAttr={this.props.updateAttr}
              label="Enter Giveaway URL"
              placeholder="http://www.yourcompany/giveaway-url"
            />
          </div>
        </div>

        <div className="row text-left" style={{ marginBottom: '32px' }}>
          <div className="col-sm-12">
            <span style={{ fontFamily: 'Larsseit-Medium', color: '#ff3c2a' }}>Warning: </span>
            <span style={{ fontFamily: 'Larsseit-Light', color: '#2c3541' }}>
              For the Advanced hosting option, it may take up to 48 hours for the domain connection
              to test successfully, but you will be able to retarget traffic regardless.
            </span>
          </div>
        </div>

        {/* buttons */}
        <div className="row text-center">
          <div className="col-sm-12">
            <ModalButtonGroup
              hideLine={false}
              canSave
              confirmText="Activate"
              cancelText="Cancel"
              confirm={this.activateHosting}
              cancel={this.deselectOption}
            />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-sm-12">
            {/*
              will be implemented later

              <SecondaryButton on
                Test
              />
            */}
          </div>
        </div>
      </div>
    )
  }
}

export default SelfHost
