import React, { Component } from 'react'
import { string, func, number } from 'prop-types'

import COLOURS from 'util/colours'
import SearchCard from 'visual-components/shared/search-card'

class SearchFilter extends Component {
  static propTypes = {
    placeholder: string.isRequired,
    onChange: func.isRequired,
    onKeyUp: func,
    value: string,
  }

  onKeyUp = e => {
    const { onKeyUp, onEnter } = this.props
    // for backwards compatibility / override
    if (onKeyUp) {
      onKeyUp(e)
    } 
    // default behavior is on 'Enter' key up, use onEnter
    else if (e.key === 'Enter' && onEnter) {
      onEnter()
    }
  }

  render() {
    const { placeholder, value, onChange, onFocus } = this.props

    return(
      <SearchCard
        placeholder={ placeholder }
        value={ value }
        onChange={ onChange }
        onKeyUp={ this.onKeyUp }
        onFocus= { onFocus }
      />
    )
  }
}

export default SearchFilter
