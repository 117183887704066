import React, { Component } from 'react';
import Clipboard from 'clipboard'
import Dialog from 'react-toolbox/lib/dialog';
import { connect } from 'react-redux';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import ModalBaseTheme from 'css/themes/dialogs/modalBase.css';
import { bindActionCreators } from "redux";
import * as CampaignTaskActions from 'actions/campaign-task-actions';
import CreateTaskTheme from 'css/themes/dialogs/createTask.css';
import { H4 } from 'visual-components/util/texts';

import { Button } from 'react-toolbox/lib/button'
import Field from 'visual-components/util/inputs/field'

import ButtonTheme from 'css/themes/Buttons.css'
const mapStateToProps = (state) => {
  const {
    subscribeUrl,
    subscribeName
  } = state.campaignTasks

  return {
    subscribeUrl,
    subscribeName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
};

class SubscribeToCalendar extends Component {
  componentDidUpdate() {
    new Clipboard(`.subscribe-calendar`)
  }
  render() {
    const {
      subscribeUrl,
      subscribeName,
      campaignTaskActions: {
        closeSubscribeModal
      }
    } = this.props

    return(
      <Dialog
        active={ !!subscribeUrl }
        title={ "Export Calendar" }
        onEscKeyDown={ closeSubscribeModal }
        onOverlayClick={ closeSubscribeModal }
        theme={ ModalBaseTheme }
      >
        <div className={ CreateTaskTheme.subTitle } style={{ paddingBottom: '24px' }}>
          <H4 multiline>
            See all your Tasks and Campaign Timelines in your calendar on iOS, Mac, Google or Outlook. Just subscribe to your private Brand Calendar URL below.
          </H4>
        </div>

        <div>
          <div style={{
            width: '533px',
            marginRight: '8px',
            display: 'inline-block'
          }}>
            <Field label={ `${subscribeName} Calendar URL` }
              attrName={ 'link' }
              dataModel={ { link: subscribeUrl } }
              formModel={ {} }
              updateAttr={ () => {} }
              readOnly
            />
          </div>
          <div style={{
            width: '67px',
            marginRight: '0px',
            display: 'inline-block'
          }}>
            <Button onClick={ this.copyLink }
              ref="copyButton"
              label={ 'Copy' }
              className={ `${ButtonTheme.blueButton} ${ButtonTheme.solidGreenButton} subscribe-calendar` }
              theme={ ButtonTheme }
              data-clipboard-text={ subscribeUrl }
              primary
              raised
            />
          </div>
        </div>
        
        <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
          <ModalButtonGroup
            confirm={ closeSubscribeModal }
            confirmText={ "Close" }
            canSave
            hideLine
          />
        </div>

      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeToCalendar);
