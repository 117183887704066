export default {
  setHistory(history) {
    store.dispatch({
      type: 'SET_HISTORY',
      model: 'browserHistory',
      data: { history },
    })
  },
  push(location) {
    const { history } = store.getState().browserHistory
    history.push(location)
  },
  replace(location) {
    const { history } = store.getState().browserHistory
    history.replace(location)
  },
  goBack() {
    const { history } = store.getState().browserHistory
    history.goBack()
  }
}
