import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReadyToGrow from './ready-to-grow';
import FooterLinks from './footer-links';
import ContactAndCopyright from './contact-and-copyright';
import * as ContactSupportActions from 'actions/contact-support-actions';

class Footer extends Component {
  openContactSupportModal = (e) => {
    e.preventDefault();
    this.props.actions.openModal();
  }

  render() {
    return(
      <div>
        <ReadyToGrow />
        <FooterLinks openContactSupportModal={this.openContactSupportModal} />
        <ContactAndCopyright openContactSupportModal={this.openContactSupportModal} />
      </div>
    )
  }
}

export default connect(null, (dispatch) => ({
  actions: bindActionCreators(ContactSupportActions, dispatch)
}))(Footer);
