import React from 'react'

import QualityAssuranceAdminCreateCampaign from './quality-assurance-admin-create-campaign'
import QAMediaMarketSeedClicks from './qa-media-market-seedClicks'
import QAMediaMarketChangeStatus from './qa-media-market-changeStatus'
import QACronjobTrigger from './qa-cronjob-trigger'

class QualityAssuranceAdminContainer extends React.Component {
  render() {
    return (
      <div>
        <h2 style={{ marginLeft: '50px', paddingTop: '50px' }}>
          Welcome to QA Admin
        </h2>
        <QACronjobTrigger />
        <QualityAssuranceAdminCreateCampaign />
        <QAMediaMarketSeedClicks />
        <QAMediaMarketChangeStatus />
      </div>
    )
  }
}

export default QualityAssuranceAdminContainer
