import { makeNumbersPretty } from 'util/makeNumbersPretty';
import SocialLabelMap from 'util/socialTitleMap';
import SocialTitleArray from 'util/socialTypeArray';

export default {
  mappings: {},
  metricInfo: {
    dashboardEmailsAcquired: {
      hoverMetric: 'emails',
      mappingLogic: 'lineGraphWithPriorPeriod',
      names: ['emails-acquired-overview', 'emails-acquired-overview-past'],
    },
    dashboardSmsSubscribers: {
      hoverMetric: 'subscribers',
      mappingLogic: 'lineGraphWithPriorPeriod',
      names: ['sms_subscribers'],
    },
    dashboardSocialActions: {
      hoverMetric: 'social actions',
      mappingLogic: 'lineGraphWithPriorPeriod',
      names: ['social-actions-overview', 'social-actions-overview-past'],
    },
    dashboardCampaignsLaunched: {
      mappingLogic: 'barGraphVertical',
      hoverMetric: 'campaigns launched',
      name: 'campaigns-launched',
    },
    dashboardNewPartnerships: {
      mappingLogic: 'barGraphVertical',
      hoverMetric: 'new partnerships',
      name: 'new-partnerships',
    },
    dashboardTopRegions: {
      mappingLogic: 'barGraphHorizontalTop3',
      hoverMetric: 'emails',
    },
    dashboardSocialFollower: {
      mappingLogic: 'donutChart',
      hoverMetric: 'emails',
    },
    dashboardSocialMetrics: {
      mappingLogic: 'stackedBarVertical',
      hoverMetric: 'emails',
    },
    dashboardCustomCTA: {
      mappingLogic: 'barGraphVertical',
      hoverMetric: 'emails',
      name: 'custom-cta',
    },
    dashboardConversionTotals: {
      mappingLogic: 'passThrough',
    },
    dashboardSmsSubscribersTotals: {
      mappingLogic: 'passThrough',
    },
    dashboardSmsSummary: {
      mappingLogic: 'passThrough',
    },
    dashboardConversionDetails: {
      mappingLogic: 'lineGraphSplit',
      fieldNames: {
        transactions: 'purchases',
        revenue: 'sales generated',
      },
    },
  },
  actions: {},
}
