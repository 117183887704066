export const loadEmailNotificationSettings = () => ({
  http: {
    url: '/brands/get-email-notification-preferences',
    method: 'GET',
  },
  types: [
    'LOAD_NOTIFICATION_SETTINGS',
    'LOAD_NOTIFICATION_SETTINGS_SUCCESS',
    'LOAD_NOTIFICATION_SETTINGS_FAILURE',
  ],
})

export const changeEmailNotificationSetting = data => ({
  http: {
    url: '/brands/change-email-notification-preferences',
    method: 'PATCH',
    data,
  },
  types: [
    'CHANGE_NOTIFICATION_SETTINGS',
    'CHANGE_NOTIFICATION_SETTINGS_SUCCESS',
    'CHANGE_NOTIFICATION_SETTINGS_FAILURE',
  ],
})
