import React from 'react'
import OpportunityInfoCard from './opportunity-info-card'
import InterestValue from 'visual-components/brandProfile/interests/interest-value.js'

class PrizingPartnerOpportunityInfo extends React.Component {
  render() {
    let { opportunity, brand, showPen, obscure } = this.props

    return (
      <OpportunityInfoCard tags={ brand.tags } showPen={ showPen }>
        <div style={{
          float: 'left',
        }}>
          <InterestValue
            title={ 'Prizing Value' }
            value={ typeof opportunity.value === 'number'
              ? `$${Math.round(opportunity.value)}`
              : `$${opportunity.value}`
            }
            marginRight='137px'
            obscure={obscure}
          />
        </div>
        <div style={{
          width: '600px',
          float: 'left'
        }}>
          <InterestValue
            title={ 'Restrictions' }
            value={ opportunity.restrictions }
            obscure={obscure}
          />
        </div>
        <div style={{
          width: '815px'
        }}>
          <InterestValue
            title={ 'Contribution' }
            value={ opportunity.contribution }
            obscure={obscure}
          />
        </div>
      </OpportunityInfoCard>
    )
  }
}

export default PrizingPartnerOpportunityInfo
