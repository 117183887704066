import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Dialog from 'react-toolbox/lib/dialog';

import * as legalDetailsActions from 'actions/legal-details-actions';
import { H4, P } from 'visual-components/util/texts';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import dialogTheme from 'css/themes/dialogs/formModal.css';

import LegalDetailsForm from './legal-details-form';

export class LegalDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = ['incorporationState', 'legalName', 'entityType', 'city', 'state', 'addressL1', 'addressL2', 'zipcode', 'country'].reduce((acc, curr) => {
      acc[curr] = props.brand[curr];
      return acc;
    }, {});
  }

  updateValue = name => value => {
    this.setState({
      [name]: value
    });
  }

  submit = () => {
    const submitAction = this.props.submit || this.props.actions.saveLegalDetails;
    return submitAction(this.state);
  }

  render() {
    const {
      active,
      actions,
      brand
    } = this.props;
    return (
      <Dialog
        theme={dialogTheme}
        active={active}
        onEscKeyDown={actions.closeModal}
        onOverlayClick={actions.closeModal}
        title="Company Legal Details"
      >
        <P>Enter and confirm your brand's details and we'll update the agreement automatically.</P>
        <LegalDetailsForm
          formData={this.state}
          updateValue={this.updateValue}
          confirm={this.submit}
          closeModal={actions.closeModal}
          confirmText="Confirm Details"
          required />
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  brand: state.currentBrand,
  partnershipId: state.legalDetailsModal.partnershipId,
  active: state.legalDetailsModal.active
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(legalDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  LegalDetailsModal
);
