import React, { Component } from 'react'
import { Card } from 'react-toolbox/lib/card'
import theme from 'css/themes/card.css'
import { H3 } from 'visual-components/util/texts'

class DefaultCard extends Component {
  render() {
    const {
      title,
      children,
      className
    } = this.props

    return (
      <Card className={`${theme.standardCard} ${className}`}>
        <div className={theme.titleWrapper}>
          <H3 coral>
            <small>
              { title }
            </small>
          </H3>
        </div>
        { children }
      </Card>
    )
  }

}

export default DefaultCard
