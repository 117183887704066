import React from 'react'
import { object, array } from 'prop-types'
import DealCard from 'visual-components/mediaMarket/DealCard'

class MediaItems extends React.PureComponent {
  static propTypes = {
    items: array.isRequired,
    statusMap: object.isRequired,
  }

  render() {
    const { items, statusMap, currentBrandId } = this.props

    const mediaItems = items.map(item => (
      <DealCard deal={item} key={item.id} statusMap={statusMap} currentBrandId={currentBrandId} />
    ))

    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'flex-start',
        }}
      >
        {mediaItems}
      </div>
    )
  }
}

export default MediaItems
