import constants from '../constants.json'

const getEditorMenuWidth = (isInEditor, isDesktop, isMenuOpen) => (
  !isInEditor || !isDesktop
    ? 0
    : isMenuOpen
      ? constants.editorMenuOpenWidth
      : constants.editorMenuClosedWidth
)
export default getEditorMenuWidth
