import React, { Component } from 'react'
import { array, object } from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as NotificationActions from 'actions/notification-actions'
import styles from './style.css'

import NotificationPopupItem from './notification-popup-item'

const BASE_URLS = {
  dev: 'wss://dev.dojomojo.com:442/ws2',
  staging: 'wss://staging.dojomojo.com:442/ws2',
  production: 'wss://www.dojomojo.com:441/ws2',
}
const BASE_URL = BASE_URLS[process.env.SENTRY_ENV] || 'ws://localhost:5000/ws2'
const DEFAULT_RETRY_WAIT = 2000 // ms
const RETRY_INCREASE_INTERVAL = 2000 // ms
const MAX_RETRY_WAIT = 60000 // ms

const mapStateToProps = ({ notifications: { liveQueue } }) => ({ liveQueue })

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
})

class LiveNotifications extends Component {
  static propTypes = {
    liveQueue: array.isRequired,
    notificationActions: object.isRequired,
  }

  retryWait = DEFAULT_RETRY_WAIT

  inFocus = true

  componentDidMount() {
    const {
      notificationActions: { load },
    } = this.props
    load()
    this.connect()
  }

  componentWillUnmount() {
    this.inFocus = false
    this.disconnect()
  }

  connect = () => {
    this.makeConnection()
    window.addEventListener('focus', this.retry)
    window.addEventListener('blur', this.cancel)
  }

  disconnect = () => {
    window.removeEventListener('focus', this.retry)
    window.removeEventListener('blur', this.cancel)
    this.ws.onclose = () => {}
    this.ws.close()
  }

  cancel = () => {
    this.inFocus = false
    clearTimeout(this.retryTimer)
  }

  retry = () => {
    this.retryWait = DEFAULT_RETRY_WAIT
    this.inFocus = true

    // If no Websocket exists or it is not in appropriate ready state, retry
    // WebSocket.CONNECTING = 0, WebSocket.OPEN = 1
    if (!this.ws || this.ws.readyState > 1) this.makeConnection()
  }

  retryWithBackoff = () => {
    if (!this.inFocus || !document.hasFocus()) return
    clearTimeout(this.retryTimer)
    console.log(`Retrying in ${Math.round(this.retryWait / 1000)} seconds`)
    this.retryTimer = setTimeout(() => this.makeConnection(), this.retryWait)
    // Increase retry wait by retry increase interval (up to maximum wait)
    this.retryWait = Math.min(this.retryWait + RETRY_INCREASE_INTERVAL, MAX_RETRY_WAIT)
  }

  getURL = () => {
    try {
      const imp = sessionStorage.getItem('impersonating')
      return imp ? `${BASE_URL}?impersonating=${imp}` : BASE_URL
    } catch (e) {
      return BASE_URL
    }
  }

  makeConnection = () => {
    // Don't connect if WebSocket already exists and is open
    // WebSocket.CONNECTING = 0, WebSocket.OPEN = 1
    if ((this.ws && this.ws.readyState <= 1) || !this.inFocus) return

    const { notificationActions: { handleNotification } } = this.props

    this.ws = new WebSocket(this.getURL())

    this.ws.onopen = () => {
      clearTimeout(this.retryTimer)
      console.log('WS 2 Connection Open')
    }
    this.ws.onmessage = event => handleNotification(event.data)
    this.ws.onerror = () => {}
    this.ws.onclose = () => {
      console.log('WS Connection Closed')
      this.retryWithBackoff()
    }
  }

  render() {
    const {
      enter, enterActive, exit, exitActive,
    } = styles
    const {
      liveQueue,
      notificationActions: { dismiss, read },
    } = this.props

    const items = liveQueue.map(item => (
      <CSSTransition
        key={item.id}
        classNames={{
          enter,
          enterActive,
          exit,
          exitActive,
        }}
        timeout={{ enter: 500, exit: 400 }}
      >
        <NotificationPopupItem item={item} handleDismiss={dismiss} handleRead={read} />
      </CSSTransition>
    ))

    return (
      <div
        style={{
          width: '400px',
          position: 'fixed',
          right: '32px',
          top: '80px',
          zIndex: '999',
        }}
      >
        <TransitionGroup children={items} />
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LiveNotifications)
)
