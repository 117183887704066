import React, { Component } from 'react'
import { Checkbox } from 'visual-components/util/form'
import InterestInput from './interest-input'

const ages = [
  { value: 'age_0_18', label: '0 - 18' },
  { value: 'age_18_24', label: '18 - 24' },
  { value: 'age_25_34', label: '25 - 34' },
  { value: 'age_35_44', label: '35 - 44' },
  { value: 'age_45_54', label: '45 - 54' },
  { value: 'age_55_up', label: '55 +' },
]

const renderCheckboxes = range => (
  <div key={range.value} style={{ display: 'inline-block', width: 'calc(100% / 3)', marginTop: '8px' }}>
    <div style={{ width: '75px' }}>
      <Checkbox name={range.value} label={range.label} />
    </div>
  </div>
)

class AgeTarget extends Component {
  render() {
    const ageCheckboxes = ages.map(renderCheckboxes)

    return (
      <InterestInput title="Target Age Range" sub="Your preferred reader age demographic.">
        <div style={{ marginBottom: '16px' }}>
          { ageCheckboxes }
        </div>
      </InterestInput>
    )
  }
}

export default AgeTarget
