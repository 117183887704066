import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import AppDispatcher from 'dispatchers/app-dispatcher'
import Validator from 'util/form-validator'

import Dialog from 'react-toolbox/lib/dialog'
import Field from 'visual-components/util/inputs/field'
import SelectField from 'visual-components/util/inputs/select-field'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { P, H4, H6 } from 'visual-components/util/texts';
import Checkbox from 'react-toolbox/lib/checkbox'
import CheckboxTheme from 'css/themes/Checkbox.css'
import TaskListTheme from 'css/themes/TaskList.css'
import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'


var validations = {
  contactName: [Validator.required],
  contactEmail: [Validator.required, Validator.isValidEmail, Validator.isValidReferral],
  inviteeBrandName: [Validator.required, Validator.UniqueBrandName],
}

var mapState = function (state) {
  return {
    platformInviteBrand: state.platformInviteBrand,
    platformInviteBrandForm: state.platformInviteBrandForm,
    currentCampaigns: state.brandCampaigns.currentCampaigns,
    upcomingCampaigns: state.brandCampaigns.upcomingCampaigns,
    currentBrand: state.currentBrand
  };
}

const themes = {
  Button: ButtonTheme,
  Dialog: DialogTheme,
}

class PlatformInviteModal extends Component {
  closeModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'platformInviteBrand',
      data: {
        open: false,
        contactName: '',
        contactEmail: '',
        inviteeBrandName: '',
        campaign_id: '',
      }
    });
    store.dispatch({
      type: 'CLEAR',
      model: 'platformInviteBrandForm'
    });
  }

  toggleShowCampaignList = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'platformInviteBrand',
      data: {
        showCampaignList: !this.props.platformInviteBrand.showCampaignList
      }
    })
  }

  sendInvite = () => {
    const { platformInviteBrand: {
      showCampaignList
    }} = this.props
    const finalValidations = {
      contactName: [Validator.required],
      contactEmail: [Validator.required, Validator.isValidEmail, Validator.isValidReferral],
      inviteeBrandName: [Validator.required, Validator.UniqueBrandName],
    }
    if (showCampaignList) {
      finalValidations['campaign_id'] = [Validator.required]
    }
    var that = this;
    Validator.validateForm(finalValidations, this.props.platformInviteBrand, 'platformInviteBrandForm', function (valid) {
      const data = { ...that.props.platformInviteBrand, ...(!showCampaignList && { campaign_id: '' }) } // don't send campaign_id if showCampaignList is false
      if (valid) {
        AppDispatcher.http({
          url: '/invite-brand-to-platform',
          method: 'POST',
          data,
        });
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'platformInviteBrand',
          data: {
            open: false,
            showSuccessModal: true
          }
        });
      }
    });
  }

  render() {
    var platformInviteBrand = this.props.platformInviteBrand
    var platformInviteBrandForm = this.props.platformInviteBrandForm;
    const canProceed = Object.keys(platformInviteBrandForm).every(key => platformInviteBrandForm[key].errors ? platformInviteBrandForm[key].errors.length === 0 : true)
    const { showCampaignList } = platformInviteBrand
    let potentialCampaigns = this.props.upcomingCampaigns;

    let campaignList = _.filter(potentialCampaigns, campaign => {
      return campaign.hostBrandId === this.props.currentBrand.id && (!campaign.endDate || new Date(campaign.endDate) > new Date());
    });

    let campaignListOptions = _.map(campaignList, (campaign, idx) => {
      return(
        {
          label: campaign.name,
          value: campaign.id
        }
      )
    })

    campaignListOptions.unshift({ label: 'Pick a Campaign', value: '' });

    return (
      <Dialog
        theme={ themes.Dialog }
        active={ platformInviteBrand.open }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title="Help Us Help You Grow"
      >
        <img
          onClick={ this.closeModal }
          className={ themes.Dialog.closeImage }
          src="/images/icons/close.svg"
        />

        <H4 multiline>Invite a brand to join DojoMojo - when they sign up, you'll receive a <em>$25 credit, and they will too.</em></H4><br/>
        <Field
          label="Contact Name*"
          attrName="contactName"
          dataModel={ platformInviteBrand }
          formModel={ platformInviteBrandForm }
          validations={ validations.contactName }
          placeholder="Mojo Tiger"
        />

        <Field
          label="Contact Email*"
          attrName="contactEmail"
          dataModel={ platformInviteBrand }
          formModel={ platformInviteBrandForm }
          validations={ validations.contactEmail }
          placeholder="hello@dojomojo.com"
        />

        <Field
          label="Brand Name*"
          attrName="inviteeBrandName"
          dataModel={ platformInviteBrand }
          formModel={ platformInviteBrandForm }
          validations={ validations.inviteeBrandName }
          placeholder="DojoMojo"
        />

        {
          campaignListOptions.length > 1 && 
          <Checkbox
            label={(
              <div style={{ display: 'flex', paddingTop: '1px' }}>
                <span className={TaskListTheme.taskLabel}>
                  Invite to Your Campaign
                </span>
              </div>
            )}
            theme={CheckboxTheme}
            checked={showCampaignList}
            onChange={this.toggleShowCampaignList}
            className={CheckboxTheme.referralCheckBox}
          />
        }
        
        {
          showCampaignList && 
          <div style={{marginTop: '-20px'}}>
            <SelectField
              label={ 'Campaign' }
              attrName="campaign_id"
              dataModel={ platformInviteBrand }
              formModel={ platformInviteBrandForm }
              validations={ [Validator.required] }
              options={ campaignListOptions }
              showErrorMessages={true}
            />
          </div>
        }

        <ModalButtonGroup
          canSave={ canProceed }
          confirm={ this.sendInvite }
          confirmText={ 'Send Invite' }
          cancel={ this.closeModal }
        />
      </Dialog>
    )
  }
};

export default connect( mapState )( PlatformInviteModal )
