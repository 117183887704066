import React from 'react'
import _ from 'lodash'

import PropTypes from 'prop-types'
import { Grid } from 'react-flexbox-grid'

class DojoGrid extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const gridStyles = _.extend(this.props.style || {}, {
      position: 'relative',
      width: '100%',
      paddingTop: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingLeft: '0',
    })

    return (
      <Grid fluid style={gridStyles}>
        {this.props.children}
      </Grid>
    )
  }
}

DojoGrid.propTypes = {
  style: PropTypes.object,
}

export default DojoGrid
