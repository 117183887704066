import React from 'react';
import _ from 'lodash';
import AdminRow from './admin-row';

class SweepsSummaryAggregate extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      totalVisits,
      totalEntries,
      totalSocialActions,
      dates
    } = this.props;

    const totalVisitsRow = <AdminRow title="Total Visits" vals={totalVisits.map(val => parseFloat(val).toLocaleString())} key="totalVisits" />;
    const totalEntriesRow = <AdminRow title="Total Entries" vals={totalEntries.map(val => parseFloat(val).toLocaleString())} key="totalEntries" />;
//    const totalSocialActionsRow = <AdminRow title="Total Social Actions" vals={totalSocialActions} />;
    const entriesPerVisit = _.zipWith(totalEntries, totalVisits, (entries, visits) => (entries / visits).toPrecision(2).toLocaleString());
    const entriesPerVisitRow = <AdminRow displayWrapper={this.props.formatFloat} title="Entries per visit" vals={entriesPerVisit} />;

    return (
      <tbody>
        <AdminRow title="Summary Aggregate Data" className="info" vals={dates} />
        { totalVisitsRow }
        { totalEntriesRow }
        { entriesPerVisitRow }
      </tbody>
    );
  }
}

export default SweepsSummaryAggregate;
