import React from 'react';
import SectionHr from 'util/components/section-hr'
import _ from 'lodash'

class TabTitleAndText extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var headers = _.map( this.props.headers, ( header, idx ) => {
      return(
        <div key={ idx }
          style={{
            paddingBottom: '30px'
        }}>
          { header }
        </div>
      )
    });

    var paras = _.map( this.props.paras, ( para, idx ) => {
      return(
        <div key={ idx }
          style={{
            paddingBottom: '20px'
        }}>
          { para }
        </div>
      )
    });

    return(
      <div>
        {/* headers */}
        { headers }

        {/* text */}
        { paras }
      </div>

    )
  }
};

export default TabTitleAndText;
