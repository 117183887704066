export const openModal = () => ({
  type: 'UPDATE_ATTR',
  model: 'contactSupport',
  data: {
    modalOpen: true
  }
});

export const closeModal = () => ({
  type: 'UPDATE_ATTR',
  model: 'contactSupport',
  data: {
    modalOpen: false
  }
});
