import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

import ClickContributionCard from 'visual-components/analytics/campaign/click-contribution-card';
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';

class ClickContribution extends React.Component {
  updateBrand = (brandId) => {
    var data = {
      metric: "campaignCustomCTA",
      campaignId: this.props.campaignId,
      specificBrandId: brandId
    };

    AnalyticsDispatcher.fetchReport(data, 'analyticsCampaign');
  }

  render() {
    var {
      data,
      started,
      isHost,
      isSolo,
    } = this.props;

    return (
      <ClickContributionCard { ...data }
        started={ started }
        isHost={ isHost }
        isSolo={ isSolo }
        onChange={ this.updateBrand }
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.analyticsCampaign.campaignCustomCTA,
    campaignId: state.analyticsCampaign.campaign.id,
    isHost: state.analyticsCampaign.isHost,
    isSolo: state.analyticsCampaign.campaign.isSolo,
  };
}

export default connect(mapStateToProps)(ClickContribution);
