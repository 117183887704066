import _ from 'lodash'

const InvoicesReducer = function (model, initialState) {

  const defaultState = _.extend({}, {
    rid: model,
    identifier: 'id',
    items: []
  }, initialState);

  return function (state, action) {
    if (action.model !== model) {
      return state || defaultState;
    }
    const actions = {
      UPDATE_ATTR: function () {
        return _.merge({}, state, action.data);
      },
      LOADING: function () {
        return _.extend({}, state, { loading: true });
      },
      LOAD_SUCCESS: function () {
        var data = _.extend({}, state, { loading: false });

        var belts = []
        var items = _.map(action.data, function (item) {
          const cleanId = (item.number && item.number.toUpperCase())
          || (item.id && item.id.split('_')[1].substring(0, 10).toUpperCase())
          || 'X'

          // if one-time charge
          if (item.object === 'charge') {
            return {
              id: item.id,
              cleanId,
              plan: item.description,
              amount: item.amount,
              period: 'once',
              date: item.created,
              paid: item.paid,
              raw: item,
            }
          }

          // filter out lines without plan
          const lines = item.lines.data.filter(({ plan }) => Boolean(plan))

          // use data for last line on invoice (for upgrades)
          let plan = null, period = null
          if (lines.length) {
            const last = lines[lines.length - 1]
            plan = last.plan.id
            period = last.period
            belts.push(plan)
          }

          return {
            id: item.id,
            cleanId,
            plan,
            amount: item.amount_due,
            period,
            date: item.date,
            paid: item.paid,
            raw: item
          }
        })
        return _.extend({}, data, { items: items, belts: _.uniq(belts) });
      },
      ADD_ITEM: function () {
        var data = _.extend({}, state);

        data.items.push(action.data);

        return data;
      },
      UPDATE_ATTR: function () {
        if (action.data.id) {
          var data = _.extend({}, state);
          var search = {};
          search[state.identifier] = action.data.id;

          var item = _.find(data.items, search);
          _.extend(item, action.data);
          return data;
        } else {
          return _.merge({}, state, action.data);
        }
      },
      UPDATE_ITEM: function () {
        var data = _.extend({}, state);

        var search = {};
        search[state.identifier] = action.data.id;

        var item = _.find(data.items, search);

        _.merge(item, action.data);

        return data;
      },
      REMOVE_ITEM_BY_ID: function () {
        var data = _.extend({}, state);

        var search = {};
        search[state.identifier] = action.data.id;
        _.remove(data.items, search);

        return data;
      }
    };

    const item = actions[action.type] || function () {
      return state || defaultState;
    };

    return item();
  };
}

export default InvoicesReducer;
