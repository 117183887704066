import React from 'react'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import { H1, H2 } from 'visual-components/util/texts';

const styles = {
  container: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    marginTop: 64,
    flexDirection: 'column',
    fontFamily: 'Larsseit',
    textAlign: 'center',
  },
  notFoundImage: {
    objectFit: 'contain',
  },
  title: {
    marginTop: 72,
    '& h1': {
      fontSize: 52,
      color: '#b7c3cd',
      fontWeight: 'bold',
    },
  },
  subtitle: {
    marginTop: 32,
    '& h2': {
      color: '#b7c3cd',
      fontFamily: 'Larsseit',
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },
}

const NotFound = props => {
  const {
    classes: css,
  } = props

  return (
    <div className={css.container}>
      <img
        className={css.notFoundImage}
        src="/images/error-message-icon.png"
      />
      <div className={css.title}>
        <H1 extraLarge>
          404: Page Not Found
        </H1>
      </div>
      <div className={css.subtitle}>
        <H2>
          Well, something didn't go right. Mojo is hard at work trying to fix any issues.
          <br />
          Try typing your desired web address again or visiting a different page.
        </H2>
      </div>
    </div>
  )
}

NotFound.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(styles)(NotFound)