import React from 'react'
import PropTypes from 'prop-types'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { P, H2, H3 } from 'visual-components/util/texts'

import MobileMarketingPostEntryPreview from './MobileMarketingPostEntryPreview'
import MobileMarketingPostEntryForm from './MobileMarketingPostEntryForm'
import MobileMarketingPostEntryMessageWrapper from './FollowupMessagesWrapper'
import AddFollowUpMessageButton from './AddFollowUpMessageButton'
import FollowUpMessagesCriteriaWrapper from './FollowUpMessagesCriteriaWrapper'
import { MobileMarketingContext } from './MobileMarketingContext'

function MobileMarketingPostEntryEdit(props) {
  const { smsSend, followups } = React.useContext(MobileMarketingContext)
  const { currentBrand, currentCampaign: { isSolo }, isWelcomeMessage } = props

  const showPostEntry = !isWelcomeMessage
  const showMessage = isWelcomeMessage || !isSolo

  const renderPostEntrySection = () => (
    <>
      <Row style={{ marginBottom: '62px' }}>
        <Col style={{ width: '560px', marginRight: '68px' }}>
          <MobileMarketingPostEntryForm />
        </Col>
        <Col style={{ width: '420px' }}>
          <MobileMarketingPostEntryPreview {...props} isEditing />
        </Col>
      </Row>
    </>
  )

  const renderMessageHeader = () => {
    if (isWelcomeMessage) {
      return (
        <>
          <div style={{ marginBottom: 12 }}>
            <H3>
              Compose Your Welcome Message
            </H3>
          </div>
          <P multiline>
            This will be the message that entrants will receive when they opt in from your sweepstakes entry form.
          </P>
        </>
      )
    }

    return (
      <H2>
        <strong>
          Compose Your Message
        </strong>
      </H2>
    )
  }

  const renderFolowups = () =>
    followups.map(followup => (
      <MobileMarketingPostEntryMessageWrapper
        isFollowup
        key={followup.id}
        message={followup}
        currentBrand={currentBrand}
        isWelcomeMessage={isWelcomeMessage}
      />
    ))

  const renderMessageContent = () => (
    <Row style={{ marginTop: '24px', marginBottom: '24px' }}>
      <MobileMarketingPostEntryMessageWrapper
        message={smsSend}
        currentBrand={currentBrand}
        isWelcomeMessage={isWelcomeMessage}
      />
      {followups.length > 0 && (
        <FollowUpMessagesCriteriaWrapper>
          {renderFolowups()}
        </FollowUpMessagesCriteriaWrapper>
      )}
      <AddFollowUpMessageButton />
    </Row>
  )

  const renderMessageSection = () => (
    <>
      {renderMessageHeader()}
      {renderMessageContent()}
    </>
  )

  return (
    <div>
      {showPostEntry ? (
        renderPostEntrySection()
      ) : null}
      {showMessage ? (
        renderMessageSection()
      ) : null}
    </div>
  )
}

MobileMarketingPostEntryEdit.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
  isWelcomeMessage: PropTypes.bool,
}

MobileMarketingPostEntryEdit.defaultProps = {
  isWelcomeMessage: false,
}

export default React.memo(MobileMarketingPostEntryEdit)
