import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Link, withRouter } from 'react-router-dom'
import Card from 'react-toolbox/lib/card'
import { P, H2 } from 'visual-components/util/texts'
import CustomerSuccessAdminPartnershipEditor from 'components/cs-admin/customer-success-admin-partnership-editor'
import CustomerSuccessAdminSuccessPageProportion from 'components/cs-admin/customer-success-admin-success-page-proportion'
import CustomerSuccessAdminCsvExport from 'components/cs-admin/customer-success-admin-csv-export'
import CustomerSuccessAdminEntrySeeder from 'components/cs-admin/customer-success-admin-entry-seeder'
import CustomerSuccessAdminImpersonate from 'components/cs-admin/customer-success-admin-impersonate'
import CustomerSuccessAdminFeatureFlags from 'components/cs-admin/customer-success-feature-flag'
import CustomerSuccessAdminEmailOverlap from 'components/cs-admin/customer-success-admin-email-overlap'
import CustomerSuccessAdminEspResults from 'components/cs-admin/customer-success-admin-esp-results'
import CustomerSuccessAdminAssociatedBrands from 'components/cs-admin/customer-success-admin-associated-brands'
import CustomerSuccessAdminDisableBrand from 'components/cs-admin/customer-success-admin-disable-brand'
import CustomerSuccessAdminUnlockUser from 'components/cs-admin/customer-success-admin-unlock-user'
import CustomerSuccessAdminMarkBrandsInterested from './customer-success-admin-mark-brands-interested'
import CSAdminBrandRatings from './customer-success-admin-brand-ratings'
import CSAdminMailchimpDoubleOptIn from './customer-success-admin-mailchimp-double-optin'
import CSAdminUpdateBrandName from './customer-success-admin-update-brand-name'
import CSAdminEditCampaignLaunch from './customer-success-edit-campaign-launch'

import CSAdminBrandManager from './brand-manager'

class CustomerSuccessAdminContainer extends React.Component {
  componentWillMount() {
    const { profile, history } = this.props
    if (!profile.isAdmin) {
      history.replace('/app')
    }
  }

  render() {
    const {
      match: {
        params: { section },
      },
    } = this.props

    if (section) {
      return (
        <Switch>
          <Route exact path="/cs-admin/brands" component={CSAdminBrandManager} />
        </Switch>
      )
    }

    return (
      <div
        className="container"
        style={{
          marginTop: '50px',
          marginBottom: '50px',
        }}
      >
        <CustomerSuccessAdminImpersonate />
        <CustomerSuccessAdminPartnershipEditor />
        <hr />
        <CustomerSuccessAdminSuccessPageProportion />
        <hr />
        <CustomerSuccessAdminCsvExport />
        {process.env.SENTRY_ENV !== 'production' ? (
          <div>
            <hr />
            <CustomerSuccessAdminEntrySeeder />
          </div>
        ) : null}
        <hr />
        <CustomerSuccessAdminEmailOverlap />
        <hr />
        <CustomerSuccessAdminEspResults />
        <hr />
        <CustomerSuccessAdminMarkBrandsInterested />
        <hr />
        <CSAdminUpdateBrandName />
        <hr />
        <CustomerSuccessAdminAssociatedBrands />
        <hr />
        <CustomerSuccessAdminDisableBrand />
        <hr />
        <CustomerSuccessAdminFeatureFlags />
        <hr />
        <CustomerSuccessAdminUnlockUser />
        <hr />
        <CSAdminBrandRatings />
        <hr />
        <CSAdminMailchimpDoubleOptIn />
        <hr />
        <CSAdminEditCampaignLaunch />
        <hr />
        <Link to="/cs-admin/brands">
          <Card style={{
            padding: '24px',
            marginBottom: '16px',
            cursor: 'pointer',
          }}>
            <H2 azure>Brand Manager</H2>
            <P multiline>- Add/Update Preferred Terms Plans</P>
            <P multiline>- Cancel Current Plan</P>
          </Card>
        </Link>
      </div>
    )
  }
}

const mapState = function (state) {
  return state
}

export default withRouter(connect(mapState)(CustomerSuccessAdminContainer))
