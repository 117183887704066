import React from 'react'
import BuilderStepHeader from 'components/campaigns/builder/builder-step-header'
import StepContent from 'components/campaigns/builder/step-content'
import LanderSectionHeader from 'components/campaigns/builder/lander/lander-section-header'
import TemplateOption from 'components/campaigns/builder/lander/template/template-option'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import CampaignBuilderSection from 'campaigns/builder/components/campaign-builder-section'
import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text'
import { H3, P } from 'visual-components/util/texts'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import * as metrics from 'util/metrics'
import { getClaim } from 'actions/brand-claim-actions'
import moment from 'moment'

const mapState = function (state) {
  return state
}

const oldTemplates = {
  'version-1': '/images/templates/version-1-updated.png',
  'version-2': '/images/templates/version-2-updated.png',
  'version-3': '/images/templates/version-3-updated.png',
  'version-4': '/images/templates/version-4-updated.png',
  'version-5': '/images/templates/version-5-updated.png',
  'version-6': '/images/templates/version-6-updated.png',
  'version-7': '/images/templates/version-7-updated.png',
  'version-9': '/images/templates/version-9-updated.png',
}

const templates = [
  ...Object.keys(oldTemplates).map(version => ({
    version: version.split('-').pop(),
    image: oldTemplates[version],
    name: '',
    show: false,
  })),
  {
    version: '10',
    image: '/images/templates/into-the-wild.png',
    name: 'Into the Wild',
  },
  {
    version: '11',
    image: '/images/templates/fall-style.png',
    name: 'Fall Style',
  },
  {
    version: '12',
    image: '/images/templates/gadgets.png',
    name: 'Gadgets',
  },
  {
    version: '13',
    image: '/images/templates/work-out.png',
    name: 'Work Out',
  },
  {
    version: '14',
    image: '/images/templates/home-sweet-home.png',
    name: 'Home Sweet Home',
  },
  {
    version: '15',
    image: '/images/templates/spice-it-up.png',
    name: 'Spice It Up',
    premium: true,
    video: '//s3-us-west-2.amazonaws.com/innovation-brand-logos/campaign-images/spice-it-up-video-thumbnail.mp4',
  },
]

class TemplateContainer extends React.Component {
  static propTypes = {
    currentCampaign: propTypes.object.isRequired,
    currentBrand: propTypes.object.isRequired,
  }

  componentDidMount() {
    document.title = 'Select Template - DojoMojo'
  }

  track = version => {
    const { currentCampaign } = this.props

    metrics.create('campaignManagerUsedTemplate', {
      meta: {
        version,
      },
    })

    if (currentCampaign.landerVersion) {
      metrics.create('campaignManagerSwitchedTemplate', {
        meta: {
          version,
        },
      })
    }
  }

  selectVersion = version => {
    this.track(version)

    CampaignDispatcher.selectVersionRequest(version)
  }

  renderTemplate = template => {
    const {
      currentBrand: {
        belt,
        trial_end,
      },
    } = this.props

    const version = `version-${template.version}`
    const canUsePremiumTemplate = getClaim('sweepstakes.premiumTemplate.use')

    return (
      <TemplateOption
        key={version}
        img={template.image}
        version={version}
        select={this.selectVersion}
        name={template.name}
        requiresUpgrade={!canUsePremiumTemplate}
        premium={template.premium === true}
        video={template.video || null}
      />
    )
  }

  render() {
    const {
      currentCampaign: campaign,
    } = this.props

    const paras = [
      <P multiline>
        Select from these templates to choose how your campaign's information will be graphically displayed. Note that changing your template after adding your content can be a bit tricky - we’re working on it!
      </P>
    ]

    return (
      <CampaignBuilderSection
        subSection={campaign.section}
      >
        <div className="row">
          <div className="col-sm-12">
            <TabTitleAndText
              headers={[
                <H3>
                  Template
                </H3>,
              ]}
              paras={paras}
            />
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridGap: 15,
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          {
            templates
              .filter(template => template.show !== false)
              .map(this.renderTemplate)
          }
        </div>
      </CampaignBuilderSection>
    )
  }
}

export default connect(mapState)(TemplateContainer)
