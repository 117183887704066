import React, { Component } from 'react'
import _ from 'lodash'


const renderGraph = function (data, handler) {
  $(`.${handler}`).html('');
  let { items } = data
  const { hideMap, percentHoverOnly } = data

  if (hideMap) {
    items = items.filter(item => {
      return !hideMap[item.id ? item.id : item.name]
    }).map(item => ({
      x: item.id ? item.id : item.name,
      y: item.y,
    }))
  }

  // This is being remapped if hideMap is truthy
  items = items.map(obj => ({
    x: obj.x,
    y: obj.y,
  }))

  let graph

  if (percentHoverOnly) {
    graph = new Contour({
      el: `.${handler}`,
      pie: {
        innerRadius: 32,
        outerRadius: 60,
        piePadding: {
          top: 0, right: 0, bottom: 0, left: 0
        }
      },
      animations: {
        enable: true
      }
    })
    .pie(items)
    .pieDonutPercentHoverText()
    .assignColors(items, {
      colorMap: data.colorMap
    })
    .render();
  } else {
    graph = new Contour({
      el: `.${handler}`,
      pie: {
        innerRadius: 32,
        outerRadius: 60,
        piePadding: {
          top: 0, right: 0, bottom: 0, left: 0
        }
      },
      animations: {
        enable: true
      }
    })
    .pie(items)
    .pieDonut()  
    .assignColors(items, {
      colorMap: data.colorMap
    })
    .render();
  }

  return graph
}

const renderHoverGraph = function (data, handler) {

  const hoverColorMap = {};

  _.each(data.colorMap, (color, key) => {
    hoverColorMap[`${key}-hover`] = color;
  });

  $(`.${handler}-hover`).html('');
  const items = data.items.map(obj => {
    return {
      x: `${obj.x}-hover`,
      y: obj.y
    }
  });

  const hoverGraph = new Contour({
    el: `.${handler}-hover`,
    pie: {
      innerRadius: 32,
      outerRadius: 66,
      piePadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      style(d) {
        return 'opacity: .5; display: none'
      },
    },
  })
    .pie(items)
    .pieDonut()
    .assignColors(items, {
      colorMap: hoverColorMap,
    })
    .render()

  return hoverGraph
}

class Donut extends Component {
  state = {
    id: `handler-${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
  }

  componentDidMount() {
    this.graph = renderGraph(this.props, this.state.id)
    this.hoverGraph = renderHoverGraph(this.props, this.state.id)
  }

  componentDidUpdate(prevProps) {
    renderGraph(this.props, this.state.id)
    renderHoverGraph(this.props, this.state.id)
  }

  render() {
    const { id } = this.state

    return (
      <div>
        <div
          className={`pie-donut pie-donut-abs ${id}`}
          style={{
            width: '126px',
            height: '126px',
          }}
        />
        <div
          className={`pie-donut-hover pie-donut-abs ${id}-hover`}
          style={{
            width: '138px',
            height: '138px',
          }}
        />
      </div>
    )
  }
}

export default Donut
