import React from 'react'
import { object, bool } from 'prop-types'
import JSONPretty from 'react-json-pretty'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import IntegrationTheme from 'css/themes/integrations.css'

class IntegrationTestResults extends React.Component {
  static propTypes = {
    testLoading: bool,
    testResults: object,
  }

  static defaultProps = {
    testLoading: false,
    testResults: {},
  }

  render() {
    const {
      testLoading,
      testResults,
    } = this.props

    return (
      <div>
        { testLoading
          ? <MaterialSpinner when={testLoading} />
          : testResults && testResults.response
            ? (
              <div className={`${IntegrationTheme.testResults} ${!testResults.success ? IntegrationTheme.error : ''}`}>
                <JSONPretty
                  className={`${IntegrationTheme.json} ${!testResults.success ? IntegrationTheme.error : ''}`}
                  json={testResults.response}
                />
              </div>
            ) : null
        }
      </div>
    )
  }
}

export default IntegrationTestResults
