import React from 'react';
import { bool } from 'prop-types';
import HeaderContainer from './header-container';
import LeftSide from './leftside';
import RightSide from './rightside';
import CenterSide from './centerside';

class Header extends React.Component {
  static propTypes = {
    lightBackground: bool
  }

  render() {
    let {
      lightBackground,
    } = this.props;

    return(
      <HeaderContainer>
        <LeftSide
          lightBackground={ lightBackground }
        />
        <CenterSide
          lightBackground={ lightBackground }
        />
        <RightSide
          lightBackground={ lightBackground }
        />
      </HeaderContainer>
    )
  }
}

export default Header;
