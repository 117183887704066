import React, { Component } from 'react'
import { H6 } from 'visual-components/util/texts'
import theme from 'css/themes/referralTheme.css'

class TableHeader extends Component {
  render() {
    return (
      <div className={`${theme.tableRow} ${theme.tableHeader}`}>
        <div className={theme.firstColumn}>
          <H6>
            Recipient
          </H6>
        </div>
        <div className={theme.secondColumn}>
          <H6>
            Brand
          </H6>
        </div>
        <div className={theme.thirdColumn}>
          <H6>
            Status
          </H6>
        </div>
        <div className={theme.fourthColumhn}>
          <H6>
            Credit
          </H6>
        </div>
        <div className={theme.fifthColumhn}>
        </div>
      </div>
    )
  }
}

export default TableHeader
