import React from 'react';
import * as ReactRedux from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';
import { array } from 'prop-types';
import { Checkbox } from 'react-toolbox';
import Promise from 'bluebird';
import _ from 'lodash';

import { H3, P } from 'visual-components/util/texts';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import COLOURS from 'util/colours';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import CheckboxTheme from 'css/themes/Checkbox.css';
import DialogTheme from 'css/themes/dialogs/formModal.css'

const themes = {
  Dialog: DialogTheme,
}

class ExportAnalyticsCsv extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkboxValues: [],
      allSelected: true,
      csvDataUrl: null
    }
  }

  static proptypes = {
    metrics: array
  }

  hideModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'exportAnalyticsModal',
      data: {
        active: false,
        metrics: null,
        checkboxValues: []
      }
    });
  }

  handleSelectOne = key => {
    let values = this.state.checkboxValues;
    values[parseInt(key) - 1] = !values[parseInt(key) - 1]

    this.setState({
      checkboxValues: values,
      allSelected: !this.hasUncheckedBox()
    })
  }

  hasUncheckedBox = () => {
    return this.state.checkboxValues.indexOf(false) != -1
  }

  handleSelectAll = () => {
    let values = this.state.checkboxValues

    if (this.hasUncheckedBox()) {
      values.fill(true)
    } else {
      values.fill(false)
    }

    this.setState({
      checkboxValues: values,
      allSelected: !this.hasUncheckedBox()
    })
  }

  downloadFile = (fileName, urlData) => {
    let aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;

    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  }

  sendCsv = () => {
    const { exportAnalyticsModal: { filename } } = this.props
    const that = this;

    Promise.map(this.props.exportAnalyticsModal.metrics, (metric, idx) => {
      if (!that.state.checkboxValues[idx]) {
        return Promise.resolve()
      }

      let csvString = '';
      let headerRow = ''
      if (metric.value && metric.value[0]) {
        headerRow = Object.keys(metric.value[0])
          .join(',')
      }

      // add sub title and header row
      csvString += metric.label + '\n'
      csvString += headerRow + '\n'

      // add the data rows
      csvString += metric.value.reduce(
        (prev, curr) => prev + Object.values(curr).join(',') + '\n',
        ''
      )

      return csvString
    }).then((res) => {
      const resultString = res.filter(item => item).join('\n')

      this.downloadFile(filename || 'analytics.csv','data:text/csv;charset=UTF-8,' + encodeURIComponent(resultString))
      this.hideModal()
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exportAnalyticsModal.metrics != this.props.exportAnalyticsModal.metrics) {
      let checkboxValues = _.map(this.props.exportAnalyticsModal.metrics, (metric) => {
        return typeof metric.checked !== 'undefined' ? metric.checked : true
      })

      this.setState({
        checkboxValues: checkboxValues
      })
    }
  }

  render() {
    var {
      exportAnalyticsModal
    } = this.props;

    let parent = this;
    let CheckBoxItems = _.map(exportAnalyticsModal.metrics, (metric, idx) => {
      return (
        <Col xs={6} style={{
          marginBottom: '16px'
        }}>
          <Checkbox
            key={ idx + 1 }
            label={ metric.label }
            checked={ this.state.checkboxValues[idx] }
            onChange={ this.handleSelectOne.bind(this, idx + 1) }
            theme={ CheckboxTheme }
          />
        </Col>
      )
    });

    return (
      <div>
        <Dialog
          theme={ themes.Dialog }
          active={ exportAnalyticsModal.active }
          onEscKeyDown={ this.hideModal }
          onOverlayClick={ this.hideModal }
        >
          <img
            onClick={ this.hideModal }
            className={ themes.Dialog.closeImage }
            src="/images/icons/close.svg"
          />
          <Grid>
            <Row>
              <Col xs={12} className='text-center' style={{
                marginBottom: '16px',
                marginTop: '20px'
              }}>
                <H3>Export Data to CSV</H3>
              </Col>
              <Col xs={12}>
                <P>Select the data you would like included in your download.</P>
              </Col>
            </Row>
            <Row>
              <Col xs={12} style={{
                marginTop: '16px',
                marginBottom: '16px'
              }}>
                <Row>
                  <Col xs={12} style={{
                    marginBottom: '16px'
                  }}>
                    <Checkbox
                      key={ 0 }
                      label={ 'All Charts' }
                      checked={ this.state.allSelected }
                      onChange={ this.handleSelectAll }
                      theme={ CheckboxTheme }
                    />
                  </Col>
                  { CheckBoxItems }
                </Row>
              </Col>
            </Row>
            <hr style={{
              borderColor: COLOURS.silver,
              marginTop: '-8px',
              marginRight: '-32px',
              marginBottom: '0',
              marginLeft: '-32px'
            }}/>
            <ModalButtonGroup
              canSave={ this.state.checkboxValues.indexOf(true) != -1 }
              canSave={ this.state.checkboxValues.indexOf(true) != -1 }
              hideLine={ true }
              confirm={ this.sendCsv }
              cancel={ this.hideModal }
              cancelText="Cancel"
              confirmText="Download"
            />
          </Grid>
        </Dialog>
      </div>
    )
  }
}

function mapState(state) {
  return {
    exportAnalyticsModal: state.exportAnalyticsModal
  };
}

export default ReactRedux.connect(mapState)(ExportAnalyticsCsv);
