import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import colors from 'util/colours'

const styles = {
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    border: `8px solid ${colors.silver}`,
    borderTop: `8px solid ${colors.azure}`,
    borderRadius: '50%',
    width: 64,
    height: 64,
    animation: 'spin 1s linear infinite',
  },
}

function Loader(props) {
  const { classes: css } = props

  return (
    <div className={css.wrapper}>
      <div className={css.loader} />
    </div>
  )
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(Loader))
