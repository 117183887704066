import React from 'react'
import { connect } from 'react-redux'

import Validator from 'util/form-validator'
import SingleDateSelect from 'util/components/single-date-select'
import PromotionalUnitDispatcher from 'dispatchers/promotional-unit-dispatcher'

import Dialog from 'react-toolbox/lib/dialog'
import Field from 'visual-components/util/inputs/field'
import SelectField from 'visual-components/util/inputs/select-field'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import DialogTheme from 'css/themes/dialogs/dialogBase.css'
import ModalTheme from 'css/themes/dialogs/modalBase.css'
import inputTheme from 'css/themes/Input.css'
import { H2, P } from 'visual-components/util/texts'

const validations = {
  brand: [Validator.required],
  promotionaltype: [Validator.required],
  label: [Validator.required],
  impressionsPer: [Validator.required]
}

const mapStateToProps = state => ({
  promotionalUnits: state.promotionalUnits,
  promotionalUnit: state.promotionalUnit,
  promotionalUnitForm: state.promotionalUnitForm
})

class TrackingLinkModal extends React.Component {
  state = {
    validForm: !!this.props.promotionalUnit.id
  }

  updatePromotionalUnit = (attr, value) => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'promotionalUnit',
      data: {
        [attr]: value
      }
    })

    Validator.validateFormAlt(validations, store.getState().promotionalUnit, 'promotionalUnitForm', valid => {
      this.setState({
        validForm: valid
      })
    })
  }

  updatePromotionalUnitPersist = () => {
    const { promotionalUnit } = this.props
    PromotionalUnitDispatcher.updateAttrPersist(promotionalUnit, () => {
      toastr.success('Link saved.', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
      this.closeEditModal()
    })
  }

  createPromotionalUnit = () => {
    const { promotionalUnit, campaignId } = this.props
    PromotionalUnitDispatcher.create(promotionalUnit, campaignId, () => {
      toastr.success('Link created.', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
      this.closeEditModal()
    })
  }

  removePromotionalUnit = () => {
    const { promotionalUnit, campaignId } = this.props
    PromotionalUnitDispatcher.remove(promotionalUnit, campaignId, () => {
      toastr.success('Link deleted.', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })

      store.dispatch({
        model: 'promotionalUnit',
        type: 'CLOSE_REMOVE_MODAL'
      })
    })
  }

  closeEditModal = () => {
    this.setState({
      validForm: false
    })

    store.dispatch({
      model: 'promotionalUnit',
      type: 'CLOSE_EDIT_MODAL'
    })
  }

  closeRemoveModal = () => {
    store.dispatch({
      model: 'promotionalUnit',
      type: 'CLOSE_REMOVE_MODAL'
    })
  }

  render() {
    const {
      isHost,
      startDate,
      endDate,
      promotionalUnits,
      promotionalUnit,
      promotionalUnitForm,
      selectedBrand
    } = this.props

    const { validForm } = this.state

    return (
      <div>
        <Dialog
          theme={ ModalTheme }
          active={ promotionalUnit.editModalOpen }
          onEscKeyDown={ this.closeEditModal }
          onOverlayClick={ this.closeEditModal }
          title={ promotionalUnit.id ? 'Edit Tracking Link' : 'Create New Tracking Link' }
        >
          <img
            onClick={ this.closeEditModal }
            className={ ModalTheme.closeImage }
            src="/images/icons/close.svg"
          />

          <div style={{
            marginBottom: '16px'
          }}>
            <P multiline>
              {
                promotionalUnit.id ?
                'Edit your promotional link for this giveaway.'
                : 'Create a promotional link for this giveaway.'
              }
            </P>
          </div>

          {
            !promotionalUnit.id && isHost ?
            <SelectField label={ 'Link Owner' }
              attrName={ 'brand' }
              options={ promotionalUnit.brandOptions }
              value={ selectedBrand }
              placeholder={ 'Select Brand' }
              dataModel={ promotionalUnit }
              formModel={ promotionalUnitForm }
              updateAttr={ this.updatePromotionalUnit }
            />
            : null
          }

          <SelectField label={ 'Promotional Channel' }
            attrName={ 'promotionaltype' }
            options={ promotionalUnit.typeOptions }
            placeholder={ 'Select Channel' }
            dataModel={ promotionalUnit }
            formModel={ promotionalUnitForm }
            updateAttr={ this.updatePromotionalUnit }
          />

          <Field label={ 'Label' }
            attrName={ 'label' }
            placeholder={ 'Name your link, i.e. “Facebook Ad Version 1”' }
            dataModel={ promotionalUnit }
            formModel={ promotionalUnitForm }
            updateAttr={ this.updatePromotionalUnit }
          />

          <Field type={ 'number' }
            label={ 'Number of Potential Impressions' }
            attrName={ 'impressionsPer' }
            dataModel={ promotionalUnit }
            formModel={ promotionalUnitForm }
            updateAttr={ this.updatePromotionalUnit }
          />

          <ModalButtonGroup canSave={ validForm }
            confirm={ promotionalUnit.id ? this.updatePromotionalUnitPersist : this.createPromotionalUnit }
            confirmText={ promotionalUnit.id ? 'Save Changes' : 'Generate Link' }
            cancel={ this.closeEditModal }
            cancelText={ 'Cancel' }
          />
        </Dialog>

        <Dialog
          theme={ DialogTheme }
          active={ promotionalUnit.removeModalOpen }
          onEscKeyDown={ this.closeRemoveModal }
          onOverlayClick={ this.closeRemoveModal }
          title={ 'Confirm Link Deletion' }
        >
          <div style={{
            marginBottom: '16px'
          }}>
            <P multiline>
              Are you sure you want to delete this link? Once deleted, it cannot be recovered and we will not be able to attribute entries for this channel.
            </P>
          </div>

          <ModalButtonGroup canSave hideLine
            confirm={ this.removePromotionalUnit }
            confirmText={ 'Delete Link' }
            cancel={ this.closeRemoveModal }
            cancelText={ 'Cancel' }
          />
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps)(TrackingLinkModal)
