import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import { P } from 'visual-components/util/texts';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import MobilePartnerContributions from 'visual-components/analytics/MobilePartnerContributions'
import CampaignMobileMarketingSummary from 'visual-components/analytics/CampaignMobileMarketingSummary.js'
import ExplorePageTitle from 'visual-components/shared/explore/explore-page-title';
import CampaignOverview from 'visual-components/analytics/campaign-overview';
import EntryContribution from './campaign/EntryContribution';
import PromoEntries from './campaign/PromoEntries';
import ClickContribution from './campaign/ClickContribution';
import SocialContribution from './campaign/SocialContribution';
import StatsByPartnerCard from 'visual-components/analytics/stats-by-partner-card';
import SocialGrowth from './campaign/SocialGrowth';
import GeographicMapDistributionCard from 'visual-components/analytics/geographic-map-distribution-card';
import GenderDistributionCard from 'visual-components/analytics/gender-distribution-card';
import IncomeDistributionCard from 'visual-components/analytics/income-distribution-card';
import Spinner from 'util/components/spinner';
import CampaignDispatcher from 'dispatchers/campaign-dispatcher';
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';
import CampaignConversion from './campaign/CampaignConversion'

const mapState = (state) => {
  return {
    analyticsCampaign: state.analyticsCampaign,
    currentBrand: state.currentBrand,
  }
}

class CampaignAnalytics extends Component {
  componentDidMount() {
    let {
      history,
      match: {
        params: {
          campaignId
        }
      },
    } = this.props;

    AnalyticsDispatcher.loadDefaultCampaignAnalytics(campaignId);
  }

  componentWillUnmount() {
    store.dispatch({
      model: 'analyticsCampaign',
      type: 'CLEAR'
    })
  }

  navToCampaignQuickview = () => {
    const { history, match: { params: { campaignId } } } = this.props
    history.push(`/partnerships/campaign/${campaignId}/quickview`)
  }

  exportCsv = () => {
    let {
      analyticsCampaign,
      currentBrand,
    } = this.props;

    let campaignDemographics = analyticsCampaign.campaignDemographics[0];

    let geographicDist = [];
    let incomeDist = [];
    let socialActionsChart = analyticsCampaign.campaignSocialActionsByType.raw;

    if (campaignDemographics) {
      Object.keys(campaignDemographics.state_distribution).forEach(key => {
        geographicDist.push({
          state: key,
          count: campaignDemographics.state_distribution[key].count,
          percentage: `${(campaignDemographics.state_distribution[key].percent * 100).toFixed(2)}%`
        })
      })

      campaignDemographics.income_distribution.distribution.map((income, idx) => {
        let incomeMap = ['< 35K', '35-49K', '50-74K', '75-99K', '100K+']

        incomeDist.push({income: incomeMap[idx], count: income})
      })
    }

    let minThresh = analyticsCampaign.campaignEntryContributionStatsByPartner.map((partner) => {
      if (analyticsCampaign.isHost) {
        return { brand: partner.accountname, entries: partner.entries, threshold: partner.threshold, percentage: `${(partner.percentage * 100).toFixed(2)}%` }
      } else {
        return { brand: partner.accountname, entries: partner.entries, percentage: `${(partner.percentage * 100).toFixed(2)}%` }
      }
    })


    let customCta = analyticsCampaign.campaignCustomCTA.data.map(item => {
      if (analyticsCampaign.isHost) {
        return { brand: item.accountname, entries: item.entries, percentage: `${(item.percentage * 100).toFixed(2)}%` }
      } else if (item.brandId == currentBrand.id) {
        return { brand: item.accountname, entries: item.entries, percentage: `${(item.percentage * 100).toFixed(2)}%` }
      }
    }).filter(item => item != undefined);

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'exportAnalyticsModal',
      data: {
        active: true,
        filename: analyticsCampaign.campaign.id ? `campaign_analytics_${analyticsCampaign.campaign.id}.csv` : `campaign_analytics.csv`,
        metrics: [
          {
            label: 'Line Chart - Visits',
            value: analyticsCampaign.campaignVisits.raw
          },
          {
            label: 'Line Chart - Entries',
            value: analyticsCampaign.campaignEntries.raw
          },
          {
            label: 'Line Chart - New To File',
            value: analyticsCampaign.campaignNTF.raw
          },
          {
            label: 'Line Chart - Social Actions',
            value: analyticsCampaign.campaignTotalSocialActions.raw
          },
          {
            label: analyticsCampaign.isHost ? 'Minimum Threshold' : 'Entry Contribution',
            value: minThresh
          },
          {
            label: 'Stats by Partner',
            value: analyticsCampaign.campaignStatsByPartner.raw
          },
          {
            label: `Social Actions ${analyticsCampaign.isHost ? '- All Partners' : ''}`,
            value: socialActionsChart
          },
          {
            label: `Custom CTA ${analyticsCampaign.isHost ? '- All Partners' : ''}`,
            value: customCta
          },
          {
            label: 'Entries - Geographic Distribution',
            value: geographicDist
          },
          {
            label: 'Entries - Gender Split',
            value: [campaignDemographics ? campaignDemographics.gender_distribution : null]
          },
          {
            label: 'Entries - Household Income',
            value: incomeDist
          },
          {
            label: 'Conversions',
            value: analyticsCampaign.campaignConversionDetails.csv,
          },
          {
            label: 'Entries By Channel',
            value:
              analyticsCampaign.campaignEntryContributionStatsByPartnerByPromoUnit.csv
          }
        ]
      }
    });
  }

  renderMobileAnalytics = campaignStarted => {
    const {
      analyticsCampaign,
    } = this.props

    const solo = analyticsCampaign.campaign.isSolo

    return (
      <>
        <Row>
          <Col
            style={{
              marginTop: '16px',
              width: '976px',
            }}
          >
            <CampaignMobileMarketingSummary
              data={{
                ...(
                  analyticsCampaign.campaignSmsSummary
                    ? analyticsCampaign.campaignSmsSummary[0]
                    : {}
                ),
                ...(
                  analyticsCampaign.campaignSmsRevenueSummary
                    ? analyticsCampaign.campaignSmsRevenueSummary[0]
                    : {}
                ),
              }}
              started={campaignStarted}
            />
          </Col>
        </Row>
        {!solo && (
          <Row>
            <Col
              style={{
                marginTop: '16px',
                width: '976px',
              }}
            >
              <MobilePartnerContributions
                data={analyticsCampaign.campaignPartnerSmsContributionPercent}
                started={campaignStarted}
              />
            </Col>
          </Row>
        )}
      </>
    )
  }

  render() {
    const {
      analyticsCampaign,
      currentBrand: {
        features: {
          block_sms,
        },
      },
    } = this.props
    const canViewSms = !block_sms

    if (analyticsCampaign.loading) {
      return (
        <Spinner />
      )
    }

    const statsByPartner = analyticsCampaign.campaignStatsByPartner
    const socialActionsByType = analyticsCampaign.campaignSocialActionsByType;
    const campaignDemographics = analyticsCampaign.campaignDemographics[0] || {};
    const campaignStarted = new Date(analyticsCampaign.campaign.startDate) <= new Date()

    const solo = analyticsCampaign.campaign.isSolo

    return (
      <InternalContentContainer width="976px">
        <div style={{
          width: '100%',
          background: COLOURS.cream
        }}>
          <Helmet>
            <title>Campaign Analytics</title>
          </Helmet>
          <Grid>
            <Row style={{
              paddingTop: '32px'
            }}>
              <Col xs>
                <ExplorePageTitle title={ analyticsCampaign.campaignName }
                  action={this.exportCsv}
                  actionLabel="Export CSV"
                  secondaryAction={this.navToCampaignQuickview}
                  secondaryActionLabel="Manage Campaign"
                  secondaryPrimaryColor
                />
              </Col>
            </Row>
            {!solo && (
              <Row style={{
                paddingTop: '12px'
              }}>
                <P multiline>
                  Use this detailed view to understand how specific partners performed in your campaign.
                </P>
              </Row>
            )}
            <Row style={{
              paddingTop: '12px'
            }}>
              <P multiline>
                <em>For this campaign, scrubbing was turned { analyticsCampaign.filterWebbula ? 'on' : 'off'}.</em>
              </P>
            </Row>
            <Row
              style={{
                paddingTop: '24px',
              }}
            >
              <Col
                style={{
                  width: '646px',
                  marginRight: '16px',
                }}
              >
                <CampaignOverview
                  {...analyticsCampaign}
                  canViewSms={canViewSms}
                  started={campaignStarted}
                />
              </Col>
              <Col
                style={{
                  width: '314px',
                }}
              >
                <EntryContribution started={campaignStarted} />
              </Col>
            </Row>
            {!solo && (
              <Row>
                <Col
                  style={{
                    width: '976px',
                  }}
                >
                  <StatsByPartnerCard
                    canViewSms={canViewSms}
                    data={statsByPartner}
                    started={campaignStarted}
                  />
                </Col>
              </Row>
            )}
            { !!canViewSms && this.renderMobileAnalytics(campaignStarted)}
            <Row style={{
              paddingTop: '16px'
            }}>
              <Col style={{
                width: '646px',
                marginRight: '16px'
              }}>
                <SocialGrowth />
              </Col>
              <Col style={{
                width: '314px'
              }}>
                <SocialContribution started={campaignStarted} />
              </Col>
            </Row>
            <Row>
              <GenderDistributionCard data={ campaignDemographics.gender_distribution }/>
              <IncomeDistributionCard data={ campaignDemographics.income_distribution }/>
              <PromoEntries />
            </Row>
            <Row style={{
              paddingTop: '16px'
            }}>
              <Col style={{
                width: '646px',
                marginRight: '16px'
              }}>
                <GeographicMapDistributionCard data={ campaignDemographics.state_distribution }/>
              </Col>
              <Col style={{
                width: '314px'
              }}>
                <ClickContribution started={campaignStarted} />
              </Col>
            </Row>
            <Row style={{
              paddingTop: '16px'
            }}>
              <CampaignConversion />
            </Row>
          </Grid>
        </div>
      </InternalContentContainer>
    )
  }
}

export default withRouter(connect(mapState)(CampaignAnalytics));
