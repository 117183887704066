import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Card } from 'react-toolbox/lib/card'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'

import TransactionItem from 'visual-components/brandSettings/transaction-item'
import { H2, H3, H6, P } from 'visual-components/util/texts'

import { loadTransactionHistory } from 'actions/brand-account-actions'

const mapStateToProps = ({ brandAccount: { transactions, dealTransactions, transactionsLoading: loading } }) => ({
  transactions,
  loading,
  dealTransactions,
})
const mapDispatchToProps = dispatch => ({
  loadTransactions: () => dispatch(loadTransactionHistory()),
})

class TransactionHistory extends Component {
  state = { selectedTransaction: null }

  componentDidMount() {
    const { loadTransactions } = this.props
    loadTransactions()
  }

  setSelectedTransaction = selectedTransaction => {
    this.setState({ 
      selectedTransaction: selectedTransaction === this.state.selectedTransaction
        ? null
        : selectedTransaction,
    })
  } 

  mapTransactionItems = () => {
    const { selectedTransaction } = this.state
    const { dealTransactions } = this.props
    return dealTransactions.map(item => {
      const { id } = item
      return (
        <TransactionItem
          key={id}
          item={item}
          isSelected={id === selectedTransaction}
          setSelected={this.setSelectedTransaction}
        />
      )
    })
  }

  render() {
    const { loading } = this.props
    return (
      <div
        style={{
          paddingTop: '32px',
        }}
      >
        <Helmet>
          <title>Transaction History</title>
        </Helmet>
        <Grid>
          <Row
            style={{
              marginBottom: '12px',
            }}
          >
            <Col xs>
              <H2 azure>Transaction History</H2>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: '24px',
            }}
          >
            <Col xs>
              <P multiline>
                If you are paid by or have paid another brand on DojoMojo, you
                can view your transaction history here.
              </P>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: '24px',
            }}
          >
            <Col xs>
              <H3 coral>
                <small>Recent Transactions</small>
              </H3>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '976px' }}>
              <table
                style={{
                  marginBottom: '6px',
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '190px',
                        paddingLeft: '16px',
                      }}
                    >
                      <H6>Date</H6>
                    </th>
                    <th style={{ 
                      width: '496px',
                      paddingLeft: '36px',
                    }}>
                      <H6>Description</H6>
                    </th>
                    <th
                      style={{
                        width: '90px',
                        textAlign: 'right',
                        paddingLeft: '26px',
                      }}
                    >
                      <H6>Amount</H6>
                    </th>
                    <th
                      style={{
                        width: '164px',
                        paddingLeft: '36px',
                      }}
                    >
                      <H6>Status</H6>
                    </th>
                    <th
                      style={{
                        width: '36px',
                        paddingRight: '12px',
                      }}
                    />
                  </tr>
                </thead>
              </table>
              <MaterialSpinner when={loading} />
              {!loading ? (
                <Card
                  style={{
                    width: '976px',
                  }}
                >
                  <table>{this.mapTransactionItems()}</table>
                </Card>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory)
