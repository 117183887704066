import React, { Component } from 'react'
import DropdownField from './dropdown-field'
import theme from 'css/themes/custom-input-theme.css'
import { P } from 'visual-components/util/texts'

import CustomFieldDispatcher from 'dispatchers/custom-field-dispatcher'

class DropdownForm extends Component {
  addOption = () =>
    CustomFieldDispatcher.addOptionToCustomField(this.props.index)

  deleteOption = subIndex =>
    CustomFieldDispatcher.deleteOptionFromCustomField(
      this.props.index,
      subIndex
    )

  updateOptionVal = (_, val, subIndex) =>
    CustomFieldDispatcher.updateOptionValue(this.props.index, subIndex, val)

  render() {
    let { data, disabled } = this.props

    let options = data.options.map((option, idx) => {
      return (
        <div className={theme.option} key={idx}>
          <DropdownField
            updateOptionVal={this.updateOptionVal}
            data={data.options}
            option={option}
            index={idx}
            canRemove={data.options.length > 2}
            remove={this.deleteOption}
            disabled={disabled}
          />
        </div>
      )
    })

    return (
      <div>
        <div className={theme.inputHolder}>{options}</div>
        {!disabled && (
          <div className={theme.addOption}>
            <P>
              <a onClick={this.addOption}>Add Response Option</a>
            </P>
          </div>
        )}
      </div>
    )
  }
}

export default DropdownForm
