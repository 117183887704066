const initialState = {
  showPartnershipInterestsModal: false,
  showAudienceDescriptionModal: false,
  showGroupAgeDistributionModal: false,
  showGenderDistributionModal: false,
  showMediaSheetModal: false,
}

export default function sellerProfileModalsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'SHOW_SELLER_PROFILE_MODAL': {
      const modalName = `show${payload[0].toUpperCase()}${payload.slice(1)}Modal`

      const newState = { ...state, [modalName]: true }

      return newState
    }
    case 'HIDE_SELLER_PROFILE_MODAL': {
      return { ...initialState }
    }
    default:
      return state
  }
}
