import React, { Component } from 'react';
import PricingColumn from './pricing-column';

import theme from 'css/themes/mobile/home/pricing-column.css'

class PricingColumns extends Component {
  render() {
    let {
      isAnnual,
      referralURLExtension
    } = this.props;

    return (
      <div className={ theme.columnWrapper }>
        <PricingColumn
          isAnnual={ isAnnual }
          headline={ 'Basic' }
          imgSrc={ '/images/pricing/white-belt-tiger.svg' }
          subHeader="Discover and be discovered"
          monthlyPrice={ 0 }
          annualPrice={ 0 }
          listItems={[
            '5 Mojo messages',
            'Post partnership opportunities',
            'Get invited to sweepstakes campaigns',
          ]}
          buttonText={ 'Sign Up Free' }
          blueButton={ true }
          belt={ 'white' }
          referralURLExtension={ referralURLExtension }
        />
        <PricingColumn
          isAnnual={ isAnnual }
          headline={ 'Essential' }
          imgSrc={ '/images/pricing/blue-belt-tiger.svg'}
          subHeader="Find the right connections and partners"
          monthlyPrice={ 199 }
          annualPrice={ 159 }
          listItems={[
            '15 monthly Mojo messages',
            'Post partnership opportunities',
            'Get invited to sweepstakes campaigns',
            'Host your own campaigns or apply to open campaigns',
          ]}
          buttonText={ 'Start Free Trial' }
          blueButton={ true }
          belt={ 'greenFixed' }
          referralURLExtension={ referralURLExtension }
        />
        <PricingColumn
          isAnnual={ isAnnual }
          headline={ 'Premium' }
          imgSrc={ '/images/pricing/black-belt-tiger.svg' }
          subHeader="Our entire network at your fingertips"
          monthlyPrice={ 499 }
          annualPrice={ 399 }
          listItems={[
            'Unlimited Mojo messages',
            'Post partnership opportunities',
            'Get invited to sweepstakes campaigns',
            'Host your own campaigns or apply to open campaigns',
            'Gain more traffic and grow your social audience',
          ]}
          buttonText={ 'Start Free Trial' }
          blueButton={ false }
          bannerText={ 'Most Popular' }
          belt={ 'black' }
          referralURLExtension={ referralURLExtension }
        />
        <PricingColumn
          isAnnual={ isAnnual }
          headline={ 'Elite' }
          imgSrc={ '/images/pricing/red-belt-tiger.svg' }
          subHeader={ 'Acquire thousands of emails - we’ll do all the work' }
          learnMoreButton = { true }
          listItems={[
            'Everything in Premium',
            'Guaranteed minimum email acquisition per month',
            'Fully-managed campaign services',
          ]}
          buttonText={ 'Contact Us' }
          blueButton={ true }
          belt={ 'sensei' }
          mailTo={ 'mailto:hello@dojomojo.com' }
          referralURLExtension={ referralURLExtension }
        />
      </div>
    )
  }
}

export default PricingColumns;
