import React from 'react'

import RangeFilter from 'visual-components/shared/filters/RangeFilter'
import CheckBoxFilter from 'visual-components/shared/filters/CheckBoxFilter'
import RadioFilter from 'visual-components/shared/filters/RadioFilter'
import CustomDateRange from 'visual-components/util/filters/custom-date-range'

const CampaignsOverviewFilters = () => (
  <div>
    <RadioFilter
      title="Date Range"
      name="dateRange"
      customOption={<CustomDateRange name="dateRange" />}
    />
    <RangeFilter
      title="Visits"
      name="totalVisits"
      range={{ min: 0, max: 70000 }}
    />
    <RangeFilter
      title="Entries"
      name="totalEntries"
      range={{ min: 0, max: 50000 }}
    />
    <RangeFilter
      title="% NTF"
      name="percentNTF"
      range={{ min: 0, max: 100 }}
      format={num => (num ? `${num}%` : 0)}
    />
    <RangeFilter
      title="# of Partners"
      name="numberOfPartners"
      range={{ min: 0, max: 10 }}
    />
    <CheckBoxFilter
      title="Industry"
      selectAllOption="All industries"
      seeMoreText="See all industries"
      seeLessText="See less industries"
      hasNestedCollapse
      name="industry"
    />
  </div>
)

export default CampaignsOverviewFilters
