import React, { Component } from 'react'
import { string, element } from 'prop-types'

import RadioList from 'visual-components/util/filters/radio-list'
import FilterBox from './filter-box'

class RadioFilter extends Component {
  static propTypes = {
    title: string.isRequired,
    name: string.isRequired,
    tooltip: string,
    customOption: element,
  }

  static defaultProps = {
    tooltip: null,
    customOption: {},
  }

  render() {
    const {
      title,
      name,
      tooltip,
      customOption,
    } = this.props

    return (
      <FilterBox
        width="184px"
        title={title}
        tooltip={tooltip}
        paddingBot="5px"
        hasNestedCollapse={!!customOption}
        name={name}
      >
        <RadioList name={name} customOption={customOption} />
      </FilterBox>
    )
  }
}

export default RadioFilter
