import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as MessagingPartnerActions from 'actions/messaging-partner-actions'
import Dialog from 'react-toolbox/lib/dialog'
import { P } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { Input, Form, InputContainer } from 'visual-components/util/form'
import LegalDetailsForm from 'container-components/partnership_agreement/legal-details-form'
import CampaignPartnerDetails from 'visual-components/campaignProfile/campaign-partner-details'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher'

import d3 from 'd3'
import moment from 'moment-timezone'

import DialogTheme from 'css/themes/dialogs/formModal.css'
import inputTheme from 'css/themes/no-padding-input.css'

const mapStateToProps = state => {
  return {
    open: state.interestedInfoModal.open,
    data: state.interestedInfoModal.data,
    invite_id: state.interestedInfoModal.invite_id,
    brand: state.currentBrand,
    applicationData: state.partnershipApplication.applicationData,
    datePickerOpen: state.partnershipApplication.datePickerOpen,
    user_id: state.profile.id,
    dedicatedListSize: state.currentBrand.dedicatedListSize,
    expectedEntries: getExpectedEntries(state)
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(MessagingPartnerActions, dispatch)
})

const getExpectedEntries = state => {
  const { averageSignUpsPerCampaign, dedicatedListSize } = state.currentBrand
  return Math.floor(averageSignUpsPerCampaign || (dedicatedListSize * 0.01))
}


class InterestedInfoModal extends Component {
  state = {
    step: 0
  }

  hideModal = () => {
    this.props.actions.toggleInterestedInfoModal(null, {campaign: {}}, false)

    this.setState({
      step: 0
    })
  }

  getMergedLegalData = () => {
    const { applicationData, brand } = this.props
    return Object.keys(applicationData.legalDetails).reduce((acc, curr) => {
      acc[curr] = applicationData.legalDetails[curr] || brand[curr]
      return acc
    }, {})
  }

  showLegal = () => {
    const { applicationData, invite_id, actions} = this.props

    this.setState({
      step: 1
    })

    actions.updatePartnerDetails(invite_id, applicationData)
  }

  updateValue = name => value => {
    this.props.actions.updateData({
      legalDetails: {
        ...this.getMergedLegalData(),
        [name]: value,
      },
    })
  }

  componentDidMount() {
    this.props.actions.updateData({
      legalDetails: {
        ...this.getMergedLegalData()
      },
    })
  }

  confirm = () => {
    const { actions, applicationData, data, brand, user_id } = this.props

    actions.updatePartnerLegal(applicationData.legalDetails)

    this.hideModal()

    actions.sendInterest(
      data.invite_id,
      data.message_id,
      data.conversation_id,
      data.messageType,
      {
        campaignName: data.campaign.name,
        brandName: brand.accountname,
        entryContribution: applicationData.entryContribution,
        promoDates: applicationData.promotionStartDate ? `${moment(applicationData.promotionStartDate).format('MMMM D, YYYY')} - ${moment(applicationData.promotionEndDate).format('MMMM D, YYYY')}` : null,
        prizingContribution: applicationData.prizingContribution,
        note: applicationData.optionalNote,
        application: data.application,
        campaignId: data.campaign.id
      }
    )
    actions.toggleInterestedConfirmationModal(true, {
      application: data.application,
      campaign_name: data.campaign.name,
      brand_name: data.brand_name,
      campaign_id: data.campaign.id
    })

    MessagingDispatcher.loadConversations()
  }

  render() {
    let { open, applicationData, data, actions, dedicatedListSize, expectedEntries } = this.props
    dedicatedListSize = dedicatedListSize.toLocaleString()

    let steps = [
      <Form
        modelName="partnershipApplication"
        updateModel={actions.updateData}
        handleSubmit={this.showLegal}
        mergeFieldsImmediately={true}
      >
        <CampaignPartnerDetails
          updateData={ this.props.actions.updateData }
          applicationData={ applicationData }
          campaign={data.campaign}
          datePickerOpen={ this.props.datePickerOpen }
          toggleDatePicker={ this.props.actions.toggleDatePicker }
          partnerExpectedEntries={ expectedEntries }
          partnerListSize= { dedicatedListSize }
          toolTip={ 'We calculate this based on 1% of your list size or the average of your last three campaigns.' }
        />
        <ModalButtonGroupWithContext
          cancel={ this.hideModal }
          canSave
          cancelText="Cancel"
          confirmText="Next"
        />
      </Form>,
      <LegalDetailsForm
        confirm={ this.confirm }
        updateValue={ this.updateValue }
        closeModal={ this.hideModal }
        formData={ applicationData.legalDetails }
        confirmText="Send Interest"
        required={ false }
      />
    ]

    return (
      <Dialog
        active={ open }
        theme={ DialogTheme }
        onEscDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
        title={ this.state.step ? 'Optional - Legal Details' : 'Optional - Confirm Partner Details' }
      >
        <img
          onClick={ this.hideModal }
          className={ DialogTheme.closeImage }
          src="/images/icons/close.svg"
          />
        <P style={{
          fontSize: '16px',
          marginBottom: '16px'
        }}>
          { this.state.step ?
            "Step 2 of 2: Add your brand's information, which the Host brand will use to create a Partnership Agreement. If you can't fill this out now, don't worry - you can complete this prior to finalizing the agreement with the Host."
          : "Step 1 of 2: Let the host know what you can offer for prizing contribution."
          }
        </P>
        { steps[this.state.step] }
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InterestedInfoModal)
