import React, { PureComponent as Component } from 'react'
import { object, bool } from 'prop-types'
import DisplayedStat from '../util/displayed-stat'

class StatsDisplay extends Component {
  static propTypes = {
    campaign: object.isRequired,
    live: bool,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const {
      currentBrand = {},
      totalImpressions,
      totalVisits,
      entriesDriven = currentBrand.entries,
    } = this.props.campaign

    const {
      live,
    } = this.props

    return (
      <div>
        <DisplayedStat live={live} name="impressions" value={totalImpressions} />
        <div style={{ padding: '0 18px', display: 'inline-block' }}>
          <DisplayedStat live={live} name="visits" value={totalVisits} />
        </div>
        <DisplayedStat live={live} name="entries driven" value={entriesDriven} />
      </div>
    )
  }
}

export default StatsDisplay
