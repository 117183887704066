import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Dialog } from 'react-toolbox/lib/dialog'
import COLOURS from 'util/colours'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4, H2 } from 'visual-components/util/texts'

const styles = {
  dialog: {
    width: 792,
    '& section': {
      padding: '0 0 0 0',
    },
  },
  imageContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: 332,
    width: '100%',
    backgroundColor: COLOURS.eggshell,
  },
  image: {
    width: '100%',
  },
  closeIcon: {
    height: '14px',
    width: '14px',
    position: 'absolute',
    top: '17px',
    right: '17px',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'Larsseit-Medium',
    fontSize: '20px',
    lineHeight: '20px',
    color: COLOURS.ink,
    marginBottom: 24,
    textAlign: 'center',
  },
  subText: {
    padding: '32px 32px 16px 32px',
    display: 'grid',
    gridTemplateColumns: '160px 1fr',
    gridColumnGap: '32px',
    '& h2': {
      fontFamily: 'Larsseit-Medium, sans-serif',
      lineHeight: '36px',
      color: '#172931', // charcoalDarker
    },
    '& h4': {
      fontFamily: 'Larsseit-Regular, sans-serif',
      lineHeight: '24px',
      color: '#172931', // charcoalDarker
    },
  },
  buttonGroup: {
    margin: '0 32px 32px 32px',
  },
}

function SmsWelcomeModal(props) {
  const { confirm, close, isHost, classes } = props

  const copy = {
    title: 'Rapidly Grow Your SMS List',
    detail: isHost
      ? 'Sweepstakes are the perfect opportunity to invite consumers to sign up for your texts. Collect SMS consent on the giveaway’s Post Entry page and drive revenue with a triggered welcome message.'
      : 'Sweepstakes are the perfect opportunity to invite consumers to sign up for your texts. Request Split Traffic from the host to collect SMS consent on the Post Entry page and drive revenue with a welcome message.',
    cta: {
      confirm: isHost ? 'Set it up' : 'Request traffic',
      cancel: 'Maybe later',
    },
  }

  return (
    <Dialog
      theme={{ dialog: classes.dialog, title: classes.title }}
      active
      onEscKeyDown={close}
      onOverlayClick={close}
      className={classes.smallShadow}
    >
      <div className={classes.imageContainer}>
        <img src="/images/sms-grow-your-list-modal.jpg" className={classes.image} />
        <img
          onClick={close}
          src="/images/icons/messaging-icons/close-x-icon-white.svg"
          className={classes.closeIcon}
        />
      </div>
      <div className={classes.subText}>
        <H2>{copy.title}</H2>
        <H4>{copy.detail}</H4>
      </div>
      <div className={classes.buttonGroup}>
        <ModalButtonGroup
          canSave
          confirm={confirm}
          cancel={close}
          confirmText={copy.cta.confirm}
          cancelText={copy.cta.cancel}
          hideLine
        />
      </div>
    </Dialog>
  )
}

SmsWelcomeModal.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(SmsWelcomeModal)
