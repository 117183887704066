import React, { PureComponent as Component } from 'react'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { Card } from 'react-toolbox/lib/card'

import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header'
import EmptyState from 'visual-components/util/graphs/empty-state'
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option'

import Donut from 'visual-components/util/graphs/donut'

class FollowersPie extends Component {
  render() {
    const {
      oldestDate,
      items,
      hideMap,
      toggle,
      colorMap,
      changeFilter,
      alignLeft,
      datePicker,
    } = this.props

    const socialOptions = items.map(item => (
      <ChartOption {...item} onClick={toggle} selected={!hideMap[item.name]} key={item.name} />
    ))

    return (
      <Card style={{ width: '314px', height: '242px', marginTop: '16px', overflow: 'visible' }}>
        <div style={{ padding: '20px 24px', overflow: 'hidden' }}>
          <AnalyticsHeader
            title="Social Followers Summary"
            tooltipText="A percentage breakdown of the social followers gained through campaigns"
          />
          {
            items.some(item => item.y > 0)
              ? (
                <Col style={{ width: '186px', marginBottom: '20px', position: 'relative', height: '106px' }}>
                  <Row>
                    <Col>
                      {socialOptions}
                    </Col>
                    <div
                      style={{
                        position: 'absolute',
                        top: '0px',
                        left: '80px',
                        width: '100px',
                        height: '100px',
                      }}
                    >
                      <Donut colorMap={colorMap} items={items} hideMap={hideMap} />
                    </div>
                  </Row>
                </Col>
              ) : (
                <EmptyState
                  text="You haven't acquired any social followers yet. Join a campaign to start growing your social following."
                  height="126px"
                />
              )
          }
        </div>
        <CardDateRangePickerRow
          oldestDate={oldestDate}
          changeFilter={changeFilter}
          alignLeft={alignLeft}
          {...datePicker}
        />
      </Card>
    )
  }
}

export default FollowersPie
