import React from 'react'
import theme from 'css/themes/homePage/oneStopHome.css'
import OneStopItem from './one-stop-item'

class OneStopHome extends React.Component {
  render() {
    return (
      <div className={theme.container}>
        <p className={theme.title}>Powered by Partnerships</p>
        <p className={theme.howItWorks}>How DojoMojo Works</p>
        <div className={theme.itemContainer}>
          <OneStopItem
            icon="find-brand-partners-illustration"
            title="Create Partnerships"
            describe="Partner with thousands of like-minded brands in our network to join or host a sweepstakes."
          />
          <OneStopItem
            icon="grow-your-audience-illustration"
            title="Grow Your Audience"
            describe="Leverage both solo and multi-brand sweepstakes to grow Email and SMS lists faster and cheaper than ever before."
          />
          <OneStopItem
            icon="coin-gate"
            title="Drive Revenue"
            describe="Engage and convert your audience via DojoMojo's SMS Marketing capabilities while still using your preferred ESP. "
          />
        </div>
      </div>
    )
  }
}

export default OneStopHome
