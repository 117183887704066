import React from 'react'
import Theme from 'css/themes/media-market/DealCollaborate.css'
import propTypes from 'prop-types'
import classNames from 'classnames'
import { Small } from 'visual-components/util/texts'

const ViewerComment = function (props) {
  const {
    id,
    x_coord: x,
    y_coord: y,
    index,
    zoomProportion,
    onClick: parentOnClick,
    selected,
  } = props

  const onClick = event => {
    event.stopPropagation()
    parentOnClick(id, 'viewer')
  }
  const styles = {
    left: x * zoomProportion,
    top: y * zoomProportion,
  }

  if (id === null) {
    styles.transition = 'all 0s ease 0s'
  }

  return (
    <div
      onClick={onClick}
      className={classNames(Theme.commentBubble, { [Theme.commentBubbleSelected]: selected })}
      style={styles}
    >
      <Small white>
        {index + 1}
      </Small>
    </div>
  )
}

ViewerComment.propTypes = {
  id: propTypes.number.isRequired,
  index: propTypes.number.isRequired,
  x_coord: propTypes.number.isRequired,
  y_coord: propTypes.number.isRequired,
  zoomProportion: propTypes.number.isRequired,
  onClick: propTypes.func.isRequired,
  selected: propTypes.bool.isRequired,
}

export default ViewerComment
