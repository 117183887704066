import React from 'react';
import { Card } from 'react-toolbox/lib/card';

import AnalyticsTheme from 'css/themes/analytics.css';
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header';
import ContributionBar from 'visual-components/analyticsDashboard/overviewCard/contribution-bar';
import EmptyState from 'visual-components/util/graphs/empty-state';


class EntryContributionCard extends React.Component {
  render() {
    let {
      data,
      started,
    } = this.props;

    let hasData = data && data.some(brand => brand.entries > 0 )

    return (
      <Card className={ `${AnalyticsTheme.ctaBarCard} ${AnalyticsTheme.contributionCard}`  }>
        <AnalyticsHeader title="Social Contribution" />
        {
          hasData ?
          <ContributionBar name="social"
            data={ data }
            started={ started }
          />
          :
          <EmptyState text={ 'Social Actions is a powerful feature reserved for Essential and Premium DojoMojo users.' }
            learnMore={ 'https://help.dojomojo.com/hc/en-us/articles/230426167-Add-Partner-Social-Actions-' }
            height={ '380px' }
            bigText
          />
        }  
      </Card>
    );
  }
}

export default EntryContributionCard;
