import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'

import AppBar from 'react-toolbox/lib/app_bar'
import { H6 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'

import appbarTheme from 'css/themes/homePage/appbarTheme.css'
import buttonTheme from 'css/themes/Buttons.css'

class PublicCTAHeader extends Component {
  navigateRegister = () => {
    const { history } = this.props
    window.location.href = '/app/register'
  }

  render() {
    return(
      <AppBar fixed={ false } theme={ appbarTheme }  className={ appbarTheme.secondary }>
        <div className={ appbarTheme.innerWrapper }>
          <div className={ appbarTheme.textWrapper }>
            <H6>
              Want to join DojoMojo’s powerful network and partner with more brands like this?
            </H6>
          </div>
          <Button
            onClick={ this.navigateRegister }
            className={ `${buttonTheme.blueButton}` }
            theme={ buttonTheme }
            label={ 'Sign Up' }
            flat
          />
          <a href="/app/login">
            <Button
              theme={ buttonTheme }
              label={ 'Log In' }
              flat
              primary
            />
          </a>
        </div>
      </AppBar>
    )
  }
}

export default withRouter(PublicCTAHeader)
