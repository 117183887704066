import React from 'react'

import { Dialog } from 'react-toolbox/lib/dialog'
import { H3, H4 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import DialogTheme from 'css/themes/dialogs/dialogBase.css'

import COLOURS from 'util/colours'

class DealPixelConfirmationModal extends React.Component {
  toggleConfirmationModal = isFailedConfirm => {
    const { success, actions, toggleModal } = this.props
    actions.closePixelConfirmation(isFailedConfirm === true)
  }

  render () {
    const {
      active,
      success,
      deal: {
        label = 'Analytics'
      } = {},
      toggleModal,
    } = this.props

    return (
      <Dialog active={ active }
        theme={ DialogTheme }
        onEscKeyDown={ this.toggleConfirmationModal }
        onOverlayClick={ this.toggleConfirmationModal }
      >
        <div style={{
          textAlign: 'center'
        }}>
          <div style={{
            marginBottom: '24px'
          }}>
            <H3>
              { success ? 'Pixel Script Confirmed' : 'Oops! Script Is Not There Yet' }
            </H3>
          </div>
        </div>

        {
          success ?
          <div style={{
            margin: 'auto',
            textAlign: 'center',
            marginBottom: '24px'
          }}>
            <img src="/images/icons/celebration-icon.svg"
              style={{
                height: '120px',
                width: '115px'
            }} />
          </div>
          :
          <div className={`${DialogTheme.bannerWrapper} ${DialogTheme.orangeBanner}`}>
            <img src="/images/icons/error-message-icon.svg" />
          </div>
        }

        <H4 multiline>
          {
            success ?
            <span>
              Success! The script is running. <em>{label}</em> will now reflect the number of views.
            </span>
            :
            <span>
              Make sure you have completed <em>step one</em> and try again.
            </span>
          }
        </H4>

        <ModalButtonGroup canSave hideLine
          confirm={ () => this.toggleConfirmationModal(!success) }
          confirmText={ success ? 'Done' : 'See Instructions' }
          cancel={ success ? null : this.toggleConfirmationModal }
          cancelText={ success ? '' : 'Cancel' }
        />
      </Dialog>
    )
  }
}


export default DealPixelConfirmationModal
