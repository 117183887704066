import React from 'react'
import Row from 'visual-components/util/no-padding-grid/row'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import Table from 'visual-components/util/table/Table'
import injectCSS from 'react-jss'
import d3 from 'd3'
import SmsAnalyticsTableCell from './SmsAnalyticsTableCell'

const styles = {
  halfRow: {
    marginTop: '24px !important',
    width: 444,
  },
  smallerCells: {
    '& tr td:not(:first-child)': {
      padding: '24px 18px',
    },
    '& tr th:not(:first-child) > div': {
      padding: '24px 18px',
    },
  },
}

const formatPercent = (part, total) => `${total > 0 ? round(part / total * 100, 2) : 0}%`

const summaryConfig = {
  campaign_name: {
    sortBy: (a, b, order) => `${(order === 'asc' ? a : b).campaign_name}`.localeCompare(`${(order === 'asc' ? b : a).campaign_name}`),
    headers: {
      columnName: 'sweepstakes',
    },
    render: (value, rowData) => ({
      link: `/analytics/campaigns/${rowData.campaign_id}`,
      name: value,
    }),
  },
  entries: {
    headers: {
      columnName: 'Entries',
    },
    render: value => ({
      primary: d3.format(',')(value),
    }),
  },
  views: {
    headers: {
      columnName: 'Views',
    },
    render: value => ({
      primary: d3.format(',')(value || 0),
    }),
  },
  subscribers: {
    headers: {
      columnName: 'Signups',
    },
    render: (value, rowData) => ({
      primary: d3.format(',')(value),
      secondary: `${formatPercent(value, rowData.views)} Signup Rate`,
    }),
  },
  messages: {
    headers: {
      columnName: 'Messages Sent',
    },
    render: value => ({
      primary: d3.format(',')(value),
    }),
  },
  clicks: {
    headers: {
      columnName: 'Clicks',
    },
    render: (value, rowData) => ({
      primary: d3.format(',')(value),
      secondary: `${formatPercent(value, rowData.messages)} CTR`,
    }),
  },
  unsubscribes: {
    headers: {
      columnName: 'Unsubscribes',
    },
    render: (value, rowData) => ({
      primary: d3.format(',')(value),
      secondary: `${formatPercent(value, rowData.messages)} Unsubs`,
    }),
  },
  columns: ['campaign_name', 'entries', 'views', 'subscribers', 'messages', 'clicks', 'unsubscribes'],
  title: 'SMS Marketing Campaign Summary',
}

const contribConfig = {
  brand_name: {
    sortBy: (a, b, order) => `${(order === 'asc' ? a : b).brand_name}`.localeCompare(`${(order === 'asc' ? b : a).brand_name}`),
    headers: {
      columnName: 'brand name',
    },
    render: (value, rowData) => ({
      link: `/explore/brands/${rowData.brand_id}/campaigns`,
      name: value,
    }),
  },
  subscribers: {
    headers: {
      columnName: 'Signups',
    },
    render: (value, rowData) => ({
      primary: d3.format(',')(value),
      secondary: `${formatPercent(value, rowData.entries)} Signup Rate`,
    }),
  },
  clicks: {
    headers: {
      columnName: 'Clicks',
    },
    render: (value, rowData) => ({
      primary: d3.format(',')(value),
      secondary: `${formatPercent(value, rowData.subscribers)} CTR`,
    }),
  },
  columns: ['brand_name', 'subscribers', 'clicks'],
  title: 'SMS Contributions By Partner',
}

const cellRenderer = config => (cellData, rowData) => {
  const { name } = cellData
  const value = config[name].render(cellData.value, rowData)

  return <SmsAnalyticsTableCell name={name} value={value} />
}

const SmsAnalyticsTables = props => {
  const {
    smsCampaignData,
    smsContributionData,
    classes: css,
  } = props

  return (
    <>
      <Row className={css.smallerCells}>
        <Table
          config={summaryConfig}
          data={smsCampaignData}
          cellRenderer={cellRenderer(summaryConfig)}
        />
      </Row>
      <Row className={css.halfRow}>
        <Table
          config={contribConfig}
          data={smsContributionData}
          cellRenderer={cellRenderer(contribConfig)}
        />
      </Row>
    </>
  )
}

SmsAnalyticsTables.propTypes = {
  smsCampaignData: PropTypes.array.isRequired,
  smsContributionData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectCSS(styles)(SmsAnalyticsTables)
