import React from 'react'
import { object } from 'prop-types'
import COLOURS from 'util/colours'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'

import * as NotificationActions from 'actions/notification-actions'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import NotificationCardContainer from 'visual-components/notificationCard/NotificationCardContainer'

import { P } from 'visual-components/util/texts'
import InfiniteScroll from '../util/InfiniteScroll'

const mapStateToProps = ({ notifications }) => ({ notifications })

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
})

class Notifications extends React.Component {
  static propTypes = {
    notifications: object.isRequired,
    notificationActions: object.isRequired,
  }

  loadMore = () => {
    const {
      notificationActions: { load },
    } = this.props
    load()
  }

  render() {
    const { notifications, notificationActions: { read, readAll } } = this.props
    return (
      <InfiniteScroll buffer={100} enabled={notifications.hasMore} whenAtBottom={this.loadMore}>
        <InternalContentContainer width="752px">
          <Grid>
            <Helmet>
              <title>Notifications</title>
            </Helmet>
            <Row
              style={{
                paddingTop: '34px',
                paddingBottom: '24px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  verticalAlign: 'bottom',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Larsseit-Bold',
                    fontSize: '24px',
                    color: COLOURS.azure,
                    display: 'inline',
                  }}
                >
                  Notifications
                </p>
                <p
                  style={{
                    fontFamily: 'Larsseit-Light',
                    fontSize: '14px',
                    lineHeight: '24px',
                    textAlign: 'right',
                    display: 'inline',
                    float: 'right',
                  }}
                >
                  <a onClick={readAll}>Mark All As Read</a>
                </p>
              </div>
            </Row>
            <Row>
              <Col xs>
                <NotificationCardContainer
                  notifications={notifications}
                  handleRead={read}
                />
              </Col>
            </Row>
          </Grid>
        </InternalContentContainer>
      </InfiniteScroll>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
