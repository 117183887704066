import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { P, H6 } from 'visual-components/util/texts'
import injectSheet from 'react-jss'

const styles = {
  wrapper: {
    marginBottom: 32,
  },
  content: {
    marginBottom: 8,
  },
}

const CTA_TYPES = {
  mobileMarketing: 'SMS Marketing',
  none: 'None',
  draft: '-',
}

class SoloPostEntryAction extends Component {
  static propTypes = {
    currentCampaign: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  state = {
    postEntryAction: '',
  }

  componentDidMount() {
    this.getPostEntryAction()
  }

  componentDidUpdate(prevProps) {
    const {
      invites: [{ prevCtaType }],
    } = prevProps.currentCampaign
    const {
      currentCampaign: {
        invites: [{ ctaType }],
      },
    } = this.props
    if (prevCtaType !== ctaType) {
      this.getPostEntryAction()
    }
  }

  getPostEntryAction = () => {
    const {
      invites: [{ ctaType }],
    } = this.props.currentCampaign
    const postEntryAction = CTA_TYPES[ctaType] || 'Custom CTA'
    this.setState({ postEntryAction })
  }

  render() {
    const {
      classes: css,
    } = this.props

    const { postEntryAction } = this.state

    return (
      <div className={css.wrapper}>
        <div className={css.content}>
          <H6>Post Entry Action</H6>
        </div>
        <P>{postEntryAction}</P>
      </div>
    )
  }
}

export default injectSheet(styles)(SoloPostEntryAction)
