import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog';

import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext';
import { Form, Submit,  Select, Input } from 'visual-components/util/form';
import { H4 } from 'visual-components/util/texts';
import CreateTaskTheme from 'css/themes/dialogs/createTask.css';
import ModalBaseTheme from 'css/themes/dialogs/modalBase.css';
import * as CampaignTaskActions from 'actions/campaign-task-actions';
import _ from 'lodash'
import InputTheme from 'css/themes/Input.css';
import SelectDueDatePicker from './select-due-date-picker'
import Validator from 'util/form-validator';
import Spinner from 'util/components/spinner';

const taskCategories = [
  {label: 'Select category', value: '', disabled: true},
  {label: 'Promotion', value: 'promotion'},
  {label: 'Review Landing Page', value: 'review_landing_page'},
  {label: 'Review ESP', value: 'review_esp'},
  {label: 'Review Official Rules', value: 'review_terms'},
  {label: 'Review Social Actions', value: 'review_social_actions'},
  {label: 'Send Prizing', value: 'send_prizing'},
  {label: 'Other', value: 'other'}
];

const mapStateToProps = (state) => {
  return {
    campaignTasks: state.campaignTasks,
    currentBrand: state.currentBrand,
    currentCampaign: state.currentCampaign
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
};


class CurrentTaskModal extends Component {
  state = {
    isDateOpen: false,
    isFocused: false,
  }

  componentDidMount() {
    const {
      campaignTasks: {
        currentTask: {
          invite_id,
          category,
          id
        },
        acceptedInvites
      },
      campaignTaskActions
    } = this.props

    if (id && invite_id && category === 'promotion') {
      campaignTaskActions.loadPromoChannels(invite_id)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      campaignTasks: {
        currentTask: {
          invite_id,
          category,
        },
        acceptedInvites
      },
      campaignTaskActions
    } = this.props

    const invite_id_old = prevProps.campaignTasks.currentTask.invite_id

    if (invite_id && invite_id !== invite_id_old) {
      campaignTaskActions.loadPromoChannels(invite_id)
    }
  }

  toggleSelectDate = () => {
    this.setState({isDateOpen: !this.state.isDateOpen});
  };


  toggleIsFocused = ({isFocused}) => {
    this.setState({isFocused});
  };


  setDate = () => {
    this.toggleSelectDate();
  }

  onSubmit = () => {
    const {
      campaignTasks: {
        currentTask,
        acceptedInvites,
        allCampaigns,
        calendarType
      },
      campaignTaskActions: {
        create,
        saveTask,
        updateCurrentTask
      }
    } = this.props

    let assignee
    let invite_id

    if (calendarType === 'campaign') {
      assignee = acceptedInvites.find(invite => {
        return invite.id === currentTask.invite_id
      })

      updateCurrentTask({
        assignee_id: assignee.invitee_id
      })
    } else {
      let campaign = allCampaigns.find(campaign => {
        return campaign.id === currentTask.campaign_id
      })

      invite_id = campaign.partners[currentTask.assignee_id].partnership_invite_id
    }

    let method = currentTask.id ? saveTask : create

    method(Object.assign({}, currentTask, {
      assignee_id: assignee ? assignee.invitee_id : currentTask.assignee_id,
      invite_id: currentTask.invite_id || invite_id
    }))
  }

  closeModal = () => {
    this.props.campaignTaskActions.currentTaskCloseModal()
  }

  render() {
    const {
      currentTask: {
        category,
        availablePromotionChannels,
        openDialog,
        isHost,
        id,
        confirmDelete,
        loading,
        promoUnits,
        creator_id,
        due_date
      },
      calendarType,
      acceptedInvites,
      liveUpcomingBrandCampaigns,
      allCampaigns
    } = this.props.campaignTasks

    const { startDate } = this.props.currentCampaign

    const isOwnTask = creator_id === this.props.currentBrand.id

    const isDashboard = calendarType === 'dashboard'

    const {
      isFocused,
      isDateOpen
    } = this.state

    const {
      update,
      toggleConfirmDelete
    } = this.props.campaignTaskActions;

    const canSave = !!due_date;

    const editing = !!id

    let campaignSet = editing ? allCampaigns : liveUpcomingBrandCampaigns

    let activePartnerships = campaignSet.map((campaign) => {
      return {
        label: campaign.header,
        value: campaign.id
      }
    })

    activePartnerships.unshift({
      value: '', disabled: true, label: 'Select active partnership'
    })

    let acceptedInvitesOptions = acceptedInvites.map((invite) => {
      return {
        label: invite.invitee.accountname,
        value: invite.id
      }
    })

    acceptedInvitesOptions.unshift({
      value: '', disabled: true, label: 'Select Brand'
    })

    let channels = _.clone(availablePromotionChannels)

    channels.unshift({
      value: '', disabled: true, label: 'Select Promotional Channel'
    })

    let createCopy = ''

    if (!editing) {
      if (isDashboard) {
        createCopy = 'Create a new task for any of your active campaigns.'
      } else if (isHost) {
        createCopy = 'Create a new task for you or any of your Partners, who will receive a notification.'
      } else {
        createCopy = 'Create a new task for this campaign.'
      }
    } else {
      createCopy = "If this task was assigned to anyone, we'll let them know you've changed it."
    }

    return(

        <Dialog
          active={ openDialog && !confirmDelete }
          title={ editing ? "Edit Campaign Task" : "Create New Task" }
          onEscKeyDown={ this.closeModal }
          onOverlayClick={ this.closeModal }
          theme={ ModalBaseTheme }
        >
          <div>
            <img onClick={ this.closeModal } className={ ModalBaseTheme.closeImage } src="images/icons/close.svg" />
          </div>

          <div className={ CreateTaskTheme.subTitle }>
            <H4>{createCopy}</H4>
          </div>

          <Form
            modelName="campaignTasks"
            updateModel={ update }
            handleSubmit={ this.onSubmit }
            mergeFieldsImmediately
          >
            { isDashboard &&
              <Select
                required
                label="Select Partnership"
                disabled={editing}
                name="currentTask.campaign_id"
                options={ activePartnerships }
              />
            }

            {
              !isDashboard && isHost &&
              <Select
                required
                label="Assignee"
                disabled={editing}
                name="currentTask.invite_id"
                options={ acceptedInvitesOptions }
              />
            }

            { editing ?
              <Input
                name="currentTask.title"
                disabled
                label="Task Category"
                required
                characterLimit={130}
              /> 
            :
            <Select
              required
              label="Task Category"
              disabled={editing}
              name="currentTask.category"
              options={ taskCategories }
            />
            }

            { category === 'other' &&
              <Input
                name="currentTask.title"
                label="Other Category Title"
                placeholder="Enter your own task category here.  The category name appears as the task title."
                required
                characterLimit={130}
              /> 
            }

            { category === 'promotion' && !editing &&
              <Select
                required
                disabled={!!id}
                label="Promotion Channel"
                name="currentTask.promoId"
                options={ channels }
              />
            }

            { category === 'promotion' && editing &&
              <Input
                name="currentTask.title"
                disabled
                label="Promotion Channel"
                forceValue={promoUnits[0].label || promoUnits[0].promotionaltype }
                required
              />
            }

            <SelectDueDatePicker
              editing={ editing }
              isFocused={ isFocused }
              isDateOpen={ isDateOpen }
              toggleSelectDate={ this.toggleSelectDate }
              onDateClick={ this.setDate }
              initialFocusDate={ startDate }
            />

            <div style={{ marginTop: '32px'}}>
              <Input
                name="currentTask.description"
                label="Task Description"
                placeholder="What do you need to accomplish?"
                required
                className={InputTheme.textarea}
                multiline
                rows={3}
                disabled={editing && !isOwnTask}
              />
            </div>

            { editing &&
              <div style={{ paddingBottom: '14px', marginTop: '-16px' }}>
                <a onClick={ toggleConfirmDelete }>Delete</a>
              </div>
            }

            <ModalButtonGroupWithContext
              cancel={ this.closeModal }
              cancelText="Cancel"
              confirmText={ editing ? "Save" : "Create Task" }
              canSave={ canSave }
            />

          </Form>

        </Dialog>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTaskModal);
