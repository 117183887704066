import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import usePostEntrySms from './usePostEntrySms'

export const MobileMarketingContext = React.createContext()

const stateToProps = ({ currentBrand, currentCampaign, sms }) =>
  ({ currentBrand, currentCampaign, sms })

function MobileMarketingProvider(props) {
  const {
    currentBrand, currentCampaign, children, sms,
  } = props

  const brandInvite = currentCampaign.invites
    ? currentCampaign.invites.find(({ invitee_id }) => invitee_id === currentBrand.id)
    : {}

  const postEntrySms = usePostEntrySms({
    currentBrand, inviteId: brandInvite.id, currentCampaign, sms,
  })

  return (
    <MobileMarketingContext.Provider value={postEntrySms}>
      {children}
    </MobileMarketingContext.Provider>
  )
}

MobileMarketingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentBrand: PropTypes.object.isRequired,
  sms: PropTypes.object.isRequired,
  currentCampaign: PropTypes.object.isRequired,
}

export default React.memo(connect(stateToProps)(MobileMarketingProvider))
