export const load = (campaign, partnershipInvite) => {
  return {
    http: {
      url: `/email-releases/${campaign.id}/${partnershipInvite.id}`,
    },
    types: [
      'LOADING_RELEASES',
      'LOAD_RELEASES_SUCCESS',
      'LOAD_RELEASES_FAILURE',
    ],
    data: {
      campaign,
      partnershipInvite
    }
  }
}

export const confirm = () => {
  return {
    type: 'CONFIRM_RELEASE_SEND'
  }
}

export const closeModal = () => {
  return {
    type: 'CLOSE_RELEASE_MODAL'
  }
}

export const update = (data) => {
  return {
    type: 'UPDATE_EMAIL_RELEASE_ATTR',
    data
  }
}

export const release = (emailRelease) => {
  return {
    http: {
      url: `/email-releases/${emailRelease.campaign.id}/${emailRelease.partnershipInvite.id}`,
      method: 'POST',
      data: {
        number_released: emailRelease.numberToRelease - emailRelease.totalAlreadyReleased
      }
    },
    types: [
      'SENDING_RELEASE',
      'CREATE_RELEASE_SUCCESS',
      'CREATE_RELEASE_FAILURE'
    ]
  }
}
