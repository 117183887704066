import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import SwitchBrand from './SwitchBrand'
import UserSettings from './UserSettings'
import BrandSettings from './BrandSettings'

class Profile extends Component {
  render() {
    const prefix = this.props.match.path

    return (
      <Switch>
        <Route path={`${prefix}/switch-brand`} component={ SwitchBrand } />
        <Route path={`${prefix}/user`} component={ UserSettings } />
        <Route path={`${prefix}/brand`} component={ BrandSettings } />
        <Redirect to="/404" />
      </Switch>
    )
  }
}

export default Profile
