import React from 'react';
import { func } from 'prop-types';
import { Input } from 'react-toolbox/lib/input';

import appbarTheme from 'css/themes/homePage/appbarTheme.css';

class OpenSearchBar extends React.Component {
  static proptypes = {
    onclick: func.isRequired
  }

  onChange = (newVal) => {
    console.log('val is:', newVal)
  }

  render() {
    let {
      onclick,
      value
    } = this.props;

    return(
      <div>
        <label htmlFor={ appbarTheme.homeSearch }>
          <img src="/images/icons/ink-mag-glass.svg"
            style={{
              cursor: 'pointer'
            }} />
        </label>
        <Input type='text'
          name='search'
          id={ appbarTheme.homeSearch }
          value={ value }
          onChange={ newVal => { this.onChange(newVal) } }
          theme={ appbarTheme }
          className={ appbarTheme.searchInput }
        />
        <img src="/images/icons/close.svg"
          onClick={ e => onclick() }
          style={{
            cursor: 'pointer'
          }} />
      </div>
    )
  }
}

export default OpenSearchBar;
