import OpportunitiesConstants from 'constants/opportunities-constants'

const {
  LOADING_OPPORTUNITY,
  LOAD_OPPORTUNITY_SUCCESS,
  LOAD_OPPORTUNITY_FAILURE,
  NEW_OPPORTUNITY,
  CLEAR_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
  TOGGLE_OPPORTUNITY_AGE_GROUP,
  EDIT_OPPORTUNITY,
  SEARCH_OPPORTUNITIES,
  SEARCH_MY_OPPORTUNITIES_SUCCESS,
  SEARCH_MY_OPPORTUNITIES_FAILURE,
} = OpportunitiesConstants

export const clear = () => ({ type: CLEAR_OPPORTUNITY })

export const create = opportunityType => ({
  type: NEW_OPPORTUNITY,
  payload: { type: opportunityType },
})

export const edit = payload => ({ type: EDIT_OPPORTUNITY, payload })

export const update = payload => ({ type: UPDATE_OPPORTUNITY, payload })

export const toggleAgeGroup = payload => ({
  type: TOGGLE_OPPORTUNITY_AGE_GROUP,
  payload,
})

export const load = ({ id, type }) => dispatch => {
  dispatch({
    http: {
      url: `/opportunities/${type}/${id}`,
    },
    types: [
      LOADING_OPPORTUNITY,
      LOAD_OPPORTUNITY_SUCCESS,
      LOAD_OPPORTUNITY_FAILURE,
    ],
  })
}

export const persist = ({ type, ...data }) => dispatch => {
  dispatch({
    // TEMPORARY
    // TODO: refactor current brand actions
    model: 'currentBrand',

    http: {
      url: `/opportunities/${type}`,
      method: 'POST',
      data,
    },
    types: [
      'LOADING_OPPORTUNITY_BRAND',
      'ADD_OPPORTUNITY', // TODO: refactor current brand actions
      'LOAD_OPPORTUNITY_FAILURE',
    ],
  })
}

export const deleteOpportunity = ({ id, type }) => dispatch => {
  dispatch({
    model: 'currentBrand', // TODO fix
    id,
    oppType: type,
    http: {
      url: `/opportunities/${type}/${id}`,
      method: 'DELETE',
    },
    types: [null, 'REMOVE_OPPORTUNITY', LOAD_OPPORTUNITY_FAILURE],
  })
  // TODO handle more elegantly
  dispatch({
    model: 'confirmationModal',
    type: 'UPDATE_ATTR',
    data: {
      expand: false,
    },
  })
}

export const save = ({ type, id, ...data }) => dispatch => {
  dispatch({
    model: 'currentBrand', // TODO fix
    http: {
      url: `/opportunities/${type}/${id}`,
      method: 'PUT',
      data,
    },
    types: [
      'LOADING_OPPORTUNITY_BRAND',
      'UPDATE_OPPORTUNITY',
      'LOAD_OPPORTUNITY_FAILURE',
    ],
  })
}

export const filterOpportunities = ({ search, sort, asc }) => ({
  model: 'currentBrand',
  http: {
    url: '/opportunities',
    data: {
      search,
      sort,
      order: asc ? 'asc' : 'desc'
    }
  },
  types: [
    SEARCH_OPPORTUNITIES,
    SEARCH_MY_OPPORTUNITIES_SUCCESS,
    SEARCH_MY_OPPORTUNITIES_FAILURE,
  ],
})

// opportuniy confirmation dialog
export const openOpportunityConfirmationDialog = () => {
  return {
    model: 'createOpportunityModal',
    type: 'UPDATE_ATTR',
    data: {
      confirmationDialog: true
    }
  };
};

export const closeOpportunityConfirmationDialog = () =>  {
  return {
    model: 'createOpportunityModal',
    type: 'UPDATE_ATTR',
    data: {
      confirmationDialog: false
    }
  };
};


// create opportunity modal
export const openCreateOpportunityModal = () => {
  return {
    model: 'createOpportunityModal',
    type: 'UPDATE_ATTR',
    data: {
      active: true
    }
  };
};

export const closeCreateOpportunityModal = () => {
  return {
    model: 'createOpportunityModal',
    type: 'UPDATE_ATTR',
    data: {
      active: false
    }
  };
};
