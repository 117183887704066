import React, { Component } from 'react'
import {
  string,
  element,
  func,
  bool,
  object,
  arrayOf,
  node,
  oneOfType,
} from 'prop-types'
import COLOURS from 'util/colours'

import { Collapse } from 'react-collapse'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'

import { H6, Tiny } from 'visual-components/util/texts'

class FilterBox extends Component {
  static propTypes = {
    title: string.isRequired,
    width: string.isRequired,
    name: string,
    tooltip: string,
    tag: string,
    learnMore: func,
    paddingBot: string,
    hasNestedCollapse: bool,
    children: oneOfType([arrayOf(node), node]).isRequired,

    /* for backwards compabtility */
    filter: element,
  }

  static defaultProps = {
    name: '',
    tooltip: null,
    tag: null,
    learnMore: null,
    paddingBot: '12px',
    hasNestedCollapse: false,
    filter: null,
  }

  static contextTypes = {
    searchModel: object,
    collapseFunction: func,
  }

  state = {
    isOpened: false,
  }

  toggleCardOpen= () => {
    const { name } = this.props
    const { isOpened } = this.state
    const { collapseFunction } = this.context

    if (collapseFunction) {
      collapseFunction(name)
    } else {
      this.setState({ isOpened: !isOpened })
    }
  }

  render() {
    const {
      children,
      filter,
      title,
      tooltip,
      tag,
      learnMore,
      width,
      hasNestedCollapse,
      paddingBot,
      name,
    } = this.props

    const { isOpened: isOpen } = this.state

    const { searchModel } = this.context

    const isOpened = searchModel ? searchModel.filtersExpanded[name] : isOpen

    return (
      <div style={{ width, paddingTop: '8px' }}>
        <div onClick={this.toggleCardOpen} style={{ cursor: 'pointer' }}>
          <div
            style={{
              display: 'inline-flex',
              verticalAlign: 'top',
              paddingTop: '6px',
              paddingBottom: '11px',
              paddingLeft: '2px',
            }}
          >
            <H6>
              { title }
            </H6>
          </div>
          {
            tooltip
              ? (
                <div
                  style={{
                    display: 'inline-flex',
                    paddingTop: '3px',
                    paddingLeft: '6px',
                  }}
                >
                  <BlueInfoTooltip text={tooltip} />
                </div>
              ) : null
          }
          {
            tag
              ? (
                <div style={{ display: 'inline-flex' }}>
                  <img
                    src={`images/icons/${tag}@3x.png`}
                    style={{
                      width: '29px',
                      height: '16px',
                      marginTop: '3px',
                      marginLeft: '8px',
                    }}
                  />
                </div>
              ) : null
          }
          <div style={{ display: 'inline-flex', float: 'right' }}>
            {
              isOpened
                ? <img src="/images/icons/collapse-arrow.svg" />
                : <img src="/images/icons/expand-arrow.svg" />
            }
          </div>
        </div>
        {
          learnMore
            ? (
              <div
                onClick={learnMore}
                style={{
                  paddingLeft: '2px',
                  marginTop: '-6px',
                  marginBottom: '16px',
                  cursor: 'pointer',
                }}
              >
                <Tiny>
                  <a href="javaScript:void(0);">
                    Learn More
                  </a>
                </Tiny>
              </div>
            ) : null
        }
        <Collapse isOpened={!!isOpened} hasNestedCollapse={hasNestedCollapse}>
          <div style={{ paddingLeft: '2px', paddingBottom: paddingBot }}>
            { filter || children }
          </div>
        </Collapse>
        <div
          style={{
            width: '100%',
            borderBottom: `1px solid ${COLOURS.silver}`,
          }}
        />
      </div>
    )
  }
}

export default FilterBox
