import React, { Component } from 'react'
import theme from 'css/themes/homePage/footer.css'
import { Link, withRouter } from 'react-router-dom'

import SubscribeForm from '../util/subscribe-form'

class FooterLinks extends Component {
  subscribe = () => {
    console.log('like and subscribe')
  }

  navigateRegister = () => {
    const { history } = this.props
    window.location.href = '/app/register'
  }

  render() {
    const { history, openContactSupportModal } = this.props
    return (
      <div className={theme.footer}>
        <div className={theme.leftSection}>
          <div className={theme.column}>
            <p className={theme.header}>Product</p>
            <Link to="/sweepstakes">Sweepstakes</Link>
            <Link to="/sms-marketing">SMS Marketing</Link>
            <Link to="/network">Network</Link>
            <a onClick={this.navigateRegister}>Sign Up</a>
          </div>
          <div className={theme.column}>
            <p className={theme.header}>About</p>
            <a href="/app/pricing"> Pricing</a>
            <Link to="/careers">Careers</Link>
            <a
              href={history.createHref({ pathname: '/terms-of-use' })}
              target="_blank"
            >
              Terms
            </a>
            <a
              href={history.createHref({ pathname: '/privacy' })}
              target="_blank"
            >
              Privacy
            </a>
            <a onClick={openContactSupportModal}>Contact Us</a>
          </div>
          <div className={theme.column}>
            <p className={theme.header}>Resources</p>
            <a href="http://blog.dojomojo.com" target="_blank">
              Blog
            </a>
            <Link to="/resources/all">Guides</Link>
          </div>
          <div className={theme.column}>
            <p className={theme.header}>Help</p>
            <a href="https://help.dojomojo.com/hc/en-us" target="_blank">
              Knowledge Base
            </a>
            <a onClick={openContactSupportModal}>Support</a>
          </div>
        </div>
        <div className={theme.rightSection}>
          <div>
            <div className={theme.column}>
              <div>
                <p className={theme.header}>Subscribe to Our Blog</p>
                <p className={theme.subscribeText}>
                  Get the best partnership marketing tips in your inbox.
                </p>
                <SubscribeForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(FooterLinks)
