import React, { Component } from 'react';
import theme from 'css/themes/homePage/painful.css';
import EasyStep from '../util/easy-step';

class PainfulAndConsuming extends Component {
  render() {
    return(
      <div className={ theme.container }>
        <p className={ theme.title }>
          Sweepstakes used to be painful and time-consuming: then came DojoMojo.
        </p>
        <p className={ theme.before }>
          Before DojoMojo
        </p>
        <div className={ theme.with }>
          3 Easy Steps with DojoMojo
        </div>
        <div className={ theme.afterContainer }>
          <EasyStep
            imgSrc={ '/images/login/sweepstakes/brand-cards.png' }
            title={ '1000s of Partners' }
            explain={ 'No more digging through LinkedIn for hours to find brand and prizing partners. DojoMojo puts marketing decision-makers at your fingertips.' }
          />
          <EasyStep
            imgSrc={ '/images/login/sweepstakes/wsiwyg-editor-on-hover.png' }
            title={ 'No developer? No problem.' }
            explain={ 'Build a fully-customized landing page in minutes, no designer required.' }
          />
          <EasyStep
            imgSrc={ '/images/login/sweepstakes/analytics-demographic-cards.png' }
            title={ 'Measure Results' }
            explain={ 'Our algorithm analyzes thousands of data points to help you understand the most powerful levers for audience growth on a campaign and historical basis.' }
          />
        </div>
      </div>
    )
  }
}

export default PainfulAndConsuming;
