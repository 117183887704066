import React from 'react';
import Table from 'util/components/standardized-tables';
import PercentageField from 'util/components/percentage-field';
import { P } from 'visual-components/util/texts';

class DistributionItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var invite = this.props.invite;
    var currentCampaign = this.props.currentCampaign;
    var isHost = invite.invitee_id === currentCampaign.hostBrandId;

    var maxProportionAvailable = currentCampaign[`successPageProportion${currentCampaign.hostBrandId}`] + currentCampaign[`successPageProportion${invite.invitee_id}`];
    return(
      <Table.Tr>
        <Table.LeftTd>
          { invite.invitee.accountname }
        </Table.LeftTd>
        <Table.Td>
          { isHost ?
            <P>
              {d3.format('%')(currentCampaign[`successPageProportion${invite.invitee_id}`])}
            </P>
            :
            <PercentageField
              updateAttr={ this.props.updateAttr }
              dataModel={ currentCampaign }
              formModel={ {} }
              max={ maxProportionAvailable }
              attrName={ `successPageProportion${invite.invitee_id}` }
            />
          }
        </Table.Td>
      </Table.Tr>
    )
  }
};

export default DistributionItem;
