/* eslint-disable react/sort-comp */
import React from 'react'
import { debounce } from 'lodash'
import injectSheet from 'react-jss'
import { object, string } from 'prop-types'
import SearchInput from 'visual-components/util/inputs/search-input'
import { P, Small } from 'visual-components/util/texts'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import InfiniteScroll from 'visual-components/util/infinite-scroll'
import COLOURS from 'util/colours'

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'flex-start',
    width: 640,
    height: 482,
    marginTop: 16,
    overflowY: 'scroll',
    overflowX: 'visible',
    '&::-webkit-scrollbar': {
      width: '0px', /* remove scrollbar space */
      background: 'transparent', /* optional: just make scrollbar invisible */
    },
  },
  imageContainer: {
    width: 200,
    height: 128,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 2,
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
    },
    '& video': {
      objectFit: 'cover',
      minWidth: 296,
      height: 160,
      marginBottom: 4,
    },
  },
  videoContainer: {
    width: 296,
    height: 160,
    marginBottom: 8,
    marginRight: 16,
  },
  selected: {
    border: `4px solid ${COLOURS.seaGreen}`,
  },
  selectedIcon: {
    position: 'absolute',
    marginTop: 8,
    marginLeft: 8,
    zIndex: 2,
    '& img': {
      width: 16,
      height: 16,
    },
  },
  selectedContainer: {
    height: 330,
    marginBottom: 24,
  },
  noMargin: {
    marginRight: 0,
  },
  portrait: {
    width: 200,
    minHeight: 128,
    marginTop: '-30%',
  },
  landscape: {
    minWidth: 200,
    height: 128,
  },
  terms: {
    marginBottom: 32,
  },
  attribution: {
    marginBottom: 24,
  },
  empty: {
    height: 482,
    paddingTop: 172,
    textAlign: 'center',
  },
  searching: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  duration: {
    marginBottom: 12,
  },
}

class SearchImageSearchTab extends React.Component {
  static propTypes = {
    backgroundType: string.isRequired,
    backgroundImages: object.isRequired,
    backgroundImageActions: object.isRequired,
    classes: object.isRequired,
  }

  select = selectedImage => {
    const { backgroundImageActions: { updateAttr } } = this.props
    updateAttr({ selectedImage })
  }

  play = idx => {
    const myVideo = document.getElementById(`video${idx}`)
    myVideo.play()
  }

  pause = idx => {
    const myVideo = document.getElementById(`video${idx}`)
    myVideo.pause()
  }

  handleSearch = value => {
    const { backgroundImageActions: { updateAttr } } = this.props

    updateAttr({
      searchPage: 1,
      searchQuery: value,
      selectedImage: null,
      skipUnsplash: false,
      skipPixabay: false,
    })
    this.executeSearch()
  }

  handleScroll = debounce(() => {
    const {
      backgroundType,
      backgroundImages: { searching, skipUnsplash, skipPixabay },
    } = this.props

    // Do not scroll if currently searching or there are no more results
    if (searching || ((backgroundType === 'Video' || skipUnsplash) && skipPixabay)) return

    this.executeSearch()
  }, 200)

  executeSearch = debounce(() => {
    const {
      backgroundType,
      backgroundImages: {
        searchQuery,
        searchPage,
        skipUnsplash,
        skipPixabay,
      },
      backgroundImageActions: { fetchStockSearch },
    } = this.props

    fetchStockSearch({
      backgroundType,
      searchQuery,
      searchPage,
      skipUnsplash,
      skipPixabay,
    })
  }, 500)

  renderImages = () => {
    const {
      backgroundType,
      backgroundImages: {
        loading,
        downloading,
        searching,
        stockImages,
        searchQuery,
        selectedImage,
        skipUnsplash,
        skipPixabay,
      },
      classes: css,
    } = this.props

    const isVideo = backgroundType === 'Video'

    const termsMap = {
      unsplash: {
        label: 'Unsplash',
        url: 'https://unsplash.com/terms',
      },
      pixabay: {
        label: 'Pixabay',
        url: 'https://pixabay.com/service/terms/',
      },
    }

    const attribution = selectedImage && selectedImage.original ? (selectedImage.type === 'unsplash' ? {
      photoUrl: `${selectedImage.original.links.html}?utm_source=DojoMojo&utm_medium=referral`,
      artistUrl: `${selectedImage.original.user.links.html}?utm_source=DojoMojo&utm_medium=referral`,
      artistName: selectedImage.original.user.name,
    } : {
      photoUrl: selectedImage.original.pageURL,
      artistUrl: `https://pixabay.com/users/${selectedImage.original.user}-${selectedImage.original.user_id}/`,
      artistName: selectedImage.original.user,
    }) : null

    if (loading) {
      const copy = downloading
        ? `Downloading ${isVideo ? 'video' : 'image'}, this may take a few minutes.`
        : `Searching for ${isVideo ? 'video' : 'image'}s.`

      return (
        <div className={css.empty}>
          <MaterialSpinner when />
          <EmptyBonzai description={copy} noTree />
        </div>
      )
    }

    if (!stockImages.length) {
      return (
        <div className={css.empty}>
          <EmptyBonzai
            description={`Sorry, we couldn't find any matches for "${searchQuery}." Try expanding your search.`}
            noTree
          />
        </div>
      )
    }

    const images = stockImages.map((item, idx) => {
      const isSelected = selectedImage && item.src && selectedImage.src === item.src
      const portrait = item.type === 'unsplash'
        ? item.original.height > item.original.width
        : item.original.imageHeight > item.original.imageWidth
      const noMargin = isVideo
        ? idx % 2 === 1
        : idx !== 0 && (idx - 1) % 3 === 1

      let duration
      if (isVideo) {
        const minutes = Math.floor(item.original.duration / 60)
        const seconds = Math.floor(item.original.duration % 60)
        duration = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
      }

      return (
        <div>
          <div
            className={`${css.imageContainer} ${isVideo ? css.videoContainer : ''} ${isSelected ? css.selected : ''} ${noMargin ? css.noMargin : ''}`}
            onClick={() => this.select(item)}
          >
            { isSelected ? (
              <div className={css.selectedIcon}>
                <img src="/images/integration-logos/checkmark.svg" />
              </div>
            ) : null}
            { isVideo ? (
              <div>
                <div onMouseOver={() => this.play(idx)} onMouseOut={() => this.pause(idx)}>
                  <video muted className={css.video} id={`video${idx}`} key={item.src}>
                    <source src={item.src} type="video/mp4" />
                  </video>
                </div>
              </div>
            ) : (
              <img
                src={item.src}
                key={item.src}
                className={portrait ? css.portrait : css.landscape}
              />
            )}
          </div>
          { duration ? (
            <div className={css.duration}>
              <Small>{duration}</Small>
            </div>
          ) : null }
        </div>
      )
    })

    return (
      <div>
        <div className={`${css.container} ${selectedImage ? css.selectedContainer : ''}`}>
          <InfiniteScroll
            elements={images}
            elementsPerRow={isVideo ? 2 : 3}
            rowHeight={isVideo ? 160 : 128}
            buffer={800}
            handleScroll={this.handleScroll}
            showAll={isVideo}
          />
          <div className={css.searching}>
            <MaterialSpinner when={searching} />
            { skipUnsplash && skipPixabay ?
              <P>{`No more matches for "${searchQuery}". Try expanding your search.`}</P>
              : null
            }
          </div>
        </div>
        { selectedImage && selectedImage.type ? (
          <div>
            <div className={css.terms}>
              <Small multiline>
                <em>
                  {'By clicking "Insert," you are agreeing to '}
                  <a href={termsMap[selectedImage.type].url} target="_blank" rel="noopener noreferrer">{`${termsMap[selectedImage.type].label}'s Terms`}</a>
                  {` and you understand that you are publishing the image and are responsible for it. This image is provided by a third party called ${termsMap[selectedImage.type].label}; it is not provided by DojoMojo.`}
                </em>
              </Small>
            </div>
            <div className={css.attribution}>
              <Small multiline>
                <em>
                  <a href={attribution.photoUrl} target="_blank" rel="noopener noreferrer">{isVideo ? 'Video' : 'Photo'}</a>
                  {' by '}
                  <a href={attribution.artistUrl} target="_blank" rel="noopener noreferrer">{attribution.artistName}</a>
                  {` on ${termsMap[selectedImage.type].label}.`}
                </em>
              </Small>
            </div>
          </div>
        ) : null }
      </div>
    )
  }

  render() {
    const { backgroundType, backgroundImages: { searchQuery } } = this.props

    const isVideo = backgroundType === 'Video'

    const poweredBy = (
      <React.Fragment>
        {'Powered by '}
        {!isVideo ? <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">Unsplash</a> : ''}
        {!isVideo ? ' and ' : ''}
        <a href="https://pixabay.com/" target="_blank" rel="noopener noreferrer">Pixabay</a>
      </React.Fragment>
    )

    return (
      <div>
        <SearchInput
          value={searchQuery}
          placeholder={`Search ${isVideo ? 'videos' : 'images'}`}
          onChange={this.handleSearch}
          className="smallInput"
          belowLabel={poweredBy}
        />

        {this.renderImages()}
      </div>
    )
  }
}

export default injectSheet(styles)(SearchImageSearchTab)
