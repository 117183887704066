import React from 'react';
import _ from 'lodash'

import MultiLine from 'visual-components/util/graphs/multi-line';
import OverviewCard from 'visual-components/analyticsDashboard/overviewCard/overviewCard';
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option';
import EmptyState from 'visual-components/util/graphs/empty-state';
import Spinner from 'util/components/spinner';
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';

class CampaignOverview extends React.Component {
  state = {
    selectedTab: 'campaignVisits'
  }

  toggleItem = (type) => {
    let {
      overviewSelection
    } = this.props;

    let hideMap = this.props[overviewSelection].hideMap;
    hideMap[type] = !hideMap[type];

    store.dispatch({
      model: 'analyticsCampaign',
      type: 'UPDATE_ATTR',
      data: {
        [overviewSelection]: _.extend({}, this.props[overviewSelection], {
          hideMap: hideMap
        })
      }
    })
  }

  switchTab = (tab) => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'analyticsCampaign',
      data: {
        overviewSelection: tab
      }
    });
  }

  render() {
    let {
      overviewSelection,
      overviewAggregates,
      started,
      canViewSms,
    } = this.props

    const defaultOverviewAggregates = {
      campaignEntries: 0,
      campaignNTF: 0,
      campaignTotalSocialActions: 0,
      campaignPartnerSmsContribution: 0,
      campaignVisits: 0,
    }

    let selectedData = this.props[overviewSelection];

    let tabs = [
      {
        ref: 'campaignVisits',
        title: 'visits'
      },
      {
        ref: 'campaignEntries',
        title: 'verified entries'
      },
      {
        ref: 'campaignNTF',
        title: 'NTF emails',
      },
      ...(
        canViewSms
          ? ([{
            ref: 'campaignPartnerSmsContribution',
            title: 'sms signups',
          }])
          : []
      ),
      {
        ref: 'campaignTotalSocialActions',
        title: 'social actions',
      },
    ]

    if (!selectedData) {
      return (
        <Spinner />
      );
    }

    const hideMap = selectedData.hideMap || {}
    const options = selectedData.items
      ? selectedData.items.map((item, idx) => {
        if (item.name) {
          return (
            <ChartOption
              key={item.brand_id}
              {...item}
              onClick={this.toggleItem}
              selected={!hideMap[item.name]}
            />
          )
        }
      })
      : []

    const hasData = ['campaignTotalSocialActions'].includes(
      overviewSelection
    )
      ? selectedData.items.some((el, idx, arr) => el.data.some(num => num.y > 0))
      : true

    return (
      <div>
        <OverviewCard
          aggregates={ started ? overviewAggregates : defaultOverviewAggregates }
          selectedTab={ overviewSelection }
          tabs={ tabs }
          switchTab={ this.switchTab }
        >
          <div style={{
            position: 'relative'
          }}>
            {
              hasData ?
              <div>
                <MultiLine { ...selectedData }
                  selectedTab={ overviewSelection }
                />
                <div style={{
                  marginTop: '50px'
                }}>
                  { options }
                </div>
              </div>
              :
              <EmptyState text={ 'Social Actions is a powerful feature reserved for Essential and Premium DojoMojo users.' }
                learnMore={ 'https://help.dojomojo.com/hc/en-us/articles/230426167-Add-Partner-Social-Actions-' }
                height={ '300px' }
                bigCard
                bigText
              />
            }
          </div>
        </OverviewCard>
      </div>
    )
  }
}

export default CampaignOverview;
