import React from 'react'
import _ from 'lodash'

import moment from 'moment-timezone'
import { renderToString } from 'react-dom/server'

import TooltipCard from 'visual-components/util/graphs/multi-line-tooltip'

const TooltipFactory = React.createFactory(TooltipCard)

const renderGraph = data => {
  $('.line-vertical').html('')

  data.items.forEach(item => {
    item.selected = !data.hideMap[item.name];
  });

  const items = _.filter(data.items, { selected: true });

  const dates = Object.keys(data.dataByDate);

  const graph = new Contour({
    el: '.line-vertical',
    xAxis: {
      type: 'linear',
      maxTicks: 8,
    },
    chart: {
      padding: {
        left: 33,
      },
      height: 300,
    },
    tooltip: {
      opacity: 1,
      verticalTrack: true,
      formatter: d => renderToString(TooltipFactory({
        label: d.x,
        period: d.period,
        selectedTab: data.selectedTab,
        hideMap: data.hideMap,
        dataByDate: data.dataByDate[moment.utc(d.x).format('l')],
      })),
    },
  })
    .cartesian()
    .yAxisLines()
    .line(items)
    .tooltip()
    .assignColors(items, {
      colorMap: data.colorMap,
    })
    .dateBasedXAxis(null, {
      n: dates.length,
      shiftLabel: true,
      formattedDates: dates.map(date => moment.utc(date).format('MMM D'))
    })
    .render()

  return graph
};


class MultiLine extends React.Component {
  componentDidMount() {
    renderGraph(this.props)
  }

  componentDidUpdate() {
    renderGraph(this.props)
  }

  render() {
    return (
      <div
        className="line-vertical multi-line"
        style={{
          display: 'inline-block',
          width: '590px',
          height: '250px',
        }}
      />
    )
  }
}

export default MultiLine
