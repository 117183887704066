import _ from 'lodash'

const model = 'uploadLogs';
let initialState = {
  rid: model,
  logs: []
};

const CsvLogReducer = (state, action) => {
  if (action.model !== model) {
    return state || initialState;
  }

  switch (action.type) {
    case 'LOAD_CSV_UPLOAD_LOG':
      return _.extend({}, state, { logs: action.data });

  default:
    return initialState;
  }
};

export default CsvLogReducer;
