import keyMirror from 'keymirror'

export default keyMirror({
  LOADING_OPPORTUNITY: null,
  CLEAR_OPPORTUNITY: null,
  NEW_OPPORTUNITY: null,
  LOAD_OPPORTUNITY_SUCCESS: null,
  LOAD_OPPORTUNITY_FAILURE: null,
  UPDATE_OPPORTUNITY: null,
  TOGGLE_OPPORTUNITY_AGE_GROUP: null,
  EDIT_OPPORTUNITY: null,
  SEARCH_OPPORTUNITIES: null,
  SEARCH_MY_OPPORTUNITIES_SUCCESS: null,
  SEARCH_MY_OPPORTUNITIES_FAILURE: null
})
