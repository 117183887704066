import React from 'react';
import FormGroup from 'util/components/form-group';
import COLOURS from 'util/colours';
import { H6 } from 'visual-components/util/texts';

class InputControl extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <td style={{
        height: '47px',
        marginTop: '-24px',
        padding: '8px',
        paddingLeft: '0',
        borderTopStyle: 'none',
        borderRightStyle: 'none',
        borderWidth: '1px',
        borderColor: '#e5e5e5',
      }}>
        <FormGroup
          {...this.props}
          type="checkbox"
          styles={[{}]}
          labelStyles={[{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            fontColor: COLOURS.ink,
          }]} />
      </td>
    )
  }
}

class EntryFields extends React.Component {
  constructor(props) {
    super(props)
    this.updateInputs = this.updateInputs.bind(this)
  }
  updateInputs(attr, value) {
    var data = this.props.campaign.inputs

    data[attr] = value;
    this.props.updateAttr('inputs', data);
  }
  render() {
    const { campaign, copy } = this.props
    const { showSmsOptin } = campaign
    return (
      <div style={{textAlign: 'left'}}>
        <H6 ink>
          Entry Fields
        </H6>
        <table
          className="table table-borderless"
          style={{ marginBottom: '0px', marginTop: '4px' }}
        >
          <tr>
            <td style={{
              height: '38px',
              width: '300px',
              padding: '8px',
              paddingLeft: '0',
              borderTopStyle: 'none',
              borderRightStyle: 'none',
              borderWidth: '1px',
              borderColor: '#e5e5e5' }}>
              <FormGroup
                editable={false}
                updateAttr={this.props.updateAttr}
                model={{includeEmail: true}}
                type="checkbox"
                attrName="includeEmail"
                label={copy.email}
                labelStyles={[{
                  fontFamily: 'Larsseit-Light',
                  fontSize: '14px',
                  fontColor: COLOURS.ink,
                }]}
                styles={[{ marginTop:'0px'}]} />
            </td>
            {showSmsOptin ? (
              <td style={{
                height: '38px',
                width: '300px',
                padding: '8px',
                paddingLeft: '0',
                borderTopStyle: 'none',
                borderRightStyle: 'none',
                borderWidth: '1px',
                borderColor: '#e5e5e5' }}>
                <FormGroup
                  editable={false}
                  updateAttr={this.props.updateAttr}
                  model={{phone: true}}
                  type="checkbox"
                  attrName="phone"
                  label="Mobile Phone Number"
                  labelStyles={[{
                    fontFamily: 'Larsseit-Light',
                    fontSize: '14px',
                    fontColor: COLOURS.ink,
                  }]}
                  styles={[{ marginTop:'0px'}]} />
              </td>
            ) :(
              <InputControl
                {...this.props}
                attrName="phone"
                label={LANGUAGE.form.phone}
                updateAttr={this.updateInputs}
                model={campaign.inputs} />
            )}
          </tr>
          <tr>
            <InputControl
              {...this.props}
              attrName="firstName"
              label={LANGUAGE.form.firstName}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
            <InputControl
              {...this.props}
              attrName="lastName"
              label={LANGUAGE.form.lastName}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
          </tr>
          <tr>
            <InputControl
              {...this.props}
              attrName="streetAddress"
              label={LANGUAGE.form.streetAddress}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
            <InputControl
              {...this.props}
              attrName="zip"
              label={LANGUAGE.form.zip}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
          </tr>
          <tr>
            <InputControl
              {...this.props}
              attrName="city"
              label={LANGUAGE.form.city}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
            <InputControl
              {...this.props}
              attrName="state"
              label={LANGUAGE.form.state}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
          </tr>
          <tr>
            <InputControl
              {...this.props}
              attrName="gender"
              label={LANGUAGE.form.gender}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
            <InputControl
              {...this.props}
              attrName="birthday"
              label={LANGUAGE.form.birthday}
              updateAttr={this.updateInputs}
              model={campaign.inputs} />
          </tr>
          <tr>
            <InputControl
              {...this.props}
              attrName="country"
              label="Country"
              updateAttr={this.updateInputs}
              model={campaign.inputs}
            />
            <InputControl
              {...this.props}
              attrName="instagram"
              label="Instagram Handle (Optional)"
              updateAttr={this.updateInputs}
              model={campaign.inputs}
            />
          </tr>
        </table>
      </div>
    )
  }
}

export default EntryFields;
