import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { H4 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import SplitTrafficBadge from './SplitTrafficBadge'

const styles = {
  badgesWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  arrow: {
    marginLeft: 12,
    transform: 'rotate(-90deg)',
    transition: 'transform .1s linear',
    fill: COLOURS.azure,
    height: 24,
    width: 24,
    marginBottom: 2,
  },
  arrowOpen: {
    transform: 'rotate(-180deg)',
  },
}

const expandArrow = classes => (
  <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M7.41 8.84L12 13.42l4.59-4.58L18 10.25l-6 6-6-6z" />
  </svg>
)

function SplitTrafficHostAction(props) {
  const {
    classes: css,
    open,
    setOpen,
    renderBadges,
  } = props

  const onClick = e => {
    e.preventDefault()
    setOpen(isOpen => !isOpen)
  }

  return (
    <>
      <div className={css.badgesWrapper}>
        { renderBadges }
      </div>
      <H4 azure>
        <a className={css.textWrapper} onClick={onClick}>
          <span>{open ? 'Hide' : 'Setup Traffic'}</span>
          {expandArrow(cn(css.arrow, { [css.arrowOpen]: open }))}
        </a>
      </H4>
    </>
  )
}

SplitTrafficHostAction.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  renderBadges: PropTypes.array.isRequired,
}

export default injectCSS(styles)(SplitTrafficHostAction)
