import _ from 'lodash'

var model = 'designHelp';

var defaultState =  {
  rid: model,
  wantsDesignHelp: false, /* coming from banner click */
}

var DesignHelpReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_ATTR: function () {
      return _.extend({}, state, action.data);
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default DesignHelpReducer;