import React from 'react';
import _ from 'lodash'

import Dialog from 'react-toolbox/lib/dialog';

import InputContainer from 'visual-components/util/InputContainer';
import IndustryDropdown from './industry-dropdown';
import EditPen from 'visual-components/util/EditPen';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import Field from 'visual-components/util/inputs/field';
import FieldDispatcher from 'dispatchers/field-dispatcher';

import { Micro } from 'visual-components/util/texts';
import Validator from 'util/form-validator'

import inputTheme from 'css/themes/Input.css'
import dialogTheme from 'css/themes/dialogs/formModal.css'

var validations = {
  website: [Validator.required, Validator.UniqueUpdateBrandDomain],
}

class EditAbout extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
    this.updateDesc = this.updateDesc.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.confirmCancel = this.confirmCancel.bind(this);
    this.revertCancel = this.revertCancel.bind(this);

    this.state = {
      active: false,
      description: props.description || '',
      confirmingCancel: false,
      website: props.currentBrand.website
    }
  }

  save() {
    const website = this.props.currentBrand.website

    this.props.save({
      description: this.state.description,
      tag1: this.state.tag1,
      tag2: this.state.tag2,
      tag3: this.state.tag3,
      website: website
    })

    FieldDispatcher.updateAttr('brand', { website: website })

    FieldDispatcher.http({
      url: '/brands/',
      method: 'PUT',
      data: {
        website: website
      }
    })

    this.handleToggle();
  }

  revertCancel() {
    this.setState({
      confirmingCancel: false,
      active: true
    })
  }

  handleCancel() {
    let different = _.some(['description', 'tag1', 'tag2', 'tag3'], item => {
      return this.props[item] !== this.state[item];
    });
    different = different || this.props.currentBrand.website !== this.state.website
    if (different) {
      this.setState({
        confirmingCancel: true,
        active: false
      })
    } else {
      this.confirmCancel();
    }

  }

  confirmCancel() {
    var {
      description,
      tag1,
      tag2,
      tag3,
    } = this.props;

    FieldDispatcher.updateAttr('currentBrand', { website: this.state.website })

    this.setState({
      active: false,
      tag1: tag1,
      tag2: tag2,
      tag3: tag3,
      description: description,
      confirmingCancel: false
    });
  }

  update(attr, value) {
    this.setState({
      [attr]: value
    });
  }

  handleToggle() {
    var {
      description,
      tag1,
      tag2,
      tag3,
      currentBrand
    } = this.props;

    this.setState({
      active: !this.state.active,
      tag1: tag1,
      tag2: tag2,
      tag3: tag3,
      website: currentBrand.website,
      description: description
    });
  }

  updateDesc(event) {
    this.setState({
      description: event.target.value
    });
  }

  render() {
    var { hovered, currentBrandForm, currentBrand } = this.props;
    var {
      description,
      tag1,
      tag2,
      tag3,
      save,
    } = this.state;

    var update = this.update;

    var categoryOptions = _.map(LANGUAGE.brandSettings.tags.tags, function  (item) {
      return {
        value: item,
        label: item
      };
    });
    description = description || '';
    var charactersRemaining = 275 - description.length;
    var overLimit = charactersRemaining < 0;

    const underLimit = 50 > description.length && 50 - description.length

    return (
      <div style={{
        visibility: hovered ? 'inherit' : 'hidden'
      }}>
        <EditPen onClick={ this.handleToggle } />
        <Dialog
          theme={ dialogTheme }
          active={ this.state.active }
          onEscKeyDown={ this.handleCancel }
          onOverlayClick={ this.handleCancel }
          title='Edit Company Info'
        >
          <img
            onClick={ this.handleCancel }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />

          <div style={{
            marginBottom: '18px'
          }}>
            <InputContainer label="Company Description">
              <div className={ inputTheme.textarea }>
                <textarea
                  onChange={ this.updateDesc }
                  value={ description }
                  className={ overLimit && inputTheme.errorState }
                  placeholder="Let other brands know a bit about your business and audience so they can connect about potential partnerships. This won't be visible to sweepstakes entrants.">
                </textarea>
                <div style={{
                  textAlign: 'right'
                }}>
                  {
                    underLimit ?
                      <Micro>
                        <span style={{
                          color: '#fc2120'
                        }}>
                           50 character minimum, please add {Math.abs(underLimit)} characters
                        </span>
                      </Micro>
                      :
                      <Micro>
                        <span style={{
                          color: overLimit ? '#fc2120' :'#374151'
                        }}>
                          { Math.abs(charactersRemaining) } characters { overLimit ? 'over limit' : 'remaining' }
                        </span>
                      </Micro>
                  }
                </div>
              </div>
            </InputContainer>
          </div>

          <Field
            dataModel={ currentBrand }
            formModel={ currentBrandForm }
            attrName='website'
            updateAttr={this.update}
            validations={ validations.website }
            label={ 'Brand Website *' }
          />

          <div style={{
            paddingBottom: '24px'
          }}>
            <InputContainer
              label={ "Industry" }
              subLabel={ "Add three industries to appear in more search results and connect with more partners." }
            >
              <div style={{
                width: '197px',
                marginRight: '8px',
                display: 'inline-block'
              }}>
                <IndustryDropdown
                  auto
                  onChange={function (value) {
                    update('tag1', value)
                  }}
                  blocked={ [tag2, tag3] }
                  source={ categoryOptions }
                  value={ tag1 }
                />
              </div>
              <div style={{
                width: '197px',
                marginRight: '8px',
                display: 'inline-block'
              }}>
                <IndustryDropdown
                  auto
                  onChange={function (value) {
                    update('tag2', value)
                  }}
                  blocked={ [tag1, tag3] }
                  source={ categoryOptions }
                  value={ tag2 }
                />
              </div>
              <div style={{
                width: '197px',
                display: 'inline-block'
              }}>
                <IndustryDropdown
                  auto
                  onChange={function (value) {
                    update('tag3', value)
                  }}
                  blocked={ [tag1, tag2] }
                  source={ categoryOptions }
                  value={ tag3 }
                />
              </div>
            </InputContainer>
          </div>
          <ModalButtonGroup
            canSave={ !overLimit && !underLimit && (!currentBrandForm.website || !currentBrandForm.website.errors.length) }
            confirm={ this.save }
            cancel={ this.handleCancel }
          />
        </Dialog>
        <Dialog
          theme={ dialogTheme }
          active={ this.state.confirmingCancel }
          onEscKeyDown={ this.confirmCancel }
          onOverlayClick={ this.confirmCancel }
          title='Changes Not Saved'
        >
          <img
            onClick={ this.confirmCancel }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />
          <p>
            Your changes have not been saved. Are you sure you want to leave without saving?
          </p>
          <ModalButtonGroup
            canSave={ true }
            confirm={ this.confirmCancel }
            cancel={ this.revertCancel }
            cancelText="Cancel"
            confirmText="Leave"
          />
        </Dialog>
      </div>
    )
  }
}

export default EditAbout;
