import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'react-toolbox/lib/button'
import moment from 'moment-timezone'
import theme from 'css/themes/dialogs/dataIntegrationDialog.css'
import { H3, P } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { bool, object, number } from 'prop-types'
import * as DataIntegrationsActions from 'actions/data-integration-actions'
import DataIntegrationsMap from 'components/brand_settings/data-integrations/data-integrations-map'

const mapStateToProps = ({ dataIntegrations, brandCampaigns }) =>
  ({ dataIntegrations, brandCampaigns })

const mapDispatchToProps = dispatch => ({
  dataIntegrationsActions: bindActionCreators(DataIntegrationsActions, dispatch),
})

const LIKELIHOOD_TO_SHOW = 30

class DataIntegrationSuggestion extends PureComponent {
  static propTypes = {
    history: object.isRequired,
    dataIntegrationsActions: object.isRequired,
    dataIntegrations: object.isRequired,
    brandCampaigns: object.isRequired,
    left: number,
    enabled: bool,
  }

  static defaultProps = {
    left: 0,
    enabled: true,
  }

  state = {
    active: false,
  }

  componentDidMount() {
    const {
      dataIntegrations: { showSuggestionDialog },
      dataIntegrationsActions: { load },
    } = this.props

    if (showSuggestionDialog) {
      load()
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      clearTimeout(this.timeout)

      const { dataIntegrations } = this.props
      const time = dataIntegrations.showSuggestionDialog ? 500 : 1

      this.timeout = setTimeout(() => {
        const active = this.isEnabled()

        this.setState({ active })
      }, time)
    }
  }

  redirectToDataIntegrations = () => {
    const {
      history,
    } = this.props

    history.push('/profile/brand/data-integrations')
  }

  closeModal = () => {
    const {
      dataIntegrationsActions: {
        closeSuggestionDialog,
      },
    } = this.props

    closeSuggestionDialog()
  }

  hasBeenRemindedToday = () => {
    const dataIntegrationSuggestionRemindedAt = window.localStorage.getItem('dataIntegrationSuggestionRemindedAt')
    const date = moment().endOf('day')

    // If doesnt exists (first time) set it to date, follow old pattern
    if (!dataIntegrationSuggestionRemindedAt) {
      window.localStorage.setItem('dataIntegrationSuggestionRemindedAt', date)

      return true
    }

    const isNextDay = dataIntegrationSuggestionRemindedAt < moment()

    // If next day, then show normally as first time of the day
    if (isNextDay) {
      window.localStorage.setItem('dataIntegrationSuggestionRemindedAt', date)

      return true
    }

    const shouldShow = Math.random() * 100 < LIKELIHOOD_TO_SHOW

    // If same day, show by percentage
    return shouldShow
  }

  isEnabled = () => {
    const {
      dataIntegrations,
      dataIntegrations: { showSuggestionDialog },
      enabled,
      brandCampaigns: {
        campaigns,
      },
    } = this.props

    //if the parent component doesn't want to show this
    if (!enabled) {
      return false
    }

    //if the user closed the dialog
    if (!showSuggestionDialog) {
      return false
    }

    //if the integration check is still loading
    const notLoaded = DataIntegrationsMap
      .find(integration => typeof dataIntegrations[integration.key] === 'undefined')
    if (notLoaded) {
      return false
    }

    //check if the user has an existing active integration
    const existingIntegration = DataIntegrationsMap
      .find(integration =>
        (integration.key === 'pixel' && dataIntegrations[integration.key])
        || dataIntegrations[integration.key].is_integrated
          || dataIntegrations[integration.key].isIntegrated)

    if (existingIntegration) {
      return false
    }

    // check if the user has at least one campaign
    if (typeof campaigns === 'undefined' || campaigns.length === 0) {
      return false
    }

    return this.hasBeenRemindedToday()
  }

  render() {
    const { left } = this.props
    const { active } = this.state

    return (
      <div className={theme.positioningContainer}>
        <div
          className={classNames(theme.container, { [theme.active]: active })}
          style={{ left }}
        >
          <img
            className={theme.banner}
            src="images/data-integration-suggestion-banner.svg"
            alt="data integration banner"
          />
          <div className={theme.body}>
            <H3 ink>
              <small>
                See Your Conversions
              </small>
            </H3>
            <div className={theme.subtitle}>
              <P multiline ink>
                <i>
                  Want to know how the leads you&apos;ve acquired are converting? Integrate your e-commerce payment provider and find out.
                </i>
              </P>
            </div>
            <div className={theme.buttons}>
              <Button
                onClick={this.redirectToDataIntegrations}
                label="Continue"
                className={classNames(ButtonTheme.primary, ButtonTheme.blueButton, ButtonTheme.flat)}
                theme={ButtonTheme}
                raised
              />
              <Button
                onClick={this.closeModal}
                label="Dismiss"
                theme={ButtonTheme}
                primary
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataIntegrationSuggestion)
)
