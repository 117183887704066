import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PropTypes from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import { Small } from 'visual-components/util/texts'

import ProfileSideCard from 'visual-components/util/profile-side-card'

import SellerProfileActions from 'actions/seller-profile-actions'

import EditPen from 'visual-components/util/EditPen'
import Chip from './ChipTag'


const dispatchToProps = dispatch => ({
  sellerProfileActions: bindActionCreators(SellerProfileActions, dispatch),
})

const renderChips = ({ tag }) => <Chip tiny key={tag} text={tag} />

class InterestsCard extends Component {
  static propTypes = {
    isBrand: PropTypes.bool.isRequired,
    interests: PropTypes.array,
    sellerProfileActions: PropTypes.object.isRequired,
  }

  static defaultProps = {
    interests: [],
  }

  state = {
    visible: false,
  }

  showEdit = () => this.setState({ visible: true })

  hideEdit = () => this.setState({ visible: false })

  onEdit = () => {
    const { sellerProfileActions } = this.props

    sellerProfileActions.showModal('partnershipInterests')
  }

  render() {
    const { visible } = this.state

    const {
      isBrand,
      interests,
    } = this.props

    const tags = interests.map(renderChips)

    return (
      <div
        style={{ marginTop: '16px' }}
        onMouseEnter={this.showEdit}
        onMouseLeave={this.hideEdit}
      >
        <ProfileSideCard title="Interested In">
          <div>
            {
              isBrand && (
                <div style={{ visibility: visible ? 'inherit' : 'hidden', zIndex: 10 }}>
                  <EditPen onClick={this.onEdit} />
                </div>
              )
            }
            <div>
              <Grid>
                <Row>
                  {
                    !interests.length
                      ? (
                        <Small multiline cloudGrey>
                          {
                            isBrand
                              ? 'Let brands know what kinds of opportunities you’d like to hear about.'
                              : 'This brand has yet to enter their interests information here.'
                          }
                        </Small>
                      ) : tags
                  }
                </Row>
              </Grid>
            </div>
          </div>
        </ProfileSideCard>
      </div>
    )
  }
}

export default connect(null, dispatchToProps)(InterestsCard)
