export const loading = () => ({ type: 'LOADING_DATA_DISCLOSURE_POLICY' })
export const reset = () => ({ type: 'RESET_DATA_DISCLOSURE_POLICY' })

export const openModal = () => ({ type: 'OPEN_DATA_DISCLOSURE_POLICY_MODAL' })
export const closeModal = () => ({ type: 'CLOSE_DATA_DISCLOSURE_POLICY_MODAL' })

export const loadPolicy = () => ({
  http: {
    url: '/brand-data-disclosures/policy',
  },
  types: [
    'LOADING_DATA_DISCLOSURE_POLICY',
    'LOAD_DATA_DISCLOSURE_POLICY_SUCCESS',
    'LOAD_DATA_DISCLOSURE_POLICY_FAILURE',
  ],
})

export const updatePolicy = data => ({ type: 'UPDATE_DATA_DISCLOSURE_POLICY', data })

export const submitPolicy = data => ({
  http: {
    url: '/brand-data-disclosures/policy',
    method: 'POST',
    data,
  },
  types: [
    'LOADING_DATA_DISCLOSURE_POLICY',
    'SUBMIT_DATA_DISCLOSURE_POLICY_SUCCESS',
    'SUBMIT_DATA_DISCLOSURE_POLICY_FAILURE',
  ],
})
