import React from 'react'
import * as ReactRedux from 'react-redux'
import Text from 'util/components/text'
import RegistrationHeader from 'components/registration/registration-header'
import COLOURS from 'util/colours'
import PartnershipInterestDispatcher from 'dispatchers/partnership-interest-dispatcher'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'
import PrimaryButton from 'util/components/buttons/primary-button'
import { Checkbox } from 'react-toolbox'
import { Button } from 'react-toolbox/lib/button'
import { H2, P, Small } from 'visual-components/util/texts'
import cn from 'classnames'

import CheckboxTheme from 'css/themes/Checkbox.css'
import theme from 'css/themes/login/registrationTheme.css'
import buttonTheme from 'css/themes/Buttons.css'
import * as metrics from 'util/metrics'
import { memoize } from 'lodash'

const track = memoize(action => () => {
  metrics.create('registrationFlow', {
    meta: {
      page: 'brandProfileInterests',
      action,
    }
  })
})

var mapState = function(state) {
  return state;
}

class BrandProfileInterests extends React.Component{
  state = {
    interests: {
      sweepstakes: {active: false},
      contentSwaps: {active: false},
      insertSwaps: {active: false},
      dedicatedEmailBuys: {active: false, type: 'Buy'},
      dedicatedEmailSales: {active: false, type: 'Sell'},
      offerPrizing: {active: false}
    }
  }

  componentDidMount = () => {
    window.scrollTo(0,0);
    track('loadedBrandProfileInterests')()
  }

  handleChange = (field, value) => {
    var {
      interests
    } = this.state;

    interests[field].active = !interests[field].active

    this.setState({
      interests: interests
    })
  };

  finish = () => {
    track('submittedBrandProfileInterests')()
    var {
      interests
    } = this.state

    PartnershipInterestDispatcher.update(interests)

    let list = Object.keys(interests).map(interest => interests[interest].active ? interest : null).filter(i => i)

    CurrentBrandDispatcher.updatePartnershipInterests(list)

    // TODO (Jenky): Disabled (10/25/2022) until we have people back on sales
    // setTimeout(_ => this.props.history.push('/registrations/call-offer'), 400)
  }

  render() {
    let { interests } = this.state
    var loading = ''

    const isDisabled = Object.values(interests).every(interest => !interest.active)

    var optionData = [{
      title: 'Sweepstakes: ',
      description: 'Collaborate on campaigns to drive email list and social growth',
      key: 'sweepstakes'
    }, {
      title: 'Dedicated Email Buys: ',
      description: 'Buy media from other brands on the platform',
      key: 'dedicatedEmailBuys'
    }, {
      title: 'Dedicated Email Sells: ',
      description: 'Sell media to other brands on the platform',
      key: 'dedicatedEmailSales'
    }, {
      title: 'Prizing Partner: ',
      description: 'Provide prizing for sweepstakes in exchange for brand exposure and/or entries',
      key: 'offerPrizing'
    }, {
      title: 'Content Swaps: ',
      description: 'Cross-promote content to acquire new readers',
      key: 'contentSwaps'
    }, {
      title: 'Box-Insert Swaps: ',
      description: 'Exchange physical inserts in product shipments to grow audience',
      key: 'insertSwaps'
    }]

    const options = optionData.map(opt => (
      <div className={ CheckboxTheme.partnershipCheckboxWrapper }
        key={ opt.key }
      >
        <Checkbox onChange={ this.handleChange.bind(this, opt.key) }
          checked={ interests[opt.key].active }
          className={ CheckboxTheme.partnershipCheckbox }
          theme={ CheckboxTheme }
          label={
            <P multiline>
              <em>
                { opt.title }
              </em>
              { opt.description }
            </P>
          }
        />
      </div>
    ))

    const buttonCn = cn(buttonTheme.blueButton,buttonTheme.noMargin, theme.botButton, {[buttonTheme.disabledButton]: isDisabled})

    return (
      <div>
        <RegistrationHeader
          step={ 4 }
        />
        <div className={ theme.container }>
          <div className={ theme.headerTitle }>
            <H2 multiline>
              Brand Profile
            </H2>
          </div>
          <div className={ `${theme.subheaderTitle} ${theme.subheaderWithPaddingBottom}`}>
            <P multiline>
              Select the partnership opportunites you’d be interested in hearing about.
            </P>
          </div>
          <div className={ theme.optionWrapper }>
            { options }
          </div>
          <div className={ theme.buttonWrapper }>
            <Button
              type={ 'submit' }
              onClick={ e => this.finish() }
              className={buttonCn}
              theme={ buttonTheme }
              label={ 'Submit Brand Profile' }
              disabled={ isDisabled }
            />
            <div
              className={theme.skipButton}
              onClick={this.finish}
            >
              <P azure>
                <i>
                  Skip for now
                </i>
              </P>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ReactRedux.connect(mapState)(BrandProfileInterests)
