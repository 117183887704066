import CampaignTimelinePopout from 'visual-components/calendar-tasks-popouts/campaign-timeline-popout.js'

import { Component } from 'react'

import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TasksList from 'visual-components/tasks/TaskList'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'

const mapStateToProps = (state) => ({
  currentBrand: state.currentBrand,
  profile: state.profile,
  campaignTasks: state.campaignTasks
})
const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
}

class CampaignPopout extends Component {
  render() {

    const {
      campaignTasks: {
        selectedCampaign,
        event,
        calendarType
      },
      campaignTaskActions: {
        deselectCampaign,
        exportCalendar,
        hideShowMore
      },
      profile,
      currentBrand,
      history
    } = this.props

    if (!selectedCampaign) {
      return (null)
    }

    let data = Object.assign(selectedCampaign, {
      campaignId: selectedCampaign.id,
      tasksPercentageCompleted: (selectedCampaign.totalTasks - selectedCampaign.tasksRemaining) / selectedCampaign.totalTasks
    })

    const isDashboard = calendarType === 'dashboard'

    return (
      <CampaignTimelinePopout
        {...data}
        event={event}
        deselect={deselectCampaign}
        manageCampaign={isDashboard ? () => {
          history.push(`/partnerships/campaign/${selectedCampaign.id}/quickview`)
        } : null}
        analytics={() => {
          history.push(`/analytics/campaigns/${selectedCampaign.id}`)
        }}
        deselectShowMore={hideShowMore}
        exportToCal={()=> {
          deselectCampaign()
          exportCalendar(
            `${location.origin}/calendar/brand-tasks/${currentBrand.uid}/${profile.uid}`,
            currentBrand.accountname
          )
        }}
      />
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignPopout))
