var model = 'newBrand';
import _ from 'lodash'

const isBrowser = typeof window !== 'undefined'

const getParameterByName = function (name, url) {
  if (!isBrowser) return null;
  if (!url) url = window.location.href;
  url = url.toLowerCase(); // This is just to avoid case sensitiveness
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

(function(window) {
  window.getParameterByName = getParameterByName
})(isBrowser ? window : {});


var defaultState =  {
  rid: model,
  wantsDesignHelp: false, /* coming from banner click */
  dedicatedListSize: 0,
  loading: false,
}

var NewBrand = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_ATTR: function () {
      var newState = _.extend({}, state, action.data);
      return newState;
    },
    LOADING_START: () => {
      const newState = { ...state, loading: true }
      return newState
    },
    LOADING_END: () => {
      const newState = { ...state, loading: false }
      return newState
    },
    CLEAR: () => {
      return defaultState
    },
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default NewBrand;
