import AppDispatcher from 'dispatchers/app-dispatcher';
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import { extend, debounce } from 'lodash'

const PartnershipInvitesDispatcher = extend({}, AppDispatcher, {
  updateAttrPersist: debounce(function(attr, value) {
    const {
      ctaModel: { id }
    } = store.getState()

    const { invites } = store.getState().currentCampaign
    const inviteIndex = invites.findIndex(i => i.id === id)
    const originalInvite =  { ...invites[inviteIndex] }

    if (attr === 'ctaType') {
      invites[inviteIndex].ctaType = value
      store.dispatch({
        model: 'currentCampaign',
        type: 'UPDATE_ATTR',
        data: {
          invites,
        }
      })
      store.dispatch({
        model: 'ctaModel',
        type: 'UPDATE_ATTR',
        data: {
          ctaType: value,
        },
      })
    }

    AppDispatcher.http({
      url: `/partnership/${id}`,
      method: 'PUT',
      data: {
        [attr]: value
      },
      error: function (err) {
        invites[inviteIndex] = originalInvite
        store.dispatch({
          model: 'currentCampaign',
          type: 'UPDATE_ATTR',
          data: {
            invites,
          }
        })
      }
    })
  }, 500),
  loadForCampaign: function (state, campaignId) {
    var invite = state.invites[campaignId];
    if (!invite) {
      AppDispatcher.http({
        url: '/brand-campaign-invite/' + campaignId,
        success: function (invite) {
          var data = {};
          data[campaignId] = invite;
          store.dispatch({
            model: 'partnershipInvites',
            type: 'UPDATE_ATTR',
            data: {
              invites: data
            }
          })
        }
      })
    }
  },
  apply: function (campaign, messageContent, callback) {
    AppDispatcher.http({
      url: '/partnership/apply/' + campaign.id,
      method: 'POST',
      data: {
        message: messageContent
      },
      success: function(data) {
        let {invite, conversation} = data;
        store.dispatch({
          model: 'conversations',
          type: 'LOAD_INVITE_MESSAGE',
          data: conversation
        })
        callback(null, invite)
      },
      error: function(err) {
        callback(err.responseJSON.message)
      }
    })
  },
  invite: function (campaign, messageContent, minimumDelivery, callback) {
    AppDispatcher.http({
      url: '/partnership/invite/' + campaign.id + '/' + store.getState().brand.id,
      method: 'POST',
      data: {
        message: messageContent,
        hostBrandName: store.getState().currentBrand.accountname,
        minimumDelivery: minimumDelivery,
      },
      success: function(data) {
        let {invite, conversation} = data;
        store.dispatch({
          model: 'conversations',
          type: 'LOAD_INVITE_MESSAGE',
          data: conversation
        })
        store.dispatch({
          model: 'brandCampaigns',
          type: 'ADD_INVITE',
          data: invite
        })
        toastr.success('Invite Sent!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
        callback(null, invite)
      },
      error: function(err) {
        callback(err.responseJSON.message)
      }
    })
  },
  reInvite: function(campaignId, messageContent, reinviteType, callback) {
    AppDispatcher.http({
      url: '/partnership/reinvite/' + campaignId + '/' + store.getState().brand.id,
      method: 'POST',
      data: {
        hostBrandName: store.getState().currentBrand.accountname,
        messageContent,
        reinviteType,
      },
      success: function(data) {
        const {invite, conversation} = data;
        store.dispatch({
          model: 'conversations',
          type: 'LOAD_INVITE_MESSAGE',
          data: conversation
        })
        store.dispatch({
          model: 'brandCampaigns',
          type: 'ADD_INVITE',
          data: invite
        })
        toastr.success('Invite Sent!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
        callback(null, invite)
      },
      error: function() {
        callback('Sorry, we were unable to send this reinvite.')
      }
    })
  },

  acceptInvite: function (invite) {
    store.dispatch({
      model: 'notificationsList',
      type: 'UPDATE_ATTR',
      data: {
        loading: true
      }
    });
    AppDispatcher.http({
      method: 'POST',
      url: `/partnership/accept/${invite.invitee.id}/${invite.id}`,
      success: function () {
        CampaignDispatcher.loadCampaignsData();
        store.dispatch({
          model: 'notificationsList',
          type: 'UPDATE_ATTR',
          data: {
            loading: false
          }
        });
        store.dispatch({
          type: 'HANDLE_ITEM',
          model: 'notificationsList',
          data: {
            id: invite.id
          }
        });

        //new messaging logic
        if (invite.message_id) {
          store.dispatch({
            type: 'UPDATE_MESSAGE_SUCCESS',
            model: 'conversations',
            data: {
              messageId: invite.message_id,
              messageType: 'receivedInvitations',
              conversationId: invite.conversation_id,
              updatedObj: {
                status: 'accepted'
              }
            }
          })
          store.dispatch({
            type: 'UPDATE_ATTR',
            model: 'conversations',
            data: {
              showPartnershipAcceptModal: {
                campaignName: invite.campaign_name,
                campaignHostBrand: invite.campaign_host_name,
                campaignStartDate: invite.campaign_start_date,
                campaignEndDate: invite.campaign_end_date,
                campaignId: invite.campaign_id,
                active: true
              }
            }
          })
          if (invite.attached_message) {
            MessagingDispatcher.sendMessage({
              conversationId: invite.conversation_id,
              content: invite.attached_message
            })
          }
        }

      }
    });
  },
  rejectInvite: function (invite) {
    store.dispatch({
      model: 'notificationsList',
      type: 'UPDATE_ATTR',
      data: {
        loading: true
      }
    });
    AppDispatcher.http({
      method: 'POST',
      url: `/partnership/reject/${invite.invitee.id}/${invite.id}`,
      success: function () {
        //Do we need this for notifications?
        store.dispatch({
          model: 'notificationsList',
          type: 'UPDATE_ATTR',
          data: {
            loading: false
          }
        });
        store.dispatch({
          type: 'HANDLE_ITEM',
          model: 'notificationsList',
          data: {
            id: invite.id
          }
        });
        //new messaging logic
        if (invite.message_id) {
          store.dispatch({
            type: 'UPDATE_MESSAGE_SUCCESS',
            model: 'conversations',
            data: {
              messageId: invite.message_id,
              messageType: 'receivedInvitations',
              conversationId: invite.conversation_id,
              updatedObj: {
                status: 'rejected'
              }
            }
          })
        }

      }
    });
    // toastr.warning('Invitation Rejected');
  },
  acceptApplication: function(invite) {
    store.dispatch({
      model: 'notificationsList',
      type: 'UPDATE_ATTR',
      data: {
        loading: true
      }
    });
    AppDispatcher.http({
      url: `/partnership-application/accept/${invite.campaign_id}/${invite.id}`,
      method: 'GET',
      success: () => {
        CampaignDispatcher.loadCampaignsData();
        store.dispatch({
          model: 'notificationsList',
          type: 'UPDATE_ATTR',
          data: {
            loading: false
          }
        });
        store.dispatch({
          type: 'HANDLE_ITEM',
          model: 'notificationsList',
          data: {
            id: invite.id
          }
        });
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'conversations',
          data: {
            showPartnershipAcceptModal: {
              campaignName: invite.campaign_name,
              campaignHostBrand: invite.campaign_host_name,
              campaignStartDate: invite.campaign_start_date,
              campaignEndDate: invite.campaign_end_date,
              campaignId: invite.campaign_id,
              application: invite.application,
              inviteeName: invite.invitee.name,
              active: true
            }
          }
        })

        //new messaging logic
        if (invite.message_id) {
          store.dispatch({
            type: 'UPDATE_MESSAGE_SUCCESS',
            model: 'conversations',
            data: {
              messageId: invite.message_id,
              messageType: 'receivedApplications',
              conversationId: invite.conversation_id,
              updatedObj: {
                status: 'accepted'
              }
            }
          })
        }
        if (invite.attached_message) {
          MessagingDispatcher.sendMessage({
            conversationId: invite.conversation_id,
            content: invite.attached_message
          })
        }

        toastr.success('Partner Accepted', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      }
    });
  },
  rejectApplication: function(invite) {
    store.dispatch({
      model: 'notificationsList',
      type: 'UPDATE_ATTR',
      data: {
        loading: true
      }
    });
    AppDispatcher.http({
      url: `/partnership-application/reject/${invite.campaign_id}/${invite.id}`,
      method: 'GET',
      success: () => {
        CampaignDispatcher.loadCampaignsData();
        store.dispatch({
          model: 'notificationsList',
          type: 'UPDATE_ATTR',
          data: {
            loading: false
          }
        });
        store.dispatch({
          type: 'HANDLE_ITEM',
          model: 'notificationsList',
          data: {
            id: invite.id
          }
        });

        //new messaging logic
        if (invite.message_id) {
          store.dispatch({
            type: 'UPDATE_MESSAGE_SUCCESS',
            model: 'conversations',
            data: {
              messageId: invite.message_id,
              messageType: 'receivedApplications',
              conversationId: invite.conversation_id,
              updatedObj: {
                status: 'rejected'
              }
            }
          })
        }

        toastr.success('Partnership Declined', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      }
    });
  },
  toggleEmailFiltering: function(invite, filterWebbula) {
    AppDispatcher.http({
      url: `/partnership/${invite.id}`,
      method: 'PUT',
      data: {
        filterWebbula
      },
      success: () => {
        store.dispatch({
          type: 'UPDATE_INVITE',
          model: 'currentCampaign',
          data: {
            invite: Object.assign({}, invite, {filterWebbula: filterWebbula})
          }
        })
      }
    })
  }
});

export default PartnershipInvitesDispatcher;
