import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { string, func, object } from 'prop-types'
import COLOURS from 'util/colours'

const styles = {
  selectedFontContainer: {
    padding: '12px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `solid 1px ${COLOURS.silver}`,
    overflow: 'hidden',
    '& p': {
      fontSize: '10px',
    },
  },
  xIcon: {
    cursor: 'pointer',
  },
}

class SelectedFont extends Component {
  static propTypes = {
    name: string.isRequired,
    updateFont: func.isRequired,
    classes: object.isRequired,
  }

  toggleFontSelect = () => {
    const { name, updateFont } = this.props
    updateFont(name, false)
  }

  render() {
    const { name, classes: css } = this.props

    return (
      <div className={css.selectedFontContainer}>
        <p style={{ fontFamily: name }}>
          { name.replace(/\+/g, ' ') }
        </p>
        <img
          src="/images/icons/small-x.svg"
          className={css.xIcon}
          onClick={this.toggleFontSelect}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(SelectedFont)
