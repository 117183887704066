import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import COLOURS from 'util/colours';
import {Editor, EditorState, RichUtils, ContentState} from 'draft-js';
import StyleButton from 'visual-components/richEditor/style-button';
import Button from 'react-toolbox/lib/button';
import PropTypes from 'prop-types';
import ButtonTheme from 'css/themes/Buttons.css'

const themes = {
  Button: ButtonTheme,
}

class PopoutRichEditor extends React.Component {
  constructor(props) {
    super(props);
    let initialEditorState;

    if(this.props.initialContent) {
      initialEditorState = EditorState.createWithContent(this.props.initialContent)
    } else {
      initialEditorState = EditorState.createEmpty()
    }
    this.state = { editorState: initialEditorState };

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => this.setState({editorState});

    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    this.onButtonClick = () => this._onButtonClick();
  }
  
  componentDidMount() {
    this.refs.editor.focus();
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      editorState: EditorState.createWithContent(this.props.initialContent)
    })
  }

  _handleKeyCommand(command) {
    const {editorState} = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  _onButtonClick() {
    const { editorState } = this.state;
    let contentState = editorState.getCurrentContent();
    this.props.buttonClick(contentState);

    const newEditorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState: newEditorState })

  }

  render() {
    const { editorState } = this.state;

    const {
      styleOptions,
      buttonText
    } = this.props;

    // Split passed options between the inline and block styles
    let inlineStyleOptions = styleOptions.filter(function(value) {
      return ['Bold', 'Italic', 'Underline', 'Monospace'].indexOf(value) != -1;
    })

    let blockStyleOptions = styleOptions.filter(function(value) {
      return ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'UL', 'OL', 'Code Block'].indexOf(value) != -1;
    })

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <Row className="RichEditor-root">
        <Col xs={12} onClick={this.focus} style={{
          border: `1px solid ${ COLOURS.lightGrey }`,
          padding: '12px',
          fontSize: '14px',
          minHeight: '350px',
          maxHeight: '350px',
          cursor: 'text',
          fontFamily: 'Larsseit-Light',
          overflow: 'auto'
        }}>
          <Editor
            blockStyleFn={ getBlockStyle }
            editorState={ editorState }
            handleKeyCommand={ this.handleKeyCommand }
            onChange={ this.onChange }
            onTab={ this.onTab }
            placeholder="Write your message here"
            ref="editor"
            spellCheck={ true }
          />
        </Col>
        { buttonText ? 
            <Col xs={ 3 } style={{
              // marginTop: '8px'
              paddingTop: '10px',
              paddingBottom: '12px',
              paddingLeft: '12px',
            }}>
              <Button className={ themes.Button.blueButton }
                theme={ themes.Button }
                onClick={ this.onButtonClick }
                style={{
                  height: 'auto',
                  padding: '8px 16px 4px 16px'
              }}>
                { buttonText }
              </Button>
            </Col>
          :
            null
        }
        
        <Col xs={ buttonText ? 9 : 12 } style={{
          textAlign: 'right',
          paddingTop: buttonText ? '14px' : 0,
          paddingRight: buttonText ? '20px' : '12px',
          paddingBottom: buttonText ? '12px' : 0,
          paddingLeft: buttonText ? '12px' : '0px',
        }}>
          <InlineStyleControls
            editorState={ editorState } 
            onToggle={ this.toggleInlineStyle }
            activeOptions={ inlineStyleOptions }
          />
          <BlockStyleControls
            editorState={ editorState }
            onToggle={ this.toggleBlockType }
            activeOptions={ blockStyleOptions }
          />
        </Col>
      </Row>
    );
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

// All possible block types
const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one', icon_unselected: null, icon_selected: null },
  { label: 'H2', style: 'header-two', icon_unselected: null, icon_selected: null },
  { label: 'H3', style: 'header-three', icon_unselected: null, icon_selected: null },
  { label: 'H4', style: 'header-four', icon_unselected: null, icon_selected: null },
  { label: 'H5', style: 'header-five', icon_unselected: null, icon_selected: null },
  { label: 'H6', style: 'header-six', icon_unselected: null, icon_selected: null },
  { label: 'Blockquote', style: 'blockquote', icon_unselected: null, icon_selected: null },
  { label: 'UL', style: 'unordered-list-item', icon_unselected: 'bullet-list-icon-unselected.svg', icon_selected: 'bullet-list-icon-selected.svg'},
  { label: 'OL', style: 'ordered-list-item', icon_unselected: null, icon_selected: null },
  { label: 'Code Block', style: 'code-block', icon_unselected: null, icon_selected: null },
];

// All possible inline style types
var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon_unselected: 'bold-icon-unselected.svg', icon_selected: 'bold-icon-selected.svg' },
  { label: 'Italic', style: 'ITALIC', icon_unselected: 'italic-icon-unselected.svg', icon_selected: 'italic-icon-selected.svg' },
  { label: 'Underline', style: 'UNDERLINE', icon_unselected: null, icon_selected: null },
  { label: 'Monospace', style: 'CODE', icon_unselected: null, icon_selected: null },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
    <div className="RichEditor-controls" style={{
      display: 'inline-block'
    }}>
      {BLOCK_TYPES.filter(type => props.activeOptions.indexOf(type.label) != -1).map((type) => 
        <StyleButton
          key={ type.label }
          active={ type.style === blockType }
          label={ type.label }
          onToggle={ props.onToggle }
          style={ type.style }
          selectedIcon={ type.icon_selected }
          unselectedIcon={ type.icon_unselected }
        />
      )}
    </div>
  );
};

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls" style={{
      display: 'inline-block'
    }}>
      {INLINE_STYLES.filter(type => props.activeOptions.indexOf(type.label) != -1).map(type => 
        <StyleButton
          key={ type.label }
          active={ currentStyle.has(type.style) }
          label={ type.label }
          onToggle={ props.onToggle }
          style={ type.style }
          selectedIcon={ type.icon_selected }
          unselectedIcon={ type.icon_unselected }
        />
      )}
    </div>
  );
};

PopoutRichEditor.propTypes = {
  styleOptions: PropTypes.array,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func
}

export default PopoutRichEditor;