import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { createCookie, getCookie } from 'util/cookie'
import validQueryParams from 'util/valid-query-params'
import IntegrationsTheme from 'css/themes/homePage/sideImg.css'
import HomepageBanner from './homepage-banner'
import StickyTabContainer from '../util/sticky-tabs-container'
import StickyTab from '../util/sticky-tab'
import OneStopHome from './one-stop-home'
import TrustedBy from '../util/trusted-by'
import Countup from './countup'
import WhatMarketersSay from './what-marketers-say'
import InThePress from '../util/in-the-press'
import SideImgContent from '../util/side-img-content'
import Footer from '../footer/Footer'
import ReferralBanner from './referral-banner'

class Home extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {}

  componentDidMount() {
    window.scrollTo(0, 0)

    const { location: { search } } = this.props
    const parsedQueryString = queryString.parse(search)

    validQueryParams.forEach(param => {
      if (parsedQueryString[param] && !getCookie(param)) {
        createCookie(param, parsedQueryString[param], 7)
      }
    })

    // if there's a referral code in cookie or URL query string
    // fetch more info about referral
    const referralCode = getCookie('referral')
    if (referralCode) {
      $.get(`/referral-lookup/${referralCode}`).then(referral => {
        this.setState(referral)
      })
    }
  }

  render() {
    const { type, firstName, campaign } = this.state

    return (
      <div>
        <Helmet>
          <title>Accelerated growth through SMS marketing</title>
          <meta
            content="Combine the power of SMS marketing and sweepstakes acquisition to accelerate your e-commerce growth."
            name="description"
          />
          <meta property="og:title" content="DojoMojo: Accelerated growth through SMS marketing" />
          <meta
            property="og:description"
            content="Combine the power of SMS marketing and sweepstakes acquisition to accelerate your e-commerce growth."
          />
          <meta
            property="og:image"
            content="http://assets.dojomojo.com/images/referrals/facebook-share-photo.png"
          />
          <meta
            property="twitter:image"
            content="http://assets.dojomojo.com/images/referrals/twitter-share-photo.png"
          />
        </Helmet>
        {
          type
            ? (
              <ReferralBanner
                type={type}
                firstName={firstName}
                campaignName={campaign}
              />
            ) : null
        }
        <HomepageBanner />
        <StickyTabContainer bannerHeight={680} start={2650}>
          <StickyTab
            title="Sweepstakes"
            icon="build"
            href="sweepstakes"
          />
          <StickyTab
            title="SMS Marketing"
            icon="sms"
            href="sms-marketing"
          />
          <StickyTab
            title="Brand Network"
            icon="ink-mag-glass"
            href="brand-network"
          />
          <StickyTab
            title="Compliance"
            icon="circle-checkmark"
            href="compliance"
          />
        </StickyTabContainer>

        <OneStopHome />
        <TrustedBy />
        <Countup />
        <WhatMarketersSay />

        <SideImgContent
          title="Build Sweepstakes, Quickly"
          para="Our simple to use templates and editing tools let you create a fully branded landing page in minutes. Generate Official Rules, customize forms and create a URL with one click."
          imgSrc="/images/login/side-imgs/campaign-builder-template.png"
          lis={[
            'Optimized Templates and Page Editor',
            'Offer Bonus Entries for Social Actions',
            'CTA Ad Space in Post Entry',
          ]}
          buttonText="See How It Works"
          buttonUrl="/sweepstakes"
          href="sweepstakes"
        />

        <SideImgContent
          right
          title="SMS Marketing"
          para="Combining DojoMojo's new SMS Marketing capabilities  with our powerful acquisition funnel to grow your SMS audience 10-20x faster than any other alternative."
          imgSrc="/images/login/side-imgs/sms-marketing.png"
          lis={[
            'Grow beyond your existing customers',
            'Engage your audience in a high performing channel',
            'Drive & track conversions with SMS/MMS messages',
          ]}
          buttonText="Learn More"
          buttonUrl="/sms-marketing"
          href="sms-marketing"
        />

        <SideImgContent
          right
          title="Find Thousands of Marketers"
          para="Browse thousands of potential partners and opportunities to connect. Our powerful search features and custom recommendation engine help you identify the best partners for your brand."
          imgSrc="/images/login/side-imgs/brand-search-screenshot-laptop.png"
          buttonText="Explore the Network"
          buttonUrl="/network"
          href="brand-network"
        />

        <SideImgContent
          title="Trust and Transparency"
          para="Transparency and trust are the foundations of healthy partnerships. Leverage features that hold partners accountable like setting minimum sweepstakes entry requirements, advanced high-volume CPC deal tracking, security verification and more. We're constantly looking for ways to keep both partnerships and your email list high quality."
          imgSrc="/images/login/side-imgs/partnership-agreement.png"
          href="compliance"
        />

        <div className={IntegrationsTheme.integrationsWrapper}>
          <SideImgContent
            right
            title="Integrations"
            para="Integrate with your favorite marketing and sales tools and send new emails directly to your list."
            imgSrc="/images/login/side-imgs/esp-data-integrations.png"
            isIntegrations
          />
        </div>

        <InThePress />

        <Footer />
      </div>
    )
  }
}

export default Home
