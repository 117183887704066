import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import TableTheme from 'css/themes/analytics-tables/partnershipTable.css'
import moment from 'moment-timezone'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

const className = `${TableTheme.partnershipRow} ${TableTheme.analyticsRow}`

class PartnershipAnalyticsTableRow extends Component {
  static propTypes = {
    partnership: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onClick = () => {
    const {
      history,
      partnership: { partner_brand_id },
    } = this.props
    history.push(`/analytics/partnerships/${partner_brand_id}`)
  }

  render() {
    const { partnership } = this.props
    const {
      partner_brand_name,
      last_partnered_date,
      avg_entries,
      dedicatedListSize,
      num_campaigns,
      total_entries_driven,
      total_ntf_driven,
    } = partnership

    const ntfPercent = total_ntf_driven && total_entries_driven
      ? Math.round(total_ntf_driven / total_entries_driven * 100)
      : 0

    return (
      <tr onClick={this.onClick} className={className}>
        <td data-title="Brand" className={TableTheme.tdFirst}>
          { partner_brand_name }
        </td>
        <td data-title="Last-Partnered">
          { `${moment(last_partnered_date).format('M/D/YY')}` }
        </td>
        <td data-title="Campaigns">
          { makeNumbersPretty(num_campaigns) }
        </td>
        <td data-title="List-Size">
          { makeNumbersPretty(dedicatedListSize)  }
        </td>
        <td data-title="Avg-Entries">
          { makeNumbersPretty(avg_entries) }
        </td>
        <td data-title="NTF">
          { makeNumbersPretty(total_ntf_driven) }
        </td>
        <td data-title="NTF-percent">
          { `${ntfPercent}%` }
        </td>
      </tr>
    )
  }
}

export default withRouter(PartnershipAnalyticsTableRow)
