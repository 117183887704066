import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Dialog } from 'react-toolbox/lib/dialog'
import {
  bool,
  object,
  number,
  func,
} from 'prop-types'

import DialogTheme from 'css/themes/dialogs/modalBase.css'
import { P } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import * as OpportunityActions from 'actions/opportunities-actions'
import { Form } from 'visual-components/util/form'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'

import OpportunitySelectType from './opportunity-select-type'
import OpportunityDescribe from './opportunity-describe'
import DedicatedEmailOpportunityInfo from './dedicated-email-opportunity-info'
import PrizingPartnerOpportunityInfo from './prizing-partner-opportunity-info'
import ProductShipmentInsertOpportunityInfo from './product-shipment-insert-opportunity-info'
import ContentSwapsOpportunityInfo from './content-swaps-opportunity-info'

const hasAgeSelected = model =>
  Object.keys(model).some(key => key.startsWith('age_') && model[key])

const typeMap = {
  dedicated_email_buy: {
    name: 'Dedicated Email Buy',
    component: DedicatedEmailOpportunityInfo,
    placeholder: 'Let partners know what you’d pay for CPC / CPM, how many clicks you’d budget for, and what list size you’d like to target.',
  },
  dedicated_email_sell: {
    name: 'Dedicated Email Sale',
    component: DedicatedEmailOpportunityInfo,
    placeholder: 'Making your offer complete by sharing your CPC / CPM asking price, how many clicks they should budget for, and any details about your list size and its demographics. ',
  },
  prizing_partner: {
    name: 'Prizing',
    component: PrizingPartnerOpportunityInfo,
    placeholder: 'Provide some details about what sort of prizing you’re looking for (travel/product/cash/multiple) and the retail/cash value you estimate would be required. ',
  },
  product_shipment_insert: {
    name: 'Insert Swap',
    component: ProductShipmentInsertOpportunityInfo,
    canProceed: hasAgeSelected,
    placeholder: 'Potential partners may want to know about how many boxes you ship a month, more about your customer demographics and the minimum number of inserts you’d be looking to swap.',
  },
  content_swap: {
    name: 'Content Swap',
    component: ContentSwapsOpportunityInfo,
    canProceed: hasAgeSelected,
    placeholder: 'For a compelling post, you may want to share your reader demographics, the average clicks your articles get, how much time readers spend on your content and your email list size.',
  },
}

const mapStateToProps = ({ opportunity, createOpportunityModal }) =>
  ({ opportunity, createOpportunityModal })

const mapDispatchToProps = dispatch => ({
  opportunityActions: bindActionCreators(OpportunityActions, dispatch),
})

class CreateOpportunityModal extends PureComponent {
  static propTypes = {
    active: bool,
    editing: bool,
    // for editing from profile pages
    forceStep: number,
    history: object,
    closeEditingModal: func.isRequired,
    createOpportunityModal: object,
    opportunityActions: object,
    persistEditOpportunity: func.isRequired,
    opportunity: object.isRequired,
  }

  static defaultProps = {
    editing: false,
    forceStep: 0,
    active: false,
    history: {},
    createOpportunityModal: {},
    opportunityActions: {},
  }

  state = { step: 0 }

  componentWillReceiveProps = nextProps => {
    const { opportunity } = this.props

    if (opportunity.type !== nextProps.opportunity.type) {
      this.setState({ step: this.getInitialStep(nextProps.opportunity) })
    }
  }

  componentDidMount = () => {
    const step = this.getInitialStep()

    return this.setState({ step })
  }

  getInitialStep = (opportunity = this.props.opportunity) => {
    return this.props.forceStep
      || (
        this.props.editing
          ? 1
          : 0
      )
  }

  advance = model => {
    const { step } = this.state
    const {
      opportunityActions,
      forceStep,
      editing,
      persistEditOpportunity,
    } = this.props

    const stepMap = {
      0: () => {
        opportunityActions.create(model.type)
        EventTrackingDispatcher.track('appPartnerships_partnership_createPartnership_opportunityStep1Complete')
      },
      1: () => EventTrackingDispatcher.track('appPartnerships_partnership_createPartnership_opportunityStep2Complete'),
      2: () => EventTrackingDispatcher.track('appPartnerships_partnership_createPartnership_opportunityStep3Complete'),
    }

    if (stepMap[step]) {
      stepMap[step]()
    }

    if (editing) {
      return persistEditOpportunity(model)
    }

    if (forceStep) {
      return this.persistOpportunity(model)
    }

    if (step < 2) {
      return this.setState({ step: 1 + step })
    }

    this.persistOpportunity(model)
    return setTimeout(() => this.setState({ step: editing ? 1 : 0 }), 500)
  }

  reverse = () => {
    const {
      editing,
      opportunity: { type },
      forceStep,
      opportunityActions,
    } = this.props

    const { step } = this.state

    if (forceStep || step === 0 || (editing && step === 1)) {
      return opportunityActions.closeCreateOpportunityModal()
    }

    const newStep = step - 1

    return this.setState({ step: newStep })
  }

  persistOpportunity = newOpportunity => {
    const { history, opportunityActions } = this.props

    opportunityActions.persist(newOpportunity)
    opportunityActions.closeCreateOpportunityModal()
    opportunityActions.openOpportunityConfirmationDialog()

    history.push('/partnerships/opportunities')
  }

  render() {
    const { step } = this.state
    const {
      active,
      editing,
      opportunity,
      createOpportunityModal,
      closeEditingModal,
      opportunityActions,
      forceStep,
    } = this.props

    const type = typeMap[opportunity.type]

    const stepMap = [
      {
        id: 'selectType',
        title: `${
          !editing && !forceStep
            ? 'Step 1 of 3: '
            : ''
        }Select the type of Opportunity you'd like to post.`,
        component: OpportunitySelectType,
      },
      {
        id: 'attributes',
        title: `${
          !editing && !forceStep ? 'Step 2 of 3: ' : ''
        }Select the attributes of your ideal ${type ? type.name : ''} partner.`,
        component: type ? type.component : (() => null),
        canProceed: o => (type && type.canProceed ? type.canProceed(o) : true),
      },
      {
        id: 'details',
        title: `${
          !editing && !forceStep
            ? 'Step 3 of 3: '
            : ''
        }Describe the details of your Opportunity.`,
        placeholder: type ? type.placeholder : '',
        component: OpportunityDescribe,
      },
    ]

    const {
      component: Step,
      id,
      title,
      placeholder,
      canProceed,
    } = stepMap[step]

    return (
      <Dialog
        theme={DialogTheme}
        active={editing ? active : !!createOpportunityModal.active}
        onEscKeyDown={editing ? closeEditingModal : opportunityActions.closeCreateOpportunityModal}
        onOverlayClick={editing ? closeEditingModal : opportunityActions.closeCreateOpportunityModal}
        title={`${editing || forceStep ? 'Edit' : 'Create'} Opportunity: ${id !== 'selectType' && type ? type.name : 'Select Type'}`}
      >
        <img
          onClick={editing ? closeEditingModal : opportunityActions.closeCreateOpportunityModal}
          className={DialogTheme.closeImage}
          src="/images/icons/close.svg"
        />
        <Form
          modelName="opportunity"
          updateModel={opportunityActions.update}
          handleSubmit={this.advance}
          mergeFieldsImmediately
        >
          <Grid>
            <div style={{ paddingBottom: '4px' }}>
              <Row>
                <Col xs>
                  <P style={{ marginBottom: '16px', fontSize: '16px' }}>
                    { title }
                  </P>
                  <Step
                    opportunity={opportunity}
                    opportunityActions={opportunityActions}
                    placeholder={placeholder}
                  />
                </Col>
              </Row>
            </div>
          </Grid>

          <ModalButtonGroupWithContext
            cancel={editing ? closeEditingModal : this.reverse}
            canSave={!canProceed || canProceed(opportunity)}
            cancelText={forceStep || step === 0 || (editing && step === 1) ? 'Cancel' : 'Back'}
            confirmText={
              forceStep
                ? 'Save' : step < 2
                  ? 'Next' : editing
                    ? 'Done' : 'Post'
            }
          />
        </Form>
      </Dialog>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateOpportunityModal))
