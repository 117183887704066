import _ from 'lodash';
import PartnershipApplicationConstants from 'constants/partnership-application-constants';

const {
  CLOSE_MODAL,
  START_APPLICATION,
  UPDATE_DATA,
  SUBMIT_APPLICATION_REQUEST,
  SUBMIT_APPLICATION_SUCCESS,
  SUBMIT_STEP_ONE,
  SUBMIT_STEP_TWO,
  TOGGLE_DATE_PICKER,
  CLOSE_APPLICATION_SENT_CONFIRMATION_MODAL
} = PartnershipApplicationConstants;

const initialState = {
  active: false,
  campaign: null,
  step: 1,
  datePickerOpen: false,
  applicationSuccess: false,
  applicationData: {
    listSize: '',
    greatFitMessage: '',
    pastPartners: [null, null, null],
    entryContribution: '',
    prizingContribution: '',
    optionalNote: '',
    // promotionStartDate: '',
    // promotionEndDate: '',
    legalDetails: {
      incorporationState: '',
      legalName: '',
      entityType: '',
      city: '',
      state: '',
      addressL1: '',
      addressL2: '',
      zipcode: '',
      country: 'United States'
    }
  },
  applicationSentModalOpen: false,
};

export default function PartnershipApplicationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case START_APPLICATION:
      return {
        ...initialState,
        active: true,
        campaign: action.payload.campaign,
        applicationData: {
          ...state.applicationData,
          listSize: action.payload.currentBrand.dedicatedListSize,
        },
      };
    case CLOSE_MODAL:
      return { ...state, active: false };
    case UPDATE_DATA:
      let data = Object.assign({}, state.applicationData, action.payload.applicationData, action.payload)
      return {
        ...state,
        ...action.payload,
        applicationData: data
      };
    case SUBMIT_STEP_ONE:
      return { ...state, step: 2 };
    case SUBMIT_STEP_TWO:
      return { ...state, step: 3 };
    case TOGGLE_DATE_PICKER:
      return { ...state, datePickerOpen: action.payload.open };
    case SUBMIT_APPLICATION_REQUEST:
      return initialState;
    case SUBMIT_APPLICATION_SUCCESS:
      return { ...state, applicationSuccess: true, applicationSentModalOpen: true };
    case CLOSE_APPLICATION_SENT_CONFIRMATION_MODAL:
      return { ...state, applicationSentModalOpen: false }
    default:
      return state;
  }
}
