import React from 'react'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { P } from 'visual-components/util/texts'

class RestrictedIntegrationDialog extends React.Component {
  render() {
    const { active, closeDialog } = this.props

    return (
      <div>
        <DefaultDialog active={ active }
          closeModal={ closeDialog }
          title={ 'Sorry! This Integration Has Restricted Access' }
          confirm={ closeDialog }
          confirmText={ 'Got It' }
          children={
            <div>
              <div className={ `${dialogTheme.bannerWrapper} ${dialogTheme.orangeBanner}` }>
                <img src={ '/images/icons/error-message-icon.svg' } />
              </div>

              <P multiline>
                { 'Please select another integration from the list.' }
              </P>
            </div>
          }
        />
      </div>
    )
  }
}

export default RestrictedIntegrationDialog
