import React, { Component } from 'react';
import PerkItem from './perk-item';
import theme from 'css/themes/homePage/careers.css';

class PerksAndBenefits extends Component {
  render() {
    return(
      <div className={ theme.perksWrapper }>
        <div className={ theme.inner }>
          <p className={ theme.title }>
            Perks and Benefits
          </p>
          <p className={ theme.explain }>
            Enjoy coming to work every day.
          </p>
          <div className={ theme.perksContainer }>
            <PerkItem
              imgSrc={ '/images/career-icons/health-benefits-01.svg' }
              title={ 'Health Coverage' }
              explain={ 'We offer competitive medical, dental and vision benefits - choose the plan that’s best for you. Not feeling well? We’ll encourage you to rest (and take advantage of your free One Medical membership).' }
            />
            <PerkItem
              imgSrc={ '/images/career-icons/breakfast-lunch-01.svg' }
              title={ 'Weekly Breakfast and Lunch' }
              explain={ 'Dig in! We provide a breakfast spread every Monday and lunch every Friday.' }
            />
            <PerkItem
              imgSrc={ '/images/career-icons/fitness-01.svg' }
              title={ 'Fitness Classes' }
              explain={ 'Take advantage of our corporate gym membership at New York Health and Racquet Clubs across the city. Feeling competitive? Spar in our group boxing classes or join the volleyball team.' }
            />
            <PerkItem
              imgSrc={ '/images/career-icons/soho.svg' }
              title={ 'A Beautiful SoHo Office' }
              explain={ 'Work from a loft in one of New York’s best neighborhoods. Explore local restaurants, bars and shopping in the heart of downtown NYC.' }
            />
            <PerkItem
              imgSrc={ '/images/career-icons/vacation-01.svg' }
              title={ 'Unlimited Vacation' }
              explain={ 'We trust you to get your work done and encourage you to take hard-earned rest (yes - that includes Summer Fridays).' }
            />
            <PerkItem
              imgSrc={ '/images/career-icons/team-outings-01.svg' }
              title={ 'Team Events' }
              explain={ 'Enjoy game and movie nights, book club, wine tastings and other chill fun with the team.' }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default PerksAndBenefits;
