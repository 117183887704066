import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'react-toolbox/lib/checkbox'
import CheckboxTheme from 'css/themes/Checkbox.css'
import CheckboxOverdueTheme from 'css/themes/Checkbox-overdue-theme.css'
import TaskListTheme from 'css/themes/TaskList.css'
import { Tiny } from 'visual-components/util/texts'

const renderTasks = (markComplete, onSelectTask) => (task, index) => {
  const checkboxTheme = !task.completed_at && task.isDue
    ? CheckboxOverdueTheme
    : CheckboxTheme

  const label = (
    <span
      className={TaskListTheme.taskLabel}
      onClick={e => onSelectTask(e, task)}
    >
      {
        task.task && task.task.length > 25
          ? `${task.task.slice(0, 25)}...`
          : task.task
      }
    </span>
  )

  return (
    <div key={task.id} className={TaskListTheme.task}>
      <Checkbox
        key={index}
        label={label}
        theme={checkboxTheme}
        disabled={!task.canEdit || task.category === 'minimum_entries_due'}
        checked={!!task.completed_at}
        onChange={() => markComplete(task)}
        className={CheckboxTheme.taskCheckbox}
      />
      {
        task.isDue
        && !task.isComplete
        && (
          <div className={TaskListTheme.dueHolder}>
            <Tiny white>
              <em>DUE</em>
            </Tiny>
          </div>
        )
      }
    </div>
  )
}

class TaskChecklist extends Component {
  selectTask = (e, task) => {
    e.preventDefault()

    const { campaignTaskActions } = this.props

    let cardHeight = 0
    let cardWidth = 0

    if (e.clientY > window.innerHeight - 300) {
      cardHeight = window.innerHeight - 300
    } else {
      cardHeight = e.clientY - 25
    }

    if (e.clientX > window.innerWidth - 600) {
      cardWidth = window.innerWidth - 550
    } else {
      cardWidth = e.clientX - 50
    }

    campaignTaskActions.selectTask(task, {
      left: cardWidth,
      top: cardHeight,
    })
  }

  render() {
    const { tasks, markComplete } = this.props

    return (
      <div className={TaskListTheme.checkbox}>
        { tasks.map(renderTasks(markComplete, this.selectTask)) }
      </div>
    )
  }
}

TaskChecklist.propTypes = {
  tasks: PropTypes.array.isRequired
}

export default TaskChecklist
