import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { Button } from 'react-toolbox/lib/button'

import {
  P, Small, H3, H4,
} from 'visual-components/util/texts'
import cn from 'classnames'
import Tooltip from 'util/components/tooltip-v2'
import COLOURS from 'util/colours'
import ButtonTheme from 'css/themes/Buttons.css'
import MessageSlider from 'visual-components/messaging/message-slider'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import requestHeaders from 'request-config'
import { stateToHTML } from 'draft-js-export-html'
import SplitTrafficPartner from './SplitTrafficPartner'

const styles = {
  table: {
    width: '100%',
    '& thead': {
      borderBottom: `1px solid ${COLOURS.foggy}`,
      '& th > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& th div': {
      display: 'flex',
    },
  },
  noPartners: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    padding: '43px 0 36px',
    height: 243,
    justifyContent: 'space-around',
    '& img': {
      width: 44,
      height: 32,
    },
  },
  footer: {
    borderTop: `1px solid ${COLOURS.foggy}`,
    display: 'flex',
    height: 36,
    marginBottom: 24,
    paddingTop: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonsGap: {
    marginRight: 12,
  },
}

const HEADER_CONTENT = [
  { text: 'Eligible Partners' },
  { text: 'Status' },
  { text: 'Traffic Offered', tooltip: 'Offer to split a percentage of your Sweepstakes post entry traffic with partner brands. Partner brands will need to accept your invite and set up a Post Entry Action to receive traffic.' },
]

function SplitTrafficPartnersTable(props) {
  const {
    classes: css,
    currentBrand,
    partners,
    onSavePartner,
    onPercentChange,
    onUpdatePartnerField,
    match: {
      params: {
        campaignId,
      },
    },
    campaignName,
    campaignUid,
    campaignStatus,
    history,
  } = props

  const [messagePartnerInfo, setMessagePartnerInfo] = useState({})

  const getMessageText = () => {
    if (messagePartnerInfo.messageType === 'invite') {
      return `<p>Hi ${messagePartnerInfo.accountname},<br /></p>
        <p>We would like to offer you ${messagePartnerInfo.traffic}% of the post entry traffic for ${campaignName}. Depending on your plan, you can set up a Post Entry Action to either collect SMS Marketing subscribers or drive entrants to your website via a Custom CTA.<br /></p>
        <p>In order to receive the traffic you must accept this invitation and set up a SMS or Custom CTA action.<br /></p>
        <p>Let us know if you have any questions!<br /></p>
        <p>Best,<br />${currentBrand.accountname}<br /></p>
      `
    }
    return ''
  }

  const onCloseMessage = () => {
    setMessagePartnerInfo({})
  }

  const onSendMessage = async message => {
    if (messagePartnerInfo.messageType === 'invite') {
      onUpdatePartnerField(messagePartnerInfo.id, 'status', 'invited')
      onUpdatePartnerField(messagePartnerInfo.id, 'state', 'inviteSending')
    }
    await axios.post(`/partnerships/split-traffic-message/${messagePartnerInfo.messageType}/${campaignId}`,
      {
        content: stateToHTML(message),
        otherBrandId: messagePartnerInfo.id,
      },
      {
        headers: requestHeaders(),
      })
    if (messagePartnerInfo.messageType === 'invite') {
      onUpdatePartnerField(messagePartnerInfo.id, 'state', 'inviteSent')
    }
    setMessagePartnerInfo({})
  }

  // message type can be 'invite' or 'message'
  const onClickMessage = (partner, messageType) => {
    // cannot send messages to partners that have not accepted an invite
    if (messageType === 'message' && partner.status !== 'active') return
    setMessagePartnerInfo({ ...partner, messageType })
  }

  return (
    <>
      <table className={css.table}>
        <thead>
          <tr>
            {HEADER_CONTENT.map(header => (
              <th key={header.text}>
                <div>
                  <P multiline>
                    <em>
                      {header.text}
                    </em>
                  </P>
                  {header.tooltip && <Tooltip text={header.tooltip} />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {partners.map(partner => (
            <SplitTrafficPartner
              key={partner.id}
              onPercentChange={onPercentChange}
              onSave={onSavePartner}
              partner={partner}
              onClickInvite={() => onClickMessage(partner, 'invite')}
              onClickMessage={onClickMessage}
              campaignUid={campaignUid}
              campaignStatus={campaignStatus}
            />
          ))}
        </tbody>
      </table>
      {partners.length === 0 ? (
        <div className={css.noPartners}>
          <img src="/images/icons/person.svg" />
          <H3>
            <small>You don’t have eligible partners yet</small>
          </H3>
          <H4>
            Your eligible partners will appear here once partnership agreements
            have been signed.
          </H4>
          <div>
            <Button
              onClick={() => history.push(`/builder/${campaignId}/partner`)}
              label="Manage Partners"
              className={cn(ButtonTheme.whiteButtonAzureText, css.buttonsGap)}
              theme={ButtonTheme}
              raised
            />
            <Button
              onClick={() => {
                window.location = '/app/search/brands'
              }}
              label="Find Partners"
              className={ButtonTheme.blueButton}
              theme={ButtonTheme}
              primary
              raised
            />
          </div>
        </div>
      ) : null}
      <div className={css.footer}>
        <Small>
          please note: partners only receive a message when invited.
        </Small>
      </div>
      {
        messagePartnerInfo.id && (
        <MessageSlider
          isShown
          onClose={onCloseMessage}
          onSend={onSendMessage}
          title={`New Message to ${messagePartnerInfo.accountname}`}
          buttonText="Send Message"
          initialContent={getMessageText()}
        />
        )
      }

    </>
  )
}

SplitTrafficPartnersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  onSavePartner: PropTypes.func.isRequired,
  onPercentChange: PropTypes.func.isRequired,
  currentBrand: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onUpdatePartnerField: PropTypes.func.isRequired,
  campaignName: PropTypes.string.isRequired,
  campaignUid: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  currentBrand: state.currentBrand,
})

export default React.memo(
  connect(mapStateToProps)(
    withRouter(
      injectCSS(styles)(SplitTrafficPartnersTable)
    )
  )
)
