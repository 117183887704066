const initialState = {
  loading: true,
  deal_id: null,
  saved: false,
  buyer_brand_id: null,
  seller_brand_id: null,
  budget_min: null,
  budget_max: null,
  budget_range: null,
  media_interests: [],
  deal_terms: [],
  start_date: null,
  end_date: null,
  expiration_date: null,
  is_date_flexible: false,
  brand_description: '',
  conversion_rate: null,
  conversion_value: null,
  seller_note: null,
}

const setLoading = (state, status) => {
  state.loading = !!status
}

const load = (state, action, status) => {
  let newState = { ...state }

  switch (status) {
    case 'loading':
      setLoading(newState, true)
      newState.saved = false
      break
    case 'failure':
      toastr.error('Cannot load the brand. Please refresh.')
      break
    case 'success':
      setLoading(newState, false)
      newState = {
        ...newState,
        ...action.payload,
        saved: false,
      }
      newState.budget_range = newState.budget_min === null
        ? null
        : `${newState.budget_min}-${newState.budget_max}`
      break
    default:
      break
  }

  return newState
}

const submit = (state, action, status) => {
  const newState = { ...state }

  switch (status) {
    case 'loading':
      setLoading(newState, true)
      break
    case 'failure':
      toastr.error('Cannot send the request. Please try again.')
      setLoading(newState, false)
      break
    case 'success':
      setLoading(newState, false)
      if (!newState.deal_id) {
        newState.deal_id = action.payload.deal_id
      }
      newState.saved = true
      break
    default:
      break
  }

  return newState
}

const updateLoading = (state, isLoading) => {
  const newState = {
    ...state,
    loading: !!isLoading,
  }

  return newState
}

export default function dealRequestReducer(state = initialState, action) {
  switch (action.type) {
    case 'DEAL_REQUEST_LOAD':
      return load(state, action, 'loading')
    case 'DEAL_REQUEST_LOAD_SUCCESS':
      return load(state, action, 'success')
    case 'DEAL_REQUEST_LOAD_FAILURE':
      return load(state, action, 'failure')
    case 'DEAL_REQUEST_SUBMIT':
      return submit(state, action, 'loading')
    case 'DEAL_REQUEST_SUBMIT_SUCCESS':
      return submit(state, action, 'success')
    case 'DEAL_REQUEST_SUBMIT_FAILURE':
      return submit(state, action, 'failure')
    case 'DEAL_REQUEST_SET_LOADING_FALSE':
      return updateLoading(state, false)
    case 'DEAL_REQUEST_SET_LOADING_TRUE':
      return updateLoading(state, true)
    case 'DEAL_REQUEST_CLEAR':
      return { ...initialState }
    default:
      return state
  }
}
