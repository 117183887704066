import React, { Component } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import RegistrationHeader from 'components/registration/registration-header'
import BrandCategoriesContainer from 'components/brands/brand_categories/brand-categories-container'
import COLOURS from 'util/colours'
import NumberField from 'util/components/number-field'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'
import UploadLogo from 'components/brands/upload-logo'
import Validator from 'util/form-validator'
import { Button } from 'react-toolbox/lib/button'

import { H2, P, Small } from 'visual-components/util/texts'
import theme from 'css/themes/login/registrationTheme.css'
import buttonTheme from 'css/themes/Buttons.css'
import * as metrics from 'util/metrics'
import { memoize } from 'lodash'

const track = memoize(action => () => {
  metrics.create('registrationFlow', {
    meta: {
      page: 'brandProfile',
      action,
    }
  })
})

const mapStateToProps = state => ({
  currentBrand: state.currentBrand,
  brandForm: state.newBrandForm
})

const validations = {
  logo: [Validator.required],
  dedicatedListSize: [Validator.required]
}

class BrandProfile extends React.Component{
  componentDidMount() {
    if (!this.props.currentBrand.id) {
      $.ajax({
        method: 'GET',
        url: '/current-brand',
        success: brand => {
          store.dispatch({
            type: 'LOAD',
            model: 'currentBrand',
            data: brand
          });
        
          $.ajaxSetup({
            beforeSend: function(xhr) {
              xhr.setRequestHeader('brand', brand.id);
            }
          });
        },
        error: err => console.log(err)
      });
    }

    window.scrollTo(0,0)
    track('loadedBrandProfile')()
  }

  updateAttr = (attr, value) => {
    const data = {
      attr: attr,
      value: value
    }

    CurrentBrandDispatcher.updateAttr(data)
  }

  updateAttrPersist = _.debounce(data => {
    CurrentBrandDispatcher.updateAttrPersist(data, true)
  }, 500)

  finish = () => {
    track('submittedBrandProfile')()
    const {
      currentBrand: { id, accountname, dedicatedListSize },
      brandForm,
      history,
    } = this.props

    this.updateAttrPersist({
      attr: 'dedicatedListSize',
      value: dedicatedListSize
    })

    // Once the new brand profile is complete create recommended brands and campaigns.
    CurrentBrandDispatcher.createRecommendedBrands()
    CurrentBrandDispatcher.createRecommendedCampaigns()

    CurrentBrandDispatcher.goToInterests(id, history)
  }

  render() {
    const { currentBrand: brand, brandForm } = this.props

    let isDisabled = brand.dedicatedListSize > 0 && (brand.brand_tag_0 || brand.brand_tag_1 || brand.brand_tag_2) ? false : true

    return (
      <div>
        <RegistrationHeader
          step={ 3 }
        />
        <div className={ theme.container }>
          <div className={ theme.headerTitle }>
            <H2 multiline>
              Brand Profile
            </H2>
          </div>
          <div className={ `${theme.subheaderTitle} ${theme.subheaderWithPaddingBottom}`}>
            <P multiline>
              Welcome! Tell us about yourself. Add this info to participate in giveaways, appear in search results and connect with partners.
            </P>
          </div>
          <div>
            <BrandCategoriesContainer
              iconBackground={ COLOURS.white }
              brand={ brand }
              updateTags={ this.updateAttrPersist }
              isSilent={ true }
            />
            <NumberField
              dataModel={ brand }
              formModel={ brandForm }
              updateAttr={ this.updateAttr }
              placeholder="Enter list size"
              attrName="dedicatedListSize"
              label={ 'Email List Size *' }
              validations={ validations.dedicatedListSize }
            />
            <div className={ theme.logoUploader }>
              <UploadLogo
                brand={ brand }
              />
            </div>
            <div className={ `${theme.buttonWrapper}` }>
              <Button
                type={ 'submit' }
                onClick={ e => this.finish() }
                className={ isDisabled
                  ? `${buttonTheme.disabledButton} ${buttonTheme.noMargin} ${theme.botButton}`
                  : `${buttonTheme.blueButton} ${buttonTheme.noMargin} ${theme.botButton}`
                }
                theme={ buttonTheme }
                label={ 'Next' }
                disabled={ isDisabled }
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(BrandProfile)
