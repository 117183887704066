import React, { useState } from 'react'
import { Card } from 'react-toolbox/lib/card'

import { Form, Input, Submit } from 'visual-components/util/form'
import { P, H2 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { isValidDate } from 'visual-components/util/form/validations/date'

import AppDispatcher from 'dispatchers/app-dispatcher'

const CSAdminEditCampaignLaunch = () => {
  const [open, setOpen] = useState(false)
  const [campaignId, setCampaignId] = useState(null)
  const [startDate, setStartDate] = useState(null)

  const handleChange = e => {
    if (e.campaignId) setCampaignId(e.campaignId)
    if (e.startDate) setStartDate(e.startDate)
  }

  const handleSubmit = () => {
    AppDispatcher.http({
      url: `/cs-edit-campaign-launch/${campaignId}`,
      method: 'PUT',
      data: { startDate },
      success: () => {
        toastr.success('Launch Date Updated', null, {
          timeOut: 3000,
          positionClass: 'toast-bottom-center',
        })
      },
      error: error => {
        toastr.error(error.responseText, null, {
          timeOut: 3000,
          positionClass: 'toast-bottom-center',
        })
      },
    })
  }

  const CaretIcon = `fa fa-angle-${open ? 'up' : 'down'}`

  return (
    <Card style={{ padding: 24 }}>
      <Grid>
        <Row style={{ marginBottom: 16 }}>
          <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
            <H2 azure>
              Edit Campaign Launch Date
              <i className={CaretIcon} style={{ fontSize: 32, marginLeft: 8 }} />
            </H2>
            <P multiline>Change the launch date for a campaign.</P>
          </div>
        </Row>
        <Row style={{ display: open ? 'flex' : 'none' }}>
          <Col style={{ width: 175, display: 'inline-block', marginRight: 75 }}>
            <Form handleSubmit={handleSubmit} updateModel={handleChange}>
              <Input
                name="campaignId"
                label="Campaign ID"
                placeholder="Campaign ID"
                value={campaignId}
                type="text"
                required
              />
              <Input
                name="startDate"
                label="Date"
                placeholder="MM/DD/YY"
                value={startDate}
                validations={[isValidDate]}
                type="text"
                required
              />
              <Submit label="Update" disabled={!campaignId || !startDate} />
            </Form>
          </Col>
        </Row>
      </Grid>
    </Card>
  )
}

export default CSAdminEditCampaignLaunch
