import React, { Component } from 'react';
import { Dialog } from 'react-toolbox';
import { H4 } from 'visual-components/util/texts'
import dialogTheme from 'css/themes/dialogs/formModal.css';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

export default class SendReminderModal extends Component {
  confirmSendReminder = () => {
    this.props.referralActions.sendReminder(this.props.referral.id)
  }

  render() {
    const { active, referral } = this.props;
    let text;

    if (referral) {
      text = referral.campaignName ?
        <H4>Send friendly reminder to <em>{referral.email}</em> to join <em>{referral.campaignName}</em>?</H4>
        :
        <H4>Send friendly reminder to <em>{referral.email}</em> to sign up?</H4>
    }

    return (
      <Dialog
        theme={ dialogTheme }
        active={ active }
        onEscKeyDown={ this.props.referralActions.closeReminderModal }
        onOverlayClick={ this.props.referralActions.closeReminderModal }
        title='Send Invite Reminder'
      >
        {
          referral ?
          text
          :
          null
        }
        <ModalButtonGroup
          canSave={ true }
          cancel = { this.props.referralActions.closeReminderModal }
          confirm={ this.confirmSendReminder }
          hideLine={ true }
          confirmText="Send Reminder"
        />
      </Dialog>
    );
  }
}
