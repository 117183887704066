import React from 'react'
import { H3 } from 'visual-components/util/texts'
import FullscreenModalWideTheme from 'css/themes/modals/FullscreenModalWideTheme.css'

class FullscreenModalWide extends React.Component {
  render() {
    const { title, close, children } = this.props

    return (
      <div className={FullscreenModalWideTheme.container}>
        <div className={FullscreenModalWideTheme.closeButton}>
          <img src="/images/icons/close.svg" onClick={close} />
        </div>
        
        <div className={FullscreenModalWideTheme.headerMain}>
          <H3>{title}</H3>
        </div>

        {children}
      </div>
    )
  }
}

export default FullscreenModalWide
