import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ExploreContainer from 'visual-components/explore/explore-container'
import SearchBrandRatingConfig from 'actions/search-config/search-brand-rating-config'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'
import PageTitle from 'visual-components/util/PageTitle'
import Spinner from 'util/components/spinner'

import ReviewsTheme from 'css/themes/ratings-reviews.css'
import ReviewsMain from './ReviewsMain'

const stateToProps = ({ currentBrand, brandRatings }) => ({ currentBrand, brandRatings })

class BrandReviewsPreview extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.string.isRequired,
    currentBrand: PropTypes.object.isRequired,
    brandRatings: PropTypes.object.isRequired,
  }

  render() {
    const {
      title,
      subtitle,
      type,
      currentBrand,
      brandRatings,
    } = this.props

    const { loading: isLoading } = brandRatings

    if (isLoading && currentBrand.loading) return <Spinner />

    return (
      <div className={`${ReviewsTheme.flex} ${ReviewsTheme.column} ${ReviewsTheme.container}`}>
        <PageTitle title={title} subtitle={subtitle} />
        <div style={{ marginTop: '16px' }}>
          <ExploreContainer
            config={{
              ...SearchBrandRatingConfig,
              extendEndpoint: `/${currentBrand.id}/${type}`,
            }}
            hideTitle
            hideSearchBar
            width="100%"
            render={({
              results,
              loading,
              error,
            }) => (
              results
              && results.length
                ? (
                  <ReviewsMain
                    showCampaignName
                    type={type}
                    reviews={results || []}
                    loading={loading}
                    error={error}
                  />
                ) : <EmptyBonzai description={`You haven't ${type} any reviews yet.`} />
            )}
          />
        </div>
      </div>
    )
  }
}

export default connect(stateToProps)(BrandReviewsPreview)
