import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { Small } from 'visual-components/util/texts'

import colors from 'util/colours'

const CollapsibleArrow = () => {
  const dPath = 'M7.41 15.16L12 10.58l4.59 4.58L18 13.75l-6-6-6 6z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="#2C3541" fillRule="nonzero" d={dPath} />
    </svg>
  )
}

const styles = {
  wrapper: {
    backgroundColor: colors.crystal,
    padding: '8px 16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  space: {
    marginBottom: 16,
  },
  arrow: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '& svg': {
      marginLeft: 8,
      marginBottom: 2,
      '& path': {
        fill: `${colors.azure} !important`,
      },
      transform: ({ open }) => (open ? 'none' : 'rotate(90deg)'),
      transition: 'transform .1s linear',
    },
  },
}

const COMPLIANCE_COPY = "The following compliance language will be automatically appended to each subscriber's message every 30 days. This language will add 89 characters to a message and may impact Credits used."
const ATTACHMENT_COPY = '“Msg&data rates may apply. Msg frequency is recurring. Reply HELP for help, STOP to cancel”'

function ComplianceBanner({ classes: css, setOpen, open }) {
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <Small multiline>
          <em>Required Compliance Message</em>
        </Small>
        <div onClick={() => setOpen(prev => !prev)} className={css.arrow}>
          <Small azure>
            <em>{open ? 'Hide' : 'View'}</em>
          </Small>
          <CollapsibleArrow />
        </div>
      </div>
      {open && (
        <>
          <Small multiline>{COMPLIANCE_COPY}</Small>
          <div className={css.space} />
          <Small multiline>
            <em>{ATTACHMENT_COPY}</em>
          </Small>
        </>
      )}
    </div>
  )
}

ComplianceBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

const StyledComplianceBanner = injectCSS(styles)(ComplianceBanner)

function ComplianceBannerWrapper() {
  const [open, setOpen] = useState(false)

  return (
    <StyledComplianceBanner
      open={open}
      setOpen={setOpen}
    />
  )
}

export default React.memo(ComplianceBannerWrapper)
