import React, { Component } from 'react';
import theme from 'css/themes/mobile/home/home.css';

class Integrations extends Component {
  render() {
    return(
      <div className={ theme.integrationsContainer }>
        <p className={ theme.title }>
          Integrations
        </p>
        <p className={ theme.explain }>
          Integrate with your favorite marketing and sales tools and send new emails directly to your list.
        </p>
        <div className={ theme.inner }>
          <div className={ theme.imgWrapper }>
            <img
              alt="Mailchimp"
              src={ '/images/login/mobile/home/integrations/mailchimp-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/mailchimp-logo.png,
                /images/login/mobile/home/integrations/mailchimp-logo@2x.png 2x,
                /images/login/mobile/home/integrations/mailchimp-logo@3x.png 3x`}
            />
          </div>
          <div className={ theme.imgWrapper }>
            <img
              alt="Wordpress"
              src={ '/images/login/mobile/home/integrations/wordpress-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/wordpress-logo.png,
                /images/login/mobile/home/integrations/wordpress-logo@2x.png 2x,
                /images/login/mobile/home/integrations/wordpress-logo@3x.png 3x`}
            />
          </div>
        </div>
        <div className={ theme.inner }>
          <div className={ theme.imgWrapper }>
            <img
              alt="Convert Kit"
              src={ '/images/login/mobile/home/integrations/convert-kit-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/convert-kit-logo.png,
                /images/login/mobile/home/integrations/convert-kit-logo@2x.png 2x,
                /images/login/mobile/home/integrations/convert-kit-logo@3x.png 3x`}
            />
          </div>
          <div className={ theme.imgWrapper }>
            <img
              alt="SalesForce"
              src={ '/images/login/mobile/home/integrations/salesforce-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/salesforce-logo.png,
                /images/login/mobile/home/integrations/salesforce-logo@2x.png 2x,
                /images/login/mobile/home/integrations/salesforce-logo@3x.png 3x`}
            />
          </div>
        </div>
        <div className={ theme.inner }>
          <div className={ theme.imgWrapper }>
            <img
              alt="Sailthru"
              src={ '/images/login/mobile/home/integrations/sailthru-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/sailthru-logo.png,
                /images/login/mobile/home/integrations/sailthru-logo@2x.png 2x,
                /images/login/mobile/home/integrations/sailthru-logo@3x.png 3x`}
            />
          </div>
          <div className={ theme.imgWrapper }>
            <img
              alt="Shopify"
              src={ '/images/login/mobile/home/integrations/shopify-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/shopify-logo.png,
                /images/login/mobile/home/integrations/shopify-logo@2x.png 2x,
                /images/login/mobile/home/integrations/shopify-logo@3x.png 3x`}
            />
          </div>
        </div>
        <div className={ theme.inner }>
          <div className={ theme.imgWrapper }>
            <img
              alt="Hubspot"
              src={ '/images/login/mobile/home/integrations/hubspot-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/hubspot-logo.png,
                /images/login/mobile/home/integrations/hubspot-logo@2x.png 2x,
                /images/login/mobile/home/integrations/hubspot-logo@3x.png 3x`}
            />
          </div>
          <div className={ theme.imgWrapper }>
            <img
              alt="Retention Science"
              src={ '/images/login/mobile/home/integrations/re-sci-logo.png' }
              srcSet={`/images/login/mobile/home/integrations/re-sci-logo.png,
                /images/login/mobile/home/integrations/re-sci-logo@2x.png 2x,
                /images/login/mobile/home/integrations/re-sci-logo@3x.png 3x`}
            />
          </div>
        </div>
        <p className={ theme.manyMore }>
          ...and many more!
        </p>
      </div>
    )
  }
}

export default Integrations;
