
import React, { Component } from 'react'
import { Small } from 'visual-components/util/texts'
import { object, func } from 'prop-types'
import DocumentReceiverTheme from 'css/themes/media-market/assetVisuals/DocumentReceiver.css'

class DocumentReceiver extends Component {
  static propTypes = {
    asset: object.isRequired,
    downloadAssetFunc: func.isRequired,
  }

  render() {
    const {
      asset,
      downloadAssetFunc,
    } = this.props

    const assetLogoMap = {
      pdf: 'images/icons/receiver-asset-pdf-icon.png', // receiver and sender have diff icons
    }

    return (
      <div style={{ display: 'block !imporant', position: 'relative' }}>
        <div className={DocumentReceiverTheme.fileWrapperContainer}>
          <div className={DocumentReceiverTheme.background}>
            <div className={DocumentReceiverTheme.fileWrapperItemInfo}>
              <Small><em>{asset.name.length > 28 ? `${asset.name.slice(0, 28)}...` : asset.name}</em></Small>

              <div className={DocumentReceiverTheme.fileType}>
                <Small>{`${asset.size} - .${asset.type}`}</Small>
              </div>
            </div>
          </div>
          <div className={DocumentReceiverTheme.verticalLine} />

          <div className={DocumentReceiverTheme.fileWrapperIconContainer}>
            <img src={assetLogoMap[asset.type] || 'images/icons/assets-file-wrapper-icon.png'} />
          </div>
        </div>

        <img
          src="images/icons/blue-asset-download-icon.png"
          className={DocumentReceiverTheme.downloadIcon}
          onClick={downloadAssetFunc}
        />

      </div>
    )
  }
}

export default DocumentReceiver
