var model = 'upcomingCampaigns';
import _ from 'lodash'

var defaultState =  {
  rid: model,
  campaigns: [],
  sort: 'startDate',
  reversed: false
}

var UpcomingCampaignsReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    ADD_INVITE: function () {
      var campaign = _.find(state.campaigns, { id: action.data.campaign_id });
      if (campaign) {
        campaign.invites.push(action.data);
      }

      return _.extend({}, state);
    },
    UPDATE_SORT: function () {
      if (action.data.sort === state.sort) {
        return _.extend({}, state, { campaigns: state.campaigns.reverse(), reversed: !state.reversed });
      } else {
        var campaigns = _.sortBy(state.campaigns, action.data.sort);
        if (action.data.sort != 'name') {
          campaigns = campaigns.reverse();
        }

        return _.extend({}, state, { sort: action.data.sort, campaigns: campaigns, reversed: false });
      }
    },
    LOAD: function () {
      var campaigns = store.getState().brandCampaigns.upcomingCampaigns;
      campaigns = _.sortBy(campaigns, state.sort);
      _.each(campaigns, function (campaign) {

        campaign.totalEmails = campaign.newEmails;
        campaign.totalConversions = campaign.conversions;
        campaign.totalPostEntries = campaign.postEntries;
        campaign.invites = campaign.invites;

        campaign.totalVisits = parseInt(campaign.visits);
        campaign.totalEntries = parseInt(campaign.entries);
        campaign.totalImpressions = campaign.impressions;
        campaign.numberOfPartners = Object.keys(campaign.partners).length;

        // visible partner logic should include all accepted partners and as many extra that are pending and have gotten to the partnership agreement step
        campaign.visiblePartners = campaign.allPartners.filter((invite) => {
          return invite.status === 'accepted'
        })

        campaign.allPartners.forEach((invite) => {
          if (campaign.visiblePartners.length < 8) { // still less than 8 and fit criteria(accepted already in list)
            if (!['accepted', 'rejected'].includes(invite.status) && !!invite.partnership_agreement_status) {
              campaign.visiblePartners.push(invite)
            }
          }
        })

        campaign.partnerImages = campaign.visiblePartners.filter(invite => {
          return invite.partner_id !== campaign.hostBrandId
        }).map(invite => { return invite.logo })

        var entryRate = campaign.totalEntries / campaign.totalVisits;
        campaign.entryRate = isNaN(entryRate) ? 0 : entryRate;
      })

      return _.extend({}, state, { campaigns: campaigns, loaded: true });
    },
    ADD_PROMO_UNIT: function () {
      var campaigns = _.clone(state.campaigns);

      var campaign = _.find(campaigns, { id: action.data.campaign_id });
      if (!campaign) {
        return state;
      }
      campaign.totalImpressions += action.data.impressions;

      return _.extend({}, state, {campaigns: campaigns});
    },
    REMOVE_PROMO_UNIT: function () {
      var campaigns = _.clone(state.campaigns);

      var campaign = _.find(campaigns, { id: action.data.campaign_id });
      if (!campaign) {
        return state;
      }
      campaign.totalImpressions -= action.data.impressions;

      return _.extend({}, state, {campaigns: campaigns});
    },
    SET_CAMPAIGN_DELETING: function () {
      return _.extend({}, state, { campaignDeletingId: action.data.id });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default UpcomingCampaignsReducer;
