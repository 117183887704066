import React from 'react';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { bool, func } from 'prop-types';

import { Dialog } from 'react-toolbox/lib/dialog';
import dialogTheme from 'css/themes/dialogs/dialogBase.css';
import COLOURS from 'util/colours';
import { P } from 'visual-components/util/texts'

class PlatformInviteSuccessModal extends React.Component {
  closeModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'platformInviteBrand',
      data: {
        showSuccessModal: false,
        contactName: '',
        contactEmail: '',
        inviteeBrandName: '',
        campaign_id: '',
      }
    })
  }
  redirect = () => {
    this.props.history.push(`/profile/brand/referrals`)
    this.closeModal()
  }

  render () {
    const {
      inviteeBrandName,
      campaign_id
    } = this.props.platformInviteBrand

    const campaignName = campaign_id ? <span> for <em> {this.props.campaigns[campaign_id].name} </em></span> : null

    return(
      <Dialog
        theme={ dialogTheme }
        active={ !!this.props.platformInviteBrand.showSuccessModal }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title={ 'Your invitation has been sent!' }
      >
        <div style={{
          paddingTop: '8px',
        }}>
          <Grid>
            <Row>
              <Col xs>
                <div style={{
                  margin: 'auto',
                  textAlign: 'center',
                  paddingBottom: '24px'
                }}>
                  <img src="/images/icons/celebration-icon.svg"
                    style={{
                      height: '120px',
                      width: '115px'
                  }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <P multiline>
                  Your invitation to <b>{ inviteeBrandName }</b>
                  {
                    campaign_id ?
                      campaignName
                      :
                      ` `
                  }
                  has been sent. Track your invitation status and referral credits from your Referral Dashboard in Settings.
                </P>
              </Col>
            </Row>
            <ModalButtonGroup
              cancel={ this.closeModal }
              cancelText={ 'Close' }
              confirm={this.redirect}
              confirmText={ 'Go to Dashboard'}
              canSave={ true }
              hideLine={ true }
            />
          </Grid>
        </div>
      </Dialog>
    )
  }
}

const mapState = ({platformInviteBrand, brandCampaigns}) => ({
  platformInviteBrand,
  campaigns: brandCampaigns.campaignsHash
})

export default withRouter(connect(mapState)(PlatformInviteSuccessModal))
