import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { H3, H4 } from 'visual-components/util/texts'

import colors from 'util/colours'

const CloseImage = props => {
  const dPath = 'M16 .941l-.928-.925L8 7.075.928.015 0 .942l7.072 7.06L0 15.058l.928.925L8 8.926l7.072 7.058.928-.925L8.927 8z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path fill="#475464" fillRule="nonzero" d={dPath} />
    </svg>

  )
}

const styles = {
  topbar: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  xButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
  title: {
    marginBottom: 24,
  },
  tabs: {
    borderBottom: `1px solid ${colors.silver}`,
    width: 'calc(100% + 40px)',
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    cursor: 'pointer',
    paddingBottom: 12,
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: 56,
    },
  },
  selected: {
    position: 'absolute',
    width: '100%',
    height: 4,
    backgroundColor: colors.neonMint,
    bottom: -2,
  },
}

function MediaTabs(props) {
  const {
    tabs, selectedTab, onChange, onClose, classes: css,
  } = props

  return (
    <div className={css.topbar}>
      <CloseImage className={css.xButton} onClick={onClose} />
      <div className={css.title}>
        <H3>Insert Media</H3>
      </div>
      <div className={css.tabs}>
        {tabs.map((tab, idx) => (
          <div key={tab} className={css.tab} onClick={() => onChange(idx)}>
            <H4>{selectedTab === idx ? <i>{tab}</i> : tab}</H4>
            {selectedTab === idx && <div className={css.selected} />}
          </div>
        ))}
      </div>
    </div>
  )
}

MediaTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
}

export default React.memo(injectCSS(styles)(MediaTabs))
