import React, {Component} from 'react'
import { H1, P } from 'visual-components/util/texts'
import theme from 'css/themes/referralTheme.css'

class EntryThermometer extends Component {
  render() {
    const {
      entries,
      userName
    } = this.props

    const entryPercent = 100 - entries
    const percentClass = `percent-${entryPercent}`

    return(
      <div className={theme.thermometerWrapper}>
        <div className={theme.text}>
          <P>
            <em>
              {`${userName}'S ENTRIES`}
            </em>
          </P>
        </div>
        <H1>
          {entries > 100 ? 100 : entries}
        </H1>
        <div className={theme.thermoProper}>
          <div className={theme.progressWrapper}>
            <div className={`${theme.undone} ${theme[percentClass]}` }
              style={{
                height: `${entryPercent}%`
            }}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default EntryThermometer
