import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import d3 from 'd3'

import Theme from 'css/themes/ExpandableCalculatorTheme.css'

import PlainBar from './visual-util/plain-bar'
import ExpandableBar from './visual-util/expandable-bar'
import ExpansionPanel from './visual-util/expansion-panel'
import ROIResultBar from './visual-util/roi-result-bar'

const stateToProps = ({ roiCalculator }) => ({ roiCalculator })

const getData = roiCalculatorData => {
  const {
    conversionRate,
    avgLifetimeValue,
    avgCampaignSpend,
    brandNTF,
    numOfCampaigns,
    totalSpend,
    conversions,
    valueReturned,
    softwareFees,
    numOfMonths,
    avgMonthlyFee,
  } = roiCalculatorData

  return {
    'total-investment': [
      {
        0: {
          title: 'No. of Campaigns',
          value: numOfCampaigns,
        },
        1: {
          title: 'Avg Spend/Campaign',
          value: d3.format('$,.f')(avgCampaignSpend),
          isBlue: true,
        },
        2: {
          title: 'Total Spend',
          value: d3.format('$,.f')(totalSpend),
        },
      },
      {
        0: {
          title: 'No. of Months',
          value: numOfMonths,
        },
        1: {
          title: 'Avg Monthly Fee',
          value: d3.format('$,.f')(avgMonthlyFee),
        },
        2: {
          title: 'Total Software Fees',
          value: d3.format('$,.f')(softwareFees),
        },
      },
    ],
    conversions: [
      {
        0: {
          title: 'NTF',
          value: d3.format(',')(brandNTF),
        },
        1: {
          title: 'Conversion Rate',
          value: d3.format('.2%')(conversionRate / 100),
          isBlue: true,
        },
        2: {
          title: 'Conversions',
          value: d3.format(',')(conversions),
        },
      },
    ],
    'value-returned': [
      {
        0: {
          title: 'Avg Lifetime Value',
          value: d3.format('$,.f')(avgLifetimeValue),
          isBlue: true,
        },
        1: {
          title: 'Conversions',
          value: d3.format(',')(conversions),
        },
        2: {
          title: 'Value Returned',
          value: d3.format('$,.f')(valueReturned),
        },
      },
    ],
  }
}

class ExpandableCalculator extends PureComponent {
  static propTypes = {
    roiCalculator: object.isRequired,
  }

  state = {
    selected: [],
  }

  onSelect = item => () => {
    const { selected } = this.state
    const newSelected = selected

    if (newSelected.includes(item)) {
      const temp = newSelected.filter(x => x !== item)
      return this.setState({ selected: temp })
    }

    newSelected.push(item)
    return this.setState({ selected: newSelected })
  }

  render() {
    const { selected } = this.state
    const { roiCalculator } = this.props

    const {
      brandNTF,
      roi,
      conversions,
      totalInvestment,
    } = roiCalculator

    const expansionData = getData(roiCalculator)

    return (
      <div className={Theme.main}>
        <Card theme={Theme}>
          <PlainBar
            title="Unique Emails Acquired (NTF)"
            value={brandNTF}
            tooltipText="NTF stands for New To File and it accounts for all new, unique emails contributed to a giveaway, that you have not previously received from another giveaway on DojoMojo."
          />

          <div onClick={this.onSelect('total-investment')}>
            <ExpandableBar
              title="Total Investment"
              value={d3.format('$,.f')(totalInvestment)}
              selected={selected.includes('total-investment')}
            />
            <ExpansionPanel
              selected={selected.includes('total-investment')}
              rowData={expansionData['total-investment']}
            />
          </div>

          <div onClick={this.onSelect('conversions')}>
            <ExpandableBar
              title="Conversions from DojoMojo"
              value={d3.format(',')(conversions)}
              selected={selected.includes('conversions')}
            />
            <ExpansionPanel
              selected={selected.includes('conversions')}
              rowData={expansionData.conversions}
            />
          </div>

          <div onClick={this.onSelect('value-returned')}>
            <ExpandableBar
              title="Value Returned"
              value={expansionData['value-returned'][0][2].value}
              selected={selected.includes('value-returned')}
            />
            <ExpansionPanel
              selected={selected.includes('value-returned')}
              rowData={expansionData['value-returned']}
            />
          </div>

          <ROIResultBar title="ROI" value={roi} />
        </Card>
      </div>
    )
  }
}

export default connect(stateToProps)(ExpandableCalculator)
