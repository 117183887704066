import React from 'react'
import ErrorMsg from 'util/components/templates/form/inputs/error-msg'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import styleHelper from 'v2/styles/style-helper'

const styles = {
  partnerContainer: {
    margin: '5px 0 0 24px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  partnerCheckbox: {
    marginBottom: '11px',
    width: props => (props.desktopVersion ? '50%' : '100%'),
    [styleHelper.max(700)]: {
      width: '100% !important',
    },
  },
  checkboxContainer: {
    color: props => props.entryButtonStyles.formTextColor.color.color,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 11,
    lineHeight: '18px',
    marginBottom: 12,
    position: 'relative',
    userSelect: 'none',
    '& a': {
      color: props => props.entryButtonStyles.entryButtonColor.color.color,
    },
    '& input': {
      cursor: 'pointer',
      height: 14,
      opacity: 0,
      position: 'absolute',
      width: 14,
      '&:checked': {
        '& ~ div:first-of-type': {
          backgroundColor: props => props.entryButtonStyles.entryButtonColor.color.color,
          '&::after': {
            display: 'inline-block',
          },
        },
      },
    },
  },
  checkbox: {
    backgroundColor: 'transparent',
    border: props => `1px solid ${props.entryButtonStyles.entryButtonColor.color.color}`,
    height: 14,
    margin: '4px 12px 0px 0px',
    minWidth: 14,
    position: 'relative',
    width: 14,
    '&::after': {
      border: '1px solid',
      borderColor: props => props.entryButtonStyles.entryButtonText.color.color,
      borderLeft: 'none',
      borderTop: 'none',
      content: "''",
      display: 'none',
      height: 8,
      left: 4,
      position: 'absolute',
      top: 1,
      transform: 'rotate(45deg)',
      width: 5,
    },
  },
  additionalTerms: {
    margin: '4px 0 10px 0',
    '& p': {
      fontSize: 10,
    },
  },
  terms: {
    marginBottom: 6,
  },
  checkboxError: {
    color: '#ff3c2a',
    fontSize: 11,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '18px',
    marginBottom: 16,
    '& a': {
      color: '#ff3c2a',
      textDecoration: 'underline',
    },
  },
}

class Optin extends React.Component {
  static propTypes = {
    additionalTermsDetail: propTypes.string.isRequired,
    partnerBrandNames: propTypes.string.isRequired,
    partnerBrandNamesCommas: propTypes.string.isRequired,
    hostBrandName: propTypes.string.isRequired,
    perBrandTerms: propTypes.bool.isRequired,
    termsLink: propTypes.string.isRequired,
    tosLinkLabel: propTypes.string.isRequired,
    allPartners: propTypes.string.isRequired,
    campaignId: propTypes.number.isRequired,
    preCheck: propTypes.string.isRequired,
    entryButtonStyles: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    inputs: propTypes.object.isRequired,
    hasSmsOptInLanguage: propTypes.bool.isRequired,
  }

  static contextTypes = {
    baseStyles: propTypes.object,
  }

  renderCheckBox = properties => {
    const { classes: css, entryButtonStyles } = this.props

    const { baseStyles } = this.context

    return (
      <label className={css.checkboxContainer}>
        <input
          type="checkbox"
          name={properties.name}
          checked={properties.preCheck}
          value={properties.preCheck}
          style={{ zIndex: 2 }}
        />
        <div className={cn('sweep', css.checkbox, baseStyles.optInCheckbox)} />
        {properties.label}
      </label>
    )
  }

  renderPerBrandTerms = preCheck => {
    const { allPartners, classes: css } = this.props

    return (
      <div className="per-brand-checkboxes clearfix" style={{ marginTop: '10px' }}>
        {this.renderCheckBox({
          name: 'agree_all_opt_in',
          label:
            'I also agree to receive email communication and special offers from all partners:',
          preCheck,
        })}
        <ErrorMsg />
        <div className={css.partnerContainer}>
          {allPartners.map((partner, index) => (
            <div className={css.partnerCheckbox}>
              {this.renderCheckBox({
                name: `${index}_email_opt_in`,
                label: partner,
                preCheck,
              })}
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderReiCampaign = preCheck => (
    <div className="checkbox text-left terms-box-2">
      <label style={{ fontSize: '10px' }}>
        <input
          name="email_opt_in_2"
          type="checkbox"
          checked={preCheck}
          value={preCheck}
          className="sweep2"
        />
        <div className="html-edit" data-attr="emailOptInLang" id="email-optin-lang">
          <div>
            <p style={{ fontSize: '10px' }}>
              Sign up for REI emails about co-op events, offers and cool new gear
            </p>
          </div>
        </div>
      </label>
      <br />
      <ErrorMsg />
    </div>
  )

  render() {
    const {
      additionalTermsDetail,
      partnerBrandNames,
      hostBrandName,
      perBrandTerms,
      termsLink,
      tosLinkLabel,
      campaignId,
      classes: css,
      inputs,
      hasSmsOptInLanguage,
    } = this.props

    const { baseStyles } = this.context

    let { preCheck } = this.props

    preCheck = preCheck ? true : ''

    const mergedAdditionalTermsDetail = additionalTermsDetail
      .replace(/{{partnerBrandNames}}/g, partnerBrandNames)
      .replace(/{{hostBrandName}}/g, hostBrandName)

    const terms = perBrandTerms ? (
      <span>
        I agree to the
        {' '}
        <a href={termsLink} target="_blank">
          Terms and Conditions
        </a>
        . By entering the sweepstakes, I agree to the sweepstakes'
        {' '}
        <a href={termsLink} target="_blank">
          Official Rules.
        </a>
      </span>
    ) : (
      <span>
        <a href={termsLink} target="_blank">
          {tosLinkLabel}
          *
        </a>
        <div
          className={css.additionalTerms}
          dangerouslySetInnerHTML={{ __html: mergedAdditionalTermsDetail }}
        />
      </span>
    )
    return (
      <div>
        <div id="terms" className={css.terms}>
          <div
            className={cn(css.checkboxError, 'check-box-error-message')}
            style={{ display: 'none' }}
          >
            You cannot submit an entry online without checking all boxes, for instructions on how to
            enter offline, see the
            {' '}
            <a href={termsLink} target="_blank">
              Official Rules.
            </a>
          </div>
          <div>
            {this.renderCheckBox({
              name: 'email_opt_in',
              label: terms,
              preCheck,
            })}
            <ErrorMsg />
          </div>
          {perBrandTerms && this.renderPerBrandTerms(preCheck)}
        </div>
        {campaignId === 2589 && this.renderReiCampaign(preCheck)}
      </div>
    )
  }
}

export default injectSheet(styles)(Optin)
