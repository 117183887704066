import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { P } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

import colors from 'util/colours'
import ClickAwayListener from 'util/components/click-away-listener'

const styles = {
  wrapper: {
    userSelect: 'none',
    border: `1px solid ${colors.silver}`,
    minWidth: 280,
    maxWidth: 384,
    position: ({ placement }) => (placement ? 'fixed' : 'absolute'),
    top: ({ placement }) => (placement ? `calc(${placement.top}px + 14px)` : 'calc(100% + 2px)'),
    left: ({ placement }) => (placement ? placement.left : 0),
    padding: '16px 12px 8px 16px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    backgroundColor: 'white',
    zIndex: 20,
    cursor: 'default',
    '& > p': {
      marginBottom: 4,
      '&:first-child': {
        marginBottom: 8,
      },
    },
  },
  buttons: {
    float: 'right',
    '& > button:first-child': {
      marginRight: 4,
    },
  },
}

function SMSEditorGenericModal(props) {
  const {
    classes: css,
    show,
    title,
    content,
    onConfirm,
    onCancel,
    cancelLabel,
    confirmLabel,
    placement,
  } = props
  const [div, setDiv] = useState(null)

  useEffect(() => {
    if (!placement) return () => {}

    const container = document.getElementById('sms-editor-wrapper')

    const tmpDiv = document.createElement('div')
    tmpDiv.style = 'position: relative;'

    setDiv(tmpDiv)
    if (!div) container.appendChild(tmpDiv)

    return () => {
      if (div) div.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [div])

  if (!show) return null

  // TODO: CHANGE THIS
  const modal = (
    <ClickAwayListener onClickAway={onCancel}>
      <div contentEditable={false} className={css.wrapper}>
        <P>
          <strong>{title}</strong>
        </P>
        {React.isValidElement(content) ? content : <P multiline>{content}</P>}
        {onCancel && onConfirm && (
          <div className={css.buttons}>
            <Button
              onClick={onCancel}
              label={cancelLabel}
              theme={ButtonTheme}
              primary
            />
            <Button
              onClick={onConfirm}
              label={confirmLabel}
              theme={ButtonTheme}
              primary
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  )

  if (div) return ReactDOM.createPortal(modal, div)

  return modal
}

SMSEditorGenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  placement: PropTypes.object,
}

SMSEditorGenericModal.defaultProps = {
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  onConfirm: null,
  onCancel: null,
  placement: null,
}

export default React.memo(injectCSS(styles)(SMSEditorGenericModal))
