import React, { PureComponent as Component } from 'react'
import { DateRangePicker } from 'react-dates'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import Label from 'util/components/label'

class DateRangePickerWrapper extends Component {
  render() {
    const {
      onDatesChange,
      onFocusChange,
      focusedInput,
      startDate,
      endDate,
      startDatePlaceholderText,
      endDatePlaceholderText,
      isOutsideRange,
      customLeftInputIcon,
      customRightInputIcon,
      openDirection,
      displayFormat,
      label,
      tooltip,
      disabled,
    } = this.props

    return (
      <div style={{ marginTop: '-40px' }}>
        { label
          && (
          <div style={{ marginTop: '44px', marginBottom: '-39px' }}>
            <Label
              label={label}
              tooltip={tooltip}
            />
          </div>
          )
        }
        <div
          id="verticalLine"
          style={{
            height: '40px',
            width: '1px',
            borderLeft: '1px solid #dee4e5',
            position: 'relative',
            top: '40px',
            left: '276px',
          }}
        />
        <DateRangePicker
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
          isOutsideRange={isOutsideRange}
          customInputIcon={customLeftInputIcon}
          customArrowIcon={customRightInputIcon}
          openDirection={openDirection}
          displayFormat={displayFormat}
          disabled={disabled}
        />
      </div>
    )
  }
}

const defaultProps = {
  startDatePlaceholderText: 'Start Date',
  endDatePlaceholderText: 'End Date',
  customLeftInputIcon: null,
  customRightInputIcon: null,
  openDirection: 'down',
  displayFormat: () => moment.localeData().longDateFormat('L'),
  label: null,
  tooltip: null,
  disabled: false,
}

DateRangePickerWrapper.defaultProps = defaultProps

DateRangePickerWrapper.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  focusedInput: PropTypes.any.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  isOutsideRange: PropTypes.func.isRequired,
  customLeftInputIcon: PropTypes.element,
  customRightInputIcon: PropTypes.element,
  openDirection: PropTypes.string,
  displayFormat: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
}

export default DateRangePickerWrapper
