import React from 'react'
import PropTypes from 'prop-types'
import {
  H1, H2, H3, H6, Small, Tiny,
} from 'visual-components/util/texts'
import injectSheet from 'react-jss'
import { Card } from 'react-toolbox/lib/card'
import { round } from 'lodash'
import { Link } from 'react-router-dom'

const styles = {
  container: {
    marginBottom: 16,
  },
  innerContainer: {
    padding: '16px 20px',
  },
  title: {
    marginBottom: 16,
  },
  statRow: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 20,
  },
  stat: {
    textAlign: 'center',
    width: 100,
  },
  statValue: {
    marginBottom: 2,
  },
}

const SmsMarketing = props => {
  const {
    subscribers,
    messages,
    clicks,
    campaignId,
    classes: css,
  } = props

  return (
    <div className={css.container}>
      <Card
        style={{
          width: '100%',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
        }}
      >
        <div className={css.innerContainer}>
          <div className={css.title}>
            <H3 coral>
              <small>
                SMS Marketing
              </small>
            </H3>
          </div>
          <div className={css.statRow}>
            <H1 azure>
              {subscribers || '-'}
            </H1>
            <div style={{ width: 4, height: 1 }} />
            <Small>
              sms signups
            </Small>
          </div>
          <div className={css.statRow}>
            <div className={css.stat}>
              <div className={css.statValue}>
                <H2>{messages || '-'}</H2>
              </div>
              <Tiny>messages Sent</Tiny>
            </div>
            <div className={css.stat}>
              <div className={css.statValue}>
                <H2>{clicks || '-'}</H2>
              </div>
              <Tiny>clicks</Tiny>
            </div>
            <div className={css.stat}>
              <div className={css.statValue}>
                <H2>
                  {
                    clicks
                      ? messages
                        ? `${round(clicks / messages * 100, 2)}%`
                        : 0
                      : '-'
                  }
                </H2>
              </div>
              <Tiny>CTR</Tiny>
            </div>
          </div>
          <Link to={`/analytics/campaigns/${campaignId}`}>
            <H6 azure>
              See full analytics
            </H6>
          </Link>
        </div>
      </Card>
    </div>
  )
}

SmsMarketing.propTypes = {
  campaignId: PropTypes.number.isRequired,
  subscribers: PropTypes.number.isRequired,
  messages: PropTypes.number.isRequired,
  clicks: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
}

export default injectSheet(styles)(SmsMarketing)
