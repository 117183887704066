import React, { PureComponent } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Menu, MenuItem, MenuDivider } from 'react-toolbox/lib/menu'
import { Small } from 'visual-components/util/texts'
import * as MetricsActions from 'actions/analytics-actions'
import BrandProfileLi from './brand-profile-li'
import NavBarDropdownTheme from 'css/themes/nav-bar-dropdown.css'

const themes = {
  NavBarDropdown: NavBarDropdownTheme,
}

const stateToProps = ({ brand, currentBrand, profile }) => ({ brand, currentBrand, profile })

const dispatchToProps = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

class BrandDropdownMenu extends PureComponent {
  static propTypes = {
    handleMenuHide: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    currentBrand: PropTypes.object.isRequired,
    brandName: PropTypes.string.isRequired,
    brandLogo: PropTypes.string.isRequired,
    profileHref: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
  }

  logout = () => {
    sessionStorage.setItem('extendedTrialModalShown', false)

    if (window.gapi && window.gapi.auth2) {
      const auth2 = window.gapi.auth2.getAuthInstance()
      if (auth2 && auth2.signOut) auth2.signOut().then(() => console.log('User signed out.'))
    }

    setTimeout(() => {
      window.location = '/logout'
    }, 500)
  }

  trackROIClick = () => {
    const { metricsActions, location, handleMenuHide } = this.props

    metricsActions.create('ROINavigated', {
      meta: {
        from: location.pathname,
        through: 'profileDropdown',
      },
    })

    return handleMenuHide()
  }

  generateBrandMenuItem = ({ brand }) => (
    <Link
      key={brand.id}
      to={`/?brandId=${brand.id}`}
      className={themes.NavBarDropdown.currentBrandContainer}
      target="_blank"
    >
      <div className={themes.NavBarDropdown.currentBrandLogoWrapper}>
        <img
          src={brand.logo}
          className={themes.NavBarDropdown.brandLogo}
          alt={brand.accountname}
        />
      </div>
      <div className={themes.NavBarDropdown.brandName}>
        <Small><i>{brand.accountname}</i></Small>
      </div>
    </Link>
  )

  render() {
    const {
      brandName,
      brandLogo,
      profileHref,
      active,
      handleMenuHide,
      currentBrand,
      profile: { mostRecentBrands },
    } = this.props

    const recentBrands = mostRecentBrands
      ? mostRecentBrands.slice(1, 4).map(this.generateBrandMenuItem)
      : []

    return (
      <Menu
        position="topRight"
        active={active}
        onHide={handleMenuHide}
        theme={themes.NavBarDropdown}
      >
        <BrandProfileLi
          brandName={brandName}
          brandLogo={brandLogo}
          profileHref={profileHref}
          handleMenuHide={handleMenuHide}
        />
        {/* This will hide in mobile */}
        <li className="mobile-navbar-menu-items">
          <Link to={`/explore/brands/${currentBrand.id}`}>
            <MenuItem
              value="profile"
              caption="Brand Profile"
              onClick={handleMenuHide}
              theme={themes.NavBarDropdown}
            />
          </Link>
          <Link to="/profile/brand/">
            <MenuItem
              value="brandSettings"
              caption="Account Settings"
              onClick={handleMenuHide}
              theme={themes.NavBarDropdown}
            />
          </Link>
          <Link to="/reviews">
            <MenuItem
              value="reviews"
              caption="Reviews"
              onClick={handleMenuHide}
              theme={themes.NavBarDropdown}
            />
          </Link>
          <Link to="/profile/brand/referrals">
            <MenuItem
              value="referrals"
              caption="Refer a Brand & Get $25"
              onClick={handleMenuHide}
              className={themes.NavBarDropdown.referralLink}
              theme={themes.NavBarDropdown}
            />
          </Link>
        </li>

        {recentBrands}
        {
          recentBrands.length > 2 ? (
            <a href="/app/profile/switch-brand">
              <MenuItem
                value="switchBrands"
                caption="Manage Brands"
                onClick={handleMenuHide}
                theme={themes.NavBarDropdown}
              />
            </a>
          ) : (
            <a href="/app/add-brand">
              <MenuItem
                value="createNewBrand"
                caption="Create New Brand"
                onClick={handleMenuHide}
                theme={themes.NavBarDropdown}
              />
            </a>
          )
        }
        <MenuDivider theme={themes.NavBarDropdown} />
        <Link to="/profile/user">
          <MenuItem
            value="userSettings"
            caption="Personal Settings"
            onClick={handleMenuHide}
            theme={themes.NavBarDropdown}
          />
        </Link>
        <MenuItem
          value="signout"
          caption="Log Out"
          className={themes.NavBarDropdown.logoutButton}
          theme={themes.NavBarDropdown}
          onClick={this.logout}
        />
      </Menu>
    )
  }
}

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps
  )(BrandDropdownMenu)
)
