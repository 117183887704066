import React from 'react'
import { string, bool, object } from 'prop-types'
import moment from 'moment'
import { H3 } from 'visual-components/util/texts'
import { Form, Input } from 'visual-components/util/form'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import IntegrationTheme from 'css/themes/integrations.css'

class IntegrationConnect extends React.Component {
  static propTypes = {
    isBrand: bool,
    currentBrand: object,
    currentCampaign: object,
    integration: object.isRequired,
    espIntegration: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  static defaultProps = {
    isBrand: false,
    currentBrand: null,
    currentCampaign: null,
  }

  state = {}

  renderInputs = () => {
    const {
      isBrand,
      currentBrand,
      currentCampaign,
      integration: { connectInputs, validations },
    } = this.props

    const model = isBrand ? currentBrand : currentCampaign
    const activeIntegrations = (model.integrations
      && Object.values(model.integrations).filter(integration =>
        integration.activated && integration.integration_type !== 'custom')) || []

    return connectInputs.map(input => {
      if (activeIntegrations.length && input.attr === 'rateLimit') {
        delete validations.rateLimit
        return null
      }

      return (
        <Input
          key={input.attr}
          name={input.attr}
          type={input.type}
          label={input.label}
          sublabel={input.sublabel}
          required={!input.optional}
          validations={input.validations}
        />
      )
    })
  }

  renderModalButtonGroup = () => {
    const {
      currentBrand,
      currentCampaign,
      integration: { oauth },
      utilityFunctions: { integrate, closeModal },
    } = this.props

    let invite
    let inviteId

    if (oauth) {
      invite = currentCampaign
        && currentCampaign.invites
        && currentCampaign.invites.find(i => i.invitee_id === currentBrand.id)
      inviteId = invite ? invite.id : null

      return (
        <ModalButtonGroup
          confirm={() => integrate(inviteId)}
          confirmText="Integrate"
          cancel={closeModal}
          canSave
        />
      )
    }

    return (
      <ModalButtonGroupWithContext
        confirmText="Integrate"
        cancel={closeModal}
        canSave
      />
    )
  }

  onUpdate = ({ ...rest }) => {
    this.setState(rest)
  }

  onSubmit = () => {
    const {
      utilityFunctions: { goTo, updateIntegration },
    } = this.props

    updateIntegration(this.state)

    const lightboxEspKey = 'lightbox_esp_setup'
    // check if user came from lightbox esp setup
    const lightboxEsp = localStorage.getItem(lightboxEspKey)
    if (lightboxEsp) {
      const item = JSON.parse(lightboxEsp)
      // remove the lightbox esp setup cache
      localStorage.removeItem(lightboxEspKey)
      if (moment().isBefore(moment(item.expiry))) {
        // if the lightbox esp setup is not expired, redirect back to lightbox esp
        window.location.href = `/app/signup-form/${item.value}/settings/integrations?configEsp=true`
      }
    } else {
      goTo('details')
    }
  }

  render() {
    const {
      integration: {
        img,
        label,
        instructions,
        connectInputs,
      },
      espIntegration,
      utilityFunctions: { updateIntegration },
    } = this.props

    let imgName
    let extension
    let srcSet
    if (img) {
      [imgName, extension] = img.split('.')
      srcSet = imgName && extension && `
        ${imgName}-modal.${extension},
        ${imgName}-modal@2x.${extension} 2x,
        ${imgName}-modal@3x.${extension} 3x
      `
    }

    return (
      <div>
        <div className={IntegrationTheme.integrationModal}>
          <div className={IntegrationTheme.modalTitle}>
            <H3>
              {`Integrate with ${label}`}
            </H3>
          </div>

          <img
            src={img}
            srcSet={srcSet}
            className={IntegrationTheme.modalLogo}
          />

          {instructions && instructions()}

          <Form
            model={this.state}
            updateModel={this.onUpdate}
            handleSubmit={this.onSubmit}
          >
            {connectInputs && this.renderInputs()}

            {this.renderModalButtonGroup()}
          </Form>
        </div>
      </div>
    )
  }
}

export default IntegrationConnect
