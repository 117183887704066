import React from 'react';

import PrimaryButton from 'util/components/buttons/primary-button';
import COLOURS from 'util/colours';
import SplashTheme from 'css/themes/splash.css'

class BigSplash extends React.Component {
  render() {
    return(
      <div>
        <div className={ SplashTheme.hidden1280 }>
          <div style={{
            height: '524px',
            background: COLOURS.cerulean
          }} />
          <div style={{
            height: '140px',
            background: COLOURS.seaGreen
          }} />
          <div style={{
            height: '1318px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '140px',
            background: COLOURS.azure
          }} />
          <div style={{
            height: '1300px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '140px',
            background: COLOURS.blues.one
          }} />
          <div style={{
            height: '584px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '140px',
            background: COLOURS.coral
          }} />
          <div style={{
            height: '1175px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '457px',
            background: COLOURS.cerulean
          }} />
        </div>
        
        <div style={{
          maxWidth: '1280px',
          marginRight: 'auto',
          position: 'absolute',
          marginLeft: 'auto',
          top: '0',
          left: '0',
          right: '0'
        }}>
          <div className={ SplashTheme.hiddenUnder910 }>
            <img src='/images/splash-page/Landing-Page-Final.png'
              style={{
                width: '100%',
                marginBottom: '-50px'
              }}/>
            <a href="/app"
              style={{
                left: '50%',
                bottom: '0',
                position: 'absolute'
            }}>
              <PrimaryButton greenButton={ true }
                styles={[{
                  minHeight: '80px',
                  height: '80px',
                  width: '280px',
                  minWidth: '280px',
                  fontSize: '30px',
                  marginTop: '-11%',
                  marginLeft: '-140px'
              }]}>
                Check it out
              </PrimaryButton>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default BigSplash;
