import React, { PureComponent } from 'react'
import injectCSS from 'react-jss'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import COLOURS from 'util/colours'
import _ from 'lodash'

const newStyles = {
  tooltip: {
    marginLeft: 4,
    verticalAlign: 'middle',
    '& + .tooltip': {
      opacity: 1,
      '& .tooltip-arrow': {
        visibility: 'hidden',
      },
      '& .tooltip-inner': {
        backgroundColor: COLOURS.stone,
        color: 'white',
        fontSize: 12,
        lineHeight: '20px',
      },
    },
  },
}

class Tooltip extends PureComponent {
  static propTypes = {
    position: PropTypes.string,
    html: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    removeTitle: PropTypes.bool,
    styles: PropTypes.object,
    title: PropTypes.string.isRequired,
    useNew: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
  }

  static defaultProps = {
    position: 'bottom',
    html: false,
    removeTitle: false,
    styles: {},
    useNew: false,
    children: null,
  }

  componentDidMount() {
    const { position, html } = this.props

    $(ReactDOM.findDOMNode(this)).tooltip({
      placement: position,
      html,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { title } = nextProps

    if (title) {
      $(ReactDOM.findDOMNode(this)).attr('data-original-title', title)
    }
  }

  render() {
    const {
      styles,
      title,
      removeTitle,
      useNew,
      classes: css,
      children,
    } = this.props

    const defaultStyles = {
      color: COLOURS.blues.three,
      fontSize: '14px',
    }

    const style = _.extend({}, defaultStyles, styles)
    const className = 'fa fa-question-circle fa-6'

    if (children || useNew) {
      return (
        <i title={title} className={css.tooltip}>
          {
            children || (
            <svg
              style={style}
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <g fill="none" fillRule="evenodd">
                <circle cx="6" cy="6" r="6" fill="#f4f9fc" />
                <path fill="#03a9f4" d="M5 5H7V10H5z" />
                <circle cx="6" cy="3" r="1" fill="#03a9f4" />
              </g>
            </svg>
            )
          }
        </i>
      )
    }

    return (
      <i
        className={className}
        style={style}
        title={removeTitle ? '' : title}
        aria-hidden="true"
      />
    )
  }
}

export default injectCSS(newStyles)(Tooltip)
