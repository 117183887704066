import _ from 'lodash'

const initialState = {
  campaignEmails: null,
  marketingEmails: null,
  messageEmails: null,
  loading: false,
  error: null,
  identifier: 'id',
}

export default function brandCredentialsReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_NOTIFICATION_SETTINGS':
    case 'CHANGE_NOTIFICATION_SETTINGS':
      return {
        ...state,
        loading: true,
      }

    case 'LOAD_NOTIFICATION_SETTINGS_SUCCESS':
      return {
        ...state,
        campaignEmails: action.payload.campaign_emails,
        marketingEmails: action.payload.marketing_emails,
        messageEmails: action.payload.message_emails,
        loading: false,
      }

    case 'CHANGE_NOTIFICATION_SETTINGS_SUCCESS': {
      toastr.success('Email notifications updated successfully.', null, {
        positionClass: 'toast-bottom-center',
      })

      return {
        ...state,
        campaignEmails: action.payload.campaign_emails,
        marketingEmails: action.payload.marketing_emails,
        messageEmails: action.payload.message_emails,
        loading: false,
      }
    }

    case 'LOAD_NOTIFICATION_SETTINGS_FAILURE':
    case 'CHANGE_NOTIFICATION_SETTING_FAILURES': {
      toastr.error(
        'Email notifications update failed. Please try again.',
        null,
        {
          positionClass: 'toast-bottom-center',
        }
      )

      const { status, data } = action.payload.response

      return {
        ...state,
        loading: false,
        error: action.payload.response ? `${status}: ${data}` : 'Error',
      }
    }

    case 'CREDENTIAL_REMOVE_ITEM_BY_ID': {
      return {
        ...state,
        items: state.items.filter(item => item.uid !== action.data.id),
      }
    }

    case 'CREDENTIAL_UPDATE_ITEM': {
      const data_update = { ...state }
      const search_update = {}
      search_update[state.identifier] = action.data.id
      const item = _.find(data_update.items, search_update)
      _.merge(item, action.data)
      return data_update
    }

    case 'CREDENTIAL_UPDATE_ATTR':
      return _.merge({}, state, action.data)

    case 'LOAD_SUCCESS':
      if (action.model === 'brandCredentials') {
        return {
          ...state,
          items: action.data
        }
      }
      return state

    default:
      return state
  }
}
