import React, { Component } from 'react'
import { number, func, bool } from 'prop-types'

class InfiniteScroll extends Component {
  static propTypes = {
    whenAtBottom: func.isRequired,
    enabled: bool,
    buffer: number,
    useBoundingRect: bool,
  }

  static defaultProps = {
    enabled: true,
    buffer: 0,
    height: 0,
    useBoundingRect: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.setSize()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setSize = () => {
    const { height, top } = this.containerDiv.getBoundingClientRect()
    this.setState({ height })
  }

  handleScroll = () => {
    this.setSize()
    const { buffer, enabled, whenAtBottom, useBoundingRect } = this.props

    // cross-browser calculation for window height
    const windowHeight = 'innerHeight' in window
      ? window.innerHeight : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement

    const docHeight = useBoundingRect
      ? this.state.height
      // cross-browser calculation for document height
      : Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,
          html.scrollHeight, html.offsetHeight)

    // add in 50 pixels plus user-defined buffer
    const windowBottom = windowHeight + window.pageYOffset + 50 + buffer

    if (windowBottom >= docHeight && enabled) {
      whenAtBottom()
    }
  }

  captureContainerDiv = el => this.containerDiv = el

  render() {
    return (
      <div ref={this.captureContainerDiv}>
        {this.props.children}
      </div>
    )
  }
}

export default InfiniteScroll
