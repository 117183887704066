import React from 'react';
import COLOURS from 'util/colours';

class BonusEntryStatus extends React.Component{

  render () {
    let colStyles = {
      paddingLeft: '0',
      paddingRight: '0'
    }

    let rowStyles = {
      marginLeft: '0',
      marginRight: '0'
    }

    return (
      <div className="earn-more">
        <div style={{
          backgroundColor: 'rgba(0,0,0,.03)',
          marginLeft: '-15px',
          marginRight: '-15px',
          marginTop: '0px',
          paddingTop: '15px',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: COLOURS.darkGreen.point1,
          borderTopWidth: this.props.isButton ? '1px' : null,
          borderTopStyle: this.props.isButton ? 'solid' : null,
          borderTopColor: this.props.isButton ? COLOURS.darkGreen.point1 : null
        }}>
          <div className="row" style={ rowStyles }>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" style={ colStyles }>
              <p style={{
                margin: '0',
                fontSize: '12px',
                fontFamily: 'sans-serif',
                color: '#384645'
              }}>
                <span className="bonus-scored">
                  4
                </span>
                <span>
                /
                </span>
                <span className="bonus-total">
                  35
                </span>
              </p>
            </div>
          </div>
          <div className="row text-center" style={ rowStyles }>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={ colStyles }>
              <p style={{
                fontSize: '11px',
                marginBottom: '15px',
                fontFamily: 'sans-serif',
                color: '#384645'
              }}>
                ENTRIES
              </p>
            </div>
          </div>
        </div>
        <div style={{
          marginLeft: '-15px',
          marginRight: '-15px',
          marginTop: '-15px',
          paddingTop: '15px'
        }}>
          <div className="row" style={ rowStyles }>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" style={ colStyles }>
              <div style={{
                marginLeft: 'calc(50% - 150px)'
              }}>
                <div style={{
                  height: '90px',
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  width: '300px'
                }}>
                  <p style={{
                    margin: '0',
                    fontFamily: 'sans-serif',
                    color: '#384645'
                  }}>
                    Earn more entries by completing these actions (excluding Facebook and Instagram)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default BonusEntryStatus;
