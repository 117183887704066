/*
  key: {
    url: pdf url,
    title: case study title,
    description: case study description. choosen from solution section,
    image: case study image,
          /images/login/resources/library-imgs/case-studies/
  }
*/

export default {
  earos: {
    url:
      'https://dojo-datablog.s3-us-west-2.amazonaws.com/wp-content/uploads/2020/10/081646509/SMS-CaseStudy-September2020-EAROS.pdf',
    title: 'Growing Leads by 5x with SMS Marketing',
    description: `EAROS creates high-fidelity hearing protection products that provide both decibel
    reduction and a superior listening experience. EAROS, like many emerging brands, wanted to acquire
    new leads. EAROS’ Ready for Summer giveaway was a two-month program with six complementary brands.
    During the giveaway, EAROS acquired 4K+ new email subscribers and over 700 SMS subscribers.
    The company also achieved a 4.5x ROAS.`,
  },
  'dojo-mojo': {
    url:
      'https://dojo-datablog.s3-us-west-2.amazonaws.com/wp-content/uploads/2020/04/09103907/SMS-Case-Study-DojoMojo_4_2020.pdf',
    title: 'Powerful Insights into SMS Marketing',
    description: 'The  first  end-to-end  mobile  marketing  solution  that  combines  the  power  of  DojoMojo’s  partnership  network  for  acquiring  audience  with our newly launched SMS marketing suite.',
  },
  caraway: {
    url: 'https://dojo-datablog.s3-us-west-2.amazonaws.com/wp-content/uploads/2019/12/24223505/DojoMojo_Case_Study-Caraway_12-2019.pdf',
    title: 'Audience Growth Before Product Launch',
    description: `Caraway’s strategy for email acquisition on DojoMojo was two-fold. Firstly, Caraway
    positioned themselves as a Prizing Partner on the platform. Prizing partners are
    responsible for providing the bulk of the prize of a sweepstakes, without promoting the
    sweepstakes to an existing audience. Given that Caraway did not have a list to promote
    to, this option allowed them to acquire emails on DojoMojo while solely providing
    prizing for the sweepstakes they participated in, eliminating the need for an existing
    audience promotion.`,
  },
  'wine-awesomeness': {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24213936/wine-awesomeness.pdf',
    title: 'Utilizing Social Growth to Grow Your Audience',
    description: `The Social Growth feature on DojoMojo takes the difficulty and challenge out of building
      a brand’s digital audience. With Social Growth, brands incentivize sweepstakes entrants
      to perform actions on Facebook, Twitter, Instagram, Pinterest, YouTube, Snapchat or by
      downloading an app in exchange for additional entries to increase their chances of
      winning.`,
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223505/wine-awesomeness-case-study-card.png',
  },
  'conde-nast': {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24213914/conde-nast.pdf',
    title: 'Growing Media Audiences at Scale',
    description: `Joel Pavelski, Director of Audience Development & Social at GQ Magazine, appreciates
      how DojoMojo is able to save him time throughout the campaign process, saying
      DojoMojo “makes every step of the process for setting up a sweeps really simple - from
      finding partners and negotiating on the promotion to setting minimum entry levels,
      dealing with the legal jargon, and setting up campaign pages.”`,
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223452/conde-nast-case-study-card.png',
  },
  're-bloom': {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2018/12/21163456/DojoMojo_Case_Study-reBloom_11-2018-v2.pdf',
    title: 'Turning DojoMojo Emails to Revenue',
    description: '',
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223458/re-bloom-case-study-card.png',
  },
  wanderfull: {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24213932/wanderfull.pdf',
    title: 'The Network Effect',
    description: `Wanderfull posted an Opportunity on DojoMojo looking to sell dedicated emails. In
      their post, Wanderfull shared that they'd be a greate fit for companies in the travel, wellness
      and beauty industries with a female-centric brand looking to leverage Wanderfull's 250,000
      person list to reach a new audience. They tried to include as much information as possible
      about their audience and their ideal partner so that they could attract the right brands to work with.`,
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223503/wanderfull-case-study-card.png',
  },
  rebloom: {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24213927/rebloom.pdf',
    title: 'The Power of Hosting',
    description: `The power of hosting also allowed reBloom to work with larger brands. By shouldering
      the responsibility of planning the details of giveaway campaigns, details like finding
      partners, securing prizing, and designing creative, reBloom was able to work with larger
      partners, since those larger brands were guaranteed an easy way to acquire emails,
      without any work on their part. Another facet of hosting that enabled reBloom to work
      with larger brands were the Custom CTA and Social Sharing features. Since hosts are
      allowed to share access to those powerful features, reBloom could offer them as a
      carrot to entice larger brands to join their giveaway, thus ensuring reBloom would
      receive a much higher number of email opt-ins per campaign.`,
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223501/reBloom-case-study-card.png',
  },
  crush: {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24213923/crush.pdf',
    title: 'Growing Your Email List with Giveaways',
    description: `Giveaways offered several advantages over other growth strategies. By targeting
      partners in the travel and media industries, Crush was able to achieve growth and
      acquire the emails of people they knew would be a natural fit for the type of content
      they were producing. Most importantly, giveaways offered a high ROI compared to other
      solutions, as the only investment needed was a subscription to DojoMojo`,
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223456/crush-case-study-card.png',
  },
  1440: {
    url: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24213908/1440.pdf',
    title: 'Why Brands Turn to DojoMojo Elite',
    description: '',
    image: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24223450/1440-case-study-card.png',
  },

}
