import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { H3 } from 'visual-components/util/texts'
import UrlEditor from 'visual-components/util/UrlEditor'

const styles = {
  container: {
    border: '1px solid #b7c3cd',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    position: 'relative',
  },
  successIcon: {
    height: '30px',
    width: '30px',
    display: 'inline-block',
  },
  successIconContainer: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
}


function PageHostingChoice(props) {
  const {
    type,
    title,
    url,
    renderSuccess,
    onEdit,
    classes,
  } = props

  function onEditClick() {
    onEdit(type)
  }

  function renderSuccessIcon() {
    return (
      <div className={classes.successIcon}>
        <img
          src="/images/Success_Icon.svg"
          style={{
            width: '100%',
          }}
        />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {renderSuccess ? (
        <div className={classes.successIconContainer}>{renderSuccessIcon()}</div>
      ) : null}
      <div className={classes.textContainer}>
        <H3 multiline>
          <small>{title}</small>
        </H3>
        {url ? <UrlEditor onEdit={onEditClick} header="Giveaway URL" url={url} /> : null}
      </div>
    </div>
  )
}

PageHostingChoice.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  renderSuccess: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
}

PageHostingChoice.defaultProps = {
  url: '',
  renderSuccess: true,
}

export default injectSheet(styles)(PageHostingChoice)
