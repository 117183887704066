import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Dialog from 'react-toolbox/lib/dialog';

import * as partnershipApplicationActions from 'actions/partnership-application-actions';
import { H4 } from 'visual-components/util/texts';
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext';
import dialogTheme from 'css/themes/dialogs/formModal.css';

import StepOne from './apply-to-campaign-step-one';
import StepTwo from './apply-to-campaign-step-two';
import StepThree from './apply-to-campaign-step-three';
import { Form } from 'visual-components/util/form'
import d3 from 'd3'
export class ApplyToCampaignModal extends Component {
  sendApplication = () => {
    const {
      applicationData,
      socialReach,
      partnerRating,
      expectedEntries,
      industries,
      campaign,
      actions: { submitApplication }
    } = this.props;

    const data = {
      ...applicationData,
      pastPartners: applicationData.pastPartners.filter(x => typeof x === 'string' && x.length),
      campaign,
      socialReach,
      partnerRating,
      expectedEntries,
      industries,
    };

    submitApplication(data);
  };

  componentWillReceiveProps(nextProps) {
  }

  advance = () => {
    const {
      actions,
      step,
    } = this.props;

    const stepMap = {
      1: actions.submitStepOne,
      2: actions.submitStepTwo,
      3: this.sendApplication
    }

    return stepMap[step]()
  }

  canProceed = (data) => {
    const {
      actions,
      step,
    } = this.props;

    const {
      listSize,
      greatFitMessage,
      entryContribution,
      prizingContribution,
    } = data.applicationData
    const stepMap = {
      1: () => {
        return (
          !!listSize && listSize >= 0 && !!greatFitMessage && greatFitMessage.length > 0
        )
      },
      2: () => {
        return (
          !!entryContribution &&
          !!prizingContribution
        )
      },
      3: () => {
        return true
      }
    }

    return stepMap[step]()
  }

  render() {
    const {
      active,
      actions,
      applicationData,
      brand,
      step,
      datePickerOpen,
      socialReach,
      pastPartners,
      partnerRating,
      expectedEntries,
      campaign,
      industries
    } = this.props;
    return (
      <Dialog
        theme={dialogTheme}
        active={active}
        onEscKeyDown={actions.closeModal}
        onOverlayClick={datePickerOpen ? f => f : actions.closeModal}
        title={ step === 3 ? "Legal Details (Optional)" : "Apply to Join" }
      >
        <img
          onClick={ actions.closeModal }
          className={ dialogTheme.closeImage }
          src="/images/icons/close.svg"
          />
        <Form
          modelName="partnershipApplication"
          updateModel={actions.updateData}
          handleSubmit={this.advance}
          mergeFieldsImmediately={true}
        >
          {step === 1 ? (
            <StepOne
              applicationData={applicationData}
              updateData={actions.updateData}
              socialReach={socialReach}
              partnerRating={partnerRating}
              pastPartners={pastPartners}
              expectedEntries={expectedEntries}
              industries={industries}
            />
          ) : step === 2 ? (
            <StepTwo
              campaign={campaign}
              applicationData={applicationData}
              updateData={actions.updateData}
              datePickerOpen={datePickerOpen}
              toggleDatePicker={actions.toggleDatePicker}
            />
          ) :
            <StepThree
              brand={brand}
              applicationData={applicationData.legalDetails}
              updateData={actions.updateData}
            />}
            <ModalButtonGroupWithContext
              cancel={ actions.closeModal }
              canSave={this.canProceed}
              cancelText="Cancel"
              confirmText={
                step === 3 ? 'Send Application' : 'Next'
              }
            />
        </Form>
      </Dialog>
    );
  }
}

const getExpectedEntries = state => {
  const { averageSignUpsPerCampaign, dedicatedListSize } = state.currentBrand
  return Math.floor(averageSignUpsPerCampaign || (dedicatedListSize * 0.01))
}

const mapStateToProps = state => ({
  ...state.partnershipApplication,
  brand: state.currentBrand,
  socialReach: state.currentBrand.socialMediaImpressions,
  industries: _.compact(
    [0, 1, 2].map(n => _.get(state.currentBrand, `brand_tag_${n}`))
  ),
  pastPartners: _(state.pastCampaigns.campaigns)
    .map(campaign =>
      Object.values(campaign.partners)
        .map(partner => partner.accountname)
        .concat([campaign.hostBrand.accountname])
    )
    .flatten()
    .sort()
    .uniq()
    .filter(name => name !== state.currentBrand.accountname)
    .compact()
    .value(),
  partnerRating: state.currentBrand.partnerRating || 0,
  expectedEntries: getExpectedEntries(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(partnershipApplicationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ApplyToCampaignModal
);
