import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import COLOURS from 'util/colours'
import { H4, Small } from 'visual-components/util/texts'
import buttonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import smsTheme from 'css/themes/sms-marketing.css'
import cn from 'classnames'
import validator from 'validator'

const styles = {
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    '& > :nth-child(2)': {
      margin: '16px 0 8px',
    },
  },
  phoneBorder: {
    width: 420,
    minHeight: 460,
    border: '1px solid #e0e0e0',
    borderRadius: 32,
    padding: '40px 46px',
    display: 'flex',
    flexDirection: 'column',
  },
  messageContainer: {
    backgroundColor: COLOURS.foggy,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
    width: 240,
    padding: '8px 12px',
    wordWrap: 'break-word',
  },
  img: {
    overflow: 'hidden',
    maxWidth: 200,
    maxHeight: 200,
    borderRadius: 16,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      objectFit: 'cover',
      width: '100%',
    },
  },
}

const stateToProps = ({ currentBrand }) => ({ currentBrand })

const AVAILABLE_MERGE_TAGS = ['COUPON_CODE', 'COUPON', 'BRAND_NAME', 'LINK']

const MERGE_TAG_REGEX = new RegExp(`\\*\\|(${AVAILABLE_MERGE_TAGS.join('|')})\\|\\*`)

const sanitizeWebsite = website => {
  if (!website) return 's'
  const [brandWebsite] = website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')
  return brandWebsite
}

const getDummyUrl = website => `https://${sanitizeWebsite(website)}.djmj.io/s0iJ3Nk7`

const replaceTags = props => {
  const {
    currentBrand: { website, accountname },
    message: {
      coupon_code,
      url_link,
      custom_sms_message: msg,
      dummyLink,
    },
  } = props

  const mapping = {
    LINK: url_link ? dummyLink || getDummyUrl(website) : '',
    COUPON_CODE: coupon_code || '',
    COUPON: coupon_code || '',
    BRAND_NAME: accountname,
  }

  return msg
    .split(/\n/)
    .join(' *||* ')
    .split(/\s/)
    .map(textPart => {
      const match = MERGE_TAG_REGEX.exec(textPart)
      if (match) {
        const [tag, tagKey] = match

        return textPart.replace(tag, mapping[tagKey])
      }

      return textPart
    })
    .join(' ')
}

const generateGIPHYAttribution = creator => `Posted using GIPHY | GIF by ${creator}`

// removes attribution when shown in editor/adds attribution when saved
export const processGIPHYAttribution = function processGIPHYAttribution(obj) {
  const { image_url, image_obj } = obj
  let { custom_sms_message: text } = obj

  ;[text] = text.split(`\n\n${generateGIPHYAttribution('')}`)

  if (image_url && validator.isURL(image_url, { require_protocol: true })) {
    const url = new URL(image_url)
    const isGIF = url.pathname.slice(-4) === '.gif'
    if (isGIF) {
      if (image_obj) {
        // if image_obj, then it's local
        const { original = {} } = image_obj
        if (original.user) {
          const { username } = original.user
          text = `${text}\n\n${generateGIPHYAttribution(username)}`
        }
      } else {
        const username = url.searchParams.get('username')
        // if username, then it's been already Uploaded
        if (username) text = `${text}\n\n${generateGIPHYAttribution(username)}`
      }
    }
  }

  return text
}

export const generatePreview = props => {
  const { message } = props
  const { url_link } = message

  const sms_message = processGIPHYAttribution(message)
  const text = replaceTags({
    ...props,
    message: {
      ...props.message,
      custom_sms_message: sms_message,
    },
  })

  return text
    .split(/\s/)
    .map((part, i) => {
      if (part === '*||*') return <br key={`${part}_${i}`.toString()} />

      const key = `${part}_${i}`.toString()
      if (validator.isEmail(part) || validator.isURL(part)) {
        return (
          <a
            key={key}
            href={validator.isEmail(part) ? `mailto:${part}` : (url_link || part)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>{` ${part}`}</span>
          </a>
        )
      }

      return <span key={key}>{` ${part}`}</span>
    })
}

function SMSPreview(props) {
  const {
    classes: css,
    message,
    message: {
      custom_sms_message,
      image_url,
    },
    showTestModal,
    currentBrand,
  } = props

  return (
    <div>
      <div className={css.wrapper}>
        <div className={css.phoneBorder}>
          {image_url && (
            <div className={css.img}>
              <img src={image_url} alt="promo" />
            </div>
          )}
          {Boolean(custom_sms_message.length) && (
            <div className={css.messageContainer}>
              <H4 multiline>
                {generatePreview({ currentBrand, message })}
              </H4>
            </div>
          )}
        </div>
        <Button
          onClick={showTestModal}
          className={cn(
            buttonTheme.button,
            buttonTheme.raised,
            buttonTheme.whiteButtonAzureText,
            smsTheme.whiteButton
          )}
        >
          Send Me a Test
        </Button>
        <Small multiline>
          You won&apos;t be charged for test messages.
        </Small>
      </div>
    </div>
  )
}

SMSPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  showTestModal: PropTypes.func.isRequired,
}

export default React.memo(connect(stateToProps)(injectSheet(styles)(SMSPreview)))
