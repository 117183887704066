import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Col from 'visual-components/util/no-padding-grid/col';

class CampaignPreviewXMore extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var {
      partnerCount
    } = this.props;

    return (
      <Col xs={6}
        style={{
          marginTop: '4px',
          maxWidth: '30px',
          marginLeft: '4px'
      }}>
        <div style={{
          width: '30px',
          height: '30px',
          maxWidth: '30px',
          maxHeight: '30px',
          padding: '2px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: COLOURS.silver,
          display: 'table-cell',
          verticalAlign: 'middle',
          textAlign: 'center'
        }}>
          <p style={{
            fontFamily: 'Larsseit',
            fontSize: '9px',
            lineHeight: '7px',
            color: COLOURS.azure
          }}>
            { `${partnerCount}+` }
          </p>
          <p style={{
            fontFamily: 'Larsseit',
            fontSize: '9px',
            color: COLOURS.azure
          }}>
            more
          </p>
        </div>
      </Col>
    )
  }
};

CampaignPreviewXMore.propTypes = {
  partnerCount: PropTypes.number.isRequired
};

export default CampaignPreviewXMore;
