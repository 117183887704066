import React from 'react';
import DojoModal from 'util/components/dojomodal';
import BrandCardContent from 'components/brands/brand-card-content';
import PrimaryButton from 'util/components/buttons/primary-button';
import SecondaryButton from 'util/components/buttons/secondary-button';
import { H2 } from 'visual-components/util/texts';

class BrandInviteNotification extends React.Component {
  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  accept () {
    this.props.acceptBrandInvite(this.props.item);
  }
  reject () {
    this.props.rejectBrandInvite(this.props.item);
  }

  closeModal () {
    this.props.closeModal();
    $('body').removeClass('modal-open');
  }

  render () {
    let item = this.props.item;
    let copy = LANGUAGE.notifications.brandInvite;
    let text = LANGUAGE.run(copy.text, {
      inviterName: item.inviter.firstName + ' ' + item.inviter.lastName,
      accountname: item.brand.accountname,
      role: item.role
    });

    return (
      <div>
        <DojoModal
          gaName="brandInvite"
          isOpen={ true }
          hideModal={ this.closeModal }
          title={ copy.title }>
          <div className="row">
            <BrandCardContent brand={item.brand} />
            <div className="col-xs-12 col-md-6">
              { text }
            </div>
          </div>
          <div style={{ position: 'absolute', bottom: '10px',right: '30px' }}>
            <PrimaryButton onClick={this.accept}>
              { LANGUAGE.notifications.accept }
            </PrimaryButton>
            <SecondaryButton styles={[{ marginLeft: '15px' }]} onClick={ this.reject }>
              { LANGUAGE.notifications.reject }
            </SecondaryButton>
          </div>
        </DojoModal>
      </div>
    );
  }
};

export default BrandInviteNotification;
