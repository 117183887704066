import React from 'react';
import _ from 'lodash'

import { Card } from 'react-toolbox/lib/card';

import { H3, H5, Small } from 'visual-components/util/texts';

import AnalyticsTheme from 'css/themes/analytics.css';
import EventTooltipCard from 'visual-components/analyticsDashboard/tooltip/event-tooltip-card';
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header';

var TooltipFactory = React.createFactory(EventTooltipCard)

import { renderToString } from 'react-dom/server';
import moment from 'moment-timezone'
class Ball extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.color,
          display: 'inline-block',
          width: '35px',
          height: '35px',
          borderRadius: '35px'
        }}>
      </div>
    )
  }
};

var renderGraphScatter = function (campaigns) {
  var series = [{
    name: 'entries',
    data: []
  }, {
    name: 'new-emails',
    data: []
  }];

  var campaignMap = {};
  _.each(campaigns, function (campaign) {
    campaignMap[campaign.id] = {
      period: `${moment(campaign.startDate).format('LL')} - ${moment(campaign.endDate).format('LL')}`,
      name: campaign.name,
      entries: campaign.entries,
      newEmails: campaign.newEmails
    };

    series[0].data.push({
      x: campaign.startDate.getTime(),
      y: 2,
      z: campaign.entries,
      campaignId: campaign.id
    });
    series[1].data.push({
      x: campaign.startDate.getTime(),
      y: 2,
      z: campaign.newEmails,
      campaignId: campaign.id
    });
  })

  var max = series[0].data.reduce((acc, entryItem) => {
    return entryItem.z > acc ? entryItem.z : acc;
  }, 0);

  new Contour({
        el: '.event-timeline',
        // xAxis: { categories: ['apples', 'oranges', 'bananas', null, 'grapes', 'pears'] },
        chart: {
          padding: {
            top: 120.5,
            left: 20,
            right: 20
          }
        },
        yAxis: {
            title: 'Value'
        },
        scatter: {
            // argument d is the element of the data series
            // that is currently being added to the visualization
            radius: function(d) {
              return d.z / max  * 60;
            }
        },
        tooltip: {
          opacity: 1,
          formatter: function (d) {
            var str = renderToString(TooltipFactory(campaignMap[d.campaignId]));

            return str;
          }
        }
      })
      .cartesian()
      .scatter(series)
      .tooltip()
      .centerSingle()
      .render();
};


class EventChart extends React.Component {
  componentDidMount() {
    renderGraphScatter(this.props.campaigns);
  }
  render() {
    let { earliestDate, latestDate } = this.props

    let midpoint = new Date((earliestDate.getTime() + latestDate.getTime()) / 2);

    midpoint = moment(midpoint).format('MMM YYYY');
    earliestDate = moment(earliestDate).format('MMM YYYY');
    latestDate = moment(latestDate).format('MMM YYYY');
    return(
      <Card className={ `${AnalyticsTheme.overviewCard} ${AnalyticsTheme.eventCard}` }>
        <div style={{
          padding: '20px 24px',
          height: '300px',
          position: 'relative'
        }}>
          <div style={{
            position: 'absolute',
            width: '868px',
            height: '1px',
            background: '#d8d8d8',
            top: '194px',
            marginLeft:' 30px',
            display: 'block'
          }}>
          </div>
          <AnalyticsHeader
            title="Historical Campaign Performance Timeline"
            tooltipText="The top three regions where you have acquired emails" />

          <div style={{
            marginBottom: '0px'
          }}>
            <div
              style={{
                borderBottom: '1px solid #d8d8d8',
                marginLeft: '30px',
                marginRight: '30px',
                marginTop: '10px'
              }}>
              <div style={{
                display: 'inline-block',
                width: '33%',
                textAlign: 'left'
              }}>
                <Small>
                  <b>
                    {earliestDate}
                  </b>
                </Small>
              </div>
              <div style={{
                display: 'inline-block',
                width: '33%',
                textAlign: 'center'
              }}>
                <Small>
                  <b>
                    {midpoint}
                  </b>
                </Small>
              </div>
              <div style={{
                display: 'inline-block',
                width: '33%',
                textAlign: 'right'
              }}>
                <Small>
                  <b>
                    {latestDate}
                  </b>
                </Small>
              </div>
            </div>
          </div>
          <div style={{
            position: 'absolute',
            marginLeft: '30px',
            marginTop: '30px'
          }}>
            <H5>
              COMPARATIVE ENTRIES AND NTF DRIVEN BY CAMPAIGN
            </H5>
          </div>
          <div style={{height: '200px', width: '900px', display: 'inline-block'}} className="event-timeline">
          </div>
          <div style={{paddingLeft: '30px'}}>
            <Ball color="#b7c3cd" />
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              height: '35px',
              marginLeft:' 6px',
              marginRight: '30px'
            }}>
              <Small>
                = campaign
              </Small>
            </div>
            <Ball color="rgba(3, 169, 244, 0.5)" />
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              height: '35px',
              marginLeft:' 6px'
            }}>
              <Small>
                = total entries
              </Small>
            </div>
          </div>
          <div style={{position: 'relative', paddingLeft: '30px'}}>
            <div style={{position: 'absolute', marginTop: '-17px'}}>
              <svg width="35" height="8" viewBox="0 0 35 8"
                xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="4" x2="34" y2="4"
                  stroke-width="2" stroke="black"/>
                <line x1="1" y1="0" x2="1" y2="4"
                  stroke-width="2" stroke="black"/>
                <line x1="34" y1="0" x2="34" y2="4"
                  stroke-width="2" stroke="black"/>
                <line x1="18" y1="4" x2="18" y2="8"
                  stroke-width="2" stroke="black"/>
              </svg>
              <div style={{marginTop: '5px'}}>
                <Small>
                  scale of entries
                </Small>
              </div>
            </div>
            <div style={{
              marginLeft: '130px',
              display: 'inline-block'
            }}>
              <Ball color="rgba(1, 118, 200, 0.5" />
            </div>
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              height: '35px',
              marginLeft:' 6px'
            }}>
              <Small>
                = NTF emails
              </Small>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

export default EventChart;
