import React from 'react';
import DocContainer from './doc-container';
import {Link} from 'react-router-dom';

var AcceptableUsePolicy = () => {
    return (
      <DocContainer>
        <div className="row text-center">
          <br />
          <b>
          Innovation Brands Corp.
          <br />
          Acceptable Use Policy
          </b>
        </div>
        <br />
        <br />
        <div>
          The actions individual members take can have a big impact on our system as a whole. That’s why all DojoMojo users must follow this Acceptable Use Policy in
          their use of DojoMojo and any other DojoMojo products. If you violate this policy, we may suspend or terminate your account. We may also suspend or
          terminate accounts according to our<Link to="/terms-of-use" style={{marginLeft: '5px'}}>Terms of Use</Link> where we see behavior, content, or other factors that poses a threat to our system.
        </div>
        <br />
        <div>
          Prohibited Content
        </div>
        <br />
        <div>
          Please don’t use DojoMojo to promote anything illegal, or to threaten or harass anyone. You may not use the Services to:
        </div>
        <br />
        <ul>
          <li>
            <div style={{display: 'inline'}}>
              Infringe any patent, trademark, trade secret, copyright, right of publicity or other right of any other person or entity or violates any law or
            contractual duty (see our
            </div>
            <Link to="/copyright" style={{display: 'inline', marginLeft: '5px'}}>Copyright Policy</Link>
            <div style={{display: 'inline'}}>
              )
            </div>
          </li>
          <li>
            Distribute illegal goods or services in the form of prizes or otherwise
          </li>
          <li>
            Promote or distribute defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, vulgar, offensive, profane,
            contains or depicts nudity, contains or depicts pornography/sexually explicit content or is otherwise inappropriate as determined by us in our sole
            discretion
          </li>
          <li>
            Send unauthorized or unsolicited advertising, junk or bulk e-mail (“spamming”);
          </li>
          <li>
            Submit to or distribute through DojoMojo any User Content that contains software viruses or any other computer codes, files, or programs that are
            designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment or to
            damage or obtain unauthorized access to any system, data, password or other information of ours or of any third party
          </li>
        </ul>
        <br />
        <div>
          Some industries have higher-than-average abuse complaints, which can jeopardize the deliverability of our entire system. Nothing personal, but in order to
          maintain the highest delivery rates possible for all our customers, we can’t allow businesses that offer these types of services, products, or content:
        </div>
        <br />
        <ul>
          <li>
            Escort and dating services
          </li>
          <li>
            Pharmaceutical products
          </li>
          <li>
            Work from home, make money online, and lead generation opportunities
          </li>
          <li>
            Gambling services or products
          </li>
          <li>
            Multi-level marketing
          </li>
          <li>
            Affiliate marketing
          </li>
          <li>
            Credit repair and get out of debt opportunities
          </li>
          <li>
            List brokers or list rental services
          </li>
          <li>
            Selling “Likes” or followers for a social media platform
          </li>
        </ul>
        <br />
        <div>
            We work hard to maintain the positive reputation of our system, but we count on our customers to pitch in too. You may not:
        </div>
        <br />
        <ul>
          <li>
            Use any misleading or incorrect names, addresses, email addresses, subject lines, prizes or other information on the Website or in any Emails created
            or sent using our Service.
          </li>
          <li>
            Share your password.
          </li>
          <li>
            Bulk message or spam other users.
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the software on our Website, or in any way used or downloaded from the Website.
          </li>
          <li>
            Use any of the software on our Website, or downloaded from the Website, to create a competing product.
          </li>
          <li>
            Set up multiple accounts for any person or entity, unless you’re part of a franchise or agency.
          </li>
          <li>
            Upload or send to purchased, rented, third-party, co-reg, publicly available data, or partner lists of any kind.
          </li>
          <li>
            Host images on our servers for any purpose other than for use in your sweepstakes campaigns.
          </li>
          <li>
            Host content on our servers for any purpose other than to plan, create or manage sweepstakes campaigns and email marketing.
          </li>
          <li>
            Delete, bulk unsubscribe, or otherwise modify your list in order to avoid our billing thresholds.
          </li>
        </ul>
        <br />
        <div>
          Best Practices
        </div>
        <br />
        <div>
          It’s in our best interest to keep the system clean, because our reputation and deliverability depend on it. For that reason we provide, and may update from
          time to time, articles outlining sending best practices in our knowledge base. You may only use DojoMojo in accordance with these best practices, and we
          may suspend or terminate your account if you violate them. DojoMojo reserves the right to suspend or terminate users at its sole discretion.
        </div>
        <br />
        <div>
          Thanks for taking the time to read our Acceptable Use Policy.
        </div>
        <br />
        <br />
      </DocContainer>
    );
  }

export default AcceptableUsePolicy;
