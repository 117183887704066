import _ from 'lodash'

const model = 'emailOverlap';
let initialState = {
  rid: model,
  overlaps: {}
};

const EmailOverlapReducer = (state, action) => {
  if (action.model !== 'emailOverlap') {
    return state || initialState;
  }

  switch (action.type) {
    case 'START_LOAD':
      state = _.extend({}, state);
      state.overlaps[action.data.targetBrandId] = action.data;

      return state;

      break;
    case 'LOAD_OVERLAP_SUCCESS':
      state = _.extend({}, state);
      
      var overlap = state.overlaps[action.data.target_brand_id] || {};

      if (overlap.waitInterval) {
        clearInterval(overlap.waitInterval);
      }

      _.extend(overlap, action.data, {
        processing: false,
        percentage: d3.format('%')(action.data.target_brand_matches / action.data.target_brand_acquires)
      });

      state.overlaps[action.data.target_brand_id] = overlap;

      return state;

    default:
      return initialState;
  }
};

export default EmailOverlapReducer;
