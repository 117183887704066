import React, { PureComponent as Component } from 'react'
import { array } from 'prop-types'
import CampaignAnalyticsCard from 'visual-components/analytics/campaign/CampaignAnalyticsCard'

class CampaignAnalyticsGrid extends Component {
  static propTypes = {
    campaigns: array.isRequired,
  }

  render() {
    const {
      campaigns,
    } = this.props

    const results = campaigns.map(campaign => (
      <CampaignAnalyticsCard campaign={campaign} key={campaign.id} />
    ))

    return (
      <div style={{ width: '752px' }}>
        { results }
      </div>
    )
  }
}

export default CampaignAnalyticsGrid
