import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'react-toolbox/lib/button'
import theme from 'css/themes/dialogs/dataIntegrationDialog.css'
import { H3, P } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { number, bool, object } from 'prop-types'
import * as RatingActions from 'actions/ratings-actions'
import createSrcset from 'util/create-srcset'
import moment from 'moment-timezone'

const stateToProps = ({
  brandRatings,
  brand,
  currentBrand,
  profile,
}) => ({
  brandRatings,
  brand,
  currentBrand,
  profile,
})

const dispatchToProps = dispatch => ({
  ratingActions: bindActionCreators(RatingActions, dispatch),
})

const findReview = id => ({ rated_brand_id: _id }) => _id === id

const findBrandFromPendingCampaign = (campaigns = [], id) => {
  for (let i = 0, len = campaigns.length; i < len; i++) {
    const [, brands] = campaigns[i]

    const brand = brands.find(findReview(id))

    if (brand) return brand
  }

  return null
}

// Dont show if they are older
const areRatingsBeforeTenDays = (reviews = []) => {
  const today = moment()

  for (let i = 0; i < reviews.length; i++) {
    const review = reviews[i]

    if (today.diff(review.model_end_date, 'day') < 10) return true
  }

  return false
}

const PERCENTAGE_MODAL_SHOULD_POP_UP = 20

class PendingReviewsReminder extends PureComponent {
  static propTypes = {
    history: object.isRequired,
    brand: object.isRequired,
    pendingOnBrand: bool,
    left: number,
    isLoading: bool,

    brandRatings: object.isRequired,
    ratingActions: object.isRequired,
    profile: object.isRequired,
    currentBrand: object.isRequired,
  }

  static defaultProps = {
    left: 0,
    pendingOnBrand: false,
    isLoading: false,
  }

  state = { open: false }

  componentDidMount() {
    this.fetchPendingReviews()

    this.timeout = setTimeout(() => this.setState({ open: true }), 1000)
  }

  componentDidUpdate(prevProps) {
    const { brand } = this.props

    if (brand.id !== prevProps.brand.id) this.setState({ open: true })
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  fetchPendingReviews = () => {
    const { ratingActions } = this.props

    ratingActions.getPendingReviews('marketplace')
    ratingActions.getPendingReviews('campaign')
  }

  onReviewAction = () => {
    const {
      history,
      brand: { id },
      pendingOnBrand,
      brandRatings,
      ratingActions,
    } = this.props

    if (pendingOnBrand) {
      const {
        pendingCampaignReviews: campaign,
        pendingMarketDealReviews: market,
      } = brandRatings

      const brandToReview = market.find(findReview(id)) || findBrandFromPendingCampaign(campaign, id)

      return ratingActions.openReviewModal(brandToReview)
    }

    return history.push('/reviews')
  }

  closeModal = () => this.setState({ open: false })

  isEnabled = () => {
    const { brandRatings, pendingOnBrand, brand } = this.props

    const hasPendingReviews = (brandRatings.pendingCampaignReviews.length > 0
        && areRatingsBeforeTenDays(brandRatings.pendingCampaignReviews)
    ) || (brandRatings.pendingMarketDealReviews.length > 0
        && areRatingsBeforeTenDays(brandRatings.pendingMarketDealReviews)
    )

    if (pendingOnBrand) {
      const { id } = brand
      const {
        pendingCampaignReviews: campaign,
        pendingMarketDealReviews: market,
      } = brandRatings

      const hasPendingReview = market.some(findReview(id)) || campaign.some(([_, brands]) => brands && brands.some(findReview(id)))

      return hasPendingReview
    }

    const shouldPopUp = Math.random() * 100 < PERCENTAGE_MODAL_SHOULD_POP_UP

    return hasPendingReviews && shouldPopUp
  }

  render() {
    const {
      left,
      isLoading,
      pendingOnBrand,

      brand: {
        id,
        accountname,
        loading,
      },
      currentBrand,
      profile: {
        impersonating,
      },
    } = this.props

    const isBrand = id === currentBrand.id
    const isImpersonating = impersonating && impersonating !== 'null'

    if (isLoading || loading || isBrand || isImpersonating) return null

    const { open } = this.state

    const canOpen = open && this.isEnabled()

    return (
      <div className={theme.positioningContainer}>
        <div style={{ left: `${left}px` }} className={classNames(theme.container, { [theme.active]: canOpen })}>
          <img
            className={theme.banner}
            src="images/data-integration-tile-banner.svg"
            srcSet={createSrcset('images/review-reminder/review-illustration.jpg')}
            alt="data integration banner"
          />
          <div className={theme.body}>
            <H3 ink>
              <small>
                {
                  pendingOnBrand
                    ? 'Pending Review'
                    : 'Leave a Review'
                }
              </small>
            </H3>
            <div className={theme.subtitle}>
              <P multiline ink>
                {
                  pendingOnBrand
                    ? (
                      <span>
                        You recently participated in a campaign with
                        <i>
                          &nbsp;
                          {accountname}
                        </i>
                        . Let them know how it went. It will only take
                        <i>&nbsp;a minute</i>
                        .
                      </span>
                    ) : (
                      <span>
                        Show your appreciation to your partners by leaving a public feedback. It will only take
                        <i>&nbsp;a minute</i>
                        .
                      </span>
                    )
                }
              </P>
            </div>
            <div className={theme.buttons}>
              <Button
                onClick={this.onReviewAction}
                label="Review Now"
                className={classNames(ButtonTheme.primary, ButtonTheme.blueButton, ButtonTheme.flat)}
                theme={ButtonTheme}
                raised
              />
              <Button
                onClick={this.closeModal}
                label="Dismiss"
                theme={ButtonTheme}
                primary
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(PendingReviewsReminder))
