import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'
import { P, H3, H6 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import ButtonTheme from 'css/themes/Buttons.css'

const styles = {
  container: {
    padding: 20,
    backgroundColor: 'white',
    border: `1px solid ${COLOURS.silver}`,
    borderRadius: 2,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  heading: {
    marginBottom: 12,
  },
  smallHeading: {
    marginBottom: 8,
  },
  copy: {
    width: 806,
    '& p:first-child': {
      marginBottom: 20,
    },
    '& p:last-child': {
      marginBottom: 32,
    },
  },
  smallCopy: {
    '& p:first-child': {
      marginBottom: 20,
    },
  },
  actionsContainer: {
    display: 'flex',
  },
  entryLanding: {
    marginRight: 12,
    borderRadius: 4,
    boxShadow: 'none',
    width: 438,
  },
  postEntry: {
    borderRadius: 4,
    boxShadow: 'none',
    width: 381,
  },
}

class WelcomeMessageEmptyState extends React.Component {
  static propTypes = {
    enableSmsOptin: PropTypes.func.isRequired,
    enableSmsPostEntry: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { enableSmsOptin, enableSmsPostEntry, classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.heading}>
          <H3>
            SMS Welcome Message
          </H3>
        </div>
        <div className={classes.copy}>
          <P multiline>
            SMS marketing offers a unique, direct pathway to increase brand awareness, enhance customer engagement, and–our favorite–boost sales!
          </P>
          <P multiline>
            Compliantly capture mobile phone numbers alongside your email leads, and scale your SMS marketing audience. Once you’ve got them, send them your welcome text!
          </P>
        </div>
        <div className={classes.actionsContainer}>
          <div className={cn(classes.container, classes.entryLanding)}>
            <div className={classes.smallHeading}>
              <H6>
                Entry Landing Page
              </H6>
            </div>
            <div className={classes.smallCopy}>
              <P>
                Opt in entrants to SMS Marketing  on your sweepstakes entry form.
              </P>
            </div>
            <Button
              label="Enable SMS"
              onClick={enableSmsOptin}
              className={ButtonTheme.blueButton}
              theme={ButtonTheme}
              primary
              raised
            />
          </div>
          {/* Note: This will likely come back at one point. Bryan should remove if it doesnt */}
          {/*<div className={cn(classes.container, classes.postEntry)}>
            <div className={classes.smallHeading}>
              <H6>
                Post Entry Action
              </H6>
            </div>
            <div className={classes.smallCopy}>
              <P>
                Capture SMS Subscribers through your post entry action.
              </P>
            </div>
            <Button
              label="Enable SMS"
              onClick={enableSmsPostEntry}
              className={ButtonTheme.blueButton}
              theme={ButtonTheme}
              primary
              raised
            />
          </div>*/}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(WelcomeMessageEmptyState)
