import React, { PureComponent as Component } from 'react'
import _ from 'lodash'

import { renderToString } from 'react-dom/server'

import GeoDistributionBarTooltip from 'visual-components/util/graphs/geo-distribution-bar-tooltip'

const TooltipFactory = React.createFactory(GeoDistributionBarTooltip)

const renderGraph = function (values, dates) {
  $('.geo-bar').html('')

  const estateTaxCollection = values.map(value => value.perc)
  const categories = values.map(value => value.state)

  _.times(3 - estateTaxCollection.length, () => {
    estateTaxCollection.unshift(0)
    categories.unshift('')
  })

  new Contour({
    el: '.geo-bar',
    xAxis: { categories },
    chart: {
      height: 110,
      padding: {
        right: 45,
      },
    },
    yAxis: {
      // this set of options will turn off all ticks on the yAxis
      ticks: 0,
      tickPadding: 0,
      innerTickSize: 0,
      outerTickSize: 0,
    },
    bar: {
      barWidth: 16,
      offset: 4,
    },
    rangeBand: 10,
    tooltip: {
      opacity: 1,
      formatter(d) {
        const data = _.find(values, { state: d.x }) || {}
        const str = renderToString(TooltipFactory(data))

        return str
      },
    },
  })
    .cartesian()
    .horizontal()
    .bar(estateTaxCollection, {
      rangeBand: 10,
    })
    .tooltip()
    // add our custom legend to our visualization instance
    .geoDistributionLegend(estateTaxCollection)
    .render()
}

class GeoDistributionBar extends Component {
  componentDidMount() {
    this.graph = renderGraph(this.props.values, this.props.dates)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.values) !== JSON.stringify(this.props.values)) {
      renderGraph(this.props.values, this.props.dates)
    }
  }

  render() {
    return (
      <div style={{ width: '275px', height: '110px', display: 'inline-block' }} className="geo-bar" />
    )
  }
}

export default GeoDistributionBar
