import moment from 'moment-timezone';
import _ from 'lodash'

import AppDispatcher from 'dispatchers/app-dispatcher';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher';
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';
import * as ReferralActions from 'actions/referral-actions'

import * as BrandClaimActions from 'actions/brand-claim-actions'
import * as ROICalculatorActions from 'actions/roi-calculator-actions'

import { cleanPlanText } from 'util/brand-stripe-belt-helper'

var loadedBrandError = function () {
  const { profile } = store.getState()
  $.ajaxSetup({ headers: null })
  try {
    // clear *Storage to prevent follow up attempts to log in as incorrect brand
    sessionStorage.removeItem(profile.uid)
    localStorage.removeItem(profile.uid)
  } catch (err) {
    console.log('sessionStorage/localStorage unavailable')
  }

  updateAttr('loading', false);
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'currentBrand',
    data: {
      loading: false
    }
  })
  if (window.location.pathname.indexOf('new-brand') === -1 && window.location.pathname.indexOf('pricing') === -1 && window.location.pathname.indexOf('registrations') === -1) {
    BrowserHistoryDispatcher.push('/registrations/brand-setup');
  }
  store.dispatch({
    model: 'currentBrand',
    type: 'UPDATE_ATTR',
    data: {
      loading: false
    }
  })
}

var handleZipUpdate = function(event) {
  if (event.target.value.length == 5) {
    AppDispatcher.http({
      url: `/check-ny-zipcode/${event.target.value}`,
      success: data => {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentBrand',
          data
        })
      },
      error: function(){

      }
    })
  } else {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentBrand',
      data: {
        isNY: false
      }
    })
  }
}


var updateTags = function(isSilent) {
  var brand = store.getState().currentBrand;
  var tags = [
    brand.brand_tag_0,
    brand.brand_tag_1,
    brand.brand_tag_2
  ]

  updateAttrPersist({
    attr: 'tagsconnected',
    value: tags
  }, isSilent);
}

const loadedBrandSuccess = function(brand) {
  brand.loading = false;

  store.dispatch({
    type: 'LOAD',
    model: 'currentBrand',
    data: brand
  });

  $.ajaxSetup({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('brand', brand.id);
    }
  });

  var user = store.getState().profile;

  try {
    sessionStorage.setItem(user.uid, brand.id)
    localStorage.setItem(user.uid, brand.id)
  } catch (e) {
    console.error('sessionStorage/localStorage unavailable')
  }

  moment.tz.setDefault(brand.timezone || "America/New_York");


  brand = _.extend({}, brand, brand.social);

  brand.brandfavorites = brand.brandfavorites.map(fav => fav.brand_b_id);

  const impersonating = sessionStorage.getItem('impersonating')

  AppDispatcher.http({
    url: '/conversations-started',
    success: function (conversations) {
      store.dispatch({
        model: 'currentBrand',
        data: {
          conversations: conversations
        },
        type: 'UPDATE_ATTR'
      })
    }
  });
  if (!brand.dedicatedListSize) {
    window.location.replace('/app')
  }
  var $zopim = window.$zopim || undefined;
  if ($zopim) {
    $zopim(function() {
      $zopim.livechat.setNotes(`
        Brand ${brand.accountname} ${brand.id}
        Campaign ${store.getState().currentCampaign.name} ${store.getState().currentCampaign.id}
      `)
    })
  }
    _.times(3, function (i) {
      if (brand.tagsconnected[i]) {
        brand['brand_tag_' + i] = brand.tagsconnected[i].name;
      }
    });
    brand.belt = cleanPlanText(brand.belt);

    store.dispatch({
      type: 'LOAD',
      model: 'currentBrand',
      data: brand
    });

    store.dispatch(ReferralActions.loadUnreadCount())

    MessagingDispatcher.loadConversations().then(function () {
    })

    AppDispatcher.load({
      model: 'customer',
      endpoint: '/customer',
    });

    AppDispatcher.http({
      url: '/campaigns-data',
      success: function (data) {

        //Display demo data if there are no campaigns that has started for brand
        const noCampaigns = data.campaigns.filter(campaign => new Date(campaign.startDate) < Date.now()).length == 0
        AnalyticsDispatcher.loadDefaultDashboardAnalytics(noCampaigns);
        store.dispatch({
          model: 'brandCampaigns',
          data: data,
          type: 'LOAD_CAMPAIGNS'
        });
        store.dispatch({
          model: 'pastCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'currentCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'inDiscussionCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'upcomingCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          type: 'LOAD',
          model: 'topPartners',
          data: {
            brandId: brand.id
          }
        });
        store.dispatch({
          type: 'LOAD',
          model: 'campaignHistory',
          data: {
            brandId: brand.id
          }
        });

        let campaigns = store.getState().brandCampaigns.campaigns;
        
        // TODO (Jenky): Disabled (10/25/2022) until we have people back on sales
        // if( campaigns.length == 1 && moment().isBetween(moment(campaigns[0].startDate).subtract(5, 'days'), moment(campaigns[0].startDate)) && !localStorage.getItem('dismissedCalendlyModal')) {
        //   store.dispatch({
        //     type: 'UPDATE_ATTR',
        //     model: 'calendlyModal',
        //     data: {
        //       open: true
        //     }
        //   })
        // }
      }
    });

    AppDispatcher.http({
      url: '/partnership-invites',
      success: function (data) {
        store.dispatch({
          model: 'notificationsList',
          type: 'ADD_CAMPAIGN_INVITES',
          data: data
        })
        const { currentBrand } = store.getState()
        const pendingInvite = data && _.find(data, { status: 'pending' });

        // If brand has been created less than 1 hour ago, has a pending invite, and is not in the registration process, then re-direct to messaging
        if ((moment(Date.now()).subtract(1, 'hours') < moment(currentBrand.created_at))
          && pendingInvite
          && window.location.pathname.indexOf('details') === -1
          && window.location.pathname.indexOf('/registrations') === -1
        ) {
          if (currentBrand.referral) {
            store.dispatch({
              model: 'confirmationModal',
              type: 'UPDATE_ATTR',
              data: {
                active: true
              }
            })
          }
        }
      }
    });

    AppDispatcher.http({
      url: '/brand-invites',
      success: function (data) {
        store.dispatch({
          model: 'notificationsList',
          type: 'ADD_BRAND_INVITES',
          data: data
        })
      }
    });

    AppDispatcher.http({
      url: '/partnership-applications',
      success: function (data) {
        store.dispatch({
          model: 'notificationsList',
          type: 'ADD_APPLICATIONS',
          data: data
        })
      }
    });

    AppDispatcher.http({
      url: '/brand-dashboard',
      success: function (data) {
        data.isEmpty = data.acquiresHistory.allTimeCampaigns === 0;
        data.brandId = brand.id;
        data.pixelInstalledAt = brand.pixelInstalledAt;
        store.dispatch({
          type: 'LOAD',
          model: 'acquiresHistory',
          data: data
        });
        store.dispatch({
          type: 'LOAD',
          model: 'recentAcquires',
          data: data
        });
        store.dispatch({
          type: 'LOAD',
          model: 'brandPostEntry',
          data: data
        });
        store.dispatch({
          type: 'LOAD',
          model: 'socialMetrics',
          data: data
        });
      }
    });

    AppDispatcher.http({
      url: '/get-recommended-brands',
      success: recommendedBrands => store.dispatch({
        model: 'currentBrand',
        type: 'UPDATE_ATTR',
        data: { recommendedBrands, recommendedBrandsLoading: false },
      }),
    });

    AppDispatcher.http({
      url: '/get-recommended-campaigns',
      success: recommendedCampaigns => store.dispatch({
        model: 'currentBrand',
        type: 'UPDATE_ATTR',
        data: { recommendedCampaigns, recommendedCampaignsLoading: false },
      }),
    });

    AppDispatcher.http({
      url: '/brand-features',
      success: data => store.dispatch({
        model: 'currentBrand',
        type: 'UPDATE_ATTR',
        data: { features: data.features, featuresLoading: false },
      }),
    });

    store.dispatch(BrandClaimActions.loadBrandClaims())
    store.dispatch(ROICalculatorActions.getROIValues())

    // this.currentBrand = new BrandModel(brand);

    // CampaignInvitesActions.getInitialState();
    // this.currentBrand.filterMemberInivtes();

    // ConversionsIntegrationsActions.loadRequest();
    // CsvLogDispatcher.loadLog();

    // this.update();
}

var load = function () {
  store.dispatch({
    model: 'currentBrand',
    type: 'UPDATE_ATTR',
    data: {
      loading: true,
      featuresLoading: true,
    }
  })
  $.ajax({
    method: 'GET',
    url: '/current-brand',
    success: loadedBrandSuccess,
    error: loadedBrandError
  });
}
var updateAttrPersist = function(data, isSilent) {
  var body = {};

  if (data.attr) {
    updateAttr(data.attr, data.value);
    body[data.attr] = data.value;
    if (data.attr === 'errors') {
      return;
    }
  } else {
    body = data;
    _.each(data, function (value, attr) {
      updateAttr(attr, value);
    })
  }
  $.ajax({
    url: '/brands/',
    method: 'PUT',
    data: JSON.stringify(body),
    contentType: 'application/json',
    success: function(brand) {
      if(!isSilent) {
        toastr.success('Saved', null, { timeOut: 1000, positionClass: 'toast-bottom-center' });
      }
    },
    error: function(error) {
      if(!isSilent) {
        // CurrentBrandActions.updateError(error);
      }
    }
  });
}

var updateAttr = function (attr, value) {
  store.dispatch({
    type: 'UPDATE_ATTR',
    data: {
      [attr]: value
    },
    model: 'currentBrand'
  });
}

var setBrand = function (brand) {


  store.dispatch({
    type: 'UPDATE_ARR',
    model: 'brandInDetail',
    data: {
      availableCampaigns: [],
      currentTab: 'details'
    }
  })
  AppDispatcher.http({
    data: { query: { match: {hostBrandId: brand.id }}},
    url: '/search-campaigns',
    method: 'POST',
    success: function (results) {
      results = _.compact(_.map(results.hits.hits, function (result) {
        if (result._source.hostBrandId !== brand.id) {
          return null;
        } else {
          return {
            name: result._source.name,
            id: result._source.id
          };
        }
      }));
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'brandInDetail',
        data: {
          availableCampaigns: results
        }
      });
    }
  })
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'brandInDetail',
    data: _.extend(brand,{
      expanded: true
    })
  });
};

var switchBrandRequest = function (id) {
  var win = window.open(`/?brand=${id}`, '_blank');
  win.focus();
};


var integrateShopify = function(data, callback) {
  $.get('/shopify-auth/' + data.storeId, function(msg) {
    callback("Success");
  }).fail(function(err) {
    toastr.error("Error");
  })
}

var upgrade = function (belt) {
  var currentBrand = store.getState().currentBrand;
  var customer = store.getState().customer;
  const { isNY } = currentBrand

  if (customer.trialAvailable) {
    BrowserHistoryDispatcher.replace(`/upgrade-trial-confirm/${belt}`);
  } else {
    if (belt === 'white') {
      store.dispatch({
        type: 'LOADING',
        model: 'customer',
      })
      AppDispatcher.http({
        url: '/changeBelt',
        method: 'POST',
        data: {
          belt: belt
        },
        success: function ({ customer, futureUpgrade }) {
          store.dispatch({
            type: 'LOAD_SUCCESS',
            model: 'customer',
            data: { ...customer, trialAvailable: false },
          })
          const subscription = customer && customer.subscriptions && customer.subscriptions.data
            && customer.subscriptions.data.length && customer.subscriptions.data[0]
          // prevent updating belt frontend for trialing/ltc brands that upgrade but dont switch immediately
          if (subscription && belt === subscription.plan.id) {
            updateAttr('belt', cleanPlanText(belt));
          }
          // prevent updating stripe_belt frontend for ltc brands that upgrade but dont switch immediately
          if (!futureUpgrade) {
            updateAttr('stripe_belt', belt);
          }
          if (belt === 'white') {
            BrowserHistoryDispatcher.replace(``);
          } else {
            BrowserHistoryDispatcher.replace(`/upgrade-success/${ belt }${isNY?'NY':''}`)
          }
        },
        error(err) {
          store.dispatch({
            type: 'LOAD_FAILURE',
            model: 'customer',
            data: err.responseText,
          })
          BrowserHistoryDispatcher.replace(`/upgrade-payment-confirm/${belt}`)
        },
      })
    } else {
      BrowserHistoryDispatcher.replace(`/upgrade-payment-confirm/${belt}`);
    }
  }
}

var upgradeConfirm = function (belt, isNY, discountId, smsOrder = null) {
  store.dispatch({
    type: 'LOADING',
    model: 'customer',
  })
  AppDispatcher.http({
    url: '/changeBelt',
    method: 'POST',
    data: {
      belt: belt,
      discountId,
      smsOrder,
    },
    success: function ({ customer, futureUpgrade }) {
      let currentBrand = store.getState().currentBrand

      if (!currentBrand.trial_end) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentBrand',
          data: {
            trial_end: moment().add(30, 'days')._d
          }
        })
      }

      customer.trialAvailable = false;

      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'customer',
        data: customer,
      })
      const subscription = customer && customer.subscriptions && customer.subscriptions.data
        && customer.subscriptions.data.length && customer.subscriptions.data[0]
      // prevent updating belt frontend for trialing/ltc brands that upgrade but dont switch immediately
      if (subscription && belt === subscription.plan.id) {
        updateAttr('belt', cleanPlanText(belt));
      }
      // prevent updating stripe_belt frontend for ltc brands that upgrade but dont switch immediately
      if (!futureUpgrade) {
        updateAttr('stripe_belt', belt);
      }

      // Fire marketing pixel events
      if (window.fbq) {
        fbq('track', 'Purchase', {value: '0.00', currency: 'USD'});
      }

      AppDispatcher.http({
        url: '/brand-sms-bank',
        success: function (bank) {
          store.dispatch({
            type: 'FETCH_BRAND_SMS_BANK_SUCCESS',
            payload: bank
          })
          BrowserHistoryDispatcher.replace(`/upgrade-success/${ belt }`);
        }
      })
    },
    error(err) {
      store.dispatch({
        type: 'LOAD_FAILURE',
        model: 'customer',
        data: err.responseText,
      })
    },
  })
}

var updateCreditCard = function (belt) {
  store.dispatch({
    type: 'LOADING',
    model: 'customer',
  })
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'currentBrand',
    data: { loading: true },
  })
  AppDispatcher.http({
    url: '/changeBelt',
    method: 'POST',
    data: {
      belt: belt
    },
    success: function ({ customer }) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'customer',
        data: _.extend({}, customer, {
          loading: false
        })
      })
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'currentBrand',
        data: { loading: false },
      })
      BrowserHistoryDispatcher.replace(`/`);
    },
    error(err) {
      store.dispatch({
        type: 'UPDATE_ATTR',
        model: 'currentBrand',
        data: { loading: false },
      })
      store.dispatch({
        type: 'LOAD_FAILURE',
        model: 'customer',
        data: err.responseText,
      })
    },
  })
}

const editSource = (id, data, callback) => {
  AppDispatcher.http({
    url: `/card/${id}`,
    method: 'PUT',
    data,
    success: response => {
      const { customer } = store.getState()
      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'customer',
        data: { ...customer, ...response },
      })
      callback()
    },
  })
}

const deleteSource = source => {
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'customer',
    data: { sourceLoading: source.id },
  })

  AppDispatcher.http({
    url: `/card/${source.id}`,
    method: 'DELETE',
    data: source,
    success: response => {
      const { customer } = store.getState()
      const { sources } = customer

      if (response.deleted) {
        const type = response.object === 'card' ? 'creditCards' : 'bankAccounts'
        sources.data = sources.data.filter(s => s.id !== source.id)
        customer[type] = customer[type].filter(s => s.id !== source.id)
      }

      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'customer',
        data: { ...customer, sources, sourceLoading: '' },
      })
    },
  })
}

const makeDefaultSource = source => {
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'customer',
    data: { sourceLoading: source.id },
  })

  AppDispatcher.http({
    url: '/set-default-source',
    method: 'POST',
    data: source,
    success: response => {
      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'customer',
        data: { ...response, sourceLoading: '' },
      })

      toastr.success('Your default payment source has been updated.', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
    },
  })
}

var createRecommendedBrands = function() {
  AppDispatcher.http({
    url: '/create-recommended-brands',
    method: 'POST'
  });
}

var createRecommendedCampaigns = function() {
  AppDispatcher.http({
    url: '/create-recommended-campaigns',
    method: 'POST'
  });
}

var updatePartnershipInterests = function(interests) {
  var interestMap = {
    sweepstakes: 'sweepstakes',
    contentSwaps: 'content_swap',
    insertSwaps: 'product_shipment_insert',
    dedicatedEmailBuys: 'dedicated_email_buy',
    dedicatedEmailSales: 'dedicated_email_sell',
    offerPrizing: 'prizing_partner'
  }

  var interestsList = interests.map(interest => interestMap[interest])

  updateAttrPersist({
    attr: 'interestslist',
    value: interestsList
  }, true);
}

var goToInterests = function(brandId, history) {
  $.ajaxSetup({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('brand', brandId);
    }
  });

  history.push('/registrations/brand-profile-interests');
}

export function toggleCustomFontModal () {
  return  {
    type: 'TOGGLE_CUSTOM_FONT_MODAL',
    model: 'currentBrand'
  }
}

const toggleFavorite = (brandId, favorites) => {
  AppDispatcher.http({
    url: '/toggle-brand-favorite',
    method: 'POST',
    data: { brandId },
    success: favorites => {
      store.dispatch({
        model: 'currentBrand',
        type: 'UPDATE_ATTR',
        data: {
          brandfavorites: favorites
        }
      })
    }
  })
}

const toggleIgnoreWebbula = (brandId, ignoreWebbula) => {
  AppDispatcher.http({
    url: `/brands`,
    method: 'PUT',
    data: { ignoreWebbula },
    success: () => {
      store.dispatch({
        model: 'currentBrand',
        type: 'UPDATE_ATTR',
        data: {
          ignoreWebbula
        }
      })
    }
  })
}


export default {
  toggleFavorite,
  toggleIgnoreWebbula,
  handleZipUpdate,
  goToInterests,
  setBrand,
  load,
  updateAttrPersist,
  updateTags,
  updateAttr,
  switchBrandRequest,
  integrateShopify,
  upgrade,
  upgradeConfirm,
  updateCreditCard,
  editSource,
  deleteSource,
  makeDefaultSource,
  createRecommendedBrands,
  createRecommendedCampaigns,
  updatePartnershipInterests,
};
