var _ = require('lodash');

var files = [
  'login/landing.json',
  'login/register.json',
  'login/brand-register.json',
  'login/opener-page.json',
  'general/form.json',
  'general/dojo.json',
  'brands/settings.json',
  'brands/search.json',
  'brands/invite.json',
  'brands/dashboard.json',
  'dashboard/dashboard.json',
  'general/actions.json',
  'campaigns/campaigns.json',
  'campaigns/landing-editor.json',
  'campaigns/design-help.json',
  'messaging/messaging.json',
  'profile.json',
  'notifications.json',
  'switch-brand.json',
  'users.json',
  'campaign-search.json',
  'navigation/navigation.json',
  'careers/index.json',
  'careers/careers-page.json',
  'careers/full-stack-developer.json',
  'careers/customer-success-director.json',
  'careers/account-manager.json',
  'careers/Partnerships-Specialist.json',
  'careers/product-manager.json',
  'careers/junior-designer.json',
  'careers/sales-dev-rep.json',
  'careers/senior-designer.json',
  'pricing/pricing-splash.json',
  'pricing/explore.json',
  'pricing/pricing-gate-features.json',
  'pricing/white.json',
  'pricing/green.json',
  'pricing/black.json',
  'pricing/billing.json',
  'powerOfPages/power-of-bonus-page.json',
  'powerOfPages/power-of-post-entry-page.json',
  'powerOfPages/feature-landing-page-builder.json',
  'powerOfPages/feature-brand-search.json',
  'powerOfPages/feature-campaign-search.json',
  'powerOfPages/feature-analytics.json',
  'powerOfPages/network.json',
  'powerOfPages/case-studies.json',
  'powerOfPages/white-pages.json',
  'tours.json'
];

files = files.map(path => path.replace('.json', ''));

var CopyLoader = function (callback) {
window.LANGUAGE= {};

  LANGUAGE.run = function (str, data) {
    return Handlebars.compile(str)(data);
  };

  // Webpack will dynamically import every JSON file in the language/ directory
  files.forEach(file => _.extend(LANGUAGE, require('../../language/' + file + '.json')))

  callback();
};

export default CopyLoader;
