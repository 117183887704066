import React from 'react';
import { renderToString } from 'react-dom/server'
import { P, Tiny } from 'visual-components/util/texts';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';


import IncomeDistibutionGraphTooltip from 'visual-components/util/graphs/income-distribution-tooltip';

var TooltipFactory = React.createFactory(IncomeDistibutionGraphTooltip)

var renderGraph = function (data) {
  var graph = new Contour({
    el: '.column-basic',
    xAxis: {
      categories: ['< 35K', '35-49K', '50-74K', '75-99K', '100K+']
    },
    yAxis: {
      ticks: 0
    },
    bar: {
      barWidth: 50
    },
    tooltip: {
      opacity: 1,
      formatter: function (d) {
        var str = renderToString(TooltipFactory({
          category: d.x,
          count: d.y,
          percent: d.y / data.total
        }));

        return str;
      }
    }
  })
  .cartesian()
  .column(data.distribution)
  .columnBasic()
  .tooltip()
  .render();

  return graph;
};

class IncomeDistributionGraph extends React.Component {
  componentDidMount() {
    this.graph = renderGraph(this.props.data);
  }

  render() {
    return (
      <div style={{
        marginLeft: '-16px'
      }}>
        <div className="column-basic"
          style={{
            width: '300px',
            height: '146px'
        }}></div>
      </div>
    );
  }
}

export default IncomeDistributionGraph;
