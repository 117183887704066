import AppDispatcher from 'dispatchers/app-dispatcher';

const BrandSocialDispatcher = {
  updateAttr: (attr, value) => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      data: {
        [attr]: value
      },
      model: 'currentBrand'
    });
  },

  updateAttrPersist: (attr, value) => {
    BrandSocialDispatcher.updateAttr(attr, value);

    AppDispatcher.http({
      url: '/brands-social',
      method: 'PUT',
      data: {
        [attr]: value
      },
      success: (data) => {
        store.dispatch({
          type: 'UPDATE_ATTR',
          data: {
            socialMediaImpressions: data.socialMediaImpressions
          },
          model: 'currentBrand'
        });
      }
    });
  },

  retrieveFacebookLikes: (username) => {
    AppDispatcher.http({
      url: '/fetch-facebook-likes/' + username,
      method: 'GET',
      success: function (data) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          data: {
            facebookFollowers: data.facebookFollowers,
            socialMediaImpressions: data.socialMediaImpressions
          },
          model: 'currentBrand'
        });
      }
    });
  },

  retrieveTwitterFollowers: (username) => {
    AppDispatcher.http({
      url: '/fetch-twitter-followers/' + username,
      method: 'GET',
      success: function (data) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          data: {
            twitterFollowers: data.twitterFollowers,
            socialMediaImpressions: data.socialMediaImpressions
          },
          model: 'currentBrand'
        });
      }
    });
  },
}

export default BrandSocialDispatcher;
