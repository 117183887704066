import React, { Component } from 'react'
import { P } from 'visual-components/util/texts'

import CampaignPartnerDetails from 'visual-components/campaignProfile/campaign-partner-details'

class ApplyToCampaignStepTwo extends Component {

  render() {
    const {
      updateData,
      nextStep,
      closeModal,
      applicationData,
      confirm,
      datePickerOpen,
      toggleDatePicker,
      campaign,
    } = this.props

    return (
      <div>
        <P>Step 2 of 3: Let the Host know what you can commit to.</P>
        <CampaignPartnerDetails
          updateData={ updateData }
          applicationData={ applicationData }
          campaign={campaign}
          datePickerOpen={ datePickerOpen }
          toggleDatePicker={ toggleDatePicker }
        />
      </div>
    )
  }
}

export default ApplyToCampaignStepTwo
