import React from 'react';
import COLOURS from 'util/colours';
import { Row, Col } from 'react-flexbox-grid';
import { P } from 'visual-components/util/texts'

class SearchResultItem extends React.Component {
  render() {
    let { brand, choose, noLogo, modalSearch } = this.props
    let modelSearchPadding = modalSearch ? '0px' : '6px 18px'

    return (
      <Row onClick={ choose } className="messaging-search-result" style={{
        cursor: 'pointer',
        padding: modelSearchPadding
      }}>
        <Col xs={ 1 } style={{
          paddingRight: '0px'
        }}>
        { !noLogo &&
            <div style={{
              width: '40px',
              minWidth: '40px',
              height: '40px',
              padding: '2px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: COLOURS.silver,
              display: 'table-cell',
              verticalAlign: 'middle',
              background: '#fff'
            }}>
              <img src={ brand.logo }
                style={{
                  maxHeight: '36px',
                  maxWidth: '36px',
                  display: 'block',
                  margin: 'auto'
              }}/>
            </div>
        }
        </Col>
        {
          modalSearch ?
          <Col xs={ 11 } style={{
            marginLeft: '-38px'
          }}>
            <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
              <P>
                <i>{ brand.accountname }</i>
              </P>
            </div>
          </Col>
          :
          <Col xs={ 11 } style={{
            paddingLeft: '0px'
          }}>
            <p style={{
              fontFamily: 'Larsseit',
              marginTop: '11px',
              fontSize: '16px',
              color: COLOURS.dropdownText
            }}>
              { brand.accountname }
            </p>
          </Col>
        }
      </Row>
    )
  }
}

export default SearchResultItem;
