// eslint-disable-next-line import/prefer-default-export
export const accept = (type = 'platform', accepted = true) => ({
  types: ['ACCEPT_TOU', 'ACCEPT_TOU_SUCCESS', 'ACCEPT_TOU_FAILURE'],
  http: {
    url: '/terms-of-use/accept',
    method: 'POST',
    data: { type, accepted },
  },
  flagType: type,
  accepted,
  model: 'currentBrand',
})
