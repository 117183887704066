import React, { Component } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import VersionTen from 'util/components/templates/versions/version-10'
import VersionEleven from 'util/components/templates/versions/version-11'
import VersionTwelve from 'util/components/templates/versions/version-12'
import VersionThirteen from 'util/components/templates/versions/version-13'
import VersionFourteen from 'util/components/templates/versions/version-14'
import VersionFifteen from 'util/components/templates/versions/version-15'
import AgreeToEditModal from 'util/components/agree-to-edit-modal'
import escapeHTML from 'escape-html'
import AppDispatcher from 'dispatchers/app-dispatcher'
import Spinner from 'util/components/spinner'
import { connect } from 'react-redux'
import * as metrics from 'util/metrics'
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'
import CampaignElementsDispatcher from 'dispatchers/campaign-elements-dispatcher'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import propTypes from 'prop-types'
import WarningMobileEditModal from 'v2/components/templates/WarningMobileEditModal'
import EditorMenuContainer from 'v2/components/templates/editorMenu/EditorMenuContainer'
import MobileWrap from './mobile-wrap'

toastr.options.closeButton = true
toastr.options.timeOut = 5000


const styles = {
  container: {
    display: 'flex',
  },
}

const mapState = ({
  currentCampaign,
  currentBrand,
}) => ({
  currentCampaign,
  currentBrand,
})

const versionMap = {
  'version-10': VersionTen,
  'version-11': VersionEleven,
  'version-12': VersionTwelve,
  'version-13': VersionThirteen,
  'version-14': VersionFourteen,
  'version-15': VersionFifteen,
}

const persist = _.debounce(campaignId => {
  const data = _.omit(store.getState().currentCampaign, [
    'promotionalUnits',
    'invites',
    'partnerCampaigns',
    'announcements',
    'brandconnections',
    'assets',
  ])

  AppDispatcher.http({
    method: 'PUT',
    data,
    url: `/campaigns/${campaignId}`,
    success: _.noop,
  })
}, 600)
class EditorMain extends Component {
  static propTypes = {
    currentCampaign: propTypes.object.isRequired,
    currentBrand: propTypes.object.isRequired,
    openToMobile: propTypes.bool.isRequired,
    campaignId: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]).isRequired,
    classes: propTypes.object.isRequired,
  }

  state = {
    showWarningMobileEditModal: false,
    isEditorMenuOpen: true,
  }

  confirmedWarningMobileEditModal = false
  mobileStyleUpdates = null

  track = _.debounce(data => {
    metrics.create('campaignManagerUpdatedCopy', {
      meta: data,
    })
  }, 1000)

  checkFont = _.debounce(attr => {
    const {
      currentCampaign,
    } = this.props

    // combine current elements with currentCampaign
    const campaign = { ...currentCampaign, ...this.getElementsModel() }
    const bodies = ['headLine', 'details', 'disclaimer', 'preEntryMessage', 'additionalTermsDetail', 'styles']

    if (bodies.indexOf(attr) === -1) {
      return
    }

    let fonts = []
    _.each(bodies, field => {
      if (!campaign[field]) {
        return
      }

      if (field == 'styles') {
        fonts.push(campaign.styles.fonts.entryButtonText.family)
      } else {
        const matches = campaign[field].match(/font-family:.*?;/gi)
        _.each(matches, match => {
          fonts.push(match.split('font-family:')[1].trim().split(/[;,"]/g)[0])
        })
      }
    })

    fonts = _.uniq(fonts)
      // filter out fonts unavailable from Google Fonts
      .filter(font => !/(Arial|Verdana|Helvetica)/.test(font))

    const data = {
      customFonts: {
        values: fonts,
      },
    }

    CampaignElementsDispatcher.persistCampaignElements(data)
  }, 1000)

  warningMobileEditModalAction = _.memoize(action => () => {
    this.setState({
      showWarningMobileEditModal: false,
    })

    if (action === 'confirm') {
      this.confirmedWarningMobileEditModal = true
      switch (this.mobileStyleUpdates.type) {
        case 'elements':
          this.updateElementsAttr.apply(null, this.mobileStyleUpdates.params)
          break
        default:
          this.updateAttr.apply(null, this.mobileStyleUpdates.params)
      }

      CampaignDispatcher.updateAttrPersist('viewedCampaignMobileEditModal', true)
    }

    this.mobileStyleUpdates = null
  })

  componentDidMount() {
    const {
      currentCampaign,
      openToMobile,
      campaignId,
    } = this.props

    AppDispatcher.http({
      url: `/campaigns-legacy/${campaignId}`,
      success: campaign => {
        campaign.startDate = new Date(campaign.startDate)
        campaign.endDate = new Date(campaign.endDate)

        campaign.styles = campaign.styles || {}

        campaign.partnerCampaigns = campaign.invites

        const host = _.find(campaign.invites, { invitee_id: campaign.hostBrandId })

        campaign.hostBrandLogo = host.invitee.logo

        campaign.inputs = campaign.inputs || {}

        campaign.showAgreeToEditModal = false

        // Merging campaign terms into campaigns
        const termsFields = [
          'additionalTermsDetail',
          'disclaimer',
          'tosLinkLabel',
          'additionalTermsLiability',
          'disclaimerLiability',
          'tosLinkLabelLiability',
          'preCheck',
          'perBrandTerms',
        ]
        campaign = _.extend({}, campaign, _.pick(campaign.campaignTerms, termsFields))
        // -----------------------------------------

        const defaultStyles = {
          imagePos: {
            zoom: 140,
            x: 0,
            y: 0,
          },
          imagePosMobile: {
            zoom: 600,
            x: 0,
            y: 0,
          },
          overlay: {
            type: 'none',
            color1: '#000000',
            opacity1: 0.25,
            color2: '#14cba8',
            opacity2: 0.5,
          },
          overlayMobile: {
            type: 'none',
            color1: '#000000',
            opacity1: 0.25,
            color2: '#14cba8',
            opacity2: 0.5,
          },
          form: {
            color: '#000000',
            opacity: 0.25,
          },
          formMobile: {
            color: '#000000',
            opacity: 0.25,
          },
          fonts: {

          },
          gradientLeftDesk: 1,
          gradientLeftMobile: 1,
          gradientRightDesk: 0,
          gradientRightMobile: 0,
        }

        campaign.desktopElements.styles = { ...defaultStyles, ...campaign.desktopElements.styles }

        campaign.mobileElements.styles = { ...defaultStyles, ...campaign.mobileElements.styles }

        const partnerBrandNames = campaign.invites
          .filter(invite => invite.status == 'accepted')
          .filter(invite => invite.invitee.id != campaign.hostBrandId)
          .map(invite => invite.invitee.accountname)

        let prettyPartnerBrandNames = [partnerBrandNames.slice(0, -1).join(', '), partnerBrandNames.slice(-1)[0]].join(partnerBrandNames.length < 2 ? '' : ' and ')
        let prettyPartnerBrandNamesCommas = partnerBrandNames.join(', ')
        if (partnerBrandNames.length > 0) {
          prettyPartnerBrandNames = `, ${prettyPartnerBrandNames}`
          prettyPartnerBrandNamesCommas = `, ${prettyPartnerBrandNamesCommas}`
        }

        campaign.partnerBrandNames = escapeHTML(prettyPartnerBrandNames)
        campaign.partnerBrandNamesCommas = escapeHTML(prettyPartnerBrandNamesCommas)

        campaign.hostBrandName = campaign.hostBrand.accountname

        // in the case that all invites are loaded prior to this,
        // this call only contains accepted invites so we dont want to overwrite
        if (currentCampaign.invites) {
          campaign.invites = currentCampaign.invites
        }

        store.dispatch({
          type: 'LOAD_SUCCESS',
          data: campaign.campaignElements,
          model: 'campaignElements',
        })

        store.dispatch({
          type: 'LOAD_SUCCESS',
          model: 'currentCampaign',
          data: _.extend({}, campaign, { loaded: true, viewDesktop: !openToMobile }),
        })
      },
    })

    this.setState({
      active: true,
    })
  }

  updateAttr = (attr, value) => {
    const {
      currentCampaign,
    } = this.props

    if (
      !currentCampaign.viewDesktop
      && !currentCampaign.viewedWarningMobileEditModal
      && !this.confirmedWarningMobileEditModal
    ) {
      //save the parameters
      this.mobileStyleUpdates = {
        type: 'campaign',
        params: [attr, value],
      }
      this.setState({
        showWarningMobileEditModal: true,
      })
      return
    }

    if (attr === 'styles') {
      value = _.merge({}, this.props.currentCampaign.styles, value)
    }

    const data = {}
    data[attr] = value

    if (attr === 'showSmsOptin' && value === true) {
      // automatically enable phone filed if not enabled
      if (!currentCampaign.inputs || !currentCampaign.inputs.phone) {
        data.inputs = {
          ...currentCampaign.inputs,
          phone: true,
        }
      }
    }

    store.dispatch({
      type: 'UPDATE_ATTR',
      data,
      model: 'currentCampaign',
    })

    persist(this.props.campaignId)

    this.checkFont(attr)
  }

  updateElementsAttr = (attr, value, skipPersist) => {
    const {
      currentCampaign,
    } = this.props

    if (
      !currentCampaign.viewDesktop
      && !currentCampaign.viewedWarningMobileEditModal
      && !this.confirmedWarningMobileEditModal
    ) {
      //save the parameters
      this.mobileStyleUpdates = {
        type: 'elements',
        params: [attr, value, skipPersist],
      }
      this.setState({
        showWarningMobileEditModal: true,
      })
      return
    }

    if (attr === 'styles') {
      value = _.merge({}, this.getElementsModel().styles, value)
    }
    const data = {}
    data[attr] = value

    if (['mainImage', 'styles'].includes(attr) && skipPersist) {
      CampaignElementsDispatcher.updateCampaignElements(data)
      return
    }

    if (['headLine', 'details', 'preEntryMessage'].includes(attr)) {
      this.track({
        attr,
      })
    }

    CampaignElementsDispatcher.persistCampaignElements(data)

    this.checkFont(attr)
  }

  // Used to update more than one field at a time to prevent debounce issue
  updateElementsDataAttr = data => {
    CampaignElementsDispatcher.persistCampaignElements(data)
  }

  agreeToLiabilityAndPersist = attr => {
    const data = {
      [attr]: new Date(),
    }

    store.dispatch({
      type: 'UPDATE_ATTR',
      data,
      model: 'currentCampaign'
    })
    const termsId = this.props.currentCampaign.campaignTerms.id

    AppDispatcher.http({
      method: 'PUT',
      data,
      url: `/terms/agree-liability/${termsId}`,
      success: _.noop,
    })
  }

  edit = attr => {
    const data = {
      editingAttr: attr,
    }

    store.dispatch({
      type: 'UPDATE_ATTR',
      data,
      model: 'currentCampaign',
    })
  }

  editElements = attr => {
    const data = { editingAttr: attr }
    CampaignElementsDispatcher.updateCampaignElements(data)
  }

  getElementsModel = () => {
    const platform = this.props.currentCampaign.viewDesktop ? 'desktop' : 'mobile'
    return this.props.currentCampaign[`${platform}Elements`]
  }

  hideCustomFontsModal = () => {
    this.setState(prevState => ({
      active: !prevState.active,
    }))
  }

  onEditorMenuOpen = (isOpen = true) => {
    this.setState({
      isEditorMenuOpen: isOpen,
    })
  }

  renderDesktop = (Version, opts) => {
    const {
      currentCampaign: campaign,
      currentBrand,
    } = this.props

    const {
      isEditorMenuOpen,
    } = this.state

    return (
      <Version
        forceMobile={!!campaign.viewDesktop}
        opts={opts}
        updateAttr={this.updateAttr}
        updateElementsAttr={this.updateElementsAttr}
        edit={this.edit}
        editElements={this.editElements}
        agreeToLiability={this.agreeToLiabilityAndPersist}
        customFonts={currentBrand.fonts}
        desktopVersion={!!campaign.viewDesktop}
        isEditorMenuOpen={isEditorMenuOpen}
      />
    )
  }

  renderMobile = (Version, opts, isV2Template) => {
    return (
      <MobileWrap
        newTemplate={isV2Template >= 10}
      >
        {this.renderDesktop(Version, opts)}
      </MobileWrap>
    )
  }

  render() {
    const {
      currentCampaign: campaign,
      currentBrand,
      classes: css,
    } = this.props

    const {
      showWarningMobileEditModal,
      isEditorMenuOpen,
    } = this.state

    const floatingButtonStyle = [{
      position: 'fixed',
      display: 'inline-block',
      width: '55px',
      height: '55px',
      left: '15px',
      borderRadius: '42px',
      color: COLOURS.white,
      cursor: 'pointer',
      backgroundColor: COLOURS.azure,
      ':hover': {
        color: COLOURS.azure,
        backgroundColor: COLOURS.white,
      },
    }]

    if (!campaign.loaded || !campaign.campaignTerms.id) {
      return (
        <Spinner />
      )
    }

    const opts = {
      campaign,
      elements: this.getElementsModel(),
      hostBrand: {
        logo: campaign.hostBrandLogo,
      },
      acceptedInvites: campaign.invites.filter(invite => invite.status === 'accepted'),
    }

    const Version = versionMap[opts.elements.landerVersion]

    const prefix = process.env.SENTRY_ENV === 'production' ? 'https://do-not-use-this-link.dojomojo.com' : ''
    const previewLink = `${prefix}/landing/campaign/${campaign.id}?preview=true&cb=${Date.now()}`

    const customFontsStylesheetLink = '//fonts.googleapis.com/css?family=' + this.props.currentBrand.fonts.map(font => font.replace(/\s+/g, '+').replace(/\'/g, '') + ':400,400i,700,700i').join('|')

    const version = opts.elements.landerVersion.split('-').pop()

    return (
      <div>
        <Helmet>
          <link
            rel="stylesheet"
            href={customFontsStylesheetLink}
          />
        </Helmet>
        {
          <EditorMenuContainer
            campaign={campaign}
            opts={opts}
            updateAttr={this.updateElementsAttr}
            updateDataAttr={this.updateElementsDataAttr}
            onEditorMenuOpen={this.onEditorMenuOpen}
            isEditorMenuOpen={isEditorMenuOpen}
            showDesktop={this.showDesktop}
          />
        }
        {
          campaign.viewDesktop
            ? this.renderDesktop(Version, opts)
            : this.renderMobile(Version, opts, version >= 10)
        }
        <AgreeToEditModal
          agreetoLiability={this.agreeToLiabilityAndPersist}
        />
        {
          showWarningMobileEditModal && (
            <WarningMobileEditModal
              close={this.warningMobileEditModalAction('close')}
              confirm={this.warningMobileEditModalAction('confirm')}
            />
          )
        }
      </div>
    )
  }
}

export default injectSheet(styles)(connect(mapState)(EditorMain))
