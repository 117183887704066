import React, { Component } from 'react'
import { string, object, bool } from 'prop-types'
import { BrowserRouter, StaticRouter } from 'react-router-dom'
import App from './App'

class Root extends Component {
  static propTypes = {
    basename: string.isRequired,
    location: string,
    context: object,
    forceMobile: bool,
  }

  static defaultProps = {
    context: {},
  }

  render() {
    const { basename, location, context, forceMobile } = this.props
    const Router = location ? StaticRouter : BrowserRouter
    const supportsHistory = !location && 'pushState' in window.history
    return (
      <Router
        basename={basename}
        forceRefresh={!supportsHistory}
        location={location}
        context={context}
      >
        <App forceMobile={forceMobile} />
      </Router>
    )
  }
}

export default Root
