import React from 'react';
import Text from 'util/components/text';
import Dialog from 'react-toolbox/lib/dialog';
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import _ from 'lodash'

import * as ReactRedux from 'react-redux';

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    brandCampaigns: state.brandCampaigns,
    notEnoughEntriesModal: state.notEnoughEntriesModal
  };
}

class NotEnoughEntriesModal extends React.Component {
  constructor(props) {
    super(props)
  }

  closeModal() {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'notEnoughEntriesModal',
      data: {
        expand: false
      }
    });
  }

  render() {
    const {
      notEnoughEntriesModal,
      brandCampaigns,
      currentBrand
    } = this.props

    let minDelivery = 0

    if (notEnoughEntriesModal.campaignId) {
      let campaign = _.find(brandCampaigns.campaigns, { id: notEnoughEntriesModal.campaignId})

      minDelivery = campaign.partners[currentBrand.id].minimumDelivery
    }

    return (
      <Dialog
        theme={ dialogTheme }
        active={ !!this.props.notEnoughEntriesModal.expand }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title="Entries Not Yet Available"
      >
        <div>
          <div style={{textAlign: 'center'}}>
            <img src="/images/warning-icon.svg"
              style={{
                height: '76px',
                width: '80px',
                marginBottom: '30px'
            }} />
          </div>

          <div style={{
            marginBottom: '30px'
          }}>
            <Text.dojoP styles={[{
              fontSize: '14px'
            }]}>
              { `To ensure an equitable campaign, the Host requires that your promotion drives ` }
            </Text.dojoP>
            <Text.dojoPBold styles={[{
              fontSize: '14px'
            }]}>
              { d3.format(',')(minDelivery) }
            </Text.dojoPBold>
            <Text.dojoP styles={[{
              fontSize: '14px'
            }]}>
              { ` entries before you can access your new emails. We’ll send you an email as soon as you’ve met this threshold!` }
            </Text.dojoP>
          </div>

          <ModalButtonGroup
            canSave
            hideLine={ true }
            confirm={ this.closeModal}
            cancel={ this.hideModal }
            confirmText="Ok, got it"
          />
        </div>
      </Dialog>
    )
  }
};

export default ReactRedux.connect(mapState)(NotEnoughEntriesModal);
