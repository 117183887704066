import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import d3 from 'd3'
import {
  H2, H3, H6, Small,
} from 'visual-components/util/texts'
import ProfileMainCard from 'visual-components/util/profile-main-card'
import Stars from 'visual-components/util/stars'
import ReviewsTheme from 'css/themes/ratings-reviews.css'

const stateToProps = ({ brand }) => ({ brand })

const Separator = ({ large = false }) => (
  <div className={`${ReviewsTheme.separator} ${large ? ReviewsTheme.large : ''}`} />
)

const ContentWrapper = ({ children, title, marginRight = null }) => (
  <div style={{ marginRight }}>
    <H6>
      { title }
    </H6>
    <div className={ReviewsTheme.flex} style={{ marginTop: '8px' }}>
      { children }
    </div>
  </div>
)

const OverallRate = ({ rate }) => (
  <ContentWrapper title="OVERALL RATING" marginRight="48px">
    <H2>
      { d3.format('.2f')(rate) }
    </H2>
    <Stars
      value={rate}
      size={20}
      className={ReviewsTheme.cstmRatingStars}
    />
  </ContentWrapper>
)

const ReviewsCount = ({ count }) => (
  <ContentWrapper title="REVIEWS">
    <H2>{ d3.format(',')(count) }</H2>
  </ContentWrapper>
)

const CardRow = props => {
  const {
    title,
    items,
    extraPadd,
  } = props

  return (
    <div>
      <H6>{ title }</H6>
      <div style={{ marginTop: '16px', marginBottom: extraPadd ? '16px' : null }} className={ReviewsTheme.flex}>
        {
          items.map(item => {
            const { text, value, isRating } = item

            if (isRating && !value) return null

            return (
              <div style={{ width: isRating ? '200px' : '178px' }}>
                <div style={{ marginBottom: '8px' }}>
                  <Small>
                    { text }
                  </Small>
                </div>
                <div className={ReviewsTheme.flex}>
                  <H3>
                    <small style={{ fontWeight: 'bold' }}>
                      { d3.format(isRating ? '.2f' : ',')(value) }
                    </small>
                  </H3>
                  {
                    isRating
                    && (
                      <Stars
                        size={20}
                        className={ReviewsTheme.cstmRatingStars}
                        value={value}
                      />
                    )
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

class OverallReviews extends PureComponent {
  static propTypes = {
    brand: PropTypes.object.isRequired,
  }

  render() {
    const {
      brand,
    } = this.props

    const isSeller = brand.features.includes('mediaMarketSeller')

    const {
      totalCampaigns,
      totalDeals,
      hostRating,
      partnerRating,
      sellerRating,
      totalRating,
      hostRatingCount,
      partnerRatingCount,
      sellerRatingCount,
      totalRatingCount,
    } = brand

    const campaignItems = [
      {
        text: 'Campaigns',
        value: totalCampaigns,
      },
      {
        text: 'Reviews',
        value: hostRatingCount + partnerRatingCount,
      },
      {
        text: 'As a Host',
        value: hostRating,
        isRating: true,
      },
      {
        text: 'As a Partner',
        value: partnerRating,
        isRating: true,
      },
    ]

    const marketplaceItems = [
      {
        text: 'Deals',
        value: totalDeals,
      },
      {
        text: 'Reviews',
        value: sellerRatingCount,
      },
      {
        text: 'As a Seller',
        value: sellerRating,
        isRating: true,
      },
    ]

    return (
      <ProfileMainCard
        titleStyle={{ marginBottom: '16px' }}
        style={{ padding: '16px 20px' }}
        title="Brand Ratings and Reviews"
      >
        <div className={ReviewsTheme.flex}>
          <OverallRate rate={totalRating} />
          <ReviewsCount count={totalRatingCount} />
        </div>
        <Separator large />
        {
          (hostRatingCount || partnerRatingCount)
            ? <CardRow title="CAMPAIGNS" items={campaignItems} />
            : null
        }
        {
          isSeller && !!sellerRatingCount
            ? (
              <React.Fragment>
                { (hostRatingCount || partnerRatingCount) ? <Separator /> : null }
                <CardRow title="MEDIA MARKET" items={marketplaceItems} extraPadd />
              </React.Fragment>
            ) : null
        }
      </ProfileMainCard>
    )
  }
}

export default connect(stateToProps)(OverallReviews)
