import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { H4 } from 'visual-components/util/texts'
import ImageDropzoneUploader from 'util/components/image-dropzone-uploader'

import colors from 'util/colours'

const Checkmark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#14CBA8" />
      <path fill="#FFF" fillRule="nonzero" d="M9.9 14.54L6.96 11.6l-.98.98L9.9 16.5l8.4-8.4-.98-.98z" />
    </g>
  </svg>
)

const styles = {
  wrapper: {
    padding: '24px 8px',
    flex: 1,
  },
  imagesPreview: {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    gridAutoRows: '128px',
    gridColumnGap: '4px',
    gridRowGap: '4px',
    margin: '20px 0 0',
    height: 344,
    overflowY: 'auto',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
  selected: {
    position: 'relative',
    border: `4px solid ${colors.seaGreen}`,
    borderRadius: 2,
  },
  checkmark: {
    position: 'absolute',
    top: 4,
    left: 4,
    height: 16,
    width: 16,
  },
}

const UPLOAD_TEXT = 'Add or select an image below. Files larger than 450KB will automatically be reduced and optimized.'

function UploadImageSection(props) {
  const { onMediaSelect, classes: css } = props
  const [images, setImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)

  const onSelect = idx => {
    const selected = selectedImage !== idx ? idx : null
    setSelectedImage(selected)
    onMediaSelect(selected !== null ? images[selected] : null)
  }

  const onImageReject = () => toastr.error('Some of your files couldn’t be uploaded because they exceed the maximum file size (450KB).')

  return (
    <section className={css.wrapper}>
      <H4 multiline>{UPLOAD_TEXT}</H4>
      <div className={css.imagesPreview}>
        <ImageDropzoneUploader
          onImageReject={onImageReject}
          onImageUpload={img => setImages(imgs => [...imgs, img])}
          // maxSize={null}
        />
        {images.length
          ? images.map(({ image, img64 }, idx) => (
            <div
              key={image.lastModified}
              className={cn(css.preview, { [css.selected]: selectedImage === idx })}
              onClick={() => onSelect(idx)}
            >
              <img src={img64} alt="preview" />
              {selectedImage === idx && <Checkmark className={css.checkmark} />}
            </div>
          ))
          : null}
      </div>
    </section>
  )
}

UploadImageSection.propTypes = {
  onMediaSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(UploadImageSection))
