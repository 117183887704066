import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import SearchInput from 'visual-components/util/inputs/search-input'
import Loader from 'util/components/loader'
import useImageSearch from 'hooks/useImageSearch'

import colors from 'util/colours'

const Checkmark = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#14CBA8" />
      <path fill="#FFF" fillRule="nonzero" d="M9.9 14.54L6.96 11.6l-.98.98L9.9 16.5l8.4-8.4-.98-.98z" />
    </g>
  </svg>
)

const styles = {
  wrapper: {
    padding: '24px 8px',
    flex: 1,
  },
  subtext: {
    textAlign: 'right',
    marginTop: 12,
    '& img': {
      height: 16,
      marginRight: -8,
    },
  },
  imagesPreview: {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    gridAutoRows: '128px',
    gridColumnGap: '4px',
    gridRowGap: '4px',
    overflowY: 'auto',
    position: 'relative',
    margin: '8px 0 0',
    height: 344,
  },
  preview: {
    backgroundColor: colors.silver,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
  selected: {
    position: 'relative',
    border: `4px solid ${colors.seaGreen}`,
    borderRadius: 2,
  },
  checkmark: {
    position: 'absolute',
    top: 4,
    left: 4,
    height: 16,
    width: 16,
  },
  spinner: props => {
    if (props.all) return null

    return {
      position: 'relative',
      gridColumn: '1/4',
    }
  },
}

const POWERED_BY = (
  <a href="https://giphy.com/" target="_blank" rel="noopener noreferrer">
    <img src="/images/powered-by-giphy.png" alt="giphy" />
  </a>
)

const stateToProps = ({ currentBrand }) => ({ currentBrand })

function SearchGIFSection(props) {
  const {
    onMediaSelect, classes: css, onQueryChange, onScroll, images: gifs, loading, query,
  } = props

  const [selectedGIF, setSelectedGIF] = useState(null)

  const onType = value => {
    setSelectedGIF(null)
    onQueryChange(value)
  }

  const onSelect = gif => {
    const selected = !selectedGIF || selectedGIF.id !== gif.id ? gif : null
    setSelectedGIF(selected)
    onMediaSelect(selected)
  }

  return (
    <section className={css.wrapper}>
      <div>
        <SearchInput className="smallInput" value={query} onChange={onType} placeholder="Search GIFs" />
        <div className={css.subtext}>{POWERED_BY}</div>
      </div>
      <div onScroll={onScroll} className={css.imagesPreview}>
        {gifs.length > 0
          && gifs.map(gif => {
            const selected = selectedGIF && selectedGIF.id === gif.id
            return (
              <div
                key={gif.id}
                className={cn(css.preview, { [css.selected]: selected })}
                onClick={() => onSelect(gif)}
              >
                <img loading="lazy" src={gif.src} alt="preview" />
                {selected && <Checkmark className={css.checkmark} />}
              </div>
            )
          })}
        {/* TODO: Change this */}
        {loading && (
          <div className={css.spinner}>
            <Loader />
          </div>
        )}
      </div>
    </section>
  )
}

SearchGIFSection.propTypes = {
  onMediaSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
}

const StyledSearchGIFSection = injectCSS(styles)(SearchGIFSection)

function SearchGIFSectionWrapper(props) {
  const { currentBrand } = props
  const hookProps = useImageSearch({ headers: { brand: currentBrand.id } }, true)

  return <StyledSearchGIFSection {...{ ...props, ...hookProps }} all={!hookProps.images.length} />
}

SearchGIFSectionWrapper.propTypes = {
  currentBrand: PropTypes.object.isRequired,
}

export default React.memo(connect(stateToProps)(SearchGIFSectionWrapper))
