import React from 'react'
import { object } from 'prop-types'
import { Dialog } from 'react-toolbox/lib/dialog'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import IntegrationConnect from './integration-connect'

class IntegrationModal extends React.Component {
  static propTypes = {
    integration: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  render() {
    const {
      integration,
      utilityFunctions,
    } = this.props

    return (
      <Dialog
        theme={DialogTheme}
        active={integration.key}
        onEscKeyDown={utilityFunctions.closeModal}
        onOverlayClick={utilityFunctions.closeModal}
      >
        <div>
          <img
            onClick={utilityFunctions.closeModal}
            className={DialogTheme.closeImage}
            src="/images/icons/close.svg"
          />

          <IntegrationConnect
            {...this.props}
            utilityFunctions={utilityFunctions}
          />
        </div>
      </Dialog>
    )
  }
}

export default IntegrationModal
