import React, { PureComponent } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { object, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { relativeDate } from 'util/relativeDate'
import { Card } from 'react-toolbox/lib/card'
import moment from 'moment-timezone'
import d3 from 'd3'
import DealCardTheme from 'css/themes/media-market/dealCard.css'
import CardTheme from 'css/themes/card.css'
import Badge from 'visual-components/util/badge'
import classNames from 'classnames'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import {
  H3,
  H4,
  H5,
  Small,
  P,
} from 'visual-components/util/texts'

import * as DealActions from 'actions/deal-actions'

const dispatchToProps = dispatch => ({ dealActions: bindActionCreators(DealActions, dispatch) })

const stateToProps = ({ deals }) => ({ deals })

class DealCard extends PureComponent {
  static propTypes = {
    deal: object.isRequired,
    deals: object.isRequired,
    dealActions: object.isRequired,
    statusMap: object.isRequired,
    collaborateView: bool,
    showEdit: bool,
    currentBrandId: number.isRequired,
    history: object.isRequired,
  }

  static defaultProps = {
    collaborateView: false,
    showEdit: false,
  }

  getCardTitle = () => {
    const {
      deal: {
        label,
        status,
      },
      collaborateView,
    } = this.props

    if (collaborateView) {
      return `Latest ${status === 'request' ? 'Proposal' : 'Offer'}`
    }

    return label && label.length > 25 ? `${label.slice(0, 25)}...` : label
  }

  onChangeDateClick = () => {
    const {
      dealActions,
    } = this.props

    dealActions.showChangeDatesModal()
  }

  static formatBudget(min, max) {
    if (min >= 10000) {
      return '$10,000+'
    }
    if (min === 0) {
      return 'N/A'
    }

    return `${d3.format('$,')(min)} - ${d3.format('$,')(max)}`
  }

  static formatDate(date) {
    return moment(date).format('MMM DD, YYYY')
  }

  renderUnreadCount() {
    const { deal: { unread_count }, collaborateView } = this.props
    if (collaborateView || !unread_count || !unread_count.all) return null

    return (
      <div style={{ position: 'relative' }}>
        <div className={CardTheme.unreadCount}>
          {unread_count.all}
        </div>
      </div>
    )
  }

  renderDateRange = () => {
    const {
      deal: {
        request,
        start_date,
        end_date,
      },
    } = this.props

    if (request && request.status !== 'accepted') {
      return request.start_date
        ? `${DealCard.formatDate(request.start_date)} - ${DealCard.formatDate(request.end_date)}`
        : 'N/A'
    }

    return `${DealCard.formatDate(start_date)} - ${DealCard.formatDate(end_date)}`
  }

  renderCardBody = () => {
    const {
      deal: {
        buying_brand,
        buying_brand_id,
        cost_type,
        expected_views,
        max_quantity,
        min_quantity,
        pretty_type,
        price,
        deposit_percentage,
        request,
        selling_brand,
        selling_brand_id,
        status,
      },
      currentBrandId,
    } = this.props

    const isSellingBrand = currentBrandId === selling_brand_id

    const linkId = isSellingBrand
      ? buying_brand_id
      : selling_brand_id

    const displayedName = isSellingBrand
      ? buying_brand.accountname
      : selling_brand.accountname

    const isRequest = status === 'request' || (request && request.status === 'pending')

    return (
      <React.Fragment>
        <div className={DealCardTheme.brandDealInfo}>
          <Link to={`/explore/brands/${linkId}`}>
            <Small azure>
              <em>
                {displayedName.length > 30 ? `${displayedName.slice(0, 30).trimEnd()}...` : displayedName}
              </em>
            </Small>
          </Link>
          {
            pretty_type
            && (
              <div className={DealCardTheme.dealType}>
                <Small>
                  <em>{`- ${pretty_type}`}</em>
                </Small>
              </div>
            )
          }
        </div>

        <div className={`${DealCardTheme.displayFlex} ${DealCardTheme.costDateGroup}`}>
          <div
            className={classNames({
              [DealCardTheme.displayFlex]: true,
              [DealCardTheme.costType]: true,
              [DealCardTheme.date]: true,
              [DealCardTheme.longDate]: ['request'].includes(status),
            })}
          >
            <img src="images/clock-icon@2x.png" className={DealCardTheme.icon} />
            <div className={DealCardTheme.costDateText}>
              <P>
                {this.renderDateRange()}
                {isRequest && !!request && !!request.is_date_flexible && ', Flexible Dates'}
              </P>
            </div>
          </div>

          {
            !['request'].includes(status)
            && !isRequest
            && (
              <div style={{ width: '76px', marginLeft: '6px' }} className={`${DealCardTheme.displayFlex} ${DealCardTheme.costType}`}>
                <img src="images/dollar-bill@2x.png" className={DealCardTheme.icon} />
                <div className={DealCardTheme.costDateText}>
                  <P>{cost_type === 'Fixed' ? 'Flat Rate' : cost_type}</P>
                </div>
              </div>
            )
          }
        </div>

        { cost_type === 'CPC' && !isRequest
          && (
            <div className={`${DealCardTheme.statsGroup} ${DealCardTheme.displayFlex}`}>
              <div className={DealCardTheme.cpcStat}>
                <Small>CPC</Small>
                <div className={DealCardTheme.stats}>
                  <Small><em>{d3.format('$,.2f')(price)}</em></Small>
                </div>
              </div>

              <div className={DealCardTheme.maxValueStat}>
                <Small>Max Value</Small>
                <div className={DealCardTheme.stats}>
                  <Small><em>{d3.format('$,.2f')(price * max_quantity)}</em></Small>
                </div>
              </div>

              <div>
                <Small>Deal Click Range</Small>
                <div className={DealCardTheme.stats}>
                  <Small>
                    <em>
                      {`${d3.format(',')(min_quantity)} - ${d3.format(',')(max_quantity)}`}
                    </em>
                  </Small>
                </div>
              </div>
            </div>
          )
        }

        { cost_type === 'Fixed'
          && (
            <div className={`${DealCardTheme.statsGroup} ${DealCardTheme.displayFlex}`}>
              <div className={DealCardTheme.expectedViewsStat}>
                <Small>Expected Reach</Small>
                <div className={DealCardTheme.stats}>
                  <Small><em>{d3.format(',')(expected_views)}</em></Small>
                </div>
              </div>

              <div className={DealCardTheme.expectedViewsStat}>
                <Small>Total Price</Small>
                <div className={DealCardTheme.stats}>
                  <Small><em>{d3.format('$,.2f')(price)}</em></Small>
                </div>
              </div>

              <div>
                <Small>Deposit %</Small>
                <div className={DealCardTheme.stats}>
                  <Small>
                    <em>
                      {`${deposit_percentage}%`}
                    </em>
                  </Small>
                </div>
              </div>
            </div>
          )
        }

        {
          cost_type === 'CPC' && isRequest
          && (
            <div className={`${DealCardTheme.statsGroup}`} style={{ marginTop: 12 }}>
              <Small>Budget Range</Small>
              <div className={DealCardTheme.stats}>
                <H3>{ DealCard.formatBudget(request.budget_min, request.budget_max) }</H3>
              </div>
            </div>
          )
        }
      </React.Fragment>
    )
  }

  renderRequestBody = () => {
    const {
      deal: {
        id,
        selling_brand_id,
      },
      currentBrandId,
    } = this.props

    const isSellingBrand = currentBrandId === selling_brand_id

    const cardStyle = DealCardTheme[`collaborateRequest${isSellingBrand ? 'Seller' : 'Buyer'}`]

    return (
      <div className={classNames(DealCardTheme.collaborateRequest, cardStyle)}>
        <Small
          cloudGrey
          multiline
        >
          <i>
            {
              isSellingBrand
                ? 'Create a proposal or ask questions in the chat to get started with your Media Deal.'
                : 'The seller hasn\'t sent you a proposal, please use the chat to keep the conversation going.'
            }
          </i>
        </Small>
        {
          isSellingBrand
          && (
            <div className={DealCardTheme.buttonContainer}>
              <Link to={`/partnerships/deal/${id}/edit`}>
                <Button
                  label="Create Proposal"
                  className={classNames(ButtonTheme.whiteButton, ButtonTheme.shortButton)}
                  theme={ButtonTheme}
                  raised
                />
              </Link>
            </div>
          )
        }
      </div>
    )
  }

  render() {
    const {
      deal: {
        currentProposal,
        status,
        id,
        selling_brand_id,
        end_date,
      },
      deals: {
        can_change_dates,
      },
      statusMap,
      collaborateView,
      history,
      currentBrandId,
      showEdit,
    } = this.props

    const isSeller = selling_brand_id === currentBrandId

    const showChangeDatesBtn = isSeller
      && ['accepted', 'live'].includes(status)
      && moment(end_date).isAfter()
      && can_change_dates

    return (
      <Card className={DealCardTheme.container}>
        {this.renderUnreadCount()}
        <div
          className={DealCardTheme.main}
          style={{
            cursor: !collaborateView ? 'pointer' : 'auto',
            ...(!collaborateView ? {
              // Unset what's already there
              margin: 0,
              height: 'unset',

              // Then set what we need
              padding: '16px 0 12px 20px',
              minHeight: 142,
            } : {}),
          }}
          onClick={!collaborateView ? () => {
            history.push(`/partnerships/deal/${id}/collaborate`)
          } : null}
        >
          <div className={DealCardTheme.statusBadge}>
            {
              collaborateView
                && status === 'pending'
                ? (
                  <Small micro multiline cloudGrey>
                    <i>
                      {`Last updated ${relativeDate(currentProposal.created_at)}`}
                    </i>
                  </Small>
                )
                : <Badge label={statusMap[status].label} color={statusMap[status].color} />
            }
          </div>


          <H4 coral={collaborateView}>
            <em>
              {this.getCardTitle()}
            </em>
          </H4>

          {
            status === 'request'
            && collaborateView
              ? this.renderRequestBody()
              : this.renderCardBody()
          }
        </div>
        {
          showEdit
            ? (
              <React.Fragment>
                <div className={DealCardTheme.separator} />
                <div className={DealCardTheme.editDateContainer}>
                  {
                    showChangeDatesBtn
                      ? (
                        <div onClick={this.onChangeDateClick}>
                          <H5 azure>
                            Edit Dates
                          </H5>
                        </div>
                      ) : null
                  }
                </div>
              </React.Fragment>
            ) : null
        }
      </Card>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(DealCard))
