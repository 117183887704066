import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from 'react-toolbox/lib/button';

import theme from 'css/themes/mobile/home/home.css';
import ButtonTheme from 'css/themes/Buttons.css'

class StartGrowingYourList extends Component {
  clickButton = () => {
    const { history } = this.props
    window.location.href = '/app/register'
  }

  render() {
    return(
      <div className={ theme.startGrowing }>
        <p className={ theme.startGrowingText }>
          Start Growing Your List
        </p>
        <Button
          onClick={ this.clickButton }
          label={ 'Start Free Trial' }
          className={ ButtonTheme.greenButton }
          theme={ ButtonTheme }
          raised
        />
      </div>
    )
  }
}

export default withRouter(StartGrowingYourList);
