import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom'
import { func, object, string } from 'prop-types'
import moment from 'moment-timezone'
import COLOURS from 'util/colours'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import SideNav from 'visual-components/sideBars/side-nav'
import Sweepstakes from 'visual-components/myPartnerships/Sweepstakes'
import CampaignOverview from 'visual-components/myPartnerships/CampaignOverview'
import MyOpportunities from 'visual-components/myPartnerships/MyOpportunities'
import MediaMarket from 'visual-components/myPartnerships/MediaMarket'
import MediaMarketDealOverview from 'visual-components/myPartnerships/MediaMarketDealOverview'
import CreateEditNewMedia from 'visual-components/mediaMarket/createNewMedia/CreateNewMedia'
import EditRequest from 'visual-components/mediaMarket/editRequest/EditRequest'
import ConfirmMedia from 'visual-components/mediaMarket/confirmMedia/ConfirmMedia'
import MyFavorites from 'visual-components/myPartnerships/MyFavorites'
import CampaignPopout from 'components/calendar/CampaignPopout'
import TaskPopout from 'components/calendar/TaskPopout'
import ShowMorePopout from 'components/calendar/ShowMorePopout'
import SubscribeToCalendar from 'components/calendar/SubscribeToCalendar'
import ExportTask from 'components/calendar/ExportTask'
import ConfirmDeleteModal from 'components/campaigns/list/confirm-delete-modal'
import NoOfficialRulesModal from 'visual-components/calendar/NoOfficialRulesModal'
import IncompleteRulesDialog from 'visual-components/myPartnerships/IncompleteRulesDialog'

import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher'

import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'

const mapStateToProps = ({ currentBrand, deals: { deals } }) => {
  const unreadDealCounts = deals.reduce((counts, deal) => {
    if (!deal.unread_count || deal.unread_count.all === 0) return counts

    if (['pending', 'request'].includes(deal.status)) counts.inDiscussion++
    else if (moment().isSameOrBefore(deal.end_date)) counts.current++
    else counts.past++

    return counts
  }, { inDiscussion: 0, current: 0, past: 0 })

  return { currentBrand, unreadDealCounts }
}

// TODO: Check why this is re-rendering too many times
class MyPartnerships extends PureComponent {
  static propTypes = {
    history: object.isRequired,
    basename: string.isRequired,
    match: object.isRequired,
    location: object.isRequired,
    currentBrand: object.isRequired,
    unreadDealCounts: object.isRequired,
  }

  static childContextTypes = {
    editCampaign: func,
    endCampaign: func,
    launchCampaign: func,
    duplicateCampaign: func,
    deleteCampaign: func,
    downloadEntries: func,
    managePartners: func,
    viewAnalytics: func,
  }

  getChildContext() {
    return {
      editCampaign: this.editCampaign,
      endCampaign: this.endCampaign,
      launchCampaign: this.launchCampaign,
      duplicateCampaign: this.duplicateCampaign,
      deleteCampaign: this.deleteCampaign,
      downloadEntries: this.downloadEntries,
      managePartners: this.managePartners,
      viewAnalytics: this.viewAnalytics,
    }
  }

  createCampaign = () => {
    const { history } = this.props

    PricingDispatcher.check('sweepstakes.partnership.create', () => {})

    EventTrackingDispatcher.track('appPartnerships_partnership_createPartnership_createPartnershipHostGiveaway')

    history.push('/new-campaign')
  }

  editCampaign = campaignId => {
    const { history } = this.props
    history.push(`/builder/${campaignId}/setup`)
  }

  signAgreement = partnershipId => {
    const { history } = this.props

    history.push(`/partnership-agreement/${partnershipId}`)
  }

  endCampaign = () => {
    CampaignDispatcher.endCampaign()
  }

  launchCampaign = (id, isSolo) => {
    CampaignDispatcher.startCampaign(id, isSolo)
  }

  duplicateCampaign = id => {
    CampaignDispatcher.duplicateCampaign(id)
  }

  deleteCampaign = id => {
    store.dispatch({
      model: 'upcomingCampaigns',
      type: 'SET_CAMPAIGN_DELETING',
      data: {
        id,
      },
    })
  }

  downloadEntries = (id, location, filterWebbula) => {
    ConfirmationDispatcher.checkDownloadEntries(id, location, filterWebbula)
  }

  viewAnalytics = campaignId => {
    const { history } = this.props
    history.push(`/analytics/campaigns/${campaignId}`)
  }

  managePartners = id => {
    const {
      history,
    } = this.props

    history.push(`/builder/${id}/partner`)
  }

  render() {
    const {
      basename,
      match: {
        path: prefix,
      },
      location: {
        pathname,
      },
      currentBrand: {
        features,
      },
    } = this.props

    const routes = [{
      label: 'Opportunities',
      to: '/opportunities',
    },
    ]

    const sortByOptions = [
      {
        value: 'startDate',
        label: 'Start Date',
      },
      {
        value: 'name',
        label: 'A-Z',
      },
      {
        value: 'emails',
        label: 'New Emails',
      },
      {
        value: 'entries',
        label: '# of Entries',
      },
      {
        value: 'entryRate',
        label: 'Entry Rate',
      },
    ]

    const SweepstakesComponent = () => (
      <Sweepstakes
        sortByOptions={sortByOptions}
        createCampaign={this.createCampaign}
        confirmDeleteCampaign={this.confirmDeleteCampaign}
        closeModal={this.closeModal}
        signAgreement={this.signAgreement}
      />
    )
    const isOverview = pathname.includes('partnerships/campaign/')
      || pathname.includes('partnerships/deal/')
      || pathname.includes('partnerships/request/')

    const postfixes = [
      'all',
      'current',
      'in-discussion',
      'past',
      'media-market/all',
      'media-market/current',
      'media-market/in-discussion',
      'media-market/past',
      'opportunities',
      'favorites',
      'dashboard',
      'accept-terms',
    ].join('|')

    return (
      <div style={{ width: '100%', background: COLOURS.cream }}>
        <InternalContentContainer width={isOverview ? '100%' : '1192px'}>
          <Route
            exact
            path={`${prefix}/(${postfixes})`}
            render={routeProps => (
              <SideNav
                width="240px"
                routes={routes}
                prefix={prefix}
                basename={basename}
                {...routeProps}
              />
            )}
          />

          <div style={{ width: '100%' }}>
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/all`} />
              <Redirect path={`${prefix}/dashboard`} to="/calendar" />
              <Route path={`${prefix}/all`} render={SweepstakesComponent} />
              <Route path={`${prefix}/current`} render={SweepstakesComponent} />
              <Route path={`${prefix}/in-discussion`} render={SweepstakesComponent} />
              <Route path={`${prefix}/past`} render={SweepstakesComponent} />
              <Route path={`${prefix}/media-market/:subtype?`} component={MediaMarket} />
              <Route path={`${prefix}/campaign/:campaignId`} render={CampaignOverview} />
              <Route path={`${prefix}/opportunities`} component={MyOpportunities} />

              {/*
                Under construction 2/27/2020
              <Route path={`${prefix}/deal/new/:brandId?`} component={CreateEditNewMedia} />
              */}
              <Redirect from={`${prefix}/deal/new/:brandId?`} to={`${prefix}/media-market/all`} />

              <Route path={`${prefix}/deal/:dealId/edit`} component={CreateEditNewMedia} />
              <Route path={`${prefix}/deal/:dealId?/confirm/:type`} component={ConfirmMedia} />
              <Route path={`${prefix}/deal/:dealId`} component={MediaMarketDealOverview} />
              <Route path={`${prefix}/favorites`} component={MyFavorites} />

              {/*
                Under construction 2/27/2020
              <Route path={`${prefix}/request/:brandId?/new`} component={EditRequest} />
              */}
              <Redirect from={`${prefix}/request/:brandId?/new`} to={`${prefix}/media-market/all`} />

              <Route path={`${prefix}/request/:requestId/edit`} component={EditRequest} />
              <Redirect to="/404" />
            </Switch>
          </div>
        </InternalContentContainer>
        <CampaignPopout />
        <TaskPopout />
        <ShowMorePopout />
        <SubscribeToCalendar />
        <ExportTask />
        <ConfirmDeleteModal />
        <NoOfficialRulesModal />
        <IncompleteRulesDialog />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(MyPartnerships))
