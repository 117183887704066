import React, { PureComponent as Component } from 'react'
import {
  bool,
  string,
  instanceOf,
  number,
} from 'prop-types'
import { Link } from 'react-router-dom'
import { H1, H5, Small } from 'visual-components/util/texts'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import moment from 'moment-timezone'
import theme from 'css/themes/partnership-analytics-card.css'
import { makeNumbersPretty } from 'util/makeNumbersPretty'
import StatsDisplay from './stats-display'
import EntryRateGraph from './entry-rate-graph'
import NewToFileDisplay from '../util/new-to-file-display'

class PartnershipAnalyticsCard extends Component {
  static propTypes = {
    partnershipBrandName: string.isRequired,
    lastPartnershipDate: instanceOf(Date).isRequired,
    campaigns: number,
    avgEntries: number,
    listSize: number,
    visitsDriven: number,
    entriesDriven: number,
    newToFile: bool,
    partnerBrandId: number.isRequired,
  }

  static defaultProps = {
    avgEntries: null,
    listSize: null,
    campaigns: null,
    entriesDriven: null,
    visitsDriven: null,
    newToFile: false,
  }

  render() {
    const {
      partnershipBrandName,
      lastPartnershipDate,
      avgEntries,
      listSize,
      campaigns,
      entriesDriven,
      visitsDriven,
      newToFile,
      partnerBrandId,
    } = this.props

    return (
      <Card className={theme.card}>
        <Link to={`/analytics/partnerships/${partnerBrandId}`}>
          <Grid>
            <Row>
              <Col style={{ width: '216px' }}>
                <H5 overflowEllipsis>
                  { partnershipBrandName }
                </H5>
              </Col>
              <Col style={{ width: '112px' }}>
                <Small right>
                  { `last partnered ${moment(lastPartnershipDate).format('M/D/YY')}` }
                </Small>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ display: 'inline-block', paddingTop: '6px' }}>
                  <H1 azure>
                    { makeNumbersPretty(avgEntries) }
                  </H1>
                </div>
                <div style={{ display: 'inline-block', paddingLeft: '4px' }}>
                  <Small>
                    avg entries
                  </Small>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: '16px' }}>
              <Col style={{ width: '235px' }}>
                <Small>
                  <em>
                    PARTNER STATS
                  </em>
                </Small>
                <StatsDisplay listSize={listSize} campaigns={campaigns} entriesDriven={entriesDriven} />
                <div style={{ paddingTop: '22px' }}>
                  <EntryRateGraph entriesDriven={entriesDriven} visitsDriven={visitsDriven} />
                </div>
              </Col>
              <Col style={{ width: '70px', marginLeft: '22px' }}>
                <NewToFileDisplay newToFile={newToFile} live={false} totalEntries={entriesDriven} />
              </Col>
            </Row>
          </Grid>
        </Link>
      </Card>
    )
  }
}

export default PartnershipAnalyticsCard
