import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button } from 'react-toolbox/lib/button'
import { Input } from 'react-toolbox/lib/input'

import Label from 'visual-components/util/inputs/label'
import Validator from 'util/form-validator'
import FieldDispatcher from 'dispatchers/field-dispatcher'
import { Micro } from 'visual-components/util/texts'

import inputTheme from 'css/themes/Input.css'

const ValidatorModel = function () {
  return this
}

ValidatorModel.prototype = _.extend({}, Validator, ValidatorModel.prototype)

class Field extends Component {
  state = { blurred: true }

  validator = new ValidatorModel()

  validate = _.debounce((value, callback = function () {}) => {
    const {
      dataModel,
      attrName,
      validations,
      formModel,
      characterLimit,
    } = this.props

    const validationsWithMaxLength = characterLimit
      ? [...(validations || []), Validator.maxLength(characterLimit)]
      : validations

    this.validator.validate(value, dataModel, validationsWithMaxLength, data => {
      let dataWithValidity = { [attrName]: data }
      const { id } = this.props

      if (id) {
        dataWithValidity = {
          errors: {
            [attrName]: data,
          },
        }
      }

      this.dispatchUpdateAttr(formModel.rid, dataWithValidity)
      callback()
    })
  }, this.props.validateTimeout || 800)

  blur = e => {
    const {
      attrName, endpoint, formModel, dataModel, blur,
    } = this.props

    if (blur) {
      blur(e)
    }

    setTimeout(() => {
      this.setState({ blurred: true })
    }, 10)

    this.validate(this.valueFromDataModel(), this.persistIfValid)
  }

  focus = () => {
    this.setState({ blurred: false })
  }

  valueFromDataModel = () => {
    const { attrName, dataModel, id } = this.props
    const value = id ? dataModel.items[id][attrName] : dataModel[attrName]

    if (value === null) {
      return ''
    }

    return value
  }

  valueFromFormModel = () => {
    const { attrName, formModel, id } = this.props

    if (id) {
      const errors = formModel.items[id].errors || {}
      return errors[attrName]
    }

    return formModel[attrName]
  }

  updateValue = value => {
    const {
      attrName,
      dataModel,
      updateAttr,
      index,
      onChange,
    } = this.props
    const data = { [attrName]: onChange ? onChange(value) : value }

    this.dispatchUpdateAttr(dataModel.rid, data)
    this.validate(value)

    if (updateAttr) {
      updateAttr(attrName, value, index)
    }
  }

  dispatchUpdateAttr = (modelId, data) => {
    const { id, disabledUpdateAttr } = this.props

    if (id) {
      data.id = id
    }

    if (!disabledUpdateAttr) {
      FieldDispatcher.updateAttr(modelId, data)
    }
  }

  persistIfValid = () => {
    const {
      attrName, endpoint, formModel, dataModel,
    } = this.props
    const data = { [attrName]: this.valueFromDataModel() }
    const formModelData = this.valueFromFormModel()

    if (endpoint && formModelData && !formModelData.errors.length) {
      this.persist()
    }
  }

  persist = () => {
    const {
      id,
      attrName,
      endpoint,
      formModel,
      // dataModel,
      hideToastr,
      ableToPersist,
    } = this.props
    const data = { [attrName]: this.valueFromDataModel() }

    if (id) {
      data.id = id
    }

    if (endpoint && ableToPersist()) {
      FieldDispatcher.persist({
        endpoint,
        model: formModel,
        data,
        attrName,
        hideToastr,
      })
    }
  }

  render() {
    const {
      label,
      sublabel,
      type,
      multiline,
      rows,
      placeholder,
      tooltip,
      editable,
      disabled,
      readonly,
      className,
      style,
      characterLimit,
      outerClassName,
      canRemove,
      remove,
      index,
      labelStyle,
      sublabelStyle,
    } = this.props

    const { blurred } = this.state

    const formModelData = this.valueFromFormModel()
    const errors = (formModelData ? formModelData.errors : []) || []
    const error_messages = _.map(errors, text => text)
    const show_messages = this.state.blurred && error_messages.length

    const charactersRemaining = characterLimit - (
      this.valueFromDataModel() ? this.valueFromDataModel().length : 0
    )

    const overLimit = charactersRemaining < 0
    const classNames = `${className} ${remove ? inputTheme.withTrashcan : ''}`

    // Modify theme to hide counter if there is a character limit
    const { counter, ...other } = inputTheme // counter is used to hide the React Toolbox counter
    const modifiedTheme = characterLimit ? inputTheme : { ...other }

    return (
      <div className={outerClassName} style={{ position: 'relative' }}>
        {
          label
            ? <Label style={labelStyle} label={label} sublabel={sublabel} sublabelStyle={sublabelStyle} tooltip={tooltip} />
            : null
        }
        <Input
          type={type}
          multiline={multiline || false}
          rows={rows}
          hint={placeholder}
          value={this.valueFromDataModel()}
          error={show_messages ? error_messages : false}
          disabled={disabled || editable === false}
          readOnly={readonly}
          onBlur={this.blur}
          onFocus={this.focus}
          onChange={e => this.updateValue(e)}
          theme={modifiedTheme}
          className={classNames}
          style={style}
          maxLength={characterLimit}
        />
        {
          canRemove
          && !disabled
          && editable
            ? (
              <div className={inputTheme.trashIcon} onClick={() => remove(index)}>
                <img src="/images/icons/trash.svg" />
              </div>
            )
            : null
        }
        {
          characterLimit
          && (
            <div style={{ position: 'relative' }}>
              <span
                style={{
                  float: 'right',
                  position: 'absolute',
                  bottom: '18px',
                  width: '120px',
                  right: '0px',
                  textAlign: 'right',
                }}
              >
                <Micro alertRed={overLimit}>
                  { `${Math.abs(charactersRemaining)} ${overLimit ? 'characters over limit' : 'characters remaining'}` }
                </Micro>
              </span>
            </div>
          )
        }
      </div>
    )
  }
}

Field.propTypes = {
  updateValue: PropTypes.func,
  attrName: PropTypes.string,
  customInputStyle: PropTypes.object,
  dataModel: PropTypes.object,
  formModel: PropTypes.object,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  updateAttr: PropTypes.func,
  labelStyle: PropTypes.object,
  sublabelStyle: PropTypes.object,
  hideToastr: PropTypes.bool,
}

Field.defaultProps = {
  updateValue(value) {
    const { attrName, dataModel } = this.props
    const data = { [attrName]: value }
    FieldDispatcher.updateAttr(dataModel.rid, data)
    this.validate(this.fromDisplay(value))
  },
  hideToastr: true,
  ableToPersist: () => true,
}

export default Field
