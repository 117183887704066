import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { H3 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import SplitTrafficStatusCol from './SplitTrafficStatusCol'
import SplitTrafficActionsCol from './SplitTrafficActionsCol'
import cn from 'classnames'

const styles = {
  wrapper: {
    '& td': {
      padding: '12px 0',
      '&:nth-child(1)': {
        width: '30%',
      },
      '&:nth-child(2)': {
        width: '38%',
      },
      '&:nth-child(3)': {
        width: '32%',
      },
    },
    '&:first-child td': {
      paddingTop: 24,
    },
    '&:last-child td': {
      paddingBottom: 24,
    },
  },
  col: {
    display: 'flex',
    alignItems: 'center',
  },
  logoCol: {
    width: 48,
  },
  logo: {
    width: 48,
    height: 48,
    border: `1px solid ${COLOURS.foggy}`,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    marginRight: 24,
  },
}

function SplitTrafficPartner(props) {
  const {
    classes: css,
    partner,
    isSaving,
    onSave,
    onPercentChange,
    onClickInvite,
    onClickMessage,
    campaignUid,
    campaignStatus,
  } = props

  return (
    <tr className={css.wrapper}>
      <td>
        <div className={css.col}>
          <div className={css.logo}>
            <img src={partner.logo} />
          </div>
          <H3>
            <small>
              {partner.accountname}
            </small>
          </H3>
        </div>
      </td>
      <td>
        <div className={cn(css.col, css.logoCol)}>
          <SplitTrafficStatusCol
            partner={partner}
            onClickMessage={onClickMessage}
            campaignUid={campaignUid}
            campaignStatus={campaignStatus}
          />
        </div>
      </td>
      <td>
        <div className={css.col}>
          <SplitTrafficActionsCol
            onChange={val => onPercentChange(partner.id, val)}
            onSave={onSave}
            isSaving={isSaving}
            partner={partner}
            onClickInvite={onClickInvite}
            campaignStatus={campaignStatus}
          />
        </div>
      </td>
    </tr>
  )
}

SplitTrafficPartner.propTypes = {
  classes: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onPercentChange: PropTypes.func.isRequired,
  onClickInvite: PropTypes.func.isRequired,
  onClickMessage: PropTypes.func.isRequired,
  campaignUid: PropTypes.string.isRequired,
  campaignStatus: PropTypes.string.isRequired,
}

export default React.memo(injectCSS(styles)(SplitTrafficPartner))
