import React from 'react'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import SecondaryButton from 'util/components/buttons/secondary-button'

import {
  H3, H4,
} from 'visual-components/util/texts'

class DojoHost extends React.Component {
  render() {
    return (
      <div>
        {/* text */}
        <div className="row text-center">
          <div className="col-sm-12">
            <H3>Automatic Hosting with DojoMojo</H3>
          </div>
        </div>
        <br />
        <div className="row text-left">
          <div className="col-sm-12">
            <H4 multiline>
              Don’t have time to set up your own web address? Let us do the legwork. Once you click
              activate, you’ll be ready to go!
            </H4>
          </div>
        </div>
        <br />
        <br />
        <div className="row text-left">
          <div className="col-sm-12">
            <H4 multiline>
              Note that entrants will view your landing page at giveaways.dojomojo.com and will not
              see your brand name in the address.
            </H4>
          </div>
        </div>

        {/* buttons */}
        <div className="row text-center">
          <div className="col-sm-12">
            <ModalButtonGroup
              canSave
              hideLine
              confirmText="Activate"
              cancelText="Cancel"
              confirm={() => this.props.activateHosting('dojoHost')}
              cancel={this.props.deselectOption}
            />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-sm-12">
            {/*
              will be implemented later

              <SecondaryButton on
                Test
              />
            */}
          </div>
        </div>
      </div>
    )
  }
}

export default DojoHost
