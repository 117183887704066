import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import COLOURS from 'util/colours'

import { Small } from 'visual-components/util/texts'

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    border: `1px solid ${COLOURS.silver}`,
    borderRadius: 4,
    overflow: 'hidden',
    display: 'flex',
    '& *': {
      fontFamily: 'Larsseit-Medium',
    },
    '& input': {
      fontSize: 18,
      fontWeight: '500',
      outline: 'none',
      border: 'none',
      width: 60,
      padding: '10px 0 8px',
      textAlign: 'center',
    },
  },
  percentageBox: {
    backgroundColor: '#fcfcfc',
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${COLOURS.silver}`,
    '& span': {
      fontFamily: 16,
    },
  },
  helpText: {
    paddingLeft: 12,
    width: 95,
  },
  error: {
    borderColor: COLOURS.alertRed,
    '& > div': {
      backgroundColor: COLOURS.fair,
      borderLeftColor: COLOURS.alertRed,
      '& span': {
        color: '#606060',
      },
    },
  },
  disabled: {
    '& input': {
      color: COLOURS.cloudGrey,
    },
    '& > div': {
      backgroundColor: '#fbfbfb',
      '& span': {
        color: COLOURS.cloudGrey,
      },
    },
  },
}

function InputWithPercentage(props) {
  const {
    classes: css, onChange, error, helpText, initialValue, disabled,
  } = props

  const [value, setValue] = useState(initialValue)

  const onInputChange = ({ target }) => {
    let valueToUse = target.value || 0
    valueToUse = valueToUse > 100 ? 100 : valueToUse
    if (/^[0-9]*$/ig.test(valueToUse)) { // Only numbers
      setValue(Number(valueToUse))
      onChange(Number(valueToUse))
    }
  }

  const raiseError = !disabled && error

  return (
    <div className={css.container}>
      <div className={cn(css.wrapper, { [css.error]: raiseError, [css.disabled]: disabled })}>
        <input disabled={disabled} onChange={onInputChange} value={value} />
        <div className={css.percentageBox}>
          <span>%</span>
        </div>
      </div>
      <div className={css.helpText}>
        <Small cloudGrey={disabled} alertRed={raiseError}>
          {helpText}
        </Small>
      </div>
    </div>
  )
}

InputWithPercentage.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.number,
  helpText: PropTypes.string,
}

InputWithPercentage.defaultProps = {
  onChange() {},
  error: false,
  disabled: false,
  initialValue: 0,
  helpText: '',
}

export default React.memo(injectCSS(styles)(InputWithPercentage))
