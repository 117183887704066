import React from 'react'
import { object } from 'prop-types'
import { Button } from 'react-toolbox/lib/button'
import { H3, H4 } from 'visual-components/util/texts'
import { Form, Input } from 'visual-components/util/form'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import IntegrationTheme from 'css/themes/integrations.css'
import ButtonTheme from 'css/themes/Buttons.css'
import TestResults from './integration-test-results'
import IntegrationIcon from './integration-icon'

class IntegrationDetails extends React.Component {
  static propTypes = {
    integration: object.isRequired,
    espIntegration: object.isRequired,
    espIntegrations: object.isRequired,
    utilityFunctions: object.isRequired,
  }

  renderInputs = () => {
    const {
      integration: { connectInputs },
    } = this.props

    return connectInputs.map((input, idx) => (
      <div
        key={input.attr}
        className={`
          ${IntegrationTheme[`modalInput${connectInputs.length}`]}
          ${idx + 1 === connectInputs.length && IntegrationTheme.noMargin}
        `}
      >
        <Input
          name={`espIntegration.${input.attr}`}
          label={input.label}
          disabled={!input.optional}
          disabledLabel={!input.optional}
        />
      </div>
    ))
  }

  renderActionButtonGroup = () => {
    const {
      integration: {
        connectInputs = [],
        selectInputs = [],
      },
      espIntegration,
      utilityFunctions: { disconnect, test },
    } = this.props

    const inputs = connectInputs.concat(selectInputs)
    const valid = inputs.every(input => input.optional || espIntegration[input.attr])

    return (
      <div>
        <Button
          label="Disconnect"
          onClick={disconnect}
          className={ButtonTheme.whiteButton}
          theme={ButtonTheme}
          style={{ marginRight: '16px' }}
          raised
        />
        { valid
          ? (
            <Button
              label="Test"
              onClick={() => test()}
              className={ButtonTheme.whiteButton}
              theme={ButtonTheme}
              raised
            />
          ) : null
        }
      </div>
    )
  }

  renderModalButtonGroup = () => {
    const {
      integration,
      espIntegration,
      utilityFunctions: {
        activate,
        deactivate,
        goTo,
        closeModal,
      },
    } = this.props

    const nextStep = integration.selectInputs
      ? 'select'
      : integration.mergeTags
        ? 'merge-tags'
        : null

    const confirm = nextStep
      ? () => goTo(nextStep)
      : !espIntegration.activated
        ? activate
        : deactivate

    const confirmText = nextStep
      ? 'Next'
      : !espIntegration.activated
        ? 'Activate'
        : 'Deactivate'

    return (
      <div className={IntegrationTheme.modalButtonGroup}>
        <ModalButtonGroup
          confirm={confirm}
          confirmText={confirmText}
          cancel={closeModal}
          hideLine
          canSave
        />
      </div>
    )
  }

  render() {
    const {
      integration: { img, connectInputs },
      espIntegration,
      espIntegrations: { testLoading, testResults },
      utilityFunctions: { updateIntegration },
    } = this.props

    const hasSuccess = espIntegration && espIntegration.verified === true
      && espIntegration.activated === true
    const hasError = espIntegration && espIntegration.verified === false

    let imgName
    let extension
    let srcSet
    if (img) {
      [imgName, extension] = img.split('.')
      srcSet = imgName && extension && `
        ${imgName}-full.${extension},
        ${imgName}-full@2x.${extension} 2x,
        ${imgName}-full@3x.${extension} 3x
      `
    }

    return (
      <div>
        <div className={IntegrationTheme.modalSubtitle}>
          <H3 coral>
            <small>Login Information</small>
          </H3>
        </div>

        <div className={IntegrationTheme.modalPara}>
          <H4 multiline>
            Make sure your ESP is correct, if not disconnect and connect again.
          </H4>
        </div>

        <div className={IntegrationTheme.modalLogo}>
          { hasSuccess || hasError
            ? <IntegrationIcon success={espIntegration.verified} />
            : null
          }
          <img
            src={img}
            srcSet={srcSet}
          />
        </div>

        <div className={IntegrationTheme.modalInputs}>
          <Form
            modelName="espIntegrations"
            updateModel={updateIntegration}
          >
            {connectInputs && this.renderInputs()}
          </Form>
        </div>

        <div>
          <TestResults testLoading={testLoading} testResults={testResults} />
        </div>

        { this.renderActionButtonGroup() }

        { this.renderModalButtonGroup() }
      </div>
    )
  }
}

export default IntegrationDetails
