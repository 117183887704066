import React from 'react'
import Grid from 'visual-components/util/no-padding-grid/grid'
import { Card } from 'react-toolbox/lib/card'
import AnalyticsTheme from 'css/themes/analytics.css'
import CardTabs from 'visual-components/util/cards/card-tabs'

class GraphOverviewCard extends React.Component {
  state = {
    currentTab: 'dashboardEmailsAcquired',
  }

  render() {
    var {
      tabs,
      children,
      aggregates,
    } = this.props

    return(
      <Card className={ AnalyticsTheme.overviewCard }>
        <Grid>
          <CardTabs { ...this.props }
            tabs={ tabs }
            data={ aggregates }
          />
          <div className={ AnalyticsTheme.overviewCardContainer }>
            { children }
          </div>
        </Grid>
      </Card>
    )
  }
}

export default GraphOverviewCard
