import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

import AnalyticsTheme from 'css/themes/analytics.css'
import { H1, H6 } from 'visual-components/util/texts'

class CardTab extends Component {
  static propTypes = {
    tab: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    switchTab: PropTypes.func.isRequired,
    currentTab: PropTypes.bool,
    twoTabs: PropTypes.bool,
  }

  static defaultProps = {
    currentTab: false,
    twoTabs: false,
  }

  render() {
    const {
      tab,
      currentTab,
      switchTab,
      value,
      twoTabs,
    } = this.props
    const { tooltip } = tab
    const dataToggle = tooltip ? 'tooltip' : null
    const title = tooltip || ''

    const blur = value < 0

    return (
      <div
        onClick={() => switchTab(tab.ref)}
        className={`${twoTabs ? AnalyticsTheme.cardTabForTwo : AnalyticsTheme.cardTab} ${AnalyticsTheme[tab.ref]}`}
        data-toggle={dataToggle}
        title={title}
        data-placement="bottom"
      >
        <div style={{ height: '80px', textAlign: 'center' }}>
          <div style={{ filter: blur ? 'blur(4px)' : 'none', paddingTop: '16px', marginBottom: '4px' }}>
            <H1 white>
              { tab.isDollar && Boolean(value) && '$' }
              { blur ? 'XX,XXX' : makeNumbersPretty(value) }
            </H1>
          </div>
          <H6 white>
            { tab.title }
          </H6>
        </div>
        <div style={{ position: 'relative' }}>
          <div className={currentTab ? `${AnalyticsTheme.selectedTab} ${AnalyticsTheme[tab.ref]}` : null} />
        </div>
      </div>
    )
  }
}

export default CardTab
