import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';
import { H1, H4, H6 } from 'visual-components/util/texts';

import ProfileMainCard from 'visual-components/util/profile-main-card';

class CampaignPrizing extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      description,
      value
    } = this.props;

    return(
      <div style={{ marginBottom: '16px' }}>
        <ProfileMainCard title={ 'Prizing' }>
          <div style={{marginBottom: '24px'}}>
             <H4 multiline>
               { description }
             </H4>
          </div>
          <div style={{marginBottom: '12px'}}>
            <H6>
              PRIZING VALUE
            </H6>
          </div>
          <H1>
            { d3.format("$,")(value) }
          </H1>
        </ProfileMainCard>
      </div>
    )
  }
}

CampaignPrizing.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default CampaignPrizing;
