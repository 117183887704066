import React from 'react'
import { Link } from 'react-router-dom'
import { stateToHTML } from 'draft-js-export-html'
import { Card } from 'react-toolbox/lib/card'
import { Small } from 'visual-components/util/texts'
import MessengerTheme from 'css/themes/media-market/Messenger.css'
import MessageWindow from './messageWindow'
import Editor from './messageEditor'

class Messenger extends React.Component {
  state = {
    messageWindowHeight: Messenger.constants.defaultMessageWindowHeight,
  }

  textEditorRef = null

  setTextEditorRef = ref => {
    this.textEditorRef = ref
  }

  onEditorUpdate = () => {
    //update the message window to be height of window - editor section
    this.setState(prevState => {
      const { defaultMessageWindowHeight, menuBarHeight } = Messenger.constants
      const editorHeight = this.textEditorRef.offsetHeight
      const messageWindowHeight = defaultMessageWindowHeight + menuBarHeight - editorHeight
      if (prevState.messageWindowHeight === messageWindowHeight) {
        return null
      }
      return {
        messageWindowHeight,
      }
    })
  }

  sendMessage = contentState => {
    const {
      deal: {
        id: deal_id,
      },
      utilityFunctions: { sendMessage },
      currentBrand: {
        id: brand_id,
      },
      profile,
    } = this.props

    const options = {
      entityStyleFn: entity => {
        const entityType = entity.get('type').toLowerCase()
        if (entityType === 'link') {
          const data = entity.getData()
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          }
        }
      },
    }

    sendMessage({
      deal_id,
      brand_id,
      profile,
      content: stateToHTML(contentState, options),
    })
  }

  static constants = {
    defaultMessageWindowHeight: 406,
    menuBarHeight: 97,
  }

  render() {
    const { messageWindowHeight } = this.state
    
    const {
      deal: { buying_brand, buying_brand_id, selling_brand, selling_brand_id },
      currentBrand,
    } = this.props

    const isBuyer = buying_brand_id === currentBrand.id

    return (
      <Card className={MessengerTheme.container}>
        <div className={`${MessengerTheme.brandHeader}`}>
          <Small ink>
            <em>{isBuyer ? selling_brand.accountname : buying_brand.accountname}</em>
          </Small>
          <Small azure>
            <em>
              <Link
                to={`/explore/brands/${isBuyer ? selling_brand_id : buying_brand_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Profile
              </Link>
            </em>
          </Small>
        </div>
        <div
          className={MessengerTheme.window}
          style={{
            height: messageWindowHeight,
          }}
        >
          <MessageWindow {...this.props} />
        </div>

        <div
          className={MessengerTheme.textEditor}
          ref={this.setTextEditorRef}
        >
          <Editor
            buttonText="Send Message"
            buttonClick={this.sendMessage}
            styleOptions={['Bold', 'Italic', 'UL']}
            onEditorUpdate={this.onEditorUpdate}
          />
        </div>
      </Card>
    )
  }
}

export default Messenger
