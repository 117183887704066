import React, { Component } from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'

const styles = {
  button: {
    borderRadius: 20,
    backgroundColor: '#1b5e20',
    color: '#fff',
    textAlign: 'center',
    padding: 12,
    width: '100%',
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 'bold',
    border: 'none',
    outline: 'none',
  },
}
class SubmitButton extends Component {
  static propTypes = {
    entryButtonText: propTypes.string,
    entryButtonStyles: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    disabled: propTypes.bool,
  }

  static defaultProps = {
    entryButtonText: 'ENTER NOW',
    disabled: false,
  }

  static contextTypes = {
    baseStyles: propTypes.object,
  }

  render() {
    const {
      entryButtonText,
      classes: css,
      entryButtonStyles,
      disabled,
    } = this.props

    const {
      baseStyles,
    } = this.context

    return (
      <div className="inputs">
        <button
          type="submit"
          id="submit-button"
          disabled={disabled}
          className={cn(css.button, baseStyles.submitButtonButton)}
          style={{
            background: entryButtonStyles.entryButtonColor.color.color,
            color: entryButtonStyles.entryButtonText.color.color,
            fontFamily: entryButtonStyles.entryButtonText.family,
            fontSize: entryButtonStyles.entryButtonText.size,
          }}
        >
          {entryButtonText}
        </button>
      </div>
    )
  }
}

export default injectSheet(styles)(SubmitButton)
