import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';
import { H3 } from 'visual-components/util/texts';
import EditLogoContainer from 'container-components/brands/EditLogoContainer';

import ProfileTheme from 'css/themes/Profile.css';

class ProfileLogo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      imgSrc,
      accountname,
      isBrand
    } = this.props;

    return(
      <div className={ ProfileTheme.profile }>
        { isBrand ? <EditLogoContainer /> : null }
        <div style={{
          width: '216px',
          height: '216px',
          padding: '8px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: COLOURS.silver,
          background: COLOURS.white,
          display: 'table-cell',
          verticalAlign: 'middle'
        }}>
          <img src={ imgSrc }
            style={{
              maxHeight: '200px',
              maxWidth: '200px',
              display: 'block',
              margin: 'auto'
          }}/>
        </div>
        <div style={{
          marginTop: '16px',
          marginBottom: '16px'
        }}>
          <H3 multiline>
            { accountname }
          </H3>
        </div>
      </div>
    )
  }
}

ProfileLogo.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  accountname: PropTypes.string.isRequired
};

export default ProfileLogo;