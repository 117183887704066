import ListReducerFor from './../list-reducer';
import _ from 'lodash'

var model = 'promotionalUnits';
var defaultState = {
  rid: model,
  items: [],
  sortField: null,
  sortReversed: false,
  editModalOpen: false,
}

//Wrapper over ListReducer
var PromotionalUnitsReducer = function (state, action) {
  function sortItems(items, field) {
    let newList = items.concat().sort(function(a,b) {
      let valueA = typeof a[field] == 'number' ? a[field] : a[field].toUpperCase();
      let valueB = typeof b[field] == 'number' ? b[field] : b[field].toUpperCase();
      if(valueA < valueB) {
        return -1
      } else if (valueB < valueA) {
        return 1;
      } else {
        return 0;
      }
    })
    return newList
  }

  let ListReducer = ListReducerFor(model)

  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    LOAD_SUCCESS: function () {
      let data = _.extend({}, state, { loading: false });
      let { promoUnits , isHost, currentBrandId, invites } = action.data
      let acceptedInvites = _.filter(invites, {status: 'accepted'});
      let brands = _.map(acceptedInvites, invite => invite.invitee);
      let validBrandsTrackingLinks = isHost ? brands : [{id: currentBrandId}];


      let updatedItems = _.map(promoUnits, (item) => {
        if (item.promotionaltype == 'Preview Promotion' || !_.some(validBrandsTrackingLinks, {id: item.brand_id})) {
          item.display = false;
        } else {
          item.display = true;
        }
        item.logo = item.brand.logo;
        item.brand = item.brand.accountname;

        return item;
      })

      return _.extend({}, data, { items: updatedItems });
    },
    SORT_COLUMN: function() {
      if(state.sortField == action.data.sortField) {
        let reversedItems = state.items.concat().reverse();
        return _.extend({}, state, { items: reversedItems, sortReversed: !state.sortReversed})
      } else {
        return _.extend({}, state, { items: sortItems(state.items, action.data.sortField), sortReversed: false, sortField: action.data.sortField})
      }
    },
    UPDATE_UNIT: function() {
      let index = _.findIndex(state.items, {id: action.data.id});
      if (index != -1) {
        let updatedItems = state.items.concat();
        let brand = _.find(_.map(store.getState().currentCampaign.invites, invite => invite.invitee), {id: action.data.brand_id})
        updatedItems[index] = _.extend( {}, state.items[index], action.data, {brand: brand.accountname, logo: brand.logo});
        return _.extend({}, state, {items: updatedItems})
      } else {
        return _.extend({}, state)
      }
    },
    ADD_UNIT: function() {
      let invite = store.getState().currentCampaign.invites
      .find((invite) => invite.invitee.id == action.data['brand_id'])

      let newUnit = _.extend({}, action.data, {
        brand: invite.invitee.accountname,
        logo: invite.invitee.logo,
        display: true
      })

      let updatedItems = state.items.concat(newUnit)
      return _.extend({}, state, { items: updatedItems})
    },
    REMOVE_UNIT: function () {
      let items = state.items.concat();
      _.remove(items, {id: action.data.id});

      return _.extend({}, state, {items: items})
    }
  };
  let item = actions[action.type];
  return item ? item() : ListReducer(state, action)
}

export default PromotionalUnitsReducer;