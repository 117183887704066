import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import d3 from 'd3'
import moment from 'moment-timezone'
import { Button } from 'react-toolbox/lib/button'
import BrowserHistory from 'dispatchers/browser-history-dispatcher'

import COLOURS from 'util/colours'

import {
  H3, H4, H6, P,
} from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import HelpLink from 'util/components/helplink'

const styles = {
  actions: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: props =>
      (!props.canAccessSms && !props.remainingBalance ? 'center' : 'space-between'),
    marginTop: 16,
    width: '100%',
    '& button': {
      margin: '0px !important',
    },
  },
  balance: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 12,
  },
  container: {
    alignItems: props => (!props.remainingBalance && !props.canAccessSms ? 'center' : 'flex-start'),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: 6,
  },
  helpLink: {
    marginLeft: 12,
  },
  icon: {
    height: 40,
    marginRight: 24,
    width: 40,
  },
  note: {
    backgroundColor: props =>
      (!props.canAccessSms || !props.remainingBalance || props.cancelledDate
        ? COLOURS.coralLight
        : COLOURS.crystal),
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
    padding: '16px 24px',
    width: '100%',
  },
  noteBody: {
    alignItems: props => (props.cancelledDate ? 'center' : 'flex-start'),
    display: 'flex',
    flexDirection: 'column',
    fontWeight: props => (props.cancelledDate || !props.canAccessSms ? 800 : null),
    width: '100%',
  },
  noteFooter: {
    marginTop: 12,
  },
  pointer: {
    cursor: 'pointer',
  },
}

const SmsSettingsView = props => {
  const {
    autoRenew,
    autoRenewCount,
    autoRenewedAt,
    cancelledDate,
    classes,
    expirationDate,
    canAccessSms,
    isTrial,
    redeemed,
    remainingBalance,
    revenue,
    subscribers,
    toggleSmsCancelModal,
    upgradePlan,
    currentPlan,
  } = props

  const hasMadeValue = !!revenue || !!subscribers

  const renderNoteHeader = () => {
    let noteHeader
    if (!canAccessSms || cancelledDate) noteHeader = ''
    else if (Number(revenue)) {
      noteHeader = `You've Made $${revenue} with SMS Marketing${
        remainingBalance ? '!' : ", but You're Out of Credits!"
      }`
    } else if (Number(subscribers)) {
      noteHeader = `You've Already Got ${subscribers} Subscribers${
        remainingBalance ? '!' : ", but You're Out of Credits!"
      }`
    } else noteHeader = "Accelerate Revenue Growth with DojoMojo's SMS Marketing Solution"
    return noteHeader ? (
      <H4 multiline>
        <b>{noteHeader}</b>
      </H4>
    ) : null
  }

  const getNoteBody = () => {
    if (!canAccessSms) {
      return `Note: You must be on a paying plan to use DojoMojo's SMS Marketing features. ${
        moment(expirationDate).isValid()
          ? `Your
        phone number and credits balance will be stored until
        ${' '}
        ${d3.time.format('%B %d, %Y')(new Date(expirationDate))}.`
          : ''
      }`
    }
    if (cancelledDate) return `Your SMS Marketing Plan was cancelled on ${moment(cancelledDate).format('MM/DD/YY')}.`
    if (hasMadeValue) return 'Acquiring SMS Subscribers is just the beginning. SMS is a powerful engagement channel that regularly outperforms email.'
    return 'Combine our powerful acquisition tools with SMS texting capabilities for a true end-to-end conversion pipeline.'
  }

  const renderNoteFooter = () => {
    let noteFooter
    if (!canAccessSms || cancelledDate) noteFooter = ''
    else if (hasMadeValue) {
      noteFooter = isTrial
        ? 'Upgrade Your DojoMojo Subscription to Keep Going!'
        : 'Select Your SMS Marketing Plan to Keep Going'
    } else noteFooter = 'FREE to Try for a Limited Time'
    return noteFooter ? (
      <div className={classes.noteFooter}>
        <P multiline>
          <i>{noteFooter}</i>
        </P>
      </div>
    ) : null
  }

  const getButtonLabel = () => {
    if (isTrial) return 'Upgrade to Select Your SMS Marketing Plan'
    if (!canAccessSms) return 'Upgrade Your Plan Today'
    if (!autoRenew) return 'Select Your SMS Marketing Plan'
    return 'Manage Your SMS Marketing Plan'
  }

  const renderBasicView = () => (
    <>
      <div className={classes.header}>
        <H3 multiline> Accelerate Revenue Growth with DojoMojo&apos;s SMS Marketing Solution </H3>
      </div>
      <P multiline>
        Combine our powerful acquisition tools with SMS texting capabilities for a true end-to-end
        conversion pipeline.
      </P>
      <H6 seaGreen multiline>
        FREE to Try for Essential & Premium.
      </H6>
    </>
  )

  const renderNote = () => (
    <div className={classes.note}>
      <div>
        {!!canAccessSms && !cancelledDate && (
          <img
            className={classes.icon}
            src={remainingBalance ? 'images/icons/blue-info.svg' : 'images/icons/red-info.svg'}
          />
        )}
      </div>
      <div className={classes.noteBody}>
        {renderNoteHeader()}
        <P multiline>{getNoteBody()}</P>
        {renderNoteFooter()}
      </div>
    </div>
  )

  const renderBalance = () => (
    <div className={classes.balance}>
      <H3>
        <small>Balance:&nbsp;</small>
      </H3>
      <H3 cloudGrey={!canAccessSms}>
        <small>
          {`${d3.format(',')(remainingBalance)} ${autoRenewedAt || !remainingBalance ? '' : 'FREE'} Credits ${
            redeemed ? 'Remaining' : ''
          } `}
        </small>
      </H3>
      <div className={classes.helpLink}>
        <HelpLink
          link="https://help.dojomojo.com/hc/en-us/articles/360036050372"
          text="How do Credits work?"
        />
      </div>
    </div>
  )

  return (
    <div className={classes.container}>
      {!canAccessSms && !remainingBalance ? (
        renderBasicView()
      ) : (
        <>
          {(!autoRenew || !canAccessSms) && renderNote()}
          {renderBalance()}
          {autoRenew && autoRenewCount ? (
            <P>{`Plan: ${d3.format(',')(autoRenewCount)} Credits Auto-Refill`}</P>
          ) : (
            <P>Plan: No SMS Plan Selected</P>
          )}
        </>
      )}
      <div className={classes.actions}>
        <Button
          onClick={
            !canAccessSms || isTrial ? upgradePlan : () => BrowserHistory.push('/manage-sms-marketing')
          }
          label={getButtonLabel()}
          className={`${ButtonTheme.raised} ${ButtonTheme.blueButton}`}
          theme={ButtonTheme}
        />
        {currentPlan && (
          <div className={classes.pointer} onClick={toggleSmsCancelModal}>
            <H6 azure>Cancel Your Plan</H6>
          </div>
        )}
      </div>
    </div>
  )
}

SmsSettingsView.propTypes = {
  currentPlan: PropTypes.bool.isRequired,
  autoRenew: PropTypes.bool.isRequired,
  autoRenewCount: PropTypes.number.isRequired,
  autoRenewedAt: PropTypes.string.isRequired,
  cancelledDate: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  expirationDate: PropTypes.string.isRequired,
  canAccessSms: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  redeemed: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  revenue: PropTypes.number.isRequired,
  subscribers: PropTypes.number.isRequired,
  toggleSmsCancelModal: PropTypes.func.isRequired,
  upgradePlan: PropTypes.func.isRequired,
}

export default injectSheet(styles)(SmsSettingsView)
