import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { H3, Tiny } from 'visual-components/util/texts';
import { makeNumbersPretty } from 'util/makeNumbersPretty';
import CurrentCampaignCardTheme from 'css/themes/CurrentCampaignCard.css';

class CampaignStatPreview extends Component{
  constructor(props) {
    super(props);
  }

  render() {
    const {
      name,
      data,
      paddingRight
    } = this.props;

    const displayedNumber = makeNumbersPretty(data);

    return(
      <Col>
        <Row style={{ paddingRight }}>
          <div className={ CurrentCampaignCardTheme.statPreviewNumber }>
            <H3>
              { displayedNumber }
            </H3>
          </div>
        </Row>
        <Row style={{ paddingRight }}>
          <div className={ CurrentCampaignCardTheme.statPreviewName }>
            <Tiny>
              { name }
            </Tiny>
          </div>
        </Row>
      </Col>
    );
  }
}

CampaignStatPreview.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  paddingRight: PropTypes.string
};

export default CampaignStatPreview;
