import React, { Component } from 'react'
import AgeTarget from 'visual-components/brandProfile/interests/age-target'

import { Input, Select, SelectRange } from 'visual-components/util/form'

const monthlyVolumes = [
  { value: '-', label: 'Select a range', disabled: true },
  { value: '0-1000', label: 'Less than 1,000' },
  { value: '1000-5000', label: '1,000 - 5,000' },
  { value: '5000-15000', label: '5,000 - 15,000' },
  { value: '15000-999999', label: '15,000+' },
]

const genders = [
  { value: '', label: 'Select an option', disabled: true },
  { value: 'Primarily Male', label: 'Primarily Male' },
  { value: 'Primarily Female', label: 'Primarily Female' },
  { value: 'Mixed', label: 'Mixed' },
]

class DedicatedEmailOpportunityInfo extends Component {
  updateAge = this.props.opportunityActions.toggleAgeGroup

  render() {
    return (
      <div>
        <SelectRange
          required
          name="min_target_monthly_volume"
          maxFieldName="max_target_monthly_volume"
          label="Target Monthly Volume"
          sublabel="The number of boxes you would like to be featured in."
          options={monthlyVolumes}
          defaultSelected="-"
        />
        <Select
          required
          name="gender"
          label="Target Gender"
          sublabel="Your preferred reader gender."
          options={genders}
          defaultSelected=""
        />
        <AgeTarget />
        <div style={{ marginTop: '24px' }}>
          <Input
            required
            name="products"
            characterLimit={55}
            placeholder="I.e. Natural health and wellness supplements"
            label="Products Shipped"
            sublabel="Describe the products you ship."
          />
        </div>
      </div>
    )
  }
}

export default DedicatedEmailOpportunityInfo
