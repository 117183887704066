import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import ACHForm from 'visual-components/brandSettings/billing-ach-form'
import * as DialogActions from 'actions/dialog-actions'

const mapStateToProps = ({ achInfoModal }) => ({ achInfoModal })

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
})

class EnterACHBankInfoModal extends React.Component {
  static propTypes = {
    achInfoModal: object.isRequired,
    dialogActions: object.isRequired,
  }

  accountAdded = () => {
    toastr.success('Your bank account has been added successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
    this.onClose()
  }

  accountUpdated = () => {
    toastr.success('Your bank account has been updated successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
    this.onClose()
  }

  onClose = () => {
    const { dialogActions: { dialogUpdateWithPayload } } = this.props
    dialogUpdateWithPayload('achInfoModal', { open: false, updating: false, item: null })
  }

  render() {
    const {
      achInfoModal: {
        open,
        updating,
        item: account,
      },
    } = this.props

    return (
      <MultiTaskModal
        title="Add Your Bank Account Information"
        active={open}
        close={this.onClose}
      >
        <ACHForm
          updateAccount={updating && account}
          onSuccess={updating ? this.accountUpdated : this.accountAdded}
          onClose={this.onClose}
        />
      </MultiTaskModal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterACHBankInfoModal)
