import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import colors from 'util/colours'

import { P } from 'visual-components/util/texts'

import useWindowResize from 'util/hooks/useWindowResize'

const styles = {
  header: {
    '@media only screen and (max-width:360px)': {
      display: 'none',
    },
    '& th': {
      textTransform: 'capitalize',
      textAlign: 'left',
    },
  },
  column: {
    padding: 24,
    minHeight: 64,
    verticalAlign: 'top',
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  clickable: {
    cursor: 'pointer',
  },
  sortArrows: {
    marginLeft: 10,
    height: 16,
    marginTop: -4,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media only screen and (max-width:767px)': {
      display: 'none',
    },
  },
  arrow: {
    width: 0,
    height: 0,
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent',
    borderBottom: `6px solid ${colors.silver}`,
  },
  sortDown: {
    transform: 'rotate(180deg)',
  },
  sortedBy: {
    borderBottom: `6px solid ${colors.ink}`,
  },
}

const checkIfSortable = (...args) => {
  const [locked, headers, idx, config] = args

  if (config.noSortable) return false

  const [fromIdx = 'not found', toIdx = 'not found'] = locked || []
  let [from, to] = [headers.indexOf(fromIdx), headers.indexOf(toIdx)]
  from = from !== -1 ? from : 0
  to = to !== -1 ? to : 1000

  return !locked || !(idx >= from && idx <= to)
}

function TableHeader(props) {
  const {
    classes: css,
    data,
    onSortClick,
    sortOptions,
    config,
  } = props
  const { columns, locked } = config

  useWindowResize() // Allows rerendering when resized

  const headers = columns || Object.keys(data[0])

  return (
    <thead className={css.header}>
      <tr>
        {headers.map((header, idx) => {
          const itemConfig = config[header] || {}
          const headersConfig = itemConfig.headers || {}

          if (window.innerWidth < (itemConfig.hideOn || 0)) return null

          const sortable = Boolean(data.length) && checkIfSortable(locked, headers, idx, itemConfig)

          const showLabel = window.innerWidth > (itemConfig.hideLabelOn || 0)

          const { column, order } = sortOptions

          const isColumn = column === header
          const sortedUp = isColumn && order === 'asc'
          const sortedDown = isColumn && order === 'desc'

          const headerStyles = {
            ...(headersConfig.styles || {}),
            ...(itemConfig.styles || {}),
          }

          return (
            <th key={header}>
              <div
                className={cn(css.column, { [css.clickable]: sortable })}
                style={headerStyles}
                onClick={sortable ? () => onSortClick(header) : null}
              >
                <P>{showLabel && <em>{headersConfig.columnName || header}</em>}</P>
                {sortable && (
                  <div className={css.sortArrows}>
                    <div className={cn(css.sortUp, css.arrow, { [css.sortedBy]: sortedUp })} />
                    <div className={cn(css.sortDown, css.arrow, { [css.sortedBy]: sortedDown })} />
                  </div>
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortOptions: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onSortClick: PropTypes.func,

  classes: PropTypes.object.isRequired,
}

TableHeader.defaultProps = {
  onSortClick: console.log,
}

export default React.memo(injectCSS(styles)(TableHeader))
