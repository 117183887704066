
import cn from 'classnames'
import React, { PureComponent as Component } from 'react'
import moment from 'moment-timezone'
import { instanceOf } from 'prop-types'

import CalendarTheme from 'css/themes/calendar/calendar.css'
import Tasks from './Tasks'
import DateHeader from './DateHeader'
import BackgroundCells from './BackgroundCells'
import CampaignBars from './CampaignBars'

const renderDateItems = (dateItem, i) => <DateHeader dateItem={dateItem} key={i} />

class Week extends React.Component {
  static contextTypes = {
    date: instanceOf(Date).isRequired,
  }

  _rowSpecialClass() {
    const {
      week,
    } = this.props

    const differenceEnd = moment(week[0]).endOf('month').diff(moment(week[0]), 'day')

    if (differenceEnd <= 7) {
      return CalendarTheme.rowOf
    }

    if (differenceEnd <= 14) {
      return CalendarTheme.oneBeforeRow
    }

    return null
  }

  render() {
    const {
      week,
    } = this.props

    const weekDays = week.map(renderDateItems)

    return (
      <div className={cn(CalendarTheme.calendarMonthRow, this._rowSpecialClass())}>
        <BackgroundCells range={week} />
        <div className={CalendarTheme.calendarRowContent}>
          <div className={CalendarTheme.calendarRow} ref={this.createHeadingRef}>
            { weekDays }
          </div>
          <Tasks range={week} />
          <CampaignBars range={week} />
        </div>
      </div>
    )
  }
}

export default Week
