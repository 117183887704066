import React from 'react';
import TableTheme from 'css/themes/analytics-tables/campaignTable.css'

class CampaignTableHeader extends React.Component {
  render() {
    return(
      <tr>
        <th className={ `${TableTheme.analyticsTh} ${TableTheme['Campaign']}` }>
          Campaign
        </th>
        <th className={ `${TableTheme.analyticsTh} ${TableTheme['Dates']}` }>
          Dates
        </th>
        <th className={ `${TableTheme.analyticsTh} ${TableTheme['Entries']}` }>
          Entries
        </th>
        <th className={ `${TableTheme.analyticsTh} ${TableTheme['Visits']}` }>
          Visits
        </th>
        <th className={ `${TableTheme.analyticsTh} ${TableTheme['NTF']}` }>
          NTF
        </th>
        <th className={ `${TableTheme.analyticsTh} ${TableTheme['NTFpercent']}` }>
          NTF%
        </th>
      </tr>
    )
  }
};

export default CampaignTableHeader;
