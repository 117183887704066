import _ from 'lodash'

import { processFacet } from 'actions/search-config/defaults/helpers'

const searchReducerFactory = ({
  model,
  facets = [],
  initialState = {},
  transformResults = ({ _source }) => _source,
}) => {
  const defaultState = { rid: model, ...initialState }

  const processResults = (state, data) => {
    const { hits, aggregations } = data
    const results = {
      ...aggregations,
      results: hits.hits.map(transformResults),
      total: hits.total,
    }
    facets.forEach(field => processFacet({ results, data, field, state }))
    return results
  }

  return (state = defaultState, action) => {
    if (action.model !== model) {
      return state
    }

    switch (action.type) {
      case 'CLEAR':
        return { ...defaultState }
      case 'UPDATE_ATTR':
        return _.merge({}, state, action.payload)
      case 'LOADING':
        return { ...state, loading: true, error: null }
      case 'LOAD_SUCCESS':
        return {
          ...state,
          ...processResults(state, action.payload),
          loading: false,
        }
      case 'LOAD_FAILURE':
        return {
          ...state,
          error: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error',
          loading: false,
        }
      default:
        return state
    }
  }
}

export default searchReducerFactory
