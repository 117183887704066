import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H3, P } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

// import DropdownTheme from 'css/themes/Dropdown.css'
import { MobileMarketingContext } from '../MobileMarketingContext'

const styles = {
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundColor: 'white',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% + 64px)',
    padding: 16,
    margin: '-32px -32px 0',
    borderBottom: `1px solid ${COLOURS.silver}`,
    '& > img': {
      cursor: 'pointer',
    },
  },
  items: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  selected: {
    backgroundColor: COLOURS.crystal,
    '& *': {
      fontFamily: 'Larsseit-Bold !important',
    },
  },
  templateItem: {
    padding: '24px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: COLOURS.silver,
    },
  },
  disabled: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}

function TemplateSelectionModal(props) {
  const { classes: css } = props
  const {
    previousSMSPostEntryCampaigns,
    onLoadPreviousPostEntryTemplate,
    smsSend,
    modals,
    showModal,
  } = React.useContext(MobileMarketingContext)
  const [selected, setSelected] = useState(null) // either invite_id or null (not selected)

  if (smsSend.status === 'published') return null
  if (!previousSMSPostEntryCampaigns.length) return null

  const onConfirm = () => onLoadPreviousPostEntryTemplate(selected)

  return (
    <SimpleInfoModal active={modals.templates}>
      <div className={css.title}>
        <H3 multiline>
            Previous SMS Forms
        </H3>
        <img src="/images/icons/close.svg" onClick={() => showModal('templates', false)} />
      </div>
      <div className={cn(css.templateItem, css.disabled)}>
        Choose From Past Sweepstakes
      </div>
      <div className={css.items}>
        {
          previousSMSPostEntryCampaigns.map(postEntry => {
            const isSelected = selected === postEntry.invite_id
            const onTemplateClick = () => setSelected(isSelected ? null : postEntry.invite_id)

            return (
              <div
                className={cn(css.templateItem, { [css.selected]: isSelected })}
                onClick={onTemplateClick}
              >
                <P multiline azure={isSelected}>
                  {postEntry.campaign_name}
                </P>
                <a
                  href={`/app/partnerships/campaign/${postEntry.campaign_id}/${postEntry.is_solo ? 'quickview' : 'grow-optimize'}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >

                  <P multiline azure>
                  Preview
                    <img
                      style={{ marginLeft: 8 }}
                      src="/images/icons/preview-new-tab.svg"
                      alt="preview in new tab"
                    />
                  </P>
                </a>
              </div>
            )
          })
        }
      </div>
      <ModalButtonGroup
        canSave={selected !== null}
        cancelText="Cancel"
        confirmText="Use Previous"
        confirm={onConfirm}
        cancel={() => showModal('templates', false)}
      />
    </SimpleInfoModal>
  )
}

TemplateSelectionModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(TemplateSelectionModal))
