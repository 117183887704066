import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import axios from 'axios'
import moment from 'moment-timezone'

import Row from 'visual-components/util/no-padding-grid/row'
import { H3 } from 'visual-components/util/texts'
import { isTrialingAndNotUpgraded } from 'util/brand-stripe-belt-helper'

import BillingTheme from 'css/themes/payments/billing-settings.css'

import { getClaim } from 'actions/brand-claim-actions'

import SmsSettingsView from './SmsSettingsView'

const SMSMarketingSettings = props => {
  const {
    currentBrand,
    toggleSmsCancelModal,
    sms = {},
    upgradePlan,
  } = props

  const [smsAnalytics, setSmsAnalytics] = useState({ revenue: {}, sumSmsCampaignAnalytics: {} })

  useEffect(() => {
    async function fetchAnalytics() {
      const {
        data: {
          smsAnalytics: {
            revenue,
          },
          subscribes,
        },
      } = await axios.get(
        '/analytics/sms-overview',
        { headers: { brand: currentBrand.id } }
      )

      setSmsAnalytics({
        subscribes,
        revenue,
      })
    }

    fetchAnalytics()
  }, [setSmsAnalytics])

  // Real trialing
  const isTrial = isTrialingAndNotUpgraded(currentBrand)

  const canAccessSms = getClaim('sms.credits.purchase')

  const { subscribes, revenue } = smsAnalytics

  const {
    credits: {
      cancelledAt: cancelledDate,
      autorefilledAt: autoRenewedAt,
      autorefill: autoRenew,
      redeemed,
      count,
      freeCount,
      createdAt,
    },
    currentPlan,
  } = sms.brandSmsBank

  const {
    credits: autoRenewCount = 0,
  } = currentPlan || {} // if no plan, currentPlan = null

  const remainingBalance = count + freeCount

  const expirationDate = moment(cancelledDate || createdAt).add(365, 'days')

  return (
    <>
      <Row className={BillingTheme.sectionRow}>
        <div className={BillingTheme.sectionHeader}>
          <H3 coral>
            <small>SMS Marketing</small>
          </H3>
        </div>
        <Card className={BillingTheme.card}>
          <SmsSettingsView
            autoRenew={autoRenew}
            autoRenewCount={autoRenewCount}
            autoRenewedAt={autoRenewedAt}
            cancelledDate={cancelledDate}
            expirationDate={expirationDate}
            canAccessSms={canAccessSms}
            isTrial={isTrial}
            redeemed={redeemed}
            remainingBalance={remainingBalance}
            revenue={revenue ? revenue.revenue : 0}
            subscribers={subscribes}
            toggleSmsCancelModal={toggleSmsCancelModal}
            upgradePlan={upgradePlan}
            currentPlan={currentPlan}
          />
        </Card>
      </Row>
    </>
  )
}

SMSMarketingSettings.propTypes = {
  sms: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  toggleSmsCancelModal: PropTypes.func.isRequired,
  upgradePlan: PropTypes.func.isRequired,
}

export default SMSMarketingSettings
