import React from 'react'
import moment from 'moment-timezone'
import CalendarTheme from 'css/themes/calendar/calendar.css'

const MonthHeader = ({ day }) => (
  <div className={CalendarTheme.calendarHeader}>
    <span>
      {moment(day).format('ddd')}
    </span>
  </div>
)

export default MonthHeader
