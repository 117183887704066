import React from 'react';

import { Checkbox } from 'react-toolbox';
import { Input } from 'react-toolbox/lib/input';

import Field from 'visual-components/util/inputs/field';
import Label from 'visual-components/util/inputs/label';
import CheckboxTheme from 'css/themes/Checkbox.css'
import InputTheme from 'css/themes/Input.css'

const themes = {
  Checkbox: CheckboxTheme,
  Input: InputTheme,
}

class PasswordField extends Field {
  constructor(props) {
    super(props);

    this.key = this.key.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);

    this.state = {
      visible: false
    }
  }

  toggleFocus() {
    var {
      formModel
    } = this.props;

    store.dispatch({
      model: formModel.rid,
      data: {
        passwordFocused: !formModel.passwordFocused
      },
      type: 'UPDATE_ATTR'
    });
  }

  key(e) {
    var {
      dataModel,
      formModel
    } = this.props;

    var value = e.target.value;

    var hasErrors = false;
    var state = {
      value: value
    };

    if (value.length >= 8) {
      state.hasLength = true;
    } else {
      hasErrors = true;
    }

    if (value.toLowerCase() !== value) {
      state.hasUpperCase = true;
    } else {
      hasErrors = true;
    }

    var hasDigit = new RegExp(/\d/);

    if (value.match(hasDigit)) {
      state.hasDigit = true;
    } else {
      hasErrors = true;
    }

    var special = new RegExp(/(?=.*[!\?@#$&*()^%+])/);

    if (value.match(special)) {
      state.hasSpecial = true;
    } else {
      hasErrors = true;
    }

    state.hasErrors = hasErrors;
    store.dispatch({
      model: dataModel.rid,
      type: 'UPDATE_ATTR',
      data: {
        newPassword: value
      }
    });

    store.dispatch({
      model: formModel.rid,
      type: 'UPDATE_ATTR',
      data: {
        errors: {
          newPassword: state
        }
      }
    });
  }

  render() {
    var {
      attrName,
      label,
      dataModel,
      formModel,
      style
    } = this.props;

    var {
      visible,
      newPassword
    } = this.state;

    var passwordFormData = formModel.errors.newPassword || {};

    var icon = (
      <div onClick={() => this.setState({ visible: !visible })}>
        { visible ?
          <img src={ `/images/icons/hide-password-icon.svg` }
            style={{
              height: '24px'
            }}
          />
          :
          <img src={ `/images/icons/view-password-icon.svg` }
            style={{
              height: '16px'
            }}
          />
        }
      </div>
    )

    return(
      <div>
        {
          label ?
          <Label label={ label } />
          : null
        }

        <Input type={ visible ? 'text' : 'password' }
          attrName={ attrName }
          value={ newPassword }
          error={ passwordFormData.hasErrors && !formModel.passwordFocused ? ['Password does not meet requirements.'] : false }
          dataModel={ dataModel }
          formModel={ formModel }
          onKeyUp={ this.key }
          onFocus={ this.toggleFocus }
          onBlur={ this.toggleFocus }
          theme={ themes.Input }
          icon={ icon }
          style={ style }
        />

        {/* error checklist */}
        <div style={{
          position: 'relative'
        }}>
          { formModel.passwordFocused ?
            <div style={{
              width: '210px',
              position: 'absolute',
              left: '372px',
              top: '-72px',
              '@media only screen and (max-width: 990px)': {
                position: 'initial',
                left: 'initial',
                top: 'initial',
                width: '100%'
              }
            }}>
              <p style={{
                fontFamily: 'Larsseit',
                fontSize: '10px',
                color: '#2b3f48',
                marginBottom: '8px'
              }}>
                Passwords should include the following:
              </p>

              <div style={{
                marginBottom: '8px'
              }}>
                <Checkbox label={ 'Minimum 8 characters' }
                  checked={ passwordFormData.hasLength }
                  theme={ themes.Checkbox }
                />
              </div>

              <div style={{
                marginBottom: '8px'
              }}>
                <Checkbox label={ 'An uppercase character' }
                  checked={ passwordFormData.hasUpperCase }
                  theme={ themes.Checkbox }
                />
              </div>

              <div style={{
                marginBottom: '8px'
              }}>
                <Checkbox label={ 'A digit' }
                  checked={ passwordFormData.hasDigit }
                  theme={ themes.Checkbox }
                />
              </div>

              <div>
                <Checkbox label={ 'A special character: [!?@#$&*()^%+]' }
                  checked={ passwordFormData.hasSpecial }
                  theme={ themes.Checkbox }
                />
              </div>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
};

export default PasswordField;
