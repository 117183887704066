import React from 'react';
import { string } from 'prop-types';
import theme from 'css/themes/homePage/oneStopHome.css';

class OneStopItem extends React.Component {
  static propType = {
    icon: string.isRequired,
    title: string.isRequired,
    describe: string.isRequired
  }
  render() {
    const {
      icon,
      title,
      describe
    } = this.props;

    return(
      <div className={ theme.item }>
        <img alt={title} src={ `images/login/homepage/${icon}.svg` }
          className={ `${theme[icon]} ${theme.icon}` }
        />
        <p className={ theme.itemTitle }>
          { title }
        </p>
        <p className={ theme.itemDescribe }>
          { describe }
        </p>
      </div>
    )
  }
}

export default OneStopItem;
