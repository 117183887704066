import React, { Component } from 'react';
import Header from '../header/Header';
import theme from 'css/themes/homePage/sweepstakes.css';

class SweepstakesBanner extends Component {
  render() {
    return(
      <div className={ theme.banner }>
        <Header />
        <div className={ theme.container }>
          <div className={ theme.leftBannerCol }>
            <div className={ theme.wrapper }>
              <p className={ theme.title }>
                Sweepstakes
              </p>
              <p className={ theme.tag }>
                Growing your audience doesn’t have to take months. Giveaways are a low cost, high ROI way to grow your email list and social following. Find brand partners, create beautiful sweepstakes pages and measure results - all in one place.
              </p>
            </div>
          </div>
          <div className={ theme.rightBannerCol }>
            <img
              src="/images/login/homepage/sweepstakes-template@2x.png"
              srcSet={`
                /images/login/homepage/sweepstakes-template.png,
                /images/login/homepage/sweepstakes-template@2x.png 2x,
                /images/login/homepage/sweepstakes-template@3x.png 3x
              `}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SweepstakesBanner;
