import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import injectSheet from 'react-jss'
import COLOURS from 'util/colours'
import { Small } from 'visual-components/util/texts'
import Spinner from 'util/components/spinner'

const styles = {
  emptyDropzone: {
    width: 272,
    height: 128,
    border: `1px solid ${COLOURS.silver}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 32,
  },
  populatedDropZone: {
    width: 272,
    height: 40,
    border: `1px solid ${COLOURS.silver}`,
    cursor: 'pointer',
    padding: '14px 16px',
    marginBottom: 32,
  },
  plusSign: {
    marginBottom: 24,
  },
  previewImage: {
    width: 272,
    display: 'block',
  },
}

const ImageUploader = props => {
  const {
    accept,
    maxSize,
    src,
    loading,
    onDrop,
    classes: css,
    dropzoneClassname,
    label,
    ...restProps
  } = props
  const dropZoneStyles = src ? css.populatedDropZone : css.emptyDropzone
  return (
    <>
      { src && <img className={css.previewImage} src={src} /> }
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        maxSize={maxSize}
        {...restProps}
      >
        {({
          getRootProps,
          getInputProps,
        }) => (
          <div
            className={dropzoneClassname || dropZoneStyles}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            { !src && <img className={css.plusSign} src="/images/builder/icons/upload-image.svg" />}
            <Small azure>
              <b>{ label || (src ? 'Change Image' : 'Upload Image')}</b>
            </Small>
          </div>
        )}
      </Dropzone>
      { loading && <Spinner /> }
    </>
  )
}

ImageUploader.propTypes = {
  onDrop: PropTypes.func.isRequired,
  src: PropTypes.string,
  dropzoneClassname: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  accept: PropTypes.object,
  maxSize: PropTypes.number,
  classes: PropTypes.object.isRequired,
}

ImageUploader.defaultProps = {
  src: null,
  loading: false,
  accept: {
    'image/x-png': [],
    'image/gif': [],
    'image/jpeg': [],
  },
  maxSize: 5242880,
  dropzoneClassname: '',
  label: '',
}

export default injectSheet(styles)(ImageUploader)
