import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

import SocialDistributionCard from 'visual-components/analytics/campaign/social-distribution-card';
import SocialTitleArray from 'util/socialTypeArray';
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher';

const socialTitles = SocialTitleArray.map((item) => {
  return item.title;
});

class SocialGrowth extends React.Component {
  state = {
    hideMap: {}
  }

  updateBrand = (brandId) => {
    var data = {
      metric: 'campaignSocialActionsByType',
      campaignId: this.props.campaignId,
      specificBrandId: brandId
    };

    AnalyticsDispatcher.fetchReport(data, 'analyticsCampaign');
  }

  toggleItem = (item) => {
    var hideMap = this.state.hideMap;
    hideMap[item] = !hideMap[item]

    this.setState({
      hideMap: hideMap
    });
  }

  render() {
    let {
      socialItems,
      isSolo,
    } = this.props;

    return (
      <SocialDistributionCard { ...this.props.data }
        hideMap={ this.state.hideMap }
        toggleItem={ this.toggleItem }
        onChange={this.updateBrand}
        isSolo={isSolo}
      />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    data: state.analyticsCampaign.campaignSocialActionsByType,
    campaignId: state.analyticsCampaign.campaign.id,
    isSolo: state.analyticsCampaign.campaign.isSolo,
  };
}

export default connect(mapStateToProps)(SocialGrowth);
