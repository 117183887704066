import React from 'react'
import PropTypes from 'prop-types'
import MultiTaskModal from 'visual-components/util/modals/MultiTaskModal'
import {
  SimpleForm, Input, Validations, Checkbox,
} from 'visual-components/util/form'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import { P, H6 } from 'visual-components/util/texts'
import UploadImage from 'components/util/editor-uploader'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import injectSheet from 'react-jss'
import cn from 'classnames'
import COLOURS from 'util/colours'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

const { isWebsiteIfExists } = Validations

const styles = {
  hrContainer: {
    borderTop: `1px solid ${COLOURS.silver}`,
    marginTop: '6px', // to counter hr's negative top margin
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  hr: {
    margin: '-6px 16px 0px',
    backgroundColor: '#ffffff',
    fontFamily: 'Larsseit-Medium',
    width: '52px',
    color: COLOURS.ink,
  },
  uploadImage: {
    // marginTop: 10,
    border: `1px solid ${COLOURS.silver}`,
    height: 80,
    width: 'auto',
    paddingTop: 21,
    marginBottom: 32,
  },
  uploadButton: {
    color: '#03a9f4 !important', //brand blue
    fontFamily: 'Larsseit-Bold',
  },
  checkbox: {
    marginBottom: 32,
  },
  urlContainer: {
    marginBottom: 32,
    paddingTop: 8,
  },
  urlHeader: {
    padding: '0px 24px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  url: {
    padding: '8px 42px 8px 16px',
    border: '1px solid #d8d8d8',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

class CCPAModal extends React.Component {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    policyActions: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  onDelete = () => {
    const { policyActions: { reset, openModal } } = this.props
    reset()
    setTimeout(openModal, 1000)
  }

  onUpload = file => {
    const { policyActions: { updatePolicy } } = this.props
    updatePolicy({ ...file, type: 'upload' })
  }

  renderForm = () => {
    const {
      policy: { tempPolicy },
      classes: css,
    } = this.props

    if (!tempPolicy || !tempPolicy.link) {
      return (
        <>
          <Input
            name="link"
            label="Link to your CCPA Compliant Privacy Policy"
            placeholder="http(s)://www.companysite.com/privacy-policy"
            validations={[isWebsiteIfExists]}
          />

          <div className={css.hrContainer}>
            <div className={css.hr}>OR</div>
          </div>

          <div className={css.uploadImage}>
            <UploadImage
              success={this.onUpload}
              action="/brand-data-disclosures/policy/upload"
              name="brand-data-disclosures"
              acceptedFiles="application/pdf,application/msword,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              maxFileSize={5}
            >
              <Button
                theme={buttonTheme}
                className={cn(buttonTheme.button, css.uploadButton)}
                label="Upload as a Document or PDF"
              />
            </UploadImage>
          </div>
        </>
      )
    }

    const {
      filename,
      link,
      type,
    } = tempPolicy

    return !type || type === 'url' ? (
      <div>
        <Input
          name="link"
          label="Link to your CCPA Compliant Privacy Policy"
          placeholder="http(s)://www.companysite.com/privacy-policy"
          validations={[isWebsiteIfExists]}
        />
      </div>
    ) : (
      <div className={css.urlContainer}>
        <Row className={css.urlHeader}>
          <Col>
            <H6>File Name</H6>
          </Col>
          <Col>
            <H6>Actions</H6>
          </Col>
        </Row>
        <Row className={css.url}>
          <Col>
            <P>{filename || link}</P>
          </Col>
          <Col>
            <div>
              <img
                src="/images/icons/blue-trash.svg"
                onClick={this.onDelete}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const {
      brand,
      policy: { tempPolicy, uploadModalOpen },
      policyActions: {
        updatePolicy,
        submitPolicy,
        closeModal,
      },
      classes: css,
    } = this.props

    return (
      <MultiTaskModal
        title="Please Provide Your CCPA Compliant Privacy Policy"
        active={uploadModalOpen}
        close={closeModal}
      >
        <SimpleForm
          model={tempPolicy}
          updateModel={data => {
            // Prevents inconsistent behavior when switching from url to upload directly after adding/modifying url
            if (data.id || (!data.link && tempPolicy.link && tempPolicy.type === 'upload')) return
            updatePolicy(data)
          }}
          handleSubmit={() => {
            submitPolicy({ ...tempPolicy, regulation: 'ccpa' })
            closeModal()
          }}
        >
          {this.renderForm()}

          <div className={css.checkbox}>
            <Checkbox
              label={(
                <H6 multiline>
                  {`I certify that ${brand.accountname}'s Privacy Policy describes the ${brand.accountname}'s sharing of consumers' information with third parties (like DojoMojo) as outlined in `}
                  <a href="https://help.dojomojo.com/hc/en-us/articles/360037641352" target="_blank" rel="noopener noreferrer">DojoMojo’s CCPA FAQs</a>
                  .
                </H6>
              )}
              name="certifyBox"
              required
            />
          </div>

          <ModalButtonGroupWithContext
            cancel={closeModal}
            cancelText="Cancel"
            confirmText="Confirm & Upload"
            canSave={tempPolicy && tempPolicy.link && tempPolicy.certifyBox}
          />
        </SimpleForm>
      </MultiTaskModal>
    )
  }
}

export default injectSheet(styles)(CCPAModal)
