import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import formModalTheme from 'css/themes/dialogs/formModal.css'
import { H4 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
const { ink , silver } = COLOURS

const TextAreaStyle = {
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
  paddingBottom: '16px',
  width: '100%',
  height: '50px',
  paddingLeft: '14px',
  backgroundColor: 'transparent',
  color: ink,
  fontFamily: 'Larsseit-Light',
  fontSize: '14px',
  outline: 'none',
  boxShadow: 'none',
  WebkitBoxShadow: 'none',
  borderRadius: '1px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: silver,
  height: 'auto',
  resize: 'none',
  marginBottom: '32px',
}

const mapStateToProps = ({ profile }) => ({ profile })

export class ErrorBoundary extends Component {
  static defaultProps = {
    profile: {},
  }

  state = {
    error: null,
    info: null,
    event_id: null,
    comments: '',
    submitting: false,
  }

  componentDidUpdate(prevProps) {
    // reset error if changing location
    if (prevProps.location && prevProps.location !== this.props.location) {
      this.setState({
        error: null,
        info: null,
        event_id: null,
        comments: '',
        submitting: false,
      })
    }
  }

  componentDidCatch(error, info) {
    // if production, fetch last event ID from Sentry
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setTag('platformCrash', true)
        scope.setExtras(info)
        const event_id = Sentry.captureException(error)
        this.setState({ error, info, event_id })
      })
    }
  }

  updateComment = e => this.setState({ comments: e.target.value })

  submit = () => {
    this.setState({ submitting: true })
    const { event_id, comments } = this.state
    const { displayName: name, email } = this.props.profile
    if (!event_id || !comments || !name || !email) {
      return this.goBack()
    }
    $.ajax({
      url: '/crash-report',
      method: 'POST',
      data: JSON.stringify({ event_id, name, email, comments }),
      contentType: 'application/json',
      success: this.goBack,
      error: err => {
        console.error(err)
        this.goBack()
      },
    })
  }

  goBack = () => {
    const { history } = this.props
    if (history) {
      history.goBack()
    } else {
      window.location = '/'
    }
  }

  renderDialog() {
    const { error, event_id, comments, submitting } = this.state
    return (
      <Dialog
        theme={formModalTheme}
        active={!!error}
        title="Oops! We’re Experiencing Some Technical Difficulties"
      >
        {event_id && (
          <img
            onClick={this.goBack}
            className={formModalTheme.closeImage}
            src="/images/icons/close.svg"
          />
        )}
        <div
          className={`${dialogTheme.bannerWrapper} ${dialogTheme.orangeBanner}`}
        >
          <img src="/images/icons/error-message-icon.svg" />
        </div>
        <H4 multiline>
          Try refreshing the page or hitting the back button on your browser.
          <br />
        </H4>
        {event_id && (
          <div>
            <H4 multiline>
              If you'd like to submit a crash report, please tell us what
              happened below:
              <br />
            </H4>
            <textarea
              style={TextAreaStyle}
              placeholder="I clicked on 'X' and then hit 'Confirm'"
              value={comments}
              onChange={this.updateComment}
              className="form-control borderAzureFocus"
              rows="3"
            />
          </div>
        )}
        <ModalButtonGroup
          hideLine={!event_id}
          canSave={!submitting}
          confirm={event_id ? this.submit : this.goBack}
          confirmText={
            event_id
              ? submitting ? 'Sending...' : 'Send Crash Report'
              : 'Close'
          }
          cancel={event_id && this.goBack}
          cancelText={event_id && 'Close'}
        />
      </Dialog>
    )
  }

  renderDevDebugger() {
    const { error, info } = this.state

    return (
      <div style={{ margin: '30px' }}>
        <h1>
          <br />APP CRASHED:
        </h1>
        <pre>{error && error.stack}</pre>
        <h3>Where:</h3>
        <pre>{info.componentStack || info}</pre>
      </div>
    )
  }

  render() {
    const { error, info } = this.state
    // if no error, render children
    if (!error && !info) {
      return <div>{this.props.children}</div>
    }

    // when in prodution, render dialog
    if (process.env.NODE_ENV === 'production') {
      return this.renderDialog()
    }

    // otherwise render debugger in dev mode
    return this.renderDevDebugger()
  }
}

export default withRouter(connect(mapStateToProps)(ErrorBoundary))
