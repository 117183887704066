import React from 'react'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'
import { StyleRoot } from 'radium'
import CustomCss from 'templates/components/custom-css'
import NotStartedWarning from 'templates/versions/NotStartedWarning'
import PreviewLinkModal from 'templates/versions/PreviewLinkModal'
import cn from 'classnames'
import PostEntry from 'util/components/templates/form/post-entry'
import getEditorMenuWidth from './utils/getEditorMenuWidth'
import HeroImage from './HeroImage'
import PoweredByDojo from './PoweredByDojo'
import constants from './constants.json'

const menuWidth = props => (getEditorMenuWidth(
  props.updateAttr,
  props.desktopVersion,
  props.isEditorMenuOpen
))

const styles = {
  bodyContainer: {
    width: props => (!props.updateAttr && props.desktopVersion ? '100vw' : '100%'),
    height: props => (
      props.desktopVersion
        ? (props.updateAttr ? `calc(100% - ${constants.topNavHeight}px)` : '100vh')
        : '100%'
    ),
    //make space for the menu
    paddingLeft: menuWidth,
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 1,
    '-webkit-overflow-scrolling': 'touch',
    'overflow-scrolling': 'touch',
    transition: `padding ${constants.menuTransitionTime}`,
  },
  body: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  backgroundContainer: {
    position: 'fixed',
    top: 0,
    left: menuWidth,
    height: '100vh',
    width: props => (`calc(100vw - ${menuWidth(props)}px)`),
    overflow: 'hidden',
    transition: `left ${constants.menuTransitionTime}, width ${constants.menuTransitionTime}`,
  },
  backgroundInMobileFrame: {
    position: 'absolute',
    top: 97,
    left: '28px !important',
    right: 25,
    bottom: 99,
    width: 'auto !important',
    height: 'auto',
    overflow: 'hidden',
  },
}


class TemplateContainer extends React.Component {
  static propTypes = {
    classes: propTypes.object.isRequired,
    children: propTypes.any.isRequired,
    campaign: propTypes.object.isRequired,
    elements: propTypes.object.isRequired,
    isFixedBackgroundImage: propTypes.bool,
    desktopVersion: propTypes.bool.isRequired,
    updateAttr: propTypes.func.isRequired,
    campaignUpdateAttr: propTypes.func.isRequired,
    acceptedInvites: propTypes.array.isRequired,
    isEditorMenuOpen: propTypes.bool.isRequired,
  }

  static defaultProps = {
    isFixedBackgroundImage: true,
  }

  static contextTypes = {
    userStyles: propTypes.object,
  }

  render() {
    const {
      children,
      classes: css,
      campaign,
      elements,
      isFixedBackgroundImage,
      desktopVersion,
      campaignUpdateAttr,
      acceptedInvites,
    } = this.props

    return (
      <StyleRoot>
        <CustomCss customCss={elements.customCss} />
        <PreviewLinkModal />
        <NotStartedWarning startDate={campaign.startDate} />
        <div
          className={css.bodyContainer}
          id="body-container"
        >
          <div
            className={css.body}
            id="body"
          >
            {children}
          </div>
        </div>
        {
          isFixedBackgroundImage
          && (
            <div
              className={cn({
                [css.backgroundContainer]: true,
                [css.backgroundInMobileFrame]: !desktopVersion,
              })}
            >
              <HeroImage
                imageUrl={elements.mainImage}
                desktopVersion={desktopVersion}
                editorMenuWidth={menuWidth(this.props)}
              />
            </div>
          )
        }
        <PoweredByDojo
          updateAttr={campaignUpdateAttr}
          campaign={campaign}
        />
        <PostEntry
          {...this.props}
          acceptedInvites={acceptedInvites}
        />
      </StyleRoot>
    )
  }
}
export default injectSheet(styles)(TemplateContainer)
