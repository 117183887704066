import React, { useState, useEffect } from 'react'
import ExplorePageTitle from 'visual-components/shared/explore/explore-page-title'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P } from 'visual-components/util/texts'
import OverviewBar from 'visual-components/analytics/util/overview-bar'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { round } from 'lodash'
import Spinner from 'util/components/spinner'
import { sortNumbers, sortStrings } from 'util/sortHelper'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import SmsAnalyticsTables from './SmsAnalyticsTables'

const styles = {
  halfRow: {
    width: 444,
  },
}

const formatOverviewData = data => [
  {
    label: 'signups',
    value: data.subscribers,
  },
  {
    label: 'messages sent',
    value: data.messages,
  },
  {
    label: 'CTR',
    value: data.messages > 0 ? round((data.clicks / data.messages) * 100, 2) : 0,
    isPercent: true,
  },
  {
    label: 'orders',
    value: data.order_count,
  },
  {
    label: 'revenue',
    isDollar: true,
    value: data.revenue,
  },
  {
    label: 'unsubscribe rate',
    value: data.messages > 0 ? round((data.unsubscribes / data.messages) * 100, 2) : 0,
    isPercent: true,
  },
]

const SmsAnalytics = props => {
  const {
    currentBrand,
  } = props


  const [loading, setLoading] = useState(true)
  const [overviewBarMetrics, setOverviewBarMetrics] = useState([])
  const [smsCampaignData, setSmsCampaignData] = useState([])
  const [smsContributionData, setSmsContributionData] = useState([])

  useEffect(() => {
    axios.get('/analytics/campaign-sms', {
      headers: {
        brand: currentBrand.id,
        impersonating: sessionStorage.getItem('impersonating'),
      },
    })
      .then(res => {
        setOverviewBarMetrics(formatOverviewData({
          ...res.data.overview,
          ...res.data.revenue,
        }))
        setSmsCampaignData(res.data.smsAnalyticsPerCampaign)
        setSmsContributionData(res.data.smsContributionsPerPartner)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const exportCSVData = () => console.log('TODO: export data from this button')

  if (loading) return <Spinner />

  return (
    <Grid>
      <Row style={{ marginTop: '32px', marginBottom: '16px' }}>
        <Col xs>
          <ExplorePageTitle
            title="SMS Marketing Analytics"
            // action={exportCSVData}
            // actionLabel="Export CSV" //todo make this work
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '24px' }}>
        <P multiline>See the results of your SMS Marketing efforts from sweepstakes.</P>
      </Row>
      <Row style={{ marginBottom: '24px' }}>
        <OverviewBar
          overviewCellWidth="16.66%"
          metrics={overviewBarMetrics}
          eventTrackingLocation="dashboardSmsAnalyticsTopBar"
        />
      </Row>

      <SmsAnalyticsTables
        smsCampaignData={smsCampaignData}
        smsContributionData={smsContributionData}
      />
    </Grid>
  )
}

SmsAnalytics.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = ({ currentBrand }) => ({ currentBrand })

export default connect(mapStateToProps)(injectSheet(styles)(SmsAnalytics))
