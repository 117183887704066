import React from 'react'

import Dialog from 'react-toolbox/lib/dialog'
import Field from 'visual-components/util/inputs/field'
import SelectField from 'visual-components/util/inputs/select-field'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/modalBase.css'
import inputTheme from 'css/themes/Input.css'
import { P } from 'visual-components/util/texts'

const initialState = {
  type: `Campaign Launch`,
  details: ''
}

class PartnerAnnouncementModal extends React.Component {
  state = initialState

  updateAttr = (attr, value) => {
    this.setState({
      [attr]: value
    })
  }

  submit = () => {
    const { sendAnnouncement } = this.props
    const { type, details } = this.state
    this.closeModal()
    sendAnnouncement(type, details)
  }

  closeModal = () => {
    this.setState(initialState)
    this.props.closeModal()
  }

  render() {
    const { active, currentCampaign: { name: campaignName } } = this.props
    const { type, details } = this.state

    const options = [{
      value: `Campaign Launch`,
      label: `Campaign Launch`
    }, {
      value: `Promotion Reminder`,
      label: `Promotion Reminder`
    }, {
      value: `Campaign Debrief`,
      label: `Campaign Debrief`
    }, {
      value: 'Winner Information',
      label: 'Winner Information'
    }, {
      value: 'Prizing Reminder',
      label: 'Prizing Reminder'
    }, {
      value: 'General Update',
      label: 'General Update'
    }, {
      value: 'Other',
      label: 'Other'
    }]

    return (
      <div>
        <Dialog active={ active }
          title={ 'Send a Campaign Announcement' }
          theme={ dialogTheme }
          onEscKeyDown={ this.closeModal }
          onOverlayClick={ this.closeModal }
        >
          <img
            onClick={ this.closeModal }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />
          <div style={{
            marginBottom: '16px'
          }}>
            <P>
              Send a message to all confirmed partners on <em>{campaignName}</em>. Announcements will be sent to partners via email and will appear in your Messages inbox.
            </P>
          </div>

          <SelectField label={ 'Type of Announcement' }
            attrName={ 'type' }
            options={ options }
            dataModel={ this.state }
            formModel={ {} }
            updateAttr={ this.updateAttr }
          />

          <Field label={ 'Details' }
            placeholder={ 'Please provide a message.' }
            multiline
            rows={ 5 }
            attrName={ 'details' }
            dataModel={ this.state }
            formModel={ {} }
            updateAttr={ this.updateAttr }
            className={ inputTheme.textarea }
          />

          <ModalButtonGroup canSave={ !!type && !!details }
            confirm={ this.submit }
            confirmText={ 'Send' }
            cancel={ this.closeModal }
            cancelText={ 'Back' }
          />
        </Dialog>
      </div>
    )
  }
}

export default PartnerAnnouncementModal
