import React, { Component } from 'react'
import { Form, Input, Submit, Validations } from 'visual-components/util/form'
import Text from 'util/components/text'
import AppDispatcher from 'dispatchers/app-dispatcher';

const { isNum } = Validations

class MediaMarketSeedClicks extends Component {
  state = {
    dealId: 0,
    startDate: '',
    endDate: ''
  }

  handleChange = e => {
    this.setState(e)
  }

  handleSubmit = () => {
    const data = {...this.state}

    AppDispatcher.http({
      url: '/qa-media-market-seedClicks',
      method: 'POST',
      data,
      success() {
        toastr.success('Deal Seeded!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      },
      error() {
        toastr.error('Cannot Seed Deal - confirm correct Deal ID', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      }
    })

    this.setState({startDate: null, endDate: null, numUniqueClicks: null })
  }


  render() {
    return(
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          marginLeft: '50px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>

          <Text.dojoH2Bold>
            Seed Media Market Clicks
          </Text.dojoH2Bold>

          <Form
            updateModel={ (e) => this.handleChange(e) }
            handleSubmit={ this.handleSubmit }
          >
            <Input
              name="dealId"
              label="Deal ID"
              placeholder="*required"
              type="number"
              required
              validations={[isNum]}
            />

            <Input
              name="numUniqueClicks"
              label="Number of Unique Clicks"
              type="number"
            />

            <Input
              name="startDate"
              label="Start Date"
              type="string"
              placeholder="1-1-19"
            />

            <Input
              name="endDate"
              label="End Date"
              type="string"
              placeholder="2-2-19"
            />

            <Submit
              label="Seed Clicks"
            />
          </Form>

        </div>
      </div>
    )
  }
}





export default MediaMarketSeedClicks
