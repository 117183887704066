import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object, bool, func } from 'prop-types'
import d3 from 'd3'
import COLOURS from 'util/colours'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

import { H6, P } from 'visual-components/util/texts'
import TransactionSubItem from './transaction-subitem'

const userTimezone = moment.tz.guess()

const getTransactionDate = transaction => {
  const { type, txn, proposal } = transaction
  if (type === 'insertionOrder') {
    return moment.tz(proposal.created_at, userTimezone).format('ll')
  }
  return txn ? moment.tz(txn.created * 1000, userTimezone).format('ll') : 'Pending'
}

const getTransactionAmount = transaction => {
  const {
    type, txn, totalQuantity, price, insertionOrderSide, proposal,
  } = transaction
  if (type === 'insertionOrder') {
    return insertionOrderSide === 'seller' ? proposal.max_value : proposal.max_value * -1
  }
  const amount = txn ? txn.amount / 100 : totalQuantity * price
  const totalAmount = txn.object === 'charge'
      ? amount * -1
      : amount
  return totalAmount
}

const mapState = ({
  brandAccount: {
    external_account: {
      bank_name,
      account_number,
      metadata: { isValid } = {},
    } = {},
  },
  currentBrand: { id: currentBrandId },
}) => ({
  currentBrandId,
  bank_name,
  account_number,
  shouldPrompt: (!bank_name && !account_number) || isValid !== 'true',
})

const statusMap = {
  accepted: 'Current',
  live: 'Current',
  completed: 'Past',
  unfulfilled: 'Past',
  in_dispute: 'Past',
  pending: 'In Discussion',
}


class TransactionItem extends React.Component {
  static propTypes = {
    item: object.isRequired,
    isSelected: bool.isRequired,
    setSelected: func.isRequired,
  }

  toggleShow = () => {
    const { setSelected, item: { id } } = this.props
    setSelected(id)
  }

  render() {
    const {
      item: deal,
      isSelected,
      currentBrandId
    } = this.props

    const {
      transactions,
      currentProposal,
      status,
    } = deal

    // max_value represents the IO/proposal total amount
    const { max_value } = currentProposal

    const dealStatus = statusMap[status] || 'Unknown'

    // start date is the date of the first IO/proposal
    // end date is last transaction of deal (if completed), or today if deal is ongoing
    const startDate = getTransactionDate(transactions[transactions.length - 1])
    const endDate = dealStatus === 'Current' ?  moment().format('ll') : getTransactionDate(transactions[0])

    const onlyIO = transactions.every(t => t.type === 'insertionOrder')

    // if all IO, then take current IO. otherwise take sum of all actual transactions (payments, refunds, purchases)
    const dealSummaryValue = onlyIO ? max_value : transactions.reduce((acc, cur) => {
      return acc + (cur.type === 'insertionOrder' ? 0 : getTransactionAmount(cur))
    }, 0)

    const formattedSummaryValue = onlyIO ? d3.format('$,.2f')(dealSummaryValue) : d3.format('+$,.2f')(dealSummaryValue)
    
    const subitems = transactions.map((t,idx) => (
      <TransactionSubItem 
        key={t.id} 
        transaction={t} 
        currentBrandId={currentBrandId} 
      />
    ))

    return (
      <tbody>
        <tr
          onClick={this.toggleShow}
          style={{
            height: '54px',
            cursor: 'pointer',
            borderBottom: `1px solid ${COLOURS.silver}`,
            backgroundColor: isSelected ? '#f4f9fc' : '#fff',
          }}
        >
          <td
            style={{
              width: '190px',
              paddingLeft: '16px',
            }}
          >
            <P>
              {`${startDate} - ${endDate}`}
            </P>
          </td>
          <td
            style={{
              width: '496px',
              paddingLeft: '36px',
            }}
          >
            <P>{currentProposal.label}</P>
          </td>
          <td
            style={{
              width: '90px',
              textAlign: 'right',
            }}
          >
            {
              onlyIO ?
              <P><span style={{ fontStyle: 'italic'}}>{formattedSummaryValue}</span></P>
              :
              <H6>
                <span
                  style={{
                    color: dealSummaryValue >= 0 ? COLOURS.seaGreen : 'inherit',
                  }}
                >
                  {formattedSummaryValue}
                </span>
              </H6>
            }
            
          </td>
          <td
            style={{
              width: '164px',
              paddingLeft: '36px',
            }}
          >
            <P>{ dealStatus }</P>
          </td>
          <td
            style={{
              width: '36px',
              paddingRight: '12px',
            }}
          >
            { isSelected ? 
              <img src="/images/icons/expand-arrow.svg" /> 
              : <img src="/images/icons/collapse-arrow.svg" />
            }
          </td>
        </tr>
        { isSelected && (
          <>
          <div style={{height: '12px'}}/>
          {subitems}
          <tr style={{borderBottom: `1px solid ${COLOURS.silver}`}}>
            <td/>
            <td
              colSpan="4" 
              style={{
                paddingLeft: '36px',
                paddingTop: '16px',
                paddingBottom: '32px',
              }}
            >
              <Link to={`/partnerships/deal/${deal.id}/collaborate`}>
                <Button theme={ ButtonTheme } className={`${ButtonTheme.blueButton} ${ButtonTheme.shortButton}` } primary raised>
                  View Campaign
                </Button>
              </Link>
              </td>
          </tr>
          </>
        )}
      </tbody>
    )
  }
}

export default connect(mapState)(TransactionItem)
