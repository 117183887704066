import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { P } from 'visual-components/util/texts'

const styles = {
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  fadeAway: {
    animation: 'fadeAway 3s linear forwards',
  },
  checkmark: {
    marginRight: 8,
  },
  loading: {
    marginRight: 8,
    animation: 'rotating 1s linear infinite',
  },
  inviteSendingStatusText: {
    marginTop: 4,
  },
  '@keyframes fadeAway': {
    '80%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes rotating': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
}

const message = {
  saving: 'Saving Changes',
  inviteSent: 'Invite Sent',
  saved: 'Changes Saved',
}


const StatusMarker = ({
  classes: css,
  state,
}) => {
  if (!state) return null

  const renderLoading = () => (
    <svg className={css} width="22" height="16" viewBox="0 0 22 16">
      <g fill="none" fillRule="evenodd">
        <path d="M-1-4h24v24H-1z" />
        <path fill="#00CEA9" fillRule="nonzero" d="M18 4l-4 4h3c0 3.31-2.69 6-6 6a5.87 5.87 0 0 1-2.8-.7l-1.46 1.46A7.93 7.93 0 0 0 11 16c4.42 0 8-3.58 8-8h3l-4-4zM5 8c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46A7.93 7.93 0 0 0 11 0C6.58 0 3 3.58 3 8H0l4 4 4-4H5z" />
      </g>
    </svg>
  )

  const renderCheckmark = () => (
    <svg className={css} width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path fill="#14CBA8" fillRule="nonzero" d="M9.9 14.54L6.96 11.6l-.98.98L9.9 16.5l8.4-8.4-.98-.98z" />
      </g>
    </svg>
  )

  return (
    <div className={cn(css.textWrapper, { [css.fadeAway]: ['inviteSent', 'saved'].includes(state) })}>
      {
        state === 'inviteSending'
          ? renderLoading()
          : renderCheckmark()
      }
      <div className={css.inviteSendingStatusText}>
        <P seaGreen>
          <em style={{ whiteSpace: 'nowrap' }}>
            {message[state]}
          </em>
        </P>
      </div>
    </div>
  )
}

StatusMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.string.isRequired,
}

export default React.memo(injectCSS(styles)(StatusMarker))
