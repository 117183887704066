import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

class AdminRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      vals,
      style,
      title,
      cols,
      displayWrapper,
      className
    } = this.props;

    const truncatedVals = vals.slice(vals.length-cols, vals.length);

    return (
      <tr className={className || ""} style={style} >
        <td className="text-left" style={{minWidth: '350px'}}>{title}</td>
        { truncatedVals.map(val => <td className="text-right" style={{minWidth: '100px'}}>{this.props.formatter(val)}</td>) }
      </tr>
    );
  }
}

AdminRow.defaultProps = {
  formatter: (val) => {
    return val;
  },
  cols: 6
};

AdminRow.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  cols: PropTypes.number,
  vals: PropTypes.array,
  formatter: PropTypes.func
};

export default AdminRow;