import React from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import moment from 'moment'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import Spinner from 'util/components/spinner'
import { H2, P } from 'visual-components/util/texts'
import { bindActionCreators } from 'redux'
import { Button } from 'react-toolbox/lib/button'
import cn from 'classnames'

import PixelConfirmationModal from 'visual-components/mediaMarket/analytics/deal-pixel-confirmation-modal'
import DataIntegrationModal from 'components/brand_settings/data-integrations/data-integrations-modal'
import DataIntegrationsMap from 'components/brand_settings/data-integrations/data-integrations-map'
import IntegrationIcon from 'components/brand_settings/data-integrations/data-integration-icon'
import * as DataIntegrationsActions from 'actions/data-integration-actions'
import * as metrics from 'util/metrics'
import sendDataExport from 'util/sendDataExport'

import ButtonTheme from 'css/themes/Buttons.css'

const mapState = state => ({
  profile: state.profile,
  currentBrand: state.currentBrand,
  dataIntegrations: state.dataIntegrations,
})

const mapDispatchToProps = dispatch => ({
  dataIntegrationsActions: bindActionCreators(DataIntegrationsActions, dispatch),
})

class IntegrationsSettings extends React.Component {
  static propTypes = {
    profile: object.isRequired,
    currentBrand: object.isRequired,
    dataIntegrations: object.isRequired,
    dataIntegrationsActions: object.isRequired,
  }

  componentWillMount() {
    const smsStoreIntegrationKey = 'sms_store_integration_setup'
    // check if user came from sms store integrations setup
    const smsStoreIntegration = localStorage.getItem(smsStoreIntegrationKey)
    if (smsStoreIntegration) {
      const item = JSON.parse(smsStoreIntegration)
      // remove the sms store integrations setup cache
      localStorage.removeItem(smsStoreIntegrationKey)
      if (moment().isBefore(moment(item.expiry))) {
        // if the sms store integrations setup is not expired, redirect back to sms settings
        window.location.href = '/app/sms-marketing/settings/integrations'
      }
    } else {
      const { dataIntegrationsActions: { load } } = this.props
      load()
    }
  }

  componentDidMount() {
    const { currentBrand } = this.props

    sendDataExport(currentBrand.id, {
      salesforce: {
        Viewed_Data_Integrations_Page__c: new Date().toISOString(),
      },
    })
  }

  requestIntegration = () => {
    const {
      profile,
      currentBrand,
    } = this.props

    store.dispatch({
      model: 'contactSupport',
      type: 'UPDATE_ATTR',
      data: {
        modalOpen: true,
        brand: currentBrand.accountname,
        name: profile.fullName || `${profile.firstName || ''} ${profile.lastName || ''}`,
        email: profile.email,
        subject: 'Request Data Integration',
      },
    })
  }

  closeModal = () => {
    const { dataIntegrationsActions: { update } } = this.props

    update({ modalOpen: false })

    // This prevents the modal from flickeringly show the data integrations while closing
    setTimeout(() => {
      update({
        step: 'choose',
        type: '',
      })
    }, 500)
  }

  renderDataIntegrationItems = () => DataIntegrationsMap.map(integration => {
    const {
      currentBrand,
      dataIntegrations,
      dataIntegrationsActions: {
        update,
      },
    } = this.props

    let integrated = false
    const integrationRow = dataIntegrations[integration.key]
    switch (integration.key) {
      case 'pixel':
        integrated = !!integrationRow && !!integrationRow
        break
      default:
        integrated = !!integrationRow && !!integrationRow.isIntegrated
        break
    }

    if (dataIntegrations.loading) {
      return (
        <Spinner />
      )
    }

    return (
      <IntegrationIcon
        integrationType={integration.key}
        integrated={integrated}
        onClick={() => {
          metrics.create('dataIntegrationModalOpened', {
            meta: {
              type: integration.key,
            },
          })
          if (!integrated && integration.oauth) {
            window.location.pathname = `/${integration.oauth.route}/${currentBrand.id}`
          } else if (integration.key === 'recharge') {
            window.location.href = '/app/sms-marketing/settings/integrations'
          } else {
            update({
              modalOpen: true,
              step: integrated ? 'edit' : 'setup',
              type: integration.key,
            })
          }
        }}
      />
    )
  })

  render() {
    const {
      currentBrand,
      dataIntegrations,
      dataIntegrationsActions,
    } = this.props

    return (
      <div style={{
        paddingTop: '32px'
      }}>
        <Grid>
          <Row style={{
            marginBottom: '12px'
          }}>
            <Col xs={6}>
              <H2 azure>
                Data Integrations
              </H2>
            </Col>
            <Col xs={6}>
              <Button
                raised
                theme={ButtonTheme}
                className={cn(ButtonTheme.greenButton, ButtonTheme.integrations)}
                onClick={this.requestIntegration}
              >
                Request Integration
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              width: '752px',
              marginBottom: '24px',
            }}
          >
            <P multiline>
              Integrate with these services to allow DojoMojo to provide insight into which partnerships drive the most value for your brand.
            </P>
          </Row>
          <Row>
            {this.renderDataIntegrationItems()}
          </Row>
        </Grid>
        <DataIntegrationModal
          currentBrand={currentBrand}
          dataIntegrations={dataIntegrations}
          dataIntegrationsActions={dataIntegrationsActions}
          closeModal={this.closeModal}
        />
        <PixelConfirmationModal
          active={!!dataIntegrations.pixelConfirmationActive}
          success={dataIntegrations.pixelConfirmationSuccess}
          actions={dataIntegrationsActions}
        />
      </div>
    )
  }
}

export default connect(mapState, mapDispatchToProps)(IntegrationsSettings)
