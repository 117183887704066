import React, { PureComponent as Component } from 'react'
import { Link } from 'react-router-dom'
import { object, bool, string, number } from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import MessengerTheme from 'css/themes/media-market/Messenger.css'
import { Small, Tiny } from 'visual-components/util/texts'
import PlainTextMessage from './messageDisplayVisuals/PlainTextMessage'
import ProposalMessage from './messageDisplayVisuals/ProposalMessage'
import RequestMessage from './messageDisplayVisuals/RequestMessage'
import DealStatusMessage from './messageDisplayVisuals/DealStatusMessage'
import ProposalStatusMessage from './messageDisplayVisuals/ProposalStatusMessage'
import AssetVisualProcessor from './messageDisplayVisuals/assetVisualProcessor'
import LinkMessage from './messageDisplayVisuals/LinkMessage'
import NewTargetLinkMessage from './messageDisplayVisuals/NewTargetLinkMessage'
import DeclinedReasonMessage from './messageDisplayVisuals/DeclinedReasonMessage'

const mapStateToProps = ({ currentBrand }) => ({
  currentBrand,
})

class MessageWrapper extends Component {
  static propTypes = {
    messageType: string,
    item: object.isRequired,
    utilityFunctions: object.isRequired,
    showDate: bool.isRequired,
    statusMap: object.isRequired,
    isSender: bool.isRequired,
    isProposingBrand: bool.isRequired,
    currentBrand: object.isRequired,
    buying_brand: object.isRequired,
    selling_brand: object.isRequired,
    proposingUser: object,
    selling_brand_id: number.isRequired,
  }

  static defaultProps = {
    proposingUser: {},
    messageType: 'message',
  }

  renderSenderLogo(messageSide, isFullWidth) {
    const {
      buying_brand,
      selling_brand,
      selling_brand_id,
      currentBrand,
      isSender,
    } = this.props

    let messageAuthor
    if (isSender) {
      messageAuthor = currentBrand
    } else {
      // If currentBrand is not the sender, then the counter party is either
      // the buyer or seller, determined to be whichever the currentBrand is not.
      messageAuthor = currentBrand.id === selling_brand_id
        ? buying_brand
        : selling_brand
    }

    const renderedBrand = {
      logo: messageAuthor.logo,
      id: messageAuthor.id,
    }

    return !isFullWidth && (
      <div
        className={MessengerTheme.logoContainer}
        style={{ [messageSide]: -42 }}
      >
        <div className={MessengerTheme.logoBox}>
          <Link to={`/explore/brands/${renderedBrand.id}`} target="_blank">
            <img src={renderedBrand.logo} className={MessengerTheme.brandLogo} />
          </Link>
        </div>
      </div>
    )
  }

  render() {
    const {
      item,
      item: {
        user_name,
        content,
        created_at,
        messageType,
        is_edited,
      },
      utilityFunctions,
      showDate,
      statusMap,
      isSender,
      currentBrand: {
        id: currentBrandId,
        features: { mediaMarketSeller },
      },
      isProposingBrand,
      buying_brand,
      selling_brand_id,
    } = this.props

    // Expired offer - Brand (Original Seller/Buyer) clicks the "Sell Media",
    // logic finds to which brand media is being sold in "Create New Proposal"
    const buyingBrandId = currentBrandId === selling_brand_id ? buying_brand.id : selling_brand_id
    let messageSide = isSender ? 'right' : 'left'
    messageSide = messageType === 'status' && isProposingBrand ? 'left' : messageSide

    const isFullWidth = ['proposalStatus', 'newTargetLink'].includes(messageType)

    const themes = {
      timeStamp: ((isSender && isProposingBrand && messageType !== 'status')
        || (isSender && !isProposingBrand && messageType !== 'status')
        || (isSender && !isProposingBrand && messageType === 'status')
        || (!isSender && isProposingBrand && messageType === 'status'))
        ? MessengerTheme.senderTimeStamp : MessengerTheme.receiverTimeStamp,
      messageComponent: messageSide === 'right' ? MessengerTheme.componentRight : MessengerTheme.componentLeft,
      proposalStatus: MessengerTheme.proposalStatus,
    }

    const messageComponent = {
      link: () => (
        <LinkMessage
          {...item}
          isSender={isSender}
        />
      ),
      declinedReason: () => (
        <DeclinedReasonMessage {...item} isSender={isSender} />
      ),
      message: () => (
        <PlainTextMessage
          content={content}
          isSender={isSender}
        />
      ),
      proposal: () => (
        <ProposalMessage
          item={item}
          utilityFunctions={utilityFunctions}
          isSender={isSender}
          statusMap={statusMap}
        />
      ),
      status: () => (
        <DealStatusMessage
          isProposingBrand={isProposingBrand}
          status={item.status}
        />
      ),
      asset: () => (
        <AssetVisualProcessor
          assets={item.assets}
          images={item.images}
          isSender={isSender}
          currentBrandId={currentBrandId}
        />
      ),
      proposalStatus: () => (
        <ProposalStatusMessage
          status={item.status}
          canSell={!!mediaMarketSeller}
          buyingBrandId={buyingBrandId}
        />
      ),
      newTargetLink: () => (
        <NewTargetLinkMessage
          buyingBrand={item.buyingBrand}
          numLinks={item.numLinks}
          dealId={item.dealId}
        />
      ),
      request: () => (
        <RequestMessage
          item={item}
          utilityFunctions={utilityFunctions}
          isSender={isSender}
          statusMap={statusMap}
        />
      ),
    }

    const MessageComponent = messageComponent[messageType || 'message']()

    return (
      <div className={
        `${MessengerTheme.messageBlock}
         ${['proposalStatus', 'newTargetLink'].includes(messageType)
          ? MessengerTheme.messageProposalStatus
          : ''
        }`
      }>
        { showDate && (
          <div className={MessengerTheme.fullDate}>
            <Small cloudGrey>
              <i>{moment(created_at).format('dddd, MMMM D, YYYY')}</i>
            </Small>
          </div>
        )}
        <div
          className={classNames(
            MessengerTheme.main,
            MessengerTheme.timeAndMessageContainer, {
              [MessengerTheme.fullWidthRow]: isFullWidth,
            }
          )}
        >
          {!isFullWidth && (
            <div className={
              isSender
                ? MessengerTheme.fromUserName
                : MessengerTheme.toUserName
            }>
              <Tiny cloudGrey>
                <em>
                  <span style={{ fontFamily: 'Larsseit-Medium' }}>
                    {user_name}
                  </span>
                  {' at '}
                  {moment(created_at).format('h:mm a')}
                  {is_edited ? ' (edited)' : ''}
                </em>
              </Tiny>
            </div>
          )}
          <div
            className={
              classNames(
                themes.messageComponent,
                themes[messageType], {
                  [MessengerTheme.fullWidthCard]: isFullWidth,
                }
              )}
          >
            { messageSide === 'left' && this.renderSenderLogo(messageSide, isFullWidth) }
            { MessageComponent }
            { messageSide === 'right' && this.renderSenderLogo(messageSide, isFullWidth) }
          </div>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(MessageWrapper)
