import React, { Component } from 'react';
import COLOURS from 'util/colours';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class BrandProfileLi extends Component {
  constructor(props) {
    super(props);
  }

  goToProfile = () => {
    this.props.dispatch({
      model: 'currentBrand',
      data: {
        questOpen: true
      },
      type: 'UPDATE_ATTR'
    })
    this.props.handleMenuHide()
  }

  render() {

    var {
      brandName,
      brandLogo,
      profileHref,
      handleMenuHide
    } = this.props;

    return (
      <li>
        <div style={{
          display: 'flex',
          display: '-webkit-flex', /* Safari */
          padding: '16px',
        }}>
          <div style={{
            borderRadius: '50%',
            overflow: 'hidden',
            height: '48px',
            width: '48px',
          }}>
            <img
              src={brandLogo}
              style={{
                backgroundColor: 'white',
                objectFit: 'contain',
                height: '100%',
                width: '100%',
              }}
            />
          </div>
          <div style={{
            paddingLeft: '12px',
            width: '144px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <p style={{
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              hyphens: 'auto',
              whiteSpace: 'normal',
              fontFamily: 'Larsseit-Bold',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'none',
              color: COLOURS.ink
            }}>
              { brandName }
            </p>
          </div>
        </div>
      </li>

    )
  }
}

BrandProfileLi.propTypes = {
  brandName: PropTypes.string.isRequired,
  brandLogo: PropTypes.string.isRequired,
  profileHref: PropTypes.string.isRequired
}

export default connect()(BrandProfileLi)
