import React from 'react';
import BuilderHeader from 'components/campaigns/builder/builder-header';
import Basics from 'campaigns/builder/basics/basics';
import Spinner from 'util/components/spinner';
import Validator from 'util/form-validator'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'

import * as ReactRedux from 'react-redux';
import { getClaim } from 'actions/brand-claim-actions';

var mapState = function (state) {
  return state;
}
export default ReactRedux.connect(mapState)(class extends React.Component {
  save = (dest) => {
    const validations = {
      name: [Validator.required, Validator.maxLength(85, 'Please limit to 85 characters')],
      description: [Validator.required, Validator.maxLength(125, 'Please limit to 125 characters')],
      startDate: [Validator.required],
      endDate: [Validator.required],
      industry: [Validator.required],
      prizingDescription: [Validator.required, Validator.maxLength(375, 'Please limit to 375 characters')],
      prizingValue: [Validator.required]
    }

    var that = this;
    Validator.validateForm(validations, this.props.currentCampaign, 'campaignSetupForm', function(valid) {
      if (valid) {
        CampaignDispatcher.createCampaignRequest(that.props.currentCampaign,dest)
      } else {
        console.log("INVALID FORM")
      }
    })
  };

  nextSave = (e) => {
    this.save('select-template');
  };

  navigateSave = (step) => {
    this.save(step)
  };

  exit = () => {
    history.back();
  };

  componentWillMount() {
    store.dispatch({
      type: 'LOAD_EMPTY',
      model: 'currentCampaign'
    })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.brandClaims.loading && prevProps.brandClaims.loading) {
      const canCreatePartnershipSweepstakes = getClaim('sweepstakes.partnership.create')
      if (!canCreatePartnershipSweepstakes) {
        window.location.href = '/'
      }
    }
  }

  render() {
    if (this.props.currentCampaign.loading) {
      return (
      <Spinner/>
      )
    }
    return (
      <div>
        <BuilderHeader
          {...this.props}
          next={this.nextSave}
          exitClick={this.exit} />
        <Basics
          isHost={true}
          campaign={this.props.currentCampaign}
          updateAttr={CampaignDispatcher.updateAttr} />
      </div>
    );
  }
});
