import React from 'react';

class CustomCss extends React.Component {
  render() {
    return (
      <style dangerouslySetInnerHTML={{__html: this.props.customCss}} />
    );
  }
}

export default CustomCss;