import React from 'react'

import Dialog from 'react-toolbox/lib/dialog'
import Field from 'visual-components/util/inputs/field'
import SelectField from 'visual-components/util/inputs/select-field'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/modalBase.css'
import inputTheme from 'css/themes/Input.css'
import { P } from 'visual-components/util/texts'

const initialState = {
  type: '',
  details: ''
}

class ReportPartnerModal extends React.Component {
  state = initialState

  updateAttr = (attr, value) => {
    this.setState({
      [attr]: value
    })
  }

  submit = () => {
    const { reportPartner } = this.props
    const { type, details } = this.state
    reportPartner(type, details)
    this.closeModal()
  }

  closeModal = () => {
    this.setState(initialState)
    this.props.closeModal()
  }

  render() {
    const { active, partnerId, partnerName } = this.props
    const { type, details } = this.state

    const options = [{
      value: '',
      label: `Select a category`
    }, {
      value: `Partner didn't promote`,
      label: `Partner didn't promote`
    }, {
      value: `Partner didn’t send prizing`,
      label: `Partner didn’t send prizing`
    }, {
      value: `Partner not responsive to communication`,
      label: `Partner not responsive to communication`
    }, {
      value: 'Other',
      label: 'Other'
    }]

    return (
      <div>
        <Dialog active={ active }
          title={ 'We’re Here to Help!' }
          theme={ dialogTheme }
          onEscKeyDown={ this.closeModal }
          onOverlayClick={ this.closeModal }
        >
          <img
            onClick={ this.closeModal }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />
          <div style={{
            marginBottom: '16px'
          }}>
            <P>
              We’re sorry to hear that you’re having trouble running this campaign with <em>{partnerName}</em>. Please provide us with details so we can contact you and try to resolve the issue.
            </P>
          </div>

          <SelectField label={ 'Issue Type' }
            attrName={ 'type' }
            options={ options }
            dataModel={ this.state }
            formModel={ {} }
            updateAttr={ this.updateAttr }
          />

          <Field label={ 'Details' }
            placeholder={ 'Please provide details and context about the issue.' }
            multiline
            rows={ 5 }
            attrName={ 'details' }
            dataModel={ this.state }
            formModel={ {} }
            updateAttr={ this.updateAttr }
            className={ inputTheme.textarea }
          />

          <ModalButtonGroup canSave={ !!type && !!details}
            confirm={ this.submit }
            confirmText={ 'Submit' }
            cancel={ this.closeModal }
            cancelText={ 'Back' }
          />
        </Dialog>
      </div>
    )
  }
}

export default ReportPartnerModal
