import moment from 'moment-timezone'

const model = 'newsFeed'

const defaultState = {
  rid: model,
  start: 0,
  size: 10,
  latest: null,
  hasMore: true,
  items: [],
}

const NewsFeedReducer = function(state, action) {
  if (action.model !== model) {
    return state || defaultState
  }

  const actions = {
    
    CLEAR() {
      return Object.assign({}, defaultState)
    },

    UPDATE_ATTR() {
      return Object.assign({}, state, action.data)
    },

    LOADING() {
      return Object.assign({}, state, { loading: true, error: null })
    },

    LOADING_NEW() {
      return Object.assign({}, state, { loadingNew: true, error: null })
    },

    LOAD_SUCCESS() {
      let data = Object.assign({}, action.data, { loading: false, loadingNew: false })
      if (action.data.direction === 'older') {
        data.items = state.items.concat(data.items)
      } else { // newer
        data.items = data.items.concat(state.items)
      }

      return Object.assign({}, state, data)  
    },

    LOAD_FAILURE() {
      return Object.assign({}, state, {
        loading: false,
        loadingNew: false,
        error: action.data || 'Error', 
      })
    },
  }

  const item = actions[action.type] || (() => state || defaultState)
  return item()
}

export default NewsFeedReducer
