import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom'

import CampaignBuilderSection from 'campaigns/builder/components/campaign-builder-section'
import AgreementHeader from './agreement-header'
import AgreementMap from './agreement-map'
import Spinner from 'util/components/spinner'

import ShareAgreementDialog from './sections/agreement-items/share-agreement-dialog'
import ConfirmPartnerDialog from './sections/agreement-items/confirm-partner-dialog'
import ConfirmedPartnerDialog from './sections/agreement-items/confirmed-partner-dialog'

import * as PartnershipAgreementActions from 'actions/partnership-agreement-actions'

const mapStateToProps = state => {
  return {
    currentCampaign: state.currentCampaign,
    partnershipAgreement: state.partnershipAgreement,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(PartnershipAgreementActions, dispatch),
  dispatch,
})

class AgreementContainer extends React.Component {
  state = {
    selected: this.props.params.subSection,
    PartnershipAgreementStep: AgreementMap.find(step => step.key === this.props.params.subSection).component,
    activeDialog: null
  }

  componentDidMount() {
    document.title = 'Partnership Agreement - DojoMojo'
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.params.subSection,
      PartnershipAgreementStep: AgreementMap.find(step => step.key === nextProps.params.subSection).component
    })
  }

  routerWillLeave = nextLocation => {
    const { params: { subSection }, partnershipAgreement, actions } = this.props
    const draft = partnershipAgreement.agreement.template_markup_draft
    const markup = partnershipAgreement.agreement.template_markup

    if (subSection === 'dashboard') {
      actions.updateAttributes({
        selectedBrand: ''
      })
    } else if (subSection === 'review' && draft && draft !== markup) {
      return JSON.stringify({
        heading: `Warning! Changes Not Saved`,
        message: `Your changes to this Partnership Agreement have not been saved. Are you sure you want to leave?`,
      })
    } else {
      return true
    }
  }

  navigate = item => {
    const { currentCampaign, partnershipAgreement, history } = this.props

    const key = item.key || item
    history.push(`/builder/${currentCampaign.id}/partnership-agreement/${key}/${partnershipAgreement.id}`)
  }

  openDialog = type => {
    this.setState({
      activeDialog: type
    })
  }

  closeDialog = type => {
    this.setState({
      activeDialog: null
    })
  }

  render() {
    const { currentBrand, currentCampaign, partnershipAgreement, actions, match: { params } } = this.props
    const { selected, PartnershipAgreementStep, activeDialog } = this.state

    if (partnershipAgreement.loading) {
      return (
        <Spinner />
      )
    }

    return (
      <CampaignBuilderSection subSection={ currentCampaign.section }>
        <Prompt message={ this.routerWillLeave } />

        <ShareAgreementDialog active={ activeDialog === 'shareAgreementDialog' }
          partnershipAgreement={ partnershipAgreement }
          closeModal={ e => this.closeDialog('shareAgreementDialog') }
          actions={ actions }
        />

        <ConfirmPartnerDialog active={ activeDialog === 'confirmPartnerDialog' }
          partnershipAgreement={ partnershipAgreement }
          confirmedPartner={ e => this.openDialog('confirmedPartnerDialog') }
          closeModal={ e => this.closeDialog('confirmPartnerDialog') }
          navigate={ this.navigate }
          actions={ actions }
        />

        <ConfirmedPartnerDialog active={ activeDialog === 'confirmedPartnerDialog' }
          currentCampaign={ currentCampaign }
          partnershipAgreement={ partnershipAgreement }
          closeModal={ e => this.closeDialog('confirmedPartnerDialog') }
          actions={ actions }
        />

        <AgreementHeader partnershipAgreement={ partnershipAgreement }
          currentCampaign={ currentCampaign }
          navigate={ this.navigate }
          selected={ selected }
          actions={ actions }
          params={ params }
          openDialog={ this.openDialog }
        />

        <div className="row">
          <div className="col-sm-12">
            <PartnershipAgreementStep partnershipAgreement={ partnershipAgreement }
              currentBrand={ currentBrand }
              currentCampaign={ currentCampaign }
              actions={ actions }
              params={ params }
              navigate={ this.navigate }
              openDialog={ this.openDialog }
              closeDialog={ this.closeDialog }
              activeDialog={ activeDialog }
            />
          </div>
        </div>
      </CampaignBuilderSection>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AgreementContainer))
