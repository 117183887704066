import React from 'react'

import TasksList from 'visual-components/tasks/TaskList'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as CampaignTaskActions from 'actions/campaign-task-actions/'

const mapStateToProps = state => ({
  campaignTasks: state.campaignTasks,
  currentBrand: state.currentBrand,
})

const mapDispatchToProps = dispatch => ({
  campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch),
})

const filterCampaigns = campaign => !campaign.hideTasks

class BrandTaskListContainer extends React.Component {
  onNewTask = () => {
    const {
      currentBrand: {
        id,
      },
      campaignTaskActions: {
        currentTaskOpenModal,
      },
    } = this.props

    return currentTaskOpenModal({ assignee_id: id })
  }

  render() {
    const {
      campaignTasks: {
        liveUpcomingBrandCampaigns,
      },
      campaignTaskActions: {
        markComplete,
      },
    } = this.props

    const tasks = liveUpcomingBrandCampaigns.filter(filterCampaigns)

    return (
      <TasksList
        tasks={tasks}
        markComplete={markComplete}
        newTask={this.onNewTask}
        isCampaignDashboard
      /> // uses prop isCampaignDashboard
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandTaskListContainer)
