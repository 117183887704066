import AppDispatcher from 'dispatchers/app-dispatcher';
import _ from 'lodash'

const TermsDispatcher = {
  updateTerms: (data) => {
    data = data || {};
    var terms = store.getState().currentCampaign.campaignTerms;

    if (data.useTemplate == true) {
      data.createYourOwn = false;
      data.termsPublishedAt = null;
      data.termsLiability = null;
      data.termsStatus = 'needsPartnerInfo';
    } else if (data.createYourOwn == true) {
      data.useTemplate = false;
      data.termsPublishedAt = null;
      data.termsLiability = null;
      data.termsStatus = 'drafted';
    } else if (data.useTemplate === false || data.createYourOwn === false) {
      data.termsStatus = 'neverCreated';
    }

    store.dispatch({
      type: 'UPDATE_TERMS',
      model: 'currentCampaign',
      data: data
    });

    AppDispatcher.http({
      url: `/terms/${terms.id}`,
      data: data,
      method: 'PUT'
    });

    // update IP only if termsLiability indicated true
    if (data.termsLiability) {
      AppDispatcher.http({
        method: 'PUT',
        data: { termsIp: true },
        url: `/terms/agree-liability/${terms.id}`,
        success: function () {}
      })
    }
  },
  loadCampaignTerms: function(campaignId) {
    AppDispatcher.http({
      url: `/terms/${campaignId}`,
      success: function (campaignTerms) {
        store.dispatch({
          model: 'currentCampaign',
          type: 'UPDATE_ATTR',
          data: {
            campaignTerms
          }
        })
      }
    })
  },
  loadPartnerInfo: function(campaign) {
    if (campaign.campaignTerms.useTemplate == true) {
      AppDispatcher.http({
        url: `/terms/partners-info/${campaign.id}`,
        success: function(invites) {
          var mappedInvites = _.map(invites, function(invite) {
            return _.extend({}, invite.inviteTermDetails, { accountname: invite.invitee.accountname, status: invite.status, invitee_id: invite.invitee_id })
          });

          store.dispatch({
            type: 'LOAD_SUCCESS',
            data: mappedInvites,
            model: 'campaignTermsDetails'
          });
        },
        error: function(err) {
          console.log(err);
        }
      });
    }
  },
  generate: (callback) => {

    var getErrorAction = function (item, id) {
      return {
        data: {
          id: id,
          errors: {
            [item]: {
              errors: ['This Field Is Required']
            }
          }
        },
        type: 'UPDATE_ITEM',
        model: 'campaignTermsDetails'
      }
    }

    var disabled = false;
    var campaign = store.getState().currentCampaign;
    var campaignTermsDetails = store.getState().campaignTermsDetails;

    var requiredItems = [
      'legalName',
      'privacyPolicy',
    ];

    var hostItems = requiredItems.concat([
      'address1',
      'city',
      'state',
      'zip'
    ]);

    _.each(campaignTermsDetails.items, function(invite) {
      if (invite.invitee_id == campaign.hostBrandId) {
        _.each(hostItems, function (item) {
          if (!invite[item]) {
            store.dispatch(getErrorAction(item, invite.id));
            disabled = true;
          }
        })
      } else {
        _.each(requiredItems, function (item) {
          if (!invite[item]) {
            store.dispatch(getErrorAction(item, invite.id));
            disabled = true;
          }
        })
      }
    });

    if(disabled) {
      return;
    }

    var terms = store.getState().currentCampaign.campaignTerms;

    var data = {
      termsStatus: 'generated',
      termsGeneratedAt: new Date()
    }

    store.dispatch({
      type: 'UPDATE_TERMS',
      model: 'currentCampaign',
      data: data
    });

    AppDispatcher.http({
      url: `/terms/${terms.id}`,
      data: data,
      method: 'PUT',
      success: function() {
        callback();
      }
    });
  },
  publish: (callback) => {
    var terms = store.getState().currentCampaign.campaignTerms;

    var data = {
      termsStatus: 'published',
      termsPublishedAt: new Date(),
      termsFromTemplate: terms.termsFromTemplateDraft || terms.termsFromTemplate,
      termsUnsaved: false,
      termsCustom: terms.termsFromCustomDraft || terms.termsFromCustom
    }

    store.dispatch({
      type: 'UPDATE_TERMS',
      model: 'currentCampaign',
      data: data
    });

    AppDispatcher.http({
      url: `/terms/${terms.id}`,
      data: data,
      method: 'PUT',
      success: function() {
        callback();
      }
    });
  }
}

export default TermsDispatcher;
