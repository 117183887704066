import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import moment from 'moment'
import Dialog from 'react-toolbox/lib/dialog'
import { RadioGroup, RadioButton } from 'react-toolbox/lib/radio'
import { P, H5 } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import DialogTheme from 'css/themes/dialogs/trialEndingDialog.css'
import RadioTheme from 'css/themes/Radio.css'

const styles = {
  radioGroup: {
    margin: '16px 0px',
  },
  radioButton: {
    height: 62,
    '& span': {
      marginTop: -4,
    },
  },
}

function CancelPlanModal(props) {
  const {
    active,
    brand,
    cancelPlan,
    closeModal,
    classes,
  } = props

  const [cancelPlanSelection, setCancelPlanSelection] = useState(brand.renewal_date
    ? 'plan-term'
    : brand.next_invoice_at
      ? 'billing-term'
      : 'immediately')

  return (
    <Dialog
      active={active}
      onEscKeyDown={closeModal}
      onOverlayClick={closeModal}
      theme={DialogTheme}
      title="Cancel Current Plan"
    >
      <img
        onClick={closeModal}
        className={DialogTheme.closeImage}
        src="/images/icons/close.svg"
      />

      <RadioGroup
        name="cancelPlanSelection"
        value={cancelPlanSelection}
        onChange={setCancelPlanSelection}
        className={classes.radioGroup}
      >
        {brand.renewal_date && (
          <RadioButton
            label={(
              <>
                <P>{`At the end of current Plan Term (${moment(brand.renewal_date).subtract(1, 'day').format('MM/DD/YYYY')})`}</P>
                <H5>Recommended</H5>
              </>
            )}
            value="plan-term"
            theme={RadioTheme}
            className={classes.radioButton}
          />
        )}
        {brand.next_invoice_at && (
          <RadioButton
            label={(
              <>
                <P>{`At the end of current Billing Term (${moment(brand.next_invoice_at).format('MM/DD/YYYY')})`}</P>
                <P>Requires approval</P>
              </>
            )}
            value="billing-term"
            theme={RadioTheme}
            className={classes.radioButton}
          />
        )}
        <RadioButton
          label={(
            <>
              <P>Immediately</P>
              <P>Requires approval. Not recommended</P>
            </>
          )}
          value="immediately"
          theme={RadioTheme}
          className={classes.radioButton}
        />
      </RadioGroup>

      <ModalButtonGroup
        cancel={closeModal}
        cancelText="Nevermind"
        confirm={() => cancelPlan(cancelPlanSelection)}
        confirmText="Cancel Plan"
        canSave
        hideLine
      />
    </Dialog>
  )
}

CancelPlanModal.propTypes = {
  active: PropTypes.bool.isRequired,
  brand: PropTypes.object.isRequired,
  cancelPlan: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CancelPlanModal)
