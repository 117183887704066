import React from 'react'
import theme from 'css/themes/homePage/inThePress.css'

class InThePress extends React.Component {
  render() {
    const imgs = [
      {
        src: 'fast-company-logo',
        link:
          'https://www.fastcompany.com/90370478/time-management-can-ruin-your-productivity-heres-how-to-prevent-that-from-happening',
        brand: 'Fast Company',
      },
      {
        src: 'forbes-logo',
        link:
          'https://www.forbes.com/sites/theyec/2019/08/22/how-to-win-at-ecommerce-without-facebook-or-google/#595b554e2f31',
        brand: 'Forbes',
      },
      {
        src: 'inc-logo',
        link:
          'https://www.inc.com/jared-hecht/6-social-media-mistakes-that-could-land-you-in-legal-trouble.html',
        brand: 'INC.',
      },
      {
        src: 'mta-logo',
        link:
          'https://www.martechadvisor.com/news/ads/dojomojo-launches-media-market-to-offer-brands-an-effective-paid-acquisition-alternative-to-facebook-and-google/',
        brand: 'Martech Advisor',
      },
      {
        src: 'entrepreneur-logo',
        link: 'https://www.entrepreneur.com/article/300897',
        brand: 'Entrepreneur',
      },
      {
        src: 'good-men-project-logo',
        link:
          'https://goodmenproject.com/featured-content/3-things-climbing-mount-kilimanjaro-taught-me-about-resilience-kpkn/',
        brand: 'The Good Men Project',
      },
      {
        src: 'modern-retail-logo',
        link:
          'https://www.modernretail.co/platforms/how-one-company-is-building-brands-to-sell-only-on-amazon/',
        brand: 'Modern Retail',
      },
    ]

    const images = imgs.map((img, idx) => (
      <a rel="noopener noreferrer" target="_blank" href={img.link} key={idx}>
        <img
          className={theme.img}
          src={`/images/login/homepage/press-logos/${img.src}.png`}
          srcSet={`/images/login/homepage/press-logos/${img.src}.png, /images/login/homepage/press-logos/${img.src}@2x.png 2x, /images/login/homepage/press-logos/${img.src}@3x.png 3x`}
          key={idx}
          alt={img.brand}
        />
      </a>
    ))
    return (
      <div className={theme.container}>
        <p className={theme.title}>In the Press</p>
        <div className={theme.imgContainer}>{images}</div>
      </div>
    )
  }
}

export default InThePress
