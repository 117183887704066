import React from 'react';
import TinyMCE from 'react-tinymce';
import ReactDOM from 'react-dom';
import _ from 'lodash'

var fonts = [
  'Alegreya',
  'Alegreyasans',
  'Anonymous+Pro',
  'Arvo',
  'BioRhyme',
  'Bitter',
  'Cabin',
  'Cardo',
  'Chivo',
  'Cormorant+Garamond',
  'Crimson+Text',
  'Domine',
  'Eczar',
  'FiraSans',
  'Gentium+Basic',
  'GothamRnd-Bold',
  'GothamRnd-Book',
  'Inconsolata',
  'Karla',
  'Lato',
  'Libre+Baskerville',
  'Libre+Franklin',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Neuton',
  'Old+Standard',
  'Open+Sans',
  'Oswald',
  'Playfair+Display',
  'Poppins',
  'Proza+Libre',
  'PT+Sans',
  'PT+Serif',
  'Raleway',
  'Roboto',
  'Rubik',
  'Rajdhani',
  'Source+Sans+Pro',
  'Space+Mono',
  'Work+Sans'
]

const lineHeightSizes = [1, 1.15, 1.25, 1.5, 2]

class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.keyUp = _.debounce(this.keyUp.bind(this),500);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.setup = this.setup.bind(this);
  }
  
  handleEditorChange(e) {
    this.props.updateAttr(this.props.attr, e.target.getContent());
  }

  setup(editor) {
    if (this.props.customFonts) {
      editor.contentCSS.push('//fonts.googleapis.com/css?family=' + this.props.customFonts.concat(fonts).map(font => font.replace(/\s/g, '+').replace(/\'/g, '') + ':400,400i,700,700i').join('|'))
    }
    editor.contentCSS.push('//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css');
    editor.contentCSS.push('/css/font/font.css');
    editor.contentCSS.push('/css/font.css');
    editor.contentCSS.push('/css/extra-editor-css.css')

    editor.addButton('lineheight', {
      type: 'menubutton',
      image: '/images/icons/line-height.svg',
      classes: 'line-height-button',
      tooltip: 'Line Height',
      menu: lineHeightSizes.map(lineHeightSize => ({
        text: String(lineHeightSize),
        onclick: () => {
          editor.formatter.toggle('lineHeight', {
            lineHeightSize: `${lineHeightSize}em`,
          })
        },
      })),
    })
  }
  shouldComponentUpdate() {
    return false
  }
  keyUp(e) {
    this.props.updateAttr(this.props.attr, e.target.innerHTML);
  }

  handlePaste = () => {
    const { handlePaste } = this.props
    if (handlePaste) handlePaste()
  }

  render() {
    let { toolbar, customFonts } = this.props
    customFonts = customFonts || []
    var fontFormats = _.reduce(customFonts.concat(fonts), function (memo, font) {
      var fontStr = font.replace(/\+/g, ' ');
      return `${memo}${fontStr}='${fontStr}';`;
    },'');

    return (
      <div>
        <TinyMCE
          content={this.props.originalContent}
          config={{
            font_formats: fontFormats,
            fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 28pt 32pt 36pt 42pt",
            plugins: 'autolink link  preview textcolor colorpicker charmap paste',
            toolbar: toolbar != undefined ? toolbar : 'fontselect fontsizeselect bold italic alignleft aligncenter alignright forecolor bullist charmap link lineheight',
            menubar: false,
            statusbar: false,
            content_style: "span {color: inherit}",
            convert_urls: false,
            paste_as_text: this.props.allowPaste ? false : true,
            paste_retain_style_properties: this.props.allowPaste ? 'all' : '',
            setup: this.setup,
            height: this.props.height,
            body_class: this.props.bodyClass || null,
            formats: {
              italic: { inline: "span", styles: { fontStyle: "italic" } },
              lineHeight: {
                inline: 'span',
                styles: { lineHeight: '%lineHeightSize' },
              },
            },
          }}
          onChange={this.handleEditorChange}
          onKeyup={this.keyUp}
          onPaste={this.handlePaste} />
      </div>
    );
  }
}

export default Editor;
