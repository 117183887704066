import React from 'react';
import PropTypes from 'prop-types';

import Col from 'visual-components/util/no-padding-grid/col'

class PartnerLogos extends React.Component{
  render() {
    const {
      logo
    } = this.props;

    return (
      <div>
      { logo ?
        <Col style={{
          padding: '0',
          height: '32px',
          maxHeight: '32px',
          width: '32px',
          maxWidth: '32px',
          marginRight: '4px'
        }}>
          <div style={{
            height: '32px',
            maxHeight: '32px',
            width: '32px',
            maxWidth: '32px',
            display: 'table-cell',
            textAlign: 'center',
            verticalAlign: 'middle',
            border: `1px solid #dee4e5`
          }}>
            <img src={ logo }
              style={{
                maxHeight: '28px',
                maxWidth: '28px',
                display: 'block',
                margin: 'auto'
            }}/>
          </div>
        </Col>
        : null
      }
      </div>
    );
  }
}

PartnerLogos.propTypes = {
  logo: PropTypes.string.isRequired
};

export default PartnerLogos;
