import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'
import { Small } from 'visual-components/util/texts'

import buttonTheme from 'css/themes/Buttons.css'
import smsTheme from 'css/themes/sms-marketing.css'
import { MobileMarketingContext } from '../MobileMarketingContext'

const styles = {
  sendTest: {
    display: 'flex',
  },
  sendPreview: {
    '& > div, & > button': {
      margin: '0 !important',
      '& button': {
        margin: '0 8px 8px 0',
      },
    },
  },
}

function SendTestEditButton(props) {
  const {
    classes: css,
    isPreview,
    message,
  } = props

  const { id, isEditing } = message

  const { toggleEditMessage, setTestData } = React.useContext(MobileMarketingContext)

  return (
    <div className={cn(css.sendTest, { [css.sendPreview]: isPreview })}>
      <div>
        <Button
          style={{ width: 'auto' }}
          onClick={() => setTestData(message)}
          className={cn(
            buttonTheme.button,
            buttonTheme.raised,
            buttonTheme.whiteButtonAzureText,
            smsTheme.whiteButton
          )}
        >
          Send Me a Test
        </Button>
        <Small>Test messages are free.</Small>
      </div>
      <Button
        style={{ width: 'auto' }}
        onClick={() => toggleEditMessage({ id, isEdit: !isEditing })}
        className={cn(
          buttonTheme.button,
          buttonTheme.raised,
          buttonTheme.whiteButtonAzureText,
          smsTheme.whiteButton
        )}
      >
        Edit
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          style={{ marginLeft: 8, marginTop: -10 }}
          className={css.icon}
        >
          <path
            fillRule="nonzero"
            d="M15.762 3.98l-1.58 1.579-3.741-3.741 1.578-1.58c.536-.536 1.072 0 1.072 0l2.67 2.67s.537.536 0 1.072zM0 16l4.536-.796-3.75-3.75L0 16zm1.312-5.055l3.742 3.742 8.554-8.553-3.742-3.742-8.554 8.553z"
          />
        </svg>
      </Button>
    </div>
  )
}

SendTestEditButton.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
}

SendTestEditButton.defaultProps = {
  isPreview: false,
}

export default React.memo(injectCSS(styles)(SendTestEditButton))
