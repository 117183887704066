import React from 'react';
import PropTypes from 'prop-types';
import { H3 } from 'visual-components/util/texts';

class LegendItem extends React.Component {
  render() {
    return (
      <div style={{
        display: 'inline-block',
        width: '130px',
        marginBottom: '16px'
      }}>
        <div style={{
          backgroundColor: this.props.color,
          display: 'inline-block',
          width: '24px',
          height: '24px',
          borderRadius: '20px',
          marginRight: '8px'
        }} />
        <div style={{display: 'inline-block', verticalAlign: '6px'}}>
          <H3>
            {this.props.label}
          </H3>
        </div>
      </div>
    )
  }
}

LegendItem.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default LegendItem;