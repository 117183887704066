import React from 'react';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import * as ReactRedux from 'react-redux';
import {stateToHTML} from 'draft-js-export-html';
import MessageSlider from 'visual-components/messaging/message-slider';

class BrandMessage extends React.Component{
  constructor(props) {
    super(props);
    this.closeSlider = this.closeSlider.bind(this);
    this.save = this.save.bind(this);
  }

  closeSlider () {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        popoutMessageBrandConvoId: null,
        popoutMessageOpportunityId: null
      }
    })
  }

  save(contentState) {
    if (!contentState.hasText())  {
      return;
    }

    let { popoutMessageBrandConvoId, popoutMessageBrandId, popoutMessageOpportunityId } = this.props;

    if (popoutMessageBrandConvoId == 'newBrandConversation') {
      MessagingDispatcher.newBrandConversation({
        brandId: popoutMessageBrandId,
        content: stateToHTML(contentState),
        opportunityId: popoutMessageOpportunityId || null
      })
    } else {
      MessagingDispatcher.sendMessage({
        conversationId: popoutMessageBrandConvoId,
        content: stateToHTML(contentState),
        opportunityId: popoutMessageOpportunityId || null
      });
    }

    this.closeSlider();
  }
  render () {
    let { popoutMessageBrandName, popoutMessageBrandConvoId, popoutMessageContent, popoutMesssageTitle } = this.props;

    return (
      <MessageSlider
        isShown={ popoutMessageBrandConvoId ? true : false }
        onClose={ this.closeSlider }
        onSend={ this.save }
        title={ popoutMesssageTitle || `Send New Message To ${popoutMessageBrandName}`}
        buttonText='Send Message'
        initialContent={popoutMessageContent}
      />
    );
  }
};

const mapState = (state) => {
  return {
    popoutMessageBrandName: state.conversations.popoutMessageBrandName,
    popoutMessageBrandConvoId: state.conversations.popoutMessageBrandConvoId,
    popoutMessageBrandId: state.conversations.popoutMessageBrandId,
    popoutMessageContent: state.conversations.popoutMessageContent,
    popoutMesssageTitle: state.conversations.popoutMesssageTitle,
    popoutMessageOpportunityId: state.conversations.popoutMessageOpportunityId
  };
}

export default ReactRedux.connect(mapState)(BrandMessage);
