import React from 'react';
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import { Helmet } from 'react-helmet'
import MessagingConversationItem from 'visual-components/messaging/messaging-conversation-item';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import Card from 'react-toolbox/lib/card';
import NewMessageContainer from './NewMessageContainer';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import COLOURS from 'util/colours';
import Dialog from 'react-toolbox/lib/dialog';
import Button from 'react-toolbox/lib/button';
import NewMessageModal from 'visual-components/messaging/new-message-modal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import { withRouter } from 'react-router-dom'
import ButtonTheme from 'css/themes/Buttons.css'
import CardTheme from 'css/themes/card.css'

const themes = {
  Button: ButtonTheme,
  Card: CardTheme,
}

class InboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.updateActiveThread = this.updateActiveThread.bind(this);
    this.archiveThread = this.archiveThread.bind(this);
    this.showArchiveConfirmation = this.showArchiveConfirmation.bind(this);
    this.hideArchiveConfirmation = this.hideArchiveConfirmation.bind(this);
    this.showNewMessageModal = this.showNewMessageModal.bind(this);
    this.hideNewMessageModal = this.hideNewMessageModal.bind(this);
  }

  componentWillMount() {
    if(this.props.match.params.id) {
      store.dispatch({
        model: 'conversations',
        type: 'SET_ACTIVE_CONVERSATION',
        data: {
          activeConversationThread: Number(this.props.match.params.id)
        }
      })
    } else {
      store.dispatch({
        model: 'conversations',
        type: 'SET_ACTIVE_CONVERSATION',
        data: {
          activeConversationThread: null
        }
      })
    }
  }

  updateActiveThread(conversationId) {

    if (this.props.activeConversationThread == conversationId) {
      store.dispatch({
        model: 'conversations',
        type: 'UPDATE_ATTR',
        data: {
          activeConversationThread: null
        }
      })
    } else {
      this.props.history.push(`/messaging/inbox/${conversationId}`)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id != nextProps.match.params.id) {
      store.dispatch({
        model: 'conversations',
        type: 'SET_ACTIVE_CONVERSATION',
        data: {
          activeConversationThread: Number(nextProps.match.params.id)
        }
      })
    }
  }

  componentDidUpdate() {
    if (this.props.activeConversationThread && $('.message-scrollbox').length != 0) {
      $('html, body').animate({
        scrollTop: $('.message-scrollbox').parent().parent().offset().top + $('.message-scrollbox').parent().parent().height() - $(window).height() + 20
      })

      let thread = $('.message-scrollbox');
      thread.scrollTop(thread[0].scrollHeight);
    }
  }

  showArchiveConfirmation(conversationId) {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        archiveConfirmation: conversationId
      }
    })
  }

  hideArchiveConfirmation() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        archiveConfirmation: null
      }
    })
  }

  showNewMessageModal() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showNewMessageModal: true
      }
    })
  }

  hideNewMessageModal() {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showNewMessageModal: false
      }
    })
  }

  archiveThread() {
    let { archiveConfirmation } = this.props
    MessagingDispatcher.archive(archiveConfirmation);
  }


  render() {
    let {
      activeConversationThread,
      conversations,
      archiveConfirmation,
      currentBrandId,
      showNewMessageModal
    } = this.props;

    let filteredConversations = _.filter(conversations, { conversation_archive: false})

    let idx = _.findIndex(filteredConversations, {conversation_id: activeConversationThread});

    let conversationsArr = [
      idx == -1 ? filteredConversations : filteredConversations.slice(0, idx),
      idx == -1 ? [] : [filteredConversations[idx]],
      idx == -1 ? [] : filteredConversations.slice(idx + 1)
    ];


    let cardContentObj = {}

    let allCardContent = conversationsArr.filter( arr => arr.length != 0).map( (conversationCards, idx)=> {
      let cardContent = _.map(conversationCards, (conversation)=> {
        let showQuickReply = conversation.messages.length == 1 && conversation.messages[0].brand_id != currentBrandId && !conversation.messages[0].partnershipinvite_id
        return (
          <MessagingConversationItem
            thread={ conversation }
            key={ conversation.conversation_id }
            onclick={ this.updateActiveThread }
            preview={ conversation.conversation_id != activeConversationThread  }
            archive={ this.showArchiveConfirmation }
            showQuickReply={ showQuickReply }
          />
        )
      })

      let selected = false;

      if (conversationsArr[idx].length == 0 && conversationCards[0].conversation_id == activeConversationThread) {
        selected = true;
      } else if (conversationsArr[0].length > 0)  {
        selected = idx !== -1 && conversationsArr[idx].length > 0 && activeConversationThread === conversationsArr[idx][0].conversation_id;
      }

      return (
        <Card key={ idx }
          className={ selected ? `${themes.Card.darkShadow}` : '' }
          style={{
            marginBottom: '40px',
            width: selected ? '870px' : 'inherit',
            marginLeft: selected ? '-16px' : '0'
        }}>
          { cardContent }
        </Card>
      )
    });

    return (

      <div style={{
        marginTop: '30px',
        width: '840px'
      }}>
        <Helmet>
          <title>Inbox</title>
        </Helmet>
        <p style={{
          marginBottom: '24px',
          fontFamily: 'Larsseit-Bold',
          fontSize: '24px',
          color: COLOURS.azure,
          display: 'inline-block'
        }}>
          Inbox
        </p>
        <Button
          onClick = {this.showNewMessageModal}
          label={ 'New Message' }
          className={ `${themes.Button.greenButton} ${themes.Button.newMessage}`}
          theme={ themes.Button }
          raised />
        <NewMessageContainer
          inputRef = { this.inputRef } />
        { allCardContent }

        {/* New Message Modal */}
        <NewMessageModal />

        {/* Archive Confirmation Modal */}
        <Dialog
          active={ archiveConfirmation ? true : false }
          onEscDown={ this.hideArchiveConfirmation }
          onOverlayClick={ this.hideArchiveConfirmation }
        >
          <div style={{
            padding: '8px'
          }}>
            <Row>
              <Col xs={12} style={{
                textAlign: 'center',
                marginTop: '6px',
                marginBottom: '16px'
              }}>
                <p style={{
                  fontSize: '20px',
                  fontFamily: 'Larsseit-Medium',
                  color: COLOURS.ink
                }}>
                  Archive Conversation
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p style={{
                  fontFamily: 'Larsseit-Light',
                  fontSize: '16px',
                  lineHeight: '1.62',
                  color: COLOURS.ink
                }}>
                  Are you sure you want to archive this conversation? It will no longer be visible in your inbox unless there is new activity in the message thread.
                  You can always restore this by searching for it.
                </p>
              </Col>
            </Row>

            <ModalButtonGroup
              canSave={true}
              confirm={this.archiveThread}
              confirmText="Archive"
              hideLine={true}
              cancelText="Cancel"
              cancel={ this.hideArchiveConfirmation }
            />

          </div>
        </Dialog>

      </div>
    )
  }

}

const mapState = (state) => {
  return {
    conversations: state.conversations.conversations,
    activeConversationThread: state.conversations.activeConversationThread,
    archiveConfirmation: state.conversations.archiveConfirmation,
    currentBrandId: state.currentBrand.id,
    showNewMessageModal: state.conversations.showNewMessageModal
  }
}
export default withRouter(ReactRedux.connect(mapState)(InboxContainer))
