import React from 'react'
import { object, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'react-toolbox/lib/button'
import { H2, P } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import IntegrationMap from 'visual-components/integrations/integrations-map'
import IntegrationItem from 'visual-components/integrations/integration-item'
import IntegrationSetup from 'visual-components/integrations/integration-setup'
import IntegrationWarningModal from 'visual-components/brandSettings/integration-warning-modal'
import DataIntegrationSuggestion from 'components/dialogs/data-integration-suggestion'
import ButtonTheme from 'css/themes/Buttons.css'
import * as IntegrationsActions from 'actions/integrations-actions'

const mapState = ({ profile, currentBrand, espIntegrations }) =>
  ({ profile, currentBrand, espIntegrations })

const mapDispatchToProps = dispatch => ({
  dispatch,
  integrationsActions: bindActionCreators(IntegrationsActions, dispatch),
})

class IntegrationsSettings extends React.Component {
  static propTypes = {
    profile: object.isRequired,
    currentBrand: object.isRequired,
    espIntegrations: object.isRequired,
    integrationsActions: object.isRequired,
    dispatch: func.isRequired,
  }

  state = {
    successfulIntegration: false,
  }

  componentWillMount() {
    const { currentBrand, integrationsActions: { load } } = this.props
    load('brand', currentBrand.id)
  }

  requestIntegration = () => {
    const {
      profile,
      currentBrand,
      dispatch,
    } = this.props

    dispatch({
      model: 'contactSupport',
      type: 'UPDATE_ATTR',
      data: {
        modalOpen: true,
        brand: currentBrand.accountname,
        name: profile.fullName || `${profile.firstName || ''} ${profile.lastName || ''}`,
        email: profile.email,
        subject: 'Request ESP Integration',
      },
    })
  }

  onSuccessfulIntegration = () => {
    this.setState({
      successfulIntegration: true,
    })
  }

  render() {
    const { espIntegrations } = this.props
    const { successfulIntegration } = this.state

    const integrationItems = IntegrationMap.map((integration, idx) => (
      <IntegrationItem
        key={integration.key}
        idx={idx}
        integration={integration}
        espIntegrations={espIntegrations}
      />
    ))

    return (
      <div style={{ paddingTop: '32px' }}>
        <Grid>
          <Row style={{ marginBottom: '12px' }}>
            <Col xs={6}>
              <H2 azure>
                ESP Integrations
              </H2>
            </Col>
            <Col xs={6}>
              <Button
                onClick={this.requestIntegration}
                label="Request Integration"
                className={ButtonTheme.greenButton}
                theme={ButtonTheme}
                raised
                style={{
                  fontFamily: 'Larsseit-Medium',
                  fontSize: '14px',
                  height: '36px',
                  marginTop: '-13px',
                  float: 'right',
                }}
              />
            </Col>
          </Row>
          <Row style={{ width: '976px', marginBottom: '24px' }}>
            <P multiline>
              {"The hottest leads are the ones delivered immediately. Integrate with your ESP and act on your entries quickly. Note the selected integration will be the default for all future campaigns. Don't see what you're looking for? Request it."}
            </P>
          </Row>
          <Row>
            <Col style={{ width: '976px' }}>
              { integrationItems }
            </Col>
          </Row>
        </Grid>

        <IntegrationSetup onSuccessfulIntegration={this.onSuccessfulIntegration} />

        <IntegrationWarningModal />

        <DataIntegrationSuggestion
          enabled={successfulIntegration}
          left={-32}
        />
      </div>
    )
  }
}

export default connect(mapState, mapDispatchToProps)(IntegrationsSettings)
