import React, { PureComponent as Component } from 'react'
import { renderToString } from 'react-dom/server'
import CtaTrafficBarTooltip from 'visual-components/analyticsDashboard/tooltip/cta-traffic-bar-tooltip'

const TooltipFactory = React.createFactory(CtaTrafficBarTooltip)

const renderGraph = function (values, dates) {
  $('.cta-bar').html('')

  const mappedDates = dates.map((date, idx) => {
    return idx !== dates.length - 1 ? `${dates[idx]} - ${dates[idx + 1]}` : dates[idx]
  })

  const graph = new Contour({
    el: '.cta-bar',
    xAxis: {
    },
    chart: {
      padding: {
        left: 33,
      },
      height: 132,
    },
    tooltip: {
      opacity: 1,
      formatter(d) {
        const str = renderToString(TooltipFactory({
          period: mappedDates[d.x],
          conversions: d.y,
        }))

        return str
      },
    },
  })
    .cartesian()
    .yAxisLines()
    .column(values)
    .tooltip()
    .dateBasedXAxis(null, {
      n: dates.length,
      formattedDates: dates,
    })
    .render()

  return graph
}

class CtaTrafficBar extends Component {
  componentDidMount() {
    this.graph = renderGraph(this.props.values, this.props.dates)
  }

  componentDidUpdate(prevProps) {
    if (this.props.values !== prevProps.values) {
      renderGraph(this.props.values, this.props.dates)
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          className="cta-bar"
          style={{
            display: 'inline-block',
            width: '590px',
            height: '140px',
            marginBottom: '-20px',
          }}
        />
      </div>
    )
  }
}

export default CtaTrafficBar
