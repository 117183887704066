import React, { Component } from 'react'
import propTypes from 'prop-types'
import * as ReactRedux from 'react-redux'
import {
  P, H4, H6, Small,
} from 'visual-components/util/texts'
import injectSheet from 'react-jss'
import SmsMarketingInput from 'util/components/sms-marketing-input'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'
import { Link } from 'react-router-dom'
import COLOURS from 'util/colours'
import SmsOptIn from '../SmsOptIn'

const styles = {
  smsMarketingDisabledScreen: {
    position: 'absolute',
    background: 'rgba(255,255,255,.5)',
    width: '96%',
    height: '420px',
    zIndex: '99999999',
  },
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    marginBottom: 25,
  },
  left: {
    flex: 1,
    marginRight: 48,
  },
  right: {
    flex: 1,
  },
  enableSmsMarketing: {
    width: '100%',
    marginBottom: 36,
  },
  optInLanguage: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
  additonalText: {
    fontFamily: 'Larsseit',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '14px',
    fontSize: 14,
    color: '#868D91',
    marginRight: 6,
  },
  smsOfferPreview: {
    border: '1px solid #DEE4E5',
    padding: 20,
    marginTop: 7,
  },
  headline: {
    marginTop: 8,
    marginBottom: 32,
  },
  warningText: {
    marginTop: 20,
    marginBottom: 12,
  },
  wrapper: {
    display: 'flex',
    padding: '0 16px 19px',
    width: '100%',
    backgroundColor: `${COLOURS.lightYellow}40`, // 40% opacity -BK
    marginBottom: 20,
  },
  exclamationCircle: {
    width: 22,
    height: 20,
    marginRight: 15,
    marginTop: 14,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    color: '#313D4B !important',
    '& h4': {
      marginTop: 16,
      marginBottom: 6,
      fontWeight: 'normal',
    },
    '& p': {
      lineHeight: '21px',
      marginBottom: 20,
    },
    '& p:last-child': {
      lineHeight: '14px',
      marginBottom: 0,
    },
  },
}

class SmsMarketingTab extends Component {
  static propTypes = {
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    updateElementsAttr: propTypes.func.isRequired,
    updateAttr: propTypes.func.isRequired,
    elements: propTypes.object.isRequired,
  }

  render() {
    const {
      classes: css,
      elements,
      updateElementsAttr,
      updateAttr,
      campaign,
    } = this.props

    const { showSmsOptin, id: campaignId } = campaign

    return (
      <div className={css.parent}>
        <div className={css.header}>
          <P multiline ink>
            <i>
              SMS Marketing offers your email audience the chance to sign up for
              communications and marketing via text messaging. This is a great
              way to drive sales and build meaningful connections with customers
              over SMS.&nbsp;
              <a
                href="https://help.dojomojo.com/hc/en-us/articles/360036362831-What-is-SMS-Marketing-"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
              .
            </i>
          </P>
        </div>

        {!showSmsOptin && (
          <div className={css.enableSmsMarketing}>
            <Button
              onClick={() => updateAttr('showSmsOptin', true)}
              className={`${buttonTheme.disableSmsMarketing}`}
              theme={buttonTheme}
              label="Enable SMS Marketing"
            />
            <div className={css.smsMarketingDisabledScreen} />
          </div>
        )}
        <div className={css.left}>
          <H6>Headline</H6>
          <div className={css.headline}>
            <SmsMarketingInput
              attr="smsOptinHeadlineText"
              updateAttr={updateElementsAttr}
              elements={elements}
              placeholder="Special Offer: Unlock a 25% Discount for MojoMeals Today!"
            />
          </div>
          <H6>Opt-in Language</H6>
          <div className={css.optInLanguage}>
            <span className={css.additonalText}>Yes,</span>
            <SmsMarketingInput
              attr="smsOptinLanguage"
              updateAttr={updateElementsAttr}
              elements={elements}
              placeholder="sign me up for exclusive news and discounts"
            />
          </div>
          <span className={css.additonalText}>via mobile marketing texts!*</span>
          <div className={css.warningText}>
            <Small multiline>
              <i>
                <span style={{ color: 'red' }}>Reminder: </span>
                Be sure to include your opt-in offer in your welcome text message.&nbsp;
                <a
                  href="https://help.dojomojo.com/hc/en-us/articles/360056630351-Solo-Sweepstakes-101"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
                .
              </i>
            </Small>
          </div>
          {showSmsOptin && (
            <>
              <div className={css.wrapper}>
                <img
                  src="images/icons/warning.svg"
                  className={css.exclamationCircle}
                />
                <div className={css.content}>
                  <H4>
                    <i>Heads Up</i>
                  </H4>
                  <P charcoalDarker size={14}>
                    Remember to create and publish a welcome text for new SMS
                    subscribers who opt in from your landing page.
                  </P>
                  <P>
                    <Link
                      to={`/partnerships/campaign/${campaignId}/welcome-message`}
                    >
                      Create Welcome Message
                    </Link>
                  </P>
                </div>
              </div>
              <Button
                onClick={() => updateAttr('showSmsOptin', false)}
                className={`${buttonTheme.disableSmsMarketing}`}
                theme={buttonTheme}
                label="Disable SMS Marketing"
              />
            </>
          )}
        </div>
        <div className={css.right}>
          <H6>SMS Offer Preview</H6>
          <div className={css.smsOfferPreview}>
            <SmsOptIn
              smsOptinHeadlineText={elements.smsOptinHeadlineText}
              smsOptinLanguage={elements.smsOptinLanguage}
              hostBrandName={campaign.hostBrandName}
              formStyles={elements.styles.fonts}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapState = function (state) {
  return {
    termsAndConditionsForm: state.termsAndConditionsForm,
  }
}

export default ReactRedux.connect(mapState)(injectSheet(styles)(SmsMarketingTab))
