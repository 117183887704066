'use strict';

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Validator from 'util/form-validator'
import Field from 'visual-components/util/inputs/field';
import PasswordField from 'visual-components/util/inputs/password-field';

import Text from 'util/components/text'
import { Button } from 'react-toolbox/lib/button'

import theme from 'css/themes/login/registrationTheme.css'
import buttonTheme from 'css/themes/Buttons.css'
import { Small } from 'visual-components/util/texts'

const validations = {
  newPassword: [Validator.required, Validator.isValidPassword],
  confirmPassword: [Validator.required, Validator.matchPassword]
}

const mapState = ({ updatePassword, updatePasswordForm }) => ({
  updatePassword,
  updatePasswordForm,
})

class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props)
    // redirect to new update-password page if user tries to access old page
    window.location.replace('/app/update-password')
  }

  state = {}

  updatePassword = e => {
    e.preventDefault();

    var that = this;
    Validator.validateForm(validations, this.props.updatePassword, 'updatePasswordForm', function (valid) {
      if (valid) {
        $.ajax({
          method: 'POST',
          url: '/update-password',
          data: {
            password: that.props.updatePassword.newPassword,
            passwordConfirm: that.props.updatePassword.confirmPassword
          },
          success: function () {
            window.location.href = '/';
          }
        });
      }
    });
  }

  updateAttr = (attr, value) => {
    this.model[attr] = value;
    this.forceUpdate();
  }

  render() {
    var copy = LANGUAGE.profile.password;

    var {
      updatePasswordForm,
      updatePassword
    } = this.props

    return (
      <div id="content" className={ `${theme.container} ${theme.updatePasswordContainer}` }>
        <Helmet>
          <title>Update Password</title>
        </Helmet>
        <div className={ theme.updatePasswordLogoRow }>
          <img src="/images/big-tiger-face-white.png"
            className={ theme.updatePasswordHeaderLogo } />
        </div>
        <form onSubmit={ this.updatePassword }
          id="login-form"
          className={`form ${theme.updatePasswordForm}`}
          role="form"
        >
          <PasswordField label={ 'New Password' }
            attrName={ 'newPassword' }
            type={ 'password' }
            dataModel={ updatePassword }
            formModel={ updatePasswordForm }
            validations={ validations.newPassword }
          />
          <Field label={ 'Confirm New Password' }
            attrName={ 'confirmPassword' }
            type={ 'password' }
            dataModel={ updatePassword }
            formModel={ updatePasswordForm }
            validations={ validations.confirmPassword }
          />
          <div className={ `${theme.buttonWrapper}` }>
            <Button
              type={ 'submit' }
              className={ `${buttonTheme.blueButton} ${buttonTheme.noMargin} ${theme.botButton}` }
              theme={ buttonTheme }
              label={ 'UPDATE PASSWORD' }
            />
          </div>
          <div className={ theme.imgWrapper }>
            <img src="/images/DojoMojo-Wordmark-Blue.png" />
          </div>
          <Small>
            { LANGUAGE.dojo.what }
          </Small>
        </form>
      </div>
    )
  }
}

export default connect(mapState)(UpdatePasswordForm)
