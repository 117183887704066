import React, { PureComponent } from 'react'
import {
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'

import InternalContentContainer from 'visual-components/containers/InternalContentContainer'
import SideNav from 'visual-components/sideBars/side-nav'
import BrandInfoSettings from 'visual-components/brandSettings/brand-info-settings'
import TrackingIntegrations from 'visual-components/brandSettings/tracking-integrations'
import AnalyticsSettings from 'visual-components/brandSettings/analytics-settings'
import UsersSettings from 'visual-components/brandSettings/users-settings'
import IntegrationsSettings from 'visual-components/brandSettings/integrations-settings'
import BrandEmailFilters from 'visual-components/brandSettings/brand-email-filters'
import BillingSettings from 'visual-components/brandSettings/billing-settings'
import PayoutSettings from 'visual-components/brandSettings/payout-settings'
import TransactionHistory from 'visual-components/brandSettings/transaction-history'
import ReferralDashboard from 'visual-components/brandSettings/referralDashboard/ReferralDashboard'
import AgreementList from 'visual-components/brandSettings/AgreementList'
import Compliance from 'visual-components/brandSettings/Compliance'

import BillingPaymentMethods from 'visual-components/brandSettings/billing-payment-methods'

import COLOURS from 'util/colours'

const mapStateToProps = ({ currentBrand: { features } }) => ({ features })

const postfixes = [
  'info',
  'analytics',
  'users',
  'esp',
  'filters',
  'billing',
  'payouts',
  'transactions',
  'referrals',
  'data-integrations',
  'agreements',
  'payment-methods',
  'compliance',
].join('|')

class BrandSettings extends PureComponent {
  render() {
    const { features, match: { path: prefix } } = this.props
    const routes = [
      {
        label: 'Billing',
        to: '/billing',
      },
      {
        label: 'Payment Methods',
        to: '/payment-methods',
      },
      {
        label: 'Brand Info',
        to: '/info',
      },
      {
        label: 'ESP Integrations',
        to: '/esp',
      },
      {
        label: 'Data Integrations',
        to: '/data-integrations',
      },
      {
        label: 'Retargeting',
        to: '/analytics',
      },
      {
        label: 'Users',
        to: '/users',
      },
      {
        label: 'Email Filters',
        to: '/filters',
      },
      features.mediaMarketSeller
      && {
        label: 'Payout Preferences',
        to: '/payouts',
      },
      {
        label: 'Transaction History',
        to: '/transactions',
      },
      {
        label: 'Get $25 Credit',
        to: '/referrals',
      },
      {
        label: 'Agreements',
        to: '/agreements',
      },
      {
        label: 'Compliance',
        to: '/compliance',
      },
    ].filter(Boolean)

    return (
      <div style={{ width: '100%', background: COLOURS.cream }}>
        <InternalContentContainer width="1176px">
          <Route
            exact
            path={`${prefix}/(${postfixes})/:key?`}
            render={routeProps => (
              <SideNav width="240px" routes={routes} prefix={prefix} {...routeProps} />
            )}
          />

          <div style={{ width: '100%' }}>
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/billing`} />
              <Route exact path={`${prefix}/info`} component={BrandInfoSettings} />
              <Route exact path={`${prefix}/data-integrations`} component={TrackingIntegrations} />
              <Route exact path={ `${prefix}/analytics` } component={ AnalyticsSettings } />
              <Route exact path={ `${prefix}/users` } component={ UsersSettings } />
              <Route exact path={ `${prefix}/esp/:section?/:subsection?` } component={ IntegrationsSettings } />
              <Route exact path={ `${prefix}/filters` } component={ BrandEmailFilters } />
              <Route exact path={ `${prefix}/payment-methods` } component={ BillingPaymentMethods } />
              <Route exact path={`${prefix}/change-payment-methods/:location`} component={BillingPaymentMethods} />
              <Route exact path={ `${prefix}/billing` } component={ BillingSettings } />
              <Route exact path={ `${prefix}/payouts` } component={ PayoutSettings } />
              <Route exact path={ `${prefix}/transactions` } component={ TransactionHistory } />
              <Route exact path={ `${prefix}/referrals` } component={ReferralDashboard} />
              <Route exact path={`${prefix}/agreements/:which?`} component={AgreementList} />
              <Route exact path={`${prefix}/compliance`} component={Compliance} />
              <Redirect to="/404" />
            </Switch>
          </div>
        </InternalContentContainer>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(BrandSettings))
