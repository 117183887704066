import React from 'react'
import BrandInfo from './brand-info'
import { Small, H4 } from 'visual-components/util/texts'

class MiniOpportunityInfo extends React.Component {
  state = {
    RelativeTime: () => null,
  }
  
  componentDidMount() {
    this.setState({ 
      RelativeTime: require('visual-components/util/relative-time').default,
    })
  }
  
  render() {
    let { opportunity, brand } = this.props  

    const typeMap = {
      dedicated_email_buy: 'Dedicated Email Buy',
      dedicated_email_sell: 'Dedicated Email Sale',
      prizing_partner: 'Prizing Partner',
      product_shipment_insert: 'Box-Insert Swaps',
      content_swap: 'Content Swaps',
      custom: 'Other',
    }

    return (
      <div>
        <div>
          <Small style={{ display: 'inline-block' }}>
            <i>{ typeMap[opportunity.type] || 'Other' }</i>
          </Small>
          <Small style={{ display: 'inline-block', marginLeft: '8px' }}>
            <this.state.RelativeTime time={ opportunity.createdAt } />
          </Small>
        </div>
        <div style={{ marginTop: '8px' }}>
          <H4 multiline>
            <em>{ opportunity.headline }</em>
          </H4>
        </div>
        <div style={{ marginTop: '12px' }}>
          <BrandInfo brand={ brand } />
        </div>
      </div>
    )
  }
}

export default MiniOpportunityInfo
