import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PartnerCard from 'visual-components/quickview/partner-card';
import { array, string, func } from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import ReferralCard from './referral-card'


export default class PartnersGrid extends PureComponent {
  static propTypes = {
    partners: array.isRequired,
    currentBrandId: string.isRequired,
    message: func.isRequired,
  }

  render() {
    const { partners, currentBrandId, message, openPlatformInviteModal, campaign, isPartner } = this.props
    const partnerCards = partners && partners.length ?
      partners.map((partner,idx) => {
        return (
          <Link to={ `/explore/brands/${partner.id}` }>
            <PartnerCard
              key = { partner.id }
              logo = { partner.logo }
              accountname = { partner.accountname }
              dedicatedListSize = { partner.dedicatedListSize }
              averageSignUpsPerCampaign = { partner.averageSignUpsPerCampaign }
              socialMediaImpressions = { partner.socialMediaImpressions }
              facebookCoverPhoto= { partner.facebookCoverPhoto || '/images/defaults/coverPhotoFull.png' }
              coverPhotoOffsetY= { partner.coverPhotoOffsetY || 0 }
              brandId= { partner.id }
              degreeOfRemoval = { partner.degreeOfRemoval }
              currentBrandId = { currentBrandId }
              message = { message }
            />
          </Link>
        )
      })
      :
      null;

    
    return (
      <div style={{marginTop: '14px', marginLeft: '-16px'}}>
        <Grid>
          <Row style={{
            flexWrap: 'wrap',
          }}>
            <ReferralCard 
              openPlatformInviteModal={openPlatformInviteModal}
              campaignId={campaign.id}
              started={new Date(campaign.startDate) <= new Date()}
              isPartner={isPartner}
            />
            { partnerCards }
          </Row>
        </Grid>
      </div>
    )
  }
}
