import initialState from './defaults/partnershipAnalyticsSearch'

export default {
  model: 'partnershipAnalyticsSearch',
  initialState,
  endpoint: '/search-partnership-analytics',
  inputName: 'partner_brand_name',
  aggs: {
    householdIncome: {
      terms: {
        field: 'top_income_bracket.keyword',
      },
    },
    industry: {
      terms: {
        field: 'industries.keyword',
        size: 100,
      },
    },
    allEntries: {
      sum: {
        field: 'total_entries_driven',
      },
    },
    allNTF: {
      sum: {
        field: 'total_ntf_driven',
      },
    },
    avgListSize: {
      avg: {
        field: 'dedicatedListSize',
      },
    },
  },
  rangeFilters: [
    'listSize',
    'avgVisits',
    'avgEntries',
    'percentNTF',
    'genderSplitFemale',
    'genderSplitMale',
  ],
  facets: ['householdIncome', 'industry'],
  extendQuery({ body, state }) {
    const { filter, must } = body.query.bool

    // Recent Partnerships
    const { recentPartnerships } = state
    if (recentPartnerships && recentPartnerships.value) {
      const last_partnered_date = {}
      if (recentPartnerships.value !== 'all') {
        last_partnered_date.lte = `now-${recentPartnerships.value}d`
      }
      filter.push({
        range: {
          last_partnered_date,
        },
      })
    }

    return body
  },
}
