import axios from 'axios'

export const espValidKey = type => async value => {
  if (!value) return

  if (type === 'campaign-monitor') value = encodeURIComponent(value.trim())

  try {
    await axios.get(`${type}/fetch-lists?key=${value.trim()}`)
  } catch (e) {
    throw 'Invalid API Key'
  }
}

export const activeCampaignValidKey = async (value, model) => {
  if (!value || !model.apiUrl) return

  try {
    const lists = await axios.get(`/active-campaign/fetch-lists?key=${value.trim()}&url=${model.apiUrl.trim()}`)
    if (lists.length === 0) {
      throw 'No Available Lists'
    }
  } catch (e) {
    throw 'Invalid API Key or URL'
  }
}

export const mailjetValidKey = async (value, model) => {
  if (!value || !model.accountId) return

  try {
    await axios.get(`/mailjet/fetch-lists?secret=${value.trim()}&key=${model.apiKey.trim()}`)
  } catch (e) {
    throw 'Invalid API Key or Secret'
  }
}

export const maropostValidKey = async (value, model) => {
  if (!value || !model.accountId) return

  try {
    await axios.get(`/maropost/fetch-lists?key=${value.trim()}&id=${model.accountId.trim()}`)
  } catch (e) {
    throw 'Invalid API Key or Account ID'
  }
}

export const postupValidKey = async (value, model) => {
  if (!value || !model.apiKey) return

  try {
    await axios.get(`/postup/fetch-lists?user=${model.username.trim()}&pass=${value.trim()}`)
  } catch (e) {
    throw 'Invalid Username or Password'
  }
}

export const retentionScienceValidKey = async value => {
  if (!value) return

  try {
    await axios.get(`/retention-science/ping?key=${value.trim()}`)
  } catch (e) {
    throw 'Invalid API Key'
  }
}

export const sailthruValidKey = async (value, model) => {
  if (!value || !model.apiKey) return

  try {
    await axios.get(`/sailthru/fetch-lists?secret=${value.trim()}&key=${model.apiKey.trim()}`)
  } catch (e) {
    const { response } = e
    if (response && response.data && response.data.error === 4) {
      throw `${response.data.errormsg}, Please add to Sailthru whitelist settings.`
    } else {
      throw 'Invalid API Key or Secret'
    }
  }
}

export const belowCustomRateLimit = async value => {
  if (value > 1000) {
    throw 'Rate limit cannot exceed 1000/hour'
  }
}
