import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import Clipboard from 'clipboard'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import Field from 'visual-components/util/inputs/field'
import { P, H6 } from 'visual-components/util/texts'
import { Button } from 'react-toolbox/lib/button'

import InputTheme from 'css/themes/Input.css'
import ButtonTheme from 'css/themes/Buttons.css'

class TrackingItem extends React.Component {
  componentDidMount() {
    const { unit: { uid } } = this.props
    const node = $(ReactDOM.findDOMNode(this)).find(`.copy-${uid}`)[0]
    if (node) {
      new Clipboard(node)
    }
  }

  copyLink = () => {
    toastr.success('Link copied.', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
  }

  render() {
    const { idx, unit, editLink, deleteLink } = this.props

    const promoLink = unit.link
    const data = {
      link: promoLink
    }

    return (
      <Col style={{
        width: '542px',
        marginRight: idx % 2 == 0 ? '28px' : '0px'
      }}>
        <div style={{
          width: '384px',
          marginRight: '8px',
          display: 'inline-block'
        }}>
          <Field label={ unit.label || unit.promotionaltype }
            attrName={ 'link' }
            dataModel={ data }
            formModel={ {} }
            updateAttr={ () => {} }
            readonly
          />
        </div>
        <div style={{
          width: '95px',
          marginRight: '12px',
          display: 'inline-block'
        }}>
          <Button onClick={ this.copyLink }
            label={ 'Copy Link' }
            className={ `${ButtonTheme.blueButton} copy-${unit.uid}` }
            theme={ ButtonTheme }
            data-clipboard-text={ promoLink }
            primary
            raised
          />
        </div>
        <div style={{
          width: '42px',
          display: 'inline-block'
        }}>
          <img src={ '/images/icons/blue-edit.svg' }
            onClick={ e => editLink(unit) }
            style={{
              marginRight: '12px',
              cursor: 'pointer'
          }}/>
          <img src={ '/images/icons/blue-trash.svg' }
            onClick={ e => deleteLink(unit) }
            style={{
              cursor: 'pointer'
          }}/>
        </div>
      </Col>
    )
  }
}

export default withRouter(TrackingItem)
