import React from 'react';

var getVideoId = function (url) {
  url = url ? url.split('?')[1] : '';
  url = url || '';
  var regex = new RegExp("v=([^&#]*)"),
    results = regex.exec(url);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

var updateYoutube = function (url) {
  $('.cta-video').html('');
  if (!url) {
    return;
  }
  var $watchItem = $('.cta-video');
  var $holder = $('<div>');
  $('.cta-video').append($holder);
  var autoplay = window.location.href.match(/(editor|edit-content)/) ? 0 : 1;

  var player = new YT.Player($holder[0], {
    // height: '390',
    playerVars:{'disablekb':1,'controls':0,'rel':0,'showinfo':0, 'autoplay': autoplay},
    width: $watchItem.width(),
    videoId: getVideoId(url),

    events: {
      onStateChange: function () {
        if (player.getPlayerState() === 0) {
          share_track('cta-video-finish');
        }
      }
    }

  });
}

class Video extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.cta.ctaVideo !== prevProps.cta.ctaVideo) {
      updateYoutube(this.props.cta.ctaVideo);
    }
  }
  componentDidMount() {
    if (this.props.cta.ctaVideo) {
      var url = this.props.cta.ctaVideo;
      var tag = document.createElement('script');

      tag.src = "//www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = function () {

        updateYoutube(url);
      }
    }
  }
  render() {
    return (
      <div className="cta-video" data-url={this.props.cta.ctaVideo}>
      </div>
    )
  }
}

export default Video;
