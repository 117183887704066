export const loading = () => ({ type: 'LOADING_DATA_DISCLOSURES' })

export const submit = data => ({
  http: {
    url: data.brand_id
      ? '/brand-data-disclosures'
      : '/data-disclosures',
    method: 'POST',
    data,
  },
  types: [
    'LOADING_DATA_DISCLOSURES',
    'SUBMIT_DATA_DISCLOSURE_SUCCESS',
    'SUBMIT_DATA_DISCLOSURE_FAILURE',
  ],
})
