import COLOURS from 'util/colours'

export default {
  request: {
    label: '',
    color: '',
  },
  pending: {
    label: 'Pending',
    color: COLOURS.cloudGrey,
  },
  accepted: {
    label: 'Accepted',
    color: COLOURS.seaGreen,
  },
  live: {
    label: 'Live',
    color: COLOURS.coral,
  },
  unfulfilled: {
    label: 'Unfulfilled',
    color: COLOURS.alertRed,
  },
  in_dispute: {
    label: 'In Dispute',
    color: COLOURS.alertRed,
  },
  completed: {
    label: 'Completed',
    color: COLOURS.cerulean,
  },
  declined: {
    label: 'Declined',
    color: COLOURS.stone,
  },
  expired: {
    label: 'Expired',
    color: COLOURS.stone,
  },
}
