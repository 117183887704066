import _ from 'lodash'

var model = 'inDiscussionCampaigns';

var defaultState =  {
  rid: model,
  campaigns: [],
  sort: 'startDate',
  reversed: false,
  loaded: false
}

var InDiscussionCampaigns = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    SIGN_AGREEMENT_PARTNER: function () {
      var campaign = _.find(state.campaigns, { partnership_invite_id: action.data.inviteId });
      if (campaign) {
        campaign.partnership_agreement_status = 'partner_signed'
      }

      return _.extend({}, state);
    },
    UPDATE_SORT: function () {
      if (action.data.sort === state.sort) {
        return _.extend({}, state, { campaigns: state.campaigns.reverse(), reversed: !state.reversed });
      } else {
        var campaigns = _.sortBy(state.campaigns, action.data.sort);
        if (action.data.sort != 'name') {
          campaigns = campaigns.reverse();
        }

        return _.extend({}, state, { sort: action.data.sort, campaigns: campaigns, reversed: false });
      }
    },
    LOAD: function () {
      let campaigns = store.getState().brandCampaigns.inDiscussionCampaigns;
      const currentBrandId = store.getState().currentBrand.id
      campaigns = _.sortBy(campaigns, state.sort).reverse();
      campaigns = campaigns.filter((campaign) => {
        let partnerInvite = campaign.allPartners.find((partner) => {
          return partner.partner_id === currentBrandId
        })


        return partnerInvite && (partnerInvite.partnership_agreement_status === 'shared' || partnerInvite.partnership_agreement_status === 'partner_signed')
      })

      campaigns.forEach((campaign) => {
        campaign.invites = campaign.invites;
        let partnerInvite = campaign.allPartners.find((partner) => {
          return partner.partner_id === currentBrandId
        })

        campaign.partnership_invite_id = partnerInvite.partnership_invite_id
        campaign.partnership_agreement_status = partnerInvite.partnership_agreement_status
        campaign.totalImpressions = campaign.impressions;

        var entryRate = campaign.totalEntries / campaign.totalVisits;
        campaign.entryRate = isNaN(entryRate) ? 0 : entryRate;
      })

      return _.extend({}, state, { campaigns: campaigns, loaded: true });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default InDiscussionCampaigns;
