import React, { PureComponent as Component } from 'react'
import _ from 'lodash'
import Label from 'util/components/label'
import FieldDispatcher from 'dispatchers/field-dispatcher'
import Validator from 'util/form-validator'
import COLOURS from 'util/colours'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import PrimaryButton from 'util/components/buttons/primary-button'
import SecondaryButton from 'util/components/buttons/secondary-button'

const ValidatorModel = function () {
  return this
}

ValidatorModel.prototype = _.extend({}, Validator, ValidatorModel.prototype)

const renderErrorMessages = (text, idx) => (
  <span
    key={idx}
    className="help-block"
    style={{
      color: COLOURS.alertRed,
      fontSize: '9px',
      lineHeight: '9px',
      fontFamily: 'Larsseit-Medium',
      paddingRight: '4px',
    }}
  >
    {idx !== 0 && <br />}
    {text}
  </span>
)

class Field extends Component {
  constructor(props) {
    super(props)
    this.updateValue = this.updateValue.bind(this)
    this.validate = _.debounce(_.bind(this.validate, this), 800)
    this.blur = this.blur.bind(this)
    this.state = {
      blurred: true,
      temporaryValue: this.props.manualSave ? this.valueFromDataModel() : null,
      fieldCharacterLength: 0,
    }
    this.focus = this.focus.bind(this)
    this.toDisplay = this.toDisplay.bind(this)
    this.persist = this.persist.bind(this)
    this.valueFromDataModel = this.valueFromDataModel.bind(this)
    this.dispatchUpdateAttr = this.dispatchUpdateAttr.bind(this)
    this.valueFromFormModel = this.valueFromFormModel.bind(this)
    this.persistIfValid = this.persistIfValid.bind(this)
    this.validator = new ValidatorModel()
    this.manualUpdateValue = this.manualUpdateValue.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this)
    // can we make this reactive?

    // wait 1 sec for at least jquery to load properly??? :\
    this.timeout = setTimeout(() => {
      if ($.fn.tooltip) {
        $(node).find('[data-toggle="tooltip"]').tooltip()
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  onCancel() {
    const { onCancelDiscard, onCancelAction } = this.props
    const { temporaryValue } = this.state
    if (onCancelDiscard) {
      if (temporaryValue !== this.valueFromDataModel()) {
        onCancelAction(true)
        return
      }
    }
    this.setState({
      temporaryValue: this.valueFromDataModel(),
    })
    if (this.props.onCancelAction) {
      this.props.onCancelAction()
    }
  }

  manualUpdateValue = () => {
    const { updateAttr, attrName, dataModel } = this.props
    const value = this.state.temporaryValue

    const data = { [attrName]: value }

    this.dispatchUpdateAttr(dataModel.rid, data)
    this.validate(this.fromDisplay(value))

    if (updateAttr) {
      this.props.updateAttr(attrName, value)
    }
    if (this.props.onCancelAction) {
      this.props.onCancelAction()
    }
  }

  updateValue(value) {
    const {
      updateAttr,
      attrName,
      dataModel,
      manualSave,
      onChange,
    } = this.props

    this.setState({ fieldCharacterLength: value.length })

    if (onChange) value = onChange(value)

    if (!manualSave) {
      const data = { [attrName]: value }

      this.dispatchUpdateAttr(dataModel.rid, data)
      this.validate(this.fromDisplay(value))

      if (updateAttr) {
        updateAttr(attrName, value)
      }
    } else {
      this.setState({
        temporaryValue: value,
      })
    }
  }

  validate(value, callback = () => {}) {
    const {
      dataModel,
      attrName,
      validations,
      formModel,
    } = this.props

    // var that = this

    this.validator.validate(value, dataModel, validations, data => {
      let dataWithValidity = { [attrName]: data }
      // FieldDispatcher.updateAttr(formModel.rid, dataWithValidity)
      if (this.props.id) {
        dataWithValidity = {
          errors: {
            [attrName]: data,
          },
        }
      }

      this.dispatchUpdateAttr(formModel.rid, dataWithValidity)
      callback()
    })
  }

  dispatchUpdateAttr(modelId, data) {
    const { id } = this.props
    if (id) {
      data.id = id
    }

    FieldDispatcher.updateAttr(modelId, data)
  }

  blur(e) {
    const { blur } = this.props

    if (blur) {
      blur(e)
    }

    setTimeout(() => {
      this.setState({
        blurred: true,
      })
    }, 10)

    this.validate(this.valueFromDataModel(), this.persistIfValid)
  }

  focus() {
    this.setState({
      blurred: false,
    })
  }

  toDisplay() {
    const {
      attrName,
      endpoint,
      formModel,
      dataModel,
    } = this.props

    if (this.props.manualSave) {
      return this.state.temporaryValue
    }

    return this.valueFromDataModel()
  }

  fromDisplay(value) {
    return value
  }

  persist() {
    const {
      attrName,
      endpoint,
      formModel,
      dataModel,
      id,
    } = this.props

    const data = { [attrName]: this.valueFromDataModel() }

    if (id) {
      data.id = id
    }

    if (endpoint) {
      FieldDispatcher.persist({
        endpoint,
        model: formModel,
        data,
        attrName,
        hideToastr: this.props.hideToastr || false,
        hideToastrError: this.props.hideToastrError || false,
      })
    }
  }

  persistIfValid() {
    const {
      attrName,
      endpoint,
      formModel,
      dataModel,
    } = this.props

    const data = { [attrName]: this.valueFromDataModel() }

    const formModelData = this.valueFromFormModel()
    if (endpoint && formModelData && !formModelData.errors.length) {
      this.persist()
    }
  }

  valueFromDataModel() {
    const { attrName, dataModel, id } = this.props
    const value = id ? dataModel.items[id][attrName] : dataModel[attrName]

    if (value === null) {
      return ''
    }

    return value
  }

  valueFromFormModel() {
    const { attrName, formModel, id } = this.props

    if (id) {
      const errors = formModel.items[id].errors || {}
      return errors[attrName]
    }

    return formModel[attrName]
  }

  render() {
    const {
      align = undefined,
      autoComplete,
      type,
      attrName,
      editable,
      disabled,
      rows,
      charLimit,
      CustomLabel,
      customDivStyle,
      customInputStyle,
      customButtonStyles,
    } = this.props

    const formModelData = this.valueFromFormModel()
    const errors = (formModelData ? formModelData.errors : []) || []
    const error_messages = _.map(errors, renderErrorMessages)
    const show_messages = this.state.blurred && error_messages.length
    const className = `form-group ${errors.length ? 'has-error' : ''}`
    const disable_input = (disabled || editable === false) ? 'disabled' : ''
    const value = this.valueFromDataModel()

    const charLimitDisplay = limit => {
      const { fieldCharacterLength } = this.state
      const numOfCharacters = Math.abs(limit - fieldCharacterLength)
      const overLimit = fieldCharacterLength > limit

      return (
        <span
          style={{
            color: overLimit && COLOURS.alertRed,
            fontSize: '9px',
            lineHeight: '9px',
            fontFamily: 'Larsseit-Medium',
            position: 'absolute',
            right: '15px',
            marginTop: !show_messages ? '-14px' : '3px',
          }}
        >
          { `${numOfCharacters} ${overLimit ? 'characters over the limit' : 'characters remaining'}`}
        </span>
      )
    }

    const inputStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '16px',

      width: '100%',
      height: '50px',

      // paddingLeft: '14px',
      backgroundColor: 'transparent',
      color: COLOURS.ink,
      fontFamily: "'Larsseit-Light'",
      fontSize: '14px',
      textAlign: align,

      outline: 'none',
      boxShadow: 'none',
      WebkitBoxShadow: 'none',

      borderRadius: '1px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: show_messages ? COLOURS.alertRed : COLOURS.silver,
    }

    const divStyle = Object.assign({}, {
      width: '100%',
      marginBottom: '10px', // this is to allow to padding inbetween error messaging and labels
      display: 'inline-block',
    }, customDivStyle)

    const buttonStyles = [{
      width: '150px',
      minWidth: '150px',
      height: '30px',
      minHeight: '30px',
      fontSize: '14px',
    }]

    const InputStyle = Object.assign({}, inputStyle, customInputStyle)
    const TextAreaStyle = Object.assign({}, inputStyle, {
      height: 'auto',
      resize: 'vertical',
    }, customInputStyle)

    const labelAttrs = {
      model: this.props.model,
      tooltip: this.props.tooltip,
      attrName: this.props.attrName,
      labelId: this.props.labelId,
      label: this.props.label,
    }

    return (
      <div className={`${className} text-left`} key={attrName} style={divStyle}>
        {
          this.props.noLabel
            ? null
            : CustomLabel
              ? <CustomLabel {...this.props} />
              : <Label {...labelAttrs} />
        }
        {
          type == 'textarea'
            ? (
              <textarea
                style={TextAreaStyle}
                onBlur={this.blur}
                onFocus={this.focus}
                disabled={disable_input}
                onChange={e => this.updateValue(e.target.value)}
                value={this.toDisplay(value)}
                placeholder={this.props.placeholder}
                ref={attrName}
                className="form-control borderAzureFocus"
                name={attrName}
                id={attrName}
                rows={rows}
              />
            ) : (
              <input
                autoComplete={autoComplete}
                style={InputStyle}
                onBlur={this.blur}
                onFocus={this.focus}
                disabled={disable_input}
                onChange={e => this.updateValue(e.target.value)}
                value={this.toDisplay(value)}
                ref={attrName}
                type={type}
                className="form-control borderAzureFocus"
                name={attrName}
                id={attrName}
                placeholder={this.props.placeholder}
              />
            )
        }
        { show_messages ? error_messages : <div style={{ marginTop: '17px' }} /> }
        { charLimit && charLimitDisplay(charLimit) }
        {
          this.props.manualSave
            ? (
              <div>
                <PrimaryButton styles={buttonStyles.concat({ marginRight: '20px', ...customButtonStyles })} onClick={this.manualUpdateValue} blueButton>
                  Save
                </PrimaryButton>
                <SecondaryButton styles={buttonStyles.concat({ ...customButtonStyles })} onClick={this.onCancel} blueButton>
                  Cancel
                </SecondaryButton>
              </div>
            )
            : null
        }
      </div>
    )
  }
}

Field.propTypes = {
  updateValue: PropTypes.func,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
}

Field.defaultProps = {
  onChange: null,
  updateValue(value) {
    const { attrName, dataModel } = this.props
    const data = { [attrName]: value }
    FieldDispatcher.updateAttr(dataModel.rid, data)
    this.validate(this.fromDisplay(value))
  },
  autoComplete: 'on',
}

export default Field
