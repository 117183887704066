import React from 'react';
import { string, number } from 'prop-types';

import { Card } from 'react-toolbox/lib/card';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H5, Small, Tiny } from 'visual-components/util/texts';

class IncomeDistributionTooltip extends React.Component {
  static propType = {
    category: string.required,
    count: number.required,
    percent: number.required
  }

  render() {
    let {
      category,
      count,
      percent
    } = this.props;

    return (
      <Card style={{
        padding: '12px',
        width: '136px',
        height: '74px'
      }}>
        <div style={{
          marginBottom: '12px'
        }}>
          <Small>
            <em>
              { category }
            </em>
          </Small>
        </div>

        <Grid>
          <Row>
            <Col style={{
              marginRight: '12px'
            }}>
              <div style={{
                marginBottom: '2px'
              }}>
                <H5>
                  { makeNumbersPretty(count) }
                </H5>
              </div>
              <Tiny>
                { 'emails' }
              </Tiny>
            </Col>
            <Col>
              <div style={{
                marginBottom: '2px'
              }}>
                <H5>
                  { d3.format('%')(percent) }
                </H5>
              </div>
              <Tiny>
                { 'of total' }
              </Tiny>
            </Col>
          </Row>
        </Grid>
      </Card>
    )
  }
};

export default IncomeDistributionTooltip;