import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import TooltipWrapper from './tool-tipped-div';

class BrandDegreeTooltip extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var {
      imgSrc,
      text
    } = this.props;

    return (
      <TooltipWrapper tooltip={ text }>
        <img src={ imgSrc } />
      </TooltipWrapper>
    )
  }
}

BrandDegreeTooltip.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default BrandDegreeTooltip;
