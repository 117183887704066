import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import GeoDistributionBar from 'visual-components/util/graphs/geo-distribution-bar'
import CardDateRangePickerRow from 'visual-components/util/cards/card-date-range-picker-row'
import { Small } from 'visual-components/util/texts'
import EmptyState from 'visual-components/util/graphs/empty-state'
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header'

class GeoDistributionCard extends Component {
  static propTypes = {
    alignLeft: PropTypes.bool,
    changeFilter: PropTypes.func.isRequired,
    datePicker: PropTypes.object.isRequired,
    els: PropTypes.array.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
    name: PropTypes.string,
  }

  static defaultProps = {
    alignLeft: false,
    name: undefined,
  }

  render() {
    const {
      name,
      els,
      oldestDate,
      alignLeft,
      datePicker,
      changeFilter,
    } = this.props

    const newestDate = new Date()

    return (
      <Card style={{ width: '314px', height: 'auto', overflow: 'visible' }}>
        <div style={{ padding: '20px 24px 8px 24px' }}>
          <AnalyticsHeader
            title="Geographic Distribution"
            tooltipText="The top three regions where you have acquired emails"
          />
          {
            els.some(el => el.perc > 0)
              ? (
                <div>
                  <div style={{ paddingBottom: '15px' }}>
                    <Small>
                      <em>
                        EMAILS ACQUIRED - TOP REGIONS
                      </em>
                    </Small>
                  </div>
                  <div
                    style={{
                      marginTop: '-10px',
                      marginBottom: '-10px',
                      height: '110px',
                    }}
                  >
                    <GeoDistributionBar name={name} values={els} />
                  </div>
                </div>
              )
              : (
                <EmptyState
                  text="You haven't requested geographic data from entrants. Add a zip code to future entry forms to view this data."
                  height="117px"
                />
              )
          }
        </div>
        <Grid>
          <CardDateRangePickerRow
            oldestDate={oldestDate}
            newestDate={newestDate}
            changeFilter={changeFilter}
            alignLeft={alignLeft}
            {...datePicker}
          />
        </Grid>
      </Card>
    )
  }
}

export default GeoDistributionCard
