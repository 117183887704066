import labelMap from 'util/media-market-filter-labels'
import { rangeDefaults, radioDefaults, checkboxDefaults } from './helpers'

const mediaMarketSearch = type => ({
  /** OPTIONS COMMON TO ALL SEARCH MODELS **/

  // Initial page (usually always 1)
  page: 1,

  // Number of items per page
  size: 24,

  // Total number items returned by search
  total: 0,

  // The text input
  searchValue: '',

  // An object representing options for the SortByFilter
  sortBy: {
    // The field by which results should initially be sorted
    value: type === 'sellers' ? 'sellerLastActivity' : 'buyerLastActivity',
    // The initial ordering (true = ascending, false = descending)
    asc: false,
    // Array of options for how search results can be sorted
    // value = the key in Elasticsearch
    // label = human readable label
    options: [
      {
        value: type === 'sellers' ? 'sellerLastActivity' : 'buyerLastActivity',
        label: `Recent ${type === 'sellers' ? 'Seller' : 'Buyer'} Activity`,
      },
      {
        value: type === 'sellers' ? 'sellerCount' : 'buyerCount',
        label: 'Most Popular',
      },
      {
        value: 'ratingMarketplaceAvg',
        label: 'Rating',
      },
    ],
    // Object with the default initial ordering for each option
    // Note: only options that are initially defaulted to ASCENDING need to be
    // enumerated here, all others are assumed to be DESCENDING
    // key: key in Elasticsearch
    defaultDirections: {},
  },

  // An object representing the expanded state of each filter in the search view
  // key: key in model
  filtersExpanded: {
    listSizeValue: true,
    recentActivity: true,
    totalSocialAudience: true,
    tags: true,
    genderTarget: true,
    ageTarget: true,
  },

  /** INDIVIDAUL FILTERS PER SEARCH MODEL **/
  // Range filters
  listSizeValue: rangeDefaults({ term: 'dedicatedListSize' }),
  totalSocialAudience: rangeDefaults({ term: 'socialMediaImpressions' }),

  // Radio filters
  recentActivity: radioDefaults({
    term: type === 'sellers' ? 'sellerLastActivity' : 'buyerLastActivity',
    value: 'older',
    options: [
      {
        label: 'Active in last 30 days',
        value: '30',
      },
      {
        label: 'Active in last 60 days',
        value: '60',
      },
      {
        label: 'Active in last 90 days',
        value: '90',
      },
      {
        label: 'Last active 90+ days ago',
        value: 'older',
      },
    ],
  }),

  // Checkbox filters
  tags: checkboxDefaults({ term: 'tags.raw' }),
  ageTarget: checkboxDefaults({ term: 'ageTarget.raw', labelMap, excludeCount: true }),
  genderTarget: checkboxDefaults({ term: 'genderTarget.raw', labelMap, excludeCount: true }),
  marketplace: checkboxDefaults({ term: 'marketplace.raw', labelMap }),
})

export default mediaMarketSearch
