import React, { Component } from 'react'

import ExploreContainer from 'visual-components/explore/explore-container'
import { P } from 'visual-components/util/texts'
import OverviewBar from 'visual-components/analytics/util/overview-bar'
import SearchPartnershipAnalyticsConfig from 'actions/search-config/search-partnership-analytics-config'
import PartnershipAnalyticsResultsContainer from 'visual-components/analytics/partnerships/PartnershipAnalyticsResultsContainer'
import moment from 'moment-timezone'
import d3 from 'd3'
import PartnershipsOverviewFilters from './partnerships-overview-filters'

class PartnershipsOverview extends Component {
  renderOverviewAnalytics = ({
    total,
    allEntries,
    allNTF,
    avgListSize,
  }) => (
    <OverviewBar
      overviewCellWidth="16.66%"
      metrics={[
        {
          label: 'total partnerships',
          value: total,
        },
        {
          label: 'entries driven',
          value: allEntries.value,
        },
        {
          label: 'avg entries/partner',
          value: allEntries.value && total
            ? allEntries.value / total
            : 0,
        },
        {
          label: 'total NTF',
          value: allNTF.value,
        },
        {
          label: '% NTF',
          value: allNTF.value && allEntries.value
            ? 100 * allNTF.value / allEntries.value
            : 0,
          isPercent: true,
        },
        {
          label: 'avg list size',
          value: avgListSize.value,
        },
      ]}
    />
  )

  renderAnalyticsResults = ({
    results,
    loading,
    error,
    filtered,
    gridView,
  }) => (
    <PartnershipAnalyticsResultsContainer
      partnerships={results || []}
      loading={loading}
      error={error}
      filtered={filtered}
      display={gridView}
    />
  )

  exportCSV = () => {
    let csvString = 'Partner Name, Last Partnered, Campaigns, List Size, Average Entries, NTF, NTF%\n';

    store.getState().partnershipAnalyticsSearch.results.map(partnership => {
      return [
        partnership.partner_brand_name,
        moment(partnership.last_partnered_date).format('MM-DD-YYYY:HH:MM:SS'),
        partnership.num_campaigns,
        partnership.dedicatedListSize,
        partnership.avg_entries,
        partnership.total_ntf_driven,
        d3.format(".2%")(partnership.ntf_perc/100)
      ]
    }).forEach(arr => csvString += (arr.toString() + "\n"))

    window.location.href =
      'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvString)
  }

  render() {
    return (
      <ExploreContainer
        config={SearchPartnershipAnalyticsConfig}
        title="Partnership Analytics"
        titleActionListOnly={this.exportCSV}
        titleActionLabel="Export CSV"
        belowTitle={
          <div style={{ marginTop: '16px' }}>
            <P multiline>
              A overview of all the Partners with whom you have worked on DojoMojo.
              Analyze and evaluate future potential Partners based on what
              worked in the past.
            </P>
          </div>
        }
        searchPlaceholder="Search partners"
        renderAboveResults={this.renderOverviewAnalytics}
        filters={<PartnershipsOverviewFilters />}
        render={this.renderAnalyticsResults}
      />
    )
  }
}

export default PartnershipsOverview
