import React, { Component } from 'react';
import d3 from 'd3'
import { Card } from 'react-toolbox/lib/card';

import AnalyticsTheme from 'css/themes/analytics.css';
import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header';
import ContributionBar from 'visual-components/analyticsDashboard/overviewCard/contribution-bar';
import { H2, Small } from 'visual-components/util/texts'

class EntryContributionCard extends Component {
  render() {
    let {
      data,
      isHost,
      filterWebbula,
      started,
    } = this.props;

    let totalEntries = data.reduce((sum, item) => sum + item.entries, 0)

    return (
      <Card className={ `${AnalyticsTheme.ctaBarCard} ${AnalyticsTheme.contributionCard}` }>
        <AnalyticsHeader
          title="Entry Contribution"
          tooltipText={ filterWebbula ? `To protect your deliverability, the entries we send to you are first scrubbed using Webbula. Expect the number of entries you receive to be 5-15% lower than what you see here.` : null}
        />
        <div>
          <div style={{
            display: 'inline-block',
            marginBottom: '12px',
            marginRight: '3px',
          }}>
            <H2>
              { started ? d3.format(',')(totalEntries) : 0 }
            </H2>
          </div>
          <div style={{
            display: 'inline-block',
            paddingLeft: '4px'
          }}>
            <Small>
              total entries
            </Small>
          </div>
        </div>
        <ContributionBar
          name="entries"
          data={data}
          isHost={isHost}
          started={started}
        />
      </Card>
    );
  }
}

export default EntryContributionCard;
