import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';
import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H3 } from 'visual-components/util/texts';

import TextThemes from 'css/themes/Text.css';

class ProfileSocialStat extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      icon,
      value,
      obscure,
      socialHandle
    } = this.props;

    if (!value && !socialHandle) {
      return null;
    }
    
    if (socialHandle && socialHandle.includes('@')) {
      socialHandle = socialHandle.replace(/@/g, '')
    }

    const socialLink = socialHandle && icon && `https://${icon}.com/${socialHandle}`

    var formatted = makeNumbersPretty(value);

    return(
      <div>
        <div style={{
          width: '80px',
          marginRight: '12px',
          marginBottom: '16px'
        }}>
          <a href={socialLink} target="_blank">
            <img src={ `/images/new_social_icons/${icon}-icon.svg` } style={{ float: 'left' }} />
          </a>
          <div style={{
            display: 'inline-block',
            marginLeft: '8px',
            height: '24px',
            paddingTop: '3px'
          }}>
            <H3>
              <small>
                <span className={ obscure ? TextThemes.obscure : '' }>
                  { obscure ? '****' : formatted }
                </span>
              </small>
            </H3>
          </div>
        </div>
      </div>
    )
  }
}

ProfileSocialStat.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
};

export default ProfileSocialStat;
