import React from 'react'
import { render } from 'react-dom'
import * as Sentry from '@sentry/browser'

import './../../styles/admin/admin.less'
import versionJson from '../../version.json'

import copyLoader from 'util/copy-loader'

import configureStore from 'util/configure-store'
const store = configureStore()
window.store = store

import { AppContainer } from 'react-hot-loader'

import Root from './v2-root'
import initDataDog from './datadog'

const { version } = versionJson

const domElement = document.getElementById('content-holder')
const basename = '/app';

// Top-level function that renders App
const renderApp = routes => copyLoader(() => render(
  <AppContainer>
    <Root
      store={ store }
      routes={ routes }
      basename={ basename }
    />
  </AppContainer>
  , domElement
))

// Configure Sentry in staging and prod only
if (!!process.env.SENTRY_ENV) {
  const sentryOptions = {
    dsn: 'https://b87bd6d0c16e4be3a82529b6760981df@sentry.io/79582',
    environment: process.env.SENTRY_ENV,
  }
  // Only attach release in production
  if (process.env.SENTRY_ENV === 'production') {
    sentryOptions.release = version
  }

  Sentry.init(sentryOptions)

  // Datadog
  initDataDog()
}

let impersonating = getParameterByName('impersonating')

try {
  // if impersonation token specified in URL, save it
  if (impersonating) {
    sessionStorage.setItem('impersonating', impersonating)
  } else {
    // otherwise check if it was already saved
    impersonating = sessionStorage.getItem('impersonating')
  }
} catch (er) {
  // stub storage methods
  window.sessionStorage = {
    getItem: () => {},
    setItem: () => {},
  }
}

// Initial render
renderApp(require('./v2-routes.js').default)

// Hot Module Replacement in dev only
if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./v2-routes.js', () => {
      renderApp(require('./v2-routes.js').default);
    })
  }
}
