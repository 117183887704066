import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import versionJson from '../../version.json'

import App from './v2-app'
import ErrorBoundary from './error-boundary'

import InternalNavBar from 'visual-components/navBar/InternalNavBar'
import LiveNotifications from './components/util/LiveNotifications'
import QuestCollapse from './components/util/QuestCollapse/QuestCollapse';

import Login from './components/Login'
import CcpaRequest from 'visual-components/ccpaRequest/ccpa-request'
import BrandCcpaRequest from 'visual-components/ccpaRequest/brand-ccpa-request'
import Profile from './components/profile/Profile'
import Analytics from './components/analytics/Analytics'
import MyPartnerships from 'visual-components/myPartnerships/MyPartnerships'
import Explore from './components/explore/Explore'
import Notifications from './components/notifications/Notifications';
import Messaging from './components/messaging/Messaging'
import BrandMessage from 'components/brands/brand-message';
import BrandApplication from 'components/brands/brand-application';
import BrandInvite from 'components/brands/brand-invite';
import NewCampaign from 'components/campaigns/new_campaign/new-campaign-container';
import NewSoloSweepstakes from 'components/campaigns/new_campaign/new-solo-sweepstakes-container';
import Builder from 'components/campaigns/builder/builder-container';
import UpgradePlan from 'components/pricing/upgrade-plan';
import SocialActionsPurchaseSuccess from 'visual-components/myPartnerships/social-actions-purchase-success';
import EliteRequest from 'components/pricing/elite-request';
import RegisterBrandSetup from 'components/registration/brand-setup';
import RegisterBrandProfile from 'components/registration/brand-profile';
import RegisterBrandProfileInterests from 'components/registration/brand-profile-interests';
// TODO (Jenky): Disabled (10/25/2022) until we have people back on sales
// import CallOffer from 'components/registration/call-offer';
import InterestedInLearnMoreModal from 'visual-components/util/modals/interested-in-learn-more-modal';
import NewUserReferredCampaignModal from 'visual-components/util/modals/new-user-referred-campaign-modal';
import PricingGateModal from 'visual-components/util/modals/pricing-gate-modal';
import ContactSupportModal from 'visual-components/util/modals/contact-support-modal';
import Admin from 'components/admin/admin';
import CustomerSuccessAdmin from 'components/cs-admin/customer-success-admin-container';
import DataAdmin from 'components/data-admin/data-admin-container';
import QualityAssuranceAdmin from 'components/qa-admin/quality-assurance-admin-container';
import Splash from 'visual-components/splash/Splash';
import InviteBannerContainer from './components/util/InviteBanner/InviteBannerContainer';
import AcceptInviteConfirmationModal from 'visual-components/messaging/messaging-accept-invite-confirmation-modal';
import AcceptApplicationConfirmationModal from 'visual-components/messaging/messaging-accept-application-confirmation-modal';
import AcceptPartnershipModal from 'visual-components/messaging/messaging-accept-partnership-modal';
import DeclinePartnershipModal from 'visual-components/messaging/messaging-decline-partnership-modal';
import PlatformInviteModal from 'components/search_brands/platform-invite-modal'
import PlatformInviteSuccessModal from 'components/search_brands/platform-invite-success-modal'
import PartnershipsCalendar from 'components/partnerships/PartnershipsCalendarContainer'
import Intercom from 'components/util/Intercom'

/* static */
import NotFound from 'visual-components/static/NotFound'

// payments
import PaymentReceivedFullModal from 'visual-components/payments/payment-received-full-modal'
import UpgradePlanFullModal from 'visual-components/payments/upgrade-plan-full-modal'
import SMSCheckoutModal from 'visual-components/mobile-marketing/modals/SMSCheckoutModal'
import SMSPurchaseSuccessModal from 'visual-components/mobile-marketing/modals/SMSPurchaseSuccessModal'

import ROICalculator from 'visual-components/roi_calculator'

/* modals */
import QuestCompleteModal from './components/util/QuestCollapse/quest-complete-modal';
import BrandLogoRequiredModal from './components/brands/BrandLogoRequiredModal';
import CurrentTaskModal from '../app/visual-components/myPartnerships/createTasks/current-task-modal';
import DeleteTaskConfirmationDialog from '../app/visual-components/myPartnerships/createTasks/delete-task-confirmation-dialog';
import CreateOpportunityModal from './components/opportunities/create-opportunity-modal';
import SocialActionsPurchaseModal from '../app/visual-components/myPartnerships/social-actions-purchase-modal'

import ConfirmationModal from 'util/components/confirmation-modal';
import NotEnoughEntriesModal from 'util/components/not-enough-entries';
import TrialEndedModal from 'components/insert-payment-info/trial-ended-modal';
import TrialExtendedModal from 'components/insert-payment-info/trial-extended-modal';
import PreferredTermsPlanModal from 'components/insert-payment-info/preferred-terms-plan-modal';
import SoftEntriesPaywallModal from 'components/insert-payment-info/soft-entries-paywall';
// TODO (Jenky): Disabled (10/25/2022) until we have people back on sales
// import CalendlyModal from 'components/notifications/calendly-modal';
import MessagingLimitPartnersModal from 'visual-components/messaging/messaging-limit-partners';
import ReferralProgramReminderDialog from 'visual-components/util/modals/referral-program-reminder-dialog'
import CheckNewVersion from 'container-components/CheckNewVersion'
import ReviewPartnershipAgreement from 'v2/components/partnership_agreement/ReviewPartnershipAgreement';
import UpdatedTermsModal from 'visual-components/UpdatedTerms/UpdatedTermsModal';

import MediaReviewsPage from 'visual-components/brandProfile/brand-reviews/MediaReviewsPage'
import MediaReviewsModal from 'visual-components/brandProfile/brand-reviews/MediaReviewsModal'

const { version } = versionJson

const starsIcon = (
  <svg width="12" height="12" style={{ marginTop: '-3px' }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0438 12.9562C11.0813 11.9937 7.5 11.5875 7.5 11.25C7.5 10.9125 11.0813 10.5062 12.0438 9.54375C13.0062 8.58125 13.4125 5 13.75 5C14.0875 5 14.4937 8.58125 15.4562 9.54375C16.4187 10.5062 20 10.9125 20 11.25C20 11.5875 16.4187 11.9937 15.4562 12.9562C14.4937 13.9187 14.0875 17.5 13.75 17.5C13.4125 17.5 13.0062 13.9187 12.0438 12.9562Z" fill="#1CE8AB" />
    <path d="M4.92333 6.74333C4.41 6.23 2.5 6.01333 2.5 5.83333C2.5 5.65333 4.41 5.43667 4.92333 4.92333C5.43667 4.41 5.65333 2.5 5.83333 2.5C6.01333 2.5 6.23 4.41 6.74333 4.92333C7.25667 5.43667 9.16667 5.65333 9.16667 5.83333C9.16667 6.01333 7.25667 6.23 6.74333 6.74333C6.23 7.25667 6.01333 9.16667 5.83333 9.16667C5.65333 9.16667 5.43667 7.25667 4.92333 6.74333Z" fill="#1CE8AB" />
    <path d="M4.545 15.455C4.28833 15.1983 3.33333 15.09 3.33333 15C3.33333 14.91 4.28833 14.8017 4.545 14.545C4.80167 14.2883 4.91 13.3333 5 13.3333C5.09 13.3333 5.19833 14.2883 5.455 14.545C5.71167 14.8017 6.66667 14.91 6.66667 15C6.66667 15.09 5.71167 15.1983 5.455 15.455C5.19833 15.7117 5.09 16.6667 5 16.6667C4.91 16.6667 4.80167 15.7117 4.545 15.455Z" fill="#1CE8AB" />
  </svg>
)

const openIcon = (
  <svg width="12" height="12" style={{ marginBottom: '-2px' }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.72667L3.17333 7.88667L4.11333 8.82667L10.6667 2.27333V4.66667H12V0H7.33333Z" fill="#F4F7F8"/>
  </svg>

)

const routes = [{
  label: 'Discover',
  to: '/',
  exact: true,
  overrideHref: '/',
}, {
  label: 'Sweepstakes',
  to: '/sweepstakes',
  overrideHref: '/app/sweepstakes',
}, {
  label: 'SMS Marketing',
  to: '/sms-marketing',
  overrideHref: '/app/sms-marketing',
  validation: brand => !brand.features.block_sms,
}, {
  label: 'Analytics',
  to: '/analytics',
}, {
  label: (
    <>
      {starsIcon}
      <span style={{ color: '#1CE8AB' }}>
        AI Audiences
      </span>
      &nbsp;
      {openIcon}
    </>
  ),
  to: 'https://www.proxima.ai/dojomojo',
  external: true,
}]

const BrandSwitcher = ({ match: { params: { brandId, userId }}, location: { state }}) => {
  try {
    sessionStorage.setItem(userId, brandId)
  } catch (e) {
    console.error('sessionStorage unavailable')
  }
  return <Redirect to={ (state && state.to) || '/' } />
}

const bannerAvoidRoutes = [
  'calculate-roi',
  'upgrade-plan',
  'upgrade-payment-confirm/',
  'upgrade-success/',
]

const redirectToFuture = ({ location }) => {
  window.location.replace(`/app${location.pathname}`)
  return null
}

const Routes = (
  <ErrorBoundary>
    <CheckNewVersion currentVersion={version} />
    <Switch>
      <Route exact path="/login" component={ Login } />
      <Route exact path="/ccpa-request" component={ CcpaRequest } />
      <Route exact path="/switch/:brandId/:userId" render={ BrandSwitcher } />
      <Route path="/missing-payment" component={ UpgradePlanFullModal } />
      <Route path="/registrations/brand-setup/:referralId?" component={ RegisterBrandSetup } />
      <Route path="/registrations/brand-profile" component={ RegisterBrandProfile } />
      <Route path="/registrations/brand-profile-interests" component={ RegisterBrandProfileInterests } />
      {/* TODO (Jenky): Disabled (10/25/2022) until we have people back on sales */}
      {/* <Route path="/registrations/call-offer" component={ CallOffer } /> */}
      <Route path="/new-splash" component={ Splash } />
      <Route render={ routeProps => (
        <App>
          <InternalNavBar routes={ routes } { ...routeProps } />
          <ErrorBoundary>
            <LiveNotifications />
            <QuestCollapse avoidRoutes={bannerAvoidRoutes} />
            <Route path="/explore/(campaigns|brands)/:id" render={redirectToFuture} />
            <Switch>
              <Route path="/admin" component={ Admin } />
              <Route exact path="/brand-ccpa-request" component={ BrandCcpaRequest } />
              <Route path="/builder/:campaignId/:section/:subSection?/:partnershipId?" component={ Builder }/>
              <Route path="/analytics" component={ Analytics } />
              <Route path="/calculate-roi" component={ROICalculator} />
              <Route path="/cs-admin/:section?" component={ CustomerSuccessAdmin } />
              <Route path="/calendar" component={PartnershipsCalendar} />
              <Route path="/data-admin" component={ DataAdmin } />
              <Route path="/elite-request" component={ EliteRequest } />
              <Route path="/explore" component={ Explore } />
              <Route path="/messaging" component={ Messaging } />
              <Route path="/notifications" component={ Notifications } />
              <Route path="/new-campaign" component={ NewCampaign }/>
              <Route path="/new-solo-sweepstakes" component={ NewSoloSweepstakes }/>
              <Route path="/partnerships/purchase-social-actions" component={SocialActionsPurchaseModal} />
              <Route path="/partnerships" component={ MyPartnerships } />
              <Route path="/partnership-agreement/:partnershipId" component={ReviewPartnershipAgreement} />
              <Route path="/profile" component={ Profile } />
              { process.env.SENTRY_ENV !== 'production' && <Route path="/qa-admin" component={ QualityAssuranceAdmin } /> }
              <Route path="/reviews" component={MediaReviewsPage} />
              <Route path="/social-action-purchase-success" component={ SocialActionsPurchaseSuccess } />
              <Route path="/upgrade-plan" component={ UpgradePlan } />
              <Route path="/upgrade-success/:belt" component={ PaymentReceivedFullModal } />
              <Route path="/upgrade-trial-confirm/:belt" component={ UpgradePlanFullModal } />
              <Route path="/upgrade-payment-confirm/:belt" component={ UpgradePlanFullModal } />
              <Route path="/manage-sms-marketing" component={ SMSCheckoutModal } />
              <Route path="/sms-marketing-purchase-success" component={ SMSPurchaseSuccessModal } />
              <Route path="/404" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
            <BrandMessage />
            <BrandApplication />
            <InterestedInLearnMoreModal />
            <PricingGateModal />
            <ContactSupportModal />
            <ConfirmationModal />
            <NotEnoughEntriesModal />
            <TrialEndedModal />
            <TrialExtendedModal />
            <PreferredTermsPlanModal />
            {/* TODO (Jenky): Disabled (10/25/2022) until we have people back on sales */}
            {/* <CalendlyModal /> */}
            <SoftEntriesPaywallModal />
            <BrandApplication />
            <BrandInvite />
            <QuestCompleteModal />
            <AcceptInviteConfirmationModal />
            <AcceptApplicationConfirmationModal />
            <AcceptPartnershipModal />
            <DeclinePartnershipModal />
            <NewUserReferredCampaignModal />
            <BrandLogoRequiredModal />
            <MessagingLimitPartnersModal />
            <ReferralProgramReminderDialog />

            <CurrentTaskModal />
            <DeleteTaskConfirmationDialog />
            <CreateOpportunityModal />
            <UpdatedTermsModal />
            {/* TODO: Check how to set priority in these modals */}
            <MediaReviewsModal />
            <PlatformInviteModal />
            <PlatformInviteSuccessModal />
            <Intercom />
          </ErrorBoundary>
        </App>
      )} />

    </Switch>
  </ErrorBoundary>
)


export default Routes
