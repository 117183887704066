import React from 'react';

import ContributionBarTooltip from 'visual-components/analyticsDashboard/tooltip/contribution-bar-tooltip';
import SocialContributionTooltip from 'visual-components/analyticsDashboard/tooltip/social-contribution-tooltip';
import { makeNumbersPretty } from 'util/makeNumbersPretty';
var TooltipFactory = React.createFactory(ContributionBarTooltip);
var SocialTooltipFactory = React.createFactory(SocialContributionTooltip)

import { renderToString } from 'react-dom/server'
import _ from 'lodash';

const renderGraph = function (props) {
  if (!props.started) {
    return
  }

  $(`.contribution-${props.name}`).html('');

  let dataCopy = props.data.slice();

  var maxValue = 0;

  dataCopy.forEach((item) => {
    var val = item.entries + parseInt(item.threshold);
    maxValue = maxValue > val ? maxValue : val;
  })

  _.times(10 - dataCopy.length, (i) => {
    dataCopy.unshift({
      accountname: 'remove',
      entries: maxValue,
      threshold: 0
    });
  });
  let partners = dataCopy.map((item) => {
    return item.accountname;
  });
  let values = props.started ? dataCopy.map((item) => {
    return item.entries;
  }) : []

  dataCopy.forEach((item) => {
    item.entriesPretty = d3.format(',')(item.entries)
  });

  new Contour({
      el: `.contribution-${props.name}`,
      chart: {
        height: 398,
        width: 330,
        padding: {
          right: 120,
          left: 0,
          top: 5
        }
      },
      yAxis: {
          // this set of options will turn off all ticks on the yAxis
          ticks: 0,
          tickPadding: 0,
          innerTickSize: 0,
          outerTickSize: 0
      },
      bar: {
        barWidth: 15,
        offset: 0,
        
      },
      tooltip: {
        opacity: 1,
        formatter: function (d) {
          let threshold = props.isHost && dataCopy[d.x].threshold;
          let socialBreakdown = dataCopy[d.x].breakdown;
          if (threshold) {
            return renderToString(TooltipFactory({
              threshold: threshold,
              performance: parseInt(d.y) / threshold
            }));
          } else if (socialBreakdown) {
            return renderToString(SocialTooltipFactory({
              data: socialBreakdown,
              brandName: dataCopy[d.x].accountname,
              datePretty: dataCopy[d.x].datePretty,
            }))
          } 
          else {
            return null;
          }
        }
      }
  })
  .cartesian()
  .horizontal()
  .bar(values)
  .tooltip()
  .contributionLegend(values, {
    items: dataCopy,
    isHost: props.isHost
  })
  .render();
};

var debouncedRender = _.debounce(renderGraph, 300);

class ContributionBar extends React.Component {
  componentDidMount() {
    this.graph = renderGraph(this.props);
  }

  componentDidUpdate() {
    debouncedRender(this.props);
  }

  render() {
    var {
      name
    } = this.props;

    return (
      <div style={{
        display: 'inline-block',
        marginLeft: '-20px'
      }}>
        <div className={`contribution-${name}`}></div>
      </div>
    );
  }
}

export default ContributionBar;
