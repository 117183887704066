import React, { Component } from 'react'
import moment from "moment-timezone"
import { Card } from 'react-toolbox/lib/card'
import Clipboard from 'clipboard'

import { Tiny, H5, Small } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import ButtonTheme from 'css/themes/Buttons.css'
import TaskCardTheme from 'css/themes/TaskCardTheme.css'
import DedicatedEmailPromoTheme from 'css/themes/dedicated-email-promo-theme.css'
import TaskListTheme from 'css/themes/TaskList.css';

import PopoutTaskcardButtons from '../util/buttons/popout-taskcard-buttons/PopoutTaskcardButtons'

class LinkItem extends Component {
  componentDidMount() {
    new Clipboard(`.promotional-task${this.props.id}`)
  }

  toaster = () =>
    toastr.success('Link copied.', null, {
      timeOut: 1000,
      positionClass: 'toast-bottom-center',
    })

  render() {
    const {
      link,
      promotionaltype,
      label,
      id
    } = this.props
    return (
      <div className={ DedicatedEmailPromoTheme.container }>
        <div className={ DedicatedEmailPromoTheme.label }>
          <Tiny><b>{ label || promotionaltype }</b></Tiny>
        </div>
        <div className={ DedicatedEmailPromoTheme.link }>
          <Tiny>{link}</Tiny>
        </div>
        <img
          onClick={this.toaster}
          data-clipboard-text={ link }
          style={{cursor: 'pointer'}}
          className={`promotional-task${ id } ${DedicatedEmailPromoTheme.ripple}`}
          src="/images/icons/copy.svg" />
      </div>
    )
  }
}



class TasksPopoutTemplate extends Component {
  render() {
    const {
      isAssignee,
      title,
      due,
      color,
      campaignName,
      isComplete,
      description,
      event,
      deselectTask,
      sendMessage,
      edit,
      markComplete,
      manageCampaign,
      analytics,
      exportToCal,
      ctaAction,
      promoUnits,
      deselectShowMore
    } = this.props;

    const momentTaskDueDate = moment(due);
    const isDue = momentTaskDueDate.diff(moment().toDate(), 'day') < 1;

    const cta = () => {
      deselectTask()
      deselectShowMore()
      ctaAction.onClick()
    }

    let top = event.top
    let left = event.left

    if (title === 'Media Promotion') {
      // ensure large media promotion has space.
      if (window.innerHeight - top < 440) {
        top = window.innerHeight - 440
      }
    }

    return(
      <div>
        <div onClick={deselectTask} className={TaskCardTheme.overlay} />
        <Card style={{
          top: `${top}px`,
          left: `${left}px`,
        }}
        className={ TaskCardTheme.mainContainer }>
          <Grid>
              <Row>
                <div className={ TaskCardTheme.headerDisplay } style={{ backgroundColor: `${color}` }}>
                  <div className={ TaskCardTheme.taskName }>
                    <H5 white><b>{ title }</b></H5>
                  </div>

                  <div className={ TaskCardTheme.campaignName }>
                    <Small white><em>{ campaignName }</em></Small>
                  </div>
                </div>

                <div className={ TaskCardTheme.closeButton }>
                  <img onClick={ deselectTask } src="/images/icons/small-white-close.svg" />
                </div>
              </Row>


              {/*task due date*/}
              <Row style={{
                height: '24px'
              }}>
                <div className={ TaskCardTheme.taskDate }>
                  <img src="/images/date-and-time.svg" />
                  <div style={{ position: 'absolute', top: '3px', left: '18px' }}><Tiny>{ momentTaskDueDate.format('dddd, MMM D') }</Tiny></div>
                </div>

                  { isComplete ?
                    <div className={ TaskCardTheme.isComplete }>
                      <img src="/images/icons/complete.svg" />
                    </div> :
                    isDue ?
                      <div className={ TaskCardTheme.isDue }>
                        <div className={TaskListTheme.dueHolder}>
                          <Tiny white>
                            DUE
                          </Tiny>
                        </div>
                      </div> :
                      <div className={ TaskCardTheme.isComplete }>
                        <img src="/images/icons/incomplete.svg" />
                      </div>
                  }
              </Row>

              { isAssignee
                ? (
                  <Row>
                    <div className={ TaskCardTheme.taskDescription }>
                      <Tiny multiline>{ description }</Tiny>
                    </div>

                    <div className={ TaskCardTheme.CTA }>
                      {  ctaAction &&
                        <button
                          onClick={ cta }
                          className={ ButtonTheme.taskCardButton }>
                          { ctaAction.label }
                        </button>
                      }
                      { promoUnits && promoUnits.map((unit, idx) => {
                          return <LinkItem key={idx} {...unit} />
                        })
                      }

                    </div>
                  </Row>
                ) : null
              }

              <Row>
                <div className={ TaskCardTheme.buttons }>
                  <PopoutTaskcardButtons
                    deselect={deselectTask}
                    exportToCal={exportToCal}
                    edit={edit}
                    analytics={analytics}
                    deselectShowMore={deselectShowMore}
                    manageCampaign={manageCampaign}
                    sendMessage={sendMessage}
                    markComplete={markComplete}
                  />
                </div>
              </Row>
          </Grid>
        </Card>
      </div>
    )
  }
}


export default TasksPopoutTemplate;
