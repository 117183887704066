import React from 'react';
import _ from 'lodash'

import { renderToString } from 'react-dom/server'
import { P, Tiny } from 'visual-components/util/texts';
import TooltipCard from 'visual-components/util/graphs/column-grouped-tooltip';

var TooltipFactory = React.createFactory(TooltipCard)

var renderGraph = props => {
  const { data, started, graphClass } = props
  $(`.column-grouped.${graphClass}`).html('');

  let mappedData = {};
  data.categories.forEach((category, idx) => {
    mappedData[category] = data.items.map(item => {
      return {
        series: item.title,
        y: item.formattedData && item.formattedData.length ? item.formattedData[idx] : item.data[idx]
      }
    });
  })

  data.items.forEach(item => {
    item.selected = !data.hideMap[item.name];
  });

  var items = started ? _.filter(data.items, { selected: true }) : []

  var graph = new Contour({
    el: `.column-grouped.${graphClass}`,
    column: { groupPadding: 4 },
    xAxis: {
      categories: started ? data.categories : null
    },
    yAxis: props.yAxis || {},
    tooltip: {
      opacity: 1,
      formatter: function (d) {
        return renderToString(TooltipFactory({
          label: d.x,
          data: mappedData[d.x]
        }));
      }
    }
  })
  .cartesian()
  .yAxisLines(null, {
    showAll: data.showAll
  })
  .column(items)
  .ellipseLabel()
  .tooltip()
  .assignColors(items, {
    colorMap: data.colorMap
  })
  .render();

  return graph;
};

class ColumnGrouped extends React.Component {
  componentDidMount() {
    renderGraph(this.props);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      renderGraph(this.props);
    }
  };

  render() {
    return (
      <div style={{position: 'relative'}}>
        <div
          className={` column-grouped ${this.props.graphClass}`}
          style={{
            width: '928px',
            height: '132px',
            display: 'inline-block',
          }}
        />
      </div>
    );
  }
}

export default ColumnGrouped;
