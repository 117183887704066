import { Component } from 'react'
import moment from 'moment-timezone'

import { Card } from 'react-toolbox/lib/card'
import { Small, Tiny, H6 } from 'visual-components/util/texts'
import CampaignsListTheme from 'css/themes/calendar/campaignsList.css'


class ShowMoreTemplate extends Component {
  render() {
    const styles = {
      header: {
        height: '50px',
        marginBottom: '12px'
      },
      date: {
        marginLeft: '17px',
        marginTop: '12px',
      },
      title: {
        marginTop: '11px',
        marginLeft: '17px',
      },
      builder: {
        marginTop: '10px',
        marginLeft: '17px',
        paddingBottom: '15px',
        cursor: 'pointer'
      },
      closeButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        cursor: 'pointer'
      }
    }

    const {
      date,
      tasks,
      campaigns,
      show,
      deselectShowMore,
      event,
      selectTask,
      selectCampaign
    } = this.props

    const select = (task, e) => {
      let cardHeight = 0
      let cardWidth = 0

      if (e.clientY > window.innerHeight - 455) {
        cardHeight = window.innerHeight - 455 
      } else {
        cardHeight = e.clientY - 200
      }

      if (e.clientX > window.innerWidth - 300) {
        cardWidth = window.innerWidth - 600
      } else if (e.clientX - 300 < 0) {
        cardWidth = e.clientX + 150
      } else {
        cardWidth = e.clientX - 400
      }

      selectTask(task, {
        left: cardWidth,
        top: cardHeight
      })
    }


    if (!show) return null

    const mapTasks = tasks.map((taskObj, idx) => {
      return (
        <div key={idx} className={ CampaignsListTheme.SmallIndividualCampaign } onClick={ (e) => { select(taskObj, e) } }>
          <div style={{ backgroundColor: taskObj.color }} className={ CampaignsListTheme.smallColorIcon } />

          <div className={ CampaignsListTheme.individualCampaignName }>
            <Small>{ taskObj.title.length > 33 ? `${taskObj.title.slice(0,33)}...` : taskObj.title }</Small>
          </div>
        </div>
      )
    })


    const selectCampaignWithEvent = (campaign, e) => {
      let cardHeight = 0
      let cardWidth = 0

      if (e.clientY > window.innerHeight - 455) {
        cardHeight = window.innerHeight - 400 
      } else {
        cardHeight = e.clientY - 200
      }

      if (e.clientX > window.innerWidth - 300) {
        cardWidth = window.innerWidth - 600
      } else if (e.clientX - 300 < 0) {
        cardWidth = e.clientX + 150
      } else {
        cardWidth = e.clientX - 400
      }

      selectCampaign(campaign, {
        left: cardWidth,
        top: cardHeight
      })
    }

    const mapCampaigns = campaigns.map((campaignObj, idx) => {
      let header = campaignObj.header || campaignObj.name
      header = header.length > 33 ? `${header.slice(0,33)}...` : header

      return(
        <div key={idx} className={ CampaignsListTheme.SmallIndividualCampaign } onClick={ (e) => { selectCampaignWithEvent(campaignObj, e) } }>
          <div style={{ backgroundColor: campaignObj.color }} className={ CampaignsListTheme.smallColorIcon } />

          <div className={ CampaignsListTheme.individualCampaignName }>
            <Small>{ header }</Small>
          </div>
        </div>
      )
    })



    return (
      <div>
        <div onClick={deselectShowMore} className={CampaignsListTheme.showMoreOverlay} />
        <Card style={ {
          width: '200px',
          height: '424px',
          position: 'absolute',
          top: `${event.top}px`,
          left: `${event.left}px`,
          zIndex: 5,
        }}>
          <div style={ styles.closeButton }>
            <img onClick={ deselectShowMore } style={{width: '12px', height: '12px'}} src="/images/icons/small-x.svg" />
          </div>

          <div style={ styles.header }>
            <div style={ styles.date }>
              <div style={{ paddingBottom: '3px' }}><Tiny>{ moment(date).format('dddd') }</Tiny></div>
              <H6>{ moment(date).format('MMMM D')}</H6>
            </div>
          </div>

          <div style={{ border: '0.5px solid #dee4e5' }}/>

          <div>
            <Small style={ styles.title }><i>Tasks</i></Small>
            <div style={ styles.builder }>{ mapTasks }</div>
          </div>

          <div style={{ border: '0.5px solid #dee4e5' }}/>

          <div>
            <Small style={ styles.title }><i>Live Campaigns</i></Small>
            <div style={ styles.builder }>{ mapCampaigns }</div>
          </div>
        </Card>
      </div>
    )
  }
}

export default ShowMoreTemplate
