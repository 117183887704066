import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import CaseStudies from 'util/case-study-map'
import ResourcesGuidesMap from 'util/resources-guides-map'

import ResourcesBanner from './resources-banner'
import ResourcesTabPicker from './resources-tab-picker'
import ResourcesCaseStudies from './resources-case-studies'
import ResourcesGuides from './resources-guides'
import Footer from '../footer/Footer'

class Resources extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const caseStudyItems = Object.keys(CaseStudies)
    if (caseStudyItems[0] === '1440') {
      // case study for brand 1440 should be last
      const item = caseStudyItems.splice(0, 1)
      caseStudyItems.push(item[0])
    }

    const caseStudies = caseStudyItems.map(item => (
      <Link to={`/case_studies/${item}`} target="_blank" key={item}>
        <img src={`/images/login/resources/library-imgs/case-studies/${item}-case-study-card.png`} />
      </Link>
    ))

    const resourcesGuides = Object.keys(ResourcesGuidesMap).map(item => (
      <a href={ResourcesGuidesMap[item].fileSrc} target="_blank" rel="noopener noreferrer" key={item}>
        <img src={`/images/login/resources/library-imgs/guides/${item}.png`} />
      </a>
    ))

    return (
      <div>
        <ResourcesBanner />
        <ResourcesTabPicker
          page="all"
        />
        <ResourcesCaseStudies>
          { caseStudies }
        </ResourcesCaseStudies>
        <ResourcesGuides>
          { resourcesGuides }
        </ResourcesGuides>
        <Footer />
      </div>
    )
  }
}

export default Resources
