import React from 'react'
import { object, bool } from 'prop-types'
import moment from 'moment-timezone'
import d3 from 'd3'
import { Card } from 'react-toolbox/lib/card'
import { H4, Small, Tiny } from 'visual-components/util/texts'
import OfferMessageTheme from 'css/themes/media-market/OfferMessage.css'
import classNames from 'classnames'
import Button from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import { Link } from 'react-router-dom'

class RequestMessage extends React.Component {
  static propTypes = {
    item: object.isRequired,
    utilityFunctions: object.isRequired,
    isSender: bool.isRequired,
  }

  onEdit = () => {
    const {
      utilityFunctions: {
        editRequest,
      },
    } = this.props
    editRequest()
  }

  onCreateMediaSell = () => {
    const {
      utilityFunctions: {
        editProposal,
      },
    } = this.props
    editProposal('edit')
  }

  renderButtons = () => {
    const {
      isSender,
      item: {
        deal_id,
      },
    } = this.props
    return (
      isSender
        ? (
          <Button
            label="Edit"
            theme={ButtonTheme}
            className={`${ButtonTheme.blueButton} ${ButtonTheme.shortButton}`}
            primary
            raised
            onClick={this.onEdit}
          />
        )
        : (
          <Link to={`/partnerships/deal/${deal_id}/edit`}>
            <Button
              label="Create Proposal"
              theme={ButtonTheme}
              className={`${ButtonTheme.greenButton} ${ButtonTheme.shortButton}`}
              primary
              raised
            />
          </Link>
        )
    )
  }

  getFooter = (themes, expiration_date) => {
    const timeRemaining = moment(expiration_date).fromNow(true)
    if (moment().diff(expiration_date, 'minutes') > 0) {
      return (
        <div className={`${OfferMessageTheme.actionFooter} ${themes.footerTheme}  ${OfferMessageTheme.footerInnerVerticallyCenter}`}>
          <div className={OfferMessageTheme.footerInner} style={{ marginTop: 0 }}>
            <Tiny alertRed>
              Offer expired
            </Tiny>
          </div>
        </div>
      )
    }

    return (
      <div className={`${OfferMessageTheme.actionFooter} ${themes.footerTheme}`}>
        <div className={OfferMessageTheme.footerInner}>
          <Tiny alertRed={timeRemaining.includes('hr')}>
            <em>
              {`${timeRemaining} left`}
            </em>
          </Tiny>
          {this.renderButtons()}
        </div>
      </div>
    )
  }

  static formatBudget(min, max) {
    if (min >= 10000) {
      return '$10,000+'
    }
    if (min === 0) {
      return 'N/A'
    }

    return `${d3.format('$,')(min)} - ${d3.format('$,')(max)}`
  }

  render() {
    const {
      item: {
        deal_status,
        request: {
          budget_min,
          budget_max,
          start_date,
          end_date,
          is_date_flexible,
          terms,
          brand_description,
          conversion_rate,
          conversion_value,
          interests,
          expiration_date,
        },
      },
      isSender,
    } = this.props
    const themes = {
      mainTheme: isSender
        ? OfferMessageTheme.senderMain
        : OfferMessageTheme.receiverMain,
      footerTheme: isSender
        ? OfferMessageTheme.senderFooter
        : OfferMessageTheme.receiverFooter,
    }

    const dealTerms = terms.map(term => term.tag)
    const mediaInterests = interests.map(interest => interest.tag)

    return (
      <div>
        <Card className={themes.mainTheme}>
          <div className={OfferMessageTheme.container} style={{ height: 'auto' }}>
            <H4>
              <i>
                {`Budget: ${RequestMessage.formatBudget(budget_min, budget_max)}`}
              </i>
            </H4>

            <div className={`${OfferMessageTheme.statsGroup}`}>
              <div className={`${OfferMessageTheme.displayFlex}`}>
                <img src="images/clock-icon@2x.png" className={OfferMessageTheme.icon} />
                <div className={OfferMessageTheme.costDateText}>
                  <Small>
                    {
                      start_date
                        ? `${moment(start_date).format('MMM D, YYYY')} - ${moment(end_date).format('MMM D, YYYY')}`
                        : 'N/A'
                    }
                    {is_date_flexible && ', Flexible Dates'}
                  </Small>
                </div>
              </div>
            </div>
            {
              dealTerms.length > 0
                && (
                  <div className={classNames(OfferMessageTheme.displayFlex, OfferMessageTheme.statsGroup)}>
                    {
                      dealTerms.map(term => (
                        <div key={term} className={classNames(OfferMessageTheme.displayFlex, OfferMessageTheme.term)}>
                          <img
                            src="images/dollar-bill@2x.png"
                            className={classNames(OfferMessageTheme.icon, OfferMessageTheme.iconSmallMargin)}
                          />
                          <Small>{term}</Small>
                        </div>
                      ))
                    }
                  </div>
                )
            }
            <div className={OfferMessageTheme.statsGroup}>
              <Small><em>Audience</em></Small>
              <div className={OfferMessageTheme.stats}>
                <Small>{brand_description || 'N/A'}</Small>
              </div>
            </div>
            
            <div className={classNames(OfferMessageTheme.displayFlex, OfferMessageTheme.statsGroup)}>
              <div style={{ marginRight: '20px' }}>
                <Small><em>Conversion Rate</em></Small>
                <div className={OfferMessageTheme.stats}>
                  <Small>{conversion_rate ? `${(conversion_rate * 1)}%` : 'N/A'}</Small>
                </div>
              </div>
              <div>
                <Small><em>Conversion Value</em></Small>
                <div className={OfferMessageTheme.stats}>
                  <Small>{conversion_value ? d3.format('$,')(conversion_value) : 'N/A'}</Small>
                </div>
              </div>
            </div>

            <div className={OfferMessageTheme.statsGroup}>
              <Small><em>Interested In</em></Small>
              <div className={OfferMessageTheme.stats}>
                <Small>
                  {mediaInterests.length === 1 && mediaInterests[0]}
                  {mediaInterests.length === 0 && 'N/A'}
                  {mediaInterests.slice(0, -1).join(', ')}
                  {mediaInterests.length > 2 ? ', ' : ' '}
                  {mediaInterests.length > 1 ? `or ${mediaInterests.slice(-1)}` : ''}
                </Small>
              </div>
            </div>
          </div>
          <div className={OfferMessageTheme.verticalSeparator} />
          {deal_status === 'request' && this.getFooter(themes, expiration_date)}
        </Card>

      </div>
    )
  }
}

export default RequestMessage
