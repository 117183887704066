import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import useWindowResize from 'util/hooks/useWindowResize'

import { P } from 'visual-components/util/texts'
import Dropdown from 'react-toolbox/lib/dropdown'
import DropDownTheme from 'css/themes/dateRangeSortWithDisplay.css'

const arrowDisabled = '/images/icons/arrow-left-disabled.svg'
const arrow = '/images/icons/arrow-left-azure.svg'

const styles = {
  footer: {
    height: 64,
    padding: 20,
  },
  row: {
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 32,
    '@media only screen and (max-width:360px)': {
      display: 'none',
    },
    '& [data-react-toolbox="input"]': {
      width: 40,
      '&::after': {
        marginTop: 10,
        right: -18,
      },
    },
  },
  pointer: { cursor: 'pointer' },
  arrow: {
    '&.right': {
      marginLeft: 24,
      transform: 'rotate(180deg)',
    },
  },
  countOf: {
    display: 'flex',
    alignItems: 'center',
    margin: 16,
  },
}

const SORT_OPTIONS = [5, 10, 15, 20, 25]

const formatSortOptions = options => options.map(value => ({ value, label: `${value}` }))

function TableFooter(props) {
  const {
    rowsPerPage,
    currentPage,
    totalCount,
    pages,
    onNext,
    onPrev,
    onSetRowsNumber,
    classes: css,
    config,
  } = props

  useWindowResize() // Allows rerendering when resized

  const sortOptions = formatSortOptions(config.sortOptions || SORT_OPTIONS)

  // Always when less than 6?
  if (totalCount < 6) return null

  if (!pages) return null

  const [canLeft, canRight] = [currentPage > 0, pages - 1 !== currentPage]
  const leftArrow = (
    <img
      alt="left arrow"
      src={canLeft ? arrow : arrowDisabled}
      onClick={canLeft ? onPrev : null}
      className={cn(css.arrow, { [css.pointer]: canLeft })}
    />
  )
  const rightArrow = (
    <img
      alt="right arrow"
      src={canRight ? arrow : arrowDisabled}
      onClick={canRight ? onNext : null}
      className={cn(css.arrow, { [css.pointer]: canRight }, 'right')}
    />
  )

  const maxItem = Math.min((currentPage + 1) * rowsPerPage, totalCount)
  const minItem = Math.max(currentPage * rowsPerPage + 1, 0)

  const countOf = (
    <div className={css.countOf}>
      <P>
        <strong>{`${minItem} - ${maxItem}`}</strong>
        &nbsp;of&nbsp;
        <strong>{totalCount}</strong>
      </P>
    </div>
  )

  return (
    <tfoot>
      <tr className={css.footer}>
        <td colSpan={1000}>
          <div className={css.row}>
            <div className={css.dropdown}>
              {/* This part is different from FutureApp */}
              <P><em>Rows per page:</em></P>
              <Dropdown
                auto
                theme={DropDownTheme}
                onChange={onSetRowsNumber}
                source={sortOptions}
                value={rowsPerPage}
              />
              {/* Up to here */}
            </div>
            {countOf}
            {rowsPerPage < totalCount && (
              <>
                {leftArrow}
                {rightArrow}
              </>
            )}
          </div>
        </td>
      </tr>
    </tfoot>
  )
}

TableFooter.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,

  pages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSetRowsNumber: PropTypes.func.isRequired,
}

export default React.memo(injectCSS(styles)(TableFooter))
