import React, { Component } from 'react'
import Axios from 'axios'
import {
  string,
  object,
  func,
  bool,
} from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Checkbox } from 'react-toolbox'

import Spinner from 'util/components/spinner'
import FullscreenModal from 'visual-components/util/modals/FullscreenModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import * as MetricsActions from 'actions/analytics-actions'
import * as TOUActions from 'actions/terms-of-use-actions'
import * as DealActions from 'actions/deal-actions'

import CheckboxTheme from 'css/themes/Checkbox.css'
import MediaMarketTOUTheme from 'css/themes/mediamarket-tou.css'

const stateToProps = ({ currentBrand }) => ({ currentBrand })

const dispatchToProps = dispatch => ({
  touActions: bindActionCreators(TOUActions, dispatch),
  dealActions: bindActionCreators(DealActions, dispatch),
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

const classes = {
  touContainer: MediaMarketTOUTheme.touContainer,
  header: MediaMarketTOUTheme.header,
  imgWrapper: MediaMarketTOUTheme.imgWrapper,
  brandImg: MediaMarketTOUTheme.brandImg,
  title: MediaMarketTOUTheme.title,
  text: MediaMarketTOUTheme.text,
  content: MediaMarketTOUTheme.contentTou,
  agreementItems: MediaMarketTOUTheme.agreementItems,
  lastContent: MediaMarketTOUTheme.lastContentTou,
}

const replaceClasses = html => {
  Object.keys(classes).forEach(key => {
    html = html.replace(new RegExp(key, 'g'), classes[key])
  })

  return html
}

class MediaMarketAcceptTOU extends Component {
  static propTypes = {
    metricsActions: object.isRequired,
    currentBrand: object.isRequired,
    dealActions: object.isRequired,
    touActions: object.isRequired,
    onBtnClick: func.isRequired,
    history: object.isRequired,
    which: string.isRequired,
    isPreview: bool,
    confirmType: string,
  }

  static defaultProps = {
    isPreview: false,
    confirmType: null,
  }

  static contextTypes = {
    impersonating: string,
  }

  state = {
    loading: true,
    content: null,
    checked: false,
  }

  componentDidMount() {
    const { which, confirmType, metricsActions } = this.props

    this.setState({ loading: true }, async () => {
      if (confirmType === 'create') {
        metricsActions.create('brandViewedMMToS', {
          meta: {
            from: 'Brand Created Offer',
            type: 'publisher',
          },
        })
      }

      let content = await this.fetchTOU(which)

      content = replaceClasses(content)

      this.setState({ loading: false, content })
    })
  }

  componentDidUpdate() {
    const {
      which,
      onBtnClick,
      currentBrand,
      isPreview,
    } = this.props

    const sellerHasAccepted = currentBrand && currentBrand.has_accepted_media_market_seller_latest_terms && which === 'publisher'
    const buyerHasAccepted = currentBrand && currentBrand.has_accepted_media_market_buyer_latest_terms && which === 'advertiser'

    if ((sellerHasAccepted || buyerHasAccepted) && !isPreview) onBtnClick()
  }

  fetchTOU = async which => {
    const { currentBrand: { id: brand } } = this.props
    const { impersonating } = this.context

    try {
      const { data } = await Axios({
        url: `/terms-of-use/media/${which}${impersonating ? `?impersonating=${impersonating}` : ''}`,
        method: 'GET',
        headers: { brand },
      })

      return data
    } catch (err) {
      console.log({ err })
    }

    return null
  }

  onCheck = () => this.setState(({ checked }) => ({ checked: !checked }))

  onSave = async () => {
    const {
      which,
      touActions,
    } = this.props

    touActions.accept(which)

    return this.setState({ loading: true })
  }

  onClose = (declined = false) => {
    const {
      history,
      dealActions: { clearDeal },
      isPreview,
      onBtnClick,
      touActions,
      which,
    } = this.props

    if (declined) touActions.accept(which, false)
    if (isPreview) return onBtnClick()

    clearDeal()

    return history.push('/partnerships')
  }

  render() {
    const { which, isPreview } = this.props
    const {
      loading,
      content,
      checked,
    } = this.state

    const title = `${which[0].toUpperCase()}${which.slice(1)}`

    return (
      <FullscreenModal title={`${isPreview ? '' : 'Review'} ${title} Agreement`} close={this.onClose} expand>
        {
          loading
            ? <Spinner />
            : (
              <div className={MediaMarketTOUTheme.modal}>
                {
                  !isPreview
                    && (
                      <H4 multiline>
                        To continue with this deal, you must agree to the terms of DojoMojo Media Market.
                      </H4>
                    )
                }
                <div
                  className={`${MediaMarketTOUTheme.wrapper} ${isPreview ? MediaMarketTOUTheme.preview : ''}`}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                {
                  !isPreview
                    && (
                      <React.Fragment>
                        <div style={{ marginLeft: '88px' }}>
                          <Checkbox
                            label="By checking this box and clicking “Agree,” I acknowledge that I have read and agree to these terms."
                            className={`${CheckboxTheme.boldLabel} ${CheckboxTheme.form}`}
                            checked={checked}
                            onChange={this.onCheck}
                            theme={CheckboxTheme}
                          />
                        </div>
                        <ModalButtonGroup
                          confirm={this.onSave}
                          cancel={() => this.onClose(true)}
                          hideLine
                          canSave={checked}
                          confirmText="Agree"
                          cancelText="Disagree"
                        />
                      </React.Fragment>
                    )
                }
              </div>
            )
        }
      </FullscreenModal>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(MediaMarketAcceptTOU))
