import React from 'react';
import * as ReactRedux from 'react-redux';
import moment from 'moment-timezone';

import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text';
import PrimaryButton from 'util/components/buttons/primary-button';

import CampaignDispatcher from 'dispatchers/campaign-dispatcher';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher';
import { P } from 'visual-components/util/texts';
import TermsWarnModal from './TermsWarnModal'

import { bindActionCreators } from 'redux'
import * as DialogActions from 'actions/dialog-actions'
 const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
})


class TermsDashboard extends React.Component{
  constructor(props) {
    super(props);

    this.getStarted = this.getStarted.bind(this);
  }

  componentDidMount() {
    if (this.props.currentCampaign.hasStarted && this.props.currentCampaign.hasStarted()) {
      this.props.dialogActions.openDialogWithPayload('rulesPublishedWarning', { })
    }
  }

  getStarted() {
    var campaignTerms = this.props.currentCampaign.campaignTerms;

    let locationMap = {
      neverCreated: 'create',
      drafted: 'edit',
      generated: 'edit',
      published: 'edit',
      needsPartnerInfo: 'info'
    }

    if (!campaignTerms) {
      return;
    }

    let loc = locationMap[campaignTerms.termsStatus] || 'edit';
    let url = `/builder/${this.props.currentCampaign.id}/terms/${loc}`;

    BrowserHistoryDispatcher.push(url)
  }

  render() {
    var campaignTerms = this.props.currentCampaign.campaignTerms || {};

    var status = campaignTerms.termsStatus;

    var statusMap = {
      generated: <p style={{ color: COLOURS.azure, fontFamily: 'Larsseit', fontSize: '14px', lineHeight: 'normal' }}>{ `Generated On ${moment(campaignTerms.termsGeneratedAt).format('LL')}` }</p>,
      drafted: <p style={{ color: COLOURS.elephantGrey, fontFamily: 'Larsseit', fontSize: '14px', lineHeight: 'normal' }}> Drafted </p>,
      published: <p style={{ color: COLOURS.azure, fontFamily: 'Larsseit', fontSize: '14px', lineHeight: 'normal' }}>{ `Published On ${moment(campaignTerms.termsPublishedAt).format('LL')}` }</p>,
      neverCreated: <p style={{ color: COLOURS.elephantGrey, fontFamily: 'Larsseit', fontSize: '14px', lineHeight: 'normal' }}> Never Created </p>,
      needsPartnerInfo: <p style={{ color: COLOURS.alertRed, fontFamily: 'Larsseit', fontSize: '14px', lineHeight: 'normal' }}> Needs To Update Your Partner Info </p>,
    }

    var statusComponent = statusMap[status];

    var paras = campaignTerms.createYourOwn ? [
      <P multiline>
        This is the Custom Official Rules Generator. Since you have opted to use your Custom Official Rules, we will not send you any additional alerts once you have published your Rules.
      </P>,
      <P multiline>
        Should you wish to use the DojoMojo’s Official Rules Template, you can go to the Create Rules step and opt to use our Template.
      </P>
    ] : [
      <P multiline>
        This is DojoMojo’s Official Rules Generator. You’ll need to create your Official Rules document before you can begin your campaign.
      </P>,
      <P multiline>
        If you need to update your Official Rules, we will remind you by email to edit your Rules 5 days before your campaign start date. If you haven’t updated them 24 hours before your campaign starts, we will delay your campaign start date.
      </P>,
      <P multiline>
        You can always check the status of your Rules here and see if any updates are required as you add new partners.
      </P>

    ];

    return(
      <div>
        <TabTitleAndText
          headers={[]}
          paras={ paras }
        />

        <p>
          { `Official Rules Status: ` }
        </p>
        { statusComponent }

        <br />
        <br />

        <PrimaryButton onClick={ this.getStarted }
          blueButton={ true }
        >
          {
            status == 'neverCreated' ?
            'Get Started'
            : 'Edit'
          }
        </PrimaryButton>
        <TermsWarnModal />
      </div>
    )
  }
}

export default ReactRedux.connect(null, mapDispatchToProps)(TermsDashboard);
