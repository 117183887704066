const initialState = {
  images: [],
  stockImages: [],
  recentStockImages: [],
  searchQuery: '',
  searchPage: 1,
  selectedImage: null,
}

export default function backgroundImageReducer(state = initialState, action) {
  switch (action.type) {
    case 'BACKGROUND_IMAGE_UPDATE_ATTR':
      return { ...state, ...action.data }

    case 'BACKGROUND_IMAGE_LOADING':
      return { ...state, loading: true }

    case 'BACKGROUND_IMAGE_DOWNLOADING':
      return { ...state, loading: true, downloading: true }

    case 'BACKGROUND_IMAGE_SEARCHING': {
      if (action.page && action.page === 1) {
        return { ...state, loading: true }
      }

      return { ...state, searching: true }
    }

    case 'BACKGROUND_IMAGE_FINISHED':
      return {
        ...state,
        loading: false,
        searching: false,
        downloading: false,
      }

    case 'BACKGROUND_IMAGE_CLEAR':
      return { ...state, stockImages: [] }

    case 'BACKGROUND_IMAGE_UPLOAD':
      return {
        ...state,
        loading: false,
        images: [action.data, ...state.images],
        selectedImage: action.data,
      }

    case 'BACKGROUND_IMAGE_STOCK_UPLOAD':
      return {
        ...state,
        loading: false,
        recentStockImages: [action.data, ...state.recentStockImages],
        selectedImage: action.data,
      }

    case 'BACKGROUND_IMAGE_SUCCESS':
      return {
        ...state,
        loading: false,
        images: action.payload.uploaded,
        recentStockImages: action.payload.stock,
      }

    case 'BACKGROUND_IMAGE_SEARCH_SUCCESS': {
      const stockImages = action.page === 1
        ? action.payload.data
        : [...state.stockImages, ...action.payload.data]

      return {
        ...state,
        loading: false,
        searching: false,
        stockImages,
        searchPage: state.searchPage + 1,
        skipUnsplash: action.payload.skipUnsplash,
        skipPixabay: action.payload.skipPixabay,
      }
    }

    case 'BACKGROUND_IMAGE_FAILURE': {
      return {
        ...state,
        loading: false,
        downloading: false,
        error: action.data.response ? `${action.data.response.status}: ${action.data.response.data}` : 'Error',
      }
    }

    default:
      return state
  }
}
