import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BuilderHeader from 'components/campaigns/builder/builder-header'
import Basics from 'campaigns/builder/basics/basics'
import Spinner from 'util/components/spinner'
import Validator from 'util/form-validator'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher'
import { getClaim } from 'actions/brand-claim-actions'

const mapState = ({ currentBrand, currentCampaign, brandClaims }) =>
  ({ currentBrand, currentCampaign, brandClaims })

class NewSoloSweepstakesContainer extends React.Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    currentCampaign: PropTypes.object.isRequired,
    brandClaims: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentWillMount() {
    store.dispatch({
      type: 'LOAD_EMPTY',
      model: 'currentCampaign',
    })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.brandClaims.loading && prevProps.brandClaims.loading) {
      const canCreateSoloSweepstakes = getClaim('sweepstakes.solo.create')
      if (!canCreateSoloSweepstakes) {
        window.location.href = '/'
      }
    }
  }

  save = destination => {
    const { currentCampaign } = this.props
    const validations = {
      name: [Validator.required, Validator.maxLength(85, 'Please limit to 85 characters')],
      startDate: [Validator.required],
      endDate: [Validator.required],
      prizingDescription: [Validator.required, Validator.maxLength(375, 'Please limit to 375 characters')],
      prizingValue: [Validator.required],
    }

    Validator.validateForm(validations, currentCampaign, 'campaignSetupForm', valid => {
      if (valid) {
        CampaignDispatcher.createCampaignRequest({
          ...currentCampaign,
          isSolo: true,
        }, destination)
      }
    })
  }

  nextSave = () => {
    this.save('select-template')
  }

  exit = () => {
    const { history } = this.props
    history.back()
  }

  render() {
    const { currentCampaign } = this.props

    if (currentCampaign.loading) return <Spinner />

    return (
      <div>
        <BuilderHeader
          {...this.props}
          isSolo
          next={this.nextSave}
          exitClick={this.exit}
        />
        <Basics
          isHost
          isSolo
          campaign={currentCampaign}
          updateAttr={CampaignDispatcher.updateAttr}
        />
      </div>
    )
  }
}

export default connect(mapState)(NewSoloSweepstakesContainer)
