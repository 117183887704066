import React from 'react'
import inputThemes from 'css/themes/Input.css'
import { Input } from 'visual-components/util/form'

const OpportunityDescribe = props => (
  <div>
    <Input
      required
      name="headline"
      label="Draw attention with a headline:"
      placeholder="Food startup with 50K reach seeking content swap partner"
      characterLimit={75}
    />
    <Input
      required
      name="details"
      label="Details"
      placeholder={ props.placeholder }
      multiline
      characterLimit={700}
      className={inputThemes.textarea}
    />
  </div>
)

export default OpportunityDescribe
