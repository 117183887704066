import React from 'react'
import { Helmet } from 'react-helmet'
import COLOURS from 'util/colours'
import PublicCTAHeader from './public-cta-header'

import {
  Switch,
  Route,
} from 'login/visual-components/decorated-router-components'
import styles from 'css/themes/homePage/home.css'

const makePublic = Component => props => <Component {...props} isPublic />

import BrandProfileContainer from 'container-components/brands/BrandProfileContainer'
const PublicBrandProfileContainer = makePublic(BrandProfileContainer)
import OpportunityProfileContainer from 'visual-components/opportunityProfile/OpportunityProfile'
const PublicOpportunityProfileContainer = makePublic(OpportunityProfileContainer)

import Header from 'login/visual-components/header/Header'

export default function({ forceMobile }) {
  const { menu } = styles
  return (
    <div className={menu}>
      <Helmet>
        <meta name="viewport" content="" />
        <meta name="apple-mobile-web-app-capable" content="no" />
      </Helmet>
      <div style={{
        background: COLOURS.cerulean,
        minWidth: '1280px',
        width: '100%'
      }}>
        <Header />
      </div>
      <PublicCTAHeader />
      <div style={{
        background: COLOURS.cream,
        minWidth: '1280px',
        width: '100%'
      }}>
        <Switch forceMobile={forceMobile}>
          <Route path="/brand/:brandId" component={PublicBrandProfileContainer} />
          <Route
            path="/opportunities/:type/:id"
            component={PublicOpportunityProfileContainer}
          />
        </Switch>
      </div>
    </div>
  )
}
