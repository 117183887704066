import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'util/components/spinner'

import Dialog from 'react-toolbox/lib/dialog'
import { H4 } from 'visual-components/util/texts'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import * as ReactRedux from 'react-redux'

import dialogTheme from 'css/themes/dialogs/dialogBase.css'

const mapState = state => ({
  confirmationModal: state.confirmationModal,
})

class ConfirmationModal extends Component {
  static propTypes = {
    confirmationModal: PropTypes.object.isRequired,
    children: PropTypes.arrayOf(PropTypes.node),
  }

  static defaultProps = {
    children: [],
  }

  componentWillUnmount() {
    $('body').removeClass('modal-open')
  }

  closeModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'confirmationModal',
      data: {
        expand: false,
      },
    })
  }

  render() {
    const {
      confirmationModal,
    } = this.props

    const {
      expand,
      header,
      copy,
      action,
      children,
      confirmText,
      cancelText = 'Cancel',
    } = confirmationModal

    if (confirmationModal.loading) {
      return (
        <Spinner />
      )
    }

    return (
      <div>
        <Dialog
          theme={dialogTheme}
          active={expand || false}
          onEscKeyDown={this.closeModal}
          onOverlayClick={this.closeModal}
          title={header}
        >
          {
            copy
              ? (
                <H4 multiline>
                  {copy}
                </H4>
              ) : children
          }
          <ModalButtonGroup
            hideLine
            canSave
            confirm={action}
            cancel={this.closeModal}
            cancelText={cancelText}
            confirmText={confirmText}
          />
        </Dialog>
      </div>
    )
  }
}

export default ReactRedux.connect(mapState)(ConfirmationModal)
