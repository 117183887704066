import moment from 'moment-timezone'
import _ from 'lodash'

const initialState = {
  rid: 'emailRelease',
  loading: false,
  numberToRelease: 0,
  releases: [],
  modalOpen: false,
  step: 'selectNumber'
}

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_EMAIL_RELEASE_ATTR':
      return _.merge({}, state, action.data)

    case 'LOADING_RELEASES':
      return {  ...state, loading: true, modalOpen: true, ...action.data }

    case 'LOAD_RELEASES_SUCCESS':
    // <LabelValue label="Total Entries Driven" value="value" marginRight={33} />
    // <LabelValue label="Partner Entries Driven" value="value" marginRight={33} />
    // <LabelValue label="Minimum Threshold" value="value" marginRight={33} />
    // <LabelValue label="Percentage Reached" value="value" marginRight={0} />
    //
      let partnerEntriesDriven

      let minimumDelivery = state.partnershipInvite.minimumDelivery

      const totalEntriesDriven = action.payload.delivery.reduce((memo, item) => {
        let entries = parseInt(item.totalentries)
        if (item.brand_id === state.partnershipInvite.invitee_id) {
          partnerEntriesDriven = entries
        }
        return memo + entries
      },0)

      const totalAlreadyReleased = action.payload.releases.reduce((memo, item) => {
        return memo + item.number_released
      }, 0)

      return Object.assign({}, state, action.payload, {
        loading: false,
        partnerEntriesDriven,
        totalEntriesDriven,
        minimumDelivery,
        numberToRelease: totalAlreadyReleased,
        emailsDelivered: state.partnershipInvite.emailsDelivered,
        percentagReached: partnerEntriesDriven / minimumDelivery,
        suggestion: parseInt(partnerEntriesDriven / minimumDelivery * totalEntriesDriven),
        totalAlreadyReleased: totalAlreadyReleased
      })

    case 'LOAD_RELEASES_FAILURE':
      toastr.error('Could Not Load Releases', null, { timeOut: 2000 })
      return { ...state, loading: false, modalOpen: false }

    case 'CONFIRM_RELEASE_SEND':
      return { ...state, step: 'confirmation' }

    case 'SENDING_RELEASE':
      return {  ...state, loading: true }

    case 'CREATE_RELEASE_SUCCESS':
      return { ...state, step: 'success', loading: false }

    case 'CREATE_RELEASE_FAILURE':
      toastr.error('Could not Release Emails', null, { timeOut: 2000 })
      return { ...state, loading: false }

    case 'CLOSE_RELEASE_MODAL':
      return { ...state, modalOpen: false, step: 'selectNumber' }

    default:
      return state
  }
}
