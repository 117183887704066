import { useState, useEffect } from 'react'
import Axios from 'axios'

import requestHeaders from 'request-config'

// https://innovationdepartment.atlassian.net/wiki/spaces/DOJO/pages/470745096/useHasIntegrations
const useHasIntegration = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [hasIntegrations, setHasIntegrations] = useState(false)
  const [integrations, setIntegrations] = useState({})

  const reload = () => {
    const axios = Axios.create()
    axios.defaults.headers = requestHeaders()

    setIntegrations({})
    setHasIntegrations(false)
    setIsLoading(true)
    setIsError(false)
    axios
      .get('/data-integrations')
      .then(res => {
        const activeIntegrations = {}
        Object.keys(res.data).forEach(integration => {
          if (integration === 'pixel') {
            if (res.data[integration]) {
              activeIntegrations[integration] = res.data[integration]
            }
          } else if (res.data[integration].isIntegrated) {
            activeIntegrations[integration] = res.data[integration]
          }
        })
        setHasIntegrations(Object.keys(activeIntegrations).length > 0)
        setIntegrations(activeIntegrations)
      })
      .catch(() => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    reload()
  }, [])

  return {
    isLoading,
    isError,
    hasIntegrations,
    integrations,
    reload,
  }
}

export default useHasIntegration
