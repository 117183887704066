import React from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import {
  object, bool, func, string,
} from 'prop-types'
import PartnerListItem from 'components/campaigns/builder/partners/partner-list-item'
import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import Tooltip from 'util/components/tooltip'
import TableTheme from 'css/themes/partnersTable.css'
import EmailReleaseFormModal from './email-release-form-modal'
import ReportPartnerModal from './report-partner-modal'
import RemovePartnerDialog from './remove-partner-dialog'
import ReinvitePartnerConfirmationModal from './reinvite-partner-confirmation-modal'

class PartnersList extends React.PureComponent {
  static propTypes = {
    currentCampaign: object.isRequired,
    currentBrand: object.isRequired,
    isHost: bool,
    typeToShow: string,
    partnerActions: object.isRequired,
    openDialog: func.isRequired,
    history: object.isRequired,
  }

  static defaultProps = {
    typeToShow: 'accepted',
    isHost: false,
  }

  state = {
    showReportModal: false,
    showRemoveModal: false,
    partnerId: null,
    partnerName: null,
    application: null,
    inviteeBrand: null,
  }

  reportPartner = (type, details) => {
    const { currentCampaign, partnerActions, openDialog } = this.props
    const { partnerName } = this.state

    partnerActions.reportPartner(currentCampaign.id, partnerName, type, details)
    this.closeModal()
    openDialog('reportDialog')
  }

  displayReportModal = partner => {
    this.setState({
      showReportModal: true,
      partnerId: partner.id,
      partnerName: partner.invitee.accountname,
    })
  }

  removePartner = () => {
    const { currentCampaign } = this.props
    const { partnerId } = this.state

    // remove partner by Id
    CampaignDispatcher.rejectInvite(currentCampaign.id, partnerId)
    this.closeModal()
  }

  displayRemovalModal = (partnerId, application) => {
    this.setState({
      showRemoveModal: true,
      partnerId,
      application,
    })
  }

  closeModal = () => {
    this.setState({
      showReportModal: false,
      showRemoveModal: false,
      partnerId: null,
      partnerName: null,
      application: null,
      inviteeBrand: null,
    })
  }

  acceptApplication = inviteId => {
    const { history } = this.props
    history.push(`/messaging/received-applications/${inviteId}`)
  }

  rejectApplication = () => {
    const { currentCampaign } = this.props
    const { partnerId } = this.state

    CampaignDispatcher.rejectApplication(currentCampaign.id, partnerId)

    this.setState({
      showRemoveModal: false,
      partnerId: null,
    })
  }

  setInviteeBrand = invitee => this.setState({ inviteeBrand: invitee })

  // For Pending re-invitation
  setBrandOpenConfirmationModal = async invite => {
    const { dialogActions } = this.props
    await this.setInviteeBrand(invite.invitee)
    dialogActions.openDialog('reinviteConfirmationModal')
  }


  // For Rejected re-invitation
  setBrandOpenInviteMessenger = async invitee => {
    await this.setInviteeBrand(invitee)
    this.openReinvitationMessenger('rejected')
  }


  openReinvitationMessenger = reinviteType => {
    const { currentBrand, dialogActions, reinviteConfirmModalActive, partnerActions, profile, campaignForMessage } = this.props

    if (reinviteConfirmModalActive) dialogActions.closeDialog('reinviteConfirmationModal')

    const { inviteeBrand } = this.state
    partnerActions.setBrandForInvite(inviteeBrand)

    const data = { campaign: campaignForMessage[0], currentBrand, inviteeBrand, profile, reinviteType }
    partnerActions.openReinvitationMessenger(data)
  }

  render() {
    const {
      isHost,
      currentCampaign,
      currentBrand,
      typeToShow,
      reinviteConfirmModalActive,
      dialogActions,
    } = this.props

    const {
      partnerId,
      partnerName,
      application,
      showReportModal,
      showRemoveModal,
      inviteeBrand
    } = this.state


    currentCampaign.invites = _.sortBy(currentCampaign.invites, 'createdAt')
    const invites = _.map(currentCampaign.invites, (invite, idx) => {
      let detailedStatus
      const isHostInvite = currentCampaign.hostBrand.id === invite.invitee.id
      const userIsHost = currentBrand.id === currentCampaign.hostBrand.id
      const isRejected = invite.status === 'rejected'

      if (invite.status === 'pending') {
        detailedStatus = invite.application ? 'pendingApplication' : 'pendingInvite'
      } else {
        detailedStatus = invite.status
      }

      invite.showRemove = !isHostInvite && !currentCampaign.started && !isRejected && userIsHost

      // Don't show partners that are not in the current category of status
      return detailedStatus !== typeToShow ? null : (
        <PartnerListItem
          key={idx}
          isHost={currentCampaign.isHost}
          isHostInvite={isHostInvite}
          campaign={currentCampaign}
          campaignId={currentCampaign.id}
          invite={invite}
          invites={currentCampaign.invites}
          displayReportModal={this.displayReportModal}
          displayRemovalModal={this.displayRemovalModal}
          acceptApplication={this.acceptApplication}
          typeToShow={typeToShow}
          setBrandOpenConfirmationModal={this.setBrandOpenConfirmationModal}
          setBrandOpenInviteMessenger={this.setBrandOpenInviteMessenger}
          showReinvite={invite.pending_reinvites < 2}
        />
      )
    })

    const tootipContent = 'Set the number of entries a brand must deliver from their audience before they will have access to new emails generated by the giveaway.'

    return (
      <div>
        <RemovePartnerDialog
          active={showRemoveModal}
          removePartner={application ? this.rejectApplication : this.removePartner}
          closeModal={this.closeModal}
        />
        <ReportPartnerModal
          active={showReportModal}
          partnerId={partnerId}
          partnerName={partnerName}
          reportPartner={this.reportPartner}
          closeModal={this.closeModal}
        />
        <ReinvitePartnerConfirmationModal
          active={reinviteConfirmModalActive}
          openReinvitationMessenger={this.openReinvitationMessenger}
          closeModal={() => dialogActions.closeDialog('reinviteConfirmationModal')}
          partnerName={inviteeBrand && inviteeBrand.accountname}
          campaignName={currentCampaign.name}
        />
        <br />
        <br />
        <table className={TableTheme.table}>
          <thead>
            <tr>
              <th className={TableTheme.firstTd} />
              <th className={TableTheme.secondTd}>
                Brand Name
              </th>
              <th className={TableTheme.thirdTd}>
                List Size
              </th>
              <th className={TableTheme.fourthTd}>
                Est. Entries per Campaign
              </th>
              { isHost
                ? (
                  <th className={TableTheme.fifthTd}>
                    { typeToShow === 'accepted' ? 'Min. Entries Required' : 'Status' }
                    { typeToShow === 'accepted'
                      ? (
                        <Tooltip
                          html
                          title={tootipContent}
                          styles={{
                            marginTop: '-4px',
                            marginLeft: '4px',
                          }}
                        />
                      ) : null
                    }
                  </th>
                ) : null
              }
              { isHost && typeToShow === 'accepted'
                ? (
                  <th className={TableTheme.sixthTd}>
                    Entries Contributed (%)
                    <Tooltip
                      html
                      title={"The percentage represents the partner's progress towards their minimum entry requirement. This number will appear green once the minimum is met."}
                      styles={{
                        marginTop: '-4px',
                        marginLeft: '4px',
                      }}
                    />
                  </th>
                ) : null
              }
              { isHost
                ? (
                  <th className={TableTheme.fifthTd}>
                    {!['accepted', 'interested'].includes(typeToShow) ? 'Actions' : ''}
                  </th>
                ) : null
              }
            </tr>
          </thead>
          <tbody>
            { invites }
          </tbody>
        </table>
        <EmailReleaseFormModal />
      </div>
    )
  }
}

export default withRouter(PartnersList)
