import React from 'react'
import _ from 'lodash'

import Field from 'util/components/field'
import SelectField from 'util/components/select-field'
import Validator from 'util/form-validator'
import Countries from 'util/countries'
import { H2, H5 } from 'visual-components/util/texts'

let countries = _.map(Countries, function (item) {
  return item.name;
})

countries.unshift('United States')


class TermsInfoHost extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div style={{
              marginBottom: '20px',
              display: 'block'
            }}>
              <H2>
                { this.props.host.accountname }
              </H2>
            </div>

            <Field
              validations={ [Validator.required] }
              attrName="legalName"
              label={ `${this.props.host.accountname}'s legal entity name *` }
              labelStyles={{ fontSize: '16px', fontFamily: 'Larsseit-Medium', marginBottom: '16px' }}
              placeholder={ `Company Name, Inc.` }
              id={ this.props.host.id }
              hideToastr={ true }
              dataModel={ this.props.campaignTermsDetails }
              formModel={ this.props.campaignTermsDetails }
              endpoint={ `/terms/partner-info/${this.props.host.id}` }
            />
            <Field
              validations={ [Validator.required] }
              attrName="privacyPolicy"
              label={ `${this.props.host.accountname}'s privacy policy *` }
              labelStyles={{ fontSize: '16px', fontFamily: 'Larsseit-Medium', marginBottom: '16px' }}
              tooltip={ `A link to the general privacy policy on ${this.props.host.accountname}'s website` }
              placeholder={ `www.companyname.com/privacypolicy` }
              id={ this.props.host.id }
              hideToastr={ true }
              dataModel={ this.props.campaignTermsDetails }
              formModel={ this.props.campaignTermsDetails }
              endpoint={ `/terms/partner-info/${this.props.host.id}` }
            />

            <div>
              <div className="row">
                <div className="col-sm-12">
                  <div style={{
                    display: 'block',
                    marginBottom: '16px'
                  }}>
                    <H5>
                      Your Legal Address
                    </H5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <Field
                    validations={ [Validator.required] }
                    attrName="address1"
                    label={ `Address 1 *` }
                    placeholder={ `1111 Street Name` }
                    id={ this.props.host.id }
                    hideToastr={ true }
                    dataModel={ this.props.campaignTermsDetails }
                    formModel={ this.props.campaignTermsDetails }
                    endpoint={ `/terms/partner-info/${this.props.host.id}` }
                  />
                </div>
                <div className="col-sm-4">
                  <Field
                    attrName="address2"
                    label={ `Address 2` }
                    placeholder={ `Apt, Suite, Unit #` }
                    id={ this.props.host.id }
                    hideToastr={ true }
                    dataModel={ this.props.campaignTermsDetails }
                    formModel={ this.props.campaignTermsDetails }
                    endpoint={ `/terms/partner-info/${this.props.host.id}` }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <Field
                    validations={ [Validator.required] }
                    attrName="city"
                    label={ `City *` }
                    placeholder={ `City` }
                    id={ this.props.host.id }
                    hideToastr={ true }
                    dataModel={ this.props.campaignTermsDetails }
                    formModel={ this.props.campaignTermsDetails }
                    endpoint={ `/terms/partner-info/${this.props.host.id}` }
                  />
                </div>
                <div className="col-sm-4">
                  <Field
                    validations={ [Validator.required] }
                    attrName="state"
                    label={ `State *` }
                    placeholder={ `State` }
                    id={ this.props.host.id }
                    hideToastr={ true }
                    dataModel={ this.props.campaignTermsDetails }
                    formModel={ this.props.campaignTermsDetails }
                    endpoint={ `/terms/partner-info/${this.props.host.id}` }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  <Field
                    validations={ [Validator.required] }
                    attrName="zip"
                    label={ `Zip Code *` }
                    placeholder={ `Zip` }
                    id={ this.props.host.id }
                    hideToastr={ true }
                    dataModel={ this.props.campaignTermsDetails }
                    formModel={ this.props.campaignTermsDetails }
                    endpoint={ `/terms/partner-info/${this.props.host.id}` }
                  />
                </div>
                <div className="col-sm-9">
                  <SelectField
                    validations={ [Validator.required] }
                    attrName="country"
                    options={ countries }
                    label={ `Country *` }
                    placeholder={ `Please Select` }
                    id={ this.props.host.id }
                    hideToastr={ true }
                    dataModel={ this.props.campaignTermsDetails }
                    formModel={ this.props.campaignTermsDetails }
                    endpoint={ `/terms/partner-info/${this.props.host.id}` }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TermsInfoHost
