import React from 'react';
import AdminRow from './admin-row';
import moment from 'moment-timezone';
import _ from 'lodash';

class HouseBrands extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const headerStyle = {
      borderTop: '1px solid',
      borderBottom: '3px solid'
    };

    const totalStyle = {
      borderTop: '1px solid',

    }

    var idMap = {
      3: 'Wine Awesomeness',
      52: 'WellPath',
      65: 'Rebloom',
      469: 'Captain Robot',
      468: 'Pawz and Play',
      467: 'Fashion Rules Apply',
      305: 'Wanderfull'
    };

    let startOfInterval = this.props.admin.interval == 'week' ? 'isoweek' : this.props.admin.interval;

    var data = _.map(this.props.admin.presentation.totalEmailsAcquireds, item => {
      item.date_trunc = item.date_trunc.split('T')[0];
      return item;
    })

    var brandsMap = _.groupBy(data, 'brand_id');

    var brandRows = _.map(brandsMap, (values, id) => {
      var sums = [];

      _.each(this.props.dates, function (date) {
        var value = _.find(values, { date_trunc: moment(date).subtract(1, 'week').format('YYYY-MM-DD') })
        if (value) {
          sums.push(d3.format(',')(value.sum));
        } else {
          sums.push(0);
        }
      })
      return <AdminRow title={idMap[id]} vals={sums} key={id} />
    })


    return (
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped table-hover table-responsive">
            <AdminRow className="info" vals={this.props.dates} style={headerStyle} />
            <tbody>
              <tr className="info"><td colSpan="5"><h4>New Emails Gained</h4></td></tr>
              {brandRows}            
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}


export default HouseBrands;
