import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Smaller } from 'visual-components/util/texts'
import cn from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const styles = {
  container: {
    borderTop: '1px solid #dee4e5',
    marginTop: 14,
    padding: '23.5px 32px',
  },
  platformToggle: {
    borderRadius: 10,
    border: '1px solid #dee4e5',
    display: 'flex',
    overflow: 'hidden',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 64,
    width: 129,
    cursor: 'default',
    textTransform: 'uppercase',
    transition: 'background .2s',
    //paths of the svg icon
    '& g': {
      transition: 'fill .2s',
    },
    '& small': {
      transition: 'color .2s, font-weight .2s',
      color: '#b7c3cd',
    },
    '&:first-child': {
      borderRight: '1px solid #dee4e5',
    },
  },
  active: {
    background: '#03a9f4',
    '& g': {
      fill: '#fff',
    },
    '& small': {
      color: '#fff',
      fontFamily: 'Larsseit-Bold',
      fontWeight: 'bold',
    },
  },
  icon: {
    height: 26,
    marginBottom: 10,
  },
}

const desktopIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
    <defs>
      <path id="a" d="M23.5.333h-21A2.34 2.34 0 0 0 .167 2.667v14A2.34 2.34 0 0 0 2.5 19h8.167v2.333H8.333v2.334h9.334v-2.334h-2.334V19H23.5a2.34 2.34 0 0 0 2.333-2.333v-14A2.34 2.34 0 0 0 23.5.333zm0 16.334h-21v-14h21v14z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="#B7C3CD" mask="url(#b)">
        <path d="M-1-2h27.222v27.222H-1z"/>
      </g>
    </g>
  </svg>
)

const mobileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewBox="0 0 16 26">
    <g fill="#B7C3CD" fillRule="nonzero">
      <path d="M12.444 25.556H3.111C1.333 25.556 0 24.24 0 22.489V3.067C0 1.314 1.333 0 3.111 0h9.333c1.778 0 3.112 1.314 3.112 3.067v19.422c0 1.752-1.482 3.067-3.112 3.067zM3.111 2.222c-.444 0-.889.437-.889.874V22.46c0 .437.445.873.89.873h9.332c.445 0 .89-.436.89-.873V3.096c0-.437-.445-.874-.89-.874H3.111z"/>
      <circle cx="7.778" cy="21.111" r="1.111" />
    </g>
  </svg>
)

const PlatformToggle = props => {
  const {
    classes: css,
    onShowDesktop,
    onShowMobile,
    isDesktopView,
  } = props

  const renderOption = (icon, text, onClick) => (
    <div
      className={cn({
        [css.option]: true,
        [css.active]: (isDesktopView && text === 'desktop') || (!isDesktopView && text === 'mobile'),
      })}
      onClick={onClick}
    >
      <div className={css.icon}>
        {icon()}
      </div>
      <Smaller>
        {text}
      </Smaller>
    </div>
  )

  return (
    <div className={css.container}>
      <div className={css.platformToggle}>
        {renderOption(desktopIcon, 'desktop', onShowDesktop)}
        {renderOption(mobileIcon, 'mobile', onShowMobile)}
      </div>
    </div>
  )
}

PlatformToggle.propTypes = {
  classes: propTypes.object.isRequired,
  onShowDesktop: propTypes.func.isRequired,
  onShowMobile: propTypes.func.isRequired,
  isDesktopView: propTypes.bool.isRequired,
}

const onShowDesktop = isDesktopView => ({
  type: 'UPDATE_ATTR',
  data: {
    viewDesktop: isDesktopView,
  },
  model: 'currentCampaign',
})

const mapDispatchToProps = dispatch => ({
  onShowDesktop: bindActionCreators(() => onShowDesktop(true), dispatch),
  onShowMobile: bindActionCreators(() => onShowDesktop(false), dispatch),
})
export default connect(() => {}, mapDispatchToProps)(injectSheet(styles)(PlatformToggle))
