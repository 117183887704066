import React from 'react'
import { H4 } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import Badge from 'visual-components/util/badge'
import statusMap from 'util/deal-status-map'

const DealTitleAndBadge = props => {
  const {
    isLive,
    label,
    status,
  } = props

  return (
    <Row style={{ marginBottom: '8px', alignItems: 'center' }}>
      <H4 overflowEllipsis white={isLive && status === 'live'}><em>{label}</em></H4>
      <div style={{ position: 'absolute', right: '12px' }}>
        <Badge
          label={statusMap[status].label}
          color={statusMap[status].color}
        />
      </div>
    </Row>
  )
}

export default DealTitleAndBadge
