import React from 'react';
import PropTypes from 'prop-types';

import Col from 'visual-components/util/no-padding-grid/col';
import { P, Smaller } from 'visual-components/util/texts';

import { makeNumbersPretty } from 'util/makeNumbersPretty';

class InfoRowContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      label,
      data,
      exploreMediaMarketResults,
    } = this.props;

    var displayedNumber = makeNumbersPretty(data);

    return(
      <div style={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        paddingRight: exploreMediaMarketResults ? '16px' : '12px',
        paddingLeft: exploreMediaMarketResults ? '16px' : '12px'
      }}>
        <Col xs={9}>
          <Smaller>
            { label }
          </Smaller>
        </Col>
        <Col xs={3}
          style={{
            textAlign: 'right',
            height: '12px',
        }}>
          <P>
            <i>
              { displayedNumber }
            </i>
          </P>
        </Col>
      </div>
    )
  }
}

InfoRowContent.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.number
}

export default InfoRowContent;
