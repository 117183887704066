
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { bindActionCreators } from 'redux'
import * as CampaignTaskActions from 'actions/campaign-task-actions'
import Calendar from 'visual-components/calendar/Calendar'

const mapStateToProps = state => ({
  campaignTasks: state.campaignTasks,
  currentBrand: state.currentBrand,
  profile: state.profile,
})

const mapDispatchToProps = dispatch => ({
  campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch),
})

class BrandCalendar extends Component {
  static propTypes = {
    profile: object.isRequired,
    currentBrand: object.isRequired,
    campaignTaskActions: object.isRequired,
    campaignTasks: object.isRequired,
  }

  exportCalendar = () => {
    const {
      campaignTaskActions: {
        exportCalendar,
      },
      profile,
      currentBrand,
    } = this.props

    return exportCalendar(
      `${window.location.origin}/calendar/brand-tasks/${currentBrand.uid}/${profile.uid}`,
      currentBrand.accountname
    )
  }

  render() {
    const {
      campaignTasks: {
        brandTasks,
        allCampaigns,
      },
      campaignTaskActions: {
        selectBrandCampaign,
        selectTask,
        showMore,
        saveTask,
      },
    } = this.props

    const tasks = brandTasks.filter(task => !task.hide)
    const campaigns = allCampaigns.filter(campaign => !campaign.hideTasks)

    return (
      <Calendar
        tasks={tasks}
        exportCalendar={this.exportCalendar}
        campaigns={campaigns}
        onNavigate={date => {
          // placeholder for when we do date based loading
        }}
        onCampaignSelect={selectBrandCampaign}
        onTaskSelect={selectTask}
        updateTaskDueDate={saveTask}
        onShowMore={showMore}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandCalendar)
