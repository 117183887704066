import AppDispatcher from 'dispatchers/app-dispatcher';
import _ from 'lodash'

const PromotionalUnitDispatcher = {
  updateAttrPersist: (data, callback) => {
    let { id, campaign_id } = data;
    let cleanData = _.pick(data, ['impressionsPer', 'promotionaltype', 'id', 'launchDate', 'label'] )
    cleanData.brand_id = Number(data.brand)
    AppDispatcher.http({
      url: `/promotional-units/${campaign_id}/${id}`,
      method: 'PUT',
      data: cleanData,
      success: (unit) => {
        store.dispatch({
          model: 'promotionalUnits',
          type: 'UPDATE_UNIT',
          data: unit
        })
        callback();
      }
    })
  },
  create: (data, campaignId, callback) => {
    let { campaign_id } = data;
    let cleanData = _.pick(data, ['impressionsPer', 'promotionaltype', 'launchDate', 'label'] )
    cleanData.brand = Number(data.brand)
    AppDispatcher.http({
      url: `/promotional-units/${campaignId}`,
      method: 'POST',
      data: cleanData,
      success: (unit) => {
        store.dispatch({
          model: 'promotionalUnits',
          type: 'ADD_UNIT',
          data: unit
        });
        store.dispatch({
          model: 'currentCampaigns',
          type: 'ADD_PROMO_UNIT',
          data: {
            impressions: unit.impressionsPer,
            campaign_id: unit.campaign_id
          }
        })
        store.dispatch({
          model: 'upcomingCampaigns',
          type: 'ADD_PROMO_UNIT',
          data: {
            impressions: unit.impressionsPer,
            campaign_id: unit.campaign_id
          }
        })
        callback();
      }
    })
  },
  remove: (unit, campaignId, callback) => {
    store.dispatch({
      model: 'promotionalUnits',
      type: 'UPDATE_ATTR',
      data: {
        loading: true
      }
    });
    AppDispatcher.http({
      url: `/promotional-units/${campaignId}/${unit.id}`,
      method: 'DELETE',
      success: function () {
        store.dispatch({
          model: 'promotionalUnits',
          type: 'REMOVE_UNIT',
          data: {
            id: unit.id
          }
        });
        store.dispatch({
          model: 'promotionalUnits',
          type: 'UPDATE_ATTR',
          data: {
            loading: false
          }
        });
        store.dispatch({
          model: 'currentCampaigns',
          type: 'REMOVE_PROMO_UNIT',
          data: {
            impressions: unit.impressionsPer,
            campaign_id: campaignId
          }
        })
        store.dispatch({
          model: 'upcomingCampaigns',
          type: 'REMOVE_PROMO_UNIT',
          data: {
            impressions: unit.impressionsPer,
            campaign_id: campaignId
          }
        })
        store.dispatch({
          model: 'promotionalUnit',
          type: 'CLOSE_REMOVE_MODAL'
        })
        callback()
      },
      error: function (err) {
        store.dispatch({
          model: 'promotionalUnits',
          type: 'UPDATE_ATTR',
          data: {
            loading: false
          }
        });
        toastr.error(err.responseJSON.message);
        callback();
      }
    })
  }
}

export default PromotionalUnitDispatcher;
