import { format } from 'd3'
import { memoize } from 'lodash'

const _isPositive = value => value && +value > 0
const _isPositiveOrZero = value => value && +value >= 0
const _isInteger = value => Math.round(+value) === +value

export const isPositive = value => {
  if (!_isPositive(value)) {
    throw 'Must be positive'
  }
}

export const isPositiveOrZero = value => {
  if (!_isPositiveOrZero(value)) {
    throw 'Must be positive or zero'
  }
}

export const isPositiveInteger = value => {
  if (!_isPositive(value) || !_isInteger(value)) {
    throw 'Must be positive integer'
  }
}

const _defCannotExceedError = max => `Cannot exceed ${format(',')(max)}`

export const cannotExceed = (max, error = _defCannotExceedError) => value => {
  if (+value > max) {
    throw error(max, value)
  }
}

export const asyncTest = (value, model) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      value > 50 ? rej('Async cannot exceed 50') : res()
    }, 5000)
  })
}

export const isNum = value => {
  if (isNaN(value)) {
    throw 'Must be a number'
  }
}


const _defAtLeastError = min => `Must be at least ${d3.format(',')(min)}`

export const atLeast = (min, error = _defAtLeastError) => value => {
  if (+value < min) {
    throw error(min, value)
  }
}

const allowedSymbols = ['%', '$']
export const isInRange = memoize(options => {
  const {
    required = false,
    minValue = 0,
    maxValue = null,
    allowSymbols = true, // ignore symbols like $ and %
  } = options

  //get error messages
  const {
    isRequired: requiredError = 'This field is required',
    outOfBounds: outOfBoundsError = maxValue
      ? `Value must be between ${d3.format(',2f')(minValue)} and ${d3.format(',2f')(maxValue)}`
      : `Value must be at least ${d3.format(',2f')(minValue)}`,
    notANumber: notANumberError = 'Value must be a number',
  } = options.errors || {}

  return num => {

    if (num === '' || num === null || typeof num === 'undefined') {
      if (!required) return
      if (required) throw requiredError
    }

    if (allowSymbols) {
      // fillter out allowed symbols
      num = Number(String(num).split('').filter(char => allowedSymbols.indexOf(char) === -1).join(''))
    }

    if (isNum(num)) {
      throw notANumberError
    }
    if (num < minValue || (maxValue && num > maxValue)) {
      throw outOfBoundsError
    }
  }
}, settings => JSON.stringify(settings))
