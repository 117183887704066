import React, { PureComponent } from 'react'
import _ from 'lodash'

import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import BrandTab from 'visual-components/brandProfile/brand-tab'

class BrandTabs extends PureComponent {
  render() {
    const { tabOptions, location } = this.props

    const [currentTab] = location.pathname.split('/').slice(-1)

    const tabs = _.map(tabOptions, (option, idx) => (
      <BrandTab key={idx} tab={option} active={option.name === currentTab} />
    ))

    return <div style={{ height: 36 }}>{tabs}</div>
  }
}

BrandTabs.propTypes = {
  tabOptions: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}

export default withRouter(BrandTabs)
