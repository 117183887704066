import React, { Component } from 'react'
import SocialButtons from './social-buttons'
import { Button, Input } from 'react-toolbox'
import DefaultCard from 'visual-components/util/cards/default-card'
import ChipInput from 'visual-components/util/inputs/chip-input'
import { H2, H3, H6, P, Micro } from 'visual-components/util/texts'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import theme from 'css/themes/referrals/ReferralDashboard.css'
import ButtonTheme from 'css/themes/Buttons.css'

class GiveAndGet extends Component {
  state = { copied: false, queueSendInvites: false };

  handleCopied = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 2000);
    })
  }

  sendInvites = () => {
    const { emails, sendInvites } = this.props;
    if (emails.length > 0) {
      sendInvites(emails);
    } else if (this.chipInput.input.value) {
      this.setState({ queueSendInvites: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.emails.length > 0 && this.state.queueSendInvites) {
      nextProps.sendInvites(nextProps.emails);
      this.setState({ queueSendInvites: false });
    }
  }

  render() {
    let {
      emails,
      handleAddEmail,
      handleRemoveEmail,
      sendInvites,
      inviteUrl,
      copiedNotify,
      startGmailImport,
      brandName
    } = this.props

    return(
      <DefaultCard title="Give and Get $25" className={theme.inviteCard}>
        <Row style={{ marginBottom: '8px' }}>
          <Col xs={9}>
            <ChipInput
              list={emails}
              ref={el => this.chipInput = el}
              onItemAdded={handleAddEmail}
              onItemDeleted={handleRemoveEmail}
              inputProps={{
                placeholder: 'Enter email addresses',
              }}
            />
          </Col>
          <Col
            xs
            className={theme.sendInvitesBtn}
          >
            <Button
              onClick={this.sendInvites}
              label={'Send Invites'}
              primary
              theme={ButtonTheme}
              className={ButtonTheme.blueButton}
              raised
            />
          </Col>
        </Row>
          <Micro>
            Import Contacts:{' '}
            <a
              onClick={startGmailImport}
              style={{ color: 'inherit' }}
              href="#gmail-import"
            >
              <img
                className={theme.gmailLogo}
                src="images/icons/gmail/logo-gmail-128-px.png"
                srcSet="images/icons/gmail/logo-gmail-128-px@2x.png 2x, images/icons/gmail/logo-gmail-128-px@3x.png 3x"
              />
              {' Gmail'}
            </a>
          </Micro>
        <Row style={{ marginTop: '24px', marginBottom: '8px' }}>
          <Col xs={9}>
            <input
              type="text"
              readOnly
              value={`${inviteUrl}-02`}
              className={theme.copyInput}
            />
          </Col>
          <Col
            xs
            className={theme.copyLinkBtn}
          >
            <Button
              label={this.state.copied ? 'Copied!' : 'Copy Link'}
              primary
              onClick={this.handleCopied}
              theme={ButtonTheme}
              className={`${ButtonTheme.greenButton} copy-invite-link`}
              data-clipboard-text={`${inviteUrl}-02`}
              raised
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <SocialButtons
              brandName={brandName}
              inviteUrl={inviteUrl}
            />
          </Col>
        </Row>
      </DefaultCard>
    )
  }
}

export default GiveAndGet
