import React from 'react';
import Field from 'util/components/field';
import Label from 'util/components/label';
import COLOURS from 'util/colours';
import Text from 'util/components/text';
import inputTheme from 'css/themes/Input.css';

class PasswordField extends Field {
  state={
    isPasswordVisible: false
  }

  toggleFocus = () => {
    let newFocused = !this.props.registerForm.passwordFocused;
    store.dispatch({
      model: 'registerForm',
      data: {
        passwordFocused: newFocused
      },
      type: 'UPDATE_ATTR'
    });
  }

  key = (e) => {
    var value = e.target.value;

    var hasErrors = false;
    var state = {
      value: value
    };

    if (value.length >= 8) {
      state.hasLength = true;
    } else {
      hasErrors = true;
    }

    if (value.toLowerCase() !== value) {
      state.hasUpperCase = true;
    } else {
      hasErrors = true;
    }

    var hasDigit = new RegExp(/\d/);

    if (value.match(hasDigit)) {
      state.hasDigit = true;
    } else {
      hasErrors = true;
    }

    var special = new RegExp(/(?=.*[!\?@#$&*+()^%])/);

    if (value.match(special)) {
      state.hasSpecial = true;
    } else {
      hasErrors = true;
    }

    state.hasErrors = hasErrors;
    store.dispatch({
      model: 'register',
      type: 'UPDATE_ATTR',
      data: {
        password: value
      }
    });

    store.dispatch({
      model: 'registerForm',
      type: 'UPDATE_ATTR',
      data: {
        errors: {
          password: state
        }
      }
    });
  }

  render() {

    var registerForm = this.props.registerForm;

    var passwordFormData = registerForm.errors.password || {};

    const inputStyle = {
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '17px',

      width: '100%',
      height: '50px',

      paddingLeft: '14px',
      backgroundColor: 'transparent',
      color: COLOURS.textGrey,
      fontFamily: 'GothamRnd-Book',
      fontSize: '15px',

      outline: 'none',
      boxShadow: 'none',
      WebkitBoxShadow: 'none',

      borderRadius: '1px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: passwordFormData.hasErrors && !registerForm.passwordFocused ? COLOURS.alertRed : COLOURS.silver
    };

    let imageStyles = {
      width: '30px'
    }

    let checkboxStyles = {
      width: '12px',
      marginRight: '8px'
    }

    return(
      <div style={{
        textAlign: 'left'
      }}>
        <Label label={ 'Password *' } />
        { this.state.isPasswordVisible ?
          <input
            type='text'
            onKeyUp={ this.key }
            value={ this.state.password }
            style={ inputStyle }
            onFocus={ this.toggleFocus }
            onBlur={ this.toggleFocus }
            className={ 'borderAzureFocus' }
           />
        :
           <input
            type='password'
            onKeyUp={ this.key }
            value={ this.state.password }
            style={ inputStyle }
            onFocus={ this.toggleFocus }
            onBlur={ this.toggleFocus }
            className={ 'borderAzureFocus' }
           />
        }

        {/* eye icon */}
        <div
          onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
          style={{
            marginTop: '-50px',
            marginLeft: 'calc(100% - 50px)',
            lineHeight: '50px'
        }}>
          { this.state.isPasswordVisible ?
            <img
              src={ registerForm.passwordFocused ?
                "/images/icons/hide-password-icon.svg"
                :
                "/images/icons/hide-password-icon-grey.svg"
              }
              style={ imageStyles }
             />
            :
            <img
              src={ registerForm.passwordFocused ?
                "/images/icons/view-password-icon.svg"
                :
                "/images/icons/view-password-icon-grey.svg"
              }
              style={ imageStyles }
             />
          }
        </div>

        {/* error state */}
        <div style={{
          height: '16px'
        }}>
          { passwordFormData.hasErrors && !registerForm.passwordFocused ?
            <Text.dojoLabel styles={[{
              fontSize: '9px',
              color: COLOURS.alertRed,
              fontFamily: 'Larsseit-Bold',
              fontWeight: 'inherit'
            }]}>
              Password does not meet requirements.
            </Text.dojoLabel>
            :
            null
          }
        </div>

        {/* error checklist */}
        <div style={{
          position: 'relative'
        }}>
          { registerForm.passwordFocused ?
            <div className={ inputTheme['Password-mobile-query'] }>
              <div className="row">
                <div className="col-xs-12">
                  <div style={{
                    paddingBottom: '4px'
                  }}>
                    <Text.dojoP
                      styles={[{
                        fontSize: '10px',
                        color: COLOURS.charcoal,
                        fontFamily: 'GothamRnd-Medium',
                        lineHeight: '10px'
                    }]}>
                      Passwords should include the following:
                    </Text.dojoP>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6 col-md-12">
                  <div style={{
                    paddingBottom: '4px'
                  }}>
                    { passwordFormData.hasLength ?
                      <img src="/images/util/blue-box-with-check.svg"
                        style={ checkboxStyles }
                      />
                      :
                      <img src="/images/util/grey-box.svg"
                        style={ checkboxStyles }
                      />
                    }
                    <Text.dojoP
                      styles={[{
                        fontSize: '10px',
                        color: COLOURS.charcoal,
                        lineHeight: '10px'
                    }]}>
                      Minimum 8 characters
                    </Text.dojoP>
                  </div>
                  <div style={{
                    paddingBottom: '4px'
                  }}>
                    { passwordFormData.hasUpperCase ?
                      <img src="/images/util/blue-box-with-check.svg"
                        style={ checkboxStyles }
                      />
                      :
                      <img src="/images/util/grey-box.svg"
                        style={ checkboxStyles }
                      />
                    }
                    <Text.dojoP
                      styles={[{
                        fontSize: '10px',
                        color: COLOURS.charcoal,
                        lineHeight: '10px'
                    }]}>
                      An uppercase character
                    </Text.dojoP>
                  </div>
                </div>
                <div className="col-xs-6 col-md-12">
                  <div style={{
                    paddingBottom: '4px'
                  }}>
                    { passwordFormData.hasDigit ?
                      <img src="/images/util/blue-box-with-check.svg"
                        style={ checkboxStyles }
                      />
                      :
                      <img src="/images/util/grey-box.svg"
                        style={ checkboxStyles }
                      />
                    }
                    <Text.dojoP
                      styles={[{
                        fontSize: '10px',
                        color: COLOURS.charcoal,
                        lineHeight: '10px'
                    }]}>
                      A digit
                    </Text.dojoP>
                  </div>
                  <div style={{
                    paddingBottom: '4px'
                  }}>
                    { passwordFormData.hasSpecial ?
                      <img src="/images/util/blue-box-with-check.svg"
                        style={ checkboxStyles }
                      />
                      :
                      <img src="/images/util/grey-box.svg"
                        style={ checkboxStyles }
                      />
                    }
                    <Text.dojoP
                      styles={[{
                        fontSize: '10px',
                        color: COLOURS.charcoal,
                        lineHeight: '10px'
                    }]}>
                      A special character [!?@#$&*+]
                    </Text.dojoP>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
};

export default PasswordField
