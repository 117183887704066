import React, { Component } from 'react';
import EachOpening from './each-opening';
import theme from 'css/themes/mobile/home/careers/careers.css';
import axios from 'axios'

class CurrentOpenings extends Component {
  state = {
    openings: []
  }
  componentDidMount() {
    axios
      .get("https://api.lever.co/v0/postings/dojomojo?mode=json")
      .then(response => {
        this.setState({
          openings: response.data
        });
      });
  }
  render() {
    return(
      <div className={ theme.careersPageContent }>
        <p className={ `${theme.sectionHeader} ${theme.secondSection}` }>
          Current Openings
        </p>
        <p className={ `${theme.sectionExplain} ${theme.beforeOpenings}` }>
          We’re always looking to meet interesting people. Don’t see a role here that’s perfect for you? Drop us a line at <a href="mailto:careers@dojomojo.com">careers@dojomojo.com</a> and let us know how you can help.
        </p>
        { this.state.openings.map(job => (
          <EachOpening
            key={job.id}
            title={job.text}
            href={`${job.hostedUrl}?lever-origin=applied&lever-source%5B%5D=DojoWebsite`} />
        ))}
      </div>
    )
  }
}

export default CurrentOpenings;
