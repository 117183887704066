import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import AnalyticsTransactionCard from 'visual-components/mediaMarket/analytics/AnalyticsTransactionCard'
import AnalyticsDealGraph from 'visual-components/mediaMarket/analytics/AnalyticsDealGraph'
import SpecificDealOverviewBar from 'visual-components/analytics/util/specific-deal-overview-bar'
import RadioButton from 'visual-components/util/buttons/RadioButton'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'
import Spinner from 'util/components/spinner'
import * as DealActions from 'actions/deal-actions'
import * as DealIntegrationActions from 'actions/deal-integrations-actions'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

const mapStateToProps = ({
  deals,
  dealIntegrations,
  currentBrand,
  mediaMarketDealGraph,
  dealAnalytics,
}) =>
  ({
    deals,
    dealIntegrations,
    currentBrand,
    mediaMarketDealGraph,
    dealAnalytics,
  })

const mapDispatchToProps = dispatch => ({
  dealActions: bindActionCreators(DealActions, dispatch),
  dealIntegrationActions: bindActionCreators(DealIntegrationActions, dispatch),
})

class DealAnalytics extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    deals: object.isRequired,
    dealIntegrations: object.isRequired,
    mediaMarketDealGraph: object.isRequired,
    dealActions: object.isRequired,
    dealIntegrationActions: object.isRequired,
    dealAnalytics: object.isRequired,
  }

  componentDidMount() {
    const {
      deals: {
        deal,
      },
      dealActions: {
        getDealESPAnalyticsById,
        getDealPixelAnalyticsById,
        getDealClickAnalyticsById,
      },
      dealIntegrationActions: {
        loadIntegrations,
      },
    } = this.props

    const integrationType = 'mailchimp'
    loadIntegrations(deal.id)
    getDealClickAnalyticsById(deal)
    getDealPixelAnalyticsById(deal.id)
    getDealESPAnalyticsById(deal.id, integrationType)
  }

  componentWillUnmount() {
    const {
      dealActions: {
        resetAnalytics,
      },
    } = this.props

    resetAnalytics()
  }


  render() {
    const {
      deals: {
        deal,
        pixelSetupActive,
        pixelConfirmationActive,
      },
      dealIntegrations: { dealIntegrations },
      dealActions,
      mediaMarketDealGraph,
      currentBrand,
      dealAnalytics,
    } = this.props

    const {
      espOpenRate,
      espClickRate,
      totalValue,
      dealEmailDojoClicks,
      loadingEspClicks,
      loadingDojoEmailClicks,
      loadingDojoPixelClicks,
    } = dealAnalytics

    if (!deal || !Object.keys(deal).length
      || loadingEspClicks || loadingDojoEmailClicks || loadingDojoPixelClicks) {
      return <Spinner />
    }

    const isEmailDeal = ['dedicated_email', 'sponsored_email'].includes(deal && deal.type)
    const isFixedDeal = deal && deal.cost_type === 'Fixed'
    const isCPCDeal = deal && deal.cost_type === 'CPC'
    const isBuyer = (currentBrand && currentBrand.id) === (deal && deal.buying_brand_id)
    const selectedTab = mediaMarketDealGraph && mediaMarketDealGraph.selectedTab
    const showingClickData = selectedTab && selectedTab.includes('Click')

    const isIntegrated = dealIntegrations && dealIntegrations.find(integration =>
      integration.details && Object.keys(integration.details).length)

    const transactionData = {
      isBuyer,
      isFixedDeal,
      isEmailDeal,
      isIntegrated,
      showingClickData,
      isCPCDeal,
      deal,
      currentBrand,
      pixelSetupActive,
      pixelConfirmationActive,
    }

    return (
      <div>
        <Grid>
          <Row style={{ marginTop: '20px' }}>
            <AnalyticsTransactionCard data={transactionData} dealValue={totalValue} />
            <div id="overview-bars">
              <SpecificDealOverviewBar
                title="Deal Metrics"
                position="left"
                marginBottom="16px"
                metrics={[
                  {
                    label: 'Total Value',
                    value: totalValue || 0,
                  },
                  {
                    label: 'Clicks Accrued',
                    value: dealEmailDojoClicks,
                  },
                  {
                    label: 'Open Rate (ESP)',
                    value: isNaN(espOpenRate) ? '-' : `${makeNumbersPretty(espOpenRate, 'withDecimal')}%`,
                  },
                  {
                    label: 'CTR (ESP)',
                    value: isNaN(espClickRate) ? '-' : `${makeNumbersPretty(espClickRate, 'withDecimal')}%`,
                  },
                ]}
              />
              <SpecificDealOverviewBar
                title="Seller Audience"
                position="right"
                metrics={[
                  {
                    label: 'Email List Size',
                    value: makeNumbersPretty(deal.selling_brand.dedicatedListSize),
                  },
                  {
                    label: 'Facebook Followers',
                    value: makeNumbersPretty(deal.selling_brand.social.facebookFollowers),
                  },
                  {
                    label: 'Instagram Followers',
                    value: makeNumbersPretty(deal.selling_brand.social.instagramFollowers),
                  },
                  {
                    label: 'Twitter Followers',
                    value: makeNumbersPretty(deal.selling_brand.social.twitterFollowers),
                  },
                ]}
              />
            </div>
          </Row>

          <Row style={{ marginTop: '32px' }}>
            <div style={{ marginRight: '8px' }}>
              <RadioButton
                leftLabel="Total"
                rightLabel="Trend"
                leftOnClick={dealActions.toggleCumulative}
                rightOnClick={dealActions.toggleCumulative}
              />
            </div>
            <div style={{ marginTop: '5px' }}>
              <BlueInfoTooltip text="View metrics change over time by selecting “Trend” or look at the aggregation by selecting “Total”" />
            </div>
          </Row>

          <Row style={{ marginTop: '16px' }}>
            <AnalyticsDealGraph
              dealActions={dealActions}
              data={transactionData}
              dealAnalytics={dealAnalytics}
            />
          </Row>
        </Grid>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealAnalytics))
