import React, { Component } from 'react'
import { array, bool, number } from 'prop-types'
import DocumentSender from './assetVisuals/DocumentSender'
import DocumentReceiver from './assetVisuals/DocumentReceiver'
import SingleImage from './assetVisuals/SingleImage'
import MultipleImage from './assetVisuals/MultipleImage'
import { string } from 'prop-types'

class AssetVisualProcessor extends Component {
  static contextTypes = {
    impersonating: string,
  }

  static propTypes = {
    assets: array,
    images: array,
    isSender: bool.isRequired,
    currentBrandId: number.isRequired,
  }

  static defaultProps = {
    assets: [],
    images: [],
  }
  

  downloadAssetFunc = asset => {
    const { currentBrandId } = this.props
    const { impersonating } = this.context
    window.open(`/deals/attachment/${asset.deal_id}/${currentBrandId}/${asset.id}/download/${asset.name}.${asset.type}${impersonating ? `?impersonating=${impersonating}` : ''}`)
  }

  downloadMultipleAssetFunc = assets => {
    const { currentBrandId } = this.props
    const { impersonating } = this.context
    const ids = assets.map(asset => asset.id).join(',')
    window.open(`/deals/attachment-zip/${assets[0].deal_id}/${currentBrandId}/download?ids=${ids}${impersonating ? `?impersonating=${impersonating}` : ''}`)
  }

  render() {
    const {
      assets,
      images,
      isSender,
    } = this.props

    let mapItems = []

    if (assets.length) {
      mapItems = assets.map(asset => {
        return isSender
          ? (
            <DocumentSender
              key={asset.id}
              asset={asset}
              downloadAssetFunc={() => this.downloadAssetFunc(asset)}
            />
          )
          : (
            <DocumentReceiver
              key={asset.id}
              asset={asset}
              downloadAssetFunc={() => this.downloadAssetFunc(asset)}
            />
          )
      })
    }

    if (images.length) {
      if (images.length < 4) {
        mapItems = images.map(image =>
          (
            <SingleImage
              key={image.id}
              asset={image}
              isSender={isSender}
              downloadAssetFunc={() => this.downloadAssetFunc(image)}
            />
          ))
      } else {
        mapItems = (
          <MultipleImage
            asset={images}
            isSender={isSender}
            downloadAssetFunc={() => this.downloadMultipleAssetFunc(images)}
          />
        )
      }
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        { mapItems }
      </div>
    )
  }
}

export default AssetVisualProcessor
