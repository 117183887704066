import Validator from 'util/validator';
import _ from 'lodash'

export default {
  updateAttr: function (model, data) {
    store.dispatch({
      model: model,
      data: data, // { valid: true/false, errors: { attrName: 'error' }}
      type: 'UPDATE_ATTR'
    });
  },
  validate: function (reduxId, data) {
    // tells store that validating has begun (is asynchronous)
    //store.dispatch({
    //  model: action.fieldModel,
    //  data: data, // { valid: true/false, errors: { attrName: 'error' }}
    //  type: 'VALIDATING'
    //});

    Validator.validateAttr(action, function (data) { // { valid: true/false,}
      store.dispatch({
        model: action.fieldModel,
        data: data, // { valid: true/false, errors: { attrName: 'error' }}
        type: 'UPDATE_ATTR'
      });
    });

    store.dispatch({
      model: action.dataModel,
      data: data, // { valid: true/false, errors: { attrName: 'error' }}
      type: 'UPDATE_ATTR'
    });
    // return _.extend({}, state, { [action.dataModel]: action.data });
  },
  http: function(action) {
    // return function () {
    $.ajax({
      url: action.url,
      method: action.method || 'GET',
      data: action.data,
      success: action.success,
      error: action.error
    });
    // }
  },
  persist: function (action) {
    store.dispatch({
      type: 'START_PERSIST',
      model: action.model.rid,
      attrName: action.attrName,
      data: action.data
    });

    this.http({
      method: 'PUT',
      data: action.data,
      url: action.endpoint,
      success: function (data) {
        store.dispatch({
          type: 'FINISH_PERSIST',
          model: action.model.rid,
          attrName: action.attrName
        });
        // store.dispatch({
        //   type: 'ADD_NOTIFICATION',
        //   model: 'notifications',
        //   data: {
        //     type: 'success',
        //     content: 'Saved'
        //   }
        // });
        if (!action.hideToastr) {
          toastr.remove(); // HOW TO NOT SHOW DUPLICATE TOASTR
          toastr.success('Changes saved successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        }
      },
      error: function (data) {
        store.dispatch({
          type: 'PERSIST_ERROR',
          model: action.model.rid,
          attrName: action.attrName,
          data: data
        });

        // store.dispatch({
        //   type: 'ADD_NOTIFICATION',
        //   model: 'notifications',
        //   data: {
        //     type: 'warning',
        //     content: 'Did not save'
        //   }
        // });

        // *********WARNING**********
        // DO NOT USE "hideToastrError" UNLESS YOU HAVE AN EXTREMELY GOOD REASON FOR HIDING TOASTR ERRORS TO THE USER
        // **************************
        if (!action.hideToastrError) {
          toastr.warning('Did not save!', null, { timeOut: 2000 });
        }

      }
    });
  }
}
