import React from 'react';
import * as ReactRedux from 'react-redux';

import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text';

import CampaignDispatcher from 'dispatchers/campaign-dispatcher';
import TermsDispatcher from 'dispatchers/terms-dispatcher';
import Editor from 'util/components/terms-editor';
import { P } from 'visual-components/util/texts';

class TermsEdit extends React.Component{
  constructor(props) {
    super(props);

    this.setup = this.setup.bind(this);
    this.save = this.save.bind(this);
  }

  setup(e) {
    e.contentCSS.push('/css/font/font.css');
    e.contentCSS.push('/web/admin.css');
  }

  save() {
    toastr.success('Saved!', null, { timeout: 2000, positionClass: 'toast-bottom-center' });
    TermsDispatcher.updateTerms({
      termsUnsaved: false,
      termsCustom: store.getState().currentCampaign.campaignTerms.termsFromCustomDraft
    });
  }

  updateAttr(attr, value) {
    store.dispatch({
      type: 'UPDATE_TERMS',
      model: 'currentCampaign',
      data: { termsFromCustomDraft: value, termsUnsaved: true }
    });
  }

  render() {
    var campaignTerms = this.props.currentCampaign.campaignTerms || {};

    var paras = [
      <P multiline>
        {
          `Enter your custom Official Rules text below.`
        }
      </P>
    ];

    return(
      <div>
        <TabTitleAndText
          headers={[]}
          paras={ paras }
        />
        <div style={{
          paddingTop: '20px'
        }}>
          <Editor
            { ...this.props }
            published={ campaignTerms.termsStatus === 'published' }
            model={ campaignTerms }
            updateAttr={ this.updateAttr }
            attr="termsFromTemplate"
            save={ this.save }
            height={ 350 }
            originalContent={ campaignTerms.termsCustom }
          />
        </div>
      </div>
    )
  }
}

export default TermsEdit;