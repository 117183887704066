import React, { PureComponent as Component } from 'react'
import { Small } from 'visual-components/util/texts'
import PropTypes from 'prop-types'

class BarGraphHeader extends Component {
  static propTypes = {
    live: PropTypes.bool,
    text: PropTypes.string.isRequired,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const {
      text,
      live,
    } = this.props

    return (
      <div style={{ paddingBottom: '8px' }}>
        <Small white={live}>
          <em>
            { text }
          </em>
        </Small>
      </div>
    )
  }
}

export default BarGraphHeader
