import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import InputTheme from 'css/themes/Input.css'
import ButtonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import { Input } from 'react-toolbox/lib/input'
import cn from 'classnames'

const THEMES = {
  input: InputTheme,
  button: ButtonTheme,
}

const styles = {
  noPadding: {
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
}

const SeedSms = props => {
  const {
    cardRowStyle,
    classes: css,
    isLoading,
    onSubmit: parentOnSeedSmsClick,
  } = props

  const [smsCampaignId, setSmsCampaignId] = useState('')
  const [subscriberAmount, setSubscriberAmount] = useState('')
  const [percentClick, setPercentClick] = useState('')
  const [percentError, setPercentError] = useState('')
  const [percentUnsubscribes, setPercentUnsubscribes] = useState('')
  const [messageContent, setMessageContent] = useState('')

  const onSeedSmsClick = async () => {
    await parentOnSeedSmsClick({
      smsCampaignId,
      subscriberAmount,
      percentClick,
      percentError,
      percentUnsubscribes,
      messageContent,
    })
  }

  return (
    <>
      <div className={cardRowStyle}>
        <Input
          value={smsCampaignId}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setSmsCampaignId}
          placeholder="SMS campaign ID"
        />
        <Input
          value={subscriberAmount}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setSubscriberAmount}
          placeholder="Amount of messages"
        />
        <Input
          value={percentClick}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setPercentClick}
          placeholder="Percent clicked"
        />
      </div>
      <div className={cardRowStyle}>
        <Input
          value={percentError}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setPercentError}
          placeholder="Error percent"
        />
        <Input
          value={percentUnsubscribes}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setPercentUnsubscribes}
          placeholder="Unsubscribe percent"
        />
      </div>
      <div className={cardRowStyle}>
        <Input
          value={messageContent}
          theme={THEMES.input}
          className={cn(css.noPadding, css.fullWidth)}
          onChange={setMessageContent}
          placeholder="Message content"
        />
      </div>
      <div className={cardRowStyle}>
        <Button
          onClick={onSeedSmsClick}
          theme={THEMES.button}
          className={THEMES.button.blueButton}
          primary
          raised
          disabled={
            isLoading
            || !smsCampaignId
            || !subscriberAmount
            || !percentClick
            || !percentError
            || !percentUnsubscribes
          }
          label="Create messages"
        />
      </div>
    </>
  )
}

SeedSms.propTypes = {
  cardRowStyle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(SeedSms)
