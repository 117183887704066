import React from 'react';
import ReactDOM from 'react-dom';
import Radium from 'radium';
import COLOURS from 'util/colours';

var base = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      paddingBottom: '3px'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);

    return (
      <span className={this.props.className} style={ styles } onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});


/* xl    lg    med   sm     xs   micro */
/* 37 => 28 => 15 => 13 =>  10 => 9 */

/* Full Point8 Point6 Point4 Point2 */
/* use the same wording as our COLOURS file => full is default*/

/* book - bold => book is default */

/* font-family => size => colour */

/* HOVER contains info about it's hover state */


// !!!!!!!!
// UPDATE JAN 9th 2017: using new text-colour: #384645
// !!!!!!!!

var basicStyles = {
  h1: {
    fontSize: '37px',
    lineHeight: '57px'
  },
  h2: {
    fontSize: '24px',
    lineHeight: '36px',
    spacing: '7px'
  },
  h3: {
    fontSize: '18px',
    lineHeight: '30px',
    spacing: '6px'
  },
  p: {
    fontSize: '16px',
    lineHeight: '24px',
    spacing: '5px'
  },
  finePrint: {
    fontSize: '14px',
    lineHeight: '20px',
    spacing: '4px'
  },
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    spacing: '3px'
  }
}


/* h1 */
var dojoH1 = Radium(/* h1 */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.h1.fontSize,
      fontFamily: 'Larsseit-Medium',
      lineHeight: basicStyles.h1.lineHeight,
      color: COLOURS.textGrey,
      display: 'inline',
    }],
    styles: []
  };

  render() {
    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, isWhite);
    // ^ please please try to avoid overwriting any of the defaults

    return(
      <h1 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h1>
    );
  }
});


var dojoH1Book = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.h1.fontSize,
      fontFamily: 'Larsseit-Light',
      lineHeight: basicStyles.h1.lineHeight,
      color: COLOURS.textGrey,
      display: 'inline',
    }],
    styles: []
  };

  render() {
    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, isWhite);
    // ^ please please try to avoid overwriting any of the defaults

    return(
      <h1 style={ styles }>
        { this.props.children }
      </h1>
    );
  }
});


/* h2 */
var dojoH2 = Radium(/* h2 */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.h2.fontSize,
      lineHeight: basicStyles.h2.lineHeight,
      fontFamily: 'Larsseit',
      color: COLOURS.textGrey,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.h2.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.h2.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);
    // ^ please please try to avoid overwriting any of the defaults

    return(
      <h2 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h2>
    );
  }
});

var dojoH2Bold = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.h2.fontSize,
      lineHeight: basicStyles.h2.lineHeight,
      fontFamily: 'Larsseit-Medium',
      color: COLOURS.textGrey,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.h2.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.h2.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);
 
    return(
      <h2 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h2>
    );
  }
});

var dojoH2Hover = Radium(class extends React.Component {
  /* !!!!
    only used on the new pricing page... please don't propogate! 
  !!! */

  static defaultProps = {
    baseStyles: [{
      color: COLOURS.tigerOrange,
      fontSize: basicStyles.h2.fontSize,
      lineHeight: basicStyles.h2.lineHeight,
      fontFamily: 'Larsseit-Light',
      cursor: 'pointer',
      display: 'inline',
      // borderBottomStyle: 'solid',
      // borderBottomWidth: '1px',
      // borderBottomColor: COLOURS.orange,
      ':hover': {
        color: COLOURS.orangeHover,
        // borderBottomColor: COLOURS.orangeHover
      }
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.h2.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.h2.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <h2 style={ styles }>
        { this.props.children }
      </h2>
    );
  }
});


/* h3 */
var dojoH3 = Radium(/* h3 */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.h3.fontSize,
      lineHeight: basicStyles.h3.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGrey,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.h3.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.h3.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <h3 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h3>
    );
  }
});

var dojoH3Bold = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.h3.fontSize,
      lineHeight: basicStyles.h3.lineHeight,
      fontFamily: 'Larsseit-Medium',
      color: COLOURS.textGrey,
      display: 'inline',
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.h3.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.h3.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <h3 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h3>
    );
  }
});

var dojoH3Hover = Radium(class extends React.Component {
  static defaultProps = {

    baseStyles: [{
      color: COLOURS.medGreen.point8,
      fontSize: basicStyles.h3.fontSize,
      lineHeight: basicStyles.h3.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGreen,
      cursor: 'pointer',
      display: 'inline',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: COLOURS.textGreen,
      ':hover': {
        color: COLOURS.textGreenHover,
        borderBottomColor: 'transparent'
      }
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.h3.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.h3.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <h3 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h3>
    );
  }
});

/* p */
var dojoP = Radium(/* p */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.p.fontSize,
      lineHeight: basicStyles.p.lineHeight,
      // fontFamily: 'GothamRnd-Book',
      // color: COLOURS.textGrey,
      fontFamily: 'Larsseit',
      color: COLOURS.ink,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.p.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.p.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <p style={ styles } onClick={this.props.onClick} dangerouslySetInnerHTML={this.props.dangerouslySetInnerHTML}>
        { this.props.children }
      </p>
    );
  }
});

var dojoPBold = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.p.fontSize,
      lineHeight: basicStyles.p.lineHeight,
      fontFamily: 'Larsseit-Medium',
      color: COLOURS.textGrey,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.p.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.p.spacing : null
    }]

    var styles = this.props.baseStyles.concat( this.props.styles, spacing );

    return(
      <h4 className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </h4>
    );
  }
});

var dojoPHover = Radium(class extends React.Component {
  static defaultProps = {

    baseStyles: [{
      color: COLOURS.medGreen.point8,
      fontSize: basicStyles.p.fontSize,
      lineHeight: basicStyles.p.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGreen,
      cursor: 'pointer',
      display: 'inline',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: COLOURS.textGreen,
      ':hover': {
        color: COLOURS.textGreenHover,
        borderBottomColor: 'transparent'
      }
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.p.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.p.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <p 
        style={ styles } 
        onClick={this.props.onClick} 
        data-clipboard-text={this.props.dataClipboardText} 
        className={this.props.className}>
        { this.props.children }
      </p>
    );
  }
});

/* fine print */
var dojoFinePrint = Radium(/* fine print */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.finePrint.fontSize,
      lineHeight: basicStyles.finePrint.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGrey,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.finePrint.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.finePrint.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);
    return(
      <small className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </small>
    );
  }
});

var dojoFinePrintBold = Radium(class extends React.Component {
  // fontSize: '14px',
  // lineHeight: '20px',
  // spacing: '4px'

  static defaultProps = {
    baseStyles: [{
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Larsseit-Medium !important',
      color: COLOURS.textGrey,
      display: 'inline'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.finePrint.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.finePrint.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <small className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </small>
    );
  }
});

var dojoFinePrintHover = Radium(class extends React.Component {
  static defaultProps = {

    baseStyles: [{
      color: COLOURS.medGreen.point8,
      fontSize: basicStyles.finePrint.fontSize,
      lineHeight: basicStyles.finePrint.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGreen,
      cursor: 'pointer',
      display: 'inline',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: COLOURS.textGreen,
      ':hover': {
        color: COLOURS.textGreenHover,
        borderBottomColor: 'transparent'
      }
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.finePrint.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.finePrint.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <small className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </small>
    );
  }
});

/* label */
var dojoLabel = Radium(/* label */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontSize: basicStyles.label.fontSize,
      lineHeight: basicStyles.label.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGrey,
      display: 'inline',
      minHeight: '16px',
      fontWeight: 'inherit'
    }],
    styles: []
  };

  render() {
    var spacing = [{
      marginRight: this.props.spaceRight ? basicStyles.label.spacing : null,
      marginLeft: this.props.spaceLeft ? basicStyles.label.spacing : null
    }]

    var isWhite = []
    if (this.props.isWhite) {
      isWhite = [{
        color: COLOURS.white
      }]
    }
    var styles = this.props.baseStyles.concat(this.props.styles, spacing, isWhite);

    return(
      <label className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </label>
    );
  }
});




/* med */

var medOrangeHover = Radium(/* med */

class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.orange,
      fontSize: '15px',
      fontFamily: 'Larsseit-Light',
      ':hover': {
        color: COLOURS.orangeHover
      }
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});


/* SM (13) */

var smOrangeHover = Radium(/* SM (13) */

class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.orange,
      fontSize: '13px',
      fontFamily: 'Larsseit-Light',
      ':hover': {
        color: COLOURS.orangeHover
      }
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});

var smWhite = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.white,
      fontSize: '13px',
      fontFamily: 'Larsseit-Light'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});


/* XS (10) */
var xsBoldOrangeHover = Radium(/* XS (10) */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.orange,
      fontSize: '10px',
      fontFamily: 'Larsseit-Medium',
      ':hover': {
        color: COLOURS.orangeHover
      }
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});


/* MICRO (9) */
var microBoldWhite = Radium(/* MICRO (9) */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.white,
      fontSize: '9px',
      fontFamily: 'Larsseit-Medium'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});

var microBoldOrangeHover = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.orange,
      fontSize: '9px',
      fontFamily: 'Larsseit-Bold', 
      ':hover': {
        color: COLOURS.orangeHover
      }
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});

var microBold = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.textGrey,
      fontSize: '9px',
      fontFamily: 'Larsseit-Bold', 
      // ':hover': {
      //   color: COLOURS.orangeHover
      // }
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
});

var Danger = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontFamily: 'Larsseit-Medium',
      color: COLOURS.red,
      fontSize: '15px'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span style={styles}
      onClick={this.props.onClick}>
        {this.props.children}
      </span>
    )
  }
});

class Sub extends React.Component {
  render() {
    return (
    <p>
    {this.props.children}
    </p>
    )
 }
}

// xsBoldOrangeHover
var Tooltip = Radium(// xsBoldOrangeHover
class extends React.Component {
  static defaultProps = {
     baseStyles: [{
       fontFamily: 'Larsseit-Medium',
       color: COLOURS.orange,
       fontSize: '10px',
       textDecoration: 'underline',
        marginLeft: '7px'
     }],
     styles: []
  };

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).tooltip();
  }

  render() {
    var copy = 'INFO';
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span
        data-placement={this.props.placement || 'top'}
        style={styles}
        onClick={this.props.onClick}
        data-toggle="tooltip"
        title={this.props.text || 'text'}>
        {this.props.hint || copy}
      </span>
    );
  }
});

/* tables don't take actualy html text elements */
var TableHeaderText = Radium(/* tables don't take actualy html text elements */
class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGrey,
      fontSize: '14px'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </span>
    )
  }
});

var TableText = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGrey,
      fontSize: '14px'
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </span>
    )
  }
});

var TableLink = Radium(class extends React.Component {
  static defaultProps = {
    baseStyles: [{
      color: COLOURS.medGreen.point8,
      fontSize: basicStyles.finePrint.fontSize,
      lineHeight: basicStyles.finePrint.lineHeight,
      fontFamily: 'Larsseit-Light',
      color: COLOURS.textGreen,
      cursor: 'pointer',
      display: 'inline',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: COLOURS.textGreen,
      ':hover': {
        color: COLOURS.textGreenHover,
        borderBottomColor: 'transparent'
      }
    }],
    styles: []
  };

  render() {
    var styles = this.props.baseStyles.concat(this.props.styles);
    return (
      <span className={this.props.className} style={ styles } onClick={this.props.onClick}>
        { this.props.children }
      </span>
    )
  }
});

class CampaignBuilderBoldText extends React.Component{
  render() {
    return(
      <span style={{
        color: this.props.color || COLOURS.ink,
        fontFamily: "'Larsseit'",
        fontSize: this.props.fontSize || '14px'
      }}>
        { this.props.children }
      </span>
    )
  }
}


export default {

  medOrangeHover,
  smOrangeHover,
  smWhite,  
  xsBoldOrangeHover,

  /* these fonts are leftover for the nav bars */
  microBoldWhite,
  microBoldOrangeHover,
  microBold, 

  /* old styles */
  Sub,
  Tooltip,
  Danger,

  /* new not-shitty styles */
  dojoH1,
  dojoH1Book,
  dojoH2,
  dojoH2Bold,
  dojoH2Hover,
  dojoH3,
  dojoH3Bold,
  dojoH3Hover,
  dojoP,
  dojoPBold,
  dojoPHover,
  dojoFinePrint,
  dojoFinePrintBold,
  dojoFinePrintHover,
  dojoLabel,

  TableHeaderText,
  TableText,
  TableLink,

  CampaignBuilderBoldText,

}
