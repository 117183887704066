import React, { Component, PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { gtagPageTrack } from 'util/google-analytics/gtag-pagetrack'

// Wrapper to send GA page views
class SendGAPageViews extends PureComponent {
  componentDidUpdate(prevProps){
    const { location, history } = this.props
    // Send GA Pageviews
    if (location.pathname !== prevProps.location.pathname) {
      const page_path = history.createHref(location)
      gtagPageTrack({ page_path })
    }
  }
  
  render() {
    return null
  }
}

export default withRouter(SendGAPageViews)
