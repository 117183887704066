import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { P } from 'visual-components/util/texts'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'

const mapState = ({ pricingModal }) => ({
  pricingModal
})

class GrowthPricingDialog extends React.Component {
  closeModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'pricingModal',
      data: {
        growthExpand: false
      }
    })
  }

  upgradePlan = () => {
    const { history, pricingModal: { feature, eventTrack } } = this.props

    if (eventTrack === 'socialActionsUpgrade') {
      EventTrackingDispatcher.track('app_upgrade_upgradeButton_socialActionsUpgradeModal')
    } else if (eventTrack === 'customCTAUpgrade') {
      EventTrackingDispatcher.track('app_upgrade_upgradeButton_customCTAUpgradeModal')
    }

    store.dispatch({
      type: 'UPDATE_ARR',
      model: 'general',
      data: {
        upgradeFeature: feature,
        upgradeOriginalLocation: history.location.pathname
      }
    })

    history.push('/upgrade-plan')
    this.closeModal()
  }

  render() {
    const { pricingModal: { growthExpand, title, message } } = this.props

    return (
      <div>
        <Dialog active={ !!growthExpand }
          title={ title }
          theme={ dialogTheme }
          onEscKeyDown={ this.closeModal }
          onOverlayClick={ this.closeModal }
        >
          <div className={ dialogTheme.bannerWrapper }>
            <img src="/images/icons/illustration-feature-gate-email-treasure-chest.svg"
              className={ dialogTheme.noMargin }
            />
          </div>

          <div style={{
            marginBottom: '16px'
          }}>
            <P multiline>
              { message }
            </P>
          </div>

          <ModalButtonGroup confirm={ this.upgradePlan }
            confirmText={ 'View Upgrade Options' }
            canSave
          />
        </Dialog>
      </div>
    )
  }
}

export default withRouter(connect(mapState)(GrowthPricingDialog))
