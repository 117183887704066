import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import * as MetricsActions from 'actions/analytics-actions'

import Spinner from 'util/components/spinner'
import ConversionGraphs from './../overviewMain/conversion-graphs'

const tabs = [
  {
    ref: 'transactions',
    title: 'purchases',
    tooltip: 'Purchases attributed to DojoMojo campaign entries',
  },
  {
    ref: 'revenue',
    title: 'sales generated',
    isDollar: true,
    tooltip: 'Sales generated attributed to DojoMojo campaign entries',
  },
]

class CampaignConversion extends Component {
  handleUpsellClick = () => {
    const { metricsActions } = this.props

    metricsActions.create('clickedAddDataIntegration', {
      meta: { from: 'campaignAnalyticsConversionGraph' }
    })
  }

  switchTab = tab => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'analyticsCampaign',
      data: {
        conversionSelection: tab,
      },
    })
  }

  changeFilter = (startDate, endDate) => {
    const data = [
      {
        startDate,
        endDate,
        metric: 'campaignConversionDetails',
      },
    ]

    AnalyticsDispatcher.fetchReport(data, 'analyticsCampaign')
  }

  render() {
    const {
      campaignConversionDetails,
      conversionSelection,
      hasAnyActiveIntegrations,
    } = this.props

    const data = campaignConversionDetails[conversionSelection]

    if (!data.data.length) {
      return <Spinner />
    }

    return (
      <ConversionGraphs
        tabs={tabs}
        data={campaignConversionDetails}
        switchTab={this.switchTab}
        conversionSelection={conversionSelection}
        hasAnyActiveIntegrations={hasAnyActiveIntegrations}
        handleUpsellClick={this.handleUpsellClick}
      />
    )
  }
}

const mapState = ({ analyticsCampaign, dataIntegrations }) => {
  const {
    campaignConversionDetails,
    conversionSelection,
    datePickerConversionDetails,
    oldestDate,
  } = analyticsCampaign

  return {
    campaignConversionDetails,
    conversionSelection,
    datePickerConversionDetails,
    oldestDate,
    hasAnyActiveIntegrations: dataIntegrations.hasAnyActiveIntegrations,
  }
}

const mapDispatch = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

export default connect(mapState, mapDispatch)(CampaignConversion)
