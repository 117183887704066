import React from 'react';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { withRouter } from 'react-router-dom'

import { bool, func } from 'prop-types';

import { Dialog } from 'react-toolbox/lib/dialog';
import dialogTheme from 'css/themes/dialogs/dialogBase.css';
import COLOURS from 'util/colours';

class OpportunityCreatedDialog extends React.Component {
  static propTypes = {
    active: bool.isRequired,
    handleToggle: func.isRequired
  }
  
  redirect = () => {
    const { type, id } = this.props.opportunity
    this.props.history.push(`/explore/opportunities/${type}/${id}`)
    this.props.handleToggle()
  }

  render () {
    const {
      active,
      handleToggle,
    } = this.props;

    return(
      <Dialog
        theme={ dialogTheme }
        active={ active }
        onEscKeyDown={ handleToggle }
        onOverlayClick={ handleToggle }
        title={ 'Opportunity Created!' }
      >
        <div style={{
          paddingTop: '8px',
        }}>
          <Grid>
            <Row>
              <Col xs>
                <div style={{
                  margin: 'auto',
                  textAlign: 'center',
                  paddingBottom: '24px'
                }}>
                  <img src="/images/icons/celebration-icon.svg"
                    style={{
                      height: '120px',
                      width: '115px'
                  }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <p style={{
                  fontFamily: 'Larsseit-Light',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: COLOURS.ink
                }}>
                  Your Opportunity is now live and will appear in search results and on your Brand Profile. Keep an eye on your inbox for responses and come back anytime to edit it.
                </p>
              </Col>
            </Row>
            <ModalButtonGroup
              cancel={ handleToggle }
              cancelText={ 'Close' }
              confirm={this.redirect}
              confirmText={ 'View Opportunity'}
              canSave={ true }
              hideLine={ true }
            />
          </Grid>
        </div>
      </Dialog>
    )
  }
}

export default withRouter(OpportunityCreatedDialog)
