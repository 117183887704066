
import { getTemplate, compileTemplate } from './TemplateV2'

const template = getTemplate()
const themeColor = '#03a9f4'

template.submitButton.button = {
  borderRadius: 0,
}

template.inputs = {
  input: {
    height: 54,
    alignItems: 'center',
    fontSize: 16,
    minWidth: '94%',
    background: props => props.opts.elements.styles.fonts.formContainerColor.color.color,
    color: props => props.opts.elements.styles.fonts.formTextColor.color.color,
  },
  selectPlaceholder: {
    color: props => props.opts.elements.styles.fonts.formTextColor.color.color,
  },
}

template.formContainer = {
  form: {
    padding: '20px 32px 0 32px',
    marginBottom: 40,
  },
  bottomInfo: {
    padding: '40px 32px 0 32px',
  },
}

export const templateStyles = compileTemplate(template)
export const defaultVals = {}
