import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'

import SideNav from 'visual-components/sideBars/side-nav'
import InternalContentContainer from 'visual-components/containers/InternalContentContainer'

import CampaignProfile from 'visual-components/campaignProfile/CampaignProfile'
import OpportunityProfile from 'visual-components/opportunityProfile/OpportunityProfile'
import BrandProfileContainer from '../brands/BrandProfileContainer'

const mapStateToProps = ({ currentBrand, brand }) => ({ currentBrand, brand })

const redirectTo = path => () => {
  window.location.replace(path)
  return null
}

class Explore extends Component {
  render() {
    const { currentBrand } = this.props
    const prefix = this.props.match.path

    const mediaMarketSubs = [{
      label: 'Sellers',
      to: '/media-market/sellers',
    }]

    if (currentBrand.features.mediaMarketSeller) {
      mediaMarketSubs.push({
        label: 'Buyers',
        to: '/media-market/buyers',
      })
    }

    const routes = [{
      label: 'Recommended',
      to: '/recommended',
    }, {
      label: 'Brands',
      to: '/brands',
    }, {
      label: 'Campaigns',
      to: '/campaigns',
    }, {
      label: 'Opportunities',
      to: '/opportunities',
    }, {
      label: 'Media Market',
      subs: mediaMarketSubs,
    }]

    return (
      <div style={{
        width: '100%',
      }}
      >
        <InternalContentContainer width="1176px">
          <Route
            exact
            path={`${prefix}/:firstLevelOnly/(sellers|buyers|content_swap|all|product_shipment_insert|dedicated_email_buy|dedicated_email_sell|prizing_partner|custom)?`}
            render={routeProps => <SideNav width="240px" routes={routes} prefix={prefix} {...routeProps} />}
          />

          <div style={{ width: '100%' }}>
            <Switch>
              <Route exact path={prefix} render={redirectTo('/app/')} />
              <Route exact path={`${prefix}/recommended`} render={redirectTo('/app/')} />
              <Route exact path={`${prefix}/brands`} render={redirectTo('/app/search/brands')} />
              <Route exact path={`${prefix}/campaigns`} render={redirectTo('/app/search/sweepstakes')} />
              <Route exact path={`${prefix}/opportunities`} render={redirectTo('/app/search/opportunities')} />
              <Route exact path={`${prefix}/opportunities/:type/:id`} component={OpportunityProfile} />
              <Route exact path={`${prefix}/media-market/:type`} render={props => redirectTo(`/app/search/media-market/${props.match.params.type || ''}`)()} />
              { /*
                This is how we redirect with query params in React Router V4, doing
                <Redirect from="/some/:id" to="/other/:id" /> is no longer supported (or working)
              */ }
              <Route exact path={`${prefix}/campaigns/:id`} render={({ match }) => <Redirect to={`${prefix}/campaigns/${match.params.id}/details`} />} />
              <Route path={`${prefix}/campaigns/:campaignId/:subTab`} component={CampaignProfile} />
              <Route path={`${prefix}/brands/:brandId`} component={BrandProfileContainer} />
              <Redirect to="/404" />
            </Switch>
          </div>
        </InternalContentContainer>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Explore)
