import React from 'react'
import { H4, Tiny } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import MediaMarketAnalyticsTheme from 'css/themes/media-market/MediaMarketAnalyticsTheme.css'
import d3 from 'd3'

import FlatRateStats from './FlatRateStats'
import CPCStats from './CPCStats'
import PerformanceBar from './PerformanceBar'
import ClicksCount from './ClicksCount'
import OpenESPCount from './OpenESPCount'

// dedicated_email or sponsor_email

const CardDealStats = props => {
  const {
    isFixed,
    isLive,
    isCPC,
    dealValueByType,
    expectedViews,
    minClicks,
    maxClicks,
    price,
    status,
  } = props

  const fixedStyle = isFixed ? { fontSize: '24px' } : {}
  const fixedAlignment = isFixed ? { textAlign: 'left' } : {}

  return (
    <React.Fragment>
      <Row
        style={{
          justifyContent: 'space-between',
          paddingBottom: '20px',
          width: isFixed && '238px',
        }}
      >
        <div className={MediaMarketAnalyticsTheme.dealStatContainer}>
          <div className={MediaMarketAnalyticsTheme.dealStatValue}>
            <H4
              neonMint={isCPC && dealValueByType > 0 && isLive}
              azure={isCPC && dealValueByType > 0 && !isLive}
              white={(isCPC && dealValueByType === 0 && isLive && status === 'live') || (isFixed && isLive)}
              style={fixedStyle}
            >
              <em>{d3.format('$,.2f')(dealValueByType) }</em>
            </H4>
          </div>
          <Tiny white={isLive && status === 'live'} style={fixedAlignment}>Deal Value</Tiny>
        </div>
        {
            isCPC && (
              <React.Fragment>
                <ClicksCount {...props} />
                <CPCStats
                  status={status}
                  isLive={isLive}
                  minClicks={minClicks}
                  maxClicks={maxClicks}
                  price={price}
                />
              </React.Fragment>
            )
          }
        { isFixed && <FlatRateStats status={status} isLive={isLive} expectedViews={expectedViews} /> }
      </Row>
      { isCPC && <PerformanceBar {...props} /> }
      {
        isFixed && (
          <Row>
            <ClicksCount {...props} />
            {/* TODO: Uncomment this once the open ESPs are available */}
            {/* <OpenESPCount {...props} /> */}
          </Row>
        )
      }
    </React.Fragment>
  )
}

export default CardDealStats
