import React from 'react';
import PropTypes from 'prop-types';

import CampaignPreviewSpecRow from './campaign-preview-spec-row';

import Grid from 'visual-components/util/no-padding-grid/grid';

class CampaignPreviewSpecs extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var {
      type,
      impressions,
      visits,
      entries,
      estEntries
    } = this.props;

    return(
      <Grid>
        <CampaignPreviewSpecRow
          name={ 'Impressions' }
          data={ impressions }
          paddingTop={ '4px' }
        />

        {
          type == 'upcoming' ?
          <CampaignPreviewSpecRow
            name={ 'Est. Entries' }
            data={ estEntries }
            paddingTop={ '8px' }
          />
          :
          <div>
            <CampaignPreviewSpecRow
              name={ 'Visits' }
              data={ visits }
              paddingTop={ '8px' }
            />
            <CampaignPreviewSpecRow
              name={ 'Entries' }
              data={ entries }
              paddingTop={ '8px' }
            />
          </div>
        }
      </Grid>
    );
  }
};

CampaignPreviewSpecs.propTypes = {
  type: PropTypes.string,
  impressions: PropTypes.number,
  visits: PropTypes.number,
  entries: PropTypes.number,
  estEntries: PropTypes.number
};

export default CampaignPreviewSpecs;
