import React, { Component } from 'react'
import { string } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import Card from 'react-toolbox/lib/card'

import { P, H3 } from 'visual-components/util/texts'

import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

import SignAgreementModal from 'container-components/partnership_agreement/sign-agreement-modal'

export class Sign extends Component {
  static contextTypes = {
    impersonating: string,
  }

  componentDidMount() {
    const { partnershipAgreement, actions, match: { params }, history } = this.props

    if (partnershipAgreement.error) {
      history.replace(`/builder/${params.campaignId}/partnership-agreement/dashboard`)
    }

    if (!partnershipAgreement.agreement.id) {
      actions.loadPartnershipAgreement(params.partnershipId)
    }
  }
  get pdfURL() {
    const {
      partnershipAgreement: { id },
      currentBrand: { id: currentBrandId },
    } = this.props
    const { impersonating } = this.context
    return `/partnership-agreement/pdf/${currentBrandId}/${id}${impersonating ? `?impersonating=${impersonating}` : ''}`
  }

  sign = (name, title) => {
    this.props.actions.countersignAgreement(
      this.props.partnershipAgreement.id,
      name,
      title,
      (err) => {
        if (!err) {
          this.props.navigate('completed')
          this.props.openDialog('confirmedPartnerDialog')
        }
      }
    )
  }

  render() {
    const {
      actions,
      currentCampaign,
      partnershipAgreement,
      currentBrand: { accountname: currentBrandName },
    } = this.props

    if (!partnershipAgreement.agreement.id) {
      return null
    }
    const {
      id: inviteId,
      agreement: {
        status,
        top_banner_html,
        rendered_html,
        signature_html,
        host_signature = {},
      },
      invitee: { accountname: partnerBrandName },
      campaign: { name: campaignName },
      signAgreementModalOpen,
    } = partnershipAgreement

    const isComplete = host_signature && !!host_signature.id

    const htmlString = top_banner_html.concat(rendered_html, signature_html)

    return (
      <Grid>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Arizonia"
            rel="stylesheet"
          />
          <link href="/css/agreement.css" rel="stylesheet" />
        </Helmet>
        <Row middle="xs">
          <Col xs={8}>
            <P multiline>
              {isComplete ? (
                <span>
                  View your Partnership Agreement with{' '}
                  <strong>{partnerBrandName}</strong> here. You signed on{' '}
                  {moment(host_signature.createdAt).format('LL')}.
                </span>
              ) : (
                'Sign this contract to add your Partner officially to the sweepstakes.'
              )}
            </P>
          </Col>
          <Col xs={4}>
            <Button
              href={this.pdfURL}
              target="_blank"
              theme={buttonTheme}
              className={buttonTheme.whiteButton}
              label="Download PDF"
              raised
              style={{ float: 'right' }}
            />
          </Col>
        </Row>
        <Row center="xs">
          <Col style={{ width: '976px', marginTop: '34px' }}>
            <Card
              style={{ background: '#fff', padding: '80px', textAlign: 'left' }}
            >
              <span dangerouslySetInnerHTML={{ __html: htmlString }} />
            </Card>
          </Col>
        </Row>
        <SignAgreementModal
          closeModal={actions.closeSigningModal}
          active={signAgreementModalOpen}
          currentBrandName={currentBrandName}
          confirm={this.sign}
        />
      </Grid>
    )
  }
}

export default withRouter(Sign)
