import React, { PureComponent } from 'react'
import { string, number, bool } from 'prop-types'

import { withRouter, Link, func } from 'react-router-dom'
import { connect } from 'react-redux'

const mapState = ({ currentBrand: { id: currentBrandId }}) => ({ currentBrandId })

class LinkOrSwitchBrand extends PureComponent {
  static propTypes = {
    to: string,
    brandId: number,
    hide: bool,
  }

  static defaultProps = {
    to: '/',
    onClick: () => {},
    hide: false,
  }

  render() {
    const {
      brandId,
      currentBrandId,
      to,
      children,
      history,
      onClick,
      hide,
    } = this.props

    const child = React.Children.only( children )

    if (hide) {
      return (
        <div onClick={onClick}>{child}</div>
      )
    }

    return brandId == currentBrandId
      // if link associated with current brand, link within same window
      ? <Link to={ to } onClick={ onClick }>{ child }</Link>
      // otherwise open new window switching the brand
      : <a
          href={ history.createHref({ pathname: to }) + `?brandId=${brandId}`}
          target="_blank"
          onClick={ onClick }
        >
          { child }
        </a>
  }
}

export default withRouter(connect(mapState)(LinkOrSwitchBrand))
