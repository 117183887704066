import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { Small } from 'visual-components/util/texts'
import ToolTip from 'util/components/tooltip'

import colors from 'util/colours'
import { SMSEditorContext } from './SMSEditorContext'

const styles = {
  wrapper: {
    display: 'flex',
    height: 32,
    padding: '8px 12px 0',
    '& > p': {
      lineHeight: 'unset',
      marginTop: 1,
      marginLeft: 4,
    },
  },
  type: {
    flex: 1,
  },
  space: {
    margin: 4,
  },
  stormySkies: {
    color: colors.stormySkies,
  },
}

const TOOLTIP_TEXT = 'Some messages may require additional language appended for complaince. This language will be added automatically every 30 days on a per subscriber basis and will add 89 additional characters to your message.'

function SMSEditorFooter(props) {
  const { classes: css } = props
  const {
    credits,
    type,
    charsInUse,
  } = useContext(SMSEditorContext)

  return (
    <div className={css.wrapper}>
      <div className={css.type}>{type}</div>
      <Small stormySkies>
        <i>Credits:</i>
      </Small>
      <Small>
        <i>{` ${credits}`}</i>
      </Small>
      <div className={css.space} />
      <Small stormySkies>
        <i>Characters:</i>
      </Small>
      <Small>
        <i>{`~${charsInUse}`}</i>
      </Small>
      <ToolTip title={TOOLTIP_TEXT} useNew />
    </div>
  )
}

SMSEditorFooter.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(SMSEditorFooter))
