import React, { Component } from 'react';
import BaseBanner from '../util/banner-base';
import MobileHeader from '../mobile-header/MobileHeader';

import theme from 'css/themes/mobile/home/banner.css';

class NetworkBanner extends Component {
  render() {
    return(
      <div id={ theme.brandNetwork }>
        <MobileHeader />
        <BaseBanner
          title={ 'Brand Network' }
          imgSrc={ '/images/login/mobile/network/brand-search.png' }
          about={ 'Tired of spending hours to find marketing decision-makers? DojoMojo’s powerful search makes it easy to find brand partners for all your partnership marketing campaigns.' } />
      </div>
    )
  }
};

export default NetworkBanner;
