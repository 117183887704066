import React from 'react';
import PropTypes from 'prop-types';
import d3 from 'd3'

import { P, H6, Small } from 'visual-components/util/texts';

import TextThemes from 'css/themes/Text.css';

var formatNumber = function(number) {
  if (!number || typeof number !== 'number') {
    return number ? number : 0;
  } else if (number <= 9999) {
    return d3.format(",")(number);
  } else {
    return d3.format(".2s")(number).replace('k', 'K').replace('G', 'B');
  }
}

class InterestValue extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      title,
      value,
      marginRight,
      marginBottom,
      paddingLeft,
      obscure,
      style
    } = this.props;

    marginRight = marginRight || '0px';
    marginBottom = marginBottom || '24px'

    var formatted = value || value === 0 ? formatNumber(value) : '-'

    let styles = Object.assign({}, {display: 'inline-block', marginBottom: marginBottom}, style)

    return(
      <div style={ styles }>
          <div style={{ marginRight, paddingLeft }}>
            <div style={{
              display: 'inline-flex',
              verticalAlign: 'top'
            }}>
              <div style={{marginBottom: '8px'}}>
                <Small>
                  <em>{ title }</em>
                </Small>
              </div>
            </div>
          </div>
          <div className={ obscure ? TextThemes.obscure : '' } style={{ marginTop: '-3px', paddingLeft }}>
            <Small style={{ display: 'inline-block', fontWeight: '300' }}>
              <i>{ obscure ? '****' : formatted }</i>
            </Small>
          </div>
      </div>
    )
  }
}

InterestValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  style: PropTypes.object
};

export default InterestValue;
