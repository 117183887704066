import { createStore, applyMiddleware, compose } from 'redux'

import thunkMiddleware from 'redux-thunk'
import apiMiddleware from 'util/api-middleware'
import rootReducer from 'util/root-reducer'

const composeEnhancers = (process.env.NODE_ENV !== 'production'
    && typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose

const configureStore = (preloadedState = {}) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, apiMiddleware))
  )

export default configureStore
