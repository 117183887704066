import React from 'react';

import TermsDashboard from 'campaigns/builder/customize/terms/terms-dashboard';
import TermsCreate from 'campaigns/builder/customize/terms/terms-create';
import TermsInfo from 'campaigns/builder/customize/terms/terms-info';
import TermsReview from 'campaigns/builder/customize/terms/terms-review';
import TermsEdit from 'campaigns/builder/customize/terms/terms-edit';

export default [{
  key: 'dashboard',
  title: 'Dashboard',
  component: TermsDashboard
}, {
  key: 'create',
  title: 'Create Rules',
  component: TermsCreate
}, {
  key: 'info',
  title: 'Enter Info',
  actionTitle: 'Generate Rules',
  actionTitle2: 'Re-Generate Rules',
  component: TermsInfo,
  useTemplate: true
}, {
  key: 'review',
  title: 'Review',
  actionTitle: 'Publish',
  actionTitle2: 'Update',
  component: TermsReview,
  useTemplate: true
}, {
  key: 'edit',
  title: 'Edit (Optional)',
  title2: 'Edit',
  actionTitle: 'Publish',
  actionTitle2: 'Update',
  component: TermsEdit
}]
