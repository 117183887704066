import SearchReducerFactory from './search-reducer-factory'

import SearchBrandConfig from 'actions/search-config/search-brand-config'
import SearchCampaignConfig from 'actions/search-config/search-campaign-config'
import SearchOpportunityConfig from 'actions/search-config/search-opportunity-config'
import SearchCampaignAnalyticsConfig from 'actions/search-config/search-campaign-analytics-config'
import SearchCampaignAggregationsConfig from 'actions/search-config/search-campaign-aggregations-config'
import SearchPartnershipAnalyticsConfig from 'actions/search-config/search-partnership-analytics-config'
import SearchBrandRatingConfig from 'actions/search-config/search-brand-rating-config'
import SearchMediaMarketConfig from 'actions/search-config/search-media-market-config'

export const brandSearch = SearchReducerFactory(SearchBrandConfig)

export const campaignSearch = SearchReducerFactory(SearchCampaignConfig)

export const campaignAnalyticsSearch = SearchReducerFactory(
  SearchCampaignAnalyticsConfig
)

export const campaignAggegrationsSearch = SearchReducerFactory(
  SearchCampaignAggregationsConfig
)

export const partnershipAnalyticsSearch = SearchReducerFactory(
  SearchPartnershipAnalyticsConfig
)

export const partnershipCampaignsSearch = SearchReducerFactory({
  ...SearchCampaignAnalyticsConfig,
  model: 'partnershipCampaignsSearch',
})

export const opportunitiesSearch = SearchReducerFactory(SearchOpportunityConfig)

export const brandRatingSearch = SearchReducerFactory(SearchBrandRatingConfig)

export const mediaMarketSearch = SearchReducerFactory(SearchMediaMarketConfig('sellers'))
export const mediaMarketBuyersSearch = SearchReducerFactory(SearchMediaMarketConfig('buyers'))
