import React, { Component } from 'react';
import Header from '../header/Header';
import theme from 'css/themes/homePage/network.css';

class NetworkBanner extends Component {
  render() {
    return(
      <div className={ theme.banner }>
        <Header />
        <div className={ theme.container }>
          <div className={ theme.leftBannerCol }>
            <div className={ theme.wrapper }>
              <p className={ theme.title }>
                Brand Network
              </p>
              <p className={ theme.tag }>
                Tired of spending hours to find marketing decision-makers? DojoMojo’s powerful search makes it easy to find brand partners for all your partnership marketing campaigns.
              </p>
            </div>
          </div>
          <div className={ theme.rightBannerCol }>
            <img
              src="/images/login/homepage/brand-search.png"
              srcSet={`
                /images/login/homepage/brand-search.png,
                /images/login/homepage/brand-search@2x.png 2x,
                /images/login/homepage/brand-search@3x.png 3x
              `}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NetworkBanner;
