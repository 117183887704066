import d3 from 'd3'

export function formatRange (num1, num2, type = null, suffix = '+') {
  let formattedNum1
  let formattedNum2

  if (type == 'money') {
    formattedNum1 = d3.format(num1 / 100 % 1 == 0 ? "$," : "$.2f")(num1 / 100)
    formattedNum2 = d3.format(num2 / 100 % 1 == 0 ? "$," : "$.2f")(num2 / 100)
  } else if (type == 'percent') {
    formattedNum1 = num1 + '%'
    formattedNum2 = num2 + '%'
  } else {
    formattedNum1 = d3.format(",")(num1)
    formattedNum2 = d3.format(",")(num2)
  }

  if (num1 || num2) {
    const showMax = num2 && (
      (type === 'percent' && num2 != 100) || 
      (type !== 'percent' && num2 < 999999)
    )
    return formattedNum1 + (showMax ? ` - ${formattedNum2}` : suffix)
  } else {
    return 'N/A'
  }
}
