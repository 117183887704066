import axios from 'axios'
import * as metrics from 'util/metrics'

export default store => next => action => {
  const { http, types, ...rest } = action
  if (types && http) {
    const [LOADING, SUCCESS, ERROR] = types
    const {
      method = 'GET',
      dataType = 'application/json',
      data,
      files = [],
      dataTransform = (apiMethod, apiData) => (['GET', 'DELETE'].includes(apiMethod) ? apiData : JSON.stringify(apiData)),
      url,
      additionalHeaders,
    } = http
    store.dispatch({ ...rest, type: LOADING })

    let filesData
    if (files.length) {
      filesData = new FormData()

      if (data) {
        Object.keys(data).forEach(field => {
          filesData.append(field, data[field])
        })
      }

      files.forEach(file => {
        filesData.append('file', file)
        filesData.append('name', file.name)
      })
    }

    const axiosData = filesData || dataTransform(method, data)
    const axiosConfig = {
      params: ['GET', 'DELETE'].includes(method) ? axiosData : null,
      headers: {
        'Content-Type': dataType,
        brand: store.getState().currentBrand.id,
        impersonating: sessionStorage.getItem('impersonating'),
        'X-Requested-With': 'XMLHttpRequest',
        'X-Metrics-Session': metrics.getSessionId(),
        ...additionalHeaders,
      },
    }
    
    axios[method.toLowerCase()](
      url,
      ['GET', 'DELETE'].includes(method) ? axiosConfig : axiosData,
      ['GET', 'DELETE'].includes(method) ? null : axiosConfig
    )
      .then(payload => {
        store.dispatch({ ...rest, type: SUCCESS, payload: payload.data })
      }, err => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 403)) {
          store.getState().browserHistory.history.replace('/login')
        } else {
          store.dispatch({ ...rest, type: ERROR, payload: err })
        }
      })
  } else {
    next(action)
  }
}
