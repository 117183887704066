import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import { Button } from 'react-toolbox/lib/button'
import { connect } from 'react-redux'
import { P } from 'visual-components/util/texts'
import ButtonTheme from 'css/themes/Buttons.css'
import MessageSlider from 'visual-components/messaging/message-slider'
import axios from 'axios'
import { stateToHTML } from 'draft-js-export-html'
import requestHeaders from 'request-config'

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& :disabled': { // Disables Button Shadow
      boxShadow: 'none',
    },
  },
  badgesContainer: {
    marginRight: 60,
  },
  textBtn: {
    height: 36,
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    whiteSpace: 'nowrap',
    '& a': {
      marginTop: 4,
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginRight: 8,
        marginBottom: 4,
      },
    },
  },
}

function SplitTrafficPartnerAction(props) {
  const {
    classes: css,
    actions,
    renderBadges,
    campaign,
    currentBrand,
    partnerBannerAction,
    mobileMarketingState,
    setMobileMarketingState
  } = props

  const { showMessageType: showMessageTypeMobileMarketing } = mobileMarketingState

  const [showMessageType, setShowMessageType] = useState(
    mobileMarketingState.showMessageType || null
  )

  useEffect(() => {
    setShowMessageType(showMessageTypeMobileMarketing || null)
  }, [showMessageTypeMobileMarketing])

  const onCloseMessage = () => {
    setShowMessageType(null)
    setMobileMarketingState({ showMessageType: null })
  }

  const onSendMessage = async message => {
    onCloseMessage()
    partnerBannerAction('request')
    await axios.post(`/partnerships/split-traffic-message/${showMessageType}/${campaign.id}`,
      {
        content: stateToHTML(message),
      },
      {
        headers: requestHeaders(),
      })
    toastr.success('Message sent', { timeOut: 10000, positionClass: 'toast-bottom-center' })
  }

  const getMessageTitle = () => {
    if (showMessageType === 'request') {
      return `Request ${campaign.hostBrand.accountname} to share traffic`
    }
    return `New Message to ${campaign.hostBrand.accountname}`
  }

  const getMessageText = () => {
    if (showMessageType !== 'request') return ''
    return `<p>Hey ${campaign.hostBrand.accountname},<br /></p>
    <p>We would like to request that you split traffic with us for the Post Entry Action for ${campaign.name}. <br /></p>
    <p>We will be sure to set up a strong post entry action in order to make the most of this.<br /></p>
    <p>We are looking forward to the sweepstakes - thank you!<br /></p>
    <p>Best,</p>
    <p>${currentBrand.accountname}<br /></p>
    `
  }

  const renderMessageHost = () => (
    <MessageSlider
      isShown
      onClose={onCloseMessage}
      onSend={onSendMessage}
      title={getMessageTitle()}
      buttonText="Send Request"
      initialContent={getMessageText()}
    />
  )

  const onBtnClick = which => async e => {
    if (which === 'request') {
      setShowMessageType('request')
    } else if (which === 'message') {
      setShowMessageType('message')
    } else if (['accept', 'decline'].includes(which)) {
      partnerBannerAction(which === 'accept' ? 'accept' : 'decline')
    }
    e.preventDefault()
  }

  const AcceptTraffic = () => (
    <Button
      onClick={onBtnClick('accept')}
      label="Accept Traffic"
      primary
      theme={ButtonTheme}
      className={ButtonTheme.blueButton}
      raised
    />
  )

  const RequestTraffic = ({ disabled }) => (
    <Button
      onClick={disabled ? null : onBtnClick('request')}
      label="Request Traffic"
      primary
      disabled={disabled}
      theme={ButtonTheme}
      className={disabled ? ButtonTheme.disabledButton : ButtonTheme.blueButton}
      raised
    />
  )

  const DeclineTraffic = () => (
    <div className={css.textBtn}>
      <a onClick={onBtnClick('decline')}>
        <img src="/images/icons/remove-circle-24-px.svg" />
        <P alertRed>Decline Traffic</P>
      </a>
    </div>
  )

  const MessageHost = () => (
    <div className={css.textBtn}>
      <a onClick={onBtnClick('message')}>
        <img src="/images/icons/email-24-px.svg" />
        <P azure>Message Host</P>
      </a>
    </div>
  )

  const actionsMap = React.useMemo(() => action => ({
    request: <RequestTraffic key={action} />,
    accept: <AcceptTraffic key={action} />,
    decline: <DeclineTraffic key={action} />,
    requested: <RequestTraffic disabled key={action} />,
    message: <MessageHost key={action} />,
  }[action]), [])

  return (
    <>
      <div className={css.wrapper}>
        {
          renderBadges
          && (
            <div className={css.badgesContainer}>
              {renderBadges}
            </div>
          )}
        {actions.map(actionsMap)}
      </div>
      { showMessageType !== null && renderMessageHost() }
    </>
  )
}

SplitTrafficPartnerAction.propTypes = {
  classes: PropTypes.object.isRequired,
  renderBadges: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(PropTypes.string),
  campaign: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  partnerBannerAction: PropTypes.func.isRequired,
  setMobileMarketingState: PropTypes.func.isRequired,
  mobileMarketingState: PropTypes.object.isRequired,
}

SplitTrafficPartnerAction.defaultProps = {
  actions: null,
}

const mapStateToProps = state => ({
  currentBrand: state.currentBrand,
})

export default connect(mapStateToProps)(injectCSS(styles)(SplitTrafficPartnerAction))
