import React, { PureComponent as Component } from 'react'
import { object } from 'prop-types'
import { Link } from 'react-router-dom'
import COLOURS from 'util/colours'
import { Card } from 'react-toolbox/lib/card'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H1, H5, Small } from 'visual-components/util/texts'

import LiveCampaignTheme from 'css/themes/campaign-analytics-cards/liveCampaign.css'
import NotLiveCampaignTheme from 'css/themes/campaign-analytics-cards/notLiveCampaign.css'

import Badge from 'visual-components/util/badge'

import moment from 'moment-timezone'

import { makeNumbersPretty } from 'util/makeNumbersPretty'
import StatsDisplay from './stats-display'
import NewToFileDisplay from '../util/new-to-file-display'
import EntryRateGraph from './entry-rate-graph'
import YourMinEntryThreshholdGraph from './your-min-entry-threshhold-graph'
import YourEntryContributionGraph from './your-entry-contribution-graph'

class CampaignAnalyticsCard extends Component {
  static propTypes = {
    campaign: object.isRequired,
  }

  render() {
    const {
      campaign,
    } = this.props

    const {
      id,
      currentBrand = {},
      minEntryRequired = currentBrand.minimum_delivery,
      newToFile = currentBrand.ntf,
      totalEntriesDelivered = currentBrand.total_entries_delivered,
      startDate,
      endDate,
      name,
      live = moment().isBetween(startDate, endDate),
    } = campaign

    const minEntry = minEntryRequired > 0

    const currentTheme = live ? LiveCampaignTheme : NotLiveCampaignTheme

    return (
      <Card className={currentTheme.card}>
        <Link to={`/analytics/campaigns/${id}`}>
          <Grid>
            <Row>
              <Col xs={9}>
                <div style={{ width: '235px', paddingBottom: '6px' }}>
                  <H5 overflowEllipsis white={live}>
                    { name }
                  </H5>
                </div>
              </Col>
              <Col xs={3}>
                {
                  live
                    ? (
                      <div className={currentTheme.liveBadge}>
                        <Badge label="LIVE" color={COLOURS.coral} />
                      </div>
                    ) : (
                      <div>
                        <Small>
                          { `${moment(startDate).format('M/D/YY')}-${moment(endDate).format('M/D/YY')}` }
                        </Small>
                      </div>
                    )
                }
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ display: 'inline-block' }}>
                  <H1 neonMint={live} azure={!live}>
                    { makeNumbersPretty(totalEntriesDelivered) }
                  </H1>
                </div>
                <div style={{ display: 'inline-block', paddingLeft: '4px' }}>
                  <Small white={live}>
                    total entries delivered
                  </Small>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: '16px' }}>
              <Col style={{ width: '235px' }}>
                {
                  live
                    ? (
                      <Small white>
                        <em>
                          STATS TO DATE
                        </em>
                      </Small>
                    ) : (
                      <Small>
                        <em>
                          TOTAL STATS
                        </em>
                      </Small>
                    )
                }
                <StatsDisplay campaign={campaign} live={live} />
                <div style={{ paddingTop: '22px' }}>
                  {
                    live
                      ? minEntry
                        ? <YourMinEntryThreshholdGraph campaign={campaign} live={live} />
                        : <YourEntryContributionGraph campaign={campaign} live={live} />
                      : <EntryRateGraph campaign={campaign} live={live} />
                  }
                </div>
              </Col>
              <Col style={{ width: '70px', marginLeft: '22px' }}>
                <NewToFileDisplay newToFile={newToFile} live={live} totalEntries={totalEntriesDelivered} />
              </Col>
            </Row>
          </Grid>
        </Link>
      </Card>
    )
  }
}

export default CampaignAnalyticsCard
