import React, {Component} from 'react'
import Dialog from 'react-toolbox/lib/dialog'
import dialogTheme from 'css/themes/dialogs/formModal.css'
import { P } from 'visual-components/util/texts'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

class ApplicationSentModal extends Component {
  render() {
    const { open, closeModal} = this.props

    return (
      <Dialog
        theme={ dialogTheme }
        active={ open }
        onEscKeyDown={ closeModal }
        onOverlayClick={ closeModal }
        title={ 'Application Sent' }
      >
        <P style={{
          fontSize: '16px'
        }}>
          Stay tuned! We’ve sent your application to the host. If they indicate interest, you’ll receive a Partnership Agreement to finalize the details of your contribution. If you entered an updated list size in this application, your Brand Profile will automatically be updated to reflect it.
        </P>
        <ModalButtonGroup
          canSave={ true }
          hideLine={ true }
          bottomPad={ true }
          confirm={ closeModal }
          confirmText={ 'Got It' }
        />
      </Dialog>
    )
  }
}

export default ApplicationSentModal
