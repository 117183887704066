import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import _ from 'lodash'

import { Link, withRouter } from 'react-router-dom';
import Spinner from 'util/components/spinner';

import ViewMap from 'campaigns/builder/viewmap';
import BuilderHeader from 'components/campaigns/builder/builder-header';

import CampaignDispatcher from 'dispatchers/campaign-dispatcher';
import { toggleCustomFontModal } from 'dispatchers/current-brand-dispatcher'
import * as BrandFontActions from 'actions/brand-font-actions'
import { H1, H2, H3 } from 'visual-components/util/texts';

import CustomFontsModal from 'visual-components/campaign-builder/custom-fonts/custom-fonts-modal'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
  customFontModalAction: bindActionCreators(toggleCustomFontModal, dispatch),
  brandFontActions: bindActionCreators(BrandFontActions, dispatch),
})

// flatten steps
var flattenedSteps = [];

_.each( ViewMap, step => {
  var currentStep = step;
  if ( currentStep.subSteps ) {
    _.each( currentStep.subSteps, function( subStep ) {
      flattenedSteps.push( _.extend( subStep, { parent: currentStep.key } ) );
    });
  } else {
    flattenedSteps.push( step )
  };
});

var parseParams = function ( params ) {

  var step = params.section || 'checklist';

  var currentStep = _.find(flattenedSteps, stepItem => {
    return stepItem.key === step
  });

  var headerNavPrimary = currentStep.parent ? currentStep.parent : currentStep.key;
  var headerNavSecondary = currentStep.parent ? currentStep.key : null;

  var currentCampaign = store.getState().currentCampaign;

  if (currentCampaign.section !== params.section || currentCampaign.subSection !== params.subSection) {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentCampaign',
      data: {
        section: params.section,
        subSection: params.subSection
      }
    });
  }

  return {
    id: params.campaignId,
    step: currentStep,
    subSection: params.subSection,
    headerNavPrimary: headerNavPrimary,
    headerNavSecondary: headerNavSecondary
  }
}

class BuilderContainer extends React.Component {
  constructor(props) {
    super(props);
    var splat  = parseParams(props.match.params);

    this.state = splat;

    CampaignDispatcher.loadRequest(splat.id);

    this.next = this.next.bind(this);
    this.navigate = this.navigate.bind(this);
    this.updateAttr = this.updateAttr.bind(this);
    this.exit = this.exit.bind(this);
  }

  // this is called when the window.location.hash changes
  componentWillReceiveProps( nextProps ) {
    this.setState( parseParams( nextProps.match.params ));
  }

  componentDidUpdate() {
    const {
      currentCampaign: { id: campaignId, endDate, isSolo },
      history,
      match: { params: { section } },
    } = this.props

    // Prevent brand coming to modify campaign from any place (including emails)
    const campaignOlderThan30Days = moment().diff(endDate, 'days') > 30
    if (campaignOlderThan30Days && (isSolo || section !== 'partner')) return history.replace(`partnerships/campaign/${campaignId}/quickview`)
  }

  componentDidMount() {
    this.props.brandFontActions.loadBrandFonts()
    // Fire events for marketing pixels
    if (window.fbq) {
      fbq('track', 'Lead');
    }
  }

  next() {
    var cloneSteps = _.clone(flattenedSteps);

    var isHost = this.props.currentCampaign.isHost;
    var isSolo = this.props.currentCampaign.isSolo;

    if (!isHost) {
      _.remove(cloneSteps, { host: true })
    }
    if (isSolo) {
      _.remove(cloneSteps, { partnerships: true })
    }
    var stepIdx = cloneSteps.indexOf( this.state.step )

    var nextStepKey = cloneSteps[stepIdx + 1] ? cloneSteps[stepIdx + 1].key : 'setup';

    if (nextStepKey == 'select-template' && this.props.currentCampaign.desktopElements
      && this.props.currentCampaign.desktopElements.landerVersion) {
      nextStepKey = cloneSteps[stepIdx + 2].key
    }

    this.navigate(nextStepKey);
  }

  exit(clickedFinish) {
    const { currentCampaign, history } = this.props

    const nextView = clickedFinish
      ? `campaign/${currentCampaign.id}/quickview`
      : 'current'

    history.push(`/partnerships/${nextView}`);
  }

  navigate(key) {
    if (['terms', 'partnership-agreement'].includes(key)) {
      this.props.history.push(`/builder/${this.state.id}/${key}/dashboard`)
    } else {
      this.props.history.push(`/builder/${this.state.id}/${key}`);
    }
  }

  updateAttr(attr, value) {
    var that = this;
    CampaignDispatcher.updateAttrPersist(attr, value, function () {
      var bodies = ['headLine', 'details', 'disclaimer', 'preEntryMessage', 'additionalTermsDetail'];
      if (bodies.indexOf(attr) !== -1) {
        CampaignDispatcher.updateFont(that.props.currentCampaign);
      }
    });
  }

  updateQuery = (query) => {
    this.setState({
      query: query,
      fonts: this.loadFonts(query)
    })
  }

  confirm = () => {
    // probably something here
    this.props.customFontModalAction()
  }

  render() {
    let {
      customFontModalAction,
      currentBrand: {
        fonts,
        customFontModalOpen
      }
    } = this.props

    var isHost = this.props.currentCampaign.hostBrandId === this.props.currentBrand.id;

    if ( this.props.currentCampaign.loading ) {
      return (
        <Spinner />
      );
    }

    return (
      <div>
        <div className="hidden-xs hidden-sm">
          <BuilderHeader
            { ...this.state }
            currentCampaign={ this.props.currentCampaign }
            viewMap={ ViewMap }
            navigate={ this.navigate }
            next={ this.next }
            exit={ this.exit }
          />
          <this.state.step.component
            params={ this.props.match.params }
            route={ this.props.route }
            subSection={ this.props.currentCampaign.section }
            updateAttr={ this.updateAttr }
            isHost={ isHost }
            currentBrand={ this.props.currentBrand }
            currentCampaign={ this.props.currentCampaign }
          />
          <CustomFontsModal
            active={ customFontModalOpen }
            onchange={ this.updateQuery }
            inputRef={ 'fontSearch' }
            hideModal={ customFontModalAction }
            confirm={ this.confirm }
            selectedFonts={ fonts }
            updateFont={ this.props.brandFontActions.updateBrandFont }
          />
        </div>
        <div className="hidden-md hidden-lg">
          <div className="container">
            <div className="row text-center"
              style={{
                marginTop: '250px'
            }}>
              <div className="col-xs-12">
                <H1>
                  Sorry!
                </H1>
              </div>
            </div>
            <br />
            <div className="row text-center">
              <div className="col-xs-12">
                <H2>
                  We don‘t support this screen size for the campaign builder. Feel free to use all our awesome features on a larger device.
                </H2>
              </div>
            </div>
            <br />
            <div className="row text-center">
              <div className="col-xs-12">
                <H3>
                  <a href={`/app/sweepstakes/${this.props.currentCampaign.isSolo ? 'solo ' : 'partnership'}`}>
                    Back to my campaigns.
                  </a>
                </H3>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuilderContainer))
