const showModal = function (payload) {
  return {
    type: 'SHOW_SELLER_PROFILE_MODAL',
    payload,
  }
}

const closeModal = function (payload) {
  return {
    type: 'HIDE_SELLER_PROFILE_MODAL',
    payload,
  }
}

const loadSellerProfile = brandId => ({
  http: {
    url: `/brands/seller-profile/${brandId}`,
  },
  types: [
    'LOADING_SELLER_PROFILE',
    'LOADING_SELLER_PROFILE_SUCCESS',
    'LOADING_SELLER_PROFILE_FAILURE',
  ],
})

const updateSellerProfile = data => ({
  http: {
    url: '/brands/seller-profile',
    method: 'PATCH',
    data,
  },
  types: [
    'UPDATE_SELLER_PROFILE',
    'UPDATE_SELLER_PROFILE_SUCCESS',
    'UPDATE_SELLER_PROFILE_FAILURE',
  ],
})

const clear = () => ({
  type: 'SELLER_PROFILE_CLEAR',
})

export default {
  showModal,
  closeModal,
  loadSellerProfile,
  updateSellerProfile,
  clear,
}
