export const loading = () => ({ type: 'LOADING_BILLING_EMAILS' })

export const load = brandId => ({
  http: {
    url: `/billing-emails/${brandId}`,
  },
  types: [
    'LOADING_BILLING_EMAILS',
    'LOAD_BILLING_EMAILS_SUCCESS',
    'LOAD_BILLING_EMAILS_FAILURE',
  ],
})

export const addEmail = (brandId, email) => ({
  email,
  http: {
    url: `/billing-emails/${brandId}`,
    method: 'POST',
    data: { email },
  },
  types: [
    'LOADING_BILLING_EMAILS',
    'ADD_BILLING_EMAIL_SUCCESS',
    'ADD_BILLING_EMAIL_FAILURE',
  ],
})

export const deleteEmail = (brandId, email) => ({
  email,
  http: {
    url: `/billing-emails/${brandId}`,
    method: 'DELETE',
    data: { email },
  },
  types: [
    'LOADING_BILLING_EMAILS',
    'DELETE_BILLING_EMAIL_SUCCESS',
    'DELETE_BILLING_EMAIL_FAILURE',
  ],
})
