import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import ExploreContainer from 'visual-components/explore/explore-container'
import OverviewBar from 'visual-components/analytics/util/overview-bar'
import CampaignAnalyticsResultsContainer from 'visual-components/analytics/campaign/CampaignAnalyticsResultsContainer'
import SearchCampaignAnalyticsConfig from 'actions/search-config/search-campaign-analytics-config'
import moment from 'moment-timezone'
import d3 from 'd3'
import { P } from 'visual-components/util/texts'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import CampaignsOverviewFilters from './campaigns-overview-filters'

const mapStateToProps = ({ currentBrand }) =>
  ({ featureFlags: currentBrand.features })

class CampaignsOverview extends Component {
  exportCSV = () => {
    let csvString = 'Campaign, Dates, Entries, Visits, NTF, NTF%\n'

    EventTrackingDispatcher.track(
      'appAnalytics_export_exportButton_campaignExportButton'
    )

    store
      .getState()
      .campaignAnalyticsSearch.results.map(campaign => {
        const { name, startDate, endDate, currentBrand, totalVisits } = campaign

        return [
          name,
          `${moment(startDate).format('MM/DD/YY')}-${moment(endDate).format(
            'MM/DD/YY'
          )}`,
          currentBrand.total_entries_delivered,
          totalVisits,
          currentBrand.ntf,
          d3.format('.2%')(currentBrand.ntf_perc / 100),
        ]
      })
      .forEach(arr => {
        csvString += `${arr.toString()}\n`
      })

    window.location.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(
      csvString
    )}`
  }

  renderResults = ({
    total,
    allVisits,
    partners: {
      currentBrand: {
        allNTF,
        totalEntriesDelivered,
        conversion_transactions,
        conversion_revenue,
        sms_subscribes,
      },
    },
  }) => {
    const { featureFlags: { block_sms } } = this.props
    const canViewSms = !block_sms

    return (
      <OverviewBar
        metrics={[
          {
            label: 'total campaigns',
            value: total,
          },
          {
            label: 'total visits',
            value: allVisits.value,
          },
          {
            label: 'total verified entries',
            value: totalEntriesDelivered.value,
          },
          {
            label: 'total NTF',
            value: allNTF.value,
          },
          {
            label: 'sms signups',
            value: sms_subscribes.value,
          },
          {
            label: 'total purchases',
            value: conversion_transactions.value,
            checkPassROI: true,
            hideZeros: true,
          },
          {
            label: 'total sales generated',
            value: conversion_revenue.value,
            isDollar: true,
            checkPassROI: true,
            hideZeros: true,
          },
        ]}
        checkDataIntegrations={true}
        eventTrackingLocation = {'campaignsAnalyticsTopBar'}
        canViewSms={canViewSms}
      />
    )
  }

  renderContainer = ({ results, loading, error, filtered, gridView }) => (
    <CampaignAnalyticsResultsContainer
      campaigns={results || []}
      loading={loading}
      error={error}
      filtered={filtered}
      display={gridView}
    />
  )

  render() {
    return (
      <ExploreContainer
        config={SearchCampaignAnalyticsConfig}
        title="Sweepstakes Analytics"
        titleAction={this.exportCSV}
        titleActionLabel="Export CSV"
        belowTitle={
          <div style={{ marginTop: '16px' }}>
            <P multiline>
              Better understand your most successful campaigns and make
              decisions for future partnerships.
            </P>
          </div>
        }
        searchPlaceholder="Search campaigns"
        renderAboveResults={this.renderResults}
        filters={<CampaignsOverviewFilters />}
        render={this.renderContainer}
      />
    )
  }
}

export default connect(mapStateToProps)(CampaignsOverview)
