import React, { PureComponent as Component } from 'react'
import AcquiredLine from 'visual-components/analyticsDashboard/overviewCard/acquired-line'
import AggregatesBar from 'visual-components/analyticsDashboard/overviewCard/aggregate-bar'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import OverviewCard from 'visual-components/analyticsDashboard/overviewCard/overviewCard'
import Spinner from 'util/components/spinner'
import PropTypes from 'prop-types'

const tabs = [
  {
    ref: 'dashboardEmailsAcquired',
    title: 'emails acquired',
  },
  {
    ref: 'dashboardSmsSubscribers',
    title: 'sms signups',
  },
  {
    ref: 'dashboardSocialActions',
    title: 'social actions',
  },
  {
    ref: 'dashboardCampaignsLaunched',
    title: 'sweeps launched',
  },
  {
    ref: 'dashboardNewPartnerships',
    title: 'new partnerships',
  },
]

const dataMap = function (data, section) {
  switch (section) {
    case 'dashboardCampaignsLaunched':
    case 'dashboardNewPartnerships':
      return AggregatesBar
    default:
      return AcquiredLine
  }
}

class AggregatesTop extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    hideTabs: PropTypes.array,
  }

  static defaultProps = {
    hideTabs: [],
  }

  switchTab = tab => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'analyticsDashboard',
      data: {
        overviewSelection: tab,
      },
    })
  }

  changeFilter = (startDate, endDate) => {
    const data = tabs.map(tab => ({
      startDate,
      endDate,
      metric: tab.ref,
    }))

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  render() {
    const { data, hideTabs } = this.props

    const acquiredData = data[data.overviewSelection]

    if (!acquiredData) { return (<Spinner />) }

    const SelectedComponent = dataMap(data, data.overviewSelection)
    return (
      <div>
        <OverviewCard
          tabs={tabs.filter(tab => !hideTabs.includes(tab.ref))}
          aggregates={data.overviewAggregates}
          selectedTab={data.overviewSelection}
          changeFilter={this.changeFilter}
          switchTab={this.switchTab}
          {...data}
        >
          <SelectedComponent
            data={acquiredData}
            section={data.overviewSelection}
          />
        </OverviewCard>
      </div>
    )
  }
}

export default AggregatesTop
