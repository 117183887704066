import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import UploadImage from 'components/util/editor-uploader'
import EditOverlay from './EditOverlay'
import LogoEditModal from './LogoEditModal'

const styles = {
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Open-Sans',
    marginBottom: 20,
    textAlign: 'center',
  },
  img: {
    height: props => props.maxHeight,
  },
  modalContainer: {
    position: 'relative',
    width: '100%',
  },
}

class Logo extends React.Component {
  static propTypes = {
    logoPath: propTypes.string.isRequired,
    hostWebsite: propTypes.string.isRequired,
    classes: propTypes.object.isRequired,
    maxHeight: propTypes.number,
    updateAttr: propTypes.func.isRequired,
    edit: propTypes.func.isRequired,
    editName: propTypes.string.isRequired,
    elements: propTypes.object.isRequired,
    campaign: propTypes.object.isRequired,
    label: propTypes.string,
    allowDelete: propTypes.bool,
    uploadButtonLabel: propTypes.string,
    className: propTypes.string,
    id: propTypes.string,
    modalPosition: propTypes.string,
    horizontalAlign: propTypes.string,
  }

  static defaultProps = {
    maxHeight: 100,
    label: null,
    allowDelete: false,
    className: '',
    id: '',
    uploadButtonLabel: 'Upload a logo',
    modalPosition: 'bottom',
    horizontalAlign: 'center',
  }

  static contextTypes = {
    baseStyles: propTypes.object,
  }

  edit = () => {
    const {
      edit,
      editName,
    } = this.props

    edit(editName)
  }

  onClose = () => {
    const {
      edit,
    } = this.props

    edit('')
  }

  renderLabel = () => {
    const {
      classes: css,
      label,
    } = this.props

    return (
      <div className={css.label}>
        {label}
      </div>
    )
  }

  onUpload = uploadRes => {
    const {
      updateAttr,
      editName,
    } = this.props

    updateAttr(editName.replace(/-/g, '_'), uploadRes.src)
  }

  onDelete = () => {
    this.onUpload({
      src: 'no-logo',
    })
    this.onClose()
  }

  renderUploadButton = () => {
    const {
      uploadButtonLabel,
      updateAttr,
      allowDelete,
      campaign,
      editName,
    } = this.props

    // Do no show upload button if
    // not in editor mode
    // or can't delete logo
    if (!updateAttr || !allowDelete) {
      return null
    }

    return (
      <UploadImage
        success={this.onUpload}
        action={`/campaign-photo/${campaign.id}/${editName}`}
        name="campaign-main-image"
        acceptedFiles="image/jpeg,image/png"
      >
        <Button
          onClick={this.onReviewAction}
          label={uploadButtonLabel}
          className={cn(ButtonTheme.primary, ButtonTheme.blueButton, ButtonTheme.flat)}
          theme={ButtonTheme}
          raised
        />
      </UploadImage>
    )
  }

  renderEditModal = () => {
    const {
      editName,
      allowDelete,
      campaign,
      maxHeight,
      updateAttr,
      modalPosition,
      horizontalAlign,
    } = this.props

    return (
      <LogoEditModal
        updateAttr={updateAttr}
        onClose={this.onClose}
        campaignId={campaign.id}
        maxHeight={maxHeight}
        editName={editName}
        modalPosition={modalPosition}
        horizontalAlign={horizontalAlign}
        onDelete={allowDelete && this.onDelete}
      />
    )
  }

  renderLogo = () => {
    const {
      classes: css,
      updateAttr,
      className,
      id,
      logoPath,
    } = this.props

    return (
      <div style={{ position: 'relative' }}>
        {!!updateAttr && <EditOverlay onClick={this.edit} />}
        <img
          id={id}
          className={cn(css.img, className)}
          src={logoPath}
        />
      </div>
    )
  }

  render() {
    const {
      logoPath,
      classes: css,
      elements: {
        editingAttr,
      },
      editName,
      label,
    } = this.props

    const isEditing = editingAttr === editName
    const logoExists = logoPath && logoPath !== 'no-logo'

    return (
      <div className={css.container}>
        <div className={css.modalContainer}>
          {isEditing && this.renderEditModal()}
        </div>
        {(label && logoExists) && this.renderLabel()}
        {logoExists ? this.renderLogo() : this.renderUploadButton()}
      </div>
    )
  }
}

export default injectSheet(styles)(Logo)
