import React, { Component } from 'react';
import _ from 'lodash';

import Dialog from 'react-toolbox/lib/dialog';

import { H4, P } from 'visual-components/util/texts';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import dialogTheme from 'css/themes/dialogs/formModal.css';

export default class AgreementSignedModal extends Component {
  state = {
    name: '',
    title: '',
  };

  render() {
    const { closeModal, active, campaignName, hostBrandName } = this.props;
    return (
      <Dialog
        theme={dialogTheme}
        active={active}
        onEscKeyDown={closeModal}
        onOverlayClick={closeModal}
        title="Agreement Signed"
      >
        <P>
          You've signed this Partnership Agreement.
          We've notified {hostBrandName} that they need to countersign for you to be confirmed as a Partner in <b>{campaignName}</b>.
        </P>
        <ModalButtonGroup
          canSave
          confirm={closeModal}
          confirmText="Got it"
          hideLine={true}
        />
      </Dialog>
    );
  }
}
