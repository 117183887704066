import React from 'react';
import _ from 'lodash'

import { Card } from 'react-toolbox/lib/card';
import { P, Tiny, H5, Small } from 'visual-components/util/texts';

class SocialNumber extends React.Component {
  render() {
    var {
      label,
      number
    } = this.props;

    return (
      <div
        style={{
          display: 'inline-block',
          width: '60px'
        }}>
        <div>
          <H5>
            {number}
          </H5>
        </div>
        <div>
          <Tiny>
            {label}
          </Tiny>
        </div>
      </div>
    )
  }
}

class SocialRow extends React.Component {
  render() {
    var {
      platform
    } = this.props;

    return (
      <div style={{marginTop: '12px'}}>
        <div style={{
          display: 'inline-block',
          width: '80px',
          marginBottom: '10px',
          float: 'left'
        }}>
          <Small>
            {platform.name}
          </Small>
        </div>
        {platform.values}
      </div>
    )
  }
}

var platforms = [
  {
    name: 'Facebook',
    types: ['fbLike', 'fbShare'],
    typeMap: {
      fbLike: 'likes',
      fbShare: 'shares'
    }
  }, {
    name: 'Instagram',
    types: ['instagramFollow'],
    typeMap: {
      instagramFollow: 'follows'
    }
  }, {
    name: 'Twitter',
    types: ['twitterFollow'],
    typeMap: {
      twitterFollow: 'follows'
    }
  }, {
    name: 'YouTube',
    types: ['youtubeSubscribe', 'youtubeView'],
    typeMap: {
      youtubeView: 'views',
      youtubeSubscribe: 'subscribes'
    }
  }, {
    name: 'Pinterest',
    types: ['pinterestFollow', 'pinterestPin'],
    typeMap: {
      pinterestPin: 'pins',
      pinterestFollow: 'follows'
    }
  }, {
    name: 'Snapchat',
    types: ['snapchatFollow'],
    typeMap: {
      snapchatFollow: 'follows'
    }
  }, {
    name: 'App Installs',
    types: ['appDownload'],
    typeMap: {
      appDownload: 'downloads'
    }
  }
]

class SocialTooltipCard extends React.Component {
  render() {
    var {
      date,
      data
    } = this.props;

    var platformRows = platforms.map(function (platform) {
      platform.values = platform.types.map(function(platformType, idx) {
        var value = _.find(data, { series: platformType });
        return value
          ? <SocialNumber
              label={platform.typeMap[platformType]}
              number={value.y}
              key={idx} />
          : null
      });

      platform.values = _.compact(platform.values);

      return platform.values.length
        ? <SocialRow platform={platform} />
        : null
    })

    platformRows = _.compact(platformRows);

    return (
      <Card style={{
        paddingTop: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        width: '224px'
      }}>
        <Tiny>
          {date}
        </Tiny>
        {platformRows}
      </Card>
    )
  }
};

export default SocialTooltipCard;
