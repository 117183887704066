import React from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { H3, H6, Small } from 'visual-components/util/texts'
import Field from 'visual-components/util/inputs/field'
import PostEntryMessage from 'util/components/templates/form/post-entry-message'
import SocialTheme from 'css/themes/SocialGrowth.css'
import PartnershipInvitesDispatcher from 'dispatchers/partnership-invites-dispatcher'

const mapStateToProps = ({ ctaModel, ctaForm }) => ({ ctaModel, ctaForm })

const MobileMarketingCard = props => {
  const { ctaModel, ctaForm } = props

  const updateAttr = (attr, value) => {
    PartnershipInvitesDispatcher.updateAttrPersist(attr, value)
  }

  return (
    <div className={SocialTheme.thankYouMessageContainer}>
      <div className={SocialTheme.thankYouMessageInputContainer}>
        <H3>Thank You Message</H3>
        <div className={SocialTheme.thankYouInputRow}>
          <div className={SocialTheme.thankYouMessageInput}>
            <Field
              attrName="postEntryMessage"
              label="Message"
              dataModel={ctaModel}
              formModel={ctaForm}
              updateAttr={updateAttr}
            />
          </div>
          <div className={SocialTheme.thankYouPreviewContainer}>
            <PostEntryMessage message={ctaModel.postEntryMessage} />
          </div>
        </div>
      </div>
    </div>
  )
}

MobileMarketingCard.propTypes = {
  ctaModel: object.isRequired,
  ctaForm: object.isRequired,
}

export default connect(mapStateToProps)(MobileMarketingCard)
