import React from 'react'
import { string, func } from 'prop-types'
import AceEditor from 'react-ace'
import brace from 'brace'
import 'brace/mode/css'
import 'brace/theme/chrome'

class CustomCSSEditor extends React.Component {
  static propTypes = {
    attr: string.isRequired,
    updateAttr: func.isRequired,
    originalContent: string.isRequired,
  }

  handleEditorChange = value => {
    const { attr, updateAttr } = this.props
    updateAttr(attr, value)
  }

  render() {
    const { originalContent } = this.props
    return (
      <AceEditor
        mode="css"
        theme="chrome"
        onChange={this.handleEditorChange}
        name="ace-editor"
        width="100%"
        value={originalContent}
        setOptions={{
          printMargin: false,
        }}
      />
    )
  }
}

export default CustomCSSEditor
