import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'

import ProfileSideCard from 'visual-components/util/profile-side-card'
import ProfileStat from 'visual-components/util/small-profile-stat'
import ProfileSocialStat from 'visual-components/util/profile-social-stat'
import EditSocial from './EditSocial'

class SocialCard extends PureComponent {
  propTypes = {
    facebookFollowers: PropTypes.any.isRequired,
    twitterFollowers: PropTypes.any.isRequired,
    instagramFollowers: PropTypes.any.isRequired,
    youtubeFollowers: PropTypes.any.isRequired,
    pinterestFollowers: PropTypes.any.isRequired,
    socialMediaImpressions: PropTypes.any.isRequired,
    isBrand: PropTypes.bool,
    obscure: PropTypes.bool,
    socialHandles: PropTypes.object.isRequired,
  }

  static defaultProps = {
    isBrand: false,
    obscure: false,
  }

  state = { isMouseInside: false }

  mouseEnter = () => { this.setState({ isMouseInside: true }) }

  mouseExit = () => { this.setState({ isMouseInside: false }) }

  render() {
    const {
      isBrand,
      facebookFollowers,
      twitterFollowers,
      instagramFollowers,
      youtubeFollowers,
      pinterestFollowers,
      socialMediaImpressions,
      obscure,
      socialHandles,
    } = this.props
    const { isMouseInside } = this.state

    const hidePlaceholder = [
      facebookFollowers,
      twitterFollowers,
      instagramFollowers,
      socialMediaImpressions,
    ].some(val => val)

    const {
      facebookUrl,
      twitterHandle,
      instagramHandle,
      youtubeHandle,
      pinterestHandle,
    } = socialHandles

    return (
      <div
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseExit}
        style={{ marginTop: '16px' }}
      >
        <ProfileSideCard title="Social Following">
          <div>
            {
              isBrand
                ? <EditSocial hovered={isMouseInside} />
                : null
            }
            <div>
              <Grid>
                <Row style={{ marginBottom: '16px' }}>
                  {
                    !socialMediaImpressions
                      ? (
                        <p
                          style={{
                            fontFamily: 'Larsseit',
                            fontSize: '12px',
                            color: COLOURS.cloudGrey,
                            lineHeight: '20px',
                          }}
                        >
                          {
                            hidePlaceholder
                              ? null
                              : isBrand
                                ? 'Add information about your different social accounts here'
                                : 'This brand has yet to enter their social account information.'
                          }
                        </p>
                      ) : null
                  }
                  <ProfileStat
                    obscure={obscure}
                    title="TOTAL AUDIENCE"
                    value={socialMediaImpressions}
                  />
                </Row>
                <Row>
                  <ProfileSocialStat
                    obscure={obscure}
                    icon="facebook"
                    value={facebookFollowers}
                    socialHandle={facebookUrl}
                  />

                  <ProfileSocialStat
                    obscure={obscure}
                    icon="twitter"
                    value={twitterFollowers}
                    socialHandle={twitterHandle}
                  />

                  <ProfileSocialStat
                    obscure={obscure}
                    icon="instagram"
                    value={instagramFollowers}
                    socialHandle={instagramHandle}
                  />

                  <ProfileSocialStat
                    obscure={obscure}
                    icon="youtube"
                    value={youtubeFollowers}
                    socialHandle={youtubeHandle}
                  />

                  <ProfileSocialStat
                    obscure={obscure}
                    icon="pinterest"
                    value={pinterestFollowers}
                    socialHandle={pinterestHandle}
                  />
                </Row>
              </Grid>
            </div>
          </div>
        </ProfileSideCard>
      </div>
    )
  }
}

export default SocialCard
