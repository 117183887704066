import axios from 'axios'

export const loading = () => ({ type: 'LOADING_ESP_INTEGRATIONS' })
export const listsLoading = () => ({ type: 'LOADING_ESP_INTEGRATION_LISTS' })
export const testLoading = () => ({ type: 'LOADING_ESP_INTEGRATION_TEST' })
export const finished = () => ({ type: 'LOADING_ESP_INTEGRATIONS_FINISHED' })

export const clear = () => ({ type: 'CLEAR_ESP_INTEGRATIONS' })
export const clearIntegration = () => ({ type: 'CLEAR_ESP_INTEGRATION' })
export const clearTestResults = () => ({ type: 'CLEAR_ESP_INTEGRATION_TEST' })
export const clearWarningModal = () => ({ type: 'CLEAR_ESP_INTEGRATION_WARNING_MODAL' })

export const load = (model, id) => ({
  model,
  http: {
    url: `/integrations/all/${model}/${id}`,
  },
  types: [
    'LOADING_ESP_INTEGRATIONS',
    'LOAD_ESP_INTEGRATIONS_SUCCESS',
    'LOAD_ESP_INTEGRATIONS_FAILURE',
  ],
})

export const loadByType = (model, type, id) => ({
  model,
  http: {
    url: model === 'brand' ? `/integrations/brand/${type}` : `/integrations/campaign/${id}/${type}`,
  },
  types: [
    'LOADING_ESP_INTEGRATIONS',
    'LOAD_ESP_INTEGRATION_SUCCESS',
    'LOAD_ESP_INTEGRATION_FAILURE',
  ],
})

export const updateAttr = data => ({
  type: 'UPDATE_ESP_INTEGRATIONS_ATTR',
  data,
})

export const updateAttrPersist = (model, id, data) => ({
  data,
  http: {
    url: `/integrations/${model}/${id}`,
    method: 'PUT',
    data,
  },
  types: [
    'UPDATE_ESP_INTEGRATION',
    'UPDATE_ESP_INTEGRATION_SUCCESS',
    'UPDATE_ESP_INTEGRATION_FAILURE',
  ],
})

export const fetchLists = (model, integration, type = 'lists') => async dispatch => {
  const urls = {
    'active-campaign': `/active-campaign/fetch-${type}?key=${integration.apiKey}&url=${integration.apiUrl}`,
    aweber: `/aweber/fetch-${type}?id=${integration.account_id}&token=${integration.oauth_token}&secret=${integration.oauth_token_secret}`,
    'campaign-monitor': `/campaign-monitor/fetch-${type}?key=${encodeURIComponent(integration.apiKey)}`,
    'conde-nast': `/conde-nast/fetch-${type}?key=${integration.siteCode}`,
    'convert-kit': `/convert-kit/fetch-${type}?key=${integration.apiKey}`,
    freshmail: `/freshmail/fetch-${type}?key=${integration.apiKey}&secret=${integration.apiSecret}`,
    hubspot: `/hubspot/fetch-${type}?key=${integration.apiKey}`,
    infusionsoft: `/infusionsoft/fetch-${type}?key=${integration.access_token}`,
    iterable: `/iterable/fetch-${type}?key=${integration.apiKey}`,
    klaviyo: `/klaviyo/fetch-${type}?key=${integration.apiKey}`,
    mailchimp: `/mailchimp/fetch-${type}?dc=${integration.dc}&mailchimpAccessToken=${integration.mailchimpAccessToken}`,
    mailgun: `/mailgun/fetch-${type}?key=${integration.apiKey}`,
    mailjet: `/mailjet/fetch-${type}?key=${integration.apiKey}&secret=${integration.apiSecret}`,
    maropost: `/maropost/fetch-${type}?id=${integration.accountId}&key=${integration.apiKey}`,
    postup: `/postup/fetch-${type}?user=${integration.username}&pass=${integration.password}`,
    sailthru: `/sailthru/fetch-${type}?key=${integration.apiKey}&secret=${integration.apiSecret}`,
    sendgrid: `/sendgrid/fetch-${type}?version=${integration.marketingVersion}&key=${integration.apiKey}`,
  }

  dispatch(listsLoading())

  try {
    const { data: lists } = await axios.get(urls[integration.integration_type])
    dispatch(updateAttrPersist(model, integration.id, { [type]: lists }))
    dispatch(finished())
  } catch (e) {
    console.log(e)
  }
}

export const updateMergeTags = data => ({
  type: 'UPDATE_ESP_INTEGRATION_MERGE_TAGS',
  data,
})

export const persistMergeTags = data => ({
  data,
  http: {
    url: `/integrations-merge-tags/${data.model}/${data.id}`,
    method: 'PUT',
    data,
  },
  types: [
    'UPDATE_ESP_INTEGRATION',
    'UPDATE_ESP_INTEGRATION_MERGE_TAGS_SUCCESS',
    'UPDATE_ESP_INTEGRATION_MERGE_TAGS_FAILURE',
  ],
})

export const defaultMergeTags = data => ({
  data,
  http: {
    url: `/integrations-merge-tags-default/${data.model}/${data.id}`,
    method: 'POST',
    data,
  },
  types: [
    'UPDATE_ESP_INTEGRATION',
    'UPDATE_ESP_INTEGRATION_MERGE_TAGS_SUCCESS',
    'UPDATE_ESP_INTEGRATION_MERGE_TAGS_FAILURE',
  ],
})

export const brandToCampaigns = (integration, data) => async dispatch => {
  try {
    const { data: integrations } = await axios.post('/integrations-brand-to-campaigns', { integration })
    if (integrations && integrations.length) {
      dispatch(updateAttr({
        warningModal: {
          expand: true,
          data,
          integration,
          integrations,
        },
      }))
    }
  } catch (e) {
    console.log(e)
  }
}

export const updateTestResults = (success, data, isActivate) => ({
  type: 'UPDATE_ESP_INTEGRATION_TEST',
  success,
  data,
  isActivate,
})

export const test = (model, id, isActivate) => async dispatch => {
  dispatch(testLoading())

  try {
    const { data } = await axios.post(`/integrations-test/${model}/${id}`)
    dispatch(updateAttrPersist(model, id, { verified: true }))
    dispatch(updateTestResults(true, data, isActivate))
    return true
  } catch (e) {
    dispatch(updateAttrPersist(model, id, { verified: false }))
    dispatch(updateTestResults(false, e.response.data.err, isActivate))
    return false
  }
}

export const disconnect = (model, id) => ({
  id,
  http: {
    url: `/integrations/${model}/${id}`,
    method: 'DELETE',
  },
  types: [
    'DELETE_ESP_INTEGRATION',
    'DELETE_ESP_INTEGRATION_SUCCESS',
    'DELETE_ESP_INTEGRATION_FAILURE',
  ],
})
