import NumberField from 'util/components/number-field';
import Label from 'util/components/label';
import _ from 'lodash'

class PercentageField extends NumberField {
  constructor(props) {
    super(props);
    this.updateNumber = this.updateNumber.bind(this);
    this.focus = this.focus.bind(this);
    this.showActual = this.showActual.bind(this);

    this.state = {};
  }

  showActual(e) {
    const input = $(e.currentTarget).val() || '0';
    var value = input.replace(/[^0-9\.]+/g, '');

    value = value || 0;

    value = parseFloat(value);
    $(e.currentTarget).val(value);

    this.setState({
      isFocused: true
    });
  }

  updateNumber(e) {
    let value = e.target.value;
    value = parseFloat(value.replace(/[^0-9\.]/g, ''));
    if (isNaN(value)) {
      value = 0;
    }

    value = (Math.round( value * 100 ) / 10000);

    value = value > this.props.max ? this.props.max : value;

    this.updateValue(value);
  }


  // processValue(value) {
  //   value = parseFloat(value.replace(/[^0-9\.]/g, ''));
  //   this.state.value = value;
  //   this.forceUpdate();

  //   this.validate(function (errors) {
  //     if (!errors.length) {
  //       this.props.update(this.props.attrName, value);
  //     }
  //   }.bind(this));
  // }

  render() {
    const { attrName, disabled, editable } = this.props;

    let errors = this.props.formModel[attrName];
    errors = errors ? errors.errors : [];
    errors = _.map(errors, function (text, idx) {
      return (
        <span key={ idx } className="help-block">
          { text }
        </span>
      );
    });

    const className = `${ errors.length ? 'has-error' : '' }`;
    const value = this.props.dataModel[this.props.attrName] || 0;

    const disable_input = (disabled || editable === false) ? 'disabled' : '';

    return (
      <div className={ className }>
        { this.props.label ?
          <Label
            label={ this.props.label}
          />
          : null
        }
        <input
          style={{
            borderRadius: '1px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: COLOURS.inputBorder,
            paddingTop: '4px',
            paddingLeft: '14px',
            paddingBottom: '4px',
            paddingRight: '12px',
            width: '100%',
            backgroundColor: 'transparent',
            color: COLOURS.textGrey,
            fontFamily: 'GothamRnd-Book',
            fontSize: '15px',
            outline: 'none',
            boxShadow: 'none',
            WebkitBoxShadow: 'none',
            height: this.props.withIcon ? null : '50px',
            textAlign: 'right'
          }}
          onBlur={ this.blur }
          disabled={ false }
          value={ !this.state.isFocused ? d3.format(".0%")( value ) : value * 100 }
          ref={ this.props.attrName }
          onFocus={ this.showActual }
          onChange={ this.updateNumber }
          type="text"
          className="form-control"
          name={ this.props.attrName }
          id={ this.props.attrName } />
        { errors }
      </div>
    );
  }
}

export default PercentageField;
