import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-toolbox/lib/button';
import PricingColumns from './pricing-columns';
import MobileHeader from '../mobile-header/MobileHeader';
import MobileFooter from '../mobile-footer/MobileFooter';

import theme from 'css/themes/mobile/home/pricing.css';
import ButtonTheme from 'css/themes/Buttons.css'

class MobilePricing extends Component {
  state = {
    isAnnual: false
  }

  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  toggleAnnual = () => {
    const newVal = !this.state.isAnnual;

    this.setState({
      isAnnual: newVal
    })
  }

  clickTalkToUs = (location) => {
    let referralURLExtension = this.props.referralId ? `/${ this.props.referralId }` : '';

    window.mixpanel.track('Click Talk to Us Button', {
      referrer: document.referrer,
      buttonLocation: location,
      referralURLExtension: referralURLExtension
    });
  }

  render() {
    let {
      isAnnual
    } = this.state;

    let referralURLExtension = this.props.referralId ? `/${ this.props.referralId }` : '';

    return(
      <div className={ theme.mostOuter }>
        <MobileHeader lightBackground={ true } />
        <div className={ theme.pricingContainer }>
          <p className={ theme.title }>
            Grow Your Audience
          </p>
          <p className={ theme.subtitle }>
            Try DojoMojo Premium free for 30 days. No credit card required.
          </p>
          <a href="/app/register">
            <Button
              onClick={ e => this.clickTalkToUs('abovePlans') }
              label="Start Free Trial"
              className={ButtonTheme.greenButton}
              theme={ButtonTheme}
              floating
            />
          </a>
          <p className={ theme.oneTrial }>
            Limit 1 free trial per brand.
          </p>
          <PricingColumns
            isAnnual={ isAnnual }
            referralURLExtension={ referralURLExtension }
          />
          <a href="/app/register">
            <Button
              onClick={ e => this.clickTalkToUs('belowPlans') }
              label="Start Free Trial"
              className={ButtonTheme.greenButton}
              theme={ButtonTheme}
              floating
            />
          </a>
          <div className={ theme.allPlansIncludeContainer }>
            <p className={ theme.theyinclude }>
              All Plans Include:
            </p>
            <img
              src='/images/login/mobile/pricing/lead-acquisiton-icon.png'
              srcSet={`/images/login/mobile/pricing/lead-acquisiton-icon.png,
                /images/login/mobile/pricing/lead-acquisiton-icon@2x.png 2x,
                /images/login/mobile/pricing/lead-acquisiton-icon@3x.png 3x`}
              className={ theme.treeImg } />
            <p className={ theme.afterimgText }>
              Acquisition of targeted, high-quality email leads
            </p>
            <img
              src='/images/login/mobile/pricing/esp-integration-email-icon.png'
              srcSet={`/images/login/mobile/pricing/esp-integration-email-icon.png,
                /images/login/mobile/pricing/esp-integration-email-icon@2x.png 2x,
                /images/login/mobile/pricing/esp-integration-email-icon@3x.png 3x`}
              className={ theme.envelopeImg } />
            <p className={ theme.afterimgText }>
              Integrate directly with your ESP for instant access to entries
            </p>
            <img
              src='/images/login/mobile/pricing/customer-support-icon.png'
              srcSet={`/images/login/mobile/pricing/customer-support-icon.png,
                /images/login/mobile/pricing/customer-support-icon@2x.png 2x,
                /images/login/mobile/pricing/customer-support-icon@3x.png 3x`}
              className={ theme.highFiveImg } />
            <p className={ theme.afterimgText }>
              Extensive customer support from our expert team
            </p>
            <img
              src='/images/login/mobile/pricing/analytics-icon.png'
              srcSet={`/images/login/mobile/pricing/analytics-icon.png,
                /images/login/mobile/pricing/analytics-icon@2x.png 2x,
                /images/login/mobile/pricing/analytics-icon@3x.png 3x`}
              className={ theme.analyticsImg } />
            <p className={ theme.afterimgText }>
              Marketing insights from comprehensive analytics
            </p>
          </div>
          <p className={ theme.needHelp }>
            Need Help Choosing a Plan?
            <a href='mailto:hello@dojomojo.com'>
              Contact Us!
            </a>
          </p>
        </div>
        <MobileFooter />
      </div>
    )
  }
}

export default MobilePricing;
