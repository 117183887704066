import React, { PureComponent } from 'react'
import _ from 'lodash'

import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from 'react-toolbox/lib/button'
import { Link } from 'react-router-dom'

import * as MetricsActions from 'actions/analytics-actions'

import CampaignTab from 'visual-components/campaignProfile/campaign-tab'
import ButtonTheme from 'css/themes/Buttons.css'

const buttonTheme = {
  Button: ButtonTheme
}

const dispatchToProps = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

const renderTabs = selectedTab => option => {
  return (
    <CampaignTab
      key={option.name}
      tab={option}
      active={option.name === selectedTab}
    />
  )
}

class CampaignTabs extends PureComponent {
  static propTypes = {
    tabOptions: PropTypes.array.isRequired,
    selectedTab: PropTypes.string.isRequired,
    campaign: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  onSignAgreementClick = () => {
    const {
      metricsActions,
      campaign,
      history,
      location,
    } = this.props

    metricsActions.create('clickedSignAgreement', {
      meta: {
        campaignId: campaign.id,
        partnershipAgreementId: campaign.partnershipAgreement.agreement_id,
        from: location.pathname,
      },
    })

    return history.push(`/partnership-agreement/${campaign.partnershipAgreement.agreement_id}`)
  }

  render() {
    const {
      tabOptions,
      selectedTab,
      campaign,
      currentBrand,
    } = this.props

    const tabs = _.map(tabOptions, renderTabs(selectedTab))

    const isPartner = _.find(campaign.invites, {
      invitee_id: currentBrand.id,
      status: 'accepted',
    })

    const showEdit = new Date(campaign.endDate) > new Date()
    const showAnalytics = new Date(campaign.startDate) < new Date()

    const SelectedTab = _.find(tabOptions, { name: selectedTab }).component

    const needsSignature = campaign.partnershipAgreement.status === 'shared'

    return (
      <div>
        <div style={{ height: '64px' }}>
          <div style={{ float: 'left', marginTop: '23px' }}>
            { tabs }
          </div>
          {
            showEdit
            && isPartner
              ? (
                <Link to={`/partnerships/campaign/${campaign.id}/quickview`}>
                  <Button
                    label="Campaign Overview"
                    className={buttonTheme.Button.blueButton}
                    theme={buttonTheme.Button}
                    flat
                    style={{ marginTop: '12px', float: 'right' }}
                  />
                </Link>
              ) : null
          }
          {
            showAnalytics
            && isPartner
              ? (
                <Link to={`/analytics/campaigns/${campaign.id}`}>
                  <Button
                    label="Analytics"
                    className={buttonTheme.Button.greenButton}
                    theme={buttonTheme.Button}
                    flat
                    style={{ marginTop: '12px', marginRight: showEdit ? '8px' : 0, float: 'right' }}
                  />
                </Link>
              ) : null
          }
          {
            needsSignature
              ? (
                <Button
                  onClick={this.onSignAgreementClick}
                  label="Sign Agreement"
                  className={buttonTheme.Button.blueButton}
                  theme={buttonTheme.Button}
                  flat
                  style={{ marginTop: '12px', marginRight: 0, float: 'right' }}
                />
              ) : null
          }
        </div>

        <SelectedTab campaign={campaign} currentBrand={currentBrand} />
      </div>
    )
  }
}

export default withRouter(connect(null, dispatchToProps)(CampaignTabs))
