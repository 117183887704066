import React, { Component } from 'react'
import DefaultCard from 'visual-components/util/cards/default-card'
import ProfileStat from 'visual-components/util/profile-stat'

import theme from 'css/themes/referralTheme.css'

class BrandReferralStats extends Component {
  render() {
    const {
      accountname,
      friendsReferred,
      rewardsEarned
    } = this.props

    return(
      <DefaultCard
        title={ `${accountname}'s Referral Stats`}
        className={ theme.brandReferralStatsCard }
      >
        <div className={theme.statsContainer}>
          <div className={theme.stat}>
            <ProfileStat title={ 'FRIENDS REFERRED' }
              alwaysShow = { true }
              value={ friendsReferred }
              brand={ true }
            />
          </div>
          <div className={theme.stat}>
            <ProfileStat title={ 'REWARDS EARNED' }
              alwaysShow = { true }
              value={ rewardsEarned }
              brand={ true }
              wholeDollar={ true }
            />
          </div>
        </div>
      </DefaultCard>
    )
  }
}

export default BrandReferralStats
