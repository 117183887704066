import React from 'react'
import FormContainer from 'v2/components/templates/FormContainer'
import Logo from 'v2/components/templates/Logo'
import Text from 'v2/components/templates/Text'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import TemplateContainer from 'v2/components/templates/TemplateContainer'
import BackgroundOverlay from 'v2/components/templates/BackgroundOverlay'
import HeroImage from 'v2/components/templates/HeroImage'
import { templateStyles } from 'v2/components/templates/styles/version-14'
import styleHelper from 'v2/styles/style-helper'
import constants from 'v2/components/templates/constants.json'
import getEditorMenuWidth from 'v2/components/templates/utils/getEditorMenuWidth'

const menuWidth = props => (getEditorMenuWidth(
  props.edit,
  props.desktopVersion,
  props.isEditorMenuOpen
))

const styles = {
  topLogoContainer: {
    margin: 32,
  },
  leftColumn: {
    position: props => (props.desktopVersion ? 'fixed' : 'relative'),
    height: props => (
      props.desktopVersion
        ? (props.updateAttr ? `calc(100vh - ${constants.topNavHeight}px)` : '100vh')
        : 'auto'
    ),
    top: props => (
      props.desktopVersion
        ? (props.updateAttr ? constants.topNavHeight : 0)
        : 0
    ),
    width: props => (props.desktopVersion ? `calc(50% - ${menuWidth(props) / 2}px)` : '100%'),
    padding: props => (props.desktopVersion ? 0 : '140px 0'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    left: menuWidth,
    overflow: 'hidden',
    transition: `left ${constants.menuTransitionTime}, width ${constants.menuTransitionTime}`,
    [styleHelper.max(1100)]: {
      top: '0 !important',
      position: 'relative !important',
      width: '100% !important',
      height: 'auto !important',
      padding: '140px 0 !important',
    },
  },
  formContainer: {
    height: props => (props.desktopVersion ? '100%' : 'auto'),
    width: props => (props.desktopVersion ? '50%' : '100%'),
    marginLeft: props => (props.desktopVersion ? 'auto' : '0'),
    background: 'rgba(255,255,255,.85)',
    [styleHelper.max(1100)]: {
      marginLeft: '0 !important',
      width: '100% !important',
      height: 'auto !important',
    },
  },
  headlineContainer: {
    position: 'relative',
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 60px)'),
    margin: props => (props.desktopVersion ? '0 60px' : '30px'),
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 60px)',
      margin: '0 30px',
    },
  },
  preEntryMessageContainer: {
    width: props => (props.desktopVersion ? 'calc(100% - 120px)' : 'calc(100% - 60px)'),
    marginTop: 32,
    [styleHelper.max(1100)]: {
      width: 'calc(100% - 60px)',
    },
  },
  detailsContainer: {
    margin: 32,
  },
  ...templateStyles,
}

class Version14 extends React.Component {
  static propTypes = {
    opts: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool,
    isEditorMenuOpen: propTypes.bool,
  }

  static defaultProps = {
    desktopVersion: true,
    isEditorMenuOpen: false,
  }

  static childContextTypes = {
    baseStyles: propTypes.object,
    userStyles: propTypes.object,
    placeholderColor: propTypes.string,
  }

  getChildContext() {
    const {
      classes: css,
      opts: {
        elements: {
          styles: userStyles,
        },
      },
    } = this.props

    return {
      baseStyles: css,
      userStyles,
      placeholderColor: userStyles.fonts.formTextColor.color.color,
    }
  }

  render() {
    const {
      opts: {
        campaign,
        hostBrand,
        elements,
        termsLink,
        acceptedInvites,
        elements: {
          styles: userStyles,
        },
      },
      updateElementsAttr,
      editElements,
      customFonts,
      edit,
      updateAttr,
      agreeToLiability,
      classes: css,
      desktopVersion,
      isEditorMenuOpen,
    } = this.props

    const formStyles = userStyles.form

    return (
      <TemplateContainer
        campaign={campaign}
        elements={elements}
        desktopVersion={desktopVersion}
        isFixedBackgroundImage={false}
        updateAttr={updateElementsAttr}
        campaignUpdateAttr={updateAttr}
        acceptedInvites={acceptedInvites}
        isEditorMenuOpen={isEditorMenuOpen}
        hostBrand={hostBrand}
      >
        <div
          className={css.leftColumn}
        >
          <HeroImage
            imageUrl={elements.mainImage}
            desktopVersion={desktopVersion}
          />
          <BackgroundOverlay
            desktopVersion={desktopVersion}
          />
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="headLine"
            customFonts={customFonts}
            className={css.headlineContainer}
            width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
          />
          
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="preEntryMessage"
            customFonts={customFonts}
            className={css.preEntryMessageContainer}
            width={desktopVersion ? 'calc(100% - 120px)' : '100%'}
          />

        </div>
        <div
          className={css.formContainer}
          style={{ background: `${formStyles.color}` }}
          id="form-holder"
        >
          <div
            id="top-logo-container"
            className={css.topLogoContainer}
          >
            <Logo
              hostWebsite={hostBrand.website}
              logoPath={elements.top_logo || hostBrand.logo}
              updateAttr={updateElementsAttr}
              edit={editElements}
              campaign={campaign}
              elements={elements}
              maxHeight={elements.styles.topLogo ? elements.styles.topLogo.height : 100}
              className={css.topLogoImg}
              id="top-logo"
              allowDelete
              editName="top-logo"
              horizontalAlign="left"
            />
          </div>
          <Text
            model={elements}
            updateAttr={updateElementsAttr}
            edit={editElements}
            attr="details"
            customFonts={customFonts}
            className={css.detailsContainer}
          />
          <FormContainer
            updateAttr={updateAttr}
            updateElementsAttr={updateElementsAttr}
            campaign={campaign}
            elements={elements}
            edit={edit}
            editElements={editElements}
            termsLink={termsLink}
            agreeToLiability={agreeToLiability}
            acceptedInvites={acceptedInvites}
            customFonts={customFonts}
            desktopVersion={desktopVersion}
          />
        </div>
      </TemplateContainer>
    )
  }
}

export default injectSheet(styles)(Version14)
