import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Card } from 'react-toolbox/lib/card'
import { H3, P } from 'visual-components/util/texts'

class ProfileMainCard extends PureComponent {
  render() {
    const {
      title, sideTitle, subTitle, style, titleStyle, children,
    } = this.props

    const mergedTitleStyles = {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      ...titleStyle,
    }

    return (
      <Card
        style={{
          position: 'relative',
          width: '100%',
          paddingTop: '20px',
          paddingRight: '24px',
          paddingBottom: '20px',
          paddingLeft: '24px',
          ...style,
        }}
      >
        <div style={mergedTitleStyles}>
          <H3 coral>
            <small>{title}</small>
          </H3>
          {sideTitle ? <P>{sideTitle}</P> : null}
        </div>
        {subTitle ? (
          <div style={{ margin: '-8px 0 16px' }}>
            <P>{subTitle}</P>
          </div>
        ) : null}
        {children}
      </Card>
    )
  }
}

ProfileMainCard.propTypes = {
  title: PropTypes.string.isRequired,
  sideTitle: PropTypes.string,
  subTitle: PropTypes.string,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

ProfileMainCard.defaultProps = {
  style: {},
  titleStyle: {},
  subTitle: null,
  sideTitle: null,
}

export default ProfileMainCard
