const model = 'currentBrand'
import _ from 'lodash'

const completedProfileFields = ['facebookCoverPhoto', 'description']
const completedProfileSocialFields = [
  'facebookUrl',
  'twitterHandle',
  'instagramHandle',
  'pinterestHandle',
  'youtubeHandle',
]

const profileComplete = function (brand) {
  if (brand.profileComplete) {
    return true
  }

  let complete = _.every(completedProfileFields, item => !!brand[item])

  if (complete == true) {
    //profile info has been completed
    complete = _.some(completedProfileSocialFields, item => !!brand[item])
  }

  return complete
}

const defaultState = {
  rid: model,
  loading: true,
  belt: 'white',
  facebookFollowers: 0,
  twitterFollowers: 0,
  instagramFollowers: 0,
  pinterestFollowers: 0,
  youtubeFollowers: 0,

  // TODO: future feature
  timezone: 'America/New_York',

  recommendedBrands: [],
  brandsExposed: 3,
  recommendedBrandsLoading: true,

  recommendedCampaigns: [],
  campaignsExposed: 2,
  recommendedCampaignsLoading: true,

  recommendedOpportunities: [],

  integrations: [],

  opportunities: [],
  opportunitiesSort: 'createdAt',
  opportunitiesReversed: false,

  customFontModalOpen: false,
  fonts: [],
  brandfavorites: [],
  features: {},

  defaultBonusValue(bonus_type) {
    const brand = store.getState().currentBrand
    const map = {
      instagramFollow() {
        return brand.instagramHandle
          ? {
            instagramHandle: brand.instagramHandle,
          }
          : {}
      },
      instagramVisit() {
        return brand.instagramHandle
          ? {
            instagramHandle: brand.instagramHandle,
          }
          : {}
      },
      twitterFollow() {
        return brand.twitterHandle
          ? {
            twitterHandle: brand.twitterHandle,
          }
          : {}
      },
      fbLike() {
        return brand.facebookUrl
          ? {
            fbHandle: brand.facebookUrl,
          }
          : {}
      },
      pinterestFollow() {
        return brand.pinterestHandle
          ? {
            pinterestHandle: brand.pinterestHandle,
          }
          : {}
      },
    }

    if (map[bonus_type]) {
      return map[bonus_type]()
    }
    return {}
  },
}

const touFlags = {
  platform: 'has_accepted_latest_terms',
  publisher: 'has_accepted_media_market_seller_latest_terms',
  advertiser: 'has_accepted_media_market_buyer_latest_terms',
}

const CurrentBrandReducer = function (state, action) {
  if (action.model !== model && action.type !== 'SUBMIT_APPLICATION_REQUEST') {
    // && action.type.model !== model) {
    return state || defaultState
  }

  const actions = {
    LOAD() {
      const data = _.extend({}, action.data)

      data.profileComplete = profileComplete(data)

      return _.extend(
        {
          loading: false,
        },
        state,
        data
      )
    },
    UPDATE_ATTR() {
      if (
        !state.profileComplete
        && completedProfileFields.indexOf(Object.keys(action.data)[0]) !== -1
      ) {
        const newData = _.extend({}, state, action.data)
        newData.profileComplete = profileComplete(newData)

        return newData
      }
      return _.extend({}, state, action.data)
    },
    ADD_CONVERSATION_STARTED() {
      let startedConversations = state.conversations

      const existing = _.find(startedConversations, {
        id: action.data.id,
        initiatingBrand: action.data.initiatingBrand,
      })

      if (!existing) {
        startedConversations = startedConversations.concat([action.data])
      }

      return _.extend({}, state, {
        conversations: startedConversations,
      })
    },
    SEARCH_MY_OPPORTUNITIES_SUCCESS() {
      const opportunities = action.payload.map(({ id, ...rest }) => {
        id = Number(id.split('__')[1])
        return { id, ...rest }
      })
      return { ...state, opportunities, loadingOpportunity: false }
    },
    SEARCH_MY_OPPORTUNITIES_FAILURE() {
      return { ...state, loadingOpportunity: false }
    },
    SEARCH_OPPORTUNITIES() {
      return { ...state, loadingOpportunity: true }
    },
    LOADING_OPPORTUNITY_BRAND() {
      return { ...state, loadingOpportunity: true }
    },
    LOAD_OPPORTUNITY_FAILURE() {
      return {
        ...state,
        loadingOpportunity: false,
        error: action.payload.response
          ? `${action.payload.response.status}: ${action.payload.response.data}`
          : 'Error',
      }
    },
    REMOVE_OPPORTUNITY() {
      const opportunities = state.opportunities.filter(
        ({ id, type }) => id != action.id || type != action.oppType
      )
      return { ...state, opportunities }
    },
    ADD_OPPORTUNITY() {
      const opportunities = [
        {
          ...action.payload,
          brandName: state.accountname,
          brandLogo: state.logo,
        },
        ...state.opportunities,
      ]
      return {
        ...state,
        opportunities,
        loadingOpportunity: false,
        newOpportunity: action.payload,
      }
    },
    UPDATE_OPPORTUNITY() {
      const opportunities = state.opportunities.map(opportunity => {
        if (opportunity.id === action.payload.id && opportunity.type === action.payload.type) {
          return action.payload
        }
        return opportunity
      })
      return { ...state, opportunities, loadingOpportunity: false }
    },
    TOGGLE_CUSTOM_FONT_MODAL() {
      return _.extend({}, state, { customFontModalOpen: !state.customFontModalOpen })
    },
    LOAD_FONTS_SUCCESS() {
      return Object.assign({}, state, { fonts: action.payload })
    },
    UPDATE_FONT_SUCCESS() {
      return Object.assign({}, state, { fonts: action.payload })
    },
    SUBMIT_APPLICATION_REQUEST() {
      return {
        ...state,
        dedicatedListSize: action.payload.dedicatedListSize,
      }
    },
    ACCEPT_TOU_SUCCESS() {
      const flag = touFlags[action.flagType]

      return {
        ...state,
        [flag]: action.accepted,
      }
    },
    ACCEPT_TOU_FAILURE() {
      if (action.payload.id !== state.id) return state

      const flag = touFlags[action.flagType]

      return {
        ...state,
        [flag]: false,
      }
    },
    ADD_BRAND_FEATURE() {
      const { feature } = action.payload
      if (state.features[feature]) return state
      const features = { ...state.features, [feature]: true }
      return { ...state, features }
    },
  }

  const item = actions[action.type]
    || function () {
      return state || defaultState
    }

  return item()
}

export default CurrentBrandReducer
