import React from 'react'

import BonusOptionItem from './bonus-option-item'
import COLOURS from 'util/colours'

const bonusOptions = [{
  key: 'linkShare',
  label: 'Share With Friends',
  colour: COLOURS.seaGreen
}, {
  key: 'appDownload',
  label: 'App Download',
  colour: COLOURS.seaGreen
}, {
  key: 'fbLike',
  label: 'Like on Facebook',
  colour: COLOURS.fbBlue
}, {
  key: 'fbShare',
  label: 'Share on Facebook',
  colour: COLOURS.fbBlue
}, {
  key: 'instagramFollow',
  label: 'Follow on Instagram',
  colour: COLOURS.instagram
}, {
  key: 'instagramVisit',
  label: 'Visit Instagram Page',
  colour: COLOURS.instagram
},
// DISABLING TWITTER PER JIRA 3796
// {
//   key: 'twitterShare',
//   label: 'Share on Twitter',
//   colour: COLOURS.twitterBlue
// },
{
  key: 'twitterFollow',
  label: 'Follow on Twitter',
  colour: COLOURS.twitterBlue
}, {
  key: 'youtubeSubscribe',
  label: 'YouTube Subscribe',
  colour: COLOURS.youtubeRed
}, {
  key: 'youtubeWatch',
  label: 'YouTube Watch Video',
  colour: COLOURS.youtubeRed
}, {
  key: 'pinterestFollow',
  label: 'Follow on Pinterest',
  colour: COLOURS.pinterestRed
}, {
  key: 'pinterestPin',
  label: 'Pin on Pinterest',
  colour: COLOURS.pinterestRed
}, {
  key: 'snapchatFollow',
  label: 'Follow on Snapchat',
  colour: COLOURS.snapchatYellow
}]

class BonusOptions extends React.Component {
  render() {
    const { currentBrand, partnershipInvite, profile: { id: user }, bonusItems, addBonusItem } = this.props

    const impersonating = sessionStorage.getItem('impersonating')
    const houseBrands = [52, 3, 65, 469, 305, 467, 468, 851, 514, 721]

    const createdItems = bonusItems.presentationOrder.map(item => item.partnership_invite_id === partnershipInvite.id ? item.bonus_type : null)
    const bonusOptionItems = bonusOptions.map((item, idx) => {
      if (!houseBrands.includes(currentBrand.id) && createdItems.includes(item.key) && (!impersonating || impersonating === 'null')) {
        return null
      }

      return (
        <BonusOptionItem key={ idx }
          item={ item }
          addBonusItem={ addBonusItem }
        />
      )
    })

    return (
      <div id='bonusOptions'>
        { bonusOptionItems }
      </div>
    )
  }
}

export default BonusOptions
