import OverviewActions from 'visual-components/myPartnerships/OverviewActions'
import moment from 'moment-timezone'

import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { func, object, bool } from 'prop-types'

import { H4 } from 'visual-components/util/texts'

import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'

import { getClaim } from 'actions/brand-claim-actions'

const areYouSure = 'Are you sure you want to launch? Your campaign will be open to entries.'
const makeSure = 'Please make sure all Partners are confirmed prior to launching your campaign. You can view your current partners'

const confirmBeforeProceed = (launch, id) => ConfirmationDispatcher.check({
  header: 'Launch Campaign?',
  children: (
    <React.Fragment>
      <H4 multiline>{areYouSure}</H4>
      <div style={{ margin: '24px 0' }} />
      <H4 multiline>
        {makeSure}
        &nbsp;
        <Link to={`/builder/${id}/partner/interested`} onClick={ConfirmationDispatcher.closeModal}>here.</Link>
      </H4>
    </React.Fragment>
  ),
  confirmText: 'Launch',
  action() {
    launch()
    ConfirmationDispatcher.closeModal()
  },
})

const getOptions = (...params) => {
  const [
    ctx,
    currentCampaign,
    launchCampaign,
    pickWinner,
  ] = params

  const {
    managePartners,
    downloadEntries,
    duplicateCampaign,
    endCampaign,
    deleteCampaign,
  } = ctx

  const {
    id,
    previewLink,
    isSolo,
  } = currentCampaign

  const beforeLaunch = () => {
    const partnersAllReady = currentCampaign.invites.every(invite => invite.status === 'accepted')

    if (!partnersAllReady) {
      return confirmBeforeProceed(launchCampaign, id)
    }

    return launchCampaign(isSolo)
  }

  const options = {
    managePartners: {
      label: 'Manage Partners',
      onClick: () => managePartners(id),
    },
    preview: {
      label: 'Preview',
      onClick: () => window.open(previewLink),
    },
    tracking: {
      label: 'Promote',
      href: `/partnerships/campaign/${id}/links-files`,
    },
    downloadEntries: {
      label: 'Download Entries',
      onClick: () => downloadEntries(id, 'Current Campaigns'),
    },
    duplicateCampaign: {
      label: 'Duplicate',
      onClick: () => duplicateCampaign(id),
    },
    launch: {
      label: 'Launch',
      onClick: () => beforeLaunch(),
    },
    pickWinner: {
      label: 'Pick Winner',
      onClick: () => pickWinner(id),
    },
    releaseEmails: {
      label: 'Release Emails',
      onClick: () => managePartners(id),
    },
    end: {
      label: 'End Campaign',
      onClick: () => endCampaign(),
    },
    delete: {
      label: 'Delete',
      onClick: () => deleteCampaign(id),
    },
  }

  return options
}

class OverviewActionsContainer extends Component {
  static propTypes = {
    currentCampaign: object.isRequired,
    onLaunchCampaign: func.isRequired,
    showConfirmationBeforeLaunching: func.isRequired,
    pickWinner: func.isRequired,
    isSolo: bool,
  }

  static defaultProps = {
    isSolo: false,
  }

  static contextTypes = {
    managePartners: func,
    editCampaign: func,
    endCampaign: func,
    launchCampaign: func,
    duplicateCampaign: func,
    deleteCampaign: func,
    downloadEntries: func,
  }

  options = () => {
    const {
      currentCampaign,
      onLaunchCampaign,
      pickWinner,
    } = this.props

    const options = getOptions(
      this.context,
      currentCampaign,
      onLaunchCampaign,
      pickWinner
    )

    const {
      isHost,
      endDate,
      startDate,
      numberOfPartners,
      isSolo,
    } = currentCampaign

    const canCreateSweepstakes = getClaim(`sweepstakes.${isSolo ? 'solo' : 'partnership'}.create`)

    if (!isHost) {
      if (startDate < new Date()) {
        return []
      }
      return []
    }

    // past campaign options
    if (endDate < new Date()) {
      return [
        options.downloadEntries,
        options.pickWinner,
        options.releaseEmails,
        ...(canCreateSweepstakes ? [options.duplicateCampaign] : []),
        options.preview,
      ]
    }

    // upcoming campaigns
    if (startDate > new Date()) {
      const upcomingOptions = [
        ...(!currentCampaign.isSolo ? [options.managePartners] : []),
        options.preview,
        options.launch,
        ...(canCreateSweepstakes ? [options.duplicateCampaign] : []),
      ]

      if (numberOfPartners === 1) {
        upcomingOptions.push(options.delete)
      }

      return upcomingOptions
    }

    // current campaigns
    return [
      ...(!currentCampaign.isSolo ? [options.managePartners] : []),
      options.preview,
      ...(canCreateSweepstakes ? [options.duplicateCampaign] : []),
      options.end,
      options.downloadEntries,
    ]
  }

  render() {
    const {
      currentCampaign: {
        id,
        analyticsLink,
        previewLink,
        isHost,
        endDate,
        isSolo,
      },
    } = this.props

    const showBuilderBtn = moment().diff(endDate, 'days') < 30

    return (
      <OverviewActions
        isHost={isHost}
        previewLink={previewLink}
        goToBuilder={() => this.context.editCampaign(id)}
        goToAnalytics={() => this.props.history.push(analyticsLink)}
        showBuilderButton={showBuilderBtn}
        options={this.options()}
        isSolo={isSolo}
      />
    )
  }
}

const mapStateToProps = state => ({
  currentCampaign: state.currentCampaign,
})

export default withRouter(connect(mapStateToProps)(OverviewActionsContainer))
