import React, { Component } from 'react'
import { Dialog } from 'react-toolbox/lib/dialog'
import DialogTheme from 'css/themes/dialogs/modalBase.css'
import {
  object,
  func,
  bool,
  string,
  oneOfType,
  array,
  node,
} from 'prop-types'

class SimpleInfoModal extends Component {
  static propTypes = {
    active: bool.isRequired,
    close: func.isRequired,
    title: string.isRequired,
    children: oneOfType([array, node, object, func]).isRequired,
    className: string,
  }

  static defaultProps = {
    className: null,
  }

  render() {
    const {
      active,
      close,
      title,
      children,
      className,
    } = this.props

    return (
      <Dialog
        className={className}
        theme={DialogTheme}
        active={active}
        onEscKeyDown={close}
        onOverlayClick={close}
        title={title}
      >
        {children}
      </Dialog>
    )
  }
}

export default SimpleInfoModal
