import React from 'react'
import { bool, string } from 'prop-types'
import { connect } from 'react-redux'

import FavoriteIconTheme from 'css/themes/favoriteIcon.css'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'

const mapState = ({
  currentBrand: {
    brandfavorites: favorites
  }
}) => ({ favorites })

class BrandFavoriteIcon extends React.Component {
  static propTypes = {
    brandId: string.isRequired,
    small: bool,
    exploreMediaMarketResults: bool,
  }

  static defaultProps = {
    exploreMediaMarketResults: false,
  }

  state = {
    selected: this.props.favorites.includes(this.props.brandId)
  }

  toggleFavorite = e => {
    e.preventDefault()

    const { brandId, favorites } = this.props
    CurrentBrandDispatcher.toggleFavorite(brandId, favorites);

    this.setState({
      selected: !this.state.selected
    })
  }

  render() {
    const { brandId, small, exploreMediaMarketResults } = this.props
    const { selected } = this.state

    return (
      <div style={{ position: 'relative', maxWidth: 1176, height: '100%', margin: '0 auto' }}>
        <div
          id={`favorite_icon_${brandId}`}
          className={`${FavoriteIconTheme.icon} ${small ? FavoriteIconTheme.smallIcon : ''} ${exploreMediaMarketResults ? FavoriteIconTheme.mediaMarketExploreIcon : ''}`}
          onClick={e => this.toggleFavorite(e)}
        >
          <img src="/images/icons/unselected-favorite.svg" />
          <img
            className={`${selected ? 'top' : FavoriteIconTheme.transparent}`}
            src="/images/icons/selected-favorite.svg"
          />
        </div>
      </div>
    )
  }
}

export default connect(mapState)(BrandFavoriteIcon)
