import React from 'react'
import propTypes from 'prop-types'
import injectSheet from 'react-jss'
import cn from 'classnames'

const styles = {
  checkboxContainer: {
    color: props => props.entryButtonStyles.formTextColor.color.color,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 11,
    lineHeight: '18px',
    marginBottom: 12,
    position: 'relative',
    userSelect: 'none',
    '& a': {
      color: props => props.entryButtonStyles.entryButtonColor.color.color,
    },
    '& input': {
      cursor: 'pointer',
      height: 14,
      opacity: 0,
      position: 'absolute',
      width: 14,
      '&:checked': {
        '& ~ div:first-of-type': {
          backgroundColor: props => props.entryButtonStyles.entryButtonColor.color.color,
          '&::after': {
            display: 'inline-block',
          },
        },
      },
    },
  },
  checkbox: {
    backgroundColor: 'transparent',
    border: props => `1px solid ${props.entryButtonStyles.entryButtonColor.color.color}`,
    height: 14,
    margin: '4px 12px 0px 0px',
    minWidth: 14,
    position: 'relative',
    width: 14,
    '&::after': {
      border: '1px solid',
      borderColor: props => props.entryButtonStyles.entryButtonText.color.color,
      borderLeft: 'none',
      borderTop: 'none',
      content: "''",
      display: 'none',
      height: 8,
      left: 4,
      position: 'absolute',
      top: 1,
      transform: 'rotate(45deg)',
      width: 5,
    },
  },
  optInLabel: {
    marginBottom: '11px',
  },
}

class Optin extends React.Component {
  static propTypes = {
    preCheck: propTypes.string.isRequired,
    entryButtonStyles: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
  }

  static contextTypes = {
    baseStyles: propTypes.object,
  }

  renderCheckBox = properties => {
    const { classes: css, entryButtonStyles } = this.props

    const { baseStyles } = this.context

    return (
      <label className={css.checkboxContainer}>
        <input
          type="checkbox"
          name={properties.name}
          checked={properties.preCheck}
          value={properties.preCheck}
        />
        <div className={cn('sweep', css.checkbox, baseStyles.optInCheckbox)} />

        {properties.label}
      </label>
    )
  }

  render() {
    let { preCheck } = this.props

    if (preCheck) {
      preCheck = true
    } else {
      preCheck = ''
    }

    const terms = (
      <span>
        I agree to the&nbsp;
        <a href="" target="_blank">
          Terms and Conditions.
        </a>
      </span>
    )
    const { classes: css } = this.props
    return (
      <div className={css.optInLabel}>
        {this.renderCheckBox({
          name: 'email_opt_in',
          label: terms,
          preCheck,
        })}
      </div>
    )
  }
}

export default injectSheet(styles)(Optin)
