const upperFirst = word => word.charAt(0).toUpperCase() + word.slice(1)

const templateDefault = {
  topLogo: {
    topLogoImg: {},
  },
  formContainer: {
    form: {},
    bottomInfo: {},
  },
  inputs: {
    label: {},
    input: {},
    selectPlaceholder: {},
  },
  submitButton: {
    button: {},
  },
  optIn: {
    checkbox: {},
  },
}

export const getTemplate = () => ({ ...templateDefault })

export const compileTemplate = template => {
  const formatted = {}
  Object.keys(template).forEach(component => {
    Object.keys(template[component]).forEach(element => {
      formatted[`${component}${upperFirst(element)}`] = template[component][element]
    })
  })
  return formatted
}
