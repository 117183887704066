import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import { H4 } from 'visual-components/util/texts'

import { MobileMarketingContext } from '../MobileMarketingContext'

function ReminderToPublishModal({
  campaignStartDate,
  campaignId,
  publishButtonRef,
}) {
  const {
    smsSend: { status = 'draft' },
  } = React.useContext(MobileMarketingContext)
  const [showModal, setShowModal] = useState(null)
  const startDate = moment(campaignStartDate)
  const twoDaysBefore = moment(startDate).subtract(48, 'hours')
  const key = `${campaignId}-${startDate.format('MM/DD/YYYY')}`
  const isDraft = status !== 'published'

  useEffect(() => {
    const modalDismissed = localStorage.getItem(key)

    if (modalDismissed || !isDraft) {
      setShowModal(false)
    } else if (showModal === null) {
      const show = moment().isBetween(twoDaysBefore, startDate, 'minutes', '[)')
      setShowModal(show)
    }
  }, [key, twoDaysBefore, startDate, showModal])

  const onDismiss = () => {
    localStorage.setItem(key, true)
    setShowModal(false)
  }

  const goToPublishButton = () => {
    setShowModal(false)
    publishButtonRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  return (
    <SimpleInfoModal
      title="Don't forget to publish your Conversion Action!"
      active={showModal}
    >
      <H4 multiline>
        Your sweepstakes is going live in two days! Be sure to publish your
        Conversion Action so you don&apos;t miss out on critical conversion
        opportunities.
        <br />
        <br />
        In the meantime, your sweepstakes will show &apos;Thank You Only&apos;
        as your post-entry action.
      </H4>
      <ModalButtonGroup
        canSave
        confirm={goToPublishButton}
        cancel={onDismiss}
        confirmText="Publish Now"
        cancelText="Dismiss"
        hideLine
      />
    </SimpleInfoModal>
  )
}

export default React.memo(withRouter(ReminderToPublishModal))

ReminderToPublishModal.propTypes = {
  campaignStartDate: PropTypes.object.isRequired,
  campaignId: PropTypes.number.isRequired,
  publishButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
}
