import React from 'react';

import Dialog from 'react-toolbox/lib/dialog';

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import dialogTheme from 'css/themes/dialogs/formModal.css'

const mapState = function (state) {
  return {
    upcomingCampaigns: state.upcomingCampaigns
  }
}
import CampaignDispatcher from 'dispatchers/campaign-dispatcher';



class ConfirmDeleteModal extends React.Component {

  confirmDeleteCampaign = () => {
    CampaignDispatcher.deleteFinal(this.props.upcomingCampaigns.campaignDeletingId);
    this.closeModal();
  }

  closeModal = () => {
    store.dispatch({
      model: 'upcomingCampaigns',
      type: 'SET_CAMPAIGN_DELETING',
      data: { id: null }
    })
  }

  render() {
    return (
      <div>
        <Dialog
          theme={ dialogTheme }
          active={ !!this.props.upcomingCampaigns.campaignDeletingId }
          onEscKeyDown={ this.props.closeModal }
          onOverlayClick={ this.props.closeModal }
          title='Confirm Delete Campaign'
        >
          <img
            onClick={ this.handleCancel }
            className={ dialogTheme.closeImage }
            src="/images/icons/close.svg"
          />
          <p
            style={{
              fontFamily: 'Larsseit',
              fontSize: '14px'
            }}>
            Are you sure you want to delete this campaign?
          </p>
          <ModalButtonGroup
            canSave={ true }
            confirm={ this.confirmDeleteCampaign }
            cancel={ this.closeModal }
            cancelText="Cancel"
            confirmText="Delete"
            hideLine
          />
        </Dialog>
      </div>
    )
  }
}


export default withRouter(connect(mapState)(ConfirmDeleteModal))
