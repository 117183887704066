import React, { Component } from 'react';
import _ from 'lodash';
import { Dialog, Checkbox, Button } from 'react-toolbox';
import cn from 'classnames';
import d3 from 'd3';

import { H4, P } from 'visual-components/util/texts';
import SearchInput from 'visual-components/util/inputs/search-input';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import COLOURS from 'util/colours';

import dialogTheme from 'css/themes/Dialog.css';
import CheckboxTheme from 'css/themes/Checkbox.css';
import ButtonTheme from 'css/themes/Buttons.css';
import ModalBaseTheme from 'css/themes/dialogs/formModal.css';
import theme from 'css/themes/referrals/import-contacts.css';

const currencyFormat = d3.format('$,.2f');

const checkboxTheme = {
  ...CheckboxTheme,
  field: theme.checkbox,
  text: theme.checkboxLabel,
};

export default class ImportContacts extends Component {
  state = {
    searchQuery: '',
    containerHeight: null
  };

  searchImportedContacts = _.debounce(query => {
    this.setState({ searchQuery: query });
    this.props.referralActions.searchImportedContacts(query);
  }, 50);

  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen !== this.props.modalOpen) {
      this.setState({ searchQuery: '' });
    }
  }

  setContainerHeight = (el) => {
    if (el) {
      this.setState({ containerHeight: el.clientHeight });
    }
  }

  sendInvites = (e) => {
    e.preventDefault();
    const emailList = this.props.selectedContacts.map(contact => contact.email)
    this.props.referralActions.sendGmailInvites(emailList);
  }

  renderContacts() {
    const {
      modalOpen,
      contactList,
      selectedContacts,
      filteredContactList,
      referralActions,
    } = this.props;

    if (
      this.state.searchQuery.length !== 0 &&
      this.props.filteredContactList === null
    ) {
      return <P>No contacts matched your search.</P>;
    }

    return (filteredContactList || contactList).map((contact, index) => (
      <li key={index} className={cn(theme.contact, 'clearfix')}>
        <Row>
          <Col xs={6}>
            <Checkbox
              label={contact.name}
              theme={checkboxTheme}
              labelStyles={{ display: 'inline', padding: '18px' }}
              checked={selectedContacts.includes(contact)}
              disabled={contact.status !== null}
              onChange={() => referralActions.toggleContact(contact)}
            />
          </Col>
          <Col xs={6}>
            <span
              className={cn(theme.email, {
                invited: contact.status !== null,
              })}
            >
              {contact.status || contact.email}
            </span>
          </Col>
        </Row>
      </li>
    ));
  }

  render() {
    const { modalOpen, selectedContacts, allSelected } = this.props;

    return (
      <Dialog
        theme={ModalBaseTheme}
        active={modalOpen}
        onEscKeyDown={this.props.referralActions.closeGmailModal}
        onOverlayClick={this.props.referralActions.closeGmailModal}
        title="Choose Friends to Invite"
      >
        <div>
          <img onClick={ this.props.referralActions.closeGmailModal } className={ ModalBaseTheme.closeImage } src="images/icons/close.svg" />
        </div>
        <H4 multiline className={theme.heading}>
          You’ll receive $25 in credit for each friend who signs up for
          DojoMojo.
        </H4>
        <Grid>
          <Row style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Col xs>
              <SearchInput
                value={this.state.searchQuery}
                placeholder="Search for a name or email address"
                onChange={this.searchImportedContacts}
                style={{ boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)' }}
              />
            </Col>
          </Row>
          <div className={theme.contactListContainer} ref={this.setContainerHeight} style={this.state.containerHeight ? { height: this.state.containerHeight } : {}}>
            <ul className={theme.contactList}>{this.renderContacts()}</ul>
          </div>
          <Row style={{ marginTop: '24px' }}>
            <Col xs={6}>
              <Checkbox
                onChange={this.props.referralActions.toggleCheckAllContacts}
                label="Check all"
                theme={checkboxTheme}
                checked={allSelected}
              />
            </Col>

            <Col xs={6}>
              <div className={theme.potentialCredit}>
                Potential Credit: {currencyFormat(
                  25 * selectedContacts.length
                )}
              </div>
            </Col>
          </Row>
          <div style={{ borderTop: `solid 1px ${COLOURS.silver}`, width: '672px', marginLeft: '-32px' }} />

          <Row end="xs" style={{ paddingTop: '16px' }}>
            <Col xs>
              <a className={theme.cancel} onClick={this.props.referralActions.closeGmailModal}>
                Cancel
              </a>
              <Button
                disabled={selectedContacts.length === 0}
                label={`Send ${selectedContacts.length} invite${selectedContacts.length !== 1 ? 's' : ''}`}
                primary
                onClick={this.sendInvites}
                theme={ButtonTheme}
                className={ButtonTheme.blueButton}
                raised
                style={{ boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', backgroundColor: selectedContacts.length === 0 ?'#b7c3cd' : '#03a9f4' }}
              />
            </Col>
          </Row>
        </Grid>
      </Dialog>
    );
  }
}
