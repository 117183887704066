import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

import EntryContributionCard from 'visual-components/analytics/campaign/entry-contribution-card';

class EntryContribution extends React.Component {
  render() {
    const {
      data,
      isHost,
      filterWebbula,
      started,
    } = this.props

    return (
      <EntryContributionCard
        data={data}
        isHost={isHost}
        filterWebbula={filterWebbula}
        started={started}
      />
    )
  }
}

const mapStateToProps = state => ({
  data: state.analyticsCampaign.campaignEntryContributionStatsByPartner,
  isHost: state.analyticsCampaign.isHost,
  filterWebbula: state.analyticsCampaign.filterWebbula,
})

export default connect(mapStateToProps)(EntryContribution);
