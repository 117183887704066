import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { H2, H3, H4 } from 'visual-components/util/texts'
import EditPen from 'visual-components/util/EditPen'

import CreateOpportunityModal from 'container-components/opportunities/create-opportunity-modal'

import OpportunityTheme from 'css/themes/opportunityProfile.css'

const typeMap = {
  product_shipment_insert: 'Box-Insert Swap',
  content_swap: 'Content Swap',
  dedicated_email_buy: 'Dedicated Email Buy',
  dedicated_email_sell: 'Dedicated Email Sale',
  prizing_partner: 'Prizing Partner',
  custom: 'Other',
}

const renderDetails = (fragment, idx) => (
  !fragment
    ? (
      <br key={idx} />
    ) : (
      <H4 key={idx} multiline>
        {fragment}
      </H4>
    )
)

class OpportunityDetailCard extends Component {
  static propTypes = {
    opportunityActions: PropTypes.object.isRequired,
    opportunity: PropTypes.object.isRequired,
    showPen: PropTypes.bool,
  }

  static defaultProps = {
    showPen: false,
  }

  state = {
    isMouseInside: false,
    modelActive: false,
  }

  handleToggle = () => this.setState(({ modelActive }) => ({ modelActive: !modelActive }))

  edit = () => {
    const { opportunity, opportunityActions } = this.props

    opportunityActions.edit(opportunity)
    this.handleToggle()
  }

  save = opportunity => {
    const { opportunityActions } = this.props

    opportunityActions.save(opportunity)
    this.handleToggle()
  }

  mouseEnter = () => this.setState({ isMouseInside: true })

  mouseExit = () => this.setState({ isMouseInside: false })

  render() {
    const {
      opportunity: {
        type,
        headline,
        details,
      },
      opportunityActions,
      showPen,
    } = this.props
    const { isMouseInside, modelActive } = this.state

    const title = typeMap[type] || 'Other'

    return (
      <div
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseExit}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <Card className={OpportunityTheme.detailsCard}>
          {
            showPen
              ? (
                <div style={{ visibility: isMouseInside ? 'visible' : 'hidden' }}>
                  <EditPen onClick={this.edit} />
                </div>
              ) : null
          }
          <div className={OpportunityTheme.opportunityType}>
            <H3 coral>
              { title }
            </H3>
          </div>
          <div className={OpportunityTheme.headline}>
            <H2>
              { headline }
            </H2>
          </div>
          { details.split('\n').map(renderDetails) }
        </Card>
        {
          showPen && (
            <CreateOpportunityModal
              editing
              forceStep={2}
              active={modelActive}
              handleToggle={this.handleToggle}
              opportunityActions={opportunityActions}
              persistEditOpportunity={this.save}
            />
          )
        }
      </div>
    )
  }
}

export default OpportunityDetailCard;
