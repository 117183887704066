/*
This is used to send page view analytics to Google.

For more info
- https://developers.google.com/analytics/devguides/collection/gtagjs/pages
- https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
*/


export const gtagPageTrack = pageParams => {
  const { page_title, page_path, location = {} } = pageParams
  const GA_MEASUREMENT_ID =  process.env.ga

  if (!GA_MEASUREMENT_ID) return

  const pp = page_path || `/app${location.pathname + location.search}`
  if (pp && window.gtag) {
    window.gtag('config', GA_MEASUREMENT_ID, {...(page_title && {page_title}), page_path: pp })
  }
}
