import Dashboard from './sections/dashboard'
import Choose from './sections/choose'
import Draft from './sections/draft'
import Review from './sections/review'
import Upload from './sections/upload'
import Sign from './sections/sign'
import Complete from './sections/complete'

export default [{
  key: 'dashboard',
  title: 'Dashboard',
  component: Dashboard,
}, {
  key: 'choose',
  title: 'Choose Template',
  component: Choose,
  template: true,
  custom: true,
}, {
  key: 'draft',
  title: 'Draft',
  actionTitle: 'Generate',
  component: Draft,
  template: true,
}, {
  key: 'upload',
  title: 'Upload',
  actionTitle: 'Confirm Partner',
  component: Upload,
  custom: true,
}, {
  key: 'review',
  title: 'Review',
  actionTitle: 'Share',
  component: Review,
  template: true,
}, {
  key: 'sign',
  title: 'Sign',
  actionTitle: 'Sign Agreement',
  component: Sign,
  template: true,
}, {
  key: 'completed',
  title: 'View Completed',
  component: Complete,
  template: true,
  custom: true,
}]
