import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { H3 } from 'visual-components/util/texts'
import { Card } from 'react-toolbox/lib/card'
import BlueTags from 'visual-components/util/tags/BlueTags.js'
import EditPen from 'visual-components/util/EditPen'

import CreateOpportunityModal from 'container-components/opportunities/create-opportunity-modal'

import OpportunityTheme from 'css/themes/opportunityProfile.css'

import * as OpportunityActions from 'actions/opportunities-actions'

const mapStateToProps = ({ opportunity }) => ({ opportunity })

const mapDispatchToProps = dispatch => ({
  opportunityActions: bindActionCreators(OpportunityActions, dispatch),
})

class OpportunityInfoCard extends Component {
  static propTypes = {
    opportunityActions: PropTypes.object.isRequired,
    opportunity: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    tags: PropTypes.array,
    showPen: PropTypes.bool,
  }

  static defaultProps = {
    showPen: false,
    tags: [],
  }

  state = {
    isMouseInside: false,
    modelActive: false,
  }

  handleToggle = () => this.setState(({ modelActive }) => ({ modelActive: !modelActive }))

  edit = () => {
    const { opportunityActions, opportunity } = this.props

    opportunityActions.edit(opportunity)
    this.handleToggle()
  }

  save = opportunity => {
    const { opportunityActions } = this.props

    opportunityActions.save(opportunity)
    this.handleToggle()
  }

  mouseEnter = () => this.setState({ isMouseInside: true })

  mouseExit = () => this.setState({ isMouseInside: false })


  render() {
    const { isMouseInside, modelActive } = this.state
    const {
      showPen,
      opportunityActions,
      children,
      tags,
    } = this.props

    return (
      <div
        onMouseLeave={this.mouseExit}
        onMouseEnter={this.mouseEnter}
        style={{ position: 'relative' }}
      >
        <Card className={OpportunityTheme.opportunityInfoCard}>
          {
            showPen
              ? (
                <div style={{ visibility: isMouseInside ? 'visible' : 'hidden' }}>
                  <EditPen onClick={this.edit} />
                </div>
              ) : null
          }
          <div style={{ marginBottom: '16px' }}>
            <H3 coral>
              Opportunity Info
            </H3>
          </div>
          <div>
            { children }
          </div>
          <div>
            <BlueTags tags={tags} noPadding />
          </div>
        </Card>
        {
          showPen && (
            <CreateOpportunityModal
              editing
              forceStep={1}
              active={modelActive}
              handleToggle={this.handleToggle}
              opportunityActions={opportunityActions}
              persistEditOpportunity={this.save}
            />
          )
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityInfoCard)
