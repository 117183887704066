import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import COLOURS from 'util/colours'
import { Small } from 'visual-components/util/texts'

import Badge from 'visual-components/util/badge'
import statusBadgeHelper from './util/statusBadgeHelper'

const ORB_COLOUR = {
  host: COLOURS.azure,
  active: COLOURS.seaGreen,
  inactive: COLOURS.alertRed,
  pendingPartner: COLOURS.turmeric,
  partnerBannerOffered: COLOURS.azure,
  partnerBannerShared: COLOURS.seaGreen,
  partnerBannerInactive: COLOURS.turmeric,
  partnerBannerDeclined: COLOURS.stone,
  partnerBannerRequested: COLOURS.cloudGrey,
}

const styles = {
  wrapper: {
    margin: 12,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    height: 24,
    borderRadius: 12.5,
    padding: '6px 12px',
    whiteSpace: 'nowrap',
  },
  orb: {
    borderRadius: '50%',
    height: 8,
    width: 8,
    marginRight: 4,
  },
  host: {
    backgroundColor: 'rgba(68,183,236,0.05)',
    border: `1px solid ${ORB_COLOUR.host}`,
  },
  active: {
    backgroundColor: 'rgba(20,203,168,0.05)',
    border: `1px solid ${ORB_COLOUR.active}`,
  },
  inactive: {
    backgroundColor: 'rgba(255,60,42,0.05)',
    border: `1px solid ${ORB_COLOUR.inactive}`,
  },
  partnerBannerOffered: {
    backgroundColor: 'rgba(68,183,236,0.05)',
    border: `1px solid ${ORB_COLOUR.host}`,
  },
  partnerBannerShared: {
    backgroundColor: 'rgba(20,203,168,0.05)',
    border: `1px solid ${ORB_COLOUR.active}`,
  },
  partnerBannerInactive: {
    backgroundColor: 'rgba(255,60,42,0.05)',
    border: `1px solid ${ORB_COLOUR.pendingPartner}`,
  },
  partnerBannerDeclined: {
    backgroundColor: 'rgba(71,84,100,0.1)',
    border: `1px solid ${ORB_COLOUR.partnerBannerDeclined}`,
  },
  partnerBannerRequested: {
    backgroundColor: 'rgba(183,195,205,0.05)',
    border: `1px solid ${ORB_COLOUR.partnerBannerRequested}`,
  },
}

// Disclaimer: badges have different content in the individual components
// later realized they sorta share the same content
const bannerBadges = {
  host: 'host',
  invited: 'offered',
  active: 'sharing',
  inactive: 'inactive',
  declined: 'sharing declined',
  partnerBannerOffered: 'offered from the host',
  partnerBannerShared: 'activated',
  partnerBannerInactive: 'pending - requires action',
  partnerBannerDeclined: 'request declined', // no percent next to name
  partnerBannerRequested: 'request pending', // no percent next to name
}

const decamelize = {
  notReady: 'not ready',
  notLive: 'not live',
  notInvited: 'not invited',
}

function SplitTrafficBadge(props) {
  const {
    classes: css,
    percentage,
    status,
    isStatus,
  } = props

  const formattedStatus = decamelize[status] || status
  if (isStatus) return <Badge nowrap {...statusBadgeHelper(formattedStatus)} />

  const hasPercent = !['partnerBannerDeclined', 'partnerBannerRequested'].includes(formattedStatus)
  const innerContent = bannerBadges[formattedStatus]
  const content = `${hasPercent ? `${percentage}% ` : ''}${innerContent}`

  if (!innerContent) return null

  return (
    <div className={cn(css.wrapper, css[formattedStatus])}>
      <div className={css.orb} style={{ backgroundColor: ORB_COLOUR[formattedStatus] }} />
      <Small>
        <em>
          {content}
        </em>
      </Small>
    </div>
  )
}

SplitTrafficBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  isHost: PropTypes.bool,
  isStatus: PropTypes.bool,
  percentage: PropTypes.number,
  status: PropTypes.string.isRequired,
}

SplitTrafficBadge.defaultProps = {
  isHost: false,
  isStatus: false,
  percentage: 0,
}

export default injectCSS(styles)(SplitTrafficBadge)
