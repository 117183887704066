import React, { PureComponent } from 'react'
import { P, H5 } from 'visual-components/util/texts'
import injectSheet from 'react-jss'
import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

const styles = {
  container: {
    border: '1px solid #dee4e5',
    marginTop: '32px',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'inline-block',
    marginRight: '12px',
    '& h5': {
      fontSize: '14px !important',
    },
  },
  description: {
    display: 'inline-block',
    marginRight: '24px',
  },
}

class ReferralBanner extends PureComponent {
  clickReferralButton = () => {
    const { campaignId, openPlatformInviteModal } = this.props
    openPlatformInviteModal({
      source_location: 'Campaign Builder Partners Tab',
      campaign_id: campaignId,
      showCampaignList: true,
    })
  }

  render() {
    const { classes: css } = this.props
    return (
      <div className={`${css.container}`}>
        <div className={css.title}>
          <H5>Invite New Brands</H5>
        </div>
        <div className={css.description}>
          <P>
            Do you know a brand that might be interested in this campaign? Invite them to DojoMojo!
          </P>
        </div>
        <Button
          theme={buttonTheme}
          className={buttonTheme.referralSmallGreen}
          label="Refer a Brand"
          raised
          onClick={this.clickReferralButton}
        />
      </div>
    )
  }
}

export default injectSheet(styles)(ReferralBanner)
