import React from 'react'
import { Tiny, H4 } from 'visual-components/util/texts'
import d3 from 'd3'
import MediaMarketAnalyticsTheme from 'css/themes/media-market/MediaMarketAnalyticsTheme.css'

const FlatRateStats = props => {
  const {
    isLive,
    expectedViews,
    status,
  } = props

  return (
    <React.Fragment>
      <div className={MediaMarketAnalyticsTheme.dealStatContainer} style={{ textAlign: 'left' }}>
        <div className={MediaMarketAnalyticsTheme.dealStatValue}>
          <H4 white={isLive && status === 'live'} style={{ fontSize: '24px' }}><em>{ d3.format(',')(expectedViews) }</em></H4>
        </div>
        <Tiny white={isLive && status === 'live'} style={{ textAlign: 'none' }}>Expected Reach</Tiny>
      </div>
    </React.Fragment>
  )
}

export default FlatRateStats
