import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Small } from 'visual-components/util/texts'
import SellerTheme from 'css/themes/seller-profile-tab.css'

class Chip extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
  }

  render() {
    const {
      text,
    } = this.props

    return (
      <div className={SellerTheme.chipTag}>
        <Small>
          { text }
        </Small>
      </div>
    )
  }
}

export default Chip
