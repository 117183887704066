import React from 'react';
import _ from 'lodash'

import EditAbout from 'visual-components/brandProfile/EditAbout';

import * as ReactRedux from 'react-redux';

import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    currentBrandForm: state.currentBrandForm
  };
}

class EditAboutContainer extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }
  save(data) {
    var tagsconnected = _.compact([data.tag1, data.tag2, data.tag3]);
    CurrentBrandDispatcher.updateAttr('brand_tag_0', data.tag1)
    CurrentBrandDispatcher.updateAttr('brand_tag_1', data.tag2)
    CurrentBrandDispatcher.updateAttr('brand_tag_2', data.tag3)
    CurrentBrandDispatcher.updateAttrPersist({
      tagsconnected: tagsconnected,
      description: data.description
    });
  }
  render() {

    var {
      description,
      brand_tag_0,
      brand_tag_1,
      brand_tag_2,
      website
    } = this.props.currentBrand;

    var { hovered } = this.props;

    return (
      <EditAbout
        description={ description }
        currentBrand={this.props.currentBrand}
        currentBrandForm={this.props.currentBrandForm}
        tag1={brand_tag_0}
        tag2={brand_tag_1}
        tag3={brand_tag_2}
        save={this.save}
        hovered={ hovered }
      />
    )
  }
}

export default ReactRedux.connect(mapState)(EditAboutContainer);
