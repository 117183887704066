import React from 'react';
import * as ReactRedux from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { Button } from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog';

import Field from 'visual-components/util/inputs/field';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import Validator from 'util/form-validator';
import AppDispatcher from 'dispatchers/app-dispatcher';

import * as PricingEliteActions from 'actions/pricing-elite-actions';

import DialogTheme from 'css/themes/dialogs/formModal.css';
import InputTheme from 'css/themes/Input.css';

const validations = {
  name: [Validator.required],
  email: [Validator.required],
  brandWebsite: [Validator.required],
  company: [Validator.required],
  message: [Validator.required]
}

const mapState = function (state) {
  return {
    profile: state.profile,
    brand: _.get(state, 'currentBrand.accountname', null),
    pricingElite: state.pricingElite,
    pricingEliteForm: state.pricingEliteForm,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PricingEliteActions, dispatch)
})

class PricingEliteModal extends React.Component {
  constructor(props) {
    super(props);

    this.hideModal = this.hideModal.bind(this);
    this.contactSupport = this.contactSupport.bind(this);
  }

  hideModal() {
    this.props.actions.closeModal();
  }

  contactSupport() {
    var {
      brand,
      pricingElite,
      pricingEliteForm,
    } = this.props;
    var that = this;
    Validator.validateForm(validations, pricingElite, 'pricingEliteForm', function (valid) {
      if (valid) {
        that.props.actions.postLeadRequest(pricingElite, brand);
        that.hideModal();
        toastr.success(`Thanks for reaching out - we'll get back to you soon!`, null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      }
    })
  }

  render() {
    var {
      profile,
      currentBrand,
      pricingElite,
      pricingEliteForm
    } = this.props;

    return(
      <Dialog
        theme={ DialogTheme }
        active={ !!pricingElite.modalOpen }
        onEscKeyDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
        title={ `Learn about Elite` }
      >
        <img
          onClick={ this.hideModal }
          className={ DialogTheme.closeImage }
          src="/images/icons/close.svg"
        />

        <Field label={ 'Contact Name*' }
          attrName={ 'name' }
          dataModel={ pricingElite }
          formModel={ pricingEliteForm }
          validations={ validations.name }
        />

        <Field label={ 'Contact Email*' }
          attrName={ 'email' }
          type={ 'email' }
          dataModel={ pricingElite }
          formModel={ pricingEliteForm }
          validations={ validations.email }
        />

        <Field label={ 'Brand*' }
          attrName={ 'company' }
          dataModel={ pricingElite }
          formModel={ pricingEliteForm }
          validations={ validations.company}
        />

        <Field label={ 'Brand Website*' }
          attrName={ 'brandWebsite' }
          dataModel={ pricingElite }
          formModel={ pricingEliteForm }
          validations={ validations.brandWebsite }
        />

        <Field label={ 'Message*' }
          attrName={ 'message' }
          multiline={ true }
          rows={ 10 }
          placeholder={ 'What are you looking to achieve with DojoMojo?' }
          dataModel={ pricingElite }
          formModel={ pricingEliteForm }
          validations={ validations.message }
          className={ InputTheme.textarea }
        />

        <ModalButtonGroup
          canSave={ true }
          confirm={ this.contactSupport }
          confirmText={ 'Send Message' }
          cancel={ this.hideModal }
        />
      </Dialog>
    )
  }
}

export default ReactRedux.connect(mapState, mapDispatchToProps)(PricingEliteModal);
