import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import { Card } from 'react-toolbox/lib/card'
import { Small } from 'visual-components/util/texts'
import PlainTextOfferMessageTheme from 'css/themes/media-market/PlainTextOfferMessage.css'

class PlainTextOfferMessage extends PureComponent {
  static propTypes = {
    content: string.isRequired,
    isSender: bool.isRequired,
  }

  render() {
    const {
      isSender,
      content,
    } = this.props

    const themes = {
      mainTheme: isSender ? PlainTextOfferMessageTheme.senderMain
        : PlainTextOfferMessageTheme.receiverMain,
    }

    return (
      <Card className={themes.mainTheme}>
        <div className={PlainTextOfferMessageTheme.container}>
          <Small multiline>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Small>
        </div>
      </Card>
    )
  }
}

export default PlainTextOfferMessage
