import React, { Component } from 'react'
import { Form, Input, Submit, } from 'visual-components/util/form'
import { H3, H4 } from 'visual-components/util/texts'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import CSAdminTheme from 'css/themes/CS-Admin.css'
import { isNum } from 'visual-components/util/form/validations'
import DefaultDialog from 'visual-components/util/modals/default-dialog'
import AppDispatcher from 'dispatchers/app-dispatcher'


class CustomerSuccessMarkBrandsInterested extends Component {
    state = {
        brandInvite: {},
        campaign: {},
        campaignId: 0,
        brandId: 0,
        minDelivery: {},
        minConfirmDialog: false,
        markInterestedConfirmDialog: false,
    }

    handleChange = (event) => {
        this.setState(event)
    }

    handleFindCampaign = (campaignId, brandId) => {
        AppDispatcher.http({
            url: `/cs-get-campaign-brands/${campaignId}/${brandId}`,
            method: 'GET',
            success: (campaign) => {
                campaign && this.setState({ campaign })
                campaign.invites.length > 0 && this.setState({ brandInvite: campaign.invites[0] })
            },
            error: () => {
                toastr.error('Campaign/Brand Invite not found', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
            }
        })
    }

    markInterested = (partnershipInviteId) => {
        AppDispatcher.http({
            url: `/cs-mark-interested/${partnershipInviteId}`,
            method: 'PUT',
            success: () => {
                toastr.success('Marked Interested', null, { timeOut: 1000, positionClass: 'toast-bottom-center' });
                this.handleFindCampaign(this.state.campaignId, this.state.brandId)
                this.toggleDialog('markInterestedConfirmDialog')
            },
            error: () => {
                toastr.error('Error Marking Interest', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
            }
        })
    }

    handleSetMinimumEntries = (partnershipInviteId) => {
        const { minDelivery } = this.state

        AppDispatcher.http({
            url: `/cs-set-min-entries/${partnershipInviteId}`,
            method: 'PUT',
            data: { minDelivery },
            success: () => {
                toastr.success('Minimum Entries Updated', null, { timeOut: 1000, positionClass: 'toast-bottom-center' });
                this.handleFindCampaign(this.state.campaignId, this.state.brandId)
                this.toggleDialog('minConfirmDialog')
            },
            error: () => {
                toastr.error('Error Setting Minimum Entries', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })
            }
        })
    }

    toggleDialog = (key) => {
        this.setState({ [key] : !this.state[key] })
    }

    render() {
        const { campaign, brandInvite } = this.state

        const brandMap = brandInvite && brandInvite.invitee && (
            <Card key={brandInvite.id} >
                    <div className={ CSAdminTheme.section }>
                        <div className={ CSAdminTheme.textInfo }><H4>Campaign Name: <strong>{campaign.name}</strong></H4></div>
                        <div className={ CSAdminTheme.textInfo }><H4>Campaign Id: {campaign.id}</H4></div>
                    </div>
                    <div className={ CSAdminTheme.section }>
                        <div className={ CSAdminTheme.textInfo }><H4>Brand Name: <strong>{brandInvite.invitee.accountname}</strong></H4></div>
                        <div className={ CSAdminTheme.textInfo }><H4>Brand Id: {brandInvite.invitee_id}</H4></div>
                    </div>
                    <div className={ CSAdminTheme.section}>
                        <div className={ CSAdminTheme.textInfo }><H4>Partnership Invite Id: {brandInvite.id}</H4></div>
                        <div className={ CSAdminTheme.textInfo }><H4>Status: <strong>{brandInvite.status}</strong></H4></div>
                        <div className={ CSAdminTheme.textInfo }><H4>Minimum Entries: <strong>{ brandInvite.minimumDelivery }</strong></H4></div>
                    </div>

                    <div className={ CSAdminTheme.section }>
                        <Button
                            onClick= { () => this.toggleDialog('markInterestedConfirmDialog') }
                            theme={ ButtonTheme }
                            label="Mark Interested"
                            disabled = { brandInvite.status === 'interested' || brandInvite.status === 'accepted' ? true : false }
                        />

                        <DefaultDialog
                            active={this.state.markInterestedConfirmDialog}
                            closeModal={ () => this.toggleDialog('markInterestedConfirmDialog')}
                            confirm={ () => this.markInterested(brandInvite.id) }
                            confirmText="Mark Interested"
                            title="Mark Interested Confirmation"
                            children={
                                <div>Are you sure you want to mark interested?</div>
                            }
                        />
                    </div>

                    <div className={ CSAdminTheme.section }>
                        <Form
                            handleSubmit={ () => this.toggleDialog('minConfirmDialog')  }
                            updateModel={ (e) => this.handleChange(e) }
                        >
                            <Input
                                name="minDelivery"
                                label="Enter Minimum Entries"
                                type="number"
                                validations={[isNum]}
                            />

                            <Submit label="Set Entries"/>
                        </Form>

                        <DefaultDialog
                            active={this.state.minConfirmDialog}
                            closeModal={ () => this.toggleDialog('minConfirmDialog') }
                            confirm={ () => this.handleSetMinimumEntries(brandInvite.id) }
                            confirmText="Set Minimum Entries"
                            title="Set Minimum Entries Confirmation"
                            children={
                                <div>Are you sure you want to set entries?</div>
                            }
                        />
                    </div>
                </Card>
        )

        return(
            <div className={ CSAdminTheme.container }>
                <div className={ CSAdminTheme.card }>
                    <Card>
                        <div className={ CSAdminTheme.section }>
                            <H3>Brand - Mark Interested/Set Min Entries</H3>
                            <Form
                                handleSubmit={ () => this.handleFindCampaign(this.state.campaignId, this.state.brandId) }
                                updateModel={ (e) => this.handleChange(e) }
                            >
                                <Input
                                    name="campaignId"
                                    label="Campaign Id"
                                    type="number"
                                    validations={[isNum]}
                                />

                                <Input
                                    name="brandId"
                                    label="Brand Id"
                                    type="number"
                                    validations={[isNum]}
                                />

                                <Submit label="Find Brand" />
                            </Form>
                        </div>
                    </Card>
                </div>

                <div className={ CSAdminTheme.card }>
                    { brandMap }
                </div>
            </div>
        )
    }

}



export default CustomerSuccessMarkBrandsInterested;
