import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  SimpleForm, Input, Select, Checkbox, Validations,
} from 'visual-components/util/form'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'
import { H3, H4 } from 'visual-components/util/texts'
import CcpaTheme from 'css/themes/ccpa-request.css'
import * as DataDisclosureActions from 'actions/data-disclosure-actions'

const mapStateToProps = ({ currentBrand, profile }) => ({ currentBrand, profile })
const mapDispatchToProps = dispatch => ({
  dataDisclosureActions: bindActionCreators(DataDisclosureActions, dispatch),
})

class BrandCcpaRequest extends Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    dataDisclosureActions: PropTypes.object.isRequired,
  }

  state = {
    regulation: 'ccpa',
    email: '',
    type: '',
    agree: false,
    recaptcha: false,
    complete: false,
  }

  componentDidMount() {
    const { currentBrand, profile } = this.props
    if (currentBrand.id) {
      this.setState({
        brand_id: currentBrand.id,
        brand_name: currentBrand.accountname,
        brand_contact_name: profile.fullName
          || `${profile.firstName || ''} ${profile.lastName || ''}`,
        brand_contact_email: profile.email,
      })
    }
  }

  onUpdate = ({ ...rest }) => {
    this.setState(rest)
  }

  onSubmit = () => {
    const { dataDisclosureActions } = this.props
    dataDisclosureActions.submit(this.state)
    this.setState({
      complete: true,
    })
  }

  onClose = () => {
    window.location = '/'
  }

  recaptcha = value => {
    this.setState({
      recaptcha: value,
    })
  }

  render() {
    const { recaptcha, complete } = this.state

    const options = [{
      label: 'Request an account of your stored personal information',
      value: 'request',
    }, {
      label: 'Opt-out of sales of your personal information',
      value: 'opt-out',
    }, {
      label: 'Delete your personal information',
      value: 'delete',
    }]

    return (
      <div className={CcpaTheme.container}>
        <Helmet>
          <title>DojoMojo - Brand Initiated CCPA Data Requests Form</title>
        </Helmet>
        <div className={CcpaTheme.header}>
          <img src="/images/icons/close.svg" onClick={this.onClose} />
          <H3>
            Brand Initiated CCPA Data Requests
          </H3>
        </div>
        <div className={CcpaTheme.content}>
          <div className={CcpaTheme.copy}>
            {complete && (
              <H3>
                Thank You
              </H3>
            )}
            <H4 multiline>
              {complete
                ? 'Your CCPA Data Request has been received. Further instructions have been sent to the brand contact email address provided.'
                : 'This form is intended for use ONLY by DojoMojo customer brands. All requests submitted through this form should be verified by the brand with which the original CCPA request was submitted.'}
            </H4>
          </div>
          <div className={CcpaTheme.form}>
            {complete ? (
              <ModalButtonGroup
                confirm={this.onClose}
                confirmText="Close"
                canSave
                hideLine
              />
            ) : (
              <SimpleForm
                model={this.state}
                updateModel={data => {
                  if (!data.recaptcha && recaptcha) return
                  this.onUpdate(data)
                }}
                handleSubmit={this.onSubmit}
              >
                <H3 coral>
                  <small>
                    Brand Information
                  </small>
                </H3>
                <Input
                  name="brand_name"
                  label="Brand"
                  disabled
                />
                <Input
                  name="brand_contact_name"
                  label="Brand Contact (Full Name)"
                  disabled
                />
                <Input
                  name="brand_contact_email"
                  label="Brand Contact (Email)"
                  disabled
                />
                <H3 coral>
                  <small>
                    California Resident Information
                  </small>
                </H3>
                <Input
                  name="email"
                  label="Email"
                  placeholder="name@dojomojo.com"
                  validations={[Validations.isEmail]}
                  required
                />
                <Select
                  name="type"
                  label="What Is the Nature of Your Request?"
                  options={options}
                  required
                />
                <Checkbox
                  name="agree"
                  label="I confirm that the individual associated with the email address provided submitted a CCPA request to my organization and that my organization properly verified the identity and California residency of this individual."
                  boldLabel
                  required
                />
                <div className={CcpaTheme.recaptcha}>
                  <ReCAPTCHA
                    sitekey="6LfwyskUAAAAAI97wmr7cZWconvQ4PlyHRJlyUu0"
                    onChange={this.recaptcha}
                  />
                </div>
                <ModalButtonGroupWithContext
                  confirmText="Submit Request"
                  cancel={this.onClose}
                  canSave={!!recaptcha}
                  hideLine
                />
              </SimpleForm>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandCcpaRequest)
