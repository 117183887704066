import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { P } from 'visual-components/util/texts'

const styles = {
  conditionPreview: {
    '& p': {
      marginBottom: 8,
      '&:last-child': {
        marginBottom: 16,
      },
    },
  },
}

function FollowupConditionPreview(props) {
  const {
    classes: css,
    message,
  } = props

  const {
    send_interval = 2,
    unit_of_time = 'day',
  } = message

  return (
    <div className={css.conditionPreview}>
      <P><em>Sending</em></P>
      <P>{`${send_interval} ${unit_of_time}${send_interval > 1 ? 's' : ''} after previous message`}</P>
    </div>
  )
}

FollowupConditionPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(FollowupConditionPreview))
