import moment from 'moment-timezone'

const defaultPlanPrices = {
  white: 0,
  greenFixed: 199,
  'essential-12months-upfront': 159,
  'essential-buy3-get1-upfront': 149,
  black: 499,
  'premium-12month-upfront': 399,
  'premium-buy3-get1-upfront': 375,
}

const defaultBadgeState = {
  white: null,
  greenFixed: null,
  black: null,
}

// Controls display of badgets in the Pricing Page
const availableBadges = {
  white: [],
  greenFixed: [
    {
      start:
        process.env.SENTRY_ENV === 'production'
          ? moment.tz('2019-12-01', 'America/New_York')
          : moment.tz('2019-11-01', 'America/New_York'),
      end:
        process.env.SENTRY_ENV === 'production'
          ? moment.tz('2019-12-31', 'America/New_York').endOf('day')
          : moment.tz('2019-11-30', 'America/New_York').endOf('day'),
      label: '25% Holiday Discount',
      eligibleStripeBelts: ['white', 'greenFixed', 'black'],
    },
  ],
  black: [
    {
      start:
        process.env.SENTRY_ENV === 'production'
          ? moment.tz('2019-12-01', 'America/New_York')
          : moment.tz('2019-11-01', 'America/New_York'),
      end:
        process.env.SENTRY_ENV === 'production'
          ? moment.tz('2019-12-31', 'America/New_York').endOf('day')
          : moment.tz('2019-11-30', 'America/New_York').endOf('day'),
      label: '25% Holiday Discount',
      eligibleStripeBelts: ['white', 'greenFixed', 'black'],
    },
  ],
}

// Add promotions here (controls the pricing page columns and full screen purchase modal)
const availablePromotions = {
  'premium-12month-upfront': [
    {
      applicableDates: {
        start:
          process.env.SENTRY_ENV === 'production'
            ? moment.tz('2019-12-01', 'America/New_York')
            : moment.tz('2019-11-01', 'America/New_York'),
        end:
          process.env.SENTRY_ENV === 'production'
            ? moment.tz('2019-12-31', 'America/New_York').endOf('day')
            : moment.tz('2019-11-30', 'America/New_York').endOf('day'),
      },
      eligibleStripeBelts: ['white', 'greenFixed', 'black'],
      label: 'Premium Plan w/ Holiday Discount - $375/month (billed annually)',
      planAmount: 4491,
      monthlyPrice: 375,
    },
  ],
  'essential-12months-upfront': [
    {
      applicableDates: {
        start:
          process.env.SENTRY_ENV === 'production'
            ? moment.tz('2019-12-01', 'America/New_York')
            : moment.tz('2019-11-01', 'America/New_York'),
        end:
          process.env.SENTRY_ENV === 'production'
            ? moment.tz('2019-12-31', 'America/New_York').endOf('day')
            : moment.tz('2019-11-30', 'America/New_York').endOf('day'),
      },
      eligibleStripeBelts: ['white', 'greenFixed', 'black'],
      label: 'Essential Plan w/ Holiday Discount - $149/month (billed annually)',
      planAmount: 1788,
      monthlyPrice: 149,
    },
  ],
}

// Iterate through default plans array, find any applicable promotions available, and override with promo information
export const applyPlanPromos = (plansArr, stripeBelt) =>
  plansArr.map(plan => {
    if (availablePromotions[plan.value]) {
      const promo = availablePromotions[plan.value].find(({ applicableDates, eligibleStripeBelts }) =>
        moment().isBetween(applicableDates.start, applicableDates.end) && eligibleStripeBelts.includes(stripeBelt))
      return promo ? { ...plan, label: promo.label } : plan
    }
    return plan
  })

// Go through summary plan map object, find any appliable promotions available, and override with promo information
export const applySummaryPromos = (summaryMap, stripeBelt) => {
  const plansObj = { ...summaryMap }
  Object.keys(plansObj).forEach(planKey => {
    if (availablePromotions[planKey]) {
      const promo = availablePromotions[planKey].find(({ applicableDates, eligibleStripeBelts }) =>
        moment().isBetween(applicableDates.start, applicableDates.end) && eligibleStripeBelts.includes(stripeBelt))
      if (promo) {
        plansObj[planKey] = {
          ...plansObj[planKey],
          planAmount: promo.planAmount,
          plan: promo.label,
        }
      }
    }
  })
  return plansObj
}

// Generate latest plan prices (monthly) including any promotional rates
export const getCurrentPlanPrices = (stripeBelt) => {
  const latestPlanPrices = { ...defaultPlanPrices }
  Object.keys(latestPlanPrices).forEach(plan => {
    const promo = availablePromotions[plan]
      && availablePromotions[plan].find(({ applicableDates, eligibleStripeBelts }) =>
        moment().isBetween(applicableDates.start, applicableDates.end) && eligibleStripeBelts.includes(stripeBelt))
    if (promo) latestPlanPrices[plan] = promo.monthlyPrice
  })
  return latestPlanPrices
}

// Generate latest badges for Pricing Plan page
export const getCurrentBadgeState = (stripeBelt) => {
  const latestBadgeState = { ...defaultBadgeState }
  Object.keys(latestBadgeState).forEach(plan => {
    const badge = availableBadges[plan]
      && availableBadges[plan].find(({ start, end, eligibleStripeBelts }) => moment().isBetween(start, end) && eligibleStripeBelts.includes(stripeBelt))
    if (badge) latestBadgeState[plan] = badge.label
  })
  return latestBadgeState
}
