import { useRef, useState, useEffect } from 'react'
import axios from 'axios'

const DEFAULTS = {
  scrollOffset: 0.9,
  delay: 1000,
}

let hook_unmounted = false

function useImageSearch(opts, isGIF = false) {
  const options = { ...DEFAULTS, ...opts }
  const { scrollOffset, delay, headers } = options
  const requestRef = useRef(null)
  const timeoutRef = useRef(null)
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')

  const requestImages = async () => {
    try {
      setLoading(true)

      const { data } = await axios.post(
        `/background-images/${isGIF ? 'gif-search' : 'stock-search'}`,
        {
          page,
          query,
          type: 'Image',
        },
        { headers }
      )

      const promises = data.data.map(
        async imgObj =>
          new Promise(res => {
            const imgElement = document.createElement('img')
            imgElement.src = imgObj.src
            imgElement.onload = () => res(imgObj)
            imgElement.onerror = () => res(null)
          })
      )

      const loadedImages = await Promise.all(promises)

      if (hook_unmounted) return

      setImages(imgs => [...imgs, ...loadedImages.filter(Boolean)])
    } catch (err) {
      console.log(err) // TODO: Add a 'onError' callback ???
    }

    setLoading(false)
  }

  useEffect(() => {
    clearTimeout(requestRef.current)
    requestRef.current = setTimeout(requestImages, delay)
    return () => clearTimeout(requestRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, delay])

  useEffect(() => {
    setPage(1)

    if (query) {
      setQuery(query)
      clearTimeout(requestRef.current)
      requestRef.current = setTimeout(() => {
        setImages([])
        requestImages()
      }, delay)
    }

    return () => clearTimeout(requestRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, delay])

  useEffect(() => {
    hook_unmounted = false
    return () => (hook_unmounted = true)
  }, [])

  const onScroll = ({ target }) => {
    if (loading) return

    const { scrollTop, offsetHeight, scrollHeight } = target
    const showNextPage = scrollTop / (scrollHeight - offsetHeight) > scrollOffset

    if (showNextPage) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => setPage(p => p + 1), delay)
    }
  }

  const onPageChange = newPage => {
    if (newPage - 1 <= 0) return setPage(0)

    return setPage(newPage)
  }

  return {
    onScroll,
    onQueryChange: setQuery,
    onPageChange,
    query,
    images,
    loading,
  }
}

export default useImageSearch
