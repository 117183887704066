import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import COLOURS from 'util/colours'
import { Tiny } from 'visual-components/util/texts'

class BlueTag extends Component {
  static propTypes = {
    tag: PropTypes.string.isRequired,
    fitOnOneLine: PropTypes.bool,
  }

  static defaultProps = {
    fitOnOneLine: false,
  }

  render() {
    const { tag, fitOnOneLine } = this.props
    return (
      <div
        style={{
          display: 'inline-flex',
          backgroundColor: COLOURS.iceBlue,
          borderRadius: '2px',
          marginRight: '4px',
          marginBottom: '4px',
          maxWidth: fitOnOneLine ? '90px' : 'auto',
        }}
      >
        <div style={{ padding: '5px 5px 3px' }}>
          {
            fitOnOneLine
              ? (
                <Tiny overflowEllipsis>
                  { tag }
                </Tiny>
              )
              : (
                <Tiny>
                  { tag }
                </Tiny>
              )
          }
        </div>
      </div>
    )
  }
}

export default BlueTag
