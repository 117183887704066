import React, { Component } from 'react';
import HomeBanner from './home-banner';
import OneStopHome from './one-stop-home';
import TrustedBy from '../util/trusted-by';
import Countup from './countup';
import WhatTheySay from '../util/what-they-say';
import StartGrowingYourList from '../util/start-growing-your-list';
import Features from './features';
import Integrations from './integrations';
import InThePress from '../util/in-the-press';
import MobileFooter from '../mobile-footer/MobileFooter';
import queryString from 'query-string';
import { createCookie, getCookie } from 'util/cookie';
import validQueryParams from 'util/valid-query-params';

class MobileHome extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
    const { location: { search }} = this.props;
    const parsedQueryString = queryString.parse(search);

    validQueryParams.forEach(param => {
      if (parsedQueryString[param] && !getCookie(param)) {
        createCookie(param, parsedQueryString[param], 7)
      }
    })
  }

  render() {
    return (
      <div>
        <HomeBanner />
        <OneStopHome />
        <TrustedBy />
        <Countup />
        <WhatTheySay
          quote={ '“DojoMojo is an effective tool that has brought transparency to our sweepstakes acquisition campaigns, helping us scale this channel tremendously and hit our quarterly targets. We couldn’t imagine our acquisition efforts without it.”' }
          who={ 'Lindsay Fitzgerald' }
          position={ 'Marketing Partnerships Account Manager' }
          where={ 'General Assembly' }
          link={ 'https://generalassemb.ly/' }
          brandImg={ '/images/brand-logos/general-assembly-logo.svg' }
          title={ 'What Marketers Are Saying' }
        />
        <StartGrowingYourList />
        <Features />
        <InThePress />
        <MobileFooter />
      </div>
    )
  }
};

export default MobileHome;
