import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Tab, Tabs } from 'react-toolbox'
import COLOURS from 'util/colours'
import injectSheet from 'react-jss'
import { H3 } from 'visual-components/util/texts'
import FullscreenModalTheme from 'css/themes/modals/FullscreenModalTheme.css'
import cn from 'classnames'
import EntryFieldsTab from './form/editModalTabs/EntryFieldsTab'
import LegalTab from './form/editModalTabs/LegalTab'
import StyleTab from './form/editModalTabs/StyleTab'
import SoloSmsStyleTab from './form/editModalTabs/SoloSmsStyleTab'
import SmsMarketingTab from './form/editModalTabs/SmsMarketingTab'

const modalTransitions = {
  modalHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '13px',
  },
  closeButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '20px',
    marginTop: '20px',
  },
  modalBackground: {
    width: '672px',
    height: '656px',
    marginLeft: '-32px',
    backgroundColor: 'white',
  },
  modalBackgroundSolo: {
    width: 'auto',
    heigth: '656px',
  },
  tabContent: {
    height: '575px',
    overflowY: 'auto',
    textAlign: 'left',
    marginTop: '25px',
    overflowX: 'hidden',
    marginRight: ({ isSoloCampaign }) => isSoloCampaign && '32px',
  },
  tablContentSolo: {
    marginRight: 32,
  },
  navTabs: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  barPointer: {
    backgroundColor: '#64ffda',
    height: '4px',
    marginTop: '-4px',
  },
  tab: {
    padding: '11px 24.5px',
    fontSize: '16px',
    textTransform: 'none',
    color: COLOURS.ink,
    fontFamily: 'Larsseit-Light',
    fontWeight: '300',
  },
  tabActive: {
    fontFamily: 'Larsseit',
    fontSize: '16px',
    fontWeight: 'normal',
    textTransform: 'none',
    padding: '11px 24.5px',
    lineHeight: '1',
    color: COLOURS.ink,
  },
}

class FormEditModal extends Component {
  static propTypes = {
    agreeToLiability: propTypes.func.isRequired,
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    customFonts: propTypes.array.isRequired,
    closeModal: propTypes.func.isRequired,
    elements: propTypes.object.isRequired,
    isHost: propTypes.bool,
    termsAndConditionsForm: propTypes.object.isRequired,
    updateElementsAttr: propTypes.func.isRequired,
    updateAttr: propTypes.func.isRequired,
    isSoloCampaign: propTypes.bool.isRequired,
  }

  static defaultProps = {
    isHost: undefined,
  }

  state = {
    fixedIndex: 0,
  }

  handleFixedTabChange = index => {
    this.setState({ fixedIndex: index })
  }

  render() {
    const {
      classes: css,
      closeModal,
      isSoloCampaign,
    } = this.props

    const { fixedIndex } = this.state

    return (
      <div className={cn(css.modalBackground, { [css.modalBackgroundSolo]: isSoloCampaign })}>
        <div className={`${FullscreenModalTheme.closeButton} ${css.closeButton}`}>
          <img src="/images/icons/close.svg" onClick={closeModal} />
        </div>
        <span className={css.modalHeader}>
          <H3 ink>Edit Content</H3>
        </span>
        <Tabs
          index={fixedIndex}
          theme={{
            pointer: css.barPointer,
            navigation: css.navTabs,
            tab: cn(css.tabContent, { [css.tablContentSolo]: isSoloCampaign }),
          }}
          onChange={this.handleFixedTabChange}
          disableAnimatedBottomBorder
        >
          <Tab
            className={css.tab}
            activeClassName={css.tabActive}
            label="Entry Fields"
            disableAnimatedBottomBorder
          >
            <EntryFieldsTab {...this.props} />
          </Tab>
          <Tab className={css.tab} activeClassName={css.tabActive} label="Legal">
            <LegalTab {...this.props} />
          </Tab>
          {isSoloCampaign && (
            <Tab
              className={css.tab}
              activeClassName={css.tabActive}
              label="SMS Marketing"
            >
              <SmsMarketingTab {...this.props} />
            </Tab>
          )}
          <Tab className={css.tab} activeClassName={css.tabActive} label="Style">
            {isSoloCampaign ? (
              <SoloSmsStyleTab {...this.props} isHost />
            ) : (
              <StyleTab {...this.props} />
            )}
          </Tab>
        </Tabs>
      </div>
    )
  }
}

export default injectSheet(modalTransitions)(FormEditModal)
