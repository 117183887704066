import React from 'react';

import Dialog from 'react-toolbox/lib/dialog';

import Field from 'visual-components/util/inputs/field';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';

import ButtonTheme from 'css/themes/Buttons.css'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const themes = {
  Button: ButtonTheme,
  Dialog: DialogTheme,
}

class InviteUserModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      newBrandInvite,
      newBrandInviteForm,
      modalOpen,
      toggleModal,
      inviteUser,
      accountname,
      validations
    } = this.props;

    return(
      <Dialog
        theme={ themes.Dialog }
        active={ modalOpen }
        onEscKeyDown={ toggleModal }
        onOverlayClick={ toggleModal }
        title={ `Invite a User to ${accountname}` }
      >
        <img
          onClick={ toggleModal }
          className={ themes.Dialog.closeImage }
          src="/images/icons/close.svg" 
        />

        <Field label={ 'First Name*' }
          attrName={ 'firstName' }
          dataModel={ newBrandInvite }
          formModel={ newBrandInviteForm }
          validations={ validations.firstName }
        />

        <Field label={ 'Last Name*' }
          attrName={ 'lastName' }
          dataModel={ newBrandInvite }
          formModel={ newBrandInviteForm }
          validations={ validations.lastName }
        />

        <Field label={ 'Email*' }
          attrName={ 'email' }
          type={ 'email' }
          dataModel={ newBrandInvite }
          formModel={ newBrandInviteForm }
          validations={ validations.email }
        />

        <ModalButtonGroup 
          canSave={ true } 
          confirm={ inviteUser }
          confirmText={ 'Send Message' }
          cancel={ toggleModal } 
        />
      </Dialog>
    )
  }
}

export default InviteUserModal;
