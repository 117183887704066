import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import MobileDetect from 'mobile-detect'

import AppDispatcher from 'dispatchers/app-dispatcher';
import FieldDispatcher from 'dispatchers/field-dispatcher';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import { getSessionId } from 'util/metrics'

const ProfileDispatcher = _.extend({}, AppDispatcher, {
  updateAttrPersist: function (attr, value) {
    AppDispatcher.http({
      method: 'PUT',
      data: {
        [attr]: value
      },
      url: '/profile'
    })
    store.dispatch({
      model: 'profile',
      data: {
        [attr]: value
      },
      type: 'UPDATE_ATTR'
    });
  },
  impersonate: (uid, brandId) => {
    window.open(`/app?impersonating=${uid}&brandId=${brandId}`, '_blank')
  },
  load: function (overrideBrandId) {
    const impersonating = sessionStorage.getItem('impersonating')
    if (impersonating && impersonating !== 'null') {
      $.ajaxSetup({ headers: { impersonating } })
    }

    const sessionId = getSessionId()
    $.ajaxSetup({ headers: { 'X-Metrics-Session': sessionId } })

    AppDispatcher.load({
      model: 'profile',
      endpoint: '/profile',
      success: function (data) {
        const brandId = overrideBrandId
          || sessionStorage.getItem(data.uid)
          || localStorage.getItem(data.uid)

        if (Number(brandId)) {
          $.ajaxSetup({ headers: { brand: brandId } })
        }

        var emailName = data.email.split('@')[0];
        var firstName = data.firstName;
        var lastName = data.lastName;
        var name = `${firstName || ''} ${lastName || ''}`;
        var displayName = name !== ' ' ? name : emailName;

        /* Define isAdmin to be:
          1) If not production, then true
          2) If production, email must be able to impersonate, anotherwords has a DB record in impersonators table
        */
        const isAdmin =
          process.env.SENTRY_ENV !== 'production' ||
          (data && data.canImpersonate)

        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'profile',
          data: {
            displayName,
            isAdmin,
          }
        })

        // Configure Sentry user profile in staging and prod only
        if (!!process.env.SENTRY_ENV) {
          Sentry.configureScope(scope => {
            scope.setUser({
              id: data.id,
              email: data.email,
              name: displayName,
              impersonatorId: data.impersonator,
              impersonatorEmail: data.impersonatorEmail,
            })
            scope.setTag('brand', brandId)
            scope.setTag('transaction_id', sessionId)
          })
        }

        // Fire FB Pixel custom event to indicate user has logged in
        if (window.fbq) {
          fbq('trackCustom', 'LoggedIn')
        }


        setTimeout(function () {
          var $zopim = window.$zopim || undefined;
          if ($zopim) {
            var name = data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : data.email.split('@')[0];
            $zopim(function() {
              $zopim.livechat.setName(name)
              $zopim.livechat.setEmail(data.email);
            })
          }
        }, 10000)

        // Store filtered brands all at once
        if (data.credentials && data.credentials.length) {
          const filteredBrands = data.credentials.reduce((brands, { brand }) => {
            if (!brand.disabled) {
              brands.push(brand)
            }
            return brands
          }, [])

          store.dispatch({
            type: 'LOAD_BRANDS',
            model: 'userBrands',
            data: filteredBrands,
          })
        }

        CurrentBrandDispatcher.load();

        store.dispatch({
          model: 'card',
          type: 'UPDATE_ATTR',
          data: {
            name: data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : data.email.split('@')[0]
          }
        });
      }
    });
  },
  updateCredential: function(rid, uid, data, credentials) {
    const credentialIndex = _.findIndex(credentials, { uid: uid });
    const newValue = _.merge({}, credentials[credentialIndex], data);
    credentials[credentialIndex] = newValue;
    FieldDispatcher.updateAttr(rid, { credentials: credentials });
  }
});

export default ProfileDispatcher;
