import React from 'react'
import { Link } from 'react-router-dom'
import homeTheme from 'css/themes/homePage/home.css'
import COLOURS from 'util/colours'
import Header from '../header/Header'
import EmailCollector from '../util/email-collector'
import { Button } from 'react-toolbox/lib/button/Button'
import ButtonTheme from 'css/themes/Buttons.css'

const starsIcon = (
  <svg width="20" height="20" style={{ marginTop: '0' }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0438 12.9562C11.0813 11.9937 7.5 11.5875 7.5 11.25C7.5 10.9125 11.0813 10.5062 12.0438 9.54375C13.0062 8.58125 13.4125 5 13.75 5C14.0875 5 14.4937 8.58125 15.4562 9.54375C16.4187 10.5062 20 10.9125 20 11.25C20 11.5875 16.4187 11.9937 15.4562 12.9562C14.4937 13.9187 14.0875 17.5 13.75 17.5C13.4125 17.5 13.0062 13.9187 12.0438 12.9562Z" fill="white"/>
    <path d="M4.92333 6.74333C4.41 6.23 2.5 6.01333 2.5 5.83333C2.5 5.65333 4.41 5.43667 4.92333 4.92333C5.43667 4.41 5.65333 2.5 5.83333 2.5C6.01333 2.5 6.23 4.41 6.74333 4.92333C7.25667 5.43667 9.16667 5.65333 9.16667 5.83333C9.16667 6.01333 7.25667 6.23 6.74333 6.74333C6.23 7.25667 6.01333 9.16667 5.83333 9.16667C5.65333 9.16667 5.43667 7.25667 4.92333 6.74333Z" fill="white"/>
    <path d="M4.545 15.455C4.28833 15.1983 3.33333 15.09 3.33333 15C3.33333 14.91 4.28833 14.8017 4.545 14.545C4.80167 14.2883 4.91 13.3333 5 13.3333C5.09 13.3333 5.19833 14.2883 5.455 14.545C5.71167 14.8017 6.66667 14.91 6.66667 15C6.66667 15.09 5.71167 15.1983 5.455 15.455C5.19833 15.7117 5.09 16.6667 5 16.6667C4.91 16.6667 4.80167 15.7117 4.545 15.455Z" fill="white"/>
  </svg>
)

class HomepageBanner extends React.Component {
  render() {
    return (
      <div className={homeTheme.banner}>
        <Header />
        <div className={homeTheme.smsBannerContainer}>
          <div className={homeTheme.leftTail}>
            <img src="/images/login/homepage/left-tail.svg" />
          </div>
          <span className={homeTheme.smsBanner}>
            <span className={homeTheme.bannerTitle}>
              <div className={homeTheme.bannerNewTag}>
                NEW
              </div>
              Find new customers and lower your Meta Ads CPA by 30% with
              &nbsp;
              <a
                href="https://www.proxima.ai/dojomojo"
                className={homeTheme.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                Proxima AI Audiences
              </a>
              &nbsp;
              {starsIcon}
            </span>
          </span>
          <div className={homeTheme.rightTail}>
            <img src="/images/login/homepage/right-tail.svg" />
          </div>
        </div>
        <div className={homeTheme.containerHome}>
          <div className={homeTheme.rightBannerCol}>
            <div className={homeTheme.wrapper}>
              <p className={homeTheme.title}> One-Stop-Shop Acquisition and Engagement </p>
              <p className={homeTheme.tag}>
                Build your audience base through our powerful acquisition network and
                leverage advanced SMS messaging features to engage with your customers.
              </p>
              <a href="/app/register">
                <Button
                  label="Sign Up and Start Growing"
                  className={ButtonTheme.orangeButton}
                  theme={ButtonTheme}
                  floating
                />
              </a>
            </div>
          </div>
          <div className={homeTheme.leftBannerCol}>
            <img src="/images/login/homepage/homepage-hero-sms.svg" />
          </div>
        </div>
        <div className={homeTheme.bottomBackground} />
      </div>
    )
  }
}

export default HomepageBanner
