import React from 'react';
import _, { debounce } from 'lodash'
import TinyMCE from 'react-tinymce';
import PrimaryButton from 'util/components/buttons/primary-button';
import SecondaryButton from 'util/components/buttons/secondary-button';
import COLOURS from 'util/colours'
import DefaultDialog from 'visual-components/util/modals/default-dialog'
import {H4} from 'visual-components/util/texts'
import injectSheet from 'react-jss'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'

const highlight = function(str) {
  return str.replace(/{{([A-Za-z]+)}}/g, '<strong><span style="background-color: #e5f3fd;">{{$1}}</span></strong>');
}

const unhighlight = function(str) {
  str = str.replace(/<strong><span style="background-color: #e5f3fd;"(\sdata-mce-style="background-color: #e5f3fd;")?>/g, '')
  str = str.replace(/<\/span><\/strong>/g, '')
  return str;
}

const defaultOptIn = 'By entering the sweepstakes, I agree to the sweepstakes’ Official Rules. I also agree to receive email communication and special offers from {{hostBrandName}}{{partnerBrandNames}}.'

const transitions = {
  preview: {
    color: '#2c3541',
    fontSize: '14px',
    fontFamily: 'Larsseit-Light',
    lineHeight: '1.57',
    marginBottom: '12px',
    marginTop: '20px',
    display: 'flex',
    borderLeft: `8px ${COLOURS.iceBlue} solid`,
    paddingLeft: '16px',
  },
  buttonArrangement: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}
class EditorWithValidations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      temporaryValue: this.props.dataModel[this.props.attrName],
      preview: this.props.displayValue,
      showWarningModal: false,
      ignoreBracketValidations: false,
      mergeFieldRegexp: new RegExp("{{(" + this.props.mergeFields.join('|') + ")}}", "g"),
    }
    this.setup = this.setup.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.revertBrackets = this.revertBrackets.bind(this);
    this.ignoreValidations = this.ignoreValidations.bind(this);
  }

  closeModal() {
    this.setState({
      showWarningModal: false
    })
  }

  setup(e) {
    e.contentCSS.push('//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css');
    e.contentCSS.push('/css/font/font.css');
    e.contentCSS.push('/css/font.css');
    e.contentCSS.push('/web/admin.css');
  }

  handleEditorChange(e, editor) {
    let bracketRegex = this.state.mergeFieldRegexp
    let newValue = unhighlight(editor.getContent());
    let originalValueBrackets = this.state.temporaryValue.match(bracketRegex) ? this.state.temporaryValue.match(bracketRegex).length : 0;
    let newValueBrackets = newValue.match(bracketRegex) ? newValue.match(bracketRegex).length : 0;
    let valid = originalValueBrackets <= newValueBrackets;
    if(valid || this.state.ignoreBracketValidations) {
      this.handlePreview(newValue)
      this.setState({
        temporaryValue: newValue
      })
    } else {
      this.setState({
        invalidValue: newValue,
        showWarningModal: true
      })
    }
  }

  onClickCancel() {
    const { attrName, dataModel, onCancelAction } = this.props
    const { temporaryValue } = this.state
    if(temporaryValue !== dataModel[attrName]) {
      onCancelAction(true)
      return
    }
    this.setState({
      temporaryValue: dataModel[attrName]
    })
    if (this.props.onCancelAction) {
      this.props.onCancelAction();
    }
  }

  onClickSave() {
    let value = this.state.temporaryValue
    this.props.updateAttr(this.props.attrName, value);
    if (this.props.onCancelAction) {
      this.props.onCancelAction();
    }
  }

  revertBrackets() {
    tinyMCE.activeEditor.setContent(highlight(this.state.temporaryValue));
    this.setState({
      showWarningModal: false
    })
  }

  ignoreValidations() {
    this.setState({
      ignoreBracketValidations: true,
      showWarningModal: false,
      temporaryValue: this.state.invalidValue
    })
  }

  handlePreview = debounce(preview => {
    preview = _.unescape(
      preview
        .replace(/<\/?p>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&rsquo;/g, "'")
    )
    if (this.props.mergeData) {
      _.each(this.props.mergeData, (value, key) => {
        const mergeField = new RegExp(`{{${key}}}`, 'g')
        preview = preview.replace(mergeField, value)
      })
    }
    this.setState({ preview })
  }, 300)

  restoreDefault = () => {
    const { updateAttr, attrName } = this.props
    ConfirmationDispatcher.check({
      header: 'Restore Default',
      copy:
        'Do you want to discard these changes?',
      confirmText: 'Restore',
      cancelText: 'Go Back',
      action: () => {
        updateAttr(attrName, defaultOptIn)
        ConfirmationDispatcher.closeModal()
        this.onClickCancel()
      },
    })
  }
  render() {
    const buttonStyles = [{
      width: '62px',
      minWidth: '62px',
      height: '36px',
      minHeight: '36px',
      fontFamily: 'Larsseit-Medium',
      fontSize: '14px',
      border: '0px',
      marginTop: '24px',
    }]

    const modalButtonStyles = buttonStyles.concat({
      height: '40px',
      minHeight: '40px'
    })

    const { classes: css } = this.props

    return (
      <div id="editFormModal">
        <TinyMCE
          content={highlight(this.state.temporaryValue)}
          config={{
            menubar: false,
            statusbar: false,
            toolbar: false,
            setup: this.setup,
            body_class: 'with-padding',
            paste_as_text: true,
            selector: 'textarea',
            // content_style: "p {border: 5px solid green;}",
            plugins: 'paste autoresize',
            mode : "textareas",
            autoresize_max_height: 110,
          }}
          onChange={this.handleEditorChange}
          onKeyup={this.handleEditorChange}
        />
        <div
          className={css.preview}
        >
          {this.state.preview}
        </div>
        <div className={css.buttonArrangement}>
          <div>
            <PrimaryButton
              onClick={ this.onClickSave }
              styles={ buttonStyles.concat({ marginRight: '20px' }) }
              blueButton={ true }
              >
              Save
            </PrimaryButton>
            <SecondaryButton
              onClick={ this.onClickCancel }
              styles={ buttonStyles }
              blueButton={ true }
              >
              Cancel
            </SecondaryButton>
          </div>
          <SecondaryButton
            onClick={ this.restoreDefault }
            styles={ buttonStyles.concat({ width: 'auto' }) }
            blueButton={ true }
          >
            Restore Default
          </SecondaryButton>
        </div>
        <DefaultDialog
          active={this.state.showWarningModal}
          closeModal={this.revertBrackets}
          title={'Warning!'}
          confirm={this.ignoreValidations}
          confirmText={'Edit Anyways'}
          cancel={this.revertBrackets}
          cancelText={'Cancel'}
          bannerImgSrc={ '/images/icons/celebration-icon.svg'}
          children={
            <div>
              <div className={`${dialogTheme.bannerWrapper} ${dialogTheme.orangeBanner}`}>
                <img src="/images/icons/error-message-icon.svg" />
              </div>
              <H4 multiline>
                {`If you edit the text within {{brackets}}, you'll be breaking DojoMojo's ability to auto-update these key fields in the future, and will need to manually update them yourself.`}
              </H4>
            </div>
          }
        />

        {/*}<DojoModal
          isOpen={ this.state.showWarningModal }
          hideModal={ this.revertBrackets }
          className="warningModal"
          title='Warning!'
        >
          <div className="row">
            <div className="col-md-12">
              <div className="row"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
              }}>
                <div className="col-md-12">
                  <Text.dojoP>
                    {`If you edit the text within {{brackets}}, you'll be breaking DojoMojo's ability to auto-update these key fields in the future, and will need to manually update them yourself.`}
                  </Text.dojoP>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-md-12">
                  <SecondaryButton
                    onClick={this.revertBrackets}
                    styles={ modalButtonStyles.concat({ marginRight: '20px' }) }
                    blueButton={ true }
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={this.ignoreValidations}
                    styles={ modalButtonStyles }
                    blueButton={ true }
                  >
                    Got it
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </DojoModal>*/}
      </div>
    )
  }
}

export default injectSheet(transitions)(EditorWithValidations)
