import React from 'react'

import HintText from './hint-text'
import AgreementThemes from 'css/themes/agreement/agreement-item.css'

class EditableNumber extends React.Component {
  render() {
    const {
      parent,
      name,
      value,
      text,
      placeholder,
      included,
      edit,
      updateValue,
      noRightSpace,
      hasError
    } = this.props

    return (
      <div className={ `${AgreementThemes.inputHolder} ${noRightSpace ? AgreementThemes.noRightSpace : ''} ${hasError ? AgreementThemes.hasError : ''}` }>
        {
          included ?
          <input type={ 'number' } name={ name }
            placeholder={ placeholder }
            defaultValue={ value }
            onChange={ e => updateValue(parent, name, e.target.value) }
          />
          :
          <HintText text={ text } edit={ edit } />
        }
      </div>
    )
  }
}

export default EditableNumber
