import React, { PureComponent as Component } from 'react'
import { P, Tiny } from 'visual-components/util/texts'

import {
  instanceOf,
  string,
  bool,
} from 'prop-types'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates'
import DateRangeSortWithDisplay from 'visual-components/shared/filters/DateRangeSortWithDisplay'
import AnalyticsTheme from 'css/themes/analytics.css'
import moment from 'moment-timezone'

const timeFrameMap = {
  0: 'Today',
  1: 'Yesterday',
  3: 'Last 3 Days',
  7: 'Last 7 Days',
  14: 'Last 14 Days',
  28: 'Last 28 Days',
  'All Time': 'All Time',
  // 'Custom': 'Custom'
}

const getDefaultOptions = (timeFrame, startDate, endDate) => {
  const displayEl = (
    <div className={AnalyticsTheme.customLabel}>
      <div style={{ marginBottom: '12px' }}>
        <Tiny coral>
          { timeFrameMap[timeFrame] }
        </Tiny>
      </div>
      <P>
        <i>
          {`${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`}
        </i>
      </P>
    </div>
  )

  const defaultOptions = [
    {
      value: 'null',
      disabled: true,
      label: displayEl,
    },
    {
      value: 0,
      label: 'Today',
    },
    {
      value: 1,
      label: 'Yesterday',
    },
    {
      value: 3,
      label: 'Last 3 Days',
    },
    {
      value: 7,
      label: 'Last 7 Days',
    },
    {
      value: 14,
      label: 'Last 14 Days',
    },
    {
      value: 28,
      label: 'Last 28 Days',
    },
    {
      value: 'All Time',
      label: 'All Time',
    },
    // {
    //   value: 'Custom',
    //   label: 'Custom'
    // }
  ]

  return defaultOptions
}

class MediaDateRangePicker extends Component {
  static propTypes = {
    oldestDate: instanceOf(Date).isRequired,
    timeFrame: string,
    displayed: bool,
    alignLeft: bool,
  }

  static defaultProps = {
    timeFrame: 'All Time',
    displayed: false,
    alignLeft: false,
  }

  handleFilterChange = (name, val) => {
    const {
      dealStartDate,
      dealEndDate,
    } = this.props

    const data = { [name]: val }
    let endDate = moment()
    let startDate = moment().subtract(val - 1, 'days')

    if (val === 'Custom') {
      return this.handleCustomVals()
    }

    if (val === 'All Time') {
      endDate = moment(dealEndDate)
      startDate = moment(dealStartDate)
    } else if (val === 0) {
      // today
      endDate = moment().endOf('day')
      startDate = moment().startOf('day')
    } else if (val === 1) {
      // yesterday
      endDate = moment().subtract(1, 'days').endOf('day')
      startDate = moment().subtract(1, 'days').startOf('day')
    }

    this.datesChange({ ...data, startDate, endDate })

    return null
  }

  handleCustomVals = () => {
    this.updateDatePicker({
      displayed: true,
      focusedInput: 'startDate',
      startDate: null,
      endDate: null,
      timeFrame: 'Custom',
    })
  }

  datesChange = data => {
    this.props.selectDateFunc(data)
  }

  onDatePickerClose = () => {
    const { startDate, endDate } = this.props

    /* if you haven't chosen a valid date range, it defaults back to all time */
    if (!startDate || !endDate) {
      this.handleFilterChange('timeFrame', 'All Time')
    } else {
      this.datesChange({ startDate, endDate })
    }

    this.updateDatePicker({ displayed: false })
  }

  onFocusChange = focusedInput => {
    if (!focusedInput) return // doesn't update the focusedInput if it is trying to close the DRP
    this.updateDatePicker({ focusedInput })
  }

  render() {
    const {
      timeFrame,
      displayed,
      startDate,
      endDate,
      alignLeft,
      dealStartDate,
      oldestDate,
      focusedInput,
    } = this.props

    const timeSinceDealStarted = (moment().diff(dealStartDate, 'days')) + 1

    const defaultOptions = getDefaultOptions(timeFrame, startDate, endDate)
      .filter(obj => obj.value <= timeSinceDealStarted || obj.value === 'All Time' || obj.value === 'null')

    return (
      <div style={{ height: '40px', padding: '9px 0px' }}>
        <DateRangeSortWithDisplay
          onChange={this.handleFilterChange}
          source={defaultOptions}
          name="timeFrame"
          value={timeFrame}
        />
        <div style={{ position: 'relative' }}>
          <div
            id="alwaysOpen"
            style={{
              display: displayed ? null : 'none',
              left: alignLeft ? -200 : 79,
            }}
          >
            {
              displayed
                ? (
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={this.updateDatePicker}
                    focusedInput={focusedInput}
                    onFocusChange={this.onFocusChange}
                    anchorDirection="right"

                    navPrev={<img src="/images/icons/left-arrow.svg" />}
                    navNext={<img src="/images/icons/right-arrow.svg" />}
                    hideKeyboardShortcutsPanel

                    keepOpenOnDateSelect
                    onClose={this.onDatePickerClose}
                    displayFormat="MMM DD, YYYY"
                    isOutsideRange={
                      oldestDate
                        ? day => !isInclusivelyAfterDay(day, moment(oldestDate)) || !isInclusivelyBeforeDay(day, moment())
                        : day => !isInclusivelyBeforeDay(day, moment())
                    }
                  />
                )
                : null
            }
          </div>
        </div>
      </div>
    )
  }
}

export default MediaDateRangePicker
