import { PureComponent } from 'react';
import { Link } from "react-router-dom";

import { number, string } from 'prop-types'
// import moment from 'moment-timezone';
// import d3 from 'd3';
import { Card } from 'react-toolbox/lib/card';
import { H1, H3, H6, P, Small } from 'visual-components/util/texts';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { makeNumbersPretty } from 'util/makeNumbersPretty';
import DisplayedStat from 'visual-components/analytics/util/displayed-stat';
import StatsPie from './stats-pie';

export default class StatsCard extends PureComponent {
  static propTypes = {
    totalEntries: number.isRequired,
    visits: number.isRequired,
    estimatedEntries: number.isRequired,
    ntf: number.isRequired,
    entriesDriven: number.isRequired,
    daysCompleted: number.isRequired,
    totalDays: number.isRequired,
    tasksCompleted: number.isRequired,
    totalTasks: number.isRequired,
    campaignId: string.isRequired,
  }
  render() {
    const {
      totalEntries,
      visits,
      estimatedEntries,
      ntf,
      entriesDriven,
      daysCompleted,
      totalDays,
      tasksCompleted,
      totalTasks,
      campaignId,
    } = this.props;

    return (
      <div style={{
        maxWidth: '200px',
        position: 'relative',
      }}>
        <Card style={{
          width: '100%',
          paddingTop: '16px',
          paddingRight: '20px',
          paddingBottom: '16px',
          paddingLeft: '20px',
          height: '320px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)'

        }}>
          <H3 coral>
            <small>
              Stats
            </small>
          </H3>
          <Grid style={{marginTop: '12px'}}>
            <Row>
              <Col>
                <div style={{ display: 'inline-block' }}>
                  <H1
                    azure
                  >
                    { makeNumbersPretty(totalEntries) }
                  </H1>
                </div>
                <div style={{
                  display: 'inline-block',
                  paddingLeft: '4px'
                }}>
                  <Small>
                    total entries
                  </Small>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }} between="xs">
              <DisplayedStat
                name={ 'visits' }
                value={ visits }
              />
              <DisplayedStat
                name={ 'est. entries' }
                value={ estimatedEntries }
              />
            </Row>
            <Row between="xs">
              <DisplayedStat
                name={ 'NTF emails' }
                value={ ntf }
              />
              <DisplayedStat
                name={ 'entries driven' }
                value={ entriesDriven }
              />
            </Row>
            <Row>
              <Col xs={6} style={{textAlign: 'center'}}>
                <StatsPie
                  total = { totalDays }
                  completed = { daysCompleted }
                  label = { 'days left'}
                />
              </Col>
              <Col xs={6} style={{textAlign: 'center'}}>
                <StatsPie
                  total = { totalTasks }
                  completed = { tasksCompleted }
                  label = {`task${totalTasks - tasksCompleted === 1 ? '' : 's'} left`}
                />
              </Col>
            </Row>
            <Row style={{marginTop: '13px'}}>
              <Link to={`/analytics/campaigns/${campaignId}`}>
                <H6 azure>
                  See full analytics
                </H6>
              </Link>
            </Row>
          </Grid>
        </Card>
      </div>
    )
  }
}
