import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { messagingRelativeDate } from 'util/relativeDate';

class MessagingRowItemActions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { timestamp, archiveOption, archiveFunction } = this.props;
    let formattedTimestamp = messagingRelativeDate(timestamp);

    return (
      <Row style={{
        borderBottom: '1px solid #dee4e5',
        padding: '12px',
        paddingLeft: '0px',
        height: '100%'
      }}>
        <Col xs={12} style={{
          textAlign: 'right'
        }}>
          <p style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '12px',
            marginTop: '2px'
          }}>
            { formattedTimestamp }
          </p>
         { archiveOption ? 
            <img src="/images/icons/messaging-icons/archive-conversation-icon.svg" 
              onClick={ archiveFunction } 
              style={{
                marginTop: '20px',
                width: '16px',
                cursor: 'pointer'
            }}/>
          : null 
          }
        </Col>
      </Row>
    )
  }
}

export default MessagingRowItemActions;