import React, { Component } from 'react'
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import { bindActionCreators } from 'redux'
import Dialog from 'react-toolbox/lib/dialog';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';
import { withRouter } from 'react-router-dom';
import RichEditor from 'visual-components/richEditor/RichEditor';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import MessagingDispatcher from 'dispatchers/messaging-dispatcher';
import { stateToHTML } from 'draft-js-export-html';
import modalTheme from 'css/themes/dialogs/modalBase.css';
import * as MessagingPartnerActions from 'actions/messaging-partner-actions'

class DeclinePartnershipModal extends Component {
  hideModal = () => {
    store.dispatch({
      model: 'conversations',
      type: 'UPDATE_ATTR',
      data: {
        showPartnershipDeclineModal: Object.assign({}, this.props.showModal, { active: false })
      }
    })
  }

  nav = (showModal) => {
    this.props.history.push( `/explore/campaigns/${showModal ? showModal.campaignId : null}` )
  }

  updateMessageText = (editorState) => {
    this.setState({editorState: editorState})
  }

  sendMessage = () => {
    let { editorState } = this.state;
    const { actions } = this.props
    let contentState = editorState.getCurrentContent();

    MessagingDispatcher.sendMessage({
      conversationId: _.find(
        (this.props.showModal.application ? this.props.conversations.receivedApplications : this.props.conversations.receivedInvitations), { partnershipinvite_id: this.props.showModal.partnershipinvite_id }
      ).conversation_id,
      content: stateToHTML(contentState)
    })

    actions.reloadConversations()
    this.hideModal();
  }

  render(){ 
    var { showModal } = this.props;

    showModal = showModal || {};
    return (
      <Dialog
        active={ !!showModal.active }
        onEscDown={ this.hideModal }
        onOverlayClick={ this.hideModal }
      >
        <img
          onClick={ this.hideModal }
          className={ modalTheme.closeImage }
          src="/images/icons/close.svg"
        />
        { showModal && showModal.active ?
            <div>
              <Row>
                <Col xs={12} style={{
                  textAlign: 'center',
                  marginTop: '6px',
                  marginBottom: '16px'
                }}>
                  <p style={{
                    fontSize: '20px',
                    fontFamily: 'Larsseit-Medium'
                  }}>
                    { showModal.application ? `You've Declined ${showModal.inviteeName}'s Application` : `You've Declined ${showModal.hostBrandName}'s Invitation`}
                  </p>
                </Col>
              </Row>
              <Row style={{
                marginTop: '16px'
              }}>
                <Col xs={12}>
                  <p style={{
                    fontFamily: 'Larsseit-Light',
                    fontSize: '16px',
                    lineHeight: '24px'
                  }}>
                    You’ve declined this { showModal.application ? 'application' : 'invite' }. Send an optional message below to let them know why it wasn’t a fit at this time.
                  </p>
                </Col>
              </Row>
              <RichEditor
                onChange={ this.updateMessageText }
                placeholder={ showModal.application ? 'Sorry, your list size at this time does not fit the audience size we’re looking for.' : 'Sorry, our promotional calendar is full for this month.' }
              />
              <ModalButtonGroup 
                canSave={ true }
                hideLine={ true }
                confirm={ this.sendMessage }
                confirmText="Send"
              />
            </div>
          :
            null
        }
      </Dialog>
    )
  }
}


const mapState = (state) => {
  return {
    showModal: state.conversations.showPartnershipDeclineModal,
    conversations: state.conversations
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(MessagingPartnerActions, dispatch)
})

export default withRouter(ReactRedux.connect(mapState, mapDispatchToProps)(DeclinePartnershipModal));
