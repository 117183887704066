import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { H2 } from 'visual-components/util/texts'

import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'
import * as MetricsActions from 'actions/analytics-actions'

const placesToBeIncluded = [
  'Partnership Analytics',
  'Campaign Analytics',
  'Overview',
]

const stateToProps = ({ currentBrand }) => ({ currentBrand })

const dispatchToProps = dispatch => ({
  metricsActions: bindActionCreators(MetricsActions, dispatch),
})

class ExplorePageTitle extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    action: PropTypes.func,
    actionLabel: PropTypes.string,
    secondaryAction: PropTypes.func,
    secondaryActionLabel: PropTypes.string,
    history: PropTypes.object.isRequired,
    metricsActions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
  }

  static defaultProps = {
    action: null,
    actionLabel: '',
    secondaryAction: null,
    secondaryActionLabel: '',
  }

  inviteAndTrack = () => {
    const { action, actionLabel } = this.props

    if (actionLabel === 'Invite Brand') {
      EventTrackingDispatcher.track('appExploreBrands_invite_invite_inviteButton')
    }

    action()
  }

  onRoiButtonClick = () => {
    const { history, metricsActions, location } = this.props

    metricsActions.create('ROINavigated', { meta: { from: location.pathname } })

    history.push('/calculate-roi')
  }

  render() {
    const {
      title,
      action,
      actionLabel,
      secondaryAction,
      secondaryActionLabel,
      secondaryPrimaryColor,
      currentBrand: {
        features,
        belt,
      },
    } = this.props

    const buttonStyle = {
      display: 'inline-block',
      marginTop: '-13px',
      float: 'right',
    }

    const secondaryButtonStyle = {
      marginRight: '12px',
    }

    const secondaryButtonClass = secondaryPrimaryColor
      ? classnames(ButtonTheme.blueButton, ButtonTheme.flat, ButtonTheme.neutral)
      : ButtonTheme.whiteButton


    return (
      <div>
        <div style={{ display: 'inline-block', width: 645 }}>
          <H2
            azure
            overflowEllipsis
            multiline
          >
            { title }
          </H2>
        </div>
        {
          action
            ? (
              <Button
                onClick={this.inviteAndTrack}
                label={actionLabel}
                className={ButtonTheme.greenButton}
                theme={ButtonTheme}
                raised
                style={buttonStyle}
              />
            )
            : null
        }
        {
          secondaryAction
            ? (
              <Button
                onClick={secondaryAction}
                label={secondaryActionLabel}
                className={secondaryButtonClass}
                theme={ButtonTheme}
                style={{ ...buttonStyle, ...secondaryButtonStyle  }}
                raised
              />
            )
            : null
        }
        {
          (features.roiCalculator || belt === 'white')
          && placesToBeIncluded.includes(title)
            ? (
              <Button
                onClick={this.onRoiButtonClick}
                label="Calculate Your ROI"
                className={`${ButtonTheme.raised} ${ButtonTheme.blueButton}`}
                theme={ButtonTheme}
                style={{
                  display: 'inline-block',
                  float: 'right',
                  marginTop: '-13px',
                  marginRight: (action || secondaryAction) ? '8px' : '0px',
                }}
              />
            ) : null
        }
      </div>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(ExplorePageTitle))
