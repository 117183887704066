import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';
import moment from 'moment-timezone'

import { Card } from 'react-toolbox/lib/card';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import CampaignPreviewSpecs from './my-campaign-preview-specs';
import CampaignPreviewPartnerList from './campaign-preview-partners-list';
import CampaignPreviewBlueCards from './campaign-preview-blue-cards';
import CampaignPreviewActions from './my-campaign-preview-actions';
import Badge from 'visual-components/util/badge';

class CampaignDetailCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      campaignId,
      name,
      campaignPictureSrc,
      type,
      impressions,
      visits,
      entries,
      estEntries,
      partnerImages,
      startDate,
      endDate,
      hostBrandImage,
      actions,
      style,
      needsPartnerSignature,
    } = this.props;

    campaignPictureSrc = campaignPictureSrc && campaignPictureSrc.endsWith('main.png')
      ? campaignPictureSrc.replace('main.png', 'thumb.png') : campaignPictureSrc

    const isVideo = campaignPictureSrc && campaignPictureSrc.endsWith('.mp4')

    return(
      <Card style={{
        width: '368px',
        display: 'inline-flex',
        marginRight: '16px',
        marginBottom: '12px',
        overflow: 'visible'
      }}>
        <Grid style={{
          marginLeft: '0'
        }}>
          <Link to={`/explore/campaigns/${campaignId}/details`}>
            <Row>
              <div style={{
                width: '100%',
                height: '120px',
                background: `url(${campaignPictureSrc}) no-repeat center`,
                position: 'relative',
                backgroundSize: '100%',
                backgroundPosition: `0% ${style.y ? 100 - style.y + '%' : '0%'}`,
                overflow: 'hidden',
              }}>
                { isVideo ? (
                  <video key={campaignPictureSrc} style={{ minWidth: '368px', height: '120px', objectFit: 'cover' }} preload="metadata" muted>
                    <source src={campaignPictureSrc} type="video/mp4" />
                  </video>
                ) : null }
                <div style={{
                  float: 'right',
                  position: 'relative',
                  top: '8px',
                  right: '8px'
                }}>
                  { needsPartnerSignature ?
                    <Badge label={ 'Please Sign' }
                      color={ COLOURS.warningRed }
                    /> :
                    startDate < new Date() && endDate > new Date() ?
                    <Badge label={ 'LIVE' }
                      color={ COLOURS.coral }
                    />
                    : null
                  }
                </div>

              </div>
            </Row>
            <Row>
              <Col xs={12}>
                <div style={{
                  textAlign: 'left',
                  paddingTop: '12px',
                  paddingRight: '12px',
                  paddingBottom: '12px',
                  paddingLeft: '12px',
                  width: '368px'
                }}>
                  <p style={{
                    fontFamily: 'Larsseit-Bold',
                    fontSize: '14px',
                    color: COLOURS.ink,
                    paddingTop: '3px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>
                    { name }
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{
              paddingBottom: '13px',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: COLOURS.silver,
            }}>
              <Col style={{
                width: '60px'
              }}>
                <div style={{
                  paddingTop: '5px',
                  paddingRight: '16px',
                  paddingBottom: '0',
                  paddingLeft: '16px'
                }}>
                  <div style={{
                    maxWidth: '50px',
                    float: 'left'
                  }}>
                    <p style={{
                      fontFamily: 'Larsseit',
                      fontSize: '14px',
                      lineHeight: '14px',
                      textAlign: 'center',
                      color: COLOURS.ink,
                      marginBottom: '8px'
                    }}>
                      { moment(startDate).format('MMM').toUpperCase() }
                    </p>
                    <p style={{
                      fontFamily: 'Larsseit-Bold',
                      fontSize: '30px',
                      lineHeight: '30px',
                      color: COLOURS.seaGreen,
                      textAlign: 'center'
                    }}>
                      { moment(startDate).format('D') }
                    </p>
                  </div>
                </div>
              </Col>
              <Col style={{
                width: '150px'
              }}>
                <CampaignPreviewSpecs
                  type={ type }
                  impressions={ impressions }
                  visits={ visits }
                  entries={ entries }
                  estEntries={ estEntries }
                />
              </Col>
              <Col style={{
                width: '148px'
              }}>
                <CampaignPreviewPartnerList
                  hostBrandImage={ hostBrandImage }
                  partnerImages={ partnerImages }
                />
              </Col>
            </Row>
          </Link>
          <Row style={{
            padding: '8px 16px'
          }}>
            <CampaignPreviewActions actions={ actions }/>
          </Row>
        </Grid>
      </Card>
    )
  }
}

CampaignDetailCard.propTypes = {
  campaignId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  campaignPictureSrc: PropTypes.string.isRequired,
  type: PropTypes.string,
  impressions: PropTypes.number,
  visits: PropTypes.number,
  entries: PropTypes.number,
  estEntries: PropTypes.number,
  partnerImages: PropTypes.array.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  hostBrandImage: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  style: PropTypes.object.isRequired
}

export default CampaignDetailCard;
