import React from 'react';
import _ from 'lodash';
import AdminRow from './admin-row';

class SweepsSummaryPeriod extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const headerStyle = {
      borderTop: '1px',
      borderBottom: '3px',
      borderStyle: 'solid'
    };
    const {
      sweepsStarted,
      sweepsEnded,
      activeSweeps,
      newVisits,
      newEntries,
      newToFile,
      distinctHosts,
      distinctCurrentHosts,
      dates
    } = this.props;

    function commas(val) {
      return parseFloat(val).toLocaleString();
    }

    const entriesPerVisit = _.zipWith(newEntries, newVisits, (entries, visits) => entries / visits);
    const emailPerEntry = _.zipWith(newToFile, newEntries, (newToFile, entries) => newToFile / entries);
    //const headerRow = <AdminRow style={headerStyle} vals={dates} />;
    const sweepsStartedRow = <AdminRow title="Sweeps Started" vals={sweepsStarted} key="sweepsStarted" />;
    const sweepsEndedRow = <AdminRow  title="Sweeps Ended" vals={sweepsEnded} key="sweepsEnded" />;
    const activeSweepsRow = <AdminRow title="Active Sweeps" vals={activeSweeps} key="activeSweeps" />;
    const newVisitsRow = <AdminRow title="New Visits" formatter={commas} vals={newVisits} key="newVisits" />;
    const newEntriesRow = <AdminRow title="New Entries" formatter={commas} vals={newEntries} key="newEntries" />;
    const newEmailsToPlatformRow = <AdminRow formatter={commas} title="New Emails to Dojo Platform" key="newEmailsToPlatform" vals={newToFile} />;
    const entryPerVisit = <AdminRow title="Entry / Visit" vals={entriesPerVisit.map(ent => ent.toPrecision(2))} key="entryPerVisit" />;
    const newEmailPerEntry = <AdminRow title="New Email Per Entry" vals={emailPerEntry.map(epe => epe.toPrecision(2))} key="emailperentry" />;
    const distinctCurrentHostsRow = <AdminRow title="Distinct Current Hosts" vals={distinctCurrentHosts} key="distinctCurrentHosts" />;
    const distinctHostsRow = <AdminRow title="Distinct Hosts" vals={distinctHosts} key="distinctHostsRow" />;

    return (
        <tbody>
          <AdminRow title="Summary Period Data" className="info" vals={dates} />
          { sweepsStartedRow }
          { sweepsEndedRow }
          { activeSweepsRow }
          { newVisitsRow }
          { newEntriesRow }
          { newEmailsToPlatformRow }
          { entryPerVisit }
          { newEmailPerEntry }
          { distinctCurrentHostsRow }
          { distinctHostsRow }
        </tbody>
    );
  }
}

export default SweepsSummaryPeriod;
