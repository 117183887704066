import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as DataIntegrationsActions from 'actions/data-integration-actions'
import moment from 'moment-timezone'
import GraphOverviewCard from 'visual-components/mediaMarket/analytics/GraphOverviewCard'
import MessagingDispatcher from 'dispatchers/messaging-dispatcher'
import { withRouter } from 'react-router-dom'
import GraphEmptyState from './GraphEmptyState'
import MediaSellDetailsGraph from '../media-sell-details-graph'
import DealPixelConfirmationModal from './deal-pixel-confirmation-modal'
import DataIntegrationModal from 'components/brand_settings/data-integrations/data-integrations-modal'
import Spinner from 'util/components/spinner'

const tabs = [
  {
    ref: 'dealPixelViews',
    title: 'views - Pixel',
  },
  {
    ref: 'dealEmailDojoClicks',
    title: 'clicks - Dojo',
  },
]

const emailTabs = [
  {
    ref: 'dealESPSends',
    title: 'sends - ESP',
  },
  {
    ref: 'dealESPOpens',
    title: 'opens - ESP',
  },
  {
    ref: 'dealESPClicks',
    title: 'clicks - ESP',
  },
  {
    ref: 'dealEmailDojoClicks',
    title: 'clicks - Dojo',
  },
]
const mapStateToProps = ({ dataIntegrations, currentBrand }) =>
  ({ dataIntegrations, currentBrand })

const mapDispatchToProps = dispatch => ({
  dataIntegrationsActions: bindActionCreators(DataIntegrationsActions, dispatch),
})
class AnalyticsDealGraph extends React.Component {

  componentWillMount() {
    const { dataIntegrationsActions: { load } } = this.props
    load()
  }

  sendMessage = () => {
    const {
      data: {
        isBuyer,
        deal: {
          buying_brand,
          buying_brand_id,
          selling_brand,
          selling_brand_id,
        },
      },
    } = this.props

    let brandName = ''
    let brandId = 0

    if (!isBuyer) {
      brandName = buying_brand && buying_brand.accountname
      brandId = buying_brand_id
    } else {
      brandName = selling_brand && selling_brand.accountname
      brandId = selling_brand_id
    }

    MessagingDispatcher.conversationModal(brandId, brandName)
  }

  connectMedia = () => {
    const {
      data: {
        deal,
      },
      history,
    } = this.props
    history.push(`/partnerships/deal/${deal.id}/content-integration?openModal=true`)
  }

  togglePixelModal = () => {
    const { dataIntegrations, dataIntegrationsActions: { update } } = this.props
    const openModal = !dataIntegrations.modalOpen
    update({
      type: openModal ? 'pixel' : dataIntegrations.type,
      step: openModal ? 'setup' : dataIntegrations.step,
      modalOpen: !dataIntegrations.modalOpen,
    })
  }

  render() {
    const {
      dealActions,
      currentBrand,
      dataIntegrations,
      dataIntegrationsActions,
      data: {
        isBuyer,
        isEmailDeal,
        isIntegrated,
        deal,
        pixelSetupActive,
        pixelConfirmationActive,
      } = {},
      dealAnalytics,
    } = this.props

    const {
      selectedTab,
    } = dealAnalytics

    const espEmptyState = isIntegrated
      ? {
        message: 'Integration successful, waiting for data.',
      } : {
        message: 'No campaign has been connected yet.',
        buttonLabel: isBuyer ? 'Contact Seller' : 'Connect Media',
        buttonAction: isBuyer ? this.sendMessage : this.connectMedia,
      }

    const emptyStateMap = {
      dealPixelViews: {
        message: isBuyer ? 'In order to collect this information, embed a script in the website chosen as the Target Link.'
          : 'This information isn’t available yet from the buyer.',
        buttonLabel: isBuyer ? 'Activate' : 'Contact Buyer',
        buttonAction: isBuyer ? this.togglePixelModal : this.sendMessage,
      },
      dealDojoClicks: {
        message: 'No data available.',
      },
      dealESPSends: espEmptyState,
      dealESPOpens: espEmptyState,
      dealESPClicks: espEmptyState,
      dealEmailDojoClicks: {
        message: 'No data available.',
      },
    }

    const dataExistenceCheck = dealAnalytics.hasData

    const {
      dealESPSends,
      dealESPOpens,
      dealESPClicks,
      dealEmailDojoClicks,
      dealPixelViews,
      dealDojoClicks,
    } = dealAnalytics

    const emptyState = emptyStateMap[selectedTab]

    return (
      <div>
        <GraphOverviewCard
          tabs={isEmailDeal ? emailTabs : tabs}
          aggregates={isEmailDeal
            ? {
              dealESPSends,
              dealESPOpens,
              dealESPClicks,
              dealEmailDojoClicks,
            }
            : {
              dealPixelViews,
              dealEmailDojoClicks,
            }
          }
          selectedTab={selectedTab}
          switchTab={dealActions.dealAnalyticsGraphSwitchTab}
        >
          {dataExistenceCheck
            ? <MediaSellDetailsGraph
              {...dealAnalytics}
              dealStartDate={moment(deal.start_date)}
              dealEndDate={moment(deal.end_date)}
              selectDate={dealActions.selectDateRange}
            />
            :
            <GraphEmptyState
              height="215px"
              width="360px"
              marginTop="122px"
              emptyStateMessage={emptyState && emptyState.message}
              buttonLabel={emptyState && emptyState.buttonLabel}
              onClick={emptyState && emptyState.buttonAction}
            />
          }
        </GraphOverviewCard>
        {!isEmailDeal && !dataIntegrations.loading
          && <div>
              <DataIntegrationModal
                currentBrand={currentBrand}
                dataIntegrations={dataIntegrations}
                dataIntegrationsActions={dataIntegrationsActions}
                closeModal={this.togglePixelModal}
              />
              <DealPixelConfirmationModal
                active={!!dataIntegrations.pixelConfirmationActive}
                success={dataIntegrations.pixelConfirmationSuccess}
                actions={dataIntegrationsActions}
              />
            </div>
        }
        {dataIntegrations.loading && <Spinner />}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnalyticsDealGraph))
