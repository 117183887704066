import React from 'react'
import { Input } from 'visual-components/util/form'
import InputTheme from 'css/themes/Input.css'
import SellMediaModalTheme from 'css/themes/media-market/sell-media-modal-theme.css'

class NoteInput extends React.Component {
  render() {
    const {
      confirmType,
    } = this.props

    const placeholderMap = {
      create: 'Hi there, would love to discuss partnership details with you...',
      accept: 'Hi there, I’m looking forward to working with you...',
    }

    return (
      <div className={SellMediaModalTheme.inputs}>
        <Input
          className={InputTheme.textarea}
          name="note"
          label="Note (Optional)"
          placeholder={placeholderMap[confirmType] || "Hi there, I've modified some of the details..."}
          multiline
        />
      </div>
    )
  }
}

export default NoteInput
