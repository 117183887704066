import React from 'react'
import SimpleInfoModal from 'visual-components/util/modals/SimpleInfoModal'
import AssetUploadTableTheme from 'css/themes/assets/AssetUploadTableTheme.css'
import { P } from 'visual-components/util/texts'

class FileUploading extends React.Component {
  render() {
    return (
      <SimpleInfoModal
        title="Uploading Files"
        active
      >
        <P>Hold on tight! We are processing the uploading files.</P>
        <div className="loadingBarHolder">
          <div className="loadingBar" />
        </div>
      </SimpleInfoModal>
    )
  }
}
export default FileUploading
