import React, {
  useRef, useCallback, useEffect, useState,
} from 'react'
import ReactDOM from 'react-dom'

function useFileUploader() {
  const [file, setFile] = useState(null)
  const [file64, setFile64] = useState(null)
  const fileRef = useRef(null)

  const clear = useCallback(() => {
    setFile(null)
    setFile64(null)
  }, [])

  const onFileChange = useCallback(({ target }) => {
    const [value] = target.files
    setFile(value)
    const reader = new FileReader()
    reader.readAsDataURL(value)
    reader.onload = () => setFile64(reader.result)
    reader.onerror = e => console.log(e)
  }, [])

  const fileInput = (
    <input onChange={onFileChange} accept="image/x-png,image/gif,image/jpeg" type="file" hidden ref={fileRef} />
  )

  const openFileUploader = useCallback(() => {
    const { current } = fileRef

    current.click()
  }, [fileRef])

  useEffect(() => {
    const portalDiv = document.createElement('div')

    document.body.appendChild(portalDiv)
    ReactDOM.render(fileInput, portalDiv)

    return () => {
      ReactDOM.unmountComponentAtNode(portalDiv)
      document.body.removeChild(portalDiv)
    }
  }, [fileRef, fileInput])

  return [file, openFileUploader, clear, file64]
}

export default useFileUploader
