import React from 'react';
import Button from 'util/components/buttons/button';
import COLOURS from 'util/colours';

class PrimaryButton extends React.Component{
  render() {

    var isDisabled = this.props.isDisabled;

    var buttonStyles = [{
      backgroundColor: isDisabled ? COLOURS.inputBorder : COLOURS.inputFocusBorder,
      borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.inputFocusBorder,
      color: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      fontFamily: 'Larsseit',
      ':hover': {
        backgroundColor: isDisabled ? COLOURS.inputBorder : COLOURS.textGreenHover,
        borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.textGreenHover
      }
    }];

    var blueButtonStyles = [{
      backgroundColor: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
      borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
      fontFamily: 'Larsseit',
      fontSize: '14px',
      color: 'white !important',
      ':hover': {
        backgroundColor: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
        borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.azure,
        color: 'white !important'
      }
    }];

    var greenButtonStyles = [{
      backgroundColor: isDisabled ? COLOURS.inputBorder : COLOURS.seaGreen,
      borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.seaGreen,
      fontFamily: 'Larsseit',
      fontSize: '14px',
      color: 'white !important',
      ':hover': {
        backgroundColor: isDisabled ? COLOURS.inputBorder : COLOURS.seaGreen,
        borderColor: isDisabled ? COLOURS.inputBorder : COLOURS.seaGreen,
        color: 'white !important'
      }
    }];

    if (this.props.buttonStyles) {
      buttonStyles = buttonStyles.concat( this.props.buttonStyles );
    }
    
    if (this.props.blueButton) {
      buttonStyles = buttonStyles.concat( blueButtonStyles );
    }

    if (this.props.greenButton) {
      buttonStyles = buttonStyles.concat( greenButtonStyles );
    }

    if (this.props.styles) {
      buttonStyles = buttonStyles.concat( this.props.styles );
    }


    return (
      <Button
        { ...this.props }
        disabled={ isDisabled }
        styles={ buttonStyles }
      />
    );
  }
};

export default PrimaryButton;
