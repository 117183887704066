import React from 'react';
import _ from 'lodash'

import Text from 'util/components/text';
import PrimaryButton from 'util/components/buttons/primary-button';
import AppDispatcher from 'dispatchers/app-dispatcher';
import ProfileDispatcher from 'dispatchers/profile-dispatcher'
import SearchCard from 'visual-components/shared/search-card'
import Card from 'react-toolbox/lib/card';
import SearchResultItem from 'visual-components/messaging/search-result-item';
import { uniq } from 'lodash'

class CustomerSuccessAdminFeatureFlags extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      searchValue: '',
      flags: [],
      brandId: null,
      accountname: '',
      brandFlags: [],
    }

    this.loadFlags()
  }

  updateSuggestions = (newValue) => {
    this.setState({searchValue: newValue});
    this.search(newValue)
  }

  search = (value) => {
    const query = {
      bool: {
        filter: {
          bool: {
            should: [{
              wildcard: {
                accountname:'*' + value.toLowerCase().replace(/\s/g, '*') + '*'
              }
            }]
          }
        }
      }
    };

    if (parseInt(value, 10) > 0) {
      query.bool.filter.bool.should.push({
        term: {
          id: parseInt(value, 10)
        }
      });
    }

    AppDispatcher.http({
      url: '/search/',
      method: 'POST',
      data: {
        size: 100,
        query
      },
      success: (data) => {
        this.setState({
          results: data.hits.hits
        })
      }
    })
  }

  loadFlags = () => {
    AppDispatcher.http({
      url: '/cs-feature-flags',
      success: (flags) => {
        this.setState({
          flags: flags
        })
      }
    })
  }

  setBrand = (data) => {
    this.setState({
      brandId: data.id,
      accountname: data.accountname,
      results: [],
      searchValue: ''
    })

    const that = this;
    AppDispatcher.http({
      url: `/cs-feature-flags-brand/${data.id}`,
      success: (flags) => {
        that.setState({
          brandFlags: flags,
        })
        toastr.success(`Loaded flags for brand ${data.accountname}`, null, { timeout: 2000, positionClass: 'toast-bottom-center' });
      }
    })
  }

  toggleFlag = (flag, isEnabled) => {
    if (!this.state.brandId) {
      return
    }

    this.setState(prevProps => ({
      brandFlags: isEnabled
        ? uniq(prevProps.brandFlags.concat(flag))
        : prevProps.brandFlags.filter(flagName => flagName !== flag),
    }))

    AppDispatcher.http({
      url: '/cs-add-flag-to-brand',
      method: 'POST',
      data: {
        brand: this.state.brandId,
        flag,
        isEnabled,
      },
      success: () => {
        toastr.success(`${flag} ${isEnabled ? 'added to' : 'delete from'} brand`, null, { timeout: 2000, positionClass: 'toast-bottom-center' });
      }
    })
  }

  render() {
    let resultItems = _.map(this.state.results, (item) => {
      return (
        <div style={{minHeight: '50px', minWidth: '900px'}}>
          <SearchResultItem
            brand = {item._source}
            choose ={ ()=>{ this.setBrand(item._source)}  } />
        </div>
      )
    })

    let flagItems = this.state.flags.map(flag => {
      return (
        <div style={{ marginBottom: '10px' }}>
          <a onClick={() => this.toggleFlag(flag, true)}>
            <span>{flag}</span>
          </a>
        </div>
      )
    })


    let brandCurrentFlags = this.state.brandFlags.map(flag => {
      return (
        <div style={{ marginBottom: '10px' }}>
          <a onClick={() => this.toggleFlag(flag, false)}>X</a>
          &nbsp;&nbsp;
          <span>{flag}</span>
        </div>
      )
    })

    return (
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <Text.dojoH2Bold>
            Add Feature to Brand
          </Text.dojoH2Bold>
          <SearchCard
            placeholder={'Which brand do you want to impersonate?'}
            value={ this.state.searchValue }
            onChange={ this.updateSuggestions }
            />
          { this.state.results ?
            <Card style={{
              padding: '0px',
              listStyleType: 'none',
              position: 'absolute',
              background: 'white',
              zIndex: '100',
              marginTop: '0px',
              width: '100%',
              maxHeight: '500px',
              overflow: 'scroll',
              borderTop: `1px solid ${ COLOURS.lightGrey }`
            }}>
               { resultItems }
            </Card>
            : null
          }
        </div>
        <div className="col-xs-5 col-xs-offset-2" style={{
          marginTop: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>
          <div>
            <Text.dojoH2Bold>
              Current Flags for Brand {this.state.accountname}:
            </Text.dojoH2Bold>
            { brandCurrentFlags }
          </div>
          <div>
            <Text.dojoH2Bold>
              Available Flags
            </Text.dojoH2Bold>
            { flagItems }
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerSuccessAdminFeatureFlags;
