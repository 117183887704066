import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { P } from 'visual-components/util/texts'
import colors from 'util/colours'
import ClickAwayListener from './click-away-listener'


const styles = {
  wrapper: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    position: 'relative',
    transition: 'background .1s linear',
    cursor: 'default',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: colors.silver,
    },
  },
  arrow: {
    marginLeft: 10,
    marginRight: 6,
    borderTop: '4px solid black',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    transition: 'transform .1s linear',
  },
  menuOpen: {
    backgroundColor: colors.silver,
  },
  open: {
    transform: 'rotate(180deg)',
  },

  // DropdownItems
  popover: {
    zIndex: 10,
    top: '100%',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    position: 'absolute',
    minWidth: 144,
    maxHeight: 400,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
    height: 30,
    padding: '0 12px',
    transition: 'background .1s linear',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.silver,
    },
  },
}

function DropdownItems(props) {
  const {
    open, options, onClick, classes: css,
  } = props

  if (!open) return null

  return (
    <div className={css.popover}>
      {options.map(item => (
        <div key={item.label} className={css.item} onClick={onClick(item.value)}>
          <P>
            <i>{item.label}</i>
          </P>
        </div>
      ))}
    </div>
  )
}

DropdownItems.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

function DropdownButtonV2(props) {
  const {
    label, onSelect, options, classes: css,
  } = props
  const [open, setOpen] = useState(false)

  const onOpen = e => {
    e.stopPropagation()

    setOpen(isOpen => !isOpen)
  }

  const onItemSelect = item => e => {
    e.stopPropagation()
    setOpen(false)
    onSelect(item)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={cn(css.wrapper, { [css.menuOpen]: open })} onClick={onOpen}>
        <P>
          <i>{label}</i>
        </P>
        <div className={cn(css.arrow, { [css.open]: open })} />
        <DropdownItems classes={css} options={options} open={open} onClick={onItemSelect} />
      </div>
    </ClickAwayListener>
  )
}

DropdownButtonV2.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

DropdownButtonV2.defaultProps = {
  onSelect() {},
}

export default React.memo(injectCSS(styles)(DropdownButtonV2))
