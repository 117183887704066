import React, { Component } from 'react'
import { array, number } from 'prop-types'
import { Small } from 'visual-components/util/texts'
import AssetsTheme from 'css/themes/media-market/Assets.css'
import FileWrapper from './file-wrapper'

class AssetsFilesWindow extends Component {
  static propTypes = {
    assetsByType: array.isRequired,
    currentBrandId: number.isRequired,
  }

  render() {
    const { assetsByType, currentBrandId } = this.props

    return (
      <div className={`${AssetsTheme.windowContainer} ${AssetsTheme.filesWindow}`}>
        {assetsByType && assetsByType.length ? (
          assetsByType.map(item => (
            <FileWrapper key={item.id} item={item} currentBrandId={currentBrandId} />
          ))
        ) : (
          <div className={AssetsTheme.emptyState}>
            <Small multiline cloudGrey>
              No Files Available.
            </Small>
          </div>
        )}
      </div>
    )
  }
}

export default AssetsFilesWindow
