import React from 'react';
import SweepsSummaryPeriod from './sweeps-summary-period';
import SweepsSummaryAggregate from './sweeps-summary-aggregate';
import AdminRow from './admin-row';
import TopTen from './top-ten';
import _ from 'lodash';


class SweepsDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props.admin);
    const { presentation: data } = this.props.admin;
    const { 
      sweepsStarted, 
      sweepsEnded,
      sweepsActive,
      newVisits,
      newEntries,
      newToFile,
      distinctHosts,
      distinctCurrentHosts,
      totalVisits,
      totalEntries,
      topTenBrandInviters,
      topTenBrandEmailsAcquired,
      topTenBrandEmailsAcquiredAggregate,
      topTenCampaigns,
      campaignInvitesAccepted,
      campaignInvitesRejected,
      campaignInvitesPending,
      campaignApplicationsAccepted,
      campaignApplicationsRejected,
      campaignApplicationsPending
    } = data;

    const { dates } = this.props;

    const totalEntryVals = totalEntries.map(row => row.count_total_entries);

    const convertData = (arr, format = ',' , overrideField) => {
      return _.map(arr, function (item) {
        return d3.format(format)(overrideField ? item[overrideField] : item.c)
      });
    }

    const totalCampaignInvites = _.map(campaignInvitesAccepted, function(el, idx){
      return Number(el.count_invites) + Number(campaignInvitesRejected[idx].count_invites) + Number(campaignInvitesPending[idx].count_invites);
    })

    const totalCampaignApplications = _.map(campaignApplicationsAccepted, function(el, idx){
      return Number(el.count_invites) + Number(campaignApplicationsRejected[idx].count_invites) + Number(campaignApplicationsPending[idx].count_invites);
    })

    const calculateInvitePercentage = (totalInvites, invitesArray) => {
      return _.zipWith(totalInvites, invitesArray, function(total, invites) {
        return d3.format('.1%')(Number(invites.count_invites) / total);
      })
    } 

    // transposes brand inviters columndata
    const topTenBrandInvitersData = _.zip.apply(_, topTenBrandInviters);
    // parses the data from db request into displayable formats
    const topTenBrandInvitersFormatted = topTenBrandInvitersData.map(
     (row) =>  {
        return {
          title: row[0].accountname,
          vals: row.map(col => col.c)
        };
      }
    );

    var cValues = [];
    var topTenTotals = {};

    _.times(12, function (i) {
      var val = 'c' + (i + 1);
      cValues.push(val);
      topTenTotals[val] = 0;
    })

    const topTenCampaignsFormatted = topTenCampaigns.map(row => {

      var vals = _.map(cValues, function (val) {
        topTenTotals[val] += row[val] || 0;
        return row[val];
      })
      return {
        title: row.name,
        vals: vals.map(col => col ? parseFloat(col).toLocaleString() || 0 : "0")
      }
    })

    const topTenBrandEmailsAcquiredFormatted = topTenBrandEmailsAcquired.map(row => {
      return {
        title: row.brand,
        vals: [row["1"], row["2"], row["3"], row["4"], row["5"], row["6"], row["7"], row["8"], row["9"], row["10"], row["11"], row["12"]].map(col => col ? parseFloat(col).toLocaleString() || 0 : "0")
      };
    });

    const topTenBrandEmailsAcquiredAggregateFormatted = topTenBrandEmailsAcquiredAggregate.map(row => {
      return {
        title: row.brand,
        vals: [row["1"], row["2"], row["3"], row["4"], row["5"], row["6"], row["7"], row["8"], row["9"], row["10"], row["11"], row["12"]].map(col => col ? parseFloat(col).toLocaleString() || 0 : "0")
      };
    });
    
    
    const topTenCampaignsPercentageOfTotal = _.map(topTenCampaignsFormatted, (row) => {
      // Remove first element from row because it's longer than our newEntries object
      row.vals.shift();

      return {
        title: row.title,
        vals: _.map(row.vals, (campaignEntries, index) => {
          return d3.format('.1%')(Number(campaignEntries.replace(',',''))/Number(newEntries[index].count_entries))
        })
      }
    });

    return (
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped table-hover table-responsive">
            <SweepsSummaryPeriod
              dates={dates}
              sweepsStarted={sweepsStarted.map(row => row.count_sweeps)}
              sweepsEnded={sweepsEnded.map(row => row.count_sweeps)}
              activeSweeps={sweepsActive.map(row => row.count_sweeps)}
              newVisits={newVisits.map(row => row.count_visits)}
              newEntries={newEntries.map(row => row.count_entries)}
              newToFile={newToFile.map(row => row.c.toLocaleString())}
              distinctHosts={distinctHosts.map(row => row.count_distinct_hosts)}
              distinctCurrentHosts={distinctCurrentHosts.map(row => row.count_distinct_current_hosts)}
              />
            <SweepsSummaryAggregate
              dates={dates}
              totalVisits={totalVisits.map(row => row.count_total_visits)}
              totalEntries={totalEntryVals}
              />
            <tr className="info"><td colSpan="13"><h4>Campaign Invites/Applications</h4></td></tr> 
              <tbody>             
              <AdminRow title="Campaign Invite Activity" className="info" vals={dates} />
                <AdminRow title="Accepted" vals={convertData(campaignInvitesAccepted,',','count_invites')} key="campaignInvitesAccepted"/>
                <AdminRow title="Rejected" vals={convertData(campaignInvitesRejected,',','count_invites')} key="campaignInvitesRejected"/>
                <AdminRow title="Pending" vals={convertData(campaignInvitesPending,',','count_invites')} key="campaignInvitesPending"/>
              <AdminRow title="Campaign Invite Activity (%)" className="info" vals={dates} />
                <AdminRow title="Accepted" vals={calculateInvitePercentage(totalCampaignInvites, campaignInvitesAccepted)} key="campaignInvitesAcceptedPercentage"/>
                <AdminRow title="Rejected" vals={calculateInvitePercentage(totalCampaignInvites, campaignInvitesRejected)} key="campaignsRejectedPercentage"/>
                <AdminRow title="Pending" vals={calculateInvitePercentage(totalCampaignInvites, campaignInvitesPending)} key="campaignsPendingPercentage"/>
              <AdminRow title="Campaign Application Activity" className="info" vals={dates} />
                <AdminRow title="Accepted" vals={convertData(campaignApplicationsAccepted,',','count_invites')} key="campaignApplicationsAccepted"/>
                <AdminRow title="Rejected" vals={convertData(campaignApplicationsRejected,',','count_invites')} key="campaignApplicationsRejected"/>
                <AdminRow title="Pending" vals={convertData(campaignApplicationsPending,',','count_invites')} key="campaignApplicationsPending"/>
              <AdminRow title="Campaign Application Activity (%)" className="info" vals={dates} />
                <AdminRow title="Accepted" vals={calculateInvitePercentage(totalCampaignApplications, campaignApplicationsAccepted)} key="campaignApplicationsAcceptedPercentage"/>
                <AdminRow title="Rejected" vals={calculateInvitePercentage(totalCampaignApplications, campaignApplicationsRejected)} key="campaignApplicationsRejectedPercentage"/>
                <AdminRow title="Pending" vals={calculateInvitePercentage(totalCampaignApplications, campaignApplicationsPending)} key="campaignApplicationsPendingPercentage"/>
              </tbody>
            <tr className="info"><td colSpan="13"><h4>Brand and Sweeps Leaderboard</h4></td></tr>
            <TopTen dates={dates} title="Top 10 Brands Emails Acquired" rows={topTenBrandEmailsAcquiredFormatted} />
            <TopTen dates={dates} title="Top 10 Brands Emails Acquired - Aggregate" rows={topTenBrandEmailsAcquiredAggregateFormatted} /> 
            <TopTen dates={dates} title="Top 10 Campaigns" rows={topTenCampaignsFormatted} />
            <TopTen dates={dates} title="Top 10 Campaigns - % of Entries" rows={topTenCampaignsPercentageOfTotal} />
          </table>
        </div>
      </div>
    );
  }
}


export default SweepsDetail;

