import React from 'react'
import propTypes from 'prop-types'
import COLOURS from 'util/colours'

class SubHeaderText extends React.Component {
  static propTypes = {
    text: propTypes.string,
    style: propTypes.object,
  }

  static defaultProps = {
    text: null,
    style: {
      fontSize: '12px',
      display: 'inline',
    },
  }

  render() {
    const { text, style } = this.props
    return (
      <div style={{ display: style.display }}>
        <small
          style={{
            fontFamily: 'Larsseit-Light', color: COLOURS.ink, lineHeight: 'normal', ...style,
          }}
        >
          { text }
        </small>
      </div>
    )
  }
}

export default SubHeaderText
