import React from 'react'
import CalendarTheme from 'css/themes/calendar/calendar.css'

import { func } from 'prop-types'

class CampaignBar extends React.Component {
  static contextTypes = {
    onCampaignSelect: func.isRequired,
  }

  select = e => {
    const { onCampaignSelect } = this.context
    const { event } = this.props

    let cardHeight = 0
    let cardWidth = 0

    if (e.clientY > window.innerHeight - 300) {
      cardHeight = window.innerHeight - 300
    } else {
      cardHeight = e.clientY - 100
    }

    if (e.clientX > window.innerWidth - 350) {
      cardWidth = window.innerWidth - 350
    } else {
      cardWidth = e.clientX - 100
    }

    onCampaignSelect(event, {
      left: cardWidth,
      top: cardHeight,
    })
  }

  render() {
    const {
      event,
    } = this.props

    return (
      <div className={CalendarTheme.longEventBar}>
        <div
          className={CalendarTheme.longEventBarSegment}
          onClick={this.select}
          style={{
            backgroundColor: event.color,
            width: `${(7 - event.left - event.right) / 7 * 100}%`,
            marginLeft: `${(event.left) / 7 * 100}%`,
          }}
        />
      </div>
    )
  }
}

export default CampaignBar
