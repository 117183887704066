import React, { PureComponent as Component } from 'react'
import { string, node, bool } from 'prop-types'
import { H2, Tiny } from 'visual-components/util/texts'
import { makeNumbersPretty } from 'util/makeNumbersPretty'
import analyticsCardTheme from 'css/themes/campaign-analytics-cards/campaignCardBase.css'

class DisplayedStat extends Component {
  static propTypes = {
    name: string,
    value: node.isRequired,
    live: bool,
  }

  static defaultProps = {
    name: '',
    live: false,
  }

  render() {
    const {
      name,
      value,
      live,
    } = this.props

    return (
      <div className={analyticsCardTheme.statCol}>
        <H2 white={live}>
          { makeNumbersPretty(value) }
        </H2>
        <Tiny white={live}>
          { name }
        </Tiny>
      </div>
    )
  }
}

export default DisplayedStat
