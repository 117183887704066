import OverviewTheme from 'css/themes/Overview.css'
import React, { PureComponent as Component } from 'react'
import Col from 'visual-components/util/no-padding-grid/col'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import {
  array,
  string,
  func,
  bool,
} from 'prop-types'
import { MenuItem, IconMenu } from 'react-toolbox/lib/menu'
import NavBarDropdownTheme from 'css/themes/nav-bar-dropdown.css'
import { P } from 'visual-components/util/texts'

const renderMenuItems = (item, idx) => {
  const { label, onClick } = item

  return (
    <MenuItem
      value={label}
      caption={label}
      onClick={onClick}
      theme={NavBarDropdownTheme}
      key={idx}
    />
  )
}

class OverviewActions extends Component {
  static propTypes = {
    options: array,
    goToBuilder: func,
    goToAnalytics: func,
    isHost: bool,
    showBuilderButton: bool,
    previewLink: string,
    isSolo: bool,
  }

  static defaultProps = {
    options: [],
    previewLink: '',
    showBuilderButton: true,
    isHost: false,
    goToAnalytics() {},
    goToBuilder() {},
    isSolo: false,
  }

  preview = () => window.open(this.props.previewLink)

  render() {
    const {
      goToBuilder,
      options,
      goToAnalytics,
      isHost,
      active,
      showBuilderButton,
      isSolo,
    } = this.props

    const buttonProps = isHost
      ? { onClick: goToBuilder, label: 'Sweepstakes Builder' }
      : { onClick: this.preview, label: 'Preview' }

    const menuItems = options.map(renderMenuItems)

    return (
      <Col xs={5}>
        <div className="text-right" style={{ marginTop: '-15px' }}>
          {
            showBuilderButton && (
              <div style={{ display: 'inline-block' }}>
                <Button
                  {...buttonProps}
                  className={`${ButtonTheme.blueButton} ${isSolo ? ButtonTheme.soloOverviewButton : ''}`}
                  theme={ButtonTheme}
                  primary
                  raised
                />
              </div>
            )
          }
          <div style={isSolo ? { marginTop: 12 } : { display: 'inline-block', marginLeft: '12px' }}>
            <Button
              onClick={goToAnalytics}
              label="Analytics"
              className={`${ButtonTheme.greenButton} ${isSolo ? ButtonTheme.soloOverviewButton : ''}`}
              theme={ButtonTheme}
              primary
              raised
            />
          </div>
          {
            options.length
              ? (
                <div
                  className={`${isSolo ? NavBarDropdownTheme.soloOverviewMenuHolder : NavBarDropdownTheme.overviewMenuHolder}`}
                  style={isSolo ? { marginTop: 12 } : { display: 'inline-block', marginLeft: '12px' }}
                >
                  <IconMenu
                    position="auto"
                    icon={(
                      <div className={`${isSolo ? OverviewTheme.soloActionsToggle : OverviewTheme.actionsToggle}`}>
                        <P azure={isSolo}>Actions</P>
                        <img src={isSolo ? 'images/icons/azure-ellipses.svg' : '/images/icons/expand-arrow.svg'} />
                      </div>
                    )}
                    active={active}
                    onHide={() => {}}
                    theme={NavBarDropdownTheme}
                  >
                    { menuItems }
                  </IconMenu>
                </div>
              ) : null
          }
        </div>
      </Col>
    )
  }
}

export default OverviewActions
