import React, { Component } from 'react'
import { bool, number } from 'prop-types'
import { Route as RouteRR, Switch as SwitchRR } from 'react-router-dom'

// Decorated Route to accept mobile version of component
export const Route = ({ component, mobileComponent, isMobile, ...rest }) => (
  <RouteRR
    {...rest}
    component={isMobile ? mobileComponent || component : component}
  />
)

// Decorated Switch to control desktop vs. mobile
export class Switch extends Component {
  static propTypes = {
    forceMobile: bool,
    threshold: number,
  }

  static defaultProps = {
    threshold: 600,
  }

  state = {
    isMobile: false,
  }

  handleWindowResize = () => {
    this.setState({
      isMobile: window.innerWidth <= this.props.threshold,
    })
  }

  componentWillMount() {
    if (typeof window === 'undefined') {
      return
    }
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  render() {
    const isMobile = this.props.forceMobile || this.state.isMobile
    const children = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { isMobile })
    )

    return <SwitchRR>{children}</SwitchRR>
  }
}
