
import moment from 'moment-timezone'

const beltMapping = {
  // Trials
  trial: {
    label: 'Premium Trial',
    onsite: true,
  },
  'post-trial-paywall': {
    label: 'Post-Trial Paywall',
    onsite: true,
  },

  // Original Plans
  white: {
    label: 'Basic',
    onsite: true,
  },
  greenFixed: {
    label: 'Essential: Monthly',
    interval: 'monthly',
    onsite: true,
  },
  greenFixed12: { // Deprecated: Replacing with essential-12months-upfront
    label: 'Essential: Annual Site',
    interval: 'annually',
    onsite: true,
  },
  greenFixedUpfront: { // Deprecated: Replacing with new 6/12 month Plans
    label: 'Essential: Upfront',
    interval: 'upfront',
  },
  greenFixedB3G1: { // Deprecated: Replacing with new B3G1 plans
    label: 'Essential: Buy 3, Get 1',
    interval: 'upfront',
  },
  black: {
    label: 'Premium: Monthly',
    interval: 'monthly',
    onsite: true,
  },
  black12: { // Deprecated: Replacing with premium-12month-upfront
    label: 'Premium: Annual Site',
    interval: 'annually',
    onsite: true,
  },
  blackUpfront: { // Deprecated: Replacing with new 6/12 month Plans
    label: 'Premium: Upfront',
    interval: 'upfront',
  },
  blackB3G1: { // Deprecated: Replacing with new B3G1 plans
    label: 'Premium: Buy 3, Get 1',
    interval: 'upfront',
  },
  elite: {
    label: 'Elite',
    ltc: true,
  },

  // Monthly plans
  'pro-monthly': {
    label: 'Pro: Monthly',
    interval: 'monthly',
    onsite: true,
  },

  // Buy 3 Get 1 Plans
  'essential-buy3-get1-monthly': {
    label: 'Essential: Buy 3, Get 1 - Monthly Billing',
    interval: 'monthly',
    coupon: 'b3g1',
    b3g1: true,
    preferredTerms: true,
    ltc: true,
  },
  'essential-buy3-get1-upfront': {
    label: 'Essential: Buy 3, Get 1 - Upfront Billing',
    interval: 'upfront',
    count: 4,
    coupon: 'b3g1',
    b3g1: true,
    preferredTerms: true,
    ltc: true,
    onsite: true,
  },
  'premium-buy3-get1-monthly': {
    label: 'Premium: Buy 3, Get 1 - Monthly Billing',
    interval: 'monthly',
    coupon: 'b3g1',
    b3g1: true,
    preferredTerms: true,
    ltc: true,
  },
  'premium-buy3-get1-upfront': {
    label: 'Premium: Buy 3, Get 1 - Upfront Billing',
    interval: 'upfront',
    count: 4,
    coupon: 'b3g1',
    b3g1: true,
    preferredTerms: true,
    ltc: true,
    onsite: true,
  },
  'pro-buy3-get1-monthly': {
    label: 'Pro: Buy 3, Get 1 - Monthly Billing',
    interval: 'monthly',
    coupon: 'b3g1',
    b3g1: true,
    preferredTerms: true,
    ltc: true,
  },
  'pro-buy3-get1-upfront': {
    label: 'Pro: Buy 3, Get 1 - Upfront Billing',
    interval: 'upfront',
    count: 4,
    coupon: 'b3g1',
    b3g1: true,
    preferredTerms: true,
    ltc: true,
    onsite: true,
  },

  // 6 Month Plans
  'essential-6months-monthly': {
    label: 'Essential: 6 Months - Monthly Billing',
    interval: 'monthly',
    preferredTerms: true,
    ltc: true,
  },
  'essential-6months-quarterly': {
    label: 'Essential: 6 Months - Quarterly Billing',
    interval: 'quarterly',
    preferredTerms: true,
    ltc: true,
  },
  'essential-6months-upfront': {
    label: 'Essential: 6 Months - Upfront Billing',
    interval: 'bi-annually',
    preferredTerms: true,
    ltc: true,
  },
  'premium-6months-monthly': {
    label: 'Premium: 6 Months - Monthly Billing',
    interval: 'monthly',
    preferredTerms: true,
    ltc: true,
  },
  'premium-6months-quarterly': {
    label: 'Premium: 6 Months - Quarterly Billing',
    interval: 'quarterly',
    preferredTerms: true,
    ltc: true,
  },
  'premium-6months-upfront': {
    label: 'Premium: 6 Months - Upfront Billing',
    interval: 'bi-annually',
    preferredTerms: true,
    ltc: true,
  },
  'pro-6months-monthly': {
    label: 'Pro: 6 Months - Monthly Billing',
    interval: 'monthly',
    preferredTerms: true,
    ltc: true,
  },
  'pro-6months-quarterly': {
    label: 'Pro: 6 Months - Quarterly Billing',
    interval: 'quarterly',
    preferredTerms: true,
    ltc: true,
  },
  'pro-6months-upfront': {
    label: 'Pro: 6 Months - Upfront Billing',
    interval: 'bi-annually',
    preferredTerms: true,
    ltc: true,
  },

  // 12 Month Plans
  'essential-12months-monthly': {
    label: 'Essential: 12 Months - Monthly Billing',
    interval: 'monthly',
    preferredTerms: true,
    ltc: true,
  },
  'essential-12months-quarterly': {
    label: 'Essential: 12 Months - Quarterly Billing',
    interval: 'quarterly',
    preferredTerms: true,
    ltc: true,
  },
  'essential-12months-bi-annual': {
    label: 'Essential: 12 Months - Bi-annual Billing',
    interval: 'bi-annually',
    preferredTerms: true,
    ltc: true,
  },
  'essential-12months-upfront': {
    label: 'Essential: 12 Months - Upfront Billing',
    interval: 'annually',
    preferredTerms: true,
    ltc: true,
  },
  'premium-12months-monthly': {
    label: 'Premium: 12 Months - Monthly Billing',
    interval: 'monthly',
    preferredTerms: true,
    ltc: true,
  },
  'premium-12months-quarterly': {
    label: 'Premium: 12 Months - Quarterly Billing',
    interval: 'quarterly',
    preferredTerms: true,
    ltc: true,
  },
  'premium-12months-bi-annual': {
    label: 'Premium: 12 Months - Bi-annual Billing',
    interval: 'bi-annually',
    preferredTerms: true,
    ltc: true,
  },
  'premium-12month-upfront': {
    label: 'Premium: 12 Months - Upfront Billing',
    interval: 'annually',
    preferredTerms: true,
    ltc: true,
  },
  'pro-12months-monthly': {
    label: 'Pro: 12 Months - Monthly Billing',
    interval: 'monthly',
    preferredTerms: true,
    ltc: true,
  },
  'pro-12months-quarterly': {
    label: 'Pro: 12 Months - Quarterly Billing',
    interval: 'quarterly',
    preferredTerms: true,
    ltc: true,
  },
  'pro-12months-bi-annual': {
    label: 'Pro: 12 Months - Bi-annual Billing',
    interval: 'bi-annually',
    preferredTerms: true,
    ltc: true,
  },
  'pro-12month-upfront': {
    label: 'Pro: 12 Months - Upfront Billing',
    interval: 'annually',
    preferredTerms: true,
    ltc: true,
  },

  // Group Plans
  'essential-group-plan': {
    label: 'Essential: Group Plan',
    ltc: true,
  },
  'pro-group-plan': {
    label: 'Pro: Group Plan',
    ltc: true,
  },

  // Custom Plans
  'essential-custom': {
    label: 'Essential: Custom',
    ltc: true,
  },
  'premium-custom': {
    label: 'Premium: Custom',
    ltc: true,
  },
  'pro-custom': {
    label: 'Pro: Custom',
    ltc: true,
  },
}

const smsCreditCostMapping = {
  greenFixed: 0.01,
  black: 0.009,
  pro: 0.008,
  elite: 0.008,
  default: 0.01,
}


// Returns coupon to use for plan
const couponMapping = plan => {
  const isProduction = !['localhost', 'dev.dojomojo.com', 'staging.dojomojo.com'].includes(window.location.hostname)
  return {
    annual: 'annual25', // Annual Discount (Standard 25%)
    'bi-annual': !isProduction ? 'p0gCdDXl' : 'd3Bs03gb', // Bi-Annual Discount (Standard 10%)
    b3g1: !isProduction ? 'X4z9jVEV' : 'Y9t4FZDC', // Buy 3, Get 1 Discount (25% off)
  }[beltMapping[plan].coupon] || null
}

// Returns brand belt label
const getPlanLabel = plan => (beltMapping[plan] ? beltMapping[plan].label : 'Basic')

// Parses brands Stripe belt to the basic belt version
const cleanPlanText = plan => {
  if (plan.startsWith('pro')) {
    return 'pro'
  }
  if (plan.includes('black') || plan.includes('premium') || plan.includes('elite')) {
    return 'black'
  }
  if (plan.includes('greenFixed') || plan.includes('essential')) {
    return 'greenFixed'
  }
  return plan.includes('trial') ? plan : 'white'
}

// Get array of Buy 3, Get 1 plans
const getB3G1Plans = () => Object.entries(beltMapping).filter(([, value]) =>
  value.b3g1)

// Get array of Preferred Term Plans
const getPreferredTermPlans = () => Object.entries(beltMapping).filter(([, value]) =>
  value.preferredTerms)

// Get array of Long Term Contract plans
const getLTCPlans = () => Object.entries(beltMapping).filter(([, value]) =>
  value.ltc)

// Offsite plans are contracted manually offsite. In these cases we want to sometimes
// treat them differently than the onsite plans. eg: hide upgrade button
const isOffsitePlan = plan => {
  const offsitePlans = Object.entries(beltMapping).filter(([, value]) =>
    !value.onsite).map(([value]) => value)
  return plan && offsitePlans.includes(plan)
}

// Determine if a brand is isTrialing
const isTrialing = (brand, customer) => {
  const subscription = (customer && customer.subscriptions && customer.subscriptions.data
    && customer.subscriptions.data.length && customer.subscriptions.data[0]) || null
  return (subscription
    && subscription.plan.id === 'trial')
    || (brand.belt === 'trial'
    && new Date(brand.trial_end) > new Date())
}

// Determine if a brand is trialing or on a special trial
const isTrialingWithOffers = (brand, customer) => {
  const subscription = (customer && customer.subscriptions && customer.subscriptions.data
    && customer.subscriptions.data.length && customer.subscriptions.data[0]) || null
  const source = (customer && customer.sources && customer.sources.data
    && customer.sources.data.length && customer.sources.data[0]) || null
  return (subscription && subscription.plan.id === 'trial')
    || (brand.belt === 'trial' && new Date(brand.trial_end) > new Date())
    || (!!source && new Date(brand.extended_trial_end) > new Date())
    || new Date(brand.offer_trial_end) > new Date()
}

// Determine if a brand is isDelinquent
const isDelinquent = (brand, customer) => !['trial', 'post-trial-paywall', 'white'].includes(brand.belt)
  && customer.delinquent
  && customer.invoices[0].status !== 'void'

// Determine if a brand is truly still trialing
// In other words, on trial plan, and have not chosen to upgrade to plan once trial ends
const isTrialingAndNotUpgraded = ({
  belt, stripe_belt, trial_end, extended_trial_end, offer_trial_end,
}) => (
  belt === 'trial'
    && stripe_belt === 'trial'
    && (new Date() < new Date(trial_end)
      || new Date() < new Date(extended_trial_end)
      || new Date() < new Date(offer_trial_end))
)

const hasTrialEnded = () => {
  const { customer, currentBrand } = store.getState()
  const trialing = isTrialing(currentBrand, customer)

  const { subscriptions } = customer
  const hasSubscriptions = Boolean(subscriptions && subscriptions.data && subscriptions.data.length)
  const subscription = hasSubscriptions ? subscriptions.data[0] : null

  return currentBrand.belt === 'post-trial-paywall'
  || (!trialing
    && !subscription
    // if subscription is through Shopify then ignore this logic. It'll be handled by Shopify
    && currentBrand.payment_method !== 'shopify'
    && currentBrand.belt !== 'white'
    && currentBrand.has_accepted_latest_terms)
}

const isTrialAboutToEnd = () => {
  const { customer, currentBrand } = store.getState()
  const trialing = isTrialing(currentBrand, customer)

  const trialEndDate = moment(currentBrand.trial_end)

  const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */
  const remainingDays = Math.round(Math.abs(trialEndDate._d.getTime() - new Date()) / oneDay)
  // show at 7, 3, 2, 1 days from ending
  const withinTrialModalDays = [7, 3, 2, 1].includes(remainingDays)

  const dismissedTrialWarningPaywall = sessionStorage.getItem('withinTenDaysOfTrialEndPaywallDismissed') === 'true'
  const hasSources = customer.sources.data.length > 0

  return trialing
    && currentBrand.trial_type === 'trial'
    && withinTrialModalDays
    && !dismissedTrialWarningPaywall
    && !hasSources
    && currentBrand.has_accepted_latest_terms
}

export {
  beltMapping,
  smsCreditCostMapping,
  couponMapping,
  getPlanLabel,
  cleanPlanText,
  getB3G1Plans,
  getPreferredTermPlans,
  getLTCPlans,
  isOffsitePlan,
  isTrialing,
  isTrialingWithOffers,
  isDelinquent,
  isTrialingAndNotUpgraded,
  hasTrialEnded,
  isTrialAboutToEnd,
}
