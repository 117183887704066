import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Text from 'util/components/text';
import COLOURS from 'util/colours';
import { messagingRelativeDate } from 'util/relativeDate';
import MessagingBrandLogo from 'visual-components/messaging/messaging-brand-logo';

class MessageThreadItemNormal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { message, seenBy } = this.props

    return (
      <div style={{
        borderBottom: `1px solid ${ COLOURS.lightGrey }`
      }}>
        {
          message.heading ?
          <Row style={{
            padding: '16px',
            borderBottom: `1px solid ${ COLOURS.lightGrey }`

          }}>
            <Col xs={11}>
              <p style={{
                fontSize: '18px',
                fontFamily: 'Larsseit-Bold'
              }}>
                { message.heading }
              </p>
            </Col>
            <Col xs={1}>
              <p style={{
                fontFamily: 'Larsseit-Light',
                fontSize: '12px',
                marginTop: '2px',
                textAlign: 'right'
              }}>
                { messagingRelativeDate(message.createdAt) }
              </p>
            </Col>
          </Row>
          : null
        }
        <Row style={{
          padding: '12px 12px 6px 12px',
        }}>
          <Col xs={1}>
            <MessagingBrandLogo logo={message.displayBrandLogo} brandId={message.brand_id}/>
          </Col>
          <Col xs={4} style={{
            paddingLeft: '5px'
          }}>
            <Row>
              <Col xs>
                <p style={{
                  fontFamily: 'Larsseit-Bold',
                  fontSize: '14px',
                  marginTop: '8px',
                  display: 'inline',
                  whiteSpace: 'nowrap',
                  color: COLOURS.ink
                }}>
                  { message.displayBrandName }
                </p>
                <p style={{
                  fontFamily: 'Larsseit-Light',
                  fontSize: '12px',
                  marginTop: '8px',
                  textAlign: 'left',
                  color: COLOURS.cloudGrey,
                  display: 'inline',
                  paddingLeft: '8px'
                }}>
                  {
                    seenBy >= 1 ?
                    `Seen by ${seenBy} ${seenBy == 1 ? 'person' : 'people'}`
                    : null
                  }
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <p style={{
                  fontFamily: 'Larsseit',
                  fontSize: '12px',
                  marginTop: '12px',
                  display: 'inline'
                }}>
                  { message.firstName }
                </p>
              </Col>
            </Row>
          </Col>
          <Col xsOffset={6} xs={1}>
            <p style={{
              fontFamily: 'Larsseit-Light',
              fontSize: '12px',
              marginTop: '2px',
              textAlign: 'right'
            }}>
              { message.heading ? '' : messagingRelativeDate(message.createdAt) }
            </p>
          </Col>
        </Row>
        <Row>
          <Col xsOffset={1} xs={10}>
            <p dangerouslySetInnerHTML={{ __html: message.content }}
              className="message-container"
              style={{
                color: COLOURS.dropdownText
            }}>
            </p>
          </Col>
        </Row>
      </div>
    )
  }

}

export default MessageThreadItemNormal
