import React, { Component } from 'react'
import { string, array, func, object } from 'prop-types'

import FilterBox from './filter-box'
import RangeSlider from 'visual-components/util/filters/range-slider'
import CheckboxList from 'visual-components/util/filters/checkbox-list'

class CheckboxSliderFilter extends Component {
  static propTypes = {
    // filter box props
    title: string.isRequired,
    tooltip: string,

    // slider props
    sliderName: string.isRequired,
    range: object.isRequired,
    format: func,
    extraStartText: string,
    extraEndText: string,
    secondaryStartText: string,
    label: string,

    // checkbox props
    checkboxName: string.isRequired,
    selectAllOption: string,
    seeMoreText: string,
    seeLessText: string,
  }

  render() {
    const {
      title,
      tooltip,
      sliderName,
      range,
      extraStartText,
      extraEndText,
      secondaryStartText,
      format,
      label,
      checkboxName,
      selectAllOption,
      seeMoreText,
      seeLessText,
    } = this.props

    return (
      <FilterBox
        width={'184px'}
        title={title}
        tooltip={tooltip}
        paddingBot={'16px'}
        name={sliderName}
        hasNestedCollapse
      >
        <RangeSlider
          minValue={range.min}
          maxValue={range.max}
          format={format}
          name={sliderName}
          label={label}
          extraStartText={extraStartText}
          extraEndText={extraEndText}
          secondaryStartText={secondaryStartText}
          paddingBottom="16px"
        />
        <CheckboxList
          selectAllOption={selectAllOption}
          seeMoreText={seeMoreText}
          seeLessText={seeLessText}
          name={checkboxName}
        />
      </FilterBox>
    )
  }
}

export default CheckboxSliderFilter
