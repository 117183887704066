import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { P, H6 } from 'visual-components/util/texts'
import IconButtonTheme from 'css/themes/IconButton.css'
import { IconButton } from 'react-toolbox/lib/button'

const styles = {
  editorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  openButton: {
    width: '68px',
    height: '36px',
    borderRadius: '2px',
    padding: '11px 16px',
    margin: '0px 8px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#03a9f4',
  },
  textContainer: {
    alignSelf: 'flex-start',
    marginTop: '-8px',
    width: '100%',
  },
  urlContainer: {
    marginTop: '16px',
  },
  urlForm: {
    width: '437px',
    height: '40px',
    padding: '13px 12px',
    borderRadius: '2px',
    border: 'solid 1px #dee4e5',
  },
}

function PageHostingChoice(props) {
  const {
    header,
    url,
    onEdit,
    openButtonLabel,
    classes,
  } = props

  return (
    <div className={classes.urlContainer}>
      {header ? <H6 multiline>{header}</H6> : null}
      <div className={classes.editorContainer}>
        <div className={classes.urlForm}>
          <P overflowEllipsis>{url}</P>
        </div>
        <a rel="noopener noreferrer" target="_blank" href={url} className={classes.openButton}>
          <H6 white>{openButtonLabel}</H6>
        </a>
        <div>
          <IconButton onClick={onEdit} icon="edit" theme={IconButtonTheme} disableRipple />
        </div>
      </div>
    </div>
  )
}

PageHostingChoice.propTypes = {
  header: PropTypes.string,
  url: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  openButtonLabel: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

PageHostingChoice.defaultProps = {
  header: '',
  url: '',
  openButtonLabel: 'Open',
}

export default injectSheet(styles)(PageHostingChoice)
