import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { H3, H2, Small } from 'visual-components/util/texts';

import AnalyticsHeader from 'visual-components/analyticsDashboard/analytics-header';
import ContributionBar from 'visual-components/analyticsDashboard/overviewCard/contribution-bar';
import Dropdown from 'react-toolbox/lib/dropdown'
import EmptyState from 'visual-components/util/graphs/empty-state';
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';

import AnalyticsTheme from 'css/themes/analytics.css';
import DropdownFilterTheme from 'css/themes/dropDownAnalytics.css'

class HostHeader extends React.Component {
  customItem = item => {
    /* this breaks out of the normal loop, allowing for customization of the chosen value */
    return (
      <div className={ DropdownFilterTheme.customItem }>
        { item.label == this.props.selectedBrand && item.label.length > 8 ? item.label.substring(0, 8) + '...' : item.label }
      </div>
    )
  }

  render() {
    var {
      options,
      value,
      onChange,
      isSolo,
    } = this.props;

    return (
      <div>
        <div style={{
          display: 'inline-block'
        }}>
          <H3 coral>
            <small>
              { `Custom CTA${!isSolo ? ' - ' : ''}` }
            </small>
          </H3>
        </div>
        {!isSolo && (
          <div style={{
            display: 'inline-block',
            marginLeft: '-12px'
          }}>
            <Dropdown
              auto
              onChange={ onChange }
              source={ options }
              value={ value }
              theme={ DropdownFilterTheme }
              template={ this.customItem }
            />
          </div>
        )}
        <div style={{
          borderRadius: '100%',
          width: '20px',
          height: '20px',
          textAlign: 'center',
          display: 'inline-block',
          marginLeft: '8px'
        }}>
          <BlueInfoTooltip text={ 'The number of Custom CTA clicks driven by each partner' }/>
        </div>
      </div>
    )
  }
}

class ClickContributionCard extends React.Component {
  render() {
    var {
      totalPretty,
      options,
      value,
      onChange,
      isHost
    } = this.props;

    return (
      <Card className={ `${AnalyticsTheme.ctaBarCard} ${AnalyticsTheme.contributionCard}` }>
        <div style={{marginBottom: '-10px'}}>
          {
            isHost ?
            <HostHeader { ...this.props } />
            :
            <AnalyticsHeader title="Custom CTA"
              tooltipText="The number of Custom CTA clicks driven by each partner" />
          }
        </div>
        {
          totalPretty != 0 ?
          <div>
            <div>
              <div style={{
                display: 'inline-block',
                marginBottom: '5px',
                marginRight: '3px'
              }}>
                <H2>
                  { totalPretty }
                </H2>
              </div>
              <div style={{
                display: 'inline-block'
              }}>
                <Small>
                  clicks
                </Small>
              </div>
            </div>
            <ContributionBar name="clicks"
              data={ this.props.data }
              started={ this.props.started }
            />
          </div>
          :
          <EmptyState text={ 'The Custom CTA is a powerful feature reserved for Premium DojoMojo users.' }
            learnMore={ 'https://help.dojomojo.com/hc/en-us/articles/226204288-Drive-Conversions-and-Page-Views-with-a-Custom-CTA' }
            height={ '380px' }
            bigText
          />
        }
      </Card>
    );
  }
}

export default ClickContributionCard;
