import React, { Component } from 'react'
import { array } from 'prop-types'

import theme from 'css/themes/invisibleScroll.css'

class InvisibleScroll extends Component {
  static propTypes = {
    elements: array.isRequired
  }

  state = {
    right: 0,
  }

  componentDidMount() {
    this.setState({
      right: this.node.clientWidth - this.node.offsetWidth,
    })
  }

  render () {
    const {
      elements
    } = this.props

    let {
      right
    } = this.state

    return(
      <div className={ theme.outer }>
        <div className={ theme.inner }
          ref={ node => this.node = node }
          style={{
            right: `${right}px`
        }}>
          { elements }
        </div>
      </div>
    )
  }
}

export default InvisibleScroll
