import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import InputTheme from 'css/themes/Input.css'
import ButtonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import { Input } from 'react-toolbox/lib/input'

const THEMES = {
  input: InputTheme,
  button: ButtonTheme,
}

const styles = {
  noPadding: {
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
}

const SetMessageRevenue = props => {
  const {
    cardRowStyle,
    classes: css,
    isLoading,
    onSubmit: parentOnSetMessageRevenueClick,
  } = props

  const [messageId, setMessageId] = useState('')
  const [revenue, setRevenue] = useState('')
  const [orderCount, setOrderCount] = useState('')

  const onSetMessageRevenueClick = async () => {
    await parentOnSetMessageRevenueClick({
      messageId,
      revenue,
      orderCount,
    })
  }

  return (
    <>
      <div className={cardRowStyle}>
        <Input
          value={messageId}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setMessageId}
          placeholder="SMS message ID"
        />
        <Input
          value={revenue}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setRevenue}
          placeholder="Revenue"
        />
        <Input
          value={orderCount}
          theme={THEMES.input}
          className={css.noPadding}
          onChange={setOrderCount}
          placeholder="Amount of orders"
        />
      </div>
      <div className={cardRowStyle}>
        <Button
          onClick={onSetMessageRevenueClick}
          theme={THEMES.button}
          className={THEMES.button.blueButton}
          primary
          raised
          disabled={
            isLoading
            || !revenue
            || !orderCount
          }
          label="Set message orders"
        />
      </div>
    </>
  )
}

SetMessageRevenue.propTypes = {
  cardRowStyle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withStyles(styles)(SetMessageRevenue)
