import getInitialState from './defaults/mediaMarketSearch'

const defaultSources = [
  'id', 'accountname', 'description', 'logo', 'facebookCoverPhoto', 'coverPhotoOffsetY',
  'dedicatedListSize', 'averageSignUpsPerCampaign', 'socialMediaImpressions',
  'tags', 'ratingAvg', 'ratingCount', 'degree', 'firstDegreePartners', 'secondDegreePartners',
]

export default type => ({
  model: type === 'sellers' ? 'mediaMarketSearch' : 'mediaMarketBuyersSearch',
  initialState: getInitialState(type),
  endpoint: '/search-media-market',
  sources: [
    ...defaultSources,
    ...(type === 'sellers' ? ['audienceDescription', 'ageTarget', 'genderTarget'] : []),
  ],
  inputName: ['accountname', 'description', 'audienceDescription', 'meta'],
  aggs: {
    marketplace: {
      filter: {},
      aggs: {
        marketplace: {
          terms: {
            field: 'marketplace.raw',
          },
        },
      },
    },
    genderTarget: {
      filter: {},
      aggs: {
        genderTarget: {
          terms: {
            field: 'genderTarget.raw',
          },
        },
      },
    },
    ageTarget: {
      filter: {},
      aggs: {
        ageTarget: {
          terms: {
            field: 'ageTarget.raw',
          },
        },
      },
    },
    tags: {
      filter: {},
      aggs: {
        tags: {
          terms: {
            field: 'tags.raw',
            size: 100,
          },
        },
      },
    },
  },
  rangeFilters: [
    'listSizeValue',
    'totalSocialAudience',
  ],
  facets: ['tags', 'marketplace', 'genderTarget', 'ageTarget'],
  limitedFacets: ['genderTarget', 'ageTarget'],
  extendQuery({ body, state }) {
    const { filter } = body.query.bool

    if (!state.searchValue) {
      filter.push({
        term: {
          inactive: false,
        },
      })
    }

    // Recent Activity
    // accepts 'older', 90, 60, 30 as parameters
    if (type === 'buyers' && state.recentActivity && state.recentActivity.value && !state.searchValue) {
      const lastActivity = {}
      if (state.recentActivity.value !== 'older') {
        lastActivity.gte = `now-${state.recentActivity.value}d`
      }
      filter.push({
        range: {
          lastActivity,
        },
      })
    }

    const typeMap = {
      sellers: 'mediaMarketSeller',
      buyers: 'mediaMarketBuyer',
    }

    filter.push({
      bool: {
        must: [{
          terms: {
            'marketplace.raw': [typeMap[type]],
          },
        }],
      },
    })

    return body
  },
})
