import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { P, H3 } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

import SplitTrafficBadge from './SplitTrafficBadge'

const styles = {
  wrapper: {
    display: 'flex',
  },
  logoWrapper: {
    height: '100%',
    display: 'inline-block',
  },
  logo: {
    width: 78,
    height: 78,
    border: `1px solid ${COLOURS.foggy}`,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  content: {
    height: 78,
    alignSelf: 'flex-end',
    display: 'inline-block',
  },
  nameStatus: {
    display: 'flex',
    marginLeft: 24,
  },
  name: {
    marginRight: 36,
    maxWidth: 245,
  },
}

const stateToProps = ({ currentCampaign, currentBrand }) =>
  ({ campaign: currentCampaign, currentBrand })

function SplitTrafficHostStatus(props) {
  const {
    classes: css,
    currentBrand,
    campaignStatus,
    campaignName,
    host,
  } = props

  const getStatus = () => {
    switch (campaignStatus) {
      case 'notStarted':
        return host.isPostEntryReady ? 'ready' : 'notReady'
      case 'running':
        return host.isPostEntryReady ? 'live' : 'notLive'
      default:
        return 'completed'
    }
  }

  return (
    <div className={css.wrapper}>
      <div className={css.logoWrapper}>
        <P style={{ marginBottom: 12 }}>
          <em>Host Status</em>
        </P>
        <div className={css.logo}>
          <img src={currentBrand.logo} />
        </div>
      </div>
      <div className={css.content}>
        <div className={css.nameStatus}>
          <div className={css.name}>
            <H3 overflowEllipsis multiline>
              <small>{campaignName}</small>
            </H3>
            <P multiline>
              Sms Marketing
            </P>
          </div>
          <SplitTrafficBadge isStatus status={getStatus()} />
        </div>
      </div>
    </div>
  )
}

SplitTrafficHostStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  host: PropTypes.object.isRequired,
  campaignStatus: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
}

const ReduxdSplitTrafficHostStatus = connect(stateToProps)(SplitTrafficHostStatus)

export default React.memo(injectCSS(styles)(ReduxdSplitTrafficHostStatus))
