import React from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import caseMap from 'util/case-study-map'
import Header from '../header/Header'
import theme from 'css/themes/homePage/case-studies.css'

class CaseStudy extends React.Component {
  render() {
    let { match: { params: { case: caseStudy } } } = this.props
    caseStudy = caseMap[caseStudy]

    if (!caseStudy) {
      this.props.history.replace('/resources/all')
    }

    return (
      <div className={ theme.banner }>
        <Helmet>
          <meta property="og:url" content={ `https://${caseStudy.url}` } />
          <meta property="og:title" content={ caseStudy.title } />
          <meta property="og:description" content={ caseStudy.description } />
          <meta property="og:image" content={ caseStudy.image } />
        </Helmet>
        <Header lightBackground />
        <iframe src={ `https://drive.google.com/viewerng/viewer?embedded=true&url=${caseStudy.url}` }
          className={ theme.frame }
        ></iframe>
      </div>
    )
  }
}

export default withRouter(CaseStudy)
