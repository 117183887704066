import React from 'react'
import Text from 'util/components/text'

class SubNavTab extends React.Component {
  clickFunction = () => {
    if (this.props.changeTab) {
      this.props.changeTab(this.props.name)
    }
  }

  isCurrentTab = () => {
    return this.props.currentTab == undefined ? false : this.props.currentTab == this.props.name
  }

  render() {
    let currentTab = this.isCurrentTab()

    return (
      <span style={{
        marginRight: '30px',
        display: 'inline-table'
      }}>
        <a
          onClick={ this.clickFunction }
          href={ this.props.href }
          style={{
            minHeight: '42px',
            height: '42px',
            display: 'table-cell',
            verticalAlign: 'middle',
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
            borderBottomColor: currentTab ? COLOURS.azure : 'transparent',
            borderTopWidth: '2px',
            borderTopStyle: 'solid',
            borderTopColor: 'transparent',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
          { ...this.props }>
          <Text.dojoFinePrint styles={[{
            color: currentTab ? COLOURS.azure : 'inherit',
            fontFamily: "'Larsseit'"
          }]}>
            { this.props.copy }
          </Text.dojoFinePrint>
        </a>
      </span>
    )
  }
}

export default SubNavTab
