import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import BarGraph from 'visual-components/util/graphs/bar-graph'
import PercentDisplay from '../util/percent-display'
import BarGraphHeader from '../util/bar-graph-header'

class YourEntryContributionGraph extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    live: PropTypes.bool,
  }

  static defaultProps = {
    live: false,
  }

  render() {
    const {
      currentBrand = {},
      totalEntries = currentBrand.total_entries_delivered,
      entriesDriven = currentBrand.entries,
    } = this.props.campaign

    const {
      live,
    } = this.props

    const percentage = totalEntries
      ? Math.round((entriesDriven / totalEntries) * 100)
      : 0

    return (
      <div>
        <BarGraphHeader live={live} text="YOUR ENTRY CONTRIBUTION" />
        <BarGraph live={live} progress={percentage} />
        <PercentDisplay live={live} percentage={percentage} />
      </div>
    )
  }
}

export default YourEntryContributionGraph
