import React from 'react'
import { func, object } from 'prop-types'
import moment from 'moment-timezone'
import { Card } from 'react-toolbox/lib/card'
import { List } from 'react-toolbox/lib/list'

import Grid from 'visual-components/util/no-padding-grid/grid'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'

import NotificationTheme from 'css/themes/NotificationTheme.css'

import NotificationShell from './notification-shell'
import NoNotifications from './no-notifications'

class NotificationCardContainer extends React.Component {
  static propTypes = {
    handleRead: func.isRequired,
    notifications: object.isRequired,
  }

  withShell = ({
    id, presentational, createdAt, read_at, brand_id, type,
  }) => {
    const text = presentational.textFormatted || presentational.text
    if (!text) return null

    const { handleRead } = this.props
    return (
      <NotificationShell
        id={id}
        key={id}
        img={presentational.img}
        date={moment(createdAt).toDate()}
        to={presentational.to}
        read={!!read_at}
        brandId={brand_id}
        type={type}
        handleRead={handleRead}
        text={text}
      />
    )
  }

  render() {
    const {
      notifications: { items, loading },
    } = this.props

    return (
      <div>
        {!items || !items.length ? (
          loading ? null : (
            <NoNotifications message="You don’t have any notifications yet! Don’t worry - as you start getting involved in campaigns and connecting, you’ll see this area fill up with important info." />
          )
        ) : (
          <Card>
            <Grid>
              <List selectable ripple theme={NotificationTheme}>
                {items.map(this.withShell)}
              </List>
            </Grid>
          </Card>
        )}
        <MaterialSpinner when={loading} style={{ paddingTop: '40px' }} />
      </div>
    )
  }
}

export default NotificationCardContainer
