import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import moment from 'moment-timezone'

import { Card } from 'react-toolbox/lib/card';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import CampaignPreviewSpecs from './campaign-preview-specs'
import CampaignPreviewPartnerList from './campaign-preview-partners-list';
import CampaignPreviewBlueCards from './campaign-preview-blue-cards';

import { Link } from 'react-router-dom'
import { H1, H5, P } from 'visual-components/util/texts';
import Badge from 'visual-components/util/badge';

class CampaignDetailCard extends React.Component {
  onClick = (e) => {
    const { onclick } = this.props

    if (onclick) {
      onclick(e)
    }
  }

  render() {
    let {
      id: campaignId,
      name,
      mainImage,
      minimumListSize: minListSize,
      estimatedEntries: estEnties,
      minimumEntriesRequired: minEntriesReq,
      industry: tags,
      partners,
      startDate,
      hostBrandLogo: hostBrandImage,
      imagePos: style,
    } = this.props.campaign;

    style = style || { zoom: 100, x: 100, y: 100 }

    const partnerImages = !partners ? [] : partners.map(partner => partner.logo)
    const campaignPictureSrc = mainImage && mainImage.endsWith('main.png') ? mainImage.replace('main.png', 'thumb.png') : mainImage
    const isFull = (partners.length === 7) ? true : false
    const isVideo = campaignPictureSrc && campaignPictureSrc.endsWith('.mp4')

    return(
      <Link to={ `/explore/campaigns/${campaignId}/details` } onClick={ this.onClick }>
        <Card style={{
          width: '368px',
          display: 'inline-flex',
          marginRight: '16px',
          marginBottom: '12px',
          position: 'relative'
        }}>
          <Grid style={{
            marginLeft: '0'
          }}>
            <Row>
              <div style={{
                width: '100%',
                height: '120px',
                background: `url(${campaignPictureSrc || '/images/defaults/campaign-cover-image@2x.png'}) no-repeat center`,
                position: 'relative',
                backgroundSize: '100%',
                backgroundPosition: `0% ${style.y ? 100 - style.y + '%' : '0%'}`,
                overflow: 'hidden',
              }}>
                { isVideo ? (
                  <video key={campaignPictureSrc} style={{ minWidth: '368px', height: '120px', objectFit: 'cover' }} preload="metadata" muted>
                    <source src={campaignPictureSrc} type="video/mp4" />
                  </video>
                ) : null }
              </div>
            </Row>
            <Row>
              <Col xs={12}>
                <div style={{
                  textAlign: 'left',
                  paddingTop: '12px',
                  paddingRight: '12px',
                  paddingBottom: '12px',
                  paddingLeft: '12px',
                  width: '368px'
                }}>
                  <div style={{
                    paddingTop: '3px'
                  }}>
                    <H5 overflowEllipsis>
                      { name }
                    </H5>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{
                width: '60px'
              }}>
                <div style={{
                  paddingTop: '5px',
                  paddingRight: '16px',
                  paddingBottom: '0',
                  paddingLeft: '16px'
                }}>
                  <div style={{
                    maxWidth: '50px',
                    float: 'left',
                    textAlign: 'center'
                  }}>
                    <div style={{
                      marginBottom: '8px'
                    }}>
                      <P>
                        <i>
                          { moment(startDate).format('MMM').toUpperCase() }
                        </i>
                      </P>
                    </div>
                    <H1 seaGreen>
                      { moment(startDate).format('D') }
                    </H1>
                  </div>
                </div>
              </Col>
              <Col style={{
                width: '150px'
              }}>
                <CampaignPreviewSpecs
                  minListSize={ minListSize }
                  estEnties={ estEnties }
                  minEntriesReq={ minEntriesReq }
                />
              </Col>
              <Col style={{
                width: '148px'
              }}>
                <CampaignPreviewPartnerList
                  hostBrandImage={ hostBrandImage }
                  partnerImages={ partnerImages }
                />
              </Col>
            </Row>
            <CampaignPreviewBlueCards
              tags={ [ tags ] }
              fullCampaign={isFull}
            />
          </Grid>
        </Card>
      </Link>
    )
  }
}

CampaignDetailCard.propTypes = {
  campaign: PropTypes.object.isRequired,
  onclick: PropTypes.func
}

export default CampaignDetailCard;
