import { PureComponent } from 'react';
import {number, string, instanceOf } from 'prop-types'
import moment from 'moment-timezone';
import d3 from 'd3';
import { Card } from 'react-toolbox/lib/card';
import { H3, H6, P, Small } from 'visual-components/util/texts';
import Badge from 'visual-components/util/badge';

export default class BasicsCard extends PureComponent {
  static propTypes = {
    description: string.isRequired,
    startDate: instanceOf(Date).isRequired,
    endDate: instanceOf(Date).isRequired,
    prizing: string.isRequired,
    prizingValue: number.isRequired,
  }
  render() {
    const { description, startDate, endDate, prizing, prizingValue, badge } = this.props;

    return (
      <div style={{
        width: '384px',
        position: 'relative',
      }}>
        <Card style={{
          width: '100%',
          height: '320px',
          paddingTop: '16px',
          paddingRight: '5px',
          paddingBottom: '16px',
          paddingLeft: '20px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
          overflow: prizing && prizing.length > 375 ? 'scroll' : undefined // temporary fix for campaign descriptions made before character limits were set
        }}>
          <div>
            <div style={{ display : 'flex', justifyContent : 'space-between', paddingRight : '7px' }}>
              <H3 coral>
                <small>
                  Basics
                </small>
              </H3>
              { badge && <Badge label={ badge.text } color={ badge.colour } /> }
            </div>
            <Small multiline style={{marginTop: '8px', marginRight: '2px'}}>
              { description }
            </Small>
            <Small style={{marginTop: '16px'}}>
              <em>
                Dates
              </em>
            </Small>
            <div style={{marginTop: '4px'}}>
              <H6 azure>
                { moment(startDate).format('LL') } - { moment(endDate).format('LL') }
              </H6>
            </div>
            <div style={{marginTop: '16px', display: 'flex'}}>
              <div style={{width: '288px', display: 'inline-block'}}>
                <Small>
                  <em>
                    Prizing
                  </em>
                </Small>
                <Small multiline style={{marginTop: '4px', marginBottom: '20px'}}>
                  { prizing }
                </Small>
              </div>
              <div style={{width: '44px', marginLeft: '12px', display: 'inline-block'}}>
                <Small>
                  <em>
                    Value
                  </em>
                </Small>
                <Small multiline style={{marginTop: '4px'}}>
                  { d3.format('$,')(prizingValue) }
                </Small>
              </div>
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
