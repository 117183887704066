import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Col from 'visual-components/util/no-padding-grid/col'

import { H2, H6 } from 'visual-components/util/texts'

import TextThemes from 'css/themes/Text.css'
import { makeNumbersPretty } from 'util/makeNumbersPretty'

class SmallProfileStat extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    marginRight: PropTypes.string,
    obscure: PropTypes.bool,
  }

  static defaultProps = {
    marginRight: '24px',
    obscure: false,
  }

  render() {
    const {
      title,
      value,
      marginRight,
      obscure,
    } = this.props

    const formatted = makeNumbersPretty(value, true)

    return (
      <div>
        {
          value
            ? (
              <Col>
                <div style={{ marginRight }}>
                  <div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
                    <div style={{ marginBottom: '8px' }}>
                      <H6>
                        { title }
                      </H6>
                    </div>
                  </div>
                </div>
                <H2>
                  <span className={obscure ? TextThemes.obscure : ''}>
                    { obscure ? '****' : formatted }
                  </span>
                </H2>
              </Col>
            ) : null
        }
      </div>
    )
  }
}

export default SmallProfileStat
