import AppDispatcher from 'dispatchers/app-dispatcher';

var stripeTokenHandler = function(token, setDefault) {
  return new Promise(function (res, rej) {
    AppDispatcher.http({
      url: '/add-card-token',
      method: 'POST',
      data: {
        token,
        setDefault,
      },
      success: function(card) {
        AppDispatcher.load({
          model: 'customer',
          endpoint: '/customer',
          success: function (customer) {
            store.dispatch({
              model: 'customer',
              data: {
                selectedSource: card && card.id,
              },
              type: 'UPDATE_ATTR'
            })
            res(card.id);
          }
        });
      },
      error: function (err) {
        rej(err);
      }
    })
  });
}

var updateAttr = function (attr, value) {
  store.dispatch({
    model: 'card',
    data: {
      [attr]: value
    },
    type: 'UPDATE_ATTR'
  })
}

var updateBilling = function(setDefault) {

  return new Promise(function (res, rej) {
    var cardModel = store.getState().card;
    var card = card = cardModel.card
    if (!cardModel.adding) {
      return res();
    }
    var form = document.querySelector('#payment-form');

    var extraDetails = {
      name: form.querySelector('input[name=cardholder-name]').value,
      address_zip: form.querySelector('input[name=address-zip]').value
    };

    if (!extraDetails.name) {
      return rej(updateAttr('missingName', true))
    }

    if (!extraDetails.address_zip) {
      return rej(updateAttr('missingZip', true))
    }

    updateAttr('loading', true)
    stripe.createToken(cardModel.card, extraDetails).then(function(result) {

      if (result.error) {
        updateAttr('loading', false)

        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        return rej();
      } else {
        // Send the token to your server
        stripeTokenHandler(result.token, setDefault).then(function (card) {
          updateAttr('loading', false);
          updateAttr('adding', false);
          res(card);
        }).catch(function (err) {
          updateAttr('loading', false)

          let errorElement = document.getElementById('card-errors');
          errorElement.textContent = err.responseText;
          rej(err);
        });
      };
    });
  });
}

export default {
  stripeTokenHandler,
  updateBilling
}
