import { merge, findIndex } from 'lodash'

const initialState = {
  rid: 'dealsIntegrations',
  loading: false,
  campaignsLoading: true,
  dealIntegrations: [],
  dealIntegration: {},
}

export default function dealIntegrationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_DEAL_INTEGRATIONS_ATTR':
      return merge({}, state, action.data)

    case 'CLEAR_INTEGRATION':
      return { ...state, dealIntegration: {}, campaignsLoading: true }

    case 'LOADING_DEAL_INTEGRATIONS':
      return { ...state, loading: true, error: false }

    case 'LOADING_DEAL_INTEGRATIONS_CAMPAIGNS':
      return { ...state, campaignsLoading: true }

    case 'LOAD_DEAL_INTEGRATIONS_FINISH':
      return { ...state, loading: false, error: false }

    case 'LOAD_DEAL_INTEGRATIONS_SUCCESS':
    case 'DELETE_DEAL_INTEGRATIONS_SUCCESS':
      return { ...state, loading: false, dealIntegrations: action.payload }

    case 'LOAD_DEAL_INTEGRATION_SUCCESS':
      return { ...state, loading: false, dealIntegration: action.payload }

    case 'UPDATE_DEAL_INTEGRATIONS_SUCCESS': {
      const { dealIntegrations } = state
      const index = findIndex(dealIntegrations, integration =>
        integration.id === action.payload.id)

      dealIntegrations[index] = action.payload

      return {
        ...state,
        loading: false,
        campaignsLoading: false,
        campaignsReady: false,
        dealIntegrations,
        dealIntegration: action.payload,
      }
    }

    case 'LOADING_DEAL_INTEGRATIONS_CAMPAIGNS_SUCCESS':
      return {
        ...state,
        campaignsReady: true,
        dealIntegration: merge(
          {},
          state.dealIntegration,
          { details: { campaigns: action.payload } }
        ),
      }

    case 'LOAD_DEAL_INTEGRATIONS_FAILURE':
    case 'LOADING_DEAL_INTEGRATIONS_FAILURE':
    case 'UPDATE_DEAL_INTEGRATIONS_FAILURE':
    case 'DELETE_DEAL_INTEGRATIONS_FAILURE':
      return {
        ...state,
        loading: false,
        campaignsLoading: false,
        error: action.payload.response ? `${action.payload.response.status}: ${action.payload.response.data}` : 'Error',
      }

    default:
      return state
  }
}
