import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone'

import { H6 } from 'visual-components/util/texts'

class SingleCalendar extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
    onChange: PropTypes.func,
    minDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
    maxDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
  }

  static defaultProps = {
    title: 'Calendar Input',
    date: new Date(),
    onChange() {},
    maxDate: null,
    minDate: null,
  }

  state = {
    show: false,
  }

  componentDidMount() {
    const { date } = this.props

    this.setState({ date: moment(date) })
  }

  // If not shown, then show, else, toggle
  onFocusChange = ({ focused }) => this.setState(({ show }) => ({ show: !focused ? focused : !show }))

  onDateChange = date => {
    const { onChange } = this.props

    this.setState({ date }, () => onChange(date))
  }

  isDayDisabled = day => {
    const { maxDate, minDate } = this.props

    if (maxDate || minDate) return (minDate && moment(minDate).isAfter(day)) || (maxDate && moment(maxDate).isBefore(day))

    return false
  }

  render() {
    const { title } = this.props
    const { show, date } = this.state

    return (
      <div style={{ width: '100%' }}>
        <div style={{ margin: '16px 0 8px' }}>
          <H6>
            { title }
          </H6>
        </div>
        <div className="single-calendar remove-caret alone">
          <button type="button" onClick={this.onFocusChange}>
            <img src="/images/react-dates-clock-icon.png" />
          </button>
          <SingleDatePicker
            date={moment(date)}
            onDateChange={this.onDateChange}
            focused={show}
            onFocusChange={this.onFocusChange}
            displayFormat="MMM DD, YYYY"
            isDayBlocked={this.isDayDisabled}
          />
        </div>
      </div>
    )
  }
}

export default SingleCalendar
