import React, { Component } from 'react';
import COLOURS from 'util/colours';
import Text from 'util/components/text';

var base = {
};

var labelStyle = {
  lineHeight: "16px"
}

var inputStyle = {
  cursor: "pointer",
  position: 'absolute',
  WebkitAppearance: "none",
  appearance: "none",
  backgroundColor: "transparent",
  borderRadius: "none",
  boxSizing: "border-box",
  boxSizing: "content-box",
  width: "20px",
  height: "20px",
  marginTop: '-15px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: COLOURS.cerulean,
  transition: "all .1s linear",
  outline: "none",
  ':checked': {
    borderColor: COLOURS.cerulean,
    backgroundImage: 'url("/images/pricing/chart-icons/blue-check.svg")'
  }
}

class Checkbox extends Component {
  render() {
    let checkboxStyles = base
    if ( this.props.styles ) {
      if (Array.isArray(this.props.styles)) {
        checkboxStyles = Object.assign({}, checkboxStyles, ...this.props.styles);
      } else {
        checkboxStyles = Object.assign({}, checkboxStyles, this.props.styles);
      }
    }

    let labelStyles = labelStyle
    if ( this.props.labelStyles ) {
      if (Array.isArray(this.props.labelStyles)) {
        labelStyles = Object.assign({}, labelStyles, ...this.props.labelStyles)
      } else {
        labelStyles = Object.assign({}, labelStyles, this.props.labelStyles)
      }
    }
    var inputStyles = inputStyle

    if (Array.isArray(this.props.inputStyles)) {
      inputStyles = Object.assign({}, inputStyles, ...this.props.inputStyles)
    } else {
      inputStyles = Object.assign({}, inputStyles, this.props.inputStyles)
    }

    var disabled = false;
    if (typeof this.props.editable !== 'undefined' && !this.props.editable) {
      disabled = true;
      inputStyles = Object.assign({}, inputStyles, {
        cursor: 'not-allowed',
        borderColor: COLOURS.cloudGrey,
      })
      labelStyles = Object.assign({}, labelStyles, {cursor: 'not-allowed'})
    }

    var value = this.props.feedbackValue ? this.props.feedbackValue : this.props.value;
    return (
      <div className="form-group checkbox"
        style={ checkboxStyles }
        >
        <label style={ labelStyles }>
          <div style={{
            display: 'inline-block',
            marginRight: '15px'
          }}>
            <input
              onChange={ this.props.toggleChecked }
              type="checkbox"
              id={ this.props.id }
              style={ inputStyles }
              checked={ this.props.value }
              disabled={ disabled }
              value={ value } />
          </div>
          <Text.dojoP styles={[labelStyles]}>
            { this.props.label }
          </Text.dojoP>
        </label>
      </div>
    );
  }
}

export default Checkbox;
