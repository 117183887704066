import React from 'react';
import _ from 'lodash'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Spinner from 'util/components/spinner';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import { Button } from 'react-toolbox/lib/button';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import ProfileLogo from 'visual-components/util/profile-logo';
import CampaignDetails from 'visual-components/campaignProfile/campaign-details';
import CampaignImage from 'visual-components/campaignProfile/campaign-image';
import CampaignTabs from 'visual-components/campaignProfile/campaign-tabs';
import CampaignDetailsTab from 'visual-components/campaignProfile/campaign-details-tab';
import CampaignPartnersTab from 'visual-components/campaignProfile/campaign-partners-tab';
import ApplyToCampaignModal from './apply-to-campaign-modal';
import ApplicationSentModal from './application-sent-confirmation-modal';

import CampaignDetailDispatcher from 'dispatchers/campaign-detail-dispatcher';
import PricingDispatcher from 'dispatchers/pricing-gate-dispatcher';

import { startApplication } from 'actions/partnership-application-actions'
import * as partnershipApplicationActions from 'actions/partnership-application-actions';
import ButtonTheme from 'css/themes/Buttons.css'

const buttonTheme = {
  Button: ButtonTheme,
}

const mapStateToProps = function (state) {
  return {
    campaign: state.campaign,
    currentBrand: state.currentBrand,
    applicationSentModalOpen: state.partnershipApplication.applicationSentModalOpen
  }
}

const mapDispatchToProps = dispatch => ({
  applicationActions: bindActionCreators(partnershipApplicationActions, dispatch)
})


class CampaignProfile extends React.Component {
  constructor(props) {
    super(props);

    this.applyToCampaign = this.applyToCampaign.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    var campaignId = this.props.match.params.campaignId;
    CampaignDetailDispatcher.loadCampaign(campaignId, () => {
      const { apply = false } = queryString.parse(this.props.location.search)

      if (apply) return this.applyToCampaign()
    });
  }

  applyToCampaign() {
    let campaign = this.props.campaign;
    let currentBrand = this.props.currentBrand;

    if (!currentBrand.logo || currentBrand.logo.indexOf('Empty-Avatar.png') >= 0) {
      store.dispatch({
        model: 'uploadLogoToApplyModal',
        type: 'UPDATE_ATTR',
        data: {
          open: true
        }
      })
      return;
    }

    PricingDispatcher.check('sweepstakes.partnershipApplication.create', function () {
      store.dispatch(startApplication(campaign, currentBrand));
    });
  }

  render() {
    const {
      campaign,
      currentBrand,
    } = this.props;

    if (!campaign.loaded) {
      return (
        <Spinner />
      )
    }

    const acceptedPartners = campaign.partners.filter(partner => partner.status === 'accepted')
    const numberOfPartners = acceptedPartners.length

    var subTab = this.props.match.params.subTab;
    var currentBrandInvite = _.find(campaign.invites, { invitee_id: currentBrand.id }) || { status: 'missing' };

    var that = this;
    var action = {
      missing: function () {
        return {
          label: 'Apply to Join',
          action: that.applyToCampaign
        }
      },
      accepted: function () {
        return {
          label: 'Accepted'
        }
      },
      rejected: function () {
        return {
          label: 'Re-Apply',
          action: that.applyToCampaign
        }
      },
      interested: function () {
        return {
          label: currentBrandInvite.application ? 'Applied' : 'Invited'
        }
      },
      pending: function () {
        return {
          label: currentBrandInvite.application ? 'Applied' : 'Invited'
        }
      }
    }[currentBrandInvite.status]();

    let campaignDetails = (
      <CampaignDetails
        startDate={ campaign.startDate }
        endDate={ campaign.endDate }
        description={ campaign.description }
        industry={ campaign.industry }
        noPartnerLimit={ campaign.noPartnerLimit }
        numPartners={ numberOfPartners }
      />
    );

    let tabOptions = [{
      name: 'details',
      title: 'Details',
      href: `/explore/campaigns/${campaign.id}/details`,
      component: CampaignDetailsTab
    }, {
      name: 'partners',
      title: 'Partners',
      href: `/explore/campaigns/${campaign.id}/partners`,
      component: CampaignPartnersTab,
      partnerCount: campaign.visiblePartnerCount
    }];

    let campaignElements = campaign.campaignElements[0];

    let nameTitle = campaign.name || ''
    if (nameTitle.length >= 20) {
      nameTitle = `${nameTitle.slice(0, 17)}...`
    }
    nameTitle = `${nameTitle && ': '}${nameTitle}`

    const prefix = process.env.SENTRY_ENV === 'production' ? 'https://do-not-use-this-link.dojomojo.com' : ''

    return(
      <Grid style={{
        width: '100%'
      }}>
        <Helmet>
          <title>{`Campaign Profile${nameTitle}`}</title>
        </Helmet>
        <Row start="xs"
          style={{
            paddingTop: '32px'
        }}>
          <Col style={{
            width: '216px'
          }}>
            <div style={{
              height: '384px'
            }}>
              <div>
                <Link to={`/explore/brands/${campaign.hostBrand.id}`} >
                  <ProfileLogo
                    imgSrc={ campaign.hostBrand.logo }
                    accountname={ campaign.hostBrand.accountname }
                  />
                </Link>
              </div>
              <div>
                {
                  campaign.hostBrandId != currentBrand.id ?
                    new Date(campaign.startDate) > new Date() && action.action ?
                      !campaign.noPartnerLimit && numberOfPartners >= 8 ?
                        <Button onClick={ action.action }
                          label={ 'Apply to Join' }
                          className={ buttonTheme.Button.disabledButton }
                          theme={ buttonTheme.Button }
                          disabled
                          style={{
                            fontFamily: 'Larsseit-Medium',
                            fontSize: '14px',
                            width: '116px',
                            height: '36px'
                        }}/>
                      :
                        <Button onClick={ action.action }
                          label={ action.label }
                          className={ buttonTheme.Button.greenButton }
                          theme={ buttonTheme.Button }
                          raised
                          style={{
                            fontFamily: 'Larsseit-Medium',
                            fontSize: '14px',
                            width: '116px',
                            height: '36px'
                        }}/>
                    :
                    <Button label={ action.label }
                      className={ buttonTheme.Button.disabledButton }
                      theme={ buttonTheme.Button }
                      disabled
                      style={{
                        fontFamily: 'Larsseit-Medium',
                        fontSize: '14px',
                        width: '116px',
                        height: '36px'
                    }}/>
                  : null
                }
                <a href={`${prefix}/landing/campaign/${campaign.id}?preview=true`} target='_blank'>
                  <Button
                    label="Preview"
                    className={ buttonTheme.Button.whiteButton }
                    theme={ buttonTheme.Button }
                    raised
                    style={{
                      fontFamily: 'Larsseit-Medium',
                      fontSize: '14px',
                      width: '82px',
                      height: '36px',
                      marginLeft: campaign.hostBrandId != currentBrand.id ? '10px' : '0'
                  }}/>
                </a>
              </div>
            </div>
            <div>
              { campaignDetails }
            </div>
          </Col>
          <Col style={{
            width: '944px',
            marginLeft: '16px'
          }}>
            <div>
              <CampaignImage
                imgSrc={ campaignElements.mainImage }
                name={ campaign.name }
                style={{
                  zoom: campaignElements.styles && campaignElements.styles.imagePos ? campaignElements.styles.imagePos.zoom : 100,
                  x: campaignElements.styles && campaignElements.styles.imagePos ? campaignElements.styles.imagePos.x : 0,
                  y: campaignElements.styles && campaignElements.styles.imagePos ? campaignElements.styles.imagePos.y : 0
              }}/>
            </div>
            <div>
              <CampaignTabs tabOptions={ tabOptions }
                selectedTab={ subTab }
                campaign={ campaign }
                currentBrand={ currentBrand }
                isHost={ campaign.hostBrandId == currentBrand.id }
              />
            </div>
          </Col>
        </Row>
        <ApplyToCampaignModal />
        <ApplicationSentModal
          closeModal = {this.props.applicationActions.closeApplicationSentModal}
          open = {this.props.applicationSentModalOpen}
        />
      </Grid>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignProfile))
