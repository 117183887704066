import React from 'react';
import * as ReactRedux from 'react-redux';

import EditLogo from 'visual-components/brandProfile/EditLogo';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand
  };
}

class EditLogoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.uploadSuccess = this.uploadSuccess.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleToggle = this.handleToggle.bind(this);

    this.state = {
      logo: props.currentBrand.logo,
      active: false
    }
  }

  uploadSuccess(src) {
    this.setState({
      logo: src
    })
  }

  handleToggle() {
    this.setState({
      active: !this.state.active
    });
  }

  cancel() {
    this.handleToggle()
  }

  save() {
    CurrentBrandDispatcher.updateAttrPersist({
      logo: this.state.logo
    })

    this.handleToggle();
  }

  render() {
    var {
      logo,
      active
    } = this.state;

    return (
      <EditLogo
        logo={ logo }
        handleToggle={ this.handleToggle }
        active={ active }
        uploadSuccess={ this.uploadSuccess }
        save={ this.save }
        cancel={ this.cancel }
      />
    )
  }
}

export default ReactRedux.connect(mapState)(EditLogoContainer);
