import React from 'react';

import PrimaryButton from 'util/components/buttons/primary-button';
import COLOURS from 'util/colours';
import SplashTheme from 'css/themes/splash.css'

class SmallSplash extends React.Component {
  render() {
    return(
      <div>
        <div className={ SplashTheme.hidden600 }>
          <div style={{
            height: '913px',
            background: COLOURS.cerulean
          }} />
          <div style={{
            height: '129px',
            background: COLOURS.seaGreen
          }} />
          <div style={{
            height: '2367px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '204px',
            background: COLOURS.azure
          }} />
          <div style={{
            height: '2115px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '130px',
            background: COLOURS.blues.one
          }} />
          <div style={{
            height: '903px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '204px',
            background: COLOURS.coral
          }} />
          <div style={{
            height: '1773px',
            background: COLOURS.white
          }} />
          <div style={{
            height: '637px',
            background: COLOURS.cerulean
          }} />
        </div>
        <div style={{
          maxWidth: '600px',
          marginRight: 'auto',
          position: 'absolute',
          marginLeft: 'auto',
          top: '0',
          left: '0',
          right: '0'
        }}>
          <img src='/images/splash-page/Landing-Page-Mobile.png'
            style={{
              width: '100%',
              marginBottom: '-50px'
            }}/>
          
          <a href="/app"
            style={{
              left: '50%',
              bottom: '0',
              position: 'absolute',
              paddingBottom: '65px'
          }}>
            <PrimaryButton greenButton={ true }
              styles={[{
                marginLeft: '-110px'
            }]}>
              Check it out
            </PrimaryButton>
          </a>
        </div>
      </div>
    )
  }
}

export default SmallSplash;
