import React from 'react';
import AdminRow from './admin-row';
import _ from 'lodash';

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    var presentation = this.props.admin.presentation;

    var colors = ['white', 'green', 'black'];

    var dateValues = _.map(this.props.dates, function (date) {
      var data = {
        emails: {
          black: 0,
          white: 0,
          green: 0
        },
        subs: {
          black: 0,
          white: 0,
          green: 0
        },
        dateMatch: date + 'T00:00:00.000Z',
        date: date,
        dateVal: new Date(date)
      }

      // var subs = presentation.subscriptions[date];

      // _.each(colors, function (color) {
      //   var colorSubs = _.filter(subs, { belt: color });

      //   _.each(colorSubs, function (sub) {
      //     data.subs[color] += 1;
      //     var emails = _.find(presentation.totalEmailsAcquireds, { brand_id: sub.brand_id, date_trunc: data.dateMatch });
      //     if (emails) {
      //       data.emails[color] += emails.sum;
      //     }
      //   })
      // })

      return data;
    });


    var beltUsers = _.map(colors, function (color) {
      var values = _.map(dateValues, function (dateItem) {
        return d3.format(',')(dateItem.subs[color]);
      })
      return (
        <AdminRow vals={values} title={color} />
      )
    });

    const headerStyle = {
      borderTop: '1px solid',
      borderBottom: '3px solid'
    };


    var emailsAcquiredByBelt = _.map(colors, function (color) {
      var values = _.map(dateValues, function (dateItem) {
        return d3.format(',')(dateItem.emails[color]);
      })
      return (
        <AdminRow vals={values} title={color} />
      )
    });


    var emailsAcquiredByBeltAverage = _.map(colors, function (color) {
      var values = _.map(dateValues, function (dateItem) {
        var val = dateItem.emails[color] / dateItem.subs[color];
        if (isNaN(val)) {
          val = 0;
        }
        return d3.format(',')(val);
      })
      return (
        <AdminRow vals={values} title={color} />
      )
    });
    

    return (
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped table-hover table-responsive">
            <AdminRow className="info" vals={this.props.dates} style={headerStyle} />
            <tbody>
              <tr className="info"><td colSpan="5"><h4>Belt Users</h4></td></tr>
              {beltUsers}   
              <tr className="info"><td colSpan="5"><h4>Emails Acquired By Belt</h4></td></tr>
              {emailsAcquiredByBelt}   
              <tr className="info"><td colSpan="5"><h4>Emails Acquired By Belt Average</h4></td></tr>
              {emailsAcquiredByBeltAverage}            
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}


export default UserDetail;
