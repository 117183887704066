import React, { Component } from 'react'
import { array, bool, string } from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import * as metrics from 'util/metrics'

import BrandDetailCard from 'visual-components/shared/brand-detail-card/BrandDetailCard'
import EmptyBonzai from 'visual-components/brandProfile/empty-bonzai'

import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

const mapState = ({ currentBrand: { id: currentBrandId } }) => ({
  currentBrandId,
})

class ExploreBrandsResults extends Component {
  static propTypes = {
    brands: array.isRequired,
    loading: bool,
    error: string,
    recommended: bool,
  }

  openPlatformInviteModal = () => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'platformInviteBrand',
      data: {
        open: true,
        source_location: 'Explore Brands - No Brand Found'
      },
    })
  }

  track = (brandId, position) => {
    const { recommended } = this.props

    if (recommended) {
      metrics.create('exploreRecommendedClickedBrand', {
        meta: {
          position,
        },
      })
    } else if (brandId) {
      store.dispatch({
        type: 'UPDATE_ARR',
        model: 'general',
        data: {
          exploreBrand: brandId,
        },
      })
    }
  }

  render() {
    const { brands, loading, error, currentBrandId, searchValue } = this.props

    if (loading) {
      return null
    }

    if (!brands || !brands.length || error) {
      const description = error
        ? `Oops! There was an error processing your search. Please try again. ${error}`
        : searchValue
          ? `Can't find the brand you’re looking for? Invite them to the platform and get a $25 credit.`
          : `Whoops! We don’t have any results for that search. Try expanding your search for more results.`

      return (
        <div style={{
          width: '752px',
          textAlign: 'center'
        }}>
          <div style={{
            marginBottom: '26px'
          }}>
            <EmptyBonzai noTree={true} description={description} />
          </div>

          {
            searchValue ?
            <Button onClick={this.openPlatformInviteModal}
              label={'Invite Brand'}
              className={ButtonTheme.blueButton}
              theme={ButtonTheme}
              primary
              raised
            />
            : null
          }
        </div>
      )
    }

    const brandsToDisplay = brands.map((brand, idx) => (
      <BrandDetailCard
        key={brand.id}
        logo={brand.logo || ''}
        recommended={this.props.recommended}
        accountname={brand.accountname}
        dedicatedListSize={brand.dedicatedListSize}
        averageSignUpsPerCampaign={brand.averageSignUpsPerCampaign}
        socialMediaImpressions={brand.socialMediaImpressions}
        tags={brand.tags}
        degreeOfRemoval={brand.degree}
        coverPhotoOffsetY={brand.coverPhotoOffsetY || 0}
        facebookCoverPhoto={brand.facebookCoverPhoto || ''}
        ratingAvg={brand.ratingAvg}
        ratingCount={brand.ratingCount}
        brandId={brand.id}
        width="240px"
        currentBrandId={currentBrandId}
        onclick={e => this.track(brand.id, idx)}
        exploreBrandResults
      />
    ))

    return (
      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {brandsToDisplay}
      </div>
    )
  }
}

export default connect(mapState)(ExploreBrandsResults)
