import moment from 'moment-timezone'

import React, { Component } from 'react';
import Dialog from 'react-toolbox/lib/dialog';
import { connect } from 'react-redux';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import ModalBaseTheme from 'css/themes/dialogs/modalBase.css';
import { bindActionCreators } from "redux";
import * as CampaignTaskActions from 'actions/campaign-task-actions';
import CreateTaskTheme from 'css/themes/dialogs/createTask.css';
import { H4 } from 'visual-components/util/texts';

const mapStateToProps = (state) => {
  return {
    campaignTasks: state.campaignTasks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
};

const imgMap = {
  google: '/images/icons/google-logo.svg',
  ical: '/images/icons/apple-logo.svg',
  outlookcom: '/images/icons/microsoft-outlook-logo.svg'
}

const labelMap = {
  google: 'Google Calendar',
  ical: 'iCal',
  outlookcom: 'Outlook'
}

class LinkItem extends Component {
  render() {
    const {
      url,
      type
    } = this.props
    return (
      <a href={url} target="_blank" alt={`export_to_${type}`}>
        <div className={CreateTaskTheme.exportTaskItem}>
          <img src={imgMap[type]} />
          <H4>{labelMap[type]}</H4>
        </div>
      </a>
    )
  }
}

class ExportTask extends Component {
  render() {
    const {
      campaignTasks: {
        selectedTask
      },
      campaignTaskActions: {
        deselectTask
      }
    } = this.props

    if (!selectedTask) {
      return (null)
    }

    return(
      <Dialog
        active={ selectedTask.exportingTask }
        title={ "Export Task to Calendar" }
        onEscKeyDown={ deselectTask }
        onOverlayClick={ deselectTask }
        theme={ ModalBaseTheme }
      >
        <div className={ CreateTaskTheme.subTitle }>
          <H4 multiline>
            Export <em>{selectedTask.title}</em> for campaign <em>{selectedTask.campaignName}</em> to your calendar.
          </H4>
        </div>
        {
          selectedTask.exportLinks.map((link, idx) => {
            return <LinkItem key={idx} {...link} />
          })
        }
        <div style={{ marginBottom: '16px' }}>
          <ModalButtonGroup
            confirm={ deselectTask }
            confirmText={ "Close" }
            canSave
            hideLine
          />
        </div>

      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportTask);
