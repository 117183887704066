import React from 'react'
import { object, number, func } from 'prop-types'
import { H6, Small } from 'visual-components/util/texts'
import { Input } from 'visual-components/util/form'
import InputTheme from 'css/themes/Input.css'
import { isWebsite } from 'visual-components/util/form/validations'

class TargetLinkForm extends React.Component {
  static propTypes = {
    data: object.isRequired,
    index: number.isRequired,
    editLink: func.isRequired,
    removeLink: func.isRequired,
  }

  render() {
    const {
      data,
      index,
      editLink,
      removeLink,
    } = this.props

    const canRemove = !data.id && index !== 0

    return (
      <div>
        { data.editing
          ? (
            <div>
              <Input
                className={InputTheme.singleLineInput}
                name={`targets[${index}].label`}
                required
              />
              <img
                onClick={() => editLink(index)}
                src="/images/pricing/chart-icons/blue-check.svg"
                style={{
                  cursor: 'pointer',
                  width: '18px',
                  height: '14px',
                  position: 'relative',
                  top: '-12px',
                  left: '535px',
                }}
              />
            </div>
          ) : (
            <div
              style={{
                marginBottom: '4px',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '8px',
                }}
              >
                <H6>
                  {data.label}
                </H6>
              </div>
              <div
                onClick={() => editLink(index)}
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
              >
                <Small azure>
                  <em>
                    Edit
                  </em>
                </Small>
              </div>
            </div>
          )
        }
        <div
          style={{
            width: canRemove ? '552px' : '572px',
          }}
        >
          <Input
            name={`targets[${index}].target_url`}
            placeholder="http://www.companysite.com/seller-brand-promo"
            validations={[isWebsite]}
            required
            canRemove={canRemove}
            remove={removeLink}
          />
        </div>
      </div>
    )
  }
}

export default TargetLinkForm
