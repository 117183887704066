import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import CurrentCampaignCard from 'visual-components/shared/campaign-detail-card/CurrentCampaignCard/CurrentCampaignCard'
import ConfirmDeleteModal from 'components/campaigns/list/confirm-delete-modal'
import Spinner from 'util/components/spinner'

class CurrentCampaigns extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    currentCampaigns: PropTypes.object,
    upcomingCampaigns: PropTypes.object,
    history: PropTypes.object.isRequired,
    confirmDeleteCampaign: PropTypes.object.isRequired,
  }

  static defaultProps = {
    closeModal() {},
    currentCampaigns: null,
    upcomingCampaigns: null,
  }

  navigateTo = link => {
    const { history } = this.props
    history.push(link)
  }

  render() {
    const {
      confirmDeleteCampaign,
      currentCampaigns,
      upcomingCampaigns,
      closeModal,
    } = this.props

    if (!currentCampaigns.loaded || !upcomingCampaigns.loaded) {
      return (
        <Spinner />
      )
    }

    let errorText
    if (!currentCampaigns.campaigns.length && !upcomingCampaigns.campaigns.length) {
      errorText = 'You have no current sweepstakes.'
    }

    const campaigns = currentCampaigns.campaigns.map(campaign => (
      <CurrentCampaignCard
        key={campaign.id}
        campaignId={campaign.id}
        name={campaign.name}
        campaignPictureSrc={campaign.mainImage || '/images/defaults/coverPhotoFull.png'}
        type="current"
        entries={campaign.entries}
        estimatedEntries={campaign.estimatedEntries}
        ntfEmails={campaign.newEmails}
        partners={campaign.allPartners}
        partnerImages={campaign.partnerImages}
        startDate={campaign.startDate}
        endDate={campaign.endDate}
        hostBrandImage={campaign.hostBrand.logo}
        started={campaign.started}
        totalTasks={campaign.totalTasks}
        tasksRemaining={campaign.totalTasks - campaign.completedTasks}
        notification={5}
        style={{
          zoom: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.zoom : 100,
          x: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.x : 0,
          y: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.y : 0,
        }}
      />
    )).concat(upcomingCampaigns.campaigns.map(campaign => (
      <CurrentCampaignCard
        key={campaign.id}
        campaignId={campaign.id}
        name={campaign.name}
        campaignPictureSrc={campaign.mainImage || '/images/defaults/coverPhotoFull.png'}
        type="upcoming"
        entries={campaign.entries}
        estimatedEntries={campaign.estimatedEntries}
        ntfEmails={campaign.newEmails}
        partners={campaign.allPartners}
        partnerImages={campaign.partnerImages}
        startDate={campaign.startDate}
        endDate={campaign.endDate}
        hostBrandImage={campaign.hostBrand.logo}
        started={campaign.started}
        totalTasks={campaign.totalTasks}
        tasksRemaining={campaign.totalTasks - campaign.completedTasks}
        notification={5}
        style={{
          zoom: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.zoom : 100,
          x: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.x : 0,
          y: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.y : 0,
        }}
      />
    )))

    return (
      <div>
        <PartnershipsContainer
          title="Current"
          noHeader
          errorText={errorText}
          content={campaigns}
        />

        <ConfirmDeleteModal
          campaignDeletingId={upcomingCampaigns.campaignDeletingId}
          closeModal={closeModal}
          confirmDeletion={confirmDeleteCampaign}
        />
      </div>
    )
  }
}
export default CurrentCampaigns
