import React from 'react';
import { object } from 'prop-types';

import { Card } from 'react-toolbox/lib/card';
import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import { makeNumbersPretty } from 'util/makeNumbersPretty';
import { H5, Small, Tiny } from 'visual-components/util/texts';

class ColumnGroupedTooltip extends React.Component {
  render() {
    let {
      label,
      data
    } = this.props;

    let tooltipItems = data.map((item, idx) => {
      return (
        <Row>
          <div style={{
            marginBottom: '4px',
            display: 'inline-block'
          }}>
            <H5>
              { d3.format(',')(item.y) }
            </H5>
          </div>
          <div style={{
            display: 'inline-block',
            marginLeft: '5px',
            padding: '2px'
          }}>
            <Tiny>
              { item.series }
            </Tiny>
          </div>
        </Row>
      )
    })

    return (
      <Card style={{
        padding: '12px',
        width: '168px',
        height: '110px'
      }}>
        <div style={{
          marginBottom: '12px'
        }}>
          <Small>
            <em>
              { label }
            </em>
          </Small>
        </div>

        <Grid>
          { tooltipItems }
        </Grid>
      </Card>
    )
  }
};

export default ColumnGroupedTooltip;
