import React, { Component } from 'react'
import propTypes from 'prop-types'
import moment from 'moment-timezone'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import EntryFields from 'util/components/templates/form/entry-fields'
import FormGroup from 'util/components/form-group'
import { Button } from 'react-toolbox/lib/button'
import { P } from 'visual-components/util/texts'
import CustomInputForm from 'visual-components/campaign-builder/custom-input-form/CustomInputForm'
import styles from 'css/themes/custom-input-transition.css'
import buttonTheme from 'css/themes/Buttons.css'
import CustomFieldDispatcher from 'dispatchers/custom-field-dispatcher'

const {
  enter, enterActive, exit, exitActive,
} = styles

const { addCustomField } = CustomFieldDispatcher

class EntryFieldsTab extends Component {
  static propTypes = {
    agreeToLiability: propTypes.func.isRequired,
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
  }

  render() {
    const {
      campaign,
      classes: css,
    } = this.props
    const copy = LANGUAGE.builder.steps.setup
    const { customFields } = campaign
    const isLive = moment(campaign.startDate).isBefore(moment())

    // only allow adding of custom field if previous ones have labels
    const disableAddCustomField = isLive
      || (customFields[customFields.length - 1] && !customFields[customFields.length - 1].label)

    const customFieldInputs = customFields.map((form, index) => (
      <CSSTransition
        key={typeof form.key !== 'undefined' ? form.key : form.id}
        classNames={{
          enter,
          enterActive,
          exit,
          exitActive,
        }}
        timeout={500}
      >
        <CustomInputForm data={form} index={index} disabled={isLive} />
      </CSSTransition>
    ))

    return (
      <div>
        <P alertRed>
          <i>
            For your legal protection, entry fields are not editable after the campaign has
            launched.
          </i>
        </P>
        <EntryFields {...this.props} copy={copy} editable={!isLive} />
        {campaign.includeCustomField && (
          <FormGroup
            editable={false}
            model={campaign}
            type="checkbox"
            attrName="includeCustomField"
            label="Legacy Custom Field (DEPRECATED)"
            styles={[{ marginTop: '0px' }]}
            labelStyles={[
              {
                cursor: 'not-allowed',
                fontFamily: 'Larsseit-Light',
                fontSize: '14px',
              },
            ]}
          />
        )}
        {campaign.includeCustomField && (
          <div style={{ paddingTop: '15px', opacity: '0.5' }}>
            <FormGroup
              disabled
              attrName="customFieldName"
              model={campaign}
              placeholder="Legacy Custom Field"
            />
          </div>
        )}
        <div
          style={{
            marginTop: '16px',
            marginBottom: '16px',
            textAlign: 'left',
          }}
        >
          <P>You can add up to 3 custom fields.</P>
        </div>
        <TransitionGroup children={customFieldInputs} />
        {customFields.length < 3 ? (
          <div style={{ width: '144px' }}>
            <Button
              onClick={addCustomField}
              className={`${
                !disableAddCustomField ? buttonTheme.blueButton : buttonTheme.disabledButton
              } ${buttonTheme.noMargin}`}
              theme={buttonTheme}
              label="Add Custom Field"
              disabled={disableAddCustomField}
              flat
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default EntryFieldsTab
