import React from 'react';
import * as ReactRedux from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet'

import Validator from 'util/form-validator';
import { H2 } from 'visual-components/util/texts';

import Grid from 'visual-components/util/no-padding-grid/grid';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import Field from 'visual-components/util/inputs/field';

const validations = {
  firstName: [Validator.required],
  lastName: [Validator.required],
  email: [Validator.required, Validator.isValidEmail]
}

var mapState = function (state) {
  return {
    currentBrand: state.currentBrand,
    profile: state.profile,
    profileForm: state.profileForm
  }
}

class UserInfoSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var {
      currentBrand,
      profile,
      profileForm
    } = this.props;

    return(
      <div style={{
        paddingTop: '32px'
      }}>
        <Helmet>
          <title>User Info</title>
        </Helmet>
        <Grid>
          <Row style={{
            marginBottom: '24px'
          }}>
            <H2 azure>
              User Info
            </H2>
          </Row>
          <Row>
            <Col style={{
              width: '360px',
              marginRight: '32px'
            }}>
              <Field label={ 'First Name' }
                attrName={ 'firstName' }
                dataModel={ profile }
                formModel={ profileForm }
                endpoint={ '/profile' }
                validations={ validations.firstName }
              />

              <Field label={ 'Last Name' }
                attrName={ 'lastName' }
                dataModel={ profile }
                formModel={ profileForm }
                endpoint={ '/profile' }
                validations={ validations.lastName }
              />

              <Field label={ 'Email' }
                attrName={ 'email' }
                dataModel={ profile }
                formModel={ profileForm }
                endpoint={ '/profile' }
                validations={ validations.email }
                disabled={ true }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
};

export default ReactRedux.connect(mapState)(UserInfoSettings);
