import React from 'react';

class CampaignBuilderSection extends React.Component{
  constructor(props) {
    super(props)
  }

  render() {

    let subSection = this.props.subSection;

    let paddingTop;
    let fatPaddingTop = '120px';
    let fatterPaddingTop = '170px';

    switch ( subSection ) {
      case 'select-template':
      case 'edit-content':
      case 'partner':
      case 'social-sharing':
      case 'custom-cta':
      case 'esp':
      case 'pixel':
      case 'email-filters':
        paddingTop = fatPaddingTop;
        break;
      case 'terms':
      case 'partnership-agreement':
        paddingTop = fatterPaddingTop;
        break;
      default:
        paddingTop = '85px'
    }

    // paddingTop = `${ paddingTop }px`;

    return(
      <section>
        <div className="container"
          style={{
            paddingTop: paddingTop,
            paddingBottom: '160px'
        }}>
          { this.props.children }
        </div>
      </section>
    )
  }
}

export default CampaignBuilderSection;
