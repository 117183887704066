import React, { Component } from 'react'
import {
  oneOfType, object, string, number, node, func,
} from 'prop-types'
import injectSheet from 'react-jss'
import COLOURS from 'util/colours'

const styles = {
  container: {
    position: 'relative',
    marginTop: '24px',
    marginBottom: '12px',
  },
  button: {
    position: 'absolute',
    bottom: '-8px',
    color: COLOURS.ink,
    fontSize: '16px',
    fontFamily: 'Larsseit-Light',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  leftButton: {
    left: '-4px',
  },
  rightButton: {
    right: '-4px',
  },
  input: {
    boxShadow: 'none',
    paddingRight: '24px',
    paddingLeft: '24px',
    paddingBottom: '3px',
  },
}

class RangeSlider extends Component {
  static propTypes = {
    attr: string.isRequired,
    value: oneOfType([string, number]).isRequired,
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
    updateValue: func.isRequired,
    incrementValue: func.isRequired,
    decrementValue: func.isRequired,
    leftButton: node.isRequired,
    rightButton: node.isRequired,
    classes: object.isRequired,
  }

  state = {
    prevVal: null,
  }

  updateValue = ({ target: { value } }) => {
    const { attr, updateValue } = this.props

    // If backspace
    if (!/%/g.test(value)) {
      const { prevVal } = this.state

      // If not first number
      if (/%/.test(prevVal)) value = value.slice(0, -1)
    }

    value = value.split('%').join('')
    if (isNaN(value)) return
    if (value > 100) value = 100
    else if (value < 0) value = 0

    this.setState({ prevVal: `${value}%` })
    updateValue(attr, value / 100)
  }

  render() {
    const {
      attr,
      value,
      min,
      max,
      step,
      updateValue,
      incrementValue,
      decrementValue,
      leftButton,
      rightButton,
      classes: css,
    } = this.props

    return (
      <div key={attr} className={css.container}>
        <span key="left" onClick={() => decrementValue(attr)} className={`${css.button} ${css.leftButton}`}>
          { leftButton }
        </span>
        <span key="right" onClick={() => incrementValue(attr)} className={`${css.button} ${css.rightButton}`}>
          { rightButton }
        </span>
        <div>
          <input
            type="range"
            min={min}
            max={max}
            step={step || 1}
            onChange={({ target }) => updateValue(attr, target.value)}
            value={value}
            className={css.input}
          />
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(RangeSlider)
