import React from 'react'
import { Link } from 'react-router-dom'
import { string } from 'prop-types'
import appbarTheme from 'css/themes/homePage/appbarTheme.css'

class AboutUsPageItemLi extends React.Component {
  static propTypes = {
    to: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
  }

  render() {
    const { to, title, description } = this.props

    return (
      <a
        href={to}
        style={{
          padding: '0',
        }}
      >
        <li className={appbarTheme.itemLi}>
          <p className={appbarTheme.title}>{title}</p>
          <p className={appbarTheme.description}>{description}</p>
        </li>
      </a>
    )
  }
}

export default AboutUsPageItemLi
