import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CurrentCampaignCard from 'visual-components/shared/campaign-detail-card/CurrentCampaignCard/CurrentCampaignCard'
import PartnershipsContainer from 'visual-components/myPartnerships/PartnershipsContainer'
import Spinner from 'util/components/spinner'

class PastCampaigns extends Component {
  static propTypes = {
    history: PropTypes.func.isRequired,
    pastCampaigns: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    brandCampaigns: PropTypes.object.isRequired,
  }

  static contextTypes = {
    duplicateCampaign: PropTypes.func,
    downloadEntries: PropTypes.func,
    viewAnalytics: PropTypes.func,
  }

  navigateTo = link => {
    const { history } = this.props
    history.push(link)
  }


  render() {
    const {
      pastCampaigns,
      currentBrand,
      brandCampaigns,
    } = this.props

    const {
      downloadEntries,
      duplicateCampaign,
      viewAnalytics,
    } = this.context

    if (!pastCampaigns.loaded) {
      return (
        <Spinner />
      )
    }

    let errorText
    if (!pastCampaigns.campaigns.length) {
      errorText = 'You have no past sweepstakes.'
    }

    const campaigns = pastCampaigns.campaigns.map(campaign => {
      let actions = [{
        label: 'Preview',
        href: campaign.previewLink,
        target: '_blank',
      }, {
        label: 'Analytics',
        onClick: () => viewAnalytics(campaign.id),
      }, {
        label: 'Download Entries',
        onClick: () => downloadEntries(campaign.id, 'Past Campaigns', brandCampaigns.invites.find(invite => invite.invitee_id === currentBrand.id && invite.id === campaign.id).filterWebbula),
      }, {
        label: 'Campaign Overview',
        href: `/partnerships/campaign/${campaign.id}/quickview`,
      }, {
        label: 'Duplicate',
        onClick: () => duplicateCampaign(campaign.id),
        host: true,
      }, {
        label: 'Pick Winner',
        href: `/partnerships/campaign/${campaign.id}/quickview`,
        host: true,
      }]

      actions = actions.filter(action => {
        if (action.host) {
          return currentBrand.id === campaign.hostBrandId
        }
        return true
      })

      return (
        <CurrentCampaignCard
          key={campaign.id}
          campaignId={campaign.id}
          name={campaign.name}
          campaignPictureSrc={campaign.mainImage || '/images/defaults/coverPhotoFull.png'}
          type="past"
          impressions={campaign.impressions}
          visits={campaign.visits}
          entries={campaign.entries}
          estimatedEntries={campaign.estimatedEntries}
          ntfEmails={campaign.newEmails}
          partners={campaign.allPartners}
          partnerImages={campaign.partnerImages}
          startDate={campaign.startDate}
          endDate={campaign.endDate}
          hostBrandImage={campaign.hostBrand.logo}
          actions={actions}
          totalTasks={campaign.totalTasks}
          tasksRemaining={campaign.totalTasks - campaign.completedTasks}
          style={{
            zoom: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.zoom : 100,
            x: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.x : 0,
            y: campaign.styles && campaign.styles.imagePos ? campaign.styles.imagePos.y : 0,
          }}
        />
      )
    })

    return (
      <PartnershipsContainer
        title="Past"
        noHeader
        errorText={errorText}
        content={campaigns}
      />
    )
  }
}

export default PastCampaigns
