import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ExploreContainer from 'visual-components/explore/explore-container'
import SearchBrandRatingConfig from 'actions/search-config/search-brand-rating-config'
import Spinner from 'util/components/spinner'
import EmptyBonzai from '../empty-bonzai'
import OverallReviews from './components/OverallReviewsCard'
import ReviewsMain from './components/ReviewsMain'

const mapStateToProps = ({
  currentBrand,
  brand,
  brandRatings,
  brandCampaigns: {
    campaignsHash,
  },
}) => ({
  currentBrand,
  brand,
  brandRatings,
  campaignsHash,
})

class BrandReviews extends PureComponent {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
    brandRatings: PropTypes.object.isRequired,
    campaignsHash: PropTypes.object.isRequired,
  }

  render() {
    const {
      brandRatings,
      brand,
      currentBrand,
      campaignsHash,
    } = this.props

    const { loading: isLoading } = brandRatings

    if (isLoading) return <Spinner />

    const isBrand = currentBrand.id === brand.id

    if (!brand.totalRatingCount) {
      const bonzaiDescription = `${isBrand ? 'You don\'t' : 'This brand doesn\'t'} have any reviews yet${isBrand ? '!' : ''}`

      return <EmptyBonzai description={bonzaiDescription} />
    }

    return (
      <div>
        <OverallReviews />
        <div style={{ marginTop: '16px' }}>
          <ExploreContainer
            config={{
              ...SearchBrandRatingConfig,
              extendEndpoint: `/${brand.id}/received`,
            }}
            hideTitle
            hideSearchBar
            width="100%"
            render={({
              results,
              loading,
              error,
            }) => {
              const reviews = results ? results.map(review => ({
                ...review,
                belongsToCampaign: review.model_type === 'campaign' && Boolean(campaignsHash && campaignsHash[review.model_id]),
              })) : []
              return (
                <ReviewsMain
                  reviews={reviews}
                  loading={loading}
                  error={error}
                />
              )
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(BrandReviews)
