import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { H3, H2, Small } from 'visual-components/util/texts';
import Row from 'visual-components/util/no-padding-grid/row';
import Col from 'visual-components/util/no-padding-grid/col';

import ContributionBar from 'visual-components/analyticsDashboard/overviewCard/contribution-bar';
import Dropdown from 'react-toolbox/lib/dropdown'
import EmptyState from 'visual-components/util/graphs/empty-state';
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip';

import AnalyticsTheme from 'css/themes/analytics.css';
import DropdownFilterTheme from 'css/themes/dropDownAnalytics.css'
import Donut from 'visual-components/util/graphs/donut';
import ChartOption from 'visual-components/analyticsDashboard/overviewCard/chart-option';

class HostHeader extends React.Component {
  customItem = item => {
    /* this breaks out of the normal loop, allowing for customization of the chosen value */
    return (
      <div className={ DropdownFilterTheme.customItem }>
        { item.label == this.props.selectedBrand && item.label.length > 8 ? item.label.substring(0, 8) + '...' : item.label }
      </div>
    )
  }

  render() {
    var {
      brandOptions,
      value,
      onChange,
      isSolo,
    } = this.props;

    return (
      <div>
        <div style={{
          display: 'inline-block'
        }}>
          <H3 coral>
            <small>
              { `Entries by Channel${!isSolo ? ' - ' : ''}` }
            </small>
          </H3>
        </div>
        {!isSolo && (
          <div style={{
            display: 'inline-block',
            marginLeft: '-12px',
            marginRight: '-6px'
          }}>
            <Dropdown
              auto
              onChange={ onChange }
              source={ brandOptions }
              value={ value }
              theme={ DropdownFilterTheme }
              template={ this.customItem }
            />
          </div>
        )}
      </div>
    )
  }
}

class BrandName extends React.Component {
  render() {
    var {
      onClick,
      color,
      title,
      name,
      id,
      selected
    } = this.props;

    return (
      <div
        style={{
          width: '117px',
          marginBottom: '6px',
          cursor: 'pointer',
          opacity: selected ? '1' : '0.5',
          height: '11px',
          display: 'inline-block',
          position: 'relative'
        }}
        onClick={e => onClick(id ? id : name)}
      >
        <div
          style={{
            position: 'absolute',
            width: '12px',
            height: '12px',
            backgroundColor: color
          }}>
        </div>
        <div
          style={{
            position: 'absolute',
            left: '20px',
            top: '1px'
          }}>
          <Small>
            { title && title.length > 15 ? title.substring(0, 15) + '...' : title }
          </Small>
        </div>
      </div>
    )
  }
}


class PromoLinkCard extends React.Component {
  render() {
    var {
      totalPretty,
      chartOptions,
      brandOptions,
      value,
      onChange,
      graphData,
      hideMap,
      toggle,
      colorMap,
    } = this.props;

    const promoOptions = chartOptions.map((item,idx) => {
      return (
        <BrandName {...item}
          onClick={toggle}
          selected={!hideMap[item.id]}
          key={idx}/>
        )
    });

    return (
      <Card className={AnalyticsTheme.promoCard}>
        <div style={{ height: '18px' }}>
          <HostHeader { ...this.props } />
        </div>
        <Col style={{
          width: '270px',
          marginTop: '20px',
          position: 'relative',
        }}>
          <Row>
            <Col>
              <div className={AnalyticsTheme.promoCardPromoUnits}>
                {promoOptions}
              </div>
            </Col>
            <div style={{
              position: 'absolute',
              top: '12px',
              left: '80px',
              width: '100px',
              height: '100px',
            }}>
              <Donut
                colorMap={colorMap}
                items={graphData}
                hideMap={hideMap} />
            </div>
          </Row>
        </Col>
      </Card>
    );
  }
}

export default PromoLinkCard;
