import React from 'react';

import Field from 'visual-components/util/inputs/field';
import Label from 'visual-components/util/inputs/label';
import COLOURS from 'util/colours';
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher';
import { Micro } from 'visual-components/util/texts';
import { connect } from 'react-redux';

var mapState = function (state) {
  return {
    missingName: state.card.missingName,
    missingZip: state.card.missingZip
  }
}

class CardForm extends React.Component {
  nameFocus() {
    store.dispatch({
      model: 'cardForm',
      type: 'UPDATE_ATTR',
      data: {
        missingName: false
      }
    })
  }

  zipFocus() {
    store.dispatch({
      model: 'cardForm',
      type: 'UPDATE_ATTR',
      data: {
        missingZip: false
      }
    })
  }

  componentDidMount() {
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontWeight: 100,
        fontSize: '14px',
        lineHeight: '14px',
        color: COLOURS.ink,
        '::placeholder': {
          color: COLOURS.cloudGrey
        }
      },
      invalid: {
        color: COLOURS.alertRed
      },
    };

    if (elements._elements.length) {
      elements._elements.pop();
    }

    // Create an instance of the card Element
    window.card = window.card || elements.create('card', {
      hidePostalCode: true,
      style: style
    });

    // Add an instance of the card Element into the `card-element` <div>
    card.mount('#card-element');

    card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      };
    });

    var myPostalCodeField = document.querySelector('input[name=address-zip]');

    myPostalCodeField.addEventListener('change', CurrentBrandDispatcher.handleZipUpdate);

    store.dispatch({
      model: 'card',
      type: 'UPDATE_ATTR',
      data: {
        card: card
      }
    })
  }

  render() {
    var inputStyle = {
      width: '100%',
      height: '40px',
      border: `1px solid ${COLOURS.silver}`,
      borderRadius: '2px',
      paddingTop: '13px',
      paddingRight: '12px',
      paddingBottom: '13px',
      paddingLeft: '12px',
      fontSize: '14px',
      lineHeight: '14px',
      color: COLOURS.ink
    }
    var {
      missingZip,
      missingName
    } = this.props;

    return (
      <div>
        <form id={ 'payment-form' } style={{
          paddingBottom: '24px'
        }}>
          <Label label={ 'Credit Card Details*' }/>
          <div style={{
            width: '100%',
            marginBottom: '16px'
          }}>
            <div id={ 'card-element' }
              style={ inputStyle }
            />
            <div id={ 'card-errors' }
              style={{
                fontSize: '9px',
                lineHeight: '9px',
                color: COLOURS.alertRed,
                paddingTop: '5px',
                minHeight: '14px'
            }}/>
          </div>
          <div style={{
            width: '404px',
            marginRight: '8px',
            display: 'inline-block'
          }}>
            <Label label={ 'Name on Card*' }/>
            <input name={ 'cardholder-name' }
              className={ 'card-input' }
              onFocus={ this.nameFocus }
              style={ inputStyle }
            />
            <div style={{
              visibility: !missingName ? 'hidden' : 'visible',
              paddingTop: '5px'
            }}>
              <Micro alertRed>
                Name Required
              </Micro>
            </div>
          </div>
          <div style={{
            width: '140px',
            display: 'inline-block'
          }}>
            <Label label={ 'Zip Code*' }/>
            <input name={ 'address-zip' }
              className={ 'card-input' }
              onFocus={ this.zipFocus }
              style={ inputStyle }
            />
            <div style={{
              visibility: !missingZip ? 'hidden' : 'visible',
              paddingTop: '5px'
            }}>
              <Micro alertRed>
                Zipcode Required
              </Micro>
            </div>
          </div>
        </form>
      </div>
    )
  }
};

export default connect(mapState)(CardForm);
