import moment from 'moment-timezone'
import _ from 'lodash'

let customFieldTransitionKey = 0

const CurrentCampaignReducer = function (model, initialState) {
  const helperFunctions = {
    hasStarted: function () {
        return new Date(this.startDate) <= new Date();
    },
    isRunning: function () {
      if (!this.startDate || !this.endDate) {
        return false;
      }

      var start = this.startDate;
      var end = this.endDate;
      var now = new Date();

      return (now >= start && now <= end);
    },
    hasEnded: function () {
      if (!this.endDate) {
        return false;
      }
      var end = new Date(this.endDate);
      var now = new Date();

      return (now >= end);
    },
    setupChecklist: function () {
      var that = this;

      var copy = LANGUAGE.builder.steps.setup;

      return {
        name: function (argument) {
          return {
            value: that.name,
            ready: that.name,
            copy: copy.name
          }
        }(),
        description: {
          value: this.description,
          ready: this.description,
          copy: copy.description
        },
        startDate: {
          value: moment(this.startDate).format('l'),
          ready: this.startDate,
          copy: copy.startDate
        },
        endDate: {
          value: moment(this.endDate).format('l'),
          ready: this.endDate,
          copy: copy.endDate
        },
      }
    },

    landerChecklist: function () {

      var copy = LANGUAGE.builder.steps.lander;

      return {
        mainImage: {
          value: LANGUAGE.builder.steps.checklist.completed,
          ready: this.mainImage && this.mainImage !== '/images/images.png',
          copy: copy.mainImage
        },
        headLine: {
          value: LANGUAGE.builder.steps.checklist.completed,
          ready: this.headLine && this.headLine !== 'this is your headline',
          copy: copy.headLine
        },
        details: {
          value: LANGUAGE.builder.steps.checklist.completed,
          ready: this.details && this.details !== '<ul><li>these</li><li>are</li><li>your</li><li>details</lil></ul>',
          copy: copy.details
        }
      };
    },

    partnersChecklist: function () {
      var partners = {};

      _.each(this.invites, function (invite) {
        partners[invite.invitee.accountname] = {
          value: invite.status,
          ready: invite.status === 'accepted',
          copy: invite.invitee.accountname
        };
      });

      return partners;
    },

    hostingChecklist: function () {
      var hosting = {};
      var copy = LANGUAGE.builder.steps.checklist.hosting.dojoHost;
      var ready = this.baseUrl || this.dojoHost;
      var value;

      if (ready) {
        value = this.dojoHost ? copy['true'] : copy['false']
      }

      return {
        hosting: {
          value: LANGUAGE.builder.steps.checklist.completed,
          ready: ready,
          copy: LANGUAGE.builder.steps.hosting.title
        }
      };
    },

    postentryChecklist: function (brand) {
      var postentry = {};

      var copy = LANGUAGE.builder.steps.postentry;

      var invite = this.getBrandInvite(brand.id);

      var ctaReady = invite.ctaUrl;

      if (ctaReady) {
        if (invite.useCtaImage) {
          ctaReady = invite.ctaImage;
        } else {
          ctaReady = invite.ctaActionText && invite.ctaMessageText;
        }
      }
      return {
        ctaReady: {
          value: LANGUAGE.builder.steps.checklist.completed,
          ready: ctaReady,
          copy: copy.sub
        },
        facebookShareImage: {
          value: LANGUAGE.builder.steps.checklist.completed,
          ready: this.facebookShareImage,
          copy: copy.sharecontent.facebookShareImage
        },
        twitterShareMessage: {
          value: this.twitterShareMessage,
          ready: this.twitterShareMessage,
          copy: copy.sharecontent.twitterShareMessage
        },
        facebookShareMessage: {
          value: this.facebookShareMessage,
          ready: this.facebookShareMessage,
          copy: copy.sharecontent.facebookShareMessage
        }
      };
    },

    checkIsReady: function () {
      this.isReady = true;

      _.each(this.campaignItems, item => {
        if (item.attr) {
          item.value = this[item.attr] && this[item.attr].length;
        }

        if (item.required && !item.value) {
          this.isReady = false;
        }
      });

      _.each(this.brandItems, item => {
        if (item.attr) {
          item.value = this.hostBrand[item.attr] && this.hostBrand[item.attr].length;
        }

        if (item.required && !item.value) {
          this.isReady = false;
        }
      });
    },
  }


  const defaultState = _.extend({}, {
    rid: model,
    loading: true,
    customFieldPersisting: false,
    showCampaignDateChangeModal: false,
    showAnnouncementSentCampaignDateChangeModal: false,
  }, initialState, helperFunctions);

  return function (state, action) {
    if (action.model !== model) {
      return state || defaultState;
    }
    const actions = {
      LOAD_EMPTY: function() {
        var emptyCampaign = {
          name: '',
          description: '',
          desktopElements: {},
          hostBrand: null,
          headLine: '',
          details: '',
          disclaimer: '',
          customCss: '',
          additionalTermsDetail: '',
          started: false,
          mainImage: '/images/images.png',
          mainImageAlignVertical: 'top',
          mainImageAlignHorizontal: 'right',
          invites: [],
          postEntryMessage: '',
          // promotionalUnits: new PromotionalUnitsCollection(),
          tags: [],
          potentialImpressions: 0,
          preEntryMessage: '',
          potentialVisits: 0,
          potentialEntries: 0,
          totalImpressions: 0,
          totalVisits: 0,
          landerVersion: '',
          totalEntries: 0,
          rid: 'currentCampaign',
          partnerships: [],
          logoSize: null,
          logoSizeMobile: null,
          visible: false,
          errors: {},
          styles: {
            imagePos: {
              zoom: 140,
              x: 0,
              y: 0
            },
            imagePosMobile: {
              zoom: 600,
              x: 0,
              y: 0
            },
            overlay: {
              type: 'none',
              color1: '#000000',
              opacity1: 0.25,
              color2: '#14cba8',
              opacity2: 0.5,
            },
            overlayMobile: {
              type: 'none',
              color1: '#000000',
              opacity1: 0.25,
              color2: '#14cba8',
              opacity2: 0.5,
            },
            form: {
              color: '#000000',
              opacity: 0.25,
            },
            formMobile: {
              color: '#000000',
              opacity: 0.25,
            },
            fonts: {

            },
            gradientLeftDesk: 1,
            gradientLeftMobile: 1,
            gradientRightDesk: 0,
            gradientRightMobile: 0
          },
          inputs: {
            email: true,
            emailRequired: true
          },
          includeEmail: true,
          includeZip: null,
          includeGender: null,
          includeFirstName: null,
          includeLastName: null,
          includePhone: null,
          includeBirthday: null,
          id: null,
          industry: null,
          startDate: moment().tz('America/New_York').add(2,'weeks').startOf('day').tz('UTC')._d,
          endDate: moment().tz('America/New_York').add(5,'weeks').endOf('day').tz('UTC')._d,
          viewDesktop: true,
          prizingLogo: null,
          tos: null,
          showPoweredByDojo: true,
          inputs: {},
          integrations: {},
          assets: [],
          customFields: [],
          announcements: [],
        };
        return _.extend({}, emptyCampaign)
      },
      LOAD_SAMPLE_DATA: function() {
        let sampleCampaign = {
          name: 'A Wonderful Giveaway',
          description: 'The best giveaway ever',
        }
        return _.extend({}, state, sampleCampaign)
      },
      UPDATE_ATTR: function () {
        return _.extend({}, state, action.data);
      },
      LOADING: function () {
        return _.extend({}, state, { loading: true });
      },
      UPDATE_TRAFFIC_PROPORTION: function () {
        var partners = _.filter(state.invites, {
          status: 'accepted',
          isHost: false
        });

        var remainder = _.reduce(partners, function (memo, partner) {
          return memo -= state[`successPageProportion${partner.invitee_id}`];
        }, 1);

        return _.extend({}, state, {
          [`successPageProportion${state.hostBrandId}`]: remainder
        })
      },
      UPDATE_DISPLAY_STATE: function () {
        var invitee_id = action.data.invitee_id;
        var newDisplayState = _.extend({}, state.displayState, {
          [invitee_id]: !state.displayState[invitee_id]

        })
        return _.extend({}, state, {
          displayState: newDisplayState
        });
      },
      LOAD_SUCCESS: function () {
        var campaign = action.data;
        campaign.started = new Date(campaign.startDate) < new Date() ? true : false;
        campaign.analyticsLink = `/analytics/campaigns/${campaign.id}`
        campaign.numberOfPartners = campaign.invites.filter(invite => {
          return invite.status === 'accepted'
        }).length
        var data = _.extend({}, state, { viewDesktop: true }, campaign);
        return _.extend({}, data );
      },
      LOADING_SUCCESS: function () {
        return _.extend({}, state, { loading: false })
      },
      ADD_INVITE: function() {
        let updateInvites = state.invites.concat([action.data])
        return _.extend({}, state, {invites: updateInvites})
      },
      REMOVE_ASSET: function () {
        var assets = _.filter(state.assets, (asset) => asset.id != action.data)

        return _.extend({}, state, {
          assets: assets
        });
      },
      UPDATE_TERMS: function () {
        var terms = _.extend({}, state.campaignTerms, action.data);

        return _.extend({}, state, {
          campaignTerms: terms
        });
      },
      UPDATE_ELEMENTS: function() {
        let platform = state.viewDesktop ? 'desktop' : 'mobile';
        // if mobile, simple update
        if (platform === 'mobile') {
          let mobileElements = Object.assign({}, state.mobileElements, action.data);
          return Object.assign({}, state, { mobileElements });
        }
        // if desktop, also update mobile if not modified
        else {
          let desktopElements = Object.assign({}, state.desktopElements, action.data);
          let mobileElements = Object.assign({}, state.mobileElements);
          for (let key in action.data) {
            if (state.desktopElements[key] === state.mobileElements[key]) {
              mobileElements[key] = action.data[key];
            }
          }
          return Object.assign({}, state, { desktopElements, mobileElements });
        }
      },
      LOAD_CUSTOM_FIELDS() {
        const customFields = action.payload.customFields.map((item, i) => {
          // maintain key for transition group
          const existingField = state.customFields[i]
          const key = existingField && existingField.key !== 'undefined'
            ? existingField.key
            : item.id
          // use most current label model
          const label = existingField ? existingField.label : item.label
          if (item.type === 'select' && !item.options.length) {
            return { ...item, options: ['', ''], key, label }
          }
          return { ...item, key, label }
        })
        return { ...state, customFields, customFieldPersisting: false }
      },
      CUSTOM_FIELD_PERSISTING() {
        return { ...state, customFieldPersisting: true }
      },
      UPDATE_CUSTOM_FIELD: function() {
        const customFields = state.customFields.map((item, index) => {
          if (index !== action.payload.index) {
            return item
          }
          const { data } = action.payload
          let { options } = item
          const newItem = { ...item, ...data }
          newItem.options = newItem.type === 'select'
            ? options.length
              ? options
              : ['', '']
            : []
          return newItem
        })
        return { ...state, customFields }
      },
      ADD_CUSTOM_FIELD: function() {
        const customFields = state.customFields.concat({
          key: customFieldTransitionKey++,
          label: '',
          type: 'text',
          options: [],
        })
        return { ...state, customFields }
      },
      DELETE_CUSTOM_FIELD: function() {
        const customFields = [...state.customFields]
        customFields.splice(action.payload.index, 1)
        return { ...state, customFields }
      },
      ADD_OPTION_TO_CUSTOM_FIELD: function() {
        const customFields = state.customFields.map((item, index) => {
          if (index !== action.payload.index) {
            return item
          }
          const options = [...item.options, '']
          return { ...item, options }
        })
        return { ...state, customFields }
      },
      UPDATE_OPTION_TO_CUSTOM_FIELD: function() {
        const customFields = state.customFields.map((item, index) => {
          if (index !== action.payload.index) {
            return item
          }
          const options = item.options.map((opt, subIndex) => {
            if (subIndex !== action.payload.subIndex) {
              return opt
            }
            return action.payload.data
          })
          return { ...item, options }
        })
        return { ...state, customFields }
      },
      DELETE_OPTION_FROM_CUSTOM_FIELD: function() {
        const customFields = state.customFields.map((item, index) => {
          if (index !== action.payload.index) {
            return item
          }
          const options = [...item.options]
          options.splice(action.payload.subIndex, 1)
          return { ...item, options }
        })
        return { ...state, customFields }
      },
      UPDATE_INVITE: function () {
        let invites = _.clone(state.invites)
        if (invites) {
          let invite = invites.find(invite => invite.id == action.data.invite.id)
          invites[invites.indexOf(invite)] = action.data.invite
          return Object.assign({}, state, { invites: invites })
        }
        return state
      },
      DELETE_AGREEMENT_SUCCESS: function () {
        const { inviteId } = action;
        return {
          ...state,
          invites: state.invites.map(invite => {
            return invite.id != inviteId ?
              invite
              :
              { ...invite, agreement: null }
          })
        }
      },
      CREATE_AGREEMENT_SUCCESS: function () {
        const { payload } = action
        return {
          ...state,
          invites: state.invites.map(invite => {
            return invite.id != payload.id ?
              invite
              :
              { ...invite, agreement: payload.agreement }
          })
        }
      },
      GENERATE_AGREEMENT_DRAFT_SUCCESS: function () {
        const { payload } = action
        return {
          ...state,
          invites: state.invites.map(invite => {
            return invite.id != payload.id ?
              invite
              :
              { ...invite, agreement: payload.agreement }
          })
        }
      },
      SHARE_AGREEMENT_SUCCESS: function () {
        const { payload } = action
        return {
          ...state,
          invites: state.invites.map(invite => {
            return invite.id != payload.agreement.id ?
              invite
              :
              { ...invite, agreement: payload.agreement.agreement }
          })
        }
      },
      CONFIRM_PARTNER_SUCCESS: function () {
        const { payload } = action
        return {
          ...state,
          invites: state.invites.map(invite => {
            return invite.id != payload.id ?
              invite
              :
              { ...invite, agreement: payload.agreement }
          })
        }
      },
      COUNTER_SIGN_AGREEMENT_SUCCESS: function () {
        const { payload, inviteId } = action
        return {
          ...state,
          invites: state.invites.map(invite => {
            return invite.id != inviteId ?
            invite
            :
            {
              ...invite,
              agreement: payload.agreement.agreement,
              status: 'accepted',
            }
          })
        }
      },
      ADD_ANNOUNCEMENT: function () {
        const { data } = action

        let announcements = state.announcements
        announcements.unshift({
          type: data.type,
          message: data.message.replace(/\r?\n|\r/g, '<br />'),
          date: data.date,
          count: data.count,
        })

        return { ...state, announcements }
      },
      SHOW_CAMPAIGN_DATE_CHANGE_MODAL() {
        const { data } = action
        const { showCampaignDateChangeModal } = data

        return { ...state, showCampaignDateChangeModal }
      },
      SHOW_ANNOUNCEMENT_SENT_CAMPAIGN_DATE_CHANGE_MODAL() {
        const { data } = action
        const { showAnnouncementSentCampaignDateChangeModal } = data

        return { ...state, showAnnouncementSentCampaignDateChangeModal }
      },
      SHOW_CAMPAIGN_CREATED_LIMIT_MODAL() {
        const { data } = action
        const { showCampaignCreatedLimitModal } = data

        return { ...state, showCampaignCreatedLimitModal, loading: false }
      },
    };


    const item = actions[action.type] || function () {
      return state || defaultState;
    };

    return item();
  };
}

export default CurrentCampaignReducer;
