import React from 'react'
import { Small } from 'visual-components/util/texts'
import Row from 'visual-components/util/no-padding-grid/row'
import moment from 'moment'
import displayTypeMap from 'util/deal-display-type-map'

const MediaCostTypeAndDates = props => {
  const {
    isLive,
    prettyType,
    costType,
    startDate,
    endDate,
    status,
  } = props

  return (
    <Row style={{ marginBottom: '16px' }}>
      {/* Media Sale/Cost Type */}
      <div style={{ marginRight: '20px' }}>
        <Small white={isLive && status === 'live'}>
          {`${prettyType} - ${displayTypeMap[costType] || costType} `}
        </Small>
      </div>

      {/* Deal Date/Icon */}
      <img
        src={isLive && status === 'live' ? 'images/date-and-time-white.svg' : 'images/date-and-time-black.svg'}
        style={{
          width: '12px', height: '12px', marginRight: '8px', marginTop: '-1px',
        }}
      />
      <Small white={isLive && status === 'live'}>{`${moment(startDate).format('M/D/YY')} - ${moment(endDate).format('M/D/YY')}`}</Small>
    </Row>
  )
}

export default MediaCostTypeAndDates
