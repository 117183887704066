import { makeNumbersPretty } from 'util/makeNumbersPretty';
import SocialLabelMap from 'util/socialTitleMap';
import SocialTitleArray from 'util/socialTypeArray';
import moment from 'moment-timezone';
import _ from 'lodash'

export default {
  mappings: {
    campaignStatsByPartnerGraph: ({metric, data}) => {
      const temp = [
        {
          name: 'visits',
          title: 'Visits',
          data: [],
        },
        {
          name: 'entries',
          title: 'Entries',
          data: [],
        },
        {
          name: 'ntf',
          title: 'NTF Emails',
          data: [],
        },
        {
          name: 'smsSubscribers',
          title: 'SMS Signups',
          data: [],
        },
      ]

      const categories = []

      data.slice(0)
        .sort((a, b) => a.brand_id - b.brand_id)
        .forEach(brand => {
          categories.push(brand.brand_name);

          temp[0].data.push(parseInt(brand.visits, 10))
          temp[1].data.push(parseInt(brand.entries_factor_webula, 10))
          temp[2].data.push(parseInt(brand.ntf, 10))
          temp[3].data.push(parseInt(brand.sms_subscribers, 10))
        })

      const colors = ['#14cba8', '#03a9f4', '#0176c8', '#1b3088']
      const colorMap = {}

      temp.forEach((item, idx) => {
        colorMap[item.name] = colors[idx]
        item.color = colors[idx]
      });

      return {
        categories,
        items: temp,
        colorMap,
        hideMap: {},
        raw: data.map(item => {
          delete item.brand_id
          return item
        })
      }
    },
    campaignPartnerSmsContributionPercentGraph: ({ data }) => {
      const totalSubscribes = data.reduce((total, brand) => total + Number(brand.subscribes), 0)
      const totalMessagesDelivered = data.reduce((total, brand) => total + Number(brand.messages), 0)

      const formattedData = [
        {
          name: 'sms_subscribers',
          title: 'Signup Rate',
          data: [],
          formattedData: [],
        },
        {
          name: 'ctr',
          title: 'Ctr',
          data: [],
          formattedData: [],
        },
      ]

      const categories = []

      data.slice(0)
        .sort((a, b) => b.subscribes - a.subscribes)
        .forEach(brand => {
          categories.push(brand.brand_name)
          formattedData[0].data.push(_.round(brand.subscribes / totalSubscribes, 4))
          formattedData[0].formattedData.push(_.round(brand.subscribes / totalSubscribes * 100, 2))
          formattedData[1].data.push(_.round(brand.clicks / totalMessagesDelivered, 4))
          formattedData[1].formattedData.push(_.round(brand.clicks / totalMessagesDelivered * 100, 2))
        })

      const colors = ['#14cba8', '#03a9f4']
      const colorMap = {}

      formattedData.forEach((item, idx) => {
        colorMap[item.name] = colors[idx]
        item.color = colors[idx]
      })

      return {
        categories,
        items: formattedData,
        colorMap,
        hideMap: {},
        raw: data.map(item => {
          delete item.brand_id
          return item
        }),
      }
    },
    campaignEntryContributionStatsByPartnerByPromoUnit: report => {
      var specificBrandId = report.specificBrandId;

      const colors = [
        '#13b697',
        '#3fdac6',
        '#bef6e9',
        '#9ad8fb',
        '#03a9f4',
        '#0176c8',
        '#1b3088',
        '#01125d',

        //TODO: Aleksey Added these. They're not part of our brand. If we really
        // care about this then have a designer look into it
        '#c4bdcf',
        '#aa98c7',
        '#a98adb',
        '#966bdb',
        '#b3cda8',
        '#a7d395',
        '#8fd573',
        '#7b3fda',
        '#7cd956',
      ]

      var campaign = store.getState().analyticsCampaign.campaign;
      if (!campaign) return {}
      const invites = campaign.invites
        .slice(0)
        .filter(invite => invite.status == 'accepted')
        .sort((a, b) => b.invitee.id - a.invitee.id)

      const units = _.filter(report.data, { brand_id: specificBrandId });

      const colorMap = {};
      const brandMap = {};

      var total = 0;

      const byType = _.groupBy(units, unit => unit.label || unit.promotionaltype)

      var graphData = Object.keys(byType).map((type) => {
        const typeTotal = _.sumBy(byType[type], data => Number(data['totalentries'] || 0))
        total += Number(typeTotal);
        return {
          id: `id_${type.replace(/ /g,"_")}`, //replacing spaces with underscore to fix Contour issue with finding the data
          name: type,
          title: type,
          y: Number(typeTotal)
        };
      })

      var chartOptions = [];

      var totalPretty = makeNumbersPretty(total);

      graphData.forEach((item, idx) => {
        colorMap[item.id] = colors[idx];
        item.color = colors[idx];
        chartOptions.push({
          name: item.name,
          title: item.name,
          color: colors[idx],
          id: item.id,
        })
      });

      var brandOptions = invites.map((item, idx) => {
        brandMap[item.invitee_id] = item.invitee.accountname
        return {
          value: item.invitee_id,
          label: item.invitee.accountname,
          name: item.invitee.accountname,
        }
       });

      const csvData = report.data.map(row => ({
        brand_name: brandMap[row.brand_id],
        label: row.label,
        type: row.promotionaltype,
        total_entries: row.totalentries
      }))



      return {
        graphData: graphData,
        selectedBrand: _.find(invites, { invitee_id: specificBrandId })
          .invitee.accountname,
        totalPretty: totalPretty,
        brandOptions: brandOptions,
        chartOptions: chartOptions,
        colorMap: colorMap,
        value: report.specificBrandId,
        csv: csvData
      };
    },
    campaignEntryContributionStatsByPartner: ({metric, data}) => {

      var entryData = data.slice(0)
        .sort((a,b) => b.brand_id - a.brand_id)
        .map((item) => {
          return {
            accountname: item.brand_name,
            entries: parseInt(item.entries_factor_webula),
            threshold: parseInt(item.minimum_delivery)
          }
        })

      //
      // {brand_id: 14, entries: "50", entries_perc: "49.5049504950495050", entries_minimum: "0", visits: "72",…}
      // {brand_id: 1, entries: "51", entries_perc: "50.4950495049504950", entries_minimum: "0", visits: "100",…}


      var total = entryData.reduce((memo, item) => {
        return memo + item.entries;
      }, 0);

      entryData.forEach((item) => {
        item.percentage = item.entries === 0 ? 0 : item.entries / total;
        item.percentagePretty = d3.format(',%')(item.percentage);
      });

      return entryData;
    },
    campaignCustomCTA: (report) => {
      var data = report.data;

      var specificBrandId = report.specificBrandId;
      // [{source_brand: 1, report_data: "50"}]
      var campaign = store.getState().analyticsCampaign.campaign;
      if (!campaign) return {}
      var invites = campaign.invites
                    .slice(0)
                    .filter(invite => invite.status == 'accepted')
                    .sort((a,b) => b.invitee.id - a.invitee.id)

      var ctaData = invites.map((invite) => {
        var delivery = _.find(data, { source_brand: invite.invitee_id }) || { report_data: "0" };
        var value = parseInt(delivery.report_data);
        return {
          accountname: invite.invitee.accountname,
          entries: value,
          brandId: invite.invitee_id
        }
      })

      var total = ctaData.reduce((memo, item) => {
        return memo + item.entries;
      }, 0);

      var totalPretty = d3.format(',')(total);

      total = total || 0;

      ctaData.forEach((item) => {
        item.percentage = item.entries == 0 ? 0 : item.entries / total;
        item.percentagePretty = d3.format(',%')(item.percentage);
      });

      var options = ctaData.map((item, idx) => {
        return {
          value: item.brandId,
          label: item.accountname
        }
      });

      return {
        data: ctaData,
        selectedBrand: _.find(invites, {invitee_id: specificBrandId }).invitee.accountname,
        totalPretty: totalPretty,
        options: options,
        value: report.specificBrandId,
        test: Math.random()
      }
    },
    socialContributionGraph: (report) => {
      let campaign = store.getState().analyticsCampaign.campaign;
      if (!campaign) return {}
      let endDate = new Date(campaign.endDate) < new Date() ? moment.utc(campaign.endDate).format('ll') : moment.utc().format('ll')

      let byBrand = _.groupBy(report.data, 'source_brand');

      let data = campaign.invites.slice(0)
        .filter(invite => invite.status == 'accepted')
        .sort((a,b) => b.invitee.id - a.invitee.id)
        .map((invite) => {
          let breakdown = byBrand[invite.invitee.id] ? byBrand[invite.invitee.id].map(row => {
            return {
              label: SocialLabelMap[row.bonus_type],
              value: parseInt(row.report_data),
              valuePretty: makeNumbersPretty(parseInt(row.report_data))
            }
          }) : [];

          let entries = _.sumBy(breakdown, 'value')
          let entriesPretty = makeNumbersPretty(entries);

          return {
            accountname: invite.invitee.accountname,
            entries,
            entriesPretty,
            breakdown,
            datePretty: `${moment.utc(campaign.startDate).format('ll')} - ${endDate}`
          }
        })

      let totalEntries = _.sumBy(data, 'entries')
      data.forEach(brand => {
        let percentage = brand.entries == 0 ? 0 : brand.entries/totalEntries;
        brand.percentage = percentage
        brand.percentagePretty = d3.format(',%')(percentage);
      })

      return {
        data
      }

      /*
      [{
        accountname: "BLAH",
        entries: 2284
        entriesPretty: "2,284"
        percentage: 0.09833323
        percentagePretty: "10%",
        breakdown: [{
          label: 'Facebook Likes',
          value: 1008,
          prettyValue: '1,008'
        }, {

        }]
      },
      {

      }]
      */
    },
    campaignSocialActionsByTypeGraph: (report) => {
      var socialItems = [];
      var data = report.data;
      var specificBrandId = report.specificBrandId;
      var campaign = store.getState().analyticsCampaign.campaign
      if (!campaign) return {}
      // {source_brand: 1, bonus_type: "fbShare", report_data: "29"}
      campaign.invites.slice(0)
        .filter(invite => invite.status == 'accepted')
        .sort((a,b) => a.invitee.id - b.invitee.id)
        .forEach((invite) => {
        var dataItem = SocialTitleArray.map((socialType) => {
          var item = _.find(data, {
            source_brand: invite.invitee_id,
            bonus_type: socialType.name
          }) || {report_data: '0'};

          return parseInt(item.report_data);
        });

        socialItems.push({
          name: `brand-${invite.invitee_id}`,
          title: invite.invitee.accountname,
          data: dataItem,
          brandId: invite.invitee_id
        });
      });

      let colors = ['#13b697', '#3fdac6', '#bef6e9', '#9ad8fb', '#20c3e9', '#03a9f4', '#0176c8', '#0b53c5', '#1b3088', '#010040'];

      var colorMap = {};

      socialItems.forEach((item, idx) => {
        item.color = colors[idx];
        colorMap[item.name] = colors[idx];
      })

      var options = socialItems.map((item) => {
        return {
          value: item.brandId,
          label: item.title
        }
      });

      return {
        colorMap: colorMap,
        socialItems: socialItems,
        categories: SocialTitleArray.map((item) => { return item.title; }),
        hideMap: {},
        campaign: campaign,
        options: options,
        value: specificBrandId,
        selectedBrand: _.find(options, { value: specificBrandId }).label,
        raw: report.data
      }
    },
    campaignDemographicGraphs: ({metric, data}) => {
      let colors = ['#0176c8', '#03a9f4', '#14cba8'];
      if (data[0]) {
        if (Object.keys(data[0].state_distribution).length) {
          let stateDistribution = {};
          let stateDistributionTotal = _.values(data[0].state_distribution).reduce((a, b) => a + b)

          Object.entries(data[0].state_distribution).forEach(([key, val]) => {
            stateDistribution[key] = {
              count: val,
              percent: val / stateDistributionTotal
            }
          });

          data[0].state_distribution = stateDistribution;
        }

        var genderDist = data[0].gender_distribution;

        if (Object.keys(genderDist).length) {
          var male = parseInt(genderDist.male);
          male = isNaN(male) ? 0 : male;
          var female = parseInt(genderDist.female);
          female = isNaN(female) ? 0 : female;
          data[0].gender_distribution = [{
            x: 'female',
            y: female
          }, {
            x: 'male',
            y: male
          }]
        }

        if (Object.keys(data[0].income_distribution).length) {
          let incomeDistribution = [
            parseInt(data[0].income_distribution.income_below35k),
            parseInt(data[0].income_distribution.income_35k_49k),
            parseInt(data[0].income_distribution.income_50k_74k),
            parseInt(data[0].income_distribution.income_75k_99k),
            parseInt(data[0].income_distribution.income_above100k)
          ]

          data[0].income_distribution = {
            distribution: incomeDistribution,
            total: incomeDistribution.reduce((sum, value) => sum + parseInt(value), 0)
          }
        }
      }

      // logic for geographic map, gender, and income distribution graphs in campain analytics
      return data;
    }
  },
  metricInfo: {
    campaignVisits: {
      mappingLogic: 'lineGraphWithMultipleSeries'
    },
    campaignEntries: {
      mappingLogic: 'lineGraphWithMultipleSeries'
    },
    campaignNTF: {
      mappingLogic: 'lineGraphWithMultipleSeries'
    },
    campaignTotalSocialActions: {
      mappingLogic: 'lineGraphWithMultipleSeries'
    },
    campaignEntryContributionStatsByPartner: {
      //**NOTE** This data is used for both the "Entry Contribution" and "Stats by Partner" graph
      mappingLogic: 'campaignEntryContributionStatsByPartner'
    },
    campaignEntryContributionStatsByPartnerByPromoUnit: {
      mappingLogic: 'campaignEntryContributionStatsByPartnerByPromoUnit'
    },
    campaignEntryContribution: {
      mappingLogic: 'dummyPlaceholder'
    },
    campaignStatsByPartner: {
      mappingLogic: 'campaignStatsByPartnerGraph'
    },
    campaignPartnerSmsContributionPercent: {
      mappingLogic: 'campaignPartnerSmsContributionPercentGraph',
    },
    campaignSocialActionsByType: {
      mappingLogic: 'campaignSocialActionsByTypeGraph'
    },
    campaignCustomCTA: {
      mappingLogic: 'campaignCustomCTA'
    },
    campaignDemographics: {
      mappingLogic: 'campaignDemographicGraphs'
    },
    campaignSocialContributionByBrand: {
      mappingLogic: 'socialContributionGraph'
    },
    campaignConversionDetails: {
      mappingLogic: 'lineGraphSplit',
      fieldNames: {
        transactions: 'transactions',
        revenue: 'revenue',
      },
    },
    campaignSmsSummary: {
      mappingLogic: 'dummyPlaceholder',
    },
    campaignSmsRevenueSummary: {
      mappingLogic: 'dummyPlaceholder',
    },
    campaignPartnerSmsContribution: {
      mappingLogic: 'lineGraphWithMultipleSeries',
    },
  },
  actions: {
    UPDATE_BRAND_CAMPAIGN_CUSTOM_CTA: function () {
      return _.extend({}, state, action.data);
    }
  }
}
