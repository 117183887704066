export const openModal = () => ({
  type: 'UPDATE_ATTR',
  model: 'pricingElite',
  data: {
    modalOpen: true
  }
});

export const closeModal = () => ({
  type: 'UPDATE_ATTR',
  model: 'pricingElite',
  data: {
    modalOpen: false
  }
});

export const postLeadRequest = (pricingElite, brand) => ({
  http: {
    url: '/lead-request',
    method: 'POST',
    data: {
      brand,
      fullName: pricingElite.name,
      email: pricingElite.email,
      company: pricingElite.company,
      brandWebsite: pricingElite.brandWebsite,
      message: pricingElite.message,
      leadSource: 'Sales Contact Form'
    }
  },
  types: ['POST_LEAD_REQUEST', 'POST_LEAD_SUCCESS', 'POST_LEAD_FAILURE'],
})
