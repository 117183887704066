export const UPDATE_FONT = 'UPDATE_FONT'
export const UPDATE_FONT_SUCCESS = 'UPDATE_FONT_SUCCESS'
export const UPDATE_FONT_FAILURE = 'UPDATE_FONT_FAILURE'
export const LOAD_FONTS = 'LOAD_FONTS'
export const LOAD_FONTS_SUCCESS = 'LOAD_FONTS_SUCCESS'
export const LOAD_FONTS_FAILURE = 'LOAD_FONTS_FAILURE'


export const updateBrandFont = (font, isSelected) => dispatch => {
  dispatch({
    model: 'currentBrand',
    http: {
      url: '/brand-fonts',
      method: isSelected ? 'POST' : 'DELETE',
      data: {
        font,
      },
    },
    types: [
      UPDATE_FONT,
      UPDATE_FONT_SUCCESS,
      UPDATE_FONT_FAILURE,
    ],
  })
}

export const loadBrandFonts = () => dispatch => {
  dispatch({
    model: 'currentBrand',
    http: {
      url: '/brand-fonts',
      method: 'GET',
    },
    types: [
      LOAD_FONTS,
      LOAD_FONTS_SUCCESS,
      LOAD_FONTS_FAILURE,
    ],
  })
}
