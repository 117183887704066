import React from 'react'
import { Button } from 'react-toolbox/lib/button/Button'
import { Link } from 'react-router-dom'
import theme from 'css/themes/homePage/smsMarketing.css'
import ButtonTheme from 'css/themes/Buttons.css'
import Header from '../header/Header'

const SmsMarketingBanner = () => (
  <div className={theme.banner}>
    <Header />
    <div className={theme.container}>
      <div className={theme.leftBannerCol}>
        <div className={theme.wrapper}>
          <p className={theme.title}>SMS Marketing</p>
          <p className={theme.tag}>
            Tackle today’s greatest marketing challenge – cutting through the noise. With DojoMojo’s
            SMS solutions, compliment your acquisition funnel with personalized, targeted messages
            to reach your customers directly and drive more conversions, faster.
          </p>
          <a href="/app/register">
            <Button
              label="Start Messaging For Free"
              className={ButtonTheme.orangeButton}
              theme={ButtonTheme}
              floating
            />
          </a>
        </div>
      </div>
      <div className={theme.rightBannerCol}>
        <img
          src="/images/login/homepage/sms-marketing.png"
          srcSet="
            /images/login/homepage/sms-marketing.png,
            /images/login/homepage/sms-marketing@2x.png 2x,
            /images/login/homepage/sms-marketing@3x.png 3x
          "
        />
      </div>
    </div>
  </div>
)

export default SmsMarketingBanner
