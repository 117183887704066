import React, { Component } from 'react';
import theme from 'css/themes/homePage/footer.css';

class ContactAndCopyright extends Component {

  render() {
    const currentYear = new Date().getFullYear()

    return(
      <div className={ theme.contactContainer }>
        <p className={ theme.copyright }>
          {`Copyright ${currentYear} Innovation Brands Corp`}
        </p>

        <div className={ theme.contact }>
          <a href='mailto:hello@dojomojo.com'
            className={ theme.mailTo }
          >
            hello@dojomojo.com
          </a>
          <a href='https://www.facebook.com/dojomojoteam'
            target='_blank'
            className={ theme.imgLink }
          >
            <img alt="facebook" src='/images/login/homepage/socialIcons/facebook-icon.svg' />
          </a>
          <a href='https://www.linkedin.com/company/dojomojo/'
            target='_blank'
            className={ theme.imgLink }
          >
            <img alt="linkedin" src='/images/login/homepage/socialIcons/linkedin-icon.svg' />
          </a>
          <a href='https://twitter.com/DojoMojoTeam'
            target='_blank'
            className={ theme.imgLink }
          >
            <img alt="twitter" src='/images/login/homepage/socialIcons/twitter-icon.svg' />
          </a>
          <a href='https://www.instagram.com/dojomojoninja/'
          target='_blank'
          className={ theme.imgLink }
          >
            <img alt="instagram" src='/images/login/homepage/socialIcons/instagram-icon.svg' />
          </a>
        </div>
      </div>
    )
  }
}


export default ContactAndCopyright;
