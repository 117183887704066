import React, {Component} from 'react'
import Dialog from 'react-toolbox/lib/dialog'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

import dialogTheme from 'css/themes/dialogs/dialogBase.css'

/*
this is a _DIALOG_ meaning that it is NOT for forms. it should just be for
dismissiable elements

thus `canSave` and `hideLine` are always true, and why the closeModal is used
in all of the closing options
*/


class DefaultDialog extends Component {
  render() {
    let {
      active,
      closeModal,
      title,
      children,
      confirm,
      confirmText,
      cancel,
      cancelText,
      imgSrc,
    } = this.props

    return (
      <Dialog
        theme={dialogTheme}
        active={active}
        onEscKeyDown={closeModal}
        onOverlayClick={closeModal}
        title={title}
      >
        {
          imgSrc
          ? <div className={dialogTheme.imgWrapper}>
              <img src={imgSrc} />
            </div>
          : null
        }
        {children}
        <ModalButtonGroup
          canSave
          hideLine
          confirm={confirm}
          confirmText={confirmText}
          cancelText={cancelText}
          cancel={cancel}
        />
      </Dialog>
    )
  }
}

export default DefaultDialog
