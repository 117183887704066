import React from 'react'
import * as ReactRedux from 'react-redux'

import Dialog from 'react-toolbox/lib/dialog'
import { H4 } from 'visual-components/util/texts'

import Field from 'visual-components/util/inputs/field'
import FieldDispatcher from 'dispatchers/field-dispatcher'
import buttonTheme from 'css/themes/Buttons.css'
import { Button } from 'react-toolbox/lib/button'
import Validator from 'util/form-validator'
import AppDispatcher from 'dispatchers/app-dispatcher'
import DialogTheme from 'css/themes/dialogs/formModal.css'
import InputTheme from 'css/themes/Input.css'

const validations = {
  name: [Validator.required],
  email: [Validator.required, Validator.isValidEmail],
  website: [Validator.IsWebsite],
}

const themes = {
  Dialog: DialogTheme,
  Input: InputTheme,
}

const mapState = function (state) {
  return {
    existingBrandSupport: state.existingBrandSupport,
    existingBrandSupportForm: state.existingBrandSupportForm,
  }
}

class ExistingBrandSupportModal extends React.Component {
  state = {
    openSuccessModal: false,
  }

  constructor(props) {
    super(props)
    this.contactSupport = this.contactSupport.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { brand, existingBrandSupport } = this.props

    if (
      brand.accountname !== prevProps.brand.accountname
      || brand.website !== prevProps.brand.website
    ) {
      FieldDispatcher.updateAttr(existingBrandSupport.rid, {
        brand: brand.accountname,
        website: brand.website,
      })
    }
  }

  successModalClose = () => {
    this.setState({ openSuccessModal: false })
  }

  clearForms = () => {
    store.dispatch({
      model: 'newBrandForm',
      type: 'CLEAR',
    })
    store.dispatch({
      model: 'newBrand',
      type: 'CLEAR',
    })
    store.dispatch({
      model: 'existingBrandSupport',
      type: 'CLEAR',
    })
    store.dispatch({
      model: 'existingBrandSupportForm',
      type: 'CLEAR',
    })
  }

  contactSupport() {
    const { onClose, existingBrandSupport, existingBrandSupportForm } = this.props

    const that = this
    Validator.validateForm(validations, existingBrandSupport, 'existingBrandSupportForm', valid => {
      if (valid) {
        AppDispatcher.http({
          url: '/zendesk-contact-support',
          method: 'POST',
          data: {
            name: existingBrandSupport.name,
            email: existingBrandSupport.email,
            subject: `Existing Brand Sign Up: ${existingBrandSupport.brand}`,
            message: `Brand Name: ${existingBrandSupport.brand}
            Brand Website: ${existingBrandSupport.website}

            Comments: ${
  existingBrandSupport.comments ? `${existingBrandSupport.comments}` : 'None'
}`,
          },
          success() {
            onClose()
            that.setState({ openSuccessModal: true })
          },
        })
      }
    })
  }

  render() {
    const {
      profile,
      brand,
      onClose,
      open,
      existingBrandSupport,
      existingBrandSupportForm,
    } = this.props

    return (
      <React.Fragment>
        <Dialog
          theme={themes.Dialog}
          active={open}
          onEscKeyDown={onClose}
          onOverlayClick={onClose}
          title={
            brand.accountname
              ? `Sorry, ${brand.accountname} already has an account`
              : 'Sorry, the brand you entered already exists'
          }
        >
          <H4 multiline>
            Let us help you get access. Please provide us with the information below.
          </H4>
          <br />
          <img
            onClick={onClose}
            className={themes.Dialog.closeImage}
            src="/images/icons/close.svg"
          />
          <Field
            label="Contact Name *"
            attrName="name"
            dataModel={existingBrandSupport}
            formModel={existingBrandSupportForm}
            validations={validations.name}
          />

          <Field
            label="Contact Email *"
            attrName="email"
            type="email"
            dataModel={existingBrandSupport}
            formModel={existingBrandSupportForm}
            validations={validations.email}
          />

          <Field
            label="Brand Name *"
            attrName="brand"
            disabled
            dataModel={existingBrandSupport}
            formModel={existingBrandSupportForm}
            validations={validations.name}
          />

          <Field
            label="Brand Website *"
            attrName="website"
            dataModel={existingBrandSupport}
            formModel={existingBrandSupportForm}
            validations={validations.website}
          />

          <Field
            label="Comments (Optional)"
            attrName="comments"
            multiline
            rows={5}
            dataModel={existingBrandSupport}
            formModel={existingBrandSupportForm}
            validations={validations.comments}
            className={themes.Input.textarea}
          />

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ width: '132px' }}
              type="submit"
              onClick={this.contactSupport}
              className={`${buttonTheme.blueButton} ${buttonTheme.noMargin}`}
              theme={buttonTheme}
              label="Submit"
            />
          </div>
        </Dialog>
        <Dialog theme={themes.Dialog} active={this.state.openSuccessModal} title="Almost there...">
          <H4 multiline>This information has been sent. We will be in touch shortly.</H4>
          <img
            onClick={this.successModalClose}
            className={themes.Dialog.closeImage}
            src="/images/icons/close.svg"
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href="/home" onClick={this.clearForms}>
              <Button
                style={{ marginTop: '32px' }}
                type="submit"
                className={`${buttonTheme.blueButton}`}
                theme={buttonTheme}
                label="Okay"
              />
            </a>
          </div>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default ReactRedux.connect(mapState)(ExistingBrandSupportModal)
