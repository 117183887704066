import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  number,
  object,
  string,
  bool,
} from 'prop-types'
import { Small, Tiny } from 'visual-components/util/texts'
import IntegrationTheme from 'css/themes/integrations.css'
import IntegrationIcon from './integration-icon'

class IntegrationItem extends React.Component {
  static propTypes = {
    idx: number.isRequired,
    currentCampaign: object,
    integration: object.isRequired,
    espIntegrations: object.isRequired,
    history: object.isRequired,
    type: string,
    noBoxShadow: bool,
  }

  static defaultProps = {
    type: 'ESP',
    noBoxShadow: false,
    currentCampaign: null,
  }

  openModal = () => {
    const {
      currentCampaign,
      integration,
      espIntegrations: { model },
      history,
    } = this.props

    const link = model === 'brand' ? `/profile/brand/esp/${integration.key}`
      : `/partnerships/campaign/${currentCampaign.id}/email-delivery/${integration.key}`

    history.push(link)
  }

  render() {
    const {
      idx,
      currentCampaign,
      integration,
      espIntegrations: { model, espIntegrations },
      type,
      noBoxShadow,
    } = this.props

    if (!espIntegrations || (model === 'campaign' && !currentCampaign)) return null

    const integrationItem = espIntegrations.find(i => i.integration_type === integration.key)

    const hasSuccess = integrationItem && integrationItem.verified === true
      && integrationItem.activated === true
    const hasError = integrationItem && integrationItem.verified === false

    let imgName
    let extension
    let srcSet
    if (integration.img) {
      [imgName, extension] = integration.img.split('.')
      srcSet = imgName && extension && `
        ${imgName}-logo.${extension},
        ${imgName}-logo@2x.${extension} 2x,
        ${imgName}-logo@3x.${extension} 3x
      `
    }

    return (
      <div
        onMouseOver={this.toggleHover}
        onMouseOut={this.toggleHover}
        onClick={this.openModal}
        className={`
          ${IntegrationTheme.integrationItem}
          ${noBoxShadow && IntegrationTheme.noBoxShadow}
          ${(idx + 1) % (model === 'brand' ? 5 : 4) === 0 && IntegrationTheme.noMargin}
        `}
      >
        { hasSuccess || hasError
          ? <IntegrationIcon success={integrationItem.verified} />
          : null
        }
        <img
          src={integration.img}
          srcSet={srcSet}
          className={IntegrationTheme.itemLogo}
        />
        <div className={IntegrationTheme.itemContent}>
          <div className={IntegrationTheme.itemLabel}>
            <Small>
              <b>
                {integration.label}
              </b>
            </Small>
          </div>
          <Tiny>
            {type}
          </Tiny>
        </div>
      </div>
    )
  }
}

export default withRouter(IntegrationItem)
