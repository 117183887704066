import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import { SMSEditorContext } from '../SMSEditorContext'
import ImageItem from './ImageItem'

const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}

function ImagesPreview(props) {
  const { classes: css } = props

  const {
    images,
    actions: { onSetImages },
  } = useContext(SMSEditorContext)

  if (!images.length) return null

  return (
    <div className={css.wrapper}>
      {images.map(image => (
        <ImageItem
          key={image}
          image={typeof image === 'string' ? image : image.fullSrc || image.img64}
          onDelete={() => onSetImages(image, true)}
        />
      ))}
    </div>
  )
}

ImagesPreview.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(ImagesPreview))
