import React, { useState } from 'react'
import { Card } from 'react-toolbox/lib/card'

import { Form, Input, Submit } from 'visual-components/util/form'
import { P, H2 } from 'visual-components/util/texts'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import AppDispatcher from 'dispatchers/app-dispatcher'

const CSAdminUpdateBrandName = () => {
  const [open, setOpen] = useState(false)
  const [brandId, setBrandId] = useState(null)
  const [brandName, setBrandName] = useState(null)

  const options = { timeOut: 3000, positionClass: 'toast-bottom-center' }

  const handleChange = e => {
    if (e.brandId) setBrandId(e.brandId)
    if (e.brandName) setBrandName(e.brandName)
  }

  const lookupBrand = async () => {
    setBrandName(null) // Clear current brand name before search
    await AppDispatcher.http({
      url: `/cs-brand-lookup/${brandId}`,
      method: 'GET',
      success: res => setBrandName(res.accountname),
      error: err => toastr.error(err.responseText, null, options),
    })
  }

  const updateBrandName = async () => {
    await AppDispatcher.http({
      url: `/cs-brand-name-update/${brandId}`,
      method: 'POST',
      data: { brandName },
      success: async () => {
        setBrandId(null)
        setBrandName(null)
        toastr.success('Brand updated successfully.', null, options)
        await AppDispatcher.http({
          url: `/cs-update-brand/${brandId}`,
          method: 'GET',
        })
      },
      error: err => toastr.error(err.responseText, null, options),
    })
  }

  const CaretIcon = `fa fa-angle-${open ? 'up' : 'down'}`

  return (
    <Card style={{ padding: 24 }}>
      <Grid>
        <Row style={{ marginBottom: 16 }}>
          <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
            <H2 azure>
              Update Brand Name
              <i className={CaretIcon} style={{ fontSize: 32, marginLeft: 8 }} />
            </H2>
            <P multiline>Updates brand name (including on Salesforce and Stripe).</P>
          </div>
        </Row>
        <Row style={{ display: open ? 'flex' : 'none' }}>
          <Col style={{ width: 175, display: 'inline-block', marginRight: 75 }}>
            <Form handleSubmit={lookupBrand} updateModel={handleChange}>
              <Input
                name="brandId"
                label="Brand ID"
                placeholder="Enter Brand ID"
                type="number"
                required
              />
              <Submit label="Look Up Brand" />
            </Form>
          </Col>
          <Col style={{ width: 450, display: 'inline-block' }}>
            <Form handleSubmit={updateBrandName} updateModel={handleChange}>
              <Input
                name="brandName"
                label={`Brand Name${brandName ? `: ${brandName}` : ''}`}
                placeholder="Enter Updated Brand Name"
                value={brandName}
                type="text"
                required
                disabled={!brandName}
              />
              <Submit label="Update Brand Name" disabled={!brandName} />
            </Form>
          </Col>
        </Row>
      </Grid>
    </Card>
  )
}

export default CSAdminUpdateBrandName
