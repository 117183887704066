import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { P, Tiny, H5, Small } from 'visual-components/util/texts';
import { makeNumbersPretty } from 'util/makeNumbersPretty';

class EventTooltipCard extends React.Component {
  render() {
    var {
    period,
    name,
    entries,
    newEmails
    } = this.props;

    return (
      <Card style={{
        paddingTop: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        width: '185px'
      }}>
        <Tiny>
          {period}
        </Tiny>
        <div
          style={{marginTop: '10px', marginBottom: '10px'}}>
          <Small>
            <b>
              {name}
            </b>
          </Small>
        </div>
        <div>
          <div style={{
            display: 'inline-block',
            width: '80px'
          }}>
            <H5>
            {makeNumbersPretty(entries)}
            </H5>
            <Tiny>
            entries driven
            </Tiny>
          </div>
          <div style={{
            display: 'inline-block',
            width: '80px'
          }}>
            <H5>
            {makeNumbersPretty(newEmails)}
            </H5>
            <Tiny>
            NTF emails
            </Tiny>
          </div>
        </div>
      </Card>
   )
 }
};

export default EventTooltipCard;
