import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { P, Tiny, H5, H2, Small } from 'visual-components/util/texts';

class SocialNumber extends React.Component {
  render() {
    var {
      label,
      value
    } = this.props;

    return (
      <div style={{marginTop: '12px'}}>
        <div
        style={{
          display: 'inline-block',
          width: '130px',
          textAlign: 'left'
        }}>
          <Small>
            {label}
          </Small>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '70px',
            textAlign: 'right'
          }}>
          <H5>
            {d3.format(',')(value)}
          </H5>
        </div>
      </div>
    )
  }
}

class CampaignSocialTooltip extends React.Component {
  render() {
    var {
      date,
      data,
      platform
    } = this.props;

    let aggregate = 0;
    var brandRows = data.map(function (item, idx) {
      if (!item.value) {
        return;
      }
      aggregate += parseInt(item.value);
      return (
        <SocialNumber
          key={idx}
          {...item} />
      )
    });

    return (
      <Card style={{
        paddingTop: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        width: '224px'
      }}>
        <Tiny>
          {date}
        </Tiny>
        <div style={{marginTop: '12px'}}>
          <Small>
            <b>
              {platform}
            </b>
          </Small>
        </div>
        <div style={{
          marginTop: '12px'
        }}>
          <div style={{
            display: 'inline-block',
            marginRight: '4px'
          }}>
            <H2>
              { d3.format(',')(aggregate || 0) }
            </H2>
          </div>
          <div style={{
            display: 'inline-block'
          }}>
            <Small>
              total
            </Small>
          </div>
        </div>

        {brandRows}
      </Card>
    )
  }
};

export default CampaignSocialTooltip;
