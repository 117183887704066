import React from 'react'
import { Card } from 'react-toolbox/lib/card'
import { H6, P } from 'visual-components/util/texts'
import Theme from 'css/themes/payments/billing-payment-methods-theme.css'

const mapBankAccounts = ({
  customer, editSource, deleteSource, renderCTAButton,
}) => {
  const accounts = customer.bankAccounts
  const defaultAccount = customer.default_source
  const accountTypeMap = {
    individual: 'Individual',
    company: 'Company',
  }

  if (!accounts) return null

  return accounts.map(item => (
    <Card className={Theme.paymentMethod__rowItem}>
      <div className={Theme.paymentMethod__rowItem__container}>
        <div className={Theme.paymentMethod__rowItem__dataColumns}>
          <div>
            <div className={`${Theme.paymentMethod__rowItemData__ownerBusinessName} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
              <H6>Owner / Business Name</H6>
            </div>
            <P>{item.account_holder_name}</P>
          </div>

          <div>
            <div className={`${Theme.paymentMethod__rowItemData__bankName} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
              <H6>Bank Name</H6>
            </div>
            <P>{item.bank_name}</P>
          </div>

          <div>
            <div className={`${Theme.paymentMethod__rowItemData__acctNumber} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
              <H6>Acct. Number</H6>
            </div>
            <P>{`*****${item.last4}`}</P>
          </div>

          <div>
            <div className={`${Theme.paymentMethod__rowItemData__acctType} ${Theme.paymentMethod__rowItemData__sectionLabel}`}>
              <H6>Type</H6>
            </div>
            <P>{accountTypeMap[item.account_holder_type]}</P>
          </div>

          <div className={Theme.paymentMethod__rowItemData__verificationCheck}>
            {
              item.status === 'verified'
                ? <img src="images/icons/green-verified-checkmark.svg" className={Theme.icon} />
                : <img src="images/icons/non-verified-checkmark.svg" className={Theme.icon} />
            }
          </div>
        </div>

        <div className={Theme.paymentMethod__rowItem__actionButtons}>
          {
            item.id === defaultAccount && item.status === 'verified'
              ? <div className={Theme.defaultLabel}><P>Your Default</P></div>
              : renderCTAButton(item)
          }

          <div className={`${Theme.icon} ${Theme.edit}`} onClick={() => editSource(item)}>
            <img src="images/icons/blue-edit.svg" />
          </div>

          <div
            className={`${Theme.icon} ${Theme.trash} ${item.id === defaultAccount ? Theme.hidden : ''}`}
            onClick={() => deleteSource(item)}
          >
            <img src="images/icons/blue-trash.svg" />
          </div>
        </div>

      </div>
    </Card>
  ))
}

export default mapBankAccounts
