import React from 'react'
import * as ReactRedux from 'react-redux'
import Field from 'util/components/field'
import NumberField from 'util/components/number-field'
import Validator from 'util/form-validator'
import COLOURS from 'util/colours'
import PrimaryButton from 'util/components/buttons/primary-button'

import AppDispatcher from 'dispatchers/app-dispatcher'

const validations = {
  fullName: [Validator.required],
  email: [Validator.required, Validator.isValidEmail],
  company: [Validator.required],
  phone: [Validator.required],
  listSize: []
}


var mapState = function (state) {
  return {
    profile: state.profile,
    currentBrand: state.currentBrand,
    eliteRequest: state.eliteRequest,
    eliteRequestForm: state.eliteRequestForm
  };
}


class EliteRequest extends React.Component {
  constructor(props) {
    super(props);

    this.request = this.request.bind(this);
    this.navigateTo = this.navigateTo.bind(this);

    // Set form defaults
    this.props.eliteRequest.fullName = this.props.profile.fullName || `${this.props.profile.firstName || ''} ${this.props.profile.lastName || ''}`;
    this.props.eliteRequest.email = this.props.profile.email || '';
    this.props.eliteRequest.company = this.props.currentBrand.accountname || '';
    this.props.eliteRequest.phone = this.props.currentBrand.phone || '';
    this.props.eliteRequest.listSize = this.props.currentBrand.dedicatedListSize || 0;
  }

  request(e) {
    e.preventDefault();
    let eliteRequest = this.props.eliteRequest;

    Validator.validateForm(validations, eliteRequest, 'eliteRequestForm', function(valid) {
      if (!valid) {
        return;
      }

      let data = {
        phone: eliteRequest.phone,
        listSize: eliteRequest.listSize,
        email: eliteRequest.email,
        fullName: eliteRequest.fullName,
        company: eliteRequest.company,
        leadSource: 'Sales Contact Form'
      };

      AppDispatcher.http({
        url: '/lead-request',
        method: 'POST',
        data,
        success: function() {
          toastr.success('Contact Request Sent!', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        }
      })
    });
  }

  navigateTo(e) {
    e.preventDefault();
    var $target = $($(e.currentTarget).data('target'));

    if(window.innerWidth > 990)
      window.scrollTo(0,$target.offset().top-60);
    else
      window.scrollTo(0,$target.offset().top);
  }

  render() {
    let eliteRequest = this.props.eliteRequest;
    let eliteRequestDisabled = !eliteRequest.email;

    let inputStyle = {
      fontFamily: 'Larsseit-Light',
      color: COLOURS.ink,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      boxShadow: 'none',
      borderRadius: '0px',
      paddingTop: '0px',
      paddingBottom: '0px'
    };

    let divStyle = {
      marginBottom: '-10px'
    }

    let containerStyle = {
      backgroundColor: COLOURS.eggshell,
      borderTop: '1px solid #cccccc',
      paddingTop: '50px',
      paddingBottom: '50px'
    }

    let formContainerStyle = {
      backgroundColor: COLOURS.iceBlue,
      padding: '50px',
      overflowY: 'hidden',
      marginTop: '50px'
    }

    let imgStyle = {
      position: 'absolute',
      bottom: '0',
      left: '31%',
      marginLeft: '-180px',
      width: '360px'
    }

    return (
      <div>
        <div id="eliteRequest"
          className="Elite-Request--containerStyle"
          style={containerStyle}>
          <div className="container">
            <div className="col-md-12 background-light-green-full Elite-Request--formContainerStyle"
              style={formContainerStyle}>
              <div className="row">
                <div className="col-md-7 col-xs-12 text-center">
                  <h2 style={{
                    marginTop: '35px',
                    width: '250px',
                    display: 'inline-block',
                    color: COLOURS.azure,
                    fontFamily: 'Larsseit-Medium',
                    fontSize: '37px',
                    lineHeight: '40px'
                  }}>
                    Learn More About Elite
                  </h2>
                </div>

                <img src="/images/big-tiger-face.svg"
                  className="Elite-Request--imgStyle"
                  style={imgStyle} />
                <div className="col-md-5 col-xs-12">
                  <form className="form" onSubmit={ this.request }>
                    <Field
                      validations={ validations.fullName }
                      attrName="fullName"
                      placeholder={ 'Full Name' }
                      value={ `${this.props.profile.firstName} ${this.props.profile.lastName}` }
                      dataModel={ this.props.eliteRequest }
                      formModel={ this.props.eliteRequestForm }
                      customDivStyle={ divStyle }
                      customInputStyle={ inputStyle } />

                    <Field
                      validations={ validations.email }
                      attrName="email"
                      placeholder={ 'Email' }
                      value={ this.props.profile.email }
                      dataModel={ this.props.eliteRequest }
                      formModel={ this.props.eliteRequestForm }
                      customDivStyle={ divStyle }
                      customInputStyle={ inputStyle } />

                    <Field
                      validations={ validations.company }
                      attrName="company"
                      placeholder={ 'Company' }
                      value={ this.props.currentBrand.accountname }
                      dataModel={ this.props.eliteRequest }
                      formModel={ this.props.eliteRequestForm }
                      customDivStyle={ divStyle }
                      customInputStyle={ inputStyle } />

                    <Field
                      validations={ validations.phone }
                      attrName="phone"
                      placeholder={ 'Phone' }
                      value={ this.props.currentBrand.phone }
                      dataModel={ this.props.eliteRequest }
                      formModel={ this.props.eliteRequestForm }
                      customDivStyle={ divStyle }
                      customInputStyle={ inputStyle } />

                    <NumberField
                      validations={ validations.listSize }
                      attrName="listSize"
                      placeholder={ 'Email List Size' }
                      value={ this.props.currentBrand.dedicatedListSize }
                      dataModel={ this.props.eliteRequest }
                      formModel={ this.props.eliteRequestForm }
                      customDivStyle={ divStyle }
                      customInputStyle={ inputStyle } />

                    <div>
                      <PrimaryButton type="submit"
                        isDisabled={ eliteRequestDisabled }
                        blueButton
                        styles={{
                          width:'100%',
                          marginTop: '60px',
                          backgroundColor: COLOURS.azure,
                          borderColor: COLOURS.azure,
                          fontFamily: 'Larsseit-Light'
                      }}>
                        Request
                      </PrimaryButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ReactRedux.connect(mapState)(EliteRequest)
