import React from 'react'
import {
  Editor, EditorState, RichUtils, ContentState, Modifier, Entity, SelectionState,
} from 'draft-js'
import linkifyIt from 'linkify-it'
import tlds from 'tlds'

import StyleButton from 'visual-components/richEditor/style-button'
import Button from 'react-toolbox/lib/button'
import PropTypes from 'prop-types'
import MessengerTheme from 'css/themes/media-market/Messenger.css'
import AssetUploader from './AssetUploader'
import ButtonTheme from 'css/themes/Buttons.css'

const linkify = linkifyIt()
linkify.tlds(tlds)

const themes = {
  Button: ButtonTheme,
}

class MessageEditor extends React.Component {
  state = {
    editorState: this.props.initialContent
      ? EditorState.createWithContent(this.props.initialContent)
      : EditorState.createEmpty(),
  }

  focus = () => this.editor.focus()

  onChange = editorState => {
    const { onChange } = this.props

    if (onChange) {
      onChange(editorState)
    }

    this.setState({ editorState }, () => {
      const { onEditorUpdate } = this.props
      onEditorUpdate()
    })
  }

  handleKeyCommand = command => this._handleKeyCommand(command)

  onTab = e => this._onTab(e)

  toggleBlockType = type => this._toggleBlockType(type)

  toggleInlineStyle = style => this._toggleInlineStyle(style)

  onButtonClick = () => this._onButtonClick()

  _handleKeyCommand(command) {
    const { editorState } = this.state

    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)

      return true
    }
    return false
  }

  _onTab(e) {
    const { editorState } = this.state
    const maxDepth = 4

    return this.onChange(RichUtils.onTab(e, editorState, maxDepth))
  }

  _toggleBlockType(blockType) {
    const { editorState } = this.state

    return this.onChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  _toggleInlineStyle(inlineStyle) {
    const { editorState } = this.state

    return this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  _onButtonClick() {
    const { buttonClick } = this.props
    const { editorState } = this.state
    const contentState = editorState.getCurrentContent()
    const plainText = contentState.getPlainText().length
    
    if (!plainText) return

    const updatedEditorState = linkifyEditorState(editorState)
    
    buttonClick(updatedEditorState.getCurrentContent())

    const newEditorState = EditorState.push(updatedEditorState, ContentState.createFromText(''))

    this.setState({ editorState: newEditorState }, () => {
      const { onEditorUpdate } = this.props

      onEditorUpdate()
    })
  }

  render() {
    const { editorState } = this.state

    const { styleOptions } = this.props

    // Split passed options between the inline and block styles
    const inlineStyleOptions = styleOptions ? styleOptions.filter(value => (
      ['Bold', 'Italic', 'Underline', 'Monospace'].includes(value)
    )) : []

    const blockStyleOptions = styleOptions ? styleOptions.filter(value => (
      ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'UL', 'OL', 'Code Block'].includes(value)
    )) : []

    return (
      <div>
        <div className={MessengerTheme.line} />

        <div className={MessengerTheme.textWindow}>
          <Editor
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder="Type your message here"
            ref={ref => { this.editor = ref }}
            spellCheck
          />
        </div>

        <div className={MessengerTheme.line} />

        <div className={MessengerTheme.textFooterButtonsGroup}>
          { styleOptions
            ? (
              <div>
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                  activeOptions={inlineStyleOptions}
                />
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                  activeOptions={blockStyleOptions}
                />
                <AssetUploader />
              </div>
            )
            : null
          }

          <div>
            <Button
              label="Send Message"
              theme={themes.Button}
              className={`${themes.Button.blueButton} ${themes.Button.noMargin} ${themes.Button.messageButton}`}
              onClick={this.onButtonClick}
              primary
              raised
            />
          </div>
        </div>
      </div>

    )
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote'
    default:
      return null
  }
}

// All possible block types
const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one', icon_unselected: null, icon_selected: null },
  { label: 'H2', style: 'header-two', icon_unselected: null, icon_selected: null },
  { label: 'H3', style: 'header-three', icon_unselected: null, icon_selected: null },
  { label: 'H4', style: 'header-four', icon_unselected: null, icon_selected: null },
  { label: 'H5', style: 'header-five', icon_unselected: null, icon_selected: null },
  { label: 'H6', style: 'header-six', icon_unselected: null, icon_selected: null },
  { label: 'Blockquote', style: 'blockquote', icon_unselected: null, icon_selected: null },
  { label: 'UL', style: 'unordered-list-item', icon_unselected: 'bullet-list-icon-unselected.svg', icon_selected: 'bullet-list-icon-selected.svg'},
  { label: 'OL', style: 'ordered-list-item', icon_unselected: null, icon_selected: null },
  { label: 'Code Block', style: 'code-block', icon_unselected: null, icon_selected: null },
]

// All possible inline style types
const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon_unselected: 'bold-icon-unselected.svg', icon_selected: 'bold-icon-selected.svg' },
  { label: 'Italic', style: 'ITALIC', icon_unselected: 'italic-icon-unselected.svg', icon_selected: 'italic-icon-selected.svg' },
  { label: 'Underline', style: 'UNDERLINE', icon_unselected: null, icon_selected: null },
  { label: 'Monospace', style: 'CODE', icon_unselected: null, icon_selected: null },
]

const BlockStyleControls = props => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const editorContent = editorState.getCurrentContent()
  const blockType = editorContent.getBlockForKey(selection.getStartKey()).getType()

  return (
    <div className="RichEditor-controls" style={{ display: 'inline-block' }}>
      {
        BLOCK_TYPES.filter(type => props.activeOptions.includes(type.label)).map(type => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            selectedIcon={type.icon_selected}
            unselectedIcon={type.icon_unselected}
          />
        ))
      }
    </div>
  )
}

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <div className="RichEditor-controls" style={{ display: 'inline-block' }}>
      {
        INLINE_STYLES.filter(type => props.activeOptions.includes(type.label)).map(type => (
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            selectedIcon={type.icon_selected}
            unselectedIcon={type.icon_unselected}
          />
        ))
      }
    </div>
  )
}

const findLinks = (contentBlock, callback) => {
  const links = linkify.match(contentBlock.get('text'))
  if (typeof links !== 'undefined' && links !== null) {
    for (let i = 0; i < links.length; i++) {
      callback(links[i])
    }
  }
}

const linkifyEditorState = editorState => {
  const contentState = editorState.getCurrentContent()
  const blocks = contentState.getBlockMap()

  let newContentState = contentState
  blocks.forEach(block => {
    const plainText = block.getText()

    const addEntityToLink = ({ index: start, lastIndex: end, schema, url }) => {
      const existingEntityKey = block.getEntityAt(start)
      if (existingEntityKey) {
        // avoid manipulation in case the link already has an entity
        const entity = Entity.get(existingEntityKey)
        if (entity && entity.get('type') === 'link') return
      }

      const selection = SelectionState.createEmpty(block.getKey()).set('anchorOffset', start).set('focusOffset', end)
      const linkText = plainText.substring(start, end)

      const entity = schema !== 'mailto:' ? { url, target: '_blank' } : { url }

      const entityKey = Entity.create('LINK', 'IMMUTABLE', entity)
      newContentState = Modifier.replaceText(
        newContentState,
        selection,
        linkText,
        null,
        entityKey
      )
    }

    findLinks(block, addEntityToLink)
  })

  if (!newContentState.equals(contentState)) {
    return EditorState.push(editorState, newContentState, 'convert-to-links')
  }

  return editorState
}

MessageEditor.propTypes = {
  styleOptions: PropTypes.array,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func,
  onEditorUpdate: PropTypes.func.isRequired,
}

export default MessageEditor
