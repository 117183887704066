import React, { Component } from 'react'
import { string, func, object } from 'prop-types'
import { DateRangePicker } from 'react-dates'
import { Smaller } from 'visual-components/util/texts'

class CustomDateRange extends Component {
  static propTypes = {
    name: string.isRequired,
  }

  static contextTypes = {
    searchModel: object.isRequired,
    handleChangeImmediately: func.isRequired,
  }

  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  }

  componentDidMount() {
    $('.DateRangePickerInput').hide()
  }

  componentDidUpdate() {
    $('.DateRangePicker_picker').css({ top: '0px' })
  }

  onFocusChange = focusedInput => this.setState({ focusedInput })

  open = () => this.onFocusChange('startDate')

  onChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate })
    if (startDate && endDate) {
      this.context.handleChangeImmediately(this.props.name, {
        customValue: { startDate, endDate },
      })
    }
  }

  render() {
    const { startDate, endDate, focusedInput } = this.state

    return (
      <div style={{ paddingLeft: '24px' }}>
        <Smaller>
          <a onClick={this.open}>
            {
              startDate
              && endDate
                ? `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`
                : 'Select date range'
            }
          </a>
        </Smaller>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.onChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
        />
      </div>
    )
  }
}

export default CustomDateRange
