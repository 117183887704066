import AppDispatcher from 'dispatchers/app-dispatcher';
import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher';
import _ from 'lodash'

var updateCampaigns = _.debounce(function() {
    store.dispatch({
      model: 'pastCampaigns',
      type: 'LOAD',
    });
    store.dispatch({
      model: 'currentCampaigns',
      type: 'LOAD',
    });
    store.dispatch({
      model: 'upcomingCampaigns',
      type: 'LOAD',
    });
},6000);

var updateAttr = function(attr, value) {
  if (attr === 'styles') {
    value = _.merge({}, store.getState().campaign.styles, value);
  }

  let data = {};
  data[attr] = value;
  store.dispatch({
    type: 'UPDATE_ATTR',
    model: 'currentCampaign',
    data: data
  });

  store.dispatch({
    type: 'UPDATE_CAMPAIGN',
    model: 'brandCampaigns',
    data: {id: store.getState().currentCampaign.id, attr: data}
  })


  updateCampaigns();
}

var updateAttrPersist = _.debounce(function(attr, value, callback) {

    if (attr != 'errors') {
    let data = {};

    if (typeof attr !== 'object') {
      data[attr] = value;
    } else {
      data = attr
    }

    var id = store.getState().currentCampaign.id;

    updateAttr(attr, value);

    AppDispatcher.http({
      url: '/campaigns/' + id,
      method: 'PUT',
      data,
      success: () => {
        updateCampaigns()

        if (callback) {
          callback();
        } else {
          toastr.success('Saved', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        }
      },
      error: function(error) {
        if (error && error.responseText && error.responseText.startsWith('You have exceeded')) {
          CampaignDispatcher.showCampaignCreatedLimitModal(true)
        }
      }
    });
  }
}, 300)

var updateAttrPersistId = function(attr, value, id, callback) {
  if (attr != 'errors') {
    const { currentCampaign } = store.getState()

    let data = {};
    data[attr] = value;

    updateAttr(attr, value);

    AppDispatcher.http({
      url: '/campaigns/' + id,
      method: 'PUT',
      data: data,
      success: () => {
        updateCampaigns()

        if (callback) {
          callback();
        } else {
          toastr.success('Saved', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
        }
      },
      error: function(error) {
        updateAttr(attr, currentCampaign[attr])
        if (error && error.responseText && error.responseText.startsWith('You have exceeded')) {
          CampaignDispatcher.showCampaignCreatedLimitModal(true)
        }
      }
    });
  }
}

const CampaignDispatcher = {
  loadCampaignsData: () => {
    AppDispatcher.http({
      url: '/campaigns-data',
      success: function(data) {
        store.dispatch({
          model: 'brandCampaigns',
          data: data,
          type: 'LOAD_CAMPAIGNS'
        });
        store.dispatch({
          model: 'pastCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'currentCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'upcomingCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'inDiscussionCampaigns',
          type: 'LOAD'
        });
        store.dispatch({
          type: 'LOAD',
          model: 'topPartners',
          data: {
            brandId: store.getState().currentBrand.id
          }
        });
        store.dispatch({
          type: 'LOAD',
          model: 'campaignHistory',
          data: {
            brandId: store.getState().currentBrand.id
          }
        })
      }
    });
  },
  createCampaignRequest: function(data, afterDest) {
    var that = this;
    store.dispatch({
      type: 'LOADING',
      model: 'currentCampaign'
    })
    AppDispatcher.http({
      url: '/campaigns',
      method: 'POST',
      data: data,
      success: function(campaign) {
        CampaignDispatcher.loadCampaignsData();

        store.dispatch({
          type: 'LOADING_SUCCESS',
          model: 'currentCampaign',
          data: campaign
        })

        BrowserHistoryDispatcher.push(`/builder/${campaign.id}/${afterDest}`);
      },
      error: function(error) {
        if (error && error.responseText && error.responseText.startsWith('You have exceeded')) {
          CampaignDispatcher.showCampaignCreatedLimitModal(true)
        }
      }
    })
  },
  rejectInvite: (campaignId, partnershipId) => {
    AppDispatcher.http({
      url: `/campaign-remove-partner/${campaignId}/${partnershipId}`,
      method: 'POST',
      success: (invite) => {
        let currentInvites = store.getState().currentCampaign.invites;
        const inviteIndex = _.findIndex(currentInvites, { id: partnershipId });
        let updatedInvites = currentInvites.slice(0);
        updatedInvites[inviteIndex] = Object.assign({}, updatedInvites[inviteIndex], { status: 'rejected'})

        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentCampaign',
          data: {
            invites: updatedInvites
          }
        })
      }
    });
  },
  acceptApplication: (campaignId, partnershipId) => {
    AppDispatcher.http({
      url: '/partnership-application/accept/' + campaignId + '/' + partnershipId,
      method: 'GET',
      success: (invite) => {
        let currentInvites = store.getState().currentCampaign.invites;
        const inviteIndex = _.findIndex(currentInvites, { id: partnershipId });
        let updatedInvites = currentInvites.slice(0);
        updatedInvites[inviteIndex] = Object.assign({}, updatedInvites[inviteIndex], { status: 'accepted'})

        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentCampaign',
          data: {
            invites: updatedInvites
          }
        })
      }
    });
  },
  rejectApplication: (campaignId, partnershipId) => {
    AppDispatcher.http({
      url: '/partnership-application/reject/' + campaignId + '/' + partnershipId,
      method: 'GET',
      success: (invite) => {
        let currentInvites = store.getState().currentCampaign.invites;
        const inviteIndex = _.findIndex(currentInvites, { id: partnershipId });
        let updatedInvites = currentInvites.slice(0);
        updatedInvites[inviteIndex] = Object.assign({}, updatedInvites[inviteIndex], { status: 'rejected'})

        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentCampaign',
          data: {
            invites: updatedInvites
          }
        })
      }
    });
  },
  updatePartnershipRequest: (data) => {
    AppDispatcher.http({
      url: '/partnership/' + data.id,
      method: 'PUT',
      data: data,
      success: (invite) => {
        let currentInvites = store.getState().currentCampaign.invites;
        const inviteIndex = _.findIndex(currentInvites, { id: data.id });
        let updatedInvites = currentInvites.slice(0);
        updatedInvites[inviteIndex] = Object.assign({}, updatedInvites[inviteIndex], invite)

        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentCampaign',
          data: {
            invites: updatedInvites
          }
        })
        toastr.success('Saved', null, { timeOut: 2000, positionClass: 'toast-bottom-center' });
      }
    });
  },
  updateAttrPersist: (attr, value, callback) => {
    updateAttrPersist(attr, value, callback);
  },
  updateFont: (campaign) => {
    let bodies = ['headLine', 'details', 'disclaimer', 'preEntryMessage', 'additionalTermsDetail'];

    let fonts = [];
    _.each(bodies, function(field) {
      let matches = campaign[field].match(/font-family:\s([^;]*)/g);

      _.each(matches, function(match) {
        fonts.push(match.split('font-family:')[1].trim())
      })
    });

    fonts = _.uniq(fonts);

    let data = {
      customFonts: {
        values: fonts
      }
    };
    $.ajax({
      url: '/campaigns/' + campaign.id,
      method: 'PUT',
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
  updateAttr: (attr, value) => {
    updateAttr(attr, value);
  },
  deleteFinal: (id) => {
    store.dispatch({
      type: 'LOADING',
      model: 'currentCampaign'
    })
    AppDispatcher.http({
      url: '/campaigns/' + id,
      method: 'DELETE',
      success: () => {
        store.dispatch({
          type: 'REMOVE_CAMPAIGN',
          model: 'brandCampaigns',
          data: id
        })
        store.dispatch({
          model: 'pastCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'currentCampaigns',
          type: 'LOAD',
        });
        store.dispatch({
          model: 'upcomingCampaigns',
          type: 'LOAD',
        });
        toastr.success('Campaign has been deleted.', null, { timeOut: 10000, positionClass: 'toast-bottom-center' });
        window.location.href = '/app/sweepstakes/partnership';
      }
    });
  },
  startCampaign: (id, isSolo) => {
    const soloCopy = 'Congrats, your sweepstakes is launched! Promote your campaign using the promotional links.'
    const partnershipCopy = 'Campaign has started, notify partners that they may send out promotional links.'
    const toastrCopy = isSolo ? soloCopy : partnershipCopy
    if (id) {
      updateAttrPersistId('startDate', new Date(), id, function() {
        toastr.success(toastrCopy, null, { timeOut: 10000, positionClass: 'toast-bottom-center' });
      });
    } else {
      updateAttrPersist('startDate', new Date());
      toastr.success(toastrCopy, null, { timeOut: 10000, positionClass: 'toast-bottom-center' });
    }
  },
  endCampaign: () => {
    updateAttrPersist('endDate', new Date());
    toastr.success('Campaign Has Ended!', null, { timeOut: 10000, positionClass: 'toast-bottom-center' });
  },
  chooseWinner: (callback)=> {
    var id = store.getState().currentCampaign.id;
    $.ajax({
      url: '/campaign-choose-winner/' + id,
      method: 'POST',
      success: function(winner) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentCampaign',
          data: {
            winner: winner,
            winner_id: winner.id
          }
        })

        callback()
      },
      error: function(err) {
        toastr.error(err.responseText, null, { timeOut: 10000, positionClass: 'toast-bottom-center' });
        callback();
      }
    });
  },
  loadRequest: (id, callback) => {
    store.dispatch({
      type: 'LOADING',
      model: 'currentCampaign'
    })

    store.dispatch({
      type: 'CLEAR',
      model: 'campaignSetupForm'
    })

    $.get(`/campaigns/${id}`, function(campaign) {
      var $zopim = $zopim || undefined;
      if ($zopim) {
        $zopim(function() {
          $zopim.livechat.setNotes(`
            Brand ${store.getState().currentBrand.accountname} ${store.getState().currentBrand.id}
            Campaign ${campaign.name} ${campaign.id}
          `)
        })
      }

      var currentBrandId = store.getState().currentBrand.id;
      var currentBrandName = store.getState().currentBrand.accountname;
      var isHost = campaign.hostBrandId === currentBrandId;
      var displayState = {};
      campaign.previewLink =  '/landing/campaign/' + campaign.id + '?preview=true';
      if (process.env.SENTRY_ENV === 'production') {
        campaign.previewLink = `https://do-not-use-this-link.dojomojo.com${campaign.previewLink}`
      }
      campaign.downloadLink = `/campaign-export-results/${campaign.id}`
      campaign.isHost = campaign.hostBrandId === currentBrandId;
      campaign.startDate = new Date(campaign.startDate);
      campaign.endDate = new Date(campaign.endDate);
      campaign.loading = false;
      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'promotionalUnits',
        data: {
          promoUnits: campaign.promotionalUnits,
          isHost: isHost,
          currentBrandId: currentBrandId,
          invites: campaign.invites
        }
      });
      var termsDetails = [];
      let acceptedCampaignInvites = _.filter(campaign.invites, { status: 'accepted' });
      _.each(acceptedCampaignInvites, function(invite) {
        displayState[invite.invitee_id] = true;
        invite.isHost = invite.invitee_id === campaign.hostBrandId;
        campaign[`successPageProportion${invite.invitee_id}`] = invite.successPageProportion;
        termsDetails.push(_.extend({}, invite.inviteTermDetails, { accountname: invite.invitee.accountname, status: invite.status, invitee_id: invite.invitee_id }));
        if (campaign.brandconnections.firstDegreePartners.includes(invite.invitee.id)) {
          invite.degree = '1st';
        } else if (campaign.brandconnections.secondDegreePartners.includes(invite.invitee.id)) {
          invite.degree = '2nd';
        } else {
          invite.degree = '3rd';
        }
      });

      store.dispatch({
        type: 'LOAD_SUCCESS',
        data: termsDetails,
        model: 'campaignTermsDetails'
      });

      let currentInvite = _.find(campaign.invites, { invitee_id: currentBrandId });
      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'ctaModel',
        data: currentInvite
      });

      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'promotionalUnit',
        data: {
          acceptedCampaignInvites: acceptedCampaignInvites,
          isHost: isHost
        }
      })

      store.dispatch({
        type: 'LOAD_SUCCESS',
        model: 'currentCampaign',
        data: _.merge({}, campaign, {
          brand: currentBrandId,
          currentBrandId: currentBrandId,
          displayState: displayState
        })
      })

      var role = currentBrandId === campaign.hostBrandId ? 'host' : 'partner';

    });
  },
  selectVersionRequest: (version) => {
    var id = store.getState().currentCampaign.id;
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentCampaign',
      data: {
        loading: true
      }
    });
    $.ajax({
      url: '/campaign-update-lander-version/' + id + '/' + version,
      method: 'POST',
      success: function() {
        CampaignDispatcher.loadRequest(id);
        BrowserHistoryDispatcher.push(`/builder/${id}/edit-content`);
      }
    });
  },
  removePrizingLogo: (id) => {
    $.ajax({
      url: '/campaign-prizing-logo/' + id,
      method: 'DELETE',
      success: function() {
        CampaignDispatcher.updateAttr('prizingLogo', null);
      }
    });
  },
  updateTrafficProportion: () => {
    var currentCampaign = store.getState().currentCampaign;
    var partners = _.filter(currentCampaign.invites, {
      status: 'accepted'
    });

    var remainder = _.reduce(partners, function(memo, partner) {
      if (partner.invitee_id === currentCampaign.hostBrandId) {
        return memo;
      }
      return memo -= currentCampaign[`successPageProportion${partner.invitee_id}`];
    }, 1);

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentCampaign',
      data: {
        [`successPageProportion${currentCampaign.hostBrandId}`]: remainder
      }
    });

    currentCampaign = store.getState().currentCampaign;

    var proportion = {};
    _.each(currentCampaign.invites, function(invite) {
      proportion[invite.id] = currentCampaign[`successPageProportion${invite.invitee_id}`];
    })

    AppDispatcher.http({
      url: `/partnership-proportion/${currentCampaign.id}`,
      data: {
        proportion: proportion
      },
      method: 'POST'
    });
  },
  duplicateCampaign: (id) => {
    $.ajax({
      url: '/duplicate-campaign/' + id,
      method: 'POST',
      success: function(campaign) {
        CampaignDispatcher.loadCampaignsData();
        BrowserHistoryDispatcher.push(`/builder/${campaign.id}/setup`);
      }
    });
  },
  loadAssets: (id) => {
    id = store.getState().currentCampaign.id || id;
    AppDispatcher.http({
      url: `/campaigns/assets/${id}`,
      success: function(assets) {
        store.dispatch({
          type: 'UPDATE_ATTR',
          model: 'currentCampaign',
          data: {
            assets: assets
          }
        });
      }
    });
  },
  deleteAsset: (id) => {
    AppDispatcher.http({
      url: `/campaigns/assets/${id}`,
      method: 'DELETE',
      success: function(id) {
        store.dispatch({
          type: 'REMOVE_ASSET',
          model: 'currentCampaign',
          data: id
        });
      }
    });
  },
  notifyPartners: (id,callback) => {
    AppDispatcher.http({
      url: `/campaign-notify-partners/${id}`,
      success: function() {
        callback();
      }
    })
  },
  filterCampaigns: function(filters) {
    var brandCampaigns = store.getState().brandCampaigns;
    var currentBrand = store.getState().currentBrand;

    const filteredCampaigns = brandCampaigns.allCampaigns.filter(campaign => {
      if (campaign.name && !campaign.name.toLowerCase().includes(filters.search.toLowerCase())) {
        return false;
      }
      if(filters.hosting && campaign.hostBrandId != currentBrand.id){
        return false;
      }
      //add more filter rules here

      return true;
    });

    store.dispatch({
      model: 'brandCampaigns',
      data: filteredCampaigns,
      type: 'SET_VISIBLE_CAMPAIGNS'
    });

    store.dispatch({
      model: 'currentCampaigns',
      type: 'LOAD'
    });

    store.dispatch({
      model: 'upcomingCampaigns',
      type: 'LOAD'
    });

    store.dispatch({
      model: 'inDiscussionCampaigns',
      type: 'LOAD'
    });

    store.dispatch({
      model: 'pastCampaigns',
      type: 'LOAD'
    });

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'currentCampaigns',
      data: {
        sort: filters.sort
      }
    });

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'upcomingCampaigns',
      data: {
        sort: filters.sort
      }
    });

    store.dispatch({
      type: 'UPDATE_SORT',
      model: 'pastCampaigns',
      data: {
        sort: filters.sort
      }
    });

  },

  toggleCampaignDateChangeModal(shouldShow = false) {
    store.dispatch({
      type: 'SHOW_CAMPAIGN_DATE_CHANGE_MODAL',
      model: 'currentCampaign',
      data: {
        showCampaignDateChangeModal: shouldShow
      }
    })
  },
  showAnnouncementSentOnCampaingDateChangeModal(shouldShow = false) {
    store.dispatch({
      type: 'SHOW_ANNOUNCEMENT_SENT_CAMPAIGN_DATE_CHANGE_MODAL',
      model: 'currentCampaign',
      data: {
        showAnnouncementSentCampaignDateChangeModal: shouldShow
      }
    })
  },
  showCampaignCreatedLimitModal(shouldShow = false) {
    store.dispatch({
      type: 'SHOW_CAMPAIGN_CREATED_LIMIT_MODAL',
      model: 'currentCampaign',
      data: {
        showCampaignCreatedLimitModal: shouldShow,
      },
    })
  },
}

export default CampaignDispatcher;
