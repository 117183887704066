import moment from 'moment-timezone';

export const isValidDate = (value, model) => {
  if (!moment(value).isValid()) {
    throw 'Must be valid date';
  }
};


export const isAfterStartDate = (value, model) => {
  const endDate = moment(value);
  const startDate = moment(model.startDate);
  if(!startDate.isBefore(endDate)) {
    throw 'End date must be after start date';
  }
};