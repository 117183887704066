import React from 'react'
import * as ReactRedux from 'react-redux'
import _ from 'lodash'

import TabTitleAndText from 'campaigns/builder/components/tab-title-and-text'
import TermsInfoHost from 'campaigns/builder/customize/terms/terms-info-host'
import TermsInfoPartner from 'campaigns/builder/customize/terms/terms-info-partner'
import TermsInfoPrizing from 'campaigns/builder/customize/terms/terms-info-prizing'

import CampaignDispatcher from 'dispatchers/campaign-dispatcher'
import TermsDispatcher from 'dispatchers/terms-dispatcher'
import { P } from 'visual-components/util/texts'

class TermsInfo extends React.Component{
  componentDidMount() {
    TermsDispatcher.loadPartnerInfo(this.props.currentCampaign);
  }

  updateAttr = (attr, value) => {
    CampaignDispatcher.updateAttrPersist(attr, value, () => {});
  }

  render() {
    var campaign = this.props.currentCampaign;
    var campaignTerms = this.props.campaignTermsDetails.items;

    var paras = [
      <P multiline>
        Enter in the information for your brand (as the Host) and your partner brands below.
      </P>
    ];

    var host = {};
    var partners = _.filter(campaignTerms, function(partner) {
      if (partner.invitee_id == campaign.hostBrandId) {
        host = partner;
      }

      return partner.status == 'accepted' && partner.invitee_id != campaign.hostBrandId;
    });

    var partnersList = _.map(partners, (partner, idx) => {
      return(
        <TermsInfoPartner
          key={ idx }
          partner={ partner }
          campaignTermsDetails={ this.props.campaignTermsDetails }
        />
      )
    });

    return(
      <div>
        <TabTitleAndText
          headers={[]}
          paras={ paras }
        />
        <div className="col-sm-6">
          <div className="row five-hundred-to-four-hundred">
            <TermsInfoHost
              host={ host }
              campaignTermsDetails={ this.props.campaignTermsDetails }
            />
            <TermsInfoPrizing
              updateAttr={ this.updateAttr }
              campaign={ campaign }
              form={ this.props.campaignTermsDetailsForm }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="five-hundred-to-four-hundred">
            { partnersList }
          </div>
        </div>
      </div>
    )
  }
}

export default TermsInfo
