import React from 'react'
import { bool } from 'prop-types'
import IntegrationTheme from 'css/themes/integrations.css'

class IntegrationIcon extends React.Component {
  static propTypes = {
    success: bool.isRequired,
  }

  render() {
    const {
      success,
    } = this.props

    const status = success === true ? 'checkmark' : 'alert'

    return (
      <div className={IntegrationTheme.itemIcon}>
        <img src={`/images/integration-logos/${status}.svg`} />
      </div>
    )
  }
}

export default IntegrationIcon
