import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'

import colors from 'util/colours'
import SMSEditorProvider, { SMSEditorContext } from './SMSEditorContext'
import SMSUtilsBar from './SMSUtilsBar'
import SMSEditorFooter from './SMSEditorFooter'
import SMSEditorBody from './SMSEditorBody'

const styles = {
  wrapper: {
    width: 'auto',
    minHeight: 212,
    borderRadius: 2,
    backgroundColor: 'white',
    border: `1px solid ${colors.silver}`,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
  },
}

function SMSEditor(props) {
  const { classes: css, ...restProps } = props

  return (
    <SMSEditorProvider {...restProps}>
      <div className={css.wrapper}>
        <SMSUtilsBar />
        <SMSEditorContext.Consumer>
          {({ actions, ...values }) => <SMSEditorBody {...{ actions, ...values }} />}
        </SMSEditorContext.Consumer>
        <SMSEditorFooter />
      </div>
    </SMSEditorProvider>
  )
}

SMSEditor.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(injectCSS(styles)(SMSEditor))
