import React from 'react'
import OpportunityInfoCard from './opportunity-info-card'
import InterestValue from 'visual-components/brandProfile/interests/interest-value.js'
import { formatRange } from 'visual-components/brandProfile/opportunities/formatRange'
import d3 from 'd3'

class DedicatedEmailOpportunityInfo extends React.Component {
  render() {
    let { opportunity, brand, showPen, obscure } = this.props

    return (
      <OpportunityInfoCard tags={ brand.tags } showPen={ showPen }>
        <InterestValue
          title={ opportunity.action == 'BUY' ? 'Minimum List Size' : 'List Size' }
          value={ formatRange(opportunity.min_list_size, opportunity.max_list_size) }
          marginRight='104px'
          obscure={obscure}
        />
        <InterestValue
          title={ opportunity.action == 'BUY' ? 'Target CPM' : 'CPM' }
          value={ formatRange(opportunity.min_target_cpm, opportunity.max_target_cpm, 'money') }
          marginRight='167px'
          obscure={obscure}
        />
        <InterestValue
          title={ opportunity.action == 'BUY' ? 'Target CPC' : 'CPC' }
          value={ formatRange(opportunity.min_target_cpc, opportunity.max_target_cpc, 'money') }
          obscure={obscure}
        />
        <div>
          <InterestValue
            title={ opportunity.action == 'BUY' ? 'Target Open Rate' : 'Open Rate' }
            value={ formatRange(opportunity.min_target_open_rate, opportunity.max_target_open_rate, 'percent') }
            marginRight={opportunity.action == 'BUY' ? '108px' : '88px'}
            obscure={obscure}
          />
          <InterestValue
            title={ opportunity.action == 'BUY' ? 'Target Click-Through Rate' : 'Click-Through Rate' }
            value={ formatRange(opportunity.min_target_clickthrough_rate, opportunity.max_target_clickthrough_rate, 'percent') }
            obscure={obscure}
          />
        </div>
      </OpportunityInfoCard>
    )
  }
}

export default DedicatedEmailOpportunityInfo
