import keyMirror from 'keymirror';

export default keyMirror({
  CREATE_AGREEMENT_REQUEST: null,
  CREATE_AGREEMENT_SUCCESS: null,
  CREATE_AGREEMENT_FAILURE: null,

  LOAD_AGREEMENT_REQUEST: null,
  LOAD_AGREEMENT_SUCCESS: null,
  LOAD_AGREEMENT_FAILURE: null,

  UPDATE_AGREEMENT_REQUEST: null,
  UPDATE_AGREEMENT_SUCCESS: null,
  UPDATE_AGREEMENT_FAILURE: null,

  UPDATE_LEGAL_DETAILS_REQUEST: null,
  UPDATE_LEGAL_DETAILS_SUCCESS: null,
  UPDATE_LEGAL_DETAILS_FAILURE: null,

  SAVE_LEGAL_DETAILS_REQUEST: null,
  SAVE_LEGAL_DETAILS_SUCCESS: null,
  SAVE_LEGAL_DETAILS_FAILURE: null,

  GENERATE_AGREEMENT_DRAFT_REQUEST: null,
  GENERATE_AGREEMENT_DRAFT_SUCCESS: null,
  GENERATE_AGREEMENT_DRAFT_FAILURE: null,

  SHARE_AGREEMENT_REQUEST: null,
  SHARE_AGREEMENT_SUCCESS: null,
  SHARE_AGREEMENT_FAILURE: null,

  CONFIRM_PARTNER_REQUEST: null,
  CONFIRM_PARTNER_SUCCESS: null,
  CONFIRM_PARTNER_FAILURE: null,

  SIGN_AGREEMENT_REQUEST: null,
  SIGN_AGREEMENT_SUCCESS: null,
  SIGN_AGREEMENT_FAILURE: null,

  COUNTER_SIGN_AGREEMENT_REQUEST: null,
  COUNTER_SIGN_AGREEMENT_SUCCESS: null,
  COUNTER_SIGN_AGREEMENT_FAILURE: null,

  OPEN_SIGNING_MODAL: null,
  CLOSE_SIGNING_MODAL: null,

  UPDATE_AGREEMENT_TEMPLATE_MARKUP: null,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_REQUEST: null,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_SUCCESS: null,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_ERROR: null,

  DELETE_AGREEMENT_REQUEST: null,
  DELETE_AGREEMENT_SUCCESS: null,
  DELETE_AGREEMENT_FAILURE: null,
  SAVE_AGREEMENT_TEMPLATE_MARKUP_FAILURE: null,

  UPDATE_AGREEMENT_ATTR: null,

  CLEAR_AGREEMENT: null,
});
