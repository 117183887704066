import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

function ClickAwayListener(props) {
  const { children, onClickAway } = props
  const ref = useRef(null)

  useEffect(() => {
    function onClickEvent(e) {
      if (ref.current.contains(e.target)) return

      if (onClickAway) onClickAway()
    }

    document.addEventListener('mousedown', onClickEvent, false)

    return () => document.removeEventListener('mousedown', onClickEvent, false)
  }, [])

  return (
    <div ref={ref}>
      {children}
    </div>
  )
}

ClickAwayListener.propTypes = {
  onClickAway: PropTypes.func,
  children: PropTypes.node.isRequired,
}

ClickAwayListener.defaultProps = {
  onClickAway() {},
}

export default ClickAwayListener
