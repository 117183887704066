import React from 'react'
import PropTypes from 'prop-types'
import injectJSS from 'react-jss'

import COLOURS from 'util/colours'

import MobileMarketingPostEntry from './mobile-marketing-post-entry'

const styles = {
  // Note: React-Toolbox components are not rendering their styles.
  // This is a quick solution to that.
  override: {
    '& h6, & h3': {
      fontFamily: 'Larsseit-Medium',
      color: COLOURS.ink,
      margin: 0,
    },
    '& .form-header h6': {
      fontSize: 14,
    },
    '& .form-body': {
      padding: '0 20px',
      '& h3': {
        fontSize: 20,
      },
      '& p:not([class*=disclaimer])': {
        fontFamily: 'Larsseit-Light',
        fontSize: 14,
        '&[class*=multiline]': {
          lineHeight: '22px',
        },
      },
    },
  },
}

class MobileMarketingPostEntryForm extends React.Component {
  render() {
    const {
      cta, classes: css, brandName, preview,
    } = this.props

    return (
      <form className={css.override} id="mobile-marketing-post-entry">
        <MobileMarketingPostEntry
          cta={{
            image_url: cta.image_url,
            image_link: cta.image_link,
            form_header: cta.form_header,
            title: cta.title,
            button_text: cta.button_text,
            button_color: cta.button_color,
            button_text_color: cta.button_text_color,
            unlock_copy: cta.unlock_copy,
          }}
          brandName={brandName}
          preview={preview}
        />
      </form>
    )
  }
}

MobileMarketingPostEntryForm.propTypes = {
  cta: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  brandName: PropTypes.string.isRequired,
  preview: PropTypes.bool,
}

MobileMarketingPostEntryForm.defaultProps = {
  preview: false,
}

export default injectJSS(styles)(MobileMarketingPostEntryForm)
