import React from 'react';
import Button from './button';

class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var buttonStyles = this.props.baseStyles.concat(this.props.styles);

    return (
      <Button 
        {...this.props}
        styles={buttonStyles} />
    );
  }
}

DropdownButton.defaultProps = {
  baseStyles: [{
    backgroundColor: 'transparent',
    width: '31px',
    minWidth: '31px',

    marginTop: '0',
    marginBottom: '0',

    paddingRight: '0px',
    paddingLeft: '8px',
    paddingBottom: '0px',
    paddingTop: '0px',

    border: 'none',
    ':hover': {
      backgroundColor: 'transparent'
    },
    ':focus': {
      outline: 'none',
      MozBoxShadow: 'none',
      boxShadow: 'none'
    }
  }],
  styles: []
};

export default DropdownButton;
