import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'
import validator from 'validator'

import { P, Small } from 'visual-components/util/texts'
import COLOURS from 'util/colours'

import { generatePreview } from '../SMSPreview'
import SMSInfoBanner from '../SMSInfoBanner'
import EditCouponCode from './EditCouponCode'
import FollowupConditionPreview from './FollowupConditionPreview'

const styles = {
  contentPreview: {
    padding: '0 28px 28px !important',
    '& .sms-info-banner': {
      maxWidth: 536,
      marginTop: 8,
      marginBottom: 8,
    },
    '& > :first-child': {
      paddingLeft: 0,
    },
  },
  padding: {
    padding: '28px !important',
    margin: '0 !important',
  },
  msgWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    maxWidth: 536,
    padding: '16px 20px',
    border: `1px solid ${COLOURS.silver}`,
    borderRadius: '16px 16px 16px 0',
    marginTop: 4,
    '& + :first-of-type': {
      margin: '16px 0 4px',
    },
    '& > div': {
      alignSelf: 'stretch',
      '&:nth-child(2)': {
        marginLeft: 12,
      },
    },
  },
  previewText: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  coupon: {
    position: 'relative',
    marginTop: 16,
    '& > p:first-of-type': {
      marginBottom: 4,
      '& > p': {
        display: 'inline-block',
        marginLeft: 4,
        '& a': {
          marginLeft: 4,
        },
      },
    },
  },
  img: {
    maxWidth: 64,
    maxHeight: 64,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLOURS.silver}`,
    borderRadius: 2,
    '& img': {
      objectFit: 'cover',
      width: 64,
      height: 64,
    },
  },
}

const stateToProps = ({ currentBrand }) => ({ currentBrand })

const VALIDATOR_OPTS = { require_host: true, require_protocol: true, require_valid_protocol: true }

function PreviewContent(props) {
  const {
    classes: css,
    isPreview,
    isFollowup,
    message,
    currentBrand,
  } = props

  const [showModal, setShowModal] = React.useState(false)

  const {
    isEditing,
    coupon_code,
    image_url,
    custom_sms_message,
  } = message

  const containsCouponTag = /\*\|COUPON(_CODE)?\|\*/g.test(custom_sms_message)

  const wordsList = custom_sms_message.split(/\s|\n/)
  const possibleCoupon = wordsList.some(word => /^(?=.*[A-Z])(?=.*[0-9])?([A-Z0-9]+){3,}$/.test(word))
  // Might need to adjust this one...
  const linkDetected = wordsList.some(word => validator.isURL(word, VALIDATOR_OPTS))

  const onLinkClick = e => {
    e.preventDefault()
    setShowModal(true)
  }

  const couponBannerContent = possibleCoupon
    ? {
      pad: true,
      body: 'If you are using a unique coupon code for this message, be sure to add it for proper conversion and revenue tracking.',
      cta: <a onClick={onLinkClick}>Add it now</a>,
      type: 'warning',
    } : {
      title: 'Be sure to include your Coupon Code',
      body: 'Add a unique Coupon Code to track your conversions and revenue.',
      type: 'info',
      cta: <a onClick={onLinkClick}>Add Coupon</a>,
      pad: true,
    }

  const linkBannerContent = {
    pad: true,
    title: 'Heads Up',
    body: 'Your message includes a link not generated by our link shortening tool. We will not be able to track clicks for this message.',
    type: 'warning',
  }

  const onUpdateClick = e => {
    e.preventDefault()
    setShowModal(!showModal)
  }

  return (
    <div
      className={cn({
        [css.padding]: isEditing && !isPreview,
        [css.contentPreview]: isPreview,
      })}
    >
      { isFollowup && <FollowupConditionPreview message={message} /> }
      <P>
        <em>Message</em>
      </P>
      { linkDetected && isPreview && <SMSInfoBanner {...linkBannerContent} /> }
      <div className={css.msgWrapper}>
        {
          image_url && (
            <div>
              <div className={css.img}>
                <img src={image_url} alt="preview" />
              </div>
            </div>
          )
        }
        <div className={css.previewText}>
          <P multiline>
            { generatePreview({ message, currentBrand }) }
          </P>
        </div>
      </div>
      <div className={css.coupon}>
        <P>
          <em>Coupon Code</em>
          {
            containsCouponTag && coupon_code && (
              <Small>
                Need to update the coupon code?
                <a href="#" onClick={onUpdateClick}>
                  Update right here.
                </a>
              </Small>
            )
          }
        </P>
        <EditCouponCode
          message={message}
          show={showModal}
          onClose={() => setShowModal(false)}
          isFollowup={isFollowup}
        />
        {
          coupon_code
            ? <P>{coupon_code}</P>
            : containsCouponTag && (
              <P alertRed>
                <em>
                  This field is Required
                </em>
              </P>
            )
          }
      </div>
      { !coupon_code && (!isEditing || isPreview) && <SMSInfoBanner {...couponBannerContent} /> }
    </div>
  )
}

PreviewContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  currentBrand: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
  isFollowup: PropTypes.bool,
}

PreviewContent.defaultProps = {
  isPreview: false,
  isFollowup: false,
}

export default React.memo(connect(stateToProps)(injectCSS(styles)(PreviewContent)))
