import React from 'react'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import dialogTheme from 'css/themes/dialogs/dialogBase.css'
import { P } from 'visual-components/util/texts'

class PartnerConfirmationDialog extends React.Component {
  render() {
    const { type, currentCampaign: { name: campaignName }, closeDialog } = this.props
    const dialogTypes = ['reportDialog', 'announcementDialog']

    if (!type || !dialogTypes.includes(type)) {
      return null
    }

    const titleMap = {
      reportDialog: `We’ll Be in Touch`,
      announcementDialog: `We’ve Sent Your Message!`
    }

    const imgMap = {
      reportDialog: `/images/icons/customer-support.svg`,
      announcementDialog: `/images/icons/illustration-message-sent.svg`
    }

    const paraMap = {
      reportDialog: `Thanks for letting us know. We’ll be in touch shortly to help look into this.`,
      announcementDialog: <span>
        This announcement was sent to your Partners for <b>{campaignName}</b> via email. You’ll receive a copy of your announcement in your email.
      </span>
    }

    return (
      <div>
        <DefaultDialog active={ !!type }
          closeModal={ closeDialog }
          title={ titleMap[type] }
          confirm={ closeDialog }
          confirmText={ 'Close' }
          children={
            <div>
              <div className={ `${dialogTheme.bannerWrapper}` }>
                <img src={ imgMap[type] } className={ `${dialogTheme.noMargin}` } />
              </div>

              <P multiline>
                { paraMap[type] }
              </P>
            </div>
          }
        />
      </div>
    )
  }
}

export default PartnerConfirmationDialog
