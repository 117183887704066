import _ from 'lodash'

import moment from 'moment-timezone';
var model = 'recentAcquires';

var terms = ['month', 'week', 'day'];

var columns = 12;

var generateXAxis = function (i,freq) {
  return {
    month: function () {
      return moment().subtract(11-i, 'months').format('MMM');
    },
    week: function () {
      return moment().subtract(1, 'days').subtract(11-i, 'weeks').format('MMM DD');
    },
    day: function () {
      return moment().subtract(11-i+1, 'days').format('MMM DD');
    }
  }[freq]();
}

var defaultState =  {
  rid: model,
  term: 'month',
  termList: [{ 
    id: 'day',
    name: 'Daily'
  },{ 
    id: 'week',
    name: 'Weekly'
  },{ 
    id: 'month',
    name: 'Monthly'
  }],
  selectedData: {
    newEmails: [{
      name: '',
      data: []
    }],
    totalEmails: [{
      name: '',
      data: []
    }]
  }
}

var RecentAcquiresReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_VIEW: function () {
      var term = action.data.term || state.term;
      var selectedData = {
        newEmails: [{
          name: state.newEmails.name,
          data: state.newEmails[term]
        }],
        totalEmails: [{
          name: state.totalEmails.name,
          data: state.totalEmails[term]
        }]
      }

      return _.extend({}, state, { selectedData: selectedData, term: term });
    },
    LOAD: function () {

      var totalEmails = {
        name: 'Total Emails Acquired'
      };
      var newEmails = {
        name: 'New Emails Acquired'
      };

      _.each(terms, function (term) {
        totalEmails[term] = [];
        newEmails[term] = [];
        var sum = 0;
        _.times(columns, function (i) {
          var val = action.data.recentAcquires[`${term}_${11 - i}`] || 0;
          if (action.data.isEmpty) {
            val = Math.random() * 10;
          }
          sum += parseInt(val);
          var x = generateXAxis(i, term);
          newEmails[term].push({
            x: x,
            y: parseInt(val)
          });
          totalEmails[term].push({
            x: x,
            y: sum
          });
        })
      });

      var selectedData = {
        newEmails: [{
          name: newEmails.name,
          data: newEmails[state.term]
        }],
        totalEmails: [{
          name: totalEmails.name,
          data: totalEmails[state.term]
        }]
      }

      return _.extend({}, state, { newEmails: newEmails, totalEmails: totalEmails, selectedData: selectedData, isEmpty: action.data.isEmpty });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default RecentAcquiresReducer;
