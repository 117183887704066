import React, { Component } from 'react';
import { string, func } from 'prop-types';
import theme from 'css/themes/homePage/bluetabs.css';

class BlueBox extends Component {
  static propTypes = {
    name: string.isRequired,
    onclick: func.isRequired,
    title: string.isRequired,
    explain: string.isRequired,
    selected: string.isRequired
  }

  render() {
    let {
      name,
      onclick,
      title,
      explain,
      selected
    } = this.props;

    let amsSelected = name === selected;

    return (
      <div className={ `${theme.box} ${amsSelected ? theme.selected : null}` }
        onClick={ e => onclick(name) }
      >
        <p className={ theme.title }>
          { title }
        </p>
        <p className={ theme.explain }>
          { explain }
        </p>
      </div>
    )
  }
}

export default BlueBox;
