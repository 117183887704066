import React, { Component } from 'react';
import Dialog from 'react-toolbox/lib/dialog';
import { connect } from 'react-redux';
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import ModalBaseTheme from 'css/themes/dialogs/modalBase.css';
import { bindActionCreators } from "redux";
import * as CampaignTaskActions from 'actions/campaign-task-actions';
import CreateTaskTheme from 'css/themes/dialogs/createTask.css';
import { H4 } from 'visual-components/util/texts';

const mapStateToProps = ({ campaignTasks }) => ({ campaignTasks });

const mapDispatchToProps = (dispatch) => {
  return {
    campaignTaskActions: bindActionCreators(CampaignTaskActions, dispatch)
  }
};

class DeleteTaskConfirmationDialog extends Component {

  toggleDeleteCancel = () => {
    this.props.campaignTaskActions.cancelDelete()
  };

  deleteTask = () => {
    const {
      campaignTasks: {
        currentTask: {
          id
        }
      },
      campaignTaskActions: {
        confirmDelete
      }
    } = this.props

    confirmDelete(id)
  };

  render() {
    const {
      confirmDelete,
    } = this.props.campaignTasks.currentTask

    return(
      <Dialog
        active={ confirmDelete }
        title={ "Confirm Event Deletion" }
        onEscKeyDown={ this.toggleDeleteCancel }
        onOverlayClick={ this.toggleDeleteCancel }
        theme={ ModalBaseTheme }
      >
        <div className={ CreateTaskTheme.subTitle }>
          <H4 multiline>Are you sure you want to delete this event? Once deleted, it cannot be recovered.
            If applicable, any assignees will be notified of this deletion
          </H4>
        </div>

        <ModalButtonGroup
          cancel={ this.toggleDeleteCancel }
          cancelText="Cancel"
          confirm={ this.deleteTask }
          confirmText={ "Delete Task" }
          canSave
          hideLine
        />

      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTaskConfirmationDialog);
