import React, { Component } from 'react'
import Switch from 'react-toolbox/lib/switch'
import { Small } from 'visual-components/util/texts'
import Theme from 'css/themes/media-market/collaborate-comment-theme.css'
import SwitchTheme from 'css/themes/Switch.css'
import { func, bool } from 'prop-types'

class HideAnnotations extends Component {
  static propTypes = {
    toggleShowAnnotations: func.isRequired,
    showAnnotations: bool.isRequired,
  }

  render() {
    const {
      toggleShowAnnotations,
      showAnnotations,
    } = this.props

    return (
      <div className={Theme.toggleContainer}>
        <div className={Theme.hideAnnotationsToggle}>
          <div className={Theme.label}>
            <Small>Hide Annotations</Small>
          </div>
          <div className={Theme.switch}>
            <Switch
              checked={showAnnotations}
              onChange={toggleShowAnnotations}
              theme={SwitchTheme}
            />
          </div>
        </div>

        <div className={Theme.line} />
      </div>
    )
  }
}

export default HideAnnotations
