import React, { PureComponent as Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import GeoDistributionCard from 'visual-components/analytics/geo-distribution-card'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import _ from 'lodash'

const mapState = ({ analyticsDashboard }) => ({
  data: analyticsDashboard.dashboardTopRegions,
  datePicker: analyticsDashboard.datePickerTopRegions,
  oldestDate: analyticsDashboard.oldestDate,
})

class GeoDistribution extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    datePicker: PropTypes.object.isRequired,
    oldestDate: PropTypes.instanceOf(Date).isRequired,
  }

  changeFilter = (startDate, endDate) => {
    const data = {
      startDate,
      endDate,
      metric: 'dashboardTopRegions',
    }

    AnalyticsDispatcher.fetchReport(data, 'analyticsDashboard')
  }

  render() {
    const {
      data,
      datePicker,
      oldestDate,
    } = this.props

    const fixedData = _.clone(data)

    // add fake placeholders if needed
    _.times(3 - fixedData.length, idx => fixedData.unshift({ perc: 0, state: `fake00000${idx}` }))

    return (
      <GeoDistributionCard
        els={fixedData}
        changeFilter={this.changeFilter}
        datePicker={datePicker}
        oldestDate={oldestDate}
        alignLeft
      />
    )
  }

}


export default withRouter(connect(mapState)(GeoDistribution))
