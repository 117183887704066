import React, { PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Spinner from 'util/components/spinner'

import BrandDetailDispatcher from 'dispatchers/brand-detail-dispatcher'
import * as DealRequestActions from 'actions/deal-request-actions'
import SellerProfileActions from 'actions/seller-profile-actions'

import BrandProfileContainerBase from './brand-profile-container-base'
import BrandProfileTopContent from './BrandProfileTopContent'

const stateToProps = ({ brand }) => ({ brand })

class BrandProfileContainer extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      isPublic,
      match: { params: { brandId } },
    } = this.props

    if (!isPublic) {
      BrandDetailDispatcher.loadBrand(brandId)
      BrandDetailDispatcher.loadPartnershipInterests(brandId)
      dispatch(DealRequestActions.loadActiveByBrandId(brandId))
      dispatch(SellerProfileActions.clear())
      dispatch(SellerProfileActions.loadSellerProfile(brandId))
    }
  }

  render() {
    const {
      match: {
        params: {
          brandId,
        },
      },
      isPublic,
      brand,
    } = this.props

    if (!brandId || (!brand.error && (!brand.loaded || !brand.id || brand.id !== parseInt(brandId, 10)))) {
      return <Spinner />
    }

    if (brand.error) {
      return <Redirect to={isPublic ? '/home' : '/explore/brands'} />
    }

    return (
      <React.Fragment>
        <BrandProfileTopContent brandId={brandId} isPublic={isPublic} />
        <BrandProfileContainerBase brandId={brandId} isPublic={isPublic} />
      </React.Fragment>
    )
  }
}

BrandProfileContainer.propTypes = {
  isPublic: PropTypes.bool,
  match: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

BrandProfileContainer.defaultProps = {
  isPublic: false,
}

export default connect(stateToProps)(BrandProfileContainer)
