import React from 'react';
import Dialog from 'react-toolbox/lib/dialog';

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup';
import DialogTheme from 'css/themes/dialogs/formModal.css'

const themes = {
  Dialog: DialogTheme,
}

class SingleImageConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    store.dispatch({
      type: 'CLEAR',
      model: 'confirmationModal'
    })
  }

  render() {
    const {
      children,
      title,
      imgSrc,
      active
    } = this.props;

    const [imgName, extension] = imgSrc.split('.')

    const srcSet =  `
      ${imgName}.${extension},
      ${imgName}@2x.${extension} 2x,
      ${imgName}@3x.${extension} 3x
    `
    return (
      <Dialog
        theme={ themes.Dialog }
        active={ !!active }
        onEscKeyDown={ this.closeModal }
        onOverlayClick={ this.closeModal }
        title={ title }
      >
        <div style={{textAlign: 'center', marginTop: '25px', marginBottom: '25px'}}>
          <img src={ imgSrc }
            srcSet={ srcSet } />
        </div>
        {children}
        <ModalButtonGroup
          confirm={ this.closeModal }
          canSave={true}
          confirmText={ `Let's Go` }
          hideLine={ true }
          bottomPad={ true }
        />
      </Dialog>
    )
  }
};

export default SingleImageConfirmationModal;
