import React from 'react'
import COLOURS from 'util/colours'
import BlueInfoTooltip from 'visual-components/util/tooltip/BlueInfoTooltip'

import { Button } from 'react-toolbox/lib/button'

import ButtonTheme from 'css/themes/Buttons.css'
import SwitchTheme from 'css/themes/Switch.css'

const themes = {
  Button: ButtonTheme,
  Switch: SwitchTheme,
}

class UserItem extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      member,
      profile,
      currentBrand,
      revokeAccess,
      showDivider,
      owner,
    } = this.props

    const isCreator = currentBrand.ownerId === member.userId
    const userIsCreator = currentBrand.ownerId === profile.id
    const canRevoke = !isCreator && member.user.id !== profile.id

    return (
      <tr
        style={{
          height: '54px',
          verticalAlign: 'middle',
          borderBottom: showDivider ? '1px solid #dee4e5' : null,
        }}
      >
        <td
          style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '14px',
            color: '#212731',
            width: '256px',
            paddingLeft: '16px',
          }}
        >
          {member.user.fullName
            || `${member.user.firstName || ''} ${member.user.lastName || ''}`}
          {owner && (
            <div
              style={{
                display: 'inline-block', verticalAlign: 'top', marginLeft: '6px',
              }}
            >
              <BlueInfoTooltip text="The Account Owner cannot be deleted. Please reach out to the support team to delete the owner." />
            </div>
          )}
        </td>
        <td
          style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '14px',
            color: '#212731',
            width: '320px',
          }}
        >
          {member.user.email}
        </td>
        <td
          style={{
            fontFamily: 'Larsseit-Light',
            fontSize: '14px',
            lineHeight: '14px',
            color: '#212731',
            width: '80px',
          }}
        >
          {member.role == 'owner' ? 'Admin' : 'User'}
        </td>
        <td
          style={{
            width: '183px',
            textAlign: 'center',
          }}
        >
          {canRevoke ? (
            <Button
              onClick={e => revokeAccess(member.uid)}
              label="Revoke Access"
              theme={themes.Button}
              flat
              style={{
                fontFamily: 'Larsseit-Medium',
                fontSize: '14px',
                height: '36px',
                color: COLOURS.azure,
              }}
            />
          ) : null}
        </td>
      </tr>
    )
  }
}

export default UserItem
