import React from 'react'

import { func, object } from 'prop-types'
import CalendarTheme from 'css/themes/calendar/calendar.css'

class ShortEvent extends React.Component {
  static contextTypes = {
    onTaskSelect: func.isRequired,
    dragTask: func,
    dragTaskItem: object,
  }

  startDragging = () => {
    const {
      dragTask,
    } = this.context

    const {
      task,
    } = this.props

    if (task.canEdit) {
      dragTask(task)
    }
  }

  select = e => {
    const { onTaskSelect } = this.context
    const { task } = this.props

    let cardHeight = 0
    let cardWidth = 0

    if (e.clientY > window.innerHeight - 300) {
      cardHeight = window.innerHeight - 300
    } else {
      cardHeight = e.clientY - 75
    }

    if (e.clientX > window.innerWidth - 450) {
      cardWidth = window.innerWidth - 450
    } else {
      cardWidth = e.clientX - 75
    }

    onTaskSelect(task, {
      left: cardWidth,
      top: cardHeight,
    })
  }

  render() {
    const { task } = this.props

    return (
      <div
        draggable={task.canEdit}
        onClick={this.select}
        onDragStart={this.startDragging}
        className={CalendarTheme.shortEvent}
        style={{ backgroundColor: task.color }}
      >
        { task.title }
      </div>
    )
  }
}

export default ShortEvent
