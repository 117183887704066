import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import COLOURS from 'util/colours';
import {Editor, EditorState, RichUtils, ContentState} from 'draft-js';
import StyleButton from 'visual-components/richEditor/style-button';
import Button from 'react-toolbox/lib/button';
import PropTypes from 'prop-types';
import ButtonTheme from 'css/themes/Buttons.css'

const themes = {
  Button: ButtonTheme,
}

class RichEditor extends React.Component {
  constructor(props) {
    super(props);
    let initialEditorState;

    if(this.props.initialContent) {
      initialEditorState = EditorState.createWithContent(this.props.initialContent)
    } else {
      initialEditorState = EditorState.createEmpty()
    }
    this.state = { editorState: initialEditorState };

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {
      if(this.props.onChange) {
        this.props.onChange(editorState)
      }
      this.setState({editorState});
    }

    this.handleKeyCommand = (command) => this._handleKeyCommand(command);
    this.onTab = (e) => this._onTab(e);
    this.toggleBlockType = (type) => this._toggleBlockType(type);
    this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
    this.onButtonClick = () => this._onButtonClick();
  }

  componentDidMount() {
    var onFocus = this.refs.editor._onFocus;
    this.setState({
      editorFocus: true
    })
    var that = this;
    this.refs.editor._onFocus = function () {
      onFocus();
      that.setState({
        editorFocus: true
      });
      clearTimeout(that.delayHide);
    }

    var onBlur = this.refs.editor._onBlur;
    var that = this;
    this.refs.editor._onBlur = function () {
      onBlur();

      that.delayHide = setTimeout(function() {
        that.setState({
          editorFocus: false
        });
      }, 200);
    }

    this.timeout = setTimeout(() => {
      this.refs.editor.focus()
      this.setState({
        editorState: EditorState.moveFocusToEnd(this.state.editorState),
      })
    }, 200)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    clearTimeout(this.delayHide)
  }

  _handleKeyCommand(command) {
    const {editorState} = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  _onButtonClick() {
    const { editorState } = this.state;
    let contentState = editorState.getCurrentContent();
    this.props.buttonClick(contentState);

    const newEditorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState: newEditorState })

  }

  render() {
    const { editorState, editorFocus } = this.state;

    const { buttonText, styleOptions, placeholder } = this.props;

    // Split passed options between the inline and block styles
    let inlineStyleOptions = styleOptions ? styleOptions.filter(function(value) {
      return ['Bold', 'Italic', 'Underline', 'Monospace'].indexOf(value) != -1;
    }) : [];

    let blockStyleOptions = styleOptions ? styleOptions.filter(function(value) {
      return ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'UL', 'OL', 'Code Block'].indexOf(value) != -1;
    }) : [];

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    var showControl = contentState.hasText() || editorFocus;

    return (
      <Row className="RichEditor-root">
        <Col xs={12} onClick={this.focus} style={{
          border: `1px solid ${ COLOURS.lightGrey }`,
          padding: '12px',
          fontSize: '14px',
          minHeight: '120px',
          maxHeight: '320px',
          overflowY: 'scroll',
          cursor: 'text',
          fontFamily: 'Larsseit'
        }}>
          <Editor
            blockStyleFn={ getBlockStyle }
            editorState={ editorState }
            handleKeyCommand={ this.handleKeyCommand }
            onChange={ this.onChange }
            onTab={ this.onTab }
            placeholder={ placeholder || "Click to write your message here" }
            ref="editor"
            spellCheck={ true }
          />
        </Col>
        { buttonText ?
            <Col xs={ 3 } style={{
              paddingLeft: '0px',
              marginTop: '12px',
              visibility: showControl ? 'visible' : 'hidden'
            }}>
              <Button
                label={ buttonText }
                theme={ themes.Button }
                className={ `${themes.Button.blueButton} ${themes.Button.noMargin} ${themes.Button.messageButton}` }
                onClick={ this.onButtonClick }
                primary
                raised
              />
            </Col>
          :
            null
        }

        { styleOptions ?
          <Col xs={ buttonText ? 9 : 12 } style={{
            textAlign: 'right',
            marginTop: '22px',
            paddingLeft: '0px',
            paddingRight: '0px',
            visibility: showControl ? 'visible' : 'hidden'
          }}>
            <InlineStyleControls
              editorState={ editorState }
              onToggle={ this.toggleInlineStyle }
              activeOptions={ inlineStyleOptions }
            />
            <BlockStyleControls
              editorState={ editorState }
              onToggle={ this.toggleBlockType }
              activeOptions={ blockStyleOptions }
            />
          </Col>
          :
          null
        }
      </Row>
    );
  }
}

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

// All possible block types
const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one', icon_unselected: null, icon_selected: null },
  { label: 'H2', style: 'header-two', icon_unselected: null, icon_selected: null },
  { label: 'H3', style: 'header-three', icon_unselected: null, icon_selected: null },
  { label: 'H4', style: 'header-four', icon_unselected: null, icon_selected: null },
  { label: 'H5', style: 'header-five', icon_unselected: null, icon_selected: null },
  { label: 'H6', style: 'header-six', icon_unselected: null, icon_selected: null },
  { label: 'Blockquote', style: 'blockquote', icon_unselected: null, icon_selected: null },
  { label: 'UL', style: 'unordered-list-item', icon_unselected: 'bullet-list-icon-unselected.svg', icon_selected: 'bullet-list-icon-selected.svg'},
  { label: 'OL', style: 'ordered-list-item', icon_unselected: null, icon_selected: null },
  { label: 'Code Block', style: 'code-block', icon_unselected: null, icon_selected: null },
];

// All possible inline style types
var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon_unselected: 'bold-icon-unselected.svg', icon_selected: 'bold-icon-selected.svg' },
  { label: 'Italic', style: 'ITALIC', icon_unselected: 'italic-icon-unselected.svg', icon_selected: 'italic-icon-selected.svg' },
  { label: 'Underline', style: 'UNDERLINE', icon_unselected: null, icon_selected: null },
  { label: 'Monospace', style: 'CODE', icon_unselected: null, icon_selected: null },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
    <div className="RichEditor-controls" style={{
      display: 'inline-block'
    }}>
      {BLOCK_TYPES.filter(type => props.activeOptions.indexOf(type.label) != -1).map((type) =>
        <StyleButton
          key={ type.label }
          active={ type.style === blockType }
          label={ type.label }
          onToggle={ props.onToggle }
          style={ type.style }
          selectedIcon={ type.icon_selected }
          unselectedIcon={ type.icon_unselected }
        />
      )}
    </div>
  );
};

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls" style={{
      display: 'inline-block'
    }}>
      {INLINE_STYLES.filter(type => props.activeOptions.indexOf(type.label) != -1).map(type =>
        <StyleButton
          key={ type.label }
          active={ currentStyle.has(type.style) }
          label={ type.label }
          onToggle={ props.onToggle }
          style={ type.style }
          selectedIcon={ type.icon_selected }
          unselectedIcon={ type.icon_unselected }
        />
      )}
    </div>
  );
};

RichEditor.propTypes = {
  styleOptions: PropTypes.array,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func
}

export default RichEditor;
