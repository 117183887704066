import React from 'react';
import BackButton from 'util/components/buttons/back-button';

class RegistrationHeader extends React.Component{
  render() {
    let img = `/images/progress-bars/Progress-Indicator-Step-${ this.props.step }.svg`;

    return(
      <div style={{
        paddingTop: '20px'
      }}>
        <div>
          {/*<div style={{
            float: 'left'
          }}>
            {/* this.props.step !== 3 ? <BackButton /> : <div style={{
              display: 'inline-block',
              width: '64px'
            }}></div>

            {/* hitting back rechecks for brands with those attrs and breaks the flow
            <div style={{
              display: 'inline-block',
              width: '64px'
            }} />
          </div>
          */}
          <div style={{
            height: '45px',
            textAlign: 'center'
          }}>
            <img src="/images/logo.png"
              style={{
                height: '100%'
            }}/>
          </div>
        </div>
        <div className="hidden-xs"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '20px',
            textAlign: 'center'
        }}>
          <img src={ img } />
        </div>
      </div>
    )
  }
};

export default RegistrationHeader;
