import React from 'react'
import { withRouter } from 'react-router-dom'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { P } from 'visual-components/util/texts'

class AssetItem extends React.Component {
  render() {
    const { asset, brandName, downloadAsset, deleteAsset, isUploader, history } = this.props


    return (
      <Row style={{
        padding: '16px 20px',
        borderTop: '1px solid #d8d8d8'
      }}>
        <Col style={{
          width: '302px',
          marginTop: '5px'
        }}>
          <P>
            <i>{ brandName }</i>
          </P>
        </Col>
        <Col style={{
          width: '415px',
          marginTop: '5px'
        }}>
          <P>
            { `${asset.name && asset.name.length > 54 ?
                asset.name.substring(0, 54) + '...'
                : asset.name}${asset.type}`
            }
          </P>
        </Col>
        <Col style={{
          width: '120px',
          marginTop: '5px'
        }}>
          <P>
            { asset.size }
          </P>
        </Col>
        <Col style={{
          width: '149px',
          overflow: 'hidden',
          marginTop: '5px'
        }}>
          <P>
            { asset.type.replace('.', '').toUpperCase() }
          </P>
        </Col>
        <Col style={{
          width: '127px'
        }}>
          <div style={{
            float: 'right'
          }}>
            {
              isUploader ?
              <img src={ '/images/icons/blue-trash.svg' }
                onClick={ e => deleteAsset(asset.id) }
                style={{
                  marginRight: '12px',
                  cursor: 'pointer'
              }}/>
              : null
            }
            <img src={ '/images/icons/blue-download.svg' }
              onClick={ e => downloadAsset(asset.url) }
              style={{
                cursor: 'pointer'
            }}/>
          </div>
        </Col>
      </Row>
    )
  }
}

export default withRouter(AssetItem)
