import React from 'react'
import injectSheet from 'react-jss'
import propTypes from 'prop-types'

const styles = {
  backgroundOverlay: {
    position: props => (props.isFixed && props.desktopVersion ? 'fixed' : 'absolute'),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
}

const hexToRGBA = (color, percent = 1) => {
  const colors = color.substring(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16))
  return `rgba(${colors.join(', ')}, ${percent})`
}

class HeroImage extends React.Component {
  static propTypes = {
    classes: propTypes.object.isRequired,
    desktopVersion: propTypes.bool.isRequired,
    isFixed: propTypes.bool,
  }

  static defaultProps = {
    isFixed: false,
  }

  static contextTypes = {
    userStyles: propTypes.object,
    desktopVersion: propTypes.bool.isRequired,
  }

  render() {
    const {
      classes: css,
      desktopVersion,
    } = this.props

    const {
      userStyles: {
        overlay,
      },
    } = this.context

    const style = {}

    switch (overlay.type) {
      case 'single':
        style.background = hexToRGBA(overlay.color1, overlay.opacity1)
        break
      case 'gradient':
        style.backgroundImage = `linear-gradient(to right, ${hexToRGBA(overlay.color1, overlay.opacity1)}, ${hexToRGBA(overlay.color2, overlay.opacity2)})`
        break
      default:
        return null
    }

    return (
      <div
        id="overlay"
        className={css.backgroundOverlay}
        style={style}
      />
    )
  }
}

export default injectSheet(styles)(HeroImage)
