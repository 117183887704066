import React, { PureComponent as Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  number,
  object,
  bool,
  func,
} from 'prop-types'
import ReactDOM from 'react-dom'
import Clipboard from 'clipboard'
import Col from 'visual-components/util/no-padding-grid/col'
import Field from 'visual-components/util/inputs/field'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

import theme from 'css/themes/mediaMarketDealTrackingTheme.css'

class TrackingItem extends Component {
  static propTypes = {
    idx: number.isRequired,
    link: object.isRequired,
    isSeller: bool.isRequired,
    toggleLinkModal: func.isRequired,
    toggleLinkPreview: func.isRequired,
  }

  componentDidMount() {
    const { link: { uid } } = this.props
    const node = $(ReactDOM.findDOMNode(this)).find(`.copy-${uid}`)[0]

    if (node) {
      new Clipboard(node)
    }
  }

  copyLink = () => {
    toastr.success('Link copied.', null, { timeOut: 1000, positionClass: 'toast-bottom-center' })

    this.props.toggleLinkPreview(null, true)
  }

  render() {
    const {
      idx,
      link,
      isSeller,
      toggleLinkModal,
      toggleLinkPreview,
    } = this.props

    const trackingLink = isSeller ? link.link : link.target_url
    const data = {
      link: trackingLink,
    }

    return (
      <Col style={{ width: '542px', marginRight: idx % 2 === 0 ? '48px' : '0px', marginTop: '8px' }}>
        <div style={{ width: '437px', marginRight: '8px', display: 'inline-block' }}>
          <Field
            label={link.label}
            attrName="link"
            dataModel={data}
            formModel={{}}
            updateAttr={() => {}}
            readonly
            labelStyle={{ marginBottom: '4px' }}
            className={theme.inputFieldPadding}
          />
        </div>
        <div style={{ width: '67px', marginRight: '12px', display: 'inline-block' }}>
          <Button
            onClick={this.copyLink}
            label="Copy"
            className={`${ButtonTheme.blueButton} copy-${link.uid}`}
            theme={ButtonTheme}
            data-clipboard-text={trackingLink}
            primary
            raised
          />
        </div>
        <div style={{ width: '16px', display: 'inline-block' }}>
          {
            !isSeller
              ? <img src="/images/icons/blue-edit.svg" onClick={() => toggleLinkModal(link)} style={{ cursor: 'pointer' }} />
              : <img src="/images/icons/blue-view.svg" onClick={() => toggleLinkPreview(link)} style={{ cursor: 'pointer' }} />
          }
        </div>
      </Col>
    )
  }
}

export default withRouter(TrackingItem)
