import React from 'react';
import AdminRow from './admin-row';
import _ from 'lodash';

class OkrMetrics extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const headerStyle = {
      borderTop: '1px solid',
      borderBottom: '3px solid'
    };

    const { presentation: data } = this.props.admin;
    const {
      totalActiveBrands60Days,
      partnershipsPerBrand,
      avgApplicationsPerCampaign,
      newCustomerSuccessJiraBugsReported,
      totalBrandInterestedIn,
      totalOpportunitiesCreated,
      totalOpportunitiesResponded,
      dailyActiveUsers,
      daysToFirstPartnership_1,
      daysToFirstPartnership_2,
      days_to_first_partnership_rolling60,
      rolling30NewConversations,
      rolling30RespondedConversations,
      messagesPerConvo,
      opportunityResponseTime,
      rolling30NewBrandsWithCampaigns,
      opportunities_v2_created_content_swap_opportunities,
      opportunities_v2_created_custom_opportunities,
      opportunities_v2_created_dedicated_email_buy_opportunities,
      opportunities_v2_created_dedicated_email_sell_opportunities,
      opportunities_v2_created_prizing_partner_opportunities,
      opportunities_v2_created_product_shipment_insert_opportunities
    } = data;
    const dummyrow = ['-','-','-','-','-','-','-','-','-','-','-','-']

    let convertData = (arr, format = ',' , overrideField) => {
      return _.map(arr, function (item) {
        return d3.format(format)(overrideField ? item[overrideField] : item.c)
      });
    }

    const avgCampaignsInFirst60Days = _.zipWith(data.totalCampaignsInFirst60Days, data.newBrands, (totalCampaignsThisPeriod, totalNewBrandsThisPeriod) => {
      let newBrandsThisPeriod = totalNewBrandsThisPeriod ? totalNewBrandsThisPeriod.c : 0;
      let campaignsThisPeriod = totalCampaignsThisPeriod ? totalCampaignsThisPeriod.count_sweeps : 0;

      return (campaignsThisPeriod / newBrandsThisPeriod).toFixed(2)
    })

    const initialMessages = data.countInitialMessages;
    const totalMessages = data.countTotalMessages;
    const conversationsResponded = data.countConversationsResponded;
    const messageResponseRate = _.zipWith(rolling30NewConversations, rolling30RespondedConversations, (initialMessage, conversationResponded) => {
      const convResponded = conversationResponded ? conversationResponded.c : 0;
      const initMessage = initialMessage ? initialMessage.c : 0;
      return `${(100 * parseInt(convResponded) / parseInt(initMessage)).toFixed(2)}%`
    });


    const rolling30NewBrandsWithCampaignsRate = rolling30NewBrandsWithCampaigns.map(unit=> {
      return `${(100*Number(unit.brands_created_with_campaign) / Number(unit.brands_created)).toFixed(2)}%`
    })

    let rolling30MAU = data.rolling30MAU.slice(1);

    const DAU_MAU = _.zipWith(rolling30MAU, dailyActiveUsers, (monthlyActiveUsers, dailyActiveUsers) => {
      let result = (Number(dailyActiveUsers.dau) / Number(monthlyActiveUsers.mau) * 100).toFixed(2)
      return isNaN(result) ? `0%` : `${result}%`;
    })

    return (
      <div className="row" style={{marginBottom: '100px'}}>
        <div className="col col-sm-12 col-md-12 col-lg-12">
          <table className="table table-striped table-hover table-responsive">
            <AdminRow title={this.props.admin.interval + ' ending'} className="info" vals={this.props.dates} style={headerStyle}/>
            <tbody>
              <tr className="info"><td colSpan="13"><h4>OKR</h4></td></tr>
                <AdminRow title="Partnerships Per Brand" vals={convertData(partnershipsPerBrand,',.2f','partnerships_per_brand')} key="partnershipsPerBrand"/>
                <AdminRow title="Total Active Brands - 60 Days" vals={convertData(totalActiveBrands60Days)} key="totalActiveBrands60Days"/>
                <AdminRow title="Average Campaigns Within First 60 Days" vals={avgCampaignsInFirst60Days} key="avgCampaignsInFirst60Days"/>
                <AdminRow title="Average Applications Per Campaign" vals={convertData(avgApplicationsPerCampaign,',.2f')} key="avgApplicationsPerCampaign"/>
                <AdminRow title="New Jira Bugs Reported" vals={convertData(newCustomerSuccessJiraBugsReported)} key="newJiraBugsReported" />
              <tr className="info"><td colSpan="13"><h4>Q4 OKR</h4></td></tr>
                <AdminRow title="Total Brands Interested In excl Sweeps (agg)" vals={convertData(totalBrandInterestedIn)} key="totalBrandInterestedIn"/>
                <AdminRow title="Total Opportunities Created (agg)" vals={convertData(totalOpportunitiesCreated)} key="totalOpportunitiesCreated"/>
                <AdminRow title="Total Opportunities Responded (agg)" vals={convertData(totalOpportunitiesResponded)} key="totalOpportunitiesResponded"/>
                <AdminRow title="Opportunity Response Time days (agg)" vals={convertData(opportunityResponseTime, ',.2f', 'c')} key="opportunityResponseTime"/>
                <AdminRow title="Opp V2 Created - Content Swap (agg)" vals={convertData(opportunities_v2_created_content_swap_opportunities)} key="opportunities_v2_created_content_swap_opportunities"/>
                <AdminRow title="Opp V2 Created - Product Insert (agg)" vals={convertData(opportunities_v2_created_product_shipment_insert_opportunities)} key="opportunities_v2_created_product_shipment_insert_opportunities"/>
                <AdminRow title="Opp V2 Created - Prizing Partner (agg)" vals={convertData(opportunities_v2_created_prizing_partner_opportunities)} key="opportunities_v2_created_prizing_partner_opportunities"/>
                <AdminRow title="Opp V2 Created - Dedicated Email Buy (agg)" vals={convertData(opportunities_v2_created_dedicated_email_buy_opportunities)} key="opportunities_v2_created_dedicated_email_buy_opportunities"/>
                <AdminRow title="Opp V2 Created - Dedicated Email Sell (agg)" vals={convertData(opportunities_v2_created_dedicated_email_sell_opportunities)} key="opportunities_v2_created_dedicated_email_sell_opportunities"/>
                <AdminRow title="Opp V2 Created - Custom (agg)" vals={convertData(opportunities_v2_created_custom_opportunities)} key="opportunities_v2_created_custom_opportunities"/>

                <AdminRow title="Monthly Active Users (Rolling 30)" vals={convertData(rolling30MAU, ',', 'mau')} key="monthlyActiveUsers"/>
                <AdminRow title="Daily Active Users" vals={convertData(dailyActiveUsers, ',.2f', 'dau')} key="dailyActiveUsers"/>
                <AdminRow title="DAU / MAU" vals={DAU_MAU} key="DAU_MAU"/>
                <AdminRow title="Avg Days To First Partnership Type 1" vals={convertData(daysToFirstPartnership_1, ',.2f', 'days_to_first_partnership')} key="daysToFirstPartnership_1"/>
                <AdminRow title="Avg Days To First Partnership Type 2 (excludes brands that don't reach)" vals={convertData(daysToFirstPartnership_2, ',.2f', 'days_to_first_partnership')} key="daysToFirstPartnership_2"/>
                <AdminRow title="Avg Days To First Partnership (Rolling 60)" vals={convertData(days_to_first_partnership_rolling60, ',.2f', 'days_to_first_partnership')} key="days_to_first_partnership_rolling60"/>
                <AdminRow title="Rolling 30 New Conversations" vals={convertData(rolling30NewConversations)} key="rolling30NewConversations"/>
                <AdminRow title="Rolling 30 Responded Conversations" vals={convertData(rolling30RespondedConversations)} key="rolling30RespondedConversations"/>
                <AdminRow title="Rolling 30 Message Response Rate" vals={messageResponseRate} key="mResponseRate"/>
                <AdminRow title="Msgs / Conversation (total msg/active convo)" vals={convertData(messagesPerConvo, ',.2f', 'avg_messages')} key="messagesPerConvo"/>
                <AdminRow title="Rolling 30 New Brands With Campaign Rate" vals={rolling30NewBrandsWithCampaignsRate} key="rolling30NewBrandsWithCampaignsRate"/>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}


export default OkrMetrics;
