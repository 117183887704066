import React from 'react'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import Checkbox from 'util/components/form-elements/checkbox'

class EditableAgreementItem extends React.Component{
  render() {
    const {
      children,
      attr,
      included,
      handleChange,
      width
    } = this.props

    return (
      <div>
        <Row>
          <Col style={{
            width: '36px'
          }}>
            <Checkbox
              toggleChecked={ e => this.props.handleChange(attr, e.target.checked) }
              label={ '' }
              value={ included }
              styles={{
                display: 'inline-block'
            }}/>
          </Col>
          <Col style={{
            width: width || '1100px',
            marginTop: included ? '-5px' : 0
          }}>
            { children }
          </Col>
        </Row>
      </div>
    )
  }
}

export default EditableAgreementItem
