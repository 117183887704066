import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { P, H3 } from 'visual-components/util/texts'

import EditableAgreementItem from './editable-agreement-item'
import EditableText from './editable-text'
import EditableNumber from './editable-number'
import EditableDate from './editable-date'
import Checkbox from 'util/components/form-elements/checkbox'

// Largest integer value
// https://www.postgresql.org/docs/9.1/static/datatype-numeric.html
const tooLarge = number => number > 2147483647

class PartnerResponsibilities extends React.Component {
  state = {
    agreement: this.props.partnershipAgreement.agreement
  }

  handleChange = (field, value) => {
    const { partnershipAgreement, actions } = this.props

    if (field === 'native') {
      this.updateValue('partner_social_channels', 'native', value)
    } else {
      value = field === 'partner_proof' ? value
        : (value ? {} : null)

      this.setState({
        agreement: Object.assign({}, this.state.agreement, {
          [field]: value
        })
      })

      this.updateValuePersist({
        [field]: value
      }, partnershipAgreement.id)
    }
  }

  updateValue = (parent, field, value) => {
    const { partnershipAgreement } = this.props

    this.setState({
      agreement: Object.assign({}, this.state.agreement, {
        [parent]: Object.assign({}, this.state.agreement[parent], {
          [field]: value
        })
      })
    })

    this.updateValuePersist({
      [parent]: {
        [field]: value
      }
    }, partnershipAgreement.id)
  }

  updateValuePersist = _.debounce((data, inviteId) => {
    const { actions } = this.props
    actions.updatePartnershipAgreement(data, inviteId)
  }, 200)

  render() {
    const { partnershipAgreement: { agreement: { hasErrors = [] } }, currentCampaign, host: { accountname: hostAccountname }, partnershipAgreement: { invitee: { accountname } } } = this.props
    const {
      partner_dedicated_email,
      partner_social_channels,
      partner_doesnt_deliver,
      partner_min_entries,
      partner_proof,
      partner_prizing
    } = this.state.agreement

    return (
      <div>
        <Row style={{
          marginBottom: '16px'
        }}>
          <H3 small>
            { `Partner Responsibilities` }
          </H3>
        </Row>

        <Row style={{
          marginBottom: '24px'
        }}>
          <P multiline>
            { `Select and detail the responsibilities you are requiring of this brand Partner. For best results, we recommend completing as many fields as possible.` }
          </P>
        </Row>

        <Row style={{
          marginBottom: !!partner_dedicated_email ? '23px' : '32px'
        }}>
          <P multiline>
            <EditableAgreementItem attr={ 'partner_dedicated_email' } included={ !!partner_dedicated_email }
              handleChange={ this.handleChange }
            >
              <em>{accountname}</em> will promote the sweepstakes by sending a dedicated email blast to a list size of
              <EditableNumber parent="partner_dedicated_email" name="num_emails"
                value={ partner_dedicated_email && partner_dedicated_email.num_emails }
                text={ 'Number of emails' }
                placeholder={ 'Number of emails' }
                included={ !!partner_dedicated_email }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_dedicated_email') && !!partner_dedicated_email
                  && (!partner_dedicated_email.num_emails || tooLarge(partner_dedicated_email.num_emails)) }
              />by
              <EditableDate parent="partner_dedicated_email" name="date"
                value={ partner_dedicated_email && partner_dedicated_email.date }
                text={ 'Date' }
                included={ !!partner_dedicated_email }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_dedicated_email') && !!partner_dedicated_email
                  && !partner_dedicated_email.date }
                min={ currentCampaign.startDate }
                max={ currentCampaign.endDate }
                noRightSpace
              />.
            </EditableAgreementItem>
          </P>
        </Row>

        <Row style={{
          marginBottom: '32px'
        }}>
          <P multiline>
            <EditableAgreementItem attr={ 'partner_social_channels' } included={ !!partner_social_channels }
              handleChange={ this.handleChange }
            >
              <em>{accountname}</em> will also promote the sweepstakes by
              <EditableDate parent="partner_social_channels" name="date"
                value={ partner_social_channels && partner_social_channels.date }
                text={ 'Date' }
                included={ !!partner_social_channels }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_social_channels') && !!partner_social_channels
                  && !partner_social_channels.date }
                min={ currentCampaign.startDate }
                max={ currentCampaign.endDate }
              />via the following channels:
              <div style={{
                marginLeft: '36px',
                marginTop: !!partner_social_channels ? '23px' : '32px'
              }}>
                <Row>
                  <Col style={{
                    width: '192px'
                  }}>
                    <div style={{
                      marginBottom: '16px'
                    }}>
                      <Checkbox
                        toggleChecked={ e => this.updateValue('partner_social_channels', 'facebook', e.target.checked) }
                        label={ '' }
                        value={ !!partner_social_channels && !!partner_social_channels.facebook }
                        styles={{
                          display: 'inline-block'
                      }}/>
                      Facebook
                    </div>
                    <div style={{
                      marginBottom: '16px'
                    }}>
                      <Checkbox
                        toggleChecked={ e => this.updateValue('partner_social_channels', 'instagram', e.target.checked) }
                        label={ '' }
                        value={ !!partner_social_channels && !!partner_social_channels.instagram }
                        styles={{
                          display: 'inline-block'
                      }}/>
                      Instagram
                    </div>
                    <div style={{
                      marginBottom: '16px'
                    }}>
                      <Checkbox
                        toggleChecked={ e => this.updateValue('partner_social_channels', 'twitter', e.target.checked) }
                        label={ '' }
                        value={ !!partner_social_channels && !!partner_social_channels.twitter }
                        styles={{
                          display: 'inline-block'
                      }}/>
                      Twitter
                    </div>
                  </Col>
                  <Col style={{
                    width: '192px'
                  }}>
                    <div style={{
                      marginBottom: '16px'
                    }}>
                      <Checkbox
                        toggleChecked={ e => this.updateValue('partner_social_channels', 'pinterest', e.target.checked) }
                        label={ '' }
                        value={ !!partner_social_channels && !!partner_social_channels.pinterest }
                        styles={{
                          display: 'inline-block'
                      }}/>
                      Pinterest
                    </div>
                    <div style={{
                      marginBottom: '16px'
                    }}>
                      <Checkbox
                        toggleChecked={ e => this.updateValue('partner_social_channels', 'newsletter', e.target.checked) }
                        label={ '' }
                        value={ !!partner_social_channels && !!partner_social_channels.newsletter }
                        styles={{
                          display: 'inline-block'
                      }}/>
                      Newsletter
                    </div>
                    <div style={{
                      marginBottom: '16px'
                    }}>
                      <Checkbox
                        toggleChecked={ e => this.updateValue('partner_social_channels', 'banner', e.target.checked) }
                        label={ '' }
                        value={ !!partner_social_channels && !!partner_social_channels.banner }
                        styles={{
                          display: 'inline-block'
                      }}/>
                      Banner Ad
                    </div>
                  </Col>
                  <Col style={{
                    width: '535px'
                  }}>
                    <div>
                      <EditableAgreementItem attr={ 'native' } included={ !!partner_social_channels && !!partner_social_channels.native }
                        handleChange={ this.handleChange }
                        width={ '498px' }
                      >
                        Native content embedded on
                        <EditableText parent="partner_social_channels" name="native_url"
                          value={ partner_social_channels && partner_social_channels.native_url }
                          text={ 'Website URL' }
                          placeholder={ 'Website URL' }
                          included={ !!partner_social_channels && !!partner_social_channels.native }
                          edit={ this.handleChange }
                          updateValue={ this.updateValue }
                          hasError={ hasErrors.includes('partner_native_url') && !!partner_social_channels
                            && !!partner_social_channels.native && !partner_social_channels.native_url }
                        />
                      </EditableAgreementItem>
                    </div>
                  </Col>
                </Row>
              </div>
            </EditableAgreementItem>
          </P>
        </Row>

        <Row style={{
          marginBottom: '32px'
        }}>
          <P multiline>
            <EditableAgreementItem attr={ 'partner_doesnt_deliver' } included={ !!partner_doesnt_deliver }
              handleChange={ this.handleChange }
            >
              If <em>{accountname}</em> does not deliver a minimum of
              <EditableNumber parent="partner_doesnt_deliver" name="min_entries"
                value={ partner_doesnt_deliver && partner_doesnt_deliver.min_entries }
                text={ 'Minimum number of entries' }
                placeholder={ 'Number of entries' }
                included={ !!partner_doesnt_deliver }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_doesnt_deliver') && !!partner_doesnt_deliver
                  && (!partner_doesnt_deliver.min_entries || tooLarge(partner_doesnt_deliver.min_entries)) }
              />entries by
              <EditableDate parent="partner_doesnt_deliver" name="date"
                value={ partner_doesnt_deliver && partner_doesnt_deliver.date }
                text={ 'Date' }
                included={ !!partner_doesnt_deliver }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_doesnt_deliver') && !!partner_doesnt_deliver
                  && !partner_doesnt_deliver.date }
                min={ currentCampaign.startDate }
                max={ currentCampaign.endDate }
              />, they will either send an additional last-call dedicated email blast or include a mention or banner in their newsletter to reach the minimum before the sweepstakes end date.
            </EditableAgreementItem>
          </P>
        </Row>

        <Row style={{
          marginBottom: '32px'
        }}>
          <P multiline>
            <EditableAgreementItem attr={ 'partner_min_entries' } included={ !!partner_min_entries }
              handleChange={ this.handleChange }
            >
              <em>{accountname}</em> will receive access to email entrants after they have contributed
              <EditableNumber parent="partner_min_entries" name="min_entries"
                value={ partner_min_entries && partner_min_entries.min_entries }
                text={ 'Minimum number of entries' }
                placeholder={ 'Number of entries' }
                included={ !!partner_min_entries }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_min_entries') && !!partner_min_entries
                  && (!partner_min_entries.min_entries || tooLarge(partner_min_entries.min_entries)) }
              />entries by whatever promotional means necessary as recorded in DojoMojo.
            </EditableAgreementItem>
          </P>
        </Row>

        <Row style={{
          marginBottom: '32px'
        }}>
          <P multiline>
            <div>
              <Checkbox
                toggleChecked={ e => this.handleChange('partner_proof', e.target.checked) }
                label={ '' }
                value={ !!partner_proof }
                styles={{
                  display: 'inline-block'
              }}/>
              <em>{accountname}</em> will provide proof of total send, open rate, and click-through rate on email performance as requested by <em>{hostAccountname}</em>.
            </div>
          </P>
        </Row>

        <Row style={{
          marginBottom: !!partner_prizing ? '48px' : '80px'
        }}>
          <P multiline>
            <EditableAgreementItem attr={ 'partner_prizing' } included={ !!partner_prizing }
              handleChange={ this.handleChange }
            >
              <em>{accountname}</em> will contribute prizing of
              <EditableText parent="partner_prizing" name="contribution"
                value={ partner_prizing && partner_prizing.contribution }
                text={ 'Prizing contribution description' }
                placeholder={ 'Prizing Contribution Description' }
                included={ !!partner_prizing }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_prizing') && !!partner_prizing
                  && !partner_prizing.contribution }
                width={ '560px '}
                charLimit={ '375' }
              />at an estimated value of
              <EditableNumber parent="partner_prizing" name="dollar_amount"
                value={ partner_prizing && partner_prizing.dollar_amount }
                text={ 'Dollar amount' }
                placeholder={ 'Dollar Amount' }
                included={ !!partner_prizing }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_prizing') && !!partner_prizing
                  && (!partner_prizing.dollar_amount || tooLarge(partner_prizing.dollar_amount)) }
              />to the winner of the sweepstakes by
              <EditableDate parent="partner_prizing" name="date"
                value={ partner_prizing && partner_prizing.date }
                text={ 'Date' }
                included={ !!partner_prizing }
                edit={ this.handleChange }
                updateValue={ this.updateValue }
                hasError={ hasErrors.includes('partner_prizing') && !!partner_prizing
                  && !partner_prizing.date }
                min={ currentCampaign.startDate }
                max={ moment(currentCampaign.endDate).add(30, 'days') }
                noRightSpace
              />.
            </EditableAgreementItem>
          </P>
        </Row>
      </div>
    )
  }
}

export default PartnerResponsibilities
