import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import { v1 as uuidv1 } from 'uuid'
import { object } from 'prop-types'
import MessengerTheme from 'css/themes/media-market/Messenger.css'
import MessageWrapper from './messageWrapper'

class MessageWindow extends PureComponent {
  static propTypes = {
    deal: object.isRequired,
    dealAssets: object.isRequired,
    dealCollaboration: object.isRequired,
    currentBrand: object.isRequired,
    utilityFunctions: object.isRequired,
    statusMap: object.isRequired,
  }

  componentDidMount() {
    const { deal: { id }, utilityFunctions: { markRead } } = this.props
    markRead(id, 'messages')
    this.messageScroll()
  }

  componentDidUpdate(prevProps) {
    const {
      deal: {
        id, currentProposal, targets, status,
      },
      utilityFunctions: { markRead },
      dealAssets: { assetGroups },
      dealCollaboration: { messages, links },
    } = this.props

    // If window not focused, don't automatically mark read
    // DISABLED FOR NOW
    // if (!document.hasFocus()) return

    if (
      prevProps.dealCollaboration.messages !== messages
      || prevProps.dealCollaboration.links !== links
      || prevProps.dealAssets.assetGroups !== assetGroups
      || prevProps.deal.currentProposal !== currentProposal
      || prevProps.deal.targets !== targets
      || prevProps.deal.status !== status
    ) {
      markRead(id, 'messages')
      this.messageScroll()
    }
  }

  messageScroll = () => {
    if (this.scrollBox) {
      this.scrollBox.scrollTop = this.scrollBox.scrollHeight
    }
  }

  render() {
    const {
      deal: {
        id: dealId,
        updated_at,
        status,
        buying_brand_id,
        buying_brand,
        selling_brand,
        selling_brand_id,
        currentProposal,
        previousProposals,
        proposingUser,
        targets,
        request,
        attributeChanges,
      },
      dealCollaboration: {
        messages,
        uniqueLinks,
      },
      dealAssets: {
        assetGroups,
      },
      currentBrand,
      utilityFunctions,
      statusMap,
    } = this.props

    const isProposingBrand = currentBrand.id === currentProposal.proposing_brand_id

    // Do not show proposals if deal is in request phase.
    // Requests are before proposals.
    const proposals = currentProposal.status === 'request'
      ? []
      : [currentProposal].concat(previousProposals)

    if (attributeChanges && attributeChanges.length) {
      const [lastProposal] = previousProposals.slice(-1)

      const copyLastProposal = { ...lastProposal }

      copyLastProposal.created_at = moment(currentProposal.created_at).subtract(1, 'second').toString()
      copyLastProposal.uid = uuidv1()

      proposals.push(copyLastProposal)
    }

    if (!['pending', 'request'].includes(status)) {
      proposals.push({
        messageType: 'proposalStatus',
        created_at: updated_at,
        uid: uuidv1(),
        status,
      })
    }

    const requestMessages = request
      ? (
        [{
          ...request,
          status: 'request',
          deal_status: status,
        }]
      )
      : []

    if (status === 'request' && request.status === 'expired') {
      proposals.push({
        messageType: 'proposalStatus',
        created_at: updated_at,
        uid: uuidv1(),
        status: 'expired',
      })
    }

    const targetMessages = []

    // Only notify user about new target links if deal is accepted
    if (['accepted', 'live', 'completed', 'unfulfilled', 'in_dispute'].includes(status)) {
      // Only include target links created after deal was accepted
      targets
        .filter(target => target.created_at > updated_at)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        // Group messages by hour
        .forEach(target => {
          const { created_at } = target
          const len = targetMessages.length
          if (
            len
            && moment(created_at).diff(moment(targetMessages[len - 1].created_at), 'minutes') < 60
          ) {
            targetMessages[len - 1].numLinks++
          } else {
            targetMessages.push({
              messageType: 'newTargetLink',
              created_at,
              buyingBrand: buying_brand.accountname,
              numLinks: 1,
              dealId,
            })
          }
        })
    }

    const combined = messages
      .concat(requestMessages)
      .concat(proposals)
      .concat(assetGroups)
      .concat(uniqueLinks)
      .concat(targetMessages)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

    const mapMessages = combined.map((item, idx) => {
      const temp = item

      const currMessageDate = moment(combined[idx].created_at).startOf('day')
      const prevMessageDate = combined[idx - 1] && moment(combined[idx - 1].created_at).startOf('day')
      const showDate = currMessageDate.diff(prevMessageDate) !== 0
      const hideTimeStamp = prevMessageDate && moment(combined[idx].created_at).isSame(moment(combined[idx - 1].created_at), 'minute')
      const isSender = item.messageType === 'status'
        || temp.brand_id === currentBrand.id
        || temp.proposing_brand_id === currentBrand.id
        || (temp.status === 'request' && temp.buyer_brand_id === currentBrand.id)

      if (temp.proposing_brand_id) {
        temp.messageType = 'proposal'
        temp.status = status
        temp.isCounterOffer = previousProposals.some(proposal =>
          proposal.proposing_brand_id === buying_brand_id)
      }

      if (temp.status === 'request') {
        temp.messageType = 'request'
        temp.request = request
      }

      const user = item.proposingUser || item.user || item.buyingUser
      temp.user_name = item.user_name
        || (user && (user.fullName ? user.fullName : `${user.firstName || ''} ${user.lastName || ''}`))

      if (temp.is_decline_reason) temp.messageType = 'declinedReason'

      return (
        <MessageWrapper
          key={temp.uid || temp.id}
          uid={temp.uid}
          messageType={temp.messageType}
          item={temp}
          hideTimeStamp={hideTimeStamp}
          utilityFunctions={utilityFunctions}
          statusMap={statusMap}
          showDate={showDate}
          isSender={isSender}
          isProposingBrand={isProposingBrand}
          proposingUser={proposingUser}
          buying_brand={buying_brand}
          buying_brand_id={buying_brand_id}
          selling_brand={selling_brand}
          selling_brand_id={selling_brand_id}
        />
      )
    })

    return (
      <div
        className={`${MessengerTheme.messageScrollbox}`}
        ref={el => (this.scrollBox = el)}
      >
        {mapMessages}
      </div>
    )
  }
}

export default MessageWindow
