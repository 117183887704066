import React from 'react';
import PropTypes from 'prop-types';
import COLOURS from 'util/colours';
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { clear } from 'actions/opportunities-actions'

import { Card } from 'react-toolbox/lib/card';
import BlueTag from 'visual-components/util/tags/blue-tag';
import { Button, IconButton } from 'react-toolbox/lib/button';
import MiniOpportunityInfo from './mini-opportunity-info';

import CardThemes from 'css/themes/card.css'
import buttonTheme from 'css/themes/Buttons.css'
import iconButtonTheme from 'css/themes/IconButton.css'

const mapDispatchToProps = dispatch => ({
  opportunityActions: bindActionCreators({ clear }, dispatch),
})

class OpportunityCard extends React.Component {
  viewOpportunity = () => {
    const { opportunity, history, brand: { obscure }, opportunityActions } = this.props
    const { type, id } = opportunity
    const url = `opportunities/${type}/${id}`

    if (obscure) {
      // TODO: forces reload, investigate if worth it to set up publicly
      // accessible endpoints to fetch brand info between in-app page requests
      window.location.href = `/home/${url}`
    } else {
      opportunityActions.clear()
      history.push(`/explore/${url}`)
    }
  }

  render() {
    let {
      opportunity,
      brand,
      isBrand,
      showActions,
      hideBar,
      shareOpportunity,
      editOpportunity,
      deleteOpportunity
    } = this.props;

    let tags = brand.tagsconnected || brand.tags || []
    const tagElements = tags && tags.map((tag, id) => {
      return <BlueTag tag={ tag.name || tag } key={id} />
    })

    return (
      <div className={ CardThemes.opportunityCard }>
        <Card style={{
          width: '100%',
          paddingTop: '16px',
          paddingRight: '16px',
          paddingBottom: '8px',
          paddingLeft: '20px',
          height: hideBar ? '195px' : '273px',
        }}>
          <div style={{ height: '220px', cursor: 'pointer' }} onClick={this.viewOpportunity}>
            <MiniOpportunityInfo brand={ brand } opportunity={ opportunity }/>
            <div style={{ marginTop: '20px' }}>
              { this.props.children }
            </div>
          </div>
          {!hideBar && <div>
            <hr style={{
              borderColor: COLOURS.silver,
              marginRight: '-32px',
              marginBottom: '12px',
              marginLeft: '-32px',
              marginTop: '0px'
            }}/>
            { isBrand && showActions ?
                <div style={{ marginLeft: '-8px' }}>
                  <Button
                    onClick={ editOpportunity }
                    className={ buttonTheme.noMargin }
                    theme={ buttonTheme }
                    label={ 'Edit' }
                    flat
                    primary
                    style={{
                      marginRight: '9px',
                      // paddingLeft: '0px'
                  }}/>
                  <Button
                    onClick={ deleteOpportunity }
                    className={ buttonTheme.noMargin }
                    theme={ buttonTheme }
                    label={ 'Delete' }
                    flat
                    primary
                    style={{
                      marginRight: '8px'
                  }}/>
                  <IconButton
                    onClick={ shareOpportunity }
                    className={ iconButtonTheme.shareButton }
                    theme={ iconButtonTheme }
                    primary
                  >
                    <img src="/images/icons/share.svg" className={ iconButtonTheme.shareIcon } />
                  </IconButton>
                </div>
              :
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}>
                  { tagElements }
                </div>
            }
          </div>
        }
        </Card>
      </div>
    )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(OpportunityCard))
