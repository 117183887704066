import React, { Component } from 'react'
import theme from 'css/themes/mobile/home/sweepstakes.css'
import SmsMarketingBanner from './SmsMarketingBanner'
import ImgAndContent from '../util/img-and-content'
import MobileFooter from '../mobile-footer/MobileFooter'

class MobileSmsMarketing extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <SmsMarketingBanner />
        <div className={theme.imgsContentContainers}>
          <ImgAndContent
            imgSrc="/images/login/side-imgs/sms-marketing/post-entry.png"
            imgClass="sms-marketing"
            title="Broaden Your Reach"
            explain="While other solutions may focus on converting your existing customers to SMS, DojoMojo makes it easy for you to quickly scale and acquire a broader audience."
            subparas={[
              {
                title: 'Partnerships powerhouse:',
                text: 'Work within our network of 10,000+ brands to widen your audience exposure',
              },
              {
                title: 'Accelerate audience growth:',
                text:
                  'Leverage existing sweepstakes to rapidly build both your email and mobile messaging lists',
              },
              {
                title: 'Maximize touchpoints:',
                text:
                  'Set up website lead forms and easily capture even more compliant SMS subscribers directly from your website',
              },
            ]}
          />
          <ImgAndContent
            imgSrc="/images/login/side-imgs/sms-marketing/create-new-message.png"
            imgClass="sms-marketing"
            title="Optimize Customer Engagement"
            explain="Stop battling flooded inboxes and find your audience where they are &mdash; their phones. SMS delivery is already 2-3x that of traditional email!"
            subparas={[
              {
                title: 'Safe send:',
                text: 'Use built-in optimizations to start meaningful conversations with the right customer, at the right time',
              },
              {
                title: 'Say more without words:',
                text:
                  'Personalize your message with content-rich GIFs, photos, and media',
              },
            ]}
          />
          <ImgAndContent
            imgSrc="/images/login/side-imgs/sms-marketing/overview.png"
            imgClass="sms-marketing"
            title="Drive More Revenue"
            explain="Marketers using DojoMojo are seeing results as high as a 12x+ return on their advertising spend. See how your SMS messages perform and watch your revenue grow!"
            subparas={[
              {
                title: 'Monitor performance:',
                text: 'Analyze and measure the results from your communications and how they stack up over time',
              },
              {
                title: 'Seamless integrations:',
                text:
                  'Connect your payment processors so you don’t miss a beat in your customer’s journey ',
              },
            ]}
          />
        </div>
        <MobileFooter />
      </div>
    )
  }
}

export default MobileSmsMarketing
