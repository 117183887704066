const model = 'browserHistory'

const defaultState = {
  rid: model,
  history: null,
}

const BrowserHistoryReducer = function(state, action) {
  if (action.model !== model) {
    return state || defaultState
  }
  const actions = {
    SET_HISTORY: function() {
      if (state.history) {
        return state || defaultState
      }
      return Object.assign({}, state, action.data)
    }
  }

  const item = actions[action.type] || (() => state || defaultState)
  return item()
}

export default BrowserHistoryReducer
