import React from 'react'
import { bool, object, func } from 'prop-types'
import { Dialog } from 'react-toolbox/lib/dialog'
import { P } from 'visual-components/util/texts'
import CustomCSSEditor from 'visual-components/campaign-builder/custom-css/custom-css-editor'
import DialogTheme from 'css/themes/dialogs/formModal.css'

class CustomCSSModal extends React.Component {
  static propTypes = {
    active: bool,
    opts: object.isRequired,
    toggleModal: func.isRequired,
    updateAttr: func.isRequired,
  }

  static defaultProps = {
    active: false,
  }

  render() {
    const {
      active,
      opts,
      toggleModal,
      updateAttr,
    } = this.props

    return (
      <Dialog
        theme={DialogTheme}
        active={active}
        title="Custom CSS"
        onEscKeyDown={toggleModal}
        onOverlayClick={toggleModal}
      >
        <div>
          <img
            onClick={toggleModal}
            className={DialogTheme.closeImage}
            src="/images/icons/close.svg"
          />
          <P multiline>
            {"Use this section to add any custom CSS in order to further customize your campaign's landing page. This step is optional and will require development resources."}
          </P>
          <CustomCSSEditor
            updateAttr={updateAttr}
            attr="customCss"
            originalContent={opts.elements.customCss || ''}
          />
        </div>
      </Dialog>
    )
  }
}

export default CustomCSSModal
