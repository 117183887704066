import React, { Component } from 'react';
import { string } from 'prop-types'
import moment from 'moment';
import COLOURS from 'util/colours';
import { P } from 'visual-components/util/texts'

import { Button } from 'react-toolbox/lib/button'
import buttonTheme from 'css/themes/Buttons.css'

import { getPlanLabel } from 'util/brand-stripe-belt-helper'

class InvoiceRow extends Component {
  static contextTypes = {
    impersonating: string,
  }

  render() {
    const { invoice, currentBrandId } = this.props
    const { impersonating } = this.context

    const userTimezone = moment.tz.guess()
    const offset = moment.tz(userTimezone).utcOffset()
    const isPaid = ['paid', 'succeeded'].includes(invoice.raw.status)

    return(
      <tr style={{
        height: '54px',
        verticalAlign: 'middle',
        borderBottom: `${COLOURS.silver} 1px solid`,
      }}>
        <td style={{
          fontFamily: 'Larsseit-Light',
          fontSize: '14px',
          lineHeight: '54px',
          color: '#212731',
          width: '420px',
          paddingLeft: '16px'
        }}>
          {`DM-${invoice.cleanId} ${invoice.plan ? `- ${getPlanLabel(invoice.plan)}` : ''}` }
        </td>
        <td style={{
          fontFamily: 'Larsseit-Light',
          fontSize: '14px',
          lineHeight: '14px',
          color: '#212731',
          width: '140px'
        }}>
          { moment.tz(invoice.date * 1000, userTimezone).format('l') }
        </td>
        <td style={{
          fontFamily: 'Larsseit-Light',
          fontSize: '14px',
          lineHeight: '14px',
          color: '#212731',
          width: '50px'
        }}>
          { d3.format('$,.2f')(invoice.amount / 100) }
        </td>
        <td style={{
          width: '110px',
        }}>
          {isPaid ? (
            <a
              href={`/invoice/sub/${currentBrandId}/${offset}/${invoice.id}/DM-${invoice.cleanId}.pdf${impersonating ? `?impersonating=${impersonating}` : ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                flat
                primary
                theme={buttonTheme}
                style={{
                  marginLeft: '30px',
                  marginTop: '-2px',
                }}
              >
                Download
              </Button>
            </a>
          ) : (
            <P style={{ width: '100%', textAlign: 'center', marginLeft: 6 }} alertRed>
              Failed
            </P>
          )}
        </td>
      </tr>
    );
  }
};

export default InvoiceRow;
