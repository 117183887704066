import React from 'react'
import { Button } from 'react-toolbox/lib/button'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Input } from 'react-toolbox/lib/input'
import { Link, withRouter } from 'react-router-dom'

import footerTheme from 'css/themes/mobile/footer.css'
import buttonTheme from 'css/themes/Buttons.css'

import MobileGrowSection from './mobile-grow-section'

class MobileFooter extends React.Component {
  render() {
    return (
      <div>
        <MobileGrowSection />
        <div className={footerTheme.lowerFooter}>
          <Grid>
            <Row>
              <Col xs>
                <p className={footerTheme.footerTitle}>
                  Subscribe to Our Blog
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <p className={footerTheme.footerSubtitle}>
                  Get the best partnership marketing tips in your inbox.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <form
                  action="//ninja.us11.list-manage.com/subscribe/post?u=edee501d99df967bf714b452d&amp;id=578ec8d474"
                  method="post"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                  noValidate
                >
                  <Input
                    type="text"
                    name="EMAIL"
                    id="mce-EMAIL"
                    theme={footerTheme}
                    className={footerTheme.blogSubInput}
                    placeholder="Enter your email"
                  />
                  <Button
                    type="submit"
                    label="Subscribe"
                    className={buttonTheme.greenButton}
                    theme={buttonTheme}
                    raised
                  />
                </form>
              </Col>
            </Row>
            <div className={footerTheme.footerLinkRow}>
              <Row>
                <Col xs={6}>
                  <p className={footerTheme.footerLinkTitle}>
                    Product
                  </p>
                  <Link to="/sweepstakes" className={footerTheme.footerLink}>Sweepstakes</Link>
                  <Link to="/sms-marketing" className={footerTheme.footerLink}>SMS Marketing</Link>
                  <Link to="/network" className={footerTheme.footerLink}>Network</Link>
                  <a href="/app/register" className={footerTheme.footerLink}>Sign Up</a>
                </Col>
                <Col xs={6}>
                  <p className={footerTheme.footerLinkTitle}>
                    Resources
                  </p>
                  <a href="https://blog.dojomojo.com" target="_blank" className={footerTheme.footerLink} rel="noreferrer">Blog</a>
                  <Link to="/resources/all" className={footerTheme.footerLink}>Guides</Link>
                </Col>
              </Row>
            </div>
            <div className={footerTheme.footerLinkRow}>
              <Row>
                <Col xs={6}>
                  <p className={footerTheme.footerLinkTitle}>
                    About
                  </p>
                  <a href="/app/pricing" className={footerTheme.footerLink}> Pricing </a>
                  <a href="/app/about-us" className={footerTheme.footerLink}> About Us </a>
                  <Link to="/careers" className={footerTheme.footerLink}>Careers</Link>
                  {/* TODO: Disabled as of 11/30/2021 */}
                  {/* <a href="/app/meet-the-team" className={footerTheme.footerLink}> Team </a> */}
                  <Link to="/terms-of-use" target="_blank" className={footerTheme.footerLink}>Terms</Link>
                  <Link to="/privacy" target="_blank" className={footerTheme.footerLink}>Privacy</Link>
                  <a href="mailto:support@dojomojo.com" className={footerTheme.footerLink}>Contact Us</a>
                </Col>
                <Col xs={6}>
                  <p className={footerTheme.footerLinkTitle}>
                    Help
                  </p>
                  <Link to="https://help.dojomojo.com/hc/en-us" target="_blank" className={footerTheme.footerLink}>Knowledge Base</Link>
                  <a href="mailto:support@dojomojo.com" className={footerTheme.footerLink}>Support</a>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs className={footerTheme.contactLink}>
                <a href="mailto:hello@dojomojo.com">
                  hello@dojomojo.com
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <a href="https://www.facebook.com/dojomojoteam" className={footerTheme.footerSocialLink}>
                  <img alt="facebook" src="images/icons/facebook-icon.svg" className={footerTheme.footerSocialImage} />
                </a>
                <a href="https://www.linkedin.com/company/dojomojo/" className={footerTheme.footerSocialLink}>
                  <img alt="linkedin" src="images/icons/linkedin-icon.svg" className={footerTheme.footerSocialImage} />
                </a>
                <a href="https://twitter.com/DojoMojoTeam" className={footerTheme.footerSocialLink}>
                  <img alt="twitter" src="images/icons/twitter-icon.svg" className={footerTheme.footerSocialImage} />
                </a>
                <a href="https://www.instagram.com/dojomojoninja/" className={footerTheme.footerSocialLink}>
                  <img alt="instagram" src="images/icons/instagram-logo.svg" className={footerTheme.footerSocialImage} />
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <p className={footerTheme.copyright}>
                  Copyright
                  {' '}
                  { new Date().getFullYear() }
                  {' '}
                  Innovation Brand Corp
                </p>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    )
  }
}

export default withRouter(MobileFooter)
