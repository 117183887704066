import React, { PureComponent } from 'react'
import _ from 'lodash'

import PropTypes from 'prop-types'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import CampaignDetailCard from 'visual-components/shared/campaign-detail-card/CampaignDetailCard'
import BrandStats from 'visual-components/brandProfile/brand-stats'
import EmptyBonzai from './empty-bonzai'

import DemographicsContent from './demographics-content'

class CampaignsTab extends PureComponent {
  static propTypes = {
    brand: PropTypes.object.isRequired,
    currentBrand: PropTypes.object.isRequired,
  }

  render() {
    const { brand, currentBrand } = this.props

    if (brand.obscure) {
      return (
        <EmptyBonzai description="Sign up or log in to DojoMojo to see this brand's full profile." />
      )
    }

    const campaigns = _.map(brand.availableCampaigns, (campaign, idx) => (
      <Col key={idx}>
        <CampaignDetailCard campaign={campaign} />
      </Col>
    ))

    const emptyState = !campaigns.length && !brand.totalCampaigns
    const isBrand = currentBrand.id === brand.id

    if (emptyState) {
      return (
        <EmptyBonzai
          description={
            isBrand
              ? 'You haven\'t participated in any campaigns yet. Create a campaign to get started!'
              : 'This brand doesn\'t have any open campaigns at this time. Connect with them to start a partnership.'
          }
        />
      )
    }

    return (
      <Grid>
        <Row style={{ marginBottom: '16px' }}>
          <BrandStats
            campaignsHosted={brand.hostedCampaigns}
            recentCampaigns={brand.campaignsLast90Days ? brand.campaignsLast90Days : 0}
            totalCampaigns={brand.totalCampaigns}
            brandPartners={parseInt(brand.pastPartners, 10)}
            totalAudienceReach={brand.totalAudienceReach}
          />
        </Row>
        <Row>
          { campaigns }
        </Row>
        <Row>
          <DemographicsContent brand={brand} currentBrand={currentBrand} />
        </Row>
      </Grid>
    )
  }
}

export default CampaignsTab
