import React, { Component } from 'react'
import { func, object } from 'prop-types'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import { H4 } from 'visual-components/util/texts'
import ModalButtonGroupWithContext from 'visual-components/util/buttons/ModalButtonGroupWithContext'

import { SimpleForm, Input } from 'visual-components/util/form'
import { isPositive, cannotExceed } from 'visual-components/util/form/validations'

import { CLOSED, BANK_ACCOUNT_SETTINGS } from './steps'

const mustBeNew = value => {
  if (!value) throw 'This value is incorrect.'
}

export default class IncorrectVerificationAmountsForm extends Component {
  static propTypes = {
    toggleModal: func.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
  }

  toggleModal = STEP => {
    const { toggleModal } = this.props
    toggleModal(STEP)
  }

  close = () => this.toggleModal(CLOSED)

  back = () => {
    const {
      brandAccount: { simpleVerifyId },
      brandAccountActions: { updateModel },
    } = this.props

    if (simpleVerifyId) {
      updateModel({ simpleVerifyId: null })
      this.close()
    } else {
      this.toggleModal(BANK_ACCOUNT_SETTINGS)
    }
  }

  update = data => this.setState(prevState => ({ ...prevState, ...data }))

  handleSubmit = ({ amount1, amount2 }) => {
    const {
      brandAccount: { simpleVerifyId },
      brandAccountActions: { verifyBankAccount },
    } = this.props
    verifyBankAccount({ amount1, amount2 }, simpleVerifyId)
    this.close()
  }

  render() {
    const {
      brandAccount: { error },
    } = this.props
    return (
      <SimpleForm
        model={this.state}
        updateModel={this.update}
        handleSubmit={this.handleSubmit}
      >
        <Grid>
          <Row style={{ marginBottom: '16px' }}>
            <H4 multiline>
              Sorry, it appears that the amounts you entered are not correct. Try entering them
              again below or you can enter a new bank account to verify instead.
              {error && (
                <strong style={{ color: 'red' }}>
                  <br />
                  {`Error: ${error}`}
                </strong>
              )}
            </H4>
          </Row>
          <Row>
            <Col style={{ width: '300px', marginRight: '8px' }}>
              <Input
                name="amount1"
                label="Amount 1"
                placeholder="0.54"
                validations={[mustBeNew, isPositive, cannotExceed(1)]}
              />
            </Col>
            <Col style={{ width: '300px' }}>
              <Input
                name="amount2"
                label="Amount 2"
                placeholder="0.46"
                validations={[mustBeNew, isPositive, cannotExceed(1)]}
              />
            </Col>
          </Row>
        </Grid>
        <ModalButtonGroupWithContext
          submitOnMount
          confirmText="Verify"
          cancelText="Try Another Account"
          cancel={this.back}
        />
      </SimpleForm>
    )
  }
}
