import { getTemplate, compileTemplate } from './TemplateV2'

const template = getTemplate()
const themeColor = '#1b5e20'

template.formContainer = {
  form: {
    padding: '20px 32px 0 32px',
    marginBottom: 40,
  },
  bottomInfo: {
    padding: '40px 32px 0 32px',
  },
}

export const templateStyles = compileTemplate(template)
export const defaultVals = {}
