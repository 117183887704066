import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import Dialog from 'react-toolbox/lib/dialog'
import BillingCardForm from 'visual-components/brandSettings/billing-card-form'
import * as DialogActions from 'actions/dialog-actions'
import DialogTheme from 'css/themes/dialogs/formModal.css'

const mapStateToProps = ({ creditCardInfoModal, currentBrand, customer }) =>
  ({ creditCardInfoModal, currentBrand, customer })

const mapDispatchToProps = dispatch => ({
  dialogActions: bindActionCreators(DialogActions, dispatch),
})

class EnterCreditCardInfoModal extends React.Component {
  static propTypes = {
    creditCardInfoModal: object.isRequired,
    dialogActions: object.isRequired,
  }

  cardAdded = () => {
    toastr.success('Your credit card has been added successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
    this.toggleModal()
  }

  cardUpdated = () => {
    toastr.success('Your credit card has been updated successfully.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
    this.toggleModal()
  }

  toggleModal = () => {
    const {
      dialogActions: { dialogUpdateWithPayload },
    } = this.props

    dialogUpdateWithPayload('creditCardInfoModal', { open: false, updating: false, item: null })
  }

  render() {
    const {
      creditCardInfoModal: {
        open,
        updating,
        item: card,
      },
    } = this.props

    return (
      <Dialog
        theme={DialogTheme}
        active={open}
        onEscKeyDown={this.toggleModal}
        onOverlayClick={this.toggleModal}
        title="Enter Credit Card Info"
      >
        <img
          onClick={this.toggleModal}
          className={DialogTheme.closeImage}
          src="/images/icons/close.svg"
        />

        <BillingCardForm
          addCard={!updating}
          updateCard={updating && card}
          cardAdded={updating ? this.cardUpdated : this.cardAdded}
          toggleModal={this.toggleModal}
        />
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterCreditCardInfoModal)
