import React from 'react'

import DefaultDialog from 'visual-components/util/modals/default-dialog'
import { H4 } from 'visual-components/util/texts'

class ReinvitePartnerConfirmationModal extends React.Component {
  render() {
    const { active, openReinvitationMessenger, closeModal, partnerName, campaignName } = this.props
    return (
      <div>
        <DefaultDialog
          active={active}
          closeModal={closeModal}
          title="Reinvite Brand to Campaign"
          confirm={() => openReinvitationMessenger('pending')}
          confirmText="Reinvite"
          cancel={closeModal}
          cancelText="Cancel"
          children={
            <div>
              <H4 multiline>
                You’ve already invited <em>{partnerName}</em> to <em>{campaignName}</em>.  Are you sure you want to invite partner again?
              </H4>
            </div>
          }
        />
      </div>
    )
  }
}

export default ReinvitePartnerConfirmationModal
