import React from 'react'
import PropTypes from 'prop-types'
import injectCSS from 'react-jss'
import cn from 'classnames'

import { P } from 'visual-components/util/texts'

import FormlessField from 'visual-components/util/inputs/FormlessField'
import InputValidator from 'util/input-validator'

import SMSForm from '../SMSForm'
import SMSPreview from '../SMSPreview'
import { MobileMarketingContext } from '../MobileMarketingContext'

const styles = {
  editing: {
    display: 'flex',
    padding: '32px 28px',
    '& > div': {
      flex: 1,
      '&:not(:first-child)': { // SmsPreview
        paddingRight: 40,
        '& > div': {
          float: 'right',
        },
      },
    },
  },
  removePadding: {
    '& > div': {
      '&:not(:first-child)': { // SmsPreview
        paddingRight: 0,
      },
    },
  },
  followupInputs: {
    flex: 1,
    '& > div': {
      '& > p': {
        marginBottom: 8,
      },
      '&:last-child > div': {
        display: 'flex',
        alignItems: 'center',
        '& > :nth-child(1)': { // input
          maxWidth: 100,
          marginRight: 12,
          padding: 0,
          '& > div:first-child': {
            padding: 0,
            '& [class*=error]': {
              display: 'none',
            },
          },
          '& > div:last-child:not([data-react-toolbox])': {
            position: 'absolute !important',
            marginTop: '4px !important',
            marginBottom: '0px !important',
          },
        },
        '& > :nth-child(2)': { // dropdown
          maxWidth: 220,
          margin: 0,
          marginRight: 12,
        },
        '& > :nth-child(3)': { // text
          flex: 1,
        },
      },
    },
  },
  followupInputsWrapper: {
    width: '100%',
    display: 'flex',
    padding: '28px 28px 0',
  },
}

function EditingContent(props) {
  const {
    classes: css,
    isFollowup,
    message,
    isWelcomeMessage,
  } = props

  const {
    setFollowupMessage,
    setMainMessage,
    onMainMessageError,
    onFollowupError,
    setTestData,
  } = React.useContext(MobileMarketingContext)

  const onChangeFn = data => (isFollowup ? setFollowupMessage : setMainMessage)(data, message.id)
  const onErrorFn = (field, error) => (isFollowup ? onFollowupError : onMainMessageError)({ [field]: error }, message.id)

  return (
    <>
      {
        isFollowup && (
          <div className={css.followupInputsWrapper}>
            <div className={css.followupInputs}>
              <div>
                <FormlessField
                  name="name"
                  label="Message Name"
                  error={message.errorFields.name}
                  dirty={message.dirty.name}
                  value={message.name}
                  onError={onErrorFn}
                  onChange={name => onChangeFn({ name })}
                  validations={[InputValidator.Required]}
                />
              </div>
              <div>
                <P><em>Send</em></P>
                <div>
                  <FormlessField
                    name="send_interval"
                    dirty={message.dirty.send_interval}
                    error={message.errorFields.send_interval}
                    value={message.send_interval}
                    formatter={
                      val => (
                        val
                          ? /^\d+$/.test(val)
                            ? Number(val)
                            : message.send_interval
                          : 0
                      )
                    }
                    onError={onErrorFn}
                    onChange={send_interval => onChangeFn({ send_interval })}
                    validations={[InputValidator.Required, InputValidator.IsNumber]}
                  />
                  {/* TODO: Not sure if this needs a logic to switch between "after previous message" and "after first message" */}
                  <P>
                    {`day${message.send_interval > 1 ? 's' : ''} after previous message`}
                  </P>
                </div>
              </div>
            </div>
            <div className={css.followupInputs}>
              {/* filler purposes */}
            </div>
          </div>
        )
      }
      <div className={cn(css.editing, { [css.removePadding]: isFollowup })}>
        <SMSForm
          onChange={onChangeFn}
          onError={onErrorFn}
          message={message}
          isFollowup={isFollowup}
          isWelcomeMessage={isWelcomeMessage}
        />
        <SMSPreview
          message={message}
          showTestModal={() => setTestData(message)}
        />
      </div>
    </>
  )
}

EditingContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isFollowup: PropTypes.bool,
  isWelcomeMessage: PropTypes.bool,
}

EditingContent.defaultProps = {
  isFollowup: false,
  isWelcomeMessage: false,
}

export default React.memo(injectCSS(styles)(EditingContent))
