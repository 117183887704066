import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import moment from 'moment-timezone'
import URL from 'url'
import { Card } from 'react-toolbox/lib/card'
import { Button } from 'react-toolbox/lib/button'
import { H2, H3, H4, P } from 'visual-components/util/texts'
import MaterialSpinner from 'visual-components/util/spinners/MaterialSpinner/MaterialSpinner'
import EnterCreditCardInfoModal from 'visual-components/brandSettings/enter-credit-card-info-modal'
import EnterACHBankAccountInfoModal from 'visual-components/brandSettings/enter-ach-bank-account-info-modal'
import Theme from 'css/themes/payments/billing-payment-methods-theme.css'
import FullscreenModalWideTheme from 'css/themes/modals/FullscreenModalWideTheme.css'
import ButtonTheme from 'css/themes/Buttons.css'
import Spinner from 'util/components/spinner'
import AppDispatcher from 'dispatchers/app-dispatcher'
import CurrentBrandDispatcher from 'dispatchers/current-brand-dispatcher'
import ConfirmationDispatcher from 'dispatchers/confirmation-dispatcher'
import * as BrandAccountActions from 'actions/brand-account-actions'
import * as DialogActions from 'actions/dialog-actions'
import MapCreditCards from './paymentMethods/creditCards'
import MapBankAccounts from './paymentMethods/bankAccounts'
import PayoutSettingsModal from './payoutSettings/payout-settings-modal'

import {
  VERIFY_BANK_ACCOUNT,
  INCORRECT_VERIFICATION_AMOUNTS,
  CLOSED,
} from './payoutSettings/steps'

const mapStateToProps = ({ currentBrand, customer, brandAccount }) =>
  ({ currentBrand, customer, brandAccount })

const mapDispatchToProps = dispatch => ({
  brandAccountActions: bindActionCreators(BrandAccountActions, dispatch),
  dialogActions: bindActionCreators(DialogActions, dispatch),
})

class BillingPaymentMethods extends React.Component {
  static propTypes = {
    currentBrand: object.isRequired,
    customer: object.isRequired,
    brandAccount: object.isRequired,
    brandAccountActions: object.isRequired,
    dialogActions: object.isRequired,
    history: object.isRequired,
    match: object.isRequired,
    location: object.isRequired,
  }

  state = {
    modalStep: CLOSED,
  }

  componentDidMount() {
    const {
      brandAccountActions: { load },
      dialogActions: { openDialog },
      location,
    } = this.props

    // this loads brand stripe account
    load()

    if (location.search.includes('achModal=open')) {
      openDialog('achInfoModal')
    }
  }

  componentDidUpdate(prevProps) {
    const {
      brandAccount: { simpleVerifyId, simpleVerifySuccess, error },
      location,
      customer,
    } = this.props
    if (location.search.includes('achVerificationOpen') && prevProps.customer.loading && !customer.loading) {
      const url = URL.parse(location.search, true)
      const bankAccount = customer.bankAccounts && customer.bankAccounts.find(account =>
        account.id === url.query.achVerificationOpen)
      if (!bankAccount || bankAccount.status !== 'new') return
      setTimeout(() => this.verifyAccount(bankAccount), 1000)
    } else if (prevProps.brandAccount.error !== error && error && error.includes('do not match')) {
      this.toggleModal(INCORRECT_VERIFICATION_AMOUNTS)
    } else if (prevProps.brandAccount.simpleVerifyId && !simpleVerifyId && simpleVerifySuccess) {
      toastr.success('Your account has been verified.', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })

      AppDispatcher.load({
        model: 'customer',
        endpoint: '/customer',
      })
    }
  }

  goBack = () => {
    const { history } = this.props
    history.goBack()
  }

  makeDefault = item => {
    CurrentBrandDispatcher.makeDefaultSource(item)
  }

  editSource = item => {
    const { dialogActions: { openDialogWithPayload } } = this.props
    const modalMap = {
      card: 'creditCardInfoModal',
      bank_account: 'achInfoModal',
    }

    openDialogWithPayload(modalMap[item.object], {
      updating: true,
      item,
    })
  }

  deleteSource = item => {
    ConfirmationDispatcher.check({
      header: 'Heads up!',
      copy: 'Are you sure you want to delete this payment source?',
      confirmText: 'Delete',
      action: () => {
        CurrentBrandDispatcher.deleteSource(item)
        ConfirmationDispatcher.closeModal()
      },
    })
  }

  toggleModal = modalStep => {
    const {
      brandAccountActions: { clearError },
    } = this.props
    clearError()
    this.setState({ modalStep })
  }

  verifyAccount = item => {
    const {
      brandAccountActions: { updateModel },
    } = this.props
    updateModel({ simpleVerifyId: item.id })
    this.toggleModal(VERIFY_BANK_ACCOUNT)
  }

  renderCTAButton = item => {
    const { customer } = this.props

    const ctaMap = {
      card: {
        label: 'Make Default',
        action: this.makeDefault,
      },
      bank_account: {
        label: item.status === 'verified' ? 'Make Default' : 'Verify Account',
        action: item.status === 'verified' ? this.makeDefault : this.verifyAccount,
      },
    }

    if (customer.sourceLoading === item.id) {
      return <MaterialSpinner when />
    }

    return (
      <div>
        <Button
          style={{ marginRight: '-8px' }}
          theme={ButtonTheme}
          label={ctaMap[item.object].label}
          onClick={() => ctaMap[item.object].action(item)}
          flat
          primary
        />
      </div>
    )
  }

  renderPaymentMethods = () => {
    const {
      customer,
      brandAccount,
      brandAccountActions,
      dialogActions: { openDialog },
    } = this.props

    const { modalStep } = this.state

    return (
      <div>
        <H2 azure>Payment Methods</H2>
        <div className={Theme.mainComponent__subtitle}>
          <P multiline>An overview of your payment methods and their settings.</P>
        </div>

        {/* Credit Cards */}
        <div className={Theme.paymentMethod}>
          <div className={Theme.paymentMethod__section}>
            <Card className={Theme.paymentMethod__card}>
              <div className={Theme.paymentMethod__cardheader}>
                <H3 coral>
                  <small>Credit Cards</small>
                </H3>

                <Button
                  className={ButtonTheme.narrowWide__white}
                  label="Add Card"
                  onClick={() => openDialog('creditCardInfoModal')}
                  raised
                />
              </div>
            </Card>
            <MapCreditCards
              customer={customer}
              editSource={this.editSource}
              deleteSource={this.deleteSource}
              renderCTAButton={this.renderCTAButton}
            />
          </div>

          {/* Bank Accounts */}
          <div className={Theme.paymentMethod__container}>
            <Card className={Theme.paymentMethod__card}>
              <div className={Theme.paymentMethod__cardheader}>
                <H3 coral>
                  <small>Bank Accounts</small>
                </H3>
                <Button
                  className={ButtonTheme.narrowWide__white}
                  label="Add Account"
                  onClick={() => openDialog('achInfoModal')}
                  raised
                />
              </div>
            </Card>
            <MapBankAccounts
              customer={customer}
              editSource={this.editSource}
              deleteSource={this.deleteSource}
              renderCTAButton={this.renderCTAButton}
            />
          </div>
        </div>

        <EnterCreditCardInfoModal />
        <EnterACHBankAccountInfoModal />
        <PayoutSettingsModal
          modalStep={modalStep}
          toggleModal={this.toggleModal}
          brandAccount={brandAccount}
          brandAccountActions={brandAccountActions}
        />
      </div>
    )
  }

  render() {
    const {
      brandAccount: { loading },
      match: { params: { location } },
    } = this.props

    const locationMap = {
      billing: 'Billing',
    }

    if (location) {
      return (
        <div style={{ paddingTop: '29px' }}>
          { loading && (<Spinner />) }
          <div className={Theme.returnNavigation} onClick={this.goBack}>
            <img src="images/icons/collapse-arrow.svg" className={Theme.returnNavigation__closebutton} />
            <H4>{locationMap[location]}</H4>
          </div>

          <div className={Theme.mainComponent__container}>
            {this.renderPaymentMethods()}
            <Button
              style={{ marginTop: '32px', marginLeft: '910px' }}
              className={ButtonTheme.blueButton}
              theme={ButtonTheme}
              label="Done"
              onClick={this.goBack}
              flat
            />
          </div>
        </div>
      )
    }

    return (
      <div style={{ paddingTop: '32px' }}>
        { loading && (<Spinner />) }
        {this.renderPaymentMethods()}
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingPaymentMethods))
