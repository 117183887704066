import React, { useState } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

import { P, Small } from 'visual-components/util/texts'

import ToolTip from 'util/components/tooltip'
import FormlessField from 'visual-components/util/inputs/FormlessField'
import InputValidator from 'util/input-validator'
import SMSEditor from './SMSEditor'

import ComplianceBanner from './ComplianceBanner'

const styles = {
  customSMS: {
    maxHeight: 'none',
  },
  space: {
    marginBottom: 8,
  },
  doubleSpace: {
    marginBottom: 32,
  },
  mergeVarButtonContainer: {
    margin: '24px 0 11px',
  },
  unsubscribeText: {
    fontStyle: 'italic',
  },
  reminderContainer: {
    marginTop: -16,
  },
  reminderHighlight: {
    color: 'red',
  },
}

const COUPON_TOOLTIP = 'For the most accurate conversion tracking, using a unique coupon code per message or campaign is highly recommended.'

function SMSForm(props) {
  const {
    classes: css, message, onChange, onError, isWelcomeMessage,
  } = props

  const {
    loading,
    custom_sms_message,
    coupon_code: coupon,
  } = message

  if (loading) return null

  const containsCoupon = /\*\|COUPON(_CODE)?\|\*/g.test(custom_sms_message)

  return (
    <div>
      <P><em>Message</em></P>
      <div className={css.space} />
      <SMSEditor
        message={message}
        setValues={values => onChange(values)}
      />
      <div className={css.space} />
      <div className={css.space} />
      <ComplianceBanner />
      <div className={css.doubleSpace} />
      <P>
        <em>Coupon Code</em>
        <ToolTip title={COUPON_TOOLTIP} useNew />
      </P>
      <div className={css.space} />
      <Small>
        Create a unique coupon code on your own (i.e. Shopify) and insert here.
      </Small>
      <div className={css.space} />
      <div className={css.inputWrapper}>
        <FormlessField
          name="coupon_code"
          value={coupon}
          formatter={value => value && value.replace(/[^0-9A-Za-z!%]/g, '').toUpperCase()}
          dirty={message.dirty.coupon_code}
          error={containsCoupon && message.errorFields.coupon_code}
          onChange={coupon_code => onChange({ coupon_code })}
          onError={onError}
          validations={containsCoupon && !coupon ? [InputValidator.Required] : []}
          placeholder="SAMPLECODE"
        />
      </div>
      {isWelcomeMessage && (
        <div className={css.reminderContainer}>
          <Small multiline>
            <i>
              <span className={css.reminderHighlight}>Reminder: </span>
              Ensure your welcome offer matches the offer shown on your sweepstakes entry form.
            </i>
          </Small>
        </div>
      )}
    </div>
  )
}

SMSForm.propTypes = {
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isWelcomeMessage: PropTypes.bool,
}

SMSForm.defaultProps = {
  isWelcomeMessage: false,
}

export default React.memo(injectSheet(styles)(SMSForm))
