
var model = 'campaignHistory';
import _ from 'lodash'

var defaultState =  {
  rid: model,
  campaigns: [],
  totals: {
  },
  sortOptions: {
    impressions: null,
    visits: null,
    // conversions: null,
    entries: null,
    postentries: null,
    postentryRate: null,
    // conversionRate: null,
    entryRate: null,
    newEmails: null,
    newEmailRate: null
  },
  selectOption: 'entries'
}

var CampaignHistoryReducer = function (state, action) {
  if (action.model !== model) {
    return state || defaultState;
  }
  const actions = {
    UPDATE_SORT: function () {
      var campaigns = _.sortBy(state.campaigns, action.data.term);
      campaigns = campaigns.reverse();
      return _.extend({}, state, { campaigns: campaigns, selectOption: action.data.term });
    },
    LOAD: function () {

      _.each(state.sortOptions, function (v, key) {
        state.sortOptions[key] = LANGUAGE.brandDashboard.campaignHistory.tableOptions[key];
      });
      var totals = {
        name: LANGUAGE.brandDashboard.campaignHistory.totals,
        impressions: 0,
        visits: 0,
        entries: 0,
        // conversions: 0,
        postentries: 0,
        newEmails: 0
      }
      var campaigns = _.clone(store.getState().brandCampaigns.pastCampaigns);
      campaigns = _.map(campaigns, function (campaign) {
        campaign.invites = [];
        _.each(['impressions', 'visits', 'entries', /*'conversions', */'postentries', 'newEmails'], function (metricType) {
          totals[metricType] += parseInt(campaign[metricType]);
        });

        campaign.entryRate = campaign.entries / campaign.visits;

        campaign.postentryRate = campaign.postentries / campaign.entries;
        // campaign.conversionRate = campaign.conversions / campaign.entries;
        campaign.newEmailRate = campaign.newEmails / campaign.entries;
        

        _.each(campaign.partners, function (partnership, invitee_id) {
          if (!partnership || typeof partnership === 'string') {
            return;
          }
          
          partnership.entryRate = partnership.entries / partnership.visits;
          
          partnership.postentryRate = partnership.postentries / partnership.entries;
          // partnership.conversionRate = partnership.conversions / partnership.entries;
          partnership.newEmailRate = partnership.newEmails / partnership.entries;

          _.each(['postentryRate', /*'conversionRate',*/ 'entryRate', 'newEmailRate'], function (metricType) {
            if (isNaN(partnership[metricType])) {
              partnership[metricType] = 0;
            }
          });

          campaign.invites.push(partnership);
        });
        campaign.entryRate = campaign.entries / campaign.visits;

        campaign.postentryRate = campaign.postentries / campaign.entries;
        // campaign.conversionRate = campaign.conversions / campaign.entries;
        campaign.newEmailRate = campaign.newEmails / campaign.entries;
        _.each(['postentryRate', /*'conversionRate',*/ 'entryRate', 'newEmailRate'], function (metricType) {
          if (isNaN(campaign[metricType])) {
            campaign[metricType] = 0;
          }
        });
        campaign.open = false;
        return campaign;
      })

      totals.entryRate = totals.entries / totals.visits;
      totals.postentryRate = totals.postentries / totals.entries;
      // totals.conversionRate = totals.conversions / totals.entries;
      totals.newEmailRate = totals.newEmails / totals.entries;
      _.each(['postentryRate', /*'conversionRate',*/ 'entryRate', 'newEmailRate'], function (metricType) {
        if (isNaN(totals[metricType])) {
          totals[metricType] = 0;
        }
      });

      campaigns = _.sortBy(campaigns, state.selectOption);
      campaigns = campaigns.reverse();

      return _.extend({}, state, { campaigns: campaigns, totals: totals, sortOptions: state.sortOptions });
    }
  };

  const item = actions[action.type] || function () {
    return state || defaultState;
  };

  return item();
}

export default CampaignHistoryReducer;
