import React from 'react';
import _ from 'lodash'

import PropTypes from 'prop-types';
import COLOURS from 'util/colours';

import { IconMenu, MenuItem } from 'react-toolbox/lib/menu';

import BrowserHistoryDispatcher from 'dispatchers/browser-history-dispatcher';
import menuTheme from 'css/themes/Menu.css'

const themes = {
  Menu: menuTheme,
}

class MyCampaignActions extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    var { 
      actions
    } = this.props;

    let extras = actions.splice(3);

    let icon = (
      <img src={ '/images/util/more-icon-button.svg' }
        style={{
          marginTop: '-4px',
          marginLeft: '-8px'
      }}/>
    )

    var actionsList = _.map(actions, function(action, idx) {
      return (
        <p key={ idx } 
          onClick={ action.onClick || function() {
            if (action.target == '_blank') {
              window.open(action.href)
            } else {
              BrowserHistoryDispatcher.push(action.href) 
            }
          }}
          style={{
            fontFamily: 'Larsseit-Medium',
            fontSize: '14px',
            lineHeight: '14px',
            color: COLOURS.azure,
            marginRight: '24px',
            cursor: 'pointer'
        }}>
          { action.label }
        </p>
      )
    })

    var extrasList = _.map(extras, function(action, idx) {
      return (
        <MenuItem key={ actionsList.length + idx } 
          caption={ action.label }
          onClick={ action.onClick || function() { BrowserHistoryDispatcher.push(action.href) } }
          theme={ themes.Menu }
        />
      )
    })

    return(
      <div>
        <div style={{
          display: 'inline-flex',
          marginTop: extrasList.length ? 0 : '9px',
          marginBottom: extrasList.length ? 0 : '7px'
        }}>
          { actionsList }
        </div>
        {
          extrasList.length ?
          <div style={{
            display: 'inline-flex'
          }}>
            <IconMenu icon={ icon } position='topLeft' theme={ themes.Menu }>
              { extrasList }
            </IconMenu>
          </div>
          : null
        }
      </div>
    )
  }
}

MyCampaignActions.propTypes = {
  actions: PropTypes.array.isRequired
};

export default MyCampaignActions;
