import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { object } from 'prop-types'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Checkbox } from 'react-toolbox'
import { H3, H4 } from 'visual-components/util/texts'
import IntegrationsMap from 'visual-components/integrations/integrations-map'
import FullscreenIntegrationModal from 'visual-components/integrations/fullscreen-integration-modal'
import Badge from 'visual-components/util/badge'
import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'
import IntegrationTheme from 'css/themes/integrations.css'
import CheckboxTheme from 'css/themes/Checkbox.css'
import COLOURS from 'util/colours'
import * as IntegrationsActions from 'actions/integrations-actions'

const mapStateToProps = ({ espIntegrations }) =>
  ({ espIntegrations })

const mapDispatchToProps = dispatch => ({
  integrationsActions: bindActionCreators(IntegrationsActions, dispatch),
})

class IntegrationWarningModal extends React.Component {
  static propTypes = {
    espIntegrations: object.isRequired,
    integrationsActions: object.isRequired,
  }

  state = {
    checked: {},
  }

  handleChange = _.debounce((key, val) => {
    this.setState(prevState => ({
      checked: Object.assign({}, prevState.checked, {
        [key]: val,
      }),
    }))
  }, 100)

  closeModal = () => {
    const {
      espIntegrations: { warningModal },
      integrationsActions: { updateAttrPersist, clearWarningModal },
    } = this.props
    const { checked } = this.state

    Object.keys(checked).forEach(id => {
      if (checked[id]) {
        updateAttrPersist('campaign', id, warningModal.data)
      }
    })

    clearWarningModal({ warningModal: {} })
  }

  renderIntegrationRows = () => {
    const {
      espIntegrations: { warningModal },
    } = this.props

    const {
      integrations,
    } = warningModal

    const { checked } = this.state

    return integrations.map(integration => (
      <tr
        className={checked[integration.id] ? IntegrationTheme.selectedRow : ''}
        onClick={() => this.handleChange(integration.id, !checked[integration.id])}
      >
        <td className={IntegrationTheme.firstTd}>
          { integration.campaignName }
        </td>
        <td className={IntegrationTheme.secondTd}>
          { `${moment(integration.startDate).format('ll')} - ${moment(integration.endDate).format('ll')}` }
        </td>
        <td className={IntegrationTheme.thirdTd}>
          { moment().isAfter(integration.startDate)
            ? <Badge label="Live" color={COLOURS.coral} />
            : <span style={{ paddingLeft: '16px' }}>-</span>
          }
        </td>
        <td className={IntegrationTheme.checkboxTd}>
          <Checkbox
            key={integration.id}
            onChange={() => this.handleChange(integration.id, !checked[integration.id])}
            checked={checked[integration.id]}
            className={CheckboxTheme.boldLabel}
            theme={CheckboxTheme}
          />
        </td>
      </tr>
    ))
  }

  render() {
    const {
      espIntegrations: { espIntegration, warningModal },
    } = this.props

    const {
      expand,
      integration,
      integrations,
    } = warningModal

    const { checked } = this.state

    if (!expand || !integrations || !integrations.length) {
      return null
    }

    const integrationItem = IntegrationsMap.find(i => i.key === integration.key)

    const utilityFunctions = {
      closeModal: this.closeModal,
    }

    return (
      <FullscreenIntegrationModal
        subsection="warning-modal"
        integration={integrationItem}
        espIntegration={espIntegration}
        utilityFunctions={utilityFunctions}
      >
        <div className={IntegrationTheme.modalSubtitle}>
          <H3 coral>
            <small>Integration Setup</small>
          </H3>
        </div>

        <div className={IntegrationTheme.modalPara}>
          <H4 multiline>
            {`Heads up! These changes will be applied automatically for current or upcoming campaigns that have not yet been integrated. However, it looks like you have current or upcoming campaigns with ${integrationItem.integration_type}. Select which of these campaigns you would like the changes to be applied on.`}
          </H4>
        </div>

        <div className={IntegrationTheme.tableContainer}>
          <table className={`${IntegrationTheme.table} ${IntegrationTheme.warningTable}`}>
            <thead>
              <tr className={IntegrationTheme.noSideBorder}>
                <th className={IntegrationTheme.firstTd}>
                  Campaign Name
                </th>
                <th className={IntegrationTheme.secondTd}>
                  Date Range
                </th>
                <th className={IntegrationTheme.thirdTd}>
                  Status
                </th>
                <th className={IntegrationTheme.checkboxTd}>
                  Update?
                </th>
              </tr>
            </thead>
          </table>
          <div className={IntegrationTheme.tableScroll}>
            <table className={`${IntegrationTheme.table} ${IntegrationTheme.warningTable}`}>
              <tbody>
                {this.renderIntegrationRows()}
              </tbody>
            </table>
          </div>
        </div>

        <div className={IntegrationTheme.modalButtonGroup}>
          <ModalButtonGroup
            confirm={this.closeModal}
            confirmText={Object.keys(checked).some(k => checked[k]) ? 'Update' : 'Skip'}
            hideLine
            canSave
          />
        </div>
      </FullscreenIntegrationModal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationWarningModal)
