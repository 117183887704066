import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AnalyticsDispatcher from 'dispatchers/analytics-dispatcher'
import AppDispatcher from 'dispatchers/app-dispatcher'

import ExplorePageTitle from 'visual-components/shared/explore/explore-page-title'

import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'

import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'

import { H3, P } from 'visual-components/util/texts'
import COLOURS from 'util/colours'
import OverviewBar from 'visual-components/analytics/util/overview-bar'

import CampaignAnalyticsResultsContainer from 'visual-components/analytics/campaign/CampaignAnalyticsResultsContainer'
import SearchActionsFactory from 'actions/search-actions-factory'
import SearchCampaignAnalyticsConfig from 'actions/search-config/search-campaign-analytics-config'
import SearchCampaignAggregationsConfig from 'actions/search-config/search-campaign-aggregations-config'
import EventTrackingDispatcher from 'dispatchers/event-tracking-dispatcher'

import DataIntegrationSuggestion from 'components/dialogs/data-integration-suggestion'
import AggregatesTop from './overviewMain/aggregates-top'
import SocialMetrics from './overviewMain/socialMetrics'
import CtaMetrics from './overviewMain/CtaMetrics'
import GeoDistribution from './overviewMain/GeoDistribution'
import Followers from './overviewMain/Followers'
import DashboardConversion from './overviewMain/DashboardConversion'
import MobileMarketingSummary from './overviewMain/MobileMarketingSummary'

const mapStateToProps = ({
  currentBrand,
  analyticsDashboard,
  campaignAnalyticsSearch,
  campaignAggegrationsSearch,
  dataIntegrations,
}) =>
  ({
    analyticsDashboard,
    campaignAnalyticsSearch,
    campaignAggegrationsSearch,
    dataIntegrations,
    featureFlags: currentBrand.features,
  })

const extendQuery = props => {
  const body = SearchCampaignAnalyticsConfig.extendQuery(props)

  body.query.bool.filter.push({
    range: {
      endDate: { gte: 'now' },
    },
  })

  return body
}

const mapDispatchToProps = dispatch => ({
  searchActions: bindActionCreators(
    SearchActionsFactory({ ...SearchCampaignAnalyticsConfig, extendQuery }),
    dispatch
  ),
  campaignAggegrationActions: bindActionCreators(
    SearchActionsFactory({ ...SearchCampaignAggregationsConfig }),
    dispatch
  ),
})

class DashboardAnalytics extends Component {
  componentDidMount() {
    this.props.searchActions.applySearch()
    this.props.campaignAggegrationActions.applySearch()
  }

  exportCsv = () => {
    const { analyticsDashboard } = this.props

    const {
      dashboardEmailsAcquired,
      dashboardSocialFollower,
      dashboardSocialMetrics,
      dashboardSocialActions,
      dashboardConversionDetails,
    } = analyticsDashboard

    const emailsAcquiredValue = Array.isArray(dashboardEmailsAcquired[0].raw[0])
      ? dashboardEmailsAcquired[0].raw[1].concat(
          dashboardEmailsAcquired[0].raw[0]
        )
      : dashboardEmailsAcquired[0].raw

    const socialActionsValue = Array.isArray(dashboardSocialActions[0].raw[0])
      ? dashboardSocialActions[0].raw[1].concat(
          dashboardSocialActions[0].raw[0]
        )
      : dashboardSocialActions[0].raw

    const socialFollowerSummaryValue = dashboardSocialFollower.data.map(
      data => ({ network: data.title, followers: data.y })
    )
    const socialMetricsValue = []

    dashboardSocialMetrics.categories.forEach((category, idx) => {
      dashboardSocialMetrics.socialItems.forEach(item => {
        socialMetricsValue.push({
          date: category,
          category: item.title,
          value: item.data[idx],
      })
    })
    })

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'exportAnalyticsModal',
      data: {
        active: true,
        filename: 'dashboard_analytics.csv',
        metrics: [
          {
            label: 'Main Chart - Emails Acquired',
            value: emailsAcquiredValue,
          },
          {
            label: 'Main Chart - Social Actions',
            value: socialActionsValue,
          },
          {
            label: 'Main Chart - Total Campaigns Launched',
            value: analyticsDashboard.dashboardCampaignsLaunched.csv,
          },
          {
            label: 'Main Chart - Total Partnerships',
            value: analyticsDashboard.dashboardNewPartnerships.csv,
          },
          {
            label: 'Geographic Overview',
            value: analyticsDashboard.dashboardTopRegions,
          },
          {
            label: 'Social Follower Summary',
            value: socialFollowerSummaryValue,
          },
          {
            label: 'Social Metrics',
            value: socialMetricsValue,
          },
          {
            label: 'Custom CTA',
            value: analyticsDashboard.dashboardCustomCTA.csv,
          },
          {
            label: 'Conversions',
            value: dashboardConversionDetails.csv
          }
        ],
      },
    })

    EventTrackingDispatcher.track(
      'appAnalytics_export_exportButton_mainExportButton'
    )
  }

  toggleDemoData = () => {
    const user = store.getState().profile

    AppDispatcher.http({
      url: '/profile',
      method: 'PUT',
      data: {
        seenDemo: !user.seenDemo,
      },
    })

    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'profile',
      data: {
        seenDemo: !user.seenDemo,
      },
    })

    AnalyticsDispatcher.loadDefaultDashboardAnalytics(false)
  }

  render() {
    const {
      analyticsDashboard,
      campaignAnalyticsSearch,
      campaignAggegrationsSearch,
      dataIntegrations,
      featureFlags: {
        block_sms,
      },
    } = this.props
    const canViewSms = !block_sms

    const { fakeData, noCampaigns, dashboardConversionTotals, dashboardSmsSubscribersTotals } = analyticsDashboard

    const { results, loading, error, filtered } = campaignAnalyticsSearch

    const {
      total,
      allVisits,
      partners: {
        currentBrand: {
          allNTF,
          totalEntriesDelivered,
        }
      },
    } = campaignAggegrationsSearch

    const overviewBarMetrics = [
      {
        label: 'total campaigns',
        value: total,
      },
      {
        label: 'total visits',
        value: allVisits.value,
      },
      {
        label: 'verified entries',
        value: totalEntriesDelivered.value,
      },
      {
        label: 'total NTF',
        value: allNTF.value,
      },
      ...(
        canViewSms
          ? [{
            label: 'sms signups',
            value: dashboardSmsSubscribersTotals.data && dashboardSmsSubscribersTotals.data[0].sum,
          }]
          : []
      ),
      {
        label: 'total purchases',
        value: dashboardConversionTotals.count,
        hideZeros: true,
        tooltip: 'Total purchases attributed to DojoMojo campaign entries'
      },
      {
        label: 'sales generated',
        value: dashboardConversionTotals.sum,
        isDollar: true,
        hideZeros: true,
        tooltip: 'Total sales generated attributed to DojoMojo campaign entries'
      },
    ]

    return (
      <Grid>
        {fakeData ? (
          <Row
            start="xs"
            style={{
              background: COLOURS.iceBlue,
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
              marginTop: '16px',
              height: '80px',
              display: 'flex',
              padding: '16px 20px',
              alignItems: 'center',
            }}
          >
            <img src="images/icons/tool-tip-jumbo.svg" />
            <div style={{ paddingLeft: '20px', width: '778px' }}>
              <div style={{ paddingTop: '2px', paddingBottom: '8px' }}>
                <H3>
                  <small>You Are Viewing Demo Data</small>
                </H3>
              </div>
              <P multiline>
            This data is displayed for demo purposes only and may not
            reflect the behavior of your campaigns.
              </P>
            </div>
            {!noCampaigns ? (
              <Button
                onClick={this.toggleDemoData}
                label="See My Data"
                primary
                theme={ButtonTheme}
                className={ButtonTheme.blueButton}
                raised
              />
            ) : null}
          </Row>
        ) : null}
        <Row style={{ marginTop: '32px', marginBottom: '16px' }}>
          <Col xs>
            <ExplorePageTitle
              title="Overview"
              action={this.exportCsv}
              actionLabel="Export CSV"
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '24px' }}>
          <P multiline>
            A summary of your most important metrics on DojoMojo. &nbsp;
            {!fakeData && !noCampaigns ? (
              <a onClick={this.toggleDemoData}>See Demo Data.</a>
            ) : null}
          </P>
        </Row>
        <Row style={{ marginBottom: '24px'}}>
          <OverviewBar
            metrics={overviewBarMetrics}
            checkDataIntegrations={true}
            eventTrackingLocation={'dashboardAnalyticsTopBar'}
            canViewSms={canViewSms}
          />
        </Row>
        {!fakeData && results && results.length ? (
              <Row>
                <CampaignAnalyticsResultsContainer
                  campaigns={results || []}
                  loading={loading}
                  error={error}
                  filtered={filtered}
                  display="grid"
                />
              </Row>
        ) : null}
        <Row>
          <Col style={{ width: '646px' }}>
            <div>
              <AggregatesTop
                data={this.props.analyticsDashboard}
                hideTabs={!canViewSms ? ['dashboardSmsSubscribers'] : []}
              />
              <DashboardConversion />
              <SocialMetrics />
              <CtaMetrics />
            </div>
          </Col>
          <Col style={{ width: '310px', marginLeft: '16px' }}>
            { !!canViewSms && <MobileMarketingSummary /> }
            <Followers />
            <GeoDistribution />
          </Col>
        </Row>
        <DataIntegrationSuggestion left={-30} />
      </Grid>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAnalytics)
