import validator from 'validator'
import { CompositeDecorator } from 'draft-js'

import EditorElementsWrapper from './EditorElementsWrapper'

// TODO: Confirm this
const COUPON_REGEX = /\s?(?=.*[A-Z])(?=.*[0-9])?([A-Z0-9!%]+){3,}\s/g

const TAG_REGEX = /(\*\|)(BRAND_NAME|COUPON_CODE|COUPON)(\|\*)/g

function detectLink(contentBlock, callback) {
  const text = contentBlock.getText()

  let textCursor = 0

  text.split(/\s|\n/).forEach(word => {
    if (
      validator.isURL(word, {
        require_host: true,
        require_protocol: true,
        require_valid_protocol: true,
      })
    ) {
      const start = text.indexOf(word, textCursor)
      const end = start + word.length

      callback(start, end)
    }

    textCursor += word.length + 1
  })
}

function detect(REGEX) {
  return function matchDetector(contentBlock, callback) {
    const text = contentBlock.getText()
    let matchArray

    // eslint-disable-next-line no-cond-assign
    while ((matchArray = REGEX.exec(text)) !== null) {
      const { 0: match, index: start } = matchArray

      callback(start, start + match.length)
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const decorators = new CompositeDecorator([
  {
    strategy: detect(TAG_REGEX),
    component: EditorElementsWrapper,
    props: {
      type: 'tag',
    },
  },
  {
    strategy: detect(COUPON_REGEX),
    component: EditorElementsWrapper,
    props: {
      type: 'coupon',
    },
  },
  {
    strategy: detectLink,
    component: EditorElementsWrapper,
    props: {
      type: 'link',
    },
  },
])
