import React, { Component } from 'react'
import propTypes from 'prop-types'
import { debounce } from 'lodash'
import * as metrics from 'util/metrics'
import COLOURS from 'util/colours'
import FormGroup from 'util/components/form-group'
import SimpleColorPicker from 'util/components/color-picker'
import injectSheet from 'react-jss'
import LanderSectionHeader from 'components/campaigns/builder/lander/lander-section-header'
import StyleChoices from '../../StyleChoices'
import InputContainer from '../InputContainer'

const landerSectionHeaderStyle = { marginBottom: '8px' }
const themeLanderSectionHeaderStyle = { marginBottom: '2px' }

const inputCheck = {
  marginBottom: '32px',
  height: '12px !important',
  display: 'block',
}

const input = {
  width: '12px',
  height: '12px',
  marginTop: '-10px',
  backgroundSize: '10px',
  backgroundPositionY: '2px',
}

const inputLabel = {
  fontFamily: 'Larsseit-Light',
  fontSize: '12px',
  lineHeight: '1',
  display: 'inline',
}

const styleTabTransitions = {
  preview: {
    width: 608,
    border: `solid 1px ${COLOURS.silver}`,
    padding: '16px 20px',
  },
  themeColors: {
    whiteSpace: 'nowrap',
    marginBottom: '28px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

class StyleTab extends Component {
  static propTypes = {
    allPartners: propTypes.array.isRequired,
    campaign: propTypes.object.isRequired,
    classes: propTypes.object.isRequired,
    customFonts: propTypes.array.isRequired,
    desktopVersion: propTypes.bool.isRequired,
    elements: propTypes.object.isRequired,
    isHost: propTypes.bool,
    termsLink: propTypes.string.isRequired,
    updateElementsAttr: propTypes.func.isRequired,
    updateAttr: propTypes.func.isRequired,
  }

  static defaultProps = {
    isHost: undefined,
  }

  track = debounce(data => {
    metrics.create('campaignManagerUpdatedFormFieldAttribute', {
      meta: data,
    })
  }, 500)

  updateFormStyles = (attr, value) => {
    const { updateElementsAttr } = this.props
    this.track({ attr })
    updateElementsAttr(attr, value)
  }

  render() {
    const {
      campaign,
      classes: css,
      updateAttr,
      isHost,
      elements,
      updateElementsAttr,
      customFonts,
      allPartners,
      desktopVersion,
      termsLink,
    } = this.props

    const campaignInputs = campaign.inputs
    const allowed = ['email']
    const onlyEmail = Object.keys(campaignInputs)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = campaignInputs[key]
        return obj
      }, {})

    return (
      <div>
        <LanderSectionHeader
          style={{ ...landerSectionHeaderStyle, width: '608px' }}
          headerText="Entry Field Lables"
          subHeaderText="Select whether you would like the entry field labels to appear within the fields or above them."
        />
        <FormGroup
          updateAttr={updateElementsAttr}
          editable={isHost}
          model={elements}
          type="checkbox"
          attrName="inputLabels"
          label="Show labels above entry fields"
          styles={[inputCheck]}
          inputStyles={input}
          labelStyles={[inputLabel]}
        />
        <LanderSectionHeader
          style={themeLanderSectionHeaderStyle}
          headerText="Theme Colors"
        />
        <div
          className={css.themeColors}
        >
          <SimpleColorPicker
            attr="formTextColor"
            updateAttr={this.updateFormStyles}
            model={elements.styles.fonts}
            label="Text Color"
          />
          <SimpleColorPicker
            attr="formContainerColor"
            updateAttr={this.updateFormStyles}
            model={elements.styles.fonts}
            label="Container Color"
          />
          <SimpleColorPicker
            attr="entryButtonColor"
            updateAttr={updateElementsAttr}
            model={elements.styles.fonts}
            label="Button Color"
          />
          <SimpleColorPicker
            attr="entryButtonText"
            updateAttr={updateElementsAttr}
            model={elements.styles.fonts}
            label="Button Text color"
          />
        </div>

        <LanderSectionHeader
          style={landerSectionHeaderStyle}
          headerText="Button"
        />

        <StyleChoices
          attr="entryButtonText"
          updateAttr={updateElementsAttr}
          elements={elements}
          styles={elements.styles}
          customFonts={customFonts}
          notScrolling
        />

        <LanderSectionHeader style={landerSectionHeaderStyle} headerText="Preview" />

        <div className={css.preview}>
          <InputContainer
            campaign={campaign}
            inputs={onlyEmail}
            includeCustomField={campaign.includeCustomField}
            customFieldName={campaign.customFieldName}
            additionalTermsDetail=""
            entryButtonText={elements.entryButtonText}
            formStyles={elements.styles.fonts}
            preCheck={campaign.preCheck}
            allPartners={allPartners}
            perBrandTerms={false}
            styles={elements.styles}
            termsLink={termsLink}
            elements={elements}
            darkTemplateTheme={false}
            desktopVersion={desktopVersion}
            updateAttr={updateAttr}
            removeCustomFields
          />
        </div>
        <br />
      </div>
    )
  }
}

export default injectSheet(styleTabTransitions)(StyleTab)
