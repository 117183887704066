import React, { Component } from 'react'
import Text from 'util/components/text'
import { Form, Input, Select, Submit, Validations } from 'visual-components/util/form'
import AppDispatcher from 'dispatchers/app-dispatcher'
const { isNum } = Validations

class MediaMarketChangeStatus extends Component {
  state = {
    dealId: 0,
    status: '',
  }

  handleChange = e => {
    this.setState(e)
  }

  handleSubmit = () => {
    const data = {...this.state}
    AppDispatcher.http({
      url: '/qa-media-market-changeStatus',
      method: 'POST',
      data,
      success() {
        toastr.success('Change successful', null, { timeOut: 2000, positionClass: 'toast-bottom-center' })
      },
      error() {
        toastr.error('Cannot Change Status - confirm correct Deal ID', null, { timeOut: 3000, positionClass: 'toast-bottom-center' })
      }
    })
  }

  statusOptions = [
    {label: 'Accepted', value: 'accepted'},
    {label: 'Pending', value: 'pending'},
    {label: 'Live', value: 'live'},
    {label: 'Completed', value: 'completed'},
    {label: 'Declined', value: 'declined'},
    {label: 'Unfulfilled', value: 'unfulfilled'},
  ]

  render() {
    return(
      <div className="row">
        <div className="col-xs-5" style={{
          marginTop: '50px',
          marginBottom: '50px',
          paddingTop: '15px',
          paddingBottom: '15px',
          marginLeft: '50px',
          boxShadow: '0px 1px 4px #c0c0c0'
        }}>

        <Text.dojoH2Bold>
          Change Media Market Deal Status
        </Text.dojoH2Bold>

        <Form
          updateModel={ (e) => this.handleChange(e) }
          handleSubmit={ this.handleSubmit }
        >
          <Input
            name="dealId"
            label="Deal ID"
            placeholder="*required"
            type="number"
            required
            validations={[isNum]}
          />

          <Select
            name="status"
            label="Select Status"
            options={ this.statusOptions }
          />

          <Input
            name="target_url"
            sublabel="Do this for accepted, completed"
            label="Enter tracking link"
            placeholder="http://hello.com"
            type="string"
          />

          <Submit
            label="Change Status"
          />
        </Form>

        </div>
      </div>
    )
  }
}


export default MediaMarketChangeStatus
