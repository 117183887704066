import React from 'react'
import ShareOpportunityCommon from 'visual-components/opportunityProfile/share-opportunity-common'

import Dialog from 'react-toolbox/lib/dialog'
import { P, H4 } from 'visual-components/util/texts'

import ModalButtonGroup from 'visual-components/util/buttons/ModalButtonGroup'

const themes = {
  Dialog: require('css/themes/dialogs/formModal.css'),
}

const ShareOpportunityModal = ({ opportunity, handleToggle, active }) =>
  !opportunity ? null : (
    <Dialog
      theme={themes.Dialog}
      active={active}
      onEscKeyDown={handleToggle}
      onOverlayClick={handleToggle}
      title={'Share Your Opportunity'}
    >
      <div
        style={{
          marginBottom: '24px',
        }}
      >
        <H4 multiline>
          Share your opportunity to increase possible responses.
        </H4>
      </div>

      <div style={{ marginBottom: '24px' }}>
        <ShareOpportunityCommon
          opportunity={opportunity}
          render={({ options, url }) =>
            options.map((option, idx) => (
              <div
                onClick={option.onClick}
                data-clipboard-text={url}
                className={option.key === 'link' ? 'copy-link' : ''}
                key={idx}
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  marginRight: idx !== options.length - 1 ? '49px' : 0,
                }}
              >
                <img
                  src={`images/social_icons/share-${option.key}-icon.svg`}
                  style={{
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                />
                <div
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <P>
                    <i>{option.label}</i>
                  </P>
                </div>
              </div>
            ))
          }
        />
      </div>

      <ModalButtonGroup
        confirm={handleToggle}
        confirmText={'Close'}
        hideLine
        canSave
      />
    </Dialog>
  )

export default ShareOpportunityModal
