
export default {
  'introducing-the-dojo-mojo-sms-platform': {
    fileSrc: 'https://mailchi.mp/dojomojo.com/introducing-sms-marketing',
  },
  'achieve-unparalleled-returns-through-mobile-acquisition': {
    fileSrc: 'https://dojo-datablog.s3-us-west-2.amazonaws.com/wp-content/uploads/2020/04/09103907/Powerful-Insights-into-SMS-Marketing-Guide_04_2020.pdf',
  },
  'before-you-join-a-campaign': {
    fileSrc: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24224002/Before-You-Join-a-Campaign.pdf',
  },
  'email-deliverability-guide': {
    fileSrc: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24224021/Email-Deliverability-Guide-1.pdf',
  },
  'get-started-on-dojo-mojo': {
    fileSrc: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24224025/Getting-Started-on-DojoMojo.pdf',
  },
  'how-to-create-email-drip-campaign': {
    fileSrc: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24224029/How-to-Create-an-Email-Drip-Campaign-2.pdf',
  },
  'partner-checklist': {
    fileSrc: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24224032/Partner-Campaign-Checklist.pdf',
  },
  'the-value-of-hosting': {
    fileSrc: 'http://s3-us-west-2.amazonaws.com/dojo-datablog/wp-content/uploads/2019/01/24224018/DojoMojo_ValueofHosting-_08-2018.pdf',
  },
}
