import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import moment from 'moment'
import { Button } from 'react-toolbox/lib/button'
import ButtonTheme from 'css/themes/Buttons.css'
import Grid from 'visual-components/util/no-padding-grid/grid'
import Row from 'visual-components/util/no-padding-grid/row'
import Col from 'visual-components/util/no-padding-grid/col'
import {
  H2, H3, H6, P,
} from 'visual-components/util/texts'
import CCPAComplianceTheme from 'css/themes/ccpa-compliance.css'
import * as DataDisclosurePolicyActions from 'actions/data-disclosure-policy-actions'
import UploadCCPAPolicyModal from 'visual-components/brandSettings/upload-ccpa-policy-modal'

const stateToProps = ({ currentBrand, dataDisclosurePolicy }) => ({
  currentBrand, dataDisclosurePolicy,
})

const dispatchToProps = dispatch => ({
  dataDisclosurePolicyActions: bindActionCreators(DataDisclosurePolicyActions, dispatch),
})

class Compliance extends Component {
  static propTypes = {
    currentBrand: PropTypes.object.isRequired,
    dataDisclosurePolicy: PropTypes.object,
    dataDisclosurePolicyActions: PropTypes.object.isRequired,
  }

  static defaultProps = {
    dataDisclosurePolicy: null,
  }

  componentDidMount() {
    const { dataDisclosurePolicyActions } = this.props
    dataDisclosurePolicyActions.loadPolicy()
  }

  render() {
    const {
      currentBrand,
      dataDisclosurePolicy,
      dataDisclosurePolicyActions,
    } = this.props

    return (
      <React.Fragment>
        <div style={{ paddingTop: '32px' }}>
          <Helmet>
            <title>Compliance</title>
          </Helmet>
          <Grid>
            <Row style={{ marginBottom: '24px' }}>
              <Col xs={6}>
                <H2 azure>
                  Compliance
                </H2>
              </Col>
            </Row>
            <Row style={{ marginBottom: '8px' }}>
              <Col xs>
                <H3 coral>
                  <small>CCPA Requests</small>
                </H3>
              </Col>
            </Row>
            <Row style={{ marginBottom: '16px' }}>
              <Col xs>
                <P>
                  Please use the link below to submit CCPA Requests to DojoMojo.
                </P>
              </Col>
            </Row>
            <Row>
              <Link to="/brand-ccpa-request">
                <Button
                  label="Submit CCPA Request"
                  className={`${ButtonTheme.raised} ${ButtonTheme.blueButton}`}
                  theme={ButtonTheme}
                />
              </Link>
            </Row>
          </Grid>
        </div>
        <UploadCCPAPolicyModal
          brand={currentBrand}
          policy={dataDisclosurePolicy}
          policyActions={dataDisclosurePolicyActions}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(Compliance))
