import { useEffect, useState, useRef } from 'react'
import Axios from 'axios'
import { v4 as uuid } from 'uuid'

import requestHeaders from 'request-config'
import useHasIntegration from 'util/hooks/useHasIntegration'
import useSmsVerify from '../mobile-marketing/hooks/useSmsVerify'

function checkCouponField(fields) {
  if ('coupon_code' in fields) {
    const { coupon_code: coupon } = fields
    const containsCoupon = /\*\|COUPON(_CODE)?\|\*/g.test(fields.custom_sms_message)

    if (containsCoupon && !coupon) return { coupon_code: true }
  }

  return false
}

// to match checkForValidShortUrlLink.js
function checkForInvalidShortUrlLink(messages) {
  const services = [
    'tinyurl\.com',
    'bit\.ly',
    'onelink\.to',
  ]
  // TODO: Figure out how to deal with rebrandly
  const regex = new RegExp(`^https?:\/\/(www\.)?(${services.join('|')})`)
  return messages.some(({ custom_sms_message: msg }) =>
    msg.split(' ').some(word => regex.test(word)))
}

const POST_ENTRY_INITIAL_STATE = {
  form_header: 'Thank you for entering',
  title: 'Unlock a Discount for',
  unlock_copy: 'to claim your discount',
  button_text: 'Get Your Discount!',
  button_color: {
    color: {
      color: '#0176c8',
      alpha: 100,
    },
  },
  button_text_color: {
    color: {
      color: '#ffffff',
      alpha: 100,
    },
  },
  image_url: null,
  image_link: null,
}

const SMS_SEND_INITIAL_STATE = {
  id: `main-${uuid()}`,
  custom_sms_message: '*|BRAND_NAME|*: This is a sample text message. Add a *|COUPON_CODE|* below and paste/shorten your links.',
  image_url: null,
  img_obj: null,
  url_link: null,
  coupon_code: null,
  status: 'draft',
}

const COMMON_STATE_PROPS = {
  isEditing: false,
  dirty: {},
  errorFields: {},
}

const FOLLOWUP_PROPS = {
  unit_of_time: 'day',
  send_interval: 2,
  isFollowup: true,
  safe_send: true,
}

const MODALS_FLAGS = {
  unpublishWarning: false,
  requestTrafficReminder: false,
  outOfMessages: false,
  publish: false,
  leaveWarning: false,
  discardChanges: false,
  saveChangesLive: false,
  editMode: false,
  testMessage: false,
  templates: false,
  overwriteTemplate: false,
  showUrlWarning: false,
}

const FOLLOWUP_CRITERIA_OPTIONS = [
  { label: 'All Original Recipients', value: 'all recipients' },
  { label: 'Hasn\'t clicked', value: 'not clicked' },
  {
    label: 'Hasn\'t purchased',
    value: 'not purchased',
    disabled: true,
    link: '/profile/brand/data-integrations',
    linkLabel: 'Add Data Integrations',
  },
  {
    label: 'Hasn\'t clicked or purchased',
    value: 'not clicked or purchased',
    disabled: true,
    link: '/profile/brand/data-integrations',
    linkLabel: 'Add Data Integrations',
  },
  {
    label: 'Clicked, but hasn\'t purchased',
    value: 'clicked but not purchased',
    disabled: true,
    link: '/profile/brand/data-integrations',
    linkLabel: 'Add Data Integrations',
  },
]

const FOLLOWUPS_LIMIT_DEFAULT = 3
const TOASTR_OPTIONS = { timeOut: 3000, positionClass: 'toast-bottom-center' } // TODO: Check why top right

function addAdditionalProps(data, isFollowup = false, isSmsSend = false) {
  return {
    ...(isFollowup ? FOLLOWUP_PROPS : {}),
    ...data,
    ...COMMON_STATE_PROPS,
    isSmsSend,
  }
}
const addFollowupProps = data => addAdditionalProps(data, true)
const addSmsSendProps = data => addAdditionalProps(data, false, true)

// formats before saving, publishing
function applyReplacementsBeforeSaving({
  postEntry, smsSend, followups, criteria, currentCampaign,
}) {
  const PLACEHOLDER_REGEX = /https:\/\/.*\.(do?jo?mo?jo?)\.io\/.{8}/g

  const postEntryToSend = { ...postEntry }
  const smsSendToSend = { ...smsSend, name: currentCampaign.name }
  const followupsToSend = followups.map(followup => ({ ...followup }))

  ;[smsSendToSend, ...followupsToSend].forEach(messageObj => {
    if (PLACEHOLDER_REGEX.test(messageObj.custom_sms_message)) {
      messageObj.custom_sms_message = messageObj.custom_sms_message.replace(PLACEHOLDER_REGEX, '*|LINK|*')
    }

    // followup criteria
    if (messageObj.isFollowup) messageObj.criteria = criteria

    messageObj.coupon_code = messageObj.coupon_code || null

    delete messageObj.validations
    delete messageObj.dirty
    delete messageObj.errorFields
  })

  delete postEntryToSend.validations
  delete postEntryToSend.dirty
  delete postEntryToSend.errorFields

  return { ...postEntryToSend, smsSend: smsSendToSend, followups: followupsToSend }
}

function usePostEntrySms(props) {
  const {
    inviteId, currentBrand, currentCampaign, sms,
  } = props
  const followupsLimit = currentBrand.features.followupMessagesLimit || FOLLOWUPS_LIMIT_DEFAULT
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [postEntry, setPostEntry] = useState(addAdditionalProps(POST_ENTRY_INITIAL_STATE))
  const [smsSend, setSmsSend] = useState(addAdditionalProps(SMS_SEND_INITIAL_STATE))
  const [followups, setFollowups] = useState([])
  const [modals, setModals] = useState(MODALS_FLAGS)
  const [oldCouponCodes, setOldCouponCodes] = useState([])
  const [testData, setTestData] = useState(null)
  const [deleteID, setDeleteID] = useState(null)
  const [followupSendCriteria, setFollowupSendCriteria] = useState('all recipients')
  const [nextPath, setNextPath] = useState(null)
  const [postEntryPublishedFn, setPostEntryPublishedFn] = useState(null)
  const [previousSMSPostEntryCampaigns, setPreviousSMSPostEntryCampaigns] = useState([])

  // checks for unsaved changes/return to old values
  const [prevValues, setPrevValues] = useState({})
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const { isLoading: integrationsLoading, hasIntegrations, integrations } = useHasIntegration()

  const { current: axios } = useRef(Axios.create({ headers: requestHeaders() }))

  const { setSmsVerifyFlag } = useSmsVerify()

  if (postEntryPublishedFn !== null) {
    postEntryPublishedFn.setIsSmsTrafficPublished(smsSend.status === 'published')
  }

  const hasInvalidUrl = checkForInvalidShortUrlLink([smsSend, ...followups])
  const defaultFollowupCriteria = ['woocommerce', 'shopify']
    .some(store => integrations[store])
    ? 'not purchased'
    : 'not clicked'

  const isLoading = integrationsLoading || loading

  function modifyMessageObject(which, data, isError = false) {
    if (!data.skipDirty) setUnsavedChanges(true)
    function mergeData(prevObject) {
      const { id, ...restData } = data
      const { dirty = {}, errorFields = {} } = prevObject
      const replacingObj = isError ? errorFields : dirty
      const newReplacingObj = Object
        .keys(restData)
        .reduce((prev, key) => ({ ...prev, [key]: isError ? data[key] : true }), replacingObj)

      return {
        ...prevObject,
        ...(isError ? {} : restData),
        [isError ? 'errorFields' : 'dirty']: newReplacingObj,
      }
    }

    switch (which) {
      case 'followup':
        setFollowups(prevFollowups => (
          prevFollowups.map(followup => (followup.id === data.id ? mergeData(followup) : followup))
        ))
        break
      case 'postEntry':
        setPostEntry(mergeData)
        break
      default:
        setSmsSend(mergeData)
    }
  }
  function validateForm(action) {
    toastr.remove() //Prevents duplicate toastr

    let isValid = true

    const formItems = [postEntry, smsSend, ...followups]

    // check sms sends first
    formItems.slice(1).forEach(fields => {
      if (!isValid) return

      const error = checkCouponField(fields)

      if (error) {
        // this { coupon_code: 'Required' } forces the field to highlight error when trying to save first instance
        // without removing the merge tag. This can be improved
        const errorModify = [fields.isFollowup ? 'followup' : 'smsSend', { coupon_code: 'Required', id: fields.id }, true]
        const dirtyModify = [fields.isFollowup ? 'followup' : 'smsSend', { coupon_code: fields.coupon_code, id: fields.id }]
        modifyMessageObject(...errorModify)
        modifyMessageObject(...dirtyModify) // forces field to highlight if error

        isValid = false // skip next step
      }
    })

    // Logic: all errorFields props have to be false, if one comes true, then this return false, meaning its not valid
    if (isValid) isValid = formItems.every(({ errorFields }) => Object.keys(errorFields).every(key => !errorFields[key]))

    if (!isValid) toastr.error(`Please fix errors${action ? ` before ${action}` : ''}`)

    return isValid
  }
  async function showModal(which, show = true, cb = () => {}) {
    const flag = which ? { [which]: show } : {}
    setModals({ ...MODALS_FLAGS, ...flag })
    setTimeout(cb, 50)
  }
  // Sets which message to edit
  function toggleEditMessage(params = {}) {
    const { id, isEdit = false, revertingChanges = false } = params

    if (!revertingChanges && unsavedChanges && !validateForm()) return

    const setNewObject = prevObj => ({ ...prevObj, isEditing: prevObj.id === id && isEdit })
    if (isEdit) setEditMode(true)
    setSmsSend(setNewObject)
    setFollowups(currentFollowups => currentFollowups.map(setNewObject))
  }
  // processes after fetch, publish, unpublish
  function processMobileMarketingData(mobileMarketing, opts = {}) {
    const { isTemplate = false } = opts
    toggleEditMessage({ revertingChanges: false }) // close all editing parts
    // no record found
    if (!mobileMarketing) {
      setPostEntry({
        ...postEntry,
        title: `Unlock a Discount for ${currentBrand.accountname}`,
      })
      toggleEditMessage({ id: smsSend.id, isEdit: true })
    } else {
      const { smsSend: smsSendData, ...postEntryData } = mobileMarketing
      const { followups: followupsData = [], ...smsSendNoFollowup } = smsSendData
      const formattedFollowups = followupsData
        .map(followup => addFollowupProps({ ...followup.criteria, ...followup }))

      if (isTemplate) {
        smsSendNoFollowup.status = 'draft'
        smsSendNoFollowup.id = `main-${uuid()}`

        formattedFollowups.forEach(followup => {
          followup.id = `dummy-${uuid()}`
          followup.status = null
        })

        delete postEntryData.id
        delete postEntryData.sms_send_id
      }

      if (followupsData.length) setFollowupSendCriteria(followupsData[0].criteria.criteria)
      else setFollowupSendCriteria(defaultFollowupCriteria)

      setPostEntry(addAdditionalProps(postEntryData))
      setSmsSend(addSmsSendProps(smsSendNoFollowup))
      setFollowups(formattedFollowups)
      setPrevValues({
        smsSend: smsSendNoFollowup,
        postEntry: postEntryData,
        followups: formattedFollowups,
      })

      if (smsSendNoFollowup.status !== 'published') toggleEditMessage({ id: smsSendNoFollowup.id, isEdit: true })
      else setEditMode(false)
    }
  }
  async function fetchMobileMarketing() {
    showModal('discardChanges', false) // close this modal
    setUnsavedChanges(false)

    try {
      const [
        { data: mobileMarketing },
        { data: oldCoupons },
      ] = await Promise.all([
        axios.get(`/partnership/mobile-marketing/${inviteId}`),
        axios.get(`/sms/sms-send/coupons/${currentBrand.id}`),
      ])

      // this fetches the templates. no need to wait?
      axios
        .get(`/partnership/mobile-marketing/${inviteId}/past-messages`)
        .then(({ data: prevTemplates }) => setPreviousSMSPostEntryCampaigns(prevTemplates))
        .catch(() => {})

      setOldCouponCodes(oldCoupons)

      processMobileMarketingData(mobileMarketing)
    } catch (err) {
      console.log(err) // TODO: CHANGE, REMOVE ONCE DONE, MAYBE TOASTR?
    }

    setLoading(false)
    setSaving(false)
  }
  async function saveChanges(opts = {}) {
    const { isConfirmed = false, showToastr = true } = opts
    const showSaveChangesLive = currentCampaign.started
      && !isConfirmed
      && smsSend.status === 'published'
      && unsavedChanges

    const ignoreUrlWarning = localStorage.getItem(`smsPostEntryIgnoreWarning_${currentCampaign.id}`)

    if (!ignoreUrlWarning && hasInvalidUrl) {
      showModal('showUrlWarning')
      return false
    }

    if (showSaveChangesLive) {
      showModal('saveChangesLive')
      return false
    }

    showModal() // close all modals

    if (!validateForm('saving')) return false

    setSaving(true)

    try {
      const dataToSend = applyReplacementsBeforeSaving({
        currentCampaign, postEntry, smsSend, followups, criteria: followupSendCriteria,
      })

      const { data: savedMobileMarketing } = await axios.put(`/partnership/mobile-marketing/${inviteId}`, dataToSend)

      processMobileMarketingData(savedMobileMarketing)

      setUnsavedChanges(false)
      toggleEditMessage() // close all editing parts
      if (showToastr) toastr.success('Successfully saved.', null, TOASTR_OPTIONS)
    } catch (err) {
      toastr.error('Something wrong happened while saving. Please review and try again.', null, TOASTR_OPTIONS)
    }
    showModal() // close modals
    setSaving(false)

    return true
  }
  function setPostEntryStatus(which) {
    return async function settingPostEntryStatus() {
      const isPublish = which === 'publish'

      const ignoreUrlWarning = localStorage.getItem(`smsPostEntryIgnoreWarning_${currentCampaign.id}`)
      if (!ignoreUrlWarning && hasInvalidUrl && isPublish) {
        localStorage.setItem(`smsPostEntryPublishAction_${currentCampaign.id}`, 'true')
        showModal('showUrlWarning')
        return
      }

      // remove it if publishing, just in case
      localStorage.removeItem(`smsPostEntryPublishAction_${currentCampaign.id}`)

      if (isPublish && !validateForm(which)) return

      const dataToSend = applyReplacementsBeforeSaving({
        currentCampaign, postEntry, smsSend, followups, criteria: followupSendCriteria,
      })

      setSaving(true)
      showModal() // close all modals

      const { data } = await axios.post(`/partnership/mobile-marketing/${which}/${inviteId}`, dataToSend)
      const { record: updatedMobileMarketing, phoneCreated } = data

      setEditMode(!isPublish)
      processMobileMarketingData(updatedMobileMarketing)
      toggleEditMessage() // close all editing
      setUnsavedChanges(false)
      setSaving(false)

      if (phoneCreated) {
        setSmsVerifyFlag()
      }
    }
  }
  async function uploadImage(files) {
    if (!files.length) return

    setSaving(true)
    try {
      let filesData
      if (files.length) {
        filesData = new FormData()

        files.forEach(file => {
          filesData.append('file', file)
          filesData.append('name', file.name)
        })
      }

      const { data: image_url } = await axios.post(`/partnership/mobile-marketing/post-entry-image/${inviteId}`, filesData)
      modifyMessageObject('postEntry', { image_url })
      toastr.remove() //Prevents duplicate toastr
      toastr.success('Changes saved successfully.', TOASTR_OPTIONS)
    } catch (err) {
      // TODO: maybe use this error? 'Image upload failed. Max filesize is 5mb.'
      toastr.error('Error while updating image.', TOASTR_OPTIONS)
    }
    setSaving(false)
  }
  async function sendSmsTest(phoneNumber) {
    if (!validateForm('sending a test')) return

    setSaving(true)
    try {
      await axios.post('/sms/sms-send/test-message', { ...testData, phoneNumber })
      setTestData(null)

      toastr.success('Test message sent successfully.', null, TOASTR_OPTIONS)
    } catch (err) {
      let errorMessage = 'Test message failed. Please try again later.'
      if (err.message === 'Request failed with status code 429') {
        errorMessage = 'You have reached your SMS test message limit for today.'
      }
      toastr.error(errorMessage, null, TOASTR_OPTIONS)
    }
    setSaving(false)
  }
  // Sets preview or editing mode of content
  function toggleEditMode() {
    setEditMode(prevEditMode => {
      if (prevEditMode) toggleEditMessage() // Close all edits
      return !prevEditMode
    })
  }
  function addFollowup() {
    if (!validateForm('adding a followup')) return

    if ((followups.length + 1) > followupsLimit) return // TODO: Add a message user cant add more?

    const dummyId = `dummy-${uuid()}`
    const name = `Follow-Up Message #${followups.length + 1}`

    setFollowups(currentFollowups => ([
      ...currentFollowups,
      addFollowupProps({
        ...SMS_SEND_INITIAL_STATE,
        id: dummyId,
        name,
        coupon_code: smsSend.coupon_code,
      }),
    ]))
    toggleEditMessage({ id: dummyId, isEdit: true })
  }
  async function deleteFollowup() {
    let amountDeleted = 0
    try {
      setDeleteID(null)
      setSaving(true)
      const followupIndex = followups.findIndex(({ id }) => id === deleteID)
      const followupIDsToDelete = followups.slice(followupIndex).map(({ id }) => id)

      // delete all subsequent followups
      await axios.delete(`/partnership/mobile-marketing/${inviteId}/followup`, { data: { followupIds: followupIDsToDelete } })

      amountDeleted = followupIDsToDelete.length

      setFollowups(followups.filter(({ id }) => !followupIDsToDelete.includes(id)))

      if (amountDeleted === followups.length) setFollowupSendCriteria(defaultFollowupCriteria)

      toastr.success(`Followup message${amountDeleted > 1 ? 's' : ''} deleted.`, null, TOASTR_OPTIONS)
    } catch (err) {
      toastr.error(`Error while deleting message${amountDeleted > 1 ? 's' : ''}`, null, TOASTR_OPTIONS)
      await fetchMobileMarketing() // fetch again to make sure all is good
    }
    setSaving(false)
  }
  function cancelChanges() {
    const mobileMarketingValues = {
      ...prevValues.postEntry,
      smsSend: {
        ...prevValues.smsSend,
        followups: [...prevValues.followups],
      },
    }

    processMobileMarketingData(mobileMarketingValues)
    showModal() // close modal
  }

  async function onLoadPreviousPostEntryTemplate(templateId, override = false) {
    toastr.clear()
    if (!/main/.test(smsSend.id) && !override) {
      showModal('overwriteTemplate', templateId) // set a value so we can use it if confirmed
      return
    }
    setLoading(true)
    showModal('templates', false)
    try {
      const { data: mobileMarketing } = await axios.get(`/partnership/mobile-marketing/${templateId}`)
      processMobileMarketingData(mobileMarketing, { isTemplate: true })

      if (override) {
        setTimeout(() => saveChanges({ showToastr: false }), 10)
      }
      toastr.success('Template loaded', null, TOASTR_OPTIONS)
    } catch (err) {
      toastr.warning('Error while loading template. Try again later.', null, TOASTR_OPTIONS)
    }
    setLoading(false)
  }

  function onRouterLeave({ pathname }) {
    if (editMode && unsavedChanges) {
      setNextPath(pathname)
      showModal('leaveWarning')
      return false
    }

    return true
  }

  useEffect(() => {
    if (!integrationsLoading) {
      setFollowupSendCriteria(defaultFollowupCriteria)
      if (inviteId) fetchMobileMarketing()
    }
  }, [inviteId, integrationsLoading])

  useEffect(() => {
    store.dispatch({
      type: 'UPDATE_ATTR',
      model: 'currentCampaign',
      data: {
        smsStatus: smsSend.status,
      },
    })
  }, [smsSend.status])

  return {
    // state
    loading: isLoading,
    postEntry,
    smsSend,
    deleteID,
    modals: {
      ...modals,
      deleteFollowup: !!deleteID,
    },
    editMode,
    followups,
    oldCouponCodes,
    smsBank: sms.brandSmsBank,
    testData,
    followupSendCriteria,
    unsavedChanges,
    saving,
    nextPath,
    currentCampaign,
    FOLLOWUPS_LIMIT: followupsLimit,
    FOLLOWUP_CRITERIA_OPTIONS: hasIntegrations
      // remove the disable prop
      ? FOLLOWUP_CRITERIA_OPTIONS.map(({ value, label }) => ({ value, label }))
      : FOLLOWUP_CRITERIA_OPTIONS,

    // actions
    toggleEditMode,
    setPostEntry: data => modifyMessageObject('postEntry', data),
    onPostEntryError: errorFields => modifyMessageObject('postEntry', errorFields, true),
    showModal,
    saveChanges,
    publishPostEntry: setPostEntryStatus('publish'),
    unpublishPostEntry: setPostEntryStatus('unpublish'),
    uploadImage,
    sendSmsTest,
    setTestData,
    cancelChanges,
    setMainMessage: data => modifyMessageObject('smsSend', data),
    onMainMessageError: errorFields => modifyMessageObject('smsSend', errorFields, true),
    setNextPath,
    onRouterLeave,
    validateForm,
    canDuplicate: !postEntry.published_count && previousSMSPostEntryCampaigns.length,
    // previous post entry templates
    previousSMSPostEntryCampaigns,
    onLoadPreviousPostEntryTemplate,

    // followup actions
    setFollowupSendCriteria: data => {
      setUnsavedChanges(true)
      setFollowupSendCriteria(data)
    },
    addFollowup,
    setDeleteID, // sets which message to delete, if confirmed
    deleteFollowup,
    toggleEditMessage,
    setFollowupMessage: (data, id) => modifyMessageObject('followup', { ...data, id }),
    onFollowupError: (errorFields, id) => modifyMessageObject('followup', { ...errorFields, id }, true),
    setPostEntryPublishedFn, // can be used to track if post entry is published outside of context scope
  }
}

export default usePostEntrySms
