import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { P, H4 } from 'visual-components/util/texts'
import Badge from 'visual-components/util/badge'
import ToggleField from 'util/components/toggle-field'
import COLOURS from 'util/colours'

const styles = {
  container: {
    padding: 16,
    border: `1px solid ${COLOURS.silver}`,
  },
  headingContainer: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  badge: {
    display: 'inline-block',
    marginLeft: 8,
    marginTop: -4,
  },
  toggle: {
    marginTop: -4,
  },
  copy: {
    width: 452,
    '& p:last-child': {
      marginBottom: 24,
    },
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'start',
    padding: 16,
    background: `${COLOURS.lightYellow}66`, // 40%
    '& img': {
      marginRight: 16,
    },
    '& h4': {
      marginBottom: 8,
    },
  },
}

class BasicsSmsOptin extends React.Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    updateAttr: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { campaign, updateAttr, classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.headingContainer}>
          <div className={classes.heading}>
            <P>
              <i>
                SMS MARKETING
              </i>
            </P>
            <div className={classes.badge}>
              <Badge label="New" color={COLOURS.seaGreen} simple inline nominwidth />
            </div>
          </div>
          <div className={classes.toggle}>
            <ToggleField
              value={campaign.showSmsOptin}
              updateAttr={updateAttr}
              altLabel={campaign.showSmsOptin ? 'Enabled' : 'Disabled'}
              attrName="showSmsOptin"
              model={campaign}
              position="top"
              microAltLabel
            />
          </div>
        </div>
        <div className={classes.copy}>
          <P multiline>
            <em>Give entrants the option to join your SMS marketing list!</em>
          </P>
          <P multiline>
            With SMS Marketing Opt-Ins, you can capture mobile phone numbers and begin growing your mobile marketing audience straight from your sweepstakes.
          </P>
        </div>
        <div className={classes.warningContainer}>
          <img src="images/icons/warning.svg" />
          <div>
            <H4>
              <i>
                Heads Up
              </i>
            </H4>
            <P multiline>
              You’ll need to create a welcome text for new SMS subscribers! Once you finish building your sweepstakes, craft your text message in the SMS Welcome Message editor. <em>Your first 1,000 SMS credits are free!</em>
            </P>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(BasicsSmsOptin)
