import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { H2, P } from 'visual-components/util/texts'

class PageTitle extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }

  static defaultProps = {
    subtitle: null,
  }

  render() {
    const { title, subtitle } = this.props

    return (
      <React.Fragment>
        <div style={{ margin: '32px 0 16px' }}>
          <H2 azure>{ title }</H2>
        </div>
        { subtitle }
      </React.Fragment>
    )
  }
}

export default PageTitle
