import React, { Component } from 'react';
import _ from 'lodash'

import * as ReactRedux from 'react-redux';
import InterestedConfirmationModal from 'visual-components/messaging/interested-confirmation-modal'

import InviteBannerItem from './invite-banner-item';

class InviteBannerContainer extends Component {
  render() {
    let { brand, campaign, match, currentBrand, receivedInvitations, receivedApplications } = this.props

    const filterDuplicateEntries = entriesArr => {
      const memo = {}
      const result = []

      entriesArr.forEach(entry => {
        // check cache for entry using a partnership invite ID as UID
        if (memo[entry.partnershipinvite_id]) {
          return
        } else {
          // store the entry in the cache and add the entry in the results array.
          memo[entry.partnershipinvite_id] = entry
          result.push(entry)
        }
      })
      return result
    }

    const itemsMatchMap = {
      brands: function () {
        if (brand.id == currentBrand.id) {
          return [];
        }

        let invites = _.filter(receivedInvitations, {
          hostbrand_id: brand.id,
          status: 'pending'
        });
        let applications = _.filter(receivedApplications, {
          invitee_id: brand.id,
          status: 'pending'
        });
        //only show the last notification from each conversation
        applications = _.uniqBy(applications,'conversation_id');
        invites = _.uniqBy(invites,'conversation_id');

        _.each(invites, function (invite) {
          invite.actionText = `${invite.host_brand_name} has invited you to join ${invite.campaign_name}`;
          invite.invitee = {
            id: brand.id
          }
        })

        _.each(applications, function (application) {
          application.actionText = `${application.invitee_brand_name} has applied to join ${application.campaign_name}`;
          application.invitee = {
            id: brand.id
          }
        });

        return invites.concat(applications);
      },
      campaigns: function () {
        let applications = _.filter(receivedApplications, {
          campaign_id: campaign.id,
          status: 'pending'
        });

        let invites = _.filter(receivedInvitations, {
          campaign_id: campaign.id,
          status: 'pending'
        })

        /* 
          receivedInvitations and receivedApplications consist of all messages (invitations) associated to one partnership invite,
          and may result in duplicates.
          To reduce redundancy, filter and display only distinct applications or invites.
        */
        const invitesFiltered = filterDuplicateEntries(invites)
        const applicationsFiltered = filterDuplicateEntries(applications)

        if (invitesFiltered.length) {
          invitesFiltered.forEach(invite => {
            invite.actionText = `${campaign.hostBrandName} has invited you to join ${invite.campaign_name}`;
            invite.invitee = { id: invites.invitee_id }
          })
        }

        if (applicationsFiltered.length) {
          applicationsFiltered.forEach(application => {
            application.actionText = `${application.invitee_brand_name} has applied to join ${campaign.name}`;
            application.invitee = { id: application.invitee_id }
          })
        }

        return invitesFiltered.concat(applicationsFiltered);
      }
    }

    const invitesAndApplications = itemsMatchMap[match.params[0]]()

    const items = invitesAndApplications.map(invite => {
      if (invite) {
        return (
          <div key={invite.id}>
            <InviteBannerItem invite={invite} />
          </div>
        )
      }
    })

    return (
      <div style={{
        backgroundColor: COLOURS.foggy,
        paddingBottom: items.length ? '15px' : '0',
      }}>
        { invitesAndApplications.length ? items : null }
        <InterestedConfirmationModal />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaign,
    currentBrand: state.currentBrand,
    brand: state.brand,
    brandCampaigns: state.brandCampaigns,
    receivedInvitations: state.conversations.receivedInvitations,
    receivedApplications: state.conversations.receivedApplications,
  }
}

export default ReactRedux.connect( mapStateToProps )( InviteBannerContainer );
