import React from 'react'
import { string, func } from 'prop-types'
import { Input } from 'react-toolbox/lib/input'
import { Micro } from 'visual-components/util/texts'
import SearchInputTheme from 'css/themes/searchInput.css'

class SearchInput extends React.Component {
  static propTypes = {
    placeholder: string.isRequired,
    value: string.isRequired,
    onChange: func.isRequired,
    onKeyUp: func,
  }

  static defaultProps = {
    onKeyUp: () => {},
  }

  render() {
    const {
      placeholder,
      value,
      onChange,
      onKeyUp,
      inputRef,
      className,
      style,
      onFocus,
      belowLabel,
    } = this.props

    const ele = <img src="/images/icons/search-icon.svg" />

    return (
      <div>
        <Input
          type="text"
          name="search"
          id="search-input"
          hint={placeholder}
          value={value}
          onChange={newVal => { onChange(newVal) }}
          onKeyUp={onKeyUp}
          theme={SearchInputTheme}
          className={SearchInputTheme[className]}
          ref={inputRef}
          icon={ele}
          style={style}
          onFocus={onFocus}
        />
        { belowLabel ? (
          <div style={{ textAlign: 'right', marginTop: '4px' }}>
            <Micro>
              { belowLabel }
            </Micro>
          </div>
        ) : null }
      </div>
    )
  }
}

export default SearchInput
