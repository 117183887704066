import React from 'react';
import { Card } from 'react-toolbox/lib/card';
import { P, Tiny, H5, Small } from 'visual-components/util/texts';
import moment from 'moment-timezone'

class TooltipCard extends React.Component {
  render() {
    let {
      period,
      periodCompare,
      value,
      metric,
      percentChange,
      formatMomentHourly,
      formatMoment
    } = this.props;

    period = formatMomentHourly ? moment(period).format('MMM DD, h a') : formatMoment ? 
      moment(period).format('MMM DD') : period

    return (
      <Card style={{
        paddingTop: '12px',
        paddingRight: '12px',
        paddingBottom: '12px',
        paddingLeft: '12px',
        width: '136px'
      }}>
        <Tiny>
          {period}
        </Tiny>
        { periodCompare ?
          <div>
            <div style={{marginTop: '4px', marginBottom: '4px'}}>
              <Tiny>
                vs
              </Tiny>
            </div>
            <Tiny>
              {periodCompare}
            </Tiny>
          </div>
          :
          null
        }
        <div style={{marginTop: '12px'}}>
          <H5>
            {value}
          </H5>
        </div>
        <Tiny>
          {metric}
        </Tiny>
        {
          periodCompare ?
          <div
            style={{marginTop: '10px'}}>
            <Small
              alertRed={percentChange < 0}
              seaGreen={percentChange >= 0}>
              {d3.format('%')(percentChange / 100)}
            </Small>
          </div>
          :
          null
        }
      </Card>
    )
  }
};

export default TooltipCard;
